import moment from "moment";
import calendarKeys from "../../hooks/calendar/calendarKeys";
import getReqParams from "./getReqParms";

const removeFromCalendarStore = ({
  queryClient,
  calendarData,
  variables,
  data = variables.args.item,
}) => {
  queryClient.setQueryData(
    calendarKeys.calendarList(getReqParams(calendarData)),
    (oldData) => {
      // Note: if the calendar cache hasn't loaded yet initiate with array
      // Note this isn't likely because the calendar is the first page, but if someone followed a link that could create an issue
      if (!oldData) return [];

      if (variables.operation === "$non-recurring") {
        const newEntriesArray = oldData.filter(
          (event) => event.resource.id !== variables.args.id
        );

        return newEntriesArray;
      }

      if (variables.operation === "$all") {
        const itemId = variables.args.id;

        const newEntriesArray = oldData.filter((event) => {
          return event.resource.id !== itemId;
        });

        return newEntriesArray;
      }

      if (variables.operation === "$single") {
        const itemId = variables.args.item.id;
        const itemDate = variables.args.item.startDate;

        const newEntryArray = oldData.filter((item) => {
          return !(
            item.resource.startDate === itemDate && itemId === item.resource.id
          );
        });

        return newEntryArray;
      }

      if (variables.operation === "$future") {
        const itemId = variables.args.item.id;
        const itemDate = variables.args.item.startDate;

        const newEntryArray = oldData.filter((item) => {
          return !(
            moment(item.resource.startDate).isSameOrAfter(moment(itemDate)) &&
            itemId === item.resource.id
          );
        });

        return newEntryArray;
      }
      return oldData;
    }
  );

  return data;
};

export default removeFromCalendarStore;

import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { BRAND_GREEN_LIGHT, DROPDOWN_STYLE } from "../../../constants";
import Dropdown from "../Dropdown/Dropdown";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    width: "100%",
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    ...DROPDOWN_STYLE.option,
    color: "black",
    backgroundColor: isFocused && BRAND_GREEN_LIGHT,
  }),
  singleValue: (provided) => ({
    ...provided,
    ...DROPDOWN_STYLE.singleValue,
  }),
  control: (provided) => ({
    ...provided,
    ...DROPDOWN_STYLE.control,
  }),
};

const formatSopData = (sopData) => {
  const newList = [];
  sopData.map((item) => {
    return newList.push({
      label: item.name,
      value: item.name,
      sop: item.reference,
    });
  });
  return newList;
};

const MaintenanceSopSelect = ({
  sopData,
  handleSopStepChange,
  isEditingStep,
  index,
  step,
  handleBlur,
  menuPlacement,
}) => {
  const [searchTerm, setSearchTerm] = React.useState(step?.description || "");
  const [searchResults, setSearchResults] = React.useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const formattedSopList = useMemo(() => formatSopData(sopData), [sopData]);

  useEffect(() => {
    setSelectedOption(step);
  }, [step]);

  const handleInputChange = useCallback((inputValue) => {
    setSearchTerm(inputValue);
  }, []);

  const handleChange = useCallback(
    // eslint-disable-next-line consistent-return
    (sop, indx) => {
      if (sop) {
        setSelectedOption(sop.label);
        handleSopStepChange({ ...sop }, indx);
      }
    },
    [handleSopStepChange]
  );

  const handleOnKeyDown = useCallback(
    (event, value, idx) => {
      // checks if the Enter key was pressed
      if (event.keyCode === 13) {
        if (value === "") {
          return undefined;
        }
        if (
          !isEditingStep &&
          searchResults?.length !== 0 &&
          searchResults?.some((r) =>
            r.description.toLowerCase().includes(searchTerm?.toLowerCase())
          )
        ) {
          event.preventDefault();
          event.stopPropagation();
          return handleSopStepChange({ description: searchTerm }, idx);
        }
        return handleSopStepChange({ description: searchTerm }, idx);
      }
      return undefined;
    },
    [handleSopStepChange, isEditingStep, searchResults, searchTerm]
  );

  useEffect(() => {
    const options = sopData
      ?.filter(
        (sop) =>
          searchTerm?.length > 3 &&
          sop?.name?.toLowerCase()?.includes(searchTerm.toLowerCase())
      )
      ?.map((item) => ({
        label: item.label,
        description: item.name,
        sop: item.sop,
      }));
    setSearchResults(options);
  }, [searchTerm, sopData]);

  return useMemo(() => {
    return (
      <div className="flex flex-col w-full items-start">
        <Dropdown
          menuPlacement={menuPlacement}
          autoFocus
          className="w-full"
          styles={customStyles}
          value={selectedOption}
          defaultValue=""
          onChange={(value) => handleChange(value, index)}
          inputValue={searchTerm}
          defaultInputValue={step}
          onInputChange={handleInputChange}
          isSearchable
          options={formattedSopList}
          onBlur={() => handleBlur(step, index)}
          onKeyDown={(event) => handleOnKeyDown(event, step, index)}
          noOptionsMessage={() => null}
        />
      </div>
    );
  }, [
    menuPlacement,
    selectedOption,
    searchTerm,
    step,
    handleInputChange,
    formattedSopList,
    handleChange,
    index,
    handleBlur,
    handleOnKeyDown,
  ]);
};

MaintenanceSopSelect.propTypes = {
  handleSopStepChange: PropTypes.func,
  sopData: PropTypes.arrayOf(
    PropTypes.shape({ description: PropTypes.string })
  ),
  index: PropTypes.number,
  step: PropTypes.shape({
    description: PropTypes.string,
    label: PropTypes.string,
  }),
  handleBlur: PropTypes.func,
  isEditingStep: PropTypes.bool,
  menuPlacement: PropTypes.string,
};

MaintenanceSopSelect.defaultProps = {
  handleSopStepChange: undefined,
  sopData: [],
  index: undefined,
  step: {},
  handleBlur: undefined,
  isEditingStep: false,
  menuPlacement: undefined,
};

export default MaintenanceSopSelect;

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { customModalStyles, SOP_TABLE_PATH } from "../../../constants";
import { useAppState } from "../../../state/appState";
import Modal from "../../../stories/Components/Modal/Modal";
import alert from "../../../stories/assets/images/alert.svg";
import { useDeleteSOPs } from "../../../hooks/useSop";

const { overlayStyle, contentStyle, titleStyle, headerStyle } =
  customModalStyles;

const SINGLE_IN_USE_MSG = `This standard operating procedure is part of active tasks,
you will delete the related step in each task.
Are you sure you want to delete this standard operating procedure?`;
const MULTI_IN_USE_MSG = `Some of these standard operating procedures are part of active tasks,
you will delete the related step in each task.
Are you sure you want to delete these standard operating procedures?`;

const COMFIRM_MSG = `Are you sure you want to delete this standard operating procedure? Once deleted it cannot be recovered`;
const MULTI_COMFIRM_MSG = `Are you sure you want to delete these standard operating procedures? Once deleted they cannot be recovered`;

export default function SopDeleteModal({
  someSopInUse,
  sopArray,
  canDeleteSop,
  setCanDeleteSop,
}) {
  const history = useHistory();
  const [{ selectOptions }] = useAppState();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const [confirmMessage, setConfirmMessage] = useState();
  const [warningMessage, setWarningMessage] = useState();

  const { mutate: removeSops, isSuccess, isError } = useDeleteSOPs();

  useEffect(() => {
    if (isSuccess || isError) history.push(SOP_TABLE_PATH);
  }, [isSuccess, isError, history]);

  const onDeleteClicked = async () => {
    setShowDeleteModal(false);
    setCanDeleteSop(false);

    removeSops({ sopsToDelete: sopArray, selectOptions });
  };

  useEffect(() => {
    if (sopArray?.length > 0 && canDeleteSop) {
      const message = `${
        sopArray?.length > 1 ? MULTI_COMFIRM_MSG : COMFIRM_MSG
      } `;
      setConfirmMessage(message);

      if (!someSopInUse) {
        setShowDeleteModal(true);
      } else {
        const warning = `${
          sopArray?.length > 1 ? MULTI_IN_USE_MSG : SINGLE_IN_USE_MSG
        } `;

        setWarningMessage(warning);
        setShowWarning(true);
        setShowDeleteModal(false);
      }
    }
  }, [canDeleteSop, someSopInUse, sopArray?.length]);

  return (
    <>
      <Modal
        overlayStyle={overlayStyle}
        contentStyle={contentStyle}
        titleStyle={titleStyle}
        headerStyle={headerStyle}
        title="Delete Sop?"
        isOpen={showDeleteModal}
        primaryButtonTitle="Yes, Delete"
        primaryButtonOnClick={onDeleteClicked}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={() => {
          setShowDeleteModal(false);
          setCanDeleteSop(false);
        }}
        closeTimeoutMS={200}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
        alert
      >
        <>
          <div
            className="flex border rounded-md p-8 ml-4"
            style={{ width: "780px" }}
          >
            <p className="relative flex justify-start items-start mr-2">
              <img src={alert} className="w-6 h-6" alt="warning" />
            </p>
            <p className="text-base mb-2">{confirmMessage}</p>
          </div>
        </>
      </Modal>

      <Modal
        title="Delete SOP"
        isOpen={showWarning}
        primaryButtonTitle="Delete"
        primaryButtonOnClick={() => {
          setShowWarning(false);
          setShowDeleteModal(true);
          setCanDeleteSop(false);
        }}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={() => {
          setCanDeleteSop(false);
          setShowWarning(false);
        }}
        closeTimeoutMS={200}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
        alert
      >
        <div
          className="flex border rounded-md p-8 ml-4"
          style={{ width: "780px" }}
        >
          <p className="relative flex justify-start items-start mr-2">
            <img src={alert} className="w-6 h-6" alt="warning" />
          </p>
          <p className="text-base mb-2">{warningMessage}</p>
        </div>
      </Modal>
    </>
  );
}

SopDeleteModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sopArray: PropTypes.arrayOf(PropTypes.object),
  canDeleteSop: PropTypes.bool,
  setCanDeleteSop: PropTypes.func,
  someSopInUse: PropTypes.bool,
};

SopDeleteModal.defaultProps = {
  sopArray: [],
  canDeleteSop: false,
  setCanDeleteSop: () => {},
  someSopInUse: false,
};

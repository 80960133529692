/* eslint-disable no-param-reassign */
import { useState, useEffect, useCallback } from "react";
import { HistoryAPI, CommentAPI } from "@griffingroupglobal/eslib-api";
import moment from "moment";

const commentSortOnInsertion = (arr, comment) => {
  const commentDate = new Date(comment?.metadata?.createdAt);
  const insert = arr?.findIndex((item) => {
    const itemDate = new Date(item?.when || item?.metadata?.createdAt);
    return itemDate < commentDate;
  });
  if (insert !== -1) {
    arr.splice(insert, 0, {
      ...comment,
      message: `Comment:`,
    });
  } else {
    arr.push({ ...comment, message: `Comment:` });
  }
};

const useResourceHistory = (parent, id) => {
  const [history, setHistory] = useState();

  const reload = useCallback(async () => {
    try {
      if (id) {
        const { data } = await HistoryAPI.get({
          params: {
            association: `${parent}/${id}`,
          },
        });
        const { data: commentsData } = await CommentAPI.get({
          params: {
            association: `${parent}/${id}`,
          },
        });
        /**
         * @summary - sets history state with sorted array of Objects
         * representing year of history on resource
         */
        setHistory(() => {
          const pointers = {};
          const formatted = [...data?.entries, ...commentsData]?.reduce(
            (list, item) => {
              const historyYear = moment(
                item?.resource?.when ?? item?.metadata?.createdAt
              ).format("YYYY");
              if (typeof pointers[historyYear] !== "number") {
                const insert = Object.keys(pointers)?.findIndex((point) => {
                  return point > historyYear;
                });
                if (insert !== -1) {
                  list.splice(insert, 0, {
                    year: historyYear,
                    items: [
                      item?.resource !== "Comment"
                        ? item?.resource
                        : { ...item, message: `Comment:` },
                    ],
                  });
                  pointers[historyYear] = insert;
                  let point = parseInt(historyYear, 10) + 1;

                  /**
                   * @summary
                   * - Inserts history year into sorted array
                   * - Traverses from Insertion Index to currentYear &
                   * increments the pointers to refelct addition
                   * to array.
                   */
                  const currentYear = parseInt(moment().format("YYYY"), 10);
                  while (point <= currentYear) {
                    if (typeof pointers[point] === "number") {
                      pointers[point] += 1;
                    }
                    point += 1;
                  }
                } else {
                  const pointerLength = Object.values(pointers)?.length;
                  pointers[historyYear] = pointerLength;
                  list.push({
                    year: historyYear,
                    items: [
                      item?.resource !== "Comment"
                        ? item?.resource
                        : { ...item, message: `Comment:` },
                    ],
                  });
                }
              } else {
                if (item?.resource !== "Comment") {
                  list[pointers[historyYear]]?.items?.push(item?.resource);
                  return list;
                }
                commentSortOnInsertion(
                  list[pointers[historyYear]]?.items,
                  item
                );
              }
              return list;
            },
            []
          );

          return formatted;
        });
      }
    } catch (error) {
      // handle error
    }
  }, [id, parent]);

  const updateComment = (key, comment) => {
    const index = history[key]?.items?.findIndex(
      (item) => item?.id === comment?.id
    );
    const updated = history[key]?.items;
    updated[index] = comment;

    setHistory((prev) => {
      prev[key] = { ...prev[key], items: updated };
      return prev;
    });
  };
  useEffect(() => {
    reload();
  }, [reload]);
  return { history, reload, updateComment };
};

export default useResourceHistory;

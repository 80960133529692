import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import cntl from "cntl";
import TabbedContainer from "../TabbedContainer/TabbedContainer";
import Table from "../Table/Table";
import {
  mockDocumentsData,
  mockTimesheetData,
} from "../../assets/mockData/pendingUpdatesData";
// eslint-disable-next-line import/no-cycle
import PendingUpdatesBudgetTable from "./PendingUpdatesBudgetTable";
import TertiaryButton from "../Buttons/TertiaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";
import { financialDocumentsTypeAcronyms } from "../../../constants";
// eslint-disable-next-line import/no-cycle
import DocumentView from "../DocumentView/DocumentView";
import CurrencyView from "../CurrencyView/CurrrencyView";

const buttonCN = cntl`
  flex
  justify-end
  mx-6
  pt-6
`;

const teritiaryButtonCN = cntl`
  rounded-md
  capitalize
`;

const primaryButtonCN = cntl`
  rounded-md
  ml-3
`;

const DocumentsQueue = ({
  data,
  openDocumentDetails,
  setOpenDocumentDetails,
  selectedDocumentsRows,
  setSelectedDocumentRows,
  projectId,
  onMergePendingUpdates,
  onPendingUpdatesQueueClick,
  disableMerge,
}) => {
  const handleRowSelect = useCallback(
    (val) => {
      if (val) {
        setSelectedDocumentRows(val);
      }
    },
    [setSelectedDocumentRows]
  );

  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
        isPrimary: true,
      },
      {
        Header: "Type",
        accessor: "docType",
        Cell: ({ value }) => (
          <div className="flex">
            <p>{financialDocumentsTypeAcronyms[value]}</p>
          </div>
        ),
      },
      // TODO: api support needed
      {
        Header: "Resolved Date",
        accessor: "modifiedDate",
        Cell: ({ value }) => (
          <div className="flex">
            <p>{moment(value).format("MM-DD-YYYY h:mm a")}</p>
          </div>
        ),
      },
      {
        Header: "Created By",
        accessor: "createdBy",
      },
      {
        Header: "",
        accessor: "merge",
        Cell: ({ row }) => (
          <div className="flex flex-row justify-between">
            {!disableMerge && (
              <div
                role="button"
                onKeyDown={() => {}}
                tabIndex="0"
                onClick={(e) => {
                  e.stopPropagation();
                  onMergePendingUpdates(row?.original?.reference);
                }}
                className="text-primaryGreen font-medium px-4"
              >
                Merge
              </div>
            )}
            <div
              role="button"
              onKeyDown={() => {}}
              tabIndex="0"
              onClick={(e) => {
                e.stopPropagation();
                setOpenDocumentDetails({
                  reference: row?.original?.reference,
                  index: row?.index,
                });
              }}
              className="text-primaryGreen font-medium"
            >
              View
            </div>
          </div>
        ),
      },
    ];
  }, [disableMerge, onMergePendingUpdates, setOpenDocumentDetails]);

  const onNextClick = () => {
    setOpenDocumentDetails((prev) => {
      if (prev.index + 1 !== data?.length) {
        return {
          index: prev.index + 1,
          // TODO: fix this
          reference: prev.reference,
        };
      }
      return prev;
    });
  };

  const onMerge = () => {
    onMergePendingUpdates();
    onNextClick();
  };

  return (
    <>
      {!openDocumentDetails && (
        <>
          <Table
            name="documentsPendingQueue"
            columns={tableColumns}
            data={data}
            allowSelection
            hideSwitchView
            hideSiteHeader
            onSelectedRowChange={handleRowSelect}
            disableAutoResetSelectedRows
            hideLineNumber
            disableSorting
            className="overflow-y-scroll"
          />
          <div className={buttonCN}>
            <TertiaryButton
              title="Cancel"
              onClick={onPendingUpdatesQueueClick}
              className={teritiaryButtonCN}
              cancelButton
            />
            <PrimaryButton
              className={primaryButtonCN}
              title="Merge"
              onClick={() => onMergePendingUpdates()}
              disabled={!selectedDocumentsRows.length || disableMerge}
            />
          </div>
        </>
      )}
      {openDocumentDetails && (
        <>
          <DocumentView
            docRef={openDocumentDetails?.reference}
            projId={projectId}
            onCancel={() => setOpenDocumentDetails(null)}
            onBackClick={() => setOpenDocumentDetails(null)}
            hideSiteHeaderButtons
            hideDocumentActions
            hideFloatingActionButtons
            hideSiteHeader
            backButtonTitle="List View"
          />
          <div className={buttonCN}>
            <TertiaryButton
              title="Cancel"
              onClick={onPendingUpdatesQueueClick}
              className={teritiaryButtonCN}
            />
            <TertiaryButton
              title="Next"
              onClick={onNextClick}
              className={teritiaryButtonCN}
              disabled={
                openDocumentDetails?.index === mockDocumentsData?.length - 1
              }
            />
            <PrimaryButton
              className={primaryButtonCN}
              title="Merge"
              onClick={onMerge}
              disabled={disableMerge}
            />
          </div>
        </>
      )}
    </>
  );
};

const TimesheetQueue = ({
  data,
  setSelectedTimesheetRows,
  budgetId,
  projectData,
  setOpenTimesheet,
  openTimesheet,
  openTimesheetData,
  setCheckedRow,
  checkedRow,
  onMergePendingUpdates,
  disableMerge,
}) => {
  const handleRowSelect = useCallback(
    (val) => {
      if (val) {
        setSelectedTimesheetRows(val);
      }
    },
    [setSelectedTimesheetRows]
  );

  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: "Employee",
        accessor: "employee",
        isPrimary: true,
      },
      {
        Header: "Category",
        accessor: "category",
      },
      {
        Header: "Rate",
        accessor: "rateDisplay",
      },
      {
        Header: "Hours",
        accessor: "value",
        Cell: ({ value }) => (
          <div className="flex">
            <p>{parseFloat(value / 60).toFixed(2)}</p>
          </div>
        ),
      },
      {
        Header: "Time Entry Date",
        accessor: "timeEntryDate",
        Cell: ({ value }) => (
          <div className="flex">
            <p>{moment(value).format("MM-DD-YYYY h:mm a")}</p>
          </div>
        ),
      },
      {
        Header: "",
        accessor: "view",
        Cell: ({ row }) => (
          <div className="flex flex-row items-center">
            <div
              role="button"
              onKeyDown={() => {}}
              tabIndex="0"
              onClick={(e) => {
                e.stopPropagation();
                setOpenTimesheet({
                  reference: row?.original?.reference,
                  timesheetEntry: row?.original?.id,
                });
              }}
              className="text-primaryGreen font-medium"
            >
              View
            </div>
          </div>
        ),
      },
    ];
  }, [setOpenTimesheet]);

  const onCancel = () => {
    setCheckedRow(null);
    setOpenTimesheet(null);
  };

  const onMerge = () => {
    onMergePendingUpdates();
    setOpenTimesheet(null);
  };
  return (
    <>
      {!openTimesheet && (
        <Table
          name="timesheetPendingQueue"
          columns={tableColumns}
          data={data}
          hideSwitchView
          hideSiteHeader
          onSelectedRowChange={handleRowSelect}
          disableAutoResetSelectedRows
          hideLineNumber
        />
      )}
      {openTimesheet && (
        <>
          <div className="text-gray-300 font-semibold pt-2">
            {
              mockTimesheetData?.find(
                (item) => item.reference === openTimesheet
              )?.employee
            }
          </div>
          <PendingUpdatesBudgetTable
            budgetId={budgetId}
            projectData={projectData}
            // send timesheet's csi code and rate to filter the data in budget
            initialCsiCode={openTimesheetData?.financialCode}
            initialRate={openTimesheetData?.rate}
            timesheetRate={null}
            setCheckedRow={setCheckedRow}
            checkedRow={checkedRow}
          />
          <div className={buttonCN}>
            <TertiaryButton
              title="Cancel"
              onClick={onCancel}
              className={teritiaryButtonCN}
              cancelButton
            />
            <PrimaryButton
              className={primaryButtonCN}
              title="Merge"
              onClick={onMerge}
              disabled={!checkedRow || disableMerge}
            />
          </div>
        </>
      )}
    </>
  );
};

const ExpenseQueue = ({
  data,
  setSelectedExpenseRows,
  budgetId,
  projectData,
  setOpenExpense,
  openExpense,
  openExpenseData,
  setCheckedRow,
  checkedRow,
  onMergePendingUpdates,
  disableMerge,
}) => {
  const handleRowSelect = useCallback(
    (val) => {
      if (val) {
        setSelectedExpenseRows(val);
      }
    },
    [setSelectedExpenseRows]
  );

  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: "Financial Code",
        accessor: "financialCode",
        isPrimary: true,
      },
      {
        Header: "Category",
        accessor: "category",
      },
      {
        Header: "Name",
        accessor: "autoName",
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => <CurrencyView value={value} />,
      },
      {
        Header: "Created By",
        accessor: "createdBy",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        Cell: ({ value }) => (
          <div className="flex">
            <p>{moment(value).format("MM-DD-YYYY h:mm a")}</p>
          </div>
        ),
      },
      {
        Header: "",
        accessor: "view",
        Cell: ({ row }) => (
          <div className="flex flex-row items-center">
            <div
              role="button"
              onKeyDown={() => {}}
              tabIndex="0"
              onClick={(e) => {
                e.stopPropagation();
                setOpenExpense(row?.original?.reference);
              }}
              className="text-primaryGreen font-medium"
            >
              View
            </div>
          </div>
        ),
      },
    ];
  }, [setOpenExpense]);

  const onCancel = () => {
    setCheckedRow(null);
    setOpenExpense(null);
  };

  const onMerge = () => {
    onMergePendingUpdates();
    setOpenExpense(null);
  };
  return (
    <>
      {!openExpense && (
        <Table
          name="expensePendingQueue"
          columns={tableColumns}
          data={data}
          hideSwitchView
          hideSiteHeader
          onSelectedRowChange={handleRowSelect}
          disableAutoResetSelectedRows
          hideLineNumber
        />
      )}
      {openExpense && (
        <>
          <div className="text-gray-300 font-semibold pt-2">
            {data?.find((item) => item.reference === openExpense)?.createdBy}
          </div>
          <PendingUpdatesBudgetTable
            budgetId={budgetId}
            projectData={projectData}
            // send expense's csi code to filter the data in budget
            initialCsiCode={openExpenseData?.financialCode}
            setCheckedRow={setCheckedRow}
            checkedRow={checkedRow}
          />
          <div className={buttonCN}>
            <TertiaryButton
              title="Cancel"
              onClick={onCancel}
              className={teritiaryButtonCN}
              cancelButton
            />
            <PrimaryButton
              className={primaryButtonCN}
              title="Merge"
              onClick={onMerge}
              disabled={!checkedRow || disableMerge}
            />
          </div>
        </>
      )}
    </>
  );
};

const VendorInvoicesQueue = ({
  data,
  openVendorInvoiceDetails,
  setOpenVendorInvoiceDetails,
  projectId,
  onMergePendingUpdates,
  setisRejectOpen,
  disableMerge,
}) => {
  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: "Vendor",
        accessor: "vendorUser.companyName",
        isPrimary: true,
      },
      {
        Header: "Invoice #",
        accessor: "name",
        Cell: ({ row }) => (
          <div className="flex flex-row items-center">
            {row?.original?.name.split("Vendor Invoice ")[1]}
          </div>
        ),
      },
      // TODO: api support
      {
        Header: "Invoice Amount Due",
        accessor: "currentPaymentDue",
        width: 220,
        Cell: ({ row }) => {
          const currentPaymentDue = Object.keys(
            row?.original?.state?.lineitemToVendorInvoiceInfo || {}
          )?.reduce((acc, key) => {
            return (
              acc +
              row?.original?.state?.lineitemToVendorInvoiceInfo[key]
                ?.currentPaymentDue
            );
          }, 0);
          return <CurrencyView value={currentPaymentDue} />;
        },
      },
      {
        Header: "",
        accessor: "view",
        Cell: ({ row }) => (
          <div className="flex flex-row items-center">
            <div
              role="button"
              onKeyDown={() => {}}
              tabIndex="0"
              onClick={(e) => {
                e.stopPropagation();
                setOpenVendorInvoiceDetails({
                  reference: row?.original?.reference,
                  index: row?.index,
                });
              }}
              className="text-primaryGreen font-medium"
            >
              View
            </div>
          </div>
        ),
      },
    ];
  }, [setOpenVendorInvoiceDetails]);

  const onMerge = () => {
    onMergePendingUpdates();
  };

  const onReject = () => {
    setisRejectOpen(true);
  };

  return (
    <>
      {!openVendorInvoiceDetails && (
        <>
          <Table
            name="vendorInvoicePendingQueue"
            columns={tableColumns}
            data={data}
            hideSwitchView
            hideSiteHeader
            disableAutoResetSelectedRows
            hideLineNumber
            disableSorting
            className="overflow-y-scroll"
          />
        </>
      )}
      {openVendorInvoiceDetails && (
        <>
          <DocumentView
            docRef={openVendorInvoiceDetails?.reference}
            projId={projectId}
            onCancel={() => setOpenVendorInvoiceDetails(null)}
            onBackClick={() => setOpenVendorInvoiceDetails(null)}
            hideSiteHeaderButtons
            hideDocumentActions
            hideFloatingActionButtons
            hideSiteHeader
            backButtonTitle="List View"
          />
          <div className={buttonCN}>
            <TertiaryButton
              title="Reject"
              onClick={onReject}
              className={teritiaryButtonCN}
            />
            <PrimaryButton
              className={primaryButtonCN}
              title="Merge"
              onClick={onMerge}
              disabled={disableMerge}
            />
          </div>
        </>
      )}
    </>
  );
};

const InvoicesQueue = ({
  data,
  openInvoiceDetails,
  setOpenInvoiceDetails,
  projectId,
  onMergePendingUpdates,
}) => {
  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: "Submitted By",
        accessor: "submittedByName",
        isPrimary: true,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "",
        accessor: "view",
        Cell: ({ row }) => (
          <div className="flex flex-row items-center">
            <div
              role="button"
              onKeyDown={() => {}}
              tabIndex="0"
              onClick={(e) => {
                e.stopPropagation();
                setOpenInvoiceDetails({
                  reference: row?.original?.reference,
                  index: row?.index,
                });
              }}
              className="text-primaryGreen font-medium"
            >
              View
            </div>
          </div>
        ),
      },
    ];
  }, [setOpenInvoiceDetails]);

  const onMerge = () => {
    onMergePendingUpdates();
  };

  return (
    <>
      {!openInvoiceDetails && (
        <>
          <Table
            name="invoicePendingQueue"
            columns={tableColumns}
            data={data}
            hideSwitchView
            hideSiteHeader
            disableAutoResetSelectedRows
            hideLineNumber
            disableSorting
            className="overflow-y-scroll"
          />
        </>
      )}
      {openInvoiceDetails && (
        <>
          <DocumentView
            docRef={openInvoiceDetails?.reference}
            projId={projectId}
            onCancel={() => setOpenInvoiceDetails(null)}
            onBackClick={() => setOpenInvoiceDetails(null)}
            hideSiteHeaderButtons
            hideDocumentActions
            hideFloatingActionButtons
            hideSiteHeader
            backButtonTitle="List View"
          />
          <div className={buttonCN}>
            <PrimaryButton
              className={primaryButtonCN}
              title="Merge"
              onClick={onMerge}
            />
          </div>
        </>
      )}
    </>
  );
};

const BudgetTablePendingUpdates = ({
  // eslint-disable-next-line no-unused-vars
  finDocs,
  vendorInvoices,
  invoices,
  expenses,
  timesheets,
  setSelectedTimesheetRows,
  setSelectedDocumentRows,
  selectedDocumentsRows,
  setSelectedExpenseRows,
  selectedExpenseRows,
  projectId,
  openDocumentDetails,
  setOpenDocumentDetails,
  setOpenExpense,
  openExpense,
  openExpenseData,
  budgetId,
  projectData,
  setOpenTimesheet,
  openTimesheet,
  openTimesheetData,
  onMergePendingUpdates,
  onPendingUpdatesQueueClick,
  setCheckedRow,
  checkedRow,
  openVendorInvoiceDetails,
  setOpenVendorInvoiceDetails,
  openInvoiceDetails,
  setOpenInvoiceDetails,
  setisRejectOpen,
  disableMerge,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onTabClick = (index) => {
    setActiveIndex(index);
  };

  const tabs = [
    {
      title: "Financial Documents",
      pill: {
        value: finDocs?.length,
        background: "bg-primaryGreen",
      },
      content: (
        <DocumentsQueue
          data={finDocs}
          setSelectedDocumentRows={setSelectedDocumentRows}
          selectedDocumentsRows={selectedDocumentsRows}
          projectId={projectId}
          openDocumentDetails={openDocumentDetails}
          setOpenDocumentDetails={setOpenDocumentDetails}
          onMergePendingUpdates={onMergePendingUpdates}
          onPendingUpdatesQueueClick={onPendingUpdatesQueueClick}
          disableMerge={disableMerge}
        />
      ),
    },
    {
      title: "Timesheets",
      pill: {
        value: timesheets?.length,
        background: "bg-primaryGreen",
      },
      content: (
        <TimesheetQueue
          data={timesheets}
          setSelectedTimesheetRows={setSelectedTimesheetRows}
          budgetId={budgetId}
          projectData={projectData}
          setOpenTimesheet={setOpenTimesheet}
          openTimesheet={openTimesheet}
          openTimesheetData={openTimesheetData}
          setCheckedRow={setCheckedRow}
          checkedRow={checkedRow}
          onMergePendingUpdates={onMergePendingUpdates}
          disableMerge={disableMerge}
        />
      ),
    },
    {
      title: "Expenses",
      pill: {
        value: expenses?.length,
        background: "bg-primaryGreen",
      },
      content: (
        <ExpenseQueue
          data={expenses}
          setSelectedExpenseRows={setSelectedExpenseRows}
          selectedExpenseRows={selectedExpenseRows}
          budgetId={budgetId}
          projectData={projectData}
          setOpenExpense={setOpenExpense}
          openExpense={openExpense}
          openExpenseData={openExpenseData}
          setCheckedRow={setCheckedRow}
          checkedRow={checkedRow}
          onMergePendingUpdates={onMergePendingUpdates}
          disableMerge={disableMerge}
        />
      ),
    },
    {
      title: "Vendor Invoices",
      pill: {
        value: vendorInvoices?.length,
        background: "bg-primaryGreen",
      },
      content: (
        <VendorInvoicesQueue
          data={vendorInvoices}
          openVendorInvoiceDetails={openVendorInvoiceDetails}
          setOpenVendorInvoiceDetails={setOpenVendorInvoiceDetails}
          projectId={projectId}
          onMergePendingUpdates={onMergePendingUpdates}
          setisRejectOpen={setisRejectOpen}
          disableMerge={disableMerge}
        />
      ),
    },
    {
      title: "Invoice",
      pill: {
        value: invoices?.length,
        background: "bg-primaryGreen",
      },
      content: (
        <InvoicesQueue
          data={invoices}
          openInvoiceDetails={openInvoiceDetails}
          setOpenInvoiceDetails={setOpenInvoiceDetails}
          projectId={projectId}
          onMergePendingUpdates={onMergePendingUpdates}
        />
      ),
    },
  ];

  return (
    <TabbedContainer
      tabs={tabs}
      activeIndex={activeIndex}
      onTabClick={onTabClick}
    />
  );
};

BudgetTablePendingUpdates.propTypes = {
  vendorInvoices: PropTypes.arrayOf(PropTypes.shape({})),
  invoices: PropTypes.arrayOf(PropTypes.shape({})),
  finDocs: PropTypes.arrayOf(PropTypes.shape({})),
  expenses: PropTypes.arrayOf(PropTypes.shape({})),
  timesheets: PropTypes.arrayOf(PropTypes.shape({})),
  setSelectedTimesheetRows: PropTypes.func,
  setSelectedDocumentRows: PropTypes.func,
  setSelectedExpenseRows: PropTypes.func,
  selectedExpenseRows: PropTypes.arrayOf(PropTypes.string),
  projectId: PropTypes.string,
  openDocumentDetails: PropTypes.string,
  setOpenDocumentDetails: PropTypes.func,
  openExpense: PropTypes.string,
  openExpenseData: PropTypes.shape({}),
  openTimesheetData: PropTypes.shape({}),
  setOpenExpense: PropTypes.func,
  budgetId: PropTypes.string,
  projectData: PropTypes.shape({
    buildings: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        spaces: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.string,
            level: PropTypes.string,
          })
        ),
      })
    ),
    members: PropTypes.arrayOf(
      PropTypes.shape({
        user: PropTypes.string,
        position: PropTypes.string,
      })
    ),
    contractualFee: PropTypes.shape({
      feeType: PropTypes.string,
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    contractualInsurance: PropTypes.shape({
      insuranceType: PropTypes.string,
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
  setOpenTimesheet: PropTypes.func,
  openTimesheet: PropTypes.string,
  onMergePendingUpdates: PropTypes.func,
  onPendingUpdatesQueueClick: PropTypes.func,
  selectedDocumentsRows: PropTypes.arrayOf(PropTypes.string),
  setCheckedRow: PropTypes.func,
  checkedRow: PropTypes.string,
  setOpenVendorInvoiceDetails: PropTypes.func,
  openVendorInvoiceDetails: PropTypes.string,
  setisRejectOpen: PropTypes.func,
  setOpenInvoiceDetails: PropTypes.func,
  openInvoiceDetails: PropTypes.string,
  disableMerge: PropTypes.bool,
};

BudgetTablePendingUpdates.defaultProps = {
  finDocs: [],
  expenses: [],
  vendorInvoices: [],
  invoices: [],
  timesheets: [],
  setSelectedTimesheetRows: undefined,
  setSelectedDocumentRows: undefined,
  setSelectedExpenseRows: undefined,
  selectedExpenseRows: [],
  projectId: undefined,
  openDocumentDetails: undefined,
  setOpenDocumentDetails: undefined,
  openExpense: undefined,
  openExpenseData: undefined,
  openTimesheetData: undefined,
  setOpenExpense: undefined,
  budgetId: undefined,
  projectData: undefined,
  setOpenTimesheet: undefined,
  openTimesheet: undefined,
  onMergePendingUpdates: undefined,
  onPendingUpdatesQueueClick: undefined,
  selectedDocumentsRows: [],
  setCheckedRow: undefined,
  checkedRow: undefined,
  openVendorInvoiceDetails: undefined,
  setOpenVendorInvoiceDetails: undefined,
  setisRejectOpen: undefined,
  openInvoiceDetails: undefined,
  setOpenInvoiceDetails: undefined,
  disableMerge: false,
};
export default BudgetTablePendingUpdates;

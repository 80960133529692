import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import DropdownOptionCheckbox from "../DropdownOptionCheckbox";
import dropdownBaseStyle from "../../../styles/dropdowns/dropdownBaseStyle";
import useDropdownWithCheckboxData from "./useDropdownWithCheckboxData";

const DropdownWithCheckbox = ({
  options,
  value,
  isMulti,
  setValue,
  menuPlacement,
  isDisabled,
  placeholder,
  rtl,
  disableAllOption,
  ...selectProps // react-select props
}) => {
  const { isOptionSelected, getOptions, getValue, handleSetValue } =
    useDropdownWithCheckboxData({
      options,
      value,
      isMulti,
      setValue,
      disableAllOption,
    });

  return (
    <Select
      isOptionSelected={isOptionSelected}
      options={getOptions()}
      onChange={handleSetValue}
      value={getValue()}
      closeMenuOnSelect={!isMulti}
      hideSelectedOptions={false}
      placeholder={placeholder}
      menuPlacement={menuPlacement}
      isMulti={isMulti}
      isDisabled={isDisabled}
      components={{
        Option: (props) => <DropdownOptionCheckbox rtl={rtl} {...props} />,
      }}
      styles={dropdownBaseStyle}
      {...selectProps}
    />
  );
};

DropdownWithCheckbox.defaultProps = {
  options: [],
  menuPlacement: "top",
  value: undefined,
  isMulti: false,
  isDisabled: false,
  rtl: true,
  isLoading: false,
  disableAllOption: false,
  placeholder: "Select",
  setValue: () => {},
};

DropdownWithCheckbox.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  value: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  menuPlacement: PropTypes.oneOf(["top", "bottom", "auto"]),
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  rtl: PropTypes.bool,
  isLoading: PropTypes.bool,
  disableAllOption: PropTypes.bool,
  placeholder: PropTypes.string,
  setValue: PropTypes.func,
};

export default DropdownWithCheckbox;

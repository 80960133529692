import moment from "moment";
import { DATE_RANGES_OPTIONS } from "../../constants";

/**
 * Formats a date range based on the provided options or custom dates.
 * @param {Object} dateRange  Object containing date range information
 * @returns {string} date range value from option or custom date in human readable
 */
const formatDateRange = (dateRange) => {
  const { option, custom } = dateRange ?? {};

  if (dateRange.option === "custom") {
    return `${moment(custom?.start).format("MMMM DD, YYYY")} to ${moment(
      custom?.end
    ).format("MMMM DD, YYYY")}`;
  }

  return (
    DATE_RANGES_OPTIONS.find(({ value }) => value === option)?.label ??
    "Unspecified"
  );
};

export default formatDateRange;

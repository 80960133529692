import PropTypes from "prop-types";
import React, { useMemo } from "react";
import {
  FILTER_TYPES,
  SHOW_COLUMNS_SETTING_RIGHT,
  TABLE_COLUMN_WIDTH,
} from "../../../constants";
import CreateSpaceModal from "../Spaces/index";
import Table from "../Table/Table";
import CardGrid from "../CardGrid/CardGrid";
import TableActionsIconsGroup from "../Table/TableActionsIconsGroup";
import ImageCell from "../Table/ImageCell";
import DeleteModal from "../DeleteModal/DeleteModal";
import useSpacesListViewData from "./useSpacesListViewData";
import Widget from "../Widget/Widget";
import TagList from "../Tags/TagList";

const groupOptions = {
  // ordered list of grouped column options
  hierarchicalOptions: [],
  // un-ordered list of grouped column options
  nonHierarchicalOptions: [
    {
      label: "Type",
      value: "type",
    },
    {
      label: "Level",
      value: "level",
    },
    {
      label: "Tags",
      value: "tags",
    },
  ],
};

const SpacesList = ({ setActions }) => {
  const {
    handleCreate,
    onRowClick,
    selectedRows,
    handleRowSelect,
    handleDeleteSpaces,
    templateSettings,
    deleteUserTemplateSettings,
    updateUserTemplateSettings,
    tableData,
    showDeleteModal,
    setShowDeleteModal,
    hasWritePermission,
    isShowingSpacesTable,
    modals,
    spaceConfiguration,
    isLoadingManagement,
    isLoading,
    isFetching,
    tagsData,
    canCreateType,
  } = useSpacesListViewData({ setActions });

  const tableColumns = React.useMemo(() => {
    const columns = [
      {
        Header: "Image",
        accessor: "image",
        Cell: ({ value }) => <ImageCell src={value} circled />,
        Edit: ({ value }) => <ImageCell src={value} circled />,
        width: TABLE_COLUMN_WIDTH,
        minWidth: 90,
        maxWidth: TABLE_COLUMN_WIDTH,
        isPrimary: true,
        disableSortBy: true,
        disableResizing: true,
        disableFilters: true,
        Aggregated: () => null,
      },
      {
        Header: "Name",
        accessor: "name",
        isPrimary: true,
        width: 200,
      },
      {
        Header: "Type",
        accessor: "type",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
        width: 200,
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ value }) => {
          const tags = value?.map((tagRef) => ({
            label: tagsData?.tagsDict?.[tagRef]?.label,
            value: tagsData?.tagsDict?.[tagRef]?.reference,
          }));
          return (
            <TagList
              tags={tags}
              tagClassName="py-1 px-2"
              className="gap-2"
              showCount
            />
          );
        },
        minWidth: 250,
        filterOptions: {
          label: "Tags",
          filterType: FILTER_TYPES.tags,
        },
      },
      {
        Header: "Level",
        accessor: "level",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
        width: 200,
      },
    ];

    return columns;
  }, [tagsData?.tagsDict]);

  return (
    <Widget
      loading={isLoading || isFetching || isLoadingManagement}
      title={null}
      draggable={false}
      overflow
    >
      <Table
        addNestedRoute="/spaces"
        resourceName="Spaces"
        columns={tableColumns}
        cardGrid={!isShowingSpacesTable}
        cardGridComponent={(rows) => {
          return (
            <CardGrid
              resourceName="Space"
              rows={rows}
              onClick={onRowClick}
              detailLabel="Level"
            />
          );
        }}
        data={useMemo(() => tableData, [tableData])}
        defaultSort={[{ id: "name", desc: false }]}
        onRowClick={({ index }) => onRowClick(tableData[index]?.id)}
        handleRowSelect={handleRowSelect}
        allowSelection
        groupOptions={groupOptions}
        templateSettings={templateSettings}
        updateUserTemplateSettings={updateUserTemplateSettings}
        deleteUserTemplateSettings={deleteUserTemplateSettings}
        showConstantRowIndex
        showColumnSettingsLeft={!SHOW_COLUMNS_SETTING_RIGHT}
        fileActionsIcons={
          <TableActionsIconsGroup
            style={{ height: "100%" }}
            handleShowDelete={() => {
              setShowDeleteModal(true);
            }}
            canDelete={isShowingSpacesTable && hasWritePermission}
            disabled={selectedRows?.length === 0}
            showColumnSettingsRight={
              SHOW_COLUMNS_SETTING_RIGHT && isShowingSpacesTable
            }
            selectedRows={selectedRows}
            disabledColumnSettings={!isShowingSpacesTable}
          />
        }
        onSelectedRowChange={handleRowSelect}
        hideSiteHeaderTitle={false}
        hideSiteHeaderActionButtons={false}
      />
      <CreateSpaceModal
        isOpen={modals.create}
        onClose={() => modals.set("create", false)}
        onCreate={handleCreate}
        configuration={spaceConfiguration}
        canCreateType={canCreateType}
      />
      <DeleteModal
        isOpen={showDeleteModal}
        onDelete={handleDeleteSpaces}
        onClose={() => setShowDeleteModal(false)}
        title={selectedRows?.length > 1 ? "Delete spaces" : "Delete space"}
        text={`Are you sure that you want to delete the selected ${
          selectedRows?.length > 1 ? "spaces" : "space"
        }? Once deleted, the ${
          selectedRows?.length > 1 ? "spaces" : "space"
        } cannot be recovered.`}
      />
    </Widget>
  );
};

SpacesList.propTypes = {
  setActions: PropTypes.func,
};

SpacesList.defaultProps = {
  setActions: undefined,
};

export default SpacesList;

// Framework Tools
import React, { useState, useCallback, useEffect } from "react";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import cntl from "cntl";

// Helpers
import { useQueryClient } from "react-query";
import { downloadMedia, uploadFileWithData } from "../../../../helpers/File";

// Components
import SimpleFileUpLoad from "../../AssetForms/SimpleFileUpLoad";
import ReuqestWorkflowShortAttachments from "./RequestWorkflowShortAttachments";

// images & icons
import whiteCrossIcon from "../../../assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../../assets/images/whiteExclamationIcon.svg";
import whiteCircleCheckIcon from "../../../assets/images/circleCheckIcon.svg";
import { filePaginatedKeys } from "../../../../config/reactQuery/queryKeyFactory";

const sidebarCN = (sidebarOpen) => cntl`
${sidebarOpen && "flex"}
${
  !sidebarOpen &&
  "flex flex-col items-start justify-center w-full col-span-2 pl-4 pb-4 pr-4"
}`;

const toastCloseIcon = <img src={whiteCrossIcon} alt="Close notice" />;
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;
const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;

export default function RequestWorkflowAttachmentsUploadCell({
  requestData,
  allSteps,
  step,
  patchSubmittal,
  isSubmittal,
  attachmentMap,
  setAttachmentMap,
  index,
  setLoading,
  disabled,
  activeStep,
  sidebarOpen,
}) {
  const queryClient = useQueryClient();
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [allFiles, setAllFiles] = useState([]);

  useEffect(() => {
    const files = [...step?.attachments, ...filesToUpload];
    setAllFiles(files);
  }, [step?.attachments, filesToUpload]);

  const onAddFile = async (doc, data = {}, progressCallback) => {
    const fileRef = await uploadFileWithData(doc, data, progressCallback);
    return fileRef;
  };

  const onUpload = useCallback(async (files, progressCallback) => {
    const handleProgressCallback = (loaded, total, filename) => {
      progressCallback(loaded, total, filename);
    };

    const uploadResult = await Promise.all(
      files.map(async ({ name, docType, isFavorited, original }) => {
        const data = {
          name,
          docType,
          isFavorited,
          contentType: original?.type,
          size: original?.size,
        };
        const res = await onAddFile(original, data, (loaded, total) =>
          handleProgressCallback(loaded, total, name)
        );

        return { ...data, reference: res };
      })
    );

    return uploadResult;
  }, []);

  const handleFilesUpdated = (updatedFiles) => {
    setFilesToUpload(updatedFiles);
  };

  const handleFilesUploaded = useCallback(
    async (file) => {
      const res = await onUpload(file, () => {});

      // update files in overview
      queryClient.invalidateQueries(filePaginatedKeys.allFiles);

      return res;
    },
    [onUpload, queryClient]
  );

  const handleUploadAttachments = useCallback(
    async (files) => {
      const prevRequestData = cloneDeep(requestData);
      const prevAttachments = step?.attachments;
      const fileRefs = files.map((file) => file?.reference);
      const newAttachments = [...prevAttachments, ...fileRefs];
      const newStep = { ...step, attachments: newAttachments };
      allSteps?.splice(index, 1, newStep);
      const newRequestData = requestData;
      if (isSubmittal) {
        try {
          const res = await patchSubmittal(newRequestData, prevRequestData);
          return res;
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      }
      return {};
    },
    [
      allSteps,
      index,
      isSubmittal,
      patchSubmittal,
      requestData,
      setLoading,
      step,
    ]
  );
  const handleFilesAdded = React.useCallback(
    async (addedFile) => {
      const savingToast = toast("Uploading...", {
        isLoading: true,
        position: "top-center",
      });
      try {
        const res = await handleFilesUploaded(addedFile);
        const fileData = res?.[0];
        const fileRef = res?.[0]?.reference;
        setAttachmentMap((prev) => {
          const fileMap = { ...prev };
          fileMap[fileRef] = fileData;
          return fileMap;
        });
        await handleUploadAttachments(res);
        toast.update(savingToast, {
          isLoading: false,
          render: `Successfully Uploaded ${addedFile[0].name}`,
          closeButton: toastCloseIcon,
          className: "bg-brandGreen text-white",
          hideProgressBar: true,
          position: "top-center",
          icon: toastIcon,
          autoClose: 3000,
        });
      } catch (e) {
        console.error(e);
        toast.update(savingToast, {
          isLoading: false,
          render: `Failed To Upload ${addedFile[0].name}, plese try again.`,
          closeButton: toastCloseIcon,
          className: "bg-brandGreen text-white",
          hideProgressBar: true,
          position: "top-center",
          icon: toastErrorIcon,
          autoClose: 3000,
        });
      }
    },
    [handleFilesUploaded, handleUploadAttachments, setAttachmentMap]
  );

  useEffect(() => {
    if (filesToUpload.length > 0) {
      handleFilesUploaded().then((res) => {
        if (res.length > 0) {
          handleUploadAttachments().then(toast());
        }
      });
    }
  }, [
    allSteps,
    filesToUpload,
    handleFilesUploaded,
    handleUploadAttachments,
    index,
    isSubmittal,
    patchSubmittal,
    requestData,
    setLoading,
    step,
  ]);

  const handleFileDownload = async (doc) => {
    if (doc) {
      await downloadMedia([doc]);
    }
    return null;
  };

  const handleFileClick = async (file) => {
    handleFileDownload([file.reference]);
  };

  const handleRemoveFile = async (attachment) => {
    const savingToast = toast("Removing File...", {
      isLoading: true,
      position: "top-center",
    });
    const prevRequestData = cloneDeep(requestData);
    const prevAttachments = step?.attachments;
    const filtered = prevAttachments?.filter(
      (item) => item !== attachment.reference
    );
    const newStep = { ...step, attachments: filtered };
    allSteps?.splice(index, 1, newStep);
    const newRequestData = requestData;
    if (isSubmittal) {
      try {
        const res = await patchSubmittal(newRequestData, prevRequestData);
        toast.update(savingToast, {
          isLoading: false,
          render: `Successfully Removed ${attachment.name}`,
          closeButton: toastCloseIcon,
          className: "bg-brandGreen text-white",
          hideProgressBar: true,
          position: "top-center",
          icon: toastIcon,
          autoClose: 3000,
        });

        return res;
      } catch (e) {
        toast.update(savingToast, {
          isLoading: false,
          render: `Failed to remove file plese try again.`,
          closeButton: toastCloseIcon,
          className: "bg-brandGreen text-white",
          hideProgressBar: true,
          position: "top-center",
          icon: toastErrorIcon,
          autoClose: 3000,
        });
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
    return {};
  };

  return (
    <div className="flex flex-col ml-4">
      {/* Seciton 1 Upload button */}
      <div className={sidebarCN(sidebarOpen)}>
        {!disabled && activeStep && (
          <>
            <SimpleFileUpLoad
              disabled={disabled}
              files={filesToUpload}
              onFilesAdded={(file) => handleFilesAdded(file)}
              onFilesUpdated={handleFilesUpdated}
              onFilesUploaded={handleFilesUploaded}
              customUploadAreaStyle={{
                marginRight: "0px",
                marginButton: "0px",
              }}
              simple
            />
          </>
        )}
        {!activeStep && allFiles?.length === 0 && (
          <p className="text-xs">No Attachments</p>
        )}
      </div>
      {/* section 2 uploadedFiles and files to be upload */}
      <ReuqestWorkflowShortAttachments
        handleRemoveFile={handleRemoveFile}
        activeStep={activeStep}
        step={step}
        stepAttachments={step?.attachments}
        attachmentMap={attachmentMap}
        handleFileClick={disabled ? () => {} : (val) => handleFileClick(val)}
        filesToUpload={filesToUpload}
      />
    </div>
  );
}

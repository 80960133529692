import { CommentAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useCallback } from "react";
import { commentsKeys } from "../config/reactQuery/queryKeyFactory";
import { useAppState } from "../state/appState";
import { toastError } from "../stories/Components/Toast/Toast";

const getComments = async (association) => {
  if (!association) return [];

  const params = {
    association,
  };

  const { data } = await CommentAPI.get({ params });
  return data;
};

const postComment = async (payload) => {
  const data = await CommentAPI.post(payload);
  return data;
};

const postReplyComment = async ({ commentId, payload }) => {
  const data = await CommentAPI.postWOP(`${commentId}/$addreply`, {
    ...payload,
  });

  return data;
};

/**
 * Query hook to fetch all comments by association
 * @param {string} association reference for any resource: Ex: Submital/111-222-333
 * @param {Function} select custom function to handle results
 */
export const useComments = (association, select, enablePing) => {
  const [{ currentUser }] = useAppState();

  const queryKey = commentsKeys.commentsByAssociation(association);

  return useQuery({
    queryKey,
    queryFn: () => getComments(association),
    enabled: !!currentUser && !!association,
    select,
    placeholderData: [],
    refetchInterval: enablePing ? 10000 : false,
    onError: (error) => {
      toastError(
        "An error occurred while fetching Comments, please try again later."
      );
      console.error("useComments", error);
    },
  });
};

/**
 * Query selector hook to format comments with user/author information
 * @param {string} association reference for any resource: Ex: Submital/111-222-333
 * @param {Object} userDict users object
 */
export const useFormattedComments = (association, userDict, enablePing) => {
  const selector = useCallback(
    (comments = []) =>
      comments.map((comment) => {
        const user = userDict?.[comment.author];

        return {
          ...comment,
          replies: selector(comment?.replies ?? []),
          author: {
            avatar: user?.avatar,
            name: `${user?.name?.firstName} ${user?.name?.lastName?.charAt(
              0
            )}.`,
            reference: user?.reference,
          },
        };
      }),
    [userDict]
  );

  return useComments(association, selector, enablePing);
};

/**
 * Query selector hook to format comments with user/author information
 * @param {string} association reference for any resource: Ex: Submital/111-222-333
 * @param {Object} userDict users object
 */
export const useSubmittalComments = (association, enablePing) => {
  const selector = (comments = []) =>
    comments.reduce((acc, comment) => {
      const id = comment.reference;
      acc[id] = comment;
      return acc;
    }, {});

  return useComments(association, selector, enablePing);
};

/**
 * Query selector hook to format comments with user/author information
 * @param {string} association reference for any resource: Ex: Workflow/111-222-333
 * @param {Object} userDict users object
 */
export const useWorkflowComments = (association, enablePing) => {
  const selector = (comments = []) =>
    comments.reduce((acc, comment) => {
      const id = comment.reference;
      acc[id] = comment;
      return acc;
    }, {});

  return useComments(association, selector, enablePing);
};

/**
 * Query selector hook to format comments with user/author information
 * @param {string} association reference for any resource: Ex: Workflow/111-222-333
 * @param {Object} userDict users object
 */
export const useTaskComments = (association, enablePing) => {
  const selector = (comments = []) =>
    comments.reduce((acc, comment) => {
      const id = comment.reference;
      acc[id] = comment;
      return acc;
    }, {});

  return useComments(association, selector, enablePing);
};

/**
 * Query mutation to post new comment
 * @returns mutation hook
 */
export const usePostComment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postComment,
    onSuccess: ({ data }) => {
      queryClient.setQueryData(
        commentsKeys.commentsByAssociation(data.association),
        (current) => {
          return [...current, data];
        }
      );
    },
    onError: (error) => {
      toastError("Something failed while posting coment");
      console.error("usePostComment", error);
    },
  });
};

/**
 * Query mutation to reply a comment
 * @returns mutation hook
 */
export const usePostCommentReply = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postReplyComment,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries(
        commentsKeys.commentsByAssociation(data.association)
      );
    },
    onError: (error) => {
      toastError("Something failed while posting coment");
      console.error("usePostCommentReply", error);
    },
  });
};

import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Mention, MentionsInput } from "react-mentions";
import Avatar from "../Message/Avatar";
import { mentionInputStyle, mentionsStyle } from "./style";
import useInputMentionsData from "./useInputMentionsData";

const InputMentions = (
  {
    data,
    placeholder,
    text,
    onTextChange,
    onKeyPress,
    className,
    trigger,
    ...props
  },
  ref
) => {
  const { setUserNameWithTrigger, getUserAvatar } = useInputMentionsData({
    trigger,
  });

  return (
    <MentionsInput
      inputRef={ref}
      className="border border-gray-light rounded-md text-sm bg-green-50"
      style={mentionInputStyle}
      placeholder={placeholder}
      value={text}
      onChange={(e) => onTextChange(e.target.value)}
      onKeyPress={onKeyPress}
      allowSuggestionsAboveCursor
      {...props}
    >
      <Mention
        trigger={trigger}
        appendSpaceOnAdd
        markup="{{__id__}}"
        displayTransform={setUserNameWithTrigger}
        data={data}
        style={mentionsStyle}
        renderSuggestion={({ id }, _, highlightedDisplay) => (
          <div className="flex items-center gap-2">
            <Avatar avatar={getUserAvatar(id)} isExternalUser />
            {highlightedDisplay}
          </div>
        )}
      />
    </MentionsInput>
  );
};

InputMentions.defaultProps = {
  data: [],
  placeholder: undefined,
  text: undefined,
  onTextChange: undefined,
  onKeyPress: undefined,
  className: undefined,
  trigger: "@",
};

InputMentions.propTypes = {
  /** Array to display as suggestions */
  data: PropTypes.arrayOf({
    id: PropTypes.string,
    display: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  text: PropTypes.string,
  onTextChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  className: PropTypes.string,

  /** Trigger to enable suggestion */
  trigger: PropTypes.string,
};

export default forwardRef(InputMentions);

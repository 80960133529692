import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import PureFinancialOverview from "./PureFinancialOverview";
import { CALENDAR_PATH } from "../../../constants";

const FinancialOverview = ({
  project,
  dispatch,
  editable,
  isEditing,
  readable,
  documentsSettings,
  financialsSettings,
}) => {
  const history = useHistory();
  useEffect(() => {
    if (typeof readable === "boolean" && !readable) {
      history.replace(CALENDAR_PATH);
    }
  }, [readable, history]);
  return (
    <>
      <PureFinancialOverview
        project={project}
        dispatch={dispatch}
        editable={editable}
        isEditing={isEditing}
        readable={readable}
        financialsSettings={financialsSettings}
        documentsSettings={documentsSettings}
      />
    </>
  );
};

FinancialOverview.propTypes = {
  project: PropTypes.shape({
    retainage: PropTypes.arrayOf(
      PropTypes.shape({
        percentComplete: PropTypes.string,
        percentRetainage: PropTypes.string,
      })
    ),
  }),
  financialsSettings: PropTypes.shape({}),
  documentsSettings: PropTypes.shape({}),
  dispatch: PropTypes.func,
  editable: PropTypes.bool,
  isEditing: PropTypes.bool,
  readable: PropTypes.bool,
};

FinancialOverview.defaultProps = {
  project: undefined,
  dispatch: undefined,
  editable: false,
  isEditing: false,
  readable: undefined,
  financialsSettings: undefined,
  documentsSettings: undefined,
};

export default FinancialOverview;

import Api from "@griffingroupglobal/eslib-api";
import { useAppState } from "../state/appState";
import { taskKeys } from "../config/reactQuery/queryKeyFactory";
import { toastError } from "../stories/Components/Toast/Toast";
import useAuthenticatedInfiniteQuery from "./useAuthenticatedInfiniteQuery";

const fetchHistory = async (pageParam, association) => {
  const { data } = await Api.get("/api/History", {
    paging: false,
    params: {
      association,
      limit: 10,
      page: pageParam,
    },
  });

  return {
    data: data.entries.map(({ resource }) => resource),
    page: data.page,
    pages: data.pages,
  };
};

const useTaskHistory = (association) => {
  const [{ currentUser }] = useAppState();

  const queryKey = taskKeys.taskHistory(association);

  return useAuthenticatedInfiniteQuery({
    queryKey,
    enabled: !!currentUser && !!association,
    queryFn: ({ pageParam = 1 }) => fetchHistory(pageParam, association),
    getNextPageParam: (lastPageData) => {
      const nextPage =
        lastPageData.page < lastPageData.pages
          ? lastPageData.page + 1
          : undefined;

      return nextPage;
    },
    onError: (error) => {
      toastError("Something failed while getting Task history");
      console.error("useTaskHistory", error);
    },
  });
};

export default useTaskHistory;

import { SopAPI } from "@griffingroupglobal/eslib-api";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import sopsKeys from "./sopKeys";
import getCategory from "../../helpers/Sop/getCategory";
import useSystemConfiguration from "../useSystemConfiguration";
import {
  configurationKeys,
  sopKeys,
  tagKeys,
} from "../../config/reactQuery/queryKeyFactory";
import { useAppState } from "../../state/appState";
import { SET_SOPS, SET_SOPS_DICT } from "../../constants";

const useSopClone = () => {
  // TODO (RQ-Perform) - Remove SOPs from AppState
  const [, dispatch] = useAppState();

  const queryClient = useQueryClient();
  const { data: systemConfiguration } = useSystemConfiguration();

  /**
   * Api call to clone a Sop
   */
  const cloneSopApiCall = useCallback(async (id) => {
    const { data } = await SopAPI.getWOP(`${id}/$clone`);
    return data;
  }, []);

  const { isLoading, mutateAsync } = useMutation({
    mutationFn: cloneSopApiCall,
    onSuccess: (data) => {
      queryClient.setQueryData(sopsKeys.byId(data.id), data);

      const sopCategories = systemConfiguration?.system?.sop?.category;

      const [category] = getCategory(sopCategories, data.category);

      const sops = queryClient.getQueryData(sopKeys.sops);
      const sopsData = [{ ...data, category }, ...sops];

      dispatch({
        type: SET_SOPS,
        sops: sopsData,
        sopSelectOptions:
          sopsData?.map((sop) => ({
            label: sop.name,
            value: sop.name,
            name: sop.name,
            sop: sop.reference,
          })) ?? [],
      });

      const sopsDict = sopsData.reduce(
        (acc, sop) => ({ ...acc, [sop.reference]: sop }),
        {}
      );

      dispatch({
        type: SET_SOPS_DICT,
        sopsDict,
      });

      // Update tags in S&C
      queryClient.invalidateQueries(tagKeys.tags);

      // Invalidate system settings to update sopcategories counter
      queryClient.invalidateQueries(configurationKeys("system"));
    },
    onError: (error) => {
      console.error("useSopClone", error);
    },
    onSettled: () => {
      queryClient.invalidateQueries(sopsKeys.all);
    },
  });

  /**
   * Clone given Sop by id
   * @param {String} id - Sop Id
   * @returns Promise<Object> - New cloned sop
   */
  const cloneSop = (id) => mutateAsync(id);

  return { isLoading, cloneSop };
};

export default useSopClone;

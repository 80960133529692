import { cloneDeep } from "lodash";
import { useReducer } from "react";

const defaultState = {};

const reducer = (tag, action) => {
  switch (action.type) {
    case "reset": {
      return (
        cloneDeep({
          ...action.tag,
          currentOpenLabel: action.tag?.currentLabel,
        }) ?? defaultState
      );
    }
    case "setCurrentOpenLabel": {
      return { ...tag, currentOpenLabel: action.currentOpenLabel };
    }
    default:
      return defaultState;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};

/* eslint-disable no-param-reassign */
import * as API from "@griffingroupglobal/eslib-api";
import React, { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import queryClient from "../config/reactQuery/queryClient";
import { AUTH_TOKEN_STORAGE_KEY } from "../constants";
import { toastError, toastMessage } from "../helpers/Toast";
import whiteCircleCheckIcon from "../stories/assets/images/circleCheckIcon.svg";
import whiteCrossIcon from "../stories/assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../stories/assets/images/whiteExclamationIcon.svg";
import useManagementConfiguration from "./useManagementConfiguration";
import { getSpaceConfiguration } from "../helpers/Formatters";
import { propertyKeys } from "./properties";
import { projectKeys } from "./projects";
import spacesKeys from "./Spaces/spacesKeys";
import taskKeys from "./tasks/taskKeys";
import calendarKeys from "./calendar/calendarKeys";
import { filePaginatedKeys } from "../config/reactQuery/queryKeyFactory";

const toastCloseIcon = <img src={whiteCrossIcon} alt="Close notice" />;
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;
const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;

const formatFootage = (value) =>
  typeof value === "string" || typeof value === "number"
    ? `${parseInt(value, 10)} sqft`
    : "";

// TODO (RQ-Perf) Rewrite this hook using the new pattern
export default ({ id, ref: parentRef, api, spaceId: selectedSpace }) => {
  const [postData, setPostData] = useState({});

  const { data: managementConfiguration } = useManagementConfiguration();

  const authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  const keys = spacesKeys.byAssociation(parentRef);

  const spaceConfiguration = useMemo(
    () => getSpaceConfiguration(managementConfiguration),
    [managementConfiguration]
  );
  /**
   * Actions
   */
  const fetchSpaceResources = useCallback(async () => {
    let parentResource;

    if (!parentResource && id) {
      try {
        const { data: fetchedParent } = await API[api].getById(id);
        parentResource = fetchedParent;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(`Parent resource ${parentRef} not found for space`);
      }
    }

    const data = (parentResource?.spaces ?? []).reduce(
      (acc, item) => {
        const resolvedSpace = {
          ...item,
          footage: formatFootage(item?.area?.value),
          parent: parentResource?.id,
        };
        acc.spaces.push(resolvedSpace);
        acc.spacesDict[item?.id] = resolvedSpace;
        return acc;
      },
      {
        spaces: [],
        spacesDict: {},
      }
    );

    return {
      ...data,
      spaceConfiguration,
      parent: parentResource,
    };
  }, [parentRef, id, spaceConfiguration, api]);

  /**
   * Get parent association resource from RQ cache (Properties) or AppState (Projects)
   * @returns
   */
  const getParentAssociationFromCache = useCallback(() => {
    let parentResource;

    if (parentRef?.startsWith("Property")) {
      const propertyId = parentRef.split("/")[1];
      parentResource = queryClient.getQueryData(propertyKeys.byId(propertyId));
    } else if (parentRef?.startsWith("Project")) {
      const projectId = parentRef.split("/")[1];
      parentResource = queryClient.getQueryData(projectKeys.byId(projectId));
    }

    return parentResource;
  }, [parentRef]);

  /**
   * Update a given property/project in the RQ cache
   * @param {Object} updatedResource - New property/project from patch result
   */
  const updateCache = (updatedResource) => {
    if (parentRef?.startsWith("Property")) {
      const propertyId = parentRef.split("/")[1];
      const queryKey = propertyKeys.byId(propertyId);

      queryClient.setQueryData(queryKey, updatedResource);
    } else if (parentRef?.startsWith("Project")) {
      const projectId = parentRef.split("/")[1];
      const queryKey = projectKeys.byId(projectId);

      queryClient.setQueryData(queryKey, updatedResource);
    }
  };

  const postSpace = async (space) => {
    const parentResource = getParentAssociationFromCache();
    const { spaces = [] } = parentResource;
    return API[api]
      .patch(
        id,
        { ...parentResource, spaces: [...spaces, space] },
        parentResource
      )
      .then(({ data }) => {
        setPostData({ data, id: data.reference });
        updateCache(data);
        return { data, id: data.reference };
      })
      .catch(() =>
        toastError(
          `Error creating space, try again. If error persists try refreshing the page`,
          toastErrorIcon,
          toastCloseIcon
        )
      );
  };

  const patchSpace = async (space, throwError) => {
    const parentResource = getParentAssociationFromCache();
    const { spaces = [] } = parentResource;

    return API[api]
      .patch(
        id,
        {
          ...parentResource,
          spaces: spaces.map((item) => (item.id === space.id ? space : item)),
        },
        parentResource
      )
      .then(({ data }) => {
        updateCache(data);
        return { data, id: data.reference };
      })
      .catch((e) => {
        toastError(
          `Error updating space, try again. If error persists try refreshing the page`,
          toastErrorIcon,
          toastCloseIcon
        );

        if (throwError) {
          throw e;
        }
      });
  };

  const removeSpace = async (space) => {
    const parentResource = getParentAssociationFromCache();
    const { spaces = [] } = parentResource;

    const newSpaces = [...spaces];

    const indexToRemove = newSpaces.findIndex(
      (newSpace) => newSpace.id === space.id
    );

    newSpaces.splice(indexToRemove, 1);

    return API[api]
      .patch(
        id,
        {
          ...parentResource,
          spaces: newSpaces,
        },
        parentResource
      )
      .then(({ data }) => {
        updateCache(data);
        return { data, id: data.reference };
      })
      .catch(() => {
        toastError(
          `Error deleting space, try again. If error persists try refreshing the page`,
          toastErrorIcon,
          toastCloseIcon
        );
      });
  };

  const removeSpaces = async (spaceIds) => {
    const parentResource = getParentAssociationFromCache();
    const { spaces = [] } = parentResource;

    let newSpaces = [...spaces];

    newSpaces = newSpaces.filter((newSpace) => !spaceIds.includes(newSpace.id));

    return API[api]
      .patch(
        id,
        {
          ...parentResource,
          spaces: newSpaces,
        },
        parentResource
      )
      .then(({ data }) => {
        updateCache(data);
        return { data, id: data.reference };
      })
      .catch(() => {
        toastError(
          `Error deleting spaces, try again. If error persists try refreshing the page`,
          toastErrorIcon,
          toastCloseIcon
        );
      });
  };

  /**
   * Actions
   */

  /**
   * Mutations
   */
  const onPostMutate = async (space) => {
    await queryClient.cancelQueries(keys);
    const { id: spaceId } = space;

    const rollback = queryClient.getQueryData(keys);

    await queryClient.setQueryData(keys, (current) => ({
      ...current,
      spaces: [
        ...current.spaces,
        { ...space, footage: formatFootage(space.area?.value), parent: id },
      ],
      spacesDict: { ...current.spacesDict, [spaceId]: space },
    }));
    return { rollback, id: spaceId };
  };

  const onPatchMutate = async (space) => {
    await queryClient.cancelQueries(keys);
    const { id: spaceId } = space;
    space = {
      ...space,
      footage: formatFootage(space.area?.value),
      parent: id,
    };

    const rollback = queryClient.getQueryData(keys);

    const indexToPatch = rollback.spaces.findIndex((spc) => spc.id === spaceId);
    await queryClient.setQueryData(keys, (current) => {
      current.spaces.splice(indexToPatch, 1, space);
      return {
        ...current,
        spaces: current.spaces,
        spacesDict: {
          ...current.spacesDict,
          [spaceId]: space,
        },
      };
    });

    return { rollback, id: spaceId };
  };

  const onMutateRemove = async (space) => {
    await queryClient.cancelQueries(keys);
    const { id: spaceId } = space;
    const rollback = queryClient.getQueryData(keys);

    await queryClient.setQueryData(keys, (current) => {
      const newCurrent = { ...current };
      const indexToRemove = newCurrent.spaces.findIndex(
        (newSpace) => newSpace.id === space.id
      );

      newCurrent.spaces.splice(indexToRemove, 1);

      delete newCurrent.spacesDict[spaceId];
      return {
        ...current,
        spaces: newCurrent.spaces,
        spacesDict: newCurrent.spacesDict,
      };
    });
    return { rollback, id: spaceId };
  };

  const onMutateRemoveMany = async (spaceIds) => {
    await queryClient.cancelQueries(keys);
    const rollback = queryClient.getQueryData(keys);

    await queryClient.setQueryData(keys, (current) => {
      const newCurrent = { ...current };
      const updatedSpaces = newCurrent.spaces.filter(
        (space) => !spaceIds.includes(space.id)
      );

      newCurrent.spaces = updatedSpaces;

      spaceIds.forEach((spaceId) => delete newCurrent.spacesDict[spaceId]);
      return {
        ...current,
        spaces: newCurrent.spaces,
        spacesDict: newCurrent.spacesDict,
      };
    });
    return { rollback };
  };

  const post = useMutation(postSpace, {
    mutationFn: postSpace,
    onMutate: onPostMutate,
    onSuccess: () => {
      toastMessage("Successfully created space.", toastIcon, toastCloseIcon);
    },
    onError: (err, variables, { rollback }) => {
      queryClient.setQueryData(keys, rollback);
    },
  });

  const patch = useMutation(patchSpace, {
    mutationFn: patchSpace,
    onMutate: onPatchMutate,
    onSuccess: () => {
      toastMessage("Successfully updated.", toastIcon, toastCloseIcon);
    },
    onError: (err, variables, { rollback }) => {
      toastError(
        `Error updating space, try again. If error persists try refreshing the page`,
        toastErrorIcon,
        toastCloseIcon
      );
      queryClient.setQueryData(keys, rollback);
    },
  });

  const remove = useMutation(removeSpace, {
    mutationFn: removeSpace,
    onMutate: onMutateRemove,
    onSuccess: () => {
      toastMessage("Space was removed successfully", toastIcon, toastCloseIcon);
    },
    onError: (err, variables, { rollback }) => {
      toastError(
        `Error removing space, try again. If error persists try refreshing the page`,
        toastErrorIcon,
        toastCloseIcon
      );
      queryClient.setQueryData(keys, rollback);
    },
    onSettled: () => {
      // invalidate related tasks, events, files
      queryClient.invalidateQueries(taskKeys.taskList);
      queryClient.invalidateQueries(calendarKeys.calendarList);
      queryClient.invalidateQueries(filePaginatedKeys.allFiles);
    },
  });

  const removeMany = useMutation(removeSpaces, {
    mutationFn: removeSpaces,
    onMutate: onMutateRemoveMany,
    onSuccess: () => {
      toastMessage(
        "Spaces were removed successfully",
        toastIcon,
        toastCloseIcon
      );
    },
    onError: (err, variables, { rollback }) => {
      toastError(
        `Error removing spaces, try again. If error persists try refreshing the page`,
        toastErrorIcon,
        toastCloseIcon
      );
      queryClient.setQueryData(keys, rollback);
    },
    onSettled: () => {
      // invalidate related tasks, events, files
      queryClient.invalidateQueries(taskKeys.taskList);
      queryClient.invalidateQueries(calendarKeys.calendarList);
      queryClient.invalidateQueries(filePaginatedKeys.allFiles);
    },
  });

  /**
   * Mutations
   */

  const base = useQuery(keys, fetchSpaceResources, {
    enabled: !!authToken,
    placeholderData: {
      spaces: [],
      spacesDict: {},
      spaceConfiguration: {},
      parent: {},
    },
    onError: () => {
      toastError(`Error loading spaces.`, toastErrorIcon, toastCloseIcon);
      return {
        spaces: [],
        spacesDict: {},
        spaceConfiguration: getSpaceConfiguration(managementConfiguration),
        parent: {},
        space: undefined,
      };
    },
    useErrorBoundary: true,
  });

  // If space is not found, it created an special 404 error to be rendered with `useQueryNotFoundNavigation`
  let spaceNotFound;
  if (base.isFetched && !base?.data?.spacesDict[selectedSpace]) {
    spaceNotFound = { response: { status: 404 } };
  }

  return {
    base,
    actions: { post, patch, remove, removeMany },
    space: base?.data?.spacesDict?.[selectedSpace],
    postData,
    spaceNotFound,
  };
};

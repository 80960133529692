/**
 * Remove given files from workflow attachments or workflow steps
 * @param {string[]} removedFiles - array of file references to remove
 * @returns workflow updated
 */
const removeFilesFromWorkflow = (workflow, removedFiles = []) => {
  const removedFilesSet = new Set(removedFiles);

  const updatedWorkflow = {
    ...workflow,
    attachments: workflow?.attachments?.filter(
      ({ ref }) => !removedFilesSet.has(ref)
    ),
    requestWorkflow: workflow?.requestWorkflow?.map((workflowItem) => ({
      ...workflowItem,
      steps: workflowItem?.steps?.map((step) => ({
        ...step,
        attachments: (step.attachments || []).filter(
          ({ ref }) => !removedFilesSet.has(ref)
        ),
      })),
    })),
  };

  return updatedWorkflow;
};

export default removeFilesFromWorkflow;

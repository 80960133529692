import React from "react";
import DistroListSelectMembers from "../DistroList/DistroListSelectMembers";
import RequestGridViewDistroListCell from "../Requests/RequestGridView/RequestGridViewDistroListCell";
import useDistroSelectorData from "./useDistroSelectorData";

const DistroListSelector = ({
  isDistroSelectOpen = false,
  members = [],
  selectedMembersRef = [],
  setDistroSelectOpen,
  handleDistroMembers,
}) => {
  const { selectedFullMembers } = useDistroSelectorData({ selectedMembersRef });

  return (
    <>
      {selectedMembersRef.length > 0 && (
        <RequestGridViewDistroListCell
          distroList={selectedMembersRef}
          hideTile
          handleDeleteFromDistro={(userRef) =>
            handleDistroMembers("remove", userRef)
          }
        />
      )}

      {isDistroSelectOpen && (
        <div className="absolute" style={{ top: -20, left: 280 }}>
          <DistroListSelectMembers
            members={members}
            selectedMembers={selectedFullMembers}
            setDistroSelectOpen={setDistroSelectOpen}
            onAdd={(user) => handleDistroMembers("add", user.reference)}
            onRemove={(userRef) => handleDistroMembers("remove", userRef)}
          />
        </div>
      )}
    </>
  );
};

export default DistroListSelector;

// clear the value associated with the search term id
export const clearSearch = (resourceName) => {
  localStorage.setItem(`${resourceName}-tableGlobalFilter`, "");
};

// use the tab name to set the search term id in localStorage
const clearSearchField = (index, tabs, resource) => {
  switch (index) {
    case tabs.assetIndex:
      clearSearch("assets");
      break;
    case tabs.expenseIndex:
      clearSearch("expenses");
      break;
    case tabs.budgetIndex:
      clearSearch("budgets");
      break;
    case tabs.financesIndex:
      clearSearch("finances");
      break;
    case tabs.workflowIndex:
      clearSearch("workflows");
      break;
    case tabs.submittalsIndex:
      clearSearch("submittals");
      break;
    case tabs.spaceIndex:
      clearSearch("Spaces");
      break;
    case tabs.taskIndex:
      clearSearch("tasks");
      break;
    case tabs.ticketIndex:
      clearSearch("tickets");
      break;
    case tabs.reportIndex:
      clearSearch("reports");
      break;
    case tabs.filesIndex: {
      if (resource) {
        clearSearch(`${resource}-associated-files`);
        break;
      }
      clearSearch("files");
      break;
    }
    default:
      break;
  }
};

export default clearSearchField;

import { useLayoutEffect } from "react";
import { useHistory, useLocation } from "react-router";

const usePageNotFoundData = () => {
  const history = useHistory();
  const location = useLocation();

  useLayoutEffect(() => {
    // If user navigates manually to `/not-found` path, will redirect to home path
    if (location.pathname === "/not-found" && !location.state) {
      history.replace("/");
    }
  }, [location.state, history, location]);

  const navigateBack = () => {
    history.goBack();
  };

  return { navigateBack };
};

export default usePageNotFoundData;

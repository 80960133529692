import React, { useCallback } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import Input from "../Input/Input";
import CollapsibleSection from "../CollapsibleSection/CollapsibleSection";
import SecondaryButton from "../Buttons/SecondaryButton";
import { COA_ACCOUNT_TYPES } from "../../../constants";
import editIcon from "../../assets/images/editIcon.svg";
import Dropdown from "../Dropdown/Dropdown";

const collapsibleContainerCN = () => cntl`
  mt-1
  pr-6
  pl-4
  text-black
  border-b-2
  mb-1
  pb-1
  border-gray-150
`;

const editButtonCN = (className) => cntl`
  ml-2
  w-5
  h-5
  ${className}
`;

const getLength = (number) => {
  return number.toString().length;
};

const getNextSubCode = (chartOfAccountCode) => {
  let subCode = chartOfAccountCode?.subCodes?.length
    ? chartOfAccountCode.subCodes.length + 1
    : 1;

  subCode = getLength(subCode) === 1 ? `0${subCode}` : subCode;
  return `${chartOfAccountCode.code}-${subCode}`;
};

const ChartOfAccountsViewCode = ({
  index,
  chartOfAccountCode,
  toggleChartOfAccounts,
  newChartOfAccountSubCode,
  setNewChartOfAccountSubCode,
  onFinishAddingSubCode,
  dispatchFinancialsSettings,
}) => {
  const onChangeDescription = useCallback(
    (val) => {
      setNewChartOfAccountSubCode((prev) => ({
        ...prev,
        code: getNextSubCode(chartOfAccountCode),
        description: val,
      }));
    },
    [chartOfAccountCode, setNewChartOfAccountSubCode]
  );

  const toggleEditingChartOfAccountCode = useCallback(() => {
    dispatchFinancialsSettings({
      type: "toggleEditingChartOfAccountCode",
      index,
    });
  }, [dispatchFinancialsSettings, index]);

  const onEditCoaCodeDescription = useCallback(
    (value) => {
      dispatchFinancialsSettings({
        type: "changeChartOfAccountCodeDescription",
        index,
        value,
      });
    },
    [dispatchFinancialsSettings, index]
  );

  const onEditCoaCodeAccountType = useCallback(
    (newValue) => {
      dispatchFinancialsSettings({
        type: "changeChartOfAccountCodeAccountType",
        index,
        value: newValue.value,
      });
    },
    [dispatchFinancialsSettings, index]
  );

  const toggleEditingChartOfAccountCodeSubCode = useCallback(
    (subCodeIndex) => {
      dispatchFinancialsSettings({
        type: "toggleEditingChartOfAccountCodeSubCode",
        coaIndex: index,
        subCodeIndex,
      });
    },
    [dispatchFinancialsSettings, index]
  );

  const onEditCoaCodeSubCodeDescription = useCallback(
    (subCodeIndex, value) => {
      dispatchFinancialsSettings({
        type: "changeChartOfAccountCodeSubCodeDescription",
        coaIndex: index,
        subCodeIndex,
        value,
      });
    },
    [dispatchFinancialsSettings, index]
  );

  return (
    <CollapsibleSection
      id={index}
      className={collapsibleContainerCN()}
      titleComponent={() => (
        <div className="flex">
          <div className="w-52 overflow-ellipsis">
            <h3 className="font-semibold text-gray-300 uppercase truncate">
              {chartOfAccountCode?.code}
            </h3>
          </div>
          <div className="w-60 overflow-ellipsis pr-4">
            {(chartOfAccountCode?.isEditing && (
              <div className="flex flex-row py-2 pr-4">
                <Input
                  value={chartOfAccountCode.description}
                  placeholder="Description"
                  onChange={onEditCoaCodeDescription}
                  onBlur={toggleEditingChartOfAccountCode}
                />
              </div>
            )) || (
              <h3 className="font-semibold text-gray-300 truncate">
                {chartOfAccountCode?.description}
              </h3>
            )}
          </div>
          <div>
            {(chartOfAccountCode?.isEditing && (
              <div className="flex flex-row py-2">
                <Dropdown
                  value={COA_ACCOUNT_TYPES.find(
                    (type) => type.value === chartOfAccountCode?.accountType
                  )}
                  options={COA_ACCOUNT_TYPES}
                  onChange={onEditCoaCodeAccountType}
                  onBlur={toggleEditingChartOfAccountCode}
                />
              </div>
            )) || (
              <h3 className="font-semibold text-gray-300">
                {
                  COA_ACCOUNT_TYPES.find(
                    (type) => type.value === chartOfAccountCode?.accountType
                  )?.label
                }
              </h3>
            )}
          </div>
          <div className="flex">
            <button
              className={editButtonCN(
                chartOfAccountCode?.isEditing ? "mt-4" : "mt-0"
              )}
              type="button"
              onClick={toggleEditingChartOfAccountCode}
            >
              <img
                className="w-8 cursor-pointer"
                src={editIcon}
                alt="edit data"
              />
            </button>
          </div>
        </div>
      )}
      isOpen={chartOfAccountCode?.isOpen}
      setIsOpen={() => toggleChartOfAccounts(index)}
      useChevronIcon
    >
      <div className="flex flex-col">
        {chartOfAccountCode?.subCodes?.map((subCode, sIndex) => {
          return (
            <div className="flex flex-row w-full py-2" key={subCode.code}>
              <div className="w-52 overflow-ellipsis pr-4">
                <p className="text-gray-300 text-md truncate">
                  {subCode?.code}
                </p>
              </div>
              {(subCode?.isEditing && (
                <div className="w-full pl-8">
                  <Input
                    value={subCode.description}
                    placeholder="Description"
                    onChange={(val) =>
                      onEditCoaCodeSubCodeDescription(sIndex, val)
                    }
                    onBlur={toggleEditingChartOfAccountCodeSubCode}
                  />
                </div>
              )) || (
                <div className="pr-4">
                  <p className="text-gray-300 text-md">
                    {subCode?.description}
                  </p>
                </div>
              )}
              <div className="flex pr-28">
                <button
                  className={editButtonCN(subCode?.isEditing ? "mt-4" : "mt-0")}
                  type="button"
                  onClick={() => toggleEditingChartOfAccountCodeSubCode(sIndex)}
                >
                  <img
                    className="w-8 cursor-pointer"
                    src={editIcon}
                    alt="edit data"
                  />
                </button>
              </div>
            </div>
          );
        })}
        <div className="flex flex-row w-full py-2">
          <div className="pr-4 w-52 overflow-ellipsis">
            <p className="text-gray-300 text-md truncate">
              {getNextSubCode(chartOfAccountCode)}
            </p>
          </div>
          <div className="px-4 w-full">
            <Input
              className="text-gray-300 pl-4"
              placeholder="Description"
              value={newChartOfAccountSubCode?.description}
              onChange={onChangeDescription}
            />
          </div>
          <SecondaryButton
            title="Add Subcode"
            onClick={() => onFinishAddingSubCode(index)}
          />
        </div>
      </div>
    </CollapsibleSection>
  );
};

ChartOfAccountsViewCode.propTypes = {
  index: PropTypes.number,
  chartOfAccountCode: PropTypes.shape({
    code: PropTypes.string,
    description: PropTypes.string,
    isOpen: PropTypes.bool,
    selectedForBudgets: PropTypes.bool,
    isOpenInProjectConfig: PropTypes.bool,
    codeType: PropTypes.string,
    accountType: PropTypes.string,
    isEditing: PropTypes.bool,
    subCodes: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        description: PropTypes.string,
        selectedForBudgets: PropTypes.bool,
        isEditing: PropTypes.bool,
      })
    ),
  }),
  toggleChartOfAccounts: PropTypes.func,
  newChartOfAccountSubCode: PropTypes.shape({
    description: PropTypes.string,
  }),
  setNewChartOfAccountSubCode: PropTypes.func,
  onFinishAddingSubCode: PropTypes.func,
  dispatchFinancialsSettings: PropTypes.func,
};

ChartOfAccountsViewCode.defaultProps = {
  index: undefined,
  chartOfAccountCode: undefined,
  toggleChartOfAccounts: undefined,
  newChartOfAccountSubCode: undefined,
  setNewChartOfAccountSubCode: undefined,
  onFinishAddingSubCode: undefined,
  dispatchFinancialsSettings: undefined,
};

export default ChartOfAccountsViewCode;

import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import Dropdown from "../Dropdown/Dropdown";

const BudgetTableUpdateVendorForm = ({
  onUpdateVendorChange,
  vendorInfo,
  vendors,
}) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <div className="pr-2">
          <Dropdown
            className="pt-2"
            label="Vendor"
            value={vendors?.find((v) => v.value === vendorInfo?.vendor)}
            onChange={onUpdateVendorChange}
            options={vendors.filter((item) => !item.createNewVendor)}
            validation={yup.mixed()}
          />
        </div>
      </div>
    </div>
  );
};

BudgetTableUpdateVendorForm.propTypes = {
  vendorInfo: PropTypes.shape({
    lineitemId: PropTypes.string,
    vendor: PropTypes.string,
    originalLineItem: PropTypes.shape({}),
  }),
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onUpdateVendorChange: PropTypes.func,
};

BudgetTableUpdateVendorForm.defaultProps = {
  vendorInfo: undefined,
  vendors: [],
  onUpdateVendorChange: undefined,
};

export default BudgetTableUpdateVendorForm;

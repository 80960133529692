import React from "react";

import PureRfiTable from "./PureRfiTable";

const RfiTable = ({ data }) => {
  return (
    <>
      <PureRfiTable data={data} />
    </>
  );
};

export default RfiTable;

import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import moment from "moment";
import { getFullName } from "../../../../../helpers/Formatters";
import Table from "../../../Table/Table";
import ToggleTextButton from "../../../Buttons/ToggleTextButton";
import CommentButton from "../../../CommentButton";
import { financialDocumentsType } from "../../../../../constants";
import { getVendorInvoiceColumns } from "../../../DocumentForm/DocumentBudgetColumns";
import DocumentDetailsSummary from "../DocumentDetailsSummary";
import useDocumentDetailsData from "./useDocumentDetailsData";

const keyCN = cntl`
font-semibold text-md w-48 text-gray-450
`;

const valueCN = (className) => cntl`
    font-normal
    text-md
    mr-20
    text-gray-450
    ${className}
  `;

const DocumentDetails = ({ document, project, setShowCommentsPopover }) => {
  const {
    isLeftActive,
    setIsLeftActive,
    isRightActive,
    setIsRightActive,
    docBudgetLines,
    budget,
    budgetType,
    isLockGmp,
  } = useDocumentDetailsData({ project, document });

  return (
    <div className="flex flex-col">
      <div className="flex justify-between py-4">
        <div className="flex">
          <p className="font-semibold text-2xl">Document Information</p>
        </div>
        <div className="flex ">
          <CommentButton onClick={() => setShowCommentsPopover(true)} />
        </div>
      </div>
      <div className="flex border-b pb-4 mb-6 w-full">
        <div className="flex w-1/2 mr-20">
          <p className={keyCN}>Submitted By</p>
          <p className={valueCN}>
            {document?.submittedBy
              ? getFullName(document?.submittedBy?.name)
              : "N/A"}
          </p>
        </div>
        <div className="flex w-1/2">
          <p className={keyCN}>Type</p>
          <p className={valueCN}>{financialDocumentsType[document?.docType]}</p>
        </div>
      </div>
      <div className="flex border-b pb-4 mb-6 w-full">
        <div className="flex w-1/2 mr-20">
          <p className={keyCN}>Descriprion</p>
          <p className={valueCN}>{document?.description || "N/A"}</p>
        </div>
        <div className="flex w-1/2">
          <p className={keyCN}>Project</p>
          <p className={valueCN}>{project?.name}</p>
        </div>
      </div>
      <div className="flex border-b pb-4 mb-6 w-full">
        <div className="flex w-1/2 mr-20">
          <p className={keyCN}>Date Modified</p>
          <p className={valueCN}>
            {moment(document?.metadata?.lastUpdated).format("MMMM DD, YYYY")}
          </p>
        </div>
        <div className="flex w-1/2">
          <p className={keyCN}>Address</p>
          {!!project?.address[0] && (
            <div className={valueCN("flex flex-col")}>
              <span>{project.address[0].street}</span>
              <span>{project.address[0].street2}</span>
              <span>{`${project.address[0].city}${
                project.address[0].state ? `, ${project.address[0].state}` : ""
              }`}</span>
              <span>{`${project.address[0].country}${
                project.address[0].zipCode
                  ? `, ${project.address[0].zipCode}`
                  : ""
              }`}</span>
            </div>
          )}
        </div>
      </div>
      <DocumentDetailsSummary
        budget={budget}
        docBudgetLines={docBudgetLines}
        project={project}
        documentNumber={document?.number}
      />
      <div className="border-b w-full pb-6" />
      <div className="flex pt-6 pb-2">
        <ToggleTextButton
          setIsLeftActive={setIsLeftActive}
          setIsRightActive={setIsRightActive}
          isLeftActive={isLeftActive}
          isRightActive={isRightActive}
          leftText="Detailed Summary"
          rightText="Executive Summary"
        />
      </div>
      <div>
        <Table
          className="max-h-96 overflow-scroll"
          resourceName="budget-items"
          data={docBudgetLines}
          columns={getVendorInvoiceColumns(isLeftActive, budgetType, isLockGmp)}
          hideCreateNewButton
          hideEditRowButton
          hideSiteHeader
          hideChangeView
          hideSaveButton
          disableHover
          showFooter
          showConstantRowIndex
        />
      </div>
    </div>
  );
};

DocumentDetails.propTypes = {
  document: PropTypes.shape({
    reference: PropTypes.string,
    number: PropTypes.string,
    docType: PropTypes.string,
    description: PropTypes.string,
    submittedBy: PropTypes.shape({
      name: PropTypes.string,
    }),
    metadata: PropTypes.shape({
      lastUpdated: PropTypes.string,
    }),
  }),
  project: PropTypes.shape({
    budget: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.arrayOf(
      PropTypes.shape({
        street: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        country: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
  }),
  setShowCommentsPopover: PropTypes.func,
};

DocumentDetails.defaultProps = {
  document: undefined,
  project: undefined,
  setShowCommentsPopover: undefined,
};

export default DocumentDetails;

import { isEqual } from "lodash";

import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useAppState } from "../../../state/appState";
import useReportPost from "../../../hooks/useReportPost";
import useReportDispatcher from "../../../hooks/useReportDispatcher";
import useEsModalData from "../EsModal/useEsModalData";
import { getSingleResourcePath } from "../../../helpers/Navigation";
import getAssociationType from "../../../helpers/Report/getAssociationType";
import { convertAssetsCategoriesToDropdownShape } from "../../../helpers/Report/reportTemplatesFormatters";
import {
  sanitizeIncludeInformation,
  sanitizeAssets,
  sanitizeSubmittals,
} from "../../../helpers/Report/sanitizeReport";
import capitalizeFirstLetter from "../../../helpers/Utilities/capitalizeFirstLetter";
import { REPORT_EMPTY } from "../../../constants";

const useReportsCreateModalData = ({ modalData }) => {
  const history = useHistory();

  const { closeModal } = useEsModalData(null, modalData);

  const [{ users }] = useAppState();

  const timeoutRef = useRef();

  // Report dispatcher hook
  const {
    reportForm,
    handleChangeForm,
    handleTags,
    handleDistroMembers,
    handleDateRange,
  } = useReportDispatcher();

  // Mutation hook to create Report on backend
  const {
    mutate: createReportTemplate,
    mutateAsync: createReport,
    isLoading: isCreating,
  } = useReportPost();

  const [isDistroSelectOpen, setDistroSelectOpen] = useState(false);
  const [humanReadableRecurrence, setHumanReadableRecurrence] = useState();

  // State to hold changes in "Include Information" section
  const [includeInformationData, setIncludeInformationData] = useState();
  // State to hold changes in "Assets Categories Dropdown"
  const [assetsCategoriesData, setAssetsCategoriesData] = useState([]);
  // State to track if report should be saved as `template`
  const [templateCheckbox, setTemplateCheckbox] = useState(false);

  const [showConfirm, setShowConfirm] = useState(false);

  // Get only member type users
  const members = useMemo(
    () => users?.filter((user) => user.kind === "member"),
    [users]
  );

  // If modal is opened from P/P/A tabs
  const lockAssociation = modalData?.association;
  // If modal is opened from table view with multiple associations selected
  const multiAssociations = modalData?.multiAssociations;
  // Disabled the association dropdown
  const disableAssociationDropdown = lockAssociation || multiAssociations;

  const initialReport = useMemo(() => {
    const resource = lockAssociation?.split("/")?.[0]?.toLowerCase();
    return {
      ...REPORT_EMPTY,
      sourceType: resource,
      sources: lockAssociation ? [lockAssociation] : [],
    };
  }, [lockAssociation]);

  useEffect(() => {
    const isSame = isEqual(initialReport, reportForm);
    setShowConfirm(!isSame);
  }, [reportForm, initialReport]);

  // Disable form if required fields are not populated or if mutation indicators are `true` (when creating Report)
  const disableForm =
    !reportForm.title.trim() ||
    !reportForm.sources.length ||
    !reportForm?.dateRange?.option ||
    isCreating;

  const associationType = reportForm?.sourceType;

  const modalTitle = associationType
    ? `Create Report - ${capitalizeFirstLetter(associationType)}`
    : "Create Report";

  // Set the resource type based on `lockAssociation` or `multiAssociations`
  // Update the `reportForm` and set the dropdown with given associations
  useEffect(() => {
    const sourceType = getAssociationType(lockAssociation || multiAssociations);
    handleChangeForm("sourceType", sourceType);

    // When modal is opened from P/P/A detail page
    if (lockAssociation) {
      handleChangeForm("sources", [lockAssociation]);
    }

    // When modal is opened from Create Report Button
    else if (multiAssociations) {
      handleChangeForm("sources", multiAssociations);
    }
  }, [lockAssociation, multiAssociations, handleChangeForm]);

  // Track the resource type based on association(s) dropdown: property | project | asset
  useEffect(() => {
    if (reportForm?.sources?.length) {
      const sourceType = getAssociationType(reportForm?.sources);
      handleChangeForm("sourceType", sourceType);
    }
  }, [reportForm?.sources, handleChangeForm]);

  /**
   * It closes the modal. If the user is creating a report from the left-side menu,
   * it redirects them to the Report single page.
   * @param {Object} data response data (Report) from backend
   */
  const onSuccessCallback = ({ reference }) => {
    closeModal();

    if (!lockAssociation && !modalData?.isQuickAdd) {
      history.push(getSingleResourcePath(reference));
    }
  };

  /**
   * Create the new report, if success redirect to SR details page
   */
  const handleCreateReport = () => {
    // 1. `includeInformationData` needs to be sanitized before sending it to the backend.
    // Backend requires that the `configuration` property should not contain empty `options` arrays.
    const updatedIncludeInformation = sanitizeIncludeInformation(
      includeInformationData
    );

    // 2. Start creating the full report object with the `sourceType` map.
    let finalReportForm = {
      ...reportForm,
      sourceType: associationType,
      configuration: { [associationType]: { ...updatedIncludeInformation } },
    };

    // 3. Check if the user has selected `property` or `project` association,
    // and if assets is checked, it needs to include an `assets` property in the configuration object,
    // following the data structure required by the backend.
    if (
      associationType !== "asset" &&
      updatedIncludeInformation?.assets?.selected
    ) {
      finalReportForm = sanitizeAssets(
        associationType,
        assetsCategoriesData,
        finalReportForm
      );
    }

    // 4. If `submittals` is selected, we need to rename the `options` array to `status`
    if (associationType === "project") {
      if (updatedIncludeInformation?.submittals?.options?.length) {
        finalReportForm = sanitizeSubmittals(
          associationType,
          updatedIncludeInformation,
          finalReportForm
        );
      }
    }

    // 5. If template checkbox is checked, we need to save as template in a diff POST
    if (templateCheckbox) {
      const templateData = {
        isTemplate: true,
        title: `${finalReportForm.title} template`,
      };

      createReportTemplate({ ...finalReportForm, ...templateData });
    }

    // 5. Post final report object
    createReport(finalReportForm).then(onSuccessCallback);
  };

  /**
   * Apply template selected to the `Include Information` section
   * @param {Object} templateSelected Report template selected from dropdown
   */
  const handleApplyTemplate = (templateSelected) => {
    const { sourceType, configuration } = templateSelected;

    handleChangeForm("sourceType", sourceType);

    /*
     Edge case: If the selected sources are of type project, and a template with a 
     different source type is chosen, then the sources array should be emptied.
    */
    if (sourceType !== associationType) {
      handleChangeForm("sources", []);
    }

    // Adding a delay to allow `IncludeInformation` component to re-render and adjust its UI
    // accordingly to the new change caused by `setAssociationType`
    timeoutRef.current = setTimeout(() => {
      setIncludeInformationData((prev) => {
        if (!sourceType) return prev;

        return { ...prev, ...configuration[sourceType] };
      });

      // Transform assets from template to dropdown shape
      setAssetsCategoriesData(() =>
        convertAssetsCategoriesToDropdownShape(
          configuration?.[sourceType]?.assets ?? { options: [] }
        )
      );
    }, 150);
  };

  // Clean up timeout when component is unmounted
  useEffect(() => {
    return () => {
      if (timeoutRef?.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return {
    showConfirm,
    modalTitle,
    reportForm,
    disableForm,
    disableAssociationDropdown,
    members,
    isDistroSelectOpen,
    associationType,
    isMultiAssociation: !lockAssociation,
    humanReadableRecurrence,
    assetsCategoriesData,
    includeInformationData,
    templateCheckbox,
    setTemplateCheckbox,
    setIncludeInformationData,
    setAssetsCategoriesData,
    setHumanReadableRecurrence,
    setDistroSelectOpen,
    handleChangeForm,
    handleTags,
    handleDistroMembers,
    handleDateRange,
    handleCreateReport,
    handleApplyTemplate,
  };
};

export default useReportsCreateModalData;

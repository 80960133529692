import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

import HelpIcon from "../../assets/images/questionMarkCircledIcon.svg";
import lockIcon from "../../assets/images/greenLock.svg";
import chatIcon from "../../assets/images/chatDarkGreenIcon.svg";
import notesIcon from "../../assets/images/notesFoldedBorderIcon.svg";
import dollar from "../../assets/images/dollarSimpleIcon.svg";
import assetIcon from "../../assets/images/assetRubyIcon.svg";
import calendarIcon from "../../assets/images/calendar-clock-icon.svg";
import taskIcon from "../../assets/images/taskCheckedIcon.svg";
import timesheetIcon from "../../assets/images/timeClockIcon.svg";
import workflows from "../../assets/images/workflowsGreenIcon.svg";
import propertyIcon from "../../assets/images/propertyLocationIcon.svg";
import projectIcon from "../../assets/images/projectToolsIcon.svg";
import managementIcon from "../../assets/images/managementGreenIcon.svg";
import generalIcon from "../../assets/images/settingsDarkenedGreenIcon.svg";
import contactIcon from "../../assets/images/contactIcon.svg";
import reportingIcon from "../../assets/images/reportIconGreen.svg";
import TodoIconDarkGreen from "../../assets/images/TodoIconDarkGreen.svg";

const titleIconMap = {
  "Account & Setup": managementIcon,
  Assets: assetIcon,
  Calendar: calendarIcon,
  Communications: chatIcon,
  Contacts: contactIcon,
  Financials: dollar,
  General: generalIcon,
  "Meeting Minutes": notesIcon,
  Projects: projectIcon,
  Properties: propertyIcon,
  Security: lockIcon,
  SOPs: taskIcon,
  Support: HelpIcon,
  Timesheets: timesheetIcon,
  Workflows: workflows,
  Reporting: reportingIcon,
  Tasks: TodoIconDarkGreen,
};

const titleWrapperCN = cntl`
flex
justify-start
items-center
mb-2
`;

const titleCN = cntl`
text-base
text-brandDarkGreen
font-semibold
`;

const HelpCardTitle = ({ title }) => {
  const icon = titleIconMap[title];

  return (
    <div className={titleWrapperCN}>
      <img src={icon} className="w-5 h-5 mr-2" alt={title} />
      <p className={titleCN}>{title}</p>
    </div>
  );
};

HelpCardTitle.propType = {
  title: PropTypes.string,
  icon: PropTypes.string,
};

HelpCardTitle.defaultProps = {
  title: undefined,
  icon: undefined,
};

export default HelpCardTitle;

import { useMutation, useQueryClient } from "react-query";
import { ProjectAPI } from "@griffingroupglobal/eslib-api";
import projectKeys from "./projectKeys";
import sortByProperty from "../../helpers/Utilities/sortByProperty";

/**
 * Mutation hook to post a single project
 */
const useProjectsPost = () => {
  const queryClient = useQueryClient();

  /**
   * Function to post a project in backend
   * @param {Object} project - single project to create
   */
  const postProject = async (project) => {
    const { data } = await ProjectAPI.post(project);

    return data;
  };

  return useMutation({
    mutationFn: postProject,
    onSuccess: (data) => {
      const queryKey = projectKeys.all;

      // Add new project to query cache after success
      queryClient.setQueryData(queryKey, (previuos) => {
        // TODO: (Tech debt) Not sure how we gonna handle this when using infinity scroll
        // Add new project to the cache list and sort them by `ES rule`
        const updatedList = [data, ...(previuos || [])];

        // Sort projects by ES rule
        return sortByProperty(updatedList, "name");
      });
    },
    onSettled: () => {
      // TODO: (Work-around to address the issue where the backend does not return newly created project for users without admin roles)
      // https://estatespace.atlassian.net/jira/software/projects/ES/boards/26?selectedIssue=ES-10166

      setTimeout(() => {
        // Invalidate projects
        queryClient.invalidateQueries(projectKeys.all);
      }, 800);
    },
    onError: (error) => {
      console.error("useProjectsPost", error);
    },
  });
};

export default useProjectsPost;

import { useReducer } from "react";
import { cloneDeep } from "lodash";

const defaultState = {
  active: true,
};

const reducer = (budget, action) => {
  switch (action.type) {
    case "reset":
      return cloneDeep(action.budget) ?? defaultState;
    case "budgetType":
      return {
        ...budget,
        budgetType: action.budgetType,
      };
    default:
      return budget;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};

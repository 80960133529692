import moment from "moment";
import { useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import useSopActivity from "../../../../hooks/useSopActivity";
import { useUsers } from "../../../../hooks/useUsers.new";
import { getFullName } from "../../../../helpers/Formatters";

const useSopActivityData = (sopId) => {
  const { ref, inView } = useInView();

  const { data: usersData } = useUsers();
  const { data, hasNextPage, isLoading, fetchNextPage } = useSopActivity(
    `Sop/${sopId}`
  );

  const userDict = usersData?.userDict;

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  const activity = useMemo(() => {
    if (!data?.pages?.length) return [];

    return data.pages.flatMap((page) =>
      page.data.map((entry) => {
        const name = userDict[entry.user]?.name;
        return {
          activity: `Sop was ${entry.action.toLowerCase()} by ${getFullName(
            name
          )}`,
          date: moment(entry.metadata.lastUpdated).format(
            "dddd, MMMM Do YYYY, h:mm a"
          ),
        };
      })
    );
  }, [data, userDict]);

  return {
    isLoading,
    activity,
    ref,
  };
};

export default useSopActivityData;

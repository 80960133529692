import React from "react";
// import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { getProjectOptions } from "../../helpers/SiteHeaderOptions";
import SiteHeader from "../../stories/Components/SiteHeader/SiteHeader";

import SecondaryHeader from "../../stories/Components/TextHeaders/SecondaryHeader";

const ProjectSchedule = () => {
  const { projectId } = useParams();

  return (
    <>
      <SiteHeader
        title="Schedule"
        dropdownRoutes={getProjectOptions(projectId)}
      />
      <SecondaryHeader>Schedule: {projectId}</SecondaryHeader>
      <p>schedule goes here.</p>
    </>
  );
};

ProjectSchedule.propTypes = {};

ProjectSchedule.defaultProps = {};

export default ProjectSchedule;

/**
 * Check whether a permission is selected or not, looking it up in the
 * selectedPermissions object.
 * @param {Object} selectedPermissions - current state of selected permissions.
 * @returns {Function} A function that accepts a `resource` and `permission`, and returns a boolean indicating if the permission is selected.
 * @param {string} resource - the resource for which the permission is being checked (ex: "property", "project").
 * @param {string} permission - the specific permission being checked (ex: "can_read", "can_write_project").
 * @returns {boolean}
 */
const isPermissionSelected =
  (selectedPermissions) => (resource, permission) => {
    return (
      selectedPermissions[resource] && selectedPermissions[resource][permission]
    );
  };

export default isPermissionSelected;

import { UserAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { userKeys } from "../config/reactQuery/queryKeyFactory";

const postUser = async (user) => {
  const { data } = await UserAPI.post(user);
  return data;
};

/**
 * Mutation hook to post single user
 * @param {string} mutationKey custom key to track mutations
 * @returns mutation metadata
 */
const useUserPost = (mutationKey) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey,
    mutationFn: postUser,
    onSuccess: (data) => {
      const queryKey = userKeys.users;
      queryClient.setQueryData(queryKey, (currrent) => {
        const newData = data;
        if (data.kind === "company") {
          newData.companyName = data.company.value;
        }
        return {
          users: [...currrent.users, newData],
          userDict: {
            ...currrent.userDict,
            [newData.reference]: newData,
          },
          employees: newData?.isEmployee
            ? [...currrent.employees, newData]
            : [...currrent.employees],
          companies:
            newData?.kind === "company"
              ? [...currrent.companies, newData]
              : [...currrent.companies],
        };
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(userKeys.users);
    },
    onError: (error) => {
      console.error("useUserPost", error);
    },
  });
};

export default useUserPost;

import React, { useState, useEffect, useRef } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import Chevron from "../Chevron/Chevron";
import { BRAND_GREEN } from "../../../constants";

const dropdownIconCN = (menuIsOpen) => cntl`
  w-3
  w-3
  self-center
  transform
  ${menuIsOpen ? "-rotate-90" : "rotate-90"}
`;

function Select({ onChange, options, value, disabled, labelClassName }) {
  const [isDropDown, setIsDropDown] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const openDropDown = () => {
    setIsDropDown(!isDropDown);
  };
  const onSelectOption = (event, val, index) => {
    if (event.keyCode === 9) {
      /**
       * If tab out the last opt
       * in dropDown --> close
       * else pass
       */
      if (index === options.length - 1) setIsDropDown(false);
    } else {
      setSelectedOption(val);
      setIsDropDown(false);
      onChange(val);
    }
  };

  const ref = useRef(null);

  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      setIsDropDown(false);
    };
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, []);

  return (
    <div className="relative" ref={ref}>
      <div
        className="flex cursor-pointer flex-row"
        onClick={disabled ? undefined : openDropDown}
        onKeyDown={disabled ? undefined : openDropDown}
        role="button"
        tabIndex={0}
      >
        <p className={labelClassName ?? "ESInputLabel mr-2"}>
          {selectedOption || value || options[0]}
        </p>
        <div className="flex">
          <Chevron
            color={BRAND_GREEN}
            strokeWidth="2px"
            className={dropdownIconCN(isDropDown)}
          />
        </div>
      </div>
      {isDropDown && (
        <div className="border p-2 absolute min-w-full z-50 bg-white">
          {options?.map((customOption, index) => (
            <div
              key={customOption}
              className="py-3"
              onClick={(e) => onSelectOption(e, customOption, index)}
              onKeyDown={(e) => onSelectOption(e, customOption, index)}
              tabIndex={0}
              role="button"
            >
              {customOption}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

Select.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  disabled: PropTypes.bool,
  labelClassName: PropTypes.string,
};

Select.defaultProps = {
  onChange: undefined,
  options: [],
  value: "",
  disabled: false,
  labelClassName: undefined,
};

export default Select;

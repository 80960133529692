import React from "react";
import Checkbox from "../../../../Checkbox/Checkbox";

const StepWithStatusWrapper = ({
  sop,
  index,
  handleStepCheck,
  completeStep,
  disabled,
}) => {
  return (
    <div className="flex mb-6">
      <Checkbox
        className="items-start h-4 mt-0.5 mr-2"
        size={6}
        checked={completeStep === "Yes"}
        onChange={() => handleStepCheck(sop, index)}
        disabled={disabled}
      />
      <p className="text-gray-450 text-sm mr-2">
        {index + 1}
        {". "}
      </p>
      <p className="text-gray-450 text-sm">{sop?.stepData?.description}</p>
    </div>
  );
};

export default StepWithStatusWrapper;

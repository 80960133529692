import { useCallback, useEffect } from "react";
import { RoleAPI } from "@griffingroupglobal/eslib-api";
import { useAppState } from "../state/appState";
import { SET_ROLES_WOP } from "../constants";

export default () => {
  const [{ rolesWOP }, dispatch] = useAppState();

  const reload = useCallback(async () => {
    const { data } = await RoleAPI.get("$json");
    dispatch({
      type: SET_ROLES_WOP,
      roles: data?.entries,
    });
  }, [dispatch]);

  useEffect(() => {
    reload();
  }, [reload]);

  return [rolesWOP, reload];
};

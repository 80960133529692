import { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { isUrlValid } from "../../../../helpers/Formatters";

const useLinksModalWidget = ({ dispatch, handleAddLink, handleRemoveLink }) => {
  const [addLinksIsOpen, setAddLinksIsOpen] = useState(false);
  const [linkName, setLinkName] = useState();
  const [linkUrl, setLinkUrl] = useState();
  const inputRef = useRef({});

  const addLink = () => {
    const link = { id: uuidv4(), name: linkName || linkUrl, url: linkUrl };
    if (dispatch) {
      dispatch({
        type: "addLinkCompleteLink",
        link,
      });
    }
    if (handleAddLink) {
      handleAddLink(link);
    }
  };

  const handleDeleteClick = (link) => {
    if (dispatch) {
      dispatch({
        type: "deleteLink",
        linkId: link.id,
      });
    }
    if (handleRemoveLink) {
      handleRemoveLink(link.id);
    }
  };

  const clearFields = () => {
    setLinkName();
    setLinkUrl();
  };

  const confirmEdit = () => {
    if (linkUrl && isUrlValid(linkUrl)) {
      addLink();
      clearFields();
      inputRef.current?.title?.focus();
    }
  };

  const handleChange = (e) => {
    if (e.key === "Enter") {
      confirmEdit();
    }
  };

  return {
    handleDeleteClick,
    addLinksIsOpen,
    setAddLinksIsOpen,
    inputRef,
    linkName,
    linkUrl,
    setLinkName,
    setLinkUrl,
    handleChange,
    confirmEdit,
  };
};

export default useLinksModalWidget;

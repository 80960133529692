// Framework Tools
import React from "react";
import PropTypes from "prop-types";

// Components
import TertiaryHeader from "../../TextHeaders/TertiaryHeader";

export default function RequestGridViewVendorCell({ vendorList }) {
  return (
    <div className="w-full h-ful flex flex-col justify-start flex-wrap px-2">
      <TertiaryHeader className="text-sm text-left">Vendor</TertiaryHeader>
      {vendorList.length > 0 &&
        vendorList?.map((vendor, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <p key={`${vendor?.companyName}-${i}`} className="text-sm">
              {vendor?.companyName}
            </p>
          );
        })}
    </div>
  );
}

// prop types
RequestGridViewVendorCell.propTypes = {
  vendorList: PropTypes.arrayOf({
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }),
};

RequestGridViewVendorCell.defaultProps = {
  vendorList: [],
};

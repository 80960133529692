import React from "react";
import { Link } from "react-router-dom";
import estateSpaceLogo from "../../../stories/assets/images/estatespace-logo-dark.svg";
import usePageNotFoundData from "./usePageNotFoundData";

const PageNotFound = () => {
  const { navigateBack } = usePageNotFoundData();

  const redirectCustomerSuccessPath = () => {
    return (
      <Link
        className="text-base font-medium text-center text-es-green"
        to="/contact-us"
        replace
      >
        Customer success
      </Link>
    );
  };

  const redirectHomePath = () => {
    return (
      <Link
        className="text-base font-medium text-center text-es-green"
        to="/"
        replace
      >
        Return home
      </Link>
    );
  };

  const redirectBackPath = () => {
    return (
      <button
        type="button"
        className="text-base font-medium text-center text-es-green"
        onClick={navigateBack}
      >
        Go back
      </button>
    );
  };

  return (
    <div className="flex items-center justify-center mt-64">
      <div className="flex flex-col max-w-lg">
        <img alt="EstateSpace Logo" src={estateSpaceLogo} className="w-full" />

        <p className="mt-8 text-base tracking-widest font-medium mx-8 text-center">
          Page was not found
        </p>

        <div
          className="mt-8 text-base tracking-widest font-light mx-8
          text-center"
        >
          The page you were looking for could not be found. If you believe this
          is an error, please contact {redirectCustomerSuccessPath()}.
          Otherwise, you can {redirectBackPath()} or {redirectHomePath()}.
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;

import React, { useEffect, useRef, useState, useCallback } from "react";
import * as yup from "yup";
import { ProjectAPI, PropertyAPI, RfiAPI } from "@griffingroupglobal/eslib-api";

import { cloneDeep } from "lodash";
import moment from "moment/moment";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import Checkbox from "../Checkbox/Checkbox";
import DatePicker from "../DatePicker/DatePicker";
import DeleteButton from "../DeleteButton/DeleteButton";
import Dropdown from "../Dropdown/Dropdown";
import Input from "../Input/Input";
import SimpleFileUpLoad from "../AssetForms/SimpleFileUpLoad";
import DisplayFileNames from "../../../Pages/Overviews/Sop/DisplayFileNames";
import SelectUserInterface from "../SelectUserInterface/SelectUserInterface";
import { getFullName } from "../../../helpers/Formatters";
import SubmittalTemplateModal from "../Submittals/SubmittalsModal/SubmittalTemplateModal";
import {
  DOCUMENT_ATTACHMENT_EXTENSIONS,
  INITIAL_REQUEST_TEMPLATE,
} from "../../../constants";
import RFIRequestWorkflow from "../Requests/RequestCreateFormComponents/RFIRequestWorkflow";
import PlusCircleButton from "../Buttons/PlusCircleButton/PlusCircleButton";
import { useAddRFI } from "../../../hooks/useRFIs";
import { usePropertiesOverview } from "../../../hooks/properties";
import { useProjectsOverview } from "../../../hooks/projects";

const RfiForm = ({
  closeCreateModal,
  associatedRef,
  associationValue,
  rfiForm,
  dispatch,
  onFinishSaving,
  filesToUpload,
  handleFilesAdded,
  handleFilesUpdated,
  handleFilesUploaded,
  removeAttachedFile,
  userDict,
}) => {
  const { mutate: addRfi } = useAddRFI();

  const [fullOptions, setFullOptions] = useState([]);

  const { propertiesDD } = usePropertiesOverview();
  const { projectsDD: projectDD } = useProjectsOverview();

  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [attachmentOpen, setAttachmentOpen] = useState(false);
  const [linksOpen, setLinksOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [memberOptions, setMemberOptions] = useState([]);
  const [templatesOptions, setTemplatesOptions] = useState([]);
  const [templatesDict, setTemplatesDict] = useState({});
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [requestTemplate, setRequestTemplate] = useState();
  const [defaultAssociationTemplate, setDefaultAssociationTemplate] =
    useState();

  const inputRefs = useRef({});

  useEffect(() => {
    setFullOptions(propertiesDD.concat(projectDD));
    return () => {};
  }, [projectDD, propertiesDD]);

  const resetTemplate = useCallback(() => {
    setRequestTemplate({
      ...cloneDeep(INITIAL_REQUEST_TEMPLATE),
      association: rfiForm?.association?.value,
    });
  }, [rfiForm?.association?.value]);

  const fetchDefaultTemplate = useCallback(async (association) => {
    const defaultProjectTemplateQuery = await RfiAPI.get({
      params: { association, isDefaultTemplate: true },
    });

    const defaultProjectTemplate = defaultProjectTemplateQuery.data.entries[0];
    setDefaultAssociationTemplate(defaultProjectTemplate);
  }, []);

  useEffect(() => {
    resetTemplate();
  }, [fetchDefaultTemplate, resetTemplate]);

  const handleAssociation = useCallback(
    async (value) => {
      const association = value?.value;
      const resourceName = value?.label;

      fetchDefaultTemplate(association);

      const associationType = value?.value.split("/")[0].toLowerCase();

      let associationMembers;
      if (associationType === "project") {
        const { data } = await ProjectAPI.getById(value?.id);
        associationMembers = data.members.map((m) => ({
          label: getFullName(userDict[m.user]?.name) ?? "",
          value: m.user,
        }));
      }

      if (associationType === "property") {
        const { data } = await PropertyAPI.getById(value?.id);
        associationMembers = data.members.map((m) => ({
          label: getFullName(userDict[m.user]?.name) ?? "",
          value: m.user,
        }));
      }

      const { data: associationTemplates } = await RfiAPI.get({
        params: { association, isTemplate: true },
      });

      const mappedAssociationTemplates = associationTemplates.entries.map(
        ({ resource }) => {
          return {
            label: resource.name,
            value: resource.id,
          };
        }
      );

      setTemplatesOptions([
        ...mappedAssociationTemplates,
        {
          label: defaultAssociationTemplate?.name,
          value: defaultAssociationTemplate?.id,
        },
        { label: "Create new ...", value: "createNew" },
      ]);

      let templatesDictionary = associationTemplates.entries.reduce(
        (acc, val) => {
          const { resource } = val;
          return {
            ...acc,
            [resource.id]: resource.requestWorkflow,
          };
        },
        {}
      );
      templatesDictionary = {
        ...templatesDictionary,
        [defaultAssociationTemplate?.id]:
          defaultAssociationTemplate?.requestWorkflow,
      };

      setTemplatesDict(templatesDictionary);

      setMemberOptions(associationMembers);

      dispatch({
        type: "association",
        value: association,
      });
      dispatch({
        type: "resourceName",
        value: resourceName,
      });
      dispatch({
        type: "requestWorkflow",
        value: defaultAssociationTemplate?.requestWorkflow,
      });
    },
    [
      defaultAssociationTemplate?.id,
      defaultAssociationTemplate?.name,
      defaultAssociationTemplate?.requestWorkflow,
      dispatch,
      fetchDefaultTemplate,
      userDict,
    ]
  );

  const handleClearAssociation = () => {
    dispatch({
      type: "assocation",
      value: "",
    });
    dispatch({
      type: "resourceName",
      value: "",
    });
    dispatch({
      type: "requestWorkflow",
      value: [],
    });
  };

  const handleRfiCustomName = (value) => {
    dispatch({
      type: "customName",
      value,
    });
  };

  const handleRfiDescription = (value) => {
    dispatch({
      type: "description",
      value,
    });
  };

  const handleRfiDateChange = (value) => {
    dispatch({
      type: "projectedStartDate",
      value,
    });
  };

  const handleRfiPrivate = (value) => {
    dispatch({
      type: "private",
      value,
    });
  };

  const onChangeLink = (link) => {
    dispatch({
      type: "changeLink",
      link,
    });
  };

  const onAddLink = useCallback(() => {
    dispatch({
      type: "addLink",
    });
  }, [dispatch]);

  const onDeleteLink = (linkId) => {
    dispatch({
      type: "deleteLink",
      linkId,
    });
  };

  const handleResourceDDValue = () => {
    return fullOptions.find((item) => item.value === rfiForm?.association);
  };

  const handleForwardRef = (key, val) => {
    if (inputRefs.current) inputRefs.current[key] = val;
  };

  const createButtonHandler = (value) => {
    onFinishSaving(rfiForm, value);
  };

  const handleGenerateEmptyStep = (num) => {
    const emptyStepObj = {
      position: num,
      description: "",
      duration: {
        projected: 0,
      },
      users: [],
      attachments: [],
    };
    return emptyStepObj;
  };

  const handleAddNewWorkflow = () => {
    dispatch({
      type: "addRequestWorkflow",
      value: defaultAssociationTemplate.requestWorkflow,
    });
  };

  const handleAddDistroList = (value) => {
    dispatch({
      type: "updateDistroList",
      value,
    });
  };

  const handleRemoveDistroList = (value) => {
    const updatedDistroList = rfiForm?.distroList?.filter(
      (list) => list.value !== value
    );
    dispatch({
      type: "updateDistroList",
      value: updatedDistroList,
    });
  };

  const addStep = (stepState, setStepState) => {
    const tempSubmittal = cloneDeep(stepState);
    let requestWorkflow = tempSubmittal?.requestWorkflow[0];
    const prevSteps = requestWorkflow?.steps;
    prevSteps.push(
      handleGenerateEmptyStep(
        prevSteps?.length + 1,
        moment(prevSteps[prevSteps?.length - 1]?.dueDate?.projected)
          .add(10, "days")
          .format()
      )
    );
    requestWorkflow = { ...requestWorkflow, steps: prevSteps };
    setStepState({ ...stepState, requestWorkflow: [requestWorkflow] });
  };

  // Adds a new step at the bottom of the workflowSteps
  const handleAddNewStep = (templateStep) => {
    if (templateStep) {
      addStep(requestTemplate, setRequestTemplate);
    }
  };

  // Check form fields for enabling the initiate button only if they have value
  useEffect(() => {
    if (
      rfiForm?.customName &&
      rfiForm?.association &&
      rfiForm?.startDate &&
      rfiForm?.requestWorkflow
    ) {
      setFormOpen(true);
    } else {
      setFormOpen(false);
    }
  }, [rfiForm]);

  const handleCloseModal = useCallback(() => {
    resetTemplate();
    setIsTemplateModalOpen((prev) => !prev);
  }, [resetTemplate]);

  const createSubmittalTemplate = useCallback(
    async (isDraft) => {
      try {
        if (isDraft) requestTemplate.status = "draft";
        requestTemplate.association = rfiForm?.association;
        addRfi(requestTemplate);
        handleCloseModal();
      } catch (error) {
        console.warn("ERROR Creating template");
      }
    },
    [handleCloseModal, requestTemplate, rfiForm?.association, addRfi]
  );

  const handleDeleteTemplateStep = (index) => {
    setRequestTemplate((prev) => {
      const updatedSteps = requestTemplate?.requestWorkflow[0]?.steps?.filter(
        (_s, idx) => idx !== index
      );
      return {
        ...prev,
        requestWorkflow: [{ steps: updatedSteps }],
      };
    });
  };

  return (
    <>
      <>
        <Dropdown
          options={[
            { label: "Projects", options: projectDD },
            { label: "Properties", options: propertiesDD },
          ]}
          onChange={(value) => handleAssociation(value)}
          label="Association"
          placeholder="Select"
          validation={yup.string().required()}
          value={!associatedRef ? handleResourceDDValue() : associationValue}
          onRequestDropdownClear={() => handleClearAssociation()}
          isDisabled={associatedRef}
        />
        <Input
          placeholder="Name"
          className="mt-4"
          label="Name"
          value={rfiForm?.customName}
          validation={yup.string().required()}
          onChange={(e) => handleRfiCustomName(e)}
        />
        <div className="flex justify-between mt-4">
          <div className="w-full pr-4">
            <DatePicker
              label="Start Date"
              onChange={handleRfiDateChange}
              value={rfiForm?.startDate?.projected}
              validation={yup.date()}
            />
          </div>
          <div className="w-full">
            <SelectUserInterface
              className="w-full flex flex-col-reverse ml-4"
              avatarClassName="w-6 h-6"
              userList={rfiForm?.distroList}
              userLabel="Distro List"
              userPlaceholder="Search Members"
              updateSelect={(val) => val}
              userOptions={memberOptions}
              onAddUser={(val) => handleAddDistroList(val)}
              onRemoveUser={(val) => handleRemoveDistroList(val)}
              disableCreateUser
            />
          </div>
        </div>
        <div className="mt-4">
          <Checkbox
            label="Private"
            onChange={handleRfiPrivate}
            checked={rfiForm?.private}
          />
        </div>
        <div>
          <PlusCircleButton
            title="Add Description"
            onClick={() => setDescriptionOpen(!descriptionOpen)}
          />
          {descriptionOpen && (
            <div className="flex">
              <Input
                placeholder="Description"
                mainClassName="w-4/5 mr-4"
                label="RFI Description"
                value={rfiForm?.description}
                onChange={(e) => handleRfiDescription(e)}
              />
              <TertiaryButton
                title="Cancel"
                className="w-1/5 mt-7"
                onClick={() => setDescriptionOpen(!descriptionOpen)}
              />
            </div>
          )}
        </div>
        <div>
          <PlusCircleButton
            title="Add Attachment"
            onClick={() => {
              setAttachmentOpen(!attachmentOpen);
              document.querySelector("#rfi_attachment input")?.click();
            }}
          />
          <div className="flex">
            <div className="hidden" id="rfi_attachment">
              <SimpleFileUpLoad
                extensions={DOCUMENT_ATTACHMENT_EXTENSIONS}
                files={filesToUpload}
                onFilesAdded={handleFilesAdded}
                onFilesUpdated={handleFilesUpdated}
                onFilesUploaded={handleFilesUploaded}
                customUploadAreaStyle={{ marginRight: "0px" }}
              />
            </div>
            <DisplayFileNames
              filesToUpload={filesToUpload}
              removeAttachedFile={removeAttachedFile}
            />
          </div>
        </div>
        <div>
          <PlusCircleButton
            title="Add Links"
            onClick={() => {
              setLinksOpen((prev) => !prev);
              onAddLink();
            }}
          />
          {linksOpen &&
            rfiForm?.links?.map((link) => (
              <div
                className="flex items-center justify-between w-full"
                key={link.id}
              >
                <Input
                  placeholder="Link Url"
                  className="my-2 mr-2"
                  label="Link URL"
                  value={link?.url}
                  onChange={(url) => onChangeLink({ ...link, url })}
                />
                <Input
                  placeholder="Link Name"
                  className="my-2"
                  label="Link Name"
                  value={link?.name}
                  onChange={(name) => onChangeLink({ ...link, name })}
                />
                <SecondaryButton
                  className="mt-4 ml-2"
                  title="Add Link"
                  onClick={onAddLink}
                />
                <div className="mt-4 flex items-center">
                  <DeleteButton onConfirm={() => onDeleteLink(link.id)} />
                </div>
              </div>
            ))}
        </div>

        {rfiForm?.association && (
          <>
            {rfiForm?.requestWorkflow?.map((workflow, workflowIndex) => {
              return (
                <RFIRequestWorkflow
                  key={workflow?.id}
                  workflow={workflow}
                  workflowIndex={workflowIndex}
                  dispatch={dispatch}
                  rfiForm={rfiForm}
                  memberOptions={memberOptions}
                  templatesDict={templatesDict}
                  templatesOptions={templatesOptions}
                  defaultAssociationTemplate={defaultAssociationTemplate}
                  setIsTemplateModalOpen={setIsTemplateModalOpen}
                />
              );
            })}
            <TertiaryButton
              className="w-full border-dashed border-gray-150 border-2 h-16 rounded-md mb-12 text-lg font-semibold"
              title="+ Add New Worflow"
              onClick={() => handleAddNewWorkflow()}
              noMaxWidth
            />
          </>
        )}
      </>
      <div className="flex justify-end mt-6 mb-2">
        <TertiaryButton
          className="mr-6"
          title="Cancel"
          onClick={() => {
            dispatch({
              type: "reset",
            });
            closeCreateModal();
          }}
          name="cancelButton"
          forwardedRef={(el) => handleForwardRef("cancelButton", el)}
        />
        <SecondaryButton
          title="Save as draft"
          className="mr-2"
          onClick={() => createButtonHandler("draft")}
          isLoading={false}
          disabled={!rfiForm?.customName?.length}
          name="createButton"
          forwardedRef={(el) => handleForwardRef("createButton", el)}
          handleEnter={() => createButtonHandler("draft")}
        />
        <PrimaryButton
          title="Initiate"
          onClick={() => createButtonHandler("in-progress")}
          isLoading={false}
          disabled={!formOpen}
          name="createButton"
          forwardedRef={(el) => handleForwardRef("createButton", el)}
          handleEnter={() => createButtonHandler("in-progress")}
        />
      </div>
      <SubmittalTemplateModal
        title="RFI Template"
        isModalOpen={isTemplateModalOpen}
        handleCloseModal={handleCloseModal}
        memberOptions={memberOptions}
        template={requestTemplate}
        setTemplate={setRequestTemplate}
        canCreateTemplate
        createSubmittalTemplate={createSubmittalTemplate}
        handleAddNewStep={handleAddNewStep}
        handleDeleteStepClick={handleDeleteTemplateStep}
      />
    </>
  );
};

export default RfiForm;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { ProjectAPI } from "@griffingroupglobal/eslib-api";

import { useProjectsOverview } from "../../../hooks/projects";
import { usePropertiesOverview } from "../../../hooks/properties";
import { useAssets } from "../../../hooks/assets";

import Dropdown from "../Dropdown/Dropdown";

const SimpleResourceDropDown = ({
  resource,
  setResource,
  setInvitees,
  required,
  showTextView,
  disableAssociation,
  noLabel,
  defaultValue,
  isMaintenance,
  setProjectCSICodes,
  validation,
  financialsConfiguration,
  setSelectedCsiOption,
}) => {
  const { propertiesDD: propertyDD } = usePropertiesOverview();
  const { projectsDD: projectDD } = useProjectsOverview();

  const { data: assets } = useAssets();
  const [options, setOptions] = useState([]);
  const [optionsFull, setOptionsFull] = useState([]);
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    const assetDD = assets?.map((info) => ({
      label: info?.name,
      value: info?.reference,
      project: info?.project,
      property: info?.property,
    }));

    const tempOptions = isMaintenance
      ? [
          { label: "Assets", options: assetDD },
          { label: "Properties", options: propertyDD },
        ]
      : [
          { label: "Assets", options: assetDD },
          { label: "Projects", options: projectDD },
          { label: "Properties", options: propertyDD },
        ];
    setOptions(tempOptions);
    setOptionsFull(
      tempOptions.reduce((arr, item) => {
        return [...arr, ...(item.options ?? [])];
      }, [])
    );
  }, [propertyDD, assets, isMaintenance, projectDD]);

  const onChange = async (option) => {
    // reset csiCode state and CSI option
    setProjectCSICodes([]);
    setSelectedCsiOption(null);

    setSelectedOption(option);
    let resourceType = option.value?.split("/")[0].toLowerCase();
    if (typeof resource === "string") {
      resourceType = resourceType ?? resource.split("/")[0].toLowerCase();
    } else {
      resourceType =
        resourceType ?? resource?.value?.split("/")[0].toLowerCase();
    }

    const resourceName = option.label;
    const val = option.value ?? undefined;
    let assetParent;
    if (option?.property) {
      assetParent = { property: option?.property };
    } else if (option?.project) {
      assetParent = { project: option?.project };
    }

    if (resourceType === "project") {
      const projectId = val.split("/")[1];
      const project = await ProjectAPI.getByIdWOP(
        projectId,
        "$getfinancialcodes"
      );
      if (project.data[val]?.useall) {
        // this project uses all the csi codes
        const csiCodeMappingObject =
          financialsConfiguration?.financials?.csiCodeMappingObject;
        const projectCSICodes = Object.keys(csiCodeMappingObject)?.map(
          (key) => ({
            label: `${key} - ${csiCodeMappingObject[key]}`,
            value: key,
          })
        );
        setProjectCSICodes(projectCSICodes);
      } else {
        // this project uses csi codes retuned by the $getfinancialcodes directive
        const projectCSICodes = project.data[val].codes?.map((c) => {
          const key = c.key.split("-").join(" ");
          return {
            label: `${key} - ${c.subcodeDescription}`,
            value: key,
          };
        });
        const uniqueProjectCSICodes = projectCSICodes.filter(
          (elem, index) =>
            projectCSICodes.findIndex((obj) => obj.value === elem.value) ===
            index
        );
        setProjectCSICodes(uniqueProjectCSICodes);
      }
    }

    setResource({ val, resourceType, resourceName, ...assetParent });
    setInvitees?.([]);
  };

  const onClear = () => {
    setResource(undefined);
  };

  const getDropdownValue = () => {
    let identifier = resource;
    if (resource && typeof resource !== "string") {
      identifier = resource.value;
    }

    if (showTextView) {
      const res = optionsFull.find((item) => item.value === identifier);
      return res?.label;
    }

    return optionsFull.find((item) => item.value === identifier);
  };

  const getLabel = () => {
    if (!noLabel) {
      return "Association";
    }
    return undefined;
  };

  return (
    <>
      {showTextView && getDropdownValue()}
      {!showTextView && (
        <Dropdown
          autoFocus
          tabIndex={0}
          options={options}
          label={getLabel()}
          labelClassName="text-gray-400"
          placeholder="Search Resources..."
          value={selectedOption || defaultValue}
          onChange={onChange}
          isDisabled={disableAssociation}
          onRequestDropdownClear={onClear}
          required={required}
          validation={
            validation ||
            yup
              .object()
              .shape({ label: yup.string(), value: yup.string() })
              .required()
          }
        />
      )}
    </>
  );
};

SimpleResourceDropDown.propTypes = {
  /**
   * current resource selected
   */
  resource: PropTypes.oneOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    PropTypes.string
  ),
  /**
   * function for setting the resource
   */
  setResource: PropTypes.func,
  /**
   * Change Label to include (Required)
   */
  required: PropTypes.bool,
  showTextView: PropTypes.bool,
  disableAssociation: PropTypes.bool,
  noLabel: PropTypes.bool,
  defaultValue: PropTypes.shape({}),
  isMaintenance: PropTypes.bool,
  setProjectCSICodes: PropTypes.func,
  setInvitees: PropTypes.func,
  validation: PropTypes.func,
  financialsConfiguration: PropTypes.shape({
    financials: PropTypes.shape({
      csiCodeMappingObject: PropTypes.shape({}),
    }),
  }),
  setSelectedCsiOption: PropTypes.func,
};

SimpleResourceDropDown.defaultProps = {
  resource: undefined,
  setResource: () => {},
  required: false,
  showTextView: false,
  disableAssociation: false,
  noLabel: false,
  defaultValue: undefined,
  isMaintenance: false,
  setProjectCSICodes: () => {},
  setInvitees: () => {},
  validation: undefined,
  financialsConfiguration: {},
  setSelectedCsiOption: () => {},
};

export default SimpleResourceDropDown;

import React, { useCallback, useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import PropTypes from "prop-types";
import TertiaryHeader from "../TextHeaders/TertiaryHeader";
import TertiaryButton from "../Buttons/TertiaryButton";
import plusIcon from "../../assets/images/plusIcon.svg";
import PreferenceItem from "./PreferenceItem";
import { useSop } from "../../../hooks/useSop";
import closeIcon from "../../assets/images/crossIcon.svg";
import IconButton from "../Buttons/IconButton";

const ContactPreferencesForm = ({
  contactPreferences,
  setContactPreferences,
  hideAddPreferencesButton,
  customPreference,
  saving,
  myProfile,
  setIsEditing,
  setTabIndex,
  onSave,
  contact,
  setEditingContact,
  isEditing,
}) => {
  const { data: sopData } = useSop({ selectOptions: true });
  const [availableCustomPreferences, setAvailableCustomPreferences] =
    useState();
  const [hovering, setHovering] = useState(false);

  const toggleEdit = () => {
    onSave();
    setTabIndex(1);
    if (contact) {
      setEditingContact((prev) => !prev);
    } else {
      setIsEditing((prev) => ({
        ...prev,
        preferences: !prev.preferences,
      }));
    }
  };

  const handleHovering = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    setHovering(value);
  };

  const handleBlur = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    setHovering(value);
  };

  useEffect(() => {
    if (customPreference) {
      setAvailableCustomPreferences(customPreference);
    }
  }, [customPreference]);

  const add = useCallback(
    (type) => {
      setContactPreferences((prev) => ({
        ...prev,
        [`${type}`]: [
          ...prev[type],
          {
            custom: true,
          },
        ],
      }));
    },
    [setContactPreferences]
  );

  const remove = useCallback(
    (id, type) => {
      let removedCustomPreference;
      setContactPreferences((prev) => ({
        ...prev,
        [`${type}`]: [
          ...prev[type].filter((pref, index) => {
            if (index === id) {
              removedCustomPreference = pref;
              return false;
            }
            return true;
          }),
        ],
      }));

      // Check to ensure we do not add empty options
      if (removedCustomPreference?.id) {
        setAvailableCustomPreferences((prev) => ({
          ...prev,
          [`${type}`]: _.uniqBy(
            [...prev[type], removedCustomPreference],
            "label"
          ),
        }));
      }
    },
    [setContactPreferences]
  );

  const change = useCallback(
    (id, name, value, link, linkType, type, uid) => {
      setContactPreferences((prev) => ({
        ...prev,
        [`${type}`]: prev[type]?.map((item, index) => {
          if (index === id) {
            if (!uid)
              return {
                ...item,
                label: name,
                value,
                link,
                linkType,
                id: uuidv4(),
              };
            return { ...item, label: name, value, link, linkType, id: uid };
          }
          return item;
        }),
      }));

      setAvailableCustomPreferences((prev) => ({
        ...prev,
        [`${type}`]: _.uniqBy(
          prev[type]?.filter((item) => item?.id !== uid),
          "label"
        ),
      }));
    },
    [setContactPreferences]
  );

  const renderPreferenceItems = (items, preference, preferences) => {
    const PreferenceItemArray = [];
    items?.forEach((item, index) => {
      if (item.selected || item.custom) {
        PreferenceItemArray.push(
          <PreferenceItem
            id={index}
            label={item.label}
            type={preference}
            value={item.value}
            link={item.link}
            linkType={item.linkType}
            onChange={(id, name, value, link, linkType, uid) =>
              change(
                id,
                name,
                value,
                link,
                linkType,
                preferences,
                uid || item.id
              )
            }
            options={availableCustomPreferences?.[preferences]}
            onRemove={(id) => remove(id, preferences)}
            isCustom={item.custom}
            saving={saving}
            sopData={sopData}
          />
        );
      }
    });
    return PreferenceItemArray;
  };

  return (
    <>
      {!myProfile && (
        <TertiaryHeader className="my-8">
          Remember this contact’s personal preferences by entering them below.
          You can skip this for now and add them later.
        </TertiaryHeader>
      )}
      <div className="grid grid-flow-row grid-cols-3 grid-rows-1 gap-4">
        <div
          className="p-6 border rounded"
          onMouseOver={(e) => handleHovering(e, true)}
          onFocus={(e) => handleHovering(e, true)}
          onMouseOut={(e) => handleHovering(e, false)}
          onBlur={(e) => handleBlur(e, false)}
          role="presentation"
        >
          <div className="flex justify-between items-center w-full">
            <h3 className="text-lg text-gray-300 font-medium">Likes</h3>
            <IconButton
              wrapperClassName={hovering ? "" : "hidden"}
              imgClassName={`${isEditing ? "w-4 h-4" : "w-6 h-6"}`}
              iconClassName={`${isEditing ? "w-4 h-4" : "w-6 h-6"}`}
              onClick={toggleEdit}
              icon={closeIcon}
              // icon={editIcon}
            />
          </div>
          <div className="border-b py-4 mb-3">
            {renderPreferenceItems(
              contactPreferences?.favorites?.map((f) => ({
                ...f,
                selected: true,
              })),
              "like",
              "favorites"
            )}
          </div>
          {!hideAddPreferencesButton && (
            <TertiaryButton
              iconLeft={<img alt="" src={plusIcon} />}
              title="Add like"
              className="whitespace-nowrap"
              onClick={() => add("favorites")}
              disabled={saving}
            />
          )}
        </div>
        <div
          className="p-6 border rounded"
          onMouseOver={(e) => handleHovering(e, true)}
          onFocus={(e) => handleHovering(e, true)}
          onMouseOut={(e) => handleHovering(e, false)}
          onBlur={(e) => handleBlur(e, false)}
          role="presentation"
        >
          <div className="flex justify-between items-center w-full">
            <h3 className="text-lg text-gray-300 font-medium">Dislikes</h3>
            <IconButton
              wrapperClassName={hovering ? "" : "hidden"}
              imgClassName={`${isEditing ? "w-4 h-4" : "w-6 h-6"}`}
              iconClassName={`${isEditing ? "w-4 h-4" : "w-6 h-6"}`}
              onClick={toggleEdit}
              icon={closeIcon}
            />
          </div>
          <div className="border-b py-4 mb-3">
            {renderPreferenceItems(
              contactPreferences?.dislikes?.map((d) => ({
                ...d,
                selected: true,
              })),
              "dislike",
              "dislikes"
            )}
          </div>
          {!hideAddPreferencesButton && (
            <TertiaryButton
              iconLeft={<img alt="" src={plusIcon} />}
              title="Add dislike"
              onClick={() => add("dislikes")}
              className="whitespace-nowrap"
              disabled={saving}
            />
          )}
        </div>
        <div className="p-6 border rounded">
          <div
            className="flex justify-between items-center w-full"
            onMouseOver={(e) => handleHovering(e, true)}
            onFocus={(e) => handleHovering(e, true)}
            onMouseOut={(e) => handleHovering(e, false)}
            onBlur={(e) => handleBlur(e, false)}
            role="presentation"
          >
            <h3 className="text-lg text-gray-300 font-medium">
              Special Conditions
            </h3>
            <IconButton
              wrapperClassName={hovering ? "" : "hidden"}
              imgClassName={`${isEditing ? "w-4 h-4" : "w-6 h-6"}`}
              iconClassName={`${isEditing ? "w-4 h-4" : "w-6 h-6"}`}
              onClick={toggleEdit}
              icon={closeIcon}
            />
          </div>
          <div className="border-b py-4 mb-3">
            {renderPreferenceItems(
              contactPreferences?.specials?.map((s) => ({
                ...s,
                selected: true,
              })),
              "special condition",
              "specials"
            )}
          </div>
          {!hideAddPreferencesButton && (
            <TertiaryButton
              iconLeft={<img alt="" src={plusIcon} />}
              title="Add special condition"
              className="whitespace-nowrap"
              onClick={() => add("specials")}
              disabled={saving}
            />
          )}
        </div>
      </div>
    </>
  );
};

const preferenceType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    link: PropTypes.string,
    linkType: PropTypes.string,
    id: PropTypes.string,
  })
);

ContactPreferencesForm.propTypes = {
  contactPreferences: PropTypes.shape({
    favorites: preferenceType,
    dislikes: preferenceType,
    specials: preferenceType,
  }),
  setContactPreferences: PropTypes.func,
  hideAddPreferencesButton: PropTypes.bool,
  customPreference: PropTypes.shape({
    favorites: preferenceType,
    dislikes: preferenceType,
    specials: preferenceType,
  }),
  saving: PropTypes.bool,
  myProfile: PropTypes.bool,
  setIsEditing: PropTypes.func,
  setTabIndex: PropTypes.func,
  onSave: PropTypes.func,
  contact: PropTypes.bool,
  setEditingContact: PropTypes.func,
  isEditing: PropTypes.bool,
};

ContactPreferencesForm.defaultProps = {
  contactPreferences: undefined,
  setContactPreferences: undefined,
  hideAddPreferencesButton: false,
  customPreference: undefined,
  saving: false,
  myProfile: false,
  setIsEditing: undefined,
  setTabIndex: undefined,
  onSave: undefined,
  contact: false,
  setEditingContact: undefined,
  isEditing: false,
};

export default ContactPreferencesForm;

import { useCallback, useState } from "react";

const INITIAL_REQUEST = {
  type: "",
  priority: "medium",
  issue: "",
  files: [],
  tasks: [],
  tags: [],
};

/**
 * Dispatcher hook to manage the state and functions related to a service request (SR) form
 * @param {Object} originalRequestForm initial SR if needed (when updating)
 */
const useServiceRequestDispatcher = (originalRequestForm) => {
  // State to handle SR data from inputs form
  const [requestForm, setRequestForm] = useState(
    originalRequestForm ?? INITIAL_REQUEST
  );

  /**
   * Set requestForm state using field and value
   * @param {string} field name of the property field
   * @param {string} value value of the property field
   */
  const handleChangeForm = useCallback((field, value) => {
    setRequestForm((current) => ({ ...current, [field]: value }));
  }, []);

  /**
   * Add/Remove tags from SR state
   * @param {String} operation could be add or remove
   * @param {String} tagReference tag reference: Tag/111-222-333-4444
   */
  const handleTags = useCallback(
    (operation = "add", tagReference) => {
      const currentTags = [...requestForm.tags];

      if (operation === "add") {
        currentTags.push(tagReference);
      }

      if (operation === "remove") {
        const indexToRemove = currentTags.indexOf(tagReference);
        if (indexToRemove !== -1) {
          currentTags.splice(indexToRemove, 1);
        }
      }

      handleChangeForm("tags", currentTags);
    },
    [requestForm?.tags, handleChangeForm]
  );

  /**
   * Add files to current SR
   */
  const handleAddFiles = useCallback((files) => {
    if (!files.length) return;

    setRequestForm((current) => ({
      ...current,
      files,
    }));
  }, []);

  /**
   * Remove single file in current SR
   */
  const handleRemoveFile = useCallback((fileId) => {
    if (!fileId) return;

    setRequestForm((current) => ({
      ...current,
      files: current.files.filter(({ id }) => id !== fileId),
    }));
  }, []);

  return {
    requestForm,
    setRequestForm,
    handleChangeForm,
    handleTags,
    handleAddFiles,
    handleRemoveFile,
  };
};

export default useServiceRequestDispatcher;

import PropTypes from "prop-types";
import React from "react";
import CrossBubble from "../CrossBubble";
import AttachmentItem from "./AttachmentItem";

const AttachmentsHorizontalList = ({
  files,
  isEditing,
  onFileRemoved,
  textCN,
}) => {
  return (
    <div className="flex flex-wrap gap-4">
      {files.map((file) => (
        <div key={file?.id} className="relative">
          {isEditing && (
            <CrossBubble
              positionCN="-top-2 -right-4"
              width={18}
              height={18}
              onClick={() => onFileRemoved(file)}
            />
          )}

          <AttachmentItem
            wrapperCN="w-full"
            fileRef={file?.ref}
            fileObject={file?.original}
            isEditing={isEditing}
            textCN={textCN}
          />
        </div>
      ))}
    </div>
  );
};

AttachmentsHorizontalList.defaultProps = {
  files: [],
  isEditing: false,
  onFileRemoved: () => {},
  textCN: undefined,
};

AttachmentsHorizontalList.propTypes = {
  /**
   * Files could be:
   * an array of ES files [{ref: File/1111-2222-3333, category: "Document"}]
   * or
   * an array of native files from picker [{ original: {}, id: 123455, name: "My File.jpeg", type: "jpeg", }]
   */
  files: PropTypes.arrayOf(
    PropTypes.oneOf([
      PropTypes.shape({ ref: PropTypes.string, category: PropTypes.string }),
      PropTypes.shape({
        original: PropTypes.shape({}),
        id: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
      }),
    ])
  ),

  isEditing: PropTypes.bool,
  onFileRemoved: PropTypes.func,
  textCN: PropTypes.string,
};

export default AttachmentsHorizontalList;

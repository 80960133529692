import React from "react";
import PropTypes from "prop-types";
import Modal from "../Modal/Modal";

const BudgetTableConfirmationModal = ({
  title,
  isOpen,
  text,
  primaryButtonTitle,
  primaryButtonOnClick,
  onRequestModalClose,
}) => {
  return (
    <Modal
      title={title}
      isOpen={isOpen}
      primaryButtonTitle={primaryButtonTitle}
      primaryButtonOnClick={primaryButtonOnClick}
      tertiaryButtonTitle="Cancel"
      onRequestModalClose={onRequestModalClose}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      hideFooter
    >
      <div className="flex flex-col">
        <p>{text}</p>
      </div>
    </Modal>
  );
};

BudgetTableConfirmationModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  text: PropTypes.string,
  primaryButtonTitle: PropTypes.string,
  primaryButtonOnClick: PropTypes.func,
  onRequestModalClose: PropTypes.func,
};

BudgetTableConfirmationModal.defaultProps = {
  title: undefined,
  isOpen: false,
  text: undefined,
  primaryButtonTitle: undefined,
  primaryButtonOnClick: undefined,
  onRequestModalClose: undefined,
};

export default BudgetTableConfirmationModal;

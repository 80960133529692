import _ from "lodash";

/**
 * Props object for the "Apply Role" dropdown.
 */
export default class RoleDropdownItemProps {
  name;

  id;

  permissions;

  label;

  value;

  static fromGetRolesResponse(response) {
    return response.entries
      .map((entry) => _.pick(entry.resource, ["name", "id", "permissions"]))
      .map(
        (resource) =>
          new RoleDropdownItemProps({
            id: resource.id,
            name: resource.name,
            permissions: resource.permissions,
          })
      )
      .filter((entry) => entry.name !== "Admin");
  }

  constructor({ name, id, permissions }) {
    this.name = name;
    this.id = id;
    this.permissions = permissions;
    this.label = name;
    this.value = id;
  }
}

import { useMemo } from "react";
import { SopAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { useAppState } from "../state/appState";
import { SET_SOPS, SET_SOPS_DICT } from "../constants";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import {
  configurationKeys,
  sopKeys,
  tagKeys,
} from "../config/reactQuery/queryKeyFactory";
import { toastError, toastMessage } from "../stories/Components/Toast/Toast";
import useSystemConfiguration from "./useSystemConfiguration";
import getCategory from "../helpers/Sop/getCategory";

const fetchSops = async (categories) => {
  const { data } = await SopAPI.get();
  return data?.entries?.map(({ resource }) => {
    const [category] = getCategory(categories, resource.category);
    return {
      ...resource,
      category,
    };
  });
};

const createSop = async ({ sop }) => {
  const { data } = await SopAPI.post(sop);
  return data;
};

const deleteSops = (sopsToDelete) => {
  return Promise.allSettled(
    sopsToDelete?.map(async (sop) => {
      await SopAPI.delete(sop.id);
    })
  );
};

/**
 * Query hook to fetch SOPs
 * @param {boolean} selectOptions
 * @returns query hook
 */
export const useSop = ({ selectOptions } = {}) => {
  const { data: systemConfiguration } = useSystemConfiguration();
  const [, dispatch] = useAppState();

  const cats = useMemo(() => {
    return systemConfiguration?.system?.sop?.category;
  }, [systemConfiguration?.system?.sop?.category]);

  return useAuthenticatedQuery({
    queryKey: sopKeys.sops,
    enabled: !!cats,
    queryFn: () => fetchSops(cats),
    onSuccess: (sopsData) => {
      dispatch({
        type: SET_SOPS,
        sops: sopsData,
        sopSelectOptions: selectOptions
          ? sopsData?.map((s) => ({
              label: s.name,
              value: s.name,
              name: s.name,
              sop: s.reference,
            }))
          : [],
      });

      const dict = sopsData.reduce(
        (acc, v) => ({ ...acc, [v.reference]: v }),
        {}
      );

      dispatch({
        type: SET_SOPS_DICT,
        sopsDict: dict,
      });
    },
  });
};

/**
 * Query mutation hook to create SOPs
 * @param {string} mutationKey mutation key to track progress
 * @returns mutation hook
 */
export const useCreateSop = (mutationKey) => {
  const [, dispatch] = useAppState();
  const { data: systemConfiguration } = useSystemConfiguration();
  const queryClient = useQueryClient();

  const cats = useMemo(() => {
    return systemConfiguration?.system?.sop?.category;
  }, [systemConfiguration?.system?.sop?.category]);

  return useMutation({
    mutationKey,
    mutationFn: createSop,
    onSuccess: (data, { selectOptions }) => {
      const [category] = getCategory(cats, data.category);

      const sops = queryClient.getQueryData(sopKeys.sops);
      const sopsData = [{ ...data, category }, ...sops];

      dispatch({
        type: SET_SOPS,
        sops: sopsData,
        sopSelectOptions: selectOptions
          ? sopsData?.map((s) => ({
              label: s.name,
              value: s.name,
              name: s.name,
              sop: s.reference,
            }))
          : [],
      });

      const dict = sopsData.reduce(
        (acc, v) => ({ ...acc, [v.reference]: v }),
        {}
      );

      dispatch({
        type: SET_SOPS_DICT,
        sopsDict: dict,
      });

      // update tags in s&c
      queryClient.invalidateQueries(tagKeys.tags);
    },
    onError: (error) => {
      console.error("Error creating SOP", error);
    },
    onSettled: () => {
      queryClient.invalidateQueries(sopKeys.sops);
      // Invalidate system settings to update sopcategories counter
      queryClient.invalidateQueries(configurationKeys("system"));
    },
  });
};

/**
 * Mutation hook to remove single/multiple SOPs
 * @param {String} mutationKey unique key to track mutations
 * @returns mutation hook
 */
export const useDeleteSOPs = (mutationKey) => {
  const [{ selectOptions }, dispatch] = useAppState();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey,
    mutationFn: ({ sopsToDelete }) => deleteSops(sopsToDelete),
    onSuccess: (results, { sopsToDelete }) => {
      const sops = queryClient.getQueryData(sopKeys.sops);
      const deleteRes = [];

      results?.forEach((result, idx) => {
        if (result?.status === "fulfilled") {
          deleteRes?.push(sopsToDelete[idx]?.id);
        }
      });

      const newSopsData = sops?.filter((sop) => !deleteRes?.includes(sop?.id));

      dispatch({
        type: SET_SOPS,
        sops: newSopsData,
        sopSelectOptions: selectOptions
          ? newSopsData?.map((s) => ({
              label: s.name,
              value: s.name,
              name: s.name,
              sop: s.reference,
            }))
          : [],
      });

      const dict = newSopsData.reduce(
        (acc, v) => ({ ...acc, [v.reference]: v }),
        {}
      );

      dispatch({
        type: SET_SOPS_DICT,
        sopsDict: dict,
      });

      if (results)
        toastMessage(
          `${
            sopsToDelete?.length > 1
              ? "Some or all selected SOPs were"
              : "Selected SOP"
          } successfully deleted`
        );
    },
    onError: (error, { sopsToDelete }) => {
      toastError(
        `Error deleting ${
          sopsToDelete?.length > 1 ? "some selected SOPs" : "selected SOP"
        }`
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(sopKeys.sops);
      // Invalidate system settings to update sopcategories counter
      queryClient.invalidateQueries(configurationKeys("system"));
    },
  });
};

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { PermissionAPI, RoleAPI } from "@griffingroupglobal/eslib-api";

import SecondaryButton from "../../stories/Components/Buttons/SecondaryButton";
import RolePermissionsList from "./RolePermissionsList";
import RoleMembersAvatarList from "./RoleMembersAvatarList";
import { transformUsersResponseToUserAvatars } from "../../helpers/User";
import { fetchRoleMembers } from "./EditRole";
import PrimaryButton from "../../stories/Components/Buttons/PrimaryButton";
import { ROLES_PERMISSIONS_EDIT_PATH } from "../../constants";
import {
  isPermissionSelected,
  extractAdministrativePermissions,
} from "../../helpers/Permissions";

/**
 * Renders the "View Role" page for Roles & Permissions.
 */
function ViewRole() {
  const { roleId } = useParams();
  const history = useHistory();
  const [role, setRole] = useState();
  const [roleMembers, setRoleMembers] = useState([]);
  const [allResourcePermissions, setAllResourcePermissions] = useState({});
  const [expandedResources, setExpandedResources] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState({});

  /**
   * When a resource is toggled, add or remove it from the expandedResources list.
   */
  const onResourceExpansionToggled = (resource) => {
    if (expandedResources.indexOf(resource) < 0) {
      setExpandedResources([...expandedResources, resource]);
    } else {
      setExpandedResources(expandedResources.filter((res) => res !== resource));
    }
  };

  useEffect(() => {
    Promise.all([
      PermissionAPI.getWOP("$master"),
      RoleAPI.getById(roleId, {}),
      fetchRoleMembers(roleId),
    ])
      .then(([permissionsResult, roleResult, roleMembersResponse]) => {
        setAllResourcePermissions(
          extractAdministrativePermissions(permissionsResult.data)
        );

        setRole(roleResult.data);

        setSelectedPermissions(
          extractAdministrativePermissions(roleResult.data.permissions)
        );

        setRoleMembers(
          transformUsersResponseToUserAvatars(roleMembersResponse)
        );
      })
      .catch((err) => console.error(`ViewRole#useEffect - error:${err}`));
  }, [roleId]);

  return (
    <div className="roles-permission-create-role flex flex-col">
      <div className="flex pb-4 justify-between border-b-2 border-gray-150">
        <h1 className="text-3xl font-semibold tracking-widest">
          View Role Permissions
        </h1>
        {role && role.custom && (
          <PrimaryButton
            title="Edit"
            onClick={() => history.push(ROLES_PERMISSIONS_EDIT_PATH(roleId))}
          />
        )}
      </div>

      <div className="flex flex-row mt-8 space-x-2 items-end justify-start">
        <p className="text-base font-normal tracking-wide flex-1 max-w-1/2">
          For all features, members may only see what they’re associated with.
          Members may only see entities, projects, and properties they have been
          invited to.
        </p>
      </div>

      <p className="text-xl font-medium tracking-widest text-gray-900 uppercase mt-6">
        {role?.name}
      </p>

      <div className="flex flex-row justify-end space-x-2 pb-4 mt-4 border-b-2 border-gray-100">
        <RoleMembersAvatarList
          members={roleMembers}
          className="flex-1 relative"
        />
        <SecondaryButton
          title="Expand All"
          onClick={() =>
            setExpandedResources(Object.keys(allResourcePermissions))
          }
        />
        <SecondaryButton
          title="Collapse All"
          onClick={() => setExpandedResources([])}
        />
      </div>

      <RolePermissionsList
        allResourcePermissions={allResourcePermissions}
        expandedResources={expandedResources}
        onResourceExpansionToggled={onResourceExpansionToggled}
        isPermissionSelected={isPermissionSelected(selectedPermissions)}
        onPermissionSelected={() => {}}
        disableCheckboxes
      />
    </div>
  );
}

ViewRole.propTypes = {};

export default ViewRole;

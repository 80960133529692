import React, { useState } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import cntl from "cntl";

import dogEarDashed from "../../assets/images/dogEarDashed.svg";
import dogEarDashedGreen from "../../assets/images/dogEarDashedGreen.svg";
import uploadArrow from "../../assets/images/uploadArrow.svg";
import { adjustableUploadStyle } from "../../../constants";
import AddAttachmentButtonNested from "../Buttons/AddAttachmentButtonNested";

const uploadAreaCN = (className) => cntl`
    flex
    flex-col
    items-center
    ${className}
    pb-6
    border-2
    border-dashed
    rounded
    hover:border-brandGreen
    cursor-pointer
`;

const simpleCN = (className, isRequestUpload) => cntl`
${isRequestUpload ?? ""}
${!isRequestUpload ?? `mt-2 ${className}`}
`;

const UploadArea = ({
  className,
  title,
  extensions,
  onFilesAdd,
  showCorner,
  customUploadAreaStyle,
  simple,
  shared,
  disabled,
  isRequestUpload,
  nestedButtonWrapperCN,
}) => {
  const [hovering, setHovering] = useState(false);

  const onDrop = (files) => {
    onFilesAdd(files);
  };

  return (
    <div
      className={
        simple ? simpleCN(className, isRequestUpload) : uploadAreaCN(className)
      }
      onMouseOver={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
      onFocus={() => {}}
      onBlur={() => {}}
      style={
        simple
          ? { ...customUploadAreaStyle }
          : { ...adjustableUploadStyle, ...customUploadAreaStyle }
      }
    >
      {!simple && (
        <img
          className={`self-end w-20 h-8 select-none ${
            !showCorner ? "invisible" : ""
          }`}
          style={{ margin: "-3px -25px 0 0" }}
          src={hovering ? dogEarDashedGreen : dogEarDashed}
          alt=""
        />
      )}
      {simple && (
        <Dropzone accept={extensions} onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getRootProps({
                className: "upload_area_click w-full h-full z-40",
              })}
            >
              <AddAttachmentButtonNested
                nestedButtonWrapperCN={nestedButtonWrapperCN}
                shared={shared}
                disabled={disabled}
                getInputProps={getInputProps}
                isRequestUpload={isRequestUpload}
              />
            </div>
          )}
        </Dropzone>
      )}
      {!simple && (
        <Dropzone accept={extensions} onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div {...getRootProps({ className: "upload_area_click w-full" })}>
              <div className="flex flex-col items-center">
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <input {...getInputProps()} />

                <p className="-mt-2 text-base text-gray-300 font-semibold">
                  {title || "+ New File"}
                </p>
                <img
                  className="h-10 mt-6"
                  src={uploadArrow}
                  alt="upload arrow"
                />
                <div className="flex flex-col items-center mt-8 text-sm">
                  <p>Drag &amp; Drop</p>
                  <p>
                    or <span className="text-brandGreen">Browse</span>
                  </p>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
      )}
    </div>
  );
};

UploadArea.propTypes = {
  /**
   * container classes
   */
  className: PropTypes.string,
  /**
   * optional title of the section
   */
  title: PropTypes.string,
  /**
   * optional accepted file types
   */
  extensions: PropTypes.string,
  /**
   * function for returning added files
   */
  onFilesAdd: PropTypes.func,
  /**
   * show bent corner
   */
  showCorner: PropTypes.bool,
  customUploadAreaStyle: PropTypes.shape({}),
  /**
   * Flag to use PlusCircleButton instead of whole area drag and drop
   */
  simple: PropTypes.bool,
  disabled: PropTypes.bool,
  shared: PropTypes.bool,
  isRequestUpload: PropTypes.bool,
  nestedButtonWrapperCN: PropTypes.string,
};

UploadArea.defaultProps = {
  disabled: false,
  simple: false,
  className: undefined,
  title: undefined,
  extensions: undefined,
  onFilesAdd: undefined,
  showCorner: false,
  customUploadAreaStyle: {},
  shared: false,
  isRequestUpload: false,
  nestedButtonWrapperCN: "",
};

export default UploadArea;

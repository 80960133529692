import React from "react";
import { components } from "react-select";
import PillsContainer from "../PillsContainer";
import Checkbox from "../../Checkbox/Checkbox";
import Chevron from "../../Buttons/Chevron";
import useExpandableOptionData from "./useExpandableOptionData";
import { PRIMARY_DARK_GREEN } from "../../../../constants";

const STYLE = {
  color: "inherit",
  display: "flex",
  flexDirection: "column",
};

const ExpandableCheckboxOptionWithPills = ({
  subOptions,
  accordionStates,
  setAccordionStates,
  selectedPills,
  setSelectedPills,
  handlePillSelected,
  ...props
}) => {
  const {
    currentOption,
    isSelected,
    children,
    customInnerProps,
    handleAccordionClick,
  } = useExpandableOptionData({
    accordionStates,
    setAccordionStates,
    props,
  });

  return (
    <div>
      <components.Option
        {...props}
        innerProps={{ ...customInnerProps, style: STYLE }}
      >
        <div className="flex justify-between w-full items-center p-2">
          <div className="flex gap-x-4">
            <Checkbox onChange={() => {}} checked={isSelected} />
            <span>{children}</span>
          </div>
          {isSelected && (
            <button
              type="button"
              onClick={handleAccordionClick}
              aria-label="current option"
            >
              <Chevron
                color={PRIMARY_DARK_GREEN}
                strokeWidth="2px"
                rotate={
                  accordionStates.some(
                    (state) => state.id === currentOption.value.id
                  )
                    ? "-90"
                    : "90"
                }
              />
            </button>
          )}
        </div>
      </components.Option>

      {/* PillsContainer */}
      {isSelected &&
        accordionStates.some(
          (state) => state.id === currentOption.value.id
        ) && (
          <div className="w-full bg-white px-4 my-2">
            <PillsContainer
              data={
                subOptions.find((sub) => sub.id === currentOption.value.id)
                  ?.suboptions || []
              }
              selectedPills={selectedPills}
              onClick={handlePillSelected}
            />
          </div>
        )}
    </div>
  );
};

export default ExpandableCheckboxOptionWithPills;

import React, { useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";
import CommentSectionLineItem from "../Comments/CommentSectionLineItem";

const modalStyles = {
  content: {
    display: "flex",
    position: "relative",
    flex: "1",
    width: "700px",
    minWidth: "700px",
    maxWidth: "700px",
    height: "calc(100% - 72px)",
    margin: "72px 0 0 0",
    padding: "18px 4px 4px 4px",
    backgroundColor: "white",
    borderRadius: "0",
    inset: "0",
    overflow: "visible",
    transform: "translateX(100%)",
    transition: "transform 250ms ease-in-out",
    overflowX: "hidden",
  },
  overlay: {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "rgba(25, 25, 25, 0.8)",
    zIndex: "50",
  },
};

const BudgetTableComments = ({
  isOpen,
  handlePostComment,
  handlePostReply,
  onRequestModalClose,
  commentsData,
  currentUser,
  isLoadingComments,
  allowNewComment,
}) => {
  const [addComment, setAddComment] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) contentRef.current.focus();
  }, []);

  return (
    <ReactModal
      style={modalStyles}
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc={false}
      onRequestClose={onRequestModalClose}
      className={{
        base: "sidebar-content-base",
        afterOpen: "sidebar-content-after",
        beforeClose: "sidebar-content-before",
      }}
      closeTimeoutMS={250}
      shouldFocusAfterRender={false}
      onAfterOpen={() => contentRef.current.focus()}
    >
      <div className="flex w-full my-4 px-6">
        <CommentSectionLineItem
          currentUser={currentUser}
          data={commentsData}
          showNewCommentSection={addComment}
          setShowNewCommentSection={setAddComment}
          handleClose={onRequestModalClose}
          allowNewComment={allowNewComment}
          showClose
          handlePostComment={handlePostComment}
          handlePostReply={handlePostReply}
          isLoading={isLoadingComments}
        />
      </div>
    </ReactModal>
  );
};

BudgetTableComments.propTypes = {
  isOpen: PropTypes.bool,
  onRequestModalClose: PropTypes.func,
  associatedResources: PropTypes.shape({}),
  commentsData: PropTypes.arrayOf(PropTypes.shape({})),
  handlePostComment: PropTypes.func,
  handlePostReply: PropTypes.func,
  currentUser: PropTypes.shape({}),
  isLoadingComments: PropTypes.bool,
  allowNewComment: PropTypes.bool,
};

BudgetTableComments.defaultProps = {
  isOpen: false,
  onRequestModalClose: undefined,
  associatedResources: undefined,
  commentsData: [],
  handlePostComment: undefined,
  handlePostReply: undefined,
  currentUser: undefined,
  isLoadingComments: false,
  allowNewComment: true,
};

export default BudgetTableComments;

import React from "react";
import DragNDropFileUploader from "../../../stories/Components/DragNDropFileUploader";
import Gallery from "../../../stories/Components/Gallery";
import useMediaViewData from "./useMediaViewData";

const Media = ({
  isEditing,
  requestForm,
  filesState,
  handleFilesToAdd,
  handleFilesToRemove,
  noTitle,
  labelClassName,
}) => {
  const { mediaFiles, addNewFiles, removeFile } = useMediaViewData({
    requestForm,
    filesState,
    handleFilesToAdd,
    handleFilesToRemove,
  });

  return (
    <div className={`${!noTitle && "gap-10"} grid`}>
      {!noTitle && (
        <span className={`font-semibold text-lg ${labelClassName}`}>Media</span>
      )}

      <div className="flex gap-x-8">
        {isEditing && (
          <div>
            <DragNDropFileUploader handleFilesToAdd={addNewFiles} />
          </div>
        )}

        <Gallery
          isEditing={isEditing}
          mediaFiles={mediaFiles}
          handleFilesToRemove={removeFile}
        />
      </div>
    </div>
  );
};

export default Media;

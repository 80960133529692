/**
 * Remove empty `options` array property
 */
const sanitizeIncludeInformation = (report) => {
  // Create a deep copy of the object
  const updatedIncludeInformation = JSON.parse(JSON.stringify(report));

  Object.keys(updatedIncludeInformation).forEach((key) => {
    const includeInfo = updatedIncludeInformation[key];

    if (includeInfo?.selected) {
      // TODO: (Josymar) This is temporal while I look for a clean solution for options that don't require the "all" option
      // confirm with Chris
      if (
        key === "expenses" &&
        (!includeInfo.options || includeInfo.options.length === 0)
      ) {
        delete includeInfo.options;
      }

      // If `options` is not present or an empty array, set it to ["all"]
      else if (!includeInfo.options || includeInfo.options.length === 0) {
        includeInfo.options = ["all"];
      }
    } else if (includeInfo?.options && includeInfo.options.length === 0) {
      // If `options` is an empty array, remove the key from the object
      delete includeInfo.options;
    }

    // Check if the current key has an `status` property
    if (includeInfo?.status && includeInfo.status.length === 0) {
      // If `status` is an empty array, remove the key from the object
      delete includeInfo.status;
    }
  });

  return updatedIncludeInformation;
};

/**
 * Transform `assetsCategoriesData` to a custom data structure required in the backend
 */
const sanitizeAssets = (
  associationType,
  assetsCategoriesData = [],
  finalReportForm
) => {
  // Initialize assets object with a default value for 'selected'
  let assets = { selected: !!assetsCategoriesData.length };

  if (assetsCategoriesData.length) {
    const assetsOptions = assetsCategoriesData.flatMap(({ value }) => {
      const categoryOption = {
        category: value.id,
      };

      if (value.suboptions && value.suboptions.length) {
        return value.suboptions.map((subcategory) => ({
          ...categoryOption,
          subcategory,
        }));
      }

      return [categoryOption];
    });

    // Update the assets object with options
    assets = { ...assets, options: assetsOptions };
  }

  // Adding assets options to the final report object
  return {
    ...finalReportForm,
    configuration: {
      ...finalReportForm.configuration,
      [associationType]: {
        ...finalReportForm.configuration[associationType],
        assets,
      },
    },
  };
};

/**
 * Change name from `options` to `status` in submittals object
 */
const sanitizeSubmittals = (
  associationType,
  includeInformation,
  finalReportForm
) => {
  const submittals = {
    selected: includeInformation.submittals.selected,
    status: includeInformation.submittals.options,
  };

  // Adding submittals with `options` replaced to `status` to the final report object
  return {
    ...finalReportForm,
    configuration: {
      ...finalReportForm.configuration,
      [associationType]: {
        ...finalReportForm.configuration[associationType],
        submittals,
      },
    },
  };
};

export { sanitizeIncludeInformation, sanitizeAssets, sanitizeSubmittals };

import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Dropdown from "../Dropdown/Dropdown";
import FormHierarchyHorizontal from "../FormHierarchyHorizontal/FormHierarchyHorizontal";
import {
  DAYS_OF_WEEK,
  TS_PERIOD_LOCKED_MSG,
  TS_PERIOD_UNLOCKED_MSG,
} from "../../../constants";
import { timesheetSettingsValidation } from "../../../helpers/FormValidations";
import lockIcon from "../../assets/images/lockIcon.svg";
import { useAppState } from "../../../state/appState";
import exclamationGreen from "../../assets/images/exclamationGreen.svg";
import notiAlertIcon from "../../assets/images/notiAlertIcon.svg";

const TimesheetPeriodsSection = ({
  financialsSettings,
  dispatchFinancialsSettings,
}) => {
  const [{ financialsConfiguration }] = useAppState();

  const showLockedMessage = useMemo(() => {
    return financialsConfiguration?.financials?.period?.locked === true;
  }, [financialsConfiguration]);

  const periodValue = useMemo(() => {
    return {
      start: DAYS_OF_WEEK.find(
        (day) => day.value === financialsSettings?.period?.start
      ),
      end: DAYS_OF_WEEK.find(
        (day) => day.value === financialsSettings?.period?.end
      ),
    };
  }, [financialsSettings?.period?.end, financialsSettings?.period?.start]);
  const onChangePeriodStart = useCallback(
    (newValue) => {
      dispatchFinancialsSettings({
        type: "changePeriod",
        value: newValue.value,
      });
    },
    [dispatchFinancialsSettings]
  );

  return (
    <div>
      <div className="flex flex-row pb-4">
        <div className="flex w-1/2 p4-4 items-center">
          <FormHierarchyHorizontal>
            <Dropdown
              label="Period Start"
              value={periodValue.start}
              options={DAYS_OF_WEEK}
              onChange={onChangePeriodStart}
              placeholder="Start"
              disableSort
              validation={timesheetSettingsValidation(periodValue.start)}
              isDisabled={financialsSettings?.period?.locked}
              disableClear
            />
            <Dropdown
              label="Period End"
              value={periodValue.end}
              options={DAYS_OF_WEEK}
              placeholder="End"
              disableSort
              disableClear
              isDisabled
            />
          </FormHierarchyHorizontal>
          {financialsSettings?.period?.locked && (
            <img
              className="inline-block"
              src={lockIcon}
              style={{ marginLeft: "-2rem" }}
              alt="locked-payroll"
            />
          )}
        </div>
      </div>

      <div className="flex">
        <img
          className="mr-2"
          src={showLockedMessage ? notiAlertIcon : exclamationGreen}
          alt="exclamation"
        />
        <h1 className={showLockedMessage ? "text-es-red" : "text-es-green"}>
          {showLockedMessage ? TS_PERIOD_LOCKED_MSG : TS_PERIOD_UNLOCKED_MSG}{" "}
        </h1>
      </div>
    </div>
  );
};

TimesheetPeriodsSection.propTypes = {
  financialsSettings: PropTypes.shape({
    payroll: PropTypes.shape({
      period: PropTypes.string,
    }),
    period: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
      locked: PropTypes.bool,
    }),
  }),
  dispatchFinancialsSettings: PropTypes.func,
};

TimesheetPeriodsSection.defaultProps = {
  financialsSettings: undefined,
  dispatchFinancialsSettings: undefined,
};

export default TimesheetPeriodsSection;

import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import CurrentSubscription from "./CurrentSubscription";
import BillingContact from "./BillingContact";
import PaymentInformation from "./PaymentInformation";
import Plans from "./Plans";
import BillingCycle from "./BillingCycle";
import UserSeats from "./UserSeats";
import CancelSubscription from "./CancelSubscription";

const containerCN = cntl`
  flex
  flex-col
  w-full
  mt-5
`;

const innerCN = (className) => cntl`
  flex
  ${className}
`;

const PureBillingDashboard = ({
  plans,
  users,
  dispatch,
  subscriptionDetails,
  isEditingBilling,
  setIsEditingBilling,
  isEditingPayment,
  setIsEditingPayment,
  isEditingPlan,
  setIsEditingPlan,
  isEditingBillingCycle,
  setIsEditingBillingCycle,
  isEditingUserSeats,
  setIsEditingUserSeats,
  isCancellingSubscription,
  setIsCancellingSubscription,
  isMonthly,
  setIsMonthly,
  billingSettings,
  updateSettings,
}) => {
  return (
    <div className={containerCN}>
      <CurrentSubscription
        subscriptionDetails={subscriptionDetails}
        billingSettings={billingSettings}
        updateSettings={updateSettings}
      />
      <div className={innerCN("flex-row my-4")}>
        <BillingContact
          isEditingBilling={isEditingBilling}
          setIsEditingBilling={setIsEditingBilling}
          subscriptionDetails={subscriptionDetails}
          dispatch={dispatch}
        />
        <PaymentInformation
          isEditingPayment={isEditingPayment}
          setIsEditingPayment={setIsEditingPayment}
          subscriptionDetails={subscriptionDetails}
          dispatch={dispatch}
        />
      </div>
      <Plans
        plans={plans}
        setIsMonthly={setIsMonthly}
        isMonthly={isMonthly}
        selectedPlan={subscriptionDetails}
        cardStyle="rounded"
        activeUsers={users?.length}
        setIsEditingPlan={setIsEditingPlan}
        dispatch={dispatch}
        isEditingPlan={isEditingPlan}
      />
      <BillingCycle
        selectedPlan={subscriptionDetails}
        isMonthly={isMonthly}
        setIsMonthly={setIsMonthly}
        onClose={() => setIsEditingBillingCycle(false)}
        dispatch={dispatch}
        plans={plans}
        isOpen={isEditingBillingCycle}
      />
      <UserSeats
        activeUsers={users?.length}
        dispatch={dispatch}
        onClose={() => setIsEditingUserSeats(false)}
        selectedPlan={subscriptionDetails}
        isOpen={isEditingUserSeats}
      />
      <CancelSubscription
        onClose={() => setIsCancellingSubscription(false)}
        selectedPlan={subscriptionDetails}
        dispatch={dispatch}
        isOpen={isCancellingSubscription}
      />
    </div>
  );
};

PureBillingDashboard.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.shape({})),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  subscriptionDetails: PropTypes.shape({
    billingCycle: PropTypes.string,
  }),
  dispatch: PropTypes.func,
  isEditingBilling: PropTypes.bool,
  setIsEditingBilling: PropTypes.func,
  isEditingPayment: PropTypes.bool,
  setIsEditingPayment: PropTypes.func,
  isEditingPlan: PropTypes.bool,
  setIsEditingPlan: PropTypes.func,
  isEditingBillingCycle: PropTypes.bool,
  setIsEditingBillingCycle: PropTypes.func,
  isEditingUserSeats: PropTypes.bool,
  setIsEditingUserSeats: PropTypes.func,
  isCancellingSubscription: PropTypes.bool,
  setIsCancellingSubscription: PropTypes.func,
  isMonthly: PropTypes.bool,
  setIsMonthly: PropTypes.func,
  billingSettings: PropTypes.shape({}),
  updateSettings: PropTypes.func,
};

PureBillingDashboard.defaultProps = {
  plans: undefined,
  users: undefined,
  subscriptionDetails: undefined,
  dispatch: undefined,
  isEditingBilling: false,
  setIsEditingBilling: undefined,
  isEditingPayment: false,
  setIsEditingPayment: undefined,
  isEditingPlan: false,
  setIsEditingPlan: undefined,
  isEditingBillingCycle: false,
  setIsEditingBillingCycle: undefined,
  isEditingUserSeats: false,
  setIsEditingUserSeats: undefined,
  isCancellingSubscription: false,
  setIsCancellingSubscription: undefined,
  isMonthly: false,
  setIsMonthly: undefined,
  billingSettings: {},
  updateSettings: () => {},
};

export default PureBillingDashboard;

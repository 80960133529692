import React from "react";
import PropTypes from "prop-types";
import upArrow from "../../assets/images/arrowWhiteUp.svg";
import IconButton from "../Buttons/IconButton";

/**
 * @param {isVisilbe} Boolean Decides to show button or not
 * @param {onClick} Function Should bring view back to top of screen or container
 */

const ToTopButton = ({ isVisible, onClick }) => (
  <IconButton
    title="Back to Top"
    className={
      isVisible
        ? "block fixed bg-brandDarkGreen rounded-md"
        : "invisible hidden"
    }
    style={{ bottom: "1%", left: "90.5%", width: "161px", height: "44px" }}
    onClick={onClick}
    iconRight={
      <img src={upArrow} style={{ width: 24, height: 24 }} alt="Up Arrow" />
    }
    wrapperClassName="text-white"
  />
);

ToTopButton.propTypes = {
  /**
   * function called when the Button is clicked
   */
  onClick: PropTypes.func,
  /**
   * Boolean to show or hide button
   */
  isVisible: PropTypes.bool,
};

ToTopButton.defaultProps = {
  onClick: undefined,
  isVisible: false,
};

export default ToTopButton;

import { fetchProjects } from "../../helpers/Projects";
import useAuthenticatedQuery from "../useAuthenticatedQuery";
import projectKeys from "./projectKeys";

/**
 * Query hook to fetch all projects
 */
const useProjects = ({ select } = {}) => {
  return useAuthenticatedQuery({
    queryKey: projectKeys.all,
    select,
    queryFn: () => fetchProjects(),
    onError: (error) => {
      console.error("useProjects: Error fetching projects", error);
    },
  });
};

export default useProjects;

import React, { useEffect, useState } from "react";
import {
  WorkflowAPI,
  CommentAPI,
  HistoryAPI,
} from "@griffingroupglobal/eslib-api";

import { useAppState } from "../state/appState";
import { getDocumentData } from "../helpers/Document";
import { SET_WORKFLOWS, SET_COMMENTS } from "../constants";

const getWorkflowData = async (reference) => {
  if (!reference) {
    return "";
  }
  const newId = reference?.split("/")[1];
  const { data } = await WorkflowAPI.getById(newId);
  return data;
};

const getUserData = (allUsers, reference) => {
  if (!reference) {
    return "";
  }

  const found = allUsers?.find((user) => user.reference === reference);
  return found;
};

const getCommentData = (allComments, reference) => {
  if (!reference) {
    return "";
  }

  const found = allComments.find((comment) => comment.reference === reference);
  return found;
};

const handleGetParFiles = async (files) => {
  return files ? Promise.all(files) : [];
};

export const buildWorkflowView = async (obj, usersData, commentsData) => {
  const tempObj = obj;

  const commentObj = {};
  const { data: versions } = await WorkflowAPI.getWOP(`$versioninfo`, {
    params: {
      ids: tempObj.id,
    },
  });

  const formatedVersions =
    versions[0]?.versions?.map(async (info) => {
      const newMembers =
        info?.members?.map(async (member) => {
          const memberData = getUserData(usersData, member);
          return {
            ...memberData,
          };
        }) || [];
      const newFiles =
        info?.documents?.map(async (file) => {
          const fileData = await getDocumentData(file);
          return {
            ...fileData,
          };
        }) || [];
      const formatedMembers = await Promise.all(newMembers);
      const formatedFiles = await Promise.all(newFiles);

      return {
        ...info,
        isDraft: tempObj?.isDraft,
        members: formatedMembers,
        documents: formatedFiles,
        modifiedBy: info.modifiedBy
          ? getUserData(usersData, info.modifiedBy)
          : getUserData(usersData, tempObj.metadata.createdBy),
      };
    }) || [];
  tempObj.versions = await Promise.all(formatedVersions);
  try {
    const { data: history } = await HistoryAPI.get({
      params: {
        association: tempObj.reference,
      },
    });

    const formatedHistory =
      history?.entries?.map(async (info) => {
        const tempHistory = info.resource;
        if (tempHistory?.context?.comment) {
          tempHistory.commentData = getCommentData(
            commentsData,
            tempHistory?.context?.comment?.reference
          );
        }
        if (tempHistory?.context?.document?.reference) {
          tempHistory.fileData = await getDocumentData(
            tempHistory?.context?.document?.reference
          );
        }
        tempHistory.userData = getUserData(usersData, tempHistory.user);
        return tempHistory;
      }) || [];
    tempObj.history = await Promise.all(formatedHistory);
  } catch (err) {
    tempObj.history = [];
  }

  const { data: cmtsData } = await CommentAPI.get({
    params: {
      association: tempObj.reference,
    },
  });
  const currentComments = cmtsData.filter(
    (info) => tempObj?.version === info?.context?.workflow?.version
  );
  const formatedComments =
    currentComments?.map(async (info) => {
      const user = getUserData(usersData, info?.author);
      const formatedReplies =
        info?.replies?.map((reply) => ({
          ...reply,
          userData: getUserData(usersData, reply?.author),
        })) || [];
      const replies = await Promise.all(formatedReplies);
      // comment on a file
      let doc;
      if (info?.context?.workflow?.file) {
        doc = await getDocumentData(info?.context?.workflow?.file);
      }
      return {
        ...info,
        userData: user,
        replies,
        doc: doc?.customName || doc?.name,
      };
    }) || [];
  const allComments = await Promise.all(formatedComments);

  for (let i = 0; i < allComments.length; i += 1) {
    const key = allComments[i].context.workflow.step?.id;
    if (commentObj[key]) {
      commentObj[key].push(allComments[i]);
    } else {
      commentObj[key] = [allComments[i]];
    }
  }
  const workflowFiles =
    tempObj?.documents?.map(async (fileId) => {
      const fileData = await getDocumentData(fileId);
      return fileData;
    }) || [];
  const newMembers = tempObj?.members?.map((info) => {
    const memberData = getUserData(usersData, info);
    return {
      ...memberData,
    };
  }) || [[]];
  tempObj.members = await Promise.all(newMembers);
  if (tempObj?.documents?.length > 0) {
    tempObj.documents = await Promise.all(workflowFiles);
  }
  if (tempObj.completed) {
    const completedWF = getUserData(usersData, tempObj?.completed?.member);
    tempObj.completed.userData = completedWF;
  }
  const stepData =
    tempObj?.steps?.map(async (info) => {
      const stepFiles =
        info?.documents?.map(async (fileId) => {
          const fileData = await getDocumentData(fileId);
          const fileUserData = getUserData(
            usersData,
            fileData?.metadata?.createdBy
          );
          if (fileUserData) {
            fileData.metadata.userData = fileUserData;
          }
          return fileData;
        }) || [];
      const stepMembers =
        info?.members?.map((memberInfo) => {
          const memberData = getUserData(usersData, memberInfo?.reference);
          return {
            ...memberInfo,
            userData: memberData,
          };
        }) || [];

      const parallelSteps =
        info?.parallelSteps?.map(async (parallel) => {
          const parallelMemberData = parallel?.members?.map((parMembers) => {
            const memberParData = getUserData(usersData, parMembers?.reference);
            return {
              ...parMembers,
              userData: memberParData,
            };
          });
          const stepParFiles = parallel?.documents?.map(async (fileId) => {
            const fileData = await getDocumentData(fileId);
            const parFileUserData = getUserData(
              usersData,
              fileData?.metadata?.createdBy
            );
            if (parFileUserData) {
              fileData.metadata.userData = parFileUserData;
            }

            return fileData;
          });

          /* const formatedParFiles = await Promise.all(stepParFiles); */
          const formatedParFiles = await handleGetParFiles(stepParFiles);
          const formatedParMembers = await Promise.all(parallelMemberData);
          return {
            ...parallel,
            members: formatedParMembers,
            documents: formatedParFiles,
            comments: commentObj[parallel.id] || [],
          };
        }) || [];
      const formatedFiles = await Promise.all(stepFiles);
      const formatedMembers = await Promise.all(stepMembers);
      const formatedParallelSteps = await Promise.all(parallelSteps);
      return {
        ...info,
        members: formatedMembers,
        parallelSteps: formatedParallelSteps,
        documents: formatedFiles,
        comments: commentObj[info.id] || [],
      };
    }) || [];

  tempObj.steps = await Promise.all(stepData);
  return tempObj;
};

export const buildWorkflowTemplate = async (obj, usersData) => {
  const tempObj = obj;
  const stepData =
    tempObj?.steps?.map(async (info) => {
      const stepMembers =
        info?.members?.map((memberInfo) => {
          const memberData = getUserData(usersData, memberInfo?.reference);
          return {
            ...memberInfo,
            userData: memberData,
          };
        }) || [];

      const parallelSteps =
        info?.parallelSteps?.map(async (parallel) => {
          const parallelMemberData =
            parallel?.members?.map((parMembers) => {
              const memberParData = getUserData(
                usersData,
                parMembers?.reference
              );
              return {
                ...parMembers,
                userData: memberParData,
              };
            }) || [];
          const formatedParMembers = await Promise.all(parallelMemberData);
          return {
            ...parallel,
            members: formatedParMembers,
          };
        }) || [];
      const formatedMembers = await Promise.all(stepMembers);
      const formatedParallelSteps = await Promise.all(parallelSteps);
      return {
        ...info,
        members: formatedMembers,
        parallelSteps: formatedParallelSteps,
      };
    }) || [];

  tempObj.steps = await Promise.all(stepData);
  return tempObj;
};

export const useWorkflows = (queryParams = {}) => {
  const [{ workflows, users, comments }, dispatch] = useAppState();

  const [isLoading, setIsLoading] = useState(true);
  const [workflowsData, setWorkflowsData] = useState(workflows);
  const [userData, setUserData] = useState();
  const [commentData, setCommentData] = useState();

  useEffect(() => {
    if (workflows) {
      setWorkflowsData(workflows);
    }
  }, [workflows]);

  useEffect(() => {
    if (users?.length) {
      setUserData(users);
    }
  }, [users]);

  useEffect(() => {
    const fetchComments = async () => {
      const { data } = await CommentAPI.get();
      const commentList = data?.entries?.map((entry) => entry.resource) ?? [];
      setCommentData(commentList);
      dispatch({
        type: SET_COMMENTS,
        comments: commentList,
      });
    };

    if (comments) {
      setCommentData(comments);
    } else if (!commentData) {
      fetchComments();
    }
  }, [comments, commentData, dispatch]);

  const reload = React.useCallback(
    async (usrData, cmtData) => {
      setIsLoading(true);
      // fetch all workflows
      const { data } = await WorkflowAPI.get({
        params: {
          showTemplates: true,
        },
      });

      if (data?.entries) {
        const usersData = usrData ?? users;
        const commentsData = cmtData ?? comments;

        const workflowData =
          data?.entries?.map(async ({ resource }) => {
            let tempObj = resource;
            if (tempObj.completed) {
              const completedWF = getUserData(
                usersData,
                tempObj?.completed?.member
              );
              tempObj.completed.userData = completedWF;
            }
            tempObj.metadata.userData = getUserData(
              usersData,
              tempObj?.metadata?.createdBy
            );
            const associatedWF =
              tempObj?.associatedWorkflows?.map(async (info) => {
                try {
                  const associatedData = await getWorkflowData(info.reference);
                  return { ...info, workflowData: associatedData };
                } catch (err) {
                  console.warn("Associaed WF is missing", err);
                  return null;
                }
              }) || [];
            tempObj.associatedWorkflows = await Promise.all(associatedWF);

            tempObj.associatedWorkflows = tempObj.associatedWorkflows.filter(
              (wf) => !!wf
            );

            if (queryParams?.id && queryParams.id === tempObj.id) {
              tempObj = await buildWorkflowView(
                tempObj,
                usersData,
                commentsData
              );
            }

            if (queryParams.isTemplate) {
              tempObj = buildWorkflowTemplate(tempObj, usersData);
            }

            return tempObj;
          }) || [];

        const formatedWF = await Promise.all(workflowData);

        setWorkflowsData(formatedWF);
        dispatch({
          type: SET_WORKFLOWS,
          workflows: formatedWF,
        });
        setIsLoading(false);
      }
    },
    [queryParams, comments, users, dispatch]
  );

  useEffect(() => {
    const setWorkflows = async () => {
      const query = Object.entries(queryParams);
      const [key, val] = query.length ? query[0] : [null, null];
      let result;

      if (key) {
        result = workflows.filter((item) => item[key] === val);
      } else {
        result = workflows;
      }

      if (key === "id") {
        if (!result[0]?.steps[0]?.comments) {
          const finishedWF = await buildWorkflowView(
            result[0],
            userData,
            commentData
          );
          setWorkflowsData(finishedWF);
        } else {
          setWorkflowsData(result[0]);
        }
      } else {
        setWorkflowsData(result);
      }
      setIsLoading(false);
    };
    if (!workflows && userData && commentData) {
      reload(userData, commentData);
    } else if (workflows && userData && commentData) {
      setWorkflows();
    }
  }, [workflows, userData, commentData, queryParams, reload]);

  return [workflowsData, reload, isLoading, setWorkflowsData];
};

import React from "react";

import IconButton from "../Buttons/IconButton";
import ColumnFooter from "../BudgetTable/ColumnFooter";
import CurrencyView from "../CurrencyView/CurrrencyView";

import flagGreen from "../../assets/images/flagGreenIcon.svg";
import flagWhite from "../../assets/images/flagWhiteIcon.svg";
import fileIconGreen from "../../assets/images/fileIcon.svg";
import fileIconGray from "../../assets/images/fileIconGray150.svg";
import { formatNumber } from "../../../helpers/Formatters";
import { TABLE_COLUMN_WIDTH } from "../../../constants";
import BudgetTableNotesCell from "../BudgetTable/BudgetTableNotesCell";
import TableDescriptionCell from "../Table/TableDescriptionCell";

const onFavoriteClick = () => {};

export const detailedSummaryColumnsCP = (tab) => {
  const tabColumns = {
    detailedSummaryExecutive: [
      "flag",
      "notes",
      "csiCode",
      "controlEstimate",
      "committedToDate",
      "percentCommittedToDate",
      "workCompletedPreviousPeriod",
      "workCompletedThisPeriod",
      "workCompletedToDate",
      "percentCompleteOfBudget",
      "retainageAndHoldback",
      "workCompletedToDateLessRetainageAndHoldback",
      "paymentRequestedPreviousPeriod",
      "paymentRequestedThisPeriod",
      "balanceToFinishIncludingHoldbackAndRetainage",
      "uncommittedToDate",
    ],
    detailedSummaryBreakdown: [
      "flag",
      "notes",
      "financialDocument",
      "vendorName",
      "description",
      "committedToDate",
      "workCompletedPreviousPeriod",
      "workCompletedThisPeriod",
      "nonBillable",
      "workCompletedToDate",
      "percentCompleteOfCommittedValue",
      "retainage",
      "percentRetainage",
      "retainageReleasedThisPeriod",
      "holdback",
      "holdbackReleasedThisPeriod",
      "workCompletedToDateLessRetainageAndHoldback",
      "paymentRequestedPreviousPeriod",
      "paymentRequestedThisPeriod",
    ],
  };
  const columns = [
    {
      Header: "",
      accessor: "flag",
      editColumnModalHeader: "Flag",
      isPrimary: true,
      Cell: ({ row }) => (
        <IconButton
          onClick={() => {
            onFavoriteClick(row.original.id);
          }}
          icon={row.original.flag ? flagGreen : flagWhite}
        />
      ),
      sortType: ({ values: { flag: a } }, { values: { flag: b } }) => {
        if (a === b) {
          return 0;
        }
        return a ? -1 : 1;
      },
      width: TABLE_COLUMN_WIDTH,
      minWidth: TABLE_COLUMN_WIDTH,
      maxWidth: TABLE_COLUMN_WIDTH,
    },
    {
      Header: "",
      accessor: "notes",
      editColumnModalHeader: "Notes",
      isPrimary: true,
      Cell: ({ row }) => <BudgetTableNotesCell row={row} />,
      Aggregated: () => null,
      disableSortBy: true,
      width: TABLE_COLUMN_WIDTH,
      minWidth: TABLE_COLUMN_WIDTH,
      maxWidth: TABLE_COLUMN_WIDTH,
    },
    {
      Header: "CSI Code",
      accessor: "csiCode",
      isPrimary: true,
      width: 400,
    },
    {
      Header: "Description of Work",
      accessor: "description",
      Cell: ({ row }) => <TableDescriptionCell row={row} />,
      Aggregated: () => null,
    },
    {
      Header: "Control Estimate",
      accessor: "controlEstimate",
      Cell: ({ row: { original } }) => (
        <CurrencyView value={original.publishedBudget} />
      ),
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.publishedBudget);
        const bVal = formatNumber(b.values.publishedBudget);
        return aVal - bVal;
      },
      aggregate: "sum",
      Aggregated: ({ value }) => <CurrencyView value={value} />,
      width: 100,
    },
    {
      Header: "Committed to Date",
      accessor: "committedToDate",
      Cell: ({ row: { original } }) => (
        <CurrencyView value={original.committedValue} />
      ),
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.committedValue);
        const bVal = formatNumber(b.values.committedValue);
        return aVal - bVal;
      },
      aggregate: "sum",
      Aggregated: ({ value }) => <CurrencyView value={value} />,
      width: 160,
    },
    {
      Header: "% Committed to Date",
      accessor: "percentCommittedToDate",
      Cell: ({ row: { original } }) => (
        <CurrencyView value={original.percentCompleteOfCommittedValue} />
      ),
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.percentCompleteOfCommittedValue);
        const bVal = formatNumber(b.values.percentCompleteOfCommittedValue);
        return aVal - bVal;
      },
      aggregate: "sum",
      Aggregated: ({ value }) => <CurrencyView value={value} />,
      width: 160,
    },
    {
      Header: "% Complete Of Committed Value",
      accessor: "percentCompleteOfCommittedValue",
      Cell: ({ row: { original } }) => (
        <CurrencyView value={original.percentCompleteOfCommittedValue} />
      ),
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.percentCompleteOfCommittedValue);
        const bVal = formatNumber(b.values.percentCompleteOfCommittedValue);
        return aVal - bVal;
      },
      aggregate: "sum",
      Aggregated: ({ value }) => <CurrencyView value={value} />,
      width: 160,
    },
    {
      // Missing column
      Header: "Work Completed Previous Period",
      accessor: "workCompletedPreviousPeriod",
      Cell: ({ value }) => <CurrencyView value={value} />,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.workCompletedPreviousPeriod);
        const bVal = formatNumber(b.values.workCompletedPreviousPeriod);
        return aVal - bVal;
      },
      aggregate: "sum",
      Aggregated: ({ value }) => <CurrencyView value={value} />,
      width: 120,
    },
    {
      // Missing column
      Header: "Work Completed This Period",
      accessor: "workCompletedThisPeriod",
      Cell: ({ row: { original } }) => <CurrencyView value={original.wip} />,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.wip);
        const bVal = formatNumber(b.values.wip);
        return aVal - bVal;
      },
      aggregate: "sum",
      Aggregated: ({ value }) => <CurrencyView value={value} />,
      width: 120,
    },
    {
      Header: "Work Completed to Date",
      accessor: "workCompletedToDate",
      Cell: ({ value }) => <CurrencyView value={value} />,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.workCompletedToDate);
        const bVal = formatNumber(b.values.workCompletedToDate);
        return aVal - bVal;
      },
      aggregate: "sum",
      Aggregated: ({ value }) => <CurrencyView value={value} />,
      width: 120,
    },
    {
      Header: "% Complete Of Budget",
      accessor: "percentCompleteOfBudget",
      Cell: ({ value }) => <CurrencyView value={value} />,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.percentCompleteOfBudget);
        const bVal = formatNumber(b.values.percentCompleteOfBudget);
        return aVal - bVal;
      },
      aggregate: "sum",
      Aggregated: ({ value }) => <CurrencyView value={value} />,
      width: 120,
    },
    {
      Header: "Retainage and Holdback",
      accessor: "retainageAndHoldback",
      Cell: ({ row: { original } }) => (
        <CurrencyView value={original.retainage + original.holdback} />
      ),
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.retainage + a.values.holdback);
        const bVal = formatNumber(b.values.retainage + b.values.holdback);
        return aVal - bVal;
      },
      aggregate: "sum",
      Aggregated: ({ row: { original } }) => (
        <CurrencyView value={original.retainage + original.holdback} />
      ),
      width: 120,
    },
    {
      Header: "Work Completed to Date Less Retainage and Holdback",
      accessor: "workCompletedToDateLessRetainageAndHoldback",
      Cell: ({ row: { original } }) => (
        <CurrencyView
          value={
            original.workCompletedToDate -
            original.retainage -
            original.holdback
          }
        />
      ),
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(
          a.values.workCompletedToDate - a.values.retainage - a.values.holdback
        );
        const bVal = formatNumber(
          b.values.workCompletedToDate - b.values.retainage - b.values.holdback
        );
        return aVal - bVal;
      },
      aggregate: "sum",
      Aggregated: ({ row: { original } }) => (
        <CurrencyView
          value={
            original.workCompletedToDate -
            original.retainage -
            original.holdback
          }
        />
      ),
      width: 120,
    },
    {
      // Missing column
      Header: "Payment Requested Previous Period",
      accessor: "paymentRequestedPreviousPeriod",
      Cell: ({ row: { original } }) => (
        <CurrencyView value={original.currentAmountDue} />
      ),
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.currentAmountDue);
        const bVal = formatNumber(b.values.currentAmountDue);
        return aVal - bVal;
      },
      aggregate: "sum",
      Aggregated: ({ row: { original } }) => (
        <CurrencyView value={original.currentAmountDue} />
      ),
      width: 120,
    },
    {
      // Missing column
      Header: "Payment Requested This Period",
      accessor: "paymentRequestedThisPeriod",
      Cell: ({ row: { original } }) => <CurrencyView value={original.wip} />,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.wip);
        const bVal = formatNumber(b.values.wip);
        return aVal - bVal;
      },
      aggregate: "sum",
      Aggregated: ({ row: { original } }) => (
        <CurrencyView value={original.wip} />
      ),
      width: 120,
    },
    {
      // Missing column
      Header: "Balance to Finish Including Holdback and Retainage",
      accessor: "balanceToFinishIncludingHoldbackAndRetainage",
      Cell: ({ row: { original } }) => (
        <CurrencyView
          value={
            original.balanceToFinish +
            original.retainage +
            original.holdback -
            original.caTotal
          }
        />
      ),
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(
          a.values.balanceToFinish +
            a.values.retainage +
            a.values.holdback -
            a.values.caTotal
        );
        const bVal = formatNumber(
          b.values.balanceToFinish +
            b.values.retainage +
            b.values.holdback -
            b.values.caTotal
        );
        return aVal - bVal;
      },
      aggregate: "sum",
      Aggregated: ({ row: { original } }) => (
        <CurrencyView
          value={
            original.balanceToFinish +
            original.retainage +
            original.holdback -
            original.caTotal
          }
        />
      ),
      width: 120,
    },
    // Missing column
    {
      Header: "Uncommitted to Date",
      accessor: "uncommittedToDate",
      Cell: ({ row: { original } }) => (
        <CurrencyView value={original.uncommittedToDate} />
      ),
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.uncommittedToDate);
        const bVal = formatNumber(b.values.uncommittedToDate);
        return aVal - bVal;
      },
      aggregate: "sum",
      Aggregated: ({ row: { original } }) => (
        <CurrencyView value={original.uncommittedToDate} />
      ),
      width: 120,
    },
    // Missing column
    {
      Header: "Financial Document",
      accessor: "financialDocument",
      Cell: ({ row: { original } }) => {
        const doc =
          original.changeOrder ??
          original.contingencyAuthorization ??
          original.purchaceAuthorization ??
          original.purchaseOrder;
        return (
          <div className="flex items-center">
            <div className="flex-1">{doc[0]}</div>
            <IconButton
              className="flex-1"
              onClick={(e) => {
                e?.stopPropagation();
              }}
              icon={doc[0] ? fileIconGreen : fileIconGray}
            />
          </div>
        );
      },
      width: 120,
    },
    {
      Header: "Vendor",
      accessor: "vendorName",
    },
    // Missing Column
    {
      Header: "Non-Billable",
      accessor: "nonBillable",
    },
    {
      Header: "Retainage",
      accessor: "retainage",
      Cell: ({ value }) => <CurrencyView value={value} />,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.retainage);
        const bVal = formatNumber(b.values.retainage);
        return aVal - bVal;
      },
      aggregate: "sum",
      Aggregated: ({ value }) => <CurrencyView value={value} />,
    },
    // Missing Column
    {
      Header: "% Retainage",
      accessor: "percentRetainage",
    },
    // Missing Column
    {
      Header: "Retainage Released this Period",
      accessor: "retainageReleasedThisPeriod",
    },
    {
      Header: "Holdback",
      accessor: "holdback",
      Cell: ({ value }) => <CurrencyView value={value} />,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.holdback);
        const bVal = formatNumber(b.values.holdback);
        return aVal - bVal;
      },
      aggregate: "sum",
      Aggregated: ({ value }) => <CurrencyView value={value} />,
    },
    // Missing Column
    {
      Header: "Holdback Released this Period",
      accessor: "holdbackReleasedThisPeriod",
      Cell: ({ row: { original } }) => (
        <CurrencyView value={original.holdbackReleasedThisPeriod} />
      ),
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.holdbackReleasedThisPeriod);
        const bVal = formatNumber(b.values.holdbackReleasedThisPeriod);
        return aVal - bVal;
      },
      aggregate: "sum",
      Aggregated: ({ value }) => <CurrencyView value={value} />,
    },
  ];

  return (
    tabColumns[tab]?.map((accessor) =>
      columns.find((col) => col.accessor === accessor)
    ) ?? []
  );
};

export const staffingSummaryColumnsCP = (
  tab
  // project,
  // rateSheet
) => {
  const tabColumns = {
    staffingSummaryColumns: [
      // "financialDocument",
      // "purchaseOrder",
      "employeeName",
      "rate",
    ],
  };
  const columns = [
    // Missing column
    {
      Header: "Employee Name",
      accessor: "employeeName",
      Cell: ({ row: { original } }) => {
        return original.employeeName;
      },
      width: 120,
    },
    {
      Header: "Rate",
      accessor: "rate",
      Cell: ({ row: { original } }) => {
        return original.rate;
      },
      width: 120,
    },
  ];

  return (
    tabColumns[tab]?.map((accessor) =>
      columns.find((col) => col.accessor === accessor)
    ) ?? []
  );
};

import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import cntl from "cntl";
import { BRAND_GREEN } from "../../../constants";

const filterCN = cntl`
  flex
  items-center
  w-full
  h-11
  px-8
  cursor-default
`;

const DateFilter = () => {
  const [hovering, setHovering] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRangeSteps, setDateRangeSteps] = useState([0, 0]);
  const [dateRange, setDateRange] = useState({});
  const [rangeActive, setRangeActive] = useState(false);
  const [rangeStart, setRangeStart] = useState(
    rangeActive && dateRange?.start ? dateRange.start : new Date(moment())
  );
  const [rangeEnd, setRangeEnd] = useState(
    rangeActive && dateRange?.end ? dateRange.end : new Date(moment())
  );
  const [awayClass] = useState("search-filter");

  const handleHover = (mouseOver, id) => {
    if (mouseOver) {
      setHovering(id);
    } else {
      setHovering(null);
    }
  };

  const handleRangeClick = () => {
    setShowDatePicker((prev) => !prev);
  };

  const handleRangeClear = () => {
    const today = new Date(moment());
    setRangeStart(today);
    setRangeEnd(today);
    setRangeActive(false);
    setShowDatePicker(false);

    setDateRange();
    setRangeActive(false);
    setDateRange({});
  };

  const handleDateRange = ({ selection }) => {
    const startDate = new Date(moment(selection.startDate));
    const endDate = new Date(moment(selection.endDate));

    setDateRange({ start: startDate, end: endDate });
    setRangeStart(startDate);
    setRangeEnd(endDate);
  };

  const handleDateRangeSteps = (steps) => {
    setDateRangeSteps(steps);
    if (!steps[1]) {
      setShowDatePicker(false);
      setRangeActive(true);
      setRangeActive(true);
    }
  };

  return (
    <>
      {showDatePicker && (
        <div className={`${awayClass} relative -top-80 right-96 w-0 h-0 z-20`}>
          <div
            className="border-2 rounded border-brandGreen overflow-hidden"
            style={{ width: "346px", height: "385px" }}
          >
            <DateRangePicker
              className="flex-row-reverse"
              months={1}
              editableDateInputs
              rangeColors={[BRAND_GREEN]}
              minDate={new Date(moment().subtract(1, "years"))}
              maxDate={new Date(moment().add(1, "years"))}
              direction="horizontal"
              scroll={{ enabled: true }}
              staticRanges={[]}
              inputRanges={[]}
              ranges={[
                {
                  startDate: rangeStart,
                  endDate: rangeEnd,
                  key: "selection",
                },
              ]}
              onChange={handleDateRange}
              focusedRange={dateRangeSteps}
              onRangeFocusChange={handleDateRangeSteps}
            />
          </div>
        </div>
      )}
      <div
        className={`${awayClass} ${filterCN} justify-between border-t border-gray-200${
          hovering === "range" && !rangeActive
            ? " text-white bg-brandGreen"
            : ""
        }`}
        style={{ padding: "0" }}
        onMouseOver={() => handleHover(true, "range")}
        onMouseOut={() => handleHover(false)}
        onFocus={() => handleHover(true, "range")}
        onBlur={() => handleHover(false)}
      >
        <button
          className="flex flex-1 items-center h-full px-8"
          type="button"
          onClick={handleRangeClick}
        >
          <span className={`flex${rangeActive ? "text-brandGreen" : ""}`}>
            Range ...
          </span>
        </button>
        {rangeActive && (
          <button type="button" className="mt-1" onClick={handleRangeClear}>
            <span
              className={`${
                hovering === "range" && !rangeActive
                  ? " text-white bg-brandGreen"
                  : "text-brandGreen"
              } mr-10`}
            >
              clear
            </span>
          </button>
        )}
      </div>
    </>
  );
};

export default DateFilter;

import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import BaseButton from "../Buttons/BaseButton";
import crossIcon from "../../assets/images/Close.svg";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import BooleanBullet from "../BooleanBullet/BooleanBullet";

const BudgetTableDocumentsCell = ({ row, columnId, setOpenDocument }) => {
  const [showPopup, setShowPopup] = useState(false);
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, () => setShowPopup(false));

  return (
    <div className="flex flex-row items-center" ref={wrapperRef}>
      <div
        onClick={(e) => {
          e.stopPropagation();
          if (row?.original?.[columnId]?.length) {
            setShowPopup((prev) => !prev);
          }
        }}
        tabIndex={0}
        role="button"
        onKeyDown={() => {}}
        className={`text-xs truncate ${
          row?.original?.[columnId]?.length > 1
            ? "text-brandGreen font-semibold"
            : "text-gray-400"
        }"`}
      >
        {row?.original?.[columnId]?.length > 1 && "View"}
        {row?.original?.[columnId]?.length <= 1
          ? row?.original?.[columnId]?.reduce(
              (previousValue, currentValue) =>
                `${previousValue + currentValue?.name}, `,
              ""
            )
          : ""}
      </div>
      {showPopup && (
        <div
          className="flex flex-col rounded border border-gray-150 z-20 bg-white absolute p-2 w-72 top-8"
          onClick={(e) => {
            e.stopPropagation();
          }}
          tabIndex={0}
          role="button"
          onKeyDown={() => {}}
        >
          <div className="flex justify-between">
            <div className="flex pb-2">
              <p className="font-semibold text-xs">Financials Documents</p>
            </div>
            <BaseButton
              onClick={() => {
                setShowPopup(false);
              }}
              iconLeft={
                <img
                  src={crossIcon}
                  alt="crossIcon"
                  style={{ height: 8, width: 10 }}
                />
              }
            />
          </div>
          <div className="flex flex-col">
            {row?.original?.[columnId]?.map((pa) => (
              <div
                className="flex flex-col pb-2 break-words whitespace-normal"
                key={pa.reference}
              >
                <div
                  className="flex flex-row pb-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenDocument(pa.reference);
                  }}
                  tabIndex={0}
                  role="button"
                  onKeyDown={() => {}}
                >
                  <BooleanBullet isTrue className="mr-1 mt-1 bg-brandGreen" />
                  <div className="font-semibold text-gray-300 text-xs">
                    {pa.name}
                  </div>
                </div>
                {pa?.documents?.length && (
                  <div className="flex ml-4">
                    <div className="w-2">
                      <BooleanBullet isTrue className="mt-1 mr-1" />
                    </div>
                    <p className="text-xs px-1">
                      Related Docs:{" "}
                      {pa?.documents?.map((doc, docIndex) => (
                        <span
                          key={doc.reference}
                          className="text-gray-300 text-xs font-semibold"
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenDocument(doc.reference);
                          }}
                          tabIndex={0}
                          role="button"
                          onKeyDown={() => {}}
                        >
                          {docIndex > 0 && ", "}
                          {doc.name}
                        </span>
                      ))}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

BudgetTableDocumentsCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({}),
  }),
  setOpenDocument: PropTypes.func,
  columnId: PropTypes.string,
};

BudgetTableDocumentsCell.defaultProps = {
  row: undefined,
  setOpenDocument: () => {},
  columnId: undefined,
};

export default BudgetTableDocumentsCell;

import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import Modal from "../Modal/Modal";
import Input from "../Input/Input";
import PasswordValidationRulesList from "../PasswordValidationRulesList/PasswordValidationRulesList";
import Auth from "../../../helpers/Auth";
import usePasswordValidator from "../../../hooks/usePasswordValidator";

/**
 * Create New Password Modal is used on the Login screen when a User must reset their password.
 */
const CreateNewPasswordModal = ({
  isCreateNewPasswordModalOpen,
  setIsCreateNewPasswordModalOpen,
  setNewPassword,
}) => {
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({});
  const inputRefs = useRef({});

  /**
   * When the password is changed, run the password validation rules against it.
   */
  usePasswordValidator(
    password,
    confirmationPassword,
    setPasswordValidation,
    setIsValidPassword
  );

  const handleSubmit = () => {
    if (isValidPassword) setNewPassword(password);
  };

  const onClose = () => {
    setIsCreateNewPasswordModalOpen(false);
    Auth.setStorage("", "");
  };

  return (
    <Modal
      title="Create New Password"
      isOpen={isCreateNewPasswordModalOpen}
      onRequestModalClose={onClose}
      hideFooter
      primaryButtonTitle="Sign In"
      primaryButtonOnClick={handleSubmit}
      tertiaryButtonTitle="Cancel"
      disabled={!isValidPassword}
      primaryButtonRef={inputRefs.current.primaryButton}
      tertiaryButtonRef={(el) => {
        inputRefs.current.tertiaryButton = el;
      }}
    >
      <div className="flex flex-col items-center">
        <div className="w-full">
          <Input
            autoFocus
            autoComplete="new-password"
            className="mt-5 w-full"
            placeholder="New Password"
            label="New Password"
            type="password"
            toggleVisibility
            onChange={setPassword}
            value={password}
            handleEnter={(event) => {
              if (event.keyCode === 13 || event.keyCode === 9)
                inputRefs.current.confirmNewPassword.focus();
            }}
          />
          <Input
            autoComplete="new-password"
            className="mt-5 w-full"
            placeholder="Confirm New Password"
            label="Confirm New Password"
            type="password"
            toggleVisibility
            onChange={setConfirmationPassword}
            value={confirmationPassword}
            handleEnter={(event) => {
              if (event.key === "Enter") {
                handleSubmit();
              } else if (event.keyCode === 9) {
                inputRefs.current.tertiaryButton.focus();
              }
            }}
            name="confirmNewPassword"
            forwardedRef={(el) => {
              inputRefs.current.confirmNewPassword = el;
            }}
          />
        </div>
        <PasswordValidationRulesList
          passwordValidation={passwordValidation}
          isValidPassword={isValidPassword}
          className="mt-5"
        />
      </div>
    </Modal>
  );
};

CreateNewPasswordModal.propTypes = {
  /**
   * if true, displays the modal
   */
  isCreateNewPasswordModalOpen: PropTypes.bool,
  /**
   * function called to trigger if the modal is open or closed
   */
  setIsCreateNewPasswordModalOpen: PropTypes.func,
  /**
   * function called to set the new password value
   */
  setNewPassword: PropTypes.func,
};

CreateNewPasswordModal.defaultProps = {
  isCreateNewPasswordModalOpen: false,
  setIsCreateNewPasswordModalOpen: undefined,
  setNewPassword: undefined,
};

export default CreateNewPasswordModal;

import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import PrimaryButton from "../Buttons/PrimaryButton";
import PrimaryHeader from "../TextHeaders/PrimaryHeader";

const primaryItemsCN = (className) => cntl`
  flex
  items-center
  mb-10
  px-2
  justify-between
  ${className}
`;

const PrimaryHeaderItems = ({
  className,
  headerTitle,
  headerTitleCN,
  buttonTitle,
  customButton,
  onClick,
  buttonClassName,
}) => {
  return (
    <div className={primaryItemsCN(className)}>
      <PrimaryHeader className={headerTitleCN}>{headerTitle}</PrimaryHeader>
      {customButton || (
        <>
          {onClick ? (
            <PrimaryButton
              resourceName={buttonTitle}
              onClick={onClick}
              addButton
              className={buttonClassName}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

PrimaryHeaderItems.propTypes = {
  className: PropTypes.string,
  headerTitle: PropTypes.string,
  headerTitleCN: PropTypes.string,
  buttonTitle: PropTypes.string,
  customButton: PropTypes.elementType,
  onClick: PropTypes.func,
  buttonClassName: PropTypes.string,
};

PrimaryHeaderItems.defaultProps = {
  className: undefined,
  headerTitle: undefined,
  headerTitleCN: undefined,
  buttonTitle: undefined,
  customButton: undefined,
  onClick: undefined,
  buttonClassName: undefined,
};

export default PrimaryHeaderItems;

import moment from "moment";

const getReqParams = (calendarData) => {
  const paramsCopy = {
    params: {
      left: moment(calendarData).startOf("month").startOf("day").format(),
      right: moment(calendarData).endOf("month").endOf("day").format(),
    },
  };

  return paramsCopy;
};

export default getReqParams;

import { useState } from "react";
import theseArraysMatch from "../../../helpers/Report/compareArrays";
import findNewItemWithUniquePrefix from "../../../helpers/Report/findNewItemWithUniquePrefix";

const useDropdownWithSegmentedControlData = ({
  selectedSegment,
  setValue,
  isMulti,
  options,
  value,
}) => {
  const [prevSelectedSegment, setPrevSelectedSegment] =
    useState(selectedSegment);

  const allSelected = theseArraysMatch(value, options);

  /**
   * This function ensures that only values from a single segment can be selected
   * If the selected segment changes, it removes all values except the last one from the dropdown.
   * If the selected segment remains the same, it sets the provided values to the dropdown.
   */
  const handleSetValue = (valueSelected) => {
    if (!isMulti) {
      setValue(valueSelected);
      return;
    }

    if (
      selectedSegment !== prevSelectedSegment &&
      prevSelectedSegment !== undefined
    ) {
      setPrevSelectedSegment(selectedSegment);
      const indexLastValue = valueSelected.length - 1;
      const lastValue = valueSelected[indexLastValue];

      setValue([lastValue]);
    } else {
      // Segment remains the same, set the provided values
      setValue(findNewItemWithUniquePrefix(valueSelected));
    }
  };

  const handleAllSelected = () => {
    if (allSelected === true) {
      setValue([]);
    } else {
      setValue(options);
    }
  };

  return {
    handleSetValue,
    handleAllSelected,
    allSelected,
  };
};

export default useDropdownWithSegmentedControlData;

import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { useQueryClient } from "react-query";
import UploadArea from "../UploadArea/UploadArea";
import { uploadFileWithData } from "../../../helpers/File";
import { toastError, toastMessage } from "../Toast/Toast";
import { filePaginatedKeys } from "../../../config/reactQuery/queryKeyFactory";

const UploadFile = ({
  setIsSaving,
  association,
  id,
  onAddFilesCallback,
  uploadFileOverwrite,
}) => {
  const queryClient = useQueryClient();
  const handleFilesAdded = React.useCallback(
    async (newFiles) => {
      if (uploadFileOverwrite) {
        uploadFileOverwrite(newFiles);
      } else {
        // post file
        try {
          setIsSaving(true);
          const filesUploaded = await Promise.all(
            newFiles.map(async (file) => {
              const data = {
                name: file.name,
                contentType: file?.type,
                size: file?.size,
                association,
                id: uuidv4(),
              };
              const addedFile = await uploadFileWithData(
                file,
                data,
                undefined,
                undefined,
                true,
                undefined,
                true
              );

              return addedFile;
            })
          );

          // update files in overview
          queryClient.invalidateQueries(filePaginatedKeys.allFiles);

          onAddFilesCallback(filesUploaded);

          toastMessage(
            `Successfully uploaded ${
              filesUploaded.length > 1 ? "files" : "file"
            }`
          );
        } catch (e) {
          toastError(`Error uploading files - ${e?.message}`);
        } finally {
          setIsSaving(false);
        }
      }
    },
    [
      association,
      onAddFilesCallback,
      queryClient,
      setIsSaving,
      uploadFileOverwrite,
    ]
  );

  return (
    <UploadArea className={`hidden ${id}`} onFilesAdd={handleFilesAdded} />
  );
};

UploadFile.propTypes = {
  setIsSaving: PropTypes.func,
  association: PropTypes.string,
  id: PropTypes.string.isRequired,
  onAddFilesCallback: PropTypes.func,
  uploadFileOverwrite: PropTypes.func,
};

UploadFile.defaultProps = {
  setIsSaving: () => {},
  association: undefined,
  onAddFilesCallback: () => {},
  uploadFileOverwrite: undefined,
};

export default UploadFile;

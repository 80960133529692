/**
 * Converts a camelCase string into a space-separated string with each word capitalized.
 * @function
 * @param {string} str - The camelCase string to be converted.
 * @returns {string} - The converted string with spaces and capitalization.
 */

const convertFromCamelCase = (str) => {
  if (typeof str !== "string") {
    throw new Error("Input must be a string");
  }

  const result = str.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/\s+/g, " ");
  return result
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export default convertFromCamelCase;

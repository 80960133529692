import cntl from "cntl";
import React from "react";

const rowCN = (count) => cntl`
grid
${`grid-cols-${count}`}
items-center
text-center
justify-items-center
`;
export default ({ children, title }) => {
  return (
    <div className="flex flex-col w-full">
      {title && <p className="text-center mb-3 text-gray-200">{title}</p>}
      <div className={rowCN(children?.length)}>
        {Array.isArray(children)
          ? children?.map((Child) =>
              React.cloneElement(Child, { style: { columnSpan: 1 } })
            )
          : React.cloneElement(children, { style: { columnSpan: 1 } })}
      </div>
    </div>
  );
};

import taskKeys from "../../hooks/tasks/taskKeys";

const editTaskStore = ({ queryClient, returnedData }) => {
  queryClient.setQueryData(
    taskKeys.singleTask(
      returnedData.data.id,
      returnedData.data?.instanceStartDate
    ),
    () => returnedData.data
  );
};

export default editTaskStore;

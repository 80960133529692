import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import {
  dateOptions,
  calendarDaysOptions,
  calendarDaysOfWeekOptions,
  monthsOptions,
} from "../../../../helpers/Holidays";
import yellowExclamationIcon from "../../../assets/images/yellowExclamationIcon.svg";
import ModalWrapper from "../../EsModal/ModalWrapper";
import useHolidaysSectionModalData from "./useHolidaysSectionModalData";
import Input from "../../Input/Input";
import Dropdown from "../../Dropdown/Dropdown";
import DatePicker from "../../DatePicker/DatePicker";
import BaseButton from "../../Buttons/BaseButton";
import Widget from "../../Widget/Widget";

const ModalCN = cntl`
  mb-4
`;

const HolidaysSectionModal = ({ modalData }) => {
  const {
    isEditingIndex,
    isAdding,
    onUpdateHoliday,
    selectedYear,
    isValid,
    recurrenceSelected,
    recurrenceAlreadySelected,
    specificDateSelected,
    specificDateAlreadySelected,
    holiday,
    setHoliday,
  } = useHolidaysSectionModalData({ modalData });

  const {
    display,
    dateOption,
    date,
    recurrence: { calendarDay, dayOfWeek, month } = {},
  } = holiday;

  // Old data could possibly lack one of these values. Condition true if any exists
  const hasAnyValidRecurrence =
    calendarDay || dayOfWeek?.length || month?.length;

  const dateOptionValue = () => {
    // Open existing recurring holiday
    if (hasAnyValidRecurrence && !dateOption) {
      return dateOptions[1];
    }

    // Open existing regular holiday
    if (date && !dateOption) {
      return dateOptions[0];
    }

    return dateOption;
  };

  return (
    <ModalWrapper
      title={`${isAdding ? "Add" : "Edit"} Holiday`}
      primaryButtonTitle="Save"
      primaryButtonOnClick={() =>
        onUpdateHoliday(holiday, isEditingIndex, isAdding)
      }
      width="530"
      alert
      float
      disabled={!isValid}
      className="lg:px-4 overflow-y-visible"
      wrapperClassName="overflow-visible"
      modalData={modalData}
    >
      <Widget draggable={false} title={null} backToTop overflow>
        <div className={ModalCN}>
          <Input
            placeholder="Name"
            label="Name of Holiday"
            value={display}
            onChange={(newDisplay) =>
              setHoliday((prev) => ({ ...prev, display: newDisplay }))
            }
          />
          <div className="mt-5">
            <Dropdown
              label="Type"
              placeholder="Select date type..."
              disableClear
              options={dateOptions}
              value={dateOptionValue()}
              onChange={(newOption) =>
                setHoliday((prev) => ({
                  ...prev,
                  dateOption: newOption,
                }))
              }
            />
          </div>
          {(dateOption || hasAnyValidRecurrence || date) && (
            <div className="pt-5">
              {dateOption?.value === "specificDate" || (date && !dateOption) ? (
                <>
                  <div>
                    <p className="text-gray-200 py-1">Date</p>
                  </div>
                  <DatePicker
                    value={date}
                    onChange={(newDate) =>
                      setHoliday((prev) => ({
                        ...prev,
                        date: newDate.toISOString(),
                      }))
                    }
                    dateFormat="eeee, MMMM do"
                    maxDate={new Date(selectedYear, 11, 31)}
                    minDate={new Date(selectedYear, 0, 1)}
                  />
                </>
              ) : (
                <>
                  <div>
                    <p className="text-gray-200 py-1">Recurrence</p>
                  </div>
                  <div className="flex flex-row">
                    <div className="w-60 pr-2">
                      <Dropdown
                        options={calendarDaysOptions()}
                        value={{
                          label: calendarDay,
                          value: calendarDay,
                        }}
                        onChange={({ value }) =>
                          setHoliday((prev) => ({
                            ...prev,
                            recurrence: {
                              ...prev.recurrence,
                              calendarDay: value,
                            },
                          }))
                        }
                        disableSort
                        disableClear
                      />
                    </div>
                    <div className="w-full">
                      <Dropdown
                        options={calendarDaysOfWeekOptions}
                        value={calendarDaysOfWeekOptions.find(
                          (opt) => opt.value === dayOfWeek
                        )}
                        onChange={({ value }) =>
                          setHoliday((prev) => ({
                            ...prev,
                            recurrence: {
                              ...prev.recurrence,
                              dayOfWeek: value,
                            },
                          }))
                        }
                        disableSort
                        disableClear
                      />
                    </div>
                    <p className="px-2 align-bottom py-2">of</p>
                    <div className="w-full align-bottom">
                      <Dropdown
                        options={monthsOptions}
                        value={monthsOptions.find((opt) => opt.value === month)}
                        onChange={({ value }) =>
                          setHoliday((prev) => ({
                            ...prev,
                            recurrence: {
                              ...prev.recurrence,
                              month: value,
                            },
                          }))
                        }
                        disableSort
                        disableClear
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {(recurrenceSelected ||
            recurrenceAlreadySelected ||
            specificDateSelected ||
            specificDateAlreadySelected) && (
            <div className="flex py-2 mx-2">
              <BaseButton
                iconLeft={
                  <img
                    src={yellowExclamationIcon}
                    alt="warning"
                    className="w-5 h-5"
                  />
                }
                title="This holiday falls on a weekend."
                innerClassName="text-xs text-gray-300 font-normal"
              />
            </div>
          )}
        </div>
      </Widget>
    </ModalWrapper>
  );
};

HolidaysSectionModal.propTypes = {
  modalData: PropTypes.shape({
    id: PropTypes.string,
  }),
};

HolidaysSectionModal.defaultProps = {
  modalData: undefined,
};

export default HolidaysSectionModal;

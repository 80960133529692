/**
 * Transform reports template array into an array for the `TemplatesDropdown`
 * @param {Object[]} templates array of report templates
 * @param {string} associationType type of association: property | project | asset
 * @param {boolean} lockAssociationType flag to determine if association dropdown is locked
 * @returns {Object[]} array of objects to populate dropdown
 */
const convertTemplatesInDropdownShape = (
  templates = [],
  associationType,
  lockAssociationType
) => {
  let dropdownData = templates;

  // If `AssociationDropdown` is locked, it should display only templates by the association type
  if (associationType && lockAssociationType) {
    dropdownData = templates.filter(
      (template) => template.sourceType === associationType
    );
  }

  return dropdownData.map((template) => ({
    id: template.id,
    label: template.title,
    value: template.reference,
    template,
  }));
};

/**
 * Transform assets categories array into an array for the `AssetsDropdown`
 * @param {Object} currentAssetsCategories object with assets categories in the template selected
 * @param {Object} assetsCategoriesMap map with the name of all assets categories
 * @returns
 */
const convertAssetsCategoriesToDropdownShape = (
  currentAssetsCategories = {}
) => {
  const categoryMap = {};

  currentAssetsCategories?.options?.forEach(({ category, subcategory }) => {
    if (!categoryMap[category]) {
      categoryMap[category] = { id: category, suboptions: [] };
    }
    categoryMap[category].suboptions.push(subcategory);
  });

  const dropdownData = Object.entries(categoryMap).map(
    ([id, { suboptions }]) => ({
      id,
      value: { id, suboptions },
    })
  );

  return dropdownData;
};

export {
  convertTemplatesInDropdownShape,
  convertAssetsCategoriesToDropdownShape,
};

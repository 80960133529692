/* eslint-disable import/prefer-default-export */
import moment from "moment";

/**
 * @param {String} start
 * @param {String} end
 * @param {String} date
 * @returns - {Boolean} if date is between start & end (includes edges)
 */
export const DateIsBetween = (start, end, date, calendarTimezone) => {
  const dayStarts = {
    date: moment.tz(date, calendarTimezone).startOf("day").format(),
    start: moment.tz(start, calendarTimezone).startOf("day").format(),
    end: moment.tz(end, calendarTimezone).startOf("day").format(),
  };

  return moment
    .tz(dayStarts.date, calendarTimezone)
    .isBetween(dayStarts.start, dayStarts.end, null, "[]");
};

/**
 * @param {String} date0
 * @param {String} date1
 * @returns - {Boolean} if date0 is after date1
 */
export const DateIsAfter = (date0, date1) => {
  return moment(date0).isAfter(moment(date1));
};

/**
 * @param {String} date0
 * @param {String} date1
 * @returns - {Boolean} if date0 is before date1
 */
export const DateIsBefore = (date0, date1) => {
  return moment(date0).isBefore(moment(date1), "day");
};

/**
 * @param {Element} divisionElement - Html representation of timeperiod
 * @summary - Call inside reduce function to add elements while iterating/mapping data to html elements
 */
export class TimePeriodDivision {
  constructor(element) {
    this.divisions = [
      "Today",
      "Yesterday",
      "This Week",
      "Last Week",
      "This Month",
      "Last Month",
    ];
    this.divisionElement = element;
  }

  getDivision(x) {
    const i = this.divisions.findIndex((item) => item === x);
    if (i < 0) return null;
    if (i >= 0) {
      const value = this.divisions[i];
      this.divisions = this.divisions.splice(i + 1);
      return value;
    }

    return null;
  }

  /**
   * @param {Array} list - first arguement of reduce function
   * @param {String} date - ISO string
   * @summary - Array reduce to map elements sorted by date will insert divisions (today, yesterday, last week etc)
   *
   *
   * ex:
   * data?.reduce((list, item)=>{
   * TimePeriodDivision.division(list, item.date)
   * list.push(<SomeElement data={data} />)
   * return list
   * },[])
   */
  division(list, date) {
    const now = moment();
    switch (true) {
      case DateIsBetween(
        now.startOf("day").format(),
        now.endOf("day").format(),
        date
      ): {
        const v = this.getDivision("Today");
        if (v) list.push(this.divisionElement(v));
        break;
      }
      case DateIsBetween(
        moment().subtract(1, "days").startOf("day").format(),
        moment().subtract(1, "days").endOf("day").format(),
        date
      ): {
        const v = this.getDivision("Yesterday");
        if (v) list.push(this.divisionElement(v));
        break;
      }
      case DateIsBetween(
        moment().subtract(1, "month").startOf("month").format(),
        moment().subtract(1, "month").endOf("month").format(),
        date
      ): {
        const v = this.getDivision("Last Month");
        if (v) list.push(this.divisionElement(v));
        break;
      }
      case DateIsAfter(date, moment().startOf("week").format()): {
        const v = this.getDivision("This Week");
        if (v) list.push(this.divisionElement(v));
        break;
      }
      case DateIsAfter(
        date,
        moment().subtract(7, "days").startOf("week").format()
      ): {
        const v = this.getDivision("Last Week");
        if (v) list.push(this.divisionElement(v));
        break;
      }
      case DateIsAfter(date, moment().startOf("month").format()): {
        const v = this.getDivision("This Month");
        if (v) list.push(this.divisionElement(v));
        break;
      }
      default:
    }
  }
}

/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { eventColor } from "../../../helpers/Calendar";

const AllDayEvent = ({
  event: { startDate, name, id, daySpan = 1, ...rest },
  index = 0,
  subIndex,
  onClick,
  dayView,
}) => {
  const containerRef = useRef(null);
  const containerAttributes = useCallback(() => {
    const fullWidth = !dayView ? 280 / 7 : 280;

    const width = (dayView ? 1 : daySpan) * fullWidth - 0.25;
    const x = index * fullWidth + 0.125;

    return { x, width, height: 7, y: 21 + 7 * subIndex };
  }, [daySpan, dayView, index, subIndex]);

  const formatEventName = useCallback(
    ({ x, width, y }) => {
      const maxLength = Math.floor(width / 2.4) - 1;

      const ellipse = (val) => {
        return val.length > maxLength
          ? `${val.substring(0, maxLength)}...`
          : val;
      };
      return (
        <text
          y={y + 2}
          x={x + 2}
          fontSize="3"
          alignmentBaseline="hanging"
          fill="white"
        >
          {ellipse(name)}
        </text>
      );
    },
    [name]
  );
  return (
    <g
      className="cursor-pointer"
      ref={containerRef}
      onClick={(e) => {
        e.stopPropagation();
        onClick(
          rest?.original ?? {
            startDate,
            name,
            id,
            ...rest,
          },
          containerRef
        );
      }}
    >
      <rect
        {...containerAttributes()}
        stroke="black"
        strokeWidth="0.125"
        fill={eventColor({ ...rest })}
        rx="1.75"
      />
      {formatEventName(containerAttributes())}
    </g>
  );
};

AllDayEvent.propTypes = {
  event: PropTypes.shape({
    startDate: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    daySpan: PropTypes.number,
  }),
  index: PropTypes.number,
  subIndex: PropTypes.number,
  onClick: PropTypes.func,
  dayView: PropTypes.bool,
};

AllDayEvent.defaultProps = {
  event: undefined,
  index: 0,
  subIndex: undefined,
  onClick: () => {},
  dayView: false,
};

export default AllDayEvent;

import React from "react";
import ImageCell from "../../../../stories/Components/Table/ImageCell";

const UserButton = ({ user, onClick }) => {
  return (
    <button
      className="flex w-full hover:bg-mint p-6 items-center gap-3 truncate"
      type="button"
      style={{ height: "78px", minHeight: "78px" }}
      key={user.streamId}
      onClick={() => onClick(user)}
    >
      <ImageCell className="w-8 h-8 rounded-full" src={user?.avatar} />
      <p style={{ marginTop: "-5px" }}>
        <span className="font-medium tracking-wider text-gray-500 text-md">
          {user?.name}
        </span>
      </p>
    </button>
  );
};

export default UserButton;

import cntl from "cntl";
import React from "react";
import PropTypes from "prop-types";
import Input from "../Input/Input";
import { reformatCurrencyString } from "../../../helpers/Formatters";

const fieldTypeCN = (editing, position) => cntl`
    flex 
    w-1/2 
    items-center 
    ${editing ? "justify-end" : `justify-${position ?? "center"}`}

`;

const titleCN = (className) => cntl`
  ${className ?? "w-1/2"}
`;

const fieldWrapperCN = (last) => cntl`
  flex 
  ${!last && "border-b"}
`;

const valueCN = (className) => cntl`
text-gray-200
${className}
`;

const ProjectEditField = ({
  isEditing,
  title,
  value,
  handleChange,
  type,
  placeHolder,
  loading,
  isDollarValue,
  isPercentageValue,
  lastField,
  fieldPosition,
  titleClassName,
  valueClassName,
}) => {
  return (
    <>
      {!loading && (
        <div className={fieldWrapperCN(lastField)}>
          <div className={titleCN(titleClassName)}>
            <p className="font-bold mx-4 my-4">{title}</p>
          </div>
          <div className={fieldTypeCN(isEditing, fieldPosition)}>
            {value && !isEditing && (
              <>
                {!isDollarValue && !isPercentageValue && (
                  <p className={valueCN(valueClassName)}>{value}</p>
                )}
                {(isDollarValue || isPercentageValue) && (
                  <p className={valueCN(valueClassName)}>
                    {isDollarValue
                      ? `$ ${reformatCurrencyString(value)}`
                      : `${value} %`}
                  </p>
                )}
              </>
            )}
            {!value &&
              typeof value !== "number" &&
              !isEditing &&
              isDollarValue && (
                <p className={valueCN(valueClassName)}>$ 0.00</p>
              )}
            {isEditing && (
              <div className={isDollarValue || isPercentageValue ? "" : "mr-4"}>
                <Input
                  onChange={(val) => handleChange(val, type)}
                  value={value ?? ""}
                  placeholder={placeHolder}
                  isDollarValue={isDollarValue}
                  isPercentageValue={isPercentageValue}
                  autoFocus
                  disableClear
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

ProjectEditField.propTypes = {
  isEditing: PropTypes.bool,
  value: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  handleChange: PropTypes.func,
  type: PropTypes.string,
  placeHolder: PropTypes.string,
  loading: PropTypes.bool,
  title: PropTypes.string,
  isDollarValue: PropTypes.bool,
  lastField: PropTypes.bool,
  fieldPosition: PropTypes.string,
  titleClassName: PropTypes.string,
  valueClassName: PropTypes.string,
  isPercentageValue: PropTypes.bool,
};

ProjectEditField.defaultProps = {
  isEditing: false,
  value: undefined,
  handleChange: undefined,
  type: undefined,
  placeHolder: undefined,
  loading: false,
  title: undefined,
  isDollarValue: false,
  lastField: false,
  fieldPosition: undefined,
  titleClassName: undefined,
  valueClassName: undefined,
  isPercentageValue: false,
};

export default ProjectEditField;

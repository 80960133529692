import React from "react";
import PropTypes from "prop-types";
import { downloadMedia } from "../../../helpers/File";

// Iconst & Images
import attachmentIconUpdated from "../../assets/images/attachment_icon_updated.svg";

export default function RequestFileCard({ title, file, iconClassName }) {
  const handleDownload = async () => {
    await downloadMedia([file?.reference]);
  };
  return (
    <div
      className="flex justify-center items-start"
      role="button"
      onClick={handleDownload}
      onKeyDown={handleDownload}
      tabIndex={0}
    >
      <img
        src={attachmentIconUpdated}
        className={`${iconClassName || "h-6 w-6"}`}
        alt="fileIcon"
      />

      <p className="text-gray-450 text-sm font-normal underline w-52 whitespace-nowrap overflow-hidden overflow-ellipsis">
        {title}
      </p>
    </div>
  );
}

RequestFileCard.propTypes = {
  title: PropTypes.string,
  file: PropTypes.shape({ reference: PropTypes.string }),
  iconClassName: PropTypes.string,
};

RequestFileCard.defaultProps = {
  title: undefined,
  file: undefined,
  iconClassName: undefined,
};

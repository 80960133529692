import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { GET_PROJECT_DOCUMENTS_DOC_PATH } from "../../../../../constants";
import { formatNumber } from "../../../../../helpers/Formatters";
import ScheduleOfValues from "../../../ScheduleOfValues/ScheduleOfValues";
import Table from "../../../Table/Table";
import IconButton from "../../../Buttons/IconButton";
import CommentButton from "../../../CommentButton";
import EditIcon from "../../../EditIcon/EditIcon";
import TertiaryButton from "../../../Buttons/TertiaryButton";
import useDocumentVendorInvoicesData from "./useDocumentVendorInvoicesData";
import ColumnFooter from "../../../BudgetTable/ColumnFooter";
import CurrencyView from "../../../CurrencyView/CurrrencyView";
import CircularProgressBar from "../../../CircularProgressBar/CircularProgressBar";
import BudgetTableDocumentsCell from "../../../BudgetTable/BudgetTableDocumentsCell";

const deleteButtonStyle = {
  marginTop: "20px",
};

const DocumentVendorInvoices = ({
  csiCodes,
  documentsDict,
  userList,
  project,
  setShowCommentsPopover,
  document,
}) => {
  const history = useHistory();

  const { handleSaveSov, docBudgetLines, isEditing, setIsEditing, budgetData } =
    useDocumentVendorInvoicesData({
      project,
      userList,
      documentsDict,
      csiCodes,
      document,
    });

  const getVendorInvoiceAdjustmentsColumns = useMemo(() => {
    const columns = [
      {
        Header: "PO",
        accessor: "parentPurchaseOrder",
        minWidth: 200,
        isPrimary: true,
        Cell: ({ row }) => (
          <BudgetTableDocumentsCell
            row={row}
            setOpenDocument={(ref) => {
              history.push(
                GET_PROJECT_DOCUMENTS_DOC_PATH(project.id, ref.split("/")[1])
              );
            }}
            columnId="parentPurchaseOrder"
          />
        ),
      },
      {
        Header: "CSI Code",
        accessor: "csiCode",
        minWidth: 400,
        isPrimary: true,
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ value }) => <div className="truncate">{value}</div>,
        minWidth: 200,
        isPrimary: true,
      },
      {
        Header: "Vendor",
        accessor: "vendorName",
      },
      {
        Header: "Committed Values",
        accessor: "committedValues",
        width: 180,
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.committedValues);
          const bVal = formatNumber(b.values.committedValues);
          return aVal - bVal;
        },
      },
      {
        Header: "Work Completed Previous Period",
        accessor: "workCompletedPreviousPeriod",
        minWidth: 250,
        isPrimary: true,
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.workCompletedPreviousPeriod);
          const bVal = formatNumber(b.values.workCompletedPreviousPeriod);
          return aVal - bVal;
        },
      },
      {
        Header: "Work Completed This Period",
        accessor: "workCompletedThisPeriod",
        minWidth: 220,
        isPrimary: true,
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.workCompletedThisPeriod);
          const bVal = formatNumber(b.values.workCompletedThisPeriod);
          return aVal - bVal;
        },
      },
      {
        Header: "Work Completed To Date",
        accessor: "workCompletedToDate",
        minWidth: 200,
        isPrimary: true,
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.workCompletedToDate);
          const bVal = formatNumber(b.values.workCompletedToDate);
          return aVal - bVal;
        },
      },
      {
        Header: "% Complete of committed values",
        accessor: "percentCompleteOfCommittedValues",
        Cell: ({ value }) => <CircularProgressBar value={value} hideIcons />,
        minWidth: 250,
        isPrimary: true,
        Footer: () => null,
      },
      {
        Header: "Retainage",
        accessor: "retainage",
        isPrimary: true,
        Cell: ({ value }) =>
          value !== formatNumber(-1) ? <CurrencyView value={value} /> : "N/A",
        Footer: () => null,
      },
      {
        Header: "Holdback",
        accessor: "holdback",
        isPrimary: true,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.holdback);
          const bVal = formatNumber(b.values.holdback);
          return aVal - bVal;
        },
        Cell: ({ value }) => <CurrencyView value={value} />,
      },
      {
        Header: "Work Completed to Date Less Retainage and Holdback",
        accessor: "workCompletedToDateLessRetainageAndHoldback",
        minWidth: 400,
        isPrimary: true,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(
            a.values.workCompletedToDateLessRetainageAndHoldback
          );
          const bVal = formatNumber(
            b.values.workCompletedToDateLessRetainageAndHoldback
          );
          return aVal - bVal;
        },
        Cell: ({ value }) => <CurrencyView value={value} />,
      },
      {
        Header: "Previous payment due",
        accessor: "previousPaymentDue",
        minWidth: 200,
        isPrimary: true,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.previousPaymentDue);
          const bVal = formatNumber(b.values.previousPaymentDue);
          return aVal - bVal;
        },
        Cell: ({ value }) => <CurrencyView value={value} />,
      },
      {
        Header: "Current Amount Due",
        accessor: "currentPaymentDue",
        minWidth: 200,
        isPrimary: true,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.currentPaymentDue);
          const bVal = formatNumber(b.values.currentPaymentDue);
          return aVal - bVal;
        },
        Cell: ({ value }) => <CurrencyView value={value} />,
      },
      {
        Header: "Balance to Finish including Holdback and Retainage",
        accessor: "balanceToFinish",
        isPrimary: true,
        minWidth: 350,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.balanceToFinish);
          const bVal = formatNumber(b.values.balanceToFinish);
          return aVal - bVal;
        },
        Cell: ({ value }) => <CurrencyView value={value} />,
      },
    ];

    return columns;
  }, [history, project?.id]);

  return (
    <div className="flex flex-col">
      <div className="flex justify-between py-2">
        <div className="flex">
          <p className="font-semibold text-2xl">Vendor Invoices</p>
        </div>
        <div className="flex ">
          <CommentButton onClick={() => setShowCommentsPopover(true)} />
          {!isEditing && (
            <IconButton
              wrapperClassName={`${!isEditing && "icon-transition"} pt-2`}
              imgClassName="w-6 h-6"
              iconClassName="w-6 h-6"
              onClick={() => setIsEditing(true)}
              iconLeft={<EditIcon />}
            />
          )}
          {isEditing && (
            <div className="flex">
              <TertiaryButton
                title="Close"
                className="mr-2"
                onClick={() => setIsEditing(false)}
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <Table
          className=""
          resourceName="budget-items"
          data={docBudgetLines}
          columns={getVendorInvoiceAdjustmentsColumns}
          cellStyling="flex items-center h-16"
          cellTextStyling="w-full pb-1"
          deleteButtonStyle={deleteButtonStyle}
          initialSelectedRows={[]}
          onSelectedRowChange={() => {}}
          onEditRowCancelClick={() => {}}
          onEditSaveClick={() => {}}
          isEditingAllInlineEditForms={isEditing}
          inLineForm={({ original }, _isEdit, togglePopOver) => (
            <div className="flex items-center mx-4 mb-8">
              <ScheduleOfValues
                row={original}
                onSaveSov={handleSaveSov}
                onTogglePopover={togglePopOver}
                budget={budgetData?.budget}
                isVendorInvoice
                isInvoice
                // Invoice is merged OR parent VI belongs to a mergedInvoice
                disbaleEditing={
                  document?.status === "closed" ||
                  !!original?.parentVendorInvoice?.mergedInvoice
                }
              />
            </div>
          )}
          allowEditingOnRowClick
          enablePopOverEditing
          noDeleteConfirm
          hideCreateNewButton
          hideEditRowButton
          hideSiteHeader
          hideChangeView
          hideSaveButton
          disableHover
          showFooter
        />
      </div>
    </div>
  );
};

DocumentVendorInvoices.propTypes = {
  csiCodes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  documentsDict: PropTypes.shape({}),
  userList: PropTypes.arrayOf(
    PropTypes.shape({
      roleName: PropTypes.string,
    })
  ),
  project: PropTypes.shape({
    reference: PropTypes.string,
    id: PropTypes.string,
    budget: PropTypes.string,
  }),
  setShowCommentsPopover: PropTypes.func,
  document: PropTypes.shape({
    reference: PropTypes.string,
    status: PropTypes.string,
  }),
};

DocumentVendorInvoices.defaultProps = {
  csiCodes: [],
  documentsDict: undefined,
  userList: [],
  project: undefined,
  setShowCommentsPopover: undefined,
  document: undefined,
};

export default DocumentVendorInvoices;

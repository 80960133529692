import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "../Buttons/IconButton";
import crossIcon from "../../assets/images/Close.svg";

const AssociationPopUp = ({ data, handleOpenWF }) => {
  const [isOpen, setIsOpen] = useState(false);
  const WFLength = data?.associatedWorkflows?.length;
  return (
    <div>
      {data?.associatedWorkflows?.length > 0 && (
        <>
          <button
            className="text-xs text-brandGreen font-medium cursor-pointer"
            type="button"
            onClick={() => setIsOpen(true)}
          >{`${WFLength} Associated Workflows`}</button>
          {isOpen && (
            <div className="absolute w-full p-2 bg-white top-0 left-0 rounded-md border border-gray-500">
              <div className="flex flex-row mb-1 justify-between">
                <h3 className="text-xs font-semibold">{`${WFLength} Associated Workflow${
                  WFLength > 1 ? "s" : ""
                }`}</h3>
                <IconButton
                  icon={crossIcon}
                  imgClassName="w-2 h-2"
                  onClick={() => setIsOpen(false)}
                />
              </div>
              {data?.associatedWorkflows?.map((info) => (
                <div
                  key={info?.workflowData?.id}
                  className="flex mb-0.5"
                  onClick={() => handleOpenWF(info?.workflowData?.id)}
                  onKeyPress={() => {}}
                  tabIndex={0}
                  role="button"
                >
                  <p className="text-xs">
                    {info.association === "contingent"
                      ? "Blocked By:"
                      : "Associated with:"}
                    <span className="text-brandGreen pl-2">
                      {info?.workflowData?.name}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};
AssociationPopUp.propTypes = {
  /**
   * data displayed on cards
   */
  data: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    associatedWorkflows: PropTypes.arrayOf(PropTypes.any),
  }),
  handleOpenWF: PropTypes.func,
};

AssociationPopUp.defaultProps = {
  data: [],
  handleOpenWF: undefined,
};
export default AssociationPopUp;

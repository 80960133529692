/* eslint-disable no-param-reassign */
import React, { useCallback, useState } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Widget from "../Widget/Widget";
import WidgetRowGrid from "../Widget/WidgetRowGrid";
import TemperatureRowItem from "./TemperatureRowItem";
import TemperatureRow from "./TemperatureRow";
import windDirectionIcon from "../../assets/images/windDirectionIcon.svg";
import pressureIcon from "../../assets/images/atmosphericPressureIcon.svg";
import WeatherIcon from "../WeatherIcon/WeatherIcon";

const containerCN = (loading, error) => cntl`
  relative
  grid
  grid-cols-3
  gap-4
  relative
  ${loading && "loading"}
  ${error && "weather-error"}
`;

const cardinalDirection = (degree) => {
  switch (degree) {
    case degree <= 348.75 && degree > 326.25:
      return "NNW";
    case degree <= 326.25 && degree > 303.75:
      return "NW";
    case degree <= 303.75 && degree > 281.25:
      return "WNW";
    case degree <= 281.25 && degree > 258.75:
      return "W";
    case degree <= 258.75 && degree > 236.25:
      return "WSW";
    case degree <= 236.25 && degree > 213.75:
      return "SW";
    case degree <= 213.75 && degree > 168.75:
      return "S";
    case degree <= 168.75 && degree > 146.25:
      return "SSE";
    case degree <= 146.25 && degree > 123.75:
      return "SE";
    case degree <= 123.75 && degree > 101.25:
      return "ESE";
    case degree <= 101.25 && degree > 78.75:
      return "E";
    case degree <= 78.75 && degree > 56.25:
      return "ENE";
    case degree <= 56.25 && degree > 33.75:
      return "NE";
    case degree <= 33.75 && degree > 11.25:
      return "NNE";
    default:
      return "N";
  }
};

const precipitationLabel = (index) => {
  switch (index) {
    case 1:
      return "2d ago";
    case 2:
      return "3d ago";
    default:
      return "midnight";
  }
};
const WeatherWidget = ({
  id,
  loading,
  error,
  weather: {
    forecasts: { week: forecasts, prev },
    current: {
      wind: { speed, deg, gust },
      humidity,
      pressure,
      temp: temperature,
    },
    snapshots,
  },
}) => {
  const [temperatureScale, setTemperatureScale] = useState("c");

  const temperatureCalc = useCallback(
    (temp) => {
      switch (temperatureScale) {
        case "c":
          return Array.isArray(temp)
            ? `${
                temp.reduce((acc, val) => {
                  acc += parseInt(val - 273.15, 10);
                  return acc;
                }, 0) / temp.length
              }°C`
            : `${parseInt(temp - 273.15, 10)}°C`;
        default:
          return Array.isArray(temp)
            ? `${
                temp.reduce((acc, val) => {
                  acc += parseInt((val - 273.15) * 1.8 + 32, 10);
                  return acc;
                }, 0) / temp.length
              }°F`
            : `${parseInt((temp - 273.15) * 1.8 + 32, 10)}°F`;
      }
    },
    [temperatureScale]
  );

  const handleSettingsChange = useCallback((val) => {
    setTemperatureScale(val);
  }, []);

  /**
   * Settings Array
   */

  const weatherSettingsArray = temperature && [
    {
      title: "Temperature Scale",
      toggles: [
        {
          type: "checkbox",
          label: "Celsius",
          onClick: () => handleSettingsChange("c"),
          value: temperatureScale === "c",
        },
        {
          type: "checkbox",
          label: "Farenheit",
          onClick: () => handleSettingsChange("f"),
          value: temperatureScale !== "c",
        },
      ],
    },
  ];
  return (
    <Widget
      title="Weather Report"
      id={id}
      settings={weatherSettingsArray}
      draggable={false}
    >
      <div className={containerCN(loading, error)}>
        {/* Temperature */}
        <WidgetRowGrid className="col-span-1" id="temperature-box">
          <TemperatureRow title="Temperature">
            <TemperatureRowItem
              title={temperatureCalc(temperature)}
              value="Now"
            />
          </TemperatureRow>
          <TemperatureRow title="Precipitation">
            {prev?.map((item, i) => {
              return (
                <TemperatureRowItem key={item?.dateTime}>
                  <>
                    <p className="text-gray-400 text-sm whitespace-nowrap">
                      {`${item?.precipitation}`}
                      <span className="text-xxs text-gray-200"> in</span>
                    </p>
                    <p className="text-xxs text-gray-200">
                      {precipitationLabel(i)}
                    </p>
                  </>
                </TemperatureRowItem>
              );
            })}
          </TemperatureRow>
          <TemperatureRow title="Humidity">
            <TemperatureRowItem>
              <>
                <p className="text-gray-400 text-sm whitespace-nowrap">
                  {/* Invisible spans to balance out text */}
                  <span className="text-xxs text-gray-200 invisible">-</span>
                  {`${humidity}%`}
                  <span className="text-xxs text-gray-200 invisible">-</span>
                </p>
                <p className="text-xxs text-gray-200">Avg</p>
              </>
            </TemperatureRowItem>
            <TemperatureRowItem>
              <>
                <p className="text-gray-400 text-sm whitespace-nowrap">
                  <img
                    src={pressureIcon}
                    className="w-3 h-3 inline mr-1"
                    alt="direction-icon"
                  />
                  {`${pressure}`}
                  <span className="text-xxs text-gray-200"> hPA</span>
                </p>
                <p className="text-xxs text-gray-200">Pressure</p>
              </>
            </TemperatureRowItem>
          </TemperatureRow>
          <TemperatureRow title="Wind Speed">
            <TemperatureRowItem>
              <>
                <p className="text-gray-400 text-sm whitespace-nowrap">
                  <img
                    src={windDirectionIcon}
                    className="w-2 h-2 inline mr-1"
                    alt="direction-icon"
                  />
                  {`${(speed * 2.237).toFixed(2)}`}
                  <span className="text-xxs text-gray-200"> mph</span>
                </p>
                <p className="text-xxs text-gray-200">Avg</p>
              </>
            </TemperatureRowItem>
            <TemperatureRowItem>
              <>
                <p className="text-gray-400 text-sm whitespace-nowrap">
                  {/* Invisible spans to balance out text */}
                  <span className="text-xxs text-gray-200 invisible">-</span>
                  {`${cardinalDirection(deg)}`}
                  <span className="text-xxs text-gray-200 invisible">-</span>
                </p>
                <p className="text-xxs text-gray-200">Direction</p>
              </>
            </TemperatureRowItem>
            <TemperatureRowItem>
              <>
                <p className="text-gray-400 text-sm whitespace-nowrap">
                  {`${(gust * 2.237).toFixed(2)}`}
                  <span className="text-xxs text-gray-200"> mph</span>
                </p>
                <p className="text-xxs text-gray-200">Gust</p>
              </>
            </TemperatureRowItem>
          </TemperatureRow>
        </WidgetRowGrid>
        {/* Todays Snapshot for future development */}
        <WidgetRowGrid
          className="col-span-1"
          id="temperature-box"
          title="Today's Snapshot"
        >
          {snapshots?.map((snap) => {
            return (
              <TemperatureRow key={snap?.dateTime}>
                <p className="text-sm">{moment(snap.dateTime).format("h A")}</p>
                <div className="flex w-6 h-6">
                  <WeatherIcon name={snap.weather.icon} />
                </div>
                <TemperatureRowItem
                  title={temperatureCalc(snap.temp)}
                  value={snap.weather.description}
                />
              </TemperatureRow>
            );
          })}
        </WidgetRowGrid>
        {/* Forecast */}
        <WidgetRowGrid
          className="col-span-1"
          id="temperature-box"
          title="Forecast"
          last
        >
          {(forecasts ?? [])?.map((item, index) => {
            const {
              dateTime,
              weather: { icon, description },
              temp: { min, max },
            } = item;
            return (
              <TemperatureRow key={item?.dateTime}>
                <p className="text-sm">
                  {index === 0 ? "Today" : moment(dateTime).format("dddd")}
                </p>
                <div className="flex w-6 h-6">
                  <WeatherIcon name={icon} />
                </div>
                <TemperatureRowItem
                  title={temperatureCalc([max, min])}
                  value={description}
                />
              </TemperatureRow>
            );
          })}
        </WidgetRowGrid>
      </div>
    </Widget>
  );
};

WeatherWidget.propTypes = {
  id: PropTypes.string,
  loading: PropTypes.bool,
  weather: PropTypes.shape({
    forecasts: PropTypes.shape({
      week: PropTypes.arrayOf(PropTypes.shape({})),
      prev: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    current: {
      wind: {},
      humidity: undefined,
      pressure: undefined,
      temp: undefined,
    },
    snapshots: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  error: PropTypes.string,
};

WeatherWidget.defaultProps = {
  id: uuidv4(),
  loading: true,
  weather: {
    forecasts: { prev: [], week: [] },
    current: {
      wind: {},
      humidity: undefined,
      pressure: undefined,
      temp: undefined,
    },
    snapshots: undefined,
  },
  error: undefined,
};

export default WeatherWidget;

import { SopAPI } from "@griffingroupglobal/eslib-api";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { sopKeys } from "../config/reactQuery/queryKeyFactory";

const fetchHistory = async (id) => {
  const { data } = await SopAPI.getById(`$versioninfo?ids=${id}`);

  return data;
};

const useSopHistory = (id) => {
  const enabled = !!id;

  return useAuthenticatedQuery({
    queryKey: sopKeys.history(id),
    queryFn: () => fetchHistory(id),
    enabled,
    onError: (error) => {
      console.error("useSopHistory", error);
    },
  });
};

export default useSopHistory;

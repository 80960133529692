import React from "react";
import PropTypes from "prop-types";
import crossIconWhite from "../../assets/images/crossIconWhite.svg";
import { truncateLabel } from "../../../helpers/Tag";

const Tag = ({ tag, isEditing, handleDeleteTag, className, size }) => {
  return (
    <div key={tag?.value} className="relative max-w-full">
      {isEditing && (
        <button
          type="button"
          className="absolute -top-1.5 right-0"
          style={{
            width: "18px",
            height: "18px",
          }}
          onClick={() => handleDeleteTag(tag)}
        >
          <img
            alt="delete tag"
            className="rounded-xl p-1"
            style={{ backgroundColor: "#027D61" }}
            src={crossIconWhite}
          />
        </button>
      )}
      {tag?.label && (
        <div
          className={`bg-tagGreen rounded-3xl truncate overflow-ellipsis ${className}`}
        >
          <p className="text-primaryGreen text-xs font-light truncate text-ellipsis">
            {truncateLabel(tag?.label, size)}
          </p>
        </div>
      )}
    </div>
  );
};

Tag.propTypes = {
  tag: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  isEditing: PropTypes.bool,
  handleDeleteTag: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.string,
};

Tag.defaultProps = {
  tag: undefined,
  isEditing: false,
  handleDeleteTag: () => {},
  className: "px-3 py-2",
  size: "20",
};
export default Tag;

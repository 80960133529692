import React, { useEffect, useState, useRef } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import Modal from "../Modal/Modal";
import Input from "../Input/Input";
import Chevron from "../Chevron/Chevron";
import { BRAND_GREEN } from "../../../constants";

const dropdownIconCN = (menuIsOpen) => cntl`
  w-3
  h-3
  self-center
  transform
  ${menuIsOpen ? "-rotate-90" : "rotate-90"}
`;

function SelectOne({
  onChange,
  options,
  hideText,
  type,
  value,
  disabled,
  className,
  placeholder,
}) {
  const [isDropDown, setIsDropDown] = useState(false);
  const [customOptions, setCustomOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [option, setOption] = useState("");
  const ref = useRef(null);

  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      setIsDropDown(false);
    };
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, []);

  useEffect(() => {
    setCustomOptions(options);
  }, [options]);

  const openDropDown = () => {
    setIsDropDown(!isDropDown);
  };

  const addCustomOption = (event) => {
    /**
     * if tab pressed on customOpt
     * close dropDown
     */
    if (event.keyCode === 9) {
      setIsDropDown(false);
    } else {
      setOpenDialog(!openDialog);
    }
  };

  const onSelectOption = (event, val) => {
    if (event.keyCode !== 9) {
      setSelectedOption(val);
      setIsDropDown(false);
      onChange(val);
    }
  };

  const onCancel = () => {
    setOpenDialog(false);
    setIsDropDown(false);
  };

  const onSave = () => {
    const newOption = {
      label: option,
      display: option,
      custom: true,
      id: uuidv4(),
      value: uuidv4(),
    };
    setCustomOptions((prev) => [...prev, newOption]);
    onChange(newOption);
    setOption("");
    setOpenDialog(false);
    setIsDropDown(false);
  };

  return (
    <div className="relative" ref={ref}>
      <div
        className="flex cursor-pointer"
        onClick={disabled ? undefined : openDropDown}
        onKeyDown={disabled ? undefined : openDropDown}
        role="button"
        tabIndex={0}
      >
        <p className={className ?? "ESInputLabel mr-2"}>
          {selectedOption?.label || value || "Select One"}
        </p>
        <Chevron
          color={BRAND_GREEN}
          strokeWidth="2px"
          className={dropdownIconCN(isDropDown)}
        />
      </div>
      {isDropDown && (
        <div className="absolute border p-1 rounded-lg shadow-md z-50 bg-white min-w-fit">
          {customOptions?.map((customOption) => (
            <div
              key={customOption?.label}
              className="p-2 hover:bg-backgroundGreen rounded-lg min-w-fit flex flex-row items-center justify-start"
              onClick={(e) => onSelectOption(e, customOption)}
              onKeyDown={(e) => onSelectOption(e, customOption)}
              tabIndex={0}
              role="button"
            >
              <p className="text-es-sm text-es-medium-grey font-es-semibold tracking-es-normal">
                {customOption?.label}
              </p>
            </div>
          ))}
          {customOptions?.length > 0 && <hr className="border-t my-2" />}
          <div
            onClick={addCustomOption}
            onKeyDown={addCustomOption}
            tabIndex={0}
            role="button"
            className="p-2 hover:bg-backgroundGreen rounded-lg min-w-fit flex flex-row items-center justify-start"
          >
            <p className="text-es-sm text-es-medium-grey font-es-semibold tracking-es-normal">
              {placeholder ?? "Add custom option"}
            </p>
          </div>
        </div>
      )}
      <Modal
        isOpen={openDialog}
        title="ADD A CUSTOM OPTION"
        onRequestModalClose={onCancel}
        hideFooter
        primaryButtonTitle="Save"
        primaryButtonOnClick={onSave}
        tertiaryButtonTitle="Cancel"
      >
        <div>
          <p>What do you want to name this {type}?</p>
          {!hideText && (
            <p>This option will become available for other contacts</p>
          )}
          <Input
            autoFocus
            placeholder="Name"
            className="mt-5"
            value={option}
            onChange={(val) => setOption(val)}
          />
        </div>
      </Modal>
    </div>
  );
}

SelectOne.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      link: PropTypes.string,
      linkType: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  hideText: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

SelectOne.defaultProps = {
  onChange: undefined,
  options: undefined,
  hideText: false,
  type: undefined,
  value: undefined,
  disabled: false,
  className: undefined,
  placeholder: undefined,
};

export default SelectOne;

import moment from "moment";

export default [
  {
    id: "be4f0855-2a89-4d89-9e97-6d9457a67abb",
    x: moment("2021-01-01").valueOf(),
    y: 33,
  },
  {
    id: "a6f40128-ff59-4686-b776-e4bb0cf106b8",
    x: moment("2021-01-02").valueOf(),
    y: 36,
  },
  {
    id: "74140f49-4795-485a-a085-82524546deae",
    x: moment("2021-01-03").valueOf(),
    y: 11,
  },
  {
    id: "93820100-42c4-4780-8407-b4b98c25238b",
    x: moment("2021-01-04").valueOf(),
    y: 92,
  },
  {
    id: "2e2fe238-edc9-4391-9138-942e0389ba74",
    x: moment("2021-01-05").valueOf(),
    y: 55,
  },
  {
    id: "e64e250d-81c8-49bd-a6bb-cb31044759b5",
    x: moment("2021-01-06").valueOf(),
    y: 32,
  },
  {
    id: "f1061823-ce8d-46d3-bf3b-df4c43d047ee",
    x: moment("2021-01-07").valueOf(),
    y: 73,
  },
  {
    id: "3278a1bd-70c5-46e5-bd43-6fd3203c1307",
    x: moment("2021-01-08").valueOf(),
    y: 87,
  },
  {
    id: "bf3c99db-e1f2-4f31-97bb-c9e76ee07fdd",
    x: moment("2021-01-09").valueOf(),
    y: 39,
  },
  {
    id: "fd9f3233-d046-4ad6-bdc4-22b1393c47a4",
    x: moment("2021-01-10").valueOf(),
    y: 10,
  },
];

import React from "react";
import PropTypes from "prop-types";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./CarouselView.css";
import MediaVideo from "./MediaVideo";

const CarouselView = ({
  items,
  showIndex,
  startIndex,
  showPlayButton,
  showFullscreenButton,
  width,
  sort,
}) => {
  return (
    <div style={{ width }}>
      <ImageGallery
        items={
          sort
            ? items
                ?.sort((a, b) => {
                  return a?.index - b?.index;
                })
                ?.map((item) => {
                  const mediaObject = {
                    original: item?.contentsUrl,
                    thumbnail: item?.contentsUrl,
                    contentType: item?.contentType,
                  };
                  if (item?.category === "Videos") {
                    mediaObject.renderItem = () => (
                      <MediaVideo video={mediaObject} />
                    );
                    mediaObject.renderThumbInner = () => (
                      <MediaVideo video={mediaObject} thumb />
                    );
                  }
                  return mediaObject;
                })
            : items?.map((item) => {
                const mediaObject = {
                  original: item?.contentsUrl,
                  thumbnail: item?.contentsUrl,
                  contentType: item?.contentType,
                };
                if (item?.category === "Videos") {
                  mediaObject.renderItem = () => (
                    <MediaVideo video={mediaObject} />
                  );
                  mediaObject.renderThumbInner = () => (
                    <MediaVideo video={mediaObject} thumb />
                  );
                }
                return mediaObject;
              })
        }
        showIndex={showIndex}
        showPlayButton={showPlayButton}
        showFullscreenButton={showFullscreenButton}
        startIndex={startIndex}
      />
    </div>
  );
};

CarouselView.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      original: PropTypes.string,
      thumbnail: PropTypes.string,
    })
  ),
  showPlayButton: PropTypes.bool,
  showIndex: PropTypes.bool,
  showFullscreenButton: PropTypes.bool,
  width: PropTypes.string,
  startIndex: PropTypes.number,
  sort: PropTypes.bool,
};

CarouselView.defaultProps = {
  items: [],
  showIndex: true,
  showPlayButton: false,
  showFullscreenButton: false,
  width: "750px",
  startIndex: 0,
  sort: false,
};

export default CarouselView;

import { useCallback, useEffect, useState } from "react";

import useGetMergedExpenses from "../../../../../hooks/useGetMergedExpenses";
import { useAppState } from "../../../../../state/appState";
import useExpenseDocuments from "../../../../../hooks/useExpenseDocuments";
import useUpdateMergedExpense from "../../../../../hooks/useUpdateMergedExpense";

const useDocumentExpensesData = ({ projectRef }) => {
  const [{ financialsConfiguration }] = useAppState();
  const { data } = useGetMergedExpenses(projectRef);
  const { csiCodes } = useExpenseDocuments();
  const { mutate: updateExpense } = useUpdateMergedExpense();
  const [isLeftActive, setIsLeftActive] = useState(true);
  const [isRightActive, setIsRightActive] = useState(false);
  const [billableExpenseData, setBillableExpenseData] = useState([]);
  const [nonBillableExpenseData, setNonBillableExpenseData] = useState([]);

  useEffect(() => {
    if (csiCodes?.length && !!data?.expenses) {
      const getExpenses = () => {
        const billable = data?.expenses?.billableExpenses?.map((entry) => {
          const csiCodeObj = csiCodes?.find(
            (c) =>
              c.value ===
              `${entry?.financialCode?.division} ${entry?.financialCode?.code} ${entry?.financialCode?.subcode}`
          );

          return {
            ...entry,
            financialCode: csiCodeObj?.label,
            category:
              financialsConfiguration?.financials?.expense?.categories?.find(
                (category) => category.id === entry?.category
              )?.display,
          };
        });
        setBillableExpenseData(billable);

        const nonBillable = data?.expenses?.nonBillableExpenses?.map(
          (entry) => {
            const csiCodeObj = csiCodes?.find(
              (c) =>
                c.value ===
                `${entry?.financialCode?.division} ${entry?.financialCode?.code} ${entry?.financialCode?.subcode}`
            );

            return {
              ...entry,
              financialCode: csiCodeObj?.label,
              category:
                financialsConfiguration?.financials?.expense?.categories?.find(
                  (category) => category.id === entry?.category
                )?.display,
            };
          }
        );
        setNonBillableExpenseData(nonBillable);
      };

      getExpenses();
    }
  }, [csiCodes, data, financialsConfiguration]);

  const onToggleIsBillable = useCallback(
    (expenseRef) => {
      const expense = data?.dict[expenseRef];

      let foundExpense;
      if (expense.billable) {
        // remove from billable
        setBillableExpenseData((prev) =>
          prev.filter((exp) => {
            if (exp.reference === expenseRef) {
              foundExpense = exp;
            }
            return exp.reference !== expenseRef;
          })
        );
        // add to non-billable
        setNonBillableExpenseData((prev) => [
          ...prev,
          {
            ...foundExpense,
            billable: false,
          },
        ]);
      } else {
        // remove from non-billable
        setNonBillableExpenseData((prev) =>
          prev.filter((exp) => {
            if (exp.reference === expenseRef) {
              foundExpense = exp;
            }
            return exp.reference !== expenseRef;
          })
        );
        // add to billable
        setBillableExpenseData((prev) => [
          ...prev,
          {
            ...foundExpense,
            billable: true,
          },
        ]);
      }

      updateExpense({
        expenseId: expense.id,
        lastUpdated: expense.metadata.lastUpdated,
      });
    },
    [data?.dict, updateExpense]
  );

  return {
    isLeftActive,
    setIsLeftActive,
    isRightActive,
    setIsRightActive,
    billableExpenseData,
    nonBillableExpenseData,
    onToggleIsBillable,
  };
};

export default useDocumentExpensesData;

import timesheetKeys from "../timesheetKeys";

const editTsListStatusResponder = (queryClient, query) => {
  return {
    onSuccess: (returnedData, variables) => {
      return queryClient.setQueryData(
        timesheetKeys.timesheetList(query),
        (oldData) => {
          const approvedMap = new Map();

          if (variables.action === "$approvePayroll") {
            /* NERD: need to reach into nested data. There should only ever be one item
             *       in here. Expecting an update to response that will remove the need for this */
            returnedData.forEach((ts) => {
              ts.data.forEach((t) => {
                approvedMap.set(t.id, t);
              });
            });
          } else {
            returnedData.forEach((ts) => {
              approvedMap.set(ts.data.id, ts.data);
            });
          }

          const updatedTimeSheets = oldData.map((ts) => {
            if (approvedMap.has(ts.id)) {
              const newStatus = {
                status: "",
                payrollStatus: "",
              };

              if (variables.action === "$approve") {
                newStatus.status = "approved";
                newStatus.payrollStatus = "pending";
              }

              if (variables.action === "$decline") {
                newStatus.status = "rejected";
                newStatus.payrollStatus = "pending";
              }

              if (variables.action === "$approvePayroll") {
                newStatus.status = "locked";
                newStatus.payrollStatus = "approved";
              }

              return {
                ...ts,
                ...newStatus,
              };
            }
            return ts;
          });

          return updatedTimeSheets;
        }
      );
    },
    onError: (error) => {
      console.error("Error", error);
    },
  };
};

export default editTsListStatusResponder;

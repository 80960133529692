// Framework Tools
import React from "react";
import PropTypes from "prop-types";

// Libraries
import moment from "moment";

// Components
import TertiaryHeader from "../../TextHeaders/TertiaryHeader";

// Parent - RequestGridView
export default function RequestGridViewProjectedCompletionCell({ projected }) {
  // abstracts the endDate from the projected prop, can also abstract totalDuration - which is calcuated in the Requestt Grid View
  const { projectedEndDate } = projected;
  return (
    <div className="w-full h-ful flex flex-col justify-start flex-wrap px-2">
      <TertiaryHeader className="text-sm text-left">
        Projected Completion
      </TertiaryHeader>
      <p className="text-sm text-left">
        {moment(projectedEndDate).format("MMMM DD, YYYY")}
      </p>
    </div>
  );
}

// prop types
RequestGridViewProjectedCompletionCell.propTypes = {
  projected: PropTypes.shape({
    projectedEndDate: PropTypes.string,
  }),
};

// default props
RequestGridViewProjectedCompletionCell.defaultProps = {
  projected: {
    projectedEndDate: "",
  },
};

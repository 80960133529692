import React from "react";
import PropTypes from "prop-types";
import cardViewGreenIcon from "../../assets/images/cardViewGreen.svg";
import tableViewGreenIcon from "../../assets/images/tableViewIcon.svg";
import IconLabel from "../IconLabel/IconLabel";

const SwitcherButton = ({ isShowingTable, setIsShowingTable }) => {
  return (
    <div className="flex">
      <IconLabel
        icon={isShowingTable ? cardViewGreenIcon : tableViewGreenIcon}
        text={`${isShowingTable ? "Card" : "List"} View`}
        iconStyle={{ width: "18px", height: "18px" }}
        onClick={() => setIsShowingTable(!isShowingTable)}
        textClassName="text-darkenedGreen text-base"
      />
    </div>
  );
};

SwitcherButton.propTypes = {
  isShowingTable: PropTypes.bool,
  setIsShowingTable: PropTypes.func,
};

SwitcherButton.defaultProps = {
  isShowingTable: false,
  setIsShowingTable: undefined,
};

export default SwitcherButton;

import { useMemo } from "react";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import { useAppState } from "../../../../state/appState";
import {
  TODO_MEMBERS_DROPDOWN,
  TODO_SELECT_MEMBER,
  TOGGLE_POSITIONED_POPUP,
} from "../../../../constants";
import useMouseTracker from "../../../../hooks/useMouseTracker";
import { useUsers } from "../../../../hooks/useUsers.new";

export default ({ todo, members, close, dispatch }) => {
  const { data: users } = useUsers();

  const { data: currentUser } = useCurrentUser();

  const [, appStateDispatch] = useAppState();

  const { mousePosition } = useMouseTracker();

  const viewMembers = () => {
    appStateDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      position: {
        x: mousePosition.x,
        y: mousePosition.y,
      },
      popupData: { members: members?.slice(5), closeModal: close },
      popupType: TODO_MEMBERS_DROPDOWN,
    });
  };

  const selectedMembers = useMemo(() => {
    return todo?.members?.map((item) => item.user);
  }, [todo?.members]);

  const selectMembersList = useMemo(() => {
    return users?.users?.filter(
      (item) => item?.reference !== currentUser?.reference
    );
  }, [currentUser?.reference, users?.users]);

  const onChangeDescription = (value) => {
    dispatch({
      type: "description",
      value,
    });
  };

  const onMemberChange = (val, command) => {
    let type;
    let value;
    if (val === currentUser?.reference) return;
    switch (command) {
      case "add":
        type = "addMember";
        value = { user: val.reference };
        break;
      default:
        type = "removeMember";
        value = val;
    }
    dispatch({
      type,
      value,
    });
  };

  const selectMembers = () => {
    appStateDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      position: {
        x: mousePosition.x,
        y: mousePosition.y + 20,
      },
      popupData: {
        members: selectMembersList,
        selectedMembers,
        onAdd: (val) => onMemberChange(val, "add"),
        onRemove: (val) => onMemberChange(val, "remove"),
        closeModal: close,
      },
      popupType: TODO_SELECT_MEMBER,
    });
  };

  const handleCheckRequiredByAll = (value) => {
    dispatch({
      type: "setRequiredByAll",
      value,
    });
  };

  return {
    onChangeDescription,
    onMemberChange,
    selectedMembers,
    selectMembersList,
    currentUser,
    appStateDispatch,
    viewMembers,
    selectMembers,
    handleCheckRequiredByAll,
  };
};

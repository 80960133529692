import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { UserAPI } from "@griffingroupglobal/eslib-api";
import { getFullName } from "../../../helpers/Formatters";
import Avatar from "../Message/Avatar";

const WorkflowCardResubmission = ({ data }) => {
  const [stepData, setStepData] = useState(data);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const getUserData = async (id) => {
      if (!id) {
        return "";
      }
      const newId = id?.split("/");
      const { data: user } = await UserAPI.get({
        params: {
          id: newId[1],
        },
      });
      return user?.entries[0]?.resource;
    };
    const getStepData = async () => {
      const tempArr = data;
      const newStepData = tempArr?.map(async (info) => {
        const newMembers = info?.members?.map(async (members) => {
          const userData = await getUserData(members?.reference);
          return { ...members, userData };
        });
        const formatedMembers = await Promise.all(newMembers);

        return { ...info, members: formatedMembers };
      });
      const formatedStepData = await Promise.all(newStepData);
      setStepData(formatedStepData);
    };
    if (data?.length > 0 && !loaded) {
      getStepData();
      setLoaded(true);
    }
  }, [data, loaded]);
  return (
    <>
      {stepData?.map((info, index) => (
        <div
          className="flex mb-4 overflow-hidden"
          // eslint-disable-next-line react/no-array-index-key
          key={`${info.stepName}-${index}`}
        >
          {info?.members?.map((member, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${member?.userData?.name}-${i}`}>
              <Avatar name={getFullName(member?.userData?.name)} />
            </div>
          ))}
          <div className="ml-4">
            <h3 className="text-xs capitalize overflow-hidden whitespace-nowrap font-semibold text-gray-400">
              {getFullName(info?.members[0]?.userData?.name)}
              {info.members > 1 && (
                <span className="normal-case">{`${
                  info?.members?.length > 1 &&
                  ` + ${info?.members?.length - 1} ${
                    info?.members?.length === 2 ? "other" : "others"
                  }`
                }`}</span>
              )}
            </h3>
            <h3 className="text-xs font-semibold">{info.stepName}</h3>
            {info?.members[0]?.note && (
              <h3 className="mt-2 text-xs text-gray-200">
                {info?.members[0]?.note}
              </h3>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

WorkflowCardResubmission.propTypes = {
  /**
   * data displayed on cards
   */
  data: PropTypes.arrayOf({
    members: PropTypes.arrayOf({
      refernce: PropTypes.string,
    }),
  }),
};

WorkflowCardResubmission.defaultProps = {
  data: [],
};
export default WorkflowCardResubmission;

const formatUrlResource = (resource) => {
  let formattedResource;

  if (resource === "properties") {
    formattedResource = "Property";
  }

  if (resource === "projects") {
    formattedResource = "Project";
  }

  if (resource === "assets" || resource === "asset") {
    formattedResource = "Asset";
  }

  if (resource === "space") {
    formattedResource = "Space";
  }

  return formattedResource;
};

export default formatUrlResource;

import cntl from "cntl";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import DocumentUploadForm from "../DocumentUploadForm/DocumentUploadForm";
import FolderCard from "../FolderCard/FolderCard";
import { financialDocumentsType } from "../../../constants";

const folderSectionCN = cntl`
  flex
  flex-wrap
  w-full
  mt-8
  mb-12
`;

const DocumentFoldersCard = ({
  docTypeOptionsMap,
  onFolderClick,
  folderViews,
  resource,
  onFinishUploading,
  disableUpload,
}) => {
  const [visibleFolders, setVisibleFolders] = useState();

  useEffect(() => {
    if (docTypeOptionsMap && !visibleFolders && folderViews) {
      setVisibleFolders(
        [
          {
            id: uuidv4(),
            title: "Favorites",
            docType: "isFavorited",
            itemCount: folderViews?.isFavorited?.sum,
          },
          {
            id: uuidv4(),
            title: "Drafts",
            docType: "isDraft",
            itemCount: folderViews?.isDraft?.sum,
          },
          ...[
            ...Object.entries(docTypeOptionsMap)?.map(([key, value]) => {
              return {
                id: key,
                title: value.label,
                docType: value.value,
                itemCount: folderViews?.[value.value]?.sum ?? 0,
                isUploadable: false,
              };
            }),
          ],
        ]
          .filter((doc) => financialDocumentsType[doc.docType])
          .sort((a, b) =>
            a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
          )
      );
    }
  }, [docTypeOptionsMap, folderViews, resource?.resource, visibleFolders]);

  return (
    <div className="flex flex-wrap" id="document-folders">
      {!disableUpload && (
        <DocumentUploadForm
          resource={resource}
          onFinishUploading={onFinishUploading}
          showCorner
          small
        />
      )}

      <div className={folderSectionCN}>
        {visibleFolders &&
          visibleFolders.map((folder) => (
            <div
              key={folder.docType}
              style={{
                width: "205px",
                maxWidth: "205px",
                margin: "0 12px 40px 0",
              }}
            >
              <FolderCard
                {...folder}
                itemCount={folderViews?.[folder.docType]?.docs?.length ?? 0}
                onClick={() => onFolderClick(folder.title, folder.docType)}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

DocumentFoldersCard.propTypes = {
  docTypeOptionsMap: PropTypes.shape({}),
  onFolderClick: PropTypes.func,
  folderViews: PropTypes.shape({
    isFavorited: PropTypes.shape({
      sum: PropTypes.number,
    }),
    isDraft: PropTypes.shape({
      sum: PropTypes.number,
    }),
  }),
  resource: PropTypes.shape({
    resource: PropTypes.string,
  }),
  onFinishUploading: PropTypes.func,
  disableUpload: PropTypes.bool,
};

DocumentFoldersCard.defaultProps = {
  docTypeOptionsMap: undefined,
  onFolderClick: undefined,
  folderViews: undefined,
  resource: undefined,
  onFinishUploading: undefined,
  disableUpload: false,
};

export default DocumentFoldersCard;

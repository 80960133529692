import { useCallback, useEffect } from "react";
import { SET_FINANCIALS_ACTUAL } from "../constants";
import { useAppState } from "../state/appState";
import financialActualData from "../stories/assets/mockData/financialActualData.json";

export default () => {
  const [{ financialsActual }, dispatch] = useAppState();

  const reload = useCallback(async () => {
    // use budgetId to pull financials for a budget
    dispatch({
      type: SET_FINANCIALS_ACTUAL,
      financialsActual: financialActualData,
    });
  }, [dispatch]);

  useEffect(() => {
    reload();
  }, [reload]);

  return [financialsActual, reload];
};

import { useMemo, useState } from "react";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import isBegOfDay from "../../../../helpers/Date/isBegOfDay";
import isEndOfDay from "../../../../helpers/Date/isEndOfDay";
import useSopVersionForTask from "../../../../hooks/useSopVersionForTask";
import { useSop } from "../../../../hooks/useSop";
import stepsForTask from "../../../../helpers/Task/stepsForTask";

const useViewTaskModalData = ({ currentTask }) => {
  const initialAllDayCheck =
    currentTask?.allDay ||
    (isBegOfDay(currentTask?.startDate) && isEndOfDay(currentTask?.endDate));

  const [taskForm, setTaskForm] = useState({ ...currentTask });
  const [isAllDay, setIsAllDay] = useState(initialAllDayCheck);

  const { usedSopDict } = useSopVersionForTask({ currentTask });

  const { data: sopsData } = useSop();

  const isTaskComplete = currentTask?.status === "done";

  const steps = useMemo(
    () => stepsForTask(currentTask, usedSopDict),
    [currentTask, usedSopDict]
  );

  const sopOptions = useMemo(() => {
    return sopsData?.reduce(
      (list, item) => {
        return {
          options: [
            ...list.options,
            { label: item.name, value: item.reference },
          ],
          dict: { ...list.dict, [item.reference]: item },
        };
      },
      { dict: {}, options: [] }
    );
  }, [sopsData]);

  const toggleAllDay = () => {
    setIsAllDay(!isAllDay);
  };

  const allDayData = { isAllDay, toggleAllDay };

  const handleStepAdd = () => {
    const taskFormCopySteps = [...taskForm?.steps];

    taskFormCopySteps.push({
      name: "",
      description: "",
      sop: "",
      date: moment().format(),
      id: uuidv4(),
    });

    setTaskForm({ ...taskForm, steps: taskFormCopySteps });
  };

  const handleStepEdit = (index, key, value) => {
    // create a copy of the task form
    const taskFormCopy = { ...taskForm };
    // create a copy of the steps array
    const taskFormCopySteps = [...taskFormCopy.steps];

    // update the step at the index with the new value
    const updatedSteps = taskFormCopySteps.map((item, i) => {
      // return the updated item if it is the one we are updating
      if (i === index) return { ...item, [key]: value };
      // return the item if it is not the one we are updating
      return item;
    });

    // update the task form with the updated steps
    setTaskForm({ ...taskFormCopy, steps: updatedSteps });
  };

  const handleStepRemove = (index) => {
    // create a copy of the steps array and set task form
    const taskFormCopySteps = taskForm.steps?.filter((item, i) => i !== index);
    // update the task form with the updated steps
    setTaskForm({ ...taskForm, steps: taskFormCopySteps });
  };

  return {
    allDayData,
    isTaskComplete,
    steps,
    sopOptions,
    handleStepAdd,
    handleStepEdit,
    handleStepRemove,
  };
};

export default useViewTaskModalData;

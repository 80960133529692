import { useEffect, useState } from "react";

const useDropdownWithPillsData = ({ value = [], setValue }) => {
  const [accordionStates, setAccordionStates] = useState([]);
  const [selectedPills, setSelectedPills] = useState([]);

  /**
   * // Calculate all pills selected inside every selected options
   * @param {Object[]} options current options selected
   * @returns {string[]} array of `ids`
   */
  const syncSelectedPills = (options = []) => {
    return options.reduce(
      (acc, current) => [...acc, ...(current?.value?.suboptions || [])],
      []
    );
  };

  // Initializes dropdown with pills selected when `value` changes. This is done in case user is updating values
  // for an existing form, or when adding new pills
  useEffect(() => {
    // Sync all pills inside every selected option with selectedPills state
    const allPills = syncSelectedPills(value);
    setSelectedPills(allPills);
  }, [value]);

  /**
   * Add given pill to the given option
   * @param {Object} props native props from react-select (Here is the current option)
   * @param {string} selectedPill id of selected pill
   */
  const handlePillSelected = (props, selectedPill) => {
    // id of selected option
    const { value: id } = props || {};

    // Check if the selected pill is already in the pills state
    const isPillSelected = selectedPills.includes(selectedPill);

    // Update the selected pills state
    const updatedPills = isPillSelected
      ? selectedPills.filter((pill) => pill !== selectedPill)
      : [...selectedPills, selectedPill];

    setSelectedPills(updatedPills);

    // Make a deep copy of selected options.
    const deepCopySelectedOptions = JSON.parse(JSON.stringify(value));

    const optionIndex = deepCopySelectedOptions.findIndex(
      (selectedOption) => selectedOption.value.id === id
    );

    // If the option is found, update its data array
    if (optionIndex !== -1) {
      const existingPills =
        deepCopySelectedOptions[optionIndex].value.suboptions || [];

      const updatedOption = {
        ...deepCopySelectedOptions[optionIndex],
        value: {
          ...deepCopySelectedOptions[optionIndex].value,
          suboptions: isPillSelected
            ? existingPills.filter((pill) => pill !== selectedPill)
            : [...existingPills, selectedPill],
        },
      };

      // Create a new array with the updated option
      const updatedData = [...deepCopySelectedOptions];
      updatedData[optionIndex] = updatedOption;

      // Update the state with the selected suboption (pill)
      setValue(updatedData);
    }
  };

  return {
    accordionStates,
    selectedPills,
    setAccordionStates,
    setSelectedPills,
    handlePillSelected,
  };
};

export default useDropdownWithPillsData;

import React, { useEffect, useState, useMemo, useRef } from "react";
import cntl from "cntl";

import SiteHeader from "../../stories/Components/SiteHeader/SiteHeader";
import WidgetContainer from "../../stories/Components/Widget/WidgetContainer";
import InlineInput from "../../stories/Components/Input/InlineInput";
import sendButtonIcon from "../../stories/assets/images/messagingDmIconWhite.svg";
import chatDarkGreenIcon from "../../stories/assets/images/chatDarkGreenIcon.svg";
import contactUsImage from "../../stories/assets/images/contactUsImage.png";
import contactUsImageSmall from "../../stories/assets/images/contactUsImageSmall.svg";
import mailIcon from "../../stories/assets/images/emailGreen.svg";
import phoneIcon from "../../stories/assets/images/phoneGreen.svg";

import useConactUsData from "./useConactUsData";

const PageCN = cntl`
  lg:flex
  flex
  flex-initial
  flex-col
  mb-12
`;

const MAX_WIDTH = "1277px";
const BREAK_POINT = 1216;

const inputStyle = {
  maxWidth: "100%",
  maxHeight: "222px",
  paddingTop: "10px",
  paddingLeft: "0px",
};

const doneStyle = {
  width: "718px",
  height: "380px",
  borderRadius: "10px",
  border: "1px",
};

const buttonStyle = {
  width: "120px",
  height: "48px",
  padding: "12px, 20px, 12px, 20px",
  gap: "10px",
  background: "#027D61",
};

const upperWrapperStyle = {
  maxWidth: MAX_WIDTH,
};
const cardWrapperStyle = {
  maxWidth: "602.5px",
};

const rightColStyle = {
  height: "376px",
  gap: "24px",
};
const leftColStyle = {
  width: "462.8px",
  height: "380px",
  borderRadius: "10px 10px 10px 10px",
};
const imageStyle = {
  width: "462.8px",
  height: "380px",
};

const ContactUsPage = () => {
  const {
    currentUser,
    onChangeMessage,
    input,
    sendMessage,
    doneSending,
    setDoneSending,
  } = useConactUsData();

  const [elementWidth, setElementWidth] = useState("0px");

  const containerRef = useRef(null);

  useEffect(() => {
    const updateWidth = () =>
      setElementWidth(Number(containerRef?.current?.offsetWidth));
    window.addEventListener("resize", updateWidth);
    updateWidth();

    // cleanup
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [setElementWidth]);

  const heroImage = useMemo(() => {
    return elementWidth > BREAK_POINT ? contactUsImage : contactUsImageSmall;
  }, [elementWidth]);

  return (
    <div className={PageCN}>
      <SiteHeader title="Contact Us" />
      <div
        ref={containerRef}
        style={{
          maxWidth: MAX_WIDTH,
        }}
        className="flex flex-col py-8 gap-5"
      >
        <WidgetContainer customStyle={upperWrapperStyle}>
          <div className="flex">
            <div style={leftColStyle} className="relative flex-shrink-0">
              <img
                style={imageStyle}
                src={heroImage}
                className="shrink-0 absolute top-0 right-1"
                alt=""
                role="presentation"
              />
            </div>
            {doneSending ? (
              <div
                style={rightColStyle}
                className="flex flex-col flex-1 justify-between"
              >
                <div style={{ width: "100%" }}>
                  <div className="flex flex-col">
                    <div className="flex mb-2">
                      <img
                        className="mr-2"
                        src={chatDarkGreenIcon}
                        alt="chat with us"
                      />
                      <p className="text-brandDarkGreen font-semibold">
                        Message Us
                      </p>
                    </div>
                  </div>
                  <p>
                    Replies will be sent to the email address you use to log
                    into EstateSpace.
                  </p>
                </div>
                <InlineInput
                  greenbackground={false}
                  inputStyle={inputStyle}
                  editing
                  textarea
                  value={input ?? null}
                  onChangeCallback={onChangeMessage}
                  width="w-full"
                  size="md"
                  rows={8}
                  placeholder="Type Message"
                  hidePencil
                />
                <button
                  className="flex justify-center items-center bg-brandDarkGreen rounded-md text-white "
                  type="button"
                  onClick={sendMessage}
                  disabled={input === ""}
                  style={buttonStyle}
                >
                  <img
                    src={sendButtonIcon}
                    className="on-button-hover"
                    alt="send-direct-message"
                  />
                  <span className="text-white ml-2">Send</span>
                </button>
              </div>
            ) : (
              <div
                style={doneStyle}
                className="flex flex-col justify-center items-start font-semibold w-full h-full"
              >
                <div className="flex flex-col items-start w-auto">
                  <h1
                    style={{
                      fontSize: "38px",
                      fontWeight: "700",
                      lineHeight: "48px",
                      letterSpacing: "1.875px",
                    }}
                    className="mb-1"
                  >
                    Thanks!
                  </h1>
                  <p className="font-normal mb-1">
                    Your message has been received.
                  </p>
                  <p className="font-normal">
                    Responses will be sent to{" "}
                    <span className="font-semibold">
                      {currentUser?.email ?? "<No email provided>"}
                    </span>
                  </p>
                  <button
                    type="button"
                    style={buttonStyle}
                    onClick={() => setDoneSending(true)}
                    className="rounded-md text-white mt-5"
                  >
                    Done
                  </button>
                </div>
              </div>
            )}
          </div>
        </WidgetContainer>
        <div className="flex w-full gap-3">
          <WidgetContainer customStyle={cardWrapperStyle}>
            <div className="flex flex-col">
              <div className="flex mb-2">
                <img className="mr-2" src={mailIcon} alt="chat with" />
                <p className="text-brandDarkGreen font-semibold">
                  Send an Email
                </p>
              </div>
              <p className="mb-2">
                One of our specialists will respond to your message as soon as
                possible
              </p>
              <p className="font-semibold">customersuccess@estatespace.com</p>
            </div>
          </WidgetContainer>
          <WidgetContainer customStyle={cardWrapperStyle}>
            <div className="flex flex-col">
              <div className="flex mb-2">
                <img className="mr-2" src={phoneIcon} alt="chat with" />
                <p className="text-brandDarkGreen font-semibold">
                  Give Us a Call
                </p>
              </div>
              <p className="mb-2">
                Chat with a real person any day of the week
              </p>
              <p className="font-semibold">1-855-261-4342</p>
            </div>
          </WidgetContainer>
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;

import React, { useMemo } from "react";
import SiteHeader from "../../../../stories/Components/SiteHeader/SiteHeader";
import TimeOff from "../../../../stories/Components/TimeOff/TimeOffTable";
import WidgetContainer from "../../../../stories/Components/Widget/WidgetContainer";
import PayrollView from "../PayrollView";
import TimeOffUserView from "../TimeOffUserView";
import TimeSheetApprovalView from "../TimesheetApprovalView";
import TimesheetView from "../TimesheetView";
import useTimesheetOverviewData from "./useTimesheetOVerviewData";

const TimesheetOverview = () => {
  const {
    currentUser,
    activeTabIndex,
    userDict,
    employees,
    pageState,
    payrollApproveConditions,
    timeOffApproveConditions,
    timesheetApprovalConditions,
    setTabs,
    handleTabClick,
  } = useTimesheetOverviewData();

  const tabs = useMemo(() => {
    const permissionedTabs = [];
    if (currentUser?.isEmployee) {
      permissionedTabs.push({
        content: <TimesheetView />,
        title: `My Timesheet`,
        id: "my-timesheet",
      });
    }

    if (currentUser?.isEmployee) {
      permissionedTabs.push({
        title: "Request Time Off",
        id: "user-timeoff",
        content: <TimeOffUserView />,
      });
    }

    if (timesheetApprovalConditions) {
      permissionedTabs.push({
        content:
          pageState?.timesheet?.userId &&
          pageState?.timesheet?.tab === "approve-time" ? (
            <TimesheetView />
          ) : (
            <TimeSheetApprovalView
              currentUser={currentUser}
              usersDict={userDict}
            />
          ),
        title: "Timesheet Approval",
        id: "approve-time",
      });
    }

    if (payrollApproveConditions) {
      permissionedTabs.push({
        title: "Payroll Approval",
        id: "approve-payroll",
        content: pageState?.timesheet?.userId ? (
          <TimesheetView />
        ) : (
          <PayrollView
            currentUser={currentUser}
            usersDict={userDict}
            employees={employees}
          />
        ),
      });
    }

    if (timeOffApproveConditions) {
      permissionedTabs.push({
        title: "Time Off Approval",
        id: "approve-timeoff",
        content: pageState?.timesheet?.userId ? (
          <TimeOffUserView />
        ) : (
          <TimeOff currentUser={currentUser} />
        ),
      });
    }
    setTabs(permissionedTabs);
    return permissionedTabs;
  }, [
    currentUser,
    employees,
    pageState?.timesheet?.tab,
    pageState?.timesheet?.userId,
    payrollApproveConditions,
    timeOffApproveConditions,
    timesheetApprovalConditions,
    userDict,
    setTabs,
  ]);

  return (
    <div>
      <SiteHeader title="Timesheets" />
      <WidgetContainer
        tabs={tabs}
        onTabClick={handleTabClick}
        activeIndex={activeTabIndex}
        className="shadow-lg"
        tabBorder
      />
    </div>
  );
};

export default TimesheetOverview;

import moment from "moment-timezone";
import updateCalendarDay from "./updateCalendarDay";

const handleAddMultiWeekItem = ({
  event,
  isFullDayEvent,
  startDate,
  endDate,
  userEvents,
  userEventUpdates,
  calendarTimezone,
}) => {
  const newUserEventsDict = { ...userEvents };

  if (calendarTimezone) {
    // determine how many weeks the item spans
    const weeksSpanned = moment
      .tz(endDate, calendarTimezone)
      .diff(moment.tz(startDate, calendarTimezone).startOf("week"), "weeks");
    for (let i = 1; i <= weeksSpanned; i += 1) {
      const startOfNextWeek = moment
        .tz(startDate, calendarTimezone)
        .add(i, "weeks")
        .startOf("week")
        .format();

      const calendarDayForNextWeek = userEvents[startOfNextWeek] ||
        userEventUpdates[startOfNextWeek] || {
          allDay: [],
          brief: [],
          allDayCount: 0,
        };

      // get the end of the week from startOftheWeek and save it as a string
      const endOfNextWeek = moment
        .tz(startOfNextWeek, calendarTimezone)
        .endOf("week")
        .format();

      // calculate difference between end of next week and event end date
      const difference = moment(
        moment.tz(endOfNextWeek, calendarTimezone).format("YYYY-MM-DD")
      )
        .clone()
        .startOf("day")
        .diff(
          moment(
            moment.tz(event.endDate, calendarTimezone).format("YYYY-MM-DD")
          )
            .clone()
            .startOf("day"),
          "days"
        );

      // if the difference is greater than 7, set the day span to 7
      // otherwise set the day span to 7 - difference
      const updatedDaySpan = 7 - difference > 7 ? 7 : 7 - difference;

      // create a new event with the updated day span and start date
      const updatedEvent = {
        ...event,
        daySpan: updatedDaySpan,
        wrappedDayBeginning: event.startDate,
      };

      // update the calendar day for the next week
      const updatedCalendarDayForNextWeek = updateCalendarDay(
        calendarDayForNextWeek,
        updatedEvent,
        isFullDayEvent
      );

      newUserEventsDict[startOfNextWeek] = updatedCalendarDayForNextWeek;
    }
  } else {
    // determine how many weeks the item spans
    const weeksSpanned = moment(endDate).diff(
      moment(startDate).startOf("week"),
      "weeks"
    );

    for (let i = 1; i <= weeksSpanned; i += 1) {
      const startOfNextWeek = moment(startDate)
        .add(i, "weeks")
        .startOf("week")
        .format();

      const calendarDayForNextWeek = userEvents[startOfNextWeek] ||
        userEventUpdates[startOfNextWeek] || {
          allDay: [],
          brief: [],
          allDayCount: 0,
        };

      // get the end of the week from startOftheWeek and save it as a string
      const endOfNextWeek = moment(startOfNextWeek).endOf("week").format();
      // calculate difference between end of next week and event end date
      const difference = moment(endOfNextWeek).diff(
        moment(event.endDate),
        "days"
      );

      // if the difference is greater than 7, set the day span to 7
      // otherwise set the day span to 7 - difference
      const updatedDaySpan = 7 - difference > 7 ? 7 : 7 - difference - 1;
      // create a new event with the updated day span and start date
      const updatedEvent = {
        ...event,
        daySpan: updatedDaySpan,
        wrappedDayBeginning: event.startDate,
      };

      // update the calendar day for the next week
      const updatedCalendarDayForNextWeek = updateCalendarDay(
        calendarDayForNextWeek,
        updatedEvent,
        isFullDayEvent
      );

      newUserEventsDict[startOfNextWeek] = updatedCalendarDayForNextWeek;
    }
  }

  return newUserEventsDict;
};

export default handleAddMultiWeekItem;

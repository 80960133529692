import dateContainedInDates from "./dateContainedInDates";

/**
 * Determines whether the edit buttons should be hidden based on the item's status and dates.
 *
 * @param {Object} item - The item to check. It should have a 'status' property and a 'dates' array.
 * @param {Array} [forbiddenDates=[]] - An array of dates during which editing is not allowed.
 * @returns {boolean} - Returns true if the edit buttons should be hidden, false otherwise.
 */
const hideEditButtons = (item, forbiddenDates = []) => {
  const immediateFalseCriteria =
    !item?.status || !item?.dates || !Array.isArray(item?.dates);

  if (immediateFalseCriteria) return false;

  if (["cancelled", "declined"]?.includes(item?.status)) return true;

  for (let i = 0; i < item?.dates.length; i += 1) {
    const { date } = item?.dates[i];

    if (dateContainedInDates(date, forbiddenDates)) return true;
  }

  return false;
};

export default hideEditButtons;

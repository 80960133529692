import React from "react";

import FinancesInformation from "../../../stories/Components/Finances/FinancesInformation";

const AssetFinances = ({ editedAsset, dispatch, editing }) => {
  return (
    <FinancesInformation
      resourceName="Asset"
      editedObject={editedAsset}
      editing={editing}
      dispatch={dispatch}
    />
  );
};

export default AssetFinances;

import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";

const ReactSelectAsyncPaginate = ({ handleEnter, ...props }, ref) => {
  return <AsyncPaginate ref={ref} onEnter={handleEnter} {...props} />;
};

ReactSelectAsyncPaginate.propTypes = {
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  handleEnter: PropTypes.func,
};

ReactSelectAsyncPaginate.defaultProps = {
  forwardedRef: undefined,
  handleEnter: undefined,
};

export default forwardRef(ReactSelectAsyncPaginate);

import { useReducer } from "react";
import { cloneDeep } from "lodash";
import {
  RESET,
  SIMPLE_INPUT,
  NESTED_INPUT,
  LIST_ADD_ONE,
  LIST_ADD_MANY,
  LIST_REMOVE_ONE,
  UPDATE_BUDGET_LINE,
  REMOVE_BUDGET_LINE,
  DOCUMENT_ID,
  DOCUMENT_TYPE,
  LINE_ITEMS,
  NUM_DAYS,
  DAY_TYPE,
  SCOPE_SUMMARY,
  FINANCIAL_CODE,
  REQUEST,
  SET_FILE_NAME,
  ADD_LINE_ITEMS,
  REMOVE_LINE_ITEMS,
  UPDATE_LINE_ITEM,
  ADD_NEW_LINE_ITEMS,
  REMOVE_NEW_LINE_ITEMS,
  UPDATE_NEW_LINE_ITEM,
  ADD_DOCUMENT_ATTACHMENT,
  ADD_DOCUMENT_ATTACHMENTS,
  REMOVE_DOCUMENT_ATTACHMENT,
  UPDATE_DOCUMENT_ATTACHMENTS,
  ADD_QUALIFICATION,
  ADD_QUALIFICATIONS,
  REMOVE_QUALIFICATION,
  UPDATE_QUALIFICATION,
  ADD_DEVIATION,
  ADD_DEVIATIONS,
  UPDATE_DEVIATION,
  REMOVE_DEVIATION,
  REASON_FOR_CHANGE,
  BID_TYPE,
  VENDOR,
  ROADBLOCKS,
  ADD_SUBMISSION_REQUIREMENT,
  UPDATE_SUBMISSION_REQUIREMENT,
  ADD_ADDITIONAL_REQUIREMENT,
  ADD_ADDITIONAL_REQUIREMENTS,
  REMOVE_ADDITIONAL_REQUIREMENT,
  DELIVERY_DETAILS,
  ADJUSTMENT_SETTINGS,
  DOCUMENT_PROJECT,
  DOCUMENT_PROPERTY,
  DOCUMENT_STATUS,
  DOCUMENT_ASSOCIATION,
  ADD_ADDITIONAL_DOCUMENT,
  ADD_ADDITIONAL_DOCUMENTS,
  REMOVE_ADDITIONAL_DOCUMENT,
  UPDATE_ADDITIONAL_DOCUMENTS,
  UPDATE_BUDGET_STATE,
  UPDATE_FORM_DATA,
  REMOVE_FORM_DATA,
} from "../constants";

const defaultState = {
  // Existing API Fields
  id: null,
  metadata: null,
  resource: null,
  reference: null,
  version: null,
  isArchived: null,
  status: "",
  createdBy: null,
  project: null,
  company: null,
  property: null,
  budget: null,
  docType: null,
  docFormat: null,
  modifiedBy: null,
  modifiedDate: null,
  name: null,
  summary: "",
  lineItems: [],
  workingLineItems: [],
  additionalDocuments: [],
  attachments: [],
  files: [],
  qualificationsAndExclusions: [],
  // scheduleImpacts: [],
  vendor: null,
  submissionReqs: [],
  meetingDate: null,
  facilitator: "",
  distro: [],
  tasks: null,
  decisions: "",
  rates: [],
  versionNumber: null,
  // End Existing API Fields

  csiCode: null,
  request: "",
  reasonForChange: "",
  roadblocks: "",
  deliveryDetails: "",
  additionalRequirements: [],
  bidType: null,
  creator: null,
  modifier: null,

  adjustments: {},
  state: {},
  dueDate: {
    allDay: true,
  },
};

const reducer = (state, action) => {
  const resource = { ...state };
  let temp;
  let tempList;
  let tempObj;
  let idx;
  let newValue;

  switch (action.type) {
    case RESET: {
      return action.value
        ? cloneDeep({ ...defaultState, ...action.value })
        : defaultState;
    }
    case SIMPLE_INPUT:
      return {
        ...resource,
        [action.key]: action.value,
      };
    case NESTED_INPUT:
      return {
        ...resource,
        [action.key1]: {
          ...(resource[action.key1] ?? {}),
          [action.key2]: action.value,
        },
      };
    case LIST_ADD_ONE:
      idx = resource[action.key]?.findIndex((item) => {
        return item.id === action.value.id;
      });
      tempList = action.append
        ? [...(resource[action.key] ?? []), action.value]
        : [action.value, ...(resource[action.key] ?? [])];
      temp = {
        ...resource,
        [action.key]:
          idx > -1
            ? resource[action.key]?.map((item, index) => {
                if (index === idx) {
                  return action.value;
                }
                return item;
              })
            : tempList,
      };

      return temp;
    case LIST_ADD_MANY:
      temp = [];
      tempList = [...(resource[action.key] ?? [])];
      action.value?.forEach((val) => {
        const foundIndex = tempList.findIndex((item) => item.id === val.id);
        if (foundIndex > -1) {
          tempList[foundIndex] = val;
        } else {
          temp.push(val);
        }
      });

      return {
        ...resource,
        [action.key]: [...temp, ...tempList],
      };
    case LIST_REMOVE_ONE:
      return {
        ...resource,
        [action.key]: resource[action.key]?.filter((item) => {
          return item.id !== action.value;
        }),
      };
    case UPDATE_BUDGET_LINE:
      tempObj = { ...(resource.state.lineItemData ?? {}) };
      return {
        ...resource,
        state: {
          ...resource.state,
          lineItemData: {
            ...tempObj,
            [action.key]: { ...(tempObj[action.key] ?? {}), ...action.value },
          },
        },
      };
    case REMOVE_BUDGET_LINE:
      tempObj = { ...(resource.state.lineItemData ?? {}) };
      delete tempObj[action.key];
      return {
        ...resource,
        state: {
          ...resource.state,
          lineItemData: tempObj,
        },
      };
    case UPDATE_FORM_DATA:
      tempObj = { ...(resource.state.formData ?? {}) };
      return {
        ...resource,
        state: {
          ...resource.state,
          formData: {
            ...tempObj,
            [action.key]: { ...(tempObj[action.key] ?? {}), ...action.value },
          },
        },
      };
    case REMOVE_FORM_DATA:
      tempObj = { ...(resource.state.formData ?? {}) };
      delete tempObj[action.key]?.[action.value];
      return {
        ...resource,
        state: {
          ...resource.state,
          formData: tempObj,
        },
      };
    case UPDATE_BUDGET_STATE:
      return {
        ...resource,
        state: action.value,
      };
    case DOCUMENT_ID:
      newValue = { id: action.value };
      break;
    case DOCUMENT_TYPE:
      newValue = { docType: action.value };
      break;
    case LINE_ITEMS:
      newValue = { docType: action.value };
      break;
    case NUM_DAYS:
      newValue = { returnBy: { ...resource.returnBy, numDays: action.value } };
      break;
    case DAY_TYPE:
      newValue = { returnBy: { ...resource.returnBy, dayType: action.value } };
      break;
    case SCOPE_SUMMARY:
      newValue = { summary: action.value };
      break;
    case ADD_QUALIFICATION:
      newValue = {
        qualificationsAndExclusions: [
          ...resource.qualificationsAndExclusions?.filter(
            (qual) => !(qual.id === action.value.id)
          ),
          action.value,
        ],
      };
      break;
    case ADD_QUALIFICATIONS:
      newValue = {
        qualificationsAndExclusions: action.value,
      };
      break;
    case REMOVE_QUALIFICATION:
      newValue = {
        qualificationsAndExclusions: [
          ...resource.qualificationsAndExclusions?.filter(
            (qual) => !(qual.id === action.value.id)
          ),
        ],
      };
      break;
    case UPDATE_QUALIFICATION:
      newValue = {
        qualificationsAndExclusions: [
          ...resource.qualificationsAndExclusions?.map((qual) => {
            if (qual.id === action.value.id) {
              return { ...qual, ...action.value };
            }
            return qual;
          }),
        ],
      };
      break;
    case ADD_DOCUMENT_ATTACHMENT:
      newValue = {
        attachments: [
          ...resource.attachments.filter(
            (doc) => !(doc.id === action.value.id)
          ),
          action.value,
        ],
      };
      break;
    case ADD_DOCUMENT_ATTACHMENTS:
      newValue = {
        attachments: [
          ...resource.attachments.filter(
            (doc) => !action.value.find((att) => att.id === doc.id)
          ),
          ...action.value,
        ],
      };
      break;
    case REMOVE_DOCUMENT_ATTACHMENT:
      newValue = {
        attachments: [
          ...resource.attachments.filter(
            (doc) => !(doc.id === action.value.id)
          ),
        ],
      };
      break;
    case UPDATE_DOCUMENT_ATTACHMENTS:
      newValue = {
        attachments: [...action.value],
      };
      break;
    case ADD_ADDITIONAL_DOCUMENT:
      newValue = {
        additionalDocuments: [
          ...resource.additionalDocuments.filter(
            (doc) => !(doc.id === action.value.id)
          ),
          action.value,
        ],
      };
      break;
    case ADD_ADDITIONAL_DOCUMENTS:
      newValue = {
        additionalDocuments: [
          ...resource.additionalDocuments.filter(
            (doc) => !action.value.find((att) => att.id === doc.id)
          ),
          ...action.value,
        ],
      };
      break;
    case REMOVE_ADDITIONAL_DOCUMENT:
      newValue = {
        additionalDocuments: [
          ...resource.additionalDocuments.filter(
            (doc) => !(doc.id === action.value.id)
          ),
        ],
      };
      break;
    case UPDATE_ADDITIONAL_DOCUMENTS:
      newValue = {
        additionalDocuments: [...action.value],
      };
      break;
    case FINANCIAL_CODE:
      newValue = { csiCode: action.value };
      break;
    case REQUEST:
      newValue = { request: action.value };
      break;
    case SET_FILE_NAME:
      newValue = { name: action.value };
      break;
    case ADD_LINE_ITEMS:
      newValue = {
        workingLineItems: [
          ...(resource.workingLineItems ?? []),
          ...action.value.filter(
            (newItem) =>
              !resource.workingLineItems?.find(
                (oldItem) => oldItem.id === newItem.id
              )
          ),
        ],
      };
      break;
    case REMOVE_LINE_ITEMS:
      newValue = {
        workingLineItems: [
          ...resource.workingLineItems?.filter(
            (item) => !action.value.find((removed) => removed.id === item.id)
          ),
        ],
      };
      break;
    case UPDATE_LINE_ITEM:
      temp = [...(resource.workingLineItems ?? [])];
      idx = temp.findIndex((item) => item.id === action.value.id);
      temp.splice(idx, 1, action.value);
      newValue = {
        workingLineItems: temp,
      };
      break;
    case ADD_NEW_LINE_ITEMS:
      newValue = {
        newLineItems: [
          ...(resource.newLineItems ?? []),
          ...action.value.filter(
            (newItem) =>
              !resource.newLineItems?.find(
                (oldItem) => oldItem.id === newItem.id
              )
          ),
        ],
      };
      break;
    case REMOVE_NEW_LINE_ITEMS:
      newValue = {
        newLineItems: [
          ...resource.newLineItems?.filter(
            (item) => !action.value.find((removed) => removed.id === item.id)
          ),
        ],
      };
      break;
    case UPDATE_NEW_LINE_ITEM:
      temp = [...(resource.newLineItems ?? [])];
      idx = temp.findIndex((item) => item.id === action.value.id);
      temp.splice(idx, 1, action.value);
      newValue = {
        newLineItems: temp,
      };
      break;
    case ADD_DEVIATION:
      newValue = {
        deviations: [
          ...resource.deviations.filter((dev) => !(dev.id === action.value.id)),
          action.value,
        ],
      };
      break;
    case ADD_DEVIATIONS:
      newValue = {
        deviations: action.value,
      };
      break;
    case UPDATE_DEVIATION:
      newValue = {
        deviations: resource.deviations.map((dev) => {
          if (dev.id === action.value.id)
            return { ...dev, checked: action.value.checked };
          return { ...dev };
        }),
      };
      break;
    case REMOVE_DEVIATION:
      newValue = {
        deviations: [
          ...resource.deviations.filter((dev) => !(dev.id === action.value.id)),
        ],
      };
      break;
    case REASON_FOR_CHANGE:
      newValue = { summary: action.value };
      // newValue = { reasonForChange: action.value };
      break;
    case BID_TYPE:
      newValue = { bidType: action.value };
      break;
    case VENDOR:
      newValue = { vendor: action.value };
      break;
    case ROADBLOCKS:
      newValue = { roadblocks: action.value };
      break;
    case ADD_SUBMISSION_REQUIREMENT:
      newValue = {
        submissionReqs: [
          ...resource.submissionReqs.filter(
            (req) => !(req.id === action.value.id)
          ),
          action.value,
        ],
      };
      break;
    case UPDATE_SUBMISSION_REQUIREMENT:
      temp = resource.submissionReqs.find((req) => req.id);
      newValue = {
        submissionReqs: [
          ...resource.submissionReqs.map((req) => {
            if (req.id === action.value.id) {
              return { ...req, ...action.value };
            }
            return req;
          }),
        ],
      };
      break;
    case ADD_ADDITIONAL_REQUIREMENT:
      newValue = {
        additionalRequirements: [
          ...resource.additionalRequirements.filter(
            (req) => !(req.id === action.value.id)
          ),
          action.value,
        ],
      };
      break;
    case ADD_ADDITIONAL_REQUIREMENTS:
      newValue = {
        additionalRequirements: action.value,
      };
      break;
    case REMOVE_ADDITIONAL_REQUIREMENT:
      newValue = {
        additionalRequirements: [
          ...resource.additionalRequirements.filter(
            (req) => !(req.id === action.value.id)
          ),
        ],
      };
      break;
    case DELIVERY_DETAILS:
      newValue = { deliveryDetails: action.value };
      break;
    case ADJUSTMENT_SETTINGS:
      newValue = { adjustments: action.value };
      break;
    case DOCUMENT_PROJECT:
      newValue = { project: action.value };
      break;
    case DOCUMENT_PROPERTY:
      newValue = { property: action.value };
      break;
    case DOCUMENT_ASSOCIATION:
      newValue = {
        [action.value?.split("/")[0]?.toLowerCase() ?? "association"]:
          action.value,
      };
      break;
    case DOCUMENT_STATUS:
      newValue = { status: action.value };
      break;
    default:
      break;
  }
  return { ...resource, ...newValue };
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};

import { useCallback, useEffect, useState } from "react";
import SignUpAPI from "../Pages/SignUp/SignUpAPI";

const useSubscriptionPlans = () => {
  const [plans, setPlans] = useState([]);

  const reload = useCallback(async () => {
    const stripePlans = await SignUpAPI.handleFetchStripeProducts();
    setPlans(stripePlans);
  }, []);

  useEffect(() => {
    reload();
  }, [reload]);

  return [plans, reload];
};

export default useSubscriptionPlans;

import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import Input from "../Input/Input";
import PrimaryButton from "../Buttons/PrimaryButton";
import Widget from "../Widget/Widget";

const requirementsCN = cntl`
flex 
flex-col 
w-1/2 
my-4
`;

const ProjectQualifications = ({ project, isEditing, dispatch }) => {
  const qualificationsAndExclusions = project?.qualificationsAndExclusions;

  const onChange = (value, i) => {
    qualificationsAndExclusions[i] = value;
    dispatch({
      type: "edit",
      key: "qualificationsAndExclusions",
      value: qualificationsAndExclusions,
    });
  };

  const handleRemove = (index) => {
    const newQualificationsAndExclusions = [...qualificationsAndExclusions];
    newQualificationsAndExclusions.splice(index, 1);
    dispatch({
      type: "edit",
      key: "qualificationsAndExclusions",
      value: newQualificationsAndExclusions,
    });
  };

  const handleAddRequirement = () => {
    dispatch({
      type: "edit",
      key: "qualificationsAndExclusions",
      value: [...project?.qualificationsAndExclusions, undefined],
    });
  };

  return (
    <Widget
      draggable={false}
      title="Qualifications &amp; Exclusions"
      overflow
      className="mb-4"
    >
      <div className="flex flex-row">
        <div className="flex mt-4 w-1/2">
          <p className="font-bold mx-4 mb-4">Requirements</p>
        </div>
        <div className={requirementsCN}>
          {qualificationsAndExclusions?.map((item, i) => {
            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`${i}-row`}
                className={`flex items-center ${
                  !isEditing ? "justify-start" : ""
                }`}
              >
                {isEditing && (
                  <Input
                    className="mb-4"
                    onChange={(val) => onChange(val, i)}
                    value={qualificationsAndExclusions[i]}
                    placeholder="Requirement"
                    onRemove={() => handleRemove(i)}
                    autoFocus
                    disableClear
                  />
                )}
                {!isEditing && item && (
                  <p className="mb-4 text-gray-200">{item}</p>
                )}
              </div>
            );
          })}
          {isEditing && (
            <div className="flex w-full justify-end mt-4">
              <PrimaryButton title="+ Add" onClick={handleAddRequirement} />
            </div>
          )}
        </div>
      </div>
    </Widget>
  );
};

ProjectQualifications.propTypes = {
  project: PropTypes.shape({
    qualificationsAndExclusions: PropTypes.arrayOf(PropTypes.string),
  }),
  isEditing: PropTypes.bool,
  dispatch: PropTypes.func,
};

ProjectQualifications.defaultProps = {
  project: undefined,
  isEditing: PropTypes.bool,
  dispatch: undefined,
};

export default ProjectQualifications;

import React from "react";
import PropTypes from "prop-types";
import { CALENDAR_STROKE } from "../../../../constants";

const CalendarHeader = ({ values, height, width }) => {
  const cellHeight = height % 35;
  const cellWidth = width / values.length;
  return (
    <>
      <defs>
        <clipPath id="round-corner">
          <rect
            x="-0.1"
            y="0"
            width={width + 0.2}
            height={cellHeight + 5}
            rx="3"
          />
        </clipPath>
      </defs>
      <rect
        x="-0.1"
        y="0"
        width={width + 0.2}
        height={cellHeight + 5}
        fill="#f9f9f9"
        rx="3"
        clipPath="url(#round-corner)"
        stroke={CALENDAR_STROKE}
        strokeWidth="0.4"
      />
      <g id="header-cells" clipPath="url(#round-corner)">
        {Array.from({ length: values.length }, (_, i) => (
          <g id={`${values[i]}-cell`} key={`${values[i]}-cell`}>
            <rect
              x={i * cellWidth}
              y="0"
              width={cellWidth}
              height={cellHeight}
              fill="none"
            />
            <text
              x={i * cellWidth + cellWidth / 2}
              y={cellHeight * 0.7}
              fontSize={cellHeight * 0.4}
              textAnchor="middle"
            >
              {values[i]}
            </text>
          </g>
        ))}
      </g>
    </>
  );
};

CalendarHeader.propTypes = {
  // Days of the week array
  values: PropTypes.arrayOf(PropTypes.string),
  // height of the calendar for porpotioning
  height: PropTypes.number,
  // width of the calendar for porpotioning
  width: PropTypes.number,
};

CalendarHeader.defaultProps = {
  values: [],
  height: undefined,
  width: undefined,
};

export default CalendarHeader;

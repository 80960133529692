import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import { v4 as uuidv4 } from "uuid";

import useSystemConfiguration from "../../../hooks/useSystemConfiguration";

import SovRow from "./SovRow";

import CurrencyView from "../CurrencyView/CurrrencyView";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import PrimaryButton from "../Buttons/PrimaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import {
  BUDGET_FIXED_FIRM,
  CHANGE_ORDER,
  CONTINGENCY_AUTHORIZATION,
  PURCHASE_AUTHORIZATION,
} from "../../../constants";

const containerCN = cntl`
  flex
`;
const titleCN = cntl`
  flex
  items-center
  p-2
  border-b-2
  border-b-grey-150 
  font-semibold
`;
const contentCN = cntl`
  flex
  flex-col
  px-2
  cursor-pointer
`;
const tableHeaderCN = cntl`
  flex
  flex-row
  items-center
  h-16
  py-2
  text-xs
  text-gray-300
  font-bold
  border-b
  border-b-gray-300
`;
const tableCellCN = (className) => cntl`
  flex-none
  items-center
  mr-4
  ${className}
`;

const ScheduleOfValues = ({
  row,
  onSaveSov,
  onTogglePopover,
  budget,
  parentDocType,
  isVendorInvoice,
  disbaleEditing,
  isInvoice,
}) => {
  const { data: systemConfiguration } = useSystemConfiguration();

  const [groupedUnitsOfMeasure, setGroupedUnitsOfMeasure] = useState([]);
  const [flatUnitsOfMeasure, setFlatUnitsOfMeasure] = useState([]);
  const [lineItems, setLineItems] = useState(row.sov?.length ? row.sov : null);
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [hasVendorInvoiceError, setHasVendorInvoiceError] = useState(false);
  const [newRows, setNewRows] = useState([]);
  const [edited, setEdited] = useState();

  useEffect(() => {
    if (!lineItems) {
      const newId = uuidv4();
      setLineItems([{ id: newId }]);
      setNewRows((prev) => [...prev, newId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setHasVendorInvoiceError(lineItems?.some((li) => !!li.maxValueError));
  }, [lineItems]);

  useEffect(() => {
    const spent = lineItems?.reduce((sum, item) => {
      return sum + (item.quantity || 0) * (item.costPerUnit || 0);
    }, 0);

    const { budgetType, isLockFixedFirm } = budget ?? {};
    const fixedFirmLocked = budgetType === BUDGET_FIXED_FIRM && isLockFixedFirm;

    let remainingAmount = 0;
    if (fixedFirmLocked) {
      remainingAmount = (Number(row.poTotalPending) || 0) - spent;
    } else {
      switch (parentDocType) {
        case PURCHASE_AUTHORIZATION:
          remainingAmount = (Number(row.paTotal) || 0) - spent;
          break;
        case CONTINGENCY_AUTHORIZATION:
          remainingAmount = (Number(row.caTotal) || 0) - spent;
          break;
        case CHANGE_ORDER:
          remainingAmount = (Number(row.coTotal) || 0) - spent;
          break;
        default:
          remainingAmount =
            (Number(row.paTotal) || 0) +
            (Number(row.caTotal) || 0) +
            (Number(row.coTotal) || 0) -
            spent;
      }
    }
    setRemainingBalance(remainingAmount);
  }, [
    row.paTotal,
    row.caTotal,
    row.coTotal,
    lineItems,
    budget,
    parentDocType,
    row.poTotalPending,
  ]);

  useEffect(() => {
    if (systemConfiguration?.system?.unitsOfMeasure) {
      const lengthOptions =
        systemConfiguration?.system?.unitsOfMeasure?.length_area
          ?.filter((uom) => uom.selected)
          ?.map((uom) => ({ label: uom.display, value: uom.id }));
      const qtyOptions = systemConfiguration?.system?.unitsOfMeasure?.quantity
        ?.filter((uom) => uom.selected)
        ?.map((uom) => ({ label: uom.display, value: uom.id }));
      const timeOptions = systemConfiguration?.system?.unitsOfMeasure?.time
        ?.filter((uom) => uom.selected)
        ?.map((uom) => ({ label: uom.display, value: uom.id }));
      const volumeOptions =
        systemConfiguration?.system?.unitsOfMeasure?.volume_weight
          ?.filter((uom) => uom.selected)
          ?.map((uom) => ({ label: uom.display, value: uom.id }));

      const groupedOptions = [
        {
          label: "Length/Area",
          options: lengthOptions,
        },
        {
          label: "Quantity",
          options: qtyOptions,
        },
        {
          label: "Time",
          options: timeOptions,
        },
        {
          label: "Volume/Weight",
          options: volumeOptions,
        },
      ];

      setFlatUnitsOfMeasure([
        ...lengthOptions,
        ...qtyOptions,
        ...timeOptions,
        ...volumeOptions,
      ]);
      setGroupedUnitsOfMeasure(groupedOptions);
    }
  }, [systemConfiguration]);

  const handleAddRow = () => {
    const newId = uuidv4();
    setLineItems((prev) => {
      return [...prev, { id: newId }];
    });
    setNewRows((prev) => [...prev, newId]);
  };

  const handleRemoveRow = (id) => {
    setLineItems((prev) => {
      return prev.filter((item) => item.id !== id);
    });

    setEdited((prev) => {
      const result = prev === true ? prev : !newRows.includes(id);
      return result;
    });
  };

  const handleDataInput = ({ id, type, value }) => {
    setLineItems((prev) => {
      return prev.map((line) => {
        if (line.id === id) {
          return { ...line, [type]: value };
        }
        return line;
      });
    });
    setEdited(true);
  };

  const handleSaveSov = () => {
    if (isInvoice) {
      onSaveSov(row.id, lineItems, row.parentVendorInvoice);
    } else {
      onSaveSov(row.id, lineItems);
    }
    setEdited(false);
  };

  const isSaveDisabled = useCallback(() => {
    if (!edited) {
      return true;
    }

    if (isVendorInvoice && hasVendorInvoiceError) {
      return true;
    }

    if (!isVendorInvoice && remainingBalance !== 0) {
      return true;
    }

    return false;
  }, [edited, hasVendorInvoiceError, isVendorInvoice, remainingBalance]);

  return (
    <div className={containerCN} style={{ minWidth: "1200px" }}>
      <div className="w-full">
        <div className={titleCN}>
          <SecondaryHeader className="">Schedule of Values</SecondaryHeader>
        </div>

        <div className={contentCN}>
          <div className={tableHeaderCN}>
            {isVendorInvoice && <div className={tableCellCN("w-64")}>PO</div>}
            <div className={tableCellCN("w-64")}>Description</div>
            <div className={tableCellCN("w-24")}>QTY</div>
            <div className={tableCellCN("w-40")}>UOM</div>
            <div className={tableCellCN("w-24")}>Cost Per</div>
            <div className={tableCellCN("w-24")}>Total</div>
            {isVendorInvoice && (
              <>
                <div className={tableCellCN("w-64 mr-8 flex-col")}>
                  <div className={tableCellCN("mb-1")}>
                    Work Completed this Period
                  </div>
                  <div className={tableCellCN("flex")}>
                    <div className="w-32 mr-4">Dollar Amt.</div>
                    <div className="">Percent Amt.</div>
                  </div>
                </div>
                <div className={tableCellCN("w-32")}>
                  Work Completed to Date
                </div>
                <div className={tableCellCN("w-32")}>
                  % Complete of total values
                </div>
              </>
            )}
          </div>
          {lineItems?.map((data) => {
            return (
              <div key={data.id}>
                <SovRow
                  data={data}
                  units={flatUnitsOfMeasure}
                  groupedUnits={groupedUnitsOfMeasure}
                  dispatch={handleDataInput}
                  onRemove={handleRemoveRow}
                  disableEditing={disbaleEditing || isVendorInvoice}
                  isVendorInvoice={isVendorInvoice}
                />
              </div>
            );
          })}
          {!disbaleEditing && !isVendorInvoice && (
            <div className="flex justify-between items-center">
              <TertiaryButton
                className="mt-4"
                title="+ Add Line"
                onClick={handleAddRow}
              />
              <div className="flex items-center pt-4 test-sm font-semibold">
                <div className="mr-4 text-xs font-bold">Remaining Balance:</div>
                <CurrencyView value={remainingBalance} />
              </div>
            </div>
          )}
          {!disbaleEditing && (
            <div className="flex justify-end mt-6">
              <TertiaryButton
                className="mr-4"
                title="Cancel"
                onClick={onTogglePopover}
              />
              <PrimaryButton
                className=""
                title="Save"
                onClick={handleSaveSov}
                disabled={isSaveDisabled()}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ScheduleOfValues.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object,
  budget: PropTypes.shape({}),
  onSaveSov: PropTypes.func,
  onTogglePopover: PropTypes.func,
  parentDocType: PropTypes.string,
  isVendorInvoice: PropTypes.bool,
  disbaleEditing: PropTypes.bool,
  isInvoice: PropTypes.bool,
};

ScheduleOfValues.defaultProps = {
  row: {},
  onSaveSov: () => {},
  onTogglePopover: () => {},
  budget: undefined,
  parentDocType: undefined,
  isVendorInvoice: false,
  disbaleEditing: false,
  isInvoice: false,
};

export default ScheduleOfValues;

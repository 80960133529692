import { PermissionAPI, UserAPI } from "@griffingroupglobal/eslib-api";
import { useAppState } from "../state/appState";
import { SET_CURRENT_USER } from "../constants";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { userKeys } from "../config/reactQuery/queryKeyFactory";

/**
 * Give the data for a User, decorate that data with a `permissions` property
 * containing that user's permissions.
 */
async function decorateWithUserPermissions(userData) {
  return PermissionAPI.getWOP(`user/${userData.id}`).then(
    (permissionsResponse) => {
      // eslint-disable-next-line no-param-reassign
      userData.permissions = permissionsResponse.data;
      // eslint-disable-next-line no-param-reassign
      userData.hasPermission = (resource, permission) =>
        userData.permissions[resource] &&
        userData.permissions[resource][permission];

      return userData;
    }
  );
}

const fetchCurrentUser = async () => {
  const { data } = await UserAPI.getWOP("$currentuser");

  const res = await decorateWithUserPermissions(data);
  return res;
};

/**
 * Query Hook to fetch the currently authenticated user.
 */
function useCurrentUser() {
  const [, dispatch] = useAppState();

  return useAuthenticatedQuery({
    queryKey: userKeys.currentUser(),
    queryFn: fetchCurrentUser,
    placeholderData: {},
    onSuccess: (data) => {
      dispatch({
        type: SET_CURRENT_USER,
        currentUser: data,
      });
    },
  });
}

export default useCurrentUser;

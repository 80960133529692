import { useCallback, useMemo } from "react";
import { TimeoffAPI } from "@griffingroupglobal/eslib-api";
import { useAppState } from "../../../../state/appState";
import useTimesheetList from "../../../../hooks/timesheets/useTimesheetList";
import combineTsWithUser from "../../../../helpers/Timesheets/combineTsWithUser";

const usePayrollData = () => {
  const [{ userDict }] = useAppState();
  const { timesheetsList, timesheetsLoading } = useTimesheetList();

  /* NERD: Normally I wouldnt want to format at this scope
   *   but it was like this before, and the child components
   *   were built to handle it the way */
  const timeSheets = useMemo(
    () => combineTsWithUser(timesheetsList, userDict),
    [timesheetsList, userDict]
  );

  const getPTO = useCallback(async () => {
    const queries = timeSheets?.map(
      (item) => () =>
        TimeoffAPI.getWOP(`?user=${item?.userRef}&status=approved`)
    );
    return queries ? Promise.all(queries.map((query) => query())) : [];
  }, [timeSheets]);

  return { timesheetsLoading, timeSheets, getPTO };
};

export default usePayrollData;

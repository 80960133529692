import getSortCategory from "./getSortCategory";

/**
 * Sort an array of contacts objects base on name object property(name: {firstName, lastName}), or label property.
 * @param {Object[]} arr - The array of contact objects to be sorted.
 * @returns {Object[]} The sorted array of contact objects.
 */
const sortContactsAndMembers = (arr = []) => {
  return arr.sort((a, b) => {
    const valueA =
      a?.name?.firstName || a?.name?.lastName
        ? `${a?.name?.firstName} ${a?.name?.lastName}`.trim().toLowerCase() // a.kind is member
        : a?.label?.trim().toLowerCase() || ""; // a.kind is company
    const valueB =
      b?.name?.firstName || b?.name?.lastName
        ? `${b?.name?.firstName} ${b?.name?.lastName}`.trim().toLowerCase() // b.kind is member
        : b?.label?.trim().toLowerCase() || ""; // b.kind is company

    const categoryA = getSortCategory(valueA);
    const categoryB = getSortCategory(valueB);

    if (categoryA === categoryB) {
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
      return 0;
    }

    return categoryA - categoryB;
  });
};

export default sortContactsAndMembers;

import { useQueryClient } from "react-query";
import { LOGOUT_USER, SET_IS_SIGNED_IN } from "../constants";
import { useAppState } from "../state/appState";
import { clearLocalStorage } from "./authentication/index";

export default () => {
  const [{ isSignedIn }, dispatch] = useAppState();
  const queryClient = useQueryClient();

  const setIsSignedIn = (loginStatus) => {
    dispatch({
      type: SET_IS_SIGNED_IN,
      isSignedIn: loginStatus,
    });

    if (!loginStatus) {
      // Clean react query cache
      queryClient.clear();

      dispatch({
        type: LOGOUT_USER,
      });

      clearLocalStorage();
    }
  };

  return [isSignedIn, setIsSignedIn];
};

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Summary from "../Summary/Summary";
import { VENDOR_INVOICE } from "../../../constants";
import {
  formatSelectUser,
  formatWithCommasWithoutDecimal,
} from "../../../helpers/Formatters";

const DocumentSummary = ({
  document,
  selectedBudgetRows,
  documentsDict,
  csiCodes,
  userList,
}) => {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (userList?.length) {
      setUserData(() => {
        return userList?.map((usr) => {
          return formatSelectUser({
            ...usr,
            role: usr.roleName,
          });
        });
      });
    }
  }, [userList]);

  const docBudgetLines = React.useMemo(() => {
    return (
      document?.workingLineItems?.map((item) => {
        const lineData = document.state?.lineItemData?.[item?.id] ?? {};
        const sov = document.state?.sov ?? {};
        const sovData = sov[item?.id] ?? [];
        const lineitemToVendorInvoiceInfo =
          document.state?.lineitemToVendorInvoiceInfo?.[item.reference];

        const result = {
          ...item,
          csiCode:
            csiCodes.find(
              (code) => code.value === item?.csiCode?.split(" -")[0]
            )?.label ?? "",
          ...lineData,
          sov: sovData,
          // VI specific ...
          parentPurchaseOrder: documentsDict[lineitemToVendorInvoiceInfo?.po],
          committedValues: lineitemToVendorInvoiceInfo?.committedValues,
          workCompletedThisPeriod:
            lineitemToVendorInvoiceInfo?.workCompletedThisPeriod,
          workCompletedPreviousPeriod:
            lineitemToVendorInvoiceInfo?.workCompletedPreviousPeriod,
          workCompletedToDate: lineitemToVendorInvoiceInfo?.workCompletedToDate,
          percentCompleteOfCommittedValues:
            lineitemToVendorInvoiceInfo?.percentCompleteOfCommittedValues,
          retainage: lineitemToVendorInvoiceInfo?.retainage,
          holdback: lineitemToVendorInvoiceInfo?.holdback,
          workCompletedToDateLessRetainageAndHoldback:
            lineitemToVendorInvoiceInfo?.workCompletedToDateLessRetainageAndHoldback,
          previousPaymentDue: lineitemToVendorInvoiceInfo?.previousPaymentDue,
          currentPaymentDue: lineitemToVendorInvoiceInfo?.currentPaymentDue,
          balanceToFinish: lineitemToVendorInvoiceInfo?.balanceToFinish,
        };

        result.vendorName = userData.find(
          (user) => user.value === result.vendor
        )?.label;

        return result;
      }) ?? []
    );
  }, [
    csiCodes,
    document.state?.lineItemData,
    document.state?.lineitemToVendorInvoiceInfo,
    document.state?.sov,
    document?.workingLineItems,
    documentsDict,
    userData,
  ]);

  const getSummaryValues = React.useCallback(
    (docType) => {
      const checkedRows = docBudgetLines.filter((item) =>
        selectedBudgetRows?.includes(item.id)
      );

      const committedValues = checkedRows?.reduce((sum, row) => {
        return sum + Number(row.committedValues || 0);
      }, 0);
      const workCompletedToDate = checkedRows?.reduce((sum, row) => {
        return sum + Number(row.workCompletedToDate || 0);
      }, 0);
      const retainage = checkedRows?.reduce((sum, row) => {
        if (Number(row.retainage || 0) === -1) {
          return "N/A";
        }

        if (sum === "N/A") {
          return sum;
        }
        return sum + Number(row.retainage || 0);
      }, 0);
      const holdback = checkedRows?.reduce((sum, row) => {
        return sum + Number(row.holdback || 0);
      }, 0);
      const workCompletedToDateLessRetainageAndHoldback = checkedRows?.reduce(
        (sum, row) => {
          return (
            sum + Number(row.workCompletedToDateLessRetainageAndHoldback || 0)
          );
        },
        0
      );
      const previousPaymentDue = checkedRows?.reduce((sum, row) => {
        return sum + Number(row.previousPaymentDue || 0);
      }, 0);
      const currentPaymentDue = checkedRows?.reduce((sum, row) => {
        return sum + Number(row.currentPaymentDue || 0);
      }, 0);
      const balanceToFinish = checkedRows?.reduce((sum, row) => {
        return sum + Number(row.balanceToFinish || 0);
      }, 0);
      let summaryArray = [];
      switch (docType) {
        case VENDOR_INVOICE: {
          summaryArray = [
            {
              key: "Committed Values",
              value: formatWithCommasWithoutDecimal(committedValues),
              unit: "$",
            },
            {
              key: "Work Completed To Date",
              value: formatWithCommasWithoutDecimal(workCompletedToDate),
              unit: "$",
            },
            {
              key: "Retainage",
              value:
                retainage === "N/A"
                  ? retainage
                  : formatWithCommasWithoutDecimal(retainage),
              unit: "$",
            },
            {
              key: "Holdback",
              value: formatWithCommasWithoutDecimal(holdback),
              unit: "$",
            },
            {
              key: "Work Completed to Date Less Retainage and Holdback",
              value: formatWithCommasWithoutDecimal(
                workCompletedToDateLessRetainageAndHoldback
              ),
              unit: "$",
            },
            {
              key: "Previous payment due",
              value: formatWithCommasWithoutDecimal(previousPaymentDue),
              unit: "$",
            },
            {
              key: "Current Amount Due",
              value: formatWithCommasWithoutDecimal(currentPaymentDue),
              unit: "$",
            },
            {
              key: "Balance to Finish including Holdback and Retainage",
              value: formatWithCommasWithoutDecimal(balanceToFinish),
              unit: "$",
            },
          ];
          break;
        }
        default:
          summaryArray = [];
          break;
      }

      return summaryArray;
    },
    [docBudgetLines, selectedBudgetRows]
  );

  return (
    <Summary
      className="mt-8 mb-12 w-1/2"
      keyClassName="w-3/4"
      title={
        document.docType === VENDOR_INVOICE
          ? "Executive Summary"
          : "Summary Of Cost"
      }
      values={getSummaryValues(document.docType)}
    />
  );
};

DocumentSummary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  csiCodes: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  userList: PropTypes.arrayOf(PropTypes.object),
  documentsDict: PropTypes.shape({}),
  // eslint-disable-next-line react/forbid-prop-types
  selectedBudgetRows: PropTypes.array,
};

DocumentSummary.defaultProps = {
  document: {},
  csiCodes: [],
  userList: [],
  documentsDict: undefined,
  selectedBudgetRows: [],
};

export default DocumentSummary;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { isEqual, cloneDeep } from "lodash";
import { useAppState } from "../../../state/appState";
import { SET_FINANCIALS_ACTUAL } from "../../../constants";
import PureActualFinancialsTable from "./PureActualFinancialsTable";

const ActualFinancialsTable = ({ originalData }) => {
  const [, appDispatch] = useAppState();
  const [data, setData] = useState(originalData);
  const [editCount, setEditCount] = useState(0);

  useEffect(() => {
    if (editCount === 0) {
      setData(cloneDeep(originalData));
    }
  }, [editCount, originalData]);

  const onDeleteRowClick = useCallback(
    async (dataIdToDelete) => {
      setData((prevData) => prevData.filter((b) => b.id !== dataIdToDelete));
      const newDataArray = [...data];
      appDispatch({
        type: SET_FINANCIALS_ACTUAL,
        financialsActual: newDataArray.filter((b) => b.id !== dataIdToDelete),
      });
      // TODO make api call to delete
    },
    [appDispatch, data]
  );

  const onEditSaveCallback = useCallback(
    (row, editedFinancial) => {
      const newActualFnancials = data.map((financial, index) => {
        if (index === row?.index) {
          return {
            ...financial,
            ...editedFinancial,
          };
        }
        return financial;
      });

      appDispatch({
        type: SET_FINANCIALS_ACTUAL,
        financialsActual: newActualFnancials,
      });
    },
    [appDispatch, data]
  );

  const onAddSaveCallback = useCallback(
    (row, newRow) => {
      const newActualFinancials = [...data];
      const newRowWithDefaults = {
        categoryNumber: "",
        categoryName: "",
        subcategoryNumber: "",
        subcategoryName: "",
        grossMargin: "",
        depreciationPercent: "",
        escalatorPercent: "",
        recurring: false,
        description: "",
        notes: "",
        ...newRow,
      };
      newActualFinancials.splice(row?.index, 0, newRowWithDefaults);
      appDispatch({
        type: SET_FINANCIALS_ACTUAL,
        financialsActual: newActualFinancials,
      });
    },
    [appDispatch, data]
  );

  const onEditRowCancelClick = useCallback(
    (row) => {
      const { index: resetIndex } = row;
      setData((prevData) =>
        prevData.map((line, index) => {
          if (index === resetIndex) {
            return originalData[index];
          }
          return line;
        })
      );
    },
    [originalData, setData]
  );

  const onStartRowEditing = useCallback(() => {
    setEditCount((prev) => prev + 1);
  }, [setEditCount]);

  const onEndRowEditing = useCallback(() => {
    setEditCount((prev) => prev - 1);
  }, [setEditCount]);

  const onSaveRowClick = useCallback(
    async (index) => {
      const editedData = data[index];
      const current = originalData[index];
      const newDataArray = [...data];
      newDataArray[index] = editedData;
      appDispatch({
        type: SET_FINANCIALS_ACTUAL,
        financialsActual: newDataArray,
      });
      if (!isEqual(current, editedData)) {
        // TODO make api call here
      }
    },
    [appDispatch, data, originalData]
  );

  return (
    <PureActualFinancialsTable
      name="ActualFinancials"
      data={useMemo(() => data, [data])}
      setData={setData}
      onDeleteRowClick={onDeleteRowClick}
      onAddSaveCallback={onAddSaveCallback}
      onEditSaveCallback={onEditSaveCallback}
      onStartRowEditing={onStartRowEditing}
      onEndRowEditing={onEndRowEditing}
      onEditRowCancelClick={onEditRowCancelClick}
      onSaveRowClick={onSaveRowClick}
    />
  );
};

ActualFinancialsTable.propTypes = {
  originalData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
};

ActualFinancialsTable.defaultProps = {
  originalData: [],
};

export default ActualFinancialsTable;

import React, { useCallback, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import useTodosMarkComplete from "../../../../hooks/useTodosMarkComplete";
import useTodosDelete from "../../../../hooks/useTodosDelete";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import { useModalState } from "../../../../state/modalState";
import {
  ADD_OPEN_MODAL,
  CONFIRM_MODAL,
  TOGGLE_POSITIONED_POPUP,
  VIEW_TODO_MODAL,
} from "../../../../constants";
import TodoDeletePrompt from "../../../../Pages/Home/TodoTray/TodoElement/TodoDeletePrompt";
import useTodosPatch from "../../../../hooks/useTodosPatch";
import useTodoFormReducer from "../../../../Pages/Home/TodoTray/TodoForm/useTodoFormReducer";
import { useAppState } from "../../../../state/appState";

export default ({ item: todo, ...rest }) => {
  const { mutate: completeTodo } = useTodosMarkComplete();
  const { mutate: deleteTodo } = useTodosDelete();
  const { mutate: patchTodo } = useTodosPatch();
  const { data: currentUser } = useCurrentUser();
  const [, modalDispatch] = useModalState();
  const [editedTodo, formDispatch] = useTodoFormReducer(todo);
  const [, popupDispatch] = useAppState();

  const closeAllPopups = useCallback(() => {
    popupDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      popupType: null,
    });
  }, [popupDispatch]);

  const members = useMemo(() => {
    let mems;
    if (todo) {
      mems = todo?.members;
      return mems;
    }

    if (!todo) {
      mems = editedTodo?.members?.filter(
        (item) => item?.user !== currentUser?.reference
      );
    }

    return mems;
  }, [currentUser?.reference, editedTodo?.members, todo]);

  const completed = useMemo(() => {
    if (todo) {
      const me = todo?.members?.find(
        (item) => item?.user === currentUser?.reference
      );
      return me?.checked;
    }
    return null;
  }, [currentUser?.reference, todo]);

  const userCreated = useMemo(() => {
    if (todo) {
      return todo?.metadata?.createdBy === currentUser?.reference;
    }
    return false;
  }, [currentUser?.reference, todo]);

  const handleDeleteTodo = () => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: `delete-todo-${todo.id}` ?? uuidv4() },
      modalData: {
        item: {
          prompt: <TodoDeletePrompt />,
          onConfirm: () => deleteTodo(todo),
          confirm: "Delete",
          cancel: "Cancel",
          title: "Delete To-Do",
        },
      },
      modalType: CONFIRM_MODAL,
    });
  };

  const handlePatch = useCallback(
    (newTodo) => {
      const lastUpdated = moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ");

      patchTodo([
        {
          ...newTodo,
          metadata: {
            ...newTodo?.metadata,
            lastUpdated,
          },
        },
        {
          ...todo,
          metadata: {
            ...todo?.metadata,
            lastUpdated,
          },
        },
      ]);
    },
    [patchTodo, todo]
  );

  const handleSave = useCallback(() => {
    if (todo) {
      handlePatch(editedTodo);
    }
    if (rest?.onFinished) rest?.onFinished(editedTodo);
    closeAllPopups();
  }, [closeAllPopups, editedTodo, handlePatch, rest, todo]);

  /**
   * Close all modals and open todo modal in Edit mode
   */
  const handleEditTodo = () => {
    closeAllPopups();
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: todo.id },
      modalData: { item: todo, edit: true },
      modalType: VIEW_TODO_MODAL,
    });
  };

  return {
    todo,
    members,
    completeTodo,
    deleteTodo: handleDeleteTodo,
    completed,
    userCreated,
    handlePatch,
    editedTodo,
    formDispatch,
    handleSave,
    closeAllPopups,
    handleEditTodo,
  };
};

import React from "react";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import DocumentItem from "../DocumentItem/DocumentItem";
import PlusIcon from "../../assets/images/plusIcon.svg";

const coporateDocuments = [
  {
    title: "Article of Organization",
    content:
      "Rate sheet is used to accurately compute the cost of goods and services when creating a budget",
  },
  {
    title: "Article of Incoporation",
    content:
      "Rate sheet is used to accurately compute the cost of goods and services when creating a budget",
  },
  {
    title: "Operating Agreement",
    content:
      "Rate sheet is used to accurately compute the cost of goods and services when creating a budget",
  },
  {
    title: "Insurance Certificate",
    content:
      "Rate sheet is used to accurately compute the cost of goods and services when creating a budget",
  },
];

const financialDocuments = [
  {
    title: "Chart of Accounts",
    content:
      "Rate sheet is used to accurately compute the cost of goods and services when creating a budget",
    isUploaded: true,
    uploadedDate: "Uploaded March 2, 2021",
  },
  {
    title: "Financial Codes",
    content:
      "Rate sheet is used to accurately compute the cost of goods and services when creating a budget",
  },
  {
    title: "Rate Sheet",
    content:
      "Rate sheet is used to accurately compute the cost of goods and services when creating a budget",
  },
];

const CompanyDocumentsForm = () => {
  return (
    <div className="mb-28">
      <SecondaryHeader className="mt-4 mb-2">
        Cooperate Documents
      </SecondaryHeader>
      {coporateDocuments?.map((document) => (
        <DocumentItem
          key={`${document.title}-${document.content}-${document?.uploadedDate}`}
          title={document.title}
          content={document.content}
          isUploaded={document?.isUploaded}
          uploadedDate={document?.uploadedDate}
        />
      ))}

      <SecondaryHeader className="mt-4 mb-2">
        Financial Documents
      </SecondaryHeader>
      {financialDocuments?.map((document) => (
        <DocumentItem
          key={`${document.title}-${document.content}-${document?.uploadedDate}`}
          title={document.title}
          content={document.content}
          isUploaded={document?.isUploaded}
          uploadedDate={document?.uploadedDate}
        />
      ))}
      <SecondaryButton
        className="col-span-3 mr-4"
        iconLeft={<img alt="" src={PlusIcon} />}
        title="Add New Document"
      />

      <br />

      <div className="flex float-right">
        <TertiaryButton className="col-span-1 mr-3" title="Discard" />
        <SecondaryButton className="col-span-1 mr-3" title="Save Draft" />
        <PrimaryButton className="col-span-1 mr-3" title="Next" />
      </div>
    </div>
  );
};

CompanyDocumentsForm.propTypes = {};

CompanyDocumentsForm.defaultProps = {};

export default CompanyDocumentsForm;

import React, { useState, useCallback } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import CollapsibleSection from "../CollapsibleSection/CollapsibleSection";
import CSICodeAndMappingExpenseMapping from "./CSICodeAndMappingExpenseMapping";
import CSICodeAndMappingRevenueMapping from "./CSICodeAndMappingRevenueMapping";
import BaseButton from "../Buttons/BaseButton";
import removeIcon from "../../assets/images/Remove.svg";
import editIcon from "../../assets/images/editIcon.svg";
import Input from "../Input/Input";
import CSICodeAndMappingAddCodePopover from "./CSICodeAndMappingAddCodePopover";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../assets/images/whiteExclamationIcon.svg";
import { toastError } from "../../../helpers/Toast";

const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);

const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const deleteButtonCN = cntl`
  ml-2
  w-5
  h-5
`;

const tertiaryButtonCN = cntl`
  text-brandGreen
  py-4
  text-base
`;

const editButtonCN = (className) => cntl`
  ml-2
  w-5
  h-5
  ${className}
`;

const collapsibleContainerCN = (className, showBottomBorder) => cntl`
  mt-2
  text-black
  ${className}
  ${showBottomBorder && "border-b"}
`;

const buttonCN = cntl`
  disabled:bg-opacity-0
  disabled:text-brandGreenLight
  text-brandGreen
  py-4
  text-base
`;

const initialState = (divCode) => {
  return {
    code: `${divCode}`,
    description: "",
  };
};

const validateUniqueness = (selectedCSICode, csiCodeMapping) => {
  const [, selectedCode, selectedSubCode] = selectedCSICode.code.split(" ");

  let isUnique = true;
  csiCodeMapping.csiCodes.map((csiCode) => {
    if (csiCode.code === selectedCode) {
      csiCode.subCodes.map((subCode) => {
        if (subCode.code === selectedSubCode) {
          isUnique = false;
        }
        return subCode;
      });
    }
    return csiCode;
  });
  return isUnique;
};

const CSICodeAndMappingViewCode = ({
  mapping,
  mappingIndex,
  revenueAccountingCodes,
  expenseAccountingCodes,
  dispatchFinancialsSettings,
}) => {
  const [isRevenueAdding, setIsRevenueAdding] = useState(false);
  const [isExpenseAdding, setIsExpenseAdding] = useState(false);
  const [showAddCodePopover, setShowAddCodePopover] = useState(false);
  const [newCSICode, setNewCSICode] = useState(initialState(mapping.division));

  const toggleMapping = useCallback(() => {
    dispatchFinancialsSettings({
      type: "toggleCSIDivisionMapping",
      mappingIndex,
    });
  }, [dispatchFinancialsSettings, mappingIndex]);

  const toggleCsiCode = useCallback(
    (csiCodeIndex) => {
      dispatchFinancialsSettings({
        type: "toggleCSICode",
        mappingIndex,
        csiCodeIndex,
      });
    },
    [dispatchFinancialsSettings, mappingIndex]
  );

  const openMapping = useCallback(() => {
    toggleMapping();
    setIsRevenueAdding(false);
    setIsExpenseAdding(false);
  }, [toggleMapping]);

  const onRemoveSubcode = useCallback(
    (csiCodeIndex, subCodeIndex) => {
      dispatchFinancialsSettings({
        type: "removeCSICode",
        mappingIndex,
        csiCodeIndex,
        subCodeIndex,
      });
    },
    [dispatchFinancialsSettings, mappingIndex]
  );

  const toggleEditSubcode = useCallback(
    (csiCodeIndex, subCodeIndex) => {
      dispatchFinancialsSettings({
        type: "toggleEditCSICode",
        mappingIndex,
        csiCodeIndex,
        subCodeIndex,
      });
    },
    [dispatchFinancialsSettings, mappingIndex]
  );

  const editSubcode = useCallback(
    (csiCodeIndex, subCodeIndex, description) => {
      dispatchFinancialsSettings({
        type: "editCSICodeDescription",
        mappingIndex,
        csiCodeIndex,
        subCodeIndex,
        description,
      });
    },
    [dispatchFinancialsSettings, mappingIndex]
  );

  const onAdd = useCallback(() => {
    setShowAddCodePopover(true);
  }, []);

  const onClose = useCallback(() => {
    setNewCSICode(initialState(mapping.division));
    setShowAddCodePopover(false);
  }, [mapping.division]);

  const onFinishAdding = useCallback(
    (addAnother) => {
      const isValid = validateUniqueness(newCSICode, mapping);
      if (isValid) {
        dispatchFinancialsSettings({
          type: "addCSICode",
          mappingIndex,
          description: newCSICode.description,
          csiCode: newCSICode.code.split(" ")[1],
          subCode: newCSICode.code.split(" ")[2],
        });
        setNewCSICode(initialState(mapping.division));
        setShowAddCodePopover(addAnother);
      } else {
        toastError(
          "CSI Code already exists. Choose a unique code.",
          toastErrorIcon,
          toastCloseIcon
        );
      }
    },
    [dispatchFinancialsSettings, mapping, mappingIndex, newCSICode]
  );

  return (
    <CollapsibleSection
      id={mappingIndex}
      className={collapsibleContainerCN("border-gray-150 border rounded px-4")}
      titleComponent={() => (
        <div className="flex ml-2">
          <div className="w-40 overflow-ellipsis pr-4">
            <h3 className="font-bold text-gray-300 truncate">
              Division {mapping?.division}
            </h3>
          </div>
          <div>
            <h3 className="font-semibold text-gray-300">
              {mapping?.description}
            </h3>
          </div>
        </div>
      )}
      isOpen={mapping?.isOpen}
      setIsOpen={openMapping}
      useChevronIcon
    >
      <div className="flex flex-col">
        <div className="flex flex-row ml-2">
          <div className="py-1 w-40 overflow-ellipsis pr-4">
            <p className="font-semibold text-sm">CSI Code</p>
          </div>
          <div className="py-1">
            <p className="font-semibold text-sm">Description</p>
          </div>
        </div>
        {mapping?.csiCodes?.map((csiCode, csiCodeIndex) => {
          return (
            <div key={csiCode?.id}>
              {csiCode?.subCodes?.map((subCode, subCodeIndex) => (
                <CollapsibleSection
                  key={subCode?.id}
                  id={`${csiCode?.code} ${subCode?.code}`}
                  className={collapsibleContainerCN(
                    "border-gray-150 mb-2 pb-2 mr-6",
                    true
                  )}
                  titleComponent={() => (
                    <>
                      {(subCode?.isEditing && (
                        <div className="flex flex-row pl-2">
                          <div className="pb-1 w-40 overflow-ellipsis pr-4">
                            <p className="text-gray-200 text-sm truncate">
                              {mapping?.division} {csiCode?.code}{" "}
                              {subCode?.code}
                            </p>
                          </div>
                          <div className="flex w-120 -mt-2">
                            <Input
                              value={subCode.description}
                              placeholder="Description"
                              onChange={(val) =>
                                editSubcode(csiCodeIndex, subCodeIndex, val)
                              }
                              onBlur={() =>
                                toggleEditSubcode(csiCodeIndex, subCodeIndex)
                              }
                              mainClassName="w-full"
                            />
                          </div>
                          <div className="flex">
                            <button
                              className={editButtonCN("mt-0")}
                              type="button"
                              onClick={() =>
                                toggleEditSubcode(csiCodeIndex, subCodeIndex)
                              }
                            >
                              <img
                                className="w-8 cursor-pointer"
                                src={editIcon}
                                alt="edit data"
                              />
                            </button>
                          </div>
                        </div>
                      )) || (
                        <div className="flex flex-row pl-2">
                          <div className="pb-1 w-40 overflow-ellipsis pr-4">
                            <p className="text-gray-200 text-sm truncate">
                              {mapping?.division} {csiCode?.code}{" "}
                              {subCode?.code}
                            </p>
                          </div>
                          <div className="pb-1">
                            <p className="text-gray-200 text-sm">
                              {!subCode?.revenueCode &&
                                !subCode?.expenseCode &&
                                subCode?.description}
                              {subCode?.revenueCode &&
                                revenueAccountingCodes?.find(
                                  (revenueCode) =>
                                    revenueCode.code === subCode?.revenueCode
                                )?.description}
                              {subCode?.expenseCode &&
                                expenseAccountingCodes?.find(
                                  (expenseCode) =>
                                    expenseCode.code === subCode?.expenseCode
                                )?.description}
                            </p>
                          </div>
                          {!subCode?.count && subCode.custom && (
                            <div className="pl-2">
                              <button
                                className={deleteButtonCN}
                                type="button"
                                onClick={() =>
                                  onRemoveSubcode(csiCodeIndex, subCodeIndex)
                                }
                              >
                                <img
                                  className="w-8"
                                  src={removeIcon}
                                  alt="remove data"
                                />
                              </button>
                            </div>
                          )}
                          {!subCode?.revenueCode &&
                            !subCode?.expenseCode &&
                            subCode.custom && (
                              <div className="flex">
                                <button
                                  className={editButtonCN("mt-0")}
                                  type="button"
                                  onClick={() =>
                                    toggleEditSubcode(
                                      csiCodeIndex,
                                      subCodeIndex
                                    )
                                  }
                                >
                                  <img
                                    className="w-8 cursor-pointer"
                                    src={editIcon}
                                    alt="edit data"
                                  />
                                </button>
                              </div>
                            )}
                        </div>
                      )}
                    </>
                  )}
                  isOpen={csiCode?.isOpen}
                  setIsOpen={() => toggleCsiCode(csiCodeIndex)}
                  useChevronIcon
                  showIconOnStart
                  disable={subCodeIndex !== csiCode?.subCodes?.length - 1}
                  hideIconOnOpen={
                    subCodeIndex !== csiCode?.subCodes?.length - 1
                  }
                >
                  <div>
                    {subCodeIndex === csiCode?.subCodes?.length - 1 &&
                      isRevenueAdding && (
                        <CSICodeAndMappingRevenueMapping
                          csiCode={csiCode}
                          revenueAccountingCodes={revenueAccountingCodes}
                          mappingIndex={mappingIndex}
                          csiCodeIndex={csiCodeIndex}
                          setIsAdding={setIsRevenueAdding}
                          dispatchFinancialsSettings={
                            dispatchFinancialsSettings
                          }
                          divCode={mapping?.division}
                        />
                      )}
                    {subCodeIndex === csiCode?.subCodes?.length - 1 &&
                      isExpenseAdding && (
                        <CSICodeAndMappingExpenseMapping
                          csiCode={csiCode}
                          expenseAccountingCodes={expenseAccountingCodes.filter(
                            (expenseCode) =>
                              !csiCode.subCodes.some(
                                (subcode) =>
                                  subcode.expenseCode === expenseCode.code
                              )
                          )}
                          mappingIndex={mappingIndex}
                          csiCodeIndex={csiCodeIndex}
                          setIsAdding={setIsExpenseAdding}
                          dispatchFinancialsSettings={
                            dispatchFinancialsSettings
                          }
                          divCode={mapping?.division}
                        />
                      )}
                    {subCodeIndex === csiCode?.subCodes?.length - 1 &&
                      !isExpenseAdding &&
                      !isRevenueAdding && (
                        <div className="flex flex-row">
                          <BaseButton
                            title="+ Map Revenue Code"
                            onClick={() => setIsRevenueAdding((prev) => !prev)}
                            disabled={csiCode?.subCodes?.some(
                              (code) => code.revenueCode
                            )}
                            className={buttonCN}
                            labelClassName="mx-0"
                          />
                          <BaseButton
                            title="+ Map Expense Code"
                            onClick={() => setIsExpenseAdding((prev) => !prev)}
                            className={buttonCN}
                            labelClassName="mx-0"
                          />
                        </div>
                      )}
                  </div>
                </CollapsibleSection>
              ))}
            </div>
          );
        })}
        {!showAddCodePopover && (
          <div>
            <BaseButton
              title="+ Add CSI Code"
              onClick={onAdd}
              className={tertiaryButtonCN}
              innerClassName="mx-0"
            />
          </div>
        )}
        {showAddCodePopover && (
          <CSICodeAndMappingAddCodePopover
            mapping={mapping}
            newCSICode={newCSICode}
            setNewCSICode={setNewCSICode}
            onFinishAdding={onFinishAdding}
            onClose={onClose}
          />
        )}
      </div>
    </CollapsibleSection>
  );
};

CSICodeAndMappingViewCode.propTypes = {
  mapping: PropTypes.shape({
    division: PropTypes.string,
    description: PropTypes.string,
    csiCodes: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        description: PropTypes.string,
        revenueCode: PropTypes.string,
        selectedForBudgets: PropTypes.string,
        selectedForTimesheets: PropTypes.bool,
        subCodes: PropTypes.arrayOf(
          PropTypes.shape({
            code: PropTypes.string,
            expenseCode: PropTypes.string,
            selectedForBudgets: PropTypes.string,
            selectedForTimesheets: PropTypes.bool,
          })
        ),
      })
    ),
    selectedForBudgets: PropTypes.string,
    selectedForTimesheets: PropTypes.bool,
    isOpen: PropTypes.bool,
  }),
  mappingIndex: PropTypes.number,
  revenueAccountingCodes: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      codeType: PropTypes.string,
      description: PropTypes.string,
      selectedForBudgets: PropTypes.bool,
      selectedForTimesheets: PropTypes.bool,
    })
  ),
  expenseAccountingCodes: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      codeType: PropTypes.string,
      description: PropTypes.string,
      selectedForBudgets: PropTypes.bool,
      selectedForTimesheets: PropTypes.bool,
    })
  ),
  dispatchFinancialsSettings: PropTypes.func,
};

CSICodeAndMappingViewCode.defaultProps = {
  mapping: undefined,
  mappingIndex: undefined,
  revenueAccountingCodes: [],
  expenseAccountingCodes: [],
  dispatchFinancialsSettings: undefined,
};

export default CSICodeAndMappingViewCode;

import editCalendarStore from "../../../helpers/Calendar/editCalendarStore";

const editEventResponder = (queryClient, calendarData) => {
  return {
    onSuccess: async (returnedData, variables) => {
      // returned data comes from the handler, variables are what was passed to the handler

      return editCalendarStore({
        queryClient,
        variables,
        calendarData,
        item: returnedData.data,
      });
    },
    onError: (err) => {
      console.error("Failed to edit event.", err);
    },
  };
};

export default editEventResponder;

import cntl from "cntl";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import editIcon from "../../assets/images/editIcon.svg";
import IconButton from "../Buttons/IconButton";
import Input from "../Input/Input";

const overviewWrapperCN = (modal) => cntl`
w-full 
flex 
flex-col 
rounded 
mb-4 
${!modal && "border-2 p-5"}
`;

const titleCN = cntl`
font-bold 
text-gray-300
`;

const valueWrapperCN = (modal) => cntl`
flex
flex-col
${!modal ? "w-1/3" : "w-2/3"}
truncate
my-4
`;

const subTitleCN = cntl`
font-bold 
text-gray-700 
text-sm
`;

const valueCN = cntl`
text-gray-200 
text-sm
mt-2
`;

const LocationOverview = ({
  location,
  onFinishEditing,
  dispatch,
  disableEditing,
  modal,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedLocation, setEditedLocation] = useState();
  const [hovering, setHovering] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    setEditedLocation(location);
  }, [location]);

  const handleHover = (mouseOver) => {
    if (mouseOver) {
      setHovering(true);
    } else {
      setHovering();
    }
  };

  const handleChange = (key, val, subKey = null) => {
    setEditedLocation((prev) => ({
      ...prev,
      [key]: subKey ? { ...editedLocation?.[key], [subKey]: val } : val,
    }));
    if (modal)
      dispatch({
        type: "overview",
        ...{ ...editedLocation, [key]: key === "area" ? { value: val } : val },
      });
  };

  const handleEditClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    } else {
      if (!_.isEqual(location, editedLocation)) {
        dispatch({
          type: "overview",
          name: editedLocation?.name,
          area: editedLocation?.area,
        });
        if (!modal)
          onFinishEditing(
            ["name", "area"],
            [editedLocation?.name, editedLocation?.area]
          );
      }
      setIsEditing(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleEditClick();
    }
  };

  return (
    <div
      className={overviewWrapperCN(modal)}
      ref={wrapperRef}
      onMouseOver={() => !modal && handleHover(true)}
      onMouseOut={() => !modal && handleHover()}
      onFocus={() => !modal && handleHover(true)}
      onBlur={() => !modal && handleHover()}
    >
      <div className="flex items-center justify-between">
        {!modal && <p className={titleCN}>General information</p>}
        {!disableEditing && !modal && (
          <IconButton
            wrapperClassName={hovering ? "" : "hidden"}
            imgClassName="w-6 h-6"
            iconClassName="w-6 h-6"
            onClick={handleEditClick}
            icon={editIcon}
          />
        )}
      </div>
      <div className={`flex ${modal && "flex-col"}`}>
        <div className={valueWrapperCN(modal)}>
          {!modal && <p className={subTitleCN}>Name</p>}
          {!isEditing && !modal && (
            <p className={valueCN}>{editedLocation?.name}</p>
          )}
          {(isEditing || modal) && (
            <Input
              className="mt-2"
              mainClassName="w-2/3"
              label={modal && "Name"}
              placeholder="Name"
              value={editedLocation?.name}
              onChange={(val) => handleChange("name", val)}
              handleEnter={handleKeyDown}
              validation={yup.string().required()}
              showValidationErrorAtBottom={!modal}
              disabled={disableEditing}
            />
          )}
        </div>
        <div className={valueWrapperCN(modal)}>
          {!modal && <p className={subTitleCN}>Square Footage</p>}
          {!isEditing && !modal && (
            <p className={valueCN}>{editedLocation?.area?.value}</p>
          )}
          {(isEditing || modal) && (
            <Input
              mainClassName="w-2/3"
              className="mt-2"
              label={modal && "Square Footage"}
              type="number"
              placeholder="Sq ft."
              value={editedLocation?.area?.value}
              onChange={(val) => handleChange("area", val, "value")}
              handleEnter={handleKeyDown}
              validation={yup.string().required()}
              showValidationErrorAtBottom={!modal}
              minValue="0"
              disableClear
              disabled={disableEditing}
            />
          )}
        </div>
        {!modal && (
          <div className={valueWrapperCN(modal)}>
            <p className={subTitleCN}>Total Spaces</p>
            {!isEditing && (
              <p className={valueCN}>{location?.spaces?.length || 0}</p>
            )}
            {isEditing && (
              <Input
                placeholder="Total"
                className="mt-2"
                mainClassName="w-1/4"
                value={location?.spaces?.length}
                disabled
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

LocationOverview.propTypes = {
  location: PropTypes.shape({
    spaces: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onFinishEditing: PropTypes.func,
  dispatch: PropTypes.func,
  disableEditing: PropTypes.bool,
  modal: PropTypes.bool,
};

LocationOverview.defaultProps = {
  location: undefined,
  onFinishEditing: undefined,
  dispatch: undefined,
  disableEditing: true,
  modal: false,
};

export default LocationOverview;

import { EventAPI, TaskAPI } from "@griffingroupglobal/eslib-api";

const addToCalendarHandler = async (newItem) => {
  const API = newItem?.resource === "Task" ? TaskAPI : EventAPI;
  const response = await API.post(newItem);

  return response;
};

export default addToCalendarHandler;

import Api from "@griffingroupglobal/eslib-api";

const { API_VERSION } = window.runConfig ?? {};

let requestInterceptor;

export default () => {
  // Clean up any existing interceptor
  if (requestInterceptor) {
    Api.interceptors.request.eject(requestInterceptor);
  }

  // Add the x-es-tenantid header to all requests
  requestInterceptor = Api.interceptors.request.use((request) => {
    const apiVersion = API_VERSION;
    const newRequest = { ...request };
    newRequest.headers["x-es-apiversion"] = apiVersion;

    return newRequest;
  });
};

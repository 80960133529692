/* Framework Tools */
import { useCallback } from "react";

import { useAppState } from "../../../state/appState";
import { SET_WORKFLOW_MODAL_STATUS } from "../../../constants";
import useCurrentUser from "../../../hooks/useCurrentUser";

const useWorkflowOverviewData = () => {
  const { data: currentUser } = useCurrentUser();

  const [, dispatch] = useAppState();

  const handleAddWorkflow = useCallback(() => {
    dispatch({
      type: SET_WORKFLOW_MODAL_STATUS,
      open: true,
      edit: false,
    });
  }, [dispatch]);

  return { currentUser, handleAddWorkflow };
};

export default useWorkflowOverviewData;

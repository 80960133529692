import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { debounce, uniqueId } from "lodash";
import { useHistory } from "react-router";
import crossIconWhite from "../../assets/images/crossIconWhite.svg";
import Input from "../Input/Input";
import "./step.css";
import checkedClipboard from "../../assets/images/checkedClipboard.svg";

const Step = ({
  index,
  sop,
  handleStepEdit,
  handleStepRemove,
  handleAddStep,
  form,
  sopOptions,
  disabled,
}) => {
  const history = useHistory();
  const wrapper = useRef();
  const sopWrapper = useRef();
  const [options, setOptions] = useState();
  const debouncedHandleSearch = useMemo(
    () =>
      debounce((input) => {
        if (input !== "") {
          const reg = new RegExp(input.toLowerCase());
          setOptions(() => {
            return [
              ...sopOptions?.filter((sopOption) =>
                reg.test(sopOption?.label?.toLowerCase())
              ),
            ];
          });
        } else {
          setOptions();
        }
      }, 200),
    [sopOptions]
  );

  const handleClickOutside = useCallback(
    (e) => {
      const { clientX: clickX, clientY: clickY } = e ?? {
        clientX: 0,
        clientY: 0,
      };
      const {
        x,
        y,
        width: containerWidth,
        height: containerHeight,
      } = sopWrapper?.current?.getBoundingClientRect() ?? {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      };

      // Track if outside click (not including div#sop-wrapper usually hidden)
      const sopStepWrapper = e?.target?.id === "sop-wrapper";

      // Check if click is outside options horizontally
      const isClickOutsideHorizontally =
        x > clickX || clickX > x + containerWidth;

      // Check if click is outside options vertically
      const isClickOutsideVertically =
        y > clickY || clickY > y + containerHeight;

      // Checks to see if click is outside of options
      const isClickOutside =
        isClickOutsideHorizontally || isClickOutsideVertically;

      // If click is outside options close options
      if (isClickOutside && !sopStepWrapper && options) {
        setOptions();
      }
    },
    [options]
  );

  // watch for click event and close SOP options
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [handleClickOutside]);

  const handleSelection = (event, val, i) => {
    event.target.blur();
    // select either step or custom description
    if (val?.value?.includes("Sop/")) {
      handleStepEdit(i, "sop", val.value, val.version);
    } else {
      handleStepEdit(index, "description", val.value);
    }
    // Clear Options after selection
    setOptions();
    wrapper?.current?.blur();
  };

  // Menu Closes on blur
  return (
    <div className="flex flex-col gap-4 menu-open" ref={wrapper}>
      <div
        key={index}
        className="relative flex items-center border rounded-lg h-ful w-full p-4"
      >
        <p className="flex justify-center items-start font-semibold w-8 mr-5">
          {index + 1}
        </p>
        <p className="relative flex h-full w-full">
          <Input
            value={
              sopOptions?.find((x) => x.value === form?.steps?.[index]?.sop)
                ?.label ?? form?.steps?.[index]?.description
            }
            onChange={(val) => {
              handleStepEdit(index, "description", val);
              debouncedHandleSearch(val);
            }}
            leftInlineInputComponent={
              sopOptions?.find((x) => x.value === form?.steps?.[index]?.sop)
                ?.value ? (
                <button
                  className="flex items-center bg-tagGreen pl-4 pr-1"
                  onClick={() =>
                    history.push(
                      `/sops/${
                        sopOptions
                          ?.find((x) => x.value === form?.steps?.[index]?.sop)
                          ?.value.split("/")[1]
                      }`
                    )
                  }
                  type="button"
                >
                  <img
                    src={checkedClipboard}
                    alt={sop?.name}
                    className="w-4 h-5"
                  />
                  {sop?.name}
                </button>
              ) : null
            }
            mainWrapperClassName="w-full"
            inputContainerClassName="w-full"
            className="rounded-md"
            inputClassName="bg-tagGreen pl-2 w-full"
            handleEnter={(e) => {
              if (e?.key === "Enter") {
                handleAddStep();
                setOptions();
              }
            }}
            placeholder="Instructions"
            autoFocus
            disabled={disabled}
          />

          {options && (
            <div
              className="pt-2 absolute z-10 w-full"
              style={{ top: "100%" }}
              id="sop-wrapper"
              ref={sopWrapper}
            >
              <div
                className="flex flex-col w-full overflow-y-scroll shadow-light-lift bg-white rounded-md border-2 border-gray-150"
                style={{
                  maxHeight: "280px",
                }}
              >
                {options?.map((item) => {
                  const isInForm = form?.steps?.some(
                    (step) => step?.sop === item?.value
                  );
                  if (isInForm) return;
                  // eslint-disable-next-line consistent-return
                  return (
                    <button
                      key={uniqueId()}
                      className="flex flex-row w-full items-center text-left text-gray-500 font-normal truncate hover:bg-backgroundGreen focus:bg-backgroundGreen px-6 text-sm"
                      style={{ minHeight: "70px" }}
                      type="button"
                      onClick={(e) => handleSelection(e, item, index)}
                    >
                      <img
                        src={checkedClipboard}
                        alt={sop?.name}
                        className="w-4 h-5 mr-2"
                      />
                      {item.label}
                    </button>
                  );
                })}
              </div>
            </div>
          )}
        </p>
        {!disabled && (
          <button
            type="button"
            className="absolute -top-2.5 -right-2.5 z-10"
            style={{
              width: "18px",
              height: "18px",
            }}
            onClick={() => handleStepRemove(index)}
          >
            <img
              alt="delete tag"
              className="rounded-xl p-1"
              style={{ backgroundColor: "#027D61" }}
              src={crossIconWhite}
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default Step;

import { UserAPI } from "@griffingroupglobal/eslib-api";
import { useQueryClient, useMutation } from "react-query";
import { userKeys } from "../config/reactQuery/queryKeyFactory";
import mutateUserCache from "../helpers/Users/mutateUsersCache";
import { toastError, toastMessage } from "../stories/Components/Toast/Toast";
import { useAppState } from "../state/appState";
import { SET_USERS, SET_USER_DICT } from "../constants";

const patchUser = async ({ oldUserInfo, newUserInfo }) => {
  const { data } = await UserAPI.patch(
    oldUserInfo.id,
    newUserInfo,
    oldUserInfo
  );

  return data;
};

/**
 * Mutation hook to patch single user
 * @param {string} mutationKey custom key to track mutations
 * @returns mutation metadata
 */
const useUserPatch = (mutationKey) => {
  const queryClient = useQueryClient();
  const [{ userDict }, dispatch] = useAppState();

  return useMutation({
    mutationKey,
    mutationFn: patchUser,
    onMutate: async ({ newUserInfo, newUserPermissions }) => {
      await queryClient.cancelQueries(userKeys.users);

      // Save reference of all query data that matches the query key ["users"]
      // This is going to be use in case of a possible error.
      const previousAllUsers = queryClient.getQueriesData(userKeys.users);

      mutateUserCache(queryClient, { ...newUserInfo, ...newUserPermissions });

      return { previousAllUsers, newUserInfo, newUserPermissions };
    },
    onError: (error, variables, context) => {
      const { previousAllUsers } = context ?? {};

      // Rollback users to previous state
      if (previousAllUsers?.length) {
        previousAllUsers.forEach(([key, value]) => {
          queryClient.setQueryData(key, value);
        });
      }

      toastError("User could not be updated. Please try again");
      console.error("useUserPatch", error);
    },
    onSuccess: (data, variables, { newUserPermissions }) => {
      // update lastUpdate, roleName, etc. from backend
      // if there are permissions passed, add them
      const updatedUserInfo = {
        ...data,
        ...newUserPermissions,
      };
      mutateUserCache(queryClient, updatedUserInfo);

      // set updated user in app state
      const updatedUserDict = { ...userDict };
      updatedUserDict[updatedUserInfo?.reference] = updatedUserInfo;
      const updatedUsers = Object.values(updatedUserDict);

      dispatch({
        type: SET_USER_DICT,
        userDict: updatedUserDict,
      });
      dispatch({
        type: SET_USERS,
        users: updatedUsers,
      });
      toastMessage("User Updated Successfully");
    },
    onSettled: (data, error, { oldUserInfo }) => {
      queryClient.invalidateQueries(userKeys.byId(oldUserInfo.id));

      if (!error) {
        queryClient.invalidateQueries(userKeys.assigness());
      }
    },
  });
};

export default useUserPatch;

import editTaskStore from "../../../helpers/Task/editTaskStore";

const editTaskResponder = (queryClient) => {
  return {
    onSuccess: (returnedData) => {
      return editTaskStore({ queryClient, returnedData });
    },
    onError: (err) => console.error("Error", err),
  };
};

export default editTaskResponder;

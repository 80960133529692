// Framework Tools
import React from "react";
import PropTypes from "prop-types";

// Components
import TertiaryHeader from "../../TextHeaders/TertiaryHeader";

export default function RequestGridViewDescriptionCell({ description }) {
  return (
    <div className="w-full h-ful flex flex-col justify-start flex-wrap px-2">
      <TertiaryHeader className="text-sm text-left">Description</TertiaryHeader>
      <p className="text-sm text-left">{description}</p>
    </div>
  );
}

// prop types
RequestGridViewDescriptionCell.propTypes = {
  description: PropTypes.string,
};

// default props
RequestGridViewDescriptionCell.defaultProps = {
  description: "",
};

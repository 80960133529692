import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";

import attachmentIcon from "../../../stories/assets/images/attachment_icon_updated.svg";

const AddMessageAttachment = ({ loading, onAddFiles }) => {
  const inputRef = useRef();

  const handleClick = useCallback(() => {
    inputRef.current.click();
  }, []);
  return (
    <div>
      <button
        className="inline-flex gap-2 justify-start items-center text-gray-450 hover:underline"
        type="button"
        onClick={!loading ? handleClick : () => {}}
        style={{ fontSize: "16px" }}
      >
        <img
          src={attachmentIcon}
          className="w-5 h-5 inline"
          alt="delete-icon"
        />
        <span>Add Attachment</span>
        {loading && <div className="loading-circle-small" />}
      </button>
      <input
        type="file"
        className="hidden"
        id="file-input"
        onChange={onAddFiles}
        ref={(el) => {
          inputRef.current = el;
        }}
        multiple
      />
    </div>
  );
};

AddMessageAttachment.propTypes = {
  loading: PropTypes.bool,
  onAddFiles: PropTypes.func,
};

AddMessageAttachment.defaultProps = {
  loading: false,
  onAddFiles: () => {},
};

export default AddMessageAttachment;

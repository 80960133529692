import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import getAssetForSpaceLookupTable from "../helpers/Calendar/getAssetForSpaceLookupTable";
import { useAssetsOverview } from "./assets";

/**
 * @Summary returns list of associated resources
 */
export default () => {
  const { propertyId, projectId, assetId, spaceId } = useParams();

  const {
    assetsDict,
    queryInfo: { isLoading: isLoadingAssetsDict },
  } = useAssetsOverview();

  const [parentFilter, setParentFilter] = useState();
  const [spaceFilter, setSpaceFilter] = useState();
  const [assetFilterDict, setAssetFilterDict] = useState({});

  const [associationLock, setAssociationLock] = useState();
  const [assetLock, setAssetLock] = useState();
  const [spaceLock, setSpaceLock] = useState();
  const [loading, setLoading] = useState(true);

  const reload = useCallback(async () => {
    // (Parker) On Browser Refresh of a Space view, received assetsDict undefined error, added this check
    if (isLoadingAssetsDict) return;
    setLoading(true);
    let reference;
    let association = false;
    let assetDict = {};

    switch (true) {
      case !!spaceId && !!propertyId:
        association = `Property/${propertyId}`;
        // Get Space n Asset Calendar
        setSpaceFilter(spaceId);
        assetDict = getAssetForSpaceLookupTable(assetsDict, spaceId);
        setSpaceLock(spaceId);
        break;
      case !!spaceId && !!projectId:
        association = `Project/${projectId}`;
        // Get Space n Asset Calendar
        setSpaceFilter(spaceId);
        assetDict = getAssetForSpaceLookupTable(assetsDict, spaceId);
        setSpaceLock(spaceId);
        break;
      case !!assetId && !!assetsDict?.[`Asset/${assetId}`]?.property: {
        association = assetsDict?.[`Asset/${assetId}`]?.property;

        // Get only Assets Calendar
        assetDict = { [`Asset/${assetId}`]: true };
        setAssetLock(`Asset/${assetId}`);
        break;
      }
      case !!assetId && !!assetsDict?.[`Asset/${assetId}`]?.project: {
        association = assetsDict?.[`Asset/${assetId}`]?.project;
        // Get only Assets Calendar
        assetDict = { [`Asset/${assetId}`]: true };
        setAssetLock(`Asset/${assetId}`);
        break;
      }
      case !!propertyId: {
        association = `Property/${propertyId}`;
        // Get Property Calendar (space, assets will be included)
        reference = `Property/${propertyId}`;
        break;
      }
      case !!projectId: {
        association = `Project/${projectId}`;
        // Get Project Calendar (space, assets will be included)
        reference = `Project/${projectId}`;
        break;
      }
      default: {
        reference = null;
      }
    }
    setAssociationLock(association);
    setParentFilter(reference);
    setAssetFilterDict(assetDict);
    setLoading(false);
  }, [
    assetId,
    assetsDict,
    isLoadingAssetsDict,
    projectId,
    propertyId,
    spaceId,
  ]);

  useEffect(() => {
    reload();
  }, [reload]);

  /**
   * Filters Events by associations
   */
  const eventFilter = (day = { allDay: [], brief: [] }) => {
    const { brief, allDay } = day;
    if (parentFilter || spaceFilter || Object.keys(assetFilterDict)?.length) {
      return !loading
        ? {
            brief: brief.filter((item) => {
              return (
                item.association === parentFilter ||
                item?.spaces?.some((space) => space.id === spaceFilter) ||
                item?.assets?.some((asset) => !!assetFilterDict[asset.ref])
              );
            }),
            allDay: allDay.filter((item) => {
              return (
                item.association === parentFilter ||
                item?.spaces?.some((space) => space.id === spaceFilter) ||
                item?.assets?.some((asset) => !!assetFilterDict[asset.ref])
              );
            }),
          }
        : { brief: [], allDay: [] };
    }
    return !loading ? day : { brief: [], allDay: [] };
  };

  const taskFilter = useCallback(
    (tasks) => {
      if (parentFilter || spaceFilter || Object.keys(assetFilterDict)?.length)
        if (!loading) {
          return (
            tasks.filter((item) => {
              return (
                item.association === parentFilter ||
                item?.spaces?.some((space) => space.id === spaceFilter) ||
                item?.assets?.some((asset) => !!assetFilterDict[asset.ref])
              );
            }) ?? []
          );
        } else {
          return [];
        }
      return tasks;
    },
    [assetFilterDict, loading, parentFilter, spaceFilter]
  );

  return { associationLock, assetLock, spaceLock, eventFilter, taskFilter };
};

import React from "react";
import PropTypes from "prop-types";
import greyCrossIcon from "../../assets/images/greyCrossIcon.svg";
import BaseButton from "../Buttons/BaseButton";

const CrossButton = ({ className, onClick, icon, style }) => (
  <BaseButton
    className={className}
    onClick={onClick}
    iconLeft={
      <img
        style={style || { height: "0.625rem", width: "0.625rem" }}
        src={icon || greyCrossIcon}
        alt="Close"
      />
    }
  />
);

CrossButton.propTypes = {
  /**
   * classes to apply to the button
   */
  className: PropTypes.string,
  /**
   * function called when the button is clicked
   */
  onClick: PropTypes.func,
  icon: PropTypes.element,
  style: PropTypes.objectOf({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
};

CrossButton.defaultProps = {
  className: undefined,
  onClick: undefined,
  icon: undefined,
  style: undefined,
};

export default CrossButton;

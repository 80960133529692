import Api from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import projectsKey from "./projectKeys";

const useProjectsDelete = () => {
  const queryClient = useQueryClient();

  const deleteProjectsBulk = (ids) => {
    return Api.post("/api/Project/$bulkdelete", {
      projects: ids,
    });
  };

  const mutation = useMutation({
    mutationFn: deleteProjectsBulk,
    onMutate: async (ids) => {
      const queryKey = projectsKey.all;

      // Cancel any query for projects in the meantime
      await queryClient.cancelQueries(queryKey);

      // Get the current state of the projects to rollback in case of any error
      const previousAllProjects = queryClient.getQueriesData(queryKey);

      const idsToRemove = new Set(ids);

      if (previousAllProjects?.length) {
        /**
         * Remove projects(s) from all posible queries but not projectsOverview
         */
        queryClient.setQueriesData(queryKey, (current) => {
          if (Array.isArray(current)) {
            return current.filter((report) => !idsToRemove.has(report.id));
          }

          if (typeof current === "object" && idsToRemove.has(current.id)) {
            return undefined;
          }

          return current;
        });
      }

      // Returning context in case of any error
      return { previousAllProjects };
    },
    onError: (error, _, context) => {
      // Rollback to previuos state
      const { previousAllProjects } = context ?? {};

      // Rollback assets to previous state
      previousAllProjects.forEach(([key, value]) => {
        queryClient.setQueryData(key, value);
      });

      console.error("useProjectsDelete: ", error);
    },
    onSettled: () => {
      // Invalidate matching queries
      queryClient.invalidateQueries(projectsKey.overview);
    },
  });

  const deleteProjects = (ids) => {
    return mutation.mutateAsync(ids);
  };

  return {
    deleteProjects,
    isDeleting: mutation.isLoading,
  };
};

export default useProjectsDelete;

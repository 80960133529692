// Framework Tools
import React from "react";

// Constants
import { SUBMITTAL_ROLES, WORKFLOW_ROLES } from "../../../constants";

// Components
import deleteIcon from "../../assets/images/collapseIcon.svg";
import Dropdown from "../Dropdown/Dropdown";
import IconButton from "../Buttons/IconButton";
import Input from "../Input/Input";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import capitalizeFirstLetter from "../../../helpers/Utilities/capitalizeFirstLetter";

const ManagementRequestTemplateStep = ({
  duration,
  role,
  handleRemoveStep,
  currentIndex,
  isWorkflowTemplate,
  onChangeDuration,
  onChangeRole,
}) => {
  return (
    <div className="flex items-center w-full border p-4 mt-4 justify-evenly">
      <div className="flex">
        <SecondaryHeader>Step {currentIndex + 1}</SecondaryHeader>
      </div>
      <div className="flex w-1/3">
        <Dropdown
          disableClear
          className="w-full"
          options={isWorkflowTemplate ? WORKFLOW_ROLES : SUBMITTAL_ROLES}
          value={{
            label: capitalizeFirstLetter(role),
            value: role?.toLowerCase(),
          }}
          onChange={(val) => onChangeRole(currentIndex, val)}
          label="Role"
          isDisabled={currentIndex === 0 && !isWorkflowTemplate}
        />
      </div>
      <div className="flex items-center">
        <Input
          placeholder="Duration"
          mainClassName="w-full mx-2"
          className="mx-2"
          label="Duration"
          value={duration}
          onChange={(val) => onChangeDuration(currentIndex, parseInt(val, 10))}
          disableClear
          type="number"
          minValue="0"
        />
        <SecondaryHeader className="mt-2">
          {duration > 1 ? "Days" : "Day"}
        </SecondaryHeader>
      </div>
      {currentIndex > 0 && (
        <IconButton
          className="ml-2 mx-2"
          imgClassName="w-5 h-5 mr-4"
          iconClassName="w-5 h-5"
          onClick={() => handleRemoveStep(currentIndex)}
          icon={deleteIcon}
        />
      )}
      {currentIndex <= 0 && <div className="w-5 h-5 mr-4" />}
    </div>
  );
};

export default ManagementRequestTemplateStep;

import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

import DetailListCard from "../../../stories/Components/DetailListCard/DetailListCard";

const conditionOptions = [
  { label: "Excellent", value: "Excellent" },
  { label: "Fair", value: "Fair" },
  { label: "Poor", value: "Poor" },
];

const AssetGenInformation = ({
  editedAsset,
  dispatch,
  onFinishEditing,
  disableEditing,
  editing,
}) => {
  const handleEdit = (name, val) => {
    dispatch({
      type: name,
      [name]: val,
    });
  };
  const handleEditTwo = (key1, key2, value) => {
    dispatch({
      type: "edit2",
      key1,
      key2,
      value,
    });
  };

  return (
    <div className="flex flex-wrap">
      <DetailListCard
        onFinishEditing={onFinishEditing}
        values={[
          {
            title: "Brand",
            type: "INPUT",
            value: editedAsset?.generalInfo?.brand ?? "",
            onChange: (val) => handleEdit("brand", val),
          },
          {
            title: "Quantity",
            type: "NUMBER",
            value: editedAsset?.generalInfo?.quantity ?? "",
            onChange: (val) => handleEdit("quantity", val),
          },
          {
            title: "Purchase Date",
            type: "DATE",
            value: editedAsset?.generalInfo?.purchaseDate
              ? moment(editedAsset.generalInfo.purchaseDate).format("M/DD/YYYY")
              : "",
            onChange: (val) =>
              handleEditTwo("generalInfo", "purchaseDate", val),
          },
          {
            title: "Place of Origin",
            type: "INPUT",
            value: editedAsset?.generalInfo?.origin ?? "",
            onChange: (val) => handleEditTwo("generalInfo", "origin", val),
          },
          {
            title: "Year Made",
            type: "INPUT",
            value: editedAsset?.generalInfo?.yearMade,
            onChange: (val) => handleEditTwo("generalInfo", "yearMade", val),
          },
          {
            title: "Number of Previous Owners",
            type: "NUMBER",
            value: editedAsset?.generalInfo?.numberOfOwners ?? "",
            onChange: (val) =>
              handleEditTwo("generalInfo", "numberOfOwners", val),
          },
          {
            title: "Condition",
            type: "DROPDOWN",
            options: conditionOptions,
            value: editedAsset?.generalInfo?.condition ?? "",
            onChange: (val) =>
              handleEditTwo("generalInfo", "condition", val.value),
          },
          {
            title: "Family Legacy",
            type: "INPUT",
            value: editedAsset?.generalInfo?.legacy,
            onChange: (val) => handleEditTwo("generalInfo", "legacy", val),
          },
        ]}
        hideEditButton={disableEditing}
        isEditing={editing}
      />
    </div>
  );
};

AssetGenInformation.propTypes = {
  /**
   * edited asset form state
   */
  // eslint-disable-next-line react/forbid-prop-types
  editedAsset: PropTypes.object,
  /**
   * create asset form dispatcher
   */
  dispatch: PropTypes.func,
  /**
   * function called to save edits
   */
  onFinishEditing: PropTypes.func,
  disableEditing: PropTypes.bool,
  editing: PropTypes.bool,
};

AssetGenInformation.defaultProps = {
  editedAsset: undefined,
  dispatch: undefined,
  onFinishEditing: undefined,
  disableEditing: false,
  editing: false,
};

export default AssetGenInformation;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import Dropdown from "../Dropdown/Dropdown";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import DatePicker from "../DatePicker/DatePicker";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import TertiaryHeader from "../TextHeaders/TertiaryHeader";
import ListRow from "../ListRow/ListRow";

const CompanyRequirementsForm = ({
  property,
  dispatch,
  handleNextStep,
  allUsers,
}) => {
  const [accountants, setAccountants] = useState([]);
  const [CPAs, setCPAs] = useState([]);
  const [attorneys, setAttorneys] = useState([]);
  const [userDropDown, setUserDropDown] = useState([]);

  const onChangeFiscalYearStartDate = (date) => {
    dispatch({
      type: "fiscalYear",
      fiscalYear: {
        startDate: date,
      },
    });
  };
  useEffect(() => {
    setUserDropDown(
      allUsers?.map((info) => ({
        label: `${info?.name?.firstName || ""} ${
          info?.name?.lastName || "\u00A0"
        }`,
        value: info.reference,
      })) ?? []
    );
  }, [allUsers]);
  const onChangeFiscalYearEndDate = (date) => {
    dispatch({
      type: "fiscalYear",
      fiscalYear: {
        endDate: date,
      },
    });
  };

  const onRemoveAccountant = (indexToRemove) => {
    const filteredUsers = accountants.filter(
      (_, index) => index !== indexToRemove
    );
    setAccountants(filteredUsers);
  };
  const onRemoveCPA = (indexToRemove) => {
    const filteredUsers = CPAs.filter((_, index) => index !== indexToRemove);
    setCPAs(filteredUsers);
  };
  const onRemoveAttorneys = (indexToRemove) => {
    const filteredUsers = attorneys.filter(
      (_, index) => index !== indexToRemove
    );
    setAttorneys(filteredUsers);
  };
  const handleAddAccountant = (val) => {
    setAccountants((prev) => [
      ...prev,
      { name: val.label, reference: val.value },
    ]);
  };
  const handleAddCPA = (val) => {
    setCPAs((prev) => [...prev, { name: val.label, reference: val.value }]);
  };
  const handleAddAttorneys = (val) => {
    setAttorneys((prev) => [
      ...prev,
      { name: val.label, reference: val.value },
    ]);
  };
  return (
    <div className="mb-28">
      <SecondaryHeader className="mt-4 mb-2">Accounting</SecondaryHeader>
      <TertiaryHeader>Fiscal Year</TertiaryHeader>
      <div className="grid grid-cols-12 gap-4">
        <DatePicker
          className="col-span-2"
          label="Start Date"
          onChange={onChangeFiscalYearStartDate}
          value={property.fiscalYear?.startDate}
          validation={yup.date()}
        />
        <DatePicker
          className="col-span-2"
          label="End Date"
          onChange={onChangeFiscalYearEndDate}
          value={property.fiscalYear?.endDate}
          validation={yup.date()}
        />
      </div>
      <div className="grid grid-cols-12 gap-4 mt-4">
        <Dropdown
          className="col-span-3"
          label="Accounting Method"
          placeholder="Select"
        />
      </div>
      <div className="grid grid-cols-12 gap-4 mt-4">
        <Dropdown
          className="col-span-3"
          label="Payroll Frequency"
          placeholder="Select"
        />
      </div>
      <br />
      <SecondaryHeader>Finance</SecondaryHeader>
      <TertiaryHeader>Accountant</TertiaryHeader>
      <div className="mt-4">
        {accountants?.map((user, index) => (
          <ListRow
            key={user.name}
            title={user.name}
            subtitle="Accountant"
            onDelete={() => onRemoveAccountant(index)}
          />
        ))}
      </div>
      <div className="grid grid-cols-12 gap-4 mt-4 items-end">
        <div className="col-span-3">
          <Dropdown
            options={userDropDown}
            className="col-span-3"
            label="Add Accountant"
            onChange={handleAddAccountant}
          />
        </div>
        <p className="font-semibold mb-2 px-6">Or</p>
        <SecondaryButton title="+ Add Contact" />
      </div>
      <br />
      <TertiaryHeader>CPA</TertiaryHeader>
      <div className="grid grid-cols-12 gap-4 mt-4">
        {CPAs?.map((user, index) => (
          <ListRow
            key={user.name}
            title={user.name}
            subtitle="CPA"
            onDelete={() => onRemoveCPA(index)}
          />
        ))}
      </div>
      <div className="grid grid-cols-12 gap-4 mt-4 items-end">
        <div className="col-span-3">
          <Dropdown
            options={userDropDown}
            className="col-span-3"
            label="Add CPA"
            onChange={handleAddCPA}
          />
        </div>
        <p className="font-semibold mb-2 px-6">Or</p>
        <SecondaryButton title="+ Add Contact" />
      </div>
      <br />
      <SecondaryHeader>Legal</SecondaryHeader>
      <div className="grid grid-cols-12 gap-4 mt-4">
        {attorneys?.map((user, index) => (
          <ListRow
            key={user.name}
            title={user.name}
            subtitle="Attorney"
            onDelete={() => onRemoveAttorneys(index)}
          />
        ))}
      </div>
      <div className="grid grid-cols-12 gap-4 mt-4 items-end">
        <div className="col-span-3">
          <Dropdown
            options={userDropDown}
            className="col-span-3"
            label="Add Attorney"
            onChange={handleAddAttorneys}
          />
        </div>
        <p className="font-semibold mb-2 px-6">Or</p>
        <SecondaryButton title="+ Add Contact" />
      </div>
      <div className="flex float-right">
        <TertiaryButton className="col-span-1 mr-3" title="Discard" />
        <SecondaryButton className="col-span-1 mr-3" title="Save Draft" />
        <PrimaryButton
          className="col-span-1 mr-3"
          title="Next"
          onClick={handleNextStep}
        />
      </div>
    </div>
  );
};

CompanyRequirementsForm.propTypes = {
  /**
   * property currently being created or edited
   */
  // eslint-disable-next-line react/forbid-prop-types
  property: PropTypes.object,
  /**
   * function called to make changes to the property.
   * Generally will be the usePropertyFormReducer
   */
  dispatch: PropTypes.func,
  /**
   * array of all users
   */
  allUsers: PropTypes.arrayOf({
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }),
  /**
   * function to handle next step click
   */
  handleNextStep: PropTypes.func,
};

CompanyRequirementsForm.defaultProps = {
  property: {},
  dispatch: undefined,
  handleNextStep: undefined,
  allUsers: [],
};

export default CompanyRequirementsForm;

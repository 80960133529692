import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import useDocumentHistory from "../../../hooks/useDocumentHistory";

import DocumentHistoryRow from "./DocumentHistoryRow";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import Spinner from "../Spinner/Spinner";
import AttachItemModal from "../AttachItemModal/AttachItemModal";
import WorkflowView from "../../../Pages/Overviews/Workflows/WorkflowView";

const DocumentHistory = ({ document }) => {
  const { history, loading } = useDocumentHistory(document.id);
  const [docHistory, setDocHistory] = useState([]);
  const [viewWorkflow, setViewWorkflow] = useState(false);

  useEffect(() => {
    if (history?.length) {
      const hist = history
        .map((item) => {
          const userName = `${item.creator.name.firstName} ${item.creator.name.lastName}`;
          return {
            ...item,
            userName,
            date: moment(item.when).format("MMM DD, YYYY h:mm A"),
          };
        })
        .reverse();
      setDocHistory(hist);
    }
  }, [history]);

  return (
    <div className="flex flex-col items-start w-full h-full">
      <SecondaryHeader className="mb-6">Document History</SecondaryHeader>
      {loading && (
        <div className="flex justify-center items-center w-full h-full">
          <Spinner notFullScreen />
        </div>
      )}

      {!loading && (
        <>
          {docHistory?.map((histEvent, idx) => (
            <div key={histEvent.id} className="w-full">
              <DocumentHistoryRow
                historyData={histEvent}
                topLine={idx !== 0}
                bottomLine={idx !== docHistory.length - 1}
                shaded={histEvent.eventType === "Locked"}
                setViewWorkflow={setViewWorkflow}
              />
            </div>
          ))}
        </>
      )}
      {viewWorkflow && (
        <AttachItemModal
          content={<WorkflowView workflowId={viewWorkflow} />}
          onRequestModalClose={() => setViewWorkflow(false)}
          isOpen
          showBackButton
          disableClose
        />
      )}
    </div>
  );
};

DocumentHistory.propTypes = {
  /**
   * document data
   */
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.object,
};

DocumentHistory.defaultProps = {
  document: {},
};

export default DocumentHistory;

import React, { useState, useEffect, useCallback, useMemo } from "react";
import moment from "moment";
import MaintenanceCard from "./MaintenanceCard";
import GetStartedCircle from "../GetStartedCircle/GetStartedCircle";
import { keepRecurrence } from "../../../helpers/Calendar";

const FormMaintenance = ({
  maintenanceTitle,
  setIsModalOpen,
  isLoading,
  setLoading,
  showGetStarted,
  maintenaceObject,
  reloadObject,
  eventsData,
  currentUser,
  membersOptions,
  users,
  isTaskDurationRequired,
  association,
}) => {
  const [maintenanceTasks, setMaintenanceTasks] = useState([]);

  const workingTasks = useMemo(() => {
    return maintenanceTasks;
  }, [maintenanceTasks]);

  useEffect(() => {
    if (eventsData?.length > 0) {
      const notRecurringEvents = [];
      const rawRecurringEvents = {};
      let recurringEvents;
      eventsData?.forEach(({ resource }) => {
        if (!resource.recurrence) {
          notRecurringEvents.push(resource);
        } else if (keepRecurrence(resource.instanceStartDate)) {
          // eslint-disable-next-line no-lonely-if
          if (!rawRecurringEvents[resource.id]) {
            rawRecurringEvents[resource.id] = [resource];
          } else {
            rawRecurringEvents[resource.id].push(resource);
          }
        }
        recurringEvents = Object.values(rawRecurringEvents).map((entry) => {
          const sortedEntry = entry.sort((d1, d2) =>
            moment(d1.instanceStartDate).diff(moment(d2.instanceStartDate))
          );

          const recurringCalendarOptions = sortedEntry.map((event) => {
            return {
              title: event.name,
              date: event.instanceStartDate,
              allDay: false,
              classNames: ["custom-event"],
            };
          });
          const recurringTasks = {
            ...sortedEntry[0],
            recurringCalendarOptions,
          };
          return recurringTasks;
        });
      });
      const tasks = [...notRecurringEvents, ...recurringEvents];
      setMaintenanceTasks(tasks);
      setLoading(false);
    }
  }, [maintenaceObject?.id, eventsData, setLoading]);

  const buildTasksByYearArray = (tasksArray) => {
    const tasksObj = {};
    tasksArray.forEach((task) => {
      const taskYear = moment(task?.startDate).format("YYYY");
      if (!tasksObj[taskYear]) {
        tasksObj[taskYear] = [task];
      } else {
        tasksObj[taskYear].push(task);
      }
    });

    const tasksByYearArray = Object.keys(tasksObj)
      .map((k) => ({
        year: Number(k),
        items: tasksObj[k],
      }))
      .sort((a, b) => b?.year - a?.year);
    return tasksByYearArray;
  };

  const handleOnDeletedTask = useCallback(
    (deletedEvent) => {
      const updatedTasks = maintenanceTasks?.filter(
        (task) => task?.reference !== deletedEvent?.reference
      );
      setMaintenanceTasks((prev) => updatedTasks ?? prev);
    },
    [maintenanceTasks]
  );

  const handleOnEditedTask = useCallback(
    async (editedTask) => {
      const updatedTasks = maintenanceTasks?.map((task) => {
        if (task.id === editedTask.id) {
          return editedTask;
        }
        return task;
      });

      await reloadObject();
      setMaintenanceTasks(updatedTasks);
    },
    [maintenanceTasks, reloadObject]
  );

  return (
    <div>
      {showGetStarted ? (
        <GetStartedCircle
          title={maintenanceTitle}
          onGetStartedClicked={() => setIsModalOpen(true)}
          className="flex justify-center items-center"
          disabled={isLoading}
        />
      ) : (
        buildTasksByYearArray(workingTasks)
          ?.filter((info) => {
            const currentYear = Number(moment(Date.now()).format("YYYY"));
            return info.year <= currentYear;
          })
          .map((info, index) => {
            return (
              <MaintenanceCard
                key={info?.year}
                data={info}
                defaultOpen={index === 0}
                currentUser={currentUser}
                onDeletedTask={handleOnDeletedTask}
                membersOptions={membersOptions}
                reloadObject={reloadObject}
                onEditedTask={handleOnEditedTask}
                users={users}
                isTaskDurationRequired={isTaskDurationRequired}
                association={association}
              />
            );
          })
      )}
    </div>
  );
};

FormMaintenance.defaultProps = {
  isLoading: true,
  showGetStarted: true,
};

export default FormMaintenance;

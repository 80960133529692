import moment from "moment";
import { SUBMITTAL_STATUS_TYPES } from "../constants";
import { formatRequestStatus } from "./Formatters";

export const sortActivity = (a, b) => {
  return moment
    .utc(a.metadata.lastUpdated)
    .diff(moment.utc(b.metadata.lastUpdated));
};

export const formatHistoryCardData = (
  roundData,
  index,
  submittal,
  submittalHistory,
  userDict
) => {
  // submittal round
  const roundNum = roundData?.round;

  // if the round matches index plus 1 it is the current round and has not been posted to submittal history yet
  const isCurrentRound = submittal?.rounds?.length === index + 1;

  // snapshot of workflow during that round
  const stepRequestWorkflow = isCurrentRound
    ? submittal?.requestWorkflow?.[0]
    : roundData?.requestWorkflow[index];

  // all attachments present during that round, cumulative
  const roundAttachments = [
    ...(stepRequestWorkflow?.steps?.map((step) => [
      ...(step?.attachments || []),
    ]) || []),
  ]
    .flat(Infinity)
    ?.filter((ref) => !!ref);

  // all actions taken during that round, from history api
  const roundActions = submittalHistory?.[roundData?.round];

  // first action that was taken on the round
  const durationStart = roundData?.startDate;

  // last action that was taken on the round
  const durationEnd = roundData?.endDate;

  const durationDays =
    durationEnd && moment(durationEnd).diff(moment(durationStart), "days") + 1;

  // all users that took action on the round by reference
  const roundMembers = [
    ...(stepRequestWorkflow?.steps?.map((step) => [
      ...(step?.users?.map((user) => user?.reference) || []),
    ]) || []),
  ]
    .flat(Infinity)
    ?.filter((ref) => !!ref);
  // only users, no duplicates
  const roundUsers = [...new Set(roundMembers)]?.map(
    (user) => userDict?.[user]
  );

  const initiatedBy = userDict?.[roundData?.initiatedBy];

  // maps step ids in action to the steps of that round by id, so all step data for the round is present
  const withSteps = roundActions?.reduce((acc, action) => {
    const step = stepRequestWorkflow?.steps?.find(
      (item) => item.id === action?.context?.submittal?.step?.id
    );
    // only show history items with step i.e. within rounds
    if (step) {
      // eslint-disable-next-line no-param-reassign
      acc = [...acc, { ...action, step }];
    }
    return acc;
  }, []);

  // sorts the activity in order of action taken, first by initiated then by created at
  const sortedActivity = withSteps?.sort(sortActivity);
  const actionsByStepMap =
    sortedActivity?.reduce((acc, curr) => {
      const key = curr.step?.position || 1;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(curr);
      return acc;
    }, {}) || {};

  return {
    roundNum,
    roundAttachments,
    durationStart,
    durationEnd,
    durationDays,
    roundUsers,
    isCurrentRound,
    actionsByStepMap,
    initiatedBy,
  };
};

const getBicUsers = (resource, userDict) => {
  if (
    resource?.status !== SUBMITTAL_STATUS_TYPES.DRAFT &&
    resource?.status !== SUBMITTAL_STATUS_TYPES.COMPLETED_RESOLVED &&
    resource?.status !== SUBMITTAL_STATUS_TYPES.WF_COMPLETED_OPEN
  ) {
    return resource?.ballInCourt?.map(
      ({ user }) => userDict?.[user] || undefined
    );
  }
  return [];
};

export const formatTableData = (
  requestArray,
  config,
  userDict,
  projectDict
) => {
  const submittalTypes = config?.management?.submittal?.types;

  const formattedTableData = requestArray?.map((item) => {
    const type = submittalTypes?.find((subType) => subType?.id === item?.type);
    const project = projectDict?.[item?.association];
    const bicUsers = getBicUsers(item, userDict);
    const initDate = moment(item?.initiated?.date).format("DD MMMM, YYYY");
    const initUser = userDict?.[item?.initiated?.user];
    const start = moment(item?.startDate?.actual).format("DD MMMM, YYYY");
    const targetComp = moment(item?.completion?.target).format("DD MMMM, YYYY");
    const projectedComp = moment(item?.completion?.projected)
      .endOf("day")
      .format("DD MMMM, YYYY");
    const today = moment().endOf("day").format();
    const overOrUnder = moment(today).diff(projectedComp, "days");
    const budgetImpact = item?.impacts?.budget?.status || "unspecified";
    const scheduleImpact = item?.impacts?.schedule?.status || "unspecified";
    const cost = item?.impacts?.budget?.value;
    const days = item?.impacts?.schedule?.value;
    const status = formatRequestStatus(item);

    return {
      id: item?.id,
      title: item?.customName,
      type: type?.display,
      project: project?.name,
      association: item?.association,
      ballInCourt: bicUsers,
      description: item?.description,
      status,
      initiated: item?.status !== SUBMITTAL_STATUS_TYPES.DRAFT ? initDate : "",
      initiatedBy:
        item?.status !== SUBMITTAL_STATUS_TYPES.DRAFT ? { user: initUser } : {},
      startDate: start,
      vendor: initUser?.companyName,
      duration: `${item?.duration?.numDays} Days`,
      targetCompletion: targetComp,
      projectedCompletion: projectedComp,
      blocked: "n/a",
      overUnder: `${
        overOrUnder <= 0
          ? `${Math.abs(overOrUnder)} days left`
          : `${overOrUnder} days overdue`
      }`,
      scheduleRef: scheduleImpact,
      budgetRef: budgetImpact,
      costImpact: cost ? `$ ${cost}` : "unspecified",
      scheduleImpact: days > 1 ? `${days || 0} Days` : `${days || 0} day`,
      completed: `${item?.status?.includes("completed") ? "yes" : "no"}`,
    };
  });
  return formattedTableData;
};

import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";

const MediaVideo = ({ video, thumb }) => {
  const videoRef = useRef(null);
  const [playing, setPlaying] = useState(false);

  const handleVideoPlayback = useCallback(() => {
    if (videoRef.current?.paused) {
      videoRef.current?.play();
      setPlaying(true);
    } else {
      videoRef.current?.pause();
      setPlaying(false);
    }
  }, []);

  return (
    <div className="relative">
      <video
        className="object-cover mx-auto h-full rounded"
        playsInline
        autoPlay={false}
        preload="metadata"
        ref={videoRef}
      >
        <track kind="captions" />
        <source
          src={`${video.original}#t=0.5`}
          type={
            video.contentType === "video/quicktime"
              ? "video/mp4"
              : video.contentType
          }
        />
        Your browser does not support the video tag.
      </video>
      {!thumb && (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 100 100"
          className="absolute top-0 left-0"
          onClick={() => !thumb && handleVideoPlayback()}
        >
          {!playing && !thumb ? (
            <polygon
              points="40,40 60,50 40,60"
              fill="gray"
              rx="10"
              strokeLinejoin="round"
              stroke="gray"
              strokeWidth="5"
              opacity="0.5"
            />
          ) : (
            <>
              <rect
                x="35"
                y="35"
                rx="1.25"
                width="10"
                height="30"
                fill="gray"
                opacity="0.4"
              />
              <rect
                x="55"
                y="35"
                rx="1.25"
                width="10"
                height="30"
                fill="gray"
                opacity="0.4"
              />
            </>
          )}
        </svg>
      )}
    </div>
  );
};

MediaVideo.defaultProps = {
  thumb: false,
  video: undefined,
};

MediaVideo.propTypes = {
  thumb: PropTypes.bool,
  video: PropTypes.shape({
    original: PropTypes.string,
    contentType: PropTypes.string,
  }),
};

export default MediaVideo;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import RadioButton from "../Buttons/RadioButton";
import Modal from "../Modal/Modal";

const YESOPTION = "YES";
const NOOPTION = "NO";

const AssociationWFModal = ({
  workflowData,
  isOpen,
  onClose,
  handleCreateAssociatedWF,
}) => {
  const [associatedWF, setAssociatedWF] = useState(null);

  useEffect(() => {
    if (!isOpen) {
      setAssociatedWF(null);
    }
  }, [isOpen]);

  const handleSubmit = () => {
    const assocatedState = {
      workflow: workflowData,
      status: associatedWF === YESOPTION ? "contingent" : "non-contingent",
    };
    handleCreateAssociatedWF(assocatedState);
  };

  return (
    <Modal
      isOpen={isOpen}
      title="CREATE ASSOCIATED WORKFLOW"
      onRequestModalClose={onClose}
      hideFooter
      primaryButtonTitle="Next"
      primaryButtonOnClick={handleSubmit}
      tertiaryButtonTitle="Cancel"
      disabled={!associatedWF}
    >
      <div className="flex flex-col">
        <div>
          <p className="pb-4">
            Will this new workflow need to be resolved before{" "}
            <strong>{workflowData.name}</strong> may proceed?
          </p>
          <div className="mb-3">
            <RadioButton
              label="Yes"
              isChecked={associatedWF === YESOPTION}
              onChange={() => setAssociatedWF(YESOPTION)}
            />
          </div>
          <RadioButton
            label="No"
            isChecked={associatedWF === NOOPTION}
            onChange={() => setAssociatedWF(NOOPTION)}
          />
        </div>
      </div>
    </Modal>
  );
};
AssociationWFModal.propTypes = {
  /**
   * workflow that is selected
   */
  workflowData: PropTypes.shape({
    name: PropTypes.string,
  }),
  /**
   * bool if modal is open
   */
  isOpen: PropTypes.bool,
  /**
   * function to handle sending model close
   */
  onClose: PropTypes.func,
  /**
   * function to handle creating an assocated WF
   */
  handleCreateAssociatedWF: PropTypes.func,
};

AssociationWFModal.defaultProps = {
  workflowData: {},
  isOpen: undefined,
  onClose: undefined,
  handleCreateAssociatedWF: undefined,
};
export default AssociationWFModal;

import React from "react";
import PropTypes from "prop-types";
import { useAppState } from "../../../../state/appState";
import ImageCell from "../../../../stories/Components/Table/ImageCell";

const MessageUserAvatar = ({ userId, reply, count }) => {
  const [{ usersStreamDict }] = useAppState();

  return (
    <>
      <div
        className="relative flex"
        style={{ width: "max-content", minWidth: "2rem", maxWidth: "2rem" }}
      >
        <ImageCell
          className="w-full h-8 rounded-full object-cover"
          src={usersStreamDict?.[userId]?.avatar}
        />
        {!reply && count > 0 && (
          <div
            className="absolute border-l border-b border-gray-300"
            style={{
              height: "calc(100% - 3.05rem)",
              top: "2.65rem",
              left: "50%",
              width: "75%",
            }}
          />
        )}
      </div>
    </>
  );
};

MessageUserAvatar.propTypes = {
  userId: PropTypes.string,
  reply: PropTypes.bool,
  count: PropTypes.number,
};

MessageUserAvatar.defaultProps = {
  userId: undefined,
  reply: false,
  count: 0,
};

export default MessageUserAvatar;

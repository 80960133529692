/* eslint-disable no-param-reassign */
import cntl from "cntl";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import {
  ADD_OPEN_MODAL,
  PAYROLL_CSV_COLUMNS_MODAL,
  SETTINGS_PAYROLL_CSV_COLUMNS,
} from "../../../../constants";
import { toastError, toastMessage } from "../../../../helpers/Toast";
import createNonSubmittedTimesheet from "../../../../helpers/Users/createNonSubmittedTimesheet";
import findEmployeesWithoutTimesheets from "../../../../helpers/Users/findEmployeesWithoutTimesheets";
import removeDuplicateRef from "../../../../helpers/Utilities/removeDuplicateRef";
import { useProjectsOverview } from "../../../../hooks/projects";
import useEmployeeTimeSheets from "../../../../hooks/useEmployeeTimeSheets";
import useFinancialsConfiguration from "../../../../hooks/useFinancialsConfiguration";
import usePayroll from "../../../../hooks/usePayroll";
import usePayrollReducer from "../../../../hooks/usePayrollReducer";
import useSettings from "../../../../hooks/useSettings";
import { useUsers } from "../../../../hooks/useUsers.new";
import { useAppState } from "../../../../state/appState";
import { useModalState } from "../../../../state/modalState";
import PrimaryButton from "../../../../stories/Components/Buttons/PrimaryButton";
import Modal from "../../../../stories/Components/Modal/Modal";
import PayrollTable from "../../../../stories/Components/TimeSheetPayrollTable/PayrollTable";
import whiteCircleCheckIcon from "../../../../stories/assets/images/circleCheckIcon.svg";
import whiteCrossIcon from "../../../../stories/assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../../../stories/assets/images/whiteExclamationIcon.svg";
import usePayrollData from "./usePayrollViewData";
import useEditTimesheetsList from "../../../../hooks/timesheets/useEditTimesheetsList";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const PageCN = cntl`
  lg:flex
  flex
  flex-initial
  flex-col
  mb-12
`;

const Header = ({ buttons, extras, search, className }) => {
  return (
    <div className={`${className} flex`}>
      <div className="flex flex-col w-1/2"> {extras}</div>
      <div className="flex flex-col w-1/2 self-end">
        <div className="flex justify-end">{buttons}</div>
        <div className="self-end">{search}</div>
      </div>
    </div>
  );
};

const PayrollView = ({ currentUser, usersDict }) => {
  const [
    {
      pageState,
      timesheetstate: { approvalDate: date },
    },
  ] = useAppState();

  const { periodStart, periodEnd } = pageState.timesheet;

  const payPeriod = useMemo(() => {
    return { periodStart, periodEnd };
  }, [periodStart, periodEnd]);

  const { data } = useUsers();
  const employees = data?.employees;

  const { financials, periodFrame, payrollHistory, reload, reloadHistory } =
    usePayroll({
      users: usersDict,
      permission: currentUser?.hasPermission(
        "corporate",
        "can_approve_payroll"
      ),
      currentUser,
    });

  const { timesheetsLoading, timeSheets, getPTO } = usePayrollData();
  const { editTimesheetsListStatus } = useEditTimesheetsList();
  const { pto } = useEmployeeTimeSheets(undefined, date);
  const [{ userDict, ptoLocationsLibrary }] = useAppState();
  const { projectDict } = useProjectsOverview();
  const { data: financialsConfiguration } = useFinancialsConfiguration();
  const [editedTimesheets, dispatch] = usePayrollReducer();
  const [selectedRows, setSelectedRows] = useState([]);
  const warnings = useRef([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [submitValues, setSubmitValues] = useState();
  const [, update] = useSettings(SETTINGS_PAYROLL_CSV_COLUMNS);
  const [, modalDispatch] = useModalState();

  const csiCodes = useMemo(
    () => financialsConfiguration?.financials?.csiCodeMappingObject,
    [financialsConfiguration?.financials?.csiCodeMappingObject]
  );

  const reset = useCallback(() => {
    dispatch({
      type: "reset",
      timesheets: timeSheets,
    });
  }, [dispatch, timeSheets]);

  useEffect(() => {
    reset();
  }, [reset]);

  const openConfirmModal = useCallback((action, index) => {
    setSubmitValues({ action, index });
    setConfirmModal(true);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setSubmitValues();
    setConfirmModal(false);
  }, []);

  const submitTimeSheets = useCallback(async () => {
    const onlyApprovedEntries = selectedRows?.filter(
      (item) => item.payrollStatus !== "status"
    );

    const editTsStatusProps = {
      items: onlyApprovedEntries,
      action: "$approvePayroll",
    };

    try {
      await editTimesheetsListStatus(editTsStatusProps);
      toastMessage("Succefully saved payroll", toastIcon, toastCloseIcon);
      reloadHistory();
      setSelectedRows([]);
    } catch (error) {
      console.error("Error saving payroll ==>", error);
      toastError("Error saving payroll", toastIcon, toastCloseIcon);
    }
  }, [editTimesheetsListStatus, reloadHistory, selectedRows]);

  const memoizedEditedTimesheets = useMemo(() => {
    if (timesheetsLoading) return [];

    const employeesWithputTimesheets = findEmployeesWithoutTimesheets(
      employees,
      timeSheets
    );

    const convertedToTimesheets = employeesWithputTimesheets?.map((employee) =>
      createNonSubmittedTimesheet(employee)
    );

    const tsWithoutDuplicates = removeDuplicateRef(editedTimesheets);

    const arrToReturn = [...tsWithoutDuplicates, ...convertedToTimesheets].map(
      (sheet, index) => {
        return {
          ...sheet,
          index,
          id: index,
        };
      }
    );

    return arrToReturn;
  }, [editedTimesheets, employees, timeSheets, timesheetsLoading]);

  const handlePayrollToCsv = useCallback(
    async (byProject = false, selectedCsvColumns) => {
      if (timesheetsLoading) return;

      try {
        const res = await getPTO();
        const requestsByUserMap = {};

        res.forEach((ptoResult) => {
          const { data: resultData } = ptoResult;
          if (resultData?.entries.length) {
            requestsByUserMap[resultData?.entries[0]?.resource?.user] =
              resultData?.entries.map((e) => e.resource);
          }
        });

        // lazy load the convertToCsv module so it only runs when needed
        const module = await import(
          "../../../../helpers/Timesheets/convertPayrollToCsv"
        );

        // extract the convertPayrollToCsv function from modules default
        const convertPayrollToCsv = module.default;

        convertPayrollToCsv({
          timesheets: memoizedEditedTimesheets,
          csvColumns: selectedCsvColumns,
          payrollMap: payrollHistory,
          csiCodes,
          userDict,
          projectDict,
          requestsByUserMap,
          payPeriod,
          ptoLocationsLibrary,
          byProject,
        });

        toastMessage("Payroll Downloaded", toastIcon, toastCloseIcon);

        // Run the update function to update the settings in a non-blocking way
        (async () => {
          try {
            await update({
              key: SETTINGS_PAYROLL_CSV_COLUMNS,
              value: selectedCsvColumns,
            });
          } catch (err) {
            /**
             * launching this asynchronous code in an IEFE lets us update the
             * settings in a non-blocking way, without sacraficing error handling
             */
            console.error(err);
            toastError(
              "Unable to Download Payroll, try again. If error persists try refreshing the page.",
              toastErrorIcon,
              toastCloseIcon
            );
          }
        })();
      } catch (err) {
        console.error(err);
        toastError(
          "Unable to Download Payroll, try again. If error persists try refreshing the page.",
          toastErrorIcon,
          toastCloseIcon
        );
      }
    },
    [
      timesheetsLoading,
      getPTO,
      memoizedEditedTimesheets,
      payrollHistory,
      csiCodes,
      userDict,
      projectDict,
      payPeriod,
      ptoLocationsLibrary,
      update,
    ]
  );

  const primaryActions = useMemo(() => {
    let options = [];

    if (
      selectedRows.length !== 0 &&
      selectedRows?.find((item) => item.payrollStatus !== "approved")
    ) {
      options = [
        {
          title: "Approve",
          onClick: () => openConfirmModal("approved"),
        },
      ];
    }

    if (memoizedEditedTimesheets?.length !== 0) {
      options.push({
        title: "Download CSV",
        onClick: () => {
          modalDispatch({
            type: ADD_OPEN_MODAL,
            ref: { id: uuidv4() },
            modalData: {
              onClick: (val) => {
                handlePayrollToCsv(false, val);
              },
            },
            modalType: PAYROLL_CSV_COLUMNS_MODAL,
          });
        },
      });
      options.push({
        title: " Download CSV By Project",
        onClick: () => {
          modalDispatch({
            type: ADD_OPEN_MODAL,
            ref: { id: uuidv4() },
            modalData: {
              onClick: async (val) => {
                await handlePayrollToCsv(true, val);
              },
            },
            modalType: PAYROLL_CSV_COLUMNS_MODAL,
          });
        },
      });
    }
    return options;
  }, [
    handlePayrollToCsv,
    memoizedEditedTimesheets,
    modalDispatch,
    openConfirmModal,
    selectedRows,
  ]);

  const primaryButtonActionsDisabled = useMemo(() => {
    return [memoizedEditedTimesheets]?.length === 0 || timesheetsLoading;
  }, [memoizedEditedTimesheets, timesheetsLoading]);

  const numberOfApproved = timeSheets?.filter(
    (item) => item.payrollStatus === "approved"
  );

  const customSiteHeader = useCallback(
    () => (
      <Header
        className="items-center h-full w-1/2"
        buttons={
          <>
            {numberOfApproved && !timesheetsLoading && (
              <div className="flex flex-row border border-gray-450 rounded-md mr-4 justify-center items-center">
                <p className="flex text-gray-450 w-full text-base font-semibold px-3">
                  {`${numberOfApproved?.length} / ${employees?.length} Approved`}
                </p>
              </div>
            )}
            <PrimaryButton
              title={
                selectedRows.length === 0
                  ? "Actions"
                  : `(${selectedRows?.length}) Selected`
              }
              large
              dropdownItems={primaryActions}
              disabled={primaryButtonActionsDisabled}
              className="whitespace-nowrap"
            />
          </>
        }
      />
    ),
    [
      employees?.length,
      numberOfApproved,
      primaryActions,
      primaryButtonActionsDisabled,
      selectedRows.length,
      timesheetsLoading,
    ]
  );

  return (
    <div className={PageCN}>
      <PayrollTable
        financials={financials}
        periodFrame={periodFrame}
        currentUser={currentUser}
        timesheets={memoizedEditedTimesheets}
        payrollHistory={payrollHistory}
        loading={timesheetsLoading}
        reload={reload}
        pto={pto}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        warnings={warnings}
        handleEditRow={openConfirmModal}
        customSiteHeader={customSiteHeader}
      />
      <Modal
        title="Approve?"
        isOpen={confirmModal}
        alert
        primaryButtonTitle="Approve Payroll"
        primaryButtonOnClick={() => {
          submitTimeSheets(submitValues?.action, submitValues?.index);
          closeConfirmModal();
        }}
        titleStyle={{
          color: "#FFFFFF",
        }}
        headerStyle={{
          background: "#626262",
          color: "#FFF",
          border: "none",
        }}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={() => closeConfirmModal()}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <>
          <p className="text-base mb-2">
            This action cannot be undone, are you sure?
          </p>
        </>
      </Modal>
    </div>
  );
};

PayrollView.propTypes = {
  currentUser: PropTypes.shape({
    hasPermission: PropTypes.func,
    reference: PropTypes.string,
  }),
  usersDict: PropTypes.shape({}),
};

PayrollView.defaultProps = {
  currentUser: undefined,
  usersDict: undefined,
};

export default PayrollView;

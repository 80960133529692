import React from "react";
import Dropdown from "../../../Dropdown/Dropdown";
import useTagFilterData from "./useTagFilterData";

const TagsFilter = ({
  currentCol,
  stagedFilters,
  setStagedFilters,
  allColumns,
  data,
}) => {
  const {
    filterOptions,
    filterValue,
    tagsOptions,
    tagsSelected,
    handleChangeFilterOptions,
    handleSelectedTags,
  } = useTagFilterData({
    allColumns,
    data,
    currentCol,
    stagedFilters,
    setStagedFilters,
  });

  return (
    <div className="flex min-w-0 pb-2 space-x-4">
      <div className="flex w-1/2">
        <Dropdown
          placeholder="Choose"
          options={filterOptions}
          value={filterValue}
          onChange={handleChangeFilterOptions}
        />
      </div>

      <div className="flex w-1/2">
        <Dropdown
          menuPlacement="bottom"
          isMulti
          options={tagsOptions}
          value={tagsSelected}
          onChange={handleSelectedTags}
          disableClear
        />
      </div>
    </div>
  );
};

export default TagsFilter;

import moment from "moment-timezone";
import { MOMENT_CALENDAR_FORMAT } from "../../constants";
import {
  getDaySpan,
  getDaySpanForNonWrappingEvents,
  isFullDay,
  isMultiDayEvent,
} from "../Calendar";
import handleAddMultiWeekItem from "./handleMultiWeekEvent";

const formatUserEvents = (events = [], calendarTimezone) => {
  let newUserEvents = {};
  if (calendarTimezone) {
    events.forEach((event) => {
      const userEventsDayKey = moment
        .tz(event.resource.startDate, calendarTimezone)
        .startOf("day")
        .format(MOMENT_CALENDAR_FORMAT);

      const dayToEdit = newUserEvents[userEventsDayKey] || {
        allDay: [],
        brief: [],
      };

      const eventTimezone =
        event.resource.timezone?.value || event.resource.timezone;

      const isFullOrMultiDayEvent =
        isFullDay(
          event.resource.startDate,
          event.resource.endDate,
          event.resource.allDay
        ) ||
        isMultiDayEvent(
          event.resource.startDate,
          event.resource.endDate,
          eventTimezone
        );

      if (isFullOrMultiDayEvent) {
        const itemWraps = moment
          .tz(event.resource.endDate, calendarTimezone)
          .isAfter(
            moment.tz(event.resource.startDate, calendarTimezone).endOf("week")
          );

        if (itemWraps) {
          newUserEvents = handleAddMultiWeekItem({
            event: event.resource,
            isFullDayEvent: true,
            startDate: event.resource.startDate,
            endDate: event.resource.endDate,
            userEvents: newUserEvents,
            userEventUpdates: newUserEvents,
            calendarTimezone,
          });

          const daySpan = getDaySpan(
            moment.tz(event.resource.startDate, calendarTimezone).format(),
            moment
              .tz(event.resource.startDate, calendarTimezone)
              .endOf("week")
              .format(),
            calendarTimezone
          );

          dayToEdit.allDay.push({
            ...event.resource,
            daySpan: daySpan + 1,
            original: event.resource,
          });
        } else {
          // get the umber of days difference between the start and end date
          const daySpan = getDaySpanForNonWrappingEvents(
            event,
            calendarTimezone
          );

          dayToEdit.allDay.push({
            ...event.resource,
            daySpan,
            original: event.resource,
          });
        }
      } else {
        dayToEdit.brief.push({ ...event.resource, original: event.resource });
      }

      newUserEvents[userEventsDayKey] = dayToEdit;
    });
  } else {
    events.forEach((event) => {
      const userEventsDayKey = moment(event.resource.startDate)
        .startOf("day")
        .format(MOMENT_CALENDAR_FORMAT);

      const dayToEdit = newUserEvents[userEventsDayKey] || {
        allDay: [],
        brief: [],
      };

      if (
        isFullDay(
          event.resource.startDate,
          event.resource.endDate,
          event.resource.allDay
        )
      ) {
        const itemWraps = moment(event.resource.endDate).isAfter(
          moment(event.resource.startDate).endOf("week")
        );

        if (itemWraps) {
          newUserEvents = handleAddMultiWeekItem({
            event: event.resource,
            isFullDayEvent: true,
            startDate: event.resource.startDate,
            endDate: event.resource.endDate,
            userEvents: newUserEvents,
            userEventUpdates: newUserEvents,
          });

          const daySpan = getDaySpan(
            event.resource.startDate,
            moment(event.resource.startDate).endOf("week").format(),
            calendarTimezone
          );

          dayToEdit.allDay.push({
            ...event.resource,
            daySpan: daySpan + 1,
            original: event.resource,
          });
        } else {
          // get the umber of days difference between the start and end date
          const daySpan = getDaySpan(
            event.resource.startDate,
            event.resource.endDate,
            calendarTimezone
          );
          dayToEdit.allDay.push({
            ...event.resource,
            daySpan: daySpan + 1,
            original: event.resource,
          });
        }
      } else {
        dayToEdit.brief.push({ ...event.resource, original: event.resource });
      }

      newUserEvents[userEventsDayKey] = dayToEdit;
    });
  }

  return newUserEvents;
};

export default formatUserEvents;

const getUpdatedTaskSteps = (steps, editedStep, newStatus) => {
  const updatedSteps = JSON.parse(JSON.stringify(steps));
  return updatedSteps.map((step) => {
    if (step?.id !== editedStep?.stepData?.id) {
      return step;
    }
    // mark sop individual steps
    if (editedStep?.steps?.length) {
      // eslint-disable-next-line no-param-reassign
      step.sopStepStatus = editedStep.steps.map((sopStep) => {
        return {
          id: sopStep.id,
          name: sopStep.description,
          isCompleted: newStatus === "Yes",
        };
      });
    }

    return { ...step, isCompleted: newStatus === "Yes" };
  });
};

export default getUpdatedTaskSteps;

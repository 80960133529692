import React from "react";
import { priorityImage } from "../../../helpers/priorityDropDownHelpers";
import capitalizeFirstLetter from "../../../helpers/Utilities/capitalizeFirstLetter";

const FormatedPriorityLevelLabel = ({
  priority,
  textCN = "text-gray-450 text-sm",
}) => {
  return (
    <div className="flex flex-row justify-start items-center cursor-pointer gap-2">
      <img
        src={priorityImage(priority)}
        alt="priority type"
        className="shadow-light-lift w-5 h-5 rounded-full"
      />
      <p className={textCN}>{capitalizeFirstLetter(priority)}</p>
    </div>
  );
};

export default FormatedPriorityLevelLabel;

import { useCallback, useEffect } from "react";
import { UserAPI } from "@griffingroupglobal/eslib-api";
import { useAppState } from "../state/appState";
import { SET_DIRECT_REPORTS, SET_DIRECT_REPORT_OPTIONS } from "../constants";
import { getFullName } from "../helpers/Formatters";

export default () => {
  const [
    { rolesDict, directReports, directReportOptions, userDict },
    dispatch,
  ] = useAppState();

  const reload = useCallback(async () => {
    try {
      const { data: directReportData } = await UserAPI.getWOP("$directreport");

      /* ** get role category options ** */
      const roleOptions = [];
      directReportData?.role.forEach((roleRef) => {
        const roleData = rolesDict?.[roleRef];

        if (roleData?.reference) {
          roleOptions?.push({
            label: roleData?.name,
            value: roleData?.reference,
            kind: "role",
          });
        }
      });

      /* ** get user category options ** */
      const userOptions = [];
      directReportData?.user?.forEach((userRef) => {
        const user = userDict?.[userRef];
        if (user?.kind !== "company") {
          userOptions?.push({
            label: getFullName(user?.name),
            value: user?.reference,
          });
        }
      });

      dispatch({
        type: SET_DIRECT_REPORTS,
        directReports: directReportData,
      });

      dispatch({
        type: SET_DIRECT_REPORT_OPTIONS,
        directReportOptions: [
          {
            label: "Roles",
            options: roleOptions,
          },
          {
            label: "Users",
            options: userOptions,
          },
        ],
      });
    } catch (error) {
      console.warn(error.message);
    }
  }, [rolesDict, dispatch, userDict]);

  useEffect(() => {
    reload();
  }, [reload]);

  return [directReports, directReportOptions, reload];
};

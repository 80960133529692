import React from "react";
import PropTypes from "prop-types";
import useAlertSeverityData from "./useAlertSeverity";
import alertIcon from "../../assets/images/alert.svg";

const AlertSeverity = ({ severity, message }) => {
  const { backgroundClassColor } = useAlertSeverityData({ severity });

  return (
    <div className={`p-2 rounded-lg ${backgroundClassColor}`}>
      <div className="flex items-start gap-2">
        <img src={alertIcon} alt="severity" />
        <span className="text-sm font-light">{message}</span>
      </div>
    </div>
  );
};

AlertSeverity.defaultProps = {
  message: "",
  severity: "error",
};

AlertSeverity.propTypes = {
  message: PropTypes.string,
  severity: PropTypes.oneOf(["error", "warning", "success"]),
};

export default AlertSeverity;

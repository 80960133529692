import { useCallback } from "react";
import moment from "moment";
import { useAppState } from "../state/appState";
import useServiceRequestById from "./useServiceRequestById";
import { getFullName } from "../helpers/Formatters";
import calculateDayDifference from "../helpers/Date/calculateDayDifference";
import { SERVICE_REQUEST_STATUS } from "../constants";
import { usePropertiesOverview } from "./properties";
import { useAssetsOverview } from "./assets";
import { useProjectsOverview } from "./projects";

/**
 * Query selector to return Ticket data in custom format
 * @param {string} serviceRequestId service request id to fetch
 * @returns ticket object in custom format:
 * original = raw data from backend
 * readOnly = transfomed data with associations, user information, etc
 */
const useServiceRequestSelectorById = (serviceRequestId) => {
  const [{ userDict }] = useAppState();

  const { assetsDict } = useAssetsOverview();
  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  const select = useCallback(
    (ticket) => {
      const user = userDict?.[ticket.createdBy];
      const completedBy = userDict?.[ticket.completedBy];

      const associationSelected =
        propertiesDict?.[ticket.association] ||
        projectDict?.[ticket.association];

      const assetSelected = assetsDict[ticket?.asset];

      return {
        original: ticket,
        readOnly: {
          ...ticket,
          createdAt: moment(ticket.metadata.createdAt).format("MMMM DD, YYYY"),
          createdBy: getFullName(user?.name),
          createdByFullUser: user,
          asset: assetSelected?.name,
          association:
            (associationSelected?.name ?? associationSelected?.title) || "N/A",
          status: SERVICE_REQUEST_STATUS[ticket.status],
          onBehalf: ticket.onBehalf || "N/A",
          completedBy: completedBy ? getFullName(completedBy.name) : "TBD",
          completedByFullUser: completedBy || {},
          duration: ticket?.completedDate
            ? calculateDayDifference(
                ticket.metadata.createdAt,
                ticket.completedDate
              )
            : "TBD",
        },
      };
    },
    [userDict, projectDict, propertiesDict, assetsDict]
  );

  return useServiceRequestById(serviceRequestId, select);
};

export default useServiceRequestSelectorById;

import cntl from "cntl";
import React from "react";
import PropTypes from "prop-types";
import ProjectEditField from "./ProjectEditField";
import Checkbox from "../Checkbox/Checkbox";
import Dropdown from "../Dropdown/Dropdown";
import { reformatCurrencyString } from "../../../helpers/Formatters";
import { calculateInvoices } from "../../../helpers/Project";
import Widget from "../Widget/Widget";

const staticSubTitle = (width) => cntl`
font-bold
capitalize
${width}
`;

const fieldWrapperCN = (last) => cntl`
flex
w-full
items-center
h-10
${!last && "border-b"}
`;

const ProjectDeposit = ({ project, isEditing, dispatch }) => {
  const deposit = project?.deposit;

  const handleChangeDeposit = (val) => {
    dispatch({
      type: "edit2Multiple",
      key1: "deposit",
      keys2: ["amount", "invoices"],
      values: [val, calculateInvoices(val, project?.deposit?.numInvoices)],
    });
  };

  const handleChangeReimbursement = (val) => {
    dispatch({
      type: "edit2Multiple",
      key1: "deposit",
      keys2: ["reimburseClient", "numInvoices", "invoices"],
      values: [
        val,
        deposit?.reimburseClient ? deposit?.numInvoices : undefined,
        deposit?.reimburseClient ? deposit?.invoices : [],
      ],
    });
  };

  const handleChangeNumInvoices = (val) => {
    dispatch({
      type: "edit2Multiple",
      key1: "deposit",
      keys2: ["numInvoices", "invoices"],
      values: [val, calculateInvoices(deposit?.amount, val)],
    });
  };

  return (
    <Widget draggable={false} title="Deposit" overflow className="mb-4">
      <div className="flex flex-col w-full">
        <ProjectEditField
          isEditing={isEditing}
          title="Total Amount"
          value={deposit?.amount}
          handleChange={handleChangeDeposit}
          placeHolder="Enter deposit amount..."
          isDollarValue
          lastField
          fieldPosition="start"
          titleClassName="w-1/3"
        />
        <Checkbox
          label="Reimburse client"
          checked={deposit?.reimburseClient}
          onChange={handleChangeReimbursement}
          disabled={!isEditing}
        />
        {deposit?.reimburseClient && (
          <div className="w-1/2 mt-4">
            {isEditing && (
              <Dropdown
                options={[...Array(12).keys()].map((item) => ({
                  label: `${item + 1} invoice(s)`,
                  value: item + 1,
                }))}
                value={
                  deposit?.numInvoices && {
                    label: `${deposit?.numInvoices} invoice(s)`,
                    value: deposit?.numInvoices,
                  }
                }
                disableClear
                disableSort
                label="Payment distribution"
                onChange={(val) => handleChangeNumInvoices(val.value)}
              />
            )}
            {!isEditing && (
              <p className="text-gray-200 ml-4">{`${
                deposit?.numInvoices ?? 0
              } invoice(s)`}</p>
            )}
          </div>
        )}
        {deposit?.invoices?.length > 0 && (
          <div className="flex w-full mt-4">
            <p className={staticSubTitle("w-4/6")}>Invoice #</p>
            <p className={staticSubTitle("w-2/6")}>Amount Due</p>
          </div>
        )}
        <div className="w-full mt-4">
          {deposit?.reimburseClient &&
            deposit?.invoices?.map((item, i) => {
              return (
                <div
                  key={item?.number}
                  className={fieldWrapperCN(
                    i === deposit?.invoices?.length - 1
                  )}
                >
                  <p className="text-gray-200 w-4/6">{item?.number}</p>
                  <p className="text-gray-200 w-2/6">{`$ ${reformatCurrencyString(
                    item?.amount
                  )}`}</p>
                </div>
              );
            })}
        </div>
      </div>
    </Widget>
  );
};

ProjectDeposit.propTypes = {
  project: PropTypes.shape({
    deposit: PropTypes.shape({
      numInvoices: PropTypes.number,
    }),
  }),
  isEditing: PropTypes.bool,
  dispatch: PropTypes.func,
};

ProjectDeposit.defaultProps = {
  project: undefined,
  isEditing: false,
  dispatch: undefined,
};

export default ProjectDeposit;

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import PureDocumentTable from "./PureDocumentTable";
import { financialDocumentsType } from "../../../constants";

const DocumentTable = ({
  className,
  documents,
  favorites,
  selectedDocuments,
  onFileSelect,
  onRowClick,
  onFileEdit,
  onShowHistory,
  onSendToWorkflow,
  onUploadVersion,
  onFileDelete,
  onFavoriteClick,
  attaching,
  hideSiteHeader,
  hideChangeView,
  hideSaveButton,
  disableHover,
  enableDeleting,
  noDeleteConfirm,
  showEditColumns,
  showSearch,
  showFooter,
  allowSelection,
  extraColumns,
  hideButtons,
  buttonActions,
  bannerComponent,
  showDeleteButton,
  resourceName,
  docTypeOptionsMap,
}) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (attaching) {
      setTableData(
        documents?.map((doc) => ({
          ...doc,
          hideSelection: financialDocumentsType[doc.docType],
        }))
      );
    } else {
      setTableData(
        documents
          .filter((doc) => !selectedDocuments.find((id) => id === doc.id))
          ?.map((doc) => ({
            ...doc,
            hideSelection: financialDocumentsType[doc.docType],
          }))
      );
    }
  }, [attaching, documents, selectedDocuments]);

  const handleRowSelect = useCallback(
    (selectedItems) => {
      if (onFileSelect) {
        const rows = selectedItems.map((row) => row.id);
        onFileSelect(rows);
      }
    },
    [onFileSelect]
  );

  return (
    <PureDocumentTable
      resourceName={resourceName}
      className={className}
      data={React.useMemo(() => tableData, [tableData])}
      favorites={favorites}
      selected={selectedDocuments}
      onFileSelect={onFileSelect}
      onRowClick={onRowClick}
      handleRowSelect={handleRowSelect}
      onFileEdit={onFileEdit}
      onShowHistory={onShowHistory}
      onSendToWorkflow={onSendToWorkflow}
      onUploadVersion={onUploadVersion}
      onFileDelete={onFileDelete}
      onFavoriteClick={onFavoriteClick}
      hideSiteHeader={hideSiteHeader}
      //
      disableHover={disableHover}
      enableDeleting={enableDeleting}
      noDeleteConfirm={noDeleteConfirm}
      hideChangeView={hideChangeView}
      hideSaveButton={hideSaveButton}
      showEditColumns={showEditColumns}
      showSearch={showSearch}
      showFooter={showFooter}
      //
      allowSelection={allowSelection}
      extraColumns={extraColumns}
      hideButtons={hideButtons}
      buttonActions={buttonActions}
      bannerComponent={bannerComponent}
      showDeleteButton={showDeleteButton}
      docTypeOptionsMap={docTypeOptionsMap}
    />
  );
};

DocumentTable.propTypes = {
  /**
   * className to pass to the table
   */
  className: PropTypes.string,
  /**
   * array of documents to be shown in the table
   */
  // eslint-disable-next-line react/forbid-prop-types
  documents: PropTypes.arrayOf(PropTypes.object),
  /**
   * array of documents that have been favorited
   */
  // eslint-disable-next-line react/forbid-prop-types
  favorites: PropTypes.arrayOf(PropTypes.object),
  /**
   * array of attached documents
   */
  // eslint-disable-next-line react/forbid-prop-types
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),
  /**
   * function for handling selection of a document
   */
  onFileSelect: PropTypes.func,
  /**
   * function that triggers favoriting a document
   */
  onFavoriteClick: PropTypes.func,
  /**
   * run on table row click
   */
  onRowClick: PropTypes.func,
  /**
   * function for handling file edit
   */
  onFileEdit: PropTypes.func,
  /**
   * function for handling file show history
   */
  onShowHistory: PropTypes.func,
  /**
   * function for handling file send to workflow
   */
  onSendToWorkflow: PropTypes.func,
  /**
   * function for handling file upload version
   */
  onUploadVersion: PropTypes.func,
  /**
   * run on table row delete
   */
  onFileDelete: PropTypes.func,
  /**
   * using for doc attachment?
   */
  attaching: PropTypes.bool,
  hideSiteHeader: PropTypes.bool,
  hideChangeView: PropTypes.bool,
  hideSaveButton: PropTypes.bool,
  showEditColumns: PropTypes.bool,
  showSearch: PropTypes.bool,
  showFooter: PropTypes.bool,
  allowSelection: PropTypes.bool,
  disableHover: PropTypes.bool,
  enableDeleting: PropTypes.bool,
  noDeleteConfirm: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  extraColumns: PropTypes.arrayOf(PropTypes.object),
  hideButtons: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  /**
   * array of SiteHeader button actions
   */
  buttonActions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  resourceName: PropTypes.string,
  docTypeOptionsMap: PropTypes.shape({}),
  bannerComponent: PropTypes.element,
};

DocumentTable.defaultProps = {
  className: undefined,
  documents: [],
  favorites: [],
  selectedDocuments: [],
  onFileSelect: undefined,
  onRowClick: undefined,
  onFileEdit: undefined,
  onShowHistory: undefined,
  onSendToWorkflow: undefined,
  onUploadVersion: undefined,
  onFileDelete: undefined,
  onFavoriteClick: undefined,
  attaching: false,
  hideSiteHeader: false,
  hideChangeView: false,
  hideSaveButton: false,
  showEditColumns: false,
  showSearch: false,
  showFooter: false,
  allowSelection: true,
  disableHover: false,
  enableDeleting: false,
  noDeleteConfirm: true,
  extraColumns: undefined,
  hideButtons: false,
  showDeleteButton: false,
  buttonActions: [],
  resourceName: undefined,
  docTypeOptionsMap: undefined,
  bannerComponent: undefined,
};

export default DocumentTable;

import React from "react";
import ESChatContext from "./ESChatContext";

/**
 * HOC to wrap authenticated main page with ESChatContext
 */
const withESChatContext = (Component) => () =>
  (
    <ESChatContext>
      <Component />
    </ESChatContext>
  );

export default withESChatContext;

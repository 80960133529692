/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import { GET_CONTACT_PATH } from "../../../constants";
import { getFullName } from "../../../helpers/Formatters";
import PlusCircleButton from "../Buttons/PlusCircleButton/PlusCircleButton";
import MembersRow from "./MembersRow";
import SimpleSearch from "../SearchBar/StatelessSearch";
import SelectUserInterface from "../SelectUserInterface/SelectUserInterface";
import Widget from "../Widget/Widget";
import { useUsers } from "../../../hooks/useUsers.new";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { isEmployeeOrAdmin } from "../../../helpers/Permissions";

const MembersView = ({
  resource,
  dispatch,
  isObjectLoading,
  memberLoading,
  setMemberLoading,
  editing,
  widgetWith,
}) => {
  const history = useHistory();
  const { data: currentUser } = useCurrentUser();
  const { data, isLoading } = useUsers();
  const users = data?.users;
  const userDict = data?.userDict;
  const [widget, setWidget] = useState({
    state: "add",
    set: (key, val) => setWidget((prev) => ({ ...prev, [key]: val })),
  });

  /**
   * Memoized Data Structures
   */
  const memberOptions = useMemo(() => {
    const addedMembers = resource?.members?.map((person) => person.user);
    return users?.reduce((arr, { reference, name, kind, ...rest }) => {
      if (
        !addedMembers?.includes(reference) &&
        name?.firstName &&
        name?.lastName &&
        kind === "member"
      ) {
        arr.push({
          ...rest,
          label: getFullName(name),
          value: reference,
        });
      }
      return arr;
    }, []);
  }, [resource?.members, users]);

  const addedMembers = useMemo(() => {
    return (
      resource?.members?.map((person) => ({
        ...userDict?.[person.user],
      })) ?? []
    );
  }, [resource?.members, userDict]);

  /**
   * Memoized Data Structures
   */

  /**
   * Search Functionality
   */

  const [searchState, setSearchState] = useState({
    members: null,
    value: null,
  });

  const searchRef = useRef();

  const handleSearch = useCallback((arr, value) => {
    setSearchState({ members: arr, value });
  }, []);

  const clearSearch = () => {
    setSearchState({
      members: null,
      value: null,
    });
    searchRef.current.value = null;
  };

  /**
   * Search Functionality
   */

  /**
   * Handlers
   */

  const handleRemove = useCallback(
    (_user) => {
      if (!memberLoading && !isObjectLoading) {
        clearSearch();
        setMemberLoading(true);
        dispatch({
          type: "removeMember",
          member: _user.reference,
        });
        setMemberLoading(false);
      }
    },
    [dispatch, isObjectLoading, memberLoading, setMemberLoading]
  );

  const handleAddMemberClick = () => {
    clearSearch();
    widget.set("state", "adding");
  };

  const handleCancelAddMember = () => {
    clearSearch();
    widget.set("state", "add");
  };

  const addTimer = useRef(null);

  const handleAdd = (list) => {
    clearSearch();
    widget.set("state", "add");
    const [newMember] = list;
    dispatch({
      type: "addMember",
      member: newMember,
    });
    addTimer.current = setTimeout(() => setMemberLoading(false), 1000);
  };

  const handleNavigate = ({ id }) => {
    if (isEmployeeOrAdmin(currentUser)) {
      history.push(GET_CONTACT_PATH(id, "0"));
    }
  };

  useEffect(() => {
    return () => {
      if (addTimer.current) clearTimeout(addTimer.current);
    };
  }, []);

  /**
   * Handlers
   */

  return (
    <Widget
      title="Members"
      showCountInBrackets
      count={searchState?.members?.length ?? addedMembers?.length}
      draggable={false}
      className="self-start"
      width={`${widgetWith || "1/3"}`}
      childClassName="flex flex-col justify-center"
      overflow
      loading={isLoading}
    >
      <div
        className={`${
          (memberLoading || isObjectLoading || !users?.length) && "loading"
        } w-full flex flex-col gap-4 flex-1`}
      >
        <SimpleSearch
          options={addedMembers}
          keys={["name.firstName", "name.lastName"]}
          onChange={handleSearch}
          value={searchState.value}
          forwardedRef={searchRef}
          placeholder="Search"
          disabled={addedMembers?.length === 0}
        />
        <div
          className="flex flex-row w-full overflow-x-auto"
          style={{ maxHeight: "240px", minHeight: "240px" }}
        >
          <MembersRow
            isEditing={editing}
            onClick={handleNavigate}
            handleRemove={handleRemove}
            currentMembers={searchState?.members ?? addedMembers}
            resource="Members"
            disableRemoveAdmins
          />
        </div>
        {editing && (
          <div className="border-t pt-2">
            {widget.state === "add" && (
              <PlusCircleButton
                title="Add Member"
                onClick={handleAddMemberClick}
                className="flex w-full items-center h-16"
                style={{ color: "#027D61", fontSize: 16 }}
                noHover
              />
            )}
            {widget.state === "adding" && (
              <SelectUserInterface
                userList={[]}
                className="w-full"
                userLabel="Add Member"
                userPlaceholder="Select"
                userOptions={memberOptions}
                onAddUser={(val) => handleAdd(val)}
                onCancelClick={handleCancelAddMember}
                isSingleSelect
                disableCreateUser
              />
            )}
          </div>
        )}
      </div>
    </Widget>
  );
};

MembersView.propTypes = {
  resource: PropTypes.shape({
    members: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  dispatch: PropTypes.func,
  setMemberLoading: PropTypes.func,
  memberLoading: PropTypes.bool,
  isObjectLoading: PropTypes.bool,
  editing: PropTypes.bool,
  widgetWith: PropTypes.string,
};

MembersView.defaultProps = {
  resource: {},
  dispatch: undefined,
  setMemberLoading: undefined,
  memberLoading: true,
  isObjectLoading: false,
  editing: false,
  widgetWith: undefined,
};

export default MembersView;

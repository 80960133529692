/* eslint-disable no-param-reassign */
import { useState, useEffect, useCallback } from "react";
import { ProjectAPI } from "@griffingroupglobal/eslib-api";
import { useAppState } from "../state/appState";
import {
  PROJECT_STATUS_TYPES,
  SET_PROJECTS,
  SET_PROJECTS_CODES,
  SET_PROJECT_DD,
  SET_PROJECT_DICT,
} from "../constants";
import { toastError } from "../stories/Components/Toast/Toast";
import useProjectCodes from "./useProjectCodes";
import sortByProperty from "../helpers/Utilities/sortByProperty";

const getFincancials = async (query) => {
  return ProjectAPI.getWOP(query).then((res) => {
    return res.data;
  });
};

/**
 * @deprecated - Use new RQ useProjects
 */
export default (projectId, memberRef) => {
  const [{ projects, projectDict: dictionary, projectDD: dropDown }, dispatch] =
    useAppState();
  const [codesQuery, setCodesQuery] = useState();
  const {
    base: {
      data: { projectCodes, members },
    },
    actions: {
      fetchMember: { mutateAsync: fetchMember },
    },
  } = useProjectCodes(codesQuery, memberRef);
  const [memberProjects, setMemberProjects] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const createProjectsData = useCallback(
    async (projectList) => {
      const projectDD = [];
      const projectDict = {};
      let projectFinancialsQuery = `$gettimesheetrates?reference=`;

      let allProjects = projectList.map((project) => {
        const resource = project?.resource?.id ? project?.resource : project;
        if (resource?.status === PROJECT_STATUS_TYPES.active) {
          projectDD.push({
            label: resource?.name,
            value: resource?.reference,
            id: resource?.id,
          });
        }
        projectFinancialsQuery = projectFinancialsQuery.concat(
          `${resource?.reference},`
        );
        projectDict[resource?.reference] = {
          ...resource,
          rates: [
            ...resource?.rateSheet?.rates?.map((rate) => ({
              label: rate?.category,
              value: rate?.id,
            })),
          ],
          ratesDict: resource?.rateSheet?.rates?.reduce((rateDict, rate) => {
            // eslint-disable-next-line no-param-reassign
            rateDict[rate?.id] = rate;
            return rateDict;
          }, {}),
        };

        return resource;
      });

      // Sort projects by ES rule
      allProjects = sortByProperty(allProjects, "name");

      dispatch({
        type: SET_PROJECT_DD,
        dropDown: projectDD,
      });
      dispatch({
        type: SET_PROJECTS,
        projects: allProjects,
      });
      dispatch({
        type: SET_PROJECT_DICT,
        projectDict,
      });
      setCodesQuery(projectFinancialsQuery);
      dispatch({
        type: SET_PROJECTS_CODES,
        codes: await getFincancials(projectFinancialsQuery).catch(() =>
          toastError("Error Loading Project Codes", true)
        ),
      });
      dispatch({
        type: SET_PROJECTS,
        projects: allProjects,
      });
    },
    [dispatch]
  );

  const reload = useCallback(async () => {
    try {
      const { data } = await ProjectAPI.get();
      createProjectsData(data.entries);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, [createProjectsData]);

  const reloadRates = useCallback(async () => {
    let projectFinancialsQuery = `$gettimesheetrates?reference=`;
    projects?.forEach((item) => {
      projectFinancialsQuery = projectFinancialsQuery.concat(
        `${item?.reference},`
      );
    });
    try {
      const timesheetrates = await getFincancials(projectFinancialsQuery);
      dispatch({
        type: SET_PROJECTS_CODES,
        codes: timesheetrates,
      });
    } catch (err) {
      console.warn(err);
    }
  }, [dispatch, projects]);

  const setProjects = useCallback(
    (newProjects) => {
      createProjectsData(newProjects);
      setIsLoading(false);
    },
    [createProjectsData]
  );

  const addProject = useCallback(
    (proj) => {
      const found = projects.find((item) => item.id === proj.id);
      let tempProjects = projects;
      if (found) {
        tempProjects = tempProjects.map((item) => {
          if (item.id === proj.id) {
            return { ...item, ...proj };
          }
          return item;
        });
      } else {
        tempProjects = [proj, ...tempProjects];
      }

      // Sort projects by ES rule
      tempProjects = sortByProperty(tempProjects, "name");

      dispatch({
        type: SET_PROJECTS,
        projects: tempProjects,
      });

      /**
       * Add Project To Dictionary
       */
      dispatch({
        type: SET_PROJECT_DICT,
        projectDict: {
          ...dictionary,
          [`Project/${proj.id}`]: proj,
        },
      });
    },
    [projects, dispatch, dictionary]
  );

  const removeProject = useCallback(
    (proId) => {
      dispatch({
        type: SET_PROJECTS,
        projects: projects?.filter((item) => item.id !== proId),
      });
      const tempDict = dictionary;

      /**
       * Remove Project From Dictionary
       */
      delete tempDict?.[`Project/${proId}`];
      dispatch({
        type: SET_PROJECT_DICT,
        projectDict: tempDict,
      });

      /**
       * Remove Project From DropDown
       */
      dispatch({
        type: SET_PROJECT_DD,
        dropDown: dropDown?.filter((item) => item.id !== proId),
      });
    },
    [dispatch, projects, dictionary, dropDown]
  );

  const changeProjectStatus = useCallback(
    (proId, status) => {
      dispatch({
        type: SET_PROJECTS,
        projects: projects?.map((item) => {
          if (item.id === proId) {
            return { ...item, status };
          }
          return item;
        }),
      });

      /**
       * Change Project Status In Dictionary
       */
      dispatch({
        type: SET_PROJECT_DICT,
        projectDict: {
          ...dictionary,
          [`Project/${proId}`]: { ...dictionary?.[`Project/${proId}`], status },
        },
      });

      /**
       * Update Dropdown according to status
       */
      dispatch({
        type: SET_PROJECT_DD,
        dropDown:
          status === PROJECT_STATUS_TYPES.active
            ? [
                ...dropDown,
                {
                  label: dictionary?.[`Project/${proId}`]?.name,
                  value: dictionary?.[`Project/${proId}`]?.reference,
                  id: dictionary?.[`Project/${proId}`]?.id,
                },
              ]
            : dropDown?.filter((item) => item?.id !== proId),
      });
    },
    [dispatch, projects, dictionary, dropDown]
  );

  const loadMemberAssociations = useCallback(async () => {
    setIsLoading(true);
    ProjectAPI.getWOP(`?members.user=${memberRef}`)
      .then(({ data: { entries } }) => {
        const resources = entries?.reduce(
          (list, { resource }) => {
            if (resource?.status === PROJECT_STATUS_TYPES.active) {
              list.dropDown.push({
                label: resource?.name,
                value: resource?.reference,
                id: resource?.id,
              });
            }
            list.query = list.query.concat(`${resource?.reference},`);
            list.dict[resource?.reference] = {
              ...resource,
              rates: [
                ...resource?.rateSheet?.rates?.map((rate) => ({
                  label: rate?.category,
                  value: rate?.id,
                })),
              ],
              ratesDict: resource?.rateSheet?.rates?.reduce(
                (rateDict, rate) => {
                  // eslint-disable-next-line no-param-reassign
                  rateDict[rate?.id] = rate;
                  return rateDict;
                },
                {}
              ),
            };

            return list;
          },
          {
            dict: {},
            dropDown: [],
            query: "",
          }
        );
        setMemberProjects(resources);
        return { query: resources.query, ref: memberRef };
      })
      .then((query) => fetchMember(query))
      .catch((err) => console.error(err));
    setIsLoading(false);
  }, [fetchMember, memberRef]);

  useEffect(() => {
    if (!projects) {
      reload();
    } else if (projects) {
      setIsLoading(false);
    }
    if (memberRef) {
      loadMemberAssociations();
    }
  }, [loadMemberAssociations, memberRef, projects, reload]);

  return {
    currentProject: projectId && projects?.find((p) => p.id === projectId),
    projects: projectId
      ? projects?.filter((p) => p.id === projectId)
      : projects,
    reload,
    reloadRates,
    setProjects,
    addProject,
    removeProject,
    changeProjectStatus,
    loading: isLoading,
    projectDict: !memberRef ? dictionary : memberProjects?.dict,
    dropdownOptions: !memberRef ? dropDown : memberProjects?.dropDown,
    projectCodes: !memberRef ? projectCodes : members?.[memberRef],
    loadMemberAssociations,
  };
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { truncate } from "mml-react";
import ResourceCard from "../ResourceCard/ResourceCard";
import { GET_CONTACT_PATH } from "../../../constants";
import { getFullName } from "../../../helpers/Formatters";
import ContactRowButton from "../ResourceCard/ContactRowButton";
import "../ResourceCard/ResourceCard.css";
import useUserDict from "../../../hooks/useUserDict";

const CardsContactsView = ({
  currentUser,
  sortedContacts,
  openChat,
  contacts,
}) => {
  const history = useHistory();
  const onContactClick = (id) => {
    history.push(GET_CONTACT_PATH(id, "0"));
  };
  // keeps track of if a dropdown for company members is open
  // if open disables the transform on the cards preventing
  // a bug of the card below showing above the DDL
  const [dropdownActive, setDropdownActive] = useState(false);

  const { data: userDict } = useUserDict();

  const getCompanyName = (companyRef) => {
    const companyName = userDict[companyRef]?.company?.value ?? "";
    return truncate(companyName, 17, "...");
  };

  return (
    <div>
      {Object.keys(sortedContacts)?.map((contact) => (
        <div
          className="my-4"
          key={`container-${contact}`}
          id={`content-${contact}`}
        >
          <p className="my-2 font-bold">{contact}</p>
          <div className="flex flex-wrap">
            {sortedContacts &&
              sortedContacts[contact]?.map((item) => (
                <div key={`card-${item?.id}`} className="mr-6 mt-6">
                  {item?.kind === "company" ? (
                    <ResourceCard
                      className={`${dropdownActive ? "" : "resourceCN"}`}
                      currentUser={currentUser}
                      company={item}
                      id={item.id}
                      title={item?.company?.value}
                      imageReference={item?.avatar}
                      detail={{ label: "Type", value: "Company" }}
                      resourceName="Company"
                      onClick={() => onContactClick(item.id)}
                      CustomComponent={() => (
                        <ContactRowButton
                          contact={item}
                          currentUser={currentUser}
                          openChat={openChat}
                          company={item}
                          contacts={contacts}
                          setDropdownActive={setDropdownActive}
                          dropdownActive={dropdownActive}
                        />
                      )}
                    />
                  ) : (
                    <ResourceCard
                      currentUser={currentUser}
                      className={`${dropdownActive ? "" : "resourceCN"}`}
                      contact={item}
                      id={item.id}
                      title={getFullName(item?.name)}
                      imageReference={item?.avatar}
                      detail={{
                        label: "Company",
                        value: getCompanyName(item?.company?.value),
                      }}
                      resourceName="Contact"
                      onClick={() => onContactClick(item.id)}
                      openChat={openChat}
                      CustomComponent={() => (
                        <ContactRowButton
                          contact={item}
                          currentUser={currentUser}
                          openChat={openChat}
                          contacts={contacts}
                          setDropdownActive={setDropdownActive}
                          dropdownActive={dropdownActive}
                        />
                      )}
                    />
                  )}
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

CardsContactsView.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.string,
  }),
  contacts: PropTypes.shape([]),
  // eslint-disable-next-line react/forbid-prop-types
  sortedContacts: PropTypes.object,
  openChat: PropTypes.func,
};

CardsContactsView.defaultProps = {
  currentUser: undefined,
  sortedContacts: undefined,
  openChat: undefined,
  contacts: [],
};

export default CardsContactsView;

import { cloneDeep } from "lodash";
import { userKeys } from "../../config/reactQuery/queryKeyFactory";

/**
 * Update single user in all possible queries:
 * - ["users"]: Holds an object with the shape
 *   {
 *     users: [],         // All users
 *     userDict: {}       // Map of all users
 *     companies: [],     // All companies
 *     employees: [],     // All employees
 *   }
 * - ["users", "3333-6666-4444-8888"]: Holds an object (single user)
 * - ["users", "current"]: Holds an object (current user)
 *
 * @param {Object} queryClient - React Query client object
 * @param {Object} userUpdated - User with new changes
 */

const mutateUserCache = (queryClient, userUpdated) => {
  queryClient.setQueriesData(userKeys.users, (current) => {
    // Update user in { users, userDict }
    if ("users" in current || "userDict" in current) {
      const userDeepCopy = cloneDeep(current);

      // User updated in `userDict`
      userDeepCopy.userDict[userUpdated.reference] = userUpdated;

      // User updated in `users`
      const indexUserToUpdate = userDeepCopy.users.findIndex(
        (user) => user.id === userUpdated.id
      );

      if (indexUserToUpdate !== 1) {
        userDeepCopy.users[indexUserToUpdate] = userUpdated;
      }

      // User update in `employees`
      const indexEmployeeToUpdate = userDeepCopy.employees.findIndex(
        (user) => user.id === userUpdated.id
      );

      if (indexEmployeeToUpdate !== -1) {
        userDeepCopy.employees[indexEmployeeToUpdate] = userUpdated;
      }

      // User update in `companies`
      const indexCompanyToUpdate = userDeepCopy.companies.findIndex(
        (user) => user.id === userUpdated.id
      );
      if (indexCompanyToUpdate !== -1) {
        userDeepCopy.companies[indexCompanyToUpdate] = userUpdated;
      }

      return userDeepCopy;
    }

    // Update single user
    if (typeof current === "object" && current.id === userUpdated.id) {
      // Update user in ["users", "current"] query key
      if ("hasPermission" in current) {
        return { ...current, ...userUpdated };
      }

      // Update user in ["users", id] query key
      return userUpdated;
    }

    return current;
  });
};

export default mutateUserCache;

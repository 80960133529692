import React, { useCallback, useEffect, useState } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import Input from "../Input/Input";
import { getStatesDropdown } from "../../../helpers/Address";
import Dropdown from "../Dropdown/Dropdown";
import PlusCircleButton from "../Buttons/PlusCircleButton/PlusCircleButton";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import TrashButton from "../Buttons/TrashButton";
import { ADD_OPEN_MODAL, CONFIRM_MODAL } from "../../../constants";
import { useModalState } from "../../../state/modalState";

const tertiaryButtonCN = cntl`
  text-es-green
  es-green
  rounded-lg
`;

const TaxesRatesSection = ({
  financialsSettings,
  dispatchFinancialsSettings,
}) => {
  const [dropdownStates, setDropdownStates] = useState([]);
  const [, modalDispatch] = useModalState();
  const { width } = useWindowDimensions();

  useEffect(() => {
    const states = getStatesDropdown();
    setDropdownStates(states);
  }, []);

  const onFicaChange = useCallback(
    (value, field) => {
      dispatchFinancialsSettings({
        type: "changeFica",
        field,
        value,
      });
    },
    [dispatchFinancialsSettings]
  );

  const onFutaChange = useCallback(
    (value, field) => {
      dispatchFinancialsSettings({
        type: "changeFuta",
        field,
        value,
      });
    },
    [dispatchFinancialsSettings]
  );

  const onSutaChange = useCallback(
    (index, field, value) => {
      dispatchFinancialsSettings({
        type: "changeSuta",
        field,
        value,
        index,
      });
    },
    [dispatchFinancialsSettings]
  );

  const onAdd = useCallback(() => {
    dispatchFinancialsSettings({
      type: "addSuta",
    });
  }, [dispatchFinancialsSettings]);

  const onRemoveSuta = useCallback(
    (index) => {
      dispatchFinancialsSettings({
        type: "removeSuta",
        index,
      });
    },
    [dispatchFinancialsSettings]
  );

  return (
    <div className="flex flex-row w-full h-fit flex-wrap gap-4 justify-start mt-4">
      <div className="flex flex-col gap-6 w-fit">
        <div className="flex flex-col">
          <h2 className="text-es-lg font-es-semibold text-es-dark-grey">
            FICA
          </h2>
          <div className="flex flex-row pt-2 gap-4 items-center">
            <div style={{ width: "250px" }}>
              <Input
                placeholder="Soc. Security"
                label="Soc Security"
                labelClassName="text-es-normal text-es-medium-grey font-es-semibold pb-2"
                value={financialsSettings?.taxRates?.fica?.socialSecurity}
                onChange={(val) => onFicaChange(val, "socialSecurity")}
                isPercentageValue
                disableClear
              />
            </div>
            <div style={{ width: "250px" }}>
              <Input
                placeholder="Medicare"
                label="Medicare"
                labelClassName="text-es-normal text-es-medium-grey font-es-semibold pb-2"
                value={financialsSettings?.taxRates?.fica?.medicare}
                onChange={(val) => onFicaChange(val, "medicare")}
                isPercentageValue
                disableClear
              />
            </div>
            <div style={{ width: "250px" }}>
              <Input
                placeholder="Total FICA"
                label="Total FICA"
                labelClassName="text-es-normal text-es-medium-grey font-es-semibold pb-2"
                value={
                  Math.round(
                    (parseFloat(
                      financialsSettings?.taxRates?.fica?.socialSecurity
                    ) +
                      parseFloat(
                        financialsSettings?.taxRates?.fica?.medicare
                      )) *
                      100
                  ) / 100
                }
                isPercentageValue
                disableClear
                disabled
                inputContainerClassName="border-4 rounded-none"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <h2 className="text-es-lg font-es-semibold text-es-dark-grey">
            FUTA
          </h2>
          <div className="flex flex-row pt-2 gap-4">
            <div style={{ minWidth: "250px" }}>
              <Input
                placeholder="Cost"
                label="Cost"
                labelClassName="text-es-normal text-es-medium-grey font-es-semibold pb-2"
                value={financialsSettings?.taxRates?.futa?.cost}
                onChange={(val) => onFutaChange(val, "cost")}
                isPercentageValue
                disableClear
              />
            </div>
            <div style={{ width: "250px" }}>
              <Input
                placeholder="Credit"
                label="Credit"
                labelClassName="text-es-normal text-es-medium-grey font-es-semibold pb-2"
                value={financialsSettings?.taxRates?.futa?.credit}
                onChange={(val) => onFutaChange(val, "credit")}
                isPercentageValue
                disableClear
              />
            </div>
            <div style={{ width: "250px" }}>
              <Input
                placeholder="Total FUTA"
                label="Total FUTA"
                labelClassName="text-es-normal text-es-medium-grey font-es-semibold pb-2"
                value={
                  Math.round(
                    (parseFloat(financialsSettings?.taxRates?.futa?.cost) -
                      parseFloat(financialsSettings?.taxRates?.futa?.credit)) *
                      100
                  ) / 100
                }
                isPercentageValue
                disableClear
                disabled
                inputContainerClassName="border-4 rounded-none"
              />
            </div>
          </div>
        </div>
      </div>

      {width > 2150 && <div className="min-h-full border-r mr-2" />}

      <div className="flex flex-col w-fit">
        <h2 className="text-es-lg font-es-semibold text-es-dark-grey pb-2">
          SUTA
        </h2>
        <div
          className="flex flex-row flex-wrap items-center"
          style={{ maxWidth: "1100px" }}
        >
          {!financialsSettings?.taxRates?.suta?.length && (
            <p className="text-gray-200 text-sm mb-4">
              Add SUTA based on the state(s) that you have employees in.
            </p>
          )}
          {financialsSettings?.taxRates?.suta?.map((rate, index) => {
            return (
              <React.Fragment
                key={`${rate?.id}-${rate?.cost}-${rate?.perEmployee}-${rate?.state}-${rate?.total}`}
              >
                {index === 0 && (
                  <div className="flex flex-row gap-4">
                    <div
                      className="flex flex-col justify-start"
                      style={{ width: "250px" }}
                    >
                      <h3 className="text-es-medium-grey font-es-semibold text-es-normal">
                        State
                      </h3>
                    </div>
                    <div
                      className="flex flex-col justify-start"
                      style={{ width: "250px" }}
                    >
                      <h3 className="text-es-medium-grey font-es-semibold text-es-normal">
                        Cost
                      </h3>
                    </div>
                    <div
                      className="flex flex-col justify-start"
                      style={{ width: "250px" }}
                    >
                      <h3 className="text-es-medium-grey font-es-semibold text-es-normal">
                        Per Employee
                      </h3>
                    </div>
                    <div
                      className="flex flex-col justify-start"
                      style={{ width: "250px" }}
                    >
                      <h3 className="text-es-medium-grey font-es-semibold text-es-normal">
                        Total SUTA
                      </h3>
                    </div>
                  </div>
                )}
                <div className="flex flex-row w-full items-center gap-4">
                  <div className="flex flex-col" style={{ width: "250px" }}>
                    <Dropdown
                      placeholder="Select"
                      options={dropdownStates.filter(
                        (state) =>
                          financialsSettings?.taxRates?.suta?.find(
                            (r) => r.state === state.value
                          ) === undefined
                      )}
                      value={dropdownStates.find(
                        (opt) => opt.value === rate?.state
                      )}
                      onChange={(val) =>
                        onSutaChange(index, "state", val.value)
                      }
                    />
                  </div>
                  <div className="flex flex-col" style={{ width: "250px" }}>
                    <Input
                      placeholder="Cost"
                      isPercentageValue
                      className="text-gray-300"
                      mainClassName="w-full"
                      value={rate?.cost}
                      onChange={(val) => onSutaChange(index, "cost", val)}
                      disableClear
                    />
                  </div>
                  <div className="flex flex-col" style={{ width: "235px" }}>
                    <Input
                      placeholder="Value"
                      isDollarValue
                      className="text-gray-300"
                      mainClassName="w-full"
                      value={rate?.perEmployee}
                      onChange={(val) =>
                        onSutaChange(index, "perEmployee", val)
                      }
                      disableClear
                    />
                  </div>
                  <div className="flex flex-col" style={{ width: "250px" }}>
                    <Input
                      placeholder="Value"
                      isDollarValue
                      className="text-gray-300"
                      mainClassName="w-full"
                      value={parseFloat((rate?.cost * rate?.perEmployee) / 100)}
                      disableClear
                      disabled
                      inputContainerClassName="border-4 rounded-none"
                    />
                  </div>
                  <TrashButton
                    className="ml-2"
                    onClick={() => {
                      modalDispatch({
                        type: ADD_OPEN_MODAL,
                        modalType: CONFIRM_MODAL,
                        ref: { id: `${index}-confirm-delete` },
                        modalData: {
                          item: {
                            prompt:
                              "Are you sure you want to delete this item?",
                            confirm: "Yes, delete",
                            cancel: "Cancel",
                            title: "Delete Item",
                            onConfirm: () => {
                              onRemoveSuta(index);
                            },
                          },
                        },
                      });
                    }}
                  />
                </div>
              </React.Fragment>
            );
          })}
        </div>
        <PlusCircleButton
          titleClassName={tertiaryButtonCN}
          title="Add SUTA"
          style={{}} // if not given style prop, auto's text color to grey
          onClick={onAdd}
        />
      </div>
    </div>
  );
};

TaxesRatesSection.propTypes = {
  financialsSettings: PropTypes.shape({
    taxRates: PropTypes.shape({
      fica: PropTypes.shape({
        socialSecurity: PropTypes.string,
        medicare: PropTypes.string,
        total: PropTypes.string,
      }),
      futa: PropTypes.shape({
        cost: PropTypes.string,
        credit: PropTypes.string,
        total: PropTypes.string,
      }),
      suta: PropTypes.arrayOf(
        PropTypes.shape({
          state: PropTypes.string,
          cost: PropTypes.string,
          perEmployee: PropTypes.string,
          total: PropTypes.string,
        })
      ),
    }),
  }),
  dispatchFinancialsSettings: PropTypes.func,
};

TaxesRatesSection.defaultProps = {
  financialsSettings: undefined,
  dispatchFinancialsSettings: undefined,
};

export default TaxesRatesSection;

import React from "react";
import PropTypes from "prop-types";
import { useFilters, useGlobalFilter, useTable } from "react-table";
import SiteHeaderSearch from "../SiteHeader/SiteHeaderSearch";
import SiteHeaderFilter from "../SiteHeader/SiteHeaderFilter";
import PrimaryHeaderItems from "../Primary/PrimaryHeaderItems";
import SiteHeaderSwitchView from "../SiteHeader/SiteHeaderSwitchView";
import folderIconGreen from "../../assets/images/folderIconGreen.svg";
import folderIconWhite from "../../assets/images/folderIconWhite.svg";
import DocumentTable from "../DocumentTable/DocumentTable";
import DocumentFoldersCard from "./DocumentFoldersCard";
import DocumentFolderContent from "../DocumentFolderContent/DocumentFolderContent";
import DocumentUploadForm from "../DocumentUploadForm/DocumentUploadForm";

const PureDocumentFoldersView = ({
  documents,
  loading,
  columns,
  resource,
  isShowingTable,
  setIsShowingTable,
  searchText,
  handleChange,
  viewFolder,
  folderViews,
  docTypeOptionsMap,
  favorites,
  handleFolderClick,
  getFolderView,
  handleBackClick,
  handleFinishUpload,
  handleFileClick,
  handleFavoriteClick,
  handleShowDelete,
  handleSendToWorkflow,
  handleRowClick,
  handleFileEdit,
  handleShowHistory,
  handleUploadVersion,
  hideHeader,
  disableUpload,
}) => {
  const {
    setAllFilters,
    allColumns,
    state: { filters },
  } = useTable(
    {
      columns,
      data: documents,
    },
    useFilters,
    useGlobalFilter
  );

  return (
    <>
      {!hideHeader && (
        <PrimaryHeaderItems
          headerTitle={resource?.title ? resource?.title : resource?.name}
          className="overwrite-primaryheaderitems-padding"
        />
      )}
      <SiteHeaderSwitchView
        isShowingTable={isShowingTable}
        setIsShowingTable={setIsShowingTable}
        firstIcon={folderIconGreen}
        firstSelectedIcon={folderIconWhite}
      />
      <div className="flex flex-row pb-4">
        <SiteHeaderSearch
          globalFilter={searchText}
          handleSearch={handleChange}
        />
        <SiteHeaderFilter
          allColumns={allColumns}
          tableData={documents}
          filters={filters}
          setAllFilters={setAllFilters}
        />
      </div>
      {!loading && !isShowingTable && (
        <div className="mt-4 pb-10">
          {!viewFolder?.title && (
            <>
              <DocumentFoldersCard
                resource={resource}
                documents={documents}
                docTypeOptionsMap={docTypeOptionsMap}
                folderViews={folderViews}
                favorites={favorites}
                onFolderClick={handleFolderClick}
                onFinishUploading={handleFinishUpload}
                disableUpload={disableUpload}
              />
            </>
          )}
          {viewFolder?.title && (
            <DocumentFolderContent
              path={viewFolder?.title}
              contents={getFolderView(viewFolder?.content, filters)}
              docTypeOptionsMap={docTypeOptionsMap}
              favorites={favorites}
              defaultType={viewFolder?.docType}
              onBackClick={handleBackClick}
              onFileClick={handleFileClick}
              onFavoriteClick={handleFavoriteClick}
              onFileDelete={handleShowDelete}
              onSendToWorkflow={handleSendToWorkflow}
              onUpdateDocuments={handleFinishUpload}
              onFileEdit={handleFileEdit}
              disableChildFileAdd
              disableUpload={disableUpload}
            />
          )}
        </div>
      )}
      {!loading && isShowingTable && (
        <div className="-mt-5">
          <div id="document-folders" className="hidden">
            <DocumentUploadForm
              resource={resource}
              onFinishUploading={handleFinishUpload}
              showCorner
              small
            />
          </div>
          <DocumentTable
            resourceName="property-document-table"
            documents={getFolderView(documents, filters)}
            favorites={favorites}
            onRowClick={handleRowClick}
            onFileEdit={handleFileEdit}
            onShowHistory={handleShowHistory}
            onSendToWorkflow={handleSendToWorkflow}
            onUploadVersion={handleUploadVersion}
            onFileDelete={handleShowDelete}
            onFavoriteClick={handleFavoriteClick}
            hideSiteHeader
            docTypeOptionsMap={docTypeOptionsMap}
          />
        </div>
      )}
    </>
  );
};

PureDocumentFoldersView.propTypes = {
  currentUser: PropTypes.shape({
    hasPermission: PropTypes.func,
  }),
  documents: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  resource: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
  }),
  isShowingTable: PropTypes.bool,
  setIsShowingTable: PropTypes.func,
  searchText: PropTypes.string,
  handleChange: PropTypes.func,
  viewFolder: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.shape({}),
    docType: PropTypes.string,
  }),
  folderViews: PropTypes.shape({}),
  docTypeOptionsMap: PropTypes.shape({}),
  favorites: PropTypes.arrayOf(PropTypes.shape({})),
  handleFolderClick: PropTypes.func,
  getFolderView: PropTypes.func,
  handleBackClick: PropTypes.func,
  handleFinishUpload: PropTypes.func,
  handleFileClick: PropTypes.func,
  handleFavoriteClick: PropTypes.func,
  handleShowDelete: PropTypes.func,
  handleSendToWorkflow: PropTypes.func,
  handleRowClick: PropTypes.func,
  handleFileEdit: PropTypes.func,
  handleShowHistory: PropTypes.func,
  handleUploadVersion: PropTypes.func,
  hideHeader: PropTypes.bool,
  disableUpload: PropTypes.bool,
};

PureDocumentFoldersView.defaultProps = {
  currentUser: undefined,
  documents: undefined,
  loading: true,
  columns: undefined,
  resource: undefined,
  isShowingTable: false,
  setIsShowingTable: undefined,
  searchText: undefined,
  handleChange: undefined,
  viewFolder: undefined,
  folderViews: undefined,
  docTypeOptionsMap: undefined,
  favorites: undefined,
  handleFolderClick: undefined,
  getFolderView: undefined,
  handleBackClick: undefined,
  handleFinishUpload: undefined,
  handleFileClick: undefined,
  handleFavoriteClick: undefined,
  handleShowDelete: undefined,
  handleSendToWorkflow: undefined,
  handleRowClick: undefined,
  handleFileEdit: undefined,
  handleShowHistory: undefined,
  handleUploadVersion: undefined,
  hideHeader: false,
  disableUpload: false,
};

export default PureDocumentFoldersView;

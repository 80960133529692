import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { cloneDeep, isEqual } from "lodash";

import RadioButton from "../Buttons/RadioButton";
import Input from "../Input/Input";
import Dropdown from "../Dropdown/Dropdown";
import { maintenanceStatus } from "../../../constants";

const initDurationOptions = [
  {
    label: "Minutes",
    value: "minute",
  },
  { label: "Hours", value: "hour" },
  { label: "Days", value: "calendarDay" },
];

const { CANCELLED, COMPLETE } = maintenanceStatus;

const AssetMaintenanceScheduledTaskStatus = ({
  data,
  setTaskStatus,
  setMaintenanceDuration,
  isTaskDurationRequired,
  setStatusHasChanged,
}) => {
  const [origStatus, setOrigStatus] = useState(data?.duration);
  const [status, setStatus] = useState(data?.status);
  const [durationCode, setDurationCode] = useState(null);
  const [durationValue, setDurationValue] = useState(null);

  useEffect(() => {
    setOrigStatus({ ...cloneDeep(data?.duration), status: data?.status });
    setDurationCode(
      initDurationOptions.find(
        (o) => o.value === data?.duration?.typeOfDuration
      )
    );
    setDurationValue(data?.duration?.value);
  }, [
    data?.duration,
    data?.duration?.typeOfDuration,
    data?.duration?.value,
    data?.status,
  ]);

  useEffect(() => {
    const duration = {
      typeOfDuration: durationCode?.value,
      value: +durationValue,
    };
    setMaintenanceDuration(duration);
    setStatusHasChanged(!isEqual(origStatus, { ...duration, status }));
  }, [
    durationCode,
    durationValue,
    origStatus,
    setStatusHasChanged,
    setMaintenanceDuration,
    status,
  ]);

  useEffect(() => {
    setTaskStatus(status);
  }, [setTaskStatus, status]);

  const handleStatus = (selectedStatus) => {
    setStatus(selectedStatus);
  };

  const handleDuration = (setFunc, value) => {
    setFunc(value);
  };

  return (
    <div className="flex flex-col justify-center items-start w-192">
      <div className="mb-3 w-full">
        <p className="font-semibold text-lg text-gray-300 text-center inline-block border rounded-2xl px-4 py-2 mb-6">
          {data?.name}
        </p>
        <div className="flex flex-col justify-between w-full border-b pb-2">
          <div className="mb-2">
            <RadioButton
              className="flex items-center"
              label="Complete task"
              labelClassName={`${status} === ${COMPLETE} ? "font-semibold" : ""`}
              isChecked={status === COMPLETE}
              onChange={() => handleStatus(COMPLETE)}
            />
          </div>
          <div className="mb-2">
            <RadioButton
              className="flex items-center"
              label="Cancel task"
              labelClassName={`${status} === ${CANCELLED} ? "font-semibold" : ""`}
              isChecked={status === CANCELLED}
              onChange={() => handleStatus(CANCELLED)}
            />
          </div>
        </div>
        {status === COMPLETE && (
          <>
            <p className="py-6 w-full">
              What is the duration for completing this task?
            </p>

            <div className="flex justify-between items-center w-full">
              <div className="w-1/2 mr-4 flex justify-start items-center">
                <Input
                  mainClassName="w-full"
                  placeholder="Duration"
                  type="number"
                  label="Duration"
                  name="durationValue"
                  value={durationValue}
                  onChange={(val) => handleDuration(setDurationValue, val)}
                  validation={isTaskDurationRequired && yup.number().required()}
                  minValue={1}
                  required={isTaskDurationRequired}
                />
              </div>
              <div className="flex justify-end items-center w-1/2">
                <Dropdown
                  label="Time unit"
                  placeholder="Select"
                  options={initDurationOptions}
                  name="durationCode"
                  value={durationCode}
                  onChange={(val) => handleDuration(setDurationCode, val)}
                  disableSort
                  validation={isTaskDurationRequired && yup.mixed().required()}
                  required={isTaskDurationRequired}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

AssetMaintenanceScheduledTaskStatus.propTypes = {
  data: PropTypes.objectOf(PropTypes.string),
  setTaskStatus: PropTypes.func,
  setMaintenanceDuration: PropTypes.func,
  isTaskDurationRequired: PropTypes.bool,
  setStatusHasChanged: PropTypes.func,
};

AssetMaintenanceScheduledTaskStatus.defaultProps = {
  data: {},
  setTaskStatus: undefined,
  setMaintenanceDuration: undefined,
  isTaskDurationRequired: false,
  setStatusHasChanged: undefined,
};

export default AssetMaintenanceScheduledTaskStatus;

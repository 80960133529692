/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import SimpleSearch from "../SearchBar/StatelessSearch";
import DistroListMembersRow from "./DistroListMembersRow";

const DistroListSelectMembers = ({
  members,
  selectedMembers,
  onAdd,
  onRemove,
  setDistroSelectOpen,
  relative,
  title,
  showMemberPill,
  placeHolder,
}) => {
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, () => setDistroSelectOpen(false));

  const [searchState, setSearchState] = useState({
    members: null,
    value: null,
  });
  const [currentMembers, setCurrentMembers] = useState([]);

  useEffect(() => {
    setCurrentMembers(
      members.map((member) => ({
        ...member,
        isSelected: !!selectedMembers.find((selectedMember) => {
          if (selectedMember?.reference) {
            return (
              selectedMember?.reference === member.value ||
              selectedMember?.reference === member.reference
            );
          }
          return (
            selectedMember === member.value ||
            selectedMember === member?.reference
          );
        }),
      }))
    );

    setSearchState((prev) => ({
      ...prev,
      members: prev?.members?.map((item) => ({
        ...item,
        isSelected: !!selectedMembers.find((selectedMember) => {
          if (selectedMember?.reference) {
            return (
              selectedMember?.reference === item.value ||
              selectedMember?.reference === item.reference
            );
          }
          return (
            selectedMember === item.value || selectedMember === item?.reference
          );
        }),
      })),
    }));
  }, [members, selectedMembers]);

  const searchRef = useRef();

  const handleSearch = useCallback((arr, value) => {
    setSearchState({ members: arr, value });
  }, []);

  const onChange = useCallback(
    (user) => {
      if (user.isSelected) {
        onRemove(user?.reference);
      } else {
        onAdd(user);
      }
    },
    [onAdd, onRemove]
  );

  return (
    <div
      ref={wrapperRef}
      className={`${
        relative ? "" : "absolute border shadow-lg"
      } w-72 flex flex-col gap-4 flex-1 justify-center z-20 bg-white rounded-lg p-4`}
    >
      {title && <p className="font-semibold h-7">{title}</p>}
      <SimpleSearch
        options={currentMembers}
        keys={["name.firstName", "name.lastName", "company.value"]}
        onChange={handleSearch}
        value={searchState.value}
        forwardedRef={searchRef}
        placeholder={placeHolder}
        disabled={members?.length === 0}
      />
      <div
        className="flex flex-row w-full overflow-x-auto"
        style={{ maxHeight: "240px", minHeight: "240px" }}
      >
        <DistroListMembersRow
          handleClick={onChange}
          currentMembers={searchState?.members ?? currentMembers}
          resource="Members"
          showMemberPill={showMemberPill}
        />
      </div>
    </div>
  );
};

DistroListSelectMembers.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({})),
  selectedMembers: PropTypes.arrayOf(
    PropTypes.oneOf(PropTypes.shape({}), PropTypes.string)
  ),
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  setDistroSelectOpen: PropTypes.func,
  relative: PropTypes.bool,
  showMemberPill: PropTypes.bool,
  title: PropTypes.string,
  placeHolder: PropTypes.string,
};

DistroListSelectMembers.defaultProps = {
  members: [],
  selectedMembers: [],
  onAdd: undefined,
  onRemove: undefined,
  setDistroSelectOpen: () => {},
  relative: false,
  showMemberPill: false,
  title: "",
  placeHolder: "Search Members",
};

export default DistroListSelectMembers;

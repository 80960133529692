import { useState, useEffect, useCallback } from "react";
import { HistoryAPI } from "@griffingroupglobal/eslib-api";

const useProjectHistory = (id) => {
  const [projectHistory, setProjectHistory] = useState();

  const reload = useCallback(async () => {
    try {
      if (id) {
        const { data } = await HistoryAPI.get({
          params: {
            association: `Project/${id}`,
          },
        });
        setProjectHistory(data?.entries?.map(({ resource }) => resource));
      }
    } catch (error) {
      // handle error
    }
  }, [id]);
  useEffect(() => {
    reload();
  }, [reload]);
  return [projectHistory, reload];
};

export default useProjectHistory;

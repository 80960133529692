import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ToggleButton from "../Buttons/ToggleButton";

const ManagementTaskDurationSection = ({
  managementSettings,
  dispatchManagementSettings,
}) => {
  const [isChecked, setIsChecked] = useState(
    managementSettings?.isCompletionTimeRequired
  );

  useEffect(() => {
    setIsChecked(managementSettings?.isCompletionTimeRequired);
  }, [managementSettings?.isCompletionTimeRequired]);

  const onToggle = () => {
    dispatchManagementSettings({
      type: "toggleTaskCompletionTimeRequired",
    });
  };
  return (
    <div className="pb-4 flex">
      <ToggleButton isChecked={isChecked} onToggle={onToggle} />
      <p className="px-2 py-1 text-sm font-medium">Task duration required</p>
    </div>
  );
};

ManagementTaskDurationSection.propTypes = {
  managementSettings: PropTypes.shape({
    isCompletionTimeRequired: PropTypes.bool,
  }),
  dispatchManagementSettings: PropTypes.func,
};

ManagementTaskDurationSection.defaultProps = {
  managementSettings: undefined,
  dispatchManagementSettings: undefined,
};

export default ManagementTaskDurationSection;

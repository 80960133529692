import React from "react";
import { v4 as uuidv4 } from "uuid";

import ExpenseTable from "../../../stories/Components/ExpenseTable/ExpenseTable";
import Widget from "../../../stories/Components/Widget/Widget";
import PrimaryHeaderItems from "../../../stories/Components/Primary/PrimaryHeaderItems";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { ADD_OPEN_MODAL, EXPENSE_CREATE_MODAL } from "../../../constants";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import { useModalState } from "../../../state/modalState";

const Expense = ({
  openNewExpenseModal,
  setOpenNewExpenseModal,
  modalData,
}) => {
  const { data: currentUser } = useCurrentUser();
  const [, modalDispatch] = useModalState();
  return (
    <>
      <PrimaryHeaderItems
        buttonTitle="Expense"
        headerTitle="Expenses"
        customButton={
          currentUser?.hasPermission?.("expense", "can_write") && (
            <PrimaryButton
              resourceName="Expense"
              onClick={() =>
                modalDispatch({
                  type: ADD_OPEN_MODAL,
                  ref: { id: uuidv4() },
                  modalType: EXPENSE_CREATE_MODAL,
                  modalData: { viewMode: "create" },
                })
              }
              addButton
            />
          )
        }
      />
      <Widget draggable={false} title={null}>
        <ExpenseTable
          modalData={modalData}
          openNewExpenseModal={openNewExpenseModal}
          setOpenNewExpenseModal={setOpenNewExpenseModal}
          hideSiteHeaderTitle
        />
      </Widget>
    </>
  );
};

export default Expense;

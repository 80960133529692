import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Dropdown from "../Dropdown/Dropdown";
import PrimaryButton from "../Buttons/PrimaryButton";
import BaseButton from "../Buttons/BaseButton";
import crossIcon from "../../assets/images/crossIcon.svg";

const getLength = (number) => {
  return number.toString().length;
};

const CSICodeAndMappingRevenueMapping = ({
  csiCode,
  revenueAccountingCodes,
  mappingIndex,
  csiCodeIndex,
  setIsAdding,
  dispatchFinancialsSettings,
  divCode,
}) => {
  const [revenueCode, setRevenueCode] = useState(null);
  const [nextSubCode, setNextSubCode] = useState(null);

  useEffect(() => {
    if (csiCode) {
      const getNextSubCode = () => {
        const subcode = csiCode?.code;
        const children = csiCode?.subCodes?.sort((a, b) => a - b);

        let digits;
        if (children.length) {
          const { code } = children[children.length - 1];
          digits = code.split("-");
          let lastCode = parseInt(digits.pop(), 10) + 1;
          lastCode = getLength(lastCode) === 1 ? `0${lastCode}` : lastCode;
          digits.push(lastCode);
        } else {
          digits = subcode.split("-");
          let lastCode = parseInt(digits.pop(), 10) + 1;
          lastCode = getLength(lastCode) === 1 ? `0${lastCode}` : lastCode;
          digits.push(lastCode);
        }

        return digits.join("-");
      };

      const rv = getNextSubCode();

      setNextSubCode(rv);
    }
  }, [csiCode]);

  const onAddCode = useCallback(() => {
    dispatchFinancialsSettings({
      type: "addCSIRevenueCode",
      mappingIndex,
      csiCodeIndex,
      revenueCode: revenueCode?.value,
      code: nextSubCode,
    });

    setIsAdding(false);
  }, [
    dispatchFinancialsSettings,
    mappingIndex,
    csiCodeIndex,
    revenueCode?.value,
    setIsAdding,
    nextSubCode,
  ]);

  return (
    <div className="border-2 rounded border-gray-150 px-4 py-4">
      <div className="flex py-4 px-2 justify-between">
        <h2 className="uppercase font-bold text-lg">
          Map revenue code to {csiCode?.description}
        </h2>
        <div>
          <BaseButton
            onClick={() => setIsAdding(false)}
            iconLeft={<img src={crossIcon} alt="crossIcon" />}
          />
        </div>
      </div>
      <div className="flex flex-row w-full px-2">
        <div className="flex-col w-1/5">
          <div className="flex">
            <h3 className="text-gray-200">CSI Code</h3>
          </div>
          <div className="flex py-2">
            <p className="text-gray-200">
              {divCode} {csiCode?.code} {nextSubCode}
            </p>
          </div>
        </div>
        <div className="flex px-4 w-1/3">
          <Dropdown
            label="Revenue Accounting Code"
            placeholder="Search"
            options={revenueAccountingCodes?.map((code) => ({
              label: code.description,
              value: code.code,
            }))}
            value={revenueCode}
            onChange={setRevenueCode}
          />
        </div>
        <div className="flex align-bottom pt-7">
          <PrimaryButton title="Add Code" onClick={onAddCode} />
        </div>
      </div>
    </div>
  );
};

CSICodeAndMappingRevenueMapping.propTypes = {
  csiCode: PropTypes.shape({
    code: PropTypes.string,
    description: PropTypes.string,
    revenueCode: PropTypes.string,
    selectedForBudgets: PropTypes.string,
    selectedForTimesheets: PropTypes.bool,
    subCodes: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        expenseCode: PropTypes.string,
        selectedForBudgets: PropTypes.string,
        selectedForTimesheets: PropTypes.bool,
      })
    ),
  }),
  revenueAccountingCodes: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      codeType: PropTypes.string,
      description: PropTypes.string,
      selectedForBudgets: PropTypes.bool,
      selectedForTimesheets: PropTypes.bool,
    })
  ),
  dispatchFinancialsSettings: PropTypes.func,
  mappingIndex: PropTypes.number,
  csiCodeIndex: PropTypes.number,
  setIsAdding: PropTypes.func,
  divCode: PropTypes.string,
};

CSICodeAndMappingRevenueMapping.defaultProps = {
  csiCode: undefined,
  revenueAccountingCodes: [],
  dispatchFinancialsSettings: undefined,
  mappingIndex: undefined,
  csiCodeIndex: undefined,
  setIsAdding: undefined,
  divCode: undefined,
};

export default CSICodeAndMappingRevenueMapping;

import React, { useRef, useState, useMemo } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import { debounce } from "lodash";
import { useDrag, useDrop } from "react-dnd";
import dragIcon from "../../assets/images/drag_icon.svg";
import editIcon from "../../assets/images/editIcon.svg";
import removeIcon from "../../assets/images/Remove.svg";
import Input from "../Input/Input";

const editButtonCN = (isEditing) => cntl`
  ml-2
  w-6
  h-6
  ${isEditing ? "mt-2" : "mt-0"}
`;

const dragButtonCN = (isEditing) => cntl`
  w-3
  mr-4
  ${isEditing ? "mt-3" : "mt-1"}
`;

const deleteButtonCN = (isEditing) => cntl`
  ml-2
  w-5
  h-5
  ${isEditing ? "mt-3" : "mt-1"}
`;

const containerCN = (className) => cntl`
  flex
  flex-row
  py-1
  ${className}
`;

const inputCN = (className) => cntl`
  py-1
  ${className}
`;

const ItemTypes = {
  CARD: "card",
};
const DocumentTile = ({
  doc,
  index,
  moveItem,
  toggleEditing,
  onRemoveDocument,
  onDocumentChange,
  className,
  inputClassName,
  handleEnter,
  handleBlur,
  setAddMode,
}) => {
  const ref = useRef(null);
  const [showActionIcons, setShowActionIcons] = useState(false);

  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    drop(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const dropIndex = index;
      // Don't replace items with themselves
      if (dragIndex === dropIndex) {
        return;
      }
      moveItem(dragIndex, dropIndex);
    },
  });

  const [, drag, preview] = useDrag({
    item: { type: ItemTypes.CARD, id: doc?.value, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(preview(ref));

  const handleMouseEnter = useMemo(
    () => debounce(() => setShowActionIcons(true), 300),
    [setShowActionIcons]
  );

  const handleMouseLeave = useMemo(
    () => debounce(() => setShowActionIcons(false), 300),
    [setShowActionIcons]
  );

  return (
    <div
      className={containerCN(className)}
      ref={ref}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={dragButtonCN(doc?.isEditing)}>
        <img
          ref={drag}
          className="h-4 cursor-move mt-1 select-none"
          src={dragIcon}
          alt="drag icon"
          draggable={false}
        />
      </div>
      {(doc?.isEditing && (
        <div className={inputCN(inputClassName)}>
          <Input
            placeholder="Qualification & Exclusion"
            value={doc.label}
            onChange={(value) => onDocumentChange(value, index)}
            handleEnter={(event) => handleEnter(event, doc.label, index)}
            onBlur={() => handleBlur(doc.label, index)}
            autoFocus
          />
        </div>
      )) || (
        <div className="py-1">
          <p className="">{doc.label}</p>
        </div>
      )}
      {showActionIcons && (
        <>
          <div className="flex">
            <button
              className={editButtonCN(doc?.isEditing)}
              type="button"
              onClick={() => {
                setAddMode(false);
                toggleEditing(index);
              }}
            >
              <img
                className="w-8 cursor-pointer"
                src={editIcon}
                alt="edit data"
              />
            </button>
          </div>
          <div className="flex">
            <button
              className={deleteButtonCN(doc?.isEditing)}
              type="button"
              onClick={() => onRemoveDocument(index)}
            >
              <img
                className="w-8 cursor-pointer"
                src={removeIcon}
                alt="remove data"
              />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

DocumentTile.propTypes = {
  doc: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    isEditing: PropTypes.string,
  }),
  index: PropTypes.number,
  moveItem: PropTypes.func,
  toggleEditing: PropTypes.func,
  onRemoveDocument: PropTypes.func,
  onDocumentChange: PropTypes.func,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  handleEnter: PropTypes.func,
  handleBlur: PropTypes.func,
  setAddMode: PropTypes.func,
};

DocumentTile.defaultProps = {
  doc: undefined,
  index: undefined,
  moveItem: undefined,
  toggleEditing: undefined,
  onRemoveDocument: undefined,
  onDocumentChange: undefined,
  className: undefined,
  inputClassName: undefined,
  handleEnter: undefined,
  handleBlur: undefined,
  setAddMode: undefined,
};

export default DocumentTile;

import PropTypes from "prop-types";
import React from "react";
import CalendarCheckbox from "../CalendarCheckbox/CalendarCheckbox";
import CalendarProjects from "../CalendarProjects";
import MiniCalendar from "./MiniCalendar";
import useCurrentUser from "../../../hooks/useCurrentUser";
import hasReadPermission from "../../../helpers/Permissions/hasReadPermissions";
import { PROJECT } from "../../../constants";
import ToggleMenuView from "./ToggleMenuView";

export default function CalendarSidebar({
  selectedEventType,
  setSelectedEventType,
  setSelectedProjectIds,
  selectedProjectIds,
  reloadEvents,
  tasksOpen,
  toggleOpenSidebar,
}) {
  const { data: currentUser } = useCurrentUser();

  return (
    <>
      <ToggleMenuView
        showCloseButton
        onCloseClick={() => toggleOpenSidebar()}
      />
      <div
        id="sidebar-container"
        className="flex flex-col gap-6 mt-8"
        style={{ width: "254px" }}
      >
        <MiniCalendar reloadEvents={reloadEvents} />
        <div
          id="calendar-event-types"
          className="grid grid-cols-2 gap-y-6 border-b-2 pb-6"
        >
          <CalendarCheckbox
            selectedEventType={selectedEventType}
            setSelectedEventType={setSelectedEventType}
            title="Events"
          />
          <CalendarCheckbox
            selectedEventType={selectedEventType}
            setSelectedEventType={setSelectedEventType}
            title="Tasks"
          />
        </div>

        {hasReadPermission(PROJECT, currentUser) && (
          <CalendarProjects
            setSelectedProjectIds={setSelectedProjectIds}
            selectedProjectIds={selectedProjectIds}
            tasksOpen={tasksOpen}
          />
        )}
      </div>
    </>
  );
}

CalendarSidebar.propTypes = {
  selectedEventType: PropTypes.shape({
    task: PropTypes.bool,
    event: PropTypes.bool,
  }),
  setSelectedProjectIds: PropTypes.func.isRequired,
  selectedProjectIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedEventType: PropTypes.func,
  reloadEvents: PropTypes.func,
  toggleOpenSidebar: PropTypes.func,
  tasksOpen: PropTypes.bool,
};

CalendarSidebar.defaultProps = {
  selectedEventType: {
    task: true,
    event: true,
  },
  setSelectedEventType: undefined,
  reloadEvents: () => {},
  toggleOpenSidebar: () => {},
  tasksOpen: false,
};

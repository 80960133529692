/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FileAPI } from "@griffingroupglobal/eslib-api";
import { uploadFile } from "../../../helpers/File";
import { formatMediaData } from "../../../helpers/Formatters";
import PureFileUpload from "./PureFileUpload";
import useDocumentsConfiguration from "../../../hooks/useDocumentsConfiguration";

const FileUpload = ({
  resource,
  onAddDocument: onAddDocumentProp,
  onDeleteDocument,
  handleCloseUploader,
  handleFileUpload,
  showSpinner,
}) => {
  const { data: documentsConfiguration } = useDocumentsConfiguration();
  const [documents, setDocuments] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [documentsToUploads, setDocumentsToUpload] = useState([]);

  useEffect(() => {
    if (documentsConfiguration?.documents) {
      setDocumentTypes(
        documentsConfiguration?.documents.documentType
          .filter((doc) => doc.custom && doc.selected)
          .map((doc) => {
            return { label: doc.display, value: doc.id };
          })
          .sort((a, b) => a.label - b.label)
      );
    }
  }, [documentsConfiguration]);

  useEffect(() => {
    setDocumentsToUpload((prev) => {
      if (prev.length) {
        return [
          ...documents.map((doc) => {
            if (doc.documentType) {
              return doc;
            }

            const existingDocType = prev.find(
              (docToUpload) => docToUpload.id === doc.id
            )?.documentType;
            return {
              ...doc,
              documentType: existingDocType,
            };
          }),
        ];
      }
      return documents;
    });
  }, [documents]);

  useEffect(() => {
    const getDocUrls = async () => {
      const resolvedDocs = await formatMediaData(
        resource?.files?.map((d) => ({ file: d }))
      );
      setDocuments(resolvedDocs ?? []);
    };

    getDocUrls();
  }, [resource?.files]);

  const onAddDocument = async (doc, progressCallback) => {
    const fileRef = await uploadFile(doc, progressCallback);
    onAddDocumentProp(fileRef);
  };

  const onUpload = async (files, progressCallback) => {
    await Promise.all(
      files.map(async (file) => {
        await onAddDocument(file, (loaded, total) =>
          progressCallback(loaded, total, file.name)
        );
      })
    );
  };

  const deleteFile = async (id) => {
    await FileAPI.delete(id);
  };

  const onRemove = (fileRef) => {
    deleteFile(fileRef.split("File/")[1]);
    onDeleteDocument(fileRef);
  };

  const updateFileName = async (id, oldName, newName) => {
    await FileAPI.patch(
      id,
      {
        name: newName,
      },
      {
        name: oldName,
      },
      {
        date: new Date(),
      }
    );
  };

  const onEdit = (file, name) => {
    const fileRef = file.id;
    updateFileName(fileRef.split("File/")[1], file.name, name);

    setDocuments((prev) =>
      prev.map((doc) => {
        if (doc.id === file.id) {
          const updatedDoc = doc;
          updatedDoc.name = name;
          return updatedDoc;
        }
        return doc;
      })
    );
  };

  const onEditDocumentType = (val, index) => {
    setDocuments((prev) =>
      prev.map((doc, idx) => {
        if (idx === index) {
          return {
            ...doc,
            documentType: val,
          };
        }
        return doc;
      })
    );
  };

  return (
    <PureFileUpload
      documents={documentsToUploads}
      onUpload={onUpload}
      onEdit={onEdit}
      onRemove={onRemove}
      handleCloseUploader={handleCloseUploader}
      handleFileUpload={() => handleFileUpload(documentsToUploads)}
      showSpinner={showSpinner}
      documentTypes={documentTypes}
      onEditDocumentType={onEditDocumentType}
    />
  );
};

FileUpload.propTypes = {
  resource: PropTypes.shape({
    files: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onAddDocument: PropTypes.func,
  onDeleteDocument: PropTypes.func,
  handleCloseUploader: PropTypes.func,
  /**
   * function to handle file upload
   */
  handleFileUpload: PropTypes.func,
  /**
   * shows/hides loading spinner
   */
  showSpinner: PropTypes.bool,
};

FileUpload.defaultProps = {
  onAddDocument: undefined,
  onDeleteDocument: undefined,
  handleCloseUploader: undefined,
  handleFileUpload: undefined,
  showSpinner: false,
};

export default FileUpload;

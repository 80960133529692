import React, { useRef, useState } from "react";
import cntl from "cntl";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import BaseButton from "../Buttons/BaseButton";
import Input from "../Input/Input";
import Table from "../Table/Table";
import Widget from "../Widget/Widget";
import Tag from "../Tags/Tag";
import ManagementTagsTableEditCell from "./ManagementTagsTableEditCell";
import crossIcon from "../../assets/images/Close.svg";
import useTagFormReducer from "../../../hooks/useTagFormReducer";
import PrimaryButton from "../Buttons/PrimaryButton";
import trashDarkenedGreenIcon from "../../assets/images/trashDarkenedGreenIcon.svg";
import trashDisabledIcon from "../../assets/images/trashDisabledIcon.svg";
import IconButton from "../Buttons/IconButton";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import { sanitizeLabel } from "../../../helpers/Tag";

const tertiaryButtonCN = cntl`
  text-darkenedGreen
`;

const errorMessageCN = cntl`
  text-xs
  text-brandRed
  italic
`;

const ManagementTags = ({ tagsSettings, dispatchTagsSettings }) => {
  const wrapperRef = useRef(null);
  const [selectedTag, dispatch] = useTagFormReducer();
  const [validationError, setValidationError] = useState();

  const closePopup = () => {
    dispatch({
      type: "reset",
    });

    setValidationError();
  };

  useOutsideAlerter(wrapperRef, () => closePopup());

  const onSave = () => {
    const sanitized = sanitizeLabel(selectedTag.currentOpenLabel);

    if (selectedTag.isNew) {
      dispatchTagsSettings({
        type: "addTag",
        value: selectedTag.currentOpenLabel,
      });

      closePopup();
    } else {
      const isExisting = tagsSettings.find((tag) => tag.label === sanitized);
      if (isExisting && selectedTag.id !== isExisting.id) {
        setValidationError("Tag already exists");
      } else {
        dispatchTagsSettings({
          type: "changeTagLabel",
          tagId: selectedTag.id,
          value: selectedTag.currentOpenLabel,
        });

        closePopup();
      }
    }
  };

  const onDelete = () => {
    dispatchTagsSettings({
      type: "removeTag",
      tagId: selectedTag.id,
    });

    closePopup();
  };

  const tableColumns = React.useMemo(() => {
    const columns = [
      {
        Header: "",
        accessor: "edit",
        isPrimary: true,
        Cell: ({ row }) => (
          <ManagementTagsTableEditCell row={row} dispatch={dispatch} />
        ),
        maxWidth: 20,
      },
      {
        Header: "Name",
        accessor: "currentLabel",
        isPrimary: true,
        minWidth: 100,
        Cell: ({ row }) => {
          return (
            <Tag
              tag={{
                label: row.original.currentLabel,
                value: row.original.id,
              }}
              size="30"
              className="py-1 px-2"
            />
          );
        },
      },
      {
        Header: "Count",
        accessor: "count",
      },
    ];

    return columns;
  }, [dispatch]);

  return (
    <Widget
      hideBorder
      draggable={false}
      title={null}
      className="relative -top-6"
    >
      <div className="flex flex-row justify-end">
        <BaseButton
          title="+ Add Tag"
          onClick={() => {
            dispatch({
              type: "reset",
              tag: {
                label: "",
                currentLabel: "",
                id: uuidv4(),
                isNew: true,
              },
            });
          }}
          className={tertiaryButtonCN}
        />
      </div>
      <Table
        className="max-h-96 overflow-scroll"
        resourceName="tags-table"
        data={tagsSettings.filter((tag) => !tag.wasRemoved)}
        columns={tableColumns}
        hideCreateNewButton
        hideChangeView
        hideSaveButton
        hideGroupedFooter
        hideSwitchView
        hideSiteHeaderActionButtons
        hideSiteHeaderDropdownContainer
        hideSiteHeaderTitle
        showConstantRowIndex
        disableCreateTemplate
      />
      {selectedTag?.id && (
        <div
          ref={wrapperRef}
          style={{ width: "350px" }}
          className="right-10 top-8 flex flex-col rounded-lg border border-gray-150 z-50 bg-white absolute px-6 pt-6 pb-4 shadow-lg"
        >
          <div className="flex justify-between">
            <div className="flex py-2">
              <p className="font-bold text-xs">Edit Tag Name</p>
            </div>
            <BaseButton
              onClick={() => {
                closePopup();
              }}
              iconLeft={
                <img
                  src={crossIcon}
                  alt="crossIcon"
                  style={{ height: 8, width: 10 }}
                />
              }
            />
          </div>
          <div className="flex flex-col py-4">
            <p className={errorMessageCN}>{validationError}</p>
            <Input
              value={selectedTag?.currentOpenLabel}
              onChange={(value) => {
                dispatch({
                  type: "setCurrentOpenLabel",
                  currentOpenLabel: value,
                });
              }}
              placeholder="Tag name"
            />
            <div className="flex justify-start pt-6">
              <div className="flex items-center">
                <PrimaryButton
                  title="Save"
                  saveButton
                  onClick={onSave}
                  disabled={!selectedTag?.currentOpenLabel?.trim()}
                />
                {!selectedTag?.isNew && (
                  <IconButton
                    className="ml-5"
                    icon={
                      selectedTag?.count
                        ? trashDisabledIcon
                        : trashDarkenedGreenIcon
                    }
                    onClick={onDelete}
                    disabled={selectedTag?.count}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Widget>
  );
};

ManagementTags.propTypes = {
  tagsSettings: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      wasAdded: PropTypes.bool,
      wasUpdated: PropTypes.number,
      reference: PropTypes.bool,
    })
  ),
  dispatchTagsSettings: PropTypes.func,
};

ManagementTags.defaultProps = {
  tagsSettings: [],
  dispatchTagsSettings: undefined,
};

export default ManagementTags;

/* eslint-disable no-param-reassign */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Table from "../Table/Table";
import usePagePersistance from "../../../hooks/usePagePersistence";

const PureTimeOffTable = ({
  searchOverRide,
  setSearchOverRide,
  originalEntries,
  selectedRows,
  columns,
  customSiteHeader,
  loading,
}) => {
  const { pageState, setPersistentPageItem } = usePagePersistance();

  const showSingleUser = (userRef) => {
    const userId = userRef.split("/")[1];
    setPersistentPageItem("timesheet", { ...pageState.timesheet, userId });
  };

  const templateOptions = useMemo(
    () => [
      {
        custom: false,
        id: "7aad139a-3264-4958-930a-ad9c354533c4",
        filters: [
          {
            value: {
              values: ["pending"],
              isTrue: true,
              type: "is",
              from: "2023-09-09T13:56:02.377Z",
              to: "2023-09-09T13:56:02.377Z",
              valueLabels: {
                Pending: "Pending",
              },
            },
            id: "status",
          },
        ],
        groups: [],
        columns: [
          {
            label: "Employee",
            value: "employee.fullname",
          },
          {
            label: "Date",
            value: "date-requested",
          },
          {
            label: "status",
            value: "status",
          },
          {
            label: "Approve / Reject",
            value: "approvalStatus",
          },
          {
            label: "Date Range",
            value: "date-range",
          },
          {
            label: "Amount",
            value: "total",
          },
          {
            label: "Type",
            value: "pto-type",
          },
          {
            label: "Note",
            value: "note",
          },
          {
            label: "Balance",
            value: "balance",
          },
          {
            label: "Action",
            value: "action",
          },
        ],
        name: "Pending Approval",
        isAdmin: true,
        hideGroupedFooter: true,
      },
      {
        custom: false,
        id: "7aad139a-3264-4958-930a-ad9c354533c2",
        filters: [],
        groups: [
          {
            label: "Employee",
            value: "employee.fullname",
          },
        ],
        columns: [
          {
            label: "Employee",
            value: "employee.fullname",
          },
          {
            label: "Date",
            value: "date-requested",
          },
          {
            label: "status",
            value: "status",
          },
          {
            label: "Approve / Reject",
            value: "approvalStatus",
          },
          {
            label: "Date Range",
            value: "date-range",
          },
          {
            label: "Amount",
            value: "total",
          },
          {
            label: "Type",
            value: "pto-type",
          },
          {
            label: "Note",
            value: "note",
          },
          {
            label: "Balance",
            value: "balance",
          },
          {
            label: "Action",
            value: "action",
          },
        ],
        name: "Request by Employee",
        isAdmin: true,
        hideGroupedFooter: true,
      },
      {
        custom: false,
        id: "7aad139a-3264-4958-930a-ad9c354633c3",
        filters: [],
        groups: [],
        columns: [
          {
            label: "Employee",
            value: "employee.fullname",
          },
          {
            label: "status",
            value: "status",
          },
          {
            label: "Approve / Reject",
            value: "approvalStatus",
          },
          {
            label: "Date",
            value: "date-requested",
          },
          {
            label: "Date Range",
            value: "date-range",
          },
          {
            label: "Amount",
            value: "total",
          },
          {
            label: "Type",
            value: "pto-type",
          },
          {
            label: "Note",
            value: "note",
          },
          {
            label: "Balance",
            value: "balance",
          },
          {
            label: "Action",
            value: "action",
          },
        ],
        name: "View All",
        isAdmin: true,
        hideGroupedFooter: true,
      },
    ],
    []
  );

  // add second key:value for 'status' to show it in columns when its also being grouped
  const tableData = originalEntries?.map((item) => {
    const newEntry = { ...item };
    newEntry.approvalStatus = item.status;
    return newEntry;
  });

  return (
    <Table
      searchOverRide={searchOverRide}
      setSearchOverRide={setSearchOverRide}
      isLoading={loading}
      disableDragging
      resourceName="timeoff"
      data={tableData}
      columns={columns}
      onRowClick={(row) => showSingleUser(row.values.user)}
      initialSelectedRows={selectedRows}
      defaultSort={[{ id: "dayNumber", desc: false }]}
      headerCellStyle="important-justify-center"
      cellStyling="h-20 flex justify-center items-center"
      cellTextStyling="flex w-full justify-center pr-5 text-base font-light text-gray-300"
      hiddenColumns={["dayNumber"]}
      customSiteHeader={customSiteHeader}
      disableCreateTemplate
      disableClearView
      templateSettings={templateOptions}
      initialTemplateViewName="View All"
      hideSiteHeaderTitle
      showConstantRowIndex
      allowSelection
      pagination
      fullWidthSearch
    />
  );
};

PureTimeOffTable.propTypes = {
  searchOverRide: PropTypes.string,
  setSearchOverRide: PropTypes.func,
  originalEntries: PropTypes.arrayOf(PropTypes.shape({})),
  selectedRows: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  customSiteHeader: PropTypes.func,
  loading: PropTypes.bool,
};

PureTimeOffTable.defaultProps = {
  searchOverRide: "",
  setSearchOverRide: () => {},
  originalEntries: [],
  selectedRows: [],
  columns: undefined,
  customSiteHeader: undefined,
  loading: true,
};

export default PureTimeOffTable;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import WorkflowsOverview from "../Workflows/WorkflowsOverview";
import { getPropertyOptions } from "../../../helpers/SiteHeaderOptions";
import useCurrentUser from "../../../hooks/useCurrentUser";
import usePropertyById from "../../../hooks/usePropertyById";
import { resolvePrimaryImage } from "../../../helpers/File";
import DetailViewBanner from "../../../stories/Components/DetailViewBanner/DetailViewBanner";
import { GET_PROPERTY_WORKFLOW_CREATE_PATH } from "../../../constants";

const PropertyWorkflows = ({ isDraftView }) => {
  const history = useHistory();
  const { propertyId } = useParams();
  const { property } = usePropertyById(propertyId);
  const { data: currentUser } = useCurrentUser();
  const [propertyImage, setPropertyImage] = useState();

  useEffect(() => {
    if (property?.images?.length) {
      resolvePrimaryImage(property.images)
        .then((imageFile) => setPropertyImage(imageFile?.contentsUrl))
        .catch((err) =>
          console.error(`Unable to resolve property primary image:${err}`)
        );
    }
  }, [property]);

  const buttonActions = [
    {
      title: "+ Add Workflow",
      onClick: () =>
        history.push(GET_PROPERTY_WORKFLOW_CREATE_PATH(propertyId)),
    },
  ];

  return (
    <WorkflowsOverview
      buttonActions={buttonActions}
      isDraftView={isDraftView}
      associatedResource={`Property/${propertyId}`}
      siteHeaderProps={{
        title: property?.title || "Loading",
        dropdownRoutes: getPropertyOptions(propertyId, currentUser),
      }}
      bannerComponent={
        <DetailViewBanner
          resource={property}
          resourceImage={propertyImage}
          disableUpload
        />
      }
    />
  );
};

export default PropertyWorkflows;

// Framework & Tools
import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { capitalize } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";

// Helpers

// Constants
import { WF_STATUS_TYPES } from "../../../constants";

// Components
import PrimaryButton from "../Buttons/PrimaryButton";
import Input from "../Input/Input";
import Modal from "../Modal/Modal";
import { toastError, toastMessage } from "../Toast/Toast";
import { useAppState } from "../../../state/appState";
import commentDarkIcon from "../../assets/images/commentDarkIcon.svg";
import { getFullName } from "../../../helpers/Formatters";
import Pill from "../Pill/Pill";
import { statusPillCN } from "../../../helpers/ServiceRequest/formatTasks";

const buttonClassName = "border-none font-normal text-brandDarkGreen px-3 pr-1";
const chevronClassName = "border-none";
const chevronColor = "#11765F";
const actionLabelCN = "pl-0 pr-0 font-normal text-brandDarkGreen";
export default function WorkflowDetailViewCard({
  step,
  index,
  activeStep,
  requestData,
  onSubmit,
  onStepAction,
  complete,
  handleSendReminder,
  setStepTasksViewData,
  stepStatus,
  isInProgress,
  canComplete,
  setShowStepDetails,
  userAction,
}) {
  // hooks
  const [{ userDict, tasksDict }] = useAppState();
  // State to track action values
  const [actionValues, setActionValues] = useState({
    requestId: requestData?.id,
    stepId: step?.id,
    status: "",
    action: "",
    comment: "",
  });

  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [tasksCompletedTotal, setTasksCompletedTotal] = useState({
    completedTasks: 0,
    totalTasks: 0,
  });
  const [separatorHeight, setSeparatorHeight] = useState(0);
  const [descriptionHeight, setDescriptionHeight] = useState();
  const [usersHeight, setUsersHeight] = useState();

  const stepContainerRef = useRef();
  const descRef = useRef();
  const usersRef = useRef();

  // set separators height from height of step container
  // Setting the height with Tailwinds style will not work
  // because the height of the parent is not a fixed value
  const separatorStyle = useMemo(() => {
    return {
      borderRight: "1px solid #D0D0D0",
      height: `${separatorHeight}px`,
    };
  }, [separatorHeight]);

  useEffect(() => {
    setDescriptionHeight(descRef?.current?.clientHeight);
    setUsersHeight(usersRef?.current?.clientHeight);
  }, [
    descRef?.current?.clientHeight,
    usersRef?.current?.clientHeight,
    setDescriptionHeight,
    setUsersHeight,
  ]);

  useEffect(() => {
    if (
      descRef?.current?.clientHeight !== descriptionHeight ||
      usersRef?.current?.clientHeight !== usersHeight
    )
      setSeparatorHeight(0);
  }, [
    descriptionHeight,
    usersHeight,
    descRef?.current?.clientHeight,
    usersRef?.current?.clientHeight,
    setSeparatorHeight,
  ]);

  useEffect(() => {
    if (separatorHeight === 0) {
      setSeparatorHeight(stepContainerRef?.current?.clientHeight);
    }
  }, [separatorHeight, stepContainerRef?.current?.clientHeight]);

  // Handle opening the comment modal
  const handleOpenCommentModal = useCallback(
    (actionStatus, action) => {
      setActionValues((prev) => {
        return { ...prev, status: actionStatus, action };
      });
      setCommentModalOpen(true);
    },
    [setCommentModalOpen]
  );

  // Memoized callback for generating dropdown options
  const handleDropdownOptions = useMemo(() => {
    return [
      {
        title: "Approved",
        onClick: () => {
          handleOpenCommentModal("approved", "respond");
        },
      },
      {
        title: "Approved Resubmit Req.",
        onClick: () => {
          handleOpenCommentModal(
            "approved-resubmission-required",
            "respond",
            "Approved Resubmit Req."
          );
        },
      },
      {
        title: "Approved No Resubmit Req.",
        onClick: () => {
          handleOpenCommentModal(
            "approved-resubmission-not-required",
            "respond"
          );
        },
      },
      {
        title: "Revise and Resubmit",
        onClick: () => {
          handleOpenCommentModal("revise-and-resubmit", "respond");
        },
      },
      {
        title: "Reject",
        onClick: () => {
          handleOpenCommentModal("rejected", "respond");
        },
      },
      {
        title: "Void",
        onClick: () => {
          handleOpenCommentModal("void", "respond");
        },
      },
    ];
  }, [handleOpenCommentModal]);

  // Memoized callback for generating dropdown options
  const handleAssigneeDropdownOptions = useMemo(() => {
    return [
      {
        title: "Complete",
        onClick: () => {
          handleOpenCommentModal("complete", "respond");
        },
      },
      {
        title: "Mark as Incomplete",
        onClick: () => {
          handleOpenCommentModal("incomplete", "respond");
        },
      },
      {
        title: "Void",
        onClick: () => {
          handleOpenCommentModal("void", "respond");
        },
      },
    ];
  }, [handleOpenCommentModal]);
  // Generate button actions based on the type
  const buttonActions = useCallback(
    (type, disabled) => {
      switch (type) {
        case "submitter": {
          return (
            requestData?.status !== WF_STATUS_TYPES?.DRAFT && (
              <PrimaryButton
                buttonClassName={buttonClassName}
                chevronClassName={chevronClassName}
                chevronColor={chevronColor}
                actionLabelCN={actionLabelCN}
                saveButton
                saveButtonTitle="Submit"
                className="reviewBtn"
                onClick={() => {
                  handleOpenCommentModal("submit", "submit", "Submit");
                }}
                disabled={!disabled}
              />
            )
          );
        }
        case "reviewer": {
          return (
            <div id="reviewBtns" className="flex">
              <PrimaryButton
                background="lightGreen"
                buttonClassName={buttonClassName}
                chevronClassName={chevronClassName}
                chevronColor={chevronColor}
                actionLabelCN={actionLabelCN}
                actionLabel="Respond"
                saveButton
                saveButtonTitle="Respond"
                id="advance"
                dropdownItems={[
                  {
                    title: "Advance",
                    onClick: () => {
                      handleOpenCommentModal("advance", "advance", "Advance");
                    },
                  },
                  {
                    title: "Initiate New Round",
                    onClick: () => {
                      handleOpenCommentModal(
                        "return-to-submitter",
                        "returntosubmitter",
                        "Initiate New Round"
                      );
                    },
                  },
                ]}
                disabled={!disabled}
              />
            </div>
          );
        }
        case "approver": {
          return (
            <PrimaryButton
              buttonClassName={buttonClassName}
              chevronClassName={chevronClassName}
              chevronColor={chevronColor}
              actionLabelCN={actionLabelCN}
              actionLabel="Respond"
              saveButton
              saveButtonTitle="Respond"
              hideDropdownChevron
              dropdownItems={handleDropdownOptions}
              title="Respond"
              disabled={!disabled}
            />
          );
        }
        case "assignee": {
          return (
            <PrimaryButton
              buttonClassName={buttonClassName}
              chevronClassName={chevronClassName}
              chevronColor={chevronColor}
              actionLabelCN={actionLabelCN}
              actionLabel="Respond"
              saveButton
              saveButtonTitle="Respond"
              hideDropdownChevron
              dropdownItems={handleAssigneeDropdownOptions}
              title="Respond"
              disabled={!disabled}
            />
          );
        }
        case "reminder": {
          return (
            <PrimaryButton
              saveButton
              saveButtonTitle="Send Reminder"
              onClick={() =>
                handleOpenCommentModal(
                  "remind",
                  "sendreminder",
                  "Send a reminder"
                )
              }
            />
          );
        }
        default: {
          return <div />;
        }
      }
    },
    [
      requestData?.status,
      handleOpenCommentModal,
      handleAssigneeDropdownOptions,
      handleDropdownOptions,
    ]
  );

  // Check if the user can complete the step

  // Handle submittal actions
  const handleStepActions = useCallback(async () => {
    const id = requestData?.id;
    setDisableSaveButton(true);

    const stepId = step?.id;
    const remark = actionValues.comment;
    if (actionValues.action === "submit") {
      await onSubmit({
        id,
        stepId,
        comment: remark,
      });
      toastMessage("Submission operation was successful");
      setDisableSaveButton(false);
      setCommentModalOpen(false);
      setActionValues({});
    }
    if (actionValues.action === "advance") {
      try {
        await onStepAction({
          id,
          stepId,
          stepStatus: actionValues.status,
          stepAction: actionValues.action,
          comment: remark,
        });
        toastMessage("Advance operation was successful");
      } catch (e) {
        console.error(e.message);
        toastError("Advance operation failed. Please try again.");
      } finally {
        setCommentModalOpen(false);
      }
    }
    if (actionValues.action === "respond") {
      try {
        await onStepAction({
          id,
          stepId,
          stepStatus: actionValues.status,
          stepAction: actionValues.action,
          comment: remark,
        });
        toastMessage("Respond operation was successful.");
      } catch (e) {
        console.error(e.message);
        toastError("Respond operation failed. Please try again.");
      } finally {
        setCommentModalOpen(false);
      }
    }
    if (actionValues.action === "returntosubmitter") {
      try {
        await onStepAction({
          id,
          stepId,
          stepStatus: actionValues.status,
          stepAction: actionValues.action,
          comment: remark,
        });
        toastMessage("Initiate new round operation was successful");
      } catch (e) {
        console.error(e.message);
        toastError("Initiate new round operation failed. Please try again.");
      } finally {
        setCommentModalOpen(false);
      }
    }
    if (actionValues.action === "sendreminder") {
      const userArr = step?.users?.map((usr) => usr?.reference);
      handleSendReminder(actionValues.comment, userArr);
      setCommentModalOpen(false);
      setActionValues({});
    }
  }, [
    requestData?.id,
    step?.id,
    step?.users,
    actionValues.comment,
    actionValues.action,
    actionValues.status,
    onSubmit,
    onStepAction,
    handleSendReminder,
  ]);
  useEffect(() => {
    const getTasks = async (stepTasks = []) => {
      let completedTasks = 0;
      stepTasks.forEach((task) => {
        const taskRecurrences = Object.keys(tasksDict)?.filter((key) =>
          key?.includes(`${task?.split("/")?.[1]}&instanceStartDate`)
        );
        if (taskRecurrences?.length) {
          // task complete if all recurrences complete
          const allOccurrencesComplete = taskRecurrences?.every((instense) => {
            const taskOccurrence = tasksDict[instense];
            return taskOccurrence?.status === "done";
          });
          if (allOccurrencesComplete) completedTasks += 1;
        } else {
          // non-recurring task
          const realTask = tasksDict[task?.split("/")?.[1]];
          if (realTask?.status === "done") completedTasks += 1;
        }
      });
      setTasksCompletedTotal({
        completedTasks,
        totalTasks: stepTasks?.length,
      });
    };
    getTasks(step?.tasks);
  }, [tasksDict, step, step?.tasks]);

  const getFormattedStatus = (status) => {
    switch (status) {
      case "Completed":
        return "Complete";
      case "Action Pending":
        return "in-progress";
      default:
        return status;
    }
  };

  return (
    <div
      ref={stepContainerRef}
      style={{ minWidth: "1491px" }}
      className="pl-3 border flex items-center rounded-lg gap-6"
    >
      <div className="relative flex flex-col h-full w-auto items-start py-3">
        <div
          ref={descRef}
          className="flex flex-col justify-between"
          style={{ width: "468px", height: "100%", paddingRight: "15px" }}
        >
          <div className="flex flex-col">
            <button
              type="button"
              onClick={useCallback(() => {
                setStepTasksViewData({
                  id: step?.id,
                  show: true,
                  tasks: step?.tasks,
                  index: index + 1,
                  status: stepStatus,
                  role: capitalize(step?.users?.[0]?.type),
                  users: step?.users,
                  attachments: step.attachments,
                  dueDate: moment(step?.dueDate?.projected).format(
                    "MMMM DD, YYYY"
                  ),
                  description: step?.description,
                });
                setShowStepDetails(true);
              }, [
                setStepTasksViewData,
                setShowStepDetails,
                index,
                stepStatus,
                step,
              ])}
            >
              <span className="flex justify-start text-md text-brandDarkGreen font-semibold underline">
                Step {index + 1}
              </span>
            </button>
          </div>
          {step?.description && (
            <p className="text-gray-450 text-base font-normal truncate">
              {step?.description ?? ""}
            </p>
          )}
          {userAction?.latestAction && (
            <p className="flex items-start font-semibold">
              {userAction?.latestAction}
            </p>
          )}
          <div className="flex">
            <img
              src={commentDarkIcon}
              className="w-5 h-5 mt-1 mr-1"
              alt="Step action taken"
            />
            <p className="truncate">{userAction?.latestComment}</p>
          </div>
        </div>
      </div>
      <div style={separatorStyle} />
      <div className="flex flex-col h-full items-start justify-center">
        <div style={{ width: "96px" }}>
          <p className="text-md font-semibold text-gray-450">Tasks</p>
          <p className="text-md">{`${tasksCompletedTotal.completedTasks}/${tasksCompletedTotal.totalTasks}`}</p>
        </div>
      </div>
      <div style={separatorStyle} />
      <div className="relative flex flex-col h-auto items-start">
        <p className="text-md font-semibold text-gray-450">
          {complete ? "Completed On" : "Due On"}
        </p>
        <p className="w-44 text-md font-normal text-gray-450">
          {" "}
          {moment(complete ? step?.endDate : step?.dueDate?.projected).format(
            "MMMM DD, YYYY"
          )}
        </p>
      </div>
      <div style={separatorStyle} />
      <div
        ref={usersRef}
        className="flex flex-col justify-center items-start w-44 py-3"
      >
        <div className="flex flex-col items-start" style={{ width: "180px" }}>
          <p className="w-28 text-md font-semibold text-gray-450">
            {capitalize(step?.users?.[0]?.type)}
          </p>
          <p className="flex flex-col text-md font-normal text-gray-450">
            {step?.users?.[0]?.reference &&
              step?.users?.map((user) => (
                <p key={user?.reference}>
                  {getFullName(userDict?.[user?.reference]?.name)}
                </p>
              ))}
          </p>
        </div>
      </div>
      <div style={separatorStyle} />
      <div className="flex gap-6 w-36">
        <Pill
          value={getFormattedStatus(stepStatus)}
          background="" // TODO: (Parker) Pill component sets bg to bg-grand if no prop is found
          className={`w-36 capitalize ${statusPillCN(stepStatus)}`}
        />
      </div>
      <div style={separatorStyle} />
      <div className="w-full min-h-full flex items-center justify-center mr-6">
        {canComplete &&
          buttonActions(
            step?.users?.[0]?.type,
            isInProgress && activeStep && canComplete
          )}
        {isInProgress &&
          step?.users?.[0]?.reference &&
          activeStep &&
          !canComplete &&
          buttonActions("reminder")}
      </div>

      {commentModalOpen && (
        <Modal
          childContainerClassName="w-192"
          title="Add a Remark"
          isOpen={commentModalOpen}
          onRequestModalClose={() => setCommentModalOpen(false)}
          primaryButtonOnClick={handleStepActions}
          primaryButtonTitle="Save"
          tertiaryButtonTitle="Cancel"
          alert
          disabled={disableSaveButton}
        >
          <Input
            placeholder="Remark"
            autoFocus
            isTextarea
            value={actionValues.comment}
            onChange={(val) =>
              setActionValues((prev) => {
                return { ...prev, comment: val };
              })
            }
          />
        </Modal>
      )}
    </div>
  );
}

WorkflowDetailViewCard.propTypes = {
  step: PropTypes.shape({
    id: PropTypes.string,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        reference: PropTypes.string,
        type: PropTypes.string,
        data: PropTypes.shape({
          name: PropTypes.string,
          companyName: PropTypes.string,
        }),
      })
    ),
    comments: PropTypes.arrayOf(PropTypes.shape({})),
    dueDate: PropTypes.shape({
      projected: PropTypes.string,
      actual: PropTypes.string,
    }),
    endDate: PropTypes.string,
    tasks: PropTypes.arrayOf(PropTypes.string),
    attachments: PropTypes.arrayOf(PropTypes.shape({})),
    description: PropTypes.string,
  }),
  index: PropTypes.number,
  activeStep: PropTypes.bool,
  requestData: PropTypes.shape({
    id: PropTypes.string,
    initiated: PropTypes.shape({ user: PropTypes.string }),
    status: PropTypes.string,
    round: PropTypes.number,
  }),
  onStepAction: PropTypes.func,
  complete: PropTypes.bool,
  handleSendReminder: PropTypes.func,
  attachmentMap: PropTypes.shape({}),
  setAttachmentMap: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  setStepTasksViewData: PropTypes.func,
  stepStatus: PropTypes.string,
  isInProgress: PropTypes.bool,
  canComplete: PropTypes.bool,
  setShowStepDetails: PropTypes.func,
  userAction: PropTypes.shape({
    latestAction: PropTypes.string,
    latestComment: PropTypes.string,
  }),
};

WorkflowDetailViewCard.defaultProps = {
  step: undefined,
  index: undefined,
  activeStep: undefined,
  requestData: undefined,
  onStepAction: undefined,
  complete: undefined,
  handleSendReminder: undefined,
  attachmentMap: undefined,
  setAttachmentMap: undefined,
  onSubmit: undefined,
  setStepTasksViewData: undefined,
  stepStatus: "Complete",
  isInProgress: false,
  canComplete: false,
  setShowStepDetails: undefined,
  userAction: {},
};

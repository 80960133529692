/* eslint-disable import/prefer-default-export */
export const inputWidth = (size) => {
  switch (size) {
    case "sm":
      return "10ch";
    case "md":
      return "20ch";
    case "lg":
      return "30ch";
    default:
      return "20ch";
  }
};

import React from "react";

const BetaTag = () => {
  return (
    <div className="ml-2 text-center text-brandGreen border-brandGreen text-xxs font-semibold border rounded-3xl inline-block px-2 tracking-wider">
      BETA
    </div>
  );
};

export default BetaTag;

import { useState } from "react";
import {
  SETTINGS_PAYROLL_CSV_COLUMNS,
  defaultCsvColumns,
} from "../../../../constants";
import useSettings from "../../../../hooks/useSettings";

const usePayrollCsvModalData = () => {
  const [payrollCsvColumns] = useSettings(SETTINGS_PAYROLL_CSV_COLUMNS);
  const [selectedColumns, setSelectedColumns] = useState(
    payrollCsvColumns ?? defaultCsvColumns
  );

  const handleClearAll = () => {
    setSelectedColumns([]);
  };

  const handleSelectAll = () => {
    setSelectedColumns(defaultCsvColumns);
  };

  const handleRadioToggle = (column) => {
    return () => {
      if (selectedColumns.includes(column)) {
        setSelectedColumns(selectedColumns.filter((col) => col !== column));
      } else {
        setSelectedColumns([...selectedColumns, column]);
      }
    };
  };

  const isChecked = (column) => {
    return selectedColumns.includes(column);
  };

  const allItemsSelected = selectedColumns.length === defaultCsvColumns.length;

  return {
    selectedColumns,
    allItemsSelected,
    isChecked,
    handleSelectAll,
    handleClearAll,
    handleRadioToggle,
  };
};

export default usePayrollCsvModalData;

import { durationTypeOptions } from "../constants";

export const formatProjectTableData = (project, projectTypes, tagsDict) => {
  return {
    ...project,
    projectType:
      projectTypes.find((type) => type.value === project?.projectType)?.label ||
      "",
    currentTags: project?.tags?.map((tagRef) => ({
      label: tagsDict?.[tagRef]?.label,
      value: tagsDict?.[tagRef]?.reference,
    })),
  };
};

export const getDurationValue = (value, type) => {
  let measure = durationTypeOptions?.find((item) => type === item?.value);
  if (!measure) measure = "Day(s)";
  return `${value} ${
    parseInt(value, 10) === 1 ? measure?.label?.split("(s)")[0] : measure?.label
  }`;
};

export const calculateInvoices = (
  depositAmount = "0.00",
  numberOfInvoices = 0
) => {
  return [...Array(numberOfInvoices).keys()].map((item, i) => {
    const amount = depositAmount.replaceAll(",", "");
    let distribution = parseFloat((amount / numberOfInvoices).toFixed(2));
    const remainder = amount - distribution * numberOfInvoices;
    if (i + 1 === numberOfInvoices) distribution += remainder;
    return {
      number: item + 1,
      amount: depositAmount ? distribution : 0,
    };
  });
};

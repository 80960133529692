import { UserAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { toastError } from "../stories/Components/Toast/Toast";
import { userKeys } from "../config/reactQuery/queryKeyFactory";

/**
 * Posting for editing members of a company
 * @param {String} userId id of current company
 * @param {Obj} payload {add: ['User/id'], remove: ['User/id']}
 * @returns data
 */
const postCompanyMembers = async ({ userId, payload }) => {
  const { data } = await UserAPI.postByIdWOP(
    userId,
    "$companymembers",
    payload
  );
  if (!data) return [];

  return data.entries.map(({ resource }) => resource);
};

/**
 * Mutation hook to post/update members of a company
 * @param {string} mutationKey custom key to track mutations
 * @returns mutation metadata
 */
const usePostCompanyMembers = (mutationKey) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey,
    mutationFn: postCompanyMembers,
    onSuccess: (data) => {
      const queryKey = userKeys.users;
      const queryCacheData = queryClient.getQueryData(queryKey);
      const copyOfCache = {
        users: [...queryCacheData?.users],
        userDict: { ...queryCacheData?.userDict },
        companies: [...queryCacheData?.companies],
        employees: [...queryCacheData?.employees],
      };
      // Update Query cache for each updated user
      data.forEach((item) => {
        const indexToUpdate = copyOfCache.users.findIndex(
          (user) => user.id === item.id
        );
        copyOfCache.users[indexToUpdate] = item;
        copyOfCache.userDict[item.reference] = item;
      });
      queryClient.setQueryData(queryKey, copyOfCache);
    },
    onError: () => {
      toastError("Unable to update members");
    },
    onSettled: () => {
      queryClient.invalidateQueries(userKeys.assigness());
    },
  });
};

export default usePostCompanyMembers;

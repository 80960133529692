import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Input from "../../Input/Input";

const RatePerMile = ({ financialsSettings, dispatchFinancialsSettings }) => {
  const defaultRate = financialsSettings?.expense?.ratePerMile || 0;
  const [rpm, setRpm] = useState();

  useEffect(() => {
    setRpm(defaultRate);
  }, [defaultRate]);

  const handleChangeRpm = (value) => {
    setRpm(value);

    dispatchFinancialsSettings({
      type: "expenseRatePerMile",
      ratePerMile: Number(value),
    });
  };

  return (
    <div className="flex items-center justify-start gap-5 mt-3">
      <p>Rate Per Mile</p>
      <Input
        placeholder="Rate"
        type="number"
        value={rpm}
        onChange={(val) => handleChangeRpm(val)}
        minValue={0.67}
      />
    </div>
  );
};

RatePerMile.propTypes = {
  financialsSettings: PropTypes.shape({
    expense: PropTypes.shape({ ratePerMile: PropTypes.number }),
  }),
  dispatchFinancialsSettings: PropTypes.func,
};

RatePerMile.defaultProps = {
  financialsSettings: {},
  dispatchFinancialsSettings: () => {},
};

export default RatePerMile;

import React from "react";

/**
 *
 * @param {String} color - Font Color
 * @param {Boolean} underline - True = Underline, False = None
 * @returns Close Text Icon
 * @summary - Needs parent height defined only and will expand to fill
 */
const CloseElement = ({ color, underline }) => {
  return (
    <svg
      id="Close-Text-Icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 201.45 62.42"
      preserveAspectRatio="xMidYMid meet"
      height="100%"
    >
      <g id="Close">
        <path
          id="C"
          d="M30.1,54.14a29.33,29.33,0,0,1-10.73-1.9A25.31,25.31,0,0,1,5.18,38.59a26,26,0,0,1-2-10.37,26,26,0,0,1,2-10.36,25,25,0,0,1,5.69-8.21,25.67,25.67,0,0,1,8.57-5.44A29.33,29.33,0,0,1,30.17,2.3a29.13,29.13,0,0,1,11.3,2.13,22.24,22.24,0,0,1,8.64,6.3l-6,5.69A18.9,18.9,0,0,0,37.87,12a17.87,17.87,0,0,0-7.27-1.48,19.43,19.43,0,0,0-7.24,1.3,16.75,16.75,0,0,0-5.68,3.67A17.07,17.07,0,0,0,13.9,21.1a18,18,0,0,0-1.37,7.12,18,18,0,0,0,1.37,7.13A17.18,17.18,0,0,0,17.68,41a16.75,16.75,0,0,0,5.68,3.67,19.63,19.63,0,0,0,7.24,1.3,18,18,0,0,0,7.27-1.48A18.42,18.42,0,0,0,44.06,40l6,5.69A22.72,22.72,0,0,1,41.47,52,29,29,0,0,1,30.1,54.14Z"
          fill={color ?? "#fff"}
        />
        <path id="L" d="M58.32,53.42V0h8.93V53.42Z" fill={color ?? "#fff"} />
        <path
          id="O"
          d="M96.33,53.93a21.28,21.28,0,0,1-10.47-2.56,19,19,0,0,1-7.31-7A19.37,19.37,0,0,1,75.89,34.2,18.87,18.87,0,0,1,85.82,17a21.35,21.35,0,0,1,10.51-2.56A21.67,21.67,0,0,1,107,17a18.94,18.94,0,0,1,7.28,7,19.5,19.5,0,0,1,2.62,10.22,19.6,19.6,0,0,1-2.62,10.19,18.82,18.82,0,0,1-7.31,7A21.66,21.66,0,0,1,96.33,53.93Zm0-7.63a11.78,11.78,0,0,0,5.91-1.48,10.37,10.37,0,0,0,4.07-4.25,13.39,13.39,0,0,0,1.47-6.37,13.12,13.12,0,0,0-1.47-6.37,10.56,10.56,0,0,0-4.07-4.18,12.32,12.32,0,0,0-11.67,0,10.84,10.84,0,0,0-4.1,4.18A12.8,12.8,0,0,0,85,34.2a13.07,13.07,0,0,0,1.51,6.37,10.63,10.63,0,0,0,4.1,4.25A11.49,11.49,0,0,0,96.33,46.3Z"
          fill={color ?? "#fff"}
        />
        <path
          id="S"
          d="M137.45,53.93a33.47,33.47,0,0,1-9.29-1.26,25,25,0,0,1-7.06-3.06l3.46-6.84a24,24,0,0,0,6.19,2.7,26.18,26.18,0,0,0,7.27,1q4.17,0,6.09-1.11a3.42,3.42,0,0,0,1.9-3.06,2.71,2.71,0,0,0-1.29-2.42,10.27,10.27,0,0,0-3.39-1.26c-1.39-.28-2.94-.55-4.64-.79s-3.41-.56-5.11-1a17.85,17.85,0,0,1-4.65-1.8,9.6,9.6,0,0,1-3.38-3.2,9.94,9.94,0,0,1-1.3-5.4,10,10,0,0,1,2.09-6.3A13.61,13.61,0,0,1,130.21,16a24.25,24.25,0,0,1,9-1.51,35.57,35.57,0,0,1,7.82.9,22.83,22.83,0,0,1,6.66,2.49l-3.53,6.84a18.31,18.31,0,0,0-5.47-2.2,26,26,0,0,0-5.55-.61,12.11,12.11,0,0,0-6,1.19,3.5,3.5,0,0,0-2,3.06,2.91,2.91,0,0,0,1.29,2.59,10.16,10.16,0,0,0,3.39,1.33c1.39.31,2.94.6,4.64.86s3.4.6,5.08,1a20.32,20.32,0,0,1,4.64,1.73,9,9,0,0,1,3.42,3.13,9.56,9.56,0,0,1,1.3,5.26,9.68,9.68,0,0,1-2.13,6.3,13.8,13.8,0,0,1-6,4.1A25.78,25.78,0,0,1,137.45,53.93Z"
          fill={color ?? "#fff"}
        />
        <path
          id="E"
          d="M180.65,53.93a23.35,23.35,0,0,1-11.2-2.56,18.65,18.65,0,0,1-7.42-7,19.6,19.6,0,0,1-2.62-10.19A20,20,0,0,1,162,24,18.56,18.56,0,0,1,169,17a20.45,20.45,0,0,1,10.26-2.56,20,20,0,0,1,10,2.49,18,18,0,0,1,6.91,6.94,21.22,21.22,0,0,1,2.52,10.59c0,.33,0,.75,0,1.26s-.06,1-.11,1.33h-32v-6h27.29L190.22,33a11.25,11.25,0,0,0-1.33-5.8,10.12,10.12,0,0,0-3.85-4,11.35,11.35,0,0,0-5.76-1.4,11.8,11.8,0,0,0-5.8,1.4,9.57,9.57,0,0,0-3.92,4,12.49,12.49,0,0,0-1.37,6v1.44a11.85,11.85,0,0,0,1.55,6.12,10.5,10.5,0,0,0,4.43,4.14,14.37,14.37,0,0,0,6.69,1.48,15,15,0,0,0,5.83-1.08,13.64,13.64,0,0,0,4.61-3.17l4.9,5.62a17.29,17.29,0,0,1-6.59,4.6A23.74,23.74,0,0,1,180.65,53.93Z"
          fill={color ?? "#fff"}
        />
        {underline && (
          <path
            id="Underline"
            d="M0,62.42v-3.6H201.45v3.6Z"
            fill={color ?? "#fff"}
          />
        )}
      </g>
    </svg>
  );
};

export default CloseElement;

// React and other library imports
import { useCallback, useEffect, useState } from "react";
import { completeTaskSchema } from "../../../../helpers/FormValidations";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import useTaskFormReducer from "../../../../hooks/useTaskFormReducer";
import { toastMessage } from "../../Toast/Toast";
import useEventModalData from "../ViewEventModal/useEventModalData";
import useEsModalData from "../useEsModalData";

const useMarkCompleteData = ({ modalData }) => {
  // State for comment and its setter
  const [comment, setComment] = useState("");

  // Retrieve the current user data using custom hook
  const { data: currentUser } = useCurrentUser();

  // State to keep track of the validation status of the form
  const [valid, setValid] = useState(false);

  // Custom hook to get the patchComplete method from the event modal
  const { patchComplete } = useEventModalData(null);

  // Custom hook to handle task form's state and its dispatcher
  const [taskForm, taskFormDispatch] = useTaskFormReducer(modalData?.item);

  // Function to handle updates to the list of attached files
  const handleFilesUpdated = (updatedFiles) => {
    taskFormDispatch({
      type: "addAttachment",
      value: updatedFiles,
    });
  };

  // Function to handle the action of removing an attachment
  const removeAttachment = (id, key) => {
    taskFormDispatch({
      type: "removeAttachment",
      id,
      key,
    });
  };

  // Function to check form validation using a schema
  const checkValidation = useCallback(async () => {
    const isValid = await completeTaskSchema.isValid(taskForm);
    setValid(isValid);
  }, [taskForm]);

  // Effect to validate taskForm every time a field changes
  useEffect(() => {
    checkValidation();
  }, [checkValidation]);

  // Custom hook to handle modal's close operation
  const { closeModal: close } = useEsModalData(null, modalData);

  // Function to handle the completion of the task
  const handleMarkComplete = useCallback(() => {
    patchComplete(
      { ...taskForm, previousTask: modalData?.item },
      {
        content: comment,
        association: taskForm.reference,
        author: currentUser?.reference,
      },
      toastMessage
    );

    close();
  }, [
    close,
    comment,
    currentUser?.reference,
    taskForm,
    modalData?.item,
    patchComplete,
  ]);

  // Function to handle changes in the duration
  const handleChangeDuration = (key, value) => {
    // If the value is negative, do not update the state
    if (Number(value) < 0) return;
    taskFormDispatch({
      type: "duration",
      key,
      value,
    });
  };

  // Return the data and methods that will be used by the component consuming this hook
  return {
    taskForm,
    valid,
    comment,
    setComment,
    handleFilesUpdated,
    removeAttachment,
    handleChangeDuration,
    handleMarkComplete,
  };
};

export default useMarkCompleteData;

/* eslint-disable no-param-reassign */
import { useReducer } from "react";
import { updateRowStatus } from "../helpers/TimeSheet";

const reducer = (timesheets, action) => {
  switch (action.type) {
    case "reset": {
      return action.timesheets ?? [];
    }
    case "approveEntry": {
      const approved = timesheets;
      approved[action.index] = {
        ...approved[action.index],
        entries: {
          ...approved[action.index]?.entries,
          [action.date]: {
            ...approved[action.index]?.entries[action.date],
            status: action.value,
          },
        },
      };
      return approved;
    }
    case "approveRow": {
      const approved = timesheets;
      approved[action.index] = {
        ...approved[action.index],
        entries: Object.keys(approved[action.index]?.entries)?.reduce(
          (list, entry) => {
            list[entry] = {
              ...approved[action.index]?.entries?.[entry],
              status: "approved",
            };
            return list;
          },
          {}
        ),
      };
      return approved;
    }
    case "approveRows": {
      const approved = updateRowStatus("approved", timesheets, action.selected);
      return approved;
    }
    case "editEntries": {
      const edits = timesheets;
      Object.values(action.entries)?.forEach((item) => {
        edits[item.index] = {
          ...edits[item.index],
          entries: {
            ...edits[item.index]?.entries,
            [item.date]: {
              ...edits[item.index]?.entries[item.date],
              status: action.value,
            },
          },
        };
      });
      return edits;
    }
    case "rejectRow": {
      const rejected = timesheets;
      rejected[action.index] = {
        ...rejected[action.index],
        entries: Object.keys(rejected[action.index]?.entries)?.reduce(
          (list, entry) => {
            list[entry] = {
              ...rejected[action.index]?.entries?.[entry],
              status: "rejected",
            };
            return list;
          },
          {}
        ),
      };
      return rejected;
    }
    case "rejectRows": {
      const rejected = updateRowStatus("rejected", timesheets, action.selected);
      return rejected;
    }
    default:
      return [];
  }
};

export default () => {
  return useReducer(reducer, []);
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Input from "../Input/Input";
import Dropdown from "../Dropdown/Dropdown";
import { PURCHASE_AUTHORIZATION, UPDATE_FORM_DATA } from "../../../constants";
import { activeCurrencyFormatter } from "../../../helpers/Formatters";

const assignmentOptions = [
  { label: "Change Order", value: "coAssignment" },
  { label: "Contingency", value: "caAssignment" },
  { label: "Split", value: "split" },
];
const splitTypeOptions = [
  { label: "Change Order", value: "coAssignment" },
  { label: "Contingency", value: "caAssignment" },
];
const splitMethodOptions = [
  { label: "$", value: "$" },
  { label: "%", value: "%" },
];

const AdjustmentSplit = ({ formData, lineItemData, dispatch, lineId }) => {
  const [rowData, setRowData] = useState({});
  const [adjustment, setAdjustment] = useState();
  const [assignment, setAssignment] = useState();
  const [splitType, setSplitType] = useState();
  const [splitMethod, setSplitMethod] = useState();
  const [splitAmount, setSplitAmount] = useState();

  useEffect(() => {
    if (formData && lineItemData && lineId && !Object.keys(rowData).length) {
      setRowData(formData);
      setAdjustment(Number(`${lineItemData.paAdjustment}`.replaceAll(",", "")));
      setAssignment(formData.assignment);
      setSplitType(formData.splitType);
      setSplitMethod(formData.splitMethod ?? splitMethodOptions[0]?.value);
      setSplitAmount(Number(`${formData.splitAmount}`.replaceAll(",", "")));
    }
  }, [formData, lineItemData, lineId, rowData]);

  const handleAdjustment = () => {
    const method = splitMethod ?? splitMethodOptions[0].value;
    const amount =
      splitMethod === "$" ? splitAmount : adjustment * (splitAmount / 100);
    dispatch({
      type: UPDATE_FORM_DATA,
      key: PURCHASE_AUTHORIZATION,
      value: {
        [lineId]: {
          ...rowData,
          splitAmount,
          splitMethod: method,
          [splitType]: amount,
          [splitTypeOptions.find((item) => item.value !== splitType)?.value]:
            adjustment - amount,
        },
      },
    });
  };

  const handleAssignment = (val) => {
    setAssignment(val);
    dispatch({
      type: UPDATE_FORM_DATA,
      key: PURCHASE_AUTHORIZATION,
      value: {
        [lineId]: {
          ...rowData,
          assignment: val,
          ...(val !== "split"
            ? {
                [val]: lineItemData?.paAdjustment ?? adjustment,
                [splitTypeOptions.find((item) => item.value !== val)?.value]: 0,
              }
            : {}),
          ...(!val
            ? {
                splitType: undefined,
                splitAmount: 0,
                coAssignment: 0,
                caAssignment: 0,
              }
            : {}),
        },
      },
    });
  };

  const handleSplitType = (val) => {
    const amount =
      val === "$" ? splitAmount : adjustment * ((splitAmount || 0) / 100);

    setSplitType(val);
    dispatch({
      type: UPDATE_FORM_DATA,
      key: PURCHASE_AUTHORIZATION,
      value: {
        [lineId]: {
          ...rowData,
          splitType: val,
          [splitType]: amount,
          [splitTypeOptions.find((item) => item.value !== splitType)?.value]:
            adjustment - amount,
        },
      },
    });
  };

  const handleSplitAmount = (val) => {
    setSplitAmount(Number(val.replaceAll(",", "")));
  };

  const handleSplitAmountKeyPress = (event) => {
    if (event.code === "Enter") {
      handleAdjustment();
    }
  };

  const handleSplitMethod = (val) => {
    const amount =
      val === "$" ? splitAmount : adjustment * ((splitAmount || 0) / 100);

    setSplitMethod(val);
    dispatch({
      type: UPDATE_FORM_DATA,
      key: PURCHASE_AUTHORIZATION,
      value: {
        [lineId]: {
          ...rowData,
          splitMethod: val,
          [splitType]: amount,
          [splitTypeOptions.find((item) => item.value !== splitType)?.value]:
            adjustment - amount,
        },
      },
    });
  };

  return (
    <div className="flex -ml-14 pt-2 pb-1">
      <div
        className="flex flex-col justify-start"
        style={{ padding: "13px 0 0 0" }}
      >
        <div className="flex items-center relative right-2">
          <div className="w-10 h-0 border-t border-gray-200" />
          <div
            className={`w-3 h-3 rounded-full${
              assignment ? " bg-brandGreen" : " bg-gray-200"
            }`}
          />
        </div>
      </div>
      <div className="flex-1">
        <Dropdown
          className=""
          options={assignmentOptions}
          value={assignmentOptions.find((item) => item.value === assignment)}
          placeholder="Select"
          onChange={({ value }) => handleAssignment(value)}
        />
        {assignment === "split" && (
          <>
            <div className="flex items-center">
              <Dropdown
                className="mt-4"
                placeholder="Select"
                options={splitTypeOptions}
                value={splitTypeOptions.find(
                  (item) => item.value === splitType
                )}
                onChange={({ value }) => handleSplitType(value)}
              />
              <div className="relative -top-12 -right-2 w-0 h-0 z-30">
                <div className="w-5 h-14 border border-l-0 border-gray-200">
                  <div className="relative top-10 -left-1 mt-2 bg-brandGreen rounded-full h-3 w-3" />
                </div>
              </div>
            </div>
            {splitType && (
              <>
                <div className="flex items-center">
                  <div className="flex items-center mt-4">
                    <Dropdown
                      className="flex-1 w-24 mr-2"
                      placeholder="Select"
                      options={splitMethodOptions}
                      value={
                        splitMethodOptions.find(
                          (item) => item.value === splitMethod
                        ) ?? splitMethodOptions[0]
                      }
                      onChange={({ value }) => handleSplitMethod(value)}
                      disableClear
                    />
                    <Input
                      className="flex-2"
                      placeholder="Amount"
                      value={activeCurrencyFormatter(splitAmount)}
                      onChange={handleSplitAmount}
                      onKeyPress={handleSplitAmountKeyPress}
                      onBlur={handleAdjustment}
                      disableClear
                    />
                  </div>
                  <div className="relative -top-12 -right-2 w-0 h-0 z-20">
                    <div className="w-5 h-14 border border-l-0 border-gray-200">
                      <div className="relative top-10 -left-1 mt-2 bg-brandGreen rounded-full h-3 w-3" />
                    </div>
                  </div>
                </div>

                <div className="flex items-center">
                  <div
                    className="flex items-end w-full h-12"
                    style={{ marginTop: "-2px" }}
                  >
                    <p className="pb-1 font-semibold">
                      Balance &gt;{" "}
                      {splitType === "coAssignment" && "Contingency"}
                      {splitType === "caAssignment" && "Change Order"}
                    </p>
                  </div>
                  <div
                    className="relative -right-2 w-0 h-0 z-10"
                    style={{ top: "-44px" }}
                  >
                    <div className="w-5 h-14 border border-l-0 border-gray-200">
                      <div className="relative top-10 -left-1 mt-2 bg-brandGreen rounded-full h-3 w-3" />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

AdjustmentSplit.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formData: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  lineItemData: PropTypes.object,
  dispatch: PropTypes.func,
  lineId: PropTypes.string,
};

AdjustmentSplit.defaultProps = {
  formData: undefined,
  lineItemData: {},
  dispatch: () => {},
  lineId: undefined,
};

export default AdjustmentSplit;

/**
 * Validates a password string based on certain criteria.
 * @param {string} password - The password to validate.
 * @returns {Object} An object containing validation results.
 */
const validatePassword = (password = "") => {
  const MIN_LENGTH = 8;

  const validationResults = {
    hasMinLength: password.length >= MIN_LENGTH,
    hasSpecialChar: /[!_(),^.;$+=\-@`\\~"']/.test(password),
    noInvalidChars: /^[^#%&*:<>?/{|}\s]+$/.test(password),
    hasNumber: /[0-9]/.test(password),
    hasUppercase: /[A-Z]/.test(password),
    hasLowercase: /[a-z]/.test(password),
  };

  validationResults.isValid = Object.values(validationResults).every(
    (value) => !!value
  );

  return validationResults;
};

export default validatePassword;

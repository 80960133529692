import { useCallback } from "react";
import { useHistory } from "react-router";
import { useProjectById, useProjectsPatch } from "../../hooks/projects";
import { toastMessage, toastError } from "../../stories/Components/Toast/Toast";
import useServiceRequestTopButtons from "../../hooks/useServiceRequestTopButtons";
import useReportTopButtons from "../../hooks/useReportTopButtons";
import { PROJECTS_PATH } from "../../constants";
import useQueryNotFoundNavigation from "../../hooks/navigation/useQueryNotFoundNavigation";

const useProjectViewData = (id) => {
  const history = useHistory();

  const { data: project, isLoading, error } = useProjectById(id);

  // Redirect to 404 page if resource is not found
  useQueryNotFoundNavigation({ error });

  const { patchProjectAsync, isPatching } = useProjectsPatch();

  // Hook to calculate buttons for SR tab
  const { getRequestButtonProps } = useServiceRequestTopButtons();

  // Hook to calculate buttons for Report tab
  const { getReportButtonProps } = useReportTopButtons();

  /**
   * Patch given project
   * @param {Object} updatedProject - Updated project
   * @param {Object} currentProject - Current project
   */
  const handlePatchProject = useCallback(
    async (updatedProject, currentProject) => {
      let data;
      try {
        data = await patchProjectAsync(updatedProject, currentProject);
        toastMessage("Project was updated successfully");
      } catch (err) {
        toastError("Project could not be updated. Please try again");
      }

      return data;
    },
    [patchProjectAsync]
  );

  /**
   * Change status to active for current project
   */
  const handleActivateProject = useCallback(async () => {
    const updatedProject = {
      ...project,
      status: "active",
    };
    return patchProjectAsync(updatedProject, project).then(() => {
      toastMessage("Project was activated successfully");
    });
  }, [project, patchProjectAsync]);

  /**
   * Change status to complete for current project
   */
  const handleCompleteProject = useCallback(async () => {
    const updatedProject = {
      ...project,
      status: "complete",
    };
    try {
      await patchProjectAsync(updatedProject, project);
      toastMessage("Project was completed successfully");

      history.push(PROJECTS_PATH);
    } catch (err) {
      toastError("Project could not be completed. Please try again");
    }
  }, [history, project, patchProjectAsync]);

  return {
    project,
    isLoading,
    isPatching,
    handlePatchProject,
    handleActivateProject,
    handleCompleteProject,
    getRequestButtonProps,
    getReportButtonProps,
  };
};

export default useProjectViewData;

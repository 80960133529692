import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import * as yup from "yup";

import Dropdown from "../../Dropdown/Dropdown";
import IconButton from "../../Buttons/IconButton";
import Input from "../../Input/Input";

import deleteIcon from "../../../assets/images/collapseIcon.svg";

export default function RFIRequestWorkflowCard({
  duration,
  handleDeleteClick,
  handleUpdateDueDates,
  index,
  userOptions,
  handleUpdateMember,
  handleUpdateRole,
  user,
  roleOptions,
}) {
  return (
    <div className="flex w-full border rounded p-4 my-4">
      <div className="flex justify-between w-full">
        <h2 className="w-20 mt-6 mr-2 text-lg font-semibold text-gray-300">
          Step {index + 1}
        </h2>
        <div className="w-54 mr-2">
          <Dropdown
            label="Members"
            placeholder="Select"
            disableCreateUser
            options={userOptions}
            onChange={handleUpdateMember}
            value={userOptions.find((u) => u.value === user?.reference)}
            validation={yup.mixed().required()}
            isDisabled={index === 0}
          />
        </div>
        <div className="w-40 mr-2">
          <Dropdown
            label="Role"
            placeholder="Select"
            options={roleOptions}
            onChange={(val) => handleUpdateRole(val)}
            value={
              index === 0
                ? { label: "Submitter", value: "submitter" }
                : roleOptions.find((r) => r.value === user?.type) // needs to be "type" instead of "role"
            }
            isDisabled={index === 0}
            validation={yup.mixed().required()}
          />
        </div>
        <div className="mr-2 pt-1">
          <Input
            placeholder="Duration"
            className="w-8"
            type="number"
            label="Duration"
            value={duration}
            onChange={(val) => handleUpdateDueDates(val)}
          />
          <p className="text-xs text-gray-800 mt-1">
            Due on: {moment().add(duration, "d").format("MMM DD, YYYY")}
          </p>
        </div>
        <div className="w-10 pt-8">
          <IconButton
            className="mx-2"
            icon={deleteIcon}
            onClick={handleDeleteClick}
          />
        </div>
      </div>
    </div>
  );
}

// prop types
RFIRequestWorkflowCard.propTypes = {
  handleUpdateDueDates: PropTypes.func,
  userOptions: PropTypes.arrayOf(PropTypes.shape({})),
  index: PropTypes.number,
  role: PropTypes.shape({ label: PropTypes.string }),
  duration: PropTypes.number,
  handleDeleteClick: PropTypes.func,
  handleUpdateMember: PropTypes.func,
  handleUpdateRole: PropTypes.func,
  user: PropTypes.shape({
    reference: PropTypes.string,
    type: PropTypes.string,
  }),
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

// default props
RFIRequestWorkflowCard.defaultProps = {
  index: undefined,
  role: {},
  duration: undefined,
  handleDeleteClick: undefined,
  handleUpdateDueDates: undefined,
  userOptions: [],
  handleUpdateMember: undefined,
  handleUpdateRole: undefined,
  user: undefined,
  roleOptions: undefined,
};

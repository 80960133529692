/* eslint-disable camelcase */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import MessageUserAvatar from "../MessageUserAvatar";
import MessageBody from "../MessageBody";
import { useESChatContext } from "../../../../../context/ChatContext/ESChatContext";

const ThreadReply = ({
  text,
  created_at,
  id,
  user: { id: userId, name: userName },
  attachments,
  resend,
}) => {
  const { replyAttachments } = useESChatContext();

  useEffect(() => {
    replyAttachments.current = {
      ...replyAttachments?.current,
      [id]: attachments,
    };
    return () => replyAttachments;
  }, [attachments, id, replyAttachments]);

  return (
    <div key={id} className="flex py-3 gap-3 px-2">
      <MessageUserAvatar userId={userId} reply />
      <MessageBody
        text={text}
        created_at={created_at}
        userId={userId}
        userName={userName}
        attachments={attachments}
        reply
        resend={resend}
        id={id}
      />
    </div>
  );
};

ThreadReply.propTypes = {
  text: PropTypes.string,
  created_at: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  id: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  attachments: PropTypes.arrayOf(PropTypes.shape({})),
  resend: PropTypes.func,
};

ThreadReply.defaultProps = {
  text: undefined,
  created_at: undefined,
  id: undefined,
  user: {
    id: undefined,
  },
  attachments: [],
  resend: undefined,
};

export default ThreadReply;

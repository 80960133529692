// Framework Tools
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

// Components
import TabbedContainer from "../../stories/Components/TabbedContainer/TabbedContainer";
import RequestHistory from "../../stories/Components/Requests/RequestHistory/RequestHistory";
import RequestGridViewHeader from "../../stories/Components/Requests/RequestGridView/RequestGridViewHeader";
import RequestGridView from "../../stories/Components/Requests/RequestGridView/RequestGridView";
import RequestWorkflowContainer from "../../stories/Components/Requests/RequestWorkflowSteps/RequestWorkflowContainer";

export default function RFIDetailView({ rfis, currentUser }) {
  // Hooks
  // rfi id pulled from route, used for finding current rfi
  const { rfiId } = useParams();

  // State controls
  const [tabIndex, setTabIndex] = useState(0);
  const [rfi, setRfi] = useState({});
  const [rfiWorkflows, setRfiWorkflows] = useState([]);

  // State Updaters
  // waits for params and rfis then sets the current rfi
  useEffect(() => {
    const currentrfi = rfis?.find((item) => {
      return item?.id === rfiId;
    });
    setRfi(currentrfi);
  }, [rfis, rfiId]);

  useEffect(() => {
    const workflows = rfi?.requestWorkflow;
    setRfiWorkflows(workflows);
  }, [rfi?.requestWorkflow]);

  // tab sections - Title and Content, content is a react component, works in conjunction with tabIndex
  const tabSections = [
    {
      title: "Workflow",
      content: rfiWorkflows?.map((workflow, index) => {
        return (
          <RequestWorkflowContainer
            key={workflow?.id}
            workflow={workflow}
            index={index}
            requestData={rfi}
            setRequestData={setRfi}
            currentUser={currentUser}
          />
        );
      }),
    },
    {
      title: "History",
      content: <RequestHistory requestData={rfi} />,
    },
    {
      title: "Attachments",
      content: <div>documents component</div>,
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <RequestGridViewHeader
        headerData={rfi}
        setHeaderData={setRfi}
        currentUser={currentUser}
        isrfi
      />
      <RequestGridView gridData={rfi} setGridData={setRfi} index={0} />
      <TabbedContainer
        tabs={tabSections}
        activeIndex={tabIndex}
        onTabClick={setTabIndex}
      />
    </div>
  );
}

import React, { useRef } from "react";
import {
  ADD_OPEN_MODAL,
  VIEW_EVENT_MODAL,
  VIEW_TASK_MODAL,
} from "../../../../../constants";
import getDateTimeInTimezone from "../../../../../helpers/Calendar/getDateTimeInTimezone";
import { useModalState } from "../../../../../state/modalState";
import EventCellCircle from "./EventCellCircle";
import useEventCellData from "./useEventCellData";

const EventCell = ({
  item,
  d,
  i,
  place,
  daySpan,
  placeTextWithEllipsis,
  CALENDAR_MONTH_EVENT_DATE_OFFSET,
  CALENDAR_MONTH_BORDER_OFFSET,
  CALENDAR_MONTH_EVENT_HEIGHT,
  calendarTimezone,
}) => {
  const containerRef = useRef(null);
  const [, modalDispatch] = useModalState();

  const {
    isMarkedComplete,
    textYPosition,
    determineStrokeColor,
    determineFillColor,
    cellText,
  } = useEventCellData({ item, calendarTimezone });

  const openModalHere = ({ position, modalData }) => {
    if (item.resource === "Task") {
      modalDispatch({
        type: ADD_OPEN_MODAL,
        ref: { id: item.id },
        position,
        modalData,
        modalType: VIEW_TASK_MODAL,
      });
      return;
    }
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: item.id },
      position,
      modalData,
      modalType: VIEW_EVENT_MODAL,
    });
  };

  return (
    <g
      ref={containerRef}
      className="cursor-pointer"
      onClick={() => {
        const { width, height, left, top } =
          containerRef.current.getBoundingClientRect();

        openModalHere({
          position: {
            x: left,
            y: top,
          },
          modalData: { item, width, height },
        });
      }}
    >
      <title>{`${getDateTimeInTimezone({
        timezone: calendarTimezone,
        date: item.startDate,
        format: "h:mma",
      })} ${item?.name}`}</title>
      <rect
        // x = column * cell width + stroke
        x={d * 40 + CALENDAR_MONTH_BORDER_OFFSET}
        y={
          i * 35 +
          CALENDAR_MONTH_EVENT_HEIGHT * (place - 1) +
          CALENDAR_MONTH_EVENT_DATE_OFFSET
        }
        width={40 * daySpan - CALENDAR_MONTH_BORDER_OFFSET * 2}
        height={CALENDAR_MONTH_EVENT_HEIGHT}
        fill={determineFillColor(item)}
        stroke={determineStrokeColor(item)}
        strokeWidth="0.25"
        rx="1"
      />
      {!item?.daySpan && (
        <EventCellCircle i={i} d={d} place={place} item={item} />
      )}
      <text
        x={d * 40 + (item?.daySpan ? 2 : 5)}
        y={textYPosition(i, place)}
        fontSize="3"
        alignmentBaseline="middle"
        fill={item?.daySpan ? "white" : "#333333"}
        style={{
          textDecoration: isMarkedComplete ? "line-through" : "none",
        }}
      >
        {placeTextWithEllipsis(cellText, daySpan)}
      </text>
    </g>
  );
};

export default EventCell;

import { useCallback, useState } from "react";
import { useHistory } from "react-router";

export default function useHelpSingleCategoryData({ videoData }) {
  const history = useHistory();

  const [videoDialog, setVideoDialog] = useState({
    open: false,
    contentsUrl: "",
    name: "",
  });

  const [startIndex, setStartIndex] = useState();
  const [modalTitle, setModalTitle] = useState();

  const getModalTitle = (name) => name?.replace(".mp4", "");

  const openPlayVideo = useCallback(
    (video) => {
      setVideoDialog({ ...video, open: true });
      const selectedVideoIndex = videoData?.findIndex(
        (item) => item?.contentsUrl === video?.contentsUrl
      );
      setStartIndex(selectedVideoIndex);
    },
    [videoData]
  );

  const closePlayVideo = useCallback(() => {
    setVideoDialog({ open: false, contentsUrl: "", name: "" });
  }, []);

  return {
    history,
    videoDialog,
    setVideoDialog,
    openPlayVideo,
    closePlayVideo,
    startIndex,
    setModalTitle,
    modalTitle,
    getModalTitle,
  };
}

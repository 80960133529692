import React from "react";
import PropTypes from "prop-types";
import Pill from "./Pill";

const StatusPill = ({ value, size }) => {
  const className = size === "large" ? "h-7" : "h-5";
  const textSize = size === "large" ? "text-xs" : "text-xxs";
  switch (value) {
    case "open":
      return (
        <Pill
          alt="draft"
          value="Not Submitted"
          className={className}
          textColor="text-pillGray"
          textSize={textSize}
          border="border border-pillGray"
          background="bg-transparent"
          padding="p-2"
        />
      );
    case "rejected":
      return (
        <Pill
          alt="rejected"
          value="Rejected"
          className={className}
          textColor="text-brandRed"
          textSize={textSize}
          background="bg-transparent"
          border="border border-brandRed"
          padding="p-2"
        />
      );
    case "submitted":
      return (
        <Pill
          alt="submitted"
          value="Submitted"
          className={className}
          textColor="text-darkenedGreen"
          textSize={textSize}
          background="bg-transparent"
          border="border border-darkenedGreen"
          padding="p-2"
        />
      );
    case "approved":
      return (
        <Pill
          alt="approved"
          value="Approved"
          className={className}
          padding="p-2"
          textSize={textSize}
          background="bg-darkenedGreen"
          border="border-darkenedGreen"
        />
      );
    case "locked":
      return (
        <Pill
          alt="payroll approved"
          value="Payroll Approved"
          className={className}
          textSize={textSize}
          textColor="text-white"
          background="bg-pillBlue"
          border="border border-pillBlue"
          padding="p-2"
        />
      );
    default:
      return (
        <Pill
          alt="draft"
          value="No timesheet saved"
          className={className}
          textColor="text-pillGray"
          textSize={textSize}
          border="border border-pillGray"
          background="bg-transparent"
          padding="p-2"
        />
      );
  }
};
StatusPill.propTypes = {
  value: PropTypes.string,
  size: PropTypes.string,
};

StatusPill.defaultProps = {
  value: "",
  size: undefined,
};

export default StatusPill;

import { useInfiniteQuery } from "react-query";
import { AUTH_TOKEN_STORAGE_KEY } from "../constants";

/**
 * useQueryHook wrapper that will make calls to api
 * if there is a authToken in the global state.
 */
function useAuthenticatedInfiniteQuery(queryKey, queryFn, options) {
  // ES Auth Token
  const authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);

  const queryOptions = {
    ...options,
    enabled: (options?.enabled ?? true) && !!authToken,
  };

  return useInfiniteQuery(queryKey, queryFn, queryOptions);
}

export default useAuthenticatedInfiniteQuery;

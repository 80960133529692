import { cloneDeep } from "lodash";
import { useState, useCallback, useMemo } from "react";
import { PTO_TYPES } from "../../../../constants";
import { ptoSchema, ptoTypeSchema } from "../../../../helpers/FormValidations";
import { useAppState } from "../../../../state/appState";
import { getStatesDropdown } from "../../../../helpers/Address";
import { useUsers } from "../../../../hooks/useUsers.new";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";

const usePtoLocationsSectionData = ({
  systemSettings,
  dispatchSystemSettings,
  setIsValid,
}) => {
  const { data } = useUsers();

  const currentStates = systemSettings.ptoLocations?.map((loc) => ({
    label: loc.location,
    value: loc.id,
  }));

  const statesInUse = useMemo(() => {
    const states = new Set();
    if (data?.users?.length) {
      // using Set so duplicate states get merged
      data?.users.forEach((user) => {
        // filter in only members
        if (user?.kind === "member") {
          const stateOfEmployment = currentStates?.find(
            (state) => state.value === user?.employeeInfo?.pto?.locationId
          )?.label;

          states.add(stateOfEmployment);
        }
      });
    }
    return states;
  }, [data?.users, currentStates]);

  const [deletingLocation, setDeletingLocation] = useState();
  const [{ financialsConfiguration }] = useAppState();

  const isLocked = useMemo(() => {
    return (
      financialsConfiguration?.financials?.payroll?.locked ||
      financialsConfiguration?.financials?.period?.locked
    );
  }, [financialsConfiguration]);

  const handleEntryChange = (value) => {
    const valueAsStr = value.toString();
    const isNegative = valueAsStr.includes("-");
    const isDecimal = valueAsStr.includes(".");
    const isNaNCheck = Number.isNaN(Number(valueAsStr));
    const hasInvalidCharacters = /[^\d]/.test(valueAsStr);
    const startsWithZero = /^0+/.test(valueAsStr);

    const cancelCriteria =
      isNegative ||
      isDecimal ||
      isNaNCheck ||
      hasInvalidCharacters ||
      startsWithZero;

    if (cancelCriteria) return null;

    return value;
  };

  const statesDDL = useMemo(
    () =>
      getStatesDropdown()?.filter(({ value }) => {
        const numOfOccurrences = systemSettings?.ptoLocations?.reduce(
          (o, { location }) => {
            if (location === value) {
              return o + 1;
            }
            return o;
          },
          0
        );
        return numOfOccurrences < 2;
      }),
    [systemSettings?.ptoLocations]
  );

  const toggle = useCallback(
    (locationIndex) => {
      dispatchSystemSettings({
        type: "togglePtoLocation",
        locationIndex,
      });
    },
    [dispatchSystemSettings]
  );

  const onSelectPtoLocationType = useCallback(
    (locationIndex, typeIndex, ptoType, value) => {
      dispatchSystemSettings({
        type: "selectPtoLocationType",
        locationIndex,
        typeIndex,
        ptoType,
        selected: value,
      });
    },
    [dispatchSystemSettings]
  );

  const onChangePtoLocationAccrualType = useCallback(
    (newValue, locationIndex, typeIndex) => {
      dispatchSystemSettings({
        type: "changePtoLocationAccrualType",
        locationIndex,
        typeIndex,
        value: newValue.value,
      });
    },
    [dispatchSystemSettings]
  );

  const onChangePtoLocationNumHours = useCallback(
    (value, locationIndex, typeIndex) => {
      dispatchSystemSettings({
        type: "changePtoLocationNumHours",
        locationIndex,
        typeIndex,
        value: handleEntryChange(value),
      });
    },
    [dispatchSystemSettings]
  );

  const onChangePtoLocationMaxRollOver = useCallback(
    (value, locationIndex, typeIndex) => {
      dispatchSystemSettings({
        type: "changePtoLocationMaxRollover",
        locationIndex,
        typeIndex,
        value: handleEntryChange(value),
      });
    },
    [dispatchSystemSettings]
  );

  const toggleEditing = useCallback(
    (locationIndex) => {
      if (isLocked) return;
      dispatchSystemSettings({
        type: "togglePtoLocationEditing",
        locationIndex,
      });
    },
    [dispatchSystemSettings, isLocked]
  );

  const onChangePtoLocationAvailabilityDate = useCallback(
    (value, locationIndex, typeIndex) => {
      dispatchSystemSettings({
        type: "changePtoLocationAvailabilityDate",
        locationIndex,
        typeIndex,
        value,
      });
    },
    [dispatchSystemSettings]
  );

  const onChangePtoLocationTypeAvailabilityDays = useCallback(
    (value, locationIndex, typeIndex) => {
      dispatchSystemSettings({
        type: "changePtoLocationTypeAvailabilityDays",
        locationIndex,
        typeIndex,
        value: handleEntryChange(value),
      });
    },
    [dispatchSystemSettings]
  );

  const onChangePtoLocationTypeMaxAccrual = useCallback(
    (value, locationIndex, typeIndex) => {
      dispatchSystemSettings({
        type: "changePtoLocationTypeMaxAccrual",
        locationIndex,
        typeIndex,
        value: value?.value,
      });
    },
    [dispatchSystemSettings]
  );

  const onRemove = useCallback((locationIndex) => {
    setDeletingLocation(locationIndex);
  }, []);

  const onConfirmRemove = useCallback(() => {
    dispatchSystemSettings({
      type: "removePtoLocation",
      locationIndex: deletingLocation,
    });
    setDeletingLocation();
  }, [deletingLocation, dispatchSystemSettings]);

  const onAdd = useCallback(() => {
    dispatchSystemSettings({
      type: "addPtoLocation",
      defaultPtoTypes: PTO_TYPES,
    });
  }, [dispatchSystemSettings]);

  const onChangeLocation = useCallback(
    (value, locationIndex) => {
      dispatchSystemSettings({
        type: "changePtoLocation",
        locationIndex,
        value,
      });
    },
    [dispatchSystemSettings]
  );

  const onChangePtoLocationTypeNegativeValues = useCallback(
    (locationIndex, typeIndex, ptoType, value) => {
      dispatchSystemSettings({
        type: "changePtoLocationTypeNegativeValues",
        locationIndex,
        typeIndex,
        ptoType,
        allowNegatives: value,
      });
    },
    [dispatchSystemSettings]
  );

  const onChangeLocationExemptStatus = useCallback(
    (value, location, locationIndex) => {
      dispatchSystemSettings({
        type: "changePtoLocationExemptStatus",
        locationIndex,
        location,
        value,
      });
    },
    [dispatchSystemSettings]
  );

  useDidMountEffect(() => {
    const validationFields = [];
    systemSettings?.ptoLocations?.forEach((pto) => {
      validationFields.push(ptoSchema.isValid(pto));
      pto?.types?.forEach((ptoType) => {
        if (ptoType?.type === "unlimited") return;
        const clonedPtoType = cloneDeep(ptoType);
        if (!ptoType.availability) {
          clonedPtoType.availability = {};
        }
        validationFields.push(ptoTypeSchema.isValid(clonedPtoType));
      });
    });
    Promise.all(validationFields).then((values) => {
      setIsValid(!values.some((field) => field === false));
    });
  }, [systemSettings?.ptoLocations, setIsValid]);

  return {
    deletingLocation,
    isLocked,
    statesDDL,
    statesInUse,
    toggle,
    onSelectPtoLocationType,
    onChangePtoLocationAccrualType,
    onChangePtoLocationNumHours,
    onChangePtoLocationMaxRollOver,
    toggleEditing,
    onChangePtoLocationAvailabilityDate,
    onChangePtoLocationTypeAvailabilityDays,
    onChangePtoLocationTypeMaxAccrual,
    onChangePtoLocationTypeNegativeValues,
    onChangeLocationExemptStatus,
    onRemove,
    onConfirmRemove,
    onAdd,
    onChangeLocation,
    setDeletingLocation,
  };
};

export default usePtoLocationsSectionData;

import cntl from "cntl";
import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";

const buttonStyle = (
  className,
  disabled,
  saveButtonHeight,
  saveButtonFontSize,
  background,
  border
) => cntl`
  border-2
  rounded-lg
  ${border && `${border}`}
  ${!disabled && `border-${background} bg-${background} text-white`}
  ${disabled && "bg-gray-100 border-gray-150 text-gray-150"}
  ${saveButtonFontSize || "text-md"}
  ${saveButtonHeight || "h-10"}
  flex
  items-center
  relative
  ${className}
`;

const SaveButton = ({
  disabled,
  onClick,
  className,
  background,
  border,
  fontColor,
  saveButtonTitle,
  saveButtonHeight,
  saveButtonFontSize,
  forwardedRef,
  style,
}) => {
  const buttonRef = useRef({});

  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick();
    }
  }, [disabled, onClick]);

  return (
    <button
      className={buttonStyle(
        className,
        disabled,
        saveButtonHeight,
        saveButtonFontSize,
        background,
        border
      )}
      onClick={handleClick}
      type="button"
      tabIndex={0}
      id="save-button"
      aria-label="save-button"
      ref={forwardedRef ?? buttonRef}
      style={style}
    >
      <p
        className={`whitespace-nowrap px-3 tracking-wider font-semibold ${fontColor}
        }`}
      >
        {saveButtonTitle ?? "Save"}
      </p>
    </button>
  );
};

SaveButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  background: PropTypes.string,
  border: PropTypes.string,
  fontColor: PropTypes.string,
  saveButtonTitle: PropTypes.string,
  saveButtonHeight: PropTypes.string,
  saveButtonFontSize: PropTypes.string,
  forwardedRef: PropTypes.func,
  style: PropTypes.shape({}),
};

SaveButton.defaultProps = {
  disabled: false,
  onClick: () => {},
  className: undefined,
  background: "darkenedGreen",
  border: "brandGreen",
  fontColor: "gray-500",
  saveButtonTitle: undefined,
  saveButtonHeight: undefined,
  saveButtonFontSize: undefined,
  forwardedRef: undefined,
  style: undefined,
};

export default SaveButton;

import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Avatar from "../Message/Avatar";
import circleCheckGreenIcon from "../../assets/images/circleCheckGreenIcon.svg";
import circleCheckIcon from "../../assets/images/circleCheckIcon.png";
import resubmissionGreenIcon from "../../assets/images/resubmissionGreenIcon.svg";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import InputModal from "../InputModal/InputModal";
import { getFullName } from "../../../helpers/Formatters";
import { ADVANCED_WF_STEP, RESUBMISSION_WF_STEP } from "../../../constants";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteCircleCheckIcon from "../../assets/images/circleCheckIcon.svg";
import { toastMessage } from "../../../helpers/Toast";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = <img src={whiteCrossIcon} alt="Close notice" />;

const WorkflowStepAction = ({
  data,
  stepData,
  memberIndex,
  handleAdvanceStep,
  handleResubmitStep,
  handleSendReminder,
  parentIndex,
  workflowData,
  isOpen,
  hideName,
  currentUser,
}) => {
  const [advanceData, setAdvanceData] = useState({});
  const [requestData, setRequestData] = useState({});
  const [remindData, setRemindData] = useState({});

  const statusName = {
    advanced: "Advanced",
    resubmissionRequested: "Resubmission Requested",
    pending: "Awaiting review",
    unresolved:
      workflowData.currentStep === parentIndex
        ? "Pending review"
        : "Awaiting review",
  };

  const handleToggleAdvance = () => {
    setAdvanceData((prev) => ({ isOpen: !prev.isOpen, comment: "" }));
    setRequestData({ isOpen: false, comment: "" });
  };
  const handleToggleRequest = () => {
    setRequestData((prev) => ({ isOpen: !prev.isOpen, comment: "" }));
    setAdvanceData({ isOpen: false, comment: "" });
  };
  const handleToggleRemind = () => {
    setRemindData((prev) => ({ isOpen: !prev.isOpen, comment: "" }));
  };
  const handleAdvanceData = (key, value) => {
    const tempObj = { ...advanceData };
    tempObj[key] = value;
    setAdvanceData(tempObj);
  };
  const handleRequestData = (key, value) => {
    const tempObj = { ...requestData };
    tempObj[key] = value;
    setRequestData(tempObj);
  };
  const handleRemindData = (key, value) => {
    const tempObj = { ...remindData };
    tempObj[key] = value;
    setRemindData(tempObj);
  };

  const handleAdvance = () => {
    toastMessage(
      `Step ${stepData?.name} has been advanced`,
      toastIcon,
      toastCloseIcon
    );
    handleAdvanceStep(memberIndex, advanceData.comment);
    handleToggleAdvance();
  };
  const handleResubmit = () => {
    toastMessage(
      `Resubmission requested on step ${stepData?.name}`,
      toastIcon,
      toastCloseIcon
    );
    handleResubmitStep(memberIndex, requestData.comment);
    handleToggleRequest();
  };
  const handleRemind = () => {
    handleSendReminder(remindData?.comment, [data.reference]);
    handleToggleRemind();
  };
  const handleEnterAdvance = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleAdvance();
    }
  };
  const handleEnterResubmit = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleResubmit();
    }
  };

  const handleCheckStepAdvanced = (step) => {
    const prevMembers = step?.members || [];
    const membersAdvanced = prevMembers?.map((member) => {
      if (member?.status === "advanced") {
        return true;
      }
      return false;
    });

    if (membersAdvanced?.includes(false)) {
      return false;
    }

    return true;
  };
  const lastReminder =
    workflowData?.reminders?.[stepData?.id]?.[data.reference];

  function CardActions() {
    const canAdvance =
      moment().isAfter(moment(workflowData.startDate?.date)) ||
      moment(workflowData.startDate?.date).isSame(moment(), "day");
    const isOverDue =
      moment().isAfter(moment(workflowData.dueDate)) ||
      moment(workflowData.dueDate).isSame(moment(), "day");

    const allSteps = workflowData?.steps;
    const previousStep = allSteps?.[parentIndex - 1];
    let hasPreviousStep;
    if (previousStep) {
      hasPreviousStep = true;
    } else {
      hasPreviousStep = false;
    }
    let checkedStep = true;
    if (hasPreviousStep) {
      checkedStep = handleCheckStepAdvanced(previousStep);
    }
    const hasParallelSteps = previousStep?.parallelSteps;

    let checkedParallelSteps = [];
    let showAdvanceButton;
    if (hasParallelSteps?.length > 0) {
      checkedParallelSteps = hasParallelSteps?.map((step) => {
        return handleCheckStepAdvanced(step);
      });
    }

    if (checkedParallelSteps.includes(false) || checkedStep === false) {
      showAdvanceButton = false;
    } else {
      showAdvanceButton = true;
    }

    if (
      canAdvance &&
      !data?.status &&
      showAdvanceButton &&
      data?.reference === currentUser?.reference &&
      isOpen
    ) {
      return (
        <div className="w-full">
          <div className="relative">
            <PrimaryButton
              title="Advance"
              className="mt-4 w-full"
              noMaxWidth
              iconLeft={
                <img
                  src={circleCheckIcon}
                  alt="chackMark"
                  className="w-4 h-4 mr-1"
                />
              }
              onClick={handleToggleAdvance}
              disabled={
                workflowData?.isDraft ||
                !currentUser?.hasPermission("workflow", "can_create")
              }
            />
            {advanceData.isOpen && (
              <InputModal
                title="Advance"
                buttonTitle="Submit to Advance"
                placeholder="Include comments (optional)"
                onClose={handleToggleAdvance}
                inputValue={advanceData?.comment}
                onInputChange={(val) => handleAdvanceData("comment", val)}
                onSubmit={handleAdvance}
                handleEnter={(event) => handleEnterAdvance(event)}
              />
            )}
          </div>
          <div className="relative">
            <SecondaryButton
              title="Request Resubmission"
              className="mt-4 w-full"
              noMaxWidth
              onClick={handleToggleRequest}
              iconLeft={
                <img
                  src={resubmissionGreenIcon}
                  alt="chackMark"
                  className="w-4 h-4 mr-1"
                />
              }
              disabled={
                workflowData?.isDraft ||
                !currentUser?.hasPermission("workflow", "can_create")
              }
            />
            {requestData.isOpen && (
              <InputModal
                title="Request Resubmission"
                buttonTitle="Submit Request"
                placeholder="What changes need to be resubmitted?"
                onClose={handleToggleRequest}
                inputValue={requestData?.comment}
                onInputChange={(val) => handleRequestData("comment", val)}
                onSubmit={handleResubmit}
                handleEnter={(event) => handleEnterResubmit(event)}
              />
            )}
          </div>
        </div>
      );
    }
    const canRemind =
      !lastReminder ||
      Math.abs(moment(lastReminder?.sent).diff(moment(), "days")) >= 3;
    if (
      canRemind &&
      canAdvance &&
      isOverDue &&
      data?.reference !== currentUser?.reference &&
      lastReminder?.sentBy !== currentUser?.reference &&
      workflowData.currentStep === parentIndex
    ) {
      return (
        <div className="relative my-4">
          <SecondaryButton
            title="Send Reminder"
            className="w-full"
            noMaxWidth
            onClick={handleToggleRemind}
            disabled={
              workflowData?.isDraft ||
              !currentUser?.hasPermission("workflow", "can_create")
            }
          />
          {remindData.isOpen && (
            <InputModal
              title="Send Reminder"
              buttonTitle="Submit to Send"
              placeholder="Include a personal note (optional)"
              onClose={handleToggleRemind}
              inputValue={remindData?.comment}
              onInputChange={(val) => handleRemindData("comment", val)}
              onSubmit={handleRemind}
            />
          )}
        </div>
      );
    }
    return <div />;
  }

  return (
    <>
      <div className="relative py-1">
        <Avatar
          name={
            data?.userData
              ? getFullName(data?.userData?.name)
              : getFullName(data?.name) ?? data?.label
          }
          className={
            data?.status === RESUBMISSION_WF_STEP &&
            "border-2 border-accessibleRed rounded-full"
          }
          avatar={data?.userData?.avatar ?? data?.avatar}
          id={data?.userData?.id}
          isExternalUser
        />
        {data?.status === ADVANCED_WF_STEP && (
          <div className="absolute top-0 right-0">
            <img
              src={circleCheckGreenIcon}
              alt="check mark"
              className="w-4 h-4 bg-white rounded-full"
            />
          </div>
        )}
      </div>
      <div className="ml-4 flex-1">
        {!hideName && (
          <h2 className="text-gray-300 font-medium capitalize">
            {data?.userData ? getFullName(data?.userData?.name) : data.label}
          </h2>
        )}
        {data?.status && (
          <h3 className="text-gray-500 font-medium">
            {`${statusName[data?.status]} ${
              data?.date
                ? moment(data?.date).format("MMM DD, YYYY, h:mm A")
                : ""
            }`}
          </h3>
        )}
        {lastReminder?.sent &&
          !data?.status &&
          lastReminder?.sentBy === currentUser?.reference &&
          moment(lastReminder?.sent).diff(moment(), "days") < 3 && (
            <h3 className="text-gray-500 font-medium">
              {`Reminder Sent ${moment(lastReminder?.sent).format(
                "MMM DD, YYYY h:mm A"
              )}`}
            </h3>
          )}
        {CardActions()}
        {data?.note && (
          <p className="text-gray-200 text-sm mt-2">{data.note}</p>
        )}
      </div>
    </>
  );
};
WorkflowStepAction.propTypes = {
  /**
   * data needed to display wrkflow steps
   */
  data: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.shape({}),
    reference: PropTypes.string,
    status: PropTypes.string,
    date: PropTypes.string,
    note: PropTypes.string,
    label: PropTypes.string,
    userData: PropTypes.shape({
      name: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      avatar: PropTypes.string,
      id: PropTypes.string,
    }),
    // eslint-disable-next-line react/forbid-prop-types
    avatar: PropTypes.object,
  }),
  /**
   * index of parent group of steps
   */
  parentIndex: PropTypes.number,
  /**
   * information about the overall workflow
   */
  workflowData: PropTypes.shape({
    metadata: PropTypes.shape({
      version: PropTypes.string,
      dataUse: PropTypes.string,
      createdBy: PropTypes.string,
      location: PropTypes.string,
      tenant: PropTypes.string,
      lastUpdated: PropTypes.string,
      createdAt: PropTypes.string,
      userData: PropTypes.shape({
        name: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      }),
    }),
    relationships: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      children: PropTypes.arrayOf(PropTypes.any),
    }),
    reminders: PropTypes.shape({}),
    dueDate: PropTypes.string,
    resource: PropTypes.string,
    id: PropTypes.string,
    reference: PropTypes.string,
    version: PropTypes.number,
    isDraft: PropTypes.bool,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      })
    ),
    status: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    documents: PropTypes.arrayOf(PropTypes.any),
    dateCreated: PropTypes.string,
    startDate: PropTypes.string,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        resubmission: PropTypes.bool,
        duration: PropTypes.number,
        needOnlyOneAdvancer: PropTypes.bool,
        position: PropTypes.number,
        // eslint-disable-next-line react/forbid-prop-types
        documents: PropTypes.arrayOf(PropTypes.any),
        id: PropTypes.string,
        members: PropTypes.arrayOf(
          PropTypes.shape({
            _id: PropTypes.string,
            reference: PropTypes.string,
            status: PropTypes.string,
            date: PropTypes.string,
            userData: PropTypes.shape({
              name: PropTypes.shape({
                firstName: PropTypes.string,
                lastName: PropTypes.string,
              }),
            }),
          })
        ),
        // eslint-disable-next-line react/forbid-prop-types
        parallelSteps: PropTypes.arrayOf(PropTypes.any),
        name: PropTypes.string,
        description: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types
        comments: PropTypes.arrayOf(PropTypes.any),
        dueDate: PropTypes.string,
      })
    ),
    // eslint-disable-next-line react/forbid-prop-types
    associatedWorkflows: PropTypes.arrayOf(PropTypes.any),
    name: PropTypes.string,
    cost: PropTypes.string,
    endDate: PropTypes.string,
    currentStep: PropTypes.number,
  }),
  memberIndex: PropTypes.number,
  handleAdvanceStep: PropTypes.func,
  handleResubmitStep: PropTypes.func,
  handleSendReminder: PropTypes.func,
  isOpen: PropTypes.bool,
  stepData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  hideName: PropTypes.bool,
  currentUser: PropTypes.shape({
    reference: PropTypes.string,
  }),
};

WorkflowStepAction.defaultProps = {
  data: {
    name: {},
  },
  parentIndex: undefined,
  workflowData: {},
  memberIndex: undefined,
  handleAdvanceStep: undefined,
  handleResubmitStep: undefined,
  handleSendReminder: undefined,
  isOpen: undefined,
  stepData: {},
  hideName: false,
  currentUser: undefined,
};
export default WorkflowStepAction;

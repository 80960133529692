import { useCallback, useEffect, useState } from "react";

import { DocumentAPI } from "@griffingroupglobal/eslib-api";
import { useAppState } from "../state/appState";
import { decorateDocumentsWithUserData } from "../helpers/Document";

export default function useDocumentsForProject(
  projectId,
  setLoading = () => {},
  spaceId
) {
  const [{ userDict }] = useAppState();
  const [documents, setDocuments] = useState();

  const reload = useCallback(() => {
    setLoading(true);
    Promise.all([
      DocumentAPI.get({ params: { project: `Project/${projectId}` } }).then(
        (response) => response.data.entries
      ),
    ]).then(([documentsData]) => {
      let filteredDocumentsData = documentsData;

      if (spaceId) {
        filteredDocumentsData = documentsData.filter((doc) => {
          return doc.resource?.spaces?.includes(spaceId);
        });
      }

      setLoading(false);

      decorateDocumentsWithUserData(filteredDocumentsData, userDict);
      setDocuments(filteredDocumentsData.map((doc) => doc.resource));
    });
  }, [projectId, setLoading, spaceId, userDict]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { documents, reload };
}

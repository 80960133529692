import moment from "moment";

/**
 * Checks if a given date is contained within any of the provided date ranges.
 *
 * @param {string} date - The date to check. It should be in a format that Moment.js can parse.
 * @param {Array} [dates=[]] - An array of date ranges. Each range should be an object with 'periodStart' and 'periodEnd' properties.
 * @returns {boolean} - Returns true if the date is contained within any of the date ranges, false otherwise.
 */
const dateContainedInDates = (dateToCheck, dates = []) => {
  if (dates?.length === 0) return false;

  for (let i = 0; i < dates.length; i += 1) {
    const { periodStart, periodEnd } = dates[i];

    if (
      moment(dateToCheck).isSameOrAfter(periodStart) &&
      moment(dateToCheck).isSameOrBefore(periodEnd)
    ) {
      return true;
    }
  }

  return false;
};

export default dateContainedInDates;

/* eslint-disable camelcase */
import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import MessageBody from "./ThreadMessage/MessageBody";
import MessageUserAvatar from "./ThreadMessage/MessageUserAvatar";
import ReplyThread from "./ThreadMessage/Reply/ReplyThread";

const replyButtonCN = (hover, count, showReply) => cntl`
transition-opacity
${hover || showReply || count > 0 ? "opacity-100" : "opacity-0"}
text-xxs
font-semibold
pt-2
`;

const ThreadMessage = ({
  text,
  created_at,
  id,
  user: { id: userId, name: userName },
  mostRecent,
  attachments,
  reply_count,
  resend,
  showReply,
  setShowReply,
}) => {
  const [hover, setHover] = useState();
  const replyButtonText = useMemo(() => {
    return `${showReply ? "hide " : ""}${
      !showReply && reply_count > 0 ? `${reply_count} ` : ""
    }repl${reply_count > 1 ? "ies" : "y"}`;
  }, [reply_count, showReply]);

  return (
    <div
      key={id ?? "message-failure"}
      id={
        mostRecent ? "thread-msg-most-recent" : `thread-msg-${id ?? "failure"}`
      }
      className="flex py-3 gap-3 px-6"
      onMouseOver={() => setHover(true)}
      onFocus={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onBlur={() => setHover(false)}
    >
      <MessageUserAvatar userId={userId} reply={!!resend} count={reply_count} />
      <div>
        <MessageBody
          text={text}
          created_at={created_at}
          userId={userId}
          userName={userName}
          attachments={attachments}
          resend={resend}
          id={id}
        />
        {!resend && (
          <>
            <ReplyThread id={id} count={reply_count} show={showReply} />
            <button
              className={replyButtonCN(hover, reply_count, showReply)}
              onClick={setShowReply}
              type="button"
            >
              {replyButtonText}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

ThreadMessage.propTypes = {
  text: PropTypes.string,
  created_at: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  id: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  mostRecent: PropTypes.bool,
  attachments: PropTypes.arrayOf(PropTypes.shape({})),
  reply_count: PropTypes.number,
  resend: PropTypes.func,
  showReply: PropTypes.bool,
  setShowReply: PropTypes.func,
};

ThreadMessage.defaultProps = {
  text: undefined,
  created_at: undefined,
  id: undefined,
  user: {
    id: undefined,
  },
  mostRecent: false,
  attachments: [],
  reply_count: 0,
  resend: undefined,
  showReply: false,
  setShowReply: undefined,
};

export default ThreadMessage;

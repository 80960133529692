/* eslint-disable no-param-reassign */
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import smoothScrollTo from "../../../helpers/smoothScroll";
import useRelativeAssociations from "../../../hooks/useRelativeAssociations";
import { useAppState } from "../../../state/appState";
import ScheduleBody from "./Schedule/ScheduleBody";

const ScheduleCalendar = ({
  now,
  events,
  handleEventClick,
  calendarLoading,
}) => {
  const [{ calendarTimezone }] = useAppState();
  const { eventFilter } = useRelativeAssociations();
  const [scheduleDays, setScheduleDays] = useState([]);
  const [pageSize, setPageSize] = useState(0);

  useEffect(() => {
    const daysArray = [];

    const startOfMonth = moment.tz(now, calendarTimezone).startOf("month");
    const endOfMonth = moment.tz(now, calendarTimezone).endOf("month");
    // Calculate the total days in the month
    const daysInMonth = endOfMonth.diff(startOfMonth, "days") + 1;

    for (let i = 0; i < daysInMonth; i += 1) {
      const day = moment
        .tz(startOfMonth, calendarTimezone)
        .add(i, "days")
        .startOf("day")
        .format();
      daysArray.push(day);
    }

    setPageSize(daysInMonth);
    setScheduleDays(daysArray);
  }, [calendarTimezone, now]);

  useEffect(() => {
    if (moment(now).startOf("day").isSame(moment().startOf("day"))) {
      smoothScrollTo("current-day");
    } else {
      smoothScrollTo("startOfMonth");
    }
  }, [now, calendarLoading]);

  return (
    <div
      className={`${
        calendarLoading ? "loading" : ""
      } flex-col w-full h-70vh overflow-y-auto pr-4`}
      id="schedule-calendar"
    >
      {scheduleDays?.map((day, i) => {
        const { allDay, brief } = eventFilter(events?.[day]) ?? {
          allDay: [],
          brief: [],
        };

        /**
         * @mixes - allDay & brief event arrays for the day
         */
        const collapsed = [...allDay, ...brief];

        return (
          <ScheduleBody
            key={day}
            first={i === 0}
            collapsed={collapsed}
            dayValue={day}
            dateStart={moment(day)
              .startOf("day")
              .isSame(moment().startOf("day"))}
            handleEventClick={handleEventClick}
            pageSize={pageSize}
            count={i}
          />
        );
      })}
    </div>
  );
};

ScheduleCalendar.propTypes = {
  now: PropTypes.string,
  events: PropTypes.shape({}),
  handleEventClick: PropTypes.func,
  calendarLoading: PropTypes.bool,
};

ScheduleCalendar.defaultProps = {
  now: undefined,
  events: {},
  handleEventClick: undefined,
  calendarLoading: false,
};

export default ScheduleCalendar;

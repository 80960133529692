import { RfiAPI } from "@griffingroupglobal/eslib-api";
import { cloneDeep } from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";
import { rfiKeys } from "../config/reactQuery/queryKeyFactory";
import { AUTH_TOKEN_STORAGE_KEY } from "../constants";
import { getFullName } from "../helpers/Formatters";
import { getAssociationNameFromReference } from "../helpers/Utilities";
import { useAppState } from "../state/appState";
import { useAssetsOverview } from "./assets";
import { usePropertiesOverview } from "./properties";

const getRFI = async (propertiesDict, projectDict, assetsDict, userDict) => {
  const { data } = await RfiAPI.get();

  const rfisDict = {};
  const reducedEntries = data?.entries.reduce((builtEntries, { resource }) => {
    if (!resource.isTemplate) {
      rfisDict[resource?.reference] = resource;
      builtEntries.push({
        ...resource,
        association: getAssociationNameFromReference(
          resource.association,
          propertiesDict,
          projectDict,
          assetsDict
        ),
        createdAt: resource.metadata.createdAt,
        createdBy:
          getFullName(userDict?.[resource.metadata.createdBy]?.name) ?? "",
      });
    }
    return builtEntries;
  }, []);

  return { rfis: reducedEntries, rfisDict };
};

const postRFI = async (
  propertiesDict,
  projectDict,
  assetsDict,
  userDict,
  newRfi
) => {
  const newEntry = {
    ...newRfi,
    association: getAssociationNameFromReference(
      newRfi.association,
      propertiesDict,
      projectDict,
      assetsDict
    ),
    createdAt: newRfi.metadata.createdAt,
    createdBy: getFullName(userDict[newRfi.metadata.createdBy]?.name) ?? "",
  };
  const { data } = await RfiAPI.post(newEntry);
  return data;
};

export const useAddRFI = (mutationKey) => {
  const queryClient = useQueryClient();
  const [{ projectDict, userDict }] = useAppState([]);

  const { assetsDict } = useAssetsOverview();
  const { propertiesDict } = usePropertiesOverview();

  return useMutation({
    mutationKey,
    mutationFn: () =>
      postRFI(propertiesDict, projectDict, assetsDict, userDict),
    onMutate: async (newWorkflow) => {
      const queryKey = rfiKeys.rfis;

      await queryClient.cancelQueries(queryKey);

      const fakeReference = `Rfi/${uuidv4()}`;

      const currentRFIs = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (current) => ({
        [fakeReference]: newWorkflow,
        ...current,
      }));

      return { queryKey, currentRFIs, fakeReference };
    },
    onSuccess: (data, variables, { queryKey, fakeReference }) => {
      const currentRFIs = cloneDeep(queryClient.getQueryData(queryKey));
      const { [fakeReference]: removed, ...rest } = currentRFIs;

      queryClient.setQueryData(queryKey, {
        [data.reference]: data,
        ...rest,
      });
    },
    onError: (error, variables, { queryKey, currentRFIs }) => {
      // Rollback
      queryClient.setQueryData(queryKey, currentRFIs);
    },
  });
};

export default ({ select } = {}) => {
  const authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  const [{ propertiesDict, projectDict, userDict }] = useAppState([]);
  const { assetsDict } = useAssetsOverview();

  return useQuery(
    rfiKeys.rfis,
    () => getRFI(propertiesDict, projectDict, assetsDict, userDict),
    {
      enabled: !!authToken,
      select,
      onError: (err) => {
        console.error("⛔️ useRFIs", err);
      },
      placeholderData: {
        rfis: [],
        rfisDict: {},
      },
    }
  );
};

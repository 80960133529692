import React, { useEffect, useCallback, useState } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import moment from "moment";
import { useQueryClient } from "react-query";
import SignUpAPI from "../../../Pages/SignUp/SignUpAPI";
import Modal from "../Modal/Modal";
import { formatCurrency } from "../../../helpers/Formatters";
import { toastError, toastMessage } from "../Toast/Toast";

const buttonCN = (showGreenBorder) => cntl`
  flex
  flex-col
  w-30
  h-28
  mx-2
  px-4
  py-3
  rounded
  ${showGreenBorder ? "border-4 border-brandGreen" : "border-4"}
`;

const innerCN = (className) => cntl`
  flex
  ${className}
`;

const BillingCycle = ({
  dispatch,
  isMonthly,
  setIsMonthly,
  selectedPlan,
  onClose,
  plans,
  isOpen,
}) => {
  const queryClient = useQueryClient();
  const [discount, setDiscount] = useState();
  const [frequency, setFrequency] = useState();
  const [isSaving, setIsSaving] = useState();

  useEffect(() => {
    setFrequency(isMonthly ? "monthly" : "annual");
  }, [isMonthly]);

  useEffect(() => {
    let totalCost = 0.0;
    let planDiscount = 0.0;
    if (frequency === "monthly") {
      totalCost =
        selectedPlan?.userCount * Number.parseFloat(selectedPlan?.monthlyCost);
    } else {
      totalCost =
        selectedPlan?.userCount *
        Number.parseFloat(selectedPlan?.annualCost) *
        12;
      planDiscount =
        selectedPlan?.userCount *
          Number.parseFloat(selectedPlan?.monthlyCost) *
          12 -
        totalCost;
    }

    setDiscount(planDiscount.toFixed(2));
  }, [frequency, selectedPlan]);

  const onSave = useCallback(async () => {
    try {
      setIsSaving(true);
      setIsMonthly(frequency === "monthly");

      const { priceIdMonthly, priceIdAnnual } = plans?.find(
        (plan) => plan.planName === selectedPlan.planName
      );

      const subscriptionItem = await SignUpAPI.updateSubscriptionItem(
        selectedPlan.subscriptionItem,
        {
          quantity: selectedPlan.userCount,
          price: frequency === "monthly" ? priceIdMonthly : priceIdAnnual,
        },
        queryClient
      );

      // TODO: update next payment date

      dispatch({
        type: "reset",
        subscription: {
          ...selectedPlan,
          billingCycle: frequency,
          nextPayment: (
            Number.parseFloat(subscriptionItem?.plan?.amount / 100) *
            subscriptionItem.quantity
          ).toFixed(2),
        },
      });
      onClose();
      toastMessage("Successfully updated billing cycle");
    } catch (e) {
      console.error("billing cycle error", e);
      toastError(
        `Error updating billing cycle. ${
          e?.response?.data?.issues[0]?.location || e.message || ""
        }`
      );
    } finally {
      setIsSaving(false);
    }
  }, [
    dispatch,
    frequency,
    onClose,
    plans,
    selectedPlan,
    setIsMonthly,
    queryClient,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestModalClose={onClose}
      title="CHANGE BILLING CYCLE"
      primaryButtonTitle="Confirm Change"
      primaryButtonOnClick={onSave}
      disabled={isSaving}
      tertiaryButtonTitle="Cancel"
      hideFooter
      crossIconGreen
    >
      <div className="flex flex-col">
        <div className="flex flex-row justify-center pt-4">
          <button
            key="MONTHLY"
            type="button"
            className={buttonCN(frequency === "monthly")}
            onClick={() => setFrequency("monthly")}
          >
            <div>
              <div className={innerCN("justify-center")}>
                <h2 className="text-gray-900 text-sm font-semibold">MONTHLY</h2>
              </div>
              <div className={innerCN("justify-center")}>
                <h2 className="font-bold text-lg">
                  $ {selectedPlan?.monthlyCost}
                </h2>
              </div>
              <div className={innerCN("justify-center")}>
                <p className="flex pb-2 text-gray-400 text-xs font-normal">
                  per user
                </p>
              </div>
            </div>
          </button>
          <button
            key="ANNUAL"
            type="button"
            className={buttonCN(frequency === "annual")}
            onClick={() => setFrequency("annual")}
          >
            <div>
              <div className={innerCN("justify-center")}>
                <h2 className="text-gray-900 text-sm font-semibold">ANNUAL</h2>
              </div>
              <div className={innerCN("justify-center")}>
                <h2 className="font-bold text-lg">
                  $ {selectedPlan?.annualCost}
                </h2>
              </div>
              <div className={innerCN("justify-center")}>
                <p className="flex pb-2 text-gray-400 text-xs font-normal">
                  per user
                </p>
              </div>
              <div className={innerCN("justify-center")}>
                <p className="flex text-black text-xs font-semibold min-w-max">
                  {selectedPlan?.discount}% discount
                </p>
              </div>
            </div>
          </button>
        </div>
        <div className={innerCN("my-4 mr-6 justify-end")}>
          <div className="flex flex-col">
            <div className="flex justify-end">
              <p className="text-md font-semibold">
                Next Payment on{" "}
                {moment(selectedPlan?.nextPaymentDate).format("MMMM Do, YYYY")}
              </p>
            </div>
            <div className="flex justify-end">
              <p className="text-lg font-bold">
                {" "}
                {formatCurrency(
                  selectedPlan?.userCount *
                    (frequency === "monthly"
                      ? selectedPlan?.monthlyCost
                      : selectedPlan?.annualCost * 12)
                )}
              </p>
            </div>
            <div className="flex justify-end">
              <p className="text-gray-400 text-xs font-normal">
                You save {formatCurrency(discount)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

BillingCycle.propTypes = {
  selectedPlan: PropTypes.shape({
    monthlyCost: PropTypes.number,
    annualCost: PropTypes.number,
    nextPaymentDate: PropTypes.number,
    discount: PropTypes.number,
    planName: PropTypes.string,
    userCount: PropTypes.number,
    subscriptionItem: PropTypes.string,
  }),
  isMonthly: PropTypes.bool,
  setIsMonthly: PropTypes.func,
  onClose: PropTypes.func,
  dispatch: PropTypes.func,
  plans: PropTypes.arrayOf(PropTypes.shape({})),
  isOpen: PropTypes.bool,
};

BillingCycle.defaultProps = {
  selectedPlan: undefined,
  isMonthly: false,
  setIsMonthly: undefined,
  onClose: undefined,
  dispatch: undefined,
  plans: undefined,
  isOpen: false,
};

export default BillingCycle;

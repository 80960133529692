import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import InlineInput from "../Input/InlineInput";

const TextDataView = ({ display, className, threshold }) => {
  // if the value is a string and it's length is greater than the threshold
  // then we need to use a text area to display the value

  const requiresTextField =
    typeof display?.value === "string" && display?.value?.length > threshold;

  // if the value is an object and it has a title prop
  // then it's an html element to parse
  const requiresObjectParser =
    typeof display?.value === "object" &&
    display?.value?.props?.title !== "undefined";

  // if the value is an object and the title prop is greater than the threshold
  // then we need to use a text area to display the value
  const parsedObjectNeedsTextField =
    requiresObjectParser && display?.value?.props?.title.length > threshold;

  // if the value is a date-picker and not empty then we need to
  // return the string formatted for easier reading
  if (display.field === "date-picker" && display.value !== "") {
    // if the value is from a custom attribute the data is stored in the title prop
    const customDataFromField = display?.value?.props?.title || null;
    return moment(customDataFromField ?? display?.value).format("MMM Do YYYY");
  }

  const containerStyling = "flex self-center w-full text-area-padding";

  // if the value is a text area and it's length is greater than the threshold
  // then we need to use a text area to display the value
  if (requiresTextField) {
    return (
      <div className={containerStyling}>
        <InlineInput
          className={className}
          textarea
          value={display?.value}
          editing={false}
          disabled
          color="gray-450"
          width="w-full"
          rows="3"
        />
      </div>
    );
  }

  // if the value is an object and the title prop is less than the threshold
  // then we can use a paragraph to display the value
  if (requiresObjectParser && display?.value?.props?.title.length < threshold) {
    return <p className={className}>{display?.value?.props?.title}</p>;
  }

  // if the value is an object and the title prop is greater than the threshold
  // then we need to use a text area to display the value
  if (requiresObjectParser && parsedObjectNeedsTextField) {
    return (
      <div className={containerStyling}>
        <InlineInput
          className={className}
          textarea
          value={display?.value?.props?.title}
          editing={false}
          disabled
          color="gray-450"
          width="w-full"
          rows="3"
        />
      </div>
    );
  }

  // if the value is a string and it's length is less than the threshold
  // then we can use a paragraph to display the value
  return <p className={className}>{display?.value}</p>;
};

TextDataView.propTypes = {
  display: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    field: PropTypes.string,
  }),
  className: PropTypes.string,
  threshold: PropTypes.number,
};

TextDataView.defaultProps = {
  display: {
    value: "",
    field: "",
  },
  className: "",
  threshold: 30,
};

export default TextDataView;

import React from "react";
import PropTypes from "prop-types";

const PlanSelectionHeader = ({ selectedPlan, userCount, frequency, total }) => {
  const userType = userCount === 1 ? "user" : "users";
  return (
    <div className="flex flex-col pr-8" style={{ width: "28rem" }}>
      <div className="flex flex-row justify-end gap-1">
        <p className="text-es-dark-grey font-es-bold tracking-es-wide">
          {selectedPlan.planName} Plan
        </p>
        <p className="text-es-dark-grey text-es-normal font-es-normal tracking-es-wide">
          (for {userCount} {userType})
        </p>
      </div>
      <div className="flex flex-row justify-end">
        <p className="text-es-dark-grey font-semibold tracking-es-wide">
          {frequency.toUpperCase()} Total: ${total}
        </p>
      </div>
    </div>
  );
};

PlanSelectionHeader.propTypes = {
  selectedPlan: PropTypes.shape({
    planName: PropTypes.string,
  }),
  frequency: PropTypes.string,
  userCount: PropTypes.number,
  total: PropTypes.number,
};

PlanSelectionHeader.defaultProps = {
  selectedPlan: undefined,
  frequency: undefined,
  userCount: undefined,
  total: undefined,
};

export default PlanSelectionHeader;

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import DetailViewBanner from "../DetailViewBanner/DetailViewBanner";
import Input from "../Input/Input";
import BaseButton from "../Buttons/BaseButton";
import link from "../../assets/images/linkGreen.svg";
import placeholderImage from "../../assets/images/placeholderImage.png";
import Checkbox from "../Checkbox/Checkbox";
import Modal from "../Modal/Modal";
import CarouselView from "../CarouselView/CarouselView";
import { transformIntoPositiveFloat } from "../../../helpers/Formatters";

const BuildingEditDetailsForm = ({
  building,
  handleAddImage,
  dispatch,
  disableEditing,
}) => {
  const [selectedImage, setSelectedImage] = useState();
  const [viewImages, setViewImages] = useState(false);

  useEffect(() => {
    setSelectedImage(building?.images.find((img) => img.selected));
  }, [building?.images]);

  const onChangeBuildingName = (value) => {
    dispatch({
      type: "buildingName",
      buildingId: building.id,
      name: value,
    });
  };
  const onChangeBuildingArea = (value) => {
    dispatch({
      type: "buildingArea",
      buildingId: building.id,
      area: transformIntoPositiveFloat(value),
    });
  };

  const selectImage = (image) => {
    dispatch({
      type: "selectBuildingImage",
      buildingId: building.id,
      reference: image.reference,
    });
  };

  const onChangePrimaryImage = useCallback(() => {
    dispatch({
      type: "changeBuildingPrimaryImage",
      buildingId: building.id,
      imageId: selectedImage?.id,
    });
  }, [building, dispatch, selectedImage]);

  return (
    <>
      <div className="flex flex-col w-2/3 mr-4">
        <div className="flex flex-row">
          <div className="flex w-1/2">
            <DetailViewBanner
              resource={building}
              resourceImage={selectedImage?.contentsUrl ?? null}
              onChangeImage={handleAddImage}
              className="rounded border"
              hideButton
              disableUpload={disableEditing}
            />
          </div>
          <div className="flex relative flex-col w-1/2">
            <div className="absolute flex-col bottom-4 w-full pl-6">
              <Input
                placeholder="Name"
                label="Name"
                value={building?.name}
                onChange={onChangeBuildingName}
                disabled={disableEditing}
              />
              <Input
                placeholder="Square Ft."
                label="Square Footage"
                value={building?.area?.value}
                onChange={onChangeBuildingArea}
                type="number"
                minValue="0"
                className="pt-4"
                disabled={disableEditing}
              />
            </div>
          </div>
        </div>
        <div className="pt-2">
          <Checkbox
            label="Primary Image"
            checked={selectedImage?.isPrimary}
            onChange={onChangePrimaryImage}
            disabled={disableEditing}
          />
        </div>
        <div className="flex flex-col pt-4">
          <div className="flex flex-row">
            <p className="text-black font-semibold py-2 text-base">Media</p>
            <BaseButton
              iconLeft={<img src={link} alt="link" className="w-5 h-5" />}
              className="pt-1 px-2"
              onClick={() => setViewImages(true)}
            />
          </div>

          <div className="flex overflow-x-scroll">
            {building?.images?.map((image) => (
              <div
                key={`${image?.contentsUrl}-${image?.name}`}
                className="flex mr-4 mb-4 min-w-max"
                onClick={() => selectImage(image)}
                onKeyDown={() => selectImage(image)}
                role="button"
                tabIndex={0}
              >
                <img
                  className={`h-16 object-cover w-32 rounded  border-gray-200 ${
                    image.selected
                      ? "border-2 border-brandGreen"
                      : "border border-gray-200"
                  }`}
                  src={image?.contentsUrl || placeholderImage}
                  alt={image?.name || "placeholder"}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal
        isOpen={viewImages}
        title="View Media"
        primaryButtonTitle="Close"
        primaryButtonOnClick={() => setViewImages(false)}
        onRequestModalClose={() => setViewImages(false)}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <CarouselView items={building?.images} />
      </Modal>
    </>
  );
};

BuildingEditDetailsForm.propTypes = {
  building: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        thumbnailUrl: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    area: PropTypes.shape({
      value: PropTypes.string,
    }),
    spaces: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        level: PropTypes.string,
        type: PropTypes.string,
        name: PropTypes.string,
        images: PropTypes.arrayOf(
          PropTypes.shape({
            thumbnailUrl: PropTypes.string,
            name: PropTypes.string,
          })
        ),
      })
    ),
  }),
  handleAddImage: PropTypes.func,
  dispatch: PropTypes.func,
  disableEditing: PropTypes.bool,
};

BuildingEditDetailsForm.defaultProps = {
  building: undefined,
  handleAddImage: undefined,
  dispatch: undefined,
  disableEditing: false,
};

export default BuildingEditDetailsForm;

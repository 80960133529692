import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { v4 as uuidv4 } from "uuid";

import { propertyKeys } from "../../../hooks/properties";
import useCurrentUser from "../../../hooks/useCurrentUser";
import Sidebar from "../Sidebar/Sidebar";
import SidebarItem from "../SidebarItem/SidebarItem";
import BetaTag from "../BetaTag";
import menuIcon from "../../assets/images/menuIconBlack.svg";
import {
  ADD_OPEN_MODAL,
  ASSET_TABLE_PATH,
  // RFI_PATH,
  CALENDAR_PATH,
  CONTACTS_PATH,
  CREATE_CONTACT_MODAL,
  CREATE_TASK_MODAL,
  EXPENSES_PATH,
  FILES_OVERVIEW_PATH,
  // CONTACT_US_PATH,
  // DOCUMENTS_PATH,
  HELP_PATH,
  HOME_PATH,
  // WORKFLOWS_PATH,
  PROJECTS_PATH,
  PROPERTY_CREATE_MODAL,
  // MARKETPLACE_PATH,
  PROPERTY_TABLE_PATH,
  REPORT_HOURS_PATH,
  SET_REQUEST_MODAL_STATUS,
  // CREATE_DOCUMENTS_PATH,
  // WORKFLOWS_ADD_NEW_PATH,
  // REPORT_PATH,
  SOP_TABLE_PATH,
  SUBMITTALS_TABLE_PATH,
  TASK_TABLE_PATH,
  TICKETS_PATH,
  EXPENSE_CREATE_MODAL,
  SPACES_PATH,
  WORKFLOW_PATH,
  REPORT_PATH,
  // SUBMITTALS_TABLE_PATH,
  // SET_RFI_MODAL_STATUS,
  SET_WORKFLOW_MODAL_STATUS,
  WF_ACTIVE_STEP_TASK_INFO,
  REPORT_CREATE_MODAL,
  CREATE_EVENT_MODAL,
  EVENT,
  TASK,
  TICKET,
  CONTACTS,
  EXPENSE,
  REPORT,
  CONTACT_US_PATH,
  CREATE_ASSET,
  SUBMITTAL_CREATE_MODAL,
  CREATE_SPACE_MODAL,
  CREATE_SOP_MODAL,
  ASSET,
  PROPERTY,
  PROJECT,
  PROJECT_CREATE_MODAL,
  SOP,
} from "../../../constants";
import { useAppState } from "../../../state/appState";
import { useModalState } from "../../../state/modalState";
import CustomHomeView from "./CustomHomeView";
import RouteSidebarFavorites from "./RouteSidebarFavorites";
import RouteSidebarLinebreak from "./RouteSidebarLinebreak";
import SidebarCustomView from "./SidebarCustomView";
import useFinancialsConfiguration from "../../../hooks/useFinancialsConfiguration";
import {
  hasReadPermission,
  hasWritePermission,
  isEmployeeOrAdmin,
} from "../../../helpers/Permissions";
import useQueryNavigation from "../../../hooks/navigation/useQueryNavigation";
import useRouteSidebarData from "./useRouteSidebarData";

const itemsContainerCN = cntl`
  flex
  flex-col
  items-start
  overflow-auto
  max-w-72
  h-full
  relative
  `;

const { API_VERSION } = window.runConfig ?? {};

/**
 * Renders the header for the Route Sidebar when the sidebar is not open.
 */
function RouteSidebarHeader({
  isOpen,
  setIsOpen,
  isActivePath,
  handleToggleView,
}) {
  return (
    <>
      {isOpen ? (
        <CustomHomeView
          title="Home"
          active={isActivePath(HOME_PATH)}
          showCloseButton
          onCloseClick={() => handleToggleView(false)}
        />
      ) : (
        <div className="flex justify-center">
          <button
            onClick={() => {
              setIsOpen(!isOpen);
              localStorage.setItem("sidebarToggleView", !isOpen);
            }}
            type="button"
            className="pt-6 pb-6"
          >
            <img className="w-6 h-6" src={menuIcon} alt="Menu Icon" />
          </button>
        </div>
      )}
    </>
  );
}

RouteSidebarHeader.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  isActivePath: PropTypes.func.isRequired,
  handleToggleView: PropTypes.func.isRequired,
};

/**
 * Renders the Route Sidebar, which is the primary left side navigation menu.
 */
const RouteSidebar = ({ activeRoute }) => {
  const { favoriteResources } = useRouteSidebarData();

  const defaulSidebarOpenState =
    localStorage.getItem("sidebarToggleView") !== "false";
  const [{ wfActiveStepTaskInfo }, dispatch] = useAppState([]);
  const [{ modals }, modalDispatch] = useModalState();

  const history = useHistory();

  const navigation = useQueryNavigation();

  const { data: currentUser } = useCurrentUser();
  const [isOpen, setIsOpen] = useState(defaulSidebarOpenState);

  const { data: financialsConfiguration } = useFinancialsConfiguration();
  const canReadTimesheets = hasReadPermission("timesheet", currentUser);

  const isActivePath = (path) => {
    const primaryResource = `/${activeRoute?.substring(1).split("/", 1)}`;
    return primaryResource === path;
  };

  const handleToggleView = (status) => {
    setIsOpen(status);
    localStorage.setItem("sidebarToggleView", status);
  };

  const showAddCalendarEventButton =
    hasWritePermission(EVENT, currentUser) &&
    (hasReadPermission(PROPERTY, currentUser) ||
      hasReadPermission(PROJECT, currentUser)) &&
    !modals.find((item) => item.modalType === CREATE_EVENT_MODAL);

  const showAddTaskButton =
    hasWritePermission(TASK, currentUser) &&
    (hasReadPermission(PROPERTY, currentUser) ||
      hasReadPermission(PROJECT, currentUser)) &&
    !modals.find((item) => item.modalType === CREATE_TASK_MODAL);

  const handleAddCalendarEventClick = () => {
    // if the user doesn't have permission don't allow them to open the modal
    if (
      !hasWritePermission(EVENT, currentUser) ||
      (!hasReadPermission(PROPERTY, currentUser) &&
        !hasReadPermission(PROJECT, currentUser))
    )
      return;
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: uuidv4() },
      modalData: { associationLock: false },
      modalType: CREATE_EVENT_MODAL,
    });
    handleToggleView(false);
  };

  const handleAddTaskClick = () => {
    // if the user doesn't have permission don't allow them to open the modal
    if (
      !hasWritePermission(TASK, currentUser) ||
      (!hasReadPermission(PROPERTY, currentUser) &&
        !hasReadPermission(PROJECT, currentUser))
    )
      return;
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: uuidv4() },
      modalType: CREATE_TASK_MODAL,
    });
    handleToggleView(false);
  };

  return (
    <div className="max-w-72 mr-5">
      <Sidebar
        className="flex shadow-medium-lift h-full"
        closedSize={44}
        header={
          <RouteSidebarHeader
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isActivePath={isActivePath}
            handleToggleView={handleToggleView}
          />
        }
        isOpen={isOpen}
        isSwitchHeader
      >
        <>
          {isOpen ? (
            <div id="routeSidebar" className={itemsContainerCN}>
              <RouteSidebarLinebreak />
              {(currentUser?.hasPermission?.("event", "can_read") ||
                currentUser?.hasPermission?.("task", "can_read")) && (
                <SidebarItem
                  label="Calendar"
                  active={isActivePath(CALENDAR_PATH)}
                  customComponent={
                    <SidebarCustomView
                      title="Calendar"
                      active={isActivePath(CALENDAR_PATH)}
                      onTitleClick={() => {
                        history.push(CALENDAR_PATH);
                        handleToggleView(false);
                      }}
                      showAddButton={showAddCalendarEventButton}
                      onAddClick={handleAddCalendarEventClick}
                    />
                  }
                />
              )}

              {currentUser?.hasPermission?.("task", "can_read") && (
                <SidebarItem
                  label="Tasks"
                  active={isActivePath(TASK_TABLE_PATH)}
                  customComponent={
                    <SidebarCustomView
                      title="Tasks"
                      active={isActivePath(TASK_TABLE_PATH)}
                      onTitleClick={() => {
                        history.push(TASK_TABLE_PATH);
                        handleToggleView(false);
                      }}
                      onEnterPress={() => history.push(TASK_TABLE_PATH)}
                      showAddButton={showAddTaskButton}
                      onAddClick={handleAddTaskClick}
                    />
                  }
                />
              )}

              {/* Service Requests/Tickets Menu */}
              {currentUser?.hasPermission?.("ticket", "can_read") && (
                <SidebarItem
                  label="Service Requests"
                  active={isActivePath(TICKETS_PATH)}
                  customComponent={
                    <SidebarCustomView
                      title="Service Requests"
                      active={isActivePath(TICKETS_PATH)}
                      onTitleClick={() => {
                        history.push(TICKETS_PATH);
                        handleToggleView(false);
                      }}
                      onEnterPress={() => history.push(TICKETS_PATH)}
                      showAddButton={hasWritePermission(TICKET, currentUser)}
                      onAddClick={() => {
                        dispatch({
                          type: SET_REQUEST_MODAL_STATUS,
                          open: true,
                          isQuickAdd: true,
                        });
                        handleToggleView(false);
                      }}
                    />
                  }
                />
              )}
              {isEmployeeOrAdmin(currentUser) && (
                <SidebarItem
                  label="Contacts"
                  active={isActivePath(CONTACTS_PATH)}
                  customComponent={
                    <SidebarCustomView
                      title="Contacts"
                      active={isActivePath(CONTACTS_PATH)}
                      onTitleClick={() => {
                        history.push(CONTACTS_PATH);
                        handleToggleView(false);
                      }}
                      onEnterPress={() => history.push(CONTACTS_PATH)}
                      showAddButton={hasWritePermission(CONTACTS, currentUser)}
                      onAddClick={() => {
                        modalDispatch({
                          type: ADD_OPEN_MODAL,
                          ref: { id: uuidv4() },
                          modalType: CREATE_CONTACT_MODAL,
                          modalData: { contactType: "contact" },
                        });
                        handleToggleView(false);
                      }}
                    />
                  }
                />
              )}
              <SidebarItem
                label="Files"
                active={isActivePath(FILES_OVERVIEW_PATH)}
                customComponent={
                  <SidebarCustomView
                    title="Files"
                    active={isActivePath(FILES_OVERVIEW_PATH)}
                    onTitleClick={() => {
                      history.push(FILES_OVERVIEW_PATH);
                      handleToggleView(false);
                    }}
                    onEnterPress={() => history.push(FILES_OVERVIEW_PATH)}
                  />
                }
              />
              <RouteSidebarLinebreak />
              {hasReadPermission(ASSET, currentUser) && (
                <SidebarItem
                  label="Assets"
                  active={isActivePath(ASSET_TABLE_PATH)}
                  customComponent={
                    <SidebarCustomView
                      title="Assets"
                      active={isActivePath(ASSET_TABLE_PATH)}
                      onTitleClick={() => {
                        history.push(ASSET_TABLE_PATH);
                        handleToggleView(false);
                      }}
                      onEnterPress={() => history.push(ASSET_TABLE_PATH)}
                      showAddButton={hasWritePermission("asset", currentUser)}
                      onAddClick={() => {
                        const id = uuidv4();
                        modalDispatch({
                          type: ADD_OPEN_MODAL,
                          ref: { id },
                          modalData: { isQuickAdd: true },
                          modalType: CREATE_ASSET,
                        });
                        handleToggleView(false);
                      }}
                    />
                  }
                />
              )}

              {(currentUser?.isSuperAdmin ||
                currentUser?.isAdmin ||
                currentUser?.hasPermission?.(
                  "administrative",
                  "can_write_property"
                ) ||
                currentUser?.hasPermission?.("property", "can_read")) && (
                <SidebarItem
                  label="Properties"
                  active={isActivePath(PROPERTY_TABLE_PATH)}
                  customComponent={
                    <SidebarCustomView
                      title="Properties"
                      active={isActivePath(PROPERTY_TABLE_PATH)}
                      onTitleClick={() => {
                        navigation.push({
                          location: PROPERTY_TABLE_PATH,
                          queryKey: propertyKeys.all,
                        });

                        handleToggleView(false);
                      }}
                      onEnterPress={() => {
                        navigation.push({
                          location: PROPERTY_TABLE_PATH,
                          queryKey: propertyKeys.all,
                        });
                      }}
                      showAddButton={
                        currentUser?.isSuperAdmin ||
                        currentUser?.isAdmin ||
                        currentUser?.hasPermission?.(
                          "administrative",
                          "can_write_property"
                        )
                      }
                      onAddClick={() => {
                        modalDispatch({
                          type: ADD_OPEN_MODAL,
                          ref: { id: uuidv4() },
                          modalData: { isQuickAdd: true },
                          modalType: PROPERTY_CREATE_MODAL,
                        });
                        handleToggleView(false);
                      }}
                    />
                  }
                />
              )}
              {currentUser?.hasPermission?.("project", "can_read") && (
                <SidebarItem
                  label="Projects"
                  active={isActivePath(PROJECTS_PATH)}
                  customComponent={
                    <SidebarCustomView
                      title={
                        <div className="flex">
                          <div>Projects</div>
                          <BetaTag />
                        </div>
                      }
                      active={isActivePath(PROJECTS_PATH)}
                      onTitleClick={() => {
                        history.push(PROJECTS_PATH);
                        handleToggleView(false);
                      }}
                      onEnterPress={() => history.push(PROJECTS_PATH)}
                      showAddButton={
                        currentUser?.isSuperAdmin ||
                        currentUser?.isAdmin ||
                        currentUser?.hasPermission?.(
                          "administrative",
                          "can_write_project"
                        )
                      }
                      onAddClick={() => {
                        modalDispatch({
                          type: ADD_OPEN_MODAL,
                          ref: { id: uuidv4() },
                          modalData: { isQuickAdd: true },
                          modalType: PROJECT_CREATE_MODAL,
                        });
                        handleToggleView(false);
                      }}
                    />
                  }
                />
              )}
              {(currentUser?.hasPermission?.("project", "can_read") ||
                currentUser?.hasPermission?.("property", "can_read")) && (
                <SidebarItem
                  label="Spaces"
                  active={isActivePath(SPACES_PATH)}
                  customComponent={
                    <SidebarCustomView
                      title="Spaces"
                      active={isActivePath(SPACES_PATH)}
                      onTitleClick={() => {
                        history.push(SPACES_PATH);
                        handleToggleView(false);
                      }}
                      onEnterPress={() => history.push(SPACES_PATH)}
                      showAddButton={
                        currentUser?.isSuperAdmin ||
                        currentUser?.isAdmin ||
                        currentUser?.hasPermission?.(
                          "administrative",
                          "can_write_project"
                        ) ||
                        currentUser?.hasPermission?.(
                          "administrative",
                          "can_write_property"
                        )
                      }
                      onAddClick={() => {
                        modalDispatch({
                          type: ADD_OPEN_MODAL,
                          ref: { id: uuidv4() },
                          modalData: { isQuickAdd: true },
                          modalType: CREATE_SPACE_MODAL,
                        });
                        handleToggleView(false);
                      }}
                    />
                  }
                />
              )}
              <RouteSidebarLinebreak />
              {/* <SidebarItem
                label="Documents"
                active={isActivePath(DOCUMENTS_PATH)}
                customComponent={
                  <SidebarCustomView
                    title="Documents"
                    active={isActivePath(DOCUMENTS_PATH)}
                    onTitleClick={() => {
                      history.push(DOCUMENTS_PATH);
                      handleToggleView(false);
                    }}
                    onEnterPress={() => history.push(DOCUMENTS_PATH)}
                    showAddButton={
                      currentUser?.isAdmin ||
                      currentUser?.hasPermission?.("document", "can_write")
                    }
                    onAddClick={() => {
                      history.push(CREATE_DOCUMENTS_PATH);
                      handleToggleView(false);
                    }}
                  />
                }
              /> */}
              {currentUser?.hasPermission?.("expense", "can_read") && (
                <SidebarItem
                  label="Expenses"
                  active={isActivePath(EXPENSES_PATH)}
                  customComponent={
                    <SidebarCustomView
                      title="Expenses"
                      active={isActivePath(EXPENSES_PATH)}
                      onTitleClick={() => {
                        history.push(EXPENSES_PATH);
                        handleToggleView(false);
                      }}
                      onEnterPress={() => history.push(EXPENSES_PATH)}
                      showAddButton={hasWritePermission(EXPENSE, currentUser)}
                      onAddClick={() => {
                        modalDispatch({
                          type: ADD_OPEN_MODAL,
                          ref: { id: uuidv4() },
                          modalType: EXPENSE_CREATE_MODAL,
                          modalData: { viewMode: "create", isQuickAdd: true },
                        });
                        handleToggleView(false);
                      }}
                    />
                  }
                />
              )}
              {/* {currentUser?.hasPermission?.("task", "can_read") && (
                <SidebarItem
                  label="Maintenance"
                  active={isActivePath(MAINTENANCE_TASKS_PATH)}
                  customComponent={
                    <SidebarCustomView
                      title="Maintenance"
                      active={isActivePath(MAINTENANCE_TASKS_PATH)}
                      onTitleClick={() => {
                        history.push(MAINTENANCE_TASKS_PATH);
                        handleToggleView(false);
                      }}
                      onEnterPress={() => history.push(MAINTENANCE_TASKS_PATH)}
                      showAddButton={
                        currentUser?.isSuperAdmin ||
                        currentUser?.isAdmin ||
                        currentUser?.hasPermission?.("task", "can_create")
                      }
                      onAddClick={() => {
                        // TODO: add create flow
                        setOpenNewMaintenanceModal(true);
                        setIsOpen(false);
                      }}
                    />
                  }
                />
              )} */}
              {/* <SidebarItem
                label="RFI"
                active={isActivePath(RFI_PATH)}
                customComponent={
                  <SidebarCustomView
                    title="RFI"
                    active={isActivePath(RFI_PATH)}
                    onTitleClick={() => {
                      history.push(RFI_PATH);
                      handleToggleView(false);
                    }}
                    onEnterPress={() => history.push(RFI_PATH)}
                    showAddButton
                    onAddClick={() => {
                      dispatch({
                        type: SET_RFI_MODAL_STATUS,
                        open: true,
                      });
                      handleToggleView(false);
                    }}
                  />
                }
              /> */}
              {currentUser?.hasPermission?.("sop", "can_read") && (
                <SidebarItem
                  label="SOPs"
                  active={isActivePath(SOP_TABLE_PATH)}
                  customComponent={
                    <SidebarCustomView
                      title="SOPs"
                      active={isActivePath(SOP_TABLE_PATH)}
                      onTitleClick={() => {
                        history.push(SOP_TABLE_PATH);
                        handleToggleView(false);
                      }}
                      onEnterPress={() => history.push(SOP_TABLE_PATH)}
                      showAddButton={hasWritePermission(SOP, currentUser)}
                      /*      onAddClick={() => {
                        dispatch({
                          type: SET_SOP_MODAL_STATUS,
                          open: true,
                        });
                        handleToggleView(false);
                      }} */
                      onAddClick={() => {
                        modalDispatch({
                          type: ADD_OPEN_MODAL,
                          ref: { id: uuidv4() },
                          modalData: { isQuickAdd: true },
                          modalType: CREATE_SOP_MODAL,
                        });
                        handleToggleView(false);
                      }}
                    />
                  }
                />
              )}
              {/* @deprecated but keeping code to help with migratinng fin docs flows */}
              {/* {currentUser?.hasPermission?.("workflow", "can_read") && (
                <SidebarItem
                  label="Workflows (old)"
                  active={isActivePath(WORKFLOWS_PATH)}
                  customComponent={
                    <SidebarCustomView
                      title="Workflows (old)"
                      active={isActivePath(WORKFLOWS_PATH)}
                      onTitleClick={() => {
                        history.push(WORKFLOWS_PATH);
                        handleToggleView(false);
                      }}
                      onEnterPress={() => history.push(WORKFLOWS_PATH)}
                      showAddButton={
                        currentUser?.isAdmin ||
                        currentUser?.hasPermission?.("workflow", "can_create")
                      }
                      onAddClick={() => {
                        history.push(WORKFLOWS_ADD_NEW_PATH);
                        handleToggleView(false);
                      }}
                    />
                  }
                  className={
                    shouldHideNavigationInStagingAndProd(window) && "hidden"
                  }
                />
              )} */}
              {currentUser?.hasPermission?.("workflow", "can_read") && (
                <SidebarItem
                  label="Workflows"
                  active={isActivePath(WORKFLOW_PATH)}
                  customComponent={
                    <SidebarCustomView
                      title="Workflows"
                      active={isActivePath(WORKFLOW_PATH)}
                      onTitleClick={() => {
                        // reset active workflow step info
                        if (wfActiveStepTaskInfo?.show) {
                          dispatch({
                            type: WF_ACTIVE_STEP_TASK_INFO,
                            payload: { ...wfActiveStepTaskInfo, show: false },
                          });
                        }
                        history.push(WORKFLOW_PATH);
                        handleToggleView(false);
                      }}
                      onEnterPress={() => history.push(WORKFLOW_PATH)}
                      showAddButton={
                        currentUser?.isAdmin ||
                        currentUser?.hasPermission?.("workflow", "can_create")
                      }
                      onAddClick={() => {
                        dispatch({
                          type: SET_WORKFLOW_MODAL_STATUS,
                          open: true,
                          isQuickAdd: true,
                        });
                      }}
                    />
                  }
                />
              )}
              {currentUser?.hasPermission?.("submittal", "can_see") && (
                <SidebarItem
                  label="Submittals"
                  active={isActivePath(SUBMITTALS_TABLE_PATH)}
                  customComponent={
                    <SidebarCustomView
                      title="Submittals"
                      active={isActivePath(SUBMITTALS_TABLE_PATH)}
                      onTitleClick={() => {
                        history.push(SUBMITTALS_TABLE_PATH);
                        handleToggleView(false);
                      }}
                      onEnterPress={() => history.push(SUBMITTALS_TABLE_PATH)}
                      showAddButton={
                        currentUser?.isSuperAdmin ||
                        currentUser?.isAdmin ||
                        currentUser?.hasPermission?.("submittal", "can_create")
                      }
                      onAddClick={() => {
                        modalDispatch({
                          type: ADD_OPEN_MODAL,
                          ref: { id: "create-submittal-modal" },
                          modalData: {
                            id: "create-submittal-modal",
                          },
                          modalType: SUBMITTAL_CREATE_MODAL,
                        });
                        handleToggleView(false);
                      }}
                    />
                  }
                />
              )}

              {/* Reports Menu */}
              {(currentUser?.isAdmin ||
                currentUser?.hasPermission?.("report", "can_read")) && (
                <SidebarItem
                  label="Reports"
                  active={isActivePath(REPORT_PATH)}
                  customComponent={
                    <SidebarCustomView
                      title={
                        <div className="flex">
                          <div>Reports</div>
                          <BetaTag />
                        </div>
                      }
                      active={isActivePath(REPORT_PATH)}
                      onTitleClick={() => {
                        history.push(REPORT_PATH);
                        handleToggleView(false);
                      }}
                      onEnterPress={() => history.push(REPORT_PATH)}
                      showAddButton={hasWritePermission(REPORT, currentUser)}
                      onAddClick={() => {
                        modalDispatch({
                          type: ADD_OPEN_MODAL,
                          ref: { id: uuidv4() },
                          modalData: { isQuickAdd: true },
                          modalType: REPORT_CREATE_MODAL,
                        });
                        handleToggleView(false);
                      }}
                    />
                  }
                />
              )}

              {(canReadTimesheets || currentUser?.isAdmin) && (
                <SidebarItem
                  label="Timesheets"
                  active={isActivePath(REPORT_HOURS_PATH)}
                  customComponent={
                    <SidebarCustomView
                      title="Timesheets"
                      active={isActivePath(REPORT_HOURS_PATH)}
                      onTitleClick={() => {
                        history.push(REPORT_HOURS_PATH);
                        handleToggleView(false);
                      }}
                      locked={
                        !financialsConfiguration?.financials?.payroll?.period ||
                        !financialsConfiguration?.financials?.period?.start
                      }
                      alert={{
                        title: "Settings & Configuration",
                        prompt:
                          "Can't access Timesheets until Payroll & Timesheet settings are configured.",
                      }}
                      onEnterPress={() => history.push(REPORT_HOURS_PATH)}
                    />
                  }
                />
              )}
              <RouteSidebarLinebreak />
              {favoriteResources.length ? (
                <>
                  <RouteSidebarFavorites
                    isActivePath={isActivePath}
                    setIsOpen={setIsOpen}
                    favorites={favoriteResources}
                  />
                  <RouteSidebarLinebreak />
                </>
              ) : null}
              {/* <SidebarItem
                label="Marketplace"
                active={isActivePath(MARKETPLACE_PATH)}
                onClick={() => {
                  history.push(MARKETPLACE_PATH);
                  setIsOpen(false);
                }}
                notBold
              />
            }
          />
        )}

        

              <SidebarItem
                label="Contact Us"
                active={isActivePath(CONTACT_US_PATH)}
                onClick={() => {
                  history.push(CONTACT_US_PATH);
                  setIsOpen(false);
                }}
                notBold
              /> */}
              <SidebarItem
                label="Help"
                active={isActivePath(HELP_PATH)}
                onClick={() => {
                  history.push(HELP_PATH);
                  setIsOpen(false);
                }}
                notBold
              />
              <SidebarItem
                label="Contact Us"
                active={isActivePath(CONTACT_US_PATH)}
                onClick={() => {
                  history.push(CONTACT_US_PATH);
                  setIsOpen(false);
                }}
                notBold
              />
              <SidebarItem
                className="pointer-events-none mt-auto"
                label={`Version ${API_VERSION}`}
              />
            </div>
          ) : null}
        </>
      </Sidebar>
    </div>
  );
};

RouteSidebar.propTypes = {
  /**
   * the path name to match against for setting the active highlight
   */
  activeRoute: PropTypes.string.isRequired,
};

export default RouteSidebar;

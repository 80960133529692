import React, { useState } from "react";
import PropTypes from "prop-types";
import Sidebar from "../Sidebar/Sidebar";
import IconLabel from "../IconLabel/IconLabel";
import MenuIcon from "../../assets/images/align-left.svg";
import CrossButton from "../CrossButton/CrossButton";

const TableMiniMapView = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const closedSize = 40;

  const Header = (
    <div
      className="flex justify-between items-center pr-2 py-2"
      style={{ height: closedSize }}
      onClick={() => setIsOpen((prev) => !prev)}
      tabIndex={0}
      onKeyDown={() => {}}
      role="button"
    >
      {isOpen ? (
        <>
          <p className="px-2 font-bold text-gray-300">Mini-Map</p>
          <CrossButton
            title="Mini-Map"
            onClick={() => setIsOpen((prev) => !prev)}
          />
        </>
      ) : (
        <IconLabel icon={MenuIcon} className="ml-3 w-5" />
      )}
    </div>
  );

  return (
    <aside className="fixed z-10 top-1/4 right-0 flex flex-wrap">
      <Sidebar
        isOpen={isOpen}
        header={Header}
        closedSize={closedSize}
        isSwitchHeader
        openSize="200"
        className={
          isOpen
            ? "border overflow-y-scroll overflow-x-hidden"
            : "border border-brandGreen rounded"
        }
        useAutoHeight
      >
        <div className="my-2 ">{children}</div>
      </Sidebar>
    </aside>
  );
};

TableMiniMapView.propTypes = {
  /**
   * react component to display as content
   */
  children: PropTypes.element.isRequired,
};

TableMiniMapView.defaultProps = {};

export default TableMiniMapView;

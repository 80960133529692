import React, { useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import "./SignUp.css";
import Plans from "./Plans";
import {
  SIGNUP_PATH,
  SIGNUP_CHECKOUT_PATH,
  SIGNUP_CONFIRMATION_PATH,
} from "../../constants";
import SignupComplete from "../../stories/Components/SubscriptionCheckoutForm/SignupComplete";
import SubscriptionCheckoutForm from "../../stories/Components/SubscriptionCheckoutForm/SubscriptionCheckoutForm";

const Signup = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isMonthly, setIsMonthly] = useState(true);
  const [orderDetails, setOrderDetails] = useState(null);

  return (
    <Switch>
      <Route exact path={SIGNUP_CONFIRMATION_PATH}>
        {(!orderDetails && (
          <Redirect
            to={{
              pathname: SIGNUP_PATH,
            }}
          />
        )) || <SignupComplete orderDetails={orderDetails} />}
      </Route>
      <Route exact path={SIGNUP_CHECKOUT_PATH}>
        {(!selectedPlan && (
          <Redirect
            to={{
              pathname: SIGNUP_PATH,
            }}
          />
        )) || (
          <SubscriptionCheckoutForm
            selected={selectedPlan}
            billingFrequency={isMonthly ? "monthly" : "annual"}
            setOrderDetails={setOrderDetails}
          />
        )}
      </Route>
      <Route exact path={SIGNUP_PATH}>
        <Plans
          setSelectedPlan={setSelectedPlan}
          setIsMonthly={setIsMonthly}
          isMonthly={isMonthly}
        />
      </Route>
    </Switch>
  );
};

Signup.propTypes = {};

Signup.defaultProps = {};

export default Signup;

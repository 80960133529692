const ERROR_MESSAGE_UNDEFINED_ARRAYS =
  "getTaskListFromDay: allDay or brief is null undefined or false";

const ERROR_MESSAGE_UNDEFINED_DAY =
  "getTaskListFromDay: dayEvent is null undefined or false";

const ERROR_MESSAGE_WRONG_DT =
  "getTaskListFromDay: allDay or brief is not an array";

const getTaskListFromDay = (dayEvent) => {
  // If no dayEvent, return empty array
  if (!dayEvent) {
    console.error(ERROR_MESSAGE_UNDEFINED_DAY);
    return [];
  }
  // destructure required arrays from dayEvent
  const allDay = dayEvent?.allDay; // If no allDay or brief events, return empty array
  const brief = dayEvent?.brief;

  if (!allDay && !brief) {
    console.error(ERROR_MESSAGE_UNDEFINED_ARRAYS);
    return [];
  }
  // If allDay or brief events are not an array, return empty array
  const { isArray } = Array;
  // if either of these items are not arrays, return empty array
  if (!isArray(allDay) && !isArray(brief)) {
    console.error(ERROR_MESSAGE_WRONG_DT);
    return [];
  }
  // create a list of tasks from allDay and brief events
  const taskList = [
    ...allDay.filter((allDayItem) => allDayItem.resource === "Task"),
    ...brief.filter((briefItem) => briefItem.resource === "Task"),
  ];
  // return the task list
  return taskList;
};

export default getTaskListFromDay;

import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Dropdown from "../Dropdown/Dropdown";
import {
  PAYROLL_PERIODS,
  TS_PERIOD_LOCKED_MSG,
  TS_PERIOD_UNLOCKED_MSG,
} from "../../../constants";
import { timesheetSettingsValidation } from "../../../helpers/FormValidations";
import lockIcon from "../../assets/images/lockIcon.svg";
import exclamationGreen from "../../assets/images/exclamationGreen.svg";
import notiAlertIcon from "../../assets/images/notiAlertIcon.svg";
import { useAppState } from "../../../state/appState";

const PayrollPeriodsSection = ({
  financialsSettings,
  dispatchFinancialsSettings,
}) => {
  const payrollValue = useMemo(() => {
    return PAYROLL_PERIODS.find(
      (period) => period.value === financialsSettings?.payroll?.period
    );
  }, [financialsSettings?.payroll?.period]);

  const onChangePayrollPeriod = useCallback(
    (newValue) => {
      dispatchFinancialsSettings({
        type: "changePayrollPeriod",
        value: newValue.value,
      });
    },
    [dispatchFinancialsSettings]
  );

  const [{ financialsConfiguration }] = useAppState();

  const showLockedMessage = useMemo(() => {
    return financialsConfiguration?.financials?.period?.locked === true;
  }, [financialsConfiguration]);

  return (
    <div>
      <div className="flex w-1/4 pb-4 gap-3">
        <Dropdown
          value={payrollValue}
          options={PAYROLL_PERIODS}
          onChange={onChangePayrollPeriod}
          validation={timesheetSettingsValidation(payrollValue)}
          disableSort
          disableClear
          isDisabled={financialsSettings?.payroll?.locked}
        />
        {financialsSettings?.payroll?.locked && (
          <img className="inline-block" src={lockIcon} alt="locked-payroll" />
        )}
      </div>
      <div className="flex">
        <img
          className="mr-2"
          src={showLockedMessage ? notiAlertIcon : exclamationGreen}
          alt="exclamation"
        />
        <h1 className={showLockedMessage ? "text-es-red" : "text-es-green"}>
          {showLockedMessage ? TS_PERIOD_LOCKED_MSG : TS_PERIOD_UNLOCKED_MSG}{" "}
        </h1>
      </div>
    </div>
  );
};

PayrollPeriodsSection.propTypes = {
  financialsSettings: PropTypes.shape({
    payroll: PropTypes.shape({
      period: PropTypes.string,
      locked: PropTypes.bool,
    }),
    period: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
  }),
  dispatchFinancialsSettings: PropTypes.func,
};

PayrollPeriodsSection.defaultProps = {
  financialsSettings: undefined,
  dispatchFinancialsSettings: undefined,
};

export default PayrollPeriodsSection;

import Api from "@griffingroupglobal/eslib-api";
import { TENANT_ID_STORAGE_KEY } from "../constants";

let requestInterceptor;

export default () => {
  // Clean up any existing interceptor
  if (requestInterceptor) {
    Api.interceptors.request.eject(requestInterceptor);
  }

  // Add the x-es-tenantid header to all requests
  requestInterceptor = Api.interceptors.request.use((request) => {
    const tenantId = localStorage.getItem(TENANT_ID_STORAGE_KEY);
    const newRequest = { ...request };
    newRequest.headers["x-es-tenantid"] = tenantId;

    return newRequest;
  });
};

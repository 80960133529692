import { useCallback, useMemo, useState } from "react";
import formatUserEvents from "../../helpers/Calendar/formatUserEvents";
import useCalendar from "../../hooks/calendar/useCalendar";

const useCalendarViewData = ({ calendarTimezone }) => {
  // State for tracking which event types are currently selected
  const [selectedEventType, setSelectedEventType] = useState({
    task: true,
    event: true,
    maintenance: true,
  });

  // State for tracking selected project IDs for filtering
  const [selectedProjectIds, setSelectedProjectIds] = useState([]);

  const { calendarList, calendarLoading } = useCalendar();

  // Memoized function to determine if an event is associated with a selected project
  const filterBySelectedProjectIds = useCallback(
    (event) => {
      // Allow events if no project IDs are selected
      if (selectedProjectIds.length === 0) {
        return true;
      }
      // Extracting the association ID and resource type from the event
      const associationId = event?.association?.split("/")[1];
      const associationResource = event?.association?.split("/")[0];

      // Check if the event is associated with a project and if that project is selected
      if (associationResource === "Project") {
        return selectedProjectIds.includes(associationId);
      }

      return false;
    },
    [selectedProjectIds] // Dependencies for useCallback
  );

  // Memoized function to filter events by type (task/event) and by project
  const filterEventsByTypeAndProject = useCallback(
    (events) => {
      // First, filter events based on their resource type
      const eventsByResourceType = events.filter((event) => {
        const isTask = event.resource === "Task";
        const isEvent = event.resource === "Event";

        return (
          (selectedEventType.task && isTask) ||
          (selectedEventType.event && isEvent)
        );
      });

      // Further filter the events based on their project association
      return eventsByResourceType.filter((event) =>
        filterBySelectedProjectIds(event)
      );
    },
    [selectedEventType, filterBySelectedProjectIds] // Dependencies for useCallback
  );

  // useMemo to compute filtered events only when dependencies change
  const filteredEvents = useMemo(() => {
    // reformat events locally (only for calendar view) based on selectedTimezone
    const updatedEvents = formatUserEvents(calendarList, calendarTimezone);

    // Reduce the userEvents object to a new object with filtered events
    return Object.keys(updatedEvents || {}).reduce((acc, date) => {
      const { allDay, brief } = updatedEvents[date];

      // Apply filter for allDay and brief events
      acc[date] = {
        allDay: filterEventsByTypeAndProject(allDay),
        allDayCount: allDay.length,
        brief: filterEventsByTypeAndProject(brief),
      };

      return acc;
    }, {});
  }, [calendarTimezone, calendarList, filterEventsByTypeAndProject]); // Dependencies for useMemo

  return {
    selectedEventType,
    filteredEvents,
    selectedProjectIds,
    calendarLoading,
    setSelectedEventType,
    setSelectedProjectIds,
  };
};

export default useCalendarViewData;

import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import CurrencyView from "../../../CurrencyView/CurrrencyView";
import useDocumentDetailsSummaryData from "./useDocumentDetailsSummaryData";

const keyCN = cntl`
font-semibold text-md w-48 text-gray-450
`;

const summaryKeyCN = cntl`
font-semibold text-md w-2/3 text-gray-450
`;

const summaryValueCN = cntl`
font-normal text-md mr-20 w-1/3 text-gray-450
`;

const DocumentDetailsSummary = ({
  documentNumber,
  budget,
  docBudgetLines,
  project,
}) => {
  const { summaryLines } = useDocumentDetailsSummaryData({
    project,
    documentNumber,
    docBudgetLines,
    budget,
  });

  return (
    <div className="flex pb-4 w-3/4">
      <p className={`${keyCN}`}>Summary</p>
      <div className="flex flex-col w-2/3 pt-4">
        {summaryLines?.map((line) => (
          <div className="flex border-b pb-4 mb-6 w-full mr-20" key={line.key}>
            <p className={summaryKeyCN}>{line.key}</p>
            <p className={summaryValueCN}>
              <CurrencyView value={line.value} />
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

DocumentDetailsSummary.propTypes = {
  documentNumber: PropTypes.string,
  budget: PropTypes.shape({}),
  docBudgetLines: PropTypes.arrayOf(PropTypes.shape({})),
  project: PropTypes.shape({}),
};

DocumentDetailsSummary.defaultProps = {
  documentNumber: undefined,
  budget: undefined,
  docBudgetLines: [],
  project: undefined,
};

export default DocumentDetailsSummary;

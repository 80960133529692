import moment from "moment";
import React from "react";
import { FILTER_TYPES } from "../../../constants";
import { getLastActiveDisplay } from "../../../helpers/Time";
import { formatPhoneNumber } from "../../../helpers/Formatters";

export const contactsViewColumns = (disciplines) => {
  return [
    {
      Header: "Name",
      accessor: "name",
      isPrimary: true,
      Cell: ({
        row: {
          original: { name, company, kind },
        },
      }) => {
        if (kind === "company") {
          return company?.value;
        }
        return `${name?.firstName || ""} ${name?.lastName || ""}`;
      },
      sortType: (a, b) => {
        let aVal;
        let bVal;
        if (a.values.kind === "company") {
          aVal = `${a.values.company?.label}`.toLowerCase();
        } else {
          aVal = `${a.values.name?.firstName}`.toLowerCase();
        }

        if (b.values.kind === "company") {
          bVal = `${b.values.company?.label}`.toLowerCase();
        } else {
          bVal = `${b.values.name?.firstName}`.toLowerCase();
        }

        return aVal.localeCompare(bVal);
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({
        row: {
          original: { email, contactPoint, kind },
        },
      }) => {
        if (kind === "member") {
          return email;
        }

        return contactPoint.find((cp) => cp.system === "Email")?.value || "";
      },
      sortType: (a, b) => {
        let aVal;
        let bVal;
        if (a.values.kind !== "member") {
          aVal = `${
            a.values?.contactPoint?.find((cp) => cp.system === "Email")?.value
          }`.toLowerCase();
        } else {
          aVal = `${a.values.email}`.toLowerCase();
        }

        if (b.values.kind !== "member") {
          bVal = `${
            b.values?.contactPoint?.find((cp) => cp.system === "Email")?.value
          }`.toLowerCase();
        } else {
          bVal = `${b.values.email}`.toLowerCase();
        }

        return aVal.localeCompare(bVal);
      },
      width: 250,
    },
    {
      Header: "Mobile",
      accessor: "mobile",
      Cell: ({
        row: {
          original: { contactPoint },
        },
      }) => {
        const phone =
          contactPoint.find((cp) => cp.system === "Phone")?.value || "";
        return formatPhoneNumber(phone);
      },
    },
    {
      Header: "Type",
      accessor: "kind",
      Cell: ({ value }) => {
        if (value === "nonmember") {
          return "non-member".charAt(0).toUpperCase() + "non-member".slice(1);
        }
        return value?.charAt(0).toUpperCase() + value?.slice(1);
      },
      filterOptions: {
        filterType: FILTER_TYPES.isOrNot,
      },
    },
    {
      Header: "Last Active",
      accessor: "lastActive",
      Cell: ({
        row: {
          original: { lastActive, isOnline },
        },
      }) => {
        return getLastActiveDisplay(lastActive, isOnline);
      },
      width: 200,
    },
    {
      Header: "Company",
      accessor: "company",
      Cell: ({
        row: {
          original: { company, kind },
        },
      }) => {
        if (kind !== "company") {
          return company?.label || "";
        }

        return "";
      },
    },
    {
      Header: "Date Modified",
      accessor: "lastUpdated",
      Cell: ({ value }) => (
        <div className="flex">
          <p>{moment(value).format("MMM. D, YYYY - h:mm A")}</p>
        </div>
      ),
      filterOptions: {
        label: "Date Range",
        filterType: FILTER_TYPES.date,
        duplicates: [
          {
            label: "Last 7 days",
            id: "lastSevenDays",
          },
          {
            label: "Last 30 days",
            id: "lastThirtyDays",
          },
          {
            label: "Last 3 months",
            id: "lastThreeMonths",
          },
          {
            label: "Last 12 months",
            id: "lastTwelveMonths",
          },
        ],
      },
      width: 350,
    },
    {
      Header: "Disciplines",
      accessor: "disciplines",
      Cell: ({ value }) => {
        return value
          .map((disciplineId) => {
            return disciplines?.find((d) => d.value === disciplineId)?.label;
          })
          .filter((item) => !!item)
          ?.join(",");
      },
      filterOptions: {
        filterType: FILTER_TYPES.includesExcludes,
        type: "includes",
        customOptions: disciplines,
      },
    },
  ];
};

export default contactsViewColumns;

import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";

const actionButtonCN = (className) => cntl`
  cursor-pointer
  flex
  flex-col
  ${className}
`;

const imgCN = (imgClassName) => cntl`
  m-auto
  ${imgClassName}
`;

const textCN = cntl`
  text-xs
  grey-100
  pt-2
`;

const ActionButton = ({ className, imgClassName, onClick, text, icon }) => (
  <button type="button" className={actionButtonCN(className)} onClick={onClick}>
    <img src={icon} className={imgCN(imgClassName)} alt="" />
    <h6 className={textCN}> {text} </h6>
  </button>
);

ActionButton.propTypes = {
  /**
   * icon that is displayed in the button
   */
  icon: PropTypes.node,
  /**
   * classes to apply to the button
   */
  className: PropTypes.string,
  /**
   * classes to apply to the icon, width and height is provided by default but can be overwritten
   */
  imgClassName: PropTypes.string,
  /**
   * function called when the button is clicked
   */
  onClick: PropTypes.func,
  /**
   * string value that displays on the bottom of the button
   */
  text: PropTypes.string,
};

ActionButton.defaultProps = {
  icon: null,
  className: null,
  imgClassName: "w-4 h-4",
  onClick: undefined,
  text: "",
};

export default ActionButton;

import moment from "moment";

/**
 * Calculate and format the difference between 2 dates in days
 */
const calculateDayDifference = (startDate, endDate) => {
  const date1 = moment(startDate);
  const date2 = moment(endDate);

  const differenceInDays = date2.diff(date1, "days");

  const formattedDifference = `${differenceInDays} day${
    differenceInDays !== 1 ? "s" : ""
  }`;

  return formattedDifference;
};

export default calculateDayDifference;

import PropTypes from "prop-types";
import React from "react";
import OwnedByListContactItem from "./OwnedByListContactItem";

const OwnedByListContacts = ({
  currentMembers = [],
  handleClick,
  setEditing,
}) => {
  return (
    <div className="flex flex-col w-full h-full justify-start scrollbar">
      {currentMembers?.reduce((acc, item) => {
        // filter out contacts w/o firstName or company name or contactType
        const hasFirstName = !!item?.name?.firstName;
        const isCompany =
          item?.company?.valueType === "string" && !!item?.company?.value;

        const tobeCreatedContact =
          item?.contactType === "contact" && !!item?.name;
        if (hasFirstName || isCompany) {
          const updatedItem = {
            ...item,
            name: {
              firstName: item?.name?.firstName || item?.company?.value || "",
              lastName: item?.name?.lastName || "",
            },
          };
          acc.push(
            <OwnedByListContactItem
              key={item?.id}
              handleClick={handleClick}
              user={updatedItem}
              isSelected={item.isSelected}
              setEditing={setEditing}
            />
          );
        } else if (tobeCreatedContact) {
          const updatedItem = {
            ...item,
            name: {
              firstName: item?.name,
              lastName: "",
            },
          };
          acc.push(
            <OwnedByListContactItem
              key={item?.id}
              handleClick={handleClick}
              user={updatedItem}
              isSelected={item.isSelected}
              setEditing={setEditing}
            />
          );
        }
        return acc;
      }, [])}

      {currentMembers.length === 0 && (
        <p className="w-full text-center text-gray-200">No contacts</p>
      )}
    </div>
  );
};

OwnedByListContacts.propTypes = {
  currentMembers: PropTypes.shape([]),
  handleClick: PropTypes.func,
  setEditing: PropTypes.func,
};

OwnedByListContacts.defaultProps = {
  currentMembers: PropTypes.shape([]),
  handleClick: () => {},
  setEditing: undefined,
};

export default OwnedByListContacts;

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import { useParams } from "react-router-dom";

import useDocuments from "../../../hooks/useDocuments";
import useDocumentsConfiguration from "../../../hooks/useDocumentsConfiguration";

import FileCard from "../FileCard/FileCard";
import DocumentSelect from "../DocumentSelect/DocumentSelect";
import AttachItemModal from "../AttachItemModal/AttachItemModal";

import TertiaryButton from "../Buttons/TertiaryButton";
import TertiaryHeader from "../TextHeaders/TertiaryHeader";
import dogEarDashed from "../../assets/images/dogEarDashed.svg";
import dogEarDashedGreen from "../../assets/images/dogEarDashedGreen.svg";
import uploadArrow from "../../assets/images/uploadArrow.svg";
import {
  REMOVE_DOCUMENT_ATTACHMENT,
  UPDATE_DOCUMENT_ATTACHMENTS,
  fileCardStyle,
} from "../../../constants";
import { toastError } from "../../../helpers/Toast";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../assets/images/whiteExclamationIcon.svg";
import PlusCircleButton from "../Buttons/PlusCircleButton/PlusCircleButton";

const uploadAreaCN = (className) => cntl`
    flex
    flex-col
    items-center
    ${className}
    border-2
    border-dashed
    rounded
    hover:border-brandGreen
    cursor-pointer
`;

const toastCloseIcon = <img src={whiteCrossIcon} alt="Close notice" />;
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const DocumentAttachmentForm = ({
  showFilesModal: showFilesModalInitial,
  setShowFilesModalInitial,
  useSmallIcon,
  document,
  dispatch,
  favorites,
  postFavorite,
  deleteFavorite,
  selectedDocuments,
  setSelectedDocuments,
  showFile,
  existingdDocuments,
  onAddDocuments,
  handleFileClick,
  isDisabled,
  // eslint-disable-next-line react/prop-types
  documentAssociation,
  forCreateModal,
  hideFileCards,
  hideFileMessage,
  buttonTitle,
}) => {
  const {
    documents: allDocuments,
    addDocument,
    addDocuments,
    removeDocument,
  } = useDocuments();
  const { data: docConfig } = useDocumentsConfiguration();
  const { projectId, propertyId } = useParams();

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hovering, setHovering] = useState();
  const [showFilesModal, setShowFilesModal] = useState(false);
  const [selected, setSelected] = useState(selectedDocuments);
  const [prevSelected, setPrevSelected] = useState(selectedDocuments);

  const docTypeOptionsMap = React.useMemo(() => {
    return {
      ...docConfig?.documents?.documentType
        ?.filter((doc) => doc.selected && (doc.value || doc.id))
        ?.reduce((obj, item) => {
          return {
            ...obj,
            [item.value ?? item.id]: {
              label: item.display,
              value: item.value ?? item.id,
            },
          };
        }, {}),
    };
  }, [docConfig]);

  useEffect(() => {
    setSelected(selectedDocuments);
    setPrevSelected(selectedDocuments);
  }, [selectedDocuments]);

  useEffect(() => {
    if (allDocuments?.length) {
      if (forCreateModal) {
        setDocuments(
          allDocuments.filter(
            (doc) =>
              // eslint-disable-next-line react/prop-types
              doc.project?.includes(documentAssociation?.split("/")[1]) ||
              // eslint-disable-next-line react/prop-types
              doc.property?.includes(documentAssociation?.split("/")[1])
          )
        );
        setLoading(false);
      } else if (projectId || propertyId) {
        setDocuments(
          allDocuments.filter(
            (doc) =>
              doc.project?.includes(projectId) ||
              doc.property?.includes(propertyId)
          )
        );
      } else if (document?.project || document?.property) {
        setDocuments(
          allDocuments.filter(
            (doc) =>
              doc.project?.includes(document.project) ||
              doc.property?.includes(document.property)
          )
        );
      } else {
        setDocuments(allDocuments);
      }
      setLoading(false);
    } else if (!allDocuments) {
      setDocuments([]);
      setLoading(false);
    }
  }, [
    document,
    allDocuments,
    projectId,
    propertyId,
    documentAssociation,
    forCreateModal,
  ]);

  const handleUpdateDocuments = (val) => {
    if (Array.isArray(val)) {
      setDocuments((prev) => {
        return [...val, ...prev];
      });
    } else {
      setDocuments((prev) => {
        return [val, ...prev];
      });
    }
  };

  const attachmentButtonClickHandler = () => {
    setShowFilesModal(true);
    setPrevSelected(selected);
  };

  const addDocumentHandler = () => {
    const addedDocuments = [
      ...documents.filter((doc) => selected.includes(doc.id)),
      ...existingdDocuments,
    ];
    dispatch({
      type: UPDATE_DOCUMENT_ATTACHMENTS,
      value: addedDocuments,
    });
    if (onAddDocuments) onAddDocuments(addedDocuments);

    setShowFilesModal(false);
    if (setShowFilesModalInitial) setShowFilesModalInitial(false);
  };

  const removeDocumentHandler = (id) => {
    dispatch({
      type: REMOVE_DOCUMENT_ATTACHMENT,
      value: { id },
    });
  };
  const closeModalHandler = () => {
    setShowFilesModal(false);
    if (setShowFilesModalInitial) setShowFilesModalInitial(false);
    setSelected(prevSelected);
  };

  const handleFileSelect = React.useCallback(
    (documentId) => {
      if (documentId?.length) {
        let newList;
        setSelected((prev) => {
          if (prev.includes(documentId)) {
            newList = [
              ...prev.filter((prevId) => !documentId.includes(prevId)),
            ];
          } else {
            newList = [...prev, documentId];
          }
          return newList;
        });
        setSelectedDocuments(newList);
      }
    },
    [setSelectedDocuments]
  );
  const handleRowSelect = React.useCallback(
    (documentIds) => {
      if (documentIds?.length) {
        let newList;
        setSelected((prev) => {
          const tempList = prev;
          documentIds.forEach((doc) => {
            const idx = tempList.indexOf(doc);
            if (idx > -1) {
              tempList.splice(idx, 1);
            } else {
              tempList.push(doc);
            }
          });
          newList = tempList;
          return newList;
        });
        setSelectedDocuments(newList);
      }
    },
    [setSelectedDocuments]
  );

  const handleFavoriteClick = (id) => {
    const foundItem = favorites.find((favorite) => {
      return favorite.item.reference.includes(id);
    });
    if (foundItem) {
      deleteFavorite([foundItem]);
    } else {
      postFavorite(id, "Document");
    }
  };

  const getDocType = useCallback(
    (documentType) => {
      if (documentType) {
        return docTypeOptionsMap[documentType]?.label ?? documentType;
      }
      return "File";
    },
    [docTypeOptionsMap]
  );

  const handleWorkflowErrorClick = () => {
    toastError(
      "Please select an association before adding attachments",
      toastErrorIcon,
      toastCloseIcon
    );
  };

  return (
    <>
      {!showFile && document?.attachments?.length > 0 && !hideFileMessage && (
        <TertiaryHeader className="my-5">
          Related Documents Have Been Attached
        </TertiaryHeader>
      )}

      {showFile && (
        <div className="flex flex-wrap mt-1">
          {useSmallIcon && (
            <PlusCircleButton
              className="pb-4"
              title="Add Document"
              onClick={() =>
                isDisabled
                  ? handleWorkflowErrorClick()
                  : attachmentButtonClickHandler()
              }
            />
          )}
          {!useSmallIcon && !setShowFilesModalInitial && (
            <div
              className={uploadAreaCN()}
              role="button"
              tabIndex={0}
              onClick={attachmentButtonClickHandler}
              onKeyPress={() => {}}
              onMouseOver={() => setHovering(true)}
              onMouseOut={() => setHovering(false)}
              onFocus={() => {}}
              onBlur={() => {}}
              style={{
                ...fileCardStyle,
                height: "240px",
              }}
            >
              <img
                className="self-end w-20 h-8 select-none"
                style={{ margin: "-3px -25px 0 0" }}
                src={hovering ? dogEarDashedGreen : dogEarDashed}
                alt=""
              />
              <div className="upload_area_click w-full">
                <div className="flex flex-col items-center">
                  <p className="text-base text-gray-300 font-semibold">
                    + New Document
                  </p>
                  <img
                    className="h-10 mt-8 mb-6"
                    src={uploadArrow}
                    alt="upload arrow"
                  />
                  <div className="flex flex-col items-center mt-6 text-sm">
                    <p>Add New Document</p>
                    <p>
                      or <span className="text-brandGreen">Browse</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!hideFileCards &&
            document?.attachments?.map((doc) => {
              return (
                <div key={doc.id} className="">
                  <FileCard
                    {...doc}
                    isFavorited={
                      doc.isFavorited
                        ? doc.isFavorited
                        : favorites.some((fav) =>
                            fav.item.reference.includes(doc.id)
                          )
                    }
                    style={fileCardStyle}
                    onFileClick={() => {
                      handleFileClick(doc);
                    }}
                    onFavoriteClick={handleFavoriteClick}
                    onDeleteClick={removeDocumentHandler}
                    docType={getDocType(doc.docType)}
                    docTypeHasLabel
                    hideButtons
                  />
                </div>
              );
            })}
        </div>
      )}

      {!showFile && (
        <>
          <div className="flex flex-wrap mt-1">
            {!hideFileCards &&
              document?.attachments?.map((doc) => (
                <div key={doc.id} className="">
                  <FileCard
                    {...doc}
                    isFavorited={
                      doc.isFavorited
                        ? doc.isFavorited
                        : favorites.some((fav) =>
                            fav.item.reference.includes(doc.id)
                          )
                    }
                    style={fileCardStyle}
                    onFileClick={handleFileClick}
                    onFavoriteClick={handleFavoriteClick}
                    onDeleteClick={removeDocumentHandler}
                    docType={getDocType(doc.docType)}
                    docTypeHasLabel
                    hideButtons
                  />
                </div>
              ))}
          </div>

          <TertiaryButton
            className="mt-1"
            title={buttonTitle ?? "+ Add Documents"}
            onClick={attachmentButtonClickHandler}
          />
        </>
      )}

      <AttachItemModal
        content={React.useMemo(
          () => (
            <DocumentSelect
              documents={documents}
              selectedDocuments={selectedDocuments}
              onUpdateDocuments={handleUpdateDocuments}
              onFileSelect={handleFileSelect}
              onRowSelect={handleRowSelect}
              loading={loading}
              addDocument={addDocument}
              addDocuments={addDocuments}
              removeDocument={removeDocument}
              docTypeOptionsMap={docTypeOptionsMap}
              favorites={favorites}
              postFavorite={postFavorite}
              deleteFavorite={deleteFavorite}
              documentAssociation={documentAssociation}
            />
          ),
          [
            documents,
            selectedDocuments,
            handleFileSelect,
            handleRowSelect,
            loading,
            addDocument,
            addDocuments,
            removeDocument,
            docTypeOptionsMap,
            favorites,
            postFavorite,
            deleteFavorite,
            documentAssociation,
          ]
        )}
        isOpen={showFilesModal || showFilesModalInitial}
        onRequestModalClose={closeModalHandler}
        onPrimaryClick={addDocumentHandler}
      />
    </>
  );
};

DocumentAttachmentForm.propTypes = {
  /**
   * document form state
   */
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.object,
  /**
   * document form dispatcher
   */
  dispatch: PropTypes.func,
  /**
   * list of favorited documents
   */
  // eslint-disable-next-line react/forbid-prop-types
  favorites: PropTypes.array,
  /**
   * selected documents array
   */
  // eslint-disable-next-line react/forbid-prop-types
  selectedDocuments: PropTypes.array,
  /**
   * boolean to use a smaller icon instead of big attachemnt icon
   */
  useSmallIcon: PropTypes.bool,
  /**
   * boolean allowing for disabling
   */
  isDisabled: PropTypes.bool,
  setSelectedDocuments: PropTypes.func,
  showFile: PropTypes.bool,
  existingdDocuments: PropTypes.arrayOf({}),
  onAddDocuments: PropTypes.func,
  handleFileClick: PropTypes.func,
  deleteFavorite: PropTypes.func,
  postFavorite: PropTypes.func,
  // associationId: PropTypes.string,
  forCreateModal: PropTypes.bool,
  hideFileCards: PropTypes.bool,
  hideFileMessage: PropTypes.bool,
  buttonTitle: PropTypes.string,
  showFilesModalInitial: PropTypes.func,
  showFilesModal: PropTypes.bool,
  setShowFilesModalInitial: PropTypes.func,
  documentAssociation: PropTypes.string,
};

DocumentAttachmentForm.defaultProps = {
  hideFileCards: false,
  hideFileMessage: false,
  buttonTitle: undefined,
  document: {},
  dispatch: undefined,
  favorites: [],
  selectedDocuments: [],
  setSelectedDocuments: () => {},
  showFile: false,
  existingdDocuments: [],
  onAddDocuments: undefined,
  handleFileClick: undefined,
  deleteFavorite: undefined,
  postFavorite: undefined,
  isDisabled: true,
  useSmallIcon: false,
  // associationId: undefined,
  forCreateModal: false,
  showFilesModalInitial: undefined,
  showFilesModal: false,
  setShowFilesModalInitial: undefined,
  documentAssociation: "",
};

export default DocumentAttachmentForm;

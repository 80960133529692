import React, { useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { TimePeriodDivision } from "../../../../helpers/Dates";
import MessageDivisionElement from "../MessageDivisionElement/MessageDivisionElement";
import InlineInput from "../../../../stories/Components/Input/InlineInput";
import ThreadMessage from "../ThreadMessage";
import DirectMessageHeader from "./DirectMessageHeader";
import MessageChannelHeader from "../MessageChannel/MessageChannelHeader";
import useMessageThreadData from "../useMessageThreadData";
import MessagingSearchHeader from "../SearchChat/MessagingSearchHeader";
import MessageAttachments from "./MessageAttachments";
import { useAppState } from "../../../../state/appState";
import AddMessageAttachment from "../AddMessageAttachment";
import {
  ERROR_DISCONNECTED,
  ERROR_SENDING_MESSAGE,
  PRIMARY_DARK_GREEN,
} from "../../../../constants";
import Chevron from "../../../../stories/Components/Chevron/Chevron";
import SendMessageButton from "../SendMessageButton";
import MediaViewContent from "../../../../stories/Components/MediaViewModal/MediaViewContent";

const DirectMessageThread = ({ isTrayOpen, expanded }) => {
  const contentRef = useRef();

  const {
    target,
    members,
    selectedChannel,
    typing,
    sendMessage,
    input,
    onChangeMessage,
    newMsg,
    onDrop,
    files,
    handleRemoveDrop,
    loadingFiles,
    messages,
    clientChat,
    error,
    resend,
    showReply,
    setShowReply,
    createDirectMessage,
    viewMedia,
    closeMedia,
    inputRef,
  } = useMessageThreadData(contentRef, isTrayOpen);

  const [{ usersStreamDict }] = useAppState();

  const MessagaeDivision = new TimePeriodDivision(MessageDivisionElement);

  return (
    <>
      {!clientChat?.user || error?.err === ERROR_DISCONNECTED ? (
        <div className="relative flex flex-col h-full bg-white flex-1 justify-center items-center px-4">
          <span className="border-2 border-errorRed bg-pink p-4 self-center rounded-lg">
            Connection failure, reconnecting now...
          </span>
        </div>
      ) : (
        <div className="flex flex-col h-full bg-white relative w-full">
          <div
            id="chat-media-view-wrapper"
            className={`w-inherit z-100 overflow-hidden ${
              viewMedia ? "absolute top-0 left-0 bg-white h-full" : "max-h-0"
            }`}
          >
            <MediaViewContent files={viewMedia || []} close={closeMedia} />
          </div>
          <>
            {!newMsg && (
              <>
                {selectedChannel?.type === "team" || members?.length > 2 ? (
                  <MessageChannelHeader
                    members={members}
                    name={selectedChannel?.data?.name}
                  />
                ) : (
                  <DirectMessageHeader {...target} expanded={expanded} />
                )}
              </>
            )}
            {newMsg && <MessagingSearchHeader />}

            <div className="flex flex-col flex-1 justify-between gap-3 overflow-hidden">
              <div
                id="channel-contents"
                className="w-full flex overflow-y-scroll flex-col"
                ref={contentRef}
              >
                {selectedChannel && !newMsg && (
                  <>
                    {messages?.reduce((list, item, index) => {
                      MessagaeDivision.division(
                        list,
                        moment(item.created_at).format()
                      );
                      list.push(
                        <ThreadMessage
                          {...item}
                          key={item.id}
                          mostRecent={index === messages?.length - 1}
                          showReply={index === showReply}
                          setShowReply={() => setShowReply(index)}
                        />
                      );
                      return list;
                    }, [])}
                    {error?.err === ERROR_SENDING_MESSAGE && (
                      <ThreadMessage {...error?.data} resend={resend} />
                    )}
                  </>
                )}
                {typing?.channel === selectedChannel?.id && selectedChannel && (
                  <p className="text-xxs text-gray-300 font-medium ml-6 py-2 animate-bounce">
                    {`${
                      usersStreamDict?.[typing?.user]?.name ?? ""
                    } is typing...`}
                  </p>
                )}
              </div>

              {showReply !== null && (
                <button
                  className="flex flex-col self-center animate-bounce text-xs text-primaryGreen font-semibold"
                  type="button"
                  onClick={() => setShowReply(null)}
                >
                  <Chevron
                    color={PRIMARY_DARK_GREEN}
                    style={{ transform: "rotate(-90deg)" }}
                    strokeWidth="2px"
                    className="w-3 h-3 self-center"
                  />
                  <p>Show</p>
                </button>
              )}
              <div
                className={`flex flex-col gap-3 w-full px-4 transition-all ${
                  showReply === null ? "max-h-max pb-4" : "max-h-0"
                }`}
              >
                {files.length > 0 && (
                  <MessageAttachments
                    files={files}
                    handleRemoveDrop={handleRemoveDrop}
                  />
                )}
                <InlineInput
                  forwardRef={inputRef}
                  inputStyle={{ paddingTop: "0.5rem", margin: "0" }}
                  editing
                  textarea
                  value={input ?? null}
                  onChangeCallback={onChangeMessage}
                  width="w-full"
                  size="md"
                  rows={4}
                  onDrop={onDrop}
                  placeholder="Type Message"
                  hidePencil
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      if (newMsg) {
                        createDirectMessage();
                      } else {
                        sendMessage();
                      }
                    }
                  }}
                />
                <AddMessageAttachment
                  onAddFiles={onDrop}
                  loading={loadingFiles}
                />

                <SendMessageButton
                  send={() => {
                    if (newMsg) {
                      createDirectMessage();
                    } else {
                      sendMessage();
                    }
                  }}
                  disabled={loadingFiles}
                />
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
};

DirectMessageThread.propTypes = {
  expanded: PropTypes.bool,
  isTrayOpen: PropTypes.bool,
};

DirectMessageThread.defaultProps = {
  expanded: false,
  isTrayOpen: false,
};

export default DirectMessageThread;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import cntl from "cntl";
import * as yup from "yup";

import SelectUserInterface from "../SelectUserInterface/SelectUserInterface";

import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";
import Dropdown from "../Dropdown/Dropdown";
import DatePicker from "../DatePicker/DatePicker";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import PrimaryButton from "../Buttons/PrimaryButton";

import deleteIcon from "../../assets/images/collapseIcon.svg";
import eventIcon from "../../assets/images/eventIcon.png";
import {
  RESET,
  SIMPLE_INPUT,
  NESTED_INPUT,
  LIST_ADD_ONE,
  LIST_REMOVE_ONE,
  ADD_QUALIFICATION,
  REMOVE_QUALIFICATION,
  TimeOptions,
} from "../../../constants";
import { formatSelectUser } from "../../../helpers/Formatters";

const deleteButtonCN = cntl`
  flex
  justify-center
  items-center
  ml-8
`;
const labelCNAlt = cntl`
  text-xs
  font-semibold
  text-gray-300
`;
const eventIconElement = (
  <img className="w-4 h-4" src={eventIcon} alt="event icon" />
);

const ContingencyAuthorization = ({
  document,
  dispatch,
  configs,
  existingDoc,
  userData,
}) => {
  const [hovering, setHovering] = useState("");
  const [addCriteriaButton, setAddCriteriaButton] = useState(false);
  const [criteriaInput, setCriteriaInput] = useState("");

  const [userOptions, setUserOptions] = useState([]);
  const [durationDays, setDurationDays] = useState();
  const [durationHours, setDurationHours] = useState();

  const docLineItem = React.useMemo(() => {}, []);

  useEffect(() => {
    if (existingDoc) {
      dispatch({
        type: RESET,
        value: existingDoc,
      });
    }
  }, [existingDoc, dispatch]);

  useEffect(() => {
    if (configs && !document.qualificationsAndExclusions?.length) {
      configs.documents?.qualsAndExceptions?.contingencyAuthorization?.forEach(
        (item) => {
          dispatch({
            type: ADD_QUALIFICATION,
            value: { id: uuidv4(), checked: true, text: item },
          });
        }
      );
    }
  }, [configs, dispatch, document.qualificationsAndExclusions?.length]);

  useEffect(() => {
    setAddCriteriaButton(criteriaInput?.trim().length > 0);
  }, [criteriaInput]);

  useEffect(() => {
    if (userData?.length && !userOptions.length) {
      setUserOptions(userData);
    }
  }, [userData, userOptions]);

  useEffect(() => {
    if (docLineItem?.financialCode && !document.financialCode) {
      dispatch({
        type: SIMPLE_INPUT,
        key: "financialCode",
        value: docLineItem.financialCode,
      });
    }
    if (docLineItem?.company && !document.company) {
      dispatch({
        type: SIMPLE_INPUT,
        key: "company",
        value: docLineItem.company,
      });
    }
    if (document.dueDate?.date) {
      const today = moment();
      const dueDate = moment(document.dueDate.date);
      const diffDays = dueDate.diff(today, "days");
      const diffHours = dueDate.diff(today, "hours");

      if (diffDays > 0) {
        setDurationDays(diffDays);
        setDurationHours();
      } else if (diffHours > 0) {
        setDurationDays();
        setDurationHours(diffHours);
      } else {
        setDurationDays();
        setDurationHours();
      }
    }
  }, [document, docLineItem, dispatch]);

  const criteriaInputChangeHandler = (val) => {
    setCriteriaInput(val);
  };

  const criteriaUpdateHandler = (key, value, checked) => {
    dispatch({
      type: LIST_ADD_ONE,
      value: { ...value, checked },
      key,
    });
  };

  const addCriteriaHandler = (key) => {
    dispatch({
      type: LIST_ADD_ONE,
      value: { id: uuidv4(), text: criteriaInput },
      append: true,
      key,
    });
    criteriaInputChangeHandler();
  };

  const criteriaInputKeyPressHandler = (key, event) => {
    if (event.code === "Enter" && addCriteriaButton) {
      addCriteriaHandler(key);
    }
  };

  const deleteCriteriaHandler = (id) => {
    dispatch({
      type: REMOVE_QUALIFICATION,
      value: { id },
    });
    criteriaInputChangeHandler();
  };

  const handleDeleteHover = (mouseOver, id, isDefault) => {
    if (isDefault) return;
    if (mouseOver) {
      setHovering(id);
    } else {
      setHovering("");
    }
  };

  const handleSimpleInput = (key, value) => {
    dispatch({
      type: SIMPLE_INPUT,
      key,
      value,
    });
  };

  const handleNestedInput = (key1, key2, value) => {
    dispatch({
      type: NESTED_INPUT,
      key1,
      key2,
      value,
    });
  };

  const handleTimeInput = (val) => {
    if (!val) return;
    const hour = Number(val.split(":")[0]);
    const minute = Number(val.split(":")[1]);
    const { dueDate } = document ?? {};
    const tmpDate = moment(dueDate?.date).set({ hour, minute }).toISOString();

    dispatch({
      type: SIMPLE_INPUT,
      key: "dueDate",
      value: { ...(dueDate ?? {}), date: tmpDate },
    });
  };

  const handleListAdd = (key, val) => {
    val.forEach(({ value }) => {
      dispatch({
        type: LIST_ADD_ONE,
        key,
        value: { id: value },
      });
    });
  };

  const handleListRemove = (key, value) => {
    dispatch({
      type: LIST_REMOVE_ONE,
      key,
      value,
    });
  };

  const handleCheckGroupClick = (key, value, checked) => {
    dispatch({
      type: SIMPLE_INPUT,
      key,
      value: checked ? value : undefined,
    });
  };

  const handleUpdateUsers = (val) => {
    setUserOptions((prev) => [
      ...prev,
      formatSelectUser({
        ...val,
        role: val.roleName,
      }),
    ]);
  };

  const getUserList = () => {
    const tmp = userOptions.filter((item) =>
      document.distro?.find((usr) => usr.id === item.value)
    );
    return tmp;
  };

  return (
    <>
      <div className="hidden">
        <div
          className="ESInputLabel mt-5"
          style={{ justifyContent: "flex-start" }}
        >
          Add Recipients<span className="text-brandGreen text-sm">*</span>
        </div>
        <SelectUserInterface
          className="w-full"
          userList={getUserList()}
          userPlaceholder="Search Companies or members..."
          userOptions={userOptions}
          buttonText="Add Contact"
          onAddUser={(val) => handleListAdd("distro", val)}
          onRemoveUser={(val) => handleListRemove("distro", val)}
          updateSelect={handleUpdateUsers}
          showCurrentRole
        />
      </div>

      <div className="flex items-center mt-5">
        <DatePicker
          className="flex-1 mr-2"
          label="Due Date "
          placeholder="MM-DD-YYYY"
          value={document.dueDate?.date}
          iconLeft={eventIconElement}
          onChange={(val) => handleNestedInput("dueDate", "date", val)}
          validation={yup.date().required()}
        />
        {!document.dueDate?.allDay && (
          <Dropdown
            className="flex-1 mt-7 mr-2"
            label=""
            options={TimeOptions}
            value={TimeOptions.find((item) => {
              if (!document.dueDate?.date) return false;
              return (
                item.value === moment(document.dueDate.date).format("HH:mm")
              );
            })}
            placeholder="Select"
            onChange={({ value }) => handleTimeInput(value)}
            disableClear
            disableSort
          />
        )}
        <Checkbox
          className="mt-7"
          label="All Day"
          checked={document.dueDate?.allDay}
          onChange={() =>
            handleNestedInput("dueDate", "allDay", !document.dueDate?.allDay)
          }
        />
      </div>

      <div className="flex">
        {durationDays && (
          <div className="ESInputLabel mt-1 mr-2">{`${durationDays} ${
            durationDays > 1 ? "Days" : "Day"
          }`}</div>
        )}
        {durationHours && (
          <div className="ESInputLabel mt-1 mr-2">{`${durationHours} ${
            durationHours > 1 ? "Hours" : "Hour"
          }`}</div>
        )}
      </div>

      <SecondaryHeader className="mt-5">
        Contingency Authorization
      </SecondaryHeader>
      <Input
        placeholder="Title"
        className=""
        label="Title"
        value={document.customName}
        onChange={(val) => handleSimpleInput("customName", val)}
        validation={yup.string().required()}
        disableClear
      />
      <Input
        placeholder="Description"
        className="mt-1"
        label="Description"
        value={document.description}
        onChange={(val) => handleSimpleInput("description", val)}
        disableClear
        isTextarea
      />

      <div className="flex mt-5 mb-2">
        <SecondaryHeader className="">Impact To Schedule</SecondaryHeader>
        <div className="text-brandGreen text-sm">*</div>
      </div>
      <div className="flex flex-col">
        <div className="flex items-center mb-2">
          <Checkbox
            className=""
            label="Yes"
            checked={document.scheduleImpacts === "Yes"}
            onChange={(checked) =>
              handleCheckGroupClick("scheduleImpacts", "Yes", checked)
            }
          />
        </div>
        <div className="flex items-center mb-2">
          <Checkbox
            className=""
            label="No"
            checked={document.scheduleImpacts === "No"}
            onChange={(checked) =>
              handleCheckGroupClick("scheduleImpacts", "No", checked)
            }
          />
        </div>
        <div className="flex items-center mb-2">
          <Checkbox
            className=""
            label="TBD"
            checked={document.scheduleImpacts === "TBD"}
            onChange={(checked) =>
              handleCheckGroupClick("scheduleImpacts", "TBD", checked)
            }
          />
        </div>
      </div>

      <SecondaryHeader className="mt-3 mb-2">
        Qualifications &amp; Exclusions
      </SecondaryHeader>
      {document.qualificationsAndExclusions?.map((item) => (
        <div
          key={item.id}
          className="flex mb-2"
          onMouseOver={() => handleDeleteHover(true, item.id)}
          onMouseOut={() => handleDeleteHover(false, item.id)}
          onFocus={() => handleDeleteHover(true, item.id)}
          onBlur={() => handleDeleteHover(false, item.id)}
        >
          {(item.checked || item.checked === false) && (
            <div className="flex items-center mb-2">
              <Checkbox
                label={item.text}
                checked={item.checked}
                onChange={(checked) =>
                  criteriaUpdateHandler(
                    "qualificationsAndExclusions",
                    item,
                    checked
                  )
                }
              />
            </div>
          )}

          {!(item.checked || item.checked === false) && (
            <div className={`${labelCNAlt} ml-5`}>{item.text}</div>
          )}

          {!(item.checked || item.checked === false) && (
            <button
              type="button"
              className={`${deleteButtonCN} ${
                hovering !== item.id ? "invisible" : ""
              }`}
              style={{ minWidth: "16px", minHeight: "16px" }}
              onClick={() => deleteCriteriaHandler(item.id)}
            >
              <img
                className="w-4 h-4 cursor-pointer"
                src={deleteIcon}
                alt="delete criteria"
              />
            </button>
          )}
        </div>
      ))}

      <div className="flex items-center mt-5">
        <Input
          className="mr-3"
          mainClassName="flex-1"
          placeholder="Qualification or Exclusion"
          value={criteriaInput}
          onChange={criteriaInputChangeHandler}
          onKeyPress={(val) =>
            criteriaInputKeyPressHandler("qualificationsAndExclusions", val)
          }
        />
        <PrimaryButton
          className=""
          title="Add"
          onClick={() => addCriteriaHandler("qualificationsAndExclusions")}
          disabled={!addCriteriaButton}
        />
      </div>
    </>
  );
};

ContingencyAuthorization.propTypes = {
  /**
   * create document form state
   */
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.object,
  /**
   * create document form dispatcher
   */
  dispatch: PropTypes.func,
  /**
   * doc configs
   */
  // eslint-disable-next-line react/forbid-prop-types
  configs: PropTypes.object,
  /**
   * existing document data
   */
  // eslint-disable-next-line react/forbid-prop-types
  existingDoc: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  userData: PropTypes.arrayOf(PropTypes.object),
};

ContingencyAuthorization.defaultProps = {
  document: {},
  dispatch: undefined,
  configs: {},
  existingDoc: null,
  userData: [],
};

export default ContingencyAuthorization;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppState } from "../../../state/appState";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useAssociationsHelper from "../../../hooks/useAssociationsHelper";
import {
  useFormattedComments,
  usePostComment,
  usePostCommentReply,
} from "../../../hooks/useComments";
import {
  extractIdsFromMessage,
  getAdminUsers,
  transformUsersToMentionsStructure,
} from "../../../helpers/Comments";

const usePopoverCommentsData = ({
  resourceReference,
  association,
  allowMentionAll,
  usersToMention = [],
}) => {
  const [{ userDict, users: allESUsers }] = useAppState();
  const { data: currentUser } = useCurrentUser();

  const { getAssociationMembers } = useAssociationsHelper();

  const [isMinimized, setMinimized] = useState(false);
  const [isReplyMode, setReplyMode] = useState(false);
  const [commentIdToReply, setCommentIdToReply] = useState();
  const [replies, setReplies] = useState([]);

  // Query selector with all comments by resource reference
  const { data: comments, isLoading } = useFormattedComments(
    resourceReference,
    userDict,
    true
  );

  const { mutate: postComment } = usePostComment();
  const { mutate: postReply } = usePostCommentReply();

  // Calculates users that can be mentioned based on the association
  // https://estatespace.atlassian.net/wiki/spaces/GC/pages/2820702226/Comment
  const mentionSuggestions = useMemo(() => {
    // Mention all users in the platform
    if (allowMentionAll) {
      return transformUsersToMentionsStructure(allESUsers, currentUser);
    }

    // Admins users always needs to be in this list
    const suggestions = getAdminUsers(allESUsers || []);

    // Edge case: A member from `givenUsers` could also be an existing admin in the `suggestion` list so to avoid duplicated members
    const avoidDuplicateUsers = (givenUsers) => {
      const usersIdsSet = new Set();

      suggestions.forEach((user) => {
        usersIdsSet.add(user.id);
      });

      givenUsers.forEach((user) => {
        if (!usersIdsSet.has(user.id)) {
          suggestions.push(user);
          usersIdsSet.add(user.id);
        }
      });
    };

    // If `usersToMention` is provided we need to use them as suggestions along with admin members
    if (usersToMention.length) {
      avoidDuplicateUsers(usersToMention);
    }

    // Otherwise we use association members along admin members
    else {
      const associationMembers = getAssociationMembers(association);
      avoidDuplicateUsers(associationMembers);
    }

    return transformUsersToMentionsStructure(suggestions, currentUser);
  }, [
    allowMentionAll,
    usersToMention,
    allESUsers,
    currentUser,
    association,
    getAssociationMembers,
  ]);

  useEffect(() => {
    if (!commentIdToReply) return;

    // Show replies messages and push parent message at the begining
    setReplies(() => {
      const parentComment = comments?.find((c) => c.id === commentIdToReply);
      if (!parentComment) return [];

      return [parentComment, ...(parentComment?.replies ?? [])];
    });
  }, [comments, commentIdToReply]);

  /**
   * Enable reply mode and set the commentId in state to reply
   * @param {string} commentId comment id to reply
   */
  const handleReply = (commentId) => {
    setReplyMode(!(commentId === commentIdToReply));
    if (commentId === commentIdToReply) {
      setCommentIdToReply(undefined);
    } else {
      setCommentIdToReply(commentId);
    }
  };

  /**
   * Post a comment
   */
  const handlePostComment = useCallback(
    (message) => {
      if (message.trim() === "") return;

      // get users id from message if any has been mentioned
      const notifyUsers = extractIdsFromMessage(message);

      postComment({
        association: resourceReference,
        content: message,
        notifyUsers,
      });
    },
    [resourceReference, postComment]
  );

  /**
   * Post reply for a comment
   */
  const handlePostReply = useCallback(
    (message) => {
      if (message.trim() === "") return;

      // get users id from message if any has been mentioned
      const notifyUsers = extractIdsFromMessage(message);

      postReply({
        commentId: commentIdToReply,
        payload: { content: message, notifyUsers },
      });
    },
    [commentIdToReply, postReply]
  );

  /**
   * Close reply mode
   */
  const handleCloseReplyMode = useCallback(() => {
    setReplyMode(false);
    setCommentIdToReply(undefined);
  }, []);

  return {
    isLoading,
    isReplyMode,
    isMinimized,
    comments,
    replies,
    commentIdToReply,
    mentionSuggestions,
    handlePostComment,
    handlePostReply,
    handleCloseReplyMode,
    handleReply,
    setMinimized,
  };
};

export default usePopoverCommentsData;

import moment from "moment";
import taskKeys from "../../hooks/tasks/taskKeys";
import getTaskListReqParams from "../Task/getTaskListReqParams";

const removeFromTaskListStore = ({ queryClient, variables }) => {
  queryClient.setQueryData(
    taskKeys.taskList(getTaskListReqParams()),
    (oldData) => {
      // olddata will be undefined if that cache hasn't been created yet
      // by initiating the empty array we avoid problems later

      if (variables.operation === "$non-recurring") {
        const newArray =
          oldData?.filter(
            (item) => item?.resource?.id !== variables?.args?.id
          ) || [];
        return newArray;
      }

      if (variables.operation === "$single") {
        const newArray =
          oldData?.filter(
            (item) => item?.resource?.id !== variables?.args?.item?.id
          ) || [];
        return newArray;
      }

      if (variables.operation === "$all") {
        const newArray =
          oldData?.filter(
            (item) => item?.resource?.id !== variables?.args?.id
          ) || [];
        return newArray;
      }

      if (variables.operation === "$future") {
        const itemId = variables.args.item.id;
        const itemDate = variables.args.item.startDate;

        const newEntryArray = oldData?.filter((item) => {
          return !(
            moment(item.resource.startDate).isSameOrAfter(moment(itemDate)) &&
            itemId === item.resource.id
          );
        });

        if (!newEntryArray) return [];

        return newEntryArray;
      }

      return [];
    }
  );
};

export default removeFromTaskListStore;

import PropTypes from "prop-types";
import React, { useRef } from "react";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";

/**
 * Renders the Submittal step comments.
 * @param comments
 */
const PopupMessageBox = ({
  messages,
  messageClassName,
  setShowViewAllComments,
  showViewAllComments,
  wrapperClassName,
  mainTitleClassName,
  mainTitle,
}) => {
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, () => setShowViewAllComments(false));

  return (
    <div
      ref={wrapperRef}
      className={`absolute z-10 top-12 left-5 bg-white overflow-y-scroll flex flex-col border rounded-md ${wrapperClassName} ${
        showViewAllComments ? "" : "hidden"
      }`}
    >
      {mainTitle && <h3 className={mainTitleClassName}>{mainTitle}</h3>}
      <div className="h-auto max-h-96 flex flex-col top-8 space-y-2">
        {messages?.map((msg, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`${msg}-${index}`}
            className={` ${
              index !== messages?.length - 1 && "border-b pb-1"
            } ${messageClassName}`}
          >
            {msg}
          </div>
        ))}
      </div>
    </div>
  );
};

PopupMessageBox.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({})),
  setShowViewAllComments: PropTypes.func,
  showViewAllComments: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  mainTitleClassName: PropTypes.string,
  messageClassName: PropTypes.string,
  mainTitle: PropTypes.string,
};

PopupMessageBox.defaultProps = {
  messages: [],
  setShowViewAllComments: undefined,
  showViewAllComments: false,
  wrapperClassName: "",
  mainTitleClassName: "",
  messageClassName: "",
  mainTitle: "",
};

export default PopupMessageBox;

import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useState } from "react";

import HelpIcon from "../../assets/images/Help.svg";

const modalCN = cntl`
  static
  flex
  flex-col
  py-3
  px-5
  bg-white
  border-2
  border-gray-200
  rounded
  z-50
`;

const InfoTooltip = ({
  title,
  infoData,
  onHover,
  customWidth,
  toolTipStyle,
  toolTipClassName,
}) => {
  const [hovering, setHovering] = useState(false);

  const handleHover = (mouseOver) => {
    if (mouseOver) {
      setHovering(true);
      onHover(true);
    } else {
      setHovering(false);
      onHover(false);
    }
  };

  return (
    <div
      className="flex flex-col cursor-default"
      onMouseOver={() => handleHover(true)}
      onMouseOut={() => handleHover()}
      onFocus={() => handleHover(true)}
      onBlur={() => handleHover()}
    >
      <img className="h-6" src={HelpIcon} alt="" />
      <div
        className={`absolute z-100 pt-8 ${toolTipClassName ?? "-ml-9"} ${
          hovering ? "" : "invisible"
        }`}
      >
        <div
          className={modalCN}
          style={
            toolTipStyle || {
              width: customWidth || "500px",
            }
          }
        >
          {title && <div className="mb-1 text-base font-semibold">{title}</div>}
          {infoData?.map((item, idx) => {
            return (
              <div
                key={`${item.label}-${item?.content?.substr(0, 4)}`}
                className={`py-2 text-sm ${idx !== 0 ? "border-t" : ""}`}
              >
                <div className="font-semibold">{item.label}</div>
                <div className="pl-2">{item.content}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

InfoTooltip.propTypes = {
  title: PropTypes.string,
  infoData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      content: PropTypes.string,
    })
  ),
  onHover: PropTypes.func,
  customWidth: PropTypes.string,
  toolTipStyle: PropTypes.shape({}),
  toolTipClassName: PropTypes.string,
};

InfoTooltip.defaultProps = {
  title: undefined,
  infoData: undefined,
  onHover: () => {},
  customWidth: undefined,
  toolTipStyle: undefined,
  toolTipClassName: undefined,
};

export default InfoTooltip;

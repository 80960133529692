import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import backArrowBlack from "../../assets/images/backArrowBlack.svg";

const homeContainer = (active) => cntl`
  flex
  flex-row
  items-center
  w-full
  text-white
  p-1
  px-3
  pt-3
  h-16
  ${active ? "underline" : ""}
`;

const CustomHomeView = ({ showCloseButton, onCloseClick, active }) => {
  return (
    <div className={homeContainer(active)}>
      {showCloseButton && (
        <button onClick={onCloseClick} type="button" className="w-full pr-2">
          <img className="w-6 h-6" src={backArrowBlack} alt="close Icon" />
        </button>
      )}
    </div>
  );
};

CustomHomeView.propTypes = {
  showCloseButton: PropTypes.bool,
  active: PropTypes.bool,
  onCloseClick: PropTypes.func,
};

CustomHomeView.defaultProps = {
  showCloseButton: false,
  onCloseClick: undefined,
  active: undefined,
};

export default CustomHomeView;

import React from "react";
import PropTypes from "prop-types";
import ChannelButton from "./ChannelButton";
import UserButton from "./UserButton";
import { useESChatContext } from "../../../../context/ChatContext/ESChatContext";

const ChatSearchList = ({ list, onClick }) => {
  const { createChannelMessage } = useESChatContext();

  return (
    <>
      {list && (
        <div
          className="overflow-y-scroll shadow-1 rounded-lg absolute bg-white flex flex-col"
          style={{
            width: "283px",
            maxHeight: "380px",
            top: "100%",
            zIndex: "99",
            border: "1px #CACACA",
          }}
        >
          {list.users?.map((item) => (
            <UserButton key={item.streamId} user={item} onClick={onClick} />
          ))}
          {list.channels?.map((item) => (
            <ChannelButton
              channel={item}
              key={item.cid}
              onClick={() => createChannelMessage(item)}
            />
          ))}
        </div>
      )}
    </>
  );
};

ChatSearchList.propTypes = {
  list: PropTypes.shape({
    users: PropTypes.arrayOf(PropTypes.shape({})),
    channels: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onClick: PropTypes.func,
};

ChatSearchList.defaultProps = {
  list: [],
  onClick: undefined,
};

export default ChatSearchList;

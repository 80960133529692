import { useQueries } from "react-query";
import { AUTH_TOKEN_STORAGE_KEY } from "../constants";

/**
 * useQueryHook wrapper that will make calls to api
 * if there is a authToken in the global state.
 */
function useAuthenticatedQueries(listOfQueries = []) {
  // ES Auth Token
  const authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);

  return useQueries(
    listOfQueries?.map((query) => ({
      ...query,
      enabled: (query?.enabled ?? true) && !!authToken,
    }))
  );
}

export default useAuthenticatedQueries;

import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { truncate } from "mml-react";
import { CONTACT_KIND_MAP, GET_CONTACT_PATH } from "../../../constants";
import { getFullName } from "../../../helpers/Formatters";
import "../ResourceCard/ResourceCard.css";
import useUserDict from "../../../hooks/useUserDict";
import ContactCard from "../ContactCard";

const CardsContactsView = ({ currentUser, sortedContacts }) => {
  const history = useHistory();
  const onContactClick = (id) => {
    history.push(GET_CONTACT_PATH(id, "0"));
  };

  const { data: userDict } = useUserDict();

  const getCompanyName = (companyRef) => {
    const companyName = userDict[companyRef]?.company?.value ?? "";
    return truncate(companyName, 17, "...");
  };

  return (
    <div>
      {Object.keys(sortedContacts)?.map((contact) => (
        <div
          className="my-4"
          key={`container-${contact}`}
          id={`content-${contact}`}
        >
          <p className="my-2 font-bold">{contact}</p>
          <div className="flex flex-wrap">
            {sortedContacts &&
              sortedContacts[contact]?.map((item) => (
                <div key={`card-${item?.id}`} className="mr-6 mt-6">
                  <ContactCard
                    currentUser={currentUser}
                    id={item.id}
                    title={
                      item?.kind === "company"
                        ? item?.company?.value
                        : getFullName(item?.name)
                    }
                    detail={
                      item?.kind !== "company"
                        ? getCompanyName(item?.company?.value)
                        : ""
                    }
                    tag={CONTACT_KIND_MAP[item?.kind]}
                    imageReference={item?.avatar}
                    resourceName={
                      item?.kind === "company" ? "Company" : "Contact"
                    }
                    onClick={() => onContactClick(item.id)}
                    isInactive={!item?.active}
                  />
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

CardsContactsView.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  sortedContacts: PropTypes.object,
};

CardsContactsView.defaultProps = {
  currentUser: undefined,
  sortedContacts: undefined,
};

export default CardsContactsView;

export const mentionInputStyle = {
  control: {
    resize: "none",
  },

  "&multiLine": {
    control: {},
    highlighter: {
      padding: 9,
      boxSizing: "border-box",
      overflow: "hidden",
      height: "7rem",
    },
    input: {
      padding: 9,
      overflow: "auto",
      height: "7rem",
    },
  },
  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0, 0, 0, 0.15)",
      maxHeight: "130px",
      width: "250px",
      overflowY: "auto",
      fontSize: "14px",
      borderRadius: "6px",
    },
    item: {
      padding: "5px 15px",
      "&focused": {
        backgroundColor: "#F9F9F9",
      },
    },
  },
};

export const mentionsStyle = {
  backgroundColor: "#D7D7D7",
  borderRadius: "4px",
};

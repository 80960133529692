import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

import SovCell from "./SovCell";

import collapseIcon from "../../assets/images/collapseIcon.svg";
import { formatNumber } from "../../../helpers/Formatters";

const rowCN = cntl`
  flex
  items-center
  h-16
  py-2
  text-xs
  text-gray-300
  border-b
  border-b-gray-100
`;
const deleteButtonCN = cntl`
  flex
  items-center
  overflow-visible
  `;

const SovRow = ({
  data,
  units,
  groupedUnits,
  dispatch,
  onRemove,
  disableEditing,
  isVendorInvoice,
}) => {
  const [hovering, setHovering] = useState();
  const [dataId, setDataId] = useState();
  const [POName, setPOName] = useState();
  const [description, setDescription] = useState();
  const [quantity, setQuantity] = useState();
  const [unitOfMeasure, setUnitOfMeasure] = useState();
  const [costPerUnit, setCostPerUnit] = useState();
  const [total, setTotal] = useState();
  const [workCompletedToDate, setWorkCompletedToDate] = useState();
  const [percentCompleteOfTotalValues, setPercentCompleteOfTotalValues] =
    useState();
  const [
    workCompletedThisPeriodPercentValue,
    setWorkCompletedThisPeriodPercentValue,
  ] = useState();
  const [
    workCompletedThisPeriodDollarValue,
    setWorkCompletedThisPeriodDollarValue,
  ] = useState();

  useEffect(() => {
    setDataId(data.id);
    setDescription(data.description);
    setQuantity(data.quantity);
    setUnitOfMeasure(data.unitOfMeasure);
    setCostPerUnit(data.costPerUnit);
    setTotal((data.quantity || 0) * (data.costPerUnit || 0));
    // setTotal((data.paTotal || 0) + (data.caTotal || 0) + (data.coTotal || 0));
    setWorkCompletedToDate(data.workCompletedToDate);
    setPercentCompleteOfTotalValues(data.percentCompleteOfTotalValues);
    setWorkCompletedThisPeriodDollarValue(
      data.workCompletedThisPeriodDollarValue
    );
    setWorkCompletedThisPeriodPercentValue(
      data.workCompletedThisPeriodPercentValue
    );
    setPOName(data.poName);
  }, [data]);

  const handleInput = ({ id, type, value }) => {
    if (type === "workCompletedThisPeriodDollarValue") {
      dispatch({
        id,
        type: "workCompletedThisPeriodPercentValue",
        value: (formatNumber(value) / formatNumber(total)) * 100,
      });

      const wcd = formatNumber(value);
      dispatch({
        id,
        type: "workCompletedToDate",
        value: wcd,
      });

      dispatch({
        id,
        type: "percentCompleteOfTotalValues",
        value: (wcd / formatNumber(total)) * 100,
      });

      dispatch({
        id,
        type: "maxValueError",
        value:
          formatNumber(value) > formatNumber(total) || formatNumber(value) < 0,
      });
    } else if (type === "workCompletedThisPeriodPercentValue") {
      const dollarAmt = (formatNumber(value) / 100) * formatNumber(total);
      dispatch({
        id,
        type: "workCompletedThisPeriodDollarValue",
        value: dollarAmt,
      });
      const wcd = formatNumber(dollarAmt);
      dispatch({
        id,
        type: "workCompletedToDate",
        value: wcd,
      });

      dispatch({
        id,
        type: "percentCompleteOfTotalValues",
        value: (wcd / formatNumber(total)) * 100,
      });

      dispatch({
        id,
        type: "maxValueError",
        value: formatNumber(value) > 100 || formatNumber(value) < 0,
      });
    }
    dispatch({
      id,
      type,
      value,
    });
  };

  return (
    <div
      className={rowCN}
      onMouseOver={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
      onFocus={() => setHovering(true)}
      onBlur={() => setHovering(false)}
    >
      {isVendorInvoice && (
        <div>
          <SovCell
            className="w-64 mr-4"
            id={dataId}
            field="PO"
            value={POName}
            dispatch={handleInput}
            disableEditing
          />
        </div>
      )}
      <div>
        <SovCell
          className="w-64 mr-4"
          id={dataId}
          field="description"
          value={description}
          dispatch={handleInput}
          disableEditing={disableEditing}
        />
      </div>

      <div>
        <SovCell
          className="w-24 mr-4"
          id={dataId}
          field="quantity"
          value={quantity}
          dispatch={handleInput}
          disableEditing={disableEditing}
        />
      </div>

      <div>
        <SovCell
          className="w-40 mr-4"
          id={dataId}
          field="unitOfMeasure"
          value={unitOfMeasure}
          units={units}
          groupedUnits={groupedUnits}
          dispatch={handleInput}
          disableEditing={disableEditing}
        />
      </div>

      <div>
        <SovCell
          className="w-24 mr-4"
          id={dataId}
          field="costPerUnit"
          value={costPerUnit}
          dispatch={handleInput}
          disableEditing={disableEditing}
        />
      </div>

      <div>
        <SovCell
          className="w-24 mr-4"
          id={dataId}
          field="total"
          value={total}
          dispatch={handleInput}
          disableEditing={disableEditing}
        />
      </div>
      {isVendorInvoice && (
        <>
          <div>
            <SovCell
              className="w-32 mr-4"
              id={dataId}
              field="workCompletedThisPeriodDollarValue"
              value={workCompletedThisPeriodDollarValue}
              dispatch={handleInput}
              maxValue={total}
            />
          </div>
          <div>
            <SovCell
              className="w-32 mr-4"
              id={dataId}
              field="workCompletedThisPeriodPercentValue"
              value={workCompletedThisPeriodPercentValue}
              dispatch={handleInput}
              maxValue="100"
            />
          </div>
          <div>
            <SovCell
              className="w-32 mr-4"
              id={dataId}
              field="workCompletedToDate"
              value={workCompletedToDate}
              dispatch={handleInput}
              disableEditing={disableEditing}
            />
          </div>
          <div>
            <SovCell
              className="w-32 mr-4"
              id={dataId}
              field="percentCompleteOfTotalValues"
              value={percentCompleteOfTotalValues}
              dispatch={handleInput}
              disableEditing={disableEditing}
            />
          </div>
        </>
      )}

      {!disableEditing && (
        <div
          className="relative -left-2"
          style={{ minWidth: "16px", maxWidth: "16px" }}
        >
          <button
            type="button"
            className={deleteButtonCN}
            onClick={() => onRemove(dataId)}
          >
            <img
              className={`w-4 h-4 ${hovering ? "" : "invisible"}`}
              src={collapseIcon}
              alt="delete icon"
            />
          </button>
        </div>
      )}
    </div>
  );
};

SovRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  units: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  groupedUnits: PropTypes.array,
  dispatch: PropTypes.func,
  onRemove: PropTypes.func,
  disableEditing: PropTypes.bool,
  isVendorInvoice: PropTypes.bool,
};

SovRow.defaultProps = {
  data: {},
  units: [],
  groupedUnits: undefined,
  dispatch: () => {},
  onRemove: () => {},
  disableEditing: false,
  isVendorInvoice: false,
};

export default SovRow;

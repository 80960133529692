import { TaskAPI } from "@griffingroupglobal/eslib-api";

const deleteTaskHandler = async (task) => {
  const deleteParams = {
    params: `instanceStartDate=${task.startDate}`,
  };

  const response = await TaskAPI.delete(task.id, deleteParams);

  return response;
};

export default deleteTaskHandler;

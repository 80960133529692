import { useCallback } from "react";
import { useAppState } from "../../../state/appState";

const useInputMentionsData = ({ trigger }) => {
  const [{ userDict }] = useAppState();

  const setUserNameWithTrigger = useCallback(
    (id) => {
      const { name } = userDict?.[id];
      return `${trigger}${name?.firstName} ${name?.lastName}`;
    },
    [trigger, userDict]
  );

  const getUserAvatar = (userId) => userDict?.[userId]?.avatar;

  return {
    setUserNameWithTrigger,
    getUserAvatar,
  };
};

export default useInputMentionsData;

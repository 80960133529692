import { useMemo } from "react";
import sortContactsAndMembers from "../../../helpers/Utilities/sortContactsAndMembers";

const useMembersRowData = ({ currentMembers }) => {
  const sortedMembers = useMemo(() => {
    const cleanMembers = currentMembers
      // filter out empty items {}
      ?.filter(({ name, label }) => name?.firstName || name?.lastName || label);

    return sortContactsAndMembers(cleanMembers);
  }, [currentMembers]);

  return { sortedMembers };
};

export default useMembersRowData;

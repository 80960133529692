import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import cntl from "cntl";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { DocumentAPI } from "@griffingroupglobal/eslib-api";

import { useAppState } from "../../../state/appState";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { useDocumentSwitchView } from "../../../hooks/useSwitchView";

import DocumentTable from "../DocumentTable/DocumentTable";
import DocumentFileFolders from "../../../Pages/Document/DocumentFileFolders";
import Modal from "../Modal/Modal";

import Spinner from "../Spinner/Spinner";
import IconButton from "../Buttons/IconButton";

import folderIconGreen from "../../assets/images/folderIconGreen.svg";
import folderIconWhite from "../../assets/images/folderIconWhite.svg";
import rowIcon from "../../assets/images/rowIcon.svg";
import rowIconGreen from "../../assets/images/rowIconGreen.svg";
import {
  GET_DOCUMENT_PATH,
  GET_DOCUMENT_EDIT_PATH,
  GET_DOCUMENT_HISTORY_PATH,
  GET_PROPERTY_WORKFLOW_CREATE_PATH,
  WORKFLOWS_ADD_NEW_PATH,
  SET_WORKFLOW_DOCUMENT,
  GET_PROJECT_WORKFLOW_CREATE_PATH,
} from "../../../constants";
import { toastError } from "../../../helpers/Toast";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../assets/images/whiteExclamationIcon.svg";
import { getDocumentUser } from "../../../helpers/Document";

const toastCloseIcon = <img src={whiteCrossIcon} alt="Close notice" />;
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const selectedButtonCN = cntl`
  bg-brandGreen
  border
  border-brandGreen
  rounded
  p-1
`;
const notSelectedButtonCN = cntl`
  bg-white
  border
  border-brandGreen
  rounded
  p-1
`;

const DocumentSelect = ({
  documents,
  selectedDocuments,
  onUpdateDocuments,
  onRowSelect,
  onFileSelect,
  showHeader,
  loading,
  addDocument,
  addDocuments,
  removeDocument,
  docTypeOptionsMap,
  favorites,
  postFavorite,
  deleteFavorite,
  documentAssociation,
}) => {
  const history = useHistory();
  const { data: currentUser } = useCurrentUser();
  const { projectId, propertyId } = useParams();
  const [, dispatchAppState] = useAppState();
  const [isShowingTable, setIsShowingTable] = useDocumentSwitchView(false);

  const [addedFiles, setAddedFiles] = useState([]);
  const [showFolder, setShowFolder] = useState(false);
  const [currentFolder, setCurrentFolder] = useState();
  const [folderPath, setFolderPath] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState();

  const pageTopRef = React.createRef(null);

  const handleFavoriteClick = React.useCallback(
    (id) => {
      const foundItem = favorites.find((favorite) => {
        return favorite.item.reference.includes(id);
      });
      const foundDoc = documents.find((doc) => doc.id === id);

      if (foundItem) {
        deleteFavorite([foundItem]);
      } else {
        postFavorite(id, "Document");
      }

      if (foundDoc) {
        delete foundDoc.isFavorited;
        addDocument(foundDoc);
      }
    },
    [documents, favorites, deleteFavorite, postFavorite, addDocument]
  );

  useEffect(() => {
    if (showFolder) {
      pageTopRef.current?.scrollIntoView();
    }
  }, [pageTopRef, showFolder]);

  useEffect(() => {
    const stillUploading = addedFiles.some((item) => item.isUploading);

    if (!stillUploading && addedFiles.length) {
      addedFiles.forEach((item) => {
        if (item.isFavorited) {
          handleFavoriteClick(item.id);
        }
      });
      addDocuments(addedFiles);
      setAddedFiles([]);
    }
  }, [addedFiles, addDocuments, handleFavoriteClick]);

  const handleShowingTableView = (isShowingTableView) => {
    setIsShowingTable(isShowingTableView);
  };
  const handleFileSelect = (val) => {
    onFileSelect(val);
  };
  const handleRowSelect = useCallback(
    (val) => {
      onRowSelect(val);
    },
    [onRowSelect]
  );

  const handleFolderClick = (title, docType) => {
    setShowFolder(true);
    setFolderPath(title);
    setCurrentFolder(docType);
  };
  const handleFileClick = (doc) => {
    history.push(GET_DOCUMENT_PATH(doc.id));
  };
  const handleRowClick = (doc) => {
    history.push(GET_DOCUMENT_PATH(doc.id));
  };
  const handleFileEdit = (id) => {
    history.push(GET_DOCUMENT_EDIT_PATH(id));
  };
  const handleShowHistory = (id) => {
    history.push(GET_DOCUMENT_HISTORY_PATH(id));
  };

  const handleSendToWorkflow = useCallback(
    ({ id, project, property }) => {
      const document = documents.find((doc) => doc.id === id);

      const parentDocument = documents.find(
        (doc) => doc.reference === document?.documentRelationships?.parent
      );
      const doesParentDocHasCurrentDocAsAdditionalDoc =
        parentDocument?.additionalDocuments?.indexOf(document.reference) !== -1;
      // if document has parent, then block the access and toast msg
      if (parentDocument && doesParentDocHasCurrentDocAsAdditionalDoc) {
        toastError(
          `This document was created from "${parentDocument?.name}" and cannot be directly sent to a Workflow. Go to the parent document to send this to a Workflow.`,
          toastErrorIcon,
          toastCloseIcon
        );
      } else {
        const allChildDocs = [];
        // if document has children then also attach the children docs to the WF
        if (documents?.length && document?.additionalDocuments?.length) {
          document?.additionalDocuments?.forEach((child) => {
            const childDoc = documents.find((doc) => doc.reference === child);
            if (childDoc) {
              allChildDocs.push(childDoc);
            }
          });
        }
        dispatchAppState({
          type: SET_WORKFLOW_DOCUMENT,
          workflowDocument: [id, ...allChildDocs.map((child) => child.id)],
        });

        if (project) {
          history.push(GET_PROJECT_WORKFLOW_CREATE_PATH(project.split("/")[1]));
        } else if (property) {
          history.push(
            GET_PROPERTY_WORKFLOW_CREATE_PATH(property.split("/")[1])
          );
        } else {
          history.push(WORKFLOWS_ADD_NEW_PATH);
        }
      }
    },
    [dispatchAppState, documents, history]
  );

  const handleUploadVersion = () => {};
  const handleUpdateDocuments = (val) => {
    if (onUpdateDocuments) {
      onUpdateDocuments(val);
    }
  };

  const handleShowDelete = (doc) => {
    setDeleteDoc(doc);
    setShowDeleteModal(true);
  };
  const handleHideDelete = () => {
    setDeleteDoc();
    setShowDeleteModal(false);
  };
  const handleDeleteDocument = () => {
    DocumentAPI.delete(deleteDoc.id);
    removeDocument(deleteDoc.id);

    if (deleteDoc?.additionalDocuments?.length) {
      deleteDoc?.additionalDocuments.map((doc) =>
        removeDocument(doc.split("/")[1])
      );
    }
    handleHideDelete();
  };

  const handleBackClick = () => {
    setCurrentFolder();
    setShowFolder(false);
  };

  const handleFileAdded = React.useCallback(
    (fileRef, fileBody) => {
      const currentFile = {
        isUploading: true,
        reference: fileRef,
        isFavorited: fileBody.isFavorited,
      };
      setAddedFiles((prev) => [currentFile, ...prev]);

      if (fileBody) {
        const tempDocument = {
          customName: fileBody.name,
          docType: currentFolder ?? fileBody.docType,
          contentReference: fileRef,
          status: "open",
          lineItems: [],
        };

        if (projectId || documentAssociation?.includes("Project/")) {
          tempDocument.project = projectId
            ? `Project/${projectId}`
            : documentAssociation;
        }
        if (propertyId || documentAssociation?.includes("Property/")) {
          tempDocument.property = propertyId
            ? `Property/${propertyId}`
            : documentAssociation;
        }

        DocumentAPI.post(tempDocument)
          .then(({ data: newDocument }) => {
            if (newDocument?.id) {
              const resultDocument = {
                ...newDocument,
                creator: currentUser,
                isFavorited: fileBody.isFavorited,
                uploadedFileRef: fileRef,
              };

              setAddedFiles((prev) => {
                return [
                  resultDocument,
                  ...prev.filter((item) => item.reference !== fileRef),
                ];
              });
            }
          })
          .catch((err) => console.error(err));
      }
    },
    [currentFolder, projectId, documentAssociation, propertyId, currentUser]
  );

  const getFolderContents = React.useCallback(() => {
    let filteredDocs = documents;
    filteredDocs =
      documents?.map((doc) => {
        return {
          ...doc,
          isFavorited:
            doc.isFavorited ??
            favorites.some((fav) => fav.item.reference.includes(doc.id)),
        };
      }) ?? [];

    if (currentFolder === "isFavorited") {
      filteredDocs = filteredDocs.filter((doc) => doc.isFavorited);
    } else if (currentFolder === "isDraft") {
      filteredDocs = filteredDocs.filter((doc) => doc.status === "draft");
    } else if (currentFolder) {
      filteredDocs = filteredDocs.filter(
        (doc) => doc.docType === currentFolder
      );
    }

    return filteredDocs.map((doc) => ({
      ...doc,
      resource: doc.name,
      createdBy: getDocumentUser(doc.creator),
      modifiedDate: doc.metadata?.lastUpdated
        ? moment(doc.metadata.lastUpdated).format("MM/DD/YYYY")
        : "--",
    }));
  }, [documents, favorites, currentFolder]);

  return (
    <>
      {!loading && (
        <div>
          <div className="absolute top-0" ref={pageTopRef} />
          {showHeader && (
            <div className="flex justify-end pt-5">
              <div className="flex justify-between">
                <IconButton
                  className={`${
                    !isShowingTable ? selectedButtonCN : notSelectedButtonCN
                  } mr-2`}
                  icon={isShowingTable ? folderIconGreen : folderIconWhite}
                  onClick={() => handleShowingTableView(false)}
                />
                <IconButton
                  className={
                    isShowingTable ? selectedButtonCN : notSelectedButtonCN
                  }
                  icon={!isShowingTable ? rowIconGreen : rowIcon}
                  onClick={() => handleShowingTableView(true)}
                />
              </div>
            </div>
          )}

          {!isShowingTable && (
            <DocumentFileFolders
              contents={getFolderContents()}
              favorites={favorites}
              path={folderPath}
              defaultType={currentFolder}
              docTypeOptionsMap={docTypeOptionsMap}
              selectedDocuments={selectedDocuments}
              addedFiles={addedFiles}
              onUpdateDocuments={handleUpdateDocuments}
              onFileSelect={handleFileSelect}
              onFileClick={handleFileClick}
              onFileEdit={handleFileEdit}
              onShowHistory={handleShowHistory}
              onSendToWorkflow={handleSendToWorkflow}
              onUploadVersion={handleUploadVersion}
              onFileAdd={handleFileAdded}
              onFileDelete={handleShowDelete}
              onFolderClick={handleFolderClick}
              onFavoriteClick={handleFavoriteClick}
              onBackClick={handleBackClick}
              showHeader={false}
              showFolder={showFolder}
              hideButtons
            />
          )}

          {isShowingTable && (
            <DocumentTable
              resourceName="document-attachment-table"
              documents={getFolderContents()}
              favorites={favorites}
              selectedDocuments={selectedDocuments}
              onFileSelect={handleRowSelect}
              onRowClick={handleRowClick}
              onFileEdit={handleFileEdit}
              onShowHistory={handleShowHistory}
              onSendToWorkflow={handleSendToWorkflow}
              onUploadVersion={handleUploadVersion}
              onFileDelete={handleShowDelete}
              onFavoriteClick={handleFavoriteClick}
              hideSiteHeader
              hideButtons
              docTypeOptionsMap={docTypeOptionsMap}
            />
          )}
        </div>
      )}

      {loading && (
        <div className="flex justify-center items-center w-full h-full">
          <Spinner notFullScreen />
        </div>
      )}

      <Modal
        title={
          deleteDoc?.name ? `Delete ${deleteDoc.name}?` : "Delete Document?"
        }
        isOpen={showDeleteModal}
        primaryButtonTitle="Yes, delete"
        primaryButtonOnClick={handleDeleteDocument}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={handleHideDelete}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <>
          <p className="text-base mb-2">
            Are you sure you want to delete {deleteDoc?.name ?? "this document"}
            ? Once deleted, it cannot be recovered.
          </p>
        </>
      </Modal>
    </>
  );
};

DocumentSelect.propTypes = {
  /**
   * array of documents
   */
  // eslint-disable-next-line react/forbid-prop-types
  documents: PropTypes.arrayOf(PropTypes.object),
  /**
   * array of attached documents
   */
  selectedDocuments: PropTypes.arrayOf(PropTypes.string),
  /**
   * run on file upload
   */
  onUpdateDocuments: PropTypes.func,
  /**
   * run on row select
   */
  onRowSelect: PropTypes.func,
  /**
   * run on file select
   */
  onFileSelect: PropTypes.func,
  /**
   * show siteheader
   */
  showHeader: PropTypes.bool,
  /**
   * loading state
   */
  loading: PropTypes.bool,
  addDocument: PropTypes.func,
  addDocuments: PropTypes.func,
  removeDocument: PropTypes.func,
  docTypeOptionsMap: PropTypes.shape({}),
  favorites: PropTypes.arrayOf(PropTypes.shape({})),
  postFavorite: PropTypes.func,
  deleteFavorite: PropTypes.func,
  documentAssociation: PropTypes.string,
};

DocumentSelect.defaultProps = {
  documents: null,
  selectedDocuments: [],
  onUpdateDocuments: undefined,
  onRowSelect: undefined,
  onFileSelect: undefined,
  showHeader: true,
  loading: false,
  addDocument: undefined,
  addDocuments: undefined,
  removeDocument: undefined,
  docTypeOptionsMap: undefined,
  favorites: [],
  postFavorite: undefined,
  deleteFavorite: undefined,
  documentAssociation: undefined,
};

export default DocumentSelect;

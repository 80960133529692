import { AssetAPI, EventAPI } from "@griffingroupglobal/eslib-api";
import { useCallback, useEffect, useState } from "react";
import { SET_ASSETS } from "../constants";
import { getEventQueryLeftRight } from "../helpers/getEventQueryLeftRight";
import { removeAsset } from "../helpers/removeAsset";
import { useAppState } from "../state/appState";

/**
 *
 * @deprecated
 */

export default ({
  catCode,
  propertyRef,
  assetId,
  projectRef,
  events,
  spaceId,
} = {}) => {
  const [{ assets }, dispatch] = useAppState();
  const [filteredAssets, setFilteredAssets] = useState(assets);
  const [assetEvents, setAssetEvents] = useState();
  const [loading, setLoading] = useState(true);

  const reload = useCallback(async () => {
    const { data } = await AssetAPI.get();
    const assetsDD = [];
    let assetAssociations = {};

    const newAssets = data.entries.map((e) => {
      // Store all asset references under association keys

      /**
       * Store Property and/or Project asset refs
       * @summary - eliminates API call to get Assets associated with the parent resource
       */
      assetAssociations = {
        ...assetAssociations,
        // Selected Key in assetAssociations: either project or property
        [e.resource?.project ?? e.resource?.property]: [
          // Spread existing assetAssociation key if it exists
          ...(assetAssociations[e.resource?.project ?? e.resource?.property] ??
            []),
          // Add new reference to assetAssociations
          e.resource.reference,
        ],
      };

      // Map Drop Down
      assetsDD.push({
        label: e.resource?.name,
        value: e.resource?.reference,
        id: e.resource?.id,
      });

      return e.resource;
    });

    dispatch({
      type: SET_ASSETS,
      assets: newAssets,
      assetsDD,
      assetsDict: newAssets.reduce(
        (acc, v) => ({ ...acc, [v.reference]: v }),
        {}
      ),
      assetAssociations,
    });

    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    let toFilter = assets;

    if (assetId) {
      toFilter = toFilter.filter((a) => a.id === assetId);
    }
    if (catCode) {
      toFilter = toFilter.filter((a) => a.category.code === catCode);
    }
    if (propertyRef) {
      toFilter = toFilter.filter((a) => a.property === propertyRef);
    }
    if (projectRef) {
      toFilter = toFilter.filter((a) => a.project === projectRef);
    }
    if (spaceId) {
      toFilter = toFilter.filter(
        (a) => !!a.spaces.find((space) => space === spaceId)
      );
    }

    setFilteredAssets(toFilter);
  }, [assets, assetId, catCode, propertyRef, projectRef, spaceId]);

  const eventsReload = useCallback(() => {
    if (events && filteredAssets?.length) {
      const fetchEvents = async () => {
        try {
          const eventsData = await Promise.all(
            filteredAssets?.map(async (asset) => {
              const { left, right } = getEventQueryLeftRight(
                asset?.metadata?.createdAt
              );
              const { data: eventData } = await EventAPI.get({
                params: {
                  left,
                  right,
                  association: asset?.reference,
                },
              });

              const cleanEvents = eventData.entries.map(
                (entry) => entry.resource
              );
              return cleanEvents;
            }, [])
          ).then((lists) =>
            lists.reduce((acc, list) => {
              acc.push(...list);
              return acc;
            }, [])
          );

          setAssetEvents(eventsData);
        } catch (error) {
          console.warn("Error fetching asset events", error);
        }
      };
      fetchEvents();
    }
  }, [events, filteredAssets]);

  return [
    filteredAssets,
    reload,
    assetEvents,
    eventsReload,
    loading,
    removeAsset,
  ];
};

import React from "react";
import PropTypes from "prop-types";
import TagsDropdown from "./TagsDropdown";
import TagList from "../TagList";
import useTagsSelectorViewData from "./useTagsSelectorViewData";

/**
 * Component that renders a Tags dropdown, displaying a horizontally aligned list of selected tags.
 */
const TagsSelector = ({
  tags,
  isEditing,
  isInline,
  onTagAdded,
  onTagRemoved,
}) => {
  // Presenter hook
  const {
    canCreateTags,
    options,
    tagListStyle,
    handleAddTag,
    handleRemoveTag,
  } = useTagsSelectorViewData({
    tags,
    isEditing,
    onTagAdded,
    onTagRemoved,
    isInline,
  });

  return (
    <div className={`w-full ${isInline ? "flex flex-row" : ""}`}>
      <TagsDropdown
        className="w-56"
        canCreate={canCreateTags}
        options={options}
        hidden={!canCreateTags || !isEditing}
        onChange={handleAddTag}
      />
      <TagList
        tags={tags}
        className={tagListStyle}
        isEditing={isEditing}
        handleDeleteTag={handleRemoveTag}
      />
    </div>
  );
};

TagsSelector.defaultProps = {
  tags: [],
  isEditing: false,
  isInline: false,
  onTagAdded: undefined,
  onTagRemoved: undefined,
};

TagsSelector.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  isEditing: PropTypes.bool,
  isInline: PropTypes.bool,
  onTagAdded: PropTypes.func,
  onTagRemoved: PropTypes.func,
};

export default TagsSelector;

/* eslint-disable react/button-has-type */
import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Spinner from "react-loader-spinner";
import Chevron from "../Chevron/Chevron";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import "./BaseButton.css";

const buttonCN = ({
  className,
  colorCN,
  hoverColorCN,
  leftAlign,
  hasCustomFontStyles,
  noMaxWidth,
  disableCapitalize,
  disbaledBackgroundColorCN,
  animated,
}) => cntl`
  flex
  ${leftAlign ? "text-left" : "justify-center"}
  items-center
  ${!hasCustomFontStyles ? "text-sm font-semibold" : ""}
  ${!disableCapitalize ? "capitalize" : ""}
  ${colorCN}
  stroke-current
  transition
  duration-300
  ease-in-out
  hover:${hoverColorCN}
  active:${hoverColorCN}
  disabled:cursor-default
  disabled:${disbaledBackgroundColorCN || "bg-gray-100"}
  disabled:text-gray-200
  disabled:border-transparent
  ${!noMaxWidth ? "max-w-min" : "max-w-none"}
  whitespace-nowrap
  ${animated && "animated-button"}
  ${className}
`;

const dropdownIndicatorCN = (chevronClass, hasDropdownItems) => cntl`
  border-l
  border-current
  flex
  self-stretch
  items-center
  ${hasDropdownItems ? "px-3" : ""}
  ${chevronClass}
`;

const dropdownIconCN = (menuIsOpen) => cntl`
  w-3
  h-3
  transform
  ${menuIsOpen ? "-rotate-90" : "rotate-90"}
`;

const menuContainerCN = (
  openAbove,
  fileButtonPosition,
  animationClassName
) => cntl`
  ${openAbove ? "bottom-0" : "bottom-100"}
  flex
  flex-col
  absolute
  right-0
  ${fileButtonPosition ?? "top-full"}
  rounded
  z-50
  ${animationClassName}
`;

const dropdownItemCN = (className) => cntl`
  w-full
  py-3
  px-4
  text-sm
  text-left
  text-es-medium-grey
  whitespace-nowrap
  ${className}
  hover:bg-es-light-green
`;

const iconCN = (title, className) => cntl`
  min-w-max
  ${title ? "mr-1" : ""}
  ${className}
`;

const titleCN = (innerClassName, hasDropdownItems) => cntl`
  ${innerClassName || "mx-2"}
  ${hasDropdownItems && "flex-grow"}
`;

const indentMenuLabelCN = (className) => cntl`
  pl-4
  py-2
  text-gray-300
  ${className}
`;

const indentMenuItemCN = (className) => cntl`
  pl-7
  ${className}
`;

const LOADING_ICON_SIZE = 20;

const BaseButton = ({
  title,
  onClick,
  className,
  innerClassName,
  chevronClassName,
  style,
  isLoading,
  disabled,
  iconLeft,
  iconRight,
  colorCN,
  hoverColorCN,
  leftAlign,
  hasCustomFontStyles,
  dropdownItems,
  noMaxWidth,
  disableCapitalize,
  iconClassName,
  disabledText,
  forwardedRef,
  name,
  hideDropdownChevron,
  chevronColor,
  fileButtonPosition,
  disbaledBackgroundColorCN,
  openAbove,
  animationClassName,
  onKeyUp,
  indentMenuLabelClassName,
  indentMenuItemClassName,
  animated,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [hover, setHover] = useState(false);
  const wrapperRef = useRef(null);

  const closeMenu = () => {
    setShowMenu(false);
  };

  const handleShowMenu = () => {
    if (showMenu) {
      closeMenu();
    } else {
      setShowMenu(true);
    }
  };

  const handleBlur = () => {
    setShowMenu(false);
  };

  // set a ref for the timer for cleanup function
  const timer = useRef(null);

  useOutsideAlerter(forwardedRef ?? wrapperRef, () => {
    timer.current = setTimeout(handleBlur, 150);
  });

  useEffect(() => {
    // cleanup function for timer
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleHover = (action) => {
    switch (action) {
      case "enter":
        setHover(true);
        break;
      case "leaveList":
        break;
      default:
        setHover(false);
    }
  };

  const getButton = () => (
    <button
      onKeyUp={onKeyUp}
      ref={forwardedRef}
      type="button"
      style={style}
      className={buttonCN({
        className,
        colorCN,
        hoverColorCN,
        leftAlign,
        hasCustomFontStyles,
        noMaxWidth,
        disableCapitalize,
        disbaledBackgroundColorCN,
        animated,
      })}
      disabled={disabled || isLoading}
      onClick={
        dropdownItems
          ? handleShowMenu
          : (e) => {
              e.stopPropagation();
              onClick();
            }
      }
      onMouseEnter={!dropdownItems ? () => handleHover("enter") : undefined}
      onMouseLeave={!dropdownItems ? () => handleHover("leaver") : undefined}
      name={name}
    >
      {isLoading ? (
        <Spinner
          type="Puff"
          color="inherit"
          height={LOADING_ICON_SIZE}
          width={LOADING_ICON_SIZE}
        />
      ) : (
        <>
          {iconLeft && (
            <span className={iconCN(title, iconClassName)}>{iconLeft}</span>
          )}
          {title && (
            <span className={titleCN(innerClassName, !!dropdownItems)}>
              {title}
            </span>
          )}
          {dropdownItems && !hideDropdownChevron && (
            <span
              className={dropdownIndicatorCN(chevronClassName, !!dropdownItems)}
            >
              <Chevron
                className={dropdownIconCN(showMenu)}
                color={hover ? "white" : chevronColor}
              />
            </span>
          )}
          {iconRight && (
            <span className={iconCN(title, iconClassName)}>{iconRight}</span>
          )}
        </>
      )}
    </button>
  );

  const makeButtonOrLabel = (item, indented = false) => {
    return item?.isMenuLabel ? (
      <h3
        key={item?.label}
        className={indentMenuLabelCN(indentMenuLabelClassName)}
      >
        {item?.label}
      </h3>
    ) : (
      <button
        key={item?.title}
        type="button"
        className={dropdownItemCN(
          indented ? indentMenuItemCN(indentMenuItemClassName) : item?.className
        )}
        onClick={() => {
          handleBlur();
          item?.onClick();
        }}
      >
        {item?.anchorTag || item?.title}
      </button>
    );
  };

  if (dropdownItems) {
    return (
      <div
        className="relative inline-block"
        onMouseLeave={() => handleHover("leaveList")}
        onMouseEnter={() => handleHover("enter")}
        ref={wrapperRef}
      >
        {getButton()}
        {showMenu && (
          <div
            className={menuContainerCN(
              openAbove,
              fileButtonPosition,
              animationClassName
            )}
            style={{ borderTop: "0.5rem solid rgba(255,255,255,.0)" }}
          >
            <div className="flex flex-col border border-gray-200 rounded bg-white">
              {dropdownItems.map((item) => {
                if (item.indentedMenu) {
                  const result = item.indentedMenuItems?.map((menuItem) =>
                    makeButtonOrLabel(menuItem, true)
                  );

                  return result;
                }
                return makeButtonOrLabel(item);
              })}
            </div>
          </div>
        )}
        {!!disabled && disabledText && (
          <div className="flex py-2">
            <p className="text-brandRed italic">{disabledText}</p>
          </div>
        )}
      </div>
    );
  }
  return (
    <>
      {getButton()}
      {!!disabled && disabledText && (
        <div className="flex py-2">
          <p className="text-brandRed italic">{disabledText}</p>
        </div>
      )}
    </>
  );
};

BaseButton.propTypes = {
  /**
   * the text to show in the button
   */
  title: PropTypes.string,
  /**
   * the handler called when the button is clicked
   */
  onClick: PropTypes.func,
  /**
   * any CSS classes to apply to the container
   */
  className: PropTypes.string,
  /**
   * any CSS classes to apply to span that holds the title
   */
  innerClassName: PropTypes.string,
  /**
   * any CSS classes to apply to span that holds the chevron
   */
  chevronClassName: PropTypes.string,
  /**
   * inline styles to apply to the button
   */
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  /**
   * shows a spinner if true
   */
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  /**
   * JSX to display on the left side of the button
   */
  iconLeft: PropTypes.element,
  iconRight: PropTypes.element,
  /**
   * color class name to use for text and icon color
   */
  colorCN: PropTypes.string,
  /**
   * color class name to use for text and icon hover/disabled/active states
   */
  hoverColorCN: PropTypes.string,
  /**
   * if true, button content will be left-aligned instead of centered
   */
  leftAlign: PropTypes.bool,
  /**
   * if true, base font styles won't be applied to this component
   * so they can be styled when rendering the component
   */
  hasCustomFontStyles: PropTypes.bool,
  /**
   * array of options to show on click, if passed
   * if this prop exists, a dropdown arrow will be added to the button
   */
  dropdownItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      className: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  /**
   * boolean to determine if button should use max-width content css property
   */
  noMaxWidth: PropTypes.bool,
  disableCapitalize: PropTypes.bool,
  iconClassName: PropTypes.string,
  disabledText: PropTypes.string,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  name: PropTypes.string,
  hideDropdownChevron: PropTypes.bool,
  chevronColor: PropTypes.string,
  fileButtonPosition: PropTypes.string,
  openAbove: PropTypes.bool,
  disbaledBackgroundColorCN: PropTypes.string,
  animationClassName: PropTypes.string,
  onKeyUp: PropTypes.func,
  indentMenuLabelClassName: PropTypes.string,
  indentMenuItemClassName: PropTypes.string,
  animated: PropTypes.bool,
};

BaseButton.defaultProps = {
  title: null,
  onClick: undefined,
  className: null,
  innerClassName: null,
  chevronClassName: null,
  iconClassName: undefined,
  style: undefined,
  isLoading: false,
  disabled: false,
  iconLeft: undefined,
  iconRight: undefined,
  colorCN: "text-white",
  hoverColorCN: "",
  leftAlign: false,
  hasCustomFontStyles: false,
  dropdownItems: undefined,
  noMaxWidth: false,
  disableCapitalize: false,
  disabledText: undefined,
  forwardedRef: undefined,
  name: undefined,
  hideDropdownChevron: false,
  chevronColor: "white",
  fileButtonPosition: undefined,
  openAbove: false,
  disbaledBackgroundColorCN: undefined,
  animationClassName: "",
  onKeyUp: undefined,
  indentMenuLabelClassName: undefined,
  indentMenuItemClassName: undefined,
  animated: false,
};

export default BaseButton;

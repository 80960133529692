import React, { useMemo } from "react";

/**
 * @param number - {Integer} - To be returned in circle
 * @param size - {Float} - will be converted to rem
 * @returns - Perfectly porportioned Circle Svg with number centered
 */
export default ({ number, size, className, fontSize, aspectRatio }) => {
  const numberFont = useMemo(() => {
    const value = String(number).length >= 3 ? `${number}+` : number;
    const length = String(number).length >= 3 ? 50 : String(number).length * 15;

    return {
      size: `${100 - length}%`,
      value,
      dimensions: `${((parseFloat(size) * 100) / (100 - length)) * 1.2}rem`,
    };
  }, [number, size]);
  return (
    <svg
      viewBox="0 0 24 24"
      width={numberFont?.dimensions}
      height={numberFont?.dimensions}
      className={className}
      preserveAspectRatio={aspectRatio}
    >
      <g id="circle">
        <circle cx="12" cy="12" r="12" fill="#1DC29D" />
        <text
          textAnchor="middle"
          alignmentBaseline="central"
          x="12"
          y="12"
          fontSize={fontSize ?? numberFont?.size}
          fill="white"
        >
          {numberFont?.value}
        </text>
      </g>
    </svg>
  );
};

import React from "react";
import useRFIs from "../../../hooks/useRFIs";
import { useRfiSwitchView } from "../../../hooks/useSwitchView";
import RfiCardGrid from "../../../stories/Components/RfiCardGrid/RfiCardGrid";
import RfiTable from "../../../stories/Components/RfiTable/RfiTable";

const RfiList = () => {
  const {
    data: { rfis = [] },
  } = useRFIs();
  const [isShowingTable, setIsShowingTable] = useRfiSwitchView(false);

  return (
    <>
      {isShowingTable ? (
        <RfiTable data={rfis} />
      ) : (
        <RfiCardGrid data={rfis} setIsShowingTable={setIsShowingTable} />
      )}
    </>
  );
};

export default RfiList;

const truncateLabel = (label, size) =>
  label?.length > parseInt(size, 10)
    ? `${label?.substring(0, size)} ...`
    : label;

const sanitizeLabel = (label) => {
  return label.trim().toLowerCase().replace(/\s+/g, "_");
};

const getTagOptions = (resource, tagsDict) => {
  // Check if the resource has tags property and if so, map over each tag
  return (
    resource?.tags?.map((tag) => {
      // For each tag, check if it has a 'value' property.
      // If it does, use that, otherwise use the tag itself. (hack for inconsistent data from server)
      const tagRef = tag?.value ?? tag;
      // Look up the tag reference in the provided tags dictionary
      const foundTag = tagsDict?.[tagRef];
      // Return an object with label and value properties
      // based on the found tag in the tags dictionary
      return {
        label: foundTag?.label,
        value: foundTag?.reference,
      };
    }) || [] // If there are no tags, return an empty array
  );
};

export { getTagOptions, sanitizeLabel, truncateLabel };

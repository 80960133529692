import { useParams } from "react-router";
import {
  usePropertiesDelete,
  usePropertiesPatch,
  usePropertyById,
} from "../../../hooks/properties";
import useServiceRequestTopButtons from "../../../hooks/useServiceRequestTopButtons";
import useReportTopButtons from "../../../hooks/useReportTopButtons";
import useQueryNotFoundNavigation from "../../../hooks/navigation/useQueryNotFoundNavigation";

const usePropertyViewData = () => {
  const { propertyId } = useParams();

  const { data: property, isLoading, error } = usePropertyById(propertyId);

  // Redirect to 404 page if resource is not found
  useQueryNotFoundNavigation({ error });

  // Hook to calculate buttons for SR tab
  const { getRequestButtonProps } = useServiceRequestTopButtons();

  // Hook to calculate buttons for Report tab
  const { getReportButtonProps } = useReportTopButtons();

  const { mutate: patchProperty, mutateAsync: patchPropertyAsync } =
    usePropertiesPatch();

  const { mutate: removeProperty } = usePropertiesDelete();

  return {
    propertyId,
    property,
    isLoading,
    getRequestButtonProps,
    getReportButtonProps,
    patchProperty,
    patchPropertyAsync,
    removeProperty,
  };
};

export default usePropertyViewData;

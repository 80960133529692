import { useLocation } from "react-router-dom";

/**
 * Hook that allows extracting a specific query parameter value from the current URL.
 * @param {string} query query parameter to look
 * @returns value of query parameter
 */
const useURLQueryParameter = (query) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get(query);
};

export default useURLQueryParameter;

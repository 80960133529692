import React from "react";

// The PopupWrapper component is a wrapper for all popups that are positioned
// relative to the mouse. It is responsible for positioning the popup and
const PopupWrapper = ({ containerRef, children, popupWrapperStyles }) => {
  return (
    <div
      ref={containerRef}
      style={popupWrapperStyles?.container}
      className="shadow-lg rounded-lg"
    >
      <div className="rounded-lg" style={popupWrapperStyles?.body}>
        {children}
      </div>
    </div>
  );
};

export default PopupWrapper;

import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";
import { toastError, toastMessage } from "../helpers/Toast";
import whiteCrossIcon from "../stories/assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../stories/assets/images/whiteExclamationIcon.svg";
import {
  getDocumentLastUpdatedPatchDate,
  getFinancialLastUpdatedPatchDate,
  getManagementLastUpdatedPatchDate,
  getSystemLastUpdatedPatchDate,
  getWokflowLastUpdatedPatchDate,
} from "../helpers/Settings";
import { userKeys } from "../config/reactQuery/queryKeyFactory";

const toastCloseIcon = <img src={whiteCrossIcon} alt="Close notice" />;
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;
const toastSuccessIcon = <img src={whiteExlamationIcon} alt="Success icon" />;

const onUpdateManagementConfig = async (
  patchDate,
  managementConfiguration,
  managementSettings
) => {
  const lastUpdatedDate = await getManagementLastUpdatedPatchDate(
    managementConfiguration,
    managementSettings,
    patchDate
  );

  return lastUpdatedDate;
};

const onUpdateFinancialsConfig = async (
  patchDate,
  financialsConfiguration,
  financialsSettings
) => {
  const lastUpdatedDate = await getFinancialLastUpdatedPatchDate(
    financialsConfiguration,
    financialsSettings,
    patchDate
  );

  return lastUpdatedDate;
};

const onUpdateSystemConfig = async (
  patchDate,
  systemConfiguration,
  systemSettings
) => {
  const lastUpdatedDate = await getSystemLastUpdatedPatchDate(
    systemConfiguration,
    systemSettings,
    patchDate
  );

  return lastUpdatedDate;
};

const onUpdateDocumentsConfig = async (
  patchDate,
  documentsConfiguration,
  documentsSettings
) => {
  const lastUpdatedDate = await getDocumentLastUpdatedPatchDate(
    documentsConfiguration,
    documentsSettings,
    patchDate
  );

  return lastUpdatedDate;
};

const onUpdateWorkflowsConfig = async (
  patchDate,
  workflowsConfiguration,
  workflowsSettings
) => {
  const lastUpdatedDate = await getWokflowLastUpdatedPatchDate(
    workflowsConfiguration,
    workflowsSettings,
    patchDate
  );

  return lastUpdatedDate;
};

const updateSettings = async ({
  patchDate,
  managementConfiguration,
  managementSettings,
  systemConfiguration,
  systemSettings,
  financialsConfiguration,
  financialsSettings,
  documentsConfiguration,
  documentsSettings,
  workflowsConfiguration,
  workflowsSettings,
}) => {
  let lastUpdatedDate = await onUpdateManagementConfig(
    patchDate,
    managementConfiguration,
    managementSettings
  );

  lastUpdatedDate = await onUpdateFinancialsConfig(
    lastUpdatedDate,
    financialsConfiguration,
    financialsSettings
  );

  lastUpdatedDate = await onUpdateSystemConfig(
    lastUpdatedDate,
    systemConfiguration,
    systemSettings
  );

  lastUpdatedDate = await onUpdateDocumentsConfig(
    lastUpdatedDate,
    documentsConfiguration,
    documentsSettings
  );

  await onUpdateWorkflowsConfig(
    lastUpdatedDate,
    workflowsConfiguration,
    workflowsSettings
  );
};

/**
 * Query mutation hook to patch all configurations sequentially
 * @param {string} mutationKey string to allow track mutations
 */
const useConfigurationPatch = (mutationKey) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey,
    mutationFn: updateSettings,
    onSuccess: (_, { reloadMeasurements }) => {
      queryClient.invalidateQueries("configuration");
      toastMessage(
        "Settings saved successfully",
        toastSuccessIcon,
        toastCloseIcon,
        uuidv4(),
        "mb-2"
      );
      reloadMeasurements();
      // refetch user data for updated prefs
      queryClient.invalidateQueries(userKeys.users);
    },
    onError: (error) => {
      toastError(
        "Something happen while saving settings, try later",
        toastErrorIcon,
        toastCloseIcon,
        uuidv4(),
        "mb-2"
      );
      console.error("Configuration❌", JSON.stringify(error, null, 2));
    },
  });
};

export default useConfigurationPatch;

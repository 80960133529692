import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { getTimeZoneOptions } from "../../../helpers/Time";
import { usePropertiesOverview } from "../../../hooks/properties";
import AssociationLink from "../AssociationLink";
import DescriptionField from "../DescriptionField";
import Dropdown from "../Dropdown/Dropdown";
import InlineInput from "../Input/InlineInput";
import TagsContainer from "../Tags/TagsContainer";
import Widget from "../Widget/Widget";

const boldCN = (className) => cntl`
  font-bold 
  text-gray-450
  text-md
  w-1/3
  ${className}
`;

const typeCN = () => cntl`
  flex
  w-full
  border-b
  border-gray-450
  border-opacity-50
  items-center
  pb-4
`;

const descriptionCN = () => cntl`
  flex
  w-full
  py-4
  text-area-padding
  border-b
  border-gray-450
  border-opacity-50
`;

const textCN = cntl`
  text-gray-450 
  text-sm
  font-normal
`;

const ProjectOverview = ({
  resource,
  isEditing,
  dispatch,
  handleEdit,
  projectTypes,
}) => {
  const { propertiesDict } = usePropertiesOverview();

  const projectType = projectTypes?.find(
    (type) => type.value === resource?.projectType
  );

  const property = useMemo(
    () => propertiesDict?.[resource?.property],
    [propertiesDict, resource?.property]
  );

  const propertyOptions = React.useMemo(() => {
    const optionsList = [];
    if (propertiesDict)
      Object.values(propertiesDict)?.forEach((prop) => {
        optionsList.push({ label: prop.title, value: prop.reference });
      });
    return optionsList;
  }, [propertiesDict]);

  // Render Association link if association is available
  const renderAssociation = () =>
    property?.title ? (
      <AssociationLink
        associationName={property?.title}
        associationRef={property?.reference}
      />
    ) : (
      "Not specified"
    );

  return (
    <Widget
      draggable={false}
      title="Project Information"
      className="mb-4"
      overflow
    >
      <div className="relative pt-6">
        <div className={typeCN()}>
          <h3 className={boldCN()}>Type</h3>
          <div className="flex items-center w-full">
            <p className={textCN}>
              {!isEditing ? (
                projectType?.label ?? "Not specified"
              ) : (
                <div className="w-56">
                  <Dropdown
                    options={projectTypes}
                    value={projectType}
                    placeholder="Select"
                    onChange={(val) => handleEdit("projectType", val.value)}
                  />
                </div>
              )}
            </p>
          </div>
        </div>
        <div className={descriptionCN()}>
          <h3 className={boldCN()}>Property</h3>
          <div className="flex items-center w-full">
            <p className={textCN}>
              {!isEditing ? (
                renderAssociation()
              ) : (
                <div className="w-56">
                  <Dropdown
                    options={propertyOptions}
                    value={propertyOptions?.find(
                      (item) => item.value === resource.property
                    )}
                    placeholder="Select"
                    onChange={(val) => handleEdit("property", val.value)}
                  />
                </div>
              )}
            </p>
          </div>
        </div>
        <div className={descriptionCN()}>
          <h3 className={boldCN()}>Timezone</h3>
          <div className="flex items-center w-full">
            <p className={textCN}>
              {!isEditing ? (
                resource?.timezone ?? "Not specified"
              ) : (
                <div className="w-56">
                  <Dropdown
                    options={[...(getTimeZoneOptions() ?? [])]}
                    value={resource?.timezone}
                    placeholder={resource?.timezone ?? "Select Timezone"}
                    onChange={(val) => handleEdit("timezone", val.label)}
                  />
                </div>
              )}
            </p>
          </div>
        </div>
        <div className={descriptionCN()}>
          <h3 className={boldCN()}>Description</h3>
          {!isEditing ? (
            <div className="flex items-center w-full">
              <DescriptionField text={resource?.description} />
            </div>
          ) : (
            <InlineInput
              className={textCN}
              value={resource?.description}
              editing
              textarea
              hidePencil
              width="w-full"
              size="sm"
              color="gray-450"
              rows={3}
              placeholder="Project Description"
              onChangeCallback={(val) => handleEdit("description", val)}
            />
          )}
        </div>
        <div className={descriptionCN()}>
          <h3 className={boldCN()}>Goals</h3>
          {!isEditing ? (
            <div className="w-full">
              <DescriptionField text={resource?.goals} />
            </div>
          ) : (
            <InlineInput
              className={textCN}
              value={resource?.goals}
              editing
              textarea
              hidePencil
              width="w-full"
              size="sm"
              color="gray-450"
              rows={3}
              placeholder="Project Goals"
              onChangeCallback={(val) => handleEdit("goals", val)}
            />
          )}
        </div>
        <div className={descriptionCN()}>
          <h3 className={boldCN()}>Challenges</h3>
          {!isEditing ? (
            <div className="w-full">
              <DescriptionField text={resource?.challenges} />
            </div>
          ) : (
            <InlineInput
              className={textCN}
              value={resource?.challenges}
              editing
              textarea
              hidePencil
              width="w-full"
              size="sm"
              color="gray-450"
              rows={3}
              placeholder="Project Challenges"
              onChangeCallback={(val) => handleEdit("challenges", val)}
            />
          )}
        </div>
        <div className="flex flex-col pt-4 mt-4">
          <h3 className={boldCN(isEditing && "mb-4")}>Tags</h3>
          <TagsContainer
            isEditing={isEditing}
            dispatch={dispatch}
            resource={resource}
          />
        </div>
      </div>
    </Widget>
  );
};

ProjectOverview.propTypes = {
  resource: PropTypes.shape({
    property: PropTypes.string,
    duration: PropTypes.shape({
      value: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
      typeOfDuration: PropTypes.string,
    }),
    association: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    contractDate: PropTypes.string,
    description: PropTypes.string,
    timezone: PropTypes.string,
    projectType: PropTypes.string,
    goals: PropTypes.string,
    challenges: PropTypes.string,
  }),
  isEditing: PropTypes.bool,
  handleEdit: PropTypes.string,
  projectTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  dispatch: PropTypes.func,
  originalResource: PropTypes.shape({}),
};

ProjectOverview.defaultProps = {
  resource: undefined,
  isEditing: false,
  projectTypes: undefined,
  handleEdit: undefined,
  dispatch: undefined,
  originalResource: undefined,
};

export default ProjectOverview;

import { toast } from "react-toastify";
import { TimesheetAPI } from "@griffingroupglobal/eslib-api";
import useAuthenticatedQuery from "../useAuthenticatedQuery";
import useCurrentUser from "../useCurrentUser";
import timesheetKeys from "./timesheetKeys";
import { APPROVED, FAILED_TS_FETCH } from "../../constants";

/**
 * Custom hook to fetch and manage payroll approved timesheets for the current user.
 * This hook utilizes the user's reference from the current user context to query and filter
 * approved timesheets from the API.
 *
 * @returns {Object} - An object containing the approved timesheets for the current user.
 * @property {Array} timeSheetsAlreadyApproved - An array of objects each containing
 *                                               periodStart and periodEnd of the approved timesheets.
 */

function usePayrollApprovedTimesheets() {
  const { data: currentUser } = useCurrentUser();

  const fetchApprovedTimesheetsByUser = async () => {
    const query = {
      payrollStatus: APPROVED,
      userRef: currentUser?.reference,
    };

    const {
      data: { entries },
    } = await TimesheetAPI.get({ params: query });

    const approvedTimesheets = entries
      ?.map((entry) => entry.resource)
      .filter(
        (timesheet) =>
          timesheet.payrollStatus === APPROVED &&
          timesheet.userRef === currentUser?.reference
      );

    return approvedTimesheets.map((timesheet) => {
      const { periodStart, periodEnd } = timesheet;

      return {
        periodStart,
        periodEnd,
      };
    });
  };

  const { data: timeSheetsAlreadyApproved } = useAuthenticatedQuery({
    queryKey: timesheetKeys.approvedTsByUser(currentUser?.reference),
    queryFn: fetchApprovedTimesheetsByUser,
    onError: () => {
      toast.error(FAILED_TS_FETCH);
    },
    staleTime: 0,
  });

  return { timeSheetsAlreadyApproved };
}

export default usePayrollApprovedTimesheets;

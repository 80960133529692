import React from "react";
import AlertTotal from "../Alert/AlertTotal";

const SiteHeaderPendignUpdatesQueue = ({
  onPendingUpdatesQueueClick,
  total = 0,
}) => {
  return (
    <AlertTotal
      className="mr-4"
      total={total}
      onClick={onPendingUpdatesQueueClick}
      title="Pending Updates"
      disableActiveStyle
      hideIcon
    />
  );
};

export default SiteHeaderPendignUpdatesQueue;

import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { downloadMedia, fetchRecentFiles } from "../../../helpers/File";
import { getFullName } from "../../../helpers/Formatters";
import { getAssociationNameFromReference } from "../../../helpers/Utilities";
import smoothScrollTo from "../../../helpers/smoothScroll";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useFileFormReducer from "../../../hooks/useFileFormReducer";
import { useGetTags } from "../../../hooks/useTags";
import { useAppState } from "../../../state/appState";
import Modal from "../Modal/Modal";
import TableActionsIconsGroup from "../Table/TableActionsIconsGroup";
import UploadFile from "../UploadFile/UploadFile";
import FilePreview from "./FilePreview";
import PureFilesTable from "./PureFilesTable";
import { useGetExpenses } from "../../../hooks/useExpenses";
import { getTagOptions } from "../../../helpers/Tag";
import useWorkflow from "../../../hooks/useWorkflow";
import useReports from "../../../hooks/useReports";
import useServiceRequests from "../../../hooks/useServiceRequests";
import { usePropertiesOverview } from "../../../hooks/properties";
import { useProjectsOverview } from "../../../hooks/projects";
import { useAssetsOverview } from "../../../hooks/assets";
import useTemplatesConfiguration from "../../../hooks/useTemplatesConfiguration";

const SHOW_COLUMNS_SETTING_RIGHT = true;

const FilesTable = ({
  files,
  removeFilesAndUpdateApi,
  onRemoveFilesCallback,
  resourceName,
  association,
  onAddFilesCallback,
  uploadFileOverwrite,
  hasDeletePermission,
  hasWritePermission,
  hasEditPermission,
  handleFileClone,
  fetchDataOnScroll,
  hasMoreData,
  isFileOverview,
  handleUpdateFile,
  hideFileActionsIcons,
}) => {
  const [{ userDict, sopsDict, eventsDict, submittalDict }] = useAppState();

  const { assetsDict } = useAssetsOverview();
  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  const { data: expensesData } = useGetExpenses();
  const { data: currentUser } = useCurrentUser();
  const { data: tagsData } = useGetTags();
  const { data: workflowsData } = useWorkflow();
  const { data: reports } = useReports({});
  const { data: tickets } = useServiceRequests();

  const [fileForm, fileDispatch] = useFileFormReducer();
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showFileView, setShowFileView] = useState({
    show: false,
    doc: undefined,
  });
  const [deleteModalMessage, setDeleteModalMessage] = useState();
  const [templatesConfiguration, update, , deleteTemplate] =
    useTemplatesConfiguration();
  const templateSettings = useMemo(() => {
    if (!templatesConfiguration?.templates?.file) {
      return [];
    }

    return templatesConfiguration?.templates?.file
      ?.filter((template) => template?.name !== "last_updated_column_view")
      ?.map((template) => ({
        ...template,
        isAdmin: !template?.custom,
      }));
  }, [templatesConfiguration]);

  const updateUserTemplateSettings = useCallback(
    async (customViews) => {
      update({
        key: "file",
        updatedTemplates: [
          ...templateSettings?.filter((temp) => !temp?.custom),
          ...customViews?.map((view) => {
            // eslint-disable-next-line no-param-reassign
            view.custom = true;
            return view;
          }),
        ],
      });
    },
    [update, templateSettings]
  );

  const deleteUserTemplateSettings = useCallback(
    async (template) => {
      deleteTemplate({
        key: "files",
        id: template.id,
      });
    },
    [deleteTemplate]
  );

  useEffect(() => {
    if (showFileView?.show) {
      fileDispatch({
        type: "setOriginalResource",
        resource: files.find(
          (file) => file.reference === showFileView?.doc?.reference
        ),
      });
    }
  }, [fileDispatch, files, showFileView]);

  const reportsDict = useMemo(
    () =>
      reports?.reduce((acc, report) => {
        acc[report.reference] = report;
        return acc;
      }, {}),
    [reports]
  );

  const ticketsDict = useMemo(
    () =>
      tickets?.reduce((acc, ticket) => {
        acc[ticket.reference] = ticket;
        return acc;
      }, {}),
    [tickets]
  );

  const tableData = useMemo(() => {
    const updatedData = files.map((file) => ({
      ...file,
      createdBy: getFullName(userDict?.[file?.metadata?.createdBy]?.name),
      kind: file?.extension?.toUpperCase(),
      associationName: getAssociationNameFromReference(
        file?.association,
        propertiesDict,
        projectDict,
        assetsDict,
        sopsDict,
        eventsDict,
        submittalDict,
        expensesData?.expensesDict,
        workflowsData?.workflowDict,
        ticketsDict,
        reportsDict
      ),
      currentTags: file?.tags?.map((tagRef) => ({
        label: tagsData?.tagsDict[tagRef]?.label,
        value: tagsData?.tagsDict[tagRef]?.reference,
      })),
    }));

    return updatedData;
  }, [
    assetsDict,
    files,
    projectDict,
    propertiesDict,
    sopsDict,
    userDict,
    eventsDict,
    submittalDict,
    expensesData?.expensesDict,
    tagsData?.tagsDict,
    workflowsData?.workflowDict,
    ticketsDict,
    reportsDict,
  ]);

  // This is to clear the timer when the component unmounts
  const timerRef = useRef(null);

  useEffect(() => {
    return () => {
      // Clear the timer on unmount
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  const handleRowClick = (doc) => {
    setShowFileView({ show: true, doc: doc?.original });
    timerRef.current = smoothScrollTo("image_preview_container");
  };

  const handleRowSelect = useCallback((val) => {
    setSelectedRows(val);
  }, []);

  const handleRemoveFiles = useCallback(async () => {
    const fileRefs = selectedRows?.map(({ reference }) => reference);
    try {
      setIsDeleting(true);
      removeFilesAndUpdateApi(
        showFileView?.doc?.reference ? [showFileView?.doc?.reference] : fileRefs
      );
      onRemoveFilesCallback(
        showFileView?.doc?.reference
          ? [showFileView?.doc?.reference]
          : fileRefs,
        showFileView?.doc?.reference ? [showFileView?.doc] : selectedRows
      );
    } catch (error) {
      console.warn(error.message);
    } finally {
      setShowDeleteModal(false);
      setShowFileView({ doc: undefined, show: false });
      setIsDeleting(false);
    }
  }, [
    onRemoveFilesCallback,
    removeFilesAndUpdateApi,
    selectedRows,
    showFileView,
  ]);

  const handleDownloadFiles = useCallback(async () => {
    if (selectedRows?.length > 0) {
      await downloadMedia(selectedRows?.map(({ reference }) => reference));
    }
  }, [selectedRows]);

  const setDeleteMessage = (filesToDelete) =>
    setDeleteModalMessage({
      title: `Delete ${filesToDelete?.length === 1 ? "file" : "files?"}`,
      message: (
        <>
          {`Once deleted, ${
            filesToDelete?.length === 1 ? "it" : "they"
          } cannot be recovered.`}
        </>
      ),
    });

  const onUploadClick = () => {
    document
      .querySelector(
        `div.${resourceName.toLowerCase()}-table-view .upload_area_click`
      )
      ?.click();
  };

  const onFileReset = () => {
    const resource = files.find(
      (file) => file.reference === showFileView?.doc?.reference
    );
    fileDispatch({
      type: "reset",
      resource,
      currentTags: getTagOptions(resource, tagsData?.tagsDict),
    });
  };

  return (
    <div className="flex flex-col h-fit">
      {!showFileView?.show && (
        <PureFilesTable
          fetchDataOnScroll={fetchDataOnScroll}
          hasMoreData={hasMoreData}
          currentUser={currentUser}
          isFileOverview={isFileOverview}
          handleRowSelect={handleRowSelect}
          handleRowClick={handleRowClick}
          resourceName={`${resourceName}-associated-files`}
          data={tableData}
          hideSiteHeader
          hideSaveButton
          hideChangeView
          templateSettings={templateSettings}
          updateUserTemplateSettings={updateUserTemplateSettings}
          deleteUserTemplateSettings={deleteUserTemplateSettings}
          disableHover
          showEditColumns
          allowSelection
          filesView
          showColumnSettingsLeft={!SHOW_COLUMNS_SETTING_RIGHT}
          fileActionsIcons={
            !hideFileActionsIcons?.all && (
              <TableActionsIconsGroup
                file={{}}
                fileData={{}}
                handleShowDelete={() => {
                  setDeleteMessage(selectedRows);
                  setShowDeleteModal(true);
                }}
                downloadFiles={handleDownloadFiles}
                uploadFiles={onUploadClick}
                canDelete={hasDeletePermission && !hideFileActionsIcons?.delete}
                canDownload
                canUpload={hasWritePermission && !hideFileActionsIcons?.upload}
                disabled={selectedRows?.length === 0}
                showColumnSettingsRight={SHOW_COLUMNS_SETTING_RIGHT}
                handleFileClone={handleFileClone}
                onScrollCallback={fetchRecentFiles}
                disableRecentFiles={isFileOverview}
              />
            )
          }
        />
      )}
      {showFileView?.show && (
        <FilePreview
          userDict={userDict}
          file={showFileView?.doc}
          handleBackClick={() =>
            setShowFileView({ doc: undefined, show: false })
          }
          setShowFileView={setShowFileView}
          handleShowDelete={() => {
            setDeleteMessage([showFileView?.doc]);
            setShowDeleteModal(true);
          }}
          canDelete={hasDeletePermission && !hideFileActionsIcons?.delete}
          canEdit={hasEditPermission && !hideFileActionsIcons?.edit}
          fileDispatch={fileDispatch}
          fileForm={fileForm}
          handleSave={() => handleUpdateFile(fileForm)}
          handleCancel={onFileReset}
        />
      )}
      <UploadFile
        id={`${resourceName.toLowerCase()}-table-view`}
        association={association}
        onAddFilesCallback={onAddFilesCallback}
        uploadFileOverwrite={uploadFileOverwrite}
      />
      <Modal
        title={deleteModalMessage?.title}
        isOpen={showDeleteModal}
        primaryButtonTitle="Yes, delete"
        primaryButtonOnClick={handleRemoveFiles}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={() => setShowDeleteModal(false)}
        deleting={isDeleting}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
        alert
      >
        <p className="text-base mb-2">
          <p>Are you sure you want to delete?</p>
          <br />
          {deleteModalMessage?.message}
        </p>
      </Modal>
    </div>
  );
};

FilesTable.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})),
  resourceName: PropTypes.string,
  removeFilesAndUpdateApi: PropTypes.func.isRequired,
  onRemoveFilesCallback: PropTypes.func,
  association: PropTypes.string,
  onAddFilesCallback: PropTypes.func,
  uploadFileOverwrite: PropTypes.func,
  hasDeletePermission: PropTypes.bool,
  hasWritePermission: PropTypes.bool,
  hasEditPermission: PropTypes.bool,
  handleFileClone: PropTypes.func,
  fetchDataOnScroll: PropTypes.func,
  hasMoreData: PropTypes.bool,
  isFileOverview: PropTypes.bool,
  handleUpdateFile: PropTypes.func,
  hideFileActionsIcons: PropTypes.shape({
    all: PropTypes.bool,
    edit: PropTypes.bool,
    delete: PropTypes.bool,
    upload: PropTypes.bool,
    download: PropTypes.bool,
  }),
};

FilesTable.defaultProps = {
  files: [],
  resourceName: undefined,
  onRemoveFilesCallback: () => {},
  association: undefined,
  onAddFilesCallback: () => {},
  uploadFileOverwrite: undefined,
  hasDeletePermission: false,
  hasWritePermission: false,
  hasEditPermission: false,
  handleFileClone: () => {},
  fetchDataOnScroll: undefined,
  hasMoreData: false,
  isFileOverview: false,
  handleUpdateFile: () => {},
  hideFileActionsIcons: {
    all: false,
    edit: false,
    delete: false,
    upload: false,
    download: false,
  },
};

export default FilesTable;

import React from "react";
import { eventColor } from "../../../../../../helpers/Calendar";
import useEventCircleData from "./useEventCircleData";

const EventCellCircle = ({ i, d, place, item }) => {
  const { circleYPosition } = useEventCircleData();
  return (
    <g>
      <circle
        cx={d * 40 + 2.5}
        cy={circleYPosition(i, place)}
        r={1.25}
        fill={eventColor(item)}
      />

      {item?.status === "done" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2" // Adjust the size of the checkmark to fit inside the circle
          height="2"
          fill="white"
          x={d * 40 + 2.5 - 1} // Position the checkmark so that it's centered over the circle
          y={circleYPosition(i, place) - 1.15}
          viewBox="0 0 24 24"
        >
          <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
        </svg>
      )}
    </g>
  );
};

export default EventCellCircle;

const getErrorUserApi = (error) => {
  const errorMessage = error?.response?.data?.issues[0]?.location;

  // TODO This error is coming from Firebase when trying to create an existing users. Handling this in FE in the meantime
  if (errorMessage?.includes("email address is already in use by")) {
    return "Looks like one of the users was registered before. Please contact customer support";
  }

  return "There was an issue sending one or more invitations. Please contact customer support";
};

export default getErrorUserApi;

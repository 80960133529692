import { useCallback, useEffect } from "react";
import { WatchAPI } from "@griffingroupglobal/eslib-api";
import { useAppState } from "../state/appState";
import { SET_WATCHLIST } from "../constants";

export default () => {
  const [{ watchList }, dispatch] = useAppState();

  const reload = useCallback(async () => {
    const { data } = await WatchAPI.get();
    const resources = data.entries.map((e) => e.resource);
    dispatch({
      type: SET_WATCHLIST,
      watchList: resources,
    });
  }, [dispatch]);

  const addToWatchlist = async (id, resourceType) => {
    const body = {
      item: {
        reference: `${resourceType}/${id}`,
      },
    };

    const { data } = await WatchAPI.post(body);
    dispatch({
      type: SET_WATCHLIST,
      watchList: [...watchList, data],
    });
  };

  const removeFromWatchList = async (currentWatch) => {
    await WatchAPI.delete(currentWatch[0]?.id);
    dispatch({
      type: SET_WATCHLIST,
      watchList: watchList.filter(
        (resource) => resource.id !== currentWatch[0]?.id
      ),
    });
  };

  useEffect(() => {
    reload();
  }, [reload]);

  return [watchList, reload, { addToWatchlist, removeFromWatchList }];
};

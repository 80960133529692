import { useCallback } from "react";

const useOwnedByListCreateEditContactData = ({
  creatingUser,
  onCreate,
  onEdit,
  editingState,
  editedUser,
}) => {
  const onEditName = useCallback(() => {
    if (creatingUser) {
      onCreate(editingState);
    } else {
      onEdit(editingState, editedUser?.reference, editedUser?.contactType);
    }
  }, [creatingUser, editedUser, editingState, onCreate, onEdit]);

  return { onEditName };
};

useOwnedByListCreateEditContactData.propTypes = {};

export default useOwnedByListCreateEditContactData;

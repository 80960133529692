import React from "react";
import MemberTable from "../../stories/Components/MemberManagement/MemberTable";

const MemberManagement = ({
  isExportingMemberList,
  setIsExportingMemberList,
}) => {
  return (
    <MemberTable
      setIsExportingMemberList={setIsExportingMemberList}
      isExportingMemberList={isExportingMemberList}
    />
  );
};

export default MemberManagement;

import { useCallback } from "react";

const useSegmentedControl = ({ segments = [], selectedSegment, onSelect }) => {
  /**
   * Pass selected segment to onSelect callback
   */
  const handleSegmentClick = useCallback(
    (event, segment) => {
      event.stopPropagation();

      if (segment.id !== selectedSegment) {
        onSelect(segment.id);
      }
    },
    [selectedSegment, onSelect]
  );

  const getClassname = useCallback(
    (segment, index) => {
      return `
        w-full p-2 border border-darkenedGreen
        ${index === 0 ? "rounded-l-xl" : ""}
        ${index === segments.length - 1 ? "rounded-r-xl" : "border-r-0"}
        ${
          segment.id === selectedSegment
            ? "bg-green-50 text-darkenedGreen"
            : "bg-white text-darkenedGreen"
        }
      `;
    },
    [selectedSegment, segments]
  );

  return {
    getClassname,
    handleSegmentClick,
  };
};

export default useSegmentedControl;

import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import LockIcon from "../../assets/images/greenLock.svg";
import AlertIcon from "../../assets/images/alert.svg";
import IconButton from "../Buttons/IconButton";

const pillCN = (
  className,
  background,
  textSize,
  textWeight,
  textColor,
  border,
  padding
) => cntl`
  flex
  items-center
  justify-center
  ${padding ?? "py-2 px-3"}
  rounded-l-full
  rounded-r-full
  ${background ?? "bg-brandGreen"}
  ${textSize ?? "text-sm"}
  ${textWeight ?? "font-semibold "}
  ${textColor ?? "text-white"}
  ${className}
  ${border}
  cursor-default
`;

const Pill = ({
  value,
  className,
  background,
  textSize,
  textWeight,
  textColor,
  border,
  onClick,
  padding,
  showLock,
  showWarning,
}) => {
  const pillClasses = pillCN(
    className,
    background,
    textSize,
    textWeight,
    textColor,
    border,
    padding
  );

  if (onClick) {
    return (
      <button
        type="button"
        style={{
          padding: 0,
        }}
        onClick={onClick}
      >
        {showLock && (
          <IconButton
            imgClassName="w-5 h-6"
            iconClassName="w-5 h-6"
            icon={LockIcon}
            wrapperClassName="pr-1"
          />
        )}
        {showWarning && (
          <IconButton
            imgClassName="w-5 h-6"
            iconClassName="w-5 h-6"
            icon={AlertIcon}
            wrapperClassName="pr-1"
          />
        )}
        <p className={pillClasses}>{value}</p>
      </button>
    );
  }

  return (
    <p className={pillClasses}>
      {showLock && (
        <IconButton
          imgClassName="w-5 h-6"
          iconClassName="w-5 h-6"
          icon={LockIcon}
          wrapperClassName="pr-1"
        />
      )}
      {showWarning && (
        <IconButton
          imgClassName="w-5 h-6"
          iconClassName="w-5 h-6"
          icon={AlertIcon}
          wrapperClassName="pr-1"
        />
      )}
      {value}
    </p>
  );
};

Pill.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  background: PropTypes.string,
  textSize: PropTypes.string,
  textWeight: PropTypes.string,
  textColor: PropTypes.string,
  border: PropTypes.string,
  onClick: PropTypes.func,
  padding: PropTypes.string,
  showLock: PropTypes.bool,
  showWarning: PropTypes.bool,
};

Pill.defaultProps = {
  className: undefined,
  background: undefined,
  textSize: undefined,
  textWeight: undefined,
  textColor: undefined,
  border: undefined,
  onClick: undefined,
  padding: undefined,
  showLock: false,
  showWarning: false,
};

export default Pill;

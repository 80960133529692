import { useEffect, useState } from "react";
import { useGetTags } from "../../../hooks/useTags";
import { truncateLabel } from "../../../helpers/Tag";

const DEFAULT_TRUNCATE_SIZE = 10;

const useTagViewData = (tagReference, size = DEFAULT_TRUNCATE_SIZE) => {
  // Query hook to get all tags
  const { data } = useGetTags();

  const [label, setLabel] = useState("");

  // Find the tag using the tag reference from dictionary and return the tag's label truncated
  useEffect(() => {
    const foundTag = data?.tagsDict?.[tagReference];

    setLabel(truncateLabel(foundTag?.label, size));
  }, [data?.tagsDict, tagReference, size]);

  return {
    label,
  };
};

export default useTagViewData;

import React from "react";
import PropTypes from "prop-types";

import plusCircleIconBlack from "../../assets/images/plusCircleIconBlack.svg";
import crossIconBlack from "../../assets/images/crossIconBlack.svg";
import crossIconWhite from "../../assets/images/crossIconWhite.svg";
import lockIcon from "../../assets/images/lockIcon.svg";
import useSidebarCustomViewData from "./useSidebarCustomViewData";

const SidebarCustomView = ({
  title,
  active,
  onTitleClick,
  onEnterPress,
  showAddButton,
  showCloseButton,
  onAddClick,
  onCloseClick,
  useBrandCloseButton,
  locked,
  alert,
}) => {
  const { onHover, setOnHover, handleEnter, handleClick } =
    useSidebarCustomViewData({
      onClick: onTitleClick,
      onEnter: onEnterPress,
      alert,
      locked,
    });

  return (
    <div className="flex flex-row w-full items-center justify-between">
      <div
        className={`flex gap-2 ${
          locked ? "text-gray-300" : "text-gray-900"
        } text-sm flex-grow tracking-wider ${
          active ? "font-bold" : "font-medium"
        }`}
        onClick={handleClick}
        onKeyDown={handleEnter}
        tabIndex={0}
        role="button"
      >
        {title}
      </div>
      <div className="flex mr-4">
        {showAddButton && !locked && (
          <button
            onClick={onAddClick}
            type="button"
            className="flex"
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
          >
            <img
              className="w-4 h-4"
              style={{ transform: `${onHover ? "scale(1.3)" : "scale(1)"}` }}
              src={plusCircleIconBlack}
              alt="plus Icon"
            />
          </button>
        )}
        {locked && (
          <img
            className="inline-block w-4 h-4"
            src={lockIcon}
            alt={`locked-${title}-route`}
          />
        )}
        {showCloseButton && (
          <button
            onClick={onCloseClick}
            type="button"
            className="flex mr-5"
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
          >
            {useBrandCloseButton && (
              <p className="text-xs font-semibold text-gray-300">close</p>
            )}
            {!useBrandCloseButton && active && (
              <img
                className="transition-all"
                style={{ transform: `${onHover ? "scale(1.3)" : "scale(1)"}` }}
                src={crossIconWhite}
                alt="close Icon"
              />
            )}
            {!useBrandCloseButton && !active && (
              <img
                className="transition-all"
                src={crossIconBlack}
                style={{ transform: `${onHover ? "scale(1.3)" : "scale(1)"}` }}
                alt="close Icon"
              />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

SidebarCustomView.propTypes = {
  useBrandCloseButton: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onTitleClick: PropTypes.func,
  onEnterPress: PropTypes.func,
  showAddButton: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  onAddClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  active: PropTypes.bool,
  /**
   * Lock sidebar menu item:
   * - Must remove route also if locked to prevent manual navigation
   * - Not the same as permissioning(should remove item from list)
   * - e.g. "has permission to access feature but requires configuration"
   */
  locked: PropTypes.bool,
  /**
   * Alert the user of the action that must be completed before usage
   */
  alert: PropTypes.string,
};

SidebarCustomView.defaultProps = {
  useBrandCloseButton: false,
  title: undefined,
  onTitleClick: undefined,
  onEnterPress: undefined,
  showAddButton: false,
  showCloseButton: false,
  onAddClick: undefined,
  onCloseClick: undefined,
  active: false,
  locked: false,
  alert: undefined,
};

export default SidebarCustomView;

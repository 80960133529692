import React, { useState } from "react";
import PropTypes from "prop-types";
import TertiaryButton from "../Buttons/TertiaryButton";
import Input from "../Input/Input";
import Avatar from "../Message/Avatar";
import PrimaryButton from "../Buttons/PrimaryButton";
import BaseButton from "../Buttons/BaseButton";

const NewComment = ({
  attachIcon,
  onAttachIconClick,
  isMaintenanceComment,
  user,
  handleAddComment,
  handleCancelComment,
  requestData,
}) => {
  const [comment, setComment] = useState("");
  const cancelComment = () => {
    setComment("");
    handleCancelComment();
  };
  const handleSendComment = () => {
    handleAddComment(comment);
  };

  return (
    <div className="ml-4 py-6 bg-white">
      <div className="flex flex-col">
        <div className="flex flex-row mb-4">
          <Avatar
            className="mr-4 rounded-full"
            name={user?.name ?? user?.currentUser?.name}
            avatar={user?.avatar ?? user?.currentUser?.avatar}
            id={
              user?.id ??
              user?.reference ??
              user?.currentUser?.id ??
              user?.currentUser?.reference
            }
            isUser
          />
          <div className="flex flex-col justify-center">
            <h3 className="font-semibold text-gray-400 capitalize">
              {user?.name}
            </h3>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center">
          <Input
            disabled={requestData?.status === "void"}
            attachIcon={attachIcon}
            onAttachIconClick={onAttachIconClick}
            label="New comment"
            value={comment}
            onChange={setComment}
            mainClassName="flex-1 mr-3"
            className="mb-4"
            placeholder="Comment"
            handleEnter={(event) => {
              if (event.key === "Enter") handleSendComment();
            }}
          />
          <PrimaryButton
            disabled={requestData?.status === "void"}
            saveButtonTitle="Post"
            saveButton
            className="mt-2"
            onClick={handleSendComment}
          />
        </div>
        {isMaintenanceComment && handleCancelComment && (
          <BaseButton
            disabled={requestData?.status === "void"}
            title="Cancel"
            className="text-brandGreen"
            innerClassName="mx-0 font-normal"
            onClick={cancelComment}
          />
        )}
        {!isMaintenanceComment && (
          <TertiaryButton
            disabled={requestData?.status === "void"}
            title="Cancel"
            className="mr-2"
            onClick={cancelComment}
          />
        )}
      </div>
    </div>
  );
};

NewComment.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    avatar: PropTypes.string,
    id: PropTypes.string,
    reference: PropTypes.string,
    currentUser: PropTypes.shape({
      name: PropTypes.string,
      avatar: PropTypes.string,
      id: PropTypes.string,
      reference: PropTypes.string,
    }),
  }),
  handleAddComment: PropTypes.func,
  handleCancelComment: PropTypes.func,
  attachIcon: PropTypes.string,
  onAttachIconClick: PropTypes.func,
  isMaintenanceComment: PropTypes.bool,
  requestData: PropTypes.shape({
    status: PropTypes.string,
  }),
};

NewComment.defaultProps = {
  user: {},
  handleAddComment: undefined,
  handleCancelComment: undefined,
  attachIcon: undefined,
  onAttachIconClick: undefined,
  isMaintenanceComment: undefined,
  requestData: undefined,
};

export default NewComment;

import React, { useMemo, useRef, useState } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { isSafari } from "react-device-detect";

import ShareIcon from "../../assets/images/shareIcon.svg";
import deleteGreenIcon from "../../assets/images/deleteGreenIcon.svg";
import PlusCircleButton from "../Buttons/PlusCircleButton/PlusCircleButton";
import Input from "../Input/Input";
import Widget from "../Widget/Widget";
import { validUrlSchema } from "../../../helpers/FormValidations";
import IconButton from "../Buttons/IconButton";
import { handleLinkClick } from "../../../helpers/Utilities";
import { isUrlValid } from "../../../helpers/Formatters";

const inputCN = (browser) => cntl`
  border-t
  border-gray-200
  sticky
  bg-white
  flex
  flex-col
  ${browser ? "flex-wrap" : "items-center justify-between"}
`;

const linkCN = (index) => cntl`
  flex 
  justify-center 
  items-center 
  flex-row
  ${index && "border-t border-gray-200"}
`;

const LinksView = ({
  editing,
  resource,
  dispatch,
  disableEditing,
  widgetWidth,
  loading,
  className,
}) => {
  const [addLinksIsOpen, setAddLinksIsOpen] = useState(false);
  const [linkName, setLinkName] = useState();
  const [linkUrl, setLinkUrl] = useState();
  const inputRef = useRef({});

  const validUrl = useMemo(() => {
    return !!linkUrl?.length && isUrlValid(linkUrl);
  }, [linkUrl]);

  const addLink = () => {
    const link = { id: uuidv4(), name: linkName || linkUrl, url: linkUrl };
    dispatch({
      type: "addLinkCompleteLink",
      link,
    });
  };

  const handleDeleteClick = (link) => {
    dispatch({
      type: "deleteLink",
      linkId: link.id,
    });
  };

  const clearFields = () => {
    setLinkName();
    setLinkUrl();
  };

  const confirmEdit = () => {
    if (validUrl) {
      addLink();
      clearFields();
      inputRef.current?.title?.focus();
    }
  };

  const handleChange = (e) => {
    if (e.key === "Enter") {
      confirmEdit();
    }
  };
  return (
    <Widget
      className={className}
      draggable={false}
      loading={loading}
      footer={
        <>
          {editing && !addLinksIsOpen ? (
            <div
              style={{ bottom: -16 }}
              className="border-t border-gray-200 bg-white"
            >
              <PlusCircleButton
                title="Add Link"
                onClick={() => setAddLinksIsOpen(!addLinksIsOpen)}
                disabled={disableEditing}
                className="flex w-full items-center h-16"
                style={{ color: "#027D61", fontSize: 16 }}
                noHover
              />
            </div>
          ) : null}
          {addLinksIsOpen && editing && (
            <div style={{ bottom: -16 }} className={inputCN(isSafari)}>
              <div className="flex mt-4">
                <Input
                  inputClassName="ESInput w-3/5"
                  className="pr-4"
                  label="Title"
                  name="title"
                  placeholder="Title"
                  // eslint-disable-next-line no-return-assign
                  forwardedRef={(el) => (inputRef.current.title = el)}
                  autoFocus
                  value={linkName}
                  onChange={(val) => setLinkName(val)}
                  disableClear
                />
                <Input
                  inputClassName="ESInput w-3/5"
                  className="mb-1"
                  label="Url"
                  value={linkUrl}
                  placeholder="Url"
                  onChange={(val) => setLinkUrl(val)}
                  // show validation * w/o validation message
                  validation={validUrlSchema()}
                  onKeyDown={(e) => handleChange(e)}
                  disableClear
                />
                <PlusCircleButton
                  onClick={confirmEdit}
                  disabled={disableEditing}
                  className="flex w-1/3 items-center h-16 mt-4"
                  style={{ color: "#027D61", fontSize: 16 }}
                  noHover
                />
              </div>
            </div>
          )}
        </>
      }
      title="Links"
      width={widgetWidth}
    >
      <div className="overflow-auto max-h-72" style={{ minHeight: "100px" }}>
        {!!resource?.links?.length &&
          !loading &&
          resource?.links?.reduce((linkList, link, index) => {
            if (link?.url) {
              linkList.push(
                <div className={linkCN(index)}>
                  <button
                    key={link?.id}
                    type="button"
                    className="flex w-full items-center cursor-pointer select-none"
                    onClick={() => handleLinkClick(link)}
                  >
                    <div className="flex py-6 items-center w-full gap-4">
                      <img src={ShareIcon} alt="link open icon" />
                      <div className="flex w-72">
                        <p className="text-gray-300 underline font-regular truncate">
                          {link?.name || link?.url}
                        </p>
                      </div>
                    </div>
                  </button>
                  <IconButton
                    wrapperClassName={editing ? "pr-2" : "hidden pr-2"}
                    onClick={() => handleDeleteClick(link)}
                    icon={deleteGreenIcon}
                    disabled={disableEditing}
                  />
                </div>
              );
            }
            return linkList;
          }, [])}
      </div>
    </Widget>
  );
};

LinksView.propTypes = {
  editing: PropTypes.bool,
  resource: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    additionalInfo: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  disableEditing: PropTypes.bool,
  dispatch: PropTypes.func,
  widgetWidth: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

LinksView.defaultProps = {
  editing: false,
  resource: undefined,
  disableEditing: false,
  dispatch: () => {},
  widgetWidth: undefined,
  loading: true,
  className: undefined,
};

export default LinksView;

import { PreferenceAPI, UserAPI } from "@griffingroupglobal/eslib-api";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { tagKeys, userKeys } from "../../config/reactQuery/queryKeyFactory";
import { PROFILE_PATH } from "../../constants";
import {
  UserContactData,
  formatCurrentUserEditPayload,
  formatPreferenceUser,
  formatUserPreference,
  getUsedAndUnusedPreferences,
} from "../../helpers/User";
import ContactInfoForm from "../../stories/Components/ContactForm/ContactInfoForm";
import ContactPreferencesForm from "../../stories/Components/ContactForm/ContactPreferencesForm";
import TabbedContainer from "../../stories/Components/TabbedContainer/TabbedContainer";
import whiteCircleCheckIcon from "../../stories/assets/images/circleCheckIcon.svg";
import whiteCrossIcon from "../../stories/assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../stories/assets/images/whiteExclamationIcon.svg";
import useContactReducer from "../../hooks/useContactReducer";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

export default function UserProfileEdit({
  currentUser,
  activeIndex: currentTabIndex,
  setEditing,
  setTabIndex,
  preferences,
  userPreferences,
  userPreferenceOptions,
  setPreferences,
  setUserPreferences,
  setReloadPreferences,
  managementConfiguration,
}) {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [profileUser, setProfileUser] = useState();
  const [details, setDetails] = useState();
  const [info, setInfo] = useState();
  const [infoClone, setInfoClone] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [customPreference, setCustomPreference] = useState();
  const [preference, setPreference] = useState();
  const [preferenceClone, setPreferenceClone] = useState();
  const [activeIndex, setActiveIndex] = useState(+currentTabIndex || 0);
  const [contactResource, contactDispatch] = useContactReducer();

  useEffect(() => {
    const getPreference = async () => {
      const { data } = await PreferenceAPI.get({
        params: {
          user: `User/${currentUser?.id}`,
        },
      });
      const formatted = formatPreferenceUser(data);
      setPreferences(data);
      setUserPreferences(formatted);
    };
    if (currentUser) {
      getPreference();
      contactDispatch({
        type: "setOriginalResource",
        resource: currentUser,
      });
    }
  }, [contactDispatch, currentUser, setPreferences, setUserPreferences]);

  useEffect(() => {
    if (userPreferences && userPreferenceOptions) {
      const {
        usedPreferences: usedFavorites,
        unusedCustomPreferences: unusedCustomFavorites,
      } = getUsedAndUnusedPreferences(
        userPreferenceOptions,
        userPreferences,
        "favorites"
      );

      const {
        usedPreferences: usedDislikes,
        unusedCustomPreferences: unusedCustomDislikes,
      } = getUsedAndUnusedPreferences(
        userPreferenceOptions,
        userPreferences,
        "dislikes"
      );

      const {
        usedPreferences: usedSpecials,
        unusedCustomPreferences: unusedCustomSpecials,
      } = getUsedAndUnusedPreferences(
        userPreferenceOptions,
        userPreferences,
        "specials"
      );

      setPreference({
        favorites: usedFavorites,
        dislikes: usedDislikes,
        specials: usedSpecials,
      });

      setPreferenceClone({
        favorites: usedFavorites,
        dislikes: usedDislikes,
        specials: usedSpecials,
      });

      setCustomPreference({
        favorites: unusedCustomFavorites,
        dislikes: unusedCustomDislikes,
        specials: unusedCustomSpecials,
      });
    }
  }, [userPreferenceOptions, userPreferences]);

  const onSave = useCallback(async () => {
    const patches = [];

    /**
     * updates current user with user detail fields
     * that may have been updated by user
     * including but not limited to: avatar
     */
    const formatPayload = { ...currentUser, ...profileUser };
    const payload = await formatCurrentUserEditPayload(
      formatPayload,
      info,
      contactResource?.currentTags
    );

    /**
     * Checks User Info Against Clone For Conditional Patch
     */
    if (
      !_.isEqual(infoClone, info) ||
      !_.isEqual(currentUser, profileUser) ||
      !_.isEqual(
        contactResource?.currentTags?.map((tag) => tag?.value) || [],
        contactResource?.originalResource?.tags || []
      )
    ) {
      patches.push(() => UserAPI.patch(currentUser.id, payload, currentUser));
    }

    const newPreference = formatUserPreference("", preference);
    const preferenceData = { ...preferences, ...newPreference };

    /**
     * Checks User Preferences Against Clone For Conditional Patch
     */
    if (!_.isEqual(preference, preferenceClone)) {
      patches.push(() =>
        PreferenceAPI.patch(preferences.id, preferenceData, preferences)
      );
    }

    let arrayOfPatches = patches?.map((patch) => patch());

    if (arrayOfPatches?.length > 0) {
      const SavingDelay = () => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 2000);
        });
      };
      arrayOfPatches = [SavingDelay(), ...arrayOfPatches];
      setIsSaving(true);

      /**
       * Toast Initial Loading State
       */
      const savingToast = toast("Saving...", {
        isLoading: true,
        position: "top-center",
      });

      /**
       * Error handling patches with Toast
       */
      Promise.all(arrayOfPatches)
        .then((response) => {
          const userObject = response?.find(
            (item) => item?.data?.resource === "User"
          );
          if (userObject) {
            setProfileUser({
              ...userObject.data,
            });
          }
          toast.update(savingToast, {
            isLoading: false,
            render: "Saved",
            closeButton: toastCloseIcon,
            className: "bg-brandGreen text-white",
            hideProgressBar: true,
            position: "top-center",
            icon: toastIcon,
            autoClose: 3000,
          });
          queryClient.invalidateQueries(userKeys.currentUser());

          // update tags in s&c
          queryClient.invalidateQueries(tagKeys.tags);
          setReloadPreferences(true);
          setIsSaving(false);
          history.replace(PROFILE_PATH(activeIndex));
        })
        .catch(() => {
          toast.update(savingToast, {
            isLoading: false,
            render: "Error Saving",
            style: {
              backgroundColor: "#BC2727",
              color: "white",
            },
            closeButton: toastCloseIcon,
            position: "top-center",
            hideProgressBar: true,
            icon: toastErrorIcon,
            autoClose: 3000,
          });
          setIsSaving(false);
          history.replace(PROFILE_PATH(activeIndex));
        });
    }
  }, [
    currentUser,
    profileUser,
    info,
    infoClone,
    contactResource?.currentTags,
    contactResource?.originalResource?.tags,
    preference,
    preferences,
    preferenceClone,
    queryClient,
    setReloadPreferences,
    history,
    activeIndex,
  ]);

  useEffect(() => {
    if (currentUser) {
      /**
       * separate payloads for header details
       * and details tab
       */
      const curUser = UserContactData.fromUserPayload(currentUser);
      const { user, ...rest } = curUser;

      if (!info && !details) {
        setInfo({ ...rest, gender: user.gender });
        setDetails(user);

        // Clone Objects for conditional saving
        setInfoClone({ ...rest, gender: user.gender });

        setProfileUser({
          ...currentUser,
        });
      }
    }
  }, [currentUser, details, info]);

  const tabs = [
    {
      title: "Details",
      content: ContactInfoForm({
        contactInfo: info,
        setContactInfo: setInfo,
        saving: isSaving,
        onSave,
        isEditing: true,
        setIsEditing: setEditing,
        setTabIndex,
        managementConfiguration,
        contactResource,
        contactDispatch,
      }),
    },

    {
      title: "Preferences",
      content: ContactPreferencesForm({
        contactPreferences: preference,
        setContactPreferences: setPreference,
        customPreference,
        saving: isSaving,
        onSave,
        myProfile: true,
        setIsEditing: setEditing,
        setTabIndex,
      }),
    },
    /**
     * Additional Tabs Here
     */
  ];

  const onTabClick = (index) => {
    setActiveIndex(index);
    setTabIndex(index);
  };

  return (
    <>
      {info && userPreferences && (
        <TabbedContainer
          tabs={tabs}
          activeIndex={activeIndex}
          onTabClick={onTabClick}
        />
      )}
    </>
  );
}

UserProfileEdit.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.string,
  }),
  activeIndex: PropTypes.number,
  setEditing: PropTypes.func,
  setTabIndex: PropTypes.func,
  preferences: PropTypes.shape({ id: PropTypes.string }),
  userPreferences: PropTypes.shape({}),
  userPreferenceOptions: PropTypes.shape({}),
  setPreferences: PropTypes.func,
  setUserPreferences: PropTypes.func,
  setReloadPreferences: PropTypes.func,
  managementConfiguration: PropTypes.shape({}),
};

UserProfileEdit.defaultProps = {
  currentUser: undefined,
  activeIndex: 0,
  setEditing: undefined,
  setTabIndex: undefined,
  preferences: undefined,
  userPreferences: undefined,
  userPreferenceOptions: undefined,
  setPreferences: undefined,
  setUserPreferences: undefined,
  setReloadPreferences: undefined,
  managementConfiguration: undefined,
};

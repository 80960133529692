import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import BaseButton from "../Buttons/BaseButton";
import TertiaryHeader from "../TextHeaders/TertiaryHeader";
import PricingCard from "../PricingCard/PricingCard";

const containerCN = cntl`
  flex
  flex-col
  items-center
`;

const tertiaryButtonCN = (selected, className) => cntl`
  ${selected ? cntl`bg-black` : cntl`bg-white`}
  px-2
  py-0
  rounded-md
  border
  ${className}
`;

const PureSubscriptionPlans = ({
  plans,
  setIsMonthly,
  isMonthly,
  onPlanSelect,
  selectedPlan,
  cardStyle,
  hideTitle,
  activeUsers,
  isSaving,
}) => {
  return (
    <div className={containerCN}>
      {!hideTitle && (
        <div className="flex justify-center">
          <TertiaryHeader className="font-semibold">
            Choose a pricing plan
          </TertiaryHeader>
        </div>
      )}
      <div className="flex justify-center py-4 space-x-1">
        <BaseButton
          className={tertiaryButtonCN(isMonthly)}
          title="MONTHLY"
          colorCN={isMonthly ? "text-white" : "text-black"}
          onClick={() => setIsMonthly(true)}
        />
        <BaseButton
          className={tertiaryButtonCN(!isMonthly, "px-4")}
          title="ANNUAL"
          colorCN={!isMonthly ? "text-white" : "text-black"}
          onClick={() => setIsMonthly(false)}
        />
      </div>
      <div className="flex flex-col md:flex-row justify-center py-4">
        {plans.map((plan) => (
          <PricingCard
            key={plan.planName}
            data={{
              ...plan,
              cost: isMonthly ? plan?.monthlyCost : plan?.annualCost,
              showDiscount: isMonthly,
            }}
            onClick={() => onPlanSelect(plan)}
            isSelected={
              plan.planName === selectedPlan?.planName &&
              ((selectedPlan?.billingCycle === "monthly" && isMonthly) ||
                (selectedPlan?.billingCycle === "annual" && !isMonthly))
            }
            cardStyle={cardStyle}
            activeUsers={activeUsers}
            isMonthly={isMonthly}
            isSaving={isSaving}
            setIsMonthly={setIsMonthly}
          />
        ))}
      </div>
    </div>
  );
};

PureSubscriptionPlans.propTypes = {
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      planName: PropTypes.string,
      userText: PropTypes.string,
      cost: PropTypes.string,
      discount: PropTypes.string,
      features: PropTypes.arrayOf(PropTypes.string),
      showDiscount: PropTypes.bool,
    })
  ),
  setIsMonthly: PropTypes.func,
  isMonthly: PropTypes.bool,
  onPlanSelect: PropTypes.func,
  hideTitle: PropTypes.bool,
  cardStyle: PropTypes.string,
  selectedPlan: PropTypes.shape({
    planName: PropTypes.string,
    billingCycle: PropTypes.string,
  }),
  activeUsers: PropTypes.number,
  isSaving: PropTypes.bool,
};

PureSubscriptionPlans.defaultProps = {
  plans: [],
  setIsMonthly: undefined,
  isMonthly: false,
  onPlanSelect: undefined,
  hideTitle: false,
  cardStyle: undefined,
  selectedPlan: undefined,
  activeUsers: undefined,
  isSaving: false,
};

export default PureSubscriptionPlans;

import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import BaseButton from "./BaseButton";
import CancelButton from "./CancelButton";
import TrashButton from "./TrashButton";

const tertiaryButtonCN = (className, onHover) => cntl`
  h-10
  px-4
  py-2
  rounded-lg
  text-gray-400
  transition-all
  duration-200
${className}
  ${
    onHover
      ? `hover:bg-primaryGreen hover:text-white hover:border hover:border-primaryGreen`
      : "hover:text-primaryGreen"
  }
`;

const formCancelButtonCN = (className, onHover) => cntl`
  ${className}
  h-10
  rounded-lg
  text-gray-400
  transition-all
  duration-200  
  border-4
  border-borderGreen
  ${
    onHover
      ? "hover:bg-primaryGreen hover:text-white hover:border-4 hover:border-primaryGreen"
      : "hover:text-primaryGreen"
  }
`;

const secondaryPurpleCN = cntl`
  h-10
  text-brandPurple
  px-4
  py-2
  rounded
  transition-all
  duration-200
  hover:bg-hoverPurple
  hover:text-white
  hover:border
  hover:border-hoverPurple
`;

const TertiaryButton = ({
  tableIconsCancelButton,
  cancelButton,
  trashCan,
  className,
  purple,
  formCancelButton,
  onHover,
  ...props
}) => {
  const button = useMemo(() => {
    switch (true) {
      case tableIconsCancelButton || cancelButton:
        return (
          <CancelButton
            tableIconsCancelButton={tableIconsCancelButton}
            className={className}
            {...props}
          />
        );
      case trashCan:
        return <TrashButton className={className} {...props} />;
      case purple:
        return (
          <BaseButton
            style={{ minWidth: 120 }}
            className={secondaryPurpleCN}
            {...props}
          />
        );
      case formCancelButton:
        return (
          <BaseButton
            className={formCancelButtonCN(className, onHover)}
            {...props}
          />
        );
      default:
        return (
          <BaseButton
            style={{ minWidth: 120 }}
            className={tertiaryButtonCN(className, onHover)}
            {...props}
          />
        );
    }
  }, [
    tableIconsCancelButton,
    cancelButton,
    className,
    props,
    trashCan,
    purple,
    formCancelButton,
    onHover,
  ]);
  return <>{button}</>;
};

TertiaryButton.propTypes = {
  /**
   * any CSS classes to apply to the container
   */
  className: PropTypes.string,
  cancelButton: PropTypes.bool,
  onHover: PropTypes.bool,
  trashCan: PropTypes.bool,
  /**
   * Consistency w/ brand purple prompts
   */
  purple: PropTypes.bool,
  formCancelButton: PropTypes.bool,
  tableIconsCancelButton: PropTypes.bool,
};

TertiaryButton.defaultProps = {
  className: null,
  cancelButton: false,
  onHover: true,
  trashCan: false,
  purple: false,
  formCancelButton: false,
  tableIconsCancelButton: false,
};

export default TertiaryButton;

import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import useReportDelete from "../../../hooks/useReportDelete";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useTemplatesConfiguration from "../../../hooks/useTemplatesConfiguration.new";
import useTemplatesConfigurationPatch from "../../../hooks/useTemplatesConfigurationPatch";
import useTemplatesConfigurationDelete from "../../../hooks/useTemplatesConfigurationDelete";
import { getSingleResourcePath } from "../../../helpers/Navigation";

const useReportsTableData = ({
  association,
  reports = [],
  onReportClicked,
}) => {
  const history = useHistory();

  const { data: currentUser } = useCurrentUser();
  // Query hook to select request templates
  const { data: templateConfiguration } = useTemplatesConfiguration();

  // State to hold ids of selected rows
  const [selectedRows, setSelectedRows] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [commentsData, setCommentsData] = useState({
    isOpen: false,
    reportReference: undefined,
  });

  // Mutation hooks
  const { mutate: patchTemplates } = useTemplatesConfigurationPatch();
  const { mutate: deleteTemplate } = useTemplatesConfigurationDelete();
  const { mutate: deleteReports } = useReportDelete();

  // Check if current user can write and delete reports
  const canDelete = !!currentUser?.permissions?.report?.can_delete;
  const canWrite = !!currentUser?.permissions?.report?.can_write;
  // Set templates for reports (Quick View)
  const reportTemplates = templateConfiguration?.templates?.report ?? [];

  const totalSelectedRows = selectedRows.length;

  /**
   * Set reports ids for selected rows in state
   */
  const handleSelectRows = useCallback((rows) => {
    setSelectedRows(rows.map(({ id }) => id));
  }, []);

  /**
   * Deletes selected reports
   */
  const handleDeleteReports = useCallback(() => {
    deleteReports(selectedRows);
    setShowDeleteDialog(false);
  }, [selectedRows, deleteReports]);

  /**
   * Open comments with report reference
   */
  const handleOpenComments = useCallback((reportReference) => {
    setCommentsData({ reportReference, isOpen: true });
  }, []);

  const handleCloseComments = useCallback(() => {
    setCommentsData({ reportReference: undefined, isOpen: false });
  }, []);

  /**
   * Navigates to single report view (when inside P/P/A tab) or page (regular navigation)
   */
  const handleReportSelected = (index) => {
    if (association) {
      const reportId = reports[index]?.id;
      onReportClicked(reportId);
    } else {
      const reportRef = reports[index]?.reference;
      const path = getSingleResourcePath(reportRef);
      history.push(path);
    }
  };

  const handleSaveTemplates = useCallback(
    (template) => {
      patchTemplates({
        resource: "report",
        updatedTemplates: [
          ...template.map((view) => ({
            ...view,
            custom: true,
          })),
        ],
      });
    },
    [patchTemplates]
  );

  const handleDeleteTemplate = useCallback(
    async (template) => {
      deleteTemplate({
        resource: "report",
        id: template.id,
      });
    },
    [deleteTemplate]
  );

  return {
    canWrite,
    canDelete,
    reports,
    selectedRows,
    reportTemplates,
    totalSelectedRows,
    showDeleteDialog,
    commentsData,
    setShowDeleteDialog,
    handleDeleteReports,
    handleSelectRows,
    handleReportSelected,
    handleSaveTemplates,
    handleDeleteTemplate,
    handleOpenComments,
    handleCloseComments,
  };
};

export default useReportsTableData;

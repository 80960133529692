/**
 * Extracts the required parameters from the form object
 * To be used with the TimesheetAPI.postWOP endpoint
 * @function
 * @param {Object} form - The form object
 * @throws {Error} - If the form is missing required fields or if the fields are of invalid type
 */

const extractParamsFrom = (form) => {
  if (!form.selectedEmployee || !form.type || !form.hours) {
    throw new Error("Missing required fields in the form");
  }

  if (
    typeof form.selectedEmployee.value !== "string" ||
    typeof form.type.value !== "string" ||
    typeof form.hours !== "number"
  ) {
    throw new Error("Invalid form types");
  }

  return {
    requestBody: {
      user: [form.selectedEmployee.value],
      note: form.notes || "",
      award: [
        {
          type: form.type.value,
          amount: form.hours,
        },
      ],
    },
  };
};

export default extractParamsFrom;

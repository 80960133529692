import React from "react";
import placeholder from "../../../assets/images/placeholderImage.png";

const VideoPreview = ({ fileUrl }) => {
  return (
    <div className="relative">
      <video
        autoPlay={false}
        muted
        controls={false}
        poster={placeholder}
        className="rounded-md h-full w-full"
        style={{ objectFit: "cover" }}
      >
        <source src={`${fileUrl}#t=0.5`} />
      </video>

      <div className="absolute inset-0 flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="rgba(255, 255, 255, 0.5)"
        >
          <path d="M6 6h38v38H6z" fill="none" />
          <path d="M6 6h38v38H6z" fill="none" />
          <path d="M18 12L18 38 43 25z" />
        </svg>
      </div>
    </div>
  );
};

export default VideoPreview;

import { useCallback, useState } from "react";
import Api from "@griffingroupglobal/eslib-api";

import useCurrentUser from "../../hooks/useCurrentUser";
import { toastError, toastMessage } from "../../stories/Components/Toast/Toast";

const useConactUsData = () => {
  const { data: currentUser } = useCurrentUser();
  const [input, setInput] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [doneSending, setDoneSending] = useState(true);

  const onChangeMessage = useCallback((msg) => {
    setInput(msg);
  }, []);

  const sendMessage = useCallback(async () => {
    if (input) {
      try {
        setIsSending(true);
        await Api.post("/api/Support", {
          message: input,
        });
        setInput("");
        setDoneSending(false);
        toastMessage("Your message was succesfully sent");
      } catch (error) {
        toastError("There was an error sending your message.");
      }
    }
  }, [input]);

  return {
    currentUser,
    onChangeMessage,
    input,
    sendMessage,
    isSending,
    doneSending,
    setDoneSending,
  };
};

export default useConactUsData;

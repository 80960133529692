import React from "react";
import PropTypes from "prop-types";
import Modal from "../../Modal/Modal";
import SubmittalTemplateForm from "./SubmittalTemplateForm";

const SubmittalTemplateModal = ({
  isModalOpen,
  handleCloseModal,
  memberOptions,
  handleCreateNewTemplate,
  templateForm,
  workflow,
  dispatch,
  isEditing,
  title,
  handleAddNewStep,
  handleDeleteStep,
  currentTemplate,
  isSaving,
}) => {
  return (
    <Modal
      title={title}
      isOpen={isModalOpen}
      hideFooter
      expandable
      primaryButtonTitle="Save"
      primaryButtonOnClick={handleCreateNewTemplate}
      onRequestModalClose={handleCloseModal}
      disabled={!templateForm?.customName || isSaving}
      modalAction="Template creation"
      showConfirm
      trashCan
    >
      <SubmittalTemplateForm
        templateName={templateForm?.customName}
        isDefaultTemplate={templateForm?.isDefaultTemplate}
        workflow={workflow}
        dispatch={dispatch}
        memberOptions={memberOptions}
        handleDeleteStep={handleDeleteStep}
        handleAddNewStep={handleAddNewStep}
        isEditing={isEditing}
        currentTemplate={currentTemplate}
      />
    </Modal>
  );
};

SubmittalTemplateModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  memberOptions: PropTypes.shape({}),
  handleCreateNewTemplate: PropTypes.func,
  templateForm: PropTypes.shape({
    customName: PropTypes.string,
    isDefaultTemplate: PropTypes.bool,
    requestWorkflow: PropTypes.arrayOf({}),
  }),
  dispatch: PropTypes.func,
  workflow: PropTypes.shape({ steps: PropTypes.shape() }),
  isEditing: PropTypes.bool,
  title: PropTypes.string,
  handleAddNewStep: PropTypes.func,
  handleDeleteStep: PropTypes.func,
  currentTemplate: PropTypes.shape({}),
  isSaving: PropTypes.bool,
};
SubmittalTemplateModal.defaultProps = {
  isModalOpen: false,
  handleCloseModal: undefined,
  memberOptions: undefined,
  handleCreateNewTemplate: undefined,
  templateForm: undefined,
  dispatch: undefined,
  workflow: undefined,
  isEditing: false,
  title: "",
  handleAddNewStep: undefined,
  handleDeleteStep: undefined,
  currentTemplate: undefined,
  isSaving: false,
};

export default SubmittalTemplateModal;

import React from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { getSingleResourcePath } from "../../../helpers/Navigation";

/**
 * @param {associationName} String name for the associated resource / title for the link
 * @param {associationRef} String ref string of association
 * @returns Text Link of the association Name with onclick to the association
 */
const AssociationLink = ({ associationName, associationRef }) => {
  const history = useHistory();

  const navigateToAssociation = () => {
    const path = getSingleResourcePath(associationRef);
    if (path) {
      history.push(path);
    }
  };

  return (
    <button type="button" onClick={() => navigateToAssociation()}>
      <p className="text-es-green underline text-es-normal font-es-normal">
        {associationName}
      </p>
    </button>
  );
};

AssociationLink.defaultProps = {
  associationRef: PropTypes.string,
  associationName: PropTypes.string,
};

AssociationLink.propTypes = {
  associationRef: undefined,
  associationName: undefined,
};

export default AssociationLink;

import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import ChartOfAccountsAddCodePopover from "./ChartOfAccountsAddCodePopover";
import ChartOfAccountsGetStarted from "./ChartOfAccountsGetStarted";
import ChartOfAccountsViewCode from "./ChartOfAccountsViewCode";
import BaseButton from "../Buttons/BaseButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import help from "../../assets/images/Help.svg";
import ChartOfAccountsPopOver from "./ChartOfAccountsPopOver";
import { COA_ACCOUNT_TYPES } from "../../../constants";

const buttonCN = (isDisabled) => cntl`
  disabled:bg-gray-100
  disabled:text-gray-200
  rounded-md
  text-base
  ${!isDisabled ? "border border-brandGreen" : "border-0"}
`;

const tertiaryButtonCN = cntl`
  text-brandGreen
  py-4
  text-base
`;

const popoverCN = cntl`
  px-2
  py-2.5
  rounded
  border
  border-gray-300
  z-50
  absolute
  bg-white
  left-40
`;

const initialState = {
  code: "",
  description: "",
  isOpen: false,
  selectedForBudgets: true,
  selectedForTimesheets: true,
  isOpenInProjectConfig: false,
  subCodes: [],
  accountType: COA_ACCOUNT_TYPES[0].value,
  codeType: COA_ACCOUNT_TYPES[0].type,
};

const subCodeInitialState = {
  code: "",
  description: "",
  selectedForBudgets: true,
  selectedForTimesheets: true,
};

const ChartOfAccountsSection = ({
  onChartOfAccountFileUpload,
  financialsSettings,
  dispatchFinancialsSettings,
}) => {
  const [showAddCodePopover, setShowAddCodePopover] = useState(false);
  const [showFinCodesPopover, setShowFinCodesPopover] = useState(false);
  const [newChartOfAccountCode, setNewChartOfAccountCode] =
    useState(initialState);

  const [newChartOfAccountSubCode, setNewChartOfAccountSubCode] =
    useState(subCodeInitialState);

  const [isExpandAllDisabled, setIsExpandAllDisabled] = useState(false);
  const [isCollapseAllDisabled, setIsCollapseAllDisabled] = useState(false);

  useEffect(() => {
    if (financialsSettings?.chartOfAccounts?.length) {
      const isAnyOneCodeCollapsed = financialsSettings?.chartOfAccounts?.some(
        (coa) => !coa.isOpen
      );
      setIsExpandAllDisabled(!isAnyOneCodeCollapsed);
      const isAnyOneCodeExpanded = financialsSettings?.chartOfAccounts?.some(
        (coa) => coa.isOpen
      );
      setIsCollapseAllDisabled(!isAnyOneCodeExpanded);
    }
  }, [financialsSettings]);

  const toggleChartOfAccounts = useCallback(
    (index) => {
      dispatchFinancialsSettings({
        type: "toggleCOACode",
        index,
      });
    },
    [dispatchFinancialsSettings]
  );

  const onFinishAdding = useCallback(
    (addAnother) => {
      dispatchFinancialsSettings({
        type: "addCOACode",
        value: newChartOfAccountCode,
      });
      setNewChartOfAccountCode(initialState);
      setShowAddCodePopover(addAnother);
    },
    [dispatchFinancialsSettings, newChartOfAccountCode]
  );

  const onFinishAddingSubCode = useCallback(
    (index) => {
      dispatchFinancialsSettings({
        type: "addCOASubCode",
        index,
        value: newChartOfAccountSubCode,
      });

      setNewChartOfAccountCode(initialState);
      setNewChartOfAccountSubCode(subCodeInitialState);
      setShowAddCodePopover(false);
    },
    [dispatchFinancialsSettings, newChartOfAccountSubCode]
  );

  const onAdd = useCallback(() => {
    setShowAddCodePopover(true);
  }, []);

  const onGetStarted = useCallback(() => {
    onAdd();
  }, [onAdd]);

  const expandAllCOACodes = useCallback(() => {
    dispatchFinancialsSettings({
      type: "expandAllCOACodes",
    });
  }, [dispatchFinancialsSettings]);

  const collapseAllCOACodes = useCallback(() => {
    dispatchFinancialsSettings({
      type: "collapseAllCOACodes",
    });
  }, [dispatchFinancialsSettings]);

  return (
    <>
      {(financialsSettings?.chartOfAccounts?.length && (
        <div id="ChartOfAccountsSection">
          <div className="flex justify-end pb-2">
            <TertiaryButton
              title="Expand All"
              onClick={expandAllCOACodes}
              disabled={isExpandAllDisabled}
              className={buttonCN(isExpandAllDisabled)}
            />
            <div className="pl-2">
              <TertiaryButton
                title="Collapse All"
                onClick={collapseAllCOACodes}
                disabled={isCollapseAllDisabled}
                className={buttonCN(isCollapseAllDisabled)}
              />
            </div>
          </div>
          <div className="flex border-b border-gray-150 py-2 justify-between">
            <div className="flex flex-row">
              <div className="flex flex-row w-52 pl-4">
                <h3 className="font-semibold text-black uppercase">
                  Account Code
                </h3>

                <BaseButton
                  iconLeft={
                    <img src={help} alt="warning" className="mr-2 w-5 h-5" />
                  }
                  className="px-2"
                  onClick={() => setShowFinCodesPopover(true)}
                />
                {showFinCodesPopover && (
                  <div className={popoverCN}>
                    <ChartOfAccountsPopOver
                      onClose={() => setShowFinCodesPopover(false)}
                    />
                  </div>
                )}
              </div>
              <div className="pl-4 w-60 pr-4">
                <h3 className="font-semibold text-black uppercase">
                  Description
                </h3>
              </div>
              <div className="pl-4">
                <h3 className="font-semibold text-black uppercase">
                  Account code type
                </h3>
              </div>
            </div>

            <div className="">
              <BaseButton
                title="+ Add Account Code"
                onClick={onAdd}
                className="text-brandGreen text-base"
                innerClassName="mx-0"
              />
            </div>
          </div>
          {financialsSettings?.chartOfAccounts?.map(
            (chartOfAccountCode, index) => {
              return (
                <ChartOfAccountsViewCode
                  key={chartOfAccountCode.code}
                  index={index}
                  chartOfAccountCode={chartOfAccountCode}
                  toggleChartOfAccounts={toggleChartOfAccounts}
                  newChartOfAccountSubCode={newChartOfAccountSubCode}
                  setNewChartOfAccountSubCode={setNewChartOfAccountSubCode}
                  onFinishAddingSubCode={onFinishAddingSubCode}
                  dispatchFinancialsSettings={dispatchFinancialsSettings}
                />
              );
            }
          )}
          {!showAddCodePopover && (
            <div>
              <BaseButton
                title="+ Add Account Code"
                onClick={onAdd}
                className={tertiaryButtonCN}
                innerClassName="mx-0"
              />
            </div>
          )}
        </div>
      )) ||
        (!showAddCodePopover && (
          <ChartOfAccountsGetStarted
            onGetStarted={onGetStarted}
            onCSVUpload={onChartOfAccountFileUpload}
          />
        ))}
      {showAddCodePopover && (
        <ChartOfAccountsAddCodePopover
          chartOfAccounts={financialsSettings?.chartOfAccounts}
          newChartOfAccountCode={newChartOfAccountCode}
          setNewChartOfAccountCode={setNewChartOfAccountCode}
          onFinishAdding={onFinishAdding}
          setShowAddCodePopover={setShowAddCodePopover}
        />
      )}
    </>
  );
};

ChartOfAccountsSection.propTypes = {
  financialsSettings: PropTypes.shape({
    chartOfAccounts: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        description: PropTypes.string,
        isOpen: PropTypes.bool,
        selectedForBudgets: PropTypes.bool,
        isOpenInProjectConfig: PropTypes.bool,
        codeType: PropTypes.string,
        accountType: PropTypes.string,
        subCodes: PropTypes.arrayOf(
          PropTypes.shape({
            code: PropTypes.string,
            description: PropTypes.string,
            selectedForBudgets: PropTypes.bool,
          })
        ),
      })
    ),
  }),
  dispatchFinancialsSettings: PropTypes.func,
  onChartOfAccountFileUpload: PropTypes.func,
};

ChartOfAccountsSection.defaultProps = {
  financialsSettings: [],
  dispatchFinancialsSettings: undefined,
  onChartOfAccountFileUpload: undefined,
};

export default ChartOfAccountsSection;

import moment from "moment";
import { useCallback, useRef, useState } from "react";

const useProjectDurationData = ({
  project,
  onChange,
  onChangeObject,
  durationTypeOptions,
}) => {
  const wrapperRef = useRef(null);
  const [invalidChar, setInvalidChar] = useState(false);

  /**
   *
   * @param {Number} value - Amount of Days, Months, or Years
   * @param {String} type - Measurement (See Above Line)
   * @param {String} currentStartDate - Current ISO String Start Date
   */

  const updateEndDate = useCallback(
    (value, type, selectedStart = null) => {
      let localType = type;
      /**
       * If no measurement is selected default to Days
       */
      if (!project?.duration?.typeOfDuration) {
        onChangeObject("duration", "typeOfDuration", "calendarDay");
      }

      /**
       * If amount and measurement are supplied calculate end date
       */
      if (value >= 0 && localType) {
        localType = localType === "calendarDay" ? "day" : localType;
        let startDate = moment();
        /**
         * If !startDate set as contract date ||
         * todays date
         */
        if (selectedStart) {
          startDate = moment(selectedStart);
        } else if (project.startDate) {
          startDate = moment(project.startDate);
        } else {
          onChange(
            "startDate",
            project.contractDate ?? startDate.toISOString()
          );
        }
        const newDate = startDate.add(value, localType);
        onChange("endDate", newDate.toISOString());
      }
    },
    [
      onChangeObject,
      onChange,
      project.startDate,
      project?.duration,
      project.contractDate,
    ]
  );

  const startChangeHandler = useCallback(
    (val) => {
      onChange("startDate", val);
      if (project?.duration?.value >= 0) {
        updateEndDate(
          project?.duration?.value,
          project?.duration?.typeOfDuration || durationTypeOptions[0]?.value,
          val
        );
      }
    },
    [onChange, updateEndDate, project?.duration, durationTypeOptions]
  );

  const endChangeHandler = (val) => {
    onChange("endDate", val);
  };

  const dateChangeHandler = (val) => {
    onChange("contractDate", val);
  };

  const durationTypeChangeHandler = useCallback(
    (val) => {
      onChangeObject("duration", "typeOfDuration", val.value);
      updateEndDate(project?.duration?.value, val.value, project?.startDate);
    },
    [updateEndDate, onChangeObject, project?.duration, project?.startDate]
  );

  const handleKeyDown = useCallback((evt) => {
    // do not accept "-" and "." (negative and decimal keys)
    if (evt.keyCode === 189 || evt.keyCode === 190) {
      setInvalidChar(true);
    }
  }, []);

  const durationChangeHandler = useCallback(
    (val) => {
      if (!invalidChar) {
        onChangeObject("duration", "value", val);
        updateEndDate(
          val,
          project?.duration?.typeOfDuration || durationTypeOptions[0]?.value,
          project?.startDate
        );
      }
      setInvalidChar(false);
    },
    [
      invalidChar,
      durationTypeOptions,
      onChangeObject,
      project?.duration?.typeOfDuration,
      project?.startDate,
      updateEndDate,
    ]
  );

  return {
    wrapperRef,
    handleKeyDown,
    startChangeHandler,
    endChangeHandler,
    dateChangeHandler,
    durationTypeChangeHandler,
    durationChangeHandler,
  };
};
export default useProjectDurationData;

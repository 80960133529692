import capitalizeFirstLetter from "../Utilities/capitalizeFirstLetter";

const { RRule } = require("rrule");
const moment = require("moment");

const describeRruleFromString = (rruleString, dropStartDate) => {
  if (!rruleString) return "";

  try {
    // Check if DTSTART exists in the rruleString
    if (rruleString.includes("DTSTART")) {
      // Splitting the input to get DTSTART and the RRULE separately
      const [dtstartStr, rruleStr] = rruleString.split("\n");

      // Extracting the date from DTSTART
      const startDate = dtstartStr.split(":")[1];

      // Use moment to parse and format the date
      const formattedDate = moment(startDate).format("MMM DD yyyy");

      // Create RRule instance from the RRULE string
      const rule = RRule.fromString(rruleStr);

      if (dropStartDate) {
        // Return human-readable text without start date
        return capitalizeFirstLetter(`${rule.toText()}`);
      }
      // Return human-readable text with included start date
      return capitalizeFirstLetter(
        `${rule.toText()}, starting on ${formattedDate}`
      );
    }

    if (!rruleString.includes("DTSTART")) {
      // If no DTSTART, simply convert rrule to text
      const rule = RRule.fromString(rruleString);
      return capitalizeFirstLetter(rule.toText());
    }
  } catch (error) {
    // Handle any parsing or conversion errors
    console.error("Error describing rrule:", error);
    return "";
  }

  return "";
};

export default describeRruleFromString;

import { UserAPI } from "@griffingroupglobal/eslib-api";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { toastError } from "../stories/Components/Toast/Toast";
import { userKeys } from "../config/reactQuery/queryKeyFactory";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { useAppState } from "../state/appState";
import {
  SET_COMPANIES,
  SET_EMPLOYEES,
  SET_USERS,
  SET_USERS_HOOK_STATE,
  SET_USER_DICT,
  USERS_HOOK_STATE,
} from "../constants";

const fetchUsers = (dispatch) => async () => {
  const response = await UserAPI.get({
    params: { showDeleted: undefined },
  });

  const userDict = {};
  const employees = [];
  const companies = [];
  const users = response?.data?.entries
    .map((item) => {
      if (item.resource?.name?.firstName || item.resource.kind === "company") {
        userDict[item.resource.reference] = item.resource;
      }
      if (item.resource?.kind === "company") {
        companies.push(item.resource);
      }
      if (item.resource?.isEmployee) {
        employees.push(item.resource);
      }
      return item.resource;
    })
    .sort((a, b) =>
      a.resource?.name?.firstName < b.resource?.name?.firstName ? -1 : 1
    );

  // Update users in AppState: We want to get rid off users in global app state. However there are a lot components
  // that relies on `users` and `userDict` from useAppState. This is only intended to support those components.

  /**
    Why am I dispatching to state from here and not from the onSuccessCallback?
    
    `onSuccess` in useQuery hooks are going to be fire based on the # of observers
    so, if this hook is observed by 4 components, then `onSuccess` will run 4 times.
    If you use `selectors` the same will happens.
   */

  dispatch({
    type: SET_USERS,
    users,
  });
  dispatch({
    type: SET_USER_DICT,
    userDict,
  });
  dispatch({
    type: SET_EMPLOYEES,
    employees,
  });
  dispatch({
    type: SET_COMPANIES,
    companies,
  });
  dispatch({
    type: SET_USERS_HOOK_STATE,
    value: USERS_HOOK_STATE.loaded,
  });

  return {
    users,
    userDict,
    employees,
    companies,
  };
};

/**
 * Prefetch users
 */
const useUsersPrefetch = () => {
  const queryClient = useQueryClient();
  const [, dispatch] = useAppState();

  useEffect(() => {
    queryClient.prefetchQuery(userKeys.users, fetchUsers(dispatch));
  }, [queryClient, dispatch]);
};

/**
 * Query for list of users
 * @returns list of users
 */
const useUsers = (select) => {
  const [, dispatch] = useAppState();

  const queryKey = userKeys.users;
  return useAuthenticatedQuery({
    queryKey,
    queryFn: fetchUsers(dispatch),
    select,
    onError: () => {
      toastError("Unable to load Users");
    },
  });
};

export { useUsers, useUsersPrefetch };

// TODO (Move useMembers, useCompanyMembers to presenter hooks)

/**
 * Query selector to return users based on user.kind
 * @param {String} kind "member", "nonmember", "company"
 * @returns list of users
 */
export const useMembers = (type) => {
  const handleSelect = ({ users }) =>
    users?.filter(({ kind }) => kind === type);

  return useUsers(handleSelect);
};

/**
 * Query selector to find a list of users based on the company's ref
 * @param {String} ref User reference
 * @returns query hook with User array
 */
export const useCompanyMembers = (ref) => {
  const handleSelect = ({ users }) =>
    users.filter((user) => user.company?.value === ref);

  return useUsers(handleSelect);
};

import React from "react";
import PropTypes from "prop-types";
import MemberCard from "./MemberCard";
import useMembersRowData from "./useMembersRowData";
import { ADMIN_ROLE_NAME } from "../../../constants";

const MembersRow = ({
  currentMembers = [],
  onClick,
  handleRemove,
  resource,
  isEditing,
  disableRemoveAdmins,
}) => {
  const { sortedMembers } = useMembersRowData({ currentMembers });

  return (
    <div className="flex flex-wrap gap-x-1 border rounded-md w-full h-fit pl-3 py-2 overflow-y-scroll">
      {sortedMembers?.map((item, index) => {
        return (
          <MemberCard
            key={item?.id}
            isEditing={isEditing}
            onClick={onClick}
            handleRemove={handleRemove}
            user={item}
            index={index}
            disableRemove={
              disableRemoveAdmins && item?.roleName === ADMIN_ROLE_NAME
            }
          />
        );
      })}

      {currentMembers.length === 0 && (
        <p className="w-full text-center text-gray-200">{`No ${resource}`}</p>
      )}
    </div>
  );
};

MembersRow.propTypes = {
  currentMembers: PropTypes.arrayOf(PropTypes.shape([])),
  onClick: PropTypes.func,
  handleRemove: PropTypes.func,
  isEditing: PropTypes.bool,
  resource: PropTypes.string,
  disableRemoveAdmins: PropTypes.bool,
};

MembersRow.defaultProps = {
  currentMembers: [],
  onClick: () => {},
  handleRemove: () => {},
  isEditing: undefined,
  resource: "[Resource]",
  disableRemoveAdmins: false,
};

export default MembersRow;

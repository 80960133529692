/**
 * Formats assets categories and subcategories data for DropdownWithPills component.
 * @param {Array} assetsCategories - Array of assets categories.
 * @returns {Object} - Formatted data with 'categories' and 'subCategories'.
 */
const formatAssetsCategoriesDropdownData = (assetsCategories = []) => {
  const formattedData = {
    categories: [],
    subCategories: [],
    categoriesNameMap: {},
  };

  assetsCategories.forEach(({ id, display, subcategories }) => {
    // Create map of category IDs to their display names
    formattedData.categoriesNameMap[id] = display;

    // Format category
    const category = {
      id,
      label: display,
      value: { id },
    };

    formattedData.categories.push(category);

    // Format subcategories
    const subCategoryData = {
      id,
      suboptions: subcategories.map((subcategory) => ({
        label: subcategory.display,
        value: subcategory.id,
      })),
    };

    formattedData.subCategories.push(subCategoryData);
  });

  return formattedData;
};

export default formatAssetsCategoriesDropdownData;

import React from "react";
import PropTypes from "prop-types";
import SiteHeader from "../../../stories/Components/SiteHeader/SiteHeader";
import HistoryCard from "./HistoryCard";

const History = ({ history, title, currentUser, updateComment, period }) => {
  return (
    <div>
      <SiteHeader title={`${title} History`} />
      <>
        {history?.length > 0 ? (
          <>
            {history?.map((item, index) => {
              // List from past - present
              // Returns inverse order
              const length = history?.length - 1;
              return (
                <HistoryCard
                  key={item?.year}
                  data={history[length - index]}
                  index={length - index}
                  defaultOpen={index === 0}
                  currentUser={currentUser}
                  updateComment={updateComment}
                  period={period}
                />
              );
            })}
          </>
        ) : (
          <div className="w-full items-center text-center font-semibold text-gray-200 mt-4">
            No History
          </div>
        )}
      </>
    </div>
  );
};

History.propTypes = {
  history: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  currentUser: PropTypes.shape({}),
  updateComment: PropTypes.func,
  period: PropTypes.string,
};

History.defaultProps = {
  history: undefined,
  title: "",
  currentUser: undefined,
  updateComment: undefined,
  period: "",
};

export default History;

import { v4 as uuidv4 } from "uuid";
import { cloneDeep, isEqual } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { UserAPI } from "@griffingroupglobal/eslib-api";
import {
  COMPANY_EMPTY,
  CONTACT_EMPTY,
  INVITE_CONTACT_POPUP,
  TOGGLE_POSITIONED_POPUP,
} from "../../../../constants";
import { useAppState } from "../../../../state/appState";
import { useUsers } from "../../../../hooks/useUsers.new";
import useContactReducer from "../../../../hooks/useContactReducer";
import useSystemConfiguration from "../../../../hooks/useSystemConfiguration";
import useUserPost from "../../../../hooks/useUserPost";
import useRoles from "../../../../hooks/useRoles";
import useDirectReports from "../../../../hooks/useDirectReports";
import useSubscription from "../../../../hooks/useSubscription";
import {
  formatSelectUser,
  yupEmailRegExp,
} from "../../../../helpers/Formatters";
import { userKeys } from "../../../../config/reactQuery/queryKeyFactory";
import { toastError, toastMessage } from "../../Toast/Toast";

const useCreateContactModal = ({ modalData }) => {
  const [{ editingResource }, appStateDispatch] = useAppState();
  const { data } = useUsers();
  const users = data?.users;
  const initialState =
    modalData?.contactType === "company" ? COMPANY_EMPTY : CONTACT_EMPTY;
  const [contact, contactDispatch] = useContactReducer(cloneDeep(initialState));
  const [saving, setSaving] = useState(false);
  const { data: systemConfiguration } = useSystemConfiguration();
  const { mutateAsync: postUser } = useUserPost();
  const [allRoles] = useRoles();
  const [roles, setRoles] = useState([]);
  const [availableSeats, setAvailableSeats] = useState();
  const [states, setStates] = useState([]);
  const [ptoCategory, setPtoCategory] = useState();
  const [, directReportOptions] = useDirectReports();
  const { data: originalSubscription } = useSubscription();
  const [memberEmails, setMemberEmails] = useState();
  const [tmpAvatarImg, setTmpAvatarImg] = useState({});

  const [showConfirm, setShowConfirm] = useState(false);
  const [inviteStatus, setInviteStatus] = useState("nonmember"); // "nonmember", "pending", "success"
  const [inviteData, setInviteData] = useState();
  const modalRef = useRef();
  const queryClient = useQueryClient();

  useEffect(() => {
    const isSame = isEqual(
      contact?.kind === "company" ? COMPANY_EMPTY : CONTACT_EMPTY,
      contact
    );
    setShowConfirm(!isSame);
  }, [contact]);

  useEffect(() => {
    // also counting deleted users
    const emails = users
      ?.filter((user) => user?.kind === "member")
      .map((member) => member?.email);
    setMemberEmails(emails);
  }, [users]);

  useEffect(() => {
    setPtoCategory(systemConfiguration?.system?.pto?.category);
    if (systemConfiguration?.system?.pto?.locations?.length) {
      setStates(
        systemConfiguration?.system?.pto?.locations?.map((loc) => ({
          label: loc.location,
          value: loc.id,
        }))
      );
    }
  }, [systemConfiguration]);

  useEffect(() => {
    if (users && originalSubscription) {
      const activeMemberCount = users?.filter(
        (user) =>
          user?.kind === "member" && user?.active && !user?.metadata?.deletedAt
      )?.length;
      const seats = originalSubscription?.userCount;
      setAvailableSeats(
        seats > activeMemberCount ? seats - activeMemberCount : 0
      );
    }
  }, [originalSubscription, users]);

  useEffect(() => {
    if (allRoles?.length) {
      setRoles(
        allRoles.map((role) => ({
          label: role.name,
          value: role.reference,
        }))
      );
    }
  }, [allRoles]);

  const [invalidForm, setInvalidForm] = useState(null);

  useEffect(() => {
    // if company Check companyName length, otherwise check firstName length
    const hasFirstName =
      contact?.kind !== "company"
        ? // First Name always required, remove spaces and make sure at least 1 char long
          contact?.name?.firstName?.trim()?.length > 0 ||
          contact?.name?.lastName?.trim()?.length > 0
        : // Company Name always required, remove spaces and make sure at least 3 char long
          contact?.companyName?.trim()?.length > 0;

    // returns contactPoint Obj of email { use: "Home", system: "Email", value: "email@email.com"}
    const hasEmail = contact?.contactPoint?.find(
      (item) => item.system === "Email"
    );
    // check email against regex
    const checkEmail = yupEmailRegExp.test(hasEmail?.value);
    // set if the form is invalid or not
    if (inviteStatus === "pending" || hasEmail?.value?.length) {
      setInvalidForm(!(checkEmail && hasFirstName));
    } else {
      setInvalidForm(!hasFirstName);
    }
  }, [contact, inviteStatus]);

  const postFinishedUser = useCallback(
    async (user) => {
      postUser(user)
        .then(async (response) => {
          if (response) {
            contactDispatch({
              type: "success",
              id: response?.id,
              reference: response?.reference,
            });
            if (modalData?.updateSelect?.update) {
              modalData?.updateSelect.update(response);

              modalData?.updateSelect.add(formatSelectUser({ ...response }));
            }
          }

          // if Inviting to be a member
          if (inviteStatus === "pending") {
            const hasEmail = contact?.contactPoint?.filter(
              (item) => item.system === "Email"
            );
            const payload = {
              ...response,
              ...inviteData,
              email: hasEmail?.[0]?.value,
            };
            // invite member await response
            try {
              await UserAPI.postWOP(`${payload.id}/$invitecontact`, payload);
              queryClient.invalidateQueries(userKeys?.users);
              toastMessage("Invite sent successfully");
              setInviteStatus("success");
            } catch (error) {
              toastError("There was an issue sending the invitation");
              setInviteStatus("nonmember");
            }
          }

          const messageAndLinkProps = {
            resource: response,
            editingResource,
          };
          toastMessage(undefined, messageAndLinkProps);
        })
        .catch(() => {
          toastError("There was an issue creating this contact");
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [
      postUser,
      inviteStatus,
      editingResource,
      contactDispatch,
      modalData?.updateSelect,
      contact?.contactPoint,
      inviteData,
      queryClient,
    ]
  );

  const createButtonHandler = useCallback(async () => {
    setSaving(true);
    // if creating new company in Create Screen
    if (contact?.isNewCompany) {
      // Post new Company
      await postUser({
        ...COMPANY_EMPTY,
        kind: "company",
        company: { value: contact?.companyName, type: "string" },
        companyName: contact?.companyName,
      }).then(async (response) => {
        // Create Updated User Obj with the returned userRef and companyName
        const updatedUser = {
          ...contact,
          companyName: response?.companyName,
          company: { value: response?.reference, type: "reference" },
        };
        // Initiate Posting the new contact with updated reference to the new company
        await postFinishedUser(updatedUser).then(() =>
          contactDispatch({ type: "address", address: [] })
        );
      });
    } else {
      // Post Contact normally
      await postFinishedUser(contact).then(() =>
        contactDispatch({ type: "address", address: [] })
      );
    }
  }, [contact, contactDispatch, postFinishedUser, postUser]);

  const onFinish = (inviteInfo) => {
    setInviteStatus("pending");
    setInviteData(inviteInfo);
  };

  const handleInviteButton = () => {
    const rect = modalRef.current.getBoundingClientRect();
    const { left, top } = rect;
    const newData = {
      userInfo: { ...contact },
      directReportOptions,
      roles,
      availableSeats,
      states,
      ptoCategory,
      memberEmails,
      onFinish,
    };
    appStateDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      ref: { id: uuidv4() },
      popupData: newData,
      position: {
        x: left - 400,
        y: top + 40,
      },
      popupType: INVITE_CONTACT_POPUP,
    });
  };
  return {
    createButtonHandler,
    invalidForm,
    saving,
    showConfirm,
    contactDispatch,
    contact,
    memberEmails,
    handleInviteButton,
    modalRef,
    inviteStatus,
    tmpAvatarImg,
    setTmpAvatarImg,
  };
};

export default useCreateContactModal;

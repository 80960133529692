import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import Dropdown from "../Dropdown/Dropdown";
import Modal from "../Modal/Modal";
import { getInitials } from "../../../helpers/User";
import { useGetFile } from "../../../hooks/useGetFile";

const avatarCN = cntl`
  bg-brandGreen
  rounded-full
  w-20
  h-20
  flex
  items-center
  justify-center
  mr-6
`;

const headerCN = cntl`
  text-lg
  font-semibold
`;

const ChangeRoleModal = ({
  user,
  onCancel,
  onSave,
  changeUserRole,
  isSaving,
  roles,
}) => {
  const { data } = useGetFile(user?.avatar);

  return (
    <Modal
      title="CHANGE MEMBER ROLE"
      inset="10rem 25rem"
      isOpen
      onRequestModalClose={onCancel}
      hideFooter
      primaryButtonTitle="Save"
      primaryButtonOnClick={() => onSave(user?.id)}
      tertiaryButtonTitle="Cancel"
      disabled={isSaving}
    >
      <div className="flex flex-row">
        {(data?.thumbnailUrl && (
          <div className="bg-gray-200 flex justify-center items-center w-20 h-20 rounded-full mr-6 relative">
            <img
              src={data?.thumbnailUrl}
              className="w-full h-full rounded-full"
              alt="userAvatarIcon"
            />
          </div>
        )) || (
          <div className={avatarCN}>
            <p className="font-semibold uppercase text-white text-4xl">
              {getInitials(user?.name)}
            </p>
          </div>
        )}
        <div className="px-2 pt-3">
          <h2 className={headerCN}>
            {user?.name?.firstName} {user?.name?.lastName}
          </h2>
          <h2 className="text-sm text-gray-300">{user?.company}</h2>
        </div>
      </div>
      <div className="pt-5 h-72">
        <Dropdown
          label="Member Role"
          options={roles}
          value={roles?.find((role) => role.value === user?.role)}
          onChange={changeUserRole}
          defaultMenuIsOpen
        />
      </div>
    </Modal>
  );
};

ChangeRoleModal.propTypes = {
  onCancel: PropTypes.func,
  user: PropTypes.shape({
    active: PropTypes.bool,
    avatar: PropTypes.shape({
      thumbnailUrl: PropTypes.string,
    }),
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    id: PropTypes.string,
    company: PropTypes.string,
    role: PropTypes.string,
  }),
  onSave: PropTypes.func,
  isSaving: PropTypes.bool,
  changeUserRole: PropTypes.func,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

ChangeRoleModal.defaultProps = {
  onCancel: undefined,
  user: undefined,
  onSave: undefined,
  isSaving: false,
  roles: undefined,
  changeUserRole: undefined,
};

export default ChangeRoleModal;

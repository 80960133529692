import React from "react";
import PropTypes from "prop-types";
import useTagViewData from "./useTagViewData";
import CrossBubble from "../CrossBubble";

/**
 * Component that renders a single tag
 */
const Tag = ({ tagReference, isEditing, handleDeleteTag, className, size }) => {
  const { label } = useTagViewData(tagReference, size);

  return (
    <div className="relative max-w-full">
      {isEditing && (
        <CrossBubble
          width={18}
          height={18}
          onClick={() => handleDeleteTag(tagReference)}
        />
      )}
      {label && (
        <div
          className={`bg-tagGreen rounded-3xl truncate overflow-ellipsis ${className}`}
        >
          <p className="text-primaryGreen text-xs font-light truncate text-ellipsis">
            {label}
          </p>
        </div>
      )}
    </div>
  );
};

Tag.propTypes = {
  tagReference: PropTypes.string,
  isEditing: PropTypes.bool,
  handleDeleteTag: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.number,
};

Tag.defaultProps = {
  tagReference: undefined,
  isEditing: false,
  handleDeleteTag: () => {},
  className: "px-3 py-2",
  size: 10,
};
export default Tag;

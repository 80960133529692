import React from "react";
import cntl from "cntl";
import websiteIcon from "../../../assets/images/shareIcon.svg";
import sopIcon from "../../../assets/images/checkedClipboard.svg";
import crossIconWhite from "../../../assets/images/crossIconWhite.svg";
import addIcon from "../../../assets/images/plusCircleIconDarkGreen.svg";

const containerCN =
  "bg-es-extra-light-grey rounded-xl p-4 flex flex-col w-fit gap-2";
const titleSectionRowCN = "flex flex-row items-center gap-2";
const titleCN =
  "text-es-medium-grey text-es-normal font-es-bold tracking-es-wide justify-start text-left";
const preferencesWrapperCN = (isEditing) =>
  cntl`flex flex-row flex-wrap items-center ${!isEditing && "gap-3"}`;
const removeTextCN =
  "text-es-sm font-es-semibold tracking-es-normal text-es-green";
const tagTextCN =
  "text-es-medium-grey font-es-semibold tracking-es-normal text-es-sm";
const tagCN =
  "bg-backgroundGreen rounded-3xl py-2 px-3 flex flex-row items-center gap-2 text-es-green mt-3";

const PreferenceContainer = ({
  preferences,
  title,
  handleOnClick,
  isEditing,
  handleRemoveContainer,
  handleRemovePreference,
  handleAddPreference,
  preferenceType,
  key,
  isAdminPage = false,
}) => {
  const preferenceTagEditing = (pref) => (
    <div className="flex fex-row" key={pref.id}>
      <button
        type="button"
        onClick={() => handleRemovePreference({ pref, preferenceType, title })}
        className={tagCN}
      >
        <p className={tagTextCN}>{pref.value}</p>
      </button>
      <button
        type="button"
        onClick={() => handleRemovePreference({ pref, preferenceType, title })}
        className="relative bottom-2 right-3"
      >
        <img
          alt="delete tag"
          className="rounded-xl p-1"
          src={crossIconWhite}
          style={{
            width: "16px",
            height: "16px",
            backgroundColor: "#027D61",
          }}
        />
      </button>
    </div>
  );

  const basePreferenceTag = (pref) => (
    <div key={pref.id} className={tagCN}>
      <p className={tagTextCN}>{pref.value}</p>
    </div>
  );

  const linkPreferenceTag = (pref) => (
    <button
      key={pref?.id}
      type="button"
      onClick={() => handleOnClick(pref)}
      className={tagCN}
    >
      {pref?.link?.includes?.("Sop/") && (
        <img
          alt="website link"
          src={sopIcon}
          style={{ height: 14, width: 12 }}
        />
      )}
      <p
        style={{
          textDecoration: "underline",
          textDecorationColor: "#2A7D61",
        }}
        className="text-es-green font-es-semibold tracking-es-normal text-es-sm"
      >
        {pref.value}
      </p>
      {!pref?.link?.includes?.("Sop/") && (
        <img
          alt="website link"
          src={websiteIcon}
          style={{ height: 12, width: 12 }}
        />
      )}
    </button>
  );

  const renderPreferences = () => {
    const sortFn = (a, b) => {
      const valueA = a.value.toUpperCase(); // ignore upper and lowercase
      const valueB = b.value.toUpperCase(); // ignore upper and lowercase
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    };
    const preferenceList = isAdminPage
      ? preferences?.[title]?.values?.sort(sortFn)
      : preferences?.[preferenceType]?.[title]?.values?.sort(sortFn);

    return preferenceList?.map((pref) => {
      if (isEditing) {
        return preferenceTagEditing(pref);
      }
      return pref?.link ? linkPreferenceTag(pref) : basePreferenceTag(pref);
    });
  };

  return (
    <div key={key} className={containerCN}>
      {/* Title Section */}
      <div className={titleSectionRowCN}>
        <p className={titleCN}>{title}</p>

        {/* Option to Remove Container of Preferences */}
        {isEditing && (
          <button
            onClick={() => handleRemoveContainer(title, preferenceType)}
            type="button"
          >
            <p className={removeTextCN}>(Remove)</p>
          </button>
        )}
      </div>

      <div
        className={preferencesWrapperCN(isEditing)}
        style={{ minHeight: "45px" }}
      >
        {/* Preferences List */}
        {renderPreferences()}

        {/* Add Preference button */}
        {isEditing && (
          <button
            type="button"
            onClick={() => handleAddPreference(title, preferenceType)}
            className="flex mt-3"
          >
            <img src={addIcon} alt="add preference" />
          </button>
        )}
      </div>
    </div>
  );
};

export default PreferenceContainer;

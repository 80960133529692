import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useQueryClient } from "react-query";
import SubscriptionPlans from "../SubscriptionPlans/SubscriptionPlans";
import SignUpAPI from "../../../Pages/SignUp/SignUpAPI";
import Modal from "../Modal/Modal";
import { toastError, toastMessage } from "../Toast/Toast";

const Plans = ({
  setIsMonthly,
  isMonthly,
  plans,
  selectedPlan,
  cardStyle,
  activeUsers,
  setIsEditingPlan,
  dispatch,
  isEditingPlan,
}) => {
  const queryClient = useQueryClient();
  const [isSaving, setIsSaving] = useState();

  const onSaveAndClose = useCallback(
    async (plan) => {
      try {
        setIsSaving(true);
        const newPlan = { ...plan };
        const currentSeats = parseInt(selectedPlan?.userCount, 10);
        if (newPlan?.planName !== selectedPlan?.planName) {
          if (currentSeats < newPlan?.minUsers) {
            newPlan.userCount = newPlan?.minUsers;
          } else {
            newPlan.userCount = newPlan?.maxUsers;
          }
        } else {
          newPlan.userCount = selectedPlan.userCount;
        }

        const subscriptionItem = await SignUpAPI.updateSubscriptionItem(
          selectedPlan.subscriptionItem,
          {
            quantity: newPlan.userCount,
            price: isMonthly ? newPlan.priceIdMonthly : newPlan.priceIdAnnual,
          },
          queryClient
        );

        // TODO: update next payment date
        dispatch({
          type: "reset",
          subscription: {
            ...selectedPlan,
            ...newPlan,
            billingCycle: isMonthly ? "monthly" : "annual",
            nextPayment: (
              Number.parseFloat(subscriptionItem?.plan?.amount / 100) *
              subscriptionItem.quantity
            ).toFixed(2),
            userCount: subscriptionItem?.quantity,
          },
        });

        setIsEditingPlan(false);
        toastMessage("Successfully updated subscription plan");
      } catch (e) {
        console.error("billing plan error", e);
        toastError(
          `Error updating plan. ${
            e?.response?.data?.issues[0]?.location || e.message || ""
          }`
        );
      } finally {
        setIsSaving(false);
      }
    },
    [selectedPlan, isMonthly, dispatch, setIsEditingPlan, queryClient]
  );

  return (
    <Modal
      isOpen={isEditingPlan}
      onRequestModalClose={() => setIsEditingPlan(false)}
      title="CHANGE ESTATESPACE PLAN"
      hideFooter
      crossIconGreen
    >
      <SubscriptionPlans
        plans={plans}
        onPlanSelect={onSaveAndClose}
        setIsMonthly={setIsMonthly}
        isMonthly={isMonthly}
        selectedPlan={selectedPlan}
        cardStyle={cardStyle}
        hideTitle
        activeUsers={activeUsers}
        isSaving={isSaving}
      />
    </Modal>
  );
};

Plans.propTypes = {
  setIsMonthly: PropTypes.func,
  isMonthly: PropTypes.bool,
  plans: PropTypes.arrayOf(PropTypes.shape({})),
  cardStyle: PropTypes.string,
  selectedPlan: PropTypes.shape({
    planName: PropTypes.string,
    billingCycle: PropTypes.string,
    userCount: PropTypes.string,
    subscriptionItem: PropTypes.string,
  }),
  activeUsers: PropTypes.number,
  setIsEditingPlan: PropTypes.func,
  dispatch: PropTypes.func,
  isEditingPlan: PropTypes.func,
};

Plans.defaultProps = {
  setIsMonthly: undefined,
  isMonthly: false,
  plans: [],
  cardStyle: undefined,
  selectedPlan: undefined,
  activeUsers: undefined,
  setIsEditingPlan: undefined,
  dispatch: undefined,
  isEditingPlan: false,
};

export default Plans;

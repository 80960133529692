import React from "react";
import PropTypes from "prop-types";
import { formatWithCommasWithoutDecimal } from "../../../helpers/Formatters";
import SymbolPrefix from "../SymbolPrefix/SymbolPrefix";

const CurrencyView = ({ value, isPercentage, budget }) => {
  return (
    <SymbolPrefix
      symbol={isPercentage ? "%" : "$"}
      value={formatWithCommasWithoutDecimal(value)}
      budget={budget}
    />
  );
};

CurrencyView.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isPercentage: PropTypes.bool,
  budget: PropTypes.bool,
};

CurrencyView.defaultProps = {
  value: 0,
  isPercentage: false,
  budget: false,
};

export default CurrencyView;

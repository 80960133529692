import { ExpenseAPI } from "@griffingroupglobal/eslib-api";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { expenseKeys } from "../config/reactQuery/queryKeyFactory";

const fetchMergedExpenses = async (association) => {
  const { data } = await ExpenseAPI.getWOP("$getmergedexpenses", {
    params: { association },
  });

  const billableExpensesDict = data.billableExpenses.reduce(
    (acc, v) => ({ ...acc, [v.reference]: v }),
    {}
  );

  const nonBillableExpensesDict = data.nonBillableExpenses.reduce(
    (acc, v) => ({ ...acc, [v.reference]: v }),
    {}
  );
  return {
    expenses: data,
    dict: {
      ...billableExpensesDict,
      ...nonBillableExpensesDict,
    },
  };
};
/**
 * Query hook to fetch Expenses
 * @returns query hook
 */
const useGetMergedExpenses = (projectRef) => {
  const queryKey = expenseKeys.mergedExpensesByAssociation(projectRef);

  return useAuthenticatedQuery({
    queryKey,
    enabled: !!projectRef,
    queryFn: () => fetchMergedExpenses(projectRef),
    onError: (error) => {
      console.error("Error fetching Merged Expenses", error);
    },
  });
};

export default useGetMergedExpenses;

import React from "react";
import PropTypes from "prop-types";

const contentStyle = {
  fontSize: "12px;",
  fontWeight: "400;",
  lineHeight: "16px;",
  letterSpacing: "0.5625px;",
  textAlign: "left;",
};

const HelpCardDescription = ({ description }) => {
  return (
    <p style={contentStyle} className="flex text-left">
      {description}
    </p>
  );
};

HelpCardDescription.propType = {
  description: PropTypes.string,
};

HelpCardDescription.defaultProps = {
  description: "",
};

export default HelpCardDescription;

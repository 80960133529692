import React from "react";
import cntl from "cntl";
import IconLabel from "../../IconLabel/IconLabel";
import CrossBubble from "../../CrossBubble";
import Pill from "../../Pill/Pill";
import formatTasks, {
  statusPillCN,
} from "../../../../helpers/ServiceRequest/formatTasks";

const statusCN = (status) => cntl`
border 
flex 
items-center 
rounded-full
font-medium
text-sm
py-2 
px-2
w-36
cursor-default
${status}
`;

const TaskRow = ({
  index,
  task,
  handleTasksToDelete,
  handleNavigateToTask,
  showAddAndDeleteTasks,
}) => {
  return (
    <div className="relative w-full mb-4 rounded-md border border-gray-150">
      {showAddAndDeleteTasks &&
        task?.status !== "done" &&
        task?.status !== "incomplete" && (
          <CrossBubble onClick={() => handleTasksToDelete(task)} />
        )}

      <table className="w-full table-fixed">
        <tr>
          <td className="w-12 p-4 border-r border-gray-150 font-semibold text-center">
            {index}
          </td>
          <td className="w-2/5 p-4 border-r border-gray-150">{task?.name}</td>
          <td className="p-4 border-r border-gray-150">{task?.date}</td>
          <td className="p-4 border-r border-gray-150">{task?.time}</td>
          <td
            className="p-4 border-r border-gray-150 flex justify-center"
            aria-label="table data"
          >
            <Pill
              value={formatTasks(task)?.formattedStatus}
              background="" // TODO: (Parker) Pill component sets bg to bg-grand if no prop is found
              className={statusCN(
                statusPillCN(formatTasks(task)?.formattedStatus)
              )}
            />
          </td>
          <td className="p-4 border-gray-150" aria-label="table data">
            <div className="items-center flex justify-center">
              <IconLabel
                text="Go To Task >"
                textClassName="text-darkenedGreen -ml-3"
                onClick={() => handleNavigateToTask(task)}
              />
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default TaskRow;

import React from "react";
import AddAttachmentsAndRecentButton from "../../../stories/Components/AddAttachmentsAndRecentButton";
import AttachmentsHorizontalList from "../../../stories/Components/AttachmentsHorizontalList/index";
import useAttachmentsViewData from "./useAttachmentsViewData";

const Attachments = ({
  noTitle,
  isEditing,
  requestForm,
  filesState,
  handleFilesToAdd,
  handleFilesToRemove,
  documentTypeFilter,
  singleTask,
  labelClassName,
}) => {
  const { attachments, containerContainerClassName, addNewFiles, removeFile } =
    useAttachmentsViewData({
      requestForm,
      filesState,
      handleFilesToAdd,
      handleFilesToRemove,
      noTitle,
      singleTask,
    });

  return (
    <div className={containerContainerClassName}>
      {!noTitle && (
        <span className={`font-semibold text-lg ${labelClassName}`}>
          Attachments
        </span>
      )}

      <AttachmentsHorizontalList
        files={attachments}
        isEditing={isEditing}
        onFileRemoved={removeFile}
      />
      {isEditing && (
        <AddAttachmentsAndRecentButton
          onFilesAdded={addNewFiles}
          isEditing={isEditing}
          documentTypeFilter={documentTypeFilter}
          className={singleTask && "mt-4"}
        />
      )}
    </div>
  );
};

export default Attachments;

import React from "react";
import PropTypes from "prop-types";
import Modal from "../Modal/Modal";

const DeleteModal = ({ isOpen, onClose, onDelete, title, text, disabled }) => {
  return (
    <Modal
      isOpen={isOpen}
      title={title}
      hideFooter
      onRequestModalClose={onClose}
      primaryButtonTitle="Yes, Delete"
      primaryButtonOnClick={onDelete}
      tertiaryButtonTitle="Cancel"
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      disabled={disabled}
      alert
    >
      <div className="flex flex-col w-120">
        <p>{text}</p>
      </div>
    </Modal>
  );
};

export default DeleteModal;

DeleteModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
};

DeleteModal.defaultProps = {
  isOpen: undefined,
  onClose: undefined,
  onDelete: undefined,
  title: undefined,
  text: undefined,
  disabled: false,
};

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import Input from "../Input/Input";
import Dropdown from "../Dropdown/Dropdown";

const SovCell = ({
  className,
  id,
  field,
  value,
  units,
  groupedUnits,
  dispatch,
  disableEditing,
  maxValue,
}) => {
  const [displayValue, setDisplayValue] = useState();
  const [maxValueError, setMaxValueError] = useState(false);

  useEffect(() => {
    let isError = false;
    if (maxValue !== undefined) {
      if (Number(value) > Number(maxValue)) {
        isError = true;
      }
    }
    setMaxValueError(isError);
  }, [maxValue, value]);

  useEffect(() => {
    setDisplayValue(value);
  }, [value]);

  const handleInput = useCallback(
    (val) => {
      dispatch({
        id,
        type: field,
        value: val,
      });
    },
    [dispatch, field, id]
  );

  const getDisplayElement = () => {
    switch (field) {
      case "PO":
        return (
          <Input
            placeholder="Value"
            value={displayValue}
            onChange={handleInput}
            disableClear
            disabled
          />
        );
      case "description":
        return (
          <Input
            placeholder="Description"
            value={displayValue}
            onChange={handleInput}
            disableClear
            disabled={disableEditing}
          />
        );
      case "quantity":
        return (
          <Input
            placeholder="Quantity"
            value={displayValue}
            onChange={handleInput}
            disableClear
            disabled={disableEditing}
          />
        );
      case "unitOfMeasure":
        return (
          <Dropdown
            placeholder="Select"
            options={groupedUnits ?? units}
            value={units.find((item) => item.value === value)}
            onChange={({ value: val }) => handleInput(val)}
            disableClear
            isDisabled={disableEditing}
          />
        );
      case "costPerUnit":
        return (
          <Input
            placeholder="Cost"
            value={displayValue}
            onChange={handleInput}
            isDollarValue
            disableClear
            disabled={disableEditing}
          />
        );
      case "workCompletedThisPeriod":
        return (
          <Input
            placeholder="Value"
            value={displayValue}
            onChange={handleInput}
            isDollarValue
            disableClear
            disabled={disableEditing}
          />
        );
      case "workCompletedThisPeriodPercentValue":
        return (
          <Input
            placeholder="Value"
            value={displayValue}
            onChange={handleInput}
            isPercentageValue
            disableClear
            disabled={disableEditing}
            errorBorder={maxValueError}
          />
        );
      case "workCompletedThisPeriodDollarValue":
        return (
          <Input
            placeholder="Value"
            value={displayValue}
            onChange={handleInput}
            isDollarValue
            disableClear
            disabled={disableEditing}
            errorBorder={maxValueError}
          />
        );
      case "workCompletedToDate":
        return (
          <Input
            placeholder="Value"
            value={displayValue}
            onChange={handleInput}
            isDollarValue
            disableClear
            disabled={disableEditing}
          />
        );
      case "percentCompleteOfTotalValues":
        return (
          <Input
            placeholder="Value"
            value={displayValue}
            onChange={handleInput}
            isPercentageValue
            disableClear
            disabled={disableEditing}
          />
        );
      case "total":
        return (
          <Input
            placeholder="Value"
            value={displayValue}
            onChange={handleInput}
            isDollarValue
            disableClear
            disabled
          />
        );
      default:
        return displayValue;
    }
  };

  return <div className={className}>{getDisplayElement()}</div>;
};

SovCell.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  field: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ]),
  // eslint-disable-next-line react/forbid-prop-types
  units: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  groupedUnits: PropTypes.array,
  dispatch: PropTypes.func,
  disableEditing: PropTypes.bool,
  maxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SovCell.defaultProps = {
  className: undefined,
  id: undefined,
  field: undefined,
  value: undefined,
  units: [],
  groupedUnits: undefined,
  dispatch: () => {},
  disableEditing: false,
  maxValue: undefined,
};

export default SovCell;

// Framework Tools
import React from "react";
import PropTypes from "prop-types";

// Components
import Widget from "../../stories/Components/Widget/Widget";
import RequestDetailViewCard from "../../stories/Components/RequestDetailView/RequestDetailViewCard";
import RequestWorkflowEditMode from "../../stories/Components/RequestWorkflow/RequestWorkflowEditMode";

const SubmittalWorkflow = ({
  loading,
  setLoading,
  setAttachmentMap,
  attachmentMap,
  handleSendReminder,
  submittalActionSubmit,
  submittalActionReview,
  patchSubmittal,
  editedSubmittal,
  commentMap,
  editing,
  workflowSteps,
  checkStepComplete,
  submittalForm,
  submittalFormDispatch,
  projectMembers,
  userDict,
  submittalHistory,
  updateAssociatedFiles,
  getStepStatus,
  getIsActiveStep,
}) => {
  return (
    <Widget title="" hideBorder draggable={false} loading={loading} overflow>
      {!editing && (
        <div className="flex flex-col gap-6">
          {workflowSteps?.map((step, index) => {
            const isActiveStep = getIsActiveStep(step, index);
            const complete = step.users.some(checkStepComplete);
            return (
              <RequestDetailViewCard
                key={step.id}
                loading={loading}
                setLoading={setLoading}
                onPatch={patchSubmittal}
                requestData={editedSubmittal}
                allSteps={workflowSteps}
                commentMap={commentMap}
                complete={complete}
                activeStep={isActiveStep}
                step={step}
                index={index}
                onSubmit={submittalActionSubmit}
                onStepAction={submittalActionReview}
                handleSendReminder={handleSendReminder}
                attachmentMap={attachmentMap}
                setAttachmentMap={setAttachmentMap}
                history={submittalHistory}
                updateAssociatedFiles={updateAssociatedFiles}
                getStepStatus={() => getStepStatus(step, isActiveStep)}
              />
            );
          })}
        </div>
      )}
      {editing && (
        <RequestWorkflowEditMode
          workflow={submittalForm?.requestWorkflow?.[0]}
          requestForm={submittalForm}
          requestFormDispatch={submittalFormDispatch}
          association={submittalForm?.association}
          projectMembers={projectMembers}
          userDict={userDict}
          onPatch={patchSubmittal}
          workflowSteps={workflowSteps}
          getStepStatus={getStepStatus}
          getIsActiveStep={getIsActiveStep}
        />
      )}
    </Widget>
  );
};

SubmittalWorkflow.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  setAttachmentMap: PropTypes.func,
  attachmentMap: PropTypes.shape({}),
  handleSendReminder: PropTypes.func,
  submittalActionReview: PropTypes.func,
  patchSubmittal: PropTypes.func,
  editedSubmittal: PropTypes.shape({}),
  commentMap: PropTypes.shape({}),
  editing: PropTypes.bool,
  workflowSteps: PropTypes.arrayOf(PropTypes.shape({})),
  checkStepComplete: PropTypes.string,
  submittalForm: PropTypes.shape({
    association: PropTypes.string,
    requestWorkflow: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  submittalFormDispatch: PropTypes.func,
  projectMembers: PropTypes.arrayOf(PropTypes.shape({})),
  userDict: PropTypes.shape({}),
  submittalHistory: PropTypes.shape({}),
  updateAssociatedFiles: PropTypes.func,
  submittalActionSubmit: PropTypes.func,
  getStepStatus: PropTypes.func,
  getIsActiveStep: PropTypes.func,
};

SubmittalWorkflow.defaultProps = {
  loading: false,
  setLoading: undefined,
  setAttachmentMap: undefined,
  attachmentMap: undefined,
  handleSendReminder: undefined,
  submittalActionReview: undefined,
  patchSubmittal: undefined,
  editedSubmittal: undefined,
  commentMap: undefined,
  editing: false,
  workflowSteps: [],
  checkStepComplete: undefined,
  submittalForm: undefined,
  submittalFormDispatch: undefined,
  projectMembers: [],
  userDict: undefined,
  submittalHistory: {},
  updateAssociatedFiles: {},
  submittalActionSubmit: undefined,
  getStepStatus: undefined,
  getIsActiveStep: undefined,
};

export default SubmittalWorkflow;

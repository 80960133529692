import React from "react";
import PropTypes from "prop-types";
import ProjectEditField from "./ProjectEditField";
import Widget from "../Widget/Widget";

const ProjectContractual = ({ project, isEditing, dispatch }) => {
  const feeAmount = project?.contractualFee?.amount;
  const yearsOfWarranty = project?.yearsOfWarranty;
  const insurancePercent = project?.contractualInsurance?.amount;

  const handleChangeFee = (value) => {
    dispatch({
      type: "edit2",
      key1: "contractualFee",
      key2: "amount",
      value,
    });
  };

  const handleChangeInsurance = (value) => {
    dispatch({
      type: "edit2",
      key1: "contractualInsurance",
      key2: "amount",
      value,
    });
  };

  const handleChangeWarranty = (val) => {
    dispatch({
      type: "edit",
      key: "yearsOfWarranty",
      value: val ? parseInt(val, 10) : "0",
    });
  };

  return (
    <Widget draggable={false} title="Contractual" overflow className="mb-4">
      <ProjectEditField
        isEditing={isEditing}
        title="Fee"
        value={feeAmount}
        handleChange={handleChangeFee}
        type="percent"
        placeHolder="Enter fee amount..."
        isPercentageValue
        fieldPosition="end"
        valueClassName="mr-6"
      />
      <ProjectEditField
        isEditing={isEditing}
        title="Insurance"
        value={insurancePercent}
        handleChange={handleChangeInsurance}
        type="percent"
        placeHolder="Enter insurance amount..."
        isPercentageValue
        fieldPosition="end"
        valueClassName="mr-6"
      />
      <ProjectEditField
        isEditing={isEditing}
        title="Years of Warranty"
        value={yearsOfWarranty}
        handleChange={handleChangeWarranty}
        type="warranty"
        placeHolder="Enter years..."
        lastField
        fieldPosition="end"
        valueClassName="mr-6"
      />
    </Widget>
  );
};

ProjectContractual.propTypes = {
  project: PropTypes.shape({
    contractualFee: PropTypes.shape({
      amount: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
    }),
    contractualInsurance: PropTypes.shape({
      amount: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
    }),
    yearsOfWarranty: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  }),
  isEditing: PropTypes.bool,
  dispatch: PropTypes.func,
};

ProjectContractual.defaultProps = {
  project: undefined,
  isEditing: false,
  dispatch: undefined,
};

export default ProjectContractual;

/* eslint-disable no-param-reassign */
import React from "react";
import { useMutation, useQuery } from "react-query";
import { TimeoffAPI } from "@griffingroupglobal/eslib-api";
import moment from "moment";
import { toastError, toastMessage } from "../helpers/Toast";
import whiteCrossIcon from "../stories/assets/images/whiteCrossIcon.svg";
import whiteCircleCheckIcon from "../stories/assets/images/circleCheckIcon.svg";
import reloadIcon from "../stories/assets/images/reloadIconWhite.svg";
import whiteExlamationIcon from "../stories/assets/images/whiteExclamationIcon.svg";
import { AUTH_TOKEN_STORAGE_KEY } from "../constants";
import queryClient from "../config/reactQuery/queryClient";
import { useAppState } from "../state/appState";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = <img src={whiteCrossIcon} alt="Close notice" />;
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;
const reload = (
  <div className="inline-flex gap-3">
    Error loading timeoff, try refreshing.
    <button onClick={() => window.location.reload()} type="button">
      <img src={reloadIcon} className="inline-block" alt="Successful upload" />
    </button>
  </div>
);

/**
 * @param {Object} list - Time Off Cache
 * @param {Array} dataArr - Array of requests (Incoming responses must be wrapped in array)
 * @param {Boolean} patch - patch flag for updating items currently in cache
 * @returns - Updated Cache {Hash Map, Array}
 */
const parseTimeoff = (list, dataArr, patch = false) => {
  let arr = list.timeoff;
  let dict = list.timeoffDict;

  /**
   * Handles array of responses
   * @summary - For Single responses wrap in array [singleResponse]
   * - Example Case: Approving Multiple Time off requests
   */
  dataArr.forEach((data) => {
    const { requests, id: parent, user, ...rest } = data;
    /**
     * Second Level Traversal
     * @summary - Time off Objects contain multiple requests from the user
     * Traverse the requests array, format the request for table view
     */
    requests.forEach((req, i) => {
      const total = req.dates.reduce((amt, { numHours }) => {
        amt += parseFloat(numHours);
        return amt;
      }, 0);
      const formattedRequest = {
        ...req,
        total,
        user,
        parent,
        index: list.timeoff.length,
        hideSelection: req.status !== "pending",
      };
      let newTimeoff;
      /**
       * Check if request exists
       * ex. (Patch and/or Post)
       * - if it does replace it in the array
       * - else push it to the end
       */
      if (dict?.[user]?.[parent]?.[req.id]?.index) {
        const position = list.timeoffDict[user][parent][req.id].index;

        newTimeoff = [
          ...arr.slice(0, position),
          {
            ...formattedRequest,
            index: position,
          },
          ...arr.slice(position + 1),
        ];
      } else {
        newTimeoff = [...arr, formattedRequest];
      }

      let newTimeoffDict = {};
      /**
       * Check if request exists
       */
      if (dict?.[user]?.[parent]) {
        newTimeoffDict = {
          ...dict,
          [user]: {
            ...dict?.[user],
            [parent]: { ...dict[user].parent, [req.id]: formattedRequest },
          },
        };

        /**
         * Update Original Sheet (Parent)
         */
        const parentRequestsLength = dict?.[user]?.original?.requests?.length;

        // If we make it to the end of request parsing and the original length
        // doesn't match the new requests length we update it
        if (i === parentRequestsLength) {
          newTimeoffDict[user] = {
            ...(newTimeoffDict?.[user] ?? {}),
            original: {
              requests,
              id: parent,
              user,
              ...rest,
            },
          };
        }

        // If patch flag is true update on last cycle
        if (patch && i === parentRequestsLength - 1) {
          newTimeoffDict[user].original = {
            requests,
            id: parent,
            user,
            ...rest,
          };
        }

        /**
         * Update Original Sheet (Parent)
         */
      } else {
        newTimeoffDict[user] = {
          [parent]: {
            [req.id]: formattedRequest,
          },
          original: { requests, id: parent, user, ...rest },
        };
      }

      arr = newTimeoff;
      dict = { ...dict, ...newTimeoffDict };
    });
  });
  /**
   * Second Level Traversal
   */

  return { ...list, timeoffDict: dict, timeoff: arr };
};

const insertPatch = (list, { requests: [request], id: parent, metadata }) => {
  const user = metadata.createdBy;
  // destructure original timeoff object (used in detailed view)
  const { original } = list.timeoffDict[user];

  // find index of request in original
  let index = original.requests.findIndex((item) => item.id === request.id);

  // replace request in original
  original.requests.splice(index, 1, request);

  // update metadata in original
  original.metadata = metadata;

  // find request in timeoff array cache
  index = list.timeoff.findIndex((item) => item.id === request.id);

  /**
   * Parse request
   */
  const total = request.dates.reduce((amt, { numHours }) => {
    amt += parseInt(numHours, 10);
    return amt;
  }, 0);

  const formattedRequest = {
    ...request,
    totalHours: `${(total / 8).toFixed(1)} days`,
    total: total.toFixed(1),
    user,
    parent,
    index: list.timeoff.length,
    hideSelection: request.status !== "pending",
  };
  /**
   * Parse request
   */

  // replace request in timeoff array cache
  list.timeoff.splice(index, 1, formattedRequest);

  // replace original in time off dict cache
  list.timeoffDict[user].original = original;

  // replace parsed request in timeoff dict cache
  list.timeoffDict[user][parent][request.id] = formattedRequest;
};

export default () => {
  const [{ currentUser }] = useAppState();
  const authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);

  const keys = ["timeoff"];

  /**
   * Create
   */
  const postTimeoff = async (body) => {
    const { data } = await TimeoffAPI.post(body).then((res) => res);
    return data;
  };

  const post = useMutation({
    mutationFn: postTimeoff,
    onSuccess: async (res) => {
      await queryClient.setQueryData(keys, (current) => {
        const posted = parseTimeoff(current, [res]);
        return posted;
      });
      toastMessage(
        "Successfully created time off request.",
        toastIcon,
        toastCloseIcon
      );
    },
    onError: () => {
      /**
       * @TODO - Add Toast
       */
    },
  });

  /**
   * Create
   */

  /**
   * Read
   */

  const fetchTimeoff = async () => {
    switch (true) {
      case currentUser?.hasPermission("corporate", "can_give_timeoff"):
        return TimeoffAPI.get().then(({ data: { entries } }) => {
          const timeoff = entries?.reduce(
            (list, { resource }) => {
              list = parseTimeoff(list, [resource]);
              return list;
            },
            { timeoff: [], timeoffDict: {} }
          );

          return { ...timeoff };
        });
      default:
        return TimeoffAPI.getWOP("$currentuser").then(({ data }) => {
          let list = { timeoff: [], timeoffDict: {} };
          list = parseTimeoff(list, [data]);
          return list;
        });
    }
  };

  const base = useQuery(keys, fetchTimeoff, {
    enabled: !!authToken,
    staleTime: 0,
    onError: () => {
      toastError(reload, toastErrorIcon, toastCloseIcon);
    },
  });

  /**
   * Read
   */

  /**
   * Update
   */

  const patchTimeoff = async ({
    op,
    id,
    body = {},
    requestId,
    originalBody,
    extras,
    batch,
  }) => {
    /**
     * Process a batch of row patches
     */
    if (batch) {
      const reducedRequests = batch.reduce((list, item) => {
        const exists = list.findIndex((combo) => combo.parent === item.parent);
        if (exists !== -1) {
          const { parent, requestIds } = list[exists];
          list[exists] = { parent, requestIds: `${requestIds},${item.id}` };
        } else {
          list.push({ parent: item.parent, requestIds: item.id });
        }
        return list;
      }, []);
      const patches = reducedRequests.map(async (item) => {
        const updatedMetadata = {
          metadata: {
            lastUpdated: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          },
        };

        const { parent, requestIds } = item;
        const extra = {
          params: { requestId: requestIds },
        };
        return TimeoffAPI.patchByIdWOP(
          parent,
          op,
          body,
          updatedMetadata,
          extra
        ).then(({ data }) => data);
      });

      return Promise.all(patches).then((response) => ({
        res: response,
        op,
      }));
    }
    /**
     * Process a batch of row patches
     */

    switch (op) {
      case "$cancel":
        return TimeoffAPI.patchByIdWOP(id, op, body, originalBody, extras).then(
          ({ data }) => ({ res: data, op })
        );
      case "$approve":
        return TimeoffAPI.patchByIdWOP(id, op, body, originalBody, extras).then(
          ({ data }) => ({ res: data, op })
        );
      case "$decline":
        return TimeoffAPI.patchByIdWOP(id, op, body, originalBody, extras).then(
          ({ data }) => ({ res: data, op })
        );

      default:
        return TimeoffAPI.postByIdWOP(id, op, body, {
          params: { requestId },
        }).then(({ data }) => ({ res: data, op }));
    }
  };

  // TODO(Parker) RQ Refactor this should be patching by id on the exact timeoff sheet
  const patch = useMutation({
    mutationFn: patchTimeoff,
    onSuccess: ({ res, op }) => {
      queryClient.setQueryData(keys, (current) => {
        /**
         * @summary - if response has user object
         * @returns - full time off object
         * else if
         * @summary - response is an array of objects traverse cache
         * - and update objects accordingly
         * else
         * @returns - single modified request
         */
        let updated;
        if (res.user) {
          // Approving on Time Of Approval Table
          updated = parseTimeoff(current, [res], "patch");
        } else if (Array.isArray(res)) {
          updated = parseTimeoff(current, res, "patch");
        } else {
          // Modifying the reqest on Request Time Off Table
          const newRes =
            res.requests.length > 0
              ? res.requests?.filter((item) => item.id)
              : res.request;
          const newFormattedRes = res;
          newFormattedRes.requests = newRes;

          insertPatch(current, newFormattedRes);
        }

        return updated ?? current;
      });
      let message = "Request";
      switch (op) {
        case "$cancel":
          message = "Request cancelled";
          break;
        case "$approve":
          message = "Request approved";
          break;
        case "$decline":
          message = "Request declined";
          break;
        default:
          message = "Request modified";
      }
      toastMessage(message, toastIcon, toastCloseIcon);
    },
    onError: () => {
      toastError("Error Modifying Request.", toastErrorIcon, toastCloseIcon);
    },
  });

  /**
   * Update
   */

  return { base: base ?? {}, actions: { post, patch } };
};

import { fetchProjectsOverview } from "../../helpers/Projects";
import useAuthenticatedQuery from "../useAuthenticatedQuery";
import projectKeys from "./projectKeys";

/**
 * Query hook to fetch projects overview
 */
const useProjectsOverview = ({ enabled = true, select } = {}) => {
  const queryInfo = useAuthenticatedQuery({
    queryKey: projectKeys.overview(),
    enabled,
    select,
    queryFn: fetchProjectsOverview,
    onError: (error) => {
      console.error(
        "useProjectsOverview: Error fetching projects overview",
        error
      );
    },
  });

  return {
    queryInfo,
    projectDict: queryInfo?.data?.projectDict,
    projectsDD: queryInfo?.data?.projectsDD,
  };
};

export default useProjectsOverview;

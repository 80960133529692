import React from "react";
import PropTypes from "prop-types";
import IconLabel from "../IconLabel/IconLabel";
import editIcon from "../../assets/images/editIcon.png";
import checkIcon from "../../assets/images/checkmark.svg";
import crossIcon from "../../assets/images/crossIcon.svg";

const SiteHeaderEditRows = ({
  enableEditing,
  rows,
  rowsEditing,
  setRowsEditing,
  onSaveRowClick,
  onEndRowEditing,
  onCancelRowsClick,
}) => {
  const onEditRowsClick = () => {
    setRowsEditing(rows.map((row) => row.id));
  };

  const onSaveRowsClick = () => {
    rowsEditing.forEach((rowId) => {
      onSaveRowClick(rows.find((row) => row.id === rowId));
      onEndRowEditing();
    });
    setRowsEditing([]);
  };

  return (
    <>
      {enableEditing && (
        <>
          <IconLabel
            icon={rowsEditing.length > 0 ? checkIcon : editIcon}
            text={rowsEditing.length > 0 ? "Save All Rows" : "Edit All Rows"}
            className="ml-5"
            onClick={rowsEditing.length > 0 ? onSaveRowsClick : onEditRowsClick}
          />
          {rowsEditing.length > 0 && (
            <IconLabel
              icon={crossIcon}
              text="Cancel Edit Rows"
              className="ml-5"
              onClick={onCancelRowsClick}
            />
          )}
        </>
      )}
    </>
  );
};

SiteHeaderEditRows.propTypes = {
  /**
   * boolean if table is in edit mode
   */
  enableEditing: PropTypes.bool,
  /**
   * array of rows that are being edited in Table
   */
  // eslint-disable-next-line react/forbid-prop-types
  rowsEditing: PropTypes.arrayOf(PropTypes.object),
  /**
   * function for handling save row editing click
   */
  onSaveRowClick: PropTypes.func,
  onEndRowEditing: PropTypes.func,
  /**
   * function for handling cancel edit row click
   */
  onCancelRowsClick: PropTypes.func,
  /**
   * object of global filter settings
   */
  setRowsEditing: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.shape({})),
};

SiteHeaderEditRows.defaultProps = {
  enableEditing: false,
  rows: [],
  rowsEditing: undefined,
  onSaveRowClick: undefined,
  onEndRowEditing: undefined,
  onCancelRowsClick: undefined,
  setRowsEditing: undefined,
};

export default SiteHeaderEditRows;

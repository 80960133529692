import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";
import DocumentTable from "../DocumentTable/DocumentTable";
import WorkflowFileView from "./WorkflowFileView";
import { downloadMedia } from "../../../helpers/File";

const modalStyles = {
  content: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    height: "calc(100% - 72px)",
    margin: "36px 0 0 0",
    padding: "18px 20px 4px 20px",
    borderRadius: "0",
    inset: "0",
    overflow: "visible",
  },
  overlay: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "rgba(25, 25, 25, 0.8)",
    zIndex: "50",
  },
};

const WorkflowDocumentTables = ({ data, docTypeOptionsMap, workflowData }) => {
  const [showFileModal, setShowFileModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [fileViewerData, setFileViewData] = useState(workflowData);
  const [fileViewerDocs, setFileViewerDocs] = useState([]);

  useEffect(() => {
    setFileViewData((prev) => ({ ...prev, documents: fileViewerDocs }));
  }, [fileViewerDocs]);

  const filterDocs = (name) => {
    const allDocs = [];
    const workflowDocs = workflowData?.documents;
    const steps = workflowData?.steps;
    let pSteps;
    let pStepDocs;

    if (workflowDocs.length > 0) {
      workflowDocs.forEach((doc) => allDocs.push(doc));
    }

    if (steps) {
      const stepDocs = steps?.map((step) => step?.documents);
      stepDocs.forEach((stepDocArray) => {
        if (stepDocArray?.length > 0) {
          stepDocArray?.forEach((doc) => allDocs.push(doc));
        }
      });
      pSteps = steps?.map((step) => step?.parallelSteps);
    }

    if (pSteps?.length > 0) {
      pStepDocs = pSteps?.forEach((pStep) => pStep.documents);
    }

    if (pStepDocs?.length > 0) {
      pStepDocs.forEach((pDocArray) => {
        if (pDocArray?.length > 0) {
          pDocArray?.forEach((doc) => allDocs.push(doc));
        }
      });
    }

    const result = allDocs?.filter((doc) => doc?.name === name);
    setFileViewerDocs(allDocs);
    return result ? result[0] : undefined;
  };

  const handleFileClick = (val) => {
    const docName = val?.resource;
    const clickedDoc = filterDocs(docName);
    setSelectedDocument(clickedDoc);
    setShowFileModal(true);
  };

  const handleFileDownload = async (doc) => {
    if (doc?.contentReference) {
      await downloadMedia([doc.contentReference]);
    }
    return null;
  };

  // changed workflowdata = {workflowData} to = {data}
  // current doc is still an issue

  return (
    <div>
      <>
        <ReactModal
          style={modalStyles}
          isOpen={showFileModal}
          onRequestClose={() => setShowFileModal(false)}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc={false}
        >
          <WorkflowFileView
            workflowData={fileViewerData}
            data={[]}
            currentDoc={selectedDocument}
            expanded
            onCloseExternalModal={() => setShowFileModal(false)}
            onDownload={handleFileDownload}
            hideComments
          />
        </ReactModal>
      </>
      {data.map((info, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="mb-10" key={`${info.title}-${i}`}>
          <h2 className="text-xl font-bold text-gray-500 mb-5">{info.title}</h2>
          <DocumentTable
            hideButtons
            resourceName="workflow-document-table"
            documents={info.data}
            attaching
            hideSiteHeader
            extraColumns={[
              {
                Header: "Step",
                accessor: "step",
              },
            ]}
            docTypeOptionsMap={docTypeOptionsMap}
            onRowClick={(val) => handleFileClick(val)}
          />
        </div>
      ))}
    </div>
  );
};

WorkflowDocumentTables.propTypes = {
  workflowData: PropTypes.shape({
    steps: PropTypes.arrayOf(PropTypes.shape({})),
    documents: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          format: PropTypes.string,
          name: PropTypes.string,
          step: PropTypes.string,
          type: PropTypes.string,
          uploadDate: PropTypes.string,
          uploadedBy: PropTypes.string,
        })
      ),
      title: PropTypes.string,
    })
  ),
  docTypeOptionsMap: PropTypes.shape({}),
};

WorkflowDocumentTables.defaultProps = {
  data: [],
  docTypeOptionsMap: undefined,
  workflowData: {},
};
export default WorkflowDocumentTables;

import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import PropTypes from "prop-types";
import BudgetTable from "../../stories/Components/BudgetTable/BudgetTable";
import { GET_PROJECT_CREATE_BUDGET_PATH } from "../../constants";
import SiteHeader from "../../stories/Components/SiteHeader/SiteHeader";
import { getProjectOptions } from "../../helpers/SiteHeaderOptions";
import GetStartedCircle from "../../stories/Components/GetStartedCircle/GetStartedCircle";
import useProject from "../../hooks/useProject";
import Widget from "../../stories/Components/Widget/Widget";
import PrimaryHeaderItems from "../../stories/Components/Primary/PrimaryHeaderItems";
import InnerPageSwitcher from "../../stories/Components/InnerPageSwitcher/InnerPageSwitcher";
import PrimaryButton from "../../stories/Components/Buttons/PrimaryButton";

/**
 * @deprecated
 */
const Budget = ({ currentUser }) => {
  const history = useHistory();
  const { projectId } = useParams();
  const [currentProject] = useProject(projectId);
  const { pathname: currentRoute } = useLocation();

  const [buttonActions, setButtonActions] = useState([]);

  const onAddBudgetClick = useCallback(() => {
    history.push(GET_PROJECT_CREATE_BUDGET_PATH(projectId));
  }, [history, projectId]);

  if (!currentProject) {
    return (
      <div className="py-2">
        <p>loading...</p>
      </div>
    );
  }

  return (
    <>
      {(currentProject?.budget && (
        <>
          <PrimaryHeaderItems
            headerTitle={currentProject.name}
            customButton={
              <PrimaryButton
                title="Budget"
                large
                dropdownItems={buttonActions}
                className="whitespace-nowrap"
              />
            }
            className="overwrite-primaryheaderitems-padding"
          />
          <InnerPageSwitcher
            innerRoutes={getProjectOptions(projectId, currentUser)}
            currentPath={currentRoute}
            history={history}
            className="mb-4"
          />
          <Widget draggable={false} title={null}>
            <BudgetTable
              budgetId={currentProject?.budget.split("/")[1]}
              projectId={projectId}
              projectData={currentProject}
              setButtonActions={setButtonActions}
              hideSiteHeaderTitle
            />
          </Widget>
        </>
      )) || (
        <>
          <SiteHeader
            title={currentProject?.name}
            dropdownRoutes={getProjectOptions(projectId, currentUser)}
          />
          {currentUser?.hasPermission?.("budget", "can_write") && (
            <GetStartedCircle
              title="Project Budget"
              className="mx-auto flex justify-center"
              onGetStartedClicked={onAddBudgetClick}
            />
          )}
        </>
      )}
    </>
  );
};

Budget.propTypes = {
  currentUser: PropTypes.shape({
    hasPermission: PropTypes.func,
  }),
};

Budget.defaultProps = {
  currentUser: undefined,
};

export default Budget;

import React from "react";
import PropTypes from "prop-types";

const FormLabel = ({ label, isRequired }) => {
  return (
    <span className="text-base text-gray-400 font-semibold">
      {label}
      {isRequired && <span className="text-brandGreen">*</span>}
    </span>
  );
};

FormLabel.defaultProps = {
  label: undefined,
  isRequired: false,
};

FormLabel.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
};

export default FormLabel;

import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

const primaryHeaderCN = (className) => cntl`
  text-4xl
  font-medium
  ${className?.includes("uppercase") ? "uppercase" : "capitalize"}
  ${className}
`;

const PrimaryHeader = ({ className, children }) => {
  return <h1 className={primaryHeaderCN(className)}>{children}</h1>;
};

PrimaryHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PrimaryHeader.defaultProps = {
  className: undefined,
};

export default PrimaryHeader;

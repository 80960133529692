import { useCallback, useEffect, useState } from "react";

export default function useFaqListNewData(items) {
  const [itemToggles, setItemToggles] = useState([]);

  useEffect(() => {
    setItemToggles(
      Array(items.length)
        .fill()
        .map(() => false)
    );
  }, [items]);

  const toggleItem = useCallback(
    (idx) => {
      const newItemToggles = [...itemToggles];
      newItemToggles[idx] = !itemToggles[idx];
      setItemToggles(newItemToggles);
    },
    [itemToggles]
  );

  return {
    toggleItem,
    itemToggles,
    setItemToggles,
  };
}

import React from "react";
import PropTypes from "prop-types";

const ChannelButton = ({ channel, onClick }) => {
  return (
    <button
      className="flex w-full hover:bg-mint p-6 truncate items-center font-medium tracking-wider text-gray-500 text-md"
      title={channel?.data?.name}
      type="button"
      style={{ height: "78px", minHeight: "78px" }}
      onClick={onClick}
    >
      {`# ${channel?.data?.name.replaceAll(" ", "")}`}
    </button>
  );
};

ChannelButton.propTypes = {
  channel: PropTypes.shape({
    data: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  onClick: PropTypes.func,
};

ChannelButton.defaultProps = {
  channel: undefined,
  onClick: () => {},
};

export default ChannelButton;

import { useCallback, useState } from "react";

import { REQUEST_PRIORITIES } from "../../../constants";
import FormatedPriorityLevel from "../../../stories/Components/DropDownWithPriorityLevel/FormatedPriorityLevel";

const useDetailsViewData = (serviceRequest) => {
  const [commentsData, setCommentsData] = useState({
    isOpen: false,
    ticketReference: undefined,
    association: undefined,
  });

  // priority DD options
  const priorityOptions = REQUEST_PRIORITIES.map(({ value }) => ({
    value,
    label: FormatedPriorityLevel(value),
  }));

  /**
   * Open comments with ticket reference
   */
  const handleOpenComments = useCallback(() => {
    setCommentsData({
      ticketReference: serviceRequest?.original.reference,
      association: serviceRequest?.original.association,
      isOpen: true,
    });
  }, [serviceRequest?.original]);

  const handleCloseComments = useCallback(() => {
    setCommentsData({ ticketReference: undefined, isOpen: false });
  }, []);

  return {
    priorityOptions,
    commentsData,
    inProgress: serviceRequest?.original?.status === "open",
    handleOpenComments,
    handleCloseComments,
  };
};

export default useDetailsViewData;

import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import {
  REMOVE_POSITIONED_MODALS,
  SET_CALENDAR_DATA,
  TOGGLE_POSITIONED_POPUP,
  VIEW_DAY_FROM_YEAR_POPUP,
} from "../../../constants";
import useMouseTracker from "../../../hooks/useMouseTracker";
import { useAppState } from "../../../state/appState";
import { useModalState } from "../../../state/modalState";
import "./YearCalendar.css";

export default function YearCalendar({ events }) {
  const [{ calendarData, calendarTimezone }] = useAppState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [yearDates, setYearDates] = useState([]);
  const [, dispatch] = useAppState();
  const [, modalDispatch] = useModalState();

  useEffect(() => {
    const currentYear = new Date(calendarData).getFullYear();
    setSelectedYear(currentYear);
  }, [calendarData]);

  useEffect(() => {
    const array1 = [];

    for (let i = 0; i <= 11; i += 1) {
      const year = moment
        .tz(calendarData, calendarTimezone)
        .startOf("year")
        .startOf("day")
        .add(i, "months")
        .format();

      array1.push(year);
    }

    setYearDates(array1);
  }, [selectedYear, calendarData, calendarTimezone]);

  const { mousePosition } = useMouseTracker();

  async function handleClickDay(dateSelected) {
    modalDispatch({
      type: REMOVE_POSITIONED_MODALS,
    });

    dispatch({
      type: SET_CALENDAR_DATA,
      data: dateSelected,
    });

    dispatch({
      type: TOGGLE_POSITIONED_POPUP,
      position: {
        x: mousePosition.x,
        y: mousePosition.y,
      },
      popupData: {
        startDate: moment.tz(dateSelected, calendarTimezone).format(),
        calendarTimezone,
        popupWidth: 239,
      },
      popupType: VIEW_DAY_FROM_YEAR_POPUP,
    });
  }

  return (
    <div className="calendarContainer">
      {yearDates.map((year) => {
        return (
          <Calendar
            onClickDay={(dateSelected) => handleClickDay(dateSelected)}
            events={events}
            value={new Date(calendarData)}
            className="monthClass"
            tileClassName="dayClass"
            key={year}
            currentDate={new Date(calendarData)}
            activeStartDate={new Date(year)}
            prevLabel={null}
            prev2Label={null}
            nextLabel={null}
            next2Label={null}
            view="month"
          />
        );
      })}
    </div>
  );
}

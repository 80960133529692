import React from "react";
import PropTypes from "prop-types";

import tooltipDownArrow from "../../assets/images/tooltipDownArrow.svg";

const tooltipStyle = {
  width: "384px",
  height: "40px",
  top: "3503px",
  left: "2452p",
  padding: "12px 0px 0px 0px",
  borderRadius: "6px",
  backgroundColor: "#027D61",
};

const FreeFlowTooltip = ({ showTooltip }) => {
  return (
    <div style={{ opacity: `${showTooltip ? "100%" : "0"}` }}>
      <div style={tooltipStyle} className="text-center -ml-12">
        <p className="text-xs text-white font-semibold">
          Free Flow will remain locked for duration of workflow
        </p>
      </div>
      <div>
        <img
          className="w-3 h-1.5 ml-36 mb-1"
          src={tooltipDownArrow}
          alt="tootiparrow"
        />
      </div>
    </div>
  );
};

FreeFlowTooltip.propTypes = {
  showTooltip: PropTypes.bool,
};

FreeFlowTooltip.defaultProps = {
  showTooltip: false,
};

export default FreeFlowTooltip;

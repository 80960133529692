import { PropertyAPI } from "@griffingroupglobal/eslib-api";
import sortByProperty from "../Utilities/sortByProperty";

/**
 * Fetch all properties
 * @returns {Promise<Object[]>}
 */
const fetchProperties = async () => {
  const { data } = await PropertyAPI.get();

  let properties = [];

  if (data?.entries) {
    properties = data.entries.map((entry) => {
      const resource = entry?.resource;

      return resource;
    });
  }

  // Sort properties by ES rule
  return sortByProperty(properties, "title");
};

export default fetchProperties;

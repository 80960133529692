import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import { cloneDeep } from "lodash";
import Checkbox from "../Checkbox/Checkbox";
import BaseButton from "../Buttons/BaseButton";
import Dropdown from "../Dropdown/Dropdown";
import yellowExclamationIcon from "../../assets/images/yellowExclamationIcon.svg";
import PlusCirlceIconDarkGreen from "../../assets/images/plusCircleIconDarkGreen.svg";
import {
  getDerivedDate,
  getCurrentYearDate,
  isWeekend,
  years,
  getOrdinalNumber,
} from "../../../helpers/Holidays";
import EditRemoveActions from "../EditRemoveActions/EditRemoveActions";
import {
  ADD_OPEN_MODAL,
  SETTINGS_HOLIDAYS_CREATE_MODAL,
} from "../../../constants";
import { useModalState } from "../../../state/modalState";

const dateCN = (selected) => cntl`
  ${
    selected ? "text-brandDarkGreen font-semibold" : "text-gray-200 font-medium"
  }
`;

const dateStringCN = (selected) => cntl`
  ${selected ? "text-brandDarkGreen" : "text-gray-200"}
`;

const tertiaryButtonCN = cntl`
  text-brandDarkGreen
  text-base
`;

const currentYear = new Date().getFullYear();

const HolidaysSection = ({ systemSettings, dispatchSystemSettings }) => {
  const [, modalDispatch] = useModalState();

  const [isHolidaysSelectAll, setIsHolidaysSelectAll] = useState(false);
  const [selectedYear, setSelectedYear] = useState({
    label: currentYear,
    value: currentYear,
  });
  const [shownHolidays, setShownHolidays] = useState([]);

  // show holidays for the selected year and or with recurrence
  useEffect(() => {
    const defaultHolidays = systemSettings?.holidays
      ?.filter((holiday) => {
        if (
          holiday?.recurrence ||
          new Date(holiday.date).getFullYear() === selectedYear?.value
        ) {
          return true;
        }

        return false;
      })
      .map((holiday) => {
        if (holiday?.recurrence) {
          const updatedHoliday = cloneDeep(holiday);
          updatedHoliday.recurrence.derivedDate = getDerivedDate(
            updatedHoliday,
            selectedYear?.value
          );
          return updatedHoliday;
        }
        return holiday;
      });

    setShownHolidays(defaultHolidays);
  }, [systemSettings, selectedYear]);

  useEffect(() => {
    const isAnyUnselected = shownHolidays?.some((holiday) => {
      if (!holiday.selected) {
        return true;
      }

      return false;
    });

    setIsHolidaysSelectAll(!isAnyUnselected);
  }, [shownHolidays]);

  const onHolidayChange = useCallback(
    (index) => {
      dispatchSystemSettings({
        type: "selectHoliday",
        index,
      });
    },
    [dispatchSystemSettings]
  );

  const onHolidayRemove = useCallback(
    (index) => {
      dispatchSystemSettings({
        type: "removeHoliday",
        index,
      });
    },
    [dispatchSystemSettings]
  );

  const selectAllCodes = useCallback(
    (bool) => {
      dispatchSystemSettings({
        type: "selectAllHolidays",
        value: bool,
      });

      setIsHolidaysSelectAll(bool);
    },
    [dispatchSystemSettings]
  );

  const onUpdateHoliday = useCallback(
    (holiday, isEditingIndex, isAdding) => {
      const selectedHoliday = { ...holiday };

      // To maintain state in modal but remove before updating
      if (
        holiday?.dateOption?.value === "specificDate" ||
        (!holiday?.dateOption?.value && holiday.date)
      ) {
        delete selectedHoliday.recurrence;
      } else {
        delete selectedHoliday.date;
      }

      if (isAdding) {
        dispatchSystemSettings({
          type: "addHoliday",
          selectedHoliday,
        });
      } else {
        dispatchSystemSettings({
          type: "updateHoliday",
          selectedHoliday,
          isEditingIndex,
          selectedYear: selectedYear?.value,
        });
      }
    },
    [dispatchSystemSettings, selectedYear]
  );

  const onEditing = useCallback(
    (existingHoliday, isEditingIndex) => {
      modalDispatch({
        type: ADD_OPEN_MODAL,
        ref: { id: "edit-holiday-modal" },
        modalData: {
          existingHoliday,
          isAdding: false,
          isEditingIndex,
          onUpdateHoliday,
          selectedYear: selectedYear?.value,
        },
        modalType: SETTINGS_HOLIDAYS_CREATE_MODAL,
      });
    },
    [modalDispatch, onUpdateHoliday, selectedYear?.value]
  );

  const handleOpenAddHolidayModal = useCallback(() => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: "new-holiday-modal" },
      modalData: {
        isAdding: true,
        onUpdateHoliday,
        selectedYear: selectedYear?.value,
      },
      modalType: SETTINGS_HOLIDAYS_CREATE_MODAL,
    });
  }, [modalDispatch, onUpdateHoliday, selectedYear?.value]);

  const onAddHoliday = useCallback(() => {
    const date = new Date();
    date.setFullYear(selectedYear?.value);

    handleOpenAddHolidayModal();
  }, [selectedYear, handleOpenAddHolidayModal]);

  return (
    <div className="flex flex-col py-2">
      <div className="w-32">
        <Dropdown
          options={years()}
          value={selectedYear}
          onChange={setSelectedYear}
          disableClear
          disableSort
        />
      </div>
      {!!shownHolidays?.length && (
        <div className="flex flex-row w-full py-4">
          <div className="flex flex-col w-full">
            <div className="flex w-full border-b border-gray-150 py-4 mr-8">
              <Checkbox
                label="Select All"
                labelClassName="text-base text-black font-medium"
                checked={isHolidaysSelectAll}
                onChange={() => selectAllCodes(!isHolidaysSelectAll)}
              />
            </div>
            <div className="pt-4">
              {shownHolidays?.map((holiday, index) => {
                return (
                  <div key={holiday?.id}>
                    <EditRemoveActions
                      index={index}
                      handleRemove={onHolidayRemove}
                      className="pl-0 w-full items-center pb-4"
                      hideRemove={holiday?.count}
                      hideEdit
                    >
                      <div className="flex flex-row">
                        <div className="flex flex-row items-center">
                          <div className="align-top">
                            <Checkbox
                              checked={holiday?.selected}
                              onChange={() => onHolidayChange(index)}
                            />
                          </div>
                          <div className="pl-2 flex">
                            <p className="font-medium">{holiday.display}</p>
                          </div>
                        </div>
                        <div className="flex flex-row pl-4">
                          {(holiday?.date && (
                            <div
                              className="flex flex-row"
                              onClick={() => onEditing(holiday, index)}
                              role="button"
                              onKeyPress={() => onEditing(holiday, index)}
                              tabIndex={0}
                            >
                              <p className={dateCN(holiday?.selected)}>
                                {getCurrentYearDate(
                                  holiday.date,
                                  selectedYear?.value
                                )}
                              </p>
                            </div>
                          )) || (
                            <div
                              className="flex flex-col"
                              role="button"
                              tabIndex={0}
                              onClick={() => onEditing(holiday, index)}
                              onKeyPress={() => onEditing(holiday, index)}
                            >
                              <div>
                                <p className={dateCN(holiday?.selected)}>
                                  {getCurrentYearDate(
                                    holiday.recurrence.derivedDate,
                                    selectedYear?.value
                                  )}
                                </p>
                              </div>
                              <p className={dateStringCN(holiday?.selected)}>
                                (
                                {getOrdinalNumber(
                                  holiday.recurrence.calendarDay
                                )}{" "}
                                {holiday.recurrence.dayOfWeek}
                                {" in "}
                                {holiday.recurrence.month})
                              </p>
                            </div>
                          )}
                          {isWeekend(holiday, selectedYear?.value) && (
                            <BaseButton
                              iconLeft={
                                <img
                                  src={yellowExclamationIcon}
                                  alt="warning"
                                  className="mr-2 w-5 h-5"
                                />
                              }
                              className="px-2"
                              onClick={() => onEditing(holiday, index)}
                            />
                          )}
                        </div>
                      </div>
                    </EditRemoveActions>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-row items-center">
        <img
          className="h-4 w-4"
          src={PlusCirlceIconDarkGreen}
          alt="plus circle icon green"
        />
        <BaseButton
          title="Add Holiday"
          onClick={onAddHoliday}
          className={tertiaryButtonCN}
        />
      </div>
    </div>
  );
};

HolidaysSection.propTypes = {
  systemSettings: PropTypes.shape({
    holidays: PropTypes.arrayOf(
      PropTypes.shape({
        display: PropTypes.string,
        id: PropTypes.string,
        selected: PropTypes.bool,
        date: PropTypes.string,
      })
    ),
  }),
  dispatchSystemSettings: PropTypes.func,
};

HolidaysSection.defaultProps = {
  systemSettings: undefined,
  dispatchSystemSettings: undefined,
};

export default HolidaysSection;

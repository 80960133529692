import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { debounce } from "lodash";
import { useESChatContext } from "../../../context/ChatContext/ESChatContext";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { onAddFilesFunction } from "../../../helpers/Chat";
import { ERROR_SENDING_MESSAGE } from "../../../constants";
import { CustomError } from "../../../helpers/Error";

export default (containerRef, isTrayOpen) => {
  const {
    selectedChannel,
    channels,
    newMsg,
    clientChat,
    channelError: error,
    setChannelError: setError,
    showChannelReply: showReply,
    setShowChannelReply: setShowReply,
    createDirectMessage,
    viewMedia,
    setViewMedia,
  } = useESChatContext();
  const { data: currentUser } = useCurrentUser();

  const inputRef = useRef();

  // Sets focus on the input element when the Chat is opened.
  useEffect(() => {
    let timeout;

    if (isTrayOpen) {
      timeout = setTimeout(() => inputRef?.current?.focus(), 200);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isTrayOpen, selectedChannel]);

  const members = useMemo(() => {
    return Object.values(selectedChannel?.state?.members ?? {})?.reduce(
      (list, item) => {
        if (item?.user_id !== currentUser?.streamId)
          return { members: [...list?.members, item], target: item };
        return { ...list, members: [...list?.members, item] };
      },
      { target: null, members: [] }
    );
  }, [currentUser?.streamId, selectedChannel?.state?.members]);

  // iFrame Implementation
  const smoothScrollTo = useMemo(
    () =>
      debounce(() => {
        // Set a delay (100ms) before scrolling to allow for potential render operations or other reasons
        const renderBuffer = setTimeout(() => {
          const top = containerRef?.current?.scrollHeight;

          // Scroll the identified element into the viewport with smooth behavior
          // block: "start" ensures the element will be aligned to the start of the visible part of the scroll area
          // inline: "nearest" ensures the element will be scrolled into view in the block and inline directions
          containerRef?.current?.scrollTo({
            top,
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }, 100);

        // Return the timeout ID. This is useful for any cleanup operations if needed elsewhere
        return renderBuffer;
      }, 500),
    [containerRef]
  );

  useEffect(() => {
    smoothScrollTo();
  }, [selectedChannel, smoothScrollTo]);

  const [typing, setTyping] = useState(false);

  useEffect(() => {
    if (selectedChannel)
      selectedChannel.on((event) => {
        if (event.channel_id === selectedChannel.id) {
          if (event.type === "typing.start" && !typing) {
            setTyping({ channel: event.channel_id, user: event.user.id });
            smoothScrollTo();
          }
          if (event.type === "typing.stop" && typing) setTyping(false);
        }
      });
    if (typing && typing?.channel !== selectedChannel?.id) setTyping(false);
    // Starting new thread
    if (newMsg && typing) setTyping(false);
  }, [currentUser?.streamId, newMsg, selectedChannel, smoothScrollTo, typing]);

  const [input, setInput] = useState();

  const onChangeMessage = (message) => {
    setInput(message);
  };
  const [files, setFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(false);

  const [messages, setMessages] = useState();

  useEffect(() => {
    setMessages(selectedChannel?.state?.messages);
    smoothScrollTo();
  }, [selectedChannel?.state?.messages, smoothScrollTo]);

  // Send Message
  const sendMessage = useCallback(
    async (msg, createdChannel) => {
      const channelToMessage = createdChannel || selectedChannel;
      /**
       * Send Message Conditions:
       * - text input
       * - resending failed message
       * - Attachments
       */
      if (input || msg || files.length > 0) {
        setInput();
        const newReply = msg ?? {
          text: input,
          attachments: files.map((item) => {
            let type = item.type.split("/")[1];
            if (type.includes("application")) type = "file";
            const f = {
              mime_type: item.type,
              name: item.file.name,
              type,
            };
            if (item?.type?.includes("image")) {
              f.image_url = item.url;
            } else {
              f.asset_url = item.url;
            }

            return f;
          }),
          user: { id: currentUser.streamId },
        };
        // Update messages optimistically

        setMessages([...(channelToMessage?.state?.messages ?? []), newReply]);
        smoothScrollTo();

        // Send Promise Chain
        channelToMessage
          .sendMessage(newReply)
          /**
           * Catch failure:
           * - remove message from state
           * - set Error state with failed message (used in component)
           * - throw error so it bubbles up in case of resend failure
           */
          .catch((e) => {
            console.error("Message attempt unsuccessful", e);
            setMessages((prev) => prev.slice(0, prev.length - 1));
            setError({
              err: ERROR_SENDING_MESSAGE,
              data: { ...newReply, user: { id: currentUser?.streamId } },
            });
            throw new CustomError({ message: ERROR_SENDING_MESSAGE });
          });
        onChangeMessage("");
        setFiles([]);
      }
    },
    [
      currentUser.streamId,
      files,
      input,
      selectedChannel,
      setError,
      smoothScrollTo,
    ]
  );

  const handleDirectMessage = async () => {
    const direct = await createDirectMessage();
    await sendMessage(null, direct);
  };

  const onDrop = useMemo(
    () => onAddFilesFunction({ selectedChannel, setFiles, setLoadingFiles }),
    [selectedChannel]
  );

  const handleRemoveDrop = (ref) => {
    setFiles((prev) => prev.filter((item) => item.file !== ref));
  };

  const resend = useCallback(() => {
    sendMessage(error?.data).then(() => setError());
  }, [error?.data, sendMessage, setError]);

  return {
    target: members.target,
    members: members.members,
    selectedChannel,
    typing,
    sendMessage,
    input,
    onChangeMessage,
    channels,
    newMsg,
    onDrop,
    files,
    handleRemoveDrop,
    loadingFiles,
    messages,
    clientChat,
    error,
    resend,
    showReply,
    setShowReply: (i) => {
      setShowReply((prev) => (prev === i ? null : i));
    },
    createDirectMessage: handleDirectMessage,
    viewMedia,
    closeMedia: () => setViewMedia(false),
    inputRef,
  };
};

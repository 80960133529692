import React from "react";
import TodoMember from "../../../../Pages/Home/TodoTray/TodoElement/TodoMember";
import { PRIMARY_DARK_GREEN } from "../../../../constants";
import Input from "../../Input/Input";
import useTodoFormData from "../../../../Pages/Home/TodoTray/TodoForm/useTodoFormData";
import RequestGridViewDistroListCell from "../../Requests/RequestGridView/RequestGridViewDistroListCell";
import PlusCircleButton from "../../Buttons/PlusCircleButton/PlusCircleButton";
import Checkbox from "../../Checkbox/Checkbox";

const ViewTodoModalContent = ({ todo, dispatch, members, close, edit }) => {
  const {
    onChangeDescription,
    onMemberChange,
    selectedMembers,
    viewMembers,
    selectMembers,
    handleCheckRequiredByAll,
  } = useTodoFormData({ todo, members, edit, close, dispatch });

  return (
    <div className="rounded-md border flex flex-col gap-2 overflow-hidden">
      <div className="grid grid-cols-3 p-4 items-center">
        <p
          className="text-sm font-semibold col-span-1 text-gray-500"
          aria-label="Todo description"
        >
          Title
        </p>
        {!edit ? (
          <p
            className="col-span-2 text-sm text-gray-300 truncate"
            title={todo?.description}
          >
            {todo?.description}
          </p>
        ) : (
          <div className="col-span-2">
            <Input value={todo?.description} onChange={onChangeDescription} />
          </div>
        )}
      </div>

      <div className="rounded-md flex flex-col gap-2 px-4">
        <div
          className={`grid grid-cols-3 border-t py-4 ${
            members.length || "items-center"
          }`}
        >
          <p className="text-sm font-semibold col-span-1 text-gray-500">
            Assign To
          </p>
          {!edit && (
            <>
              {!members ? (
                <p className="text-sm col-span-2 text-gray-300">Me</p>
              ) : (
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2">
                    {members?.slice(0, 5)?.map((mem) => (
                      <TodoMember
                        key={`todo-member-${mem?.user}`}
                        member={mem}
                        closeModal={close}
                      />
                    ))}
                    {members?.length > 5 && (
                      <button
                        className="bg-backgroundGrey shadow-light-lift rounded-full w-7 h-7 p-1 flex justify-center cursor-pointer"
                        style={{ minWidth: "1.75rem" }}
                        type="button"
                        onClick={() => viewMembers()}
                      >
                        <p className="uppercase text-primaryGreen text-xxs truncate self-center font-semibold">
                          +{members?.length - 5}
                        </p>
                      </button>
                    )}
                  </div>
                  {todo?.requiredByAll && (
                    <p className="font-semibold text-primaryGreen text-xs">
                      *All Required
                    </p>
                  )}
                </div>
              )}
            </>
          )}
          {edit && (
            <div>
              <div className="relative flex items-center gap-6">
                {selectedMembers?.length > 0 && (
                  <RequestGridViewDistroListCell
                    distroList={selectedMembers}
                    hideTile
                    handleDeleteFromDistro={(val) =>
                      onMemberChange(val, "removeMember")
                    }
                    isEditing
                  />
                )}
              </div>

              <PlusCircleButton
                title="Add"
                disabled={false}
                className={`flex ${selectedMembers?.length ? "mt-4" : ""}`}
                onClick={selectMembers}
                style={{ color: PRIMARY_DARK_GREEN }}
              />

              {selectedMembers?.length > 1 && (
                <div className="mt-4 flex flex-row justify-start items-center gap-2">
                  <Checkbox
                    size={6}
                    checked={todo?.requiredByAll}
                    onChange={handleCheckRequiredByAll}
                  />

                  <p className="font-normal text-base text-gray-450 w-full min-w-full">
                    All Required
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewTodoModalContent;

import React from "react";
import arrowLeftIcon from "../../../assets/images/arrowLeftIcon.png";

const BackButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="flex self-start items-center mt-4"
      type="button"
    >
      <img
        src={arrowLeftIcon}
        alt="Back"
        style={{ width: "10px", height: "10px" }}
      />
      <span className="ml-2 font-semibold" style={{ color: "#101010" }}>
        Back
      </span>
    </button>
  );
};

export default BackButton;

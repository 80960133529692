import React from "react";
import PropTypes from "prop-types";

const PlusArrowIcon = ({ fill }) => {
  return (
    <svg
      width="22px"
      height="13px"
      viewBox="0 0 22 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>B2A11781-9817-4638-991C-73AA9902E311</title>
      <g
        id="Icons-and-Colors"
        stroke="none"
        strokeWidth="1"
        fill="white"
        fillRule="evenodd"
      >
        <g
          id="Styles---Sampler"
          transform="translate(-2069.000000, -247.000000)"
          fill={fill || "#1CC39D"}
          fillRule="nonzero"
        >
          <g id="Icon/Add-item" transform="translate(2069.000000, 244.000000)">
            <g id="Icon/Add-Item" transform="translate(0.000000, 3.000000)">
              <g id="Group" transform="translate(0.000000, 0.000000)">
                <polygon
                  id="Path"
                  points="11 6 9 6 9 4 7 4 7 6 5 6 5 8 7 8 7 10 9 10 9 8 11 8"
                />
                <path
                  d="M21.707,6.293 L15.707,0.293 C15.512,0.098 15.256,0 15,0 L1,0 C0.447,0 0,0.447 0,1 L0,13 C0,13.553 0.447,14 1,14 L15,14 C15.256,14 15.512,13.902 15.707,13.707 L21.707,7.707 C22.098,7.316 22.098,6.684 21.707,6.293 Z M14.586,12 L2,12 L2,2 L14.586,2 L19.586,7 L14.586,12 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

PlusArrowIcon.propTypes = {
  fill: PropTypes.string,
};

PlusArrowIcon.defaultProps = {
  fill: undefined,
};

export default PlusArrowIcon;

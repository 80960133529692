/* eslint-disable import/prefer-default-export */

import priorityMedium from "../stories/assets/images/priorityMedium.svg";
import priorityHigh from "../stories/assets/images/priorityHigh.svg";
import priorityLow from "../stories/assets/images/priorityLow.svg";

export const priorityImage = (priority) => {
  switch (priority) {
    case "medium": {
      return priorityMedium;
    }
    case "high": {
      return priorityHigh;
    }
    case "low": {
      return priorityLow;
    }
    default:
      return priorityMedium;
  }
};

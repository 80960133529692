import { useEffect, useMemo, useState } from "react";
import { useAssetsOverview } from "./assets";
import { usePropertiesOverview } from "./properties";
import { useProjectsOverview } from "./projects";

const useFormAssociation = ({
  dispatch,
  form,
  associationLock,
  assetLock,
  spaceLock,
}) => {
  // Get the assetsDict, propertiesDict and projectDict from the appState

  const { assetsDict: Asset } = useAssetsOverview();
  const { propertiesDict: Property } = usePropertiesOverview();
  const { projectDict: Project } = useProjectsOverview();

  const dict = useMemo(
    () => ({ Asset, Property, Project }),
    [Asset, Project, Property]
  );
  const [association, setAssociation] = useState(null);

  // Throw an error if dispatch or form is not passed in
  if (!dispatch || !form)
    throw new Error("useFormAssociation requires dispatch and form");
  // useEffect hook that runs when assetsDict and propertiesDict change
  useEffect(() => {
    if (associationLock) {
      setAssociation(dict?.[associationLock?.split("/")[0]][associationLock]);
      dispatch({
        type: "association",
        value: associationLock,
      });
    }
    if (assetLock) {
      dispatch({
        type: "selectAssets",
        value: assetLock,
      });
    }

    if (spaceLock) {
      dispatch({
        type: "selectSpaces",
        value: spaceLock,
      });
    }
  }, [assetLock, spaceLock, associationLock, dict, dispatch]);

  return association;
};

export default useFormAssociation;

import { useMutation, useQueryClient } from "react-query";
import spacesKeys from "./spacesKeys";
import { toastError, toastMessage } from "../../stories/Components/Toast/Toast";
import postSpaceToProject from "../../helpers/Spaces/postSpaceToProject";

const useSpacesPostToProject = (mutationKey) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey,
    mutationFn: postSpaceToProject,
    onSuccess: () => {
      toastMessage("Space was created successfully");
    },
    onError: (error) => {
      console.error("useAddSpaceToProperty", error);
      toastError("Space could not be created. Please try again");
    },
    onSettled: () => {
      queryClient.invalidateQueries(spacesKeys.spaces);
    },
  });
};

export default useSpacesPostToProject;

import { useCallback, useEffect, useState } from "react";
import { WeatherAPI } from "@griffingroupglobal/eslib-api";
import moment from "moment";
import { DateIsAfter, DateIsBetween } from "../helpers/Dates";

const parseForcast = (forecast) => {
  const today = moment().startOf("day").format();
  const pastLimit = moment().startOf("day").subtract("3", "days").format();
  return forecast.reduce(
    (dict, item) => {
      const thisWeek = DateIsAfter(item?.dateTime, today);

      if (thisWeek && dict.week.length < 7) {
        dict.week.push(item);
      } else if (DateIsBetween(pastLimit, today, item?.dateTime)) {
        dict.prev.push(item);
      }
      return dict;
    },
    { week: [], prev: [] }
  );
};

const useDashboard = (weatherRef) => {
  const [weather, setWeather] = useState();
  const [loading, setLoading] = useState(true);
  const reload = useCallback(async () => {
    const weatherData = await WeatherAPI.getByRef(weatherRef)
      .then(({ data }) => data)
      .catch(() => new Error("Error Loading Weather"));

    setWeather(
      weatherData instanceof Error
        ? { error: weatherData?.message }
        : {
            ...weatherData,
            forecasts: parseForcast(weatherData?.forecasts ?? []),
          }
    );
    setLoading(false);
  }, [weatherRef]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { weather, loading };
};

export default useDashboard;

import { useEffect, useState } from "react";

const MAX_TAGS_ALLOWED_IF_COUNT = 2;

const useTagListViewData = (tags = [], showCount) => {
  const [tagsToDisplay, setTagsToDisplay] = useState([]);
  const [showBubble, setShowBubble] = useState(false);
  const [bubbleAmount, setBubbleAmount] = useState(0);

  // This useEffect is responsible for managing the tags to be displayed based on the 'showCount' flag.
  useEffect(() => {
    if (showCount) {
      setTagsToDisplay(tags.slice(0, MAX_TAGS_ALLOWED_IF_COUNT));
      setShowBubble(tags?.length > MAX_TAGS_ALLOWED_IF_COUNT);
      setBubbleAmount(tags?.length - MAX_TAGS_ALLOWED_IF_COUNT);
    } else {
      setTagsToDisplay(tags);
    }
  }, [tags, showCount]);

  return {
    tagsToDisplay,
    showBubble,
    bubbleAmount,
  };
};

export default useTagListViewData;

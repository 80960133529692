// Framework Tools
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Components
import RequestWorkflowStepCardDueOnCell from "./RequestWorkflowStepCardDueOnCell";
import RequestWorkflowStepCardActionStatusCell from "./RequestWorkflowStepCardActionStatusCell";
import RequestWorkflowAttachmentsUploadCell from "./RequestWorkflowAttachmentsUploadCell";

export default function RequestWorkflowStepCardClosedView({
  step,
  handleActionButton,
  allSteps,
  index,
  stepAdvanced,
  commentModalOpen,
  setCommentModalOpen,
  setLoading,
  loading,
  actionValues,
  setActionValues,
  submittalActionReview,
  submittalActionSubmit,
  requestData,
  patchSubmittal,
  isSubmittal,
  attachmentMap,
  setAttachmentMap,
  reload,
  currentUser,
  sidebarOpen,
  commentMap,
}) {
  const [disabled, setDisabled] = useState(false);
  const [activeStep, setActiveStep] = useState(false);

  // State updaters

  useEffect(() => {
    const checkActiveStep = () => {
      const activeStepIndex = allSteps?.findIndex((item) => {
        return item?.users.every((user) => user?.status === "waiting");
      });

      setActiveStep(activeStepIndex === index);
    };

    checkActiveStep();
  }, [allSteps, index]);

  useEffect(() => {
    if (requestData.status === "void") {
      setDisabled(true);
    }
  }, [requestData.status]);
  return (
    <div className="w-full grid grid-cols-5 border my-4 shadow-light-lift">
      {/* Section 1 - Step 1 - Action Pending (status) */}
      <div className="flex border-r col-span-1 items-start flex-col">
        <div className="flex">
          <p className="p-4 text-xs font-semibold">Step {index + 1} - </p>
          <RequestWorkflowStepCardActionStatusCell
            step={allSteps[index]}
            stepAdvanced={stepAdvanced}
            index={index}
            activeStep={activeStep}
            commentMap={commentMap}
          />
        </div>
        <div className="p-4">
          {allSteps[index]?.comments?.map((item) => (
            <div className="flex flex-col" key={item}>
              <p className="text-xs font-semibold">Remarks</p>
              <p className="text-xs">{commentMap[item]?.content}</p>
            </div>
          ))}
        </div>
      </div>
      {/* Section 2 Add attachments button and attachments */}
      <div className="flex border-r col-span-2 items-start">
        <RequestWorkflowAttachmentsUploadCell
          activeStep={activeStep}
          disabled={disabled}
          sidebarOpen={sidebarOpen}
          step={step}
          allSteps={allSteps}
          requestData={requestData}
          patchSubmittal={patchSubmittal}
          isSubmittal={isSubmittal}
          attachmentMap={attachmentMap}
          setAttachmentMap={setAttachmentMap}
          index={index}
          loading={loading}
          setLoading={setLoading}
        />
      </div>
      {/* Section 3 DueOn: Reviewer: Waiting duedate, role, status */}
      <div className="flex justify-between items-start col-span-2 pr-8">
        <RequestWorkflowStepCardDueOnCell
          sidebarOpen={sidebarOpen}
          disabled={disabled}
          actionValues={actionValues}
          commentModalOpen={commentModalOpen}
          setCommentModalOpen={setCommentModalOpen}
          users={step?.users}
          dueDate={step?.dueDate}
          duration={step?.duration}
          handleActionButton={handleActionButton}
          allSteps={allSteps}
          index={index}
          stepAdvanced={stepAdvanced}
          setActionValues={setActionValues}
          submittalActionReview={submittalActionReview}
          submittalActionSubmit={submittalActionSubmit}
          isSubmittal={isSubmittal}
          reload={reload}
          loading={loading}
          setLoading={setLoading}
          currentUser={currentUser}
          requestData={requestData}
          activeStep={activeStep}
        />
      </div>
    </div>
  );
}

// prop types
RequestWorkflowStepCardClosedView.propTypes = {
  requestData: PropTypes.shape({
    status: PropTypes.string,
  }),
  currentUser: PropTypes.shape({}),
  actionValues: PropTypes.shape({}),
  setActionValues: PropTypes.func,
  submittalActionReview: PropTypes.func,
  submittalActionSubmit: PropTypes.func,
  patchSubmittal: PropTypes.func,
  isSubmittal: PropTypes.bool,
  attachmentMap: PropTypes.shape({}),
  setAttachmentMap: PropTypes.func,
  reload: PropTypes.func,
  setLoading: PropTypes.func,
  loading: PropTypes.bool,
  allSteps: PropTypes.arrayOf(
    PropTypes.shape({
      comments: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
  index: PropTypes.number,
  stepAdvanced: PropTypes.shape({}),
  handleActionButton: PropTypes.func,
  commentModalOpen: PropTypes.bool,
  setCommentModalOpen: PropTypes.func,
  step: PropTypes.shape({
    dueDate: PropTypes.string,
    duration: PropTypes.number,
    users: PropTypes.arrayOf({
      user: PropTypes.string,
    }),
    status: PropTypes.string,
  }),
  sidebarOpen: PropTypes.bool,
  commentMap: PropTypes.shape({}),
};

// default props
RequestWorkflowStepCardClosedView.defaultProps = {
  allSteps: undefined,
  currentUser: undefined,
  actionValues: undefined,
  commentMap: undefined,
  setActionValues: undefined,
  submittalActionReview: undefined,
  submittalActionSubmit: undefined,
  requestData: undefined,
  patchSubmittal: undefined,
  isSubmittal: undefined,
  attachmentMap: undefined,
  setAttachmentMap: undefined,
  reload: undefined,
  setLoading: undefined,
  loading: false,
  index: undefined,
  stepAdvanced: undefined,
  handleActionButton: undefined,
  commentModalOpen: false,
  setCommentModalOpen: undefined,
  step: {
    dueDate: "",
    duration: 10,
    users: [{ user: "" }],
    status: "",
  },
  sidebarOpen: false,
};

import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import starIcon from "../../assets/images/starIcon.svg";
import starDarkenedGreenIcon from "../../assets/images/starDarkenedGreen.svg";
import downloadDarkenedGreenIcon from "../../assets/images/downloadDarkenedGreenIcon.svg";
import downloadDisabledIcon from "../../assets/images/downloadDisabledIcon.svg";
import trashDisabledIcon from "../../assets/images/trashDisabledIcon.svg";
import deleteGreenIcon from "../../assets/images/deleteGreenIcon.svg";
import IconButton from "../Buttons/IconButton";
import InfoTooltip from "../InfoTooltip/InfoTooltip";
import { assetMediaTooltip, assetMediaTooltipTitle } from "../../../constants";

const buttonCN = (sopClass) => cntl`
flex
self-center
ml-4
justify-center
${sopClass}
`;

const MediaWidgetHeader = ({
  hasWritePermission,
  hasDeletePermission,
  selected,
  openImageDialog,
  disableEditing,
  isSop,
  modal,
  handleDownloadSelected,
}) => {
  const disabled = disableEditing || !selected?.length;
  // to allow setting media as primary, exactly one media selected and it must be an image
  const allowSetPrimary =
    selected?.length === 1 && selected?.[0]?.contentType?.includes("image");
  return (
    <div
      className={`${
        modal
          ? "flex w-full justify-between border-b mb-6"
          : "flex w-full justify-between"
      }`}
    >
      <div className="flex" />
      <div className="flex mb-5">
        {!isSop && (
          <div className={buttonCN()}>
            <InfoTooltip
              toolTipClassName="self-end"
              title={assetMediaTooltipTitle}
              infoData={assetMediaTooltip}
            />
          </div>
        )}
        {!isSop && hasWritePermission && (
          // set selected image as primary
          <IconButton
            className={`${!allowSetPrimary && "cursor-default"}`}
            iconLeft={
              <img
                alt=""
                src={allowSetPrimary ? starDarkenedGreenIcon : starIcon}
              />
            }
            colorCN="text-brandGreen font-semiBold"
            onClick={() => {
              if (allowSetPrimary) openImageDialog("primary");
            }}
            wrapperClassName={buttonCN()}
          />
        )}
        {/* download selected image */}
        <IconButton
          className={`${disabled && "cursor-default"}`}
          iconLeft={
            <img
              alt=""
              src={disabled ? downloadDisabledIcon : downloadDarkenedGreenIcon}
            />
          }
          onClick={() => {
            if (!disabled) handleDownloadSelected();
          }}
          colorCN="text-brandGreen font-semiBold"
          wrapperClassName={buttonCN()}
        />
        {/* delete selected image */}
        {hasDeletePermission && (
          <IconButton
            className={`${disabled && "cursor-default"}`}
            iconLeft={
              <img
                alt=""
                src={disabled ? trashDisabledIcon : deleteGreenIcon}
              />
            }
            onClick={() => {
              if (!disabled) openImageDialog("delete");
            }}
            colorCN="text-brandGreen font-semiBold"
            wrapperClassName={buttonCN()}
          />
        )}
      </div>
    </div>
  );
};

MediaWidgetHeader.propTypes = {
  selected: PropTypes.arrayOf(
    PropTypes.shape({ contentType: PropTypes.string })
  ),
  openImageDialog: PropTypes.func,
  disableEditing: PropTypes.bool,
  isSop: PropTypes.bool,
  modal: PropTypes.bool,
  handleDownloadSelected: PropTypes.func,
  hasWritePermission: PropTypes.bool,
  hasDeletePermission: PropTypes.bool,
};

MediaWidgetHeader.defaultProps = {
  selected: [],
  openImageDialog: undefined,
  disableEditing: false,
  isSop: false,
  modal: false,
  handleDownloadSelected: undefined,
  hasWritePermission: true,
  hasDeletePermission: true,
};

export default MediaWidgetHeader;

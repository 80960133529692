import React from "react";
import PropTypes from "prop-types";
import PrimaryButton from "../Buttons/PrimaryButton";
import useCreateReportButton from "./useCreateReportButtonData";

const CreateReportButton = ({ showReportButton }) => {
  const { canCreate, shouldDisableButton, handleOpenModal } =
    useCreateReportButton();

  if (showReportButton && canCreate) {
    return (
      <PrimaryButton
        saveButton
        saveButtonTitle="Create Report"
        disabled={shouldDisableButton}
        onClick={handleOpenModal}
      />
    );
  }

  return null;
};

CreateReportButton.defaultProps = {
  showReportButton: false,
};

CreateReportButton.propTypes = {
  showReportButton: PropTypes.bool,
};

export default CreateReportButton;

import { cloneDeep } from "lodash";
import { useCallback, useMemo } from "react";
import { ptoTypeSchema } from "../../../../helpers/FormValidations";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import { useAppState } from "../../../../state/appState";

const usePtoGeneralSectionData = ({
  systemSettings,
  dispatchSystemSettings,
  setIsValid,
}) => {
  const [{ financialsConfiguration }] = useAppState();

  const isLocked = useMemo(() => {
    return (
      financialsConfiguration?.financials?.payroll?.locked ||
      financialsConfiguration?.financials?.period?.locked
    );
  }, [financialsConfiguration]);

  const onSelectPtoGeneralType = useCallback(
    (typeIndex, ptoType, value) => {
      dispatchSystemSettings({
        type: "selectPtoGeneralType",
        typeIndex,
        ptoType,
        selected: value,
      });
    },
    [dispatchSystemSettings]
  );

  const onChangePtoGeneralAccrualType = useCallback(
    (newValue, typeIndex) => {
      dispatchSystemSettings({
        type: "changePtoGeneralAccrualType",
        typeIndex,
        value: newValue.value,
      });
    },
    [dispatchSystemSettings]
  );

  const onChangePtoGeneralTypeNumHours = useCallback(
    (value, typeIndex) => {
      dispatchSystemSettings({
        type: "changePtoGeneralTypeNumHours",
        typeIndex,
        value: value ? parseInt(value, 10) : "",
      });
    },
    [dispatchSystemSettings]
  );

  const onChangePtoGeneralTypeMaxRollOver = useCallback(
    (value, typeIndex) => {
      dispatchSystemSettings({
        type: "changePtoGeneralTypeMaxRollover",
        typeIndex,
        value: value ? parseInt(value, 10) : "",
      });
    },
    [dispatchSystemSettings]
  );

  const onChangePtoGeneralAvailabilityDate = useCallback(
    (value, typeIndex) => {
      dispatchSystemSettings({
        type: "changePtoGeneralAvailabilityDate",
        typeIndex,
        value,
      });
    },
    [dispatchSystemSettings]
  );

  const onChangePtoGeneralTypeAvailabilityDays = useCallback(
    (value, typeIndex) => {
      dispatchSystemSettings({
        type: "changePtoGeneralTypeAvailabilityDays",
        typeIndex,
        value,
      });
    },
    [dispatchSystemSettings]
  );

  const onChangePtoGeneralTypeMaxAccrual = useCallback(
    (value, typeIndex) => {
      dispatchSystemSettings({
        type: "changePtoGeneralTypeMaxAccrual",
        typeIndex,
        value: value?.value,
      });
    },
    [dispatchSystemSettings]
  );

  const onChangePtoGeneralTypeNegativeValues = useCallback(
    (typeIndex, ptoType, value) => {
      dispatchSystemSettings({
        type: "onChangePtoGeneralTypeNegativeValues",
        typeIndex,
        ptoType,
        allowNegatives: value,
      });
    },
    [dispatchSystemSettings]
  );

  useDidMountEffect(() => {
    const validationFields = [];
    systemSettings?.ptoGeneralTypes?.forEach((ptoType) => {
      const clonedPtoType = cloneDeep(ptoType);
      if (!ptoType.availability) {
        clonedPtoType.availability = {};
      }
      validationFields.push(ptoTypeSchema.isValid(clonedPtoType));
    });
    Promise.all(validationFields).then((values) => {
      setIsValid(!values.some((field) => field === false));
    });
  }, [systemSettings?.ptoGeneralTypes, setIsValid]);

  return {
    onSelectPtoGeneralType,
    onChangePtoGeneralAccrualType,
    onChangePtoGeneralTypeNumHours,
    onChangePtoGeneralTypeMaxRollOver,
    onChangePtoGeneralAvailabilityDate,
    onChangePtoGeneralTypeAvailabilityDays,
    onChangePtoGeneralTypeMaxAccrual,
    onChangePtoGeneralTypeNegativeValues,
    isLocked,
  };
};

export default usePtoGeneralSectionData;

import { useReducer } from "react";
import { cloneDeep } from "lodash";

const defaultState = {};

const reducer = (budget, action) => {
  switch (action.type) {
    case "reset":
      return cloneDeep(action.budget) ?? defaultState;
    case "financialCode": {
      const codes = action?.value?.value?.split(" ");

      return {
        ...budget,
        financialCode: {
          ...budget.financialCode,
          division: codes?.[0],
          code: codes?.[1],
          subcode: codes?.[2],
        },
      };
    }
    case "building":
      return {
        ...budget,
        building: action?.value?.value,
        space: undefined,
      };
    case "space":
      return {
        ...budget,
        space: action?.value?.value,
      };
    case "group":
      return {
        ...budget,
        group: action.value?.value ? action.value : undefined,
        isEditingGroup: action.isEditingGroup,
      };
    case "location":
      return {
        ...budget,
        location: action.value.location?.value,
        // locationId: action.value.location?.id,
        space: action.value.space?.value,
        // buildingId: action.value.building?.id,
        level: action.value.level?.value,
        // levelId: action.value.level?.id,
      };
    case "quantity":
      return {
        ...budget,
        quantity: action.value,
      };
    case "unitOfMeasure":
      return {
        ...budget,
        unitOfMeasure: action.value.value,
        highRange: {
          ...budget?.highRange,
          unitOfMeasure: budget?.isCostRange ? action?.value?.value : undefined,
        },
      };
    case "costPerUnit":
      return {
        ...budget,
        costPerUnit: action.value,
      };
    case "adjustmentUnit":
      return {
        ...budget,
        adjustment: {
          ...budget.adjustment,
          adjustmentUnit: action.value,
        },
      };
    case "arithmeticUnit":
      return {
        ...budget,
        adjustment: {
          ...budget.adjustment,
          arithmeticUnit: action.value,
        },
      };
    case "adjustment":
      return {
        ...budget,
        adjustment: {
          ...budget.adjustment,
          amount: action.value,
        },
      };
    case "liveBudget":
      return {
        ...budget,
        liveBudget: action.value,
      };
    case "isBillable":
      return {
        ...budget,
        isBillable: action.value,
      };
    case "allowance":
      return {
        ...budget,
        allowance: action.value,
      };
    case "applyRetainage":
      return {
        ...budget,
        applyRetainage: action.value,
      };
    case "billed":
      return {
        ...budget,
        billed: action.value,
      };
    case "notes":
      return {
        ...budget,
        notes: action.value,
      };
    case "description":
      return {
        ...budget,
        description: action.value,
      };
    case "vendor":
      return {
        ...budget,
        vendor: action?.value?.value,
      };
    case "rateAndOthers":
      return {
        ...budget,
        rate: action?.rate?.value,
        unitOfMeasure: action?.unitOfMeasure?.value,
        costPerUnit: action?.costPerUnit,
        highRange: {
          ...budget?.highRange,
          rate: budget?.isCostRange ? action?.rate?.value : undefined,
          unitOfMeasure: budget?.isCostRange
            ? action?.unitOfMeasure?.value
            : undefined,
          costPerUnit: budget?.isCostRange ? action?.costPerUnit : undefined,
        },
        vendor: action?.vendor,
      };
    case "calculationAndQuantity":
      return {
        ...budget,
        calculation: action?.calculation,
        quantity: action?.quantity,
      };
    case "isCostRange":
      return {
        ...budget,
        isCostRange: action?.value,
        highRange: {
          ...budget?.highRange,
          rate: budget?.rate,
          unitOfMeasure: budget?.unitOfMeasure,
          costPerUnit: budget?.costPerUnit,
          calculation: budget?.calculation,
          quantity: budget?.quantity,
          adjustment: budget?.adjustment,
          allowance: budget?.allowance,
          isBillable: budget?.isBillable,
          applyRetainage: budget?.applyRetainage,
        },
      };
    case "highRangeCostPerUnit":
      return {
        ...budget,
        highRange: {
          ...budget?.highRange,
          costPerUnit: action?.value,
        },
      };
    case "highRangeCalculationAndQuantity":
      return {
        ...budget,
        highRange: {
          ...budget?.highRange,
          calculation: action?.calculation,
          quantity: action?.quantity,
        },
      };
    case "highRangeLiveBudget":
      return {
        ...budget,
        highRange: {
          ...budget?.highRange,
          liveBudget: action?.value,
        },
      };
    case "highRangeArithmeticUnit":
      return {
        ...budget,
        highRange: {
          ...budget?.highRange,
          adjustment: {
            ...budget?.highRange?.adjustment,
            arithmeticUnit: action.value,
          },
        },
      };
    case "highRangeAdjustmentUnit":
      return {
        ...budget,
        highRange: {
          ...budget?.highRange,
          adjustment: {
            ...budget?.highRange?.adjustment,
            adjustmentUnit: action.value,
          },
        },
      };
    case "highRangeAdjustment":
      return {
        ...budget,
        highRange: {
          ...budget?.highRange,
          adjustment: {
            ...budget?.highRange?.adjustment,
            amount: action.value,
          },
        },
      };
    case "highRangeAllowance":
      return {
        ...budget,
        highRange: {
          ...budget?.highRange,
          allowance: action?.value,
        },
      };
    case "highRangeIsBillable":
      return {
        ...budget,
        highRange: {
          ...budget?.highRange,
          isBillable: action?.value,
        },
      };
    case "highRangeApplyRetainage":
      return {
        ...budget,
        highRange: {
          ...budget?.highRange,
          applyRetainage: action?.value,
        },
      };
    case "reallocateFromLineitem":
      return {
        ...budget,
        reallocateFromLineitem: action?.reallocateFromLineitem,
        reallocateTotalAmount: action?.reallocateTotalAmount,
      };
    case "reallocateToLineitem":
      return {
        ...budget,
        reallocateToLineitem: action?.reallocateToLineitem,
      };
    case "reallocateAmount":
      return {
        ...budget,
        reallocateAmount: action?.reallocateAmount,
      };
    case "resetReallocateLineitems":
      return {
        ...budget,
        reallocateToLineitem: null,
        reallocateFromLineitem: null,
        reallocateAmount: null,
        reallocateTotalAmount: null,
      };
    case "splitValue":
      return {
        ...budget,
        splitValue: action?.value,
      };
    case "vendorUpdate":
      return {
        ...budget,
        vendor: action?.vendor?.value,
        id: action?.id || budget?.id,
        metadata: action?.metadata || budget?.metadata,
      };
    default:
      return budget;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};

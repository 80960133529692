import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { hours } from "../../../../constants";
import {
  buildTree,
  checkRoot,
  getPreviousNodePath,
  getRootDepth,
  percentageOfDayElapsed,
} from "../../../../helpers/Calendar";
import DayEvent from "../DayEvent";
import useRelativeAssociations from "../../../../hooks/useRelativeAssociations";
import { useAppState } from "../../../../state/appState";

const DayEventMap = ({ value, events, handleEventClick, currentDay }) => {
  const { eventFilter: filter } = useRelativeAssociations();
  const [{ calendarTimezone }] = useAppState();
  /**
   * Builds linked list
   * event ->[...(events starting during event -> [...(and so forth)])]
   */
  const handleEventHeirarchy = useCallback((eventsOnDay) => {
    const day = eventsOnDay;

    const tree = [];

    day.forEach((event) => {
      if (!checkRoot(tree, event.id)) {
        tree.push(buildTree(event, day));
      }
    });

    return tree;
  }, []);

  const brief = useMemo(() => {
    return handleEventHeirarchy(filter(events?.[value])?.brief);
  }, [events, filter, handleEventHeirarchy, value]);

  /**
   * Maps multi-level tree of events
   * viewBox Values: (-strokeWidth * 2) (hides top stroke) (width) (height = # hours in a day * cell height)
   */
  return (
    <svg
      viewBox={`-0.5 -0.125 296 ${(hours.length - 1) * 15}`}
      width="100%"
      style={{ position: "absolute", top: 0 }}
    >
      {brief.map((event, index) => {
        const grouped = event.getGroup();
        const mappedEvents = [];
        //  Find Root Node Deepest Child
        mappedEvents.push(
          <DayEvent
            event={event}
            index={index}
            subIndex={event?.level}
            group={getRootDepth(grouped)}
            onClick={handleEventClick}
            h={(hours.length - 1) * 15}
            calendarTimezone={calendarTimezone}
          />
        );
        if (event.group.length > 0) {
          grouped.forEach((subEvent) => {
            mappedEvents.push(
              <DayEvent
                event={subEvent}
                index={index}
                subIndex={subEvent?.level}
                //  Find Deepest Child (Top<-(Node)->Bottom)
                group={getRootDepth([
                  ...getPreviousNodePath(subEvent),
                  ...subEvent.getGroup(),
                ])}
                onClick={handleEventClick}
                h={(hours.length - 1) * 15}
                calendarTimezone={calendarTimezone}
              />
            );
          });
        }
        return mappedEvents;
      })}

      <g id="now-indicator">
        <title>{moment().format("h:mma")}</title>
        <circle
          fill={currentDay ? "red" : "rgba(0,0,0,0)"}
          cx={15}
          cy={percentageOfDayElapsed() * 360}
          r={1}
          opacity={0.8}
        />
        <line
          fill={currentDay ? "red" : "rgba(0,0,0,0)"}
          x1={15}
          x2={296}
          y1={percentageOfDayElapsed() * 360}
          y2={percentageOfDayElapsed() * 360}
          stroke={currentDay ? "red" : "rgba(0,0,0,0)"}
          strokeWidth="0.5"
          opacity={0.5}
        />
      </g>
    </svg>
  );
};

DayEventMap.propTypes = {
  value: PropTypes.string,
  events: PropTypes.shape({}),
  handleEventClick: PropTypes.func,
  currentDay: PropTypes.bool,
};

DayEventMap.defaultProps = {
  value: undefined,
  events: undefined,
  handleEventClick: undefined,
  currentDay: false,
};

export default DayEventMap;

import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import useQueryNotFoundNavigation from "../../../hooks/navigation/useQueryNotFoundNavigation";
import { useSingleTask } from "../../../hooks/tasks";
import useSopVersionForTask from "../../../hooks/useSopVersionForTask";
import useURLQueryParameter from "../../../hooks/useURLQueryParameter";

const useTaskSingleData = () => {
  const [currentTask, setCurrentTask] = useState(null);
  const history = useHistory();
  const { taskId } = useParams();

  const instanceStartDateParam = useURLQueryParameter("instanceStartDate");

  const { singleTask, singleTaskLoading, singleTaskError } = useSingleTask(
    taskId,
    instanceStartDateParam
  );

  // Redirect to 404 page if resource is not found
  useQueryNotFoundNavigation({ error: singleTaskError });

  const { usedSopDict } = useSopVersionForTask({ currentTask });

  useEffect(() => {
    if (singleTask) {
      setCurrentTask(singleTask);
    }
  }, [singleTask]);

  const clearCurrentTask = () => {
    history.push("/tasks");
  };

  return {
    currentTask,
    singleTaskLoading,
    setCurrentTask,
    usedSopDict,
    clearCurrentTask,
  };
};

export default useTaskSingleData;

import React, { useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import placeholderImage from "../../assets/images/placeholderImage.png";
import addIcon from "../../assets/images/plusCircleIconGreen.svg";
import trashIcon from "../../assets/images/deleteGreenIcon.svg";
import IconButton from "../Buttons/IconButton";
import Input from "../Input/Input";
import Dropdown from "../Dropdown/Dropdown";
import TertiaryButton from "../Buttons/TertiaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";
import FileForm from "../FileForm/FileForm";
import Modal from "../Modal/Modal";
import CarouselView from "../CarouselView/CarouselView";

const EditSpaceForm = ({
  isEdit,
  space,
  building,
  setEditSpaceId,
  spaceTypes,
  levels,
  dispatch,
  handleAddSpaceImage,
}) => {
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const onChangeSpaceName = (value) => {
    dispatch({
      type: "spaceName",
      buildingId: building.id,
      spaceId: space.id,
      name: value,
    });
  };

  const onChangeSpaceType = (val) => {
    dispatch({
      type: "spaceType",
      buildingId: building.id,
      spaceId: space.id,
      spaceType: val.value,
    });
  };

  const onChangeSpaceLevel = (val) => {
    dispatch({
      type: "spaceLevel",
      buildingId: building.id,
      spaceId: space.id,
      level: val.value,
    });
  };

  const onRemoveSpace = () => {
    dispatch({
      type: "removeSpace",
      buildingId: building.id,
      spaceId: space.id,
    });

    setEditSpaceId(null);
  };

  const showFileForm = () => {
    setShowFileUpload((prev) => !prev);
  };

  const validateFields = () => {
    if (!space?.name) return true;
    return false;
  };

  return (
    <div className="border-2 border-brandGreen flex flex-col p-4 rounded w-full h-auto">
      <div className="flex justify-between pb-4 px-2 mb-4">
        <h3 className="font-bold text-gray-300">
          {isEdit ? "Edit Space" : "Add Space"}
        </h3>
        <IconButton icon={trashIcon} onClick={onRemoveSpace} />
      </div>
      <Input
        placeholder="Name"
        label="Space Name"
        className="pb-4 px-4"
        value={space?.name}
        onChange={onChangeSpaceName}
        validation={yup.string().required()}
      />
      <Dropdown
        label="Type"
        className="pb-4 px-4"
        value={spaceTypes?.find((type) => type.value === space?.type)}
        options={spaceTypes}
        onChange={onChangeSpaceType}
        validation={yup.mixed()}
      />
      <Dropdown
        label="Level"
        className="pb-4 px-4"
        value={levels?.find((level) => level.value === space?.level)}
        options={levels}
        onChange={onChangeSpaceLevel}
      />
      <div className="flex py-4 px-4 overflow-x-scroll">
        {space?.images?.map((image, idx) => (
          <div
            key={`${image?.contentsUrl}-${image?.name}`}
            className="flex mr-2 min-w-max"
            onClick={() => setSelectedImageIndex(idx)}
            onKeyDown={() => setSelectedImageIndex(idx)}
            role="button"
            tabIndex={0}
          >
            <img
              className="h-12 object-cover w-12 rounded border border-gray-200"
              src={image?.contentsUrl || placeholderImage}
              alt={image?.name || "placeholder"}
            />
          </div>
        ))}
      </div>
      <IconButton
        icon={addIcon}
        title="Add Media"
        className="text-gray-200 px-2"
        onClick={showFileForm}
      />
      {showFileUpload && (
        <FileForm
          resource={space}
          onAddPhoto={(image) => {
            handleAddSpaceImage(image, building.id, space.id);
          }}
          hideUploadedFiles
        />
      )}
      <div className="flex justify-end pt-6">
        <TertiaryButton
          title="Cancel"
          className="mx-2"
          onClick={() => setEditSpaceId(null)}
        />
        <PrimaryButton
          title="Save"
          onClick={() => setEditSpaceId(null)}
          disabled={validateFields()}
        />
      </div>
      <Modal
        isOpen={selectedImageIndex !== null}
        title="View Media"
        primaryButtonTitle="Close"
        primaryButtonOnClick={() => setSelectedImageIndex(null)}
        onRequestModalClose={() => setSelectedImageIndex(null)}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <CarouselView items={space?.images} startIndex={selectedImageIndex} />
      </Modal>
    </div>
  );
};

EditSpaceForm.propTypes = {
  isEdit: PropTypes.bool,
  handleAddSpaceImage: PropTypes.func,
  space: PropTypes.shape({
    id: PropTypes.string,
    level: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        thumbnailUrl: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
  building: PropTypes.shape({
    id: PropTypes.string,
    spaces: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        level: PropTypes.string,
        type: PropTypes.string,
        name: PropTypes.string,
        images: PropTypes.arrayOf(
          PropTypes.shape({
            thumbnailUrl: PropTypes.string,
            name: PropTypes.string,
          })
        ),
      })
    ),
  }),
  setEditSpaceId: PropTypes.func,
  levels: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  spaceTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  dispatch: PropTypes.func,
};

EditSpaceForm.defaultProps = {
  isEdit: false,
  building: [],
  handleAddSpaceImage: undefined,
  setEditSpaceId: undefined,
  levels: [],
  space: undefined,
  spaceTypes: [],
  dispatch: undefined,
};

export default EditSpaceForm;

import React from "react";
import PropTypes from "prop-types";
import VideoPreview from "./VideoPreview";
import ImagePreview from "./ImagePreview";
import useThumbnailViewData from "./useThumbnailViewData";

/**
 * Component that renders a clickable media file
 */
const Thumbnail = ({ file, fileObject, isEditing }) => {
  const { fileUrl, isVideoType, handleOpenFile } = useThumbnailViewData({
    file,
    fileObject,
    isEditing,
  });

  return (
    <button type="button" className="w-full h-full" onClick={handleOpenFile}>
      {isVideoType ? (
        <VideoPreview fileUrl={fileUrl} />
      ) : (
        <ImagePreview fileUrl={fileUrl} />
      )}
    </button>
  );
};

Thumbnail.defaultProps = {
  file: undefined,
  fileObject: undefined,
  isEditing: false,
};

Thumbnail.propTypes = {
  /**
   * ES File representation
   * {ref: File/1111-2222-3333, category: "Photo"}
   */
  file: PropTypes.shape({
    ref: PropTypes.string,
    category: PropTypes.string,
  }),

  /**
   * File from picker
   * { original: {}, id: 123455, name: "My File.jpeg", type: "jpeg", }
   */
  fileObject: PropTypes.shape({}),

  isEditing: PropTypes.bool,
};

export default Thumbnail;

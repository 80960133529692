import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useInView } from "react-intersection-observer";
import useUserDict from "../../../../../hooks/useUserDict";
import useTaskHistory from "../../../../../hooks/useTaskHistory";
import getTaskActivityDescription from "../../../../../helpers/Task/getTaskActivityDescription";

const useTaskActivity = ({ currentTask }) => {
  const { data, hasNextPage, fetchNextPage } = useTaskHistory(
    currentTask?.reference
  );
  const { data: userDict } = useUserDict();
  const [tableData, setTableData] = useState([]);
  const [searchState, setSearchState] = useState({
    filteredData: null,
    value: null,
  });

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  const handleSearch = useCallback((arr, value) => {
    setSearchState({ filteredData: arr, value });
  }, []);

  const columns = [
    {
      Header: "Activity",
      accessor: "activity",
      isPrimary: true,
    },
    {
      Header: "Date and Time",
      accessor: "date",
      Cell: ({ value }) => (
        <div className="flex">
          <p>{moment(value).format("dddd, MMMM Do YYYY, h:mm a")}</p>
        </div>
      ),
      isPrimary: true,
      width: 250,
    },
    {
      Header: "Created By",
      accessor: "fullName",
      isPrimary: true,
    },
  ];

  useEffect(() => {
    if (userDict && data?.pages?.length) {
      let activities = [];
      data?.pages?.forEach((page) => {
        activities = [
          ...activities,
          ...page.data.map((entry) => {
            const { activity, date, fullName } = getTaskActivityDescription({
              entry,
              userDict,
            });

            return {
              activity,
              date,
              fullName,
              id: entry.id,
            };
          }),
        ];
      });

      setTableData(activities);
    }
  }, [data?.pages, userDict]);

  return {
    columns,
    tableData,
    fetchNextPage,
    hasNextPage,
    ref,
    searchState,
    handleSearch,
  };
};

export default useTaskActivity;

import { useEffect, useMemo, useState } from "react";
import { formatSelectUser } from "../../../../../helpers/Formatters";
import useGetMergedVendorInvoices from "../../../../../hooks/useGetMergedVendorInvoices";
import usePatchMergedVendorInvoice from "../../../../../hooks/usePatchMergedVendorInvoice";
import useGetBudgetLineitemByInvoice from "../../../../../hooks/useGetInvoiceLineitems";

const useDocumentVendorInvoicesData = ({
  project,
  userList,
  documentsDict,
  csiCodes,
  document,
}) => {
  const { data } = useGetMergedVendorInvoices(project?.reference);
  const budegtId = project?.budget?.split("/")?.[1];
  const { data: budgetData } = useGetBudgetLineitemByInvoice(
    budegtId,
    document?.reference
  );
  const { mutate: patchVI } = usePatchMergedVendorInvoice();
  const [userData, setUserData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (userList?.length) {
      setUserData(() => {
        return userList?.map((usr) => {
          return formatSelectUser({
            ...usr,
            role: usr.roleName,
          });
        });
      });
    }
  }, [userList]);

  const docBudgetLines = useMemo(() => {
    if (data?.vendorinvoices?.length && budgetData?.budgetLineItems?.length) {
      return data?.vendorinvoices?.reduce((acc, doc) => {
        // eslint-disable-next-line no-param-reassign
        doc.workingLineItems = doc.lineItems.map((item) =>
          budgetData?.budgetLineItems.find((li) => li.reference === item)
        );

        // eslint-disable-next-line no-param-reassign
        doc.workingLineItems = doc.workingLineItems?.map((item) => {
          const lineData = doc.state?.lineItemData?.[item?.id] ?? {};
          const sov = doc.state?.sov ?? {};
          const sovData = sov[item?.id] ?? [];
          const lineitemToVendorInvoiceInfo =
            doc.state?.lineitemToVendorInvoiceInfo?.[item.reference];

          const result = {
            ...item,
            csiCode:
              csiCodes.find(
                (code) => code.value === item?.csiCode?.split(" -")[0]
              )?.label ?? "",
            ...lineData,
            sov: sovData.map((sovRow) => ({
              ...sovRow,
              poName: documentsDict[sovRow?.po]?.name,
            })),
            // VI specific ...
            parentPurchaseOrder: lineitemToVendorInvoiceInfo?.po?.map((po) => {
              const res = documentsDict[po];
              return {
                name: res?.name,
                reference: po,
              };
            }),
            committedValues: lineitemToVendorInvoiceInfo?.committedValues,
            workCompletedThisPeriod:
              lineitemToVendorInvoiceInfo?.workCompletedThisPeriod,
            workCompletedPreviousPeriod:
              lineitemToVendorInvoiceInfo?.workCompletedPreviousPeriod,
            workCompletedToDate:
              lineitemToVendorInvoiceInfo?.workCompletedToDate,
            percentCompleteOfCommittedValues:
              lineitemToVendorInvoiceInfo?.percentCompleteOfCommittedValues,
            retainage: lineitemToVendorInvoiceInfo?.retainage,
            holdback: lineitemToVendorInvoiceInfo?.holdback,
            workCompletedToDateLessRetainageAndHoldback:
              lineitemToVendorInvoiceInfo?.workCompletedToDateLessRetainageAndHoldback,
            previousPaymentDue: lineitemToVendorInvoiceInfo?.previousPaymentDue,
            currentPaymentDue: lineitemToVendorInvoiceInfo?.currentPaymentDue,
            balanceToFinish: lineitemToVendorInvoiceInfo?.balanceToFinish,
            // Invoice specific
            parentVendorInvoice: doc,
          };

          result.vendorName = userData.find(
            (user) => user.value === result.vendor
          )?.label;

          return result;
        });

        // eslint-disable-next-line no-param-reassign
        acc = [...acc, ...doc.workingLineItems];
        return acc;
      }, []);
    }
    return [];
  }, [
    csiCodes,
    data?.vendorinvoices,
    documentsDict,
    budgetData?.budgetLineItems,
    userData,
  ]);

  const handleSaveSov = (rowId, lines, vendorInvoice) => {
    const state = vendorInvoice.state ?? {};
    const sov = { ...(state.sov ?? {}) };
    sov[rowId] = lines;

    patchVI({
      document: vendorInvoice,
      updatedDocument: {
        ...vendorInvoice,
        state: { ...state, sov },
      },
    });
  };

  return {
    handleSaveSov,
    docBudgetLines,
    isEditing,
    setIsEditing,
    budgetData,
  };
};

export default useDocumentVendorInvoicesData;

import React, { useCallback, useState } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import DocumentTile from "../DocumentTile/DocumentTile";
import BaseButton from "../Buttons/BaseButton";

const tertiaryButtonCN = cntl`
  text-brandGreen
  py-4
  text-base
`;

const PAQualitificationsSubSection = ({
  paQualifications,
  dispatchDocumentsSettings,
}) => {
  const [addMode, setAddMode] = useState(false);

  const movePaQualification = useCallback(
    (from, to) => {
      dispatchDocumentsSettings({
        type: "changePaQualificationsPosition",
        from,
        to,
      });
    },
    [dispatchDocumentsSettings]
  );

  const togglePaQualificationsEditing = useCallback(
    (index) => {
      dispatchDocumentsSettings({
        type: "changePaQualificationsEditing",
        index,
      });
    },
    [dispatchDocumentsSettings]
  );

  const onRemovePaQualificationsDocument = useCallback(
    (index) => {
      dispatchDocumentsSettings({
        type: "removePaQualifications",
        index,
      });
    },
    [dispatchDocumentsSettings]
  );

  const onPaQualificationsDocumentChange = useCallback(
    (newValue, index) => {
      dispatchDocumentsSettings({
        type: "changePaQualifications",
        index,
        value: newValue,
      });
    },
    [dispatchDocumentsSettings]
  );

  const onAddQualification = useCallback(() => {
    dispatchDocumentsSettings({
      type: "addPaQualifications",
    });
  }, [dispatchDocumentsSettings]);

  const handleEnter = useCallback(
    (event, value, idx) => {
      // checks if the Enter key was pressed
      if (event.keyCode === 13) {
        if (value === "") {
          onRemovePaQualificationsDocument(idx);
          return;
        }
        if (addMode) onAddQualification();
        togglePaQualificationsEditing(idx);
      }
    },
    [
      addMode,
      onAddQualification,
      onRemovePaQualificationsDocument,
      togglePaQualificationsEditing,
    ]
  );

  const handleBlur = (value, idx) => {
    if (window.event.keyCode === 13) return;
    togglePaQualificationsEditing(idx);
    if (value === "") onRemovePaQualificationsDocument(idx);
    // prevents new empty input field from being added inside handleEnter
    setAddMode(false);
  };

  return (
    <div className="py-4 border-b border-gray-150 pr-6">
      <h2 className="py-2 font-semibold">Purchase Authorization</h2>
      <div>
        {paQualifications.map((doc, index) => (
          <DocumentTile
            key={doc?.value}
            doc={doc}
            index={index}
            moveItem={movePaQualification}
            toggleEditing={togglePaQualificationsEditing}
            onRemoveDocument={onRemovePaQualificationsDocument}
            onDocumentChange={onPaQualificationsDocumentChange}
            handleBlur={handleBlur}
            handleEnter={handleEnter}
            setAddMode={setAddMode}
            className="py-2"
            inputClassName="w-1/2"
          />
        ))}
      </div>
      <BaseButton
        title="+ Add Qualification & Exclusion"
        className={tertiaryButtonCN}
        onClick={() => {
          setAddMode(true);
          onAddQualification();
        }}
        innerClassName="mx-0"
      />
    </div>
  );
};

PAQualitificationsSubSection.propTypes = {
  paQualifications: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      isEditing: PropTypes.bool,
    })
  ),
  dispatchDocumentsSettings: PropTypes.func,
};

PAQualitificationsSubSection.defaultProps = {
  paQualifications: [],
  dispatchDocumentsSettings: undefined,
};

export default PAQualitificationsSubSection;

import { SET_ASSETS } from "../constants";
// eslint-disable-next-line import/prefer-default-export
export const removeAsset = (dispatch, assets, deletedAssetId) => {
  const assetsDD = [];
  const newAssets = assets
    ?.filter(({ id }) => deletedAssetId !== id)
    .map((e) => {
      assetsDD.push({
        label: e?.name,
        value: e?.reference,
        id: e?.id,
      });
      return e;
    });

  dispatch({
    type: SET_ASSETS,
    assets: newAssets,
    assetsDD,
    assetsDict: newAssets.reduce(
      (acc, v) => ({ ...acc, [v.reference]: v }),
      {}
    ),
  });
};

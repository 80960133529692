import { useMutation } from "react-query";
import { ReportAPI } from "@griffingroupglobal/eslib-api";
import { toastError, toastMessage } from "../helpers/Toast";

/**
 * Mutation hook to run reports by given `reportId`
 * @param mutationKey (?) optional key to track this mutation
 */
const useReportRun = (mutationKey) => {
  const successMessage =
    "been scheduled to run during the next Scheduler cycle. You will receive a Notification once it's complete and available.";

  return useMutation({
    mutationKey,
    mutationFn: (reportId) => ReportAPI.getWOP(`${reportId}/$runreport`),
    onSuccess: () => {
      toastMessage(`Report has ${successMessage}`);
    },
    onError: (error) => {
      const errorMessage = error?.message ?? "";

      if (errorMessage.includes("code 412")) {
        toastMessage(`Report already has ${successMessage}`);
        return;
      }

      toastError("There was an error running this report. Please try again");
    },
  });
};

export default useReportRun;

import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

const containerCN = (className) => cntl`
  cursor-pointer
  select-none
  flex
  items-center
  leading-5
  pl-5
  py-2
  w-full
  hover:bg-brandGreenLight
  ${className}
`;

// Showing green
const activeCN = (active) => cntl`
  absolute
  h-7
  -left-0
  ml-2
  bg-brandGreen
  ${active ? "w-1" : "w-0"}
`;

const routeTextCN = () => cntl`
  text-gray-900
  font-medium
  tracking-wider
  text-sm
`;

const SidebarItem = ({
  active,
  onClick,
  label,
  customComponent,
  className,
}) => {
  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      onClick();
    }
  };

  return (
    <div
      className={containerCN(className)}
      onClick={onClick}
      onKeyDown={onKeyDown}
      role="button"
      tabIndex={0}
    >
      <div className={activeCN(active)} />
      {customComponent || <p className={routeTextCN()}>{label}</p>}
    </div>
  );
};

SidebarItem.propTypes = {
  /**
   * The text displayed in the button
   */
  label: PropTypes.string,
  /**
   * The function called onClick
   */
  onClick: PropTypes.func,
  /**
   * Boolean for toggling active UI
   */
  active: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  customComponent: PropTypes.object,
  className: PropTypes.string,
};

SidebarItem.defaultProps = {
  label: "Test",
  onClick: undefined,
  active: false,
  customComponent: undefined,
  className: undefined,
};

export default SidebarItem;

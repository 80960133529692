const getAssetForSpaceLookupTable = (assetsDict, spaceId) => {
  if (!assetsDict) return {};
  // Extract assets from the assets dictionary
  const assets = Object.values(assetsDict);
  // Filter assets to include only those associated with the specified space
  const assetsForSpace = assets.filter((asset) => {
    return asset?.spaces?.includes(spaceId);
  });
  // Create a lookup table for assets in the specified space
  const assetsLookUpTable = {};
  // Populate the lookup table with assets, using their IDs as keys
  assetsForSpace.forEach((asset) => {
    assetsLookUpTable[`Asset/${asset.id}`] = true;
  });

  return assetsLookUpTable;
};

export default getAssetForSpaceLookupTable;

import React, { Fragment, useCallback, useMemo } from "react";
import cntl from "cntl";
import TaskCard from "./TaskCard";

const badge = (hasAtleastOneSubRow, className) => cntl`
  rounded-full
  flex
  ${hasAtleastOneSubRow ? cntl`bg-brandGreen` : cntl`bg-gray-100`}
  mr-2
  ${className}
`;

const TaskCardGrid = ({ rows, onTaskClick, handleOpenComments }) => {
  const isGrouped = useMemo(() => {
    return rows?.[0]?.isGrouped;
  }, [rows]);

  const groupedHeader = useCallback((row, step) => {
    const defaultSize = 18;
    const maxStepCount = 4;
    const currentSize = defaultSize - 2 * step;
    const minSize = defaultSize - 2 * maxStepCount;
    const hasAtleastOneSubRow =
      row.subRows?.length && !row.subRows[0].isManualGrouped;
    const headerColor =
      row.isManualGrouped ||
      (row.subRows?.length && !row.subRows[0].isManualGrouped)
        ? "text-black"
        : "text-gray-200";

    return (
      <div className="flex sticky mb-2 left-0" id={row.id}>
        <div className="w-10 flex justify-center items-center">
          <span
            className={badge(hasAtleastOneSubRow)}
            style={{
              width: currentSize,
              height: currentSize,
              minWidth: minSize,
              minHeight: minSize,
            }}
          />
        </div>
        <div className={`font-semibold uppercase ${headerColor} text-3xl`}>
          {row.groupByVal === "undefined" || !row.groupByVal
            ? "Not Specified"
            : row.groupByVal}
        </div>
      </div>
    );
  }, []);
  const filteredRows = useCallback((rs) => {
    return rs;
  }, []);

  const card = useCallback(
    (row) => {
      return (
        <TaskCard
          key={row.id}
          onTaskClick={() => {
            onTaskClick(row);
          }}
          handleOpenComments={() =>
            handleOpenComments({
              reference: row?.original?.reference,
              association: row?.original?.association,
            })
          }
          {...row?.original}
        />
      );
    },
    [handleOpenComments, onTaskClick]
  );

  const getTableRow = useCallback(
    (row) => {
      return card(row);
    },
    [card]
  );
  const getTableGroups = useCallback(
    (tableRows, step = 0) => {
      return tableRows.map((row, index) => {
        if (row.isGrouped) {
          return (
            <Fragment key={row.id}>
              <div className="w-full">{groupedHeader(row, step, index)}</div>
              <div
                className={`flex flex-wrap gap-x-6 gap-y-9 ${
                  row.subRows?.[0]?.subRows.length ? "" : "my-4"
                }`}
              >
                {getTableGroups(filteredRows(row.subRows), step + 1)}
              </div>
            </Fragment>
          );
        }
        return getTableRow(row);
      });
    },
    [getTableRow, groupedHeader, filteredRows]
  );

  return (
    <>
      {!isGrouped && (
        <div className="flex w-full gap-x-6 gap-y-10 flex-wrap">
          {rows?.map((row) => (
            <TaskCard
              key={row.id}
              onTaskClick={() => {
                onTaskClick(row);
              }}
              handleOpenComments={() =>
                handleOpenComments({
                  reference: row?.original?.reference,
                  association: row?.original?.association,
                })
              }
              {...row?.original}
            />
          ))}
        </div>
      )}
      {isGrouped && getTableGroups(rows)}
    </>
  );
};

export default TaskCardGrid;

import React from "react";
import PrimaryHeaderItems from "../../../stories/Components/Primary/PrimaryHeaderItems";
import SpacesTable from "../SpacesTable";

const SpacesMainList = () => {
  return (
    <>
      <PrimaryHeaderItems headerTitle="Spaces" />
      <SpacesTable />
    </>
  );
};

SpacesMainList.defaultProps = {};

SpacesMainList.propTypes = {};

export default SpacesMainList;

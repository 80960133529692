import React from "react";
import PropTypes from "prop-types";
import SearchLogo from "../../assets/images/magnify_glass_icon.svg";
import Dropdown from "../Dropdown/Dropdown";

/**
 * Component that renders a dropdown with tags options. Also allows to create new tags
 */
const TagsDropdown = ({ options, canCreate, hidden, className, onChange }) => {
  return (
    <div className={hidden ? "hidden" : `flex ${className ?? ""}`}>
      <Dropdown
        options={options}
        onChange={onChange}
        className="w-56 pr-4 h-8"
        placeholder={
          <div className="flex flex-row">
            <img src={SearchLogo} alt="dd" />
            <p className="pl-4">Add Tags</p>
          </div>
        }
        isCreatable={canCreate}
        hideDropdownIndicator
        hideIndicator
        menuPlacement="top"
      />
    </div>
  );
};

TagsDropdown.defaultProps = {
  options: [],
  canCreate: false,
  hidden: false,
  className: undefined,
  onChange: undefined,
};

TagsDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, label: PropTypes.string })
  ),
  canCreate: PropTypes.bool,
  hidden: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default TagsDropdown;

import { useEffect } from "react";
import validatePassword from "../helpers/Password/validatePassword";

const usePasswordValidator = (
  password,
  confirmPassword,
  setPasswordValidation,
  setIsValidPassword
) => {
  useEffect(() => {
    const validationResult = validatePassword(password);

    setPasswordValidation(validationResult);
    setIsValidPassword(
      validationResult.isValid && password === confirmPassword
    );
  }, [password, confirmPassword, setIsValidPassword, setPasswordValidation]);
};

export default usePasswordValidator;

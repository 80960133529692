import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { CommentAPI } from "@griffingroupglobal/eslib-api";
import { capitalize } from "lodash";
import IconButton from "../../stories/Components/Buttons/IconButton";
import moreInformationIcon from "../../stories/assets/images/moreInformation.svg";
import chevronRightIcon from "../../stories/assets/images/chevronRight.svg";
import CommentSection from "../../stories/Components/Comments/CommentSection";
import { toastError } from "../../helpers/Toast";
import whiteCrossIcon from "../../stories/assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../stories/assets/images/whiteExclamationIcon.svg";

const ProjectHistorySection = ({ historyData, currentUser, users }) => {
  const [updatedData, setUpdatedData] = useState(historyData);
  const [moreOptions, setMoreOptions] = useState([]);
  const [historyComments, setHistoryComments] = useState([]);
  const [historyUser, setHistoryUser] = useState("");
  const [showComments, setShowComments] = useState(false);
  const [addComment, setAddComment] = useState(false);
  const [canAddComment, setCanAddComment] = useState(false);
  const toastCloseIcon = (
    <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
  );

  const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;
  const getUserData = (allUsers, reference) => {
    if (!reference) {
      return "";
    }
    const found = allUsers?.find((user) => user.reference === reference);
    return found;
  };
  useEffect(() => {
    setUpdatedData(historyData);
  }, [historyData]);
  useEffect(() => {
    const fetchUser = () => {
      if (updatedData) {
        if (updatedData.user) {
          const found = getUserData(users, updatedData?.user);
          setHistoryUser(
            `${capitalize(found?.name?.lastName)}, ${
              capitalize(found?.name?.firstName)[0]
            }.`
          );
        }
      }
    };
    fetchUser();
  }, [historyData?.user, updatedData, users]);

  useEffect(() => {
    setCanAddComment(
      currentUser?.isAdmin ||
        currentUser?.hasPermission?.("project", "can_create") ||
        currentUser?.hasPermission?.("property", "can_create")
    );
  }, [currentUser]);

  useEffect(() => {
    const showHide = showComments ? "Hide Comments" : "Show Comments";
    if (updatedData && canAddComment) {
      setMoreOptions([
        {
          title: `${historyComments?.length > 0 ? showHide : "Add Comments"}`,
          onClick: () => {
            if (historyComments?.length === 0) {
              setAddComment((prev) => !prev);
            } else if (showHide !== "Add Comments") {
              setShowComments(showHide !== "Hide Comments");
            }
          },
        },
      ]);
    }
  }, [
    updatedData,
    currentUser.isAdmin,
    historyComments?.length,
    showComments,
    canAddComment,
  ]);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const fetchComments = async () => {
      try {
        const { data: commentsData } = await CommentAPI.get({
          params: {
            association: updatedData?.reference,
          },
        });

        const formatedComments =
          commentsData?.map(async (info) => {
            const user = getUserData(users, info?.author);
            const formatedReplies =
              info?.replies?.map((reply) => ({
                ...reply,
                userData: getUserData(users, reply?.author),
              })) || [];
            const replies = await Promise.all(formatedReplies);
            return {
              ...info,
              userData: user,
              replies,
            };
          }) || [];
        const allComments = await Promise.all(formatedComments);
        setHistoryComments(allComments);
      } catch (error) {
        return null;
      }
    };

    fetchComments();
  }, [updatedData?.reference, users]);

  const handlePostComment = async (value) => {
    try {
      if (value.trim().length > 0) {
        const commentObj = {
          association: updatedData?.reference,
          content: value,
          author: currentUser?.reference,
        };
        const { data: newComment } = await CommentAPI.post(commentObj);
        newComment.userData = currentUser;
        newComment.notifyUsers = [currentUser.reference];
        setHistoryComments((prev) => [...prev, newComment]);
      }
    } catch (error) {
      toastError(
        `Error posting Comment: ${JSON.stringify(error)}`,
        toastErrorIcon,
        toastCloseIcon
      );
    }
  };
  const handlePostReply = async (value, commentId) => {
    if (value.trim().length > 0) {
      const tempObj = {
        content: value,
        author: currentUser.reference,
      };
      const { data: reply } = await CommentAPI.postWOP(
        `${commentId}/$addreply`,
        {
          ...tempObj,
        }
      );

      const replyComment = {
        ...reply,
        userData: currentUser,
        replies: [],
      };

      const updatedComments = historyComments?.map((comment) => {
        if (comment.id === commentId) {
          return { ...comment, replies: [...comment.replies, replyComment] };
        }
        return comment;
      });

      setUpdatedData({ ...updatedData, comments: updatedComments });
      setHistoryComments((prev) => updatedComments ?? prev);
    }
  };
  return (
    <div className="flex flex-col pl-5">
      <div className="flex flex-col justify-between">
        <div className="grid grid-cols-3 w-full pr-8 pl-1 pb-1">
          <div className="flex items-center font-bold text-gray-500">
            {historyComments?.length > 0 && (
              <div className="pt-1 pr-3">
                <IconButton
                  icon={chevronRightIcon}
                  className={`${
                    showComments
                      ? "transform origin-center rotate-90 nl-0 w-10"
                      : "nl-0 pb-1.5 w-10"
                  }`}
                  imgClassName="w-4 h-4"
                  onClick={() => setShowComments((prev) => !prev)}
                />
              </div>
            )}
            <div className={`${historyComments?.length === 0 ? "ml-7" : ""}`}>
              {" "}
              {updatedData?.message}
            </div>
          </div>
          <div className="text-gray-500 justify-self-end"> {historyUser} </div>
          <div className="flex text-gray-500 justify-self-end">
            <p>{moment(updatedData?.when).format("lll")}</p>
            <div className="w-20 h-7 flex justify-end pr-1 items-center">
              <IconButton
                icon={moreInformationIcon}
                dropdownItems={moreOptions}
                imgClassName="w-5 h-5"
                hideDropdownChevron
              />
            </div>
          </div>
        </div>
        <div className="object-bottom mx-8 border-b">
          <div className="overflow-auto">
            <div className="grid grid-cols-2">
              {(showComments || addComment) && (
                <CommentSection
                  data={historyComments || []}
                  handlePostComment={handlePostComment}
                  handlePostReply={handlePostReply}
                  allowNewComment
                  hideCommentTag
                  showNewCommentSection={addComment}
                  setShowNewCommentSection={setAddComment}
                  canAddComment
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectHistorySection.propTypes = {
  /**
   * project history data
   */
  historyData: PropTypes.shape({
    id: PropTypes.string,
    reference: PropTypes.string,
    when: PropTypes.string,
    user: PropTypes.string,
    association: PropTypes.string,
    message: PropTypes.string,
    comments: PropTypes.arrayOf("object"),
  }),
  // eslint-disable-next-line react/forbid-prop-types

  currentUser: PropTypes.shape({
    isAdmin: PropTypes.bool,
    reference: PropTypes.string,
    hasPermission: PropTypes.bool,
  }),
  users: PropTypes.shape({}),
};

ProjectHistorySection.defaultProps = {
  historyData: undefined,
  currentUser: undefined,
  users: undefined,
};
export default ProjectHistorySection;

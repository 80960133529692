import React from "react";
import PropTypes from "prop-types";
import BaseButton from "../Buttons/BaseButton";

const VisibilityButton = ({ isVisible, className, onClick }) => (
  <BaseButton
    className={className}
    onClick={onClick}
    iconRight={
      isVisible ? (
        <p className="text-xs font-semibold text-brandGreen">Hide</p>
      ) : (
        <p className="text-xs font-semibold text-brandGreen">Show</p>
      )
    }
  />
);

VisibilityButton.propTypes = {
  /**
   * classes to apply to the button
   */
  className: PropTypes.string,
  /**
   * function called when the button is clicked
   */
  onClick: PropTypes.func,
  isVisible: PropTypes.bool,
};

VisibilityButton.defaultProps = {
  className: undefined,
  onClick: undefined,
  isVisible: false,
};

export default VisibilityButton;

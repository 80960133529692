import { useCallback, useState } from "react";
import moment from "moment";
import useTodos from "../../../hooks/useTodos";
import useTodosPatch from "../../../hooks/useTodosPatch";
import useTodosMarkComplete from "../../../hooks/useTodosMarkComplete";
import useTodoCreate from "../../../hooks/useTodoCreate";
import useCurrentUser from "../../../hooks/useCurrentUser";

export default () => {
  const { data } = useTodos();
  const { mutate: patchTodo } = useTodosPatch();
  const { mutate: completeTodo } = useTodosMarkComplete();
  const { mutate: postTodo } = useTodoCreate();
  const { data: currentUser } = useCurrentUser();

  const [editing, setEditing] = useState();

  const handlePatch = useCallback(
    (todo, newTodo) => {
      const lastUpdated = moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ");

      patchTodo([
        {
          ...newTodo,
          metadata: {
            ...newTodo?.metadata,
            lastUpdated,
          },
        },
        {
          ...todo,
          metadata: {
            ...todo?.metadata,
            lastUpdated,
          },
        },
      ]);
    },
    [patchTodo]
  );

  const create = useCallback(
    (todo) => {
      postTodo(todo);
    },
    [postTodo]
  );

  const handleEditing = (idx) => {
    setEditing((prev) => (prev === idx ? undefined : idx));
  };

  const groupTodoElements = data?.filter((todo) => {
    const todoMembers = todo?.members?.filter(
      (member) => member?.user !== currentUser?.reference
    );
    return todoMembers?.length > 0;
  });

  return {
    todos: data,
    create,
    completeTodo,
    handlePatch,
    editing,
    handleEditing,
    groupTodoElements,
  };
};

import { useReducer } from "react";

const defaultState = [];

const reducer = (group, action) => {
  switch (action.type) {
    case "update":
      return group.map((g, index) => {
        if (index === action.index) {
          return action.group;
        }
        return g;
      });
    case "add": {
      return [...group, action.group];
    }
    case "remove": {
      return group?.filter((_, index) => index !== action.index);
    }
    case "updateAll": {
      return action.groups;
    }
    default:
      return group;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};

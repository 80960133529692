const groupDateTotals = (entryData) => {
  const entryArr = Object.values(entryData);
  const flattendEntries = entryArr.flat();
  const entryDataObj = {};

  flattendEntries.forEach((entry) => {
    const day = entry.date;
    if (!entryDataObj[day]) {
      entryDataObj[day] = entry.value;
    } else {
      entryDataObj[day] += entry.value;
    }
  });

  return entryDataObj;
};

export default groupDateTotals;

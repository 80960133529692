import { UserAPI } from "@griffingroupglobal/eslib-api";
import { useQueryClient } from "react-query";
import { userKeys } from "../config/reactQuery/queryKeyFactory";
import useAuthenticatedQuery from "./useAuthenticatedQuery";

/**
 * Fetch User by id
 * @param {string} id User id: 1111-2222-3333-4444
 */
const getUserById = async (userId) => {
  const { data } = await UserAPI.getWOP(`?id=${userId}`);
  return data?.entries[0]?.resource ?? {};
};

/**
 * Query hook to fetch single user by id
 * @param {string} UserId user id
 * @param {Function} select function to select or transform data
 */
export const useUserById = (userId, select) => {
  const queryClient = useQueryClient();

  return useAuthenticatedQuery({
    queryKey: userKeys.byId(userId),
    enabled: !!userId,
    queryFn: () => getUserById(userId),
    select,
    // Set initial data from cache if exists
    initialData: () =>
      queryClient.getQueryData(userKeys.users)?.userDict[`User/${userId}`],

    // This will refetch in the background if data is older than the provided staleTime
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(userKeys.users)?.dataUpdatedAt,
    onError: (error) => {
      console.error("useUserById", error);
    },
  });
};

export default useUserById;

/* eslint-disable prefer-destructuring */
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { truncate } from "lodash";
import resourceDefault from "../../assets/images/diamond.svg";
import projectDefault from "../../assets/images/projectIcon.svg";
import userDefault from "../../assets/images/userIcon.svg";
import propertyDefault from "../../assets/images/propertyIcon.svg";
import companyDefault from "../../assets/images/companyWhiteIcon.svg";
import "./ResourceCard.css";
import chevron from "../../assets/images/chevronRightDarkGreen.svg";
import { useGetFile } from "../../../hooks/useGetFile";

/**
 * Component to render a circular image
 * @param {string | undefined} imageRef Deprecated: S3 url
 * @param {string | undefined} imageReference File reference for the image to render.
 *         This prop allow to display primary image using react-query.
 */
const ResourceImage = ({ imageRef, imageReference, title, resourceName }) => {
  const { data } = useGetFile(imageReference);

  const placeholder = () => {
    switch (resourceName) {
      case "Asset":
        return resourceDefault;
      case "Contact":
        return userDefault;
      case "Property":
        return propertyDefault;
      case "Space":
        return propertyDefault;
      case "Company":
        return companyDefault;
      case "Project":
        return projectDefault;
      default:
        return resourceDefault;
    }
  };

  const [image, setImage] = useState(null);

  // TODO (Josymar) This is temporal, until all resources are migrated to imageReference
  useEffect(() => {
    if (imageReference && data?.contentsUrl) {
      setImage(data?.contentsUrl);
    } else if (imageRef) {
      setImage(imageRef);
    } else setImage(null);
  }, [imageRef, imageReference, data]);

  return (
    <svg
      viewBox="0 0 10 10"
      width="130px"
      preserveAspectRatio="xMidYMid meet"
      aria-label="avatar-image"
      className="rounded-full self-center"
      style={{ minWidth: "130px" }}
      role="presentation"
    >
      <clipPath id={`clipCircle-${title}`}>
        <circle r="5" cx="5" cy="5" />
      </clipPath>

      {/* Green border circle */}
      <circle
        r="4.5"
        cx="5"
        cy="5"
        fill="none"
        stroke="#1CC39D"
        strokeWidth="0.5"
      />

      <circle r="4.5" cx="5" cy="5" fill="#585858" strokeWidth="1" />
      {/* Avatar Image */}

      <image
        href={image || placeholder()}
        alt="avatar-image"
        x="0"
        y="0"
        height="10"
        width="10"
        clipPath={`url(#clipCircle-${title})`}
        preserveAspectRatio="xMaxYMax slice"
      />
      {/* Avatar Image */}
    </svg>
  );
};

const resourceCardHeight = { height: 235 };

function ResourceCard({
  id,
  title,
  detail = { label: "[Detail Label]", value: "[Detail Value]" },
  resourceName,
  image,
  imageReference,
  onClick,
  disabled,
  className,
  hideButton,
  CustomComponent,
  isAssetSpace,
  labelPlaceholder,
  valuePlaceholder,
}) {
  const handleClick = useCallback(() => {
    if (!disabled) onClick();
  }, [disabled, onClick]);

  const isContactsList =
    resourceName.includes("Contact") || resourceName.includes("Company");

  const cardContainerSize = isContactsList
    ? { height: 284, width: 380 }
    : { height: 240, width: 380 };

  return (
    <div
      id={id}
      style={cardContainerSize}
      className={`border-gray-200 border rounded-lg cursor-pointer ${
        isContactsList ? "" : "resourceCN"
      } ${className}`}
    >
      <div
        className="flex flex-col py-7 px-7"
        style={resourceCardHeight}
        onClick={handleClick}
        role="presentation"
      >
        {/* Title */}
        {
          <p
            className={`font-semibold pb-4 pl-1 text-2xl break-words truncate ${
              !isAssetSpace && "capitalize"
            }`}
          >
            {title ?? valuePlaceholder}
          </p>
        }

        {/* Resource Image Row */}
        <div className="flex">
          {/* Resource Image */}
          <div className="mt-2">
            <ResourceImage
              imageRef={image}
              imageReference={imageReference}
              title={title}
              resourceName={resourceName}
            />
          </div>
          {/* Resource Info Col */}
          <div className="flex flex-col w-full pl-5 justify-center">
            <div className="font-bold text-md text-gray-450 pr-4">
              {truncate(detail?.label ?? labelPlaceholder, { length: 15 })}
              <div className="block text-md w-full font-normal text-gray-300 mt-1 pb-3 border-b border-gray-450">
                {detail?.value &&
                  truncate(detail?.value ?? valuePlaceholder, { length: 15 })}
              </div>
            </div>

            {!hideButton && (
              <button
                type="button"
                style={{ color: !disabled ? "#027D61" : "#A6A6A6" }}
                onClick={handleClick}
                className="flex flex-row mt-6"
              >
                <p className="text-md tracking-wider font-semibold">
                  View {resourceName}
                </p>
                <img
                  src={chevron}
                  alt="arrow"
                  className="flex self-center pl-2"
                />
              </button>
            )}
          </div>
        </div>
      </div>
      {CustomComponent ? CustomComponent() : null}
    </div>
  );
}

ResourceCard.propTypes = {
  /**
   * ID Of Resource
   */
  id: PropTypes.string,
  /**
   * Title Of Resource
   */
  title: PropTypes.string,
  /**
   * currentUser
   */
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    kind: PropTypes.string,
  }),
  /**
   * Sub title of resource
   * ie. { label: Type, Value: Pool House}
   */
  detail: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  /**
   * Contact Resource
   * ie. { name: {firstName: "", lastName: ""},
   */
  contact: PropTypes.shape({
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    id: PropTypes.string,
    kind: PropTypes.string,
    contactPoint: PropTypes.shape([]),
  }),
  /**
   * Company Resource
   * ie. { name: {firstName: "", lastName: ""},
   */
  company: PropTypes.shape({
    id: PropTypes.string,
    company: PropTypes.shape({
      value: PropTypes.string,
      valueType: PropTypes.string,
    }),
    reference: PropTypes.string,
  }),
  /**
   * Name Of Resource
   */
  resourceName: PropTypes.string,
  /**
   * Resource Image
   */
  image: PropTypes.string,
  imageReference: PropTypes.string,
  onClick: PropTypes.func,
  /**
   * Disabled button (configured for onAddClick only)
   */
  disabled: PropTypes.bool,
  className: PropTypes.string,
  hideButton: PropTypes.bool,
  CustomComponent: PropTypes.func,
  isAssetSpace: PropTypes.bool,
  labelPlaceholder: PropTypes.string,
  valuePlaceholder: PropTypes.string,
};

ResourceCard.defaultProps = {
  id: "",
  title: "",
  detail: { label: "[Detail Label]", value: "[Detail Value]" },
  contact: undefined,
  company: undefined,
  resourceName: "[Resource]",
  image: undefined,
  imageReference: undefined,
  onClick: () => {},
  disabled: false,
  className: "",
  hideButton: false,
  isAssetSpace: false,
  currentUser: {},
  CustomComponent: () => {},
  labelPlaceholder: "Category",
  valuePlaceholder: "Not specified",
};

export default ResourceCard;

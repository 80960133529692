import React from "react";
import Widget from "../../Widget/Widget";
import addIcon from "../../../assets/images/plusCircleIconDarkGreen.svg";
import PreferenceContainer from "../../ContactForm/PreferenceContainer";
import usePreferencesLibrarySection from "./usePreferencesLibrarySection";

const PreferencesLibrarySection = ({
  dispatchSystemSettings,
  systemSettings,
}) => {
  const {
    preferences,
    containerTitles,
    handleAddPreference,
    handleRemovePreference,
    handleAddContainer,
    handleRemoveContainer,
  } = usePreferencesLibrarySection({ systemSettings, dispatchSystemSettings });

  const addCategoryButton = () => (
    <button
      className="flex flex-row items-center gap-2"
      type="button"
      onClick={() => handleAddContainer()}
    >
      <p className="text-es-normal text-es-green font-es-semibold">
        Add Category
      </p>
      <img src={addIcon} alt="add preference" />
    </button>
  );

  return (
    <div className="flex flex-col">
      <Widget
        title=""
        draggable={false}
        childClassName="flex flex-col gap-6"
        className="mt-6"
      >
        <div className="flex flex-row flex-wrap gap-6">
          {containerTitles?.map((title) => (
            <PreferenceContainer
              key={title}
              title={title}
              preferences={preferences}
              isEditing
              handleRemoveContainer={handleRemoveContainer}
              handleRemovePreference={handleRemovePreference}
              handleAddPreference={handleAddPreference}
              isAdminPage
            />
          ))}
        </div>
        <div className="flex pb-2">{addCategoryButton()}</div>
      </Widget>
    </div>
  );
};

export default PreferencesLibrarySection;

// Framework Tools
import React from "react";
import PropTypes from "prop-types";

// Components
import TertiaryHeader from "../../TextHeaders/TertiaryHeader";
import { getInitials } from "../../../../helpers/User";
import Avatar from "../../Message/Avatar";
import { getFullName } from "../../../../helpers/Formatters";

export default function RequestGridViewBallInCourtCell({ ballInCourtMember }) {
  return (
    <div className="w-full h-ful flex flex-col justify-start flex-wrap px-2">
      <TertiaryHeader className="text-sm text-left">
        Ball In Court
      </TertiaryHeader>
      <div className="flex items-center mt-2">
        <Avatar
          className="text-xxs"
          name={getInitials(ballInCourtMember?.name)}
          isOnline={ballInCourtMember?.isOnline}
          id={ballInCourtMember?.id}
          avatar={ballInCourtMember?.avatar}
        />
        <p className="text-xs font-semibold">
          {getFullName(ballInCourtMember?.name)} (
          {ballInCourtMember?.companyName})
        </p>
      </div>
    </div>
  );
}

// prop types
RequestGridViewBallInCourtCell.propTypes = {
  ballInCourtMember: PropTypes.shape({
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    isOnline: PropTypes.bool,
    id: PropTypes.string,
    avatar: PropTypes.string,
    companyName: PropTypes.string,
  }),
};

// default props
RequestGridViewBallInCourtCell.defaultProps = {
  ballInCourtMember: undefined,
};

import React from "react";
import PropTypes from "prop-types";
import { formatWithCommasWithoutDecimal } from "../../../helpers/Formatters";

const SymbolPrefix = ({ symbol, value, budget }) => {
  const getValue = () => {
    try {
      const tmpValue = Number(value.replaceAll(",", ""));
      const formattedValue = formatWithCommasWithoutDecimal(Math.abs(tmpValue));

      if (budget) {
        return tmpValue < 0 ? `( ${formattedValue} )` : formattedValue;
      }
    } catch (err) {
      console.error(err);
    }

    return value;
  };
  return (
    <p>
      <span className="text-gray-200 mr-2">{symbol}</span>
      {getValue()}
    </p>
  );
};

SymbolPrefix.propTypes = {
  symbol: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  budget: PropTypes.bool,
};

SymbolPrefix.defaultProps = {
  value: undefined,
  budget: false,
};

export default SymbolPrefix;

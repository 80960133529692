/* Framework & Tools */
import moment from "moment";
import {
  SUBMITTAL_COMPLETED_OPEN,
  SUBMITTAL_COMPLETED_RESOLVED,
  SUBMITTAL_DRAFT,
} from "../constants";
import { getFullName } from "./Formatters";

/**
 * SubmittalData is a helper object to format data from a Submittal payload returned by the API into a more organized
 * format for display.
 */

/**
 * Create a new {@link SubmittalData} from a Submittal payload. The Submittal payload may come from useSubmittals.
 */
const formatPayloadForTable = (
  info,
  projectDict = {},
  submittalTypesDict = {},
  userDict = {}
) => {
  const association = info?.association;

  // eslint-disable-next-line consistent-return
  const completed = () => {
    if (info?.status === SUBMITTAL_COMPLETED_RESOLVED) return "Resolved";
    if (info?.status === SUBMITTAL_COMPLETED_OPEN) return "Open";
  };

  return {
    resolved: info.status === SUBMITTAL_COMPLETED_RESOLVED,
    original: info.original,
    status: info.status,
    isFlagged: info.isFlagged,
    comment: info.reference,
    name: info.name,
    type: submittalTypesDict ? submittalTypesDict[info.type]?.name : "",
    description: info.description,
    initiatedBy: getFullName(userDict[info.initiated?.user]?.name),
    initiationDate: info.initiated
      ? moment(info.initiated?.date)?.format("MMM D, YYYY")
      : "",
    startDate: moment(info.startDate?.projected)?.format("MMM D, YYYY"),
    ballInCourt: info.ballInCourt
      ?.map(({ user }) => userDict[user])
      ?.filter((user) => user),
    stepDueDate: moment(info.ballInCourt?.due)?.format("MMM D, YYYY"),
    isPrivate: info.private,
    vendor: info.vendor && info.vendor[0],
    duration: info.duration?.numDays,
    targetCompletionDate: moment(info.completion?.target).format("MMM D, YYYY"),
    projectedCompletionDate: moment(info.completion?.projected)?.format(
      "MMM D, YYYY"
    ),
    overUnder: "Y/N",
    schedule: info.schedule?.description,
    budgetRef: info.budget?.reference,
    blocked: "Y/N",
    associations: info.associatedArtifacts && info.associatedArtifacts[0],
    costImpact: info.impacts?.budget?.value,
    scheduleImpact: info.impacts?.schedule?.status,
    distList: info.distribution && info.distribution[0],
    associatedWorkflows: info.associatedWorkflows,
    hasAssociatedWorkflows: info.associatedWorkflows?.length > 0 ? "Y" : "N",
    endDate: moment(info.duration?.end).format("MMM D, YYYY"),
    warning: info.warning,
    workflowId: info.id,
    associationResource: association,
    project: projectDict?.[association]?.name,
    containerView: info.containerView,
    currentStep: info.currentStep,
    dateCreated: info.dateCreated,
    daysOverdue: info.daysOverdue,
    members: info.members,
    id: info.id,
    files: info.files,
    metadata: info.metadata,
    steps: info.requestWorkflow?.steps,
    completed: completed(),
    canInitiate:
      info?.status === SUBMITTAL_DRAFT &&
      info?.requestWorkflow?.steps?.length !== 0,
    isDraft: info.isDraft,
    versions: info.versions,
    history: info.history,
    documentTypes: info.documentTypes,
  };
};

const handleGetAssocName = (info, projectDict) => {
  const project = info?.associationResource;
  return `Project - ${projectDict?.[project]?.name || "Not specified"}`;
};

const isSubmittalStartDateInPast = (submittal) => {
  // if start date is in the past
  return moment(submittal?.startDate?.actual).isBefore(moment(), "day");
};

export {
  handleGetAssocName,
  formatPayloadForTable,
  isSubmittalStartDateInPast,
};

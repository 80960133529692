import React from "react";
import AddNewButton from "../../Message/AddNewButton";
import useAddTodoButtonData from "./useAddTodoButtonData";
import { PRIMARY_DARK_GREEN } from "../../../../constants";

const AddTodoButton = ({ create }) => {
  const { openCreate, disableButton } = useAddTodoButtonData({
    create,
  });

  return (
    <div className="relative">
      <AddNewButton
        onClick={() => openCreate()}
        color={PRIMARY_DARK_GREEN}
        expanded
        disabled={disableButton}
      />
    </div>
  );
};

export default AddTodoButton;

import React, { useCallback } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import * as yup from "yup";
import Input from "../Input/Input";
import Dropdown from "../Dropdown/Dropdown";
import ResourceDropDown from "../WorkflowCreation/ResourceDropDown";
import Widget from "../Widget/Widget";
import FormAvatar from "../Avatar/FormAvatar";
import TagsContainer from "../Tags/TagsContainer";
import LinksView from "../Links/LinksView";
import AttributeModalWidget from "../../../Pages/Overviews/Asset/AttributeModalWidget";
import OwnedBy from "../OwnedBy";

const boldCN = (className) => cntl`
  font-bold 
  text-gray-450
  text-md
  self-center
  flex
  flex-row
  ${className}
`;

const AssetInfoForm = ({
  asset,
  dispatch,
  categoryOptionsList,
  subCatOptionsMap,
  associatedResource,
  setAssociatedResource,
  subAssociation,
  disableAssociation,
  handleKeyUp,
  inputRefs,
  isFormReset,
  setIsFormReset,
  assetModalStatus,
  spacesDD,
  newAttributes,
  setNewAttributes,
}) => {
  const categoryOptions = React.useMemo(
    () =>
      subCatOptionsMap
        ? Object.entries(subCatOptionsMap).map(([key, val]) => {
            return {
              label: key,
              options: val.map((item) => {
                return { ...item, category: key };
              }),
            };
          })
        : [],
    [subCatOptionsMap]
  );

  const onNameChange = (name) =>
    dispatch({
      type: "name",
      name,
    });

  const onOwnedByChange = (ownedBy) =>
    dispatch({
      type: "ownedBy",
      ownedBy,
    });

  const onBlur = () => {
    setIsFormReset(false);
  };

  const onChangeDescription = (description) =>
    dispatch({
      type: "description",
      description,
    });

  const handleChangeCategory = (val) => {
    dispatch({
      type: "category",
      category: categoryOptionsList.find((item) => item.label === val.category)
        ?.value,
    });
    dispatch({
      type: "subcategory",
      subcategory: val.value,
    });
  };

  const changeSpace = useCallback(
    ({ value }) => {
      dispatch({
        type: "editSpace",
        value,
      });
    },
    [dispatch]
  );

  const handlePrimaryImageChange = ({ reference, category }) => {
    dispatch({
      type: "creationImage",
      reference,
      category,
    });
  };

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="pb-6 flex flex-row align-center">
        <div className="pr-4">
          <FormAvatar
            isEditing
            editing
            image={asset?.primaryImage}
            onChange={handlePrimaryImageChange}
          />
        </div>
        <div className={boldCN()}>Add Image</div>
      </div>
      <Widget draggable={false} title="Asset Information" overflow>
        <div className="grid grid-cols-2 gap-8 pr-4">
          <div className="">
            <div className="flex flex-row py-4">
              <div className="w-2/5 self-center">
                <div className={boldCN()}>
                  Name
                  {asset.name ? (
                    ""
                  ) : (
                    <div className="text-brandDarkGreen">*</div>
                  )}
                </div>
              </div>
              <div className="w-3/5 self-center">
                <Input
                  onChange={onNameChange}
                  value={asset.name}
                  inputClassName="bg-backgroundGreen w-full ESInput"
                  validation={!isFormReset && yup.string().required()}
                  // eslint-disable-next-line no-return-assign, no-param-reassign
                  forwardedRef={(el) => (inputRefs.current.assetName = el)}
                  handleEnter={(event) => handleKeyUp(event)}
                  onBlur={onBlur}
                  name="assetName"
                  placeholder="Name"
                  showValidationErrorAtBottom
                />
              </div>
            </div>
            <div className="flex flex-row py-4">
              <div className="w-2/5 self-center">
                <h3 className={boldCN()}>
                  Association
                  {associatedResource ? (
                    ""
                  ) : (
                    <div className="text-brandDarkGreen">*</div>
                  )}
                </h3>
              </div>
              <div className="w-3/5 self-center">
                <ResourceDropDown
                  noLabel
                  disableErrorMessage
                  resource={associatedResource}
                  setResource={setAssociatedResource}
                  validation={yup.mixed().required()}
                  disableAssociation={
                    !assetModalStatus?.allowAssocSelect && disableAssociation
                  }
                  placeholder="Select"
                  // eslint-disable-next-line no-return-assign, no-param-reassign
                  forwardedRef={(el) =>
                    // eslint-disable-next-line no-param-reassign
                    (inputRefs.current.association = el)
                  }
                  onKeyUp={(event) => handleKeyUp(event)}
                  bottomMessage
                />
              </div>
            </div>
            <div className="flex flex-row pt-4">
              <div className="w-2/5 self-center">
                <h3 className={boldCN()}>Owned by</h3>
              </div>
              <div className="w-3/5">
                <OwnedBy
                  editing
                  ownedBy={asset.ownedBy}
                  onChange={onOwnedByChange}
                  customWidth="241px"
                />
              </div>
            </div>
          </div>
          <div className="">
            <div className="flex flex-row py-4">
              <div className="w-2/5 self-center">
                <div className={boldCN()}>
                  Category
                  {subCatOptionsMap[
                    categoryOptionsList?.find(
                      (opt) => opt.value === asset?.category
                    )?.label
                  ]?.find((item) => item.value === asset.subcategory) ? (
                    ""
                  ) : (
                    <div className="text-brandDarkGreen">*</div>
                  )}
                </div>
              </div>
              <div className="w-3/5 self-center">
                <Dropdown
                  key="Type"
                  placeholder="Select"
                  disableErrorMessage
                  options={categoryOptions}
                  value={subCatOptionsMap[
                    categoryOptionsList?.find(
                      (opt) => opt.value === asset?.category
                    )?.label
                  ]?.find((item) => item.value === asset.subcategory)}
                  onChange={(val) => handleChangeCategory(val)}
                  validation={yup.mixed().required()}
                  disableClear
                  // eslint-disable-next-line no-return-assign, no-param-reassign
                  forwardedRef={(el) => (inputRefs.current.category = el)}
                  onKeyUp={(event) => handleKeyUp(event)}
                />
              </div>
            </div>
            <div className="flex flex-row py-4">
              <div className="w-2/5 self-center">
                <h3 className={boldCN()}>Space</h3>
              </div>
              <div className="w-3/5 self-center">
                <Dropdown
                  placeholder="Select"
                  onChange={changeSpace}
                  options={spacesDD}
                  disableErrorMessage
                  value={spacesDD?.find(
                    (item) => item?.value === asset?.spaces?.[0]
                  )}
                  // eslint-disable-next-line no-return-assign, no-param-reassign
                  forwardedRef={(el) => (inputRefs.current.space = el)}
                  disableClear={subAssociation}
                  // eslint-disable-next-line no-return-assign
                  onKeyUp={(event) => handleKeyUp(event)}
                  isDisabled={subAssociation}
                />
              </div>
            </div>
            <div className="flex flex-row pt-4">
              <div className="w-2/5 self-center">
                <h3 className={boldCN()}>Description</h3>
              </div>
              <div className="w-3/5">
                <Input
                  inputClassName="bg-backgroundGreen w-full h-full p-1 pl-3 ESInput text-sm"
                  inputContainerClassName="p-0 m-0"
                  onChange={onChangeDescription}
                  value={asset.description}
                  placeholder="Description"
                  isTextarea
                />
              </div>
            </div>
          </div>
        </div>
      </Widget>
      <AttributeModalWidget
        resource={asset}
        editing
        dispatch={dispatch}
        newAttributes={newAttributes}
        setNewAttributes={setNewAttributes}
        loading={false}
      />
      <div className="flex flex-row gap-4">
        <Widget
          title="Tags"
          width="1/2"
          draggable={false}
          childClassName="h-full"
          overflow
        >
          <TagsContainer
            dispatch={dispatch}
            className="flex flex-col flex-wrap gap-6"
            isEditing
            resource={asset}
            fullWidth
          />
        </Widget>
        <LinksView
          resource={asset}
          editing
          loading={false}
          dispatch={dispatch}
          widgetWidth="1/2"
        />
      </div>
    </div>
  );
};

AssetInfoForm.propTypes = {
  /**
   * create asset form state
   */
  // eslint-disable-next-line react/forbid-prop-types
  asset: PropTypes.object,
  /**
   * create asset form dispatcher
   */
  dispatch: PropTypes.func,
  /**
   * is the form reset back to the original state, untouched
   */
  isFormReset: PropTypes.bool,
  setIsFormReset: PropTypes.func,
  /**
   * array of the possible categories for an asset, formatted for the DDL
   */
  // eslint-disable-next-line react/forbid-prop-types
  categoryOptionsList: PropTypes.arrayOf(PropTypes.object),
  /**
   * object mapping category codes to the array of possible subcategories, formatted for the DDL
   */
  subCatOptionsMap: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),
  // eslint-disable-next-line react/forbid-prop-types
  associatedResource: PropTypes.object,
  setAssociatedResource: PropTypes.func,
  /**
   * If user came from Property -> Create Asset
   */
  disableAssociation: PropTypes.bool,
  mediaFiles: PropTypes.shape({}),
  handleKeyUp: PropTypes.func,
  inputRefs: PropTypes.shape({
    current: PropTypes.shape({
      assetName: PropTypes.arrayOf(PropTypes.string),
      association: PropTypes.arrayOf(PropTypes.string),
      category: PropTypes.arrayOf(PropTypes.string),
      description: PropTypes.string,
      location: PropTypes.string,
      space: PropTypes.string,
      assetLinks: PropTypes.string,
    }),
  }),
  assetModalStatus: PropTypes.shape({
    propertyId: PropTypes.string,
    projectId: PropTypes.string,
    spaceId: PropTypes.string,
    allowAssocSelect: PropTypes.bool,
    disableSpaceSelect: PropTypes.bool,
  }),
  spacesDD: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.shape({}),
    })
  ),
  newAttributes: PropTypes.arrayOf(PropTypes.shape({})),
  setNewAttributes: PropTypes.func,
  subAssociation: PropTypes.string,
};

AssetInfoForm.defaultProps = {
  asset: {},
  dispatch: undefined,
  isFormReset: false,
  setIsFormReset: undefined,
  categoryOptionsList: [],
  subCatOptionsMap: {},
  associatedResource: undefined,
  setAssociatedResource: undefined,
  disableAssociation: false,
  mediaFiles: [],
  handleKeyUp: undefined,
  inputRefs: { current: {} },
  assetModalStatus: {},
  spacesDD: [],
  newAttributes: [],
  setNewAttributes: undefined,
  subAssociation: undefined,
};

export default AssetInfoForm;

import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import PrimaryHeader from "../TextHeaders/PrimaryHeader";
import checkIcon from "../../assets/images/checkSolid.svg";

const buttonCN = cntl`
  w-full
`;

const containerCN = (className, isSelected) => cntl`
  flex
  flex-col
  border
  rounded-lg
  m-2
  p-6
  w-full
  w-max
  ${isSelected && cntl`border-brandGreen`}
  ${className}
`;

const innerCN = (className) => cntl`
  flex
  justify-left
  ${className}
`;

const PricingCard = ({
  data,
  onClick,
  isSelected,
  cardStyle,
  activeUsers,
  isMonthly,
  isSaving,
  setIsMonthly,
}) => {
  return (
    <div
      className={containerCN(cardStyle, isSelected)}
      style={{ width: "310px" }}
    >
      <div className={innerCN("my-6")}>
        <SecondaryHeader
          className="text-es-dark-grey"
          textSize="text-3xl"
          fontWeight="bold"
        >
          {data.planName}
        </SecondaryHeader>
      </div>
      <div className={innerCN("flex-row pt-6")}>
        <PrimaryHeader className="font-bold text-6xl text-es-dark-grey">
          ${isMonthly ? parseInt(data.cost, 10) : data.cost}
        </PrimaryHeader>
        {isMonthly && (
          <p className="flex items-center ml-3 text-md tracking-es-normal">
            per user/month
          </p>
        )}
      </div>
      {data.showDiscount && (
        <p className="flex flex-row mt-8 text-sm flex-wrap">
          <button
            className="flex underline text-es-green"
            onClick={() => setIsMonthly(false)}
            type="button"
          >
            Switch to annual
          </button>
          <span className="flex pl-1">to </span>
          <span className="flex font-semibold pl-1">
            {" "}
            get {data.discount}% off
          </span>
        </p>
      )}
      <div className={innerCN("pb-6 pt-10")}>
        <PrimaryButton
          title={isSelected ? "Current Plan" : "Get Started"}
          onClick={onClick}
          className={buttonCN}
          disabled={isSelected || activeUsers > data.maxUsers || isSaving}
          noMaxWidth
        />
      </div>
      {activeUsers !== undefined && (
        <div className="pb-4 h-20">
          {activeUsers > data.maxUsers && (
            <>
              <p className="text-xs text-center">
                You currently have {activeUsers} active users on your plan. To
                downgrade, your must first deactivate some users in{" "}
                {/* TODO: set link to open memeber management */}
                <a
                  href="https://estatespace.com/eula/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-brandGreen text-center text-xs"
                >
                  Member Management.
                </a>
              </p>
            </>
          )}
          {!isSelected && !isMonthly && activeUsers < data.minUsers && (
            <>
              <p className="text-xs text-center">
                Upgrade today and you’ll receive a $
                {/* TODO: derive discount formula */}
                {data.discount * data.minUsers} prorated credit for the
                remainder of the year.
              </p>
            </>
          )}
        </div>
      )}
      <div className="py-3">
        {data?.features?.map((feature, index) => (
          <div className="mx-2 mb-4 flex flex-row w-max" key={feature}>
            <button className="mr-3" type="button">
              <img className="w-4 h-4" src={checkIcon} alt="edit icon" />
            </button>
            <div className="flex flex-col">
              {feature.split(/\r?\n/).map((line) => {
                return (
                  <p
                    key={line}
                    className={`text-black text-sm  tracking-es-normal ${
                      index === 0 && "font-semibold"
                    }`}
                  >
                    {line}
                  </p>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

PricingCard.propTypes = {
  data: PropTypes.shape({
    planName: PropTypes.string,
    userText: PropTypes.string,
    cost: PropTypes.string,
    discount: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.string),
    showDiscount: PropTypes.bool,
    minUsers: PropTypes.number,
    maxUsers: PropTypes.number,
  }),
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  cardStyle: PropTypes.string,
  activeUsers: PropTypes.number,
  isMonthly: PropTypes.bool,
  isSaving: PropTypes.bool,
  setIsMonthly: PropTypes.func,
};

PricingCard.defaultProps = {
  data: {},
  onClick: null,
  isSelected: false,
  cardStyle: undefined,
  activeUsers: undefined,
  isMonthly: false,
  isSaving: false,
  setIsMonthly: () => {},
};

export default PricingCard;

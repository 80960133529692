/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { hours } from "../../../constants";
import DayHeader from "./Day/DayHeader";
import DayBody from "./Day/DayBody";
import DayEventMap from "./Day/DayEventMap";
import smoothScrollTo from "../../../helpers/smoothScroll";
import { useAppState } from "../../../state/appState";

const DayCalendar = ({
  now,
  events,
  handleEventClick,
  filter,
  offset,
  setOffset,
}) => {
  const [{ calendarTimezone }] = useAppState();
  const currentDay = moment
    .tz(new Date(), calendarTimezone)
    .startOf("day")
    .isSame(moment.tz(now, calendarTimezone).startOf("day").format());
  const dayValue = moment.tz(now, calendarTimezone).startOf("day").format();

  const bodyRef = useRef(null);

  /**
   * Scroll to current time of day on render
   */
  useEffect(() => {
    if (bodyRef.current) {
      smoothScrollTo("now-indicator");
    }
  }, []);
  return (
    <div className="w-full h-full mb-5">
      <DayHeader
        value={dayValue}
        filter={filter}
        events={events}
        handleEventClick={handleEventClick}
        offset={offset}
        setOffset={setOffset}
      />
      <div
        style={{ maxHeight: "1000px" }}
        className="overflow-y-scroll hide-scrollbar relative mb-1"
        ref={bodyRef}
        id="day-calendar"
      >
        <DayBody hours={hours} />
        <DayEventMap
          value={dayValue}
          filter={filter}
          events={events}
          handleEventClick={handleEventClick}
          currentDay={currentDay}
        />
      </div>
    </div>
  );
};

DayCalendar.propTypes = {
  now: PropTypes.string,
  events: PropTypes.shape({}),
  handleEventClick: PropTypes.func,
  filter: PropTypes.func,
  offset: PropTypes.number,
  setOffset: PropTypes.func,
};

DayCalendar.defaultProps = {
  now: undefined,
  events: {},
  handleEventClick: undefined,
  filter: undefined,
  offset: 0,
  setOffset: () => {},
};

export default DayCalendar;

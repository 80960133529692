import React from "react";
import "./NotificationTray.css";
import cntl from "cntl";
import useNotifications from "../../../hooks/useNotifications";
import CrossButton from "../CrossButton/CrossButton";
import crossBlack from "../../assets/images/blackCrossIcon.svg";
import readIcon from "../../assets/images/notiReadIcon.svg";
import unreadIcon from "../../assets/images/notiUnreadIcon.svg";
import flagIcon from "../../assets/images/notiFlagIcon.svg";
import trashIcon from "../../assets/images/notiTrashIcon.svg";
import searchIcon from "../../assets/images/notiSearch.svg";
import filterIcon from "../../assets/images/notiFilter.svg";
import checkmark from "../../assets/images/checkmarkNew.svg";
import TrayNotification from "./TrayNotification";
import useNotificationsData from "./useNotificationsData";
import SearchBar from "../SearchBar/SearchBar";
import { TimePeriodDivision } from "../../../helpers/Dates";
import NotificationTimePeriodElement from "./NotificationTimePeriodElement";
import TrayContainer from "../TrayContainer";
import Chevron from "../Chevron/Chevron";
import { notificationCheckboxOptions } from "../../../constants";

const NotificationTray = ({ open, close }) => {
  const { data: notifications = [] } = useNotifications();

  const {
    handleAction,
    search,
    filters,
    multiselectOpen,
    setMultiselectOpen,
    handleMultiSelect,
    handleFlagged,
    ...notifData
  } = useNotificationsData();

  const dropdownIconCN = (props) => cntl`
  transform
  ${props ? "rotate-90" : ""}
  ${"h-3 w-3"}
`;

  const NotificationDivision = new TimePeriodDivision(
    NotificationTimePeriodElement
  );

  return (
    <TrayContainer isOpen={open}>
      <header className="header flex justify-between items-center px-6">
        <p className="font-semibold">Notifications</p>
        <CrossButton icon={crossBlack} onClick={close} />
      </header>
      <div className="flex items-center px-6 bg-white action-tray">
        <div className="grid grid-cols-10 overflow-hidden items-center w-full">
          <div
            className="col-span-2 w-14 p-2 rounded-lg gap-2 bg-es-mint bg-opacity-10"
            id="checkbox-multiselect"
          >
            <button
              aria-label="openmulti"
              type="button"
              title="multi-select"
              className="flex gap-2 items-center justify-center relative"
              onClick={() => setMultiselectOpen(!multiselectOpen)}
            >
              <input
                checked={false}
                type="checkbox"
                className=" border-2 rounded-md px-2 py-4 h-5 w-5"
              />
              <Chevron
                color="#101010"
                strokeWidth="1px"
                className={dropdownIconCN(multiselectOpen)}
              />
            </button>
            {multiselectOpen && (
              <div className="w-36 h-60 flex flex-col items-center absolute  rounded-lg border-2 shadow-xl bg-white">
                <ul className="w-full">
                  {notificationCheckboxOptions?.map((option) => {
                    return (
                      <button
                        key={option}
                        type="button"
                        onKeyDown={(e) =>
                          handleMultiSelect(option, notifications, e)
                        }
                        onClick={() => handleMultiSelect(option, notifications)}
                        className="hover:bg-brandGreen h-12 w-full p-3 gap-3 text-es-normal font-es-normal flex items-center justify-start cursor-pointer rounded-md"
                      >
                        {option}
                      </button>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
          <button
            onClick={() => handleFlagged()}
            onKeyDown={(e) => handleFlagged(e)}
            type="button"
            title="flag"
          >
            <img src={flagIcon} alt="flag-notif" className="col-span-1" />
          </button>
          <button
            onClick={() => handleAction("$read")}
            onKeyDown={(e) => handleAction("$read", e)}
            type="button"
            title="mark read"
          >
            <img src={readIcon} alt="mark-read" className="col-span-1" />
          </button>
          <button
            onClick={() => handleAction("$unread")}
            onKeyDown={(e) => handleAction("$unread", e)}
            type="button"
            title="mark unread"
          >
            <img src={unreadIcon} alt="mark-unread" className="col-span-1" />
          </button>
          <button
            onClick={() => handleAction("$dismissed")}
            onKeyDown={(e) => handleAction("$dismissed", e)}
            type="button"
            title="delete notifications"
          >
            <img src={trashIcon} alt="delete-notif" className="col-span-1" />
          </button>

          <button
            onClick={() => search.open(!search.isOpen)}
            onKeyDown={(e) => search.open(!search.isOpen, e)}
            type="button"
            className="col-start-9 col-span-1 "
          >
            <img
              src={searchIcon}
              alt="search-notif"
              style={{ height: "18px" }}
              className="w-full float-right"
            />
          </button>
          <button
            onClick={() => filters.open()}
            onKeyDown={(e) => filters.open(e)}
            type="button"
            className="col-start-10 col-span-1"
          >
            <img
              src={filterIcon}
              alt="filter-notif"
              style={{ height: "18px" }}
              className="col-start-10 col-span-1 w-full float-right"
            />
          </button>
        </div>
      </div>
      {search.isOpen && (
        <div className="noti-list-search-bar-open">
          <SearchBar
            onSearchChange={(val) => search.handleSearch(val)}
            globalFilter={search.value}
            placeholder={search.isOpen ? "Search Notifications..." : ""}
          />
        </div>
      )}

      <div
        className={`noti-list-filter-bar-open ${
          filters.isOpen ? "" : "noti-list-filter-bar-closed"
        } flex w-full flex-wrap gap-2 px-4 pt-2`}
      >
        {filters?.values?.map((item, i) => (
          <button
            className={`rounded-full ${
              item.value
                ? "bg-primaryGreen text-white"
                : "bg-gray-150 text-gray-300"
            } text-sm px-2 py-1`}
            onClick={() => filters.change(i)}
            type="button"
            key={`${item?.label}-filter`}
          >
            {item.value ? (
              <>
                <img className="inline" alt="filter-selected" src={checkmark} />{" "}
              </>
            ) : (
              ""
            )}
            {item.label}
          </button>
        ))}
      </div>
      <ul className="flex flex-col noti-list">
        {search.filter(notifications)?.reduce((list, item) => {
          NotificationDivision.division(list, item.created);
          list.push(
            <TrayNotification {...item} {...notifData} close={close} />
          );
          return list;
        }, [])}
      </ul>
    </TrayContainer>
  );
};

export default NotificationTray;

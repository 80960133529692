import React from "react";
import chevronRightIcon from "../../../../stories/assets/images/chevronRightDarkGreen.svg";
import IconButton from "../../../../stories/Components/Buttons/IconButton";

const SopHistoryRow = ({
  sop,
  isExpanded,
  getDateFormatted,
  handleNavigateToSop,
}) => {
  return (
    <table className="w-full table-fixed h-16">
      <tr
        className={`h-16 align-middle text-gray-400 ${
          isExpanded && "bg-backgroundGrey"
        }`}
      >
        <td
          className="border-r border-gray-150 text-md p-4 w-40"
          style={{ verticalAlign: "top" }}
        >
          <p className="text-es-normal font-es-semibold text-es-medium-grey">{`Version ${sop.version}`}</p>
        </td>
        <td
          className="border-r text-md p-4 border-gray-150"
          style={{ verticalAlign: "top" }}
        >
          <p className="text-es-normal font-es-semibold text-es-medium-grey pb-2">
            Title
          </p>
          <p>{sop.name}</p>
        </td>
        <td
          className="border-r text-md p-4 border-gray-150"
          style={{ verticalAlign: "top" }}
        >
          <p className="text-es-normal font-es-semibold text-es-medium-grey pb-2">
            Type
          </p>
          {sop?.category}
        </td>
        <td
          className="border-r text-md p-4 border-gray-150"
          style={{ verticalAlign: "top" }}
        >
          <p className="text-es-normal font-es-semibold text-es-medium-grey pb-2">
            Date Modified
          </p>
          {getDateFormatted(sop.dateRetired)}
        </td>
        <td
          className="border-r text-md p-4 border-gray-150"
          style={{ verticalAlign: "top" }}
        >
          <p className="text-es-normal font-es-semibold text-es-medium-grey pb-2">
            Date Created
          </p>
          {getDateFormatted(sop.dateCreated)}
        </td>
        <td
          className="text-md p-4 border-gray-150 justify-center w-40"
          aria-label="table-data"
        >
          <IconButton
            icon={chevronRightIcon}
            isIconOnRight
            imgClassName="w-3 h-3"
            className="text-darkenedGreen"
            onClick={() => handleNavigateToSop(sop)}
            title="View SOP"
          />
        </td>
      </tr>
    </table>
  );
};

export default SopHistoryRow;

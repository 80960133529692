// APIs
import moment from "moment";
import PropTypes from "prop-types";
import { capitalize } from "lodash";
// Framework Tools
import React, { useState, useEffect } from "react";
// helpers
import { capitalized, getFullName } from "../../helpers/Formatters";
// Components
import RequestInfoContainer from "../../stories/Components/RequestInfoContainer/RequestInfoContainer";
import LinksView from "../../stories/Components/Links/LinksView";
import Widget from "../../stories/Components/Widget/Widget";
import { SUBMITTAL_STATUS_TYPES } from "../../constants";
import Avatar from "../../stories/Components/Message/Avatar";
import RequestGridViewDistroListCell from "../../stories/Components/Requests/RequestGridView/RequestGridViewDistroListCell";
import AssociationLink from "../../stories/Components/AssociationLink";

const SubmittalsDetails = ({
  submittal,
  loading,
  project,
  submittalForm,
  submittalFormDispatch,
  isDraft,
  editing,
  projectDD,
  handleDistroList,
  projectMembers,
  ballInCourt,
  type,
  submittalTypes,
  initiated,
  handleArtifactsList,
  submittalDDOptions,
  handleTargetCompletion,
  handleProjectedCompletion,
  distroList,
  submittalHistory,
  commentMap,
}) => {
  const [completedComment, setCompletedComment] = useState();

  useEffect(() => {
    const resolvedHistoryComment = submittalHistory?.[submittal?.round]?.find(
      (historyItem) => !!historyItem?.context?.submittal?.resolved?.user
    )?.context?.comment?.reference;

    setCompletedComment(commentMap?.[resolvedHistoryComment]?.content);
  }, [commentMap, submittal?.round, submittalHistory]);

  return (
    <div className="gap-10 grid">
      {/* Many columns and rows will have a truthy value, when editing it uses the submittalForm otherwise use the loaded submittal */}
      <Widget
        title="Submittal Information"
        draggable={false}
        loading={loading}
        overflow
        className="h-min"
      >
        <RequestInfoContainer
          columns={[
            {
              rows: [
                {
                  title: "Project",
                  value: (
                    <AssociationLink
                      associationName={project?.name}
                      associationRef={project?.reference}
                    />
                  ),
                  form: submittalForm,
                  dispatch: submittalFormDispatch,
                  editing: {
                    isEditing: isDraft && editing,
                    isDraft,
                    value: {
                      field: "association",
                      options: projectDD,
                      type: "Dropdown",
                      editingValue: {
                        label: project?.name,
                        value: project?.reference,
                      },
                    },
                    disabled: true,
                  },
                },
                {
                  form: submittalForm,
                  dispatch: submittalFormDispatch,
                  title: "Distro List",
                  value: editing ? handleDistroList() : distroList,
                  distroList: true,
                  distroListOptions: projectMembers,
                  editing: {
                    isEditing: isDraft && editing,
                    isDraft,
                    value: {
                      options: projectMembers?.filter(
                        (item) =>
                          !submittalForm?.distribution?.includes(item?.value)
                      ),
                      type: "distroDropdown",
                    },
                  },
                },
                ...(submittal?.status !== SUBMITTAL_STATUS_TYPES?.DRAFT &&
                submittal?.status !== SUBMITTAL_STATUS_TYPES?.COMPLETED_OPEN &&
                submittal?.status !== SUBMITTAL_STATUS_TYPES?.COMPLETED_RESOLVED
                  ? [
                      {
                        customElementCN: "relative flex flex-wrap w-2/3",
                        title: "Ball In Court",
                        value: (
                          <RequestGridViewDistroListCell
                            hideTile
                            distroList={ballInCourt}
                            hideDeleteIcon
                          />
                        ),
                      },
                    ]
                  : []),
                {
                  title: "Type",
                  value: type?.display,
                  form: submittalForm,
                  dispatch: submittalFormDispatch,
                  editing: {
                    isEditing: isDraft && editing,
                    isDraft,
                    value: {
                      field: "type",
                      options: submittalTypes,
                      type: "Dropdown",
                      editingValue: {
                        label: type?.display,
                        value: type?.id,
                      },
                    },
                  },
                },
                {
                  title: "Description",
                  value: editing
                    ? submittalForm?.description
                    : submittal?.description,
                  form: submittalForm,
                  dispatch: submittalFormDispatch,
                  editing: {
                    isEditing: editing,
                    isDraft,
                    value: {
                      field: "description",
                      fieldValue: submittalForm?.description,
                      type: "Input",
                      isTextarea: true,
                    },
                  },
                  last: true,
                },
              ],
            },
            {
              rows: [
                ...(submittal?.status !== SUBMITTAL_STATUS_TYPES?.DRAFT
                  ? [
                      {
                        title: "Initiated By",
                        value: (
                          <div className="flex">
                            {initiated?.name && (
                              <>
                                <Avatar
                                  className="w-10 h-10 text-xs mr-1"
                                  isExternalUser
                                  id={initiated?.id}
                                  avatar={initiated?.avatar}
                                />
                                <p className="flex items-center">
                                  {getFullName(initiated?.name)} on
                                  {` ${moment(initiated?.date).format(
                                    "MMMM, DD, YYYY"
                                  )}`}
                                </p>
                              </>
                            )}
                          </div>
                        ),
                      },
                    ]
                  : []),
                ...(submittal?.status !== SUBMITTAL_STATUS_TYPES?.DRAFT &&
                submittal?.status !== SUBMITTAL_STATUS_TYPES?.IN_PROGRESS
                  ? [
                      {
                        title: "Vendor",
                        value: capitalize(initiated?.companyName),
                      },
                    ]
                  : []),
                {
                  title: "Associations",
                  value: handleArtifactsList(),
                  associations: true,
                  form: submittalForm,
                  dispatch: submittalFormDispatch,
                  editing: {
                    isEditing: isDraft && editing,
                    isDraft,
                    value: {
                      field: "associatedArtifacts",
                      options: submittalDDOptions?.filter(
                        (option) => !option?.isTemplate
                      ),
                      type: "Dropdown",
                    },
                  },
                  /* **  TO DO: set last: false when unhiding Budget Reference and Schedule Reference below ** */
                  last: true,
                },

                /* ** Hiding Schedule Reference field until Budget is complete ** */

                // {
                //   title: "Schedule Reference",
                //   value: editing
                //     ? submittalForm?.schedule?.description
                //     : submittal?.schedule?.description,
                //   form: submittalForm,
                //   dispatch: submittalFormDispatch,
                //   editing: {
                //     isEditing: editing,
                //     isDraft,
                //     value: {
                //       field: "scheduleDescription",
                //       fieldValue: submittalForm?.schedule?.description,
                //       type: "Input",
                //     },
                //   },
                // },

                /* ** Hiding Budget Reference field until Budget is complete ** */

                // {
                //   title: "Budget Reference",
                //   form: submittalForm,
                //   dispatch: submittalFormDispatch,
                //   value: editing
                //     ? submittalForm?.budget?.description
                //     : submittal?.budget?.description,
                //   editing: {
                //     isEditing: editing,
                //     isDraft,
                //     value: {
                //       field: "budgetDescription",
                //       fieldValue: submittalForm?.budget?.description,
                //       type: "Input",
                //     },
                //   },
                //   last: true,
                // },
              ],
            },
          ]}
        />
      </Widget>
      <div className="grid grid-cols-2 w-full gap-10 h-min">
        <Widget
          overflow
          title="Timing"
          loading={loading}
          draggable={false}
          className="h-min"
        >
          <RequestInfoContainer
            columns={[
              {
                rows: [
                  {
                    title: "Start Date",
                    value: moment(submittalForm?.startDate?.actual).format(
                      "MMMM DD, YYYY"
                    ),
                    form: submittalForm,
                    dispatch: submittalFormDispatch,
                    editing: {
                      isEditing: isDraft && editing,
                      isDraft,
                      value: {
                        field: "StartDate",
                        type: "Date",
                        display: moment(
                          submittalForm?.startDate?.actual
                        ).format("MMMM DD, YYYY"),
                        value: submittalForm?.startDate?.actual,
                        minDate: new Date(),
                      },
                    },
                  },
                  {
                    title: "Target Completion",
                    value:
                      /* if in edit mode and submittal is draft this field can change */
                      editing && isDraft
                        ? handleTargetCompletion()
                        : moment(submittal?.completion?.target).format(
                            "MMMM DD, YYYY"
                          ),
                    form: submittalForm,
                    dispatch: submittalFormDispatch,
                    editing: {
                      isEditing: false,
                      isDraft,
                      value: {
                        field: "CompletionTargetDate",
                        type: "Date",
                        display: moment(submittal?.completion?.target).format(
                          "MMMM DD, YYYY"
                        ),
                        value: submittalForm?.completion?.target,
                      },
                    },
                  },
                  {
                    title: "Projected Completion",
                    value:
                      /* if in edit mode and submittal is draft this field can change */
                      editing && isDraft
                        ? handleProjectedCompletion()
                        : moment(submittal?.completion?.projected).format(
                            "MMMM DD, YYYY"
                          ),
                    form: submittalForm,
                    dispatch: submittalFormDispatch,
                    editing: {
                      isEditing: false,
                      isDraft,
                      value: {
                        field: "CompletionProjectedDate",
                        type: "Date",
                        display: moment(
                          submittal?.completion?.projected
                        ).format("MMMM DD, YYYY"),
                        value: submittalForm?.completion?.projected,
                      },
                    },
                  },
                  {
                    title: "Duration",
                    value: `${submittal?.duration?.numDays} ${
                      submittal?.duration?.numDays === 1 ? "Day" : "Days"
                    }`,
                    last: true,
                  },
                ],
              },
            ]}
          />
        </Widget>
        <LinksView
          resource={submittalForm}
          dispatch={submittalFormDispatch}
          editing={editing}
          loading={loading}
          widgetWidth="1/3"
          className="w-full h-min"
        />
      </div>
      <div className="grid grid-cols-2 w-full gap-10 h-min">
        {submittal?.status?.includes("completed") && (
          <Widget
            title="Impact"
            loading={loading}
            draggable={false}
            className="h-min"
          >
            <RequestInfoContainer
              columns={[
                {
                  rows: [
                    {
                      title: "Results",
                      value: capitalized(submittal?.status?.split("-")[1]),
                    },
                    {
                      title: "Schedule Impact",
                      value: submittal?.impacts?.schedule?.status,
                      impactValue: submittal?.impacts?.schedule?.value,
                    },
                    {
                      title: "Budget Impact",
                      value: submittal?.impacts?.budget?.status,
                      impactValue: submittal?.impacts?.budget?.value,
                    },
                    {
                      title: "Comments",
                      value: completedComment,
                      last: true,
                    },
                  ],
                },
              ]}
            />
            <div />
          </Widget>
        )}
      </div>
    </div>
  );
};

SubmittalsDetails.propTypes = {
  submittal: PropTypes.shape({
    round: PropTypes.string,
    status: PropTypes.string,
    description: PropTypes.string,
    schedule: PropTypes.shape({
      description: PropTypes.string,
    }),
    startDate: PropTypes.shape({
      actual: PropTypes.string,
    }),
    budget: PropTypes.shape({
      description: PropTypes.string,
    }),
    completion: PropTypes.shape({
      target: PropTypes.string,
      projected: PropTypes.string,
    }),
    duration: PropTypes.shape({
      numDays: PropTypes.string,
    }),
    impacts: PropTypes.shape({
      budget: PropTypes.shape({
        status: PropTypes.string,
        value: PropTypes.string,
      }),
      schedule: PropTypes.shape({
        status: PropTypes.string,
        value: PropTypes.string,
      }),
    }),
  }),
  loading: PropTypes.bool,
  project: PropTypes.shape({
    name: PropTypes.string,
    reference: PropTypes.string,
  }),
  submittalForm: PropTypes.shape({
    distribution: PropTypes.string,
    description: PropTypes.string,
    schedule: PropTypes.shape({
      description: PropTypes.string,
    }),
    budget: PropTypes.shape({
      description: PropTypes.string,
    }),
    completion: PropTypes.shape({
      target: PropTypes.string,
      projected: PropTypes.string,
    }),
    startDate: PropTypes.shape({
      actual: PropTypes.string,
    }),
  }),
  submittalFormDispatch: PropTypes.func,
  isDraft: PropTypes.bool,
  editing: PropTypes.bool,
  projectDD: PropTypes.arrayOf(PropTypes.shape({})),
  handleDistroList: PropTypes.func,
  projectMembers: PropTypes.arrayOf(PropTypes.shape({})),
  ballInCourt: PropTypes.shape({
    name: PropTypes.string,
  }),
  type: PropTypes.shape({
    display: PropTypes.string,
    id: PropTypes.string,
  }),
  submittalTypes: PropTypes.arrayOf(PropTypes.shape({})),
  initiated: PropTypes.shape({
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    date: PropTypes.string,
    companyName: PropTypes.string,
    id: PropTypes.string,
    avatar: PropTypes.string,
    kind: PropTypes.string,
  }),
  handleArtifactsList: PropTypes.func,
  submittalDDOptions: PropTypes.arrayOf(PropTypes.shape({})),
  handleTargetCompletion: PropTypes.func,
  handleProjectedCompletion: PropTypes.func,
  distroList: PropTypes.arrayOf(PropTypes.shape({})),
  submittalHistory: PropTypes.shape({}),
  commentMap: PropTypes.shape({}),
};

SubmittalsDetails.defaultProps = {
  submittal: undefined,
  loading: false,
  project: undefined,
  submittalForm: undefined,
  submittalFormDispatch: undefined,
  isDraft: false,
  editing: false,
  projectDD: [],
  handleDistroList: undefined,
  projectMembers: [],
  ballInCourt: undefined,
  type: undefined,
  submittalTypes: [],
  initiated: undefined,
  handleArtifactsList: undefined,
  submittalDDOptions: [],
  handleTargetCompletion: undefined,
  handleProjectedCompletion: undefined,
  distroList: [],
  submittalHistory: {},
  commentMap: undefined,
};

export default SubmittalsDetails;

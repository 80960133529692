import React from "react";
import PropTypes from "prop-types";

const ProgressBar = ({ progress }) => {
  const fillerStyles = {
    width: `${progress}%`,
  };
  return (
    <div className="h-2 w-full bg-gray-100 rounded">
      <div
        className="h-full w-full bg-brandGreen rounded"
        style={fillerStyles}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  /**
   * progress displayed on bar
   */
  progress: PropTypes.number,
};

ProgressBar.defaultProps = {
  progress: [],
};
export default ProgressBar;

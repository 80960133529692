import cntl from "cntl";
import React, { useRef } from "react";
import * as yup from "yup";
import Attachments from "../../../../Pages/ServiceRequest/Attachments";
import { PRIMARY_DARK_GREEN } from "../../../../constants";
import convertTZ from "../../../../helpers/Date/convertTimezone";
import getRecurrenceSelectValue from "../../../../helpers/Date/getRecurrenceSelectValue";
import { getUserFirstAndLast } from "../../../../helpers/Utilities";
import AddSpacesAssetsWidget from "../../AddSpacesAssetsWidget";
import PlusCircleButton from "../../Buttons/PlusCircleButton/PlusCircleButton";
import Checkbox from "../../Checkbox/Checkbox";
import DatePicker from "../../DatePicker/DatePicker";
import TimePicker from "../../DatePicker/TimePicker";
import DistroListSelectMembers from "../../DistroList/DistroListSelectMembers";
import Dropdown from "../../Dropdown/Dropdown";
import Input from "../../Input/Input";
import LinksModalWidget from "../../Links/LinksModalWidget";
import ModalMediaWidget from "../../ModalMediaWidget";
import RequestGridViewDistroListCell from "../../Requests/RequestGridView/RequestGridViewDistroListCell";
import TagsContainer from "../../Tags/TagsContainer";
import useCreateEditEventFormData from "./useCreateEditEventFormData";

const widgetContainer = {
  border: "1px solid #CACACA",
  borderRadius: "6px",
};

const recurrenceStringStyle = (allDay = false) => cntl`
  ${!allDay ? "col-start-3" : "col-start-7"} 
  col-span-6 
  text-sm 
  text-gray-450
`;

const modalRow = (showBorder, className) => cntl`
  flex
  flex-row
  ${showBorder && "border-b"}
  col-span-6
  justify-between
  items-center
  pb-4
  pt-4
  ${className}
`;

const widgetCN = "px-6 pb-8 pt-4 flex flex-col gap-2";

const divider = "border-b border-gray-250 mt-2 mb-2";

const eventNameInputCN =
  "bg-inputBgGreen pl-4 pr-8 w-full overflow-hidden font-semibold text-gray-300 text-xl";

const modalContainer =
  "flex flex-col relative overflow-y-auto px-1 pt-1 gap-6 pb-6";

const labelWidth = "w-1/4";

const inputWidth = "w-3/4";

const row = "flex flex-row gap-6 w-full";

const labelCN = "font-semibold text-gray-450";

const CreateEditEventForm = ({ formData, associationDisabled, isEdit }) => {
  const recurrenceDropDownRef = useRef(null);

  const {
    formState,
    allDayData,
    startDate,
    endDate,
    recurrenceOptions,
    currentInvitees,
    attendeePopupOpen,
    invitesForDropdown,
    recurrenceString,
    projectDD,
    propertiesDD,
    locationsAvailable,
    filesState,
    associationLocked,
    createdBy,
    handleTagsDispatch,
    handleFilesToRemove,
    handleFilesToAdd,
    removeFormFileDuplicates,
    resourceDropdownValue,
    handleAddInvitee,
    handleRemoveInvitee,
    setAttendeePopupOpen,
    handleFormChange,
    handleSelectSpaces,
    handleRemoveSpaces,
    handleAddLink,
    handleRemoveLink,
  } = useCreateEditEventFormData({
    formData,
    recurrenceDropDownRef,
    isEdit,
  });

  const isNewEvent = !formState?.id;

  const eventHasTags = formState?.tags?.length > 0;

  // Styling for Tags section:
  // When Task is new `Created By` row is hidden and move `Tags` to `Created By` updating the top position
  let tagsSectionClassName = `${modalRow(false)} flex w-1/2`;

  if (isNewEvent) {
    if (eventHasTags) {
      tagsSectionClassName += " items-stretch -mt-20";
    } else if (recurrenceString) {
      tagsSectionClassName += " -mt-48";
    } else {
      tagsSectionClassName += " -mt-40";
    }
  } else if (eventHasTags || recurrenceString) {
    tagsSectionClassName += " items-stretch";
  }

  return (
    <div id="first" className={modalContainer}>
      <Input
        inputClassName={eventNameInputCN}
        inputContainerClassName="bg-inputBgGreen shadow-light-lift"
        placeholder="Name"
        value={formState.name}
        validation={yup.string().required()}
        onChange={(value) => handleFormChange("name", value)}
      />

      <div className={widgetCN} style={widgetContainer}>
        {/* Row: Association and Timezone */}
        <div className={row}>
          {/** Association */}
          <div className={`${modalRow(true)} flex w-1/2`}>
            <div className={labelWidth}>
              <p className={labelCN}>Association</p>
            </div>
            <div className={inputWidth}>
              <Dropdown
                customErrorMessage={
                  formState?.association ?? associationDisabled
                    ? false
                    : "Association must be selected."
                }
                options={[
                  { label: "Projects", options: projectDD },
                  { label: "Properties", options: propertiesDD },
                ]}
                onChange={(value) => handleFormChange("association", value)}
                placeholder="Select"
                value={resourceDropdownValue()}
                isDisabled={associationLocked || associationDisabled}
              />
            </div>
          </div>

          {/* Timezone */}
          <div className={`${modalRow(true)} flex w-1/2`}>
            <div className={labelWidth}>
              <p className={labelCN}>Timezone</p>
            </div>
            <div className={inputWidth}>
              <p>{formState?.timezone ?? "N/A"}</p>
            </div>
          </div>
        </div>

        {/* Row: Start and End */}
        <div className={row}>
          {/** Start date */}
          <div className={`${modalRow(true)} flex w-1/2`}>
            <div className={labelWidth}>
              <p className={labelCN}>Start</p>
            </div>
            <div className="flex flex-row w-3/4 gap-1">
              <div style={{ width: "50%" }}>
                <DatePicker
                  onChange={(value) => handleFormChange("startDate", value)}
                  className="bg-input w-full"
                  value={convertTZ(startDate, formState.timezone)}
                  validation={yup.string().required()}
                  dateFormat="MMM dd, yyyy"
                  placeholder="Start"
                />
              </div>

              {!allDayData.isAllDay && (
                <div style={{ width: "35%" }}>
                  <TimePicker
                    textClassName="bg-input"
                    customPadding="pr-0"
                    isLast
                    value={convertTZ(startDate, formState.timezone)}
                    validation={yup.string().required()}
                    timeIntervals={5}
                    onChange={(value) => handleFormChange("startTime", value)}
                    disabled={allDayData.isAllDay}
                    manualEntry={false}
                    format="h:mma"
                    placeholder="Time"
                  />
                </div>
              )}

              <div
                className="flex flex-row gap-1 items-center justify-end"
                style={{ width: "32%" }}
              >
                <Checkbox
                  checked={allDayData.isAllDay}
                  onChange={allDayData.toggleAllDay}
                  size="5"
                />

                <p className="text-sm text-gray-450">All Day</p>
              </div>
            </div>
          </div>

          {/* End date */}
          <div className={`${modalRow(true)} flex w-1/2`}>
            <div className={labelWidth}>
              <p className={labelCN}>End</p>
            </div>

            <div className="flex flex-row w-3/4 gap-1">
              <DatePicker
                onChange={(value) => handleFormChange("endDate", value)}
                className="bg-input w-1/2"
                value={convertTZ(endDate, formState.timezone)}
                validation={yup.string().required()}
                dateFormat="MMM dd, yyyy"
                placeholder="End"
              />

              {!allDayData.isAllDay && (
                <div className="w-1/3">
                  <TimePicker
                    className="bg-input"
                    customPadding="pr-0"
                    isLast
                    value={convertTZ(endDate, formState.timezone)}
                    validation={yup.string().required()}
                    timeIntervals={5}
                    onChange={(value) => handleFormChange("endTime", value)}
                    disabled={allDayData.isAllDay}
                    manualEntry={false}
                    format="h:mma"
                    placeholder="Time"
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/** Row: Only Description for new Event */}
        {isNewEvent && (
          <div className="flex flex-row gap-6 w-1/2">
            <div
              className={`${modalRow(true)} flex w-full pr-3 ${
                isNewEvent && eventHasTags ? "items-stretch" : ""
              }`}
            >
              <div className={labelWidth}>
                <p className={labelCN}>Description</p>
              </div>

              <div className={inputWidth}>
                <Input
                  className="bg-inputBgGreen rounded"
                  inputClassName="bg-inputBgGreen w-full p-2"
                  placeholder="Description"
                  value={formState.description}
                  onChange={(value) => handleFormChange("description", value)}
                />
              </div>
            </div>
          </div>
        )}

        {/** Row: Description and Created By for an existing Event */}
        {!isNewEvent && (
          <div className={row}>
            {/* Description */}
            <div className={`${modalRow(true)} flex w-1/2`}>
              <div className={labelWidth}>
                <p className={labelCN}>Description</p>
              </div>

              <div className={inputWidth}>
                <Input
                  className="bg-inputBgGreen rounded"
                  inputClassName="bg-inputBgGreen w-full p-2"
                  placeholder="Description"
                  value={formState.description}
                  onChange={(value) => handleFormChange("description", value)}
                />
              </div>
            </div>

            <div className={`${modalRow(true)} flex w-1/2`}>
              <div className={labelWidth}>
                <p className={labelCN}>Created By</p>
              </div>

              <div className={inputWidth}>
                <Input
                  placeholder="Name"
                  className="bg-inputBgGreen rounded"
                  inputClassName="bg-inputBgGreen w-full p-2"
                  value={getUserFirstAndLast(createdBy)}
                  disabled
                  onChange={() => {}}
                />
              </div>
            </div>
          </div>
        )}

        {/* Row: Repeat and Tags: Show tags in the same row when is an existing Event */}
        <div className={row}>
          {/* Repeat */}
          <div
            className={`${modalRow(false)} flex w-1/2 ${
              eventHasTags || recurrenceString ? "items-stretch" : ""
            }`}
          >
            <div className={labelWidth}>
              <p className={labelCN}>Repeat</p>
            </div>
            <div className={inputWidth}>
              <Dropdown
                containerRef={recurrenceDropDownRef}
                tabIndex={0}
                name="recurrence"
                placeholder="Select"
                className="w-full"
                options={recurrenceOptions}
                value={getRecurrenceSelectValue(formState.recurrence)}
                onChange={(val) => handleFormChange("select recurrence", val)}
                onRequestDropdownClear={() =>
                  handleFormChange("select recurrence", {
                    label: "",
                    value: "",
                  })
                }
                disableSort
              />

              {!!recurrenceString && (
                <span className={recurrenceStringStyle(allDayData.allDay)}>
                  <p
                    className={`text-gray-450 text-sm ${
                      isNewEvent ? "mt-2" : ""
                    }`}
                  >
                    {recurrenceString}
                  </p>
                </span>
              )}
            </div>
          </div>

          {/* Tags Section */}
          <div className={tagsSectionClassName}>
            <div className={labelWidth}>
              <p className={labelCN}>Tags</p>
            </div>
            <div className={inputWidth}>
              <TagsContainer
                isEditing
                menuPlacement="bottom"
                className="h-full flex flex-row flex-wrap"
                dropdownClassName="self-center"
                fullWidth
                tagsWrapperClassName="pt-4 flex flex-wrap"
                dispatch={handleTagsDispatch}
                resource={formState}
                placeholder="Add Tags"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-4">
        <div className="w-1/2 flex flex-col gap-3">
          <div className={widgetCN} style={widgetContainer}>
            {/* Guests */}
            <p className={labelCN}>Guests</p>

            {currentInvitees.length !== 0 && (
              <RequestGridViewDistroListCell
                distroList={currentInvitees}
                hideTile
                handleDeleteFromDistro={(val) => handleRemoveInvitee(val)}
                className="col-span-4"
              />
            )}

            {attendeePopupOpen && (
              <DistroListSelectMembers
                members={invitesForDropdown}
                setDistroSelectOpen={setAttendeePopupOpen}
                selectedMembers={currentInvitees}
                onAdd={(val) => handleAddInvitee(val)}
                onRemove={(val) => handleRemoveInvitee(val)}
                title="Guests"
                showMemberPill
                placeHolder="Search Guests"
              />
            )}

            {!currentInvitees.length && (
              <p className="text-gray-200 text-sm italic">No one invited</p>
            )}

            <PlusCircleButton
              title="Add Guest"
              className="col-span-2 pt-4"
              disabled={!formState?.association}
              onClick={() => setAttendeePopupOpen(true)}
              style={{ color: PRIMARY_DARK_GREEN }}
            />

            {formState?.association && <div className={divider} />}

            {locationsAvailable && (
              <AddSpacesAssetsWidget
                form={formState}
                handleSelect={handleSelectSpaces}
                handleRemove={handleRemoveSpaces}
                resource="spaces"
                isEditing
                isModal
                noTitle={!formState?.spaces?.length}
              />
            )}
          </div>

          {/* Links */}
          <div className={widgetCN} style={widgetContainer}>
            <LinksModalWidget
              editing
              resource={formState}
              loading={false}
              handleAddLink={handleAddLink}
              handleRemoveLink={handleRemoveLink}
            />
          </div>
        </div>

        <div className="w-1/2 flex flex-col gap-3">
          {/** Media  */}
          <div className={widgetCN} style={widgetContainer}>
            <p className={labelCN}>Media</p>

            <ModalMediaWidget
              noTitle
              requestForm={removeFormFileDuplicates()}
              isEditing
              filesState={filesState}
              handleFilesToAdd={handleFilesToAdd}
              handleFilesToRemove={handleFilesToRemove}
            />
          </div>

          {/** Attachments  */}
          <div className={widgetCN} style={widgetContainer}>
            <p className={`${labelCN} pb-4`}>Attachments</p>

            <Attachments
              noTitle
              isEditing
              requestForm={removeFormFileDuplicates()}
              filesState={filesState}
              handleFilesToAdd={handleFilesToAdd}
              handleFilesToRemove={handleFilesToRemove}
              documentTypeFilter="Documents"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEditEventForm;

import React from "react";
import PropTypes from "prop-types";
import RadioButton from "../../Buttons/RadioButton";
import PtoGeneralSection from "../PtoGeneralSection";
import usePtoSectionData from "./usePtoSectionData";
import {
  TS_PERIOD_LOCKED_MSG,
  TS_PERIOD_UNLOCKED_MSG,
} from "../../../../constants";
import exclamationGreen from "../../../assets/images/exclamationGreen.svg";
import PtoLocationsSection from "../PtoLocationsSection";
import notiAlertIcon from "../../../assets/images/notiAlertIcon.svg";

const PtoSection = ({ systemSettings, dispatchSystemSettings, setIsValid }) => {
  const { onChangePtoCategory, isLocked } = usePtoSectionData({
    dispatchSystemSettings,
  });

  return (
    <div className="flex flex-col py-4">
      <div className="flex mb-4">
        <img
          className="mr-2"
          src={isLocked ? notiAlertIcon : exclamationGreen}
          alt="exclamation"
        />
        <h1 className={isLocked ? "text-es-red" : "text-es-green"}>
          {isLocked ? TS_PERIOD_LOCKED_MSG : TS_PERIOD_UNLOCKED_MSG}{" "}
        </h1>
      </div>

      <div className="flex flex-row">
        <RadioButton
          disabled={isLocked}
          label="General"
          isChecked={systemSettings?.ptoCategory === "general"}
          onChange={() => onChangePtoCategory("general")}
          className="mb-3"
          labelClassName="mb-3"
        />

        <RadioButton
          disabled={isLocked}
          label="By Location"
          isChecked={systemSettings?.ptoCategory === "location"}
          onChange={() => onChangePtoCategory("location")}
          className="mb-4"
          labelClassName="mb-4"
        />
      </div>

      {systemSettings?.ptoCategory === "general" && (
        <PtoGeneralSection
          systemSettings={systemSettings}
          dispatchSystemSettings={dispatchSystemSettings}
          setIsValid={setIsValid}
        />
      )}
      <div className="mt-4">
        {systemSettings?.ptoCategory === "location" && (
          <PtoLocationsSection
            systemSettings={systemSettings}
            dispatchSystemSettings={dispatchSystemSettings}
            setIsValid={setIsValid}
          />
        )}
      </div>
    </div>
  );
};

PtoSection.propTypes = {
  systemSettings: PropTypes.shape({
    ptoGeneralTypes: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        accrualType: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
        availability: PropTypes.shape({
          waitPeriod: PropTypes.string,
          date: PropTypes.string,
        }),
        selected: PropTypes.bool,
        numHours: PropTypes.number,
        maxRollover: PropTypes.number,
      })
    ),
    ptoLocations: PropTypes.arrayOf(
      PropTypes.shape({
        location: PropTypes.string,
        types: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            accrualType: PropTypes.shape({
              label: PropTypes.string,
              value: PropTypes.string,
            }),
            availability: PropTypes.shape({
              waitPeriod: PropTypes.string,
              date: PropTypes.string,
            }),
            selected: PropTypes.bool,
            numHours: PropTypes.number,
            maxRollover: PropTypes.number,
          })
        ),
      })
    ),
    ptoCategory: PropTypes.string,
  }),
  dispatchSystemSettings: PropTypes.func,
  setIsValid: PropTypes.func,
};

PtoSection.defaultProps = {
  systemSettings: undefined,
  dispatchSystemSettings: undefined,
  setIsValid: undefined,
};

export default PtoSection;

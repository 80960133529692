import { getUserFirstAndLast } from "../Utilities";

/**
 * Retrieves member details from a list of member references.
 */
const getMembers = (members = [], userDict, full) => {
  return members.reduce((list, member) => {
    const user = userDict?.[member?.user];
    if (user) {
      if (full) {
        list.push(user);
      } else {
        const label = getUserFirstAndLast(user);

        list.push({
          label,
          value: user?.reference,
        });
      }
    }
    return list;
  }, []);
};

/**
 * Calculates the associated members for a given association reference
 * @returns {Array} An array of associated members.
 */
const getAssociatedMembersForTaskEvent = ({
  associationReference,
  userDict,
  propertiesDict,
  assetsDict,
  projectDict,
  full,
}) => {
  // If associationReference or userDict is missing, return an empty array
  if (!associationReference && !userDict) return [];

  let associationObject = {};

  // If `associationReference` is an Asset, we need to get the parent association (Project || Property)
  if (associationReference.startsWith("Asset")) {
    const asset = assetsDict[associationReference];
    const parentAssociationRef = asset?.property || asset?.project;
    associationObject =
      projectDict?.[parentAssociationRef] ||
      propertiesDict?.[parentAssociationRef];
  } else {
    associationObject =
      projectDict?.[associationReference] ||
      propertiesDict?.[associationReference];
  }

  // Retrieve members and contacts from the association object
  const membersAndContacts = [
    ...(associationObject?.members || []),
    ...(associationObject?.vendors || []),
  ];

  // Edge case: A member could also be a Contact, so to avoid duplicated members, they are placed into a Set, to ensure not dups.
  const usersSet = new Set(membersAndContacts.map((obj) => obj.user));

  // Convert back to an array of users object
  const usersArray = Array.from(usersSet).map((user) => ({ user }));

  return getMembers(usersArray, userDict, full);
};

export default getAssociatedMembersForTaskEvent;

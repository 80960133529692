import { ReportAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { reportsKeys } from "../config/reactQuery/queryKeyFactory";
import { toastMessage, toastError } from "../stories/Components/Toast/Toast";

/**
 * Delete single or multiple reports
 * @param {string[]} ids report(s) id(s) to remove
 */
const deleteReports = async (ids) => {
  if (!ids) return null;

  return ReportAPI.postWOP("$bulkdelete", { reports: ids });
};

/**
 * Mutation hook to remove single/multiple reports
 * @param {string} mutationKey (?) mutation key to track api call
 */
const useReportDelete = (mutationKey) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey,
    mutationFn: deleteReports,
    onMutate: async (ids) => {
      await queryClient.cancelQueries(reportsKeys.reports);

      // Save reference of all query data that matches the query key ["reports"]
      // This is going to be use in case of a possible error.
      const previousAllRequest = queryClient.getQueriesData(
        reportsKeys.reports
      );

      const idsToRemove = new Set(ids);

      if (previousAllRequest?.length) {
        /**
         * Remove report(s) from all posible queries
         */
        queryClient.setQueriesData(reportsKeys.reports, (current) => {
          if (Array.isArray(current)) {
            return current.filter((report) => !idsToRemove.has(report.id));
          }

          if (typeof current === "object" && idsToRemove.has(current.id)) {
            return undefined;
          }

          return current;
        });
      }

      // Returning context in case of any error
      return { previousAllRequest };
    },
    onSuccess: (_, ids) => {
      toastMessage(
        `${ids.length > 1 ? "Reports were" : "Report was"} deleted successfully`
      );
    },
    onError: (error, ids, context) => {
      const { previousAllRequest } = context ?? {};

      // Rollback report to previous state
      previousAllRequest.forEach(([key, value]) => {
        queryClient.setQueryData(key, value);
      });

      toastError(
        `${
          ids.length > 1 ? "Reports" : "Report"
        } could not be deleted. Please try again`
      );
      console.error("useReportDelete", error);
    },
    onSettled: () => {
      // Invalidate matching queries
      queryClient.invalidateQueries(reportsKeys.reports);
    },
  });
};

export default useReportDelete;

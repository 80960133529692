import { useState } from "react";
import { useESChatContext } from "../../../context/ChatContext/ESChatContext";

const WINDOW_WIDTH_CHANNEL_LIST_EXPANDED = 800;

const useMessagingTrayData = () => {
  const {
    newMsg,
    setNewMsg,
    newChnl,
    setNewChannel,
    selectedChannel,
    clientChat,
    setViewMedia,
  } = useESChatContext();

  const [expanded, setExpanded] = useState({
    value: false,
    width: undefined,
    toggle: () =>
      setExpanded((prev) => ({
        ...prev,
        value: !prev.value,
        width: !prev.value ? WINDOW_WIDTH_CHANNEL_LIST_EXPANDED : undefined,
      })),
  });

  return {
    expanded,
    newMsg,
    setNewMsg,
    newChnl,
    setNewChannel,
    selectedChannel,
    clientChat,
    setViewMedia,
  };
};

export default useMessagingTrayData;

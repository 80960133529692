import React, { useState } from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import useSpaceReducer from "../../../hooks/useSpaceReducer";
import LocationSelectModal from "../Locations/LocationSelectModal";
import ResourceCard from "../ResourceCard/ResourceCard";
import PlusCircleButton from "../Buttons/PlusCircleButton/PlusCircleButton";
import deleteGreenIcon from "../../assets/images/deleteGreenIcon.svg";
import IconButton from "../Buttons/IconButton";
import Modal from "../Modal/Modal";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useQueryNavigation from "../../../hooks/navigation/useQueryNavigation";
import { propertyKeys } from "../../../hooks/properties";
import { getSingleResourcePath } from "../../../helpers/Navigation";

const LocationSpace = ({
  space,
  spaces,
  handleAddSpace,
  setIsEditing,
  onClickSpaceView,
  editing,
  parent,
  removeSpace,
  resourceDetails,
  levelType,
}) => {
  const history = useHistory();
  const navigation = useQueryNavigation();

  const [, dispatchSpace] = useSpaceReducer();
  const { data: currentUser } = useCurrentUser();
  const [modalOpen, setModalOpen] = useState(false);
  const [showDeleteSpace, setShowDeleteSpace] = useState(false);
  const [confirmDeleteSpace, setConfirmDeleteSpace] = useState(false);

  const handleModalOpen = () => {
    if (modalOpen) {
      setModalOpen(false);
      dispatchSpace({ type: "reset" });
    } else {
      setModalOpen(true);
    }
  };

  const handleChangeSpace = (val) => {
    handleAddSpace(val);
    setIsEditing(false);
  };

  const handleDeleteSpace = (val) => {
    removeSpace(val);
  };

  const handleMouseEnter = () => setShowDeleteSpace(true);
  const handleMouseLeave = () => setShowDeleteSpace(false);

  const handleParentClick = () => {
    if (parent?.resource === "Property")
      navigation.push({
        location: getSingleResourcePath(`Property/${parent?.id}`),
        queryKey: propertyKeys.byId(parent?.id),
      });
    else {
      history.push(getSingleResourcePath(`Project/${parent?.id}`));
    }
  };

  return (
    <div className="flex flex-col">
      {!space && (
        <ResourceCard
          className="border-none"
          id={parent?.id}
          title={parent?.name}
          detail={resourceDetails}
          imageReference={parent?.primaryImage}
          resourceName={parent?.resource}
          {...parent}
          onClick={handleParentClick}
          // disable link click parent is undefined or user does not have can_read permission
          disabled={
            !parent || parent?.resource === "Property"
              ? !currentUser?.permissions?.property?.can_read
              : !currentUser?.permissions?.project?.can_read
          }
        />
      )}

      {space && (
        <div
          className="flex relative"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <ResourceCard
            title={space?.name}
            key={space?.id}
            imageReference={space?.primaryImage}
            resourceName="Space"
            className="border-none"
            detail={{
              label: "Level",
              value: levelType?.label,
            }}
            onClick={() => onClickSpaceView(space?.parent, space?.id)}
            isAssetSpace
          />
          <IconButton
            className={`z-50 absolute top-2 right-2 ${
              !showDeleteSpace && "hidden"
            }`}
            icon={deleteGreenIcon}
            onClick={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
              setConfirmDeleteSpace(true);
            }}
          />
        </div>
      )}
      {editing && (
        <div className={`flex justify-start ${editing && "border-t mt-4"}`}>
          {spaces?.length > 0 ? (
            <PlusCircleButton
              title="Select Space"
              onClick={() => setModalOpen(true)}
              className="flex w-full items-center h-16"
              style={{ color: "#027D61", fontSize: 16 }}
              noHover
            />
          ) : (
            <p className="mt-2">{`This ${parent?.resource?.toLowerCase()} has no spaces available`}</p>
          )}
        </div>
      )}

      <LocationSelectModal
        hideMedia
        isOpen={modalOpen}
        title="Select space"
        handleModalOpen={handleModalOpen}
        handleSave={handleChangeSpace}
        spaces={spaces}
        space={space}
      />

      <Modal
        title="Remove space"
        onRequestModalClose={() => setConfirmDeleteSpace(false)}
        primaryButtonOnClick={() => handleDeleteSpace(space)}
        isOpen={confirmDeleteSpace}
        primaryButtonTitle="Remove"
        tertiaryButtonTitle="Cancel"
        hideFooter
        alert
      >
        <p className="mb-4">Are you sure you want to remove this space?</p>
      </Modal>
    </div>
  );
};

LocationSpace.propTypes = {
  space: PropTypes.shape({
    parent: PropTypes.string,
    level: PropTypes.string,
    name: PropTypes.string,
    primaryImage: PropTypes.string,
    id: PropTypes.string,
  }),
  spaces: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  handleAddSpace: PropTypes.func,
  location: PropTypes.shape({ spaces: PropTypes.shape({}) }),
  setIsEditing: PropTypes.func,
  levelType: PropTypes.shape({
    label: PropTypes.string,
  }),
  spaceType: PropTypes.shape({
    label: PropTypes.string,
  }),
  editing: PropTypes.bool,
  onClickSpaceView: PropTypes.func,
  parent: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    resource: PropTypes.string,
    primaryImage: PropTypes.string,
  }),
  removeSpace: PropTypes.func,
  resourceDetails: PropTypes.shape({}),
};

LocationSpace.defaultProps = {
  space: undefined,
  spaces: undefined,
  handleAddSpace: undefined,
  location: undefined,
  setIsEditing: undefined,
  levelType: undefined,
  spaceType: undefined,
  editing: false,
  onClickSpaceView: () => {},
  parent: undefined,
  removeSpace: undefined,
  resourceDetails: undefined,
};

export default LocationSpace;

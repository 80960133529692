import React, { useState } from "react";
import PropTypes from "prop-types";
import editDarkenedGreenIcon from "../../assets/images/editDarkenedGreenIcon.svg";
import settingsDarkenedGreenIcon from "../../assets/images/settingsDarkenedGreenIcon.svg";
import settingsDisabledIcon from "../../assets/images/settingsDisabledIcon.svg";
import downloadDarkenedGreenIcon from "../../assets/images/downloadDarkenedGreenIcon.svg";
import trashDarkenedGreenIcon from "../../assets/images/trashDarkenedGreenIcon.svg";
import trashDisabledIcon from "../../assets/images/trashDisabledIcon.svg";
import downloadDisabledIcon from "../../assets/images/downloadDisabledIcon.svg";
import activityLogIcon from "../../assets/images/activityLogIcon.svg";
import infoDarkenedGreenIcon from "../../assets/images/infoDarkenedGreenIcon.svg";
import IconButton from "../Buttons/IconButton";
import useEditModal from "../../../hooks/useEditModal";
import IconDropdownButton from "../Buttons/IconDropdownButton/IconDropdownButton";
import RecentFiles from "../FilesTable/RecentFiles";
import recentFileIcon from "../../assets/images/recentFile.svg";
import uploadFromComputerIcon from "../../assets/images/uploadFromComputer.svg";
import PrimaryButton from "../Buttons/PrimaryButton";
import CommentButton from "../CommentButton";
import TertiaryButton from "../Buttons/TertiaryButton";

const TableActionsIconsGroup = ({
  file,
  canEdit,
  canDelete,
  canDownload,
  canUpload,
  showColumnSettingsRight,
  handleShowDelete,
  handleEdit,
  handleSave,
  handleCancel,
  isEditing,
  uploadFiles,
  downloadFiles,
  disabled,
  selectedRows,
  showInfoIcon,
  style,
  handleFileClone,
  onScrollCallback,
  disableRecentFiles,
  disabledColumnSettings,
  isSaveDisabled,
  wrapperClassName,
  onOpenComments,
  absoluteBtnPosition,
  cancelClassName,
  saveClassName,
  commentClassName,
  showActivityIcon,
  onActivityClicked,
}) => {
  const [, setIsEditModalOpen] = useEditModal();
  const [showRecentFilesModal, setShowRecentFileModal] = useState();

  const uploadOptions = [
    ...(!disableRecentFiles
      ? [
          {
            title: "Recent files",
            onClick: () => setShowRecentFileModal((prev) => !prev),
            image: recentFileIcon,
          },
        ]
      : []),
    {
      title: "Upload from computer",
      onClick: uploadFiles,
      image: uploadFromComputerIcon,
    },
  ];

  return (
    <>
      <div
        className={`flex justify-end pt-3 relative items-center ${wrapperClassName}`}
        style={style ?? { height: "92px" }}
      >
        {onOpenComments && (
          <CommentButton
            onClick={onOpenComments}
            commentClassName={commentClassName}
          />
        )}
        {canEdit && !isEditing && (
          <IconButton
            wrapperClassName={`mr-7 ${
              selectedRows?.length === 1 && "icon-transition"
            }`}
            imgClassName="w-4 h-5"
            iconClassName="w-4 h-5"
            onClick={handleEdit}
            icon={editDarkenedGreenIcon}
          />
        )}

        {isEditing && (
          <>
            <div
              className={`${
                absoluteBtnPosition ? "absolute top-1.5 -left-40 mr-7" : "mr-3"
              }`}
            >
              <TertiaryButton
                onClick={handleCancel}
                className={`font-semibold text-sm text-darkenedGreen px-2 py-0 h-8 rounded-lg ${cancelClassName}`}
                title="Cancel"
                onKeyUp={handleCancel}
                name="cancelButton"
                tableIconsCancelButton
              />
            </div>
            <div
              className={`mr-7 ${
                absoluteBtnPosition ? "absolute top-1.5 -left-20" : ""
              }`}
            >
              <PrimaryButton
                className={saveClassName}
                onClick={handleSave}
                background="primaryGreen"
                saveButtonHeight="h-8"
                saveButtonFontSize="text-sm"
                saveButton
                saveButtonTitle="Save"
                onKeyUp={handleSave}
                name="primaryButton"
                disabled={isSaveDisabled}
              />
            </div>
          </>
        )}
        {canDownload && (
          <IconButton
            wrapperClassName={`${disabled ? "mr-7" : "icon-transition mr-7"}`}
            imgClassName="w-4 h-5"
            iconClassName="w-4 h-5"
            onClick={downloadFiles}
            icon={disabled ? downloadDisabledIcon : downloadDarkenedGreenIcon}
          />
        )}

        {canUpload && (
          <IconDropdownButton
            dropdownItems={uploadOptions}
            className="mr-7 z-50"
            imgClassName="w-4 h-5"
            iconClassName="w-4 h-5"
          />
        )}

        {showColumnSettingsRight && (
          <IconButton
            wrapperClassName="icon-transition mr-7"
            imgClassName="w-5 h-5"
            iconClassName="w-5 h-5"
            onClick={() =>
              disabledColumnSettings
                ? null
                : setIsEditModalOpen((prev) => !prev)
            }
            icon={
              disabledColumnSettings
                ? settingsDisabledIcon
                : settingsDarkenedGreenIcon
            }
          />
        )}

        {showInfoIcon && (
          <IconButton
            wrapperClassName={`mr-7 ${
              selectedRows?.length === 1 && "icon-transition"
            }`}
            imgClassName="w-5 h-5"
            iconClassName="w-5 h-5"
            onClick={() => {}}
            icon={infoDarkenedGreenIcon}
            disabled={selectedRows?.length !== 1}
          />
        )}

        {canDelete && (
          <IconButton
            wrapperClassName={`${!disabled && "icon-transition"}`}
            imgClassName="w-4 h-5"
            iconClassName="w-4 h-5"
            onClick={() => (disabled ? null : handleShowDelete(file))}
            icon={disabled ? trashDisabledIcon : trashDarkenedGreenIcon}
          />
        )}

        {showActivityIcon && (
          <IconButton
            imgClassName="w-5 h-5"
            iconClassName="w-5 h-5"
            onClick={onActivityClicked}
            icon={activityLogIcon}
          />
        )}
      </div>

      {showRecentFilesModal && (
        <RecentFiles
          hide={() => setShowRecentFileModal(false)}
          handleFileClick={handleFileClone}
          onScrollCallback={onScrollCallback}
        />
      )}
    </>
  );
};

TableActionsIconsGroup.propTypes = {
  file: PropTypes.shape({}),
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  canDownload: PropTypes.bool,
  canUpload: PropTypes.bool,
  showColumnSettingsRight: PropTypes.bool,
  handleShowDelete: PropTypes.func,
  uploadFiles: PropTypes.func,
  downloadFiles: PropTypes.func,
  disabled: PropTypes.bool,
  selectedRows: PropTypes.arrayOf(PropTypes.shape({})),
  showInfoIcon: PropTypes.bool,
  style: PropTypes.string,
  handleFileClone: PropTypes.func,
  onScrollCallback: PropTypes.func,
  disableRecentFiles: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleSave: PropTypes.func,
  disabledColumnSettings: PropTypes.bool,
  isEditing: PropTypes.bool,
  handleCancel: PropTypes.func,
  isSaveDisabled: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  onOpenComments: PropTypes.func,
  absoluteBtnPosition: PropTypes.bool,
  cancelClassName: PropTypes.string,
  saveClassName: PropTypes.string,
  commentClassName: PropTypes.string,
  showActivityIcon: PropTypes.bool,
  onActivityClicked: PropTypes.func,
};

TableActionsIconsGroup.defaultProps = {
  file: {},
  onOpenComments: false,
  canEdit: false,
  canDelete: false,
  canDownload: false,
  canUpload: false,
  showColumnSettingsRight: false,
  handleShowDelete: undefined,
  uploadFiles: undefined,
  downloadFiles: undefined,
  disabled: false,
  selectedRows: [],
  showInfoIcon: false,
  style: undefined,
  handleFileClone: undefined,
  onScrollCallback: undefined,
  disableRecentFiles: false,
  handleEdit: undefined,
  handleSave: undefined,
  disabledColumnSettings: false,
  isEditing: false,
  handleCancel: undefined,
  isSaveDisabled: false,
  wrapperClassName: "",
  absoluteBtnPosition: true,
  cancelClassName: undefined,
  saveClassName: undefined,
  commentClassName: undefined,
  showActivityIcon: false,
  onActivityClicked: () => {},
};

export default TableActionsIconsGroup;

// Framework Tools
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Components
import Pill from "../../Pill/Pill";
import PrimaryButton from "../../Buttons/PrimaryButton";
import SecondaryHeader from "../../TextHeaders/SecondaryHeader";
import { useAppState } from "../../../../state/appState";
import {
  checkRequestStatus,
  SET_SUBMITTAL_MODAL_STATUS,
} from "../../../../constants";
import { formatRequestStatus } from "../../../../helpers/Formatters";
import IconButton from "../../Buttons/IconButton";
import chatIcon from "../../../assets/images/messageGreenIcon.svg";

export default function RequestGridViewHeader({
  headerData,
  currentUser,
  handleDeleteClick,
  handleVoidClick,
  isSubmittal,
  isRFI,
  isRFP,
  canComplete,
  sidebarOpen,
  setSidebarOpen,
  initiateById,
  /* ** Pending FinDoc/budget/Financial BE implementation ** */
  // submittalBudget,
}) {
  // properties abstracted from headerData
  const [, dispatch] = useAppState([]);
  const [canInitiate, setCanInitiate] = useState(false);

  useEffect(() => {
    if (isSubmittal) {
      /* checks every user in a step for a role */
      const hasRole = (user) => {
        return user?.type && user?.type?.trim() !== "";
      };
      /* checks that every step has a user */
      const hasUser = (step) => {
        const verifyRole = step?.users?.every(hasRole);
        return step?.users?.length > 0 && verifyRole;
      };
      /* if there are steps, checks if every step has a user and every user has a role */
      const verifySteps = (steps) => {
        let verifyUser = false;

        if (steps?.length > 0) {
          verifyUser = steps?.every(hasUser);
        }

        if (verifyUser && steps.length > 0) {
          return true;
        }
        return false;
      };

      /* checks that all fields for submittal are there to allow for initiate */
      const verifyInitiate = (data) => {
        const hasAssociation =
          data?.association && data?.association.trim() !== "";
        const hasType = data?.type && data?.type.trim() !== "";
        const hasTitle = data?.name && data?.name.trim() !== "";
        const hasCustomName = data?.name && data?.name.trim() !== "";
        const hasStartDate =
          data?.startDate?.projected && data?.startDate?.projected !== "";
        const stepsVerified = verifySteps(data?.requestWorkflow?.[0]?.steps);
        if (
          hasAssociation &&
          hasType &&
          hasTitle &&
          hasCustomName &&
          hasStartDate &&
          stepsVerified
        ) {
          return setCanInitiate(true);
        }
        return setCanInitiate(false);
      };
      verifyInitiate(headerData);
    }
  }, [isSubmittal, headerData]);

  const handleEditSubmittal = () => {
    dispatch({
      type: SET_SUBMITTAL_MODAL_STATUS,
      open: true,
      edit: true,
      submittal: headerData,
    });
  };

  const handleEditRfi = () => {
    console.warn("RFI EDIT CLICK");
  };
  const handleEditRfp = () => {
    console.warn("RRP EDIT CLICK");
  };

  const handleCompleteSubmittal = () => {
    dispatch({
      type: SET_SUBMITTAL_MODAL_STATUS,
      complete: true,
    });
  };

  /* ** Pending FinDoc/budget/Financial BE implementation ** */

  // const getDocumentsMenu = (budget) => {
  //   const documentOptions = [];

  //   if (budget?.isLockGmp) {
  //     documentOptions.push({
  //       title: "Contengency Auth.",
  //       onClick: () => {},
  //     });
  //   }

  //   if (budget?.type === "isCostPlus" || budget?.isLockGmp) {
  //     documentOptions.push({
  //       title: "Purchase Aut.",
  //       onClick: () =>
  //         history.push(
  //           GET_PROJECT_BUDGET_CREATE_DOCUMENT_PATH(
  //             headerData?.association?.split("/")[1]
  //           ),
  //           headerData?.id
  //         ),
  //     });
  //   }
  //   if (budget?.isLockFixedFirm || budget?.isLockGmp) {
  //     documentOptions.push({
  //       title: "Change Order",
  //       onClick: () =>
  //         history.push(
  //           GET_PROJECT_BUDGET_CREATE_DOCUMENT_PATH(
  //             headerData?.association?.split("/")[1]
  //           ),
  //           headerData?.id
  //         ),
  //     });
  //   }

  //   return documentOptions;
  // };
  const checkStatus = () => {
    if (
      headerData?.status === "completed-resolved" ||
      headerData?.status === "completed-open" ||
      headerData?.status === "void"
    ) {
      return false;
    }
    return true;
  };

  const handleInitiateSubmittal = () => {
    initiateById(headerData);
  };

  const submittalActions = (user) => {
    const options = [];

    if (user && user?.hasPermission("submittal", "can_create")) {
      if (canInitiate) {
        options.push({
          title: "initiate",
          onClick: () => handleInitiateSubmittal(),
        });
      }
      if (headerData?.status === "in-progress" && canComplete) {
        options.push({
          title: "Complete",
          onClick: () => handleCompleteSubmittal(),
        });
      }
      if (headerData?.status === "draft") {
        options.push({
          title: "Edit",
          onClick: () => {
            if (isSubmittal) {
              handleEditSubmittal();
            }
            if (isRFI) {
              handleEditRfi();
            }

            if (isRFP) {
              handleEditRfp();
            }
          },
        });
      }

      /* ** Disable till version 3.7 ** 
      options.push({
        title: "Flag",
        onClick: () => {},
      });
       ********************************* */
      if (checkStatus()) {
        options.push({
          title: "Void",
          onClick: () => handleVoidClick(headerData),
        });
      }
    }
    if (
      user &&
      user?.hasPermission("submittal", "can_delete") &&
      checkStatus() &&
      checkRequestStatus(headerData)
    ) {
      options.push({
        title: "Delete",
        onClick: () => handleDeleteClick(headerData),
      });
    }

    return options;
  };

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center">
        <SecondaryHeader>
          {headerData?.customName} - {headerData?.resource} {headerData?.number}
        </SecondaryHeader>
        <div className="flex ">
          <Pill
            value={formatRequestStatus(headerData)}
            className="mx-2 capitalize"
          />
          <Pill
            value={`Round ${headerData?.round}`}
            className="mx-2"
            background="bg-gray-200"
          />
          {/* This pill is commented out intentionally until further notice. */}
          {/*         {headerData?.isPrivate && (
          <Pill value="private" className="mx-2" background="bg-red-800" />
        )} */}
        </div>
      </div>
      <div className="flex justify-center items-center">
        <IconButton
          onClick={() => setSidebarOpen(!sidebarOpen)}
          icon={chatIcon}
          imgClassName="w-6 h-6 mr-8"
          iconClassName="flex items-center w-4 h-4"
        />
        <PrimaryButton
          disabled={headerData?.status === "void"}
          large
          title="Actions"
          dropdownItems={submittalActions(currentUser)}
        />
      </div>
    </div>
  );
}

// prop types
RequestGridViewHeader.propTypes = {
  handleVoidClick: PropTypes.func,
  sidebarOpen: PropTypes.bool,
  setSidebarOpen: PropTypes.func,
  requestStatus: PropTypes.shape({}),
  canComplete: PropTypes.bool,
  isSubmittal: PropTypes.bool,
  isRFI: PropTypes.bool,
  isRFP: PropTypes.bool,
  headerData: PropTypes.shape({
    name: PropTypes.string,
    round: PropTypes.number,
    status: PropTypes.string,
    isPrivate: PropTypes.bool,
    id: PropTypes.string,
    association: PropTypes.string,
    resource: PropTypes.shape({}),
    number: PropTypes.number,
    customName: PropTypes.string,
  }),
  currentUser: PropTypes.shape({ hasPermission: PropTypes.func }),
  handleDeleteClick: PropTypes.func,
  /* ** Pending FinDoc/budget/Financial BE implementation ** */
  // submittalBudget: PropTypes.shape({}),
  initiateById: PropTypes.func,
};

RequestGridViewHeader.defaultProps = {
  handleVoidClick: undefined,
  sidebarOpen: false,
  setSidebarOpen: undefined,
  requestStatus: undefined,
  headerData: {
    name: "",
    round: 1,
    status: "",
    isPrivate: false,
  },
  currentUser: { hasPermission: () => {} },
  handleDeleteClick: undefined,
  isSubmittal: false,
  isRFI: false,
  isRFP: false,
  canComplete: false,
  initiateById: undefined,
  /* ** Pending FinDoc/budget/Financial BE implementation ** */
  // submittalBudget: undefined,
};

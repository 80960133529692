import React from "react";
import PropTypes from "prop-types";
import crossBlack from "../../../../stories/assets/images/blackCrossIcon.svg";
import CrossButton from "../../../../stories/Components/CrossButton/CrossButton";
import Input from "../../../../stories/Components/Input/Input";
import PrimaryButton from "../../../../stories/Components/Buttons/PrimaryButton";
import useChannelCreateData from "./useChannelCreateData";
import DistroListSelectMembers from "../../../../stories/Components/DistroList/DistroListSelectMembers";
import RequestGridViewDistroListCell from "../../../../stories/Components/Requests/RequestGridView/RequestGridViewDistroListCell";

const AddChannelDialog = ({ setOpen }) => {
  const {
    channelMembers,
    onChangeMembers,
    selected,
    onRemoveMember,
    channelName,
    setChannelName,
    onSave,
  } = useChannelCreateData();

  return (
    <div
      className="absolute bg-white flex flex-col justify-between shadow-1 rounded-lg cursor-default"
      style={{
        width: "362px",
        zIndex: "99",
        left: "20px",
        top: "50px",
      }}
    >
      <div
        className="font-semibold relative flex items-center justify-between w-full px-6"
        style={{ height: "60px" }}
      >
        <span className="inline-block">New Channel</span>
        <CrossButton
          icon={crossBlack}
          className="absolute right-0 top-0 p-4"
          onClick={() => setOpen(false)}
        />
      </div>
      <div className="w-full px-6 py-4 flex flex-1 flex-col justify-between gap-3">
        <Input
          placeholder="# channel"
          inputClassName="bg-tagGreen pl-2 w-full"
          value={channelName}
          onChange={setChannelName}
        />

        <RequestGridViewDistroListCell
          distroList={selected}
          hideTile
          handleDeleteFromDistro={onRemoveMember}
          className="flex-col"
        />
        <div className="self-center">
          <DistroListSelectMembers
            members={channelMembers}
            selectedMembers={selected}
            onAdd={onChangeMembers}
            onRemove={onRemoveMember}
            relative
          />
        </div>
        <PrimaryButton
          saveButton
          className="self-start"
          onClick={() => {
            setOpen(false);
            onSave();
          }}
          disabled={!channelName || selected.length === 0}
        />
      </div>
    </div>
  );
};

AddChannelDialog.propTypes = {
  setOpen: PropTypes.func,
};

AddChannelDialog.defaultProps = {
  setOpen: () => {},
};
export default AddChannelDialog;

import { useCallback, useEffect, useState } from "react";
import { useGetFile } from "../../../../hooks/useGetFile";

/**
 * Generates a URL for accessing the contents of a local file.
 * @param {File} fileObject - The file object for which to generate the URL.
 * @returns {string} A URL that can be used to access the file's contents.
 */
const getFileUrl = (fileObject) => {
  const isFile = typeof fileObject?.name === "string";
  return isFile ? fileObject?.contentsUrl : URL.createObjectURL(fileObject);
};

const useAttachmentItemData = ({ fileRef, fileObject, isEditing }) => {
  const { data } = useGetFile(fileRef);
  const [fileUrl, setFileUrl] = useState();

  useEffect(() => {
    // Set url for local media file

    if (fileObject) {
      const url = getFileUrl(fileObject);
      setFileUrl(url);
    }

    // Set url for remote media file
    if (data) {
      setFileUrl(data?.contentsUrl);
    }
  }, [data, fileObject]);

  // Open media file in a new tab
  const handleOpenFile = useCallback(() => {
    if (!isEditing) {
      window.open(fileUrl, "_blank");
    }
  }, [fileUrl, isEditing]);

  // determine the file name based on editing or new file
  const fileName = fileObject?.name || data?.name;

  return {
    handleOpenFile,
    fileName,
  };
};

export default useAttachmentItemData;

export const countries = [
  "United States",
  "Canada",
  "Mexico",
  "United Kingdom",
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahrain",
  "Bahamas",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia, Plurinational State of",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, the Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Côte d'Ivoire",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, the Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela, Bolivarian Republic of",
  "Vietnam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const states = [
  "Other",
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "DC",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const getFullAddressFromGoogleAutocompleteResults = (results) => {
  const fullAddress = {
    street: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  };

  results[0].address_components.forEach((comp) => {
    if (comp.types.indexOf("establishment") !== -1) {
      fullAddress.street = comp.long_name;
    } else if (comp.types.indexOf("subpremise") !== -1) {
      fullAddress.street2 = comp.long_name;
    } else if (comp.types.indexOf("street_number") !== -1) {
      fullAddress.street = fullAddress?.street
        ? `${fullAddress?.street} ${comp.long_name}`
        : comp.long_name;
    } else if (comp.types.indexOf("premise") !== -1) {
      fullAddress.street = `${fullAddress?.street || ""} ${comp.long_name}`;
    } else if (comp.types.indexOf("sublocality_level_2") !== -1) {
      fullAddress.street = `${fullAddress?.street || ""} ${comp.long_name}`;
    } else if (comp.types.indexOf("route") !== -1) {
      fullAddress.street = fullAddress?.street
        ? `${fullAddress?.street} ${comp.long_name}`
        : comp.long_name;
    } else if (comp.types.indexOf("locality") !== -1) {
      fullAddress.city = comp.long_name;
    } else if (comp.types.indexOf("administrative_area_level_1") !== -1) {
      fullAddress.state = comp.long_name;
    } else if (comp.types.indexOf("country") !== -1) {
      fullAddress.country = comp.long_name;
    } else if (comp.types.indexOf("postal_code") !== -1) {
      fullAddress.zipCode = comp.long_name;
    } else if (comp.types.indexOf("sublocality_level_1") !== -1) {
      fullAddress.sublocality = comp.long_name;
    }

    if (!fullAddress.street) {
      fullAddress.street = fullAddress.sublocality;
      delete fullAddress.sublocality;
    }

    if (!fullAddress.city) {
      fullAddress.city = fullAddress.sublocality;
      delete fullAddress.sublocality;
    }
  });

  return fullAddress;
};

export const formatCountriesDropdown = (countryList) => {
  const list = [];
  Object.keys(countryList).forEach((key) => {
    if (countryList[key] === "US") {
      list.unshift(
        {
          label: "United States",
          value: "United States",
          countryCode: countryList[key],
        },
        {
          label: "USA",
          value: "USA",
          countryCode: countryList[key],
        },
        {
          label: "US",
          value: "US",
          countryCode: countryList[key],
        }
      );
    } else if (countryList[key] === "GB") {
      list.unshift({
        label: "United Kingdom",
        value: "United Kingdom",
        countryCode: countryList[key],
      });
    } else {
      list.push({
        label: key.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()),
        value: key.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()),
        countryCode: countryList[key],
      });
    }
  });
  return list;
};

export const getStatesDropdown = () => {
  const list = states?.map((state) => {
    if (state === "DC") {
      return {
        label: "District of Columbia",
        value: "District of Columbia",
      };
    }
    return {
      label: state,
      value: state,
    };
  });
  return list;
};

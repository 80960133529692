import { useMemo, useRef } from "react";
import { useSopsByIdAndVersion } from "./useSopById";

const useSopVersionForTask = ({ currentTask }) => {
  const sopsUsed = useMemo(
    () =>
      currentTask?.steps?.reduce((acc, item) => {
        if (item?.sop) {
          // eslint-disable-next-line no-param-reassign
          acc = [
            ...acc,
            {
              sopId: item?.sop.split("/")[1],
              sopVersion: item?.sopVersion,
            },
          ];
        }
        return acc;
      }, []),
    [currentTask?.steps]
  );

  const results = useSopsByIdAndVersion(sopsUsed);

  const sopDict = useRef({});

  const isReady = results.every((result) => result.isSuccess);
  if (isReady) {
    sopDict.current = results.reduce((acc, result) => {
      if (result.data) {
        acc[result.data.reference] = result.data;
      }
      return acc;
    }, {});
  }

  return { usedSopDict: sopDict.current };
};
export default useSopVersionForTask;

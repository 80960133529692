/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import { GET_CONTACT_PATH } from "../../../constants";
import { getFullName } from "../../../helpers/Formatters";
import PlusCircleButton from "../../../stories/Components/Buttons/PlusCircleButton/PlusCircleButton";
import MembersRow from "../../../stories/Components/Members/MembersRow";
import SimpleSearch from "../../../stories/Components/SearchBar/StatelessSearch";
import SelectUserInterface from "../../../stories/Components/SelectUserInterface/SelectUserInterface";
import Widget from "../../../stories/Components/Widget/Widget";
import { useUsers } from "../../../hooks/useUsers.new";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { isEmployeeOrAdmin } from "../../../helpers/Permissions";

const VendorView = ({
  resource,
  dispatch,
  isPropertyLoading,
  memberLoading,
  setMemberLoading,
  editing,
}) => {
  const history = useHistory();
  const { data, isLoading } = useUsers();
  const { data: currentUser } = useCurrentUser();
  const userDict = data?.userDict;
  const users = data?.users;
  const [widget, setWidget] = useState({
    state: "add",
    set: (key, val) => setWidget((prev) => ({ ...prev, [key]: val })),
  });
  /**
   * Memoized Data Structures
   */
  const vendors = useMemo(() => {
    const addedVendors = resource?.vendors?.map((person) => person.user);
    return users?.reduce((arr, { reference, name, kind, ...rest }) => {
      if (!addedVendors?.includes(reference)) {
        arr.push({
          ...rest,
          label: kind === "company" ? rest?.companyName : getFullName(name),
          value: reference,
        });
      }
      return arr;
    }, []);
  }, [resource?.vendors, users]);

  const currentVendors = useMemo(() => {
    return resource?.vendors?.reduce((acc, vendor) => {
      const existingVendor = userDict?.[vendor.user];
      const label =
        existingVendor?.kind === "company"
          ? existingVendor?.companyName
          : getFullName(existingVendor?.name);
      // do not include vendor that has no name
      if (label?.trim()) {
        acc.push({
          ...existingVendor,
          label,
        });
      }
      return acc;
    }, []);
  }, [resource?.vendors, userDict]);

  /**
   * Memoized Data Structures
   */

  /**
   * Search Functionality
   */

  const [searchState, setSearchState] = useState({
    vendors: null,
    value: null,
  });

  const searchRef = useRef();

  const handleSearch = useCallback((arr, value) => {
    setSearchState({ vendors: arr, value });
  }, []);

  const clearSearch = () => {
    setSearchState({
      vendors: null,
      value: null,
    });
    searchRef.current.value = null;
  };
  /**
   * Search Functionality
   */

  /**
   * Handlers
   */

  const handleRemove = useCallback(
    (_user) => {
      if (!memberLoading && !isPropertyLoading) {
        clearSearch();
        setMemberLoading(true);
        dispatch({
          type: "removeVendor",
          vendor: _user.reference,
        });
        setMemberLoading(false);
      }
    },
    [dispatch, isPropertyLoading, memberLoading, setMemberLoading]
  );

  const handleAddVendorClick = () => {
    clearSearch();
    widget.set("state", "adding");
  };

  const handleCancelAddVendor = () => {
    clearSearch();
    widget.set("state", "add");
  };

  // create ref to hold timer for handleAdd function
  const handleAddRef = useRef(null);

  useEffect(() => {
    return () => {
      // clear timer on unmount
      if (handleAddRef.current) clearTimeout(handleAddRef.current);
    };
  }, []);

  const handleAdd = (list) => {
    clearSearch();
    widget.set("state", "add");
    const [newVendor] = list;
    dispatch({
      type: "addVendor",
      vendor: newVendor,
    });
    handleAddRef.current = setTimeout(() => setMemberLoading(false), 1000);
  };

  const handleNavigate = ({ id }) => {
    if (isEmployeeOrAdmin(currentUser)) {
      history.push(GET_CONTACT_PATH(id, "0"));
    }
  };

  /**
   * Handlers
   */
  return (
    <Widget
      title="Contacts"
      showCountInBrackets
      count={searchState?.vendors?.length ?? currentVendors?.length}
      draggable={false}
      className="self-start"
      width="1/3"
      childClassName="flex flex-col justify-center"
      overflow
      loading={isLoading || isPropertyLoading}
    >
      <div
        className={`${
          (memberLoading || isPropertyLoading || !users?.length) && "loading"
        } w-full flex flex-col gap-4 flex-1`}
      >
        <SimpleSearch
          options={currentVendors}
          keys={["name.firstName", "name.lastName", "companyName"]}
          onChange={handleSearch}
          value={searchState.value}
          forwardedRef={searchRef}
          placeholder="Search"
          disabled={currentVendors?.length === 0}
        />
        <div
          className="flex flex-row w-full overflow-x-auto"
          style={{ maxHeight: "240px", minHeight: "240px" }}
        >
          <MembersRow
            isEditing={editing}
            onClick={handleNavigate}
            handleRemove={handleRemove}
            currentMembers={searchState?.vendors ?? currentVendors}
            resource="Contacts"
            users={vendors}
          />
        </div>
        {editing && (
          <div className="border-t pt-2">
            {widget.state === "add" && (
              <PlusCircleButton
                title="Add Contact"
                onClick={handleAddVendorClick}
                className="flex w-full items-center h-16"
                style={{ color: "#027D61", fontSize: 16 }}
                noHover
              />
            )}

            {widget.state === "adding" && (
              <SelectUserInterface
                className="w-full"
                nameHeaderClassName="text-gray-450 font-normal"
                userList={[]}
                userLabel="Add Contact"
                userPlaceholder="Select"
                userOptions={vendors}
                onAddUser={(val) => handleAdd(val)}
                onCancelClick={handleCancelAddVendor}
                disableCreateUser
                isSingleSelect
              />
            )}
          </div>
        )}
      </div>
    </Widget>
  );
};

VendorView.propTypes = {
  resource: PropTypes.shape({
    vendors: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  dispatch: PropTypes.func,
  setMemberLoading: PropTypes.func,
  memberLoading: PropTypes.bool,
  isPropertyLoading: PropTypes.bool,
  editing: PropTypes.bool,
};

VendorView.defaultProps = {
  resource: {},
  dispatch: undefined,
  setMemberLoading: undefined,
  memberLoading: true,
  isPropertyLoading: false,
  editing: false,
};

export default VendorView;

import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { getTagOptions, sanitizeLabel } from "../../../helpers/Tag";
import { useCreateTag, useGetTags } from "../../../hooks/useTags";
import { useAppState } from "../../../state/appState";
import SearchLogo from "../../assets/images/magnify_glass_icon.svg";
import Dropdown from "../Dropdown/Dropdown";
import TagList from "./TagList";

const TagsContainer = ({
  isEditing,
  className,
  tagsWrapperClassName,
  dropdownClassName,
  menuPlacement,
  fullWidth,
  dispatch,
  resource,
  isSop,
  placeholder,
}) => {
  const [{ currentUser }] = useAppState();
  const { data: tagsData } = useGetTags();
  const createTag = useCreateTag();
  const [options, setOptions] = useState([]);

  const currentTags = useMemo(
    () => resource?.currentTags || resource?.tags || [],
    [resource?.currentTags, resource?.tags]
  );

  /**
   * When a new tag is created tagsData?.tagsDict gets updated, causing a re-render
   * At this point `resource?.originalResource` is not been updated with the new tag because the user hasn't clicked on the Save button.
   * When this re-renders `resource?.originalResource` will contain the existing tags in the resource and not the new ones.
   * Solution: Remove tagsData?.tagsDict from dependency array and prefetch Tags on MainContainer
   */
  useEffect(() => {
    // check if info is available for dispatch (was causing error on navigation)
    if (resource?.originalResource && tagsData?.tagsDict) {
      dispatch({
        type: "setCurrentTags",
        currentTags: getTagOptions(
          resource?.originalResource,
          tagsData?.tagsDict
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, resource?.originalResource]);

  useEffect(() => {
    if (isEditing) {
      setOptions(
        tagsData?.tags?.reduce((acc, tag) => {
          const found = currentTags.find(
            (current) => current.value === tag.reference
          );

          if (!found) {
            acc.push({
              label: tag.label,
              value: tag.reference,
            });
          }

          return acc;
        }, [])
      );
    }
  }, [tagsData?.tags, isEditing, currentTags]);

  const onAdd = async (option) => {
    const existingTag = tagsData?.tags?.find(
      (opt) => opt?.label === sanitizeLabel(option?.label)
    );

    // eslint-disable-next-line no-underscore-dangle
    if (option?.__isNew__) {
      if (!existingTag) {
        try {
          // creeate tag and add here
          const data = await createTag.mutateAsync({
            label: sanitizeLabel(option?.label),
          });

          dispatch({
            type: "setCurrentTags",
            currentTags: [
              ...currentTags,
              {
                label: data?.label,
                value: data?.reference,
              },
            ],
          });
        } catch (e) {
          console.error("Error creating tag", e);
        }
      } else {
        // tag exists, but check also check if already selected
        const isSelected = resource?.currentTags?.find(
          (current) => current.value === existingTag.reference
        );

        if (!isSelected) {
          dispatch({
            type: "setCurrentTags",
            currentTags: [
              ...currentTags,
              {
                label: existingTag?.label,
                value: existingTag?.reference,
              },
            ],
          });
        }
      }
    } else {
      dispatch({
        type: "setCurrentTags",
        currentTags: [...currentTags, option],
      });
    }
  };

  const onRemove = (removedTag) => {
    dispatch({
      type: "setCurrentTags",
      currentTags: currentTags.filter((tag) => tag.value !== removedTag.value),
    });
  };

  return (
    <div className={`flex flex-row ${className}`}>
      {isEditing && (
        <div
          className={`flex ${fullWidth && "min-w-full"}`}
          style={{ width: "208px" }}
        >
          <Dropdown
            menuPlacement={menuPlacement}
            options={options}
            onChange={onAdd}
            className={
              dropdownClassName ?? ` w-56 pr-4 h-8 ${isSop && "mt-4 mb-1"}`
            }
            placeholder={
              <div className="flex flex-row">
                <img src={SearchLogo} alt="dd" />
                <p className="pl-4" style={{ minWidth: "120px" }}>
                  {placeholder ?? "Add tags..."}
                </p>
              </div>
            }
            isCreatable={currentUser?.permissions?.tag?.can_write}
            hideDropdownIndicator
            hideIndicator
          />
        </div>
      )}
      <TagList
        tags={currentTags}
        isEditing={isEditing}
        handleDeleteTag={onRemove}
        className={`flex-wrap gap-4 mb-4 ${tagsWrapperClassName}`}
      />
    </div>
  );
};

TagsContainer.propTypes = {
  isEditing: PropTypes.bool,
  fullWidth: PropTypes.bool,
  dispatch: PropTypes.func,
  resource: PropTypes.shape({
    tags: PropTypes.arrayOf(PropTypes.string),
    originalResource: PropTypes.shape({
      tags: PropTypes.arrayOf(PropTypes.string),
    }),
    currentTags: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  tagsWrapperClassName: PropTypes.string,
  isSop: PropTypes.bool,
  menuPlacement: PropTypes.string,
  placeholder: PropTypes.string,
};

TagsContainer.defaultProps = {
  menuPlacement: "bottom",
  resource: undefined,
  fullWidth: undefined,
  isEditing: false,
  dispatch: () => {},
  className: undefined,
  dropdownClassName: undefined,
  tagsWrapperClassName: undefined,
  isSop: false,
  placeholder: undefined,
};
export default TagsContainer;

import PropTypes from "prop-types";
import React from "react";
import Checkbox from "../../../../Checkbox/Checkbox";
import editIcon from "../../../../../assets/images/editpencilgreen.svg";
import { hasWritePermission } from "../../../../../../helpers/Permissions";
import { CONTACTS } from "../../../../../../constants";
import useOwnedByListContactItem from "./useOwnedByListContactItemData";

const OwnedByListContactItem = ({
  user,
  isSelected,
  handleClick,
  setEditing,
}) => {
  const { hover, currentUser, name, handleHover, onEdit } =
    useOwnedByListContactItem({ user, setEditing });

  return (
    <div className="flex hover:bg-lightGreen">
      <button
        type="button"
        className="flex w-11/12 truncate items-center"
        style={{
          height: "56px",
        }}
        onMouseEnter={() => handleHover()}
        onMouseLeave={() => handleHover("leave")}
      >
        <div className="flex justify-between w-full">
          <div className="flex flex-row w-11/12">
            <div>
              <Checkbox
                checked={isSelected}
                onChange={() => handleClick(user)}
                size={6}
              />
            </div>
            <div className="flex pl-3 truncate">
              <p
                className="text-left font-es-normal tracking-es-normal text-es-medium-grey"
                title={name}
              >
                {name}
              </p>
            </div>
          </div>
          <div
            className={`flex ${
              (!hover || !hasWritePermission(CONTACTS, currentUser)) &&
              "invisible"
            }`}
            style={{ width: "18px" }}
          >
            <button
              type="button"
              onClick={(e) => {
                onEdit(e);
              }}
            >
              <img src={editIcon} alt="edit" />
            </button>
          </div>
        </div>
      </button>
    </div>
  );
};

export default OwnedByListContactItem;

OwnedByListContactItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    role: PropTypes.string,
    kind: PropTypes.string,
    title: PropTypes.string,
    companyName: PropTypes.string,
  }),
  isSelected: PropTypes.bool,
  handleClick: PropTypes.func,
  setEditing: PropTypes.func,
};

OwnedByListContactItem.defaultProps = {
  user: {},
  isSelected: false,
  handleClick: undefined,
  setEditing: undefined,
};

import { WorkflowAPI, FileAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { cloneDeep } from "lodash";
import moment from "moment";
import { useAppState } from "../state/appState";
import { getFullName } from "../helpers/Formatters";
import {
  commentsKeys,
  workflowKeys,
} from "../config/reactQuery/queryKeyFactory";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { isSubmittalStartDateInPast } from "../helpers/Submittal";
import useCurrentUser from "./useCurrentUser";
import { toastError, toastMessage } from "../stories/Components/Toast/Toast";
import { usePropertiesOverview } from "./properties";
import { useProjectsOverview } from "./projects";

// Helper functions to transform data
const mapFilesData = (filesData) =>
  filesData.reduce((acc, attachment) => {
    const id = attachment.reference;
    acc[id] = attachment;
    return acc;
  }, {});

const mapDistroData = (data, userDict) => {
  const list = data?.distribution?.map((ref) => {
    const userInfo = userDict[ref];
    return userInfo;
  });
  return list;
};

const mapWorkflowSteps = (steps, userDict) =>
  steps.map((step) => {
    const fullUsers = step?.users?.reduce((acc, user) => {
      const fullUser = {
        ...user,
        data: userDict[user.reference],
      };
      return [...acc, fullUser];
    }, []);
    return { ...step, users: fullUsers };
  });

const mapAssociationMembers = (resource, userDict) => {
  const membersList = resource?.members?.reduce((acc, mem) => {
    const member = userDict[mem.user];
    if (member) {
      const name = getFullName(member?.name);
      const ref = member?.reference;

      acc.push({
        avatar: member?.avatar,
        label: name,
        value: ref,
        name: member?.name,
        reference: member?.reference,
        kind: member?.kind,
      });
    }
    return acc;
  }, []);
  return membersList;
};

const formatWorkflow = async ({
  data,
  usersDict,
  projectsDict,
  propertiesDict,
}) => {
  const allFileRefs = [...data.attachments?.map((file) => file?.ref)];

  const allsteps = data.requestWorkflow[0]?.steps || [];
  allsteps.forEach((step) =>
    allFileRefs.push(...step.attachments?.map((file) => file?.ref))
  );

  data.rounds.forEach((round) =>
    round?.requestWorkflow?.[0]?.steps.forEach((step) => {
      allFileRefs.push(...step.attachments?.map((file) => file?.ref));
    })
  );

  const allFilesData = await FileAPI.get({
    params: { reference: allFileRefs.toString() },
  });
  const files = allFilesData.data.entries.map((item) => item.resource);

  const initiatedUser = usersDict[data?.initiated?.user];
  const ballInCourt =
    data.ballInCourt?.map((bic) => usersDict[bic?.user]) ?? [];

  return {
    workflow: data,
    attachmentMap: mapFilesData(files),
    association: data.association?.includes("Project")
      ? projectsDict[data.association]
      : propertiesDict[data.association],
    distroList: mapDistroData(data, usersDict),
    initiated: {
      ...initiatedUser,
      date: data?.initiated?.date,
    },
    ballInCourt,
    workflowSteps: mapWorkflowSteps(allsteps, usersDict),
    associationMembers: mapAssociationMembers(
      data.association?.includes("Project")
        ? projectsDict[data.association]
        : propertiesDict[data.association],
      usersDict
    ),
  };
};

const fetchWorkflow = async (
  workflowId,
  usersDict,
  projectsDict,
  propertiesDict,
  managementConfiguration
) => {
  const { data } = await WorkflowAPI.getById(workflowId);

  const rv = await formatWorkflow({
    data,
    usersDict,
    projectsDict,
    propertiesDict,
    managementConfiguration,
  });
  return rv;
};

const postReminder = async ({ workflowId, userId, note }) => {
  await WorkflowAPI.postByIdWOP(workflowId, "$sendreminder", {
    note: note || "",
    sentTo: [`User/${userId}`],
  });
};

/**
 * Query hook to fetch workflow by Id
 * @param {string} workflowId workflow id
 */
export const useGetWorkflowById = (workflowId) => {
  const [{ userDict }] = useAppState();

  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  return useAuthenticatedQuery({
    queryKey: workflowKeys.workflowById(workflowId),
    enabled: !!userDict && !!projectDict && !!propertiesDict,
    queryFn: () =>
      fetchWorkflow(workflowId, userDict, projectDict, propertiesDict),
    onError: (error) => {
      console.error("useWorkflows: error getting workflow", error);
    },
  });
};

/**
 * Mutation hook to send workflow reminders
 * @param {string} mutationKey allow to track mutations (optional)
 */
export const useSendWorkflowReminder = (workflowId) =>
  useMutation({
    mutationFn: (args) => postReminder({ workflowId, ...args }),
    onError: (error) => console.error("Failed to send reminder", error),
  });

const initiateById = async (
  workflowData,
  currentUser,
  usersDict,
  projectsDict,
  propertiesDict
) => {
  const oldBody = cloneDeep(workflowData);
  const newBody = {
    ...workflowData,
    status: "in-progress",
    startDate: {
      ...workflowData.startDate,
      actual: isSubmittalStartDateInPast(workflowData)
        ? moment().toISOString()
        : moment(workflowData.startDate.actual).toISOString(),
    },
    initiated: {
      ...workflowData.initiated,
      date: moment(),
      user: currentUser?.reference,
    },
  };
  const { data } = await WorkflowAPI.patch(workflowData.id, newBody, oldBody);

  const rv = formatWorkflow({
    data,
    usersDict,
    projectsDict,
    propertiesDict,
  });
  return rv;
};

export const useInitiateWorkflow = () => {
  const queryClient = useQueryClient();
  const [{ projectDict, propertiesDict, userDict }] = useAppState();
  const { data: currentUser } = useCurrentUser();

  return useMutation({
    mutationFn: (workflow) =>
      initiateById(
        workflow,
        currentUser,
        userDict,
        projectDict,
        propertiesDict
      ),
    onError: (error) => {
      console.error("Failed to initiate workflow", error);
      toastError(" Failed to initiate workflow. Try again later.");
    },
    onSuccess: () => {
      toastMessage("Successfully initiated workflow.");
      queryClient.invalidateQueries(workflowKeys.workflows);
    },
  });
};

const sendReminder = async ({ workflowId, note, userArr }) => {
  const { data: results } = await WorkflowAPI.postByIdWOP(
    workflowId,
    "$sendreminder",
    {
      note: note || "",
      sentTo: [...userArr],
    }
  );
  return results;
};

export const useSendReminderWorkflow = () => {
  const [{ userDict }] = useAppState();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: sendReminder,
    onError: (error) => {
      console.error("Failed to send reminder", error);
      toastError("Send reminder operation failed. Try again later.");
    },
    onSuccess: (results) => {
      const sentToNames = results?.reminders[
        results?.reminders?.length - 1
      ]?.sentTo
        ?.map((userRef) => getFullName(userDict?.[userRef]?.name))
        ?.join(", ");

      toastMessage(
        `Reminder(s) successfully sent to: ${sentToNames || "members"}`
      );
      queryClient.invalidateQueries(workflowKeys.workflows);
    },
  });
};

const patch = async (
  newWorkflow,
  workflow,
  usersDict,
  projectsDict,
  propertiesDict
) => {
  const { data } = await WorkflowAPI.patch(workflow.id, newWorkflow, workflow);

  const rv = formatWorkflow({
    data,
    usersDict,
    projectsDict,
    propertiesDict,
  });
  return rv;
};

export const usePatchWorkflow = () => {
  const queryClient = useQueryClient();
  const [{ projectDict, propertiesDict, userDict }] = useAppState();

  return useMutation({
    mutationFn: (params) =>
      patch(
        params.updatedResource,
        params.originalResource,
        userDict,
        projectDict,
        propertiesDict
      ),
    onError: (error) => {
      console.warn("Error updating workflow", error);
      toastError("Error updating workflow. Try again later.");
    },
    onSuccess: () => {
      queryClient.invalidateQueries(workflowKeys.workflows);
    },
  });
};

const deleteById = async (id) => {
  await WorkflowAPI.delete(id);
};

export const useDeleteWorkflow = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteById,
    onError: (error) => {
      console.warn("Error deleting workflow", error);
      toastError("Error deleting workflow. Try again later.");
    },
    onSuccess: () => {
      queryClient.invalidateQueries(workflowKeys.workflows);
    },
  });
};

const voidById = async (workflowId) => {
  const updatedWorkflow = await WorkflowAPI.postWOP(`${workflowId}/$action`, {
    action: "void",
  });

  return updatedWorkflow;
};

export const useVoidWorkflow = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: voidById,
    onError: (error) => {
      console.warn("Error voiding workflow", error);
      toastError("Error voiding workflow. Try again later.");
    },
    onSuccess: () => {
      queryClient.invalidateQueries(workflowKeys.workflows);
    },
  });
};

const submitStep = async ({
  id,
  stepId,
  comment,
  projectDict,
  propertiesDict,
  userDict,
  managementConfiguration,
}) => {
  const { data } = await WorkflowAPI.postWOP(`${id}/$action`, {
    action: "submit",
    step: stepId,
    comment,
  });

  const rv = formatWorkflow({
    data,
    usersDict: userDict,
    projectsDict: projectDict,
    propertiesDict,
    managementConfiguration,
  });
  return rv;
};

export const useSubmitWorkflowStep = () => {
  const queryClient = useQueryClient();
  const [{ projectDict, propertiesDict, userDict }] = useAppState();

  return useMutation({
    mutationFn: ({ id, stepId, comment }) =>
      submitStep({
        id,
        stepId,
        comment,
        projectDict,
        propertiesDict,
        userDict,
      }),
    onError: (error) => {
      console.warn("Error submitting step", error);
      toastError("Error submitting the step. Try again later.");
    },
    onSuccess: ({ workflow }) => {
      queryClient.invalidateQueries(workflowKeys.workflows);
      queryClient.invalidateQueries(
        commentsKeys.commentsByAssociation(workflow?.reference)
      );
    },
  });
};

const completeWorkflow = async ({
  workflowId,
  workflowImpacts,
  comment,
  workflowStatus,
  projectDict,
  propertiesDict,
  userDict,
}) => {
  const { data } = await WorkflowAPI.postWOP(`${workflowId}/$action`, {
    action: "complete",
    impacts: workflowImpacts,
    status: workflowStatus,
    comment,
  });

  const rv = formatWorkflow({
    data,
    usersDict: userDict,
    projectsDict: projectDict,
    propertiesDict,
  });
  return rv;
};

export const useCompleteWorkflow = () => {
  const queryClient = useQueryClient();
  const [{ projectDict, propertiesDict, userDict }] = useAppState();

  return useMutation({
    mutationFn: ({ workflowId, workflowImpacts, workflowStatus, comment }) =>
      completeWorkflow({
        workflowId,
        workflowImpacts,
        workflowStatus,
        comment,
        projectDict,
        propertiesDict,
        userDict,
      }),
    onError: (error) => {
      console.warn("Error completing workflow", error);
      toastError("Error completing the workflow. Try again later.");
    },
    onSuccess: ({ workflow }) => {
      toastMessage("Successfully completed workflow.");
      queryClient.invalidateQueries(workflowKeys.workflows);
      queryClient.invalidateQueries(
        commentsKeys.commentsByAssociation(workflow?.reference)
      );
    },
  });
};

const onStepAction = async ({
  id,
  stepId,
  stepAction,
  comment,
  stepStatus,
  projectDict,
  propertiesDict,
  userDict,
}) => {
  const { data } = await WorkflowAPI.postWOP(`${id}/$action`, {
    action: stepAction,
    step: stepId,
    status: stepStatus,
    comment,
  });

  const rv = formatWorkflow({
    data,
    usersDict: userDict,
    projectsDict: projectDict,
    propertiesDict,
  });
  return rv;
};

export const useActionOnWorkflowStep = () => {
  const queryClient = useQueryClient();
  const [{ projectDict, propertiesDict, userDict }] = useAppState();

  return useMutation({
    mutationFn: ({ id, stepAction, stepId, stepStatus, comment }) =>
      onStepAction({
        id,
        stepId,
        stepAction,
        stepStatus,
        comment,
        projectDict,
        propertiesDict,
        userDict,
      }),
    onError: (error) => {
      console.warn("Error completing workflow", error);
    },
    onSuccess: ({ workflow }) => {
      queryClient.invalidateQueries(workflowKeys.workflows);
      queryClient.invalidateQueries(
        commentsKeys.commentsByAssociation(workflow?.reference)
      );
    },
  });
};

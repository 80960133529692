import { useCallback, useEffect, useState } from "react";
import { ProjectAPI } from "@griffingroupglobal/eslib-api";
import useAssociationsHelper from "../../../hooks/useAssociationsHelper";
import { toastError } from "../Toast/Toast";

/**
 * Presentation hook for `ExpenseCreateModal`
 */
const useExpenseCreateFormData = ({
  modalData,
  expenseData,
  financialsConfiguration,
  editedExpense,
  setEditedExpense,
  setProjectCSICodes,
}) => {
  const { getParentAssociation } = useAssociationsHelper();

  // If `asset` means the modal was opened from an Asset Single Page under Expenses Tab. Both Association and Asset dropdown will be shown.
  // If `association` means the modal was opened from P/P. Only Association dropdown will be shown
  // If both `undefined` means the modal was opened from the left side menu. Only Association dropdown will be shown (DD will show P/P/A)
  const [lockAssociation, setLockAssociation] = useState({
    association: undefined,
    asset: undefined,
  });

  const currentAsset = expenseData?.asset || modalData?.assetLock;
  const currentAssociation =
    expenseData?.association || modalData?.associationLock;

  // It should be a pure project without an asset selected
  const isProjectAssociated =
    !lockAssociation?.asset &&
    !currentAsset &&
    !editedExpense?.asset &&
    (currentAssociation?.startsWith("Project") ||
      editedExpense?.association?.startsWith("Project"));

  useEffect(() => {
    // This will always display the Parent Association when creating from P/P/A
    if (currentAssociation) {
      setLockAssociation(() => {
        return { association: currentAssociation, asset: currentAsset };
      });
    }
  }, [modalData, currentAssociation, currentAsset]);

  useEffect(() => {
    /**
     * Fetch CSI codes if Association selected is a Project
     */
    const fetchProject = async (val) => {
      try {
        const pId = val.split("/")[1];
        const project = await ProjectAPI.getByIdWOP(pId, "$getfinancialcodes");

        if (project.data[val]?.useall) {
          // this project uses all the csi codes
          const csiCodeMappingObject =
            financialsConfiguration?.financials?.csiCodeMappingObject;
          const allCSICodes = Object.keys(csiCodeMappingObject)?.map((key) => ({
            label: `${key} - ${csiCodeMappingObject[key]}`,
            value: key,
          }));
          setProjectCSICodes(allCSICodes);
        } else {
          // this project uses csi codes retuned by the $getfinancialcodes directive
          const pCSICodes = project.data[val].codes?.map((c) => {
            const key = c.key.split("-").join(" ");
            return {
              label: `${key} - ${c.subcodeDescription}`,
              value: key,
            };
          });

          const uniqueProjectCSICodes = pCSICodes.filter(
            (elem, index) =>
              pCSICodes.findIndex((obj) => obj.value === elem.value) === index
          );
          setProjectCSICodes(uniqueProjectCSICodes);
        }
      } catch (error) {
        toastError(
          "Unable to load the project data associated to this expense, Please try again"
        );
      }
    };

    if (isProjectAssociated) {
      fetchProject(currentAssociation || editedExpense?.association);
    }
  }, [
    isProjectAssociated,
    currentAssociation,
    editedExpense?.association,
    financialsConfiguration?.financials?.csiCodeMappingObject,
    setEditedExpense,
    setProjectCSICodes,
  ]);

  /**
   * Received the association selected from the dropdown and update the `editedExpense`
   * @param {string} reference - string representing the association reference
   */
  const handleChangeAssociation = useCallback(
    (reference) => {
      if (reference?.startsWith("Asset")) {
        const parentResource = getParentAssociation(reference);
        setEditedExpense((current) => ({
          ...current,
          association: parentResource?.reference,
          asset: reference,
        }));
      } else {
        setEditedExpense((current) => ({
          ...current,
          association: reference,
          asset: undefined,
        }));
      }
    },
    [setEditedExpense, getParentAssociation]
  );

  return {
    lockAssociation,
    isProjectAssociated,
    handleChangeAssociation,
  };
};

export default useExpenseCreateFormData;

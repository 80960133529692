import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "../../stories/Components/Buttons/IconButton";
import arrowRightGray from "../../stories/assets/images/arrowRightGray.svg";
import ProjectHistorySection from "./ProjectHistorySection";

const ProjectHistoryCard = ({ data, users, defaultOpen, currentUser }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className="border-2 border-grey-100 rounded-md">
      <div className="flex border-b-2 justify-between items-center pr-4 h-20 px-4">
        <h3 className="font-bold">{data.year}</h3>
        <IconButton
          icon={arrowRightGray}
          onClick={() => setIsOpen((prev) => !prev)}
          className={isOpen && "transform rotate-90"}
        />
      </div>
      <div className="pl-1">
        <h3 className="pl-12 pt-5 pb-5 text-gray-400">Activity</h3>
      </div>
      {isOpen &&
        data.items
          .sort((a, b) => new Date(b.startDate) - new Date(a.startDate) > 0)
          .map((items) => (
            <div className="pb-3" key={items?.id}>
              <ProjectHistorySection
                users={users}
                historyData={items}
                currentUser={currentUser}
              />
            </div>
          ))}
    </div>
  );
};

ProjectHistoryCard.propTypes = {
  /**
   * maintance data
   */
  data: PropTypes.shape({
    year: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
      })
    ),
  }),
  defaultOpen: PropTypes.bool,
  currentUser: PropTypes.shape({}),
  users: PropTypes.arrayOf(PropTypes.shape({})),
};

ProjectHistoryCard.defaultProps = {
  data: {},
  defaultOpen: undefined,
  users: undefined,
  currentUser: {},
};

export default ProjectHistoryCard;

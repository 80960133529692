import React from "react";
import Dropdown from "../Dropdown/Dropdown";
import useReportManagementTemplatesDropdownData from "./useReportManagementTemplatesDropdownData";
import gearIcon from "../../assets/images/settingsIconGray.svg";

const ReportManagementTemplatesDropdown = () => {
  const { reportTemplatesOptions, handleOpenModal } =
    useReportManagementTemplatesDropdownData();

  const formatOptionLabel = (prop) => {
    const { label } = prop;

    return (
      <div className="flex flex-row justify-between w-full p-2 items-center">
        <span className="w-full">{label}</span>
        <img src={gearIcon} alt="gear" />
      </div>
    );
  };

  return (
    <div className="flex-initial w-72 max-w-sm min-w-max">
      <Dropdown
        placeholder="Manage Templates"
        options={reportTemplatesOptions}
        formatOptionLabel={formatOptionLabel}
        onChange={handleOpenModal}
        disableSort
        disableClear
      />
    </div>
  );
};

export default ReportManagementTemplatesDropdown;

import React from "react";
import BillingDashboard from "../../stories/Components/BillingManagement/BillingDashboard";

const BillingManagement = ({
  isEditingPlan,
  setIsEditingPlan,
  isEditingBillingCycle,
  setIsEditingBillingCycle,
  isEditingUserSeats,
  setIsEditingUserSeats,
  isCancellingSubscription,
  setIsCancellingSubscription,
  isExportingMemberList,
  setIsExportingMemberList,
}) => {
  return (
    <BillingDashboard
      isEditingPlan={isEditingPlan}
      setIsEditingPlan={setIsEditingPlan}
      isEditingBillingCycle={isEditingBillingCycle}
      setIsEditingBillingCycle={setIsEditingBillingCycle}
      isEditingUserSeats={isEditingUserSeats}
      setIsEditingUserSeats={setIsEditingUserSeats}
      isCancellingSubscription={isCancellingSubscription}
      setIsCancellingSubscription={setIsCancellingSubscription}
      isExportingMemberList={isExportingMemberList}
      setIsExportingMemberList={setIsExportingMemberList}
    />
  );
};

export default BillingManagement;

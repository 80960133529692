import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useSubscription from "../../../hooks/useSubscription";
import useSubscriptionPlans from "../../../hooks/useSubscriptionPlans";
import { useUsers } from "../../../hooks/useUsers.new";
import PureBillingDashboard from "./PureBillingDashboard";
import useSubscriptionFormReducer from "../../../hooks/useSubscriptionFormReducer";
import useSettings from "../../../hooks/useSettings";
import { USER_SETTINGS_KEYS } from "../../../constants";

const BillingDashboard = ({
  isEditingPlan,
  setIsEditingPlan,
  isEditingBillingCycle,
  setIsEditingBillingCycle,
  isEditingUserSeats,
  setIsEditingUserSeats,
  isCancellingSubscription,
  setIsCancellingSubscription,
  isExportingMemberList,
  setIsExportingMemberList,
}) => {
  const { data: originalSubscription } = useSubscription();
  const [plans] = useSubscriptionPlans();
  const [billingSettings, updateSettings] = useSettings(
    USER_SETTINGS_KEYS.BILLING.KEY
  );
  const { data } = useUsers();
  const users = data?.users;
  const [subscriptionDetails, dispatch] = useSubscriptionFormReducer();
  const [isEditingBilling, setIsEditingBilling] = useState(false);
  const [isEditingPayment, setIsEditingPayment] = useState(false);
  const [isMonthly, setIsMonthly] = useState(true);
  const [activeUsers, setActiveUsers] = useState([]);

  useEffect(() => {
    if (users?.length) {
      setActiveUsers(
        users.filter((user) => user.kind === "member" && user.active)
      );
    }
  }, [users]);

  useEffect(() => {
    dispatch({
      type: "reset",
      subscription: originalSubscription,
    });
    setIsMonthly(originalSubscription?.billingCycle === "monthly");
  }, [dispatch, originalSubscription]);

  if (!originalSubscription) {
    return <p className="font-semibold">Loading...</p>;
  }
  return (
    <PureBillingDashboard
      users={activeUsers}
      plans={plans}
      subscriptionDetails={subscriptionDetails}
      isEditingBilling={isEditingBilling}
      setIsEditingBilling={setIsEditingBilling}
      isEditingPayment={isEditingPayment}
      setIsEditingPayment={setIsEditingPayment}
      isEditingPlan={isEditingPlan}
      setIsEditingPlan={setIsEditingPlan}
      isEditingBillingCycle={isEditingBillingCycle}
      setIsEditingBillingCycle={setIsEditingBillingCycle}
      isEditingUserSeats={isEditingUserSeats}
      setIsEditingUserSeats={setIsEditingUserSeats}
      isCancellingSubscription={isCancellingSubscription}
      setIsCancellingSubscription={setIsCancellingSubscription}
      isExportingMemberList={isExportingMemberList}
      setIsExportingMemberList={setIsExportingMemberList}
      isMonthly={isMonthly}
      setIsMonthly={setIsMonthly}
      dispatch={dispatch}
      billingSettings={billingSettings}
      updateSettings={updateSettings}
    />
  );
};

BillingDashboard.propTypes = {
  isEditingPlan: PropTypes.bool,
  setIsEditingPlan: PropTypes.func,
  isEditingBillingCycle: PropTypes.bool,
  setIsEditingBillingCycle: PropTypes.func,
  isEditingUserSeats: PropTypes.bool,
  setIsEditingUserSeats: PropTypes.func,
  isCancellingSubscription: PropTypes.bool,
  setIsCancellingSubscription: PropTypes.func,
  isExportingMemberList: PropTypes.bool,
  setIsExportingMemberList: PropTypes.func,
};

BillingDashboard.defaultProps = {
  isEditingPlan: false,
  setIsEditingPlan: undefined,
  isEditingBillingCycle: false,
  setIsEditingBillingCycle: undefined,
  isEditingUserSeats: false,
  setIsEditingUserSeats: undefined,
  isCancellingSubscription: false,
  setIsCancellingSubscription: undefined,
  isExportingMemberList: false,
  setIsExportingMemberList: undefined,
};

export default BillingDashboard;

import { useMemo } from "react";
import formatAssetsCategoriesDropdownData from "../../../helpers/Report/formatAssetCategoriesDropdownData";
import useAssetsCategories from "../../../hooks/useAssetsCategories.new";

const useAssetCategoriesDropdownData = (value) => {
  // Get assets categories with custom format for DropdownWithPills
  const { data } = useAssetsCategories({
    select: formatAssetsCategoriesDropdownData,
  });

  const {
    categories = [],
    subcategories = [],
    categoriesNameMap = {},
  } = useMemo(
    () => ({
      categories: data?.categories,
      subcategories: data?.subCategories,
      categoriesNameMap: data?.categoriesNameMap,
    }),
    [data]
  );

  // Resolve assets categories names if `value` only provides ids
  const dropdownValue = useMemo(() => {
    if (value && Array.isArray(value) && categoriesNameMap) {
      return value.map((item) => {
        return {
          ...item,
          label: categoriesNameMap[item.id],
        };
      });
    }

    return value;
  }, [value, categoriesNameMap]);

  return {
    categories,
    subcategories,
    dropdownValue,
  };
};

export default useAssetCategoriesDropdownData;

import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import useOutsideAlerter from "../../../hooks/useOutsideAlerter";

import IconButton from "../Buttons/IconButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import EditField from "../EditField/EditField";

import { GET_CONTACT_PATH } from "../../../constants";
import editIcon from "../../assets/images/editIcon.svg";
import stopEditIcon from "../../assets/images/editIconStop.svg";
import plusIcon from "../../assets/images/plusIcon.svg";
import InfoTooltip from "../InfoTooltip/InfoTooltip";
import SelectUserInterface from "../SelectUserInterface/SelectUserInterface";

const listItemCN = cntl`
  flex
  justify-between
  items-center
`;
const cardCN = (className) => cntl`
  w-full
  mb-4  
  p-4
  border
  border-gray-200
  rounded-lg
  ${className}
`;
const titleItemCN = cntl`
  font-medium 
  flex-0 
  text-gray-200
  text-sm 
  w-60
  py-5
`;
const valueItemCN = cntl`
  h-min 
  text-gray-500 
  text-xs
  font-medium
  truncate
`;

const DetailListCard = ({
  title,
  values,
  className,
  users,
  addItemText,
  onAdd,
  onFinishEditing,
  userPlaceholderText,
  rolePlaceholderText,
  hideEditButton,
  disableDropdownSort,
  isEditing,
}) => {
  const [editing, setEditing] = useState(isEditing);
  const [edited, setEdited] = useState(false);
  const [hovering, setHovering] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    setEditing(isEditing);
  }, [isEditing]);

  const handleHover = (mouseOver) => {
    if (mouseOver) {
      setHovering(true);
    } else {
      setHovering();
    }
  };

  const handleBlur = () => {
    setEditing(false);
    if (editing && edited) {
      setEdited(false);
      onFinishEditing();
    }
  };
  useOutsideAlerter(wrapperRef, handleBlur);

  const handleAddClick = () => {
    onAdd();
    setEditing(true);
    setEdited(true);
  };

  const handleEditClick = () => {
    setEditing((prev) => {
      const newVal = !prev;
      if (!newVal && edited) {
        setEdited(false);
        onFinishEditing();
      }
      return newVal;
    });
  };

  const handleChange = (changeFunc, valueA, valueB) => {
    changeFunc(valueA, valueB);
    setEdited(true);
  };

  return (
    <div
      className={cardCN(className)}
      ref={wrapperRef}
      onMouseOver={() => handleHover(true)}
      onMouseOut={() => handleHover()}
      onFocus={() => handleHover(true)}
      onBlur={() => handleHover()}
    >
      <div className="flex items-center justify-between mb-4">
        <p className="font-semibold text-gray-500">{title}</p>
        {!hideEditButton && (
          <div className="flex items-center">
            {onAdd && (
              <div
                className={onAdd ? "" : "invisible"}
                style={{ margin: "2px 16px 0 0" }}
              >
                <TertiaryButton
                  title={addItemText ?? "Add Item"}
                  iconLeft={
                    <img
                      style={{ marginBottom: "2px" }}
                      src={plusIcon}
                      alt="plus"
                    />
                  }
                  onClick={handleAddClick}
                />
              </div>
            )}
            <div className="flex items-center">
              {!editing && (
                <IconButton
                  wrapperClassName={hovering ? "" : "hidden"}
                  imgClassName="w-6 h-6"
                  iconClassName="w-6 h-6"
                  onClick={handleEditClick}
                  icon={editIcon}
                />
              )}
              {editing && (
                <IconButton
                  wrapperClassName={hovering ? "" : "invisible"}
                  imgClassName="w-6 h-6"
                  iconClassName="w-6 h-6"
                  onClick={handleEditClick}
                  icon={stopEditIcon}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="p-4">
        {values.map(
          (
            {
              title: itemTitle,
              type,
              value,
              displayValue,
              role,
              onChange,
              options,
              editValue,
              contactLink,
              formatOptionLabel,
              infoToolTip,
              AssetFinancialsToolTipTitle,
            },
            index
          ) => {
            let height = "60px";
            let selectedUsers = [];
            if (type === "USERSELECT" && value) {
              selectedUsers =
                users?.filter((item) => value.includes(item.reference)) ?? null;
            }

            if (editing) {
              height = ["USERSELECT", "DATERANGE"].includes(type)
                ? "120px"
                : height;
            }

            return (
              <React.Fragment key={itemTitle}>
                <div className={listItemCN} style={{ height }}>
                  <p className={titleItemCN}>
                    <p className="flex">
                      <p className="pr-2 pt-0.5">{itemTitle}</p>
                      <p className="font-normal">
                        {infoToolTip && (
                          <InfoTooltip infoData={AssetFinancialsToolTipTitle} />
                        )}
                      </p>
                    </p>
                  </p>
                  {type === "USERSELECT" ? (
                    <div className="flex items-center w-1/2">
                      <div className={editing ? "w-full" : "hidden"}>
                        <SelectUserInterface
                          className="flex items-center w-full -mb-2"
                          userList={
                            selectedUsers
                              ? selectedUsers.map((usr) => {
                                  return { ...usr, value: usr.reference };
                                })
                              : []
                          }
                          userLabel={`Add ${role}`}
                          userPlaceholder={userPlaceholderText}
                          roleLabel="Select Role"
                          rolePlaceholder={rolePlaceholderText}
                          defaultRole={role}
                          buttonText="Add Contact"
                          userOptions={users}
                          onAddUser={([val]) =>
                            handleChange(onChange, val.reference)
                          }
                          onRemoveUser={() => handleChange(onChange, "")}
                          disableRemove={!editing}
                          disableCreateUser
                          isSingleSelect
                        />
                      </div>
                      <div className={editing ? "hidden" : ""}>
                        {contactLink ? (
                          <a
                            className={valueItemCN}
                            href={GET_CONTACT_PATH(
                              contactLink?.split("/")[1],
                              "0"
                            )}
                          >
                            {selectedUsers.map((selectedUser) =>
                              selectedUser?.kind !== "company"
                                ? `${selectedUser?.name?.firstName} ${selectedUser?.name?.lastName}`
                                : selectedUser?.companyName
                            )}
                          </a>
                        ) : (
                          <p className={valueItemCN}>
                            {selectedUsers.map(
                              (selectedUser) =>
                                `${selectedUser?.name?.firstName} ${selectedUser?.name?.lastName}`
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <EditField
                      className="w-full"
                      title={itemTitle}
                      type={type}
                      value={value}
                      editValue={editValue}
                      options={options}
                      onChange={(valueA, valueB) =>
                        handleChange(onChange, valueA, valueB)
                      }
                      onBlur={handleBlur}
                      isEditing={editing}
                      disableDropdownSort={disableDropdownSort}
                      formatOptionLabel={formatOptionLabel}
                    >
                      <p
                        className={valueItemCN}
                        title={displayValue ?? value}
                        aria-label={displayValue ?? value}
                      >
                        {displayValue ?? value}
                      </p>
                    </EditField>
                  )}
                </div>
                {index < values.length - 1 && <hr />}
              </React.Fragment>
            );
          }
        )}
      </div>
    </div>
  );
};

DetailListCard.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.arrayOf(PropTypes.object),
  addItemText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  users: PropTypes.arrayOf(PropTypes.object),
  onAdd: PropTypes.func,
  onFinishEditing: PropTypes.func,
  userPlaceholderText: PropTypes.string,
  rolePlaceholderText: PropTypes.string,
  hideEditButton: PropTypes.bool,
  disableDropdownSort: PropTypes.bool,
  isEditing: PropTypes.bool,
};

DetailListCard.defaultProps = {
  title: undefined,
  className: undefined,
  values: [],
  users: undefined,
  addItemText: undefined,
  onAdd: undefined,
  onFinishEditing: undefined,
  userPlaceholderText: "Search Users",
  rolePlaceholderText: "Search Roles",
  hideEditButton: false,
  disableDropdownSort: false,
  isEditing: false,
};

export default DetailListCard;

import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../stories/Components/Modal/Modal";
import useProjectDeleteModalData from "./useProjectDeleteModalData";

const ProjectDeleteModal = ({
  project,
  selectedProjects,
  showDeleteModal,
  setShowDeleteModal,
}) => {
  const { message, isDeleting, hasMultipleProjects, onDeleteClicked } =
    useProjectDeleteModalData({
      project,
      selectedProjects,
      setShowDeleteModal,
    });

  return (
    <Modal
      title={`Delete ${hasMultipleProjects ? "Projects" : "Project"}?`}
      isOpen={showDeleteModal}
      primaryButtonTitle="Yes, Delete"
      primaryButtonOnClick={onDeleteClicked}
      tertiaryButtonTitle="Cancel"
      onRequestModalClose={() => setShowDeleteModal(false)}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      hideFooter
      alert
      disabled={isDeleting}
    >
      <>
        <p className="text-base mb-2">
          Are you sure you want to delete {message}
        </p>
      </>
    </Modal>
  );
};

ProjectDeleteModal.propTypes = {
  project: PropTypes.shape({}),
  selectedProjects: PropTypes.arrayOf(PropTypes.string),
  showDeleteModal: PropTypes.bool,
  setShowDeleteModal: PropTypes.func,
};

ProjectDeleteModal.defaultProps = {
  project: null,
  showDeleteModal: false,
  selectedProjects: [],
  setShowDeleteModal: () => {},
};

export default ProjectDeleteModal;

/**
 * Checks if a user has administrative write permissions for a specific resource.
 *
 * @param {string} resource - The resource for which write permissions are being checked.
 * @param {Object} user - The user object, which should contain a 'permissions' property.
 * @returns {boolean} True if the user has write permissions for the resource, false otherwise.
 */
const hasWriteAdministrativePermission = (resource, user) => {
  const permissionResource = user?.permissions?.administrative;

  if (!permissionResource || !user) {
    return false;
  }

  let canWriteCriteria = false;

  if (resource === "property") {
    canWriteCriteria = permissionResource?.can_write_property === true;
  } else if (resource === "project") {
    canWriteCriteria = permissionResource?.can_write_project === true;
  }

  return canWriteCriteria;
};

export default hasWriteAdministrativePermission;

import calendarKeys from "../../hooks/calendar/calendarKeys";
import createReqItems from "./createRecItems";
import getReqParams from "./getReqParms";

const editCalendarStore = ({ queryClient, variables, calendarData, item }) => {
  queryClient.setQueryData(
    calendarKeys.calendarList(getReqParams(calendarData)),
    (oldData) => {
      if (variables.operation === "$non-recurring") {
        const originalItem = oldData?.find(
          (event) => item?.id === event?.resource?.id
        );

        // If Single item is edited to reoccur
        if (!originalItem?.resource?.recurrence && item?.recurrence) {
          const recurringEvents = createReqItems(
            item,
            getReqParams(calendarData).params
          );
          // remove the original task as createReqItems re-creates it
          const filteredOldData = oldData?.filter(
            (event) => event?.resource?.id !== item?.id
          );

          if (oldData === undefined) {
            return [...recurringEvents];
          }
          const updatedEntriesArray = [...filteredOldData, ...recurringEvents];

          return updatedEntriesArray;
        }
        if (oldData === undefined) {
          return [
            {
              resource: item,
            },
          ];
        }
        const updatedEntriesArray = oldData.map((event) => {
          return event.resource.id === item.id
            ? { ...event, resource: item }
            : event;
        });

        return updatedEntriesArray;
      }

      if (variables.operation === "$single") {
        const itemId = variables.args.originalItem.id;
        const itemDate = variables.args.originalItem.instanceStartDate;

        if (oldData === undefined) {
          return [
            {
              resource: item,
            },
          ];
        }

        const newEntryArray = oldData.map((event) => {
          if (
            event.resource.instanceStartDate === itemDate &&
            itemId === event.resource.id
          ) {
            return { ...event, resource: item };
          }

          return event;
        });

        return newEntryArray;
      }

      if (variables.operation === "$future" || variables.operation === "$all") {
        return oldData || [];
      }

      return oldData;
    }
  );
};
export default editCalendarStore;

import React, { useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import Input from "../Input/Input";
import PrimaryButton from "../Buttons/PrimaryButton";
import Dropdown from "../Dropdown/Dropdown";
import Checkbox from "../Checkbox/Checkbox";
import { COA_ACCOUNT_TYPES } from "../../../constants";
import BaseButton from "../Buttons/BaseButton";
import crossIcon from "../../assets/images/crossIcon.svg";

const ChartOfAccountsAddCodePopover = ({
  chartOfAccounts,
  newChartOfAccountCode,
  setNewChartOfAccountCode,
  onFinishAdding,
  setShowAddCodePopover,
}) => {
  const [addAnother, setAddAnother] = useState(false);

  return (
    <div className="flex flex-col border-2 border-gray-150 p-4">
      <div className="flex py-2 justify-between">
        <h3 className="uppercase font-bold">
          {chartOfAccounts?.length
            ? "Add Another Account Code"
            : "Get started by adding your first account code…"}
        </h3>
        <div>
          <BaseButton
            onClick={() => setShowAddCodePopover(false)}
            iconLeft={<img src={crossIcon} alt="crossIcon" />}
          />
        </div>
      </div>
      <div className="flex flex-row py-2 w-full">
        <div className="w-1/6 pr-4">
          <Input
            placeholder="Account Code"
            className="text-gray-300"
            label="Account Code"
            value={newChartOfAccountCode?.code}
            onChange={(val) =>
              setNewChartOfAccountCode({
                ...newChartOfAccountCode,
                code: val,
              })
            }
            showValidationErrorAtBottom
            validation={yup
              .number()
              .positive()
              .transform((v, o) => (o === "" ? undefined : v))
              .required()}
            disableClear
          />
        </div>
        <div className="w-full pr-4">
          <Input
            placeholder="Description"
            className="text-gray-300"
            label="Description"
            value={newChartOfAccountCode?.description}
            onChange={(val) =>
              setNewChartOfAccountCode({
                ...newChartOfAccountCode,
                description: val,
              })
            }
          />
        </div>
        <div className="w-2/3 pr-4">
          <Dropdown
            label="Account Code Type"
            options={COA_ACCOUNT_TYPES}
            onChange={(val) =>
              setNewChartOfAccountCode({
                ...newChartOfAccountCode,
                accountType: val?.value,
                codeType: val?.type,
              })
            }
            value={COA_ACCOUNT_TYPES.find(
              (type) => type.value === newChartOfAccountCode?.accountType
            )}
            validation={yup.mixed().required()}
          />
        </div>
        <div className="w-1/6 flex flex-col pt-7">
          <PrimaryButton
            title="Add Code"
            onClick={() => onFinishAdding(addAnother)}
          />
          <Checkbox
            label="Add Another"
            className="py-2"
            onChange={setAddAnother}
            checked={addAnother}
          />
        </div>
      </div>
    </div>
  );
};

ChartOfAccountsAddCodePopover.propTypes = {
  chartOfAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      description: PropTypes.string,
      isOpen: PropTypes.bool,
      selectedForBudgets: PropTypes.bool,
      isOpenInProjectConfig: PropTypes.bool,
      codeType: PropTypes.string,
      accountType: PropTypes.string,
      subCodes: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          description: PropTypes.string,
          selectedForBudgets: PropTypes.bool,
        })
      ),
    })
  ),
  newChartOfAccountCode: PropTypes.shape({
    code: PropTypes.string,
    description: PropTypes.string,
    isOpen: PropTypes.bool,
    isSelectedForProjects: PropTypes.bool,
    isOpenInProjectConfig: PropTypes.bool,
    codeType: PropTypes.string,
    accountType: PropTypes.string,
    subCodes: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        description: PropTypes.string,
        isSelectedForProjects: PropTypes.bool,
      })
    ),
  }),
  setNewChartOfAccountCode: PropTypes.func,
  onFinishAdding: PropTypes.func,
  setShowAddCodePopover: PropTypes.func,
};

ChartOfAccountsAddCodePopover.defaultProps = {
  chartOfAccounts: [],
  newChartOfAccountCode: undefined,
  setNewChartOfAccountCode: undefined,
  onFinishAdding: undefined,
  setShowAddCodePopover: undefined,
};

export default ChartOfAccountsAddCodePopover;

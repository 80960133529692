import React from "react";
import Widget from "../../Widget/Widget";
import useContactPreferences from "./useContactPreferences";
import PreferenceContainer from "../PreferenceContainer";
import addIcon from "../../../assets/images/plusCircleIconDarkGreen.svg";

const preferenceContainerCN = "w-full flex flex-row flex-wrap gap-8 pb-2";
const LIKE = "like";
const DISLIKE = "dislike";
const SPECIAL = "special";

const ContactPreferences = ({
  preferences,
  setPreferences,
  isEditing,
  isLoading,
}) => {
  const {
    handleRemoveContainer,
    handleRemovePreference,
    handleAddContainer,
    handleOnClick,
    handleAddPreference,
  } = useContactPreferences({
    setPreferences,
    preferences,
  });

  const addCategoryButton = (preferenceType) => (
    <button
      className="flex flex-row items-center gap-2"
      type="button"
      onClick={() => handleAddContainer(preferenceType)}
    >
      <p className="text-es-normal text-es-green font-es-semibold">
        Add Category
      </p>
      <img src={addIcon} alt="add preference" />
    </button>
  );

  return (
    <div className="flex flex-col gap-8">
      <Widget
        loading={isLoading}
        draggable={false}
        title="Likes"
        childClassName="w-full flex flex-col gap-6"
      >
        <div className={preferenceContainerCN}>
          {preferences?.like &&
            Object.keys(preferences?.[LIKE])?.map((title) => (
              <PreferenceContainer
                key={title}
                preferenceType={LIKE}
                handleOnClick={handleOnClick}
                title={title}
                preferences={preferences}
                isEditing={isEditing}
                handleRemoveContainer={handleRemoveContainer}
                handleRemovePreference={handleRemovePreference}
                handleAddPreference={handleAddPreference}
              />
            ))}
        </div>

        {isEditing && addCategoryButton(LIKE)}
      </Widget>

      <Widget
        loading={isLoading}
        draggable={false}
        title="Dislikes"
        childClassName="w-full flex flex-col gap-6"
      >
        <div className={preferenceContainerCN}>
          {preferences?.dislike &&
            Object.keys(preferences?.[DISLIKE])?.map((title) => (
              <PreferenceContainer
                key={title}
                preferenceType={DISLIKE}
                handleOnClick={handleOnClick}
                title={title}
                preferences={preferences}
                isEditing={isEditing}
                handleRemoveContainer={handleRemoveContainer}
                handleRemovePreference={handleRemovePreference}
                handleAddPreference={handleAddPreference}
              />
            ))}
        </div>

        {isEditing && addCategoryButton(DISLIKE)}
      </Widget>

      <Widget
        loading={isLoading}
        draggable={false}
        title="Considerations"
        childClassName="w-full flex flex-col gap-6"
      >
        <div className={preferenceContainerCN}>
          {preferences?.special &&
            Object.keys(preferences?.[SPECIAL])?.map((title) => (
              <PreferenceContainer
                key={title}
                preferenceType={SPECIAL}
                handleOnClick={handleOnClick}
                title={title}
                preferences={preferences}
                isEditing={isEditing}
                handleRemoveContainer={handleRemoveContainer}
                handleRemovePreference={handleRemovePreference}
                handleAddPreference={handleAddPreference}
              />
            ))}
        </div>

        {isEditing && addCategoryButton(SPECIAL)}
      </Widget>
    </div>
  );
};

export default ContactPreferences;

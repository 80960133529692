// Framework Tools
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Components
import RequestWorkflowStepCardClosedView from "./RequestWorkflowStepCardClosedView ";
import SecondaryButton from "../../Buttons/SecondaryButton";

export default function RequestWorkflowStepCard({
  step,
  index,
  currentUser,
  isSubmittal,
  requestData,
  submittalActionReview,
  submittalActionSubmit,
  loading,
  setLoading,
  allSteps,
  patchSubmittal,
  requestStatus,
  attachmentMap,
  setAttachmentMap,
  reload,
  sidebarOpen,
  commentMap,
}) {
  const [actionValues, setActionValues] = useState({
    requestId: requestData?.id,
    stepId: step?.id,
    status: "",
    action: "",
    comment: null,
  });

  const [open, setOpen] = useState(false);
  const [, setStepDueDate] = useState("");
  const [stepUsers, setStepUsers] = useState([]);
  const [, setStepRole] = useState("");
  const [stepActionStatus, setStepActionStatus] = useState("");
  const [stepAdvanced, setStepAdvanced] = useState(false);
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [comment, setComment] = useState("");

  const handleOpenCommentModal = (status, action, title) => {
    setActionValues((prev) => {
      return { ...prev, status, action, title };
    });
    setCommentModalOpen(true);
  };

  // State updaters
  useEffect(() => {
    setStepDueDate(step?.dueDate?.projected);
  }, [step?.dueDate?.projected]);

  useEffect(() => {
    setStepUsers(step?.users);
  }, [step?.users]);

  useEffect(() => {
    setStepRole(stepUsers?.[0]?.type);
    setStepActionStatus(stepUsers?.[0]?.type);
  }, [stepUsers]);

  useEffect(() => {
    const advancingUser = step?.users?.find(
      (user) => user.status !== "waiting"
    );

    if (advancingUser?.status) {
      setStepAdvanced({
        status: advancingUser?.status,
        date: advancingUser?.date,
        advanced: true,
      });
    }

    if (!advancingUser) {
      setStepAdvanced({
        advanced: false,
      });
    }
  }, [step?.users]);

  useEffect(() => {
    const userResult = stepUsers?.filter(
      (user) => user?.reference === currentUser?.reference
    );
    if (userResult?.length > 0) {
      setStepActionStatus(step?.users?.[0]?.type);
    } else {
      setStepActionStatus("reminder");
    }
  }, [currentUser?.reference, step?.users, stepAdvanced, stepUsers]);

  useEffect(() => {
    setActionValues((prev) => {
      return { ...prev, comment };
    });
  }, [comment]);

  const handleReviewApproveAction = async () => {
    try {
      setLoading(true);
      await submittalActionReview(
        actionValues?.requestId,
        actionValues?.stepId,
        actionValues?.status,
        actionValues?.action,
        actionValues?.comment
      );
    } catch (err) {
      console.error(err);
    } finally {
      reload();
      setLoading(false);
    }
  };

  const handleDropdownOptions = () => {
    const options = [
      {
        title: "Approved",
        onClick: () =>
          handleOpenCommentModal("approved", "respond", "Approved"),
      },
      {
        title: "Approved Resubmit Req.",
        onClick: () =>
          handleOpenCommentModal(
            "approved-resubmission-required",
            "respond",
            "Approved Resubmit Req."
          ),
      },
      {
        title: "Approved No Resubmit Req.",
        onClick: () =>
          handleOpenCommentModal(
            "respond",
            "respond",
            "Approved No Resubmit Req."
          ),
      },
      {
        title: "Revise and Resubmit",
        onClick: () =>
          handleOpenCommentModal(
            "revise-and-resubmit",
            "respond",
            "Revise and Resubmit"
          ),
      },
      {
        title: "Reject",
        onClick: () => handleOpenCommentModal("rejected", "respond", "Reject"),
      },
      {
        title: "Void",
        onClick: () => handleOpenCommentModal("void", "void", "Void"),
      },
    ];
    return options;
  };

  const handleActionButton = () => {
    switch (stepActionStatus) {
      case "submitter": {
        return (
          <SecondaryButton
            className=" reviewBtn"
            onClick={() => handleOpenCommentModal("submit", "submit", "Submit")}
            title="Submit"
          />
        );
      }
      case "reviewer": {
        return (
          <div id="reviewBtns" className="flex  reviewBtn">
            <SecondaryButton
              id="advance"
              onClick={() =>
                handleOpenCommentModal("advance", "advance", "Advance")
              }
              title="Advance"
            />
            <SecondaryButton
              id="returntosubmitter"
              onClick={() =>
                handleOpenCommentModal(
                  "return-to-submitter",
                  "returntosubmitter",
                  "Return To Submitter"
                )
              }
              className="ml-4 overflow-x overflow-ellipsis"
              title="Return to Submitter"
            />
          </div>
        );
      }
      case "approver": {
        return (
          <SecondaryButton
            hideDropdownChevron
            dropdownItems={handleDropdownOptions()}
            title="Respond"
            chevronColor="text-brandGreen"
          />
        );
      }
      case "reminder": {
        return (
          <SecondaryButton
            title="Send Reminder"
            onClick={() =>
              handleOpenCommentModal(
                "remind",
                "sendreminder",
                "Send a reminder"
              )
            }
          />
        );
      }
      case "advanced": {
        return <p>advanced</p>;
      }
      default: {
        return <div>default</div>;
      }
    }
  };

  return (
    <div>
      {!open && (
        <RequestWorkflowStepCardClosedView
          commentMap={commentMap}
          sidebarOpen={sidebarOpen}
          attachmentMap={attachmentMap}
          setActionValues={setActionValues}
          actionValues={actionValues}
          requestStatus={requestStatus}
          stepAdvanced={stepAdvanced}
          allSteps={allSteps}
          requestData={requestData}
          step={step}
          index={index}
          setOpen={setOpen}
          open={open}
          isSubmittal={isSubmittal}
          comment={comment}
          setComment={setComment}
          commentModalOpen={commentModalOpen}
          setCommentModalOpen={setCommentModalOpen}
          handleActionButton={handleActionButton}
          handleReviewApproveAction={handleReviewApproveAction}
          loading={loading}
          setLoading={setLoading}
          patchSubmittal={patchSubmittal}
          submittalActionReview={submittalActionReview}
          submittalActionSubmit={submittalActionSubmit}
          reload={reload}
          currentUser={currentUser}
          setAttachmentMap={setAttachmentMap}
        />
      )}
    </div>
  );
}

// prop types
RequestWorkflowStepCard.propTypes = {
  commentMap: PropTypes.shape({}),
  reload: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  attachmentMap: PropTypes.shape({}),
  requestStatus: PropTypes.shape({}),
  allSteps: PropTypes.arrayOf(PropTypes.shape({})),
  patchSubmittal: PropTypes.func,
  submittalActionReview: PropTypes.func,
  submittalActionSubmit: PropTypes.func,
  requestData: PropTypes.shape({
    id: PropTypes.string,
  }),
  isSubmittal: PropTypes.bool,
  currentUser: PropTypes.shape({
    reference: PropTypes.string,
  }),
  step: PropTypes.shape({
    id: PropTypes.string,
    dueDate: PropTypes.string,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
      })
    ),
    position: PropTypes.number,
  }),
  index: PropTypes.number,
  sidebarOpen: PropTypes.bool,
  setAttachmentMap: PropTypes.func,
};

// default props
RequestWorkflowStepCard.defaultProps = {
  commentMap: undefined,
  reload: undefined,
  loading: true,
  setLoading: undefined,
  attachmentMap: undefined,
  setAttachmentMap: undefined,
  requestStatus: undefined,
  allSteps: undefined,
  patchSubmittal: undefined,
  submittalActionReview: undefined,
  submittalActionSubmit: undefined,
  requestData: undefined,
  isSubmittal: false,
  currentUser: undefined,
  step: {
    position: 1,
  },
  index: 0,
  sidebarOpen: false,
};

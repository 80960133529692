import moment from "moment-timezone";
import React from "react";
import formatTasks, {
  statusPillCN,
} from "../../../../../helpers/ServiceRequest/formatTasks";
import RoleMembersAvatarList from "../../../../../Pages/Admin/RoleMembersAvatarList";
import chevronRightIcon from "../../../../assets/images/chevronRightDarkGreen.svg";
import IconButton from "../../../Buttons/IconButton";
import Pill from "../../../Pill/Pill";

const TaskClosingRow = ({
  index,
  task,
  allInviteesInfo,
  onClick,
  isExpanded,
}) => {
  return (
    <div className={`relative w-full rounded-t-lg ${isExpanded && "border-b"}`}>
      <table className="w-full table-fixed h-16">
        <tr
          className={`h-16 align-middle text-gray-400 ${
            isExpanded && "font-semibold"
          }`}
        >
          <td className="w-12 p-4 border-r border-gray-150 font-semibold text-md  text-center">
            {index + 1}
          </td>
          <td className="text-md p-4 border-r border-gray-150">
            {moment
              .tz(task?.closing?.date || task.lastUpdated, task.timezone)
              .format("ll")}
          </td>
          <td className="text-md p-4 border-r border-gray-150 w-44">
            {moment
              .tz(task?.closing?.date || task.lastUpdated, task.timezone)
              .format("h:mm A")}{" "}
            {moment.tz(task.timezone).format("z")}
          </td>
          <td
            className="text-md p-4 border-r border-gray-150 capitalize w-44"
            aria-label="table-data"
          >
            {allInviteesInfo?.length > 0 && (
              <RoleMembersAvatarList
                members={allInviteesInfo}
                membersToShowCount="3"
                showExtraCount
              />
            )}
          </td>
          <td
            className="p-4 border-r border-gray-150 flex justify-center w-44 h-full px-6"
            aria-label="table-data"
          >
            <Pill
              value={formatTasks(task)?.formattedStatus}
              className={statusPillCN(formatTasks(task)?.formattedStatus)}
              background=""
            />
          </td>
          <td className="p-4 border-gray-150 w-44" aria-label="table-data">
            <IconButton
              icon={chevronRightIcon}
              isIconOnRight
              imgClassName={`w-3 h-3 ${isExpanded && "transform rotate-90"}`}
              className="text-darkenedGreen"
              onClick={() => onClick(index)}
              title="Go To Task"
            />
          </td>
        </tr>
      </table>
    </div>
  );
};

export default TaskClosingRow;

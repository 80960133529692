// Framework Tools
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import urlSchemify from "url-schemify";

// Components
import TertiaryHeader from "../../TextHeaders/TertiaryHeader";
import IconButton from "../../Buttons/IconButton";

// Icons
import returnIcon from "../../../assets/images/returnIcon.svg";

export default function RequestGridViewLinksCell({ links }) {
  return (
    <div className="w-full h-ful flex flex-col justify-start flex-wrap px-2">
      <TertiaryHeader className="text-sm text-left">Links</TertiaryHeader>
      {links?.map((link, i) => {
        return (
          <Link
            // eslint-disable-next-line react/no-array-index-key
            key={`${link.url}-${link.name}-${i}`}
            to={{
              pathname: urlSchemify(link.url, { scheme: "https" }),
            }}
            target="_blank"
            className=" text-black flex items-center"
          >
            {link.name}
            <IconButton className="ml-4 text-brandGreen" icon={returnIcon} />
          </Link>
        );
      })}
    </div>
  );
}

// prop types
RequestGridViewLinksCell.propTypes = {
  links: PropTypes.arrayOf({
    name: PropTypes.string,
    url: PropTypes.string,
  }),
};

RequestGridViewLinksCell.defaultProps = {
  links: [],
};

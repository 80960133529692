import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import Modal from "../Modal/Modal";
import { getContactDisplayType } from "../../../helpers/User";
import UserImageAvatar from "../Avatar/UserImageAvatar";
import UserInitialsAvatar from "../Avatar/UserInitialsAvatar";
import { useGetFile } from "../../../hooks/useGetFile";

const ErrorModalCN = cntl`
  flex
  flex-col
  px-6
  py-4
`;

const headerCN = cntl`
  text-lg
  font-semibold
`;

const ReinviteUserModal = ({ user, onCancel, onSave, isSaving }) => {
  const { data } = useGetFile(user?.avatar);

  return (
    <Modal
      title={`Reinvite ${getContactDisplayType(user?.kind)}`}
      isOpen
      onRequestModalClose={onCancel}
      hideFooter
      primaryButtonTitle="Reinvite"
      primaryButtonOnClick={() => onSave(user?.id)}
      tertiaryButtonTitle="Cancel"
      disabled={isSaving}
    >
      <div className={ErrorModalCN}>
        <div className="flex flex-row">
          {(data?.thumbnailUrl && (
            <UserImageAvatar
              width="w-20"
              height="h-20"
              className="mr-6 relative"
              thumbnailUrl={data?.thumbnailUrl}
            />
          )) || (
            <UserInitialsAvatar
              name={user?.name}
              width="w-20"
              height="h-20"
              className="mr-6"
            />
          )}
          <div className="px-2 pt-3">
            <>
              <h2 className={headerCN}>
                {user?.name?.firstName} {user?.name?.lastName}
              </h2>
              <h2 className="text-sm text-gray-300">{user?.company}</h2>
            </>
          </div>
        </div>
        <div className="pt-5">
          <h2>
            Do you want to reinvite the {getContactDisplayType(user?.kind)}?
          </h2>
        </div>
      </div>
    </Modal>
  );
};

ReinviteUserModal.propTypes = {
  onCancel: PropTypes.func,
  user: PropTypes.shape({
    active: PropTypes.bool,
    avatar: PropTypes.shape({
      thumbnailUrl: PropTypes.string,
    }),
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    id: PropTypes.string,
    company: PropTypes.string,
    role: PropTypes.string,
    kind: PropTypes.string,
  }),
  onSave: PropTypes.func,
  isSaving: PropTypes.bool,
};

ReinviteUserModal.defaultProps = {
  onCancel: undefined,
  user: undefined,
  onSave: undefined,
  isSaving: false,
};

export default ReinviteUserModal;

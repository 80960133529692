import moment from "moment";

export default [
  {
    id: "308fd207-d412-4d7e-bcb4-8e456a9dff45",
    x: moment("2021-01-01").valueOf(),
    y: 38,
  },
  {
    id: "fef32d6c-87e2-487b-af58-5ba034242e45",
    x: moment("2021-01-02").valueOf(),
    y: 34,
  },
  {
    id: "a08c7875-f261-4c4a-8fc7-860c758136df",
    x: moment("2021-01-03").valueOf(),
    y: 24,
  },
  {
    id: "252e3752-cadf-4a73-b808-4ff3489b3326",
    x: moment("2021-01-04").valueOf(),
    y: 49,
  },
  {
    id: "fe2228f7-56c8-4f7d-950b-60368cf38be1",
    x: moment("2021-01-05").valueOf(),
    y: 72,
  },
  {
    id: "703c51a3-4250-4a00-9ecc-5dba5d084d12",
    x: moment("2021-01-06").valueOf(),
    y: 34,
  },
  {
    id: "c30538fa-c24f-41e4-a698-2218a2a6e287",
    x: moment("2021-01-07").valueOf(),
    y: 65,
  },
  {
    id: "10117cde-5672-40fe-8df1-7951c10e717c",
    x: moment("2021-01-08").valueOf(),
    y: 82,
  },
  {
    id: "d7990d0a-9d2d-4e04-8012-81ca1c677159",
    x: moment("2021-01-09").valueOf(),
    y: 61,
  },
  {
    id: "10100fce-82d6-443c-8390-6364ba35811c",
    x: moment("2021-01-10").valueOf(),
    y: 3,
  },
];

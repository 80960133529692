import React, { useEffect, useRef, useState } from "react";
import reloadIcon from "../../../stories/assets/images/reloadIconGray.svg";

const ReloadButton = ({ onClick }) => {
  const [clicked, setClicked] = useState(false);
  const timer = useRef();

  useEffect(() => {
    if (clicked) timer.current = setTimeout(() => setClicked(false), 3000);

    if (timer.current) clearTimeout(timer.current);
  }, [clicked]);
  return (
    <button
      className={clicked ? "animate-spin" : ""}
      style={{ minWidth: "12px" }}
      type="button"
      onClick={() => {
        setClicked(true);
        onClick();
      }}
    >
      <img src={reloadIcon} alt="resend-message" />
    </button>
  );
};

export default ReloadButton;

import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";

import Checkbox from "../../Checkbox/Checkbox";
import BaseButton from "../../Buttons/BaseButton";
import SelectSectionItem from "../SelectSectionItem";
import PlusCirlceIconDarkGreen from "../../../assets/images/plusCircleIconDarkGreen.svg";
import useSelectSectionData from "./useSelectSectionData";
import WorldClockSelect from "../../SystemSettings/WorldClockSelect";

const tertiaryButtonCN = cntl`
  text-brandDarkGreen
  py-4
  text-base
`;

const SelectSection = ({
  selection: {
    selectionName = "",
    selectionValue = "",
    selectionItemValue = "display",
    selectionSecondItemValue = "",
  },
  settings: { settings = {}, dispatchSettings = () => {} },
  setIsValid,
}) => {
  const {
    isSelectAll,
    isWorldClock,
    setAddMode,
    onAddSectionItem,
    onRemoveSectionItem,
    onSectionItemChange,
    toggleSectionItemEditing,
    handleEnter,
    handleBlur,
    selectAllSectionItems,
    onSectionItemSelect,
  } = useSelectSectionData({
    selectionValue,
    selectionItemValue,
    selectionSecondItemValue,
    settings,
    dispatchSettings,
    setIsValid,
  });

  return (
    <>
      {!!settings?.[selectionValue]?.length && (
        <div className="flex flex-col">
          <div className="flex border-b border-gray-150 justify-between py-4">
            <Checkbox
              label="Select All"
              labelClassName={`font-medium text-sm ${
                isSelectAll ? "" : "text-gray-200"
              }`}
              checked={isSelectAll}
              onChange={() => selectAllSectionItems(!isSelectAll)}
            />
          </div>

          <div className="pt-4">
            {settings?.[selectionValue]?.map((item, index) => {
              return (
                <div key={item?.id}>
                  <SelectSectionItem
                    item={item}
                    index={index}
                    toggleEditing={toggleSectionItemEditing}
                    handleChange={onSectionItemChange}
                    handleRemove={onRemoveSectionItem}
                    handleCheck={onSectionItemSelect}
                    handleBlur={handleBlur}
                    handleEnter={handleEnter}
                    setAddMode={setAddMode}
                    selectionValue={selectionValue}
                  >
                    {isWorldClock && item?.isEditing ? (
                      <div className="ml-4 min-w-48">
                        <WorldClockSelect
                          idx={index}
                          handleCustomCityChange={(value, i, secondValue) => {
                            onSectionItemChange(value, i, secondValue);
                            toggleSectionItemEditing(i);
                          }}
                          city={item.city}
                          handleBlur={handleBlur}
                        />
                      </div>
                    ) : null}
                  </SelectSectionItem>
                </div>
              );
            })}
            <div className="py-4 flex flex-row items-center">
              <img
                className="h-4 w-4"
                src={PlusCirlceIconDarkGreen}
                alt="plus circle icon green"
              />
              <BaseButton
                title={`Add ${selectionName}`}
                onClick={() => {
                  setAddMode(true);
                  onAddSectionItem();
                }}
                className={tertiaryButtonCN}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

SelectSection.propTypes = {
  selection: PropTypes.shape({
    selectionName: PropTypes.string,
    selectionValue: PropTypes.string,
    selectionItemValue: PropTypes.string,
    selectionSecondItemValue: PropTypes.string,
  }),
  settings: PropTypes.shape({
    settings: PropTypes.shape({
      projectTypes: PropTypes.arrayOf(
        PropTypes.shape({
          display: PropTypes.string,
          id: PropTypes.string,
        })
      ),

      projectPositions: PropTypes.arrayOf(
        PropTypes.shape({
          display: PropTypes.string,
          id: PropTypes.string,
          selected: PropTypes.bool,
        })
      ),
    }),
    dispatchSettings: PropTypes.func,
  }),
  setIsValid: PropTypes.func,
};

SelectSection.defaultProps = {
  selection: undefined,
  settings: undefined,
  setIsValid: undefined,
};

export default SelectSection;

import moment from "moment-timezone";
import { useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  ADD_OPEN_MODAL,
  GET_ASSET_VIEW_PATH,
  GET_PROJECTS_PATH,
  GET_PROPERTY_VIEW_PATH,
  GET_SPACE_VIEW_PATH,
  VIEW_TASK_MODAL,
} from "../../../../constants";
import isBegOfDay from "../../../../helpers/Date/isBegOfDay";
import isEndOfDay from "../../../../helpers/Date/isEndOfDay";
import formatTasks, {
  statusPillCN,
} from "../../../../helpers/ServiceRequest/formatTasks";
import { getTimeZoneAbbreviation } from "../../../../helpers/Time";
import { useAssetsOverview } from "../../../../hooks/assets";
import { useProjectsOverview } from "../../../../hooks/projects";
import { usePropertiesOverview } from "../../../../hooks/properties";
import { useRsvp } from "../../../../hooks/rsvp";
import { useGetFile } from "../../../../hooks/useGetFile";
import useSpaces from "../../../../hooks/useSpaces";
import { useAppState } from "../../../../state/appState";
import { useModalState } from "../../../../state/modalState";

export default ({
  association,
  files,
  status,
  invitees,
  metadata,
  startDate,
  endDate,
  reference,
  instanceStartDate,
}) => {
  const { assetId, spaceId } = useParams();
  const [{ calendarTimezone }] = useAppState();
  const { data: rsvpData } = useRsvp(reference, instanceStartDate);

  // get if task is all day
  const isAllDay = isBegOfDay(startDate) && isEndOfDay(endDate);
  // format task time
  const taskTime = isAllDay
    ? `${moment(startDate).format("MMMM D")} - All Day`
    : `${moment
        .tz(startDate, calendarTimezone)
        .format("MMMM D · h:mma")} ${getTimeZoneAbbreviation(
        startDate,
        calendarTimezone
      )}`;
  // format task time title
  const taskTimeTitle = isAllDay
    ? taskTime
    : moment.tz(startDate, calendarTimezone).format("LLLL");

  /**
   * Hook Dependencies
   */
  const hookDeps = useMemo(() => {
    if (association?.includes("Property")) {
      return {
        resource: "propertiesDict",
        route: "property",
        cacheKey: "properties",
        id: association?.split("Property/")[1],
        ref: association,
        spaceId,
        api: "PropertyAPI",
      };
    }
    return {
      resource: "projectDict",
      route: "project",
      cacheKey: "projects",
      id: association?.split("Project/")[1],
      ref: association,
      spaceId,
      api: "ProjectAPI",
    };
  }, [association, spaceId]);

  const { space } = useSpaces(hookDeps);

  const [{ userDict }] = useAppState();

  const { assetsDict } = useAssetsOverview();
  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  const history = useHistory();

  const [, modalDispatch] = useModalState();

  const location = useMemo(() => {
    let route;
    let name;
    const [resource, id] = (association ?? "")?.split("/");

    switch (resource) {
      case "Property":
        name = propertiesDict?.[association]?.title;
        route = GET_PROPERTY_VIEW_PATH(id);
        break;
      case "Project":
        name = projectDict?.[association]?.name;
        route = GET_PROJECTS_PATH(id);
        break;
      default:
        return null;
    }

    if (assetId) {
      name = assetsDict?.[`Asset/${assetId}`]?.name;
      route = GET_ASSET_VIEW_PATH(assetId);
    }

    if (spaceId) {
      name = space?.name;
      route = GET_SPACE_VIEW_PATH?.[resource.toLowerCase()](id, space?.id);
    }
    return { name, route };
  }, [
    assetId,
    assetsDict,
    association,
    projectDict,
    propertiesDict,
    space?.id,
    space?.name,
    spaceId,
  ]);

  /**
   * Resolve Image
   */
  const imageAvailable = useMemo(
    () => files?.find((item) => item?.category === "Photos"),
    [files]
  );
  const { data: photo } = useGetFile(imageAvailable?.ref);

  /**
   * Resolve Image
   */

  // Status
  const state = useMemo(() => {
    return formatTasks({ startDate, endDate, status })?.formattedStatus;
  }, [endDate, startDate, status]);
  // Status pill classname
  const taskStatusPillCN = useMemo(() => {
    return statusPillCN(state);
  }, [state]);

  // Task Members
  const members = useMemo(() => {
    const inviteeData = invitees?.reduce((list, invitee) => {
      // Get information for each invitee from the user dictionary
      const inviteeInfo = userDict?.[invitee];
      if (inviteeInfo) list.push(inviteeInfo);
      return list;
    }, []);
    return inviteeData;
  }, [invitees, userDict]);

  // Task Creator
  const creator = useMemo(() => {
    const user = userDict?.[metadata?.createdBy];
    return user;
  }, [userDict, metadata?.createdBy]);

  const handleRowClick = useCallback(
    (task) => {
      modalDispatch({
        type: ADD_OPEN_MODAL,
        ref: { id: task?.id },
        modalData: { item: task },
        modalType: VIEW_TASK_MODAL,
      });
    },
    [modalDispatch]
  );

  return {
    taskTime,
    taskTimeTitle,
    isAllDay,
    location,
    photo: photo?.contentsUrl,
    imageAvailable,
    state,
    creator,
    handleRowClick,
    members,
    taskStatusPillCN,
    history,
    rsvpData,
  };
};

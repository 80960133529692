import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { capitalize } from "lodash";

import Input from "../../Input/Input";
import Dropdown from "../../Dropdown/Dropdown";
import Checkbox from "../../Checkbox/Checkbox";
import crossIconWhite from "../../../assets/images/crossIconWhite.svg";
import PlusCircleButton from "../../Buttons/PlusCircleButton/PlusCircleButton";
import { WORKFLOW_ROLES } from "../../../../constants";

export default function WorkflowTemplateForm({
  templateName,
  isDefaultTemplate: defaultTemplate,
  memberOptions,
  workflow,
  dispatch,
  handleDeleteStep,
  handleAddNewStep,
  currentTemplate,
  isEditing,
}) {
  const [customName, setCustomName] = useState();
  const [editedSteps, setEditedSteps] = useState();
  const [isDefaultTemplate, setIsDefaultTemplate] = useState(false);

  useEffect(() => {
    setEditedSteps(workflow?.steps);
    setIsDefaultTemplate(defaultTemplate);
    setCustomName(templateName);
  }, [defaultTemplate, templateName, workflow?.steps]);

  const handleChange = (key, value) => {
    setCustomName(value);

    dispatch({
      type: key,
      payload: value,
    });
  };
  const handleDefaultTempChange = () => {
    setIsDefaultTemplate((prev) => !prev);

    dispatch({
      type: "defaultTemplate",
      payload: !isDefaultTemplate,
    });
  };

  const handleDurationChange = (val, step, idx) => {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(Number(val))) {
      const allSteps = workflow?.steps;
      const newDuration = {
        ...step.duration,
        projected: Number(val),
      };
      const newStep = {
        ...step,
        duration: newDuration,
      };
      allSteps?.splice(idx, 1, newStep);

      dispatch({
        type: "requestWorkflow",
        payload: { ...workflow, steps: allSteps },
      });
    }
  };

  const handleStepRoleChange = (role, step, idx) => {
    const allSteps = workflow?.steps;
    const newStep = {
      ...step,
      users: [{ ...step.users[0], type: role.value }],
    };
    allSteps?.splice(idx, 1, newStep);

    dispatch({
      type: "requestWorkflow",
      payload: { ...workflow, steps: allSteps },
    });
  };

  const handleStepMemberChange = (userRef, step, idx) => {
    const allSteps = workflow?.steps;
    const newStep = {
      ...step,
      users: [{ ...step.users[0], reference: userRef }],
    };
    allSteps?.splice(idx, 1, newStep);
    dispatch({
      type: "requestWorkflow",
      payload: { ...workflow, steps: allSteps },
    });
  };

  return (
    <div className="flex flex-col gap-6 items-start self-stretch w-full">
      <div className="w-726px">
        <Input
          inputClassName="bg-lightGreen py-2 px-5 w-full"
          inputContainerClassName="bg-lightGreen w-full"
          placeholder="Name (Required)"
          onChange={(val) => handleChange("name", val)}
          value={customName}
        />
      </div>
      <div className="flex flex-col items-start gap-5 border rounded-lg p-5 w-full">
        {editedSteps?.map((step, index) => {
          return (
            <div
              key={step.position}
              className="relative h-20 flex border rounded-lg w-full gap-6 px-6 items-center"
            >
              {index > 0 && (
                <button
                  type="button"
                  className="absolute -top-2.5 -right-2.5"
                  style={{
                    width: "18px",
                    height: "18px",
                  }}
                  onClick={() => handleDeleteStep(step)}
                >
                  <img
                    alt="delete tag"
                    className="rounded-xl p-1"
                    style={{ backgroundColor: "#027D61" }}
                    src={crossIconWhite}
                  />
                </button>
              )}
              <p className="font-bold text-base flex flex-nonwrap">
                Step {step.position}
              </p>
              <Dropdown
                className="w-60"
                st
                placeholder="Select"
                options={memberOptions}
                value={
                  step?.users?.[0]?.reference && {
                    label: memberOptions?.find(
                      (item) => item.value === step?.users?.[0]?.reference
                    )?.label,
                    value: memberOptions?.find(
                      (item) => item.value === step?.users?.[0]?.reference
                    )?.value,
                  }
                }
                onChange={(val) =>
                  handleStepMemberChange(val?.value, step, index)
                }
                disableClear
              />
              <div id="verital-divider-1" className="border-r h-full" />
              <Dropdown
                className="flex-1"
                placeholder="Select"
                options={WORKFLOW_ROLES}
                value={
                  step?.users?.[0]?.type && {
                    label: capitalize(step?.users?.[0]?.type),
                    value: step?.users?.[0]?.type,
                  }
                }
                onChange={(val) => handleStepRoleChange(val, step, index)}
                disableClear
              />
              <div id="verital-divider-2" className="border-r h-full" />
              <p className=" font-bold text-base">Duration</p>
              <div className="w-14">
                <Input
                  inputClassName="bg-lightGreen w-full text-center"
                  placeholder="--"
                  value={step?.duration?.projected}
                  onChange={(val) => handleDurationChange(val, step, index)}
                  minValue={1}
                  disableClear
                />
              </div>
              <p className="text-base font-normal">days</p>
            </div>
          );
        })}
        <div className="flex justify-between items-center w-full">
          <div
            className={`flex items-center ${
              !!currentTemplate?.isDefaultTemplate && isEditing && "invisible"
            }`}
          >
            <Checkbox
              checked={isDefaultTemplate}
              onChange={handleDefaultTempChange}
            />
            <p className="text-base font-medium text-gray-450 px-5">
              {" "}
              Make default workflow template
            </p>
          </div>
          <PlusCircleButton
            className="flex items-center"
            title="Add Step"
            style={{ color: "#027D61" }}
            onClick={() => handleAddNewStep()}
            noHover
          />
        </div>
      </div>
    </div>
  );
}

WorkflowTemplateForm.propTypes = {
  memberOptions: PropTypes.arrayOf(PropTypes.shape({})),
  workflow: PropTypes.shape({ steps: PropTypes.shape() }),
  dispatch: PropTypes.func,
  handleDeleteStep: PropTypes.func,
  handleAddNewStep: PropTypes.func,
  templateName: PropTypes.string,
  isDefaultTemplate: PropTypes.bool,
  currentTemplate: PropTypes.shape({ isDefaultTemplate: PropTypes.bool }),
  isEditing: PropTypes.bool,
};

WorkflowTemplateForm.defaultProps = {
  memberOptions: [],
  workflow: undefined,
  dispatch: undefined,
  handleDeleteStep: undefined,
  handleAddNewStep: undefined,
  templateName: "",
  isDefaultTemplate: false,
  currentTemplate: undefined,
  isEditing: false,
};

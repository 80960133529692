import { useMutation } from "react-query";
import queryClient from "../../../config/reactQuery/queryClient";
import { chatCodeKeys } from "../../../config/reactQuery/queryKeyFactory";
import { alphaSortChannels } from "../../../helpers/Chat";

export default () => {
  return useMutation({
    mutationFn: (channel) => {
      return channel;
    },
    onMutate: (channel) => {
      queryClient.setQueryData(chatCodeKeys, (prev) => {
        switch (channel.type) {
          case "messaging": {
            const foundChannel = prev?.channels[0]?.find(
              (item) => item.cid === channel.cid
            );
            if (!foundChannel) {
              return {
                ...prev,
                channels: [
                  alphaSortChannels([channel, ...prev.channels[0]]),
                  alphaSortChannels(prev.channels[1]),
                ],
              };
            }
            return {
              ...prev,
              channels: [
                alphaSortChannels(prev.channels[0]),
                alphaSortChannels(prev.channels[1]),
              ],
            };
          }
          default: {
            const foundChannel = prev?.channels[1]?.find(
              (item) => item.cid === channel.cid
            );
            if (!foundChannel) {
              return {
                ...prev,
                channels: [
                  alphaSortChannels(prev.channels[0]),
                  alphaSortChannels([channel, ...prev.channels[1]]),
                ],
              };
            }
            return {
              ...prev,
              channels: [
                alphaSortChannels(prev.channels[0]),
                alphaSortChannels(prev.channels[1]),
              ],
            };
          }
        }
      });
    },
  });
};

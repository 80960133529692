import { useUsers } from "./useUsers.new";

/**
 * Query selector to get single user by reference or the `userDict` Map
 * * @param {String} userRef User reference
 */
const useUserDict = (userRef) => {
  const handleSelect = ({ userDict }) => {
    if (userRef) {
      return userDict[userRef];
    }

    return userDict;
  };

  // Pass selector to useQuery hook and return data + metadata
  return useUsers(handleSelect);
};

export default useUserDict;

import React, { useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import Input from "../Input/Input";
import Dropdown from "../Dropdown/Dropdown";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import DatePicker from "../DatePicker/DatePicker";
import Checkbox from "../Checkbox/Checkbox";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";

const CompanyDetails = ({ property, dispatch, handleNextStep }) => {
  const [checked, setChecked] = useState(false);
  const [insurances, setInsurances] = useState([""]);

  const onClickAddNew = () => {
    setInsurances((prev) => [...prev, ""]);
  };
  const onChangeCompanyName = (name) => {
    dispatch({
      type: "companyName",
      name,
    });
  };

  const onChangeBusinessPerformed = (value) => {
    dispatch({
      type: "businessPerformed",
      value,
    });
  };

  const onChangeCompanyDescription = (description) => {
    dispatch({
      type: "companyDescription",
      description,
    });
  };

  const onChangeFederalEIN = (ein) => {
    dispatch({
      type: "federalEIN",
      ein,
    });
  };

  const onChangeNAICSCode = (code) => {
    dispatch({
      type: "NAICSCode",
      code,
    });
  };

  const onChangeDUNSNumber = (number) => {
    dispatch({
      type: "DUNSNumber",
      number,
    });
  };

  const onChangeBusinessEntity = (value) => {
    dispatch({
      type: "businessEntity",
      value,
    });
  };

  const onChangeIncorporationDate = (date) => {
    dispatch({
      type: "Incorporation",
      incorporation: {
        date,
      },
    });
  };

  const onChangeIncorporationState = (state) => {
    dispatch({
      type: "Incorporation",
      incorporation: {
        state,
      },
    });
  };

  const onChangePhysicalAddressLine1 = (street) => {
    dispatch({
      type: "physicalAddress",
      address: {
        street,
      },
    });
  };

  const onChangePhysicalAddressLine2 = (street2) => {
    dispatch({
      type: "physicalAddress",
      address: {
        street2,
      },
    });
  };

  const onChangePhysicalAddressCity = (city) => {
    dispatch({
      type: "physicalAddress",
      address: {
        city,
      },
    });
  };

  const onChangePhysicalAddressState = ({ value: state }) => {
    dispatch({
      type: "physicalAddress",
      address: {
        state,
      },
    });
  };

  const onChangePhysicalAddressZipCode = (zipCode) => {
    dispatch({
      type: "physicalAddress",
      address: {
        zipCode,
      },
    });
  };

  const onChangeMailingAddressLine1 = (street) => {
    dispatch({
      type: "mailingAddress",
      address: {
        street,
      },
    });
  };

  const onChangeMailingAddressLine2 = (street2) => {
    dispatch({
      type: "mailingAddress",
      address: {
        street2,
      },
    });
  };

  const onChangeMailingAddressCity = (city) => {
    dispatch({
      type: "mailingAddress",
      address: {
        city,
      },
    });
  };

  const onChangeMailingAddressState = ({ value: state }) => {
    dispatch({
      type: "mailingAddress",
      address: {
        state,
      },
    });
  };

  const onChangeMailingAddressZipCode = (zipCode) => {
    dispatch({
      type: "mailingAddress",
      address: {
        zipCode,
      },
    });
  };

  const onChangeCheckBox = (check) => {
    setChecked(check);
  };

  return (
    <div className="mb-28">
      <SecondaryHeader className="mt-4 mb-2">Overview</SecondaryHeader>
      <div className="grid grid-cols-12 gap-4 mt-4">
        <Input
          className="col-span-4"
          label="Company Name"
          placeholder="Name"
          onChange={onChangeCompanyName}
          value={property.companyName}
          validation={yup.string()}
        />
      </div>

      <div className="grid grid-cols-12 gap-4 mt-4">
        <Dropdown
          className="col-span-3"
          onChange={onChangeBusinessPerformed}
          label="Business Performed"
          placeholder="Select"
        />
      </div>

      <div className="grid grid-cols-12 gap-4 mt-4">
        <Input
          className="col-span-3"
          label="Company Description"
          isTextarea
          placeholder="Description"
          onChange={onChangeCompanyDescription}
          value={property.companyDescription}
          validation={yup.string()}
        />
      </div>
      <br />

      <SecondaryHeader className="mt-4 mb-2">Tax ID & Numbers</SecondaryHeader>

      <div className="grid grid-cols-12 gap-4 mt-4">
        <Input
          className="col-span-3"
          label="Federal EIN"
          placeholder="-- -----"
          onChange={onChangeFederalEIN}
          value={property.federalEIN}
          validation={yup.string()}
        />
      </div>

      <div className="grid grid-cols-12 gap-4 mt-4">
        <Input
          className="col-span-3"
          placeholder="------"
          onChange={onChangeNAICSCode}
          value={property.NAICSCode}
          validation={yup.string()}
        />
      </div>

      <div className="grid grid-cols-12 gap-4 mt-4">
        <Input
          className="col-span-3"
          label="DUNS Number"
          placeholder="------"
          onChange={onChangeDUNSNumber}
          value={property.DUNSNumber}
          validation={yup.string()}
        />
      </div>

      <div className="grid grid-cols-12 gap-4 mt-4">
        <Dropdown
          className="col-span-3"
          onChange={onChangeBusinessEntity}
          label="Business Entity"
          placeholder="Select"
        />
      </div>

      <br />

      <SecondaryHeader className="mt-4 mb-2">
        Incorperation details
      </SecondaryHeader>

      <div className="grid grid-cols-12 gap-4 mt-4">
        <Dropdown
          className="col-span-3"
          label="State"
          placeholder="Select"
          onChange={onChangeIncorporationState}
        />
      </div>

      <div className="grid grid-cols-12 gap-4 mt-4">
        <DatePicker
          className="col-span-2"
          label="Date"
          onChange={onChangeIncorporationDate}
          value={property.date}
          validation={yup.date()}
        />
      </div>

      <br />
      <SecondaryHeader className="mt-4 mb-2">Physical Address</SecondaryHeader>
      <div className="grid grid-cols-12 gap-4 mt-4">
        <Input
          className="col-span-4"
          label="Address Line 1"
          placeholder="Address 1"
          onChange={onChangePhysicalAddressLine1}
          value={property.physicalAddress?.street}
          validation={yup.string()}
        />
      </div>

      <div className="grid grid-cols-12 gap-4 mt-4">
        <Input
          className="col-span-4"
          label="Address Line 2"
          placeholder="Address 2"
          onChange={onChangePhysicalAddressLine2}
          value={property.physicalAddress?.street2}
          validation={yup.string()}
        />
      </div>

      <div className="grid grid-cols-12 gap-4 mt-4">
        <Input
          className="col-span-2"
          label="City"
          placeholder="City"
          onChange={onChangePhysicalAddressCity}
          value={property.physicalAddress?.city}
          validation={yup.string()}
        />
        <Dropdown
          className="col-span-2"
          onChange={onChangePhysicalAddressState}
          label="State"
          placeholder="Select"
        />
      </div>

      <div className="grid grid-cols-12 gap-4 mt-4">
        <Input
          className="col-span-2"
          label="Zip Code"
          placeholder="Zip Code"
          onChange={onChangePhysicalAddressZipCode}
          value={property.physicalAddress?.zipCode}
          validation={yup
            .number()
            .positive()
            .integer()
            .transform((v, o) => (o === "" ? undefined : v))}
        />
      </div>

      <br />

      {!checked && (
        <>
          <SecondaryHeader className="mt-4 mb-2">
            Mailing Address
          </SecondaryHeader>

          <div className="grid grid-cols-12 gap-4 mt-4">
            <Input
              className="col-span-4"
              label="Address Line 1"
              placeholder="Line 1"
              onChange={onChangeMailingAddressLine1}
              value={property.mailingAddress?.street}
              validation={yup.string()}
            />
          </div>

          <div className="grid grid-cols-12 gap-4 mt-4">
            <Input
              className="col-span-4"
              label="Address Line 2"
              placeholder="Line 2"
              onChange={onChangeMailingAddressLine2}
              value={property.mailingAddress?.street2}
              validation={yup.string()}
            />
          </div>

          <div className="grid grid-cols-12 gap-4 mt-4">
            <Input
              className="col-span-2"
              label="City"
              placeholder="City"
              onChange={onChangeMailingAddressCity}
              value={property.mailingAddress?.city}
              validation={yup.string()}
            />
            <Dropdown
              className="col-span-2"
              onChange={onChangeMailingAddressState}
              label="State"
              placeholder="Select"
            />
          </div>

          <div className="grid grid-cols-12 gap-4 mt-4">
            <Input
              className="col-span-2"
              label="Zip Code"
              placeholder="Zip Code"
              onChange={onChangeMailingAddressZipCode}
              value={property.mailingAddress?.zipCode}
              validation={yup
                .number()
                .positive()
                .integer()
                .transform((v, o) => (o === "" ? undefined : v))}
            />
          </div>

          <br />
        </>
      )}
      <div className="grid grid-cols-12 gap-4 mt-4">
        <Checkbox
          className="col-span-8"
          onChange={onChangeCheckBox}
          checked={checked}
          label="Mailing address is same as Physical Address"
        />
      </div>

      <br />
      {insurances?.map((insurance) => (
        <div key={insurance}>
          <br />
          <SecondaryHeader className="mt-4 mb-2">
            Insurance & Bonds
          </SecondaryHeader>
          <div className="grid grid-cols-12 gap-4 mt-4">
            <Input
              className="col-span-4"
              label="Carrier Name"
              placeholder="Name"
              value={property.insurance?.carrierName}
              validation={yup.string()}
            />
          </div>
          <div className="grid grid-cols-12 gap-4 mt-4">
            <Dropdown
              className="col-span-3"
              label="Insurance Type"
              placeholder="Select"
            />
          </div>
          <div className="grid grid-cols-12 gap-4 mt-4">
            <Input
              className="col-span-3"
              label="Coverage Limit"
              placeholder="Limit"
              value={property.insurance?.coverageLimit}
              validation={yup.string()}
            />
          </div>
          <div className="grid grid-cols-12 gap-4 mt-4">
            <DatePicker
              className="col-span-2"
              label="Certification Expiration"
              value={property.insurance?.certificateExpiration}
              validation={yup.date()}
            />
          </div>
        </div>
      ))}
      <TertiaryButton
        className="col-span-4 mt-4"
        title="+ Add Another"
        onClick={onClickAddNew}
      />
      <br />
      <div className="flex float-right">
        <TertiaryButton className="col-span-1 mr-3" title="Discard" />
        <SecondaryButton className="col-span-1 mr-3" title="Save Draft" />
        <PrimaryButton
          className="col-span-1 mr-3"
          title="Next"
          onClick={handleNextStep}
        />
      </div>
    </div>
  );
};

CompanyDetails.propTypes = {
  /**
   * property currently being created or edited
   */
  // eslint-disable-next-line react/forbid-prop-types
  property: PropTypes.object,
  /**
   * function called to make changes to the property.
   * Generally will be the usePropertyFormReducer
   */
  dispatch: PropTypes.func,
  /**
   * function to handle next step click
   */
  handleNextStep: PropTypes.func,
};

CompanyDetails.defaultProps = {
  property: {},
  dispatch: undefined,
  handleNextStep: undefined,
};

export default CompanyDetails;

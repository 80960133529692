import React from "react";
import TrashButton from "../../../Buttons/TrashButton";

const TaskDeleteButton = ({ handleDeleteClick, canShow }) => {
  // If the user is not allowed to edit the task or downs have permission,
  // don't show the button

  if (!canShow) return null;

  // Otherwise, show the button
  return <TrashButton onClick={handleDeleteClick} />;
};

export default TaskDeleteButton;

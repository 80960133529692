import React from "react";
import PropTypes from "prop-types";
import ProjectEditField from "./ProjectEditField";
import Dropdown from "../Dropdown/Dropdown";
import Widget from "../Widget/Widget";

const dayTypeOptions = {
  calendar: { label: "Calendar Days", value: "calendar" },
  business: { label: "Business Days", value: "business" },
};

const ProjectTimeToNotify = ({ project, isEditing, dispatch }) => {
  const timeToNotify = project?.timeToNotify;

  const handleChange = (key, value) => {
    dispatch({
      type: "edit2",
      key1: "timeToNotify",
      key2: key,
      value,
    });
  };

  return (
    <Widget draggable={false} title="Time to Notify" overflow>
      <div className="flex flex-col w-full">
        {isEditing && (
          <div className="my-4 w-1/2">
            <Dropdown
              options={[
                { label: "Calendar Days", value: "calendar" },
                { label: "Business Days", value: "business" },
              ]}
              value={dayTypeOptions[timeToNotify?.dayType]}
              disableClear
              disableSort
              label="Day Type"
              placeholder="Select Day Type"
              onChange={({ value }) => handleChange("dayType", value)}
            />
          </div>
        )}
        <ProjectEditField
          isEditing={isEditing}
          title="Delays"
          value={
            isEditing
              ? timeToNotify?.delays
              : `${timeToNotify?.delays ?? 0} Days`
          }
          handleChange={(val) => handleChange("delays", val)}
          placeHolder="Enter Amount"
        />
        <ProjectEditField
          isEditing={isEditing}
          title="Unforseen Conditions"
          value={
            isEditing
              ? timeToNotify?.unforseenConditions
              : `${timeToNotify?.unforseenConditions ?? 0} ${
                  timeToNotify?.unforseenConditions === 1 ? "Day" : "Days"
                }`
          }
          handleChange={(val) => handleChange("unforseenConditions", val)}
          placeHolder="Enter Amount"
        />
        <ProjectEditField
          isEditing={isEditing}
          title="Discrepancies"
          value={
            isEditing
              ? timeToNotify?.discrepancies
              : `${timeToNotify?.discrepancies ?? 0} ${
                  timeToNotify?.discrepancies === 1 ? "Day" : "Days"
                }`
          }
          handleChange={(val) => handleChange("discrepancies", val)}
          placeHolder="Enter Amount"
          lastField
        />
      </div>
    </Widget>
  );
};

ProjectTimeToNotify.propTypes = {
  project: PropTypes.shape({
    timeToNotify: PropTypes.shape({}),
  }),
  isEditing: PropTypes.bool,
  dispatch: PropTypes.func,
};

ProjectTimeToNotify.defaultProps = {
  project: undefined,
  isEditing: false,
  dispatch: undefined,
};

export default ProjectTimeToNotify;

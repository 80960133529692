import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useProperties from "../../../hooks/useProperties";
import useProjects from "../../../hooks/useProjects";
import WorkflowData from "../../../helpers/Workflow";
import WorkflowCardsView from "./WorkflowCardsView";

const WorkflowContainer = ({
  isLoading,
  data,
  setSelectedCard,
  handleOpenResolve,
  handleOpenAssociation,
  handleEditWorkflow,
  handleReinitiateWF,
  handleDeleteWF,
  handleCreateAssociatedWF,
  handleFlagWF,
  siteHeaderProps,
  siteHeaderTabs,
  handleAddWorkflow,
  sortActiveCardsByOverdue,
  warningCount,
  alertCount,
  alertToggle,
  warningToggle,
  setWarningToggle,
  setAlertToggle,
  currentUser,
  searchInput,
  handleSearch,
  bannerComponent,
  associatedResource,
  handleWatchWF,
  watchList,
  workflowsCount,
}) => {
  const [properties] = useProperties();
  const { projects } = useProjects();
  const [workflowData, setWorkflowData] = useState([]);

  useEffect(() => {
    const tempArr = data?.map((info) =>
      WorkflowData.formatPayloadForTable(info, properties, projects)
    );

    setWorkflowData(tempArr);
  }, [data, projects, properties]);

  return (
    <WorkflowCardsView
      data={workflowData}
      setSelectedCard={setSelectedCard}
      handleOpenResolve={handleOpenResolve}
      handleOpenAssociation={handleOpenAssociation}
      handleEditWorkflow={handleEditWorkflow}
      handleReinitiateWF={handleReinitiateWF}
      handleDeleteWF={handleDeleteWF}
      handleCreateAssociatedWF={handleCreateAssociatedWF}
      handleFlagWF={handleFlagWF}
      siteHeaderProps={siteHeaderProps}
      siteHeaderTabs={siteHeaderTabs}
      handleAddWorkflow={handleAddWorkflow}
      sortActiveCardsByOverdue={sortActiveCardsByOverdue}
      warningCount={warningCount}
      alertCount={alertCount}
      alertToggle={alertToggle}
      warningToggle={warningToggle}
      setWarningToggle={setWarningToggle}
      setAlertToggle={setAlertToggle}
      currentUser={currentUser}
      searchInput={searchInput}
      handleSearch={handleSearch}
      bannerComponent={bannerComponent}
      associatedResource={associatedResource}
      handleWatchWF={handleWatchWF}
      watchList={watchList}
      workflowsCount={workflowsCount}
      isLoading={isLoading}
    />
  );
};

WorkflowContainer.propTypes = {
  /**
   * data displayed on cards
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      hasAssociatedWorkflows: PropTypes.string,
      changes: PropTypes.arrayOf(
        PropTypes.shape({
          details: PropTypes.string,
          task: PropTypes.string,
          users: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
            })
          ),
        })
      ),
      currentStep: PropTypes.number,
      dueDate: PropTypes.string,
      isFlagged: PropTypes.bool,
      initiationDate: PropTypes.string,
      initiatorName: PropTypes.string,
      resolved: PropTypes.bool,
      status: PropTypes.string,
      steps: PropTypes.string,
      totalStep: PropTypes.number,
      version: PropTypes.string,
      warning: PropTypes.bool,
      workflowName: PropTypes.string,
      containerView: PropTypes.string,
    })
  ),
  /**
   * sets the selected card
   */
  setSelectedCard: PropTypes.func,
  /**
   * function to handle opening the resolve modal
   */
  handleOpenResolve: PropTypes.func,
  handleReinitiateWF: PropTypes.func,
  handleEditWorkflow: PropTypes.func,
  handleDeleteWF: PropTypes.func,
  handleOpenAssociation: PropTypes.func,
  handleCreateAssociatedWF: PropTypes.func,
  handleFlagWF: PropTypes.func,
  handleAddWorkflow: PropTypes.func,
  sortActiveCardsByOverdue: PropTypes.func,
  siteHeaderProps: PropTypes.shape({
    title: PropTypes.string,
    dropdownRoutes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        path: PropTypes.string,
      })
    ),
  }),
  warningCount: PropTypes.number,
  alertCount: PropTypes.number,
  warningToggle: PropTypes.bool,
  alertToggle: PropTypes.bool,
  setWarningToggle: PropTypes.func,
  setAlertToggle: PropTypes.func,
  isLoading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  currentUser: PropTypes.object,
  searchInput: PropTypes.string,
  handleSearch: PropTypes.func,
  bannerComponent: PropTypes.node,
  siteHeaderTabs: PropTypes.arrayOf(PropTypes.node),
  associatedResource: PropTypes.string,
  handleWatchWF: PropTypes.func,
  watchList: PropTypes.arrayOf(PropTypes.string),
  workflowsCount: PropTypes.number,
};

WorkflowContainer.defaultProps = {
  data: [],
  setSelectedCard: undefined,
  handleOpenResolve: undefined,
  handleReinitiateWF: undefined,
  handleEditWorkflow: undefined,
  handleDeleteWF: undefined,
  handleOpenAssociation: undefined,
  handleCreateAssociatedWF: undefined,
  handleFlagWF: undefined,
  handleAddWorkflow: undefined,
  sortActiveCardsByOverdue: undefined,
  siteHeaderProps: {
    title: "Workflows",
    dropdownRoutes: undefined,
  },
  warningCount: 0,
  alertCount: 0,
  warningToggle: false,
  alertToggle: false,
  setWarningToggle: undefined,
  setAlertToggle: undefined,
  currentUser: undefined,
  searchInput: "",
  handleSearch: undefined,
  bannerComponent: undefined,
  siteHeaderTabs: undefined,
  associatedResource: undefined,
  handleWatchWF: undefined,
  watchList: [],
  workflowsCount: 0,
  isLoading: false,
};

export default WorkflowContainer;

import { useQuery } from "react-query";
import { AUTH_TOKEN_STORAGE_KEY } from "../constants";

/**
 * useQueryHook wrapper that will make calls to api
 * if there is a authToken in the global state.
 */
function useAuthenticatedQuery({ queryKey, queryFn, ...options }) {
  // ES Auth Token
  const authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);

  return useQuery({
    queryKey,
    queryFn,
    enabled: (options?.enabled ?? true) && !!authToken,
    ...options,
  });
}

export default useAuthenticatedQuery;

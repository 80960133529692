import cntl from "cntl";
import { PropTypes } from "prop-types";
import React, { useRef } from "react";
import * as yup from "yup";
import Attachments from "../../../../Pages/ServiceRequest/Attachments/index";
import { PRIMARY_DARK_GREEN } from "../../../../constants";
import convertTZ from "../../../../helpers/Date/convertTimezone";
import getRecurrenceSelectValue from "../../../../helpers/Date/getRecurrenceSelectValue";
import AddSpacesAssetsWidget from "../../AddSpacesAssetsWidget";
import PlusCircleButton from "../../Buttons/PlusCircleButton/PlusCircleButton";
import Checkbox from "../../Checkbox/Checkbox";
import DatePicker from "../../DatePicker/DatePicker";
import TimePicker from "../../DatePicker/TimePicker";
import DistroListSelectMembers from "../../DistroList/DistroListSelectMembers";
import DropDownWithPriorityLevel from "../../DropDownWithPriorityLevel";
import Dropdown from "../../Dropdown/Dropdown";
import Input from "../../Input/Input";
import LinksModalWidget from "../../Links/LinksModalWidget";
import ModalMediaWidget from "../../ModalMediaWidget";
import RequestGridViewDistroListCell from "../../Requests/RequestGridView/RequestGridViewDistroListCell";
import Step from "../../Step";
import TagsContainer from "../../Tags/TagsContainer";
import useTaskFormData from "./useTaskFormData";

const modalRow = (showBorder, className) => cntl`
  flex
  flex-row
  ${showBorder && "border-b"}
  col-span-6
  justify-between
  items-center
  pb-4
  pt-4
  ${className}
`;

const recurrenceStringStyle = cntl`
  col-start-3
  col-span-6 
  text-sm 
  text-gray-450
`;

const widgetContainer = {
  border: "1px solid #CACACA",
  borderRadius: "6px",
};

const widgetCN = "px-6 pb-8 pt-4 flex flex-col gap-2";

const divider = "border-b border-gray-250 mt-2 mb-2";

const taskNameInputCN =
  "bg-inputBgGreen pl-4 pr-8 w-full overflow-hidden font-semibold text-gray-300 text-xl";

const modalContainer =
  "flex flex-col relative overflow-y-auto px-1 pt-1 gap-6 pb-6";

const labelWidth = "w-1/4";

const inputWidth = "w-3/4";

const row = "flex flex-row gap-6 w-full";

const labelCN = "font-semibold text-gray-450";

export default function CreateEditTaskForm({
  form,
  dispatch,
  setInputValid,
  setChangesMade,
  sopOptions,
  disableAssociation,
  initialTask,
  setAssociation,
  associationLock,
  assetLock,
  spaceLock,
}) {
  const recurrenceDropDownRef = useRef(null);
  const {
    inviteesDD,
    allDay,
    projectDD,
    propertiesDD,
    recurrenceString,
    currentInvitees,
    inviting,
    filesState,
    taskHasTags,
    spacesOrAssetsAvailable,
    isNewTask,
    isOverdueTask,
    isSingleTask,
    createdBy,
    repetitionOptions,
    removeFormFileDuplicates,
    handlePriorityUpdate,
    handleDescriptionChange,
    handleFilesToAdd,
    handleFilesToRemove,
    setOpenInvite,
    handleAddInvitee,
    handleResourceDDValue,
    handleRemoveInvitee,
    handleRecurrenceChange,
    handleStartDate,
    handleStartTime,
    handleEndDate,
    handleEndTime,
    handleEventName,
    toggleAllDay,
    handleAssociation,
    handleAddStep,
    handleStepEdit,
    handleStepRemove,
    handleSelectSpaces,
    handleRemoveSpaces,
    handleSelectAssets,
    handleRemoveAssets,
  } = useTaskFormData({
    dispatch,
    form,
    setInputValid,
    setChangesMade,
    recurrenceDropDownRef,
    initialTask,
    setAssociation,
    associationLock,
    assetLock,
    spaceLock,
  });
  // Styling for Tags section:
  // When Task is new `Created By` row is hidden and move `Tags` to `Created By` updating the top position
  let tagsSectionClassName = `${modalRow(false)} flex w-1/2`;

  if (isNewTask) {
    if (taskHasTags) {
      tagsSectionClassName += " items-stretch -mt-20";
    } else {
      // TODO: When `Related To` is enabled use ` -mt-36` instead
      tagsSectionClassName += " -mt-32";
    }
  } else if (taskHasTags) {
    tagsSectionClassName += " items-stretch";
  }

  return (
    <div id="first" className={modalContainer}>
      <Input
        inputClassName={taskNameInputCN}
        inputContainerClassName="bg-inputBgGreen shadow-light-lift"
        placeholder="Name"
        value={form?.name}
        validation={yup.string().trim().required("Name cannot be blank.")}
        onChange={(e) => handleEventName(e)}
        autoFocus
      />

      <div className={widgetCN} style={widgetContainer}>
        {/* Row: Association and Timezone */}
        <div className={row}>
          {/* Association */}
          <div className={`${modalRow(true)} flex w-1/2`}>
            <div className={labelWidth}>
              <p className={labelCN}>Association</p>
            </div>
            <div className={inputWidth}>
              <Dropdown
                customErrorMessage={
                  form?.association ?? disableAssociation
                    ? false
                    : "Association must be selected."
                }
                options={[
                  { label: "Projects", options: projectDD },
                  { label: "Properties", options: propertiesDD },
                ]}
                onChange={(value) => handleAssociation(value)}
                placeholder="Select"
                value={handleResourceDDValue()}
                isDisabled={disableAssociation}
              />
            </div>
          </div>

          {/* Timezone */}
          <div className={`${modalRow(true)} flex w-1/2`}>
            <div className={labelWidth}>
              <p className={labelCN}>Timezone</p>
            </div>
            <div className={inputWidth}>
              <p>{form?.timezone ?? "N/A"}</p>
            </div>
          </div>
        </div>

        {/* Row: Start and End */}
        <div className={row}>
          {/** Start date */}
          <div className={`${modalRow(true)} flex w-1/2`}>
            <div className={labelWidth}>
              <p className={labelCN}>Start</p>
            </div>
            <div className="flex flex-row w-3/4 gap-1">
              <div style={{ width: "50%" }}>
                <DatePicker
                  onChange={handleStartDate}
                  className="bg-input w-full"
                  value={convertTZ(form?.startDate, form.timezone)}
                  validation={yup.string().required()}
                  dateFormat="MMM dd, yyyy"
                  placeholder="Start"
                />
              </div>

              {!allDay && (
                <div style={{ width: "35%" }}>
                  <TimePicker
                    className="bg-input"
                    customPadding="pr-0"
                    isLast
                    value={convertTZ(form?.startDate, form.timezone)}
                    validation={yup.string().required()}
                    timeIntervals={5}
                    onChange={(e) => handleStartTime(e)}
                    disabled={!form?.startDate || allDay}
                    manualEntry={false}
                    format="h:mma"
                    placeholder="Time"
                  />
                </div>
              )}

              <div
                className="flex flex-row gap-1 items-center justify-end"
                style={{ width: "32%" }}
              >
                <Checkbox checked={allDay} onChange={toggleAllDay} size="5" />

                <p className="text-sm text-gray-450">All Day</p>
              </div>
            </div>
          </div>

          {/** End date */}
          <div className={`${modalRow(true)} flex w-1/2`}>
            <div className={labelWidth}>
              <p className={labelCN}>End</p>
            </div>

            <div className="flex flex-row w-3/4 gap-1">
              <DatePicker
                onChange={handleEndDate}
                className="bg-input w-1/2"
                value={convertTZ(form?.endDate, form.timezone)}
                validation={yup.string().required()}
                dateFormat="MMM dd, yyyy"
                placeholder="End"
              />

              {!allDay && (
                <div className="w-1/3">
                  <TimePicker
                    className="bg-input"
                    customPadding="pr-0"
                    isLast
                    value={convertTZ(form?.endDate, form.timezone)}
                    validation={yup.string().required()}
                    timeIntervals={5}
                    onChange={(e) => handleEndTime(e)}
                    disabled={!form?.endDate || allDay}
                    manualEntry={false}
                    format="h:mma"
                    placeholder="Time"
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Row: Repeat and Priority */}
        <div className={row}>
          {/* Repeat */}
          <div
            className={`${modalRow(true)} flex w-1/2 ${
              recurrenceString ? "items-stretch" : ""
            }`}
          >
            <div className={labelWidth}>
              <p className={labelCN}>Repeat</p>
            </div>
            <div className={inputWidth}>
              <Dropdown
                containerRef={recurrenceDropDownRef}
                tabIndex={0}
                name="recurrence"
                placeholder="Select"
                className="w-full"
                options={repetitionOptions}
                value={getRecurrenceSelectValue(form?.recurrence)}
                onChange={(val) => handleRecurrenceChange(val)}
                isDisabled={isOverdueTask && !isSingleTask}
                disableSort
                disableClear
              />

              {!!recurrenceString && (
                <span className={recurrenceStringStyle}>
                  <p
                    className={`text-gray-450 text-sm ${
                      isNewTask ? "mt-2" : ""
                    }`}
                  >
                    {recurrenceString}
                  </p>
                </span>
              )}
            </div>
          </div>

          {/** Priority */}
          <div
            className={`${modalRow(true)} flex w-1/2 ${
              recurrenceString ? "items-stretch" : ""
            }`}
          >
            <div className={labelWidth}>
              <p className={labelCN}>Priority</p>
            </div>
            <div className={inputWidth}>
              <DropDownWithPriorityLevel
                priority={form.priority}
                onChangePriority={handlePriorityUpdate}
              />
            </div>
          </div>
        </div>

        {/** Row: Only Description for new Task */}
        {isNewTask && (
          <div className="flex flex-row gap-6 w-1/2">
            <div
              className={`${modalRow(true)} flex w-full pr-3 ${
                isNewTask && taskHasTags ? "items-stretch" : ""
              }`}
            >
              <div className={labelWidth}>
                <p className={labelCN}>Description</p>
              </div>

              <div className={inputWidth}>
                <Input
                  className="bg-inputBgGreen rounded"
                  inputClassName="bg-inputBgGreen w-full p-2"
                  placeholder="Description"
                  value={form.description}
                  onChange={(value) => handleDescriptionChange(value)}
                />
              </div>
            </div>
          </div>
        )}

        {/** Row: Description and Created By for an existing Task */}
        {!isNewTask && (
          <div className={row}>
            {/* Description */}
            <div
              className={`${modalRow(true)} flex w-1/2 ${
                isNewTask && taskHasTags ? "items-stretch" : ""
              }`}
            >
              <div className={labelWidth}>
                <p className={labelCN}>Description</p>
              </div>

              <div className={inputWidth}>
                <Input
                  className="bg-inputBgGreen rounded"
                  inputClassName="bg-inputBgGreen w-full p-2"
                  placeholder="Description"
                  value={form.description}
                  onChange={(value) => handleDescriptionChange(value)}
                />
              </div>
            </div>

            <div className={`${modalRow(true)} flex w-1/2`}>
              <div className={labelWidth}>
                <p className={labelCN}>Created By</p>
              </div>

              <div className={inputWidth}>
                <Input
                  placeholder="Name"
                  className="bg-inputBgGreen rounded"
                  inputClassName="bg-inputBgGreen w-full p-2"
                  value={createdBy}
                  disabled
                  onChange={() => {}}
                />
              </div>
            </div>
          </div>
        )}

        {/** When `Related to` is enabled remove this block */}
        {!isNewTask && (
          <div className={row}>
            <div
              className={`${modalRow(false)} flex w-1/2 pr-3 ${
                taskHasTags ? "items-stretch" : ""
              }`}
            >
              <div className={labelWidth}>
                <p className={labelCN}>Tags</p>
              </div>
              <div className={inputWidth}>
                <TagsContainer
                  isEditing
                  menuPlacement="bottom"
                  className="h-full flex flex-row flex-wrap"
                  dropdownClassName="self-center"
                  fullWidth
                  tagsWrapperClassName="pt-4 flex flex-wrap"
                  dispatch={dispatch}
                  resource={form}
                  placeholder="Add Tags"
                />
              </div>
            </div>
          </div>
        )}

        {/* Row: Related and Tags: Show tags in the same row when is an existing Task */}
        {isNewTask && (
          <div className={row}>
            <div
              className={`${modalRow(false)} flex w-1/2 ${
                taskHasTags ? "items-stretch" : ""
              }`}
            >
              {/** TODO: Enable when `Related to` is supported in Backend */}
              {/* <div className={labelWidth}>
              <p className={labelCN}>Related To</p>
            </div>

            <div className={inputWidth}>
              <PlusCircleButton
                title="Add Workflow or Service Request"
                className="col-span-2"
                disabled={!form?.association || inviting.state}
                onClick={() => setOpenInvite(true)}
                style={{ color: PRIMARY_DARK_GREEN }}
              />
            </div> */}
            </div>

            {/* Tags Section */}
            <div className={tagsSectionClassName}>
              <div className={labelWidth}>
                <p className={labelCN}>Tags</p>
              </div>
              <div className={inputWidth}>
                <TagsContainer
                  isEditing
                  menuPlacement="bottom"
                  className="h-full flex flex-row flex-wrap"
                  dropdownClassName="self-center"
                  fullWidth
                  tagsWrapperClassName="pt-4 flex flex-wrap"
                  dispatch={dispatch}
                  resource={form}
                  placeholder="Add Tags"
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="flex gap-4">
        <div className="w-1/2 flex flex-col gap-3">
          <div className={widgetCN} style={widgetContainer}>
            {/* Assignees */}
            <p className={labelCN}>Assign To</p>

            {currentInvitees.length !== 0 && (
              <RequestGridViewDistroListCell
                distroList={currentInvitees}
                hideTile
                handleDeleteFromDistro={(val) => handleRemoveInvitee(val)}
              />
            )}

            {inviting.state && (
              <DistroListSelectMembers
                members={inviteesDD}
                setDistroSelectOpen={setOpenInvite}
                selectedMembers={currentInvitees}
                onAdd={(val) => handleAddInvitee(val)}
                onRemove={(val) => handleRemoveInvitee(val)}
                title="Assignees"
                showMemberPill
                placeHolder="Search Assignees"
              />
            )}

            {!currentInvitees.length && (
              <p className="text-gray-200 text-sm italic">No one assigned</p>
            )}

            <PlusCircleButton
              title="Assign"
              className="col-span-2 pt-3"
              disabled={!form?.association || inviting.state}
              onClick={() => setOpenInvite(true)}
              style={{ color: PRIMARY_DARK_GREEN }}
            />

            {form?.association && <div className={divider} />}

            {/** Assets and Spaces */}
            {spacesOrAssetsAvailable && (
              <>
                <div className="">
                  <AddSpacesAssetsWidget
                    form={form}
                    handleSelect={handleSelectSpaces}
                    handleRemove={handleRemoveSpaces}
                    resource="spaces"
                    isEditing
                    isModal
                  />
                </div>
                <div className="mt-2">
                  <AddSpacesAssetsWidget
                    form={form}
                    handleSelect={handleSelectAssets}
                    handleRemove={handleRemoveAssets}
                    resource="assets"
                    isEditing
                    isModal
                  />
                </div>
              </>
            )}
          </div>

          {/** Media */}
          <div className={widgetCN} style={widgetContainer}>
            <p className={labelCN}>Media</p>

            <ModalMediaWidget
              noTitle
              requestForm={removeFormFileDuplicates()}
              isEditing
              filesState={filesState}
              handleFilesToAdd={handleFilesToAdd}
              handleFilesToRemove={handleFilesToRemove}
            />
          </div>

          {/** Attachments */}
          <div className={widgetCN} style={widgetContainer}>
            <p className={`${labelCN} pb-4`}>Attachments</p>

            <Attachments
              noTitle
              isEditing
              requestForm={removeFormFileDuplicates()}
              filesState={filesState}
              handleFilesToAdd={handleFilesToAdd}
              handleFilesToRemove={handleFilesToRemove}
              documentTypeFilter="Documents"
            />
          </div>
        </div>

        <div className="w-1/2 flex flex-col gap-3">
          {/** Steps  */}
          <div className={widgetCN} style={widgetContainer}>
            <span className={`${labelCN} pb-4`}>Steps</span>

            {!!form?.steps?.length && (
              <div className="flex flex-col gap-2">
                {form?.steps?.map((item, index) => {
                  return (
                    <Step
                      index={index}
                      key={`${item.id}-step`}
                      form={form}
                      handleStepEdit={handleStepEdit}
                      handleAddStep={handleAddStep}
                      handleStepRemove={handleStepRemove}
                      sopOptions={sopOptions}
                    />
                  );
                })}
              </div>
            )}
            {
              <div className="flex">
                <PlusCircleButton
                  title="Add Step"
                  className="m-0"
                  onClick={handleAddStep}
                  style={{ color: PRIMARY_DARK_GREEN }}
                />
              </div>
            }
          </div>

          {/** Links  */}
          <div className={widgetCN} style={widgetContainer}>
            <LinksModalWidget
              editing
              resource={form}
              loading={false}
              dispatch={dispatch}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

CreateEditTaskForm.propTypes = {
  form: PropTypes.shape({
    invitees: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    association: PropTypes.string,
    reminders: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    files: PropTypes.arrayOf(PropTypes.shape({})),
    images: PropTypes.arrayOf(PropTypes.shape({})),
    videos: PropTypes.arrayOf(PropTypes.shape({})),
    metadata: PropTypes.shape({
      createdAt: PropTypes.string,
    }),
    recurrence: PropTypes.oneOf(PropTypes.shape({}), PropTypes.string),
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        sop: PropTypes.string,
      })
    ),
    timezone: PropTypes.string,
    priority: PropTypes.string,
    spaces: PropTypes.arrayOf(PropTypes.string),
    assets: PropTypes.arrayOf(PropTypes.string),
  }),
  dispatch: PropTypes.func,
  setInputValid: PropTypes.func,
  sopOptions: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ),
  /**
   * track changes to form
   */
  setChangesMade: PropTypes.func,
  initialTask: PropTypes.shape({}),
  setAssociation: PropTypes.func,
  disableAssociation: PropTypes.bool,
  associationLock: PropTypes.string,
  assetLock: PropTypes.string,
  spaceLock: PropTypes.string,
};

CreateEditTaskForm.defaultProps = {
  disableAssociation: false,
  form: undefined,
  dispatch: undefined,
  setInputValid: () => {},
  setChangesMade: () => {},
  sopOptions: [],
  initialTask: {},
  setAssociation: () => {},
  associationLock: undefined,
  assetLock: undefined,
  spaceLock: undefined,
};

import React, { useEffect, useState } from "react";
import * as yup from "yup";
import cntl from "cntl";
import PropTypes from "prop-types";
import Dropdown from "../Dropdown/Dropdown";
import RadioButton from "../Buttons/RadioButton";
import BaseButton from "../Buttons/BaseButton";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import SearchLogo from "../../assets/images/magnify_glass_icon.svg";
import deleteGreeenIcon from "../../assets/images/collapseIcon.svg";
import Input from "../Input/Input";
import {
  EMPLOYEE_TYPES,
  MEMBER_SALARY_OPTIONS,
  PTO_CATEGORY,
} from "../../../constants";
import MemberInviteeProps from "../../../props/MemberInviteeProps";
import {
  activeCurrencyFormatter,
  getFullName,
} from "../../../helpers/Formatters";
import Avatar from "../Message/Avatar";
import SelectOptionsWithUpdates from "../SelectUserInterface/SelectOptionsWithUpdates";
import DatePicker from "../DatePicker/DatePicker";

const headerCN = cntl`
  text-lg
  font-semibold
`;

const internalBarCN = cntl`
  lg:flex
  flex
  flex-initial
`;

const textCN = cntl`
  ml-2
  lg:flex
  flex-initial
  flex
  text-gray-200
  italic
`;

const tertiaryButtonCN = (selected, className) => cntl`
  ${selected ? cntl`bg-brandGreen` : cntl`bg-white`}
  px-2
  py-1
  rounded-lg
  border
  ${className}
`;

const UserLabel = ({ avatar, name, label, company }) => {
  return (
    <div className="flex flex-row">
      {avatar?.thumbnailUrl && (
        <Avatar
          name={getFullName(name)}
          avatar={avatar}
          isExternalUser
          className="mr-6"
        />
      )}
      {!avatar?.thumbnailUrl && (
        <Avatar name={getFullName(name)} isExternalUser className="mr-6" />
      )}
      <div className="flex flex-col">
        <div className="font-semibold">{label}</div>
        <div className="font-normal text-sm text-gray-400">{company}</div>
      </div>
    </div>
  );
};

const SearchLabel = () => {
  return (
    <div className={internalBarCN}>
      <img src={SearchLogo} alt="" />
      <p className={textCN}>Search existing contacts</p>
    </div>
  );
};

const formatOptionLabel = ({ label, company, avatar, name }) => (
  <div>
    {(label && (
      <UserLabel avatar={avatar} label={label} company={company} name={name} />
    )) || <SearchLabel />}
  </div>
);

const AddExistingMemberForm = ({
  invitee,
  onRemoveInvitee,
  onChangeEmployee,
  onChangeIsExisting,
  onChangeRole,
  onChangeEmail,
  onChangePhone,
  onChangeInvitee,
  contacts,
  emailError,
  onChangeEmployeeType,
  onChangeAnnualVacation,
  onChangeSalaryType,
  onChangeSalary,
  roles,
  states,
  onChangeEmployeeState,
  onChangeEmployeeId,
  onChangeHireDate,
  hideEmployeeDetails,
  ptoCategory,
  directReportOptions,
  onDirectReportChange,
}) => {
  const [salaryTypes, setSalaryTypes] = useState(MEMBER_SALARY_OPTIONS);
  const [selectedDirectReport, setSelectedDirectReport] = useState([]);

  useEffect(() => {
    if (selectedDirectReport?.length > 0) {
      const newDirectReport = { user: [], role: [] };
      selectedDirectReport?.forEach((dr) => {
        if (dr?.value?.startsWith("User/"))
          newDirectReport.user.push(dr?.value);
        else if (dr?.value?.startsWith("Role/"))
          newDirectReport.role.push(dr?.value);
      });

      onDirectReportChange(newDirectReport);
    }
  }, [onDirectReportChange, selectedDirectReport]);

  useEffect(() => {
    if (invitee?.employeeType) {
      if (invitee?.employeeType?.value === "exempt") {
        const annualType = MEMBER_SALARY_OPTIONS.find(
          (opt) => opt.value === "annual"
        );
        setSalaryTypes([annualType]);
        onChangeSalaryType(annualType);
      } else {
        const hourlyType = MEMBER_SALARY_OPTIONS.find(
          (opt) => opt.value === "hourly"
        );
        setSalaryTypes([hourlyType]);
        onChangeSalaryType(hourlyType);
      }
    }
  }, [invitee?.employeeType, onChangeSalaryType]);

  return (
    <div>
      {(invitee?.value && (
        <div className="border rounded-md p-4">
          <div className="flex flex-col">
            <div className="flex justify-between">
              <div className="flex flex-row">
                {(invitee?.avatar?.thumbnailUrl && (
                  <Avatar
                    name={getFullName(invitee?.name)}
                    avatar={invitee?.avatar}
                    className="w-20 h-20 mr-6 border-4"
                    isExternalUser
                  />
                )) || (
                  <Avatar
                    name={getFullName(invitee?.name)}
                    className="w-20 h-20 mr-6 border-4"
                    fontClassName="text-4xl"
                    avatar={invitee?.avatar?.thumbnailUrl}
                    isExternalUser
                  />
                )}
                <div className="px-2 pt-3">
                  <h2 className={headerCN}>{invitee?.label}</h2>
                  <h2 className="text-sm text-gray-300">{invitee?.company}</h2>
                </div>
              </div>

              <BaseButton
                iconLeft={
                  <img className="w-5 h-5" src={deleteGreeenIcon} alt="" />
                }
                className="mr-2 pb-8"
                onClick={onRemoveInvitee}
              />
            </div>
            <div className="flex flex-row py-6">
              <div className="w-1/2 pr-4">
                <Dropdown
                  label="Email"
                  options={invitee?.contactPoint
                    ?.filter((cp) => cp.system === "Email")
                    ?.map((cp) => ({
                      label: `${cp.use} - ${cp.value}`,
                      value: cp.value,
                    }))}
                  value={invitee?.email}
                  onChange={onChangeEmail}
                  subText="This will also be used as the member's username at login"
                  validation={yup.mixed().required()}
                />
                <p className="text-red-500 text-sm">{emailError}</p>
              </div>
              <div className="w-1/3 pr-4">
                <Dropdown
                  label="Mobile"
                  options={invitee?.contactPoint
                    ?.filter((cp) => cp.system === "Phone")
                    ?.map((cp) => ({
                      label: `${cp.use} - ${cp.value}`,
                      value: cp.value,
                    }))}
                  value={invitee?.phone}
                  onChange={onChangePhone}
                  validation={yup.mixed().required()}
                />
              </div>
              <div className="w-1/4">
                <Dropdown
                  label="Member Role"
                  options={roles}
                  value={invitee?.role}
                  onChange={onChangeRole}
                  validation={yup.mixed().required()}
                />
              </div>
            </div>
            <div className="flex flex-col">
              <p className="font-medium">Invite as employee?</p>
              <div className="flex flex-row">
                <RadioButton
                  label="Yes"
                  isChecked={invitee?.isEmployee}
                  onChange={() => onChangeEmployee(true)}
                />
                <RadioButton
                  label="No"
                  isChecked={!invitee?.isEmployee}
                  onChange={() => onChangeEmployee(false)}
                />
              </div>
              {invitee?.isEmployee && (
                <div className="grid grid-flow-row grid-cols-4 gap-3 mt-7 items-start">
                  {ptoCategory === PTO_CATEGORY.location && (
                    <Dropdown
                      label="State of Employment"
                      options={states}
                      value={invitee?.stateOfEmployment}
                      onChange={onChangeEmployeeState}
                      validation={yup.mixed().required()}
                    />
                  )}
                  <Input
                    label="Employee ID"
                    value={invitee?.employeeId}
                    placeholder="Id"
                    onChange={onChangeEmployeeId}
                    validation={yup.string().trim()}
                  />
                  <SelectOptionsWithUpdates
                    dropDownLabel="Select Direct Report"
                    options={directReportOptions}
                    selectedValues={selectedDirectReport}
                    setSelectedValues={setSelectedDirectReport}
                  />
                  <DatePicker
                    className=""
                    label="Hire Date"
                    value={invitee?.hireDate}
                    onChange={onChangeHireDate}
                    validation={yup.date().required()}
                  />
                </div>
              )}
            </div>
          </div>
          {invitee?.isEmployee && ptoCategory === PTO_CATEGORY.location && (
            <div className="grid grid-flow-row grid-cols-2 gap-3 mt-7">
              {!hideEmployeeDetails && (
                <>
                  <Dropdown
                    label="Employee Type"
                    options={EMPLOYEE_TYPES}
                    value={invitee?.employeeType}
                    onChange={onChangeEmployeeType}
                    validation={yup.mixed().required()}
                  />
                  <Input
                    placeholder="Days"
                    label="Annual Vacation Days"
                    value={invitee?.annualVacation}
                    onChange={onChangeAnnualVacation}
                    validation={yup.number().integer().min(0)}
                  />
                  <Dropdown
                    label="Salary or Hourly Pay"
                    options={salaryTypes}
                    value={invitee?.salaryType}
                    onChange={onChangeSalaryType}
                    className="pt-2"
                    validation={yup.mixed().required()}
                  />
                  <Input
                    placeholder="Salary"
                    label={invitee?.salaryType?.label || "Annual Salary"}
                    isDollarValue
                    value={activeCurrencyFormatter(invitee?.salary)}
                    onChange={onChangeSalary}
                    className="py-2"
                    validation={yup.number().min(0).required()}
                  />
                </>
              )}
            </div>
          )}
        </div>
      )) || (
        <div className="border rounded-md p-4">
          <div className="flex justify-between">
            <div className="flex flex-row">
              <SecondaryHeader>Invite Contact:</SecondaryHeader>
              <div className="flex space-x-2 pl-2">
                <BaseButton
                  className={tertiaryButtonCN(invitee?.isExisting)}
                  title="EXISTING"
                  colorCN={invitee?.isExisting ? "text-white" : "text-black"}
                  onClick={() => onChangeIsExisting(true)}
                />
                <BaseButton
                  className={tertiaryButtonCN(!invitee?.isExisting, "px-4")}
                  title="NEW"
                  colorCN={!invitee?.isExisting ? "text-white" : "text-black"}
                  onClick={() => onChangeIsExisting(false)}
                />
              </div>
            </div>
            <BaseButton
              iconLeft={
                <img className="w-5 h-5" src={deleteGreeenIcon} alt="" />
              }
              className="mr-2"
              onClick={onRemoveInvitee}
            />
          </div>
          <div className="w-1/2 py-4">
            <Dropdown
              options={contacts}
              value={invitee}
              onChange={onChangeInvitee}
              formatOptionLabel={formatOptionLabel}
              hideIndicator
              hideDropdownIndicator
            />
          </div>
        </div>
      )}
    </div>
  );
};
AddExistingMemberForm.propTypes = {
  invitee: MemberInviteeProps.SHAPE,
  onChangeIsExisting: PropTypes.func,
  onRemoveInvitee: PropTypes.func,
  onChangeEmail: PropTypes.func,
  onChangePhone: PropTypes.func,
  emailError: PropTypes.string,
  onChangeRole: PropTypes.func,
  onChangeEmployee: PropTypes.func,
  onChangeEmployeeType: PropTypes.func,
  onChangeHireDate: PropTypes.func,
  onChangeAnnualVacation: PropTypes.func,
  onChangeSalaryType: PropTypes.func,
  onChangeSalary: PropTypes.func,
  onChangeInvitee: PropTypes.func,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })
  ),
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  hideEmployeeDetails: PropTypes.bool,
  states: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onChangeEmployeeState: PropTypes.func,
  onChangeEmployeeId: PropTypes.func,
  ptoCategory: PropTypes.string,
  directReportOptions: PropTypes.shape({}),
  onDirectReportChange: PropTypes.func,
};

AddExistingMemberForm.defaultProps = {
  invitee: undefined,
  onChangeIsExisting: undefined,
  onRemoveInvitee: undefined,
  onChangeEmail: undefined,
  onChangePhone: undefined,
  emailError: undefined,
  onChangeInvitee: undefined,
  onChangeRole: undefined,
  onChangeHireDate: undefined,
  onChangeEmployee: undefined,
  onChangeEmployeeType: undefined,
  onChangeAnnualVacation: undefined,
  onChangeSalaryType: undefined,
  onChangeSalary: undefined,
  contacts: undefined,
  roles: [],
  hideEmployeeDetails: false,
  states: [],
  onChangeEmployeeState: undefined,
  onChangeEmployeeId: undefined,
  ptoCategory: undefined,
  directReportOptions: {},
  onDirectReportChange: undefined,
};

UserLabel.propTypes = {
  avatar: PropTypes.shape({
    thumbnailUrl: PropTypes.string,
  }).isRequired,
  label: PropTypes.string.isRequired,
  company: PropTypes.string,
  name: PropTypes.string.isRequired,
};

UserLabel.defaultProps = {
  company: undefined,
};

export default AddExistingMemberForm;

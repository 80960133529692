import { ProjectAPI } from "@griffingroupglobal/eslib-api";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import removeDuplicateTsEntries from "../../../../../helpers/Timesheets/removeDuplicateTsEntryies";
import {
  useProjects,
  useProjectsOverview,
} from "../../../../../hooks/projects";
import useOutsideAlerter from "../../../../../hooks/useOutsideAlerter";
import usePagePersistance from "../../../../../hooks/usePagePersistence";
import { useAppState } from "../../../../../state/appState";
import { toastError } from "../../../../../stories/Components/Toast/Toast";
import circleDarkGreen from "../../../../../stories/assets/images/circleDarkGreen.svg";

const useEmployeeTimesheet = ({ handleEntryChange }) => {
  const [{ financialsConfiguration, userDict }] = useAppState();

  const { projectDict } = useProjectsOverview();
  const { data: projects } = useProjects();

  const [projectsState, setProjectsState] = useState({});
  const [projectsDD, setProjectsDD] = useState([]);
  const dropDownStyles = "col-span-1 text-gray-700 text-sm font-light";
  const [toolTip, setToolTip] = useState({ open: false, id: null });
  const { pageState } = usePagePersistance();

  const wrapperRef = useRef(null);

  // a combo is a combination of project, rate, and category

  useEffect(() => {
    (async () => {
      try {
        const { data } = await ProjectAPI.getWOP("$gettimesheetrates");

        setProjectsState(data);
      } catch (error) {
        toastError("There was an error getting the timesheet rates");
      }
    })();
  }, []);

  useOutsideAlerter(wrapperRef, () => {
    setToolTip({ open: false, id: null });
  });

  // TODO Once the infinite scroll feature is in production, we should optimize this code on the backend to enhance its performance.
  useEffect(() => {
    const projectsWithFlatMembers = projects
      ?.map((project) => {
        return {
          ...project,
          members: project.members?.map((member) => member.user),
        };
      })
      .filter((project) => {
        return (
          project.members?.includes(`User/${pageState?.timesheet?.userId}`) &&
          projectsState[`Project/${project.id}`]?.status === "active"
        );
      })
      .map((project) => {
        return {
          label: project.name,
          value: `Project/${project.id}`,
        };
      });

    setProjectsDD(projectsWithFlatMembers);
  }, [pageState, projects, projectsState]);

  const ratesDD = useMemo(() => {
    return financialsConfiguration?.financials?.rateSheet?.rates?.map(
      (rate) => {
        return {
          label: rate?.category,
          value: rate?.id,
        };
      }
    );
  }, [financialsConfiguration?.financials?.rateSheet?.rates]);

  const filterRatesByProject = useCallback(
    (projectReference) => {
      if (projectsState[projectReference]?.useall === true) {
        const projectsRatesList = projectDict[
          projectReference
        ].rateSheet.rates.map((rate) => {
          return {
            label: rate?.category,
            value: rate?.id,
          };
        });

        return projectsRatesList;
      }

      if (projectsState[projectReference]?.codes?.length === 0) return [];
      if (projectsState[projectReference]?.codes === undefined) return [];

      const ratesForProject = projectsState[projectReference]?.codes;
      const filteredRatesDD = ratesForProject?.map((rate) => {
        return {
          label: rate?.rate?.category,
          value: rate?.rate?.id,
        };
      });

      const filteredWithoutDuplicates = removeDuplicateTsEntries(
        filteredRatesDD,
        "value"
      );

      return filteredWithoutDuplicates;
    },
    [projectDict, projectsState]
  );

  const csiCodesMap = useMemo(() => {
    const csCodeMappingObj =
      financialsConfiguration?.financials?.csiCodeMappingObject;

    const codesMap = {};

    Object.entries(csCodeMappingObj)?.forEach(([key, value]) => {
      codesMap[key.split(" ").join("-")] = value;
    });

    return codesMap;
  }, [financialsConfiguration?.financials]);

  const categoryDD = useMemo(() => {
    const csCodeMapping = financialsConfiguration?.financials?.csiCodeMapping;
    const categoryDDPrep = [];

    csCodeMapping?.forEach((div) => {
      const categoryDivision = {
        label: div?.description,
        options: [],
      };

      div.csiCodes.forEach((csi) => {
        csi.subCodes.forEach((sub) => {
          const fullCode = `${div.division}-${csi.code}-${sub.code}`;
          const description = csiCodesMap[fullCode];
          const csiCodeFormatted = {
            label: `${description}`,
            value: fullCode,
          };

          categoryDivision.options.push(csiCodeFormatted);
        });
      });

      categoryDDPrep.push(categoryDivision);
    });

    return categoryDDPrep;
  }, [csiCodesMap, financialsConfiguration?.financials?.csiCodeMapping]);

  const filteredCategoriesByProjectRate = useCallback(
    (projectRef, rate) => {
      if (projectsState[projectRef]?.useall) return categoryDD;
      if (projectsState[projectRef]?.codes === undefined) return categoryDD;

      const projectCodes = projectsState[projectRef]?.codes || [];

      const categoriesByRate = projectCodes.filter(
        (code) => code.rate.id === rate
      );

      const categoryMap = {};

      categoriesByRate?.forEach((category) => {
        if (!categoryMap[category?.divisionDescription]) {
          categoryMap[category?.divisionDescription] = [category];
        } else {
          categoryMap[category?.divisionDescription].push(category);
        }
      });

      const categoryDDFormatted = Object.entries(categoryMap).map(
        ([key, value]) => {
          const formattedOptions = value.map((item) => {
            return {
              label: item?.codeDescription,
              value: item?.key,
            };
          });

          const formattedWithNonDuplicates = removeDuplicateTsEntries(
            formattedOptions,
            "label"
          );

          return {
            label: key,
            options: formattedWithNonDuplicates,
          };
        }
      );

      const filteredWithoutDuplicates = removeDuplicateTsEntries(
        categoryDDFormatted,
        "codeDescription"
      );

      return filteredWithoutDuplicates;
    },
    [categoryDD, projectsState]
  );

  const formatEntryValue = useCallback((value) => {
    if (value < 0) return 0;
    if (Number.isNaN(value)) return 0;
    if ((value / 60).toString() === "NaN") return 0;

    if (typeof value === "string" && value.slice(-2) === ".0") {
      return `${(value / 60).toString()}.`;
    }

    return (value / 60).toString();
  }, []);

  const roundEntryToNearestQuarter = useCallback(
    (entry, key) => {
      const value = entry.value / 60;
      const roundedValue = Math.round(value * 4) / 4;

      handleEntryChange(roundedValue.toString(), entry, key);
    },
    [handleEntryChange]
  );

  const showActiveIcon = (entry) => {
    const showActiveIconCriteria = entry?.note || entry.isBillable === false;
    if (showActiveIconCriteria) return circleDarkGreen;
    return null;
  };

  const isBeforeHire = (entryDate) => {
    const userRef = `User/${pageState?.timesheet?.userId}`;
    const user = userDict[userRef];
    const hireDate = user?.employeeInfo?.hireDate;

    if (!hireDate) return false;

    const hireDateStartOfDay = moment(hireDate).startOf("day");
    const hireIsAfterEntry = moment(hireDateStartOfDay).isAfter(entryDate);

    if (hireIsAfterEntry === true) return true;

    return false;
  };

  return {
    dropDownStyles,
    toolTip,
    projectsDD,
    projectsState,
    wrapperRef,
    ratesDD,
    csiCodesMap,
    categoryDD,
    projectDict,
    financialsConfiguration,
    isBeforeHire,
    setToolTip,
    formatEntryValue,
    filterRatesByProject,
    showActiveIcon,
    filteredCategoriesByProjectRate,
    roundEntryToNearestQuarter,
  };
};

export default useEmployeeTimesheet;

import cntl from "cntl";
import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./IconDropdownButton.css";
import uploadDarkenedGreenIcon from "../../../assets/images/uploadDarkenedGreenIcon.svg";

const buttonStyle = () => cntl`
  flex
  justify-center
  relative
  transition-all
`;

const menuContainerCN = (open, isEditing) => cntl`
  w-60
  flex
  flex-col
  absolute
  border
  ${isEditing ? "-top-24 -right-20" : "top-8 right-2"}
  rounded
  z-50
  transition-all
  bg-white
  ${!open && "hidden"}
`;

const menuItem = () => cntl`
  slide 
  flex 
  justify-start 
  py-3 
  px-6
  w-full
  whitespace-nowrap 
  text-gray-450
  hover:bg-lightGreen
  focus:bg-lightGreen
  menuItem
  items-center
  text-sm
`;

const iconCN = (className) => cntl`
  min-w-max
  ${className}
`;

const IconDropdownButton = ({
  dropdownItems,
  disabled,
  imgClassName,
  iconClassName,
  icon,
  className,
  title,
  isEditing,
  noHover,
}) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef({});
  const [hover, setHover] = useState(false);

  const handleOpen = () => setOpen(!open && !disabled);

  const handleBlur = useCallback((e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpen(false);
    }
  }, []);

  return (
    <div className={`relative transition-all ${className}`}>
      <button
        title={`${title || ""}`}
        className={buttonStyle()}
        onClick={(e) => handleOpen(e)}
        onBlur={(e) => handleBlur(e)}
        onFocus={() => setHover(true)}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        type="button"
        tabIndex={0}
        id="dropdown-button"
        aria-label="dropdown-button"
        ref={(el) => {
          buttonRef.current.parent = el;
        }}
        style={{
          transform:
            hover && !noHover
              ? "scale(1.05) translateX(2%)"
              : "scale(1) translateX(0%)",
        }}
      >
        {title && (
          <span className="font-semibold text-brandDarkGreen mr-2 -mt-0.5">{`${
            title || ""
          }`}</span>
        )}
        <span className={iconCN(iconClassName)}>
          <img
            className={imgClassName}
            src={icon || uploadDarkenedGreenIcon}
            alt=""
          />
        </span>
        <div className={menuContainerCN(open, isEditing)}>
          <div className="lift rounded-sm flex flex-col w-full">
            {dropdownItems.map((item, index) => {
              return (
                <React.Fragment key={`dropdown-key-${index + 1}`}>
                  <button
                    className={menuItem(false)}
                    style={{ animationDelay: `${index * 0.05 + 0.05}s` }}
                    onClick={item.onClick}
                    ref={(el) => {
                      buttonRef.current[`menu-item-child${index}-button`] = el;
                    }}
                    type="button"
                    tabIndex={0}
                    id={`menu-item-child${index}-button`}
                    aria-label={`${item.title}-button`}
                  >
                    <img className="w-4 h-4" src={item?.image} alt="file" />
                    <p className="pl-2">{item.title}</p>
                  </button>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </button>
    </div>
  );
};

IconDropdownButton.propTypes = {
  dropdownItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.element,
      onClick: PropTypes.func,
    })
  ),
  disabled: PropTypes.bool,
  imgClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  icon: PropTypes.element,
  className: PropTypes.string,
  title: PropTypes.string,
  isEditing: PropTypes.bool,
  noHover: PropTypes.bool,
};

IconDropdownButton.defaultProps = {
  dropdownItems: [],
  disabled: false,
  imgClassName: "w-5 h-5",
  iconClassName: "w-5 h-5",
  icon: undefined,
  className: undefined,
  title: undefined,
  isEditing: false,
  noHover: false,
};

export default IconDropdownButton;

import React, { useMemo, useState, useCallback } from "react";
import { useParams } from "react-router";
import { RfiAPI } from "@griffingroupglobal/eslib-api";
import {
  SET_RFI_MODAL_STATUS,
  customModalStyles,
  URL_REGEXP,
} from "../../../constants";
import useCreateRfiReducer from "../../../hooks/useCreateRfiReducer";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { useAppState } from "../../../state/appState";
import Modal from "../Modal/Modal";
import RfiForm from "./RfiForm";
import { uploadFileWithData } from "../../../helpers/File";
import { useAddRFI } from "../../../hooks/useRFIs";
import { usePropertiesOverview } from "../../../hooks/properties";
import { useProjectsOverview } from "../../../hooks/projects";

const { contentStyle, overlayStyle, titleStyle, headerStyle } =
  customModalStyles;

const RfiCreateModal = () => {
  const [{ userDict }] = useAppState();
  const { data: currentUser } = useCurrentUser();
  const { mutate: addRfi } = useAddRFI();

  const [filesToUpload, setFilesToUpload] = useState([]);

  const allParams = useParams();

  const associatedRef = useMemo(() => {
    if (allParams?.propertyId) {
      return `Property/${allParams?.propertyId}`;
    }

    if (allParams?.projectId) {
      return `Project/${allParams?.projectId}`;
    }

    return null;
  }, [allParams?.projectId, allParams?.propertyId]);

  const [{ rfiModalStatus }, dispatch] = useAppState([]);

  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  const [rfiForm, rfiFormDispatch] = useCreateRfiReducer();

  const associationValue = React.useMemo(() => {
    let association;
    if (allParams?.propertyId) {
      association = propertiesDict?.[`Property/${allParams?.propertyId}`];
      return {
        label: association?.title,
        value: association?.reference,
      };
    }

    if (allParams?.projectId) {
      association = projectDict?.[`Project/${allParams?.projectId}`];
      return {
        label: association?.name,
        value: association?.reference,
      };
    }

    return null;
  }, [
    allParams?.projectId,
    allParams?.propertyId,
    projectDict,
    propertiesDict,
  ]);

  const handleModalClose = () => {
    dispatch({
      type: SET_RFI_MODAL_STATUS,
      open: false,
    });
  };

  const onAddFile = async (doc, data = {}, progressCallback) => {
    const fileRef = await uploadFileWithData(doc, data, progressCallback);
    return fileRef;
  };

  const onUpload = useCallback(async (files, progressCallback) => {
    const handleProgressCallback = (loaded, total, filename) => {
      progressCallback(loaded, total, filename);
    };

    const result = await Promise.all(
      files.map(async ({ name, docType, isFavorited, original }) => {
        const data = {
          name,
          docType,
          isFavorited,
          contentType: original?.type,
          size: original?.size,
        };
        const res = await onAddFile(original, data, (loaded, total) =>
          handleProgressCallback(loaded, total, name)
        );

        return { ...data, reference: res };
      })
    );

    return result;
  }, []);

  const handleFilesAdded = React.useCallback(
    async (addedFiles) => {
      setFilesToUpload(addedFiles);
    },
    [setFilesToUpload]
  );

  const handleFilesUpdated = (updatedFiles) => {
    setFilesToUpload(updatedFiles);
  };

  const handleFilesUploaded = useCallback(async () => {
    const filteredFiles = filesToUpload.filter((file) => !file.isEditing);
    const res = await onUpload(filteredFiles, () => {});

    setFilesToUpload([]);
    return res;
  }, [filesToUpload, onUpload, setFilesToUpload]);

  const removeAttachedFile = useCallback(
    (id) => {
      const remainingFiles = filesToUpload.filter(
        (_file, index) => index !== id
      );
      setFilesToUpload(remainingFiles);
    },
    [filesToUpload, setFilesToUpload]
  );

  const patchAssociatedArtifact = async (rfiToPatch, ref) => {
    const newRfi = {
      ...rfiToPatch,
      associatedArtifacts: [...rfiToPatch?.associatedArtifacts, ref],
    };
    await RfiAPI.patch(rfiToPatch?.id, newRfi, rfiToPatch);
  };

  const onFinishSaving = async (rfiFormData, rfiStatus) => {
    const { resourceName, ...rfiData } = rfiFormData;
    rfiData.status = rfiStatus;

    // upload files
    const filesUploadedResults = await handleFilesUploaded();
    const uploadedFiles = filesUploadedResults.map((f) => f.reference);
    rfiData.associatedArtifacts = uploadedFiles;
    rfiData.links = rfiData.links?.filter((l) => URL_REGEXP.test(l.url));
    const { data: createdRfi } = addRfi(rfiData);
    if (createdRfi?.reference)
      await patchAssociatedArtifact(
        rfiModalStatus?.artifactFor,
        createdRfi?.reference
      );
    rfiFormDispatch({
      type: "reset",
    });
    handleModalClose();
  };

  return (
    <Modal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      minWidth="650px"
      title="Create RFI"
      overlayStyle={overlayStyle}
      content={contentStyle}
      titleStyle={titleStyle}
      headerStyle={headerStyle}
      isOpen={rfiModalStatus.open}
      onRequestModalClose={handleModalClose}
      hideFooter
    >
      <RfiForm
        currentUser={currentUser}
        closeCreateModal={handleModalClose}
        associatedRef={associatedRef}
        associationValue={associationValue}
        rfiForm={rfiForm}
        dispatch={rfiFormDispatch}
        onFinishSaving={onFinishSaving}
        filesToUpload={filesToUpload}
        handleFilesAdded={handleFilesAdded}
        handleFilesUpdated={handleFilesUpdated}
        handleFilesUploaded={handleFilesUploaded}
        removeAttachedFile={removeAttachedFile}
        userDict={userDict}
      />
    </Modal>
  );
};

export default RfiCreateModal;

import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import CollapsibleSection from "../CollapsibleSection/CollapsibleSection";
import useFaqListNewData from "./useFaqListNewData";

const collapsibleContainerCN = (isLastItem) => cntl`
  mt-3
  mx-6
  ${isLastItem ? "" : "border-b"}
  pb-2
`;

const FaqListNew = ({ items }) => {
  const { toggleItem, itemToggles } = useFaqListNewData(items);

  return (
    <div className="border rounded-md">
      {items?.map((item, idx) => {
        const isLastItem = idx === items?.length - 1;
        return (
          <CollapsibleSection
            key={item.question}
            id={`question-${idx}`}
            className={collapsibleContainerCN(isLastItem)}
            title={item.question}
            isOpen={itemToggles[idx]}
            setIsOpen={() => toggleItem(idx)}
            useChevronIcon
          >
            <div className="flex">
              <p>{item.answer}</p>
            </div>
          </CollapsibleSection>
        );
      })}
    </div>
  );
};

FaqListNew.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.oneOf(PropTypes.string, PropTypes.element),
    })
  ),
};

FaqListNew.defaultProps = {
  items: [],
};

export default FaqListNew;

import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import ResourceCard from "../ResourceCard/ResourceCard";

const buttonCN = () => cntl`
w-30 
h-30 
text-center 
rounded-md 
my-auto 
px-5 
py-5
`;

const LocationOrSpaceSelectCard = ({
  item,
  setSelectedLocationOrSpace,
  active,
}) => {
  return (
    <div className="flex flex-col items-center justify-Center rounded-sm mx-2 mt-4">
      <button
        className={buttonCN()}
        style={{ transform: active ? "scale(1.025)" : "" }}
        onClick={() => {
          setSelectedLocationOrSpace(item?.id);
        }}
        type="button"
        aria-label="select location or space"
      >
        <ResourceCard
          title={item?.name}
          image={item?.image}
          detail={{ value: item?.levelType, label: "Level" }}
          hideButton
          className={`${
            !active ? "bg-gray-100 text-gray-200" : "bg-white text-gray-300"
          }`}
        />
      </button>
    </div>
  );
};

LocationOrSpaceSelectCard.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    area: PropTypes.number,
    image: PropTypes.shape({ file: PropTypes.string }),
    id: PropTypes.string,
    levelType: PropTypes.string,
  }),
  setSelectedLocationOrSpace: PropTypes.func,
  active: PropTypes.bool,
};
LocationOrSpaceSelectCard.defaultProps = {
  item: {},
  setSelectedLocationOrSpace: undefined,
  active: false,
};

export default LocationOrSpaceSelectCard;

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Widget from "../Widget/Widget";
import Table from "../Table/Table";
import TableActionsIconsGroup from "../Table/TableActionsIconsGroup";
import Modal from "../Modal/Modal";
import TagList from "../TagList";
import RoleMembersAvatarList from "../../../Pages/Admin/RoleMembersAvatarList";
import IconButton from "../Buttons/IconButton";
import PopoverComments from "../PopoverComments";
import TableHeader from "./TableHeader";
import useReportTableData from "./useReportTableData";
import commentIcon from "../../assets/images/comment_icon_updated.svg";
import { FILTER_TYPES } from "../../../constants";

const REPORTS_GROUPING = {
  hierarchicalOptions: [],
  nonHierarchicalOptions: [
    {
      label: "Type",
      value: "outputType",
    },
    {
      label: "Repeat",
      value: "repeat",
    },
    {
      label: "Resource Reference",
      value: "sourceType",
    },
    {
      label: "Tags",
      value: "tags",
    },
  ],
};

const ReportsTable = ({ association, reports, isLoading, onReportClicked }) => {
  const {
    reportTemplates,
    canWrite,
    canDelete,
    totalSelectedRows,
    showDeleteDialog,
    commentsData,
    handleSelectRows,
    handleReportSelected,
    handleDeleteReports,
    handleSaveTemplates,
    handleDeleteTemplate,
    handleOpenComments,
    handleCloseComments,
    setShowDeleteDialog,
  } = useReportTableData({
    association,
    reports,
    onReportClicked,
  });

  // add a test commit
  const [columns, data] = useMemo(() => {
    const tableColumns = [
      {
        Header: "Comments",
        accessor: "comments",
        Cell: ({ row }) => {
          return (
            <IconButton
              icon={commentIcon}
              onClick={() => {
                handleOpenComments(row.original.reference);
              }}
            />
          );
        },
        maxWidth: 150,
        disableSortBy: true,
        disableFilters: true,
        Aggregated: () => null,
      },
      {
        Header: "Name",
        accessor: "title",
        minWidth: 200,
      },
      {
        Header: "Type",
        accessor: "outputType",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Resource Reference",
        accessor: "sourceType",
        minWidth: 100,
        filterOptions: {
          label: "Resource Reference",
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Associations",
        accessor: "sources",
        minWidth: 300,
      },
      {
        Header: "Repeat",
        accessor: "repeat",
        minWidth: 100,
        filterOptions: {
          label: "Repeat",
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Distro List",
        accessor: "distribution",
        minWidth: 300,
        Cell: ({ value }) => {
          return (
            <RoleMembersAvatarList
              showExtraCount
              showRightTooltip
              members={value}
              tooltipClassName="top-8 left-8 z-10"
              viewAllClassName="text-xs pl-3"
              className="mr-20"
            />
          );
        },
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ row }) => {
          return (
            <TagList
              tags={row?.original?.tags}
              tagClassName="py-1 px-2"
              showCount
            />
          );
        },
        minWidth: 300,
        filterOptions: {
          label: "Tags",
          filterType: FILTER_TYPES.tags,
        },
      },
    ];

    return [tableColumns, reports];
  }, [reports, handleOpenComments]);

  return (
    <Widget loading={isLoading} title={null} draggable={false}>
      <Table
        data={data}
        groupOptions={REPORTS_GROUPING}
        columns={columns}
        allowSelection
        showConstantRowIndex
        templateSettings={reportTemplates}
        updateUserTemplateSettings={handleSaveTemplates}
        deleteUserTemplateSettings={handleDeleteTemplate}
        hideSwitchView
        showColumnSettingsLeft={false}
        resourceName="reports"
        onSelectedRowChange={handleSelectRows}
        onRowClick={({ index }) => handleReportSelected(index)}
        customSiteHeader={(props) => (
          <TableHeader canWrite={canWrite} {...props} />
        )}
        fileActionsIcons={
          <TableActionsIconsGroup
            handleShowDelete={() => setShowDeleteDialog(true)}
            showColumnSettingsRight
            canDelete={canDelete}
            disabled={!totalSelectedRows}
          />
        }
      />

      {commentsData.isOpen && (
        <PopoverComments
          isOpen={commentsData.isOpen}
          resourceReference={commentsData.reportReference}
          allowMentionAll
          onClose={handleCloseComments}
        />
      )}

      {showDeleteDialog && (
        <Modal
          alert
          title={`Delete ${totalSelectedRows > 1 ? "Reports" : "Report"}`}
          isOpen={showDeleteDialog}
          primaryButtonTitle="Yes, delete"
          primaryButtonOnClick={handleDeleteReports}
          tertiaryButtonTitle="Cancel"
          onRequestModalClose={() => setShowDeleteDialog(false)}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
          hideFooter
        >
          <p className="text-base mb-2">
            {`Are you sure you want to delete ${
              totalSelectedRows > 1
                ? `these ${totalSelectedRows} reports`
                : "this report"
            }? Once deleted, it cannot be recovered.`}
          </p>
        </Modal>
      )}
    </Widget>
  );
};

ReportsTable.defaultProps = {
  association: undefined,
  reports: [],
  isLoading: false,
  onReportClicked: undefined,
};

ReportsTable.propTypes = {
  association: PropTypes.string,
  reports: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  onReportClicked: PropTypes.func,
};

export default ReportsTable;

import cntl from "cntl";
import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import editIcon from "../../assets/images/editIcon.svg";
import IconButton from "../Buttons/IconButton";
import Dropdown from "../Dropdown/Dropdown";
import Input from "../Input/Input";

const overviewWrapperCN = (modal) => cntl`
w-full 
flex 
flex-col 
rounded 
mb-4 
${!modal && "border-2 p-5"}
`;

const titleCN = cntl`
font-bold 
text-gray-300
`;

const valueWrapperCN = (modal) => cntl`
flex
flex-col
${!modal ? "w-1/3" : "w-2/3"}
my-4
`;

const subTitleCN = cntl`
font-bold 
text-gray-700 
text-sm
`;

const valueCN = cntl`
text-gray-200 
text-sm
mt-2
`;

const SpaceOverview = ({
  space,
  onFinishEditing,
  dispatch,
  disableEditing,
  configuration,
  modal,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedSpace, setEditedSpace] = useState();
  const [hovering, setHovering] = useState(false);
  const wrapperRef = useRef(null);

  const spaceTypeOptions = useMemo(
    () =>
      Object.values(configuration?.spaces ?? {})?.map((item) => ({
        label: item?.display,
        value: item?.id,
      })),
    [configuration?.spaces]
  );

  const levelTypeOptions = useMemo(
    () =>
      Object.values(configuration?.levels ?? {})?.map((item) => ({
        label: item?.display,
        value: item?.id,
      })),
    [configuration?.levels]
  );
  useEffect(() => {
    setEditedSpace(space);
  }, [space]);

  const handleHover = (mouseOver) => {
    if (mouseOver) {
      setHovering(true);
    } else {
      setHovering();
    }
  };

  const handleChange = (key, val, subKey = null) => {
    setEditedSpace((prev) => ({
      ...prev,
      [key]: subKey ? { ...editedSpace?.[key], [subKey]: val } : val,
    }));
    if (modal) {
      const tempEditedSpace = {
        ...editedSpace,
        type: "overview",
        spaceType: editedSpace?.type,
      };
      dispatch({
        ...{ ...tempEditedSpace, [key === "type" ? "spaceType" : key]: val },
      });
    }
  };

  const handleEditClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    } else {
      if (!_.isEqual(space, editedSpace)) {
        dispatch({
          type: "overview",
          name: editedSpace?.name,
          spaceType: editedSpace?.type,
          level: editedSpace?.level,
        });
        if (!modal)
          onFinishEditing(
            ["name", "type", "level"],
            [editedSpace?.name, editedSpace?.type, editedSpace?.level]
          );
      }
      setIsEditing(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleEditClick();
    }
  };

  return (
    <div
      className={overviewWrapperCN(modal)}
      ref={wrapperRef}
      onMouseOver={() => !modal && handleHover(true)}
      onMouseOut={() => !modal && handleHover()}
      onFocus={() => !modal && handleHover(true)}
      onBlur={() => !modal && handleHover()}
    >
      <div className="flex items-center justify-between">
        {!modal && <p className={titleCN}>General Information</p>}
        {!disableEditing && !modal && (
          <IconButton
            wrapperClassName={hovering ? "" : "hidden"}
            imgClassName="w-6 h-6"
            iconClassName="w-6 h-6"
            onClick={handleEditClick}
            icon={editIcon}
          />
        )}
      </div>
      <div className={`flex ${modal && "flex-col"}`}>
        <div className={valueWrapperCN(modal)}>
          <p className={subTitleCN}>Name</p>
          {!isEditing && !modal && (
            <p className={valueCN}>{editedSpace?.name}</p>
          )}
          {(isEditing || modal) && (
            <Input
              placeholder="Name"
              className="mt-2"
              mainClassName="w-2/3"
              label={modal && "Name"}
              value={editedSpace?.name}
              onChange={(val) => handleChange("name", val)}
              handleEnter={handleKeyDown}
              validation={yup.string().required()}
              disabled={disableEditing}
              showValidationErrorAtBottom={!modal}
            />
          )}
        </div>
        <div className={valueWrapperCN(modal)}>
          {!modal && <p className={subTitleCN}>Type</p>}
          {!isEditing && !modal && (
            <p className={valueCN}>
              {configuration?.spaces?.[editedSpace?.type]?.display}
            </p>
          )}
          {(isEditing || modal) && (
            <Dropdown
              className="mt-2 w-2/3 relative"
              label={modal && "Type"}
              options={[...spaceTypeOptions]}
              value={spaceTypeOptions?.find(
                (item) => item?.value === editedSpace?.type
              )}
              onChange={(value) => handleChange("type", value?.value)}
              isDisabled={disableEditing}
            />
          )}
        </div>
        <div className={valueWrapperCN(modal)}>
          {!modal && <p className={subTitleCN}>Level</p>}
          {!isEditing && !modal && (
            <p className={valueCN}>
              {configuration?.levels?.[editedSpace?.level]?.display}
            </p>
          )}
          {(isEditing || modal) && (
            <Dropdown
              className="mt-2 w-2/3 relative"
              options={levelTypeOptions}
              label={modal && "Level"}
              value={levelTypeOptions?.find(
                (item) => item?.value === editedSpace?.level
              )}
              onChange={(value) => handleChange("level", value?.value)}
              isDisabled={disableEditing}
            />
          )}
        </div>
      </div>
    </div>
  );
};

SpaceOverview.propTypes = {
  space: PropTypes.shape({}),
  onFinishEditing: PropTypes.func,
  dispatch: PropTypes.func,
  disableEditing: PropTypes.bool,
  configuration: PropTypes.shape({
    levels: PropTypes.shape({}),
    spaces: PropTypes.shape({}),
  }),
  modal: PropTypes.bool,
};

SpaceOverview.defaultProps = {
  space: undefined,
  onFinishEditing: undefined,
  dispatch: undefined,
  disableEditing: true,
  configuration: undefined,
  modal: false,
};

export default SpaceOverview;

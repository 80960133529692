import PropTypes from "prop-types";
import React from "react";
import Tag from "./Tag";

/**
 * @deprecated - Instead use TagList from `stories/components/TagList`
 */
const TagList = ({
  tags,
  isEditing,
  handleDeleteTag,
  className,
  tagClassName,
  showCount,
}) => {
  return (
    <div className={`flex ${className}`}>
      {showCount &&
        tags?.slice(0, 2)?.map((tag, index) => {
          const key = tag?.value || index;

          return (
            <Tag
              key={key}
              tag={tag}
              isEditing={isEditing}
              handleDeleteTag={handleDeleteTag}
              className={tagClassName}
              size="10"
            />
          );
        })}
      {showCount && tags.length > 2 && (
        <div className="bg-gray-100 rounded-3xl py-1 px-1.5">
          <p className="text-primaryGreen text-xs font-normal">
            {`+${tags?.length - 2}`}
          </p>
        </div>
      )}
      {!showCount &&
        tags?.map((tag, index) => {
          const key = tag?.value || index;

          return (
            <Tag
              key={key}
              tag={tag}
              isEditing={isEditing}
              handleDeleteTag={handleDeleteTag}
              className={tagClassName}
            />
          );
        })}
    </div>
  );
};

TagList.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  isEditing: PropTypes.bool,
  handleDeleteTag: PropTypes.func,
  className: PropTypes.string,
  tagClassName: PropTypes.string,
  showCount: PropTypes.bool,
};

TagList.defaultProps = {
  tags: [],
  isEditing: false,
  handleDeleteTag: () => {},
  className: undefined,
  tagClassName: undefined,
  showCount: false,
};

export default TagList;

const getUpdatedAllTaskSteps = (
  taskSteps,
  sopsForTask,
  newCompletionStatus
) => {
  const updatedSteps = JSON.parse(JSON.stringify(taskSteps));
  updatedSteps.map((step) => {
    // eslint-disable-next-line no-param-reassign
    step.isCompleted = newCompletionStatus;
    // normal step
    if (step.sop) {
      const sopRef = step.sop;
      const sopResource = sopsForTask.find(
        (sopInfo) => sopInfo.reference === sopRef
      );
      // eslint-disable-next-line no-param-reassign
      step.sopStepStatus = sopResource.steps.map((sopStep) => {
        return {
          id: sopStep.id,
          name: sopStep.description,
          isCompleted: newCompletionStatus,
        };
      });
    }
    return step;
  });
  return updatedSteps;
};

export default getUpdatedAllTaskSteps;

import { useCallback, useMemo } from "react";
import useReportsTemplates from "../../../hooks/useReportsTemplates";
import { convertTemplatesInDropdownShape } from "../../../helpers/Report/reportTemplatesFormatters";
import { useModalState } from "../../../state/modalState";
import { ADD_OPEN_MODAL, REPORT_TEMPLATE_MODAL } from "../../../constants";

const useReportManagementTemplatesDropdownData = () => {
  const [, modalDispatch] = useModalState();
  const { data: reportTemplates = [] } = useReportsTemplates();

  // Transform templates to display them in dropdown
  const reportTemplatesOptions = useMemo(
    () => convertTemplatesInDropdownShape(reportTemplates),
    [reportTemplates]
  );

  const handleOpenModal = useCallback(
    ({ template }) => {
      modalDispatch({
        type: ADD_OPEN_MODAL,
        ref: { id: "new-template-modal" },
        modalData: { template },
        modalType: REPORT_TEMPLATE_MODAL,
      });
    },
    [modalDispatch]
  );

  return {
    reportTemplatesOptions,
    handleOpenModal,
  };
};

export default useReportManagementTemplatesDropdownData;

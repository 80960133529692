/* eslint-disable import/prefer-default-export */

/**
 * @param {Number} height - height in regards to pixels
 * @param {Number} width - width in regards to pixels
 * @param {Number} stroke - stroke applied
 * @returns - offset x,y,height,& width as percentages - half the stroke
 * @summary - (Prevents clippling the stroke)
 */
export const calcSvgStrokeOffset = (height, width, stroke = 3) => {
  const x = (stroke / 2 / width) * 100;
  const y = (stroke / 2 / height) * 100;
  const w = 100 - x * 2;
  const h = 100 - y * 2;
  return `x='${x}%25' y='${y}%25' width='${w}%25' height='${h}%25'`;
};

/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Gallery from "../Gallery";
import useModalMediaWidget from "./useModalMediaWidget";
import PlusCircleButton from "../Buttons/PlusCircleButton/PlusCircleButton";
import { PRIMARY_DARK_GREEN } from "../../../constants";

const ModalMediaWidget = ({
  isEditing,
  requestForm,
  filesState,
  handleFilesToAdd,
  handleFilesToRemove,
  noTitle,
  noHover,
}) => {
  const {
    mediaFiles,
    removeFile,
    hover,
    setHover,
    getRootProps,
    getInputProps,
  } = useModalMediaWidget({
    requestForm,
    filesState,
    handleFilesToAdd,
    handleFilesToRemove,
  });

  return (
    <div className={`${!noTitle && "gap-10"} grid`}>
      {!noTitle && <span className="font-semibold text-lg">Media</span>}

      <Gallery
        thumbnailHeight={60}
        thumbnailWidth={60}
        isEditing={isEditing}
        mediaFiles={mediaFiles}
        handleFilesToRemove={removeFile}
      />
      {isEditing && (
        <div className="flex gap-x-8">
          <button
            {...getRootProps()}
            className="flex flex-row mt-5 h-5 flex-wrap transition-all"
            type="button"
            onFocus={() => setHover(true)}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
              transform:
                hover && !noHover
                  ? "scale(1.05) translateX(2%)"
                  : "scale(1) translateX(0%)",
            }}
          >
            <input {...getInputProps()} />

            <PlusCircleButton
              title="Add Media"
              className="m-0"
              onClick={() => {}}
              style={{ color: PRIMARY_DARK_GREEN }}
              noHover
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default ModalMediaWidget;

import {
  CALENDAR_MONTH_EVENT_DATE_OFFSET,
  CALENDAR_MONTH_EVENT_HEIGHT,
  CALENDAR_MONTH_TEXT_VERTICAL_CENTER,
  PRIMARY_DARK_GREEN,
  TASK_BLUE,
} from "../../../../../constants";
import { eventColor } from "../../../../../helpers/Calendar";
import getDateTimeInTimezone from "../../../../../helpers/Calendar/getDateTimeInTimezone";

const useEventCellData = ({ item, calendarTimezone }) => {
  // function to calculate the Y position of the text
  const textYPosition = (iterator, place) => {
    return (
      iterator * 35 +
      CALENDAR_MONTH_EVENT_HEIGHT * (place - 1) +
      CALENDAR_MONTH_TEXT_VERTICAL_CENTER +
      CALENDAR_MONTH_EVENT_DATE_OFFSET
    );
  };
  // value to determine if the task is marked complete
  const isMarkedComplete = item.status === "done";

  const cellText = item?.daySpan
    ? `${item?.name}`
    : `${getDateTimeInTimezone({
        date: item.startDate,
        timezone: calendarTimezone,
        format: "h:mma",
      })} ${item?.name}`;

  const determineStrokeColor = (calendarItem) => {
    if (calendarItem?.daySpan) return eventColor(calendarItem);
    if (calendarItem?.status === "done") return "white";
    return "transparent";
  };

  const determineFillColor = (calendarItem) => {
    if (calendarItem?.daySpan && calendarItem.resource === "Task")
      return TASK_BLUE;
    if (calendarItem?.daySpan && calendarItem.resource === "Event")
      return PRIMARY_DARK_GREEN;
    if (calendarItem?.status === "done") return "#076ccb4a";

    return "none";
  };

  const circleYPosition = (iterator, place) => {
    return (
      iterator * 35 +
      CALENDAR_MONTH_EVENT_HEIGHT * (place - 1) +
      CALENDAR_MONTH_TEXT_VERTICAL_CENTER -
      0.25 +
      CALENDAR_MONTH_EVENT_DATE_OFFSET
    );
  };

  return {
    isMarkedComplete,
    cellText,
    determineFillColor,
    circleYPosition,
    textYPosition,
    determineStrokeColor,
  };
};

export default useEventCellData;

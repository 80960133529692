import React from "react";
import FilesPopup from "../ExpenseTable/ReceiptPopup";
import RequestFileCard from "../RequestFileCard/RequestFileCard";

export default function RequestAttachmentCard({
  attachments,
  attachmentMap,
  currentRound,
}) {
  return (
    <div className="flex flex-col items-start gap-2">
      {attachments?.length <= 2 &&
        attachments?.map((item) => {
          const fileData = attachmentMap[item?.ref];
          const title = fileData?.name;
          return (
            title && (
              <RequestFileCard
                key={item?.ref}
                title={title}
                file={fileData}
                iconClassName="w-4.5 h-4.5"
              />
            )
          );
        })}
      {attachments?.length > 2 && (
        <div className="relative flex flex-col items-start gap-2">
          {attachments?.slice(0, 2)?.map((item) => {
            const fileData = attachmentMap[item?.ref];
            const title = fileData?.name;
            return (
              title && (
                <RequestFileCard
                  key={item?.ref}
                  title={title}
                  file={fileData}
                  iconClassName="w-4.5 h-4.5"
                />
              )
            );
          })}
          <div className="flex items-center gap-2 w-full">
            <div
              className="flex justify-center items-center rounded-full text-xs font-semibold"
              style={{
                color: "#027D61",
                background: "#F9F9F9",
                width: "25px",
                height: "25px",
              }}
            >
              <p className="flex text-center items-center">
                +{attachments?.length - 2}
              </p>
            </div>
            <FilesPopup
              value={attachments}
              title={`View All ${attachments?.length} files`}
              downloadable
              absolutePosition={
                currentRound ? "top-24 left-8" : "bottom-8 left-8"
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}

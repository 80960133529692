import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useFilters, useTable } from "react-table";

import { useWorkflowSwitchView } from "../../../hooks/useSwitchView";
import useDocumentsConfiguration from "../../../hooks/useDocumentsConfiguration";
import {
  FILTER_TYPES,
  TABLE_FILTER,
  WORKFLOW_TAB_VIEWS,
} from "../../../constants";
import {
  BooleanFilter,
  DateFilter,
  IsOrNotFilter,
  IncludesExcludesFilter,
} from "../Table/tableFilters";

import PrimaryButton from "../Buttons/PrimaryButton";
import SiteHeader from "../SiteHeader/SiteHeader";
import SiteHeaderAlert from "../SiteHeader/SiteHeaderAlert";
import SiteHeaderSearch from "../SiteHeader/SiteHeaderSearch";
import SiteHeaderSwitchView from "../SiteHeader/SiteHeaderSwitchView";
import SiteHeaderFilter from "../SiteHeader/SiteHeaderFilter";
import WorkflowActiveCard from "../WorkflowOverview/WorkflowActiveCard";
import WorkflowOtherCards from "../WorkflowOverview/WorkflowOtherCards";
import GetStartedCircle from "../GetStartedCircle/GetStartedCircle";

const WorkflowCardsView = ({
  data,
  setSelectedCard,
  handleOpenResolve,
  handleOpenAssociation,
  handleEditWorkflow,
  handleReinitiateWF,
  handleDeleteWF,
  handleCreateAssociatedWF,
  handleFlagWF,
  siteHeaderProps,
  siteHeaderTabs,
  handleAddWorkflow,
  sortActiveCardsByOverdue,
  warningCount,
  alertCount,
  alertToggle,
  warningToggle,
  setWarningToggle,
  setAlertToggle,
  currentUser,
  searchInput,
  handleSearch,
  bannerComponent,
  associatedResource,
  handleWatchWF,
  watchList,
  workflowsCount,
  isLoading,
}) => {
  const [isShowingTable, setIsShowingTable] = useWorkflowSwitchView(false);
  const [columns, setColumns] = useState([]);
  const { data: docConfig } = useDocumentsConfiguration();
  const docTypeOptions = React.useMemo(() => {
    return docConfig?.documents?.documentType
      .filter((doc) => !doc.custom)
      .map((doc) => {
        return { label: doc.display, value: doc.value };
      })
      .sort(({ label: a }, { label: b }) => a.localeCompare(b));
  }, [docConfig]);

  const workflowColumns = React.useMemo(
    () => [
      {
        Header: "Completed",
        accessor: "resolved",
        show: false,
      },
      {
        Header: "Flag",
        accessor: "isFlagged",
        filterOptions: {
          label: "Flagged",
          filterType: FILTER_TYPES.boolean,
        },
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Status",
        accessor: "status",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Steps Advanced",
        accessor: "isStepAdvanced",
        filterOptions: {
          label: "Steps advanced",
          filterType: FILTER_TYPES.boolean,
        },
      },
      {
        Header: "Due Date",
        accessor: "endDate",
        filterOptions: {
          label: "Due Date",
          filterType: FILTER_TYPES.date,
        },
      },
      {
        Header: "Version #",
        accessor: "version",
      },
      {
        Header: "Initiation Date",
        accessor: "initiationDate",
        filterOptions: {
          label: "Initiation Date",
          filterType: FILTER_TYPES.date,
        },
      },
      {
        Header: "Initiator Name",
        accessor: "initiatorName",
        show: false,
      },
      {
        Header: "Has Associated Workflows",
        accessor: "hasAssociatedWorkflows",
      },
      {
        Header: "Warnings/Alerts",
        accessor: "warning",
        Aggregated: () => null,
      },
      {
        Header: "Property",
        accessor: "property",
        show: false,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Container View",
        accessor: "containerView",
        show: false,
        aggregate: "sum",
      },
      {
        Header: "Document Type",
        accessor: "documentTypes",
        filterOptions: {
          filterType: FILTER_TYPES.includesExcludes,
          type: "includes",
          customOptions: docTypeOptions,
        },
      },
    ],
    [docTypeOptions]
  );

  useEffect(() => {
    const col = workflowColumns.map((currentCol) => {
      switch (currentCol.filterOptions?.filterType) {
        case FILTER_TYPES.isOrNot: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.IS_OR_NOT,
            Filter: IsOrNotFilter,
          };
        }
        case FILTER_TYPES.boolean: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.BOOL,
            Filter: BooleanFilter,
          };
        }
        case FILTER_TYPES.date: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.DATE,
            Filter: DateFilter,
          };
        }
        case FILTER_TYPES.includesExcludes: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.INCLUDES_EXCLUDES,
            Filter: IncludesExcludesFilter,
          };
        }
        default: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.IS_OR_NOT,
            Filter: IsOrNotFilter,
          };
        }
      }
    });
    setColumns(col);
  }, [workflowColumns]);

  const {
    setAllFilters,
    rows,
    allColumns,
    state: { filters },
  } = useTable(
    {
      columns,
      data,
      autoResetFilters: false,
      autoResetGroupBy: false,
    },
    useFilters
  );

  return (
    <>
      <div className="mb-20">
        <SiteHeader
          title={siteHeaderProps.title}
          dropdownRoutes={siteHeaderProps.dropdownRoutes}
          buttons={
            (currentUser?.isAdmin ||
              currentUser?.hasPermission?.("workflow", "can_create")) && (
              <PrimaryButton
                {...siteHeaderProps}
                large
                title="+ Add Workflow"
                onClick={handleAddWorkflow}
              />
            )
          }
          viewOptions={<div style={{ minHeight: "2.75rem" }} />}
          search={
            !!workflowsCount && (
              <SiteHeaderSearch
                handleSearch={handleSearch}
                globalFilter={searchInput}
              />
            )
          }
          filter={
            !!workflowsCount && (
              <SiteHeaderFilter
                allColumns={allColumns}
                tableData={data}
                filters={filters}
                setAllFilters={setAllFilters}
              />
            )
          }
          alerts={
            !!workflowsCount && (
              <div
                onClick={sortActiveCardsByOverdue}
                onKeyDown={() => {}}
                tabIndex={0}
                role="button"
                className="flex"
                aria-label="sort by overdue"
              >
                <SiteHeaderAlert
                  total={warningCount}
                  isRed
                  onClick={() => {
                    setWarningToggle((el) => !el);
                  }}
                  buttonState={warningToggle}
                />
                <SiteHeaderAlert
                  total={alertCount}
                  onClick={() => {
                    setAlertToggle((el) => !el);
                  }}
                  buttonState={alertToggle}
                />
              </div>
            )
          }
          switchView={
            !!workflowsCount && (
              <SiteHeaderSwitchView
                isShowingTable={isShowingTable}
                setIsShowingTable={setIsShowingTable}
              />
            )
          }
          tabs={!!workflowsCount && siteHeaderTabs}
        />
        {bannerComponent}
      </div>
      {!workflowsCount && !isLoading && (
        <GetStartedCircle
          className="table mx-auto"
          title="Workflows"
          onGetStartedClicked={handleAddWorkflow}
          disabled={
            !(
              currentUser?.isAdmin ||
              currentUser?.hasPermission?.("workflow", "can_create")
            )
          }
        />
      )}
      <div className="flex flex-wrap -mx-4">
        {rows.map((info) => {
          switch (info?.original?.containerView) {
            case WORKFLOW_TAB_VIEWS.active: {
              return (
                <WorkflowActiveCard
                  data={info?.original}
                  key={data.id}
                  setSelectedCard={setSelectedCard}
                  handleOpenResolve={handleOpenResolve}
                  handleOpenAssociation={handleOpenAssociation}
                  handleEditWorkflow={handleEditWorkflow}
                  handleReinitiateWF={handleReinitiateWF}
                  handleDeleteWF={handleDeleteWF}
                  handleFlagWF={handleFlagWF}
                  disableEditing={
                    !currentUser?.hasPermission?.("workflow", "can_create")
                  }
                  disableDelete={
                    !currentUser?.hasPermission?.("workflow", "can_delete")
                  }
                  associatedResource={associatedResource}
                  handleWatchWF={handleWatchWF}
                  watchList={watchList}
                />
              );
            }
            default: {
              return (
                <WorkflowOtherCards
                  data={info?.original}
                  view={info?.original.containerView}
                  key={data.id}
                  setSelectedCard={setSelectedCard}
                  handleOpenResolve={handleOpenResolve}
                  handleOpenAssociation={handleOpenAssociation}
                  handleEditWorkflow={handleEditWorkflow}
                  handleReinitiateWF={handleReinitiateWF}
                  handleDeleteWF={handleDeleteWF}
                  handleCreateAssociatedWF={handleCreateAssociatedWF}
                  handleFlagWF={handleFlagWF}
                  disableEditing={
                    !currentUser?.hasPermission?.("workflow", "can_create")
                  }
                  disableDelete={
                    !currentUser?.hasPermission?.("workflow", "can_delete")
                  }
                  associatedResource={associatedResource}
                  handleWatchWF={handleWatchWF}
                  watchList={watchList}
                />
              );
            }
          }
        })}
      </div>
    </>
  );
};

WorkflowCardsView.propTypes = {
  /**
   * data displayed on cards
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      associatedWorkflows: PropTypes.string,
      changes: PropTypes.arrayOf(
        PropTypes.shape({
          details: PropTypes.string,
          task: PropTypes.string,
          users: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
            })
          ),
        })
      ),
      currentStep: PropTypes.number,
      dueDate: PropTypes.string,
      isFlagged: PropTypes.bool,
      initiationDate: PropTypes.string,
      initiatorName: PropTypes.string,
      resolved: PropTypes.bool,
      status: PropTypes.string,
      steps: PropTypes.string,
      totalStep: PropTypes.number,
      version: PropTypes.string,
      warning: PropTypes.bool,
      workflowName: PropTypes.string,
      containerView: PropTypes.string,
    })
  ),
  /**
   * sets the selected card
   */
  setSelectedCard: PropTypes.func,
  /**
   * function to handle opening the resolve modal
   */
  handleOpenResolve: PropTypes.func,
  handleReinitiateWF: PropTypes.func,
  handleEditWorkflow: PropTypes.func,
  handleDeleteWF: PropTypes.func,
  handleOpenAssociation: PropTypes.func,
  handleCreateAssociatedWF: PropTypes.func,
  handleFlagWF: PropTypes.func,
  handleAddWorkflow: PropTypes.func,
  sortActiveCardsByOverdue: PropTypes.func,
  siteHeaderProps: PropTypes.shape({
    title: PropTypes.string,
    dropdownRoutes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        path: PropTypes.string,
      })
    ),
  }),
  warningCount: PropTypes.number,
  alertCount: PropTypes.number,
  warningToggle: PropTypes.bool,
  alertToggle: PropTypes.bool,
  setWarningToggle: PropTypes.func,
  setAlertToggle: PropTypes.func,
  isLoading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  currentUser: PropTypes.object,
  searchInput: PropTypes.string,
  handleSearch: PropTypes.func,
  bannerComponent: PropTypes.node,
  siteHeaderTabs: PropTypes.arrayOf(PropTypes.node),
  associatedResource: PropTypes.string,
  handleWatchWF: PropTypes.func,
  watchList: PropTypes.arrayOf(PropTypes.string),
  workflowsCount: PropTypes.number,
};

WorkflowCardsView.defaultProps = {
  data: [],
  setSelectedCard: undefined,
  handleOpenResolve: undefined,
  handleReinitiateWF: undefined,
  handleEditWorkflow: undefined,
  handleDeleteWF: undefined,
  handleOpenAssociation: undefined,
  handleCreateAssociatedWF: undefined,
  handleFlagWF: undefined,
  handleAddWorkflow: undefined,
  sortActiveCardsByOverdue: undefined,
  siteHeaderProps: {
    title: "Workflows",
    dropdownRoutes: undefined,
  },
  warningCount: 0,
  alertCount: 0,
  warningToggle: false,
  alertToggle: false,
  setWarningToggle: undefined,
  setAlertToggle: undefined,
  currentUser: undefined,
  searchInput: "",
  handleSearch: undefined,
  bannerComponent: undefined,
  siteHeaderTabs: undefined,
  associatedResource: undefined,
  handleWatchWF: undefined,
  watchList: [],
  workflowsCount: 0,
  isLoading: false,
};

export default WorkflowCardsView;

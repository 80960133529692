import PropTypes from "prop-types";
import React from "react";
import ModalWrapper from "../ModalWrapper";
import CreateEditEventForm from "../../EsForm/CreateEditEventForm";
import useEditEventModalData from "./useEditEventModalData";

const EventModal = ({ modalData }) => {
  const {
    formState,
    formIsValid,
    allDayData,
    updateFormState,
    handleSaveSubmit,
  } = useEditEventModalData({ modalData });

  return (
    <ModalWrapper
      formData={formState}
      modalData={modalData}
      resourceName="Event"
      width="1100px"
      title="Add Event"
      primaryButtonOnClick={handleSaveSubmit}
      disabled={!formIsValid}
      showConfirm={false}
    >
      <CreateEditEventForm
        formData={{
          formState,
          allDayData,
          updateFormState,
          handleSaveSubmit,
        }}
        associationDisabled
        isEdit
      />
    </ModalWrapper>
  );
};

export default EventModal;

EventModal.propTypes = {
  modalData: PropTypes.shape({
    item: PropTypes.shape({
      id: PropTypes.string,
      instanceStartDate: PropTypes.string,
      name: PropTypes.string,
      startDate: PropTypes.string,
    }),
    position: PropTypes.shape({}),
  }),
};

EventModal.defaultProps = {
  modalData: undefined,
};

const combineDateTime = ({ timeFrom, dateFrom }) => {
  // Extracting date from dateFrom
  const date = dateFrom.split("T")[0];

  // Extracting time from timeFrom
  const time = timeFrom.split("T")[1];

  // Combining both
  const combined = `${date}T${time}`;

  return combined;
};

export default combineDateTime;

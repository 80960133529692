import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import { isSafari } from "react-device-detect";
import { useHistory } from "react-router-dom";
import SecondaryButton from "../Buttons/SecondaryButton";
import messageGreenIcon from "../../assets/images/messageGreenIcon.svg";
import emailGreenIcon from "../../assets/images/emailGreenIcon.svg";
import phoneGreenIcon from "../../assets/images/phoneGreenIcon.svg";
import BaseButton from "../Buttons/BaseButton";
import link from "../../assets/images/linkGreen.svg";
import editIcon from "../../assets/images/editIcon.svg";
import Pill from "../Pill/Pill";
import Dropdown from "../Dropdown/Dropdown";
import { getLastActiveDisplay } from "../../../helpers/Time";
import Avatar from "../Message/Avatar";
import { getFullName } from "../../../helpers/Formatters";
import { BRAND_GREEN, GET_CONTACT_PATH } from "../../../constants";
import IconButton from "../Buttons/IconButton";
import { useESChatContext } from "../../../context/ChatContext/ESChatContext";
import { useAppState } from "../../../state/appState";

const associatedUserCN = (backgroundColor) => cntl`
  ${backgroundColor || "bg-gray-150"}
  rounded-full
  w-7
  h-7
  flex
  items-center
  justify-center
  mr-1
`;

const formatOptionLabel = ({
  label,
  title,
  value,
  name,
  avatar,
  hideAvatar,
  isMember,
  titleCN,
  titleContainerCN,
  isOnline,
  lastActive,
}) => (
  <div>
    <div className="flex flex-row  justify-between">
      <div className="flex flex-row">
        {!hideAvatar && (
          <Avatar
            className="text-xs mr-1 -ml-2"
            name={getFullName(name)}
            isOnline={isOnline}
            avatar={avatar}
            isExternalUser
            id={value}
          />
        )}
        <div className={`flex flex-col ${isMember && titleContainerCN}`}>
          <p className={`font-semibold ${titleCN} truncate text-ellipsis`}>
            {label}
          </p>
          <p className="font-normal text-xs text-gray-400">{title}</p>
          <p className="font-normal text-xs text-gray-200">
            {getLastActiveDisplay(lastActive)}
          </p>
        </div>
      </div>
      {isMember && (
        <p
          className="bg-black rounded-full w-20 text-white pl-2 py-0.5 self-center"
          style={{ fontSize: "0.6rem" }}
        >
          ES MEMBER
        </p>
      )}
    </div>
  </div>
);

const customStyle = {
  control: {
    border: 0,
    boxShadow: "none",
  },
};

const getIdFromReference = (ref) => {
  return ref.split("/")[1];
};

const ContactDetails = ({
  contactDetails,
  isCreating,
  openChat,
  associatedUsers,
  isPropertyVendor,
  setEditingContact,
  isBrokerCompany,
}) => {
  const history = useHistory();
  const [{ currentUser }] = useAppState();
  const [emailOptions, setEmailOptions] = useState([]);
  const [phoneOptions, setPhoneOptions] = useState([]);
  const [textOptions, setTextOptions] = useState([]);
  const [memberCount, setMemberCount] = useState(0);

  const [hovering, setHovering] = useState(false);

  const { clientChat } = useESChatContext();

  const toggleEdit = () => {
    setEditingContact((prev) => !prev);
  };

  const handleHovering = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    setHovering(value);
  };

  const handleBlur = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    setHovering(value);
  };

  useEffect(() => {
    if (associatedUsers?.length) {
      setMemberCount(associatedUsers.filter((u) => u.isMember)?.length);
    }
  }, [associatedUsers]);

  useEffect(() => {
    if (contactDetails) {
      setEmailOptions(
        contactDetails?.contactPoint
          ?.filter((cp) => cp.system === "Email")
          ?.map((email) => ({
            title: `${email?.use} ${email.value}`,
            onClick: () => {
              document.location = `mailto:${email.value}?subject=&body=`;
            },
          }))
      );
      setPhoneOptions(
        contactDetails?.contactPoint
          ?.filter((cp) => cp.system === "Phone")
          ?.map((phone) => ({
            title: `${phone?.use} ${phone.value}`,
            anchorTag: (
              <a href={`tel:${phone.value}`}>
                {phone?.use} {phone.value}
              </a>
            ),
          }))
      );
      setTextOptions(
        contactDetails?.contactPoint
          ?.filter((cp) => cp.system === "Phone")
          ?.map((phone) => ({
            title: `${phone?.use} ${phone.value}`,
            anchorTag: (
              <a href={`sms:${phone.value}`}>
                {phone?.use} {phone.value}
              </a>
            ),
          }))
      );
    }
  }, [contactDetails]);

  const navigate = useCallback(() => {
    history.push(
      GET_CONTACT_PATH(
        getIdFromReference(
          isPropertyVendor || isBrokerCompany
            ? contactDetails?.value
            : contactDetails?.company?.value
        ),
        "0"
      )
    );
  }, [
    contactDetails?.company?.value,
    contactDetails?.value,
    history,
    isPropertyVendor,
    isBrokerCompany,
  ]);

  const previewNContacts = useCallback(
    (n) => {
      const selectedContacts = associatedUsers.slice(0, n);
      return selectedContacts;
    },
    [associatedUsers]
  );

  const onSelectContact = (selectedContact) => {
    history.push(GET_CONTACT_PATH(selectedContact.value, "0"));
  };
  return (
    <div
      className={
        isPropertyVendor
          ? "py-2 flex justify-start items-start w-full"
          : "py-6 flex justify-between items-start w-full"
      }
    >
      <div
        className={
          isPropertyVendor
            ? "flex items-center w-full"
            : "flex items-start flex-1"
        }
        onMouseOver={(e) => handleHovering(e, true)}
        onFocus={(e) => handleHovering(e, true)}
        onMouseOut={(e) => handleHovering(e, false)}
        onBlur={(e) => handleBlur(e, false)}
        role="presentation"
      >
        <div className="flex items-center w-full">
          <>
            <div className="flex relative">
              <br />
              <Avatar
                className={
                  isPropertyVendor || isBrokerCompany
                    ? "w-12 h-12 mb-2 border-4"
                    : "w-24 h-24 mb-2 mr-6 border-4"
                }
                fontClassName="text-3xl"
                isExternalUser
                disableTooltip
                {...contactDetails}
                name={
                  contactDetails?.firstName &&
                  contactDetails?.lastName &&
                  `${contactDetails?.firstName} ${contactDetails?.lastName}`
                }
              />
            </div>
            <div
              className={`flex ${isBrokerCompany ? "flex-row" : "flex-col"} ${
                isPropertyVendor || isBrokerCompany ? "w-full" : "ml-7"
              }`}
            >
              <p className="text-xl font-medium uppercase flex">
                {`${contactDetails?.firstName || ""} ${
                  contactDetails?.lastName || ""
                }`}
              </p>
              <div
                className={
                  isPropertyVendor || isBrokerCompany
                    ? "flex flex-row w-full pb-2"
                    : "flex flex-row w-full py-2"
                }
              >
                <p className="flex flex-wrap justify-start items-center">
                  {(!isPropertyVendor || isBrokerCompany) && (
                    <h3
                      className={`whitespace-nowrap ${isSafari ? "mr-3" : ""}`}
                    >
                      {contactDetails?.title}{" "}
                      {contactDetails?.company?.label && "at"}
                    </h3>
                  )}
                  {contactDetails?.companyName &&
                    (isPropertyVendor || isBrokerCompany) && (
                      <BaseButton
                        title={contactDetails?.companyName}
                        iconRight={
                          <img src={link} alt="link" className="w-5 h-5" />
                        }
                        innerClassName="text-lg font-semibold text-gray-300 mr-3"
                        className="ml-1"
                        onClick={navigate}
                      />
                    )}
                  {contactDetails?.company?.label &&
                    (!isPropertyVendor || isBrokerCompany) && (
                      <BaseButton
                        title={contactDetails?.company?.label}
                        iconRight={
                          <img src={link} alt="link" className="w-5 h-5" />
                        }
                        innerClassName="whitespace-nowrap text-black font-semibold mr-1"
                        className="ml-1"
                        onClick={navigate}
                      />
                    )}
                </p>
              </div>
              {!!associatedUsers?.length && (
                <div className="flex">
                  {!isBrokerCompany && (
                    <div className="flex flex-row ml-3">
                      {previewNContacts(5).map((user) => (
                        <Avatar
                          className="text-xs mr-1 -ml-3"
                          name={getFullName(user?.name)}
                          isOnline={user?.isOnline}
                          isExternalUser
                          id={user?.value}
                          avatar={user?.avatar}
                          key={user?.id}
                        />
                      ))}
                      {associatedUsers?.length > 5 && (
                        <div
                          className={associatedUserCN(
                            "bg-gray-150 border-2 border-gray-100 mr-1 -ml-3 z-40"
                          )}
                        >
                          <p className="uppercase text-gray-400 text-xs truncate">
                            +{associatedUsers?.length - 5}
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                  {!isBrokerCompany && (
                    <Dropdown
                      options={[
                        {
                          label: `${contactDetails?.firstName || ""} ${
                            contactDetails?.lastName || ""
                          }`,
                          options: associatedUsers,
                        },
                      ]}
                      value={{
                        label: `${!isBrokerCompany ? "View All" : ""}`,
                        value: "View All",
                        hideAvatar: true,
                        titleCN: "text-brandGreen",
                      }}
                      onChange={onSelectContact}
                      formatOptionLabel={formatOptionLabel}
                      customStyle={customStyle}
                      disableClear
                      disableSearch
                      className={`${isPropertyVendor ? "w-full" : "w-80"}`}
                      hideIndicator
                      hideDropdownIndicator
                    />
                  )}
                </div>
              )}
              {(contactDetails?.kind === "member" ||
                !!associatedUsers?.length) &&
                !isBrokerCompany && (
                  <div>
                    <Pill
                      value={
                        contactDetails?.kind === "member"
                          ? "ES Member"
                          : `ES Members (${memberCount})`
                      }
                      className="w-max uppercase mt-1 self-none"
                      background="bg-black"
                      textSize="text-xs"
                      textWeight="font-normal"
                    />
                  </div>
                )}
            </div>
          </>
          {!isBrokerCompany &&
            (currentUser?.isAdmin ||
              currentUser?.isSuperAdmin ||
              currentUser?.hasPermission?.("contacts", "can_delete")) && (
              <IconButton
                wrapperClassName={hovering ? "" : "hidden"}
                imgClassName="w-6 h-6"
                iconClassName="w-6 h-6"
                onClick={toggleEdit}
                icon={editIcon}
              />
            )}
        </div>
      </div>
      {!isCreating && !isPropertyVendor && !isBrokerCompany && (
        <div className="flex flex-row">
          {!!clientChat && contactDetails?.kind === "member" && (
            <SecondaryButton
              iconLeft={<img alt="" src={messageGreenIcon} />}
              title="Message"
              className="ml-4"
              onClick={openChat}
            />
          )}
          {!!emailOptions?.length && (
            <SecondaryButton
              iconLeft={<img alt="" src={emailGreenIcon} />}
              dropdownItems={emailOptions}
              title="Email"
              className="ml-4"
              innerClassName="ml-2 mr-4"
              chevronClassName="pl-4 pr-0"
              chevronColor={BRAND_GREEN}
              strokeWidth="2px"
            />
          )}
          {contactDetails?.kind !== "company" && !!textOptions?.length && (
            <SecondaryButton
              iconLeft={<img alt="" src={phoneGreenIcon} />}
              title="Send Text"
              dropdownItems={textOptions}
              className="ml-4 rounded-md"
              innerClassName="ml-2 mr-4"
              chevronClassName="pl-4 pr-0"
              chevronColor={BRAND_GREEN}
            />
          )}
          {!!phoneOptions?.length && (
            <SecondaryButton
              iconLeft={<img alt="" src={phoneGreenIcon} />}
              dropdownItems={phoneOptions}
              title="Call"
              className="ml-4 rounded-md"
              innerClassName="ml-2 mr-4"
              chevronClassName="pl-4 pr-0"
              chevronColor={BRAND_GREEN}
            />
          )}
        </div>
      )}
    </div>
  );
};

ContactDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  contactDetails: PropTypes.object,
  isCreating: PropTypes.bool,
  openChat: PropTypes.func,
  associatedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.shape({
        thumbnailUrl: PropTypes.string,
      }),
      name: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      isOnline: PropTypes.bool,
    })
  ),
  setContactAvatar: PropTypes.func,
  isPropertyVendor: PropTypes.bool,
  setEditingContact: PropTypes.func,
  isBrokerCompany: PropTypes.bool,
};

ContactDetails.defaultProps = {
  contactDetails: undefined,
  isCreating: false,
  openChat: undefined,
  associatedUsers: undefined,
  setContactAvatar: undefined,
  isPropertyVendor: false,
  setEditingContact: undefined,
  isBrokerCompany: false,
};

export default ContactDetails;

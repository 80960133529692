import { json2csv } from "json-2-csv";
import { toastError, toastMessage } from "../../stories/Components/Toast/Toast";

/**
 * @param {Array} formattedUsers - Array of Formatted users to be downloaded.
 * @param {String} title - String to name file and show in Toast.
 */
const handleDownloadUsers = (formattedUsers, title) => {
  json2csv(
    formattedUsers,
    (err, csv) => {
      if (!err) {
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });

        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.style.display = "none";

        document.body.appendChild(downloadLink);

        downloadLink.setAttribute("href", url);
        downloadLink.setAttribute("download", title);
        downloadLink.click();

        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);
        toastMessage(`Successfully downloaded ${title}`);
      } else {
        toastError(`Error while downloading ${title}, please try again later.`);
      }
    },
    {
      keys: ["Name", "Email", "Company", "Role", "status", "Mobile"],
      emptyFieldValue: "NA",
    }
  );
};

export default handleDownloadUsers;

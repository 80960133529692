import moment from "moment";
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { getWeeks } from "../../../helpers/Calendar";
import { LeftChevron, RightChevron } from "./CalendarNavigation";
import {
  MINICALENDAR_PAD_Y,
  MINI_CALENDAR_BODY_OFFSET,
  MINI_CALENDAR_CELL_DIMENSIONS,
  MINI_CALENDAR_PAD_X,
  MINI_CALENDAR_WIDTH,
  SET_CALENDAR_DATA,
  dayAbbreviations,
} from "../../../constants";
import { useAppState } from "../../../state/appState";

const MiniCalendar = ({ reloadEvents, test }) => {
  const [, dispatch] = useAppState();
  const [month, setMonth] = useState({
    value: moment(test).startOf("month"),
    showNext: () =>
      setMonth((prev) => ({ ...prev, value: prev.value.add(1, "months") })),
    showPrev: () =>
      setMonth((prev) => ({
        ...prev,
        value: prev.value.subtract(1, "months"),
      })),
  });
  const calendarData = getWeeks(month.value.format());
  const today = moment().startOf("day").format();

  const dayColor = useCallback(
    (column) => {
      switch (true) {
        case today === column.iso:
          return "rgba(2, 125, 96, .8)";
        case column?.prev || column?.next:
          return "#CACACA";
        default:
          return "#33333";
      }
    },
    [today]
  );

  const onDayClick = (day) => {
    dispatch({
      type: SET_CALENDAR_DATA,
      data: day,
    });
    reloadEvents(day);
  };

  const miniCalendarHeight =
    (calendarData.length + 1) * 38 + MINICALENDAR_PAD_Y * 2 + 24;

  return (
    <div id="miniCalendar">
      <svg
        viewBox={`0 0 ${MINI_CALENDAR_WIDTH} ${miniCalendarHeight}`}
        width="254px"
        className="bg-gray-50 rounded-md"
      >
        <g id="mini-calendar-header">
          <g id="mini-calendar-header-row-0" transform="translate(0,5)">
            <text
              x={MINI_CALENDAR_PAD_X}
              y={MINICALENDAR_PAD_Y}
              fontWeight="500"
              alignmentBaseline="middle"
              fontSize="17"
            >
              {month.value.format("MMMM YYYY")}
            </text>
            <foreignObject
              id="mini-calendar-navigation"
              x={252 - 60 - MINI_CALENDAR_PAD_X}
              y={MINICALENDAR_PAD_Y - 12}
              width="60"
              height="24"
            >
              <div className="flex items-center h-full justify-between">
                <LeftChevron height="16" onClick={month.showPrev} />
                <RightChevron height="16" onClick={month.showNext} />
              </div>
            </foreignObject>
          </g>
          <g id="mini-calendar-header-row-1">
            {dayAbbreviations?.map((d, i) => {
              return (
                <text
                  key={`${d}`}
                  x={
                    i * MINI_CALENDAR_CELL_DIMENSIONS.width +
                    MINI_CALENDAR_CELL_DIMENSIONS.width / 2
                  }
                  y={
                    MINI_CALENDAR_BODY_OFFSET +
                    MINI_CALENDAR_CELL_DIMENSIONS.height / 2
                  }
                  fontWeight="600"
                  alignmentBaseline="middle"
                  textAnchor="middle"
                  opacity="70%"
                  fontSize="13"
                  fill="#333333"
                >
                  {d[0]}
                </text>
              );
            })}
          </g>
        </g>
        <g id="mini-calendar-body">
          {calendarData.map((row, r) => {
            return row.map((column, c) => {
              return (
                <g
                  id="day-cell"
                  onClick={() => onDayClick(column.iso)}
                  className="cursor-pointer"
                  key={column.iso}
                >
                  {today === column.iso && (
                    <circle
                      cx={
                        c * MINI_CALENDAR_CELL_DIMENSIONS.width +
                        MINI_CALENDAR_CELL_DIMENSIONS.width / 2 -
                        0.5
                      }
                      cy={
                        (r + 1) * MINI_CALENDAR_CELL_DIMENSIONS.height +
                        MINI_CALENDAR_BODY_OFFSET +
                        MINI_CALENDAR_CELL_DIMENSIONS.height / 2 -
                        1
                      }
                      r="13"
                      fill="#1CC39D"
                      opacity="10%"
                    />
                  )}
                  <text
                    x={
                      c * MINI_CALENDAR_CELL_DIMENSIONS.width +
                      MINI_CALENDAR_CELL_DIMENSIONS.width / 2
                    }
                    y={
                      (r + 1) * MINI_CALENDAR_CELL_DIMENSIONS.height +
                      MINI_CALENDAR_BODY_OFFSET +
                      MINI_CALENDAR_CELL_DIMENSIONS.height / 2
                    }
                    alignmentBaseline="middle"
                    fontWeight="600"
                    textAnchor="middle"
                    opacity={today === column.iso ? "100%" : "70%"}
                    fontSize="13"
                    fill={dayColor(column)}
                  >
                    {column.day}
                  </text>
                </g>
              );
            });
          })}
        </g>
      </svg>
    </div>
  );
};

MiniCalendar.propTypes = {
  reloadEvents: PropTypes.func,
  // Test Day to match snapshots
  test: PropTypes.string,
};

MiniCalendar.defaultProps = {
  reloadEvents: () => {},
  test: undefined,
};

export default MiniCalendar;

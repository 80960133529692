import React from "react";
import PropTypes from "prop-types";
import InputMentions from "../InputMentions";
import IconLabel from "../IconLabel/IconLabel";
import DMIcon from "../../assets/images/DMIconNew.svg";
import blackCrossIcon from "../../assets/images/Close.svg";
import CommentRow from "./CommentRow";
import CrossButton from "../CrossButton/CrossButton";
import useCommentsContainerData from "./useCommentsContainerData";
import { COMMENT_MODAL_WIDTH } from "../../../constants";

const CommentsContainer = ({
  comments,
  useReplyMode,
  onReplyPressed,
  onSend,
  disabledInput,
  selectedCommentId,
  onCloseReply,
  mentionSuggestions,
}) => {
  const {
    containerRef,
    inputRef,
    message,
    setMessage,
    handleEnter,
    handleSendMessage,
  } = useCommentsContainerData({
    comments,
    useReplyMode,
    onReplyPressed,
    onSend,
    selectedCommentId,
    onCloseReply,
  });

  return (
    <div
      className={useReplyMode ? "px-4 border-l border-gray-light" : ""}
      style={{
        width: COMMENT_MODAL_WIDTH,
      }}
    >
      {useReplyMode && (
        <div className="py-2 flex justify-between items-center">
          <h1 className="font-semibold text-xl">Thread</h1>
          <CrossButton onClick={onCloseReply} icon={blackCrossIcon} />
        </div>
      )}
      <div
        ref={containerRef}
        className="bg-white overflow-y-auto"
        style={{
          minHeight: useReplyMode ? 10 : 400,
          maxHeight: useReplyMode ? 355 : 400,
        }}
      >
        <div className="list-disc">
          {comments.map((comment) => (
            <CommentRow
              key={comment.id}
              comment={comment}
              useReplyMode={useReplyMode}
              onReplyPressed={onReplyPressed}
              selectedCommentId={selectedCommentId}
            />
          ))}
        </div>
      </div>

      {/* Input text and button */}
      <div className="bg-white pb-4 px-4">
        <InputMentions
          ref={inputRef}
          placeholder={
            useReplyMode ? "Reply..." : "Type @ to mention and notify someone."
          }
          trigger="@"
          text={message}
          data={mentionSuggestions}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleEnter}
          disabled={disabledInput}
          resizable={false}
        />
        <div className="mt-2 flex justify-end">
          <IconLabel
            icon={DMIcon}
            text="Send"
            textClassName="text-darkenedGreen text-base font-normal"
            direction="right"
            onClick={handleSendMessage}
            resizable={false}
          />
        </div>
      </div>
    </div>
  );
};

CommentsContainer.defaultProps = {
  comments: [],
  mentionSuggestions: [],
  useReplyMode: false,
  onReplyPressed: undefined,
  onSend: undefined,
  disabledInput: false,
  selectedCommentId: undefined,
  onCloseReply: undefined,
};

CommentsContainer.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape({})),
  mentionSuggestions: PropTypes.arrayOf(PropTypes.shape({})),
  useReplyMode: PropTypes.bool,
  onReplyPressed: PropTypes.func,
  onSend: PropTypes.func,
  disabledInput: PropTypes.bool,
  selectedCommentId: PropTypes.string,
  onCloseReply: PropTypes.func,
};

export default CommentsContainer;

import React from "react";
import PropTypes from "prop-types";
import PrimaryButton from "../Buttons/PrimaryButton";
import useRunReportButtonData from "./useRunReportButtonData";

const RunReportButton = ({ reportId }) => {
  const { isRunning, handleRunReport } = useRunReportButtonData({ reportId });

  return (
    <PrimaryButton
      saveButton
      saveButtonTitle={isRunning ? "Running Report..." : "Run Report"}
      disabled={isRunning}
      onClick={handleRunReport}
    />
  );
};

RunReportButton.defaultProps = {
  reportId: undefined,
};

RunReportButton.defaultProps = {
  reportId: PropTypes.string,
};

export default RunReportButton;

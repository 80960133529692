import React from "react";
import { toast } from "react-toastify";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../assets/images/whiteExclamationIcon.svg";
import whiteCircleCheckIcon from "../../assets/images/circleCheckIcon.svg";
import reloadIcon from "../../assets/images/reloadIconWhite.svg";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const toastMessage = (msg) => {
  return toast.success(msg, {
    toastId: "toast-is-active",
    position: "top-center",
    autoClose: 3000,
    icon: toastIcon,
    closeButton: toastCloseIcon,
    hideProgressBar: true,
    className: "bg-brandGreen text-white px-6 py-0 mb-0",
    style: { minHeight: "43px", top: "29px" },
  });
};

/**
 *
 * @param {String} msg - message displayed in toast
 * @param {Boolean} refresh - optional Refresh page button
 *
 */
const toastError = (msg, refresh = false) => {
  return toast.error(
    refresh ? (
      <div className="inline-flex gap-3">
        {msg}
        <button onClick={() => window.location.reload()} type="button">
          <img
            src={reloadIcon}
            className="inline-block"
            alt="Successful upload"
          />
        </button>
      </div>
    ) : (
      msg
    ),
    {
      toastId: "toast-is-active",
      position: "top-center",
      autoClose: 3000,
      icon: toastErrorIcon,
      closeButton: toastCloseIcon,
      hideProgressBar: true,
      className: `bg-errorRed text-white px-6 py-0 mb-0`,
      style: { minHeight: "43px", top: "29px" },
    }
  );
};

/**
 *
 * @param {String} msg - message displayed in toast
 * @param {Boolean} refresh - optional Refresh page button
 *
 */
const toastWarning = (msg) => {
  return toast.warn(msg, {
    toastId: "toast-is-active",
    position: "top-center",
    autoClose: 3000,
    icon: toastErrorIcon,
    closeButton: toastCloseIcon,
    hideProgressBar: true,
    className: `bg-es-yellow text-white px-4 py-0 mb-0`,
    style: { minHeight: "43px", top: "29px" },
  });
};

export { toastMessage, toastError, toastWarning };

import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useAppState } from "../../../../state/appState";
import Checkbox from "../../Checkbox/Checkbox";
import { taskDueDate } from "../../../../helpers/Calendar";
import { getFullName } from "../../../../helpers/Formatters";
import Avatar from "../../Message/Avatar";
import { DateIsAfter } from "../../../../helpers/Dates";
import { useModalState } from "../../../../state/modalState";
import {
  ADD_OPEN_MODAL,
  MARK_COMPLETE,
  VIEW_TASK_MODAL,
} from "../../../../constants";

const TaskItem = ({
  id,
  instanceStartDate,
  name,
  status,
  endDate,
  task,
  close,
  ...rest
}) => {
  const [{ userDict }] = useAppState();
  const [, modalDispatch] = useModalState();
  const containerRef = useRef(null);
  const invitees = useMemo(() => {
    return rest?.invitees.slice(0, 2).map((item) => userDict?.[item]);
  }, [rest?.invitees, userDict]);
  const handleOpenTask = ({ position, modalData }) => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: task.id },
      position,
      modalData,
      modalType: VIEW_TASK_MODAL,
    });
    close();
  };

  const handleOpenCompleteModal = ({ position, modalData }) => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: task.id },
      position,
      modalData,
      modalType: MARK_COMPLETE,
    });
  };
  return (
    <div
      className="flex flex-col gap-4 p-4 hover:bg-gray-50 cursor-pointer"
      onClick={() => {
        const { width, height, left, top } =
          containerRef.current.getBoundingClientRect();
        handleOpenTask({
          position: { x: left, y: top },
          modalData: { item: task, width, height },
        });
      }}
      onKeyDown={() => {
        const { width, height, left, top } =
          containerRef.current.getBoundingClientRect();
        handleOpenTask({
          position: { x: left, y: top },
          modalData: { item: task, width, height },
        });
      }}
      tabIndex={0}
      role="button"
      key={`${id}`}
      ref={containerRef}
    >
      <div className="flex gap-5">
        <Checkbox
          size="6"
          checked={status === "done"}
          stopEventPropagation
          onChange={() => {
            const { width, height, left, top } =
              containerRef.current.getBoundingClientRect();
            handleOpenCompleteModal({
              position: { x: left, y: top },
              modalData: {
                item: {
                  ...task,
                },
                width,
                height,
              },
            });
          }}
          inputClassName="calendar-form-tick"
          disabled={status === "done"}
        />
        <p className="text-md font-normal">{name}</p>
      </div>
      <div className="grid grid-cols-6">
        <div className="col-start-2 col-span-5 flex">
          <span
            className={`block border-2 ${
              DateIsAfter(endDate, moment().format())
                ? "border-gray-500 text-gray-500"
                : "border-errorRed text-errorRed"
            } bg-white p-2 rounded-full font-semibold text-xxs `}
          >
            {taskDueDate(endDate)}
          </span>
          <div className="flex flex-row ml-3">
            {invitees.slice(0, 2).map((user) => (
              <Avatar
                className="text-xs w-9 h-9 !border-transparent"
                name={getFullName(user?.name)}
                isOnline={user?.isOnline}
                isExternalUser
                id={user?.value}
                avatar={user?.avatar}
                key={user?.id}
              />
            ))}
            {invitees?.length > 2 && (
              <div className="bg-gray-100 rounded-full w-9 h-9 flex justify-center">
                <p className="uppercase text-gray-450 text-xxs truncate self-center font-medium">
                  +{invitees?.length - 2}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

TaskItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  instanceStartDate: PropTypes.string,
  status: PropTypes.string,
  endDate: PropTypes.string,
  task: PropTypes.shape({ id: PropTypes.string }),
  close: PropTypes.func,
};

TaskItem.defaultProps = {
  id: undefined,
  instanceStartDate: undefined,
  name: undefined,
  status: undefined,
  endDate: undefined,
  task: undefined,
  close: () => {},
};

export default TaskItem;

/**
 * Return an array with a single Sop category
 * @param {Object[]} sopCategories - List of Sops categories
 * @param {string} catId - Sop category Id
 * @returns - Array with single Sop category
 */
const getCategory = (sopCategories = [], categoryId) => {
  const categories = sopCategories.filter(
    (category) => category.id === categoryId
  );

  if (categories.length) {
    return [{ id: categories[0].id, display: categories[0].display }];
  }

  return [{}];
};

export default getCategory;

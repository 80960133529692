import React from "react";

import groupDateTotals from "../../../../../../helpers/Timesheets/groupDateTotals";

const TotalsRow = ({ entriesForEditing }) => {
  const entryTotalsByDate = groupDateTotals(entriesForEditing);

  return (
    <>
      <div className="grid col-span-4 gap-3">
        <p className="font-semibold text-gray-300">Daily Total Time Worked</p>
      </div>
      <div className="col-span-6 grid grid-cols-7 gap-4 ml-6">
        {Object.entries(entryTotalsByDate)?.map(([date, value]) => {
          if (value === 0)
            return (
              <p key={date} className="pl-2 col-span-1 text-gray-450">
                --
              </p>
            );
          return (
            <p key={date} className="pl-2 col-span-1 text-gray-450">
              {value / 60} hrs
            </p>
          );
        })}
      </div>
    </>
  );
};

export default TotalsRow;

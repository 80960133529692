import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import useDocumentsConfiguration from "../../../hooks/useDocumentsConfiguration";

import UploadArea from "../UploadArea/UploadArea";
import DroppedFile from "../DragDropDialog/DroppedFile";

const SimpleFileUpLoad = ({
  title,
  files,
  extensions,
  defaultType,
  onFilesAdded,
  onFilesUpdated,
  customUploadAreaStyle,
  sort,
  simple,
  shared,
  disabled,
  isRequestUpload,
  nestedButtonWrapperCN,
}) => {
  const showDroppedFiles = false;
  const { data: docConfig } = useDocumentsConfiguration();
  const [acceptedFiles, setAcceptedFiles] = useState(files);

  const docTypeOptions = React.useMemo(() => {
    return docConfig?.documents?.documentType
      .filter((doc) => doc.custom)
      .map((doc) => {
        return { label: doc.display, value: doc.id };
      })
      .sort(({ label: a }, { label: b }) => a.localeCompare(b));
  }, [docConfig]);

  const docTypeOptionsMap = React.useMemo(() => {
    return {
      ...docConfig?.documents?.documentType
        .filter((doc) => doc.selected && (doc.value || doc.id))
        .reduce((obj, item) => {
          return {
            ...obj,
            [item.value ?? item.id]: {
              label: item.display,
              value: item.value ?? item.id,
            },
          };
        }, {}),
    };
  }, [docConfig]);

  useEffect(() => {
    setAcceptedFiles(files);
  }, [files]);

  const handleFilesAdded = React.useCallback(
    (newFiles) => {
      let fileList;
      if (!sort) {
        setAcceptedFiles((prev) => {
          fileList = [
            ...prev,
            ...newFiles?.map((file) => {
              return {
                id: uuidv4(),
                original: file,
                name: file.name,
                type: file.type ?? defaultType,
                ...file,
              };
            }),
          ];
          onFilesAdded(fileList);
          return fileList;
        });
        return;
      }

      fileList = acceptedFiles;
      newFiles?.forEach((file) => {
        const formattedFile = {
          id: uuidv4(),
          original: file,
          name: file.name,
          type: file.type ?? defaultType,
          ...file,
        };
        if (file.type.includes("image")) {
          if (fileList.images) {
            fileList.images.push({
              ...formattedFile,
              index: fileList.images.length,
            });
            return;
          }
          fileList.images = [{ ...formattedFile, index: 0 }];
          return;
        }
        if (file.type.includes("video")) {
          if (fileList.videos) {
            fileList.videos.push({
              ...formattedFile,
              index: fileList.videos.length,
            });
            return;
          }
          fileList.videos = [{ ...formattedFile, index: 0 }];
          return;
        }
        if (fileList.files) {
          fileList.files.push({
            ...formattedFile,
            index: fileList.files.length,
          });
          return;
        }
        fileList.files = [{ ...formattedFile, index: 0 }];
      });
      onFilesAdded(fileList);
      setAcceptedFiles(fileList);
    },
    [acceptedFiles, defaultType, onFilesAdded, sort]
  );
  const handleFileNameChange = (id, val, fileType) => {
    let updatedFiles = acceptedFiles;
    if (sort) {
      updatedFiles[fileType] = updatedFiles[fileType]?.map((file) => {
        if (file.id === id) {
          return { ...file, name: val };
        }
        return file;
      });
      onFilesUpdated(updatedFiles);
      return;
    }
    updatedFiles = acceptedFiles?.map((file) => {
      if (file.id === id) {
        return { ...file, name: val };
      }
      return file;
    });
    onFilesUpdated(updatedFiles);
  };
  const handleFileTypeChange = (id, val, fileType) => {
    let updatedFiles = acceptedFiles;
    if (sort) {
      updatedFiles[fileType] = updatedFiles[fileType]?.map((file) => {
        if (file.id === id) {
          return { ...file, docType: val };
        }
        return file;
      });
      onFilesUpdated(updatedFiles);
      return;
    }
    updatedFiles = acceptedFiles?.map((file) => {
      if (file.id === id) {
        return { ...file, docType: val };
      }
      return file;
    });
    onFilesUpdated(updatedFiles);
  };
  const handleFileFavorited = (id, val, fileType) => {
    let updatedFiles = acceptedFiles;
    if (sort) {
      updatedFiles[fileType] = updatedFiles[fileType]?.map((file) => {
        if (file.id === id) {
          return { ...file, isFavorited: val };
        }
        return file;
      });
      onFilesUpdated(updatedFiles);
      return;
    }
    updatedFiles = acceptedFiles?.map((file) => {
      if (file.id === id) {
        return { ...file, isFavorited: val };
      }
      return file;
    });
    onFilesUpdated(updatedFiles);
  };

  const handleFileRemoved = (id, fileType) => {
    if (sort) {
      setAcceptedFiles((prev) => ({
        ...prev,
        [fileType]: prev[fileType].filter((item) => item.id !== id),
      }));
      return;
    }
    setAcceptedFiles((prev) => prev.filter((item) => item.id !== id));
  };

  return (
    <>
      {showDroppedFiles && (
        <div className="flex">
          {acceptedFiles?.map((file) => (
            <div key={file.id} className="mb-6">
              <DroppedFile
                file={file}
                defaultType={defaultType}
                docTypeOptions={docTypeOptions}
                docTypeOptionsMap={docTypeOptionsMap}
                onNameChange={handleFileNameChange}
                onTypeChange={handleFileTypeChange}
                onFavorited={handleFileFavorited}
                onRemove={handleFileRemoved}
              />
            </div>
          ))}
        </div>
      )}
      <UploadArea
        isRequestUpload={isRequestUpload}
        disabled={disabled}
        title={title}
        className="flex-row"
        extensions={extensions}
        onFilesAdd={handleFilesAdded}
        customUploadAreaStyle={customUploadAreaStyle}
        simple={simple}
        shared={shared}
        nestedButtonWrapperCN={nestedButtonWrapperCN}
      />
    </>
  );
};

SimpleFileUpLoad.propTypes = {
  title: PropTypes.string,
  /**
   * files selected for upload
   */
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.array,
  /**
   * optional accepted file types
   */
  extensions: PropTypes.string,
  /**
   * optional accepted file types
   */
  defaultType: PropTypes.string,
  /**
   * function called when files added
   */
  onFilesAdded: PropTypes.func,
  /**
   * function called when files added
   */
  onFilesUpdated: PropTypes.func,
  /**
   * function called when files uploaded
   */
  customUploadAreaStyle: PropTypes.shape({}),
  /**
   * Flag to sort files by extension
   */
  sort: PropTypes.bool,
  /**
   * flag to use addDeescriptionPlusIcon instead of drag and drop uploader
   */
  simple: PropTypes.bool,
  disabled: PropTypes.bool,
  shared: PropTypes.bool,
  isRequestUpload: PropTypes.bool,
  nestedButtonWrapperCN: PropTypes.string,
};

SimpleFileUpLoad.defaultProps = {
  disabled: false,
  simple: false,
  title: undefined,
  files: [],
  extensions: "",
  defaultType: undefined,
  onFilesAdded: () => {},
  onFilesUpdated: () => {},
  customUploadAreaStyle: {},
  sort: false,
  shared: false,
  isRequestUpload: false,
  nestedButtonWrapperCN: "",
};

export default SimpleFileUpLoad;

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CSICodeAndMappingViewCode from "./CSICodeAndMappingViewCode";
import { COA_CODE_TYPES } from "../../../constants";
import SecondaryButton from "../Buttons/SecondaryButton";

const CSICodesAndMappingSection = ({
  financialsSettings,
  dispatchFinancialsSettings,
}) => {
  const [revenueAccountingCodes, setRevenueAccountingCodes] = useState([]);
  const [expenseAccountingCodes, setExpenseAccountingCodes] = useState([]);
  useEffect(() => {
    setRevenueAccountingCodes(
      financialsSettings?.chartOfAccounts?.filter(
        (code) => code.codeType === COA_CODE_TYPES[1].value
      )
    );
    setExpenseAccountingCodes(
      financialsSettings?.chartOfAccounts?.filter(
        (code) => code.codeType === COA_CODE_TYPES[0].value
      )
    );
  }, [financialsSettings]);

  const expandAll = useCallback(() => {
    dispatchFinancialsSettings({
      type: "exapandAllCSIDivisionMappings",
    });
  }, [dispatchFinancialsSettings]);

  const collapseAll = useCallback(() => {
    dispatchFinancialsSettings({
      type: "collapseAllCSIDivisionMappings",
    });
  }, [dispatchFinancialsSettings]);

  return (
    <div className="flex flex-col pb-2">
      <div className="flex py-2 px-0 justify-end">
        <div className="flex flex-row ">
          <div className="pr-2">
            <SecondaryButton title="Expand All" onClick={expandAll} />
          </div>
          <SecondaryButton title="Collapse All" onClick={collapseAll} />
        </div>
      </div>
      {financialsSettings?.csiCodeMapping?.map((mapping, index) => {
        return (
          <div className="my-1" key={mapping?.id}>
            <CSICodeAndMappingViewCode
              mapping={mapping}
              mappingIndex={index}
              revenueAccountingCodes={revenueAccountingCodes}
              expenseAccountingCodes={expenseAccountingCodes}
              dispatchFinancialsSettings={dispatchFinancialsSettings}
            />
          </div>
        );
      })}
    </div>
  );
};

CSICodesAndMappingSection.propTypes = {
  financialsSettings: PropTypes.shape({
    csiCodeMapping: PropTypes.arrayOf(
      PropTypes.shape({
        division: PropTypes.string,
        description: PropTypes.string,
        csiCodes: PropTypes.arrayOf(
          PropTypes.shape({
            code: PropTypes.string,
            description: PropTypes.string,
            revenueCode: PropTypes.string,
            selectedForBudgets: PropTypes.string,
            selectedForTimesheets: PropTypes.bool,
            subCodes: PropTypes.arrayOf(
              PropTypes.shape({
                code: PropTypes.string,
                expenseCode: PropTypes.string,
                selectedForBudgets: PropTypes.string,
                selectedForTimesheets: PropTypes.bool,
              })
            ),
          })
        ),
        selectedForBudgets: PropTypes.string,
        selectedForTimesheets: PropTypes.bool,
        isOpen: PropTypes.bool,
      })
    ),
    chartOfAccounts: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        codeType: PropTypes.string,
        description: PropTypes.string,
        selectedForBudgets: PropTypes.bool,
        selectedForTimesheets: PropTypes.bool,
      })
    ),
  }),

  dispatchFinancialsSettings: PropTypes.func,
};

CSICodesAndMappingSection.defaultProps = {
  financialsSettings: undefined,
  dispatchFinancialsSettings: undefined,
};

export default CSICodesAndMappingSection;

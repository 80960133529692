import { useCallback } from "react";
import useUserDict from "./useUserDict";
import useReports from "./useReports";
import useDescribeRruleFromString from "./useDescribeRruleFromString";
import capitalizeFirstLetter from "../helpers/Utilities/capitalizeFirstLetter";
import { REPORT_EXPORT_TYPES } from "../constants";
import { usePropertiesOverview } from "./properties";
import { useProjectsOverview } from "./projects";
import { useAssetsOverview } from "./assets";

const transformDistributionList = (distribution = [], userDict = {}) =>
  distribution.map((userRef) => {
    const user = userDict[userRef];
    return user;
  });

/**
 * Query selector to return Reports data in the desire format table
 * @param {string} association (?) association reference
 * @returns reports in custom format
 */
const useReportsTableFormat = ({ association } = {}) => {
  const { assetsDict } = useAssetsOverview();
  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  const { getRruleInHumanReadable } = useDescribeRruleFromString();
  const { data: userDict } = useUserDict();

  const select = useCallback(
    (reports) =>
      reports.map((report) => {
        const associationSelected =
          propertiesDict?.[report.association] ||
          projectDict?.[report.association] ||
          assetsDict?.[report.association];

        const outputType =
          REPORT_EXPORT_TYPES.find(({ value }) => value === report?.outputType)
            ?.label ?? "Not specified";

        // Transform `sources` to human readable
        const sources = (
          report.sources.map((source) => {
            const associationObj =
              propertiesDict?.[source] ||
              projectDict?.[source] ||
              assetsDict?.[source];

            return associationObj?.title || associationObj?.name;
          }) ?? []
        ).join(", ");

        return {
          ...report,
          association: associationSelected?.name ?? associationSelected?.title,
          outputType,
          repeat: getRruleInHumanReadable(report.repeat),
          sourceType: capitalizeFirstLetter(report?.sourceType) ?? "N/A",
          sources,
          distribution: transformDistributionList(
            report?.distribution,
            userDict
          ),
        };
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectDict, propertiesDict, assetsDict, userDict]
  );

  return useReports({ association, select });
};

export default useReportsTableFormat;

import moment from "moment";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CommentAPI } from "@griffingroupglobal/eslib-api";
import IconButton from "../../../stories/Components/Buttons/IconButton";
import moreInformationIcon from "../../../stories/assets/images/moreInformation.svg";
import chevronRightIcon from "../../../stories/assets/images/chevronRight.svg";
import whiteCrossIcon from "../../../stories/assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../../stories/assets/images/whiteExclamationIcon.svg";
import CommentSection from "../../../stories/Components/Comments/CommentSection";
import { toastError } from "../../../helpers/Toast";

const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);

const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const HistorySection = ({
  history,
  historyUser,
  users,
  historyActions,
  currentUser,
  updateComment,
  index,
  length,
}) => {
  const [historyComments, setHistoryComments] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const [moreOptions] = useState([]);

  useEffect(() => {
    if (history?.resource === "Comment" && users) {
      setHistoryComments([
        {
          ...history,
          userData: historyUser,
          replies: history?.replies?.map((reply) => ({
            ...reply,
            userData: users?.[reply?.author],
          })),
        },
      ]);
    }
  }, [history, historyUser, users]);

  /**
   * Comment Functionality: Start
   */

  const handlePostReply = async (value, commentId) => {
    try {
      if (value.trim().length > 0) {
        const tempObj = {
          content: value,
          author: currentUser.reference,
        };
        const { data: reply } = await CommentAPI.postWOP(
          `${commentId}/$addreply`,
          {
            ...tempObj,
          }
        );

        const replyComment = {
          ...reply,
          userData: currentUser,
          replies: [],
        };
        updateComment(index, {
          ...history,
          replies: [...history.replies, replyComment],
        });
        setHistoryComments([
          {
            ...historyComments[0],
            replies: [...historyComments[0].replies, replyComment],
          },
        ]);
      }
    } catch (error) {
      toastError(
        `Error posting Comment: ${JSON.stringify(error)}`,
        toastErrorIcon,
        toastCloseIcon
      );
    }
  };

  /**
   * Comment Functionality: End
   */

  return (
    <div className="flex flex-col pl-5">
      <div className="flex flex-col justify-between">
        <div className="grid grid-cols-2 w-full pr-8 pl-1 pb-1">
          <div className="flex items-center font-normal text-gray-400">
            {historyComments.length > 0 && (
              <div className="pt-1 pr-3">
                <IconButton
                  icon={chevronRightIcon}
                  className={`${
                    showComments
                      ? "transform origin-center rotate-90 nl-0 w-10"
                      : "nl-0 pb-1.5 w-10"
                  }`}
                  imgClassName="w-4 h-4"
                  onClick={() => setShowComments((prev) => !prev)}
                  isDisabled={!users}
                />
              </div>
            )}
            <div className={`${historyComments?.length === 0 ? "ml-7" : ""}`}>
              {history?.message ?? historyActions?.[history?.action]}
            </div>
          </div>

          <div className="flex text-gray-400 justify-self-end">
            <div className="flex flex-col">
              <div className="text-gray-400 justify-self-end">
                {historyUser?.fullName}
              </div>
              <p>
                {moment(history?.when ?? history?.metadata?.createdAt).format(
                  "lll"
                )}
              </p>
            </div>

            {moreOptions?.length > 0 && (
              <div className="w-20 h-7 flex justify-center pr-1 items-center">
                <IconButton
                  icon={moreInformationIcon}
                  dropdownItems={moreOptions}
                  imgClassName="w-5 h-5"
                  hideDropdownChevron
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={`object-bottom mx-8 ${index !== length ? "border-b" : ""}`}
        >
          <div className="overflow-auto">
            <div className="grid grid-cols-2">
              {/* {(showComments || addComment) && ( */}
              {history?.resource === "Comment" && showComments && (
                <CommentSection
                  data={historyComments}
                  // handlePostComment={handlePostComment}
                  handlePostReply={handlePostReply}
                  allowNewComment
                  hideCommentsTag
                  //   showNewCommentSection={addComment}
                  //   setShowNewCommentSection={setAddComment}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HistorySection.propTypes = {
  history: PropTypes.shape({
    message: PropTypes.string,
    when: PropTypes.string,
    action: PropTypes.string,
    replies: PropTypes.arrayOf(PropTypes.shape({})),
    resource: PropTypes.string,
    metadata: PropTypes.shape({
      createdAt: PropTypes.string,
    }),
  }),
  historyUser: PropTypes.shape({
    fullName: PropTypes.string,
  }),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * Constant Actions Pre-defined
   */
  historyActions: PropTypes.shape({}),
  currentUser: PropTypes.shape({
    reference: PropTypes.string,
  }),
  updateComment: PropTypes.func,
  /**
   * Index of history item in hook state
   */
  index: PropTypes.number,
  /**
   * Length of history items
   */
  length: PropTypes.number,
};

HistorySection.defaultProps = {
  history: undefined,
  historyUser: undefined,
  users: undefined,
  historyActions: undefined,
  currentUser: undefined,
  updateComment: undefined,
  index: undefined,
  length: 0,
};

export default HistorySection;

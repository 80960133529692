import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import IconButton from "../Buttons/IconButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import Dropdown from "../Dropdown/Dropdown";
import useWorkflowFileReducer from "../../../hooks/usePropertyFormReducer";
import expandGreenIcon from "../../assets/images/expandGreenIcon.svg";
import WorkflowFileForm from "../FileForm/WorkflowFileForm";
import useCurrentUser from "../../../hooks/useCurrentUser";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import CrossButton from "../CrossButton/CrossButton";
import CommentSectionWF from "../Comments/CommentSectionWF";
import WorkflowDocViewModal from "../WorkflowDocViewModal/WorkflowDocViewModal";
import PrimaryButton from "../Buttons/PrimaryButton";
import circleCheckIcon from "../../assets/images/circleCheckIcon.png";
import resubmissionGreenIcon from "../../assets/images/resubmissionGreenIcon.svg";
import commentIcon from "../../assets/images/commentIcon.svg";
import expandBackIcon from "../../assets/images/expandBackIcon.svg";
import NewComment from "../Comments/NewComment";
import { getFullName } from "../../../helpers/Formatters";
import { RESOLVED_WF, RESUBMISSION_WF_STEP } from "../../../constants";
import PDFViewer from "../PDFViewer/PDFViewer";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteCircleCheckIcon from "../../assets/images/circleCheckIcon.svg";
import InputModal from "../InputModal/InputModal";
import { getDocumentPdfUrl } from "../../../helpers/Document";
import Spinner from "../Spinner/Spinner";

const WorkflowFileView = ({
  handleAddFiles,
  data,
  handlePostComment,
  handlePostReply,
  parentIndex,
  cardStatus,
  workflowData,
  canAdvance,
  handleAdvanceStep,
  handleResubmitStep,
  currentDoc,
  expanded,
  onSetExpanded,
  onCloseExternalModal,
  commentsOnly,
  hideComments,
  onDownload,
}) => {
  const [files, dispatch] = useWorkflowFileReducer();
  const { data: currentUser } = useCurrentUser();
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [showPageComment, setShowPageComment] = useState(false);
  const [expandedFile, setExpandedFile] = useState(expanded);
  const [selectedFile, setSelectedFile] = useState({});
  const [fileOptions, setFileOptions] = useState();
  const [showSpinner, setShowSpinner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [advanceData, setAdvanceData] = useState({});
  const [requestData, setRequestData] = useState({});
  const [showComments, setShowComments] = useState(false);
  const [commentsCount, setCommentsCount] = useState(0);
  const [selectedFilePdfUrl, setSelectedFilePdfUrl] = useState(null);

  const handleCloseUploader = () => {
    setIsUploadOpen(false);
  };

  useEffect(() => {
    if (fileOptions && currentDoc) {
      setSelectedFile(
        fileOptions.find((file) => file.value === currentDoc.reference)
      );
    }
  }, [fileOptions, currentDoc]);

  useEffect(() => {
    setExpandedFile(expanded);
  }, [expanded]);

  useEffect(() => {
    const mainFileDD =
      workflowData?.documents?.map((info) => ({
        ...info,
        label: `${info.customName || info.name} (Original)`,
        value: info.reference,
      })) || [];
    const fileDD =
      data?.documents?.map((info) => ({
        ...info,
        label: `${info.customName || info.name} (${getFullName(
          info.metadata?.userData?.name
        )})`,
        value: info.reference,
      })) || [];
    const allFiles = [...mainFileDD, ...fileDD];
    // intentionally commented out -- fixes wrong doc upload bug, doesn't appear to break anything else, but leaving. Will remove after 3.6 release.
    /*     if (currentDoc || allFiles.length > 0) {
      setSelectedFile({ ...currentDoc, ...(allFiles[0] ?? {}) });
    } */
    setFileOptions(allFiles);
    if (showSpinner)
      toast.success("Your files have been successfully uploaded", {
        toastId: "toast-is-active",
        position: "top-center",
        autoClose: 3000,
        icon: () => <img src={whiteCircleCheckIcon} alt="Successful upload" />,
        closeButton: <img src={whiteCrossIcon} alt="Close notice" />,
        hideProgressBar: true,
        className: "bg-brandGreen text-white px-6 py-0 mb-0",
        style: { minHeight: "43px", top: "29px" },
      });
    setShowSpinner(false);

    dispatch({ type: "reset" });
  }, [
    data?.documents,
    dispatch,
    showSpinner,
    workflowData?.documents,
    currentDoc,
  ]);

  useEffect(() => {
    const setUrl = async () => {
      setIsLoading(true);
      const id = selectedFile.value.split("/")[1];
      const url = await getDocumentPdfUrl(id);
      setIsLoading(false);
      setSelectedFilePdfUrl(url);
    };
    if (selectedFile?.value) {
      setUrl();
    }
  }, [selectedFile]);

  // To make the comments scrollable we need to set some height and overflow-auto.
  // Below in the render I set it to "900px" because we want it to match to the height of the PDF Viewer on the left.
  // If we don't have comments we don't want to have that big "900px" screen
  // So I added a condition only if we have more than 3 comments or replies otherwise the default is "auto".
  // This is the function that will count the comments with replies.
  useEffect(() => {
    let repliesCount = 0;
    data.comments?.forEach((comment) => {
      if (comment.replies.length > 0) {
        repliesCount += comment.replies.length;
      }
    });
    setCommentsCount(data.comments?.length ?? 0 + repliesCount);
  }, [data.comments]);

  const handleToggleAdvance = () => {
    setAdvanceData((prev) => ({ isOpen: !prev.isOpen, comment: "" }));
    setRequestData({ isOpen: false, comment: "" });
  };
  const handleToggleRequest = () => {
    setRequestData((prev) => ({ isOpen: !prev.isOpen, comment: "" }));
    setAdvanceData({ isOpen: false, comment: "" });
  };
  const handleAdvanceData = (key, value) => {
    const tempObj = { ...advanceData };
    tempObj[key] = value;
    setAdvanceData(tempObj);
  };
  const handleRequestData = (key, value) => {
    const tempObj = { ...requestData };
    tempObj[key] = value;
    setRequestData(tempObj);
  };
  const handleAdvance = () => {
    const memberIndex = data.members.findIndex(
      (memb) => memb.reference === currentUser.reference
    );
    handleAdvanceStep(memberIndex, advanceData.comment);
    handleToggleAdvance();
  };
  const handleResubmit = () => {
    const memberIndex = data.members.findIndex(
      (memb) => memb.reference === currentUser.reference
    );
    handleResubmitStep(memberIndex, requestData.comment);
    handleToggleRequest();
  };

  const handlePageComment = () => {
    setShowPageComment((prev) => !prev);
  };
  const handleExpandedFile = () => {
    let val;
    setExpandedFile((prev) => {
      val = !prev;
      return val;
    });
    onSetExpanded(val);
    setAdvanceData({});
    setRequestData({});
    setIsUploadOpen(false);
  };
  const handleFileUpload = (documentsToUpload) => {
    handleAddFiles(files.files, documentsToUpload);
    setShowSpinner(true);
  };

  const handleAddComment = (val) => {
    handlePostComment(val, data.id, parentIndex, selectedFile?.value);
  };

  const FileExpandContent = () => (
    <div className="flex flex-col w-full h-80vh">
      <div className="bg-gray-500 flex flex-row px-8 py-4 items-center justify-between">
        <div className="flex flex-row">
          {/*           <Dropdown
            className="w-96 flex-none bg-white mr-4"
            value={selectedFile || null}
            options={fileOptions}
            onChange={setSelectedFile}
            disableSort
          /> */}
          {workflowData.status !== RESOLVED_WF && (
            <TertiaryButton
              title="Download"
              onClick={() => onDownload(currentDoc?.reference)}
              disabled={!currentUser?.hasPermission("workflow", "can_create")}
            />
          )}
        </div>
        <div className="flex flex-row items-center">
          {canAdvance && (
            <>
              <div className="relative">
                <PrimaryButton
                  title="Advance"
                  className="w-80 mr-2"
                  noMaxWidth
                  iconLeft={
                    <img
                      src={circleCheckIcon}
                      alt="chackMark"
                      className="w-4 h-4 mr-1"
                    />
                  }
                  onClick={handleToggleAdvance}
                  disabled={
                    !currentUser?.hasPermission("workflow", "can_create")
                  }
                />
                {advanceData.isOpen && (
                  <InputModal
                    title="Advance"
                    buttonTitle="Submit to Advance"
                    placeholder="Include comments (optional)"
                    onClose={handleToggleAdvance}
                    inputValue={advanceData?.comment}
                    onInputChange={(val) => handleAdvanceData("comment", val)}
                    onSubmit={handleAdvance}
                  />
                )}
              </div>
              <div className="relative">
                <SecondaryButton
                  title="Request Resubmission"
                  className="w-80 mr-2"
                  noMaxWidth
                  onClick={handleToggleRequest}
                  iconLeft={
                    <img
                      src={resubmissionGreenIcon}
                      alt="chackMark"
                      className="w-4 h-4 mr-1"
                    />
                  }
                  disabled={
                    !currentUser?.hasPermission("workflow", "can_create")
                  }
                />
                {requestData.isOpen && (
                  <InputModal
                    title="Request Resubmission"
                    buttonTitle="Submit Request"
                    placeholder="What changes need to be resubmitted?"
                    onClose={handleToggleRequest}
                    inputValue={requestData?.comment}
                    onInputChange={(val) => handleRequestData("comment", val)}
                    onSubmit={handleResubmit}
                  />
                )}
              </div>
            </>
          )}

          {!hideComments && (
            <>
              {showComments && (
                <IconButton
                  icon={commentIcon}
                  className="p-2 mr-1 bg-white rounded-full border-2	border-brandGreen"
                  imgClassName="w-5 h-5"
                  onClick={() => setShowComments(false)}
                />
              )}

              {!showComments && (
                <IconButton
                  icon={commentIcon}
                  className="p-2 mr-1 border-2 border-transparent"
                  imgClassName="w-5 h-5"
                  onClick={() => setShowComments(true)}
                />
              )}
            </>
          )}

          <IconButton
            onClick={() => {
              handleExpandedFile();
              onCloseExternalModal();
            }}
            icon={expandBackIcon}
            className="p-4"
            imgClassName="w-5 h-5"
          />
        </div>
      </div>
      <div className="flex flex-col w-full h-full justify-center items-center relative">
        {showComments && (
          <div className="absolute z-30 right-0 top-0 w-1/4 border-l-2 border-b-2 border-r-2 border-gray-150 px-8 max-h-120 overflow-auto bg-white">
            <CommentSectionWF
              data={data?.comments}
              handlePostComment={(val) =>
                handlePostComment(val, data.id, parentIndex)
              }
              handlePostReply={(val, commentID) =>
                handlePostReply(val, commentID, data.id, parentIndex)
              }
              allowNewComment={
                workflowData.status !== RESOLVED_WF &&
                (workflowData.status !== RESUBMISSION_WF_STEP ||
                  (workflowData.status === RESUBMISSION_WF_STEP &&
                    cardStatus === "ERROR"))
              }
              showClose
              handleClose={() => setShowComments(false)}
            />
          </div>
        )}
        {isUploadOpen && (
          <div
            className="absolute h-80 bg-white z-20 left-0 right-0 mx-auto top-4 max-w-3xl"
            style={{ width: "95%" }}
          >
            <WorkflowFileForm
              files={files}
              dispatch={dispatch}
              handleCloseUploader={handleCloseUploader}
              handleFileUpload={handleFileUpload}
              showSpinner={showSpinner}
            />
          </div>
        )}
        {selectedFilePdfUrl ||
          (currentDoc?.contentsUrl && (
            <PDFViewer
              initialUrl={selectedFilePdfUrl ?? currentDoc?.contentsUrl}
            />
          ))}
      </div>
    </div>
  );

  return (
    <>
      <div
        className={`flex flex-row items-start pt-8 pl-2 ${
          commentsOnly ? "border-t-2 mt-8" : ""
        }`}
      >
        <div className="select-none" />
        {!commentsOnly && (
          <div
            className={`bg-gray-100 flex-1 rounded-md p-2 flex flex-col relative ${
              !hideComments ? "mr-16" : ""
            }`}
          >
            <div className="flex flex-row justify-between p-4">
              <div className="flex flex-row">
                <Dropdown
                  className="w-80 bg-white z-30"
                  value={selectedFile || null}
                  options={fileOptions}
                  onChange={setSelectedFile}
                  disableSort
                  onDropdownClick={() => {
                    setShowPageComment(false);
                  }}
                />
                {workflowData.status !== RESOLVED_WF && (
                  <TertiaryButton
                    title="+ Add Upload"
                    onClick={() => {
                      setShowPageComment(false);
                      setIsUploadOpen(true);
                    }}
                    className="z-20 mx-2"
                    disabled={
                      !currentUser?.hasPermission("workflow", "can_create")
                    }
                  />
                )}
              </div>
              <IconButton
                icon={expandGreenIcon}
                imgClassName="w-5 h-5"
                onClick={() => {
                  setShowPageComment(false);
                  handleExpandedFile();
                }}
                className="py-2"
              />
            </div>
            {selectedFilePdfUrl && (
              <div className="w-full bg-white p-4">
                <div className="relative">
                  {selectedFile?.value &&
                    workflowData.status !== RESOLVED_WF &&
                    !hideComments && (
                      <SecondaryButton
                        title="Comment on this Page"
                        onClick={handlePageComment}
                      />
                    )}
                  {showPageComment && (
                    <div className="absolute bg-white border-brandGreen border rounded px-4 pt-4 -top-2 w-3/5 z-20">
                      <div className="flex justify-between items-start">
                        <SecondaryHeader>
                          {`Comment on ${selectedFile.label}`}
                        </SecondaryHeader>
                        <CrossButton
                          className="mt-1.5"
                          onClick={handlePageComment}
                        />
                      </div>
                      <NewComment
                        handleCancelComment={handlePageComment}
                        handleAddComment={handleAddComment}
                        user={{
                          ...currentUser,
                          name: getFullName(currentUser?.name),
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="h-192 pt-5 z-30">
                  {!isLoading && selectedFile?.contentReference && (
                    <PDFViewer initialUrl={selectedFilePdfUrl} />
                  )}
                  {isLoading && <Spinner notFullScreen />}
                </div>
              </div>
            )}
            {isUploadOpen && (
              <div
                className={`absolute h-80 bg-white z-30 left-0 right-0 mx-auto ${
                  selectedFilePdfUrl ? "top-4" : "-bottom-2"
                }`}
                style={{ width: "95%" }}
              >
                <WorkflowFileForm
                  files={files}
                  dispatch={dispatch}
                  handleCloseUploader={handleCloseUploader}
                  handleFileUpload={handleFileUpload}
                  showSpinner={showSpinner}
                />
              </div>
            )}
          </div>
        )}
        <div className={`flex-1 ${hideComments ? "hidden" : ""}`}>
          <div
            className="overflow-auto mr-8 pr-8"
            style={{ height: commentsCount > 3 ? "900px" : "auto" }}
          >
            <CommentSectionWF
              data={data?.comments}
              handlePostComment={(val) =>
                handlePostComment(val, data.id, parentIndex)
              }
              handlePostReply={(val, commentID) =>
                handlePostReply(val, commentID, data.id, parentIndex)
              }
              allowNewComment={
                !workflowData?.isDraft &&
                workflowData.status !== RESOLVED_WF &&
                (workflowData.status !== RESUBMISSION_WF_STEP ||
                  (workflowData.status === RESUBMISSION_WF_STEP &&
                    cardStatus === "ERROR"))
              }
            />
          </div>
        </div>
      </div>
      <WorkflowDocViewModal
        content={FileExpandContent()}
        isOpen={expandedFile}
        onRequestModalClose={handleExpandedFile}
      />
    </>
  );
};

WorkflowFileView.propTypes = {
  /**
   * data needed to display wrkflow steps
   */
  data: PropTypes.shape({
    duration: PropTypes.number,
    needOnlyOneAdvancer: PropTypes.bool,
    position: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    documents: PropTypes.arrayOf(PropTypes.any),
    id: PropTypes.string,
    newStep: PropTypes.bool,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        reference: PropTypes.string,
        userData: PropTypes.shape({
          name: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
          }),
        }),
      })
    ),
    name: PropTypes.string,
    description: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    comments: PropTypes.arrayOf(PropTypes.any),
    // eslint-disable-next-line react/forbid-prop-types
    dueDate: PropTypes.object,
  }),
  /**
   * handles posting a comment
   */
  handlePostComment: PropTypes.func,
  /**
   * handles posting a reply
   */
  handlePostReply: PropTypes.func,
  /**
   * handles adding a file
   */
  handleAddFiles: PropTypes.func,
  /**
   * parent index of workflow step
   */
  parentIndex: PropTypes.number,
  /**
   * current workflow step cards status
   */
  cardStatus: PropTypes.string,
  /**
   * entire workflow object
   */
  workflowData: PropTypes.shape({
    status: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    documents: PropTypes.arrayOf(PropTypes.any),
    isDraft: PropTypes.bool,
  }),
  canAdvance: PropTypes.bool,
  handleAdvanceStep: PropTypes.func,
  handleResubmitStep: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  currentDoc: PropTypes.object,
  expanded: PropTypes.bool,
  onSetExpanded: PropTypes.func,
  onCloseExternalModal: PropTypes.func,
  commentsOnly: PropTypes.bool,
  hideComments: PropTypes.bool,
  onDownload: PropTypes.func,
};

WorkflowFileView.defaultProps = {
  data: [],
  handleAddFiles: undefined,
  handlePostComment: undefined,
  handlePostReply: undefined,
  parentIndex: undefined,
  cardStatus: undefined,
  workflowData: {},
  canAdvance: false,
  handleAdvanceStep: undefined,
  handleResubmitStep: undefined,
  currentDoc: {},
  expanded: false,
  onSetExpanded: () => {},
  onCloseExternalModal: () => {},
  commentsOnly: false,
  hideComments: false,
  onDownload: () => {},
};
export default WorkflowFileView;

import React from "react";
import IconLabel from "../../../stories/Components/IconLabel/IconLabel";
import CrossBubble from "../../../stories/Components/CrossBubble";
import Pill from "../../../stories/Components/Pill/Pill";

const TaskRow = ({
  index,
  task,
  isEditing,
  handleTasksToDelete,
  handleNavigateToTask,
}) => {
  return (
    <div className="relative w-full mb-4 rounded-md border border-gray-150">
      {isEditing && <CrossBubble onClick={() => handleTasksToDelete(task)} />}

      <table className="w-full table-fixed">
        <tr>
          <td className="w-12 p-4 border-r border-gray-150 font-semibold text-center">
            {index}
          </td>
          <td className="w-2/5 p-4 border-r border-gray-150">{task.name}</td>
          <td className="p-4 border-r border-gray-150">{task.date}</td>
          <td className="p-4 border-r border-gray-150">{task.time}</td>
          <td
            className="p-4 border-r border-gray-150 flex justify-center"
            aria-label="table-data"
          >
            <div className="w-36">
              <Pill
                value={task.formattedStatus}
                textSize="text-xs"
                background="bg-white"
                border={`border ${
                  task.status === "scheduled"
                    ? "border-darkenedGreen"
                    : "border-gray-450"
                }`}
                textColor={
                  task.status === "scheduled"
                    ? "text-darkenedGreen"
                    : "text-gray-450"
                }
              />
            </div>
          </td>
          {!isEditing && (
            <td className="p-4 border-gray-150" aria-label="table-data">
              <div className="items-center flex justify-center">
                <IconLabel
                  text="Go To Task >"
                  textClassName="text-darkenedGreen"
                  onClick={() => handleNavigateToTask(task)}
                />
              </div>
            </td>
          )}
        </tr>
      </table>
    </div>
  );
};

export default TaskRow;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import gearIcon from "../../assets/images/settingsIconGray.svg";
import Dropdown from "../Dropdown/Dropdown";
import IconLabel from "../IconLabel/IconLabel";
import PlusCirlceIconDarkGreen from "../../assets/images/plusCircleIconDarkGreen.svg";

const SiteHeaderChangeViewDD = ({
  templateSettings,
  selectedTemplateOption,
  onTemplateChange,
  disableCreateTemplate,
  disableClearView,
}) => {
  const [templateDDOptions, setTemplateDDOptions] = useState([]);

  useEffect(() => {
    const options = templateSettings.map((view) => {
      return {
        label: view.name,
        value: view.id,
        isAdmin: view.isAdmin,
      };
    });

    // if (
    //   selectedTemplateOption
    //   // && !selectedTemplateOption.isAdmin
    // ) {
    //   options.unshift({
    //     label: "Edit Current View...",
    //     value: "edit",
    //   });
    //   nonAdminTemplateEndIndex += 1;
    // }

    // sort the dropdown options alphabetically
    options.sort((a, b) => a.label.localeCompare(b.label));
    options.sort((a, b) => Number(b.isAdmin) - Number(a.isAdmin));

    // put the "Add View option last"
    if (!disableCreateTemplate)
      options.push({
        label: "Add View",
        value: "create",
      });

    setTemplateDDOptions(options);
  }, [templateSettings, selectedTemplateOption, disableCreateTemplate]);

  // style the <option> of the template dropdown
  const formatOptionLabel = (prop) => {
    const { label, value, isAdmin } = prop;
    return (
      <div>
        <div className="flex flex-row justify-between">
          {value === "create" ? (
            <IconLabel
              icon={PlusCirlceIconDarkGreen}
              text="Add View"
              textClassName="text-darkenedGreen text-sm"
              direction="right"
            />
          ) : (
            <div className="">{label}</div>
          )}
          <div className="">
            <div
              role="button"
              onKeyDown={() => {}}
              tabIndex="0"
              onClick={() =>
                onTemplateChange({
                  label,
                  type: "editFromDropDown",
                  value,
                })
              }
            >
              {value !== "create" && !isAdmin && <img src={gearIcon} alt="" />}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-initial w-72 max-w-sm min-w-max">
      <Dropdown
        placeholder="Quick View"
        disableClear={disableClearView}
        options={templateDDOptions}
        onChange={(selectedOption) => onTemplateChange(selectedOption)}
        formatOptionLabel={formatOptionLabel}
        value={selectedTemplateOption}
        disableSort
      />
    </div>
  );
};

SiteHeaderChangeViewDD.propTypes = {
  templateSettings: PropTypes.arrayOf(PropTypes.shape({})),
  selectedTemplateOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    isAdmin: PropTypes.bool,
  }),
  onTemplateChange: PropTypes.func,
  disableCreateTemplate: PropTypes.bool,
  disableClearView: PropTypes.bool,
};

SiteHeaderChangeViewDD.defaultProps = {
  templateSettings: [],
  selectedTemplateOption: undefined,
  onTemplateChange: undefined,
  disableCreateTemplate: false,
  disableClearView: false,
};

export default SiteHeaderChangeViewDD;

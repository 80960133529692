import { useMemo, useState } from "react";
import { useAppState } from "../../../../state/appState";
import { useESChatContext } from "../../../../context/ChatContext/ESChatContext";
import useCurrentUser from "../../../../hooks/useCurrentUser";

export default () => {
  const [{ usersStreamInfo: users }] = useAppState();
  const [value, setValue] = useState(null);
  const {
    channels,
    selectedMembers: selected,
    setSelectedMembers: setSelected,
  } = useESChatContext();
  const { data: currentUser } = useCurrentUser();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSelect = (user) => {
    setValue("");
    setSelected((prev) =>
      prev.find((item) => item.id === user.id)
        ? prev.filter((item) => item.id !== user.id)
        : [...prev, user]
    );
  };

  const filteredList = useMemo(() => {
    if (value) {
      return {
        users:
          value[0] !== "#"
            ? users?.filter(
                (item) =>
                  item.name
                    .toLowerCase()
                    .includes(value.toLowerCase().replace(/[#@]/g, "")) &&
                  !selected?.find((x) => x.id === item.id) &&
                  item.id !== currentUser?.streamId
              )
            : [],
        channels:
          value[0] !== "@" && selected.length === 0
            ? channels?.filter((item) =>
                item.data.name
                  .toLowerCase()
                  .includes(value.toLowerCase().replace(/[#@]/g, ""))
              )
            : [],
      };
    }
    return null;
  }, [channels, currentUser?.streamId, selected, users, value]);

  return {
    value,
    setValue,
    filteredList,
    channels,
    handleChange,
    selected,
    handleSelect,
  };
};

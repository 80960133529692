/**
 * Check if an object is empty
 * One less comparison operation than Object.keys(obj).length > 0
 * Key Words: ~object~ ~empty~
 */

/* eslint-disable no-restricted-syntax */
const objectHasProperties = (obj) => {
  // eslint-disable-next-line guard-for-in
  for (const property in obj) {
    return true;
  }
  return false;
};

export default objectHasProperties;

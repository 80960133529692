/* eslint-disable no-restricted-syntax */
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import notiAlertIcon from "../../../assets/images/notiAlertIcon.svg";
import RadioButton from "../../Buttons/RadioButton";
import Checkbox from "../../Checkbox/Checkbox";
import DatePicker from "../../DatePicker/DatePicker";
import Dropdown from "../../Dropdown/Dropdown";
import Input from "../../Input/Input";
import "../../TimeSheetTable/TimeSheetTable.css";
import ModalWrapper from "../ModalWrapper";
import TimeoffAmountInput from "./TImeoffAmountInput";
import useTimeoffRequestModalData from "./useTimeoffRequestModalData";

const TimeoffRequestModal = ({ modalData }) => {
  const {
    timeOffRequest,
    disableSubmit,
    submit,
    selectedType,
    typeOptions,
    dayType: radioState,
    showHours,
    processed,
    beyondAllowedAmount,
    hoursRemaining,
    requestTotal,
    typeNotSelectedAndFull,
    setRequestType,
    setRequestStart,
    setRequestEnd,
    setUncheckedDate,
    setNote,
    onChangeTimeAmount,
    totalAvailableAmount,
  } = useTimeoffRequestModalData(modalData);

  return (
    <ModalWrapper
      modalData={modalData}
      resourceName="Time Off"
      width="500px"
      primaryButtonOnClick={submit}
      disabled={disableSubmit || beyondAllowedAmount}
      title={modalData?.item ? "Modify Request" : "Create Request"}
      className="p-5 pt-6 no-scrollbar"
    >
      <div className="flex flex-col h-full border rounded-md p-4 px-4">
        <div className="flex flex-col gap-4 px-1">
          {/* End Date */}
          <div className="flex flex-row w-full items-center justify-between">
            <p className="flex font-semibold text-gray-450">Type</p>
            <div className="flex flex-row w-2/3">
              <Dropdown
                className="justify-end text-gray-700"
                placeholder="Select"
                value={selectedType?.dropDown}
                options={typeOptions}
                isDisabled={false}
                onChange={(val) => setRequestType(val.value)}
                disableSort
                disableClear
              />
            </div>
          </div>
          {/* Start Date */}
          <div className="flex flex-row w-full items-center justify-between">
            <p className="flex font-semibold text-gray-450">Start</p>
            <DatePicker
              className="w-2/3"
              value={timeOffRequest?.startDate}
              onChange={setRequestStart}
              dateFormat="MMM do, yyyy"
              maxDate={new Date().setDate(new Date().getDate() + 365)}
            />
          </div>
          {/* End Date */}
          <div className="flex flex-row w-full items-center justify-between">
            <p className="flex font-semibold text-gray-450">End</p>
            <DatePicker
              className="w-2/3"
              value={timeOffRequest?.endDate}
              onChange={setRequestEnd}
              dateFormat="MMM do, yyyy"
              maxDate={new Date().setDate(new Date().getDate() + 365)}
            />
          </div>
          {Object.values(timeOffRequest?.dateList ?? {}).length > 1 &&
            !modalData?.item && (
              <div className="flex flex-row w-full justify-end">
                <div className="flex flex-row w-2/3 justify-start gap-3">
                  <RadioButton
                    labelClassName="w-full text-gray-400 font-normal"
                    label="Full Days"
                    isChecked={radioState.type === "full"}
                    onChange={() => radioState.toggle("full")}
                  />
                  <RadioButton
                    labelClassName="w-full text-gray-400 font-normal"
                    label="Partial Days"
                    isChecked={radioState.type === "partial"}
                    onChange={() => radioState.toggle("partial")}
                  />
                </div>
              </div>
            )}

          <div className="border-b w-full" />

          <>
            {(Object.values(timeOffRequest?.dateList ?? {}).length === 1 ||
              radioState.type === "partial" ||
              modalData?.item) && (
              <div className="flex flex-col gap-4">
                <div className="grid grid-cols-5 font-semibold text-gray-450">
                  <p className="col-span-1">Dates</p>
                  <p className="col-start-5">Hours</p>
                </div>
                {Object.keys(timeOffRequest?.dateList ?? {}).map((ptoDate) => {
                  return (
                    <div
                      className={`pb-3 grid grid-cols-5 flex-1 pt-3 border rounded-xl p-4 ${
                        timeOffRequest?.dateList[ptoDate].checked &&
                        "border-pillGray"
                      } ${
                        typeof processed === "boolean" &&
                        processed &&
                        "border-errorRed"
                      }`}
                      key={ptoDate}
                    >
                      <Checkbox
                        className="flex items-center pr-3 p-1 col-span-4"
                        size="6"
                        label={moment(ptoDate).format("ll")}
                        labelClassName={`text-lg font-medium text-gray-450 ml-3 ${
                          typeof processed === "boolean" &&
                          processed &&
                          "text-errorRed"
                        }`}
                        checked={timeOffRequest?.dateList[ptoDate].checked}
                        onChange={(val) => {
                          setUncheckedDate(ptoDate, val);
                        }}
                        disabled={!timeOffRequest?.requestType}
                      />
                      <TimeoffAmountInput
                        value={timeOffRequest?.dateList[ptoDate].hours}
                        processedPto={processed}
                        onChange={onChangeTimeAmount}
                        date={ptoDate}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {selectedType?.label && (
              <div className="box-border h-30 w-30 rounded-md bg-backgroundGrey p-4 max-w-lg">
                {!beyondAllowedAmount && (
                  <div className="flex flex-row pb-2 w-full items-center">
                    <p className="text-start font-semibold text-gray-300 text-sm">
                      You are requesting: {requestTotal} hours{" "}
                      {showHours && "(8 hours per workday)"}
                    </p>
                  </div>
                )}

                <div className="flex flex-row">
                  {!beyondAllowedAmount && (
                    <>
                      <p className="text-center text-gray-300 text-xs">
                        You will have{" "}
                      </p>
                      <p className="font-semibold text-gray-300 text-xs pl-1 pr-1">
                        {parseInt(hoursRemaining, 10)} {selectedType?.label}{" "}
                        Hours
                      </p>
                      <p className="text-gray-300 text-xs">remaining</p>
                    </>
                  )}

                  {beyondAllowedAmount && (
                    <div className="flex">
                      <img
                        className="mr-2"
                        src={notiAlertIcon}
                        alt="exclamation"
                      />
                      <p className="text-es-red text-xs">
                        {`Your request of ${requestTotal} hours for ${selectedType?.dropDown?.label.toLowerCase()} timeoff can not be submitted because you only have ${totalAvailableAmount} hours remaining and the benefits settings does not allow it to go negative.`}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}

            {!typeNotSelectedAndFull && (
              <div className="border-b w-full mt-2" />
            )}

            <div className="flex flex-row w-full justify-between">
              <p className="flex font-semibold text-gray-450 w-1/5">Notes</p>
              <Input
                placeholder="Notes"
                className="flex pb-2"
                inputContainerClassName="bg-inputBgGreen ESInput pl-2"
                mainClassName="bg-transparent"
                inputClassName="bg-inputBgGreen w-full"
                value={timeOffRequest?.note}
                onChange={(val) => setNote(val)}
                isTextarea
                size="lg"
              />
            </div>
          </>
        </div>
      </div>
    </ModalWrapper>
  );
};

TimeoffRequestModal.propTypes = {
  modalData: PropTypes.shape({
    item: PropTypes.shape({}),
  }),
};

TimeoffRequestModal.defaultProps = {
  modalData: undefined,
};

export default TimeoffRequestModal;

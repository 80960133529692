import React from "react";
import PropTypes from "prop-types";
import BooleanBullet from "../BooleanBullet/BooleanBullet";

const AssociatedDocumentsDisplay = ({
  associatedDocuments,
  handleDocumentClick,
}) => {
  return (
    <div className="flex flex-col">
      {associatedDocuments?.map((doc) => (
        <div className="flex flex-row mb-1" key={doc?.reference}>
          <BooleanBullet isTrue className="mr-1 mt-2" />
          <button
            type="button"
            className="flex text-brandGreen font-medium text-left"
            onClick={() => handleDocumentClick(doc?.reference)}
          >
            {doc?.name}
          </button>
        </div>
      ))}
    </div>
  );
};

AssociatedDocumentsDisplay.propTypes = {
  associatedDocuments: PropTypes.arrayOf(PropTypes.shape({})),
  handleDocumentClick: PropTypes.func,
};

AssociatedDocumentsDisplay.defaultProps = {
  associatedDocuments: [],
  handleDocumentClick: undefined,
};

export default AssociatedDocumentsDisplay;

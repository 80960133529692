import cntl from "cntl";
import { evaluate } from "mathjs";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { budgetLineitemInfoSchema } from "../../../helpers/FormValidations";
import { formatWithCommasWithoutDecimal } from "../../../helpers/Formatters";
import { sortNumbersBeforeLetters } from "../../../helpers/Utilities";
import useBudgetFormReducer from "../../../hooks/useBudgetFormReducer";
import useBuildingLocationReducer from "../../../hooks/useBuildingLocationReducer";
import useSpaceReducer from "../../../hooks/useSpaceReducer";
import gearIcon from "../../assets/images/settingsIconGray.svg";
import BudgetGroupModal from "../BudgetGroupModal/BudgetGroupModal";
import PrimaryButton from "../Buttons/PrimaryButton";
import Checkbox from "../Checkbox/Checkbox";
import CreateContactModal from "../CreateContactModal/CreateContactModal";
import Dropdown from "../Dropdown/Dropdown";
import Input from "../Input/Input";
import LocationModal from "../Locations/LocationModal";
import BaseButton from "../Buttons/BaseButton";
import chevronIcon from "../../assets/images/chevronUpWithGreenBackground.svg";
import ToggleTextButton from "../Buttons/ToggleTextButton";
import TertiaryButton from "../Buttons/TertiaryButton";

const popOverCN = cntl`
  bg-white
  flex
  flex-col
  px-2
  border-t-2
`;

const rowCN = cntl`
  flex
  relative
`;

const rowItemCN = (lastIndex, className) => cntl`
  pt-5
  pb-5
  ${!lastIndex && "px-1.5"}
  min-w-max
  ${className}
`;

const arithmeticToggleUnits = {
  add: "+",
  subtract: "-",
};

const currencyToggleUnits = {
  dollar: "$",
  percent: "%",
};

const BudgetTableInLineForm = ({
  row,
  togglePopOver,
  csiCodes,
  unitsOfMeasure,
  groupedUnitsOfMeasure,
  vendors,
  isEdit,
  onAddSaveCallback,
  onEditSaveCallback,
  onSplitLineSaveCallback,
  onCancelSaveCallback,
  groupBy,
  prefillFieldsOnAddAnother,
  projectBuildings,
  budgetGroups,
  onSaveProjectLocation,
  onSaveProjectLocationSpace,
  projectLocationSpaceConfiguration,
  onSaveContactCallback,
  systemConfiguration,
  users,
  rateSheetRates,
  isDraftProject,
  onAddBudgetGroup,
  onEditBudgetGroup,
  onDeleteBudgetGroup,
  isSplitLinePopOver,
  currentUser,
  hideCrossButton,
  hideAddAnother,
  disableRequiredFieldsValidation,
}) => {
  const [isAddAnother, setIsAddAnother] = useState(false);
  const inputRefs = useRef({});
  const [isTabPressed, setIsTabPressed] = useState(false);
  const [isInputValid, setIsInputValid] = useState(false);
  const [projectLocation, dispatchProjectLocation] =
    useBuildingLocationReducer();
  const [projectLocationSpace, dispatchProjectLocationSpace] =
    useSpaceReducer();
  const [filteredCsiCodes, setFilteredCsiCodes] = useState(csiCodes);
  const [isSaving, setIsSaving] = useState(false);

  const initialAddState = useCallback(
    (autoFillGroupedColumns, li) => {
      const state = {
        isBillable: true,
        adjustment: {
          arithmeticUnit: arithmeticToggleUnits.add,
          adjustmentUnit: currencyToggleUnits.dollar,
        },
      };
      if (autoFillGroupedColumns.length) {
        autoFillGroupedColumns?.map((columnId) => {
          state[columnId] = li[columnId];
          if (columnId === "buildingName") {
            state.building = li.building;
          }
          return columnId;
        });
      }
      if (isAddAnother) {
        prefillFieldsOnAddAnother.map((columnId) => {
          if (columnId === "adjustment") {
            if (
              state[columnId]?.adjustment?.adjustmentUnit &&
              state[columnId]?.adjustment?.amount &&
              state[columnId]?.adjustment?.arithmeticUnit
            ) {
              state[columnId] = {
                adjustmentUnit: currencyToggleUnits.dollar,
                amount: "",
                arithmeticUnit: arithmeticToggleUnits.add,
              };
            }
          } else {
            state[columnId] = li[columnId];
          }

          return columnId;
        });
      }
      return state;
    },
    [isAddAnother, prefillFieldsOnAddAnother]
  );

  const [lineItem, dispatch] = useBudgetFormReducer(
    isEdit ? row.original : initialAddState(groupBy, row?.original)
  );

  const handleKeyUp = useCallback(
    (event) => {
      // checks if the Tab key was pressed
      if (isTabPressed) {
        event.preventDefault();
        const inputCategories = Object.keys(inputRefs.current).sort(
          sortNumbersBeforeLetters
        );

        let currentInputCategoryIdx = inputCategories.indexOf(
          event.target.id || event.target.name
        );

        let nextInputCategory =
          inputRefs.current[inputCategories[currentInputCategoryIdx + 1]];

        let isNextInputCategoryDisabled =
          nextInputCategory?.disabled ||
          nextInputCategory?.commonProps?.selectProps?.isDisabled;

        // loop through disabled and undefined (due to cost range unchecked) fields
        while (
          (isNextInputCategoryDisabled || !nextInputCategory) &&
          currentInputCategoryIdx < inputCategories?.length
        ) {
          currentInputCategoryIdx += 1;
          nextInputCategory =
            inputRefs.current[inputCategories[currentInputCategoryIdx + 1]];
          isNextInputCategoryDisabled =
            nextInputCategory?.disabled ||
            nextInputCategory?.commonProps?.selectProps?.isDisabled;
        }

        if (nextInputCategory) {
          if (nextInputCategory.name === "24.save") {
            nextInputCategory.click();
          } else {
            nextInputCategory.focus();
          }
        }
      }
    },
    [isTabPressed]
  );

  const checkValidation = useCallback(
    async (lineitemData, validationSchema) => {
      let isValid = await validationSchema.isValid(lineitemData);
      if (isSplitLinePopOver) {
        const isInvalidSplitValue =
          !lineitemData?.splitValue ||
          parseFloat(lineitemData?.splitValue) === 0 ||
          parseFloat(lineitemData?.splitValue) >
            parseFloat(lineitemData?.uncommittedValue);
        if (isInvalidSplitValue) {
          isValid = false;
        }
      }
      setIsInputValid(isValid);
    },
    [isSplitLinePopOver]
  );

  const formatOptionLabel = useCallback(
    (prop) => {
      const { label, value } = prop;
      return (
        <div>
          <div className="flex flex-row justify-between">
            <div className="">{label}</div>
            <div className="">
              <div
                role="button"
                onKeyDown={() => {}}
                tabIndex="0"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch({
                    type: "group",
                    value: {
                      label,
                      value,
                    },
                    isEditingGroup: true,
                  });
                }}
              >
                {value !== "create" && <img src={gearIcon} alt="" />}
              </div>
            </div>
          </div>
        </div>
      );
    },
    [dispatch]
  );

  useEffect(() => {
    checkValidation(
      lineItem,
      budgetLineitemInfoSchema(disableRequiredFieldsValidation)
    );
  }, [lineItem, checkValidation, disableRequiredFieldsValidation]);

  useEffect(() => {
    if (
      groupBy?.length &&
      groupBy.includes("csiCodeDivision") &&
      row?.original?.csiCodeDivision !== ""
    ) {
      const divCode = row?.original?.csiCodeDivision.split(" - ")[0];
      setFilteredCsiCodes(
        csiCodes.filter((code) => code.value.split(" ")[0] === divCode)
      );
    }
  }, [csiCodes, groupBy, row?.original?.csiCodeDivision]);

  useEffect(() => {
    if (lineItem.costPerUnit) {
      const liveBudget = lineItem.costPerUnit * (lineItem.quantity || 0);
      dispatch({
        type: "liveBudget",
        value: liveBudget,
      });
    }
  }, [dispatch, lineItem.costPerUnit, lineItem.quantity]);

  useEffect(() => {
    if (lineItem.highRange?.costPerUnit) {
      const highRangeLiveBudget =
        lineItem.highRange?.costPerUnit * (lineItem.highRange?.quantity || 0);
      dispatch({
        type: "highRangeLiveBudget",
        value: highRangeLiveBudget,
      });
    }
  }, [dispatch, lineItem.highRange?.costPerUnit, lineItem.highRange?.quantity]);

  const getDropdownValue = useCallback(
    (type, value) => {
      let rv = {};
      switch (type) {
        case "vendor": {
          rv = vendors.find((v) => v.value === value);
          break;
        }
        case "rate": {
          rv = rateSheetRates.find((rate) => rate.value === value);
          break;
        }
        case "group": {
          rv = budgetGroups.find((v) => v.value === value);
          break;
        }
        case "space": {
          rv = projectBuildings
            ?.find((building) => building.value === lineItem?.building)
            ?.spaces?.find((space) => space.value === value);
          break;
        }
        case "building": {
          rv = projectBuildings.find((l) => l.value === value);
          break;
        }
        case "financialCode": {
          rv = filteredCsiCodes.find(
            (code) =>
              code.value ===
              `${value?.division} ${value?.code} ${value?.subcode}`
          );
          break;
        }
        case "unitOfMeasure": {
          rv = unitsOfMeasure.find((u) => u.value === value);
          break;
        }
        default:
      }

      return rv;
    },
    [
      vendors,
      budgetGroups,
      projectBuildings,
      lineItem?.building,
      filteredCsiCodes,
      unitsOfMeasure,
      rateSheetRates,
    ]
  );

  const onEditSave = useCallback(async () => {
    togglePopOver();
    setIsSaving(true);
    let cloneLineItem = { ...lineItem };
    if (isDraftProject && !lineItem?.isCostRange) {
      cloneLineItem = {
        ...cloneLineItem,
        highRange: {
          rate: lineItem?.rate,
          unitOfMeasure: lineItem?.unitOfMeasure,
          costPerUnit: lineItem?.costPerUnit,
          calculation: lineItem?.calculation,
          quantity: lineItem?.quantity,
          adjustment: lineItem?.adjustment,
          allowance: lineItem?.allowance,
          isBillable: lineItem?.isBillable,
          applyRetainage: lineItem?.applyRetainage,
        },
      };
    }
    await onEditSaveCallback(cloneLineItem);
    setIsSaving(false);
  }, [isDraftProject, lineItem, onEditSaveCallback, togglePopOver]);

  const onAddSave = useCallback(
    async (addAnother) => {
      setIsSaving(true);
      if (!addAnother) {
        // close popover
        togglePopOver();
      }

      let cloneLineItem = { ...lineItem };
      if (isDraftProject && !lineItem?.isCostRange) {
        cloneLineItem = {
          ...cloneLineItem,
          highRange: {
            rate: lineItem?.rate,
            unitOfMeasure: lineItem?.unitOfMeasure,
            costPerUnit: lineItem?.costPerUnit,
            calculation: lineItem?.calculation,
            quantity: lineItem?.quantity,
            adjustment: lineItem?.adjustment,
            allowance: lineItem?.allowance,
            isBillable: lineItem?.isBillable,
            applyRetainage: lineItem?.applyRetainage,
          },
        };
      }

      try {
        await onAddSaveCallback(cloneLineItem);

        if (addAnother) {
          // initialize state from previous lineitem on add another
          dispatch({
            type: "reset",
            budget: initialAddState(groupBy, cloneLineItem),
          });
        }
      } catch (err) {
        console.warn(err);
      }

      setIsSaving(false);
    },
    [
      dispatch,
      groupBy,
      initialAddState,
      isDraftProject,
      lineItem,
      onAddSaveCallback,
      togglePopOver,
    ]
  );

  const onSplitLineSave = useCallback(async () => {
    togglePopOver();
    try {
      await onSplitLineSaveCallback(lineItem);
    } catch (err) {
      console.warn(err);
    }
  }, [lineItem, onSplitLineSaveCallback, togglePopOver]);

  const onCancel = useCallback(async () => {
    onCancelSaveCallback();
    togglePopOver();
  }, [onCancelSaveCallback, togglePopOver]);

  const computeAdjustedTotal = useCallback(
    (liveBudget) => {
      const total = parseFloat(liveBudget || 0);
      if (
        lineItem?.adjustment?.arithmeticUnit &&
        lineItem?.adjustment?.adjustmentUnit &&
        lineItem?.adjustment?.amount
      ) {
        let difference = 0;
        const adjustment = parseFloat(lineItem?.adjustment?.amount);

        switch (lineItem?.adjustment?.adjustmentUnit) {
          case "%": {
            difference = (adjustment / 100) * total;
            break;
          }
          default: {
            difference = adjustment;
          }
        }

        return lineItem?.adjustment?.arithmeticUnit ===
          arithmeticToggleUnits.add
          ? total + difference
          : total - difference;
      }

      return total;
    },
    [lineItem]
  );

  const computeHighRangeAdjustedTotal = useCallback(
    (highRangeLiveBudget) => {
      const total = parseFloat(highRangeLiveBudget || 0);
      if (
        lineItem?.highRange?.adjustment?.arithmeticUnit &&
        lineItem?.highRange?.adjustment?.adjustmentUnit &&
        lineItem?.highRange?.adjustment?.amount
      ) {
        let difference = 0;
        const adjustment = parseFloat(lineItem?.highRange?.adjustment?.amount);

        switch (lineItem?.highRange?.adjustment?.adjustmentUnit) {
          case "%": {
            difference = (adjustment / 100) * total;
            break;
          }
          default: {
            difference = adjustment;
          }
        }

        return lineItem?.highRange?.adjustment?.arithmeticUnit ===
          arithmeticToggleUnits.add
          ? total + difference
          : total - difference;
      }

      return total;
    },
    [lineItem]
  );

  const onSubmit = useCallback(
    async (addAnother) => {
      if (!isSaving && isInputValid) {
        if (isSplitLinePopOver) {
          onSplitLineSave();
        } else if (isEdit) {
          onEditSave();
        } else {
          setIsAddAnother(addAnother);
          await onAddSave(addAnother);
        }
      }
    },
    [
      isEdit,
      isInputValid,
      isSaving,
      isSplitLinePopOver,
      onAddSave,
      onEditSave,
      onSplitLineSave,
    ]
  );

  const escapeKeyListener = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        togglePopOver();
      }
    },
    [togglePopOver]
  );

  const onEnterPress = (event) => {
    // tab pressed
    if (event.which === 9 || event.keyCode === 9) {
      setIsTabPressed(true);
      event.preventDefault();
      return;
    }
    setIsTabPressed(false);
    // Disabling submit click on enter press from any field
    // enter pressed
    // if (event.which === 13) {
    //   onSubmit();
    // }
  };

  useEffect(() => {
    window.addEventListener("keydown", escapeKeyListener);
    return () => {
      window.removeEventListener("keydown", escapeKeyListener);
    };
  }, [escapeKeyListener]);

  const onCloseLocationModal = () => {
    dispatch({
      type: "building",
      value: null,
    });
  };

  const onCloseSpaceModal = () => {
    dispatch({
      type: "space",
      value: null,
    });
  };

  const onCloseVendorModal = () => {
    dispatch({
      type: "vendor",
      value: null,
    });
  };

  const onVendorResetAndClose = () => {
    dispatch({
      type: "vendor",
      value: null,
    });
  };

  const onCloseGroupModal = () => {
    dispatch({
      type: "group",
      value: null,
    });
  };

  const onSaveLocation = () => {
    onSaveProjectLocation(projectLocation);
    dispatchProjectLocation({ type: "reset" });
    onCloseLocationModal();
  };

  const onSaveSpace = () => {
    onSaveProjectLocationSpace(lineItem?.building, projectLocationSpace);
    dispatchProjectLocationSpace({ type: "reset" });
    onCloseSpaceModal();
  };

  const onSaveCompanyContact = async () => {
    onCloseVendorModal();
    await onSaveContactCallback();
  };

  const onCalculationChange = (calculation) => {
    let quantity = 0;
    try {
      quantity = evaluate(calculation);
      quantity = quantity > 0 ? quantity : 0;
    } catch (err) {
      console.warn(err);
    }

    dispatch({
      type: "calculationAndQuantity",
      calculation,
      quantity,
    });
  };

  const onHighRangeCalculationChange = (calculation) => {
    let quantity = 0;
    try {
      quantity = evaluate(calculation);
      quantity = quantity > 0 ? quantity : 0;
    } catch (err) {
      console.warn(err);
    }

    dispatch({
      type: "highRangeCalculationAndQuantity",
      calculation,
      quantity,
    });
  };

  const onChangeRate = (newVal) => {
    dispatch({
      type: "rateAndOthers",
      rate: newVal,
      unitOfMeasure: unitsOfMeasure.find((u) => u.label === "Hour (HR)"),
      costPerUnit: newVal?.ratePerHr,
      vendor: newVal?.value ? undefined : lineItem.vendor,
    });
  };

  const onIsCostRangeChange = (newVal) => {
    if (newVal) {
      inputRefs.current["4.unitOfMeasure"].focus();
    }

    dispatch({
      type: "isCostRange",
      value: newVal,
    });
  };

  const onChangeUnitOfMeasure = (newVal) => {
    dispatch({
      type: "unitOfMeasure",
      value: newVal,
    });
  };

  const getUncommittedRemainingBalalnce = useCallback(() => {
    const diff =
      parseFloat(lineItem?.uncommittedValue) -
      parseFloat(lineItem?.splitValue || 0);

    return formatWithCommasWithoutDecimal(diff >= 0 ? diff : 0);
  }, [lineItem?.splitValue, lineItem?.uncommittedValue]);

  // list of column content to be shown in the popover
  const columns = [
    ...(isSplitLinePopOver
      ? [
          {
            content: (
              <>
                <div className="text-sm font-semibold absolute top-0">
                  Remaining Uncommitted Value available to split: $
                  {getUncommittedRemainingBalalnce()}
                </div>
                <Input
                  className="w-44"
                  labelClassName="font-semibold text-gray-300 opacity-70 text-xs"
                  inputClassName="ESInput bg-inputBgGreen"
                  label="Uncommitted Value"
                  placeholder="Split amount"
                  value={lineItem?.splitValue}
                  onChange={(newVal) => {
                    dispatch({
                      type: "splitValue",
                      value: newVal,
                    });
                  }}
                  validation={yup
                    .number()
                    .min(0, `Minimum atleast 0`)
                    .max(
                      parseFloat(lineItem?.uncommittedValue),
                      `Allowed maximum is ${lineItem?.uncommittedValue}`
                    )}
                  showValidationErrorAtBottom
                  onKeyDown={onEnterPress}
                  // eslint-disable-next-line no-return-assign
                  forwardedRef={(el) =>
                    (inputRefs.current["1.splitValue"] = el)
                  }
                  handleEnter={handleKeyUp}
                  name="1.splitValue"
                />
              </>
            ),
          },
        ]
      : []),
    {
      content: (
        <>
          <div className="w-44">
            <Dropdown
              labelClassName="font-semibold"
              label="CSI Code"
              placeholder="Select"
              options={filteredCsiCodes}
              value={getDropdownValue("financialCode", lineItem?.financialCode)}
              onChange={(newVal) => {
                dispatch({
                  type: "financialCode",
                  value: newVal,
                });
              }}
              validation={
                disableRequiredFieldsValidation
                  ? yup.mixed()
                  : yup.mixed().required()
              }
              onKeyDown={onEnterPress}
              // eslint-disable-next-line no-return-assign
              forwardedRef={(el) => (inputRefs.current["2.financialCode"] = el)}
              onKeyUp={handleKeyUp}
              name="2.financialCode"
            />
          </div>
          <div className="w-44">
            <Dropdown
              className="pt-2"
              labelClassName="font-semibold"
              label="Vendor"
              placeholder="Select"
              value={getDropdownValue("vendor", lineItem?.vendor)}
              onChange={(newVal) => {
                dispatch({
                  type: "vendor",
                  value: newVal,
                });
              }}
              options={vendors}
              validation={yup.mixed()}
              onKeyDown={onEnterPress}
              disableSort
              // eslint-disable-next-line no-return-assign
              forwardedRef={(el) => (inputRefs.current["7.vendor"] = el)}
              onKeyUp={handleKeyUp}
              name="7.vendor"
              isDisabled={!!lineItem?.rate}
            />
          </div>
        </>
      ),
    },
    {
      content: (
        <>
          <div className="w-44">
            <Dropdown
              label="Rate"
              labelClassName="font-semibold"
              placeholder="Select"
              options={rateSheetRates}
              value={getDropdownValue("rate", lineItem?.rate)}
              onChange={onChangeRate}
              onKeyDown={onEnterPress}
              rateSheetRates
              // eslint-disable-next-line no-return-assign
              forwardedRef={(el) => (inputRefs.current["3.rate"] = el)}
              onKeyUp={handleKeyUp}
              name="3.rate"
            />
          </div>
          <div className="w-44">
            <Dropdown
              className="pt-2"
              labelClassName="font-semibold"
              label="Group"
              placeholder="Select"
              options={budgetGroups}
              value={lineItem?.group}
              onChange={(newVal) => {
                dispatch({
                  type: "group",
                  value: newVal,
                });
              }}
              validation={yup.mixed()}
              onKeyDown={onEnterPress}
              formatOptionLabel={formatOptionLabel}
              optionsSortRange={[1, budgetGroups?.length]}
              // eslint-disable-next-line no-return-assign
              forwardedRef={(el) => (inputRefs.current["8.group"] = el)}
              onKeyUp={handleKeyUp}
              name="8.group"
            />
          </div>
        </>
      ),
    },
    {
      content: (
        <div>
          <div className="flex flex-row">
            <div className="w-44">
              <Dropdown
                className="pr-3"
                labelClassName="font-semibold"
                placeholder="Select"
                label="Unit of Measure"
                options={groupedUnitsOfMeasure}
                value={getDropdownValue(
                  "unitOfMeasure",
                  lineItem?.unitOfMeasure
                )}
                onChange={onChangeUnitOfMeasure}
                validation={
                  disableRequiredFieldsValidation
                    ? yup.mixed()
                    : yup.mixed().required()
                }
                isDisabled={lineItem?.rate}
                openMenuOnFocus
                // eslint-disable-next-line no-return-assign
                forwardedRef={(el) =>
                  (inputRefs.current["4.unitOfMeasure"] = el)
                }
                onKeyDown={onEnterPress}
                onKeyUp={handleKeyUp}
                name="4.unitOfMeasure"
              />
            </div>
            <Input
              className="pr-3 w-44"
              labelClassName="font-semibold text-gray-300 opacity-70 text-xs"
              inputClassName="ESInput bg-inputBgGreen"
              label="Cost Per"
              placeholder="Amount"
              value={lineItem?.costPerUnit}
              onChange={(newVal) => {
                dispatch({
                  type: "costPerUnit",
                  value: newVal,
                });
              }}
              validation={
                disableRequiredFieldsValidation
                  ? yup
                      .number()
                      .positive()
                      .transform((v, o) => (o === "" ? undefined : v))
                  : yup
                      .number()
                      .positive()
                      .transform((v, o) => (o === "" ? undefined : v))
                      .required()
              }
              onKeyDown={onEnterPress}
              disabled={lineItem?.rate}
              // eslint-disable-next-line no-return-assign
              forwardedRef={(el) => (inputRefs.current["5.costPerUnit"] = el)}
              handleEnter={handleKeyUp}
              name="5.costPerUnit"
            />
            <Input
              placeholder="Calculation"
              className="pr-3 w-44"
              labelClassName="font-semibold text-gray-300 opacity-70 text-xs"
              inputClassName="ESInput bg-inputBgGreen"
              label="Calculation"
              value={lineItem?.calculation}
              onChange={onCalculationChange}
              onKeyDown={onEnterPress}
              // eslint-disable-next-line no-return-assign
              forwardedRef={(el) => (inputRefs.current["6.calculation"] = el)}
              handleEnter={handleKeyUp}
              name="6.calculation"
            />
            <Input
              className="w-44"
              labelClassName="font-semibold text-gray-300 opacity-70 text-xs"
              label="Quantity"
              placeholder="Number"
              disabled
              value={lineItem?.quantity}
            />
          </div>
          <div className="flex flex-row w-full">
            <div className="w-1/2">
              <Input
                className="pt-2 pr-3"
                labelClassName="font-semibold text-gray-300 opacity-70 text-xs"
                label="Description"
                inputClassName="ESInput bg-inputBgGreen"
                placeholder="Lineitem Description"
                value={lineItem?.description}
                onChange={(newVal) => {
                  dispatch({
                    type: "description",
                    value: newVal,
                  });
                }}
                validation={yup.string()}
                onKeyDown={onEnterPress}
                autoExpandHeight
                // eslint-disable-next-line no-return-assign
                forwardedRef={(el) => (inputRefs.current["9.description"] = el)}
                handleEnter={handleKeyUp}
                name="9.description"
              />
            </div>
            <div className="w-1/2">
              <Input
                label="Notes"
                inputClassName="ESInput bg-inputBgGreen"
                labelClassName="font-semibold text-gray-300 opacity-70 text-xs"
                className="pt-2"
                placeholder="Note"
                value={lineItem?.notes}
                onChange={(newVal) => {
                  dispatch({
                    type: "notes",
                    value: newVal,
                  });
                }}
                validation={yup.string()}
                onKeyDown={onEnterPress}
                // eslint-disable-next-line no-return-assign
                forwardedRef={(el) => (inputRefs.current["10.notes"] = el)}
                handleEnter={handleKeyUp}
                name="10.notes"
              />
            </div>
          </div>
        </div>
      ),
      className: "border-r pr-3",
    },
    {
      content: (
        <div className="flex-col w-48">
          <Input
            placeholder="Total"
            className="w-full"
            disabled
            labelClassName="font-semibold text-gray-300 opacity-70 text-xs"
            label={
              isDraftProject && lineItem?.isCostRange
                ? "Total (Low Range)"
                : "Total"
            }
            value={computeAdjustedTotal(lineItem?.liveBudget)}
            onChange={(newVal) => {
              dispatch({
                type: "liveBudget",
                value: newVal,
              });
            }}
          />
          <>
            <div className="flex font-semibold text-gray-300 opacity-70 text-xs pt-2">
              Adjust Total Cost
            </div>
            <div className="flex pt-1 items-center">
              <div className="pr-2">
                <ToggleTextButton
                  setIsLeftActive={(isTrue) => {
                    if (isTrue) {
                      dispatch({
                        type: "arithmeticUnit",
                        value: arithmeticToggleUnits?.add,
                      });
                    }
                  }}
                  setIsRightActive={(isTrue) => {
                    if (isTrue) {
                      dispatch({
                        type: "arithmeticUnit",
                        value: arithmeticToggleUnits?.subtract,
                      });
                    }
                  }}
                  isLeftActive={
                    lineItem?.adjustment?.arithmeticUnit ===
                    arithmeticToggleUnits.add
                  }
                  isRightActive={
                    lineItem?.adjustment?.arithmeticUnit ===
                    arithmeticToggleUnits.subtract
                  }
                  leftText=" + "
                  rightText=" - "
                  borderLeftPill="rounded-l-md border-gray-200 border"
                  borderRightPill="rounded-r-md border-gray-200 border"
                  textClassName="text-sm"
                  // eslint-disable-next-line no-return-assign
                  forwardedRef={(el) =>
                    (inputRefs.current["11.lowRangeArithmeticUnit"] = el)
                  }
                  name="11.lowRangeArithmeticUnit"
                />
              </div>
              <Input
                placeholder="Adjustment"
                className="w-20 max-w-min pr-2"
                labelClassName="font-semibold text-gray-300 opacity-70 text-xs"
                inputClassName="ESInput bg-inputBgGreen"
                value={lineItem?.adjustment?.amount}
                onChange={(newVal) => {
                  dispatch({
                    type: "adjustment",
                    value: newVal,
                  });
                }}
                validation={yup
                  .number()
                  .positive()
                  .transform((v, o) => (o === "" ? undefined : v))}
                onKeyDown={onEnterPress}
                handleEnter={handleKeyUp}
                // eslint-disable-next-line no-return-assign
                forwardedRef={(el) =>
                  (inputRefs.current["12.lowRangeAdjustment"] = el)
                }
                name="12.lowRangeAdjustment"
              />
              <div className="pr-2">
                <ToggleTextButton
                  setIsLeftActive={(isTrue) => {
                    if (isTrue) {
                      dispatch({
                        type: "adjustmentUnit",
                        value: currencyToggleUnits?.dollar,
                      });
                    }
                  }}
                  setIsRightActive={(isTrue) => {
                    if (isTrue) {
                      dispatch({
                        type: "adjustmentUnit",
                        value: currencyToggleUnits?.percent,
                      });
                    }
                  }}
                  isLeftActive={
                    lineItem?.adjustment?.adjustmentUnit ===
                    currencyToggleUnits.dollar
                  }
                  isRightActive={
                    lineItem?.adjustment?.adjustmentUnit ===
                    currencyToggleUnits.percent
                  }
                  leftText={` ${currencyToggleUnits.dollar}`}
                  rightText={` ${currencyToggleUnits.percent}`}
                  textClassName="text-sm"
                  borderLeftPill="rounded-l-md border-gray-200 border"
                  borderRightPill="rounded-r-md border-gray-200 border"
                  // eslint-disable-next-line no-return-assign
                  forwardedRef={(el) =>
                    (inputRefs.current["13.lowRangeAdjustmentUnit"] = el)
                  }
                  name="13.lowRangeAdjustmentUnit"
                />
              </div>
            </div>
          </>
        </div>
      ),
      className: "border-r bg-backgroundGrey pl-3 pr-3",
    },
    ...(isDraftProject && lineItem?.isCostRange
      ? [
          {
            content: (
              <>
                <div className="flex flex-row">
                  <div className="w-44">
                    <Dropdown
                      className="pr-3"
                      labelClassName="font-semibold"
                      placeholder="Select"
                      label="Unit of Measure"
                      options={groupedUnitsOfMeasure}
                      value={getDropdownValue(
                        "unitOfMeasure",
                        lineItem?.highRange?.unitOfMeasure
                      )}
                      onChange={onChangeUnitOfMeasure}
                      validation={yup.mixed().required()}
                      onKeyDown={onEnterPress}
                      isDisabled={lineItem?.highRange?.rate}
                      // eslint-disable-next-line no-return-assign
                      forwardedRef={(el) =>
                        (inputRefs.current["18.highRangeUnitOfMeasure"] = el)
                      }
                      onKeyUp={handleKeyUp}
                      name="18.highRangeUnitOfMeasure"
                    />
                  </div>
                  <Input
                    className="pr-3"
                    labelClassName="font-semibold text-gray-300 opacity-70 text-xs"
                    inputClassName="ESInput bg-inputBgGreen"
                    label="Cost Per"
                    placeholder="Amount"
                    value={lineItem?.highRange?.costPerUnit}
                    onChange={(newVal) => {
                      dispatch({
                        type: "highRangeCostPerUnit",
                        value: newVal,
                      });
                    }}
                    validation={yup
                      .number()
                      .positive()
                      .transform((v, o) => (o === "" ? undefined : v))
                      .required()}
                    onKeyDown={onEnterPress}
                    disabled={lineItem?.highRange?.rate}
                    // eslint-disable-next-line no-return-assign
                    forwardedRef={(el) =>
                      (inputRefs.current["19.highRangeCostPerUnit"] = el)
                    }
                    handleEnter={handleKeyUp}
                    name="19.highRangeCostPerUnit"
                  />
                  <div className="flex items-end">
                    <Input
                      placeholder="Calculation"
                      className="pr-3"
                      labelClassName="font-semibold text-gray-300 opacity-70 text-xs"
                      label="Calculation"
                      inputClassName="ESInput bg-inputBgGreen"
                      value={lineItem?.highRange?.calculation}
                      onChange={onHighRangeCalculationChange}
                      onKeyDown={onEnterPress}
                      // eslint-disable-next-line no-return-assign
                      forwardedRef={(el) =>
                        (inputRefs.current["20.highRangeCalculation"] = el)
                      }
                      handleEnter={handleKeyUp}
                      name="20.highRangeCalculation"
                    />
                    <Input
                      className=""
                      label="Quantity"
                      labelClassName="font-semibold text-gray-300 opacity-70 text-xs"
                      placeholder="Number"
                      disabled
                      value={lineItem?.highRange?.quantity}
                    />
                  </div>
                </div>
              </>
            ),
            className: "border-r pl-3 pr-3",
          },
          {
            content: (
              <div className="flex-col w-48">
                <Input
                  placeholder="Total"
                  className="w-full"
                  disabled
                  labelClassName="font-semibold text-gray-300 opacity-70 text-xs"
                  label="Total (High Range)"
                  value={computeHighRangeAdjustedTotal(
                    lineItem?.highRange?.liveBudget
                  )}
                  onChange={(newVal) => {
                    dispatch({
                      type: "highRangeLiveBudget",
                      value: newVal,
                    });
                  }}
                />

                <>
                  <div className="flex font-semibold text-gray-300 opacity-70 text-xs pt-2">
                    Adjust Total Cost
                  </div>
                  <div className="flex items-center pt-1">
                    <div className="pr-2">
                      <ToggleTextButton
                        setIsLeftActive={(isTrue) => {
                          if (isTrue) {
                            dispatch({
                              type: "highRangeArithmeticUnit",
                              value: arithmeticToggleUnits?.add,
                            });
                          }
                        }}
                        setIsRightActive={(isTrue) => {
                          if (isTrue) {
                            dispatch({
                              type: "highRangeArithmeticUnit",
                              value: arithmeticToggleUnits?.subtract,
                            });
                          }
                        }}
                        isLeftActive={
                          lineItem?.highRange?.adjustment?.arithmeticUnit ===
                          arithmeticToggleUnits.add
                        }
                        isRightActive={
                          lineItem?.highRange?.adjustment?.arithmeticUnit ===
                          arithmeticToggleUnits.subtract
                        }
                        leftText=" + "
                        rightText=" - "
                        borderLeftPill="rounded-l-md border-gray-200 border"
                        borderRightPill="rounded-r-md border-gray-200 border"
                        textClassName="text-sm"
                        // eslint-disable-next-line no-return-assign
                        forwardedRef={(el) =>
                          (inputRefs.current["21.highRangeArithmeticUnit"] = el)
                        }
                        name="21.highRangeArithmeticUnit"
                      />
                    </div>
                    <Input
                      placeholder="Adjustment"
                      className="w-20 max-w-min pr-2"
                      labelClassName="font-semibold text-gray-300 opacity-70 text-xs"
                      inputClassName="ESInput bg-inputBgGreen"
                      value={lineItem?.highRange?.adjustment?.amount}
                      onChange={(newVal) => {
                        dispatch({
                          type: "highRangeAdjustment",
                          value: newVal,
                        });
                      }}
                      validation={yup
                        .number()
                        .positive()
                        .transform((v, o) => (o === "" ? undefined : v))}
                      onKeyDown={onEnterPress}
                      // eslint-disable-next-line no-return-assign
                      forwardedRef={(el) =>
                        (inputRefs.current["22.highRangeAdjustment"] = el)
                      }
                      handleEnter={handleKeyUp}
                      name="22.highRangeAdjustment"
                    />
                    <div className="pr-2">
                      <ToggleTextButton
                        setIsLeftActive={(isTrue) => {
                          if (isTrue) {
                            dispatch({
                              type: "highRangeAdjustmentUnit",
                              value: currencyToggleUnits?.dollar,
                            });
                          }
                        }}
                        setIsRightActive={(isTrue) => {
                          if (isTrue) {
                            dispatch({
                              type: "highRangeAdjustmentUnit",
                              value: currencyToggleUnits?.percent,
                            });
                          }
                        }}
                        isLeftActive={
                          lineItem?.highRange?.adjustment?.adjustmentUnit ===
                          currencyToggleUnits.dollar
                        }
                        isRightActive={
                          lineItem?.highRange?.adjustment?.adjustmentUnit ===
                          currencyToggleUnits.percent
                        }
                        leftText={` ${currencyToggleUnits.dollar}`}
                        rightText={` ${currencyToggleUnits.percent}`}
                        borderLeftPill="rounded-l-md border-gray-200 border"
                        borderRightPill="rounded-r-md border-gray-200 border"
                        textClassName="text-sm"
                        // eslint-disable-next-line no-return-assign
                        forwardedRef={(el) =>
                          (inputRefs.current["23.highRangeAdjustmentUnit"] = el)
                        }
                        name="23.highRangeAdjustmentUnit"
                      />
                    </div>
                  </div>
                </>
              </div>
            ),
            className: "border-r bg-backgroundGrey pl-3 pr-3",
          },
        ]
      : []),
    {
      content: (
        <>
          <div className="flex">
            <Checkbox
              className="flex items-center p-2"
              label="Allowance"
              checked={lineItem?.allowance}
              onChange={(newVal) => {
                dispatch({
                  type: "allowance",
                  value: newVal,
                });
              }}
              onFocusClassName="border-brandGreen"
              // eslint-disable-next-line no-return-assign
              ref={(el) => (inputRefs.current["14.allowance"] = el)}
              onKeyUp={handleKeyUp}
              name="14.allowance"
            />
          </div>
          <div className="flex">
            <Checkbox
              className="flex items-center pl-2 pb-2"
              label="Non Billable"
              checked={!lineItem?.isBillable}
              onChange={(newVal) => {
                dispatch({
                  type: "isBillable",
                  value: !newVal,
                });
              }}
              onFocusClassName="border-brandGreen"
              // eslint-disable-next-line no-return-assign
              ref={(el) => (inputRefs.current["15.isBillable"] = el)}
              onKeyUp={handleKeyUp}
              name="15.isBillable"
            />
          </div>
          <div className="flex">
            <Checkbox
              className="flex items-center pl-2 pb-2"
              label="Apply Retainage"
              checked={lineItem?.applyRetainage}
              onChange={(newVal) => {
                dispatch({
                  type: "applyRetainage",
                  value: newVal,
                });
              }}
              onFocusClassName="border-brandGreen"
              // eslint-disable-next-line no-return-assign
              ref={(el) => (inputRefs.current["16.applyRetainage"] = el)}
              onKeyUp={handleKeyUp}
              name="16.applyRetainage"
            />
          </div>
          {isDraftProject && (
            <div className="flex">
              <Checkbox
                className="flex items-center pl-2 pb-2"
                label="Cost Range"
                checked={lineItem?.isCostRange}
                onChange={onIsCostRangeChange}
                onFocusClassName="border-brandGreen"
                // eslint-disable-next-line no-return-assign
                ref={(el) => (inputRefs.current["17.costRange"] = el)}
                onKeyUp={handleKeyUp}
                name="17.costRange"
              />
            </div>
          )}
        </>
      ),
      className: "border-r bg-backgroundGrey pl-1 pr-2",
    },
  ];

  return (
    <>
      <div className={popOverCN}>
        <div className={rowCN}>
          {!hideCrossButton && (
            <div className="flex justify-start items-start pt-3">
              <BaseButton
                onClick={onCancel}
                iconLeft={
                  <img src={chevronIcon} alt="Close" className="w-6 h-6" />
                }
              />
            </div>
          )}
          {columns?.map((option, index) => (
            <div
              key={option.id}
              className={rowItemCN(
                index === columns.length - 1,
                option.className
              )}
            >
              {option.content}
            </div>
          ))}
          <div className="flex flex-col px-3 pt-10 relative bg-backgroundGrey items-center">
            <PrimaryButton
              saveButton
              onClick={onSubmit}
              disabled={
                !disableRequiredFieldsValidation && (isSaving || !isInputValid)
              }
              // eslint-disable-next-line no-return-assign
              forwardedRef={(el) => (inputRefs.current["24.save"] = el)}
              name="24.save"
            />
            {!isEdit && !hideAddAnother && (
              <div className="pt-2">
                <TertiaryButton
                  title="Save Another"
                  onClick={() => onSubmit(true)}
                  // eslint-disable-next-line no-return-assign
                  forwardedRef={(el) =>
                    (inputRefs.current["25.addAnother"] = el)
                  }
                  name="25.addAnother"
                  disabled={
                    !disableRequiredFieldsValidation &&
                    (isSaving || !isInputValid)
                  }
                  onHover={false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <LocationModal
        isOpen={lineItem?.building === "createNewBuilding"}
        title="NEW LOCATION"
        handleModalOpen={onCloseLocationModal}
        location={projectLocation}
        dispatch={dispatchProjectLocation}
        handleSave={onSaveLocation}
      />
      <LocationModal
        isOpen={lineItem?.building && lineItem?.space === "createNewSpace"}
        title="NEW SPACE"
        isSpace
        handleModalOpen={onCloseSpaceModal}
        location={projectLocationSpace}
        dispatch={dispatchProjectLocationSpace}
        spaceConfiguration={projectLocationSpaceConfiguration}
        handleSave={onSaveSpace}
      />
      <CreateContactModal
        isOpen={lineItem?.vendor === "createNewVendor"}
        handleModalOpen={onVendorResetAndClose}
        onSaveCallback={onSaveCompanyContact}
        contactType="company"
        actionOverride={() => {}}
        systemConfiguration={systemConfiguration}
        users={users}
        currentUser={currentUser}
      />
      <BudgetGroupModal
        isOpen={lineItem?.group?.value === "create" || lineItem?.isEditingGroup}
        handleModalClose={onCloseGroupModal}
        dispatch={dispatch}
        group={lineItem?.group}
        isEditingGroup={lineItem?.isEditingGroup}
        onAddGroup={onAddBudgetGroup}
        onEditGroup={onEditBudgetGroup}
        onDeleteGroup={onDeleteBudgetGroup}
      />
    </>
  );
};

BudgetTableInLineForm.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number,
    original: {
      line: PropTypes.string,
    },
  }),
  togglePopOver: PropTypes.func,
  projectBuildings: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  csiCodes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  unitsOfMeasure: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  isEdit: PropTypes.bool,
  onAddSaveCallback: PropTypes.func,
  onEditSaveCallback: PropTypes.func,
  onSplitLineSaveCallback: PropTypes.func,
  groupBy: PropTypes.arrayOf(PropTypes.string),
  prefillFieldsOnAddAnother: PropTypes.arrayOf(PropTypes.string),
  budgetGroups: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  onCancelSaveCallback: PropTypes.func,
  groupedUnitsOfMeasure: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    })
  ),
  onSaveProjectLocation: PropTypes.func,
  onSaveProjectLocationSpace: PropTypes.func,
  projectLocationSpaceConfiguration: PropTypes.shape({}),
  onSaveContactCallback: PropTypes.func,
  systemConfiguration: PropTypes.shape({}),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  rateSheetRates: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      ratePerHr: PropTypes.string,
    })
  ),
  isDraftProject: PropTypes.bool,
  onAddBudgetGroup: PropTypes.func,
  onEditBudgetGroup: PropTypes.func,
  onDeleteBudgetGroup: PropTypes.func,
  isSplitLinePopOver: PropTypes.bool,
  currentUser: PropTypes.shape({
    isAdmin: PropTypes.bool,
    isSuperAdmin: PropTypes.bool,
  }),
  hideCrossButton: PropTypes.bool,
  hideAddAnother: PropTypes.bool,
  disableRequiredFieldsValidation: PropTypes.bool,
};

BudgetTableInLineForm.defaultProps = {
  row: undefined,
  togglePopOver: () => {},
  csiCodes: [],
  unitsOfMeasure: [],
  vendors: [],
  projectBuildings: [],
  isEdit: false,
  onEditSaveCallback: undefined,
  onAddSaveCallback: undefined,
  onSplitLineSaveCallback: undefined,
  groupBy: [],
  prefillFieldsOnAddAnother: [],
  budgetGroups: [],
  groupedUnitsOfMeasure: [],
  onCancelSaveCallback: undefined,
  onSaveProjectLocation: undefined,
  onSaveProjectLocationSpace: undefined,
  projectLocationSpaceConfiguration: undefined,
  onSaveContactCallback: undefined,
  systemConfiguration: undefined,
  users: [],
  rateSheetRates: [],
  isDraftProject: false,
  onAddBudgetGroup: undefined,
  onEditBudgetGroup: undefined,
  onDeleteBudgetGroup: undefined,
  isSplitLinePopOver: false,
  currentUser: undefined,
  hideCrossButton: false,
  hideAddAnother: false,
  disableRequiredFieldsValidation: false,
};

export default BudgetTableInLineForm;

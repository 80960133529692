import { useCallback, useEffect, useState } from "react";
import useURLChange from "../../../hooks/useURLChange";
import useGoBack from "../../../hooks/useGoBack";
import useReportSelectorById from "../../../hooks/useReportSelectorById";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useReportDelete from "../../../hooks/useReportDelete";
import useDownloadFile from "../../../hooks/useDownloadFile";
import { useModalState } from "../../../state/modalState";
import getDownloadReportPermissions from "../../../helpers/Report/getDownloadReportPermissions";
import { ADD_OPEN_MODAL, REPORT_EDIT_MODAL } from "../../../constants";
import useQueryNotFoundNavigation from "../../../hooks/navigation/useQueryNotFoundNavigation";

const useReportSingleViewData = ({
  reportId,
  association,
  setButtonActions,
  onBackButtonPressed,
}) => {
  const [, modalDispatch] = useModalState();

  const { data: currentUser } = useCurrentUser();

  const isAdmin = currentUser?.isAdmin;
  const canWrite = isAdmin || currentUser?.permissions?.report?.can_write;
  const canDelete = isAdmin || currentUser?.permissions?.report?.can_delete;

  // Get report id from URL
  const url = useURLChange();
  const reportIdFromUrl = url.split("/").pop();

  // @id represent the request id to display details
  // This value could be taken from url (page) or component (P/P/A)
  const id = reportId || reportIdFromUrl;

  const { navigateBack } = useGoBack();

  // Query hook to fetch report by id
  const { data: report, isLoading, error } = useReportSelectorById(id);

  // Redirect to 404 page if resource is not found
  useQueryNotFoundNavigation({ error });

  // Allow to download ES Files
  const { handleDownloadESFile } = useDownloadFile();

  const { mutate: deleteReport } = useReportDelete();

  // Check if this report has run before.
  const hasPreviuosReportFiles = !!report?.original?.scheduler?.length;

  // Get permissions to download file report
  const canDownload =
    hasPreviuosReportFiles &&
    getDownloadReportPermissions(currentUser, report?.original);

  const reportForm = report?.original;

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [commentsData, setCommentsData] = useState({
    isOpen: false,
    reportReference: undefined,
  });

  // This effect hides the Add Report button in top
  // header if report is rendered inside
  // widget tabs of P/P/A
  useEffect(() => {
    if (!association || !setButtonActions) return;

    setButtonActions((prev) => {
      return prev.map((button) => ({ ...button, className: "hidden" }));
    });
  }, [setButtonActions, association]);

  /**
   * Handles the navigation back action. It checks if an @association is provided
   * and invokes it to conditionally render a Report single view. If it is not provided,
   * it performs a standard navigation back.
   */
  const handleNavigateBack = () => {
    if (association) {
      onBackButtonPressed();
    } else {
      navigateBack();
    }
  };

  /**
   * Open comments with report reference
   */
  const handleOpenComments = useCallback(() => {
    setCommentsData({
      reportReference: report?.original.reference,
      isOpen: true,
    });
  }, [report?.original.reference]);

  const handleCloseComments = useCallback(() => {
    setCommentsData({ reportReference: undefined, isOpen: false });
  }, []);

  /**
   * Deletes report
   */
  const handleDeleteReport = () => {
    deleteReport([id]);
    setShowDeleteDialog(false);
    handleNavigateBack();
  };

  /**
   * Opens edit modal
   */
  const handleOpenReportEditModal = () => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: `report-${id}` },
      modalData: {
        reportId: id,
      },
      modalType: REPORT_EDIT_MODAL,
    });
  };

  /**
   * Initializes report (file) download
   */
  const handleDownloadReport = () => {
    const fileReference = report?.original?.scheduler[0]?.file;
    handleDownloadESFile(fileReference);
  };

  return {
    reportForm,
    isLoading,
    commentsData,
    canWrite,
    canDelete,
    canDownload,
    showDeleteDialog,
    setShowDeleteDialog,
    handleNavigateBack,
    handleOpenComments,
    handleCloseComments,
    handleDeleteReport,
    handleOpenReportEditModal,
    handleDownloadReport,
  };
};

export default useReportSingleViewData;

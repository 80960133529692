import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

import useOutsideAlerter from "../../../hooks/useOutsideAlerter";

import EditField from "../EditField/EditField";
import TertiaryButton from "../Buttons/TertiaryButton";
import IconButton from "../Buttons/IconButton";
import SecondaryButton from "../Buttons/SecondaryButton";

import plusIcon from "../../assets/images/plusIcon.svg";
import editIcon from "../../assets/images/editIcon.svg";
import stopEditIcon from "../../assets/images/editIconStop.svg";
import collapseIcon from "../../assets/images/collapseIcon.svg";
import returnIcon from "../../assets/images/returnIcon.svg";
import SelectUserInterface from "../SelectUserInterface/SelectUserInterface";
import { handleLinkClick } from "../../../helpers/Utilities";

const cardCN = (
  noBorder,
  cardWidth,
  cardHeight,
  customClassName,
  hoverClassName
) => cntl`
  ${cardWidth || "w-full"}
  flex
  flex-col
  rounded-lg
  ${customClassName}
  ${noBorder ? "py-5" : "mb-4 p-4 border border-gray-200"}
  ${cardHeight}
  ${hoverClassName}
`;

const titleCN = (noBorder) => cntl`
flex 
text-gray-500
items-center 
justify-between
${noBorder ? "" : "mb-4"}
`;

const childCN = (noBorder) => cntl`
flex 
${noBorder ? "items-center" : "items-start flex-1 p-4"}
`;

const valueCN = (wordBreak) => cntl`
  font-medium 
  text-gray-700 
  text-sm 
  mt-1 
  ${wordBreak ? "word-break" : "truncate"}
`;

const DetailCard = ({
  title,
  type,
  options,
  users,
  value,
  links,
  selectedUsers,
  displayValue,
  subValue,
  editValue,
  addItemText,
  Component,
  onAdd,
  onChange,
  onDelete,
  onFinishEditing,
  isEditing,
  setIsEditing,
  disableTrackChange,
  noBorder,
  alwaysCallOnFinishEditing,
  cardWidth,
  cardHeight,
  customClassName,
  hoverClassName,
  titleClassName,
  hideEditButton,
  editBuildingId,
  setEditBuildingId,
  editSpaceId,
  setEditSpaceId,
  wordBreak,
  handleBuyLink,
  additionalInfo,
  overviewItems,
  getAssetCategoryValue,
  isPropertyVendor,
  isBrokerCompany,
  dispatch,
}) => {
  const [sourceData, setSourceData] = useState(value);
  const [editing, setEditing] = useState(false);
  const [edited, setEdited] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [deleteHover, setDeleteHover] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (isEditing) {
      setEditing(isEditing);
    }
  }, [isEditing]);

  useEffect(() => {
    if (value) {
      setSourceData(value);
    } else if (links) {
      setSourceData(links);
    }
  }, [value, links]);

  const onEditCallback = (id, spaceId) => {
    if (setEditSpaceId) {
      setEditSpaceId(spaceId);
    }
    if (setEditBuildingId) {
      setEditBuildingId(id);
    }
  };

  const handleHover = (mouseOver) => {
    if (mouseOver) {
      setHovering(true);
    } else {
      setHovering();
    }
  };

  const handleDeleteHover = (mouseOver) => {
    if (mouseOver) {
      setDeleteHover(true);
    } else {
      setDeleteHover();
    }
  };

  const handleBlur = () => {
    setEditing(false);
    setIsEditing(false);
    if ((editing && edited) || (editing && disableTrackChange)) {
      setEdited(false);
      onFinishEditing();
    }
  };
  useOutsideAlerter(wrapperRef, handleBlur);

  const handleAddClick = () => {
    onAdd();
    setEditing(true);
    setIsEditing(true);
    onEditCallback(editBuildingId, editSpaceId);
    setEdited(true);
  };

  const handleEditClick = () => {
    let newVal;
    let onFinishEditingCalled = false;
    setEdited(false);
    setEditing((prev) => {
      newVal = !prev;
      if (!newVal && edited) {
        onFinishEditing();
        onFinishEditingCalled = true;
      }
      return newVal;
    });
    setIsEditing(newVal);
    onEditCallback(editBuildingId || null, editSpaceId || null);
    if (alwaysCallOnFinishEditing && !onFinishEditingCalled) {
      onFinishEditing();
    }
  };

  const handleDeleteClick = (id) => {
    onDelete(id);
    setEdited(true);
    setEditing(false);
  };

  const handleChange = (valueA, valueB) => {
    onChange(valueA, valueB);
    setEdited(true);
  };

  const onBuyLinkChange = (val) => {
    handleBuyLink(val);
    setEdited(true);
  };

  const handleBuyLinkClick = (link) => {
    if (link) handleLinkClick(link);
    else handleEditClick();
  };

  const getComponent = () => {
    if (!Component) {
      return undefined;
    }
    if (typeof Component === "function") {
      return Component(handleEditClick);
    }
    return Component;
  };

  const handleDispatch = useCallback(
    (props) => {
      dispatch(props);
      setEdited(true);
    },
    [dispatch]
  );

  return (
    <div
      className={cardCN(
        noBorder,
        cardWidth,
        cardHeight,
        customClassName,
        hovering && hoverClassName
      )}
      ref={wrapperRef}
      onMouseOver={() => handleHover(true)}
      onMouseOut={() => handleHover()}
      onFocus={() => handleHover(true)}
      onBlur={() => handleHover()}
    >
      <div className={titleCN(noBorder)}>
        <p className={`${titleClassName || "font-semibold text-gray-500"}`}>
          {title}
        </p>
        {!hideEditButton && (
          <div className="flex items-center">
            {!isEditing && (
              <IconButton
                wrapperClassName={hovering ? "" : "hidden"}
                imgClassName="w-6 h-6"
                iconClassName="w-6 h-6"
                onClick={handleEditClick}
                icon={editIcon}
              />
            )}

            {isEditing && (
              <IconButton
                wrapperClassName={hovering ? "" : "invisible"}
                imgClassName="w-6 h-6"
                iconClassName="w-6 h-6"
                onClick={handleEditClick}
                icon={stopEditIcon}
              />
            )}
          </div>
        )}
      </div>

      <div
        className={childCN(noBorder)}
        onMouseOver={() => handleDeleteHover(true)}
        onMouseOut={() => handleDeleteHover()}
        onFocus={() => handleDeleteHover(true)}
        onBlur={() => handleDeleteHover()}
      >
        {selectedUsers && (
          <div className="flex w-full mt-4">
            {!!selectedUsers.length && (
              <SelectUserInterface
                className="w-full"
                userList={selectedUsers}
                userLabel="Add Member"
                userPlaceholder="Search Members"
                roleLabel="Select Role"
                rolePlaceholder="Search Roles"
                buttonText="Add Member"
                userOptions={users}
                roleOptions={options}
                onAddUser={() => {}}
                onRemoveUser={handleDeleteClick}
                disableRemove={!editing}
                disableCreateUser
                isSingleSelect
                isPropertyVendor={isPropertyVendor}
              />
            )}
          </div>
        )}

        {!selectedUsers && (
          <EditField
            type={type}
            value={sourceData}
            editValue={editValue}
            options={options}
            onChange={handleChange}
            onDelete={handleDeleteClick}
            onBlur={handleBlur}
            isEditing={onChange && editing}
            handleAddClick={handleAddClick}
            addItemText={addItemText}
            handleBuyLink={onBuyLinkChange}
            additionalInfo={additionalInfo}
            fullWidth
            overviewItems={overviewItems}
            setEdited={setEdited}
            getAssetCategoryValue={getAssetCategoryValue}
            isBrokerCompany={isBrokerCompany}
            dispatch={handleDispatch}
          >
            {getComponent() ?? (
              <div className="flex flex-col w-full">
                <div className={`flex flex-col ${links ? "" : "hidden"}`}>
                  {selectedUsers && <></>}
                  {links && (
                    <>
                      <SecondaryButton
                        title="Buy It Again"
                        className="mb-3"
                        onClick={() =>
                          handleBuyLinkClick(
                            additionalInfo?.find(
                              (el) => el.detail === "Buy It Again"
                            )?.value
                          )
                        }
                      />
                      {links.map((link) => (
                        <div
                          className="flex justify-between items-center w-full"
                          key={link.id}
                        >
                          <button
                            key={link.id}
                            type="button"
                            className="flex items-center mr-4 cursor-pointer select-none"
                            onClick={() => handleLinkClick(link)}
                          >
                            <p className="text-brandGreen font-semibold">
                              {link.name}
                            </p>
                            <img
                              src={returnIcon}
                              alt="arrow"
                              className="w-5 h-5 ml-3 mt-0.5"
                            />
                          </button>
                        </div>
                      ))}
                    </>
                  )}
                  <div className={onAdd ? "" : "hidden"}>
                    <TertiaryButton
                      title={addItemText ?? "Add Item"}
                      iconLeft={
                        <img
                          style={{ marginBottom: "2px" }}
                          src={plusIcon}
                          alt="plus"
                        />
                      }
                      onClick={handleAddClick}
                    />
                  </div>
                </div>

                {!links && (
                  <div
                    className={valueCN(wordBreak)}
                    title={displayValue ?? value}
                    aria-label={displayValue ?? value}
                  >
                    {displayValue ?? value}
                  </div>
                )}

                {subValue && (
                  <div className="text-gray-700 text-sm mt-1">{subValue}</div>
                )}
              </div>
            )}
          </EditField>
        )}

        <IconButton
          wrapperClassName={
            onDelete && deleteHover && editing && !links && !selectedUsers
              ? ""
              : "hidden"
          }
          imgClassName="w-6 h-6"
          iconClassName="w-6 h-6"
          onClick={handleDeleteClick}
          icon={collapseIcon}
        />
      </div>
    </div>
  );
};

DetailCard.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string })),
  // eslint-disable-next-line react/forbid-prop-types
  users: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOf(PropTypes.string, PropTypes.number, PropTypes.element),
  selectedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.string,
      position: PropTypes.string,
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  links: PropTypes.arrayOf(PropTypes.object),
  displayValue: PropTypes.oneOf(PropTypes.string, PropTypes.element),
  subValue: PropTypes.oneOf(PropTypes.string, PropTypes.element),
  addItemText: PropTypes.string,
  Component: PropTypes.oneOf(PropTypes.func, PropTypes.element),
  onAdd: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onFinishEditing: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  editValue: PropTypes.any,
  isEditing: PropTypes.bool,
  setIsEditing: PropTypes.func,
  disableTrackChange: PropTypes.bool,
  noBorder: PropTypes.bool,
  alwaysCallOnFinishEditing: PropTypes.bool,
  cardWidth: PropTypes.string,
  cardHeight: PropTypes.string,
  customClassName: PropTypes.string,
  hoverClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  hideEditButton: PropTypes.bool,
  editBuildingId: PropTypes.string,
  setEditBuildingId: PropTypes.func,
  editSpaceId: PropTypes.string,
  setEditSpaceId: PropTypes.func,
  /**
   * WordBreak ?? Truncate value string
   */
  wordBreak: PropTypes.bool,
  handleBuyLink: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  additionalInfo: PropTypes.array,
  overviewItems: PropTypes.shape({
    handleDeleteLink: PropTypes.func,
    handleAddLink: PropTypes.func,
    handleChangeLink: PropTypes.func,
    handleAddressInput: PropTypes.func,
    propertyTypes: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    projectTypes: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  getAssetCategoryValue: PropTypes.func,
  isPropertyVendor: PropTypes.bool,
  isBrokerCompany: PropTypes.bool,
  dispatch: PropTypes.func,
};

DetailCard.defaultProps = {
  title: undefined,
  type: undefined,
  options: undefined,
  users: undefined,
  value: undefined,
  selectedUsers: undefined,
  links: undefined,
  displayValue: undefined,
  subValue: undefined,
  addItemText: undefined,
  Component: undefined,
  onAdd: undefined,
  onChange: undefined,
  onDelete: undefined,
  onFinishEditing: () => {},
  editValue: undefined,
  isEditing: false,
  setIsEditing: () => {},
  disableTrackChange: false,
  noBorder: false,
  alwaysCallOnFinishEditing: false,
  cardWidth: undefined,
  cardHeight: undefined,
  customClassName: undefined,
  hoverClassName: undefined,
  titleClassName: undefined,
  hideEditButton: false,
  editBuildingId: undefined,
  setEditBuildingId: undefined,
  editSpaceId: undefined,
  setEditSpaceId: undefined,
  wordBreak: false,
  handleBuyLink: undefined,
  additionalInfo: [],
  overviewItems: undefined,
  getAssetCategoryValue: undefined,
  isPropertyVendor: false,
  isBrokerCompany: false,
  dispatch: undefined,
};

export default DetailCard;

import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DocumentTable from "../DocumentTable/DocumentTable";
import AttachItemModal from "../AttachItemModal/AttachItemModal";
import CustomDocumentReview from "../DocumentReviewForm/CustomDocumentReview";
import { getDocumentUser } from "../../../helpers/Document";

const DocumentAttachments = ({ docTypeOptionsMap, document }) => {
  const [currentAttachment, setCurrentAttachment] = useState({});
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const getDocType = React.useCallback(
    (documentType) => {
      if (documentType) {
        return docTypeOptionsMap?.[documentType]?.label ?? documentType;
      }
      return "File";
    },
    [docTypeOptionsMap]
  );

  const handleShowAttachment = (doc) => {
    setCurrentAttachment({
      id: doc ? doc.reference.split("/")?.[1] : "",
      contentReference: doc.contentReference,
    });
    setShowAttachmentModal(true);
  };

  const handleHideAttachment = () => {
    setCurrentAttachment({});
    setShowAttachmentModal(false);
  };

  return (
    <>
      <DocumentTable
        resourceName="view-document-attachments-table"
        documents={document?.attachments?.map((doc) => {
          const val = {
            ...doc,
            resource: doc.name,
            docType: getDocType(doc.docType),
            createdBy: getDocumentUser(doc.creator),
            modifiedBy: getDocumentUser(doc.modifier),
            modifiedDate: doc.metadata?.lastUpdated
              ? moment(doc.metadata.lastUpdated).format("MM/DD/YYYY")
              : "--",
          };

          delete val.id;
          return val;
        })}
        onRowClick={handleShowAttachment}
        allowSelection={false}
        noDeleteConfirm
        hideSiteHeader
        hideChangeView
        hideSaveButton
        hideButtons
        disableHover
        docTypeOptionsMap={docTypeOptionsMap}
      />
      <AttachItemModal
        content={<CustomDocumentReview document={currentAttachment} />}
        contentReference={currentAttachment.contentReference}
        onRequestModalClose={handleHideAttachment}
        isOpen={showAttachmentModal}
      />
    </>
  );
};

DocumentAttachments.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  docTypeOptionsMap: PropTypes.object,
};

DocumentAttachments.defaultProps = {
  document: {},
  docTypeOptionsMap: {},
};

export default DocumentAttachments;

import React, { useState } from "react";
import PropTypes from "prop-types";
import messageIcon from "../../../stories/assets/images/messagingArrow.svg";

const ExpandListButton = ({ onClick }) => {
  const [expanded, setExpanded] = useState({
    value: false,
    click: () => {
      onClick();
      setExpanded((prev) => ({ ...prev, value: !prev.value }));
    },
  });

  return (
    <button
      id="add-message-button"
      title="Add Message"
      type="button"
      className={`w-full flex justify-${
        expanded.value ? "start px-3" : "center"
      }`}
      style={{
        height: "50px",
        transform: `rotate(${expanded.value ? "0" : "180"}deg)`,
      }}
      onClick={expanded.click}
    >
      <img src={messageIcon} alt="add-message-icon" className="self-center" />
    </button>
  );
};

ExpandListButton.propTypes = {
  onClick: PropTypes.func,
};

ExpandListButton.defaultProps = {
  onClick: () => {},
};
export default ExpandListButton;

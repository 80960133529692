import React from "react";
import DescriptionField from "../../../stories/Components/DescriptionField";
import InlineInput from "../../../stories/Components/Input/InlineInput";

const Description = ({
  isEditing,
  serviceRequest,
  editableRequestForm,
  handleChangeForm,
  descriptionCN,
}) => {
  return (
    <div className="w-full pb-4 grid">
      <div className="flex w-full">
        {isEditing ? (
          <InlineInput
            width="w-full"
            size="custom4xl"
            value={editableRequestForm?.issue}
            editing={isEditing}
            loading={!serviceRequest?.original?.issue}
            disabled={!editableRequestForm?.issue}
            fontWeight="bold"
            color="gray-650"
            onConfirm={(value) => handleChangeForm("issue", value)}
            onChangeCallback={(value) => handleChangeForm("issue", value)}
            hidePencil
            isHeaderTitle
            autoFocus
          />
        ) : (
          <DescriptionField
            className={descriptionCN}
            text={serviceRequest?.original.issue}
          />
        )}
      </div>
    </div>
  );
};

export default Description;

import { cloneDeep } from "lodash";
import { useReducer } from "react";

const INITIAL_WORKFLOW = [
  {
    steps: [
      {
        position: 1,
        duration: {
          projected: 1,
          actual: 0,
        },
        description: "Step 1",
        users: [{ type: "submitter" }],
      },
      {
        position: 2,
        duration: {
          projected: 1,
          actual: 0,
        },
        description: "Step 2",
        users: [],
      },
      {
        position: 3,
        duration: {
          projected: 1,
          actual: 0,
        },
        description: "Step 3",
        users: [],
      },
      {
        position: 4,
        duration: {
          projected: 1,
          actual: 0,
        },
        description: "Step 4",
        users: [],
      },
    ],
  },
];
const INITIAL_WF = [
  {
    steps: [
      {
        position: 1,
        duration: {
          projected: 5,
          actual: 0,
        },
        description: "Step 1",
        users: [{ type: "approver" }],
      },
      {
        position: 2,
        duration: {
          projected: 5,
          actual: 0,
        },
        description: "Step 2",
        users: [{ type: "reviewer" }],
      },
    ],
  },
];

// definte initial state

const initialState = {
  customName: "",
  isTemplate: true,
  isDefaultTemplate: false,
  requestWorkflow: INITIAL_WORKFLOW,
};

// create reducer

const reducer = (state, action) => {
  switch (action.type) {
    case "name":
      return { ...state, customName: action.payload };
    case "defaultTemplate":
      return { ...state, isDefaultTemplate: action.payload };
    case "requestWorkflow":
      return { ...state, requestWorkflow: [action.payload] };
    case "setTemplate":
      return { ...action.payload };
    case "reset":
      return { ...cloneDeep(initialState) };
    default: {
      return initialState;
    }
  }
};

const useRequestTemplateReducer = (isWF) => {
  const [templateForm, dispatch] = useReducer(reducer, {
    ...initialState,
    requestWorkflow: isWF ? INITIAL_WF : INITIAL_WORKFLOW,
  });
  return [templateForm, dispatch];
};

export default useRequestTemplateReducer;

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import Input from "../Input/Input";
import PrimaryButton from "../Buttons/PrimaryButton";
import Checkbox from "../Checkbox/Checkbox";
import BaseButton from "../Buttons/BaseButton";
import crossIcon from "../../assets/images/crossIcon.svg";

const CSICodeAndMappingAddCodePopover = ({
  mapping,
  newCSICode,
  setNewCSICode,
  onFinishAdding,
  onClose,
}) => {
  const [addAnother, setAddAnother] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const checkValidation = async () => {
      try {
        await yup
          .string()
          .required()
          .matches(
            /^[0-9]{2}\s[0-9]{2}\s[0-9]{2}$/,
            "Code must be of type XX XX XX"
          )
          .validate(newCSICode.code);
        await yup.string().required().validate(newCSICode.description);

        setIsValid(true);
      } catch (err) {
        setIsValid(false);
      }
    };

    checkValidation();
  }, [newCSICode]);

  const onChangeCode = useCallback(
    (val) => {
      if (
        val?.length >= 2 &&
        val.startsWith(`${mapping.division}`) &&
        val?.length <= 8
      ) {
        setNewCSICode({
          ...newCSICode,
          code: val
            .replace(/[^\dA-Z]/g, "")
            .replace(/(.{2})/g, "$1 ")
            .trim(),
        });
      }
    },
    [mapping, newCSICode, setNewCSICode]
  );

  return (
    <div className="flex flex-col border-2 border-gray-100 p-4 mb-4">
      <div className="flex py-2 justify-between">
        <h3 className="uppercase font-bold">Add CSI Code</h3>
        <div>
          <BaseButton
            onClick={onClose}
            iconLeft={<img src={crossIcon} alt="crossIcon" />}
          />
        </div>
      </div>
      <div className="flex flex-row py-2 w-full">
        <div className="w-1/6 pr-4">
          <Input
            placeholder="CSI Code"
            className="text-gray-300"
            label="CSI Code"
            value={newCSICode?.code}
            onChange={(val) => onChangeCode(val)}
            showValidationErrorAtBottom
            validation={yup
              .string()
              .required()
              .matches(
                /^[0-9]{2}\s[0-9]{2}\s[0-9]{2}$/,
                "Code must be of type XX XX XX"
              )}
            disableClear
            errorMessageClassName={
              newCSICode?.code?.length === 2 && "invisible"
            }
          />
        </div>
        <div className="w-full pr-4">
          <Input
            placeholder="Description"
            className="text-gray-300"
            label="Description"
            value={newCSICode?.description}
            onChange={(val) =>
              setNewCSICode({
                ...newCSICode,
                description: val,
              })
            }
            validation={yup.string().required()}
          />
        </div>
        <div className="w-1/6 flex flex-col pt-7">
          <PrimaryButton
            title="Add Code"
            onClick={() => onFinishAdding(addAnother)}
            disabled={!isValid}
          />
          <Checkbox
            label="Add Another"
            className="py-2"
            onChange={setAddAnother}
            checked={addAnother}
          />
        </div>
      </div>
    </div>
  );
};

CSICodeAndMappingAddCodePopover.propTypes = {
  newCSICode: PropTypes.shape({
    code: PropTypes.string,
    description: PropTypes.string,
  }),
  setNewCSICode: PropTypes.func,
  onFinishAdding: PropTypes.func,
  onClose: PropTypes.func,
  mapping: PropTypes.shape({
    division: PropTypes.string,
  }),
};

CSICodeAndMappingAddCodePopover.defaultProps = {
  newCSICode: undefined,
  setNewCSICode: undefined,
  onFinishAdding: undefined,
  onClose: undefined,
  mapping: undefined,
};

export default CSICodeAndMappingAddCodePopover;

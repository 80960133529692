import { PreferenceAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { toastError, toastMessage } from "../stories/Components/Toast/Toast";

const patchPreferences = async ({
  preferenceId,
  newPreferenceData,
  oldPreferenceData,
}) => {
  const { data } = await PreferenceAPI.patch(
    preferenceId,
    newPreferenceData,
    oldPreferenceData
  );

  return data;
};

/**
 * Mutation hook to patch preferences
 * @param {string} mutationKey custom key to track mutations
 * @returns mutation metadata
 */
const usePreferencePatch = (mutationKey) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey,
    mutationFn: patchPreferences,
    onError: (error) => {
      toastError("Preferences could not be updated. Please try again");
      console.error("usePreferencePatch", error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("configuration");
      toastMessage("Preference Updated Successfully");
    },
  });
};

export default usePreferencePatch;

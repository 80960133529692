import moment from "moment";
import convertTZ from "./convertTimezone";

const updateTimeValueOnDate = (dateToUpdate, date, timezone) => {
  // set the time value of a date (dateToUpdate) to the time value of another date (date)
  const dateFrom = moment(date);
  const updatedValue = moment(dateToUpdate).set({
    hour: dateFrom.hour(),
    minute: dateFrom.minutes(),
    second: dateFrom.seconds(),
  });

  return convertTZ(updatedValue.format(), timezone);
};

export default updateTimeValueOnDate;

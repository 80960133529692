import React from "react";
import BudgetLineInput from "../DocumentAdjustments/BudgetLineInput";
import AdjustmentSplit from "../DocumentAdjustments/AdjustmentSplit";
import CurrencyView from "../CurrencyView/CurrrencyView";
import TertiaryHeader from "../TextHeaders/TertiaryHeader";
import commentIcon from "../../assets/images/comment_icon.svg";

import {
  computeAdjustedTotal,
  getAggregatedTotal,
} from "../../../helpers/Budget";
import {
  formatNumber,
  reformatCurrencyString,
} from "../../../helpers/Formatters";
import {
  BUDGET_COST_PLUS,
  BUDGET_FIXED_FIRM,
  BUDGET_TIME_AND_MATERIALS,
  CHANGE_ORDER,
  CONTINGENCY_AUTHORIZATION,
  docLineItemAdjustmentKeys,
  GET_PROJECT_DOCUMENTS_DOC_PATH,
  PURCHASE_AUTHORIZATION,
  PURCHASE_ORDER,
  REQUEST_FOR_PROPOSAL,
  TABLE_COLUMN_WIDTH,
  VENDOR_INVOICE,
} from "../../../constants";
import ColumnFooter from "../BudgetTable/ColumnFooter";
import CircularProgressBar from "../CircularProgressBar/CircularProgressBar";
import IconButton from "../Buttons/IconButton";
import BudgetTableDocumentsCell from "../BudgetTable/BudgetTableDocumentsCell";
import TableDescriptionCell from "../Table/TableDescriptionCell";
import BooleanBullet from "../BooleanBullet/BooleanBullet";

const getMaxValue = (
  costPlusLocked,
  contingencyUVP,
  rows,
  key,
  currentRowId
) => {
  const totalContingencyUsed = rows
    .filter((row) => row.original.id !== currentRowId)
    .reduce((sum, n) => sum + formatNumber(n.original?.[key]), 0);
  if (costPlusLocked) {
    return contingencyUVP - totalContingencyUsed;
  }
  return undefined;
};

const getMinValue = (lineItemUVP) => {
  if (Number(lineItemUVP) === 0) {
    return 0;
  }
  return lineItemUVP * -1;
};

const getRfpReferencesColumns = (unitsOfMeasure) => {
  //   const requestForProposal = [
  //     "csiCode",
  //     "description",
  //     "location",
  //     "quantity",
  //     "unitOfMeasure",
  //     "costPerUnit",
  //     "adjustment",
  //     "liveBudget",
  //   ];

  const columns = [
    {
      Header: "CSI Code",
      accessor: "csiCode",
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }) => <div className="truncate">{value}</div>,
      minWidth: 200,
      isPrimary: true,
    },
    { Header: "Location", accessor: "buildingName", isPrimary: true },
    {
      Header: "QTY",
      accessor: "quantity",
      aggregate: "sum",
      Aggregated: () => null,
      isPrimary: true,
    },
    {
      Header: "Unit Of Measure",
      accessor: "unitOfMeasure",
      Cell: ({ row }) => {
        return (
          <div className="flex">
            <p>
              {
                unitsOfMeasure?.find(
                  (unit) => unit.value === row?.original?.unitOfMeasure
                )?.label
              }
            </p>
          </div>
        );
      },
      minWidth: 180,
      isPrimary: true,
    },
    {
      Header: "Cost Per",
      accessor: "costPerUnit",
      Cell: ({ value }) => <CurrencyView value={value} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.costPerUnit);
        const bVal = formatNumber(b.values.costPerUnit);
        return aVal - bVal;
      },
      isPrimary: true,
    },
    {
      Header: "Adjustment",
      accessor: "adjustment",
      Cell: ({ value }) => {
        return (
          <CurrencyView
            value={value?.amount}
            isPercentage={value?.adjustmentUnit === "%"}
          />
        );
      },
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.adjustment?.amount);
        const bVal = formatNumber(b.values.adjustment?.amount);
        return aVal - bVal;
      },
      isPrimary: true,
    },
    {
      Header: "Live Budget",
      accessor: "liveBudget",
      Cell: ({
        row: {
          original: { costPerUnit, quantity, adjustment },
        },
      }) =>
        React.useMemo(() => {
          const adjustedTotal = computeAdjustedTotal(
            costPerUnit * (quantity || 0),
            adjustment?.arithmeticUnit,
            adjustment?.adjustmentUnit,
            adjustment?.amount
          );
          return <CurrencyView value={adjustedTotal} />;
        }, [costPerUnit, quantity, adjustment]),
      Footer: ({ rows }) => {
        const total = React.useMemo(
          () =>
            rows.reduce(
              (sum, { original: { costPerUnit, quantity, adjustment } }) => {
                const adjustedTotal = computeAdjustedTotal(
                  costPerUnit * (quantity || 0),
                  adjustment?.arithmeticUnit,
                  adjustment?.adjustmentUnit,
                  adjustment?.amount
                );
                return sum + adjustedTotal;
              },
              0
            ),
          [rows]
        );
        return <CurrencyView value={total} budget />;
      },
      sortType: (a, b) => {
        const aVal = computeAdjustedTotal(
          a.values.costPerUnit * (a.values.quantity || 0),
          a.values.adjustment?.arithmeticUnit,
          a.values.adjustment?.adjustmentUnit,
          a.values.adjustment?.amount
        );
        const bVal = computeAdjustedTotal(
          b.values.costPerUnit * (b.values.quantity || 0),
          b.values.adjustment?.arithmeticUnit,
          b.values.adjustment?.adjustmentUnit,
          b.values.adjustment?.amount
        );
        return aVal - bVal;
      },
      isPrimary: true,
    },
  ];

  return columns;
};

const getCoReferencesColumns = (budget) => {
  const { budgetType } = budget ?? {};
  const isCostPlusBudgetType = budgetType === BUDGET_COST_PLUS;
  const columns = [
    {
      Header: "CSI Code",
      accessor: "csiCode",
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }) => <div className="truncate">{value}</div>,
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Vendor",
      accessor: "vendorName",
      Cell: ({ value }) => <div className="truncate">{value}</div>,
      minWidth: 150,
      isPrimary: true,
    },
    {
      Header: "Uncommitted Value",
      accessor: "uncommittedValue",
      Cell: ({ value }) => <CurrencyView value={value} budget />,
      Footer: ({ rows }) => {
        const total = React.useMemo(
          () =>
            rows.reduce((sum, { original }) => {
              return sum + formatNumber(original.uncommittedValue);
            }, 0),
          [rows]
        );
        return <CurrencyView value={total} budget />;
      },
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.uncommittedValue);
        const bVal = formatNumber(b.values.uncommittedValue);
        return aVal - bVal;
      },
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Uncommitted Value (pending)",
      accessor: "uncommittedValuePending",
      Cell: ({ value }) => <CurrencyView value={value} budget />,
      Footer: ({ rows }) => {
        const total = React.useMemo(
          () =>
            rows.reduce((sum, { original }) => {
              return sum + formatNumber(original.uncommittedValuePending);
            }, 0),
          [rows]
        );
        return <CurrencyView value={total} budget />;
      },
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.uncommittedValuePending);
        const bVal = formatNumber(b.values.uncommittedValuePending);
        return aVal - bVal;
      },
      minWidth: 280,
      isPrimary: true,
    },
    {
      Header: "Published Budget",
      accessor: "publishedBudget",
      Cell: ({ value }) => <CurrencyView value={value} />,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.publishedBudget);
        const bVal = formatNumber(b.values.publishedBudget);
        return aVal - bVal;
      },
      width: 180,
    },
    ...(isCostPlusBudgetType
      ? [
          {
            Header: "Published Budget (pending)",
            accessor: "publishedBudgetPending",
            Cell: ({ value }) => <CurrencyView value={value} />,
            Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
            sortType: (a, b) => {
              const aVal = formatNumber(a.values.publishedBudgetPending);
              const bVal = formatNumber(b.values.publishedBudgetPending);
              return aVal - bVal;
            },

            width: 250,
          },
        ]
      : []),
    {
      Header: "CO Total (pending)",
      accessor: "coTotalPending",
      Cell: ({ value }) => <CurrencyView value={value} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.coTotalPending);
        const bVal = formatNumber(b.values.coTotalPending);
        return aVal - bVal;
      },
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      isPrimary: true,
      width: 200,
    },
  ];

  return columns;
};

const getCaReferencesColumns = (budget) => {
  const { budgetType } = budget ?? {};
  const isCostPlusBudgetType = budgetType === BUDGET_COST_PLUS;

  const columns = [
    {
      Header: "CSI Code",
      accessor: "csiCode",
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }) => <div className="truncate">{value}</div>,
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Vendor",
      accessor: "vendorName",
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Uncommitted Value",
      accessor: "uncommittedValue",
      Cell: ({ value }) => <CurrencyView value={value} budget />,
      Footer: ({ rows }) => {
        const total = React.useMemo(
          () =>
            rows.reduce((sum, { original }) => {
              return sum + formatNumber(original.uncommittedValue);
            }, 0),
          [rows]
        );
        return <CurrencyView value={total} budget />;
      },
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.uncommittedValue);
        const bVal = formatNumber(b.values.uncommittedValue);
        return aVal - bVal;
      },
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Uncommitted Value (pending)",
      accessor: "uncommittedValuePending",
      Cell: ({ value }) => <CurrencyView value={value} budget />,
      Footer: ({ rows }) => {
        const total = React.useMemo(
          () =>
            rows.reduce((sum, { original }) => {
              return sum + formatNumber(original.uncommittedValuePending);
            }, 0),
          [rows]
        );
        return <CurrencyView value={total} budget />;
      },
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.uncommittedValuePending);
        const bVal = formatNumber(b.values.uncommittedValuePending);
        return aVal - bVal;
      },
      minWidth: 280,
      isPrimary: true,
    },
    {
      Header: "Published Budget",
      accessor: "publishedBudget",
      Cell: ({ value }) => <CurrencyView value={value} />,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.publishedBudget);
        const bVal = formatNumber(b.values.publishedBudget);
        return aVal - bVal;
      },
      width: 180,
    },
    ...(isCostPlusBudgetType
      ? [
          {
            Header: "Published Budget (pending)",
            accessor: "publishedBudgetPending",
            Cell: ({ value }) => <CurrencyView value={value} />,
            Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
            sortType: (a, b) => {
              const aVal = formatNumber(a.values.publishedBudgetPending);
              const bVal = formatNumber(b.values.publishedBudgetPending);
              return aVal - bVal;
            },

            width: 250,
          },
        ]
      : []),
  ];

  return columns;
};

const getPaReferencesColumns = (dispatch, budget, selected, editMode) => {
  const editable = true;
  const selectAll = true;
  const { budgetType, isLockGmp } = budget ?? {};
  const costPlusLocked = budgetType === BUDGET_COST_PLUS && isLockGmp;
  const isCostPlusBudgetType = budgetType === BUDGET_COST_PLUS;

  const columns = [
    {
      Header: "CSI Code",
      accessor: "csiCode",
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }) => <div className="truncate">{value}</div>,
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Vendor",
      accessor: "vendorName",
      Cell: ({ value }) => <div className="truncate">{value}</div>,
      minWidth: 150,
      isPrimary: true,
    },
    {
      Header: "Published Budget",
      accessor: "publishedBudget",
      Cell: ({ value }) => <CurrencyView value={value} />,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.publishedBudget);
        const bVal = formatNumber(b.values.publishedBudget);
        return aVal - bVal;
      },
      width: 180,
    },
    ...(isCostPlusBudgetType
      ? [
          {
            Header: "Published Budget (pending)",
            accessor: "publishedBudgetPending",
            Cell: ({ value }) => <CurrencyView value={value} />,
            Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
            sortType: (a, b) => {
              const aVal = formatNumber(a.values.publishedBudgetPending);
              const bVal = formatNumber(b.values.publishedBudgetPending);
              return aVal - bVal;
            },

            width: 250,
          },
        ]
      : []),
    {
      Header: "Uncommitted Value",
      accessor: "uncommittedValue",
      Cell: ({ value }) => <CurrencyView value={value} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.uncommittedValue);
        const bVal = formatNumber(b.values.uncommittedValue);
        return aVal - bVal;
      },
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Uncommitted Value (pending)",
      accessor: "uncommittedValuePending",
      Cell: ({ value }) => <CurrencyView value={value} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.uncommittedValuePending);
        const bVal = formatNumber(b.values.uncommittedValuePending);
        return aVal - bVal;
      },
      minWidth: 280,
      isPrimary: true,
    },
    {
      Header: "PA Amount",
      accessor: "paAmount",
      Cell: ({ row: { original } }) => {
        return editMode && editable ? (
          <BudgetLineInput
            itemKey="paAmount"
            lineItem={original}
            dispatch={dispatch}
            maxValue={original.uncommittedValuePending}
            disableNegative
            disableZero
          />
        ) : (
          <CurrencyView value={original.paAmount} />
        );
      },
      sortType: (a, b) => {
        const aVal = formatNumber(a.paAmount);
        const bVal = formatNumber(b.paAmount);
        return aVal - bVal;
      },
      Footer: ({ rows }) => {
        const total = React.useMemo(
          () =>
            rows.reduce((sum, { original }) => {
              if (selected.includes(original.id) || selectAll) {
                return sum + formatNumber(original.paAmount);
              }
              return sum;
            }, 0),
          [rows]
        );

        return <CurrencyView value={total} budget />;
      },
      minWidth: 215,
      isPrimary: true,
    },
  ];

  if (costPlusLocked) {
    columns.push({
      Header: "Adjustment",
      accessor: `${docLineItemAdjustmentKeys[PURCHASE_AUTHORIZATION]}`,
      Cell: ({ row: { original } }) => {
        const key = `${docLineItemAdjustmentKeys[PURCHASE_AUTHORIZATION]}`;
        return editMode && editable ? (
          <BudgetLineInput
            itemKey={key}
            lineItem={original}
            dispatch={dispatch}
            disableNegative
            minValue={0}
          />
        ) : (
          <CurrencyView value={original[key]} />
        );
      },
      sortType: (a, b) => {
        const key = `${docLineItemAdjustmentKeys[PURCHASE_AUTHORIZATION]}`;
        const aVal = formatNumber(a[key]);
        const bVal = formatNumber(b[key]);
        return aVal - bVal;
      },
      Footer: ({ rows }) => {
        const key = `${docLineItemAdjustmentKeys[PURCHASE_AUTHORIZATION]}`;
        const total = React.useMemo(
          () =>
            rows.reduce((sum, { original }) => {
              if (selected.includes(original.id) || selectAll) {
                return sum + formatNumber(original[key]);
              }
              return sum;
            }, 0),
          [rows, key]
        );

        return <CurrencyView value={total} budget />;
      },
      minWidth: 215,
      isPrimary: true,
    });
  }

  return columns;
};

const getPoReferencesColumns = (
  budget,
  parentDocType,
  project,
  dispatch,
  editMode
) => {
  const { budgetType, isLockFixedFirm } = budget ?? {};
  const fixedFirmLocked = budgetType === BUDGET_FIXED_FIRM && isLockFixedFirm;

  const columns = [
    {
      Header: "CSI Code",
      accessor: "csiCode",
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }) => <div className="truncate">{value}</div>,
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Rate",
      accessor: "rate",
      width: 180,
      Cell: ({ row }) => {
        return (
          <div className="flex">
            <p>
              {
                project?.rateSheet?.rates?.find(
                  (rate) => rate.id === row?.original?.rate
                )?.category
              }
            </p>
          </div>
        );
      },
    },
    {
      Header: "Vendor",
      accessor: "vendorName",
      minWidth: 200,
      isPrimary: true,
    },
    ...(parentDocType === PURCHASE_AUTHORIZATION
      ? [
          {
            Header: "PA",
            accessor: "purchaseAuthorization",
            Cell: ({ row }) => (
              <BudgetTableDocumentsCell
                row={row}
                columnId="purchaseAuthorization"
              />
            ),
            disableSortBy: true,
            isPrimary: true,
          },
          {
            Header: "PA Total",
            accessor: "paTotal",
            Cell: ({ value }) => <CurrencyView value={value} />,
            // Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
            sortType: (a, b) => {
              const aVal = formatNumber(a.values.paTotal);
              const bVal = formatNumber(b.values.paTotal);
              return aVal - bVal;
            },
            aggregate: "sum",
            Aggregated: ({ value }) => <CurrencyView value={value} />,
            isPrimary: true,
          },
        ]
      : []),
    ...(parentDocType === CONTINGENCY_AUTHORIZATION
      ? [
          {
            Header: "CA",
            accessor: "contingencyAuthorization",
            Cell: ({ row }) => (
              <BudgetTableDocumentsCell
                row={row}
                columnId="contingencyAuthorization"
              />
            ),
            disableSortBy: true,
            isPrimary: true,
          },
          {
            Header: "CA Total",
            accessor: "caTotal",
            Cell: ({ value }) => <CurrencyView value={value} />,
            sortType: (a, b) => {
              const aVal = formatNumber(a.values.caTotal);
              const bVal = formatNumber(b.values.caTotal);
              return aVal - bVal;
            },
            aggregate: "sum",
            Aggregated: ({ value }) => <CurrencyView value={value} />,
            isPrimary: true,
          },
        ]
      : []),
    ...(parentDocType === CHANGE_ORDER
      ? [
          {
            Header: "CO",
            accessor: "changeOrder",
            Cell: ({ row }) => (
              <BudgetTableDocumentsCell row={row} columnId="changeOrder" />
            ),
            disableSortBy: true,
            isPrimary: true,
          },
          {
            Header: "CO Total",
            accessor: "coTotal",
            Cell: ({ value }) => <CurrencyView value={value} />,
            sortType: (a, b) => {
              const aVal = formatNumber(a.values.coTotal);
              const bVal = formatNumber(b.values.coTotal);
              return aVal - bVal;
            },
            aggregate: "sum",
            Aggregated: ({ value }) => <CurrencyView value={value} />,
            isPrimary: true,
          },
        ]
      : []),
    ...(!parentDocType
      ? [
          {
            Header: "Uncommitted Value",
            accessor: "uncommittedValue",
            Cell: ({ value }) => <CurrencyView value={value} budget />,
            sortType: (a, b) => {
              const aVal = formatNumber(a.values.uncommittedValue);
              const bVal = formatNumber(b.values.uncommittedValue);
              return aVal - bVal;
            },
            Aggregated: ({ value }) => <CurrencyView value={value} />,
            isPrimary: true,
            width: 280,
          },
          {
            Header: "Uncommitted Value (pending)",
            accessor: "uncommittedValuePending",
            Cell: ({ value }) => <CurrencyView value={value} budget />,
            sortType: (a, b) => {
              const aVal = formatNumber(a.values.uncommittedValuePending);
              const bVal = formatNumber(b.values.uncommittedValuePending);
              return aVal - bVal;
            },
            minWidth: 280,
            isPrimary: true,
          },
          {
            Header: "Updated Fixed Firm",
            accessor: "updatedGmpOrFixedFirm",
            Cell: ({ value }) => <CurrencyView value={value} />,
            sortType: (a, b) => {
              const aVal = formatNumber(a.values.updatedGmpOrFixedFirm);
              const bVal = formatNumber(b.values.updatedGmpOrFixedFirm);
              return aVal - bVal;
            },
            width: 250,
          },
          {
            Header: "Updated Fixed Firm (pending)",
            accessor: "updatedGmpPending",
            Cell: ({ value }) => <CurrencyView value={value} />,
            sortType: (a, b) => {
              const aVal = formatNumber(a.values.updatedGmpPending);
              const bVal = formatNumber(b.values.updatedGmpPending);
              return aVal - bVal;
            },
            width: 250,
          },
        ]
      : []),

    ...(parentDocType
      ? [
          {
            Header: "Total",
            accessor: "total",
            Cell: ({ row }) =>
              React.useMemo(() => {
                const { original } = row;
                let adjustedTotal = 0;
                if (fixedFirmLocked) {
                  adjustedTotal = formatNumber(original.uncommittedValue);
                } else {
                  switch (parentDocType) {
                    case PURCHASE_AUTHORIZATION:
                      adjustedTotal = formatNumber(original.paTotal);
                      break;
                    case CONTINGENCY_AUTHORIZATION:
                      adjustedTotal = formatNumber(original.caTotal);
                      break;
                    case CHANGE_ORDER:
                      adjustedTotal = formatNumber(original.coTotal);
                      break;
                    default:
                      adjustedTotal =
                        formatNumber(original.paTotal) +
                        formatNumber(original.caTotal) +
                        formatNumber(original.coTotal);
                  }
                }
                return <CurrencyView value={adjustedTotal} />;
              }, [row]),
            Footer: ({ rows }) => {
              const total = React.useMemo(
                () =>
                  rows.reduce(
                    (
                      sum,
                      {
                        original: {
                          paTotal,
                          caTotal,
                          coTotal,
                          uncommittedValue,
                        },
                      }
                    ) => {
                      let adjustedTotal = 0;
                      if (fixedFirmLocked) {
                        adjustedTotal = formatNumber(uncommittedValue);
                      } else {
                        switch (parentDocType) {
                          case PURCHASE_AUTHORIZATION:
                            adjustedTotal = formatNumber(paTotal);
                            break;
                          case CONTINGENCY_AUTHORIZATION:
                            adjustedTotal = formatNumber(caTotal);
                            break;
                          case CHANGE_ORDER:
                            adjustedTotal = formatNumber(coTotal);
                            break;
                          default:
                            adjustedTotal =
                              formatNumber(paTotal) +
                              formatNumber(caTotal) +
                              formatNumber(coTotal);
                        }
                      }
                      return sum + adjustedTotal;
                    },
                    0
                  ),
                [rows]
              );
              return <CurrencyView value={total} budget />;
            },
            sortType: (a, b) => {
              let aVal = 0;
              let bVal = 0;
              if (fixedFirmLocked) {
                aVal = formatNumber(a.values.uncommittedValue);
                bVal = formatNumber(b.values.uncommittedValue);
              } else {
                switch (parentDocType) {
                  case PURCHASE_AUTHORIZATION:
                    aVal = formatNumber(a.values.paTotal);
                    bVal = formatNumber(b.values.paTotal);
                    break;
                  case CONTINGENCY_AUTHORIZATION:
                    aVal = formatNumber(a.values.caTotal);
                    bVal = formatNumber(b.values.caTotal);
                    break;
                  case CHANGE_ORDER:
                    aVal = formatNumber(a.values.coTotal);
                    bVal = formatNumber(b.values.coTotal);
                    break;
                  default:
                    aVal =
                      formatNumber(a.values.paTotal) +
                      formatNumber(a.values.caTotal) +
                      formatNumber(a.values.coTotal);
                    bVal =
                      formatNumber(b.values.paTotal) +
                      formatNumber(b.values.caTotal) +
                      formatNumber(b.values.coTotal);
                }
              }
              return aVal - bVal;
            },
            isPrimary: true,
          },
        ]
      : [
          {
            Header: "PO Total (pending)",
            accessor: "poTotalPending",
            Cell: ({ row: { original } }) => {
              return editMode ? (
                <BudgetLineInput
                  itemKey="poTotalPending"
                  lineItem={original}
                  dispatch={dispatch}
                  maxValue={original.uncommittedValuePending}
                  disableNegative
                  disableZero
                />
              ) : (
                <CurrencyView value={original.poTotalPending} />
              );
            },
            sortType: (a, b) => {
              const aVal = formatNumber(a.poTotalPending);
              const bVal = formatNumber(b.poTotalPending);
              return aVal - bVal;
            },
            Footer: ({ rows }) => {
              const total = React.useMemo(
                () =>
                  rows.reduce((sum, { original }) => {
                    return sum + formatNumber(original.poTotalPending);
                  }, 0),
                [rows]
              );

              return <CurrencyView value={total} budget />;
            },
            minWidth: 215,
            isPrimary: true,
          },
        ]),
  ];

  return columns;
};

const getRfpAdjustmentsColumns = () => {
  const columns = [
    {
      Header: "CSI Code",
      accessor: "csiCode",
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }) => <div className="truncate">{value}</div>,
      minWidth: 200,
      isPrimary: true,
    },
    { Header: "Location", accessor: "buildingName", isPrimary: true },
    {
      Header: "Group",
      accessor: "group",
      Cell: ({ row }) => {
        return (
          <div className="flex">
            <p>{row?.original?.group?.label}</p>
          </div>
        );
      },
      isPrimary: true,
    },
    {
      Header: "QTY",
      accessor: "quantity",
      aggregate: "sum",
      Aggregated: () => null,
      isPrimary: true,
    },
  ];

  return columns;
};

const getCoAdjustmentsColumns = (dispatch, selected, editMode, budget) => {
  const { budgetType } = budget ?? {};
  const isCostPlusBudgetType = budgetType === BUDGET_COST_PLUS;

  const columns = [
    {
      Header: "CSI Code",
      accessor: "csiCode",
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }) => <div className="truncate">{value}</div>,
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Vendor",
      accessor: "vendorName",
      Cell: ({ value }) => <div className="truncate">{value}</div>,
      minWidth: 150,
      isPrimary: true,
    },
    {
      Header: "Uncommitted Value",
      accessor: "uncommittedValue",
      Cell: ({ value }) => <CurrencyView value={value} budget />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.uncommittedValue);
        const bVal = formatNumber(b.values.uncommittedValue);
        return aVal - bVal;
      },
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Uncommitted Value (pending)",
      accessor: "uncommittedValuePending",
      Cell: ({ value }) => <CurrencyView value={value} budget />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.uncommittedValuePending);
        const bVal = formatNumber(b.values.uncommittedValuePending);
        return aVal - bVal;
      },
      minWidth: 280,
      isPrimary: true,
    },
    {
      Header: "Published Budget",
      accessor: "publishedBudget",
      Cell: ({ value }) => <CurrencyView value={value} />,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.publishedBudget);
        const bVal = formatNumber(b.values.publishedBudget);
        return aVal - bVal;
      },
      width: 180,
    },
    ...(isCostPlusBudgetType
      ? [
          {
            Header: "Published Budget (pending)",
            accessor: "publishedBudgetPending",
            Cell: ({ value }) => <CurrencyView value={value} />,
            Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
            sortType: (a, b) => {
              const aVal = formatNumber(a.values.publishedBudgetPending);
              const bVal = formatNumber(b.values.publishedBudgetPending);
              return aVal - bVal;
            },

            width: 250,
          },
        ]
      : []),
    {
      Header: "CO Total (pending)",
      accessor: "coTotalPending",
      Cell: ({ row: { original } }) => {
        return editMode ? (
          <BudgetLineInput
            itemKey="coTotalPending"
            lineItem={original}
            dispatch={dispatch}
            maxValue={original.uncommittedValuePending}
            minValue={getMinValue(original.uncommittedValuePending)}
          />
        ) : (
          <CurrencyView value={original.coTotalPending} />
        );
      },
      sortType: (a, b) => {
        const aVal = formatNumber(a.coTotalPending);
        const bVal = formatNumber(b.coTotalPending);
        return aVal - bVal;
      },
      Footer: ({ rows }) => {
        const total = React.useMemo(
          () =>
            rows.reduce((sum, { original }) => {
              if (selected.includes(original.id)) {
                return sum + formatNumber(original.coTotalPending);
              }
              return sum;
            }, 0),
          [rows]
        );

        return <CurrencyView value={total} budget />;
      },
      minWidth: 215,
      isPrimary: true,
    },
  ];

  return columns;
};

const getCaAdjustmentsColumns = (
  dispatch,
  budget,
  selected,
  editMode,
  contingencyLineItem
) => {
  const { budgetType, isLockGmp } = budget ?? {};
  const costPlusLocked = budgetType === BUDGET_COST_PLUS && isLockGmp;
  const isCostPlusBudgetType = budgetType === BUDGET_COST_PLUS;

  const columns = [
    {
      Header: "CSI Code",
      accessor: "csiCode",
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }) => <div className="truncate">{value}</div>,
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Vendor",
      accessor: "vendorName",
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Uncommitted Value",
      accessor: "uncommittedValue",
      Cell: ({ value }) => <CurrencyView value={value} budget />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.uncommittedValue);
        const bVal = formatNumber(b.values.uncommittedValue);
        return aVal - bVal;
      },
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Uncommitted Value (pending)",
      accessor: "uncommittedValuePending",
      Cell: ({ value }) => <CurrencyView value={value} budget />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.uncommittedValuePending);
        const bVal = formatNumber(b.values.uncommittedValuePending);
        return aVal - bVal;
      },
      minWidth: 280,
      isPrimary: true,
    },
    {
      Header: "Published Budget",
      accessor: "publishedBudget",
      Cell: ({ value }) => <CurrencyView value={value} />,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.publishedBudget);
        const bVal = formatNumber(b.values.publishedBudget);
        return aVal - bVal;
      },
      width: 180,
    },
    ...(isCostPlusBudgetType
      ? [
          {
            Header: "Published Budget (pending)",
            accessor: "publishedBudgetPending",
            Cell: ({ value }) => <CurrencyView value={value} />,
            Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
            sortType: (a, b) => {
              const aVal = formatNumber(a.values.publishedBudgetPending);
              const bVal = formatNumber(b.values.publishedBudgetPending);
              return aVal - bVal;
            },

            width: 250,
          },
        ]
      : []),
    {
      Header: "CA Amount",
      accessor: `${docLineItemAdjustmentKeys[CONTINGENCY_AUTHORIZATION]}`,
      Cell: ({ row, rows }) => {
        const key = `${docLineItemAdjustmentKeys[CONTINGENCY_AUTHORIZATION]}`;
        return editMode ? (
          <BudgetLineInput
            itemKey={key}
            lineItem={row.original}
            dispatch={dispatch}
            maxValue={getMaxValue(
              costPlusLocked,
              contingencyLineItem?.uncommittedValuePending,
              rows,
              key,
              row.original?.id
            )}
            minValue={getMinValue(row.original.uncommittedValuePending)}
          />
        ) : (
          <CurrencyView value={row.original[key]} />
        );
      },
      sortType: (a, b) => {
        const key = `${docLineItemAdjustmentKeys[CONTINGENCY_AUTHORIZATION]}`;
        const aVal = formatNumber(a.values[key]);
        const bVal = formatNumber(b.values[key]);
        return aVal - bVal;
      },
      Footer: ({ rows }) => {
        const key = `${docLineItemAdjustmentKeys[CONTINGENCY_AUTHORIZATION]}`;
        const total = React.useMemo(
          () =>
            rows.reduce((sum, { original }) => {
              if (selected.includes(original.id)) {
                return sum + formatNumber(original[key]);
              }
              return sum;
            }, 0),
          [rows, key]
        );

        return <CurrencyView value={total} budget />;
      },
      minWidth: 215,
      isPrimary: true,
    },
  ];

  return columns;
};

const getPaAdjustmentsColumns = (
  document,
  dispatch,
  budget,
  selected,
  editMode,
  filterColumns = []
) => {
  const editable = true;
  const { budgetType, isLockGmp } = budget ?? {};
  const costPlusLocked = budgetType === BUDGET_COST_PLUS && isLockGmp;
  const isCostPlusBudgetType = budgetType === BUDGET_COST_PLUS;

  const columns = [
    {
      Header: "CSI Code",
      accessor: "csiCode",
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }) => <div className="truncate">{value}</div>,
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Vendor",
      accessor: "vendorName",
      Cell: ({ value }) => <div className="truncate">{value}</div>,
      minWidth: 150,
      isPrimary: true,
    },
    {
      Header: "Published Budget",
      accessor: "publishedBudget",
      Cell: ({ value }) => <CurrencyView value={value} />,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.publishedBudget);
        const bVal = formatNumber(b.values.publishedBudget);
        return aVal - bVal;
      },
      width: 180,
    },
    ...(isCostPlusBudgetType
      ? [
          {
            Header: "Published Budget (pending)",
            accessor: "publishedBudgetPending",
            Cell: ({ value }) => <CurrencyView value={value} />,
            Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
            sortType: (a, b) => {
              const aVal = formatNumber(a.values.publishedBudgetPending);
              const bVal = formatNumber(b.values.publishedBudgetPending);
              return aVal - bVal;
            },

            width: 250,
          },
        ]
      : []),
    {
      Header: "Uncommitted Value",
      accessor: "uncommittedValue",
      Cell: ({ value }) => <CurrencyView value={value} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.uncommittedValue);
        const bVal = formatNumber(b.values.uncommittedValue);
        return aVal - bVal;
      },
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Uncommitted Value (pending)",
      accessor: "uncommittedValuePending",
      Cell: ({ value }) => <CurrencyView value={value} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.uncommittedValuePending);
        const bVal = formatNumber(b.values.uncommittedValuePending);
        return aVal - bVal;
      },
      minWidth: 280,
      isPrimary: true,
    },
    {
      Header: "PA Amount",
      accessor: "paAmount",
      Cell: ({ row: { original } }) => {
        return editMode && editable ? (
          <BudgetLineInput
            itemKey="paAmount"
            lineItem={original}
            dispatch={dispatch}
            maxValue={original.uncommittedValuePending}
            disableNegative
            disableZero
          />
        ) : (
          <CurrencyView value={original.paAmount} />
        );
      },
      sortType: (a, b) => {
        const aVal = formatNumber(a.paAmount);
        const bVal = formatNumber(b.paAmount);
        return aVal - bVal;
      },
      Footer: ({ rows }) => {
        const total = React.useMemo(
          () =>
            rows.reduce((sum, { original }) => {
              if (selected.includes(original.id)) {
                return sum + formatNumber(original.paAmount);
              }
              return sum;
            }, 0),
          [rows]
        );

        return <CurrencyView value={total} budget />;
      },
      minWidth: 215,
      isPrimary: true,
    },
  ];

  if (costPlusLocked) {
    columns.push(
      {
        Header: "Adjustment",
        accessor: `${docLineItemAdjustmentKeys[PURCHASE_AUTHORIZATION]}`,
        Cell: ({ row: { original } }) => {
          const key = `${docLineItemAdjustmentKeys[PURCHASE_AUTHORIZATION]}`;
          return editMode && editable ? (
            <BudgetLineInput
              itemKey={key}
              lineItem={original}
              dispatch={dispatch}
              disableNegative
              minValue={0}
            />
          ) : (
            <CurrencyView value={original[key]} />
          );
        },
        sortType: (a, b) => {
          const key = `${docLineItemAdjustmentKeys[PURCHASE_AUTHORIZATION]}`;
          const aVal = formatNumber(a[key]);
          const bVal = formatNumber(b[key]);
          return aVal - bVal;
        },
        Footer: ({ rows }) => {
          const key = `${docLineItemAdjustmentKeys[PURCHASE_AUTHORIZATION]}`;
          const total = React.useMemo(
            () =>
              rows.reduce((sum, { original }) => {
                if (selected.includes(original.id)) {
                  return sum + formatNumber(original[key]);
                }
                return sum;
              }, 0),
            [rows, key]
          );

          return <CurrencyView value={total} budget />;
        },
        width: 260,
        maxWidth: 260,
        isPrimary: true,
      },
      {
        Header: "ASSIGN TO ...",
        accessor: "assigned",
        Cell: ({ row: { original } }) => {
          return (
            <div className="-mt-2 mr-2 pr-12" style={{ width: "280px" }}>
              <AdjustmentSplit
                formData={
                  document.state?.formData?.[PURCHASE_AUTHORIZATION]?.[
                    original.id
                  ]
                }
                lineItemData={document.state?.lineItemData?.[original.id]}
                document={document}
                dispatch={dispatch}
                lineId={original.id}
              />
            </div>
          );
        },
        minWidth: 308,
        maxWidth: 308,
        isPrimary: true,
      },
      {
        Header: "Change Order",
        accessor: "coAssignment",
        Cell: ({ row }) => {
          const formData =
            document.state?.formData?.[PURCHASE_AUTHORIZATION] ?? {};
          const rowData = formData[row.id] ?? {};
          const value = rowData.coAssignment;

          return (
            <div className="flex">
              {!!value && (
                <div
                  className="mr-1 text-gray-200"
                  style={{ marginTop: "10px" }}
                >
                  $
                </div>
              )}
              <div
                className={`flex justify-center items-center w-32 h-10 ${
                  value && "border-4 border-gray-100"
                }`}
              >
                {!!value && (
                  <TertiaryHeader className="font-semibold">
                    {reformatCurrencyString(value)}
                  </TertiaryHeader>
                )}
              </div>
            </div>
          );
        },
        Footer: () => {
          const formData =
            document.state?.formData?.[PURCHASE_AUTHORIZATION] ?? {};
          const rowsData = Object.entries(formData);

          const total = React.useMemo(
            () =>
              rowsData.reduce((sum, [id, data]) => {
                if (selected.includes(id)) {
                  return sum + Number(data.coAssignment || 0);
                }
                return sum;
              }, 0),
            [rowsData]
          );

          return (
            <div className="flex" style={{ marginTop: "-6px" }}>
              {total !== 0 && (
                <div
                  className="mr-1 text-gray-200"
                  style={{ marginTop: "10px" }}
                >
                  $
                </div>
              )}
              <div
                className={`flex justify-center items-center w-32 h-10 ${
                  total !== 0 && "border-4 border-gray-100"
                }`}
              >
                <TertiaryHeader className="font-semibold">
                  {total !== 0 ? reformatCurrencyString(total) : null}
                </TertiaryHeader>
              </div>
            </div>
          );
        },
        minWidth: 160,
        isPrimary: true,
      },
      {
        Header: "Contingency",
        accessor: "caAssignment",
        Cell: ({ row }) => {
          const formData =
            document.state?.formData?.[PURCHASE_AUTHORIZATION] ?? {};
          const rowData = formData[row.id] ?? {};
          const value = rowData.caAssignment;

          return (
            <div className="flex">
              {!!value && (
                <div
                  className="mr-1 text-gray-200"
                  style={{ marginTop: "10px" }}
                >
                  $
                </div>
              )}
              <div
                className={`flex justify-center items-center w-32 h-10 ${
                  value && "border-4 border-gray-100"
                }`}
              >
                {!!value && (
                  <TertiaryHeader className="font-semibold">
                    {reformatCurrencyString(value)}
                  </TertiaryHeader>
                )}
              </div>
            </div>
          );
        },
        Footer: () => {
          const formData =
            document.state?.formData?.[PURCHASE_AUTHORIZATION] ?? {};
          const rowsData = Object.entries(formData);
          const total = React.useMemo(
            () =>
              rowsData.reduce((sum, [id, data]) => {
                if (selected.includes(id)) {
                  return sum + Number(data.caAssignment || 0);
                }
                return sum;
              }, 0),
            [rowsData]
          );

          return (
            <div className="flex" style={{ marginTop: "-6px" }}>
              {total !== 0 && (
                <div
                  className="mr-1 text-gray-200"
                  style={{ marginTop: "10px" }}
                >
                  $
                </div>
              )}
              <div
                className={`flex justify-center items-center w-32 h-10 ${
                  total !== 0 && "border-4 border-gray-100"
                }`}
              >
                <TertiaryHeader className="font-semibold">
                  {total !== 0 ? reformatCurrencyString(total) : null}
                </TertiaryHeader>
              </div>
            </div>
          );
        },
        minWidth: 160,
        isPrimary: true,
      }
    );
  }

  return columns.filter((col) => !filterColumns.includes(col.accessor));
};

const getPoAdjustmentsColumns = (
  dispatch,
  selected,
  editMode,
  filterColumns = [],
  budget,
  parentDocType,
  project,
  history
) => {
  const { budgetType, isLockFixedFirm } = budget ?? {};
  const fixedFirmLocked = budgetType === BUDGET_FIXED_FIRM && isLockFixedFirm;
  const editable = false;

  const columns = [
    {
      Header: "CSI Code",
      accessor: "csiCode",
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }) => <div className="truncate">{value}</div>,
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Rate",
      accessor: "rate",
      width: 180,
      Cell: ({ row }) => {
        return (
          <div className="flex">
            <p>
              {
                project?.rateSheet?.rates?.find(
                  (rate) => rate.id === row?.original?.rate
                )?.category
              }
            </p>
          </div>
        );
      },
    },
    {
      Header: "Vendor",
      accessor: "vendorName",
      minWidth: 200,
      isPrimary: true,
    },
    ...(parentDocType === PURCHASE_AUTHORIZATION
      ? [
          {
            Header: "PA",
            accessor: "purchaseAuthorization",
            Cell: ({ row }) => (
              <BudgetTableDocumentsCell
                row={row}
                setOpenDocument={(ref) => {
                  history?.push(
                    GET_PROJECT_DOCUMENTS_DOC_PATH(
                      project.id,
                      ref.split("/")[1]
                    )
                  );
                }}
                columnId="purchaseAuthorization"
              />
            ),
            disableSortBy: true,
            isPrimary: true,
          },
          {
            Header: "PA Total",
            accessor: "paTotal",
            Cell: ({ row: { original } }) => {
              return editMode && editable ? (
                <BudgetLineInput
                  itemKey="paTotal"
                  lineItem={original}
                  dispatch={dispatch}
                  disableNegative
                  disableZero
                />
              ) : (
                <CurrencyView value={original.paTotal} />
              );
            },
            sortType: (a, b) => {
              const aVal = formatNumber(a.paTotal);
              const bVal = formatNumber(b.paTotal);
              return aVal - bVal;
            },
            Footer: ({ rows }) => {
              const total = React.useMemo(
                () =>
                  rows.reduce((sum, { original }) => {
                    if (selected.includes(original.id)) {
                      return sum + formatNumber(original.paTotal);
                    }
                    return sum;
                  }, 0),
                [rows]
              );

              return <CurrencyView value={total} budget />;
            },
            isPrimary: true,
          },
        ]
      : []),
    ...(parentDocType === CONTINGENCY_AUTHORIZATION
      ? [
          {
            Header: "CA",
            accessor: "contingencyAuthorization",
            Cell: ({ row }) => (
              <BudgetTableDocumentsCell
                row={row}
                setOpenDocument={(ref) => {
                  history?.push(
                    GET_PROJECT_DOCUMENTS_DOC_PATH(
                      project.id,
                      ref.split("/")[1]
                    )
                  );
                }}
                columnId="contingencyAuthorization"
              />
            ),
            disableSortBy: true,
            isPrimary: true,
          },
          {
            Header: "CA Total",
            accessor: "caTotal",
            Cell: ({ row: { original } }) => {
              return editMode && editable ? (
                <BudgetLineInput
                  itemKey="caTotal"
                  lineItem={original}
                  dispatch={dispatch}
                  disableNegative
                  disableZero
                />
              ) : (
                <CurrencyView value={original.caTotal} />
              );
            },
            sortType: (a, b) => {
              const aVal = formatNumber(a.caTotal);
              const bVal = formatNumber(b.caTotal);
              return aVal - bVal;
            },
            Footer: ({ rows }) => {
              const total = React.useMemo(
                () =>
                  rows.reduce((sum, { original }) => {
                    if (selected.includes(original.id)) {
                      return sum + formatNumber(original.caTotal);
                    }
                    return sum;
                  }, 0),
                [rows]
              );

              return <CurrencyView value={total} budget />;
            },
            isPrimary: true,
          },
        ]
      : []),
    ...(parentDocType === CHANGE_ORDER
      ? [
          {
            Header: "CO",
            accessor: "changeOrder",
            Cell: ({ row }) => (
              <BudgetTableDocumentsCell
                row={row}
                setOpenDocument={(ref) => {
                  history?.push(
                    GET_PROJECT_DOCUMENTS_DOC_PATH(
                      project.id,
                      ref.split("/")[1]
                    )
                  );
                }}
                columnId="changeOrder"
              />
            ),
            disableSortBy: true,
            isPrimary: true,
          },
          {
            Header: "CO Total",
            accessor: "coTotal",
            Cell: ({ row: { original } }) => {
              return editMode && editable ? (
                <BudgetLineInput
                  itemKey="coTotal"
                  lineItem={original}
                  dispatch={dispatch}
                  disableNegative
                  disableZero
                />
              ) : (
                <CurrencyView value={original.coTotal} />
              );
            },
            sortType: (a, b) => {
              const aVal = formatNumber(a.coTotal);
              const bVal = formatNumber(b.coTotal);
              return aVal - bVal;
            },
            Footer: ({ rows }) => {
              const total = React.useMemo(
                () =>
                  rows.reduce((sum, { original }) => {
                    if (selected.includes(original.id)) {
                      return sum + formatNumber(original.coTotal);
                    }
                    return sum;
                  }, 0),
                [rows]
              );

              return <CurrencyView value={total} budget />;
            },
            minWidth: 215,
            isPrimary: true,
          },
        ]
      : []),
    ...(!parentDocType
      ? [
          {
            Header: "Uncommitted Value",
            accessor: "uncommittedValue",
            Cell: ({ value }) => <CurrencyView value={value} budget />,
            sortType: (a, b) => {
              const aVal = formatNumber(a.uncommittedValue);
              const bVal = formatNumber(b.uncommittedValue);
              return aVal - bVal;
            },
            Footer: ({ rows }) => {
              const total = React.useMemo(
                () =>
                  rows.reduce((sum, { original }) => {
                    if (selected.includes(original.id)) {
                      return sum + formatNumber(original.uncommittedValue);
                    }
                    return sum;
                  }, 0),
                [rows]
              );

              return <CurrencyView value={total} budget />;
            },
            isPrimary: true,
            width: 280,
          },
          {
            Header: "Uncommitted Value (pending)",
            accessor: "uncommittedValuePending",
            Cell: ({ value }) => <CurrencyView value={value} budget />,
            Footer: ({ rows }) => {
              const total = React.useMemo(
                () =>
                  rows.reduce((sum, { original }) => {
                    return sum + formatNumber(original.uncommittedValuePending);
                  }, 0),
                [rows]
              );
              return <CurrencyView value={total} budget />;
            },
            sortType: (a, b) => {
              const aVal = formatNumber(a.uncommittedValuePending);
              const bVal = formatNumber(b.uncommittedValuePending);
              return aVal - bVal;
            },
            minWidth: 280,
            isPrimary: true,
          },
          {
            Header: "Updated Fixed Firm",
            accessor: "updatedGmpOrFixedFirm",
            Cell: ({ value }) => <CurrencyView value={value} />,
            sortType: (a, b) => {
              const aVal = formatNumber(a.updatedGmpOrFixedFirm);
              const bVal = formatNumber(b.updatedGmpOrFixedFirm);
              return aVal - bVal;
            },
            width: 250,
          },
          {
            Header: "Updated Fixed Firm (pending)",
            accessor: "updatedGmpPending",
            Cell: ({ value }) => <CurrencyView value={value} />,
            sortType: (a, b) => {
              const aVal = formatNumber(a.updatedGmpPending);
              const bVal = formatNumber(b.updatedGmpPending);
              return aVal - bVal;
            },
            width: 250,
          },
        ]
      : []),

    {
      Header: "Total",
      accessor: "total",
      Cell: ({ row }) =>
        React.useMemo(() => {
          const { original } = row;
          let adjustedTotal = 0;
          if (fixedFirmLocked) {
            adjustedTotal = formatNumber(original.poTotalPending);
          } else {
            switch (parentDocType) {
              case PURCHASE_AUTHORIZATION:
                adjustedTotal = formatNumber(original.paTotal);
                break;
              case CONTINGENCY_AUTHORIZATION:
                adjustedTotal = formatNumber(original.caTotal);
                break;
              case CHANGE_ORDER:
                adjustedTotal = formatNumber(original.coTotal);
                break;
              default:
                adjustedTotal =
                  formatNumber(original.paTotal) +
                  formatNumber(original.caTotal) +
                  formatNumber(original.coTotal);
            }
          }
          return <CurrencyView value={adjustedTotal} />;
        }, [row]),
      Footer: ({ rows }) => {
        const total = React.useMemo(
          () =>
            rows.reduce(
              (
                sum,
                { original: { paTotal, caTotal, coTotal, poTotalPending } }
              ) => {
                let adjustedTotal = 0;
                if (fixedFirmLocked) {
                  adjustedTotal = formatNumber(poTotalPending);
                } else {
                  switch (parentDocType) {
                    case PURCHASE_AUTHORIZATION:
                      adjustedTotal = formatNumber(paTotal);
                      break;
                    case CONTINGENCY_AUTHORIZATION:
                      adjustedTotal = formatNumber(caTotal);
                      break;
                    case CHANGE_ORDER:
                      adjustedTotal = formatNumber(coTotal);
                      break;
                    default:
                      adjustedTotal =
                        formatNumber(paTotal) +
                        formatNumber(caTotal) +
                        formatNumber(coTotal);
                  }
                }
                return sum + adjustedTotal;
              },
              0
            ),
          [rows]
        );
        return <CurrencyView value={total} budget />;
      },
      sortType: (a, b) => {
        let aVal = 0;
        let bVal = 0;
        if (fixedFirmLocked) {
          aVal = formatNumber(a.values.uncommittedValue);
          bVal = formatNumber(b.values.uncommittedValue);
        } else {
          switch (parentDocType) {
            case PURCHASE_AUTHORIZATION:
              aVal = formatNumber(a.values.paTotal);
              bVal = formatNumber(b.values.paTotal);
              break;
            case CONTINGENCY_AUTHORIZATION:
              aVal = formatNumber(a.values.caTotal);
              bVal = formatNumber(b.values.caTotal);
              break;
            case CHANGE_ORDER:
              aVal = formatNumber(a.values.coTotal);
              bVal = formatNumber(b.values.coTotal);
              break;
            default:
              aVal =
                formatNumber(a.values.paTotal) +
                formatNumber(a.values.caTotal) +
                formatNumber(a.values.coTotal);
              bVal =
                formatNumber(b.values.paTotal) +
                formatNumber(b.values.caTotal) +
                formatNumber(b.values.coTotal);
          }
        }
        return aVal - bVal;
      },
      isPrimary: true,
    },
  ];

  return columns.filter((col) => !filterColumns.includes(col.accessor));
};

const getVendorInvoiceAdjustmentsColumns = (
  setSelectedLineItemForComments,
  project,
  history
) => {
  const columns = [
    {
      Header: "",
      accessor: "comments",
      editColumnModalHeader: "Comments",
      Cell: ({ row }) => (
        <div className="flex justify-center">
          <IconButton
            icon={commentIcon}
            onClick={() => {
              setSelectedLineItemForComments(row?.original?.reference);
            }}
          />
        </div>
      ),
      disableSortBy: true,
      width: TABLE_COLUMN_WIDTH,
      minWidth: 70,
      maxWidth: 70,
      isPrimary: true,
    },
    {
      Header: "PO",
      accessor: "parentPurchaseOrder",
      minWidth: 200,
      isPrimary: true,
      Cell: ({ row }) => (
        <BudgetTableDocumentsCell
          row={row}
          setOpenDocument={(ref) => {
            history.push(
              GET_PROJECT_DOCUMENTS_DOC_PATH(project.id, ref.split("/")[1])
            );
          }}
          columnId="parentPurchaseOrder"
        />
      ),
    },
    {
      Header: "CSI Code",
      accessor: "csiCode",
      minWidth: 400,
      isPrimary: true,
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }) => <div className="truncate">{value}</div>,
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Committed Values",
      accessor: "committedValues",
      width: 180,
      Cell: ({ value }) => <CurrencyView value={value} />,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.committedValues);
        const bVal = formatNumber(b.values.committedValues);
        return aVal - bVal;
      },
    },
    {
      Header: "Work Completed Previous Period",
      accessor: "workCompletedPreviousPeriod",
      minWidth: 250,
      isPrimary: true,
      Cell: ({ value }) => <CurrencyView value={value} />,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.workCompletedPreviousPeriod);
        const bVal = formatNumber(b.values.workCompletedPreviousPeriod);
        return aVal - bVal;
      },
    },
    {
      Header: "Work Completed This Period",
      accessor: "workCompletedThisPeriod",
      minWidth: 220,
      isPrimary: true,
      Cell: ({ value }) => <CurrencyView value={value} />,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.workCompletedThisPeriod);
        const bVal = formatNumber(b.values.workCompletedThisPeriod);
        return aVal - bVal;
      },
    },
    {
      Header: "Work Completed To Date",
      accessor: "workCompletedToDate",
      minWidth: 200,
      isPrimary: true,
      Cell: ({ value }) => <CurrencyView value={value} />,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.workCompletedToDate);
        const bVal = formatNumber(b.values.workCompletedToDate);
        return aVal - bVal;
      },
    },
    {
      Header: "% Complete of committed values",
      accessor: "percentCompleteOfCommittedValues",
      Cell: ({ value }) => <CircularProgressBar value={value} hideIcons />,
      minWidth: 250,
      isPrimary: true,
      Footer: () => null,
    },
    {
      Header: "Retainage",
      accessor: "retainage",
      isPrimary: true,
      Cell: ({ value }) =>
        value !== formatNumber(-1) ? <CurrencyView value={value} /> : "N/A",
      Footer: () => null,
    },
    {
      Header: "Holdback",
      accessor: "holdback",
      isPrimary: true,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.holdback);
        const bVal = formatNumber(b.values.holdback);
        return aVal - bVal;
      },
      Cell: ({ value }) => <CurrencyView value={value} />,
    },
    {
      Header: "Work Completed to Date Less Retainage and Holdback",
      accessor: "workCompletedToDateLessRetainageAndHoldback",
      minWidth: 400,
      isPrimary: true,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(
          a.values.workCompletedToDateLessRetainageAndHoldback
        );
        const bVal = formatNumber(
          b.values.workCompletedToDateLessRetainageAndHoldback
        );
        return aVal - bVal;
      },
      Cell: ({ value }) => <CurrencyView value={value} />,
    },
    {
      Header: "Previous payment due",
      accessor: "previousPaymentDue",
      minWidth: 200,
      isPrimary: true,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.previousPaymentDue);
        const bVal = formatNumber(b.values.previousPaymentDue);
        return aVal - bVal;
      },
      Cell: ({ value }) => <CurrencyView value={value} />,
    },
    {
      Header: "Current Amount Due",
      accessor: "currentPaymentDue",
      minWidth: 200,
      isPrimary: true,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.currentPaymentDue);
        const bVal = formatNumber(b.values.currentPaymentDue);
        return aVal - bVal;
      },
      Cell: ({ value }) => <CurrencyView value={value} />,
    },
    {
      Header: "Balance to Finish including Holdback and Retainage",
      accessor: "balanceToFinish",
      isPrimary: true,
      minWidth: 350,
      Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
      sortType: (a, b) => {
        const aVal = formatNumber(a.values.balanceToFinish);
        const bVal = formatNumber(b.values.balanceToFinish);
        return aVal - bVal;
      },
      Cell: ({ value }) => <CurrencyView value={value} />,
    },
  ];

  return columns;
};

export const popoverTableColumns = (unitsOfMeasure = []) => [
  {
    Header: "CSI Code",
    accessor: "csiCode",
    Cell: ({ value }) => {
      return value;
    },
    minWidth: 200,
    isPrimary: true,
  },
  {
    Header: "Description",
    accessor: "description",
    Cell: ({ value }) => <div className="truncate">{value}</div>,
    minWidth: 200,
    isPrimary: true,
  },
  { Header: "Location", accessor: "location", isPrimary: true },
  {
    Header: "Vendor",
    accessor: "vendorName",
    Cell: ({ value }) => <div className="truncate">{value}</div>,
    minWidth: 150,
    isPrimary: true,
  },
  { Header: "QTY", accessor: "quantity", isPrimary: true },
  {
    Header: "Unit Of Measure",
    accessor: "unitOfMeasure",
    Cell: ({ row }) => {
      return (
        <div className="flex">
          <p>
            {
              unitsOfMeasure?.find(
                (unit) => unit.value === row?.original?.unitOfMeasure
              )?.label
            }
          </p>
        </div>
      );
    },
    minWidth: 150,
    isPrimary: true,
  },
  {
    Header: "Uncommitted Budget",
    accessor: "uncommittedValue",
    Cell: ({ value }) => <CurrencyView value={value} />,
    sortType: (a, b) => {
      const aVal = formatNumber(a.values.uncommittedValue);
      const bVal = formatNumber(b.values.uncommittedValue);
      return aVal - bVal;
    },
    isPrimary: true,
  },
  {
    Header: "Cost Per",
    accessor: "costPerUnit",
    Cell: ({ value }) => <CurrencyView value={value} />,
    sortType: (a, b) => {
      const aVal = formatNumber(a.values.costPerUnit);
      const bVal = formatNumber(b.values.costPerUnit);
      return aVal - bVal;
    },
    isPrimary: true,
  },
  {
    Header: "Adjustment",
    accessor: "adjustment",
    Cell: ({ value }) => (
      <CurrencyView
        value={value?.amount}
        isPercentage={value?.adjustmentUnit === "%"}
      />
    ),
    isPrimary: true,
  },
  {
    Header: "Contingency $",
    accessor: "contingencyAuthorization",
    minWidth: 180,
    Cell: ({ value }) => <CurrencyView value={value} />,
    isPrimary: true,
  },
  {
    Header: "Change Order $",
    accessor: "changeOrder",
    minWidth: 180,
    Cell: ({ value }) => <CurrencyView value={value} />,
    isPrimary: true,
  },
  {
    Header: "Live Budget",
    accessor: "liveBudget",
    Cell: ({
      row: {
        original: { costPerUnit, quantity, adjustment },
      },
    }) =>
      React.useMemo(() => {
        const adjustedTotal = computeAdjustedTotal(
          costPerUnit * (quantity || 0),
          adjustment?.arithmeticUnit,
          adjustment?.adjustmentUnit,
          adjustment?.amount
        );
        return <CurrencyView value={adjustedTotal} />;
      }, [costPerUnit, quantity, adjustment]),
    isPrimary: true,
  },
];

export const contingencyColumns = (document = {}, budget) => {
  const { docType } = document;
  const { budgetType } = budget ?? {};
  const isCostPlusBudgetType = budgetType === BUDGET_COST_PLUS;

  const docColumns = {
    contingencyAuthorization: [
      "csiCode",
      "vendorName",
      "description",
      "uncommittedValue",
      "uncommittedValuePending",
      "publishedBudget",
      "publishedBudgetPending",
    ],
  };

  const adjColumns = [
    {
      Header: "CSI Code",
      accessor: "csiCode",
      Cell: ({ value }) => value,
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }) => <div className="truncate">{value}</div>,
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Vendor",
      accessor: "vendorName",
      minWidth: 200,
      isPrimary: true,
    },
    {
      Header: "Uncommitted Value",
      accessor: "uncommittedValue",
      Cell: ({ value }) => <CurrencyView value={value} budget />,
      isPrimary: true,
    },
    {
      Header: "Uncommitted Value (pending)",
      accessor: "uncommittedValuePending",
      Cell: ({ value }) => <CurrencyView value={value} budget />,
      isPrimary: true,
      width: 280,
    },
    {
      Header: "Published Budget",
      accessor: "publishedBudget",
      Cell: ({ value }) => <CurrencyView value={value} />,
      width: 180,
    },
    ...(isCostPlusBudgetType
      ? [
          {
            Header: "Published Budget (pending)",
            accessor: "publishedBudgetPending",
            Cell: ({ value }) => <CurrencyView value={value} />,
            width: 250,
          },
        ]
      : []),
    {
      Header: "Adjustment",
      accessor: "adjustmentAmount",
      Cell: ({ value }) => <CurrencyView value={value} budget />,
      isPrimary: true,
    },
    {
      Header: "Net Uncommitted",
      accessor: "netUncommitted",
      Cell: ({ row: { original } }) => (
        <CurrencyView
          value={
            formatNumber(original.uncommittedValue) -
            formatNumber(original.adjustmentAmount)
          }
          budget
        />
      ),
      hidden: true,
      isPrimary: true,
    },
  ];

  return adjColumns.filter((item) =>
    docColumns[docType]?.includes(item.accessor)
  );
};

export const budgetReferencesColumns = (
  document = {},
  budget,
  dispatch,
  selected = [],
  editMode = true,
  unitsOfMeasure = [],
  parentDocType,
  project
) => {
  const { docType } = document;
  const functionMap = {
    [REQUEST_FOR_PROPOSAL]: () => getRfpReferencesColumns(unitsOfMeasure),
    [CHANGE_ORDER]: () => getCoReferencesColumns(budget),
    [CONTINGENCY_AUTHORIZATION]: () => getCaReferencesColumns(budget),
    [PURCHASE_AUTHORIZATION]: () =>
      getPaReferencesColumns(dispatch, budget, selected, editMode),
    [PURCHASE_ORDER]: () =>
      getPoReferencesColumns(
        budget,
        parentDocType,
        project,
        dispatch,
        editMode
      ),
  };

  return functionMap[docType]?.() ?? [];
};

export const budgetAdjustmentsColumns = (
  document = {},
  budget,
  dispatch,
  selected = [],
  editMode = true,
  filterColumns,
  contingencyLineItem,
  parentDocType,
  project,
  setSelectedLineItemForComments,
  history
) => {
  const { docType } = document;
  const functionMap = {
    [REQUEST_FOR_PROPOSAL]: () => getRfpAdjustmentsColumns(),
    [CHANGE_ORDER]: () =>
      getCoAdjustmentsColumns(dispatch, selected, editMode, budget),
    [CONTINGENCY_AUTHORIZATION]: () =>
      getCaAdjustmentsColumns(
        dispatch,
        budget,
        selected,
        editMode,
        contingencyLineItem
      ),
    [PURCHASE_AUTHORIZATION]: () =>
      getPaAdjustmentsColumns(
        document,
        dispatch,
        budget,
        selected,
        editMode,
        filterColumns
      ),
    [PURCHASE_ORDER]: () =>
      getPoAdjustmentsColumns(
        dispatch,
        selected,
        editMode,
        filterColumns,
        budget,
        parentDocType,
        project,
        history
      ),
    [VENDOR_INVOICE]: () =>
      getVendorInvoiceAdjustmentsColumns(
        setSelectedLineItemForComments,
        project,
        history
      ),
  };

  return functionMap[docType]?.() ?? [];
};

export const getVendorInvoiceColumns = (
  showDetailed,
  budgetType,
  isLockGmp
) => {
  const costPlusLocked = budgetType === BUDGET_COST_PLUS && isLockGmp;
  const isCostPlusBudgetType = budgetType === BUDGET_COST_PLUS;
  const isFixedFirmBudgetType = budgetType === BUDGET_FIXED_FIRM;
  const isTimeAndMaterialsBudgetType = budgetType === BUDGET_TIME_AND_MATERIALS;

  let columns = [];
  if (costPlusLocked) {
    columns = [
      {
        Header: "CSI Code",
        accessor: "csiCode",
        minWidth: 400,
        isPrimary: true,
      },
      ...(showDetailed
        ? [
            {
              Header: "Vendor",
              accessor: "vendorName",
              isPrimary: true,
            },
            {
              Header: "Description",
              accessor: "description",
              Cell: ({ row }) => <TableDescriptionCell row={row} />,
              Aggregated: () => null,
            },
          ]
        : []),

      ...(!showDetailed
        ? [
            {
              Header: "Original GMP",
              accessor: "publishedBudget",
              Cell: ({ value }) => <CurrencyView value={value} />,
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.publishedBudget);
                const bVal = formatNumber(b.values.publishedBudget);
                return aVal - bVal;
              },
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(
                  row,
                  "publishedBudget"
                );
                return <CurrencyView value={totalAdjusted} />;
              },
              width: 180,
            },
            {
              Header: "CO Total Approved",
              accessor: "coTotal",
              Cell: ({ value }) => <CurrencyView value={value} />,
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.coTotal);
                const bVal = formatNumber(b.values.coTotal);
                return aVal - bVal;
              },
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(row, "coTotal");
                return <CurrencyView value={totalAdjusted} />;
              },
            },
            {
              Header: "CA Total Approved",
              accessor: "caTotal",
              Cell: ({ value }) => <CurrencyView value={value} />,
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.caTotal);
                const bVal = formatNumber(b.values.caTotal);
                return aVal - bVal;
              },
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(row, "caTotal");
                return <CurrencyView value={totalAdjusted} />;
              },
            },
          ]
        : []),

      {
        Header: "Updated GMP",
        accessor: "updatedGmpOrFixedFirm",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.updatedGmpOrFixedFirm);
          const bVal = formatNumber(b.values.updatedGmpOrFixedFirm);
          return aVal - bVal;
        },
        Aggregated: ({ row }) => {
          const totalAdjusted = getAggregatedTotal(
            row,
            "updatedGmpOrFixedFirm"
          );
          return <CurrencyView value={totalAdjusted} />;
        },
        width: 250,
      },
      {
        Header: "Committed to Date",
        accessor: "committedValues",
        minWidth: 400,
        isPrimary: true,
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.committedValues);
          const bVal = formatNumber(b.values.committedValues);
          return aVal - bVal;
        },
      },
      ...(!showDetailed
        ? [
            {
              Header: "% Complete of Committed Value",
              accessor: "percentCompleteOfCommittedValue",
              Footer: () => null,
              Cell: ({ value }) => (
                <CircularProgressBar value={value} hideIcons />
              ),
              Aggregated: () => null,
              width: 280,
            },
          ]
        : []),
      {
        Header: "Work Completed Previous Period",
        accessor: "workCompletedPreviousPeriod",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.workCompletedPreviousPeriod);
          const bVal = formatNumber(b.values.workCompletedPreviousPeriod);
          return aVal - bVal;
        },
        Aggregated: ({ row }) => {
          const totalAdjusted = getAggregatedTotal(
            row,
            "workCompletedPreviousPeriod"
          );
          return <CurrencyView value={totalAdjusted} />;
        },
        width: 280,
      },
      {
        Header: "Work Completed This Period",
        accessor: "workCompletedThisPeriod",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.workCompletedThisPeriod);
          const bVal = formatNumber(b.values.workCompletedThisPeriod);
          return aVal - bVal;
        },
        Aggregated: ({ row }) => {
          const totalAdjusted = getAggregatedTotal(
            row,
            "workCompletedThisPeriod"
          );
          return <CurrencyView value={totalAdjusted} />;
        },
        width: 280,
      },
      ...(showDetailed
        ? [
            {
              Header: "Non-Billable",
              accessor: "isBillable",
              disableSortBy: true,
              Cell: ({ value }) => (
                <BooleanBullet isTrue={!value} className="ml-5" />
              ),
            },
          ]
        : []),
      {
        Header: "Work Completed to Date",
        accessor: "workCompletedToDate",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.workCompletedToDate);
          const bVal = formatNumber(b.values.workCompletedToDate);
          return aVal - bVal;
        },
        Aggregated: ({ row }) => {
          const totalAdjusted = getAggregatedTotal(row, "workCompletedToDate");
          return <CurrencyView value={totalAdjusted} />;
        },
        width: 220,
      },
      ...(!showDetailed
        ? [
            {
              Header: "% Complete of Budget",
              accessor: "percentCompleteOfBudget",
              Footer: () => null,
              Cell: ({ value }) => <CircularProgressBar value={value} />,
              Aggregated: () => null,
              width: 220,
            },
          ]
        : []),

      ...(showDetailed
        ? [
            {
              Header: "% Complete of Committed Value",
              accessor: "percentCompleteOfCommittedValue",
              Footer: () => null,
              Cell: ({ value }) => (
                <CircularProgressBar value={value} hideIcons />
              ),
              Aggregated: () => null,
              width: 280,
            },
          ]
        : []),
      {
        Header: "Retainage",
        accessor: "retainage",
        isPrimary: true,
        Cell: ({ value }) =>
          typeof value === "number" ? <CurrencyView value={value} /> : "N/A",
        Footer: () => null,
      },

      ...(showDetailed
        ? [
            {
              Header: "Retainage %",
              accessor: "retainagePercentage",
              Footer: () => null,
              Cell: ({ value }) =>
                value !== "-1.00" ? (
                  <CircularProgressBar value={value} hideIcons />
                ) : (
                  "N/A"
                ),
              aggregate: "sum",
              Aggregated: () => null,
            },
            {
              Header: "Retainage Released this Period",
              accessor: "retainedReleasedThisPeriod",
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.retainedReleasedThisPeriod);
                const bVal = formatNumber(b.values.retainedReleasedThisPeriod);
                return aVal - bVal;
              },
              Cell: ({ value }) => <CurrencyView value={value} />,
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(
                  row,
                  "retainedReleasedThisPeriod"
                );
                return <CurrencyView value={totalAdjusted} />;
              },
              width: 300,
            },
          ]
        : []),
      {
        Header: "Holdback",
        accessor: "holdback",
        isPrimary: true,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.holdback);
          const bVal = formatNumber(b.values.holdback);
          return aVal - bVal;
        },
        Cell: ({ value }) => <CurrencyView value={value} />,
      },
      // TODO: add "Holdback released this Period"
      {
        Header: "Work Completed to Date Less Retainage and Holdback",
        accessor: "workCompletedToDateLessRetainageAndHoldback",
        minWidth: 400,
        isPrimary: true,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(
            a.values.workCompletedToDateLessRetainageAndHoldback
          );
          const bVal = formatNumber(
            b.values.workCompletedToDateLessRetainageAndHoldback
          );
          return aVal - bVal;
        },
        Cell: ({ value }) => <CurrencyView value={value} />,
      },

      // TODO: add "Payment Requested Previous Period"
      {
        Header: "Payment Requested This Period",
        accessor: "paymentRequestedThisPeriod",
        minWidth: 200,
        isPrimary: true,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.previousPaymentDue);
          const bVal = formatNumber(b.values.previousPaymentDue);
          return aVal - bVal;
        },
        Cell: ({ value }) => <CurrencyView value={value} />,
      },
      {
        Header: "Balance to Finish including Holdback and Retainage",
        accessor: "balanceToFinish",
        isPrimary: true,
        minWidth: 350,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.balanceToFinish);
          const bVal = formatNumber(b.values.balanceToFinish);
          return aVal - bVal;
        },
        Cell: ({ value }) => <CurrencyView value={value} />,
      },
      {
        Header: "Uncommitted To Date",
        accessor: "uncommittedValue",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.uncommittedValue);
          const bVal = formatNumber(b.values.uncommittedValue);
          return aVal - bVal;
        },
        Aggregated: ({ row }) => {
          const totalAdjusted = getAggregatedTotal(row, "uncommittedValue");
          return <CurrencyView value={totalAdjusted} />;
        },
        width: 200,
      },
    ];
  } else if (isCostPlusBudgetType) {
    columns = [
      {
        Header: "CSI Code",
        accessor: "csiCode",
        minWidth: 400,
        isPrimary: true,
      },
      ...(showDetailed
        ? [
            {
              Header: "Vendor",
              accessor: "vendorName",
              isPrimary: true,
            },
            {
              Header: "Description",
              accessor: "description",
              Cell: ({ row }) => <TableDescriptionCell row={row} />,
              Aggregated: () => null,
            },
          ]
        : []),

      ...(!showDetailed
        ? [
            {
              Header: "Control Estimate",
              accessor: "publishedBudget",
              Cell: ({ value }) => <CurrencyView value={value} />,
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.publishedBudget);
                const bVal = formatNumber(b.values.publishedBudget);
                return aVal - bVal;
              },
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(
                  row,
                  "publishedBudget"
                );
                return <CurrencyView value={totalAdjusted} />;
              },
              width: 180,
            },
          ]
        : []),

      {
        Header: "Committed to Date",
        accessor: "committedValues",
        minWidth: 400,
        isPrimary: true,
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.committedValues);
          const bVal = formatNumber(b.values.committedValues);
          return aVal - bVal;
        },
      },
      ...(!showDetailed
        ? [
            {
              Header: "% Complete of Committed Value",
              accessor: "percentCompleteOfCommittedValue",
              Footer: () => null,
              Cell: ({ value }) => (
                <CircularProgressBar value={value} hideIcons />
              ),
              Aggregated: () => null,
              width: 280,
            },
          ]
        : []),

      {
        Header: "Work Completed Previous Period",
        accessor: "workCompletedPreviousPeriod",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.workCompletedPreviousPeriod);
          const bVal = formatNumber(b.values.workCompletedPreviousPeriod);
          return aVal - bVal;
        },
        Aggregated: ({ row }) => {
          const totalAdjusted = getAggregatedTotal(
            row,
            "workCompletedPreviousPeriod"
          );
          return <CurrencyView value={totalAdjusted} />;
        },
        width: 280,
      },
      {
        Header: "Work Completed This Period",
        accessor: "workCompletedThisPeriod",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.workCompletedThisPeriod);
          const bVal = formatNumber(b.values.workCompletedThisPeriod);
          return aVal - bVal;
        },
        Aggregated: ({ row }) => {
          const totalAdjusted = getAggregatedTotal(
            row,
            "workCompletedThisPeriod"
          );
          return <CurrencyView value={totalAdjusted} />;
        },
        width: 280,
      },
      ...(showDetailed
        ? [
            {
              Header: "Non-Billable",
              accessor: "isBillable",
              disableSortBy: true,
              Cell: ({ value }) => (
                <BooleanBullet isTrue={!value} className="ml-5" />
              ),
            },
          ]
        : []),
      {
        Header: "Work Completed to Date",
        accessor: "workCompletedToDate",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.workCompletedToDate);
          const bVal = formatNumber(b.values.workCompletedToDate);
          return aVal - bVal;
        },
        Aggregated: ({ row }) => {
          const totalAdjusted = getAggregatedTotal(row, "workCompletedToDate");
          return <CurrencyView value={totalAdjusted} />;
        },
        width: 220,
      },
      ...(!showDetailed
        ? [
            {
              Header: "% Complete of Budget",
              accessor: "percentCompleteOfBudget",
              Footer: () => null,
              Cell: ({ value }) => <CircularProgressBar value={value} />,
              Aggregated: () => null,
              width: 220,
            },
          ]
        : []),

      ...(showDetailed
        ? [
            {
              Header: "% Complete of Committed Value",
              accessor: "percentCompleteOfCommittedValue",
              Footer: () => null,
              Cell: ({ value }) => (
                <CircularProgressBar value={value} hideIcons />
              ),
              Aggregated: () => null,
              width: 280,
            },
          ]
        : []),
      {
        Header: "Retainage",
        accessor: "retainage",
        isPrimary: true,
        Cell: ({ value }) =>
          typeof value === "number" ? <CurrencyView value={value} /> : "N/A",
        Footer: () => null,
      },

      ...(showDetailed
        ? [
            {
              Header: "Retainage %",
              accessor: "retainagePercentage",
              Footer: () => null,
              Cell: ({ value }) =>
                value !== "-1.00" ? (
                  <CircularProgressBar value={value} hideIcons />
                ) : (
                  "N/A"
                ),
              aggregate: "sum",
              Aggregated: () => null,
            },
            {
              Header: "Retainage Released this Period",
              accessor: "retainedReleasedThisPeriod",
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.retainedReleasedThisPeriod);
                const bVal = formatNumber(b.values.retainedReleasedThisPeriod);
                return aVal - bVal;
              },
              Cell: ({ value }) => <CurrencyView value={value} />,
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(
                  row,
                  "retainedReleasedThisPeriod"
                );
                return <CurrencyView value={totalAdjusted} />;
              },
              width: 300,
            },
          ]
        : []),
      {
        Header: "Holdback",
        accessor: "holdback",
        isPrimary: true,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.holdback);
          const bVal = formatNumber(b.values.holdback);
          return aVal - bVal;
        },
        Cell: ({ value }) => <CurrencyView value={value} />,
      },
      // @TODO: add "Holdback released this period"
      {
        Header: "Work Completed to Date Less Retainage and Holdback",
        accessor: "workCompletedToDateLessRetainageAndHoldback",
        minWidth: 400,
        isPrimary: true,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(
            a.values.workCompletedToDateLessRetainageAndHoldback
          );
          const bVal = formatNumber(
            b.values.workCompletedToDateLessRetainageAndHoldback
          );
          return aVal - bVal;
        },
        Cell: ({ value }) => <CurrencyView value={value} />,
      },
      // @TODO: add "Payment Requested Previous Period"
      {
        Header: "Payment Requested This Period",
        accessor: "paymentRequestedThisPeriod",
        minWidth: 200,
        isPrimary: true,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.previousPaymentDue);
          const bVal = formatNumber(b.values.previousPaymentDue);
          return aVal - bVal;
        },
        Cell: ({ value }) => <CurrencyView value={value} />,
      },
      {
        Header: "Balance to Finish including Holdback and Retainage",
        accessor: "balanceToFinish",
        isPrimary: true,
        minWidth: 350,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.balanceToFinish);
          const bVal = formatNumber(b.values.balanceToFinish);
          return aVal - bVal;
        },
        Cell: ({ value }) => <CurrencyView value={value} />,
      },
      {
        Header: "Uncommitted To Date",
        accessor: "uncommittedValue",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.uncommittedValue);
          const bVal = formatNumber(b.values.uncommittedValue);
          return aVal - bVal;
        },
        Aggregated: ({ row }) => {
          const totalAdjusted = getAggregatedTotal(row, "uncommittedValue");
          return <CurrencyView value={totalAdjusted} />;
        },
        width: 200,
      },
    ];
  } else if (isFixedFirmBudgetType) {
    columns = [
      {
        Header: "CSI Code",
        accessor: "csiCode",
        minWidth: 400,
        isPrimary: true,
      },
      ...(showDetailed
        ? [
            {
              Header: "Vendor",
              accessor: "vendorName",
              isPrimary: true,
            },
            {
              Header: "Description",
              accessor: "description",
              Cell: ({ row }) => <TableDescriptionCell row={row} />,
              Aggregated: () => null,
            },
          ]
        : []),

      ...(!showDetailed
        ? [
            {
              Header: "Original Contract Sum",
              accessor: "publishedBudget",
              Cell: ({ value }) => <CurrencyView value={value} />,
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.publishedBudget);
                const bVal = formatNumber(b.values.publishedBudget);
                return aVal - bVal;
              },
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(
                  row,
                  "publishedBudget"
                );
                return <CurrencyView value={totalAdjusted} />;
              },
              width: 180,
            },
            {
              Header: "CO Total Approved",
              accessor: "coTotal",
              Cell: ({ value }) => <CurrencyView value={value} />,
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.coTotal);
                const bVal = formatNumber(b.values.coTotal);
                return aVal - bVal;
              },
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(row, "coTotal");
                return <CurrencyView value={totalAdjusted} />;
              },
            },
            {
              Header: "Updated Contract Sum",
              accessor: "updatedGmpOrFixedFirm",
              Cell: ({ value }) => <CurrencyView value={value} />,
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.updatedGmpOrFixedFirm);
                const bVal = formatNumber(b.values.updatedGmpOrFixedFirm);
                return aVal - bVal;
              },
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(
                  row,
                  "updatedGmpOrFixedFirm"
                );
                return <CurrencyView value={totalAdjusted} />;
              },
              width: 250,
            },
          ]
        : []),

      {
        Header: "Committed to Date",
        accessor: "committedValues",
        minWidth: 400,
        isPrimary: true,
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.committedValues);
          const bVal = formatNumber(b.values.committedValues);
          return aVal - bVal;
        },
      },
      ...(!showDetailed
        ? [
            {
              Header: "% Complete of Committed Value",
              accessor: "percentCompleteOfCommittedValue",
              Footer: () => null,
              Cell: ({ value }) => (
                <CircularProgressBar value={value} hideIcons />
              ),
              Aggregated: () => null,
              width: 280,
            },
          ]
        : []),
      {
        Header: "Work Completed Previous Period",
        accessor: "workCompletedPreviousPeriod",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.workCompletedPreviousPeriod);
          const bVal = formatNumber(b.values.workCompletedPreviousPeriod);
          return aVal - bVal;
        },
        Aggregated: ({ row }) => {
          const totalAdjusted = getAggregatedTotal(
            row,
            "workCompletedPreviousPeriod"
          );
          return <CurrencyView value={totalAdjusted} />;
        },
        width: 280,
      },
      {
        Header: "Work Completed This Period",
        accessor: "workCompletedThisPeriod",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.workCompletedThisPeriod);
          const bVal = formatNumber(b.values.workCompletedThisPeriod);
          return aVal - bVal;
        },
        Aggregated: ({ row }) => {
          const totalAdjusted = getAggregatedTotal(
            row,
            "workCompletedThisPeriod"
          );
          return <CurrencyView value={totalAdjusted} />;
        },
        width: 280,
      },
      ...(showDetailed
        ? [
            {
              Header: "Non-Billable",
              accessor: "isBillable",
              disableSortBy: true,
              Cell: ({ value }) => (
                <BooleanBullet isTrue={!value} className="ml-5" />
              ),
            },
          ]
        : []),
      {
        Header: "Work Completed to Date",
        accessor: "workCompletedToDate",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.workCompletedToDate);
          const bVal = formatNumber(b.values.workCompletedToDate);
          return aVal - bVal;
        },
        Aggregated: ({ row }) => {
          const totalAdjusted = getAggregatedTotal(row, "workCompletedToDate");
          return <CurrencyView value={totalAdjusted} />;
        },
        width: 220,
      },
      ...(!showDetailed
        ? [
            {
              Header: "% Complete of Budget",
              accessor: "percentCompleteOfBudget",
              Footer: () => null,
              Cell: ({ value }) => <CircularProgressBar value={value} />,
              Aggregated: () => null,
              width: 220,
            },
          ]
        : []),

      ...(showDetailed
        ? [
            {
              Header: "% Complete of Committed Value",
              accessor: "percentCompleteOfCommittedValue",
              Footer: () => null,
              Cell: ({ value }) => (
                <CircularProgressBar value={value} hideIcons />
              ),
              Aggregated: () => null,
              width: 280,
            },
          ]
        : []),
      {
        Header: "Retainage",
        accessor: "retainage",
        isPrimary: true,
        Cell: ({ value }) =>
          typeof value === "number" ? <CurrencyView value={value} /> : "N/A",
        Footer: () => null,
      },

      ...(showDetailed
        ? [
            {
              Header: "Retainage %",
              accessor: "retainagePercentage",
              Footer: () => null,
              Cell: ({ value }) =>
                value !== "-1.00" ? (
                  <CircularProgressBar value={value} hideIcons />
                ) : (
                  "N/A"
                ),
              aggregate: "sum",
              Aggregated: () => null,
            },
            {
              Header: "Retainage Released this Period",
              accessor: "retainedReleasedThisPeriod",
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.retainedReleasedThisPeriod);
                const bVal = formatNumber(b.values.retainedReleasedThisPeriod);
                return aVal - bVal;
              },
              Cell: ({ value }) => <CurrencyView value={value} />,
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(
                  row,
                  "retainedReleasedThisPeriod"
                );
                return <CurrencyView value={totalAdjusted} />;
              },
              width: 300,
            },
          ]
        : []),
      {
        Header: "Holdback",
        accessor: "holdback",
        isPrimary: true,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.holdback);
          const bVal = formatNumber(b.values.holdback);
          return aVal - bVal;
        },
        Cell: ({ value }) => <CurrencyView value={value} />,
      },
      // TODO: add "Holdback released this Period"
      {
        Header: "Work Completed to Date Less Retainage and Holdback",
        accessor: "workCompletedToDateLessRetainageAndHoldback",
        minWidth: 400,
        isPrimary: true,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(
            a.values.workCompletedToDateLessRetainageAndHoldback
          );
          const bVal = formatNumber(
            b.values.workCompletedToDateLessRetainageAndHoldback
          );
          return aVal - bVal;
        },
        Cell: ({ value }) => <CurrencyView value={value} />,
      },

      ...(!showDetailed
        ? [
            // TODO: add "Payment Requested Previous Period"
            {
              Header: "Payment Requested This Period",
              accessor: "paymentRequestedThisPeriod",
              minWidth: 200,
              isPrimary: true,
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.previousPaymentDue);
                const bVal = formatNumber(b.values.previousPaymentDue);
                return aVal - bVal;
              },
              Cell: ({ value }) => <CurrencyView value={value} />,
            },
          ]
        : []),
    ];
  } else if (isTimeAndMaterialsBudgetType) {
    columns = [
      {
        Header: "CSI Code",
        accessor: "csiCode",
        minWidth: 400,
        isPrimary: true,
      },
      ...(showDetailed
        ? [
            {
              Header: "PO",
              accessor: "purchaseOrder",
              disableSortBy: true,
              Cell: ({ row }) => (
                <BudgetTableDocumentsCell row={row} columnId="purchaseOrder" />
              ),
              Aggregated: () => null,
              width: TABLE_COLUMN_WIDTH,
            },
            {
              Header: "Vendor",
              accessor: "vendorName",
              isPrimary: true,
            },
            {
              Header: "Description",
              accessor: "description",
              Cell: ({ row }) => <TableDescriptionCell row={row} />,
              Aggregated: () => null,
            },
          ]
        : []),

      ...(!showDetailed
        ? [
            {
              Header: "Control Estimate",
              accessor: "publishedBudget",
              Cell: ({ value }) => <CurrencyView value={value} />,
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.publishedBudget);
                const bVal = formatNumber(b.values.publishedBudget);
                return aVal - bVal;
              },
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(
                  row,
                  "publishedBudget"
                );
                return <CurrencyView value={totalAdjusted} />;
              },
              width: 180,
            },
            {
              Header: "Committed to Date",
              accessor: "committedValues",
              minWidth: 400,
              isPrimary: true,
              Cell: ({ value }) => <CurrencyView value={value} />,
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.committedValues);
                const bVal = formatNumber(b.values.committedValues);
                return aVal - bVal;
              },
            },
            {
              Header: "% Complete of Committed Value",
              accessor: "percentCompleteOfCommittedValue",
              Footer: () => null,
              Cell: ({ value }) => (
                <CircularProgressBar value={value} hideIcons />
              ),
              Aggregated: () => null,
              width: 280,
            },
          ]
        : []),

      ...(!showDetailed ? [] : []),
      {
        Header: "Work Completed Previous Period",
        accessor: "workCompletedPreviousPeriod",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.workCompletedPreviousPeriod);
          const bVal = formatNumber(b.values.workCompletedPreviousPeriod);
          return aVal - bVal;
        },
        Aggregated: ({ row }) => {
          const totalAdjusted = getAggregatedTotal(
            row,
            "workCompletedPreviousPeriod"
          );
          return <CurrencyView value={totalAdjusted} />;
        },
        width: 280,
      },
      {
        Header: "Work Completed This Period",
        accessor: "workCompletedThisPeriod",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.workCompletedThisPeriod);
          const bVal = formatNumber(b.values.workCompletedThisPeriod);
          return aVal - bVal;
        },
        Aggregated: ({ row }) => {
          const totalAdjusted = getAggregatedTotal(
            row,
            "workCompletedThisPeriod"
          );
          return <CurrencyView value={totalAdjusted} />;
        },
        width: 280,
      },
      ...(showDetailed
        ? [
            {
              Header: "Non-Billable",
              accessor: "isBillable",
              disableSortBy: true,
              Cell: ({ value }) => (
                <BooleanBullet isTrue={!value} className="ml-5" />
              ),
            },
          ]
        : []),
      {
        Header: "Work Completed to Date",
        accessor: "workCompletedToDate",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.workCompletedToDate);
          const bVal = formatNumber(b.values.workCompletedToDate);
          return aVal - bVal;
        },
        Aggregated: ({ row }) => {
          const totalAdjusted = getAggregatedTotal(row, "workCompletedToDate");
          return <CurrencyView value={totalAdjusted} />;
        },
        width: 220,
      },
    ];
  }

  return columns;
};

import { UserAPI } from "@griffingroupglobal/eslib-api";
import { useCallback, useEffect, useState } from "react";
import {
  SET_COMPANIES,
  SET_EMPLOYEES,
  SET_USERS,
  SET_USERS_HOOK_STATE,
  SET_USER_DICT,
  USERS_HOOK_STATE,
} from "../constants";
import { useAppState } from "../state/appState";
import { toastError } from "../stories/Components/Toast/Toast";

/**
 * This hook is deprecated. Get users from useUsers.new.js file.
 *
 * ``
 *    const { data } = useUsers();
 *    const users = data?.users ?? []
 *    const userDict = data?.userDict
 * ``
 * @deprecated
 */
export default (showDeleted) => {
  const [isLoading, setIsLoading] = useState();
  const [{ users, userDict: dict, usersHookState }, dispatch] = useAppState();

  const updateUser = useCallback(
    ({ userRef, key, value, payload = {} }) => {
      dispatch({
        type: SET_USERS,
        users: users.map((usr) => {
          if (usr.reference === userRef) {
            return {
              ...usr,
              ...payload,
              [`${key}`]: value,
            };
          }
          return usr;
        }),
      });

      const newDict = { ...dict };
      newDict[userRef] = {
        ...newDict[userRef],
        ...payload,
        [`${key}`]: value,
      };

      dispatch({
        type: SET_USER_DICT,
        userDict: newDict,
      });
    },
    [dict, dispatch, users]
  );

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      dispatch({
        type: SET_USERS_HOOK_STATE,
        value: USERS_HOOK_STATE.calling,
      });
      const { data } = await UserAPI.get({
        params: { showDeleted: showDeleted || undefined },
      });

      const dictionary = {};
      const employees = [];
      const companies = [];
      const allUsers = data.entries.map(({ resource }) => {
        dictionary[resource?.reference] = resource;
        if (resource?.kind === "company") {
          companies.push(resource);
        }
        if (resource?.isEmployee) {
          employees.push(resource);
        }
        return resource;
      });

      dispatch({ type: SET_COMPANIES, companies });
      dispatch({
        type: SET_USER_DICT,
        userDict: dictionary,
      });
      dispatch({
        type: SET_EMPLOYEES,
        employees,
      });
      dispatch({
        type: SET_USERS,
        users: allUsers,
      });
      dispatch({
        type: SET_USERS_HOOK_STATE,
        value: USERS_HOOK_STATE.loaded,
      });
      setIsLoading(false);
    } catch (e) {
      toastError("Error loading users.", true);
      console.error("User Fetch Error:", e);
    }
  }, [dispatch, showDeleted]);

  useEffect(() => {
    if (
      usersHookState !== USERS_HOOK_STATE.loaded &&
      usersHookState !== USERS_HOOK_STATE.calling
    ) {
      reload();
    }
  }, [reload, usersHookState]);

  return [users, reload, isLoading, dict, updateUser];
};

// Import necessary hooks from React and custom hooks
import { useEffect, useState } from "react";
import useURLChange from "./useURLChange";
import { usePropertiesOverview } from "./properties";
import { useProjectsOverview } from "./projects";
import { useAssetsOverview } from "./assets";

const useAssociatedEvents = (events) => {
  // Get the current URL using a custom hook
  const currentUrl = useURLChange();

  // Initialize the state to hold associated events
  const [associationRef, setAssociationRef] = useState("");
  const [association, setAssociation] = useState({});
  const [spaceLock, setSpaceLock] = useState();
  const [timezoneLock, setTimezoneLock] = useState();

  const { assetsDict } = useAssetsOverview();
  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  // ref to manage useEFfect triggers

  useEffect(() => {
    // Get the resource type and ID from the current URL
    const urlSplits = currentUrl.split("/");
    const [resource, resourceId] = urlSplits.slice(
      Math.max(urlSplits.length - 2, 0)
    );
    let resourceRef = "";

    // Determine the reference string based on the resource type
    if (resource.startsWith("space")) {
      // set space
      setSpaceLock(resourceId);
      // set parent as association
      const [parentResource, parentResourceId] = urlSplits.slice(
        Math.max(urlSplits.length - 4, 0),
        Math.max(urlSplits.length - 2, 0)
      );
      if (parentResource.startsWith("project")) {
        resourceRef = `Project/${parentResourceId}`;
        //  get asset from the corresponding dictionary
        const project = projectDict[resourceRef];
        // set the association if the asset exists
        if (project) {
          setAssociation(project);
          setTimezoneLock(project?.timezone);
        }
      } else if (parentResource.startsWith("properties")) {
        resourceRef = `Property/${parentResourceId}`;
        //  get asset from the corresponding dictionary
        const property = propertiesDict[resourceRef];
        // set the association if the asset exists
        if (property) {
          setAssociation(property);
          setTimezoneLock(property?.timezone);
        }
      }
    } else if (resource.startsWith("asset")) {
      resourceRef = `Asset/${resourceId}`;
      //  get asset from the corresponding dictionary
      const asset = assetsDict[resourceRef];
      // set the association if the asset exists
      if (asset) setAssociation(asset);
    } else if (resource.startsWith("project")) {
      resourceRef = `Project/${resourceId}`;
      //  get project from the corresponding dictionary
      const project = projectDict[resourceRef];
      // set the association if the project exists
      if (project) {
        setAssociation(project);
        setTimezoneLock(project?.timezone);
      }
    } else if (resource.startsWith("properties")) {
      resourceRef = `Property/${resourceId}`;
      // get property from the corresponding dictionary
      const property = propertiesDict[resourceRef];
      // set the association if the property exists
      if (property) {
        setAssociation(property);
        setTimezoneLock(property?.timezone);
      }
    }

    // Update the state with the resource reference
    setAssociationRef(resourceRef);
  }, [events, currentUrl, assetsDict, projectDict, propertiesDict]);

  // Return the associated events
  return { associationRef, association, spaceLock, timezoneLock };
};

export default useAssociatedEvents;

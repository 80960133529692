import React, { useCallback } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import RadioButton from "../Buttons/RadioButton";
import CollapsibleSection from "../CollapsibleSection/CollapsibleSection";
import Input from "../Input/Input";
import editIcon from "../../assets/images/editIcon.svg";
import TertiaryButton from "../Buttons/TertiaryButton";
import DatePicker from "../DatePicker/DatePicker";
import Dropdown from "../Dropdown/Dropdown";
import BaseButton from "../Buttons/BaseButton";

const collapsibleContainerCN = cntl`
  mt-3
  px-3
  text-black
  rounded
  border-2
  mb-6
  pb-2
  border-gray-150
`;

const editButtonCN = (className) => cntl`
  ml-2
  w-5
  h-5
  ${className}
`;

const tertiaryButtonCN = cntl`
  text-brandGreen
  py-4
  text-base
`;

const durationOptions = [
  {
    label: "Annual",
    value: "annual",
  },
  {
    label: "6-months",
    value: "6months",
  },
];

const initialLevels = [
  {
    level: "employee",
    display: "Employee",
  },
  {
    level: "employeeSpouse",
    display: "Employee & Spouse",
  },
  {
    level: "employeeChild",
    display: "Employee & Child",
  },
  {
    level: "employeeChildren",
    display: "Employee & Children",
  },
  {
    level: "family",
    display: "Family",
  },
];

const BenefitsSection = ({ systemSettings, dispatchSystemSettings }) => {
  const onChangePolicyName = useCallback(
    (value, benefitIndex, policyIndex) => {
      dispatchSystemSettings({
        type: "changeBenefitCategoryPolicyName",
        benefitIndex,
        policyIndex,
        value,
      });
    },
    [dispatchSystemSettings]
  );

  const toggleEditingPolicyName = useCallback(
    (benefitIndex, policyIndex) => {
      dispatchSystemSettings({
        type: "toggleBenefitCategoryPolicyNameEditing",
        benefitIndex,
        policyIndex,
      });
    },
    [dispatchSystemSettings]
  );

  const togglePolicy = useCallback(
    (benefitIndex, policyIndex) => {
      dispatchSystemSettings({
        type: "toggleBenefitCategoryPolicy",
        benefitIndex,
        policyIndex,
      });
    },
    [dispatchSystemSettings]
  );

  const onAddPolicy = useCallback(
    (benefitIndex) => {
      dispatchSystemSettings({
        type: "addBenefitCategoryPolicy",
        benefitIndex,
        initialLevels,
      });
    },
    [dispatchSystemSettings]
  );

  const onAddBenefit = useCallback(() => {
    dispatchSystemSettings({
      type: "addBenefitCategory",
    });
  }, [dispatchSystemSettings]);

  const onChangeAgeBandingCoverage = useCallback(
    (benefitIndex, policyIndex) => {
      dispatchSystemSettings({
        type: "changeBenefitCategoryPolicyAgeBanding",
        benefitIndex,
        policyIndex,
      });
    },
    [dispatchSystemSettings]
  );

  const toggleEditingBenefit = useCallback(
    (index) => {
      dispatchSystemSettings({
        type: "toggleBenefitCategoryEditing",
        index,
      });
    },
    [dispatchSystemSettings]
  );

  const onChangeBenefitCategory = useCallback(
    (value, index) => {
      dispatchSystemSettings({
        type: "changeBenefitCategory",
        index,
        value,
      });
    },
    [dispatchSystemSettings]
  );

  const onChangeLevelCost = useCallback(
    (value, benefitIndex, policyIndex, levelIndex) => {
      dispatchSystemSettings({
        type: "changeBenefitCategoryPolicyLevelCost",
        benefitIndex,
        policyIndex,
        levelIndex,
        value,
      });
    },
    [dispatchSystemSettings]
  );

  const onChangeLevelCoverage = useCallback(
    (value, benefitIndex, policyIndex, levelIndex) => {
      dispatchSystemSettings({
        type: "changeBenefitCategoryPolicyLevelCoverage",
        benefitIndex,
        policyIndex,
        levelIndex,
        value,
      });
    },
    [dispatchSystemSettings]
  );

  const onChangePolicyDate = useCallback(
    (value, benefitIndex, policyIndex) => {
      dispatchSystemSettings({
        type: "changeBenefitCategoryPolicyDate",
        benefitIndex,
        policyIndex,
        value,
      });
    },
    [dispatchSystemSettings]
  );

  const onChangePolicyDuration = useCallback(
    (newValue, benefitIndex, policyIndex) => {
      dispatchSystemSettings({
        type: "changeBenefitCategoryPolicyDuration",
        benefitIndex,
        policyIndex,
        value: newValue.value,
      });
    },
    [dispatchSystemSettings]
  );

  const onChangePolicyWebsite = useCallback(
    (value, benefitIndex, policyIndex) => {
      dispatchSystemSettings({
        type: "changeBenefitCategoryPolicyWebsite",
        benefitIndex,
        policyIndex,
        value,
      });
    },
    [dispatchSystemSettings]
  );

  return (
    <div className="flex flex-col">
      {systemSettings?.benefits?.map((entityBenefit, bIndex) => {
        return (
          <div className="flex flex-col py-4" key={entityBenefit?.category}>
            <div className="flex flex-row w-1/4">
              {(entityBenefit?.isEditing && (
                <div className="pb-4">
                  <Input
                    value={entityBenefit.category}
                    placeholder="Category"
                    onChange={(val) => onChangeBenefitCategory(val, bIndex)}
                  />
                </div>
              )) || (
                <h2 className="font-semibold truncate text-gray-300 text-lg">
                  {entityBenefit.category}
                </h2>
              )}
              {entityBenefit?.custom && (
                <div>
                  <button
                    className={editButtonCN(
                      entityBenefit?.isEditing ? "mt-2" : "mt-0"
                    )}
                    type="button"
                    onClick={() => toggleEditingBenefit(bIndex)}
                  >
                    <img
                      className="w-8 cursor-pointer"
                      src={editIcon}
                      alt="edit data"
                    />
                  </button>
                </div>
              )}
            </div>
            {entityBenefit.benefit.map((policy, pIndex) => {
              return (
                <CollapsibleSection
                  key={policy?.plan}
                  id={policy.plan}
                  className={collapsibleContainerCN}
                  titleComponent={() => (
                    <div className="flex flex-row px-3">
                      {(policy?.isEditing && (
                        <Input
                          value={policy.plan}
                          placeholder="Provider Name"
                          onChange={(val) =>
                            onChangePolicyName(val, bIndex, pIndex)
                          }
                        />
                      )) || (
                        <h3 className="font-semibold truncate text-gray-300 text-medium">
                          {policy?.plan}
                        </h3>
                      )}
                      <div>
                        <button
                          className={editButtonCN(
                            policy?.isEditing ? "mt-2" : "mt-0"
                          )}
                          type="button"
                          onClick={() =>
                            toggleEditingPolicyName(bIndex, pIndex)
                          }
                        >
                          <img
                            className="w-8 cursor-pointer"
                            src={editIcon}
                            alt="edit data"
                          />
                        </button>
                      </div>
                    </div>
                  )}
                  isOpen={policy.isOpen}
                  setIsOpen={() =>
                    !policy?.isEditing && togglePolicy(bIndex, pIndex)
                  }
                  useChevronIcon
                >
                  <div className="flex flex-col border-t mx-3 border-gray-150">
                    <div className="flex flex-row pt-6">
                      <p className="text-sm font-semibold pr-4">
                        Age banding coverage:
                      </p>
                      <RadioButton
                        value="Yes"
                        label="Yes"
                        isChecked={policy?.ageBanding}
                        onChange={() =>
                          onChangeAgeBandingCoverage(bIndex, pIndex)
                        }
                      />
                      <RadioButton
                        value="No"
                        label="No"
                        isChecked={!policy?.ageBanding}
                        onChange={() =>
                          onChangeAgeBandingCoverage(bIndex, pIndex)
                        }
                      />
                    </div>
                    <div className="flex flex-row py-6">
                      <div className="w-1/4 pr-4">
                        <DatePicker
                          label="Coverage Start Date"
                          value={policy.startDate}
                          onChange={(val) =>
                            onChangePolicyDate(val, bIndex, pIndex)
                          }
                        />
                      </div>
                      <div className="w-1/4 px-4">
                        <Dropdown
                          label="Coverage Duration"
                          value={durationOptions.find(
                            (opt) => opt.value === policy.duration
                          )}
                          options={durationOptions}
                          onChange={(val) =>
                            onChangePolicyDuration(val, bIndex, pIndex)
                          }
                        />
                      </div>
                      <div className="w-1/2 px-4">
                        <Input
                          placeholder="Website Url"
                          label="Plan Website"
                          value={policy.website}
                          onChange={(val) =>
                            onChangePolicyWebsite(val, bIndex, pIndex)
                          }
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="flex flex-row w-full py-2">
                        <div className="flex w-1/3 pr-6">
                          <h3 className="font-semibold text-black">
                            Benefit Level
                          </h3>
                        </div>
                        <div className="flex w-1/6 pl-8 pr-2">
                          <h3 className="font-semibold text-black">
                            Plan Cost
                          </h3>
                        </div>
                        <div className="flex w-1/6">
                          <h3 className="font-semibold text-black">
                            Employer Coverage
                          </h3>
                        </div>
                      </div>
                      {policy.levels.map((policyLevel, lIndex) => {
                        return (
                          <div
                            className="flex flex-row w-full py-2"
                            key={policyLevel?.level}
                          >
                            <div className="flex w-1/3 p4-6">
                              <h3 className="font-semibold text-gray-200">
                                {
                                  initialLevels.find(
                                    (lvl) => lvl.level === policyLevel.level
                                  ).display
                                }
                              </h3>
                            </div>
                            <div className="flex w-1/6 pl-4 pr-2">
                              <div className="flex w-4/6">
                                <Input
                                  placeholder="Cost"
                                  isDollarValue
                                  disableClear
                                  onChange={(val) =>
                                    onChangeLevelCost(
                                      val,
                                      bIndex,
                                      pIndex,
                                      lIndex
                                    )
                                  }
                                  value={policyLevel.cost}
                                />
                              </div>
                            </div>
                            <div className="flex w-1/6">
                              <div className="flex w-1/2">
                                <Input
                                  placeholder="Level"
                                  isPercentageValue
                                  disableClear
                                  onChange={(val) =>
                                    onChangeLevelCoverage(
                                      val,
                                      bIndex,
                                      pIndex,
                                      lIndex
                                    )
                                  }
                                  value={policyLevel.employerCoverage}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </CollapsibleSection>
              );
            })}
            <div className="flex border-dashed border-2 border-brandGreen p-4 justify-center">
              <TertiaryButton
                title={`+ Add ${entityBenefit.category} Benefit`}
                onClick={() => onAddPolicy(bIndex)}
              />
            </div>
          </div>
        );
      })}
      <div className="flex py-4">
        <BaseButton
          title="+ Add Benefit Category"
          className={tertiaryButtonCN}
          onClick={onAddBenefit}
          innerClassName="mx-0"
        />
      </div>
    </div>
  );
};

BenefitsSection.propTypes = {
  systemSettings: PropTypes.shape({
    benefits: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string,
        benefit: PropTypes.arrayOf(
          PropTypes.shape({
            plan: PropTypes.string,
            startDate: PropTypes.string,
            duration: PropTypes.string,
            website: PropTypes.string,
            levels: PropTypes.arrayOf(
              PropTypes.shape({
                level: PropTypes.string,
                cost: PropTypes.string,
                employerCoverage: PropTypes.string,
              })
            ),
            isOpen: PropTypes.bool,
            isEditing: PropTypes.bool,
            ageBanding: PropTypes.bool,
          })
        ),
        isEditing: PropTypes.bool,
      })
    ),
  }),
  dispatchSystemSettings: PropTypes.func,
};

BenefitsSection.defaultProps = {
  systemSettings: undefined,
  dispatchSystemSettings: undefined,
};

export default BenefitsSection;

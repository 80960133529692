import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { DocumentAPI } from "@griffingroupglobal/eslib-api";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";

import { toastError } from "../../helpers/Toast";
import { useAppState } from "../../state/appState";
import useCurrentUser from "../../hooks/useCurrentUser";
import useDocuments from "../../hooks/useDocuments";
import useFavorites from "../../hooks/useFavorites";
import useDocumentsConfiguration from "../../hooks/useDocumentsConfiguration";
import { useDocumentSwitchView } from "../../hooks/useSwitchView";

import DocumentMgmtFileFolders from "./DocumentMgmtFileFolders";
import DocumentTable from "../../stories/Components/DocumentTable/DocumentTable";
import DocumentUploadForm from "../../stories/Components/DocumentUploadForm/DocumentUploadForm";
import DocumentEditModal from "../../stories/Components/DocumentEditModal/DocumentEditModal";

import Spinner from "../../stories/Components/Spinner/Spinner";
import Modal from "../../stories/Components/Modal/Modal";
import {
  GET_DOCUMENT_PATH,
  GET_DOCUMENT_EDIT_PATH,
  GET_DOCUMENT_HISTORY_PATH,
  GET_PROPERTY_WORKFLOW_CREATE_PATH,
  GET_PROJECT_CREATE_DOCUMENT_PATH,
  CREATE_DOCUMENTS_PATH,
  WORKFLOWS_ADD_NEW_PATH,
  SET_WORKFLOW_DOCUMENT,
  GET_PROJECT_WORKFLOW_CREATE_PATH,
  DOCUMENT_DISPLAY_STATUS,
} from "../../constants";

import whiteCrossIcon from "../../stories/assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../stories/assets/images/whiteExclamationIcon.svg";
import { getDocumentUser } from "../../helpers/Document";

const toastCloseIcon = <img src={whiteCrossIcon} alt="Close notice" />;
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

export default function Documents({ showHeader }) {
  const history = useHistory();
  const { projectId } = useParams();
  const { data: currentUser } = useCurrentUser();
  const {
    documents: allDocuments,
    addDocument,
    addDocuments,
    removeDocument,
  } = useDocuments();
  const [, dispatchAppState] = useAppState();
  const { data: docConfig } = useDocumentsConfiguration();
  const [isShowingTable] = useDocumentSwitchView(false);
  const [favorites, { postFavorite, deleteFavorite }] = useFavorites();

  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [addedFiles, setAddedFiles] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingDoc, setEditingDoc] = useState();

  const docTypeOptionsMap = React.useMemo(() => {
    return {
      ...docConfig?.documents?.documentType
        .filter((doc) => doc.selected && (doc.value || doc.id))
        .reduce((obj, item) => {
          return {
            ...obj,
            [item.value ?? item.id]: {
              label: item.display,
              value: item.value ?? item.id,
            },
          };
        }, {}),
    };
  }, [docConfig]);

  const handleFavoriteClick = React.useCallback(
    (id) => {
      const foundItem = favorites.find((favorite) => {
        return favorite.item.reference.includes(id);
      });
      const foundDoc = documents.find((doc) => doc.id === id);

      if (foundItem) {
        deleteFavorite([foundItem]);
      } else {
        postFavorite(id, "Document");
      }

      if (foundDoc) {
        delete foundDoc.isFavorited;
        addDocument(foundDoc);
      }
    },
    [documents, favorites, deleteFavorite, postFavorite, addDocument]
  );

  useEffect(() => {
    const stillUploading = addedFiles.some((item) => item.isUploading);

    if (!stillUploading && addedFiles.length) {
      addedFiles.forEach((item) => {
        if (item.isFavorited) {
          handleFavoriteClick(item.id);
        }
      });
      addDocuments(addedFiles);
      setAddedFiles([]);
    }
  }, [addedFiles, addDocuments, handleFavoriteClick]);

  useEffect(() => {
    if (allDocuments?.length) {
      if (projectId) {
        setDocuments(
          allDocuments.filter((doc) => doc.project?.includes(projectId))
        );
      } else {
        setDocuments(allDocuments);
      }
      setLoading(false);
    } else if (allDocuments && !allDocuments.length) {
      setDocuments([]);
      setLoading(false);
    }
  }, [allDocuments, projectId]);

  const handleFileClick = (doc) => {
    history.push(GET_DOCUMENT_PATH(doc.id));
  };
  const handleRowClick = (doc) => {
    history.push(GET_DOCUMENT_PATH(doc.id));
  };
  const handleFileEdit = async (doc) => {
    const { data } = await DocumentAPI.getWOP(`${doc.id}/$canedit`);
    if (data.canedit) {
      if (doc.contentReference) {
        setEditingDoc(doc);
        setShowEditModal(true);
      } else {
        history.push(GET_DOCUMENT_EDIT_PATH(doc.id));
      }
    } else {
      toastError(
        `Document is currently "${
          DOCUMENT_DISPLAY_STATUS[data?.status]
        }" and cannot be edited`,
        toastErrorIcon,
        toastCloseIcon
      );
    }
  };
  const handleShowHistory = (id) => {
    history.push(GET_DOCUMENT_HISTORY_PATH(id));
  };
  const handleSendToWorkflow = useCallback(
    ({ id, project, property }) => {
      const document = allDocuments.find((doc) => doc.id === id);

      const parentDocument = allDocuments.find(
        (doc) => doc.reference === document?.documentRelationships?.parent
      );

      const doesParentDocHasCurrentDocAsAdditionalDoc =
        parentDocument?.additionalDocuments?.indexOf(document.reference) !== -1;
      // if document has parent, then block the access and toast msg
      if (parentDocument && doesParentDocHasCurrentDocAsAdditionalDoc) {
        toastError(
          `This document was created from "${parentDocument?.name}" and cannot be directly sent to a Workflow. Go to the parent document to send this to a Workflow.`,
          toastErrorIcon,
          toastCloseIcon
        );
      } else {
        const allChildDocs = [];
        // if document has children then also attach the children docs to the WF
        if (allDocuments?.length && document?.additionalDocuments?.length) {
          document?.additionalDocuments?.forEach((child) => {
            const childDoc = allDocuments.find(
              (doc) => doc.reference === child
            );
            if (childDoc) {
              allChildDocs.push(childDoc);
            }
          });
        }
        dispatchAppState({
          type: SET_WORKFLOW_DOCUMENT,
          workflowDocument: [id, ...allChildDocs.map((child) => child.id)],
        });

        if (project) {
          history.push(GET_PROJECT_WORKFLOW_CREATE_PATH(project.split("/")[1]));
        } else if (property) {
          history.push(
            GET_PROPERTY_WORKFLOW_CREATE_PATH(property.split("/")[1])
          );
        } else {
          history.push(WORKFLOWS_ADD_NEW_PATH);
        }
      }
    },
    [allDocuments, dispatchAppState, history]
  );

  const handleUploadVersion = () => {};
  const handleUpdateDocuments = () => {};
  const handleUpdateDocument = async (doc) => {
    const originalDoc = documents.find((item) => item.id === doc.id);
    const { creator, modifier } = originalDoc;

    const { data } = await DocumentAPI.patch(doc.id, doc, {
      ...originalDoc,
      createdBy: doc.createdBy,
    });

    setDocuments((prev) =>
      prev.map((item) => {
        if (item.id === data.id) {
          return { ...data, creator, modifier };
        }
        return item;
      })
    );
    setShowEditModal(false);
  };

  const handleShowDelete = (doc) => {
    setDeleteDoc(doc);
    setShowDeleteModal(true);
  };
  const handleHideDelete = () => {
    setDeleteDoc();
    setShowDeleteModal(false);
  };
  const handleDeleteDocument = async () => {
    const { data } = await DocumentAPI.getWOP(`${deleteDoc.id}/$canedit`);
    if (data.canedit) {
      DocumentAPI.delete(deleteDoc.id);
      removeDocument(deleteDoc.id);
      if (deleteDoc?.additionalDocuments?.length) {
        deleteDoc?.additionalDocuments.map((doc) =>
          removeDocument(doc.split("/")[1])
        );
      }
      setDocuments((prev) => prev.filter((item) => item.id !== deleteDoc.id));
      handleHideDelete();
    } else {
      toastError(
        `Document is currently "${
          DOCUMENT_DISPLAY_STATUS[data?.status]
        }" and cannot be deleted`,
        toastErrorIcon,
        toastCloseIcon
      );
    }
  };

  const handleDeleteAllDocuments = () => {
    Promise.all(deleteDoc.map(async (id) => DocumentAPI.delete(id))).then(() =>
      setDocuments((prev) =>
        prev.filter((item) => !deleteDoc.includes(item.id))
      )
    );
    setDeleteDoc();
    setShowDeleteAllModal(false);
  };
  const handleShowDeleteAll = (selectedDocs) => {
    const selectedDocIds = Object.keys(selectedDocs);
    if (selectedDocIds?.length === 1) handleShowDelete({ id: selectedDocIds });
    else if (selectedDocIds?.length > 1) {
      setDeleteDoc(selectedDocIds);
      setShowDeleteAllModal(true);
    }
  };
  const handleHideDeleteAll = () => {
    setDeleteDoc();
    setShowDeleteModal(false);
  };

  const handleUploadClick = () => {
    document.getElementsByClassName("document_upload_form")[0]?.click();
  };

  const handleCreateDocumentClick = () => {
    if (projectId) {
      history.push(GET_PROJECT_CREATE_DOCUMENT_PATH(projectId));
    } else {
      history.push(CREATE_DOCUMENTS_PATH);
    }
  };

  const handleFileAdded = React.useCallback(
    (fileRef, fileBody, showSpinner) => {
      if (showSpinner) {
        setIsUploading(true);
      }

      const currentFile = {
        isUploading: true,
        reference: fileRef,
        isFavorited: fileBody.isFavorited,
      };
      setAddedFiles((prev) => [currentFile, ...prev]);

      if (fileBody) {
        const tempDocument = {
          customName: fileBody.name,
          docType: fileBody.docType,
          contentReference: fileRef,
          lineItems: [],
          status: "open",
        };

        // TODO: this should have a propeject/property reference

        DocumentAPI.post(tempDocument)
          .then(({ data: newDocument }) => {
            const resultDocument = {
              ...newDocument,
              creator: currentUser,
              isFavorited: fileBody.isFavorited,
              uploadedFileRef: fileRef,
            };

            setAddedFiles((prev) => {
              return [
                resultDocument,
                ...prev.filter((item) => item.reference !== fileRef),
              ];
            });
            setIsUploading(false);
          })
          .catch((err) => {
            setAddedFiles((prev) => {
              return [
                tempDocument,
                ...prev.filter((item) => item.reference !== fileRef),
              ];
            });
            setIsUploading(false);
            console.error(err);
          });
      }
    },
    [currentUser]
  );

  const getFolderContents = () => {
    let filteredDocs = documents;
    filteredDocs =
      documents?.map((doc) => {
        return {
          ...doc,
          isFavorited:
            doc.isFavorited ??
            favorites.some((fav) => fav.item.reference.includes(doc.id)),
        };
      }) ?? [];

    return filteredDocs.map((doc) => ({
      ...doc,
      resource: doc.name,
      createdBy: getDocumentUser(doc.creator),
      modifiedDate: doc.metadata?.lastUpdated
        ? moment(doc.metadata.lastUpdated).format("MM/DD/YYYY")
        : "--",
    }));
  };

  const buttonActions = [
    { title: "Create New", onClick: handleCreateDocumentClick },
    { title: "Upload File", onClick: handleUploadClick },
    { title: "Delete", onClick: handleShowDeleteAll },
  ];

  return (
    <>
      {!loading && (
        <div>
          {!isShowingTable && (
            <>
              <div className="absolute top-0" />
              <DocumentMgmtFileFolders
                contents={getFolderContents()}
                favorites={favorites}
                docTypeOptionsMap={docTypeOptionsMap}
                addedFiles={addedFiles}
                onUpdateDocuments={handleUpdateDocuments}
                onFileClick={handleFileClick}
                onFileEdit={handleFileEdit}
                onShowHistory={handleShowHistory}
                onSendToWorkflow={handleSendToWorkflow}
                onUploadVersion={handleUploadVersion}
                onFileAdd={handleFileAdded}
                onFileDelete={handleShowDelete}
                onFavoriteClick={handleFavoriteClick}
                showHeader={showHeader}
              />
            </>
          )}

          {isShowingTable && (
            <>
              {!isUploading && (
                <DocumentTable
                  documents={getFolderContents()}
                  favorites={favorites}
                  onRowClick={handleRowClick}
                  onFileEdit={handleFileEdit}
                  onShowHistory={handleShowHistory}
                  onSendToWorkflow={handleSendToWorkflow}
                  onUploadVersion={handleUploadVersion}
                  onFileDelete={handleShowDelete}
                  onFavoriteClick={handleFavoriteClick}
                  buttonActions={buttonActions}
                  docTypeOptionsMap={docTypeOptionsMap}
                />
              )}
              {isUploading && (
                <div className="flex justify-center items-center w-full h-full">
                  <Spinner notFullScreen />
                </div>
              )}
            </>
          )}
        </div>
      )}

      {loading && (
        <div className="flex justify-center items-center w-full h-full">
          <Spinner notFullScreen />
        </div>
      )}

      <Modal
        title={
          deleteDoc?.name ? `Delete ${deleteDoc.name}?` : "Delete Document?"
        }
        isOpen={showDeleteModal}
        primaryButtonTitle="Yes, delete"
        primaryButtonOnClick={handleDeleteDocument}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={handleHideDelete}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <>
          <p className="text-base mb-2">
            Are you sure you want to delete {deleteDoc?.name ?? "this document"}
            ? Once deleted, it cannot be recovered.
          </p>
        </>
      </Modal>

      <Modal
        title="Delete All Documents?"
        isOpen={showDeleteAllModal}
        primaryButtonTitle="Yes, Delete All"
        primaryButtonOnClick={handleDeleteAllDocuments}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={handleHideDeleteAll}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <>
          <p className="text-base mb-2">
            Are you sure you want to delete ALL documents? Once deleted, they
            cannot be recovered.
          </p>
        </>
      </Modal>

      <DocumentEditModal
        document={editingDoc}
        onDocumentUpdate={handleUpdateDocument}
        onClose={() => setShowEditModal(false)}
        show={showEditModal}
      />

      <div className="hidden">
        <DocumentUploadForm
          onAddFile={(fileRef, file) => handleFileAdded(fileRef, file, true)}
          onAddPhoto={(fileRef, file) => handleFileAdded(fileRef, file, true)}
          onAddVideo={(fileRef, file) => handleFileAdded(fileRef, file, true)}
          showCorner
        />
      </div>
    </>
  );
}

Documents.propTypes = {
  showHeader: PropTypes.bool,
};

Documents.defaultProps = {
  showHeader: true,
};

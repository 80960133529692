/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import collapseIcon from "../../assets/images/collapseIcon.svg";
import expandIcon from "../../assets/images/expandIcon.svg";
import checkIcon from "../../assets/images/checkSolidGreen.svg";
import Chevron from "../Chevron/Chevron";

const barCN = (showIconOnStart, barClassName) => cntl`
  flex
  select-none
  ${showIconOnStart ? "justify-start px-2" : "justify-between"}
  ${barClassName}
`;

const titleCN = (isDisabled, disableClassName) => cntl`
  ${isDisabled ? disableClassName : "text-es-medium-grey"}
  text-es-lg
  font-es-semibold
  text-es-dark-grey
`;

const iconCN = (hide, showIconOnStart) => cntl`
  ${hide ? "invisible pt-2 " : showIconOnStart ? "pt-1 px-2" : "pt-2"}
`;

const containerCN = (
  className,
  isOpen,
  openClassName,
  isDisabled,
  disableClassName
) => cntl`
  ${
    // eslint-disable-next-line no-nested-ternary
    isOpen && openClassName
      ? openClassName
      : !isOpen && isDisabled && disableClassName
      ? disableClassName
      : className
  }
  px-2
`;

const CollapsibleSection = ({
  title,
  subtitle,
  titleComponent,
  subComponent,
  defaultOpen,
  children,
  className,
  barClassName,
  hideIconOnOpen,
  useChevronIcon,
  isOpen: isOpenProp,
  setIsOpen: setIsOpenProp,
  showCheckOnClose,
  disable,
  id,
  openContainerClassName,
  disabledContainerClassName,
  disabledTitleClassName,
  showIconOnStart,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  useEffect(() => {
    if (isOpenProp !== undefined) {
      setIsOpen(isOpenProp);
    }
  }, [isOpenProp]);

  const toggleOpen = useCallback(
    (e) => {
      if (!disable && e?.target?.type !== "checkbox") {
        if (setIsOpenProp) {
          setIsOpenProp(!isOpenProp);
        } else {
          setIsOpen(!isOpen);
        }
      }
    },
    [disable, isOpen, isOpenProp, setIsOpenProp]
  );

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      toggleOpen();
    }
  };

  return (
    <div
      className={containerCN(
        className,
        isOpen,
        openContainerClassName,
        disable,
        disabledContainerClassName
      )}
      style={{
        transitionProperty: "all",
        transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
        transitionDuration: "500ms",
      }}
      id={id}
    >
      <div
        className={barCN(showIconOnStart, barClassName)}
        onClick={toggleOpen}
        onKeyDown={onKeyDown}
        role="button"
        tabIndex={0}
      >
        <div className="flex">
          <div className="flex flex-col">
            <div className="flex flex-row items-center">
              {showCheckOnClose && !isOpen && (
                <div className="mr-4">
                  <img src={checkIcon} alt="check" style={{ width: "18px" }} />
                </div>
              )}
              {title && (
                <h2
                  className={titleCN(
                    !isOpen && disable,
                    disabledTitleClassName
                  )}
                >
                  {title}
                </h2>
              )}
              {titleComponent && titleComponent()}
            </div>
            {subtitle && (
              <div className="flex flex-row pt-1">
                <h3 className="text-es-normal text-es-dark-grey">{subtitle}</h3>
              </div>
            )}
          </div>
        </div>
        <div className="flex">
          {subComponent && subComponent()}
          <div
            className={iconCN(
              hideIconOnOpen && (isOpen || disable),
              showIconOnStart
            )}
          >
            {(useChevronIcon && (
              <Chevron
                className={isOpen ? "transform rotate-90 w-3" : "w-3"}
                color="#333333"
              />
            )) || (
              <img
                src={isOpen ? collapseIcon : expandIcon}
                alt={isOpen ? "collapse" : "expand"}
              />
            )}
          </div>
        </div>
      </div>
      <div className={!isOpen ? "hidden" : "pt-6"}>{children}</div>
    </div>
  );
};

CollapsibleSection.propTypes = {
  /**
   * the text to show on the collapsible section
   */
  title: PropTypes.string,
  /**
   * section should show child on default
   */
  defaultOpen: PropTypes.bool,
  /**
   * the react component to display when section is open
   */
  children: PropTypes.node,
  /**
   * optional className to add to the container
   */
  className: PropTypes.string,
  barClassName: PropTypes.string,
  subComponent: PropTypes.node,
  hideIconOnOpen: PropTypes.bool,
  useChevronIcon: PropTypes.bool,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  showCheckOnClose: PropTypes.bool,
  disable: PropTypes.bool,
  id: PropTypes.string,
  openContainerClassName: PropTypes.string,
  disabledContainerClassName: PropTypes.string,
  disabledTitleClassName: PropTypes.string,
  subtitle: PropTypes.string,
  titleComponent: PropTypes.node,
  showIconOnStart: PropTypes.bool,
};

CollapsibleSection.defaultProps = {
  title: null,
  subtitle: undefined,
  defaultOpen: false,
  children: null,
  className: null,
  barClassName: null,
  subComponent: null,
  hideIconOnOpen: false,
  useChevronIcon: false,
  isOpen: undefined,
  setIsOpen: undefined,
  showCheckOnClose: false,
  disable: false,
  id: undefined,
  openContainerClassName: undefined,
  disabledContainerClassName: undefined,
  disabledTitleClassName: undefined,
  titleComponent: undefined,
  showIconOnStart: false,
};

export default CollapsibleSection;

import { useCallback, useState } from "react";

const INITIAL_REPORT = {
  title: "",
  outputType: "csv",
  tags: [],
  distribution: [],
  sources: [],
  dateRange: {
    option: "last7days",
  },
  repeat: undefined,
};

/**
 * Dispatcher hook to manage the state and functions related to Report form
 */
const useServiceRequestDispatcher = (currentReport) => {
  // State to handle Report data from inputs form
  const [reportForm, setReportForm] = useState(currentReport || INITIAL_REPORT);

  /**
   * Set reportForm state using field and value
   * @param {string} field name of the property field
   * @param {string} value value of the property field
   */
  const handleChangeForm = useCallback((field, value) => {
    // reset sourceType to undefined when DD selection cleared
    if (Array.isArray(value) && value?.length === 0) {
      setReportForm((current) => ({
        ...current,
        [field]: value,
        sourceType: undefined,
      }));
    } else setReportForm((current) => ({ ...current, [field]: value }));
  }, []);

  /**
   * Add/Remove tags from Report state
   * @param {String} operation could be add or remove
   * @param {String} tagReference tag reference: Tag/111-222-333-4444
   */
  const handleTags = useCallback(
    (operation = "add", tagReference) => {
      const currentTags = [...reportForm.tags];

      if (operation === "add") {
        currentTags.push(tagReference);
      }

      if (operation === "remove") {
        const indexToRemove = currentTags.indexOf(tagReference);
        if (indexToRemove !== -1) {
          currentTags.splice(indexToRemove, 1);
        }
      }

      handleChangeForm("tags", currentTags);
    },
    [reportForm?.tags, handleChangeForm]
  );

  /**
   * Add/Remove distro members from Report state
   * @param {String} operation could be add or remove
   * @param {String} userRef user reference: User/111-222-333-4444
   */
  const handleDistroMembers = useCallback(
    (operation = "add", userReference) => {
      const currentDistribution = [...reportForm.distribution];

      if (operation === "add") {
        currentDistribution.push(userReference);
      }

      if (operation === "remove") {
        const indexToRemove = currentDistribution.indexOf(userReference);
        if (indexToRemove !== -1) {
          currentDistribution.splice(indexToRemove, 1);
        }
      }

      handleChangeForm("distribution", currentDistribution);
    },
    [reportForm?.distribution, handleChangeForm]
  );

  /**
   * Set date range in Report form state
   * @param {Object} dateRangeSelected date range option selected from dropdown
   */
  const handleDateRange = (dateRangeSelected) => {
    const { id: option, value } = dateRangeSelected;
    const dateRange =
      option === "custom" ? { option, custom: value.custom } : { option };

    handleChangeForm("dateRange", dateRange);
  };

  return {
    reportForm,
    setReportForm,
    handleChangeForm,
    handleTags,
    handleDistroMembers,
    handleDateRange,
  };
};

export default useServiceRequestDispatcher;

import React from "react";
import { dayAbbreviations } from "../../../constants";
import { getWeeks } from "../../../helpers/Calendar";
import { useAppState } from "../../../state/appState";
import CalendarBody from "./Children/CalendarBody";
import CalendarHeader from "./Children/CalendarHeader";
import MonthViewEventMap from "./Children/MonthViewEventMap";

const PureCalendar = ({
  now,
  events,
  filter,
  onClick,
  handleCreateModalOpen,
}) => {
  const [{ calendarTimezone }] = useAppState();
  const calendarData = getWeeks(now, calendarTimezone);

  const calendarHeight = calendarData?.length * 35 + 10;

  return (
    <div className="w-full h-full mb-5">
      <svg viewBox={`-0.5 -0.5 281 ${calendarHeight + 1}`} width="100%">
        <CalendarHeader
          values={dayAbbreviations}
          height={calendarHeight}
          width={280}
        />
        <CalendarBody
          values={calendarData}
          handleCreateModalOpen={handleCreateModalOpen}
        />
        <MonthViewEventMap
          weeks={calendarData}
          events={events}
          filter={filter}
          onClick={onClick}
        />
      </svg>
    </div>
  );
};

export default PureCalendar;

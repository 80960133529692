/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
import { TimesheetUtil } from "@griffingroupglobal/eslib-util";
import moment from "moment";
import { DateIsAfter, DateIsBetween } from "./Dates";
import { CustomError } from "./Error";

const { TimeSheets } = TimesheetUtil;

export const MinutesToHours = (value) => {
  return parseFloat((value / 60).toFixed(2));
};

export const HoursToMinutes = (value) => {
  return parseInt(value * 60, 10);
};

/**
 * TimecardNavigation class
 */
export class TimecardNaviation {
  constructor(
    navStart,
    navDate,
    navPeriod,
    reloadFunc,
    currentPeriodFrame,
    userRef
  ) {
    this.currentWeek = TimeSheets.weekFollowingDay(navStart, navDate);
    this.dates = this.currentWeek;
    this.period = navPeriod;
    this.start = navStart;
    this.reload = reloadFunc;
    this.periodFrame = currentPeriodFrame;
    this.user = userRef;
  }

  thisWeek() {
    if (this.dates.length !== 7)
      throw new CustomError({ message: "Navigation Error" });
    // set dates array to current week
    this.dates = this.currentWeek;

    // fetch timecard for current week
    this.reload(this.periodFrame?.periodStart, this.periodFrame?.periodEnd);
  }

  prev() {
    if (this.dates.length !== 7)
      throw new CustomError({ message: "Navigation Error" });

    // Get payroll period of previous week
    const { periodStart: prevPeriodStart, periodEnd: prevPeriodEnd } =
      TimeSheets.getPeriodFrame(
        // Period - e.g. everyweek or everyotherweek
        this.period,
        // Start - e.g Monday, Tuesday, etc
        this.start,
        // First date of current week - 2 days = previous week
        this.dates[0].clone().subtract(2, "days").format("MM/DD/YYYY")
      );

    // Get array of dates representing previous week
    const previousWeek = TimeSheets.weekFollowingDate(
      this.dates[0].clone().subtract(8, "days").format("MM/DD/YYYY")
    );

    // Update dates with previous week
    this.dates = previousWeek;

    // fetch timecard for new payroll period
    this.reload(prevPeriodStart, prevPeriodEnd);
  }

  next() {
    if (this.dates.length !== 7)
      throw new CustomError({ message: "Navigation Error" });

    const { periodEnd: nextPeriodEnd } = TimeSheets.getPeriodFrame(
      this.period,
      this.start,
      this.dates[6].clone().add(2, "days").format("MM/DD/YYYY")
    );

    const nextWeek = TimeSheets.weekFollowingDate(
      this.dates[6].format("MM/DD/YYYY")
    );
    this.dates = nextWeek;
    const firstDayOfNextWeek = this.dates[0].clone().format().split("T")[0];
    this.reload(firstDayOfNextWeek, nextPeriodEnd);
  }
}

/**
 * @param {Object} dates - dates returned from TimesheetAPI $isopen
 * @returns - resolved promise or error for dates included that have gone through payroll
 */
export const payrollCheck = (dates) =>
  new Promise((open, closed) => {
    if (!dates) {
      // eslint-disable-next-line prefer-promise-reject-errors
      closed({ message: "Error Cancelling PTO", processed: true });
    }

    Object.values(dates).forEach((item) => {
      if (item === "closed")
        // eslint-disable-next-line prefer-promise-reject-errors
        closed({
          message: "Cannot cancel requests that have been processed.",
          processed: true,
          dates,
        });
    });
    open();
  });

const displayTime = (value, empty) => {
  const time = parseFloat(value) || 0;

  if (time === 0) return empty || "";
  return `${time.toFixed(2)} hrs`;
};
export default displayTime;

export const convertDecimalValue = (val, multiple) => {
  if (!val) return 0;
  const [whole, portion = 0] = String(parseFloat(val)).split(".");
  const denominator = parseInt(
    [1, ...Array(portion.length).fill(0)].join(""),
    10
  );
  return (
    parseFloat(whole) * multiple +
    (parseFloat(portion) * multiple) / denominator
  );
};

export const parseTimeString = (time) => {
  let value;
  switch (true) {
    case typeof time === "number":
      value = time;
      break;
    case time.includes("h") && time.includes("m"):
      let hours;
      let mins;

      // Regex Example: 10h 1m = ["1", "0h", " ", " ", "1m"]
      const arr = time.replace(" ", "").split(/(?![m,h])+/);

      // Find first instance of h or m
      const i = arr.findIndex((c) => c.includes("h") || c.includes("m"));

      // Create array of values using index of first instance
      // Traverse and assign values accordingly
      [
        arr
          .slice(0, i + 1)
          .join("")
          .replace(" ", ""),
        arr
          .slice(i + 1)
          .join("")
          .replace(" ", ""),
      ].forEach((v) => {
        if (v.includes("h")) {
          hours = parseFloat(v);
        } else {
          mins = parseFloat(v);
        }
      });

      value = hours + mins * (1 / 60);
      break;
    case time.includes("h"):
      const [strippedHour] = time.split("h");
      value = parseFloat(strippedHour);
      break;
    case time.includes("m"):
      const [strippedMin] = time.split("m");
      value = strippedMin * (1 / 60);
      break;
    default:
      value = parseFloat(time);
  }

  return value > 24 ? 24 : value - ((value % 1) % 0.25);
};

export const textToMinutes = (time) => {
  const newMins = parseTimeString(time);
  return newMins < 1440 ? newMins : 1440;
};

export const timeSheetInput = (value) => {
  const inputRegex = /([^\d.hm ])/gi;
  const hasHour = String(value.match(/h/gi) || []).length;
  const hasMinute = String(value.match(/m/gi) || []).length;
  const hasDecimal = String(value.match(/\./g) || []).length;
  const lastLetter = value.toLowerCase().slice(-1);
  if (
    hasHour > 1 ||
    hasMinute > 1 ||
    hasDecimal > 1 ||
    inputRegex.test(value)
  ) {
    return false;
  }
  if (lastLetter === "." && (hasHour || hasMinute)) {
    return false;
  }
  if ((lastLetter === "h" || lastLetter === "m") && hasDecimal) {
    return false;
  }
  return true;
};

export const getWeekFromDay = (day) => {
  const startWeek = moment(day).startOf("week");
  const currentDay = startWeek.subtract(1, "day");
  const newDates = Array(7)
    .fill(0)
    .map(() => currentDay.add(1, "day").clone());
  return newDates;
};

export const getWeekFollowingDay = (day, dateInput) => {
  const today = moment(dateInput).isoWeekday();
  let newDates;
  let startWeek;

  if (today < day) {
    startWeek = moment(moment(dateInput).isoWeekday(day))
      .subtract(1, "day")
      .subtract(1, "weeks");
    newDates = Array(7)
      .fill(0)
      .map(() => startWeek.add(1, "day").clone());
    return newDates;
  }
  startWeek = moment(moment(dateInput).isoWeekday(day)).subtract(1, "day");
  newDates = Array(7)
    .fill(0)
    .map(() => startWeek.add(1, "day").clone());
  return newDates;
};

export const getWeekFollowingDate = (day) => {
  const startWeek = moment(day);
  const newDates = Array(7)
    .fill(0)
    .map(() => startWeek.add(1, "day").clone());
  return newDates;
};

export const rangeToMoments = (start, end) => {
  if (!start || !end) return [];

  const endDate = moment(end.split("T")[0]);
  const days = endDate.diff(start.split("T")[0], "days") + 1;
  const startDate = moment(start.split("T")[0]).subtract(1, "days");

  return Array(days)
    .fill(0)
    .map(() => startDate.add(1, "days").clone());
};

export const weeksFollowingDay = (
  day = "monday",
  dateInput = undefined,
  weeks
) => {
  // Get Start Of Input Date
  const calendarDay = dateInput.split("T")[0];
  const startOfInput = dateInput ? new Date(calendarDay) : new Date();
  startOfInput.setUTCHours(0, 0, 0, 0);

  const today = moment(dateInput.split("T")[0]);

  let newDates;
  let startWeek;
  /**
   * Checks if the todays date is less than
   * the weekStart date && they are in
   * the same year
   */

  if (
    today.dayOfYear() < moment(calendarDay).day(day).dayOfYear() &&
    today.year() === moment(calendarDay).day(day).year()
  ) {
    startWeek = moment(startOfInput)
      .day(day)
      .subtract(1, "day")
      .subtract(1, "weeks");
    newDates = Array(7 * weeks ?? 1)
      .fill(0)
      .map(() => startWeek.add(1, "day").clone());
    return newDates;
  }
  startWeek = moment(startOfInput).day(day).subtract(1, "day");
  newDates = Array(7 * weeks ?? 1)
    .fill(0)
    .map(() => startWeek.add(1, "day").clone());
  return newDates;
};

export const weekDays = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export const payrollPeriod = {
  everyweek: { amount: 1, measure: "week" },
  everyotherweek: { amount: 2, measure: "week" },
  monthly: {
    amount: 1,
    measure: "month",
  },
  firstandfifteenth: { date: [1, 15] },
  fifteenthandlast: { date: [1, "last"] },
};

/**
 * @function calculatePeriodStart
 * Edge Case:
 * @summary - Time sheet first day of the week is
 * before period start return {periodFrame.periodStart}
 */
export const calculatePeriodStart = (date, payrollFrame) => {
  if (DateIsAfter(payrollFrame?.periodStart, moment(date).format())) {
    return payrollFrame?.periodStart;
  }

  return moment(date).format();
};

/**
 * @function calculatePeriodEnd
 * Edge Case:
 * @summary - Time sheet is on current week
 * and last day of the week is
 * after period end return {periodFrame.periodEnd}
 */
export const calculatePeriodEnd = (date, payrollFrame) => {
  const lastTimeSheetDay = moment(date);
  if (
    !DateIsBetween(
      payrollFrame?.periodStart,
      payrollFrame?.periodEnd,
      moment(date).format()
    ) &&
    DateIsAfter(moment(date).format(), payrollFrame?.periodEnd)
  ) {
    return payrollFrame?.periodEnd;
  }
  return lastTimeSheetDay.format();
};

/**
 *
 * @param {Timesheet} timesheet
 * @returns Timesheet entries array represented as rows
 * Row-Key: ${ProjectRef}|${FinancialCode}|${Rate}
 * Row-Entry key: ${Date as MM/DD/YYYY}
 * @summary - Ensures unique rows as individual entries contain their own properties
 * for the references used in the Row-Key
 */
export const unzipTimesheet = (timesheet, row = false) => {
  return Array.isArray(timesheet?.entries)
    ? {
        ...timesheet,
        entries: timesheet.entries.reduce((list, entry) => {
          const {
            project,
            financialCode: rawFinancialCode,
            rate,
            date,
            value,
          } = entry;

          let financialCode;
          if (!rawFinancialCode) {
            financialCode = null;
          } else if (typeof rawFinancialCode === "string") {
            financialCode = rawFinancialCode;
          } else if (rawFinancialCode?.key) {
            financialCode = rawFinancialCode?.key;
          } else {
            financialCode = `${rawFinancialCode?.division}-${rawFinancialCode?.code}-${rawFinancialCode?.subcode}`;
          }

          const key = `${project}|${financialCode}|${rate}`;
          let val = value ?? 0;
          if (row) {
            val = MinutesToHours(val);
          }
          // eslint-disable-next-line no-param-reassign
          list[key] = list[key]
            ? {
                ...list[key],
                [`${moment(date?.split("T")?.[0]).format("MM/DD/YYYY")}`]: {
                  ...entry,
                  value: val,
                },
              }
            : {
                [`${moment(date?.split("T")?.[0]).format("MM/DD/YYYY")}`]: {
                  ...entry,
                  value: val,
                },
              };
          return list;
        }, {}),
      }
    : undefined;
};

export const unzipPto = (requests) => {
  return requests?.map((request) => {
    const dates = request?.dates?.reduce((list, item) => {
      list[`${moment.utc(item.date).format("MM/DD/YYYY")}`] = item;
      return list;
    }, {});
    return { ...request, dates };
  });
};

export const unzipEmployeePto = (requests, users) => {
  return requests?.reduce((arr, request) => {
    if (request.status === "approved") {
      const entries = request?.dates?.reduce((list, item) => {
        list[`${moment.utc(item.date).format("MM/DD/YYYY")}`] = {
          ...item,
          value: parseFloat(item?.numHours) * 60,
        };
        return list;
      }, []);
      arr.push({
        ...request,
        employee: {
          fullName: `${users?.[request?.userRef]?.name?.firstName} ${
            users?.[request?.userRef]?.name?.lastName
          }`,
          reference: users?.[request?.userRef]?.reference,
        },
        entries,
        periodStart: moment(request?.dates?.[0]?.date).format(),
        project: "Time Off",
        timeOff: true,
      });
    }

    return arr;
  }, []);
};

export const checkPtoCache = (cache, start, end, frame) => {
  return (
    cache?.filter((pto) => {
      const dates = Object.keys(pto?.dates);
      return dates?.find((date) =>
        DateIsBetween(
          start ?? frame?.periodStart,
          end ?? frame?.periodEnd,
          moment(date).format()
        )
      );
    }) ?? []
  );
};

const getDateKeys = (start, end) => {
  const pointer = moment(start);
  const endPointer = moment(end).add(1, "days").format("MM/DD/YYYY");
  const keys = [];
  while (pointer.format("MM/DD/YYYY") !== endPointer) {
    keys.push(pointer.format("MM/DD/YYYY"));
    pointer.add(1, "days");
  }
  return keys;
};

export const checkEmployeePtoCache = (cache, start, end) => {
  const periodStart = start?.split("T")[0];
  const periodEnd = end?.split("T")[0];
  const included = cache?.reduce((list, pto) => {
    const includedDates = getDateKeys(periodStart, periodEnd).filter(
      (key) => pto?.entries[key]
    );

    if (includedDates.length > 0) {
      list.push({
        ...pto,
        entries: includedDates?.reduce((entries, key) => {
          if (pto?.entries?.[key]) entries[key] = pto.entries[key];
          return entries;
        }, {}),
      });
    }

    return list;
  }, []);
  return included;
};

export const zipTimesheet = (timesheet) => {
  return {
    ...timesheet,
    entries: Object.values(timesheet.entries)?.reduce((list, item) => {
      const entryArray = [...Object.values(item)]?.map((val) => {
        let financialCode = val?.financialCode;
        if (typeof val?.financialCode === "string") {
          const keySplit = financialCode?.split("-");
          financialCode = {
            division: keySplit?.[0],
            code: keySplit?.[1],
            subcode: keySplit?.[2],
          };
        }
        let value = val?.value ?? 0;
        value = HoursToMinutes(value);
        return {
          ...val,
          financialCode,
          value,
        };
      });

      return [...list, ...entryArray];
    }, []),
  };
};

export const zipTimesheetPatch = (timesheets, selected) => {
  const timesheetPairs = timesheets?.reduce((list, item) => {
    const key = item?.id?.split("$=")[0];
    if (selected?.includes(item?.id?.split("$=")[0])) {
      const temp = list[key];
      if (!temp) {
        list[key] = {
          ...item,
          entries: [...Object.values(item.entries)],
        };
      } else {
        list[key] = {
          ...list[key],
          entries: [...list[key]?.entries, ...Object.values(item.entries)],
        };
      }
      return list;
    }
    return list;
  }, {});
  return timesheetPairs;
};

/**
 *
 * @param {String} operation - Value to overwrite entry status ie. "Approved", "Rejected"
 * @param {*} timesheets - Original entries
 * @param {*} selectedRows - Rows selected from original entries to be overwritten
 * @returns - Timesheet rows with updated status based on action param
 */
export const updateRowStatus = (operation, timesheets, selectedRows) => {
  const updated = timesheets;

  selectedRows?.forEach((item) => {
    updated[item.index] = {
      ...updated[item.index],
      entries: Object.keys(updated[item.index]?.entries)?.reduce(
        (list, entry) => {
          list[entry] = {
            ...updated[item.index]?.entries?.[entry],
            status: operation,
          };
          return list;
        },
        {}
      ),
    };
  });
  return updated?.map((item, index) => ({ ...item, index }));
};

export const unzipTimesheets = (entries, users) => {
  const newRows = [];
  entries?.forEach((item) => {
    const original = typeof item?.resource === "string" ? item : item?.resource;
    const temp = unzipTimesheet(original);
    Object.keys(temp?.entries)?.forEach((key, idx) => {
      const [project, financialCode, rate] = key?.split("|");
      newRows.push({
        resource: {
          ...original,
          id: `${original?.id}$=${idx}`,
          employee: original?.employee
            ? original?.employee
            : {
                ...users?.[original?.userRef],
                fullName: `${users?.[original?.userRef]?.name?.firstName} ${
                  users?.[original?.userRef]?.name?.lastName
                }`,
              },
          entries: temp?.entries[key],
          project,
          financialCode,
          rate,
        },
      });
    });
  });
  return newRows;
};

export const unzipPayroll = (entries, users) => {
  let queryString = "";
  const unzipped = entries?.map((item, index) => {
    queryString = queryString
      ? `${queryString},${item?.resource?.reference}`
      : `?association=${item?.resource?.reference}`;
    return {
      resource: {
        ...item?.resource,
        index,
        employee: {
          ...users?.[item?.resource?.userRef],
          fullName: `${users?.[item?.resource?.userRef]?.name?.firstName} ${
            users?.[item?.resource?.userRef]?.name?.lastName
          }`,
        },
      },
    };
  });
  return { queryString, unzipped };
};

/**
 * @function checkCache
 * @summary - Check cache before making another request
 * @returns - Timesheet
 */
export const checkCache = (list, reqStart, reqEnd, multi = false, user) => {
  /**
   * Strip time from isoString because only date is important
   */
  const start0 =
    typeof reqStart === "string" ? reqStart?.split("T")[0] : reqStart;

  const end0 = typeof reqEnd === "string" ? reqEnd?.split("T")[0] : reqEnd;

  const isValid = (item) => {
    const start1 =
      typeof item?.resource?.periodStart === "string"
        ? item?.resource?.periodStart?.split("T")[0]
        : item?.resource?.periodStart;

    const end1 =
      typeof item?.resource?.periodEnd === "string"
        ? item?.resource?.periodEnd?.split("T")[0]
        : item?.resource?.periodEnd;
    const validDate =
      moment(start1).isSame(start0, "day") && moment(end1).isSame(end0, "day");

    if (user) {
      return validDate && item?.resource?.userRef === user;
    }
    return validDate;
  };

  const cachedSheet = !multi ? list?.find(isValid) : list?.filter(isValid);

  return !multi
    ? cachedSheet?.resource
    : cachedSheet?.map((item, index) => ({ ...item?.resource, index }));
};

export const checkPayrollCache = (list, reqStart, reqEnd, multi = false) => {
  const isValid = (item) => {
    return (
      DateIsBetween(
        reqStart,
        reqEnd,
        item?.resource?.periodStart?.split("T")[0]
      ) &&
      DateIsBetween(reqStart, reqEnd, item?.resource?.periodEnd?.split("T")[0])
    );
  };
  const cachedSheet = !multi ? list?.find(isValid) : list?.filter(isValid);

  return !multi
    ? cachedSheet?.resource
    : cachedSheet?.map((item, index) => ({ ...item?.resource, index }));
};

/**
 *
 * @function getTimeSheets
 * @param {Object[]} cache - array of cached timesheets
 * @param {String} cacheLimit - ISOstring query limit (past date)
 * @param {Object[]} dates - week of query
 * @param {Object} frame - payroll period periodStart && periodEnd
 * @param {String} start - start date (used to check cache)
 * @param {String} end - end date (used to check cache)
 * @returns - timesheet from cache
 */
export const getTimeSheets = async (
  cache,
  cacheLimit,
  dates,
  frame,
  start,
  end,
  reference
) => {
  /**
   * @summary - If cache.length check for requested
   * timesheet
   */
  if (cache.current?.length > 0) {
    let cachedSheet;
    if (!start) {
      cachedSheet = checkCache(
        cache.current,
        frame.periodStart,
        frame.periodEnd,
        null,
        reference
      );
    }

    if (
      start &&
      TimeSheets.DateIsBetween(cacheLimit, frame?.periodEnd, start)
    ) {
      cachedSheet = checkCache(cache.current, start, end, null, reference);
    }

    if (cachedSheet) {
      return {
        cachedSheet,
      };
    }
  }

  return { cachedSheet: undefined };
};

export const getIsoDate = (input) => {
  const splitDate = moment(input).format().split("T")[0];

  return moment(splitDate).format();
};

const checkDuplicateUsers = (users, newUser) => {
  if (users.find((user) => user.id === newUser.id)) {
    return users;
  }
  return [...users, newUser];
};

export const formatHistory = (entries, users) => {
  const formatted = entries?.reduce((list, entry) => {
    const existingEntry = list?.[entry?.resource?.association];
    if (existingEntry) {
      const existingAction = existingEntry?.[entry?.resource?.action];
      list[entry?.resource?.association] = {
        ...existingEntry,
        [entry?.resource?.action]: existingAction
          ? {
              ...existingAction,
              user: checkDuplicateUsers(existingAction.user, {
                ...users[entry?.resource?.user],
                fullName: `${users[entry?.resource?.user]?.name?.firstName} ${
                  users[entry?.resource?.user]?.name?.lastName?.[0]
                }.`,
              }),
            }
          : {
              ...entry?.resource,
              user: [
                {
                  ...users[entry?.resource?.user],
                  fullName: `${users[entry?.resource?.user]?.name?.firstName} ${
                    users[entry?.resource?.user]?.name?.lastName?.[0]
                  }.`,
                },
              ],
            },
      };
      return list;
    }
    list[entry?.resource?.association] = {
      [entry?.resource?.action]: {
        ...entry?.resource,
        user: [
          {
            ...users[entry?.resource?.user],
            fullName: `${users[entry?.resource?.user]?.name?.firstName} ${
              users[entry?.resource?.user]?.name?.lastName?.[0]
            }.`,
          },
        ],
      },
    };
    return list;
  }, {});

  return formatted;
};

import { useHistory } from "react-router";
import { useProjectsDelete } from "../../../hooks/projects";
import {
  toastError,
  toastMessage,
} from "../../../stories/Components/Toast/Toast";

const useProjectDeleteModalData = ({
  project,
  selectedProjects = [],
  setShowDeleteModal,
}) => {
  const history = useHistory();

  const { deleteProjects, isDeleting } = useProjectsDelete();

  const hasMultipleProjects = selectedProjects.length > 1;

  const message = hasMultipleProjects
    ? "these projects? Once deleted, these projects and their data cannot be recovered."
    : "this project? Once deleted, this project and its data cannot be recovered.";

  /**
   * Api call to delete selected project(s)
   * @param {string[]} projectsIds - array of project(s) id to delete
   */
  const handleDeleteProjects = async (projectsIds) => {
    deleteProjects(projectsIds)
      .then(() => {
        const display = hasMultipleProjects
          ? "Projects were removed successfully"
          : "Project was deleted successfully";

        toastMessage(display);
      })
      .catch(() => {
        const display = hasMultipleProjects
          ? "Projects could not be deleted. Please try again"
          : "Project could not be deleted. Please try again";

        toastError(display);
      });

    setShowDeleteModal(false);
  };

  /**
   * Handle project(s) deletion. Remove single or multiple projects based on props.
   */
  const onDeleteClicked = () => {
    // Remove 1:M project(s) from table
    if (selectedProjects.length > 0) {
      const projectsIds = selectedProjects.map((data) => data.id);
      handleDeleteProjects(projectsIds);

      return;
    }

    // Remove single project from single project page
    if (project) {
      handleDeleteProjects([project.id]);

      history.goBack();
    }
  };

  return {
    message,
    isDeleting,
    hasMultipleProjects,
    onDeleteClicked,
  };
};

export default useProjectDeleteModalData;

import { useReducer } from "react";
import { cloneDeep } from "lodash";

const defaultState = {};

const reducer = (holdback, action) => {
  switch (action.type) {
    case "reset":
      return cloneDeep(action.holdback) ?? defaultState;
    case "initialize":
      return {
        lineitemId: action?.lineitemId,
        paidToDate: action?.paidToDate,
        workCompletedThisPeriod: action?.workCompletedThisPeriod,
        holdbackAmount: action?.holdbackAmount,
        originalLineitem: action?.originalLineitem,
      };
    case "holdbackAmount":
      return {
        ...holdback,
        holdbackAmount: action?.holdbackAmount,
      };
    default:
      return holdback;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};

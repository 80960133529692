import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Avatar from "../Message/Avatar";

const Replies = ({ data }) => {
  return (
    <div className="border-2 rounded-lg flex flex-col p-4 mb-4">
      <div className="flex flex-row mb-6">
        <Avatar
          className="mr-4 rounded-full"
          isExternalUser
          id={data?.userData?.id ?? data?.userData?.reference}
          disableTooltip
          name={`${data?.userData?.name?.firstName} ${data?.userData?.name?.lastName}`}
        />
        <div className="flex flex-col">
          <h2 className="font-semibold text-gray-400 capitalize">
            {`${data?.userData?.name?.firstName} ${data?.userData?.name?.lastName}`}
          </h2>
          <h3 className="text-gray-200 text-sm">
            {moment(data?.metadata?.createdAt).format("lll")}
          </h3>
        </div>
      </div>
      <p className="mb-4">{data.content}</p>
    </div>
  );
};

Replies.propTypes = {
  /**
   * data displayed in the comments
   */
  data: PropTypes.shape({
    id: PropTypes.string,
    metadata: PropTypes.shape({
      createdAt: PropTypes.string,
    }),
    content: PropTypes.string,
    userData: PropTypes.shape({
      id: PropTypes.string,
      reference: PropTypes.string,
      name: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    }),
  }),
};

Replies.defaultProps = {
  data: {},
};
export default Replies;

import React from "react";
import { PropTypes } from "prop-types";
import useContentRowData from "./useContentRowData";

const ContentRow = ({ children, title }) => {
  const { inlineStyles, classStyles } = useContentRowData({ title });

  return (
    <div style={inlineStyles.row} className={classStyles.rowCN}>
      <h1 className={classStyles.topicCN}>{title}</h1>
      <div style={inlineStyles.input}>{children}</div>
    </div>
  );
};

ContentRow.defaultProps = {
  children: undefined,
  title: undefined,
};

ContentRow.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default ContentRow;

/* eslint-disable no-param-reassign */
import { useMutation } from "react-query";
import { DocumentAPI } from "@griffingroupglobal/eslib-api";
import { toastError } from "../helpers/Toast";
import queryClient from "../config/reactQuery/queryClient";
import {
  todosCodeKeys,
  vendorinvoiceKeys,
} from "../config/reactQuery/queryKeyFactory";

export default () => {
  const patch = async ({ document, updatedDocument }) => {
    // merge changes directly to budget
    const { data } = await DocumentAPI.patchByIdWOP(
      document?.id,
      "$frominvoice",
      updatedDocument,
      document
    );

    return data;
  };

  return useMutation({
    mutationFn: patch,
    onSettled: () => {
      queryClient.invalidateQueries(vendorinvoiceKeys);
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(todosCodeKeys, context?.prev);
      toastError(
        `There was an error updating vendor invoice. \n Message: ${error.message} \n Please try again`
      );
      console.error("usePatchMergedVendorInvoice", error);
    },
  });
};

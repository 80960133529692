import React from "react";
import {
  SR_SINGLE_TASK_PAGE,
  WF_SINGLE_TASK_PAGE,
} from "../../../../../constants";

const HeaderNavigationTitle = ({ fromPage }) => {
  const getTitle = () => {
    switch (fromPage) {
      case SR_SINGLE_TASK_PAGE.from:
        return SR_SINGLE_TASK_PAGE.title;
      case WF_SINGLE_TASK_PAGE.from:
        return WF_SINGLE_TASK_PAGE.title;
      default:
        return "";
    }
  };
  return (
    <h1 className="text-gray-500 text-4xl font-medium pl-2 mt-6 mb-6">
      {getTitle()}
    </h1>
  );
};

export default HeaderNavigationTitle;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useESChatContext } from "../../../../../context/ChatContext/ESChatContext";
import {
  ERROR_LOADING_REPLIES,
  ERROR_SENDING_MESSAGE,
} from "../../../../../constants";
import useCurrentUser from "../../../../../hooks/useCurrentUser";
import { onAddFilesFunction } from "../../../../../helpers/Chat";
import { CustomError } from "../../../../../helpers/Error";

export default (id, count) => {
  const { data: currentUser } = useCurrentUser();
  const [replies, setReplies] = useState([]);
  const [loading, setLoading] = useState(true);
  const { selectedChannel } = useESChatContext();

  /**
   * Error Handling
   */
  const [error, setError] = useState();
  const clearError = () => {
    setError();
  };

  /**
   * Error Handling
   */

  const getReplies = useCallback(async () => {
    setLoading(true);
    try {
      const { messages } = await selectedChannel.getReplies(id, {});
      setReplies(messages);
    } catch (e) {
      setError({ err: ERROR_LOADING_REPLIES });
      throw CustomError({ message: ERROR_LOADING_REPLIES });
    }
    setLoading(false);
  }, [id, selectedChannel]);

  useEffect(() => {
    if (count > 0) getReplies();
  }, [count, getReplies]);

  /**
   * Uploads
   */
  const [files, setFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(false);

  const onAddFiles = useMemo(
    () => onAddFilesFunction({ selectedChannel, setFiles, setLoadingFiles }),
    [selectedChannel]
  );

  const handleRemoveFiles = (ref) => {
    setFiles((prev) => prev.filter((item) => item.file !== ref));
  };

  /**
   * Uploads
   */

  const [input, setInput] = useState();

  const onChangeMessage = (message) => {
    setInput(message);
  };

  const sendReply = useCallback(
    async (msg) => {
      /**
       * Send Message Conditions:
       * - text input
       * - resending failed message
       * - Attachments
       */
      if (input || msg || files.length > 0) {
        setInput();
        const newReply = msg ?? {
          text: input,
          parent_id: id,
          attachments: files.map((item) => {
            let type = item.type.split("/")[1];
            if (type.includes("application")) type = "file";
            const f = {
              mime_type: item.type,
              name: item.file.name,
              type,
            };
            if (item?.type?.includes("image")) {
              f.image_url = item.url;
            } else {
              f.asset_url = item.url;
            }

            return f;
          }),
        };

        selectedChannel
          .sendMessage(newReply)
          .then(({ message }) => {
            setReplies((prev) => [...prev, message]);
          })
          .catch((err) => {
            console.error("Message attempt unsuccessful", err);
            setError({
              err: ERROR_SENDING_MESSAGE,
              data: { ...newReply, user: { id: currentUser?.streamId } },
            });
            throw new CustomError({ message: ERROR_SENDING_MESSAGE });
          });
        setFiles([]);
      }
    },
    [currentUser?.streamId, files, id, input, selectedChannel]
  );

  const resend = useCallback(() => {
    sendReply(error?.data).then(() => clearError());
  }, [error?.data, sendReply]);

  const reloadThread = useCallback(() => {
    getReplies()
      .then(() => {
        clearError();
      })
      .catch((err) => {
        console.error(err);
      });
  }, [getReplies]);

  return {
    sendReply,
    onChangeMessage,
    onAddFiles,
    replies,
    loadingThread: loading,
    loadingFiles,
    error,
    resend,
    input,
    handleRemoveFiles,
    files,
    reload: reloadThread,
  };
};

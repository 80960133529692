import moment from "moment";
import {
  ADVANCED_WF_STEP,
  RESOLVED_WF,
  RESUBMISSION_WF_STEP,
  STATUS_PILL_INFO,
} from "../constants";
import { getFullName } from "./Formatters";

/**
 * WorkflowData is a helper object to format data from a Workflow payload returned by the API into a more organized
 * format for display.
 */
class WorkflowData {
  resolved = false;

  status = "";

  isFlagged = false;

  name = "";

  version = "";

  initiatorName = "";

  initiationDate = new Date();

  associatedWorkflows = [];

  hasAssociatedWorkflows = "N";

  progress = "";

  endDate = new Date();

  warning = "";

  workflowId = "";

  property = "";

  project = "";

  isStepAdvanced = false;

  containerView = "";

  currentStep = 0;

  dateCreated = new Date();

  daysOverdue = 0;

  members = [];

  id = "";

  files = [];

  steps = [];

  metadata = {};

  completed = {};

  isDraft = false;

  versions = [];

  history = [];

  associationResource = "";

  documentTypes = [];

  /**
   * Create a new {@link WorkflowData} from a Workflow payload. The Workflow payload may come from useWorkflows.
   */
  static formatPayloadForTable(info, properties, projects) {
    const progress = this.getProgress(info);
    return new WorkflowData({
      resolved: info.status === RESOLVED_WF,
      status: info.isDraft ? "Draft" : STATUS_PILL_INFO[info.status]?.name,
      isFlagged: info.isFlagged,
      name: info.name,
      version: info.version,
      initiatorName: getFullName(info.metadata?.userData?.name),
      initiationDate: moment(info.dateCreated).format("MMM D, YYYY"),
      associatedWorkflows: info.associatedWorkflows,
      hasAssociatedWorkflows: info.associatedWorkflows?.length > 0 ? "Y" : "N",
      endDate: moment(info.endDate).format("MMM D, YYYY"),
      warning: info.warning,
      workflowId: info.id,
      property:
        properties?.find((property) => property.reference === info.property)
          ?.title || "Not Specified *",
      associationResource: info.property || info.project,
      project:
        projects?.find((project) => project.reference === info.project)?.name ||
        "Not Specified *",
      progress,
      isStepAdvanced: progress.charAt(0) !== "0",
      containerView: info.containerView,
      currentStep: info.currentStep,
      dateCreated: info.dateCreated,
      daysOverdue: info.daysOverdue,
      members: info.members,
      id: info.id,
      files: info.files,
      metadata: info.metadata,
      steps: info.steps,
      completed: info.completed,
      isDraft: info.isDraft,
      versions: info.versions,
      history: info.history,
      documentTypes: info.documentTypes,
    });
  }

  static getProgress(info) {
    return info.status === RESOLVED_WF
      ? `${info?.steps?.length || 0}/${info.steps?.length}`
      : `${info?.currentStep || 0}/${info.steps?.length}`;
  }

  static checkStep(step, checkResubmission) {
    if (step?.needOnlyOneAdvancer) {
      const advancers = step.members.filter(
        (member) => member.status === ADVANCED_WF_STEP
      );
      if (advancers.length === 0) {
        // check resubmission issue
        if (checkResubmission) {
          const rejectors = step.members.filter(
            (member) => member.status === RESUBMISSION_WF_STEP
          );
          if (rejectors?.length === step.members.length) {
            return false;
          }
        } else {
          return false;
        }
      }
    } else {
      const allAdvanced = step.members.every(
        (member) => member?.status === ADVANCED_WF_STEP
      );
      if (!allAdvanced) {
        // check resubmission issue
        if (checkResubmission) {
          const rejectors = step.members.filter(
            (member) => member.status === RESUBMISSION_WF_STEP
          );
          if (rejectors?.length > 0) {
            return false;
          }
        } else {
          return false;
        }
      }
    }
    return true;
  }

  static getWorkflowStepName = (position, id, reference, steps, showFull) => {
    if (position === undefined) return "";
    const isParallel = steps[position].parallelSteps.length > 0;
    let index = 0;
    let notes = "";
    if (isParallel) {
      const isMain = steps[position].id === id;
      if (isMain) {
        index = 0;
      } else {
        const newIndex = steps[position].parallelSteps.findIndex(
          (info) => info.id === id
        );
        index = newIndex;
      }
    }
    if (isParallel) {
      notes =
        steps[position]?.parallelSteps?.[index]?.members?.filter(
          (info) => info?.reference === reference
        )?.[0]?.note || "";
    } else {
      notes =
        steps[position]?.members?.filter(
          (info) => info?.reference === reference
        )?.[0]?.note || "";
    }
    const stepName = isParallel
      ? `${
          showFull
            ? `Step ${position + 1}${String.fromCharCode(97 + index)}. ${
                steps[position].parallelSteps?.[index]?.name
              }`
            : `${position + 1}${String.fromCharCode(97 + index)}`
        }`
      : `${
          showFull
            ? ` Step ${position + 1}. ${steps[position].name}`
            : position + 1
        }`;
    return { stepName, notes };
  };

  /**
   * Constructor. Please use {@link formatPayloadForTable} instead.
   */
  constructor({
    resolved,
    status,
    isFlagged,
    name,
    version,
    initiatorName,
    initiationDate,
    associatedWorkflows,
    hasAssociatedWorkflows,
    progress,
    endDate,
    warning,
    workflowId,
    property,
    project,
    isStepAdvanced,
    containerView,
    currentStep,
    dateCreated,
    daysOverdue,
    members,
    id,
    files,
    metadata,
    steps,
    completed,
    isDraft,
    versions,
    history,
    associationResource,
    documentTypes,
  }) {
    this.resolved = resolved;
    this.status = status;
    this.isFlagged = isFlagged;
    this.name = name;
    this.version = version;
    this.initiatorName = initiatorName;
    this.initiationDate = initiationDate;
    this.associatedWorkflows = associatedWorkflows;
    this.hasAssociatedWorkflows = hasAssociatedWorkflows;
    this.progress = progress;
    this.endDate = endDate;
    this.warning = warning;
    this.workflowId = workflowId;
    this.property = property;
    this.project = project;
    this.isStepAdvanced = isStepAdvanced;
    this.containerView = containerView;
    this.currentStep = currentStep;
    this.dateCreated = dateCreated;
    this.daysOverdue = daysOverdue;
    this.members = members;
    this.id = id;
    this.info = files;
    this.metadata = metadata;
    this.steps = steps;
    this.completed = completed;
    this.isDraft = isDraft;
    this.versions = versions;
    this.history = history;
    this.associationResource = associationResource;
    this.documentTypes = documentTypes;
  }
}

export default WorkflowData;

import React, { useRef } from "react";
import WidgetEditorView from "../WidgetEditorView";
import useReportSingleViewData from "./useReportSingleViewData";
import PopoverComments from "../PopoverComments";
import Modal from "../Modal/Modal";
import RunReportButton from "../RunReportButton";
import PastLog from "./PastLog";
import SiteHeader from "../SiteHeader/SiteHeader";
import InlineInput from "../Input/InlineInput";

/**
 * Show Report single view
 * @param {association} If `association` is present, means this page is rendered inside P/P/A details page, otherwhise from Reports page
 */
const ReportSingleView = ({
  isLoading,
  reportId,
  association,
  setButtonActions,
  onBackButtonPressed,
}) => {
  const containerRef = useRef();

  const {
    reportForm,
    commentsData,
    canWrite,
    canDelete,
    canDownload,
    showDeleteDialog,
    setShowDeleteDialog,
    handleNavigateBack,
    handleOpenComments,
    handleCloseComments,
    handleDeleteReport,
    handleOpenReportEditModal,
    handleDownloadReport,
  } = useReportSingleViewData({
    reportId,
    association,
    onBackButtonPressed,
    setButtonActions,
    containerRef,
  });

  const renderRunReportButton = () => (
    <div ref={containerRef} className="flex flex-row items-center">
      <RunReportButton reportId={reportForm?.id} />
    </div>
  );

  return (
    <>
      <div className={association ? "hidden" : ""}>
        <SiteHeader
          title={
            <InlineInput
              width="-ml-4 w-full"
              size="custom4xl"
              value={reportForm?.title}
              editing={false}
              fontWeight="bold"
              color="gray-650"
              hidePencil
              isHeaderTitle
            />
          }
          buttons={renderRunReportButton()}
        />
      </div>

      <WidgetEditorView
        hideBorder={!association}
        showBackButton={association}
        hideFooter
        isLoading={isLoading}
        onBackPressed={handleNavigateBack}
      >
        <WidgetEditorView
          title={reportForm?.title}
          canEdit={canWrite}
          canDelete={canDelete}
          canDownload={canDownload}
          hideFooter
          onDeletePressed={() => setShowDeleteDialog(true)}
          onBeginEditing={handleOpenReportEditModal}
          onDownloadPressed={handleDownloadReport}
          ExtraHeaderActions={association ? renderRunReportButton() : undefined}
          onOpenComments={handleOpenComments}
        >
          <div className="col-span-3 border-b-4 border-black mt-8 mb-7" />

          <PastLog reportId={reportForm?.id} />
        </WidgetEditorView>
      </WidgetEditorView>

      {commentsData.isOpen && (
        <PopoverComments
          isOpen={commentsData.isOpen}
          resourceReference={commentsData?.reportReference}
          allowMentionAll
          onClose={handleCloseComments}
        />
      )}

      {showDeleteDialog && (
        <Modal
          alert
          title="Delete Report"
          isOpen={showDeleteDialog}
          primaryButtonTitle="Yes, delete"
          primaryButtonOnClick={handleDeleteReport}
          tertiaryButtonTitle="Cancel"
          onRequestModalClose={() => setShowDeleteDialog(false)}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
          hideFooter
        >
          <p className="text-base mb-2">
            Are you sure you want to delete this report? Once deleted, it cannot
            be recovered.
          </p>
        </Modal>
      )}
    </>
  );
};

export default ReportSingleView;

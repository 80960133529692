import { useQueryClient } from "react-query";
import { useCallback } from "react";
import { fetchPropertiesOverview } from "../../helpers/Properties";
import propertyKeys from "./propertiesKeys";

/**
 * Query hook to prefetch properties overview
 */
const usePropertiesPrefetch = () => {
  const queryClient = useQueryClient();

  const prefetchPropertiesOverview = useCallback(() => {
    queryClient.prefetchQuery({
      queryKey: propertyKeys.overview(),
      queryFn: fetchPropertiesOverview,
    });
  }, [queryClient]);

  return { prefetchPropertiesOverview };
};

export default usePropertiesPrefetch;

import { reportsKeys } from "../../config/reactQuery/queryKeyFactory";

/**
 * Update report in all posible queries
 * ["reports"]                                  ===> Holds an array of reports
 * ["reports", "Property/1111-2222-3333-4444"]  ===> Holds an array of reports for associations
 * ["reports", "3333-6666-4444-8888"]           ===> Holds an object (single report)
 * @param queryClient react query client object
 * @param id report id
 * @param reportUpdated report with new changes
 */
const mutateReportCache = (queryClient, id, reportUpdated) => {
  queryClient.setQueriesData(reportsKeys.reports, (current) => {
    if (Array.isArray(current)) {
      return current.map((report) => {
        if (report.id === id) {
          return reportUpdated;
        }
        return report;
      });
    }

    if (typeof current === "object" && current.id === id) {
      return reportUpdated;
    }

    return current;
  });
};

export default mutateReportCache;

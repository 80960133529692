import React, { Fragment } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

const navContainerCN = cntl`
  flex 
  justify-between
  items-center
  w-full
  lg:w-2/3
  xl:w-1/2
  mt-8 
  mb-14 
  ml-6
  relative
`;

const navItemCN = (selected) => cntl`
  relative
  text-sm 
  text-gray-200
  uppercase 
  text-center
  ${selected === 0 && "text-brandGreen font-semibold"}
`;

const navItemDotCN = (selected) => cntl`
  w-4
  h-4
  block
  rounded-full 
  ${selected < 0 && "bg-gray-150"}
  ${selected === 0 && "bg-brandGreen"}
  ${selected > 0 && "bg-white border-2 border-gray-150"}
  transition
  duration-300
  ease-in-out
`;

const navItemNameCN = cntl`
  absolute
  left-1/2
  transform
  -translate-x-2/4
  top-7
  whitespace-nowrap
`;

const hitAreaCN = cntl`
  absolute
  bottom-0
  left-0 
  w-full
  h-14
`;

const trackCN = cntl`
  absolute 
  w-full
  h-0.5 
  bg-gray-150
`;

const ProgressTracker = ({
  steps,
  selectedIndex,
  onStepClick,
  disableNext,
}) => {
  const getNavItem = (name, index) => (
    <>
      <i className={navItemDotCN(index - selectedIndex)} aria-hidden="true">
        &nbsp;
      </i>
      <div className={navItemNameCN}>
        {name}
        <div className={hitAreaCN} aria-hidden="true">
          &nbsp;
        </div>
      </div>
    </>
  );
  const handleNextStep = (index) => {
    if (disableNext && index > selectedIndex) {
      onStepClick(selectedIndex);
    } else {
      onStepClick(index);
    }
  };
  return (
    <nav className={navContainerCN}>
      <div className={trackCN} aria-hidden="true">
        &nbsp;
      </div>
      {steps.map((name, index) => (
        <Fragment key={name}>
          {index === selectedIndex ? (
            <span className={navItemCN(index - selectedIndex)}>
              {getNavItem(name, index)}
            </span>
          ) : (
            <button
              onClick={() => handleNextStep(index)}
              className={navItemCN(index - selectedIndex)}
              type="button"
            >
              {getNavItem(name, index)}
            </button>
          )}
        </Fragment>
      ))}
    </nav>
  );
};

ProgressTracker.propTypes = {
  /**
   * array of strings that represent each step
   */
  steps: PropTypes.arrayOf(PropTypes.string),
  /**
   * the currently active index
   */
  selectedIndex: PropTypes.number,
  /**
   * function called when a step is clicked
   */
  onStepClick: PropTypes.func,
  /**
   * boolean to determine if next steps can be clicked
   */
  disableNext: PropTypes.bool,
};

ProgressTracker.defaultProps = {
  steps: [],
  selectedIndex: 0,
  onStepClick: undefined,
  disableNext: undefined,
};

export default ProgressTracker;

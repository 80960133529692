import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import ProjectDuration from "../../stories/Components/ProjectForm/ProjectDuration";
import ProjectOverview from "../../stories/Components/ProjectForm/ProjectOverview";
import AddressWidget from "../../stories/Components/Widgets/AddressWidget";
import WeatherWidget from "../../stories/Components/WeatherWidget/WeatherWidget";
import useDashboard from "../../hooks/useDashboard";
import MembersView from "../../stories/Components/Members/MembersView";
import LinksView from "../../stories/Components/Links/LinksView";
import VendorView from "../Overviews/Property/VendorView";

const ProjectDetailView = ({
  editedProject,
  loading,
  isEditing,
  config,
  dispatch,
  handleEdit,
  handleEditTwo,
  onFinishEditing,
  originalResource,
  setMemberLoading,
  memberLoading,
  disableEditing,
}) => {
  const { projectId } = useParams();
  const { weather, loading: loadingHook } = useDashboard(
    editedProject?.weather,
    projectId
  );
  const [projectTypes, setProjectTypes] = useState([]);

  useEffect(() => {
    if (config) {
      setProjectTypes(
        config.project.types
          .filter((type) => type.selected)
          .map((type) => ({
            label: type.display,
            value: type.id,
          }))
      );
    }
  }, [config]);

  const handleAddressInput = (val) => {
    dispatch({
      type: "address",
      address: val,
    });
  };

  const handleMailingSameAsPhysicalInput = (val) => {
    dispatch({
      type: "mailingSameAsPhysical",
      mailingSameAsPhysical: val,
    });
  };

  return (
    <div className="flex flex-col pb-2.5">
      <ProjectOverview
        resource={editedProject}
        projectTypes={projectTypes}
        loading={loading}
        isEditing={isEditing}
        dispatch={dispatch}
        handleEdit={handleEdit}
        handleEditTwo={handleEditTwo}
      />
      <AddressWidget
        resource={editedProject}
        loading={loading}
        isEditing={isEditing}
        handleMailingSameAsPhysicalInput={handleMailingSameAsPhysicalInput}
        handleAddressInput={handleAddressInput}
        dispatch={dispatch}
      />
      <div className="flex w-full min-h-full mb-4 gap-4">
        <MembersView
          resource={editedProject}
          dispatch={dispatch}
          editing={isEditing}
          isPropertyLoading={loading}
          setMemberLoading={setMemberLoading}
          memberLoading={memberLoading}
        />
        <VendorView
          resource={editedProject}
          dispatch={dispatch}
          editing={isEditing}
          isPropertyLoading={loading}
          setMemberLoading={setMemberLoading}
          memberLoading={memberLoading}
        />
        <LinksView
          resource={editedProject}
          disableEditing={disableEditing}
          dispatch={dispatch}
          editing={isEditing}
          loading={loading}
          widgetWidth="1/3"
        />
      </div>
      <ProjectDuration
        project={editedProject}
        isEditing={isEditing}
        onChange={handleEdit}
        onChangeObject={handleEditTwo}
        originalResource={originalResource}
        onFinishEditing={onFinishEditing}
      />
      <WeatherWidget
        weather={weather?.error ? undefined : weather}
        loading={loadingHook}
        error={weather?.error}
      />
    </div>
  );
};

ProjectDetailView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editedProject: PropTypes.object,
  loading: PropTypes.bool,
  isEditing: PropTypes.bool,
  config: PropTypes.objectOf({
    project: PropTypes.objectOf({
      types: PropTypes.arrayOf({
        selected: PropTypes.bool,
        display: PropTypes.string,
        id: PropTypes.string,
      }),
    }),
  }),
  dispatch: PropTypes.func,
  handleEdit: PropTypes.func,
  handleEditTwo: PropTypes.func,
  onFinishEditing: PropTypes.func,
  originalResource: PropTypes.shape({}),
  setMemberLoading: PropTypes.func,
  memberLoading: PropTypes.bool,
  disableEditing: PropTypes.bool,
};

ProjectDetailView.defaultProps = {
  editedProject: undefined,
  loading: false,
  isEditing: false,
  config: undefined,
  dispatch: undefined,
  handleEdit: undefined,
  handleEditTwo: undefined,
  onFinishEditing: undefined,
  originalResource: undefined,
  setMemberLoading: undefined,
  memberLoading: false,
  disableEditing: false,
};

export default ProjectDetailView;

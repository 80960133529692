import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Avatar } from "stream-chat-react";
import cntl from "cntl";
import "./ResourceCard.css";
import phoneGreen from "../../assets/images/phoneGreen.svg";
import emailGreen from "../../assets/images/emailGreen.svg";
import messageGreen from "../../assets/images/messageGreen.svg";
import ContactButton from "../Buttons/ContactButton";
import Dropdown from "../Dropdown/Dropdown";
import { GET_CONTACT_PATH } from "../../../constants";
import { getLastActiveDisplay } from "../../../helpers/Time";
import { getFullName } from "../../../helpers/Formatters";
import phoneGray from "../../assets/images/phoneGray.svg";
import emailGray from "../../assets/images/emailGray.svg";
import messageGray from "../../assets/images/messageGray.svg";

const avatarDropdownCN = cntl`
  w-8
  h-8
  mr-2
`;

const customStyle = {
  singleValue: {
    color: "#027D61",
  },
  valueContainer: {
    justifyContent: "center",
    paddingLeft: "0px",
    padding: "0px",
  },
  control: {
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    borderColor: "#027D61",
  },
};

const utilRowHeight = { height: "48px" };

const formatOptionLabel = ({
  label,
  title,
  avatar,
  name,
  hideAvatar,
  isMember,
  titleCN,
  titleContainerCN,
  isOnline,
  lastActive,
}) => (
  <div>
    <div className="flex flex-row justify-between">
      <div className="flex flex-row">
        {!hideAvatar && (
          <div className="flex relative">
            <Avatar
              className={avatarDropdownCN}
              name={getFullName(name)}
              isOnline={isOnline}
              isExternalUser
              avatar={avatar}
            />
          </div>
        )}
        <div className={`flex flex-col ${isMember && titleContainerCN}`}>
          <p className={`font-semibold ${titleCN} truncate text-ellipsis`}>
            {label}
          </p>
          <p className="font-normal text-xs text-gray-400">{title}</p>
          <p className="font-normal text-xs text-gray-200">
            {getLastActiveDisplay(lastActive, isOnline)}
          </p>
        </div>
      </div>
      {isMember && (
        <div className="bg-black rounded-full w-20 h-4 justify-end">
          <p
            className="text-white w-full pl-2 pt-0.5"
            style={{ fontSize: "0.6rem" }}
          >
            ES MEMBER
          </p>
        </div>
      )}
    </div>
  </div>
);

const ContactRowButton = ({
  contact,
  company,
  openChat,
  contacts,
  currentUser,
  setDropdownActive,
  dropdownActive,
}) => {
  const history = useHistory();
  const [phoneOptions, setPhoneOptions] = useState();
  const [emailOptions, setEmailOptions] = useState();
  const [fileDownloadUrl, setFileDownloadUrl] = useState(null);
  const [associatedUsers, setAssociatedUsers] = useState([]);
  const [, setIsIndividual] = useState(false);

  const downloadButton = useRef(null);

  useEffect(() => {
    if (contacts?.length && contact && company) {
      const companyAssociatedUsers = contacts?.filter(
        (c) => c.company.value === company?.reference && !c.metadata.deletedAt
      );
      setIsIndividual(companyAssociatedUsers?.length === 0);
      setAssociatedUsers(
        companyAssociatedUsers?.map((user) => ({
          label: `${user?.name?.firstName} ${user?.name?.lastName}`,
          name: {
            firstName: user?.name?.firstName,
            lastName: user?.name?.lastName,
          },
          title: user?.title,
          value: user?.id,
          contactPoint: user?.contactPoint,
          avatar: user?.avatar,
          isMember: user?.kind === "member",
          titleCN: "text-black text-xs",
          titleContainerCN: "w-24",
          lastActive: user?.lastActive,
          isOnline: user?.isOnline,
        }))
      );
    }
  }, [contacts, contact, company]);

  useEffect(() => {
    if (fileDownloadUrl) {
      downloadButton.current.click();

      setFileDownloadUrl(null);
    }
  }, [fileDownloadUrl]);

  useEffect(() => {
    if (contact) {
      setEmailOptions(
        contact?.contactPoint
          ?.filter((cp) => cp.system === "Email")
          ?.map((email) => ({
            title: `${email?.use} ${email?.value}`,
            onClick: () => {
              document.location = `mailto:${email?.value}?subject=&body=`;
            },
          }))
      );
      setPhoneOptions(
        contact?.contactPoint
          ?.filter((cp) => cp.system === "Phone")
          ?.map((phone) => ({
            title: `${phone?.use} ${phone?.value}`,
            anchorTag: (
              <a href={`tel:${phone?.value}`}>
                {phone?.use} {phone?.value}
              </a>
            ),
          }))
      );
    }
  }, [contact]);

  const onSelectContact = (selectedContact) => {
    history.push(GET_CONTACT_PATH(selectedContact?.value, "0"));
  };
  return (
    <>
      {/* Row added for "Reach Out" on Contact Cards */}
      {contact && !company ? (
        <div
          style={utilRowHeight}
          className="flex w-full rounded-b bg-brandGreen bg-opacity-10"
        >
          {/* row container */}
          <div className="flex flex-row w-full h-full">
            <div className="flex justify-start items-center pl-8 mr-7 h-full">
              <p className="text-md text-gray-450 font-medium">Reach Out</p>
            </div>

            {/* row of icons */}
            <div className="flex flex-row ml-4 w-36 h-full">
              <div className="flex flex-col w-1/3 justify-center items-center">
                <>
                  <ContactButton
                    iconLeft={
                      <img
                        alt="phoneGreenIcon"
                        src={phoneOptions?.length ? phoneGreen : phoneGray}
                        className="h-5"
                      />
                    }
                    iconClassName="flex-auto flex justify-center"
                    dropdownItems={phoneOptions}
                    containerClassName=""
                  />
                  <a
                    className="hidden"
                    download={`${contact?.name?.firstName} ${contact?.name?.lastName}-VCard.vcf`}
                    href={fileDownloadUrl}
                    ref={downloadButton}
                  >
                    export_vcard
                  </a>
                </>
              </div>
              <div className="flex flex-col w-1/3 justify-center items-center">
                <ContactButton
                  iconLeft={
                    <img
                      alt="emailGreenIcon"
                      src={emailOptions?.length !== 0 ? emailGreen : emailGray}
                      className="h-5 w-5"
                    />
                  }
                  iconClassName="flex-auto flex justify-center"
                  dropdownItems={emailOptions}
                  containerClassName=""
                />
              </div>
              <div className="flex flex-col w-1/3 justify-center items-center">
                <ContactButton
                  iconLeft={
                    <img
                      alt="messageGreenIcon"
                      src={
                        contact?.id !== currentUser?.id &&
                        contact?.kind === "member"
                          ? messageGreen
                          : messageGray
                      }
                      className="h-5"
                    />
                  }
                  onClick={
                    contact?.id !== currentUser?.id &&
                    contact?.kind === "member"
                      ? () => openChat(contact)
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={utilRowHeight}
          className="flex w-full rounded-b border-darkenedGreen"
        >
          <Dropdown
            onMenuOpen={() => (dropdownActive ? null : setDropdownActive(true))}
            onMenuClose={() =>
              dropdownActive ? setDropdownActive(false) : null
            }
            options={associatedUsers}
            value={{
              label: associatedUsers?.length
                ? "Company Contacts"
                : "No Company Contacts",
              value: "Company Contacts",
              hideAvatar: true,
              titleCN: "text-darkenedGreen text-sm",
            }}
            /**
             * Dropdown list with zero options is not recognized in onMenuOpen
             * This keeps the untracked list from interferring with cards around it
             */
            isDisabled={!associatedUsers?.length}
            onChange={onSelectContact}
            formatOptionLabel={formatOptionLabel}
            customStyle={customStyle}
            disableClear
            disableSearch
            className="px-2"
            hideDropdownIndicator
            hideIndicator
            maxMenuHeight={200}
          />
        </div>
      )}
    </>
  );
};

ContactRowButton.propTypes = {
  /**
   * Open Chat Func
   */
  openChat: PropTypes.func,
  /**
   * currentUser
   */
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    kind: PropTypes.string,
  }),
  /**
   * Sub title of resource
   * ie. { label: Type, Value: Pool House}
   */
  detail: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  /**
   * Contact Resource
   * ie. { name: {firstName: "", lastName: ""},
   */
  contact: PropTypes.shape({
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    id: PropTypes.string,
    kind: PropTypes.string,
    contactPoint: PropTypes.shape([]),
  }),
  /**
   * Company Resource
   * ie. { name: {firstName: "", lastName: ""},
   */
  company: PropTypes.shape({
    id: PropTypes.string,
    company: PropTypes.shape({
      value: PropTypes.string,
      valueType: PropTypes.string,
    }),
    reference: PropTypes.string,
  }),
  contacts: PropTypes.shape([]),
  dropdownActive: PropTypes.bool,
  setDropdownActive: PropTypes.func,
};

ContactRowButton.defaultProps = {
  detail: { label: "[Detail Label]", value: "[Detail Value]" },
  contact: undefined,
  company: undefined,
  dropdownActive: undefined,
  setDropdownActive: () => {},
  contacts: [],
  currentUser: {},
  openChat: () => {},
};

export default ContactRowButton;

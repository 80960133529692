// Framework Tools
import React from "react";
import PropTypes from "prop-types";

// Components
import TertiaryHeader from "../../TextHeaders/TertiaryHeader";

export default function RequestGridViewTypeCell({ typeData }) {
  // abstracts display property from type data
  const { display } = typeData;
  return (
    <div className="w-full h-ful flex flex-col justify-start flex-wrap px-2">
      <TertiaryHeader className="text-sm text-left">Type</TertiaryHeader>
      <p className="text-sm text-left">{display}</p>
    </div>
  );
}

// prop types
RequestGridViewTypeCell.propTypes = {
  typeData: PropTypes.shape({
    display: PropTypes.string,
  }),
};

// default props
RequestGridViewTypeCell.defaultProps = {
  typeData: {
    display: "",
  },
};

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { isEqual, isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import TabbedContainer from "../../TabbedContainer/TabbedContainer";
import Spinner from "../../Spinner/Spinner";
import useManagementConfiguration from "../../../../hooks/useManagementConfiguration";
import useManagementConfigurationReducer from "../../../../hooks/useManagementConfigurationReducer";
import useWorkflowsConfigurationReducer from "../../../../hooks/useWorkflowsConfigurationReducer";
import useWorkflowsConfiguration from "../../../../hooks/useWorkflowsConfiguration";
import { resolveFileRef } from "../../../../helpers/File";
import useDocumentsConfiguration from "../../../../hooks/useDocumentsConfiguration";
import useDocumentsConfigurationReducer from "../../../../hooks/useDocumentsConfigurationReducer";
import useSystemConfiguration from "../../../../hooks/useSystemConfiguration";
import useSystemConfigurationReducer from "../../../../hooks/useSystemConfigurationReducer";
import useFinancialsConfigurationReducer from "../../../../hooks/useFinancialsConfigurationReducer";
import useFinancialsConfiguration from "../../../../hooks/useFinancialsConfiguration";
import {
  formatDocumentConfigurationOriginalPayload,
  formatFinancialConfigurationOriginalPayload,
  formatManagementConfigurationOriginalPayload,
  formatSystemConfigurationOriginalPayload,
  formatWorkflowConfigurationOriginalPayload,
} from "../../../../helpers/Settings";
import useConfigurationLastUpdated from "../../../../hooks/useConfigurationLastUpdated";
import useAttributes from "../../../../hooks/useAttributes";
import useAttributesFormReducer from "../../../../hooks/useAttributesFormReducer";
import updateAttributes from "../../../../helpers/Attribute";
import useConfigurationPatch from "../../../../hooks/useConfigurationPatch";
import { toastError } from "../../../../helpers/Toast";
import whiteCrossIcon from "../../../assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../../assets/images/whiteExclamationIcon.svg";
import {
  useCreateTag,
  useGetTags,
  useRemoveTags,
  useUpdateTag,
} from "../../../../hooks/useTags";
import useTagsFormReducer from "../../../../hooks/useTagsFormReducer";
import SettingsAndConfigurationList from "../../../../Pages/Admin/SettingsAndConfigurationList";
import BillingManagement from "../../../../Pages/Admin/BillingManagement";
import MemberManagement from "../../../../Pages/Admin/MemberManagement";
import RolesAndPermissions from "../../../../Pages/Admin/RolesAndPermissions";
import {
  ADMIN_BILLING_PATH,
  ADMIN_ROLES_PERMISSIONS_PATH,
  MEMBERS_PATH,
  SETTINGS_PATH,
} from "../../../../constants";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import {
  documentSettingsSchema,
  financialSettingsSchema,
  managementSettingsSchema,
  systemSettingsSchema,
  workflowSettingsSchema,
} from "../../../../helpers/FormValidations";

const toastCloseIcon = <img src={whiteCrossIcon} alt="Close notice" />;
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const AccountAndAdminContent = ({
  isSaving,
  setIsSaving,
  isCancel,
  setIsCancel,
  activeIndex,
  onSave,
  onCancel,
  isEditingPlan,
  setIsEditingPlan,
  isEditingBillingCycle,
  setIsEditingBillingCycle,
  isEditingUserSeats,
  setIsEditingUserSeats,
  isCancellingSubscription,
  setIsCancellingSubscription,
  isExportingMemberList,
  setIsExportingMemberList,
}) => {
  const onUpdateTags = useRef();
  const history = useHistory();
  // hooks
  const { data: managementConfiguration } = useManagementConfiguration();
  const { data: workflowsConfiguration } = useWorkflowsConfiguration();
  const { data: documentsConfiguration } = useDocumentsConfiguration();
  const { data: systemConfiguration } = useSystemConfiguration();
  const { data: financialsConfiguration } = useFinancialsConfiguration();
  const [measurements, reloadMeasurements] = useAttributes();

  const { data: tagsData } = useGetTags();
  const addTag = useCreateTag();
  const removeTags = useRemoveTags();
  const updateTag = useUpdateTag();

  // tags
  const [tagsSettings, dispatchTagsSettings] = useTagsFormReducer();
  // attributes
  const [attributeSettings, dispatchAttributeSettings] =
    useAttributesFormReducer();
  // financials
  const [financialsSettings, dispatchFinancialsSettings] =
    useFinancialsConfigurationReducer();
  // management
  const [managementSettings, dispatchManagementSettings] =
    useManagementConfigurationReducer();
  // workflows
  const [workflowsSettings, dispatchWorkflowsSettings] =
    useWorkflowsConfigurationReducer();
  // documents
  const [documentsSettings, dispatchDocumentsSettings] =
    useDocumentsConfigurationReducer();
  // system
  const [systemSettings, dispatchSystemSettings] =
    useSystemConfigurationReducer();

  const correctMeasurements = useMemo(() => {
    return measurements?.map((item) => {
      if (item.fieldValues.length) {
        return {
          ...item,
          fieldValues: item.fieldValues.map((value) => ({
            label: value,
            value,
          })),
        };
      }
      if (item.unit?.fieldValues.length) {
        return {
          ...item,
          fieldType: "number-entry-with-unit",
          unit: {
            ...item.unit,
            fieldValues: item.unit.fieldValues.map((value) => ({
              label: value,
              value,
            })),
          },
        };
      }
      return item;
    });
  }, [measurements]);

  const [configurationLastUpdated] = useConfigurationLastUpdated();

  const [settingDetails, setSettingDetails] = useState({});
  const [noChangesMade, setNoChangesMade] = useState(true);
  const [isValid, setIsValid] = useState(true);

  const { mutate, isLoading } = useConfigurationPatch();

  const setEmployeeHandbook = useCallback(
    async (ref) => {
      if (ref) {
        const file = await resolveFileRef(ref);
        dispatchWorkflowsSettings({
          type: "changeHandbookFileName",
          value: file?.name,
        });
      }
    },
    [dispatchWorkflowsSettings]
  );

  const resetTagsSettings = useCallback(() => {
    dispatchTagsSettings({
      type: "reset",
      tags: tagsData?.tags,
    });
  }, [dispatchTagsSettings, tagsData]);

  const resetAttributeSettings = useCallback(() => {
    dispatchAttributeSettings({
      type: "reset",
      attributes: correctMeasurements,
    });
  }, [dispatchAttributeSettings, correctMeasurements]);

  const resetDocumentsSettings = useCallback(() => {
    dispatchDocumentsSettings({
      type: "reset",
      configuration: formatDocumentConfigurationOriginalPayload(
        documentsConfiguration
      ),
    });
  }, [dispatchDocumentsSettings, documentsConfiguration]);

  const resetFinancialsSettings = useCallback(() => {
    dispatchFinancialsSettings({
      type: "reset",
      configuration: formatFinancialConfigurationOriginalPayload(
        financialsConfiguration
      ),
    });
  }, [dispatchFinancialsSettings, financialsConfiguration]);

  const resetManagementSettings = useCallback(() => {
    dispatchManagementSettings({
      type: "reset",
      configuration: formatManagementConfigurationOriginalPayload(
        managementConfiguration
      ),
    });
  }, [dispatchManagementSettings, managementConfiguration]);

  const resetSystemSettings = useCallback(() => {
    dispatchSystemSettings({
      type: "reset",
      configuration:
        formatSystemConfigurationOriginalPayload(systemConfiguration),
    });
  }, [dispatchSystemSettings, systemConfiguration]);

  const resetWorkflowsSettings = useCallback(() => {
    const employeeHandbookRef =
      workflowsConfiguration.workflows?.employeeHandbookAcceptance?.document;

    dispatchWorkflowsSettings({
      type: "reset",
      configuration: formatWorkflowConfigurationOriginalPayload(
        workflowsConfiguration,
        employeeHandbookRef
      ),
    });

    setEmployeeHandbook(employeeHandbookRef);
  }, [dispatchWorkflowsSettings, setEmployeeHandbook, workflowsConfiguration]);

  useEffect(() => {
    setIsSaving?.(isLoading);
  }, [isLoading, setIsSaving]);

  useEffect(() => {
    if (isCancel) {
      resetDocumentsSettings();
      resetFinancialsSettings();
      resetManagementSettings();
      resetSystemSettings();
      resetWorkflowsSettings();
      resetAttributeSettings();
      resetTagsSettings();
      setIsCancel(false);
    }
  }, [
    isCancel,
    resetDocumentsSettings,
    resetFinancialsSettings,
    resetManagementSettings,
    resetSystemSettings,
    resetWorkflowsSettings,
    resetAttributeSettings,
    resetTagsSettings,
    setIsCancel,
  ]);

  useEffect(() => {
    if (!isEmpty(measurements)) {
      resetAttributeSettings();
    }
  }, [measurements, resetAttributeSettings]);

  useEffect(() => {
    if (!isEmpty(financialsConfiguration)) {
      resetFinancialsSettings();
    }
  }, [financialsConfiguration, resetFinancialsSettings]);

  useEffect(() => {
    if (!isEmpty(systemConfiguration)) {
      resetSystemSettings();
    }
  }, [resetSystemSettings, systemConfiguration]);

  useEffect(() => {
    if (!isEmpty(documentsConfiguration)) {
      resetDocumentsSettings();
    }
  }, [documentsConfiguration, resetDocumentsSettings]);

  useEffect(() => {
    if (!isEmpty(workflowsConfiguration)) {
      resetWorkflowsSettings();
    }
  }, [resetWorkflowsSettings, workflowsConfiguration]);

  useEffect(() => {
    if (!isEmpty(managementConfiguration)) {
      resetManagementSettings();
    }
  }, [managementConfiguration, resetManagementSettings]);

  useEffect(() => {
    if (!isEmpty(tagsData?.tags)) {
      resetTagsSettings();
    }
  }, [resetTagsSettings, tagsData?.tags]);

  const onUpdateAttributes = useCallback(async () => {
    const { errors } = await updateAttributes(attributeSettings, measurements);

    errors.forEach((e) => {
      toastError(e, toastErrorIcon, toastCloseIcon, uuidv4(), "mb-2");
    });
  }, [attributeSettings, measurements]);

  onUpdateTags.current = () => {
    // post
    const newTags = tagsSettings.filter((t) => t.wasAdded);
    newTags.forEach((t) => {
      addTag.mutate(t);
    });

    // delete
    const deletedTags = tagsSettings
      .filter((t) => t.wasRemoved && !t.wasAdded)
      .map((t) => t.reference);
    removeTags.mutate(deletedTags);

    // update
    const updatedTags = tagsSettings.filter((t) => t.wasUpdated && !t.wasAdded);

    Promise.all(
      updatedTags.map(async (t) => {
        const data = await updateTag.mutateAsync({
          id: t.id,
          prevTag: t,
          updatedTag: {
            ...t,
            label: t.currentLabel,
          },
        });

        return data;
      })
    );
  };

  const updateAllConfigurations = useCallback(() => {
    onUpdateAttributes();
    onUpdateTags?.current();

    mutate({
      patchDate: configurationLastUpdated,
      managementConfiguration,
      managementSettings,
      systemConfiguration,
      systemSettings,
      financialsConfiguration,
      financialsSettings,
      documentsConfiguration,
      documentsSettings,
      workflowsConfiguration,
      workflowsSettings,
      // Aditional functions to reload mesurements and wf templates
      reloadMeasurements,
    });
  }, [
    configurationLastUpdated,
    documentsConfiguration,
    documentsSettings,
    financialsConfiguration,
    financialsSettings,
    managementConfiguration,
    managementSettings,
    mutate,
    onUpdateAttributes,
    reloadMeasurements,
    systemConfiguration,
    systemSettings,
    workflowsConfiguration,
    workflowsSettings,
  ]);

  useEffect(() => {
    if (isSaving) {
      updateAllConfigurations();
    }
  }, [isSaving, updateAllConfigurations]);

  // Changes & validation check
  useDidMountEffect(() => {
    const isNoChanges =
      isEqual(
        systemSettings,
        formatSystemConfigurationOriginalPayload(systemConfiguration)
      ) &&
      isEqual(
        managementSettings,
        formatManagementConfigurationOriginalPayload(managementConfiguration)
      ) &&
      isEqual(
        workflowsSettings,
        formatWorkflowConfigurationOriginalPayload(workflowsConfiguration)
      ) &&
      isEqual(
        documentsSettings,
        formatDocumentConfigurationOriginalPayload(documentsConfiguration)
      ) &&
      isEqual(
        financialsSettings,
        formatFinancialConfigurationOriginalPayload(financialsConfiguration)
      ) &&
      isEqual(attributeSettings, correctMeasurements) &&
      isEqual(
        tagsSettings,
        tagsData?.tags?.map((tag) => ({
          ...tag,
          currentLabel: tag?.label,
        }))
      );
    setNoChangesMade(isNoChanges);

    const validationFields = [];
    validationFields.push(systemSettingsSchema.isValid(systemSettings));
    validationFields.push(managementSettingsSchema.isValid(managementSettings));
    validationFields.push(financialSettingsSchema.isValid(financialsSettings));
    validationFields.push(documentSettingsSchema.isValid(documentsSettings));
    validationFields.push(workflowSettingsSchema.isValid(workflowsSettings));
    Promise.all(validationFields).then((values) => {
      setIsValid(!values.some((field) => field === false));
    });
  }, [
    systemSettings,
    managementSettings,
    workflowsSettings,
    documentsSettings,
    financialsSettings,
    attributeSettings,
    tagsSettings,
  ]);

  const onTabClick = useCallback(
    (index) => {
      switch (index) {
        case 0:
          history.push(SETTINGS_PATH);
          break;
        case 1:
          history.push(ADMIN_BILLING_PATH);
          break;
        case 2:
          history.push(MEMBERS_PATH);
          break;
        case 3:
          history.push(ADMIN_ROLES_PERMISSIONS_PATH);
          break;
        default:
          break;
      }
    },
    [history]
  );

  const navigateToHolidaysSection = useCallback(() => {
    setSettingDetails({
      id: "holidays",
      title: "Holidays",
      type: "System",
      description: "Select the holidays and add custom holidays in EstateSpace",
      customBackNavigation: {
        id: "rateSheet",
        title: "Rate Sheet",
        type: "Financial",
        description:
          "Manage the default rate sheet for projects in EstateSpace",
      },
    });
  }, []);

  const tabs = [
    {
      title: "Settings & Configuration",
      content: (
        <SettingsAndConfigurationList
          managementSettings={managementSettings}
          dispatchManagementSettings={dispatchManagementSettings}
          attributeSettings={attributeSettings}
          dispatchAttributeSettings={dispatchAttributeSettings}
          systemSettings={systemSettings}
          dispatchSystemSettings={dispatchSystemSettings}
          financialsSettings={financialsSettings}
          dispatchFinancialsSettings={dispatchFinancialsSettings}
          documentsSettings={documentsSettings}
          dispatchDocumentsSettings={dispatchDocumentsSettings}
          tagsSettings={tagsSettings}
          dispatchTagsSettings={dispatchTagsSettings}
          navigateToHolidaysSection={navigateToHolidaysSection}
          onSave={onSave}
          onCancel={onCancel}
          isSaving={isSaving}
          isLoading={isLoading}
          settingDetails={settingDetails}
          setSettingDetails={setSettingDetails}
          noChangesMade={noChangesMade}
          isValid={isValid}
          setIsValid={setIsValid}
          resetSystemSettings={resetSystemSettings}
        />
      ),
    },
    {
      title: "Billing Management",
      content: (
        <BillingManagement
          isEditingPlan={isEditingPlan}
          setIsEditingPlan={setIsEditingPlan}
          isEditingBillingCycle={isEditingBillingCycle}
          setIsEditingBillingCycle={setIsEditingBillingCycle}
          isEditingUserSeats={isEditingUserSeats}
          setIsEditingUserSeats={setIsEditingUserSeats}
          isCancellingSubscription={isCancellingSubscription}
          setIsCancellingSubscription={setIsCancellingSubscription}
          isExportingMemberList={isExportingMemberList}
          setIsExportingMemberList={setIsExportingMemberList}
        />
      ),
    },
    {
      title: "Member Management",
      content: (
        <MemberManagement
          setIsExportingMemberList={setIsExportingMemberList}
          isExportingMemberList={isExportingMemberList}
          noChangesMade={noChangesMade}
        />
      ),
    },
    {
      title: "Roles & Permissions",
      content: <RolesAndPermissions />,
    },
  ];

  return (
    <>
      {isSaving && <Spinner />}
      <TabbedContainer
        tabs={tabs}
        activeIndex={activeIndex}
        onTabClick={onTabClick}
      />
    </>
  );
};

AccountAndAdminContent.propTypes = {
  isSaving: PropTypes.bool,
  setIsSaving: PropTypes.func,
  isCancel: PropTypes.bool,
  setIsCancel: PropTypes.func,
  activeIndex: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  isEditingPlan: PropTypes.bool,
  setIsEditingPlan: PropTypes.func,
  isEditingBillingCycle: PropTypes.bool,
  setIsEditingBillingCycle: PropTypes.func,
  isEditingUserSeats: PropTypes.bool,
  setIsEditingUserSeats: PropTypes.func,
  isCancellingSubscription: PropTypes.bool,
  setIsCancellingSubscription: PropTypes.func,
  isExportingMemberList: PropTypes.bool,
  setIsExportingMemberList: PropTypes.func,
};

AccountAndAdminContent.defaultProps = {
  isSaving: false,
  setIsSaving: undefined,
  isCancel: false,
  setIsCancel: undefined,
  activeIndex: false,
  onSave: undefined,
  onCancel: undefined,
  isEditingPlan: false,
  setIsEditingPlan: undefined,
  isEditingBillingCycle: false,
  setIsEditingBillingCycle: undefined,
  isEditingUserSeats: false,
  setIsEditingUserSeats: undefined,
  isCancellingSubscription: false,
  setIsCancellingSubscription: undefined,
  isExportingMemberList: false,
  setIsExportingMemberList: undefined,
};
export default AccountAndAdminContent;

import { useEffect, useMemo, useState } from "react";
import useReportsTemplates from "../../../../hooks/useReportsTemplates";
import { convertTemplatesInDropdownShape } from "../../../../helpers/Report/reportTemplatesFormatters";

const useTemplatesDropdownData = ({
  associationType,
  lockAssociationType,
  handleApplyTemplate,
  isDisabled,
}) => {
  const { data: reportTemplates = [], isLoading } = useReportsTemplates();

  const [dropdownValue, setDropdownValue] = useState(null);

  // Reset template dropdown if disabled or if template selected is a different type than the current association type
  useEffect(() => {
    const templateSourceType = dropdownValue?.template?.sourceType;

    if (
      isDisabled ||
      (associationType &&
        templateSourceType &&
        associationType !== templateSourceType)
    ) {
      setDropdownValue(null);
    }
  }, [associationType, dropdownValue, isDisabled]);

  // Transform templates to display them in dropdown
  const reportTemplatesOptions = useMemo(
    () =>
      convertTemplatesInDropdownShape(
        reportTemplates,
        associationType,
        lockAssociationType
      ),
    [reportTemplates, associationType, lockAssociationType]
  );

  const handleSelectedValue = (optionSelected) => {
    if (!optionSelected) {
      setDropdownValue(null);
      return;
    }

    const { template } = optionSelected;
    setDropdownValue(optionSelected);

    // Apply template to ReportForm `IncludeInformation`
    handleApplyTemplate(template);
  };

  return {
    isLoading,
    reportTemplatesOptions,
    dropdownValue,
    handleSelectedValue,
  };
};

export default useTemplatesDropdownData;

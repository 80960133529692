import PropTypes from "prop-types";
import React from "react";
import BaseActionButton from "./BaseActionButton";
import CloseElement from "./CloseElement";

const CloseButton = ({ className, onClick, animated, color }) => (
  <BaseActionButton
    Icon={CloseElement}
    className={className}
    onClick={onClick}
    animated={animated}
    color={color}
  />
);

CloseButton.propTypes = {
  /**
   * classes to apply to the button
   */
  className: PropTypes.string,
  /**
   * function called when the button is clicked
   */
  onClick: PropTypes.func,
  animated: PropTypes.bool,
  color: PropTypes.string,
};

CloseButton.defaultProps = {
  className: undefined,
  onClick: undefined,
  animated: false,
  color: undefined,
};

export default CloseButton;

import React from "react";
import PropTypes from "prop-types";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import TertiaryHeader from "../TextHeaders/TertiaryHeader";
import IconLabel from "../IconLabel/IconLabel";
import SecondaryButton from "../Buttons/SecondaryButton";
import PlusIcon from "../../assets/images/plusIcon.svg";
import FileIcon from "../../assets/images/fileIcon.svg";
import DownloadIcon from "../../assets/images/downloadIcon.svg";

const DocumentItem = ({ title, content, isUploaded, uploadedDate }) => {
  return (
    <div className="border-gray-200 border rounded-md pt-6 pb-6 pl-12 pr-6 flex flex-row justify-between mb-4">
      <div className="col-span-9">
        <SecondaryHeader className="mb-4">{title}</SecondaryHeader>
        <TertiaryHeader className="mb-4">{content}</TertiaryHeader>
        <IconLabel
          text="Download Template"
          icon={DownloadIcon}
          direction="right"
        />
        <div className="flex" />
      </div>
      {isUploaded ? (
        <div className="flex flex-col justify-between items-end">
          <IconLabel text={uploadedDate} icon={FileIcon} direction="right" />
          <SecondaryButton
            dropdownItems={[
              {
                onClick: () => {},
                title: "option 1",
              },
            ]}
            title="Change"
          />
        </div>
      ) : (
        <div className="flex flex-col justify-between items-end">
          <SecondaryButton
            dropdownItems={[
              {
                onClick: () => {},
                title: "option 1",
              },
            ]}
            title="Add"
            iconLeft={<img alt="" src={PlusIcon} />}
          />
        </div>
      )}
    </div>
  );
};

DocumentItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  isUploaded: PropTypes.bool,
  uploadedDate: PropTypes.string,
};

DocumentItem.defaultProps = {
  isUploaded: false,
  uploadedDate: null,
};

export default DocumentItem;

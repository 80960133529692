import { useCallback, useMemo } from "react";
import useAssociatedFiles from "../../../../hooks/useAssociatedFiles";
import { toastError, toastMessage } from "../../Toast/Toast";
import { onUpdateFile } from "../../../../helpers/File";

const useDocumentFilesData = ({ document, patchDocument }) => {
  const params = useMemo(
    () => ({ association: `Document/${document?.id}` }),
    [document]
  );

  const {
    associatedFiles,
    addFiles,
    removeFilesAndUpdateApi,
    cloneFile,
    patchFile,
  } = useAssociatedFiles(params);

  const onAddFilesCallback = useCallback(
    async (filesUploaded) => {
      // update associated files state
      addFiles(filesUploaded);

      const updatedFiles = [
        ...document.files,
        ...filesUploaded.map((file) => ({
          ref: file.reference,
          category: file.category,
        })),
      ];

      const updatedDocument = {
        ...document,
        files: updatedFiles,
      };
      // patch resource
      patchDocument(updatedDocument);
    },
    [addFiles, document, patchDocument]
  );

  const updateDocumentFiles = useCallback(
    (fileRefs) => {
      const updatedFiles = document.files.filter(
        (file) => !fileRefs.includes(file.ref)
      );

      const updatedDocument = {
        ...document,
        files: updatedFiles,
      };

      // patch resource
      patchDocument(updatedDocument);
    },
    [document, patchDocument]
  );

  const handleFileClone = useCallback(
    (fileId) => {
      cloneFile(fileId)
        .then((clonedFile) => {
          const updatedDocument = {
            ...document,
            files: [
              ...document?.files,
              { ref: clonedFile.reference, category: clonedFile.category },
            ],
          };

          return patchDocument(updatedDocument, true);
        })
        .then(() =>
          toastMessage(`Recent file successfully attached to document`)
        )
        .catch(() => {
          toastError(`Error attaching recent file`);
          // remove created files if PATCH fails
          removeFilesAndUpdateApi([`File/${fileId}`]);
        });
    },
    [cloneFile, document, patchDocument, removeFilesAndUpdateApi]
  );

  const handleUpdateFile = useCallback(
    ({ originalResource, currentTags, name }) => {
      onUpdateFile({ originalResource, currentTags, name, patchFile });
    },
    [patchFile]
  );

  return {
    handleUpdateFile,
    handleFileClone,
    associatedFiles,
    updateDocumentFiles,
    onAddFilesCallback,
    removeFilesAndUpdateApi,
  };
};

export default useDocumentFilesData;

import { useHistory, useLocation } from "react-router";
import { PAGE_NOT_FOUND } from "../../constants";

const useQueryNotFoundNavigation = ({ error }) => {
  const history = useHistory();
  const location = useLocation();

  const errorStatusCode = error?.response?.status;

  // Retrieve the resource link and remove the "/" character
  const path = location.pathname.slice(1);

  const userNotFound = path.includes("contacts") && errorStatusCode === 422;

  // 403 = resource has been removed
  // 404 = resource not found
  if (errorStatusCode === 403 || errorStatusCode === 404 || userNotFound) {
    // Using `replace` to block navigate back to broken page
    history.replace(`${PAGE_NOT_FOUND}?resource=${path}`, { errorStatusCode });
  }
};

export default useQueryNotFoundNavigation;

import React from "react";
import { timeUnits } from "../../../../constants";
import AddAttachment from "../../AssetForms/AddAttachment";
import PrimaryButton from "../../Buttons/PrimaryButton";
import Dropdown from "../../Dropdown/Dropdown";
import Input from "../../Input/Input";
import ModalWrapper from "../ModalWrapper";
import useMarkCompleteData from "./useMarkCompleteData";

const MarkComplete = ({ modalData }) => {
  const {
    taskForm,
    valid,
    comment,
    setComment,
    handleFilesUpdated,
    removeAttachment,
    handleChangeDuration,
    handleMarkComplete,
  } = useMarkCompleteData({ modalData });

  return (
    <ModalWrapper
      width="500px"
      modalData={modalData}
      resourceName="Mark Complete?"
      hideActions
      alert
      buttons={
        <div className="flex gap-4 w-full">
          {/* <SecondaryButton title="Cancel" onClick={close} /> */}
          <PrimaryButton
            saveButtonTitle="Confirm"
            saveButton
            onClick={() => handleMarkComplete()}
            disabled={!valid}
          />
        </div>
      }
    >
      <>
        <h1
          className="text-xl font-semibold tracking-wider text-gray-400 truncate"
          title={taskForm?.name}
        >
          {taskForm?.name}
        </h1>
        <div
          className="px-6 py-5 flex flex-col gap-6 relative"
          style={{
            border: "1px solid #CACACA",
            borderRadius: "6px",
          }}
        >
          <div>
            <span className="font-semibold text-gray-450">Duration</span>
            <Input
              placeholder="Duration"
              type="number"
              value={taskForm?.duration?.value}
              onChange={(val) =>
                handleChangeDuration("value", parseInt(val, 10))
              }
            />
          </div>
          <div className="relative">
            <span className="font-semibold text-gray-450">Time Unit</span>
            <Dropdown
              options={timeUnits}
              value={timeUnits?.find(
                (item) => item.value === taskForm?.duration?.typeOfDuration
              )}
              onChange={(val) =>
                handleChangeDuration("typeOfDuration", val.value)
              }
              disableSort
            />
          </div>
          <div>
            <span className="font-semibold text-gray-450">Comment</span>
            <textarea
              style={{
                color: "#585858",
              }}
              rows="4"
              className="bg-green-50 w-full p-4 rounded border border-gray-150 resize-none"
              onChange={(e) => setComment(e.target.value)}
              value={comment}
            />
          </div>
          <AddAttachment
            files={taskForm?.files}
            onFilesAdded={handleFilesUpdated}
            onRemove={removeAttachment}
          />
        </div>
      </>
    </ModalWrapper>
  );
};

export default MarkComplete;

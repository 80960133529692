/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { hours } from "../../../constants";
import { getWeek } from "../../../helpers/Calendar";
import WeekHeader from "./Week/WeekHeader";
import WeekBody from "./Week/WeekBody";
import WeekEventMap from "./Week/WeekEventMap";
import { DateIsBetween } from "../../../helpers/Dates";
import smoothScrollTo from "../../../helpers/smoothScroll";
import { useAppState } from "../../../state/appState";

const WeekCalendar = ({
  now,
  events,
  handleEventClick,
  filter,
  offset,
  setOffset,
}) => {
  const [{ calendarTimezone }] = useAppState();
  const week = getWeek(now, calendarTimezone);

  const currentWeek = DateIsBetween(
    week[0].iso,
    week[6].iso,
    moment.tz(new Date(), calendarTimezone).format(),
    calendarTimezone
  );

  const bodyRef = useRef(null);

  /**
   * Scroll to current time of day on render
   */
  useEffect(() => {
    if (bodyRef.current) {
      smoothScrollTo("now-indicator");
    }
  }, []);

  return (
    <div className="w-full h-full mb-5">
      <WeekHeader
        values={week}
        filter={filter}
        events={events}
        handleEventClick={handleEventClick}
        offset={offset}
        setOffset={setOffset}
      />
      <div
        style={{ maxHeight: "1000px" }}
        className="overflow-y-scroll hide-scrollbar relative mb-1"
        ref={bodyRef}
        id="week-calendar"
      >
        <WeekBody hours={hours} />
        <WeekEventMap
          week={week}
          filter={filter}
          events={events}
          handleEventClick={handleEventClick}
          currentWeek={currentWeek}
        />
      </div>
    </div>
  );
};

WeekCalendar.propTypes = {
  now: PropTypes.string,
  events: PropTypes.shape({}),
  handleEventClick: PropTypes.func,
  filter: PropTypes.func,
  offset: PropTypes.number,
  setOffset: PropTypes.func,
};

WeekCalendar.defaultProps = {
  now: undefined,
  events: {},
  handleEventClick: undefined,
  filter: undefined,
  offset: 0,
  setOffset: () => {},
};

export default WeekCalendar;

import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import Chevron from "../Chevron/Chevron";

const pageCN = (current) => cntl`
${current ? "border border-darkenedGreen text-darkenedGreen" : "text-gray-450"}
w-8
h-8
font-semibold
`;

const Pagination = ({ next, prev, canPrev, canNext, count, page, goto }) => {
  const showPages = 12;
  const totalPages = count;
  const middlePosition = showPages / 2 + 1;

  const renderPageLinks = () => {
    const links = [];

    let start = 0;

    if (totalPages <= showPages) {
      start = 0;
    } else if (page < middlePosition) {
      start = 0;
    } else if (page >= totalPages - middlePosition) {
      start = totalPages - showPages;
    } else {
      start = page - middlePosition + 1;
    }

    const end = Math.min(start + showPages, totalPages);

    for (let i = start; i < end; i += 1) {
      links.push(
        <button
          type="button"
          key={i}
          className={pageCN(i === page)}
          onClick={() => goto(i)}
        >
          {i + 1}
        </button>
      );
    }

    return links;
  };

  return (
    <div className="flex gap-3 items-center justify-center pt-4">
      {count > showPages ? (
        <button
          type="button"
          className={`${!canPrev && "invisible"}`}
          onClick={prev}
          aria-label="previous page"
        >
          <Chevron
            color="#191919"
            className={`h-3 transform rotate-180 ${!canPrev && "invisible"}`}
            onClick={prev}
          />
        </button>
      ) : null}
      {renderPageLinks()}
      {count > showPages ? (
        <button
          type="button"
          className={`${!canNext && "invisible"}`}
          onClick={next}
          aria-label="next page"
        >
          <Chevron color="#191919" className="h-3" />
        </button>
      ) : null}
    </div>
  );
};

Pagination.propTypes = {
  next: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  canPrev: PropTypes.bool.isRequired,
  canNext: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  goto: PropTypes.func.isRequired,
};

export default Pagination;

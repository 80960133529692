import Api, { SopAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import {
  configurationKeys,
  sopKeys,
  tagKeys,
} from "../config/reactQuery/queryKeyFactory";
import { toastError, toastMessage } from "../stories/Components/Toast/Toast";
import useAuthenticatedQueries from "./useAuthenticatedQueries";

const fetchSopById = async (sopId, version) => {
  let queryString = `Sop/${sopId}`;

  // Append version query parameter if version is given
  if (version) {
    queryString += `?v=${version}`;
  }

  const { data } = await Api.getByRef(queryString);
  return data;
};

const patchSop = async ({ id, prevSop, updatedSop }) => {
  const { data } = await SopAPI.patch(id, updatedSop, prevSop);
  return data;
};

/**
 * Query hook to fetch single SOP by id
 * @param {string} sopId - sop id
 * @param {string} version - sop version
 * @param {Function} select - custom react-query selector/subscriber
 * @returns query hook
 */
export const useSopById = (sopId, version, select) => {
  const queryKey = version
    ? sopKeys.byVersion(sopId, version)
    : sopKeys.sopDetail(sopId);

  return useAuthenticatedQuery({
    queryKey,
    queryFn: () => fetchSopById(sopId, version),
    select,
    onError: (error) => {
      console.error("Error getting SOP", error);
    },
  });
};

/**
 * Query hook to fetch multiple sops by their id and version
 * @param {string} sopIdsAndVersion - [{ sopId, sopVersion }] list of sop id/version
 * @returns query hook
 */
export const useSopsByIdAndVersion = (sopIdsAndVersion) => {
  return useAuthenticatedQueries(
    // react-qeury v3 format. Need to change when upgrading RQ version
    sopIdsAndVersion?.map((sop) => ({
      queryKey: sopKeys.byVersion(sop.sopId, sop.sopVersion),
      queryFn: () => fetchSopById(sop.sopId, sop.sopVersion),
      onError: (error) => {
        console.error("Error getting SOP", error);
      },
    }))
  );
};

/**
 * Prefetch an old sop if `version` is given
 * @param {string} sopId - Sop id
 * @param {string} version - Sop version
 */
export const useSopByIdPrefetch = (sopId, version) => {
  const queryClient = useQueryClient();

  const queryKey = sopKeys.byVersion(sopId, version);

  if (sopId && version) {
    queryClient.prefetchQuery({
      queryKey,
      queryFn: () => fetchSopById(sopId, version),
    });
  }
};

/**
 * Mutation hook to patch single SOP
 * @param {string} mutationKey mutation key to track process
 * @returns mutation hook
 */
export const usePatchSop = (mutationKey) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey,
    mutationFn: patchSop,
    onSuccess: (_, { updatedSop, onAdditionalSuccessCallback }) => {
      onAdditionalSuccessCallback?.();

      // update tags in s&c
      queryClient.invalidateQueries(tagKeys.tags);

      toastMessage(`Changes applied successfully to ${updatedSop?.name}`);
    },
    onError: (error, { prevSop, onAdditionalErrorCallback }) => {
      onAdditionalErrorCallback?.();

      toastError(`Error applying changes to ${prevSop?.name}`);
      console.error(error);
    },
    onSettled: () => {
      queryClient.invalidateQueries(sopKeys.sops);
      // Invalidate system settings to update sopcategories counter
      queryClient.invalidateQueries(configurationKeys("system"));
    },
  });
};

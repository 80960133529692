import React from "react";
import CreateEditEventForm from "../../EsForm/CreateEditEventForm";
import ModalWrapper from "../ModalWrapper";
import useCreateEventModalData from "./useCreateEventModalData";

const EventEditCreateModal = ({ modalData }) => {
  const {
    showConfirm,
    formState,
    allDayData,
    formIsValid,
    updateFormState,
    handleEventSubmit,
  } = useCreateEventModalData({ modalData });

  return (
    <ModalWrapper
      formData={formState}
      modalData={modalData}
      resourceName="Event"
      width="1100px"
      title="Add Event"
      primaryButtonOnClick={handleEventSubmit}
      disabled={!formIsValid}
      showConfirm={showConfirm}
    >
      <CreateEditEventForm
        formData={{
          formState,
          allDayData,
          updateFormState,
          handleEventSubmit,
        }}
        associationDisabled={false}
        recurrenceDisabled={false}
      />
    </ModalWrapper>
  );
};

export default EventEditCreateModal;

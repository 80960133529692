import { useState, useEffect } from "react";
import { FileAPI } from "@griffingroupglobal/eslib-api";

function useSubmittalFiles(fileStrings) {
  const [resolvedFiles, setResolvedFiles] = useState([]);

  useEffect(() => {
    async function fetchResolvedFiles() {
      try {
        const allFilesData = await FileAPI.get({
          params: { reference: fileStrings?.toString() },
        });

        if (allFilesData && allFilesData.data?.entries) {
          const files = allFilesData.data.entries.map((item) => item.resource);
          setResolvedFiles(files);
        }
      } catch (error) {
        console.error("Error fetching resolved files:", error);
      }
    }

    if (fileStrings.length > 0) {
      fetchResolvedFiles();
    } else {
      setResolvedFiles([]);
    }
  }, [fileStrings]);

  return resolvedFiles;
}

export default useSubmittalFiles;

import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import {
  CALENDAR_DATE_NUMBER_OFFSET,
  CALENDAR_STROKE,
} from "../../../../constants";

/**
 *
 * @param {Array} values - two dimensional array containing every week of the current month
 * @returns Graph of month
 */

const CalendarBody = ({ values, handleCreateModalOpen }) => {
  /**
   * Note: Event Container is 80% of the cell
   */
  const today = useCallback((iso) => {
    const day = moment().startOf("day");
    return day.isSame(iso);
  }, []);

  const dayColor = useCallback(
    (column) => {
      switch (true) {
        case today(column.iso):
          return "rgba(2, 125, 96, .8)";
        case column?.prev || column?.next:
          return "#CACACA";
        default:
          return "#33333";
      }
    },
    [today]
  );

  return (
    <g id="body-cells" transform="translate(0,10)">
      {Array.from({ length: values.length }, (_, i) => (
        <g id={`week-${i}-row`} key={`week-${i}-row`}>
          {Array.from({ length: 7 }, (__, d) => (
            <g
              id={`day-${values[i][d].iso}`}
              key={`day-${values[i][d].iso}`}
              onClick={() => {
                handleCreateModalOpen(values[i][d].iso);
              }}
            >
              <rect
                // x = column * cell width
                x={d * 40}
                // y = row * cell height
                y={i * 35}
                // width = cell width
                width={40}
                // height = cell height
                height={35}
                fill={today(values[i][d].iso) ? "#eeeeee" : "white"}
                stroke={CALENDAR_STROKE}
                strokeWidth="0.2"
              />
              <text
                // x = column * cell width + (1/2 cell width)
                x={d * 40 + 20}
                // y = row * cell height + (20% of cell)
                y={i * 35 + CALENDAR_DATE_NUMBER_OFFSET}
                fontSize={40 * 0.1}
                fill={dayColor(values[i][d])}
                textAnchor="middle"
              >
                {values[i][d].day}
              </text>
            </g>
          ))}
        </g>
      ))}
    </g>
  );
};

CalendarBody.propTypes = {
  handleCreateModalOpen: PropTypes.func,
  values: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        day: PropTypes.string,
        next: PropTypes.bool,
        prev: PropTypes.bool,
        iso: PropTypes.string,
      })
    )
  ),
};

CalendarBody.defaultProps = {
  values: [],
  handleCreateModalOpen: () => {},
};

export default CalendarBody;

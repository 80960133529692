import { useCallback, useEffect, useMemo, useState } from "react";
import resourceOptions from "../../../../helpers/Report/resourceOptions";

const { defaultOptions, pillsSection } = resourceOptions;

const useIncludeInformationData = ({
  associationType,
  assetsCategoriesData,
  includeInformationData,
  setAssetsCategoriesData,
  setIncludeInformationData,
}) => {
  // State that holds options in the Include Information section
  const [sectionStates, setSectionStates] = useState(defaultOptions);

  // This effect listen for templates selected from `TemplateDropdown`
  // and loads include information section with data from template
  useEffect(() => {
    if (includeInformationData) {
      setSectionStates(includeInformationData);
    }
  }, [includeInformationData]);

  // Reset sectionStates when associationType changes
  useEffect(() => {
    setSectionStates(defaultOptions);
    setAssetsCategoriesData([]);
  }, [associationType, setAssetsCategoriesData]);

  // This effect will inform parent component about changes in the Include Section.
  useEffect(() => {
    setIncludeInformationData(sectionStates);
  }, [sectionStates, setIncludeInformationData]);

  const handleCheckboxChange = (key, checked) => {
    setSectionStates((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        selected: checked,
      },
    }));
  };

  const handleDropdownChange = (key, selectedOption) => {
    setSectionStates((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        options: selectedOption.map((option) => option.value),
      },
    }));
  };

  /**
   * Add/Remove pill selected in state with the given `key`
   */
  const handlePillSelected = (key, selectedPill) => {
    setSectionStates((prev) => {
      const currentSection = prev[key];
      const currentStatus = currentSection?.status || [];

      // If pill exists is removed, otherwise is added to the given `key` state
      const updatedStatus = currentStatus.includes(selectedPill)
        ? currentStatus.filter((pill) => pill !== selectedPill)
        : [selectedPill, ...currentStatus];

      const updatedSection = {
        ...currentSection,
        status: updatedStatus,
      };

      return {
        ...prev,
        [key]: updatedSection,
      };
    });
  };

  /**
   * Generate checkboxes based on `associationType`
   */
  const generateCheckboxesSection = (label, key, checked, onChange) => ({
    label,
    isChecked: checked,
    onChange: (isChecked) => onChange(key, isChecked),
  });

  /**
   * Generate a checkbox with its dropdown based on custom options
   */
  const generateDropdownSection = useCallback(
    (type = "property", key, label, defaultSelected = false) => {
      const resourceMapOptions = resourceOptions[type][key];

      return {
        label,
        checkboxDisabled: defaultSelected,
        isChecked: sectionStates[key]?.selected,
        dropdownDisabled: !sectionStates[key]?.selected,
        dropdownOptions: resourceMapOptions,
        value: sectionStates[key]?.options?.map((value) =>
          resourceMapOptions.find((opt) => opt.value === value)
        ),
        pillOptions: pillsSection[key],
        pillValue: sectionStates[key]?.status,
        menuPlacement: "top",
        isMulti: true,
        disableAllOption: key === "expenses",
        checkboxOnChange: (checked) => handleCheckboxChange(key, checked),
        dropdownOnChange: (ddOptions) => handleDropdownChange(key, ddOptions),
        onSelectedPill: (pillSelected) => handlePillSelected(key, pillSelected),
      };
    },
    [sectionStates]
  );

  /**
   * Generate an Asset categories checkbox with their dropdown
   */
  const generateAssetCategoriesSection = useCallback(
    () => ({
      label: "Assets",
      isChecked: sectionStates?.assets?.selected,
      dropdownDisabled: !sectionStates?.assets?.selected,
      value: assetsCategoriesData,
      menuPlacement: "top",
      isMulti: true,
      checkboxOnChange: (checked) => handleCheckboxChange("assets", checked),
      dropdownOnChange: setAssetsCategoriesData,
    }),
    [sectionStates?.assets, assetsCategoriesData, setAssetsCategoriesData]
  );

  // Checkboxes in top of dropdowns
  const checkboxesSection = useMemo(() => {
    const checkboxes = [
      ...(associationType !== "asset"
        ? [
            generateCheckboxesSection(
              "Spaces",
              "spaces",
              sectionStates?.spaces?.selected,
              handleCheckboxChange
            ),
          ]
        : []),
      generateCheckboxesSection(
        "Media",
        "media",
        sectionStates?.media?.selected,
        handleCheckboxChange
      ),
      generateCheckboxesSection(
        "Files",
        "files",
        sectionStates?.files?.selected,
        handleCheckboxChange
      ),
      generateCheckboxesSection(
        "Finances",
        "finances",
        sectionStates?.finances?.selected,
        handleCheckboxChange
      ),
      ...(associationType === "project"
        ? [
            generateCheckboxesSection(
              "Budget",
              "budget",
              sectionStates?.budget?.selected,
              handleCheckboxChange
            ),
          ]
        : []),
    ];

    // Returns an array
    return checkboxes;
  }, [
    associationType,
    sectionStates?.spaces,
    sectionStates?.media,
    sectionStates?.files,
    sectionStates?.finances,
    sectionStates?.budget,
  ]);

  /**
   * This is a compute array of options that UI will render.
   */
  const dropdownsSection = useMemo(() => {
    // Array of dynamic options based on `associationType`
    return [
      generateDropdownSection(associationType, "details", "Details", true),
      generateDropdownSection(associationType, "tasks", "Tasks"),
      ...(associationType !== "asset"
        ? [generateAssetCategoriesSection()]
        : []),
      generateDropdownSection(associationType, "tickets", "Service Requests"),
      generateDropdownSection(associationType, "calendar", "Calendar"),
      generateDropdownSection(associationType, "expenses", "Expenses"),

      // Aditional options for Properties && Projects
      ...(associationType !== "asset"
        ? [generateDropdownSection(associationType, "workflows", "Workflows")]
        : []),

      // Aditional options for Projects
      ...(associationType === "project"
        ? [
            generateDropdownSection("project", "submittals", "Submittals"),
            generateDropdownSection(
              "project",
              "financialDocs",
              "Financial Documents"
            ),
          ]
        : []),
    ];
  }, [
    associationType,
    generateDropdownSection,
    generateAssetCategoriesSection,
  ]);

  /**
   * Generate classnames for every checkbox - dropdown iteration
   * based on type of section.
   */
  const getDropdownSectionClassnames = useCallback((section) => {
    const classname =
      section.label === "Financial Documents" ? "" : "items-center";
    return `grid grid-cols-3 gap-2 ${classname}`;
  }, []);

  return {
    checkboxesSection,
    dropdownsSection,
    getDropdownSectionClassnames,
  };
};

export default useIncludeInformationData;

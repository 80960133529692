import React from "react";
import requiredIcon from "../../../../assets/images/requiredIcon.svg";
import TimeSheetTooltipCard from "../../../TimeSheetTable/TimeSheetTooltipCard";
import Input from "../../../Input/Input";
import useViewEntrySectionData from "./useViewEntrySectionData";

const TsViewEntrySection = ({
  entriesForEditing,
  projectsState,
  projectDict,
  projectsDD,
  financialsConfiguration,
  filteredCategoriesByProjectRate,
  isBeforeHire,
  setToolTip,
  formatEntryValue,
  toolTip,
  handleEntryNoteChange,
  handleBillableChange,
  roundEntryToNearestQuarter,
  showActiveIcon,
  handleEntryChange,
}) => {
  const { tsEntryData } = useViewEntrySectionData({
    entriesForEditing,
    projectsState,
    projectDict,
    projectsDD,
    financialsConfiguration,
    filteredCategoriesByProjectRate,
    isBeforeHire,
    toolTip,
  });

  return (
    <>
      {tsEntryData.flat().map((item) => {
        if (item.error) {
          return (
            <div
              key={item.key}
              className="flex items-center col-span-7 p-2 rounded-lg"
            >
              <img
                src={requiredIcon}
                style={{
                  width: "30px",
                  height: "30px",
                }}
                className="mr-2 invisible"
                alt="alert"
              />
              <h1
                className="es-dark-grey es-medium invisible"
                style={{ width: "500px" }}
              >
                {item.error}
              </h1>
            </div>
          );
        }

        const { disabled, entry, key } = item;

        return (
          <div className="col-span-1 text-gray-700 font-light" key={entry?.id}>
            <div
              className="rounded-lg"
              style={{
                maxWidth: "78px",
              }}
            >
              <Input
                disabled={disabled}
                key={entry?.id}
                value={
                  isBeforeHire(entry?.date) ? "" : formatEntryValue(entry.value)
                }
                onBlur={() => roundEntryToNearestQuarter(entry, key)}
                onChange={(val) => handleEntryChange(val, entry, key)}
                className="text-gray-200 font-normal w-full rounded-xl"
                activeIcon={showActiveIcon(entry)}
                onFocus={() => setToolTip({ open: true, id: entry?.id })}
                inputContainerClassName="rounded-lg"
                title={entry?.date}
                disableClear
                tooltipElement={
                  toolTip.open &&
                  toolTip.id === entry?.id && (
                    <TimeSheetTooltipCard
                      date={entry?.date}
                      note={entry?.note}
                      isBillable={entry?.isBillable}
                      onChangeBillable={(val) => {
                        handleBillableChange(!val, entry, key);
                      }}
                      onChangeNote={(val) =>
                        handleEntryNoteChange(val, entry, key)
                      }
                      disabled={false}
                      onClose={() => setToolTip({ open: false, id: null })}
                    />
                  )
                }
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default TsViewEntrySection;

import moment from "moment";
import { MOMENT_UTC_ES_FORMAT } from "../../constants";

const getUniqueTasksWithClosestStartDate = (tasks) => {
  const uniqueTasks = {};
  const currentDate = moment().format(MOMENT_UTC_ES_FORMAT);

  tasks.forEach((task) => {
    const { id, instanceStartDate } = task;

    if (!uniqueTasks[id]) {
      uniqueTasks[id] = task;
      return;
    }

    if (
      moment(instanceStartDate).isBefore(currentDate) &&
      !moment(instanceStartDate).isAfter(currentDate)
    ) {
      uniqueTasks[id] = task;
    }

    if (
      moment(uniqueTasks[id].instanceStartDate).isBefore(currentDate) &&
      moment(instanceStartDate).isAfter(currentDate)
    ) {
      uniqueTasks[id] = task;
    }
  });

  // Return an array of values from the uniqueTasks object
  return Object.values(uniqueTasks);
};

export default getUniqueTasksWithClosestStartDate;

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const DateCell = ({ value, format }) => {
  return (
    <div className="flex">
      <p>{value ? moment(value).format(format) : ""}</p>
    </div>
  );
};

DateCell.propTypes = {
  value: PropTypes.string,
  format: PropTypes.string,
};

DateCell.defaultProps = {
  value: undefined,
  format: "MMMM Do YYYY, h:mm a",
};

export default DateCell;

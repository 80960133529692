import React from "react";
import * as yup from "yup";
import PropTypes from "prop-types";
import Input from "../../Input/Input";
import Dropdown from "../../Dropdown/Dropdown";
import Checkbox from "../../Checkbox/Checkbox";
import {
  TIME_OFF_TYPES,
  ACCRUAL_TYPES,
  PTO_TYPES,
  MAX_ACCRUAL_OPTIONS,
} from "../../../../constants";
import usePtoGeneralSectionData from "./usePtoGeneralSectionData";
import DatePicker from "../../DatePicker/DatePicker";

const PtoGeneralSection = ({
  systemSettings,
  dispatchSystemSettings,
  setIsValid,
}) => {
  const {
    onSelectPtoGeneralType,
    onChangePtoGeneralAccrualType,
    onChangePtoGeneralTypeNumHours,
    onChangePtoGeneralTypeMaxRollOver,
    onChangePtoGeneralAvailabilityDate,
    onChangePtoGeneralTypeAvailabilityDays,
    onChangePtoGeneralTypeMaxAccrual,
    onChangePtoGeneralTypeNegativeValues,
    isLocked,
  } = usePtoGeneralSectionData({
    systemSettings,
    dispatchSystemSettings,
    setIsValid,
  });

  return (
    <div className="overflow-x-scroll">
      <div className="grid grid-cols-es-pto pt-4 px-6 gap-2 border-b pb-2 mb-2">
        <h3 className="font-semibold text-black">Time Off Type</h3>
        <h3 className="font-semibold text-black">Accrual Type</h3>
        <h3 className="font-semibold text-black">Hours Annually</h3>
        <h3 className="font-semibold text-black">Max. Accrual</h3>
        <h3 className="font-semibold text-black">Max. Roll-over Annually</h3>
        <h3 className="font-semibold text-black">Availability Date</h3>
        <h3 className="font-semibold text-black">Wait Period</h3>
        <h3 className="font-semibold text-black">Negative Balance</h3>
      </div>
      {systemSettings?.ptoGeneralTypes?.map((ptoType, typeIndex) => {
        return (
          <div
            className="grid grid-cols-es-pto w-full py-3 gap-2 px-6"
            key={ptoType?.type}
          >
            <div className="self-center">
              <Checkbox
                checked={ptoType.selected}
                size={5}
                label={
                  TIME_OFF_TYPES.find((type) => type.value === ptoType?.type)
                    .label
                }
                onChange={(val) =>
                  onSelectPtoGeneralType(typeIndex, ptoType?.type, val)
                }
                labelClassName="text-base font-normal"
                disabled={isLocked}
              />
            </div>
            <div>
              {ptoType.selected ? (
                <Dropdown
                  value={ACCRUAL_TYPES.find(
                    (type) => type.value === ptoType?.accrualType
                  )}
                  onChange={(val) =>
                    onChangePtoGeneralAccrualType(val, typeIndex)
                  }
                  options={ACCRUAL_TYPES}
                  disableClear
                  isDisabled={
                    !ptoType.selected ||
                    ptoType?.type === PTO_TYPES[0] ||
                    isLocked
                  }
                />
              ) : (
                <Input
                  inputContainerClassName="bg-backgroundGrey border-0"
                  inputClassName="bg-backgroundGrey p-4"
                  value="--"
                  disabled
                />
              )}
            </div>
            <div>
              <Input
                placeholder="Value"
                className="text-es-medium-grey"
                inputContainerClassName={
                  (!ptoType.selected || ptoType?.type === PTO_TYPES[0]) &&
                  "bg-backgroundGrey border-0"
                }
                inputClassName={
                  (!ptoType.selected || ptoType?.type === PTO_TYPES[0]) &&
                  "bg-backgroundGrey p-4"
                }
                value={
                  ptoType.selected && ptoType?.type !== PTO_TYPES[0]
                    ? ptoType?.numHours
                    : "--"
                }
                onChange={(val) =>
                  onChangePtoGeneralTypeNumHours(val, typeIndex)
                }
                disableClear
                disabled={
                  !ptoType.selected ||
                  ptoType?.type === PTO_TYPES[0] ||
                  isLocked
                }
                validation={
                  ptoType.selected &&
                  yup.number().min(1, "Atleast 1").max(240, `240 maximum`)
                }
                showValidationErrorAtBottom
              />
            </div>
            <div>
              {ptoType.selected && ptoType?.accrualType !== "non-accrual" ? (
                <Dropdown
                  className="text-gray-300"
                  options={MAX_ACCRUAL_OPTIONS}
                  value={MAX_ACCRUAL_OPTIONS.find(
                    (opt) => opt.value === ptoType?.maxAccrual
                  )}
                  onChange={(val) =>
                    onChangePtoGeneralTypeMaxAccrual(val, typeIndex)
                  }
                  disableClear
                  isDisabled={
                    !ptoType.selected ||
                    ptoType?.type === PTO_TYPES[0] ||
                    isLocked
                  }
                />
              ) : (
                <Input
                  inputContainerClassName="bg-backgroundGrey border-0"
                  inputClassName="bg-backgroundGrey p-4"
                  value="--"
                  disabled
                />
              )}
            </div>
            <div>
              <Input
                placeholder="Value"
                className="text-es-medium-grey"
                inputContainerClassName={
                  (!ptoType.selected ||
                    ptoType?.accrualType === "non-accrual") &&
                  "bg-backgroundGrey border-0"
                }
                inputClassName={
                  (!ptoType.selected ||
                    ptoType?.accrualType === "non-accrual") &&
                  "bg-backgroundGrey p-4"
                }
                value={
                  ptoType.selected && ptoType?.accrualType !== "non-accrual"
                    ? ptoType?.maxRollover
                    : "--"
                }
                onChange={(val) =>
                  onChangePtoGeneralTypeMaxRollOver(val, typeIndex)
                }
                disableClear
                disabled={
                  !ptoType.selected ||
                  ptoType?.type === PTO_TYPES[0] ||
                  isLocked
                }
                validation={
                  ptoType.selected &&
                  ptoType?.accrualType === "accrual" &&
                  yup
                    .number()
                    .integer()
                    .min(1, "Atleast 1")
                    .max(240, `240 maximum`)
                }
                showValidationErrorAtBottom
              />
            </div>
            <div>
              {ptoType.selected ? (
                <DatePicker
                  value={ptoType?.availability?.date}
                  onChange={(val) =>
                    onChangePtoGeneralAvailabilityDate(val, typeIndex)
                  }
                  disabled={!ptoType.selected || isLocked}
                  validation={ptoType.selected && yup.date()}
                />
              ) : (
                <Input
                  inputContainerClassName="bg-backgroundGrey border-0"
                  inputClassName="bg-backgroundGrey p-4"
                  value="--"
                  disabled
                />
              )}
            </div>
            <div className="flex flex-row items-center">
              <div className="w-3/4 pr-2">
                <Input
                  placeholder="Value"
                  className="text-es-medium-grey"
                  inputContainerClassName={
                    !ptoType.selected && "bg-backgroundGrey border-0"
                  }
                  inputClassName={!ptoType.selected && "bg-backgroundGrey p-4"}
                  value={
                    ptoType.selected && ptoType?.availability?.date
                      ? ptoType?.availability?.waitPeriod
                      : "--"
                  }
                  onChange={(val) =>
                    onChangePtoGeneralTypeAvailabilityDays(val, typeIndex)
                  }
                  disableClear
                  disabled={
                    !ptoType.selected ||
                    !ptoType?.availability?.date ||
                    isLocked
                  }
                  validation={
                    ptoType?.availability?.waitPeriod &&
                    ptoType.selected &&
                    yup.number().min(1, "Atleast 1").max(90, `90 maximum`)
                  }
                  showValidationErrorAtBottom
                />
              </div>
              <div className="w-1/4">
                <p className="text-gray-200">Days</p>
              </div>
            </div>
            <div className="self-center">
              <Checkbox
                checked={ptoType?.allowNegatives}
                size={5}
                label="Allow"
                labelClassName="text-base text-es-medium-grey"
                onChange={(val) =>
                  onChangePtoGeneralTypeNegativeValues(
                    typeIndex,
                    ptoType?.type,
                    val
                  )
                }
                disabled={
                  isLocked ||
                  ptoType?.type === PTO_TYPES[0] ||
                  !ptoType.selected
                }
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

PtoGeneralSection.propTypes = {
  systemSettings: PropTypes.shape({
    ptoGeneralTypes: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        accrualType: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
        availability: PropTypes.shape({
          waitPeriod: PropTypes.string,
          date: PropTypes.string,
        }),
        selected: PropTypes.bool,
        numHours: PropTypes.number,
        maxRollover: PropTypes.number,
      })
    ),
    ptoLocations: PropTypes.arrayOf(
      PropTypes.shape({
        location: PropTypes.string,
        types: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            accrualType: PropTypes.shape({
              label: PropTypes.string,
              value: PropTypes.string,
            }),
            availability: PropTypes.shape({
              waitPeriod: PropTypes.string,
              date: PropTypes.string,
            }),
            selected: PropTypes.bool,
            numHours: PropTypes.number,
            maxRollover: PropTypes.number,
          })
        ),
      })
    ),
    ptoCategory: PropTypes.string,
  }),
  dispatchSystemSettings: PropTypes.func,
  setIsValid: PropTypes.func,
};

PtoGeneralSection.defaultProps = {
  systemSettings: undefined,
  dispatchSystemSettings: undefined,
  setIsValid: undefined,
};

export default PtoGeneralSection;

import { FileAPI } from "@griffingroupglobal/eslib-api";
import { flatten } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import attachmentIcon from "../../assets/images/attachment_icon_updated.svg";
import deleteIcon from "../../assets/images/magnoliaDeleteIcon.svg";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../assets/images/whiteExclamationIcon.svg";
import "../Spinner/styles.css";
import { toastError } from "../Toast/Toast";

const toastCloseIcon = <img src={whiteCrossIcon} alt="Close notice" />;
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

/**
 * @TODO - Cleanup Function on unmount
 */
const AddAttachment = ({
  files,
  extensions,
  onFilesAdded,
  onRemove,
  labelClassname,
}) => {
  const inputRef = useRef();
  const [resolvedFiles, setResolvedFiles] = useState();
  const [loading, setLoading] = useState(true);

  const resolveEventFiles = useCallback(async () => {
    setLoading(true);
    let resolved = [...files]?.map(async (item) => {
      if (item?.ref) {
        return FileAPI.getByRef(item?.ref)
          .then((res) => {
            const { data } = res;
            return data;
          })
          .catch((err) => {
            toastError(
              `Error fetching Event File: ${err.message}`,
              toastErrorIcon,
              toastCloseIcon
            );
            return { name: "Unable to Load", ...item };
          });
      }
      return item;
    });
    resolved = await Promise.all(resolved);
    setResolvedFiles(resolved);
    setLoading(false);
  }, [files]);

  useEffect(() => {
    resolveEventFiles();
    return () => {};
  }, [resolveEventFiles]);

  const handleClick = useCallback(() => {
    inputRef.current.click();
  }, []);

  const handleFilesAdded = React.useCallback(
    ({ target: { files: f } }) => {
      const newFiles = Object.values(f)?.reduce((list, item) => {
        if (item?.name) {
          list.push({
            id: uuidv4(),
            original: item,
            name: item.name,
            type: item.type,
            ...item,
          });
        }
        return list;
      }, []);
      onFilesAdded([...files, ...newFiles]);
    },
    [files, onFilesAdded]
  );

  const handleOpenFile = ({ original, contentsUrl }) => {
    const url = original ? URL.createObjectURL(original) : contentsUrl;
    window.open(url);
  };

  return (
    <div className="flex flex-col gap-4">
      <button
        className="inline-flex gap-2 justify-start items-center text-gray-450 hover:underline"
        type="button"
        onClick={!loading ? handleClick : () => {}}
        style={{ fontSize: "16px" }}
      >
        <img
          src={attachmentIcon}
          className="w-5 h-5 inline"
          alt="delete-icon"
        />
        <span className={labelClassname ?? ""}>Add Attachment</span>
        {loading && <div className="loading-circle-small" />}
      </button>
      <input
        type="file"
        className="hidden"
        id="file-input"
        accept={extensions}
        onChange={handleFilesAdded}
        // eslint-disable-next-line no-return-assign
        ref={(el) => (inputRef.current = el)}
        multiple
      />
      <div className="flex flex-col gap-4 w-full">
        {flatten(Object.values(resolvedFiles ?? []))?.map((file) => (
          <div
            key={file?.id}
            className="grid grid-cols-6 gap-4 items-center justify-center"
          >
            <button
              className="col-span-5 truncate text-brandGreen hover:underline cursor-pointer text-left"
              type="button"
              onClick={() => handleOpenFile(file)}
            >
              <span className={labelClassname ?? ""}> {file?.name}</span>
            </button>
            <button
              onClick={() =>
                onRemove(file?.contentType ? file?.reference : file.id)
              }
              className="w-4 h-4 col-span-1"
              style={{ minWidth: "1rem" }}
              type="button"
            >
              <img src={deleteIcon} className="w-4 h-4" alt="delete-icon" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

AddAttachment.propTypes = {
  /**
   * files selected for upload
   */
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.array,
  /**
   * optional accepted file types
   */
  extensions: PropTypes.string,
  /**
   * function called when files added
   */
  onFilesAdded: PropTypes.func,
  /**
   * Function called to remove file
   */
  onRemove: PropTypes.func,
  /**
   * Classnames for texts
   */
  labelClassname: PropTypes.string,
};

AddAttachment.defaultProps = {
  files: [],
  extensions: "",
  onFilesAdded: () => {},
  onRemove: () => {},
  labelClassname: undefined,
};

export default AddAttachment;

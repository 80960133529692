import React from "react";
import ChatSearchList from "./ChatSearchList";
import useChatSearchListData from "./useChatSearchListData";
import CrossButton from "../../../../stories/Components/CrossButton/CrossButton";

const MessagingSearchHeader = () => {
  const { value, filteredList, handleChange, selected, handleSelect } =
    useChatSearchListData();

  return (
    <>
      <header
        className="py-3 border-b relative flex bg-mint items-center justify-start px-4 gap-2"
        style={{ borderColor: "#cacaca", minHeight: "64px", maxHeight: "64px" }}
      >
        <span className="font-semibold">To:</span>
        <input
          className="flex-1 bg-transparent"
          value={value}
          onChange={handleChange}
          placeholder="#channel, @somebody"
        />
        <ChatSearchList list={filteredList} onClick={handleSelect} />
      </header>
      <div className="w-full flex flex-wrap gap-3 pt-2 px-4">
        {selected?.map((item) => (
          <div
            key={item.id}
            className="flex gap-1 bg-mint rounded-md p-2"
            title={item.name}
          >
            <span className="w-20 truncate text-xs capitalize">
              {item.name}
            </span>
            <CrossButton onClick={() => handleSelect(item)} />
          </div>
        ))}
      </div>
    </>
  );
};

export default MessagingSearchHeader;

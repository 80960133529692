import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import Select from "react-select";
import moment from "moment";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import * as yup from "yup";
import { FileAPI } from "@griffingroupglobal/eslib-api";

import DatePicker from "../DatePicker/DatePicker";
import Input from "../Input/Input";
import RadioButton from "../Buttons/RadioButton";
import Dropdown from "../Dropdown/Dropdown";
import SimpleFileUpLoad from "../AssetForms/SimpleFileUpLoad";

import {
  BRAND_GREEN,
  DROPDOWN_STYLE,
  EXPENSE_RECEIPT_EXTENSIONS,
} from "../../../constants";
import DisplayFileNames from "../../../Pages/Overviews/Sop/DisplayFileNames";
import paperclip from "../../assets/images/attachment_icon_updated.svg";
import IconButton from "../Buttons/IconButton";
import { toastError } from "../../../helpers/Toast";
import AssociationDropdown from "../AssociationDropdown";
import useExpenseCreateFormData from "./useExpenseCreateFormData";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    width: "100%",
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    ...DROPDOWN_STYLE.option,
    backgroundColor: isFocused && BRAND_GREEN,
  }),
  singleValue: (provided) => ({
    ...provided,
    ...DROPDOWN_STYLE.singleValue,
  }),
  control: (provided) => ({
    ...provided,
    ...DROPDOWN_STYLE.control,
  }),
};

const ExpenseCreateForm = ({
  modalData,
  isEditing,
  expenseData,
  csiCodes,
  setHasChanged,
  financialsConfiguration,
  editedExpense,
  setEditedExpense,
  filesToUpload,
  setFilesToUpload,
  handleFilesAdded,
  handleFilesUpdated,
  removeAttachedFile,
  handleFilesUploaded,
  setCanCreate,
}) => {
  const inputRefs = useRef({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [origReceipts, setOrigReceipts] = useState();
  const [projectCSICodes, setProjectCSICodes] = useState([]);
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [showAddDocuments, setShowAddDocuments] = useState(false);
  const [isMileage, setIsMileage] = useState(false);
  const [isTolls, setIsTolls] = useState(false);

  const { isProjectAssociated, lockAssociation, handleChangeAssociation } =
    useExpenseCreateFormData({
      modalData,
      expenseData,
      editedExpense,
      financialsConfiguration,
      setEditedExpense,
      setProjectCSICodes,
    });

  const ratePerMile = useMemo(() => {
    return financialsConfiguration.financials.expense?.ratePerMile;
  }, [financialsConfiguration.financials.expense?.ratePerMile]);

  useEffect(() => {
    if (financialsConfiguration?.financials) {
      setExpenseCategories(
        financialsConfiguration?.financials?.expense?.categories?.map(
          (category) => ({
            label: category.display,
            value: category.id,
          })
        )
      );
    }
  }, [financialsConfiguration]);

  useEffect(() => {
    // fetch receipts of the edited Expense
    const fetchReceipt = async () => {
      try {
        const receipts = expenseData?.receipts;
        if (isEditing && receipts?.length > 0) {
          const {
            data: { entries },
          } = await FileAPI.get({
            params: { association: expenseData?.reference },
          });

          const formatedReceipts = entries?.map(({ resource: receipt }) => ({
            ...receipt,
            isEditing: true,
          }));
          setFilesToUpload(formatedReceipts);
          // set original receipts to check if changes occurred
          setOrigReceipts(formatedReceipts);
        }
      } catch (error) {
        toastError("Unable to get receipts for this expense, Please try again");
        console.warn("Unable to get recipts for this expense", error.message);
      }
    };

    fetchReceipt();
  }, [
    expenseData?.receipts,
    expenseData?.reference,
    isEditing,
    setFilesToUpload,
  ]);

  useEffect(() => {
    // set SCI code DD options
    const options = projectCSICodes?.filter(
      (csiCode) =>
        searchTerm?.length > 0 &&
        csiCode?.label?.toLowerCase()?.includes(searchTerm.toLowerCase())
    );
    setSearchResults(options);
  }, [projectCSICodes, searchTerm]);

  useEffect(() => {
    // check if changes occurred in edited Expense
    if (isEditing) {
      setHasChanged(
        !isEqual(editedExpense, expenseData) ||
          !isEqual(origReceipts, filesToUpload)
      );
    } else {
      setHasChanged(false);
    }
  }, [
    editedExpense,
    expenseData,
    filesToUpload,
    isEditing,
    origReceipts,
    setHasChanged,
  ]);

  useEffect(() => {
    // set validation of the modalSave button
    let validate =
      Boolean(editedExpense?.amount) &&
      Boolean(editedExpense?.category) &&
      Boolean(editedExpense?.association);

    // Enforce Propject CSI Code
    if (
      (isProjectAssociated ||
        (isEditing && editedExpense?.category?.includes("Project"))) &&
      projectCSICodes?.length > 0
    ) {
      validate =
        validate &&
        Boolean(editedExpense?.financialCode?.code) &&
        Boolean(editedExpense?.financialCode?.division) &&
        Boolean(editedExpense?.financialCode?.subcode);
    }

    // Mileage expense
    if (isMileage) {
      validate = validate && Boolean(editedExpense?.numMiles);
    } else {
      validate = validate && Boolean(editedExpense?.merchant);
    }

    // no receipt required for Mileage and Tolls expenses
    if (!isTolls && !isMileage) {
      validate = validate && filesToUpload?.length > 0;
    }

    setCanCreate(validate);
  }, [
    isTolls,
    isMileage,
    filesToUpload?.length,
    isEditing,
    isProjectAssociated,
    filesToUpload,
    origReceipts,
    setHasChanged,
    editedExpense,
    setCanCreate,
    expenseData,
    editedExpense?.category,
    editedExpense?.numMiles,
    editedExpense?.financialCode?.code,
    editedExpense?.financialCode?.division,
    editedExpense?.financialCode?.subcode,
    projectCSICodes?.length,
  ]);

  useEffect(() => {
    // Set Project CSI code
    if (isEditing && editedExpense?.financialCode) {
      const { financialCode } = editedExpense;
      const editedCsiCode = `${financialCode?.division} ${financialCode?.code} ${financialCode?.subcode}`;
      const editedCsiOption = csiCodes?.find(
        (item) => item.value === editedCsiCode
      );
      setSelectedOption(editedCsiOption);
    }
  }, [csiCodes, editedExpense, isEditing]);

  useEffect(() => {
    if (isEditing) {
      const catego = expenseCategories?.find(
        (category) => category.value === editedExpense?.category
      );

      if (catego?.label === "Mileage") {
        setIsMileage(catego?.label === "Mileage");
      }

      if (catego?.label === "Tolls") {
        setIsTolls(catego?.label === "Tolls");
      }
    }
  }, [isEditing, editedExpense, expenseCategories]);

  useEffect(() => {
    if (editedExpense?.billable === false) {
      setSelectedOption(null);
    }
  }, [editedExpense?.billable]);

  const onFieldChange = useCallback(
    (prop, value) => {
      if (prop === "csiCode") {
        setSelectedOption(value);
        const codeArray = value?.value.split(" ");

        const financialCode = value
          ? {
              division: codeArray[0],
              code: codeArray[1],
              subcode: codeArray[2],
            }
          : undefined;
        setEditedExpense((prev) => ({ ...prev, financialCode }));
      } else if (prop === "numMiles") {
        setEditedExpense((prev) => ({
          ...prev,
          [prop]: value,
          amount: (parseInt(value, 10) * ratePerMile).toFixed(2),
        }));
      }
      if (prop === "billable") {
        setEditedExpense((prev) => ({
          ...prev,
          financialCode: undefined,
          [prop]: value,
        }));
      } else {
        setEditedExpense((prev) => ({
          ...prev,
          [prop]: value,
        }));
      }
    },
    [setEditedExpense, ratePerMile]
  );

  const handleInputChange = (inputValue) => {
    setSearchTerm(inputValue);
  };

  const handleCsiChange = useCallback((csiCode) => {
    if (csiCode) {
      setSelectedOption(csiCode);
    }
  }, []);

  const handleOnKeyDown = useCallback(
    (event) => {
      // checks if the Enter key was pressed
      if (event.keyCode === 13) {
        if (searchResults?.length === 0) {
          return handleCsiChange({ description: searchTerm });
        }
      }
      return undefined;
    },
    [handleCsiChange, searchResults?.length, searchTerm]
  );

  /**
   * Automatically moves cursor to next input field on pressing Enter
   */
  const handleEnter = useCallback((event) => {
    // checks if the Enter key was pressed
    if (event.keyCode === 13) {
      const inputCategories = Object.keys(inputRefs.current);
      const currentInputCategoryIdx = inputCategories.indexOf(
        event.target.name
      );
      const nextInputCategory =
        inputRefs.current[inputCategories[currentInputCategoryIdx + 1]];

      event.preventDefault();

      if (event.target.name.includes("purchaseOrder")) {
        inputRefs.current[inputCategories.indexOf("amount")].focus();
      } else if (event.target.name.includes("amount")) {
        nextInputCategory.focus();
      } else if (nextInputCategory) {
        nextInputCategory.focus();
      }
    }
  }, []);

  /**
   * Handles the change of the selected category.
   * @param {Object} categorySelected - The selected category object containing label and value.
   */
  const handleChangeCategory = (categorySelected) => {
    const isTollsSelected = categorySelected?.label === "Tolls";
    const isMileageSelected = categorySelected?.label === "Mileage";

    setIsTolls(isTollsSelected);

    // Reset `amount` if `isMileage` was selected before
    if (isMileage && !isMileageSelected) {
      setIsMileage(false);
      setEditedExpense((prev) => ({
        ...prev,
        amount: undefined,
      }));
    }

    // If `mileage` the amount needs to be removed. The value will be calculated based on #Miles
    if (isMileageSelected) {
      setIsMileage(true);
      setEditedExpense((prev) => ({
        ...prev,
        merchant: "",
        amount: undefined,
      }));

      onFieldChange("numMiles", 0);
    }

    onFieldChange("category", categorySelected?.value);
  };

  return (
    <div className="flex flex-col  border rounded-lg px-7 w-full">
      <div className="flex flex-col items-start w-full mt-7">
        <p className="flex items-center text-gray-500 font-semibold mb-1">
          Association<span className="text-brandGreen">*</span>
        </p>
        <div className="w-full">
          <AssociationDropdown
            association={editedExpense?.association}
            disabled={!!lockAssociation?.association}
            onChangeAssociation={handleChangeAssociation}
          />
        </div>
      </div>

      {editedExpense?.asset && (
        <div className="flex flex-col items-start w-full mt-5">
          <p className="flex items-center text-gray-500 font-semibold mb-1">
            Asset
          </p>
          <div className="w-full">
            <AssociationDropdown
              association={editedExpense.asset}
              disabled={!!lockAssociation.asset}
              onChangeAssociation={handleChangeAssociation}
            />
          </div>
        </div>
      )}

      <div className="flex flex-col items-start w-full mt-5">
        <p className="flex items-center text-gray-500 font-semibold mb-1">
          Expense Date<span className="text-brandGreen">*</span>
        </p>
        <div className="w-full">
          <DatePicker
            className="flex-1"
            labelClassName="text-gray-500"
            name="date"
            value={editedExpense?.date || moment()}
            dateFormat="MMMM d, yyyy"
            onChange={(val) => onFieldChange("date", val)}
            validation={yup.date().required()}
            required
          />
        </div>
      </div>

      <div className="flex flex-col justify-between items-start w-full mt-5">
        <p className="flex items-center text-gray-500 font-semibold mb-1">
          Category<span className="text-brandGreen">*</span>
        </p>
        <div className="w-full">
          <Dropdown
            placeholder="Select"
            labelClassName="text-gray-300"
            options={expenseCategories}
            value={expenseCategories?.find(
              (category) => category.value === editedExpense?.category
            )}
            validation={yup.object().typeError("Required").required()}
            onChange={handleChangeCategory}
          />
        </div>
      </div>

      {isMileage && (
        <div className="flex flex-col justify-between items-start w-full mt-5">
          <p className="flex items-center text-gray-500 font-semibold mb-1">
            # of Miles<span className="text-brandGreen">*</span>
          </p>
          <div className="w-full">
            <Input
              tabIndex={0}
              labelClassName="flex justify-between text-gray-300"
              name="numMiles"
              placeholder="# of miles"
              value={parseInt(editedExpense?.numMiles, 10)}
              validation={yup.number().typeError("Required").required()}
              onChange={(val) => onFieldChange("numMiles", val)}
              forwardedRef={(el) => {
                inputRefs.current.numMiles = el;
              }}
              handleEnter={handleEnter}
              required
              type="number"
              minValue={0}
              disableClear
              autocomplete="off"
              errorMessageClassName="font-normal"
            />
          </div>
        </div>
      )}

      {!isMileage && (
        <div className="flex flex-col items-start w-full mt-5">
          <p className="flex items-center text-gray-500 font-semibold mb-1">
            Merchant<span className="text-brandGreen">*</span>
          </p>
          <div className="w-full">
            <Input
              tabIndex={0}
              name="merchant"
              placeholder="Name"
              value={editedExpense?.merchant}
              validation={yup.string().required()}
              onChange={(val) => onFieldChange("merchant", val)}
              forwardedRef={(el) => {
                inputRefs.current.merchant = el;
              }}
              handleEnter={handleEnter}
              required
              disableClear
              errorMessageClassName="font-normal"
            />
          </div>
        </div>
      )}

      <div className="flex flex-col items-start w-full mt-5">
        <p className="flex items-center text-gray-500 font-semibold mb-1">
          Total Amount <span className="text-brandGreen">*</span>
        </p>
        <div className="w-full">
          <Input
            tabIndex={0}
            labelClassName="flex justify-between text-gray-300"
            name="amount"
            placeholder="$00.00"
            value={editedExpense?.amount}
            validation={yup.number().typeError("Required").required()}
            onChange={(val) => onFieldChange("amount", val)}
            forwardedRef={(el) => {
              inputRefs.current.amount = el;
            }}
            handleEnter={handleEnter}
            required
            type="number"
            step="0.01"
            minValue={0}
            disableClear
            autocomplete="off"
            errorMessageClassName="font-normal"
            disabled={isMileage}
          />
        </div>
      </div>

      {(isProjectAssociated ||
        (isEditing && editedExpense?.association?.includes("Project"))) && (
        <div className="flex flex-col items-start w-full mt-5">
          <p className="flex items-center text-gray-500 font-semibold mb-1">
            CSI Code{" "}
            {projectCSICodes?.length > 0 && (
              <span className="text-brandGreen">*</span>
            )}
          </p>
          <div className="w-full">
            <Select
              tabIndex={0}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              className="w-full"
              styles={customStyles}
              value={selectedOption}
              name="financialCode"
              onChange={(value) => onFieldChange("csiCode", value)}
              inputValue={searchTerm}
              defaultInputValue={{
                label: "enter search term",
                value: undefined,
              }}
              onInputChange={handleInputChange}
              isSearchable
              isClearable
              options={searchResults}
              onKeyDown={(event) => handleOnKeyDown(event)}
              forwardedRef={(el) => {
                inputRefs.current.financialCode = el;
              }}
              handleEnter={handleEnter}
              placeholder={
                <div>
                  {projectCSICodes?.length > 0
                    ? "Search CSI codes"
                    : "No CSI Code available"}
                </div>
              }
              isDisabled={!projectCSICodes?.length}
              noOptionsMessage={() => null}
            />
          </div>
        </div>
      )}

      <div className="flex">
        <div className="flex flex-col w-full mt-5">
          <p className="text-gray-500 font-semibold w-44 mb-1">Billable?</p>
          <div className="flex items-center ml-1">
            <div className="">
              <RadioButton
                tabIndex={0}
                name="billable"
                labelClassName="mr-2.5 text-xs"
                accentStyle={{
                  accentColor: BRAND_GREEN,
                  border: `1px solid ${BRAND_GREEN}`,
                }}
                label="Yes"
                isChecked={
                  editedExpense?.billable === undefined
                    ? true
                    : editedExpense?.billable === true
                }
                onChange={() => onFieldChange("billable", true)}
                forwardedRef={(el) => {
                  inputRefs.current.billable = el;
                }}
                handleEnter={handleEnter}
              />
            </div>
            <div className="">
              <RadioButton
                tabIndex={0}
                name="billable"
                labelClassName="text-xs"
                accentStyle={{
                  accentColor: BRAND_GREEN,
                  border: `1px solid ${BRAND_GREEN}`,
                }}
                label="No"
                isChecked={
                  editedExpense?.billable === undefined
                    ? false
                    : editedExpense?.billable === false
                }
                onChange={() => onFieldChange("billable", false)}
                forwardedRef={(el) => {
                  inputRefs.current.billable = el;
                }}
                handleEnter={handleEnter}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full mt-5">
          <p className="text-gray-500 font-semibold w-44 mb-1">Credit Card?</p>
          <div className="flex items-center ml-1">
            <div className="">
              <RadioButton
                name="creditCard"
                tabIndex={0}
                label="Yes"
                labelClassName="mr-2.5 text-xs"
                accentStyle={{
                  accentColor: BRAND_GREEN,
                  border: `1px solid ${BRAND_GREEN}`,
                }}
                isChecked={
                  editedExpense?.creditCard === undefined
                    ? true
                    : editedExpense?.creditCard === true
                }
                onChange={() => onFieldChange("creditCard", true)}
                forwardedRef={(el) => {
                  inputRefs.current.creditCard = el;
                }}
                handleEnter={handleEnter}
              />
            </div>
            <div className="">
              <RadioButton
                name="creditCard"
                tabIndex={0}
                label="No"
                labelClassName="text-xs"
                accentStyle={{
                  accentColor: BRAND_GREEN,
                  border: `1px solid ${BRAND_GREEN}`,
                }}
                isChecked={
                  editedExpense?.creditCard === undefined
                    ? false
                    : editedExpense?.creditCard === false
                }
                onChange={() => onFieldChange("creditCard", false)}
                forwardedRef={(el) => {
                  inputRefs.current.creditCard = el;
                }}
                handleEnter={handleEnter}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mt-4">
        <div className="flex flex-col items-start justify-between mb-5">
          <p className="flex justify-start text-gray-500 font-semibold mb-1">
            Description
          </p>
          <div className="w-full flex flex-col">
            <Input
              tabIndex={0}
              labelClassName="text-gray-300 mb-2"
              name="description"
              placeholder="Description"
              value={editedExpense?.description}
              onChange={(val) => onFieldChange("description", val)}
              forwardedRef={(el) => {
                inputRefs.current.description = el;
              }}
              handleEnter={handleEnter}
              disableClear
              isTextarea
            />
            <div className="flex items-center">
              <IconButton
                title="Add Receipt"
                className="my-4"
                innerClassName="text-gray-300 text-sm font-normal ml-2"
                icon={paperclip}
                onClick={() => {
                  setShowAddDocuments(!showAddDocuments);
                }}
              />
              {!isMileage && !isTolls && (
                <p className="text-gray-300 text-sm font-normal">
                  <span className="text-brandGreen font-semibold">*</span>
                </p>
              )}
            </div>
            {showAddDocuments && (
              <div className="flex items-center">
                <SimpleFileUpLoad
                  extensions={EXPENSE_RECEIPT_EXTENSIONS}
                  files={filesToUpload}
                  onFilesAdded={handleFilesAdded}
                  onFilesUpdated={handleFilesUpdated}
                  onFilesUploaded={handleFilesUploaded}
                  customUploadAreaStyle={{ marginRight: "0px" }}
                  simple
                />
              </div>
            )}

            {filesToUpload?.length > 0 && (
              <div className="mt-2">
                <DisplayFileNames
                  filesToUpload={filesToUpload}
                  removeAttachedFile={removeAttachedFile}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ExpenseCreateForm.propTypes = {
  modalData: PropTypes.shape({
    associationLock: PropTypes.string,
    assetLock: PropTypes.string,
  }),
  associatedResource: PropTypes.shape({
    reference: PropTypes.string,
    name: PropTypes.string,
    resource: PropTypes.string,
  }),
  isEditing: PropTypes.bool,
  expenseData: PropTypes.shape({
    receipts: PropTypes.arrayOf(PropTypes.shape({})),
    reference: PropTypes.string,
  }),
  csiCodes: PropTypes.arrayOf(PropTypes.shape({})),
  setHasChanged: PropTypes.func,
  financialsConfiguration: PropTypes.shape({
    financials: PropTypes.shape({
      expense: PropTypes.shape({
        ratePerMile: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        categories: PropTypes.arrayOf(
          PropTypes.shape({
            display: PropTypes.string,
            id: PropTypes.string,
            isOpen: PropTypes.bool,
            isEditing: PropTypes.bool,
          })
        ),
      }),
      csiCodeMappingObject: PropTypes.shape({}),
    }),
  }),

  editedExpense: PropTypes.shape({
    numMiles: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    description: PropTypes.string,
    creditCard: PropTypes.bool,
    billable: PropTypes.bool,
    category: PropTypes.string,
    date: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    merchant: PropTypes.string,
    association: PropTypes.string,
    asset: PropTypes.string,
    reference: PropTypes.string,
    receipts: PropTypes.arrayOf(PropTypes.shape({})),
    financialCode: PropTypes.shape({
      division: PropTypes.string,
      code: PropTypes.string,
      subcode: PropTypes.string,
    }),
  }),
  setEditedExpense: PropTypes.func,
  filesToUpload: PropTypes.arrayOf(PropTypes.shape({})),
  setFilesToUpload: PropTypes.func,
  handleFilesAdded: PropTypes.func,
  handleFilesUpdated: PropTypes.func,
  removeAttachedFile: PropTypes.func,
  handleFilesUploaded: PropTypes.func,
  setCanCreate: PropTypes.func,
};

ExpenseCreateForm.defaultProps = {
  modalData: undefined,
  associatedResource: undefined,
  isEditing: false,
  expenseData: {},
  csiCodes: undefined,
  setHasChanged: () => {},
  financialsConfiguration: undefined,

  editedExpense: undefined,
  setEditedExpense: undefined,
  filesToUpload: undefined,
  setFilesToUpload: undefined,
  handleFilesAdded: undefined,
  handleFilesUpdated: undefined,
  removeAttachedFile: undefined,
  handleFilesUploaded: undefined,
  setCanCreate: undefined,
};

export default ExpenseCreateForm;

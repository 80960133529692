import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import SidebarItem from "../SidebarItem/SidebarItem";
import { sortLettersAndThenNumbers } from "../../../helpers/Utilities";

const RouteSidebarFavorites = ({ isActivePath, setIsOpen, favorites }) => {
  const history = useHistory();
  return (
    <>
      <div className="overflow-y-scroll w-full max-h-44">
        {sortLettersAndThenNumbers(favorites, "label").map((favorite) => {
          return (
            <SidebarItem
              key={favorite?.value}
              label={favorite.label}
              active={isActivePath(favorite?.path)}
              onClick={() => {
                history.push(favorite?.path);
                setIsOpen(false);
              }}
            />
          );
        })}
      </div>
    </>
  );
};

RouteSidebarFavorites.propTypes = {
  isActivePath: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func,
  favorites: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      path: PropTypes.string,
    })
  ),
};

RouteSidebarFavorites.defaultProps = {
  setIsOpen: true,
  favorites: [],
};

export default RouteSidebarFavorites;

import React, { useState, useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { cloneDeep, isEqual } from "lodash";
import moment from "moment";

import { FileAPI } from "@griffingroupglobal/eslib-api";
import { useQueryClient } from "react-query";

import { filePaginatedKeys } from "../../../config/reactQuery/queryKeyFactory";
import { uploadFileWithData } from "../../../helpers/File";
import ExpenseCreateForm from "./ExpenseCreateForm";

import "./styles.css";
import {
  useCreateExpense,
  useRemoveExpenses,
  useUpdateExpense,
} from "../../../hooks/useExpenses";
import ModalWrapper from "../EsModal/ModalWrapper";
import useExpenseDocuments from "../../../hooks/useExpenseDocuments";
import { useAppState } from "../../../state/appState";
import ExpenseDetailsView from "./ExpenseDetailsView";
import useFinancialsConfiguration from "../../../hooks/useFinancialsConfiguration";
import { EXPENSE_EMPTY } from "../../../constants";
import useAssociationsHelper from "../../../hooks/useAssociationsHelper";

const ExpenseCreateModal = ({ onCloseModal, navigateTo, modalData }) => {
  const [{ currentUser }] = useAppState();
  const { csiCodes } = useExpenseDocuments();
  const { data: financialsConfiguration } = useFinancialsConfiguration();
  const queryClient = useQueryClient();

  const { getAssociationInfo } = useAssociationsHelper();

  const { mutate: createExpense } = useCreateExpense();
  const { mutate: updateExpense } = useUpdateExpense();
  const { mutate: deleteExpenses } = useRemoveExpenses();

  const [filesToUpload, setFilesToUpload] = useState([]);
  const [canCreate, setCanCreate] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [origReceipts, setOrigReceipts] = useState();
  const [loadingReceipts, setLoadingReceipts] = useState(false);
  const [formatedExpense, setFormatedExpense] = useState(
    modalData?.expenseData
  );

  const templateExpense = useMemo(
    () => ({
      ...EXPENSE_EMPTY,
      association: modalData?.associationLock,
      asset: modalData?.assetLock,
      date: modalData?.expenseData?.date,
    }),
    [modalData]
  );

  const [editedExpense, setEditedExpense] = useState(templateExpense);

  useEffect(() => {
    const isSame = isEqual(templateExpense, editedExpense);
    setHasChanged(!isSame);
  }, [templateExpense, editedExpense]);

  useEffect(() => {
    if (modalData?.expenseData && isEditing) {
      setEditedExpense(modalData?.expenseData);
      setOrigReceipts(
        cloneDeep(
          modalData?.expenseData?.receipts?.map((r) => ({
            ...r,
            isEditing: true,
          }))
        )
      );
    }
  }, [isEditing, modalData?.expenseData]);

  const handleCreateExpense = useCallback(
    (newExpense) => {
      createExpense(newExpense);
    },
    [createExpense]
  );

  const handleRequestModalClose = () => {
    if (onCloseModal) onCloseModal();
  };

  const onAddFile = async (doc, data = {}, progressCallback) => {
    const fileResource = await uploadFileWithData(
      doc,
      data,
      progressCallback,
      undefined,
      true,
      undefined,
      true
    );
    return fileResource;
  };

  const onUpload = useCallback(async (files, progressCallback) => {
    const handleProgressCallback = (loaded, total, filename) => {
      progressCallback(loaded, total, filename);
    };

    const result = await Promise.all(
      files.map(async ({ name, docType, isFavorited, original }) => {
        const data = {
          name,
          docType,
          isFavorited,
          contentType: original.type,
          size: original.size,
        };

        const fileResource = await onAddFile(original, data, (loaded, total) =>
          handleProgressCallback(loaded, total, name)
        );

        return fileResource;
      })
    );

    return result;
  }, []);

  const handleFilesUploaded = useCallback(async () => {
    const filteredFiles = filesToUpload.filter((file) => !file.isEditing);
    const res = await onUpload(filteredFiles, () => {});

    // update files in overview
    queryClient.invalidateQueries(filePaginatedKeys.allFiles);

    setFilesToUpload([]);
    return res;
  }, [filesToUpload, onUpload, queryClient]);

  const handleFilesAdded = useCallback(
    (addedFiles) => {
      setFilesToUpload(addedFiles);
    },
    [setFilesToUpload]
  );
  const handleFilesUpdated = (updatedFiles) => {
    setFilesToUpload(updatedFiles);
  };

  const removeAttachedFile = useCallback(
    (id) => {
      const removedFiles = filesToUpload.filter((_file, index) => index !== id);
      setFilesToUpload(removedFiles);
    },
    [filesToUpload]
  );

  const handlePrimaryButtonClick = async () => {
    const filesUploadedResults = await handleFilesUploaded();
    const uploadedFiles = filesUploadedResults.map((f) => ({
      ref: f.reference,
      category: f.category,
    }));
    const expense = {
      ...editedExpense,
      date: editedExpense?.date || moment(),
      association: editedExpense?.association,
      amount: Number(editedExpense?.amount),
      receipts: uploadedFiles,
    };

    if (isEditing) {
      const editedFiles = filesToUpload
        ?.filter((f) => f.isEditing)
        .map((f) => ({
          ref: f.reference,
          category: f.category,
        }));

      expense.receipts = [...expense.receipts, ...editedFiles];
      if (!expense?.billable) {
        expense.financialCode = undefined;
      }
      updateExpense({
        prevExpense: modalData?.expenseData,
        updatedExpense: expense,
      });
    } else createExpense(expense);
  };

  useEffect(() => {
    const fetchReceipt = async () => {
      const associationName = getAssociationInfo(
        modalData?.expenseData?.association
      )?.name;

      if (modalData?.expenseData?.receipts?.length > 0) {
        try {
          setLoadingReceipts(true);
          const refs = modalData?.expenseData?.receipts
            ?.map(({ ref }) => ref)
            ?.join(",");

          const { data: receiptsData } = await FileAPI.get({
            params: { reference: refs },
          });

          const fullReceipts = receiptsData?.entries?.map(
            (entry) => entry?.resource
          );

          setFormatedExpense((prev) => ({
            ...prev,
            receipts: fullReceipts,
            associationName,
          }));
        } catch (error) {
          console.warn("Error loading receipts");
        }
        setLoadingReceipts(false);
      } else
        setFormatedExpense((prev) => ({
          ...prev,
          associationName,
        }));
    };

    fetchReceipt();
  }, [
    getAssociationInfo,
    modalData?.expenseData?.association,
    modalData?.expenseData?.receipts,
    financialsConfiguration?.financials?.expense?.categories,
  ]);

  const getModatTitle = useCallback(() => {
    if (isEditing) return "Edit Expense";
    if (modalData?.viewMode === "viewDetails") return "Expense Details";
    return "Create Expense";
  }, [isEditing, modalData?.viewMode]);

  return (
    <ModalWrapper
      title={`${getModatTitle()}`}
      resourceName="Expense"
      primaryButtonTitle={`${isEditing ? "Save" : "Create"}`}
      primaryButtonOnClick={handlePrimaryButtonClick}
      disabled={
        (!isEditing && !canCreate) ||
        (isEditing && !hasChanged) ||
        (isEditing && !canCreate)
      }
      modalData={modalData}
      showConfirm={
        isEditing || (modalData?.viewMode === "create" && hasChanged)
      }
      buttons={
        !isEditing && modalData?.viewMode === "viewDetails" ? <></> : undefined
      }
      width="539px"
    >
      <>
        {modalData?.viewMode === "viewDetails" && (
          <>
            {!isEditing && (
              <ExpenseDetailsView
                currentUser={currentUser}
                modalData={modalData}
                deleteExpenses={deleteExpenses}
                loadingReceipts={loadingReceipts}
                setIsEditing={setIsEditing}
                expenseData={formatedExpense}
                csiCodes={csiCodes}
                financialsConfiguration={financialsConfiguration}
              />
            )}
            {isEditing && (
              <ExpenseCreateForm
                modalData={modalData}
                isEditing={isEditing}
                expenseData={modalData?.expenseData}
                editedExpense={editedExpense}
                setEditedExpense={setEditedExpense}
                onFinishedEditing={handleCreateExpense}
                onCloseCreate={handleRequestModalClose}
                csiCodes={csiCodes}
                navigateTo={navigateTo}
                financialsConfiguration={financialsConfiguration}
                filesToUpload={filesToUpload}
                setFilesToUpload={setFilesToUpload}
                handleFilesAdded={handleFilesAdded}
                handleFilesUpdated={handleFilesUpdated}
                handleFilesUploaded={handleFilesUploaded}
                removeAttachedFile={removeAttachedFile}
                setCanCreate={setCanCreate}
                setHasChanged={setHasChanged}
                origReceipts={origReceipts}
              />
            )}
          </>
        )}
        {modalData?.viewMode === "create" && (
          <ExpenseCreateForm
            modalData={modalData}
            editedExpense={editedExpense}
            setEditedExpense={setEditedExpense}
            onFinishedEditing={handleCreateExpense}
            onCloseCreate={handleRequestModalClose}
            csiCodes={csiCodes}
            navigateTo={navigateTo}
            financialsConfiguration={financialsConfiguration}
            filesToUpload={filesToUpload}
            handleFilesAdded={handleFilesAdded}
            handleFilesUpdated={handleFilesUpdated}
            handleFilesUploaded={handleFilesUploaded}
            removeAttachedFile={removeAttachedFile}
            setCanCreate={setCanCreate}
          />
        )}
      </>
    </ModalWrapper>
  );
};

ExpenseCreateModal.propTypes = {
  modalData: PropTypes.shape({
    associationLock: PropTypes.string,
    assetLock: PropTypes.string,
    viewMode: PropTypes.string,
    expenseData: PropTypes.shape({
      date: PropTypes.string,
      association: PropTypes.string,
      receipts: PropTypes.arrayOf(PropTypes.string),
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          display: PropTypes.string,
          id: PropTypes.string,
          isOpen: PropTypes.bool,
          isEditing: PropTypes.bool,
        })
      ),
    }),
  }),
  onCloseModal: PropTypes.func,
  navigateTo: PropTypes.string,
  financialsConfiguration: PropTypes.shape({
    financials: PropTypes.shape({
      expense: PropTypes.shape({
        categories: PropTypes.arrayOf(
          PropTypes.shape({
            display: PropTypes.string,
            id: PropTypes.string,
            isOpen: PropTypes.bool,
            isEditing: PropTypes.bool,
          })
        ),
      }),
    }),
  }),
};
ExpenseCreateModal.defaultProps = {
  modalData: undefined,
  onCloseModal: undefined,
  navigateTo: undefined,
  financialsConfiguration: undefined,
};

export default ExpenseCreateModal;

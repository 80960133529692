import { PropertyAPI } from "@griffingroupglobal/eslib-api";
import { useCallback } from "react";
import useAuthenticatedQuery from "../useAuthenticatedQuery";
import propertyKeys from "./propertiesKeys";
import { DEFAULT_QUERIES_STALE_TIME } from "../../constants";

/**
 * Query hook that returns a single property by id
 * @param {string} id - property id
 */
const usePropertyById = (id, refetch) => {
  /**
   * Fetch single property
   */
  const fetchSingleProperty = useCallback(async () => {
    const { data } = await PropertyAPI.getById(id);

    return data;
  }, [id]);

  const enabled = !!id;

  return useAuthenticatedQuery({
    queryKey: propertyKeys.byId(id),
    queryFn: fetchSingleProperty,
    enabled,
    staleTime: refetch ? 0 : DEFAULT_QUERIES_STALE_TIME,
  });
};

export default usePropertyById;

import { toast } from "react-toastify";

const toastMessage = (msg, icon, closeButton) => {
  toast.success(msg, {
    toastId: "toast-is-active",
    position: "top-center",
    autoClose: 5000,
    icon,
    closeButton,
    hideProgressBar: true,
    className: "bg-brandGreen text-white px-6 py-0 mb-0",
    style: { minHeight: "43px", top: "29px" },
  });
};

const toastError = (msg, icon, closeButton, id, className) => {
  toast.error(msg, {
    toastId: id || "toast-is-active",
    position: "top-center",
    autoClose: 5000,
    icon,
    closeButton,
    hideProgressBar: true,
    className: `bg-errorRed text-white px-6 py-0 mb-0 ${className}`,
    style: { minHeight: "43px", top: "29px" },
  });
};

export { toastMessage, toastError };

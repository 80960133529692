/* Framework & Tools */
import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// Helpers
import { getFullName } from "../../../helpers/Formatters";

// Components
import RequestAttachmentCard from "../RequestAttachmentAndUpload/RequestAttachmentCard";
import RequestGridViewDistroListCell from "../Requests/RequestGridView/RequestGridViewDistroListCell";
import RoleMembersAvatarList from "../../../Pages/Admin/RoleMembersAvatarList";

export default function RequestHistoryCard({
  attachments,
  attachmentMap,
  actionsByStepMap,
  users,
  round,
  currentRound,
  start,
  end,
  duration,
  commentMap,
  initiatedBy,
}) {
  const [viewActivity, setViewActivity] = useState(!!currentRound);

  const handleOpen = () => {
    setViewActivity((prev) => !prev);
  };
  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={(e) => handleOpen(e)}
      onClick={(e) => handleOpen(e)}
      id="card-container"
      className="flex flex-col w-full border rounded-lg"
      style={currentRound ? { background: "#F9F9F9" } : {}}
    >
      <div id="base-container" className="flex gap-6 border-b pl-6">
        {/* Round & Initiated By */}
        <div className="flex flex-col w-48 h-40 py-6 gap-2">
          <p className=" w-36 font-bold text-base flex flex-col">
            Round {round}
          </p>
          <p
            className="flex flex-col text-base font-normal"
            style={{ alignSelf: "stretch" }}
          >
            Initiated by {getFullName(initiatedBy?.name)}
          </p>
        </div>
        {/* Duration */}
        <div id="divider-1" className="border-r" />
        <div className="flex flex-col w-60 h-40 py-6 gap-2">
          <p className="w-28 font-bold text-base flex flex-col text-gray-450">
            Duration
          </p>
          <p className="w-auto h-auto text-base font-normal">
            {start} {end && `- ${end}`}{" "}
            {duration &&
              `(${duration > 1 ? `${duration} days` : `${duration} day`})`}
          </p>
        </div>
        {/* Attachments */}
        <div id="divider-2" className="border-r" />
        <div id="attachments" className="flex flex-col w-60 h-40 py-6 gap-2">
          <p className="w-28 font-bold text-base flex flex-col text-gray-450">
            Attachments
          </p>
          <RequestAttachmentCard
            attachments={attachments}
            attachmentMap={attachmentMap}
            currentRound={currentRound}
          />
        </div>
        <div id="divider-3" className="border-r" />
        <div className="flex flex-col w-44 gap-3 flex-shrink-0 py-6">
          <p className=" w-28 h-auto font-bold text-base flex flex-col text-gray-450">
            Members
          </p>
          <RequestGridViewDistroListCell
            distroList={users}
            hideTile
            hideDeleteIcon
          />
        </div>
      </div>
      {viewActivity && (
        <div id="activity" className="flex flex-col py-6">
          <p className=" font-semibold text-2xl w-36 pl-6">Activity</p>

          {Object?.keys(actionsByStepMap)?.map((step) => {
            const actions = actionsByStepMap?.[step];

            return (
              <div className="flex flex-col border-l mx-6 mt-6" key={step}>
                <div className="flex items-start">
                  <span
                    className=" h-3 w-3 bg-gray-150 rounded-full"
                    style={{ marginLeft: "-0.40rem" }}
                  />
                  <p className="pl-6 pb-6 text-gray-450 font-bold text-base">
                    Step {step}
                  </p>
                </div>
                {actions?.map((action) => {
                  const hasComment = action?.context?.comment?.reference;
                  const comment = commentMap[hasComment];
                  return (
                    <div
                      className="flex flex-col justify-start gap-6"
                      key={action.id}
                    >
                      {action?.action !== "Initiated" && (
                        <>
                          <div className="flex items-start pt-4">
                            <span
                              className=" h-3 w-3 bg-white rounded-full border"
                              style={{ marginLeft: "-0.40rem" }}
                            />
                            <div className="flex gap-20 pl-12">
                              <div className="flex flex-col w-72 gap-2">
                                <p className=" text-gray-500 font-bold text-base">
                                  {action?.action}
                                </p>

                                {comment && <p>{comment?.content}</p>}
                              </div>
                              <div className="flex items-start">
                                <RoleMembersAvatarList
                                  className="-mt-1.5"
                                  members={[
                                    users?.find(
                                      (item) => item?.reference === action?.user
                                    ),
                                  ]}
                                />
                                <p className=" text-gray-500 font-normal text-base">
                                  {getFullName(
                                    users?.find(
                                      (item) => item?.reference === action?.user
                                    )?.name
                                  )}
                                </p>
                              </div>
                              <div className="flex flex-col">
                                <p className=" text-gray-500 font-normal text-base">
                                  {moment(action?.when).format(
                                    "MMMM, DD, YYYY hh:mm A"
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="border-b mx-12" />
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

RequestHistoryCard.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.string),
  attachmentMap: PropTypes.shape({}),
  actionsByStepMap: PropTypes.shape({}),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  round: PropTypes.number,
  currentRound: PropTypes.bool,
  start: PropTypes.string,
  end: PropTypes.string,
  duration: PropTypes.string,
  commentMap: PropTypes.shape({}),
  initiatedBy: PropTypes.shape({
    name: PropTypes.shape({}),
  }),
};

RequestHistoryCard.defaultProps = {
  attachments: [],
  attachmentMap: undefined,
  actionsByStepMap: {},
  users: [],
  round: 1,
  currentRound: false,
  start: undefined,
  end: undefined,
  duration: undefined,
  commentMap: {},
  initiatedBy: undefined,
};

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";
import WorkflowStepCard from "./WorkflowStepCard";
import WorkflowAddStep from "./WorkflowAddStep";

const WorkflowStepForm = ({
  stepData,
  handleDragInStep,
  handleAddNewStep,
  handleAddParallelStep,
  handleEditStep,
  handleDeleteStep,
  handleMemberResubmission,
  handleMemberAdvance,
  setStepData,
  canEdit,
  template,
  viewing,
  workflowData,
  allMembers,
  workflows,
  dispatch,
  handlePostComment,
  handlePostReply,
  handleAddFiles,
  handleAddReminder,
  currentRef,
  hideDueDate,
  disableEditing,
  currentUser,
}) => {
  const [arrayOfIndices, setArrayOfIndices] = useState([]);
  const [disableAddStep, setDisableAddStep] = useState(false);

  useEffect(() => {
    const durations = stepData.map((step) => step.duration);
    if (durations.includes(null)) {
      setDisableAddStep(true);
    } else {
      setDisableAddStep(false);
    }
  }, [stepData]);

  useEffect(() => {
    const arr = stepData.map((_, i) => i);
    setArrayOfIndices(arr);
  }, [stepData]);

  const unselectCompletedCard = (index) => {
    const uncompletedCards = arrayOfIndices.map((i) => {
      if (i !== index) return i;
      return undefined;
    });
    setArrayOfIndices(uncompletedCards.filter(Boolean));
  };

  const moveColumn = (
    fromParent,
    fromIndex,
    fromIsParallel,
    toParent,
    toIndex,
    toIsParallel
  ) => {
    const tempArr = cloneDeep(stepData);

    if (fromParent === toParent) {
      if (fromIsParallel && toIsParallel) {
        // dragging between parallel steps
        tempArr[fromParent].parallelSteps.splice(
          toIndex,
          0,
          tempArr[fromParent].parallelSteps.splice(fromIndex, 1)[0]
        );
      } else {
        // dragging between parallel and  main step
        const newMain = tempArr[fromParent]?.parallelSteps.splice(
          fromIndex,
          1
        )[0];
        const mainStep = tempArr[fromParent];
        const parallelStepArray = tempArr[fromParent].parallelSteps;
        tempArr[fromParent] = newMain;
        tempArr[fromParent].parallelSteps = parallelStepArray;
        delete mainStep.parallelSteps;
        tempArr[fromParent].parallelSteps.unshift(mainStep);
      }
    } else if (fromIsParallel && toIsParallel) {
      // dragging parallel into parallel

      tempArr[toParent].parallelSteps.splice(
        toIndex,
        0,
        tempArr[fromParent].parallelSteps.splice(fromIndex, 1)[0]
      );
    } else if (fromIsParallel && !toIsParallel) {
      // dragging parallel into main

      const newMain = tempArr[fromParent]?.parallelSteps.splice(
        fromIndex,
        1
      )[0];
      const mainStep = tempArr[toParent];
      const parallelStepArray = tempArr[toParent].parallelSteps;
      tempArr[toParent] = newMain;
      tempArr[toParent].parallelSteps = parallelStepArray;
      delete mainStep.parallelSteps;
      tempArr[toParent].parallelSteps.unshift(mainStep);
    } else if (!fromIsParallel && toIsParallel) {
      // dragging main into parrallel

      const newMain = tempArr[fromParent];
      if (newMain.parallelSteps.length === 0) {
        tempArr.splice(fromParent, 1);
      } else {
        const nextMain = tempArr[fromParent].parallelSteps.splice(0, 1)[0];
        nextMain.parallelSteps = tempArr[fromParent].parallelSteps;
        tempArr[fromParent] = nextMain;
      }
      delete newMain.parallelSteps;
      tempArr[toParent].parallelSteps.splice(toIndex, 0, newMain);
    } else {
      // dragging main step into a main step

      const mainStep = tempArr[toParent];
      const newMain = tempArr[fromParent];
      if (newMain.parallelSteps.length === 0) {
        tempArr.splice(fromParent, 1);
      } else {
        const nextMain = tempArr[fromParent].parallelSteps.splice(0, 1)[0];
        nextMain.parallelSteps = tempArr[fromParent].parallelSteps;
        tempArr[fromParent] = nextMain;
      }
      delete newMain.parallelSteps;
      const parallelStepArray = tempArr[toParent].parallelSteps;
      tempArr[toParent] = newMain;
      tempArr[toParent].parallelSteps = parallelStepArray;
      tempArr[toParent].parallelSteps.unshift(mainStep);
    }
    setStepData(tempArr);
  };

  return (
    <>
      {stepData?.map((step, parentIndex) => {
        return (
          <div key={step.id}>
            <WorkflowStepCard
              prevStep={stepData[parentIndex - 1]}
              data={step}
              index={0}
              parentIndex={parentIndex}
              handleDragInStep={handleDragInStep}
              handleAddNewStep={handleAddNewStep}
              handleAddParallelStep={handleAddParallelStep}
              handleEditStep={handleEditStep}
              handleDeleteStep={handleDeleteStep}
              multiple={stepData[parentIndex].parallelSteps?.length > 0}
              last={stepData[parentIndex]?.parallelSteps?.length === 0}
              canEdit={canEdit}
              moveColumn={moveColumn}
              template={template}
              viewing={viewing}
              handleMemberResubmission={handleMemberResubmission}
              handleMemberAdvance={handleMemberAdvance}
              workflowData={workflowData}
              allMembers={allMembers}
              workflows={workflows}
              totalDuration={stepData[parentIndex - 1]?.totalDuration || 0}
              dispatch={dispatch}
              handlePostComment={handlePostComment}
              handlePostReply={handlePostReply}
              handleAddFiles={handleAddFiles}
              handleAddReminder={handleAddReminder}
              currentRef={currentRef}
              minIndex={arrayOfIndices[0] || 0}
              unselectCompletedCard={unselectCompletedCard}
              hideDueDate={hideDueDate}
              disableEditing={disableEditing}
              startDate={
                parentIndex === 0
                  ? workflowData.startDate
                  : stepData[parentIndex - 1]?.dueDate
              }
              currentUser={currentUser}
            />
            {step?.parallelSteps?.map((info, index) => (
              <WorkflowStepCard
                data={info}
                index={index}
                parentIndex={parentIndex}
                handleDragInStep={handleDragInStep}
                handleAddNewStep={handleAddNewStep}
                handleAddParallelStep={handleAddParallelStep}
                handleEditStep={handleEditStep}
                handleDeleteStep={handleDeleteStep}
                multiple
                last={stepData[parentIndex].parallelSteps?.length === index + 1}
                canEdit={canEdit}
                moveColumn={moveColumn}
                key={info.id}
                template={template}
                viewing={viewing}
                handleMemberResubmission={handleMemberResubmission}
                handleMemberAdvance={handleMemberAdvance}
                workflowData={workflowData}
                isParallel
                allMembers={allMembers}
                workflows={workflows}
                totalDuration={stepData[parentIndex - 1]?.totalDuration || 0}
                dispatch={dispatch}
                handlePostComment={handlePostComment}
                handlePostReply={handlePostReply}
                handleAddFiles={handleAddFiles}
                handleAddReminder={handleAddReminder}
                unselectCompletedCard={unselectCompletedCard}
                currentRef={currentRef}
                hideDueDate={hideDueDate}
                disableEditing={disableEditing}
                startDate={
                  parentIndex === 0
                    ? workflowData.startDate
                    : stepData[parentIndex - 1]?.maxDate
                }
                currentUser={currentUser}
              />
            ))}
          </div>
        );
      })}
      {canEdit && (
        <WorkflowAddStep
          disabled={disableAddStep}
          handleAddNewStep={handleAddNewStep}
          parentIndex={0}
          handleDragInStep={handleDragInStep}
        />
      )}
    </>
  );
};

WorkflowStepForm.propTypes = {
  /**
   * data needed to display workflow steps
   */
  stepData: PropTypes.arrayOf(
    PropTypes.shape({
      duration: PropTypes.number,
      needOnlyOneAdvancer: PropTypes.bool,
      position: PropTypes.number,
      // eslint-disable-next-line react/forbid-prop-types
      documents: PropTypes.arrayOf(PropTypes.any),
      id: PropTypes.string,
      totalDuration: PropTypes.number,
      members: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          reference: PropTypes.string,
          status: PropTypes.string,
          date: PropTypes.string,
          userData: PropTypes.shape({
            name: PropTypes.shape({
              firstName: PropTypes.string,
              lastName: PropTypes.string,
            }),
          }),
        })
      ),
      // eslint-disable-next-line react/forbid-prop-types
      parallelSteps: PropTypes.arrayOf(PropTypes.any),
      name: PropTypes.string,
      description: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      comments: PropTypes.arrayOf(PropTypes.any),
      dueDate: PropTypes.string,
      maxDate: PropTypes.string,
    })
  ),
  /**
   * function used to add a new step when draging and dropping
   */
  handleDragInStep: PropTypes.func,
  /**
   * function used to add a new step to a workflow
   */
  handleAddNewStep: PropTypes.func,
  /**
   * function used to add a parallel step to a workflow
   */
  handleAddParallelStep: PropTypes.func,
  /**
   * function used to edit a step in the workflow
   */
  handleEditStep: PropTypes.func,
  /**
   * function used to delete a step on the workflow
   */
  handleDeleteStep: PropTypes.func,
  /**
   * function used to handle a member requesting a step resubmission
   */
  handleMemberResubmission: PropTypes.func,
  /**
   * function used to handle a member advancing a step
   */
  handleMemberAdvance: PropTypes.func,
  /**
   * setState used to change workflow step data
   */
  setStepData: PropTypes.func,
  /**
   * boolean to determine if steps can be edited
   */
  canEdit: PropTypes.bool,
  /**
   * boolean to determine if the workflow steps are being viewed or edited
   */
  viewing: PropTypes.bool,
  /**
   * information about the overall workflow
   */
  workflowData: PropTypes.shape({
    currentStep: PropTypes.number,
    status: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    startDate: PropTypes.object,
  }),
  /**
   * value of current template selected
   */
  template: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  /**
   * all members selected for this workflow
   */
  allMembers: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  /**
   * unedited workflow data
   */
  // eslint-disable-next-line react/forbid-prop-types
  workflows: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /**
   * total days passed since last step
   */
  totalDuration: PropTypes.number,
  /**
   * workflow dispatch function
   */
  dispatch: PropTypes.func,
  /**
   * handles posting a comment
   */
  handlePostComment: PropTypes.func,
  /**
   * handles posting a reply
   */
  handlePostReply: PropTypes.func,
  /**
   * handles adding file
   */
  handleAddFiles: PropTypes.func,
  handleAddReminder: PropTypes.func,
  currentRef: PropTypes.string,
  hideDueDate: PropTypes.bool,
  disableEditing: PropTypes.bool,
  currentUser: PropTypes.shape({}),
};

WorkflowStepForm.defaultProps = {
  stepData: [],
  handleDragInStep: undefined,
  handleAddNewStep: undefined,
  handleAddParallelStep: undefined,
  handleEditStep: undefined,
  handleDeleteStep: undefined,
  handleMemberResubmission: undefined,
  handleMemberAdvance: undefined,
  setStepData: undefined,
  canEdit: undefined,
  viewing: undefined,
  workflowData: {},
  allMembers: undefined,
  workflows: undefined,
  totalDuration: undefined,
  dispatch: undefined,
  handlePostComment: undefined,
  handlePostReply: undefined,
  template: undefined,
  handleAddFiles: () => {},
  handleAddReminder: undefined,
  currentRef: undefined,
  hideDueDate: false,
  disableEditing: false,
  currentUser: undefined,
};

export default WorkflowStepForm;

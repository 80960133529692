/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import cntl from "cntl";
import Dropzone from "react-dropzone";

import DragDropDialog from "../DragDropDialog/DragDropDialog";

import dogEarDashed from "../../assets/images/dogEarDashed.svg";
import dogEarDashedGreen from "../../assets/images/dogEarDashedGreen.svg";
import handIcon from "../../assets/images/handIcon.svg";
import { fileCardStyle, uploadAreaStyle } from "../../../constants";
import "../FileForm/PureFileForm.css";
import { calcSvgStrokeOffset } from "../../../helpers/svg";

const uploadAreaCN = cntl`
  flex
  flex-col
  items-center
  h-64
  rounded
  hover:border-brandGreen
  cursor-pointer
  upload_area_click
`;
const overlayStyling = cntl`
  flex
  flex-1
  flex-col
  items-center
  h-full
  w-full
  text-white
  cursor-pointer
  upload_area_click
`;
const extns = "";

const PureDocumentUploadForm = ({
  extensions,
  defaultType,
  onUpload,
  onNewDocType,
  showCorner,
  isOverlay,
  noModal,
  small,
  disableHooks,
  isSop,
  sopStyle,
}) => {
  const [hovering, setHovering] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [defaultExtensions] = useState(extns);

  const widthStyling = small ? fileCardStyle : sopStyle || uploadAreaStyle;

  const onDrop = async (droppedFiles) => {
    let tmpFiles = droppedFiles?.map((file) => {
      return {
        id: uuidv4(),
        original: file,
        name: file.name,
        type: file.type,
        ...file,
      };
    });

    if (isSop) {
      tmpFiles = tmpFiles.filter(
        (file) => file.type.includes("image/") || file.type.includes("video/")
      );
    }

    if (isOverlay || noModal) {
      onUpload(tmpFiles, () => {});
    } else {
      setShowUpload(true);
      setFilesToUpload(tmpFiles);
    }
  };

  const handleFilesAdded = React.useCallback(
    (addedFiles) => {
      setFilesToUpload(addedFiles);
    },
    [setFilesToUpload]
  );
  const handleFilesUpdated = (updatedFiles) => {
    setFilesToUpload(updatedFiles);
  };
  const handleFilesUploaded = async () => {
    setShowUpload(false);
    await onUpload(filesToUpload, () => {});
    setFilesToUpload([]);
  };
  const handleCancelClick = () => {
    setShowUpload(false);
    setFilesToUpload([]);
  };

  const buttonRef = useRef();
  const { height, width } = buttonRef?.current?.getBoundingClientRect() ?? {
    height: 0,
    width: 0,
  };

  const style = useMemo(() => {
    let styleCN = {
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect ${calcSvgStrokeOffset(
        height,
        width
      )} fill='none' stroke='%2309AB86' rx='6' stroke-width='3' stroke-dasharray='7px 15px' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    };

    if (sopStyle || isOverlay) {
      styleCN = { ...styleCN, ...widthStyling, margin: "0px" };
    } else {
      styleCN = { ...styleCN, ...widthStyling };
    }

    return styleCN;
  }, [height, isOverlay, sopStyle, width, widthStyling]);

  return (
    <>
      <div
        className={isOverlay ? overlayStyling : uploadAreaCN}
        onMouseOver={() => setHovering(true)}
        onMouseOut={() => setHovering(false)}
        onFocus={() => {}}
        onBlur={() => setHovering(false)}
        style={style}
        ref={buttonRef}
      >
        {!sopStyle && (
          <img
            className={`self-end w-20 h-8 select-none ${
              !showCorner ? "invisible" : ""
            }`}
            style={{ margin: "-3px -25px 0 0" }}
            src={hovering ? dogEarDashedGreen : dogEarDashed}
            alt="file corner"
          />
        )}
        <Dropzone accept={extensions ?? defaultExtensions} onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({
                className: "w-full h-full document_upload_form",
              })}
            >
              <input
                {...getInputProps()}
                style={{ visibility: "hidden", display: "flex" }}
                id="upload-form-input"
              />

              <div className="flex flex-col items-center justify-center">
                <p
                  className={` text-base font-semibold ${
                    isOverlay ? "text-white" : "text-gray-650"
                  }`}
                >
                  Drag & Drop
                </p>
                <p
                  className={` text-base font-semibold ${
                    isOverlay ? "text-white" : "text-gray-650"
                  }`}
                >
                  Image/Video
                </p>
                <img
                  className={isOverlay ? "h-10 mt-1" : "h-10 mt-4"}
                  src={handIcon}
                  alt="hand icon"
                />
                <div className="flex flex-col items-center font-semibold text-md mt-4">
                  <p>
                    or <span className="text-brandGreen underline">browse</span>
                  </p>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
      </div>
      <DragDropDialog
        files={filesToUpload}
        extensions={extensions ?? defaultExtensions}
        defaultType={defaultType}
        onFilesAdded={handleFilesAdded}
        onFilesUpdated={handleFilesUpdated}
        onFilesUploaded={handleFilesUploaded}
        onNewDocType={onNewDocType}
        onClose={handleCancelClick}
        show={showUpload}
        disableHooks={disableHooks}
      />
    </>
  );
};

PureDocumentUploadForm.propTypes = {
  extensions: PropTypes.string,
  defaultType: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  onNewDocType: PropTypes.func,
  showCorner: PropTypes.bool,
  isOverlay: PropTypes.bool,
  small: PropTypes.bool,
  /**
   * No Overlay
   * But same functionality
   */
  noModal: PropTypes.bool,
  /**
   * Disable hooks eg. (Media Upload Widget)
   */
  disableHooks: PropTypes.bool,
  isSop: PropTypes.bool,
  sopStyle: PropTypes.shape({}),
};

PureDocumentUploadForm.defaultProps = {
  extensions: undefined,
  defaultType: undefined,
  onNewDocType: undefined,
  showCorner: false,
  isOverlay: false,
  small: false,
  noModal: false,
  disableHooks: false,
  isSop: false,
  sopStyle: undefined,
};

export default PureDocumentUploadForm;

import useReportRun from "../../../hooks/useReportRun";

const useRunReportButtonData = ({ reportId }) => {
  const { mutate, isLoading } = useReportRun();

  const handleRunReport = () => {
    mutate(reportId);
  };

  return { handleRunReport, isRunning: isLoading };
};

export default useRunReportButtonData;

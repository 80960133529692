/* eslint-disable no-param-reassign */

import moment from "moment";
import { useReducer } from "react";
import { v4 as uuidv4 } from "uuid";
import { guessTimeZone } from "../helpers/Time";

const defaultState = () => {
  const initStart =
    moment().minutes() > 30
      ? moment().startOf("hour").add(30, "minutes").format()
      : moment().startOf("hour").format();
  const getEndDate = (start) => moment(start).add(30, "minutes").format();

  return {
    name: "",
    description: "",
    startDate: initStart,
    endDate: getEndDate(initStart),
    status: "scheduled",
    invitees: [],
    association: undefined,
    files: [],
    recurrence: "",
    steps: [],
    originalResource: {},
    currentTags: [],
    timezone: guessTimeZone()?.label,
    duration: {},
    priority: "medium",
    space: "",
    spaces: [],
    assets: [],
    allDay: false,
  };
};

const reducer = (task, action) => {
  const initStart =
    moment().minutes() > 30
      ? moment().startOf("hour").add(30, "minutes").format()
      : moment().startOf("hour").format();

  const getEndDate = (start) => moment(start).add(30, "minutes").format();

  switch (action.type) {
    case "update":
      return { ...task, ...action.value };
    case "reset": {
      const startDate = action.startDate
        ? moment(action.startDate)
            .set("hour", moment().get("hour"))
            .add(1, "hour")
            .set("minutes", 0)
            .format()
        : initStart;
      const endDate = getEndDate(startDate);

      return action.associated
        ? {
            ...defaultState(),
            association: action.associated,
            startDate,
            endDate,
            files: [],
          }
        : {
            ...defaultState(),
            startDate,
            endDate,
            files: [],
          };
    }
    case "setCurrentTags": {
      return { ...task, currentTags: action.currentTags };
    }
    case "description":
      return { ...task, description: action.value };
    case "recurrenceEditingTASK":
      return {
        ...task,
      };
    case "association":
      return {
        ...task,
        association: action.value,
        invitees: [],
        spaces: [],
        assets: [],
      };
    case "startDate": {
      /**
       * Case 1:
       * All Day is toggled
       * End Date Input is disabled
       * Start Date is set to the beginning of selection
       * End Date is set to the end of the start day selection
       */
      if (action.allDay) {
        const begin = moment
          .tz(action.value, task.timezone)
          .startOf("day")
          .format();

        const end = moment
          .tz(action.endDate, task.timezone)
          .endOf("day")
          .format();
        return { ...task, startDate: begin, endDate: end, allDay: true };
      }

      /**
       * Case 2:
       * By Default End Date is an hour after start
       */
      return {
        ...task,
        startDate: action.value,
        endDate: action.endDate,
      };
    }

    case "endDate": {
      let startDate = moment.tz(task.startDate, task.timezone);
      let endDate = moment.tz(action.value, task.timezone);

      // Task must end after it starts.
      // `startDate` will be set to the same `endDate` but with 1 hour less
      if (moment(endDate).isBefore(startDate)) {
        startDate = moment.tz(endDate, task.timezone).subtract(1, "hours");
      }

      if (action.allDay) {
        startDate = startDate.startOf("day");
        endDate = endDate.endOf("day");
      }

      return {
        ...task,
        startDate: startDate.format(),
        endDate: endDate.format(),
      };
    }

    case "allDay":
      return {
        ...task,
        allDay: action.value,
      };
    case "name":
      return { ...task, name: action.value };
    case "editInvite":
      return { ...task, invitees: action.value };
    case "addInvite":
      return { ...task, invitees: [...task.invitees, action.value] };
    case "storePrevious":
      return {
        ...task,
        previousTask: action.value,
        originalKey: action.originalKey,
      };
    case "editMode":
      return {
        ...action.task,
        previousTask: action.previous,
        originalKey: action.originalKey,
      };
    case "removeInvite":
      return {
        ...task,
        invitees: task.invitees.filter(
          (user) => user !== action.value && user?.value !== action.value
        ),
      };
    case "addAttachment": {
      return {
        ...task,
        files: action.value,
      };
    }
    case "removeAttachment": {
      return {
        ...task,
        files: task.files.filter(
          (item) => item.id !== action.id && item.ref !== action.id
        ),
      };
    }
    case "recurrence": {
      return {
        ...task,
        recurrence: action.value.value,
      };
    }
    case "addStep":
      return {
        ...task,
        steps: [
          ...task.steps,
          {
            name: "",
            description: "",
            sop: "",
            date: moment().format(),
            id: uuidv4(),
          },
        ],
      };
    case "editStep": {
      const steps = task?.steps?.reduce((list, item, index) => {
        if (action.index === index) {
          const editedStep = { ...item, [action.key]: action.value };

          // if adding sop remove description and vice versa
          delete editedStep?.[
            ["sop", "description"].find((x) => x !== action.key)
          ];

          if (editedStep?.sop) {
            editedStep.sopVersion = action.version;
          }

          list.push(editedStep);
        } else {
          list.push(item);
        }
        return list;
      }, []);
      return { ...task, steps };
    }
    case "removeStep": {
      const steps = [...task?.steps];
      steps.splice(action.index, 1);
      return { ...task, steps };
    }
    case "updateStatus": {
      return { ...task, status: action.status };
    }
    case "duration": {
      return {
        ...task,
        duration: { ...(task?.duration ?? {}), [action.key]: action.value },
      };
    }
    case "timezone":
      return {
        ...task,
        timezone: action.value,
      };
    case "selectSpaces":
      return {
        ...task,
        spaces: [...task?.spaces, { id: action.value, isCompleted: false }],
      };
    case "removeSpaces":
      return {
        ...task,
        spaces:
          task?.spaces?.filter((space) => space.id !== action.value) ?? [],
      };
    case "selectAssets":
      return {
        ...task,
        assets: [...task?.assets, { ref: action.value, isCompleted: false }],
      };
    case "removeAssets":
      return {
        ...task,
        assets:
          task?.assets?.filter((asset) => asset.ref !== action.value) ?? [],
      };
    case "removeAssociatedSpacesAssets":
      return {
        ...task,
        spaces: [],
        assets: [],
      };
    case "addLinkCompleteLink":
      return {
        ...task,
        links: [...(task.links ?? []), action.link],
      };
    case "deleteLink":
      return {
        ...task,
        links: task.links?.filter((link) => link.id !== action.linkId) ?? [],
      };
    default:
      return defaultState();
  }
};

export default (initialState) => {
  return useReducer(
    reducer,
    initialState
      ? {
          ...defaultState(),
          ...initialState,
          originalResource: initialState,
        }
      : defaultState()
  );
};

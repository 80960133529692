import Api, { DocumentAPI, FileAPI } from "@griffingroupglobal/eslib-api";
import axios from "axios";
import heicConvert from "heic-convert";
import { Buffer } from "buffer";
import { docTypeOptionsMap } from "../constants";

window.Buffer = window.Buffer || Buffer;

const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

/**
 * @deprecated
 */
export const resolveFileRef = async (ref) => {
  if (ref) {
    try {
      const id = ref?.split("/")[1];
      const { data } = await FileAPI.getById(id);
      return data;
    } catch (err) {
      console.warn("file error", err);
    }
  }
  return {};
};

export const resolveFileRefByQuery = async (refs) => {
  const response = {
    files: [],
    filesDict: {},
  };

  if (refs?.length)
    try {
      const dict = {};
      const { data } = await FileAPI.postWOP("$getbyreferences", {
        reference: refs.join(),
      });
      data?.forEach((resource) => {
        dict[`${resource.reference}`] = resource;
      });

      response.files = data;
      response.filesDict = dict;
    } catch (err) {
      console.warn("file error", err);
    }
  return response;
};

export const resolveResourceImages = async (
  imageRefs,
  resources,
  primaryImageKey
) => {
  const { filesDict } = await resolveFileRefByQuery(imageRefs);

  const resolved = resources.map((resource) => {
    if (resource?.[`${primaryImageKey}`]) {
      return {
        ...resource,
        [`${primaryImageKey}`]:
          filesDict[`${resource?.[`${primaryImageKey}`]}`],
      };
    }

    return resource;
  });

  return resolved;
};

/**
 * @deprecated
 */
export const resolvePrimaryImage = async (images) => {
  if (images && images.length > 0) {
    const { file } = images.find((image) => image.isPrimary) ?? images[0];
    const primaryImgFile = await resolveFileRef(file);
    return primaryImgFile?.category === "Photos" ? primaryImgFile : {};
  }
  return undefined;
};

// e.g. converts file.heic -> file.jpeg
const convertHeicToJpegFileName = (heicName) => {
  const formattedName = heicName.toLowerCase();
  const validExtensions = [".heic", ".heif"];

  const index = validExtensions.findIndex((ext) => {
    return formattedName?.endsWith(ext);
  });

  if (index !== -1) {
    const extLength = validExtensions[index].length;
    return `${heicName.substring(0, heicName.length - extLength)}.jpeg`;
  }

  return heicName;
};

const convertHeicToJpeg = async (file) => {
  // convert before storing
  const buffer = Buffer.from(await file.arrayBuffer());
  const jpegBuffer = await heicConvert({ buffer, format: "JPEG" });
  const base64String = Buffer.from(jpegBuffer).toString("base64");
  const dataUrl = `data:image/jpeg;base64,${base64String}`;

  const fileName = convertHeicToJpegFileName(file.name);

  // eslint-disable-next-line no-param-reassign
  file = dataURLtoFile(dataUrl, fileName);
  return file;
};

export const uploadFile = async (
  file,
  uploadProgress,
  getUploadUrl = FileAPI.post,
  saveImmediately = true,
  baseRequestBody,
  fileName
) => {
  let body = {
    ...baseRequestBody,
    name: file.name || fileName,
    contentType: file.type,
    size: file.size,
  };

  const isHeic = body.contentType.includes("heic");
  const isHeif = body.contentType.includes("heif");

  if (isHeic || isHeif) {
    // eslint-disable-next-line no-param-reassign
    file = await convertHeicToJpeg(file);
    body = {
      ...body,
      name: file.name,
      contentType: file.type,
      size: file.size,
    };
  }

  const {
    data: { uploadUrl, id },
  } = await getUploadUrl(body);

  const instance = axios.create({});

  await instance.put(uploadUrl, file, {
    headers: { "Content-Type": file.type },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      if (uploadProgress) {
        uploadProgress(loaded, total);
      }
    },
  });

  if (!saveImmediately) {
    return null;
  }

  const {
    data: { reference },
  } = await Api.post(`/api/File/${id}/$uploaded`);
  return reference;
};

export const uploadFileWithData = async (
  file,
  data,
  uploadProgress,
  getUploadUrl = FileAPI.post,
  saveImmediately = true,
  baseRequestBody,
  returnResource
) => {
  let body = {
    ...baseRequestBody,
    name: file.name,
    contentType: file.type,
    size: file.size,
    ...data,
  };

  const isHeic = body.contentType.includes("heic");
  const isHeif = body.contentType.includes("heif");

  if (isHeic || isHeif) {
    // eslint-disable-next-line no-param-reassign
    file = await convertHeicToJpeg(file);
    body = {
      ...body,
      name: file.name,
      contentType: file.type,
      size: file.size,
    };
  }
  const {
    data: { uploadUrl, id },
  } = await getUploadUrl(body);

  const instance = axios.create({});

  await instance.put(uploadUrl, file, {
    headers: { "Content-Type": file.type },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      if (uploadProgress) {
        uploadProgress(loaded, total);
      }
    },
  });

  if (!saveImmediately) {
    return null;
  }

  const { data: uploadedFile } = await Api.post(`/api/File/${id}/$uploaded`);

  if (returnResource) {
    return uploadedFile;
  }

  return uploadedFile.reference;
};

export const bytesToSize = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
};

export const uploadEmployeeDocument = async (
  document,
  docType,
  progressCallback,
  getDocUrl = DocumentAPI.post,
  addDocumentAttachment = DocumentAPI.patch,
  baseRequestBody
) => {
  const body = {
    ...baseRequestBody,
    customName: document.filename,
    docType: docTypeOptionsMap[docType]?.value,
    //  Todo: status: "published",
  };

  const { data } = await getDocUrl(body);

  const fileRef = await uploadFile(document.file, progressCallback);

  const patchBody = { ...data, attachments: [...data.attachments, fileRef] };

  const response = await addDocumentAttachment(data.id, patchBody, data);

  return response.data;
};

export const getAttachment = async (url, contentType) => {
  const file = await fetch(url).then((res) => {
    return res.arrayBuffer();
  });

  const blob = new Blob([file], {
    type: contentType,
  });
  const fileUrl = URL.createObjectURL(blob);

  return fileUrl;
};

export const formatMediaPatch = (media) => {
  const formatted = media?.map((file) => {
    return file.contentType.includes("video/")
      ? { isPrimary: false, file: file?.reference, contentType: "video" }
      : { isPrimary: false, file: file?.reference };
  });

  return formatted;
};

export const getImages = async (images) => {
  const fileQueryString = images
    ? `?reference=${images.reduce(
        (str, val) => (str ? `${str},${val}` : `${val}`),
        ""
      )}`
    : "";
  const {
    data: { entries: files },
  } = await FileAPI.getWOP(fileQueryString);
  return files;
};

const getAttachments = async (media) => {
  const objectUrls = Promise.all(
    [...media].map((val) => {
      return getAttachment(val?.url, val?.contentType);
    })
  );

  return objectUrls;
};

export const downloadMedia = async (fileRefs) => {
  let media = await getImages(fileRefs);
  media = media?.map((file) => ({
    url: file?.resource?.contentsUrl,
    contentType: file?.resource?.contentType,
    name: file?.resource?.name,
  }));

  const urls = await getAttachments(media);
  // eslint-disable-next-line vars-on-top, no-plusplus, no-var
  for (var i = 0; i < urls?.length; i++) {
    const downloadLink = document.createElement("a");
    downloadLink.style.display = "none";

    document.body.appendChild(downloadLink);

    downloadLink.setAttribute("href", urls[i]);
    downloadLink.setAttribute("download", media[i]?.name);
    downloadLink.click();

    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(urls[i]);
  }
};

export const getFileTypeKey = (file) => {
  if (file?.type?.includes("image") || file?.contentType?.includes("image")) {
    return "images";
  }
  if (file?.type?.includes("video") || file?.contentType?.includes("video")) {
    return "videos";
  }
  return "files";
};

/**
 * Used to show recent files using react select async paginate
 * @param {*} search - name to search file by
 * @param {*} loadedOptions - list of options already fetched
 * @param {*} param2 - page param to fetch api data
 * @returns
 */
export const fetchRecentFiles = async (
  search,
  loadedOptions,
  { page },
  userRef,
  documentTypeFilter
) => {
  let entries = [];
  let pages = 0;
  try {
    const { data } = await Api.get("/api/File", {
      paging: false,
      params: {
        limit: 20,
        page,
        name: search,
        "metadata.createdBy": userRef,
      },
    });

    entries = data.entries.reduce((acc, { resource }) => {
      if (!resource.clonedFrom) {
        if (documentTypeFilter && resource?.category === documentTypeFilter) {
          // take only documents specified in documentTypeFilter
          acc.push({
            ...resource,
            label: resource.name,
            value: resource.reference,
          });
        } else if (!documentTypeFilter) {
          // take all documents if no filter provided
          acc.push({
            ...resource,
            label: resource.name,
            value: resource.reference,
          });
        }
      }
      return acc;
    }, []);

    // eslint-disable-next-line no-param-reassign
    page = data.page;
    pages = data.pages;
  } catch (err) {
    console.error(err);
  }

  return {
    options: entries,
    hasMore: page < pages,
    additional: {
      page: page + 1,
    },
  };
};

export const onUpdateFile = async ({
  originalResource,
  currentTags,
  patchFile,
  name,
}) => {
  await patchFile({
    id: originalResource?.id,
    prevFile: originalResource,
    updatedFile: {
      ...originalResource,
      tags: currentTags?.map((tag) => tag?.value),
      name,
    },
  });
};

export const splitFileNameAndExtension = (filename) => {
  if (!filename) {
    return { name: "", ext: "" };
  }
  const name = filename.substring(0, filename.lastIndexOf("."));
  const ext = filename.substring(filename.lastIndexOf("."));
  return { name, ext };
};

import moment from "moment";

/**
 * This function will take the end date, subtract a week then check to see if thats before the users hireDate
 *
 * @param {*} user
 * @param {*} minDate
 * @returns boolean
 */
const wasUserHiredAfterDate = (user, minDate = undefined) => {
  if (!user || !minDate) return false;

  const usersHireDate = user?.employeeInfo?.hireDate;

  if (!usersHireDate || !moment(usersHireDate, moment.ISO_8601, true).isValid())
    return false;

  if (!moment(minDate, moment.ISO_8601, true).isValid()) return false;

  const minDateMinusWeek = moment(minDate).subtract(1, "week");
  const isAfter = moment(usersHireDate).isAfter(minDateMinusWeek);

  if (isAfter) return true;

  return false;
};

export default wasUserHiredAfterDate;

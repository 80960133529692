import React, { useState } from "react";
import PropTypes from "prop-types";
import Comments from "./Comments";
import NewComment from "./NewComment";
import TertiaryButton from "../Buttons/TertiaryButton";
import crossIcon from "../../assets/images/crossIcon.svg";
import Spinner from "../Spinner/Spinner";

const PureCommentWFSection = ({
  currentUser,
  commentData,
  handlePostComment,
  handlePostReply,
  allowNewComment,
  showClose,
  handleClose,
  isLoading,
}) => {
  const emptyComment = {
    user: {
      name: `${currentUser?.name?.firstName} ${currentUser?.name?.lastName}`,
      reference: currentUser?.reference,
    },
  };
  const [showNewComment, setShowNewComment] = useState(false);
  const handleCancelComment = () => {
    setShowNewComment(false);
  };
  const handleNewComment = () => {
    setShowNewComment(true);
  };
  const handleAddComment = (val) => {
    handlePostComment(val);
  };

  return (
    <div className="flex flex-col justify-between w-full py-4 bg-white">
      <div className="flex justify-between items-cemter">
        <h2 className="flex justify-end font-bold text-gray-400 text-lg pr-6">
          Comments
        </h2>
        {showClose && (
          <button
            className="flex items-center h-6 mt-0.5"
            type="button"
            onClick={handleClose}
          >
            <img className="h-4" src={crossIcon} alt="close icon" />
          </button>
        )}
      </div>

      {(!showClose || showNewComment || !!commentData.length) && (
        <div className="flex-1">
          {commentData.map((info, index) => {
            return (
              <div key={info.reference}>
                <Comments
                  data={info}
                  index={index}
                  user={emptyComment?.user}
                  handlePostReply={handlePostReply}
                  allowNewComment={allowNewComment}
                />
              </div>
            );
          })}

          {showNewComment && (
            <NewComment
              user={emptyComment?.user}
              handleAddComment={handleAddComment}
              handleCancelComment={handleCancelComment}
            />
          )}
        </div>
      )}

      {!showNewComment && (
        <div className="flex flex-col">
          {allowNewComment && (
            <div className="py-4">
              <TertiaryButton
                title="+ Add Comment"
                className="whitespace-nowrap border border-white"
                onClick={handleNewComment}
              />
            </div>
          )}
        </div>
      )}
      {(isLoading || !currentUser) && <Spinner />}
    </div>
  );
};
PureCommentWFSection.propTypes = {
  /**
   * current user data
   */
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    reference: PropTypes.string,
    resource: PropTypes.string,
  }),
  /**
   * data displayed in the comments
   */
  commentData: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string,
      content: PropTypes.string,
      id: PropTypes.string,
      metadata: PropTypes.shape({
        createdAt: PropTypes.string,
      }),
      replies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          metadata: PropTypes.shape({
            createdAt: PropTypes.string,
          }),
          userData: PropTypes.shape({
            name: PropTypes.shape({
              firstName: PropTypes.string,
              lastName: PropTypes.string,
            }),
          }),
        })
      ),
      resource: PropTypes.string,
      userData: PropTypes.shape({
        name: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      }),
    })
  ),
  /**
   * function called when a comment is posted
   */
  handlePostComment: PropTypes.func,
  /**
   * function called when a reply is posted
   */
  handlePostReply: PropTypes.func,
  /**
   * boolean to allow users to add comments or replies
   */
  allowNewComment: PropTypes.bool,
  showClose: PropTypes.bool,
  handleClose: PropTypes.func,
  isLoading: PropTypes.bool,
};

PureCommentWFSection.defaultProps = {
  currentUser: undefined,
  commentData: [],
  handlePostComment: undefined,
  handlePostReply: undefined,
  allowNewComment: undefined,
  showClose: undefined,
  handleClose: undefined,
  isLoading: false,
};
export default PureCommentWFSection;

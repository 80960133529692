import React, { useCallback, useState } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import Input from "../Input/Input";
import BaseButton from "../Buttons/BaseButton";
import removeIcon from "../../assets/images/Remove.svg";
import editIcon from "../../assets/images/editIcon.svg";
import DivisionCodesAddCodePopover from "./DivisionCodesAddCodePopover";
import { toastError } from "../../../helpers/Toast";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../assets/images/whiteExclamationIcon.svg";
import PlusCirlceIconDarkGreen from "../../assets/images/plusCircleIconDarkGreen.svg";

const tertiaryButtonCN = cntl`
text-brandDarkGreen
py-4
text-base
`;

const textCN = cntl`
font-medium
truncate
`;

const deleteButtonCN = cntl`
  ml-2
  w-5
  h-5
`;

const editButtonCN = (className) => cntl`
  ml-2
  w-5
  h-5
  ${className}
`;

const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);

const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const initialState = {
  code: "",
  description: "",
};

const validateUniqueness = (selectedDivisionCode, divisonCodes) => {
  let isUnique = true;
  divisonCodes.map((divCode) => {
    if (divCode.code === selectedDivisionCode.code) {
      isUnique = false;
    }
    return divCode;
  });
  return isUnique;
};

const DivisionCodesSection = ({
  financialsSettings,
  dispatchFinancialsSettings,
}) => {
  const [newDivisionCode, setNewDivisionCode] = useState(initialState);
  const [showAddCodePopover, setShowAddCodePopover] = useState(false);

  const onDescriptionChange = useCallback(
    (index, value) => {
      dispatchFinancialsSettings({
        type: "changeDivCodeDescription",
        index,
        value,
      });
    },
    [dispatchFinancialsSettings]
  );

  const onAdd = useCallback(() => {
    setShowAddCodePopover(true);
  }, []);

  const onClose = useCallback(() => {
    setNewDivisionCode(initialState);
    setShowAddCodePopover(false);
  }, []);

  const onRemove = useCallback(
    (index) => {
      dispatchFinancialsSettings({
        type: "removeDivCode",
        index,
      });
    },
    [dispatchFinancialsSettings]
  );

  const toggleEditDivcode = useCallback(
    (index) => {
      dispatchFinancialsSettings({
        type: "toggleEditDivCode",
        index,
      });
    },
    [dispatchFinancialsSettings]
  );

  const onFinishAdding = useCallback(
    (addAnother) => {
      const isValid = validateUniqueness(
        newDivisionCode,
        financialsSettings?.divisionCodes
      );
      if (isValid) {
        dispatchFinancialsSettings({
          type: "addDivCode",
          description: newDivisionCode.description,
          code: newDivisionCode.code,
        });
        setNewDivisionCode(initialState);
        setShowAddCodePopover(addAnother);
      } else {
        toastError(
          "Division Code already exists. Choose a unique code.",
          toastErrorIcon,
          toastCloseIcon
        );
      }
    },
    [
      dispatchFinancialsSettings,
      financialsSettings?.divisionCodes,
      newDivisionCode,
    ]
  );

  return (
    <div className="flex flex-col">
      <div className="flex flex-row pt-4 py-1">
        {!!financialsSettings?.divisionCodes?.length && (
          <>
            <div className="py-1 w-40 overflow-ellipsis pr-4">
              <h3 className="font-bold">Division Code</h3>
            </div>
            <div className="py-1">
              <h3 className="font-bold">Description</h3>
            </div>
          </>
        )}
      </div>
      {financialsSettings?.divisionCodes?.map((divCode, index) => {
        return (
          <div key={divCode?.id}>
            {(divCode?.isEditing && (
              <div className="flex flex-row py-2">
                <div className="pb-1 w-40 overflow-ellipsis pr-4">
                  <p className="text-gray-200 truncate">{divCode?.code}</p>
                </div>
                <div className="flex w-120 -mt-2">
                  <Input
                    value={divCode.description}
                    placeholder="Description"
                    onChange={(val) => onDescriptionChange(index, val)}
                    onBlur={() => toggleEditDivcode(index)}
                    mainClassName="w-full"
                  />
                </div>
                <div className="flex">
                  <button
                    className={editButtonCN("mt-0")}
                    type="button"
                    onClick={() => toggleEditDivcode(index)}
                  >
                    <img
                      className="w-8 cursor-pointer"
                      src={editIcon}
                      alt="edit data"
                    />
                  </button>
                </div>
              </div>
            )) || (
              <div className="flex flex-row">
                <div className="pb-1 w-40 overflow-ellipsis pr-4">
                  <p className={textCN}>{divCode?.code}</p>
                </div>
                <div className="pb-1">
                  <p className={textCN}>{divCode.description}</p>
                </div>
                {divCode?.custom && (
                  <div className="pl-2">
                    <button
                      className={deleteButtonCN}
                      type="button"
                      onClick={() => onRemove(index)}
                    >
                      <img className="w-8" src={removeIcon} alt="remove data" />
                    </button>
                  </div>
                )}
                {divCode?.custom && (
                  <div className="flex">
                    <button
                      className={editButtonCN("mt-0")}
                      type="button"
                      onClick={() => toggleEditDivcode(index)}
                    >
                      <img
                        className="w-8 cursor-pointer"
                        src={editIcon}
                        alt="edit data"
                      />
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
      <div className="py-4">
        {(showAddCodePopover && (
          <DivisionCodesAddCodePopover
            newDivisionCode={newDivisionCode}
            setNewDivisionCode={setNewDivisionCode}
            onFinishAdding={onFinishAdding}
            onClose={onClose}
          />
        )) || (
          <div className="py-4 flex flex-row items-center">
            <img
              className="h-4 w-4"
              src={PlusCirlceIconDarkGreen}
              alt="plus circle icon green"
            />
            <BaseButton
              title="Add Division Code"
              onClick={onAdd}
              className={tertiaryButtonCN}
            />
          </div>
        )}
      </div>
    </div>
  );
};

DivisionCodesSection.propTypes = {
  financialsSettings: PropTypes.shape({
    divisionCodes: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        description: PropTypes.string,
      })
    ),
  }),

  dispatchFinancialsSettings: PropTypes.func,
};

DivisionCodesSection.defaultProps = {
  financialsSettings: undefined,
  dispatchFinancialsSettings: undefined,
};

export default DivisionCodesSection;

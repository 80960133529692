import React, { useCallback, useState } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";
import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";
import PrimaryButton from "../Buttons/PrimaryButton";
import CrossButton from "../CrossButton/CrossButton";
import FormHierarchy from "../FormHierarchy/FormHierarchy";
import useActualFinancialsFormReducer from "../../../hooks/useActualFinancialsFormReducer";

const popOverCN = cntl`
  bg-white
  flex
  flex-col
  pt-2
  px-2
  overflow-x-auto
  sticky
  left-0
  pb-24
`;

const rowCN = cntl`
  flex
`;

const rowItemCN = cntl`
  px-2
  flex-1
  min-w-max
`;

const initialAddState = {};

const ActualFinancialsTableInLineForm = ({
  row,
  togglePopOver,
  setIsPopoverOpen,
  isEdit,
  onAddSaveCallback,
  onEditSaveCallback,
}) => {
  const [financial, dispatch] = useActualFinancialsFormReducer(
    isEdit ? row.original : initialAddState
  );

  const [addAnother, setAddAnother] = useState(false);

  const onEditSave = useCallback(async () => {
    const editedFinancial = cloneDeep(financial);
    await onEditSaveCallback(editedFinancial);
    togglePopOver();
  }, [financial, onEditSaveCallback, togglePopOver]);

  const onAddSave = useCallback(async () => {
    const createdFinancial = cloneDeep(financial);
    await onAddSaveCallback(createdFinancial);
    togglePopOver();
    if (addAnother) {
      setIsPopoverOpen(true);
    }
  }, [
    financial,
    onAddSaveCallback,
    togglePopOver,
    addAnother,
    setIsPopoverOpen,
  ]);

  const onSave = useCallback(async () => {
    if (isEdit) {
      await onEditSave();
    } else {
      await onAddSave();
    }
  }, [isEdit, onAddSave, onEditSave]);

  // list of column content to be shown in the popover
  const columns = [
    {
      id: "Category Number",
      content: (
        <FormHierarchy>
          <Input
            label="Category Number"
            placeholder="Number"
            value={financial?.categoryNumber}
            onChange={(categoryNumber) => {
              dispatch({
                type: "categoryNumber",
                categoryNumber,
              });
            }}
          />
          <Input
            label="Category Name"
            placeholder="Name"
            value={financial?.categoryName}
            onChange={(categoryName) => {
              dispatch({
                type: "categoryName",
                categoryName,
              });
            }}
          />
          <Input
            label="Sub Category Name"
            placeholder="Name"
            value={financial?.subcategoryName}
            onChange={(subcategoryName) => {
              dispatch({
                type: "subcategoryName",
                subcategoryName,
              });
            }}
          />
        </FormHierarchy>
      ),
    },
    {
      id: "Project Name",
      content: (
        <>
          <Input
            label="Project Name"
            placeholder="Name"
            value={financial?.projectName}
            onChange={(projectName) => {
              dispatch({
                type: "projectName",
                projectName,
              });
            }}
          />
          <Input
            label="Description"
            isTextarea
            placeholder="Description"
            value={financial?.description}
            onChange={(description) => {
              dispatch({
                type: "description",
                description,
              });
            }}
          />
        </>
      ),
    },
  ];

  return (
    <div className={popOverCN}>
      <div className="flex justify-end py-2">
        <CrossButton className="color" onClick={() => togglePopOver()} />
      </div>
      <div className={rowCN}>
        {columns?.map((option) => (
          <div className={rowItemCN} key={option.id}>
            {option.content}
          </div>
        ))}
        <div className="flex flex-col px-2 pt-7">
          <PrimaryButton title="Save" className="mb-2" onClick={onSave} />
          {!isEdit && (
            <Checkbox
              className="flex items-center"
              label="Add Another"
              checked={addAnother}
              onChange={setAddAnother}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ActualFinancialsTableInLineForm.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number,
    original: {
      line: PropTypes.string,
    },
  }),
  togglePopOver: PropTypes.func,
  setIsPopoverOpen: PropTypes.func,
  isEdit: PropTypes.bool,
  onAddSaveCallback: PropTypes.func,
  onEditSaveCallback: PropTypes.func,
};

ActualFinancialsTableInLineForm.defaultProps = {
  row: undefined,
  togglePopOver: undefined,
  setIsPopoverOpen: undefined,
  isEdit: false,
  onEditSaveCallback: undefined,
  onAddSaveCallback: undefined,
};

export default ActualFinancialsTableInLineForm;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Dropdown from "../Dropdown/Dropdown";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import TertiaryHeader from "../TextHeaders/TertiaryHeader";
import ListRow from "../ListRow/ListRow";
import { memberRoles } from "../../../constants";
import plusIcon from "../../assets/images/plusIcon.svg";

const CompanyMembersForm = ({ allUsers, handleNextStep }) => {
  const [users, setUsers] = useState([]);
  const [isNewMember, setIsNewMember] = useState(true);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState({ label: null, value: null });
  const [name, setName] = useState({ label: null, value: null });
  const [userDropDown, setUserDropDown] = useState([]);

  useEffect(() => {
    setUserDropDown(
      allUsers?.map((info) => ({
        label: `${info?.name?.firstName || ""} ${
          info?.name?.lastName || "\u00A0"
        }`,
        value: info.reference,
      })) ?? []
    );
  }, [allUsers]);
  useEffect(() => {
    setRoles(
      memberRoles?.map((memberRole) => ({
        label: memberRole,
        value: memberRole,
      })) ?? []
    );
  }, []);

  const AddNewMember = () => {
    setUsers([
      ...users,
      { name: name.label, role: role.value, reference: name.value },
    ]);
    setIsNewMember(false);
    setName("");
    setRole("");
  };

  const onChangeRole = (val) => {
    setRole(val);
  };

  const onChangeName = (val) => {
    setName(val);
  };

  const onRemoveUser = (indexToRemove) => {
    const filteredUsers = users.filter(
      (user, index) => index !== indexToRemove
    );
    setUsers(filteredUsers);
  };
  const handleCancelAddMember = () => {
    const emptyObj = {
      label: null,
      value: null,
    };
    setIsNewMember(false);
    setRole(emptyObj);
    setName(emptyObj);
  };

  const SearchUser = () => {
    return (
      <>
        <div className="flex items-end">
          <SecondaryButton
            iconLeft={<img alt="" src={plusIcon} />}
            title="Add New Member"
            onClick={() => setIsNewMember(true)}
          />
        </div>
      </>
    );
  };

  const AddNewUser = () => {
    return (
      <>
        <TertiaryHeader>Add Member</TertiaryHeader>
        <div className="grid grid-cols-12 gap-4 items-center">
          <div className="col-span-4">
            <Dropdown
              className="col-span-4"
              value={name}
              onChange={onChangeName}
              options={userDropDown}
            />
            <Dropdown
              className="col-span-4 mt-4"
              label="Their Role"
              placeholder="Select"
              options={roles}
              onChange={onChangeRole}
              value={role}
            />
            <div className="flex float-right mt-6">
              <TertiaryButton title="Cancel" onClick={handleCancelAddMember} />
              <PrimaryButton
                title="Add"
                onClick={AddNewMember}
                disabled={!role.value || !name.value}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="mb-28">
      <SecondaryHeader className="mt-4 mb-2">Members & Roles</SecondaryHeader>
      {users?.map((user, index) => (
        <ListRow
          key={`${user.name}-${user.role}`}
          title={user.name}
          subtitle={user.role}
          onDelete={() => onRemoveUser(index)}
        />
      ))}
      <br />

      {isNewMember ? <AddNewUser /> : <SearchUser />}

      <br />
      <div className="flex float-right">
        <TertiaryButton className="col-span-1 mr-3" title="Discard" />
        <SecondaryButton className="col-span-1 mr-3" title="Save Draft" />
        <PrimaryButton
          className="col-span-1 mr-3"
          title="Next"
          onClick={handleNextStep}
        />
      </div>
    </div>
  );
};

CompanyMembersForm.propTypes = {
  allUsers: PropTypes.arrayOf({
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }),
  /**
   * function to handle next step click
   */
  handleNextStep: PropTypes.func,
};

CompanyMembersForm.defaultProps = {
  allUsers: [],
  handleNextStep: undefined,
};

export default CompanyMembersForm;

import { debounce } from "lodash";
import { useCallback, useState } from "react";
import doesNameExist from "../../helpers/Associations/doesNameOrTitleExist";
import useProjectsOverview from "./useProjectsOverview";

const useCheckDuplicatedName = () => {
  const { projectDict } = useProjectsOverview();

  // Make sure new Project and edited Project have a unique name
  const [nameExists, setNameExists] = useState(false);

  // check for duplicated project name
  const checkNameExists = useCallback(
    (editedProjectName) => {
      const check = debounce((name) => {
        try {
          if (name && projectDict && typeof projectDict === "object") {
            const isNameInUse = doesNameExist({
              resourceType: "Project",
              resourceName: name,
              resourceDict: projectDict,
            });
            setNameExists(isNameInUse);
          }
        } catch (error) {
          console.error("Error checking duplicated project name", error);
        }
      }, 500);

      check(editedProjectName);
    },
    [projectDict]
  );

  return { checkNameExists, nameExists, setNameExists };
};

export default useCheckDuplicatedName;

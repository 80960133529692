/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FileAPI } from "@griffingroupglobal/eslib-api";
import { uploadFile } from "../../../helpers/File";
import { formatMediaData } from "../../../helpers/Formatters";
import PureFileForm from "./PureFileForm";

const FileForm = ({
  resource,
  onIsPrimaryChange,
  onAddPhoto: onAddPhotoProp,
  onDeletePhoto,
  onAddVideo: onAddVideoProp,
  onDeleteVideo,
  onAddDocument: onAddDocumentProp,
  onDeleteDocument,
  onAddLink,
  onChangeLink,
  onDeleteLink,
  isInputValid,
  handleContinue,
  hideUploadedFiles,
}) => {
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    const getImageUrls = async () => {
      const resolvedImgs = await formatMediaData(resource.images);
      setPhotos(
        resolvedImgs?.map((img, index) => ({
          ...img,
          isPrimary: resource.images[index]?.isPrimary,
        })) ?? []
      );
    };

    getImageUrls();
  }, [resource?.images]);

  useEffect(() => {
    const getVideoUrls = async () => {
      const resolvedVids = await formatMediaData(
        resource?.videos?.map((v) => ({ file: v }))
      );
      setVideos(resolvedVids ?? []);
    };

    getVideoUrls();
  }, [resource?.videos]);

  useEffect(() => {
    const getDocUrls = async () => {
      const resolvedDocs = await formatMediaData(
        resource?.files?.map((d) => ({ file: d }))
      );
      setDocuments(resolvedDocs ?? []);
    };

    getDocUrls();
  }, [resource?.files]);

  const onAddPhoto = async (photo, progressCallback) => {
    const fileRef = await uploadFile(photo, progressCallback);
    onAddPhotoProp(fileRef);
  };

  const onAddVideo = async (video, progressCallback) => {
    const fileRef = await uploadFile(video, progressCallback);
    onAddVideoProp(fileRef);
  };

  const onAddDocument = async (doc, progressCallback) => {
    const fileRef = await uploadFile(doc, progressCallback);
    onAddDocumentProp(fileRef);
  };

  const onUpload = async (files, progressCallback) => {
    await Promise.all(
      files.map(async (file) => {
        if (file.type.includes("image")) {
          await onAddPhoto(file, (loaded, total) =>
            progressCallback(loaded, total, file.name)
          );
        } else if (file.type.includes("video")) {
          await onAddVideo(file, (loaded, total) =>
            progressCallback(loaded, total, file.name)
          );
        } else {
          await onAddDocument(file, (loaded, total) =>
            progressCallback(loaded, total, file.name)
          );
        }
      })
    );
  };

  const onRemove = (fileId) => {
    const photoToRemove = photos.find((p) => p.id === fileId);
    const videoToRemove = videos.find((v) => v.id === fileId);
    const docToRemove = documents.find((d) => d.id === fileId);

    if (photoToRemove) {
      onDeletePhoto(fileId);
    } else if (videoToRemove) {
      onDeleteVideo(fileId);
    } else if (docToRemove) {
      onDeleteDocument(fileId);
    }
  };

  const onEdit = async (file, name) => {
    const body = {
      name,
    };
    const originalBody = {
      name: file.name,
      metadata: {
        lastUpdated: file?.metadata?.lastUpdated,
      },
    };
    await FileAPI.patch(file.id.split("File/")[1], body, originalBody);
    const photoIndex = photos.findIndex((p) => p.id === file.id);
    const videoIndex = videos.findIndex((v) => v.id === file.id);
    const docIndex = documents.findIndex((d) => d.id === file.id);
    if (photoIndex > -1) {
      const p = [...photos];
      p[photoIndex].name = name;
      setPhotos(p);
    } else if (videoIndex > -1) {
      const v = [...videos];
      v[videoIndex].name = name;
      setVideos(v);
    } else if (docIndex > -1) {
      const d = [...documents];
      d[docIndex].name = name;
      setDocuments(d);
    }
  };

  return (
    <PureFileForm
      photos={photos}
      videos={videos}
      documents={documents}
      links={resource.links}
      onUpload={onUpload}
      onEdit={onEdit}
      onRemove={onRemove}
      onIsPrimaryChange={onIsPrimaryChange}
      onAddPhoto={onAddPhoto}
      onAddVideo={onAddVideo}
      onAddDocument={onAddDocument}
      onAddLink={onAddLink}
      onChangeLink={onChangeLink}
      onDeleteLink={onDeleteLink}
      isInputValid={isInputValid}
      handleContinue={handleContinue}
      hideUploadedFiles={hideUploadedFiles}
    />
  );
};

FileForm.propTypes = {
  resource: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        file: PropTypes.string.isRequired,
        isPrimary: PropTypes.bool.isRequired,
      })
    ),
    videos: PropTypes.arrayOf(PropTypes.string),
    files: PropTypes.arrayOf(PropTypes.string),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  onIsPrimaryChange: PropTypes.func,
  onAddPhoto: PropTypes.func,
  onDeletePhoto: PropTypes.func,
  onAddVideo: PropTypes.func,
  onDeleteVideo: PropTypes.func,
  onAddDocument: PropTypes.func,
  onDeleteDocument: PropTypes.func,
  onAddLink: PropTypes.func,
  onChangeLink: PropTypes.func,
  onDeleteLink: PropTypes.func,
  isInputValid: PropTypes.bool,
  handleContinue: PropTypes.func,
  hideUploadedFiles: PropTypes.bool,
};

FileForm.defaultProps = {
  onIsPrimaryChange: undefined,
  onAddPhoto: undefined,
  onDeletePhoto: undefined,
  onAddVideo: undefined,
  onDeleteVideo: undefined,
  onAddDocument: undefined,
  onDeleteDocument: undefined,
  onAddLink: undefined,
  onChangeLink: undefined,
  onDeleteLink: undefined,
  isInputValid: false,
  handleContinue: undefined,
  hideUploadedFiles: false,
};

export default FileForm;

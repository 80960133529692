import React from "react";
import PropTypes from "prop-types";
import imageSelector from "../../../helpers/Weather/imageSelector";

const WeatherIcon = ({ name, className }) => {
  const imageName = imageSelector(name);
  return <img src={imageName} className={className} alt="weather-icon" />;
};

WeatherIcon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
};

WeatherIcon.defaultProps = {
  name: undefined,
  className: undefined,
};

export default WeatherIcon;

import React, { useState } from "react";
import { useUsers } from "../../../../../hooks/useUsers.new";
import useGetBudgetLineitemByInvoice from "../../../../../hooks/useGetInvoiceLineitems";

const useDocumentDetailsData = ({ project, document }) => {
  const budegtId = project?.budget?.split("/")?.[1];
  const { data } = useGetBudgetLineitemByInvoice(budegtId, document?.reference);

  const { data: userData } = useUsers();
  const userDict = userData?.userDict;
  const [isLeftActive, setIsLeftActive] = useState(true);
  const [isRightActive, setIsRightActive] = useState(false);

  const docBudgetLines = React.useMemo(() => {
    return data?.budgetLineItems?.map((item) => {
      const result = {
        ...item,
        workCompletedToDateLessRetainageAndHoldback:
          Number(item?.workCompletedToDate || 0) -
          Number(item?.holdback || 0) -
          (Number(item?.retainage || 0) === "N/A"
            ? 0
            : Number(item?.retainage || 0)),
        balanceToFinish:
          Number(item?.balanceToFinish || 0) +
          Number(item?.holdback || 0) +
          (Number(item?.retainage || 0) === "N/A"
            ? 0
            : Number(item?.retainage || 0)),
        paymentRequestedThisPeriod: item?.workCompletedThisPeriod,
      };

      result.vendorName = userDict[result.vendor]?.companyName;

      return result;
    });
  }, [data?.budgetLineItems, userDict]);

  return {
    isLeftActive,
    setIsLeftActive,
    isRightActive,
    setIsRightActive,
    docBudgetLines,
    budget: data?.budget,
    budgetType: data?.budget?.budgetType,
    isLockGmp: data?.budget?.isLockGmp,
  };
};

export default useDocumentDetailsData;

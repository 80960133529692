import { ConfigurationAPI } from "@griffingroupglobal/eslib-api";
import { useAppState } from "../state/appState";
import { getLatestTime } from "../helpers/Time";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { configurationKeys } from "../config/reactQuery/queryKeyFactory";
import {
  SET_PTO_LOCATIONS_LIBRARY,
  SET_PTO_SETTINGS,
  SET_SYSTEM_CONFIGURATION,
} from "../constants";

const formatPtoCategories = (general = {}) => {
  const cats = Object.entries(general);
  const unlimited = cats?.find(
    ([key, item]) => key === "unlimited" && item?.selected
  );
  /**
   * If Unlimited return all PTO Categories
   */
  if (unlimited)
    return cats.slice(1)?.map(([name, item]) => ({ name, ...item }));

  /**
   * Return Selected PTO Categories
   */
  return cats?.reduce((arr, [key, item]) => {
    if (item.selected) {
      return [...arr, { name: key, ...item }];
    }
    return arr;
  }, []);
};

const fetchSystemConfiguration = async () => {
  const { data } = await ConfigurationAPI.getById("system");
  return data;
};

const useSystemConfiguration = () => {
  const [{ configurationLastUpdated }, dispatch] = useAppState();

  return useAuthenticatedQuery({
    queryKey: configurationKeys("system"),
    queryFn: fetchSystemConfiguration,
    placeholderData: {},
    onError: (error) => console.error("❌ useSystemConfiguration", error),
    onSuccess: (data) => {
      dispatch({
        type: SET_SYSTEM_CONFIGURATION,
        systemConfiguration: { system: data?.system },
        configurationLastUpdated: getLatestTime(
          configurationLastUpdated,
          data.metadata.lastUpdated
        ),
      });
      const ptoLocations = data?.system?.pto?.locations?.reduce(
        (list, item) => {
          const location = {
            pto: Object.entries(item)?.reduce((arr, [key, val]) => {
              if (val.selected) {
                return [...arr, { name: key, ...val }];
              }
              return arr;
            }, []),
            label: item?.location,
            value: item?.id,
          };
          // eslint-disable-next-line no-param-reassign
          list.dict[item?.id] = location;
          list.iter.push(location);
          return list;
        },
        { dict: {}, iter: [] }
      );

      dispatch({
        type: SET_PTO_SETTINGS,
        general: formatPtoCategories(data?.system?.pto?.general),
        category: data?.system?.pto?.category,
      });

      dispatch({
        type: SET_PTO_LOCATIONS_LIBRARY,
        locations: ptoLocations,
      });
    },
  });
};

export default useSystemConfiguration;

import { ProjectAPI } from "@griffingroupglobal/eslib-api";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { GET_PROJECT_ACTIVATE_PATH, PROJECTS_PATH } from "../../constants";
import { getErrorMessage } from "../../helpers/Error";
import { getProjectOptions } from "../../helpers/SiteHeaderOptions";
import useAssociatedFiles from "../../hooks/useAssociatedFiles";
import useEditModal from "../../hooks/useEditModal";
import useManagementConfiguration from "../../hooks/useManagementConfiguration";
import useProject from "../../hooks/useProject";
import useProjectFormReducer from "../../hooks/useProjectFormReducer";
import useProjectHistory from "../../hooks/useProjectHistory";
import useProjects from "../../hooks/useProjects";
import useUsers from "../../hooks/useUsers";
import PrimaryButton from "../../stories/Components/Buttons/PrimaryButton";
import DetailViewBanner from "../../stories/Components/DetailViewBanner/DetailViewBanner";
import FilesTable from "../../stories/Components/FilesTable/FilesTable";
import ImagesAndVideosWidget from "../../stories/Components/MediaWidget/ImagesAndVideosWidget";
import Modal from "../../stories/Components/Modal/Modal";
import SiteHeader from "../../stories/Components/SiteHeader/SiteHeader";
import TabbedContainer from "../../stories/Components/TabbedContainer/TabbedContainer";
import { toastError, toastMessage } from "../../stories/Components/Toast/Toast";
import ProjectDetailView from "./ProjectDetailView";
import ProjectHistory from "./ProjectHistory";
import ProjectMembersView from "./ProjectMembersView";
// import UploadFile from "../../stories/Components/UploadFile/UploadFile";
import { tagKeys } from "../../config/reactQuery/queryKeyFactory";
import { onUpdateFile } from "../../helpers/File";
import { formatErrorResponse, getFullName } from "../../helpers/Formatters";
import Spaces from "../Spaces/Spaces";

/**
 * @deprecated - ProjectView is the default project details view. Delete this file
 */
const ProjectDetails = ({ currentUser }) => {
  const { projectId } = useParams();
  const { changeProjectStatus, removeProject, addProject } = useProjects();
  const [project, reload, , patchProject, , projectMembers] =
    useProject(projectId);
  const params = useMemo(
    () => ({ association: `Project/${projectId}` }),
    [projectId]
  );
  const {
    associatedFiles,
    addFile,
    addFiles,
    removeFilesAndUpdateApi,
    cloneFile,
    patchFile,
  } = useAssociatedFiles(params);
  const [, setIsEditModalOpen] = useEditModal();
  const queryClient = useQueryClient();

  const [showModal, setShowModal] = useState(false);
  const [modalState, setModalState] = useState();
  const history = useHistory();
  const [users, , , dict] = useUsers();
  const [editedProject, dispatch] = useProjectFormReducer();
  const { data: managementConfiguration } = useManagementConfiguration();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [buttonActions, setButtonActions] = useState([]);
  const [projectHistory] = useProjectHistory(projectId);

  const resetProjectState = useCallback(() => {
    dispatch({
      type: "reset",
      project,
    });
  }, [dispatch, project]);

  useEffect(() => {
    if (project) resetProjectState();
  }, [project, resetProjectState]);

  const handleDeleteProject = useCallback(
    async (currentProject) => {
      try {
        removeProject(currentProject.id);
        ProjectAPI.delete(projectId);
      } catch (err) {
        toastError(`Error deleting project: ${getErrorMessage(err)}`);
        addProject(currentProject);
      }
      history.push(PROJECTS_PATH);
    },
    [addProject, history, projectId, removeProject]
  );

  const handleCompleteProject = useCallback(
    async (currentProject) => {
      const completedProject = {
        ...currentProject,
        status: "complete",
      };
      try {
        ProjectAPI.patch(projectId, completedProject, currentProject);
        changeProjectStatus(currentProject.id, "complete");
      } catch (err) {
        //  Todo Error handling
      }
      history.push(PROJECTS_PATH);
    },
    [changeProjectStatus, history, projectId]
  );

  const handleActivateProject = useCallback(
    async (currentProject) => {
      history.push(GET_PROJECT_ACTIVATE_PATH(currentProject.id));
    },
    [history]
  );

  const handleOpenModal = ({ title, message, func, buttonTitle }) => {
    setModalState({
      title,
      message,
      func,
      buttonTitle,
    });
    setShowModal(true);
  };

  const onFinishEditing = useCallback(
    async (key, val) => {
      const finishedProject = {
        ...editedProject,
        tags:
          editedProject?.currentTags?.map((tag) => tag?.value) ||
          editedProject?.tags,
        [key]: val,
      };

      await ProjectAPI.patch(project.id, finishedProject, project)
        .then(() => {
          // update tags in s&c
          queryClient.invalidateQueries(tagKeys.tags);
        })
        .catch((err) => {
          let message = formatErrorResponse(err);
          if (message?.includes("User/")) {
            const [one, two] = message?.split("User/");
            const failedUser = dict?.[`User/${two}`];
            message = `${one} ${getFullName(failedUser?.name)}`;
          }
          toastError(`${message ?? "Error"}`);
        });
      await reload();
    },
    [dict, editedProject, project, queryClient, reload]
  );

  const handleEdit = useCallback(
    (key, value) => {
      dispatch({
        type: "edit",
        key,
        value,
      });
    },
    [dispatch]
  );

  const handleEditObject = useCallback(
    (parentKey, childKey, value) => {
      dispatch({
        type: "editObject",
        parentKey,
        childKey,
        value,
      });
    },
    [dispatch]
  );

  const handleFileClone = useCallback(
    (fileId) => {
      cloneFile(fileId)
        .then((clonedFile) => {
          const updatedProject = {
            ...project,
            files: [
              ...project?.files,
              { ref: clonedFile.reference, category: clonedFile.category },
            ],
          };

          return patchProject(updatedProject, true);
        })
        .then((resource) =>
          toastMessage(`Recent file successfully attached to ${resource?.name}`)
        )
        .catch(() => {
          toastError(`Error attaching recent file`);
          // remove created files if PATCH fails
          removeFilesAndUpdateApi([`File/${fileId}`]);
        });
    },
    [cloneFile, patchProject, project, removeFilesAndUpdateApi]
  );

  const handlePrimaryImageChange = async (image, imageData, imageResource) => {
    const updatedProject = {
      ...project,
      files: [
        ...project?.files,
        { ref: imageResource.reference, category: imageResource.category },
      ],
      primaryImage: imageResource.reference,
    };

    patchProject(updatedProject)
      .then(() => {
        addFile(imageResource);
        toastMessage(`Primary Image Updated`);
      })
      .catch(() => {
        toastError(`Error updating primary image`);
      });
  };

  const handleAddMedia = useCallback(
    (imageResources) => {
      const updatedFiles = [
        ...project.files,
        ...imageResources?.map((imageResource) => ({
          ref: imageResource.reference,
          category: imageResource.category,
        })),
      ];

      const primary =
        project.primaryImage ||
        updatedFiles.find((file) => file.category === "Photos")?.ref;

      const updatedProject = {
        ...project,
        files: updatedFiles,
        primaryImage: primary,
      };
      // patch resource
      patchProject(updatedProject);
      // update associated files state
      addFiles(imageResources);
    },
    [addFiles, patchProject, project]
  );

  const handleSetPrimaryMedia = useCallback(
    (imageRef) => {
      const updatedProject = {
        ...project,
        primaryImage: imageRef,
      };
      // patch resource
      patchProject(updatedProject);
    },
    [patchProject, project]
  );

  const handleRemoveMedia = useCallback(
    (imageRefs) => {
      const updatedFiles = project.files.filter(
        (file) => !imageRefs.includes(file.ref)
      );
      const primary = imageRefs.includes(project?.primaryImage)
        ? updatedFiles.find((file) => file.category === "Photos")?.ref
        : project.primaryImage;
      const updatedProject = {
        ...project,
        files: updatedFiles,
        primaryImage: primary,
      };

      patchProject(updatedProject, {
        onSuccess: () => {
          // update associated files state
          removeFilesAndUpdateApi(imageRefs);
        },
        onError: () => {
          toastError("Error removing media");
        },
      });
    },
    [patchProject, project, removeFilesAndUpdateApi]
  );

  const onAddFilesCallback = useCallback(
    async (filesUploaded) => {
      // update associated files state
      addFiles(filesUploaded);

      const updatedFiles = [
        ...project.files,
        ...filesUploaded.map((file) => ({
          ref: file.reference,
          category: file.category,
        })),
      ];

      const updatedProject = {
        ...project,
        files: updatedFiles,
        primaryImage:
          project?.primaryImage ||
          updatedFiles.find((file) => file.category === "Photos")?.ref,
      };
      // patch resource
      patchProject(updatedProject);
    },
    [addFiles, patchProject, project]
  );

  const updateProjectFiles = useCallback(
    (fileRefs) => {
      const updatedFiles = project.files.filter(
        (file) => !fileRefs.includes(file.ref)
      );
      const primary = fileRefs.includes(project?.primaryImage)
        ? updatedFiles.find((file) => file.category === "Photos")?.ref
        : project.primaryImage;
      const updatedProject = {
        ...project,
        files: updatedFiles,
        primaryImage: primary,
      };
      // patch resource
      patchProject(updatedProject);
    },
    [patchProject, project]
  );

  const handleUpdateFile = useCallback(
    ({ originalResource, currentTags, name }) => {
      onUpdateFile({ originalResource, currentTags, name, patchFile });
    },
    [patchFile]
  );

  const tabs = useMemo(() => {
    const permissionedTabs = {
      tabs: [
        {
          title: "Details",
          content: (
            <ProjectDetailView
              editedProject={editedProject}
              config={managementConfiguration?.management}
              dispatch={dispatch}
              onChange={handleEdit}
              onChangeObject={handleEditObject}
              onFinishEditing={onFinishEditing}
              originalResource={project}
              disableEditing={
                !currentUser?.hasPermission(
                  "administrative",
                  "can_write_project"
                )
              }
            />
          ),
        },
        {
          title: "Members",
          content: (
            <ProjectMembersView
              editedProject={editedProject}
              dispatch={dispatch}
              onFinishEditing={onFinishEditing}
              handleEdit={handleEdit}
              disableEditing={
                !currentUser?.hasPermission?.(
                  "administrative",
                  "can_write_project"
                )
              }
            />
          ),
        },
      ],
    };

    permissionedTabs.tabs.push({
      title: "Spaces",
      content: <Spaces setActions={setButtonActions} />,
    });

    permissionedTabs.spaceIndex = permissionedTabs.tabs.length - 1;

    permissionedTabs.tabs.push({
      title: "Media",
      content: (
        <ImagesAndVideosWidget
          border={false}
          resource={project}
          disableEditing={
            !currentUser?.hasPermission?.("administrative", "can_write_project")
          }
          handleAddMedia={handleAddMedia}
          handleSetPrimaryMedia={handleSetPrimaryMedia}
          handleRemoveMedia={handleRemoveMedia}
        />
      ),
    });
    permissionedTabs.tabs.push({
      title: "Files",
      content: (
        <FilesTable
          files={associatedFiles}
          onAddFilesCallback={onAddFilesCallback}
          removeFilesAndUpdateApi={removeFilesAndUpdateApi}
          onRemoveFilesCallback={updateProjectFiles}
          setIsEditModalOpen={setIsEditModalOpen}
          resourceName="Project"
          association={`Project/${projectId}`}
          hasDeletePermission={currentUser?.hasPermission?.(
            "administrative",
            "can_write_project"
          )}
          hasWritePermission={currentUser?.hasPermission?.(
            "administrative",
            "can_write_project"
          )}
          handleFileClone={handleFileClone}
          hasEditPermission
          handleUpdateFile={handleUpdateFile}
        />
      ),
    });
    permissionedTabs.tabs.push({
      title: "History",
      content: (
        <ProjectHistory
          project={editedProject}
          users={users}
          projectMembers={projectMembers}
          style={{ padding: "40px" }}
          currentUser={currentUser}
          history={projectHistory}
        />
      ),
    });

    return permissionedTabs;
  }, [
    editedProject,
    managementConfiguration?.management,
    dispatch,
    handleEdit,
    handleEditObject,
    onFinishEditing,
    project,
    currentUser,
    handleAddMedia,
    handleSetPrimaryMedia,
    handleRemoveMedia,
    associatedFiles,
    onAddFilesCallback,
    removeFilesAndUpdateApi,
    updateProjectFiles,
    setIsEditModalOpen,
    projectId,
    handleFileClone,
    handleUpdateFile,
    users,
    projectMembers,
    projectHistory,
  ]);

  useEffect(() => {
    const defaultActions = [
      ...(editedProject?.status === "active"
        ? [
            {
              onClick: () =>
                handleOpenModal({
                  title: "Complete Project",
                  message: `Are you sure that you want to complete ${project?.name}?`,
                  func: handleCompleteProject,
                  buttonTitle: `Yes Complete`,
                }),
              title: "Complete Project",
            },
          ]
        : []),

      ...(editedProject?.status === "complete" ||
      editedProject?.status === "draft"
        ? [
            {
              onClick: () => {
                const buttonText =
                  editedProject?.status === "complete"
                    ? "Reactivate"
                    : "Activate";
                handleOpenModal({
                  title: "Activate Project",
                  message: `Are you sure that you want to ${buttonText.toLowerCase()} ${
                    project?.name
                  }?`,
                  func: handleActivateProject,
                  buttonTitle: `Yes, ${buttonText}`,
                });
              },
              title: `${
                editedProject?.status === "complete" ? "Reactivate" : "Activate"
              } Project`,
            },
          ]
        : []),

      ...(currentUser?.hasPermission?.("administrative", "can_write_project") &&
      (editedProject?.status === "active" || editedProject?.status === "draft")
        ? [
            {
              onClick: () =>
                handleOpenModal({
                  title: "Delete Project",
                  message: `Are you sure that you want to delete ${project?.name}?`,
                  func: handleDeleteProject,
                  buttonTitle: `Yes Delete`,
                }),
              title: "Delete",
            },
          ]
        : []),
    ];

    setButtonActions(defaultActions);
  }, [
    currentUser,
    editedProject?.status,
    handleActivateProject,
    handleCompleteProject,
    handleDeleteProject,
    project?.name,
  ]);
  /**
   * Clean Up Actions CTA Menu
   */
  useEffect(() => {
    // remove CTA to add event when not in Calendar tab view
    switch (activeTabIndex) {
      case tabs.spaceIndex:
        setButtonActions((prev) => [
          ...prev.filter((opt) => !opt.tabAction || opt.title === "Add Space"),
        ]);
        return;
      default: {
        setButtonActions((prev) => [...prev.filter((opt) => !opt.tabAction)]);
      }
    }
  }, [activeTabIndex, tabs.spaceIndex]);

  return (
    <>
      <SiteHeader
        title={editedProject?.name}
        dropdownRoutes={getProjectOptions(
          projectId,
          currentUser,
          undefined,
          undefined,
          project?.name
        )}
        buttons={
          currentUser?.hasPermission("administrative", "can_write_project") && (
            <>
              <PrimaryButton
                large
                title="Actions"
                className="dropdown-btn"
                dropdownItems={buttonActions}
                disabled={showModal}
              />
            </>
          )
        }
      />
      <DetailViewBanner
        resource={editedProject}
        onChangeImage={handlePrimaryImageChange}
      />
      <TabbedContainer
        tabs={tabs.tabs}
        activeIndex={activeTabIndex}
        onTabClick={(index) => setActiveTabIndex(index)}
      />
      {showModal && (
        <Modal
          title={modalState?.title}
          primaryButtonTitle={modalState?.buttonTitle}
          primaryButtonOnClick={() => modalState?.func(project)}
          tertiaryButtonTitle="Cancel"
          onRequestModalClose={() => setShowModal(false)}
          shouldCloseOnOverlayClick
          isOpen={showModal}
          shouldCloseOnEsc
          hideFooter
        >
          <>
            <p className="text-base mb-2">{modalState?.message}</p>
          </>
        </Modal>
      )}
    </>
  );
};

ProjectDetails.propTypes = {
  currentUser: PropTypes.shape({ hasPermission: PropTypes.func }),
};

ProjectDetails.defaultProps = {
  currentUser: undefined,
};

export default ProjectDetails;

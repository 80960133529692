import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

import Spinner from "../Spinner/Spinner";
import DocumentUploadForm from "../DocumentUploadForm/DocumentUploadForm";
import placeholderImage from "../../assets/images/placeholderImage.png";
import plusIcon from "../../assets/images/plusIcon.svg";
import removeIcon from "../../assets/images/Remove.svg";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import { PRIMARY_IMAGE_EXTENSIONS } from "../../../constants";

const uploadButtonCN = cntl`
  relative
  flex
  justify-center
  items-center
  w-full
  h-72
  -mb-72
`;

const DetailViewBanner = ({
  resource,
  resourceImage,
  onChangeImage,
  disableUpload,
  className,
  hideButton,
  secondaryHeader,
}) => {
  const [hovering, setHovering] = useState();
  const [loading, setLoading] = useState(false);
  const [hideBanner, setHideBanner] = useState(true);

  useEffect(() => {
    if (resourceImage) {
      setLoading(false);
    }
  }, [resourceImage]);

  const handleChangeImage = (image, imageData, imageResource) => {
    setLoading(true);
    onChangeImage(image, imageData, imageResource);
  };
  const handleHovering = (mouseOver) => {
    if (mouseOver) {
      setHovering(true);
    } else {
      setHovering();
    }
  };

  return (
    <>
      {!hideButton && (
        <div
          className={`flex justify-${
            secondaryHeader ? "between" : "end"
          } text-brandGreen my-2.5 font-semibold`}
        >
          {secondaryHeader && (
            <SecondaryHeader className="text-2xl my-2" fontWeight="light">
              {secondaryHeader}
            </SecondaryHeader>
          )}
          <div
            className="cursor-pointer flex items-center"
            onClick={() => setHideBanner(!hideBanner)}
            onKeyDown={() => setHideBanner(!hideBanner)}
            role="button"
            tabIndex={0}
          >
            <span>{hideBanner ? "Show" : "Hide"}</span>
            {!hideBanner ? (
              <img
                className="w-4 cursor-pointer ml-2"
                src={removeIcon}
                alt="hide"
              />
            ) : (
              <img
                className="w-3 cursor-pointer ml-2"
                src={plusIcon}
                alt="plus"
              />
            )}
          </div>
        </div>
      )}
      {!hideBanner && (
        <div
          className="flex flex-col justify-start items-center w-full h-72 overflow-hidden mb-4 shadow-medium-lift rounded-xl"
          onMouseOver={() => handleHovering(true)}
          onMouseOut={() => handleHovering()}
          onFocus={() => handleHovering(true)}
          onBlur={() => handleHovering()}
        >
          <div className="flex w-full h-72">
            <div
              className={`${uploadButtonCN} ${
                hovering && !loading && !disableUpload ? "" : "hidden"
              }`}
            >
              <DocumentUploadForm
                resource={resource}
                extensions={PRIMARY_IMAGE_EXTENSIONS}
                onAddPhoto={handleChangeImage}
                isOverlay
              />
            </div>
            <div className={`${uploadButtonCN} ${loading ? "" : "hidden"}`}>
              <div className="flex justify-center items-center w-full h-full">
                <Spinner notFullScreen />
              </div>
            </div>
          </div>
          <img
            className={`w-full h-72 object-center ${
              resourceImage ? "object-cover" : "object-contain bg-gray-100"
            } ${className}`}
            src={resourceImage ?? placeholderImage}
            alt="background"
          />
        </div>
      )}
    </>
  );
};

DetailViewBanner.propTypes = {
  /**
   * resource currently being created or edited
   */
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object,
  /**
   * resource primary image
   */
  resourceImage: PropTypes.string,
  /**
   * function called to make changes to the resource's primary image.
   */
  onChangeImage: PropTypes.func,

  disableUpload: PropTypes.bool,
  className: PropTypes.string,
  hideButton: PropTypes.bool,
  secondaryHeader: PropTypes.string,
};

DetailViewBanner.defaultProps = {
  resource: {},
  resourceImage: undefined,
  onChangeImage: undefined,
  disableUpload: false,
  className: undefined,
  hideButton: false,
  secondaryHeader: undefined,
};

export default DetailViewBanner;

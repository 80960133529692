import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import useEvents from "../../../hooks/useEvents";
import { useAppState } from "../../../state/appState";
import { useProjects } from "../../../hooks/projects";

const useCalendarProjectsData = ({
  tasksOpen,
  setSelectedProjectIds,
  selectedProjectIds,
}) => {
  // State to manage whether to show the list of projects
  const [showProjects, setShowProjects] = useState(false);
  const [calendarFilterShown, setCalendarFilterShown] = useState(false);

  // Close the project list if tasks are open
  useEffect(() => {
    if (tasksOpen && showProjects) setShowProjects(false);
  }, [tasksOpen, showProjects]);

  // Fetch calendar data from app state using custom hook
  const [{ calendarData }] = useAppState();

  // Fetch projects from cache if data is not stale
  const { data: projects } = useProjects();

  // State to manage the search string for project filtering
  const [projectSearchString, setProjectSearchString] = useState("");

  // State to store currently selected projects
  const [currentlySelectedProjects, setCurrentlySelectedProjects] = useState(
    []
  );

  // Initialize an array of selectable projects with selected state
  const [selectableProjects, setSelectableProjects] = useState([]);

  // Get the current path from the location hook
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;

    if (pathname === "/calendar") {
      setCalendarFilterShown(true);
      // Clean App State when hook is loaded so selected projects don't persist

      setSelectedProjectIds([]);
    } else {
      setCalendarFilterShown(false);
    }
  }, [location, setSelectedProjectIds]);

  // Joins selected Ids into comma delimited query string
  const queryString = useMemo(() => {
    return selectedProjectIds?.length > 0
      ? `Project/${selectedProjectIds.join(",Project/")}`
      : undefined;
  }, [selectedProjectIds]);

  // Fires hook if ids are selected to load app state with said projects
  // This is important for non admins who should only see projects they have access too.

  useEvents({
    startDate: moment(calendarData)
      .startOf("month")
      .startOf("day")
      .subtract(3, "months")
      .format(),
    endDate: moment(calendarData)
      .endOf("month")
      .endOf("day")
      .add(3, "months")
      .format(),
    include: queryString,
  });

  // Function to remove duplicates from array1 that are present in array2
  const removeDuplicates = (array1, array2) => {
    if (!array1 || !array2) return [];
    const newArrayWithNoDuplicates = array1?.filter(
      (project) =>
        !array2?.find((selectedProject) => selectedProject.id === project.id)
    );

    return newArrayWithNoDuplicates;
  };

  useEffect(() => {
    // Map projects to newSelectableProjects array with updated selected status
    const newSelectableProjects = projects?.map((project) => {
      const projectIsSelected = currentlySelectedProjects?.find(
        (selectedProject) => selectedProject.id === project.id
      );

      return { ...project, selected: projectIsSelected };
    });

    if (projectSearchString !== "") {
      const filteredProjectList =
        newSelectableProjects?.filter((project) =>
          project.name
            .toLowerCase()
            ?.includes(projectSearchString.toLowerCase())
        ) || [];

      // Filter and add filteredProjectsNoDuplicates to selectableProjects
      const filteredProjectsNoDuplicates = removeDuplicates(
        filteredProjectList,
        currentlySelectedProjects
      ).filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);

      setSelectableProjects([
        ...currentlySelectedProjects,
        ...filteredProjectsNoDuplicates,
      ]);
    } else {
      // Filter and add filteredArrayNoDuplicates to selectableProjects
      const filteredArrayNoDuplicates = removeDuplicates(
        newSelectableProjects,
        currentlySelectedProjects
      ).filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);

      setSelectableProjects([
        ...currentlySelectedProjects,
        ...filteredArrayNoDuplicates,
      ]);
    }
  }, [projects, currentlySelectedProjects, projectSearchString]);

  // Function to perform fuzzy search on projects based on search string
  const performFuzzySearch = (value) => {
    setProjectSearchString(value);
  };

  // Function to toggle visibility of the project list
  const toggleShowProjects = () => setShowProjects(!showProjects);

  // Function to toggle the selected status of an item
  const toggleItemSelected = (item, index) => {
    const newSelectableProjects = [...selectableProjects];
    newSelectableProjects[index] = { ...item, selected: !item.selected };

    if (item.selected) {
      // Update currentlySelectedProjects when an item is deselected
      const newCurrentlySelectedProjects = currentlySelectedProjects
        .filter((project) => project.id !== item.id)
        .map((project) => ({ ...project, selected: true }));

      setCurrentlySelectedProjects(newCurrentlySelectedProjects);

      setSelectedProjectIds(
        newCurrentlySelectedProjects.map((project) => project.id)
      );
    } else {
      // Add a newly selected item to currentlySelectedProjects
      setCurrentlySelectedProjects([
        ...currentlySelectedProjects,
        { ...item, selected: true },
      ]);

      setSelectedProjectIds(
        [...currentlySelectedProjects, { ...item, selected: true }].map(
          (project) => project.id
        )
      );
    }

    // Update selectableProjects with the new status
    setSelectableProjects([...newSelectableProjects]);
  };

  // Function to clear all the selected project IDs
  const clearAllSelectedProjectIds = () => {
    setCurrentlySelectedProjects([]);
    setSelectedProjectIds([]);
  };

  // Styles for different elements related to calendar projects
  const calendarProjectStyles = {
    chevronStyles: {
      display: "flex",
      justifyItems: "center",
      cursor: "pointer",
      transitionProperty: "all",
      transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
      transitionDuration: "150ms",
      transform: `rotate(${showProjects ? 90 : 180}deg)`,
    },
    projectListStyles: {
      height: "400px",
      overflowY: "scroll",
      position: "relative",
      zIndex: "50",
    },
    projectListShadowEffect: {
      position: "sticky",
      bottom: "0",
      left: "0",
      width: "100%",
      height: "40px",
      background:
        "linear-gradient(180deg, rgba(255,255,255,0) 0%, #ffffff 100%)",
      zIndex: "100",
    },
    projectRowStyles: {
      display: "flex",
      alignItems: "center",
    },
  };

  // Return all the functions and values to be used in the component
  return {
    toggleShowProjects,
    toggleItemSelected,
    performFuzzySearch,
    clearAllSelectedProjectIds,
    currentlySelectedProjects,
    showProjects,
    selectableProjects,
    calendarProjectStyles,
    projectSearchString,
    calendarFilterShown,
  };
};

export default useCalendarProjectsData;

// Framework Tools
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import * as yup from "yup";

// components
import PlusCircleButton from "../../../Buttons/PlusCircleButton/PlusCircleButton";
import DatePicker from "../../../DatePicker/DatePicker";
import Dropdown from "../../../Dropdown/Dropdown";
import Input from "../../../Input/Input";
import RequestGridViewDistroListCell from "../../../Requests/RequestGridView/RequestGridViewDistroListCell";
import Widget from "../../../Widget/Widget";
import DistroListSelectMembers from "../../../DistroList/DistroListSelectMembers";
import useSubmittalsCreateModal from "./useSubmittalsCreateModal";
import ModalWrapper from "../../../EsModal/ModalWrapper";

const labelCN = "text-es-normal font-es-semibold text-es-medium-grey";
const labelWidth = { minWidth: "7rem" };
const rowHeight = { minHeight: "70px" };

const SubmittalsCreateModal = ({ modalData }) => {
  const {
    requestFormDispatch,
    handleModalClose,
    handlePostSubmittal,
    disableSave,
    isSaving,
    showConfirm,
    requestForm,
    handleChange,
    projectDD,
    projectDict,
    userDict,
    resetDistroList,
    submittalTypes,
    handleDeleteFromDistro,
    setDistroSelectOpen,
    distroSelectOpen,
    distroSelectOptions,
    disableAssociation,
    association,
  } = useSubmittalsCreateModal({ modalData });

  return (
    <ModalWrapper
      modalData={modalData}
      onRequestClose={() => {
        requestFormDispatch({ type: "reset" });
        handleModalClose();
      }}
      width="900px"
      primaryButtonTitle="Save"
      title="Create Submittal"
      primaryButtonOnClick={handlePostSubmittal}
      tertiaryButtonTitle="Cancel"
      disabled={disableSave || isSaving}
      showConfirm={showConfirm}
      trashCan
    >
      <div className="flex flex-col items-start gap-6 w-full">
        <div className="w-full">
          <Input
            inputClassName="ESInput bg-backgroundGreen text-es-normal text-es-medium-grey placeholderOverride"
            placeholder="Name (Required)"
            value={requestForm.customName}
            validation={yup.string().required()}
            onChange={(val) => handleChange("customName", val)}
            required
          />
        </div>
        <Widget
          childClassName="w-full flex"
          title="Submittal Information"
          draggable={false}
          overflow
        >
          <div className="flex flex-col gap-6 items-start self-stretch w-full">
            <div
              style={rowHeight}
              className="flex justify-between items-end gap-7 w-full"
            >
              <div className="fle flex-col w-1/2">
                <div className="flex justify-between items-center w-full pb-6">
                  <p className={labelCN} style={labelWidth}>
                    Project<span className="text-brandGreen">*</span>
                  </p>
                  {distroSelectOpen && (
                    <div className="w-full">
                      <DistroListSelectMembers
                        members={distroSelectOptions}
                        selectedMembers={requestForm?.distribution}
                        placeholder="Select Member"
                        onAdd={(val) =>
                          handleChange("addDistroMember", val.value)
                        }
                        onRemove={handleDeleteFromDistro}
                        setDistroSelectOpen={setDistroSelectOpen}
                      />
                    </div>
                  )}
                  <div className="w-full">
                    <Dropdown
                      placeholder="Select"
                      options={projectDD}
                      isDisabled={
                        !(
                          projectDD &&
                          projectDict &&
                          userDict &&
                          !disableAssociation
                        )
                      }
                      onChange={(val) => {
                        resetDistroList();
                        handleChange("association", val?.value);
                      }}
                      value={projectDD?.find(
                        (item) =>
                          item.value === requestForm.association ||
                          item.value === association
                      )}
                      validation={yup.mixed().required()}
                    />
                  </div>
                </div>
                <div id="horizontal-divider-1" className="border-b w-full" />
              </div>
              <div className="fle flex-col w-1/2">
                <div className="flex justify-between items-center w-full pb-6">
                  <p className={labelCN} style={labelWidth}>
                    Type<span className="text-brandGreen">*</span>
                  </p>
                  <div className="w-full">
                    <Dropdown
                      placeholder="Select"
                      options={submittalTypes}
                      onChange={(val) => {
                        handleChange("type", val.value);
                      }}
                      value={submittalTypes?.find(
                        (item) => item?.value === requestForm?.type
                      )}
                      validation={yup.mixed().required()}
                    />
                  </div>
                </div>
                <div id="horizontal-divider-2" className="border-b  w-full" />
              </div>
            </div>

            <div className="w-full border-b">
              <div className="flex justify-between items-center w-full pb-6">
                <p className={labelCN} style={labelWidth}>
                  Description
                </p>
                <div className="flex w-full">
                  <Input
                    mainWrapperClassName="w-full"
                    placeholder="Description"
                    inputClassName="ESInput bg-lightGreen text-gray-450 placeholderOverride"
                    inputContainerClassName="bg-lightGreen text-gray-450"
                    value={requestForm.description}
                    autoExpandHeight
                    isTextarea
                    onChange={(val) => handleChange("description", val)}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-start gap-6 w-full">
              <div id="left-column" className="flex-col items-start flex w-1/2">
                <div className="flex flex-col w-full">
                  <div className="flex justify-between items-center w-full">
                    <p className={labelCN} style={labelWidth}>
                      Start Date
                      <span className="text-brandGreen">*</span>
                    </p>
                    <div className="w-full">
                      <DatePicker
                        className=""
                        onChange={(val) =>
                          handleChange("startDate", {
                            data: moment(val).utc().format(),
                          })
                        }
                        value={requestForm?.startDate?.actual}
                        minDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-b w-full" />

              <div
                id="right-column"
                className="flex flex-col items-start w-1/2"
                style={rowHeight}
              >
                <div className="flex items-center w-full">
                  <p className={labelCN} style={labelWidth}>
                    Distro List
                  </p>
                  <div className="relative inline-flex flex-col justify-end items-start gap-3 w-full">
                    {!!requestForm?.distribution?.length && (
                      <div className="flex items-end gap-1">
                        <RequestGridViewDistroListCell
                          distroList={requestForm?.distribution}
                          hideTile
                          handleDeleteFromDistro={handleDeleteFromDistro}
                          className="flex-col"
                        />
                      </div>
                    )}
                    <PlusCircleButton
                      className="mt-0"
                      noHover
                      style={{ color: "#027D61" }}
                      title="Add"
                      disabled={!requestForm.association}
                      onClick={() => setDistroSelectOpen(true)}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* ** commented out per ES-9360: Schedule Reference and Budget Reference values will be automated ** */}

            {/* <div className="flex justify-between items-center w-full py-6">
                    <p className={labelCN}>Schedule Reference</p>
                    <div className="w-2/3">
                      <Input
                        placeholder="Enter Description"
                        inputClassName="ESInput bg-lightGreen text-gray-450 placeholderOverride"
                        inputContainerClassName="bg-lightGreen text-gray-450"
                        value={requestForm.schedule.description}
                        onChange={(val) =>
                          handleChange("scheduleDescription", val)
                        }
                      />
                    </div>
                  </div>
                  <div id="horizontal-divider-3" className="border-b w-full" />
                  <div className="flex justify-between items-center py-6 w-full">
                    <p className={labelCN}>Budget Reference</p>
                    <div className="w-2/3">
                      <Input
                        placeholder="Enter Description"
                        inputClassName="ESInput bg-lightGreen text-gray-450 placeholderOverride"
                        inputContainerClassName="bg-lightGreen text-gray-450"
                        value={requestForm.budget.description}
                        onChange={(val) =>
                          handleChange("budgetDescription", val)
                        }
                      />
                    </div>
                  </div> */}
          </div>
        </Widget>
      </div>
    </ModalWrapper>
  );
};

// prop types
SubmittalsCreateModal.propTypes = {
  modalData: PropTypes.shape({
    id: PropTypes.string,
  }),
};

// default props
SubmittalsCreateModal.defaultProps = {
  modalData: undefined,
};

export default SubmittalsCreateModal;

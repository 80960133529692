/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import "./FormHierarchyHorizontal.css";

const lineContainer = (hasLabel, isLastChild, customPadding) => cntl`
  ${hasLabel && "top-4"}
  ${!isLastChild && "pr-8"}
  ${customPadding && `${customPadding}`}
  ${!customPadding && "pl-4"}
  relative
`;

const horzLine = (showOnLeft) => cntl`
  bg-gray-300
  w-7
  h-px
  ${showOnLeft ? "ml-0" : "ml-1"}
  absolute
  bottom-1/2
  ${showOnLeft ? "left-0" : "right-0"}
`;

const badge = (hasLabel, isTextArea, isDateRangeFilter) => cntl`
  rounded-full
  h-3
  w-3
  flex
  bg-brandGreen
  absolute
  left-7
  ${
    hasLabel
      ? isTextArea
        ? cntl`badge-bottom46`
        : cntl`badge-bottom42`
      : isDateRangeFilter
      ? cntl`badge-bottom48`
      : cntl`badge-bottom39`
  }
`;

const FormHierarchyHorizontal = ({ children }) => {
  const doesChildHaveLabel = (child) => {
    if (child?.props?.label) {
      return true;
    }

    try {
      return (
        child?.props?.children?.some((grandChild) =>
          doesChildHaveLabel(grandChild)
        ) ?? false
      );
    } catch {
      return false;
    }
  };

  const customPadding = (child) => {
    if (child?.props?.customPadding) {
      return child.props.customPadding;
    }
    return false;
  };

  const isLast = (child) => {
    if (child?.props?.isLast) {
      return true;
    }
    return false;
  };

  const isChildTextArea = (child) => {
    if (child?.props?.isTextarea) {
      return true;
    }
    try {
      return (
        child?.props?.children?.some((grandChild) =>
          doesChildHaveLabel(grandChild)
        ) ?? false
      );
    } catch {
      return false;
    }
  };

  const isChildDateRangeFilter = (child) => {
    if (child?.props?.currentCol?.filterOptions?.label === "Date Range") {
      return true;
    }
    try {
      return (
        child?.props?.children?.some((grandChild) =>
          doesChildHaveLabel(grandChild)
        ) ?? false
      );
    } catch {
      return false;
    }
  };

  return (
    <div className="flex">
      {children?.map((child, index) => (
        <div key={Math.random(10000)} className="flex mb-2 flex-row">
          <div className="flex min-w-0">{child}</div>
          <div
            className={lineContainer(
              doesChildHaveLabel(child),
              isLast(child),
              customPadding(child)
            )}
          >
            {index < children?.length - 1 && (
              <>
                <span
                  className={badge(
                    doesChildHaveLabel(child),
                    isChildTextArea(child),
                    isChildDateRangeFilter(child)
                  )}
                />
                <span className={horzLine(true)} />
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

FormHierarchyHorizontal.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

FormHierarchyHorizontal.defaultProps = {};

export default FormHierarchyHorizontal;

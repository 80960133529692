import cntl from "cntl";
import React from "react";
import RadioButton from "../Buttons/RadioButton";
import Checkbox from "../Checkbox/Checkbox";

const settingsCN = () => cntl`
flex
flex-wrap   
`;

/**
 *
 * @param {Array} settings
 * - Example:
 * [
 *  {
 *      title: [Section Title],
 *      toggles: [
 *                  {
 *                      type: ["checkbox" || "radio"],
 *                      label: ["button label"],
 *                      onClick: () => func(),
 *                      value: [stateOfSetting] boolean
 *                    }
 *                  ]
 *  },
 * ]
 */
const WidgetSettings = ({ settings }) => {
  return (
    <div
      className="flex flex-col items-center absolute shadow-1 bg-white rounded-md overflow-hidden"
      style={{ top: "100%", right: "0%", width: "344px", zIndex: "20" }}
    >
      <p className="text-white text-md mb-1 w-full text-center bg-brandGreen font-medium py-1">
        Settings
      </p>
      <div className={settingsCN()}>
        {settings?.map((item, i) => {
          return (
            <div
              className="flex flex-col p-4"
              style={{ flex: "0 1 0px" }}
              // eslint-disable-next-line react/no-array-index-key
              key={`${item?.type}-${i}`}
            >
              <div className="flex flex-col">
                {item?.toggles?.map((toggle, index) => {
                  switch (item?.type) {
                    case "radio":
                      return (
                        <RadioButton
                          isChecked={toggle?.value}
                          onChange={toggle?.onClick}
                          label={toggle?.label}
                          className={`${
                            index !== item?.toggles?.length - 1 && "mb-1"
                          }`}
                        />
                      );
                    default:
                      return (
                        <Checkbox
                          checked={toggle?.value}
                          onChange={toggle?.onClick}
                          label={toggle?.label}
                          className={`${
                            index !== item?.toggles?.length - 1 && "mb-1"
                          }`}
                        />
                      );
                  }
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WidgetSettings;

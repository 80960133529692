import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import moment from "moment";
import { getFullName } from "../../../helpers/Formatters";
import Pill from "../Pill/Pill";
import {
  DOCUMENT_STATUS_PILL_INFO,
  VENDOR_INVOICE,
  INVOICE,
} from "../../../constants";
import InvoiceIcon from "../../assets/images/InvoiceIcon.svg";
import IconButton from "../Buttons/IconButton";

const topLeaderItemCN = cntl`
    flex-1
    flex
    flex-col
    mr-4
`;
const topLeaderTitleCN = cntl`
    text-lg
    text-gray-300
    font-medium
`;

const topLDocumentTitleCN = cntl`
    text-lg
    font-semibold
    uppercase
    pt-1
`;

const topLInvoiceTitleCN = cntl`
    text-3xl
    font-semibold
    pt-1
    pl-2
`;
const topLeaderBodyCN = cntl`
    flex
    flex-col
    text-lg
    font-medium
`;

const DocumentHeader = ({ project, document }) => {
  return (
    <>
      {document.docType === VENDOR_INVOICE && (
        <div className="flex flex-col">
          <div className="flex mb-4">
            <div className={`${topLDocumentTitleCN}`}>
              {document?.vendorUser?.companyName} - {document?.name}
            </div>
            <div className="flex px-4">
              <Pill
                value={DOCUMENT_STATUS_PILL_INFO[document?.status]?.name}
                className={DOCUMENT_STATUS_PILL_INFO[document?.status]?.css}
                showLock={DOCUMENT_STATUS_PILL_INFO[document?.status]?.showLock}
                background=""
                padding="px-3"
              />
            </div>
            <div className="flex">
              <Pill
                value={`Round ${document?.round}`}
                background="bg-gray-200"
              />
            </div>
          </div>
          <div
            className="flex justify-between mb-16"
            style={{ minHeight: "192px" }}
          >
            <div className={topLeaderItemCN}>
              <div className={topLeaderTitleCN}>
                Submitted by {getFullName(document?.creator?.name)}
              </div>

              <div className={topLeaderBodyCN}>
                <span>
                  {moment(document?.metadata?.createdAt).format(
                    "dddd, MMMM Do YYYY, h:mm a"
                  )}
                </span>
              </div>
            </div>
            <div className={topLeaderItemCN}>
              <div className={topLeaderTitleCN}>Project</div>

              <div className={topLeaderBodyCN}>
                <span>{project?.name}</span>
              </div>
            </div>
            <div className={topLeaderItemCN}>
              <div className={topLeaderTitleCN}>Address</div>
              {!!project?.address[0] && (
                <div className={topLeaderBodyCN}>
                  <span>{project.address[0].street}</span>
                  <span>{project.address[0].street2}</span>
                  <span>{`${project.address[0].city}${
                    project.address[0].state
                      ? `, ${project.address[0].state}`
                      : ""
                  }`}</span>
                  <span>{`${project.address[0].country}${
                    project.address[0].zipCode
                      ? `, ${project.address[0].zipCode}`
                      : ""
                  }`}</span>
                </div>
              )}
            </div>
            {document?.status === "open" && document?.rejectReason && (
              <div className={topLeaderItemCN}>
                <div className={topLeaderTitleCN}>Reject Reason</div>

                <div className={topLeaderBodyCN}>
                  <span>{document?.rejectReason}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {document.docType === INVOICE && (
        <div className="flex flex-col">
          <div className="flex mb-4">
            <div className="flex pt-2.5">
              <IconButton
                imgClassName="w-5 h-6"
                iconClassName="w-5 h-6"
                icon={InvoiceIcon}
              />
            </div>
            <div className={`${topLInvoiceTitleCN}`}>{document?.name}</div>
            <div className="flex px-4">
              <Pill
                value={DOCUMENT_STATUS_PILL_INFO[document?.status]?.name}
                className={DOCUMENT_STATUS_PILL_INFO[document?.status]?.css}
                showLock={DOCUMENT_STATUS_PILL_INFO[document?.status]?.showLock}
                background=""
                padding="px-3"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

DocumentHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.object,
};

DocumentHeader.defaultProps = {
  project: {},
  document: {},
};

export default DocumentHeader;

import React from "react";
// import PropTypes from "prop-types";

const PlusIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        stroke="currentColor"
        strokeWidth="0.3"
        d="M11.127 5.692H7.175V1.739A.74.74 0 006.434 1a.74.74 0 00-.74.74v3.952H1.74a.74.74 0 100 1.481h3.952v3.953a.74.74 0 101.482 0V7.173h3.952a.74.74 0 100-1.481"
      />
    </svg>
  );
};

PlusIcon.propTypes = {};

PlusIcon.defaultProps = {};

export default PlusIcon;

/**
 * This restore the operation made by `extractAdministrativePermissions`, transforming the given role's permissions
 * to align with the backend's expected data model (https://estatespace.atlassian.net/jira/software/projects/ES/boards/26?selectedIssue=ES-8848)
 * @param {Object} rolePayload -  payload role data with permissions
 * @returns {Object} new object with administrative permissions extracted from P/P and organized, as backend expected
 */
const restoreAdministrativePermissions = (rolePayload) => {
  const data = { ...rolePayload };

  // creates an `administrative` object with given permissions
  data.permissions.administrative = {
    can_write_property: !!data.permissions.property?.can_write_property,
    can_delete_property: !!data.permissions.property?.can_delete_property,
    can_write_project: !!data.permissions.project?.can_write_project,
    can_delete_project: !!data.permissions.project?.can_delete_project,
  };

  // Remove the admnistrative permissions from property/project permissions
  delete data.permissions?.property?.can_write_property;
  delete data.permissions?.property?.can_delete_property;
  delete data.permissions?.project?.can_write_project;
  delete data.permissions?.project?.can_delete_project;

  return data;
};

export default restoreAdministrativePermissions;

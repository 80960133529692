import { useReducer } from "react";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";

const defaultState = {};

const getUpdatedList = (list, from, to) => {
  const array = [...list];
  array.splice(to, 0, array.splice(from, 1)[0]);
  return array;
};

const reducer = (configuration, action) => {
  switch (action.type) {
    case "reset":
      return cloneDeep(action.configuration) ?? defaultState;
    case "addCustomDocumentType": {
      return {
        ...configuration,
        customDocumentTypes: [
          ...configuration.customDocumentTypes,
          {
            display: action.value ?? "",
            id: uuidv4(),
            selected: true,
            custom: true,
            isEditing: true,
          },
        ],
      };
    }
    case "updateCustomDocumentType": {
      return action.value;
    }
    case "removeCustomDocumentType": {
      return {
        ...configuration,
        customDocumentTypes: configuration.customDocumentTypes.filter(
          (type, idx) => idx !== action.index
        ),
      };
    }
    case "changeContractTerm": {
      return {
        ...configuration,
        contractTermHighlights: action.value,
      };
    }
    case "changeCoverSheet": {
      return {
        ...configuration,
        coverSheet: action.value,
      };
    }
    case "changeCustomDocumentType": {
      return {
        ...configuration,
        customDocumentTypes: configuration.customDocumentTypes.map((p, idx) => {
          if (idx === action.index) {
            return {
              ...p,
              display: action.value,
            };
          }
          return p;
        }),
      };
    }
    case "changeCustomDocumentTypeEditing": {
      return {
        ...configuration,
        customDocumentTypes: configuration.customDocumentTypes.map(
          (type, idx) => {
            if (idx === action.index) {
              return {
                ...type,
                isEditing: !type.isEditing,
              };
            }
            return type;
          }
        ),
      };
    }
    case "changeBidsQualificationsPosition": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          bid: getUpdatedList(
            configuration.qualsAndExceptions?.bid,
            action.from,
            action.to
          ),
        },
      };
    }
    case "changeCoQualificationsPosition": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          changeOrder: getUpdatedList(
            configuration.qualsAndExceptions?.changeOrder,
            action.from,
            action.to
          ),
        },
      };
    }
    case "changePaQualificationsPosition": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          purchaseAuthorization: getUpdatedList(
            configuration.qualsAndExceptions?.purchaseAuthorization,
            action.from,
            action.to
          ),
        },
      };
    }
    case "changeCaQualificationsPosition": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          contingencyAuthorization: getUpdatedList(
            configuration.qualsAndExceptions?.contingencyAuthorization,
            action.from,
            action.to
          ),
        },
      };
    }
    case "changePoQualificationsPosition": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          purchaseOrder: getUpdatedList(
            configuration.qualsAndExceptions?.purchaseOrder,
            action.from,
            action.to
          ),
        },
      };
    }
    case "changeRfpDocumentsPosition": {
      return {
        ...configuration,
        submissionReqs: getUpdatedList(
          configuration.submissionReqs,
          action.from,
          action.to
        ),
      };
    }
    case "changeBidsQualificationsEditing": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          bid: configuration.qualsAndExceptions?.bid?.map((qual, idx) => {
            if (idx === action.index) {
              return {
                ...qual,
                isEditing: !qual.isEditing,
              };
            }

            return qual;
          }),
        },
      };
    }
    case "changeCoQualificationsEditing": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          changeOrder: configuration.qualsAndExceptions?.changeOrder?.map(
            (qual, idx) => {
              if (idx === action.index) {
                return {
                  ...qual,
                  isEditing: !qual.isEditing,
                };
              }

              return qual;
            }
          ),
        },
      };
    }
    case "changePaQualificationsEditing": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          purchaseAuthorization:
            configuration.qualsAndExceptions?.purchaseAuthorization?.map(
              (qual, idx) => {
                if (idx === action.index) {
                  return {
                    ...qual,
                    isEditing: !qual.isEditing,
                  };
                }

                return qual;
              }
            ),
        },
      };
    }
    case "changeCaQualificationsEditing": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          contingencyAuthorization:
            configuration.qualsAndExceptions?.contingencyAuthorization?.map(
              (qual, idx) => {
                if (idx === action.index) {
                  return {
                    ...qual,
                    isEditing: !qual.isEditing,
                  };
                }

                return qual;
              }
            ),
        },
      };
    }
    case "changePoQualificationsEditing": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          purchaseOrder: configuration.qualsAndExceptions?.purchaseOrder?.map(
            (qual, idx) => {
              if (idx === action.index) {
                return {
                  ...qual,
                  isEditing: !qual.isEditing,
                };
              }

              return qual;
            }
          ),
        },
      };
    }
    case "changeRfpDocumentsEditing": {
      return {
        ...configuration,
        submissionReqs: configuration.submissionReqs?.map((qual, idx) => {
          if (idx === action.index) {
            return {
              ...qual,
              isEditing: !qual.isEditing,
            };
          }

          return qual;
        }),
      };
    }
    case "removeBidsQualifications": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          bid: configuration.qualsAndExceptions?.bid?.filter(
            (qual, idx) => idx !== action.index
          ),
        },
      };
    }
    case "removeCoQualifications": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          changeOrder: configuration.qualsAndExceptions?.changeOrder?.filter(
            (qual, idx) => idx !== action.index
          ),
        },
      };
    }
    case "removePaQualifications": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          purchaseAuthorization:
            configuration.qualsAndExceptions?.purchaseAuthorization?.filter(
              (qual, idx) => idx !== action.index
            ),
        },
      };
    }
    case "removeCaQualifications": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          contingencyAuthorization:
            configuration.qualsAndExceptions?.contingencyAuthorization?.filter(
              (qual, idx) => idx !== action.index
            ),
        },
      };
    }
    case "removePoQualifications": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          purchaseOrder:
            configuration.qualsAndExceptions?.purchaseOrder?.filter(
              (qual, idx) => idx !== action.index
            ),
        },
      };
    }
    case "removeRfpDocuments": {
      return {
        ...configuration,
        submissionReqs: configuration.submissionReqs?.filter(
          (qual, idx) => idx !== action.index
        ),
      };
    }
    case "changeBidsQualifications": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          bid: configuration.qualsAndExceptions?.bid?.map((qual, idx) => {
            if (idx === action.index) {
              return {
                ...qual,
                label: action.value,
                value: action.value,
              };
            }

            return qual;
          }),
        },
      };
    }
    case "changeCoQualifications": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          changeOrder: configuration.qualsAndExceptions?.changeOrder?.map(
            (qual, idx) => {
              if (idx === action.index) {
                return {
                  ...qual,
                  label: action.value,
                  value: action.value,
                };
              }

              return qual;
            }
          ),
        },
      };
    }
    case "changePaQualifications": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          purchaseAuthorization:
            configuration.qualsAndExceptions?.purchaseAuthorization?.map(
              (qual, idx) => {
                if (idx === action.index) {
                  return {
                    ...qual,
                    label: action.value,
                    value: action.value,
                  };
                }

                return qual;
              }
            ),
        },
      };
    }
    case "changeCaQualifications": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          contingencyAuthorization:
            configuration.qualsAndExceptions?.contingencyAuthorization?.map(
              (qual, idx) => {
                if (idx === action.index) {
                  return {
                    ...qual,
                    label: action.value,
                    value: action.value,
                  };
                }

                return qual;
              }
            ),
        },
      };
    }
    case "changePoQualifications": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          purchaseOrder: configuration.qualsAndExceptions?.purchaseOrder?.map(
            (qual, idx) => {
              if (idx === action.index) {
                return {
                  ...qual,
                  label: action.value,
                  value: action.value,
                };
              }

              return qual;
            }
          ),
        },
      };
    }
    case "changeRfpDocuments": {
      return {
        ...configuration,
        submissionReqs: configuration.submissionReqs?.map((qual, idx) => {
          if (idx === action.index) {
            return {
              ...qual,
              label: action.value,
              value: action.value,
            };
          }

          return qual;
        }),
      };
    }
    case "addBidsQualifications": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          bid: [
            ...configuration.qualsAndExceptions?.bid,
            {
              label: "",
              value: "",
              isEditing: true,
            },
          ],
        },
      };
    }
    case "addCoQualifications": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          changeOrder: [
            ...configuration.qualsAndExceptions?.changeOrder,
            {
              label: "",
              value: "",
              isEditing: true,
            },
          ],
        },
      };
    }
    case "addPaQualifications": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          purchaseAuthorization: [
            ...configuration.qualsAndExceptions?.purchaseAuthorization,
            {
              label: "",
              value: "",
              isEditing: true,
            },
          ],
        },
      };
    }
    case "addCaQualifications": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          contingencyAuthorization: [
            ...configuration.qualsAndExceptions?.contingencyAuthorization,
            {
              label: "",
              value: "",
              isEditing: true,
            },
          ],
        },
      };
    }
    case "addPoQualifications": {
      return {
        ...configuration,
        qualsAndExceptions: {
          ...configuration.qualsAndExceptions,
          purchaseOrder: [
            ...configuration.qualsAndExceptions?.purchaseOrder,
            {
              label: "",
              value: "",
              isEditing: true,
            },
          ],
        },
      };
    }
    case "addRfpDocuments": {
      return {
        ...configuration,
        submissionReqs: [
          ...configuration.submissionReqs,
          {
            label: "",
            value: "",
            isEditing: true,
          },
        ],
      };
    }
    default:
      return configuration;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};

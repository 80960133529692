import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import moment from "moment";
import { FILTER_TYPES } from "../../../constants";
import Table from "../Table/Table";
import TableDescriptionCell from "../Table/TableDescriptionCell";
import { isFullDay } from "../../../helpers/Calendar";

const containerCN = cntl`
  bg-white
  mb-10
`;

const groupOptions = {
  // ordered list of grouped column options
  hierarchicalOptions: [
    // add more hierarchical groups
  ],
  // un-ordered list of grouped column options
  nonHierarchicalOptions: [
    {
      label: "Association",
      value: "associationName",
    },
    {
      label: "Assigned To",
      value: "assignedTo",
    },
  ],
};

const PureMaintenanceTable = ({
  data,
  setCreateMaintenanceModal,
  onRowClick,
  hideSiteHeaderTitle,
}) => {
  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
        isPrimary: true,
        width: 200,
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ row }) => <TableDescriptionCell row={row} />,
        width: 200,
      },
      {
        Header: "Due Date",
        accessor: "tableDueDate",
        Cell: ({ row }) => {
          return (
            <div className="flex">
              <p>
                {isFullDay(
                  row?.original?.startDate,
                  row?.original?.endDate,
                  row?.original?.allDay
                )
                  ? moment(row?.original?.tableDueDate).format(
                      "dddd, MMMM Do YYYY"
                    )
                  : moment(row?.original?.tableDueDate).format(
                      "dddd, MMMM Do YYYY, h:mm a"
                    )}
              </p>
            </div>
          );
        },
        filterOptions: {
          label: "Due Date",
          filterType: FILTER_TYPES.date,
        },
        width: 350,
        Aggregated: () => null,
      },
      {
        Header: "Assigned To",
        accessor: "assignedTo",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
        width: 200,
      },
      {
        Header: "Association",
        accessor: "associationName",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
        width: 200,
      },
      {
        Header: "Recurrence",
        accessor: "recurrence",
        width: 200,
      },
      {
        Header: "Last Completed",
        accessor: "lastCompleted",
        Cell: ({ value }) => (
          <div className="flex">
            <p>
              {value ? moment(value).format("dddd, MMMM Do YYYY, h:mm a") : "-"}
            </p>
          </div>
        ),
        width: 350,
      },
    ];
  }, []);

  return (
    <div className={containerCN}>
      <Table
        resourceName="maintenance"
        columns={tableColumns}
        data={data}
        showFilterButton
        showChangeView
        showConstantRowIndex
        groupOptions={groupOptions}
        setCreateMaintenanceModal={setCreateMaintenanceModal}
        onRowClick={onRowClick}
        hideSiteHeaderTitle={hideSiteHeaderTitle}
      />
    </div>
  );
};

PureMaintenanceTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  setCreateMaintenanceModal: PropTypes.func,
  onRowClick: PropTypes.func,
  hideSiteHeaderTitle: PropTypes.bool,
};

PureMaintenanceTable.defaultProps = {
  data: [],
  setCreateMaintenanceModal: undefined,
  onRowClick: undefined,
  hideSiteHeaderTitle: false,
};

export default PureMaintenanceTable;

import React from "react";
import Modal from "../Modal/Modal";
import useCreateSpaceModalData from "./useCreateSpaceModalData";
import SpaceForm from "./SpaceForm";

const CreateSpaceModal = ({ isOpen, onCreate, onClose, configuration }) => {
  const {
    space,
    handleClose,
    valid,
    handleCreate,
    changeName,
    spaceTypeOptions,
    changeType,
    levelTypeOptions,
    changeLevel,
    changeArea,
    canCreateType,
    showConfirm,
    tmpAvatarImg,
    setTmpAvatarImg,
  } = useCreateSpaceModalData({
    onClose,
    onCreate,
    configuration,
  });

  return (
    <Modal
      title="Create Space"
      isOpen={isOpen}
      onRequestModalClose={handleClose}
      primaryButtonTitle="Save"
      primaryButtonOnClick={handleCreate}
      tertiaryButtonTitle="Cancel"
      trashCan
      disabled={!valid}
      hideFooter
      modalAction="Space Creation"
      showConfirm={showConfirm}
    >
      <SpaceForm
        space={space}
        tmpAvatarImg={tmpAvatarImg}
        setTmpAvatarImg={setTmpAvatarImg}
        changeName={changeName}
        spaceTypeOptions={spaceTypeOptions}
        changeType={changeType}
        levelTypeOptions={levelTypeOptions}
        changeLevel={changeLevel}
        changeArea={changeArea}
        canCreateType={canCreateType}
      />
    </Modal>
  );
};

export default CreateSpaceModal;

const calculateTotalsByDate = (value) => {
  if (!value) return 0;

  const totalMinutes = value.reduce((acc, item) => acc + item?.value, 0);
  let totalHours = totalMinutes / 60;

  if (totalHours % 1 !== 0) {
    totalHours = totalHours.toFixed(2);
  } else {
    totalHours = Math.floor(totalHours);
  }

  return totalHours;
};

export default calculateTotalsByDate;

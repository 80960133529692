import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Spinner from "react-loader-spinner";
import moment from "moment";
import cntl from "cntl";

import Checkbox from "../Checkbox/Checkbox";
import BaseButton from "../Buttons/BaseButton";

import dogEar from "../../assets/images/dogEar.svg";
import dogEarGreen from "../../assets/images/dogEarGreen.svg";
import dogEarDashed from "../../assets/images/dogEarDashed.svg";
import dogEarDashedGreen from "../../assets/images/dogEarDashedGreen.svg";
import starSolidGreen from "../../assets/images/starSolidGreen.svg";
import starWhite from "../../assets/images/starWhite.svg";
import uploadArrow from "../../assets/images/uploadArrow.svg";
import lockIcon from "../../assets/images/lockIcon.svg";
import ellipsisIcon from "../../assets/images/ellipsisIcon.svg";
import editIcon from "../../assets/images/editIcon.svg";

import { downloadMedia } from "../../../helpers/File";
import { docTypeOptionsMap } from "../../../constants";
import { getDocumentUser } from "../../../helpers/Document";

const styling = (style) => ({
  maxWidth: "225px",
  ...style,
});

const FileCard = ({
  id,
  style,
  docType,
  attachments,
  name,
  filename,
  customName,
  createdAt,
  creator,
  status,
  checked,
  isFavorited,
  isDraft,
  isUploadable,
  isLocked,
  isUploading,
  isDisabled,
  newFile,
  selectedDocuments,
  contentReference,
  onFileSelect,
  onFileClick,
  onFileEdit,
  onFileDelete,
  onShowHistory,
  onSendToWorkflow,
  onDownloadFile,
  // onUploadVersion,
  onFavoriteClick,
  hideButtons,
  isDisplay,
  docTypeHasLabel,
  fileButtonPosition,
  isMiniature,
  allowEdit,
  vendorName,
}) => {
  const [isChecked, setIsChecked] = useState(
    selectedDocuments.includes(id) || checked
  );
  const [hovering, setHovering] = useState(false);
  const [hoveringStar, setHoveringStar] = useState(false);
  const [fileDownloadUrl, setFileDownloadUrl] = useState();
  const downloadButton = useRef(null);

  const fileCardCN = cntl`
    flex
    flex-col
    items-center
    h-60
    px-3
    ${newFile ? "border-2" : "border"}
    ${newFile ? "border-dashed" : ""}
    rounded
    ${!hoveringStar && !isDisplay ? "hover:border-brandGreen" : ""}
  `;

  const cornerIconStyling = React.useMemo(
    () => ({
      width: newFile ? "41px" : "40px",
      height: newFile ? "33px" : "32px",
      margin: newFile ? "-2.0px -16.5px 0 0" : "-2.0px -16px 0 0",
    }),
    [newFile]
  );

  const cornerIconHovering = React.useMemo(() => {
    if (newFile) {
      return hovering && !hoveringStar ? dogEarDashedGreen : dogEarDashed;
    }
    return hovering && !hoveringStar ? dogEarGreen : dogEar;
  }, [newFile, hovering, hoveringStar]);

  const handleFileSelect = (check) => {
    onFileSelect(id);
    setIsChecked(check);
  };

  const getDocType = (documentType) => {
    if (documentType) {
      return docTypeOptionsMap[documentType]?.label || documentType;
    }
    return "File";
  };

  useEffect(() => {
    if (fileDownloadUrl) {
      downloadButton.current.click();
      setFileDownloadUrl(null);
    }
  }, [fileDownloadUrl]);

  const handleFileDownload = async (cRefs) => {
    await downloadMedia(cRefs ?? [contentReference]);
  };

  const getFileOptions = () => {
    const fileOptionsArr = [];
    const editFile = {
      title: "Edit",
      onClick: () => onFileEdit({ id, customName, docType }),
    };
    const sendToWorkflow = {
      title: "Send to Workflow",
      onClick: () => onSendToWorkflow(id),
    };
    const showHistory = {
      title: "Show History",
      onClick: () => onShowHistory(id),
    };
    const downloadFile = {
      title: "Download File",
      onClick: () =>
        onDownloadFile
          ? onDownloadFile({ id, customName, docType })
          : handleFileDownload(),
    };
    const attachmentOptions = {
      title: "Download Attachments",
      onClick: () => {
        if (attachments?.length) {
          handleFileDownload(
            attachments.map(({ contentReference: cRef }) => cRef)
          );
        }
      },
    };
    // Removed for 3.0
    // const uploadVersion = {
    //   title: "Upload New Version",
    //   onClick: () => onUploadVersion(id),
    // };
    const deleteFile = {
      title: "Delete",
      onClick: () =>
        onFileDelete({
          id,
          name: filename || customName || name || "Unnamed Document",
        }),
    };

    if (isMiniature) {
      fileOptionsArr.push(downloadFile);
      return fileOptionsArr;
    }

    if (docType === docTypeOptionsMap.purchaseOrder.label) {
      fileOptionsArr.push(sendToWorkflow);
    } else if (isLocked) {
      fileOptionsArr.push(showHistory);
    } else {
      if (status === "draft" || allowEdit) {
        fileOptionsArr.push(editFile);
      }
      fileOptionsArr.push(sendToWorkflow);
      fileOptionsArr.push(showHistory);
      if (contentReference) {
        fileOptionsArr.push(downloadFile);
      }
      fileOptionsArr.push(deleteFile);
    }

    if (contentReference) {
      // fileOptionsArr.push(uploadVersion);
    }

    if (attachments?.length) {
      fileOptionsArr.push(attachmentOptions);
    }

    return fileOptionsArr;
  };

  const handleHover = (val) => {
    if (!isDisplay) {
      setHovering(val);
    }
  };
  const handleStarHover = (val) => {
    if (!isDisplay) {
      setHoveringStar(val);
    }
  };

  return (
    <div
      className={fileCardCN}
      onMouseOver={() => handleHover(true)}
      onMouseOut={() => handleHover(false)}
      onFocus={() => handleHover(true)}
      onBlur={() => handleHover(false)}
      style={styling(style)}
    >
      <div className="flex justify-between w-full mb-2">
        <Checkbox
          className={`float-left m-2 bg-white rounded ${
            onFileSelect ? "visible" : "invisible"
          }`}
          checked={isChecked}
          onChange={(val) => handleFileSelect(val)}
          disabled={isDisabled}
        />

        <button
          type="button"
          className={newFile || isDisplay ? "invisible" : ""}
          onClick={() => onFavoriteClick(id)}
          onMouseOver={() => handleStarHover(true)}
          onMouseOut={() => handleStarHover(false)}
          onFocus={() => handleStarHover(true)}
          onBlur={() => handleStarHover(false)}
        >
          {!isMiniature && (
            <img
              className={`self-center h-5 ${isUploading ? "invisible" : ""}`}
              src={isFavorited || hoveringStar ? starSolidGreen : starWhite}
              alt="favorite button"
            />
          )}
        </button>

        <img
          className={`select-none ${isDisplay ? "invisible" : ""}`}
          style={cornerIconStyling}
          src={cornerIconHovering}
          alt=""
        />
      </div>

      {!isUploading && (
        <div className="flex flex-col w-full h-full overflow-hidden">
          <button
            type="button"
            className="flex w-full h-full mx-auto"
            onClick={(e) => {
              if (!onFileSelect) {
                onFileClick(e);
              }
            }}
          >
            <p
              className="w-full h-full text-center text-sm font-bold overflow-hidden capitalize"
              style={{ overflowWrap: "break-word" }}
            >
              {newFile && "+ New Document"}
              {!newFile &&
                docType !== "Published Budget" &&
                docType !== "Vendor Invoice" &&
                (filename || customName || name || "Unnamed Document")}
              {!newFile &&
                docType === "Published Budget" &&
                (filename || name || customName || "Unnamed Document")}
              {!newFile &&
                docType === "Vendor Invoice" &&
                `${
                  filename || customName || name || "Unnamed Document"
                } - ${vendorName}`}
            </p>
          </button>
        </div>
      )}

      {isUploading && (
        <div className="flex flex-col pt-2 mx-auto">
          <p className="text-center text-sm font-bold">Uploading</p>
        </div>
      )}

      {newFile && !isUploading && (
        <button type="button" className="" onClick={() => {}}>
          <img
            className="self-center h-10 mb-8"
            src={editIcon}
            alt="create button"
          />
        </button>
      )}

      {!newFile && !isUploading && !isMiniature && (
        <button
          type="button"
          className="w-full pt-2"
          onClick={(e) => {
            if (!onFileSelect) {
              onFileClick(e);
            }
          }}
        >
          <div className="flex flex-col items-center w-full text-xs">
            <p className="font-semibold">
              {docTypeHasLabel ? docType : getDocType(docType)}
            </p>
            <p className="mt-3">
              <span className="text-gray-200">Created </span>
              {createdAt ? moment(createdAt).format("MM/DD/YYYY") : "--"}
            </p>
            <p className="max-w-full truncate">
              <span className="text-gray-200">By </span>
              {getDocumentUser(creator)}
            </p>
          </div>
        </button>
      )}

      {isUploading && (
        <div className="flex flex-1 justify-center items-center">
          <Spinner />
        </div>
      )}

      <div className="flex justify-between items-center w-full h-10 -mt-1">
        {isUploadable && !isMiniature && (
          <img
            className="self-center h-5 cursor-pointer"
            src={uploadArrow}
            alt="upload icon"
          />
        )}

        {isLocked && !isMiniature && (
          <img
            className="self-center h-5 cursor-pointer"
            src={lockIcon}
            alt="lock icon"
          />
        )}

        {!isMiniature && (
          <p
            className={`rounded-full my-3 px-2 text-xs text-white bg-gray-200 ${
              status === "draft" || isDraft ? "visible" : "invisible"
            }`}
          >
            DRAFT
          </p>
        )}

        <div
          className={`relative ${isDisplay || hideButtons ? "invisible" : ""}`}
          style={{ marginLeft: isMiniature ? "auto" : "0px" }}
        >
          <BaseButton
            className="flex items-center justify-center"
            iconRight={<img src={ellipsisIcon} alt="" />}
            dropdownItems={getFileOptions()}
            fileButtonPosition={fileButtonPosition}
          />
        </div>
        <a
          className="hidden"
          download={`${name}.pdf`}
          href={fileDownloadUrl}
          ref={downloadButton}
        >
          Download Attachments
        </a>
      </div>
    </div>
  );
};

FileCard.propTypes = {
  /**
   * container styles
   */
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  /**
   * string used for handling click events
   */
  id: PropTypes.string,
  /**
   * string used to determine the description
   */
  docType: PropTypes.string,
  /**
   * attachments
   */
  attachments: PropTypes.arrayOf(PropTypes.string),
  /**
   * file name
   */
  name: PropTypes.string,
  /**
   * file name
   */
  filename: PropTypes.string,
  /**
   * file custom name
   */
  customName: PropTypes.string,
  /**
   * doc creation date
   */
  createdAt: PropTypes.string,
  /**
   * doc creator
   */
  // eslint-disable-next-line react/forbid-prop-types
  creator: PropTypes.oneOf(PropTypes.string, PropTypes.object),
  /**
   * doc status
   */
  status: PropTypes.string,
  /**
   * doc is checked
   */
  checked: PropTypes.bool,
  /**
   * boolean value to determine if the favorite icon is highlighted
   */
  isFavorited: PropTypes.bool,
  /**
   * is doc draft
   */
  isDraft: PropTypes.bool,
  /**
   * is doc uploadable
   */
  isUploadable: PropTypes.bool,
  /**
   * is doc locked
   */
  isLocked: PropTypes.bool,
  /**
   * is doc uploading
   */
  isUploading: PropTypes.bool,
  /**
   * render new doc version
   */
  newFile: PropTypes.bool,
  /**
   * array of attached documents
   */
  selectedDocuments: PropTypes.arrayOf(PropTypes.string),
  /**
   * file reference for uploaded document
   */
  contentReference: PropTypes.string,
  /**
   * function for handling select functionality
   */
  onFileSelect: PropTypes.func,
  /**
   * function for handling file click functionality
   */
  onFileClick: PropTypes.func,
  /**
   * function for handling file edit functionality
   */
  onFileEdit: PropTypes.func,
  /**
   * function for handling file delete functionality
   */
  onFileDelete: PropTypes.func,
  /**
   * function for handling file show history functionality
   */
  onShowHistory: PropTypes.func,
  /**
   * function for handling file send to workflow functionality
   */
  onSendToWorkflow: PropTypes.func,
  /**
   * function for handling file download
   */
  onDownloadFile: PropTypes.func,
  /**
   * function for handling file send upload version
   */
  // onUploadVersion: PropTypes.func,
  /**
   * function for handling favorite click functionality
   */
  onFavoriteClick: PropTypes.func,
  /**
   * hide file buttons
   */
  hideButtons: PropTypes.bool,
  /**
   * show non-interactive version
   */
  isDisplay: PropTypes.bool,
  /**
   * passing display label for docType
   */
  docTypeHasLabel: PropTypes.bool,
  /**
   * open file options above rather than below
   */
  fileButtonPosition: PropTypes.string,
  /**
   * show miniature versioh of file card
   */
  isMiniature: PropTypes.bool,
  /**
   * override status based edit logic
   */
  allowEdit: PropTypes.bool,
  isDisabled: PropTypes.bool,
  vendorName: PropTypes.string,
};

FileCard.defaultProps = {
  style: {},
  id: undefined,
  docType: undefined,
  attachments: undefined,
  name: undefined,
  filename: undefined,
  customName: undefined,
  createdAt: undefined,
  creator: undefined,
  status: undefined,
  checked: false,
  isFavorited: false,
  isDraft: false,
  isUploadable: false,
  isLocked: false,
  isUploading: false,
  newFile: false,
  selectedDocuments: [],
  contentReference: undefined,
  onFileSelect: undefined,
  onFileClick: undefined,
  onFileEdit: () => {},
  onFileDelete: () => {},
  onShowHistory: () => {},
  onSendToWorkflow: () => {},
  onDownloadFile: undefined,
  // onUploadVersion: () => {},
  onFavoriteClick: undefined,
  hideButtons: false,
  isDisplay: false,
  docTypeHasLabel: false,
  fileButtonPosition: undefined,
  isMiniature: false,
  allowEdit: false,
  isDisabled: false,
  vendorName: undefined,
};

export default FileCard;

import React from "react";
import cntl from "cntl";
import { isSafari } from "react-device-detect";
import PropTypes from "prop-types";
import moreInformation from "../../assets/images/moreInformation.svg";
import IconButton from "../Buttons/IconButton";
import PopoverList from "../PopoverList/PopoverList";

const menuContainerCN = cntl`
  bg-white
  border
  border-gray-300
  rounded
  absolute
  -right-2
  top-0
  z-50
`;
const WorkflowOptions = ({ options, onClick, isOptionOpen, optionRef }) => {
  return (
    <div className="flex relative z-10" ref={optionRef}>
      <IconButton
        icon={moreInformation}
        className={`ml-4 ${isSafari && "pl-4"} `}
        onClick={onClick}
      />
      {isOptionOpen && (
        <div className={menuContainerCN}>
          <PopoverList
            items={options}
            colorCN="text-black w-full"
            innerClassName="text-xs"
            noMaxWidth
          />
        </div>
      )}
    </div>
  );
};

WorkflowOptions.propTypes = {
  /**
   * popup options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ),
  /**
   * function triggered when popup is open
   */
  onClick: PropTypes.func,
  isOptionOpen: PropTypes.bool,
  optionRef: PropTypes.string,
};

WorkflowOptions.defaultProps = {
  options: [],
  onClick: undefined,
  isOptionOpen: false,
  optionRef: undefined,
};
export default WorkflowOptions;

import PropTypes from "prop-types";
import React from "react";
import Checkbox from "../Checkbox/Checkbox";

export default function CalendarCheckbox({
  selectedEventType,
  setSelectedEventType,
  title,
}) {
  return (
    <div className="flex col-span-1">
      {title === "Events" && (
        <Checkbox
          isCalendarCheckbox
          size="7"
          checked={selectedEventType?.event}
          label={`${title}`}
          labelClassName="text-lg text-gray-300 font-semibold"
          inputClassName="calendar-form-tick"
          onChange={() =>
            setSelectedEventType((prev) => ({
              ...prev,
              event: !prev.event,
            }))
          }
        />
      )}
      {title === "Tasks" && (
        <Checkbox
          isCalendarCheckbox
          isTask
          size="7"
          checked={selectedEventType?.task}
          label={`${title}`}
          inputClassName="calendar-form-tick"
          labelClassName="text-lg text-gray-300 font-semibold"
          onChange={() =>
            setSelectedEventType((prev) => ({
              ...prev,
              task: !prev.task,
            }))
          }
        />
      )}
    </div>
  );
}

CalendarCheckbox.propTypes = {
  selectedEventType: PropTypes.shape({
    task: PropTypes.bool,
    event: PropTypes.bool,
    maintenance: PropTypes.bool,
  }),
  setSelectedEventType: PropTypes.func,
  title: PropTypes.string,
};

CalendarCheckbox.defaultProps = {
  selectedEventType: {
    task: true,
    event: false,
    maintenance: false,
  },
  setSelectedEventType: undefined,
  title: undefined,
};

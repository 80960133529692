import _, { cloneDeep } from "lodash";
import { useReducer } from "react";
import { v4 as uuidv4 } from "uuid";

const defaultState = {
  originalResource: {},
};

const reducer = (project, action) => {
  let foundIndex;
  switch (action.type) {
    case "reset": {
      const cloned = cloneDeep(action.project);

      return cloned
        ? {
            ...cloned,
            rateSheet: {
              ...action.project.rateSheet,
              rates: [...(action.project.rateSheet?.rates ?? [])],
            },
            originalResource: cloned,
            retainage: cloned?.retainage?.map((item, idx) => ({
              ...item,
              index: idx,
            })),
          }
        : defaultState;
    }
    case "name":
      return {
        ...project,
        name: action.name,
      };
    case "setCurrentTags": {
      return { ...project, currentTags: action.currentTags };
    }
    case "edit":
      return {
        ...project,
        [action.key]: action.value,
      };
    case "changeStatus":
      return {
        ...project,
        status: action.value,
      };
    case "editMultiple": {
      let projectClone = _.cloneDeep(project);
      action.keys?.forEach((item, index) => {
        projectClone = { ...projectClone, [item]: action.values[index] };
      });
      return projectClone;
    }
    case "edit2":
      return {
        ...project,
        [action.key1]: {
          ...project[action.key1],
          [action.key2]: action.value,
        },
      };
    case "edit2Multiple": {
      let projectClone = _.cloneDeep(project);
      action.keys2?.forEach((item, index) => {
        projectClone = {
          ...projectClone,
          [action.key1]: {
            ...projectClone[action.key1],
            [item]: action.values[index],
          },
        };
      });
      return projectClone;
    }
    case "addRateSheet":
      return {
        ...project,
        rateSheet: {
          ...project.rateSheet,
          rates: [
            ...project.rateSheet.rates,
            {
              category: "",
              ratePerHr: "",
              rateOver40Hrs: "",
              premiumRate: "",
              selected: true,
              id: uuidv4(),
            },
          ],
        },
      };
    case "removeRateSheet": {
      return {
        ...project,
        rateSheet: {
          ...project.rateSheet,
          rates: project.rateSheet.rates.filter(
            (type, idx) => idx !== action.index
          ),
        },
      };
    }
    case "changeRateSheet": {
      return {
        ...project,
        rateSheet: {
          ...project.rateSheet,
          rates: project.rateSheet.rates.map((rateItem, idx) => {
            if (idx === action.index) {
              return {
                ...rateItem,
                [`${action.field}`]: action.value,
              };
            }
            return rateItem;
          }),
        },
      };
    }
    case "selectRateSheetPremiumRateDays": {
      return {
        ...project,
        rateSheet: {
          ...project.rateSheet,
          premiumRateDaysOfWeek: {
            ...project.rateSheet.premiumRateDaysOfWeek,
            [`${action.day}`]:
              !project.rateSheet.premiumRateDaysOfWeek[`${action.day}`],
          },
        },
      };
    }
    case "addMember":
      return {
        ...project,
        members: [...project.members, action.member],
      };
    case "removeMember":
      return {
        ...project,
        members: project.members?.filter((item) => item.user !== action.member),
      };
    case "addSpace": {
      return {
        ...project,
        buildings: project.buildings?.map((building) => {
          if (action.buildingId === building.id) {
            return {
              ...building,
              spaces: [
                ...building?.spaces,
                {
                  id: action.spaceId,
                  images: [],
                },
              ],
            };
          }
          return building;
        }),
      };
    }
    case "addBuilding": {
      return {
        ...project,
        buildings: [
          ...project.buildings,
          {
            id: action.buildingId,
            images: [],
            spaces: [],
            name: "Not Specified",
          },
        ],
      };
    }
    case "removeSpace": {
      return {
        ...project,
        buildings: project.buildings?.map((building) => {
          if (action.buildingId === building.id) {
            return {
              ...building,
              spaces: building?.spaces?.filter(
                (space) => space.id !== action.spaceId
              ),
            };
          }
          return building;
        }),
      };
    }
    case "removeBuilding": {
      return {
        ...project,
        buildings: project.buildings?.filter(
          (building) => building.id !== action.buildingId
        ),
      };
    }
    case "buildingArea": {
      const index = project.buildings?.findIndex(
        (item) => item.id === action.buildingId
      );
      if (index > -1) {
        return {
          ...project,
          buildings: project.buildings?.map((building, idx) => {
            if (index === idx) {
              return {
                ...building,
                area: {
                  ...building.area,
                  value: action.area,
                },
              };
            }
            return building;
          }),
        };
      }
      return project;
    }
    case "selectBuildingImage": {
      const index = project.buildings?.findIndex(
        (item) => item.id === action.buildingId
      );
      if (index > -1) {
        return {
          ...project,
          buildings: project.buildings?.map((building, idx) => {
            if (index === idx) {
              return {
                ...building,
                images: [
                  ...building.images?.map((img) => {
                    if (img.reference === action.reference) {
                      return {
                        ...img,
                        selected: true,
                      };
                    }
                    return {
                      ...img,
                      selected: false,
                    };
                  }),
                ],
              };
            }
            return building;
          }),
        };
      }
      return project;
    }
    case "changeBuildingPrimaryImage": {
      return {
        ...project,
        buildings: project.buildings?.map((building) => {
          if (building.id === action.buildingId) {
            return {
              ...building,
              images:
                building?.images?.map((img) => {
                  if (img.id === action.imageId) {
                    return {
                      ...img,
                      isPrimary: true,
                    };
                  }
                  return {
                    ...img,
                    isPrimary: false,
                  };
                }) || [],
            };
          }
          return building;
        }),
      };
    }
    case "addBuildingImage": {
      const index = project.buildings?.findIndex(
        (item) => item.id === action.buildingId
      );
      if (index > -1) {
        return {
          ...project,
          buildings: project.buildings?.map((building, idx) => {
            if (index === idx) {
              return {
                ...building,
                images: [
                  ...(building.images?.map((img) => ({
                    ...img,
                    isPrimary: false,
                    selected: false,
                  })) || []),
                  {
                    ...action.image,
                    isPrimary: true,
                    selected: true,
                    file: action.image.reference,
                  },
                ],
              };
            }
            return building;
          }),
        };
      }
      return project;
    }
    case "buildingName": {
      const index = project.buildings?.findIndex(
        (item) => item.id === action.buildingId
      );
      if (index > -1) {
        return {
          ...project,
          buildings: project.buildings?.map((building, idx) => {
            if (index === idx) {
              return {
                ...building,
                name: action.name,
              };
            }
            return building;
          }),
        };
      }
      return project;
    }
    case "spaceName": {
      return {
        ...project,
        buildings: project.buildings?.map((building) => {
          if (action.buildingId === building.id) {
            return {
              ...building,
              spaces: building?.spaces?.map((space) => {
                if (space.id === action.spaceId) {
                  return {
                    ...space,
                    name: action.name,
                  };
                }
                return space;
              }),
            };
          }
          return building;
        }),
      };
    }
    case "spaceLevel": {
      return {
        ...project,
        buildings: project.buildings?.map((building) => {
          if (action.buildingId === building.id) {
            return {
              ...building,
              spaces: building?.spaces?.map((space) => {
                if (space.id === action.spaceId) {
                  return {
                    ...space,
                    level: action.level,
                  };
                }
                return space;
              }),
            };
          }
          return building;
        }),
      };
    }
    case "spaceType": {
      return {
        ...project,
        buildings: project.buildings?.map((building) => {
          if (action.buildingId === building.id) {
            return {
              ...building,
              spaces: building?.spaces?.map((space) => {
                if (space.id === action.spaceId) {
                  return {
                    ...space,
                    type: action.spaceType,
                  };
                }
                return space;
              }),
            };
          }
          return building;
        }),
      };
    }
    case "addSpaceImage": {
      return {
        ...project,
        buildings: project.buildings?.map((building) => {
          if (action.buildingId === building.id) {
            return {
              ...building,
              spaces: building?.spaces?.map((space) => {
                if (space.id === action.spaceId) {
                  return {
                    ...space,
                    images: [
                      ...space.images,
                      {
                        ...action.image,
                        file: action.image.reference,
                      },
                    ],
                  };
                }
                return space;
              }),
            };
          }
          return building;
        }),
      };
    }
    case "editObject":
      return {
        ...project,
        [action.parentKey]: {
          ...project[action.parentKey],
          [action.childKey]: action.value,
        },
      };
    case "mailingSameAsPhysical":
      return {
        ...project,
        mailingSameAsPhysical: action.mailingSameAsPhysical,
      };
    case "address":
      return {
        ...project,
        address: action.address,
      };
    case "timezone":
      return {
        ...project,
        timezone: action.timezone,
      };
    case "addImage":
      return {
        ...project,
        images: [...(project.images ?? []), action.image],
      };
    case "addImages":
      return {
        ...project,
        images: action.images,
      };
    case "description":
      return {
        ...project,
        description: action.description,
      };
    case "projectType":
      return {
        ...project,
        projectType: action.projectType,
      };
    case "duration":
      return {
        ...project,
        duration: { ...project.duration, value: action.value },
      };
    case "startDate":
      return {
        ...project,
        startDate: action.value,
      };
    case "endDate":
      return {
        ...project,
        endDate: action.value,
      };
    case "contractDate":
      return {
        ...project,
        contractDate: action.contractDate,
      };
    case "addVendor":
      return {
        ...project,
        vendors: [...(project.vendors || []), action.vendor],
      };
    case "addVendors":
      return {
        ...project,
        vendors: action.vendors,
      };
    case "updateVendor":
      foundIndex = project.vendors?.findIndex(
        (item) => item.user === action.data?.user
      );
      if (foundIndex > -1) {
        return {
          ...project,
          vendors: project.vendors?.map((item) => {
            if (item.user === action.data?.user) {
              return {
                ...item,
                ...action.data,
              };
            }
            return item;
          }),
        };
      }
      return {
        ...project,
        vendors: [...project.vendors, action.data],
      };
    case "removeVendor":
      return {
        ...project,
        vendors: project.vendors?.filter((item) => item.user !== action.vendor),
      };
    case "addMembers":
      return {
        ...project,
        members: action.members,
      };
    case "updateMember":
      foundIndex = project.members?.findIndex(
        (item) => item.user === action.data?.user
      );
      if (foundIndex > -1) {
        return {
          ...project,
          members: project.members?.map((item) => {
            if (item.user === action.data?.user) {
              return {
                ...item,
                ...action.data,
              };
            }
            return item;
          }),
        };
      }
      return {
        ...project,
        members: [...project.members, action.data],
      };
    case "addLinkCompleteLink":
      return {
        ...project,
        links: [...(project.links ?? []), action.link],
      };
    case "addLink":
      return {
        ...project,
        links: [
          ...(project.links ?? []),
          { id: action.id, name: action.name, url: action.url },
        ],
      };
    case "deleteLink":
      return {
        ...project,
        links: project.links?.filter((link) => link.id !== action.linkId) ?? [],
      };
    case "setLinks":
      return {
        ...project,
        links: action?.links,
      };
    case "editRetainage":
      return { ...project, retainage: action.retainage };
    case "editInsurance":
      return {
        ...project,
        insurance: {
          ...project.insurance,
          requirements: [action.requirments],
          insuranceType: action.insruanceType,
        },
      };
    case "editTermHighlights":
      return {
        ...project,
        contractTermHighlights: action.termHighlights,
      };
    case "hoursOfOperation":
      return {
        ...project,
        hoursOfOperation: action.hoursOfOperation,
      };
    case "editDeposit":
      return {
        ...project,
        deposit: action.deposit,
      };
    case "editTimeToNotify":
      return {
        ...project,
        timeToNotify: action.timeToNotify,
      };
    case "editDivisionCode":
      return { ...project, divisionCode: action.divisionCode };
    case "contractual":
      return {
        ...project,
        contractualFee: action.fee,
        contractualInsurance: action.insurance,
        yearsOfWarranty: action.yearsOfWarranty,
      };
    default:
      return project;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};

import cntl from "cntl";
import React from "react";
import PropTypes from "prop-types";

const containerCN = (className) => cntl`
flex
flex-col
justify-center
items-center
${className}
`;

const TemperatureRowItem = ({
  title,
  value,
  className,
  children,
  measurement,
}) => {
  return (
    <div className={containerCN(className)}>
      {!children && (
        <>
          <p className="text-gray-400 text-sm">
            {title}
            <span className="text-xs text-gray-200">{measurement}</span>
          </p>
          <p className="text-xxs text-gray-200">{value}</p>
        </>
      )}
      <>{children}</>
    </div>
  );
};

TemperatureRowItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.element,
  measurement: PropTypes.string,
};

TemperatureRowItem.defaultProps = {
  title: "[Value]",
  value: "[Value]",
  className: undefined,
  children: undefined,
  measurement: "",
};

export default TemperatureRowItem;

import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

const titleCN = (className) => cntl`
  text-xl
  font-medium
  uppercase
  mb-3
  mt-3
  ${className}
`;

const containerCN = cntl`
  inline-block
`;

const rowCN = (index, isFooter) => cntl`
  flex
  ${index > 0 && !isFooter && "border-t border-gray-150"}
  ${isFooter && "border-t border-gray-300"}
`;

const keyCN = (className) => cntl`
  font-semibold
  ${className ?? "w-44"}
  truncate
`;

const unitCN = cntl`
  text-gray-200
  w-12
`;

const valueCN = cntl`
  ml-auto
`;

const Summary = ({
  title,
  values,
  className,
  keyClassName,
  titleClassName,
}) => {
  return (
    <div className={className}>
      {title && <h2 className={titleCN(titleClassName)}>{title}</h2>}
      <div className={containerCN}>
        {values?.map(({ id, key, value, isFooter, unit }, index) => (
          <div key={id || key || index} className={rowCN(index, isFooter)}>
            <p className={keyCN(keyClassName)}>{key}</p>
            <div className={unitCN}>{unit && <p>{unit}</p>}</div>
            <p className={valueCN}>{value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

Summary.propTypes = {
  /**
   * prints as an H2 above the summary chart
   */
  title: PropTypes.string,

  /**
   * the key value pairs to be displayed on the summary
   */
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      key: PropTypes.string,
      value: PropTypes.string,
      unit: PropTypes.string,
      isFooter: PropTypes.bool,
    })
  ),

  /**
   * class names to apply to the outer container element
   */
  className: PropTypes.string,
  /**
   * class names to apply to the key container element
   */
  keyClassName: PropTypes.string,
  titleClassName: PropTypes.string,
};

Summary.defaultProps = {
  title: undefined,
  values: undefined,
  className: undefined,
  keyClassName: undefined,
  titleClassName: undefined,
};

export default Summary;

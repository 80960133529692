/* eslint-disable no-alert */
import PropTypes from "prop-types";
import React from "react";
import {
  CALENDAR_MONTH_BORDER_OFFSET,
  CALENDAR_MONTH_EVENT_DATE_OFFSET,
  CALENDAR_MONTH_EVENT_HEIGHT,
  CALENDAR_MONTH_TEXT_VERTICAL_CENTER,
} from "../../../../constants";

const ViewMoreCell = ({ events, spill, column, row, onClick }) => {
  /**
   * lastEventIndex = index of first item to exceed cell
   * @returns {Number} More
   * Number = events.slice(lastEventIndex).length (Remaining events past cell limit`3`)
   */
  const lastEventIndex = events.findIndex((z) => z.id === spill.id);
  return (
    <g onClick={onClick} className="cursor-pointer">
      <rect
        // x = column * cell width + stroke
        x={column * 40 + CALENDAR_MONTH_BORDER_OFFSET}
        // y = (row * cell height) + (event height * array index - 1) + (size of date # container)
        y={
          row * 35 +
          CALENDAR_MONTH_EVENT_HEIGHT * 3 +
          CALENDAR_MONTH_EVENT_DATE_OFFSET
        }
        // width = (cell width * how many days the event spans) - (stroke * 2)
        width={40 - CALENDAR_MONTH_BORDER_OFFSET * 2}
        // height = height of event container
        height={CALENDAR_MONTH_EVENT_HEIGHT}
        fill="none"
        rx="1"
      />
      <text
        // x = column * cell width + (1/2 cell width)
        x={column * 40 + 20}
        // y = (row * cell height) + (event height * 4'last remaining event position') + (1/2 event container height) + (size of date # container)
        y={
          row * 35 +
          CALENDAR_MONTH_EVENT_HEIGHT * 3 +
          CALENDAR_MONTH_TEXT_VERTICAL_CENTER +
          CALENDAR_MONTH_EVENT_DATE_OFFSET
        }
        textAnchor="middle"
        fontSize="3"
        alignmentBaseline="middle"
        fill="#333333"
      >
        {`${events.slice(lastEventIndex).length} More`}
      </text>
    </g>
  );
};

ViewMoreCell.propTypes = {
  // events in cell
  events: PropTypes.arrayOf(PropTypes.shape({})),
  // first event exceeding container height
  spill: PropTypes.shape({ id: PropTypes.string }),
  // Cell Column
  column: PropTypes.number,
  // Cell Row
  row: PropTypes.number,
  onClick: PropTypes.func,
};

ViewMoreCell.defaultProps = {
  events: [],
  spill: undefined,
  column: undefined,
  row: undefined,
  onClick: () => alert("Todo: View More Modal"),
};

export default ViewMoreCell;

import moment from "moment";
import { useCallback } from "react";
import useReportById from "../../../../hooks/useReportById";
import useDescribeRruleFromString from "../../../../hooks/useDescribeRruleFromString";
import useDownloadFile from "../../../../hooks/useDownloadFile";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import useReportRun from "../../../../hooks/useReportRun";
import formatDateRange from "../../../../helpers/Date/formatDateRange";
import getDownloadReportPermissions from "../../../../helpers/Report/getDownloadReportPermissions";
import { REPORT_EXPORT_TYPES } from "../../../../constants";
import { usePropertiesOverview } from "../../../../hooks/properties";
import { useProjectsOverview } from "../../../../hooks/projects";
import { useAssetsOverview } from "../../../../hooks/assets";

const usePastlogData = ({ reportId }) => {
  const { assetsDict } = useAssetsOverview();
  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  const { data: currentUser } = useCurrentUser();
  const { getRruleInHumanReadable } = useDescribeRruleFromString();

  const { isFetching: isFetchingFile, handleDownloadESFile } =
    useDownloadFile();

  const { mutateAsync: runReport, isLoading: isRunningReport } = useReportRun();

  /**
   * Function selector to transform single report into custom object
   */
  const select = useCallback(
    (report) => {
      // Transform `dateRange` to human readable
      const dateRange = formatDateRange(report.dateRange);

      // Transform `repeat` to human readable
      const repeat = getRruleInHumanReadable(report.repeat);

      // Transform `sources` to human readable
      const sources =
        report.sources.map((source) => {
          const associationObj =
            propertiesDict?.[source] ||
            projectDict?.[source] ||
            assetsDict?.[source];

          return {
            title: associationObj?.title || associationObj?.name,
            reference: associationObj?.reference,
          };
        }) ?? [];

      const outputType =
        REPORT_EXPORT_TYPES.find(({ value }) => value === report?.outputType)
          ?.label ?? "Not specified";

      // Transform `scheduler` to human readable
      const scheduler = report.scheduler.map((run) => ({
        ...run,
        date: moment(run.date).format("MM/DD/YYYY • hh:mm a"),
        outputType,
      }));

      // Check if report is template and return its name
      const template = report.isTemplate ? report.title : "N/A";

      return {
        ...report,
        repeat,
        dateRange,
        sources,
        scheduler,
        template,
      };
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [propertiesDict, projectDict, assetsDict]
  );

  const { data: report } = useReportById(reportId, select);

  const canDownload = getDownloadReportPermissions(currentUser, report);

  /**
   * // TODO (Josymar) For now this function is not in use
   * Run Report and download file
   */
  const runAndDownloadReportFile = () => {
    runReport(reportId).then(({ data }) => {
      if (data?.scheduler?.[0]) {
        const fileRef = data.scheduler[0].file;
        handleDownloadESFile(fileRef);
      }
    });
  };

  return {
    report,
    isRunningOrDownloadingReport: isRunningReport || isFetchingFile,
    canDownload,
    handleDownloadESFile,
    runAndDownloadReportFile,
  };
};

export default usePastlogData;

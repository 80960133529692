const useExpandableOptionData = ({
  accordionStates,
  setAccordionStates,
  props,
}) => {
  const { data: currentOption, isSelected, children, innerProps } = props ?? {};

  /**
   * Toggles the accordion state for the current option in the dropdown.
   * If the current option is in the accordion states, it is removed, otherwise it is added
   */
  const toggleAccordion = () => {
    const updatedStates = [...accordionStates];
    const index = updatedStates.findIndex(
      (state) => state.id === currentOption.value.id
    );

    if (index !== -1) {
      updatedStates.splice(index, 1);
    } else {
      updatedStates.push({ id: currentOption.value.id });
    }

    setAccordionStates(updatedStates);
  };

  const handleAccordionClick = (event) => {
    event.stopPropagation();
    toggleAccordion();
  };

  // Overrides the normal onClick behavior for option (react-select) with custom logic
  const customInnerProps = {
    ...innerProps,
    onClick: () => {
      toggleAccordion();
      if (innerProps.onClick) {
        innerProps.onClick();
      }
    },
  };

  return {
    currentOption,
    isSelected,
    children,
    customInnerProps,
    handleAccordionClick,
  };
};

export default useExpandableOptionData;

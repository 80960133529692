import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import Comments from "./Comments";
import NewComment from "./NewComment";
import CrossButton from "../CrossButton/CrossButton";
import PrimaryButton from "../Buttons/PrimaryButton";

const PureCommentSection = ({
  attachIcon,
  onAttachIconClick,
  isMaintenanceComment,
  showNewCommentSection,
  setShowNewCommentSection,
  hideCommentsTag,
  currentUser,
  commentData,
  handlePostComment,
  handlePostReply,
  allowNewComment,
  showClose,
  handleClose,
  canAddComment,
}) => {
  const [hasExistingComments, setHasExistingComments] = useState(false);
  const emptyComment = {
    user: {
      name: `${currentUser?.name?.firstName} ${currentUser?.name?.lastName}`,
      reference: currentUser?.reference,
    },
  };

  const handleCancelComment = useMemo(() => {
    return commentData.length === 0
      ? null
      : () => {
          setShowNewCommentSection(false);
          setHasExistingComments(false);
        };
  }, [commentData.length, setShowNewCommentSection]);

  const handleNewComment = () => {
    setShowNewCommentSection(true);
    setHasExistingComments(true);
  };
  const handleAddComment = (val) => {
    handlePostComment(val);
    setShowNewCommentSection(false);
    setHasExistingComments(false);
  };
  return (
    <div className="">
      <div className="flex flex-row justify-between">
        {!hideCommentsTag && (
          <h2 className="font-semibold text-gray-500 text-lg">Comments</h2>
        )}
        {showClose && <CrossButton className="w-5" onClick={handleClose} />}
      </div>
      {commentData.map((info, index) => {
        return (
          <div key={info.reference}>
            <Comments
              data={info}
              index={index}
              user={emptyComment?.user}
              handlePostReply={handlePostReply}
              allowNewComment={allowNewComment}
              isMaintenanceComment={isMaintenanceComment}
              canAddComment={canAddComment}
            />
          </div>
        );
      })}

      {canAddComment &&
        allowNewComment &&
        (commentData.length === 0 || hasExistingComments) && (
          <NewComment
            attachIcon={attachIcon}
            onAttachIconClick={onAttachIconClick}
            user={emptyComment?.user}
            handleAddComment={handleAddComment}
            handleCancelComment={handleCancelComment}
            hideCancel
            isMaintenanceComment
          />
        )}

      {canAddComment &&
        commentData.length > 0 &&
        allowNewComment &&
        !showNewCommentSection && (
          <div className="ml-4 pt-4">
            {isMaintenanceComment && (
              <PrimaryButton
                resourceName="Comment"
                addButton
                className="text-brandGreen"
                innerClassName="mx-0 font-normal"
                onClick={handleNewComment}
              />
            )}
            {!isMaintenanceComment && (
              <PrimaryButton
                resourceName="Comment"
                addButton
                className="whitespace-nowrap border mb-4 border-white"
                onClick={handleNewComment}
              />
            )}
          </div>
        )}
    </div>
  );
};
PureCommentSection.propTypes = {
  /**
   * current user data
   */
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    reference: PropTypes.string,
    resource: PropTypes.string,
  }),
  /**
   * data displayed in the comments
   */
  commentData: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string,
      content: PropTypes.string,
      id: PropTypes.string,
      metadata: PropTypes.shape({
        createdAt: PropTypes.string,
      }),
      replies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          metadata: PropTypes.shape({
            createdAt: PropTypes.string,
          }),
          userData: PropTypes.shape({
            name: PropTypes.shape({
              firstName: PropTypes.string,
              lastName: PropTypes.string,
            }),
          }),
        })
      ),
      resource: PropTypes.string,
      userData: PropTypes.shape({
        name: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      }),
    })
  ),
  /**
   * function called when a comment is posted
   */
  handlePostComment: PropTypes.func,
  /**
   * function called when a reply is posted
   */
  handlePostReply: PropTypes.func,
  /**
   * boolean to allow users to add comments or replies
   */
  allowNewComment: PropTypes.bool,
  showClose: PropTypes.bool,
  handleClose: PropTypes.func,
  attachIcon: PropTypes.string,
  onAttachIconClick: PropTypes.func,
  isMaintenanceComment: PropTypes.bool,
  hideCommentsTag: PropTypes.bool,
  showNewCommentSection: PropTypes.bool,
  setShowNewCommentSection: PropTypes.func,
  canAddComment: PropTypes.bool,
};

PureCommentSection.defaultProps = {
  currentUser: {},
  commentData: [],
  handlePostComment: undefined,
  handlePostReply: undefined,
  allowNewComment: undefined,
  showClose: undefined,
  handleClose: undefined,
  attachIcon: undefined,
  onAttachIconClick: undefined,
  isMaintenanceComment: false,
  hideCommentsTag: false,
  showNewCommentSection: false,
  setShowNewCommentSection: false,
  canAddComment: false,
};
export default PureCommentSection;

import { useEffect, useState } from "react";
import { useAppState } from "../../../../../state/appState";

const useEmployeeNameStatusData = () => {
  const [{ userDict, pageState }] = useAppState();
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const userId = pageState?.timesheet?.userId;
    const user = userDict?.[`User/${userId}`];

    if (!user) return;

    setUserName(`${user.name.firstName} ${user.name.lastName}`);
  }, [userDict, pageState?.timesheet?.userId]);

  return {
    userName,
  };
};

export default useEmployeeNameStatusData;

import React from "react";
import "./styles.css";

const MessageDivisionElement = (value) => {
  return (
    <div
      id="division-value"
      key={`messaging-division-${value}`}
      className="division flex w-full relative justify-center py-2"
    >
      <span
        className="bg-white px-2 py-1 text-xxs rounded-full border text-gray-450"
        style={{ borderColor: "#cacaca", zIndex: "32" }}
      >
        {value}
      </span>
    </div>
  );
};

export default MessageDivisionElement;

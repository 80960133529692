import { TagAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { useCallback, useEffect } from "react";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { tagKeys } from "../config/reactQuery/queryKeyFactory";
import { toastError } from "../stories/Components/Toast/Toast";

const getTags = async () => {
  const { data } = await TagAPI.get();
  const tags = data?.entries?.map(({ resource }) => resource);

  const tagsDict = tags.reduce((acc, v) => ({ ...acc, [v.reference]: v }), {});

  return {
    tags,
    tagsDict,
  };
};
/**
 * Query hook to fetch Tags
 * @returns query hook
 */
export const useGetTags = () => {
  return useAuthenticatedQuery({
    queryKey: tagKeys.tags,
    queryFn: () => getTags(),
    onError: (error) => {
      console.error("Error fetching Tags", error);
    },
  });
};

const postTag = async (tag) => {
  const { data } = await TagAPI.post(tag);
  return data;
};

/**
 * Query mutation hook to create Tags
 * @returns mutation hook
 */
export const useCreateTag = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postTag,
    onSuccess: (data) => {
      queryClient.setQueryData(tagKeys.tags, (current) => ({
        tags: [data, ...current?.tags],
        tagsDict: {
          ...(current?.tagsDict || {}),
          [`${data?.reference}`]: data,
        },
      }));
    },
    onError: (error, data) => {
      console.error("Error creating tag", error);
      toastError(`Error creating tag ${data?.label}`);
    },
    onSettled: () => {
      queryClient.invalidateQueries(tagKeys.tags);
    },
  });
};

const deleteTags = async (tagRefs) => {
  await Promise.all(tagRefs?.map((ref) => TagAPI.delete(ref?.split("/")[1])));

  return tagRefs;
};

/**
 * Mutation hook to remove single/multiple Tags
 * @returns mutation hook
 */
export const useRemoveTags = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteTags,
    onSuccess: (tagRefs) => {
      queryClient.setQueryData(tagKeys.tags, (current) => {
        const newTagsData = current.tags?.filter(
          (tag) => !tagRefs?.includes(tag?.reference)
        );

        const updatedDict = newTagsData.reduce(
          (acc, v) => ({ ...acc, [v.reference]: v }),
          {}
        );
        return {
          tags: newTagsData,
          tagsDict: updatedDict,
        };
      });
    },
    onError: (error) => {
      console.error("Error deleting Tag(s)", error);
      toastError(`Error deleting selected tag(s)`);
    },
    onSettled: () => {
      queryClient.invalidateQueries(tagKeys.tags);
    },
  });
};

const patchTag = async ({ id, prevTag, updatedTag }) => {
  const { data } = await TagAPI.patch(id, updatedTag, prevTag);

  return data;
};

/**
 * Mutation hook to remove single/multiple Tags
 * @returns mutation hook
 */
export const useUpdateTag = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchTag,
    onSuccess: (updatedTag) => {
      queryClient.setQueryData(tagKeys.tags, (current) => {
        const newTagsData = current?.tags?.map((tag) => {
          if (updatedTag.reference === tag?.reference) {
            return updatedTag;
          }
          return tag;
        });
        const updatedDict = current?.tagsDict;
        updatedDict[`${updatedTag.reference}`] = updatedTag;
        return {
          tags: newTagsData,
          tagsDict: updatedDict,
        };
      });
    },
    onError: (error, data) => {
      console.error("Error updating Tag", error);
      toastError(`Error updating tag: ${data?.label}`);
    },
  });
};

/**
 * Hook to prefetch Tags
 */
export const useTagsPrefetch = () => {
  const queryClient = useQueryClient();

  const prefetchTags = useCallback(async () => {
    await queryClient.prefetchQuery(tagKeys.tags, getTags);
  }, [queryClient]);

  useEffect(() => {
    prefetchTags();
  }, [prefetchTags]);
};

import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import redExclamationIcon from "../../assets/images/redExclamationIcon.svg";
import {
  ADVANCED_WF_STEP,
  RESUBMISSION_WF_STEP,
  UNRESOLVED_WF_STEP,
} from "../../../constants";
import WorkflowCardResubmission from "./WorkflowCardResubmission";
// commented out for later use, no code was to be removed from workflows
/* import WorkflowCardPending from "./WorkflowCardPending"; */

const ActiveCardBody = ({ data }) => {
  const getStepName = (multiple, parentIndex, index, name) => {
    const stepName = multiple
      ? `${parentIndex + 1}${String.fromCharCode(97 + index)}. ${name}`
      : `${parentIndex + 1}. ${name}`;
    return stepName;
  };

  const handleGetAssocName = () => {
    const project = data?.project;
    if (project.toLowerCase().includes("not specified")) {
      const property = data?.property;
      if (property.toLowerCase().includes("not specified")) {
        return "No Associated Resource.";
      }
      return `Property - ${data?.property}`;
    }
    return `Project - ${data?.project}`;
  };
  const statusName = {
    inProgress: "Pending review",
    unresolved: "Pending resolution",
    resubmissionRequested: "Resubmission requested",
  };
  const statusWarning =
    data.status === RESUBMISSION_WF_STEP || data.status === UNRESOLVED_WF_STEP;
  const currentIndex = 0;
  if (data.status === UNRESOLVED_WF_STEP) {
    return (
      <h3 className="text-sm">{`All ${
        data?.steps?.length > 1 && data?.steps?.length
      } steps have been advanced`}</h3>
    );
  }

  const currentStep = data.steps[data.currentStep];
  const getResubmissions = (step, parallelIndex) => {
    const stepName = getStepName(
      currentStep?.parallelSteps?.length > 0,
      currentIndex,
      parallelIndex || 0,
      currentStep?.name
    );
    if (step?.needOnlyOneAdvancer) {
      const advancers = step?.members?.filter(
        (member) => member?.status === ADVANCED_WF_STEP
      );
      if (advancers?.length === 0) {
        // check resubmission issue
        const rejectors = step?.members?.filter(
          (member) => member?.status === RESUBMISSION_WF_STEP
        );
        if (rejectors?.length > 0) {
          return { members: rejectors, stepName };
        }
      }
    } else {
      const allAdvanced = step?.members?.every(
        (member) => member.status === ADVANCED_WF_STEP
      );
      if (!allAdvanced) {
        // check resubmission issue
        const rejectors = step?.members?.filter(
          (member) => member.status === RESUBMISSION_WF_STEP
        );
        if (rejectors?.length > 0) {
          return { members: rejectors, stepName };
        }
      }
    }
    return [];
  };
  if (data.status === RESUBMISSION_WF_STEP) {
    const stepIssues = [];
    const mainStepIssue = getResubmissions(currentStep, 0);
    if (mainStepIssue?.members?.length > 0) {
      stepIssues.push(mainStepIssue);
    }
    for (let j = 0; j < currentStep?.parallelSteps?.length; j += 1) {
      const parallelStep = currentStep?.parallelSteps[j];
      const parIssue = getResubmissions(parallelStep, j + 1);
      if (parIssue?.members?.length > 0) {
        stepIssues.push(parIssue);
      }
    }
    return (
      <>
        <div className="flex flex-row items-start overflow-hidden">
          <h2 className="mb-2">{statusName[data.status]}</h2>
          {statusWarning && (
            <img
              src={redExclamationIcon}
              alt="warning"
              className="mt-0.5 ml-2"
            />
          )}
        </div>
        <WorkflowCardResubmission data={stepIssues} />
      </>
    );
  }
  const checkPendingReviews = (step, parallelIndex) => {
    const stepName = getStepName(
      currentStep?.parallelSteps?.length > 0,
      currentIndex,
      parallelIndex || 0,
      currentStep?.name
    );
    if (step?.needOnlyOneAdvancer) {
      const advancers = step?.members?.filter(
        (member) => member.status === ADVANCED_WF_STEP
      );
      if (advancers?.length === 0) {
        // check if review is overdue
        if (
          moment(step?.dueDate).startOf("day").isBefore(moment().startOf("day"))
        ) {
          const overDueMembers = step?.members?.filter(
            (member) => !member.status
          );
          return { members: overDueMembers, stepName };
        }
      }
    } else {
      const allAdvanced = step?.members?.every(
        (member) => member.status === ADVANCED_WF_STEP
      );
      if (!allAdvanced) {
        // check resubmission issue
        if (
          moment(step?.dueDate).startOf("day").isBefore(moment().startOf("day"))
        ) {
          const overDueMembers = step?.members?.filter(
            (member) => !member.status
          );
          return { members: overDueMembers, stepName };
        }
      }
    }
    return [];
  };
  const stepOverdue = [];
  const mainStepOverdue = checkPendingReviews(currentStep, 0);
  if (mainStepOverdue?.members?.length > 0) {
    stepOverdue.push(mainStepOverdue);
  }
  for (let j = 0; j < currentStep?.parallelSteps?.length; j += 1) {
    const parallelStep = currentStep?.parallelSteps[j];
    const parIssue = checkPendingReviews(parallelStep, j + 1);
    if (parIssue?.members?.length > 0) {
      stepOverdue.push(parIssue);
    }
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-row">
          <h2 className="mb-2">{handleGetAssocName()}</h2>
          {statusWarning && (
            <img
              src={redExclamationIcon}
              alt="warning"
              className="mt-0.5 ml-2"
            />
          )}
        </div>
        <h2>Due: {data?.endDate ? data.endDate : "No Due Date"}</h2>
      </div>
      {/* commented out for later use, no code was to be removed from workflows */}
      {/* <WorkflowCardPending data={stepOverdue} /> */}
    </>
  );
};

ActiveCardBody.propTypes = {
  /**
   * data displayed on cards
   */
  data: PropTypes.shape({
    metadata: PropTypes.shape({
      lastUpdated: PropTypes.string,
      createdAt: PropTypes.string,
      userData: PropTypes.shape({
        name: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      }),
    }),
    relationships: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      children: PropTypes.arrayOf(PropTypes.any),
    }),
    resource: PropTypes.string,
    id: PropTypes.string,
    reference: PropTypes.string,
    version: PropTypes.number,
    isDraft: PropTypes.bool,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      })
    ),
    status: PropTypes.string,
    project: PropTypes.string,
    property: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    documents: PropTypes.arrayOf(PropTypes.any),
    dateCreated: PropTypes.string,
    startDate: PropTypes.string,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        duration: PropTypes.number,
        needOnlyOneAdvancer: PropTypes.bool,
        position: PropTypes.number,
        // eslint-disable-next-line react/forbid-prop-types
        documents: PropTypes.arrayOf(PropTypes.any),
        id: PropTypes.string,
        members: PropTypes.arrayOf(
          PropTypes.shape({
            _id: PropTypes.string,
            reference: PropTypes.string,
            status: PropTypes.string,
            date: PropTypes.string,
            userData: PropTypes.shape({
              name: PropTypes.shape({
                firstName: PropTypes.string,
                lastName: PropTypes.string,
              }),
            }),
          })
        ),
        // eslint-disable-next-line react/forbid-prop-types
        parallelSteps: PropTypes.arrayOf(PropTypes.any),
        name: PropTypes.string,
        description: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types
        comments: PropTypes.arrayOf(PropTypes.any),
        dueDate: PropTypes.string,
      })
    ),
    // eslint-disable-next-line react/forbid-prop-types
    associatedWorkflows: PropTypes.arrayOf(PropTypes.any),
    name: PropTypes.string,
    cost: PropTypes.string,
    endDate: PropTypes.string,
    currentStep: PropTypes.number,
    dueDate: PropTypes.string,
    isFlagged: PropTypes.bool,
    initiationDate: PropTypes.string,
    initiatorName: PropTypes.string,
    resolved: PropTypes.bool,
    totalStep: PropTypes.number,
  }),
};

ActiveCardBody.defaultProps = {
  data: [],
};
export default ActiveCardBody;

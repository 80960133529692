import React from "react";
import useSubmittalFiles from "../../../../hooks/useSubmittalFiles";
import DisplayFileNames from "../../../../Pages/Overviews/Sop/DisplayFileNames";
import IconButton from "../../Buttons/IconButton";
import collapseIcon from "../../../assets/images/collapseIcon.svg";

export default function ReuqestWorkflowShortAttachments({
  handleFileClick,
  filesToUpload,
  removeAttachedFile,
  stepAttachments,
  activeStep,
  handleRemoveFile,
}) {
  const resolved = useSubmittalFiles(stepAttachments);
  return (
    <div className="flex flex-col items-start justify-center w-full col-span-3">
      {resolved.length > 0 &&
        resolved.map((attachment) => {
          return (
            <div className="flex items-center" key={attachment?.id}>
              <button
                type="button"
                onClick={() => handleFileClick(attachment)}
                aria-label="remove attachment"
              >
                <DisplayFileNames
                  className="text-xs"
                  filesToUpload={[attachment]}
                  customMargin="ml-0 my-2"
                  hideDeleteIcon
                />
              </button>
              {activeStep && (
                <IconButton
                  icon={collapseIcon}
                  className="ml-4"
                  onClick={() => handleRemoveFile(attachment)}
                />
              )}
            </div>
          );
        })}
      <DisplayFileNames
        filesToUpload={filesToUpload}
        removeAttachedFile={removeAttachedFile}
        customMargin="ml-0 my-2"
        className="text-xs"
      />
    </div>
  );
}

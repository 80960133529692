import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import Chevron from "../Chevron/Chevron";
import videoIcon from "../../assets/images/videoIcon.svg";
import attachmentIcon from "../../assets/images/attachment_icon_updated.svg";
import "../../../../node_modules/video-react/dist/video-react.css";
import useMediaViewData from "./useMediaViewData";
import { BRAND_GREY } from "../../../constants";
import CrossButton from "../CrossButton/CrossButton";
import crossBlack from "../../assets/images/blackCrossIcon.svg";

const thumbnailButtonCN = (isSelected) => cntl`
  mx-2
  p-2
  mb-1
  flex
  flex-none
  items-center
  justify-center
  ${isSelected && cntl`border border-brandGreen`}
`;

const MediaViewContent = ({ files, initialIndex, onEdit, onRemove, close }) => {
  const {
    onPreviousFile,
    onNextFile,
    onThumbnailClick,
    isVideoType,
    downloadAttachment,
    getVideoType,
    fileIndex,
  } = useMediaViewData({ files, initialIndex, onEdit, onRemove });

  return (
    <div className="relative flex py-5 px-5 h-full bg-white">
      {close && (
        <CrossButton
          icon={crossBlack}
          className="absolute right-0 top-0 p-4"
          onClick={() => close()}
        />
      )}
      <button
        type="button"
        onClick={onPreviousFile}
        aria-label="previous button"
      >
        <Chevron className="transform rotate-180 w-7" color={BRAND_GREY} />
      </button>
      <div className="flex flex-col items-center flex-1 min-w-0 px-2">
        {isVideoType(files[fileIndex]) ? (
          <div className="flex justify-center items-center flex-1">
            <video
              className="rounded-lg mt-2 cursor-pointer"
              style={{ maxHeight: "400px", maxWidth: "100%" }}
              autoPlay={false}
              preload="metadata"
              controls
            >
              <track kind="captions" />
              <source
                src={files[fileIndex].url}
                type={
                  files[fileIndex].type === "video/quicktime"
                    ? "video/mp4"
                    : getVideoType(files[fileIndex])
                }
              />
              Your browser does not support the video tag.
            </video>
          </div>
        ) : (
          <>
            {!files[fileIndex]?.type?.includes("image") ? (
              <button
                className="truncate text-xs hover:underline flex-1 flex flex-col items-center justify-center"
                type="button"
                onClick={() => downloadAttachment(files[fileIndex])}
              >
                <img
                  className="object-scale-down w-10 h-10"
                  src={attachmentIcon}
                  alt=""
                />
                {files[fileIndex]?.title}
              </button>
            ) : (
              <img
                className="object-scale-down flex-1 min-h-0"
                src={files[fileIndex]?.url}
                alt=""
              />
            )}
          </>
        )}
        <p className="my-5">{`${fileIndex + 1}/${files.length}`}</p>
        <div className="flex w-full">
          <div className="overflow-x-scroll flex flex-1">
            {files?.map((f, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`media-viewer-file-${index}`}
                role="button"
                className={thumbnailButtonCN(index === fileIndex)}
                onClick={() => onThumbnailClick(index)}
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onThumbnailClick(index);
                  }
                }}
              >
                {isVideoType(f) ? (
                  <img
                    className="object-scale-down w-10 h-10"
                    src={videoIcon}
                    alt=""
                  />
                ) : (
                  <img
                    className="object-scale-down w-10 h-10"
                    src={f?.type?.includes("image") ? f?.url : attachmentIcon}
                    alt=""
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <button
        aria-label="next button"
        type="button"
        onClick={onNextFile}
        data-testid="MediaModalRightUT"
      >
        <Chevron className="w-7" color={BRAND_GREY} />
      </button>
    </div>
  );
};

MediaViewContent.propTypes = {
  /**
   * array of files to display
   */
  files: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      type: PropTypes.string,
      url: PropTypes.string,
      originalFile: PropTypes.shape({
        type: PropTypes.string,
      }),
    })
  ),
  /**
   * index to be shown on open, resets fileIndex state when it changes
   */
  initialIndex: PropTypes.number,
  /**
   * function called when an edit is requested
   */
  onEdit: PropTypes.func,
  /**
   * function called when remove is requested
   */
  onRemove: PropTypes.func,
  /**
   * Close component viewer
   */
  close: PropTypes.func,
};

MediaViewContent.defaultProps = {
  files: [],
  initialIndex: 0,
  onEdit: undefined,
  onRemove: undefined,
  close: undefined,
};

export default MediaViewContent;

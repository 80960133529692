import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../../Checkbox/Checkbox";
import { defaultCsvColumns } from "../../../../constants";
import PrimaryButton from "../../Buttons/PrimaryButton";
import ModalWrapper from "../ModalWrapper";
import clearIcon from "../../../assets/images/reloadIconGreen.svg";
import usePayrollCsvModalData from "./usePayrollCsvModalData";

const PayrollCsvModal = ({ modalData }) => {
  const {
    selectedColumns,
    allItemsSelected,
    isChecked,
    handleClearAll,
    handleRadioToggle,
    handleSelectAll,
  } = usePayrollCsvModalData();

  return (
    <ModalWrapper
      width="702px"
      modalData={modalData}
      resourceName="Edit Columns"
      alert
      buttons={
        <div className="flex gap-4 w-full">
          <PrimaryButton
            saveButton
            disabled={selectedColumns.length === 0}
            onClick={() => modalData.onClick(selectedColumns)}
          />
        </div>
      }
    >
      <div className="grid grid-cols-4 border py-6 px-8 rounded-lg">
        <p className="text-xs col-span-1 font-semibold text-primaryGreen">
          Include
          <br />
          Information
        </p>
        <div className="flex flex-col gap-4 col-span-3">
          <div className="grid grid-cols-2 pb-4 border-b gap-4">
            <Checkbox
              isCalendarCheckbox
              size="5"
              checked={allItemsSelected}
              label="Select All"
              labelClassName="text-sm text-gray-300 font-normal"
              inputClassName="calendar-form-tick"
              onChange={handleSelectAll}
            />
            <button
              className="flex items-center gap-2 font-semibold text-primaryGreen"
              onClick={handleClearAll}
              type="button"
            >
              <img src={clearIcon} alt="clear-cols" className="inline-block" />
              Clear
            </button>
          </div>
          <div className="grid grid-cols-2 gap-4">
            {defaultCsvColumns.map((col) => (
              <Checkbox
                key={col}
                isCalendarCheckbox
                size="5"
                checked={isChecked(col)}
                label={col}
                className="gap-2"
                labelClassName="text-sm text-gray-300 font-normal"
                inputClassName="calendar-form-tick col-span-1"
                onChange={handleRadioToggle(col)}
              />
            ))}
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

PayrollCsvModal.propTypes = {
  modalData: PropTypes.shape({
    onClick: PropTypes.func,
  }),
};

PayrollCsvModal.defaultProps = {
  modalData: undefined,
};

export default PayrollCsvModal;

import React, { useState } from "react";
import PropTypes from "prop-types";
import Avatar from "../Message/Avatar";
import deleteIcon from "../../assets/images/collapseIcon.svg";
import { useAppState } from "../../../state/appState";
import { inputWidth } from "../../../helpers/StyleFunctions";

const UserList = ({ userList, handleRemove }) => {
  const [{ userDict }] = useAppState();
  const [hovering, setHovering] = useState(false);

  const handleDeleteHover = (mouseOver, id) => {
    if (mouseOver) {
      setHovering(id);
    } else {
      setHovering(false);
    }
  };
  return (
    <>
      {!!userList?.length && (
        <div className="flex flex-col w-full mt-4">
          {userList?.map((usr) => {
            const user = userDict?.[usr?.value];
            return (
              <div
                key={usr?.value}
                className="flex justify-between items-center mb-2"
                style={{ width: inputWidth() }}
              >
                <div
                  className="flex w-full items-center justify-between cursor-pointer"
                  onMouseOver={() => handleDeleteHover(true, usr?.value)}
                  onMouseOut={() => handleDeleteHover(false)}
                  onFocus={() => handleDeleteHover(true, usr?.value)}
                  onBlur={() => handleDeleteHover(false)}
                >
                  <div className="flex items-center">
                    <Avatar
                      className="w-6 h-6 mr-2"
                      fontClassName="text-xxs"
                      isExternalUser
                      avatar={user?.avatar}
                      id={user?.id}
                      name={user?.name}
                      kind={user ? user?.kind : usr?.kind}
                    />

                    <p className="text-xxs text-gray-200 font-medium text-ellipsis">
                      {usr?.firstName &&
                        usr?.lastName &&
                        `${usr?.firstName[0].toUpperCase()}` +
                          `${usr?.firstName.substring(1)}` +
                          (` ${usr?.lastName[0].toUpperCase()}` +
                            `${usr?.lastName.substring(1)}`)}
                      {!(usr?.firstName && usr?.lastName) && usr?.label}
                    </p>
                  </div>
                  <button
                    className={`${
                      hovering === usr?.value ? "" : "invisible"
                    } mr-2`}
                    type="button"
                    onClick={() => handleRemove(usr?.value)}
                  >
                    <img
                      className="w-5 cursor-pointer"
                      src={deleteIcon}
                      alt="delete user"
                    />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

UserList.propTypes = {
  userList: PropTypes.arrayOf(PropTypes.shape({})),
  handleRemove: PropTypes.func,
};

UserList.defaultProps = {
  userList: [],
  handleRemove: () => {},
};

export default UserList;

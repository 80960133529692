import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { TimeOptions } from "../../../constants";
import Checkbox from "../Checkbox/Checkbox";
import Dropdown from "../Dropdown/Dropdown";

const HoursOfOperationSection = ({
  systemSettings,
  dispatchSystemSettings,
}) => {
  const operationChangeHandler = useCallback(
    (key, field, value) => {
      const currentHours = { ...systemSettings?.hoursOfOperation };
      const newItem = currentHours[key] ?? {};
      newItem[field] = value;
      if (field === "active") {
        newItem.from = "09:00";
        newItem.to = "17:00";
      }
      currentHours[key] = newItem;
      dispatchSystemSettings({
        type: "changeHoursOfOperation",
        value: currentHours,
      });
    },
    [dispatchSystemSettings, systemSettings?.hoursOfOperation]
  );

  return (
    <div className="flex flex-col col-span-4 py-4">
      {Object.keys(systemSettings?.hoursOfOperation ?? {}).map((day, index) => (
        <div key={day} className="grid grid-cols-9 gap-10 mb-2">
          <Checkbox
            className={`col-span-2 ${index === 0 ? "mt-7" : "mt-1"}`}
            label={`${day[0].toUpperCase()}${day.substring(1)}`}
            checked={systemSettings.hoursOfOperation[day]?.active}
            onChange={(checked) =>
              operationChangeHandler(day, "active", checked)
            }
          />

          <>
            <div className="flex col-span-2">
              <Dropdown
                label={index === 0 ? "From" : ""}
                options={TimeOptions}
                value={TimeOptions.find(
                  (item) =>
                    item.value === systemSettings?.hoursOfOperation[day]?.from
                )}
                placeholder="Select"
                onChange={(val) =>
                  operationChangeHandler(day, "from", val.value)
                }
                disableClear
                disableSearch
                disableSort
                isDisabled={!systemSettings.hoursOfOperation[day]?.active}
              />
              <div
                className={`relative left-0 z-10 w-0 h-px ${
                  index === 0 ? "top-10" : "top-3"
                }`}
              >
                <div className="flex w-8 items-center">
                  <div className="w-5 h-px bg-gray-200" />
                  <div
                    className={`w-3 h-3 rounded-full ${
                      systemSettings?.hoursOfOperation[day]?.from
                        ? "bg-brandGreen"
                        : "bg-gray-200"
                    }`}
                  />
                </div>
              </div>
            </div>
            <Dropdown
              className="col-span-2"
              label={index === 0 ? "To" : ""}
              options={TimeOptions}
              value={TimeOptions.find(
                (item) =>
                  item.value === systemSettings?.hoursOfOperation[day]?.to
              )}
              placeholder="Select"
              onChange={(val) => operationChangeHandler(day, "to", val.value)}
              disableClear
              disableSearch
              disableSort
              isDisabled={!systemSettings.hoursOfOperation[day]?.active}
            />
          </>
        </div>
      ))}
    </div>
  );
};

HoursOfOperationSection.propTypes = {
  systemSettings: PropTypes.shape({
    hoursOfOperation: PropTypes.shape({
      sunday: PropTypes.shape({
        active: PropTypes.bool,
        from: PropTypes.string,
        to: PropTypes.string,
      }),
    }),
  }),
  dispatchSystemSettings: PropTypes.func,
};

HoursOfOperationSection.defaultProps = {
  systemSettings: undefined,
  dispatchSystemSettings: undefined,
};

export default HoursOfOperationSection;

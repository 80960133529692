import Api from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { cloneDeep } from "lodash";
import { userKeys } from "../config/reactQuery/queryKeyFactory";
import { toastMessage, toastError } from "../stories/Components/Toast/Toast";

const useUserDelete = (mutationKey) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey,
    mutationFn: (data) => {
      return Api.post("/api/User/$bulkdelete", {
        users: data,
      });
    },
    onMutate: async (data) => {
      const previousAllUsers = queryClient.getQueriesData(userKeys.users);

      const copyOfCache = cloneDeep(queryClient.getQueryData(userKeys.users));

      // Delete in Backend only soft deletes by secting active status to false
      data?.forEach((ref) => {
        delete copyOfCache.userDict[ref];
      });

      // use updated users dict from copy of cach to update others
      // userDict{}, companies[], employees[], users[]
      copyOfCache.users = Object.values(copyOfCache.userDict);
      copyOfCache.employees = copyOfCache?.users?.filter(
        (item) => item.isEmployee
      );
      copyOfCache.companies = copyOfCache?.users?.filter(
        (item) => item.kind === "company"
      );

      // set updated query data
      queryClient.setQueryData(userKeys.users, copyOfCache);

      return { previousAllUsers, data };
    },
    onSuccess: ({ data }) => {
      if (data?.entries?.length === 1) {
        toastMessage("User was removed successfully");
      }
      toastMessage("Users were removed successfully");
    },
    onError: (error, ids, context) => {
      const { previousAllUsers } = context ?? {};
      previousAllUsers?.forEach(([key, value]) => {
        queryClient.setQueryData(key, value);
      });

      toastError("Unable to remove user");
      console.error("useUserDelete", error);
    },
    onSettled: () => {
      queryClient.invalidateQueries(userKeys.users);
    },
  });
};

export default useUserDelete;

import React from "react";
import PropTypes from "prop-types";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { formatPercentage } from "../../../helpers/Formatters";
import warningIcon from "../../assets/images/yellowExclamationIcon.svg";
import errorIcon from "../../assets/images/redExclamationIcon.svg";
import "react-circular-progressbar/dist/styles.css";

const CircularProgressBar = ({ value, hideIcons }) => {
  return (
    <div className="flex my-0 text-sm">
      <div className="mr-2 w-6 h-6">
        {value > 10 && value <= 100 && (
          <CircularProgressbar
            value={value}
            styles={buildStyles({
              pathColor: "#636363",
            })}
            strokeWidth={10}
          />
        )}
        {!hideIcons && value >= 95 && value < 100 && (
          <img width="80" src={warningIcon} alt="warningIcon" />
        )}
        {!hideIcons && value >= 100 && (
          <img width="80" src={errorIcon} alt="errorIcon" />
        )}
      </div>
      <p>{formatPercentage(value)}</p>
    </div>
  );
};

CircularProgressBar.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hideIcons: PropTypes.bool,
};

CircularProgressBar.defaultProps = {
  value: 0,
  hideIcons: false,
};

export default CircularProgressBar;

/* eslint-disable react/no-array-index-key */
import React, { Fragment } from "react";
import cntl from "cntl";

const retainageGroupingCN = (i) => cntl`
absolute 
-left-5 
border-gray-150 
border-l-2 
border-b-2
${i === 1 && "border-t-2"}
w-3 
h-20
`;

const stepGroupingBallCN = cntl`
absolute
h-4 
w-4 
-left-3
rounded-full
z-10
bg-brandGreen
`;

const GroupingElement = ({ retainage }) =>
  retainage?.length > 1 &&
  retainage.map((_, i) => {
    const key = `grouping-element-${i}`;
    if (i > 0) {
      if (i === 1) {
        return (
          <Fragment key={key}>
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              className={retainageGroupingCN(i)}
              style={{ top: "2.5rem" }}
            />
            {retainage.length === 2 && (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className={stepGroupingBallCN}
                style={{ top: `${2.5 * i + (i - 1) * 2.5 + 4.5}rem` }}
              />
            )}
          </Fragment>
        );
      }
      if (i === retainage.length - 1) {
        return (
          <Fragment key={`grouping-element-${i}`}>
            <div
              className={retainageGroupingCN(i)}
              style={{ top: `${2.5 * i + (i - 1) * 2.5}rem` }}
            />
            <div
              className={stepGroupingBallCN}
              style={{ top: `${2.5 * i + (i - 1) * 2.5 + 4.5}rem` }}
            />
          </Fragment>
        );
      }
      return (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={retainageGroupingCN(i)}
          style={{ top: `${2.5 * i + (i - 1) * 2.5}rem` }}
        />
      );
    }

    return <Fragment key={`grouping-element-${i}`} />;
  });

export default GroupingElement;

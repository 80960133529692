import React from "react";
import PropTypes from "prop-types";
import DropdownWithPills from "../DropdownWithPills";
import useAssetCategoriesDropdownData from "./useAssetCategoriesDropdownData";

const AssetCategoriesDropdown = ({
  value,
  setValue,
  menuPlacement,
  isMulti,
  isDisabled,
}) => {
  const { categories, subcategories, dropdownValue } =
    useAssetCategoriesDropdownData(value);

  return (
    <DropdownWithPills
      options={categories}
      subOptions={subcategories}
      isMulti={isMulti}
      isDisabled={isDisabled}
      value={dropdownValue}
      setValue={setValue}
      menuPlacement={menuPlacement}
    />
  );
};

AssetCategoriesDropdown.defaultProps = {
  value: undefined,
  isMulti: true,
  isDisabled: false,
  menuPlacement: "top",
  setValue: () => {},
};

AssetCategoriesDropdown.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({})),
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  menuPlacement: PropTypes.oneOf(["top", "bottom", "auto"]),
  setValue: PropTypes.func,
};

export default AssetCategoriesDropdown;

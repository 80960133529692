import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import cntl from "cntl";
import Tab from "../Tab/Tab";

const containerCN = (className) => cntl`
  flex
  flex-col
  flex-1
  relative
  ${className}
`;

const tabsContainerCN = (indentTabs) => cntl`
  flex
  min-w-0
  self-end
  ${indentTabs ? "pl-7" : ""}
`;

const contentContainerCN = (contentClassName, workFlows, noPadding) => cntl`
  flex-1
  ${workFlows || noPadding ? "" : "py-5"}
  ${contentClassName}
`;

const TabbedContainer = ({
  className,
  contentClassName,
  tabs,
  titleClassName,
  labelIndentWidth,
  activeIndex,
  onTabClick,
  indentTabs,
  workflows,
  noPadding,
}) => {
  return (
    <div className={containerCN(className)}>
      <div
        className={contentContainerCN(contentClassName, workflows, noPadding)}
      >
        {tabs
          .filter((tab) => !tab.isHidden)
          [activeIndex]?.content(
            <div className={tabsContainerCN(indentTabs)}>
              {labelIndentWidth && (
                <div
                  className={`mt-auto bg-white borderHider ${labelIndentWidth}`}
                />
              )}
              {tabs
                .filter((tab) => !tab.isHidden)
                .map(({ title, pill, large }, index) => (
                  <Tab
                    key={uuidv4()}
                    title={title}
                    pill={pill}
                    isSelected={activeIndex === index}
                    titleClassName={titleClassName}
                    largeFont={large}
                    onClick={() => onTabClick(index)}
                  />
                ))}
            </div>
          )}
      </div>
    </div>
  );
};

TabbedContainer.propTypes = {
  /**
   * classNames applied to the container
   */
  className: PropTypes.string,
  /**
   * classNames applied to the content container
   */
  contentClassName: PropTypes.string,
  /**
   * array of strings to display as tabs
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.element,
      ]),
      content: PropTypes.element,
    })
  ),
  /**
   * className for tab titles
   */
  titleClassName: PropTypes.string,
  /**
   * className for indenting tab names
   */
  labelIndentWidth: PropTypes.string,
  /**
   * the index of the tab to apply the isSelected state to
   */
  activeIndex: PropTypes.number,
  /**
   * function called when a tab is clicked
   */
  onTabClick: PropTypes.func,

  /**
   * Whether the tabs should be indented (e.g. to allow space for a draggable icon)
   */
  indentTabs: PropTypes.bool,
  workflows: PropTypes.bool,
  noPadding: PropTypes.bool,
};

TabbedContainer.defaultProps = {
  className: null,
  contentClassName: null,
  tabs: [],
  titleClassName: null,
  labelIndentWidth: null,
  activeIndex: 0,
  onTabClick: null,
  indentTabs: false,
  workflows: false,
  noPadding: false,
};

export default TabbedContainer;

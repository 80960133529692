import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import AssociationDropdown from "../AssociationDropdown";
import Input from "../Input/Input";
import Dropdown from "../Dropdown/Dropdown";
import Widget from "../Widget/Widget";
import TagsSelector from "../TagsSelector";
import FormLabel from "../FormLabel";
import AddAttachment from "../AssetForms/AddAttachment";
import { REQUEST_PRIORITIES } from "../../../constants";

const ServiceRequestForm = ({
  requestForm,
  disableAssociationDropdown,
  ticketTypeOptions,
  handleChangeForm,
  handleTags,
  handleAddFiles,
  handleRemoveFile,
  handleChangeAssociation,
}) => {
  return (
    <>
      <Widget
        draggable={false}
        title={null}
        overflow
        className="min-w-xl max-w-xl"
      >
        <div
          className="grid grid-cols-3 gap-4"
          style={{
            width: 520,
          }}
        >
          <div className="col-span-1 flex items-center">
            <FormLabel label="Type" />
          </div>
          <div className="col-span-2">
            <Dropdown
              value={ticketTypeOptions.find(
                ({ value }) => value === requestForm.type
              )}
              options={ticketTypeOptions}
              onChange={({ value }) => handleChangeForm("type", value)}
              name="requestType"
            />
          </div>
          <div className="col-span-1">
            <FormLabel label="Request" isRequired />
          </div>
          <div className="col-span-2">
            <Input
              onChange={(value) => handleChangeForm("issue", value)}
              value={requestForm.issue}
              inputClassName="w-full bg-green-50 pl-4 pr-8 text-sm"
              inputContainerClassName="bg-green-50"
              validation={yup.string().required()}
              name="requestIssue"
              placeholder="Request Description"
              isTextarea
            />
          </div>

          <div className="col-span-1 flex items-center">
            <FormLabel label="Association" isRequired />
          </div>
          <div className="col-span-2">
            <AssociationDropdown
              association={requestForm?.association}
              disabled={disableAssociationDropdown}
              onChangeAssociation={handleChangeAssociation}
              isRequired
              disabledOptions={["Assets"]}
            />
          </div>

          {requestForm?.asset && (
            <>
              <div className="col-span-1 flex items-center">
                <FormLabel label="Asset" />
              </div>
              <div className="col-span-2">
                <AssociationDropdown
                  association={requestForm?.asset}
                  disabled={disableAssociationDropdown}
                  onChangeAssociation={handleChangeAssociation}
                />
              </div>
            </>
          )}

          <div className="col-span-1 flex items-center">
            <FormLabel label="Request For" />
          </div>
          <div className="col-span-2">
            <Input
              value={requestForm?.onBehalf}
              onChange={(value) => handleChangeForm("onBehalf", value)}
              className="w-full"
              inputClassName="bg-green-50 pl-4 pr-8 text-sm"
              inputContainerClassName="bg-green-50"
              placeholder="On Behalf"
            />
          </div>

          <div className="col-span-1 flex items-center">
            <FormLabel label="Priority" />
          </div>
          <div className="col-span-2">
            <Dropdown
              value={REQUEST_PRIORITIES.find(
                ({ value }) => value === requestForm.priority
              )}
              options={REQUEST_PRIORITIES}
              onChange={({ value }) => handleChangeForm("priority", value)}
              disableSort
              disableClear
              name="requestPriority"
            />
          </div>

          <div className="col-span-1" />
          <div className="col-span-2 mt-6">
            <AddAttachment
              files={requestForm?.files}
              onFilesAdded={handleAddFiles}
              onRemove={handleRemoveFile}
              labelClassname="text-sm"
            />
          </div>
        </div>

        <div className="border-b border-gray-200 mb-7 mt-2" />

        <div className="mt-6">
          <TagsSelector
            tags={requestForm.tags}
            isEditing
            isInline
            onTagAdded={(tag) => handleTags("add", tag)}
            onTagRemoved={(tag) => handleTags("remove", tag)}
          />
        </div>
      </Widget>
    </>
  );
};

ServiceRequestForm.defaultProps = {
  requestForm: undefined,
  disableAssociationDropdown: false,
  lockAssociation: {
    association: undefined,
    asset: undefined,
  },
  ticketTypeOptions: undefined,
  handleChangeForm: undefined,
  handleTags: undefined,
  handleAddFiles: undefined,
  handleRemoveFile: undefined,
  handleChangeAssociation: undefined,
};

ServiceRequestForm.propTypes = {
  requestForm: PropTypes.shape({
    type: PropTypes.string,
    association: PropTypes.string,
    asset: PropTypes.string,
    issue: PropTypes.string,
    priority: PropTypes.string,
    onBehalf: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.shape({})),
    tags: PropTypes.arrayOf(PropTypes.string),
  }),
  disableAssociationDropdown: PropTypes.bool,
  ticketTypeOptions: PropTypes.arrayOf(PropTypes.string),
  lockAssociation: PropTypes.shape({
    association: PropTypes.string,
    asset: PropTypes.string,
  }),
  handleChangeForm: PropTypes.func,
  handleTags: PropTypes.func,
  handleAddFiles: PropTypes.func,
  handleRemoveFile: PropTypes.func,
  handleChangeAssociation: PropTypes.func,
};

export default ServiceRequestForm;

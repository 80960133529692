import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import collapseIcon from "../../assets/images/collapseIcon.svg";
import IconButton from "../Buttons/IconButton";
import PlusCircleButton from "../Buttons/PlusCircleButton/PlusCircleButton";
import DatePicker from "../DatePicker/DatePicker";
import DistroListSelectMembers from "../DistroList/DistroListSelectMembers";
import Dropdown from "../Dropdown/Dropdown";
import Input from "../Input/Input";
import RequestGridViewDistroListCell from "../Requests/RequestGridView/RequestGridViewDistroListCell";
import DescriptionField from "../DescriptionField";

export default function RequestInfoCard({
  title,
  value,
  distroList,
  associations,
  last,
  first,
  impactValue,
  editing,
  dispatch,
  distroListOptions,
  customElementCN,
}) {
  const [dropdownValue, setDropdownValue] = useState({
    label: "Select",
  });
  const [distroSelectOpen, setDistroSelectOpen] = useState(false);

  const handleDropdownChange = useCallback(
    (fieldValue, field) => {
      switch (field) {
        case "scheduleReference": {
          setDropdownValue(fieldValue);
          return dispatch({
            type: "scheduleRef",
            payload: fieldValue.value,
          });
        }
        case "budgetReference": {
          setDropdownValue(fieldValue);
          return dispatch({
            type: "budgetRef",
            payload: fieldValue.value,
          });
        }
        default:
          setDropdownValue(fieldValue);
          return dispatch({
            type: field,
            payload: fieldValue.value,
          });
      }
    },
    [dispatch]
  );
  const handleDeleteFromDistro = (val) => {
    const result = value.filter((item) => {
      return item.reference !== val;
    });
    const refs = result?.map((user) => {
      return user?.reference;
    });
    return dispatch({
      type: "distribution",
      payload: refs,
    });
  };
  const handleStartDate = (val, type) => {
    if (type === "CompletionTargetDate") {
      dispatch({
        type: "editmodeCompletionTargetDate",
        payload: val,
      });
    } else if (type === "CompletionProjectedDate") {
      dispatch({
        type: "editmodeCompletionProjectedDate",
        payload: val,
      });
    } else
      dispatch({
        type: "editmodeStartDate",
        payload: val,
      });
  };

  const handleAddToDistro = (val) => {
    dispatch({
      type: "editModeDistribution",
      payload: val,
    });
  };
  const handleAddToAssociatedArtifacts = (val) => {
    dispatch({
      type: "editModeArtifacts",
      payload: val,
    });
  };

  const handleDeleteFromAstifacts = (ref) => {
    const updatedArtifacts = value
      ?.filter((item) => item?.reference !== ref)
      ?.map((item) => item?.reference);
    dispatch({
      type: "associatedArtifacts",
      payload: updatedArtifacts,
    });
  };

  const handleInputChange = (key, val) => {
    dispatch({
      type: key,
      payload: val,
    });
  };

  return (
    <div
      id="submittal-row"
      className={`${last ? "" : "border-b"} ${first ? "pb-6" : "py-6"}`}
    >
      <div className="flex w-full">
        <p className="text-sm font-bold text-gray-450 w-1/3">{title}</p>
        {distroList && (
          <div className="relative flex flex-col gap-4 w-2/3">
            <RequestGridViewDistroListCell
              hideTile
              distroList={value}
              hideDeleteIcon={!editing.isEditing}
              handleDeleteFromDistro={handleDeleteFromDistro}
              isEditing={editing.isEditing}
            />
            {editing.isEditing && (
              <PlusCircleButton
                className="mt-0"
                noHover
                style={{ color: "#027D61" }}
                title="Add"
                onClick={() => setDistroSelectOpen((prev) => !prev)}
              />
            )}
            {editing.isEditing && distroSelectOpen && (
              <div className="w-full">
                <DistroListSelectMembers
                  members={distroListOptions}
                  selectedMembers={value}
                  placeholder="Select Member"
                  onAdd={(val) => handleAddToDistro(val.value)}
                  onRemove={handleDeleteFromDistro}
                  setDistroSelectOpen={setDistroSelectOpen}
                />
              </div>
            )}
          </div>
        )}
        {associations && (
          <div className="flex flex-col gap-1 w-2/3">
            {value?.map((item, index) => {
              const key = index;
              return (
                <div key={key} className="flex justify-start">
                  <p className="truncate overflow-x-hidden text-ellipsis mr-4">
                    {item?.name}
                  </p>
                  {editing.isEditing && (
                    <IconButton
                      onClick={() => handleDeleteFromAstifacts(item?.reference)}
                      icon={collapseIcon}
                      className="mt-1"
                    />
                  )}
                </div>
              );
            })}
            {editing.isEditing && (
              <Dropdown
                className="mt-2"
                options={editing?.value?.options}
                onChange={(val) => handleAddToAssociatedArtifacts(val.value)}
              />
            )}
          </div>
        )}
        {!distroList &&
          !associations &&
          !impactValue &&
          !editing?.isEditing && (
            <p
              className={`${
                value === ""
                  ? "h-5"
                  : `${
                      customElementCN ||
                      "capitalize text-sm font-normal flex-wrap w-2/3 max-h-24 overflow-y-scroll break-all"
                    }`
              }`}
            >
              {typeof value === "string" ? (
                <DescriptionField text={value} />
              ) : (
                value
              )}
            </p>
          )}
        {editing?.isEditing && editing?.value?.type === "Input" && (
          <div className="flex w-2/3">
            <Input
              placeholder="Value"
              className={`${editing?.isEditing && "w-full"}`}
              inputClassName="ESInput bg-lightGreen text-gray-450 placeholderOverride"
              inputContainerClassName="bg-lightGreen text-gray-450"
              value={editing?.value?.fieldValue}
              onChange={(val) => handleInputChange(editing?.value?.field, val)}
              isTextarea={editing?.value?.isTextarea}
            />
          </div>
        )}

        {!associations &&
          editing?.isEditing &&
          editing?.value?.type === "Dropdown" && (
            <>
              <Dropdown
                options={editing?.value?.options}
                onChange={(val) =>
                  handleDropdownChange(val, editing?.value?.field)
                }
                value={
                  dropdownValue?.value
                    ? dropdownValue
                    : editing?.value?.editingValue
                }
                isDisabled={editing?.disabled}
              />
            </>
          )}
        {editing?.isEditing &&
          editing?.value?.type === "Date" &&
          editing?.isDraft && (
            <>
              <DatePicker
                name="start"
                labelClassName="ESInputLabel font-light"
                className="mr-4 text-black"
                onChange={(val) => {
                  handleStartDate(val, editing?.value?.field);
                }}
                value={editing?.value?.value}
                minDate={editing?.value?.minDate}
              />
            </>
          )}
        {!!impactValue && (
          <div className="flex w-2/3 justify-evenly">
            <p
              className={`${
                value === "" ? "h-5" : "capitalize text-sm font-normal w-1/2"
              }`}
            >
              {value}
            </p>
            <p
              className={`${
                value === "" ? "h-5" : "text-sm font-normal w-1/2"
              }`}
            >
              {Number.isInteger(impactValue)
                ? `${impactValue} days`
                : `$ ${impactValue}`}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

RequestInfoCard.propTypes = {
  /* title to be displayed in the row */
  title: PropTypes.string,
  /* value will be a string value usually, except for distro list */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  /* distroList is a boolean to display distro list as an array value */
  distroList: PropTypes.bool,
  /* associations is a boolean to display associated artifacts as an array value */
  associations: PropTypes.bool,
  /* determines if it's the last card in the column, and sets border conditionally */
  last: PropTypes.bool,
  /* If part of the impacts widget, impact value is a string associated with a budget cost or schedule duration */
  impactValue: PropTypes.string,
  editing: PropTypes.shape({
    /* isEditing - boolean for edit mode */
    isEditing: PropTypes.bool,
    /* boolean for whether submittal is a draft or in-progress */
    isDraft: PropTypes.bool,
    value: PropTypes.shape({
      isTextarea: PropTypes.bool,
      value: PropTypes.string,
      editingValue: PropTypes.string,
      fieldValue: PropTypes.string,
      /* type declares what type of input should be used while editing */
      type: PropTypes.string,
      /* field refers to what field on the form is being changed - look at SubmittalDetailView.jsx for this prop */
      field: PropTypes.string,
      /* options to be used in dropdown component should be an array of objects with label and value */
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        })
      ),
      minDate: PropTypes.string,
    }),
    disabled: PropTypes.bool,
  }),
  /* form from requestform reducer, submittal / rfi / rfp data */
  form: PropTypes.shape({
    startDate: PropTypes.string,
  }),
  /* form dispatcher */
  dispatch: PropTypes.func,
  distroListOptions: PropTypes.arrayOf(PropTypes.shape({})),
  first: PropTypes.bool,
  customElementCN: PropTypes.string,
};

RequestInfoCard.defaultProps = {
  title: undefined,
  value: undefined,
  last: false,
  distroList: false,
  associations: false,
  impactValue: undefined,
  editing: undefined,
  form: undefined,
  dispatch: undefined,
  distroListOptions: [],
  first: false,
  customElementCN: undefined,
};

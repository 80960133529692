import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import cntl from "cntl";

import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";

const containerCN = (className) => cntl`
    flex
    flex-col
    items-between
    justify-end
    ${className}
`;

const TimePicker = ({
  value,
  onChange,
  label,
  className,
  validation,
  placeholder,
  iconLeft,
  forwardedRef,
  handleEnter,
  minTime,
  timeIntervals,
  disabled,
  manualError,
  manualEntry,
  inModal,
  format,
  textClassName,
}) => {
  const [errorMessage, setErrorMessage] = useState();
  const [wasFocused, setWasFocused] = useState(false);

  useEffect(() => {
    const validate = async () => {
      if (validation) {
        try {
          await validation.validate(value);
          // validation passed
          setErrorMessage(undefined);
        } catch (err) {
          setErrorMessage(err.message);
        }
      }
    };
    validate();
  }, [validation, value]);

  const timeChange = (time) => {
    onChange(time.toISOString());
  };
  const selected = value ?? null;

  const handleBlur = () => {
    if (!wasFocused) {
      setWasFocused(true);
    }
  };

  return (
    <div className={containerCN(className)}>
      {(label || errorMessage) && (
        <div className="ESInputLabel">
          {label && (
            <div className="flex">
              <p>{label}</p>
              {validation?.exclusiveTests?.required && (
                <p className="text-brandGreen text-sm">*</p>
              )}
            </div>
          )}
          {!!errorMessage && wasFocused && (
            <p className="text-brandRed italic ml-2">
              {errorMessage ?? manualError}
            </p>
          )}
        </div>
      )}
      <div
        className={`${
          inModal ? "ESTimeInputContainer" : "ESInputContainer"
        } flex ${manualError && "border-brandRed"} ${
          disabled ? "bg-gray-100 opacity-50" : "bg-gray-input"
        }`}
        onBlur={handleBlur}
      >
        {iconLeft && (
          <span className="flex items-center relative -mr-2 pl-2 z-10">
            {iconLeft}
          </span>
        )}
        <ReactDatePicker
          placeholderText={placeholder}
          className={`${
            inModal ? "ESTimeInput" : "ESInput"
          } w-full h-full relative bg-transparent ${textClassName}`}
          wrapperClassName="w-full h-full"
          selected={selected}
          onChange={timeChange}
          dateFormat={format ?? "h:mm aa"}
          ref={forwardedRef}
          showTimeSelect
          showTimeSelectOnly
          onKeyDown={(e) => {
            if (!manualEntry) e.preventDefault();
          }}
          timeCaption="Time"
          timeIntervals={timeIntervals ?? 1}
          minTime={minTime ? new Date(minTime) : new Date().setHours(0, 0, 0)}
          maxTime={new Date().setHours(23, 59, 59)}
          onCalendarClose={handleEnter}
          readOnly={disabled}
        />
      </div>
    </div>
  );
};

TimePicker.propTypes = {
  /**
   * Current value
   */
  value: PropTypes.string,
  /**
   * Function called when time is changed
   */
  onChange: PropTypes.func,
  /**
   * Text to apply above the cell
   */
  label: PropTypes.string,
  /**
   * Classes to apply to the container
   */
  className: PropTypes.string,
  /**
   * Placeholder text to display
   */
  placeholder: PropTypes.string,
  /**
   * Validation schema using yup
   */
  // eslint-disable-next-line react/forbid-prop-types
  validation: PropTypes.object,
  /**
   * JSX to display on left side of input
   */
  iconLeft: PropTypes.element,
  /**
   * Sets the first selectable time in the Timepicker
   */
  minTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  handleEnter: PropTypes.func,
  /**
   * Time interval in dropdown select
   */
  timeIntervals: PropTypes.number,
  /**
   * Disable time input
   */
  disabled: PropTypes.bool,
  /**
   * Manual Error Border
   */
  manualError: PropTypes.bool,
  /**
   * Flag For smaller input container(Preferably in modal)
   */
  inModal: PropTypes.bool,
  /**
   * Format
   */
  format: PropTypes.string,
  /**
   * Manual Entry
   * Enabled by default
   */
  manualEntry: PropTypes.bool,
  /**
   * ClassName to apply to the value in the input
   * example: text-center p-1 to center text in input for modals
   */
  textClassName: PropTypes.string,
};

TimePicker.defaultProps = {
  value: undefined,
  onChange: undefined,
  label: undefined,
  className: undefined,
  validation: undefined,
  placeholder: "Select a time...",
  iconLeft: undefined,
  forwardedRef: undefined,
  handleEnter: undefined,
  timeIntervals: undefined,
  disabled: false,
  manualError: false,
  inModal: false,
  format: undefined,
  manualEntry: true,
  textClassName: " ",
};

export default TimePicker;

import { useState, useCallback, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

const useBillingBaseAddressData = (address, onChange) => {
  const [addr, setAddr] = useState(address);
  const inputId = useRef(uuidv4()).current;
  /**
   * @function handleFullChange
   * @summary - Sets local state to full google address & passes it to
   * onChange prop function call
   */
  const handleFullChange = useCallback(
    (val) => {
      setAddr(val);
      onChange(val);
    },
    [onChange]
  );

  /**
   * @function handleChange
   * @summary - Updates local state with key value pair & passes key value
   * pair to onChange prop function call
   */
  const handleChange = useCallback(
    (key, val) => {
      setAddr({ ...addr, [key]: val });
      onChange({ ...addr, [key]: val });
    },
    [addr, onChange]
  );

  const handleAddressSelect = (val) => {
    // When selecting custom address don't change other values
    if (Object.values(val).length === 1) {
      handleChange("street", val.street);
      return;
    }
    handleFullChange(val);
  };

  const getValueFromKey = (key) => {
    return addr?.[key] ? addr?.[key] : null;
  };

  return {
    inputId,
    handleChange,
    handleAddressSelect,
    getValueFromKey,
  };
};

export default useBillingBaseAddressData;

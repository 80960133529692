import React from "react";
import commentIcon from "../../assets/images/commentGreenIcon.svg";

const ReplyButton = ({ onClick }) => {
  return (
    <button
      className="bg-white px-1 border border-gray-light"
      type="button"
      onClick={onClick}
    >
      <div className="flex row items-center">
        <span className="text-sm" style={{ color: "#027D61" }}>
          Reply
        </span>
        <div className="pr-2" />
        <img src={commentIcon} alt="comment" />
      </div>
    </button>
  );
};

export default ReplyButton;

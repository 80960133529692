/* eslint-disable no-param-reassign */
import { useReducer } from "react";
import moment from "moment";
import { DateIsAfter, DateIsBefore } from "../helpers/Dates";

const subtractHours = (val0, val1) => {
  const [hour0, minutes0] = val0.split(":");

  const [hour1, minutes1] = val1.split(":");

  const compTime = ((hour0 - hour1) * 60 + (minutes0 - minutes1)) / 60;

  if (compTime % 1) {
    return compTime;
  }
  return parseInt(compTime, 10);
};

const formatRequest = (req) => {
  if (!req) return undefined;

  const { dates, dateRange } = req;

  const dateList = dates?.reduce((list, item) => {
    list[item.date?.split("T")[0]] = {
      hours: parseFloat(item.numHours),
      checked: +item.numHours > 0,
      ...item,
    };
    return list;
  }, {});
  const [start, end] = dateRange.split("to");
  return {
    ...req,
    startDate: start?.split("T")[0],
    endDate: end?.split("T")[0] ?? start?.split("T")[0],
    dateList,
    requestType: req.type,
  };
};

const defaultState = () => {
  const currentDay = moment();

  if (currentDay.day() === 0) {
    currentDay.add(1, "days");
  } else if (currentDay.day() === 6) {
    currentDay.add(2, "days");
  }
  return {
    startDate: currentDay.format("YYYY-MM-DD"),
    endDate: currentDay.format("YYYY-MM-DD"),
    dateList: {
      [currentDay.format("YYYY-MM-DD")]: {
        hours: 8.0,
        checked: true,
      },
    },
  };
};

const reducer = (request, action) => {
  switch (action.type) {
    case "durations": {
      return {
        ...request,
        dateList: {
          ...request.dateList,
          [action.key]: {
            ...request.dateList[action.key],
            hours: action.hours,
          },
        },
      };
    }
    case "uncheckDate": {
      const newRequest = { ...request };
      newRequest.dateList[action.key].hours = newRequest.dateList[action.key]
        .checked
        ? 0
        : 8;
      newRequest.dateList[action.key].checked = action.value;
      return newRequest;
    }
    case "startDate": {
      /**
       * if start date is after end date
       * set start date for both
       */
      if (
        (request.endDate && DateIsAfter(action.start, request.endDate)) ||
        !request.endDate
      ) {
        const startMoment = moment(action.start);
        // Check if day is a weekend
        // By Default Weekends are unchecked and === 0
        const isWeekend = startMoment.day() === 6 || startMoment.day() === 0;
        return {
          ...request,
          startDate: action.start,
          endDate: action.start,
          dateList: {
            [action.start]: {
              hours: !isWeekend ? 8.0 : 0,
              checked: !isWeekend,
            },
          },
        };
      }
      if (request.endDate) {
        const difference = moment(request.endDate).diff(
          moment(action.start),
          "days"
        );
        const timeOff = Array(difference + 1)
          .fill(0)
          .reduce((list, _, index) => {
            const currentDay = moment
              .utc(action.start.split("T")[0])
              .add(index, "days");
            // Check if day is a weekend
            // By Default Weekends are unchecked and === 0
            const isWeekend = currentDay.day() === 6 || currentDay.day() === 0;
            list[currentDay.format("YYYY-MM-DD")] = {
              hours: !isWeekend ? 8.0 : 0,
              checked: !isWeekend,
            };
            return list;
          }, {});

        return { ...request, dateList: timeOff, startDate: action.start };
      }

      return { ...request, startDate: action.start };
    }
    case "endDate": {
      if (request.startDate && DateIsAfter(request.startDate, action.end)) {
        return {
          ...request,
          startDate: action.end,
          endDate: action.end,
          dateList: {
            [action.end]: {
              hours: 8.0,
              checked: true,
            },
          },
        };
      }

      if (request.startDate) {
        const difference = moment
          .utc(action.end.split("T")[0])
          .diff(moment.utc(request.startDate), "days");

        const timeOff = Array(difference + 1)
          .fill(0)
          .reduce((list, _, index) => {
            const day = moment.utc(request.startDate).add(index, "days");
            const dayIso = day.format("YYYY-MM-DD");
            // Check if day is a weekend
            // By Default Weekends are unchecked and === 0
            const isWeekend = day.day() === 6 || day.day() === 0;
            /**
             * Check if existing days are split between payroll period or before current date
             * if so do not overwrite them
             */
            if (
              DateIsBefore(
                dayIso,
                moment(moment().format("MM/DD/YYYY")).startOf("day").format()
              ) &&
              action.toModify
            ) {
              const existingDate = action.toModify.dates.find(
                (item) =>
                  moment(item.date).format("MM/DD/YYYY") ===
                  day.format("MM/DD/YYYY")
              );

              list[dayIso] = {
                hours: existingDate?.numHours ?? (!isWeekend ? 8.0 : 0),
                checked: !isWeekend,
              };

              return list;
            }

            list[dayIso] = {
              hours: !isWeekend ? 8.0 : 0,
              checked: !isWeekend,
            };

            return list;
          }, {});

        return { ...request, dateList: timeOff, endDate: action.end };
      }
      return { ...request, endDate: action.end };
    }
    case "type": {
      return {
        ...request,
        requestType: action.requestType,
        type: action.requestType,
      };
    }
    case "fromTime": {
      const editedDate = request.dateList[action.key];
      const hours = subtractHours(editedDate.to, action.from);
      if (hours < 0) {
        return {
          ...request,
          dateList: {
            ...request.dateList,
            [action.key]: { hours: 0 },
          },
        };
      }
      return {
        ...request,
        dateList: {
          ...request.dateList,
          [action.key]: { hours },
        },
      };
    }
    case "toTime": {
      const editedDate = request.dateList[action.key];
      const hours = subtractHours(action.to, editedDate.from);
      if (hours < 0) {
        return {
          ...request,
          dateList: {
            ...request.dateList,
            [action.key]: {
              hours: 0,
            },
          },
        };
      }
      return {
        ...request,
        dateList: {
          ...request.dateList,
          [action.key]: { hours },
        },
      };
    }
    case "note":
      return { ...request, note: action.note };
    case "focus": {
      const { dateList } = request;
      dateList[action.key].focus = action.value;

      return { ...request, dateList };
    }
    case "reset":
      return {};
    case "modify":
      return { ...action.request };
    case "resetHours": {
      const difference = moment(request.endDate).diff(
        moment(request.startDate),
        "days"
      );
      const timeOff = Array(difference + 1)
        .fill(0)
        .reduce((list, _, index) => {
          const day = moment(request.startDate).add(index, "days");
          // Check if day is a weekend
          // By Default Weekends are unchecked and === 0
          const isWeekend = day.day() === 6 || day.day() === 0;
          list[day.format()] = {
            hours: !isWeekend ? 8.0 : 0,
            checked: !isWeekend,
          };
          return list;
        }, {});

      return { ...request, dateList: timeOff };
    }
    default:
      return request;
  }
};

export default (initialState) => {
  return useReducer(reducer, formatRequest(initialState) ?? defaultState());
};

import { useMutation } from "react-query";
import { TimeoffAPI } from "@griffingroupglobal/eslib-api";
import timeoffKeys from "./timeoffKeys";

const useAwardTimeoff = () => {
  const { mutate, isLoading, isError } = useMutation(
    timeoffKeys.availableTimeoff,
    async (requestBody) => {
      await TimeoffAPI.postWOP("$award", requestBody);
    }
  );

  return { awardTimeoff: mutate, isLoading, isError };
};

export default useAwardTimeoff;

import { cloneDeep } from "lodash";
import { useReducer } from "react";

const defaultState = {
  edit: false,
  name: "",
  category: "",
  description: "",
  dateCreated: "",
  lastUpdatedBy: "",
  steps: [],
  tags: [],
  files: [],
  links: [],
  originalResource: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "addLinkCompleteLink":
      return {
        ...state,
        links: [...(state.links ?? []), action.link],
      };
    case "addLink": {
      return {
        ...state,
        links: [...state.links, action.payload],
      };
    }
    case "addStep": {
      return {
        ...state,
        steps: [...state.steps, action?.payload],
      };
    }
    case "setCurrentTags": {
      return { ...state, currentTags: action.currentTags };
    }
    case "editName": {
      return { ...state, name: action.payload };
    }
    case "editCategory": {
      return { ...state, category: action.payload };
    }
    case "editDescription": {
      return { ...state, description: action.payload };
    }
    case "editStep": {
      return {
        ...state,
        steps: state?.steps?.map((step) => {
          if (action.payload?.id === step?.id)
            return { ...step, description: action?.payload?.description };
          return step;
        }),
      };
    }
    case "updateSteps": {
      return {
        ...state,
        steps: action?.payload ?? state?.steps,
      };
    }
    case "deleteLink": {
      return {
        ...state,
        links: state.links.filter((link) => link.id !== action.linkId) || [],
      };
    }
    case "deleteStep": {
      const result = state.steps.filter((step) => step.id !== action.payload);
      return { ...state, steps: result };
    }
    case "reset": {
      const cloned = cloneDeep(action.sop);
      return cloned ? { ...cloned, originalResource: cloned } : defaultState;
    }
    case "initialize": {
      return {
        ...defaultState,
        ...action.payload,
        originalResource: { ...defaultState, ...action.payload },
      };
    }
    default:
      return defaultState;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};

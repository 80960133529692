import React from "react";
import PropTypes from "prop-types";
import arrowLeftIcon from "../../../assets/images/arrowLeftIcon.png";

const BackButton = ({
  onBackPressed,
  title,
  buttonStyle,
  buttonClassName,
  titleClassName,
}) => {
  return (
    <button
      className={`flex justify-start items-center ${buttonClassName}`}
      style={{ width: "118.1px", height: "40px", ...buttonStyle }}
      type="button"
      onClick={onBackPressed}
    >
      <img
        src={arrowLeftIcon}
        alt="Back"
        style={{ width: "10px", height: "10px" }}
      />
      <span className={`ml-2 font-semibold ${titleClassName}`}>{title}</span>
    </button>
  );
};

BackButton.defaultProps = {
  onBackPressed: () => {},
  title: "Back",
  buttonStyle: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
  buttonClassName: PropTypes.string,
  titleClassName: PropTypes.string,
};

BackButton.propTypes = {
  onBackPressed: PropTypes.func,
  title: PropTypes.string,
  buttonStyle: undefined,
  buttonClassName: undefined,
  titleClassName: undefined,
};

export default BackButton;

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import Input from "../Input/Input";
import { formatWithCommasWithoutDecimal } from "../../../helpers/Formatters";

const BudgetTableReallocateForm = ({
  onChangeReallocationAmount,
  reallocation,
}) => {
  const getReallocationRemainingBalalnce = useCallback(() => {
    const diff =
      parseFloat(reallocation?.reallocateTotalAmount) -
      parseFloat(reallocation?.reallocateAmount);

    return formatWithCommasWithoutDecimal(diff >= 0 ? diff : 0);
  }, [reallocation?.reallocateAmount, reallocation?.reallocateTotalAmount]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <div className="pr-2">
          <Input
            placeholder="Amount"
            title="Rellocation Amount"
            onChange={onChangeReallocationAmount}
            validation={yup
              .number()
              .min(0, `Minimum atleast 0`)
              .max(
                parseFloat(reallocation?.reallocateTotalAmount),
                `Allowed maximum is ${reallocation?.reallocateTotalAmount}`
              )}
            showValidationErrorAtBottom
            value={reallocation?.reallocateAmount}
          />
        </div>
        <div className="pt-3">
          <span className="text-gray-300 px-1">out of</span>
          <span className="text-gray-300 font-medium">
            $
            {formatWithCommasWithoutDecimal(
              reallocation?.reallocateTotalAmount
            )}
          </span>
        </div>
      </div>
      <div className="pt-3">
        <span className="text-gray-300 font-medium">
          Remaining balance available to reallocate: $
          {getReallocationRemainingBalalnce()}
        </span>
      </div>
    </div>
  );
};

BudgetTableReallocateForm.propTypes = {
  reallocation: PropTypes.shape({
    reallocateTotalAmount: PropTypes.string,
    reallocateAmount: PropTypes.string,
  }),
  onChangeReallocationAmount: PropTypes.func,
};

BudgetTableReallocateForm.defaultProps = {
  onChangeReallocationAmount: undefined,
  reallocation: undefined,
};

export default BudgetTableReallocateForm;

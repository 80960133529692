/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import cntl from "cntl";
import { FileAPI } from "@griffingroupglobal/eslib-api";

import { useQueryClient } from "react-query";
import useDocumentsConfiguration from "../../../hooks/useDocumentsConfiguration";
import useFavorites from "../../../hooks/useFavorites";

import PureDocumentUploadForm from "./PureDocumentUploadForm";
import FileCard from "../FileCard/FileCard";

import { uploadFileWithData } from "../../../helpers/File";
import { formatMediaData } from "../../../helpers/Formatters";
import { fileCardStyle } from "../../../constants";
import { filePaginatedKeys } from "../../../config/reactQuery/queryKeyFactory";

const containerCN = (isOverlay, disableEditing) => cntl`
  ${isOverlay && "flex justify-center w-full"}
  ${disableEditing && "invisible"}
`;

const DocumentUploadForm = ({
  resource,
  resourceFiles,
  extensions,
  defaultType,
  onAddPhoto: onAddPhotoProp,
  onDeletePhoto,
  onAddVideo: onAddVideoProp,
  onDeleteVideo,
  onAddFile: onAddFileProp,
  onDeleteDocument,
  onFinishUploading,
  onNewDocType,
  showFiles,
  showCorner,
  isOverlay,
  noModal,
  small,
  title,
  disableEditing,
  disableHooks,
  isSop,
  sopStyle,
}) => {
  const { data: docConfig } = useDocumentsConfiguration();
  const [favorites] = useFavorites("File", disableHooks);
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState({});
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [documents, setDocuments] = useState([]);

  const docTypeOptionsMap = React.useMemo(() => {
    return {
      ...docConfig?.documents?.documentType
        ?.filter((doc) => doc.selected && (doc.value || doc.id))
        ?.reduce((obj, item) => {
          return {
            ...obj,
            [item.value ?? item.id]: {
              label: item.display,
              value: item.value ?? item.id,
            },
          };
        }, {}),
    };
  }, [docConfig]);

  useEffect(() => {
    const getImageUrls = async () => {
      try {
        if (resource?.images?.length) {
          const resolvedImgs = await formatMediaData(resource.images);
          setPhotos(
            resolvedImgs?.map((img, index) => ({
              ...img,
              isPrimary: resource.images[index]?.isPrimary,
            })) ?? []
          );
        }
      } catch (error) {
        console.warn(error);
      }
    };

    getImageUrls();
  }, [resource?.images]);

  useEffect(() => {
    const getVideoUrls = async () => {
      try {
        if (resource?.videos?.length) {
          const resolvedVids = await formatMediaData(
            resource?.videos?.map((v) => ({ file: v }))
          );
          setVideos(resolvedVids ?? []);
        }
      } catch (error) {
        console.warn(error);
      }
    };

    getVideoUrls();
  }, [resource?.videos]);

  useEffect(() => {
    const getDocUrls = async () => {
      try {
        if (resource?.files?.length) {
          const resolvedDocs = await formatMediaData(
            resource?.files?.map((d) => ({ file: d?.ref || d }))
          );
          setDocuments(resolvedDocs ?? []);
        }
      } catch (error) {
        console.warn(error);
      }
    };

    getDocUrls();
  }, [resource?.files]);

  const handleProgress = (loaded, total, fileId) => {
    setProgress((prevState) => ({
      ...prevState,
      [fileId]: [loaded, total],
    }));
  };

  const onAddPhoto = async (photo, data = {}, progressCallback) => {
    const fileResource = await uploadFileWithData(
      photo,
      data,
      progressCallback,
      undefined,
      true,
      undefined,
      true
    );
    const fileBody = {
      name: photo.name,
      contentType: photo.docType,
      size: photo.size,
      ...data,
    };
    if (onAddPhotoProp) {
      onAddPhotoProp(fileResource.reference, fileBody, fileResource);
    }
    return fileResource;
  };
  const onAddVideo = async (video, data = {}, progressCallback) => {
    const fileResource = await uploadFileWithData(
      video,
      data,
      progressCallback,
      undefined,
      true,
      undefined,
      true
    );
    const fileBody = {
      name: video.name,
      contentType: video.docType,
      size: video.size,
      ...data,
    };
    if (onAddVideoProp) {
      onAddVideoProp(fileResource.reference, fileBody, fileResource);
    }
    return fileResource;
  };
  const onAddFile = async (doc, data = {}, progressCallback) => {
    const fileResource = await uploadFileWithData(
      doc,
      data,
      progressCallback,
      undefined,
      true,
      undefined,
      true
    );
    const fileBody = {
      name: doc.name,
      contentType: doc.docType,
      size: doc.size,
      ...data,
    };
    if (onAddFileProp) {
      await onAddFileProp(fileResource.reference, fileBody, fileResource);
    }
    return fileResource;
  };

  const handleFavoriteClick = () => {};
  const handleFileClick = () => {};

  const onUpload = async (files, progressCallback) => {
    const handleProgressCallback = (loaded, total, filename) => {
      progressCallback(loaded, total, filename);
      handleProgress(loaded, total, filename);
    };
    setLoading(true);
    const result = await Promise.all(
      files.map(async ({ name, type, docType, isFavorited, original }) => {
        let res;
        const data = {
          name,
          docType: docType ?? defaultType,
          isFavorited,
          contentType: original.type,
          size: original.size,
        };

        if (type?.includes("image")) {
          res = await onAddPhoto(original, data, (loaded, total) =>
            handleProgressCallback(loaded, total, name)
          );
        } else if (type?.includes("video")) {
          res = await onAddVideo(original, data, (loaded, total) =>
            handleProgressCallback(loaded, total, name)
          );
        } else {
          res = await onAddFile(original, data, (loaded, total) =>
            handleProgressCallback(loaded, total, name)
          );
        }

        return { ...data, ...res, reference: res.reference };
      })
    );

    // update files in overview
    queryClient.invalidateQueries(filePaginatedKeys.allFiles);

    result.forEach((item) => {
      if (item.isFavorited) {
        handleFavoriteClick(item.reference.split("/")[1]);
      }
    });
    setLoading(false);
    if (onFinishUploading) {
      onFinishUploading(result);
    }
  };

  const onRemove = (fileId) => {
    const photoToRemove = photos.find((p) => p.id === fileId);
    const videoToRemove = videos.find((v) => v.id === fileId);
    const docToRemove = documents.find((d) => d.id === fileId);

    if (photoToRemove) {
      onDeletePhoto(fileId);
    } else if (videoToRemove) {
      onDeleteVideo(fileId);
    } else if (docToRemove) {
      onDeleteDocument(fileId);
    }
  };

  const onEdit = async (file, name) => {
    const body = {
      name,
    };
    const originalBody = {
      name: file.name,
    };
    await FileAPI.patch(file.id.split("File/")[1], body, originalBody);
    const photoIndex = photos.findIndex((p) => p.id === file.id);
    const videoIndex = videos.findIndex((v) => v.id === file.id);
    const docIndex = documents.findIndex((d) => d.id === file.id);
    if (photoIndex > -1) {
      const p = [...photos];
      p[photoIndex].name = name;
      setPhotos(p);
    } else if (videoIndex > -1) {
      const v = [...videos];
      v[videoIndex].name = name;
      setVideos(v);
    } else if (docIndex > -1) {
      const d = [...documents];
      d[docIndex].name = name;
      setDocuments(d);
    }
  };

  const getDocType = (documentType) => {
    if (documentType) {
      return docTypeOptionsMap[documentType]?.label ?? documentType;
    }
    return "File";
  };

  return (
    <>
      <div className={`flex flex-col ${isOverlay ? "w-full" : ""}`}>
        <div className={`flex flex-wrap ${isOverlay ? "w-full" : ""}`}>
          <div className={containerCN(isOverlay, disableEditing)}>
            <PureDocumentUploadForm
              extensions={extensions}
              defaultType={defaultType}
              onUpload={onUpload}
              onNewDocType={onNewDocType}
              showCorner={showCorner}
              isOverlay={isOverlay}
              noModal={noModal}
              small={small}
              title={title}
              disableHooks={disableHooks}
              isSop={isSop}
              sopStyle={sopStyle}
            />
          </div>
          {showFiles &&
            resourceFiles?.map((file) => {
              return (
                <div key={file.id} className="">
                  <FileCard
                    {...file}
                    docType={getDocType(file.docType)}
                    createdAt={
                      file.metadata?.createdAt
                        ? moment(file.metadata.createdAt).format("MM/DD/YYYY")
                        : "--"
                    }
                    isFavorited={
                      file.isFavorited
                        ? file.isFavorited
                        : favorites.some((fav) =>
                            fav.item.reference.includes(file.id)
                          )
                    }
                    style={fileCardStyle}
                    onFavoriteClick={() => handleFavoriteClick(file.id)}
                    onFileClick={() => handleFileClick(file)}
                    onFileEdit={onEdit}
                    onFileDelete={onRemove}
                    loadingProgress={progress}
                    isLoading={loading}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

DocumentUploadForm.propTypes = {
  resource: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        file: PropTypes.string,
        isPrimary: PropTypes.bool,
      })
    ),
    videos: PropTypes.arrayOf(PropTypes.string),
    files: PropTypes.arrayOf(PropTypes.string),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
      })
    ),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  resourceFiles: PropTypes.arrayOf(PropTypes.object),
  extensions: PropTypes.string,
  defaultType: PropTypes.string,
  onAddPhoto: PropTypes.func,
  onDeletePhoto: PropTypes.func,
  onAddVideo: PropTypes.func,
  onDeleteVideo: PropTypes.func,
  onAddFile: PropTypes.func,
  onDeleteDocument: PropTypes.func,
  onFinishUploading: PropTypes.func,
  onNewDocType: PropTypes.func,
  showFiles: PropTypes.bool,
  showCorner: PropTypes.bool,
  isOverlay: PropTypes.bool,
  small: PropTypes.bool,
  /**
   * No Modal && No Overlay
   * Same Functionality as Overlay
   */
  noModal: PropTypes.bool,
  /**
   * Title of Upload Container
   */
  title: PropTypes.string,
  disableEditing: PropTypes.bool,
  /**
   * Disable Hooks eg. (Media Upload)
   */
  disableHooks: PropTypes.bool,
  isSop: PropTypes.bool,
  sopStyle: PropTypes.shape({}),
};

DocumentUploadForm.defaultProps = {
  resource: {},
  resourceFiles: [],
  extensions: undefined,
  defaultType: undefined,
  onAddPhoto: undefined,
  onDeletePhoto: undefined,
  onAddVideo: undefined,
  onDeleteVideo: undefined,
  onAddFile: undefined,
  onDeleteDocument: undefined,
  onFinishUploading: undefined,
  onNewDocType: undefined,
  showFiles: false,
  showCorner: false,
  isOverlay: false,
  small: false,
  noModal: false,
  title: undefined,
  disableEditing: false,
  disableHooks: false,
  isSop: false,
  sopStyle: undefined,
};

export default DocumentUploadForm;

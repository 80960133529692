/* eslint-disable no-param-reassign */
import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import Checkbox from "../../Checkbox/Checkbox";
import IconButton from "../../Buttons/IconButton";
import TabbedContainer from "../../TabbedContainer/TabbedContainer";
import crossIcon from "../../../assets/images/Close.svg";
import TaskItem from "./TaskItem";

const TaskPopup = ({ tasks = [], close }) => {
  const { data: currentUser } = useCurrentUser();
  const [active, setActive] = useState(0);
  const [complete, setComplete] = useState(true);
  const [incomplete, setInComplete] = useState(true);

  const filters = useMemo(() => {
    return (
      <div className="flex w-full gap-6 border-b pb-5">
        <Checkbox
          size="7"
          label="Complete"
          checked={complete}
          onChange={(val) => setComplete(val)}
          inputClassName="calendar-form-tick"
        />
        <Checkbox
          size="7"
          label="Incomplete"
          checked={incomplete}
          onChange={(val) => setInComplete(val)}
          inputClassName="calendar-form-tick"
        />
      </div>
    );
  }, [complete, incomplete]);

  const taskList = useMemo(() => {
    return tasks.reduce(
      (list, item) => {
        if (item.invitees.includes(currentUser?.reference)) {
          list.my = [item, ...list.my];
        }
        list.all = [item, ...list.all];
        return list;
      },
      { all: [], my: [] }
    );
  }, [currentUser?.reference, tasks]);

  const filter = useCallback(
    (list) => {
      let conditions = [];
      if (!complete) {
        conditions.push("done");
      }
      if (!incomplete) {
        conditions = conditions.concat([
          "scheduled",
          "in-progress",
          "incomplete",
          "cancelled",
        ]);
      }
      return list.filter((item) => !conditions.includes(item.status));
    },
    [complete, incomplete]
  );

  const tabs = useMemo(() => {
    return [
      {
        title: "All Tasks",
        content: (
          <div>
            {filters}
            <div className="overflow-y-auto" style={{ maxHeight: "598px" }}>
              {filter(taskList?.all)?.map((item) => (
                <TaskItem key={item.id} {...item} task={item} close={close} />
              ))}
            </div>
          </div>
        ),
      },
      {
        title: "My Tasks",
        content: (
          <div>
            {filters}
            <div className="overflow-y-auto" style={{ maxHeight: "598px" }}>
              {filter(taskList?.my)?.map((item) => (
                <TaskItem key={item.id} {...item} task={item} close={close} />
              ))}
            </div>
          </div>
        ),
      },
    ];
  }, [filters, filter, taskList?.all, taskList?.my, close]);
  return (
    <div
      className="absolute bg-white shadow-1 rounded-lg"
      style={{ top: "-1rem", right: "-1.25rem", zIndex: "2" }}
    >
      <div className="p-6 relative" style={{ width: "359px" }}>
        <IconButton
          icon={crossIcon}
          wrapperClassName="absolute top-0 right-0 p-4"
          imgClassName="w-3 h-3"
          onClick={close}
        />
        <TabbedContainer
          tabs={tabs}
          activeIndex={active}
          onTabClick={(i) => setActive(i)}
        />
      </div>
    </div>
  );
};

TaskPopup.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({})),
  close: PropTypes.func,
};

TaskPopup.defaultProps = {
  tasks: [],
  close: () => {},
};

export default TaskPopup;

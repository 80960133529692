import React from "react";
import TaskClosingRow from "./TaskClosingRow";
import checkedClipboard from "../../../../assets/images/checkedClipboard.svg";
import Tooltip from "../../../Tooltip";
import Gallery from "../../../Gallery";
import AttachmentsHorizontalList from "../../../AttachmentsHorizontalList";
import TextWithMention from "../../../TextWithMention";
import useTaskClosingSectionData from "./useTaskClosingSectionData";

const TaskClosingSection = ({ taskForm }) => {
  const {
    history,
    sopsForTask,
    mediaFiles,
    documentFiles,
    allInviteesInfo,
    formattedComments,
    duration,
    isExpanded,
    onExpandClick,
  } = useTaskClosingSectionData(taskForm);

  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
        <p className="text-gray-500 font-semibold text-2xl w-1/2">
          Task History
        </p>
      </div>
      <div className="grid bg-backgroundGrey border rounded-lg mt-6">
        <TaskClosingRow
          index={0}
          task={taskForm}
          allInviteesInfo={allInviteesInfo}
          onClick={onExpandClick}
          isExpanded={isExpanded}
        />
        {isExpanded && (
          <div className="m-8">
            <div className="grid gap-4">
              <div className="flex gap-4">
                <div className="flex-col w-1/3">
                  <h3 className="text-gray-450 font-semibold text-lg mb-3">
                    Steps
                  </h3>
                  {sopsForTask?.length > 0 ? (
                    sopsForTask?.map((sop, index) => (
                      <div
                        key={sop?.name || sop?.stepData?.description}
                        className="flex"
                      >
                        <p className="text-gray-450 text-sm mr-2">
                          {index + 1}
                          {". "}
                        </p>
                        {sop?.resource ? (
                          <Tooltip title="click to navigate to sop">
                            <button
                              className="flex text-primaryGreen text-sm font-semibold"
                              onClick={() => history.push(`/sops/${sop.id}`)}
                              type="button"
                            >
                              <img
                                src={checkedClipboard}
                                alt={sop?.name}
                                className="w-4 h-5 mr-2"
                              />
                              {sop?.name}
                            </button>
                          </Tooltip>
                        ) : (
                          <p className="text-gray-450 text-sm">
                            {sop?.stepData?.description}
                          </p>
                        )}
                      </div>
                    ))
                  ) : (
                    <p className="text-sm text-gray-450">
                      There are no steps saved to this task
                    </p>
                  )}
                </div>
                <div className="flex-col w-2/3">
                  <div className="flex-col mb-10">
                    <h3 className="text-gray-450 font-semibold text-lg mb-3">
                      Media
                    </h3>
                    {taskForm?.closing?.files?.length > 0 ? (
                      <Gallery
                        isEditing={false}
                        mediaFiles={mediaFiles}
                        handleFilesToRemove={() => {}}
                      />
                    ) : (
                      <p className="text-sm text-gray-450">
                        There are no media saved to this task
                      </p>
                    )}
                  </div>
                  <div className="flex-col mb-10">
                    <h3 className="text-gray-450 font-semibold text-lg mb-3">
                      Attachments
                    </h3>
                    {taskForm?.closing?.files?.length > 0 ? (
                      <AttachmentsHorizontalList
                        files={documentFiles}
                        isEditing={false}
                        onFileRemoved={() => {}}
                      />
                    ) : (
                      <p className="text-sm text-gray-450">
                        There are no attachments saved to this task
                      </p>
                    )}
                  </div>
                  <div className="flex-col">
                    <h3 className="text-gray-450 font-semibold text-lg mb-3">
                      Duration
                    </h3>
                    <p className="text-sm text-gray-450">{duration}</p>
                  </div>
                </div>
              </div>
              <div className="border-b mb-4" />
              <div className="grid col-auto gap-4">
                <h3 className="text-gray-450 font-semibold text-lg">Comment</h3>
                {formattedComments ? (
                  <div className="flex items-start pb-2 w-full">
                    <p
                      className="flex items-start h-full mr-7"
                      style={{ width: "400px" }}
                    >
                      {formattedComments?.date} {formattedComments?.time}
                    </p>
                    <div className="flex justify-start w-full py-1">
                      <TextWithMention comment={formattedComments} />
                    </div>
                  </div>
                ) : (
                  <p className="text-sm text-gray-450">
                    There are no comments saved to this task
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskClosingSection;

import { useEffect, useState } from "react";
import { holidayModalSchema } from "../../../../helpers/FormValidations";
import { getDerivedDate, isWeekend } from "../../../../helpers/Holidays";

const useHolidaysSectionModalData = ({
  modalData: {
    existingHoliday = {},
    isEditingIndex,
    isAdding,
    onUpdateHoliday,
    selectedYear,
  },
}) => {
  // Form State
  const [holiday, setHoliday] = useState(existingHoliday);
  const [isValid, setIsValid] = useState(false);

  const { dateOption, recurrence: { calendarDay, dayOfWeek, month } = {} } =
    holiday;

  // Only if all recurrence values are filled out
  const validRecurrence = calendarDay && dayOfWeek?.length && month?.length;

  // For option specific validating
  const pureRecurrenceHoliday = { ...holiday };
  if (!validRecurrence) {
    delete pureRecurrenceHoliday?.recurrence?.derivedDate;
  }
  delete pureRecurrenceHoliday.date;
  const pureDateHoliday = { ...holiday };
  delete pureDateHoliday.recurrence;

  // Recurrence
  const recurrenceSelected =
    dateOption?.value === "customRecurrence" &&
    validRecurrence &&
    isWeekend(pureRecurrenceHoliday, selectedYear);
  const recurrenceAlreadySelected =
    !dateOption && isWeekend(pureRecurrenceHoliday, selectedYear);

  // Specific Date
  const specificDateSelected =
    dateOption?.value === "specificDate" &&
    isWeekend(pureDateHoliday, selectedYear);
  const specificDateAlreadySelected =
    !dateOption && isWeekend(pureDateHoliday, selectedYear);

  useEffect(() => {
    // Only if all recurrence values are filled out
    if (calendarDay && dayOfWeek?.length && month?.length) {
      setHoliday((prev) => {
        return {
          ...prev,
          recurrence: {
            ...prev.recurrence,
            derivedDate: getDerivedDate(prev, selectedYear?.value),
          },
        };
      });
    }
  }, [selectedYear, calendarDay, dayOfWeek, month]);

  useEffect(() => {
    const validationFields = [];
    validationFields.push(holidayModalSchema(isAdding).isValid(holiday));
    Promise.all(validationFields).then((values) => {
      setIsValid(!values.some((field) => field === false));
    });
  }, [isAdding, holiday, setIsValid]);

  return {
    isEditingIndex,
    isAdding,
    onUpdateHoliday,
    selectedYear,
    isValid,
    recurrenceSelected,
    recurrenceAlreadySelected,
    specificDateSelected,
    specificDateAlreadySelected,
    holiday,
    setHoliday,
  };
};

export default useHolidaysSectionModalData;

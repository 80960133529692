const getTsListParams = (pageState) => {
  const query = {
    params: {
      periodStart: pageState?.timesheet?.periodStart,
      periodEnd: pageState?.timesheet?.periodEnd,
      status: "approved,approved-with-changes,locked,submitted,rejected",
    },
  };
  return query;
};

export default getTsListParams;

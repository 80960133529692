import React from "react";
import PropTypes from "prop-types";
import TableActionsIconsGroup from "../../Table/TableActionsIconsGroup";

const Footer = ({
  isEditing,
  hidden,
  canEdit,
  onFinishEditing,
  onCancelEditing,
}) => {
  return (
    <div id="widgetEditor-footer" className={`mt-8 ${hidden ? "hidden" : ""}`}>
      <div id="header-actions" className="flex row justify-end">
        <TableActionsIconsGroup
          canEdit={canEdit}
          isEditing={isEditing}
          style={{ marginBottom: 30 }}
          handleSave={onFinishEditing}
          handleCancel={onCancelEditing}
        />
      </div>
    </div>
  );
};

Footer.defaultProps = {
  canEdit: false,
  isEditing: false,
  hidden: false,
  onFinishEditing: () => {},
  onCancelEditing: () => {},
};

Footer.propTypes = {
  canEdit: PropTypes.bool,
  isEditing: PropTypes.bool,
  hidden: PropTypes.bool,
  onFinishEditing: PropTypes.func,
  onCancelEditing: PropTypes.func,
};

export default Footer;

import React from "react";
import { useParams } from "react-router";
import { getProjectOptions } from "../../helpers/SiteHeaderOptions";
import SubmittalsOverview from "../Overviews/Submittals/SubmittalsOverview";
import { useProjectById } from "../../hooks/projects";

const ProjectSubmittals = ({
  isDraftView,
  managementConfiguration,
  currentUser,
}) => {
  const { projectId } = useParams();
  const { data: currentProject } = useProjectById(projectId);

  return (
    <SubmittalsOverview
      isDraftView={isDraftView}
      associatedResource={`Project/${projectId}`}
      siteHeaderProps={{
        title: currentProject?.name || "Loading",
        dropdownRoutes: getProjectOptions(projectId, currentUser),
      }}
      managementConfiguration={managementConfiguration}
      currentUser={currentUser}
    />
  );
};

export default ProjectSubmittals;

import { EventAPI, TaskAPI } from "@griffingroupglobal/eslib-api";

const fetchCalendarHandler = async (params) => {
  const response = await Promise.all([
    EventAPI.get(params),
    TaskAPI.get(params),
  ]);

  const fullList = [...response[0].data.entries, ...response[1].data.entries];

  return fullList;
};

export default fetchCalendarHandler;

import { TimesheetAPI } from "@griffingroupglobal/eslib-api";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { timesheetKeys } from "../config/reactQuery/queryKeyFactory";

const fetchMergedTimesheets = async (association, invoice) => {
  const { data } = await TimesheetAPI.getWOP("$getmergedtimesheets", {
    params: { association, invoice },
  });

  const dict = data.reduce((acc, v) => ({ ...acc, [v.reference]: v }), {});

  return {
    timesheets: data,
    dict,
  };
};
/**
 * Query hook to fetch Expenses
 * @returns query hook
 */
const useGetMergedTimesheets = (projectRef, invoiceRef) => {
  const queryKey = timesheetKeys.mergedTimesheetsByAssociationAndInvoice(
    projectRef,
    invoiceRef
  );

  return useAuthenticatedQuery({
    queryKey,
    enabled: !!projectRef && !!invoiceRef,
    queryFn: () => fetchMergedTimesheets(projectRef, invoiceRef),
    onError: (error) => {
      console.error("Error fetching Merged Timesheets", error);
    },
  });
};

export default useGetMergedTimesheets;

import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { DEFAULT_MEDIA_EXTENSIONS } from "../../../constants";

/**
 * Return only media files
 */
const getOnlyMediaFiles = (files) => {
  return files.reduce((acc, current) => {
    if (current.category !== "Documents") {
      acc.push(current);
    }

    return acc;
  }, []);
};

const useModalMediaWidget = ({
  requestForm,
  filesState,
  handleFilesToAdd,
  handleFilesToRemove,
}) => {
  const [hover, setHover] = useState(false);
  const [mediaFiles, setMediaFiles] = useState([]);

  // This effect set only Media files on state
  useEffect(() => {
    if (!requestForm?.files) return;

    const onlyMedia = getOnlyMediaFiles(requestForm?.files) ?? [];

    // Append possible new added files to be rendered in the list
    setMediaFiles([...onlyMedia, ...filesState.mediaFilesToAdd]);
  }, [requestForm?.files, filesState.mediaFilesToAdd]);

  const addNewFiles = (files) => {
    handleFilesToAdd(files, "media");
  };

  const removeFile = (file) => {
    handleFilesToRemove(file, "media");
  };
  /**
   * Transform files dropped to the way needs to be uploaded in backend
   */
  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = acceptedFiles.map((file) => ({
        original: file,
        id: uuidv4(),
        path: file.path,
        name: file.name,
        type: file.type,
        size: file.size,
      }));

      handleFilesToAdd(files);
    },
    [handleFilesToAdd]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: DEFAULT_MEDIA_EXTENSIONS,
  });

  return {
    removeFile,
    addNewFiles,
    onDrop,
    getRootProps,
    getInputProps,
    setHover,
    hover,
    mediaFiles,
  };
};

export default useModalMediaWidget;

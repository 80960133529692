import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import cntl from "cntl";
import { useHistory, useParams } from "react-router-dom";
import {
  CommentAPI,
  DocumentAPI,
  WorkflowAPI,
} from "@griffingroupglobal/eslib-api";
import ReactModal from "react-modal";
import { cloneDeep } from "lodash";
import { useAppState } from "../../../state/appState";
import WorkflowStepForm from "../../../stories/Components/WorkflowCreation/WorkflowStepForm";
import PrimaryHeader from "../../../stories/Components/TextHeaders/PrimaryHeader";
import Avatar from "../../../stories/Components/Message/Avatar";
import TabbedContainer from "../../../stories/Components/TabbedContainer/TabbedContainer";
import WorkflowDocumentTables from "../../../stories/Components/Workflows/WorkflowDocumentTables";
import Modal from "../../../stories/Components/Modal/Modal";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import Input from "../../../stories/Components/Input/Input";
import RadioButton from "../../../stories/Components/Buttons/RadioButton";
import yellowExclamationIcon from "../../../stories/assets/images/yellowExclamationIcon.svg";
import {
  GET_WORKFLOW_VIEW_PATH,
  RESOLVED_WF,
  RESUBMISSION_WF_STEP,
  UNRESOLVED_WF_STEP,
  WORKFLOWS_ADD_NEW_PATH,
  WORKFLOWS_DRAFTS_PATH,
  WORKFLOWS_EDIT_PATH,
  WORKFLOWS_PATH,
  SET_COMMENTS,
  GET_PROPERTY_WORKFLOW_EDIT_PATH,
  GET_PROJECT_WORKFLOW_EDIT_PATH,
  GET_PROPERTY_WORKFLOW_CREATE_PATH,
  GET_PROJECT_WORKFLOW_CREATE_PATH,
  fileCardMiniStyle,
  GET_PROPERTY_WORKFLOWS_DRAFT_PATH,
  GET_PROJECT_WORKFLOWS_DRAFT_PATH,
  GET_PROPERTY_WORKFLOWS_PATH,
  GET_PROJECT_WORKFLOWS_PATH,
  financialDocumentsType,
} from "../../../constants";
import WorkflowHistoryCard from "../../../stories/Components/Workflows/WorkflowHistoryCard";
import StatusPill from "../../../stories/Components/Pill/StatusPill";
import useWorkflowById from "../../../hooks/useWorkflowById";
import { getFullName } from "../../../helpers/Formatters";
import WorkflowData from "../../../helpers/Workflow";
import useCurrentUser from "../../../hooks/useCurrentUser";
import BaseButton from "../../../stories/Components/Buttons/BaseButton";
import PDFViewerModal from "../../../stories/Components/PDFViewer/PDFViewerModal";
import Spinner from "../../../stories/Components/Spinner/Spinner";
import DateAndName from "../../../stories/Components/Workflows/DateAndName";
import DeleteModal from "../../../stories/Components/DeleteModal/DeleteModal";
import { getDocumentData } from "../../../helpers/Document";
import useDocumentsConfiguration from "../../../hooks/useDocumentsConfiguration";
import useSingleAndDoubleClick from "../../../hooks/useSingleAndDoubleClick";
import useFavorites from "../../../hooks/useFavorites";
import ResourceDropDown from "../../../stories/Components/WorkflowCreation/ResourceDropDown";
import FileCard from "../../../stories/Components/FileCard/FileCard";
import WorkflowFileView from "../../../stories/Components/Workflows/WorkflowFileView";
import { downloadMedia } from "../../../helpers/File";
import useWatchList from "../../../hooks/useWatchList";

const headerTitleCN = (overdue) => cntl`
${overdue && "text-red-500 font-medium text-lg"}
${!overdue && "text-gray-400 font-medium text-lg"}
 `;
const headerSubTitleCN = cntl`
    text-gray-500
    font-medium
 `;

const overdueTitleCN = (overdue) => cntl`
${overdue && "text-red-500"}
${!overdue && "text-grey-500"}`;
const YESOPTION = "YES";
const NOOPTION = "NO";

const modalStyles = {
  content: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    height: "calc(100% - 72px)",
    margin: "36px 0 0 0",
    padding: "18px 20px 4px 20px",
    borderRadius: "0",
    inset: "0",
    overflow: "visible",
  },
  overlay: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "rgba(25, 25, 25, 0.8)",
    zIndex: "50",
  },
};

const WorkflowView = ({ workflowId: workflowIdProp }) => {
  const [watchList, , { addToWatchlist, removeFromWatchList }] = useWatchList();
  const history = useHistory();
  const { workflowId, projectId, propertyId } = useParams();
  const { data: currentUser } = useCurrentUser();
  const [favorites, { postFavorite, deleteFavorite }] = useFavorites();
  const { data: documentsConfiguration } = useDocumentsConfiguration();
  const {
    workflow,
    reload,
    isLoading,
    setWorkflow,
    reload: reloadWorkflow,
  } = useWorkflowById(workflowIdProp ?? workflowId);
  const [{ comments }, dispatch] = useAppState();

  const [currentWorkflowId, setCurrentWorkflowId] = useState(
    workflowIdProp ?? workflowId
  );
  const [workflowVersions, setWorkflowVersions] = useState([]);
  const [workflowDocs, setWorkflowDocs] = useState([]);
  const [associatedModal, setAssociatedModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [finDocPromptModal, setFinDocPromptModal] = useState(false);
  const [workflowData, setWorkFlowData] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [resolveOption, setResolveOption] = useState("resolved");
  const [resolvedNote, setResolvedNote] = useState("");
  const [associatedWF, setAssociatedWF] = useState(null);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [currentVersion, setCurrentVersion] = useState({});
  const [showFileModal, setShowFileModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [expanded, setExpanded] = useState(false);
  const [clickedDoc, setClickedDoc] = useState();
  const [watchedWF, setWatchedWF] = useState();
  const [isWatched, setIsWatched] = useState(false);
  const [isOverdue, setIsOverdue] = useState(false);
  const [checked, setChecked] = useState({
    resolved: true,
    unresloved: false,
  });
  const [isResolved, setIsResolved] = useState(false);

  useEffect(() => {
    if (workflowData?.status === RESOLVED_WF) {
      setIsResolved(true);
    }
  }, [workflowData?.status]);

  useEffect(() => {
    setCurrentWorkflowId(workflowIdProp ?? workflowId);
  }, [workflowIdProp, workflowId]);

  useEffect(() => {
    if (currentWorkflowId !== workflow?.id) {
      setWorkflow(reloadWorkflow(null, null, currentWorkflowId));
    }
  }, [workflow?.id, currentWorkflowId, reloadWorkflow, setWorkflow]);

  useEffect(() => {
    if (documentsConfiguration?.documents) {
      setDocumentTypes(
        documentsConfiguration?.documents.documentType
          .filter((doc) => doc.custom && doc.selected)
          .map((doc) => {
            return { label: doc.display, value: doc.id };
          })
          .sort((a, b) => a.label - b.label)
      );
    }
  }, [documentsConfiguration]);

  useEffect(() => {
    const foundItem = watchList.find((watchedItem) => {
      return watchedItem.item.reference.includes(currentWorkflowId);
    });
    setWatchedWF(foundItem);
    if (foundItem) {
      setIsWatched(true);
    } else {
      setIsWatched(false);
    }
  }, [watchList, currentWorkflowId]);

  useEffect(() => {
    const tempWorkflow = cloneDeep(workflow) ?? {};

    if (tempWorkflow.metadata && tempWorkflow.history && tempWorkflow.steps) {
      if (tempWorkflow?.reminders?.length > 0) {
        const reminderObj = {};
        for (let i = 0; i < tempWorkflow?.reminders?.length; i += 1) {
          const elem = tempWorkflow?.reminders[i];

          const prevReminder =
            reminderObj[elem?.step]?.[elem?.sentTo?.[0] || i];

          if (prevReminder) {
            if (moment(prevReminder?.sent).isAfter(elem.sent)) {
              // previous reminder is newer
            }
          } else {
            reminderObj[elem.step] = { [elem?.sentTo?.[0] || i]: elem };
          }
        }
        tempWorkflow.reminders = reminderObj;
      }
      let daySoFar = 0;
      tempWorkflow?.steps?.reduce((acc, cur, currentIndex) => {
        let mostDays = cur?.duration;
        let resubmissionStep = false;
        const stepPassed = WorkflowData.checkStep(cur, true);
        if (!stepPassed) {
          resubmissionStep = true;
        }
        const currentStep = workflow.steps[currentIndex];
        tempWorkflow.steps[currentIndex].dueDate = currentStep?.dueDate;

        for (let i = 0; i < cur?.parallelSteps?.length; i += 1) {
          const parallelStep = cur?.parallelSteps[i];
          if (parallelStep?.duration > mostDays) {
            mostDays = parallelStep?.duration;
          }
          const parStepPassed = WorkflowData.checkStep(
            cur?.parallelSteps[i],
            true
          );
          if (!parStepPassed) {
            resubmissionStep = true;
          }
          parallelStep.dueDate = moment(tempWorkflow.startDate?.date)
            .add(daySoFar + parallelStep?.duration, "day")
            .format();
        }
        if (resubmissionStep) {
          tempWorkflow.steps[currentIndex].resubmission = true;
        }
        daySoFar += mostDays;
        return acc + mostDays;
      }, 0);

      let maxDays = 0;
      tempWorkflow.steps = tempWorkflow.steps?.map((step) => {
        let localMax = step.duration;
        step.parallelSteps?.forEach((pstep) => {
          localMax = pstep.duration > localMax ? pstep.duration : localMax;
        });
        maxDays = localMax > maxDays ? localMax : maxDays;
        return { ...step, totalDurration: localMax };
      });

      tempWorkflow.steps = tempWorkflow.steps?.map((step) => {
        let maxDate = moment(step.dueDate?.date);
        step.parallelSteps?.forEach((stp) => {
          const localDueDate = moment(stp.dueDate);
          maxDate = localDueDate.isAfter(maxDate) ? localDueDate : maxDate;
        });
        return { ...step, maxDate: { date: maxDate.toISOString() } };
      });

      const lastStep = tempWorkflow.steps[tempWorkflow.steps?.length - 1];
      tempWorkflow.endDate = lastStep?.dueDate?.date;

      for (let i = 0; i < tempWorkflow.steps.length; i += 1) {
        const mainStep = tempWorkflow.steps[i];
        const mainPassed = WorkflowData.checkStep(mainStep);
        const failedParallelStep = [];
        for (
          let j = 0;
          j < tempWorkflow.steps[i]?.parallelSteps?.length;
          j += 1
        ) {
          const parallelStep = mainStep?.parallelSteps[j];
          const parPassed = WorkflowData.checkStep(parallelStep);
          if (!parPassed) {
            failedParallelStep.push(parallelStep);
          }
        }
        if (!mainPassed || failedParallelStep.length > 0) {
          tempWorkflow.currentStep = i;
          break;
        }
      }
      if (typeof tempWorkflow.currentStep !== "number") {
        tempWorkflow.currentStep = tempWorkflow?.steps?.length;
      }
      if (tempWorkflow.isDraft) {
        tempWorkflow.status = "draft";
      }
      const tempVersions = {};
      const replyObj = {};
      const tempUserInfoVersion = {};
      // eslint-disable-next-line array-callback-return
      tempWorkflow?.history?.map((info) => {
        const tempObj = { ...info };
        const version = info?.context?.workflow?.version;

        if (tempObj?.action === "Initiated") {
          tempUserInfoVersion[version] = {
            name: getFullName(info?.userData?.name),
            date: tempObj.when,
          };
          return;
        }

        const stepIndex = info?.context?.workflow?.step?.position;
        if (info?.context?.document) {
          tempObj.secondaryTitle =
            info?.fileData?.customName || info?.fileData?.name;
        } else if (info?.context?.comment) {
          const { commentData } = info;
          tempObj.text = commentData?.content;
          if (commentData?.ancestors?.length > 1) {
            // is reply
            const key = commentData?.ancestors[0];
            if (replyObj[key]) {
              replyObj[key]?.unshift(tempObj);
            } else {
              replyObj[key] = [tempObj];
            }
            return;
          }
          // is comment
          const replies = replyObj[commentData?.reference] || [];
          tempObj.subData = replies;
        }
        if (!tempVersions[version]) {
          tempVersions[version] = [];
        }

        if (tempVersions[version][stepIndex]) {
          tempVersions[version][stepIndex]?.subData?.unshift(tempObj);
        } else {
          tempVersions[version][stepIndex] = {
            title: `Step ${stepIndex + 1}`,
            subData: [tempObj],
          };
        }
      });
      const newVersions = tempWorkflow?.versions?.reverse()?.map((ver) => ({
        ...(ver ?? {}),
        history: tempVersions[ver?.version],
        user:
          tempUserInfoVersion[ver?.version]?.name ||
          getFullName(ver?.modifiedBy?.name),
        initiatedDate:
          tempUserInfoVersion[ver?.version]?.date || ver?.modifiedDate,
      }));
      if (newVersions?.length) {
        setCurrentVersion(newVersions[0]);
      }

      // document data
      const docVersions = [];
      const allDocuments = tempWorkflow?.history?.filter(
        (info) => info.fileData
      );

      for (let i = 0; i < allDocuments?.length; i += 1) {
        const info = allDocuments[i];

        const version = info?.context?.workflow?.version;
        const versionIndex = version - 1;
        const position = info?.context?.workflow?.step?.position;
        const stepId = info?.context?.workflow?.step?.id;
        const { stepName } = WorkflowData.getWorkflowStepName(
          position,
          stepId,
          "",
          workflow?.steps
        );
        const fullName = getFullName(info?.userData?.name);
        const tempObj = {
          step: stepName,
          resource: info.fileData?.customName || info.fileData?.name,
          docType: documentTypes.find(
            (type) => type.value === info.fileData?.docType
          )?.label,
          status: info.fileData?.status,
          creator: info.userData,
          contentType: info.fileData?.contentType,
          uploadedBy: fullName,
          modifiedDate: info.metadata?.lastUpdated,
        };

        if (docVersions[versionIndex]?.title) {
          docVersions[versionIndex]?.data.push(tempObj);
        } else {
          docVersions[versionIndex] = {
            title: `Version ${version}`,
            data: [tempObj],
          };
        }
      }

      setWorkflowDocs(docVersions.reverse());
      setWorkflowVersions(newVersions);
      delete tempWorkflow.versions;
      delete tempWorkflow.history;
      delete workflow?.versions;
      delete workflow?.history;
      setWorkFlowData(tempWorkflow);
    }
  }, [documentTypes, workflow]);

  const remainingDays = moment(workflowData?.endDate).diff(moment(), "days");

  useEffect(() => {
    const getPillStatus = () => {
      if (workflow?.status === RESUBMISSION_WF_STEP) {
        return "resubmissionRequested";
      }

      if (workflow.status === RESOLVED_WF) {
        return "resolved";
      }
      if (workflow.status === "inProgress") {
        return "inProgress";
      }
      if (workflow.status === RESOLVED_WF) {
        return "resolved";
      }

      return "unresolved";
    };
    if (workflow?.metadata) {
      const tempObj = { status: getPillStatus() };
      setWorkFlowData((prev) => ({ ...prev, ...tempObj }));
    }
  }, [remainingDays, workflowData.isDraft, workflowData.status, workflow]);

  const handleEditWorkflow = () => {
    if (propertyId) {
      history.push(
        GET_PROPERTY_WORKFLOW_EDIT_PATH(propertyId, currentWorkflowId)
      );
    } else if (projectId) {
      history.push(
        GET_PROJECT_WORKFLOW_EDIT_PATH(projectId, currentWorkflowId)
      );
    } else {
      history.push(WORKFLOWS_EDIT_PATH(currentWorkflowId));
    }
  };

  const handleResolveModalClose = () => {
    setFinDocPromptModal(false);
    setCompleteModal(false);
    setResolveOption(RESOLVED_WF);
    setResolvedNote("");
  };
  const handleAddReminder = (elem) => {
    const reminderObj = workflowData?.reminders || {};
    reminderObj[elem.step] = { [elem?.sentTo?.[0]]: elem };
    setWorkFlowData((prev) => ({ ...prev, reminders: reminderObj }));
    reload();
  };

  const resolve = async () => {
    const completed = {
      member: currentUser.reference,
      note: resolvedNote,
      date: moment().format(),
    };
    const tempObj = {
      ...(workflow ?? {}),
      status: resolveOption,
      completed,
      documents: workflow?.documents?.filter((doc) => doc.reference),
    };

    await WorkflowAPI.patch(workflow?.id, tempObj, workflow);
    reload();
    handleResolveModalClose();
  };

  const handleResolveWFWithFinDoc = () => {
    resolve();
  };

  const handleResolveWF = () => {
    // When resolving a WF with a finDoc, show prompt
    if (
      resolveOption === RESOLVED_WF &&
      financialDocumentsType[workflow?.documents?.[0]?.docType]
    ) {
      setFinDocPromptModal(true);
    } else {
      resolve();
    }
  };
  const handleDeleteWF = async () => {
    setIsSaving(true);
    setIsDeleteModalOpen(false);
    await WorkflowAPI.delete(workflow?.id);
    setIsSaving(false);

    if (workflow?.isDraft) {
      if (propertyId) {
        history.push(GET_PROPERTY_WORKFLOWS_DRAFT_PATH(propertyId));
      } else if (projectId) {
        history.push(GET_PROJECT_WORKFLOWS_DRAFT_PATH(projectId));
      } else {
        history.push(WORKFLOWS_DRAFTS_PATH);
      }
    } else if (propertyId) {
      history.push(GET_PROPERTY_WORKFLOWS_PATH(propertyId));
    } else if (projectId) {
      history.push(GET_PROJECT_WORKFLOWS_PATH(projectId));
    } else {
      history.push(WORKFLOWS_PATH);
    }
  };

  const getCreateAssociatedWorkflowPath = () => {
    if (propertyId) {
      return GET_PROPERTY_WORKFLOW_CREATE_PATH(propertyId);
    }
    if (projectId) {
      return GET_PROJECT_WORKFLOW_CREATE_PATH(projectId);
    }
    return WORKFLOWS_ADD_NEW_PATH;
  };

  const handleAssocation = () => {
    if (workflowData.status === RESOLVED_WF) {
      const assocatedState = {
        workflow,
        status: "contingent",
      };
      const path = getCreateAssociatedWorkflowPath();

      history.push(path, assocatedState);
    } else {
      setAssociatedModal(true);
    }
  };

  const handleWatchWFClick = async (id) => {
    if (watchedWF && isWatched) {
      removeFromWatchList([watchedWF]);
      setIsWatched(false);
    } else {
      addToWatchlist(id, "Workflow");
      setIsWatched(true);
    }
  };

  // commented out on purpose, may be necessary to check steps in the manor at a later date.
  /*   const handleCheckSteps = (finalStep) => {
    if (finalStep >= 1) {
      const checkStep = workflowData.steps[finalStep - 1];
      const passed = WorkflowData.checkStep(checkStep);
      return passed;
    }

    return false;
  }; */

  const manageWorkflowDdlOptions = (
    workflowStatus,
    user,
    associatedWorkflows
  ) => {
    const options = [];

    if (user && user?.hasPermission("workflow", "can_create")) {
      if (workflowStatus !== RESOLVED_WF || !workflowData.completed) {
        options.push({
          title: "Edit",
          onClick: handleEditWorkflow,
        });
      }
      options.push({
        title: "Create Associated Workflow ...",
        onClick: handleAssocation,
      });

      if (workflowStatus === UNRESOLVED_WF_STEP) {
        let hasNoBlockingWorkflows = true;
        // if all associated WFs resolved
        associatedWorkflows?.map((wf) => {
          if (wf.association === "contingent") {
            hasNoBlockingWorkflows = wf.workflowData?.status === "resolved";
          }
          return wf;
        });

        if (hasNoBlockingWorkflows && workflowStatus !== "inProgress") {
          options.unshift({
            title: "Complete",
            onClick: () => setCompleteModal(true),
          });
        }
      }
    }
    if (user?.hasPermission("workflow", "can_delete")) {
      if (workflowStatus !== UNRESOLVED_WF_STEP) {
        options.push({
          title: "Delete",
          onClick: () => setIsDeleteModalOpen(true),
        });
      }
    }
    options.push({
      title: `${isWatched ? "Unwatch" : "Watch"}`,
      onClick: () => handleWatchWFClick(currentWorkflowId),
    });
    return options;
  };

  const handleUpdateHistory = useCallback(
    async (text, context, message) => {
      const tempObj = {
        user: currentUser?.reference,
        when: moment().format(),
        action: text,
        association: workflow?.reference,
        context,
      };

      if (message) {
        tempObj.message = message;
      }
    },
    [currentUser, workflow]
  );

  const handleMemberResubmission = async (value, index, stepId) => {
    await WorkflowAPI.patch(workflow?.id, value, workflow);
    const msg = value.message;
    const historyContext = {
      workflow: {
        step: { id: stepId, position: index },
        version: workflowData?.version,
      },
    };
    handleUpdateHistory("Resubmission Requested", historyContext, msg);
    reload();
  };

  const handleMemberAdvance = async (value, index, stepId) => {
    const msg = value.message;
    const tempObj = value;
    if (value.currentStep === workflow?.steps?.length - 1) {
      const mainStep = value.steps[value.currentStep];
      const mainPassed = WorkflowData.checkStep(mainStep);
      const failedParallelStep = [];
      for (
        let j = 0;
        j < value.steps[value.currentStep]?.parallelSteps?.length;
        j += 1
      ) {
        const parallelStep = mainStep?.parallelSteps[j];
        const parPassed = WorkflowData.checkStep(parallelStep);
        if (!parPassed) {
          failedParallelStep.push(parallelStep);
        }
      }
      if (mainPassed && failedParallelStep.length === 0) {
        tempObj.status = UNRESOLVED_WF_STEP;
      }
    }
    const historyContext = {
      workflow: {
        step: { id: stepId, position: index },
        version: workflowData?.version,
      },
    };

    await WorkflowAPI.patch(workflow?.id, tempObj, workflow);

    handleUpdateHistory("Advanced", historyContext, msg);
    reload();
  };

  const getRemainingDayText = () => {
    if (typeof remainingDays !== "number") {
      return "";
    }
    if (remainingDays >= 0) {
      if (remainingDays === 1) {
        return `${remainingDays} Day remaining`;
      }
      return `${remainingDays} Days remaining`;
    }
    if (remainingDays === -1) {
      return `${remainingDays} Day overdue`;
    }
    return `${Math.abs(remainingDays)} Days overdue`;
  };

  useEffect(() => {
    if (remainingDays < 0) {
      setIsOverdue(true);
    }
  }, [remainingDays]);

  const handlePostComment = async (value, stepId, index, fileRef) => {
    if (value.trim().length > 0) {
      const context = {
        workflow: {
          version: workflow?.version,
          step: {
            position: index,
            id: stepId,
          },
        },
      };
      // if comment posted on a file
      if (fileRef) {
        context.workflow.file = fileRef;
        // TODO: need support for page number post 3.0 both in UI and API
      }

      const doc = await getDocumentData(fileRef);
      const tempObj = {
        content: value,
        association: workflow?.reference,
        author: currentUser.reference,
        context,
      };
      const { data: res } = await CommentAPI.post(tempObj);

      const historyContext = {
        workflow: {
          step: { id: stepId, position: index },
          version: workflowData?.version,
        },
        comment: { reference: res.reference },
      };
      handleUpdateHistory("Comment Posted", historyContext);

      const newComment = {
        ...res,
        userData: currentUser,
        replies: [],
        doc: doc?.customName || doc?.name,
      };
      const newWorkflow = {
        ...workflow,
        steps: workflow.steps?.map((item) => {
          if (item.id === stepId) {
            return { ...item, comments: [...item.comments, newComment] };
          }

          const found = item.parallelSteps?.find((stp) => stp.id === stepId);
          if (found) {
            return {
              ...item,
              parallelSteps: item.parallelSteps.map((stp) => {
                if (stp.id === found.id) {
                  return { ...stp, comments: [...stp.comments, newComment] };
                }
                return stp;
              }),
            };
          }

          return item;
        }),
      };
      dispatch({
        type: SET_COMMENTS,
        comments: [...comments, newComment],
      });
      setWorkflow(newWorkflow);
      setWorkFlowData((prev) => ({ ...prev, ...newWorkflow }));
    }
  };
  const handlePostReply = async (value, commentId, stepId, index) => {
    if (value.trim().length > 0) {
      const tempObj = {
        content: value,
        author: currentUser.reference,
        context: {
          workflow: {
            version: workflow?.version,
            step: {
              position: index,
              id: stepId,
            },
          },
        },
      };
      const { data: res } = await CommentAPI.postWOP(`${commentId}/$addreply`, {
        ...tempObj,
      });

      const historyContext = {
        workflow: {
          step: { id: stepId, position: index },
          version: workflowData?.version,
        },
        comment: { reference: res?.reference },
      };
      handleUpdateHistory("Reply Posted", historyContext);

      const newComment = {
        ...res,
        userData: currentUser,
        replies: [],
      };
      const newWorkflow = {
        ...workflow,
        steps: workflow.steps?.map((item) => {
          if (item.id === stepId) {
            return {
              ...item,
              comments: item.comments.map((cmt) => {
                if (cmt.id === commentId) {
                  return { ...cmt, replies: [...cmt.replies, newComment] };
                }
                return cmt;
              }),
            };
          }

          const found = item.parallelSteps?.find((stp) => stp.id === stepId);
          if (found) {
            return {
              ...item,
              parallelSteps: item.parallelSteps.map((stp) => {
                if (stp.id === found.id) {
                  return {
                    ...stp,
                    comments: stp.comments.map((cmt) => {
                      if (cmt.id === commentId) {
                        return {
                          ...cmt,
                          replies: [...cmt.replies, newComment],
                        };
                      }
                      return cmt;
                    }),
                  };
                }
                return stp;
              }),
            };
          }

          return item;
        }),
      };
      setWorkflow(newWorkflow);
      setWorkFlowData((prev) => ({ ...prev, ...newWorkflow }));
    }
  };

  const handleAssociatdModalClose = () => {
    setAssociatedModal(false);
    setAssociatedWF(null);
  };

  const handleAddFiles = useCallback(
    async (
      documents,
      isParallel,
      parentIndex,
      index,
      id,
      documentsToUpload
    ) => {
      // create documents
      setIsSaving(true);
      Promise.all(
        documentsToUpload?.map(async (doc) => {
          const payload = {
            status: "open",
            createdBy: currentUser.reference,
            docType: doc.documentType.value,
            customName: doc.name,
            contentReference: doc.id,
            lineItems: [],
          };

          if (workflow?.project) {
            payload.project = workflow?.project;
          } else if (workflow?.property) {
            payload.property = workflow?.property;
          }

          const { data } = await DocumentAPI.post(payload);
          return data.reference;
        })
      ).then(async (docRefs) => {
        const tempObj = cloneDeep(workflow);
        tempObj.documents = tempObj.documents?.filter((doc) => doc.reference);
        if (isParallel) {
          const currentFiles =
            tempObj.steps[parentIndex].parallelSteps[index].documents;
          const newFiles = [...currentFiles, ...docRefs];
          tempObj.steps[parentIndex].parallelSteps[index].documents = newFiles;
        } else {
          const currentFiles = tempObj.steps[parentIndex].documents;
          const newFiles = [...currentFiles, ...docRefs];
          tempObj.steps[parentIndex].documents = newFiles;
        }

        await WorkflowAPI.patch(workflow?.id, tempObj, workflow);

        docRefs?.map(async (file) => {
          const historyContext = {
            document: { reference: file },
            workflow: {
              step: { id, position: parentIndex },
              version: workflowData?.version,
            },
          };
          handleUpdateHistory("Document Uploaded", historyContext);
        });

        reload();
        setIsSaving(false);
      });
    },
    [currentUser, reload, handleUpdateHistory, workflowData, workflow]
  );

  const docTypeOptionsMap = React.useMemo(() => {
    return {
      ...documentsConfiguration?.documents?.documentType
        .filter((doc) => doc.selected && (doc.value || doc.id))
        .reduce((obj, item) => {
          return {
            ...obj,
            [item.value ?? item.id]: {
              label: item.display,
              value: item.value ?? item.id,
            },
          };
        }, {}),
    };
  }, [documentsConfiguration]);

  const tabSections = [
    {
      title: "STEPS",
      content: (
        <WorkflowStepForm
          stepData={workflowData?.steps}
          handleMemberResubmission={handleMemberResubmission}
          handleMemberAdvance={handleMemberAdvance}
          workflowData={workflowData}
          workflows={workflow}
          dispatch={reload}
          handlePostComment={handlePostComment}
          handlePostReply={handlePostReply}
          handleAddFiles={handleAddFiles}
          handleAddReminder={handleAddReminder}
          currentRef={currentUser?.reference}
          disableEditing={!currentUser?.hasPermission("workflow", "can_create")}
          viewing
          currentUser={currentUser}
        />
      ),
    },
    {
      title: "HISTORY",
      content: workflowVersions?.map((info) => {
        return (
          <WorkflowHistoryCard
            key={info?.version}
            data={{ ...info, steps: workflowData.steps }}
          />
        );
      }),
    },
    {
      title: "DOCUMENTS",
      content: (
        <WorkflowDocumentTables
          data={workflowDocs}
          workflowData={workflowData}
          selectedDocumentUrl={selectedDocumentUrl}
          docTypeOptionsMap={docTypeOptionsMap}
        />
      ),
    },
  ];

  const handleCreateAssociatedWF = () => {
    const assocatedState = {
      workflow,
      status: associatedWF === YESOPTION ? "contingent" : "non-contingent",
    };

    assocatedState.workflow.associationResource =
      workflow.property || workflow.project;

    const path = getCreateAssociatedWorkflowPath();

    history.push(path, JSON.stringify(assocatedState));
  };
  const handleViewAssociatedWF = (id) => {
    history.push(GET_WORKFLOW_VIEW_PATH(id));
  };
  const handleModalDelete = () => {
    handleDeleteWF();
    setIsDeleteModalOpen(false);
  };

  const handleFavoriteClick = React.useCallback(
    (id) => {
      const foundItem = favorites.find((favorite) => {
        return favorite.item.reference.includes(id);
      });

      if (foundItem) {
        deleteFavorite([foundItem]);
      } else {
        postFavorite(id, "Document");
      }
    },
    [favorites, deleteFavorite, postFavorite]
  );

  const handleDeleteFile = () => {};

  const handleDoubleClick = () => {
    setSelectedFile(clickedDoc);
    setShowFileModal(true);
    setExpanded(true);
  };

  const handleFileClick = useSingleAndDoubleClick(() => {}, handleDoubleClick);

  const handleFileDownload = async (doc) => {
    if (doc?.contentReference) {
      await downloadMedia([doc.contentReference]);
    }
    return null;
  };

  const getDocType = (documentType) => {
    if (documentType) {
      return docTypeOptionsMap[documentType]?.label ?? documentType;
    }
    return "File";
  };

  const handleChecked = (value) => {
    if (value === "unresolved") {
      setChecked({ ...checked, unresloved: true, resolved: false });
      setResolveOption(RESOLVED_WF);
    } else {
      setChecked({ ...checked, unresloved: false, resolved: true });
      setResolveOption(RESOLVED_WF);
    }
  };

  const getStatus = () => {
    if (workflowData?.status === "unresolved") {
      return "pending";
    }
    return workflowData.status;
  };

  return (
    <div className="mt-6">
      {!(isLoading || isSaving) && (
        <>
          <div className="flex justify-between items-end mb-8">
            <div className="flex flex-row justify-end pt-6">
              <PrimaryHeader className="mr-6">
                {workflowData.name}
              </PrimaryHeader>
              <StatusPill value={getStatus()} />
            </div>
            {!workflowIdProp && !isResolved && (
              <PrimaryButton
                large
                title="Actions"
                dropdownItems={manageWorkflowDdlOptions(
                  workflowData.status,
                  currentUser,
                  workflowData.associatedWorkflows
                )}
                Dropdown
              />
            )}
            {!workflowIdProp && isResolved && (
              <PrimaryButton
                large
                title="+ Associated"
                onClick={handleAssocation}
              />
            )}
          </div>

          {!!workflow?.associatedWorkflows?.length && (
            <div className="mb-6">
              {workflow?.associatedWorkflows
                ?.sort((a, b) => a?.association - b.association)
                .map((info) => (
                  <div className="flex flex-row" key={info?.association}>
                    <img
                      src={yellowExclamationIcon}
                      alt="warning"
                      className="mr-2"
                    />
                    <h3 className="flex flex-row text-gray-200">
                      {info?.association === "contingent" && "Blocked by: "}
                      {info?.association === "non-contingent" &&
                        "Associated with: "}
                      {info?.association === "parent" && "Parent: "}
                      <BaseButton
                        title={info?.workflowData?.name}
                        colorCN="text-brandGreen"
                        onClick={() =>
                          handleViewAssociatedWF(info?.workflowData?.id)
                        }
                      />
                    </h3>
                  </div>
                ))}
            </div>
          )}

          {workflowData.completed && (
            <div className="mt-6 mb-5 flex flex-col bg-gray-100 rounded-md p-6">
              <div className="flex flex-row pb-2">
                <div className="flex flex-row pr-8 border-gray-200 border-r-2">
                  <Avatar
                    name={getFullName(workflowData?.completed?.userData?.name)}
                    id={workflowData?.completed?.userData?.id}
                    isExternalUser
                    className="w-12 h-12 rounded-full"
                    fontClassName="text-xl"
                  />
                  <div className="ml-4">
                    <h3 className="text-lg font-medium text-gray-400">
                      Completed by{" "}
                      <span className="capitalize">{`${getFullName(
                        workflowData?.completed?.userData?.name
                      )}`}</span>
                    </h3>

                    <h4 className="font-semibold">
                      {moment(workflowData?.completed?.date).format(
                        "MMM D, YYYY h:m A"
                      )}
                    </h4>
                  </div>
                </div>
                <div className="flex flex-row flex-1 pl-8">
                  <div>
                    <p className=" text-gray-400 flex pr-28">
                      {workflowData?.completed?.note}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-between w-5/6 mb-5">
            <div className="flex">
              <Avatar
                className="w-12 h-12 rounded-full mr-2"
                fontClassName="text-xl"
                name={getFullName(workflowData?.metadata?.userData?.name)}
                avatar={workflowData?.metadata?.userData?.avatar}
                id={workflowData?.metadata?.userData?.id}
                isExternalUser
              />
              <DateAndName workflowData={currentVersion} />
            </div>

            <div className="flex flex-col">
              <h3 className="text-gray-400 font-medium text-lg ml-4">
                Association
              </h3>
              <ResourceDropDown
                resource={
                  workflowData.property ||
                  workflowData.project ||
                  workflowData.company
                }
                showTextView
              />
            </div>

            <div className="flex flex-col">
              <h3 className={headerTitleCN(isOverdue)}>Duration</h3>
              <h3 className={headerSubTitleCN}>
                {`${moment(workflowData?.startDate?.date)?.format(
                  "MMM D, YYYY"
                )} - ${moment(workflowData.endDate)?.format("MMM D, YYYY")}`}
              </h3>
              <h3 className={overdueTitleCN(isOverdue)}>
                {getRemainingDayText()}
              </h3>
            </div>
            {/* DISTRO LIST - commented out for future use */}
            {/*             <div className="flex flex-col">
              <h3 className={headerTitleCN}>Distro List</h3>
              <div className="flex flex-row">
                {workflowData?.members?.map((info) => (
                  <Avatar
                    name={getFullName(info?.name)}
                    avatar={info?.avatar}
                    id={info?.id}
                    isExternalUser
                    className="w-2 h-2 mr-2 rounded-full"
                  />
                ))}
              </div>
            </div> */}
          </div>

          {!!workflowData.documents?.length && (
            <div className="flex flex-col">
              <h3 className={headerTitleCN}>Attachments</h3>
              <div className="flex">
                {workflowData.documents
                  ?.filter((doc) => doc.reference)
                  .map((doc) => {
                    return (
                      <div key={doc.id} className="mb-2 mr-3">
                        <FileCard
                          {...doc}
                          docType={getDocType(doc.docType)}
                          createdAt={
                            doc.metadata?.createdAt
                              ? moment(doc.metadata.createdAt).format(
                                  "MM/DD/YYYY"
                                )
                              : "--"
                          }
                          isFavorited={favorites.some((fav) =>
                            fav.item.reference.includes(doc.id)
                          )}
                          style={fileCardMiniStyle}
                          onFavoriteClick={() => handleFavoriteClick(doc.id)}
                          onFileDelete={() => handleDeleteFile(doc.id)}
                          onFileClick={() => {
                            setClickedDoc(doc);
                            handleFileClick();
                          }}
                          onDownloadFile={() => handleFileDownload(doc)}
                          isMiniature
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          )}

          <TabbedContainer
            tabs={tabSections}
            activeIndex={tabIndex}
            onTabClick={setTabIndex}
          />

          {selectedDocumentUrl && (
            <PDFViewerModal
              isOpen
              onClose={() => setSelectedDocumentUrl(null)}
              initialUrl={selectedDocumentUrl}
            />
          )}

          <ReactModal
            style={modalStyles}
            isOpen={showFileModal}
            onRequestClose={() => setShowFileModal(false)}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc={false}
          >
            <WorkflowFileView
              handleAddFiles={handleAddFiles}
              data={[]}
              workflowData={workflowData}
              handlePostComment={handlePostComment}
              handlePostReply={handlePostReply}
              currentDoc={selectedFile}
              expanded={expanded}
              onSetExpanded={setExpanded}
              onCloseExternalModal={() => setShowFileModal(false)}
              onDownload={handleFileDownload}
              hideComments
            />
          </ReactModal>

          <Modal
            isOpen={associatedModal}
            title="CREATE ASSOCIATED WORKFLOW"
            onRequestModalClose={handleAssociatdModalClose}
            primaryButtonTitle="Next"
            primaryButtonOnClick={handleCreateAssociatedWF}
            disabled={!associatedWF}
            tertiaryButtonTitle="Cancel"
            hideFooter
          >
            <div>
              <p className="pb-4">
                Will this new workflow need to be resolved before{" "}
                <strong>{workflowData.name}</strong> may proceed?
              </p>
              <div className="mb-3">
                <RadioButton
                  label="Yes"
                  isChecked={associatedWF === YESOPTION}
                  onChange={() => setAssociatedWF(YESOPTION)}
                />
              </div>
              <RadioButton
                label="No"
                isChecked={associatedWF === NOOPTION}
                onChange={() => setAssociatedWF(NOOPTION)}
              />
            </div>
          </Modal>
          <Modal
            isOpen={completeModal}
            title="COMPLETE WORKFLOW"
            onRequestModalClose={handleResolveModalClose}
            primaryButtonTitle="Submit"
            primaryButtonOnClick={handleResolveWF}
            tertiaryButtonTitle="Cancel"
            hideFooter
            minWidth="400px"
            buttonCnClass="pt-3"
          >
            <div className="mb-5 text-sm">
              <div className="mb-1">
                <RadioButton
                  label="Mark as resolved"
                  isChecked={checked.resolved}
                  onChange={() => handleChecked(RESOLVED_WF)}
                />
              </div>
              <div className="mb-1">
                <RadioButton
                  label="Mark as unresolved"
                  isChecked={checked.unresloved}
                  onChange={() => handleChecked(UNRESOLVED_WF_STEP)}
                />
              </div>
              {/* commented out so as not to perma remove this button as it may be used in the future */}
              {/*               <div className="mb-1">
                <RadioButton
                  label="Request for resubmission"
                  isChecked={resolveOption === RESOLVED_WF}
                  onChange={() => setResolveOption(RESOLVED_WF)}
                />
              </div> */}
              <Input
                isTextarea
                className="mt-3"
                label="Comments"
                placeholder="Comments"
                value={resolvedNote}
                onChange={setResolvedNote}
              />
            </div>
          </Modal>
          <Modal
            isOpen={finDocPromptModal}
            title="RESOLVE WORKFLOW"
            onRequestModalClose={handleResolveModalClose}
            primaryButtonTitle="Complete"
            primaryButtonOnClick={handleResolveWFWithFinDoc}
            tertiaryButtonTitle="Cancel"
            hideFooter
            minWidth="400px"
            buttonCnClass="pt-3"
          >
            <div className="flex">
              <p>
                Resolving this workflow can not be undone, the financial
                document{" "}
                <span className="font-medium">
                  {workflow?.documents?.[0]?.name}
                </span>{" "}
                will become locked and ready to be merged into the project
                budget. Are you sure you want to resolve?
              </p>
            </div>
          </Modal>
          <DeleteModal
            isOpen={isDeleteModalOpen}
            onDelete={handleModalDelete}
            onClose={() => setIsDeleteModalOpen(false)}
            title="Delete Workflow?"
            text={`By Deleting this Workflow you're also deleting all of the related Tasks, are you sure? This action cannot be undone.`}
          />
        </>
      )}
      {(isLoading || isSaving) && (
        <div className="flex justify-center items-center w-full h-full">
          <Spinner notFullScreen />
        </div>
      )}
    </div>
  );
};

WorkflowView.propTypes = {
  workflowId: PropTypes.string,
};

WorkflowView.defaultProps = {
  workflowId: undefined,
};

export default WorkflowView;

import { NotificationAPI } from "@griffingroupglobal/eslib-api";
import moment from "moment";

const getNotifications = async (previousNotifications = [], lastUpdated) => {
  const notifications = [];
  const start = lastUpdated || moment().startOf("day").add(-7, "days").format();
  const encodedStart = encodeURIComponent(start);

  const { data } = await NotificationAPI.getWOP(`?start=${encodedStart}`);

  if (!data?.entries) {
    return previousNotifications;
  }

  data.entries.forEach((notif) => {
    if (notif.resource.target.split("/")[0] !== "messages") {
      notifications.push({
        id: notif.resource.id,
        reference: notif.resource.reference,
        createdBy: notif.resource.target.split("/")[0],
        created: notif.resource.dateTime,
        title: notif.resource.title,
        text: notif.resource.body,
        type: notif.resource.resource,
        target: notif.resource.target,
        read: notif.resource.status === "read",
        flagged: Boolean(notif.resource.flagged),
        lastUpdated: notif.resource.metadata.createdAt,
      });
    }
  });

  const mergedNotifications = [...previousNotifications, ...notifications];

  // Sort notifications from newest to oldest and set the index
  return mergedNotifications
    .sort((a, b) => (a.created > b.created ? -1 : 1))
    .map((notification, index) => ({ ...notification, index }));
};

export default getNotifications;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import { isEqual } from "lodash";
import Widget from "../Widget/Widget";
import Address from "../AddressInput/Address";
import BillingAddress from "../AddressInput/BillingAddress";
import Checkbox from "../Checkbox/Checkbox";

const textCN = cntl`
  text-gray-450 
  text-sm
  font-normal
  flex
`;

const AddressWidget = ({ value: contact, loading, isEditing, setValue }) => {
  const [mailingSameAsPhysical, setMailingSameAsPhysical] = useState();

  const firstPhysical = useMemo(() => {
    return contact?.addresses?.find((item) => item?.use === "Physical");
  }, [contact?.addresses]);
  const firstMailing = useMemo(() => {
    return contact?.addresses?.find((item) => item?.use === "Mailing");
  }, [contact?.addresses]);

  const handleMailingSameAsPhysicalInput = useCallback(
    (val) => {
      if (val) {
        const physical = contact?.addresses?.findIndex(
          (item) => item?.use === "Physical"
        );
        setValue((prev) => ({
          ...prev,
          addresses: [
            { ...contact?.addresses?.[physical] },
            { ...contact?.addresses?.[physical], use: "Mailing" },
          ],
        }));
      } else {
        setValue((prev) => ({
          ...prev,
        }));
      }
    },
    [contact?.addresses, setValue]
  );

  useEffect(() => {
    // Makes sure you aren't currently editing address
    // Then checks to make sure both are empty ex. new contact or no current address
    // so initial state of checkbox is true and if they are matching after save, it updates
    if (isEditing) return;
    if (
      isEqual(contact?.addresses?.[0], {
        ...contact?.addresses?.[1],
        use: "Physical",
      }) ||
      (!contact?.addresses?.[0] && !contact?.addresses?.[1])
    ) {
      setMailingSameAsPhysical(true);
    } else {
      setMailingSameAsPhysical(false);
    }
  }, [contact?.addresses, isEditing]);

  /**
   * @param {Array} val Expecting array addresses[]
   * Handles changing addresses array in contactInfo {}
   */
  const onChange = useCallback(
    (val) => {
      // Check for Undefined address2 field -> if patch and no address2 field backend does not return it
      // This makes it difficult to compare and keep track of the address being === or !==
      const newVal = val?.map((item) => {
        const newAddress = item;
        if (item?.street2 === undefined) {
          newAddress.street2 = "";
        }
        return newAddress;
      });

      if (newVal?.length) {
        setMailingSameAsPhysical(
          isEqual(newVal?.[0], {
            ...newVal?.[1],
            use: "Physical",
          })
        );
      }

      setValue((prev) => {
        return {
          ...prev,
          addresses: val,
        };
      });
    },
    [setValue]
  );

  const addresses = useMemo(() => {
    if (isEditing) {
      return (
        <BillingAddress
          address={contact?.addresses}
          mailingSameAsPhysical={mailingSameAsPhysical}
          setMailingSameAsPhysical={setMailingSameAsPhysical}
          onChange={onChange}
          handleMailingSameAsPhysicalInput={handleMailingSameAsPhysicalInput}
          isEditing={isEditing}
        />
      );
    }
    if (!mailingSameAsPhysical) {
      return (
        <>
          <div
            className={`flex w-full ${"border-b"} border-gray-450 border-opacity-50 pb-4`}
            key={`Address-${firstPhysical?.street ?? firstPhysical?.zipCode}`}
          >
            <h3 className="font-bold text-gray-450 text-md w-1/4">
              Physical Address
            </h3>
            <Address address={firstPhysical} />
          </div>
          <div className="flex w-full border-gray-450 border-opacity-50 pt-4">
            <h3 className="font-bold text-gray-450 text-md w-1/4">
              Mailing Address
            </h3>
            {firstMailing ? (
              <Address address={firstMailing} />
            ) : (
              <p className="text-gray-450 text-md font-normal not-italic hover:underline cursor-pointer">
                {" "}
                No mailing address
              </p>
            )}
          </div>
        </>
      );
    }

    return (
      <>
        {!firstMailing && !firstPhysical ? null : (
          <>
            <div className="flex w-full border-b border-gray-450 border-opacity-50 pb-4">
              <h3 className="font-bold text-gray-450 text-md w-1/4">
                {`${contact?.addresses?.[0]?.use} Address`}
              </h3>
              <Address address={contact?.addresses?.[0]} />
            </div>
            <div className="flex w-full pt-4">
              <h3 className="font-bold text-gray-450 text-md w-1/4">
                Mailing Address
              </h3>
              <Checkbox disabled checked className="pr-2" />
              <p className={textCN}>Same As Physical Address</p>
            </div>
          </>
        )}
      </>
    );
  }, [
    isEditing,
    mailingSameAsPhysical,
    firstMailing,
    firstPhysical,
    contact?.addresses,
    onChange,
    handleMailingSameAsPhysicalInput,
  ]);

  return (
    <Widget
      draggable={false}
      title="Address"
      className="mb-4"
      loading={loading}
    >
      <div className="relative">{addresses}</div>
    </Widget>
  );
};

AddressWidget.propTypes = {
  value: PropTypes.shape({
    addresses: PropTypes.arrayOf(
      PropTypes.shape({
        use: PropTypes.bool,
      })
    ),
    contactType: PropTypes.string,
    description: PropTypes.string,
  }),
  loading: PropTypes.bool,
  isEditing: PropTypes.bool,
  setValue: PropTypes.func,
};

AddressWidget.defaultProps = {
  value: undefined,
  loading: true,
  isEditing: false,
  setValue: () => {},
};

export default AddressWidget;

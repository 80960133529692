import { ExpenseAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { expenseKeys } from "../config/reactQuery/queryKeyFactory";
import { toastError, toastMessage } from "../stories/Components/Toast/Toast";

const fetchExpenses = async (params) => {
  const { data } = await ExpenseAPI.get({
    params,
  });
  const expenses = data?.entries?.map(({ resource }) => resource);

  const expensesDict = expenses.reduce(
    (acc, v) => ({ ...acc, [v.reference]: v }),
    {}
  );

  return {
    expenses,
    expensesDict,
  };
};
/**
 * Query hook to fetch Expenses
 * @returns query hook
 */
export const useGetExpenses = (assetId, projectId, propertyId) => {
  let queryKey = expenseKeys.expenses;
  let association;
  let params;

  if (propertyId) {
    association = `Property/${propertyId}`;
    queryKey = expenseKeys.expensesByAssociation(association);
    params = { association };
  }
  if (projectId) {
    association = `Project/${projectId}`;
    queryKey = expenseKeys.expensesByAssociation(association);
    params = { association };
  }
  if (assetId) {
    const asset = `Asset/${assetId}`;
    queryKey = expenseKeys.expensesByAssociation(asset);
    params = { asset };
  }

  return useAuthenticatedQuery({
    queryKey,
    queryFn: () => fetchExpenses(params),
    onError: (error) => {
      console.error("Error fetching Expenses", error);
    },
  });
};

const postExpense = async (expense) => {
  const { data } = await ExpenseAPI.post(expense);
  return data;
};

/**
 * Query mutation hook to create Expenses
 * @returns mutation hook
 */
export const useCreateExpense = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postExpense,
    onSuccess: () => {
      toastMessage("Successfully created expense");
      queryClient.invalidateQueries(expenseKeys.expenses);
    },
    onError: (error) => {
      console.error("Error creating expense", error);
      toastError(
        `Error creating expense ${error?.response?.data?.issues[0]?.location}`
      );
    },
  });
};

const deleteExpenses = async (expenseRefs) => {
  await Promise.all(
    expenseRefs?.map((ref) => ExpenseAPI.delete(ref?.split("/")[1]))
  );

  return expenseRefs;
};

/**
 * Mutation hook to remove single/multiple Expenses
 * @returns mutation hook
 */
export const useRemoveExpenses = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteExpenses,
    onSuccess: () => {
      toastMessage("Successfully deleted expense");
      queryClient.invalidateQueries(expenseKeys.expenses);
    },
    onError: (error) => {
      console.error("Error deleting Expense(s)", error);
      toastError(`Error deleting selected expense(s)`);
    },
  });
};

const patchExpense = async ({ prevExpense, updatedExpense }) => {
  const { data } = await ExpenseAPI.patch(
    prevExpense?.id,
    updatedExpense,
    prevExpense
  );

  return data;
};

/**
 * Mutation hook to remove single/multiple Expenses
 * @returns mutation hook
 */
export const useUpdateExpense = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchExpense,
    onSuccess: () => {
      toastMessage("Successfully updated expense");
      queryClient.invalidateQueries(expenseKeys.expenses);
    },
    onError: (error) => {
      console.error("Error updating Expense", error);
      toastError(
        `Error updating expense: ${error?.response?.data?.issues[0]?.location}`
      );
    },
  });
};

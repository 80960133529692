import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "../../../fonts.css";
import { formatPhoneNumber } from "../../../helpers/Formatters";
import BaseButton from "../Buttons/BaseButton";
import TrashButton from "../Buttons/TrashButton";
import CrossButton from "../CrossButton/CrossButton";
import VisibilityButton from "../VisibilityButton/VisibilityButton";

const containerCN = (className) => cntl`
  ${className === "noGrow" ? "flex-0" : "flex-1"} 
  min-w-0
  ${className}
`;

const labelCN = (className) => cntl`
  ${className || "ESInputLabel"}
  flex
`;
const mainWrapperCN = (mainWrapperClassName) => cntl`
  ${mainWrapperClassName ?? ""}
`;
const wrapperCN = (mainClassName) => cntl`
  flex 
  items-center
  w-full
  show-tooltip
  ${mainClassName}
`;
const inputContainerCN = (
  hasError,
  isTextarea,
  disabled,
  disableClear,
  isFocused,
  wasFocused,
  className,
  errorBorder,
  size
) => cntl`
  ${size ? `ESInputContainer-${size}` : "ESInputContainer flex-1 min-w-0"}
  flex
  relative
  overflow-hidden
  ${hasError && !isFocused && wasFocused ? "border-brandRed" : ""}
  ${errorBorder ? "border-brandRed" : ""}
  ${!hasError && !disabled && !isFocused ? "border-gray-150" : ""}
  ${isTextarea && cntl`ESTextArea`}
  ${disabled ? "bg-gray-100 border-gray-100" : ""}
  ${className}
  `;

const inputCN = (hasIcon) => cntl`
  ESInput
  ${hasIcon && cntl`pl-8`}
`;

const errorMessageCN = (errorAtBottom, className) => cntl`
capitalize-first
text-xs
${
  errorAtBottom
    ? "text-brandRed italic opacity-70 absolute top-100 pt-1"
    : "text-brandRed italic ml-2"
}
${className}
`;

const dollarCharCN = (className) => cntl`
self-center
${className}
`;

const Input = ({
  toggleFocusRef,
  placeholder,
  label,
  inlineLabel,
  icon,
  attachIcon,
  onAttachIconClick,
  className,
  mainClassName,
  inputContainerClassName,
  labelClassName,
  errorMessageClassName,
  currencyCharClassName,
  onChange,
  onKeyPress,
  value,
  isTextarea,
  type,
  disableClear,
  validation,
  autoFocus,
  onBlur,
  onFocus,
  disabled,
  tooltipElement,
  toggleVisibility,
  isDollarValue,
  isPercentageValue,
  isPhoneNumber,
  showValidationErrorAtBottom,
  alwaysShowError,
  subText,
  inputClassName,
  onRemove,
  forwardedRef,
  handleEnter,
  name,
  id,
  shiftSymbol,
  minValue,
  autoExpandHeight,
  title,
  errorBorder,
  onKeyDown,
  size,
  resizable,
  tabIndex,
  customErrorMessage,
  activeIcon,
  mainWrapperClassName,
  step,
  leftInlineInputComponent,
}) => {
  const [errorMessage, setErrorMessage] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [wasFocused, setWasFocused] = useState(false);

  useEffect(() => {
    // Check if autoExpandHeight or isTextarea is false, and return if true
    if (!autoExpandHeight || !isTextarea) return;

    // Function to automatically adjust textarea height
    const autosize = () => {
      // Get the textarea element
      const el = document.querySelector("textarea");

      // Set a timeout to allow the browser to update before calculating the new height
      const timeoutId = setTimeout(() => {
        // Reset the CSS properties for height and padding
        el.style.cssText = "height:auto; padding:0";

        // Set the textarea's height to its scrollHeight plus some extra padding (18 pixels in this case)
        el.style.cssText = `height:${el.scrollHeight + 18}px`;

        // Clear the timeout to prevent memory leaks
        clearTimeout(timeoutId);
      }, 0);
    };

    // Get the textarea element
    const textarea = document.querySelector("textarea");

    // Attach the autosize function to the "keydown" event of the textarea
    textarea.addEventListener("keydown", autosize);

    // Cleanup function to remove the event listener when the component unmounts
    // eslint-disable-next-line consistent-return
    return () => {
      // Remove the "keydown" event listener when component is removed
      // from dom to prevent memory leaks
      textarea.removeEventListener("keydown", autosize);
    };
  }, [autoExpandHeight, isTextarea]);

  useEffect(() => {
    const validate = async () => {
      if (validation) {
        try {
          await validation.validate(value);
          // validation passed
          setErrorMessage(undefined);
        } catch (err) {
          setErrorMessage(err.message);
        }
      } else {
        setErrorMessage(undefined);
      }
    };

    validate();
  }, [validation, value]);

  useEffect(() => {
    // Check if toggleFocusRef is undefined or null, and return if true
    if (!toggleFocusRef?.current) return;

    // Get the length of the current value in the input element
    const valueLength = toggleFocusRef.current.value.length;

    // Focus on the input element
    toggleFocusRef.current.focus();

    // Set the selection range for the input element to the end of its content
    toggleFocusRef.current.setSelectionRange(valueLength, valueLength);
  }, [toggleFocusRef, isTextarea]);

  const onInputChange = ({ target: { value: newValue } }) => {
    onChange(isPhoneNumber ? formatPhoneNumber(newValue) : newValue);
  };

  const onClearClick = () => {
    onChange("");
  };

  const CustomTag = `${isTextarea ? "textarea" : "input"}`;
  const handleBlur = () => {
    setIsFocused(false);
    if (!wasFocused) {
      setWasFocused(true);
    }
  };

  const showTopError =
    (errorMessage || customErrorMessage) &&
    wasFocused &&
    !label &&
    !inlineLabel &&
    !showValidationErrorAtBottom;

  return (
    <div className={mainWrapperCN(mainWrapperClassName)}>
      {showTopError && (
        <p className={errorMessageCN(errorMessageClassName)}>
          {customErrorMessage} {errorMessage}
        </p>
      )}
      <div className={wrapperCN(mainClassName)} title={title}>
        {(label || errorMessage) && inlineLabel ? (
          <div className={labelCN(labelClassName)}>
            {label && (
              <div className="flex">
                <p>{label}</p>
                <p
                  className={`text-brandGreen text-sm ${
                    !validation?.exclusiveTests?.required && "invisible"
                  }`}
                >
                  *
                </p>
              </div>
            )}
            {!!errorMessage && wasFocused && !showValidationErrorAtBottom && (
              <p
                className={errorMessageCN(
                  showValidationErrorAtBottom,
                  errorMessageClassName
                )}
              >
                {errorMessage}
              </p>
            )}
          </div>
        ) : null}
        <div
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
          className={containerCN(className)}
        >
          {(label || errorMessage) && !inlineLabel ? (
            <div className={labelCN(labelClassName)}>
              {label && (
                <div className="flex">
                  <p>{label}</p>
                  <p
                    className={`text-brandGreen text-sm ${
                      !validation?.exclusiveTests?.required && "invisible"
                    }`}
                  >
                    *
                  </p>
                </div>
              )}
              {!!errorMessage &&
                label &&
                (alwaysShowError || wasFocused) &&
                !showValidationErrorAtBottom && (
                  <p
                    className={errorMessageCN(
                      showValidationErrorAtBottom,
                      errorMessageClassName
                    )}
                  >
                    {errorMessage}
                  </p>
                )}
            </div>
          ) : null}
          <div
            className={`flex flex-row show-tooltip relative${
              isDollarValue && shiftSymbol ? "-ml-4" : ""
            }`}
          >
            {isDollarValue && (
              <div
                className={dollarCharCN(currencyCharClassName)}
                style={{
                  marginRight: "6px",
                }}
              >
                <p className="text-gray-200">$</p>
              </div>
            )}
            <div
              className={inputContainerCN(
                !!errorMessage || !!customErrorMessage,
                isTextarea,
                disabled,
                disableClear,
                isFocused,
                wasFocused,
                inputContainerClassName,
                errorBorder,
                size
              )}
            >
              {leftInlineInputComponent}
              <CustomTag
                aria-label={label}
                style={{
                  background: `url(${icon}) no-repeat 10px scroll 11px`,
                  minHeight: isTextarea ? "6.2rem" : "",
                  resize: resizable ? undefined : "none",
                  position: "relative",
                }}
                className={inputClassName || inputCN(!!icon)}
                placeholder={placeholder}
                type={
                  // eslint-disable-next-line no-nested-ternary
                  toggleVisibility ? (isVisible ? "text" : "password") : type
                }
                onChange={onInputChange}
                value={isPhoneNumber ? formatPhoneNumber(value) : value}
                onKeyPress={onKeyPress}
                onKeyDown={onKeyDown}
                autoFocus={autoFocus}
                onBlur={onBlur}
                onFocus={onFocus}
                disabled={disabled}
                ref={forwardedRef || toggleFocusRef}
                onKeyUp={handleEnter}
                name={name}
                id={id}
                min={minValue}
                tabIndex={tabIndex}
                step={step}
              />
              {attachIcon && (
                <BaseButton
                  iconRight={
                    <img
                      className="w-5 h-5 mr-2 self-end"
                      src={attachIcon}
                      alt="attach file"
                    />
                  }
                  onClick={onAttachIconClick}
                />
              )}

              {activeIcon && (
                <div className="flex flex-col h-full justify-start mt-1">
                  <img
                    className="w-2 mr-1"
                    src={activeIcon}
                    alt="active input"
                  />
                </div>
              )}

              {toggleVisibility && (
                <div className="flex pr-2">
                  <VisibilityButton
                    isVisible={isVisible}
                    onClick={() => setIsVisible((prev) => !prev)}
                  />
                </div>
              )}
            </div>
            {isPercentageValue && <p className="pt-2 pl-1 text-gray-200">%</p>}
            {onRemove && <TrashButton onClick={!disabled && onRemove} />}

            {tooltipElement && (
              <div
                className="absolute rounded-xxl pb-4"
                style={{ bottom: "100%" }}
              >
                <div className="text-xs inline-block whitespace-normal overflow-ellipsis">
                  {tooltipElement}
                </div>
              </div>
            )}
            {!onRemove && !disableClear ? (
              <div
                className="flex justify-end px-2"
                style={{
                  background: "white",
                }}
              >
                <CrossButton
                  className={
                    disableClear || !value?.length ? "w-3 hidden" : "p-1 m-auto"
                  }
                  onClick={onClearClick}
                />
              </div>
            ) : null}
          </div>
          {!!errorMessage &&
            (alwaysShowError || wasFocused) &&
            showValidationErrorAtBottom && (
              <p
                className={errorMessageCN(
                  showValidationErrorAtBottom,
                  errorMessageClassName
                )}
              >
                {errorMessage}
              </p>
            )}
          {subText && <p className="text-xs py-3 text-gray-300">{subText}</p>}
        </div>
      </div>
    </div>
  );
};

Input.propTypes = {
  inlineLabel: PropTypes.bool,
  /**
   * text that appears in the textbox when no user-entered text is preset
   */
  placeholder: PropTypes.string,
  /**
   * text that appears above the input box
   */
  label: PropTypes.string,
  /**
   * any CSS classes to apply to the container
   */
  className: PropTypes.string,
  /**
   * any CSS classes to apply to the main container
   */
  mainClassName: PropTypes.string,
  /**
   * custom on top error message
   */
  customErrorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * any CSS classes to apply to label of the container
   */
  labelClassName: PropTypes.string,
  /**
   * any CSS classes to apply to error message of the container
   */
  errorMessageClassName: PropTypes.string,
  /**
   * any CSS classes to apply to $ character of the container
   */
  currencyCharClassName: PropTypes.string,
  /**
   * function called when the input value changes, provides the input value
   */
  onChange: PropTypes.func,
  /**
   * function called when a key is pressed
   */
  onKeyPress: PropTypes.func,
  /**
   * applies this value to the input
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * url to an image to display on the left side of the input
   */
  icon: PropTypes.string,
  /**
   * if true, sets the html tag to textarea
   */
  isTextarea: PropTypes.bool,
  /**
   * the input type
   */
  type: PropTypes.string,
  /**
   * if true, prevents X from appearing for resetting the value to an empty string
   */
  disableClear: PropTypes.bool,
  /**
   * validation schema using yup
   */
  // eslint-disable-next-line react/forbid-prop-types
  validation: PropTypes.object,
  /**
   * if true, focuses the input on mount
   */
  autoFocus: PropTypes.bool,
  /**
   * function called when input is no longer being focused
   */
  onBlur: PropTypes.func,
  /**
   * function called when input is being focused
   */
  onFocus: PropTypes.func,
  /**
   * if true, input is disabled
   */
  disabled: PropTypes.bool,
  tooltipElement: PropTypes.element,
  leftInlineInputComponent: PropTypes.element,
  toggleVisibility: PropTypes.bool,
  isDollarValue: PropTypes.bool,
  isPercentageValue: PropTypes.bool,
  isPhoneNumber: PropTypes.bool,
  inputContainerClassName: PropTypes.string,
  showValidationErrorAtBottom: PropTypes.bool,
  /**
   * Always show input error vs show on focus
   */
  alwaysShowError: PropTypes.bool,
  subText: PropTypes.string,
  inputClassName: PropTypes.string,
  onRemove: PropTypes.func,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ current: PropTypes.any }),
  ]),

  /**
   * toggleFocusRef is a Special use case when an input can switch to a textarea during typing
   */
  toggleFocusRef: PropTypes.oneOfType([
    PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  handleEnter: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shiftSymbol: PropTypes.bool,
  minValue: PropTypes.string,
  attachIcon: PropTypes.string,
  onAttachIconClick: PropTypes.func,
  autoExpandHeight: PropTypes.bool,
  title: PropTypes.string,
  errorBorder: PropTypes.bool,
  onKeyDown: PropTypes.func,
  /**
   * sm, md, or lg
   */
  size: PropTypes.string,
  /**
   * allow manually drag input to change the size
   */
  resizable: PropTypes.bool,
  /**
   * Set tab index (used to make item skippable when tabbing)
   */
  tabIndex: PropTypes.number,
  activeIcon: PropTypes.element,
  mainWrapperClassName: PropTypes.string,
  step: PropTypes.string,
};

Input.defaultProps = {
  inlineLabel: false,
  placeholder: undefined,
  label: undefined,
  className: undefined,
  mainClassName: undefined,
  labelClassName: undefined,
  errorMessageClassName: undefined,
  currencyCharClassName: undefined,
  onChange: null,
  onKeyPress: null,
  value: "",
  icon: null,
  isTextarea: false,
  type: undefined,
  disableClear: true,
  validation: undefined,
  customErrorMessage: undefined,
  autoFocus: false,
  onBlur: undefined,
  onFocus: undefined,
  disabled: false,
  tooltipElement: undefined,
  leftInlineInputComponent: undefined,
  toggleVisibility: false,
  isDollarValue: false,
  isPercentageValue: false,
  isPhoneNumber: false,
  inputContainerClassName: undefined,
  showValidationErrorAtBottom: false,
  alwaysShowError: false,
  subText: undefined,
  inputClassName: undefined,
  onRemove: undefined,
  forwardedRef: undefined,
  toggleFocusRef: undefined,
  handleEnter: undefined,
  name: undefined,
  id: undefined,
  shiftSymbol: false,
  minValue: undefined,
  attachIcon: undefined,
  onAttachIconClick: undefined,
  autoExpandHeight: false,
  title: undefined,
  errorBorder: undefined,
  onKeyDown: undefined,
  size: undefined,
  resizable: true,
  tabIndex: 0,
  activeIcon: undefined,
  mainWrapperClassName: undefined,
  step: undefined,
};

export default Input;

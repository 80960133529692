import moment from "moment-timezone";
import { calendarYOffset, PRIMARY_DARK_GREEN, TASK_BLUE } from "../constants";
import { CustomError } from "./Error";

/**
 * @description - Components not wrapped in react-router-dom switch do not pull back params
 * @returns - Associated Reference from non router switch components
 */

// takes in moment object and returns the nearest half hour rounded up
export const roundToNearestHalfHour = (time) => {
  const remainder = time.minute() % 30;

  // If time is already on the half-hour mark
  if (remainder === 0) {
    return time;
  }

  const minutesToAdd = 30 - remainder;
  return time.add(minutesToAdd, "minutes");
};

export const adjustToCurrentTime = (date) => {
  // Get current time
  const now = moment();

  // Extract hour and minute
  const currentHour = now.hour();
  const currentMinute = now.minute();

  // Calculate the minutes to add for rounding up to the nearest half hour
  const remainder = currentMinute % 30;
  const minutesToAdd = remainder === 0 ? 0 : 30 - remainder;

  // Set the adjusted hour and minute to the date
  return date
    .hour(currentHour)
    .minute(currentMinute)
    .add(minutesToAdd, "minutes");
};

export const isSameWeek = (right) => {
  const now = moment().startOf("day").day(0).format();
  return now === moment(right).day(0).startOf("day").format();
};

export const isTomorrow = (right) => {
  const now = moment().startOf("day").add(1, "days").format();
  return moment(right).startOf("day").format() === now;
};

export const isToday = (right) => {
  const now = moment().startOf("day").format();
  return moment(right).startOf("day").format() === now;
};

export const taskDueDate = (right) => {
  const pastDue = () => {
    const now = moment().startOf("day");
    const due =
      ((moment(right).startOf("day").toDate() - now.toDate()) / 60000 / 60) *
      -1;
    // if due is greater than 24 hours, return days rounded to nearest integer
    if (due >= 24) {
      return `${Math.round(due / 24)} days ago`;
    }
    // if due is less than 24 hours, return hours rounded to nearest integer
    if (due < 24 && due > 0) {
      return `${Math.round(due)} hours ago`;
    }
    return false;
  };

  const notDue = () => {
    const now = moment().startOf("day");
    const due =
      (moment(right).startOf("day").toDate() - now.toDate()) / 60000 / 60;
    // if due is greater than 24 hours, return days rounded to nearest integer
    if (due >= 24) {
      return `${Math.round(due / 24)} days`;
    }
    // if due is less than 24 hours, return hours rounded to nearest integer
    return `${Math.round(due)} hours`;
  };

  switch (true) {
    case isToday(right):
      return "Today";
    case !!pastDue(right):
      return pastDue(right);
    case isTomorrow(right):
      return "Tomorrow";
    case isSameWeek(right):
      return moment(right).format("dddd");
    default:
      return notDue(right);
  }
};

export function percentageOfDayElapsed() {
  const today = moment().valueOf();

  const startOfDay = moment().startOf("day").valueOf();

  const lengthOfDay = 1000 * 60 * 60 * 24;
  return parseFloat(((today - startOfDay) / lengthOfDay).toFixed(2));
}

export function eventColor({ resource }) {
  switch (true) {
    case resource === "Task":
      return TASK_BLUE;
    default:
      return PRIMARY_DARK_GREEN;
  }
}

export const formatEventDate = (date, format = undefined, timezone) => {
  // if date does not exist or is not a string, throw a browser error
  if (!date || typeof date !== "string")
    throw new CustomError({
      message:
        "Invalid Argument: Must be of type String representing date, to be used with moment",
    });

  // if a moment format is passed in, use that format,
  // otherwise use the default format
  return moment.tz(date, timezone).format(format ?? "MMMM Do");
};

export const isFullDay = (sd, ed, allDay) => {
  if (allDay) {
    return true;
  }

  return (
    moment(sd).format() === moment(sd).startOf("day").format() &&
    moment(ed).format() === moment(ed).endOf("day").format()
  );
};

/**
 *
 * @param {String} left - Iso string starting date
 * @param {String} right - Iso string end date
 * @returns - Number of days including starting date
 */
export function getDaySpan(left, right, calendarTimezone) {
  try {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = moment.tz(left, calendarTimezone);
    const secondDate = moment.tz(right, calendarTimezone);
    if (typeof left !== "string" && typeof right !== "string") {
      throw new CustomError({
        message: "Invalid Arguement: Must be of type String representing date",
      });
    }
    const diffDays = Math.ceil(Math.abs((firstDate - secondDate) / oneDay));
    return diffDays - 1;
  } catch (e) {
    return console.error(e);
  }
}

export function getDaySpanForNonWrappingEvents(event, calendarTimezone) {
  const isFull = isFullDay(
    event.resource.startDate,
    event.resource.endDate,
    event.resource.allDay
  );
  if (isFull) {
    return (
      moment
        .tz(event.resource.endDate, calendarTimezone)
        .diff(moment.tz(event.resource.startDate, calendarTimezone), "days") + 1
    );
  }

  return (
    Math.ceil(
      moment
        .tz(event.resource.endDate, calendarTimezone)
        .diff(
          moment.tz(event.resource.startDate, calendarTimezone),
          "days",
          true
        )
    ) + 1
  );
}

export function getWeek(cur, calendarTimezone) {
  const startOfWeek = moment
    .tz(cur, calendarTimezone)
    .day(-0)
    .subtract(1, "days")
    .startOf("day");
  const week = [];

  for (let i = 0; i < 7; i += 1) {
    const date = startOfWeek.add(1, "days");
    week.push({
      iso: date.format(),
      day: date.date(),
      yearDay: date.format("DDDD"),
      dayName: date.format("ddd"),
      monthName: date.format("MMMM"),
      dayNumber: date.format("DD"),
      trueISO: date.startOf("day").format(),
    });
  }
  return week;
}

export function getWeeks(cur, calendarTimezone) {
  const firstOfTheMonth = moment.tz(cur, calendarTimezone).startOf("Month");

  const month = firstOfTheMonth.month();

  const monthLength = moment.tz(cur, calendarTimezone).endOf("Month").date();

  const firstWeekday = firstOfTheMonth.day();

  const firstCalendarDay = firstOfTheMonth.clone();

  // If first of month is not on sunday, get last sunday of previous month
  if (firstCalendarDay.day() !== 0) {
    firstCalendarDay.subtract(firstCalendarDay.day(), "days");
  }

  // How many weeks are visible on this months calendar
  const weeksVisible =
    (monthLength < 29 && firstWeekday > 0) ||
    (monthLength === 30 && firstWeekday > 5) ||
    (monthLength === 31 && firstWeekday > 4)
      ? Math.ceil(monthLength / 7) + 1
      : Math.ceil(monthLength / 7);

  return Array.from({ length: weeksVisible }, (_, i) => {
    return Array.from({ length: 7 }, (__, j) => {
      const nextDay = firstCalendarDay.clone().add(i * 7 + j, "days");
      const obj = {
        day: nextDay.date(),
        iso: nextDay.format(),
      };
      if (nextDay.month() < month || nextDay.year() < firstOfTheMonth.year()) {
        obj.prev = true;
        return obj;
      }
      if (nextDay.month() > month) {
        obj.next = true;
      }
      return obj;
    });
  });
}

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const calculateEventDuration = (eventStart, eventEnd, offSet = 0) => {
  const dayStart = moment(eventStart).startOf("day");
  const start = moment(eventStart);
  const end = moment(eventEnd);
  const startDifference = (start.diff(dayStart, "minutes") / 1440) * 240;
  const endDifference = (end.diff(dayStart, "minutes") / 1440) * 240;
  const height = endDifference - startDifference;

  return { y: startDifference + offSet, height };
};

export const checkRoot = (root, id) => {
  let exists;
  if (root) {
    exists = root.find((item) => {
      return item.getGroup().find((subItem) => subItem.id === id);
    });
  }

  return exists;
};

export const buildTree = (event, day, level = 0) => {
  const current = {
    ...event,
    ...calculateEventDuration(
      event?.startDate,
      event?.endDate,
      calendarYOffset + 10
    ),
    level,
  };
  current.group = [];
  current.prev = undefined;

  // Returns subtree starting with parent
  // Use to check if node exists when building tree
  current.getGroup = (response = []) => {
    let res = [...response];
    if (current.group.length > 0) {
      current.group.forEach((entry) => {
        res.push(entry);
        res = [...entry.getGroup(res)];
      });
    }
    return res;
  };

  day.forEach((item, index) => {
    const checkedEvent = {
      ...item,
      ...calculateEventDuration(
        item?.startDate,
        item?.endDate,
        calendarYOffset + 10
      ),
    };
    if (
      current.y <= checkedEvent.y &&
      checkedEvent.y <
        current.y + (current.height === 0 ? 10 : current.height) &&
      current.id !== checkedEvent.id &&
      !checkRoot([current], checkedEvent.id)
    ) {
      current.group.push(
        buildTree(
          { ...checkedEvent, prev: current },
          day.slice(index),
          level + 1
        )
      );
    }
  });

  return current;
};

export const getRootDepth = (group) => {
  let depth = 0;
  group.forEach((item) => {
    if (item.level > depth) {
      depth = item.level;
    }
  });
  return depth ? depth + 1 : depth;
};

export const getPreviousNodePath = (child, response = []) => {
  const res = [...response, child];
  if (child.prev) {
    return getPreviousNodePath(child.prev, res);
  }
  return res;
};

export const isAllDay = (eventStart, eventEnd) => {
  const start = moment(eventStart);
  const end = moment(eventEnd);
  const duration = moment.duration(end.diff(start));

  return duration.asHours() > 23.5 && duration.asHours();
};

const eventWipeCheck = (day, reference) => {
  const brief = day?.brief?.find(
    (item) => item?.reference === reference && !item?.recurrence
  );
  const allDay = day?.allDay?.find(
    (item) => item?.reference === reference && !item?.recurrence
  );

  return allDay || brief;
};

export const wipeEvents = (list, position, reference, direction) => {
  const temp = list;
  const allDay = temp?.[position]?.allDay?.filter(
    (item) => item?.reference !== reference
  );
  const brief = temp?.[position]?.brief?.filter(
    (item) => item?.reference !== reference
  );
  const allDayCount = allDay?.length;
  const nextKey = moment(position).add(direction, "days").format();
  if (eventWipeCheck(temp?.[nextKey], reference)) {
    return wipeEvents(
      {
        ...temp,
        [position]: { brief, allDay, allDayCount },
      },
      nextKey,
      reference,
      direction
    );
  }
  return {
    ...temp,
    [position]: {
      brief: brief ?? [],
      allDay: allDay ?? [],
      allDayCount: allDayCount ?? 0,
    },
  };
};

export const removeObjectById = (id, userEventsObject) => {
  // Create a deep copy of the original object to avoid mutating it
  const userEventsObjectCopied = { ...userEventsObject };

  // Use Object.keys to get the keys of the object and then iterate over them
  Object.keys(userEventsObjectCopied).forEach((date) => {
    // Check and filter the allDay array
    if (userEventsObjectCopied[date].allDay) {
      userEventsObjectCopied[date].allDay = userEventsObjectCopied[
        date
      ].allDay.filter((item) => item.id !== id);
    }

    // Check and filter the brief array
    if (userEventsObjectCopied[date].brief) {
      userEventsObjectCopied[date].brief = userEventsObjectCopied[
        date
      ].brief.filter((item) => item.id !== id);
    }
  });

  return userEventsObjectCopied;
};

export const pushUserEvent = (list, event) => {
  let tempList = list;
  Object.entries(event).forEach(([key, value]) => {
    if (tempList?.[key]) {
      /**
       * Brief Property of Event Date Must be sorted proper function
       */
      const brief = [...tempList?.[key]?.brief, ...value.brief].sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        a.startDate < b.startDate ? -1 : a.startDate > b.startDate ? 1 : 0
      );

      const allDay = [...tempList?.[key]?.allDay, ...value.allDay];

      const allDayCount = tempList?.[key]?.allDayCount + value.allDayCount;

      tempList = {
        ...tempList,
        [key]: { brief, allDay, allDayCount },
      };
    } else {
      tempList = {
        ...tempList,
        [key]: value,
      };
    }
  });

  return tempList;
};

export const handleFormatDate = (dateInfo) => {
  const dateArray = dateInfo.toString().split(" ");
  const day = dateArray[0];
  const month = dateArray[1];
  const year = dateArray[2];
  const dateString = `${day} ${month} ${year}`;
  const startHours = dateInfo.getHours();
  const startMinutes = dateInfo.getMinutes();
  const Time = moment(`${startHours}:${startMinutes}`, "hh:mm").format("LT");

  return { dateString, Time };
};

export const compareDays = (day1, day2) => {
  const currentDay = moment().format("DDDD");
  const currentDayName = moment().format("ddd");
  const dayName = moment(day1).format("ddd");
  const compDay1 = moment(day1).format("DDDD");
  const compDay2 = moment(day2).format("DDDD");

  if (compDay1 === compDay2) {
    return {
      compDay1,
      currentDay,
      dayName,
      currentDayName,
      result: true,
    };
  }

  return false;
};

export const addBusinessdays = (inputDate, days) => {
  let date = moment(inputDate); // use a clone

  while (days > 0) {
    date = date.add(1, "days");
    // decrease "days" only if it's a week/business day.
    if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
      // eslint-disable-next-line no-param-reassign
      days -= 1;
    }
  }
  return date;
};

export const keepRecurrence = (startDate) => {
  if (!startDate) return false;
  const currentYear = new Date().getFullYear();
  const instanceYear = new Date(startDate).getFullYear();
  return +instanceYear <= +currentYear;
};

export const isMultiDayEvent = (startDate, endDate, calendarTimezone) =>
  moment
    .tz(endDate, calendarTimezone)
    .isAfter(
      moment.tz(startDate, calendarTimezone).add(1, "day").startOf("day")
    );

import axios from "axios";
import { AUTH_TOKEN_STORAGE_KEY, TENANT_ID_STORAGE_KEY } from "../constants";
import Auth from "../helpers/Auth";

const { API_HOST } = window.runConfig ?? {};

const MODIFIED_API = API_HOST?.length
  ? API_HOST.replace("api", "signup")
  : "https://signup.es-feature.io";

const axiosInstance = axios.create({
  baseURL: MODIFIED_API,
  headers: {
    "Content-Type": "application/json",
  },
});

let requestInterceptor;
let responseInterceptor;

function SignupInterceptor(setIsSignedIn) {
  // Clean up any existing interceptor
  if (requestInterceptor) {
    axiosInstance.interceptors.request.eject(requestInterceptor);
  }

  requestInterceptor = axiosInstance.interceptors.request.use((request) => {
    const newRequest = { ...request };
    const authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
    newRequest.headers.Authorization = `Bearer ${authToken}`;
    newRequest.headers["Content-Type"] = "application/bsee+json";

    const tenantId = localStorage.getItem(TENANT_ID_STORAGE_KEY);
    newRequest.headers["x-es-tenantid"] = tenantId;
    return newRequest;
  });

  // Clean up any existing interceptor
  if (responseInterceptor) {
    axiosInstance.interceptors.response.eject(responseInterceptor);
  }

  // trigger token refresh / retry on response error
  responseInterceptor = axiosInstance.interceptors.response.use(
    (res) => res,
    async (err) => {
      if (err.response?.status === 401) {
        if (!err.config.isRetry) {
          await Auth.refresh(setIsSignedIn);
          const newConfig = { ...err.config };
          newConfig.isRetry = true;
          return axiosInstance(newConfig);
        }
        setIsSignedIn(false);
        return Promise.reject(Error("token refresh failed"));
      }
      // can be caused by Auth.signOut()
      if (err.response?.status === 405) {
        setIsSignedIn(false);
        return Promise.reject(
          Error(
            `Missing or incorrect x-es-tenantid header in request: ${err.config.url}`
          )
        );
      }
      return Promise.reject(err);
    }
  );
}

export default axiosInstance;
export { SignupInterceptor };

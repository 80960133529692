// APIs
import moment from "moment";
import PropTypes from "prop-types";
// Framework Tools
import React, { useEffect, useState } from "react";
// helpers
import { formatHistoryCardData } from "../../helpers/RequestHelpers";
// Components
import Widget from "../../stories/Components/Widget/Widget";
import RequestHistoryCard from "../../stories/Components/RequestHistoryCard/RequestHistoryCard";
import { useSubmittalHistory } from "../../hooks/useHistory";
import { useAppState } from "../../state/appState";

const SubmittalHistory = ({
  loading,
  attachmentMap,
  commentMap,
  submittal,
}) => {
  const { data: submittalHistory, isLoading } = useSubmittalHistory(
    submittal?.reference
  );
  const [{ userDict }] = useAppState();
  const [historyCards, setHistoryCards] = useState([]);

  useEffect(() => {
    if (submittal && submittalHistory) {
      setHistoryCards(
        submittal?.rounds?.map((data, index) => {
          return formatHistoryCardData(
            data,
            index,
            submittal,
            submittalHistory || {},
            userDict
          );
        })
      );
    }
  }, [submittal, submittalHistory, userDict]);

  return (
    <Widget
      title=""
      draggable={false}
      loading={loading || isLoading}
      hideBorder
    >
      <div className="flex flex-col items-start gap-6">
        {!loading &&
          !isLoading &&
          historyCards
            ?.map((historyCardData) => {
              return (
                <RequestHistoryCard
                  key={historyCardData?.roundNum}
                  start={moment(historyCardData?.durationStart)
                    .startOf("day")
                    .format("MMMM, DD, YYYY")}
                  end={
                    historyCardData?.durationEnd &&
                    moment(historyCardData?.durationEnd)
                      .endOf("day")
                      .format("MMMM, DD, YYYY")
                  }
                  duration={historyCardData?.durationDays}
                  commentMap={commentMap}
                  currentRound={historyCardData?.isCurrentRound}
                  round={historyCardData?.roundNum}
                  users={historyCardData?.roundUsers}
                  attachments={historyCardData?.roundAttachments}
                  actionsByStepMap={historyCardData?.actionsByStepMap}
                  attachmentMap={attachmentMap}
                  initiatedBy={historyCardData?.initiatedBy}
                />
              );
            })
            .reverse()}
      </div>
    </Widget>
  );
};

SubmittalHistory.propTypes = {
  loading: PropTypes.bool,
  attachmentMap: PropTypes.shape({}),
  commentMap: PropTypes.shape({}),
  submittal: PropTypes.shape({
    reference: PropTypes.string,
    rounds: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

SubmittalHistory.defaultProps = {
  loading: true,
  attachmentMap: undefined,
  commentMap: undefined,
  submittal: undefined,
};
export default SubmittalHistory;

import React from "react";
import PropTypes from "prop-types";
import DocumentUploadForm from "../DocumentUploadForm/DocumentUploadForm";
import MediaWidgetImage from "./MediaWidgetImage";
import Spinner from "../Spinner/Spinner";
import { DEFAULT_MEDIA_EXTENSIONS } from "../../../constants";

const MediaWidgetBody = ({
  hasWritePermission,
  images,
  primary,
  addImage,
  resource,
  selectImage,
  selected,
  compileResolved,
  handleOnFinishUpload,
  actionPerforming,
  disableEditing,
  isSop,
  isContact,
  sopStyle,
  sopMediaStyle,
}) => {
  return (
    <div className="flex w-full relative flex-wrap">
      <div
        className={`${
          sopMediaStyle
            ? "flex flex-wrap flex-auto min-w-0 content-start gap-y-8 gap-x-8"
            : "flex flex-wrap flex-1 min-w-0 content-start gap-y-8 gap-x-8 pl-2 items-center"
        }`}
      >
        {!sopMediaStyle && !isContact && hasWritePermission && (
          <div className="flex flex-col flex-initial items-start">
            <DocumentUploadForm
              title={"Drag & Drop\nImage/Video"}
              extensions={DEFAULT_MEDIA_EXTENSIONS}
              onAddPhoto={addImage}
              onFinishUploading={handleOnFinishUpload}
              resource={resource}
              noModal
              disableHooks
              disableEditing={disableEditing}
              isSop={isSop}
              sopStyle={sopStyle}
            />
          </div>
        )}
        {sopMediaStyle && (
          <div className="flex-col">
            {primary && (
              <div>
                <MediaWidgetImage
                  image={primary}
                  containerClassName="flex-auto min-w-0 h-60 justify-center bg-gray-100 mb-3"
                  blur={!!selected?.length || actionPerforming}
                  onClick={selectImage}
                  isPrimary
                  compileResolved={compileResolved}
                />
                <p className="text-center mb-3 font-semiBold">Primary Image</p>
              </div>
            )}

            <DocumentUploadForm
              title="Drag & Drop Image/Video"
              extensions={DEFAULT_MEDIA_EXTENSIONS}
              onAddPhoto={addImage}
              onFinishUploading={handleOnFinishUpload}
              resource={resource}
              noModal
              disableHooks
              disableEditing={disableEditing}
              sopStyle={sopStyle}
            />
          </div>
        )}
        {images?.map((image, i) => {
          return (
            <MediaWidgetImage
              image={image}
              key={`${image?.file}`}
              index={i}
              containerClassName="bg-gray-100"
              styles={sopMediaStyle || {}}
              onClick={selectImage}
              selected={selected?.some((img) => img?.file === image?.file)}
              blur={!!selected?.length || actionPerforming}
              compileResolved={compileResolved}
            />
          );
        })}
      </div>
      {actionPerforming && (
        <div className="absolute flex w-full h-full justify-center">
          <div className="flex h-full">
            <Spinner notFullScreen />
          </div>
        </div>
      )}
    </div>
  );
};

MediaWidgetBody.propTypes = {
  /**
   * Resource Images
   */
  images: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string,
      isPrimary: PropTypes.bool,
    })
  ),
  /**
   * Primary Image of resource
   */
  primary: PropTypes.shape({}),
  /**
   * Add New Image Function
   */
  addImage: PropTypes.func,
  /**
   * Resource
   */
  resource: PropTypes.shape({}),
  /**
   * Select Image Function
   */
  selectImage: PropTypes.func,
  /**
   * Array of selected images
   */
  selected: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * Return resolved images to parent
   * for mediaViewer
   */
  compileResolved: PropTypes.func,
  /**
   * Document finish uploading handler
   */
  handleOnFinishUpload: PropTypes.func,
  /**
   * State of parent action being performed
   */
  actionPerforming: PropTypes.bool,
  disableEditing: PropTypes.bool,
  isSop: PropTypes.bool,
  isContact: PropTypes.bool,
  sopStyle: PropTypes.shape({}),
  sopMediaStyle: PropTypes.shape({}),
  hasWritePermission: PropTypes.bool,
};

MediaWidgetBody.defaultProps = {
  images: undefined,
  primary: undefined,
  addImage: undefined,
  resource: undefined,
  selectImage: undefined,
  selected: undefined,
  compileResolved: undefined,
  handleOnFinishUpload: undefined,
  actionPerforming: false,
  disableEditing: false,
  isSop: false,
  isContact: false,
  sopStyle: undefined,
  sopMediaStyle: undefined,
  hasWritePermission: true,
};

export default MediaWidgetBody;

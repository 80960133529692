import React from "react";
import PropTypes from "prop-types";
import Tag from "../Tag";
import useTagListViewData from "./useTagListViewData";

/**
 * Component that renders a list of tags horizontally
 */
const TagList = ({
  tags,
  isEditing,
  handleDeleteTag,
  className,
  tagClassName,
  showCount,
}) => {
  const { tagsToDisplay, showBubble, bubbleAmount } = useTagListViewData(
    tags,
    showCount
  );

  return (
    <div className={`flex flex-wrap gap-2 ${className ?? ""}`}>
      {tagsToDisplay?.map((tagReference) => (
        <Tag
          key={tagReference}
          tagReference={tagReference}
          isEditing={isEditing}
          handleDeleteTag={handleDeleteTag}
          className={tagClassName}
        />
      ))}
      {showBubble && (
        <div className="bg-gray-100 rounded-3xl py-1 px-1.5">
          <p className="text-primaryGreen text-xs font-normal">
            {`+${bubbleAmount}`}
          </p>
        </div>
      )}
    </div>
  );
};

TagList.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  isEditing: PropTypes.bool,
  handleDeleteTag: PropTypes.func,
  className: PropTypes.string,
  tagClassName: PropTypes.string,
  showCount: PropTypes.bool,
};

TagList.defaultProps = {
  tags: [],
  isEditing: false,
  handleDeleteTag: () => {},
  className: undefined,
  tagClassName: undefined,
  showCount: false,
};

export default TagList;

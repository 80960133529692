import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import BaseButton from "../Buttons/BaseButton";

const popoverItemCN = (className) => cntl`
  py-2.5
  px-2
  rounded
  leading-5
  ${className}
`;

const PopoverItem = ({ className, hoverColorCN, ...props }) => {
  return (
    <BaseButton
      className={popoverItemCN(className)}
      hoverColorCN={hoverColorCN}
      leftAlign
      hasCustomFontStyles
      {...props}
    />
  );
};

PopoverItem.propTypes = {
  /**
   * any CSS classes to apply to the container
   */
  className: PropTypes.string,
  hoverColorCN: PropTypes.string,
};

PopoverItem.defaultProps = {
  className: null,
  hoverColorCN: null,
};

export default PopoverItem;

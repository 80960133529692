import { useReducer } from "react";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { getDerivedDate } from "../helpers/Holidays";

const defaultState = {};

const reducer = (configuration, action) => {
  switch (action.type) {
    case "reset":
      return cloneDeep(action.configuration) ?? defaultState;
    case "addSectionItem": {
      return {
        ...configuration,
        [action.selectionValue]: [
          ...configuration?.[action.selectionValue],
          {
            [action.selectionItemValue]: "",
            id: uuidv4(),
            selected: true,
            custom: true,
            isEditing: true,
          },
        ],
      };
    }
    case "removeSectionItem": {
      return {
        ...configuration,
        [action.selectionValue]: configuration?.[action.selectionValue].filter(
          (_, idx) => idx !== action.index
        ),
      };
    }
    case "changeSectionItem": {
      return {
        ...configuration,
        [action.selectionValue]: configuration?.[action.selectionValue].map(
          (p, idx) => {
            if (idx === action.index) {
              return {
                ...p,
                [action.selectionItemValue]: action.value,
                [action?.selectionSecondItemValue]: action?.secondValue,
              };
            }
            return p;
          }
        ),
      };
    }
    case "changeSectionItemEditing": {
      return {
        ...configuration,
        [action.selectionValue]: configuration?.[action.selectionValue].map(
          (item, idx) => {
            if (idx === action.index) {
              return {
                ...item,
                isEditing: !item.isEditing,
              };
            }
            return item;
          }
        ),
      };
    }
    case "selectAllSectionItems": {
      return {
        ...configuration,
        [action.selectionValue]: configuration?.[action.selectionValue].map(
          (item) => {
            return {
              ...item,
              selected: !item.count ? action.value : item.selected,
            };
          }
        ),
      };
    }
    case "selectSectionItem": {
      return {
        ...configuration,
        [action.selectionValue]: configuration?.[action.selectionValue].map(
          (item, idx) => {
            if (idx === action.index) {
              return {
                ...item,
                selected: !item.selected,
              };
            }
            return item;
          }
        ),
      };
    }
    case "addCustomLengthUnit": {
      return {
        ...configuration,
        customLengthUnits: [
          ...configuration.customLengthUnits,
          {
            display: "",
            id: uuidv4(),
            selected: true,
            custom: true,
            isEditing: true,
          },
        ],
      };
    }
    case "removeCustomLengthUnit": {
      return {
        ...configuration,
        customLengthUnits: configuration.customLengthUnits.filter(
          (item, idx) => idx !== action.index
        ),
      };
    }
    case "changeCustomLengthUnit": {
      return {
        ...configuration,
        customLengthUnits: configuration.customLengthUnits.map((p, idx) => {
          if (idx === action.index) {
            return {
              ...p,
              display: action.value,
            };
          }
          return p;
        }),
      };
    }
    case "toggleCustomLengthUnitEditing": {
      return {
        ...configuration,
        customLengthUnits: configuration.customLengthUnits.map((p, bIndex) => {
          if (action.index === bIndex) {
            return {
              ...p,
              isEditing: !p.isEditing,
            };
          }
          return p;
        }),
      };
    }

    case "addCustomQuantityUnit": {
      return {
        ...configuration,
        customQuantityUnits: [
          ...configuration.customQuantityUnits,
          {
            display: "",
            id: uuidv4(),
            selected: true,
            custom: true,
            isEditing: true,
          },
        ],
      };
    }
    case "removeCustomQuantityUnit": {
      return {
        ...configuration,
        customQuantityUnits: configuration.customQuantityUnits.filter(
          (item, idx) => idx !== action.index
        ),
      };
    }
    case "changeCustomQuantityUnit": {
      return {
        ...configuration,
        customQuantityUnits: configuration.customQuantityUnits.map((p, idx) => {
          if (idx === action.index) {
            return {
              ...p,
              display: action.value,
            };
          }
          return p;
        }),
      };
    }

    case "addCustomTimeUnit": {
      return {
        ...configuration,
        customTimeUnits: [
          ...configuration.customTimeUnits,
          {
            display: "",
            id: uuidv4(),
            selected: true,
            custom: true,
            isEditing: true,
          },
        ],
      };
    }
    case "removeCustomTimeUnit": {
      return {
        ...configuration,
        customTimeUnits: configuration.customTimeUnits.filter(
          (item, idx) => idx !== action.index
        ),
      };
    }
    case "changeCustomTimeUnit": {
      return {
        ...configuration,
        customTimeUnits: configuration.customTimeUnits.map((p, idx) => {
          if (idx === action.index) {
            return {
              ...p,
              display: action.value,
            };
          }
          return p;
        }),
      };
    }

    case "addCustomVolumeUnit": {
      return {
        ...configuration,
        customVolumeUnits: [
          ...configuration.customVolumeUnits,
          {
            display: "",
            id: uuidv4(),
            selected: true,
            custom: true,
            isEditing: true,
          },
        ],
      };
    }
    case "removeCustomVolumeUnit": {
      return {
        ...configuration,
        customVolumeUnits: configuration.customVolumeUnits.filter(
          (item, idx) => idx !== action.index
        ),
      };
    }
    case "changeCustomVolumeUnit": {
      return {
        ...configuration,
        customVolumeUnits: configuration.customVolumeUnits.map((p, idx) => {
          if (idx === action.index) {
            return {
              ...p,
              display: action.value,
            };
          }
          return p;
        }),
      };
    }

    case "changeCustomCityEditing": {
      return {
        ...configuration,
        customCities: configuration.customCities.map((p, idx) => {
          if (idx === action.index) {
            return {
              ...p,
              isEditing: !p.isEditing,
            };
          }
          return p;
        }),
      };
    }

    case "addCustomCity": {
      return {
        ...configuration,
        customCities: [
          ...configuration.customCities,
          {
            city: "",
            id: uuidv4(),
            selected: false,
            custom: true,
            isEditing: true,
          },
        ],
      };
    }
    case "removeCustomCity": {
      return {
        ...configuration,
        customCities: configuration.customCities.filter(
          (item, idx) => idx !== action.index
        ),
      };
    }

    case "changeCustomCity": {
      return {
        ...configuration,
        customCities: configuration.customCities.map((p, idx) => {
          if (idx === action.index) {
            return {
              ...p,
              city: action.value.city,
              cityCode: action.value.timezone,
              selected: true,
            };
          }
          return p;
        }),
      };
    }
    case "selectCity": {
      return {
        ...configuration,
        cities: configuration.cities.map((item, idx) => {
          if (idx === action.index) {
            return {
              ...item,
              selected: !item.selected,
            };
          }
          return item;
        }),
      };
    }
    case "selectAllCities": {
      return {
        ...configuration,
        cities: configuration.cities.map((item) => {
          return {
            ...item,
            selected: action.value,
          };
        }),
      };
    }
    case "toggleSopCategory": {
      return {
        ...configuration,
        sopCategories: configuration.sopCategories.map((item, idx) => {
          if (idx === action.index) {
            return {
              ...item,
              isOpen: !item.isOpen,
            };
          }
          return {
            ...item,
            isOpen: false,
          };
        }),
      };
    }
    case "addSopCategory": {
      return {
        ...configuration,
        sopCategories: [
          ...configuration.sopCategories,
          {
            display: "",
            id: uuidv4(),
            selected: true,
            custom: true,
            isEditing: true,
            subcategories: [],
          },
        ],
      };
    }
    case "changeSopCategory": {
      return {
        ...configuration,
        sopCategories: configuration.sopCategories.map((p, idx) => {
          if (idx === action.index) {
            return {
              ...p,
              display: action.value,
            };
          }
          return p;
        }),
      };
    }
    case "changeSopCategoryEditing": {
      return {
        ...configuration,
        sopCategories: configuration.sopCategories.map((p, idx) => {
          if (idx === action.index) {
            return {
              ...p,
              isEditing: !p.isEditing,
            };
          }
          return p;
        }),
      };
    }
    case "updatePreferences": {
      return {
        ...configuration,
        preferences: { ...action.preferences },
      };
    }
    case "selectPtoLocationType": {
      return {
        ...configuration,
        ptoLocations: configuration.ptoLocations.map((loc, lIndex) => {
          if (lIndex === action.locationIndex) {
            return {
              ...loc,
              types: loc.types.map((type, tIndex) => {
                if (action.ptoType === "unlimited") {
                  if (action.selected) {
                    if (tIndex === action.typeIndex) {
                      return {
                        ...type,
                        selected: true,
                      };
                    }
                    return {
                      ...type,
                      selected: false,
                    };
                  }

                  if (tIndex === action.typeIndex) {
                    return {
                      ...type,
                      selected: false,
                    };
                  }

                  return type;
                }

                if (action.ptoType !== "unlimited" && action.selected) {
                  if (type.type === "unlimited") {
                    return {
                      ...type,
                      selected: false,
                    };
                  }
                  if (tIndex === action.typeIndex) {
                    return {
                      ...type,
                      selected: true,
                    };
                  }
                  return type;
                }

                if (tIndex === action.typeIndex) {
                  return {
                    ...type,
                    selected: false,
                  };
                }

                return type;
              }),
            };
          }
          return loc;
        }),
      };
    }
    case "changePtoLocationTypeNegativeValues": {
      return {
        ...configuration,
        ptoLocations: configuration.ptoLocations.map((loc, lIndex) => {
          if (lIndex === action.locationIndex) {
            return {
              ...loc,
              types: loc.types.map((type, tIndex) => {
                if (action.ptoType === "unlimited") {
                  if (action.allowNegatives) {
                    if (tIndex === action.typeIndex) {
                      return {
                        ...type,
                        allowNegatives: true,
                      };
                    }
                    return {
                      ...type,
                      allowNegatives: false,
                    };
                  }

                  if (tIndex === action.typeIndex) {
                    return {
                      ...type,
                      allowNegatives: false,
                    };
                  }

                  return type;
                }

                if (action.ptoType !== "unlimited" && action.allowNegatives) {
                  if (type.type === "unlimited") {
                    return {
                      ...type,
                      allowNegatives: false,
                    };
                  }
                  if (tIndex === action.typeIndex) {
                    return {
                      ...type,
                      allowNegatives: true,
                    };
                  }
                  return type;
                }

                if (tIndex === action.typeIndex) {
                  return {
                    ...type,
                    allowNegatives: false,
                  };
                }

                return type;
              }),
            };
          }
          return loc;
        }),
      };
    }
    case "selectPtoGeneralType": {
      return {
        ...configuration,
        ptoGeneralTypes: configuration.ptoGeneralTypes.map((type, tIndex) => {
          if (action.ptoType === "unlimited") {
            if (action.selected) {
              if (tIndex === action.typeIndex) {
                return {
                  ...type,
                  selected: true,
                };
              }
              return {
                ...type,
                selected: false,
              };
            }

            if (tIndex === action.typeIndex) {
              return {
                ...type,
                selected: false,
              };
            }

            return type;
          }

          if (action.ptoType !== "unlimited" && action.selected) {
            if (type.type === "unlimited") {
              return {
                ...type,
                selected: false,
              };
            }
            if (tIndex === action.typeIndex) {
              return {
                ...type,
                selected: true,
              };
            }
            return type;
          }

          if (tIndex === action.typeIndex) {
            return {
              ...type,
              selected: false,
            };
          }

          return type;
        }),
      };
    }

    case "togglePtoLocation": {
      return {
        ...configuration,
        ptoLocations: configuration.ptoLocations.map((loc, lIndex) => {
          if (lIndex === action.locationIndex) {
            return {
              ...loc,
              isOpen: !loc.isOpen,
            };
          }
          return loc;
        }),
      };
    }
    case "removePtoLocation": {
      return {
        ...configuration,
        ptoLocations: configuration.ptoLocations.filter(
          (pto, pIndex) => pIndex !== action.locationIndex
        ),
      };
    }
    case "addPtoLocation": {
      return {
        ...configuration,
        ptoLocations: [
          ...configuration.ptoLocations,
          {
            location: "",
            id: uuidv4(),
            types: action.defaultPtoTypes.map((type) => ({
              type,
              selected: false,
              accrualType:
                type === "unlimited" || type === "unpaid"
                  ? "non-accrual"
                  : "accrual",
              maxAccrual: "100.00",
              allowNegatives: false,
            })),
            isEditing: true,
            isOpen: true,
          },
        ],
      };
    }
    case "togglePtoLocationEditing": {
      return {
        ...configuration,
        ptoLocations: configuration.ptoLocations.map((loc, lIndex) => {
          if (lIndex === action.locationIndex) {
            return {
              ...loc,
              isEditing: !loc.isEditing,
            };
          }
          return loc;
        }),
      };
    }
    case "changePtoLocation": {
      return {
        ...configuration,
        ptoLocations: configuration.ptoLocations.map((loc, lIndex) => {
          if (lIndex === action.locationIndex) {
            // First check if the location already exists in PTO
            // If it does, auto-fill with the opposite exempt status
            const locationThatAlreadyExists = configuration.ptoLocations.find(
              ({ location }) => location === action.value
            );

            if (locationThatAlreadyExists) {
              return {
                ...loc,
                isExempt: !locationThatAlreadyExists.isExempt,
                location: action.value,
              };
            }

            return {
              ...loc,
              location: action.value,
            };
          }
          return loc;
        }),
      };
    }
    case "changePtoLocationExemptStatus": {
      return {
        ...configuration,
        ptoLocations: configuration.ptoLocations.map((loc, lIndex) => {
          // First check if the location already exists in PTO
          // If it does, auto-fill with the opposite exempt status
          const locationThatAlreadyExistsIndex =
            configuration.ptoLocations.findIndex(
              ({ location }, index) =>
                location === action.location && index !== action.locationIndex
            );
          if (lIndex === locationThatAlreadyExistsIndex) {
            return {
              ...loc,
              isExempt: !action.value,
            };
          }

          if (lIndex === action.locationIndex) {
            return {
              ...loc,
              isExempt: action.value,
            };
          }
          return loc;
        }),
      };
    }
    case "changePtoLocationAvailabilityDate": {
      return {
        ...configuration,
        ptoLocations: configuration.ptoLocations.map((loc, lIndex) => {
          if (lIndex === action.locationIndex) {
            return {
              ...loc,
              types: loc.types.map((type, tIndex) => {
                if (tIndex === action.typeIndex) {
                  return {
                    ...type,
                    availability: {
                      date: action.value,
                    },
                  };
                }
                return type;
              }),
            };
          }
          return loc;
        }),
      };
    }
    case "changePtoGeneralAvailabilityDate": {
      return {
        ...configuration,
        ptoGeneralTypes: configuration.ptoGeneralTypes.map((type, tIndex) => {
          if (tIndex === action.typeIndex) {
            return {
              ...type,
              availability: {
                ...type.availability,
                date: action.value,
              },
            };
          }
          return type;
        }),
      };
    }
    case "changePtoLocationTypeAvailabilityDays": {
      return {
        ...configuration,
        ptoLocations: configuration.ptoLocations.map((loc, lIndex) => {
          if (lIndex === action.locationIndex) {
            return {
              ...loc,
              types: loc.types.map((type, tIndex) => {
                if (tIndex === action.typeIndex) {
                  const clonedType = cloneDeep(type);
                  if (!action.value && action.value !== 0) {
                    delete clonedType.availability.waitPeriod;
                    return clonedType;
                  }
                  return {
                    ...type,
                    availability: {
                      ...type.availability,
                      waitPeriod: action.value,
                    },
                  };
                }
                return type;
              }),
            };
          }
          return loc;
        }),
      };
    }
    case "changePtoGeneralTypeAvailabilityDays": {
      return {
        ...configuration,
        ptoGeneralTypes: configuration.ptoGeneralTypes.map((type, tIndex) => {
          if (tIndex === action.typeIndex) {
            return {
              ...type,
              availability: {
                ...type.availability,
                waitPeriod: action.value,
              },
            };
          }
          return type;
        }),
      };
    }
    case "changePtoGeneralTypeMaxAccrual": {
      return {
        ...configuration,
        ptoGeneralTypes: configuration.ptoGeneralTypes.map((type, tIndex) => {
          if (tIndex === action.typeIndex) {
            return {
              ...type,
              maxAccrual: action.value,
            };
          }
          return type;
        }),
      };
    }
    case "onChangePtoGeneralTypeNegativeValues": {
      return {
        ...configuration,
        ptoGeneralTypes: configuration.ptoGeneralTypes.map((type, tIndex) => {
          if (action.ptoType === "unlimited") {
            if (action.allowNegatives) {
              if (tIndex === action.typeIndex) {
                return {
                  ...type,
                  allowNegatives: true,
                };
              }
              return {
                ...type,
                allowNegatives: false,
              };
            }

            if (tIndex === action.typeIndex) {
              return {
                ...type,
                allowNegatives: false,
              };
            }

            return type;
          }

          if (action.ptoType !== "unlimited" && action.allowNegatives) {
            if (type.type === "unlimited") {
              return {
                ...type,
                allowNegatives: false,
              };
            }
            if (tIndex === action.typeIndex) {
              return {
                ...type,
                allowNegatives: true,
              };
            }
            return type;
          }

          if (tIndex === action.typeIndex) {
            return {
              ...type,
              allowNegatives: false,
            };
          }

          return type;
        }),
      };
    }
    case "changePtoLocationTypeMaxAccrual": {
      return {
        ...configuration,
        ptoLocations: configuration.ptoLocations.map((loc, lIndex) => {
          if (lIndex === action.locationIndex) {
            return {
              ...loc,
              types: loc.types.map((type, tIndex) => {
                if (tIndex === action.typeIndex) {
                  return {
                    ...type,
                    maxAccrual: action.value,
                  };
                }
                return type;
              }),
            };
          }
          return loc;
        }),
      };
    }
    case "changePtoLocationAccrualType": {
      return {
        ...configuration,
        ptoLocations: configuration.ptoLocations.map((loc, lIndex) => {
          if (lIndex === action.locationIndex) {
            return {
              ...loc,
              types: loc.types.map((type, tIndex) => {
                if (tIndex === action.typeIndex) {
                  return {
                    ...type,
                    accrualType: action.value,
                  };
                }
                return type;
              }),
            };
          }
          return loc;
        }),
      };
    }
    case "changePtoGeneralAccrualType": {
      return {
        ...configuration,
        ptoGeneralTypes: configuration.ptoGeneralTypes.map((type, tIndex) => {
          if (tIndex === action.typeIndex) {
            return {
              ...type,
              accrualType: action.value,
            };
          }
          return type;
        }),
      };
    }
    case "changePtoLocationNumHours": {
      return {
        ...configuration,
        ptoLocations: configuration.ptoLocations.map((loc, lIndex) => {
          if (lIndex === action.locationIndex) {
            return {
              ...loc,
              types: loc.types.map((type, tIndex) => {
                if (tIndex === action.typeIndex) {
                  const clonedType = cloneDeep(type);
                  if (!action.value && action.value !== 0) {
                    delete clonedType.numHours;
                    return clonedType;
                  }
                  return {
                    ...type,
                    numHours: action.value,
                  };
                }
                return type;
              }),
            };
          }
          return loc;
        }),
      };
    }
    case "changePtoGeneralTypeNumHours": {
      return {
        ...configuration,
        ptoGeneralTypes: configuration.ptoGeneralTypes.map((type, tIndex) => {
          if (tIndex === action.typeIndex) {
            const clonedType = cloneDeep(type);
            if (!action.value && action.value !== 0) {
              delete clonedType.numHours;
              return clonedType;
            }
            return {
              ...type,
              numHours: action.value,
            };
          }
          return type;
        }),
      };
    }
    case "changePtoLocationMaxAccrual": {
      return {
        ...configuration,
        ptoLocations: configuration.ptoLocations.map((loc, lIndex) => {
          if (lIndex === action.locationIndex) {
            return {
              ...loc,
              types: loc.types.map((type, tIndex) => {
                if (tIndex === action.typeIndex) {
                  return {
                    ...type,
                    maxAccrual: action.value,
                  };
                }
                return type;
              }),
            };
          }
          return loc;
        }),
      };
    }
    case "changePtoLocationMaxRollover": {
      return {
        ...configuration,
        ptoLocations: configuration.ptoLocations.map((loc, lIndex) => {
          if (lIndex === action.locationIndex) {
            return {
              ...loc,
              types: loc.types.map((type, tIndex) => {
                if (tIndex === action.typeIndex) {
                  const clonedType = cloneDeep(type);
                  if (!action.value && action.value !== 0) {
                    delete clonedType.maxRollover;
                    return clonedType;
                  }
                  return {
                    ...type,
                    maxRollover: action.value,
                  };
                }
                return type;
              }),
            };
          }
          return loc;
        }),
      };
    }
    case "changePtoGeneralTypeMaxRollover": {
      return {
        ...configuration,
        ptoGeneralTypes: configuration.ptoGeneralTypes.map((type, tIndex) => {
          if (tIndex === action.typeIndex) {
            return {
              ...type,
              maxRollover: action.value,
            };
          }
          return type;
        }),
      };
    }
    case "changePtoCategory": {
      return {
        ...configuration,
        ptoCategory: action.value,
      };
    }
    case "addBenefitCategory": {
      return {
        ...configuration,
        benefits: [
          ...configuration.benefits,
          {
            category: "",
            benefit: [],
            isEditing: true,
            custom: true,
          },
        ],
      };
    }
    case "toggleBenefitCategoryEditing": {
      return {
        ...configuration,
        benefits: configuration.benefits.map((entityBenefit, bIndex) => {
          if (action.index === bIndex) {
            return {
              ...entityBenefit,
              isEditing: !entityBenefit.isEditing,
            };
          }
          return entityBenefit;
        }),
      };
    }
    case "changeBenefitCategory": {
      return {
        ...configuration,
        benefits: configuration.benefits.map((entityBenefit, bIndex) => {
          if (action.index === bIndex) {
            return {
              ...entityBenefit,
              category: action.value,
            };
          }
          return entityBenefit;
        }),
      };
    }
    case "changeBenefitCategoryPolicyWebsite": {
      return {
        ...configuration,
        benefits: configuration.benefits.map((entityBenefit, bIndex) => {
          if (action.benefitIndex === bIndex) {
            return {
              ...entityBenefit,
              benefit: entityBenefit.benefit.map((policy, pIndex) => {
                if (action.policyIndex === pIndex) {
                  return {
                    ...policy,
                    website: action.value,
                  };
                }
                return policy;
              }),
            };
          }
          return entityBenefit;
        }),
      };
    }
    case "changeBenefitCategoryPolicyDuration": {
      return {
        ...configuration,
        benefits: configuration.benefits.map((entityBenefit, bIndex) => {
          if (action.benefitIndex === bIndex) {
            return {
              ...entityBenefit,
              benefit: entityBenefit.benefit.map((policy, pIndex) => {
                if (action.policyIndex === pIndex) {
                  return {
                    ...policy,
                    duration: action.value,
                  };
                }
                return policy;
              }),
            };
          }
          return entityBenefit;
        }),
      };
    }
    case "changeBenefitCategoryPolicyDate": {
      return {
        ...configuration,
        benefits: configuration.benefits.map((entityBenefit, bIndex) => {
          if (action.benefitIndex === bIndex) {
            return {
              ...entityBenefit,
              benefit: entityBenefit.benefit.map((policy, pIndex) => {
                if (action.policyIndex === pIndex) {
                  return {
                    ...policy,
                    startDate: action.value,
                  };
                }
                return policy;
              }),
            };
          }
          return entityBenefit;
        }),
      };
    }
    case "changeBenefitCategoryPolicyName": {
      return {
        ...configuration,
        benefits: configuration.benefits.map((entityBenefit, bIndex) => {
          if (action.benefitIndex === bIndex) {
            return {
              ...entityBenefit,
              benefit: entityBenefit.benefit.map((policy, pIndex) => {
                if (action.policyIndex === pIndex) {
                  return {
                    ...policy,
                    plan: action.value,
                  };
                }
                return policy;
              }),
            };
          }
          return entityBenefit;
        }),
      };
    }
    case "changeBenefitCategoryPolicyAgeBanding": {
      return {
        ...configuration,
        benefits: configuration.benefits.map((entityBenefit, bIndex) => {
          if (action.benefitIndex === bIndex) {
            return {
              ...entityBenefit,
              benefit: entityBenefit.benefit.map((policy, pIndex) => {
                if (action.policyIndex === pIndex) {
                  return {
                    ...policy,
                    ageBanding: !policy.ageBanding,
                  };
                }
                return policy;
              }),
            };
          }
          return entityBenefit;
        }),
      };
    }
    case "toggleBenefitCategoryPolicyNameEditing": {
      return {
        ...configuration,
        benefits: configuration.benefits.map((entityBenefit, bIndex) => {
          if (action.benefitIndex === bIndex) {
            return {
              ...entityBenefit,
              benefit: entityBenefit.benefit.map((policy, pIndex) => {
                if (action.policyIndex === pIndex) {
                  return {
                    ...policy,
                    isEditing: !policy.isEditing,
                  };
                }
                return policy;
              }),
            };
          }
          return entityBenefit;
        }),
      };
    }
    case "toggleBenefitCategoryPolicy": {
      return {
        ...configuration,
        benefits: configuration.benefits.map((entityBenefit, bIndex) => {
          if (action.benefitIndex === bIndex) {
            return {
              ...entityBenefit,
              benefit: entityBenefit.benefit.map((policy, pIndex) => {
                if (action.policyIndex === pIndex) {
                  return {
                    ...policy,
                    isOpen: !policy.isOpen,
                  };
                }
                return policy;
              }),
            };
          }
          return entityBenefit;
        }),
      };
    }
    case "addBenefitCategoryPolicy": {
      return {
        ...configuration,
        benefits: configuration.benefits.map((entityBenefit, bIndex) => {
          if (action.benefitIndex === bIndex) {
            return {
              ...entityBenefit,
              benefit: [
                ...entityBenefit.benefit,
                {
                  plan: "",
                  startDate: new Date(),
                  duration: "annual",
                  website: "",
                  levels: action.initialLevels,
                  isEditing: true,
                  isOpen: true,
                },
              ],
            };
          }
          return entityBenefit;
        }),
      };
    }
    case "changeBenefitCategoryPolicyLevelCost": {
      return {
        ...configuration,
        benefits: configuration.benefits.map((entityBenefit, bIndex) => {
          if (action.benefitIndex === bIndex) {
            return {
              ...entityBenefit,
              benefit: entityBenefit.benefit.map((policy, pIndex) => {
                if (action.policyIndex === pIndex) {
                  return {
                    ...policy,
                    levels: policy.levels.map((level, lIndex) => {
                      if (action.levelIndex === lIndex) {
                        return {
                          ...level,
                          cost: action.value,
                        };
                      }
                      return level;
                    }),
                  };
                }
                return policy;
              }),
            };
          }
          return entityBenefit;
        }),
      };
    }
    case "changeBenefitCategoryPolicyLevelCoverage": {
      return {
        ...configuration,
        benefits: configuration.benefits.map((entityBenefit, bIndex) => {
          if (action.benefitIndex === bIndex) {
            return {
              ...entityBenefit,
              benefit: entityBenefit.benefit.map((policy, pIndex) => {
                if (action.policyIndex === pIndex) {
                  return {
                    ...policy,
                    levels: policy.levels.map((level, lIndex) => {
                      if (action.levelIndex === lIndex) {
                        return {
                          ...level,
                          employerCoverage: action.value,
                        };
                      }
                      return level;
                    }),
                  };
                }
                return policy;
              }),
            };
          }
          return entityBenefit;
        }),
      };
    }

    case "removeHoliday": {
      return {
        ...configuration,
        holidays: configuration.holidays.filter(
          (holiday, index) => index !== action.index
        ),
      };
    }
    case "selectHoliday": {
      return {
        ...configuration,
        holidays: configuration.holidays.map((holiday, idx) => {
          if (idx === action.index) {
            return {
              ...holiday,
              selected: !holiday.selected,
            };
          }
          return holiday;
        }),
      };
    }
    case "selectAllHolidays": {
      return {
        ...configuration,
        holidays: configuration.holidays.map((holiday) => {
          return {
            ...holiday,
            selected: action.value,
          };
        }),
      };
    }
    case "updateHoliday": {
      return {
        ...configuration,
        holidays: configuration.holidays.map((holiday, index) => {
          if (index === action.isEditingIndex) {
            const updatedHoliday = { ...action.selectedHoliday };
            if (action.selectedDateOption?.value === "customRecurrence") {
              updatedHoliday.recurrence.derivedDate = getDerivedDate(
                updatedHoliday,
                action.selectedYears
              );
            }
            return updatedHoliday;
          }

          return holiday;
        }),
      };
    }
    case "addHoliday": {
      return {
        ...configuration,
        holidays: [
          ...configuration.holidays,
          {
            ...action.selectedHoliday,
            selected: true,
          },
        ],
      };
    }
    case "changeHoursOfOperation": {
      return {
        ...configuration,
        hoursOfOperation: action.value,
      };
    }
    default:
      return configuration;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};

const getTaskHistoryDuration = (taskForm) => {
  if (!taskForm?.duration.value) return "Duration not set";

  const durationUnit =
    taskForm?.duration.typeOfDuration === "calendarDay"
      ? "Day"
      : taskForm?.duration.typeOfDuration;

  return taskForm?.duration.value > 1
    ? `${taskForm?.duration.value} ${durationUnit}s`
    : `${taskForm?.duration.value} ${durationUnit}`;
};
export default getTaskHistoryDuration;

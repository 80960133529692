import React, { useCallback } from "react";
import ModalWrapper from "../ModalWrapper";
import PrimaryButton from "../../Buttons/PrimaryButton";
import useEsModalData from "../useEsModalData";
import { toastMessage } from "../../Toast/Toast";
import TertiaryButton from "../../Buttons/TertiaryButton";
import { BORDER_GREEN, PILL_GRAY } from "../../../../constants";
import notiAlertIcon from "../../../assets/images/notiAlertIcon.svg";

const ConfirmModal = ({ modalData }) => {
  /**
   * prompt - the prompt to be displayed
   * confirm - primary button title
   * cancel - secondary button title
   * onConfirm - function called on confirm
   */
  const { prompt, confirm, cancel, onConfirm, toast, modalWidth } =
    modalData.item ?? {
      prompt: "Are you sure?",
      confirm: "Yes",
      cancel: "No",
      onConfirm: () => {},
      toast: undefined,
    };

  const { closeModal: close } = useEsModalData(null, modalData);

  const primaryButtonClick = useCallback(() => {
    onConfirm();
    if (toast) toastMessage(toast);
    close();
  }, [close, onConfirm, toast]);
  return (
    <ModalWrapper
      width={modalWidth || "454px"}
      modalData={modalData}
      resourceName={modalData?.item?.title ?? "Mark Complete?"}
      title={modalData?.item?.title}
      hideActions
      alert
      closeOnOverlayClick
      buttons={
        <div className="flex gap-4 w-full justify-start px-4">
          <PrimaryButton
            saveButton
            saveButtonTitle={confirm}
            onClick={primaryButtonClick}
          />
          <TertiaryButton
            style={{
              background: "white",
              borderWidth: "3px",
              minWidth: "fit-content",
              borderRadius: "0.5rem",
              paddingLeft: "0.25rem",
              paddingRight: " 0.25rem",
              color: PILL_GRAY,
              borderColor: BORDER_GREEN,
            }}
            title={cancel}
            onClick={close}
          />
        </div>
      }
    >
      <div className="flex items-start border rounded-md p-8">
        <img src={notiAlertIcon} alt="attention" />
        <p className="text-es-medium-grey text-es-normal tracking-es-wide pl-4">
          {prompt}
        </p>
      </div>
    </ModalWrapper>
  );
};

export default ConfirmModal;

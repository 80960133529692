import { useReducer } from "react";
import { cloneDeep } from "lodash";

const defaultState = {};

const getUpdatedPolicies = (list, from, to) => {
  const array = [...list];
  array.splice(to, 0, array.splice(from, 1)[0]);
  return array;
};
const reducer = (configuration, action) => {
  switch (action.type) {
    case "reset":
      return cloneDeep(action.configuration) ?? defaultState;
    case "changeCompletionTime": {
      return {
        ...configuration,
        completionTime: action.value,
      };
    }
    case "changeCompletionTimeZone": {
      return {
        ...configuration,
        completionTimeZone: action.value,
      };
    }
    case "changeClientInvoiceApproverTime": {
      return {
        ...configuration,
        clientInvoiceApprovers: configuration.clientInvoiceApprovers.map(
          (approver, idx) => {
            if (idx === action.index) {
              return {
                ...approver,
                numDays: {
                  ...approver.numDays,
                  value: action.value,
                },
              };
            }
            return approver;
          }
        ),
      };
    }
    case "changeVendorInvoiceApproverTime": {
      return {
        ...configuration,
        vendorInvoiceApprovers: configuration.vendorInvoiceApprovers.map(
          (approver, idx) => {
            if (idx === action.index) {
              return {
                ...approver,
                numDays: {
                  ...approver.numDays,
                  value: action.value,
                },
              };
            }
            return approver;
          }
        ),
      };
    }
    case "changePtoApprovalTime": {
      return {
        ...configuration,
        ptoApproval: configuration.ptoApproval.map((approver, idx) => {
          if (idx === action.index) {
            return {
              ...approver,
              numDays: {
                ...approver.numDays,
                value: action.value,
              },
            };
          }
          return approver;
        }),
      };
    }
    case "changeTimesheetApproverTime": {
      return {
        ...configuration,
        timesheetApproval: {
          ...configuration.timesheetApproval,
          approved: configuration.timesheetApproval.approved.map(
            (approver, idx) => {
              if (idx === action.index) {
                return {
                  ...approver,
                  numDays: {
                    ...approver.numDays,
                    value: action.value,
                  },
                };
              }
              return approver;
            }
          ),
        },
      };
    }
    case "changePayrollApproverSubmissionTime": {
      return {
        ...configuration,
        payrollApproval: {
          ...configuration.payrollApproval,
          submitted: {
            ...configuration.payrollApproval.submitted,
            numDays: {
              ...configuration.payrollApproval.submitted.numDays,
              value: action.value,
            },
          },
        },
      };
    }
    case "changePayrollApproverSubmissionTypeOfDuration": {
      return {
        ...configuration,
        payrollApproval: {
          ...configuration.payrollApproval,
          submitted: {
            ...configuration.payrollApproval.submitted,
            numDays: {
              ...configuration.payrollApproval.submitted.numDays,
              typeOfDuration: action.value,
            },
          },
        },
      };
    }
    case "changeClientInvoiceApproverTypeOfDuration": {
      return {
        ...configuration,
        clientInvoiceApprovers: configuration.clientInvoiceApprovers.map(
          (approver, idx) => {
            if (idx === action.index) {
              return {
                ...approver,
                numDays: {
                  ...approver.numDays,
                  typeOfDuration: action.value,
                },
              };
            }
            return approver;
          }
        ),
      };
    }
    case "changeVendorInvoiceApproverTypeOfDuration": {
      return {
        ...configuration,
        vendorInvoiceApprovers: configuration.vendorInvoiceApprovers.map(
          (approver, idx) => {
            if (idx === action.index) {
              return {
                ...approver,
                numDays: {
                  ...approver.numDays,
                  typeOfDuration: action.value,
                },
              };
            }
            return approver;
          }
        ),
      };
    }
    case "changeTimesheetApproverTypeOfDuration": {
      return {
        ...configuration,
        timesheetApproval: {
          ...configuration.timesheetApproval,
          approved: configuration.timesheetApproval.approved.map(
            (approver, idx) => {
              if (idx === action.index) {
                return {
                  ...approver,
                  numDays: {
                    ...approver.numDays,
                    typeOfDuration: action.value,
                  },
                };
              }
              return approver;
            }
          ),
        },
      };
    }
    case "changePtoApprovalTypeOfDuration": {
      return {
        ...configuration,
        ptoApproval: configuration.ptoApproval.map((approver, idx) => {
          if (idx === action.index) {
            return {
              ...approver,
              numDays: {
                ...approver.numDays,
                typeOfDuration: action.value,
              },
            };
          }
          return approver;
        }),
      };
    }
    case "changeClientInvoiceApprover": {
      return {
        ...configuration,
        clientInvoiceApprovers: configuration.clientInvoiceApprovers.map(
          (approver, idx) => {
            if (idx === action.index) {
              return {
                ...approver,
                reference: action.value,
              };
            }
            return approver;
          }
        ),
      };
    }
    case "removeClientInvoiceApprover": {
      return {
        ...configuration,
        clientInvoiceApprovers: configuration.clientInvoiceApprovers?.filter(
          (approver, idx) => idx !== action.index
        ),
      };
    }
    case "removeVendorInvoiceApprover": {
      return {
        ...configuration,
        vendorInvoiceApprovers: configuration.vendorInvoiceApprovers?.filter(
          (approver, idx) => idx !== action.index
        ),
      };
    }
    case "changeVendorInvoiceApprover": {
      return {
        ...configuration,
        vendorInvoiceApprovers: configuration.vendorInvoiceApprovers.map(
          (approver, idx) => {
            if (idx === action.index) {
              return {
                ...approver,
                reference: action.value,
              };
            }
            return approver;
          }
        ),
      };
    }

    case "changeFinancialAdminApproval": {
      return {
        ...configuration,
        financialAdminApproval: configuration.financialAdminApproval.map(
          (approver, idx) => {
            if (idx === action.index) {
              return {
                ...approver,
                reference: action.value,
              };
            }
            return approver;
          }
        ),
      };
    }
    case "addFinancialAdminApproval": {
      return {
        ...configuration,
        financialAdminApproval: [
          ...configuration.financialAdminApproval,
          {
            numDays: {
              value: 0,
              typeOfDuration: "calendarDay",
            },
          },
        ],
      };
    }
    case "removeFinancialAdminApprover": {
      return {
        ...configuration,
        financialAdminApproval: configuration.financialAdminApproval?.filter(
          (approver, idx) => idx !== action.index
        ),
      };
    }
    case "changeFinancialAdminApprovalTime": {
      return {
        ...configuration,
        financialAdminApproval: configuration.financialAdminApproval.map(
          (approver, idx) => {
            if (idx === action.index) {
              return {
                ...approver,
                numDays: {
                  ...approver.numDays,
                  value: action.value,
                },
              };
            }
            return approver;
          }
        ),
      };
    }
    case "changeFinancialAdminApprovalTypeOfDuration": {
      return {
        ...configuration,
        financialAdminApproval: configuration.financialAdminApproval.map(
          (approver, idx) => {
            if (idx === action.index) {
              return {
                ...approver,
                numDays: {
                  ...approver.numDays,
                  typeOfDuration: action.value,
                },
              };
            }
            return approver;
          }
        ),
      };
    }

    case "changePtoApproval": {
      return {
        ...configuration,
        ptoApproval: configuration.ptoApproval.map((approver, idx) => {
          if (idx === action.index) {
            return {
              ...approver,
              reference: action.value,
            };
          }
          return approver;
        }),
      };
    }
    case "changeTimesheetApprover": {
      return {
        ...configuration,
        timesheetApproval: {
          ...configuration.timesheetApproval,
          approved: configuration.timesheetApproval.approved.map(
            (approver, idx) => {
              if (idx === action.index) {
                return {
                  ...approver,
                  reference: action.value,
                };
              }
              return approver;
            }
          ),
        },
      };
    }
    case "changePayrollApprover": {
      return {
        ...configuration,
        payrollApproval: {
          ...configuration.payrollApproval,
          approved: configuration.payrollApproval.approved.map(
            (approver, idx) => {
              if (idx === action.index) {
                return {
                  ...approver,
                  reference: action.value,
                };
              }
              return approver;
            }
          ),
        },
      };
    }
    case "addClientInvoiceApprover": {
      return {
        ...configuration,
        clientInvoiceApprovers: [
          ...configuration.clientInvoiceApprovers,
          {
            numDays: {
              value: 0,
              typeOfDuration: "calendarDay",
            },
          },
        ],
      };
    }
    case "addVendorInvoiceApprover": {
      return {
        ...configuration,
        vendorInvoiceApprovers: [
          ...configuration.vendorInvoiceApprovers,
          {
            numDays: {
              value: 0,
              typeOfDuration: "calendarDay",
            },
          },
        ],
      };
    }
    case "addPtoApproval": {
      return {
        ...configuration,
        ptoApproval: [
          ...configuration.ptoApproval,
          {
            numDays: {
              value: 0,
              typeOfDuration: "calendarDay",
            },
          },
        ],
      };
    }
    case "removePtoApprover": {
      return {
        ...configuration,
        ptoApproval: configuration.ptoApproval?.filter(
          (approver, idx) => idx !== action.index
        ),
      };
    }
    case "addPayrollApprover": {
      return {
        ...configuration,
        payrollApproval: {
          ...configuration.payrollApproval,
          approved: [...configuration.payrollApproval.approved, {}],
        },
      };
    }
    case "removePayrollApprover": {
      return {
        ...configuration,
        payrollApproval: {
          ...configuration.payrollApproval,
          approved: configuration.payrollApproval?.approved?.filter(
            (approver, idx) => idx !== action.index
          ),
        },
      };
    }
    case "addTimesheetApprover": {
      return {
        ...configuration,
        timesheetApproval: {
          ...configuration.timesheetApproval,
          approved: [
            ...configuration.timesheetApproval.approved,
            {
              numDays: {
                value: 0,
                typeOfDuration: "calendarDay",
              },
            },
          ],
        },
      };
    }
    case "removeTimesheetApprover": {
      return {
        ...configuration,
        timesheetApproval: {
          ...configuration.timesheetApproval,
          approved: configuration.timesheetApproval?.approved?.filter(
            (approver, idx) => idx !== action.index
          ),
        },
      };
    }
    case "changeClientInvoicePaymentDueTime": {
      return {
        ...configuration,
        clientInvoicePaymentDue: {
          ...configuration.clientInvoicePaymentDue,
          value: action.value,
        },
      };
    }
    case "changeTimesheetSubmissionTime": {
      return {
        ...configuration,
        timesheetApproval: {
          ...configuration.timesheetApproval,
          submitted: {
            ...configuration.timesheetApproval.submitted,
            value: action.value,
          },
        },
      };
    }
    case "changeTimesheetNotificationTime": {
      return {
        ...configuration,
        timesheetApproval: {
          ...configuration.timesheetApproval,
          notificationDays: action.value,
        },
      };
    }

    case "changeHandbookAcceptanceTime": {
      return {
        ...configuration,
        employeeHandbookAcceptance: {
          ...configuration.employeeHandbookAcceptance,
          numDays: {
            ...configuration.employeeHandbookAcceptance.numDays,
            value: action.value,
          },
        },
      };
    }
    case "changeHandbookFile": {
      return {
        ...configuration,
        employeeHandbookAcceptance: {
          ...configuration.employeeHandbookAcceptance,
          document: {
            ...configuration.employeeHandbookAcceptance.document,
            reference: action.value,
            name: action.name,
          },
        },
      };
    }
    case "changeHandbookFileName": {
      return {
        ...configuration,
        employeeHandbookAcceptance: {
          ...configuration.employeeHandbookAcceptance,
          document: {
            ...configuration.employeeHandbookAcceptance.document,
            name: action.value,
          },
        },
      };
    }
    case "changeHandbookAcceptanceTypeOfDuration": {
      return {
        ...configuration,
        employeeHandbookAcceptance: {
          ...configuration.employeeHandbookAcceptance,
          numDays: {
            ...configuration.employeeHandbookAcceptance.numDays,
            typeOfDuration: action.value,
          },
        },
      };
    }
    case "changePolicyAcknowledgementTypeOfDuration": {
      return {
        ...configuration,
        policyAcknowledgement: {
          ...configuration.policyAcknowledgement,
          numDays: {
            ...configuration.policyAcknowledgement.numDays,
            typeOfDuration: action.value,
          },
        },
      };
    }
    case "changePolicyAcknowledgementTime": {
      return {
        ...configuration,
        policyAcknowledgement: {
          ...configuration.policyAcknowledgement,
          numDays: {
            ...configuration.policyAcknowledgement.numDays,
            value: action.value,
          },
        },
      };
    }
    case "addPolicyAcknowledgementPolicy": {
      return {
        ...configuration,
        policyAcknowledgement: {
          ...configuration.policyAcknowledgement,
          policies: [
            ...configuration.policyAcknowledgement?.policies,
            {
              label: "",
              value: "",
              isEditing: true,
            },
          ],
        },
      };
    }
    case "removePolicyAcknowledgementPolicy": {
      return {
        ...configuration,
        policyAcknowledgement: {
          ...configuration.policyAcknowledgement,
          policies: configuration.policyAcknowledgement?.policies?.filter(
            (policy, idx) => idx !== action.index
          ),
        },
      };
    }
    case "changePolicyAcknowledgementPolicy": {
      return {
        ...configuration,
        policyAcknowledgement: {
          ...configuration.policyAcknowledgement,
          policies: configuration.policyAcknowledgement?.policies?.map(
            (policy, idx) => {
              if (idx === action.index) {
                return {
                  ...policy,
                  label: action.value,
                  value: action.value,
                };
              }

              return policy;
            }
          ),
        },
      };
    }
    case "changePolicyAcknowledgementPolicyEditing": {
      return {
        ...configuration,
        policyAcknowledgement: {
          ...configuration.policyAcknowledgement,
          policies: configuration.policyAcknowledgement?.policies?.map(
            (policy, idx) => {
              if (idx === action.index) {
                return {
                  ...policy,
                  isEditing: !policy.isEditing,
                };
              }

              return policy;
            }
          ),
        },
      };
    }
    case "changePolicyAcknowledgementPolicyPosition": {
      return {
        ...configuration,
        policyAcknowledgement: {
          ...configuration.policyAcknowledgement,
          policies: getUpdatedPolicies(
            configuration.policyAcknowledgement?.policies,
            action.from,
            action.to
          ),
        },
      };
    }
    case "changeVendorInvoicePaymentProcessingTime": {
      return {
        ...configuration,
        vendorInvoicePaymentProcessing: {
          ...configuration.vendorInvoicePaymentProcessing,
          value: action.value,
        },
      };
    }
    case "changeTimesheetSubmissionTypeOfDuration": {
      return {
        ...configuration,
        timesheetApproval: {
          ...configuration.timesheetApproval,
          submitted: {
            ...configuration.timesheetApproval.submitted,
            typeOfDuration: action.value,
          },
        },
      };
    }
    case "changeClientInvoicePaymentDueTypeOfDuration": {
      return {
        ...configuration,
        clientInvoicePaymentDue: {
          ...configuration.clientInvoicePaymentDue,
          typeOfDuration: action.value,
        },
      };
    }
    case "changeVendorInvoicePaymentProcessingTypeOfDuration": {
      return {
        ...configuration,
        vendorInvoicePaymentProcessing: {
          ...configuration.vendorInvoicePaymentProcessing,
          typeOfDuration: action.value,
        },
      };
    }
    default:
      return configuration;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};

import React from "react";
import PropTypes from "prop-types";

const RfiCard = ({ rfi }) => {
  const { id, name, description, association } = rfi;
  return (
    <div
      className="flex flex-col border-2 border-gray-150 hover:border-brandGreen rounded overflow-hidden cursor-pointer mr-3 mb-3"
      style={{ height: 420, width: 300 }}
      key={id}
    >
      <div className="h-40 w-full border-b p-3 rounded-t-md bg-lightGreen">
        <p className="font-semibold inline-block w-11/12 truncate mb-2">
          {name}
        </p>
        <p className="text-sm text-gray-300">{association}</p>
      </div>
      <div className="p-3">
        <p className="text-sm text-gray-300">{description}</p>
      </div>
    </div>
  );
};

RfiCard.propTypes = {
  rfi: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    association: PropTypes.string,
  }),
};

RfiCard.defaultProps = {
  rfi: undefined,
};

export default RfiCard;

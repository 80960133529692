const compareObjects = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1?.length !== keys2?.length) return false;

  for (let i = 0; i < keys1.length; i += 1) {
    const key = keys1[i];
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
};

const generateObjectKey = (obj) => {
  return JSON.stringify(
    Object.keys(obj)
      .sort()
      .reduce((result, key) => {
        return { ...result, [key]: obj[key] };
      }, {})
  );
};

/**
 * Compares two arrays of objects to see if they contain the same objects, regardless of order.
 *
 * @param {Array<Object>} arr1 - The first array of objects to compare.
 * @param {Array<Object>} arr2 - The second array of objects to compare.
 * @returns {boolean} True if the arrays contain the same objects, false otherwise.
 */

const theseArraysMatch = (arr1, arr2) => {
  if (arr1?.length !== arr2?.length) return false;

  const sortedArr1 = arr1
    .slice()
    .sort((a, b) => generateObjectKey(a).localeCompare(generateObjectKey(b)));
  const sortedArr2 = arr2
    .slice()
    .sort((a, b) => generateObjectKey(a).localeCompare(generateObjectKey(b)));

  for (let i = 0; i < sortedArr1.length; i += 1) {
    if (!compareObjects(sortedArr1[i], sortedArr2[i])) {
      return false;
    }
  }
  return true;
};

export default theseArraysMatch;

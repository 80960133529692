import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

import DatePicker from "../DatePicker/DatePicker";
import TertiaryButton from "../Buttons/TertiaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";

import eventIcon from "../../assets/images/eventIcon.png";

const eventIconElement = (
  <img className="w-4 h-4" src={eventIcon} alt="event icon" />
);

const DateRangePicker = ({
  onChangeStartDate,
  onChangeEndDate,
  onFinishEditing,
  onCancelEditing,
  initialStart,
  initialEnd,
  minDate,
  maxDate,
  startValid,
  endValid,
  saveOnFinish,
}) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    setStartDate(initialStart);
    setEndDate(initialEnd);
  }, [initialStart, initialEnd]);

  const circleCN = cntl`
    w-3
    h-3
    rounded-full
    ${startDate ? "bg-brandGreen" : "bg-gray-200"}
  `;

  const handleEditStartDate = (date) => {
    setStartDate(date);
    if (!saveOnFinish) {
      onChangeStartDate(date);
    }
  };

  const handleEditEndDate = (date) => {
    setEndDate(date);
    if (!saveOnFinish) {
      onChangeEndDate(date);
    }
  };

  const handleFinishEditing = () => {
    onFinishEditing({ startDate, endDate });
  };

  return (
    <div className="flex items-center">
      <div className="mr-8" style={{ width: "182px" }}>
        <div
          className={`-mt-10 mb-4 text-red-400${
            startValid ? " invisible" : ""
          }`}
        >
          Invalid start date
        </div>
        <div className="flex">
          <DatePicker
            className="-mt-4"
            label="Start Date"
            placeholder="MM-DD-YYYY"
            value={startDate}
            minDate={minDate}
            maxDate={maxDate}
            iconLeft={eventIconElement}
            onChange={handleEditStartDate}
          />
          <div className="relative top-7 left-0 z-10 w-0 h-px">
            <div className="flex w-6 items-center">
              <div className="w-3 h-px bg-gray-200" />
              <div className={circleCN} />
            </div>
          </div>
        </div>
      </div>
      <div className="mr-3" style={{ width: "182px" }}>
        <div
          className={`-mt-10 mb-4 text-red-400${endValid ? " invisible" : ""}`}
        >
          Invalid end date
        </div>
        <DatePicker
          className="-mt-4"
          label="End Date"
          placeholder="MM-DD-YYYY"
          value={endDate}
          minDate={minDate ?? startDate}
          maxDate={maxDate}
          iconLeft={eventIconElement}
          onChange={handleEditEndDate}
        />
      </div>

      {saveOnFinish && (
        <div className="flex items-center mt-auto ml-2">
          <TertiaryButton
            className="mr-2"
            title="Cancel"
            onClick={onCancelEditing}
          />
          <SecondaryButton
            className="mr-2"
            title="Save"
            onClick={handleFinishEditing}
          />
        </div>
      )}
    </div>
  );
};

DateRangePicker.propTypes = {
  initialStart: PropTypes.string,
  initialEnd: PropTypes.string,
  onChangeStartDate: PropTypes.func,
  onChangeEndDate: PropTypes.func,
  onFinishEditing: PropTypes.func,
  onCancelEditing: PropTypes.func,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  startValid: PropTypes.bool,
  endValid: PropTypes.bool,
  saveOnFinish: PropTypes.bool,
};

DateRangePicker.defaultProps = {
  initialStart: undefined,
  initialEnd: undefined,
  onChangeStartDate: () => {},
  onChangeEndDate: () => {},
  onFinishEditing: () => {},
  onCancelEditing: () => {},
  minDate: undefined,
  maxDate: undefined,
  startValid: true,
  endValid: true,
  saveOnFinish: undefined,
};

export default DateRangePicker;

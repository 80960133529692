import { useCallback, useEffect, useMemo, useState } from "react";
import { useModalState } from "../../../state/modalState";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useReportsTableFormat from "../../../hooks/useReportsTableFormat";
import { ADD_OPEN_MODAL, REPORT_CREATE_MODAL } from "../../../constants";

const useReportsOverviewData = (association, setButtonActions) => {
  const [, modalDispatch] = useModalState();
  const { data: currentUser } = useCurrentUser();

  // Query selector to get reports by association
  const { data: reports = [], isLoading } = useReportsTableFormat({
    association,
  });

  // State to hold report id selected
  const [reportId, setReportId] = useState();

  const canCreate = useMemo(
    () =>
      currentUser?.isAdmin ||
      currentUser?.hasPermission?.("report", "can_write"),
    [currentUser]
  );

  const handleOpenModal = useCallback(() => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: "new-report-modal" },
      modalData: {
        association,
      },
      modalType: REPORT_CREATE_MODAL,
    });
  }, [modalDispatch, association]);

  // Add functionality to "Add Report" button in top header, if Report list is rendered inside association page (P/P/A)
  useEffect(() => {
    if (reportId || !setButtonActions) return;

    if (canCreate) {
      setButtonActions([
        {
          title: "Add Report",
          onClick: handleOpenModal,
          tabAction: true,
        },
      ]);
    }
  }, [reportId, canCreate, setButtonActions, handleOpenModal]);

  return {
    canCreate,
    reports,
    isLoading,
    reportId,
    setReportId,
    handleOpenModal,
  };
};

export default useReportsOverviewData;

const removeDuplicateTsEntries = (array, property) => {
  if (!array) return [];

  const map = new Map();
  array.forEach((item) => {
    map.set(item[property], item);
  });

  return Array.from(map.values());
};

export default removeDuplicateTsEntries;

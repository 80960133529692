import React from "react";
import PropTypes from "prop-types";
import SelectCustomSection from "../SettingsAndConfiguration/SelectCustomSection/SelectCustomSection";

const UnitOfMeasurementSection = ({
  systemSettings,
  dispatchSystemSettings,
}) => {
  return (
    <>
      <SelectCustomSection
        selection={{
          selectionName: "Length/Area",
          defaultValue: "lengthUnits",
          selectionValue: "customLengthUnits",
          selectionItemValue: "display",
        }}
        settings={{
          settings: systemSettings,
          dispatchSettings: dispatchSystemSettings,
        }}
      />
      <SelectCustomSection
        selection={{
          selectionName: "Quantity",
          defaultValue: "quantityUnits",
          selectionValue: "customQuantityUnits",
          selectionItemValue: "display",
        }}
        settings={{
          settings: systemSettings,
          dispatchSettings: dispatchSystemSettings,
        }}
      />
      <SelectCustomSection
        selection={{
          selectionName: "Time",
          defaultValue: "timeUnits",
          selectionValue: "customTimeUnits",
          selectionItemValue: "display",
        }}
        settings={{
          settings: systemSettings,
          dispatchSettings: dispatchSystemSettings,
        }}
      />
      <SelectCustomSection
        selection={{
          selectionName: "Volume",
          defaultValue: "volumeUnits",
          selectionValue: "customVolumeUnits",
          selectionItemValue: "display",
        }}
        settings={{
          settings: systemSettings,
          dispatchSettings: dispatchSystemSettings,
        }}
      />
    </>
  );
};

UnitOfMeasurementSection.propTypes = {
  systemSettings: PropTypes.shape({
    lengthUnits: PropTypes.arrayOf(
      PropTypes.shape({
        display: PropTypes.string,
        id: PropTypes.string,
        selected: PropTypes.bool,
      })
    ),
    customLengthUnits: PropTypes.arrayOf(
      PropTypes.shape({
        display: PropTypes.string,
        id: PropTypes.string,
        selected: PropTypes.bool,
      })
    ),
    quantityUnits: PropTypes.arrayOf(
      PropTypes.shape({
        display: PropTypes.string,
        id: PropTypes.string,
        selected: PropTypes.bool,
      })
    ),
    customQuantityUnits: PropTypes.arrayOf(
      PropTypes.shape({
        display: PropTypes.string,
        id: PropTypes.string,
        selected: PropTypes.bool,
      })
    ),
    timeUnits: PropTypes.arrayOf(
      PropTypes.shape({
        display: PropTypes.string,
        id: PropTypes.string,
        selected: PropTypes.bool,
      })
    ),
    customTimeUnits: PropTypes.arrayOf(
      PropTypes.shape({
        display: PropTypes.string,
        id: PropTypes.string,
        selected: PropTypes.bool,
      })
    ),
    volumeUnits: PropTypes.arrayOf(
      PropTypes.shape({
        display: PropTypes.string,
        id: PropTypes.string,
        selected: PropTypes.bool,
      })
    ),
    customVolumeUnits: PropTypes.arrayOf(
      PropTypes.shape({
        display: PropTypes.string,
        id: PropTypes.string,
        selected: PropTypes.bool,
      })
    ),
  }),
  dispatchSystemSettings: PropTypes.func,
};

UnitOfMeasurementSection.defaultProps = {
  systemSettings: undefined,
  dispatchSystemSettings: undefined,
};

export default UnitOfMeasurementSection;

import React from "react";
import ModalWrapper from "../ModalWrapper";
import ContactFormContainer from "../../../../Pages/Contact/ContactFormContainer";
import useCreateContactModal from "./useCreateContactModal";

const CreateContactModal = ({ modalData }) => {
  const {
    createButtonHandler,
    invalidForm,
    saving,
    showConfirm,
    contactDispatch,
    contact,
    memberEmails,
    handleInviteButton,
    modalRef,
    inviteStatus,
    tmpAvatarImg,
    setTmpAvatarImg,
  } = useCreateContactModal({ modalData });

  return (
    <ModalWrapper
      title="Create Contact"
      resourceName="Contact"
      primaryButtonOnClick={createButtonHandler}
      disabled={invalidForm || saving}
      modalData={modalData}
      showConfirm={showConfirm}
      width="839px"
      wrapperClassName={saving && "loading"}
    >
      <ContactFormContainer
        contactDispatch={contactDispatch}
        contact={contact}
        memberEmails={memberEmails}
        contactType={modalData?.contactType}
        handleInviteButton={handleInviteButton}
        modalRef={modalRef}
        inviteStatus={inviteStatus}
        tmpAvatarImg={tmpAvatarImg}
        setTmpAvatarImg={setTmpAvatarImg}
      />
    </ModalWrapper>
  );
};

export default CreateContactModal;

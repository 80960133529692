import React from "react";
import PropTypes from "prop-types";
import TertiaryButton from "../Buttons/TertiaryButton";

const BudgetTableReallocationToast = ({ onCancel }) => {
  return (
    <div className="flex flex-row">
      <div className="pr-2">
        Please select a line item to reallocate the amount to.
      </div>
      <TertiaryButton
        title="Cancel"
        onClick={onCancel}
        className="rounded-md capitalize"
      />
    </div>
  );
};

BudgetTableReallocationToast.propTypes = {
  onCancel: PropTypes.func,
};

BudgetTableReallocationToast.defaultProps = {
  onCancel: undefined,
};
export default BudgetTableReallocationToast;

import {
  ADMIN_BILLING_PATH,
  //  Post 3.0
  // APPROVE_HOURS_PATH,
  // CALENDAR_PATH,
  GET_ASSET_DOCUMENT_PATH,
  GET_ASSET_VIEW_PATH,
  GET_PROJECTS_PATH,
  GET_PROJECT_ASSETS_PATH,
  GET_PROJECT_BUDGET_PATH,
  GET_PROJECT_DOCUMENTS_PATH,
  GET_PROJECT_DETAILS_PATH,
  //  GET_PROJECT_INVENTORY_PATH,
  GET_PROJECT_WORKFLOWS_PATH,
  HOME_PATH,
  PROFILE_PATH,
  //  Post 3.0
  // REPORT_HOURS_PATH,
  SETTINGS_PATH,
  PROJECT_CREATION_PATH,
  GET_PROPERTY_VIEW_PATH,
  GET_PROPERTY_DOCUMENT_PATH,
  GET_PROPERTY_FINANCE_PATH,
  // GET_PROPERTY_WORKFLOWS_PATH,
  MEMBERS_PATH,
  GET_ASSET_FINANCES_PATH,
  GET_PROPERTY_ASSETS_PATH,
  GET_PROPERTY_ASSET_PATH,
  GET_PROPERTY_ASSET_DOCUMENTS_PATH,
  GET_PROPERTY_ASSET_FINANCIALS_PATH,
  GET_PROPERTY_ASSET_MAINTENANCE_PATH,
  GET_PROJECT_ASSET_MAINTENANCE_PATH,
  GET_ASSET_MAINTENANCE_PATH,
  GET_PROJECT_FINANCIALS_PATH,
  GET_PROJECT_ASSET_DOCUMENTS_PATH,
  GET_PROJECT_ASSET_FINANCIALS_PATH,
  GET_PROJECT_ASSET_PATH,
  GET_LOCATION_VIEW_PATH,
  GET_SPACE_VIEW_PATH,
  REPORT_HOURS_PATH,
  APPROVE_HOURS_PATH,
  GET_TIMESHEET_APPROVAL_VIEW_PATH,
  GET_PROJECT_EXPENSES_PATH,
  GET_PROPERTY_EXPENSES_PATH,
  GET_ASSET_EXPENSES_PATH,
  PAYROLL_PATH,
  TIME_OFF_APPROVAL_PATH,
  GET_PROJECT_ASSET_EXPENSES_PATH,
  GET_PROPERTY_ASSET_EXPENSES_PATH,
  MAINTENANCE_TASKS_PATH,
  PROPERTY_CALENDAR_PATH,
  PROJECT_CALENDAR_PATH,
  GET_LOCATION_ASSETS_PATH,
  GET_LOCATION_SPACE_ASSETS_PATH,
  GET_PROPERTY_SPACE_DOCUMENT_PATH,
  GET_LOCATION_DOCUMENT_PATH,
  GET_TIMESHEET_APPROVAL_EDIT_PATH,
  GET_LOCATION_ASSET_VIEW_PATH,
  GET_LOCATION_ASSET_DOCUMENTS_PATH,
  GET_LOCATION_ASSET_EXPENSES_PATH,
  GET_LOCATION_ASSET_FINANCIALS_PATH,
  GET_LOCATION_ASSET_MAINTENANCE_PATH,
  GET_LOCATION_SPACE_ASSET_VIEW_PATH,
  GET_LOCATION_SPACE_ASSET_DOCUMENTS_PATH,
  GET_LOCATION_SPACE_ASSET_EXPENSES_PATH,
  GET_LOCATION_SPACE_ASSET_FINANCIALS_PATH,
  GET_LOCATION_SPACE_ASSET_MAINTENANCE_PATH,
  GET_PROJECT_SPACE_DOCUMENT_PATH,
  GET_PROPERTY_MAINTENANCE_PATH,
  // GET_PROJECT_SUBMITTALS_PATH,
  // GET_PROJECT_RFI_PATH,
  // GET_PROPERTY_RFI_PATH,
  TIME_OFF_HISTORY_BY_USER_PATH,
  ADMIN_ROLES_PERMISSIONS_PATH,
  ASSET,
  // GET_SPACES_OVERVIEW_PATH,
} from "../constants";
import { hasReadPermission } from "./Permissions";

/**
 * @param {*} tabIndex - active tab index
 * @returns - Array of Dropdown Routes
 * @summary - Home Routes Are Dynamic
 */
export const getHomeOptions = (tabIndex = 0, user, userId, date) => {
  const routes = [
    {
      name: "Home",
      path: HOME_PATH,
    },
    {
      name: "My Profile",
      path: PROFILE_PATH(tabIndex, "/Home"),
    },
    {
      name: "My Timecard",
      path: REPORT_HOURS_PATH("/Home"),
    },
  ];
  if (user?.hasPermission("timesheet", "can_approve")) {
    routes.push({
      name: "Timecard Approval",
      path: APPROVE_HOURS_PATH,
    });
    routes.push({
      name: "Submitted Time",
      path: GET_TIMESHEET_APPROVAL_VIEW_PATH(userId, date),
    });

    if (user?.hasPermission("corporate", "can_approve_payroll")) {
      routes.push({
        name: "Payroll Approval",
        path: PAYROLL_PATH,
      });
    }
  }

  return routes;
};

export const getAccountAndAdminOptions = () => [
  {
    name: "Settings & Configuration",
    path: SETTINGS_PATH,
  },
  {
    name: "Billing Management",
    path: ADMIN_BILLING_PATH,
  },
  {
    name: "Member Management",
    path: MEMBERS_PATH,
  },
  {
    name: "Roles & Permissions",
    path: ADMIN_ROLES_PERMISSIONS_PATH,
  },
];

export const getProjectSpaceOptions = (
  projectId,
  currentUser,
  locationId,
  spaceId
) => {
  const options = [];

  if (hasReadPermission(ASSET, currentUser)) {
    options.push({
      name: "Assets",
      path: GET_LOCATION_SPACE_ASSETS_PATH.project(
        projectId,
        locationId,
        spaceId
      ),
    });
  }

  options.push({
    name: "Documents",
    path: GET_PROJECT_SPACE_DOCUMENT_PATH(projectId, locationId, spaceId),
  });

  options.push({
    name: "Details",
    path: GET_SPACE_VIEW_PATH.project(projectId, locationId, spaceId),
  });

  return options;
};

/**
 * Defines the entries for the dropdowns accessible by viewing  Property.
 */

export const getProjectLocationOptions = (
  projectId,
  currentUser,
  locationId,
  spaceId
) => {
  const options = [];

  if (hasReadPermission(ASSET, currentUser)) {
    options.push({
      name: "Assets",
      path: GET_LOCATION_ASSETS_PATH.project(projectId, locationId),
    });
  }

  options.push({
    name: "Documents",
    path: GET_LOCATION_DOCUMENT_PATH.project(projectId, locationId),
  });

  options.push({
    name: "Details",
    path: GET_LOCATION_VIEW_PATH.project(projectId, locationId),
    subPath: !spaceId
      ? GET_LOCATION_VIEW_PATH.project(projectId, locationId)
      : GET_SPACE_VIEW_PATH.project(projectId, locationId, spaceId),
  });

  return options;
};

export const getProjectOptions = (projectId, currentUser) => {
  /**
   * Post 3.3
   */
  // {
  //   name: "Dashboard",
  //   path: GET_PROJECTS_PATH(projectId),
  // },
  // {
  //   name: "Inventory",
  //   path: GET_PROJECT_INVENTORY_PATH(projectId),
  // },
  // {
  //     name: "Media",
  //     permissionKey: "project",
  //     path: GET_PROJECT_MEDIA_PATH(projectId),
  //   },
  const options = [
    {
      name: "Details",
      permissionKey: "project",
      path: GET_PROJECT_DETAILS_PATH(projectId),
    },
    {
      name: "Budget",
      permissionKey: "budget",
      path: GET_PROJECT_BUDGET_PATH(projectId),
      isBeta: true,
    },
    {
      name: "Financial Documents",
      permissionKey: "document",
      path: GET_PROJECT_DOCUMENTS_PATH(projectId),
    },
    {
      name: "Financials",
      permissionKey: "project",
      permissionSubKey: "financials",
      path: GET_PROJECT_FINANCIALS_PATH(projectId),
      isBeta: true,
    },
    {
      name: "Workflows",
      permissionKey: "workflow",
      path: GET_PROJECT_WORKFLOWS_PATH(projectId),
    },
    {
      name: "Assets",
      permissionKey: "asset",
      path: GET_PROJECT_ASSETS_PATH(projectId),
    },
  ];

  const permissionedOptions = options.reduce((opts, testCase) => {
    if (testCase?.permissionSubKey) {
      if (
        currentUser?.permissions?.[testCase?.permissionKey]?.[
          `can_read_${testCase?.permissionSubKey}`
        ]
      )
        opts?.push(testCase);
    } else if (
      currentUser?.permissions?.[testCase?.permissionKey]?.can_read ||
      currentUser?.permissions?.[testCase?.permissionKey]?.can_write
    ) {
      opts?.push(testCase);
    }
    return opts;
  }, []);

  if (hasReadPermission(ASSET, currentUser)) {
    // permissionedOptions.push({
    //   name: "Spaces",
    //   path: GET_SPACES_OVERVIEW_PATH(projectId),
    // });
    permissionedOptions.push({
      name: "Expenses",
      permissionKey: "expense",
      path: GET_PROJECT_EXPENSES_PATH(projectId),
    });
    // TODO: hiding for 3.6.1
    // if (currentUser?.hasPermission?.("submittal", "can_see")) {
    //   permissionedOptions.push({
    //     name: "Submittals",
    //     permissionKey: "submittal",
    //     path: GET_PROJECT_SUBMITTALS_PATH(projectId),
    //   });
    // }

    // permissionedOptions.push({
    //   name: "RFI",
    //   path: GET_PROJECT_RFI_PATH(projectId),
    // });
  }

  permissionedOptions.push({
    name: "Calendar",
    path: PROJECT_CALENDAR_PATH(projectId),
  });

  permissionedOptions.push({
    name: "Dashboard",
    path: GET_PROJECTS_PATH(projectId),
  });

  return permissionedOptions;
};

const getPath = (
  assetId,
  propId,
  propPathFunc,
  projId,
  projPathFunc,
  locId,
  propLocPathFunc,
  projLocPathFunc,
  spaceId,
  propLocSpacePathFunc,
  projLocSpacePathFunc
) => {
  if (propId) {
    if (locId) {
      if (spaceId) {
        return propLocSpacePathFunc(propId, locId, spaceId, assetId);
      }
      return propLocPathFunc(propId, locId, assetId);
    }
    return propPathFunc(propId, assetId);
  }
  if (projId) {
    if (locId) {
      if (spaceId) {
        return projLocSpacePathFunc(projId, locId, spaceId, assetId);
      }
      return projLocPathFunc(projId, locId, assetId);
    }
    return projPathFunc(projId, assetId);
  }
  return null;
};

export const getAssetOptions = (
  assetId,
  assetProperty,
  assetProject,
  assetLocation,
  assetSpace
) => {
  const propertyId = assetProperty ?? assetProject;
  return [
    {
      name: "Details",
      path: propertyId
        ? getPath(
            assetId,
            assetProperty,
            GET_PROPERTY_ASSET_PATH,
            assetProject,
            GET_PROJECT_ASSET_PATH,
            assetLocation,
            GET_LOCATION_ASSET_VIEW_PATH.property,
            GET_LOCATION_ASSET_VIEW_PATH.project,
            assetSpace,
            GET_LOCATION_SPACE_ASSET_VIEW_PATH.property,
            GET_LOCATION_SPACE_ASSET_VIEW_PATH.project
          )
        : GET_ASSET_VIEW_PATH(assetId),
    },
    {
      name: "Documents",
      path: propertyId
        ? getPath(
            assetId,
            assetProperty,
            GET_PROPERTY_ASSET_DOCUMENTS_PATH,
            assetProject,
            GET_PROJECT_ASSET_DOCUMENTS_PATH,
            assetLocation,
            GET_LOCATION_ASSET_DOCUMENTS_PATH.property,
            GET_LOCATION_ASSET_DOCUMENTS_PATH.project,
            assetSpace,
            GET_LOCATION_SPACE_ASSET_DOCUMENTS_PATH.property,
            GET_LOCATION_SPACE_ASSET_DOCUMENTS_PATH.project
          )
        : GET_ASSET_DOCUMENT_PATH(assetId),
    },
    {
      name: "Expenses",
      path: propertyId
        ? getPath(
            assetId,
            assetProperty,
            GET_PROPERTY_ASSET_EXPENSES_PATH,
            assetProject,
            GET_PROJECT_ASSET_EXPENSES_PATH,
            assetLocation,
            GET_LOCATION_ASSET_EXPENSES_PATH.property,
            GET_LOCATION_ASSET_EXPENSES_PATH.project,
            assetSpace,
            GET_LOCATION_SPACE_ASSET_EXPENSES_PATH.property,
            GET_LOCATION_SPACE_ASSET_EXPENSES_PATH.project
          )
        : GET_ASSET_EXPENSES_PATH(assetId),
    },
    {
      name: "Financials",
      path: propertyId
        ? getPath(
            assetId,
            assetProperty,
            GET_PROPERTY_ASSET_FINANCIALS_PATH,
            assetProject,
            GET_PROJECT_ASSET_FINANCIALS_PATH,
            assetLocation,
            GET_LOCATION_ASSET_FINANCIALS_PATH.property,
            GET_LOCATION_ASSET_FINANCIALS_PATH.project,
            assetSpace,
            GET_LOCATION_SPACE_ASSET_FINANCIALS_PATH.property,
            GET_LOCATION_SPACE_ASSET_FINANCIALS_PATH.project
          )
        : GET_ASSET_FINANCES_PATH(assetId),
    },
    {
      name: "Maintenance",
      path: propertyId
        ? getPath(
            assetId,
            assetProperty,
            GET_PROPERTY_ASSET_MAINTENANCE_PATH,
            assetProject,
            GET_PROJECT_ASSET_MAINTENANCE_PATH,
            assetLocation,
            GET_LOCATION_ASSET_MAINTENANCE_PATH.property,
            GET_LOCATION_ASSET_MAINTENANCE_PATH.project,
            assetSpace,
            GET_LOCATION_SPACE_ASSET_MAINTENANCE_PATH.property,
            GET_LOCATION_SPACE_ASSET_MAINTENANCE_PATH.project
          )
        : GET_ASSET_MAINTENANCE_PATH(assetId),
    },
  ];
};

export const getProjectAssetOptions = (assetId, assetProperty) => {
  let projectId;
  if (assetProperty) projectId = assetProperty;
  return [
    {
      name: "Details",
      path: assetProperty
        ? GET_PROJECT_ASSET_PATH(projectId, assetId)
        : GET_ASSET_VIEW_PATH(assetId),
    },
    {
      name: "Documents",
      path: assetProperty
        ? GET_PROPERTY_ASSET_DOCUMENTS_PATH(projectId, assetId)
        : GET_ASSET_DOCUMENT_PATH(assetId),
    },
    {
      name: "Financials",
      path: assetProperty
        ? GET_PROPERTY_ASSET_FINANCIALS_PATH(projectId, assetId)
        : GET_ASSET_FINANCES_PATH(assetId),
    },
    {
      name: "Maintenance",
      path: assetProperty
        ? GET_PROJECT_ASSET_MAINTENANCE_PATH(projectId, assetId)
        : GET_ASSET_MAINTENANCE_PATH(assetId),
    },
    {
      name: "Expenses",
      path: assetProperty
        ? GET_PROJECT_ASSET_EXPENSES_PATH(projectId, assetId)
        : GET_ASSET_EXPENSES_PATH(assetId),
    },
  ];
};

export const getMaintenanceTaskOptions = () => [
  {
    name: "Maintenance",
    path: MAINTENANCE_TASKS_PATH,
  },
];

export const getTimeCardOptions = (user, userId, date) => {
  const options = [];
  if (user?.isEmployee) {
    options.push({
      name: "My Timecard",
      path: "/timecard",
    });
    options.push({
      name: "Request Time Off",
      path: "/timeoff/history",
    });
  }
  if (
    user?.hasPermission &&
    user?.hasPermission("corporate", "can_give_timeoff")
  ) {
    options.push({
      name: "Time Off Approval",
      path: TIME_OFF_APPROVAL_PATH,
    });

    options.push({
      name: "Time Off Requests",
      path: TIME_OFF_HISTORY_BY_USER_PATH(userId),
    });
  }
  if (user?.hasPermission && user?.hasPermission("timesheet", "can_approve")) {
    options.push({
      name: "Timecard Approval",
      path: APPROVE_HOURS_PATH,
    });
    if (user?.hasPermission("corporate", "can_approve_payroll")) {
      options.push({
        name: "Payroll Approval",
        path: PAYROLL_PATH,
      });
      options.push({
        name: "Submitted Time",
        path: GET_TIMESHEET_APPROVAL_EDIT_PATH(userId, date),
      });
      options.push({
        name: "Submitted Time",
        path: GET_TIMESHEET_APPROVAL_VIEW_PATH(userId, date),
      });
    }
  }

  return options;
};

export const getProjectListOptions = () => [
  {
    name: "Create",
    path: PROJECT_CREATION_PATH,
  },
];

/**
 * Defines the entries for the dropdowns accessible by viewing  Property.
 */

export const getPropertySpaceOptions = (
  propertyId,
  currentUser,
  locationId,
  spaceId
) => {
  const options = [];

  if (hasReadPermission(ASSET, currentUser)) {
    options.push({
      name: "Assets",
      path: GET_LOCATION_SPACE_ASSETS_PATH.property(
        propertyId,
        locationId,
        spaceId
      ),
    });
  }

  options.push({
    name: "Documents",
    path: GET_PROPERTY_SPACE_DOCUMENT_PATH(propertyId, locationId, spaceId),
  });

  options.push({
    name: "Details",
    path: GET_SPACE_VIEW_PATH.property(propertyId, locationId, spaceId),
  });

  return options;
};

/**
 * Defines the entries for the dropdowns accessible by viewing  Property.
 */

export const getPropertyLocationOptions = (
  propertyId,
  currentUser,
  locationId,
  spaceId
) => {
  const options = [];

  if (hasReadPermission(ASSET, currentUser)) {
    options.push({
      name: "Assets",
      path: GET_LOCATION_ASSETS_PATH.property(propertyId, locationId),
    });
  }

  options.push({
    name: "Documents",
    path: GET_LOCATION_DOCUMENT_PATH.property(propertyId, locationId),
  });

  options.push({
    name: "Details",
    path: GET_LOCATION_VIEW_PATH.property(propertyId, locationId),
    subPath: !spaceId
      ? GET_LOCATION_VIEW_PATH.property(propertyId, locationId)
      : GET_SPACE_VIEW_PATH.property(propertyId, locationId, spaceId),
  });

  return options;
};

/**
 * Defines the entries for the dropdowns accessible by viewing a single Property.
 */
export const getPropertyOptions = (propertyId, currentUser) => {
  const options = [
    {
      name: "Details",
      path: GET_PROPERTY_VIEW_PATH(propertyId),
    },
  ];

  options.push({
    name: "Documents",
    path: GET_PROPERTY_DOCUMENT_PATH(propertyId),
  });

  if (hasReadPermission(ASSET, currentUser)) {
    options.push({
      name: "Assets",
      path: GET_PROPERTY_ASSETS_PATH(propertyId),
    });

    options.push({
      name: "Calendar",
      path: PROPERTY_CALENDAR_PATH(propertyId),
    });
    if (currentUser?.hasPermission?.("expense", "can_read")) {
      options.push({
        name: "Expenses",
        path: GET_PROPERTY_EXPENSES_PATH(propertyId),
      });
    }

    if (currentUser?.hasPermission?.("property", "can_read_financials")) {
      options.push({
        name: "Financials",
        path: GET_PROPERTY_FINANCE_PATH(propertyId),
      });
    }

    // if (currentUser?.hasPermission?.("workflow", "can_read")) {
    //   options.push({
    //     name: "Workflows",
    //     path: GET_PROPERTY_WORKFLOWS_PATH(propertyId),
    //   });
    // }

    if (currentUser?.hasPermission?.("task", "can_read")) {
      options.push({
        name: "Maintenance",
        path: GET_PROPERTY_MAINTENANCE_PATH(propertyId),
      });
    }
  }

  return options;
};

export const getLocationResourceOptions = (
  resource,
  id,
  currentUser,
  locationId,
  spaceId
) => {
  const resourceOptions = {
    Project: getProjectLocationOptions(id, currentUser, locationId, spaceId),
    Property: getPropertyLocationOptions(id, currentUser, locationId, spaceId),
  };
  return resourceOptions[resource];
};

export const getAllLocationResourceOptions = (
  resource,
  id,
  projectId,
  currentUser,
  locationId,
  spaceId
) => {
  const resourceOptions = {
    Project: getProjectOptions(id, projectId, currentUser, locationId, spaceId),
    Property: getPropertyOptions(id, currentUser, locationId, spaceId),
  };
  return resourceOptions[resource];
};

export const getLocationResourceSpaceOptions = (
  resource,
  id,
  currentUser,
  locationId,
  spaceId
) => {
  const resourceOptions = {
    Project: getProjectSpaceOptions(id, currentUser, locationId, spaceId),
    Property: getPropertySpaceOptions(id, currentUser, locationId, spaceId),
  };
  return resourceOptions[resource];
};

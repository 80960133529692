/**
 * Returns a resource name for saving columns in Settings API and Configuration Templates API.
 * @param {string} resourceName - The table name or resource name.
 * @returns {string|undefined} The resource name, or undefined if not found.
 */
const getTableResourceName = (resourceName = "") => {
  if (resourceName?.includes("document") || resourceName?.includes("budget")) {
    return undefined;
  }
  if (resourceName?.includes("asset")) {
    return "asset";
  }
  if (resourceName?.includes("workflow")) {
    return "workflow";
  }
  if (resourceName?.includes("submitals")) {
    return "submitals";
  }
  if (resourceName?.includes("expense")) {
    return "expense";
  }
  if (
    resourceName === "property" ||
    resourceName === "project" ||
    resourceName === "sop"
  ) {
    return resourceName;
  }

  return resourceName;
};

export default getTableResourceName;

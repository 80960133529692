import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import DocumentUploadForm from "../DocumentUploadForm/DocumentUploadForm";
import FileCard from "../FileCard/FileCard";

import folderIcon from "../../assets/images/folderOpenGreen.svg";
import { fileCardStyle, financialDocumentsType } from "../../../constants";

const DocumentFolderContent = ({
  path,
  contents,
  favorites,
  selectedDocuments,
  docTypeOptionsMap,
  defaultType,
  addedFiles,
  onUpdateDocuments,
  onFileSelect,
  onFileClick,
  onFileEdit,
  onShowHistory,
  onSendToWorkflow,
  onUploadVersion,
  onFileAdd,
  onFileDelete,
  onFavoriteClick,
  onBackClick,
  hideButtons,
  disableChildFileAdd,
  disableUpload,
}) => {
  const [folderContents, setFolderContents] = useState([]);

  useEffect(() => {
    setFolderContents([
      ...addedFiles,
      ...contents.map((item) => item.original ?? item),
    ]);
  }, [contents, addedFiles]);

  const handleFileAdded = (fileRef, fileBody) => {
    onFileAdd(fileRef, { ...fileBody, docType: defaultType });
  };

  const getDocType = (documentType) => {
    if (documentType) {
      return docTypeOptionsMap[documentType]?.label ?? documentType;
    }
    return "File";
  };

  return (
    <>
      <div className="flex items-center mt-4">
        <button type="button" onClick={onBackClick}>
          <p className="text-md">Documents</p>
        </button>
        <p className="mx-3"> / </p>
        <div className="flex items-center">
          <img className="-mt-1 mr-3" src={folderIcon} alt="file icon" />
          <p className="font-semibold">{path ?? "Change Orders"}</p>
        </div>
      </div>

      <div className="flex flex-wrap mt-3">
        {!disableUpload && (
          <DocumentUploadForm
            defaultType={defaultType}
            onFinishUploading={onUpdateDocuments}
            onAddFile={!disableChildFileAdd ? handleFileAdded : undefined}
            onAddPhoto={!disableChildFileAdd ? handleFileAdded : undefined}
            onAddVideo={!disableChildFileAdd ? handleFileAdded : undefined}
            showCorner
            small
          />
        )}
        {folderContents?.map((doc) => {
          return (
            <div key={doc.id} className="">
              <FileCard
                {...doc}
                docType={getDocType(doc.docType)}
                style={fileCardStyle}
                selectedDocuments={selectedDocuments}
                createdAt={
                  doc.metadata?.createdAt
                    ? moment(doc.metadata.createdAt).format("MM/DD/YYYY")
                    : "--"
                }
                isFavorited={
                  doc.isFavorited
                    ? doc.isFavorited
                    : favorites.some((fav) =>
                        fav.item.reference.includes(doc.id)
                      )
                }
                isDraft={doc.status === "draft"}
                onFileSelect={onFileSelect}
                onFileClick={() => onFileClick(doc)}
                onFileEdit={() => onFileEdit(doc)}
                onShowHistory={onShowHistory}
                onSendToWorkflow={() => onSendToWorkflow(doc)}
                onUploadVersion={onUploadVersion}
                onFileDelete={() => onFileDelete(doc)}
                onFavoriteClick={() => onFavoriteClick(doc.id)}
                hideButtons={hideButtons}
                isDisabled={financialDocumentsType[doc.docType]}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

DocumentFolderContent.propTypes = {
  /**
   * folder path to display
   */
  path: PropTypes.string,
  /**
   * folder contents
   */
  // eslint-disable-next-line react/forbid-prop-types
  contents: PropTypes.array,
  /**
   * array of favortied documents
   */
  // eslint-disable-next-line react/forbid-prop-types
  favorites: PropTypes.arrayOf(PropTypes.object),
  /**
   * array of attached documents
   */
  selectedDocuments: PropTypes.arrayOf(PropTypes.string),
  /**
   * map of all document type options
   */
  // eslint-disable-next-line react/forbid-prop-types
  docTypeOptionsMap: PropTypes.object,
  /**
   * default document type
   */
  // eslint-disable-next-line react/forbid-prop-types
  defaultType: PropTypes.string,
  /**
   * array of added files
   */
  // eslint-disable-next-line react/forbid-prop-types
  addedFiles: PropTypes.array,
  /**
   * function called after new document is uploaded
   */
  onUpdateDocuments: PropTypes.func,
  /**
   * function for handling selection of a document
   */
  onFileSelect: PropTypes.func,
  /**
   * function for handling file click
   */
  onFileClick: PropTypes.func,
  /**
   * function for handling file edit
   */
  onFileEdit: PropTypes.func,
  /**
   * function for handling file show history
   */
  onShowHistory: PropTypes.func,
  /**
   * function for handling file send to workflow
   */
  onSendToWorkflow: PropTypes.func,
  /**
   * function for handling file upload version
   */
  onUploadVersion: PropTypes.func,
  /**
   * function for file upload
   */
  onFileAdd: PropTypes.func,
  /**
   * function for handling file delete
   */
  onFileDelete: PropTypes.func,
  /**
   * function for handling favoriting of a document
   */
  onFavoriteClick: PropTypes.func,
  /**
   * handle click back to documents view
   */
  onBackClick: PropTypes.func,
  /**
   * boolean for hiding document buttons
   */
  hideButtons: PropTypes.bool,
  /**
   * disable file Upload
   */
  disableChildFileAdd: PropTypes.bool,
  disableUpload: PropTypes.bool,
};

DocumentFolderContent.defaultProps = {
  path: undefined,
  contents: [],
  favorites: [],
  selectedDocuments: [],
  docTypeOptionsMap: {},
  defaultType: undefined,
  addedFiles: [],
  onUpdateDocuments: undefined,
  onFileSelect: undefined,
  onFileClick: undefined,
  onFileEdit: undefined,
  onShowHistory: undefined,
  onSendToWorkflow: undefined,
  onUploadVersion: undefined,
  onFileAdd: () => {},
  onFileDelete: undefined,
  onFavoriteClick: undefined,
  onBackClick: undefined,
  hideButtons: false,
  disableChildFileAdd: false,
  disableUpload: false,
};

export default DocumentFolderContent;

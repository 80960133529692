import React from "react";
import PropTypes from "prop-types";
import { PASSWORD_SPECIAL_CHARS } from "../../../constants";
import Checkbox from "../Checkbox/Checkbox";

function PasswordValidationRulesList({
  passwordValidation,
  isValidPassword,
  className,
  checkboxClassName,
  labelClassName,
}) {
  return (
    <div className={className}>
      <p className=" text-es-normal text-es-medium-grey font-es-semibold tracking-es-wide">
        Password must contain:
      </p>
      <Checkbox
        className="mb-1"
        type="radio"
        label="8 character minimum"
        checked={passwordValidation.hasMinLength}
        onChange={() => {}}
        inputClassName={checkboxClassName}
        labelClassName={labelClassName}
      />
      <Checkbox
        className="mb-1"
        type="radio"
        label={`At least 1 special character: ${PASSWORD_SPECIAL_CHARS}`}
        checked={passwordValidation.hasSpecialChar}
        onChange={() => {}}
        inputClassName={checkboxClassName}
        labelClassName={labelClassName}
      />
      <Checkbox
        className="mb-1"
        type="radio"
        label="At least 1 number (0-9)"
        checked={passwordValidation.hasNumber}
        onChange={() => {}}
        inputClassName={checkboxClassName}
        labelClassName={labelClassName}
      />
      <Checkbox
        className="mb-1"
        type="radio"
        label="At least 1 UPPERCASE letter (A-Z)"
        checked={passwordValidation.hasUppercase}
        onChange={() => {}}
        inputClassName={checkboxClassName}
        labelClassName={labelClassName}
      />
      <Checkbox
        className="mb-1"
        type="radio"
        label="At least 1 LOWERCASE letter (a-z)"
        checked={passwordValidation.hasLowercase}
        onChange={() => {}}
        inputClassName={checkboxClassName}
        labelClassName={labelClassName}
      />
      <Checkbox
        className="mb-1"
        type="radio"
        label="No invalid characters"
        checked={passwordValidation.noInvalidChars}
        onChange={() => {}}
        inputClassName={checkboxClassName}
        labelClassName={labelClassName}
      />
      <Checkbox
        type="radio"
        label="Passwords must match"
        checked={isValidPassword}
        onChange={() => {}}
        inputClassName={checkboxClassName}
        labelClassName={labelClassName}
      />
    </div>
  );
}

PasswordValidationRulesList.propTypes = {
  passwordValidation: PropTypes.shape({
    hasMinLength: PropTypes.bool,
    hasSpecialChar: PropTypes.bool,
    hasNumber: PropTypes.bool,
    hasUppercase: PropTypes.bool,
    hasLowercase: PropTypes.bool,
    noInvalidChars: PropTypes.bool,
  }),
  isValidPassword: PropTypes.bool,
  className: PropTypes.string,
  checkboxClassName: PropTypes.string,
  labelClassName: PropTypes.string,
};

PasswordValidationRulesList.defaultProps = {
  className: "",
  checkboxClassName: "",
  labelClassName: "",
  isValidPassword: false,
  passwordValidation: {
    hasMinLength: false,
    hasSpecialChar: false,
    hasNumber: false,
    hasUppercase: false,
    hasLowercase: false,
    noInvalidChars: false,
  },
};

export default PasswordValidationRulesList;

/* eslint-disable no-param-reassign */
import { cloneDeep } from "lodash";
import { useReducer } from "react";

const reducer = (timesheets, action) => {
  switch (action.type) {
    case "reset": {
      return action.timesheets ?? [];
    }
    case "editRow": {
      const rows = timesheets;
      rows[action.index] = {
        ...rows[action.index],
        payrollStatus: action.value,
      };
      return cloneDeep(rows);
    }
    case "editRows": {
      const rows = timesheets;
      action.selected.forEach((item) => {
        rows[item.index] = { ...rows[item.index], payrollStatus: action.value };
      });
      return cloneDeep(rows);
    }
    default:
      return [];
  }
};

export default () => {
  return useReducer(reducer, []);
};

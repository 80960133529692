import React from "react";
import PropTypes from "prop-types";
import PasswordValidationRulesList from "../../PasswordValidationRulesList/PasswordValidationRulesList";
import useNewPasswordForm from "./useNewPasswordForm";
import newPasswordImage from "../../../assets/images/newPasswordImage.svg";
import visibilityIcon from "../../../assets/images/visibleIconGrey.svg";
import hideVisibilityIcon from "../../../assets/images/visibilityHiddenIconGrey.svg";
import PrimaryButton from "../../Buttons/PrimaryButton";
import {
  listCN,
  titleCN,
  titleStyles,
  container,
  containerStyles,
  inputLabel,
  asteriskCN,
  inputStyle,
  visibilityIconStyle,
  rightContainerStyle,
  logoStyles,
  bulletedListStyles,
  buttonStyle,
  fadeInContainer,
} from "./newPasswordFormStyles";

const NewPasswordForm = ({ handleNext, temporaryPassword, email }) => {
  const {
    passwordValidation,
    confirmPasswordRef,
    confirmTabRef,
    passwordShown,
    setPasswordShown,
    showSecondPass,
    setShowSecondPass,
    passwordInputRef,
    createPasswordRef,
    isLoaded,
    isValidPassword,
    newPassword,
    setNewPassword,
    confirmNewPassword,
    setConfirmNewPassword,
    handleSubmitNewPassword,
    isSaving,
  } = useNewPasswordForm({
    temporaryPassword,
    email,
    handleNext,
  });

  return (
    <div className={fadeInContainer(isLoaded)}>
      <p className={titleCN} style={titleStyles}>
        Welcome To EstateSpace
      </p>
      <div className={container} style={containerStyles}>
        {/* Left container */}
        <div className="flex flex-col w-1/2 p-10 gap-8 justify-between">
          <p className="text-es-dark-grey text-es-lg tracking-es-wide font-es-semibold">
            Please create a new password to accept your member invitation.
          </p>

          <div style={{ position: "relative", width: "100%" }}>
            <p className={inputLabel}>
              New Password
              <span className={asteriskCN}>*</span>
            </p>
            <input
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              type={!passwordShown ? "password" : "text"}
              placeholder="Your Password"
              style={inputStyle}
              className="rounded border text-es-medium-grey bg-backgroundGreen"
              ref={passwordInputRef}
              onKeyDown={(event) => {
                if (event.key === "Enter" || event.key === "Tab") {
                  confirmPasswordRef.current.focus();
                }
              }}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
            <button
              style={visibilityIconStyle}
              type="button"
              onClick={() => setPasswordShown(!passwordShown)}
              className="cursor-pointer"
            >
              {passwordShown ? (
                <img alt="visible" src={visibilityIcon} />
              ) : (
                <img alt="hide" src={hideVisibilityIcon} />
              )}
            </button>
          </div>

          <PasswordValidationRulesList
            passwordValidation={passwordValidation}
            isValidPassword={isValidPassword}
            className="flex flex-col gap-2"
            checkboxClassName="rounded-full bg-none"
            labelClassName="text-es-normal text-black font-es-normal"
          />

          <div
            ref={confirmTabRef}
            style={{ position: "relative", width: "100%" }}
          >
            <p className={inputLabel}>
              Retype Password
              <span className={asteriskCN}>*</span>
            </p>
            <input
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              type={!showSecondPass ? "password" : "text"}
              placeholder="Retype new password"
              style={inputStyle}
              className="rounded border text-es-medium-grey bg-backgroundGreen"
              ref={confirmPasswordRef}
              onKeyDown={(event) => {
                if (event.key === "Enter" && isValidPassword) {
                  handleSubmitNewPassword();
                }
              }}
            />
            <button
              style={visibilityIconStyle}
              type="button"
              onClick={() => setShowSecondPass(!showSecondPass)}
              className="cursor-pointer"
              onFocus={() => {
                confirmPasswordRef.current.focus();
              }}
            >
              {showSecondPass ? (
                <img alt="visible" src={visibilityIcon} />
              ) : (
                <img alt="hide" src={hideVisibilityIcon} />
              )}
            </button>
          </div>

          <div className="flex" style={buttonStyle}>
            <PrimaryButton
              className="w-full h-full flex tracking-es-wide text-base"
              title="Create Password"
              disabled={!isValidPassword || isSaving}
              onClick={handleSubmitNewPassword}
              noMaxWidth
              forwardedRef={(el) => {
                createPasswordRef.current = el;
              }}
              name="login"
            />
          </div>
        </div>

        {/* Right Container */}
        <div
          className="flex flex-col w-1/2 bg-backgroundGrey"
          style={rightContainerStyle}
        >
          <div className="flex flex-col" style={bulletedListStyles}>
            <p className="text-es-normal text-es-dark-grey font-es-bold tracking-es-wide">
              EstateSpace helps you connect and collaborate with your team to
              streamline:
            </p>
            <div className="flex flex-col gap-2 mt-2">
              <div className={listCN}>
                <span className="pr-2">&#8226;</span>Task management and
                schedules
              </div>
              <div className={listCN}>
                <span className="pr-2 h-full">&#8226;</span>Complex business
                workflows and standard operating procedures
              </div>
              <div className={listCN}>
                <span className="pr-2">&#8226;</span>Communication and real-time
                notifications
              </div>
              <div className={listCN}>
                <span className="pr-2">&#8226;</span>Project and financial
                management to include construction, renovations, and small
                projects
              </div>
              <div className={listCN}>
                <span className="pr-2">&#8226;</span>And much more...
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-end h-full">
            <img
              alt="EstateSpace Logo"
              src={newPasswordImage}
              style={logoStyles}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

NewPasswordForm.propTypes = {
  handleNext: PropTypes.func,
  temporaryPassword: PropTypes.string,
  email: PropTypes.string,
};

NewPasswordForm.defaultProps = {
  handleNext: undefined,
  temporaryPassword: undefined,
  email: undefined,
};

export default NewPasswordForm;

import { useMemo } from "react";
// import { taskRecurrenceOptions as recurrenceOptions } from "../../../helpers/Time";

import {
  RECURRENCE_FORM_POPUP,
  TOGGLE_POSITIONED_POPUP,
  repetitionOptions,
} from "../../../constants";
import describeRruleFromString from "../../../helpers/Calendar/describeRruleFromString";
import { useAppState } from "../../../state/appState";

const useRecurrenceDropdown = ({
  recurrence,
  setRecurrence,
  setHumanReadableCustomRecurrence,
  containerRef,
}) => {
  const [, appStateDispatch] = useAppState();

  // Compute the recurrence value based on `recurrence` value.
  // The `useMemo` hook ensures that the computation is only done when `recurrence` changes.
  const recurrenceValue = useMemo(() => {
    // If recurrence is undefined, return null
    if (!recurrence) {
      return null;
    }

    // If recurrence in the form is an object, return it directly
    if (typeof recurrence === "object") {
      return recurrence;
    }

    // If recurrence starts with DTSTART, it is assuming rrule string is a custom recurrence. Then returns in the form
    // of object for the dropdown. This is util if recurrence has an initial value with custom recurrence. If user goes to
    // Custom Recurrence option, the popup will allow to update the initial rrule string.
    if (recurrence.startsWith("DTSTART")) {
      // Transform rrule to human readable here, instead of the popup dispatcher, to ensure
      // the text is displayed on initial render and to avoid unncesary re renders
      setHumanReadableCustomRecurrence(describeRruleFromString(recurrence));

      return {
        label: "Custom Recurrence",
        value: recurrence,
      };
    }

    // Extract the interval from `recurrence`
    let interval = recurrence;

    // Check the type of recurrence and set the interval accordingly
    if (interval && interval.includes("DAILY")) {
      interval = "DAILY";
    }
    if (interval && interval.includes("WEEKLY")) {
      interval = "WEEKLY";
    }
    if (interval && interval.includes("MONTHLY")) {
      interval = "MONTHLY";
    }
    if (interval && interval.includes("YEARLY")) {
      interval = "YEARLY";
    }

    // Find and return the matching recurrence option from RECURRENCE_OPTIONS based on the interval
    return repetitionOptions.find(
      (item) => item.value && item.value.includes(interval)
    );
  }, [recurrence, setHumanReadableCustomRecurrence]);

  /**
   * Open custom recurrence modal and sets selected value in setRecurrence callback
   */
  const handleOpenCurrencePopup = () => {
    const rect = containerRef.current.getBoundingClientRect();
    const { left, top } = rect;

    appStateDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      position: {
        x: left - 420,
        y: top - 100,
      },
      popupData: {
        dispatch: ({ value }) => {
          setRecurrence(value);
        },
        noMaxHeight: true,
        setRecurrenceString: () => {},
        item: {
          recurrence: recurrenceValue,
        },
      },
      popupType: RECURRENCE_FORM_POPUP,
    });
  };

  const handleSetRecurrence = (optionSelected) => {
    if (optionSelected.label === "Custom Recurrence") {
      handleOpenCurrencePopup();
      return;
    }

    setRecurrence(optionSelected);
    // Reset to undefined, if options different for custom recurrence is selected
    setHumanReadableCustomRecurrence(undefined);
  };

  return {
    recurrenceValue,
    repetitionOptions,
    handleSetRecurrence,
  };
};

export default useRecurrenceDropdown;

import React from "react";
import PropTypes from "prop-types";
import notiAlertIcon from "../../assets/images/notiAlertIcon.svg";

const Warning = ({ prompt }) => {
  return (
    <div className="flex items-start rounded-lg p-3 bg-es-light-red">
      <img src={notiAlertIcon} alt="attention" />
      {prompt}
    </div>
  );
};

Warning.propTypes = {
  prompt: PropTypes.element,
};

Warning.defaultProps = {
  prompt: undefined,
};

export default Warning;

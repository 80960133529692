/**
 * Checks if the last error message in the API response contains the specified search text in location.
 *
 * @param {object} errorResponse - The error response object from the API.
 * @param {string} searchText - The text to search for in the error message location.
 * @returns {boolean} Returns true if the search text is found
 */
const isLastErrorMessageFound = (errorResponse = {}, searchText = "") => {
  const issues = errorResponse?.response?.data?.issues;

  if (issues && Array.isArray(issues)) {
    const lastIssue = issues[0];

    if (lastIssue?.location?.includes(searchText)) {
      return true;
    }
  }
  return false;
};

export default isLastErrorMessageFound;

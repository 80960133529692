import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import { getInitials } from "../../../helpers/User";

const avatarCN = (width, height, className) => cntl`
  bg-brandGreen
  rounded-full
  ${width || "w-24"}
  ${height || "h-24"}
  flex
  items-center
  justify-center
  ${className}
`;

const UserInitialsAvatar = ({ name, textSize, width, height, className }) => {
  return (
    <div className={avatarCN(width, height, className)}>
      <p
        className={`font-semibold uppercase text-white ${
          textSize || "text-4xl"
        }`}
      >
        {getInitials({
          firstName: name?.firstName,
          lastName: name?.lastName,
        })}
      </p>
    </div>
  );
};

UserInitialsAvatar.propTypes = {
  name: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  textSize: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

UserInitialsAvatar.defaultProps = {
  name: undefined,
  textSize: undefined,
  width: undefined,
  height: undefined,
  className: undefined,
};

export default UserInitialsAvatar;

/**
 * Generates a unique key for a task or event based on its `id` and `instanceStartDate` (if available).
 * @param {Object} taskOrEvent - The task or event object.
 * @param {number} index - The index used as a fallback for generating the unique key.
 * @returns {string} The unique key for the task or event.
 */
const getUniqueKey = (taskOrEvent, index) => {
  if (taskOrEvent?.instanceStartDate) {
    return `${taskOrEvent?.id}_${taskOrEvent.instanceStartDate}`;
  }

  return `${taskOrEvent?.id}_${index}`;
};

export default getUniqueKey;

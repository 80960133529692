import React, { useState } from "react";
import PropTypes from "prop-types";
import msgIcon from "../../../stories/assets/images/msgIcon.svg";
import channelIcon from "../../../stories/assets/images/channelIcon.svg";
import crossBlack from "../../../stories/assets/images/blackCrossIcon.svg";
import CrossButton from "../../../stories/Components/CrossButton/CrossButton";
import AddNewButton from "./AddNewButton";

const AddMessageButton = ({
  onNewMessage,
  expanded,
  openChannelCreate,
  disabled,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div style={{ height: "50px" }} className="bg-gray-500 relative">
      <AddNewButton
        onClick={() => setMenuOpen((prev) => (disabled ? false : !prev))}
        expanded={expanded}
      />
      {!disabled && (
        <>
          {menuOpen && (
            <div
              className="absolute bg-white shadow-1 rounded-lg cursor-default"
              style={{
                width: "302px",
                height: "231px",
                zIndex: "99",
                left: "20px",
                top: "50px",
              }}
            >
              <div
                className="font-semibold relative flex items-center justify-between w-full px-10"
                style={{ height: "72px" }}
              >
                <span className="inline-block">Add New</span>
                <CrossButton
                  icon={crossBlack}
                  className="absolute right-0 top-0 p-4"
                  onClick={() => setMenuOpen(false)}
                />
              </div>
              <button
                className="flex items-center gap-2 w-full hover:bg-mint px-10"
                style={{ height: "72px" }}
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  setMenuOpen(false);
                  onNewMessage();
                }}
              >
                <img
                  src={msgIcon}
                  alt="add-msg-icon"
                  className="inline-block"
                />
                <span>Message</span>
              </button>
              <button
                className="flex items-center gap-2 w-full hover:bg-mint px-10"
                style={{ height: "72px" }}
                type="button"
                onClick={() => {
                  openChannelCreate(true);
                  setMenuOpen(false);
                }}
              >
                <img
                  src={channelIcon}
                  alt="add-channel-icon"
                  className="inline-block"
                />
                <span>Channel</span>
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

AddMessageButton.propTypes = {
  onNewMessage: PropTypes.func,
  expanded: PropTypes.bool,
  openChannelCreate: PropTypes.func,
  disabled: PropTypes.bool,
};

AddMessageButton.defaultProps = {
  onNewMessage: () => {},
  expanded: false,
  openChannelCreate: () => {},
  disabled: false,
};
export default AddMessageButton;

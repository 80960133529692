import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import IconButton from "../Buttons/IconButton";
import collapseIcon from "../../assets/images/collapseIcon.svg";
import fileIcon from "../../assets/images/fileIcon.svg";
import editIcon from "../../assets/images/editIcon.svg";
import Dropdown from "../Dropdown/Dropdown";
import Input from "../Input/Input";
import SecondaryButton from "../Buttons/SecondaryButton";

const FileCell = ({
  doc,
  onEdit,
  onRemove,
  documentTypes,
  documentType,
  onEditDocumentType,
}) => {
  const [isRenaming, setIsRenaming] = useState(false);
  const [fileName, setFileName] = useState(doc.name);

  const handleRename = useCallback(() => {
    onEdit(doc, fileName);
    setFileName(fileName);
    setIsRenaming(false);
  }, [doc, fileName, onEdit]);

  return (
    <div className="flex my-6 border-b border-gray-100 pb-6">
      {isRenaming && (
        <div className="flex justify-between w-full">
          <div className="flex flex-row items-end w-full">
            <Input
              placeholder="Rename File"
              value={fileName}
              label="Document Name"
              onChange={setFileName}
              mainClassName="min-w-3/4"
            />
            <SecondaryButton
              title="Save"
              onClick={handleRename}
              className="mx-4"
              minWidth={0}
            />
          </div>
          <div className="flex flex-row items-end">
            <Dropdown
              placeholder="Select"
              options={documentTypes}
              value={documentType}
              onChange={onEditDocumentType}
              className="max-w-sm"
            />
          </div>
        </div>
      )}
      {!isRenaming && (
        <div className="flex justify-between w-full">
          <div className="flex flex-row items-start">
            <img src={fileIcon} alt="file" className="mr-2 mt-1" />
            <p className="mr-2 font-semibold text-gray-500 max-w-xl">
              {doc.name}
            </p>
          </div>
          <div className="flex flex-row items-center">
            <IconButton
              icon={editIcon}
              className="w-5 h-5 mr-2"
              imgClassName="w-5 h-5"
              onClick={() => setIsRenaming(true)}
            />
            <IconButton
              icon={collapseIcon}
              className="w-5 h-5 mr-2"
              onClick={() => onRemove(doc.id)}
            />
            <Dropdown
              placeholder="Select"
              options={documentTypes}
              value={documentType}
              onChange={onEditDocumentType}
              className="w-60"
            />
          </div>
        </div>
      )}
    </div>
  );
};
FileCell.propTypes = {
  doc: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  documentTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  documentType: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  onEditDocumentType: PropTypes.func,
};

FileCell.defaultProps = {
  onEdit: undefined,
  onRemove: undefined,
  documentTypes: [],
  documentType: undefined,
  onEditDocumentType: undefined,
};

export default FileCell;

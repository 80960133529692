// Framework Tools
import React from "react";
import PropTypes from "prop-types";

// Components
import { useAppState } from "../../../../state/appState";

// Components
import TertiaryHeader from "../../TextHeaders/TertiaryHeader";

export default function RequestGridViewAssociationsCell({ associations }) {
  const [{ submittalDict, rifiDict }] = useAppState();

  const resolveResourceName = (item) => {
    switch (item?.split("/")[0]) {
      case "Submittal":
        return submittalDict?.[item]?.customName;
      case "Rfi":
        return rifiDict?.[item]?.customName;
      /* ** more cases for RFP, CA, PO, PA to be added ** */
      default:
        return "None";
    }
  };

  return (
    <div className="w-full h-ful flex flex-col justify-start flex-wrap px-2">
      <TertiaryHeader className="text-sm text-left">
        Associations
      </TertiaryHeader>
      {associations?.length <= 0 && <p className="text-sm text-left">none</p>}
      {associations?.length >= 1 &&
        associations?.map((item) => {
          return <p key={item}>{resolveResourceName(item)}</p>;
        })}
    </div>
  );
}

// prop types
RequestGridViewAssociationsCell.propTypes = {
  associations: PropTypes.arrayOf(PropTypes.shape({})),
};

// default props
RequestGridViewAssociationsCell.defaultProps = {
  associations: undefined,
};

import React from "react";
import { useHistory } from "react-router-dom";
import SiteHeader from "../../../stories/Components/SiteHeader/SiteHeader";
import { ENTITIES_CREATION_PATH } from "../../../constants";

const EntitiesOverview = () => {
  const history = useHistory();
  const handleCreate = () => {
    history.push(ENTITIES_CREATION_PATH);
  };
  return (
    <>
      <SiteHeader
        title="Entities"
        primaryButtonText="Create New"
        showPrimaryButton
        onPrimaryButtonClick={handleCreate}
      />
      <p>Entities go here</p>
    </>
  );
};

export default EntitiesOverview;

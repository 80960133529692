import React from "react";

const EditIcon = ({ disabled }) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 17.0242H3.4L12.025 8.39917L10.625 6.99917L2 15.6242V17.0242ZM16.3 6.94917L12.05 2.74917L13.45 1.34917C13.8333 0.965837 14.3043 0.77417 14.863 0.77417C15.4217 0.77417 15.8923 0.965837 16.275 1.34917L17.675 2.74917C18.0583 3.1325 18.2583 3.59517 18.275 4.13717C18.2917 4.67917 18.1083 5.1415 17.725 5.52417L16.3 6.94917ZM14.85 8.42417L4.25 19.0242H0V14.7742L10.6 4.17417L14.85 8.42417ZM11.325 7.69917L10.625 6.99917L12.025 8.39917L11.325 7.69917Z"
        fill={!disabled ? "#027D61" : "#d0d0d0"}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default EditIcon;

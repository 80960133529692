/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Table from "../Table/Table";

import { FILTER_TYPES } from "../../../constants";
import TagList from "../Tags/TagList";

const groupOptions = {
  // ordered list of grouped column options
  hierarchicalOptions: [
    // add more hierarchical groups
  ],
  // un-ordered list of grouped column options
  nonHierarchicalOptions: [
    {
      label: "Type",
      value: "category",
    },
    {
      label: "Created By",
      value: "createdBy",
    },
    {
      label: "Tags",
      value: "tags",
    },
  ],
};

const PureFilesTable = ({
  resourceName,
  fetchDataOnScroll,
  hasMoreData,
  isFileOverview,
  data,
  selected,
  hideChangeView,
  hideSaveButton,
  disableHover,
  allowSelection,
  handleRowSelect,
  handleRowClick,
  filesView,
  fileActionsIcons,
  showColumnSettingsLeft,
  templateSettings,
  updateUserTemplateSettings,
  deleteUserTemplateSettings,
}) => {
  const tableColumns = React.useMemo(() => {
    const columns = [
      {
        Header: "Name",
        accessor: "name",
        isPrimary: true,
        minWidth: 200,
      },
      {
        Header: "Type",
        accessor: "category",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Kind",
        accessor: "kind",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Association",
        accessor: "associationName",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Created By",
        accessor: "createdBy",
        Cell: ({ row }) => {
          return <p className="truncate">{row?.original?.createdBy}</p>;
        },
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ row }) => {
          return (
            <TagList
              tags={row?.original?.currentTags}
              tagClassName="py-1 px-2"
              className="gap-2"
              showCount
            />
          );
        },
        minWidth: 200,
        filterOptions: {
          label: "Tags",
          filterType: FILTER_TYPES.tags,
        },
      },
      {
        Header: "Date Modified",
        accessor: "modifiedDate",
        Cell: ({ row }) => {
          return (
            <p className="truncate">
              {moment(row?.original?.metadata?.lastUpdated).format(
                "MMM DD, YYYY, h:mm A"
              )}
            </p>
          );
        },
        filterOptions: {
          filterType: FILTER_TYPES.date,
        },
        minWidth: 170,
      },
    ];

    return columns;
  }, []);

  return (
    <Table
      fetchDataOnScroll={fetchDataOnScroll}
      hasMoreData={hasMoreData}
      resourceName={resourceName || "document-table"}
      data={data}
      defaultSort={[{ id: "modifiedDate", desc: false }]}
      initialTemplateViewName={
        !isFileOverview &&
        resourceName !== "Submittal-associated-files" &&
        resourceName !== "Workflow-associated-files" &&
        resourceName !== "Document-associated-files" &&
        ""
        // "By Type"
      }
      onRowClick={handleRowClick}
      columns={tableColumns}
      initialSelectedRows={selected}
      onSelectedRowChange={handleRowSelect}
      hideCreateNewButton
      disableHover={disableHover}
      hideChangeView={hideChangeView}
      hideSaveButton={hideSaveButton}
      allowSelection={allowSelection}
      groupOptions={groupOptions}
      templateSettings={templateSettings}
      updateUserTemplateSettings={updateUserTemplateSettings}
      deleteUserTemplateSettings={deleteUserTemplateSettings}
      hideGroupedFooter
      hideSwitchView
      hideSiteHeaderActionButtons
      hideSiteHeaderDropdownContainer
      hideSiteHeaderTitle
      showConstantRowIndex
      filesView={filesView}
      fileActionsIcons={fileActionsIcons}
      showColumnSettingsLeft={showColumnSettingsLeft}
      pagination={false}
    />
  );
};

PureFilesTable.propTypes = {
  /**
   * originalDocuments to display on the table, as they come from the server
   */
  data: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * array of selected row ids
   */
  selected: PropTypes.arrayOf(PropTypes.shape({})),
  hideChangeView: PropTypes.bool,
  hideSaveButton: PropTypes.bool,
  allowSelection: PropTypes.bool,
  disableHover: PropTypes.bool,
  resourceName: PropTypes.string,
};

PureFilesTable.defaultProps = {
  data: [],
  selected: [],
  hideChangeView: false,
  hideSaveButton: false,
  allowSelection: true,
  disableHover: false,
  resourceName: undefined,
};

export default PureFilesTable;

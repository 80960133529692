import React from "react";
import PropTypes from "prop-types";

import BaseButton from "../Buttons/BaseButton";
import { downloadMedia } from "../../../helpers/File";
import moreInformationIcon from "../../assets/images/moreInformation.svg";
import { docTypeOptionsMap } from "../../../constants";

const FileButtons = ({
  className,
  id,
  docType,
  name,
  customName,
  resource,
  status,
  contentReference,
  attachments,
  onFileEdit,
  onShowHistory,
  onSendToWorkflow,
  onDownloadFile,
  // onUploadVersion,
  onFileDelete,
  fileButtonPosition,
}) => {
  const handleFileDownload = async (cRefs) => {
    await downloadMedia(cRefs ?? [contentReference]);
  };

  const getFileOptions = () => {
    const fileOptionsArr = [];
    const editFile = {
      title: "Edit",
      onClick: () => onFileEdit(),
    };
    const sendToWorkflow = {
      title: "Send to Workflow",
      onClick: () => onSendToWorkflow(id),
    };
    const showHistory = {
      title: "Show History",
      onClick: () => onShowHistory(id),
    };
    const downloadFile = {
      title: "Download File",
      onClick: () =>
        onDownloadFile
          ? onDownloadFile({ id, customName, docType })
          : handleFileDownload(),
    };
    const attachmentOptions = {
      title: "Download Attachments",
      onClick: () => {
        if (attachments?.length) {
          handleFileDownload(
            attachments.map(({ contentReference: cRef }) => cRef)
          );
        }
      },
    };
    // Removed for 3.0
    // const uploadVersion = {
    //   title: "Upload New Version",
    //   onClick: () => onUploadVersion(id),
    // };
    const deleteFile = {
      title: "Delete",
      onClick: () => onFileDelete({ id, name: name ?? resource }),
    };

    if (docType === docTypeOptionsMap.purchaseOrder.label) {
      fileOptionsArr.push(sendToWorkflow);
    } else if (status === "locked") {
      fileOptionsArr.push(showHistory);
    } else {
      fileOptionsArr.push(editFile);
      fileOptionsArr.push(sendToWorkflow);
      fileOptionsArr.push(showHistory);
      if (contentReference) {
        fileOptionsArr.push(downloadFile);
      }
      fileOptionsArr.push(deleteFile);
    }

    if (contentReference) {
      // fileOptionsArr.push(uploadVersion);
    }

    if (attachments?.length) {
      fileOptionsArr.push(attachmentOptions);
    }

    return fileOptionsArr;
  };

  return (
    <div className={className}>
      <BaseButton
        className="flex items-center justify-center"
        iconRight={<img src={moreInformationIcon} alt="" />}
        dropdownItems={getFileOptions()}
        fileButtonPosition={fileButtonPosition}
        hideDropdownChevron
      />
    </div>
  );
};

FileButtons.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  docType: PropTypes.string,
  name: PropTypes.string,
  customName: PropTypes.string,
  resource: PropTypes.string,
  status: PropTypes.string,
  contentReference: PropTypes.string,
  attachments: PropTypes.arrayOf(PropTypes.string),
  onFileEdit: PropTypes.func,
  onShowHistory: PropTypes.func,
  onSendToWorkflow: PropTypes.func,
  onDownloadFile: PropTypes.func,
  // onUploadVersion: PropTypes.func,
  onFileDelete: PropTypes.func,
  fileButtonPosition: PropTypes.string,
};

FileButtons.defaultProps = {
  className: undefined,
  id: undefined,
  docType: undefined,
  name: undefined,
  customName: undefined,
  resource: undefined,
  status: undefined,
  contentReference: undefined,
  attachments: undefined,
  onFileEdit: () => {},
  onShowHistory: () => {},
  onSendToWorkflow: () => {},
  onDownloadFile: undefined,
  // onUploadVersion: () => {},
  onFileDelete: () => {},
  fileButtonPosition: undefined,
};

export default FileButtons;

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import BaseButton from "../../Buttons/BaseButton";
import PlusCirlceIconDarkGreen from "../../../assets/images/plusCircleIconDarkGreen.svg";
import SelectSectionItem from "../SelectSectionItem";

const tertiaryButtonCN = cntl`
  text-brandDarkGreen
  py-4
  text-base
`;

const SelectCustomSection = ({
  selection: {
    selectionName,
    defaultValue,
    selectionValue,
    selectionItemValue,
  },
  settings: { settings, dispatchSettings },
}) => {
  const [addMode, setAddMode] = useState(false);

  const onAddSectionItem = useCallback(() => {
    dispatchSettings({
      type: "addSectionItem",
      selectionValue,
    });
  }, [selectionValue, dispatchSettings]);

  const onRemoveSectionItem = useCallback(
    (index) => {
      dispatchSettings({
        type: "removeSectionItem",
        selectionValue,
        index,
      });
    },
    [selectionValue, dispatchSettings]
  );

  const onSectionItemChange = useCallback(
    (newValue, index) => {
      dispatchSettings({
        type: "changeSectionItem",
        selectionValue,
        selectionItemValue,
        index,
        value: newValue,
      });
    },
    [selectionValue, selectionItemValue, dispatchSettings]
  );

  const toggleSectionItemEditing = useCallback(
    (index) => {
      dispatchSettings({
        type: "changeSectionItemEditing",
        selectionValue,
        index,
      });
    },
    [selectionValue, dispatchSettings]
  );

  const onSectionItemSelect = useCallback(
    (index) => {
      dispatchSettings({
        type: "selectSectionItem",
        selectionValue,
        index,
      });
    },
    [selectionValue, dispatchSettings]
  );

  const hasDuplicate = useCallback(
    (value, idx) => {
      return settings?.[selectionValue].some((item, index) => {
        return (
          item?.display?.toLowerCase() === value?.toLowerCase() && index !== idx
        );
      });
    },
    [settings, selectionValue]
  );

  const handleBlur = (value, idx) => {
    if (window.event.keyCode === 13) return;
    toggleSectionItemEditing(idx);
    if (!value || hasDuplicate(value, idx)) {
      onRemoveSectionItem(idx);
    }

    // prevents new empty input field from being added inside handleEnter
    setAddMode(false);
  };

  const handleEnter = useCallback(
    (event, value, idx) => {
      // checks if the Enter key was pressed
      if (event.keyCode === 13) {
        if (hasDuplicate(value, idx)) {
          event.stopPropagation();
          return false;
        }
        if (value === "") {
          onRemoveSectionItem(idx);
          return undefined;
        }

        if (addMode) onAddSectionItem();
        toggleSectionItemEditing(idx);
      }
      return undefined;
    },
    [
      addMode,
      hasDuplicate,
      onAddSectionItem,
      onRemoveSectionItem,
      toggleSectionItemEditing,
    ]
  );

  return (
    <div className="py-2">
      <h3 className="font-semibold text-gray-300">{selectionName}</h3>
      <div className="flex flex-row w-full py-4">
        <div className="flex flex-col w-1/2 border-r border-gray-150">
          <h3 className="font-semibold text-black pb-2">Default Units</h3>
          {settings?.[defaultValue]?.map((item) => {
            return (
              <div className="flex" key={item?.id}>
                <p className="text-black">{item.display}</p>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col pl-8">
          <h3 className="font-semibold text-black">Custom Units</h3>
          {(settings?.[selectionValue]?.length &&
            settings?.[selectionValue]?.map((selection, index) => {
              return (
                <SelectSectionItem
                  key={selection?.id}
                  item={selection}
                  index={index}
                  toggleEditing={toggleSectionItemEditing}
                  handleChange={onSectionItemChange}
                  handleBlur={handleBlur}
                  handleRemove={onRemoveSectionItem}
                  handleEnter={handleEnter}
                  handleCheck={onSectionItemSelect}
                  setAddMode={setAddMode}
                />
              );
            })) || (
            <p className="text-gray-200 text-sm py-4">
              No custom unit types have been added
            </p>
          )}
          <div className="py-4 flex flex-row items-center">
            <img
              className="h-4 w-4"
              src={PlusCirlceIconDarkGreen}
              alt="plus circle icon green"
            />
            <BaseButton
              title="Add Unit Type"
              onClick={onAddSectionItem}
              className={tertiaryButtonCN}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SelectCustomSection.propTypes = {
  selection: PropTypes.shape({
    selectionName: PropTypes.string,
    defaultValue: PropTypes.string,
    selectionValue: PropTypes.string,
    selectionItemValue: PropTypes.string,
  }),
  settings: PropTypes.shape({
    settings: PropTypes.shape({
      projectTypes: PropTypes.arrayOf(
        PropTypes.shape({
          display: PropTypes.string,
          id: PropTypes.string,
        })
      ),

      projectPositions: PropTypes.arrayOf(
        PropTypes.shape({
          display: PropTypes.string,
          id: PropTypes.string,
          selected: PropTypes.bool,
        })
      ),
    }),
    dispatchSettings: PropTypes.func,
  }),
};

SelectCustomSection.defaultProps = {
  selection: undefined,
  settings: undefined,
};

export default SelectCustomSection;

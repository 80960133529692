import { useCallback, useEffect, useState } from "react";
import {
  ADD_OPEN_MODAL,
  CONTRACT_MODAL,
  EXPAND_MODAL,
  MAXIMIZE_MODAL,
  MINIMIZE_ALL_MODALS,
  MINIMIZE_MODAL,
  REMOVE_MODAL_INSTANCE,
} from "../constants";
import { useModalState } from "../state/modalState";

export default (id, initialState) => {
  const [{ modals }, dispatch] = useModalState();
  const [state, setState] = useState();

  const reload = useCallback(async () => {
    const modal = modals?.find((item) => item.id === id);
    if (modal) setState(modal);
  }, [id, modals]);

  const openModal = useCallback(
    (ref) => {
      dispatch({
        type: ADD_OPEN_MODAL,
        ref,
        initialState,
      });
    },
    [dispatch, initialState]
  );

  const minimizeAll = useCallback(() => {
    dispatch({
      type: MINIMIZE_ALL_MODALS,
    });
  }, [dispatch]);

  const minimize = useCallback(
    (modalId) => {
      dispatch({
        type: MINIMIZE_MODAL,
        id: modalId,
      });
    },
    [dispatch]
  );

  const maximize = useCallback(
    (modalId) => {
      dispatch({
        type: MAXIMIZE_MODAL,
        id: modalId,
      });
    },
    [dispatch]
  );

  const expand = useCallback(
    (modalId) => {
      dispatch({
        type: EXPAND_MODAL,
        id: modalId,
      });
    },
    [dispatch]
  );

  const contract = useCallback(
    (modalId) => {
      dispatch({
        type: CONTRACT_MODAL,
        id: modalId,
      });
    },
    [dispatch]
  );

  const removeInstance = useCallback(
    (modalId) => {
      dispatch({
        type: REMOVE_MODAL_INSTANCE,
        id: modalId,
      });
      setState();
    },
    [dispatch]
  );

  useEffect(() => {
    reload();
  }, [reload, modals]);

  return {
    openModal,
    minimizeAll,
    minimize,
    maximize,
    expand,
    contract,
    removeInstance,
    state,
    modals,
  };
};

import { useCallback, useState, useMemo } from "react";
import { useHistory } from "react-router";
import { cloneDeep, isEqual } from "lodash";
import { useProjectById, useProjectsPatch } from "../../hooks/projects";
import { toastMessage, toastError } from "../../stories/Components/Toast/Toast";
import { PROJECTS_PATH } from "../../constants";
import useQueryNotFoundNavigation from "../../hooks/navigation/useQueryNotFoundNavigation";
import useProjectFormReducer from "../../hooks/useProjectFormReducer";
import { isPositiveNumber } from "../../helpers/Utilities";
import useCheckDuplicatedName from "../../hooks/projects/useCheckDuplicatedName";

const useProjectViewData = (id) => {
  const history = useHistory();

  const [editedProject, dispatch] = useProjectFormReducer();
  const { data: project, isLoading, error } = useProjectById(id);

  // Redirect to 404 page if resource is not found
  useQueryNotFoundNavigation({ error });

  const { patchProjectAsync, isPatching } = useProjectsPatch();

  const [isEditing, setIsEditing] = useState(false);

  // hook to check duplicated Project name
  const { checkNameExists, nameExists } = useCheckDuplicatedName();

  // take a copy of original edited Project to validate changes
  const origEditedProject = useMemo(
    () =>
      cloneDeep({
        ...project,
        name: project?.name.toLowerCase(),
        currentTags: [],
        originalResource: { ...project },
      }),
    [project]
  );

  // check if editedProject has been modified
  const isProjectEdited = useMemo(() => {
    const sameProject = isEqual(origEditedProject, {
      ...editedProject,
      name: editedProject?.name?.toLowerCase(),
    });
    return !sameProject;
  }, [editedProject, origEditedProject]);

  // ratesheet validation
  const validRateSheet = useMemo(() => {
    if (editedProject?.rateSheet?.rates.length === 0) return false;
    return editedProject?.rateSheet?.rates?.every((rate) => {
      return (
        rate?.category !== "" &&
        isPositiveNumber(rate?.premiumRate) &&
        isPositiveNumber(rate?.rateOver40Hrs) &&
        isPositiveNumber(rate?.ratePerHr)
      );
    });
  }, [editedProject]);

  /**
   * Patch given project
   * @param {Object} updatedProject - Updated project
   * @param {Object} currentProject - Current project
   */
  const handlePatchProject = useCallback(
    async (updatedProject, currentProject) => {
      let data;
      try {
        data = await patchProjectAsync(updatedProject, currentProject);
        toastMessage("Project was updated successfully");
      } catch (err) {
        toastError("Project could not be updated. Please try again");
      }

      return data;
    },
    [patchProjectAsync]
  );

  /**
   * Change status to active for current project
   */
  const handleActivateProject = useCallback(async () => {
    const updatedProject = {
      ...project,
      status: "active",
    };
    return patchProjectAsync(updatedProject, project).then(() => {
      toastMessage("Project was activated successfully");
    });
  }, [project, patchProjectAsync]);

  /**
   * Change status to complete for current project
   */
  const handleCompleteProject = useCallback(async () => {
    const updatedProject = {
      ...project,
      status: "complete",
    };
    try {
      await patchProjectAsync(updatedProject, project);
      toastMessage("Project was completed successfully");

      history.push(PROJECTS_PATH);
    } catch (err) {
      toastError("Project could not be completed. Please try again");
    }
  }, [history, project, patchProjectAsync]);

  return {
    isEditing,
    project,
    isLoading,
    isPatching,
    nameExists,
    isProjectEdited,
    editedProject,
    validRateSheet,
    handlePatchProject,
    handleActivateProject,
    handleCompleteProject,
    dispatch,
    setIsEditing,
    checkNameExists,
  };
};

export default useProjectViewData;

import { RRule } from "rrule";

/**
 * Given a recurrence parameter, returns a select value object.
 *
 * @param {string} recParam - a recurrence string, can be empty.
 * @returns {Object} A select value object with label and value.
 *
 * The returned object's label and value are determined as follows:
 * - If the recurrence parameter object is empty, return an object with label 'Does not repeat' and value 'does not repeat'.
 * - If the recurrence parameter object's freq is DAILY and there is no until, return an object with label 'Daily' and value 'daily'.
 * - If the recurrence parameter object's freq is WEEKLY and there is no until and no byweekday, return an object with label 'Weekly' and value 'weekly'.
 * - If the recurrence parameter object's freq is MONTHLY and there is no until, return an object with label 'Monthly' and value 'monthly'.
 * - If the recurrence parameter object's freq is YEARLY and there is no until, return an object with label 'Yearly' and value 'yearly'.
 * - Otherwise, return an object with label 'Custom Recurrence' and value 'custom recurrence'.
 */

const getRecurrenceSelectValue = (recParam) => {
  if (!recParam) {
    return {
      label: "Does not repeat",
      value: "does not repeat",
    };
  }

  const ruleFromString = RRule.fromString(recParam);

  if (
    ruleFromString.options.freq === RRule.DAILY &&
    !ruleFromString.options.until
  ) {
    return {
      label: "Daily",
      value: "daily",
    };
  }

  if (
    ruleFromString.options.freq === RRule.WEEKLY &&
    !ruleFromString.options.until &&
    !ruleFromString?.origOptions?.byweekday
  ) {
    return {
      label: "Weekly",
      value: "weekly",
    };
  }

  if (
    ruleFromString.options.freq === RRule.MONTHLY &&
    !ruleFromString.options.until
  ) {
    return {
      label: "Monthly",
      value: "monthly",
    };
  }

  if (
    ruleFromString.options.freq === RRule.YEARLY &&
    !ruleFromString.options.until
  ) {
    return {
      label: "Yearly",
      value: "yearly",
    };
  }

  return {
    label: "Custom Recurrence",
    value: "custom recurrence",
  };
};

export default getRecurrenceSelectValue;

import { ProjectAPI } from "@griffingroupglobal/eslib-api";

/**
 * Fetch projects dictionary
 * @returns - @returns {Promise<Object>} An object containing project, dictionary and dropdown options.
 */
const fetchProjectsOverview = async () => {
  const { data } = await ProjectAPI.get();

  const projectDict = {};

  const projectsDD = data?.entries?.map((entry) => {
    const project = entry?.resource;
    projectDict[project?.reference] = project;

    return {
      label: project?.name,
      value: project?.reference,
      id: project?.id,
      status: project?.status,
    };
  });

  return {
    projectDict,
    projectsDD,
  };
};

export default fetchProjectsOverview;

import React from "react";
import PropTypes from "prop-types";
import crossIcon from "../../assets/images/Close.svg";
import BaseButton from "../Buttons/BaseButton";

const list = [
  {
    code: "1000-1999",
    description: "Fixed Assets or Other/Current Assets",
  },
  {
    code: "2000-2999",
    description: "Liabilities or Other/Current Liabilities",
  },
  {
    code: "3000-3999",
    description: "Equity",
  },
  {
    code: "4000-4999",
    description: "Income or Revenue",
  },
  {
    code: "5000-5999",
    description: "Job Costs/Costs of Goods Sold",
  },
  {
    code: "6000-6999",
    description: "Overhead Costs or Expenses",
  },
  {
    code: "7000-7999",
    description: "Other Income",
  },
  {
    code: "8000-8999",
    description: "Other Expense",
  },
];

const ChartOfAccountsPopOver = ({ onClose }) => {
  return (
    <div className="flex flex-col">
      <div className="flex justify-end">
        <BaseButton
          onClick={onClose}
          iconLeft={
            <img
              src={crossIcon}
              alt="crossIcon"
              style={{ height: 8, width: 10 }}
            />
          }
        />
      </div>
      <div className="flex pl-2 pr-4 pb-1">
        <p className="font-semibold text-sm">
          Common Account Code Convention for Chart of Accounts
        </p>
      </div>
      <div className="flex flex-col px-2">
        {list.map((item) => (
          <div className="flex flex-row" key={item.code}>
            <div className="w-1/3 text-sm">{item.code}</div>
            <div className="text-sm">{item.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

ChartOfAccountsPopOver.propTypes = {
  onClose: PropTypes.func,
};

ChartOfAccountsPopOver.defaultProps = {
  onClose: undefined,
};

export default ChartOfAccountsPopOver;

import React, { useState } from "react";
import ProgressTracker from "../../../stories/Components/ProgressTracker/ProgressTracker";
import PrimaryHeader from "../../../stories/Components/TextHeaders/PrimaryHeader";
import CompanyDetails from "../../../stories/Components/CompanyFormSteps/CompanyDetailsForm";
import CompanyRequirementsForm from "../../../stories/Components/CompanyFormSteps/CompanyRequirementsForm";
import CompanyDocumentsForm from "../../../stories/Components/CompanyFormSteps/CompanyDocumentsForm";
import CompanyMembersForm from "../../../stories/Components/CompanyFormSteps/CompanyMembersForm";
import useUsers from "../../../hooks/useUsers";

const EntityCreation = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const steps = ["DETAILS", "FINANCES", "MEMBERS", "DOCUMENTS"];
  const [users] = useUsers();
  const handleNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };
  return (
    <div>
      <div className="flex flex-row justify-between pr-14 items-center">
        <PrimaryHeader>Create Entity</PrimaryHeader>
        <ProgressTracker
          steps={steps}
          selectedIndex={currentStep}
          onStepClick={setCurrentStep}
        />
      </div>
      {currentStep === 0 && <CompanyDetails handleNextStep={handleNextStep} />}
      {currentStep === 1 && (
        <CompanyRequirementsForm
          handleNextStep={handleNextStep}
          allUsers={users}
        />
      )}
      {currentStep === 2 && (
        <CompanyMembersForm handleNextStep={handleNextStep} allUsers={users} />
      )}
      {currentStep === 3 && <CompanyDocumentsForm />}
    </div>
  );
};

export default EntityCreation;

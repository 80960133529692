import React from "react";
import PropTypes from "prop-types";
import checkmark from "../../../../../stories/assets/images/roundedCheckMark.svg";
import placeholderImage from "../../../../../stories/assets/images/esShortLogo.svg";
import useTodoMemberData from "./useTodoMemberData";
import { getFullName } from "../../../../../helpers/Formatters";
import "./todomember.css";

const TodoMember = ({ member, closeModal, showName }) => {
  const { avatar, handleNavigate, user } = useTodoMemberData(member);

  return (
    <button
      type="button"
      className={`relative flex gap-2 ${
        showName ? "border-b pb-3 member-container" : "rounded-full"
      }`}
      onClick={() => {
        closeModal();
        handleNavigate();
      }}
      title={getFullName(user?.name) ?? ""}
    >
      {member?.checked && (
        <div
          className="rounded-full bg-primaryGreen w-3 h-3 flex items-center justify-center"
          style={{
            position: "absolute",
            top: "-0.25rem",
            right: "-0.25rem",
            zIndex: 99,
          }}
        >
          <img
            className=""
            style={{
              width: "0.5rem",
              height: "0.5rem",
            }}
            src={checkmark}
            alt="completed-icon"
          />
        </div>
      )}
      <img
        className="w-7 h-7 rounded-full object-cover shadow-md"
        style={{ minWidth: "1.75rem" }}
        src={avatar?.contentsUrl ?? placeholderImage}
        alt="user-avatar"
      />
      {showName && <p className="truncate">{getFullName(user?.name) ?? ""}</p>}
    </button>
  );
};

TodoMember.propTypes = {
  member: PropTypes.shape({
    user: PropTypes.string,
    checked: PropTypes.bool,
  }),
  closeModal: PropTypes.func,
  showName: PropTypes.bool,
};

TodoMember.defaultProps = {
  member: undefined,
  closeModal: () => {},
  showName: false,
};

export default TodoMember;

import React from "react";
import PropTypes from "prop-types";
import ProjectRetainage from "./ProjectRetainage";
// import ProjectInsurance from "./ProjectInsurance";
import ProjectContractual from "./ProjectContractual";
import ProjectRateSheet from "./ProjectRateSheet";
import ProjectTermHighlights from "./ProjectTermHighlights";
import ProjectQualifications from "./ProjectQualifications";
import ProjectDeposit from "./ProjectDeposit";
import ProjectTimeToNotify from "./ProjectTimeToNotify";
import ProjectDivisionCode from "./ProjectDivisionCode";
import HoursOfOperation from "../HoursOfOperation/HoursOfOperation";

const PureFinancialOverview = ({
  project,
  dispatch,
  isEditing,
  editable,
  readable,
  financialsSettings,
  documentsSettings,
}) => {
  return (
    <div className="flex flex-col w-full">
      <ProjectRateSheet
        project={project}
        dispatch={dispatch}
        isEditing={isEditing}
      />
      <div className="flex w-full">
        <div className="flex flex-col w-1/2 pr-3">
          <ProjectRetainage
            project={project}
            isEditing={isEditing}
            editable={editable}
            readable={readable}
            dispatch={dispatch}
          />
          <ProjectDeposit
            project={project}
            isEditing={isEditing}
            dispatch={dispatch}
          />
          <ProjectContractual
            project={project}
            isEditing={isEditing}
            dispatch={dispatch}
          />
          <ProjectQualifications
            project={project}
            isEditing={isEditing}
            dispatch={dispatch}
          />
          <ProjectDivisionCode
            project={project}
            isEditing={isEditing}
            financialsSettings={financialsSettings}
            dispatch={dispatch}
          />
        </div>
        <div className="flex flex-col w-1/2 pl-3">
          <ProjectTermHighlights
            project={project}
            isEditing={isEditing}
            documentsSettings={documentsSettings}
            dispatch={dispatch}
          />
          <HoursOfOperation
            resource={project}
            dispatch={dispatch}
            isEditing={isEditing}
          />
          <ProjectTimeToNotify
            project={project}
            isEditing={isEditing}
            dispatch={dispatch}
          />
        </div>
      </div>
    </div>
  );
};

PureFinancialOverview.propTypes = {
  project: PropTypes.shape({
    retainage: PropTypes.arrayOf(
      PropTypes.shape({
        percentComplete: PropTypes.string,
        percentRetainage: PropTypes.string,
      })
    ),
  }),
  dispatch: PropTypes.func,
  isEditing: PropTypes.bool,
  editable: PropTypes.bool,
  readable: PropTypes.bool,
  financialsSettings: PropTypes.shape({}),
  documentsSettings: PropTypes.shape({}),
  systemConfiguration: PropTypes.shape({}),
};

PureFinancialOverview.defaultProps = {
  project: undefined,
  dispatch: undefined,
  isEditing: false,
  editable: false,
  readable: undefined,
  financialsSettings: undefined,
  documentsSettings: undefined,
  systemConfiguration: undefined,
};

export default PureFinancialOverview;

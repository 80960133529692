import React from "react";
import PropTypes from "prop-types";

import RFIRequestWorkflowCard from "./RFIRequestWorkflowCard";

export default function RFIRequestWorkflowCreateCard({
  index,
  step,
  members,
  handleStepChange,
  handleStepDelete,
}) {
  const handleUpdateDueDates = (val) => {
    const updatedStep = {
      ...step,
      duration: { ...step.duration, projected: parseInt(val, 10) },
    };
    handleStepChange(updatedStep);
  };

  const handleUpdateMember = (val) => {
    let existingUser = step.users[0];
    if (index === 0) {
      existingUser = { ...existingUser, type: "submitter" };
    }
    const updatedStep = {
      ...step,
      users: [
        {
          ...existingUser,
          reference: val.value,
        },
      ],
    };
    handleStepChange(updatedStep);
  };

  const handleUpdateRole = (val) => {
    const existingUser = step.users[0];
    const updatedStep = {
      ...step,
      users: [
        {
          ...existingUser,
          type: val.value,
        },
      ],
    };
    handleStepChange(updatedStep);
  };

  const roleOptions = [
    { label: "Submitter", value: "submitter" },
    { label: "Approver", value: "approver" },
    { label: "Reviewer", value: "reviewer" },
  ];

  const stepUser = step?.users?.[0];

  return (
    <RFIRequestWorkflowCard
      duration={step?.duration?.projected}
      handleDeleteClick={handleStepDelete}
      handleUpdateDueDates={handleUpdateDueDates}
      index={index}
      userOptions={members}
      handleUpdateMember={handleUpdateMember}
      handleUpdateRole={handleUpdateRole}
      roleOptions={roleOptions}
      user={stepUser}
    />
  );
}

// prop types
RFIRequestWorkflowCreateCard.propTypes = {
  index: PropTypes.number,
  step: PropTypes.shape({
    duration: PropTypes.string,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  members: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.value,
    })
  ),
  handleStepChange: PropTypes.func,
  handleStepDelete: PropTypes.func,
  userDict: PropTypes.shape({}),
};

RFIRequestWorkflowCreateCard.defaultProps = {
  index: undefined,
  step: { duration: undefined },
  members: [],
  handleStepChange: undefined,
  handleStepDelete: undefined,
  userDict: undefined,
};

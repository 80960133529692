import { SpaceAPI } from "@griffingroupglobal/eslib-api";
import { spacesKeys } from "../config/reactQuery/queryKeyFactory";
import { toastError } from "../stories/Components/Toast/Toast";
import useAuthenticatedQuery from "./useAuthenticatedQuery";

/**
 * Fecth all spaces or by association
 * @returns array of spaces
 */
const getSpaces = async () => {
  const { data } = await SpaceAPI.get();
  if (data?.entries) {
    return data.entries;
  }

  return [];
};

/**
 * Query hook to fetch all spaces
 * @param {Function} select (?) custom function to transform data
 * @returns spaces in backend format
 */
const useGetSpacesList = () => {
  const queryKey = spacesKeys.spaces;

  return useAuthenticatedQuery({
    queryKey,
    queryFn: getSpaces,
    onError: (error) => {
      toastError("There was an issue getting spaces. Please try again");
      console.error("useSpaces", error);
    },
  });
};

export default useGetSpacesList;

import { SET_IS_SITE_MAINTENANCE } from "../constants";
import { useAppState } from "../state/appState";

export default () => {
  const [{ isSiteMaintenance }, dispatch] = useAppState();

  const setIsSiteMaintenance = (status) => {
    dispatch({
      type: SET_IS_SITE_MAINTENANCE,
      isSiteMaintenance: status,
    });
  };

  return [isSiteMaintenance, setIsSiteMaintenance];
};

import { useState } from "react";
import useRsvp from "../../../hooks/rsvp/useRsvp";
import useRsvpPost from "../../../hooks/rsvp/useRsvpPost";
import useCurrentUser from "../../../hooks/useCurrentUser";

const useRsvpRow = ({ reference, instanceStartDate }) => {
  const {
    data: rsvp,
    isFetched,
    isLoading,
  } = useRsvp(reference, instanceStartDate);

  const { data: currentUser } = useCurrentUser();
  const { mutate: postRsvp } = useRsvpPost();
  const [single, setSingle] = useState(true);
  const [all, setAll] = useState(false);
  const [status, setStatus] = useState();

  const currentStatus = !isFetched ? false : rsvp?.[currentUser?.reference];

  const onButtonPress = (selectedStatus) => {
    setStatus(selectedStatus);

    const operation = () => {
      if (!instanceStartDate || !single) {
        return "$all";
      }
      return "$single";
    };

    postRsvp({
      reference,
      op: operation(),
      status: selectedStatus,
      instanceStartDate,
    });
  };

  const onDone = (operation) => {
    postRsvp({
      reference,
      op: operation,
      status: status ?? currentStatus,
      instanceStartDate,
    });
  };

  const onCheckboxPress = (operation) => {
    onDone(operation);
  };

  return {
    setSingle,
    setAll,
    onButtonPress,
    onCheckboxPress,
    currentStatus,
    single,
    all,
    isLoading,
  };
};

export default useRsvpRow;

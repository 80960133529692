import moment from "moment";

const nthWeekdayOfMonth = (weekday, n, date) => {
  let count = 0;
  const idate = new Date(
    moment(date).get("year"),
    moment(date).get("month"),
    1
  );
  while (count < n) {
    if (idate.getDay() === weekday) {
      count += 1;
    }
    idate.setDate(idate.getDate() + 1);
  }
  // Added later as it was returning a day greater that expected
  idate.setDate(idate.getDate() - 1);
  return idate;
};

const getWeekDayNumber = (weekDay) => {
  const days = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };

  return days[weekDay.toLowerCase()];
};

const getDerivedDate = (updatedHoliday, year) => {
  const monthNumber = parseInt(
    moment()
      .month(updatedHoliday?.recurrence?.month || "January")
      .format("M"),
    10
  );

  const weekNumber = getWeekDayNumber(
    updatedHoliday?.recurrence?.dayOfWeek || "sunday"
  );

  const newDerivedDate = nthWeekdayOfMonth(
    weekNumber,
    updatedHoliday?.recurrence?.calendarDay || 1,
    moment()
      .set("month", monthNumber - 1)
      .set("year", year || new Date().getFullYear())
  );

  return newDerivedDate;
};

const years = () => {
  const list = [];
  const currentYear = new Date().getFullYear();
  list.push({
    label: currentYear,
    value: currentYear,
  });

  for (let i = 1; i <= 2; i += 1) {
    list.splice(0, 0, {
      label: currentYear - i,
      value: currentYear - i,
    });
    list.push({
      label: currentYear + i,
      value: currentYear + i,
    });
  }

  return list;
};

const getCurrentYearDate = (date, year) => {
  return moment(date)
    .set("year", year || moment().get("year"))
    .format("dddd, MMMM Do");
};

const getDay = (date, year) => {
  return moment(date).set("year", year).format("dddd");
};

const isWeekend = (holiday, year) => {
  const day = getDay(holiday?.date || holiday?.recurrence?.derivedDate, year);

  return day === "Sunday" || day === "Saturday";
};

const getOrdinalNumber = (n) => {
  return (
    n +
    (["st", "nd", "rd"][(((((n < 0 ? -n : n) + 90) % 100) - 10) % 10) - 1] ||
      "th")
  );
};

const dateOptions = [
  {
    value: "specificDate",
    label: "Specific date",
  },
  {
    value: "customRecurrence",
    label: "Custom Recurrence",
  },
];

const calendarDaysOptions = () => {
  const days = [];
  for (let i = 1; i <= 4; i += 1) {
    days.push({
      label: i,
      value: i,
    });
  }

  return days;
};

const calendarDaysOfWeekOptions = [
  {
    label: "Sunday",
    value: "sunday",
  },
  {
    label: "Monday",
    value: "monday",
  },
  {
    label: "Tuesday",
    value: "tuesday",
  },
  {
    label: "Wednesday",
    value: "wednesday",
  },
  {
    label: "Thursday",
    value: "thursday",
  },
  {
    label: "Friday",
    value: "friday",
  },
  {
    label: "Saturday",
    value: "saturday",
  },
];

const monthsOptions = moment.months().map((month) => {
  return {
    label: month,
    value: month,
  };
});

export {
  getDerivedDate,
  getCurrentYearDate,
  years,
  getDay,
  isWeekend,
  getOrdinalNumber,
  calendarDaysOptions,
  monthsOptions,
  calendarDaysOfWeekOptions,
  dateOptions,
};

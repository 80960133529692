import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import useOutsideAlerter from "../../../hooks/useOutsideAlerter";

import IconButton from "../Buttons/IconButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import EditItemAsset from "../DetailListCard/EditItemAsset";

import AssetAttributesModal from "../../../Pages/Overviews/Asset/AssetAttributesModal";
import collapseIcon from "../../assets/images/collapseIcon.svg";
import closeIcon from "../../assets/images/crossIcon.svg";
import editIcon from "../../assets/images/editIcon.svg";
import plusIcon from "../../assets/images/plusIcon.svg";

const cardCN = cntl`
  w-full
  mb-4  
  p-5 
  border-2
  rounded 
`;

const AttributeDetailListCard = ({
  resource,
  title,
  values,
  addItemText,
  onAdd,
  onDelete,
  onFinishEditing,
  disableEditing,
  measurements,
  setNewAttributes,
  newAttributes,
  isEditing,
}) => {
  const [editing, setEditing] = useState(isEditing);
  const [showModal, setShowModal] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [deleteHover, setDeleteHover] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    setEditing(isEditing);
  }, [isEditing]);

  const handleAddElements = (elements, newAttr) => {
    onAdd(elements, newAttr);
  };

  const clickTimer = useRef(null);

  const handleEditClick = (closeModal) => {
    if (closeModal) {
      setEditing(false);
      clickTimer.current = setTimeout(() => {
        onFinishEditing();
      }, 0);
    } else {
      setEditing((prev) => {
        const newVal = !prev;
        if (!newVal) {
          onFinishEditing();
        }
        return newVal;
      });
    }
  };

  const handleEditClickAsset = () => {
    setEditing((prev) => {
      const newVal = !prev;
      if (!newVal) {
        onFinishEditing();
      }
      return newVal;
    });
  };

  const handleDeleteClick = (id) => {
    onDelete(id);
  };

  const handleAddClick = () => {
    setShowModal(true);
    setEditing(false);
  };

  const handleBlur = () => {
    setEditing(false);
    if (editing) {
      onFinishEditing();
    }
  };
  useOutsideAlerter(wrapperRef, handleBlur);

  const handleHover = (mouseOver) => {
    if (mouseOver) {
      setHovering(true);
    } else {
      setHovering();
    }
  };

  const handleDeleteHover = (id) => {
    if (id) {
      setDeleteHover(id);
    } else {
      setDeleteHover();
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(clickTimer.current);
    };
  }, []);

  return (
    <>
      <div
        className={cardCN}
        style={{ minWidth: "384px" }}
        ref={wrapperRef}
        onMouseOver={() => handleHover(true)}
        onMouseOut={() => handleHover()}
        onFocus={() => handleHover(true)}
        onBlur={() => handleHover()}
      >
        <div className="flex justify-between items-center">
          <p className="font-bold text-gray-300">{title}</p>
          <div className="flex items-center">
            <div
              className={onAdd ? "" : "hidden"}
              style={{ margin: "2px 16px 0 0" }}
            >
              <TertiaryButton
                title={addItemText ?? "Add Item"}
                iconLeft={
                  <img
                    style={{ marginBottom: "2px" }}
                    src={plusIcon}
                    alt="plus"
                  />
                }
                onClick={handleAddClick}
                disabled={disableEditing}
              />
            </div>
            {!disableEditing && (
              <>
                {!editing && values?.length > 0 && (
                  <IconButton
                    wrapperClassName={hovering ? "" : "invisible"}
                    imgClassName="w-6 h-6"
                    iconClassName="w-6 h-6"
                    onClick={handleEditClickAsset}
                    icon={editIcon}
                  />
                )}
                {editing && (
                  <IconButton
                    wrapperClassName={hovering ? "" : "invisible"}
                    imgClassName="mt-1 w-4 h-4"
                    iconClassName="w-4 h-4"
                    onClick={handleEditClickAsset}
                    icon={closeIcon}
                  />
                )}
              </>
            )}
          </div>
        </div>
        {values.map(({ input, display }, index) => {
          const id = display?.title;
          const fieldType =
            display?.field === "check-box" ? "-mt-2 ml-auto" : "mt-5 ml-auto";
          const iconWrapper =
            onDelete && deleteHover === id && editing ? "" : "invisible";
          const isLastElement = index === values.length - 1;
          const iconSpacing = "pt-2";

          return (
            <React.Fragment key={id}>
              <div
                key={display?.title}
                className="flex items-center"
                onMouseOver={() => handleDeleteHover(id)}
                onMouseOut={() => handleDeleteHover()}
                onFocus={() => handleDeleteHover(id)}
                onBlur={() => handleDeleteHover()}
                style={{ height: editing ? "" : "60px" }}
              >
                <EditItemAsset
                  input={input}
                  display={display}
                  isEditing={editing}
                />
                <IconButton
                  wrapperClassName={`${iconWrapper} ${fieldType} ${iconSpacing}`}
                  imgClassName="w-6 h-6"
                  iconClassName="w-6 h-6"
                  onClick={() => handleDeleteClick(id)}
                  icon={collapseIcon}
                />
              </div>
              {!isLastElement && <hr />}
            </React.Fragment>
          );
        })}
      </div>
      <AssetAttributesModal
        asset={resource}
        showModal={showModal}
        setShowModal={setShowModal}
        onAddElements={handleAddElements}
        newAttributes={newAttributes}
        setNewAttributes={setNewAttributes}
        handleEditClick={handleEditClick}
        onCancel={() => setShowModal(false)}
        measurements={measurements}
      />
    </>
  );
};

AttributeDetailListCard.propTypes = {
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      input: PropTypes.element,
      // eslint-disable-next-line react/forbid-prop-types
      display: PropTypes.object,
    })
  ),
  addItemText: PropTypes.string,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onFinishEditing: PropTypes.func,
  disableEditing: PropTypes.bool,
  measurements: PropTypes.arrayOf(PropTypes.shape({})),
  newAttributes: PropTypes.arrayOf(PropTypes.shape({})),
  setNewAttributes: PropTypes.func,
  isEditing: PropTypes.func,
};

AttributeDetailListCard.defaultProps = {
  title: undefined,
  resource: undefined,
  values: [],
  addItemText: undefined,
  onAdd: undefined,
  onDelete: undefined,
  onFinishEditing: undefined,
  disableEditing: false,
  measurements: [],
  newAttributes: undefined,
  setNewAttributes: undefined,
  isEditing: false,
};

export default AttributeDetailListCard;

/* eslint-disable import/prefer-default-export */
import { useQuery } from "react-query";
// import Api from "@griffingroupglobal/eslib-api";
import { useAppState } from "../state/appState";
import { associationKeyGenerator } from "../config/reactQuery/queryKeyFactory";

// param: user
const getFilesByUser = async () => {
  // const { data } = await Api.get(`api/File?user=${user}`);
  // return data?.entries.map(({ resource }) => resource);
};
/**
 * Query hook that fetch all files by user with presigned url and refetch after 45 min
 * @note: This will fetch all files include (file for spaces)
 * @param {string} user Resource reference, ex: User/2223-3334-5556
 * @returns query hook
 */
export const useGetFilesByUser = (user, select) => {
  const [{ currentUser }] = useAppState();

  const queryKey = associationKeyGenerator("files", user);

  return useQuery({
    queryKey,
    // TODO (Validate with token)
    enabled: !!currentUser && !!user,
    select,
    queryFn: () => getFilesByUser(user),
    // TODO (Move time to constants and reuse)
    staleTime: 45 * 60 * 1000,
    onError: (error) => {
      console.error("❌ useGetFilesByUser", error.message);
    },
  });
};

/**
 * Query hook selector, to filter specific files by User reference
 * Primarily used for Contacts -> Media Widget & Files Widget
 * @param {string} user current user: Ex: User/1222-5566-3321
 * @param {string} category ["Documents"] for Files, ["Photos", "Videos"] for media
 * @returns array of selected file refs [ { file: "File/1222-5566-3321" }]
 */
export const useGetFilesByCategory = (user, category) => {
  // TODO (If you note perf issues, wrap this func into a useCallback)
  const selectFiles = (currentFiles) => {
    return currentFiles
      .filter((f) => category.includes(f.category))
      .map((item) => {
        return category.includes("Documents") ? item : { file: item.reference };
      });
  };

  return useGetFilesByUser(user, selectFiles);
};

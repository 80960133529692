import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import crossIcon from "../../assets/images/crossIcon.svg";
import filterIcon from "../../assets/images/filterIcon.svg";
import IconLabel from "../IconLabel/IconLabel";
import DateFilter from "../SearchFilter/DateFilter";

const filterCN = cntl`
  flex
  items-center
  w-full
  h-11
  px-8
  cursor-default
`;

const SiteHeaderListFilter = ({
  dateFilter,
  filterOptions,
  handleFilter,
  currentFilter,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [hovering, setHovering] = useState();
  const ref = useRef(null);

  const handleHover = (mouseOver, id) => {
    if (mouseOver) {
      setHovering(id);
    } else {
      setHovering(null);
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      setIsFilterOpen(false);
    };
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, []);

  return (
    <>
      <IconLabel
        icon={filterIcon}
        text="Filter"
        onClick={() => setIsFilterOpen((prev) => !prev)}
        textClassName="text-brandGreen"
      />
      {isFilterOpen && (
        <div
          ref={ref}
          className="search-filter relative top-10 right-96 w-0 h-0 z-20"
        >
          <div className="search-filter flex flex-col w-96 py-4 bg-white border rounded border-gray-200">
            <button
              className="self-end mb-2 mr-6"
              type="button"
              onClick={() => setIsFilterOpen(false)}
            >
              <img src={crossIcon} alt="close icon" />
            </button>
            {Object.entries(filterOptions).map(([key, label]) => {
              let hoverSelectClass = "";
              if (currentFilter === key) {
                hoverSelectClass = " text-brandGreen";
              } else if (hovering === key) {
                hoverSelectClass = " text-white bg-brandGreen";
              }
              return (
                <button
                  key={key}
                  className={`${filterCN} ${hoverSelectClass}`}
                  type="button"
                  onClick={() => {
                    handleFilter(key);
                    setIsFilterOpen(false);
                  }}
                  onMouseOver={() => handleHover(true, key)}
                  onMouseOut={() => handleHover(false)}
                  onFocus={() => handleHover(true, key)}
                  onBlur={() => handleHover(false)}
                >
                  <span>{label}</span>
                </button>
              );
            })}
            {dateFilter && <DateFilter />}
          </div>
        </div>
      )}
    </>
  );
};

export default SiteHeaderListFilter;

SiteHeaderListFilter.propTypes = {
  /**
   * filter options available
   */
  filterOptions: PropTypes.objectOf(PropTypes.string),
  /**
   * run when filter option selected
   */
  handleFilter: PropTypes.func,
  /**
   * current filter option selected
   */
  currentFilter: PropTypes.string,
  dateFilter: PropTypes.bool,
};

SiteHeaderListFilter.defaultProps = {
  filterOptions: {},
  handleFilter: undefined,
  currentFilter: undefined,
  dateFilter: false,
};

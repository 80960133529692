import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import AddExistingMemberForm from "./AddExistingMemberForm";
import AddNewMemberForm from "./AddNewMemberForm";

const NewInviteeForm = ({
  invitee,
  setInvitees,
  inviteeIndex,
  contacts,
  companyContacts,
  memberEmails,
  roles,
  states,
  ptoCategory,
  directReportOptions,
}) => {
  const [emailError, setEmailError] = useState();

  const onDirectReportChange = useCallback(
    (directReport) => {
      setInvitees((prev) =>
        prev?.map((inv, index) => {
          if (index === inviteeIndex) {
            return {
              ...inv,
              directReport,
            };
          }
          return inv;
        })
      );
    },
    [inviteeIndex, setInvitees]
  );

  const onChangeEmailErrorFlag = useCallback(
    (value) => {
      setInvitees((prev) =>
        prev.map((inv, index) => {
          if (index === inviteeIndex) {
            return {
              ...inv,
              hasEmailError: value,
            };
          }
          return inv;
        })
      );
    },
    [inviteeIndex, setInvitees]
  );

  useEffect(() => {
    if (emailError) {
      onChangeEmailErrorFlag(true);
    } else {
      onChangeEmailErrorFlag(false);
    }
  }, [emailError, onChangeEmailErrorFlag]);

  useEffect(() => {
    if (memberEmails?.length) {
      const isDuplicateEmail =
        memberEmails.indexOf(invitee?.email?.value?.trim()?.toLowerCase()) !==
        -1;
      setEmailError(
        isDuplicateEmail ? "Member with email already exists" : null
      );
    }
  }, [memberEmails, invitee]);

  const onChangeIsExisting = useCallback(
    (value) => {
      setInvitees((prev) =>
        prev.map((inv, index) => {
          if (index === inviteeIndex) {
            return {
              ...inv,
              isExisting: value,
            };
          }
          return inv;
        })
      );
    },
    [inviteeIndex, setInvitees]
  );

  const onChangeEmail = useCallback(
    (value) => {
      setInvitees((prev) =>
        prev.map((inv, index) => {
          if (index === inviteeIndex) {
            return {
              ...inv,
              email: {
                value: _.has(value, "value") ? value?.value : value,
                label: _.has(value, "label") ? value?.value : value,
              },
            };
          }
          return inv;
        })
      );
    },
    [inviteeIndex, setInvitees]
  );

  const onChangePhone = useCallback(
    (value) => {
      setInvitees((prev) =>
        prev.map((inv, index) => {
          if (index === inviteeIndex) {
            return {
              ...inv,
              phone: {
                value: _.has(value, "value") ? value?.value : value,
                label: _.has(value, "label") ? value?.value : value,
              },
            };
          }
          return inv;
        })
      );
    },
    [inviteeIndex, setInvitees]
  );

  const onChangeRole = useCallback(
    (value) => {
      setInvitees((prev) =>
        prev.map((inv, index) => {
          if (index === inviteeIndex) {
            return {
              ...inv,
              role: value,
            };
          }
          return inv;
        })
      );
    },
    [inviteeIndex, setInvitees]
  );

  const onChangeEmployee = useCallback(
    (value) => {
      setInvitees((prev) =>
        prev.map((inv, index) => {
          if (index === inviteeIndex) {
            return {
              ...inv,
              isEmployee: value,
            };
          }
          return inv;
        })
      );
    },
    [inviteeIndex, setInvitees]
  );

  const onChangeFirstName = useCallback(
    (value) => {
      setInvitees((prev) =>
        prev.map((inv, index) => {
          if (index === inviteeIndex) {
            return {
              ...inv,
              name: {
                ...inv?.name,
                firstName: value,
              },
            };
          }
          return inv;
        })
      );
    },
    [inviteeIndex, setInvitees]
  );

  const onChangeLastName = useCallback(
    (value) => {
      setInvitees((prev) =>
        prev.map((inv, index) => {
          if (index === inviteeIndex) {
            return {
              ...inv,
              name: {
                ...inv?.name,
                lastName: value,
              },
            };
          }
          return inv;
        })
      );
    },
    [inviteeIndex, setInvitees]
  );

  const onChangeCompany = useCallback(
    (value) => {
      setInvitees((prev) =>
        prev.map((inv, index) => {
          if (index === inviteeIndex) {
            return {
              ...inv,
              company: value,
              isNewCompany:
                // eslint-disable-next-line no-underscore-dangle
                value?.__isNew__ || value?.wasCreatedWithAnotherContact,
              wasCreatedWithAnotherContact: value?.wasCreatedWithAnotherContact,
            };
          }
          return inv;
        })
      );
    },
    [inviteeIndex, setInvitees]
  );

  const onChangeInvitee = useCallback(
    (value) => {
      setInvitees((prev) =>
        prev.map((inv, index) => {
          if (index === inviteeIndex) {
            return {
              ...inv,
              ...value,
            };
          }
          return inv;
        })
      );
    },
    [inviteeIndex, setInvitees]
  );

  const onChangeEmployeeType = useCallback(
    (value) => {
      setInvitees((prev) =>
        prev.map((inv, index) => {
          if (index === inviteeIndex) {
            return {
              ...inv,
              employeeType: value,
            };
          }
          return inv;
        })
      );
    },
    [inviteeIndex, setInvitees]
  );

  const onChangeAnnualVacation = useCallback(
    (value) => {
      setInvitees((prev) =>
        prev.map((inv, index) => {
          if (index === inviteeIndex) {
            return {
              ...inv,
              annualVacation: value,
            };
          }
          return inv;
        })
      );
    },
    [inviteeIndex, setInvitees]
  );

  const onChangeSalaryType = useCallback(
    (value) => {
      setInvitees((prev) =>
        prev.map((inv, index) => {
          if (index === inviteeIndex) {
            return {
              ...inv,
              salaryType: value,
            };
          }
          return inv;
        })
      );
    },
    [inviteeIndex, setInvitees]
  );

  const onChangeSalary = useCallback(
    (value) => {
      setInvitees((prev) =>
        prev.map((inv, index) => {
          if (index === inviteeIndex) {
            return {
              ...inv,
              salary: value,
            };
          }
          return inv;
        })
      );
    },
    [inviteeIndex, setInvitees]
  );

  const onRemoveInvitee = useCallback(() => {
    setInvitees((prev) => prev.filter((inv, index) => index !== inviteeIndex));
  }, [inviteeIndex, setInvitees]);

  const onChangeEmployeeState = useCallback(
    (value) => {
      setInvitees((prev) =>
        prev.map((inv, index) => {
          if (index === inviteeIndex) {
            return {
              ...inv,
              stateOfEmployment: value,
            };
          }
          return inv;
        })
      );
    },
    [inviteeIndex, setInvitees]
  );

  const onChangeEmployeeId = useCallback(
    (value) => {
      setInvitees((prev) =>
        prev.map((inv, index) => {
          if (index === inviteeIndex) {
            return {
              ...inv,
              employeeId: value,
            };
          }
          return inv;
        })
      );
    },
    [inviteeIndex, setInvitees]
  );

  const onChangeHireDate = useCallback(
    (value) => {
      const hireDate = new Date(
        Date.UTC(
          value.getUTCFullYear(),
          value.getUTCMonth(),
          value.getUTCDate()
        )
      );
      setInvitees((prev) =>
        prev.map((inv, index) => {
          if (index === inviteeIndex) {
            return {
              ...inv,
              hireDate,
            };
          }
          return inv;
        })
      );
    },
    [inviteeIndex, setInvitees]
  );

  return (
    <>
      {invitee?.isExisting && (
        <AddExistingMemberForm
          invitee={invitee}
          onRemoveInvitee={onRemoveInvitee}
          onChangeEmployee={onChangeEmployee}
          onChangeIsExisting={onChangeIsExisting}
          onChangeRole={onChangeRole}
          onChangeEmail={onChangeEmail}
          onChangePhone={onChangePhone}
          onChangeInvitee={onChangeInvitee}
          contacts={contacts}
          emailError={emailError}
          onChangeEmployeeType={onChangeEmployeeType}
          onChangeAnnualVacation={onChangeAnnualVacation}
          onChangeSalaryType={onChangeSalaryType}
          onChangeSalary={onChangeSalary}
          roles={roles}
          states={states}
          onChangeEmployeeState={onChangeEmployeeState}
          onChangeEmployeeId={onChangeEmployeeId}
          onChangeHireDate={onChangeHireDate}
          ptoCategory={ptoCategory}
          hideEmployeeDetails
          directReportOptions={directReportOptions}
          onDirectReportChange={onDirectReportChange}
        />
      )}
      {!invitee.isExisting && (
        <AddNewMemberForm
          invitee={invitee}
          onRemoveInvitee={onRemoveInvitee}
          onChangeEmployee={onChangeEmployee}
          onChangeIsExisting={onChangeIsExisting}
          onChangeRole={onChangeRole}
          onChangeEmail={onChangeEmail}
          onChangePhone={onChangePhone}
          onChangeFirstName={onChangeFirstName}
          onChangeLastName={onChangeLastName}
          onChangeCompany={onChangeCompany}
          companyContacts={companyContacts}
          emailError={emailError}
          onChangeEmployeeType={onChangeEmployeeType}
          onChangeAnnualVacation={onChangeAnnualVacation}
          onChangeSalaryType={onChangeSalaryType}
          onChangeSalary={onChangeSalary}
          roles={roles}
          states={states}
          onChangeEmployeeState={onChangeEmployeeState}
          onChangeEmployeeId={onChangeEmployeeId}
          onChangeHireDate={onChangeHireDate}
          ptoCategory={ptoCategory}
          hideEmployeeDetails
          directReportOptions={directReportOptions}
          onDirectReportChange={onDirectReportChange}
        />
      )}
    </>
  );
};

NewInviteeForm.propTypes = {
  invitee: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    avatar: PropTypes.shape({
      thumbnailUrl: PropTypes.string,
    }),
    contactPoint: PropTypes.arrayOf(
      PropTypes.shape({
        system: PropTypes.string,
        value: PropTypes.string,
        use: PropTypes.string,
      })
    ),
    isEmployee: PropTypes.bool,
    isExisting: PropTypes.bool,
    role: PropTypes.string,
    phone: PropTypes.shape({
      value: PropTypes.string,
    }),
    email: PropTypes.shape({
      value: PropTypes.string,
    }),
    company: PropTypes.string,
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }),
  setInvitees: PropTypes.func,
  inviteeIndex: PropTypes.number,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  companyContacts: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  memberEmails: PropTypes.arrayOf(PropTypes.string),
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  states: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  ptoCategory: PropTypes.string,
  directReportOptions: PropTypes.shape({}),
};

NewInviteeForm.defaultProps = {
  setInvitees: undefined,
  invitee: undefined,
  inviteeIndex: undefined,
  contacts: undefined,
  companyContacts: undefined,
  memberEmails: undefined,
  roles: [],
  states: [],
  ptoCategory: undefined,
  directReportOptions: {},
};

export default NewInviteeForm;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { RightChevron } from "../CalendarSidebar/CalendarNavigation";
import Checkbox from "../Checkbox/Checkbox";
import useCalendarProjectsData from "./useCalendarProjectsData";

const CalendarProjects = ({
  tasksOpen,
  setSelectedProjectIds,
  selectedProjectIds,
}) => {
  const {
    toggleShowProjects,
    toggleItemSelected,
    performFuzzySearch,
    clearAllSelectedProjectIds,
    currentlySelectedProjects,
    showProjects,
    selectableProjects,
    calendarProjectStyles,
    projectSearchString,
    calendarFilterShown,
  } = useCalendarProjectsData({
    tasksOpen,
    setSelectedProjectIds,
    selectedProjectIds,
  });

  // We only want to see the project filter on the calendar page
  // If we're not on the calendar page, we don't want to render the project filter
  if (!calendarFilterShown) return null;
  return (
    <>
      <div className="flex items-center justify-between">
        <h1
          className="font-semibold tracking-widest"
          style={{ fontSize: "16px" }}
        >
          Include Projects
        </h1>
        <div
          style={calendarProjectStyles.chevronStyles}
          onClick={() => toggleShowProjects()}
        >
          <RightChevron height={12} />
        </div>
      </div>

      {/* When we click on the chevron, we want to see the projects list, 
      when we click on the chevron again we want to close it */}

      {showProjects && (
        <>
          <input
            type="text"
            placeholder="Filter by project(s)"
            className="border-2 w-full border-gray-100 border-solid rounded-md p-2"
            value={projectSearchString}
            onChange={(e) => performFuzzySearch(e.target.value)}
          />
          {currentlySelectedProjects.length > 0 && (
            <div className="flex justify-between items-center mt-4">
              <p className="text-gray-300 font-normal">
                {currentlySelectedProjects.length} selected
              </p>
              <button
                type="button"
                className="text-gray-300 font-normal cursor-pointer"
                onClick={() => clearAllSelectedProjectIds()}
              >
                Clear
              </button>
            </div>
          )}

          <div style={calendarProjectStyles.projectListStyles}>
            {selectableProjects?.map((project, index) => (
              <div className="mt-4" key={project?.id}>
                {/* Clicking the checkbox should toggle the project's selected state */}
                <Checkbox
                  key={project.projectId}
                  onChange={() => toggleItemSelected(project, index)}
                  checked={project.selected}
                  inputClassName="calendar-form-tick"
                  labelClassName="text-lg text-gray-300 font-normal truncate"
                  size="7"
                  label={project.name}
                />
              </div>
            ))}
            <div style={calendarProjectStyles.projectListShadowEffect} />
          </div>
        </>
      )}
    </>
  );
};

export default CalendarProjects;

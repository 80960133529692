import React from "react";
import PropTypes from "prop-types";
import DocumentReviewField from "./DocumentReviewField";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";

const DocumentReviewElement = ({
  type,
  title,
  label,
  labelAlt,
  displayValue,
  editValue,
  editField,
  options,
  optionsAlt,
  selectedOptions,
  associatedResource,
  onChange,
  onChangeAlt,
  onCancel,
  onSave,
  onDelete,
  disableAssociation,
  disableEdit,
}) => {
  return (
    <div>
      {title && <SecondaryHeader className="mt-5">{title}</SecondaryHeader>}
      <DocumentReviewField
        className={!title ? "mt-2" : ""}
        type={type}
        label={label}
        labelAlt={labelAlt}
        displayValue={displayValue}
        editValue={editValue}
        editField={editField}
        options={options}
        optionsAlt={optionsAlt}
        selectedOptions={selectedOptions}
        associatedResource={associatedResource}
        onChange={onChange}
        onChangeAlt={onChangeAlt}
        onCancel={onCancel}
        onSave={onSave}
        onDelete={onDelete}
        disableAssociation={disableAssociation}
        disableEdit={disableEdit}
      />
    </div>
  );
};

DocumentReviewElement.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  labelAlt: PropTypes.string,
  displayValue: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  editValue: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  editField: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ),
  optionsAlt: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  selectedOptions: PropTypes.arrayOf(PropTypes.object),
  associatedResource: PropTypes.string,
  onChange: PropTypes.func,
  onChangeAlt: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  disableAssociation: PropTypes.bool,
  disableEdit: PropTypes.bool,
};

DocumentReviewElement.defaultProps = {
  type: undefined,
  title: undefined,
  label: undefined,
  labelAlt: undefined,
  displayValue: undefined,
  editValue: undefined,
  editField: undefined,
  options: undefined,
  optionsAlt: undefined,
  selectedOptions: undefined,
  associatedResource: undefined,
  onChange: undefined,
  onChangeAlt: undefined,
  onCancel: undefined,
  onSave: undefined,
  onDelete: undefined,
  disableAssociation: false,
  disableEdit: false,
};

export default DocumentReviewElement;

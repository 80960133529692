import { TicketAPI } from "@griffingroupglobal/eslib-api";
import { ticketsKeys } from "../config/reactQuery/queryKeyFactory";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { toastError } from "../helpers/Toast";

/**
 * Fetch ticket by id
 * @param {string} id Ticket id: 1111-2222-3333-4444
 */
const getTasks = async (ticketReference) => {
  const { data } = await TicketAPI.getWOP(
    `$gettasks?ticket=${ticketReference}`
  );
  return data[ticketReference];
};

/**
 * Query hook to fetch tasks associated with a service request
 * @param {string} serviceRequestReference ticket id
 * @param {Function} select function to select or transform data
 */
const useTasksByServiceRequest = (serviceRequestReference, select) => {
  return useAuthenticatedQuery({
    queryKey: ticketsKeys.tasksByTicketRef(serviceRequestReference),
    enabled: !!serviceRequestReference,
    queryFn: () => getTasks(serviceRequestReference),
    select,
    onError: (error) => {
      toastError("There was an error getting tasks. Please try again");
      console.error("useTasksByServiceRequest", error);
    },
  });
};

export default useTasksByServiceRequest;

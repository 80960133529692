import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import deleteIcon from "../../../stories/assets/images/collapseIcon.svg";
import downloadIconWhite from "../../../stories/assets/images/downloadIconWhite.svg";
import fileIconGray150 from "../../../stories/assets/images/fileIconGray150.svg";

const containerCN = (className) => cntl`
flex
flex-col
${className}`;

const DisplayFileNames = ({
  removeAttachedFile,
  filesToUpload,
  isEditing,
  customMargin,
  hideDeleteIcon,
  className,
}) => {
  return (
    <div className={containerCN(className)}>
      {filesToUpload?.map((file, index) => {
        return (
          <div
            key={file}
            className={`flex items-center ${customMargin ?? "my-2 m-l8"}`}
          >
            <div className="flex items-center">
              {!isEditing && (
                <img
                  className="w-3.5 h-3.5 mr-2"
                  src={fileIconGray150}
                  alt="upload icon"
                />
              )}
              <p
                className="text-gray-400 text-ellipsis overflow-auto"
                style={{ transition: "all 1000ms ease-in-out" }}
              >
                {isEditing && (
                  <PrimaryButton
                    className="w-full justify-center px-4"
                    style={{ minWidth: "100%" }}
                    title={file?.name}
                    iconLeft={<img alt="icon" src={downloadIconWhite} />}
                    onClick={() => {
                      window.open(file?.contentsUrl, "_blank");
                    }}
                  />
                )}
                {!isEditing && file?.name}
              </p>
              {!hideDeleteIcon && (
                <button
                  type="button"
                  onClick={() => removeAttachedFile(index, file)}
                >
                  <img
                    className="h-5 w-5 ml-2"
                    src={deleteIcon}
                    alt="delete icon"
                  />
                </button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

DisplayFileNames.propTypes = {
  hideDeleteIcon: PropTypes.bool,
  customMargin: PropTypes.string,
  removeAttachedFile: PropTypes.func,
  filesToUpload: PropTypes.arrayOf(PropTypes.shape({})),
  isEditing: PropTypes.bool,
  className: PropTypes.string,
};

DisplayFileNames.defaultProps = {
  className: undefined,
  hideDeleteIcon: false,
  customMargin: undefined,
  removeAttachedFile: undefined,
  filesToUpload: undefined,
  isEditing: false,
};

export default DisplayFileNames;

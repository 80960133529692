import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  DocumentAPI,
  WorkflowAPI,
  CommentAPI,
} from "@griffingroupglobal/eslib-api";
import { useParams, useHistory } from "react-router";

import useFavorites from "../../../hooks/useFavorites";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useSystemConfiguration from "../../../hooks/useSystemConfiguration";

// eslint-disable-next-line import/no-cycle
import BudgetTable from "../BudgetTable/BudgetTable";
import CustomDocumentReview from "../DocumentReviewForm/CustomDocumentReview";
import PurchaseAuthorizationReview from "../DocumentReviewForm/PurchaseAuthorizationReview";
import RequestForInformationReview from "../DocumentReviewForm/RequestForInformationReview";
import RequestForProposalReview from "../DocumentReviewForm/RequestForProposalReview";
import ContingencyAuthorizationReview from "../DocumentReviewForm/ContingencyAuthorizationReview";
import PurchaseOrderReview from "../DocumentReviewForm/PurchaseOrderReview";
import ChangeOrderReview from "../DocumentReviewForm/ChangeOrderReview";
import DocumentTable from "../DocumentTable/DocumentTable";
import DocumentUploadForm from "../DocumentUploadForm/DocumentUploadForm";
import AttachItemModal from "../AttachItemModal/AttachItemModal";
import ScheduleOfValues from "../ScheduleOfValues/ScheduleOfValues";
import WorkflowView from "../../../Pages/Overviews/Workflows/WorkflowView";
import {
  contingencyColumns,
  budgetAdjustmentsColumns,
} from "../DocumentForm/DocumentBudgetColumns";
import {
  formatSelectUser,
  formatWithCommasWithoutDecimal,
} from "../../../helpers/Formatters";

// eslint-disable-next-line import/no-cycle
import DocumentAdjustments from "../DocumentAdjustments/DocumentAdjustments";

import Spinner from "../Spinner/Spinner";
import Pill from "../Pill/Pill";
import Table from "../Table/Table";
import Summary from "../Summary/Summary";
import Modal from "../Modal/Modal";
import ModalConfirmAll from "../Modal/ModalConfirmAll";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";

import {
  RESET,
  ADD_DOCUMENT_ATTACHMENTS,
  REMOVE_DOCUMENT_ATTACHMENT,
  PROJECT_ADDITIONAL_DOCUMENTS_PATH,
  PURCHASE_AUTHORIZATION,
  GET_PROJECT_DOCUMENTS_PATH,
  DOCUMENTS_PATH,
  ADD_LINE_ITEMS,
  REMOVE_LINE_ITEMS,
  REQUEST_FOR_INFORMATION,
  REQUEST_FOR_PROPOSAL,
  CHANGE_ORDER,
  CONTINGENCY_AUTHORIZATION,
  CONTINGENGY_CSI_CODE,
  CONTINGENGY_LINE_ITEM_DESCRIPTION,
  PURCHASE_ORDER,
  SIMPLE_INPUT,
  docLineItemAdjustmentKeys,
  REMOVE_BUDGET_LINE,
  REMOVE_FORM_DATA,
  GET_DOCUMENT_FOLDER_FILE_PATH,
  GET_PROJECT_DOCUMENTS_DOC_PATH,
  GET_DOCUMENT_PATH,
  GET_DOCUMENT_FOLDER_PATH,
  GET_PROPERTY_DOCUMENT_PATH,
  BUDGET_COST_PLUS,
  BUDGET_FIXED_FIRM,
  GET_PROPERTY_DOCUMENTS_DOC_PATH,
  VENDOR_INVOICE,
} from "../../../constants";
import fileIcon from "../../assets/images/fileIcon.svg";
import { getUserData } from "../../../helpers/Budget";
import BudgetTableComments from "../BudgetTable/BudgetTableComments";
import { getDocumentUser } from "../../../helpers/Document";
import { getSingleResourcePath } from "../../../helpers/Navigation";

const deleteButtonStyle = {
  marginTop: "20px",
};

const MINI_MAP_SCROLL_CONTAINER_ID = "mini-map-document-budget-table";

const DocumentViewForm = ({
  document,
  existingDoc,
  dispatch,
  budget,
  userList,
  property,
  project,
  associatedResource,
  onSelectAssociatedResource: setAssociatedResource,
  docTypeOptionsMap,
  selectedBudgetRows,
  onBudgetRowSelect,
  disableAssociation,
  disableEdit,
  createMode,
  viewMode,
  editMode,
  originalLineItems,
  csiCodes,
  onCancel,
  hideFloatingActionButtons,
  documentsDict,
  allDocuments,
  addDocument,
  addDocuments,
  removeDocument,
  reload,
}) => {
  const history = useHistory();
  const { data: currentUser } = useCurrentUser();
  const [favorites, { postFavorite, deleteFavorite }] = useFavorites();
  const { folderName, projectId, propertyId, documentId } = useParams();
  const { data: systemConfiguration } = useSystemConfiguration();

  const [userData, setUserData] = useState([]);
  const [originalDocument, setOriginalDocument] = useState(document);
  const [isSaving, setIsSaving] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [handledAdjustments] = useState(true);

  const [workflow, setWorkflow] = useState();
  const [workflowModal, setWorkflowModal] = useState();
  const [selectedRowsToAdd, setSelectedRowsToAdd] = useState([]);
  const [showRowsModal, setShowRowsModal] = useState(false);
  const [showAdjustmentModal, setShowAdjustmentModal] = useState(false);

  const [addedFiles, setAddedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [showDeleteDocModal, setShowDeleteDocModal] = useState(false);
  const [isDeletingDoc, setIsDeletingDoc] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState();
  const [contingencyLineItem, setContingencyLineItem] = useState();
  const [, setUnitsOfMeasure] = useState([]);
  const [currentAttachment, setCurrentAttachment] = useState({});
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [prevRowCount, setPrevRowCount] = useState(0);
  const [filterColumns, setFilterColumns] = useState([]);
  const [adjustmentKey, setAdjustmentKey] = useState(
    docLineItemAdjustmentKeys[document?.docType]
  );
  const [adjustmentButtonDisabled, setAdjustmentButtonDisabled] =
    useState(true);
  const [costPlusLocked, setCostPlusLocked] = useState();
  const [, setFixedFirmLocked] = useState();
  const [parentDocument, setParentDocument] = useState();
  const [selectedLineItemForComments, setSelectedLineItemForComments] =
    useState(null);
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [commentsData, setCommentsData] = useState([]);
  const [docBudgetLines, setDocBudgetLines] = useState([]);

  useEffect(() => {
    const getComments = async () => {
      if (selectedLineItemForComments) {
        setIsLoadingComments(true);
        try {
          const { data } = await CommentAPI.get({
            params: {
              association: selectedLineItemForComments,
            },
          });

          const formatedComments =
            data?.map(async (info) => {
              const user = getUserData(userList, info?.author);
              const formatedReplies =
                info?.replies?.map((reply) => ({
                  ...reply,
                  userData: getUserData(userList, reply?.author),
                })) || [];
              const replies = await Promise.all(formatedReplies);
              return {
                ...info,
                userData: user,
                replies,
              };
            }) || [];
          const allComments = await Promise.all(formatedComments);

          setCommentsData(allComments);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(
            `Error fetching Comment: ${err?.response?.data?.issues[0]?.detail?.display}`
          );
        }
        setIsLoadingComments(false);
      }
    };

    if (selectedLineItemForComments) {
      getComments();
    } else {
      setCommentsData([]);
    }
  }, [selectedLineItemForComments, userList]);

  useEffect(() => {
    if (existingDoc && existingDoc.id !== document?.id) {
      dispatch({
        type: RESET,
        value: existingDoc,
      });
    }
  }, [document, dispatch, existingDoc]);

  useEffect(() => {
    if (!originalDocument?.id) {
      setOriginalDocument(document);
    }
  }, [originalDocument, document]);

  useEffect(() => {
    if (allDocuments?.length && document?.documentRelationships?.parent) {
      const parentDoc = allDocuments.find(
        (doc) => doc.reference === document?.documentRelationships?.parent
      );
      setParentDocument(parentDoc);
    }
  }, [allDocuments, document]);

  useEffect(() => {
    const { budgetType, isLockGmp, isLockFixedFirm } = budget ?? {};

    const lockGMP = budgetType === BUDGET_COST_PLUS && isLockGmp;
    const lockFF = budgetType === BUDGET_FIXED_FIRM && isLockFixedFirm;

    setCostPlusLocked(lockGMP);
    setFixedFirmLocked(lockFF);
  }, [budget]);

  useEffect(() => {
    const getWorkflow = async () => {
      const { data } = await WorkflowAPI.getById(
        document.workflow.split("/")[1]
      );
      setWorkflow(data);
    };
    if (document?.workflow && !workflow) {
      getWorkflow();
    }
  }, [document, workflow]);

  useEffect(() => {
    setAdjustmentKey(docLineItemAdjustmentKeys[document?.docType]);
  }, [document?.docType]);

  useEffect(() => {
    const adjustmentSum = Object.entries(document?.state?.lineItemData ?? {})
      .filter(([key]) => {
        return selectedBudgetRows.includes(key);
      })
      .reduce((sum, [, val]) => {
        return sum + val[adjustmentKey] || 0;
      }, 0);

    setContingencyLineItem((prev) => ({
      ...(prev ?? {}),
      adjustmentAmount: adjustmentSum,
    }));
  }, [document?.state?.lineItemData, selectedBudgetRows, adjustmentKey]);

  useEffect(() => {
    const contingencyLine = originalLineItems
      .filter((item) => {
        return CONTINGENGY_CSI_CODE.includes(item.csiCode);
      })
      .reduce(
        (obj, item) => {
          const val = {
            uncommittedValue:
              obj.uncommittedValue + (Number(item.uncommittedValue) || 0),
            adjustmentAmount:
              obj.adjustmentAmount + (Number(item.adjustmentAmount) || 0),
            uncommittedValuePending:
              obj.uncommittedValuePending +
              (Number(item.uncommittedValuePending) || 0),
            updatedGmp: obj.updatedGmp + (Number(item.updatedGmp) || 0),
            updatedGmpPending:
              obj.updatedGmpPending + (Number(item.updatedGmpPending) || 0),
            vendor: obj.vendor || item.vendor,
          };
          return val;
        },
        {
          uncommittedValue: 0,
          adjustmentAmount: 0,
          uncommittedValuePending: 0,
          updatedGmp: 0,
          updatedGmpPending: 0,
          vendor: "",
        }
      );

    const adjustmentSum =
      Object.entries(document?.state?.lineItemData ?? {})
        .filter(([key]) => {
          return selectedBudgetRows.includes(key);
        })
        .reduce((sum, [, val]) => {
          return sum + val.adjustment?.amount || 0;
        }, 0) ?? {};

    contingencyLine.adjustmentAmount = adjustmentSum;
    contingencyLine.csiCode =
      csiCodes.find((code) => CONTINGENGY_CSI_CODE.includes(code.value))
        ?.label ?? CONTINGENGY_CSI_CODE;
    contingencyLine.description = CONTINGENGY_LINE_ITEM_DESCRIPTION;
    contingencyLine.vendorName = userData.find(
      (user) => user.value === contingencyLine.vendor
    )?.label;
    setContingencyLineItem((prev) => {
      return { ...prev, ...contingencyLine };
    });
  }, [
    document?.state?.lineItemData,
    originalLineItems,
    selectedBudgetRows,
    csiCodes,
    userData,
  ]);

  useEffect(() => {
    if (userList?.length) {
      setUserData(() => {
        return userList?.map((usr) => {
          return formatSelectUser({
            ...usr,
            role: usr.roleName,
          });
        });
      });
    }
  }, [userList]);

  useEffect(() => {
    if (systemConfiguration?.system?.unitsOfMeasure) {
      const lengthOptions =
        systemConfiguration?.system?.unitsOfMeasure?.length_area
          ?.filter((uom) => uom.selected)
          ?.map((uom) => ({ label: uom.display, value: uom.id }));
      const qtyOptions = systemConfiguration?.system?.unitsOfMeasure?.quantity
        ?.filter((uom) => uom.selected)
        ?.map((uom) => ({ label: uom.display, value: uom.id }));
      const timeOptions = systemConfiguration?.system?.unitsOfMeasure?.time
        ?.filter((uom) => uom.selected)
        ?.map((uom) => ({ label: uom.display, value: uom.id }));
      const volumeOptions =
        systemConfiguration?.system?.unitsOfMeasure?.volume_weight
          ?.filter((uom) => uom.selected)
          ?.map((uom) => ({ label: uom.display, value: uom.id }));

      setUnitsOfMeasure([
        ...lengthOptions,
        ...qtyOptions,
        ...timeOptions,
        ...volumeOptions,
      ]);
    }
  }, [systemConfiguration]);

  useEffect(() => {
    setDocBudgetLines(
      document?.workingLineItems?.map((item) => {
        const lineData = document.state?.lineItemData?.[item?.id] ?? {};
        const sov = document.state?.sov ?? {};
        const sovData = sov[item?.id] ?? [];
        const lineitemToVendorInvoiceInfo =
          document.state?.lineitemToVendorInvoiceInfo?.[item.reference];

        const result = {
          ...item,
          csiCode:
            csiCodes.find(
              (code) => code.value === item?.csiCode?.split(" -")[0]
            )?.label ?? "",
          ...lineData,
          sov: sovData.map((sovRow) => ({
            ...sovRow,
            poName: documentsDict[sovRow?.po]?.name,
          })),
          // VI specific ...
          parentPurchaseOrder: lineitemToVendorInvoiceInfo?.po?.map((po) => {
            const res = documentsDict[po];
            return {
              name: res?.name,
              reference: po,
            };
          }),
          committedValues: lineitemToVendorInvoiceInfo?.committedValues,
          workCompletedThisPeriod:
            lineitemToVendorInvoiceInfo?.workCompletedThisPeriod,
          workCompletedPreviousPeriod:
            lineitemToVendorInvoiceInfo?.workCompletedPreviousPeriod,
          workCompletedToDate: lineitemToVendorInvoiceInfo?.workCompletedToDate,
          percentCompleteOfCommittedValues:
            lineitemToVendorInvoiceInfo?.percentCompleteOfCommittedValues,
          retainage: lineitemToVendorInvoiceInfo?.retainage,
          holdback: lineitemToVendorInvoiceInfo?.holdback,
          workCompletedToDateLessRetainageAndHoldback:
            lineitemToVendorInvoiceInfo?.workCompletedToDateLessRetainageAndHoldback,
          previousPaymentDue: lineitemToVendorInvoiceInfo?.previousPaymentDue,
          currentPaymentDue: lineitemToVendorInvoiceInfo?.currentPaymentDue,
          balanceToFinish: lineitemToVendorInvoiceInfo?.balanceToFinish,
        };

        result.vendorName = userData.find(
          (user) => user.value === result.vendor
        )?.label;

        return result;
      }) || []
    );
  }, [
    csiCodes,
    document.state?.lineItemData,
    document.state?.lineitemToVendorInvoiceInfo,
    document.state?.sov,
    document?.workingLineItems,
    documentsDict,
    userData,
  ]);

  const handlePostComment = useCallback(
    async (value) => {
      try {
        if (value.trim().length > 0) {
          const commentObj = {
            association: selectedLineItemForComments,
            content: value,
            author: currentUser?.reference,
          };
          const { data: newComment } = await CommentAPI.post(commentObj);
          newComment.userData = currentUser;
          newComment.replies = [];
          setCommentsData((prev) => [...prev, newComment]);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(
          `Error posting Comment: ${error?.response?.data?.issues[0]?.detail?.display}`
        );
      }
    },
    [currentUser, selectedLineItemForComments]
  );

  const handlePostReply = useCallback(
    async (value, commentId) => {
      if (value.trim().length > 0) {
        const tempObj = {
          content: value,
          author: currentUser.reference,
        };
        const { data: reply } = await CommentAPI.postWOP(
          `${commentId}/$addreply`,
          {
            ...tempObj,
          }
        );

        const replyComment = {
          ...reply,
          userData: currentUser,
          replies: [],
        };

        const updatedComments = commentsData.map((comment) => {
          if (comment.id === commentId) {
            return { ...comment, replies: [...comment.replies, replyComment] };
          }
          return comment;
        });

        setCommentsData((prev) => updatedComments ?? prev);
      }
    },
    [commentsData, currentUser]
  );

  const handleAdjustmentValidation = React.useCallback(
    (formDataOverride, enableModal) => {
      const lineItemData = document.state?.lineItemData ?? {};
      const formData = document.state?.formData?.purchaseAuthorization ?? {};
      let isValidCoPending;
      let hasContingency;

      switch (document.docType) {
        case CHANGE_ORDER:
          isValidCoPending = document.workingLineItems.every(
            (item) => lineItemData[item.id]?.coTotalPending
          );
          if (enableModal && !isValidCoPending) {
            setShowAdjustmentModal(true);
            return false;
          }
          return isValidCoPending;
        case CONTINGENCY_AUTHORIZATION:
          hasContingency = originalLineItems
            .filter((item) => {
              return CONTINGENGY_CSI_CODE.includes(item.csiCode);
            })
            .reduce((val, item) => {
              return val + (Number(item.uncommittedValue) || 0);
            }, 0);
          if (enableModal && !hasContingency) {
            setShowAdjustmentModal(true);
            return false;
          }
          return (
            hasContingency &&
            document.workingLineItems.every(
              (item) => lineItemData[item.id]?.caAdjustment
            )
          );
        case PURCHASE_AUTHORIZATION:
          return document.workingLineItems.every((item) => {
            let validLineData = true;
            let validFormData = true;

            const { coAssignment, caAssignment } = formData[item.id] ?? {};
            const paAmount = Number(
              `${lineItemData[item.id]?.paAmount}`.replaceAll(",", "")
            );

            validLineData = paAmount > 0;

            if (costPlusLocked) {
              const paAdjustment = Number(
                `${lineItemData[item.id]?.paAdjustment}`.replaceAll(",", "")
              );
              const uncommittedValuePending =
                document.workingLineItems?.find((line) => line.id === item.id)
                  ?.uncommittedValuePending ?? -1;
              const uncommittedPendingAmount = Number(
                `${uncommittedValuePending}`.replaceAll(",", "")
              );

              validLineData =
                uncommittedPendingAmount > 0 &&
                uncommittedPendingAmount >= paAmount;

              const coAmount = Number(`${coAssignment}`.replaceAll(",", ""));
              const caAmount = Number(`${caAssignment}`.replaceAll(",", ""));
              validFormData =
                !paAdjustment || paAdjustment === coAmount + caAmount;
              if (enableModal && !validFormData) {
                setShowAdjustmentModal(true);
                return false;
              }
            }

            if (enableModal && !validLineData) {
              setShowAdjustmentModal(true);
              return false;
            }

            return validLineData && (validFormData || formDataOverride);
          });
        default:
          return true;
      }
    },
    [
      originalLineItems,
      document.docType,
      document.state?.lineItemData,
      document.state?.formData,
      document.workingLineItems,
      costPlusLocked,
    ]
  );

  const handleAdjustmentInput = (action) => {
    dispatch(action);
  };

  useEffect(() => {
    const result = handleAdjustmentValidation(false, false);
    setAdjustmentButtonDisabled(!result);
  }, [document?.state, handleAdjustmentValidation]);

  const handleFavoriteClick = React.useCallback(
    (id) => {
      const foundItem = favorites.find((favorite) => {
        return favorite.item.reference.includes(id);
      });
      const foundDoc = allDocuments.find((doc) => doc.id === id);

      if (foundItem) {
        deleteFavorite([foundItem]);
      } else {
        postFavorite(id);
      }

      if (foundDoc) {
        delete foundDoc.isFavorited;
        dispatch({
          type: ADD_DOCUMENT_ATTACHMENTS,
          value: addedFiles.map((item) => {
            if (item.id === foundDoc.id) {
              return foundDoc;
            }
            return item;
          }),
        });
        addDocument(foundDoc);
      }
    },
    [
      allDocuments,
      favorites,
      addedFiles,
      dispatch,
      deleteFavorite,
      postFavorite,
      addDocument,
    ]
  );

  const args = {
    document,
    dispatch,
    userData,
    associatedResource,
    setAssociatedResource,
    disableAssociation: disableAssociation || viewMode,
    disableEdit: disableEdit || viewMode,
    createMode,
    viewMode,
    editMode,
    documentsDict,
    projectId,
  };

  const docTypes = {
    purchaseAuthorization: <PurchaseAuthorizationReview {...args} />,
    purchaseOrder: <PurchaseOrderReview {...args} />,
    changeOrder: <ChangeOrderReview {...args} />,
    requestForProposal: <RequestForProposalReview {...args} />,
    requestForInformation: <RequestForInformationReview {...args} />,
    contingencyAuthorization: <ContingencyAuthorizationReview {...args} />,
    submittal: "submittal",
    invoice: "invoice",
    meetingMinutes: "meetingMinutes",
    dailyLog: "dailyLog",
    drawings: "drawings",
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else if (folderName) {
      history.push(GET_DOCUMENT_FOLDER_PATH(folderName));
    } else if (projectId) {
      history.push(GET_PROJECT_DOCUMENTS_PATH(projectId));
    } else if (propertyId) {
      history.push(GET_PROPERTY_DOCUMENT_PATH(propertyId));
    } else {
      history.push(DOCUMENTS_PATH);
    }
  };

  const handleCancelClick = () => {
    setShowConfirm(true);
  };

  const handleCancelConfirm = () => {
    if (editMode) {
      if (folderName) {
        return GET_DOCUMENT_FOLDER_FILE_PATH(folderName, documentId);
      }
      if (projectId) {
        return GET_PROJECT_DOCUMENTS_DOC_PATH(projectId, documentId);
      }
      if (propertyId) {
        return GET_PROPERTY_DOCUMENT_PATH(propertyId);
      }
    }
    return GET_DOCUMENT_PATH(documentId);
  };

  const deleteButtonHandler = () => {
    setDeleteDoc(document);
    setShowDeleteModal(true);
  };

  const handleShowDelete = (doc) => {
    if (typeof doc === "string") {
      setDeleteDoc(doc);
    } else {
      setDeleteDoc(doc);
    }
    setShowDeleteDocModal(true);
  };

  const handleHideDelete = () => {
    setDeleteDoc();
    setShowDeleteDocModal(false);
  };

  const handleDeleteDocument = async () => {
    setIsDeletingDoc(true);
    await DocumentAPI.delete(
      typeof deleteDoc === "string" ? deleteDoc : deleteDoc.id
    )
      .then(({ data: docData }) => {
        setAddedFiles((prev) => prev.filter((item) => item.id !== docData.id));
        dispatch({
          type: REMOVE_DOCUMENT_ATTACHMENT,
          value: docData,
        });
        removeDocument(docData.id);
      })
      .catch((err) => {
        console.error(err);
      });

    setIsDeletingDoc(false);
    handleHideDelete();
  };

  const discardButtonHandler = () => {
    setShowConfirm(true);
  };

  const finishButtonHandler = (patch, patchDoc) => {
    if (!handleAdjustmentValidation(false, true)) {
      return;
    }
    setIsSaving(true);
    const formVersion = { ...document };
    const hasChange = document.workingLineItems?.some(
      (item) => item.changeOrder
    );
    const hasContingency = document.workingLineItems?.some(
      (item) => item.contingencyAuthorization
    );

    const documentData = Object.entries(patchDoc ?? document)
      .filter(([key, value]) => {
        if (key === "customName") {
          return true;
        }
        return !!value;
      })
      .reduce((obj, entry) => {
        const [entryKey, entryValue] = entry;
        let newVal;
        if (
          entryKey === "qualificationsAndExclusions" ||
          entryKey === "submissionReqs" ||
          entryKey === "additionalRequirements" ||
          entryKey === "deviations"
        ) {
          newVal = entryValue
            ?.filter((qual) => qual.checked !== false)
            .map((qual) => qual.text);
        } else if (entryKey === "attachments" || entryKey === "lineItems") {
          newVal = entryValue.map((item) => item.reference);
        } else if (entryKey === "distro") {
          newVal = entryValue.map((item) => item.id);
        }
        return { ...obj, [entryKey]: newVal ?? entryValue };
      }, {});

    delete documentData.creator;
    delete documentData.history;
    delete documentData.adjustments;

    if (!(documentData.property || documentData.project)) {
      // Associated this document to a Property or Project
      // property id is set when Create is clicked from within a property. Url Param
      if (property?.id) {
        documentData.property = `Property/${property.id}`;
      }

      // project id is set when Create is clicked from within a project. Url Param
      if (project?.id) {
        documentData.project = `Project/${project.id}`;
      }
    }
    // associatedResource is set from the Admin Associated Resource dropdown.
    // associatedResource is given precendence over propertyId or project id,
    // because the Admin user specifically chose it.
    if (associatedResource) {
      if (associatedResource?.value?.startsWith("Property")) {
        documentData.property = associatedResource.value;
      } else if (associatedResource?.value?.startsWith("Project")) {
        documentData.project = associatedResource.value;
      } else if (associatedResource.startsWith("Property")) {
        documentData.property = associatedResource;
      } else if (associatedResource.startsWith("Project")) {
        documentData.project = associatedResource;
      }
    }

    documentData.state = {
      ...documentData.state,
      checkedBudgetRows: selectedBudgetRows,
      lineItems: [],
    };

    const lineItemData = documentData.state?.lineItemData ?? {};
    const formData = documentData.state?.formData ?? {};
    const sov = documentData.state?.sov ?? {};
    const lineItems = [];

    switch (documentData.docType) {
      case CHANGE_ORDER:
        Object.entries(lineItemData).forEach(([key, val]) => {
          lineItems.push({
            reference: `Lineitem/${key}`,
            coTotalPending: `${val?.coTotalPending ?? 0}`,
          });
        });
        break;
      case CONTINGENCY_AUTHORIZATION:
        Object.entries(lineItemData).forEach(([key, val]) => {
          lineItems.push({
            reference: `Lineitem/${key}`,
            caAdjustment: `${val?.caAdjustment ?? 0}`,
          });
        });
        break;
      case PURCHASE_AUTHORIZATION:
        Object.entries(lineItemData).forEach(([key, val]) => {
          const rowData = formData[PURCHASE_AUTHORIZATION]?.[key] ?? {};
          const result = {
            reference: `Lineitem/${key}`,
            paAmount: `${val?.paAmount ?? 0}`,
            adjustment: {
              ...(val?.paAdjustment
                ? { paAdjustment: `${val.paAdjustment}` }
                : {}),
              ...(rowData.coAssignment
                ? { co: `${rowData.coAssignment}` }
                : {}),
              ...(rowData.caAssignment
                ? { ca: `${rowData.caAssignment}` }
                : {}),
            },
          };
          lineItems.push(result);
        });
        break;
      case PURCHASE_ORDER:
        Object.entries(sov).forEach(([key, val]) => {
          lineItems.push({
            reference: `Lineitem/${key}`,
            sov: val,
          });
        });
        break;
      case VENDOR_INVOICE:
        Object.entries(sov).forEach(([key, val]) => {
          lineItems.push({
            reference: `Lineitem/${key}`,
            sov: val,
          });
        });
        break;
      default:
        break;
    }

    documentData.lineItemEdits = lineItems.filter((item) =>
      selectedBudgetRows.includes(item.reference?.split("/")[1])
    );
    documentData.workingLineItems = documentData.workingLineItems
      .filter((item) =>
        selectedBudgetRows.includes(item.reference?.split("/")[1])
      )
      .map((item) => item.reference);
    documentData.lineItems = documentData.workingLineItems;
    delete documentData.workingLineItems;
    if (patch) {
      DocumentAPI.patch(document.id, documentData, originalDocument)
        .then(async ({ data }) => {
          formVersion.metadata.lastUpdated = data.metadata.lastUpdated;
          formVersion.modifiedBy = data.modifiedBy;
          formVersion.modifiedDate = data.modifiedDate;
          formVersion.status = data.status;
          formVersion.number = data.number;
          formVersion.version = data.version;
          formVersion.state = data.state;

          dispatch({
            type: RESET,
            value: formVersion,
          });

          addDocument(data);

          // add additional Documents (if any) created on patch call
          if (data?.childrenDocuments?.length) {
            data.childrenDocuments.map((doc) => addDocument(doc));
          }

          setIsSaving(false);
          await reload();

          if (project?.id) {
            history.push(
              GET_PROJECT_DOCUMENTS_DOC_PATH(project.id, document.id)
            );
          } else if (property?.id) {
            history.push(
              GET_PROPERTY_DOCUMENTS_DOC_PATH(property.id, document.id)
            );
          } else {
            history.push(DOCUMENTS_PATH);
          }
        })
        .catch((err) => {
          setIsSaving(false);
          console.error(err);
        });
    } else {
      DocumentAPI.post(documentData)
        .then(({ data }) => {
          formVersion.id = data.id;
          formVersion.status = data.status;
          formVersion.createdBy = data.createdBy;
          formVersion.state = data.state;

          dispatch({
            type: RESET,
            value: formVersion,
          });

          addDocument(data);

          // add additional Documents (if any) created on patch call
          if (data?.childrenDocuments?.length) {
            data.childrenDocuments.map((doc) => addDocument(doc));
          }
          setIsSaving(false);

          if (
            (hasChange || hasContingency) &&
            document.docType === PURCHASE_AUTHORIZATION
          ) {
            history.push(PROJECT_ADDITIONAL_DOCUMENTS_PATH);
          } else if (project?.id) {
            history.push(GET_PROJECT_DOCUMENTS_PATH(project.id));
          } else if (property?.id) {
            history.push(GET_PROPERTY_DOCUMENT_PATH(property.id));
          } else {
            history.push(DOCUMENTS_PATH);
          }
        })
        .catch((err) => {
          setIsSaving(false);
          console.error(err);
        });
    }
  };

  const attachmentButtonClickedHandler = () => {
    window.document.getElementsByClassName("document_upload_form")[0]?.click();
  };

  const previewDocHandler = () => {};

  const getDocType = React.useCallback(
    (documentType) => {
      if (documentType) {
        return docTypeOptionsMap[documentType]?.label ?? documentType;
      }
      return "File";
    },
    [docTypeOptionsMap]
  );

  useEffect(() => {
    const stillUploading = addedFiles.some((item) => item.isUploading);
    if (!stillUploading && isUploading && addedFiles.length) {
      addedFiles.forEach((item) => {
        if (item.isFavorited) {
          handleFavoriteClick(item.id);
        }
      });
      addDocuments(addedFiles);
      dispatch({
        type: ADD_DOCUMENT_ATTACHMENTS,
        value: addedFiles,
      });
      setIsUploading(false);
    }
  }, [addedFiles, isUploading, dispatch, addDocuments, handleFavoriteClick]);

  const handleFileAdded = React.useCallback(
    (fileRef, fileBody, showSpinner) => {
      if (showSpinner) {
        setIsUploading(true);
      }

      const currentFile = {
        isUploading: true,
        reference: fileRef,
        isFavorited: fileBody.isFavorited,
      };
      setAddedFiles((prev) => [currentFile, ...prev]);

      if (fileBody) {
        const tempDocument = {
          customName: fileBody.name,
          docType: fileBody.docType,
          contentReference: fileRef,
          lineItems: [],
          status: "open",
        };

        if (document?.project) {
          tempDocument.project = document?.project;
        } else if (document?.property) {
          tempDocument.property = document?.property;
        }

        DocumentAPI.post(tempDocument)
          .then(({ data: newDocument }) => {
            const resultDocument = {
              ...newDocument,
              creator: currentUser,
              isFavorited: fileBody.isFavorited,
              uploadedFileRef: fileRef,
            };

            setAddedFiles((prev) => {
              return [
                resultDocument,
                ...prev.filter((item) => item.reference !== fileRef),
              ];
            });
            setIsUploading(false);
          })
          .catch((err) => console.error(err));
      }
    },
    [currentUser, document?.project, document?.property]
  );

  const handleRemoveRows = ({ original }) => {
    dispatch({
      type: REMOVE_LINE_ITEMS,
      value: [original],
    });
    dispatch({
      type: REMOVE_BUDGET_LINE,
      key: original.id,
    });

    if (document.docType === PURCHASE_AUTHORIZATION) {
      dispatch({
        type: REMOVE_FORM_DATA,
        key: PURCHASE_AUTHORIZATION,
        value: original.id,
      });
    }
  };

  const handleAddRows = () => {
    dispatch({
      type: ADD_LINE_ITEMS,
      value: selectedRowsToAdd.map((item) => {
        return { ...item, checked: true };
      }),
    });
    dispatch({
      type: SIMPLE_INPUT,
      key: "state",
      value: {
        ...document.state,
        checkedBudgetRows: selectedRowsToAdd.map((item) => item.id),
      },
    });
    onBudgetRowSelect(selectedRowsToAdd.map((item) => item.id));
    setShowAdjustmentModal(true);
    setShowRowsModal(false);
  };

  const handleShowRowsModal = () => {
    setShowRowsModal(true);
  };

  const handleCloseRowsModal = () => {
    setShowRowsModal(false);
  };

  const handleShowAttachment = (doc) => {
    setCurrentAttachment({
      id: doc ? doc.reference.split("/")?.[1] : "",
      contentReference: doc.contentReference,
    });
    setShowAttachmentModal(true);
  };

  const handleHideAttachment = () => {
    setCurrentAttachment({});
    setShowAttachmentModal(false);
  };

  const handleLineUpdate = React.useCallback(
    (action) => {
      const { key, value } = action ?? {};
      const itemData = document.state?.lineItemData?.[key] ?? {};
      setContingencyLineItem((prev) => {
        return {
          ...prev,
          adjustmentAmount:
            (prev?.adjustmentAmount || 0) +
            (value.adjustment?.amount || 0) -
            (itemData.adjustment?.amount || 0),
        };
      });

      if (action.value?.paAdjustment) {
        setShowAdjustmentModal(true);
      }
      dispatch(action);
    },
    [document.state?.lineItemData, dispatch]
  );

  const handleBudgetRowSelect = React.useCallback(
    (val) => {
      if (showAdjustmentModal) return;
      const rows = val.map((item) => item.id);
      if (rows.length > selectedBudgetRows?.length) {
        const added = rows.filter((item) => !selectedBudgetRows.includes(item));
        const addedAmount = added.reduce((sum, id) => {
          const itemData = document.state?.lineItemData?.[id] ?? {};
          return sum + itemData[adjustmentKey] || 0;
        }, 0);
        if (addedAmount) {
          setContingencyLineItem((prev) => {
            return {
              ...prev,
              adjustmentAmount: (prev?.adjustmentAmount || 0) + addedAmount,
            };
          });
        }
        onBudgetRowSelect(rows);
      } else if (
        (rows.length > 0 && rows.length < selectedBudgetRows?.length) ||
        (rows.length === 0 &&
          prevRowCount === 1 &&
          rows.length < selectedBudgetRows?.length)
      ) {
        const removed = selectedBudgetRows.filter(
          (item) => !rows.includes(item)
        );
        const subtractedAmount = removed.reduce((sum, id) => {
          const itemData = document.state?.lineItemData?.[id] ?? {};
          return sum + itemData[adjustmentKey] || 0;
        }, 0);
        if (subtractedAmount) {
          setContingencyLineItem((prev) => {
            return {
              ...prev,
              adjustmentAmount:
                (prev?.adjustmentAmount || 0) - subtractedAmount,
            };
          });
        }
        onBudgetRowSelect(rows);
      } else if (!rows.length && prevRowCount !== 0) {
        setContingencyLineItem((prev) => {
          return {
            ...prev,
            adjustmentAmount: 0,
          };
        });
        onBudgetRowSelect(rows);
      }

      setPrevRowCount(rows.length);
    },
    [
      document,
      selectedBudgetRows,
      onBudgetRowSelect,
      adjustmentKey,
      prevRowCount,
      showAdjustmentModal,
    ]
  );

  const adjustmentsColumns = React.useMemo(
    () =>
      budgetAdjustmentsColumns(
        document,
        budget,
        handleLineUpdate,
        selectedBudgetRows,
        // Block edits if its a child/attached doc to a parent/PA finDoc
        !!(!viewMode && !document?.documentRelationships?.parent),
        filterColumns,
        null,
        parentDocument?.docType,
        project,
        setSelectedLineItemForComments,
        history
      ),
    [
      budget,
      document,
      filterColumns,
      handleLineUpdate,
      parentDocument?.docType,
      project,
      selectedBudgetRows,
      viewMode,
      history,
    ]
  );

  const contingencyItemColumns = React.useMemo(
    () => contingencyColumns(document, budget),
    [document, budget]
  );

  useEffect(() => {
    let hasPA = false;
    let hasPaTotal = false;
    let hasCA = false;
    let hasCaTotal = false;
    let hasCO = false;
    let hasCoTotal = false;
    const filterList = [];

    if (document?.workingLineItems) {
      document.workingLineItems.forEach((doc) => {
        if (!hasPA && doc?.purchaseAuthorization?.length) {
          hasPA = true;
        }
        if (!hasPaTotal && doc?.paTotal) {
          hasPaTotal = true;
        }
        if (!hasCA && doc?.contingencyAuthorization?.length) {
          hasCA = true;
        }
        if (!hasCaTotal && doc?.caTotal) {
          hasCaTotal = true;
        }
        if (!hasCO && doc?.changeOrder?.length) {
          hasCO = true;
        }
        if (!hasCoTotal && doc?.coTotal) {
          hasCoTotal = true;
        }
      });
    }

    if (!hasPA) filterList.push("purchaseAuthorization");
    if (!hasPaTotal) filterList.push("paTotal");
    if (!hasCA) filterList.push("contingencyAuthorization");
    if (!hasCaTotal) filterList.push("caTotal");
    if (!hasCO) filterList.push("contingencyAuthorization");
    if (!hasCoTotal) filterList.push("coTotal");
    if (document.docType === PURCHASE_AUTHORIZATION) {
      filterList.push("assigned", "coAssignment", "caAssignment");
    }

    setFilterColumns(filterList);
  }, [document?.workingLineItems, document?.docType]);

  const handleSaveSov = (rowId, lines) => {
    const state = document.state ?? {};
    const sov = { ...(state.sov ?? {}) };
    sov[rowId] = lines;

    finishButtonHandler(true, {
      ...document,
      state: { ...state, sov },
    });
  };

  const feeType = React.useMemo(() => {
    return project?.contractualFee?.feeType ?? "";
  }, [project?.contractualFee]);

  const feeAmount = React.useMemo(() => {
    return project?.contractualFee?.amount ?? 0;
  }, [project?.contractualFee]);

  const feeString = React.useMemo(() => {
    if (!feeType) {
      return "";
    }
    return feeType === "$" ? `$${feeAmount}` : `${feeAmount}%`;
  }, [feeType, feeAmount]);

  const insuranceType = React.useMemo(() => {
    return project?.contractualInsurance?.insuranceType ?? "";
  }, [project?.contractualInsurance]);

  const insuranceAmount = React.useMemo(() => {
    return project?.contractualInsurance?.amount ?? 0;
  }, [project?.contractualInsurance]);

  const insuranceString = React.useMemo(() => {
    if (!insuranceType) {
      return "";
    }
    return insuranceType === "$"
      ? `$${insuranceAmount}`
      : `${insuranceAmount}%`;
  }, [insuranceType, insuranceAmount]);

  const getSummaryValues = React.useCallback(
    (docType) => {
      let totalValue;
      let summaryArray = [];
      const checkedRows = docBudgetLines.filter((item) =>
        selectedBudgetRows?.includes(item.id)
      );

      switch (docType) {
        case VENDOR_INVOICE: {
          const committedValues = checkedRows?.reduce((sum, row) => {
            return sum + Number(row.committedValues || 0);
          }, 0);
          const workCompletedToDate = checkedRows?.reduce((sum, row) => {
            return sum + Number(row.workCompletedToDate || 0);
          }, 0);
          const retainage = checkedRows?.reduce((sum, row) => {
            if (Number(row.retainage || 0) === -1) {
              return "N/A";
            }

            if (sum === "N/A") {
              return sum;
            }
            return sum + Number(row.retainage || 0);
          }, 0);
          const holdback = checkedRows?.reduce((sum, row) => {
            return sum + Number(row.holdback || 0);
          }, 0);
          const workCompletedToDateLessRetainageAndHoldback =
            checkedRows?.reduce((sum, row) => {
              return (
                sum +
                Number(row.workCompletedToDateLessRetainageAndHoldback || 0)
              );
            }, 0);
          const previousPaymentDue = checkedRows?.reduce((sum, row) => {
            return sum + Number(row.previousPaymentDue || 0);
          }, 0);
          const currentPaymentDue = checkedRows?.reduce((sum, row) => {
            return sum + Number(row.currentPaymentDue || 0);
          }, 0);
          const balanceToFinish = checkedRows?.reduce((sum, row) => {
            return sum + Number(row.balanceToFinish || 0);
          }, 0);

          summaryArray = [
            {
              key: "Committed Values",
              value: formatWithCommasWithoutDecimal(committedValues),
              unit: "$",
            },
            {
              key: "Work Completed To Date",
              value: formatWithCommasWithoutDecimal(workCompletedToDate),
              unit: "$",
            },
            {
              key: "Retainage",
              value:
                retainage === "N/A"
                  ? retainage
                  : formatWithCommasWithoutDecimal(retainage),
              unit: "$",
            },
            {
              key: "Holdback",
              value: formatWithCommasWithoutDecimal(holdback),
              unit: "$",
            },
            {
              key: "Work Completed to Date Less Retainage and Holdback",
              value: formatWithCommasWithoutDecimal(
                workCompletedToDateLessRetainageAndHoldback
              ),
              unit: "$",
            },
            {
              key: "Previous payment due",
              value: formatWithCommasWithoutDecimal(previousPaymentDue),
              unit: "$",
            },
            {
              key: "Current Amount Due",
              value: formatWithCommasWithoutDecimal(currentPaymentDue),
              unit: "$",
            },
            {
              key: "Balance to Finish including Holdback and Retainage",
              value: formatWithCommasWithoutDecimal(balanceToFinish),
              unit: "$",
            },
          ];
          break;
        }
        case CHANGE_ORDER:
          totalValue = checkedRows?.reduce((sum, row) => {
            return sum + Number(row.coTotalPending || 0);
          }, 0);
          summaryArray = checkedRows
            ?.map((line) => {
              const csiLbl =
                csiCodes.find((code) => {
                  const lcode = line.csiCode?.split(" -")?.[0];
                  return code.value === lcode;
                })?.label ?? "";
              return { ...line, csiLbl };
            })
            .map((line) => {
              return {
                id: line.id,
                key: `${line.csiLbl}${
                  line.vendorName ? ` - ${line.vendorName}` : ""
                }`,
                value: formatWithCommasWithoutDecimal(line.coTotalPending || 0),
                unit: "$",
              };
            });
          break;
        case CONTINGENCY_AUTHORIZATION:
          totalValue = checkedRows?.reduce((sum, row) => {
            return sum + Number(row.caAdjustment || 0);
          }, 0);
          summaryArray = checkedRows
            ?.map((line) => {
              const csiLbl =
                csiCodes.find((code) => {
                  const lcode = line.csiCode?.split(" -")?.[0];
                  return code.value === lcode;
                })?.label ?? "";
              return { ...line, csiLbl };
            })
            .map((line) => {
              return {
                id: line.id,
                key: `${line.csiLbl}${
                  line.vendorName ? ` - ${line.vendorName}` : ""
                }`,
                value: formatWithCommasWithoutDecimal(line.caAdjustment || 0),
                unit: "$",
              };
            });
          summaryArray.push({
            key: "CA TOTAL",
            value: formatWithCommasWithoutDecimal(totalValue),
            isFooter: true,
            unit: "$",
          });
          break;
        case PURCHASE_ORDER:
          totalValue = checkedRows?.reduce((sum, row) => {
            if (
              budget?.budgetType === BUDGET_FIXED_FIRM &&
              budget?.isLockFixedFirm
            ) {
              return sum + Number(row.poTotalPending || 0);
            }
            return (
              sum +
              Number(row.paTotal || 0) +
              Number(row.caTotal || 0) +
              Number(row.coTotal || 0)
            );
          }, 0);
          summaryArray = checkedRows
            ?.map((line) => {
              const csiLbl =
                csiCodes.find((code) => {
                  const lcode = line.csiCode?.split(" -")?.[0];
                  return code.value === lcode;
                })?.label ?? "";
              return { ...line, csiLbl };
            })
            .map((line) => {
              const sum =
                budget?.budgetType === BUDGET_FIXED_FIRM &&
                budget?.isLockFixedFirm
                  ? Number(line.poTotalPending || 0)
                  : Number(line.paTotal || 0) +
                    Number(line.caTotal || 0) +
                    Number(line.coTotal || 0);
              return {
                key: `${line.csiLbl}${
                  line.vendorName ? ` - ${line.vendorName}` : ""
                }`,
                value: formatWithCommasWithoutDecimal(sum),
                unit: "$",
              };
            });
          summaryArray.push({
            key: "PO TOTAL",
            value: formatWithCommasWithoutDecimal(totalValue),
            isFooter: true,
            unit: "$",
          });
          break;
        case PURCHASE_AUTHORIZATION:
          totalValue = checkedRows?.reduce((sum, row) => {
            return sum + Number(row.paAmount || 0);
          }, 0);
          summaryArray = checkedRows
            ?.map((line) => {
              const csiLbl =
                csiCodes.find((code) => {
                  const lcode = line.csiCode?.split(" -")?.[0];
                  return code.value === lcode;
                })?.label ?? "";
              return { ...line, csiLbl };
            })
            .map((line) => {
              return {
                id: line.id,
                key: `${line.csiLbl}${
                  line.vendorName ? ` - ${line.vendorName}` : ""
                }`,
                value: formatWithCommasWithoutDecimal(line.paAmount || 0),
                unit: "$",
              };
            });
          summaryArray.push({
            key: "PA TOTAL",
            value: formatWithCommasWithoutDecimal(totalValue),
            isFooter: true,
            unit: "$",
          });
          break;
        default:
          totalValue = 0;
          summaryArray = [];
          break;
      }

      const calculatedFee =
        feeType === "%" ? totalValue * (feeAmount / 100) : feeAmount;
      const calculatedInsurance =
        insuranceType === "%"
          ? totalValue * (insuranceAmount / 100)
          : insuranceAmount;

      let summaryValues = [...summaryArray];
      if (
        ![PURCHASE_ORDER, PURCHASE_AUTHORIZATION, VENDOR_INVOICE].includes(
          docType
        )
      ) {
        summaryValues = [
          ...summaryValues,
          ...(docType !== CONTINGENCY_AUTHORIZATION
            ? [
                {
                  key: "Subtotal",
                  value: formatWithCommasWithoutDecimal(totalValue),
                  isFooter: docType === CHANGE_ORDER,
                  unit: "$",
                },
              ]
            : []),
          {
            key: `Fee (${feeString})`,
            value: formatWithCommasWithoutDecimal(calculatedFee),
            unit: feeType,
          },
          {
            key: `Insurance (${insuranceString})`,
            value: formatWithCommasWithoutDecimal(calculatedInsurance),
            unit: insuranceType,
          },
          {
            key: "Total",
            value: formatWithCommasWithoutDecimal(
              totalValue + calculatedFee + calculatedInsurance
            ),
            isFooter: docType === CONTINGENCY_AUTHORIZATION,
            unit: "$",
          },
        ];
      }

      const result = summaryValues.length < 2 ? [] : summaryValues;
      return result;
    },
    [
      docBudgetLines,
      feeType,
      feeAmount,
      insuranceType,
      insuranceAmount,
      selectedBudgetRows,
      csiCodes,
      budget?.budgetType,
      budget?.isLockFixedFirm,
      feeString,
      insuranceString,
    ]
  );

  const handleWorkflowClick = () => {
    // @TODO: see if we want to show WF in modal or routing to WF is fine
    // setWorkflowModal(true);
    const path = getSingleResourcePath(`Workflow/${workflow.id}`);
    history.push(path);
  };

  return (
    <div className="p-8 border">
      <div className="flex justify-between items-center cursor-pointer">
        {document.docType !== VENDOR_INVOICE && (
          <div className="text-2xl">{document.name || document.customName}</div>
        )}
        <button
          type="button"
          className="flex items-center self-end text-brandGreen hidden"
          onClick={previewDocHandler}
        >
          <img className="w-3 mr-2" src={fileIcon} alt="preview form" />
          <div className="text-lg">Preview PDF</div>
        </button>
      </div>

      {workflow && document.docType !== VENDOR_INVOICE && (
        <>
          <button
            type="button"
            className="mt-4"
            onClick={handleWorkflowClick}
            aria-label="workflow"
          >
            <Pill
              className="text-white bg-brandGreen cursor-pointer"
              value={`In Workflow ${workflow.name}`}
            />
          </button>

          <AttachItemModal
            content={<WorkflowView workflowId={workflow.id} />}
            onRequestModalClose={() => setWorkflowModal(false)}
            isOpen={workflowModal}
            showBackButton
            disableClose
          />
        </>
      )}

      {document.contentReference ? (
        <CustomDocumentReview {...args} />
      ) : (
        docTypes[document.docType]
      )}

      {!document.contentReference &&
        document.docType &&
        ![REQUEST_FOR_INFORMATION, REQUEST_FOR_PROPOSAL].includes(
          document.docType
        ) && (
          <Summary
            className="mt-8 mb-12 w-1/2"
            keyClassName="w-3/4"
            title={
              document.docType === VENDOR_INVOICE
                ? "Executive Summary"
                : "Summary Of Cost"
            }
            values={getSummaryValues(document.docType)}
          />
        )}

      {document.docType === CONTINGENCY_AUTHORIZATION && (
        <>
          <SecondaryHeader className="mt-6 mb-6">
            Contingency Reference
          </SecondaryHeader>
          <Table
            className=""
            resourceName="budget-items"
            data={contingencyLineItem?.csiCode ? [contingencyLineItem] : []}
            columns={contingencyItemColumns}
            cellStyling="flex items-center h-16"
            cellTextStyling="w-full pb-1"
            deleteButtonStyle={deleteButtonStyle}
            showHeader={false}
            hideCreateNewButton
            hideSiteHeader
            hideChangeView
            hideSaveButton
            disableHover
          />
        </>
      )}

      {!document.contentReference &&
        document.docType !== REQUEST_FOR_INFORMATION && (
          <>
            {document.docType !== VENDOR_INVOICE && (
              <SecondaryHeader className="mt-6">
                Budget References
              </SecondaryHeader>
            )}
            <Table
              className=""
              resourceName="budget-items"
              data={docBudgetLines}
              columns={adjustmentsColumns}
              cellStyling="flex items-center h-16"
              cellTextStyling="w-full pb-1"
              deleteButtonStyle={deleteButtonStyle}
              initialSelectedRows={selectedBudgetRows}
              onSelectedRowChange={editMode ? handleBudgetRowSelect : () => {}}
              onDeleteRowClick={handleRemoveRows}
              onEditRowCancelClick={() => {}}
              onEditSaveClick={() => {}}
              inLineForm={({ original }, isEdit, togglePopOver) => (
                <div className="flex items-center mx-4 mb-8">
                  <ScheduleOfValues
                    row={original}
                    onSaveSov={handleSaveSov}
                    onTogglePopover={togglePopOver}
                    isEdit={isEdit}
                    budget={budget}
                    parentDocType={parentDocument?.docType}
                    isVendorInvoice={document.docType === VENDOR_INVOICE}
                    disbaleEditing={
                      !["open", "requestResubmission"].includes(document.status)
                    }
                  />
                </div>
              )}
              allowEditingOnRowClick={
                document?.docType === PURCHASE_ORDER ||
                document?.docType === VENDOR_INVOICE
              }
              enableDeleting={
                createMode ||
                (editMode && !document?.documentRelationships?.parent)
              }
              enablePopOverEditing
              fixedSelection={viewMode}
              noDeleteConfirm
              hideCreateNewButton
              hideEditRowButton
              hideSiteHeader
              hideChangeView
              hideSaveButton
              disableHover
              showFooter
            />
            {(createMode ||
              (editMode && !document?.documentRelationships?.parent)) && (
              <div className="flex">
                <TertiaryButton
                  title="+ Add More"
                  onClick={handleShowRowsModal}
                />
              </div>
            )}
          </>
        )}

      <AttachItemModal
        primaryText="Finish"
        content={
          <>
            <DocumentAdjustments
              document={document}
              dispatch={handleAdjustmentInput}
              property={property}
              project={project}
              budget={budget}
              budgetId={budget?.id}
              selectedBudgetRows={selectedBudgetRows}
              onBudgetRowSelect={onBudgetRowSelect}
              originalLineItems={originalLineItems}
              csiCodes={csiCodes}
              modalView
            />
          </>
        }
        onRequestModalClose={() => setShowAdjustmentModal(false)}
        onPrimaryClick={() => setShowAdjustmentModal(false)}
        isOpen={showAdjustmentModal}
        disablePrimary={adjustmentButtonDisabled}
      />

      {showRowsModal && (
        <AttachItemModal
          content={
            <>
              <div
                className={
                  isLoading
                    ? "flex justify-center items-center w-full h-full"
                    : "hidden"
                }
              >
                <Spinner notFullScreen />
              </div>
              <div className={isLoading ? "hidden" : ""}>
                <BudgetTable
                  budgetId={budget.id}
                  projectId={project.id}
                  projectData={project}
                  propertyData={property}
                  onRowSelect={setSelectedRowsToAdd}
                  setIsLoading={setIsLoading}
                  documentModalView
                  hideSiteHeader
                  tableMiniMapContainerId={MINI_MAP_SCROLL_CONTAINER_ID}
                  disableRowsWithRateAndNoUncommitedValuePending={
                    document.docType === PURCHASE_AUTHORIZATION
                  }
                  disableContingencyLineitem={
                    document.docType === CONTINGENCY_AUTHORIZATION
                  }
                  disableSelectAllCheckbox={
                    document.docType === CONTINGENCY_AUTHORIZATION
                  }
                  disbaleRowActions
                  viaChangeOrder={document.docType === CHANGE_ORDER}
                />
              </div>
            </>
          }
          onRequestModalClose={handleCloseRowsModal}
          onPrimaryClick={handleAddRows}
          isOpen={showRowsModal}
          tableMiniMapContainerId={MINI_MAP_SCROLL_CONTAINER_ID}
        />
      )}

      {!document.contentReference && document.docType !== VENDOR_INVOICE && (
        <>
          <SecondaryHeader className="mt-6">Attachments</SecondaryHeader>
          <DocumentTable
            resourceName="view-document-attachments-table"
            documents={document.attachments?.map((doc) => {
              const val = {
                ...doc,
                resource: doc.name,
                docType: getDocType(doc.docType),
                createdBy: getDocumentUser(doc.creator),
                modifiedBy: getDocumentUser(doc.modifier),
                modifiedDate: doc.metadata?.lastUpdated
                  ? moment(doc.metadata.lastUpdated).format("MM/DD/YYYY")
                  : "--",
              };

              delete val.id;
              return val;
            })}
            favorites={favorites}
            onRowClick={handleShowAttachment}
            onFileDelete={handleShowDelete}
            onFavoriteClick={handleFavoriteClick}
            allowSelection={false}
            enableDeleting={!viewMode}
            noDeleteConfirm
            hideSiteHeader
            hideChangeView
            hideSaveButton
            hideButtons
            disableHover
            docTypeOptionsMap={docTypeOptionsMap}
          />
          {(createMode || editMode) && (
            <TertiaryButton
              className="mt-4"
              title="+ Add More"
              onClick={attachmentButtonClickedHandler}
            />
          )}
        </>
      )}
      {(createMode || editMode || (viewMode && !hideFloatingActionButtons)) && (
        <div className="relative right-0 bottom-0 flex justify-end mt-20">
          <div
            className="flex w-fit py-4 px-4 border-gray-100 rounded-lg bg-white"
            style={{
              borderWidth: "3px",
              bottom: "75px",
              right: "32px",
              zIndex: "20",
            }}
          >
            {createMode && (
              <div className="flex justify-end">
                <TertiaryButton
                  className="mr-2"
                  title="Discard"
                  onClick={discardButtonHandler}
                />
                <PrimaryButton
                  title="Finish"
                  disabled={!handledAdjustments}
                  onClick={() => finishButtonHandler()}
                  isLoading={isSaving}
                />
              </div>
            )}

            {editMode && (
              <div className="flex justify-end">
                <TertiaryButton
                  className="mr-2"
                  title="Cancel"
                  onClick={handleCancelClick}
                />
                <SecondaryButton
                  className="mr-2"
                  title="Delete"
                  onClick={deleteButtonHandler}
                />
                <PrimaryButton
                  className={editMode ? "" : "hidden"}
                  title="Save Changes"
                  onClick={() => {
                    if (document.contentReference) {
                      finishButtonHandler(true);
                    } else {
                      setShowUpdateModal(true);
                    }
                  }}
                  isLoading={isSaving}
                />
              </div>
            )}

            {viewMode && !hideFloatingActionButtons && (
              <div className="flex justify-end">
                <TertiaryButton
                  className="mr-2"
                  title="Cancel"
                  onClick={handleCancel}
                />
                <SecondaryButton
                  className={editMode ? "mr-6" : ""}
                  title="Delete"
                  onClick={deleteButtonHandler}
                />
              </div>
            )}
          </div>
        </div>
      )}

      <Modal
        title={
          document
            ? `Update ${
                document.filename || document.customName || document.name
              }?`
            : "Update Document?"
        }
        isOpen={showUpdateModal}
        primaryButtonTitle="Yes, update"
        primaryButtonOnClick={() => finishButtonHandler(true)}
        disabled={isSaving}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={() => setShowUpdateModal(false)}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <>
          <p className="text-base mb-2">
            By changing this financial document you may lose existing associated
            documents, are you sure to want to update{" "}
            {document
              ? document.filename || document.customName || document.name
              : "this document"}
            ?
          </p>
        </>
      </Modal>
      <Modal
        title={
          document
            ? `Delete ${
                document.filename || document.customName || document.name
              }?`
            : "Delete Document?"
        }
        isOpen={showDeleteModal}
        primaryButtonTitle="Yes, delete"
        primaryButtonOnClick={handleDeleteDocument}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={() => setShowDeleteModal(false)}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <>
          <p className="text-base mb-2">
            Are you sure you want to delete{" "}
            {document
              ? document.filename || document.customName || document.name
              : "this document"}
            ? Once deleted, it cannot be recovered.
          </p>
        </>
      </Modal>

      <Modal
        title={
          deleteDoc?.name ? `Delete ${deleteDoc.name}?` : "Delete Document?"
        }
        isOpen={showDeleteDocModal}
        primaryButtonTitle="Yes, delete"
        primaryButtonOnClick={handleDeleteDocument}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={handleHideDelete}
        deleting={isDeletingDoc}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <>
          <p className="text-base mb-2">
            Are you sure you want to delete {deleteDoc?.name ?? "this document"}
            ? Once deleted, it cannot be recovered.
          </p>
        </>
      </Modal>

      <ModalConfirmAll
        navigateToPath={handleCancelConfirm()}
        showConfirmModal={showConfirm}
        setShowConfirmModal={setShowConfirm}
        modalAction={createMode ? "Discard Attachments" : "Cancel"}
      />

      <AttachItemModal
        content={<CustomDocumentReview document={currentAttachment} />}
        contentReference={currentAttachment.contentReference}
        onRequestModalClose={handleHideAttachment}
        isOpen={showAttachmentModal}
      />

      <BudgetTableComments
        currentUser={currentUser}
        isOpen={!!selectedLineItemForComments}
        onRequestModalClose={() => setSelectedLineItemForComments(null)}
        commentsData={commentsData}
        handlePostComment={handlePostComment}
        handlePostReply={handlePostReply}
        isLoadingComments={isLoadingComments}
      />

      <div className="hidden">
        <DocumentUploadForm
          onAddFile={(fileRef, file) => handleFileAdded(fileRef, file, true)}
          onAddPhoto={(fileRef, file) => handleFileAdded(fileRef, file, true)}
          onAddVideo={(fileRef, file) => handleFileAdded(fileRef, file, true)}
          showCorner
        />
      </div>
    </div>
  );
};

DocumentViewForm.propTypes = {
  /**
   * create document form state
   */
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.object,
  /**
   * existing document data
   */
  // eslint-disable-next-line react/forbid-prop-types
  existingDoc: PropTypes.object,
  /**
   * create document form dispatcher
   */
  dispatch: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  budget: PropTypes.object,
  /**
   * list of users
   */
  // eslint-disable-next-line react/forbid-prop-types
  userList: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  property: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.object,
  // budgetId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  associatedResource: PropTypes.object,
  onSelectAssociatedResource: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  docTypeOptionsMap: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  selectedBudgetRows: PropTypes.array,
  onBudgetRowSelect: PropTypes.func,
  //
  disableAssociation: PropTypes.bool,
  disableEdit: PropTypes.bool,
  editMode: PropTypes.bool,
  createMode: PropTypes.bool,
  viewMode: PropTypes.bool,
  //
  // eslint-disable-next-line react/forbid-prop-types
  originalLineItems: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  csiCodes: PropTypes.array,
  onCancel: PropTypes.func,
  hideFloatingActionButtons: PropTypes.bool,
  documentsDict: PropTypes.shape({}),
  // eslint-disable-next-line react/forbid-prop-types
  allDocuments: PropTypes.arrayOf(PropTypes.object),
  addDocument: PropTypes.func,
  addDocuments: PropTypes.func,
  removeDocument: PropTypes.func,
  reload: PropTypes.func,
};

DocumentViewForm.defaultProps = {
  document: {},
  existingDoc: null,
  dispatch: undefined,
  budget: {},
  userList: [],
  property: {},
  project: {},
  associatedResource: undefined,
  onSelectAssociatedResource: undefined,
  docTypeOptionsMap: {},
  selectedBudgetRows: [],
  onBudgetRowSelect: () => {},
  disableAssociation: false,
  disableEdit: false,
  createMode: false,
  viewMode: false,
  editMode: false,
  csiCodes: [],
  originalLineItems: [],
  onCancel: undefined,
  hideFloatingActionButtons: false,
  documentsDict: undefined,
  allDocuments: [],
  addDocument: undefined,
  addDocuments: undefined,
  removeDocument: undefined,
  reload: undefined,
};

export default DocumentViewForm;

import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { useESChatContext } from "../../../../context/ChatContext/ESChatContext";
import { ADD_OPEN_MODAL, CONFIRM_MODAL } from "../../../../constants";
import { useModalState } from "../../../../state/modalState";

export default () => {
  const { setViewMedia, selectedChannel, replyAttachments } =
    useESChatContext();
  const [, modalDispatch] = useModalState();

  // Parse Attachment
  const parse = (attach) => {
    const type = attach?.mime_type ?? attach?.type;
    const url = attach?.image_url ?? attach?.asset_url;
    const title = attach?.name ?? attach?.title;
    const originalFile = attach?.originalFile;
    return { type, url, title, originalFile };
  };

  // Attachments
  const mediaProps = useMemo(() => {
    if (selectedChannel?.state?.messages) {
      // Main Thread Attachments
      const main = selectedChannel?.state?.messages.reduce((list, item) => {
        const newList = list.concat(item?.attachments?.map(parse));
        return newList;
      }, []);

      // Reply Thread(s) attachments from context
      const reply = Object.values(replyAttachments?.current).reduce(
        (list, item) => list.concat(item?.map(parse)),
        []
      );
      return [...main, ...reply];
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    replyAttachments?.current,
    selectedChannel?.state?.messages,
    replyAttachments,
  ]);

  // Sort by item clicked
  const handleClick = (event, item) => {
    const url = item?.asset_url || item?.image_url;
    const sortedMedia = mediaProps.sort((a) => {
      if (a?.url === url) {
        return -1;
      }
      return 0;
    });
    if (event.detail === 1 && event.type === "click") {
      setViewMedia(sortedMedia);
    }
  };

  const downloadAttachment = (attachment) => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      modalType: CONFIRM_MODAL,
      ref: { id: `${uuidv4()}-confirm-modify` },
      modalData: {
        item: {
          prompt: `Do you want to save? \n ${attachment.title}`,
          confirm: "Yes",
          cancel: "No",
          title: "Download Attachment",
          onConfirm: () => window.open(attachment?.asset_url),
        },
      },
      position: { x: 0, y: 0 },
    });
  };
  return { handleClick, downloadAttachment };
};

import React from "react";
import PropTypes from "prop-types";
import CircularProgressBar from "../CircularProgressBar/CircularProgressBar";
import {
  formatNumber,
  formatWithCommasWithoutDecimal,
} from "../../../helpers/Formatters";
import { computeAdjustedTotal } from "../../../helpers/Budget";

const displayValue = (rows, id) => {
  let rv = "";
  let unit;
  let showPercentage;
  let hideIcons;
  switch (id) {
    case "budgetPercentage": {
      const totalActualCost = rows.reduce(
        (sum, n) => sum + formatNumber(n.original.actualCost),
        0
      );

      const liveBudget = rows.reduce(
        (sum, n) => sum + formatNumber(n.original.liveBudget),
        0
      );
      rv = liveBudget ? ((totalActualCost / liveBudget) * 100).toFixed(2) : 0;
      showPercentage = true;

      break;
    }
    case "projectPercentage": {
      const total = rows.reduce(
        (sum, n) => sum + formatNumber(n.original[id]),
        0
      );
      rv = rows.length ? ((total / rows.length) * 100).toFixed(2) : 0;
      showPercentage = true;

      break;
    }
    case "varianceInDollars": {
      const getRowVariance = (row) => {
        const adjustedTotal = computeAdjustedTotal(
          row.costPerUnit * (row.quantity || 0),
          row?.adjustment?.arithmeticUnit,
          row?.adjustment?.adjustmentUnit,
          row?.adjustment?.amount
        );

        const variance = adjustedTotal - parseFloat(row.actualCost);
        return variance;
      };
      const total = rows.reduce(
        (sum, n) => sum + getRowVariance(n.original),
        0
      );
      rv = formatWithCommasWithoutDecimal(total);
      unit = "$";
      break;
    }
    case "varianceInPercentage": {
      const totalAdjustedTotal = rows.reduce(
        (sum, row) =>
          sum +
          computeAdjustedTotal(
            row.original.costPerUnit * (row.original.quantity || 0),
            row.original.arithmeticUnit,
            row.original.adjustmentUnit,
            row.original.adjustment
          ),
        0
      );
      const totalActualCost = rows.reduce(
        (sum, n) => sum + n.original.actualCost,
        0
      );
      rv =
        totalAdjustedTotal && totalActualCost
          ? ((totalActualCost / totalAdjustedTotal) * 100).toFixed(2)
          : 0;
      showPercentage = true;
      break;
    }
    case "purchaseVariancePercentage": {
      const total = rows.reduce(
        (sum, n) => sum + formatNumber(n.original[id]),
        0
      );
      rv = rows.length ? ((total / rows.length) * 100).toFixed(2) : 0;
      showPercentage = true;
      hideIcons = true;

      break;
    }
    case "percentCompleteOfCommittedValue": {
      const total = rows.reduce(
        (sum, n) => sum + formatNumber(n.original[id]),
        0
      );
      rv = rows.length ? ((total / rows.length) * 100).toFixed(2) : 0;
      showPercentage = true;
      hideIcons = true;

      break;
    }
    case "percentCompleteOfBudget": {
      const total = rows.reduce(
        (sum, n) => sum + formatNumber(n.original[id]),
        0
      );
      rv = rows.length ? ((total / rows.length) * 100).toFixed(2) : 0;
      showPercentage = true;

      break;
    }
    case "schedulePercentage": {
      const total = rows.reduce(
        (sum, n) => sum + formatNumber(n.original[id]),
        0
      );
      rv = rows.length ? ((total / rows.length) * 100).toFixed(2) : 0;
      showPercentage = true;
      hideIcons = true;

      break;
    }
    default: {
      const total = rows.reduce(
        (sum, n) => sum + formatNumber(n.original[id]),
        0
      );
      rv = formatWithCommasWithoutDecimal(total);
      unit = "$";
    }
  }

  return { rv, unit, showPercentage, hideIcons };
};

const ColumnFooter = ({ columnProps, className }) => {
  const { groupedFlatRows } = columnProps;
  const {
    column: { id },
  } = columnProps;

  const rows = groupedFlatRows.filter(
    (row) => !row.isGrouped && !row?.original?.parentIdOfVersion
  );
  const { rv, unit, showPercentage, hideIcons } = displayValue(rows, id);

  return (
    <>
      {showPercentage ? (
        <CircularProgressBar value={rv} hideIcons={!!hideIcons} />
      ) : (
        <div className={`flex text-sm ${className}`}>
          <div className="text-gray-200 mr-2">{unit && <p>{unit}</p>}</div>
          <p>{rv}</p>
        </div>
      )}
    </>
  );
};

ColumnFooter.propTypes = {
  /**
   * react table column props object
   */
  // eslint-disable-next-line react/forbid-prop-types
  columnProps: PropTypes.object,
  className: PropTypes.string,
};

ColumnFooter.defaultProps = {
  columnProps: undefined,
  className: undefined,
};

export default ColumnFooter;

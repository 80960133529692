import moment from "moment";

export const HightchartOptions = (Highchart) => {
  // colors from tailwind.config
  const gray200 = "#A6A6A6";
  const gray400 = "#636363";
  const gray700 = "#191919";

  // common styling for reuse
  const labelStyle = {
    fontSize: 14,
    lineHeight: 18,
    color: gray400,
  };
  const titleStyle = {
    fontSize: 16,
    lineHeight: 19,
    color: gray700,
  };

  Highchart.setOptions({
    chart: {
      backgroundColor: "transparent",
      spacing: [0, 0, 0, 0],
      style: {
        fontFamily: "Montserrat",
      },
    },
    colors: ["#CAD163", "#92B9E0", "#F0E2A4", "#E89F80", "#AF64A6", "#E4E4E4"],
    credits: { enabled: false },
    legend: {
      align: "right",
      alignColumns: false,
      // itemDistance needs to include width of symbol
      // (currently 16px width plus 12px margin)
      itemDistance: 28,
      itemHiddenStyle: {
        color: gray200,
      },
      itemHoverStyle: {
        color: gray400,
      },
      itemStyle: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 18,
        color: gray700,
      },
      padding: 0,
      verticalAlign: "top",
      x: -12,
    },
    title: {
      text: "",
    },
    xAxis: {
      labels: {
        style: labelStyle,
      },
      lineColor: gray400,
      tickColor: "transparent",
      title: {
        margin: 12,
        style: titleStyle,
      },
    },
    yAxis: {
      gridLineColor: gray400,
      labels: {
        style: labelStyle,
      },
      lineColor: gray400,
      lineWidth: 1,
      title: {
        margin: 24,
        style: titleStyle,
      },
    },
  });
};

// filters a dataset based on passed-in dates
// and returns as x and y coordinates
export const formatHighchartsData = (startDate, endDate, dataset) => {
  return dataset.reduce((filteredArray, data) => {
    const date = moment(data.date);
    if (
      (date.isSameOrAfter(startDate, "day") &&
        date.isSameOrBefore(endDate, "day")) ||
      (!startDate && !endDate) ||
      (!startDate && date.isSameOrBefore(endDate, "day")) ||
      (!endDate && date.isSameOrAfter(startDate, "day"))
    ) {
      return filteredArray.concat({
        x: date.valueOf(),
        y: data.value,
      });
    }
    return filteredArray;
  }, []);
};

// this takes in a (mocked) data array and sorts the values so they're in descending order by date
export const devOnlySortValue = (originalData, trimAfterToday) => {
  const sorted = [...originalData].sort((a, b) => {
    return b.value - a.value;
  });

  const filteredData = trimAfterToday
    ? originalData.filter((data) =>
        moment(data.date).isSameOrBefore(moment(), "day")
      )
    : originalData;

  return filteredData.map((data, index) => {
    return {
      ...data,
      value: sorted[index].value,
    };
  });
};

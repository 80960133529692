import { useMutation, useQueryClient } from "react-query";
import { AssetAPI } from "@griffingroupglobal/eslib-api";
import assetKeys from "./assetsKeys";
import mutateAssetCache from "../../helpers/Assets/mutateAssetCache";
import { toastError, toastMessage } from "../../stories/Components/Toast/Toast";
import { userKeys } from "../../config/reactQuery/queryKeyFactory";

/**
 * Mutation hook to patch a single asset
 */
const useAssetsPatch = () => {
  const queryClient = useQueryClient();

  /**
   * Function to patch a asset in backend
   * @param {Object} data - single object containing old asset and updated asset data
   * @param {Object} data.updatedAsset - New asset data
   * @param {Object} data.asset - Old asset data
   */
  const patchAsset = async ({ updatedAsset, asset }) => {
    const { data } = await AssetAPI.patch(asset.id, updatedAsset, asset);

    return data;
  };

  return useMutation({
    mutationFn: patchAsset,
    onMutate: async ({ updatedAsset, asset }) => {
      const queryKey = assetKeys.byId(asset.id);

      // Save reference of all query data that matchs the query key ["assets"]
      // This is going to be use in case of a possible error.
      const previousAllAssets = queryClient.getQueriesData(assetKeys.assets);

      // Cancel any query for assets in the meantime
      await queryClient.cancelQueries(queryKey);

      // Update asset in all possible queries that matches the queryKey ["assets"]
      if (previousAllAssets?.length) {
        mutateAssetCache(queryClient, asset.id, updatedAsset);
      }

      // Return a context that can be accessed from callbacks below
      return { previousAllAssets };
    },
    onSuccess: (data, variables) => {
      const { id } = variables ?? {};
      mutateAssetCache(queryClient, id, data);

      toastMessage(`Asset was updated successfully`);
    },
    onError: (error, _, context) => {
      const { previousAllAssets } = context ?? {};

      // Rollback asset to previous state in all possible queries
      previousAllAssets.forEach(([key, value]) => {
        queryClient.setQueryData(key, value);
      });

      toastError("Asset could not be updated. Please try again");
      console.error("useAssetsPatch", error);
    },
    onSettled: (_, __, variables) => {
      const { asset, updatedAsset } = variables;
      queryClient.invalidateQueries(assetKeys.assets);

      if (
        JSON.stringify(updatedAsset?.ownedBy) !== JSON.stringify(asset?.ownedBy)
      ) {
        // invalidating as new users may have been created in the owned by field
        queryClient.invalidateQueries(userKeys.users);
      }
    },
  });
};

export default useAssetsPatch;

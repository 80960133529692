import { useEffect, useMemo, useState } from "react";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { useCreateTag, useGetTags } from "../../../hooks/useTags";

/**
 * Calculate and return a filtered list of tags that are not already present in the currentTags array.
 * @param {Object[]} tags - The master list of tags.
 * @param {Object[]} currentTags - The tags to filter out from the master list.
 * @returns {Object[]} An array of tags that are not present in the currentTags array.
 */
const calculateTagsOptions = (tags = [], currentTags = []) => {
  return tags.reduce((acc, tag) => {
    const found = currentTags.find((value) => value === tag.reference);

    if (!found) {
      acc.push({
        label: tag.label,
        value: tag.reference,
      });
    }

    return acc;
  }, []);
};

const useTagsSelectorViewData = ({
  tags,
  isEditing,
  onTagAdded,
  onTagRemoved,
  isInline,
}) => {
  const { data: currentUser } = useCurrentUser();

  // Query hook to get all tags
  const { data: tagsData, isLoading } = useGetTags();
  // Query mutation to create tags
  const { mutate } = useCreateTag();

  const [options, setOptions] = useState([]);

  // Boolean flag that check if user can create tags
  const canCreateTags = useMemo(
    () =>
      currentUser?.isAdmin ||
      currentUser?.isSuperAdmin ||
      currentUser?.permissions?.tag?.can_write ||
      false,
    [currentUser]
  );

  const hideTagsDropdown = useMemo(() => !!isEditing, [isEditing]);

  // This effect updates the dropdown options based on all tags and the current tags.
  // It filters out tags that are already selected in the currentTags array
  useEffect(() => {
    if (!(tagsData?.tags && Array.isArray(tagsData.tags))) return;

    const tagsDropdownOptions = calculateTagsOptions(tagsData.tags, tags);

    setOptions(tagsDropdownOptions);
  }, [tagsData?.tags, tags]);

  const handleRemoveTag = (tagRemoved) => {
    onTagRemoved(tagRemoved);
  };

  const handleAddTag = (tagSelected) => {
    if (!tagSelected) return;

    // If tag exist, then the tagReference is sent to the component
    if (tagsData.tagsDict[tagSelected.value]) {
      onTagAdded(tagSelected.value);
    }

    // If tag dens't exist, then it needs to be created on backend.
    // After success, the tagReference is sent to the component
    else {
      const tag = { label: tagSelected.label };
      mutate(tag, {
        onSuccess: ({ reference }) => {
          onTagAdded(reference);
        },
      });
    }
  };

  // eslint-disable-next-line no-nested-ternary
  const tagListStyle = isEditing ? (isInline ? "w-1/2" : "mt-6") : "";

  return {
    isLoading,
    canCreateTags,
    options,
    hideTagsDropdown,
    tagListStyle,
    handleAddTag,
    handleRemoveTag,
  };
};

export default useTagsSelectorViewData;

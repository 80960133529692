import { useEffect, useState } from "react";

export default (localStorageKey, defaultValue) => {
  const [value, setValue] = useState(
    defaultValue || localStorage.getItem(localStorageKey)
  );

  useEffect(() => {
    localStorage.setItem(localStorageKey, value || "");
  }, [localStorageKey, value]);

  return [value, setValue];
};

import { useRef, useState } from "react";
import useAttributes from "../../../../hooks/useAttributes";
import { useAppState } from "../../../../state/appState";
import {
  ADD_ATTRIBUTE_POPUP,
  TOGGLE_POSITIONED_POPUP,
} from "../../../../constants";

const useAttributeModalWidget = ({ dispatch, setNewAttributes, resource }) => {
  const inputRef = useRef(null);
  const [, reloadAttributes, selectedMeasurements] = useAttributes();
  const [showModal, setShowModal] = useState(false);
  const wrapperRef = useRef(null);
  const [, appStateDispatch] = useAppState();

  const handleDeleteElement = (id) => {
    dispatch({
      type: "measurement",
      operation: "remove",
      detail: id,
    });
  };

  const handleAddElements = (elements, attributes) => {
    elements.forEach(({ detail, id, fieldType, data: { value, unit } }) => {
      let finalValue = value;
      if (!finalValue) {
        finalValue = fieldType === "check-box" ? "No" : "";
      }
      const attribute = {
        type: "measurement",
        detail,
        value: finalValue,
        unit,
        id,
      };
      dispatch(attribute);
    });

    setNewAttributes(attributes);
  };

  const handleAddClick = () => {
    appStateDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      position: {
        x:
          window.innerWidth < 1200
            ? window.innerWidth / 5
            : window.innerWidth / 2.5,
        y: window.innerHeight / 5,
      },
      popupData: {
        item: {
          resource,
          reloadAttributes,
          onAddElements: handleAddElements,
          measurements: selectedMeasurements,
        },
      },
      popupType: ADD_ATTRIBUTE_POPUP,
    });
  };

  return {
    reloadAttributes,
    selectedMeasurements,
    inputRef,
    handleDeleteElement,
    handleAddElements,
    handleAddClick,
    setShowModal,
    showModal,
    wrapperRef,
    measurements: selectedMeasurements,
  };
};

export default useAttributeModalWidget;

import { useCallback, useEffect, useState } from "react";
import { UserAPI } from "@griffingroupglobal/eslib-api";

export default () => {
  const [entityContacts, setEntityContacts] = useState();
  const reload = useCallback(async () => {
    const {
      data: { entries: companies },
    } = await UserAPI.get({
      params: { kind: "company" },
    });

    const allCompanies = companies?.map((entity) => ({
      label: entity?.resource?.company?.value,
      value: entity?.resource?.reference,
    }));
    setEntityContacts(allCompanies);
  }, []);

  useEffect(() => {
    if (!entityContacts) {
      reload();
    }
  }, [reload, entityContacts]);

  return [entityContacts, reload];
};

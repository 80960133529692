/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import "./StatelessSearch.css";
import _ from "lodash";
import searchIcon from "../../assets/images/magnoliaSearchIcon.svg";
import clearIcon from "../../assets/images/greyCrossIcon.svg";

/**
 * @param {String} placeholder - String Inside Empty Searchbar
 * @param {Function} onChange - Function That sets state of parent with filtered options returned from search
 * @param {Array} options - Array of objects to perform search on
 * @param {Array} keys - array of keys for nested object elements i.e. ["name.firstName", "id", etc...]
 * @param {String} value - controlled input
 * @returns - Search Bar
 */
const SimpleSearch = ({
  placeholder = "Search [Resource]",
  onChange,
  options,
  keys,
  disabled,
  value,
  forwardedRef,
  className,
  disableClear,
}) => {
  const handleFilter = useCallback(
    (val) => {
      const filteredList = [];
      const exists = [];

      keys.forEach((key) => {
        options.forEach((item) => {
          if (
            _.get(item, key)?.toLowerCase()?.includes(val?.toLowerCase()) &&
            !exists.includes(item.id)
          ) {
            filteredList.push(item);
            exists.push(item.id);
          }
        });
      });
      onChange(filteredList, val);
    },
    [keys, onChange, options]
  );

  const handleInput = useCallback(
    (e) => {
      handleFilter(e.target.value);
    },
    [handleFilter]
  );

  const clearInput = () => {
    onChange(null, "");
  };
  return (
    <div
      className={`relative searchbarCN flex gap-2 rounded-md px-3 py-1 ${className}`}
      aria-label="search-bar"
      role="search"
    >
      <img src={searchIcon} className="w-4 h-4 self-center" alt="search-icon" />
      <input
        className="search-bar flex-1 truncate"
        placeholder={placeholder}
        ref={(el) => (forwardedRef.current = el)}
        value={value}
        onInput={handleInput}
        disabled={disabled}
      />
      {!disabled && !disableClear && (
        <button type="button" onClick={clearInput}>
          <img
            src={clearIcon}
            className="w-2 h-2 self-center"
            alt="search-icon"
          />
        </button>
      )}
    </div>
  );
};

SimpleSearch.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  keys: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  forwardedRef: PropTypes.shape({ current: PropTypes.element }),
  value: PropTypes.string,
  className: PropTypes.string,
  disableClear: PropTypes.bool,
};

SimpleSearch.defaultProps = {
  placeholder: "Search [Resource]",
  onChange: () => {},
  options: [],
  keys: [],
  disabled: true,
  forwardedRef: { current: null },
  value: null,
  className: undefined,
  disableClear: false,
};

export default SimpleSearch;

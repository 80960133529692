/**
 *  Restricted event/task fields to disbale edit functionality
 * @param oldTask - existing event/task resource
 * @param  newTask - modified event/task resource
 * @returns Boolean - if any restricted fields were modified
 */
const hasRecurrenceFieldChanged = (oldResource, newResource) => {
  // compare recurrence
  if (oldResource.recurrence !== newResource.recurrence) {
    return true;
  }

  return false;
};

export default hasRecurrenceFieldChanged;

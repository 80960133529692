import { ConfigurationAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import useTemplatesConfiguration from "./useTemplatesConfiguration.new";
import { configurationKeys } from "../config/reactQuery/queryKeyFactory";

const patchTemplates = async ({
  templatesConfiguration,
  resource,
  updatedTemplates,
}) => {
  const patchBody = {
    ...templatesConfiguration,
    templates: {
      ...templatesConfiguration.templates,
      [resource]: updatedTemplates,
    },
  };

  const { data } = await ConfigurationAPI.patch(
    "templates",
    patchBody,
    templatesConfiguration
  );

  return data;
};

/**
 * Mutation hook to patch templates for given resource
 */
const useTemplatesConfigurationPatch = (mutationKey) => {
  const { data: templatesConfiguration } = useTemplatesConfiguration();

  const queryClient = useQueryClient();
  return useMutation({
    mutationKey,
    mutationFn: ({ resource, updatedTemplates }) =>
      patchTemplates({ templatesConfiguration, resource, updatedTemplates }),
    onSuccess: (templates) => {
      queryClient.setQueryData(configurationKeys("templates"), templates);
    },
    onError: (error) => {
      queryClient.invalidateQueries(configurationKeys("templates"));
      console.error("useTemplatesConfigurationPatch", error);
    },
  });
};

export default useTemplatesConfigurationPatch;

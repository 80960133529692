import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FileAPI } from "@griffingroupglobal/eslib-api";

import Modal from "../Modal/Modal";
import ExpenseDetailsView from "./ExpenseDetailsView";
import ExpenseCreateForm from "./ExpenseCreateForm";
import { customModalStyles } from "../../../constants";

import "./styles.css";
import TableActionsIconsGroup from "../Table/TableActionsIconsGroup";
import { useAppState } from "../../../state/appState";

const ExpenseDetailsModal = ({
  associatedResource,
  expenseData,
  onFinishedEditing,
  onCloseModal,
  csiCodes,
  onDeleteExpense,
  viewModalOpen,
  reload,
  financialsConfiguration,
}) => {
  const { overlayStyle, contentStyle, titleStyle, headerStyle } =
    customModalStyles || {};
  const [{ currentUser }] = useAppState();

  const [isEditing, setIsEditing] = useState(false);
  const [formatedExpense, setFormatedExpense] = useState(expenseData);
  const [showInvoicedInfo, setShowInvoicedInfo] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [deleteExpenseWarning, setDeleteExpenseWarning] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [loadingReceipts, setLoadingReceipts] = useState(false);

  useEffect(() => {
    const fetchReceipt = async () => {
      if (expenseData?.receipts?.length > 0) {
        try {
          setLoadingReceipts(true);
          const tempReceipts = await Promise.allSettled(
            expenseData?.receipts.map(async (receipt) => {
              const { data } = await FileAPI.getById(
                receipt?.ref?.split("/")[1]
              );
              return data;
            })
          );
          const fullReceipts = tempReceipts
            ?.filter((r) => r.status === "fulfilled")
            ?.map((r) => r.value);
          setFormatedExpense((prev) => ({ ...prev, receipts: fullReceipts }));
        } catch (error) {
          console.warn("Error loading receipts");
        }
        setLoadingReceipts(false);
      }
    };

    fetchReceipt();
  }, [
    expenseData?.receipts,
    financialsConfiguration?.financials?.expense?.categories,
  ]);

  const handleEdit = useCallback(() => {
    if (formatedExpense?.isInvoiced) {
      setShowInvoicedInfo(true);
    } else {
      setIsEditing((prev) => !prev);
    }
    setShowInvoicedInfo(false);
  }, [formatedExpense?.isInvoiced]);

  return (
    <div>
      <Modal
        title={`${isEditing ? "Editing expense" : "Expense details"}`}
        isOpen={viewModalOpen}
        onRequestModalClose={() => {
          if (isEditing && hasChanged) {
            setCancel(true);
            setDeleteExpenseWarning(true);
          } else {
            onCloseModal(false);
          }
        }}
        primaryButtonOnClick={() => onCloseModal(false)}
        tertiaryButtonTitle={!isEditing && "Close"}
        shouldCloseOnEsc
        overlayStyle={overlayStyle}
        contentStyle={contentStyle}
        titleStyle={titleStyle}
        headerStyle={headerStyle}
        hideFooter
        modalAction="expense"
        alert
      >
        <>
          <div className="flex justify-between mx-2.5 mt-4 w-192">
            <div className="flex flex-col">
              <p className="text-gray-300 font-bold mb-3">
                This expense is associated with:
              </p>
              <p className="text-gray-500">
                {associatedResource?.resource} -{" "}
                {`${associatedResource?.name || "(Deleted)"}`}
              </p>
            </div>
            <TableActionsIconsGroup
              canEdit
              canDelete={
                currentUser?.hasPermission?.("expense", "can_delete") &&
                isEditing
              }
              handleShowDelete={setDeleteExpenseWarning}
              handleEdit={handleEdit}
            />
          </div>
          {!isEditing && (
            <ExpenseDetailsView
              loadingReceipts={loadingReceipts}
              setIsEditing={setIsEditing}
              expenseData={formatedExpense}
              csiCodes={csiCodes}
              financialsConfiguration={financialsConfiguration}
            />
          )}
          {isEditing && (
            <ExpenseCreateForm
              associatedResource={associatedResource}
              isEditing={isEditing}
              cancel={cancel}
              setCancel={setCancel}
              setHasChanged={setHasChanged}
              setDeleteExpenseWarning={setDeleteExpenseWarning}
              deleteExpenseWarning={deleteExpenseWarning}
              hasChanged={hasChanged}
              onFinishedEditing={onFinishedEditing}
              onDeleteExpense={onDeleteExpense}
              expenseData={formatedExpense}
              onCloseCreate={onCloseModal}
              reload={reload}
              csiCodes={csiCodes}
              financialsConfiguration={financialsConfiguration}
            />
          )}
        </>
      </Modal>

      <Modal
        title="Edit expense."
        isOpen={showInvoicedInfo}
        primaryButtonTitle="Ok"
        primaryButtonOnClick={() => setShowInvoicedInfo(false)}
        onRequestModalClose={() => setShowInvoicedInfo(false)}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
        overlayStyle={overlayStyle}
        contentStyle={contentStyle}
        titleStyle={titleStyle}
        headerStyle={headerStyle}
      >
        <>
          <p className="text-base mb-2">
            This expence was invoiced and cannot be edited.
          </p>
        </>
      </Modal>

      <Modal
        isDestructive
        primaryButtonClass="border border-none"
        title={`${hasChanged ? "Discard changes" : "Delete expense"}`}
        isOpen={deleteExpenseWarning}
        primaryButtonTitle="Yes"
        primaryButtonOnClick={() => {
          onCloseModal(false);
          if (!cancel) onDeleteExpense(expenseData?.id);
        }}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={() => {
          setCancel(false);
          setDeleteExpenseWarning(false);
        }}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
        overlayStyle={overlayStyle}
        contentStyle={contentStyle}
        titleStyle={titleStyle}
        headerStyle={headerStyle}
        alert
      >
        <>
          <p className="text-base mb-2">
            {`Are you sure you want to ${
              hasChanged ? "discard changes" : "delete this expense"
            }? This action can not
              be undone.`}
          </p>
        </>
      </Modal>
    </div>
  );
};

ExpenseDetailsModal.propTypes = {
  associatedResource: PropTypes.shape({
    reference: PropTypes.string,
    name: PropTypes.string,
    resource: PropTypes.string,
  }),
  expenseData: PropTypes.shape({
    id: PropTypes.string,
    receipts: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.shape({})),
    ]),
  }),
  onFinishedEditing: PropTypes.func,
  onCloseModal: PropTypes.func,
  csiCodes: PropTypes.arrayOf(PropTypes.shape({})),
  onDeleteExpense: PropTypes.func,
  viewModalOpen: PropTypes.bool,
  reload: PropTypes.func,
  financialsConfiguration: PropTypes.shape({
    financials: PropTypes.shape({
      expense: PropTypes.shape({
        categories: PropTypes.arrayOf(
          PropTypes.shape({
            display: PropTypes.string,
            id: PropTypes.string,
            isOpen: PropTypes.bool,
            isEditing: PropTypes.bool,
          })
        ),
      }),
    }),
  }),
};
ExpenseDetailsModal.defaultProps = {
  associatedResource: undefined,
  expenseData: undefined,
  onFinishedEditing: undefined,
  onCloseModal: undefined,
  csiCodes: undefined,
  onDeleteExpense: undefined,
  viewModalOpen: false,
  reload: undefined,
  financialsConfiguration: undefined,
};

export default ExpenseDetailsModal;

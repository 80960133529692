/* eslint-disable no-param-reassign */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import PureCardGrid from "./PureCardGrid";

const CardGrid = ({
  resourceName,
  rows,
  onClick,
  detailLabel,
  customFilter,
}) => {
  const isGrouped = useMemo(() => {
    return rows?.[0]?.isGrouped;
  }, [rows]);

  return (
    <PureCardGrid
      resourceName={resourceName}
      rows={rows}
      onClick={onClick}
      isGrouped={isGrouped}
      detailLabel={detailLabel}
      customFilter={customFilter}
    />
  );
};

CardGrid.propTypes = {
  onClick: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.shape({ isGrouped: PropTypes.bool })),
  resourceName: PropTypes.string,
  detailLabel: PropTypes.string,
  customFilter: PropTypes.func,
};

CardGrid.defaultProps = {
  onClick: undefined,
  rows: [],
  resourceName: PropTypes.string,
  detailLabel: undefined,
  customFilter: undefined,
};

export default CardGrid;

import { get } from "lodash";

const aggregateTableRow = (tableRow, field) => {
  if (tableRow?.subRows?.length) {
    const subRowsTotal = tableRow.subRows.reduce(
      (sum, n) => sum + aggregateTableRow(n, field),
      0
    );
    return subRowsTotal;
  }
  const total = get(tableRow.original, field, 0);
  return parseFloat(total);
};

export default aggregateTableRow;

import React from "react";
import Dropdown from "../../../Dropdown/Dropdown";
import useUserFilterData from "./useUserFilterData";

const UsersFilter = ({
  currentCol,
  stagedFilters,
  setStagedFilters,
  allColumns,
  data,
}) => {
  const {
    filterOptions,
    filterValue,
    userOptions,
    usersSelected,
    handleChangeFilterOptions,
    handleSelectedUsers,
  } = useUserFilterData({
    allColumns,
    data,
    currentCol,
    stagedFilters,
    setStagedFilters,
  });

  return (
    <div className="flex min-w-0 pb-2 space-x-4">
      <div className="flex w-1/2">
        <Dropdown
          placeholder="Choose"
          options={filterOptions}
          value={filterValue}
          onChange={handleChangeFilterOptions}
        />
      </div>

      <div className="flex w-1/2">
        <Dropdown
          menuPlacement="bottom"
          isMulti
          options={userOptions}
          value={usersSelected}
          onChange={handleSelectedUsers}
          disableClear
        />
      </div>
    </div>
  );
};

export default UsersFilter;

import React, { useState } from "react";

const Tooltip = ({ title, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const textWidth = title.length * 8;

  return (
    <div
      className="relative flex items-center z-50"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && (
        <div
          style={{
            width: `${textWidth}px`,
            transform: "translateY(-95%)",
          }}
          className="absolute z-10 p-2 bg-black text-white text-sm rounded-md shadow-lg"
        >
          {title}
          <span
            style={{ transform: "translateY(6px) rotate(45deg)" }}
            className="absolute bottom-0 left-4 w-3 h-3 transform -translate-x-1/2 bg-black"
          />
        </div>
      )}
    </div>
  );
};

export default Tooltip;

import moment from "moment";

const returnDates = () => {
  const pageData = JSON.parse(localStorage.getItem("pageData"));

  const startDate = moment.utc(pageData?.timesheet?.periodStart);
  const endDate = moment.utc(pageData?.timesheet?.periodEnd);

  const dateArray = [];
  let currentDate = startDate;

  if (!startDate || !endDate) {
    console.error("start and end dates dont exist in local storage");
    return [];
  }

  while (currentDate <= endDate) {
    dateArray.push(currentDate.toISOString());
    currentDate = currentDate.add(1, "days");
  }

  return dateArray || [];
};

export default returnDates;

//

import moment from "moment";
import {
  SET_ALL_UNFORMATTED_EVENTS,
  UNFORMATTED_EVENTS_ACTIONS,
} from "../../constants";
import returnRecurrenceDates from "../returnRecurrenceDates";
import getEndDateForInstance from "./getEndDateForInstance";

/**
 * This is to maintain "unformattedEvents" state that is used in calendar view used for formatting dates with selected/current timezone
 * Always update its state when updating userEvents(previous state used in calendar view)
 * @param {appStateDispatch} - app state dispatch function
 * @param {unformattedEvents} - From app state unformattedEvents, used in calendar view
 * @param {item} - updated/added/deleted task/event resource
 * @param {instanceStartDate} - (optional) instanceStartDate of recurring task/event
 * @param {items} -  multiple updated/added/deleted task/event resources
 * @param {action} - Types of actions to update state - constants.UNFORMATTED_EVENTS_ACTIONS
 */
const updateUnformattedEvents = ({
  appStateDispatch,
  unformattedEvents,
  item,
  instanceStartDate,
  items,
  action,
}) => {
  switch (action) {
    case UNFORMATTED_EVENTS_ACTIONS.ADD_SINGLE: {
      appStateDispatch({
        type: SET_ALL_UNFORMATTED_EVENTS,
        unformattedEvents: [...unformattedEvents, { resource: item }],
      });
      break;
    }
    case UNFORMATTED_EVENTS_ACTIONS.ADD_RECURRING: {
      appStateDispatch({
        type: SET_ALL_UNFORMATTED_EVENTS,
        unformattedEvents: [
          ...unformattedEvents,
          ...items.map((newTask) => ({ resource: newTask })),
        ],
      });
      break;
    }
    case UNFORMATTED_EVENTS_ACTIONS.REMOVE_SINGLE: {
      appStateDispatch({
        type: SET_ALL_UNFORMATTED_EVENTS,
        unformattedEvents: unformattedEvents.filter((entry) => {
          return entry.resource.id !== item.id;
        }),
      });
      break;
    }
    case UNFORMATTED_EVENTS_ACTIONS.REMOVE_SINGLE_INSTANCE: {
      const filtered = unformattedEvents.filter((entry) => {
        const found =
          entry.resource.id === item.id &&
          entry.resource.instanceStartDate === instanceStartDate;
        return !found;
      });

      appStateDispatch({
        type: SET_ALL_UNFORMATTED_EVENTS,
        unformattedEvents: filtered.map((entry) => {
          if (entry.resource.id === item.id) {
            return {
              ...entry,
              resource: {
                ...entry.resource,
                metadata: item.metadata,
              },
            };
          }
          return entry;
        }),
      });
      break;
    }
    case UNFORMATTED_EVENTS_ACTIONS.REMOVE_FUTURE_INSTANCES: {
      // remove all after the the specified instanceStartDate and update past events
      const filteredEvents = unformattedEvents.reduce((acc, entry) => {
        const isSameOrAfter = moment(
          entry.resource?.instanceStartDate
        ).isSameOrAfter(instanceStartDate);

        if (
          entry.resource.id === item.id &&
          entry.resource.instanceStartDate &&
          isSameOrAfter
        ) {
          // filter future events
          return acc;
        }

        if (entry.resource.id === item.id) {
          // eslint-disable-next-line no-param-reassign
          acc = [
            ...acc,
            {
              ...entry,
              resource: {
                // update metadata and recurrence for past events
                ...entry.resource,
                metadata: item.metadata,
                recurrence: item.recurrence,
              },
            },
          ];
          return acc;
        }

        // add remaining events
        // eslint-disable-next-line no-param-reassign
        acc = [...acc, entry];
        return acc;
      }, []);

      appStateDispatch({
        type: SET_ALL_UNFORMATTED_EVENTS,
        unformattedEvents: filteredEvents,
      });
      break;
    }
    case UNFORMATTED_EVENTS_ACTIONS.REMOVE_RECURRING: {
      appStateDispatch({
        type: SET_ALL_UNFORMATTED_EVENTS,
        unformattedEvents: unformattedEvents.filter((entry) => {
          return entry.resource.id !== item.id;
        }),
      });
      break;
    }
    case UNFORMATTED_EVENTS_ACTIONS.EDIT_SINGLE: {
      appStateDispatch({
        type: SET_ALL_UNFORMATTED_EVENTS,
        unformattedEvents: unformattedEvents.map((entry) => {
          if (entry.resource.id === item.id) {
            return { resource: item };
          }
          return entry;
        }),
      });
      break;
    }
    case UNFORMATTED_EVENTS_ACTIONS.EDIT_SINGLE_INSTANCE: {
      appStateDispatch({
        type: SET_ALL_UNFORMATTED_EVENTS,
        unformattedEvents: unformattedEvents.map((entry) => {
          if (
            moment(entry.resource.instanceStartDate).isSame(
              moment(item.instanceStartDate)
            ) &&
            entry.resource.id === item.id
          ) {
            return { resource: item };
          }

          if (entry.resource.id === item.id) {
            return {
              ...entry,
              resource: {
                ...entry.resource,
                metadata: item.metadata,
              },
            };
          }

          return entry;
        }),
      });
      break;
    }
    case UNFORMATTED_EVENTS_ACTIONS.EDIT_ALL_INSTANCES: {
      // remove all instances
      // using recurrence, add all instances
      // Get the recurrence dates for the updated item
      const filteredEvents = unformattedEvents.filter(
        (entry) => entry.resource.id !== item.id
      );

      const recurrenceDatesForEdit = returnRecurrenceDates({
        recurrence: item?.recurrence,
      });

      recurrenceDatesForEdit.forEach((date) => {
        filteredEvents.push({
          resource: {
            ...item,
            startDate: date,
            endDate: getEndDateForInstance(item, date),
            instanceStartDate: date,
          },
        });
      });

      appStateDispatch({
        type: SET_ALL_UNFORMATTED_EVENTS,
        unformattedEvents: filteredEvents,
      });
      break;
    }
    case UNFORMATTED_EVENTS_ACTIONS.EDIT_FUTUREINSTANCES: {
      // on edit future we refetch api, so no need to recalc here
      break;
    }
    default:
  }
};

export default updateUnformattedEvents;

const getErrorMessage = (err) => {
  return err?.response?.data?.issues[0]?.detail?.display;
};

class CustomError extends Error {
  constructor(params) {
    super(params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }
    Object.keys(params).forEach((param) => {
      this[param] = params[param];
    });
  }
}
export { getErrorMessage, CustomError };

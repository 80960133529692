import { cloneDeep } from "lodash";
import { useCallback, useMemo } from "react";
import { getLocation } from "../../helpers/Asset";
import { getUserFirstAndLast } from "../../helpers/Utilities";
import { useProjectsOverview } from "../projects";
import { usePropertiesOverview } from "../properties";
import useManagementConfiguration from "../useManagementConfiguration";
import { useGetTags } from "../useTags";
import { useUsers } from "../useUsers.new";
import useAssets from "./useAssets";

/**
 * Query selector to transform assets to table format
 */
const useAssetsTableFormat = (reference, spaceId = null) => {
  // TODO: RQ-Perfm
  const { projectDict } = useProjectsOverview();
  const { propertiesDict } = usePropertiesOverview();
  const { data: tagsData } = useGetTags();
  const { data: { userDict } = {} } = useUsers();
  const tagsDict = tagsData?.tagsDict;

  const { data: managementConfiguration } = useManagementConfiguration();

  const subCategoryDict = useMemo(() => {
    return managementConfiguration?.management?.asset?.category?.reduce(
      (list, cat) => {
        const newList = cloneDeep(list);
        cat.subcategories.forEach((subcat) => {
          newList[subcat.id] = {
            category: cat.display,
            subCategory: subcat.display,
          };
        });
        return newList;
      },
      {}
    );
  }, [managementConfiguration?.management?.asset?.category]);

  const formatTableData = useCallback(
    (asset) => {
      const property = propertiesDict?.[asset?.property]?.title;
      const project = projectDict?.[asset?.project]?.name;

      return {
        id: asset?.id,
        name: asset?.name,
        image: asset?.primaryImage,
        primaryImage: asset?.primaryImage,
        categoryId: asset?.category,
        category: subCategoryDict?.[asset?.subcategory]?.category,
        subcategory: subCategoryDict?.[asset?.subcategory]?.subCategory,
        subcategoryId: asset?.subcategory,
        additionalInfo: asset?.additionalInfo,
        measurement: asset?.additionalInfo,
        type: asset?.additionalInfo?.find((m) => m.detail === "Types")?.value,
        link: asset?.additionalInfo?.find((m) => m.detail === "Buy It Again")
          ?.value,
        valuation:
          asset?.value?.length > 0 ? asset?.value[0]?.amount : undefined,
        isAppraised: !!asset?.value?.length,
        appraisalDate:
          asset?.value?.length > 0
            ? asset?.value[0]?.dateOfExamination
            : undefined,
        brand: asset?.generalInfo?.brand,
        quantity: asset?.generalInfo?.quantity || 1,
        condition: asset?.generalInfo?.condition,
        yearMade: asset?.generalInfo?.yearMade,
        origin: asset?.generalInfo?.origin,
        numberOfOwners: asset?.generalInfo?.numberOfOwners || 0,
        purchaseDate: asset?.generalInfo?.purchaseDate,
        lastUpdated: asset?.metadata?.lastUpdated,
        parLevel: asset?.generalInfo?.parLevel,
        property,
        project,
        association: project ?? property,
        description: asset?.description,
        location: getLocation(asset, propertiesDict, projectDict),
        hasLinks: !!asset?.links?.length,
        hasDocuments: !!asset?.documents?.length,
        insuranceAgent: asset?.insuranceAgent,
        insuranceBroker: asset?.insuranceBroker,
        addAnother: asset?.addAnother,
        tags: asset?.tags,
        currentTags:
          asset?.tags?.map((tagRef) => ({
            label: tagsDict?.[tagRef]?.label,
            value: tagsDict?.[tagRef]?.reference,
          })) || [],
        ownedBy:
          (
            asset?.ownedBy?.reduce((acc, item) => {
              const user = userDict?.[item?.reference];
              if (user) {
                const fullName = getUserFirstAndLast(user);
                acc.push(fullName);
              }
              return acc;
            }, []) || []
          ).join(", ") || "Not specified",
      };
    },
    [projectDict, propertiesDict, subCategoryDict, tagsDict, userDict]
  );
  /**
   * Selector to transform assets to table format
   */
  const formatAssetTableData = useCallback(
    (assets) => {
      // if viewing assetTable from P/P -> Space -> asset list
      // filter by P/P Reference via hook, then use this selector to only grab the items with the spaceId in the asset.spaces[]
      const assetArray = [];

      // check for existence of spaceId
      if (spaceId) {
        assets?.forEach((asset) => {
          // push assets that have the spaceId in their spaces[]
          if (asset?.spaces?.includes(spaceId)) {
            const newAsset = formatTableData(asset);
            assetArray.push(newAsset);
          }
        });
      } else {
        // No Space ID push all assets tied to the reference
        assets?.forEach((asset) => {
          const newAsset = formatTableData(asset);
          assetArray.push(newAsset);
        });
      }

      return assetArray;
    },
    [spaceId, formatTableData]
  );

  return useAssets({
    reference,
    select: formatAssetTableData,
  });
};

export default useAssetsTableFormat;

import React, { useCallback, useState } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import DocumentTile from "../DocumentTile/DocumentTile";
import BaseButton from "../Buttons/BaseButton";

const tertiaryButtonCN = cntl`
  text-brandGreen
  py-4
  text-base
`;

const POQualitificationsSubSection = ({
  poQualifications,
  dispatchDocumentsSettings,
}) => {
  const [addMode, setAddMode] = useState(false);

  const movePoQualification = useCallback(
    (from, to) => {
      dispatchDocumentsSettings({
        type: "changePoQualificationsPosition",
        from,
        to,
      });
    },
    [dispatchDocumentsSettings]
  );

  const togglePoQualificationsEditing = useCallback(
    (index) => {
      dispatchDocumentsSettings({
        type: "changePoQualificationsEditing",
        index,
      });
    },
    [dispatchDocumentsSettings]
  );

  const onRemovePoQualificationsDocument = useCallback(
    (index) => {
      dispatchDocumentsSettings({
        type: "removePoQualifications",
        index,
      });
    },
    [dispatchDocumentsSettings]
  );

  const onPoQualificationsDocumentChange = useCallback(
    (newValue, index) => {
      dispatchDocumentsSettings({
        type: "changePoQualifications",
        index,
        value: newValue,
      });
    },
    [dispatchDocumentsSettings]
  );

  const onAddQualification = useCallback(() => {
    dispatchDocumentsSettings({
      type: "addPoQualifications",
    });
  }, [dispatchDocumentsSettings]);

  const handleEnter = useCallback(
    (event, value, idx) => {
      // checks if the Enter key was pressed
      if (event.keyCode === 13) {
        if (value === "") {
          onRemovePoQualificationsDocument(idx);
          return;
        }
        if (addMode) onAddQualification();
        togglePoQualificationsEditing(idx);
      }
    },
    [
      addMode,
      onAddQualification,
      onRemovePoQualificationsDocument,
      togglePoQualificationsEditing,
    ]
  );

  const handleBlur = (value, idx) => {
    if (window.event.keyCode === 13) return;
    togglePoQualificationsEditing(idx);
    if (value === "") onRemovePoQualificationsDocument(idx);
    // prevents new empty input field from being added inside handleEnter
    setAddMode(false);
  };

  return (
    <div className="py-4 border-b border-gray-150 pr-6">
      <h2 className="py-2 font-semibold">Purchase Order</h2>
      <div>
        {poQualifications.map((doc, index) => (
          <DocumentTile
            key={doc?.value}
            doc={doc}
            index={index}
            moveItem={movePoQualification}
            toggleEditing={togglePoQualificationsEditing}
            onRemoveDocument={onRemovePoQualificationsDocument}
            onDocumentChange={onPoQualificationsDocumentChange}
            handleBlur={handleBlur}
            handleEnter={handleEnter}
            setAddMode={setAddMode}
            className="py-2"
            inputClassName="w-1/2"
          />
        ))}
      </div>
      <BaseButton
        title="+ Add Qualification & Exclusion"
        className={tertiaryButtonCN}
        onClick={() => {
          setAddMode(true);
          onAddQualification();
        }}
        innerClassName="mx-0"
      />
    </div>
  );
};

POQualitificationsSubSection.propTypes = {
  poQualifications: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      isEditing: PropTypes.bool,
    })
  ),
  dispatchDocumentsSettings: PropTypes.func,
};

POQualitificationsSubSection.defaultProps = {
  poQualifications: [],
  dispatchDocumentsSettings: undefined,
};

export default POQualitificationsSubSection;

import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import BaseButton from "./BaseButton";

const secondaryButtonCN = (className) => cntl`
  h-10
  py-2
  px-4
  border
  rounded
  bg-white
  text-primaryGreen
  font-semibold
  transition-all
  duration-200
  disabled:bg-opacity-70
  hover:bg-primaryGreen
  border
  border-primaryGreen
  ${className}
`;

const SecondaryButton = ({ className, minWidth, ...props }) => {
  return (
    <BaseButton
      style={{ minWidth }}
      className={secondaryButtonCN(className)}
      colorCN="text-black"
      hoverColorCN="text-white"
      {...props}
    />
  );
};

SecondaryButton.propTypes = {
  /**
   * any CSS classes to apply to the container
   */
  className: PropTypes.string,

  minWidth: PropTypes.number,
};

SecondaryButton.defaultProps = {
  className: null,
  minWidth: 120,
};

export default SecondaryButton;

import React, { useEffect, useState } from "react";
import cntl from "cntl";
import { orderBy } from "lodash";
import Input from "../../Input/Input";
import IconButton from "../../Buttons/IconButton";
import collapseIcon from "../../../assets/images/collapseIcon.svg";

const retainageValCN = (length, i) => cntl`
flex
h-20
items-center
mx-4
${length > 1 && i < length - 1 && "border-b"}
${`order-${i}`}
`;

const staticSubTitle = cntl`
font-bold
mx-4
`;

const RetainageList = ({
  isEditing,
  retainage: rList = [],
  handleChange,
  handleRemoveValue,
  editable,
}) => {
  const [retainage, setRetainage] = useState([]);
  const [order, setOrder] = useState([]);

  useEffect(() => {
    setOrder(
      orderBy(rList, ["percentComplete"], ["asc"])?.reduce(
        (list, item, idx) => ({ ...list, [item.index]: idx }),
        {}
      )
    );
    setRetainage(rList);
  }, [rList]);
  return retainage.map((item, i) => {
    const key = `retainage-${item?.id || i}`;
    return (
      <div
        key={key}
        className={retainageValCN(retainage?.length, order?.[item.index])}
        onBlur={(e) => {
          if (e.relatedTarget.tabIndex !== 99 + order?.[item.index]) {
            handleChange(
              retainage[i]?.percentComplete,
              retainage[i]?.percentRetainage,
              i
            );
          }
        }}
        onMouseLeave={() => {
          handleChange(
            retainage[i]?.percentComplete,
            retainage[i]?.percentRetainage,
            i
          );
        }}
      >
        <p className="truncate">
          <span className={staticSubTitle}>If</span>
          {isEditing && (
            <div className="inline-block" style={{ width: "5ch" }}>
              <Input
                placeholder="% Complete"
                className="mr-1"
                inputClassName="ESInput px-2 text-center"
                onChange={(val) => {
                  setRetainage((prev) => {
                    const previous = [...prev];
                    const percentComplete = val > 100 ? 100 : val;
                    const percentRetained = previous[i]?.percentRetainage;
                    return previous.toSpliced(i, 1, {
                      ...prev[i],
                      percentComplete,
                      percentRetainage:
                        percentRetained > 100 - percentComplete
                          ? 100 - percentComplete
                          : percentRetained,
                    });
                  });
                }}
                value={retainage[i]?.percentComplete}
                minValue={0}
                disableClear
                tabIndex={99 + order?.[item.index]}
              />
            </div>
          )}
          <span className="text-gray-200 ">
            {!isEditing && item.percentComplete}% Complete
          </span>
        </p>
        <p className="truncate">
          <span className={staticSubTitle}>then</span>
        </p>
        {isEditing && (
          <div className="inline-block" style={{ width: "5ch" }}>
            <Input
              placeholder="% Retainage"
              className="mr-1"
              inputClassName="ESInput px-2 text-center"
              onChange={(val) => {
                setRetainage((prev) => {
                  const previous = [...prev];
                  const percentComplete = previous[i]?.percentComplete;
                  let percentRetainage =
                    val > 100 - percentComplete ? 100 - percentComplete : val;
                  percentRetainage =
                    percentRetainage > 100 ? 100 : percentRetainage;
                  return previous.toSpliced(i, 1, {
                    ...prev[i],
                    percentRetainage,
                  });
                });
              }}
              value={retainage[i]?.percentRetainage}
              minValue={0}
              tabIndex={99 + order?.[item.index]}
              disableClear
            />
          </div>
        )}
        <p className="truncate">
          <span className="text-gray-200">
            {!isEditing && item.percentRetainage}% Retainage
          </span>
        </p>
        {isEditing && editable && (
          <IconButton
            className="ml-4"
            icon={collapseIcon}
            onClick={() => handleRemoveValue(i)}
          />
        )}
      </div>
    );
  });
};

export default RetainageList;

import { useEffect, useState } from "react";

const useUserAgreement = ({ setIsTNCCheck }) => {
  const [isChecked, setIsChecked] = useState({
    tnc: false,
    eula: false,
    privacy: false,
  });

  useEffect(() => {
    if (Object.values(isChecked).every((item) => item === true)) {
      return setIsTNCCheck(true);
    }
    return setIsTNCCheck(false);
  }, [isChecked, setIsTNCCheck]);

  const onChange = (key, val) => {
    setIsChecked((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  return {
    isChecked,
    onChange,
  };
};

export default useUserAgreement;

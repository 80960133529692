import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { FileAPI, SopAPI } from "@griffingroupglobal/eslib-api";

import { formatDate } from "../../../helpers/Formatters";
import Spinner from "../Spinner/Spinner";
import { getFullRrule } from "../../../helpers/Time";
import Checkbox from "../Checkbox/Checkbox";
import IconButton from "../Buttons/IconButton";
import chevronRight from "../../assets/images/chevronRight.svg";
import returnIcon from "../../assets/images/returnIcon.svg";
import { maintenanceStatus } from "../../../constants";
import { isAllDay } from "../../../helpers/Calendar";
import AttachmentViewAndDownload from "../FileForm/AttachmentViewAndDownload";
import TagList from "../Tags/TagList";

const { CANCELLED, COMPLETE } = maintenanceStatus;

const AssetMaintenanceScheduledTaskView = ({
  isLoading,
  currentUser,
  assignedTo,
  data,
  setUpdatedSteps,
  navigateToSop,
  setViewModalButtonAction,
  taskStatus,
  canNavigateToAssociation,
  associationName,
  navigateToAssociation,
  isMaintenanceView,
  canCompleteStep,
  hideSteps,
}) => {
  const { name, startDate, endDate, description } = data || {};
  const [urls, setUrls] = useState([]);
  const [recurrence, setRecurrence] = useState();
  const [maintenanceSteps, setMaintenanceSteps] = useState([]);
  const [sopSteps, setSopSteps] = useState([]);
  const [expendSteps, setExpendSteps] = useState([]);

  useEffect(() => {
    setMaintenanceSteps(data?.steps);
  }, [data?.steps]);

  useEffect(() => {
    const fetchSOPSteps = async () => {
      try {
        if (maintenanceSteps) {
          const steps = maintenanceSteps?.filter(
            (step) => step.sop !== undefined
          );
          const sopXYZ = await Promise.all(
            steps.map(async (sopItem) => {
              const sopId = sopItem?.sop?.split("/")[1];
              const { data: sopData } = await SopAPI.getById(sopId);
              return sopData;
            })
          );
          setSopSteps(sopXYZ);
        }
      } catch (error) {
        console.warn("Error fetching SOP");
      }
    };

    fetchSOPSteps();
  }, [maintenanceSteps]);

  useEffect(() => {
    if (setUpdatedSteps) setUpdatedSteps(maintenanceSteps);
  }, [maintenanceSteps, setUpdatedSteps]);

  useEffect(() => {
    const getRecurrence = async () => {
      const editRecurrence = await getFullRrule(data?.recurrence);
      setRecurrence(editRecurrence);
    };
    getRecurrence();
  }, [data?.recurrence]);

  useEffect(() => {
    const buildFileUrl = async () => {
      try {
        if (data?.files?.length > 0) {
          const filesAndDocUrls = await Promise.all(
            data?.files?.map(async (f) => {
              const { data: file } = await FileAPI.getByRef(f?.ref);
              return {
                url: file?.contentsUrl,
                name: file?.name,
                file,
              };
            })
          );
          setUrls(filesAndDocUrls);
        }
      } catch (error) {
        console.warn("Failed to build file url", error);
      }
    };
    buildFileUrl();
  }, [data?.files]);

  const onStepCheckedOff = useCallback(
    (step, index) => {
      const changedSteps = maintenanceSteps?.map((foundStep, i) => {
        if (index === i) {
          const clickedStep = { ...step };
          clickedStep.isCompleted = !foundStep?.isCompleted;
          return clickedStep;
        }
        return foundStep;
      });
      setMaintenanceSteps((prev) => changedSteps ?? prev);
    },
    [maintenanceSteps]
  );

  useEffect(() => {
    if (!isMaintenanceView) {
      const everyStepCompleted = maintenanceSteps.every(
        (sopStep) => sopStep.isCompleted
      );
      setViewModalButtonAction(
        everyStepCompleted && data?.invitees.includes(currentUser?.reference)
          ? "Complete"
          : "Close"
      );
    }
  }, [
    currentUser,
    data?.invitees,
    isMaintenanceView,
    maintenanceSteps,
    setViewModalButtonAction,
  ]);

  const handleExpandSteps = useCallback(
    (sop) => {
      if (!expendSteps.includes(sop)) {
        setExpendSteps((prev) => [...prev, sop]);
      } else {
        const tempExpend = expendSteps.filter((item) => item !== sop);
        setExpendSteps(tempExpend);
      }
    },
    [expendSteps]
  );
  return (
    <div className="flex flex-col justify-center items-start w-192">
      {!isLoading && (
        <>
          {associationName && (
            <div className="w-full mb-4">
              <h3 className="pb-2 text-gray-200">Association</h3>
              <p className="flex items-center text-gray-400">
                {associationName}
                {canNavigateToAssociation && (
                  <IconButton
                    icon={returnIcon}
                    className="ml-3"
                    imgClassName="w-5.5 h-5.5"
                    onClick={navigateToAssociation}
                  />
                )}
              </p>
            </div>
          )}
          <div className="w-full mb-4">
            <h3 className="pb-2  text-gray-200">Task</h3>
            <p className="text-gray-400">{name}</p>
          </div>
          <div className="w-full flex mb-4">
            <div className="w-1/2">
              <h3 className="pb-2 text-gray-200">Due Date</h3>
              <p className="text-gray-400">{formatDate(startDate)}</p>
            </div>
            <div className="">
              <h3 className="pb-2 text-gray-200">Due Time</h3>
              <p className="text-gray-400">
                {isAllDay(moment(startDate), moment(endDate))
                  ? "All Day"
                  : moment(startDate)?.format("h:mm A")}
              </p>
            </div>
          </div>
          <div className="w-full flex  mb-4">
            <div className="w-1/2">
              <h3 className="pb-2 text-gray-200">Assigned to</h3>
              <p className="text-gray-400 capitalize">
                {assignedTo?.length > 0 &&
                  assignedTo?.map((a) => (
                    <span key={a}>
                      {a}
                      <br />
                    </span>
                  ))}
              </p>
            </div>
            <div className="">
              <h3 className="text-gray-200">Repeat</h3>
              <div className="pt-2 text-gray-400">
                {isMaintenanceView
                  ? data?.recurrence || "No repeat"
                  : recurrence?.label || "No repeat"}
              </div>
            </div>
          </div>
          {description && (
            <div className="w-full mb-4">
              <h3 className="pb-2 text-gray-200">Description</h3>
              <p className="w-full max-h-60 p-4 border overflow-y-auto font-medium text-gray-400 text-sm">
                {description}
              </p>
            </div>
          )}

          {!hideSteps && (
            <>
              {maintenanceSteps?.length > 0 && (
                <div className="mt-auto pl-0 mb-4  w-full">
                  <div className="flex flex-col">
                    <h3 className="pb-2 text-gray-200">Steps</h3>
                    <div className="flex flex-col w-full">
                      {maintenanceSteps?.map((step, i) => {
                        if (!step?.sop) {
                          return (
                            <div
                              className="flex items-center !w-full mt-2"
                              key={step?.id}
                            >
                              <Checkbox
                                label={step?.description}
                                labelClassName="text-sm text-gray-400"
                                className="flex-grow"
                                checked={step?.isCompleted}
                                onChange={() => onStepCheckedOff(step, i)}
                                disabled={
                                  isMaintenanceView ||
                                  !canCompleteStep ||
                                  taskStatus === COMPLETE ||
                                  taskStatus === CANCELLED
                                }
                              />
                            </div>
                          );
                        }
                        return (
                          <React.Fragemnt key={step?.id}>
                            <div className="flex items-center !w-full mt-2">
                              <div className="flex w-1/2">
                                <Checkbox
                                  label={step?.description}
                                  labelClassName="text-sm text-ellipsis font-semibold text-gray-200"
                                  className=""
                                  checked={step?.isCompleted}
                                  onChange={() => onStepCheckedOff(step, i)}
                                  disabled={
                                    isMaintenanceView ||
                                    !canCompleteStep ||
                                    taskStatus === COMPLETE ||
                                    taskStatus === CANCELLED
                                  }
                                />
                                <IconButton
                                  icon={chevronRight}
                                  className={`${
                                    expendSteps?.includes(step?.sop)
                                      ? "transform rotate-90 origin-center ml-2"
                                      : "ml-0 mt-1"
                                  }`}
                                  imgClassName="ml-2 w-4 h-4"
                                  onClick={() => handleExpandSteps(step?.sop)}
                                />
                              </div>
                              <div className="flex justify-start items-center">
                                <span className="text-sm text-gray-200">
                                  sop
                                </span>
                                <IconButton
                                  icon={returnIcon}
                                  className="ml-3"
                                  imgClassName="w-5.5 h-5.5"
                                  onClick={() =>
                                    navigateToSop(step?.sop?.split("/")[1])
                                  }
                                />
                              </div>
                            </div>
                            {expendSteps?.includes(step?.sop) &&
                              sopSteps
                                ?.find((item) => item?.reference === step?.sop)
                                ?.steps?.map((itemStep) => (
                                  <div
                                    className="transform flex items-center !w-full ml-6 mt-2"
                                    key={itemStep?.id}
                                  >
                                    <Checkbox
                                      label={itemStep?.description}
                                      labelClassName="text-xs text-gray-200"
                                      className="flex-grow"
                                      checked={step?.isCompleted}
                                      disabled
                                    />
                                  </div>
                                ))}
                          </React.Fragemnt>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {urls?.length > 0 && (
            <div className="w-full mb-6">
              <h3 className="text-gray-200">Attachments</h3>
              <div className="flex flex-col w-full">
                <AttachmentViewAndDownload
                  filesArray={urls?.map(({ file }) => file)}
                />
              </div>
            </div>
          )}

          {data?.links?.length > 0 && (
            <div className="w-full mb-8">
              <h3 className="pb-2 text-gray-200">Links</h3>
              {data?.links?.map((item) => {
                return (
                  <div key={item?.url} className="flex items-center mt-2">
                    <a
                      className=" text-brandGreen"
                      href={
                        item?.url?.startsWith("https://")
                          ? item?.url
                          : `https://${item?.url}`
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item?.name || item?.url}
                    </a>
                  </div>
                );
              })}
            </div>
          )}

          {!!data?.currentTags?.length && (
            <div className="w-full mb-8">
              <TagList tags={data?.currentTags} className="flex-wrap gap-2" />
            </div>
          )}
        </>
      )}

      {isLoading && (
        <div className="flex justify-center items-center w-full h-60">
          <Spinner />
        </div>
      )}
    </div>
  );
};

AssetMaintenanceScheduledTaskView.propTypes = {
  currentUser: PropTypes.shape({ reference: PropTypes.string }),
  invitees: PropTypes.arrayOf(PropTypes.string),
  assignedTo: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.shape({
    name: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    description: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.string),
    images: PropTypes.arrayOf(PropTypes.shape({})),
    videos: PropTypes.arrayOf(PropTypes.string),
    recurrence: PropTypes.string,
    steps: PropTypes.arrayOf(PropTypes.string),
    invitees: PropTypes.arrayOf(PropTypes.shape({})),
    links: PropTypes.arrayOf(PropTypes.shape({})),
    currentTags: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  setUpdatedSteps: PropTypes.func,
  isLoading: PropTypes.bool,
  navigateToSop: PropTypes.func,
  setViewModalButtonAction: PropTypes.func,
  taskStatus: PropTypes.string,
  canNavigateToAssociation: PropTypes.bool,
  associationName: PropTypes.string,
  navigateToAssociation: PropTypes.func,
  isMaintenanceView: PropTypes.bool,
  canCompleteStep: PropTypes.bool,
  hideSteps: PropTypes.bool,
};

AssetMaintenanceScheduledTaskView.defaultProps = {
  currentUser: {},
  invitees: [],
  assignedTo: [],
  data: {},
  setUpdatedSteps: undefined,
  isLoading: false,
  navigateToSop: undefined,
  setViewModalButtonAction: undefined,
  taskStatus: "scheduled",
  canNavigateToAssociation: false,
  associationName: undefined,
  navigateToAssociation: undefined,
  isMaintenanceView: false,
  canCompleteStep: false,
  hideSteps: false,
};

export default AssetMaintenanceScheduledTaskView;

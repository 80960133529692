import { useCallback } from "react";

const BUTTONS_LABELS = ["Add Report"];

/**
 * Hook for generating button properties for Report top button inside P/P/A
 */
const useReportTopButtons = () => {
  const getReportButtonProps = useCallback((buttonsActions = []) => {
    const addAction = buttonsActions.find((opt) =>
      BUTTONS_LABELS.includes(opt.title)
    );

    const title = addAction?.title;
    const className = addAction?.className;

    return {
      onClick: () => addAction?.onClick(),
      addButton: true,
      resourceName: title?.includes("Report") ? "Report" : title,
      buttonTitle: title?.includes("Report") ? undefined : title,
      className,
    };
  }, []);

  return { getReportButtonProps };
};

export default useReportTopButtons;

import { ConfigurationAPI } from "@griffingroupglobal/eslib-api";
import { useAppState } from "../state/appState";
import { SET_WORKFLOWS_CONFIGURATION } from "../constants";
import { getLatestTime } from "../helpers/Time";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { configurationKeys } from "../config/reactQuery/queryKeyFactory";

const fetchWorkflowConfiguration = async () => {
  const { data } = await ConfigurationAPI.getById("workflows");
  return data;
};

export default () => {
  const [{ configurationLastUpdated }, dispatch] = useAppState();

  return useAuthenticatedQuery({
    queryKey: configurationKeys("workflows"),
    queryFn: fetchWorkflowConfiguration,
    placeholderData: {},
    onError: (error) => console.error("❌ useWorkflowsConfiguration", error),
    onSuccess: (data) => {
      dispatch({
        type: SET_WORKFLOWS_CONFIGURATION,
        workflowsConfiguration: { workflows: data?.workflows },
        configurationLastUpdated: getLatestTime(
          configurationLastUpdated,
          data.metadata.lastUpdated
        ),
      });
    },
  });
};

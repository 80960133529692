import React from "react";
import PropTypes from "prop-types";
import Pill from "../../stories/Components/Pill/Pill";

const SubmittalStatusPill = ({ submittalStatus, canComplete }) => {
  switch (submittalStatus) {
    case "in-progress":
      return (
        <Pill
          value={canComplete ? "Pending Completion" : "In-Progress"}
          textSize="text-xs"
          border={
            canComplete
              ? "border border-brandDarkblue"
              : "border border-darkenedGreen"
          }
          background={canComplete ? "bg-brandDarkBlue" : "bg-white"}
          textColor={canComplete ? "text-white" : "text-darkenedGreen"}
        />
      );
    case "completed-resolved":
      return (
        <Pill
          value="Complete - Resolved"
          textSize="text-xs"
          border="border border-darkenedGreen"
          background="bg-darkenedGreen"
          textColor="text-white"
        />
      );
    case "completed-open":
      return (
        <Pill
          value="Complete - Open"
          textSize="text-xs"
          border="border border-darkenedGreen"
          background="bg-darkenedGreen"
          textColor="text-white"
        />
      );
    case "draft":
      return (
        <Pill
          value="Draft"
          textSize="text-xs"
          border="border border-gray-450"
          background="bg-gray-450"
          textColor="text-white"
        />
      );
    case "void":
      return (
        <Pill
          value="Void"
          textSize="text-xs"
          border="border border-gray-450"
          background="bg-white"
          textColor="text-gray-450"
        />
      );
    default:
      return "";
  }
};

SubmittalStatusPill.propTypes = {
  submittalStatus: PropTypes.string,
  canComplete: PropTypes.bool,
};

SubmittalStatusPill.defaultProps = {
  submittalStatus: undefined,
  canComplete: false,
};

export default SubmittalStatusPill;

import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import moment from "moment";
import { useDrag, useDrop } from "react-dnd";
import { cloneDeep } from "lodash";
import { WorkflowAPI } from "@griffingroupglobal/eslib-api";
import { toast } from "react-toastify";
import ReactModal from "react-modal";
import useDocumentsConfiguration from "../../../hooks/useDocumentsConfiguration";
import useSingleAndDoubleClick from "../../../hooks/useSingleAndDoubleClick";
import TertiaryButton from "../Buttons/TertiaryButton";
import IconButton from "../Buttons/IconButton";
import deleteGreeenIcon from "../../assets/images/deleteGreenIcon.svg";
import editIcon from "../../assets/images/editIcon.svg";
import WorkflowEditStepCard from "./WorkflowEditStepCard";
import resubmissionBlackIcon from "../../assets/images/resubmissionBlackIcon.svg";
import arrowRightGray from "../../assets/images/arrowRightGray.svg";
import arrowRightGreen from "../../assets/images/arrowRightGreen.svg";
import arrowRightRed from "../../assets/images/arrowRightRed.svg";
import checkmark from "../../assets/images/checkmark.svg";
import WorkflowFileView from "../Workflows/WorkflowFileView";
import {
  ADVANCED_WF_STEP,
  RESUBMISSION_WF_STEP,
  fileCardMiniStyle,
  RESOLVED_WF,
} from "../../../constants";
import WorkflowData from "../../../helpers/Workflow";
import WorkflowStepAction from "./WorkflowStepAction";
import DeleteModal from "../DeleteModal/DeleteModal";
import { formatSelectUser, getFullName } from "../../../helpers/Formatters";
import FileCard from "../FileCard/FileCard";
import WorkflowFileForm from "../FileForm/WorkflowFileForm";
import useWorkflowFileReducer from "../../../hooks/usePropertyFormReducer";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteCircleCheckIcon from "../../assets/images/circleCheckIcon.svg";
import { downloadMedia } from "../../../helpers/File";
import QuickAddToResource from "../QuickAddToResrouce/QuickAddToResource";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";

const cardWrapperCN = (completedStep, status) => cntl`
  flex 
  flex-col
  mb-6
  p-6
  pb-10
  ${completedStep ? "border-gray-150" : "border-gray-200"}
  ${completedStep ? "border-gray-150" : "hover:border-borderGreen"}
  ${status === "ERROR" && "border-accessibleRed"}
  ${
    status === "ACTIVE" && !completedStep
      ? "border-brandGreen border-2"
      : "border"
  }
  rounded-md
  relative
 `;
const cardGroupingCN = (
  multiple,
  index,
  editing,
  last,
  status,
  isParallel
) => cntl`
    absolute 
    -left-5 
    border-gray-150 
    group-hover:border-borderGreen
    ${status === "ERROR" && "border-accessibleRed"}
    ${status === "ACTIVE" && "border-brandGreen"}
    border-l-2 
    ${multiple ? "w-5" : "w-3"}
    ${index === 0 && !isParallel && "rounded-t-sm"}
    ${index === 0 && !isParallel && "border-t-2"}
    ${index === 0 && !isParallel ? "top-9" : "top-0"}
    ${(index === 0 && !isParallel) || (!editing && !last) ? "h-full" : ""}
    ${!multiple && !editing && "hidden"}
    ${last && !editing && "h-9"}
    ${last && editing && "h-full"}
 `;
const stepGroupingBallCN = (status) => cntl`
  absolute
  top-7
  h-4 
  w-4 
  rounded-full 
  group-hover:bg-borderGreen
  z-10
  ${!status && "bg-gray-150"}
  ${status === "ERROR" && "bg-accessibleRed"}
  ${status === "ACTIVE" && "bg-brandGreen"}
`;
const stepGroupingMidCN = (status) => cntl`
    absolute 
    -left-5 
    -top-11 
    border-gray-150 
    border-l-2 
    w-3 
    h-11
    ${status === "ERROR" && "border-accessibleRed"}
    ${status === "ACTIVE" && "border-brandGreen"}
`;
const stepGroupingBottomCN = (status) => cntl`
    absolute 
    -left-5 
    top-9 
    border-gray-150
    group-hover:border-borderGreen 
    border-t-2 
    w-5
    ${status === "ERROR" && "border-accessibleRed"}
    ${status === "ACTIVE" && "border-brandGreen"}
`;
const arrowCN = (isOpen) => cntl`
    z-20
    ${isOpen && "transform rotate-90"}
`;

const modalStyles = {
  content: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    height: "calc(100% - 72px)",
    margin: "36px 0 0 0",
    padding: "18px 20px 4px 20px",
    borderRadius: "0",
    inset: "0",
    overflow: "visible",
  },
  overlay: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "rgba(25, 25, 25, 0.8)",
    zIndex: "50",
  },
};

const WorkflowStepCard = ({
  data,
  index,
  parentIndex,
  multiple,
  last,
  handleAddParallelStep,
  handleEditStep,
  handleDeleteStep,
  canEdit,
  moveColumn,
  viewing,
  handleMemberResubmission,
  handleMemberAdvance,
  workflowData,
  isParallel,
  allMembers,
  totalDuration,
  handlePostComment,
  handlePostReply,
  handleAddFiles,
  handleAddReminder,
  currentRef,
  minIndex,
  unselectCompletedCard,
  hideDueDate,
  startDate,
  currentUser,
  template,
}) => {
  const { data: documentsConfiguration } = useDocumentsConfiguration();
  const [files, dispatch] = useWorkflowFileReducer();
  const [editMode, setEditMode] = useState(false);
  const [cardStatus, setCardStatus] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [needsResubmition, setNeedsResubmition] = useState(false);
  const [completedStep, setCompletedStep] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [showSpinner, setShowSpinner] = useState(false);
  const [clickedDoc, setClickedDoc] = useState();
  const [expanded, setExpanded] = useState(false);
  const [dueDateInfo, setDueDateInfo] = useState({
    pastDue: false,
    duration: "",
  });
  const [allDateInfo, setAllDateInfo] = useState(null);
  // state value to track members who are part of template but not part of resource
  const [nonRscMembers, setNonRscMembers] = useState([]);

  useEffect(() => {
    if (showSpinner) {
      toast.success("Your files have been successfully uploaded", {
        toastId: "toast-is-active",
        position: "top-center",
        autoClose: 3000,
        icon: () => <img src={whiteCircleCheckIcon} alt="Successful upload" />,
        closeButton: <img src={whiteCrossIcon} alt="Close notice" />,
        hideProgressBar: true,
        className: "bg-brandGreen text-white px-6 py-0 mb-0",
        style: { minHeight: "43px", top: "29px" },
      });
    }
    setShowSpinner(false);

    dispatch({ type: "reset" });
  }, [workflowData?.documents, data?.documents, dispatch, showSpinner]);

  const docTypeOptionsMap = React.useMemo(() => {
    return {
      ...documentsConfiguration?.documents?.documentType
        .filter((doc) => doc.selected && (doc.value || doc.id))
        .reduce((obj, item) => {
          return {
            ...obj,
            [item.value ?? item.id]: {
              label: item.display,
              value: item.value ?? item.id,
            },
          };
        }, {}),
    };
  }, [documentsConfiguration]);

  const toggleEdit = () => {
    setEditMode((prev) => !prev);
    setNonRscMembers((prev) => prev);
  };
  const cardPos = { parentIndex, index, isParallel };
  const additionalLetter = isParallel ? 1 : 0;
  const stepName = multiple
    ? `${parentIndex + 1}${String.fromCharCode(
        97 + index + additionalLetter
      )}. ${data.name}`
    : `${parentIndex + 1}. ${data.name}`;
  const ItemTypes = {
    CARD: "card",
  };
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      if (!ref.current) {
        return;
      }
      const dragParentIndex = item.cardPos.parentIndex;
      const dragIndex = item.cardPos.index;
      const dragIsParallel = item.cardPos.isParallel;
      const dropParentIndex = parentIndex;
      const dropIndex = index;
      const dropIsParallel = isParallel;

      // if (dragParentIndex === dropParentIndex && dragIndex === dropIndex) {
      //   return;
      // }
      moveColumn(
        dragParentIndex,
        dragIndex,
        dragIsParallel,
        dropParentIndex,
        dropIndex,
        dropIsParallel
      );
    },
  });
  const [, , preview] = useDrag({
    item: { type: ItemTypes.CARD, id: data.id, cardPos, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(preview(ref));

  useEffect(() => {
    if (template && !data.newStep) {
      setEditMode(false);
    } else if (data.newStep) {
      setEditMode(true);
    }
  }, [data.newStep, template]);
  const getCardStatus = useCallback(() => {
    if (workflowData?.currentStep > parentIndex) {
      setCardStatus("ACTIVE");
    } else if (
      workflowData?.currentStep === parentIndex &&
      workflowData.status !== RESUBMISSION_WF_STEP
    ) {
      setCardStatus("ACTIVE");
    } else if (
      workflowData?.steps[parentIndex]?.resubmission &&
      workflowData.currentStep === parentIndex
    ) {
      setCardStatus("ERROR");
    }
    const stepTest = WorkflowData.checkStep(data);

    setCompletedStep(stepTest);

    if (stepTest) {
      unselectCompletedCard(parentIndex);
    }
    if (!stepTest && parentIndex === minIndex) {
      setIsOpen(true);
    }

    const currentSteps = workflowData?.steps[workflowData?.currentStep];

    if (
      !stepTest &&
      parentIndex === workflowData.currentStep &&
      currentSteps.parallelSteps
    ) {
      setIsOpen(true);
    }

    const stepTestRes = WorkflowData.checkStep(data, true);
    setNeedsResubmition(!stepTestRes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    workflowData.currentStep,
    workflowData.status,
    workflowData?.steps,
    data,
    parentIndex,
    minIndex,
  ]);

  useEffect(() => {
    if (viewing) {
      getCardStatus();
    }
  }, [
    workflowData,
    data.members,
    data.needOnlyOneAdvancer,
    data,
    viewing,
    getCardStatus,
  ]);

  const handleAdvanceStep = (memberIndex, comment) => {
    const tempObj = { ...workflowData };
    if (tempObj.status === "dueSoon" || tempObj.status === "overdue") {
      tempObj.status = "inProgress";
    }
    if (isParallel) {
      const memberInfo =
        tempObj.steps[parentIndex].parallelSteps[index].members[memberIndex];
      tempObj.steps[parentIndex].parallelSteps[index].members[memberIndex] = {
        ...memberInfo,
        status: ADVANCED_WF_STEP,
        date: moment().format("YYYY-MM-DD"),
        note: comment || "",
      };
    } else {
      const memberInfo = tempObj.steps[parentIndex].members[memberIndex];
      tempObj.steps[parentIndex].members[memberIndex] = {
        ...memberInfo,
        status: ADVANCED_WF_STEP,
        date: moment().format(),
        note: comment || "",
      };
    }
    if (comment) {
      tempObj.message = comment;
    }

    handleMemberAdvance(tempObj, parentIndex, data.id);
    getCardStatus();
  };

  const handleResubmitStep = (memberIndex, comment) => {
    const tempObj = cloneDeep(workflowData);
    if (tempObj.status === "dueSoon" || tempObj.status === "overdue") {
      tempObj.status = "inProgress";
    }
    if (isParallel) {
      const memberInfo =
        tempObj.steps[parentIndex].parallelSteps[index].members[memberIndex];
      tempObj.steps[parentIndex].parallelSteps[index].members[memberIndex] = {
        ...memberInfo,
        status: RESUBMISSION_WF_STEP,
        date: moment().format(),
        note: comment || "",
      };
      const stepTest = WorkflowData.checkStep(
        tempObj.steps[parentIndex].parallelSteps[index],
        true
      );
      if (!stepTest) {
        tempObj.status = RESUBMISSION_WF_STEP;
      }
    } else {
      const memberInfo = tempObj.steps[parentIndex].members[memberIndex];
      tempObj.steps[parentIndex].members[memberIndex] = {
        ...memberInfo,
        status: RESUBMISSION_WF_STEP,
        date: moment().format(),
        note: comment || "",
      };
      const stepTest = WorkflowData.checkStep(tempObj.steps[parentIndex], true);

      if (!stepTest) {
        tempObj.status = RESUBMISSION_WF_STEP;
      }
    }
    if (comment) {
      tempObj.message = comment;
    }

    handleMemberResubmission(tempObj, parentIndex, data.id);
    getCardStatus();
  };

  const handleUploadFiles = (fileUploads, documentsToUpload) => {
    handleAddFiles(
      fileUploads,
      isParallel,
      parentIndex,
      index,
      data.id,
      documentsToUpload
    );
  };

  const arrayIcon = () => {
    if (cardStatus === "ERROR") {
      return arrowRightRed;
    }
    if (cardStatus === "ACTIVE") {
      return arrowRightGreen;
    }
    return arrowRightGray;
  };
  const handleSendReminder = async (note, sentTo) => {
    const Res = await WorkflowAPI.postWOP(`${workflowData.id}/$sendreminder`, {
      note: note || "",
      sentTo,
      step: data.id,
    });
    handleAddReminder(Res.data);
  };
  const canAdvance =
    workflowData?.currentStep === parentIndex &&
    data?.members?.some(
      (memb) => memb?.reference === currentRef && !memb?.status
    );
  const handleModalDelete = () => {
    handleDeleteStep(parentIndex, index, isParallel);
    setIsDeleteModalOpen(false);
  };

  const getDocType = (documentType) => {
    if (documentType) {
      return docTypeOptionsMap[documentType]?.label ?? documentType;
    }
    return "File";
  };

  const handleFavoriteClick = () => {};

  const handleDeleteFile = () => {};

  const handleDoubleClick = async () => {
    setSelectedFile(`Document/${clickedDoc?.id}`);
    setShowFileModal(true);
    setExpanded(true);
  };

  const handleFileClick = useSingleAndDoubleClick(() => {}, handleDoubleClick);

  const handleFileDownload = async (doc) => {
    await downloadMedia([doc.contentReference]);
  };

  const handleFileUpload = (documentsToUpload) => {
    handleUploadFiles(files.files, documentsToUpload);
    setShowSpinner(true);
  };

  const handleCloseUploader = () => {
    setIsUploadOpen(false);
  };

  useEffect(() => {
    const start = moment(startDate?.date).format();
    const today = moment().format();
    const due = moment(startDate?.date).add(data?.duration, "days").format();
    const dueDuration = moment(due).diff(start, "days");
    const daysRemaining = moment(due).diff(today, "days");

    if (dueDuration < 0) {
      setDueDateInfo({
        duration: data?.duration,
        pastDue: true,
        remaining: daysRemaining,
        due,
      });
    }

    setDueDateInfo({
      duration: data?.duration,
      pastDue: false,
      remaining: daysRemaining,
      due,
    });
  }, [data, startDate]);

  const handleGetDueOn = (date) => {
    // if it's not an allDay step return the date with due at time
    const dueDate = moment(date?.date || date).format("LL");
    const dueTime =
      date?.time?.label || moment(date?.time, ["HHmm"]).format("hh:mm A");
    if (!date?.allDay && date?.time) {
      return `${dueDate} at ${dueTime}`;
    }
    // else return just the date
    return dueDate;
  };

  useEffect(() => {
    const tempSteps = template?.steps ?? [];
    const tempMembers = tempSteps[parentIndex]?.members?.map(
      (mem) => mem.reference
    );
    const resourceMembers = data?.members?.map((mem) => mem.value);
    const nonResourceMembers = [];

    tempMembers?.forEach((mem) => {
      if (!resourceMembers?.includes(mem)) {
        nonResourceMembers.push(mem);
      }
    });

    setNonRscMembers(nonResourceMembers);
  }, [data?.members, parentIndex, template]);
  return (
    <div className="group">
      {editMode && canEdit ? (
        <WorkflowEditStepCard
          currentMembers={data?.members}
          setNonRscMembers={setNonRscMembers}
          nonRscMembers={nonRscMembers}
          setAllDateInfo={setAllDateInfo}
          allDateInfo={allDateInfo}
          data={data}
          toggleEdit={toggleEdit}
          handleEditStep={handleEditStep}
          handleDeleteStep={handleDeleteStep}
          index={index}
          parentIndex={parentIndex}
          isParallel={isParallel}
          allMembers={allMembers}
          totalDuration={totalDuration}
          workflowData={workflowData}
          hideDueDate={hideDueDate}
          startDate={startDate}
        />
      ) : (
        <>
          <div className="relative flex flex-col" ref={drop}>
            {workflowData?.status === RESUBMISSION_WF_STEP &&
              workflowData.currentStep !== parentIndex && (
                <div className="absolute h-full bg-white left-0 right-0 top-0 opacity-40 z-10" />
              )}
            {(index > 0 || isParallel) && (
              <>
                <div className={stepGroupingMidCN(cardStatus)} />
                <div className={stepGroupingBottomCN(cardStatus)} />
              </>
            )}
            {multiple && (
              <div className="absolute flex items-center -left-2 h-full top-0 mt-0.5">
                <div className={stepGroupingBallCN(cardStatus)} />
              </div>
            )}
            <div
              className={cardGroupingCN(
                multiple,
                index,
                canEdit,
                last,
                cardStatus,
                isParallel
              )}
            />
            <div
              ref={canEdit && ref}
              className={cardWrapperCN(completedStep, cardStatus)}
            >
              <div
                className="flex flex-row"
                onClick={() => {
                  if (!canEdit) {
                    setIsOpen((prev) => !prev);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !canEdit) {
                    setIsOpen((prev) => !prev);
                  }
                }}
                tabIndex={0}
                role="button"
              >
                {viewing && (
                  <div className="mt-1 mr-4">
                    <IconButton
                      icon={arrayIcon()}
                      className={arrowCN(isOpen)}
                    />
                  </div>
                )}
                <div className="w-5 mr-5 select-none">
                  {(workflowData?.currentStep > parentIndex || completedStep) &&
                    !canEdit && (
                      <div className="bg-brandGreen rounded-full w-6 h-6 flex items-center justify-center mr-4">
                        <img
                          src={checkmark}
                          alt="check mark"
                          className="w-5 h-5"
                        />
                      </div>
                    )}
                  {workflowData?.status === RESUBMISSION_WF_STEP &&
                    needsResubmition &&
                    !canEdit && (
                      <div className="bg-accessibleRed rounded-full w-6 h-6 flex items-center justify-center mr-4">
                        <img
                          src={resubmissionBlackIcon}
                          alt="check mark"
                          className="w-6 h-6"
                        />
                      </div>
                    )}
                </div>
                <div className="flex-1 ml-2 pr-8 border-r-2">
                  <div className="flex">
                    <h2 className="mr-2 -ml-4 text-lg font-semibold text-gray-400">
                      {stepName}
                    </h2>
                    <div className="h-full">
                      <p className="text-lg text-gray-300 max-w-2xl">
                        {data.description}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col flex-1 pl-8">
                  {!hideDueDate && (
                    <div className="flex flex-col">
                      <div className="flex justify-start text-lg text-gray-300">
                        <span
                          className="font-medium"
                          style={{ width: "100px" }}
                        >
                          Due on:&nbsp;
                        </span>
                        <span className="pl-4">
                          {handleGetDueOn(data?.dueDate)}
                        </span>
                      </div>

                      <div className="flex items-center mt-1">
                        {dueDateInfo.pastDue && (
                          <span className="">
                            {Math.abs(dueDateInfo.duration)} overdue
                          </span>
                        )}
                        {!dueDateInfo.pastDue && (
                          <span className="">
                            {dueDateInfo.duration} remaining
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  {data.needOnlyOneAdvancer && data.members?.length > 1 && (
                    <div className="bg-gray-500 text-white text-xs px-3 py-1 rounded-full my-4 w-min whitespace-nowrap">
                      ONE ADVANCER REQUIRED
                    </div>
                  )}

                  {data?.members?.length > 0 && (
                    <div className="flex items-center justify-between mt-4 w-full">
                      <div
                        className="flex items-center h-full font-medium"
                        style={{ width: "10rem" }}
                      >
                        <span className="text-lg text-gray-300">Assignees</span>
                      </div>
                      <div className="w-full pl-4">
                        {data?.members?.length > 0 &&
                          data?.members?.map((mem, memberIndex) => {
                            return (
                              <div
                                aria-label="div as button"
                                // eslint-disable-next-line no-underscore-dangle
                                key={mem._id || mem.id || mem.reference}
                                className="flex items-center"
                                onClick={(e) => e.stopPropagation()}
                                onKeyDown={(e) => e.stopPropagation()}
                                tabIndex={0}
                                role="button"
                              >
                                <WorkflowStepAction
                                  data={{
                                    ...(mem.userData
                                      ? {
                                          ...mem,
                                          value: mem.userData?.id,
                                          label: getFullName(
                                            mem?.userData?.name
                                          ),
                                          reference: mem?.reference,
                                          ...formatSelectUser({
                                            ...(mem.userData ?? {}),
                                            role: mem.userData?.roleName,
                                          }),
                                        }
                                      : mem),
                                  }}
                                  stepData={data}
                                  memberIndex={memberIndex}
                                  handleAdvanceStep={handleAdvanceStep}
                                  handleResubmitStep={handleResubmitStep}
                                  handleSendReminder={handleSendReminder}
                                  parentIndex={parentIndex}
                                  workflowData={workflowData}
                                  isOpen={isOpen}
                                  currentUser={currentUser}
                                  hideName={false}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}
                  {nonRscMembers?.length > 0 && (
                    <div className="flex w-full">
                      <div className="flex flex-col w-full">
                        {nonRscMembers?.map((mem) => {
                          return (
                            <QuickAddToResource
                              key={mem}
                              currentMembers={data?.members}
                              setNonRscMembers={setNonRscMembers}
                              addMember={mem}
                              currentUser={currentUser}
                              projectId={
                                workflowData?.project?.split("/")[1] ?? false
                              }
                              propertyId={
                                workflowData?.property?.split("/")[1] ?? false
                              }
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex items-start justify-end w-24">
                  {canEdit && (
                    <>
                      <div className="mr-2">
                        <IconButton
                          icon={deleteGreeenIcon}
                          imgClassName="w-5 h-5"
                          className="mr-2"
                          onClick={() => setIsDeleteModalOpen(true)}
                        />
                      </div>
                      <div>
                        <IconButton
                          icon={editIcon}
                          imgClassName="w-6 h-6"
                          onClick={toggleEdit}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              {!canEdit && (
                <div className="border-t pt-2">
                  <SecondaryHeader>Attachments</SecondaryHeader>
                  <div className="flex mt-6 -ml-5 items-center">
                    {workflowData?.status !== RESOLVED_WF &&
                      workflowData?.status !== RESUBMISSION_WF_STEP && (
                        <>
                          <TertiaryButton
                            title="+ Add Attachment"
                            onClick={() => setIsUploadOpen(true)}
                          />
                        </>
                      )}
                    {data.documents
                      ?.filter((doc) => doc.reference)
                      .map((doc) => {
                        return (
                          <div key={doc.id} className="mr-3">
                            <FileCard
                              {...doc}
                              docType={getDocType(doc.docType)}
                              createdAt={
                                doc.metadata?.createdAt
                                  ? moment(doc.metadata.createdAt).format(
                                      "MM/DD/YYYY"
                                    )
                                  : "--"
                              }
                              style={fileCardMiniStyle}
                              onFavoriteClick={() => {
                                handleFavoriteClick(doc.id);
                              }}
                              onFileDelete={() => {
                                handleDeleteFile(doc.id);
                              }}
                              onFileClick={() => {
                                if (!canEdit) {
                                  setClickedDoc(doc);
                                  handleFileClick();
                                }
                              }}
                              onDownloadFile={() => {
                                handleFileDownload(doc);
                              }}
                              hideButtons={canEdit}
                              isMiniature
                            />
                          </div>
                        );
                      })}
                    <ReactModal
                      style={modalStyles}
                      isOpen={showFileModal}
                      onRequestClose={() => setShowFileModal(false)}
                      shouldCloseOnOverlayClick
                      shouldCloseOnEsc={false}
                    >
                      <WorkflowFileView
                        currentDoc={clickedDoc}
                        handleAddFiles={handleUploadFiles}
                        data={data}
                        handlePostComment={handlePostComment}
                        handlePostReply={handlePostReply}
                        parentIndex={parentIndex}
                        cardStatus={cardStatus}
                        workflowData={workflowData}
                        canAdvance={canAdvance}
                        currentFileRef={selectedFile}
                        handleAdvanceStep={handleAdvanceStep}
                        handleResubmitStep={handleResubmitStep}
                        expanded={expanded}
                        onSetExpanded={setExpanded}
                        onCloseExternalModal={() => setShowFileModal(false)}
                        onDownload={handleFileDownload}
                      />
                    </ReactModal>
                    {isUploadOpen && (
                      <div
                        className="absolute h-80 bg-white z-20 left-0 right-0 mx-auto top-4 max-w-3xl"
                        style={{ width: "95%" }}
                      >
                        <WorkflowFileForm
                          files={files}
                          dispatch={dispatch}
                          handleCloseUploader={handleCloseUploader}
                          handleFileUpload={handleFileUpload}
                          showSpinner={showSpinner}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {isOpen && (
                <WorkflowFileView
                  handleAddFiles={handleUploadFiles}
                  data={data}
                  handlePostComment={handlePostComment}
                  handlePostReply={handlePostReply}
                  parentIndex={parentIndex}
                  cardStatus={cardStatus}
                  workflowData={workflowData}
                  canAdvance={canAdvance}
                  currentFileRef={selectedFile}
                  handleAdvanceStep={handleAdvanceStep}
                  handleResubmitStep={handleResubmitStep}
                  onDownload={handleFileDownload}
                  commentsOnly
                />
              )}
            </div>
          </div>
          {last &&
            canEdit &&
            workflowData?.status !== RESOLVED_WF &&
            workflowData?.status !== RESUBMISSION_WF_STEP && (
              <>
                <div className="mb-4 relative">
                  <TertiaryButton
                    className="text-lg font-medium"
                    title="+ Add Parallel Step"
                    onClick={() => handleAddParallelStep(parentIndex)}
                  />

                  <div className="absolute -bottom-1 -left-5 border-gray-150 border-l-2 border-b-2 group-hover:border-borderGreen w-3 h-16 rounded-b-sm mb-6" />
                </div>
              </>
            )}
        </>
      )}
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onDelete={handleModalDelete}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete Workflow Step?"
        text="Are you sure that you want to delete this step? Once deleted, the step cannot be recovered."
      />
    </div>
  );
};

WorkflowStepCard.propTypes = {
  /**
   * data needed to display wrkflow steps
   */
  data: PropTypes.shape({
    duration: PropTypes.number,
    needOnlyOneAdvancer: PropTypes.bool,
    position: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    documents: PropTypes.arrayOf(PropTypes.any),
    id: PropTypes.string,
    newStep: PropTypes.bool,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        reference: PropTypes.string,
        userData: PropTypes.shape({
          name: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
          }),
        }),
      })
    ),
    name: PropTypes.string,
    description: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    comments: PropTypes.arrayOf(PropTypes.any),
    dueDate: PropTypes.string,
  }),
  /**
   * index of current step
   */
  index: PropTypes.number,
  /**
   * index of parent group of steps
   */
  parentIndex: PropTypes.number,
  /**
   * boolean used to determine if this group of steps contains multiple steps
   */
  multiple: PropTypes.bool,
  /**
   * boolean used to determine if a step is the last of its group
   */
  last: PropTypes.bool,
  /**
   * function used to add a parallel step to a workflow
   */
  handleAddParallelStep: PropTypes.func,
  /**
   * function used to edit a step in the workflow
   */
  handleEditStep: PropTypes.func,
  /**
   * function used to delete a step on the workflow
   */
  handleDeleteStep: PropTypes.func,
  /**
   * boolean to determine if steps can be edited
   */
  canEdit: PropTypes.bool,
  /**
   * function used to move steps after they have been dragged and dropped
   */
  moveColumn: PropTypes.func,
  /**
   * value of current template selected
   */
  template: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    steps: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  /**
   * boolean to determine if the workflow steps are being viewed or edited
   */
  viewing: PropTypes.bool,
  /**
   * information about the overall workflow
   */
  workflowData: PropTypes.shape({
    metadata: PropTypes.shape({
      version: PropTypes.string,
      dataUse: PropTypes.string,
      createdBy: PropTypes.string,
      location: PropTypes.string,
      tenant: PropTypes.string,
      lastUpdated: PropTypes.string,
      createdAt: PropTypes.string,
      userData: PropTypes.shape({
        name: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      }),
    }),
    project: PropTypes.string,
    property: PropTypes.string,
    relationships: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      children: PropTypes.arrayOf(PropTypes.any),
    }),
    resource: PropTypes.string,
    id: PropTypes.string,
    reference: PropTypes.string,
    version: PropTypes.number,
    isDraft: PropTypes.bool,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      })
    ),
    status: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    documents: PropTypes.arrayOf(PropTypes.any),
    dateCreated: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    startDate: PropTypes.object,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        resubmission: PropTypes.bool,
        duration: PropTypes.number,
        needOnlyOneAdvancer: PropTypes.bool,
        position: PropTypes.number,
        // eslint-disable-next-line react/forbid-prop-types
        documents: PropTypes.arrayOf(PropTypes.any),
        id: PropTypes.string,
        members: PropTypes.arrayOf(
          PropTypes.shape({
            _id: PropTypes.string,
            reference: PropTypes.string,
            status: PropTypes.string,
            date: PropTypes.string,
            userData: PropTypes.shape({
              name: PropTypes.shape({
                firstName: PropTypes.string,
                lastName: PropTypes.string,
              }),
            }),
          })
        ),
        // eslint-disable-next-line react/forbid-prop-types
        parallelSteps: PropTypes.arrayOf(PropTypes.any),
        name: PropTypes.string,
        description: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types
        comments: PropTypes.arrayOf(PropTypes.any),
        dueDate: PropTypes.string,
      })
    ),
    // eslint-disable-next-line react/forbid-prop-types
    associatedWorkflows: PropTypes.arrayOf(PropTypes.any),
    name: PropTypes.string,
    cost: PropTypes.string,
    endDate: PropTypes.string,
    currentStep: PropTypes.number,
  }),
  /**
   * function to handle a member requesting a resubmission on a step
   */
  handleMemberResubmission: PropTypes.func,
  /**
   * function to handle a member advancing a step
   */
  handleMemberAdvance: PropTypes.func,
  /**
   * boolean to determine if this steo is a parallel step
   */
  isParallel: PropTypes.bool,
  /**
   * all members selected for this workflow
   */
  allMembers: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  /**
   * total days passed since last step
   */
  totalDuration: PropTypes.number,
  /**
   * handles posting a comment
   */
  handlePostComment: PropTypes.func,
  /**
   * handles posting a reply
   */
  handlePostReply: PropTypes.func,
  /**
   * handles adding file
   */
  handleAddFiles: PropTypes.func,
  handleAddReminder: PropTypes.func,
  currentRef: PropTypes.string,
  /**
   * handles default card view
   */
  minIndex: PropTypes.number,
  unselectCompletedCard: PropTypes.func,
  hideDueDate: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  startDate: PropTypes.object,
  currentUser: PropTypes.shape({}),
};

WorkflowStepCard.defaultProps = {
  data: [],
  index: undefined,
  parentIndex: undefined,
  multiple: undefined,
  last: undefined,
  handleAddParallelStep: undefined,
  handleEditStep: undefined,
  handleDeleteStep: undefined,
  canEdit: undefined,
  moveColumn: undefined,
  template: undefined,
  viewing: undefined,
  handleMemberResubmission: undefined,
  handleMemberAdvance: undefined,
  workflowData: {},
  isParallel: undefined,
  allMembers: undefined,
  totalDuration: undefined,
  handlePostComment: undefined,
  handlePostReply: undefined,
  handleAddFiles: undefined,
  handleAddReminder: undefined,
  currentRef: undefined,
  minIndex: undefined,
  unselectCompletedCard: undefined,
  hideDueDate: false,
  startDate: undefined,
  currentUser: undefined,
};

export default WorkflowStepCard;

import { useReducer } from "react";
import { cloneDeep } from "lodash";

const defaultState = {};

const reducer = (actualFinancial, action) => {
  switch (action.type) {
    case "reset":
      return cloneDeep(action.actualFinancial) ?? defaultState;
    case "categoryNumber":
      return {
        ...actualFinancial,
        categoryNumber: action.categoryNumber,
      };
    case "categoryName": {
      return {
        ...actualFinancial,
        categoryName: action.categoryName,
      };
    }
    case "subcategoryName": {
      return {
        ...actualFinancial,
        subcategoryName: action.subcategoryName,
      };
    }
    case "description":
      return {
        ...actualFinancial,
        description: action.description,
      };
    case "projectName":
      return {
        ...actualFinancial,
        projectName: action.projectName,
      };
    case "accountType": {
      return {
        ...actualFinancial,
        accountType: action.accountType,
      };
    }
    case "forecastAmount":
      return {
        ...actualFinancial,
        forecastAmount: action.forecastAmount,
      };
    case "actualAmount":
      return {
        ...actualFinancial,
        actualAmount: action.actualAmount,
      };
    case "varianceAmount": {
      return {
        ...actualFinancial,
        varianceAmount: action.varianceAmount,
      };
    }
    case "variancePercentage": {
      return {
        ...actualFinancial,
        variancePercentage: action.variancePercentage,
      };
    }
    case "paymentDate": {
      return {
        ...actualFinancial,
        paymentDate: action.paymentDate,
      };
    }
    case "paymentAmount":
      return {
        ...actualFinancial,
        paymentAmount: action.paymentAmount,
      };
    case "balance": {
      return {
        ...actualFinancial,
        balance: action.balance,
      };
    }
    case "interest": {
      return {
        ...actualFinancial,
        interest: action.interest,
      };
    }
    case "recurring": {
      return {
        ...actualFinancial,
        recurring: action.recurring,
      };
    }
    case "invoiceAmount":
      return {
        ...actualFinancial,
        invoiceAmount: action.invoiceAmount,
      };
    case "invoiceSentDate":
      return {
        ...actualFinancial,
        invoiceSentDate: action.invoiceSentDate,
      };
    case "invoicePaidDate":
      return {
        ...actualFinancial,
        invoicePaidDate: action.invoicePaidDate,
      };
    case "profit":
      return {
        ...actualFinancial,
        profit: action.profit,
      };
    case "grossMargin":
      return {
        ...actualFinancial,
        grossMargin: action.grossMargin,
      };
    case "depreciationAmount":
      return {
        ...actualFinancial,
        depreciationAmount: action.depreciationAmount,
      };
    case "depreciationPercent":
      return {
        ...actualFinancial,
        depreciationPercent: action.depreciationPercent,
      };
    case "depreciationValue":
      return {
        ...actualFinancial,
        depreciationValue: action.depreciationValue,
      };
    case "equityAmount":
      return {
        ...actualFinancial,
        equityAmount: action.equityAmount,
      };
    case "equityPercent":
      return {
        ...actualFinancial,
        equityPercent: action.equityPercent,
      };
    case "escalatorPercent":
      return {
        ...actualFinancial,
        escalatorPercent: action.escalatorPercent,
      };
    case "notes":
      return {
        ...actualFinancial,
        notes: action.notes,
      };
    default:
      return actualFinancial;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};

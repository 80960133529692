import cntl from "cntl";

const listCN =
  "flex flex-row gap-2 text-es-normal text-es-dark-grey font-es-normal tracking-es-normal";
const titleCN = "text-es-dark-grey font-es-semibold mx-auto text-left";
const titleStyles = {
  letterSpacing: "1.88px",
  fontSize: "38px",
  lineHeight: "46.32px",
  width: "1142px",
  paddingBottom: "45px",
};
const container = "flex flex-row border shadow-light-lift mx-auto gap-8";
const containerStyles = {
  width: "1142px",
  height: "722px",
  borderRadius: "10px",
};
const inputLabel =
  "text-es-medium-grey font-es-bold text-es-normal pb-1 tracking-es-wide";
const asteriskCN =
  "text-brandGreen text-es-normal font-es-bold tracking-es-wide";
const inputStyle = {
  height: "44px",
  width: "360px",
  paddingRight: "60px",
  paddingLeft: "18px",
};
const visibilityIconStyle = {
  position: "absolute",
  right: "110",
  top: "69%",
  transform: "translateY(-50%)",
};
const rightContainerStyle = {
  borderTopRightRadius: "10px",
  borderBottomRightRadius: "10px",
  padding: "40px 65px 40px 65px",
};
const logoStyles = { width: "409px", height: "345px" };
const bulletedListStyles = { width: "438px", height: "266px" };
const buttonStyle = { height: "44px", width: "193px" };

const fadeInContainer = (isLoaded = false) => cntl`
  transition-opacity
  ease-in
  duration-500
  ${isLoaded ? "opacity-100" : "opacity-0"}
`;
export {
  listCN,
  titleCN,
  titleStyles,
  container,
  containerStyles,
  inputLabel,
  asteriskCN,
  inputStyle,
  visibilityIconStyle,
  rightContainerStyle,
  logoStyles,
  bulletedListStyles,
  buttonStyle,
  fadeInContainer,
};

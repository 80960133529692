import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useHistory } from "react-router";

import DocumentReviewElement from "../DocumentReviewElement/DocumentReviewElement";
import Checkbox from "../Checkbox/Checkbox";

import {
  SIMPLE_INPUT,
  LIST_ADD_ONE,
  LIST_REMOVE_ONE,
  GET_PROJECT_DOCUMENTS_DOC_PATH,
} from "../../../constants";
import AssociatedDocumentsDisplay from "./AssociatedDocumentsDisplay";

const PurchaseOrderReview = ({
  document,
  dispatch,
  userData,
  associatedResource,
  setAssociatedResource,
  disableAssociation,
  disableEdit,
  editMode,
  documentsDict,
  projectId,
}) => {
  const history = useHistory();
  const [originalValues, setOriginalValues] = useState({});
  const [showRecipients] = useState(true);
  const [associatedDocuments, setAssociatedDocuments] = useState([]);

  useEffect(() => {
    const allDocs =
      document?.documentRelationships?.children.map((ref) => {
        const doc = documentsDict[ref];
        return {
          name: doc?.name,
          reference: ref,
          status: doc?.status,
        };
      }) || [];

    if (document?.documentRelationships?.parent) {
      const doc = documentsDict[document?.documentRelationships?.parent];
      allDocs.push({
        name: doc?.name,
        reference: doc?.reference,
        status: doc?.status,
      });
    }
    setAssociatedDocuments(allDocs.filter((doc) => doc?.status !== "draft"));
  }, [document, documentsDict]);

  useEffect(() => {
    if (
      !originalValues.qualificationsAndExclusions &&
      document?.qualificationsAndExclusions
    ) {
      setOriginalValues((prev) => {
        return {
          ...prev,
          qualificationsAndExclusions: document.qualificationsAndExclusions,
        };
      });
    }
    if (!originalValues.scheduleImpacts && document?.scheduleImpacts) {
      setOriginalValues((prev) => ({
        ...prev,
        scheduleImpacts: document.scheduleImpacts,
      }));
    }
  }, [
    document?.qualificationsAndExclusions,
    document?.scheduleImpacts,
    originalValues,
  ]);

  const handleSimpleInput = React.useCallback(
    (key, value) => {
      dispatch({
        type: SIMPLE_INPUT,
        key,
        value,
      });
    },
    [dispatch]
  );

  const handleUserListAdd = React.useCallback(
    (key, val) => {
      val.reverse().forEach(({ value }) => {
        dispatch({
          type: LIST_ADD_ONE,
          key,
          value: { id: value },
        });
      });
    },
    [dispatch]
  );

  const handleListAdd = React.useCallback(
    (key, value, append) => {
      dispatch({
        type: LIST_ADD_ONE,
        key,
        value,
        append,
      });
    },
    [dispatch]
  );

  const handleListRemove = React.useCallback(
    (key, value) => {
      dispatch({
        type: LIST_REMOVE_ONE,
        key,
        value,
      });
    },
    [dispatch]
  );

  const handleSaveUpdate = React.useCallback(
    (key, val) => {
      setOriginalValues((prev) => {
        if (Object.keys(prev).includes(key)) {
          const update = document[key];
          if (val && Array.isArray(update)) {
            update.push(val);
          }
          return { ...prev, [key]: update };
        }
        return prev;
      });
    },
    [document]
  );

  const handleCancelUpdate = React.useCallback(
    (key) => {
      const original = originalValues[key];
      if (original) {
        handleSimpleInput(key, original);
      }
    },
    [originalValues, handleSimpleInput]
  );

  const getListDisplay = React.useCallback((list, override) => {
    return (
      <div className="flex flex-col">
        {list
          ?.filter(({ checked }) => checked !== false || override)
          .map(({ text }) => (
            <div className="flex items-center mb-2" key={text}>
              {text}
            </div>
          ))}
      </div>
    );
  }, []);

  const handleDocumentClick = useCallback(
    (ref) => {
      history.push(
        GET_PROJECT_DOCUMENTS_DOC_PATH(projectId, ref.split("/")[1])
      );
    },
    [history, projectId]
  );

  const getDueDateDisplay = React.useCallback(() => {
    if (!document.dueDate?.date) return "";
    const dateString = moment(document.dueDate.date).format("MMMM Do, YYYY");
    const timeString = document.dueDate.allDay
      ? "End Of Day"
      : moment(document.dueDate.date).format("hh:mm A");
    return `${dateString} - ${timeString}`;
  }, [document.dueDate]);

  const scheduleImpacts = React.useMemo(
    () => (
      <div className="flex flex-col mt-2">
        <div className="flex flex-col">
          <div className="flex items-center mb-2">
            <Checkbox
              className=""
              label="Yes"
              checked={document.scheduleImpacts === "Yes"}
              onChange={(checked) =>
                handleSimpleInput(
                  "scheduleImpacts",
                  checked ? "Yes" : undefined
                )
              }
            />
          </div>
          <div className="flex items-center mb-2">
            <Checkbox
              className=""
              label="No"
              checked={document.scheduleImpacts === "No"}
              onChange={(checked) =>
                handleSimpleInput("scheduleImpacts", checked ? "No" : undefined)
              }
            />
          </div>
          <div className="flex items-center mb-2">
            <Checkbox
              className=""
              label="TBD"
              checked={document.scheduleImpacts === "TBD"}
              onChange={(checked) =>
                handleSimpleInput(
                  "scheduleImpacts",
                  checked ? "TBD" : undefined
                )
              }
            />
          </div>
        </div>
      </div>
    ),
    [document.scheduleImpacts, handleSimpleInput]
  );

  const sectionOne = React.useMemo(() => {
    return [
      {
        title: "Project",
        type: "ASSOCIATION",
        displayValue: associatedResource?.name,
        editValue: document.project ?? document.property,
        onChangeAlt: setAssociatedResource,
        associatedResource,
        disableAssociation,
        disableEdit,
      },
      {
        title: "Association",
        type: "CUSTOM",
        displayValue: (
          <AssociatedDocumentsDisplay
            associatedDocuments={associatedDocuments}
            handleDocumentClick={handleDocumentClick}
          />
        ),
        disableEdit: true,
      },
      {
        title: "Due Date",
        type: "DUEDATE",
        displayValue: getDueDateDisplay(),
        editValue: document.dueDate,
        onChange: (val) => handleSimpleInput("dueDate", val),
        disableEdit,
      },
      {
        title: "Title",
        type: "INPUT",
        displayValue: document.customName,
        editValue: document.customName,
        onChange: (val) => handleSimpleInput("customName", val),
        disableEdit: disableEdit || editMode,
      },
      {
        title: "Description",
        type: "TEXTAREA",
        displayValue: document.description,
        editValue: document.description,
        onChange: (val) => handleSimpleInput("description", val),
        disableEdit,
      },
    ];
  }, [
    associatedResource,
    document.project,
    document.property,
    document.dueDate,
    document.customName,
    document.description,
    setAssociatedResource,
    disableAssociation,
    disableEdit,
    associatedDocuments,
    handleDocumentClick,
    getDueDateDisplay,
    editMode,
    handleSimpleInput,
  ]);

  const sectionTwo = React.useMemo(() => {
    return [
      ...(showRecipients
        ? [
            {
              title: "Recipients",
              type: "USERSELECT",
              options: userData,
              selectedOptions: document.distro,
              onChange: (val) => handleUserListAdd("distro", val),
              onDelete: (val) => handleListRemove("distro", val),
              disableEdit,
            },
          ]
        : []),
      {
        title: "Impact To Schedule",
        type: "CUSTOM",
        displayValue: document.scheduleImpacts,
        editField: scheduleImpacts,
        onSave: (val) => handleSaveUpdate("scheduleImpacts", val),
        onCancel: () => handleCancelUpdate("scheduleImpacts"),
        disableEdit,
      },
      {
        title: "Qualifications & Exclusions",
        type: "CRITERIA",
        displayValue: getListDisplay(document.qualificationsAndExclusions),
        editValue: document.qualificationsAndExclusions,
        onChange: (val) =>
          handleListAdd("qualificationsAndExclusions", val, true),
        onSave: (val) => handleSaveUpdate("qualificationsAndExclusions", val),
        onCancel: () => handleCancelUpdate("qualificationsAndExclusions"),
        onDelete: (val) => handleListRemove("qualificationsAndExclusions", val),
        disableEdit,
      },
    ];
  }, [
    document,
    userData,
    handleUserListAdd,
    handleListAdd,
    handleListRemove,
    handleCancelUpdate,
    handleSaveUpdate,
    scheduleImpacts,
    getListDisplay,
    disableEdit,
    showRecipients,
  ]);

  return (
    <>
      <div className="flex justify-between">
        <div className="flex-1 flex flex-col pr-4">
          {sectionOne.map((item) => (
            <DocumentReviewElement key={item.title} {...item} />
          ))}
        </div>
        <div className="flex-1 flex flex-col">
          {sectionTwo.map((item) => (
            <DocumentReviewElement key={item.title} {...item} />
          ))}
        </div>
      </div>
    </>
  );
};

PurchaseOrderReview.propTypes = {
  /**
   * create document form state
   */
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.object,
  /**
   * create document form dispatcher
   */
  dispatch: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  userData: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  associatedResource: PropTypes.object,
  disableEdit: PropTypes.bool,
  disableAssociation: PropTypes.bool,
  setAssociatedResource: PropTypes.func,
  editMode: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  documentsDict: PropTypes.object,
  projectId: PropTypes.string,
};

PurchaseOrderReview.defaultProps = {
  document: {},
  dispatch: undefined,
  userData: undefined,
  associatedResource: undefined,
  disableEdit: false,
  disableAssociation: false,
  setAssociatedResource: undefined,
  editMode: false,
  documentsDict: {},
  projectId: undefined,
};

export default PurchaseOrderReview;

import React from "react";
import PlusCircleButton from "../../../Buttons/PlusCircleButton/PlusCircleButton";
import Checkbox from "../../../Checkbox/Checkbox";
import Step from "../../../Step";
import TaskStepText from "./TaskStepText";
import useSopStepStatusData from "./useSopStepStatusData";

const labelWidth = { minWidth: "12.5rem" };

const TaskStepsSection = ({
  editingMode,
  isModal,
  sopsForTask,
  taskForm,
  currentTask,
  setCurrentTask,
  sopOptions,
  handleStepAdd,
  handleStepEdit,
  handleStepRemove,
  noTitle,
}) => {
  const { handleCompleteCheck, isAllChecked } = useSopStepStatusData(
    sopsForTask,
    currentTask,
    setCurrentTask
  );

  return (
    <div
      className={`flex flex-${
        (editingMode && isModal) ||
        (!isModal && (!sopsForTask?.length || editingMode))
          ? "row"
          : "col"
      } w-full pt-4`}
    >
      {!noTitle && (
        <p style={labelWidth} className="font-semibold text-gray-450 text-md">
          Steps
        </p>
      )}
      {!editingMode && !sopsForTask?.length && (
        <p className="text-sm text-gray-450 whitespace-nowrap">
          There are no steps saved to this task
        </p>
      )}
      <div
        className={`flex flex-${isModal ? "col" : "row"} w-full ${
          !editingMode && "pt-3"
        }`}
      >
        {!editingMode && sopsForTask?.length > 0 && (
          <div className="flex mb-6">
            <Checkbox
              className="flex items-start h-4 mr-2 mt-0.5"
              size={6}
              checked={isAllChecked}
              disabled={currentTask?.status === "done"}
              onChange={() => handleCompleteCheck(!isAllChecked)}
            />
            <p className="text-gray-450 text-sm whitespace-nowrap">
              Select All
            </p>
          </div>
        )}
        <div
          className={`flex flex-col w-full ${
            !editingMode && !isModal && "ml-24"
          }`}
        >
          {!editingMode &&
            sopsForTask?.map((sop, index) => (
              <TaskStepText
                key={sop.id}
                sop={sop}
                index={index}
                currentTask={currentTask}
                setCurrentTask={setCurrentTask}
              />
            ))}
          {editingMode && (
            <div className="flex flex-col">
              {taskForm.steps?.map((sop, index) => {
                return (
                  <div key={sop.id} className="mb-2 mr-2">
                    <Step
                      index={index}
                      key={`${sop.id}-step`}
                      sop={sop}
                      form={taskForm}
                      handleAddStep={handleStepAdd}
                      handleStepEdit={handleStepEdit}
                      handleStepRemove={handleStepRemove}
                      sopOptions={sopOptions?.options || []}
                    />
                  </div>
                );
              })}
              {editingMode && (
                <PlusCircleButton
                  title="Add Step"
                  className="mt-0"
                  titleClassName="text-darkenedGreen font-semibold"
                  // if style is false overrides titleClassName
                  style={{}}
                  onClick={handleStepAdd}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskStepsSection;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SecondaryButton from "../Buttons/SecondaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import Input from "../Input/Input";
import Avatar from "../Message/Avatar";

const NewReply = ({
  name,
  replyTo,
  handleCancelReply,
  handleSendReply,
  user,
}) => {
  const [reply, setReply] = useState("");
  useEffect(() => {
    setReply(`@${replyTo} `);
  }, [replyTo]);
  const cancelReply = () => {
    setReply("");
    handleCancelReply();
  };
  const handleReply = () => {
    handleSendReply(reply);
  };
  return (
    <div className="border-2 rounded-lg flex flex-col p-4 mb-4">
      <div className="flex flex-row mb-6 items-center">
        <Avatar
          className="mr-4 rounded-full"
          name={name}
          id={user?.id ?? user?.reference}
          isExternalUser
          disableTooltip
        />
        <div className="flex flex-col">
          <h2 className="font-semibold text-gray-400">{name}</h2>
        </div>
      </div>
      <Input
        placeholder="Comment"
        value={reply}
        onChange={setReply}
        className="mb-4"
        handleEnter={(event) => {
          if (event.key === "Enter") handleReply();
        }}
        autoFocus
      />
      <div className="flex flex-row justify-end mb-4">
        <TertiaryButton title="Cancel" className="mr-2" onClick={cancelReply} />
        <SecondaryButton title="Reply" onClick={handleReply} />
      </div>
    </div>
  );
};

NewReply.propTypes = {
  name: PropTypes.string,
  replyTo: PropTypes.string,
  handleCancelReply: PropTypes.func,
  handleSendReply: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.string,
    reference: PropTypes.string,
  }),
};

NewReply.defaultProps = {
  name: undefined,
  replyTo: undefined,
  handleCancelReply: undefined,
  handleSendReply: undefined,
  user: undefined,
};
export default NewReply;

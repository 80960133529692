import moment from "moment";

// return rage of dates fully spelled out
// ex: April 16, 2024 to April 23, 2024
const getDateRange = (dates = []) => {
  if (!Array.isArray(dates)) return "";

  const datesLength = dates?.length;
  const firstDay = dates[0]?.date?.split("T")[0];
  const lastDay = dates[datesLength - 1]?.date?.split("T")[0];

  let dateRange = "";
  if (datesLength === 1) {
    dateRange = moment(firstDay).format("LL");
  }
  if (datesLength > 1) {
    dateRange = `${moment(firstDay).format("LL")} to ${moment(lastDay).format(
      "LL"
    )}`;
  }
  return dateRange;
};

export default getDateRange;

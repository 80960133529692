import { useMutation } from "react-query";
import { NotificationAPI } from "@griffingroupglobal/eslib-api";
import { useCallback } from "react";
import { cloneDeep } from "lodash";
import { toastError } from "../helpers/Toast";
import queryClient from "../config/reactQuery/queryClient";
import { notificationCodeKeys } from "../config/reactQuery/queryKeyFactory";

export default () => {
  const patch = useCallback(async (notifs, command) => {
    const { notifications, indexList } = notifs.reduce(
      (list, item) => {
        if (item.type === "Notification") {
          list.notifications.push(item.reference);
          return list;
        }
        list.threats.push(item);
        return list;
      },
      { notifications: [] }
    );

    let notfiResponse = { data: { updated: [] } };

    if (notifications.length > 0) {
      notfiResponse = await NotificationAPI.postWOP(command, {
        notifications,
      });
    }

    return {
      data: [...notfiResponse.data.updated],
      indexList,
      command,
    };
  }, []);

  return useMutation({
    mutationFn: (payload) => {
      return patch(payload[0], payload[1]);
    },
    onMutate: async ([notifs, command]) => {
      await queryClient.cancelQueries(notificationCodeKeys);
      const previousData = queryClient.getQueryData(notificationCodeKeys);
      const indexList = notifs.map((item) => item.index);
      queryClient.setQueryData(notificationCodeKeys, (prev) => {
        const current = cloneDeep(prev);
        switch (command) {
          case "$flag":
            indexList.forEach((i) => {
              current[i].flagged = true;
            });
            break;
          case "$unflag":
            indexList.forEach((i) => {
              current[i].flagged = false;
            });
            break;
          case "$read":
            indexList.forEach((i) => {
              current[i].status = "read";
              current[i].read = true;
            });
            break;
          case "$unread":
            indexList.forEach((i) => {
              current[i].status = "unread";
              current[i].read = false;
            });
            break;
          case "$dismissed": {
            const indexSet = new Set(indexList);
            return current.reduce((acc, notification) => {
              if (!indexSet.has(notification.index)) {
                acc.push({ ...notification, index: acc.length });
              }
              return acc;
            }, []);
          }
          default:
        }
        return current;
      });

      return previousData;
    },
    onError: (error, _, prev) => {
      queryClient.setQueryData(notificationCodeKeys, prev[0][1]);
      toastError("There was an error performing this action. Please try again");
      console.error("useNotifications", error);
    },
  });
};

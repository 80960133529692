import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import Table from "../Table/Table";
import CardGrid from "../CardGrid/CardGrid";
import { FILTER_TYPES } from "../../../constants";

const containerCN = () => cntl`
  bg-white
`;

const groupOptions = {
  hierarchicalOptions: [],
  nonHierarchicalOptions: [
    {
      label: "Type",
      value: "type",
    },
  ],
};

const SettingsTable = ({ data, onClick }) => {
  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "title",
      },
      {
        Header: "Type",
        accessor: "type",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ];
  }, []);

  return (
    <div className={containerCN()}>
      <Table
        cardGrid
        cardGridComponent={(rows) => {
          return (
            <div className="pt-1">
              <CardGrid
                resourceName="SettingsAndConfiguration"
                rows={rows}
                onClick={onClick}
              />
            </div>
          );
        }}
        columns={tableColumns}
        data={data}
        onRowClick={onClick}
        groupOptions={groupOptions}
        pagination={24}
      />
    </div>
  );
};

SettingsTable.propTypes = {
  /**
   * data to pass to the table
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.string,
      category: PropTypes.string,
      subcategory: PropTypes.string,
      type: PropTypes.string,
      link: PropTypes.string,
      valuation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  onClick: PropTypes.func,
};

SettingsTable.defaultProps = {
  data: undefined,
  onClick: undefined,
};

export default SettingsTable;

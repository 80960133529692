import React from "react";
import CrossButton from "../../../stories/Components/CrossButton/CrossButton";
import crossBlack from "../../../stories/assets/images/blackCrossIcon.svg";
import "./messagingTray.css";
import AddMessageButton from "./AddMessageButton";
import withStreamChatContext from "../../../context/ChatContext/withStreamChatContext";
import MessagingDirectMessageList from "./DirectMessage/DirectMessageList";
import ExpandListButton from "./ExpandListButton";
import DirectMessageThread from "./DirectMessage/DirectMessageThread";
import MessagingChannelList from "./MessageChannel/MessagingChannelList";
import AddChannelDialog from "./CreateChannel/AddChannelDialog";
import TrayContainer from "../../../stories/Components/TrayContainer";
import useMessagingTrayData from "./useMessagingTrayData";

const MessagingTray = ({ open, close }) => {
  const {
    expanded,
    newMsg,
    setNewMsg,
    newChnl,
    setNewChannel,
    selectedChannel,
    clientChat,
    setViewMedia,
  } = useMessagingTrayData();

  return (
    <TrayContainer isOpen={open} width={expanded.width} height={800}>
      <header className="header flex justify-between items-center px-6">
        <p className="font-semibold">Messages</p>
        <CrossButton icon={crossBlack} onClick={close} />
      </header>
      <div className="messaging-container">
        <div className="flex h-full overflow-hidden w-full">
          <div
            id="channel-bar"
            className={`flex flex-col h-full transition-all bg-gray-450 relative ${
              expanded.value ? "channel-bar-expanded" : "channel-bar"
            }`}
          >
            <AddMessageButton
              expanded={expanded.value}
              onNewMessage={() => {
                setViewMedia(false);
                setNewMsg(true);
              }}
              openChannelCreate={() => {
                setViewMedia(false);
                setNewChannel(true);
              }}
              disabled={!clientChat?.user}
            />
            {newChnl && (
              <AddChannelDialog setOpen={(val) => setNewChannel(val)} />
            )}

            <div id="channel-bar-list" className="flex flex-col gap-1">
              <ExpandListButton onClick={expanded.toggle} />
              <MessagingDirectMessageList expanded={expanded.value} />
              <MessagingChannelList expanded={expanded.value} />
            </div>
          </div>

          {(selectedChannel || newMsg) && (
            <DirectMessageThread
              isTrayOpen={open}
              newMsg={newMsg ?? newChnl}
              expanded={expanded.value}
            />
          )}
        </div>
      </div>
    </TrayContainer>
  );
};

export default withStreamChatContext(MessagingTray);

const getFormattedRecurrence = (value) => {
  let recurrencePlainText = "N/A";
  const recurrence = value || "";

  if (recurrence.includes("DAILY")) {
    recurrencePlainText = "Daily";
  } else if (recurrence.includes("WEEKLY")) {
    recurrencePlainText = "Weekly";
  } else if (recurrence.includes("MONTHLY")) {
    recurrencePlainText = "Monthly";
  } else if (recurrence.includes("YEARLY")) {
    recurrencePlainText = "Yearly";
  }
  return recurrencePlainText;
};

export default getFormattedRecurrence;

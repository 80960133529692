import React from "react";
import PropTypes from "prop-types";
import CrossButton from "../CrossButton/CrossButton";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import HideButton from "../Modal/Buttons/HideButton";
import usePopoverCommentsData from "./usePopoverCommentsData";
import CommentsContainer from "./CommentsContainer";
import { COMMENT_MODAL_HEIGHT, COMMENT_MODAL_WIDTH } from "../../../constants";

const PopoverComments = ({
  isOpen,
  onClose,
  resourceReference,
  association,
  allowMentionAll,
  usersToMention,
}) => {
  const {
    isLoading,
    isReplyMode,
    isMinimized,
    comments,
    replies,
    commentIdToReply,
    mentionSuggestions,
    handlePostComment,
    handlePostReply,
    handleCloseReplyMode,
    handleReply,
    setMinimized,
  } = usePopoverCommentsData({
    resourceReference,
    association,
    allowMentionAll,
    usersToMention,
  });

  return (
    <>
      {isOpen ? (
        <div
          className="fixed bottom-0 right-7 bg-white z-50 border border-gray-light rounded-t-md shadow-lg"
          style={{
            width: isReplyMode
              ? `${COMMENT_MODAL_WIDTH * 2}px`
              : `${COMMENT_MODAL_WIDTH}px`,
            maxHeight: `${COMMENT_MODAL_HEIGHT}px`,
            backgroundColor: "#f9f9f9",
          }}
        >
          {/* Header */}
          <div
            className="rounded-t-md"
            style={{ backgroundColor: "#585858", height: "50px" }}
          >
            <div className="flex flex-row justify-between p-4">
              <span className="text-white font-bold">Comments</span>
              <div className="flex space-x-2">
                <HideButton
                  className="h-6 p-2"
                  animated
                  onClick={() => setMinimized(!isMinimized)}
                  value={isMinimized}
                  color="white"
                />
                <CrossButton onClick={onClose} icon={whiteCrossIcon} />
              </div>
            </div>
          </div>

          <div
            className={`flex content ${isLoading ? "loading" : ""} ${
              isMinimized && "hidden"
            }`}
          >
            {/* Comments Box */}
            <CommentsContainer
              comments={comments}
              onSend={handlePostComment}
              onReplyPressed={handleReply}
              selectedCommentId={commentIdToReply}
              mentionSuggestions={mentionSuggestions}
            />

            {/* Reply Box */}
            {isReplyMode ? (
              <CommentsContainer
                comments={replies}
                useReplyMode
                onSend={handlePostReply}
                onCloseReply={handleCloseReplyMode}
                mentionSuggestions={mentionSuggestions}
              />
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

PopoverComments.defaultProps = {
  isOpen: false,
  resourceReference: undefined,
  association: undefined,
  allowMentionAll: false,
  usersToMention: [],
  onClose: () => {},
};

PopoverComments.propTypes = {
  isOpen: PropTypes.bool,
  resourceReference: PropTypes.string,
  association: PropTypes.string,
  allowMentionAll: PropTypes.bool,
  usersToMention: PropTypes.arrayOf(PropTypes.shape({})),
  onClose: PropTypes.func,
};

export default PopoverComments;

import { TicketAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { ticketsKeys } from "../config/reactQuery/queryKeyFactory";
import { toastError, toastMessage } from "../stories/Components/Toast/Toast";

const patchTicket = async ({ id, requestUpdated, request, isCompletion }) => {
  const patchFn = isCompletion
    ? TicketAPI.postWOP(`${id}/$complete`)
    : TicketAPI.patch(id, requestUpdated, request);

  const { data } = await patchFn;

  return data;
};

/**
 * Update ticket in all possible queries
 * ["tickets"]                                  ===> Holds an array of tickets
 * ["tickets", "Property/1111-2222-3333-4444"]  ===> Holds an array of tickets
 * ["tickets", "id", 3333-6666-4444-8888]       ===> Holds an object (single ticket)
 */
const mutateCache = (queryClient, id, requestUpdated) => {
  queryClient.setQueriesData(ticketsKeys.tickets, (current) => {
    if (Array.isArray(current)) {
      return current.map((ticket) => {
        if (ticket.id === id) {
          return requestUpdated;
        }
        return ticket;
      });
    }

    if (typeof current === "object" && current.id === id) {
      return requestUpdated;
    }

    return current;
  });
};

/**
 * Mutation hook to patch single ticket
 * @param {string} mutationKey (?) mutation key to track api call
 */
const useServiceRequestPatch = (mutationKey) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey,
    mutationFn: patchTicket,
    onMutate: async (variables) => {
      const { id, requestUpdated } = variables ?? {};

      await queryClient.cancelQueries(ticketsKeys.tickets);

      // Save reference of all query data that matches the query key ["tickets"]
      // This is going to be use in case of a possible error.
      const previousAllRequest = queryClient.getQueriesData(
        ticketsKeys.tickets
      );

      // Update ticket in all possible queries that matches the queryKey ["tickets"]
      if (previousAllRequest?.length) {
        mutateCache(queryClient, id, requestUpdated);
      }

      // Returning context in case of any error
      return { previousAllRequest };
    },
    onSuccess: (data, variables) => {
      const { id } = variables ?? {};
      // Update ticket with new data from database in all possible queries
      mutateCache(queryClient, id, data);
      // TODO: mutate cache needs a refactor or to be removed as it was not updating the base ["tickets"] query on patch resulting in missing data. Invalidating Queries manually as a band-aid.
      // Invalidate tasks for ticket
      queryClient.invalidateQueries(
        ticketsKeys.tasksByTicketRef(data.reference)
      );
      // Invalidate for ticket association, to update listing table on Association's SR table
      queryClient.invalidateQueries(
        ticketsKeys.byAssociation(data.association)
      );
      // Invalidate for SR Main Listing Page
      queryClient.invalidateQueries(ticketsKeys.tickets);
      toastMessage("Service Request was updated successfully");
    },
    onError: (error, _, context) => {
      const { previousAllRequest } = context ?? {};

      // Rollback ticket to previous state in all possible queries
      previousAllRequest.forEach(([key, value]) => {
        queryClient.setQueryData(key, value);
      });

      toastError("Service Request could not be updated. Please try again");
      console.error("useServiceRequestPatch", error);
    },
  });
};

export default useServiceRequestPatch;

import React from "react";

const Title = ({ title, titleClassName }) => {
  return (
    <p className={`text-gray-500 font-semibold text-2xl ${titleClassName}`}>
      {title}
    </p>
  );
};

export default Title;

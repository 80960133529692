import React from "react";
import PopoverComments from "../../../../stories/Components/PopoverComments";
import BackButton from "./BackButton";
import EmployeeNameStatus from "./EmployeeNameStatus";
import EmployeeTimesheet from "./EmployeeTimesheet";
import TimesheetControl from "./EsTimesheetControl";
import TimesheetContent from "./TimesheetContent";
import useTimesheetViewData from "./useTimesheetViewData";

const TimesheetView = () => {
  const {
    timesheet,
    commentsData,
    changesDetected,
    entriesForEditing,
    timesheetEntryTotal,
    canCreateTimesheet,
    overtimeTotal,
    isApprover,
    ptoEntryState,
    ptoTotal,
    hideBackButton,
    isLoading,
    timesheetIsLocked,
    usersToMention,
    rateOrCatEmpty,
    handleApproveTimesheet,
    handleRejectTimesheet,
    handelTimesheetSubmit,
    createNewTimesheet,
    saveTimesheet,
    addTimesheetRow,
    removeTimesheetRow,
    resetTimesheet,
    handleProjectChange,
    handleRateChange,
    handleCategoryChange,
    handleEntryChange,
    handleEntryNoteChange,
    handleBillableChange,
    handleBackClick,
    handleCloseComments,
    handleCommentClick,
  } = useTimesheetViewData();

  return (
    <>
      {!hideBackButton && <BackButton onClick={handleBackClick} />}
      <EmployeeNameStatus isLoading={isLoading} timesheet={timesheet} />
      <TimesheetControl
        isLoading={isLoading}
        isApprover={isApprover}
        handleCommentClick={handleCommentClick}
        rateOrCatEmpty={rateOrCatEmpty}
        changesDetected={changesDetected}
        resetTimesheet={resetTimesheet}
        timesheet={timesheet}
        saveTimesheet={saveTimesheet}
        canCreateTimesheet={canCreateTimesheet}
        createNewTimesheet={createNewTimesheet}
        handelTimesheetSubmit={handelTimesheetSubmit}
        handleApproveTimesheet={handleApproveTimesheet}
        handleRejectTimesheet={handleRejectTimesheet}
      />
      <TimesheetContent timesheet={timesheet}>
        <EmployeeTimesheet
          isLoading={isLoading}
          timesheet={timesheet}
          ptoEntryState={ptoEntryState}
          entriesForEditing={entriesForEditing}
          timesheetEntryTotal={timesheetEntryTotal}
          ptoTotal={ptoTotal}
          overtimeTotal={overtimeTotal}
          handleProjectChange={handleProjectChange}
          handleRateChange={handleRateChange}
          handleCategoryChange={handleCategoryChange}
          handleEntryChange={handleEntryChange}
          handleEntryNoteChange={handleEntryNoteChange}
          handleBillableChange={handleBillableChange}
          addTimesheetRow={addTimesheetRow}
          removeTimesheetRow={removeTimesheetRow}
          timesheetIsLocked={timesheetIsLocked}
        />
      </TimesheetContent>

      {commentsData.isOpen && (
        <PopoverComments
          isOpen={commentsData.isOpen}
          resourceReference={commentsData?.timesheetReference}
          usersToMention={usersToMention}
          onClose={handleCloseComments}
        />
      )}
    </>
  );
};

export default TimesheetView;

import React, { useCallback, useState } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import CollapsibleSection from "../CollapsibleSection/CollapsibleSection";
import BaseButton from "../Buttons/BaseButton";
import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";
import EditRemoveActions from "../EditRemoveActions/EditRemoveActions";
import PlusCirlceIconDarkGreen from "../../assets/images/plusCircleIconDarkGreen.svg";

const tertiaryButtonCN = cntl`
text-brandDarkGreen
py-4
text-base
`;

const textCN = (category, selected) => cntl`
pl-2
${category ? "font-semibold" : "font-medium"}
truncate
${selected ? "" : "text-gray-200"}
`;

const collapsibleContainerCN = (className) => cntl`
  text-black
  mr-6
  ${className}
`;

const ManagementAssetTypes = ({
  managementSettings,
  dispatchManagementSettings,
}) => {
  const [addMode, setAddMode] = useState(false);

  const toggleAssetCategories = useCallback(
    (index) => {
      dispatchManagementSettings({
        type: "toggleAssetCategory",
        index,
      });
    },
    [dispatchManagementSettings]
  );

  const onAddCategory = useCallback(() => {
    dispatchManagementSettings({
      type: "addAssetCategory",
    });
  }, [dispatchManagementSettings]);

  const onEditCategory = useCallback(
    (pIndex, newValue) => {
      dispatchManagementSettings({
        type: "changeAssetCategory",
        index: pIndex,
        value: newValue,
      });
    },
    [dispatchManagementSettings]
  );

  const onRemoveCategory = useCallback(
    (pIndex) => {
      dispatchManagementSettings({
        type: "removeAssetCategory",
        index: pIndex,
      });
    },
    [dispatchManagementSettings]
  );

  const toggleEditing = useCallback(
    (pIndex) => {
      dispatchManagementSettings({
        type: "changeAssetCategoryEditing",
        index: pIndex,
      });
    },
    [dispatchManagementSettings]
  );

  const onAddSubcategory = useCallback(
    (pIndex) => {
      dispatchManagementSettings({
        type: "addAssetSubCategory",
        index: pIndex,
      });
    },
    [dispatchManagementSettings]
  );

  const onEditSubcategory = useCallback(
    (pIndex, cIndex, newValue) => {
      dispatchManagementSettings({
        type: "changeAssetSubCategory",
        index: pIndex,
        typeIndex: cIndex,
        value: newValue,
      });
    },
    [dispatchManagementSettings]
  );

  const onRemoveSubcategory = useCallback(
    (pIndex, cIndex) => {
      dispatchManagementSettings({
        type: "removeAssetSubCategory",
        index: pIndex,
        typeIndex: cIndex,
      });
    },
    [dispatchManagementSettings]
  );

  const toggleSubcategoryEditing = useCallback(
    (pIndex, cIndex) => {
      dispatchManagementSettings({
        type: "changeAssetSubCategoryEditing",
        index: pIndex,
        typeIndex: cIndex,
      });
    },
    [dispatchManagementSettings]
  );

  const selectCategory = useCallback(
    (index) => {
      dispatchManagementSettings({
        type: "selectAssetCategory",
        index,
      });
    },
    [dispatchManagementSettings]
  );

  const selectSubCategory = useCallback(
    (index, cIndex) => {
      dispatchManagementSettings({
        type: "selectAssetSubCategory",
        index,
        cIndex,
      });
    },
    [dispatchManagementSettings]
  );

  const checkForEmptyValue = useCallback(
    (value, pIdx, cIdx) => {
      if (cIdx === undefined && value === "") onRemoveCategory(pIdx);
      else if (value === "") onRemoveSubcategory(pIdx, cIdx);
    },
    [onRemoveCategory, onRemoveSubcategory]
  );

  const hasDuplicate = useCallback(
    (value, idx) => {
      return managementSettings.assetCategories.some((x, index) => {
        return x.display.toLowerCase() === value.toLowerCase() && index !== idx;
      });
    },
    [managementSettings.assetCategories]
  );

  const hasDuplicateSubcategory = useCallback(
    (value, pIdx, cIdx) => {
      return managementSettings.assetCategories[pIdx].subcategories.some(
        (x, index) => {
          return (
            x.display.toLowerCase() === value.toLowerCase() && index !== cIdx
          );
        }
      );
    },
    [managementSettings.assetCategories]
  );

  const handleEnter = useCallback(
    (event, value, pIdx, cIdx) => {
      // checks if the Enter key was pressed
      if (event.keyCode === 13) {
        if (hasDuplicate(value, pIdx)) {
          return false;
        }
        if (value === "") {
          checkForEmptyValue(value, pIdx, cIdx);
          return undefined;
        }
        if (cIdx === undefined) {
          if (addMode) onAddCategory();
          toggleEditing(pIdx);
          onEditCategory(pIdx, value);
        } else {
          if (hasDuplicateSubcategory(value, pIdx, cIdx)) {
            return false;
          }
          if (addMode) onAddSubcategory(pIdx);
          toggleSubcategoryEditing(pIdx, cIdx);
          onEditSubcategory(pIdx, cIdx, value);
        }
      }
      return undefined;
    },
    [
      addMode,
      checkForEmptyValue,
      hasDuplicate,
      onAddCategory,
      onAddSubcategory,
      toggleEditing,
      toggleSubcategoryEditing,
      onEditCategory,
      onEditSubcategory,
      hasDuplicateSubcategory,
    ]
  );

  const handleBlur = (value, pIdx, cIdx) => {
    if (window.event.keyCode === 13) return;

    if (cIdx === undefined) {
      toggleEditing(pIdx);
      if (value === "" || hasDuplicate(value, pIdx)) {
        onRemoveCategory(pIdx);
      } else {
        onEditCategory(pIdx, value);
      }
    } else {
      toggleSubcategoryEditing(pIdx, cIdx);
      if (value === "" || hasDuplicateSubcategory(value, pIdx, cIdx)) {
        onRemoveSubcategory(pIdx, cIdx);
      } else {
        onEditSubcategory(pIdx, cIdx, value);
      }
    }

    // prevents new empty input field from being added inside handleEnter
    setAddMode(false);
  };

  return (
    <div>
      <div className="flex flex-col">
        <div className="flex flex-row border-b border-gray-150 py-2 justify-between mr-6">
          <h2 className="font-semibold py-4 uppercase">category</h2>
        </div>
        {managementSettings?.assetCategories?.map((category, pIndex) => (
          <CollapsibleSection
            key={category.id}
            id={category.id}
            className={collapsibleContainerCN("border-gray-150 border-b")}
            titleComponent={() => (
              <div className="flex flex-row w-full">
                <EditRemoveActions
                  index={pIndex}
                  isEditing={category?.isEditing}
                  toggleEditing={toggleEditing}
                  handleRemove={onRemoveCategory}
                  setAddMode={setAddMode}
                  hideRemove={
                    category?.count ||
                    category.subcategories.some((subcat) => subcat.count)
                  }
                >
                  <Checkbox
                    checked={category?.selected}
                    onChange={() => selectCategory(pIndex)}
                    disabled={
                      category?.count ||
                      category.subcategories.some((subcat) => subcat.count)
                    }
                  />
                  <div className="pl-2">
                    {(category?.isEditing && (
                      <Input
                        value={category.display}
                        placeholder="Category"
                        onChange={(val) => onEditCategory(pIndex, val)}
                        onBlur={() => handleBlur(category.display, pIndex)}
                        handleEnter={(event) =>
                          handleEnter(event, category.display, pIndex)
                        }
                        autoFocus
                      />
                    )) || (
                      <h3 className={textCN(true, category?.selected)}>
                        {category?.display}
                      </h3>
                    )}
                  </div>
                </EditRemoveActions>
              </div>
            )}
            isOpen={category?.isOpen}
            setIsOpen={() => {
              toggleAssetCategories(pIndex);
            }}
            useChevronIcon
          >
            <div className="flex flex-col">
              {category?.subcategories?.map((subcategory, cIndex) => {
                return (
                  <EditRemoveActions
                    key={subcategory?.id}
                    index={pIndex}
                    secondIndex={cIndex}
                    isEditing={subcategory?.isEditing}
                    toggleEditing={toggleSubcategoryEditing}
                    handleRemove={onRemoveSubcategory}
                    className="pt-2"
                    setAddMode={setAddMode}
                    hideRemove={subcategory?.count}
                  >
                    <Checkbox
                      checked={subcategory?.selected}
                      onChange={() => selectSubCategory(pIndex, cIndex)}
                      disabled={subcategory?.count}
                    />
                    {(subcategory?.isEditing && (
                      <div className="w-1/4 pl-2">
                        <Input
                          value={subcategory.display}
                          placeholder="Sub Category"
                          onChange={(val) =>
                            onEditSubcategory(pIndex, cIndex, val)
                          }
                          onBlur={() =>
                            handleBlur(subcategory.display, pIndex, cIndex)
                          }
                          handleEnter={(event) =>
                            handleEnter(
                              event,
                              subcategory.display,
                              pIndex,
                              cIndex
                            )
                          }
                          autoFocus
                        />
                      </div>
                    )) || (
                      <p className={textCN(false, subcategory?.selected)}>
                        {subcategory?.display}
                      </p>
                    )}
                  </EditRemoveActions>
                );
              })}
              <div className="flex flex-row items-center">
                <img
                  className="h-4 w-4"
                  src={PlusCirlceIconDarkGreen}
                  alt="plus circle icon green"
                />
                <BaseButton
                  title="Add Subcategory"
                  className={tertiaryButtonCN}
                  onClick={() => {
                    setAddMode(true);
                    onAddSubcategory(pIndex);
                  }}
                />
              </div>
            </div>
          </CollapsibleSection>
        ))}
        <div className="py-4 flex flex-row items-center">
          <img
            className="h-4 w-4"
            src={PlusCirlceIconDarkGreen}
            alt="plus circle icon green"
          />
          <BaseButton
            title="Add Category"
            onClick={() => {
              setAddMode(true);
              onAddCategory();
            }}
            className={tertiaryButtonCN}
          />
        </div>
      </div>
    </div>
  );
};

ManagementAssetTypes.propTypes = {
  managementSettings: PropTypes.shape({
    assetCategories: PropTypes.arrayOf(
      PropTypes.shape({
        display: PropTypes.string,
        id: PropTypes.string,
        subcategories: PropTypes.arrayOf(
          PropTypes.shape({
            display: PropTypes.string,
            id: PropTypes.string,
            isEditing: PropTypes.bool,
          })
        ),
        isOpen: PropTypes.bool,
        isEditing: PropTypes.bool,
      })
    ),
  }),
  dispatchManagementSettings: PropTypes.func,
};

ManagementAssetTypes.defaultProps = {
  managementSettings: undefined,
  dispatchManagementSettings: undefined,
};

export default ManagementAssetTypes;

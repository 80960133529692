import { useCallback, useEffect, useState } from "react";
import { ProjectAPI, PropertyAPI } from "@griffingroupglobal/eslib-api";

/**
 * 3.6.2
 * @deprecated
 */
const useBuildingLocation = (resource, resourceId, id, spaceId) => {
  const [space, setSpace] = useState();
  const [spaces, setSpaces] = useState();
  const [location, setLocation] = useState();
  const [locationParent, setLocationParent] = useState();
  const [loading, setLoading] = useState(true);
  const reload = useCallback(async () => {
    try {
      const request = {
        property: (val) => PropertyAPI.getById(val),
        project: (val) => ProjectAPI.getById(val),
      };
      const { data } = await request[resource](resourceId);

      setLocationParent({
        ...data,
        buildings: data?.buildings?.reduce((dict, item) => {
          // eslint-disable-next-line no-param-reassign
          dict[item?.id] = item;
          return dict;
        }, {}),
      });
      const tempLocation = data?.buildings?.find((item) => item?.id === id);
      setLocation(tempLocation);
      if (spaceId) {
        const tempSpaces = tempLocation?.spaces?.reduce((dict, item) => {
          // eslint-disable-next-line no-param-reassign
          dict[item?.id] = item;
          return dict;
        }, {});
        setSpaces(tempSpaces);
        setSpace(tempSpaces[spaceId]);
      }
    } catch (error) {
      console.warn(error);
    }
    setLoading(false);
  }, [id, resource, resourceId, spaceId]);

  useEffect(() => {
    reload();
  }, [reload]);

  const prepareOriginal = () => {
    if (resource)
      return {
        ...locationParent,
        buildings: Object.values(locationParent?.buildings ?? {}),
      };

    return {};
  };

  return {
    location,
    locationParent,
    reload,
    spaces,
    space,
    loading,
    prepareOriginal,
  };
};

export default useBuildingLocation;

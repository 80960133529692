import PropTypes from "prop-types";
import React, { useState } from "react";
import darkenedGreenMagnifier from "../../assets/images/darkenedGreenMagnifier.svg";
import searchIcon from "../../assets/images/searchIcon.svg";
import IconLabel from "../IconLabel/IconLabel";
import SearchBar from "../SearchBar/SearchBar";

const SiteHeaderSearch = ({
  handleSearch,
  searchKeyword,
  globalFilter,
  fullWidthSearch,
}) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const handleSearchClose = () => {
    setIsSearchOpen(false);
  };

  return (
    <>
      {isSearchOpen || globalFilter || fullWidthSearch ? (
        <div className="w-72">
          <SearchBar
            className="pr-3"
            placeholder="Search"
            onSearchChange={handleSearch}
            searchKeyword={searchKeyword}
            searchIcon={searchIcon}
            handleCloseSearchBar={handleSearchClose}
            globalFilter={globalFilter}
          />
        </div>
      ) : (
        <IconLabel
          icon={darkenedGreenMagnifier}
          text="Search"
          onClick={() => setIsSearchOpen((prev) => !prev)}
          iconStyle={{ width: "18px", height: "18px" }}
          className="pr-7"
          textClassName="text-darkenedGreen text-base"
        />
      )}
    </>
  );
};

SiteHeaderSearch.propTypes = {
  handleSearch: PropTypes.func,
  searchKeyword: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  globalFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidthSearch: PropTypes.bool,
};

SiteHeaderSearch.defaultProps = {
  handleSearch: undefined,
  searchKeyword: "",
  globalFilter: undefined,
  fullWidthSearch: false,
};

export default SiteHeaderSearch;

import React, { useState, useRef, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import PropTypes from "prop-types";

import { useAppState } from "../../../state/appState";

import TabbedContainer from "../TabbedContainer/TabbedContainer";
import Checkbox from "../Checkbox/Checkbox";

import dragIcon from "../../assets/images/drag_icon.svg";
import SettingsIcon from "../../assets/images/settingsIconGray.svg";
import { SET_DOCUMENT_SECTIONS } from "../../../constants";

const ItemTypes = {
  CARD: "card",
};

const DocumentSection = ({
  id,
  index,
  title,
  content,
  tabbedSection,
  moveCard,
  onTabClick,
}) => {
  const [{ documentSections }, dispatch] = useAppState();
  const [showTabFilter, setShowTabFilter] = useState(false);
  const [visibleTabIds, setVisibleTabIds] = useState();
  const [currentTabId, setCurrentTabId] = useState(content[0]?.id);

  const ref = useRef(null);

  useEffect(() => {
    if (Array.isArray(content) && !visibleTabIds && !documentSections) {
      if (dispatch) {
        dispatch({
          type: SET_DOCUMENT_SECTIONS,
          documentSections: content.map((item) => item.id),
        });
      }
    } else if (documentSections && !visibleTabIds) {
      setVisibleTabIds(documentSections);
    }
  }, [documentSections, visibleTabIds, content, dispatch]);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.CARD, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  const handleShowTabFilter = () => {
    setShowTabFilter(true);
  };
  const resolveVisibleContent = () => {
    return content.filter((item) => visibleTabIds?.includes(item.id));
  };
  const resolveActiveTabIndex = () => {
    const workingTabs = resolveVisibleContent();
    for (let i = 0; i < workingTabs.length; i += 1) {
      if (workingTabs[i].id === currentTabId) {
        return i;
      }
    }
    return 0;
  };
  const handleTabClick = (idx) => {
    const visibleTabs = resolveVisibleContent();
    setCurrentTabId(visibleTabs[idx]?.id);
    onTabClick(visibleTabs[idx]?.id);
  };
  const handleTabToggle = (idx) => {
    let tabIds = [];
    setVisibleTabIds((prev) => {
      tabIds = [...prev];
      const targetIndex = tabIds.findIndex((tabId) => tabId === idx);
      if (targetIndex > -1) {
        const removedId = tabIds.splice(targetIndex, 1);
        if (currentTabId === removedId[0]) {
          setCurrentTabId(content.find((item) => tabIds.includes(item.id))?.id);
        }
      } else {
        tabIds.push(idx);
      }

      dispatch({
        type: SET_DOCUMENT_SECTIONS,
        documentSections: tabIds,
      });
      return tabIds;
    });
  };

  drag(drop(ref));

  const tabRef = useRef();
  useEffect(() => {
    const listener = (event) => {
      if (!tabRef.current || tabRef.current.contains(event.target)) {
        return;
      }
      setShowTabFilter(false);
    };
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, []);

  return (
    <div ref={preview} className="flex flex-1 w-full">
      {!tabbedSection && (
        <div
          className="flex flex-col flex-1 overflow-x-auto overflow-y-visible"
          style={{ opacity }}
          data-handler-id={handlerId}
        >
          <div className="flex items-center w-full h-10 text-xl font-bold text-gray-300 border-b-2">
            <button type="button" ref={ref}>
              <img className="mr-5" src={dragIcon} alt="drag button" />
            </button>
            {!tabbedSection && title}
          </div>
          {content}
        </div>
      )}
      {tabbedSection && (
        <div
          className="flex w-full"
          style={{ opacity }}
          data-handler-id={handlerId}
        >
          <div
            className="flex items-center w-0 h-10 mb-3 border-b-2"
            style={{ height: "34px" }}
          >
            <div className="w-5">
              <button type="button" className="relative z-10" ref={ref}>
                <img className="mr-5" src={dragIcon} alt="drag button" />
              </button>
            </div>
          </div>
          <div className="w-full -mt-1">
            <TabbedContainer
              tabs={resolveVisibleContent()}
              activeIndex={resolveActiveTabIndex()}
              onTabClick={handleTabClick}
              indentTabs
              noPadding
            />
          </div>
          <div className="flex items-center w-0 h-8 mb-3">
            <div className="relative w-5 right-5">
              <button
                className="w-5"
                type="button"
                onClick={handleShowTabFilter}
              >
                <img className="w-5" src={SettingsIcon} alt="settings button" />
              </button>
            </div>
            <div
              ref={tabRef}
              className={`relative top-9 right-48 px-3 py-1 bg-white border rounded-md border-gray-200 ${
                showTabFilter ? "visible" : "invisible"
              }`}
            >
              {content.map((item) => (
                <Checkbox
                  key={item.id}
                  className="w-40 py-1"
                  label={item.title}
                  checked={visibleTabIds?.includes(item.id)}
                  onChange={() => handleTabToggle(item.id)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

DocumentSection.propTypes = {
  /**
   * array of document attachments
   */
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /**
   * section id
   */
  id: PropTypes.string,
  /**
   * section index in dragable list
   */
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * section title
   */
  title: PropTypes.string,
  /**
   * is section a tabbed section
   */
  tabbedSection: PropTypes.bool,
  /**
   * function for moving section
   */
  moveCard: PropTypes.func,
  /**
   * run on tab click
   */
  onTabClick: PropTypes.func,
};

DocumentSection.defaultProps = {
  content: [],
  id: undefined,
  index: undefined,
  title: undefined,
  tabbedSection: false,
  moveCard: () => {},
  onTabClick: undefined,
};

export default DocumentSection;

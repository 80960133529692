import { useReducer } from "react";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { COA_ACCOUNT_TYPES, DAYS_OF_WEEK } from "../constants";

const defaultState = {};

const reducer = (configuration, action) => {
  switch (action.type) {
    case "reset":
      return cloneDeep(action.configuration) ?? defaultState;
    case "addSectionItem": {
      return {
        ...configuration,
        [action.selectionValue]: [
          ...configuration?.[action.selectionValue],
          {
            display: "",
            id: uuidv4(),
            selected: true,
            custom: true,
            isEditing: true,
          },
        ],
      };
    }
    case "removeSectionItem": {
      return {
        ...configuration,
        [action.selectionValue]: configuration?.[action.selectionValue].filter(
          (item, idx) => idx !== action.index
        ),
      };
    }
    case "changeSectionItem": {
      return {
        ...configuration,
        [action.selectionValue]: configuration?.[action.selectionValue].map(
          (p, idx) => {
            if (idx === action.index) {
              return {
                ...p,
                display: action.value,
              };
            }
            return p;
          }
        ),
      };
    }
    case "changeSectionItemEditing": {
      return {
        ...configuration,
        [action.selectionValue]: configuration?.[action.selectionValue].map(
          (item, idx) => {
            if (idx === action.index) {
              return {
                ...item,
                isEditing: !item.isEditing,
              };
            }
            return item;
          }
        ),
      };
    }
    case "selectAllSectionItems": {
      return {
        ...configuration,
        [action.selectionValue]: configuration?.[action.selectionValue].map(
          (item) => {
            return {
              ...item,
              selected: !item.count ? action.value : item.selected,
            };
          }
        ),
      };
    }
    case "selectSectionItem": {
      return {
        ...configuration,
        [action.selectionValue]: configuration?.[action.selectionValue].map(
          (item, idx) => {
            if (idx === action.index) {
              return {
                ...item,
                selected: !item.selected,
              };
            }
            return item;
          }
        ),
      };
    }
    case "changeDivCodeDescription": {
      return {
        ...configuration,
        divisionCodes: configuration.divisionCodes.map((code, idx) => {
          if (idx === action.index) {
            return {
              ...code,
              description: action.value,
            };
          }
          return code;
        }),
      };
    }
    case "changeDivCodeCode": {
      return {
        ...configuration,
        divisionCodes: configuration.divisionCodes.map((code, idx) => {
          if (idx === action.index) {
            return {
              ...code,
              code: action.value,
            };
          }
          return code;
        }),
      };
    }
    case "toggleEditDivCode": {
      return {
        ...configuration,
        divisionCodes: configuration.divisionCodes.map((code, idx) => {
          if (idx === action.index) {
            return {
              ...code,
              isEditing: !code.isEditing,
            };
          }
          return code;
        }),
      };
    }
    case "addDivCode": {
      return {
        ...configuration,
        divisionCodes: [
          ...configuration.divisionCodes,
          {
            id: uuidv4(),
            code: action.code,
            description: action.description,
            custom: true,
          },
        ],
      };
    }
    case "removeDivCode": {
      return {
        ...configuration,
        divisionCodes: configuration.divisionCodes.filter(
          (code, idx) => idx !== action.index
        ),
      };
    }

    case "addCustomProjectBudgetType": {
      return {
        ...configuration,
        customProjectBudgetTypes: [
          ...configuration.customProjectBudgetTypes,
          {
            display: "",
            id: uuidv4(),
            selected: true,
            custom: true,
          },
        ],
      };
    }
    case "removeCustomProjectBudgetType": {
      return {
        ...configuration,
        customProjectBudgetTypes: configuration.customProjectBudgetTypes.filter(
          (type, idx) => idx !== action.index
        ),
      };
    }
    case "changeCustomProjectBudgetType": {
      return {
        ...configuration,
        customProjectBudgetTypes: configuration.customProjectBudgetTypes.map(
          (p, idx) => {
            if (idx === action.index) {
              return {
                ...p,
                display: action.value,
              };
            }
            return p;
          }
        ),
      };
    }

    case "addRateSheet": {
      return {
        ...configuration,
        rateSheet: [
          ...configuration.rateSheet,
          {
            category: "",
            ratePerHr: "",
            rateOver40Hrs: "",
            premiumRate: "",
            selected: true,
          },
        ],
      };
    }
    case "removeRateSheet": {
      return {
        ...configuration,
        rateSheet: configuration.rateSheet.filter(
          (type, idx) => idx !== action.index
        ),
      };
    }
    case "changeRateSheet": {
      return {
        ...configuration,
        rateSheet: configuration.rateSheet.map((rateItem, idx) => {
          if (idx === action.index) {
            return {
              ...rateItem,
              [`${action.field}`]: action.value,
            };
          }
          return rateItem;
        }),
      };
    }
    case "selectRateSheetPremiumRateDays": {
      return {
        ...configuration,
        premiumRateDaysOfWeek: {
          ...configuration.premiumRateDaysOfWeek,
          [`${action.day}`]:
            !configuration.premiumRateDaysOfWeek[`${action.day}`],
        },
      };
    }

    case "changeFica": {
      return {
        ...configuration,
        taxRates: {
          ...configuration.taxRates,
          fica: {
            ...configuration.taxRates?.fica,
            [`${action.field}`]: action.value,
          },
        },
      };
    }
    case "changeFuta": {
      return {
        ...configuration,
        taxRates: {
          ...configuration.taxRates,
          futa: {
            ...configuration.taxRates?.futa,
            [`${action.field}`]: action.value,
          },
        },
      };
    }
    case "changeSuta": {
      return {
        ...configuration,
        taxRates: {
          ...configuration.taxRates,
          suta: configuration.taxRates?.suta.map((rateItem, idx) => {
            if (idx === action.index) {
              return {
                ...rateItem,
                [`${action.field}`]: action.value,
              };
            }
            return rateItem;
          }),
        },
      };
    }
    case "addSuta": {
      return {
        ...configuration,
        taxRates: {
          ...configuration.taxRates,
          suta: [
            ...configuration.taxRates?.suta,
            {
              state: "",
              cost: "",
              perEmployee: "7000",
              total: "",
            },
          ],
        },
      };
    }
    case "removeSuta": {
      return {
        ...configuration,
        taxRates: {
          ...configuration.taxRates,
          suta: configuration.taxRates.suta.filter(
            (type, idx) => idx !== action.index
          ),
        },
      };
    }

    case "toggleCOACode": {
      return {
        ...configuration,
        chartOfAccounts: configuration.chartOfAccounts?.map((code, idx) => {
          if (idx === action.index) {
            return {
              ...code,
              isOpen: !code.isOpen,
            };
          }
          return code;
        }),
      };
    }
    case "expandAllCOACodes": {
      return {
        ...configuration,
        chartOfAccounts: configuration.chartOfAccounts?.map((code) => {
          return {
            ...code,
            isOpen: true,
          };
        }),
      };
    }
    case "collapseAllCOACodes": {
      return {
        ...configuration,
        chartOfAccounts: configuration.chartOfAccounts?.map((code) => {
          return {
            ...code,
            isOpen: false,
          };
        }),
      };
    }
    case "addCOACode": {
      return {
        ...configuration,
        chartOfAccounts: [...configuration?.chartOfAccounts, action.value].sort(
          (a, b) => parseInt(a?.code, 10) - parseInt(b?.code, 10)
        ),
      };
    }
    case "addCOASubCode": {
      return {
        ...configuration,
        chartOfAccounts: configuration.chartOfAccounts?.map((code, idx) => {
          if (idx === action.index) {
            return {
              ...code,
              subCodes: [...code.subCodes, action.value],
            };
          }
          return code;
        }),
      };
    }
    case "toggleEditingChartOfAccountCode": {
      return {
        ...configuration,
        chartOfAccounts: configuration.chartOfAccounts?.map((code, idx) => {
          if (idx === action.index) {
            return {
              ...code,
              isEditing: !code.isEditing,
            };
          }
          return code;
        }),
      };
    }
    case "toggleEditingChartOfAccountCodeSubCode": {
      return {
        ...configuration,
        chartOfAccounts: configuration.chartOfAccounts?.map((code, idx) => {
          if (idx === action.coaIndex) {
            return {
              ...code,
              subCodes: code.subCodes.map((subCode, sIndex) => {
                if (sIndex === action.subCodeIndex) {
                  return {
                    ...subCode,
                    isEditing: !subCode.isEditing,
                  };
                }
                return subCode;
              }),
            };
          }
          return code;
        }),
      };
    }
    case "changeChartOfAccountCodeSubCodeDescription": {
      return {
        ...configuration,
        chartOfAccounts: configuration.chartOfAccounts?.map((code, idx) => {
          if (idx === action.coaIndex) {
            return {
              ...code,
              subCodes: code.subCodes.map((subCode, sIndex) => {
                if (sIndex === action.subCodeIndex) {
                  return {
                    ...subCode,
                    description: action.value,
                  };
                }
                return subCode;
              }),
            };
          }
          return code;
        }),
      };
    }
    case "changeChartOfAccountCodeDescription": {
      return {
        ...configuration,
        chartOfAccounts: configuration.chartOfAccounts?.map((code, idx) => {
          if (idx === action.index) {
            return {
              ...code,
              description: action.value,
            };
          }
          return code;
        }),
      };
    }
    case "changeChartOfAccountCodeAccountType": {
      return {
        ...configuration,
        chartOfAccounts: configuration.chartOfAccounts?.map((code, idx) => {
          if (idx === action.index) {
            return {
              ...code,
              accountType: action.value,
              codeType: COA_ACCOUNT_TYPES.find(
                (type) => type.value === action.value
              )?.type,
            };
          }
          return code;
        }),
      };
    }

    case "importCOACodes": {
      return {
        ...configuration,
        chartOfAccounts: action.chartOfAccounts,
      };
    }

    case "toggleCSIDivisionMapping": {
      return {
        ...configuration,
        csiCodeMapping: configuration.csiCodeMapping?.map((div, idx) => {
          if (idx === action.mappingIndex) {
            return {
              ...div,
              isOpen: !div.isOpen,
            };
          }
          return div;
        }),
      };
    }
    case "exapandAllCSIDivisionMappings": {
      return {
        ...configuration,
        csiCodeMapping: configuration.csiCodeMapping?.map((div) => {
          return {
            ...div,
            isOpen: true,
          };
        }),
      };
    }
    case "collapseAllCSIDivisionMappings": {
      return {
        ...configuration,
        csiCodeMapping: configuration.csiCodeMapping?.map((div) => {
          return {
            ...div,
            isOpen: false,
          };
        }),
      };
    }
    case "toggleCSICode": {
      return {
        ...configuration,
        csiCodeMapping: configuration.csiCodeMapping?.map((div, idx) => {
          if (idx === action.mappingIndex) {
            return {
              ...div,
              csiCodes: div.csiCodes.map((csiCode, cIdx) => {
                if (cIdx === action.csiCodeIndex) {
                  return {
                    ...csiCode,
                    isOpen: !csiCode.isOpen,
                  };
                }

                return csiCode;
              }),
            };
          }
          return div;
        }),
      };
    }
    case "addCSIRevenueCode": {
      return {
        ...configuration,
        csiCodeMapping: configuration.csiCodeMapping?.map((div, idx) => {
          if (idx === action.mappingIndex) {
            return {
              ...div,
              csiCodes: div.csiCodes.map((csiCode, cIdx) => {
                if (cIdx === action.csiCodeIndex) {
                  return {
                    ...csiCode,
                    subCodes: [
                      ...csiCode.subCodes,
                      {
                        code: action.code,
                        selectedForBudgets: true,
                        selectedForTimesheets: true,
                        revenueCode: action.revenueCode,
                        custom: true,
                      },
                    ],
                  };
                }
                return csiCode;
              }),
            };
          }
          return div;
        }),
      };
    }
    case "addCSIExpenseCode": {
      return {
        ...configuration,
        csiCodeMapping: configuration.csiCodeMapping?.map((div, idx) => {
          if (idx === action.mappingIndex) {
            return {
              ...div,
              csiCodes: div.csiCodes.map((csiCode, cIdx) => {
                if (cIdx === action.csiCodeIndex) {
                  return {
                    ...csiCode,
                    subCodes: [
                      ...csiCode.subCodes,
                      {
                        code: action.code,
                        expenseCode: action.expenseCode,
                        selectedForBudgets: true,
                        selectedForTimesheets: true,
                        custom: true,
                      },
                    ],
                  };
                }

                return csiCode;
              }),
            };
          }
          return div;
        }),
      };
    }
    case "addCSICode": {
      return {
        ...configuration,
        csiCodeMapping: configuration.csiCodeMapping?.map((div, idx) => {
          if (idx === action.mappingIndex) {
            // CHECK IF CSI CODE ALREADY EXIST
            const foundIndex = div.csiCodes.findIndex(
              (csiCode) => csiCode.code === action.csiCode
            );

            if (foundIndex !== -1) {
              div.csiCodes[foundIndex].subCodes.push({
                id: uuidv4(),
                code: action.subCode,
                description: action.description,
                custom: true,
              });
              return div;
            }

            return {
              ...div,
              csiCodes: [
                ...div.csiCodes,
                {
                  code: action.csiCode,
                  description: "",
                  subCodes: [
                    {
                      code: action.subCode,
                      description: action.description,
                      custom: true,
                    },
                  ],
                },
              ],
            };
          }
          return div;
        }),
      };
    }
    case "removeCSICode": {
      return {
        ...configuration,
        csiCodeMapping: configuration.csiCodeMapping?.map((div, idx) => {
          if (idx === action.mappingIndex) {
            return {
              ...div,
              csiCodes: div.csiCodes.map((csiCode, cIdx) => {
                if (cIdx === action.csiCodeIndex) {
                  return {
                    ...csiCode,
                    subCodes: csiCode.subCodes.filter(
                      (code, sIdx) => sIdx !== action.subCodeIndex
                    ),
                  };
                }

                return csiCode;
              }),
            };
          }
          return div;
        }),
      };
    }
    case "toggleEditCSICode": {
      return {
        ...configuration,
        csiCodeMapping: configuration.csiCodeMapping?.map((div, idx) => {
          if (idx === action.mappingIndex) {
            return {
              ...div,
              csiCodes: div.csiCodes.map((csiCode, cIdx) => {
                if (cIdx === action.csiCodeIndex) {
                  return {
                    ...csiCode,
                    subCodes: csiCode.subCodes.map((code, sIdx) => {
                      if (sIdx === action.subCodeIndex) {
                        return {
                          ...code,
                          isEditing: !code.isEditing,
                        };
                      }
                      return code;
                    }),
                  };
                }

                return csiCode;
              }),
            };
          }
          return div;
        }),
      };
    }
    case "editCSICodeDescription": {
      return {
        ...configuration,
        csiCodeMapping: configuration.csiCodeMapping?.map((div, idx) => {
          if (idx === action.mappingIndex) {
            return {
              ...div,
              csiCodes: div.csiCodes.map((csiCode, cIdx) => {
                if (cIdx === action.csiCodeIndex) {
                  return {
                    ...csiCode,
                    subCodes: csiCode.subCodes.map((code, sIdx) => {
                      if (sIdx === action.subCodeIndex) {
                        return {
                          ...code,
                          description: action.description,
                        };
                      }
                      return code;
                    }),
                  };
                }

                return csiCode;
              }),
            };
          }
          return div;
        }),
      };
    }
    case "toggleCSIDivisionMappingInProjectConfig": {
      return {
        ...configuration,
        csiCodeMapping: configuration.csiCodeMapping?.map((div, idx) => {
          if (idx === action.mappingIndex) {
            return {
              ...div,
              isOpenInProjectConfig: !div.isOpenInProjectConfig,
            };
          }
          return div;
        }),
      };
    }
    case "toggleCSIDivisionMappingInTimesheetConfig": {
      return {
        ...configuration,
        csiCodeMapping: configuration.csiCodeMapping?.map((div, idx) => {
          if (idx === action.mappingIndex) {
            return {
              ...div,
              isOpenInTimesheetConfig: !div.isOpenInTimesheetConfig,
            };
          }
          return div;
        }),
      };
    }
    case "selectCSIDivisionMappingInProjectConfig": {
      return {
        ...configuration,
        csiCodeMapping: configuration.csiCodeMapping?.map((div, idx) => {
          if (idx === action.mappingIndex) {
            return {
              ...div,
              selectedForBudgets: !div.selectedForBudgets,
            };
          }
          return div;
        }),
      };
    }
    case "selectCSIDivisionMappingInTimesheetConfig": {
      return {
        ...configuration,
        csiCodeMapping: configuration.csiCodeMapping?.map((div, idx) => {
          if (idx === action.mappingIndex) {
            return {
              ...div,
              selectedForTimesheets: !div.selectedForTimesheets,
            };
          }
          return div;
        }),
      };
    }
    case "selectCSICodeInProjectConfig": {
      return {
        ...configuration,
        csiCodeMapping: configuration.csiCodeMapping?.map((div, idx) => {
          if (idx === action.mappingIndex) {
            return {
              ...div,
              csiCodes: div.csiCodes.map((csiCode, cIdx) => {
                if (cIdx === action.csiCodeIndex) {
                  return {
                    ...csiCode,
                    selectedForBudgets: !csiCode.selectedForBudgets,
                  };
                }

                return csiCode;
              }),
            };
          }
          return div;
        }),
      };
    }
    case "selectCSICodeInTimesheetConfig": {
      return {
        ...configuration,
        csiCodeMapping: configuration.csiCodeMapping?.map((div, idx) => {
          if (idx === action.mappingIndex) {
            return {
              ...div,
              csiCodes: div.csiCodes.map((csiCode, cIdx) => {
                if (cIdx === action.csiCodeIndex) {
                  return {
                    ...csiCode,
                    selectedForTimesheets: !csiCode.selectedForTimesheets,
                  };
                }

                return csiCode;
              }),
            };
          }
          return div;
        }),
      };
    }
    case "selectCSISubCodeInProjectConfig": {
      return {
        ...configuration,
        csiCodeMapping: configuration.csiCodeMapping?.map((div, idx) => {
          if (idx === action.mappingIndex) {
            return {
              ...div,
              csiCodes: div.csiCodes.map((csiCode, cIdx) => {
                if (cIdx === action.csiCodeIndex) {
                  return {
                    ...csiCode,
                    subCodes: csiCode.subCodes.map((subCode, ccIdx) => {
                      if (ccIdx === action.subCodeIndex) {
                        return {
                          ...subCode,
                          selectedForBudgets: !subCode.selectedForBudgets,
                        };
                      }
                      return subCode;
                    }),
                  };
                }

                return csiCode;
              }),
            };
          }
          return div;
        }),
      };
    }
    case "selectCSISubCodeInTimesheetConfig": {
      return {
        ...configuration,
        csiCodeMapping: configuration.csiCodeMapping?.map((div, idx) => {
          if (idx === action.mappingIndex) {
            return {
              ...div,
              csiCodes: div.csiCodes.map((csiCode, cIdx) => {
                if (cIdx === action.csiCodeIndex) {
                  return {
                    ...csiCode,
                    subCodes: csiCode.subCodes.map((subCode, ccIdx) => {
                      if (ccIdx === action.subCodeIndex) {
                        return {
                          ...subCode,
                          selectedForTimesheets: !subCode.selectedForTimesheets,
                        };
                      }
                      return subCode;
                    }),
                  };
                }

                return csiCode;
              }),
            };
          }
          return div;
        }),
      };
    }
    case "selectAllCSICodesInProjectConfig": {
      return {
        ...configuration,
        csiCodeMapping: configuration.csiCodeMapping?.map((div) => {
          return {
            ...div,
            selectedForBudgets: action.value,
            csiCodes: div.csiCodes.map((csiCode) => {
              return {
                ...csiCode,
                selectedForBudgets: action.value,
                subCodes: csiCode.subCodes.map((subCode) => {
                  return {
                    ...subCode,
                    selectedForBudgets: action.value,
                  };
                }),
              };
            }),
          };
        }),
      };
    }
    case "selectAllCSICodesInTimesheetConfig": {
      return {
        ...configuration,
        csiCodeMapping: configuration.csiCodeMapping?.map((div) => {
          return {
            ...div,
            selectedForTimesheets: action.value,
            csiCodes: div.csiCodes.map((csiCode) => {
              return {
                ...csiCode,
                selectedForTimesheets: action.value,
                subCodes: csiCode.subCodes.map((subCode) => {
                  return {
                    ...subCode,
                    selectedForTimesheets: action.value,
                  };
                }),
              };
            }),
          };
        }),
      };
    }

    case "toggleCOACodeInProjectConfig": {
      return {
        ...configuration,
        chartOfAccounts: configuration.chartOfAccounts?.map((code, idx) => {
          if (idx === action.index) {
            return {
              ...code,
              isOpenInProjectConfig: !code.isOpenInProjectConfig,
            };
          }
          return code;
        }),
      };
    }
    case "toggleCOACodeInTimesheetConfig": {
      return {
        ...configuration,
        chartOfAccounts: configuration.chartOfAccounts?.map((code, idx) => {
          if (idx === action.index) {
            return {
              ...code,
              isOpenInTimesheetConfig: !code.isOpenInTimesheetConfig,
            };
          }
          return code;
        }),
      };
    }
    case "selectCOACodeInProjectConfig": {
      return {
        ...configuration,
        chartOfAccounts: configuration.chartOfAccounts?.map((code, idx) => {
          if (idx === action.index) {
            return {
              ...code,
              selectedForBudgets: !code.selectedForBudgets,
            };
          }
          return code;
        }),
      };
    }
    case "selectCOACodeInTimesheetConfig": {
      return {
        ...configuration,
        chartOfAccounts: configuration.chartOfAccounts?.map((code, idx) => {
          if (idx === action.index) {
            return {
              ...code,
              selectedForTimesheets: !code.selectedForTimesheets,
            };
          }
          return code;
        }),
      };
    }
    case "selectCOASubCodeInProjectConfig": {
      return {
        ...configuration,
        chartOfAccounts: configuration.chartOfAccounts?.map((code, idx) => {
          if (idx === action.index) {
            return {
              ...code,
              subCodes: code.subCodes.map((sCode, cIdx) => {
                if (cIdx === action.subCodeIndex) {
                  return {
                    ...sCode,
                    selectedForBudgets: !sCode.selectedForBudgets,
                  };
                }

                return sCode;
              }),
            };
          }
          return code;
        }),
      };
    }
    case "selectCOASubCodeInTimesheetConfig": {
      return {
        ...configuration,
        chartOfAccounts: configuration.chartOfAccounts?.map((code, idx) => {
          if (idx === action.index) {
            return {
              ...code,
              subCodes: code.subCodes.map((sCode, cIdx) => {
                if (cIdx === action.subCodeIndex) {
                  return {
                    ...sCode,
                    selectedForTimesheets: !sCode.selectedForTimesheets,
                  };
                }

                return sCode;
              }),
            };
          }
          return code;
        }),
      };
    }
    case "selectAllCOACodesInProjectConfig": {
      return {
        ...configuration,
        chartOfAccounts: configuration.chartOfAccounts?.map((code) => {
          return {
            ...code,
            selectedForBudgets: action.value,
            subCodes: code.subCodes.map((sCode) => {
              return {
                ...sCode,
                selectedForBudgets: action.value,
              };
            }),
          };
        }),
      };
    }
    case "selectAllCOACodesInTimesheetConfig": {
      return {
        ...configuration,
        chartOfAccounts: configuration.chartOfAccounts?.map((code) => {
          return {
            ...code,
            selectedForTimesheets: action.value,
            subCodes: code.subCodes.map((sCode) => {
              return {
                ...sCode,
                selectedForTimesheets: action.value,
              };
            }),
          };
        }),
      };
    }

    case "selectRateSheetCategoryInProjectConfig": {
      return {
        ...configuration,
        rateSheet: configuration.rateSheet.map((rateItem, idx) => {
          if (idx === action.index) {
            return {
              ...rateItem,
              selected: !rateItem.selected,
            };
          }
          return rateItem;
        }),
      };
    }
    case "selectAllRateSheetCategoriesInProjectConfig": {
      return {
        ...configuration,
        rateSheet: configuration.rateSheet.map((rateItem) => {
          return {
            ...rateItem,
            selected: action.value,
          };
        }),
      };
    }
    case "changePayrollPeriod": {
      return {
        ...configuration,
        payroll: {
          ...configuration.payroll,
          period: action.value,
        },
      };
    }
    case "changePeriod": {
      return {
        ...configuration,
        period: {
          ...configuration.period,
          start: action.value,
          end: DAYS_OF_WEEK.find((day) => day.value === action.value)
            .endOfWeekDay,
        },
      };
    }
    case "changeExpenseCategoryEditing": {
      return {
        ...configuration,
        expenseCategories: configuration.expenseCategories.map(
          (category, idx) => {
            if (idx === action.index) {
              return {
                ...category,
                isEditing: !category.isEditing,
              };
            }
            return category;
          }
        ),
      };
    }
    case "removeExpenseCategory": {
      return {
        ...configuration,
        expenseCategories: configuration.expenseCategories.filter(
          (category, idx) => idx !== action.index
        ),
      };
    }
    case "selectExpenseCategory": {
      return {
        ...configuration,
        expenseCategories: configuration.expenseCategories.map(
          (category, idx) => {
            if (idx === action.index) {
              return {
                ...category,
                selected: !category.selected,
              };
            }
            return category;
          }
        ),
      };
    }
    case "changeExpenseCategory": {
      return {
        ...configuration,
        expenseCategories: configuration.expenseCategories.map(
          (category, idx) => {
            if (idx === action.index) {
              return {
                ...category,
                display: action.value,
              };
            }
            return category;
          }
        ),
      };
    }
    case "addExpenseCategory": {
      return {
        ...configuration,
        expenseCategories: [
          ...configuration.expenseCategories,
          {
            display: "",
            id: uuidv4(),
            selected: true,
            custom: true,
            isEditing: true,
          },
        ],
      };
    }
    case "expenseRatePerMile": {
      return {
        ...configuration,
        expense: {
          ...configuration.expense,
          ratePerMile: action.ratePerMile,
        },
      };
    }

    default:
      return configuration;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};

import React from "react";
import PropTypes from "prop-types";

import IconButton from "./IconButton";
import starGreen from "../../assets/images/starGreen.svg";
import starIcon from "../../assets/images/starIcon.svg";

const FavoriteButton = ({ className, imgClassName, onClick, isSelected }) => (
  <IconButton
    className={className}
    onClick={onClick}
    icon={isSelected ? starGreen : starIcon}
    imgClassName={imgClassName}
  />
);

FavoriteButton.propTypes = {
  /**
   * classes to apply to the button
   */
  className: PropTypes.string,
  /**
   * classes to apply to the icon, width and height is provided by default but can be overwritten
   */
  imgClassName: PropTypes.string,
  /**
   * function called when the button is clicked
   */
  onClick: PropTypes.func,
  /**
   * boolean value that determines which icon to display
   */
  isSelected: PropTypes.bool,
};

FavoriteButton.defaultProps = {
  className: null,
  imgClassName: "w-4 h-4",
  onClick: undefined,
  isSelected: false,
};

export default FavoriteButton;

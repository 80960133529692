const useAttachmentSectionData = ({ editingMode, currentTask }) => {
  // a boolean value to check if there are any attachments to show
  const hasAttachmentsToShow =
    !editingMode &&
    currentTask.files.find((file) => file.category === "Documents");
  // a boolean value to check if there are no attachments to show
  const hasNoAttachmentsToShow =
    !editingMode &&
    !currentTask.files.find((file) => file.category === "Documents");

  return { hasAttachmentsToShow, hasNoAttachmentsToShow };
};

export default useAttachmentSectionData;

import React, { useCallback } from "react";
import cntl from "cntl";
import useAddPreferenceModal from "./useAddPreferenceModal";
import Dropdown from "../../Dropdown/Dropdown";
import Input from "../../Input/Input";
import { SOP } from "../../../../constants";
import Checkbox from "../../Checkbox/Checkbox";
import capitalizeFirstLetter from "../../../../helpers/Utilities/capitalizeFirstLetter";
import sopIcon from "../../../assets/images/checkedClipboard.svg";
import greenCheckmark from "../../../assets/images/checkGreenNoBackground.svg";
import ModalWrapper from "../ModalWrapper";
import PrimaryButton from "../../Buttons/PrimaryButton";
import TertiaryButton from "../../Buttons/TertiaryButton";
import crossIconWhite from "../../../assets/images/crossIconWhite.svg";

const tagTextCN =
  "text-es-green font-es-semibold tracking-es-normal text-es-sm";

const tagCN = (isSelected) => cntl`
  ${
    isSelected ? "bg-backgroundGreen border-es-green border" : "bg-white"
  } rounded-3xl py-2 px-3 flex flex-row items-center gap-1 text-es-green`;
const buttonStyle = {
  false: {
    borderColor: "rgba(2, 125, 97, 0.4)", // custom color for border as its RGB with added .4 opacity
    borderWidth: "1px",
    height: "32px",
  },
  true: {
    height: "32px",
  },
};

const WEBSITE = "Website";
const listItem = (item, selectPreference) => (
  <button
    key={item?.id}
    style={buttonStyle[item?.isSelected]}
    type="button"
    onClick={() => selectPreference(item)}
    className={tagCN(item?.isSelected)}
  >
    {item?.isSelected && (
      <img
        src={greenCheckmark}
        className="flex"
        style={{ height: "12px", width: "12px" }}
        alt="selected"
      />
    )}
    <p className={tagTextCN}>{item.label}</p>
  </button>
);
const adminPageListItem = (item, selectPreference) => (
  <div className="flex" key={item.id}>
    <button
      type="button"
      onClick={() => selectPreference(item)}
      className={tagCN(true)}
    >
      <p className={tagTextCN}>{item.label}</p>
    </button>
    <button
      type="button"
      onClick={() => selectPreference(item)}
      className="relative bottom-4 right-4"
    >
      <img
        alt="delete tag"
        className="rounded-xl p-1"
        src={crossIconWhite}
        style={{
          width: "16px",
          height: "16px",
          backgroundColor: "#027D61",
        }}
      />
    </button>
  </div>
);

const AddPreferenceModal = ({ modalData, closePopup }) => {
  const {
    loading,
    changeName,
    changeLink,
    changeSOP,
    name,
    link,
    sop,
    sopData,
    isAddDisabled,
    handleRequestModalClose,
    title,
    selectLinkType,
    linkType,
    sopsDict,
    optionsList,
    isAdminPage,
    isSaveDisabled,
    selectPreference,
    value,
    setValue,
    handleAddNew,
    handleOnSubmit,
  } = useAddPreferenceModal({
    modalData,
    closePopup,
  });

  const sopDDLOptions = useCallback(({ label }) => {
    return (
      <div className="flex flex-row justify-start items-center cursor-pointer gap-4 px-2 py-2">
        <img src={sopIcon} alt="priority type" className="w-5 h-5" />
        <p className="text-es-normal text-es-medium-grey font-es-normal truncate">
          {capitalizeFirstLetter(label)}
        </p>
      </div>
    );
  }, []);

  // if search value and matches display only those items
  // if no search value show all items
  const renderList = useCallback(() => {
    return optionsList?.map((item) => {
      if (value && item?.label?.toLowerCase().includes(value.toLowerCase())) {
        return isAdminPage
          ? adminPageListItem(item, selectPreference)
          : listItem(item, selectPreference);
      }
      if (!value) {
        return isAdminPage
          ? adminPageListItem(item, selectPreference)
          : listItem(item, selectPreference);
      }
      return <React.Fragment key={item.id} />;
    });
  }, [isAdminPage, optionsList, selectPreference, value]);

  return (
    <ModalWrapper
      modalData={modalData}
      onRequestClose={() => {
        handleRequestModalClose();
      }}
      width="900px"
      primaryButtonTitle="Save"
      title={title}
      primaryButtonOnClick={handleOnSubmit}
      tertiaryButtonTitle="Cancel"
      disabled={loading || isSaveDisabled}
      showConfirm={!loading && !isSaveDisabled}
      resourceName=""
      className="items-center m-0"
    >
      <div className="flex flex-row gap-8" style={{ minHeight: "493px" }}>
        <div>
          <div className="flex flex-row justify-between pb-4 items-center">
            <p className="text-es-medium-grey font-es-bold text-es-normal">
              {!isAdminPage ? "Choose Existing" : "View Existing"}
            </p>
          </div>
          <div
            className="overflow-visible flex flex-col justify-between"
            style={{
              width: "404px",
              minHeight: "270px",
            }}
          >
            <div className="flex flex-col justify-start p-4 px-6 mb-4 rounded-xl border border-es-light-grey">
              <p className="text-es-normal font-es-semibold text-es-medium-grey pt-2 pb-1">
                Search
              </p>
              <Input value={value} onChange={(val) => setValue(val)} />
              <div
                className="flex flex-row h-full flex-wrap w-full pt-4 gap-3 overflow-y-auto"
                style={{ maxHeight: "300px" }}
              >
                {renderList()}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-row justify-between pb-4 items-center">
            <p className="text-es-medium-grey font-es-bold text-es-normal">
              {isAdminPage ? "Add New" : "Or Add New"}
            </p>
          </div>
          <div
            className="h-fit overflow-visible"
            style={{
              width: "404px",
              minHeight: "270px",
            }}
          >
            <div className="flex flex-col gap-6 justify-start p-6 mb-6 border border-es-light-grey rounded-xl">
              <Input
                label="Name"
                labelClassName="pb-1 text-es-normal font-es-semibold text-es-medium-grey"
                value={name}
                onChange={(val) => changeName(val)}
              />

              <div className="flex flex-row gap-4 justify-between px-6">
                <Checkbox
                  size={6}
                  onChange={(val) => selectLinkType(WEBSITE, val)}
                  label="Website"
                  checked={linkType && linkType === WEBSITE}
                  labelClassName="text-es-normal text-es-medium-grey font-es-semibold"
                />
                <Checkbox
                  size={6}
                  onChange={(val) => selectLinkType(SOP, val)}
                  checked={linkType && linkType === SOP}
                  label="SOP"
                  labelClassName="text-es-normal text-es-medium-grey font-es-semibold"
                />
              </div>
              {linkType === WEBSITE && (
                <Input
                  label="Link (Optional)"
                  value={link}
                  onChange={(val) => changeLink(val)}
                />
              )}
              {linkType === SOP && (
                <div>
                  <p className="pb-2 text-es-normal font-es-semibold text-es-medium-grey">
                    SOP (Optional)
                  </p>
                  <Dropdown
                    value={
                      sop
                        ? {
                            label: sopsDict?.[sop]?.name,
                            value: sopsDict?.[sop]?.reference,
                          }
                        : ""
                    }
                    onChange={(val) => changeSOP(val?.value)}
                    options={sopData}
                    formatOptionLabel={sopDDLOptions}
                    maxMenuHeight="180px"
                  />
                </div>
              )}
              <div className="flex flex-row items-center gap-3 bottom-0">
                <PrimaryButton
                  onClick={() => handleAddNew()}
                  saveButtonTitle="Add"
                  saveButton
                  disabled={isAddDisabled}
                />
                <TertiaryButton
                  onClick={() => {}}
                  cancelButtonTitle="Clear"
                  cancelButton
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AddPreferenceModal;

import { CommentAPI, TaskAPI } from "@griffingroupglobal/eslib-api";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router";
import { uniqBy } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useQueryClient } from "react-query";
import {
  ADD_OPEN_MODAL,
  CONFIRM_MODAL,
  SINGLE_EVENT_INSTANCE,
  CREATE_WF_COMMENT_MODAL,
  WF_STATUS_TYPES,
  SET_TASKS_DICT,
  WF_STEP_ACTION_STATUS,
} from "../../../constants";
import formatTasks, {
  formattedTaskStatus,
} from "../../../helpers/ServiceRequest/formatTasks";
import useAssociatedFiles from "../../../hooks/useAssociatedFiles";
import { usePostComment } from "../../../hooks/useComments";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useEsTasks from "../../../hooks/useEsTasks";
import useFilesPost from "../../../hooks/useFilesPost";
import useAssociatedResource from "../../../hooks/useGeteAssociatedResource";
import { useModalState } from "../../../state/modalState";
import { toastError, toastMessage } from "../Toast/Toast";
import { useAppState } from "../../../state/appState";
import taskKeys from "../../../hooks/tasks/taskKeys";
import useSopVersionForTask from "../../../hooks/useSopVersionForTask";
import { getFullName } from "../../../helpers/Formatters";

const INITIAL_FILES = {
  mediaFilesToAdd: [],
  nonMediaFilesToAdd: [],
};

export default function useWorkflowStepTasksData({
  requestFormDispatch,
  stepTasksViewData,
  association,
  workflow,
  onPatch,
  editedWorkflow,
  workflowForm,
  setStepTasksViewData,
  onStepAction,
  setShowStepDetails,
  getStepStatus,
  refetchWorkFlow,
}) {
  const { workflowId } = useParams();
  const queryClient = useQueryClient();
  const [{ userDict }, appStateDispatch] = useAppState();
  const [, modalDispatch] = useModalState();
  const { mutate: postComment } = usePostComment();
  const fileAssociation = `Workflow/${workflowId}`;
  const params = useMemo(() => ({ fileAssociation }), [fileAssociation]);
  const { addFiles } = useAssociatedFiles(params);
  // TODO (RQ-Perf)
  const { tasksDict, deleteTaskAndAllRecurrences } = useEsTasks();
  const { mutate: postFiles } = useFilesPost();
  const location = useLocation();
  const { data: currentUser } = useCurrentUser();
  const { value, label, reference } = useAssociatedResource(association) || {};
  const { getTaskByAssociation } = useEsTasks();
  const [formattedTasks, setFormattedTasks] = useState([]);
  const [currentTask, setCurrentTask] = useState(null);
  const [canFetchTasks, setcanFetchTasks] = useState(true);
  const { usedSopDict } = useSopVersionForTask({ currentTask });

  // get formatted list of assignees for this step (FirstName, LastName), or return ""
  const assignee = useMemo(() => {
    if (stepTasksViewData?.users?.length === 0) return "";

    const formattedAssignee = [];
    stepTasksViewData?.users?.forEach((user) => {
      if (user?.reference) {
        const userFullName =
          getFullName(userDict?.[user?.reference]?.name) || "";
        if (userFullName) formattedAssignee.push(userFullName);
      }
    });
    return formattedAssignee.length !== 0 ? formattedAssignee?.join(", ") : "";
  }, [userDict, stepTasksViewData?.users]);

  useEffect(() => {
    // TODO (RQ-Perf) Some or all of this will be redundant with RQ
    // Array of task associated to this step
    const taskRefArray = [
      ...workflow?.steps?.[stepTasksViewData?.index - 1]?.tasks,
    ];

    const getTask = async () => {
      try {
        // get response from API
        const response = await getTaskByAssociation(association);
        // loop the associated refs array and get the task {}
        const formattedArray = [];
        taskRefArray?.forEach((item) => {
          const newItem = response.associatedTaskDict?.[item?.split("/")[1]];
          // check to make sure this isn't 'undefined' -> prevents empty task row from appearing after delete
          if (newItem) {
            formattedArray.push(newItem);
          }
        });

        // Set the state for the formatted task info
        setFormattedTasks(formattedArray);
      } catch (error) {
        console.error("ERROR fetching tasks", error);
      } finally {
        setcanFetchTasks(false);
      }
    };

    if (taskRefArray?.length && canFetchTasks) getTask();
  }, [
    stepTasksViewData?.index,
    canFetchTasks,
    association,
    workflow,
    workflowForm,
    getTaskByAssociation,
    setFormattedTasks,
  ]);

  // current user can void step if current user is member of step
  const canVoidStep = stepTasksViewData?.users?.some(
    (user) => user?.reference === currentUser?.reference
  );

  const showVoidButton =
    canVoidStep &&
    workflow?.isFreeFlow &&
    stepTasksViewData?.status !== WF_STEP_ACTION_STATUS.COMPLETED &&
    stepTasksViewData?.status !== WF_STEP_ACTION_STATUS.VOID &&
    editedWorkflow?.status !== WF_STATUS_TYPES.VOID &&
    editedWorkflow?.status !== WF_STATUS_TYPES.DRAFT &&
    !editedWorkflow?.status?.includes("completed");

  // for restoring WF edit state on back click
  const editableWFState =
    workflowForm?.status?.includes(WF_STATUS_TYPES?.COMPLETED_OPEN) ||
    workflowForm?.status?.includes(WF_STATUS_TYPES?.COMPLETED_RESOLVED) ||
    workflowForm?.status?.includes(WF_STATUS_TYPES?.VOID) ||
    (workflowForm?.status?.includes(WF_STATUS_TYPES?.IN_PROGRESS) &&
      !workflow?.isFreeFlow);

  const [showAddComment, setShowAddComment] = useState(false);

  const [filesState, setFilesState] = useState(INITIAL_FILES);

  const handleDeleteTaskAndAllRecurrences = useCallback(
    async (tasks) => {
      const results = await Promise.allSettled(
        tasks?.map((task) => deleteTaskAndAllRecurrences(task))
      );
      return results;
    },
    [deleteTaskAndAllRecurrences]
  );

  const handleDeleteStepClick = () => {
    const wfRequest = workflowForm?.requestWorkflow?.[0];
    const workflowSteps = wfRequest?.steps;
    const updatedSteps = workflowSteps?.filter(
      (step) => step?.id !== stepTasksViewData?.id
    );

    const updatedWorkflow = {
      ...workflowForm,
      requestWorkflow: [{ ...wfRequest, steps: updatedSteps }],
    };

    // patch WF after step is deleted
    onPatch(
      {
        updatedResource: updatedWorkflow,
        originalResource: editedWorkflow,
      },
      {
        onSuccess: () => {
          // on successful deletion dispatch update to UI
          requestFormDispatch({
            type: "editModeWorkflow",
            payload: [{ ...wfRequest, steps: updatedSteps }],
          });

          // remove related tasks from API
          handleDeleteTaskAndAllRecurrences(
            stepTasksViewData?.tasks?.map((taskRef) => ({
              id: taskRef?.split("/")[1],
            }))
          );

          toastMessage("Workflow step successfully deleted");
          // when step is delete, show WF tab and restore WF edit state
          setShowStepDetails(false);
          setStepTasksViewData((prev) => ({ ...prev, show: false }));
        },
        onError: () => {
          toastError("There was an error deleting this workflow step");
        },
      }
    );
  };

  /**
   * Add new files selected to the current step
   */
  const handleFilesToAdd = async (newFiles) => {
    if (newFiles?.length) {
      postFiles(newFiles, {
        onSuccess: async (response) => {
          const responseFileReferences = response?.map((item) => {
            return { ref: item.reference, category: item.category };
          });

          // combine new files with old files
          // and update state
          const existingAttachments =
            workflow?.steps[stepTasksViewData?.index - 1].attachments;

          const attachmentsForStep = [
            ...existingAttachments,
            ...responseFileReferences,
          ];

          // update workflow with new task for current step
          const updatedSteps = workflow?.steps?.map((step) => {
            if (step?.id !== stepTasksViewData?.id) return step;
            return { ...step, attachments: attachmentsForStep };
          });

          // patch workflow with new files add to step
          onPatch(
            {
              updatedResource: {
                ...editedWorkflow,
                requestWorkflow: [{ ...workflow, steps: updatedSteps }],
              },
              originalResource: editedWorkflow,
            },
            {
              // create associated files to show on Files Tab for this WF
              onSuccess: async () => {
                await addFiles(attachmentsForStep);
                toastMessage("Workflow step updated successfully");
              },
              onError: () => {
                toastError("There was an error updating this workflow step");
              },
            }
          );

          // reset files state
          setFilesState(INITIAL_FILES);
        },
      });
    }
  };

  /**
   * Remove existing and new files from the state
   */
  const handleFilesToRemove = (fileToRemove, type = "media") => {
    // update state
    setFilesState((prev) => {
      const updatedFilesState = { ...prev };

      if (type === "media") {
        updatedFilesState.mediaFilesToAdd = prev.mediaFilesToAdd.filter(
          (file) => file !== fileToRemove
        );
      } else {
        updatedFilesState.nonMediaFilesToAdd = prev.nonMediaFilesToAdd.filter(
          (file) => file !== fileToRemove
        );
      }

      return updatedFilesState;
    });
    // Remove file from workflow step
    const updatedSteps = workflow?.steps?.map((step) => {
      if (step?.id !== stepTasksViewData?.id) return step;
      const updatedStepFiles = step?.attachments?.filter(
        ({ ref }) => ref !== fileToRemove?.ref
      );
      return { ...step, attachments: updatedStepFiles };
    });

    // set updated WF after files are deleted
    const updatedWorkflow = {
      ...editedWorkflow,
      requestWorkflow: [{ ...workflow, steps: updatedSteps }],
    };

    // patch WF after step is deleted
    onPatch(
      {
        updatedResource: updatedWorkflow,
        originalResource: editedWorkflow,
      },
      {
        onSuccess: () => {
          // update workflow UI
          requestFormDispatch({
            type: "editModeWorkflow",
            payload: [{ ...workflow, steps: updatedSteps }],
          });
        },
      }
    );
  };

  const handleRemoveTasks = useCallback(
    async (taskToDelete) => {
      try {
        // remove the deleted task and update steps
        const updatedSteps = workflow?.steps?.map((step) => {
          if (step?.id !== stepTasksViewData?.id) return step;
          return {
            ...step,
            tasks: step?.tasks?.filter(
              (task) => task !== taskToDelete?.reference
            ),
          };
        });

        // set updated WF after files are deleted
        const updatedWorkflow = {
          ...editedWorkflow,
          requestWorkflow: [{ ...workflow, steps: updatedSteps }],
        };

        // update workflow UI
        requestFormDispatch({
          type: "editModeWorkflow",
          payload: [{ ...workflow, steps: updatedSteps }],
        });

        // patch WF, then delete task
        onPatch(
          {
            updatedResource: updatedWorkflow,
            originalResource: editedWorkflow,
          },
          {
            onSuccess: () => {
              toastMessage("Task successfully removed from step");

              refetchWorkFlow();
              // remove newly this task from API
              handleDeleteTaskAndAllRecurrences([taskToDelete]);
            },
            onError: () => toastError("Failed to patch WF"),
          }
        );
      } catch (error) {
        console.warn(error);
        toastError("Failed to removed task from step");
      } finally {
        // allow fetching tasks to update tasks list in UI
        setcanFetchTasks(true);
      }
    },
    [
      refetchWorkFlow,
      editedWorkflow,
      handleDeleteTaskAndAllRecurrences,
      onPatch,
      requestFormDispatch,
      stepTasksViewData?.id,
      workflow,
    ]
  );

  const handleAddTasks = useCallback(
    (task) => {
      try {
        // set updated steps after new task added
        const updatedSteps = workflow?.steps?.reduce((acc, step) => {
          if (step?.id !== stepTasksViewData?.id) return [...acc, step];

          // make sure task.reference is not NULL
          if (task?.reference) step?.tasks.push(task.reference);
          return [...acc, step];
        }, []);

        // set updated WF after tasks are added
        const updatedWorkflow = {
          ...editedWorkflow,
          requestWorkflow: [{ ...workflow, steps: updatedSteps }],
        };

        // update workflow UI
        // requestFormDispatch({
        //   type: "editModeWorkflow",
        //   payload: [{ ...workflow, steps: updatedSteps }],
        // });

        // patch WF after step is added
        onPatch(
          {
            updatedResource: updatedWorkflow,
            originalResource: editedWorkflow,
          },
          {
            onSuccess: () => {
              toastMessage("Task successfully added to step");
              // update workflow UI
              requestFormDispatch({
                type: "editModeWorkflow",
                payload: [{ ...workflow, steps: updatedSteps }],
              });

              // refetch workflow atfert patch
              refetchWorkFlow();
            },
          },
          {
            onError: () => {
              toastMessage("Failed to add task to step");
              // remove created task after failed to patch WF
              handleDeleteTaskAndAllRecurrences([task]);
            },
          }
        );
      } catch (error) {
        console.warn(error);
      } finally {
        // allow fetching tasks to update tasks list in UI
        setcanFetchTasks(true);
      }
    },
    [
      editedWorkflow,
      handleDeleteTaskAndAllRecurrences,
      onPatch,
      requestFormDispatch,
      stepTasksViewData?.id,
      workflow,
      refetchWorkFlow,
    ]
  );

  const markTaskIncomplete = async (originalTask) => {
    const taskId = originalTask.id;

    // Preparing the updated task object with new duration and status
    const updatedTask = {
      ...originalTask,
      duration: { typeOfDuration: "calendarDay", value: 0 },
      status: "incomplete",
    };

    let newCommentRef = "";

    try {
      // Build a comment
      const newComment = {
        content:
          "This workflow task is marked incomplete because the step was voided",
        association: originalTask.reference,
        author: currentUser.reference,
      };
      // Posting the comment and retrieving the reference
      const commentResponse = await CommentAPI.post(newComment);
      newCommentRef = commentResponse?.data?.reference || "";

      // Setting the closing details of the task
      updatedTask.closing = {
        comment: newCommentRef,
        closedBy: currentUser.reference,
        time: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      };
      // Determining the task request path based on whether the task has a reference
      let taskRequestPath = taskId;
      if (originalTask.reference !== "") {
        const startDateUTC = moment(originalTask.startDate)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        taskRequestPath = `${taskId}/$${SINGLE_EVENT_INSTANCE}?instanceStartDate=${startDateUTC}`;
      }
      // Sending the patch request to update the task
      const taskResponse = await TaskAPI.patch(
        taskRequestPath,
        updatedTask,
        originalTask
      );

      // update tasks history
      queryClient.invalidateQueries(taskKeys.history(originalTask.id));

      return taskResponse.data;
    } catch (error) {
      // Display an error message in case of any exceptions
      toastError(error);
      return {};
    }
  };

  const handleVoidStep = async () => {
    // update API
    onStepAction(
      {
        id: workflowForm?.id,
        stepId: stepTasksViewData?.id,
        stepStatus: "void",
        stepAction: "respond",
        comment: "This step was voided by user",
      },
      {
        onSuccess: async (voidedStepWF) => {
          const formattedIncompleteTasks = [];

          // if step has tasks, mark them as incomplete
          if (stepTasksViewData?.tasks?.length > 0) {
            try {
              let incompleteTasksDict = {};
              const incompleteTasksResults = await Promise.allSettled(
                stepTasksViewData?.tasks?.map(async (taskRef) => {
                  // get full task properties from tasksDict
                  const tasksData = tasksDict?.[taskRef?.split("/")[1]] || {};
                  const res = await markTaskIncomplete(tasksData);
                  return res;
                })
              );

              // build formatted task for step details UI and build incomplete task dict to update appstate
              incompleteTasksResults?.forEach((task) => {
                incompleteTasksDict = {
                  ...incompleteTasksDict,
                  [task.value.id]: task.value,
                };

                formattedIncompleteTasks.push(formatTasks(task.value));
              });

              // update app tasksDict
              appStateDispatch({
                type: SET_TASKS_DICT,
                tasksDict: {
                  ...tasksDict,
                  ...incompleteTasksDict,
                },
              });
            } catch (error) {
              toastError(
                `Some or all the associated tasks could not be marked as "Incomplete"`
              );
              console.warn(error);
            }
          }
          // update UI state
          requestFormDispatch({
            type: "editModeWorkflow",
            payload: [{ ...workflow, steps: voidedStepWF.workflowSteps }],
          });

          // update step details UI status
          setStepTasksViewData((prev) => ({
            ...prev,
            status: getStepStatus(
              voidedStepWF.workflowSteps[stepTasksViewData?.index - 1],
              false
            ),
          }));

          toastMessage("Void step has been successful");
        },
      },
      {
        onError: (error) => {
          console.warn("ERROR: void step failed", error);
        },
      }
    );
  };

  const handleVoidClick = () => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      modalType: CONFIRM_MODAL,
      ref: { id: stepTasksViewData?.id },
      modalData: {
        item: {
          prompt:
            "Voiding a step will set all related tasked as incomplete. Are you sure you want to void this step, this action cannot be undone?",
          confirm: "Yes",
          cancel: "No",
          title: "Void Step",
          onConfirm: handleVoidStep,
        },
      },
      position: { x: 0, y: 0 },
    });
  };

  const handleAddCommentClick = useCallback(
    (msg) => {
      // if message is empty no job done
      if (msg.trim() === "") return;

      // get users refs from steps users
      const allUsers = workflow?.steps?.reduce((acc, step) => {
        return [...acc, ...step?.users];
      }, []);
      const uniqUsers = uniqBy(allUsers, "reference");

      let notifyUsers = [];
      if (
        uniqUsers?.length === 1 &&
        uniqUsers[0]?.reference === currentUser?.reference
      ) {
        notifyUsers.push(uniqUsers?.[0]);
      } else {
        notifyUsers = uniqUsers?.reduce((acc, user) => {
          if (user?.reference) acc.push(user?.reference);
          return acc;
        }, []);
      }

      // POST comment
      postComment(
        {
          association: fileAssociation,
          content: msg,
          notifyUsers,
        },
        {
          onSuccess: ({ data }) => {
            // update workflow step comments with new comment ref
            const updatedSteps = workflow?.steps?.map((step) => {
              if (step?.id !== stepTasksViewData?.id) return step;
              return { ...step, comments: [...step.comments, data.reference] };
            });

            // set updated WF after successfully posted
            const updatedWorkflow = {
              ...editedWorkflow,
              requestWorkflow: [{ ...workflow, steps: updatedSteps }],
            };

            // patch WF after comment successfully posted
            onPatch(
              {
                updatedResource: updatedWorkflow,
                originalResource: editedWorkflow,
              },
              {
                onSuccess: () => {
                  toastMessage("comment successfully added to step");
                  // update workflow UI
                  requestFormDispatch({
                    type: "editModeWorkflow",
                    payload: [{ ...workflow, steps: updatedSteps }],
                  });
                },
              }
            );
          },
          onError: () => {
            toastError("There was an error adding comment");
          },
        }
      );
    },
    [
      workflow,
      currentUser?.reference,
      postComment,
      fileAssociation,
      editedWorkflow,
      onPatch,
      stepTasksViewData?.id,
      requestFormDispatch,
    ]
  );

  const openConfirmDeleteStepModal = () => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      modalType: CONFIRM_MODAL,
      ref: { id: uuidv4() },
      modalData: {
        item: {
          prompt:
            "Deleting this step will delete all related tasks. Are you sure you want to delete this step? Once deleted, it cannot be recovered.",
          confirm: "Yes, delete",
          cancel: "No",
          title: "Delete Workflow Step",
          onConfirm: () => handleDeleteStepClick(),
        },
      },
      position: { x: 0, y: 0 },
    });
  };

  const openConfirmDeleteTaskModal = (task) => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      modalType: CONFIRM_MODAL,
      ref: { id: uuidv4() },
      modalData: {
        item: {
          prompt:
            "Are you sure you want to delete this task? Once deleted, it cannot be recovered.",
          confirm: "Yes, delete",
          cancel: "No",
          title: "Delete task",
          onConfirm: () => handleRemoveTasks(task),
        },
      },
      position: { x: 0, y: 0 },
    });
  };

  const openAddCommentModal = useCallback(() => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: uuidv4() },
      modalType: CREATE_WF_COMMENT_MODAL,
      modalData: { item: { createComment: handleAddCommentClick } },
    });
  }, [handleAddCommentClick, modalDispatch]);

  const handleBackToWorkflow = useCallback((task) => {
    // TODO (RQ-Perf) Task RQ will make some of this redundant
    // update current view with changes that occured in task, if any
    const formatedTask = { ...formatTasks(task) };
    formatedTask.formattedStatus = formattedTaskStatus(task?.status);
    if (task) {
      setFormattedTasks((prev) =>
        prev?.map((item) => (item?.id === task.id ? formatedTask : item))
      );
    }
    setcanFetchTasks(true);
    // do not show task
    setCurrentTask(null);
  }, []);

  return {
    assignee,
    value,
    label,
    location,
    reference,
    filesState,
    currentUser,
    formattedTasks,
    editableWFState,
    showVoidButton,
    handleAddTasks,
    handleFilesToAdd,
    handleFilesToRemove,
    handleVoidClick,
    setShowAddComment,
    showAddComment,
    openConfirmDeleteStepModal,
    openConfirmDeleteTaskModal,
    openAddCommentModal,
    currentTask,
    usedSopDict,
    setCurrentTask,
    handleBackToWorkflow,
  };
}

import React, { useEffect } from "react";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Dropdown from "../Dropdown/Dropdown";
import { budgetTypesOptions, budgetwDistroTooltip } from "../../../constants";
import BudgetTable from "../BudgetTable/BudgetTable";

const ActivateProjectForm = ({
  projectData,
  projectBudget,
  dispatchProjectBudget,
  setActivateProjectModal,
  setDraftBudgetLineItems,
}) => {
  const { projectId } = useParams();

  useEffect(() => {
    if (projectData?.reference) {
      dispatchProjectBudget({
        type: "reset",
        budget: {
          project: projectData?.reference,
          active: true,
        },
      });
    }
  }, [dispatchProjectBudget, projectData]);

  const onChangeBudgetType = (val) => {
    dispatchProjectBudget({
      type: "budgetType",
      budgetType: val.value,
    });
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-4">
        <Dropdown
          label="Budget Type"
          className="col-span-3"
          labelClassName="items-center"
          options={budgetTypesOptions}
          value={budgetTypesOptions?.find(
            (type) => type.value === projectBudget?.budgetType
          )}
          tooltipData={budgetwDistroTooltip}
          tooltipDataWrapperClassName="w-5 items-center justify-center"
          onChange={onChangeBudgetType}
          validation={yup.mixed().required()}
        />
      </div>
      <div className="flex pt-5 pb-2 items-center  -ml-3">
        <p className="font-semibold text-gray-300 ml-3">
          Reconcile Project Budget
        </p>
      </div>
      <BudgetTable
        budgetId={projectData?.budget?.split("/")[1]}
        projectId={projectId}
        projectData={!!Object.keys(projectData).length && projectData}
        isActivateProject
        setDraftBudgetLineItems={setDraftBudgetLineItems}
        setActivateProjectModal={setActivateProjectModal}
        disbaleRowActions
      />
    </>
  );
};

ActivateProjectForm.propTypes = {
  projectData: PropTypes.shape({
    budget: PropTypes.string,
    budgetType: PropTypes.string,
    reference: PropTypes.string,
  }),
  projectBudget: PropTypes.shape({
    budgetType: PropTypes.string,
  }),
  dispatchProjectBudget: PropTypes.func,
  setActivateProjectModal: PropTypes.func,
  setDraftBudgetLineItems: PropTypes.func,
};

ActivateProjectForm.defaultProps = {
  projectData: undefined,
  projectBudget: undefined,
  dispatchProjectBudget: undefined,
  setActivateProjectModal: undefined,
  setDraftBudgetLineItems: undefined,
};

export default ActivateProjectForm;

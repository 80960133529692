import { QueryClient } from "react-query";
import {
  DEFAULT_QUERIES_CACHE_TIME,
  DEFAULT_QUERIES_STALE_TIME,
} from "../../constants";

export default new QueryClient({
  defaultOptions: {
    queries: {
      // Garbage collector will remove this data after X seconds of inactivity
      cacheTime: DEFAULT_QUERIES_CACHE_TIME,
      // All queries will be considered stale after X seconds
      staleTime: DEFAULT_QUERIES_STALE_TIME,
      // Queries are going to re-render just when tracked properties change
      notifyOnChangeProps: "tracked",
      // During development RQ is refetching multiple times when the devtool is being used. Enabled only for production
      refetchOnWindowFocus: process.env.NODE_ENV !== "development",
      // Will retry API calls once upon failure
      retry: 1,
    },
  },
});

import React from "react";
import Pill from "../../Pill/Pill";
import usePillsContainerData from "./usePillsContainerData";

const PillsContainer = ({ data = [], selectedPills = [], onClick }) => {
  const { handlePillClick } = usePillsContainerData(onClick);

  return (
    <div className="flex gap-4 flex-wrap pt-4 p-2">
      {data.map((pill) => (
        <Pill
          key={pill.value}
          value={pill.label}
          onClick={(event) => handlePillClick(event, pill)}
          textSize="text-xs"
          textWeight="font-normal"
          background={
            selectedPills.includes(pill.value) ? "bg-green-50" : "bg-white"
          }
          border={
            selectedPills.includes(pill.value)
              ? "border border-darkenedGreen"
              : "border border-green-500"
          }
          textColor="text-darkenedGreen"
        />
      ))}
    </div>
  );
};

export default PillsContainer;

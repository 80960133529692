import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import Dropdown from "../Dropdown/Dropdown";
import Input from "../Input/Input";
import BaseButton from "../Buttons/BaseButton";
import errorIcon from "../../assets/images/redExclamationIcon.svg";

const primaryButtonCN = cntl`
  rounded-md
  bg-es-green
  hover:bg-opacity-90
`;

const buttonCN = (showGreenBorder) => cntl`
  flex
  flex-col
  rounded-lg
  ${
    showGreenBorder
      ? "border-2 border-es-green"
      : "border-2 border-es-light-grey"
  }
`;

const innerCN = (className) => cntl`
  flex
  flex-row
  items-start
  w-full
  ${className}
`;

const formatOptionLabel = ({ label, subLabel }) => (
  <div className="flex flex-row items-center gap-1">
    <div className="text-es-normal text-es-dark-grey font-es-semibold">
      {label}
    </div>
    <div className="font-normal text-sm text-es-dark-grey">({subLabel})</div>
  </div>
);

const PlanSelection = ({
  changeFrequency,
  frequency,
  onPlanChange,
  onPlanUpdate,
  onUserChange,
  selectedPlan,
  subscriptionPlans,
  supportedPlan,
  userCount,
  userCountError,
  isPlanComplete,
  handleNextBtn,
}) => {
  return (
    <>
      <div className={innerCN()}>
        <div className="mb-4 w-full">
          <p className="flex text-es-dark-grey text-md font-es-semibold pb-2">
            Select Plan Level
          </p>
          <div style={{ width: "330px" }}>
            <Dropdown
              formatOptionLabel={formatOptionLabel}
              options={subscriptionPlans}
              onChange={(val) => onPlanChange(val)}
              value={selectedPlan}
              disableClear
              disableSearch
            />
          </div>
        </div>
        <div className="mb-4 px-4 w-full">
          <p className="flex text-es-dark-grey text-md font-es-semibold pb-2">
            Number of Users
          </p>
          <div className={innerCN("flex-row")}>
            <div className="mr-2 truncate rounded-md">
              <Input
                placeholder="Count"
                className="flex w-full"
                inputContainerClassName="rounded-md"
                value={userCount}
                disableClear
                onChange={(val) => onUserChange(val)}
              />
              <p
                className="flex pt-2 text-es-dark-grey font-normal"
                style={{ fontSize: "12px" }}
              >
                You can always add more users.
              </p>
            </div>
            {userCountError && (
              <img width="20" src={errorIcon} alt="errorIcon" />
            )}
          </div>
          <p className="flex py-2 text-red-600 text-xs font-normal">
            {userCountError}
          </p>
          {userCountError && supportedPlan && (
            <BaseButton
              className="min-w-max"
              title={`Switch to ${supportedPlan.planName.toLowerCase()} plan`}
              colorCN="text-brandGreen"
              onClick={() => onPlanUpdate()}
            />
          )}
        </div>
        <div className="w-full mb-6">
          <div className="flex flex-row gap-4">
            <button
              key="ANNUAL"
              type="button"
              className={buttonCN(frequency === "annual")}
              onClick={() => changeFrequency("annual")}
              style={{ width: "201px", height: "140px" }}
            >
              <div className="px-6 py-4">
                <div className={innerCN("justify-start")}>
                  <h2 className="font-es-semibold text-es-dark-grey text-es-lg">
                    ${selectedPlan.annualCost}
                  </h2>
                </div>
                <div className={innerCN("justify-start")}>
                  <h2 className="text-es-dark-grey text-sm font-es-semibold">
                    ANNUAL
                  </h2>
                </div>
                <div className={innerCN("justify-start")}>
                  <p className="flex pb-2 text-es-dark-grey text-xs font-normal">
                    Per user
                  </p>
                </div>
                <div className={innerCN("justify-start")}>
                  <p className="flex text-primaryGreen bg-borderGreen bg-opacity-10 font-es-semibold rounded text-xs py-1 px-2">
                    {selectedPlan.discount}% Discount
                  </p>
                </div>
              </div>
            </button>
            <button
              key="MONTHLY"
              type="button"
              className={buttonCN(frequency === "monthly")}
              onClick={() => changeFrequency("monthly")}
              style={{ width: "201px", height: "140px" }}
            >
              <div className="px-6 py-4">
                <div className={innerCN("justify-start")}>
                  <h2 className="font-es-semibold text-es-dark-grey text-es-lg">
                    ${selectedPlan.monthlyCost}
                  </h2>
                </div>
                <div className={innerCN("justify-start")}>
                  <h2 className="text-es-dark-grey text-sm font-es-semibold">
                    MONTHLY
                  </h2>
                </div>
                <div className={innerCN("justify-start")}>
                  <p className="flex pb-2 text-es-dark-grey text-xs font-normal">
                    Per user
                  </p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className={innerCN("justify-end")}>
        {!userCountError && (
          <div className="flex flex-col pr-1">
            <div className="flex justify-end">
              <BaseButton
                style={{ minWidth: "156px", minHeight: "48px" }}
                title="Next"
                className={primaryButtonCN}
                onClick={handleNextBtn}
                disabled={!isPlanComplete}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

PlanSelection.propTypes = {
  changeFrequency: PropTypes.func,
  discount: PropTypes.string,
  frequency: PropTypes.string,
  onPlanChange: PropTypes.func,
  onPlanUpdate: PropTypes.func,
  onUserChange: PropTypes.func,
  selectedPlan: PropTypes.shape({
    planName: PropTypes.string,
    userText: PropTypes.string,
    minUsers: PropTypes.number,
    maxUsers: PropTypes.number,
    monthlyCost: PropTypes.string,
    annualCost: PropTypes.string,
    discount: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.string),
    priceIdMonthly: PropTypes.string,
    priceIdAnnual: PropTypes.string,
  }),
  subscriptionPlans: PropTypes.arrayOf(
    PropTypes.shape({
      planName: PropTypes.string,
      userText: PropTypes.string,
      minUsers: PropTypes.number,
      maxUsers: PropTypes.number,
      monthlyCost: PropTypes.string,
      annualCost: PropTypes.string,
      discount: PropTypes.string,
      features: PropTypes.arrayOf(PropTypes.string),
      priceIdMonthly: PropTypes.string,
      priceIdAnnual: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
      subLabel: PropTypes.string,
    })
  ),
  supportedPlan: PropTypes.shape({
    planName: PropTypes.string,
    userText: PropTypes.string,
    minUsers: PropTypes.number,
    maxUsers: PropTypes.number,
    monthlyCost: PropTypes.string,
    annualCost: PropTypes.string,
    discount: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.string),
    priceIdMonthly: PropTypes.string,
    priceIdAnnual: PropTypes.string,
  }),
  userCount: PropTypes.number,
  userCountError: PropTypes.string,
  isPlanComplete: PropTypes.bool,
  handleNextBtn: PropTypes.func,
};

PlanSelection.defaultProps = {
  selectedPlan: undefined,
  changeFrequency: undefined,
  discount: undefined,
  frequency: undefined,
  onPlanChange: undefined,
  onPlanUpdate: undefined,
  onUserChange: undefined,
  subscriptionPlans: [],
  supportedPlan: undefined,
  userCount: 0,
  userCountError: undefined,
  isPlanComplete: false,
  handleNextBtn: undefined,
};

export default PlanSelection;

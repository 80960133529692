import React from "react";
import PropTypes from "prop-types";

import PrimaryButton from "../Buttons/PrimaryButton";
import BaseButton from "../Buttons/BaseButton";

function GetStartedCircle({
  className,
  title,
  onGetStartedClicked,
  hasAlternateOption,
  alternateOptionTitle,
  onAlternateOptionClicked,
  disabled,
}) {
  return (
    <div className={`get-started-circle ${className}`}>
      <div className="flex flex-col justify-center items-center h-72 w-72 rounded-full border-gray-100 border-2">
        <p className="uppercase mb-3 text-center">{title}</p>
        <p className="text-gray-300 text-sm font-semibold">
          You don&apos;t have
        </p>
        <p className="text-gray-300 text-sm font-semibold mb-3">
          anything here yet.
        </p>
        <PrimaryButton
          title="Get Started"
          onClick={onGetStartedClicked}
          disabled={disabled}
        />
        {hasAlternateOption && (
          <div className="flex flex-row py-2">
            <p className="text-gray-300 text-sm pr-2">or</p>
            <BaseButton
              style={{ minWidth: 80 }}
              title={alternateOptionTitle}
              className="text-brandGreen text-sm font-normal"
              onClick={onAlternateOptionClicked}
              disableCapitalize
            />
          </div>
        )}
      </div>
    </div>
  );
}

GetStartedCircle.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onGetStartedClicked: PropTypes.func,
  hasAlternateOption: PropTypes.bool,
  alternateOptionTitle: PropTypes.string,
  onAlternateOptionClicked: PropTypes.func,
  disabled: PropTypes.bool,
};

GetStartedCircle.defaultProps = {
  className: "",
  title: "Title Goes Here",
  onGetStartedClicked: undefined,
  hasAlternateOption: false,
  alternateOptionTitle: "",
  onAlternateOptionClicked: undefined,
  disabled: false,
};

export default GetStartedCircle;

import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import editIcon from "../../assets/images/editpencilgreen.svg";

const editButtonCN = cntl`
  w-4
  h-4
`;

const ManagementTagsTableEditCell = ({ row, dispatch }) => {
  return (
    <div className="flex flex-row items-center">
      <div>
        <button
          className={editButtonCN}
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            dispatch({
              type: "reset",
              tag: row?.original,
            });
          }}
        >
          <img className="w-8 cursor-pointer" src={editIcon} alt="edit data" />
        </button>
      </div>
    </div>
  );
};

ManagementTagsTableEditCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      notes: PropTypes.string,
    }),
  }),
  dispatch: PropTypes.func,
};

ManagementTagsTableEditCell.defaultProps = {
  row: undefined,
  dispatch: undefined,
};

export default ManagementTagsTableEditCell;

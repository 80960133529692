import { useMemo, useState } from "react";
import {
  getAdminUsers,
  transformUsersToMentionsStructure,
} from "../../../helpers/Comments";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { useAppState } from "../../../state/appState";
import useEsModalData from "../EsModal/useEsModalData";

// Admins users always needs to be in this list
const useCreateWFCommentModalData = (modalData) => {
  const { closeModal: close } = useEsModalData(null, modalData);
  const [{ users: allESUsers }] = useAppState();
  const { data: currentUser } = useCurrentUser();

  const [message, setMessage] = useState("");

  const mentionSuggestions = useMemo(() => {
    let suggestions = [];

    // use admin users as suggestions
    suggestions = getAdminUsers(allESUsers || []);

    return transformUsersToMentionsStructure(suggestions, currentUser);
  }, [allESUsers, currentUser]);

  return { mentionSuggestions, close, message, setMessage };
};

export default useCreateWFCommentModalData;

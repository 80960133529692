import { useParams } from "react-router";
import { useAssetById, useAssetsPatch } from "../../../hooks/assets";
import useQueryNotFoundNavigation from "../../../hooks/navigation/useQueryNotFoundNavigation";

const useAssetDetailsData = () => {
  const { assetId } = useParams();

  const { data: asset, isLoading, error } = useAssetById(assetId);

  const { mutate: patchAsset, mutateAsync: patchAssetAsync } = useAssetsPatch();

  // Redirect to 404 page if resource is not found
  useQueryNotFoundNavigation({ error });

  return { assetId, asset, isLoading, patchAsset, patchAssetAsync };
};

export default useAssetDetailsData;

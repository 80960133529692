import { PropertyAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import propertyKeys from "./propertiesKeys";

const deleteProperty = (id) => {
  return PropertyAPI.delete(id);
};

const usePropertiesDelete = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteProperty,
    onMutate: async (id) => {
      const queryKey = propertyKeys.all;

      // Get the current state of the property to rollback in case of any error
      const currentProperties = queryClient.getQueryData(queryKey) || [];

      // Cancel any query for properties in the meantime
      await queryClient.cancelQueries(queryKey);

      // Find the property to delete by Id
      const index = currentProperties.findIndex(
        (property) => property.id === id
      );

      if (index !== -1) {
        queryClient.setQueryData(queryKey, (current) => {
          const updatedPropertyList = current.slice();
          updatedPropertyList.splice(index, 1);

          return updatedPropertyList;
        });
      }

      return { currentProperties };
    },
    onError: (error, _, context) => {
      // Rollback to previous state
      const { queryKey, currentProperties } = context;
      queryClient.setQueryData(queryKey, currentProperties);

      console.error("usePropertiesDelete: ", error);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: propertyKeys.all,
        exact: true,
      });

      queryClient.invalidateQueries({
        queryKey: propertyKeys.overview(),
        exact: true,
      });
    },
  });
};

export default usePropertiesDelete;

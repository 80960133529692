import React, { useState } from "react";
import SecondaryHeader from "../../TextHeaders/SecondaryHeader";
import RequestWorkflowStepCard from "./RequestWorkflowStepCard";

export default function RequestWorkflowContainer({
  workflow,
  index,
  requestData,
  setRequestData,
  currentUser,
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      {!open && (
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          className="w-full border p-4 flex justify-start items-center"
          onClick={() => setOpen(true)}
        >
          <SecondaryHeader>Workflow {index + 1}</SecondaryHeader>
        </div>
      )}
      {open && (
        <div className="w-full">
          {workflow?.steps?.map((step) => {
            return (
              <RequestWorkflowStepCard
                key={`${step?.position}-${step?.type}-${step?.name}`}
                requestData={requestData}
                setRequestData={setRequestData}
                index={index}
                step={step}
                currentUser={currentUser}
                isSubmittal
              />
            );
          })}
        </div>
      )}
    </>
  );
}

import cntl from "cntl";
import React from "react";
import resourceDefault from "../../../assets/images/diamond.svg";
import locationIcon from "../../../assets/images/locationGrey.svg";
import managementIcon from "../../../assets/images/userGearIconGrey.svg";
import commentIconGrey from "../../../assets/images/commentGreyIcon.svg";
import recurringEventGrey from "../../../assets/images/recurringEventIconGrey.svg";
import timeIcon from "../../../assets/images/timeIcon.svg";
import useTaskCardData from "./useTaskCardData";
import Pill from "../../Pill/Pill";
import RoleMembersAvatarList from "../../../../Pages/Admin/RoleMembersAvatarList";
import { truncateLabel } from "../../../../helpers/Tag";

const statusCN = (status) => cntl`
border 
flex 
items-center 
rounded-full
font-medium
text-sm
py-2 
px-3
shadow-light-lift
${status}
`;

const TaskCard = ({
  key,
  name,
  startDate,
  endDate,
  id,
  association,
  files,
  status,
  onTaskClick,
  invitees,
  metadata,
  recurrence,
  reference,
  handleOpenComments,
  instanceStartDate,
}) => {
  const {
    taskTime,
    taskTimeTitle,
    location,
    photo,
    imageAvailable,
    state,
    creator,
    members,
    taskStatusPillCN,
    history,
    rsvpData,
  } = useTaskCardData({
    association,
    files,
    status,
    invitees,
    metadata,
    startDate,
    endDate,
    reference,
    instanceStartDate,
  });

  return (
    <div
      key={key}
      className="rounded-lg border border-es-light-grey flex flex-col"
      style={{
        width: "390px",
        height: "240px",
      }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          onTaskClick();
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
          onTaskClick();
        }}
        tabIndex={0}
        aria-roledescription={`Click to navigate to the task, ${name} `}
        role="button"
        className="flex flex-col h-full w-full justify-evenly"
      >
        <p
          style={{ width: "350px" }}
          className="flex items-center text-es-normal justify-start text-es-black font-es-semibold pl-6 pt-2 flex-wrap"
          title={name}
        >
          {truncateLabel(name, 68)}
        </p>
        <div className="flex flex-row items-center justify-start px-6 gap-3">
          {imageAvailable && (
            <div className="w-fit flex flex-col h-full">
              <img
                alt={`${id}-task`}
                className="flex rounded-md object-cover shadow-light-lift"
                style={{
                  height: "64px",
                  width: "64px",
                  minWidth: "64px",
                  minHeight: "64px",
                }}
                src={photo ?? resourceDefault}
              />
            </div>
          )}
          {/* Task Info Column contains 3 rows */}
          <div id="task-info-wrapper" className="flex flex-col gap-3 w-full">
            {/* Task Time Row */}
            <div
              id="task-time-date"
              className="flex flex-row justify-start gap-3 w-full items-center truncate"
              title={taskTimeTitle}
            >
              <img
                alt="time-date-icon"
                src={timeIcon}
                style={{ height: "24px", width: "24px" }}
              />
              <span className="text-es-normal font-es-normal text-es-medium-grey">
                {taskTime}
              </span>
            </div>

            {/* Task Location Row */}
            <div
              id="task-location"
              className="flex flex-row justify-start gap-3 w-full items-center truncate"
            >
              <img
                alt="location-icon"
                src={locationIcon}
                style={{ height: "24px", width: "24px" }}
              />
              <button
                className="hover:underline cursor-pointer truncate"
                style={{ maxWidth: "200px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(location?.route);
                }}
                type="button"
                title={location?.name}
                aria-roledescription={`Click link to navigate to the task to the association, ${location?.name}`}
              >
                <p className="text-es-normal font-es-normal text-es-medium-grey">
                  {location?.name}
                </p>
              </button>
            </div>

            {/* Task Invitees || Creator row */}
            <div className="flex flex-row justify-start gap-3 w-full items-center">
              <img
                alt="Invitees"
                style={{ minHeight: "26px", minWidth: "26px" }}
                src={managementIcon}
              />
              <RoleMembersAvatarList
                showRsvpStatus
                rsvpData={rsvpData}
                showExtraCount
                membersToShowCount={4}
                members={members?.length > 0 ? members : [creator]}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Info Row - Status icon, recurring status, comment link */}
      <div
        style={{ minHeight: "64px" }}
        className="flex flex-row w-full items-center justify-between rounded-b-lg bg-backgroundGrey px-6 py-4"
      >
        {/* TODO: (Parker) Pill defaults to bg-brandGreen if no bg prop is passed */}
        <Pill
          value={state}
          background=""
          className={statusCN(taskStatusPillCN)}
        />
        <div className="flex flex-row items-center gap-3">
          <img
            alt="recurring event"
            className={recurrence ? "flex" : "hidden"}
            src={recurringEventGrey}
            style={{ height: "24px", width: "24px" }}
          />
          <button type="button" onClick={handleOpenComments}>
            <img
              alt="open comments"
              src={commentIconGrey}
              style={{ height: "24x", width: "24px" }}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaskCard;

import React from "react";
import PropTypes from "prop-types";
import ReportsTable from "../../../stories/Components/ReportTable";
import useReportsOverviewData from "./useReportsOverviewData";
import ReportSingleView from "../../../stories/Components/ReportSingleView";

/**
 * Component that renders Report lists and Report single view inside P/P/A
 */
const ReportsOverview = ({ association, setButtonActions }) => {
  const { isLoading, reports, reportId, setReportId } = useReportsOverviewData(
    association,
    setButtonActions
  );

  if (reportId) {
    return (
      <ReportSingleView
        reportId={reportId}
        association={association}
        setButtonActions={setButtonActions}
        onBackButtonPressed={setReportId}
      />
    );
  }

  return (
    <ReportsTable
      association={association}
      reports={reports}
      isLoading={isLoading}
      onReportClicked={setReportId}
    />
  );
};

ReportsOverview.defaultProps = {
  association: undefined,
  setButtonActions: undefined,
};

ReportsOverview.propTypes = {
  association: PropTypes.string,
  setButtonActions: PropTypes.func,
};

export default ReportsOverview;

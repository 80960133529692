import { useCallback } from "react";
import moment from "moment-timezone";
import { useAppState } from "../state/appState";
import { SET_CALENDAR_TIMEZONE } from "../constants";
import { usePropertiesOverview } from "./properties";
import { useProjectsOverview } from "./projects";
import { useAssetsOverview } from "./assets";

const getFromLocalStorage = (reference) => {
  const key = `timezone-${reference}`;
  const savedTZ = localStorage.getItem(key);
  return savedTZ;
};

const saveinLocalStorage = (reference, value) => {
  const key = `timezone-${reference}`;
  localStorage.setItem(key, value || moment.tz.guess());
};

// initially save tz in local storage,
// fetch from local state, if not exist then store
// if one changes for a resource, update local storage
export default (project, property, asset) => {
  const [{ calendarTimezone }, dispatchAppState] = useAppState();

  const { assetsDict } = useAssetsOverview();
  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  const getAssetParentResource = useCallback(
    (assetRef) => {
      const resource = assetsDict[assetRef];
      const parentAssociationReference =
        resource?.property || resource?.project;

      return (
        propertiesDict[parentAssociationReference] ||
        projectDict[parentAssociationReference]
      );
    },
    [assetsDict, propertiesDict, projectDict]
  );

  const setState = useCallback(
    (value) => {
      dispatchAppState({
        type: SET_CALENDAR_TIMEZONE,
        calendarTimezone: value || moment.tz.guess(),
      });
    },
    [dispatchAppState]
  );

  const updateTimezone = useCallback(
    (reference, value) => {
      saveinLocalStorage(reference, value);
      setState(value);
    },
    [setState]
  );

  const processResourceTimezone = useCallback(
    (resource) => {
      const currentTZ = getFromLocalStorage(resource?.reference);
      if (currentTZ) {
        if (currentTZ !== resource?.timezone) {
          updateTimezone(resource?.reference, resource?.timezone);
        } else {
          setState(currentTZ);
        }
      } else {
        updateTimezone(resource?.reference, resource?.timezone);
      }
    },
    [setState, updateTimezone]
  );

  // set default tz based on parent resource
  const refreshTimezone = useCallback(() => {
    if (project?.timezone) {
      processResourceTimezone(project);
    } else if (property?.timezone) {
      processResourceTimezone(property);
    } else if (asset?.reference) {
      const parentResource = getAssetParentResource(asset?.reference);
      processResourceTimezone(parentResource);
    } else {
      // if not timezone specified, then use overview tz
      const currentTZ = getFromLocalStorage("overview");
      if (currentTZ) {
        setState(currentTZ);
      } else {
        updateTimezone("overview");
      }
    }
  }, [
    asset?.reference,
    getAssetParentResource,
    processResourceTimezone,
    project,
    property,
    setState,
    updateTimezone,
  ]);

  return { refreshTimezone, calendarTimezone, updateTimezone };
};

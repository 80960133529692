import React from "react";
import Checkbox from "../../../Checkbox/Checkbox";
import TaskHistoryRow from "./TaskHistoryRow";
import Gallery from "../../../Gallery";
import AttachmentsHorizontalList from "../../../AttachmentsHorizontalList";
import TextWithMention from "../../../TextWithMention";
import useTaskHistoryData from "./useTaskHistoryData";
import TaskStepText from "../TaskSteps/TaskStepText";

const TaskHistory = ({ currentTask, setCurrentTask }) => {
  const {
    filteredData,
    onButtonClick,
    onRowClick,
    expandedRowInfo,
    lastRowReferenceForInfiniteScroll,
    onChangeFilter,
    shouldBlockTaskNavigation,
    selectedFilter,
  } = useTaskHistoryData(currentTask);

  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
        <p className="text-gray-500 font-semibold text-2xl w-1/2">
          Task History
        </p>
        <div className="flex">
          <Checkbox
            className="text-gray-450 mr-6"
            inputClassName=" w-6 h-6"
            labelClassName="text-sm"
            label="All"
            checked={selectedFilter.all}
            onChange={() => onChangeFilter("all")}
          />
          <Checkbox
            className="text-gray-450 mr-6"
            inputClassName=" w-6 h-6"
            labelClassName="text-sm"
            label="Complete"
            checked={selectedFilter.complete}
            onChange={() => onChangeFilter("complete")}
          />
          <Checkbox
            className="text-gray-450 mr-6"
            inputClassName=" w-6 h-6"
            labelClassName="text-sm"
            label="Incomplete"
            checked={selectedFilter.incomplete}
            onChange={() => onChangeFilter("incomplete")}
          />
          <Checkbox
            className="text-gray-450"
            inputClassName=" w-6 h-6"
            labelClassName="text-sm"
            label="Overdue"
            checked={selectedFilter.overdue}
            onChange={() => onChangeFilter("overdue")}
          />
        </div>
      </div>
      {filteredData.map((row, idx) => (
        <div
          key={row.instanceStartDate}
          className="grid border rounded-lg mt-6"
        >
          <TaskHistoryRow
            index={idx}
            task={row}
            allInviteesInfo={row?.invitees}
            onButtonClick={onButtonClick}
            onRowClick={onRowClick}
            shouldBlockTaskNavigation={shouldBlockTaskNavigation}
            isExpanded={!!expandedRowInfo[idx]}
            lastRowReferenceForInfiniteScroll={
              lastRowReferenceForInfiniteScroll
            }
          />
          {!!expandedRowInfo[idx] && (
            <div className="bg-es-extra-light-grey p-8 rounded-b-lg">
              <div className="grid gap-4">
                <div className="flex gap-4">
                  <div className="flex-col w-1/3">
                    <h3 className="text-gray-450 font-semibold text-lg mb-3">
                      Steps
                    </h3>
                    {row?.steps?.length > 0 ? (
                      row?.steps?.map((sop, index) => (
                        <div
                          key={sop?.name || sop?.stepData?.description}
                          className="flex"
                        >
                          <TaskStepText
                            sop={sop}
                            index={index}
                            currentTask={currentTask}
                            setCurrentTask={setCurrentTask}
                            disableMarkComplete
                            defaultExpandStep={false}
                          />
                        </div>
                      ))
                    ) : (
                      <p className="text-sm text-gray-450">
                        There are no steps saved to this task
                      </p>
                    )}
                  </div>
                  {row.status !== "overdue" && (
                    <div className="flex-col w-2/3">
                      <div className="flex-col mb-10">
                        <h3 className="text-gray-450 font-semibold text-lg mb-3">
                          Media
                        </h3>
                        {row?.mediaFiles?.length > 0 ? (
                          <Gallery
                            isEditing={false}
                            mediaFiles={row?.mediaFiles}
                            handleFilesToRemove={() => {}}
                          />
                        ) : (
                          <p className="text-sm text-gray-450">
                            There are no media saved to this task
                          </p>
                        )}
                      </div>
                      <div className="flex-col mb-10">
                        <h3 className="text-gray-450 font-semibold text-lg mb-3">
                          Attachments
                        </h3>
                        {row?.documentFiles?.length > 0 ? (
                          <AttachmentsHorizontalList
                            files={row?.documentFiles}
                            isEditing={false}
                            onFileRemoved={() => {}}
                          />
                        ) : (
                          <p className="text-sm text-gray-450">
                            There are no attachments saved to this task
                          </p>
                        )}
                      </div>
                      <div className="flex-col">
                        <h3 className="text-gray-450 font-semibold text-lg mb-3">
                          Duration
                        </h3>
                        <p className="text-sm text-gray-450">{row?.duration}</p>
                      </div>
                    </div>
                  )}
                </div>
                {row.status !== "overdue" && <div className="border-b mb-4" />}
                {row.status !== "overdue" && (
                  <div className="grid col-auto gap-4">
                    <h3 className="text-gray-450 font-semibold text-lg">
                      Comment
                    </h3>
                    {row?.formattedComments ? (
                      <div className="flex items-start pb-2 w-full">
                        <p
                          className="flex items-start h-full mr-7"
                          style={{ width: "400px" }}
                        >
                          {row?.formattedComments?.date}{" "}
                          {row?.formattedComments?.time}
                        </p>
                        <div className="flex justify-start w-full py-1">
                          <TextWithMention comment={row?.formattedComments} />
                        </div>
                      </div>
                    ) : (
                      <p className="text-sm text-gray-450">
                        There are no comments saved to this task
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TaskHistory;

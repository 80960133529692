import {
  CALENDAR_MONTH_EVENT_DATE_OFFSET,
  CALENDAR_MONTH_EVENT_HEIGHT,
  CALENDAR_MONTH_TEXT_VERTICAL_CENTER,
} from "../../../../../../constants";

const useEventCircleData = () => {
  //  function to calculate the Y position of the circle
  const circleYPosition = (iterator, place) => {
    return (
      iterator * 35 +
      CALENDAR_MONTH_EVENT_HEIGHT * (place - 1) +
      CALENDAR_MONTH_TEXT_VERTICAL_CENTER -
      0.25 +
      CALENDAR_MONTH_EVENT_DATE_OFFSET
    );
  };
  return { circleYPosition };
};

export default useEventCircleData;

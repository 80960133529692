import React, { useCallback, useState, useRef } from "react";
import PropTypes from "prop-types";

import Modal from "../Modal/Modal";
import Input from "../Input/Input";
import PasswordValidationRulesList from "../PasswordValidationRulesList/PasswordValidationRulesList";
import usePasswordValidator from "../../../hooks/usePasswordValidator";

function ChangePasswordModal({ isModalOpen, setModalOpen, onSaveNewPassword }) {
  const inputRefs = useRef({});
  const [newPassword, setNewPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({});

  /**
   * When the password is changed, run the password validation rules against it.
   */
  usePasswordValidator(
    newPassword,
    confirmationPassword,
    setPasswordValidation,
    setIsNewPasswordValid
  );

  const handleSave = () => {
    if (isNewPasswordValid) onSaveNewPassword(newPassword);
  };

  const handleEnter = (event) => {
    if (event.keyCode === 13) handleSave();
  };

  /**
   * Automatically moves cursor to next input field on pressing Enter
   */
  const handleKeyDown = useCallback((event) => {
    // checks if the Tab key was pressed
    if (event.keyCode === 9) {
      const inputCategories = Object.keys(inputRefs.current);
      const currentInputCategoryIdx = inputCategories.indexOf(
        event.target.name
      );
      const nextInputCategory =
        inputRefs.current[inputCategories[currentInputCategoryIdx + 1]];

      if (event.target.name.includes("password")) {
        nextInputCategory.focus();
      } else if (event.target.name.includes("confirmPassword")) {
        inputRefs.current[inputCategories[currentInputCategoryIdx - 1]].focus();
      }
    }
  }, []);

  return (
    <Modal
      title="Change Password"
      isOpen={isModalOpen}
      onRequestModalClose={() => setModalOpen(false)}
      hideFooter
      primaryButtonTitle="Save"
      primaryButtonOnClick={handleSave}
      primaryButtonStyle={{ marginLeft: "0px" }}
      buttonCnStyle={{ marginLeft: "0px", marginRight: "0px" }}
      tertiaryDelete
      tertiaryButtonTitle="cancel"
      tertiaryButtonClass="mr-2"
      disabled={!isNewPasswordValid}
      alert
    >
      <div>
        <Input
          autoFocus
          className="mt-5 w-full"
          placeholder="New Password"
          label="Enter New Password"
          type="password"
          name="password"
          onChange={setNewPassword}
          value={newPassword}
          forwardedRef={(el) => {
            inputRefs.current.password = el;
          }}
          toggleVisibility
          onKeyDown={handleKeyDown}
          disableClear
        />
        <PasswordValidationRulesList
          passwordValidation={passwordValidation}
          isValidPassword={isNewPasswordValid}
          className="mt-5"
        />
        <Input
          className="mt-5 w-full"
          placeholder="Retype New Password"
          label="Retype New Password"
          type="password"
          name="confirmPassword"
          onChange={setConfirmationPassword}
          value={confirmationPassword}
          forwardedRef={(el) => {
            inputRefs.current.confirmPassword = el;
          }}
          toggleVisibility
          handleEnter={(event) => {
            handleEnter(event);
          }}
          onKeyDown={handleKeyDown}
          disableClear
        />
      </div>
    </Modal>
  );
}

ChangePasswordModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  onSaveNewPassword: PropTypes.func.isRequired,
};

export default ChangePasswordModal;

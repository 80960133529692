import { useMemo } from "react";
import { useAppState } from "../../../state/appState";

const useDistroSelectorData = ({ selectedMembersRef = [] }) => {
  const [{ userDict }] = useAppState();

  // Transform array of user references into array of full member object from userDict
  const selectedFullMembers = useMemo(
    () => selectedMembersRef.map((memberRef) => userDict?.[memberRef]),
    [selectedMembersRef, userDict]
  );

  return {
    selectedFullMembers,
  };
};

export default useDistroSelectorData;

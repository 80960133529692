import { TIMESHEET_NO_RATES_ERROR } from "../../../../../constants";
import calculateTotalHours from "../../../../../helpers/Timesheets/calculateTotalsByDate";
import gatherSheetInfo from "../../../../../helpers/Timesheets/gatherSheetInfo";

/**
 * Processes timesheet entries for viewing and removes unnecessary section data needed for total and removal section.
 *
 * @param {Object} entriesForEditing - An object containing key-value pairs where keys are entry IDs, values contain details about each entry including project information.
 * @param {Object} projectsState - The current state of all projects with relevant rate sheets or codes if applicable.
 * @param {Object} financialsConfiguration - Configuration for the financial system which might include ratesheets to use as fallback sources.
 * @param {Object} projectDict - A dictionary mapping each project reference to its name and other properties such as ratesheet information.
 * @param {HTMLSelectElement[]} projectsDD - Dropdown elements representing available projects from which selections can be made.
 * @param {Array<Object>} filteredCategoriesByProjectRate - An array of categories that have been filtered by the rate-sheet criteria for each project reference provided in `entriesForEditing`.
 *
 * @returns {{tsEntryData: (null|{totalHours: number|string, multiLine: boolean, hasError: string, key: *})[]}} Returns an object with processed timesheet entry data. Each entry includes total hours worked (`totalHours`), whether there are errors (`hasError`) and if the error is related to missing rates (`multiLine`). The `key` property retains original entry ID.
 */

const useTsViewTotalAndRemoveSectionData = ({
  entriesForEditing,
  projectsState,
  financialsConfiguration,
  projectDict,
  projectsDD,
  filteredCategoriesByProjectRate,
}) => {
  const tsEntryData = Object.entries(entriesForEditing || {}).map(
    ([key, values]) => {
      if (!values) return null;

      const projectReference = values.find((value) => value.project)?.project;

      const { ddErrors } = gatherSheetInfo({
        projectsState,
        projectReference,
        financialsConfiguration,
        projectDict,
        filteredCategoriesByProjectRate,
        projectsDD,
        values,
      });

      return {
        totalHours: calculateTotalHours(values),
        hasError: ddErrors[0],
        multiLine: ddErrors[0] === TIMESHEET_NO_RATES_ERROR,
        key,
      };
    }
  );
  return { tsEntryData };
};

export default useTsViewTotalAndRemoveSectionData;

import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router";
import {
  MEMBERS_INVITE_PATH,
  REACTIVATE_SUBSCRIPTION_POPUP,
  TOGGLE_POSITIONED_POPUP,
} from "../../../constants";
import useSubscription from "../../../hooks/useSubscription";
import { useAppState } from "../../../state/appState";
import SignUpAPI from "../../SignUp/SignUpAPI";

export default function useAccountAndAdminViewData({ currentIndex }) {
  const history = useHistory();
  const [, appStateDispatch] = useAppState();
  const queryClient = useQueryClient();
  const { data: originalSubscription } = useSubscription();
  const [isSaving, setIsSaving] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [activeIndex, setActiveIndex] = useState(currentIndex || 0);
  const [isEditingPlan, setIsEditingPlan] = useState(false);
  const [isEditingBillingCycle, setIsEditingBillingCycle] = useState(false);
  const [isEditingUserSeats, setIsEditingUserSeats] = useState(false);
  const [isCancellingSubscription, setIsCancellingSubscription] =
    useState(false);
  const [isExportingMemberList, setIsExportingMemberList] = useState(false);
  const [isInvitingMember, setIsInvitingMember] = useState(false);

  const onSave = useCallback(() => {
    setIsSaving(true);
  }, [setIsSaving]);

  const onCancel = () => {
    setIsCancel(true);
  };

  const handleSubmit = useCallback(async () => {
    await SignUpAPI.reactivateSubscription(
      originalSubscription?.subscriptionId,
      queryClient
    );
  }, [originalSubscription?.subscriptionId, queryClient]);

  const onReactivateSubscriptionClick = useCallback(() => {
    appStateDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      position: {
        centered: true,
      },
      popupData: {
        popupWidth: 425,
        handleSubmit,
      },
      popupType: REACTIVATE_SUBSCRIPTION_POPUP,
    });
  }, [appStateDispatch, handleSubmit]);

  const dropDownItems = useCallback(
    (index) => {
      switch (index) {
        case 0:
          return [];
        case 1: {
          const list = [
            {
              title: "Change Plan",
              onClick: () => setIsEditingPlan(true),
              disabled: originalSubscription?.toCancelAtPeriodEnd,
            },
            {
              title: "Change Billing Cycle",
              onClick: () => setIsEditingBillingCycle(true),
              disabled: originalSubscription?.toCancelAtPeriodEnd,
            },
            {
              title: "Change User Seats",
              onClick: () => setIsEditingUserSeats(true),
              disabled: originalSubscription?.toCancelAtPeriodEnd,
            },
          ];
          if (originalSubscription?.toCancelAtPeriodEnd) {
            list.push({
              title: "Reactivate Subscription",
              onClick: () => onReactivateSubscriptionClick(),
            });
          } else {
            list.push({
              title: "Cancel Subscription",
              onClick: () => setIsCancellingSubscription(true),
            });
          }
          return list;
        }

        case 2:
          return [
            {
              title: "Invite Member",
              onClick: () => {
                history.push(MEMBERS_INVITE_PATH);
              },
            },
            {
              title: "Export Member List",
              onClick: () => {
                setIsExportingMemberList(true);
              },
            },
          ];
        case 3:
          return [];

        default:
          return [];
      }
    },
    [
      history,
      onReactivateSubscriptionClick,
      originalSubscription?.toCancelAtPeriodEnd,
    ]
  );

  return {
    isSaving,
    setIsSaving,
    isCancel,
    setIsCancel,
    activeIndex,
    setActiveIndex,
    onSave,
    onCancel,
    dropDownItems,
    isEditingPlan,
    setIsEditingPlan,
    isEditingBillingCycle,
    setIsEditingBillingCycle,
    isEditingUserSeats,
    setIsEditingUserSeats,
    isCancellingSubscription,
    setIsCancellingSubscription,
    isExportingMemberList,
    setIsExportingMemberList,
    isInvitingMember,
    setIsInvitingMember,
  };
}

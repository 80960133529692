import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { getFullName } from "../../../helpers/Formatters";
import { useGetFile } from "../../../hooks/useGetFile";
import checkGreenNoBackground from "../../assets/images/checkGreenNoBackground.svg";
import placeholderImage from "../../assets/images/esShortLogo.svg";
import Pill from "../Pill/Pill";
import "./DistroListMemberCard.css";

const pillStyle = `
  flex
  items-center
  justify-between
  gap-3
  w-full
  flex-1
  hover:bg-lightGreen
`;

const nameStyle = {
  color: "#585858",
  fontFamily: "Montserrat;",
  fontSize: "16px;",
  fontWeight: "400;",
  lineHeight: "22px;",
  letterSpacing: "0px;",
  textAlign: "left;",
};

const DistroListMemberCard = ({
  user,
  isSelected,
  handleClick,
  showMemberPill,
}) => {
  const { data: avatar } = useGetFile(user?.avatar);

  const name = useMemo(() => {
    return getFullName(user?.name);
  }, [user?.name]);

  return (
    <div className={pillStyle}>
      <button
        type="button"
        className="flex flex-row w-5/6 truncate"
        style={{
          height: "45px",
          maxHeight: "45px",
          fontSize: "12px",
        }}
        onClick={() => handleClick(user)}
      >
        <div className="flex items-center gap-3">
          <div
            style={{
              width: "40px",
              height: "40px",
              minWidth: "40px",
              overflow: "hidden",
              borderRadius: "50%",
              border: "1px solid #3EECC4",
            }}
          >
            <img
              src={avatar?.contentsUrl || placeholderImage}
              alt={name}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
          <div className="flex flex-col justify-center h-12">
            <p style={nameStyle} className="text-left font-medium" title={name}>
              {name}
            </p>
            {showMemberPill && (
              <div className="flex w-2/3">
                <Pill
                  value={user?.kind === "member" ? "Member" : "Contact"}
                  background="bg-gray-500"
                  textSize="text-xs"
                  className=""
                  padding="py-1 px-2"
                />
              </div>
            )}
          </div>
        </div>
      </button>

      {isSelected && (
        <div
          className="flex justify-end w-1/6 mr-2"
          style={{ minWidth: "1rem" }}
        >
          <img
            src={checkGreenNoBackground}
            className="w-3 h-3"
            alt="check-icon"
          />
        </div>
      )}
    </div>
  );
};

export default DistroListMemberCard;

DistroListMemberCard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    role: PropTypes.string,
    kind: PropTypes.string,
    title: PropTypes.string,
    companyName: PropTypes.string,
  }),
  isSelected: PropTypes.bool,
  handleClick: PropTypes.func,
  showMemberPill: PropTypes.bool,
};

DistroListMemberCard.defaultProps = {
  user: {},
  isSelected: false,
  handleClick: undefined,
  showMemberPill: false,
};

import { useState } from "react";
import { REMEMBER_ME_STATUS, USER_EMAIL } from "../../constants";

const useRememberMe = () => {
  const localStatus = localStorage.getItem(REMEMBER_ME_STATUS);

  // Status handles the checkbox on Login.jsx
  const [status, setStatus] = useState(localStatus);

  //   set remember me in local storage
  const setRememberMeStatus = (newStatus) => {
    localStorage.setItem(REMEMBER_ME_STATUS, JSON.stringify(newStatus));
  };

  // update checkbox & local storage
  const updateStatus = (newStatus) => {
    setStatus(newStatus);
    setRememberMeStatus(newStatus);
  };

  const initialEmail = status ? localStorage.getItem(USER_EMAIL) : "";

  const handleInitialEmail = () => {
    // Edge case if rememberMeStatus true, but if email is set/cleared/wrong when checkbox selected it returns null
    if (initialEmail === null) {
      return "";
    }
    return initialEmail;
  };

  return { status, updateStatus, setRememberMeStatus, handleInitialEmail };
};

export default useRememberMe;

import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

const ReactSelect = ({ isCreatable, handleEnter, ...props }, ref) => {
  return (
    (isCreatable && (
      <CreatableSelect ref={ref} handleEnter={handleEnter} {...props} />
    )) || <Select ref={ref} onEnter={handleEnter} {...props} />
  );
};

ReactSelect.propTypes = {
  isCreatable: PropTypes.bool,
  handleEnter: PropTypes.func,
};

ReactSelect.defaultProps = {
  isCreatable: false,
  handleEnter: undefined,
};

export default forwardRef(ReactSelect);

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import DocumentReviewElement from "../DocumentReviewElement/DocumentReviewElement";
import {
  SIMPLE_INPUT,
  LIST_ADD_ONE,
  LIST_REMOVE_ONE,
} from "../../../constants";

const RequestForInformationReview = ({
  document,
  dispatch,
  userData,
  associatedResource,
  setAssociatedResource,
  disableAssociation,
  disableEdit,
  editMode,
}) => {
  const handleSimpleInput = React.useCallback(
    (key, value) => {
      dispatch({
        type: SIMPLE_INPUT,
        key,
        value,
      });
    },
    [dispatch]
  );

  const handleListAdd = React.useCallback(
    (key, val) => {
      val.reverse().forEach(({ value }) => {
        dispatch({
          type: LIST_ADD_ONE,
          key,
          value: { id: value },
        });
      });
    },
    [dispatch]
  );

  const handleListRemove = React.useCallback(
    (key, value) => {
      dispatch({
        type: LIST_REMOVE_ONE,
        key,
        value,
      });
    },
    [dispatch]
  );

  const getDueDateDisplay = React.useCallback(() => {
    if (!document.dueDate?.date) return "";
    const dateString = moment(document.dueDate.date).format("MMMM Do, YYYY");
    const timeString = document.dueDate.allDay
      ? "End Of Day"
      : moment(document.dueDate.date).format("hh:mm A");
    return `${dateString} - ${timeString}`;
  }, [document.dueDate]);

  const sectionOne = React.useMemo(() => {
    return [
      {
        title: "Association",
        type: "ASSOCIATION",
        displayValue: associatedResource?.name,
        editValue: document.project ?? document.property,
        onChangeAlt: setAssociatedResource,
        associatedResource,
        disableAssociation,
        disableEdit,
      },
      {
        title: "Due Date",
        type: "DUEDATE",
        displayValue: getDueDateDisplay(),
        editValue: document.dueDate,
        onChange: (val) => handleSimpleInput("dueDate", val),
        disableEdit,
      },
      {
        title: "Title",
        type: "INPUT",
        displayValue: document.customName,
        editValue: document.customName,
        onChange: (val) => handleSimpleInput("customName", val),
        disableEdit: disableEdit || editMode,
      },
      {
        title: "Description",
        type: "TEXTAREA",
        displayValue: document.description,
        editValue: document.description,
        onChange: (val) => handleSimpleInput("description", val),
        disableEdit,
      },
    ];
  }, [
    document,
    associatedResource,
    setAssociatedResource,
    handleSimpleInput,
    disableAssociation,
    disableEdit,
    getDueDateDisplay,
    editMode,
  ]);

  const sectionTwo = React.useMemo(() => {
    return [
      {
        title: "Recipients",
        type: "USERSELECT",
        options: userData,
        selectedOptions: document.distro,
        onChange: (val) => handleListAdd("distro", val),
        onDelete: (val) => handleListRemove("distro", val),
        disableEdit,
      },
    ];
  }, [document, userData, handleListAdd, handleListRemove, disableEdit]);
  const sectionThree = React.useMemo(() => {
    return [];
  }, []);

  return (
    <>
      <div className="flex justify-between">
        <div className="flex-1 flex flex-col pr-4">
          {sectionOne.map((item) => (
            <DocumentReviewElement key={item.title} {...item} />
          ))}
        </div>
        <div className="flex-1 flex flex-col">
          {sectionTwo.map((item) => (
            <DocumentReviewElement key={item.title} {...item} />
          ))}
          {sectionThree.map((item) => (
            <DocumentReviewElement key={item.title} {...item} />
          ))}
        </div>
      </div>
    </>
  );
};

RequestForInformationReview.propTypes = {
  /**
   * create document form state
   */
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.object,
  /**
   * create document form dispatcher
   */
  dispatch: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  userData: PropTypes.arrayOf(PropTypes.object),
  associatedResource: PropTypes.string,
  setAssociatedResource: PropTypes.func,
  disableAssociation: PropTypes.bool,
  /**
   * is document editable
   */
  disableEdit: PropTypes.bool,
  editMode: PropTypes.bool,
};

RequestForInformationReview.defaultProps = {
  document: {},
  dispatch: undefined,
  userData: [],
  associatedResource: undefined,
  setAssociatedResource: PropTypes.func,
  disableAssociation: false,
  disableEdit: false,
  editMode: false,
};

export default RequestForInformationReview;

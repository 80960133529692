import React from "react";
import PropTypes from "prop-types";
import MediaWidget from "../MediaWidget/MediaWidget";
import Modal from "../Modal/Modal";
import LocationOverview from "./LocationOverview";
import SpaceOverview from "./SpaceOverview";

const LocationModal = ({
  title,
  handleModalOpen,
  isOpen,
  handleSave,
  dispatch,
  isSpace,
  location,
  spaceConfiguration,
  saving,
  hideMedia,
}) => {
  return (
    <Modal
      title={title}
      isOpen={isOpen}
      primaryButtonTitle="Save"
      primaryButtonOnClick={handleSave}
      contentStyle={{ maxHeight: "100%", overflowY: "scroll" }}
      tertiaryButtonTitle="Cancel"
      onRequestModalClose={handleModalOpen}
      disabled={!location?.name || (!isSpace && !location?.area?.value)}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      hideFooter
    >
      <div className="flex flex-col">
        {!isSpace && (
          <LocationOverview
            location={location}
            dispatch={dispatch}
            modal
            disableEditing={saving}
          />
        )}
        {isSpace && (
          <SpaceOverview
            space={location}
            dispatch={dispatch}
            configuration={spaceConfiguration}
            modal
            disableEditing={saving}
          />
        )}
        {!hideMedia && (
          <MediaWidget
            images={location?.images}
            dispatch={dispatch}
            resource={location}
            modal
            disableEditing={saving}
          />
        )}
      </div>
    </Modal>
  );
};

LocationModal.propTypes = {
  title: PropTypes.string,
  handleModalOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  handleSave: PropTypes.func,
  dispatch: PropTypes.func,
  isSpace: PropTypes.bool,
  location: PropTypes.shape({
    images: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
    area: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  spaceConfiguration: PropTypes.shape({}),
  saving: PropTypes.bool,
  hideMedia: PropTypes.bool,
};

LocationModal.defaultProps = {
  title: "",
  handleModalOpen: undefined,
  isOpen: false,
  handleSave: undefined,
  dispatch: undefined,
  isSpace: false,
  location: undefined,
  spaceConfiguration: undefined,
  saving: false,
  hideMedia: false,
};

export default LocationModal;

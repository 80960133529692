import React, { useState } from "react";
import cntl from "cntl";
import MaintenanceTable from "../../stories/Components/Maintenance/MaintenanceTable";
import PrimaryHeaderItems from "../../stories/Components/Primary/PrimaryHeaderItems";
import Widget from "../../stories/Components/Widget/Widget";

const PageCN = cntl`
  lg:flex
  flex
  flex-initial
  flex-col
`;
const MaintenanceListView = () => {
  const [createMaintenanceModal, setCreateMaintenanceModal] = useState(false);
  return (
    <div className={PageCN}>
      <PrimaryHeaderItems
        buttonTitle="Maintenance"
        headerTitle="Maintenance"
        onClick={() => setCreateMaintenanceModal(true)}
      />
      <Widget draggable={false} title={null}>
        <MaintenanceTable
          createMaintenanceModal={createMaintenanceModal}
          setCreateMaintenanceModal={setCreateMaintenanceModal}
          hideSiteHeaderTitle
        />
      </Widget>
    </div>
  );
};

MaintenanceListView.propTypes = {};
MaintenanceListView.defaultProps = {};

export default MaintenanceListView;

/**
 * Get the sorting category of a given string based on the first character
 * ES Rule
 * - 0: A-Z
 * - 1: #s
 * - 2: Special characters
 *
 * @param {string} value - The string whose first character is used to determine the sorting category.
 * @returns {number} A numeric value representing the sorting category.
 */
const getSortCategory = (value) => {
  const firstChar = value.charAt(0).toLowerCase();

  // A-Z
  if (firstChar >= "a" && firstChar <= "z") {
    return 0;
  }

  // #s
  if (firstChar >= "0" && firstChar <= "9") {
    return 1;
  }

  // Special characters
  return 2;
};

export default getSortCategory;

/* eslint-disable no-use-before-define */
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  ADD_OPEN_MODAL,
  ASSET,
  CREATE_ASSET,
  CREATE_EVENT_MODAL,
  CREATE_TASK_MODAL,
} from "../../../constants";
import { onUpdateFile } from "../../../helpers/File";
import { getSpaceConfiguration } from "../../../helpers/Formatters";
import clearSearchField from "../../../helpers/clearSearchField";
import useQueryNotFoundNavigation from "../../../hooks/navigation/useQueryNotFoundNavigation";
import useAssociatedFiles from "../../../hooks/useAssociatedFiles";
import useEditModal from "../../../hooks/useEditModal";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import useRelativeAssociations from "../../../hooks/useRelativeAssociations";
import useSpaceReducer from "../../../hooks/useSpaceReducer";
import useSpaces from "../../../hooks/useSpaces";
import useWidgetTabNavigation from "../../../hooks/useWidgetTabNavigation";
import { useAppState } from "../../../state/appState";
import { useModalState } from "../../../state/modalState";
import FormAvatar from "../../../stories/Components/Avatar/FormAvatar";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import DeleteModal from "../../../stories/Components/DeleteModal/DeleteModal";
import FilesTable from "../../../stories/Components/FilesTable/FilesTable";
import InlineInput from "../../../stories/Components/Input/InlineInput";
import ImagesAndVideosWidget from "../../../stories/Components/MediaWidget/ImagesAndVideosWidget";
import SiteHeader from "../../../stories/Components/SiteHeader/SiteHeader";
import {
  toastError,
  toastMessage,
} from "../../../stories/Components/Toast/Toast";
import UploadFile from "../../../stories/Components/UploadFile/UploadFile";
import WidgetContainer from "../../../stories/Components/Widget/WidgetContainer";
import CalendarView from "../../Calendar/CalendarView";
import ProjectSpaceDocuments from "../../Project/ProjectSpaceDocuments";
import Assets from "../Asset/Assets";
import PropertySpaceDocuments from "../Property/PropertySpaceDocuments";
import TaskList from "../Task/TaskList";
import SpaceDetailView from "./SpaceDetailView";
import { hasReadPermission } from "../../../helpers/Permissions";

const SpaceView = () => {
  const { associationLock, spaceLock } = useRelativeAssociations();
  const { propertyId, projectId, spaceId } = useParams();
  const params = useMemo(
    () => ({
      association: projectId
        ? `Project/${projectId}`
        : `Property/${propertyId}`,
      spaceId,
    }),
    [projectId, propertyId, spaceId]
  );
  const {
    associatedFiles,
    addFile,
    addFiles,
    removeFilesAndUpdateApi,
    cloneFile,
    patchFile,
  } = useAssociatedFiles(params);
  const [, setIsEditModalOpen] = useEditModal();
  const [newAttributes, setNewAttributes] = useState([]);
  const [{ currentUser }, appDispatch] = useAppState();
  const [, modalDispatch] = useModalState();

  const [editing, setEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [buttonActions, setButtonActions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  /**
   * Hook Dependencies
   */
  const hookDeps = useMemo(() => {
    if (propertyId) {
      return {
        resource: "propertiesDict",
        id: propertyId,
        ref: `Property/${propertyId}`,
        api: "PropertyAPI",
        spaceId,
        cacheKey: "properties",
      };
    }
    return {
      resource: "projectDict",
      id: projectId,
      ref: `Project/${projectId}`,
      api: "ProjectAPI",
      spaceId,
      cacheKey: "projects",
    };
  }, [projectId, propertyId, spaceId]);

  /**
   * Hooks
   */
  const {
    /* ** commented out for future use: clone Space ** */
    // postData,
    base: {
      data: { parent },
      isFetching,
    },
    actions: {
      /* ** commented out for future use: clone Space ** */
      // post: { mutate: postSpace },
      patch: { mutateAsync: patch },
      remove: { mutateAsync: remove },
    },
    space: xspace,
    spaceNotFound,
  } = useSpaces(hookDeps);

  // Redirect to 404 page if resource is not found
  useQueryNotFoundNavigation({ error: spaceNotFound });

  const [space, setSpace] = useState(xspace);

  useEffect(() => {
    // initialize space state
    setSpace(xspace);
  }, [xspace]);

  useEffect(() => {
    setIsLoading(!space?.name);
  }, [space?.name]);

  useEffect(() => {
    // if clonedSpace is on route state initialize space with clonedSpace
    if (history.location.state?.clonedSpace) {
      const clonedData = history.location.state?.clonedSpace;
      setSpace(clonedData);
    }
  }, [history.location.state]);

  const [editedSpace, dispatch] = useSpaceReducer(space);
  const { data: managementConfiguration } = useManagementConfiguration();
  const spaceConfiguration = useMemo(
    () => getSpaceConfiguration(managementConfiguration),
    [managementConfiguration]
  );
  /**
   * Handlers
   */
  const resetEditedSpace = useCallback(() => {
    dispatch({
      type: "reset",
      space,
    });
  }, [dispatch, space]);

  const handleFinishEditing = useCallback(
    (finishedSpace) => {
      const value = Number(
        editedSpace?.area?.value ?? finishedSpace?.area?.value
      );
      // if user inputs number and then backspaces this will empty the field instead of forcing the "" to a 0
      const area = value !== 0 ? { value } : {};
      const updatedSpace = {
        ...(editedSpace ?? finishedSpace),
        tags:
          editedSpace?.currentTags?.map((tag) => tag?.value) ||
          editedSpace?.tags,
        area,
      };
      patch(updatedSpace);
    },
    [editedSpace, patch]
  );

  const updateSpaceFiles = useCallback(
    (fileRefs) => {
      const updatedFiles = space.files.filter(
        (file) => !fileRefs.includes(file.ref)
      );
      const primary = fileRefs.includes(space?.primaryImage)
        ? updatedFiles.find((file) => file.category === "Photos")?.ref
        : space.primaryImage;
      const updatedSpace = {
        ...space,
        files: updatedFiles,
        primaryImage: primary,
      };
      // patch resource
      patch(updatedSpace);
    },
    [patch, space]
  );

  const onAddFilesCallback = useCallback(
    async (filesUploaded) => {
      addFiles(filesUploaded);

      const updatedFiles = [
        ...space.files,
        ...filesUploaded.map((file) => ({
          ref: file.reference,
          category: file.category,
        })),
      ];

      const updatedSpace = {
        ...space,
        files: updatedFiles,
        primaryImage:
          space?.primaryImage ||
          updatedFiles.find((file) => file.category === "Photos")?.ref,
      };

      patch(updatedSpace);
    },
    [addFiles, patch, space]
  );

  const handleRemoveMedia = useCallback(
    (imageRefs) => {
      const updatedFiles = space.files.filter(
        (file) => !imageRefs.includes(file.ref)
      );
      const primary = imageRefs.includes(space?.primaryImage)
        ? updatedFiles.find((file) => file.category === "Photos")?.ref
        : space.primaryImage;
      const updatedSpace = {
        ...space,
        files: updatedFiles,
        primaryImage: primary,
      };

      patch(updatedSpace, {
        onSuccess: () => {
          // update associated files state
          removeFilesAndUpdateApi(imageRefs);
        },
        onError: () => {
          toastError("Error removing media");
        },
      });
    },
    [patch, removeFilesAndUpdateApi, space]
  );

  const handleSetPrimaryMedia = useCallback(
    (imageRef) => {
      const updatedSpace = {
        ...space,
        primaryImage: imageRef,
      };

      patch(updatedSpace);
    },
    [patch, space]
  );

  const handleAddMedia = useCallback(
    (imageResources) => {
      const updatedFiles = [
        ...space.files,
        ...imageResources?.map((imageResource) => ({
          ref: imageResource.reference,
          category: imageResource.category,
        })),
      ];

      const primary =
        space.primaryImage ||
        updatedFiles.find((file) => file.category === "Photos")?.ref;

      const updatedSpace = {
        ...space,
        files: updatedFiles,
        primaryImage: primary,
      };

      patch(updatedSpace);

      addFiles(imageResources);
    },
    [addFiles, patch, space]
  );

  const handleFileClone = useCallback(
    (fileId) => {
      cloneFile(fileId)
        .then((clonedFile) => {
          const updatedSpace = {
            ...space,
            files: [
              ...space?.files,
              { ref: clonedFile.reference, category: clonedFile.category },
            ],
          };

          return patch(updatedSpace, true);
        })
        .then((resource) =>
          toastMessage(`Recent file successfully attached to ${resource?.name}`)
        )
        .catch(() => {
          toastError(`Error attaching recent file`);
          // remove created files if PATCH fails
          removeFilesAndUpdateApi([`File/${fileId}`]);
        });
    },
    [cloneFile, patch, removeFilesAndUpdateApi, space]
  );

  const handlePrimaryImageChange = useCallback(
    async (fileResource) => {
      const updatedFiles = [
        ...space.files,
        {
          ref: fileResource.reference,
          category: fileResource.category,
        },
      ];

      const updatedSpace = {
        ...space,
        files: updatedFiles,
        primaryImage: fileResource.reference,
      };

      patch(updatedSpace);

      addFile(fileResource);
    },
    [addFile, patch, space]
  );

  const handleChangeTitle = useCallback(
    (name) => {
      dispatch({
        type: "name",
        name,
      });
    },
    [dispatch]
  );

  const handleModalDelete = () => {
    remove(editedSpace);

    history.goBack();
  };

  const hasWritePermission = useCallback(() => {
    if (propertyId) {
      return currentUser?.hasPermission?.(
        "administrative",
        "can_write_property"
      );
    }

    if (projectId) {
      return currentUser?.hasPermission?.(
        "administrative",
        "can_write_project"
      );
    }
    return false;
  }, [currentUser, projectId, propertyId]);

  const handleUpdateFile = useCallback(
    ({ originalResource, currentTags, name }) => {
      onUpdateFile({ originalResource, currentTags, name, patchFile });
    },
    [patchFile]
  );

  const tabs = useMemo(() => {
    const permissionedTabs = {
      tabs: [
        {
          id: "details",
          title: "Details",
          content: (
            <SpaceDetailView
              space={editedSpace}
              onFinishEditing={handleFinishEditing}
              dispatch={dispatch}
              spaceConfiguration={spaceConfiguration}
              parentId={parent?.id}
              setNewAttributes={setNewAttributes}
              newAttributes={newAttributes}
              editing={editing}
              isLoading={isFetching}
              handleEditClick={handleEditClick}
            />
          ),
        },
        {
          id: "media",
          title: "Media",
          content: (
            <ImagesAndVideosWidget
              hasWritePermission={hasWritePermission()}
              hasDeletePermission={hasWritePermission()}
              disableEditing={!hasWritePermission()}
              border={false}
              dispatch={dispatch}
              resource={space}
              handleAddMedia={handleAddMedia}
              handleSetPrimaryMedia={handleSetPrimaryMedia}
              handleRemoveMedia={handleRemoveMedia}
            />
          ),
        },
        {
          id: "files",
          title: "Files",
          content: (
            <FilesTable
              files={associatedFiles}
              resourceName="Space"
              association={
                projectId ? `Project/${projectId}` : `Property/${propertyId}`
              }
              setIsEditModalOpen={setIsEditModalOpen}
              onAddFilesCallback={onAddFilesCallback}
              removeFilesAndUpdateApi={removeFilesAndUpdateApi}
              onRemoveFilesCallback={updateSpaceFiles}
              hasDeletePermission={hasWritePermission()}
              hasWritePermission={hasWritePermission()}
              handleFileClone={handleFileClone}
              hasEditPermission
              handleUpdateFile={handleUpdateFile}
            />
          ),
        },
      ],
    };

    permissionedTabs.detailsIndex = 0;
    permissionedTabs.mediaIndex = 1;
    permissionedTabs.filesIndex = 2;

    if (
      currentUser?.hasPermission?.("event", "can_read") ||
      currentUser?.hasPermission?.("task", "can_read")
    ) {
      permissionedTabs.tabs.push({
        id: "calendar",
        title: "Calendar",
        content: <CalendarView currentUser={currentUser} isTabView />,
      });

      permissionedTabs.calendarIndex = permissionedTabs.tabs.length - 1;
    }

    if (currentUser?.hasPermission?.("task", "can_read")) {
      permissionedTabs.tabs.push({
        id: "tasks",
        title: "Tasks",
        content: <TaskList setButtonActions={setButtonActions} />,
      });

      permissionedTabs.taskIndex = permissionedTabs.tabs.length - 1;
    }

    if (hasReadPermission(ASSET, currentUser)) {
      permissionedTabs.tabs.push({
        id: "assets",
        title: "Assets",
        content: <Assets />,
      });
      permissionedTabs.assetIndex = permissionedTabs.tabs.length - 1;
    }
    permissionedTabs.tabs.push({
      id: "documents",
      title: "Documents",
      content: propertyId ? (
        <PropertySpaceDocuments />
      ) : (
        <ProjectSpaceDocuments />
      ),
      isHidden: true,
    });

    return permissionedTabs;
  }, [
    associatedFiles,
    currentUser,
    dispatch,
    editedSpace,
    editing,
    handleAddMedia,
    handleEditClick,
    handleFileClone,
    handleFinishEditing,
    handleRemoveMedia,
    handleSetPrimaryMedia,
    handleUpdateFile,
    hasWritePermission,
    isFetching,
    newAttributes,
    onAddFilesCallback,
    parent?.id,
    projectId,
    propertyId,
    removeFilesAndUpdateApi,
    setIsEditModalOpen,
    space,
    spaceConfiguration,
    updateSpaceFiles,
  ]);

  const { activeTabIndex, setTabIndex: setActiveTabIndex } =
    useWidgetTabNavigation({ tabs: tabs.tabs });

  const handleEditClick = useCallback(() => {
    if (!editing && activeTabIndex === 0) {
      setEditing(true);
      return;
    }
    setEditing(false);
  }, [activeTabIndex, editing]);

  const handleAddTask = useCallback(() => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: uuidv4() },
      modalData: { associationLock, spaceLock },
      modalType: CREATE_TASK_MODAL,
    });
  }, [associationLock, spaceLock, modalDispatch]);

  useEffect(() => {
    const tabTitle = tabs?.tabs?.[activeTabIndex]?.title;

    switch (tabTitle) {
      case "Details": {
        const options = [];

        if (
          propertyId &&
          currentUser?.hasPermission?.("administrative", "can_write_property")
        )
          options.push({
            title: "Delete Space",
            tabAction: true,
            onClick: () => setShowDeleteModal(true),
          });
        if (
          projectId &&
          currentUser?.hasPermission?.("administrative", "can_write_project")
        )
          options.push({
            title: "Delete Space",
            tabAction: true,
            onClick: () => setShowDeleteModal(true),
          });

        if (options.length)
          setButtonActions([
            {
              title: "Actions",
              className: "dropdown-btn",
              large: true,
              dropdownItems: isLoading ? [] : options,
            },
          ]);
        break;
      }

      case "Calendar": {
        const options = [];
        if (propertyId) {
          if (currentUser?.permissions?.event?.can_write) {
            options.push({
              title: "Add Event",
              tabAction: true,
              onClick: () =>
                modalDispatch({
                  type: ADD_OPEN_MODAL,
                  ref: { id: uuidv4() },
                  modalData: { associationLock: false },
                  modalType: CREATE_EVENT_MODAL,
                }),
            });
          }
          if (currentUser?.permissions?.task?.can_create) {
            options.push({
              title: "Add Task",
              tabAction: true,
              onClick: () => handleAddTask(),
            });
          }
        }

        if (projectId) {
          if (currentUser?.permissions?.event?.can_write) {
            options.push({
              title: "Add Event",
              tabAction: true,
              onClick: () =>
                modalDispatch({
                  type: ADD_OPEN_MODAL,
                  ref: { id: uuidv4() },
                  modalData: { associationLock: false },
                  modalType: CREATE_EVENT_MODAL,
                }),
            });
          }
          if (currentUser?.permissions?.task?.can_create) {
            options.push({
              title: "Add Task",
              tabAction: true,
              onClick: () => handleAddTask(),
            });
          }
        }

        if (options.length)
          setButtonActions([
            {
              title: "Actions",
              className: "dropdown-btn",
              large: true,
              dropdownItems: options,
            },
          ]);
        break;
      }

      case "Tasks": {
        setButtonActions([
          {
            addButton: true,
            resourceName: "Task",
            onClick: () => handleAddTask(),
          },
        ]);
        break;
      }

      case "Media": {
        if (
          (propertyId &&
            currentUser?.hasPermission?.(
              "administrative",
              "can_write_property"
            )) ||
          (projectId &&
            currentUser?.hasPermission?.("administrative", "can_write_project"))
        ) {
          setButtonActions([
            {
              onClick: () =>
                document.getElementById("upload-form-input").click(),
              addButton: true,
              resourceName: "Media",
            },
          ]);
        }
        break;
      }

      case "Files": {
        setButtonActions([
          {
            ...(propertyId &&
            currentUser?.hasPermission?.("administrative", "can_write_property")
              ? {
                  onClick: () => {
                    document
                      .querySelector("div.space-details .upload_area_click")
                      ?.click();
                  },
                  addButton: true,
                  resourceName: "Files",
                }
              : {}),
            ...(projectId &&
            currentUser?.hasPermission?.("administrative", "can_write_project")
              ? {
                  onClick: () => {
                    document
                      .querySelector("div.space-details .upload_area_click")
                      ?.click();
                  },
                  addButton: true,
                  resourceName: "Files",
                }
              : {}),
          },
        ]);
        break;
      }

      case "Assets": {
        if (hasWritePermission(ASSET, currentUser)) {
          setButtonActions([
            {
              onClick: () =>
                modalDispatch({
                  type: ADD_OPEN_MODAL,
                  allowAssocSelect: false,
                  modalData: {
                    item: {
                      spaceId,
                      disableAssociation: true,
                      association: propertyId
                        ? `Property/${propertyId}`
                        : `Project/${projectId}`,
                      subAssociation: spaceId,
                      associationType: propertyId ? "Property" : "Project",
                    },
                  },
                  ref: { id: uuidv4() },
                  modalType: CREATE_ASSET,
                }),
              addButton: true,
              resourceName: "Asset",
            },
          ]);
        }
        break;
      }

      default:
        break;
    }
  }, [
    space?.reference,
    activeTabIndex,
    modalDispatch,
    appDispatch,
    currentUser,
    propertyId,
    projectId,
    isLoading,
    spaceId,
    tabs,
    handleAddTask,
    hasWritePermission,
  ]);

  useEffect(() => {
    if (space) {
      resetEditedSpace();
    }
    return () => {};
  }, [resetEditedSpace, space]);

  // set tab index and reset search field
  const handleTabClick = useCallback(
    (index, resourceTabs, resource) => {
      setActiveTabIndex(index);
      clearSearchField(index, resourceTabs, resource);
    },
    [setActiveTabIndex]
  );

  const ctaButtonActions = useMemo(() => {
    return buttonActions[0];
  }, [buttonActions]);

  return (
    <>
      <SiteHeader
        title={
          <div className="flex items-center">
            <FormAvatar
              isEditing
              editing={editing}
              image={space?.primaryImage}
              loading={!space?.name}
              onChange={handlePrimaryImageChange}
            />
            <InlineInput
              width="w-full"
              size="custom4xl"
              value={space?.name}
              editing={editing}
              loading={!space?.name}
              disabled={!space?.name}
              fontWeight="bold"
              color="gray-650"
              onConfirm={handleChangeTitle}
              onChangeCallback={handleChangeTitle}
              hidePencil
              isHeaderTitle
            />
          </div>
        }
        buttons={
          (ctaButtonActions?.dropdownItems?.length ||
            !!ctaButtonActions?.resourceName) && (
            <PrimaryButton {...ctaButtonActions} />
          )
        }
      />

      <WidgetContainer
        className="p-4 border-gray-200 shadow-lg border rounded-md"
        style={{ minWidth: "903px" }}
        isEditing={editing}
        handleEditClick={activeTabIndex === 0 && handleEditClick}
        onFinishEditing={handleFinishEditing}
        tabs={tabs?.tabs}
        loading={isFetching}
        activeIndex={activeTabIndex}
        onTabClick={(index) => {
          handleTabClick(index, tabs, "Space");
        }}
        disableEditing={
          !currentUser?.hasPermission?.("administrative", "can_write_property")
        }
        resetResourceState={resetEditedSpace}
      />
      <UploadFile
        id="space-details"
        association={
          projectId ? `Project/${projectId}` : `Property/${propertyId}`
        }
        onAddFilesCallback={onAddFilesCallback}
      />
      <DeleteModal
        isOpen={showDeleteModal}
        onDelete={handleModalDelete}
        onClose={() => setShowDeleteModal(false)}
        title="Delete Space?"
        text={`Are you sure that you want to delete ${editedSpace?.name}? Once deleted, the space cannot be recovered.`}
      />
    </>
  );
};

SpaceView.propTypes = {
  userEvents: PropTypes.shape({}),
  addUserEvent: PropTypes.shape({}),
  updateUserEvent: PropTypes.shape({}),
  isTest: PropTypes.bool,
  currentUser: PropTypes.shape({
    reference: PropTypes.string,
    permissions: PropTypes.shape({
      event: PropTypes.shape({
        can_write: PropTypes.bool,
      }),
    }),
    hasPermission: PropTypes.func,
    isSuperAdmin: PropTypes.bool,
    isAdmin: PropTypes.bool,
  }),
};

SpaceView.defaultProps = {
  userEvents: undefined,
  addUserEvent: undefined,
  updateUserEvent: undefined,
  isTest: false,
  currentUser: undefined,
};

export default SpaceView;

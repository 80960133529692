import React from "react";
import { Chat } from "stream-chat-react";
import { useESChatContext } from "./ESChatContext";

/**
 * HOC to wrap components to have access to the Stream Chat context.
 * If for some reason StreamChat connection fails components using this HOC
 * won't be displayed
 */
const withStreamChatContext = (Component) => (props) => {
  const { clientChat } = useESChatContext();

  if (!clientChat) return null;

  return (
    <Chat client={clientChat}>
      <Component {...props} clientChat={clientChat} />
    </Chat>
  );
};

export default withStreamChatContext;

// Framework Tools
import React from "react";
import PropTypes from "prop-types";

// Components
import TertiaryHeader from "../../TextHeaders/TertiaryHeader";

export default function RequestGridViewDurationCell({ duration }) {
  // abstracts start, end, numDays properties from duration - requestions duration: { start: "datestring", end: "datestring", numDays: interger }
  const { numDays } = duration;
  return (
    <div className="w-full h-ful flex flex-col justify-start flex-wrap px-2">
      <TertiaryHeader className="text-sm text-left">Duration</TertiaryHeader>
      <p className="text-sm text-left">
        {numDays > 1 && `${numDays} days`}
        {numDays <= 1 && `${numDays} day`}
      </p>
    </div>
  );
}

// prop types
RequestGridViewDurationCell.propTypes = {
  duration: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    numDays: PropTypes.number,
  }),
};

// default props
RequestGridViewDurationCell.defaultProps = {
  duration: {
    start: "",
    end: "",
    numDays: 10,
  },
};

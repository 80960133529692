import {
  CredentialManagerAPI,
  UsertenantAPI,
} from "@griffingroupglobal/eslib-api";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { Transition } from "react-transition-group";
import { SIGNUP_PATH, USER_EMAIL } from "../constants";
import Auth from "../helpers/Auth";
import { isValidEmail } from "../helpers/Validators";
import useIsSignedIn from "../hooks/useIsSignedIn";
import PrimaryButton from "../stories/Components/Buttons/PrimaryButton";
import Checkbox from "../stories/Components/Checkbox/Checkbox";
import CreateNewPasswordModal from "../stories/Components/CreateNewPasswordModal/CreateNewPasswordModal";
import Dropdown from "../stories/Components/Dropdown/Dropdown";
import ErrorModal from "../stories/Components/ErrorModal/ErrorModal";
import ResetPasswordConfirmationModal from "../stories/Components/ResetPasswordConfirmationModal/ResetPasswordConfirmationModal";
import estateSpaceLogo from "../stories/assets/images/ESLogoLoginScreen.svg";
import visibilityIcon from "../stories/assets/images/visibleIconGrey.svg";
import hideVisibilityIcon from "../stories/assets/images/visibilityHiddenIconGrey.svg";
import UserOnboardingForm from "../stories/Components/UserOnboarding/UserOnboardingForm/index";
import getErrorForgotPasswordApi from "../helpers/Error/getErrorForgotPasswordApi";
import { useRememberMe } from "../hooks/authentication/index";

const transitionStyles = {
  entering: { opacity: 1, display: "none" },
  entered: { opacity: 1, display: "flex" },
  exiting: { opacity: 0, display: "none" },
  exited: { opacity: 0, display: "none" },
};
const defaultStyle = {
  transition: "opacity 500ms ease-in-out",
  opacity: 0,
  display: "none",
};

const Login = () => {
  const history = useHistory();
  const [, setIsSignedIn] = useIsSignedIn();
  const {
    status: rememberMeStatus,
    updateStatus: updateRememberMeStatus,
    handleInitialEmail,
  } = useRememberMe();

  // Field state values
  const [email, setEmail] = useState(handleInitialEmail());
  const [tenant, setTenant] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [tenantOptions, setTenantOptions] = useState([]);
  // Modal state values
  const [newPassword, setNewPassword] = useState();
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isResetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [successfulRequest, setSuccessfulRequest] = useState(false);
  const [isPasswordExpiredModalOpen, setIsPasswordExpiredModalOpen] =
    useState(false);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [userDoesntExistMessage, setUserDoesntExistMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const disabledLogin =
    !email || !password || !tenant || userDoesntExistMessage || loading;

  useEffect(() => {
    setUserDoesntExistMessage(false);
    const getTenants = async () => {
      try {
        const response = await UsertenantAPI.get({ params: { email } });

        const {
          data: { tenants },
        } = response;
        return tenants;
      } catch (err) {
        throw new Error(err);
      }
    };
    const asyncFunc = async () => {
      try {
        const tenants = await getTenants();

        const tenantOpts = tenants.map((t) => ({
          label: t.displayName,
          value: t["x-es-tenantid"],
        }));
        setTenantOptions(tenantOpts);
        setTenant(tenantOpts[0]);
      } catch (err) {
        console.warn("Error fetching tenants: ", err);
        setUserDoesntExistMessage(true);
      }
    };

    const validEmail = isValidEmail(email);

    let timer = null;

    if (validEmail) {
      timer = setTimeout(asyncFunc, 500);
    }

    return () => {
      // Clear the timer if component is unmounted
      if (timer) clearTimeout(timer);
    };
  }, [email]);

  /**
   * React effect for the resetting an account's password.
   */
  useEffect(() => {
    const handleCreateNewPassword = async () => {
      try {
        await CredentialManagerAPI.postWOP("$setpassword", {
          resource: "Parameters",
          parameters: [
            { name: "password", valueString: newPassword },
            { name: "email", valueString: email },
            { name: "temporaryPassword", valueString: password },
          ],
        });
        setIsSignedIn(true);
      } catch (e) {
        console.warn("There was an issue resetting your password", e);
      }
    };
    if (newPassword) {
      // noinspection JSIgnoredPromiseFromCall
      handleCreateNewPassword();
    }
  }, [email, newPassword, password, setIsSignedIn]);

  /**
   * Helper method to fetch the tenants associated with an account
   */
  const getTenants = useCallback(async () => {
    const response = await UsertenantAPI.get({ params: { email } });
    const {
      data: { tenants },
    } = response;
    return tenants;
  }, [email]);

  /**
   * Click handler for the main "Login" button.
   */

  const onLoginClicked = useCallback(async () => {
    setLoading(true);

    if (!tenant) {
      setErrorMessage("Not a valid EstateSpace user email.");
      setIsErrorModalOpen(true);
    } else {
      // if (tenant) set email in local storage, this allows <ProtectedRoute />
      // to query for userTenant and decide what route to Redirect to
      localStorage.setItem(USER_EMAIL, email);
      const userTenants = await getTenants();
      const userTenant = userTenants.find(
        (ut) => ut["x-es-tenantid"] === tenant.value
      );
      // If not registered and timestamp > 24 hours
      if (
        !userTenant.registered &&
        moment().diff(moment(userTenant.timestamp), "hours") >= 24
      ) {
        setErrorMessage(
          "Your temporary password has expired. Click `Resend` to retrieve a new one and then use it to Login."
        );
        setIsPasswordExpiredModalOpen(true);
      } else {
        Auth.setTenant(tenant.value);
        const success = await Auth.signIn(email, password);

        if (success) {
          // user has not set new password
          if (!userTenant.registered) {
            Auth.setRegistered(email, false); // registered = false will determine showing new password screen
            setIsOnboarding(true);
            setIsSignedIn(true);

            // user has set password, but not finished onboarding
          } else if (!userTenant.legalAccepted) {
            setIsSignedIn(true);
            setIsOnboarding(true);

            // user is requesting to reset their password
          } else if (userTenant.resetPassword) {
            setIsPasswordModalOpen(true);

            // user is signing in normally
          } else {
            Auth.setRegistered(email, true);
            setIsSignedIn(true);
            // Edge case of email already being present doesn't force navigation to home screen
            history.push("/calendar");
          }
          // connectStreamChat();
        } else {
          setErrorMessage("Email and Password combination do not match.");
          setIsErrorModalOpen(true);
        }
      }
    }
    setLoading(false);
  }, [tenant, email, getTenants, password, setIsSignedIn, history]);

  /**
   * Click handler for the "Forgot" password action.
   */
  const onForgotPasswordClicked = async () => {
    setErrorMessage(null);
    setIsPasswordExpiredModalOpen(false);

    if (!email.length) {
      setResetPasswordModalOpen(true);
      return;
    }

    Auth.setTenant(tenant.value);
    Auth.setStorage("", "");

    try {
      await CredentialManagerAPI.getWOP("$forgotpassword", {
        params: { email },
      });
      setSuccessfulRequest(true);
    } catch (err) {
      console.warn("Password reset request failed: ", err);
      setErrorMessage(getErrorForgotPasswordApi(err));
    }
    setResetPasswordModalOpen(true);
  };

  const handleSetPassword = useCallback(
    (value) => {
      // Prevents autofill from overriding the temporary password
      if (!isPasswordModalOpen) setPassword(value);
    },
    [isPasswordModalOpen]
  );

  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  const handleEmailKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter") {
        if (passwordInputRef.current.value) {
          onLoginClicked();
        } else {
          passwordInputRef.current.focus();
        }
      }
    },
    [onLoginClicked]
  );

  const handlePasswordKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter") {
        if (emailInputRef.current.value && !disabledLogin) {
          onLoginClicked();
        } else {
          emailInputRef.current.focus();
        }
      }
    },
    [disabledLogin, onLoginClicked]
  );

  return (
    <>
      {!isOnboarding ? (
        <>
          {isPasswordExpiredModalOpen && (
            <ErrorModal
              title="Password has expired"
              isErrorModalOpen={isPasswordExpiredModalOpen}
              setIsErrorModalOpen={setIsPasswordExpiredModalOpen}
              message={errorMessage}
              primaryButtonOnClick={onForgotPasswordClicked}
              primaryButtonTitle="Resend"
              tertiaryButtonTitle="Cancel"
              hideSecondaryHeader
            />
          )}
          {isErrorModalOpen && (
            <ErrorModal
              title="Login Error"
              isErrorModalOpen={isErrorModalOpen}
              setIsErrorModalOpen={setIsErrorModalOpen}
              message={errorMessage}
            />
          )}

          {isResetPasswordModalOpen && (
            <ResetPasswordConfirmationModal
              isResetPasswordConfirmationModalOpen={isResetPasswordModalOpen}
              setIsResetPasswordConfirmationModalOpen={
                setResetPasswordModalOpen
              }
              successfulRequest={successfulRequest}
              errorMessage={errorMessage}
            />
          )}

          {isPasswordModalOpen && (
            <CreateNewPasswordModal
              isCreateNewPasswordModalOpen={isPasswordModalOpen}
              setIsCreateNewPasswordModalOpen={setIsPasswordModalOpen}
              setNewPassword={setNewPassword}
            />
          )}

          <div className="flex flex-col items-center justify-center align-middle h-full w-full gap-8">
            <img
              alt="EstateSpace Logo"
              src={estateSpaceLogo}
              style={{ width: "761px", height: "178px" }}
            />

            <div
              className="flex flex-col border self-center p-10 shadow-light-lift transition-height duration-500 ease-in-out"
              style={{
                minHeight: tenantOptions?.length > 1 ? "512px" : "401px",
                width: "440px",
                borderRadius: "10px",
              }}
            >
              <div className="flex-1 flex flex-col gap-8 justify-between">
                <div className="flex flex-col gap-2">
                  {userDoesntExistMessage && (
                    <p className="text-es-normal font-es-semibold tracking-es-wide text-brandRed">
                      This email address does not exist.
                    </p>
                  )}

                  {!userDoesntExistMessage && (
                    <div className="flex flex-row items-center">
                      <p className="text-es-normal font-es-bold text-es-medium-grey tracking-es-wide">
                        Email
                      </p>
                      <p className="text-es-normal font-es-bold text-brandGreen pl-0.5">
                        *
                      </p>
                    </div>
                  )}
                  <input
                    type="email"
                    placeholder="Your Email"
                    style={{
                      minHeight: "40px",
                      width: "100%",
                      paddingLeft: "18px",
                    }}
                    name="email"
                    autoComplete="email"
                    className="rounded border text-es-medium-grey bg-backgroundGreen"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    ref={emailInputRef}
                    onKeyDown={handleEmailKeyDown}
                  />
                </div>

                <Transition in={tenantOptions?.length > 1} timeout={400}>
                  {(state) => (
                    <div
                      className="flex flex-col gap-2 transition-all ease-in-out duration-500"
                      style={{ ...defaultStyle, ...transitionStyles[state] }}
                    >
                      <p className="text-es-normal font-es-bold text-es-medium-grey tracking-es-wide">
                        Tenant
                      </p>
                      <Dropdown
                        className="text-left"
                        disableClear
                        options={tenantOptions}
                        onChange={setTenant}
                        value={tenant}
                      />
                    </div>
                  )}
                </Transition>

                <div className="flex flex-col gap-2">
                  <div className="flex flex-row items-center">
                    <p className="text-es-normal font-es-bold text-es-medium-grey tracking-es-wide">
                      Password
                    </p>
                    <p className="text-es-normal font-es-bold text-brandGreen pl-0.5">
                      *
                    </p>
                  </div>
                  <div style={{ position: "relative", width: "100%" }}>
                    <input
                      value={password}
                      onChange={(e) => handleSetPassword(e.target.value)}
                      type={!passwordShown ? "password" : "text"}
                      placeholder="Your Password"
                      style={{
                        height: "40px",
                        width: "100%",
                        paddingRight: "60px",
                        paddingLeft: "18px",
                      }}
                      className="rounded border text-es-medium-grey bg-backgroundGreen"
                      ref={passwordInputRef}
                      onKeyDown={handlePasswordKeyDown}
                    />
                    <button
                      style={{
                        position: "absolute",
                        right: "10",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                      type="button"
                      onClick={() => setPasswordShown(!passwordShown)}
                      className="cursor-pointer"
                    >
                      {passwordShown ? (
                        <img alt="visible" src={visibilityIcon} />
                      ) : (
                        <img alt="hide" src={hideVisibilityIcon} />
                      )}
                    </button>
                  </div>
                </div>

                <div className="flex flex-row justify-between">
                  <Checkbox
                    size={6}
                    label="Remember Me"
                    checked={rememberMeStatus}
                    onChange={(newStatus) => updateRememberMeStatus(newStatus)}
                    labelClassName="text-es-normal font-es-normal text-es-dark-grey"
                    inputClassName={rememberMeStatus ? "" : "bg-backgroundGrey"}
                  />
                  <button
                    className="text-es-normal font-es-bold text-es-green hover:text-brandGreen cursor-pointer disabled:text-es-medium-grey"
                    onClick={onForgotPasswordClicked}
                    type="button"
                    disabled={!email || !tenant}
                  >
                    Forgot
                  </button>
                </div>
                <div className="flex" style={{ height: "48px" }}>
                  <PrimaryButton
                    className="w-full h-full flex tracking-es-wide text-base"
                    title={!loading ? "Login" : "Submitting ..."}
                    disabled={disabledLogin}
                    onClick={onLoginClicked}
                    noMaxWidth
                    name="login"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <p className="text-base text-center tracking-es-wide font-es-normal">
                Don&apos;t have an account?
              </p>
              <div className="text-center">
                <button type="button" onClick={() => history.push(SIGNUP_PATH)}>
                  <p className="px-4 py-2 text-es-normal border-2 rounded-md border-brandGreen text-center tracking-es-wide font-es-semibold text-black">
                    Sign up now
                  </p>
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <UserOnboardingForm temporaryPassword={password} email={email} />
      )}
    </>
  );
};

export default Login;

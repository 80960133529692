import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import ExpandableCheckboxOptionWithPills from "./ExpandableCheckboxOptionWithPills";
import useDropdownWithPillsData from "./useDropdownWithPillsData";
import dropdownBaseStyle from "../../../styles/dropdowns/dropdownBaseStyle";

const DropdownWithPills = ({
  options,
  subOptions,
  value,
  isMulti,
  setValue,
  menuPlacement,
  isDisabled,
}) => {
  const {
    accordionStates,
    selectedPills,
    setAccordionStates,
    setSelectedPills,
    handlePillSelected,
  } = useDropdownWithPillsData({ value, setValue });

  return (
    <Select
      options={options}
      onChange={setValue}
      value={value}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      menuPlacement={menuPlacement}
      isMulti={isMulti}
      isDisabled={isDisabled}
      getOptionValue={(option) => option.value.id}
      getOptionLabel={(option) => option.label}
      components={{
        Option: (props) => (
          <ExpandableCheckboxOptionWithPills
            {...props}
            subOptions={subOptions}
            accordionStates={accordionStates}
            setAccordionStates={setAccordionStates}
            selectedPills={selectedPills}
            setSelectedPills={setSelectedPills}
            handlePillSelected={(pillSelected) =>
              handlePillSelected(props, pillSelected)
            }
          />
        ),
      }}
      styles={dropdownBaseStyle}
    />
  );
};

DropdownWithPills.defaultProps = {
  options: [],
  subOptions: [],
  value: undefined,
  isMulti: true,
  isDisabled: false,
  menuPlacement: "auto",
  setValue: () => {},
};

DropdownWithPills.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.shape({
        id: PropTypes.string,
      }),
    })
  ),
  subOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.shape({
        id: PropTypes.string,
        suboptions: PropTypes.arrayOf(PropTypes.string),
      }),
    })
  ),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      suboptions: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  menuPlacement: PropTypes.oneOf(["top", "bottom", "auto"]),
  setValue: PropTypes.func,
};

export default DropdownWithPills;

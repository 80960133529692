import { ReportAPI } from "@griffingroupglobal/eslib-api";
import { reportsKeys } from "../config/reactQuery/queryKeyFactory";
import { toastError } from "../stories/Components/Toast/Toast";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import useCurrentUser from "./useCurrentUser";

/**
 * Fecth all reports or by association
 * @param {string | string[]} associations (?) association reference or array of associations references
 * @returns array of reports
 */
const getReports = async (associations) => {
  const sources = Array.isArray(associations)
    ? associations.join(",")
    : associations;

  const { data } = await ReportAPI.get({
    params: {
      sources,
    },
  });

  if (data?.entries) {
    return data.entries.map(({ resource }) => resource);
  }

  return [];
};

/**
 * Query hook to fetch all reports or by association
 * @param {string} association (?) association reference
 * @param {Function} select (?) custom function to transform data
 * @returns reports in backend format
 */
const useReports = ({ association, select }) => {
  const { data: currentUser } = useCurrentUser();

  const canReadReports =
    currentUser?.isAdmin || currentUser?.permissions?.report?.can_read;

  const queryKey = association
    ? reportsKeys.byAssociation(association)
    : reportsKeys.reports;

  return useAuthenticatedQuery({
    queryKey,
    queryFn: () => getReports(association),
    enabled: !!canReadReports,
    select,
    onError: (error) => {
      toastError("There was an issue getting reports. Please try again");
      console.error("useReports", error);
    },
  });
};

export default useReports;

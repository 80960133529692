import moment from "moment-timezone";
import useCurrentUser from "../../../../../hooks/useCurrentUser";
import { hasUpdatePermission } from "../../../../../helpers/Permissions";
import isCurrentUserAssigned from "../../../../../helpers/Task/isCurrentUserAssigned";
import isBegOfDay from "../../../../../helpers/Date/isBegOfDay";
import isEndOfDay from "../../../../../helpers/Date/isEndOfDay";
import isCurrentTask from "../../../../../helpers/Task/isCurrentTask";
import { TASK } from "../../../../../constants";

const useHeaderControlItemsData = ({ currentTask }) => {
  const { data: currentUser } = useCurrentUser();

  let title = "Current task";

  if (
    currentTask?.instanceStartDate &&
    currentTask?.recurrence &&
    !isCurrentTask(currentTask.instanceStartDate, currentTask?.recurrence)
  ) {
    // get if task is all day
    const isAllDay =
      isBegOfDay(currentTask?.startDate) && isEndOfDay(currentTask?.endDate);

    title = isAllDay
      ? `${moment
          .tz(currentTask?.startDate, currentTask?.timezone)
          .format("MMMM D")} - All Day`
      : `${moment
          .tz(currentTask?.startDate, currentTask?.timezone)
          .format("MMMM D · h:mma")} ${moment
          .tz(currentTask.timezone)
          .format("z")}`;
  }

  const canTaskBeCompleted =
    currentTask.status !== "done" &&
    currentTask.status !== "incomplete" &&
    hasUpdatePermission(TASK, currentUser) &&
    (currentUser?.isAdmin || isCurrentUserAssigned(currentTask, currentUser));

  return { title, canTaskBeCompleted, currentUser };
};

export default useHeaderControlItemsData;

import React from "react";
import PropTypes from "prop-types";
import Dropdown from "../Dropdown/Dropdown";
import Widget from "../Widget/Widget";

const ProjectDivisionCode = ({
  project,
  isEditing,
  financialsSettings,
  dispatch,
}) => {
  const { divisionCode } = project;

  const handleChange = (val) => {
    dispatch({
      type: "edit",
      key: "divisionCode",
      value: val,
    });
  };

  return (
    <Widget draggable={false} title="Division Code" overflow>
      <div className="flex flex-col w-full">
        {isEditing && (
          <div className="my-4 w-1/2">
            <Dropdown
              options={[
                ...financialsSettings?.divisionCodes?.map((item) => ({
                  label: item?.code,
                  value: item?.code,
                  description: item?.description,
                })),
                {
                  label: "No Code Selected",
                  value: undefined,
                  description: undefined,
                },
              ]}
              value={
                divisionCode
                  ? {
                      label: divisionCode?.code ?? "No Code Selected",
                      value: divisionCode?.code,
                      description: divisionCode?.description,
                    }
                  : undefined
              }
              disableSort
              placeholder="Select Division Code"
              onChange={(val) =>
                handleChange({ code: val.value, description: val.description })
              }
            />
          </div>
        )}
        {!isEditing && (
          <div className="my-4 w-1/2">
            <p className="text-gray-200">
              {divisionCode?.code ?? "No Code Selected"}
            </p>
          </div>
        )}
      </div>
    </Widget>
  );
};

ProjectDivisionCode.propTypes = {
  project: PropTypes.shape({
    divisionCode: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  }),
  isEditing: PropTypes.bool,
  financialsSettings: PropTypes.shape({
    divisionCodes: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  dispatch: PropTypes.func,
};

ProjectDivisionCode.defaultProps = {
  project: undefined,
  isEditing: false,
  financialsSettings: undefined,
  dispatch: undefined,
};

export default ProjectDivisionCode;

import { TaskAPI } from "@griffingroupglobal/eslib-api";

const editTaskHandler = async (props) => {
  const { originalItem, editedItem } = props.args;
  const { id: itemId } = originalItem;

  const response = await TaskAPI.patch(itemId, editedItem, originalItem);
  return response;
};

export default editTaskHandler;

import React from "react";
import PropTypes from "prop-types";
import crossBlack from "../../../stories/assets/images/blackCrossIcon.svg";
import "../../../stories/Components/NotificationSlider/NotificationTray.css";
import TodoElement from "./TodoElement";
import "./todo.css";
import memberIcon from "../../../stories/assets/images/membersIcon.svg";
import useTodoTrayData from "./useTodoTrayData";
import CrossButton from "../../../stories/Components/CrossButton/CrossButton";
import AddTodoButton from "./AddTodoButton";
import TrayContainer from "../../../stories/Components/TrayContainer";

const TodoTray = ({ open, close }) => {
  const {
    todos,
    create,
    editing,
    handleEditing,
    handlePatch,
    completeTodo,
    groupTodoElements,
  } = useTodoTrayData();

  const renderTodoElements = () => {
    return todos?.map((todo, index) => {
      const key = todo?.id || `todo-${index}`;
      const isEditing = !editing || editing === index;

      return (
        <TodoElement
          key={key}
          todo={todo}
          last={index === todos.length - 1}
          complete={() => completeTodo(todo)}
          onEditClick={isEditing ? () => handleEditing(index) : undefined}
          editing={editing === index}
          update={handlePatch}
        />
      );
    });
  };

  const renderGroupTodoElements = () => {
    return groupTodoElements?.map((todo, index) => {
      const key = todo?.id || `todo-group-${index}`;

      return <TodoElement key={key} todo={todo} group />;
    });
  };

  return (
    <TrayContainer isOpen={open}>
      <header className="header flex justify-between items-center px-6">
        <p className="font-semibold">To-Dos</p>
        <CrossButton icon={crossBlack} onClick={close} />
      </header>
      <header className="flex bg-white items-center">
        <AddTodoButton create={create} />
      </header>

      <div className="todos-container">
        <p className="border-b py-2 font-medium mx-6">My To-Dos</p>
        <div className="grid grid-rows-10 gap-2 overflow-hidden w-full px-6 py-2">
          <div className="row-span-5 overflow-y-auto flex flex-col gap-2">
            {renderTodoElements()}
          </div>
          {groupTodoElements?.length > 0 && (
            <>
              <p className="flex items-center gap-2 row-span-2 border-b py-2 font-medium">
                <img
                  src={memberIcon}
                  className="w-4 h-4 member-icon"
                  alt="member-icon"
                />
                Group To-Dos
              </p>
              <div className="relative row-span-4 overflow-y-auto pb-3 flex flex-col gap-2">
                {renderGroupTodoElements()}
              </div>
            </>
          )}
        </div>
      </div>
      {!todos?.length && (
        <div className="flex text-xs text-gray-300 font-medium w-full h-full items-center justify-center">
          <span className="block">You have no To-Dos</span>
        </div>
      )}
    </TrayContainer>
  );
};

TodoTray.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

TodoTray.defaultProps = {
  open: false,
  close: () => {},
};

export default TodoTray;

import { TimesheetUtil } from "@griffingroupglobal/eslib-util";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { getIsoDate, rangeToMoments } from "../../../helpers/TimeSheet";
import usePagePersistance from "../../../hooks/usePagePersistence";
import PurePayrollTable from "./PurePayrollTable";

const { TimeSheets } = TimesheetUtil;

const PayrollTable = ({
  financials,
  payrollHistory,
  currentUser,
  loading,
  timesheets,
  selectedRows,
  setSelectedRows,
  warnings,
  handleEditRow,
  customSiteHeader,
  pto,
}) => {
  const { pageState, setPersistentPageItem } = usePagePersistance();

  const currentWeek = useMemo(() => {
    const currentFrame = TimeSheets.getPeriodFrame(
      financials?.period,
      financials?.start,
      getIsoDate()
    );
    return rangeToMoments(currentFrame?.periodStart, currentFrame?.periodEnd);
  }, [financials]);

  const [dates] = useState(currentWeek);

  /**
   * @function showNextWeek
   * @summary - disables next week navigation if the 1st day of
   * next week is outside the pay period
   * Dev Notes: Two types biweekly & weekly (array element 13 & 6 are the last days)
   */

  const showNextWeek = () => {
    const newStartDate = moment(pageState?.timesheet?.periodStart)
      .add(7, "days")
      .format("YYYY-MM-DD");
    const newEndDate = moment(pageState?.timesheet?.periodEnd)
      .add(7, "days")
      .format("YYYY-MM-DD");

    setPersistentPageItem("timesheet", {
      ...pageState?.timesheet,
      periodStart: newStartDate,
      periodEnd: newEndDate,
    });
  };

  const showPrevWeek = () => {
    if (loading) return;
    const newStartDate = moment(pageState?.timesheet?.periodStart)
      .subtract(7, "days")
      .format("YYYY-MM-DD");

    const newEndDate = moment(pageState?.timesheet?.periodEnd)
      .subtract(7, "days")
      .format("YYYY-MM-DD");

    setPersistentPageItem("timesheet", {
      ...pageState?.timesheet,
      periodStart: newStartDate,
      periodEnd: newEndDate,
    });
  };

  const showThisWeek = () => {
    if (loading) return;
    setPersistentPageItem("timesheet", {
      ...pageState?.timesheet,
      periodStart: moment(currentWeek[0]).format("YYYY-MM-DD"),
      periodEnd: moment(
        currentWeek[financials?.period === "everyotherweek" ? 13 : 6]
      ).format("YYYY-MM-DD"),
    });
  };
  /**
   * Time Navigation Func/Var End
   */

  const data = useMemo(() => {
    return timesheets?.map((item) => {
      const newItem = { ...item };
      if (item?.payrollStatus === "approved") {
        newItem.status = "payrollApproved";
      }
      return { ...newItem };
    });
  }, [timesheets]);

  return (
    <PurePayrollTable
      currentUser={currentUser}
      data={data}
      historyData={payrollHistory}
      dates={dates}
      showNextWeek={showNextWeek}
      showPrevWeek={showPrevWeek}
      showThisWeek={showThisWeek}
      loading={loading}
      pto={pto}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      warnings={warnings}
      handleEditRow={handleEditRow}
      customSiteHeader={customSiteHeader}
    />
  );
};

PayrollTable.propTypes = {
  financials: PropTypes.shape({
    start: PropTypes.string,
    period: PropTypes.string,
  }),
  periodFrame: PropTypes.shape({
    periodStart: PropTypes.string,
    periodEnd: PropTypes.string,
  }),
  payrollHistory: PropTypes.shape({}),
  currentUser: PropTypes.shape({}),
  loading: PropTypes.bool,
  timesheets: PropTypes.arrayOf(PropTypes.shape({})),
  selectedRows: PropTypes.arrayOf(PropTypes.shape({})),
  setSelectedRows: PropTypes.func,
  warnings: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.string),
  }),
  handleEditRow: PropTypes.func,
  /**
   * Used to set Custom Site Header From Parent
   * (Outside the Table Component But maintaining Functionality)
   */
  customSiteHeader: PropTypes.element,
  pto: PropTypes.arrayOf(PropTypes.shape({})),
};

PayrollTable.defaultProps = {
  financials: undefined,
  periodFrame: undefined,
  payrollHistory: undefined,
  currentUser: undefined,
  loading: true,
  timesheets: undefined,
  selectedRows: undefined,
  setSelectedRows: undefined,
  warnings: undefined,
  handleEditRow: undefined,
  customSiteHeader: undefined,
  pto: [],
};

export default PayrollTable;

import Api from "@griffingroupglobal/eslib-api";
import Auth from "../helpers/Auth";
import { toastWarning } from "../stories/Components/Toast/Toast";

let responseInterceptor;
let isRefreshingAuthToken = false;
let refreshAuthTokenPromise;

// eslint-disable-next-line no-unused-vars
export default (setIsSignedIn, setIsSiteMaintenance) => {
  // Clean up any existing interceptor
  if (responseInterceptor) {
    Api.interceptors.response.eject(responseInterceptor);
  }

  // trigger token refresh / retry on response error
  responseInterceptor = Api.interceptors.response.use(
    (config) => config,
    async (err) => {
      const error = err.response?.data?.issues[0];
      const errorLocation = error?.location;
      const errorDetailCode = error?.detail?.code;

      // unauthorized: `refreshToken` has expired
      const isRefreshTokenExp =
        err.response?.status === 401 &&
        errorDetailCode === "MSG_USER_INVALID" &&
        errorLocation?.includes("$refresh");

      if (isRefreshTokenExp) {
        setIsSignedIn(false);
        return Promise.reject(Error("Refresh token has expired"));
      }

      // unauthorized: `authToken` has expired
      const isAuthTokenExp =
        err.response?.status === 401 && !errorLocation?.includes("$gettokens");

      if (isAuthTokenExp) {
        // When multiple API calls fail due to an expired authentication token (401),
        // this block ensures that the interceptor initiates a single call to `/api/refresh`
        if (!isRefreshingAuthToken) {
          isRefreshingAuthToken = true;

          refreshAuthTokenPromise = Auth.retryTokenRefresh(0, setIsSignedIn)
            .then(() => {
              isRefreshingAuthToken = false;
            })
            .catch((refreshError) => {
              setIsSignedIn(false);
              throw refreshError;
            });
        }
        return refreshAuthTokenPromise.then(() => {
          // Retry the original request
          return Api.axios(err.config);
        });
      }

      // can be caused by Auth.signOut()
      if (err.response?.status === 405) {
        setIsSignedIn(false);
        return Promise.reject(
          Error(
            `Missing or incorrect x-es-tenantid header in request: ${err.config.url}`
          )
        );
      }

      // api error, force refresh after a toast
      if (err.response?.status === 410) {
        toastWarning(
          `A newer version of the app was detected. Reloading the app now....`
        );

        // gets all the service workers currently installed for your web app and uninstalls them
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          registrations.forEach((registration) => {
            registration.unregister();
          });
        });
        // removes all browser cache entries.
        caches.keys().then((keyList) => {
          keyList.forEach(async (key) => {
            await caches.delete(key);
          });
        });

        // reload
        const timer = setTimeout(() => {
          window.location.reload();
        }, 5000);

        return () => {
          if (timer) clearTimeout(timer);
        };
      }

      const isOnline = window?.navigator?.onLine;

      // site maintenance
      // browser interprets 503 from aws load balancer as cors and has code = ERR_NETWORK
      if (
        err.response?.status === 503 ||
        (err.code === "ERR_NETWORK" && isOnline)
      ) {
        // TODO: Temporarily disable maintenance screen to allow the backend team to improve scalable endpoints.
        // setIsSiteMaintenance(true);
      }
      return Promise.reject(err);
    }
  );
};

import React, { useState } from "react";

import ExpenseTable from "../../stories/Components/ExpenseTable/ExpenseTable";

const ProjectExpenses = ({ project, isTabView, setButtonActions }) => {
  const [createExpenseModal, setCreateExpenseModal] = useState(false);
  return (
    <ExpenseTable
      association={{
        reference: project?.reference,
        name: project?.name,
        resource: project?.resource,
      }}
      hideHeader
      setButtonActions={setButtonActions}
      isTabView={isTabView}
      createExpenseModal={createExpenseModal}
      setCreateExpenseModal={setCreateExpenseModal}
    />
  );
};

export default ProjectExpenses;

import React, { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { formatProjectTableData } from "../../helpers/Project";
import useManagementConfiguration from "../../hooks/useManagementConfiguration";
import { useProjects } from "../../hooks/projects";
import ProjectTable from "../../stories/Components/ProjectTable/ProjectTable";
import useFavorites from "../../hooks/useFavorites";
import useCurrentUser from "../../hooks/useCurrentUser";
import Widget from "../../stories/Components/Widget/Widget";
import PrimaryHeaderItems from "../../stories/Components/Primary/PrimaryHeaderItems";
import PrimaryButton from "../../stories/Components/Buttons/PrimaryButton";
import { ADD_OPEN_MODAL, PROJECT, PROJECT_CREATE_MODAL } from "../../constants";
import { useGetTags } from "../../hooks/useTags";
import { hasWriteAdministrativePermission } from "../../helpers/Permissions";
import { useModalState } from "../../state/modalState";

const ProjectList = ({ property }) => {
  const { data: projects } = useProjects();
  const { data: tagsData } = useGetTags();
  const { data: currentUser } = useCurrentUser();
  const [, modalDispatch] = useModalState();
  const [favorites, { postFavorite, deleteFavorite }] = useFavorites();
  const { data: managementConfiguration } = useManagementConfiguration();

  const projectTypes = useMemo(() => {
    if (!managementConfiguration?.management?.project?.types) {
      return [];
    }

    return managementConfiguration.management.project.types
      .filter((type) => type.selected)
      .map((type) => ({
        label: type.display,
        value: type.id,
      }));
  }, [managementConfiguration?.management?.project?.types]);

  const tableData = useMemo(() => {
    if (!projects?.length) {
      return [];
    }

    const allProjects = projects?.map((project) => {
      return {
        ...formatProjectTableData(project, projectTypes, tagsData?.tagsDict),
        isFavorited:
          project.isFavorited ??
          favorites.some((fav) => fav.item.reference.includes(project.id)),
      };
    });

    // projects for property Tab
    // take only projects that are linked to the given property via propertyRef
    if (property) {
      return allProjects?.filter(
        (project) => project?.property === property?.reference
      );
    }

    return allProjects;
  }, [property, projects, projectTypes, favorites, tagsData?.tagsDict]);

  const handleFavoriteClick = React.useCallback(
    async (id) => {
      const foundItem = favorites.find((favorite) => {
        return favorite.item.reference.includes(id);
      });

      if (foundItem) {
        await deleteFavorite([foundItem]);
      } else {
        await postFavorite(id, "Project");
      }
    },
    [deleteFavorite, favorites, postFavorite]
  );

  return (
    <>
      {!property?.reference && (
        <PrimaryHeaderItems
          headerTitle="Projects"
          buttonTitle="Project"
          customButton={
            hasWriteAdministrativePermission(PROJECT, currentUser) && (
              <PrimaryButton
                resourceName="Project"
                onClick={() =>
                  modalDispatch({
                    type: ADD_OPEN_MODAL,
                    ref: { id: uuidv4() },
                    modalType: PROJECT_CREATE_MODAL,
                  })
                }
                addButton
              />
            )
          }
        />
      )}
      <Widget draggable={false} title={null} overflow>
        <ProjectTable
          data={tableData}
          handleFavoriteClick={handleFavoriteClick}
        />
      </Widget>
    </>
  );
};

export default ProjectList;

import React from "react";
import useEditModal from "../../../hooks/useEditModal";
import IconLabel from "../IconLabel/IconLabel";
import settingsDarkenedGreenIcon from "../../assets/images/settingsDarkenedGreenIcon.svg";

const SiteHeaderEditColumns = () => {
  const [, setIsEditModalOpen] = useEditModal();

  return (
    <IconLabel
      icon={settingsDarkenedGreenIcon}
      text="Edit Columns"
      textClassName="text-darkenedGreen text-base"
      onClick={() => setIsEditModalOpen((prev) => !prev)}
    />
  );
};

export default SiteHeaderEditColumns;

import React from "react";
import { useESChatContext } from "../../../../context/ChatContext/ESChatContext";
import "../messagingTray.css";

const MessagingChannelList = ({ expanded }) => {
  const { setSelectedChannel, channels, clientChat } = useESChatContext();

  return (
    <>
      {expanded && clientChat?.user && (
        <div className="flex flex-col py-2 gap-2">
          <header className="text-white font-medium text-sm px-3">
            <p>Channels</p>
          </header>
          <div
            className="flex flex-col gap-2 overflow-y-scroll channels-list px-3"
            style={{ maxHeight: "136px" }}
          >
            {channels.map((item) => {
              return (
                <button
                  key={`button-${item?.id}-${item?.created_at}`}
                  type="button"
                  className="w-full relative flex rounded-full channel-button font-light"
                  onClick={() => setSelectedChannel(item)}
                >
                  <p
                    className="text-white truncate text-xs"
                    title={item?.data?.name}
                  >
                    {`# ${item?.data?.name}`}
                  </p>
                </button>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default MessagingChannelList;

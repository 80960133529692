import PropTypes from "prop-types";
import React from "react";
import DistroListMemberCard from "./DistroListMemberCard";

const DistroListMembersRow = ({
  currentMembers = [],
  handleClick,
  showMemberPill,
}) => {
  return (
    <div className="flex flex-col w-full h-full justify-start gap-4 scrollbar">
      {currentMembers?.reduce((acc, item) => {
        // filter out contacts w/o firstName or comoany name
        const hasFirstName = !!item?.name?.firstName;
        const isCompany =
          item?.company?.valueType === "string" && !!item?.company?.value;

        if (hasFirstName || isCompany) {
          const updatedItem = {
            ...item,
            name: {
              firstName: item?.name?.firstName || item?.company?.value || "",
              lastName: item?.name?.lastName || "",
            },
          };
          acc.push(
            <DistroListMemberCard
              key={item?.id}
              handleClick={handleClick}
              user={updatedItem}
              isSelected={item.isSelected}
              showMemberPill={showMemberPill}
            />
          );
        }
        return acc;
      }, [])}

      {currentMembers.length === 0 && (
        <p className="w-full text-center text-gray-200">No members</p>
      )}
    </div>
  );
};

DistroListMembersRow.propTypes = {
  currentMembers: PropTypes.shape([]),
  handleClick: PropTypes.func,
  showMemberPill: PropTypes.bool,
};

DistroListMembersRow.defaultProps = {
  currentMembers: PropTypes.shape([]),
  handleClick: () => {},
  showMemberPill: false,
};

export default DistroListMembersRow;

import React from "react";
import PropTypes from "prop-types";
import BaseActionButton from "./BaseActionButton";
import ExpandElement from "./ExpandElement";

const ExpandButton = ({ className, onClick, animated, color, value }) => {
  const toggle = () => {
    if (value) {
      onClick(false);
    } else {
      onClick(true);
    }
  };
  return (
    <BaseActionButton
      Icon={ExpandElement}
      className={className}
      onClick={toggle}
      animated={animated}
      color={color}
      toggle={value}
    />
  );
};

ExpandButton.propTypes = {
  /**
   * classes to apply to the button
   */
  className: PropTypes.string,
  /**
   * function called when the button is clicked
   */
  onClick: PropTypes.func,
  animated: PropTypes.bool,
  color: PropTypes.string,
  value: PropTypes.bool,
};

ExpandButton.defaultProps = {
  className: undefined,
  onClick: () => {},
  animated: false,
  color: undefined,
  value: false,
};

export default ExpandButton;

import React from "react";
import cntl from "cntl";

import HelpCategoryCard from "./HelpCategoryCard";

const gridContainer = cntl`
flex
flex-wrap
gap-5
w-full
`;

const HelpGrid = ({ setCategoryView, helpGridItems = {} }) => {
  return (
    <div className={gridContainer}>
      {Object.keys(helpGridItems)?.map((category) => {
        return (
          <HelpCategoryCard
            key={category}
            category={category}
            helpData={helpGridItems[category]}
            setCategoryView={setCategoryView}
          />
        );
      })}
    </div>
  );
};

export default HelpGrid;

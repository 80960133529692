import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Summary from "../Summary/Summary";
import { formatWithCommasWithoutDecimal } from "../../../helpers/Formatters";

const BudgetSummary = ({
  budgetSummary,
  fee,
  insurance,
  budgetType,
  isLockFixedFirm,
  isLockGmp,
}) => {
  const [publishedBudgetData, setPublishedBudgetData] = useState(null);
  const [liveBudgetData, setLiveBudgetData] = useState(null);
  const [otherBudgetData, setOtherBudgetData] = useState(null);

  useEffect(() => {
    const other = [
      {
        key: "Billed",
        value: formatWithCommasWithoutDecimal(budgetSummary?.billed),
        unit: "$",
      },
      {
        key: "Paid",
        value: formatWithCommasWithoutDecimal(budgetSummary?.paid),
        unit: "$",
      },
      {
        key: "% Complete",
        value: formatWithCommasWithoutDecimal(budgetSummary?.percentComplete),
        unit: "%",
      },
      {
        key: "Committed Value",
        value: formatWithCommasWithoutDecimal(budgetSummary?.committedValue),
        unit: "$",
      },
      {
        key: "Uncommitted Value",
        value: formatWithCommasWithoutDecimal(budgetSummary?.uncommittedValue),
        unit: "$",
      },
      {
        key: "Work Completed To Date",
        value: formatWithCommasWithoutDecimal(
          budgetSummary?.workCompletedToDate
        ),
        unit: "$",
      },
      {
        key: "Work Completed to This Period",
        value: formatWithCommasWithoutDecimal(
          budgetSummary?.workCompletedThisPeriod
        ),
        unit: "$",
      },
    ];

    const published = [
      {
        key: "Cost of Work",
        value: formatWithCommasWithoutDecimal(
          budgetSummary?.publishedBudget?.costOfWork
        ),
        unit: "$",
      },
      {
        key: `Fee (${fee?.amount || "0.0"} ${fee?.feeType || "%"})`,
        value: formatWithCommasWithoutDecimal(
          budgetSummary?.publishedBudget?.fee
        ),
        unit: `${fee?.feeType || "%"}`,
      },
      {
        key: `Insurance (${insurance?.amount || "0.0"} ${
          insurance?.insuranceType || "%"
        })`,
        value: formatWithCommasWithoutDecimal(
          budgetSummary?.publishedBudget?.insurance
        ),
        unit: `${insurance?.insuranceType || "%"}`,
      },
      {
        key: "Budget Total",
        value: formatWithCommasWithoutDecimal(
          budgetSummary?.publishedBudget?.budgetTotal
        ),
        unit: "$",
      },
    ];

    const live = [
      {
        key: "Cost of Work",
        value: formatWithCommasWithoutDecimal(
          budgetSummary?.liveBudget?.costOfWork
        ),
        unit: "$",
      },
      {
        key: `Fee (${fee?.amount || "0.0"} ${fee?.feeType || "%"})`,
        value: formatWithCommasWithoutDecimal(budgetSummary?.liveBudget?.fee),
        unit: `${fee?.feeType || "%"}`,
      },
      {
        key: `Insurance (${insurance?.amount || "0.0"} ${
          insurance?.insuranceType || "%"
        })`,
        value: formatWithCommasWithoutDecimal(
          budgetSummary?.liveBudget?.insurance
        ),
        unit: `${insurance?.insuranceType || "%"}`,
      },
      {
        key: "Budget Total",
        value: formatWithCommasWithoutDecimal(
          budgetSummary?.liveBudget?.budgetTotal
        ),
        unit: "$",
      },
      {
        key: "Budget Variance",
        value: formatWithCommasWithoutDecimal(budgetSummary?.variance),
        isFooter: true,
        unit: "$",
      },
    ];

    setPublishedBudgetData(published);
    setLiveBudgetData(live);
    setOtherBudgetData(other);
  }, [budgetSummary, fee, insurance]);

  const getBudgetTitle = () => {
    if (budgetType) {
      if (isLockFixedFirm) {
        return `${budgetType} (Lock Fixed Firm)`;
      }
      if (isLockGmp) {
        return `${budgetType} (Lock GMP)`;
      }
      return budgetType;
    }
    return "Draft Budget";
  };

  return (
    <div className="flex pb-2">
      <Summary
        values={publishedBudgetData}
        title="Published Budget"
        className="pr-4"
        keyClassName="w-48"
      />
      <Summary
        values={liveBudgetData}
        title="Live Budget"
        className="pr-4"
        keyClassName="w-48"
      />
      <Summary
        values={otherBudgetData}
        title={getBudgetTitle()}
        keyClassName="w-72"
      />
    </div>
  );
};

BudgetSummary.propTypes = {
  /**
   * data to display on the summary
   */
  budgetSummary: PropTypes.shape({
    variance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    liveBudget: PropTypes.shape({
      costOfWork: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      insurance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      budgetTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    publishedBudget: PropTypes.shape({
      costOfWork: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      insurance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      budgetTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    billed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    paid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    committedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    uncommittedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    percentComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    workCompletedThisPeriod: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    workCompletedToDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  insurance: PropTypes.shape({
    insuranceType: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  fee: PropTypes.shape({
    feeType: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  budgetType: PropTypes.string,
  isLockFixedFirm: PropTypes.bool,
  isLockGmp: PropTypes.bool,
};

BudgetSummary.defaultProps = {
  budgetSummary: undefined,
  insurance: undefined,
  fee: undefined,
  budgetType: undefined,
  isLockFixedFirm: false,
  isLockGmp: false,
};

export default BudgetSummary;

import React, { useCallback } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import * as yup from "yup";
import BaseButton from "../Buttons/BaseButton";
import Input from "../Input/Input";
import { calendarDaysOfWeekOptions } from "../../../helpers/Holidays";
import Checkbox from "../Checkbox/Checkbox";
import Widget from "../Widget/Widget";
import TrashIcon from "../../assets/images/trashDarkenedGreenIcon.svg";

const tertiaryButtonCN = cntl`
text-brandGreen
py-2
`;

const valueCN = cntl`
text-gray-200
text-center
`;

const rateColCN = (last) => cntl`
grid
grid-cols-8
gap-8
w-full
py-2
${!last && "border-b"}
`;

const days = [...calendarDaysOfWeekOptions];
const ProjectRateSheet = ({ project, isEditing, dispatch }) => {
  const onAdd = useCallback(() => {
    dispatch({
      type: "addRateSheet",
    });
  }, [dispatch]);
  const onChange = useCallback(
    (index, field, value) => {
      dispatch({
        type: "changeRateSheet",
        index,
        value,
        field,
      });
    },
    [dispatch]
  );

  const onRemove = useCallback(
    (index) => {
      dispatch({
        type: "removeRateSheet",
        index,
      });
    },
    [dispatch]
  );
  const onSelectPremiumRateDay = useCallback(
    (day) => {
      dispatch({
        type: "selectRateSheetPremiumRateDays",
        day,
      });
    },
    [dispatch]
  );

  return (
    <Widget draggable={false} title="Ratesheet" overflow className="mb-4">
      {isEditing && (
        <p className="my-4">Modify the default hourly rate sheet.</p>
      )}
      {project?.rateSheet && (
        <div className="flex flex-col">
          {project?.rateSheet?.rates.length !== 0 && (
            <div className="grid grid-cols-8 gap-8 w-full">
              <h3 className="font-semibold col-span-4 flex items-center">
                Category
              </h3>

              <h3
                title="Rate Per Hour"
                className="font-semibold col-span-1 text-center"
              >
                Rate Per
                <br />
                Hour
              </h3>

              <h3
                title="Rate Over 40 Hours"
                className="font-semibold col-span-1 text-center"
              >
                Rate Over
                <br /> 40 Hours
              </h3>

              <h3
                title="Premium Rate"
                className="font-semibold col-span-1 text-center"
              >
                Premium
                <br />
                Rate
              </h3>
            </div>
          )}
          {[...(project?.rateSheet?.rates ?? [])]?.map((rs, index) => {
            return (
              <div
                key={rs?.id}
                className={rateColCN(
                  [...(project?.rateSheet?.rates ?? [])]?.length - 1 === index
                )}
              >
                <div className="py-2 flex col-span-4">
                  {!isEditing ? (
                    <p className={valueCN}>{rs?.category}</p>
                  ) : (
                    <Input
                      placeholder="Category"
                      mainClassName="w-full"
                      className="text-gray-300"
                      value={rs?.category}
                      onChange={(val) => onChange(index, "category", val)}
                      disableClear
                    />
                  )}
                </div>
                <div className="py-2 col-span-1 flex items-center justify-center">
                  <div className="w-full" style={{ "min-width": "100px" }}>
                    {!isEditing ? (
                      <p className={valueCN}>{`$ ${rs?.ratePerHr}`}</p>
                    ) : (
                      <Input
                        placeholder="Per Hr"
                        isDollarValue
                        className="text-gray-300"
                        value={rs?.ratePerHr}
                        onChange={(val) => onChange(index, "ratePerHr", val)}
                        disableClear
                        validation={yup
                          .number()
                          .positive()
                          .transform((v, o) => (o === "" ? undefined : v))}
                        showValidationErrorAtBottom
                      />
                    )}
                  </div>
                </div>
                <div className="py-2 col-span-1 flex items-center justify-center">
                  <div className="w-full" style={{ "min-width": "100px" }}>
                    {!isEditing ? (
                      <p className={valueCN}>{`$ ${rs?.rateOver40Hrs}`}</p>
                    ) : (
                      <Input
                        placeholder="> 40 Hrs"
                        isDollarValue
                        className="text-gray-300"
                        value={rs?.rateOver40Hrs}
                        onChange={(val) =>
                          onChange(index, "rateOver40Hrs", val)
                        }
                        disableClear
                        validation={yup
                          .number()
                          .positive()
                          .transform((v, o) => (o === "" ? undefined : v))}
                        showValidationErrorAtBottom
                      />
                    )}
                  </div>
                </div>
                <div className="py-2 col-span-1 flex items-center justify-center">
                  <div className="w-full" style={{ "min-width": "100px" }}>
                    {!isEditing ? (
                      <p className={valueCN}>{`$ ${rs?.premiumRate}`}</p>
                    ) : (
                      <Input
                        placeholder="Premium"
                        isDollarValue
                        className="text-gray-300"
                        value={rs?.premiumRate}
                        onChange={(val) => onChange(index, "premiumRate", val)}
                        disableClear
                        validation={yup
                          .number()
                          .positive()
                          .transform((v, o) => (o === "" ? undefined : v))}
                        showValidationErrorAtBottom
                      />
                    )}
                  </div>
                </div>
                <div className="py-2 flex items-center justify-center col-span-1">
                  {isEditing && !(rs.count && rs.count > 0) && (
                    <button onClick={() => onRemove(index)} type="button">
                      <img src={TrashIcon} alt="remove-icon" />
                    </button>
                  )}
                </div>
              </div>
            );
          })}
          <div className="py-4">
            {isEditing && (
              <BaseButton
                className={tertiaryButtonCN}
                labelClassName="mx-0"
                title="+ Add Rate"
                onClick={onAdd}
              />
            )}
          </div>
          <div className="py-4">
            <h3 className="font-semibold py-4">
              Apply premium rates on the following days:
            </h3>
            <div className="flex flex-row">
              {days?.map((day) => (
                <div className="flex flex-row" key={day?.value}>
                  <Checkbox
                    label={day?.label}
                    checked={
                      project?.rateSheet?.premiumRateDaysOfWeek &&
                      project?.rateSheet?.premiumRateDaysOfWeek[day?.value]
                    }
                    onChange={() => onSelectPremiumRateDay(day?.value)}
                    className={day?.value === "holidays" ? "pr-2" : "pr-6"}
                    disabled={!isEditing}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </Widget>
  );
};

ProjectRateSheet.propTypes = {
  project: PropTypes.shape({
    rateSheet: PropTypes.shape({
      premiumRateDaysOfWeek: PropTypes.shape({
        sunday: PropTypes.bool,
        monday: PropTypes.bool,
        tuesday: PropTypes.bool,
        wednesday: PropTypes.bool,
        thursday: PropTypes.bool,
        friday: PropTypes.bool,
      }),
      rates: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  isEditing: PropTypes.bool,
  dispatch: PropTypes.func,
};

ProjectRateSheet.defaultProps = {
  project: undefined,
  isEditing: false,
  dispatch: undefined,
};

export default ProjectRateSheet;

import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import Papa from "papaparse";
import cntl from "cntl";
import PrimaryButton from "../Buttons/PrimaryButton";
import UploadArea from "../UploadArea/UploadArea";
import BaseButton from "../Buttons/BaseButton";
import crossIcon from "../../assets/images/crossIcon.svg";
import attachmentIcon from "../../assets/images/attachmentIcon.svg";
import warningIcon from "../../assets/images/warning.svg";
import TertiaryButton from "../Buttons/TertiaryButton";
import coaTemplateFile from "../../assets/templates/coaTemplateFile.csv";
import help from "../../assets/images/Help.svg";
import ChartOfAccountsPopOver from "./ChartOfAccountsPopOver";

const popoverCN = cntl`
  px-2
  py-2.5
  rounded
  border
  border-gray-300
  z-50
  absolute
  bg-white
  left-40
`;

const ChartOfAccountsGetStarted = ({ onGetStarted, onCSVUpload }) => {
  const [uploadedCsvFile, setUploadedCsvFile] = useState();
  const [showUpload, setShowUpload] = useState(false);
  const [showFinCodesPopover, setShowFinCodesPopover] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const validateData = (rows) => {
      if (!rows.length) {
        setErrors((prev) => [...prev, "File is empty"]);
      } else if (
        rows[0].code === undefined ||
        !rows[0].description === undefined ||
        !rows[0].type === undefined
      ) {
        setErrors((prev) => [
          ...prev,
          "Missing one or more columns - code, description, type",
        ]);
      } else {
        rows.map((row, idx) => {
          if (row.code === "" || row.description === "" || row.type === "") {
            setErrors((prev) => [
              ...prev,
              `Row ${
                idx + 1
              }- Missing one or more required values (code, description, type)`,
            ]);
          } else if (!/^(\d+-?)+\d+$/.test(row.code)) {
            setErrors((prev) => [
              ...prev,
              `Row ${
                idx + 1
              }- Code is invalid. Must be of type XXX-XXX-XX or XXXX`,
            ]);
          } else if (row.subcode !== "" && !/^(\d+-?)+\d+$/.test(row.code)) {
            setErrors((prev) => [
              ...prev,
              `Row ${
                idx + 1
              }- Subcode is invalid. Must be of type XXX-XXX-XX or XXXX`,
            ]);
          }

          return row;
        });
      }
    };

    if (uploadedCsvFile) {
      Papa.parse(uploadedCsvFile, {
        complete: (results) => {
          validateData(results.data);
        },
        header: true,
      });
    }
  }, [uploadedCsvFile]);

  const onClose = useCallback(() => {
    setShowUpload(false);
    setUploadedCsvFile(null);
    setErrors([]);
  }, []);

  const readFile = useCallback(() => {
    // TODO: api integration
    setIsUploading(true);
    Papa.parse(uploadedCsvFile, {
      complete: (results) => {
        onCSVUpload({ rows: results.data });
        setIsUploading(false);
        setShowUpload(false);
      },
      header: true,
    });
  }, [onCSVUpload, uploadedCsvFile]);

  return (
    <div className="flex flex-col justify-center items-center h-5/6 py-4">
      {(showUpload && (
        <div className="flex flex-col w-2/3 h-full p-8 border rounded">
          <div className="flex justify-between mb-4">
            <div className="flex flex-row">
              <h2 className="text-lg font-semibold">
                Import Chart of Accounts
              </h2>
              <BaseButton
                iconLeft={
                  <img src={help} alt="warning" className="mr-2 w-5 h-5" />
                }
                className="px-2"
                onClick={() => setShowFinCodesPopover(true)}
              />

              {showFinCodesPopover && (
                <div className={popoverCN}>
                  <ChartOfAccountsPopOver
                    onClose={() => setShowFinCodesPopover(false)}
                  />
                </div>
              )}
            </div>
            <button className="-mt-6 -mr-3" type="button" onClick={onClose}>
              <img src={crossIcon} alt="close button" />
            </button>
          </div>
          <div className="flex flex-row">
            <p>
              Before you import a CSV file, please ensure that it conforms with
              the correct format.{" "}
              <a
                href={coaTemplateFile}
                download="EstateSpace COA Template.csv"
                className="text-brandGreen"
              >
                Download CSV template
              </a>
            </p>
          </div>
          {(uploadedCsvFile && (
            <div className="flex flex-col">
              <div className="flex items-center pl-1 py-4">
                <img
                  className="mr-3"
                  src={attachmentIcon}
                  alt="attachment icon"
                />
                <p className="font-semibold text-gray-200">
                  {uploadedCsvFile?.name}
                </p>
              </div>
              {(!!errors.length && (
                <>
                  <p className="font-bold">Error parsing file:</p>
                  {errors.map((error) => (
                    <div className="flex items-center py-1" key={error}>
                      <img
                        className="mr-3"
                        src={warningIcon}
                        alt="warning icon"
                      />
                      <p className="font-semibold text-gray-200">{error}</p>
                    </div>
                  ))}
                </>
              )) || (
                <div className="flex justify-end w-full mt-8">
                  <TertiaryButton
                    className="mr-2"
                    title="Cancel"
                    onClick={onClose}
                  />
                  <PrimaryButton
                    title="Upload"
                    onClick={readFile}
                    disabled={isUploading}
                  />
                </div>
              )}
            </div>
          )) || (
            <UploadArea
              className="h-56 mt-4"
              onFilesAdd={(files) => {
                setUploadedCsvFile(files[0]);
              }}
              title="File Upload"
            />
          )}
        </div>
      )) || (
        <div className="py-9 mb-1.5">
          <div className="flex flex-col justify-center items-center h-80 w-80 rounded-full border-gray-100 border-2">
            <p className="uppercase mb-3">chart of accounts</p>
            <p className="text-gray-300 text-sm font-semibold">
              You don&apos;t have
            </p>
            <p className="text-gray-300 text-sm font-semibold mb-3">
              anything here yet.
            </p>
            <PrimaryButton title="Get Started" onClick={onGetStarted} />
            <div className="flex flex-row py-2">
              <p className="text-gray-300 text-sm pr-2">or</p>
              <BaseButton
                style={{ minWidth: 80 }}
                title="import CSV"
                className="text-brandGreen text-sm font-normal"
                onClick={() => {
                  setShowUpload(true);
                }}
                disableCapitalize
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ChartOfAccountsGetStarted.propTypes = {
  onGetStarted: PropTypes.func,
  onCSVUpload: PropTypes.func,
};

ChartOfAccountsGetStarted.defaultProps = {
  onGetStarted: undefined,
  onCSVUpload: undefined,
};

export default ChartOfAccountsGetStarted;

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import Input from "../Input/Input";
import { formatWithCommasWithoutDecimal } from "../../../helpers/Formatters";

const BudgetTableHoldbackForm = ({ onChangeHoldbackAmount, holdback }) => {
  const getHoldbackTotal = useCallback(() => {
    const diff =
      parseFloat(holdback?.workCompletedThisPeriod) -
      parseFloat(holdback?.paidToDate);

    return diff >= 0 ? diff : 0;
  }, [holdback?.paidToDate, holdback?.workCompletedThisPeriod]);

  const getHoldbackRemainingAmount = useCallback(() => {
    const diff =
      parseFloat(getHoldbackTotal()) - parseFloat(holdback?.holdbackAmount);

    return formatWithCommasWithoutDecimal(diff >= 0 ? diff : 0);
  }, [getHoldbackTotal, holdback?.holdbackAmount]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <div className="pr-2">
          <Input
            placeholder="Holdback Amount"
            onChange={onChangeHoldbackAmount}
            validation={yup
              .number()
              .min(0, `Minimum atleast 0`)
              .max(
                getHoldbackTotal(),
                `Allowed maximum is ${getHoldbackTotal()}`
              )}
            showValidationErrorAtBottom
            value={holdback?.holdbackAmount}
            autoFocus
          />
        </div>
        <div className="pt-3">
          <span className="text-gray-300 px-1">out of</span>
          <span className="text-gray-300 font-medium">
            ${getHoldbackTotal()}
          </span>
        </div>
      </div>
      <div className="pt-3">
        <span className="text-gray-300 font-medium">
          Remaining balance available to holdback: $
          {getHoldbackRemainingAmount()}
        </span>
      </div>
    </div>
  );
};

BudgetTableHoldbackForm.propTypes = {
  holdback: PropTypes.shape({
    workCompletedThisPeriod: PropTypes.string,
    holdbackAmount: PropTypes.string,
    paidToDate: PropTypes.string,
  }),
  onChangeHoldbackAmount: PropTypes.func,
};

BudgetTableHoldbackForm.defaultProps = {
  onChangeHoldbackAmount: undefined,
  holdback: undefined,
};

export default BudgetTableHoldbackForm;

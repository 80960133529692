import PropTypes from "prop-types";

/**
 * Props class for an item in {@link AddNewMemberForm}.
 */
export default class MemberInviteeProps {
  static SHAPE = PropTypes.shape({
    salaryType: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    employeeType: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    role: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    email: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    phone: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    company: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    salary: PropTypes.string,
    annualVacation: PropTypes.string,
    isEmployee: PropTypes.bool,
    isExisting: PropTypes.bool,
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  });
}

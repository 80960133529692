// Framework Tools
import React from "react";
import PropTypes from "prop-types";

// Components
import TertiaryHeader from "../../TextHeaders/TertiaryHeader";

export default function RequestGridViewBudgetCell({ budget }) {
  // abstracts status property from budget prop requires budget: { status: * }
  const { status } = budget;
  return (
    <div className="w-full h-ful flex flex-col justify-start flex-wrap px-2">
      <TertiaryHeader className="text-sm text-left">
        Budget Reference
      </TertiaryHeader>
      <p className="text-sm text-left">{status}</p>
    </div>
  );
}

// prop types
RequestGridViewBudgetCell.propTypes = {
  budget: PropTypes.shape({
    status: PropTypes.string,
  }),
};

// default props
RequestGridViewBudgetCell.defaultProps = {
  budget: { status: undefined },
};

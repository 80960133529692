import { useMutation, useQueryClient } from "react-query";
import addToCalendarStore from "../../helpers/Calendar/addToCalendarStore";
import { useAppState } from "../../state/appState";
import { addToCalendarHandler } from "./calendarHandlers";
import addToCalendarResponder from "./calendarResponders/addToCalendarResponder";

const useAddToCalendar = () => {
  const queryClient = useQueryClient();
  const [{ calendarData }] = useAppState();

  const createMutation = useMutation(
    addToCalendarHandler,
    addToCalendarResponder(queryClient, calendarData)
  );

  /**
   *
   * @param {Props} item item can be task or event
   * @param {boolean} newRequest optional defaults to true, if false is sent an api request will not be attempted
   */
  const addToCalendar = async (props, newRequest = true) => {
    if (!props) {
      return console.error(
        "props is required: populate with the Task or Event you are adding to the calendar"
      );
    }

    if (newRequest === false) {
      return addToCalendarStore({ queryClient, calendarData, item: props });
    }

    const response = await createMutation.mutateAsync(props);
    return response;
  };

  const {
    isLoading: addCalendarLoading,
    isError: hasAddCalendarError,
    isSuccess: addCalendarSuccess,
    error: addCalendarError,
  } = createMutation;

  return {
    addToCalendar,
    addCalendarLoading,
    hasAddCalendarError,
    addCalendarError,
    addCalendarSuccess,
  };
};

export default useAddToCalendar;

import { PreferenceAPI } from "@griffingroupglobal/eslib-api";
import { userKeys } from "../config/reactQuery/queryKeyFactory";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { toastError } from "../helpers/Toast";

const fetchUserPreferences = async (userId) => {
  const { data } = await PreferenceAPI.get({
    params: {
      user: `User/${userId}`,
    },
  });

  return data;
};

/**
 * Query hook to fetch single user preferences
 * @param {string} userId user primary id
 */
const useUserPreferences = (userId) => {
  return useAuthenticatedQuery({
    queryKey: userKeys.preferences(userId),
    enabled: !!userId,
    queryFn: () => fetchUserPreferences(userId),
    onError: (error) => {
      toastError(
        "There was an issue getting user preferences. Please try again"
      );
      console.error("useUserPreferences", error);
    },
  });
};

export default useUserPreferences;

const useMediaSectionData = ({ editingMode, currentTask }) => {
  // a boolean value to check if there are any attachments to show
  const hasMediaToShow =
    !editingMode &&
    currentTask.files.find((file) => file.category !== "Documents");
  // a boolean value to check if there are no attachments to show
  const hasNoMediaToShow =
    !editingMode &&
    !currentTask.files.find((file) => file.category !== "Documents");

  return { hasMediaToShow, hasNoMediaToShow };
};

export default useMediaSectionData;

import React from "react";
import PropTypes from "prop-types";

import PrimaryButton from "../../Buttons/PrimaryButton";
import Widget from "../../Widget/Widget";
import GroupingElement from "./groupingElement";
import RetainageList from "./retainageList";
import useProjectRetainageData from "./useProjectRetainageData";

const ProjectRetainage = ({
  project,
  isEditing,
  editable,
  readable,
  dispatch,
}) => {
  const { handleAddRetainage, handleChange, handleRemoveValue } =
    useProjectRetainageData({ dispatch, project, readable });

  return (
    <Widget draggable={false} title="Retainage" overflow className="mb-4">
      <div className="relative mx-6 my-0 flex flex-col">
        {project?.retainage && (
          <>
            <GroupingElement retainage={project?.retainage} />
            <RetainageList
              isEditing={isEditing}
              editable={editable}
              retainage={project?.retainage}
              handleChange={handleChange}
              handleRemoveValue={handleRemoveValue}
            />
          </>
        )}
        {isEditing && (
          <div className="flex w-full justify-end mt-4 order-last">
            <PrimaryButton title="+ Add" onClick={handleAddRetainage} />
          </div>
        )}
      </div>
    </Widget>
  );
};

ProjectRetainage.propTypes = {
  project: PropTypes.shape({
    retainage: PropTypes.arrayOf(
      PropTypes.shape({
        percentComplete: PropTypes.string,
        percentRetainage: PropTypes.string,
      })
    ),
    id: PropTypes.string,
  }),
  isEditing: PropTypes.bool,
  editable: PropTypes.bool,
  readable: PropTypes.bool,
  dispatch: PropTypes.func,
};

ProjectRetainage.defaultProps = {
  project: undefined,
  isEditing: false,
  editable: false,
  readable: undefined,
  dispatch: undefined,
};

export default ProjectRetainage;

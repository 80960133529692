import React from "react";
import * as yup from "yup";
import activityLogIcon from "../../../../assets/images/activityLogIcon.svg";
import IconButton from "../../../Buttons/IconButton";
import InlineInput from "../../../Input/InlineInput";

const TaskTitle = ({
  isEditing,
  value,
  onChange,
  currentTask,
  page,
  onActivityClick,
  showActivityButton,
}) => {
  // if in editing mode, show input
  if (isEditing)
    return (
      <div className="mb-6 mt-3">
        <InlineInput
          placeholder="Name"
          width="w-full"
          size="custom4xl"
          value={value}
          editing={isEditing}
          disabled={!value}
          fontWeight="bold"
          color="gray-650"
          onChangeCallback={onChange}
          hidePencil
          isHeaderTitle
          autoFocus
          validation={yup.string().trim().required("Name cannot be blank.")}
        />
      </div>
    );

  // if in page mode, show title in 4xl
  if (page) {
    return (
      <div className="flex justify-between items-end mb-4">
        <h2 className="text-es-black font-es-semibold text-es-xl tracking-es-xwide">
          {`${currentTask?.name}`}
        </h2>
        <div className={!showActivityButton && "hidden"}>
          <IconButton
            imgClassName="w-7 h-8"
            iconClassName="w-7 h-8"
            icon={activityLogIcon}
            onClick={onActivityClick}
          />
        </div>
      </div>
    );
  }

  // if not in editing mode, show title
  return (
    <div className="flex justify-between items-end mb-4">
      <h2 className="text-es-black font-es-semibold text-es-xl tracking-es-xwide">
        {`${currentTask?.name}`}
      </h2>
      <div className={`pb-4 ${!showActivityButton && "hidden"}`}>
        <IconButton
          imgClassName="w-7 h-8"
          iconClassName="w-7 h-8"
          icon={activityLogIcon}
          onClick={onActivityClick}
        />
      </div>
    </div>
  );
};

export default TaskTitle;

import React from "react";
import PropTypes from "prop-types";
import Input from "../../../Input/Input";
import useOwnedByListCreateEditContactData from "./useOwnedByListCreateEditContactData";

const OwnedByListCreateEditContact = ({
  creatingUser,
  editingState,
  setEditingState,
  onCancel,
  onCreate,
  onEdit,
  editedUser,
}) => {
  const { onEditName } = useOwnedByListCreateEditContactData({
    creatingUser,
    onCreate,
    onEdit,
    editingState,
    editedUser,
  });

  return (
    <div className="flex w-full h-auto px-2">
      <div className="flex flex-col w-full">
        <p className="font-es-bold tracking-es-normal text-es-black text-es-normal">
          {creatingUser ? "Add Contact" : "Edit Contact"}
        </p>
        <div className="flex flex-col mt-4 px-4 pt-6 pb-4 bg-es-extra-light-grey rounded-md">
          <Input
            value={editingState}
            label="Name"
            onChange={setEditingState}
            labelClassName="text-es-sm font-es-semibold"
          />
          <div className="flex justify-between pt-4 pb-2">
            <button
              type="button"
              className={`font-es-semibold text-es-normal ${
                !editingState?.trim()?.length
                  ? "text-es-light-grey"
                  : "text-es-dark-green"
              }`}
              onClick={onEditName}
              disabled={!editingState?.trim()?.length}
            >
              Save
            </button>

            <button
              type="button"
              className="font-es-semibold text-es-normal text-es-dark-green"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onCancel();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

OwnedByListCreateEditContact.propTypes = {
  setEditingState: PropTypes.func,
  editingState: PropTypes.string,
  creatingUser: PropTypes.bool,
  onCancel: PropTypes.func,
  editedUser: PropTypes.shape({
    label: PropTypes.string,
    reference: PropTypes.string,
    contactType: PropTypes.string,
  }),
  onEdit: PropTypes.func,
  onCreate: PropTypes.func,
};

OwnedByListCreateEditContact.defaultProps = {
  editingState: undefined,
  onCancel: undefined,
  setEditingState: undefined,
  creatingUser: false,
  editedUser: undefined,
  onEdit: undefined,
  onCreate: undefined,
};

export default OwnedByListCreateEditContact;

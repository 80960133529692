import wiDaySunny from "../../stories/assets/images/weatherIcons/wi-day-sunny.svg";
import wiNightClear from "../../stories/assets/images/weatherIcons/wi-night-clear.svg";
import wiDayCloudy from "../../stories/assets/images/weatherIcons/wi-day-cloudy.svg";
import wiNightCloudy from "../../stories/assets/images/weatherIcons/wi-night-cloudy.svg";
import wiDayCloudyHigh from "../../stories/assets/images/weatherIcons/wi-day-cloudy-high.svg";
import wiNightCloudyHigh from "../../stories/assets/images/weatherIcons/wi-night-cloudy-high.svg";
import wiDaySunnyOvercast from "../../stories/assets/images/weatherIcons/wi-day-sunny-overcast.svg";
import wiNightPartlyCloudy from "../../stories/assets/images/weatherIcons/wi-night-alt-partly-cloudy.svg";
import wiDayShowers from "../../stories/assets/images/weatherIcons/wi-day-showers.svg";
import wiNightSHowers from "../../stories/assets/images/weatherIcons/wi-night-showers.svg";
import wiDayRain from "../../stories/assets/images/weatherIcons/wi-day-rain.svg";
import wiNightRain from "../../stories/assets/images/weatherIcons/wi-night-rain.svg";
import wiDayThunderstorm from "../../stories/assets/images/weatherIcons/wi-day-thunderstorm.svg";
import wiNightThunderstorm from "../../stories/assets/images/weatherIcons/wi-night-thunderstorm.svg";
import wiDaySnow from "../../stories/assets/images/weatherIcons/wi-day-snow.svg";
import wiNightSnow from "../../stories/assets/images/weatherIcons/wi-night-snow.svg";
import wiDayRainMix from "../../stories/assets/images/weatherIcons/wi-day-rain-mix.svg";
import wiNightRainMix from "../../stories/assets/images/weatherIcons/wi-night-rain-mix.svg";
import wiMeteor from "../../stories/assets/images/weatherIcons/wi-meteor.svg";

/**
 * Selects the name for an image
 * @param {string} name the name given to you by OpenWeatherMap
 * @returns {string} returns the string for the image
 */
const imageSelector = (name) => {
  let image = wiMeteor;
  switch (name) {
    case "01d":
      image = wiDaySunny;
      break;
    case "01n":
      image = wiNightClear;
      break;
    case "02d":
      image = wiDayCloudy;
      break;
    case "02n":
      image = wiNightCloudy;
      break;
    case "03d":
      image = wiDayCloudyHigh;
      break;
    case "03n":
      image = wiNightCloudyHigh;
      break;
    case "04d":
      image = wiDaySunnyOvercast;
      break;
    case "04n":
      image = wiNightPartlyCloudy;
      break;
    case "09d":
      image = wiDayShowers;
      break;
    case "09n":
      image = wiNightSHowers;
      break;
    case "10d":
      image = wiDayRain;
      break;
    case "10n":
      image = wiNightRain;
      break;
    case "11d":
      image = wiDayThunderstorm;
      break;
    case "11n":
      image = wiNightThunderstorm;
      break;
    case "13d":
      image = wiDaySnow;
      break;
    case "13n":
      image = wiNightSnow;
      break;
    case "50d":
      image = wiDayRainMix;
      break;
    case "50n":
      image = wiNightRainMix;
      break;
    default:
      image = wiMeteor;
  }
  return image;
};

export default imageSelector;

import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import cntl from "cntl";

import returnIcon from "../../assets/images/returnIcon.svg";
import BaseButton from "../Buttons/BaseButton";

const rowCN = cntl`
  grid
  grid-flow-row
  grid-cols-12
  gap-x-2
  w-full
`;

const tertiaryButtonCN = cntl`
  text-brandGreen
`;

const DocumentHistoryRow = ({
  historyData,
  topLine,
  bottomLine,
  shaded,
  setViewWorkflow,
}) => {
  const history = useHistory();

  const cellCN = React.useMemo(
    () => cntl`
      flex
      items-center 
      ${bottomLine ? "border-b border-gray-100" : ""}
    `,
    [bottomLine]
  );

  const handleLinkClick = () => {
    history.push(historyData.link);
  };

  return (
    <div className="flex w-full h-11">
      <div className="flex flex-col items-center w-3 h-11 mr-10">
        <div
          className={`w-px h-4 bg-gray-200 border-r${
            topLine ? "" : " invisible"
          }`}
        />
        <div
          className={`w-3 h-3 border border-gray-200 rounded-full${
            shaded ? " bg-gray-200" : ""
          }`}
        />
        <div
          className={`w-px h-4 bg-gray-200 border-r${
            bottomLine ? "" : " invisible"
          }`}
        />
      </div>
      <div className={rowCN}>
        <div className={`${cellCN} col-span-4 font-medium`}>
          {historyData.action}
          {historyData?.context?.workflow?.name && (
            <>
              <p className="pl-2">-</p>
              <BaseButton
                className={tertiaryButtonCN}
                onClick={() =>
                  setViewWorkflow(historyData?.context?.workflow?.id)
                }
                title={historyData?.context?.workflow?.name}
              />
            </>
          )}
        </div>
        <div className={`${cellCN} col-span-4`}>{historyData.userName}</div>
        <div className={`${cellCN} col-span-2`}>{historyData.date}</div>
        <div className={`${cellCN} col-span-1`}>
          {historyData.link && (
            <button type="button" onClick={handleLinkClick}>
              <img src={returnIcon} alt="return icon" />
            </button>
          )}
        </div>
        <div className="col-span-1" />
      </div>
    </div>
  );
};

DocumentHistoryRow.propTypes = {
  /**
   * document history entry
   */
  // eslint-disable-next-line react/forbid-prop-types
  historyData: PropTypes.object,
  /**
   * show top connecting line
   */
  // eslint-disable-next-line react/forbid-prop-types
  topLine: PropTypes.bool,
  /**
   * show bottom connecting line
   */
  // eslint-disable-next-line react/forbid-prop-types
  bottomLine: PropTypes.bool,
  /**
   * shaded circle
   */
  // eslint-disable-next-line react/forbid-prop-types
  shaded: PropTypes.bool,
  setViewWorkflow: PropTypes.func,
};

DocumentHistoryRow.defaultProps = {
  historyData: {},
  topLine: true,
  bottomLine: true,
  shaded: false,
  setViewWorkflow: () => {},
};

export default DocumentHistoryRow;

/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-props-no-spreading */
import moment from "moment-timezone";
import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { eventColor } from "../../../helpers/Calendar";
import "./Calendar.css";
import { MARKED_COMPLETE_BLUE, MARKED_COMPLETE_GRAY } from "../../../constants";
import getDateTimeInTimezone from "../../../helpers/Calendar/getDateTimeInTimezone";

const WeekEvent = ({
  event: { startDate, endDate, name, id, ...rest },
  index,
  subIndex,
  group,
  onClick,
  h,
  calendarTimezone,
}) => {
  const containerRef = useRef(null);
  const dayStart = moment.tz(startDate, calendarTimezone).startOf("day");
  const start = getDateTimeInTimezone({
    date: startDate,
    timezone: calendarTimezone,
  });
  const end = getDateTimeInTimezone({
    date: endDate,
    timezone: calendarTimezone,
  });
  const startTime = start.format("h:mma");
  const startDifference = start.diff(dayStart, "minutes") / 1440;
  const endDifference = end.diff(dayStart, "minutes") / 1440;
  const height = endDifference - startDifference;

  const containerAttributes = (text) => {
    /**
     * @summary - Rules:
     * -  The last event in the overlap is half the width of the base porportion of overlapping events (e.g. 3 overlappingEvents base = 1/3 so last event is 1/6)
     * -  The width of overlapping events is in porportion to the number of events that overlap + half of the last events width (e.g. 3 overlappingEvents base = 1/3 so last event is 1/6 so events are base + half of last = 5/12)
     * -  The total width of all overlapping events should equal the container before overlap
     * -  Events overlap 1/(4 * numOfEventsOverlapping) of their width
     * -  Overlap/xPositionOffset is added to width of all events to fill container
     * -  Width accounts for cell borders (e.g. 0.5 in calculations)
     *
     * -
     */
    const fullWidth = 280 / 7;
    const grouped = group;
    const porportion = 1 / grouped;
    const ratio = (idx) => {
      if (grouped === 0) {
        return 1;
      }
      return grouped - 1 === idx
        ? 1 / (2 * grouped)
        : 1 / (2 * grouped * (grouped - 1)) + porportion;
    };

    const overlap = (1 / (4 * grouped)) * fullWidth;

    const width = grouped
      ? fullWidth * ratio(subIndex) + overlap
      : fullWidth - 0.5;
    const left = () => {
      const dayOffset = index * fullWidth;
      let widthOffset = fullWidth - 0.5;

      if (grouped) {
        widthOffset -= overlap + 1;
      }

      const x =
        (grouped ? subIndex : 0) *
        (widthOffset *
          ratio(grouped - 1 === subIndex ? subIndex - 1 : subIndex));
      if (subIndex === 0) {
        return dayOffset + x;
      }
      return dayOffset + x;
    };
    return { x: text ? left() + 17.25 : left() + 15.25, width };
  };

  /**
   * @param {number} width - width divided by three is ~maxlength of string before it exceeds container
   * @summary - Manually apply ellipses to SVG text for overflow (approximation)
   */
  const formatEventName = useCallback(
    ({ x, width }, y) => {
      const maxLength = Math.floor(width / 3) - 1;

      // Length < 2 just return ellipse
      const ellipse = (val) => {
        return val.length > maxLength
          ? `${maxLength > 1 ? `${val.substring(0, maxLength)}...` : "..."}`
          : val;
      };
      if (name?.length > 7) {
        return (
          <text
            y={y}
            x={x}
            fontSize="3"
            alignmentBaseline="hanging"
            fill={rest?.status === "done" ? MARKED_COMPLETE_GRAY : "white"}
            style={{
              textDecoration: rest?.status === "done" ? "line-through" : "none",
            }}
          >
            <tspan y={y + 2} x={x + 2} alignmentBaseline="hanging">
              {ellipse(startTime)}
            </tspan>
            <tspan y={y + 6} x={x + 2} alignmentBaseline="hanging">
              {ellipse(name)}
            </tspan>
          </text>
        );
      }
      return (
        <text
          y={y + 2}
          x={x + 2}
          fontSize="3"
          alignmentBaseline="hanging"
          fill={rest?.status === "done" ? MARKED_COMPLETE_GRAY : "white"}
        >
          {ellipse(`${startTime} ${name}`)}
        </text>
      );
    },
    [name, rest?.status, startTime]
  );
  return (
    <g
      key={`brief-event-${id}`}
      ref={containerRef}
      onClick={(e) => {
        e.stopPropagation();
        onClick(
          rest?.original ?? {
            startDate,
            endDate,
            name,
            id,
            ...rest,
          },
          containerRef
        );
      }}
      className="cursor-pointer"
    >
      <rect
        y={`${startDifference * h}`}
        {...containerAttributes()}
        height={`${height < 0.04 ? 4 : height * 100}%`}
        strokeWidth="0.25"
        stroke="white"
        rx="1"
        fill={
          rest.status === "done"
            ? MARKED_COMPLETE_BLUE
            : eventColor({ ...rest })
        }
      />
      {formatEventName(containerAttributes(), startDifference * h)}
    </g>
    /**
     * May need this later
     */
    //   ref={(el) => (ref[`${startDate}-${id}`] = el)}
    //   onFocus={() => {
    //     ref[`${startDate}-${id}`].style.zIndex = 3;
    //   }}
    //   onBlur={() => {
    //     ref[`${startDate}-${id}`].style.zIndex = 0;
    //   }}
    /**
     * May need this later
     */
  );
};

WeekEvent.propTypes = {
  event: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    name: PropTypes.string,
    isMaintenance: PropTypes.bool,
    id: PropTypes.string,
  }),
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  group: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  h: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  calendarTimezone: PropTypes.string,
};

WeekEvent.defaultProps = {
  event: undefined,
  index: undefined,
  subIndex: undefined,
  group: undefined,
  onClick: undefined,
  h: undefined,
  calendarTimezone: undefined,
};

export default WeekEvent;

import { useCallback } from "react";

const BUTTONS_LABELS = ["Add Request", "Mark as Complete"];

/**
 * Hook for generating button properties for Service Request top buttons inside P/P/A
 */
const useServiceRequestTopButtons = () => {
  const getRequestButtonProps = useCallback((buttonsActions = []) => {
    const addAction = buttonsActions.find((opt) =>
      BUTTONS_LABELS.includes(opt.title)
    );

    const title = addAction?.title;
    const className = addAction?.className;

    return {
      onClick: () => addAction?.onClick(),
      addButton: true,
      resourceName: title?.includes("Request") ? "Request" : title,
      buttonTitle: title?.includes("Request") ? undefined : title,
      className,
    };
  }, []);

  return { getRequestButtonProps };
};

export default useServiceRequestTopButtons;

import { useEffect, useMemo, useState } from "react";
import {
  BUDGET_COST_PLUS,
  BUDGET_FIXED_FIRM,
  BUDGET_TIME_AND_MATERIALS,
} from "../../../../../constants";
import { isContingencyLineItem } from "../../../../../helpers/Budget";

const getRetainage = (sum, row) => {
  if (Number(row.retainage || 0) === -1) {
    return "N/A";
  }

  if (sum === "N/A") {
    return sum;
  }
  return sum + Number(row.retainage || 0);
};

const useDocumentDetailsSummaryData = ({
  project,
  budget,
  documentNumber,
  docBudgetLines,
}) => {
  const [summary, setSummary] = useState();
  const [projectDeposit, setProjectDeposit] = useState();

  useEffect(() => {
    if (project.deposit) {
      const totalDeposit = project.deposit?.amount || 0;
      const currentInvoiceDeposit =
        project.deposit?.invoices.find((inv) => inv?.number === documentNumber)
          ?.amount || 0;
      const totalDepositUsed =
        project.deposit?.invoices?.reduce((acc, inv) => {
          if (inv?.number <= documentNumber) {
            // eslint-disable-next-line no-param-reassign
            acc += inv?.amount;
          }
          return acc;
        }, 0) || 0;

      setProjectDeposit({
        totalDeposit,
        currentInvoiceDeposit,
        totalDepositUsed,
      });
    }
  }, [documentNumber, project.deposit]);

  useEffect(() => {
    if (docBudgetLines?.length) {
      const summaryValues = docBudgetLines.reduce(
        (acc, liValue) => {
          let contingency = 0;
          // calculate contingency
          if (isContingencyLineItem(liValue)) {
            contingency = liValue?.liveBudget;
          }
          // eslint-disable-next-line no-param-reassign
          acc = {
            publishedBudget:
              acc.publishedBudget + Number(liValue?.publishedBudget || 0),
            coTotal: acc.coTotal + Number(liValue?.coTotal || 0),
            updatedGmpOrFixedFirm:
              acc.updatedGmpOrFixedFirm +
              Number(liValue?.updatedGmpOrFixedFirm || 0),
            committedValues:
              acc.committedValues + Number(liValue?.committedValue || 0),
            workCompletedThisPeriod:
              acc.workCompletedThisPeriod +
              Number(liValue?.workCompletedThisPeriod || 0),
            workCompletedPreviousPeriod:
              acc.workCompletedPreviousPeriod +
              Number(liValue?.workCompletedPreviousPeriod || 0),
            workCompletedToDate:
              acc.workCompletedToDate +
              Number(liValue?.workCompletedToDate || 0),
            retainage: getRetainage(acc.retainage, liValue),
            holdback: acc.holdback + Number(liValue?.holdback || 0),
            workCompletedToDateLessRetainageAndHoldback:
              acc.workCompletedToDate +
              (Number(liValue?.workCompletedToDate || 0) -
                Number(liValue?.holdback || 0) -
                (Number(liValue?.retainage || 0) === "N/A"
                  ? 0
                  : Number(liValue?.retainage || 0))),
            balanceToFinish:
              acc.balanceToFinish +
              Number(liValue?.balanceToFinish || 0) +
              Number(liValue?.holdback || 0) +
              (Number(liValue?.retainage || 0) === "N/A"
                ? 0
                : Number(liValue?.retainage || 0)),
            remainingDepositOnHand:
              Number(projectDeposit?.totalDeposit || 0) -
              Number(projectDeposit?.currentInvoiceDeposit || 0),
            depositAppliedThisPeriod: Number(
              projectDeposit?.currentInvoiceDeposit || 0
            ),
            contingency: acc.contingency + Number(contingency || 0),
          };
          return acc;
        },
        {
          publishedBudget: 0,
          coTotal: 0,
          updatedGmpOrFixedFirm: 0,
          committedValues: 0,
          workCompletedThisPeriod: 0,
          workCompletedPreviousPeriod: 0,
          workCompletedToDate: 0,
          retainage: 0,
          holdback: 0,
          workCompletedToDateLessRetainageAndHoldback: 0,
          previousPaymentDue: 0,
          balanceToFinish: 0,
          paymentRequestedPreviousPeriod: 0,
          paymentRequestedThisPeriod: 0,
          depositAppliedThisPeriod: 0,
          remainingDepositOnHand: 0,
          contingency: 0,
        }
      );

      summaryValues.currentPaymentDue =
        summaryValues.workCompletedThisPeriod -
        summaryValues.depositAppliedThisPeriod;

      summaryValues.balanceToFinishLessContingency =
        summaryValues.balanceToFinish - summaryValues.contingency;

      setSummary(summaryValues);
    }
  }, [docBudgetLines, projectDeposit]);

  const summaryLines = useMemo(() => {
    const costPlusLocked =
      budget?.budgetType === BUDGET_COST_PLUS && budget?.isLockGmp;
    const isCostPlusBudgetType = budget?.budgetType === BUDGET_COST_PLUS;
    const isFixedFirmBudgetType = budget?.budgetType === BUDGET_FIXED_FIRM;
    const isTimeAndMaterialsBudgetType =
      budget?.budgetType === BUDGET_TIME_AND_MATERIALS;
    let lines = [];

    if (costPlusLocked) {
      lines = [
        {
          key: "Original GMP",
          value: summary?.publishedBudget,
        },
        {
          key: "CO Total Approved",
          value: summary?.coTotal,
        },
        {
          key: "Updated GMP",
          value: summary?.updatedGmpOrFixedFirm,
        },
        {
          key: "Work Completed To Date",
          value: summary?.workCompletedToDate,
        },
        {
          key: "Retainage",
          value: summary?.retainage,
        },
        {
          key: "Holdback",
          value: summary?.holdback,
        },
        {
          key: "Work Completed to Date Less Retainage and Holdback",
          value: summary?.workCompletedToDateLessRetainageAndHoldback,
        },
        {
          key: "Payment Requested Previous Period",
          value: summary?.workCompletedPreviousPeriod,
        },
        {
          key: "Payment Requested This Period",
          value: summary?.workCompletedThisPeriod,
        },
        {
          key: "Deposit Applied This Period",
          value: summary?.depositAppliedThisPeriod,
        },
        {
          key: "Current Payment Due",
          value: summary?.currentPaymentDue,
        },
        {
          key: "Balance to Finish including Holdback and Retainage",
          value: summary?.balanceToFinish,
        },
        {
          key: "Balance to Finish including Holdback and Retainage less Contingency",
          value: summary?.balanceToFinishLessContingency,
        },
        {
          key: "Remaining Deposit on Hand",
          value: summary?.remainingDepositOnHand,
        },
      ];
    } else if (isCostPlusBudgetType) {
      lines = [
        {
          key: "Control Estimate",
          value: summary?.publishedBudget,
        },
        {
          key: "Work Completed To Date",
          value: summary?.workCompletedToDate,
        },
        {
          key: "Retainage",
          value: summary?.retainage,
        },
        {
          key: "Holdback",
          value: summary?.holdback,
        },
        {
          key: "Work Completed to Date Less Retainage and Holdback",
          value: summary?.workCompletedToDateLessRetainageAndHoldback,
        },
        {
          key: "Payment Requested Previous Period",
          value: summary?.workCompletedPreviousPeriod,
        },
        {
          key: "Payment Requested This Period",
          value: summary?.workCompletedThisPeriod,
        },
        {
          key: "Deposit Applied This Period",
          value: summary?.depositAppliedThisPeriod,
        },
        {
          key: "Current Payment Due",
          value: summary?.currentPaymentDue,
        },
        {
          key: "Balance to Finish including Holdback and Retainage",
          value: summary?.balanceToFinish,
        },
        {
          key: "Balance to Finish including Holdback and Retainage less Contingency",
          value: summary?.balanceToFinishLessContingency,
        },
        {
          key: "Remaining Deposit on Hand",
          value: summary?.remainingDepositOnHand,
        },
      ];
    } else if (isFixedFirmBudgetType) {
      lines = [
        {
          key: "Original Contact Sum",
          value: summary?.publishedBudget,
        },
        {
          key: "CO Total Approved",
          value: summary?.coTotal,
        },
        {
          key: "Updated Contract Sum",
          value: summary?.updatedGmpOrFixedFirm,
        },
        {
          key: "Work Completed To Date",
          value: summary?.workCompletedToDate,
        },
        {
          key: "Retainage",
          value: summary?.retainage,
        },
        {
          key: "Holdback",
          value: summary?.holdback,
        },
        {
          key: "Work Completed to Date Less Retainage and Holdback",
          value: summary?.workCompletedToDateLessRetainageAndHoldback,
        },
        {
          key: "Payment Requested Previous Period",
          value: summary?.workCompletedPreviousPeriod,
        },
        {
          key: "Payment Requested This Period",
          value: summary?.workCompletedThisPeriod,
        },
        {
          key: "Deposit Applied This Period",
          value: summary?.depositAppliedThisPeriod,
        },
        {
          key: "Current Payment Due",
          value: summary?.currentPaymentDue,
        },
        {
          key: "Balance to Finish including Holdback and Retainage",
          value: summary?.balanceToFinish,
        },
        {
          key: "Balance to Finish including Holdback and Retainage less Contingency",
          value: summary?.balanceToFinishLessContingency,
        },
        {
          key: "Remaining Deposit on Hand",
          value: summary?.remainingDepositOnHand,
        },
      ];
    } else if (isTimeAndMaterialsBudgetType) {
      lines = [
        {
          key: "Control Estimate",
          value: summary?.publishedBudget,
        },
        {
          key: "Work Completed To Date",
          value: summary?.workCompletedToDate,
        },
        {
          key: "Payment Requested Previous Period",
          value: summary?.workCompletedPreviousPeriod,
        },
        {
          key: "Payment Requested This Period",
          value: summary?.workCompletedThisPeriod,
        },
        {
          key: "Deposit Applied This Period",
          value: summary?.depositAppliedThisPeriod,
        },
        {
          key: "Current Payment Due",
          value: summary?.currentPaymentDue,
        },
      ];
    }

    return lines;
  }, [
    budget?.budgetType,
    budget?.isLockGmp,
    summary?.balanceToFinish,
    summary?.balanceToFinishLessContingency,
    summary?.coTotal,
    summary?.currentPaymentDue,
    summary?.depositAppliedThisPeriod,
    summary?.holdback,
    summary?.publishedBudget,
    summary?.remainingDepositOnHand,
    summary?.retainage,
    summary?.updatedGmpOrFixedFirm,
    summary?.workCompletedPreviousPeriod,
    summary?.workCompletedThisPeriod,
    summary?.workCompletedToDate,
    summary?.workCompletedToDateLessRetainageAndHoldback,
  ]);

  return { summaryLines };
};

export default useDocumentDetailsSummaryData;

import React from "react";
import PropTypes from "prop-types";
import handIcon from "../../assets/images/handIcon.svg";
import { calcSvgStrokeOffset } from "../../../helpers/svg";

/**
 * Component that renders green dropable area
 */
const DropableArea = ({ subtitle }) => {
  return (
    <div
      className="flex flex-col items-center justify-center rounded-md"
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect ${calcSvgStrokeOffset(
          150,
          150
        )} fill='none' stroke='%2309AB86' rx='6' stroke-width='3' stroke-dasharray='7px 15px' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      }}
    >
      <div className="flex flex-col items-center justify-center h-full p-4">
        <p className="text-sm text-sm text-center font-semibold">Drag / Drop</p>
        {subtitle ? (
          <p className="text-sm text-center font-semibold">{subtitle}</p>
        ) : null}
        <img className="h-9 w-9" src={handIcon} alt="hand-icon" />
        <p className="text-sm font-semibold pt-3">
          Or <span className="underline text-brandGreen">browse</span>
        </p>
      </div>
    </div>
  );
};

DropableArea.defaultProps = {
  subtitle: undefined,
};

DropableArea.propTypes = {
  subtitle: PropTypes.string,
};

export default DropableArea;

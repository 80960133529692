/* @deprecated */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import {
  UserAPI,
  PreferenceAPI,
  ConfigurationAPI,
} from "@griffingroupglobal/eslib-api";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import ContactDetailsForm from "./ContactDetailsForm";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteCircleCheckIcon from "../../assets/images/circleCheckIcon.svg";
import whiteExlamationIcon from "../../assets/images/whiteExclamationIcon.svg";
import {
  formatPayloadUser,
  formatUserPayload,
  formatPreferenceUser,
  formatUserPreference,
  getUsedAndUnusedPreferences,
  disassociateCompanyFromContact,
} from "../../../helpers/User";
import { UPDATE_USERS } from "../../../constants";
import { useAppState } from "../../../state/appState";
import useContactReducer from "../../../hooks/useContactReducer";
import { tagKeys } from "../../../config/reactQuery/queryKeyFactory";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

/**
 * @deprecated: Remove this file
 */
const ContactEditForm = ({
  contactUser,
  preferences,
  preferencesOptions,
  companyContacts,
  currentTabIndex,
  companyAssignees,
  originalCompanyAssignees,
  managementConfiguration,
  configurationLastUpdated,
  editing,
  setEditing,
  setEditingContact,
  setPreferencesUpdated,
  reloadAssignedUsers,
  reloadUsers,
}) => {
  const [, dispatch] = useAppState();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { contactDetails, contactInfo } = useMemo(
    () => formatPayloadUser(contactUser),
    [contactUser]
  );
  const contactPreferences = useMemo(
    () => formatPreferenceUser(preferences),
    [preferences]
  );

  const contactPreferencesOptions = useMemo(
    () => formatPreferenceUser(preferencesOptions),
    [preferencesOptions]
  );

  const [contactResource, contactDispatch] = useContactReducer();

  const [activeIndex] = useState(+currentTabIndex || 0);
  const [details, setDetails] = useState(contactDetails);
  const [detailsClone, setDetailsClone] = useState();
  const [info, setInfo] = useState(contactInfo);
  const [infoClone, setInfoClone] = useState();
  const [preference, setPreference] = useState(contactPreferencesOptions);
  const [preferenceClone, setPreferenceClone] = useState();
  const [, setCustomPreference] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [newDisciplines] = useState([]);

  useEffect(() => {
    if (contactUser && loadingDetails) {
      setDetails(contactDetails);
      setDetailsClone(contactDetails);
      setInfo(contactInfo);
      setInfoClone(contactInfo);
      setLoadingDetails(false);

      contactDispatch({
        type: "setOriginalResource",
        resource: contactInfo,
      });
    }

    if (contactPreferences && contactPreferencesOptions) {
      const {
        usedPreferences: usedFavorites,
        unusedCustomPreferences: unusedCustomFavorites,
      } = getUsedAndUnusedPreferences(
        contactPreferencesOptions,
        contactPreferences,
        "favorites"
      );

      const {
        usedPreferences: usedDislikes,
        unusedCustomPreferences: unusedCustomDislikes,
      } = getUsedAndUnusedPreferences(
        contactPreferencesOptions,
        contactPreferences,
        "dislikes"
      );

      const {
        usedPreferences: usedSpecials,
        unusedCustomPreferences: unusedCustomSpecials,
      } = getUsedAndUnusedPreferences(
        contactPreferencesOptions,
        contactPreferences,
        "specials"
      );

      setPreference({
        favorites: usedFavorites,
        dislikes: usedDislikes,
        specials: usedSpecials,
      });

      setPreferenceClone({
        favorites: usedFavorites,
        dislikes: usedDislikes,
        specials: usedSpecials,
      });

      setCustomPreference({
        favorites: unusedCustomFavorites,
        dislikes: unusedCustomDislikes,
        specials: unusedCustomSpecials,
      });
    }
  }, [
    contactDetails,
    contactPreferences,
    contactPreferencesOptions,
    contactInfo,
    contactUser,
    loadingDetails,
    contactDispatch,
    setCustomPreference,
  ]);

  const onSave = useCallback(async () => {
    const patches = [];
    /**
     * Delay Saving
     * @summary - give Toast time to load
     */

    /**
     * Push API patches into array
     * For Promise All and Toast
     * Saving/Success/Error Message
     */

    setIsSaving(true);

    const payload = await formatUserPayload(
      details,
      info,
      contactUser?.kind,
      contactResource?.currentTags
    );
    const newPayload = { ...contactUser, ...payload };

    /**
     * Compare Details to Clone
     * @summary - Conditionally patch on changes
     */
    if (
      !_.isEqual(infoClone, info) ||
      !_.isEqual(detailsClone, details) ||
      !_.isEqual(
        contactResource?.currentTags?.map((tag) => tag?.value) || [],
        contactResource?.originalResource?.tags || []
      )
    ) {
      patches.push(() =>
        UserAPI.patch(contactUser?.id, newPayload, contactUser)
      );
    }

    /**
     * Compare Preferences to Clone
     * @summary - Conditionally patch on changes
     */
    let preferenceData;
    if (contactUser?.kind !== "company") {
      const newPreference = formatUserPreference("", preference);
      preferenceData = { ...preferences, ...newPreference };
      if (!_.isEqual(preference, preferenceClone)) {
        patches.push(() =>
          PreferenceAPI.patch(preferences.id, preferenceData, preferences)
        );
      }
    }

    /**
     * Check for new Assigness
     * @summary - Conditionally patch new Assignees
     */
    const newAssignees = _.differenceBy(
      companyAssignees,
      originalCompanyAssignees,
      "value"
    );

    if (newAssignees?.length > 0) {
      const newAddedAssignees = newAssignees?.map((a) => a?.reference);
      patches.push(() =>
        UserAPI.postByIdWOP(details.id, "$companymembers", {
          add: newAddedAssignees,
          remove: [],
        })
      );
    }

    /**
     * Check for removed Assignees
     * @summary - Conditionally patch removed assignees
     */
    const removedAssignees = _.differenceBy(
      originalCompanyAssignees,
      companyAssignees,
      "value"
    );

    for (let i = 0; i < removedAssignees?.length; i += 1) {
      const assineeId = removedAssignees[i]?.value.split("/")[1];
      const lastUpdated = removedAssignees[i]?.metadata?.lastUpdated;
      patches.push(() =>
        disassociateCompanyFromContact(assineeId, lastUpdated)
      );
    }

    // adding new discplines if newDisciplines state contains value
    if (newDisciplines.length) {
      const newDisciplinesToAdd = newDisciplines.map((discipline) => ({
        selected: true,
        custom: true,
        id: discipline.id,
        display: discipline.display,
      }));
      const { management } = managementConfiguration;
      const patchBody = {
        ...management,
        contact: {
          ...management.contact,
          disciplines: [
            ...management.contact.disciplines,
            ...newDisciplinesToAdd,
          ],
        },
      };
      try {
        await ConfigurationAPI.patch(
          "management",
          { ...managementConfiguration, management: patchBody },
          managementConfiguration,
          { date: configurationLastUpdated }
        );
        // reload configuration
        queryClient.invalidateQueries("configuration");
      } catch (err) {
        console.error("Error updating management configuration");
      }
    }

    /**
     * Promise All Patches
     * @summary - Catch Patches Success/Error
     */
    let arrayOfPatches = patches?.map((patch) => patch());

    if (arrayOfPatches?.length > 0) {
      const SavingDelay = () => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 2000);
        });
      };
      arrayOfPatches = [SavingDelay(), ...arrayOfPatches];
      setIsSaving(true);
      /**
       * Initialize Loading Toast
       */
      const savingToast = toast("Saving...", {
        isLoading: true,
        position: "top-center",
      });

      Promise.all(arrayOfPatches)
        .then(() => {
          /**
           * On Success
           * @summary - Update Toast Accordingly
           * Redirect
           */
          toast.update(savingToast, {
            isLoading: false,
            render: "Saved",
            closeButton: toastCloseIcon,
            className: "bg-brandGreen text-white",
            hideProgressBar: true,
            position: "top-center",
            icon: toastIcon,
            autoClose: 3000,
          });
          // dispatch new_Payload to appState in UPDATE_USERS
          dispatch({
            type: UPDATE_USERS,
            user: newPayload,
          });
          reloadAssignedUsers();
          reloadUsers();

          // update tags in s&c
          queryClient.invalidateQueries(tagKeys.tags);

          setPreferencesUpdated(true);
          setIsSaving(false);
          history.push(
            `/contacts/${details.id || contactUser?.id}/${
              contactUser?.kind === "company" ? 0 : activeIndex
            }`
          );
        })
        .catch(() => {
          /**
           * On Failure
           * @summary -  Update Toast Accordingly
           * Redirect
           */
          toast.update(savingToast, {
            isLoading: false,
            render: "Error Saving",
            style: {
              backgroundColor: "#BC2727",
              color: "white",
            },
            closeButton: toastCloseIcon,
            position: "top-center",
            hideProgressBar: true,
            icon: toastErrorIcon,
            autoClose: 3000,
          });
          setIsSaving(false);
          history.push(
            `/contacts/${details.id || contactUser?.id}/${
              contactUser?.kind === "company" ? 0 : activeIndex
            }`
          );
        });
    }
  }, [
    details,
    info,
    contactUser,
    contactResource?.currentTags,
    contactResource?.originalResource?.tags,
    infoClone,
    detailsClone,
    companyAssignees,
    originalCompanyAssignees,
    newDisciplines,
    preference,
    preferences,
    preferenceClone,
    managementConfiguration,
    configurationLastUpdated,
    queryClient,
    dispatch,
    reloadAssignedUsers,
    reloadUsers,
    setPreferencesUpdated,
    history,
    activeIndex,
  ]);

  return (
    <div className="flex">
      <ContactDetailsForm
        contactDetails={details}
        setContactDetails={setDetails}
        companyContacts={companyContacts}
        saving={isSaving}
        isEdit
        editing={editing}
        setEditing={setEditing}
        onSave={onSave}
        setEditingContact={setEditingContact}
      />
    </div>
  );
};

const contactsShape = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    avatar: PropTypes.string,
  })
);

ContactEditForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  contactUser: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  preferences: PropTypes.object,
  companyContacts: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  currentTabIndex: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  preferencesOptions: PropTypes.object,
  companyAssignees: contactsShape,
  originalCompanyAssignees: contactsShape,
  managementConfiguration: PropTypes.shape({
    management: PropTypes.shape({
      contact: PropTypes.shape({
        disciplines: PropTypes.arrayOf({
          display: PropTypes.string,
          id: PropTypes.string,
        }),
      }),
    }),
  }),
  configurationLastUpdated: PropTypes.string,
  editing: PropTypes.bool,
  setEditing: PropTypes.func,
  setEditingContact: PropTypes.func,
  setPreferencesUpdated: PropTypes.func,
  reloadAssignedUsers: PropTypes.func,
  reloadUsers: PropTypes.func,
};

ContactEditForm.defaultProps = {
  contactUser: undefined,
  preferences: undefined,
  companyContacts: undefined,
  currentTabIndex: undefined,
  preferencesOptions: undefined,
  companyAssignees: undefined,
  originalCompanyAssignees: undefined,
  managementConfiguration: undefined,
  configurationLastUpdated: undefined,
  editing: false,
  setEditing: undefined,
  setEditingContact: undefined,
  setPreferencesUpdated: undefined,
  reloadAssignedUsers: () => {},
  reloadUsers: () => {},
};

export default ContactEditForm;

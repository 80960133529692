import React, { useState } from "react";
import { useParams } from "react-router-dom";
import SiteHeader from "../../stories/Components/SiteHeader/SiteHeader";
import useProjects from "../../hooks/useProjects";
import { getProjectOptions } from "../../helpers/SiteHeaderOptions";
import Spaces from "../Spaces/Spaces";
import PrimaryButton from "../../stories/Components/Buttons/PrimaryButton";

/**
 * Will Be @deprecated after flattening
 */

const ProjectSpaces = ({ currentUser }) => {
  const { projectId } = useParams();
  const { currentProject } = useProjects(projectId);
  const [actions, setActions] = useState([]);

  return (
    <>
      <SiteHeader
        title={currentProject?.name ?? "Loading..."}
        dropdownRoutes={getProjectOptions(projectId, currentUser)}
        buttons={
          currentUser?.hasPermission?.(
            "administrative",
            "can_write_project"
          ) && (
            <PrimaryButton
              title="Actions"
              dropdownItems={actions}
              className="dropdown-btn"
              large
            />
          )
        }
      />
      <Spaces className="pt-6 px-2" setActions={setActions} />
    </>
  );
};

export default ProjectSpaces;

import PropTypes from "prop-types";
import React from "react";
import { PRIMARY_DARK_GREEN } from "../../../constants";
import crossIconWhite from "../../assets/images/crossIconWhite.svg";

/**
 * Component that renders a green rounded cross.
 */
const CrossBubble = ({ onClick, height, width, positionCN, className }) => {
  return (
    <button
      type="button"
      className={`absolute ${positionCN} ${className}`}
      onClick={onClick}
      style={{
        width,
        height,
      }}
    >
      <img
        alt="click-bubble"
        className="rounded-xl p-1"
        style={{ backgroundColor: PRIMARY_DARK_GREEN }}
        src={crossIconWhite}
      />
    </button>
  );
};

CrossBubble.defaultProps = {
  height: "22px",
  width: "22px",
  onClick: () => {},
  positionCN: "-top-1.5 -right-1",
  className: undefined,
};

CrossBubble.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  positionCN: PropTypes.string,
  className: PropTypes.string,
};

export default CrossBubble;

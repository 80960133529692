import PropTypes from "prop-types";
import React, { useMemo } from "react";
import Input from "../../../Input/Input";

const TimeoffAmountInput = ({
  onChange,
  value: timeoffAmount,
  date,
  processedPto,
}) => {
  const disabled = useMemo(() => {
    return (
      processedPto?.[new Date(date).toISOString()] === "closed" ||
      !["boolean", "object"].includes(typeof processedPto)
    );
  }, [date, processedPto]);

  return (
    <Input
      placeholder="Amount"
      disableClear
      value={timeoffAmount}
      className="w-16"
      mainClassName="w-14 col-span-1"
      inputClassName="bg-inputBgGreen ESInput"
      onChange={(val) => onChange(val, date)}
      disabled={disabled}
    />
  );
};

TimeoffAmountInput.propTypes = {
  /**
   * Parse input on blur
   */
  onChange: PropTypes.func,
  /**
   * Value from reducer(parent)
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * date for request entry object
   */
  date: PropTypes.string,
  /**
   * Processed pto object to check requested days against
   */
  processedPto: PropTypes.shape({}),
};

TimeoffAmountInput.defaultProps = {
  onChange: () => {},
  value: "",
  date: "",
  processedPto: {},
};

export default TimeoffAmountInput;

import { useCallback, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router";
import { CALENDAR_PATH } from "../../../../constants";

export default ({ project, dispatch, readable }) => {
  const history = useHistory();
  const handleChange = useCallback(
    (complete, retained, i) => {
      let percentComplete = complete ? parseInt(complete, 10) : 0;
      percentComplete = percentComplete > 100 ? 100 : percentComplete;

      let percentRetained = retained ? parseInt(retained, 10) : 0;
      percentRetained = percentRetained > 100 ? 100 : percentRetained;

      percentRetained =
        percentRetained > 100 - complete ? 100 - complete : percentRetained;
      let retainage = [...project?.retainage];
      retainage = retainage?.toSpliced(i, 1, {
        ...project?.retainage[i],
        percentComplete,
        percentRetainage: percentComplete === 100 ? 0 : percentRetained,
      });
      dispatch({
        type: "edit",
        key: "retainage",
        value: retainage,
      });
    },
    [dispatch, project?.retainage]
  );

  useEffect(() => {
    if (typeof readable === "boolean" && readable === false)
      history.replace(CALENDAR_PATH);
  }, [history, readable]);

  const handleRemoveValue = useCallback(
    (index) => {
      const retainage = project?.retainage?.toSpliced(index, 1);

      dispatch({
        type: "edit",
        key: "retainage",
        value: retainage,
      });
    },
    [dispatch, project?.retainage]
  );

  const handleAddRetainage = () => {
    dispatch({
      type: "edit",
      key: "retainage",
      value: [
        ...project?.retainage,
        {
          percentComplete: null,
          percentRetainage: null,
          id: uuidv4(),
          index: project?.retainage?.length ?? 0,
        },
      ],
    });
  };

  return { handleAddRetainage, handleChange, handleRemoveValue };
};

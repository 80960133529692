import React, { useState, useEffect } from "react";
import cntl from "cntl";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { HightchartOptions } from "../../../helpers/Highcharts";
import Summary from "../../../stories/Components/Summary/Summary";
import financialActualQuarterlyData from "../../../stories/assets/mockData/financialActualQuarterlyData.json";
import financialActualChartData from "../../../stories/assets/mockData/financialActualChartData";
import financialForecastChartData from "../../../stories/assets/mockData/financialForecastChartData";
import BaseButton from "../../../stories/Components/Buttons/BaseButton";
import PlusIcon from "../../../stories/Components/PlusIcon/PlusIcon";
import TrashButton from "../../../stories/Components/Buttons/TrashButton";

const chartHeaderCN = cntl`
  flex
  mt-6
  mb-3
  border-t
  border-gray-200
  py-1.5
  font-bold
  text-gray-500
`;

/**
 * @deprecated
 */

const FinancialCharts = () => {
  // charts is an array of objects containing data for forecast and actual for each chart
  const [charts, setCharts] = useState([]);

  useEffect(() => {
    // initially sets the charts array to contain chart mock data
    setCharts([
      {
        forecast: financialForecastChartData,
        actual: financialActualChartData,
        id: 1,
      },
    ]);
  }, []);

  const getChartOptions = (forecastData, actualData) => {
    return {
      chart: {
        type: "column",
        marginBottom: 48,
      },
      series: [
        {
          name: "Forecast",
          data: forecastData,
        },
        {
          name: "Actual",
          data: actualData,
        },
      ],
      xAxis: {
        type: "datetime",
        labels: {
          format: "{value:%m/%d}",
        },
      },
    };
  };

  const removeChart = (chartId) => {
    setCharts(charts.filter((chart) => chart.id !== chartId));
  };

  // This re-adds the mock data to the charts array if it's been removed
  // or adds a new member to the array based on the mock data, but with
  // new randomized values from 1-100.
  // Obviously this is totally temporary, till chart data is figured out.
  const addRandoChart = () => {
    if (charts.length > 0) {
      setCharts([
        ...charts,
        {
          forecast: financialForecastChartData.map((forecastData) => ({
            ...forecastData,
            y: Math.floor(Math.random() * 101),
          })),
          actual: financialActualChartData.map((actualData) => ({
            ...actualData,
            y: Math.floor(Math.random() * 101),
          })),
          id: charts[charts.length - 1].id + 1,
        },
      ]);
    } else {
      setCharts([
        {
          forecast: financialForecastChartData,
          actual: financialActualChartData,
          id: 1,
        },
      ]);
    }
  };

  return (
    <>
      <Summary
        title="Quarterly Total"
        values={financialActualQuarterlyData}
        className="my-6"
      />
      {charts.length > 0 &&
        charts.map((chart) => (
          <React.Fragment key={chart.id}>
            <div className={chartHeaderCN}>
              {/* TODO: add chart icon here */}
              <p>Forecast &amp; Actual{chart.id !== 1 && ` ${chart.id}`}</p>
              <BaseButton
                className="ml-auto"
                iconLeft={<TrashButton />}
                onClick={() => removeChart(chart.id)}
              />
            </div>
            <HighchartsReact
              highcharts={HightchartOptions(Highcharts)}
              options={getChartOptions(chart.forecast, chart.actual)}
            />
          </React.Fragment>
        ))}

      <BaseButton
        className={`my-6 ${charts.length > 0 && "m-auto"}`}
        colorCN="text-gray-300"
        hoverColorCN="text-gray-200"
        title={charts.length > 0 ? "Add Another" : "Add Data Visualization"}
        iconLeft={<PlusIcon />}
        onClick={() => addRandoChart()}
      />
    </>
  );
};

export default FinancialCharts;

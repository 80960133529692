import React, { useRef } from "react";
import PropTypes from "prop-types";
import handIcon from "../../assets/images/handIcon.svg";
import { calcSvgStrokeOffset } from "../../../helpers/svg";

const AddAttachmentButtonNested = ({
  getInputProps,
  shared,
  isRequestUpload,
  nestedButtonWrapperCN,
}) => {
  const buttonRef = useRef();

  return (
    <>
      {!isRequestUpload && (
        <div
          className={`flex flex-col items-start mt-4 ${nestedButtonWrapperCN}`}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input {...getInputProps()} />
          <div
            className="flex flex-col items-center justify-start rounded-md"
            ref={buttonRef}
            style={{
              backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect ${calcSvgStrokeOffset(
                buttonRef?.current?.getBoundingClientRect()?.height,
                buttonRef?.current?.getBoundingClientRect()?.width
              )} fill='none' stroke='%2309AB86' rx='6' stroke-width='3' stroke-dasharray='7px 15px' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
            }}
          >
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <input {...getInputProps()} />
            <div className="flex items-center justify-center px-10 py-3 rounded-md w-auto h-14">
              <p className=" text-base font-semibold">Drag / Drop</p>
              <img className=" h-9 w-9" src={handIcon} alt="hand-icon" />
            </div>
            <p className="text-base font-semibold pb-3">
              Or <span className=" underline text-brandGreen">browse</span>
            </p>
          </div>
        </div>
      )}
      {isRequestUpload && (
        <div className="flex flex-col items-center">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input {...getInputProps()} />
          {shared && (
            <div className="flex flex-col justify-center items-start gap-2">
              <div
                className="flex border border-dashed border-brandGreen rounded-lg h-14 items-center justify-center w-auto"
                style={{ padding: "2px" }}
              >
                <p className="font-semibold text-sm text-center h-8 w-28 flex items-center justify-center">
                  Drag/Drop
                </p>
              </div>
              <p className=" font-semibold text-xs text-center">
                or{" "}
                <span className=" text-brandDarkGreen underline">browse</span>
              </p>
            </div>
          )}
          {!shared && (
            <div className="flex justify-center items-center gap-1">
              <div
                className="border border-dashed border-brandGreen rounded-lg w-48 h-14 items-center justify-center flex"
                style={{ padding: "2px" }}
              >
                <p className="font-semibold text-sm text-center h-8 w-28 flex items-center justify-center">
                  Drag/Drop
                </p>
              </div>
              <p className="ml-2 font-semibold text-xs text-center w-24 h-8 flex items-center">
                or{" "}
                <span className="ml-1 text-brandDarkGreen underline">
                  browse
                </span>
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

AddAttachmentButtonNested.propTypes = {
  /**
   * Props from Upload Area
   */

  getInputProps: PropTypes.func,
  disabled: PropTypes.bool,
  /*  boolean that controls look based on cell sharing space */
  shared: PropTypes.bool,
  /* Boolean that controls display if being used in request workflow */
  isRequestUpload: PropTypes.bool,
  nestedButtonWrapperCN: PropTypes.string,
};

AddAttachmentButtonNested.defaultProps = {
  getInputProps: undefined,
  disabled: false,
  shared: false,
  isRequestUpload: false,
  nestedButtonWrapperCN: "",
};

export default AddAttachmentButtonNested;

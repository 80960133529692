import cntl from "cntl";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  reformatCurrencyString,
  transformCurrencyIntoPositiveFloat,
} from "../../../helpers/Formatters";
import DetailRow from "../DetailRow/DetailRow";
import EditField from "../EditField/EditField";
import Widget from "../Widget/Widget";

const textCN = cntl`
  text-gray-450
  text-sm
  font-normal
  w-full
  ml-4
  flex
`;

const titleBottomBorder = cntl`
  border-b
  border-gray-450
  border-opacity-50
`;

const INTENDED_USE_OPTIONS = [
  { label: "Unspecified", value: "unspecified" },
  { label: "Scheduling", value: "scheduling" },
  { label: "Damage settlement", value: "damagesettlement" },
  { label: "Estate taxes", value: "estatetaxes" },
  { label: "Charity", value: "charity" },
  { label: "Estate planning", value: "estateplanning" },
  { label: "Equitable distribution", value: "equitabledistribution" },
  { label: "Marital assets in divorce", value: "maritalassetsindivorce" },
  { label: "Advice in sale", value: "adviceinsale" },
  { label: "Liquidation", value: "liquidation" },
  { label: "Bankruptcy", value: "bankruptcy" },
];

function getIntendedUse(intendedUse) {
  return INTENDED_USE_OPTIONS?.find(
    (iu) => iu?.value === intendedUse ?? "unspecified"
  )?.label;
}

const formatDate = (date) => {
  // allow undefined date
  return date ? moment(date).format("LL") : date;
};

const InformationFirstColumn = ({
  editedObject,
  editing,
  resourceName,
  dispatch,
  handleEditTwo,
  estimatedValue,
  setEstimatedValue,
}) => {
  useEffect(() => {
    if (Array.isArray(editedObject?.value)) {
      setEstimatedValue(
        editedObject?.value.find((item) => {
          return item?.type?.code === "EST";
        })
      );
    }
  }, [editedObject, setEstimatedValue]);

  const purchasePriceDisplay = editedObject?.purchase?.price
    ? `$ ${reformatCurrencyString(editedObject?.purchase?.price)}`
    : null;

  const filteredEstimatedValue = editedObject?.value?.filter(
    (v) => v?.type?.code === "EST"
  )?.[0]?.amount;

  const editedEstimatedValue = (resource) => {
    switch (resource) {
      case "Asset":
        return filteredEstimatedValue;
      default:
        return editedObject?.estimatedValue?.price;
    }
  };
  const estimatedValueDisplay = editedEstimatedValue(resourceName)
    ? `$ ${reformatCurrencyString(editedEstimatedValue(resourceName))}`
    : null;

  const editedReplacementCost = (resource) => {
    switch (resource) {
      case "Asset":
        return editedObject?.replacement?.cost;
      default:
        return editedObject?.replacementCost?.price;
    }
  };
  const replacementCostDisplay = editedReplacementCost(resourceName)
    ? `$ ${reformatCurrencyString(editedReplacementCost(resourceName))}`
    : null;

  const editedDate = (resource) => {
    switch (resource) {
      case "Asset":
        return editedObject?.replacement?.date;
      default:
        return editedObject?.replacementCost?.date;
    }
  };

  const dateDisplay = editedDate(resourceName)
    ? formatDate(editedDate(resourceName))
    : null;

  return (
    <div className="col-span-1 pr-6 bg-white">
      <DetailRow
        title="Original Purchase Price"
        containerClassName={titleBottomBorder}
      >
        <div className={textCN}>
          {!editing ? (
            purchasePriceDisplay
          ) : (
            <EditField
              className="w-full"
              type="CURRENCY"
              value={editedObject?.purchase?.price}
              editValue={editedObject?.purchase?.price}
              onChange={(val) =>
                handleEditTwo(
                  "purchase",
                  "price",
                  transformCurrencyIntoPositiveFloat(
                    val,
                    editedObject?.purchase?.price
                  )
                )
              }
              isEditing={editing}
            />
          )}
        </div>
      </DetailRow>
      <DetailRow title="Purchase Date" containerClassName={titleBottomBorder}>
        <div className={textCN}>
          {!editing ? (
            formatDate(editedObject?.purchase?.date)
          ) : (
            <EditField
              className="w-full"
              type="DATE"
              value={formatDate(editedObject?.purchase?.date)}
              editValue={editedObject?.purchase?.date}
              onChange={(val) => {
                handleEditTwo("purchase", "date", val);
              }}
              isEditing={editing}
              allowDateClear
            />
          )}
        </div>
      </DetailRow>
      <DetailRow
        title="Current Estimated Value"
        containerClassName={titleBottomBorder}
      >
        <div className={textCN}>
          {!editing ? (
            estimatedValueDisplay
          ) : (
            <EditField
              className="w-full"
              type="CURRENCY"
              value={editedEstimatedValue(resourceName)}
              editValue={editedEstimatedValue(resourceName)}
              onChange={(val) => {
                switch (resourceName) {
                  case "Asset": {
                    const temp = {
                      ...estimatedValue,
                      amount: transformCurrencyIntoPositiveFloat(
                        val,
                        editedEstimatedValue(resourceName)
                      ),
                    };
                    dispatch({
                      type: "estimatedValue",
                      data: temp,
                    });
                    setEstimatedValue({});
                    break;
                  }
                  default: {
                    handleEditTwo(
                      "estimatedValue",
                      "price",
                      transformCurrencyIntoPositiveFloat(
                        val,
                        editedObject?.estimatedValue?.price
                      )
                    );
                  }
                }
              }}
              isEditing={editing}
            />
          )}
        </div>
      </DetailRow>
      <DetailRow
        title="Replacement Cost"
        containerClassName={titleBottomBorder}
      >
        <div className={textCN}>
          {!editing ? (
            replacementCostDisplay
          ) : (
            <EditField
              className="w-full"
              type="CURRENCY"
              value={editedReplacementCost(resourceName)}
              editValue={editedReplacementCost(resourceName)}
              onChange={(val) => {
                const key1 = {
                  Asset: "replacement",
                };
                const key2 = {
                  Asset: "cost",
                };

                handleEditTwo(
                  key1[resourceName] || "replacementCost",
                  key2[resourceName] || "price",
                  transformCurrencyIntoPositiveFloat(
                    val,
                    editedReplacementCost(resourceName)
                  )
                );
              }}
              isEditing={editing}
            />
          )}
        </div>
      </DetailRow>
      <DetailRow title="Replacement Date">
        <div className={textCN}>
          {!editing ? (
            dateDisplay
          ) : (
            <EditField
              className="w-full"
              type="DATE"
              value={dateDisplay}
              editValue={dateDisplay}
              onChange={(val) => {
                if (resourceName === "Asset")
                  handleEditTwo("replacement", "date", val);
                else handleEditTwo("replacementCost", "date", val);
              }}
              isEditing={editing}
              allowDateClear
            />
          )}
        </div>
      </DetailRow>
    </div>
  );
};

const InformationSecondColumn = ({
  editedObject,
  editing,
  resourceName,
  dispatch,
  handleEdit,
  handleEditTwo,
  appraisalValue,
  setAppraisalValue,
  estimatedValue = {},
  setEstimatedValue = { undefined },
}) => {
  useEffect(() => {
    // if editedObject.value is an array this must be an asset
    // in this case we need to find the appraisal value
    if (Array.isArray(editedObject?.value))
      setAppraisalValue(
        editedObject?.value.find((item) => {
          return item?.type?.code === "ASS";
        })
      );
  }, [editedObject, setAppraisalValue]);

  const editedValuePrice = (resource) => {
    switch (resource) {
      case "Asset": {
        /* Finds the nested value object by type code, and returns the amount */
        const nestedAppraisalValueObj = editedObject?.value?.find((item) => {
          return item?.type?.code === "ASS";
        });
        return nestedAppraisalValueObj?.amount;
      }
      default:
        return editedObject?.appraisedValue?.price;
    }
  };

  const appraisedValue = editedValuePrice(resourceName)
    ? `$ ${reformatCurrencyString(editedValuePrice(resourceName))}`
    : null;

  const editedValueDate = (resource) => {
    switch (resource) {
      case "Asset":
        return appraisalValue?.dateOfExamination;
      default:
        return editedObject?.appraisedValue?.date;
    }
  };
  const appraisalDate = editedValueDate(resourceName)
    ? formatDate(editedValueDate(resourceName))
    : null;

  const editedIntendedUse = (resource) => {
    switch (resource) {
      case "Asset":
        return appraisalValue?.intendedUse || "unspecified";
      default:
        return editedObject?.intendedUse || "unspecified";
    }
  };

  const reappraisalDate = editedObject?.reappraisalDate
    ? formatDate(editedObject?.reappraisalDate)
    : null;

  const examinerName =
    resourceName === "Asset"
      ? editedObject?.value?.filter((val) => {
          const examiner = Object.keys(val)?.includes("examiner");
          return examiner;
        })?.[0]?.examiner
      : editedObject?.inspector;

  return (
    <div className="col-span-1 pl-6 bg-white">
      <DetailRow title="Appraised Value" containerClassName={titleBottomBorder}>
        <div className={textCN}>
          {!editing ? (
            appraisedValue
          ) : (
            <EditField
              className="w-full"
              type="CURRENCY"
              value={editedValuePrice(resourceName)}
              editValue={editedValuePrice(resourceName)}
              onChange={(val) => {
                switch (resourceName) {
                  case "Asset": {
                    const temp = {
                      ...appraisalValue,
                      amount: transformCurrencyIntoPositiveFloat(
                        val,
                        editedValuePrice(resourceName)
                      ),
                    };
                    dispatch({
                      type: "appraisalValue",
                      data: temp,
                    });
                    setAppraisalValue({});
                    break;
                  }
                  default: {
                    handleEditTwo(
                      "appraisedValue",
                      "price",
                      transformCurrencyIntoPositiveFloat(
                        val,
                        editedObject?.appraisedValue?.price
                      )
                    );
                  }
                }
              }}
              isEditing={editing}
            />
          )}
        </div>
      </DetailRow>
      <DetailRow title="Appraisal Date" containerClassName={titleBottomBorder}>
        <div className={textCN}>
          {!editing ? (
            appraisalDate
          ) : (
            <EditField
              className="w-full"
              type="DATE"
              value={appraisalDate}
              editValue={editedValueDate(resourceName)}
              onChange={(val) => {
                switch (resourceName) {
                  case "Asset": {
                    const temp = {
                      ...appraisalValue,
                      dateOfExamination: val,
                    };
                    dispatch({
                      type: "appraisalValue",
                      data: temp,
                    });
                    setAppraisalValue({});
                    break;
                  }
                  default: {
                    handleEditTwo("appraisedValue", "date", val);
                  }
                }
              }}
              isEditing={editing}
              allowDateClear
            />
          )}
        </div>
      </DetailRow>
      <DetailRow title="Intended Use" containerClassName={titleBottomBorder}>
        <div className={textCN}>
          {!editing ? (
            getIntendedUse(editedIntendedUse(resourceName))
          ) : (
            <EditField
              className="w-full pr-3"
              type="DROPDOWN"
              options={INTENDED_USE_OPTIONS}
              value={editedIntendedUse(resourceName)}
              editValue={editedIntendedUse(resourceName)}
              onChange={(val) => {
                switch (resourceName) {
                  case "Asset": {
                    const temp = { ...appraisalValue, intendedUse: val.value };
                    dispatch({
                      type: "appraisalValue",
                      data: temp,
                    });
                    setAppraisalValue({});
                    break;
                  }
                  default: {
                    handleEdit("intendedUse", val.value);
                  }
                }
              }}
              isEditing={editing}
            />
          )}
        </div>
      </DetailRow>
      <DetailRow
        title="Reappraisal Date"
        containerClassName={titleBottomBorder}
      >
        <div className={textCN}>
          {!editing ? (
            reappraisalDate
          ) : (
            <EditField
              className="w-full"
              type="DATE"
              value={
                editedObject?.reappraisalDate
                  ? moment(editedObject?.reappraisalDate).format("LL")
                  : null
              }
              editValue={editedObject?.reappraisalDate}
              onChange={(val) => handleEdit("reappraisalDate", val)}
              isEditing={editing}
              allowDateClear
            />
          )}
        </div>
      </DetailRow>
      <DetailRow title="Examiner">
        <div className={textCN}>
          {!editing ? (
            examinerName || "Unspecified"
          ) : (
            <EditField
              className="w-full"
              type="INPUT"
              value={examinerName}
              editValue={examinerName}
              onChange={(val) => {
                if (resourceName === "Asset") {
                  // set examiner for Asset
                  const temp = {
                    ...estimatedValue,
                    examiner: val,
                  };
                  dispatch({
                    type: "estimatedValue",
                    data: temp,
                  });
                  setEstimatedValue({});
                } else {
                  // set examiner for Property
                  dispatch({ type: "inspector", inspector: val });
                }
              }}
              isEditing={editing}
            />
          )}
        </div>
      </DetailRow>
    </div>
  );
};

const FinancesInformation = ({
  editedObject,
  editing,
  dispatch,
  resourceName,
}) => {
  const [estimatedValue, setEstimatedValue] = useState();
  const [appraisalValue, setAppraisalValue] = useState();

  const handleEdit = (key, value) => {
    dispatch({
      type: "edit",
      key,
      value,
    });
  };

  const handleEditTwo = (key1, key2, value) => {
    dispatch({
      type: "edit2",
      key1,
      key2,
      value,
    });
  };

  return (
    <Widget
      draggable={false}
      title="Finances Information"
      className="mb-4 w-full"
      overflow
      width="full"
    >
      <div
        className="relative grid grid-cols-2 bg-gray-450 bg-opacity-50"
        style={{ gap: "1px" }}
      >
        <InformationFirstColumn
          estimatedValue={estimatedValue}
          setEstimatedValue={setEstimatedValue}
          editedObject={editedObject}
          editing={editing}
          resourceName={resourceName}
          dispatch={dispatch}
          handleEdit={handleEdit}
          handleEditTwo={handleEditTwo}
        />
        <InformationSecondColumn
          appraisalValue={appraisalValue}
          setAppraisalValue={setAppraisalValue}
          editedObject={editedObject}
          editing={editing}
          resourceName={resourceName}
          dispatch={dispatch}
          handleEdit={handleEdit}
          handleEditTwo={handleEditTwo}
          estimatedValue={estimatedValue}
          setEstimatedValue={setEstimatedValue}
        />
      </div>
    </Widget>
  );
};

FinancesInformation.propTypes = {
  editedObject: PropTypes.shape({
    isInvestment: PropTypes.bool,
  }),
  editing: PropTypes.bool,
  resourceName: PropTypes.string,
  dispatch: PropTypes.func,
};

FinancesInformation.defaultProps = {
  editedObject: {},
  editing: false,
  resourceName: undefined,
  dispatch: () => {},
};

export default FinancesInformation;

/* eslint-disable func-names */
import React, { useCallback } from "react";
import cntl from "cntl";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import SubscriptionPlans from "../../stories/Components/SubscriptionPlans/SubscriptionPlans";
import { SIGNUP_CHECKOUT_PATH } from "../../constants";
import useSubscriptionPlans from "../../hooks/useSubscriptionPlans";
import logo from "../../stories/assets/images/estatespace-logo-dark.svg";
import plansImage from "../../stories/assets/images/plans.jpeg";

const containerCN = cntl`
  flex
  flex-1
  flex-col
  bg-white
  items-center
  p-10
  h-screen
  overflow-auto
`;

const Plans = ({ setSelectedPlan, setIsMonthly, isMonthly }) => {
  const history = useHistory();
  const [plans] = useSubscriptionPlans();

  const onPlanSelect = useCallback(
    (plan) => {
      setSelectedPlan(plan);
      history.push(SIGNUP_CHECKOUT_PATH);
    },
    [history, setSelectedPlan]
  );

  return (
    <>
      <Helmet>
        <script>
          {(function (w, d, s, l, i) {
            // eslint-disable-next-line no-param-reassign
            w[l] = w[l] || [];
            w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            const f = d.getElementsByTagName(s)[0];
            const j = d.createElement(s);
            const dl = l !== "dataLayer" ? `&l=${l}` : "";
            j.async = true;
            j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
            f.parentNode?.insertBefore?.(j, f);
          })(window, document, "script", "dataLayer", "GTM-KDN4NCR")}
        </script>
      </Helmet>
      <div className="flex flex-col">
        <div
          className="container mx-auto py-10 pl-6"
          style={{ maxWidth: "1200px" }}
        >
          <div>
            <a href="https://estatespace.com">
              <img width="220" src={logo} alt="Estate Space Logo" />
            </a>
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url(${plansImage})`,
          }}
          className="py-20 lg:py-40 xl:py-60 text-center bg-cover bg-center"
        >
          <p
            className="text-white text-center text-3xl md:text-5xl"
            style={{ textShadow: "0px 2px 10px rgba(0,0,0,0.6)" }}
          >
            Simple pricing
          </p>
          <a
            className="font-normal mx-auto mt-8 px-5 py-3 inline-block text-white bg-brandPurple text-md justify-center rounded border-white border-2"
            href="https://estatespace.com/contact-estatespace/"
            rel="noreferrer"
          >
            Schedule Demo
          </a>
        </div>
        <div className={containerCN}>
          <div className="flex justify-center">
            <SubscriptionPlans
              plans={plans}
              setIsMonthly={setIsMonthly}
              isMonthly={isMonthly}
              onPlanSelect={onPlanSelect}
            />
          </div>
        </div>
        <Helmet>
          <noscript>
            {`
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-KDN4NCR"
              height="0"
              width="0"
              style=“display:none;visibility:hidden”
            />
            `}
          </noscript>
        </Helmet>
      </div>
    </>
  );
};

Plans.propTypes = {
  setSelectedPlan: PropTypes.func,
  setIsMonthly: PropTypes.func,
  isMonthly: PropTypes.bool,
};

Plans.defaultProps = {
  setSelectedPlan: undefined,
  setIsMonthly: undefined,
  isMonthly: false,
};

export default Plans;

import {
  PropertyAPI,
  ProjectAPI,
  AssetAPI,
} from "@griffingroupglobal/eslib-api";

const associationAPI = {
  Property: PropertyAPI,
  Project: ProjectAPI,
  Asset: AssetAPI,
};

/**
 * Fetch an association by reference
 * @param {String} reference -  The Association reference
 * @returns - The association data
 */
const getAssociationByReference = async (reference) => {
  if (!reference) {
    throw Error("Association reference was not provided");
  }

  const [resource] = reference.split("/");

  const { data } = await associationAPI[resource].getByRef(reference);

  return data;
};

export default getAssociationByReference;

// Framework Tools
import React from "react";
import PropTypes from "prop-types";

// Libraries
import moment from "moment";

// Components
import TertiaryHeader from "../../TextHeaders/TertiaryHeader";

export default function RequestGridViewTargetCompletionCell({
  targetCompletionDate,
}) {
  return (
    <div className="w-full h-ful flex flex-col justify-start flex-wrap px-2">
      <TertiaryHeader className="text-sm text-left">
        Target Completion
      </TertiaryHeader>
      <p className="text-sm text-left">
        {moment(targetCompletionDate).format("MMMM DD, YYYY")}
      </p>
    </div>
  );
}
// prop types
RequestGridViewTargetCompletionCell.propTypes = {
  targetCompletionDate: PropTypes.string,
};

// default props
RequestGridViewTargetCompletionCell.defaultProps = {
  targetCompletionDate: "",
};

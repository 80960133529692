import { useState, useEffect, useMemo } from "react";

function observeSiteHeaderHooks(target) {
  let listeners = []; // initial listeners can be passed an an argument as well
  let value = target;

  function get() {
    return value;
  }

  function set(newValue) {
    if (value === newValue) return;
    value = newValue;
    listeners.forEach((l) => l(value));
  }

  function unsubscribe(listenerFunc) {
    listeners = listeners.filter((l) => l !== listenerFunc);
  }

  function subscribe(listenerFunc) {
    listeners.push(listenerFunc);
    return () => unsubscribe(listenerFunc); // will be used inside React.useEffect
  }

  return {
    get,
    set,
    subscribe,
  };
}

/*
 * Open Edit Table Modal
 */
const editModalStore = observeSiteHeaderHooks(false);

export default function useEditModal() {
  const [openEditModal, setOpenEditModal] = useState(editModalStore.get());

  useEffect(() => {
    return editModalStore.subscribe(setOpenEditModal);
  }, []);

  const actions = useMemo(() => {
    return (bool) => editModalStore.set(bool);
  }, []);

  return [openEditModal, actions];
}

import React, { useState } from "react";
import PropTypes, { objectOf } from "prop-types";
import { useParams } from "react-router";

import MaintenanceScheduleTaskForm from "./MaintenanceScheduleTaskForm";
import useAssetById from "../../../hooks/useAssetById";

import "./styles.css";

const MaintenanceScheduleModal = ({
  association,
  assetData,
  isEditing,
  invitees,
  onFinishedEditing,
  onCloseModal,
  reloadAsset,
  setUpdatedAsset,
  assetMembersOptions,
}) => {
  const { assetId } = useParams();
  const { reload } = useAssetById(assetId);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleRequestModalClose = async () => {
    setIsModalOpen(false);
    if (!isEditing) {
      await reload();
    }
    if (onCloseModal) onCloseModal();
  };

  return (
    <MaintenanceScheduleTaskForm
      association={association}
      isEditing={isEditing}
      onFinishedEditing={onFinishedEditing}
      assetData={assetData || {}}
      invitees={invitees}
      onCloseTaskModal={handleRequestModalClose}
      assetMembersOptions={assetMembersOptions}
      reloadAsset={reloadAsset}
      setUpdatedAsset={setUpdatedAsset}
      isModalOpen={isModalOpen}
    />
  );
};

MaintenanceScheduleModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  assetData: PropTypes.object,
  isEditing: PropTypes.bool,
  invitees: PropTypes.arrayOf(PropTypes.string),
  onFinishedEditing: PropTypes.func,
  onCloseModal: PropTypes.func,
  assetMembersOptions: PropTypes.arrayOf(objectOf),
  reloadAsset: PropTypes.func,
  setUpdatedAsset: PropTypes.func,
  association: PropTypes.shape({}),
};
MaintenanceScheduleModal.defaultProps = {
  assetData: {},
  isEditing: false,
  invitees: [],
  onFinishedEditing: undefined,
  onCloseModal: undefined,
  assetMembersOptions: [],
  reloadAsset: undefined,
  setUpdatedAsset: undefined,
  association: undefined,
};

export default MaintenanceScheduleModal;

import React from "react";

import useTaskRelatedTo from "./useTaskRelatedTo";

const TaskRelatedTo = ({ taskRef }) => {
  const { data, onPress, getIcon, getName } = useTaskRelatedTo({ taskRef });

  const renderItem = ({ item }) => {
    return (
      <button
        key={item?.id}
        type="button"
        className="flex flex-row justify-start w-full items-center"
        onClick={() => onPress(item)}
      >
        <div className="flex flex-row gap-2">
          <img src={getIcon(item)} alt={`Navigate to ${getName(item)}`} />
          <p className="text-es-normal text-es-medium-grey">{getName(item)}</p>
        </div>
      </button>
    );
  };

  if (!taskRef || !data?.length) return null;

  return (
    <div>
      <div className="flex flex-col gap-2">
        {data?.length && data?.map((item) => renderItem({ item }))}
      </div>
    </div>
  );
};

export default TaskRelatedTo;

import React from "react";

const NotificationTimePeriodElement = (value) => {
  return (
    <div className="flex w-full px-5 pt-5">
      <span className="border-b border-gray-400 text-gray-500 w-full pb-4">
        {value}
      </span>
    </div>
  );
};

export default NotificationTimePeriodElement;

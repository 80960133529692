import React from "react";
import PropTypes from "prop-types";
import TableActionsIconsGroup from "../../Table/TableActionsIconsGroup";
import BackButton from "./BackButton";
import Title from "./Title";

const Header = ({
  canEdit,
  canDelete,
  canDownload,
  isEditing,
  title,
  showBackButton,
  onBeginEditing,
  onFinishEditing,
  onCancelEditing,
  onBackPressed,
  onDeletePressed,
  onDownloadPressed,
  onOpenComments,
  ExtraHeaderActions,
  backButtonTitle,
  titleClassName,
  absoluteBtnPosition,
  cancelClassName,
  saveClassName,
  commentClassName,
}) => {
  return (
    <div id="widgetEditor-header" className="flex justify-between">
      <div className="flex flex-row items-center w-full">
        {showBackButton && (
          <BackButton onBackPressed={onBackPressed} title={backButtonTitle} />
        )}

        {title && <Title title={title} titleClassName={titleClassName} />}
      </div>

      <div id="header-actions" className="flex row justify-end gap-x-6">
        {ExtraHeaderActions}

        <TableActionsIconsGroup
          cancelClassName={cancelClassName}
          saveClassName={saveClassName}
          commentClassName={commentClassName}
          canEdit={canEdit}
          canDelete={canDelete}
          canDownload={canDownload}
          isEditing={isEditing}
          style={{ marginBottom: 10 }}
          handleEdit={onBeginEditing}
          handleSave={onFinishEditing}
          handleCancel={onCancelEditing}
          handleShowDelete={onDeletePressed}
          downloadFiles={onDownloadPressed}
          onOpenComments={onOpenComments}
          absoluteBtnPosition={absoluteBtnPosition}
        />
      </div>
    </div>
  );
};

Header.defaultProps = {
  absoluteBtnPosition: undefined,
  canEdit: false,
  canDelete: false,
  canDownload: false,
  isEditing: false,
  showBackButton: false,
  title: undefined,
  ExtraHeaderActions: undefined,
  onBeginEditing: () => {},
  onFinishEditing: () => {},
  onCancelEditing: () => {},
  onBackPressed: () => {},
  onDeletePressed: () => {},
  onDownloadPressed: () => {},
  onOpenComments: undefined,
  backButtonTitle: undefined,
  titleClassName: undefined,
  cancelClassName: undefined,
  saveClassName: undefined,
  commentClassName: undefined,
};

Header.propTypes = {
  absoluteBtnPosition: PropTypes.bool,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  canDownload: PropTypes.bool,
  isEditing: PropTypes.bool,
  showBackButton: PropTypes.bool,
  title: PropTypes.string,
  ExtraHeaderActions: PropTypes.node,
  onBeginEditing: PropTypes.func,
  onFinishEditing: PropTypes.func,
  onCancelEditing: PropTypes.func,
  onBackPressed: PropTypes.func,
  onDeletePressed: PropTypes.func,
  onDownloadPressed: PropTypes.func,
  onOpenComments: PropTypes.func,
  backButtonTitle: PropTypes.string,
  titleClassName: PropTypes.string,
  cancelClassName: PropTypes.string,
  saveClassName: PropTypes.string,
  commentClassName: PropTypes.string,
};

export default Header;

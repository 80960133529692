import { useRef } from "react";

const allOption = {
  id: "all",
  value: "all",
  label: "All",
};

const useDropdownWithCheckboxData = ({
  options = [],
  value = [],
  isMulti = false,
  disableAllOption = false,
  setValue,
}) => {
  const valueRef = useRef(value);
  valueRef.current = value;

  // If `isMulti` is false, return default values for single option
  if (!isMulti) {
    return {
      getOptions: () => options,
      getValue: () => valueRef.current,
      isOptionSelected: () => false,
      handleSetValue: setValue,
    };
  }

  const areAllOptionsSelected = () => {
    return valueRef.current?.length === options?.length;
  };

  const isOptionSelected = (option) => {
    return (
      valueRef.current?.some((current) => current?.value === option?.value) ||
      areAllOptionsSelected()
    );
  };

  // Append "all" option to current options
  const getOptions = () => {
    if (disableAllOption) {
      return options;
    }

    return [allOption, ...options];
  };

  /**
   * Sets the dropdown value based on options selected
   */
  const getValue = () => {
    return areAllOptionsSelected() ? options : value;
  };

  const handleSetValue = (selectedValues, metadata) => {
    const { action, option } = metadata;

    // If "all" is selected, then select all options
    if (action === "select-option" && option.value === allOption.value) {
      setValue(options, metadata);
    }
    // If "all" is unselected, then unselect all options
    else if (
      (action === "deselect-option" || action === "removed-value") &&
      option.value === allOption.value
    ) {
      setValue([], metadata);
    }
    // Deselect any other option different than "all" when all options are selected
    else if (action === "deselect-option" && areAllOptionsSelected()) {
      setValue(
        options.filter((current) => current.value !== option.value),
        metadata
      );
    }
    // set the selected values or an empty array if none
    else {
      setValue(selectedValues || [], metadata);
    }
  };

  return {
    getOptions,
    getValue,
    isOptionSelected,
    handleSetValue,
  };
};

export default useDropdownWithCheckboxData;

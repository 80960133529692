import { useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useModalState } from "../../../state/modalState";
import { ADD_OPEN_MODAL, ALERT_MODAL } from "../../../constants";

export default ({ onClick, onEnter, alert, locked }) => {
  const [onHover, setOnHover] = useState(false);
  const [, modalDispatch] = useModalState();

  const alertUser = useCallback(() => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: uuidv4() },
      modalData: {
        ...alert,
      },
      modalType: ALERT_MODAL,
    });
  }, [alert, modalDispatch]);

  const handleClick = useCallback(() => {
    if (locked) {
      if (alert) alertUser();
      return;
    }
    onClick();
  }, [alert, alertUser, locked, onClick]);

  const handleEnter = useCallback(
    (e) => {
      if (e.key === "Enter") {
        if (locked) {
          if (alert) alertUser();
          return;
        }
        onEnter();
      }
    },
    [alert, alertUser, locked, onEnter]
  );

  return { onHover, setOnHover, handleEnter, handleClick };
};

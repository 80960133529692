import React from "react";
import flagIconRed from "../../assets/images/notiFlagIconRed.svg";
import alertIcon from "../../assets/images/notiAlertIcon.svg";

export default ({ flagged, type }) => {
  return (
    <>
      {flagged && (
        <img src={flagIconRed} alt="flagged-notif" className="col-span-1" />
      )}
      {type === "Threat" && !flagged && (
        <img src={alertIcon} alt="flagged-notif" className="col-span-1" />
      )}
    </>
  );
};

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useMaintenanceTasks from "../../../hooks/useMaintenanceTasks";
import PureMaintenanceTable from "./PureMaintenanceTable";
import MaintenanceScheduleModal from "../AssetForms/MaintenanceScheduleModal";
import TaskView from "../TaskView/TaskView";

const MaintenanceTable = ({
  setOpenNewMaintenanceModal,
  openNewMaintenanceModal,
  createMaintenanceModal,
  setCreateMaintenanceModal,
  hideSiteHeaderTitle,
}) => {
  const [originalTasks, reload] = useMaintenanceTasks();
  const [tasks, setTasks] = useState();
  const [viewMaintenanceModal, setViewMaintenanceModal] = useState(false);
  const [maintenaceTask, setMaintenaceTask] = useState();

  useEffect(() => {
    setTasks(originalTasks);
  }, [originalTasks]);
  useEffect(() => {
    if (openNewMaintenanceModal) {
      setCreateMaintenanceModal(openNewMaintenanceModal);
      setOpenNewMaintenanceModal(false);
    }
  }, [
    openNewMaintenanceModal,
    setOpenNewMaintenanceModal,
    setCreateMaintenanceModal,
  ]);

  const handleRowClick = (rowData) => {
    setViewMaintenanceModal(true);
    setMaintenaceTask(rowData?.original);
  };

  return (
    <>
      <PureMaintenanceTable
        data={tasks}
        setCreateMaintenanceModal={setCreateMaintenanceModal}
        onRowClick={handleRowClick}
        hideSiteHeaderTitle={hideSiteHeaderTitle}
      />
      {createMaintenanceModal && (
        <MaintenanceScheduleModal
          onCloseModal={() => {
            setCreateMaintenanceModal(false);
          }}
          reloadAsset={reload}
        />
      )}

      {viewMaintenanceModal && (
        <TaskView
          reload={reload}
          data={maintenaceTask}
          viewTaskModal={viewMaintenanceModal}
          setViewTaskModal={setViewMaintenanceModal}
          title="Maintenance Task"
        />
      )}
    </>
  );
};

MaintenanceTable.propTypes = {
  setOpenNewMaintenanceModal: PropTypes.func,
  openNewMaintenanceModal: PropTypes.bool,
  setCreateMaintenanceModal: PropTypes.func,
  createMaintenanceModal: PropTypes.bool,
  hideSiteHeaderTitle: PropTypes.bool,
};

MaintenanceTable.defaultProps = {
  setOpenNewMaintenanceModal: undefined,
  openNewMaintenanceModal: false,
  setCreateMaintenanceModal: undefined,
  createMaintenanceModal: false,
  hideSiteHeaderTitle: false,
};

export default MaintenanceTable;

import React, { useMemo } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { formatCurrency } from "../../../../../helpers/Formatters";
import Table from "../../../Table/Table";

import Pill from "../../../Pill/Pill";
import ReceiptPopup from "../../../ExpenseTable/ReceiptPopup";
import Checkbox from "../../../Checkbox/Checkbox";
import ToggleTextButton from "../../../Buttons/ToggleTextButton";
import CommentButton from "../../../CommentButton";
import useDocumentExpensesData from "./useDocumentExpensesData";

const columns = (onToggleIsBillable) => {
  return [
    {
      Header: "Receipt",
      accessor: "receipts",
      minWidth: 60,
      maxWidth: 100,
      Cell: ({ value }) => {
        return <ReceiptPopup value={value} />;
      },
      Aggregated: () => null,
    },
    {
      Header: "CSI Code",
      accessor: "financialCode",
      width: 250,
      Cell: ({ value }) => value || "",
    },
    {
      Header: "Date",
      accessor: "date",
      Cell: ({ value }) =>
        value ? <span>{moment(value).format("LL")}</span> : "",
    },
    {
      Header: "Merchant",
      accessor: "merchant",
    },
    {
      Header: "Amount",
      accessor: "amount",
      width: 150,
      Cell: ({ value }) => formatCurrency(value),
    },
    {
      Header: "Category",
      accessor: "category",
    },
    {
      Header: "Non-Billable",
      accessor: "billable",
      width: 140,
      Cell: ({
        row: {
          original: { billable, reference, mergedInvoice },
        },
      }) => {
        return (
          <Checkbox
            className="col-span-4"
            checked={!billable}
            onChange={() => onToggleIsBillable(reference)}
            inputClassName="calendar-form-tick"
            label=""
            size="6"
            labelClassName="text-md text-gray-450"
            disabled={!!mergedInvoice}
          />
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => {
        return (
          <Pill
            value={value}
            textSize="text-xs capitalize"
            border="border-2 border-primaryGreen"
            background="bg-white"
            textColor="text-primaryGreen"
            padding="py-1"
            className="w-24"
          />
        );
      },
    },
  ];
};

const DocumentExpenses = ({ projectRef, setShowCommentsPopover }) => {
  const {
    isLeftActive,
    setIsLeftActive,
    isRightActive,
    setIsRightActive,
    billableExpenseData,
    nonBillableExpenseData,
    onToggleIsBillable,
  } = useDocumentExpensesData({ projectRef });

  return (
    <div className="flex flex-col">
      <div className="flex justify-between py-2">
        <div className="flex">
          <p className="font-semibold text-2xl">Expenses</p>
        </div>
        <div className="flex ">
          <CommentButton onClick={() => setShowCommentsPopover(true)} />
        </div>
      </div>
      <div className="flex pt-4 pb-2">
        <ToggleTextButton
          setIsLeftActive={setIsLeftActive}
          setIsRightActive={setIsRightActive}
          isLeftActive={isLeftActive}
          isRightActive={isRightActive}
          leftText="Billable"
          rightText="Non Billable"
        />
      </div>
      <div>
        <Table
          className=""
          resourceName="invoice-expenses"
          data={useMemo(() => {
            if (isLeftActive) {
              return billableExpenseData;
            }
            return nonBillableExpenseData;
          }, [billableExpenseData, isLeftActive, nonBillableExpenseData])}
          columns={columns(onToggleIsBillable)}
          cellStyling="flex items-center h-16"
          cellTextStyling="w-full pb-1"
          hideCreateNewButton
          hideEditRowButton
          hideSiteHeader
          hideChangeView
          hideSaveButton
          disableHover
          showFooter
        />
      </div>
    </div>
  );
};

DocumentExpenses.propTypes = {
  projectRef: PropTypes.string,
  setShowCommentsPopover: PropTypes.func,
};

DocumentExpenses.defaultProps = {
  projectRef: undefined,
  setShowCommentsPopover: undefined,
};

export default DocumentExpenses;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import cntl from "cntl";

import useDocuments from "../../../hooks/useDocuments";

import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import SecondaryButton from "../Buttons/SecondaryButton";
import RadioButton from "../Buttons/RadioButton";

import checkmark from "../../assets/images/checkmark.svg";
import fileIcon from "../../assets/images/fileIcon.svg";
import {
  GET_DOCUMENT_EDIT_PATH,
  GET_DOCUMENT_FOLDER_FILE_EDIT_PATH,
  GET_DOCUMENT_FOLDER_FILE_PATH,
  GET_DOCUMENT_PATH,
  GET_PROJECT_DOCUMENTS_DOC_EDIT_PATH,
  GET_PROJECT_DOCUMENTS_DOC_PATH,
} from "../../../constants";

const wrapperCN = (condensed) => cntl`
  pl-12
  pr-8
  ${condensed ? "pb-6" : "py-6"}
`;

const AdditionalDocuments = ({ document, condensedView }) => {
  const { folderName, projectId, propertyId } = useParams();
  const history = useHistory();
  const { documents } = useDocuments();

  const [bundle, setBundle] = useState(false);
  const [draft, setDraft] = useState(false);
  const [hasChange] = useState(true);
  const [hasContingency] = useState(true);
  const [additionalDocuments, setAdditionalDocuments] = useState([]);

  const barHeight = hasChange || hasContingency;
  const barCN = cntl`
    flex
    justify-end
    w-6
    ${barHeight ? "h-64" : "h-28"}
    border-gray-200
  `;

  useEffect(() => {
    if (document.additionalDocuments) {
      setAdditionalDocuments(
        documents.filter((item) =>
          document.additionalDocuments?.includes(item.reference)
        )
      );
    }
  }, [documents, document.additionalDocuments]);

  const handleRadioClick = (button) => {
    if (button === "bundle") {
      setBundle(true);
      setDraft(false);
    } else {
      setDraft(true);
      setBundle(false);
    }
  };

  const handleView = (id, docType) => {
    if (folderName) {
      history.push(GET_DOCUMENT_FOLDER_FILE_PATH(docType, id));
    } else if (projectId) {
      history.push(GET_PROJECT_DOCUMENTS_DOC_PATH(projectId, id));
    } else if (propertyId) {
      history.push(GET_DOCUMENT_PATH(id));
    } else {
      history.push(GET_DOCUMENT_PATH(id));
    }
  };

  const handleEdit = (id, docType) => {
    if (folderName) {
      history.push(GET_DOCUMENT_FOLDER_FILE_EDIT_PATH(docType, id));
    } else if (projectId) {
      history.push(GET_PROJECT_DOCUMENTS_DOC_EDIT_PATH(projectId, id));
    } else if (propertyId) {
      history.push(GET_DOCUMENT_EDIT_PATH(id));
    } else {
      history.push(GET_DOCUMENT_EDIT_PATH(id));
    }
  };

  return (
    <div className={wrapperCN(condensedView)}>
      {!condensedView && (
        <>
          <div className="flex flex-col">
            <p>
              Additional documents are required to complete this Purchase
              Authorization
            </p>

            <div className="flex items-center mt-3">
              <RadioButton
                label="Bundle additional documents"
                value="bundle"
                isChecked={bundle}
                onChange={() => handleRadioClick("bundle")}
              />
            </div>
            <div className="flex items-center mt-1">
              <RadioButton
                label="Save additional documents to Draft"
                value="draft"
                isChecked={draft}
                onChange={() => handleRadioClick("draft")}
              />
            </div>
          </div>
        </>
      )}

      {additionalDocuments.map((doc) => {
        return (
          <div className="mt-10" key={doc.id}>
            <div
              className="relative w-0 h-0"
              style={{ right: "31px", top: "72px" }}
            >
              {bundle && (
                <div className={`border-t-2 border-l-2 rounded-tl-md ${barCN}`}>
                  <div
                    className="relative w-3 h-3 rounded-full bg-gray-200"
                    style={{ right: "-7px", top: "-6px" }}
                  />
                </div>
              )}
            </div>
            <div className="flex flex-col w-full h-36 p-4 border-2 border-gray-200 rounded-md">
              <div className="flex justify-between">
                <div className="flex items-center">
                  <div className="flex justify-center items-center w-6 h-6 mr-5 rounded-full bg-brandGreen">
                    <img
                      className="w-5 h-5"
                      src={checkmark}
                      alt="task complete"
                    />
                  </div>
                  <SecondaryHeader>
                    {doc.customName || doc.name}
                  </SecondaryHeader>
                </div>
                <div className="flex items-center">
                  {doc.metadata?.createdAt && (
                    <>
                      <p className="text-sm text-gray-200 mr-4">
                        Created{" "}
                        {moment(doc.metadata.createdAt).format("MMMM D, YYYY")}
                      </p>
                      <img
                        className="w-4 h-4"
                        src={fileIcon}
                        alt="task complete"
                      />
                    </>
                  )}
                </div>
              </div>
              {doc.description && (
                <p className="ml-11 mt-4 text-sm">{doc.description}</p>
              )}
              {!doc.description && (
                <p className="ml-11 mt-4 text-sm">
                  Rate sheet is used to accurately compute the cost of goods and
                  services when creating a budget.
                </p>
              )}
              <div className="flex justify-end">
                {doc.status !== "open" &&
                  doc.status !== "requestResubmission" && (
                    <SecondaryButton
                      title="View"
                      onClick={() => handleView(doc.id, doc.docType)}
                    />
                  )}
                {(doc.status === "open" ||
                  doc.status === "requestResubmission") && (
                  <SecondaryButton
                    title="Edit"
                    onClick={() => handleEdit(doc.id, doc.docType)}
                  />
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

AdditionalDocuments.propTypes = {
  /**
   * create document form state
   */
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.object,
  /**
   * show condensed view
   */
  condensedView: PropTypes.bool,
};

AdditionalDocuments.defaultProps = {
  document: {},
  condensedView: false,
};

export default AdditionalDocuments;

import React from "react";
import * as yup from "yup";
import ModalWrapper from "../EsModal/ModalWrapper";
import PrimaryButton from "../Buttons/PrimaryButton";
import IncludeInformation from "../ReportsCreateModal/IncludeInformation";
import Widget from "../Widget/Widget";
import GridDetailRow from "../GridDetailRow";
import Input from "../Input/Input";
import TertiaryButton from "../Buttons/TertiaryButton";
import useReportTemplateModalData from "./useReportManageTemplateModalData";

const ReportsManageTemplateModal = ({ modalData }) => {
  const {
    modalTitle,
    template,
    templateForm,
    includeInformationData,
    assetsCategoriesData,
    associationType,
    disableForm,
    setAssetsCategoriesData,
    setIncludeInformationData,
    handleChangeForm,
    handleUpdateTemplate,
    handleDeleteButton,
  } = useReportTemplateModalData({ modalData });

  return (
    <ModalWrapper
      width="66rem"
      modalData={modalData}
      title={modalTitle}
      resourceName={`Editing ${template.title}`}
      showConfirm
      disabled={disableForm}
      buttons={
        <div className="flex ml-4 gap-x-4">
          <PrimaryButton
            saveButton
            disabled={disableForm}
            onClick={handleUpdateTemplate}
          />
          <TertiaryButton trashCan onClick={handleDeleteButton} />
        </div>
      }
    >
      <Widget draggable={false} title={null} overflow className="min-w-4xl">
        <div className="grid grid-cols-2 gap-4 -mt-4">
          <GridDetailRow
            label="Template Name"
            className="items-center gap-x-2"
            isEditing
            editableComponent={
              <div className="w-72">
                <Input
                  placeholder="Title"
                  onChange={(value) => handleChangeForm("title", value)}
                  value={templateForm.title}
                  inputClassName="w-full bg-green-50 pl-4 pr-8 text-sm"
                  inputContainerClassName="bg-green-50"
                  validation={yup.string().required()}
                />
              </div>
            }
          />
        </div>

        <IncludeInformation
          associationType={associationType}
          includeInformationData={includeInformationData}
          assetsCategoriesData={assetsCategoriesData}
          setIncludeInformationData={setIncludeInformationData}
          setAssetsCategoriesData={setAssetsCategoriesData}
        />
      </Widget>
    </ModalWrapper>
  );
};

export default ReportsManageTemplateModal;

import { useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";
import { useSop } from "../../../../hooks/useSop";
import { REMOVE_MODAL_INSTANCE, SOP } from "../../../../constants";
import { useAppState } from "../../../../state/appState";
import { useModalState } from "../../../../state/modalState";

const WEBSITE = "Website";

const useAddPreferenceModal = ({ modalData }) => {
  const { data: unformattedSOPData, isLoading } = useSop({
    selectOptions: true,
  });

  // Format SOPData for DDL
  const sopData = useMemo(() => {
    return unformattedSOPData?.map((item) => ({
      label: item.name,
      value: item.reference,
    }));
  }, [unformattedSOPData]);

  // Info Passed From ContactPreferences || PreferencesLibrarySection(isAdminPage = true)
  const isAdminPage = modalData?.item?.isAdminPage;
  const preferenceSubCat = modalData?.item?.title; // ie "Food" "Beverages"
  const preferenceType = modalData?.item?.preferenceType; // LIKE || DISLIKE || SPECIAL
  const userPreferences = modalData?.item?.preferences; // LIKE || DISLIKE || SPECIAL
  const adminPrefs = modalData?.item?.adminPreferences;
  const title = `Add ${preferenceSubCat}`;
  const handleSubmit = modalData?.item?.handleSubmit;

  // handling of changing Preferences for management.system.preferences && contact preferences
  const [preferences, setPreferences] = useState(cloneDeep(userPreferences));
  const [adminPreferences, setAdminPreferences] = useState(
    cloneDeep(adminPrefs)
  );

  // SOPs for dropdown
  const [{ sopsDict }] = useAppState();

  // control closing modal
  const [, modalDispatch] = useModalState();

  // Form State
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [sop, setSop] = useState("");
  const [linkType, setLinkType] = useState(null);
  const [value, setValue] = useState();

  const changeName = (val) => {
    setName(val);
  };

  const changeLink = (val) => {
    setLink(val);
  };

  const changeSOP = (val) => {
    setSop(val);
  };

  // Controls add button for adding a new preference (only name required)
  const isAddDisabled = useMemo(() => {
    return name?.length < 2;
  }, [name?.length]);

  // List of preference tags
  // Main list of all for this subcategory ("Food", "Beverage") is from settings.preferences
  // Determine if user has the preference currently in use or is selects it in modal
  const optionsList = useMemo(() => {
    // Returns array of preference ids ["uuidv4"]
    const usersPreferenceList = preferences?.[preferenceType]?.[
      preferenceSubCat
    ]?.values?.map((item) => item?.value);

    // check admin subcat list of prefs against the users active / selected preferences
    // Add label for use with DDL, not /needed/ now could be in future
    const list = adminPreferences?.[preferenceSubCat]?.values?.map((item) => {
      return {
        ...item,
        label: item?.value,
        isSelected: !!usersPreferenceList?.includes(item?.value),
      };
    });

    return list;
  }, [adminPreferences, preferenceSubCat, preferenceType, preferences]);

  const selectLinkType = (type, val) => {
    const isSop = type === SOP && val;
    const isWebsite = type === WEBSITE && val;
    if (isSop && val) {
      setLink("");
      setLinkType(SOP);
    }
    if (isWebsite && val) {
      setSop("");
      setLinkType(WEBSITE);
    }
    if (!val) {
      setLinkType(null);
    }
  };

  const handleRequestModalClose = () => {
    modalDispatch({
      type: REMOVE_MODAL_INSTANCE,
      id: modalData.id,
    });
  };

  const handleAddNew = () => {
    const formatLink = linkType === SOP ? sop : link;
    const newPreference = {
      value: name,
      link: formatLink ?? "",
      linkType: linkType ?? "",
      custom: true,
      id: uuidv4(),
    };

    const subCatNew = {};
    subCatNew[preferenceSubCat] = { values: [{ ...newPreference }] };
    const isCategoryNotNew =
      preferences?.[preferenceType]?.[preferenceSubCat]?.values?.length;

    const categoryUser = isCategoryNotNew
      ? {
          values: [
            ...preferences?.[preferenceType]?.[preferenceSubCat]?.values,
            { ...newPreference },
          ],
        }
      : { values: [{ ...newPreference }] };

    setPreferences((prev) => ({
      ...prev,
      // like || dislike || special
      [preferenceType]: {
        ...prev?.[preferenceType],
        // title of category
        [preferenceSubCat]: {
          ...categoryUser,
        },
      },
    }));

    // add new preference to admin list in both use cases
    // option list is based on admin list
    // this will not cause a patch to admin list, adding new prefs from user -> admin is BE
    const category = adminPreferences?.[preferenceSubCat]?.values?.length
      ? {
          [preferenceSubCat]: {
            values: [
              ...adminPreferences?.[preferenceSubCat]?.values,
              { ...newPreference },
            ],
          },
        }
      : subCatNew;

    setAdminPreferences((prev) => ({
      ...prev,
      ...category,
    }));

    setName("");
    setLink("");
    setSop("");
    setLinkType(null);
  };

  const handleOnSubmit = () => {
    handleSubmit(isAdminPage ? adminPreferences : preferences);
    handleRequestModalClose();
  };

  const selectPreference = (selectedPreference) => {
    let updatedUserPrefs = { ...preferences };

    // if new subcategory
    if (!preferences?.[preferenceType]?.[preferenceSubCat] && !isAdminPage) {
      updatedUserPrefs = {
        ...updatedUserPrefs,
        [preferenceType]: {
          ...updatedUserPrefs?.[preferenceType],
          [preferenceSubCat]: {
            values: [],
          },
        },
      };
    }

    if (isAdminPage) {
      const valuesList = [...adminPreferences?.[preferenceSubCat]?.values];
      const filteredList = valuesList?.filter(
        (item) => item?.id !== selectedPreference.id
      );
      setAdminPreferences((prev) => ({
        ...prev,
        [preferenceSubCat]: {
          ...prev?.[preferenceSubCat],
          values: filteredList,
        },
      }));
    }

    if (!isAdminPage) {
      let valuesList = [];
      const isSelectedPreferenceCurrent = !!updatedUserPrefs?.[
        preferenceType
      ]?.[preferenceSubCat]?.values?.find(
        (item) => item.id === selectedPreference.id
      );

      if (!isSelectedPreferenceCurrent) {
        valuesList = [
          ...updatedUserPrefs?.[preferenceType]?.[preferenceSubCat]?.values,
        ];

        valuesList.push({
          ...selectedPreference,
        });
      }
      if (isSelectedPreferenceCurrent) {
        valuesList = [
          ...updatedUserPrefs?.[preferenceType]?.[
            preferenceSubCat
          ]?.values?.filter((item) => item.id !== selectedPreference.id),
        ];
      }

      setPreferences((prev) => ({
        ...prev,
        // like || dislike || special
        [preferenceType]: {
          ...updatedUserPrefs?.[preferenceType],
          // title of category
          [preferenceSubCat]: {
            // use updated user Prefs incase of new category
            ...updatedUserPrefs?.[preferenceType]?.[preferenceSubCat],
            values: valuesList,
          },
        },
      }));
    }
  };

  return {
    isLoading,
    changeName,
    changeLink,
    changeSOP,
    name,
    link,
    sop,
    sopData,
    handleOnSubmit,
    isAddDisabled,
    handleRequestModalClose,
    title,
    linkType,
    selectLinkType,
    sopsDict,
    preferences,
    optionsList,
    setPreferences,
    isAdminPage,
    selectPreference,
    value,
    setValue,
    handleAddNew,
  };
};

export default useAddPreferenceModal;

import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";
import moment from "moment";

const containerCN = (className) => cntl`
  ${className}
`;

const labelCN = (className) => cntl`
    flex
    ${className}
`;

const DatePicker = ({
  value,
  onChange,
  label,
  className,
  labelClassName,
  validation,
  placeholder,
  iconLeft,
  minDate,
  maxDate,
  dateFormat,
  forwardedRef,
  name,
  disabled,
  nonEditable,
  manualError,
  showValidationErrorAtBottom,
  inModal,
  handleEnter,
  popperPlacement,
  allowClear,
}) => {
  const [errorMessage, setErrorMessage] = useState();
  const [wasFocused, setWasFocused] = useState(false);

  useEffect(() => {
    const validate = async () => {
      if (validation) {
        try {
          await validation.validate(value);
          // validation passed
          setErrorMessage(undefined);
        } catch (err) {
          setErrorMessage(err.message);
        }
      }
    };

    validate();
  }, [validation, value]);

  const handleChange = (val) => {
    if (moment(val).isValid()) {
      onChange(val);
    } else if (allowClear) {
      onChange(undefined);
    }
  };

  const handleBlur = () => {
    if (!wasFocused) {
      setWasFocused(true);
    }
  };

  // fixed a plus one issue when sending 2024-02-29 and getting 2024-03-01
  const timezone = moment().format("Z");
  const isNegativeOffset = timezone.includes("-");

  // if no value is passed, set the start date to an empty string for reactCalendar to work
  let startDate = value || "";

  // reactCalendar has offset bug when using negative offset, below hack was to get around that
  // no solutions here worked:
  // https://github.com/Hacker0x01/react-datepicker/issues/1018#issuecomment-485648061
  if (value && isNegativeOffset) {
    startDate = new Date(moment(value).add(1, "days").format().split("T")[0]);
  }

  if (value && !isNegativeOffset) {
    startDate = new Date(moment(value).format().split("T")[0]);
  }

  return (
    <div className={containerCN(className)}>
      {(label || errorMessage) && (
        <div className={labelClassName || "ESInputLabel"}>
          {label && (
            <div className={labelCN(labelClassName)}>
              <p className={labelClassName}>{label}</p>
              {validation?.exclusiveTests?.required && (
                <p className="text-brandGreen text-sm">*</p>
              )}
            </div>
          )}
          {!!errorMessage && wasFocused && !showValidationErrorAtBottom && (
            <p className="text-brandRed italic ml-2">{errorMessage}</p>
          )}
        </div>
      )}
      <div
        className={`${
          inModal ? "ESDateInputContainer" : "ESInputContainer"
        } w-full flex ${disabled ? "bg-gray-100 opacity-50" : "bg-gray-input"}`}
      >
        {iconLeft && (
          <span
            className={`flex items-center relative -mr-2 bg-transparent ${
              disabled ? "z-10" : "z-0"
            }`}
          >
            {iconLeft}
          </span>
        )}
        <ReactDatePicker
          onBlur={handleBlur}
          placeholderText={placeholder}
          wrapperClassName="w-full"
          className={`${
            inModal ? "ESDateInput" : "ESInput"
          } w-full h-full bg-transparent ${manualError && "border-brandRed"}`}
          selected={startDate}
          value={startDate}
          onChange={(val) => handleChange(val)}
          minDate={minDate}
          maxDate={maxDate}
          dateFormat={dateFormat}
          ref={forwardedRef}
          name={name}
          disabled={disabled || nonEditable}
          onCalendarClose={handleBlur}
          onSelect={(date) => handleChange(date)}
          handleEnter={handleEnter}
          popperPlacement={popperPlacement}
        />
      </div>
      {!!errorMessage && wasFocused && showValidationErrorAtBottom && (
        <p className="absolute text-xs text-brandRed bottom-0 pr-11">
          {errorMessage}
        </p>
      )}
    </div>
  );
};

DatePicker.propTypes = {
  /**
   * the current value
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * the function called when the date on the picker is changed
   */
  onChange: PropTypes.func,
  /**
   * text to apply above the cell
   */
  label: PropTypes.string,
  /**
   * classes to apply to the container
   */
  className: PropTypes.string,
  /**
   * placeholder text to display
   */
  placeholder: PropTypes.string,
  /**
   * validation schema using yup
   */
  // eslint-disable-next-line react/forbid-prop-types
  validation: PropTypes.object,
  /**
   * JSX to display on the left side of the button
   */
  iconLeft: PropTypes.element,
  /**
   * sets the first selectable day on the datepicker
   */
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * sets the last selectable day on the datepicker
   */
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * date format to display
   */
  dateFormat: PropTypes.string,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  handleEnter: PropTypes.func,
  name: PropTypes.string,
  /**
   * Disable Input
   */
  disabled: PropTypes.bool,
  /**
   * Disabled Input Without Edit/Gray Background
   */
  nonEditable: PropTypes.bool,
  /**
   * Label Tailwind Classes
   */
  labelClassName: PropTypes.string,
  /**
   * Error Border
   */
  manualError: PropTypes.bool,
  showValidationErrorAtBottom: PropTypes.bool,
  /**
   * Flag For smaller input container(Preferably in modal)
   */
  inModal: PropTypes.bool,
  /**
   * popperPlacement: Controls placement of popup ex: 'auto', 'auto-left', 'auto-right', 'bottom', 'bottom-end', 'bottom-start',
   * 'left', 'left-end', 'left-start', 'right', 'right-end', 'right-start', 'top', 'top-end', 'top-start'
   * Defaults to: "" to not affect normal locations
   */
  popperPlacement: PropTypes.string,
  /**
   * Flag For allowing clearing date value and let it be undefined
   */
  allowClear: PropTypes.bool,
};

DatePicker.defaultProps = {
  value: undefined,
  onChange: undefined,
  label: undefined,
  className: undefined,
  validation: undefined,
  placeholder: "Select a date...",
  iconLeft: undefined,
  minDate: undefined,
  maxDate: undefined,
  dateFormat: undefined,
  forwardedRef: undefined,
  handleEnter: undefined,
  name: undefined,
  disabled: false,
  nonEditable: false,
  labelClassName: false,
  manualError: false,
  showValidationErrorAtBottom: false,
  inModal: false,
  popperPlacement: "",
  allowClear: false,
};

export default DatePicker;

import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";

import ActionButton from "../Buttons/ActionButton";

const actionListCN = (className) => cntl`
  flex
  justify-center
  ${className}
`;

const DocumentActions = ({ className, actionsList }) => {
  return (
    <div className={actionListCN(className)}>
      {actionsList.map((action) => (
        <div className="pl-3 pr-3" key={action?.text}>
          <ActionButton
            icon={action?.icon}
            text={action?.text}
            onClick={action?.onClick}
          />
        </div>
      ))}
    </div>
  );
};

DocumentActions.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  actionsList: PropTypes.arrayOf(PropTypes.object),
};

DocumentActions.defaultProps = {
  className: false,
  actionsList: [],
};

export default DocumentActions;

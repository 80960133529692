/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-extra-boolean-cast */
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import {
  CALENDAR_MONTH_BORDER_OFFSET,
  CALENDAR_MONTH_EVENT_DATE_OFFSET,
  CALENDAR_MONTH_EVENT_HEIGHT,
  CALENDAR_MONTH_TEXT_VERTICAL_CENTER,
  REMOVE_POSITIONED_MODALS,
  TOGGLE_POSITIONED_POPUP,
  VIEW_MORE_POPUP,
} from "../../../../constants";
import useMouseTracker from "../../../../hooks/useMouseTracker";
import useRelativeAssociations from "../../../../hooks/useRelativeAssociations";
import { useAppState } from "../../../../state/appState";
import { useModalState } from "../../../../state/modalState";
import EventCell from "./EventCell";
import ViewMoreCell from "./ViewMoreCell";

const MonthViewEventMap = ({ weeks, events, onClick }) => {
  const [{ calendarTimezone }, dispatch] = useAppState();
  const [, modalDispatch] = useModalState();
  const { eventFilter } = useRelativeAssociations();

  function placeTextWithEllipsis(textString, span = 1) {
    const maxLength = span * 14;
    if (textString?.length > maxLength) {
      return `${textString.substring(0, maxLength)}`;
    }
    return textString;
  }

  /**
   * Map that stores information on calendar Cells
   * @description - {place, skip}
   * - place | How many events are mapped so far
   * - skip | Which lines to skip in cell when mapping | Edge Case: Two overlapping events that span multiple days
   */
  const weekMap = new Map();

  /**
   * Two-Dimensional Loop
   * 1st Dimension: Weeks [0 - (Weeks in month - 1)]
   * 2nd Dimension: Days [0 - 6]
   */

  const { mousePosition } = useMouseTracker();

  const openPopupHere = (calendarDayEvents) => {
    dispatch({
      type: TOGGLE_POSITIONED_POPUP,
      position: {
        x: mousePosition.x,
        y: mousePosition.y,
      },
      popupData: { calendarDayEvents, onClick, popupWidth: 239 },
      popupType: VIEW_MORE_POPUP,
    });
    /**
     * When opening popup remove all modals that are positioned on page(Non-Tabbed ES-Modals)
     */
    modalDispatch({
      type: REMOVE_POSITIONED_MODALS,
    });
  };

  return (
    <g id="calendar-events" transform="translate(0,10)">
      {Array.from({ length: weeks.length }, (_, i) => {
        return (
          <g id={`week-${i}-row`} key={`week-${i}-row`}>
            {Array.from({ length: 7 }, (__, d) => {
              const { allDay, brief } = eventFilter(
                events?.[weeks[i][d].iso]
              ) ?? {
                allDay: [],
                brief: [],
              };

              /**
               * @mixes - allDay & brief event arrays for the day
               */
              const collapsed = [...allDay, ...brief];
              return (
                <g key={`event-cell-${weeks[i][d].iso}-${i}-${d}`}>
                  {collapsed.map((item, j) => {
                    // Check if Day spans multiple cells
                    const { daySpan = 1 } = item ?? { daySpan: 1 };

                    // Base Key Map
                    const baseKey = moment
                      .tz(weeks[i][d].iso, calendarTimezone)
                      .format();

                    /**
                     * @summary - If event spans multiple days traverse weekMap span-1x's and update open cell spaces
                     */
                    if (daySpan > 1) {
                      let originalPlace;
                      for (let v = 0; daySpan > v; v += 1) {
                        const mapKey = moment
                          .tz(weeks[i][d].iso, calendarTimezone)
                          .clone()
                          .add("days", v)
                          .format();

                        // Check if exists in week map
                        const mapCheck = weekMap.get(mapKey);

                        /**
                         * Update Week Map
                         * @summary sets array of open spaces in calendar cells
                         */

                        weekMap.set(
                          `${mapKey}`,
                          mapCheck
                            ? {
                                open: !originalPlace
                                  ? mapCheck?.open.slice(1)
                                  : mapCheck?.open.filter(
                                      // eslint-disable-next-line no-loop-func
                                      (s) => s !== originalPlace
                                    ),
                              }
                            : {
                                open: originalPlace
                                  ? [1, 2, 3, 4, 5].filter(
                                      // eslint-disable-next-line no-loop-func
                                      (s) => s !== originalPlace
                                    )
                                  : [2, 3, 4, 5],
                              }
                        );

                        /**
                         * @edge - Event > 1 days
                         * - Event is not first in starting cell
                         * - Following events on succeeding cells should skip this position
                         */
                        if (v === 0 && mapCheck?.open)
                          /**
                           * @edge - Event is collapsed in view more container it should not take space
                           * - in succeeding cells so we mark its original place 'view more'
                           */
                          originalPlace = mapCheck?.open[1] ?? "view More";
                      }
                    } else {
                      const mapCheck = weekMap.get(baseKey);

                      weekMap.set(
                        `${baseKey}`,
                        mapCheck
                          ? { open: mapCheck.open.slice(1) }
                          : { open: [2, 3, 4, 5] }
                      );
                    }

                    const { open } = weekMap.get(weeks[i][d].iso) ?? {
                      open: [1, 2, 3, 4, 5],
                    };

                    /**
                     * Gets place of the cell
                     * @returns either first open cell or 4(for view more button)
                     */
                    const place = !!open?.length ? open[0] - 1 : 5;

                    return (
                      <g
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${item?.id}-${d}-event-cell-${weeks[i][d].iso}-${i}-${j}`}
                      >
                        {place === 4 && (
                          <ViewMoreCell
                            onClick={() => openPopupHere(collapsed)}
                            events={collapsed}
                            spill={item}
                            column={d}
                            row={i}
                          />
                        )}
                        {place < 4 && (
                          <EventCell
                            item={item}
                            d={d}
                            i={i}
                            place={place}
                            daySpan={daySpan}
                            placeTextWithEllipsis={placeTextWithEllipsis}
                            CALENDAR_MONTH_TEXT_VERTICAL_CENTER={
                              CALENDAR_MONTH_TEXT_VERTICAL_CENTER
                            }
                            CALENDAR_MONTH_EVENT_DATE_OFFSET={
                              CALENDAR_MONTH_EVENT_DATE_OFFSET
                            }
                            CALENDAR_MONTH_BORDER_OFFSET={
                              CALENDAR_MONTH_BORDER_OFFSET
                            }
                            CALENDAR_MONTH_EVENT_HEIGHT={
                              CALENDAR_MONTH_EVENT_HEIGHT
                            }
                            calendarTimezone={calendarTimezone}
                          />
                        )}
                      </g>
                    );
                  })}
                </g>
              );
            })}
          </g>
        );
      })}
    </g>
  );
};

MonthViewEventMap.propTypes = {
  weeks: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        day: PropTypes.number,
        next: PropTypes.bool,
        prev: PropTypes.bool,
        iso: PropTypes.string,
      })
    )
  ),
  events: PropTypes.shape({}),
  onClick: PropTypes.func,
};

MonthViewEventMap.defaultProps = {
  weeks: [],
  events: undefined,
  onClick: () => {},
};

export default MonthViewEventMap;

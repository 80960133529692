import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

const chevronCN = ({ className }) => cntl`
  flex-initial
  ${className}
  fill-current
`;
/**
 * @summary
 * Chevron Icon:
 * Centered within the bounds of a square
 * Must provide height, width,
 * & in some cases self-center to:
 * @param {string} className - tailwind styling
 * @param {string} color - Color of Chevron default: white
 * @param {string} strokeWidth - width of stroke around path
 * @returns SVG Chevron
 */
const Chevron = ({ className, color, strokeWidth, style }) => {
  return (
    <>
      <svg
        className={chevronCN({ className })}
        viewBox="0 0 16 16"
        preserveAspectRatio="xMidYMid meet"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <g
          id="Icons-and-Colors"
          stroke="yellow"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g id="Icon/Disclosure---Open">
            <g>
              <g
                id="arrow-down"
                stroke={color ?? "white"}
                strokeWidth={strokeWidth ?? "2px"}
              >
                <polyline id="Path" points="4 16 12 8 4 0" />
              </g>
              <rect
                id="Bounds"
                fill="red"
                opacity="0"
                x="0"
                y="0"
                width="16"
                height="16"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

Chevron.propTypes = {
  /**
   * classes to apply to the chevron container
   */
  className: PropTypes.string,
  /**
   * color of the chevron
   */
  color: PropTypes.string,
  /**
   * strokeWidth of the chevron
   */
  strokeWidth: PropTypes.string,
  style: PropTypes.shape({}),
};

Chevron.defaultProps = {
  className: "",
  color: "white",
  strokeWidth: "2",
  style: {},
};

export default Chevron;

import { UsertenantAPI } from "@griffingroupglobal/eslib-api";
import { useQuery } from "react-query";
import { tenantKeys } from "../config/reactQuery/queryKeyFactory";
import { TENANT_ID_STORAGE_KEY } from "../constants";

const getTenants = async (email) => {
  const tenantId = localStorage.getItem(TENANT_ID_STORAGE_KEY);
  const { data } = await UsertenantAPI.get({
    params: { email },
  });
  const tenant = data?.tenants?.filter(
    (item) => item["x-es-tenantid"] === tenantId
  );
  return tenant[0];
};
/**
 * Query hook for current users active tenant
 * @returns current tenant details { }
 */
const useGetCurrentTenant = (email) => {
  return useQuery({
    enabled: !!email,
    queryKey: tenantKeys.currentTenant,
    queryFn: () => getTenants(email),
    onError: (error) => {
      console.error("Error fetching User tenants", error);
    },
  });
};

export default useGetCurrentTenant;

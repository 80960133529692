import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import lockIcon from "../../assets/images/lockIcon.svg";
import IconButton from "../Buttons/IconButton";
import BaseButton from "../Buttons/BaseButton";
import crossIcon from "../../assets/images/Close.svg";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";

const isLockedByNonCurrentUser = (reference, currentUserReference) => {
  return reference && reference !== "" && reference !== currentUserReference;
};

const BudgetTableLockedCell = ({ row, currentUserReference }) => {
  const [showPopup, setShowPopup] = useState(false);
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, () => setShowPopup(false));

  return (
    <div className="flex flex-row items-center" ref={wrapperRef}>
      <IconButton
        onClick={() => {
          if (row?.original?.lock?.lockedBy?.reference) {
            setShowPopup((prev) => !prev);
          }
        }}
        icon={
          isLockedByNonCurrentUser(
            row?.original?.lock?.lockedBy?.reference,
            currentUserReference
          ) && lockIcon
        }
      />
      {showPopup && (
        <div
          className="flex flex-col rounded border border-gray-150 z-20 bg-white absolute p-2 w-72 top-8"
          onClick={(e) => {
            e.stopPropagation();
          }}
          tabIndex={0}
          onKeyPress={() => {}}
          role="button"
        >
          <div className="flex justify-between">
            <div className="flex pb-2">
              <p className="font-semibold text-sm">Lock Information</p>
            </div>
            <BaseButton
              onClick={() => {
                setShowPopup(false);
              }}
              iconLeft={
                <img
                  src={crossIcon}
                  alt="crossIcon"
                  style={{ height: 8, width: 10 }}
                />
              }
            />
          </div>
          <p className="break-words whitespace-normal text-sm pr-2">
            Locked{" "}
            {row?.original?.lock?.lockedBy?.display ? (
              <>
                by
                <span className="font-semibold text-brandGreen">
                  {" "}
                  {row?.original?.lock?.lockedBy?.display}{" "}
                </span>
                on
              </>
            ) : (
              "on"
            )}{" "}
            {moment(row?.original?.lock?.lockedBy?.date).format(
              "dddd, MMMM Do YYYY, h:mm:ss a"
            )}
          </p>
        </div>
      )}
    </div>
  );
};

BudgetTableLockedCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      lock: PropTypes.shape({
        lockedBy: PropTypes.shape({
          date: PropTypes.string,
          display: PropTypes.string,
          reference: PropTypes.string,
        }),
      }),
    }),
  }),
  currentUserReference: PropTypes.string,
};

BudgetTableLockedCell.defaultProps = {
  row: undefined,
  currentUserReference: undefined,
};

export default BudgetTableLockedCell;

import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

import Modal from "../Modal/Modal";
import TertiaryHeader from "../TextHeaders/TertiaryHeader";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";

const ErrorModalCN = cntl`
  flex
  flex-col
  items-center
`;

const innerCN = cntl`
  flex
  flex-col
  items-center
  w-2/3
`;

const ErrorModal = ({
  title,
  isErrorModalOpen,
  setIsErrorModalOpen,
  overlayStyle,
  message,
  primaryButtonOnClick,
  primaryButtonTitle,
  tertiaryButtonTitle,
  hideSecondaryHeader,
}) => {
  return (
    <Modal
      overlayStyle={overlayStyle}
      title={title}
      isOpen={isErrorModalOpen}
      onRequestModalClose={() => setIsErrorModalOpen(false)}
      hideFooter
      primaryButtonOnClick={primaryButtonOnClick}
      primaryButtonTitle={primaryButtonTitle}
      tertiaryButtonTitle={tertiaryButtonTitle}
      crossIconGreen
      alert
    >
      <div className={ErrorModalCN}>
        <div className={innerCN}>
          {!hideSecondaryHeader && (
            <SecondaryHeader>There was an error</SecondaryHeader>
          )}
          <div className="py-5 text-center">
            <TertiaryHeader>{message}</TertiaryHeader>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ErrorModal.propTypes = {
  isErrorModalOpen: PropTypes.bool,
  setIsErrorModalOpen: PropTypes.func,
  title: PropTypes.string,
  overlayStyle: PropTypes.shape({}),
  message: PropTypes.string,
  primaryButtonOnClick: PropTypes.func,
  primaryButtonTitle: PropTypes.string,
  tertiaryButtonTitle: PropTypes.string,
  hideSecondaryHeader: PropTypes.bool,
};

ErrorModal.defaultProps = {
  isErrorModalOpen: false,
  setIsErrorModalOpen: undefined,
  title: undefined,
  overlayStyle: undefined,
  message: undefined,
  primaryButtonOnClick: undefined,
  primaryButtonTitle: undefined,
  tertiaryButtonTitle: undefined,
  hideSecondaryHeader: false,
};

export default ErrorModal;

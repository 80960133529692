import React from "react";

const HideElement = ({ toggle, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      height="100%"
      preserveAspectRatio="xMidYMid meet"
    >
      <g id="Hide">
        <line
          id="bottom-line"
          x1="4.83"
          y1={toggle ? "3.58" : "56.42"}
          x2="55.17"
          y2={toggle ? "3.58" : "56.42"}
          strokeWidth="7"
          stroke={color ?? "#fff"}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
    </svg>
  );
};

export default HideElement;

import { AssetAPI, ProjectAPI } from "@griffingroupglobal/eslib-api";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { resolvePrimaryImage } from "../../../helpers/File";
import { getAssetOptions } from "../../../helpers/SiteHeaderOptions";
import useAssetFormReducer from "../../../hooks/useAssetFormReducer";
import useAssets from "../../../hooks/useAssets";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import useProject from "../../../hooks/useProject";
import usePropertyById from "../../../hooks/usePropertyById";
import useUsers from "../../../hooks/useUsers";
import AttributeAddInformation from "../../../stories/Components/Attributes/AttributeAddInformation";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import DetailViewBanner from "../../../stories/Components/DetailViewBanner/DetailViewBanner";
import SiteHeader from "../../../stories/Components/SiteHeader/SiteHeader";
import Spinner from "../../../stories/Components/Spinner/Spinner";
import TabbedContainer from "../../../stories/Components/TabbedContainer/TabbedContainer";
import whiteCircleCheckIcon from "../../../stories/assets/images/circleCheckIcon.svg";
import whiteCrossIcon from "../../../stories/assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../../stories/assets/images/whiteExclamationIcon.svg";
import AssetDetailView from "./AssetDetailView";
import AssetGenInformation from "./AssetGenInformation";

import { ASSET, GET_ASSET_DOCUMENT_PATH } from "../../../constants";
import useAttributes from "../../../hooks/useAttributes";
import MaintenanceScheduleModal from "../../../stories/Components/AssetForms/MaintenanceScheduleModal";
import AssetDeleteModal from "./DeleteModal";
import {
  hasDeletePermission,
  hasWritePermission,
} from "../../../helpers/Permissions";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

/**
 * @deprecated
 */
const ProjectAssetDetails = () => {
  const history = useHistory();
  const { data: currentUser } = useCurrentUser();
  const [measurements, reloadMeasurements] = useAttributes();
  const { data: managementConfiguration } = useManagementConfiguration();
  const { assetId, projectId, locationId, spaceId } = useParams();
  const [assets] = useAssets({ assetId });

  const [editedAsset, dispatch] = useAssetFormReducer();
  const [asset, setAsset] = useState(undefined);

  const [users] = useUsers();
  const { property } = usePropertyById(asset?.property?.split("/")?.[1]);
  const [projectData, reload] = useProject(projectId);
  const [assetMembers, setAssetMembers] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [assetImage, setAssetImage] = useState();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [flatSpaces, setFlatSpaces] = useState([]);
  const [flatBuildings, setFlatBuildings] = useState([]);
  const [categoryOptionsList, setCategoryOptionsList] = useState([]);
  const [subcategoryOptionsMap, setSubcategoryOptionsMap] = useState({});
  const [project, setProject] = useState();
  const [projectBuilding, setProjectBuilding] = useState();
  const [levelTypes, setLevelTypes] = useState();
  const [spaceTypes, setSpaceTypes] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [newAttributes, setNewAttributes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (users?.length) {
      let members;
      if (property?.members) {
        members = property?.members;
      }
      if (projectData?.members) {
        members = projectData?.members;
      }
      if (members) {
        const tempMembers = users.filter((user) => {
          return !!members.find((member) => {
            return member?.user === user.reference && user?.name?.firstName;
          });
        });
        const membersArray = tempMembers?.map((member) => {
          return {
            label: `${member.name.firstName} ${member.name.lastName}`,
            value: member.reference,
          };
        });
        setAssetMembers(membersArray);
      }
    }
  }, [
    project,
    users,
    setProject.members,
    property?.members,
    projectData?.members,
  ]);

  const getAssetProject = async (id) => {
    const { data } = await ProjectAPI.getById(id?.split("/")[1]);
    const buildings =
      data?.buildings &&
      data?.buildings?.flatMap((spot) => {
        const tempBuilding = [
          {
            name: spot?.name,
            label: spot?.name,
            id: spot?.id,
            value: spot?.id,
            area: spot?.area?.value && `${parseInt(spot?.area?.value, 10)} SF`,
            image:
              spot?.images?.reduce((prime, img) => {
                if (img?.isPrimary) prime?.push(img);
                return prime;
              }, [])[0] ?? spot?.images?.[0],

            spaces: spot?.spaces?.flatMap((space) => [
              {
                name: space?.name,
                label: space?.name,
                value: space?.id,
                type: space?.type,
                level: space?.level,
                id: space?.id,
                image:
                  space?.images?.reduce((prime, img) => {
                    if (img?.isPrimary) prime?.push(img);
                    return prime;
                  }, [])[0] ?? space?.images?.[0],
              },
            ]),
          },
        ];
        return tempBuilding;
      });
    const spaces = buildings?.reduce((map, val) => {
      // eslint-disable-next-line no-param-reassign
      map[val?.id] = val?.spaces?.reduce(
        (spaceMap, space) => {
          // eslint-disable-next-line no-param-reassign
          spaceMap[space?.id] = {
            building: val?.id,
            name: space?.name,
            label: space?.name,
            value: space?.id,
            type: space?.type,
            level: space?.level,
            id: space?.id,
            image: space?.image,
          };
          spaceMap.flat.push(spaceMap[space?.id]);
          return spaceMap;
        },
        { flat: [] }
      );
      return map;
    }, {});
    setProject({
      ...data,
      images:
        data?.images?.reduce((prime, img) => {
          if (img?.isPrimary) prime?.push(img);
          return prime;
        }, [])[0] ?? data?.images?.[0],
    });
    setFlatBuildings(buildings);
    setFlatSpaces(spaces);
  };
  useEffect(() => {
    if (
      asset?.id !== assetId &&
      assets?.[0]?.id === assetId &&
      !project &&
      assets?.[0]?.project?.split("/")[0] === "Project"
    ) {
      setAssetImage(assets?.[0]?.primaryImage?.contentsUrl ?? null);
      setAsset(assets?.[0]);
      getAssetProject(assets?.[0]?.project);
    }
  }, [assets, asset?.id, assetId, project]);

  useEffect(() => {
    const resolvePrimary = async () => {
      if (asset?.images?.length) {
        const primaryImage = await resolvePrimaryImage(asset.images);
        setAssetImage(primaryImage?.contentsUrl);
        setAsset((prev) => {
          return { ...prev, primaryImage };
        });
      }
    };
    resolvePrimary();
  }, [asset?.images]);

  useEffect(() => {
    if (managementConfiguration) {
      const categories = managementConfiguration.management?.asset?.category;
      const subcatMap = {};
      setCategoryOptionsList(() => {
        const catList = categories
          ?.filter((cat) => cat.selected)
          .map((cat) => {
            subcatMap[cat.display] = cat.subcategories
              .filter((subcat) => subcat.selected)
              .map((subcat) => {
                return { label: subcat.display, value: subcat.id };
              });
            return { label: cat.display, value: cat.id };
          });
        return catList;
      });
      setSubcategoryOptionsMap(subcatMap);
      const projectLevels =
        managementConfiguration.management?.projectLevel?.types?.reduce(
          (levels, val) => {
            // eslint-disable-next-line no-param-reassign
            levels[val.id] = {
              value: val?.id,
              label: val?.display,
            };
            return levels;
          },
          {}
        );
      const projectSpaces =
        managementConfiguration.management?.projectSpace?.types?.reduce(
          (levels, val) => {
            // eslint-disable-next-line no-param-reassign
            levels[val.id] = {
              value: val?.id,
              label: val?.display,
            };
            return levels;
          },
          {}
        );
      setSpaceTypes(projectSpaces);
      setLevelTypes(projectLevels);
    }
  }, [managementConfiguration]);

  useEffect(() => {
    if (editedAsset) {
      setProjectBuilding(
        flatBuildings?.filter(
          (value) => value?.id === editedAsset?.locations?.[0]?.building
        )
      );
    }
  }, [editedAsset, flatBuildings]);

  const resetAssetState = React.useCallback(() => {
    setAssetImage(asset?.primaryImage?.contentsUrl);
    dispatch({
      type: "reset",
      asset,
    });
  }, [asset, dispatch]);

  // ref to hold timer for onFinishEditing function
  const finishEditingRef = useRef();

  useEffect(() => {
    // clear timer on unmount
    if (finishEditingRef.current) {
      clearTimeout(finishEditingRef.current);
    }
  }, []);

  const onFinishEditing = React.useCallback(
    async (key, val) => {
      const SavingDelay = () => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 2000);
        });
      };
      const patches = [SavingDelay];
      const posts = [SavingDelay];
      setIsSaving(true);

      let newUpdateIso = new Date();
      newUpdateIso = newUpdateIso.toISOString();
      const finishedAsset = {
        ...editedAsset,
        metadata: { ...editedAsset?.metadata, lastUpdated: newUpdateIso },
        [key]: val,
      };
      switch (key) {
        case "images": {
          const newAsset = await AssetAPI.patch(asset.id, finishedAsset, asset);
          setAsset((prev) => newAsset?.data ?? prev);
          setIsSaving(false);
          break;
        }
        default: {
          /**
           * Toast Initial Loading State
           */
          const savingToast = toast("Saving...", {
            isLoading: true,
            position: "top-center",
          });

          patches.push(() => AssetAPI.patch(asset.id, finishedAsset, asset));

          const arrayOfPatches = patches?.map((patch) => patch());
          const arrayOfPosts = posts?.map((post) => post());
          Promise.all(arrayOfPatches)
            .then((res) => {
              setAsset((prev) => res[1]?.data ?? prev);
              toast.update(savingToast, {
                isLoading: false,
                render: "Saved",
                closeButton: toastCloseIcon,
                className: "bg-brandGreen text-white",
                hideProgressBar: true,
                position: "top-center",
                icon: toastIcon,
                autoClose: 3000,
              });
              setIsSaving(false);
            })
            .catch(() => {
              toast.update(savingToast, {
                isLoading: false,
                render: "Error Saving",
                style: {
                  backgroundColor: "#BC2727",
                  color: "white",
                },
                closeButton: toastCloseIcon,
                position: "top-center",
                hideProgressBar: true,
                icon: toastErrorIcon,
                autoClose: 3000,
              });
              setIsSaving(false);
            });

          if (arrayOfPosts?.length) {
            arrayOfPosts.push(() => {
              reloadMeasurements();
              setNewAttributes([]);
            });
          }

          Promise.all(arrayOfPosts);
        }
      }
    },
    [asset, editedAsset, reloadMeasurements]
  );

  useEffect(() => {
    if (asset) {
      resetAssetState();
    }
  }, [asset, resetAssetState]);

  const buttonActions = () => {
    const options = [];

    if (currentUser?.hasPermission?.("task", "can_create")) {
      options.push({
        title: "Schedule Maintenance",
        onClick: () => setIsModalOpen(true),
      });
    }

    options.push({
      title: "Add Document",
      onClick: () => history.push(GET_ASSET_DOCUMENT_PATH(assetId)),
    });

    if (hasDeletePermission(ASSET, currentUser)) {
      options.push({
        title: "Delete",
        onClick: () => setShowDeleteModal(true),
      });
    }
    // { title: "Archive", onClick: handleArchive },
    return options;
  };

  const tabs = useMemo(
    () => [
      {
        title: "About",
        content: (
          <AssetDetailView
            editedAsset={editedAsset}
            dispatch={dispatch}
            flatSpaces={flatSpaces}
            flatBuildings={flatBuildings}
            project={project}
            levelTypes={levelTypes}
            spaceTypes={spaceTypes}
            projectBuilding={projectBuilding}
            categoryOptionsList={categoryOptionsList}
            subcategoryOptionsMap={subcategoryOptionsMap}
            onFinishEditing={onFinishEditing}
            isSaving={isSaving}
            disableEditing={hasWritePermission(ASSET, currentUser)}
          />
        ),
      },
      {
        title: "Attributes",
        content: (
          <div className="flex">
            <div className="w-2/5 mr-5">
              <AssetGenInformation
                title="General Information"
                editedAsset={editedAsset}
                dispatch={dispatch}
                onFinishEditing={onFinishEditing}
                disableEditing={!hasWritePermission(ASSET, currentUser)}
              />
            </div>
            <div className="w-2/5">
              <AttributeAddInformation
                title="Additional Information"
                resource={editedAsset}
                dispatch={dispatch}
                onFinishEditing={onFinishEditing}
                disableEditing={!hasWritePermission(ASSET, currentUser)}
                setNewAttributes={setNewAttributes}
                measurements={measurements}
                newAttributes={newAttributes}
              />
            </div>
          </div>
        ),
      },
    ],
    [
      editedAsset,
      dispatch,
      flatSpaces,
      flatBuildings,
      project,
      levelTypes,
      spaceTypes,
      projectBuilding,
      categoryOptionsList,
      subcategoryOptionsMap,
      onFinishEditing,
      isSaving,
      currentUser,
      measurements,
      newAttributes,
    ]
  );
  const handleChangeImage = (image) => {
    const newImage = { file: image, isPrimary: true };
    dispatch({
      type: "addImage",
      image: newImage,
    });

    let tempImages = [];
    if (editedAsset.images?.length) {
      tempImages = editedAsset.images;
    }
    onFinishEditing("images", [
      ...tempImages.map((item) => {
        return { ...item, isPrimary: false };
      }),
      newImage,
    ]);
  };

  return (
    <>
      <SiteHeader
        title={editedAsset?.name}
        dropdownRoutes={
          editedAsset &&
          getAssetOptions(editedAsset?.id, null, projectId, locationId, spaceId)
        }
        buttons={
          hasWritePermission(ASSET, currentUser) && (
            <PrimaryButton
              title="Actions"
              dropdownItems={buttonActions()}
              large
            />
          )
        }
      />
      {asset ? (
        <>
          <DetailViewBanner
            resource={editedAsset}
            resourceImage={assetImage}
            onChangeImage={handleChangeImage}
            disableUpload={!hasWritePermission(ASSET, currentUser)}
          />
          <TabbedContainer
            tabs={tabs}
            activeIndex={activeTabIndex}
            onTabClick={(index) => setActiveTabIndex(index)}
          />

          <AssetDeleteModal
            asset={asset}
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
          />

          {isModalOpen && (
            <MaintenanceScheduleModal
              onCloseModal={() => setIsModalOpen(false)}
              assetMembersOptions={assetMembers}
              reloadAsset={reload}
              association={{
                reference: editedAsset?.reference,
                name: editedAsset?.name,
                [asset?.property ? "property" : "project"]: asset?.property
                  ? asset?.property?.split("/")[1]
                  : asset?.project?.split("/")[1],
              }}
            />
          )}
        </>
      ) : (
        <div className="flex justify-center items-center w-full h-full">
          <Spinner notFullScreen />
        </div>
      )}
    </>
  );
};

ProjectAssetDetails.propTypes = {};

ProjectAssetDetails.defaultProps = {};

export default ProjectAssetDetails;

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Table from "../../../Table/Table";
import CurrencyView from "../../../CurrencyView/CurrrencyView";
import CommentButton from "../../../CommentButton";
import useDocumentTimesheetsData from "./useDocumentTimesheetsData";

const columns = () => {
  return [
    {
      Header: "Employee",
      accessor: "employee",
      isPrimary: true,
    },
    {
      Header: "Financial Code",
      accessor: "category",
    },
    {
      Header: "Category",
      accessor: "rateDisplay",
    },
    {
      Header: "Rate",
      accessor: "ratePerHr",
      Cell: ({ value }) => {
        return <CurrencyView value={value} />;
      },
    },
    {
      Header: "Total Hours",
      accessor: "value",
      Cell: ({ value }) => (
        <div className="flex">
          <p>{parseFloat(value / 60).toFixed(2)}</p>
        </div>
      ),
    },
    {
      Header: "Total Cost",
      accessor: "totalCost",
      Cell: ({ value }) => (
        <CurrencyView value={parseFloat(value).toFixed(2)} />
      ),
    },
    {
      Header: "Time Entry Date",
      accessor: "timeEntryDate",
      Cell: ({ value }) => (
        <div className="flex">
          <p>{moment(value).format("MM-DD-YYYY h:mm a")}</p>
        </div>
      ),
    },
  ];
};

const DocumentTimesheets = ({
  project,
  csiCodes,
  setShowCommentsPopover,
  document,
}) => {
  const { tableData } = useDocumentTimesheetsData({
    project,
    csiCodes,
    document,
  });

  return (
    <div className="flex flex-col">
      <div className="flex justify-between py-2">
        <div className="flex">
          <p className="font-semibold text-2xl">Timesheets</p>
        </div>
        <div className="flex ">
          <CommentButton onClick={() => setShowCommentsPopover(true)} />
        </div>
      </div>
      <div>
        <Table
          className=""
          resourceName="invoice-timesheets"
          data={useMemo(() => {
            return tableData;
          }, [tableData])}
          columns={columns()}
          cellStyling="flex items-center h-16"
          cellTextStyling="w-full pb-1"
          hideCreateNewButton
          hideEditRowButton
          hideSiteHeader
          hideChangeView
          hideSaveButton
          disableHover
          showFooter
        />
      </div>
    </div>
  );
};

DocumentTimesheets.propTypes = {
  csiCodes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  project: PropTypes.shape({
    reference: PropTypes.string,
    id: PropTypes.string,
    budget: PropTypes.string,
    rateSheet: PropTypes.shape({
      rates: PropTypes.arrayOf(
        PropTypes.shape({
          category: PropTypes.string,
          id: PropTypes.string,
          ratePerHr: PropTypes.string,
        })
      ),
    }),
  }),
  setShowCommentsPopover: PropTypes.func,
  document: PropTypes.shape({
    reference: PropTypes.string,
  }),
};

DocumentTimesheets.defaultProps = {
  csiCodes: [],
  project: undefined,
  setShowCommentsPopover: undefined,
  document: undefined,
};

export default DocumentTimesheets;

import React from "react";
import useTextWithMentionData from "./useTextWithMentionData";

/**
 * Component that renders a user mention with custom style
 */
const TextWithMention = ({ comment, mentionTextCN, commentTextCN }) => {
  const { textWithMention, mentionData } = useTextWithMentionData({ comment });

  const renderRegularText = (value, key) => (
    <span key={key} className={commentTextCN || "text-sm"}>
      {value}
    </span>
  );

  const renderMention = (mention, key) => (
    <span
      key={key}
      className={`text-darkenedGreen bg-green-50 ${mentionTextCN || "text-sm"}`}
    >
      {mention}
    </span>
  );

  const renderText = () => {
    const textSplitted = textWithMention.split(/(@\S+)/);

    return textSplitted.map((value, index) => {
      if (value.startsWith("@") && mentionData[value]) {
        return renderMention(value, index);
      }
      return renderRegularText(value, index);
    });
  };

  return <div className="text-sm">{renderText()}</div>;
};

export default TextWithMention;

import { useReducer } from "react";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";
import moment from "moment";

const defaultState = {
  association: "",
  category: "",
  description: "",
  value: [],
  additionalInfo: [],
  locations: [],
  files: [],
  images: [],
  videos: [],
  links: [],
  documents: [],
  spaces: [],
  name: "",
  originalResource: {},
};

const defaultAppraisedValue = {
  type: {
    system: "https://auth-dev.g3dev.io/api/Codesystem/asset-value-type",
    version: "1.0",
    code: "ASS",
    display: "Assessed",
    definition: "The value has been assessed",
  },
  units: {
    system: "https://auth-dev.g3dev.io/api/Codesystem/currency",
    version: "1.0",
    code: "USD",
    display: "US Dollar",
    definition: "US Dollar, monetary currency of United States",
  },
  amount: "",
  date: moment().toISOString(),
};

const defaultEstimatedValue = {
  type: {
    display: "Estimated Value",
    code: "EST",
    definition: "The value has been estimated",
    system: "https://auth-dev.g3dev.io/api/Codesystem/asset-value-type",
    version: "1.0",
  },
  units: {
    system: "https://auth-dev.g3dev.io/api/Codesystem/currency",
    version: "1.0",
    code: "USD",
    display: "US Dollar",
    definition: "US Dollar, monetary currency of United States",
  },
  amount: undefined,
};

const defaultAppraisalValue = {
  type: {
    display: "Appraisal Value",
    code: "ASS",
    definition: "The price at which it was appraised",
    system: "https://auth-dev.g3dev.io/api/Codesystem/asset-value-type",
    version: "1.0",
  },
  units: {
    system: "https://auth-dev.g3dev.io/api/Codesystem/currency",
    version: "1.0",
    code: "USD",
    display: "US Dollar",
    definition: "US Dollar, monetary currency of United States",
  },
  amount: "",
};

const reducer = (asset, action) => {
  let foundIndex;
  let tempItem;
  let tempLength;

  switch (action.type) {
    case "reset": {
      const cloned = cloneDeep(action.asset);
      return cloned ? { ...cloned, originalResource: cloned } : defaultState;
    }
    case "setCurrentTags": {
      return { ...asset, currentTags: action.currentTags };
    }
    case "name":
      return {
        ...asset,
        name: action.name,
      };
    case "creationImage":
      return {
        ...asset,
        files: [{ ref: action.reference, category: action.category }],
        primaryImage: action.reference,
      };
    case "brand":
      return {
        ...asset,
        generalInfo: { ...asset.generalInfo, brand: action.brand },
      };
    case "quantity":
      return {
        ...asset,
        generalInfo: { ...asset.generalInfo, quantity: action.quantity },
      };
    case "purchaseDate":
      return {
        ...asset,
        generalInfo: {
          ...asset.generalInfo,
          purchaseDate: action.purchaseDate,
        },
      };
    case "origin":
      return {
        ...asset,
        generalInfo: { ...asset.generalInfo, origin: action.origin },
      };
    case "yearMade":
      return {
        ...asset,
        generalInfo: { ...asset.generalInfo, yearMade: action.yearMade },
      };
    case "numberOfOwners":
      return {
        ...asset,
        generalInfo: {
          ...asset.generalInfo,
          numberOfOwners: action.numberOfOwners,
        },
      };
    case "certificateOfOwnership":
      return {
        ...asset,
        generalInfo: {
          ...asset.generalInfo,
          certificateOfOwnership: action.certificateOfOwnership,
        },
      };
    case "condition":
      return {
        ...asset,
        generalInfo: { ...asset.generalInfo, condition: action.condition },
      };
    case "edition":
      return {
        ...asset,
        generalInfo: { ...asset.generalInfo, edition: action.edition },
      };
    case "category": {
      return {
        ...asset,
        category: action.category,
      };
    }
    case "subcategory":
      return {
        ...asset,
        subcategory: action.subcategory,
      };
    case "categorySubcategory":
      return {
        ...asset,
        category: action.category,
        subcategory: action.subcategory,
      };
    case "inLineFormCategoryAndSubcategory":
      return {
        ...asset,
        category: action.category,
        categoryId: action.categoryId,
        subcategory: action.subcategory,
        subcategoryId: action.subcategoryId,
      };
    case "type": {
      const newInfo = asset?.additionalInfo?.filter(
        (m) => m.detail !== "Types"
      );
      if (action.value) {
        newInfo.push({
          detail: "Types",
          value: action.value,
          unit: action.unit,
        });
      }

      return {
        ...asset,
        additionalInfo: newInfo,
        category: action.category,
        subcategory: action.subcategory,
      };
    }
    case "description":
      return {
        ...asset,
        description: action.description,
      };
    case "appraiser":
      return {
        ...asset,
        appraiser: action.appraiser,
      };
    case "broker": {
      if (action.broker === "remove") {
        const { broker, ...restAsset } = asset;
        return restAsset;
      }
      return {
        ...asset,
        broker: action.broker,
      };
    }
    case "examiner": {
      const newValue =
        asset?.value?.length === 0
          ? [...asset?.value, { examiner: action.examiner }]
          : asset?.value?.map((val) => {
              return Object.keys(val)?.includes("examiner")
                ? { ...val, examiner: action.examiner }
                : val;
            });
      return {
        ...asset,
        value: newValue,
      };
    }
    case "agent": {
      if (action.agent === "remove") {
        const { agent, ...restAsset } = asset;
        return restAsset;
      }
      return {
        ...asset,
        agent: action.agent,
      };
    }
    case "edit":
      return {
        ...asset,
        [action.key]: action.value,
      };
    case "edit2":
      return {
        ...asset,
        [action.key1]: {
          ...asset[action.key1],
          [action.key2]: action.value,
        },
      };
    case "building": {
      const updatedLocations = [...asset.locations];
      const currentLocation = updatedLocations[action.index];
      if (currentLocation.building !== action.building) {
        updatedLocations[action.index] = {
          id: currentLocation.id,
          building: action.building,
        };
        return {
          ...asset,
          locations: updatedLocations,
        };
      }
      return asset;
    }
    case "level": {
      const updatedLocations = [...asset.locations];
      const currentLocation = updatedLocations[action.index];
      if (currentLocation.level !== action.level) {
        updatedLocations[action.index] = {
          id: currentLocation.id,
          building: currentLocation.building,
          level: action.level,
        };
        return {
          ...asset,
          locations: updatedLocations,
        };
      }
      return asset;
    }
    case "location": {
      const updatedLocations = [...asset.locations];
      const currentLocation = updatedLocations[action.index];
      if (currentLocation.location !== action.location) {
        updatedLocations[action.index] = {
          id: currentLocation.id,
          building: currentLocation.building,
          level: currentLocation.level,
          location: action.location,
        };
        return {
          ...asset,
          locations: updatedLocations,
        };
      }
      return asset;
    }
    case "fullLocation": {
      const updatedLocations = [...asset.locations];
      updatedLocations[action.index] = {
        id: uuidv4(),
        property: action.property,
        building: action.building,
        level: action.level,
        location: action.location,
      };
      return {
        ...asset,
        locations: updatedLocations,
      };
    }
    case "editLocation": {
      if (asset?.locations?.length === 0) {
        return {
          ...asset,
          locations: [{ building: action.value }],
        };
      }
      return {
        ...asset,
        locations: [{ ...asset?.locations[0], building: action.value }],
      };
    }
    case "editSpace": {
      return {
        ...asset,
        spaces: [action.value],
      };
    }
    case "removeLocation": {
      const removedLocation = {
        ...asset,
        locations: [{ id: asset?.locations[0]?.id }],
      };
      return removedLocation;
    }
    case "addLocation":
      return {
        ...asset,
        locations: [{ id: uuidv4(), building: action.building }],
      };
    case "addSpace":
      return {
        ...asset,
        spaces: [action.space],
      };
    case "removeSpace":
      return {
        ...asset,
        spaces: [],
      };
    case "value":
      tempLength = asset.value.length;
      tempItem = asset.value.splice(tempLength - 1, 1);

      return {
        ...asset,
        value: [
          ...asset.value.filter((val) => val?.type?.code !== "EST"),
          { ...tempItem[0], ...action.data },
        ],
      };
    case "addValue":
      return {
        ...asset,
        value: [...asset.value, {}],
      };
    case "valueAlt":
      if (action.data?.id) {
        foundIndex = asset.value?.findIndex(
          (item) => item.id === action.data.id
        );
      }
      if (foundIndex > -1) {
        return {
          ...asset,
          value: asset.value?.map((item) => {
            if (item.id === action.data.id) {
              return {
                ...item,
                ...action.data,
              };
            }
            return item;
          }),
        };
      }
      return {
        ...asset,
        value: [...asset.value, { ...action.data }],
      };
    case "isAppraised": {
      return {
        ...asset,
        value: action.isAppraised
          ? [...asset?.value].concat(defaultAppraisedValue)
          : [
              ...asset?.value.filter(
                (item) =>
                  !(!item?.type && item?.amount) && item?.type?.code !== "ASS"
              ),
            ],
      };
    }
    case "estimatedValue": {
      // determine whether EST values already exist
      const isEstimatedValue = asset?.value.find((item) => {
        return item?.type?.code === "EST";
      });

      // if NO EST values already exist add
      const withoutEstimatedValue = [
        ...asset?.value,
        {
          ...defaultEstimatedValue,
          ...action.data,
        },
      ];

      // if EST values already exist update value
      const withEstimatedValue = asset?.value?.map((val) => {
        return val?.type?.code === "EST" ? { ...val, ...action.data } : val;
      });

      return {
        ...asset,
        value: isEstimatedValue ? withEstimatedValue : withoutEstimatedValue,
      };
    }
    case "appraisalValue": {
      const isAppraisalValue = asset?.value.find((item) => {
        return item?.type?.code === "ASS";
      });

      const valuesForEmptyAppraisal = [
        ...asset?.value,
        {
          ...defaultAppraisalValue,
          amount: action.appraisalValue,
          ...action.data,
        },
      ];

      const valuesForExistingAppraisal = asset?.value?.map((val) =>
        val?.type?.code === "ASS" ? { ...val, ...action.data } : val
      );

      const updatedAssetValues = isAppraisalValue
        ? valuesForExistingAppraisal
        : valuesForEmptyAppraisal;

      return {
        ...asset,
        value: updatedAssetValues,
      };
    }
    case "measurement": {
      if (action?.operation === "remove") {
        return {
          ...asset,
          additionalInfo: asset?.additionalInfo?.filter(
            (item) => item.detail !== action.detail
          ),
        };
      }
      if (action?.operation === "removeAll") {
        return {
          ...asset,
          additionalInfo: [],
        };
      }
      const elementIndex = asset?.additionalInfo.findIndex(
        (item) => item.detail === action.detail
      );
      if (elementIndex > -1) {
        return {
          ...asset,
          additionalInfo: asset?.additionalInfo?.map((item, idx) => {
            if (idx === elementIndex) {
              return {
                id: action.id,
                detail: action.detail,
                value: action.value,
                unit: action.unit,
              };
            }
            return item;
          }),
        };
      }
      return {
        ...asset,
        additionalInfo: [
          ...asset?.additionalInfo,
          {
            id: action.id,
            detail: action.detail,
            value: action.value,
            unit: action.unit,
          },
        ],
      };
    }
    case "notes": {
      return {
        ...asset,
        notes: action.notes,
      };
    }
    case "barcode": {
      return {
        ...asset,
        barcode: action.barcode,
      };
    }
    case "addImage":
      return {
        ...asset,
        images: [...(asset.images ?? []), action.image],
      };
    case "addImages":
      return {
        ...asset,
        images: action.images,
      };
    case "deleteImage":
      return {
        ...asset,
        images: asset.images?.filter((image) => image.file !== action.imageRef),
      };
    case "deleteImages":
      return {
        ...asset,
        images: asset.images?.filter(
          (image) => action.images.indexOf(image?.file) < 0
        ),
      };
    case "changeIsPrimary": {
      return {
        ...asset,
        images: asset.images?.map((image) => {
          if (image.file === action.id) {
            return {
              ...image,
              isPrimary: action.isPrimary,
            };
          }
          return {
            ...image,
            isPrimary: false,
          };
        }),
      };
    }
    case "addVideo":
      return {
        ...asset,
        videos: [...(asset.videos ?? []), action.video],
      };
    case "addVideos":
      return {
        ...asset,
        videos: [...asset.videos, ...action.videos],
      };
    case "deleteVideo":
      return {
        ...asset,
        videos:
          asset.videos?.filter((videoRef) => videoRef !== action.videoRef) ??
          [],
      };
    case "addFile":
      return {
        ...asset,
        files: [...(asset.files ?? []), action.file],
      };
    case "addFiles":
      return {
        ...asset,
        files: [...asset.files, ...action.files],
      };
    case "deleteFile":
      return {
        ...asset,
        files:
          asset.files?.filter((fileRef) => fileRef !== action.fileRef) ?? [],
      };
    case "addDocument":
      return {
        ...asset,
        documents: [...(asset.documents ?? []), action.document],
      };
    case "addDocuments":
      return {
        ...asset,
        documents: [...asset.documents, ...action.documents],
      };
    case "removeDocument":
      return {
        ...asset,
        documents:
          asset.documents?.filter((docRef) => docRef !== action.docRef) ?? [],
      };
    case "ownedBy":
      return {
        ...asset,
        ownedBy: action.ownedBy,
      };
    case "changeLink": {
      return {
        ...asset,
        links: asset.links?.map((link) => {
          if (link.id === action.link?.id) {
            return action.link;
          }
          return link;
        }),
      };
    }
    case "changeLinkItem": {
      const updatedLinks = [...asset.links];
      updatedLinks[action.index] = action.linkObj;
      return {
        ...asset,
        links: updatedLinks,
      };
    }
    case "addLinkCompleteLink":
      return {
        ...asset,
        links: [...(asset.links ?? []), action.link],
      };
    case "addLink":
      return {
        ...asset,
        links: [...(asset.links ?? []), { id: uuidv4(), name: "", url: "" }],
      };
    case "addLinkObject":
      return {
        ...asset,
        links: [...(asset.links ?? []), action.link],
      };
    case "addLinkObjects":
      return {
        ...asset,
        links: action.links,
      };
    case "filterLinks":
      return {
        ...asset,
        links: asset.links.filter((link) => link.name && link.url),
      };
    case "deleteLink":
      return {
        ...asset,
        links: asset.links?.filter((link) => link.id !== action.linkId) ?? [],
      };
    case "project": {
      const assetClone = cloneDeep(asset);
      delete assetClone.property;
      return {
        ...assetClone,
        project: action.value,
      };
    }
    case "property": {
      const assetClone = cloneDeep(asset);
      delete assetClone.project;
      return {
        ...assetClone,
        property: action.value,
      };
    }
    case "association": {
      const assetClone = cloneDeep(asset);
      const resourceType = action.value?.split("/")[0]?.toLowerCase();
      if (resourceType === "property") {
        delete assetClone.project;
      } else if (resourceType === "project") {
        delete assetClone.property;
      }
      return {
        ...assetClone,
        [action.value?.split("/")[0]?.toLowerCase() ?? "association"]:
          action.value,
      };
    }

    case "clearAssociation": {
      const assetClone = cloneDeep(asset);
      delete assetClone.property;
      delete assetClone.project;
      return { ...assetClone };
    }
    case "replacement-cost":
      return {
        ...asset,
        replacement: {
          ...asset.replacement,
          cost: action.value,
        },
      };
    case "replacement-date":
      return {
        ...asset,
        replacement: {
          ...asset.replacement,
          date: action.value,
        },
      };
    // ********************************
    case "purchase-date":
      return {
        ...asset,
        purchase: {
          ...asset.purchase,
          date: action.value,
        },
      };
    // ********************************
    case "clearAttributes": {
      const assetClone = cloneDeep(asset);
      return {
        ...assetClone,
        additionalInfo: [],
      };
    }
    default:
      return asset;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};

// This file allows to map options for different resources

// Property resource
const property = {
  details: [
    { id: "general", value: "general", label: "General Information" },
    { id: "members", value: "members", label: "Members" },
    { id: "contacts", value: "contacts", label: "Contacts" },
    { id: "attributes", value: "attributes", label: "Attributes" },
  ],
  tasks: [
    { id: "complete", value: "complete", label: "Complete" },
    { id: "incomplete", value: "incomplete", label: "Incomplete" },
  ],
  tickets: [
    { id: "complete", value: "complete", label: "Complete" },
    { id: "incomplete", value: "incomplete", label: "Incomplete" },
  ],
  calendar: [
    { id: "event", value: "event", label: "Events" },
    { id: "task", value: "task", label: "Tasks" },
    { id: "pto", value: "pto", label: "PTO" },
  ],
  expenses: [
    { id: "billable", value: "billable", label: "Billable" },
    { id: "nonbillable", value: "nonbillable", label: "Non-Billable" },
  ],
  workflows: [
    { id: "draft", value: "draft", label: "Draft" },
    { id: "inprogress", value: "inprogress", label: "In Progress" },
    { id: "completed", value: "completed", label: "Complete" },
    { id: "void", value: "void", label: "Void" },
  ],
};

// Project resource
const project = {
  details: [
    { id: "general", value: "general", label: "General Information" },
    { id: "members", value: "members", label: "Members" },
    { id: "contacts", value: "contacts", label: "Contacts" },
  ],
  tasks: [
    { id: "complete", value: "complete", label: "Complete" },
    { id: "incomplete", value: "incomplete", label: "Incomplete" },
  ],
  tickets: [
    { id: "complete", value: "complete", label: "Complete" },
    { id: "incomplete", value: "incomplete", label: "Incomplete" },
  ],
  calendar: [
    { id: "event", value: "event", label: "Events" },
    { id: "task", value: "task", label: "Tasks" },
    { id: "pto", value: "pto", label: "PTO" },
  ],
  expenses: [
    { id: "billable", value: "billable", label: "Billable" },
    { id: "nonbillable", value: "nonbillable", label: "Non-Billable" },
  ],
  workflows: [
    { id: "draft", value: "draft", label: "Draft" },
    { id: "inprogress", value: "inprogress", label: "In Progress" },
    { id: "completed", value: "completed", label: "Complete" },
    { id: "void", value: "void", label: "Void" },
  ],
  submittals: [
    { id: "open", value: "open", label: "Open" },
    { id: "closed", value: "closed", label: "Closed" },
  ],
  financialDocs: [
    { id: "ca", value: "ca", label: "CA" },
    { id: "co", value: "co", label: "CO" },
    { id: "pa", value: "pa", label: "PA" },
    { id: "po", value: "po", label: "PO" },
    { id: "invoice", value: "invoice", label: "Invoice" },
    { id: "vendorinvoice", value: "vendorinvoice", label: "Vendor Invoice" },
  ],
};

// Asset resource
const asset = {
  details: [
    { id: "general", value: "general", label: "General Information" },
    { id: "spaces", value: "spaces", label: "Spaces" },
    { id: "attributes", value: "attributes", label: "Attributes" },
  ],
  tasks: [
    { id: "complete", value: "complete", label: "Complete" },
    { id: "incomplete", value: "incomplete", label: "Incomplete" },
  ],
  tickets: [
    { id: "complete", value: "complete", label: "Complete" },
    { id: "incomplete", value: "incomplete", label: "Incomplete" },
  ],
  calendar: [
    { id: "event", value: "event", label: "Events" },
    { id: "task", value: "task", label: "Tasks" },
  ],
  expenses: [
    { id: "billable", value: "billable", label: "Billable" },
    { id: "nonbillable", value: "nonbillable", label: "Non-Billable" },
  ],
};

// Default options in all resources
const defaultOptions = {
  details: {
    selected: true,
    options: ["general"],
  },
};

const pillsSection = {
  financialDocs: [
    { label: "All", value: "all" },
    { label: "Open", value: "open" },
    { label: "Closed", value: "closed" },
  ],
};

export default {
  property,
  project,
  asset,
  defaultOptions,
  pillsSection,
};

/* eslint-disable no-console */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { findIndex, isEqual, omit, uniqBy } from "lodash";
import cntl from "cntl";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import {
  BudgetAPI,
  CommentAPI,
  LineitemAPI,
  ProjectAPI,
  FileAPI,
} from "@griffingroupglobal/eslib-api";
import moment from "moment";
import { json2csv } from "json-2-csv";
import PureBudgetTable from "./PureBudgetTable";
import useBudgetLineItems from "../../../hooks/useBudgetLineItems";
import { useAppState } from "../../../state/appState";
import {
  SET_BUDGET_LINE_ITEMS,
  SET_BUDGET_TYPE,
  BUDGET_COST_PLUS,
  PROJECT_STATUS_TYPES,
  budgetTypeMap,
  SET_BUDGET_GROUPS,
  SET_BUDGET_LOCK_GMP,
  SET_BUDGET_LOCK_FIXED_FIRM,
  SET_BUDGET_LAST_UPDATED,
  GET_PROJECT_BUDGET_CREATE_DOCUMENT_PATH,
  CONTINGENGY_CSI_CODE,
  VENDOR_INVOICE,
  SET_WORKFLOW_MODAL_STATUS,
  INVOICE,
} from "../../../constants";
import useBudgetFormReducer from "../../../hooks/useBudgetFormReducer";
import {
  cleanFieldsForBulkUpdate,
  formatGroupDropdownForBudgetTable,
  formatLineitem,
  getRowsAndColumsForCsv,
  getUserData,
  lockFixedFirmModalText,
  lockGMPModalText,
  publishBudgetModalText,
  unlockLineitemText,
} from "../../../helpers/Budget";
import { uploadFile } from "../../../helpers/File";
import BudgetSummary from "../BudgetSummary/BudgetSummary";
import useTemplatesConfiguration from "../../../hooks/useTemplatesConfiguration";
import useCurrentUser from "../../../hooks/useCurrentUser";
import BudgetTableComments from "./BudgetTableComments";
import whiteExlamationIcon from "../../assets/images/whiteExclamationIcon.svg";
import whiteCircleCheckIcon from "../../assets/images/circleCheckIcon.svg";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import BudgetTableConfirmationModal from "./BudgetTableConfirmationModal";
import useUsers from "../../../hooks/useUsers";
import Modal from "../Modal/Modal";
// eslint-disable-next-line import/no-cycle
import DocumentView from "../DocumentView/DocumentView";
// eslint-disable-next-line import/no-cycle
import BudgetTablePendingUpdates from "../BudgetTablePendingUpdates/BudgetTablePendingUpdates";
import useDocuments from "../../../hooks/useDocuments";
import GetStartedCircle from "../GetStartedCircle/GetStartedCircle";
import BudgetTableReallocationToast from "./BudgetTableReallocationToast";
import {
  formatWithCommasWithoutDecimal,
  getFullName,
  formatNumber,
} from "../../../helpers/Formatters";
import BudgetTableReallocateForm from "./BudgetTableReallocateForm";
import ExpenseDetailsModal from "../ExpenseForm/ExpenseDetailsModal";
import BudgetTableHoldbackForm from "./BudgetTableHoldbackForm";
import useHoldbackFormReducer from "../../../hooks/useHoldbackFormReducer";
import BudgetTableInLineForm from "./BudgetTableInLineForm";
import BudgetTableUpdateVendorForm from "./BudgetTableUpdateVendorForm";
import useVendorFormReducer from "../../../hooks/useVendorFormReducer";
import BudgetTableRejectVendorInvoiceForm from "./BudgetTableRejectVendorInvoiceForm";
import useProjectCodes from "../../../hooks/useProjectCodes";
import { useGetExpenses } from "../../../hooks/useExpenses";
import useTimesheetsForBudget from "../../../hooks/useTimesheetForBudget";
import queryClient from "../../../config/reactQuery/queryClient";
import {
  budgetLineitemKeys,
  expenseKeys,
  vendorinvoiceKeys,
} from "../../../config/reactQuery/queryKeyFactory";
import BackButton from "../WidgetEditorView/Header/BackButton";
import { toastMessage, toastError } from "../Toast/Toast";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;
const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);

const menuButtonCN = cntl`
  w-64
  bg-white
  hover:bg-gray-100
  text-gray-900
 `;

const BudgetTable = ({
  budgetId,
  projectId,
  projectData,
  onRowSelect,
  initialSelectedRows,
  documentModalView,
  isActivateProject,
  setDraftBudgetLineItems,
  setIsLoading,
  hideSiteHeader,
  pullSummary,
  tableMiniMapContainerId,
  disableRowsWithRateAndNoUncommitedValuePending,
  disableContingencyLineitem,
  disableSelectAllCheckbox,
  disbaleRowActions,
  report,
  viaChangeOrder,
  setButtonActions,
  setActivateProjectModal,
  hideSiteHeaderTitle,
}) => {
  const history = useHistory();
  const { data: currentUser } = useCurrentUser();
  const {
    addDocument,
    documentsDict,
    documents,
    reload: reloadDocuments,
  } = useDocuments();
  const timesheetParams = { project: `Project/${projectId}` };
  const { data: timesheetsData } = useTimesheetsForBudget(timesheetParams);
  const [
    originalBudgetLineItems,
    budgetGroups,
    budgetLastUpdated,
    budgetType,
    budgetSummary,
    budgetPendingUpdates,
    reload,
    dispatchAppState,
    isLockGmp,
    isLockFixedFirm,
    loading,
  ] = useBudgetLineItems(budgetId);
  const [templatesConfiguration, update, , deleteTemplate] =
    useTemplatesConfiguration();
  const { data: expensesGetData } = useGetExpenses(
    undefined,
    projectId,
    undefined
  );
  const [reallocation, dispatchForm] = useBudgetFormReducer();
  const [holdback, dispatchHoldback] = useHoldbackFormReducer();
  const [vendorInfo, dispatchVendor] = useVendorFormReducer();
  const [users, reloadUsers, , usersDict] = useUsers(false, false);
  // Replace Reload All Rates
  const {
    actions: {
      update: { mutateAsync: reloadRates },
    },
  } = useProjectCodes();
  const [
    { systemConfiguration, managementConfiguration, financialsConfiguration },
    appDispatch,
  ] = useAppState();
  const [project, setProject] = useState(projectData);
  const [lineitemVersions, setLineitemVersions] = useState({});
  const [budgetLineItems, setBudgetLineItems] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState(initialSelectedRows);
  const [templateSettings, setTemplateSettings] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [unitsOfMeasure, setUnitsOfMeasure] = useState([]);
  const [groupedUnitsOfMeasure, setGroupedUnitsOfMeasure] = useState([]);
  const [divisionCodes, setDivisionCodes] = useState([]);
  const [csiCodes, setCsiCodes] = useState([]);
  const [commentsData, setCommentsData] = useState([]);
  const [rateSheetRates, setRateSheetRates] = useState([]);
  const [selectedTimesheetRows, setSelectedTimesheetRows] = useState([]);
  const [selectedDocumentsRows, setSelectedDocumentRows] = useState([]);
  const [pendingFinDocsUpdates, setPendingFinDocsUpdates] = useState([]);
  const [pendingVendorInvoicesUpdates, setPendingVendorInvoicesUpdates] =
    useState([]);
  const [pendingInvoicesUpdates, setPendingInvoicesUpdates] = useState([]);
  const [selectedExpenseRows, setSelectedExpenseRows] = useState([]);
  const [pendingExpensesUpdates, setPendingExpensesUpdates] = useState([]);
  const [pendingTimesheetsUpdates, setPendingTimesheetsUpdates] = useState([]);

  const [spaceConfiguration, setSpaceConfiguration] = useState();
  const [openDocumentDetails, setOpenDocumentDetails] = useState();
  const [openVendorInvoiceDetails, setOpenVendorInvoiceDetails] = useState();
  const [openInvoiceDetails, setOpenInvoiceDetails] = useState();
  const [openTimesheet, setOpenTimesheet] = useState();
  const [checkedRow, setCheckedRow] = useState();

  const [selectedLineItemForComments, setSelectedLineItemForComments] =
    useState(null);
  const [unlockLineitem, setUnlockLineitem] = useState(null);
  const [unlockLineitemUser, setUnlockLineitemUser] = useState(null);
  const [openDocument, setOpenDocument] = useState(null);
  const [openExpense, setOpenExpense] = useState(null);
  const [expenseData, setExpenseData] = useState(null);
  const [timesheetData, setTimesheetData] = useState(null);

  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [isAllLowRangeChecked, setIsAllLowRangeChecked] = useState(false);
  const [isAllHighRangeChecked, setIsAllHighRangeChecked] = useState(false);
  const [showPublishBudgetModal, setShowPublishBudgetModal] = useState(false);
  const [showLockGMPModal, setShowLockGMPModal] = useState(false);
  const [showLockFixedFirmModal, setShowLockFixedFirmModal] = useState(false);
  const [showHoldbackModal, setShowHoldbackModal] = useState(false);
  const [showEditSelectedModal, setEditSelectedModal] = useState(false);
  const [showUpdateVendorModal, setShowUpdateVendorModal] = useState(false);
  const [isRejectOpen, setisRejectOpen] = useState(false);
  const [showPendingUpdatesQueue, setShowPendingUpdatesQueue] = useState(false);
  const [showClientInvoice, setShowClientInvoice] = useState(false);
  const [projectClientInovice, setProjectClientInovice] = useState();

  const isDraftProject = project?.status === PROJECT_STATUS_TYPES.draft;

  useEffect(() => {
    if (documents?.length) {
      setProjectClientInovice(
        documents?.find(
          (doc) =>
            doc.docType === INVOICE &&
            doc.project === `Project/${projectId}` &&
            doc.status !== "closed"
        )
      );
    }
  }, [documents, projectId]);

  useEffect(() => {
    if (budgetPendingUpdates?.length && documentsDict) {
      setPendingFinDocsUpdates(
        budgetPendingUpdates.reduce((filteredFinDocs, { reference }) => {
          const doc = documentsDict[reference];
          if (
            doc &&
            doc?.docType !== VENDOR_INVOICE &&
            doc?.docType !== INVOICE
          ) {
            filteredFinDocs.push({
              ...doc,
              createdBy: getFullName(doc?.creator?.name),
            });
          }
          return filteredFinDocs;
        }, [])
      );

      setPendingVendorInvoicesUpdates(
        budgetPendingUpdates.reduce((filteredVIs, { reference }) => {
          const doc = documentsDict[reference];
          if (doc && doc?.docType === VENDOR_INVOICE) {
            filteredVIs.push({
              ...doc,
            });
          }
          return filteredVIs;
        }, [])
      );

      setPendingInvoicesUpdates(
        budgetPendingUpdates.reduce((filteredInvoices, { reference }) => {
          const doc = documentsDict[reference];
          if (doc && doc?.docType === INVOICE) {
            filteredInvoices.push({
              ...doc,
              submittedByName: getFullName(doc?.submittedBy?.name),
            });
          }
          return filteredInvoices;
        }, [])
      );

      setPendingExpensesUpdates(
        budgetPendingUpdates.reduce((filteredExpenses, { reference }) => {
          const expense = expensesGetData?.expensesDict[reference];
          if (expense) {
            const user = usersDict[expense.metadata.createdBy];
            const createdBy = getFullName(user?.name);
            const csiCodeObj = csiCodes?.find(
              (c) =>
                c.value ===
                `${expense?.financialCode?.division} ${expense?.financialCode?.code} ${expense?.financialCode?.subcode}`
            )?.label;

            filteredExpenses.push({
              ...expense,
              financialCode: csiCodeObj,
              createdBy,
              createdAt: expense.metadata.createdAt,
              category:
                financialsConfiguration?.financials?.expense?.categories?.find(
                  (category) => category.id === expense?.category
                )?.display,
            });
          }
          return filteredExpenses;
        }, [])
      );

      setPendingTimesheetsUpdates(
        budgetPendingUpdates.reduce(
          (filteredTimesheets, { reference, context }) => {
            const timesheet = timesheetsData?.timesheetsDict?.[reference];
            const timesheetEntry = timesheet?.[context?.id];

            if (timesheetEntry) {
              const user = usersDict[timesheetEntry.userRef];
              const employee = getFullName(user?.name);
              const csiCodeObj = csiCodes?.find(
                (c) =>
                  c.value ===
                  `${timesheetEntry?.financialCode?.division} ${timesheetEntry?.financialCode?.code} ${timesheetEntry?.financialCode?.subcode}`
              )?.label;

              const rateInfo = rateSheetRates?.find(
                (rate) => rate.value === timesheetEntry.rate
              );
              filteredTimesheets.push({
                ...timesheetEntry,
                category: csiCodeObj,
                employee,
                timeEntryDate: timesheetEntry.date,
                reference,
                rateDisplay: rateInfo?.label,
              });
            }
            return filteredTimesheets;
          },
          []
        )
      );
    } else {
      setPendingFinDocsUpdates([]);
      setPendingVendorInvoicesUpdates([]);
      setPendingExpensesUpdates([]);
      setPendingTimesheetsUpdates([]);
    }
  }, [
    budgetPendingUpdates,
    csiCodes,
    documentsDict,
    expensesGetData?.expensesDict,
    financialsConfiguration?.financials?.expense?.categories,
    rateSheetRates,
    timesheetsData?.timesheetsDict,
    usersDict,
  ]);

  useEffect(() => {
    const getExpense = async () => {
      const ref = openExpense.startsWith("Expense/")
        ? openExpense
        : `Expense/${openExpense}`;
      setExpenseData(expensesGetData.expensesDict[ref]);
    };

    if (openExpense && expensesGetData?.expensesDict) {
      getExpense();
    } else {
      setExpenseData(null);
    }
  }, [expensesGetData?.expensesDict, openExpense]);

  useEffect(() => {
    const getTimesheet = async () => {
      const timesheet =
        timesheetsData?.timesheetsDict?.[openTimesheet.reference];
      const timesheetEntry = timesheet?.[openTimesheet.timesheetEntry];

      setTimesheetData(timesheetEntry);
    };

    if (openTimesheet && timesheetsData?.timesheetsDict) {
      getTimesheet();
    } else {
      setTimesheetData(null);
    }
  }, [openTimesheet, timesheetsData?.timesheetsDict]);

  useEffect(() => {
    if (setIsLoading) {
      setIsLoading(loading);
    }
  }, [loading, setIsLoading]);

  useEffect(() => {
    if (isActivateProject) {
      const allAreNotLowRange = budgetLineItems.some(
        (li) => !li.isLowRangeSelected
      );
      setIsAllLowRangeChecked(!allAreNotLowRange);

      const allAreNotHighRange = budgetLineItems.some(
        (li) => !li.isHighRangeSelected
      );
      setIsAllHighRangeChecked(!allAreNotHighRange);
      setDraftBudgetLineItems(budgetLineItems);
    }
  }, [budgetLineItems, isActivateProject, setDraftBudgetLineItems]);

  useEffect(() => {
    if (!project && projectData?.reference) {
      setProject(projectData);
    }
  }, [project, projectData]);

  useEffect(() => {
    if (project) {
      setRateSheetRates(
        project?.rateSheet?.rates?.map((rate) => ({
          label: rate.category,
          value: rate.id,
          ratePerHr: rate.ratePerHr,
        }))
      );
    }
  }, [project]);

  useEffect(() => {
    setSpaceConfiguration({
      spaces: managementConfiguration?.management?.propertySpace?.types
        ?.filter((type) => type?.selected)
        ?.reduce((dict, item) => {
          // eslint-disable-next-line no-param-reassign
          dict[item?.id] = item;
          return dict;
        }, {}),
      levels: managementConfiguration?.management?.propertyLevel?.types
        ?.filter((type) => type?.selected)
        ?.reduce((dict, item) => {
          // eslint-disable-next-line no-param-reassign
          dict[item?.id] = item;
          return dict;
        }, {}),
    });
  }, [
    managementConfiguration?.management?.propertyLevel?.types,
    managementConfiguration?.management?.propertySpace?.types,
  ]);

  useEffect(() => {
    if (templatesConfiguration) {
      setTemplateSettings(
        templatesConfiguration?.templates?.project_budget?.map((template) => ({
          ...template,
          isAdmin: !template.custom,
        }))
      );
    }
  }, [templatesConfiguration]);

  useEffect(() => {
    if (isActivateProject) {
      setBudgetLineItems(
        originalBudgetLineItems?.filter(
          (li) => li?.highRangeCalculated - li?.liveBudget !== 0
        )
      );
    } else if (disableRowsWithRateAndNoUncommitedValuePending) {
      setBudgetLineItems(
        originalBudgetLineItems.map((li) => {
          if (li?.rate || formatNumber(li?.uncommittedValuePending) <= 0) {
            return {
              ...li,
              hideSelection: true,
            };
          }
          return li;
        })
      );
    } else if (disableContingencyLineitem) {
      setBudgetLineItems(
        originalBudgetLineItems.map((li) => {
          if (CONTINGENGY_CSI_CODE.includes(li.csiCode)) {
            return {
              ...li,
              hideSelection: true,
            };
          }
          return li;
        })
      );
    } else {
      setBudgetLineItems(originalBudgetLineItems);
    }
  }, [
    disableContingencyLineitem,
    disableRowsWithRateAndNoUncommitedValuePending,
    isActivateProject,
    originalBudgetLineItems,
  ]);

  useEffect(() => {
    if (reallocation?.reallocateFromLineitem) {
      setBudgetLineItems((prev) =>
        prev.map((li) => {
          if (li?.id === reallocation?.reallocateFromLineitem) {
            return {
              ...li,
              hideSelection: true,
            };
          }
          return li;
        })
      );
    } else {
      setBudgetLineItems((prev) =>
        prev.map((li) => {
          return {
            ...li,
            hideSelection: false,
          };
        })
      );
    }
  }, [reallocation?.reallocateFromLineitem]);

  useEffect(() => {
    if (reallocation?.reallocateToLineitem) {
      toast.dismiss("reallocation-toast");
    }
  }, [reallocation?.reallocateToLineitem]);

  useEffect(() => {
    if (financialsConfiguration?.financials) {
      const divisionsFormatted = [];
      const csiCodesFormatted = [];

      financialsConfiguration?.financials?.csiCodeMapping?.forEach((div) => {
        const division = {
          label: `${div.division} - ${div.description}`,
          value: div.division,
        };
        divisionsFormatted.push(division);

        div.csiCodes.forEach((csi) => {
          csi.subCodes.forEach((sub) => {
            const fullCode = `${div.division} ${csi.code} ${sub.code}`;
            const description =
              financialsConfiguration?.financials?.csiCodeMappingObject[
                fullCode
              ];
            const csiCodeFormatted = {
              label: `${fullCode} - ${description}`,
              value: fullCode,
            };
            csiCodesFormatted.push(csiCodeFormatted);
          });
        });
      });
      setCsiCodes(csiCodesFormatted);
      setDivisionCodes(divisionsFormatted);
    }
  }, [financialsConfiguration]);

  useEffect(() => {
    if (systemConfiguration?.system?.unitsOfMeasure) {
      const lengthOptions =
        systemConfiguration?.system?.unitsOfMeasure?.length_area
          ?.filter((uom) => uom.selected)
          ?.map((uom) => ({ label: uom.display, value: uom.id }));
      const qtyOptions = systemConfiguration?.system?.unitsOfMeasure?.quantity
        ?.filter((uom) => uom.selected)
        ?.map((uom) => ({ label: uom.display, value: uom.id }));
      const timeOptions = systemConfiguration?.system?.unitsOfMeasure?.time
        ?.filter((uom) => uom.selected)
        ?.map((uom) => ({ label: uom.display, value: uom.id }));
      const volumeOptions =
        systemConfiguration?.system?.unitsOfMeasure?.volume_weight
          ?.filter((uom) => uom.selected)
          ?.map((uom) => ({ label: uom.display, value: uom.id }));

      const groupedOptions = [
        {
          label: "Length/Area",
          options: lengthOptions,
        },
        {
          label: "Quantity",
          options: qtyOptions,
        },
        {
          label: "Time",
          options: timeOptions,
        },
        {
          label: "Volume/Weight",
          options: volumeOptions,
        },
      ];

      setUnitsOfMeasure([
        ...lengthOptions,
        ...qtyOptions,
        ...timeOptions,
        ...volumeOptions,
      ]);
      setGroupedUnitsOfMeasure(groupedOptions);
    }
  }, [systemConfiguration]);

  useEffect(() => {
    if (Object.keys(usersDict || {})?.length) {
      const memberCompanies =
        project?.members
          ?.map((member) => {
            const companyRef = usersDict[member?.user]?.company?.value;
            return {
              label: usersDict[companyRef]?.companyName,
              value: companyRef,
            };
          })
          ?.filter((member) => !!member?.value) || [];

      memberCompanies.unshift({
        label: "Create New...",
        value: "createNewVendor",
      });

      setVendors(uniqBy(memberCompanies, "value"));
    }
  }, [project, usersDict]);

  // useEffect(() => {
  //   if (project?.buildings?.length) {
  //     const buildings = project.buildings.map((building) => {
  //       const build = {
  //         label: building.name,
  //         value: building.id,
  //         spaces: [
  //           {
  //             label: "Create New...",
  //             value: "createNewSpace",
  //           },
  //         ],
  //       };

  //       if (building.spaces.length) {
  //         building.spaces.map((space) => {
  //           const buildSpace = {
  //             label: space.name,
  //             value: space.id,
  //           };
  //           build.spaces.push(buildSpace);
  //           return space;
  //         });
  //       }

  //       return build;
  //     });

  //     setProjectBuildings([
  //       {
  //         label: "Create New...",
  //         value: "createNewBuilding",
  //         spaces: [],
  //       },
  //       ...buildings,
  //     ]);
  //   } else {
  //     setProjectBuildings([
  //       {
  //         label: "Create New...",
  //         value: "createNewBuilding",
  //         spaces: [],
  //       },
  //     ]);
  //   }
  // }, [project]);

  useEffect(() => {
    const getComments = async () => {
      if (selectedLineItemForComments) {
        setIsLoadingComments(true);
        try {
          const { data } = await CommentAPI.get({
            params: {
              association: selectedLineItemForComments,
            },
          });

          const formatedComments =
            data?.map(async (info) => {
              const user = getUserData(users, info?.author);
              const formatedReplies =
                info?.replies?.map((reply) => ({
                  ...reply,
                  userData: getUserData(users, reply?.author),
                })) || [];
              const replies = await Promise.all(formatedReplies);
              return {
                ...info,
                userData: user,
                replies,
              };
            }) || [];
          const allComments = await Promise.all(formatedComments);

          setCommentsData(allComments);
        } catch (err) {
          toastError(
            `Error fetching Comment: ${err?.response?.data?.issues[0]?.detail?.display}`
          );
        }
        setIsLoadingComments(false);
      }
    };

    if (selectedLineItemForComments) {
      getComments();
    } else {
      setCommentsData([]);
    }
  }, [selectedLineItemForComments, users]);

  useEffect(() => {
    const data = [];
    budgetLineItems?.map((li) => {
      const dataWithVersions = {
        ...li,
        // buildingName: projectBuildings?.find(
        //   (loc) => loc?.value === li.building
        // )?.label,
        vendorName: vendors?.find((vendor) => vendor?.value === li.vendor)
          ?.label,
        // spaceName: projectBuildings
        //   ?.find((build) => build.value === li?.building)
        //   ?.spaces?.find((sp) => sp.value === li?.space)?.label,
      };

      if (lineitemVersions[li.id]) {
        dataWithVersions.subRows = [];
        lineitemVersions[li.id].map((ver) => {
          const version = formatLineitem(
            ver,
            financialsConfiguration?.financials?.divisionsObject,
            financialsConfiguration?.financials?.csiCodeMappingObject,
            budgetGroups
          );
          dataWithVersions.subRows.push({
            ...version,
            // buildingName: projectBuildings?.find(
            //   (loc) => loc?.value === version.building
            // )?.label,
            vendorName: vendors?.find(
              (vendor) => vendor?.value === version.vendor
            )?.label,
            // spaceName: projectBuildings
            //   ?.find((build) => build.value === version?.building)
            //   ?.spaces?.find((sp) => sp.value === version?.space)?.label,
          });
          return ver;
        });
      }

      data.push(dataWithVersions);

      return li;
    });
    setTableData(pullSummary ? [] : data);
  }, [
    budgetGroups,
    budgetLineItems,
    divisionCodes,
    financialsConfiguration,
    lineitemVersions,
    // projectBuildings,
    vendors,
    pullSummary,
  ]);

  const toggleLowRange = (rowId, isChecked) => {
    setBudgetLineItems((prev) =>
      prev.map((li) => {
        if (li.id === rowId) {
          return {
            ...li,
            isLowRangeSelected: isChecked,
            isHighRangeSelected: !isChecked,
          };
        }
        return li;
      })
    );
  };

  const toggleHighRange = (rowId, isChecked) => {
    setBudgetLineItems((prev) =>
      prev.map((li) => {
        if (li.id === rowId) {
          return {
            ...li,
            isHighRangeSelected: isChecked,
            isLowRangeSelected: !isChecked,
          };
        }
        return li;
      })
    );
  };

  const toggleAllHighRange = (isChecked) => {
    setIsAllHighRangeChecked(isChecked);
    setBudgetLineItems((prev) =>
      prev.map((li) => {
        return {
          ...li,
          isHighRangeSelected: isChecked,
          isLowRangeSelected: !isChecked,
        };
      })
    );
  };
  const toggleAllLowRange = (isChecked) => {
    setIsAllLowRangeChecked(isChecked);
    setBudgetLineItems((prev) =>
      prev.map((li) => {
        return {
          ...li,
          isLowRangeSelected: isChecked,
          isHighRangeSelected: !isChecked,
        };
      })
    );
  };

  const downloadReport = useCallback(() => {
    const { formattedLineItems, columns } = getRowsAndColumsForCsv({
      originalBudgetLineItems,
      project,
      budgetType,
      unitsOfMeasure,
    });

    json2csv(
      formattedLineItems,
      async (err, csv) => {
        if (!err) {
          const blob = new Blob([csv], {
            type: "text/csv;charset=utf-8;",
          });

          const url = URL.createObjectURL(blob);
          const downloadLink = document.createElement("a");
          downloadLink.style.display = "none";

          document.body.appendChild(downloadLink);

          downloadLink.setAttribute("href", url);
          downloadLink.setAttribute("download", "Report");
          downloadLink.click();

          document.body.removeChild(downloadLink);
          URL.revokeObjectURL(url);
        }
      },
      {
        keys: columns,
        emptyFieldValue: "",
      }
    );
  }, [budgetType, originalBudgetLineItems, project, unitsOfMeasure]);

  const publishBudget = useCallback(() => {
    const savingToast = toast("Publishing Budget...", {
      isLoading: true,
      position: "top-center",
    });

    const { formattedLineItems, columns } = getRowsAndColumsForCsv({
      originalBudgetLineItems,
      project,
      budgetType,
      unitsOfMeasure,
    });

    json2csv(
      formattedLineItems,
      async (err, csv) => {
        if (!err) {
          const blob = new Blob([csv], {
            type: "text/csv;charset=utf-8;",
          });

          const fileRef = await uploadFile(
            blob,
            null,
            undefined,
            true,
            null,
            "PublishedBudget"
          );

          try {
            const { data } = await BudgetAPI.postWOP(`${budgetId}/$publish`, {
              lastUpdated: budgetLastUpdated,
              file: fileRef,
            });

            setBudgetLineItems(
              data.lineItems.map((li) =>
                formatLineitem(
                  li,
                  financialsConfiguration?.financials?.divisionsObject,
                  financialsConfiguration?.financials?.csiCodeMappingObject,
                  budgetGroups
                )
              )
            );

            appDispatch({
              type: SET_BUDGET_LAST_UPDATED,
              budgetLastUpdated: data.metadata.lastUpdated,
            });

            toast.update(savingToast, {
              isLoading: false,
              render: `Successfully Published Budget Version ${data?.lastPublishedVersion}`,
              closeButton: toastCloseIcon,
              className: "bg-brandGreen text-white",
              hideProgressBar: true,
              position: "top-center",
              icon: toastIcon,
              autoClose: 3000,
            });

            addDocument(data?.document);
            queryClient.invalidateQueries(budgetLineitemKeys.budgetLineitems);
            reload();
          } catch (e) {
            toast.update(savingToast, {
              isLoading: false,
              render: `Error publishing Budget: ${e}}`,
              style: {
                backgroundColor: "#BC2727",
                color: "white",
              },
              closeButton: toastCloseIcon,
              position: "top-center",
              hideProgressBar: true,
              icon: toastErrorIcon,
              autoClose: 3000,
            });
            await FileAPI.delete(fileRef.split("/")[1]);
          }
        } else {
          toast.update(savingToast, {
            isLoading: false,
            render: `Error publishing Budget: ${JSON.stringify(err)}`,
            style: {
              backgroundColor: "#BC2727",
              color: "white",
            },
            closeButton: toastCloseIcon,
            position: "top-center",
            hideProgressBar: true,
            icon: toastErrorIcon,
            autoClose: 3000,
          });
        }
      },
      {
        keys: columns,
        emptyFieldValue: "",
      }
    );
  }, [
    addDocument,
    appDispatch,
    budgetGroups,
    budgetId,
    budgetLastUpdated,
    budgetType,
    financialsConfiguration?.financials?.csiCodeMappingObject,
    financialsConfiguration?.financials?.divisionsObject,
    originalBudgetLineItems,
    project,
    reload,
    unitsOfMeasure,
  ]);

  const updateUserTemplateSettings = useCallback(
    async (customViews) => {
      update({
        key: "project_budget",
        updatedTemplates: [
          ...templateSettings.filter((temp) => !temp.custom),
          ...customViews.map((view) => {
            // eslint-disable-next-line no-param-reassign
            view.custom = true;
            return view;
          }),
        ],
      });
    },
    [update, templateSettings]
  );

  const deleteUserTemplateSettings = useCallback(
    async (template) => {
      deleteTemplate({
        key: "project_budget",
        id: template.id,
      });
    },
    [deleteTemplate]
  );

  const versionCloseCallback = useCallback(
    (lineitemId) => {
      setLineitemVersions(omit(lineitemVersions, lineitemId));
    },
    [lineitemVersions]
  );

  const confirmLineitemUnlock = useCallback(async () => {
    try {
      await LineitemAPI.getWOP(`${unlockLineitem}/$unlock?override=true`);
      toastMessage("Successfully unlocked line item");
      setBudgetLineItems((prev) =>
        prev.map((li) => {
          if (li.id === unlockLineitem) {
            return {
              ...li,
              lock: {
                ...li.lock,
                lockedBy: {
                  ...li.lockedBy,
                  display: "",
                  reference: "",
                },
              },
            };
          }
          return li;
        })
      );
    } catch (e) {
      toastError(
        `Error unlocking line item: ${e?.response?.data?.issues[0]?.detail?.display}`
      );
    }
    setUnlockLineitem(null);
    setUnlockLineitemUser(null);
  }, [unlockLineitem]);

  const rowAssociationMenuList = useMemo(() => {
    const isInvoiceNotSubmitted = ["draft"].includes(
      projectClientInovice?.status
    );
    return [
      ...(!isLockGmp &&
      !isLockFixedFirm &&
      !disbaleRowActions &&
      isInvoiceNotSubmitted
        ? [
            {
              title: "Delete",
              className: menuButtonCN,
              isDeleteOption: true,
            },
          ]
        : []),
      ...(!isLockGmp &&
      !isLockFixedFirm &&
      !disbaleRowActions &&
      isInvoiceNotSubmitted
        ? [
            {
              title: "Edit",
              className: menuButtonCN,
              isEditOption: true,
            },
          ]
        : []),
      ...(!disbaleRowActions
        ? [
            {
              title: "History",
              className: menuButtonCN,
              clearTemplateOnClick: true,
              onClick: async (lineitemId, toggleRowExpanded) => {
                const { data } = await LineitemAPI.getWOP(
                  `${lineitemId}/$getversions`
                );

                if (data.versions.length > 1) {
                  let date;
                  const lineItemVersions = data.versions.map((v, index) => {
                    // to show date header only once in table for versions that have same year/month/date
                    const showDate =
                      !date || !moment(date).isSame(v.modifiedDate, "day");

                    const lineItemVersion = {
                      ...v,
                      id: uuidv4(),
                      parentIdOfVersion: data.id,
                      showDate,
                      showButton: index === data.versions.length - 1,
                      isParent: index === 0, // set this so the first version (parent) can be used to edit parent row
                    };
                    date = v.modifiedDate;

                    return lineItemVersion;
                  });
                  setLineitemVersions({
                    ...lineitemVersions,
                    [lineitemId]: lineItemVersions,
                  });

                  toggleRowExpanded(lineitemId, true);
                }
              },
            },
          ]
        : []),
      ...(!disbaleRowActions && isInvoiceNotSubmitted
        ? [
            {
              title: "Holdback",
              className: menuButtonCN,
              onClick: async (lineitemId) => {
                const lidata = budgetLineItems.find(
                  (li) => li.id === lineitemId
                );

                dispatchHoldback({
                  type: "initialize",
                  lineitemId,
                  paidToDate: formatNumber(lidata.paidToDate),
                  workCompletedThisPeriod: formatNumber(
                    lidata.workCompletedThisPeriod
                  ),
                  holdbackAmount: lidata.holdback
                    ? parseFloat(lidata.holdback)
                    : null,
                  originalLineitem: lidata,
                });
                setShowHoldbackModal(true);
              },
            },
          ]
        : []),

      ...((isLockGmp || isLockFixedFirm) &&
      !reallocation?.reallocateFromLineitem &&
      !disbaleRowActions &&
      isInvoiceNotSubmitted
        ? [
            {
              title: "Reallocate",
              className: menuButtonCN,
              onClick: async (lineitemId) => {
                const lineitem = budgetLineItems.find(
                  (li) => li.id === lineitemId
                );
                dispatchForm({
                  type: "reallocateFromLineitem",
                  reallocateFromLineitem: lineitem?.id,
                  reallocateTotalAmount: lineitem?.uncommittedValue,
                });
                toast.info(
                  ({ closeToast }) => (
                    <BudgetTableReallocationToast
                      onCancel={() => {
                        dispatchForm({
                          type: "resetReallocateLineitems",
                        });
                        closeToast();
                      }}
                    />
                  ),
                  {
                    toastId: "reallocation-toast",
                    position: "top-center",
                    autoClose: false,
                    closeButton: false,
                    closeOnClick: false,
                    hideProgressBar: true,
                    className: "bg-white text-gray-400 px-2 py-2 mb-0",
                    style: { minHeight: "43px", top: "29px" },
                    icon: false,
                  }
                );
              },
            },
          ]
        : []),
      ...((budgetType?.label === budgetTypeMap.costPlus ||
        budgetType?.label === budgetTypeMap.fixedFirm) &&
      !disbaleRowActions &&
      isInvoiceNotSubmitted
        ? [
            {
              title: "Split Line",
              className: menuButtonCN,
              isSplitLineOption: true,
            },
          ]
        : []),
      // show this option even if disbaleRowActions===true
      ...(isInvoiceNotSubmitted
        ? [
            {
              title: "Update Vendor",
              className: menuButtonCN,
              onClick: async (lineitemId) => {
                const lidata = budgetLineItems.find(
                  (li) => li.id === lineitemId
                );
                if (lidata.rate) {
                  toastError(
                    `Cannot update vendor as lineitem already has a rate.`
                  );
                } else {
                  dispatchVendor({
                    type: "initialize",
                    lineitemId,
                    vendor: lidata.vendor,
                    originalLineItem: lidata,
                  });
                  setShowUpdateVendorModal(true);
                }
              },
            },
          ]
        : []),

      ...(currentUser?.isAdmin || currentUser?.isSuperAdmin
        ? [
            {
              title: "Unlock",
              className: menuButtonCN,
              showForLineitemsLockedByUser: true,
              onClick: async (lineitemId) => {
                setUnlockLineitem(lineitemId);
                setUnlockLineitemUser(
                  budgetLineItems.find((li) => li.id === lineitemId)?.lock
                    ?.lockedBy?.display
                );
              },
            },
          ]
        : []),
    ];
  }, [
    budgetLineItems,
    budgetType,
    currentUser,
    disbaleRowActions,
    dispatchForm,
    dispatchHoldback,
    dispatchVendor,
    isLockFixedFirm,
    isLockGmp,
    lineitemVersions,
    reallocation,
    projectClientInovice,
  ]);

  const onAddBudgetGroup = useCallback(
    async (group) => {
      try {
        const { data } = await BudgetAPI.postWOP(`${budgetId}/$addgroup`, {
          name: group?.label,
          lastUpdated: budgetLastUpdated,
        });

        const groups = formatGroupDropdownForBudgetTable(data.groups);

        dispatchAppState({
          type: SET_BUDGET_GROUPS,
          budgetGroups: groups,
          budgetLastUpdated: data?.metadata?.lastUpdated,
        });

        toastMessage("Successfully add Group");
      } catch (e) {
        toastError(
          `Error adding Group: ${e?.response?.data?.issues[0]?.detail?.display}`
        );
      }
    },
    [budgetId, budgetLastUpdated, dispatchAppState]
  );

  const onEditBudgetGroup = useCallback(
    async (group) => {
      try {
        const { data } = await BudgetAPI.postWOP(`${budgetId}/$renamegroup`, {
          name: group?.label,
          id: group?.value,
          lastUpdated: budgetLastUpdated,
        });

        const groups = formatGroupDropdownForBudgetTable(data.groups);

        dispatchAppState({
          type: SET_BUDGET_GROUPS,
          budgetGroups: groups,
          budgetLastUpdated: data?.metadata?.lastUpdated,
        });

        toastMessage("Successfully edited Group");
      } catch (e) {
        toastError(
          `Error editing Group: ${e?.response?.data?.issues[0]?.detail?.display}`
        );
      }
    },
    [budgetId, budgetLastUpdated, dispatchAppState]
  );

  const onDeleteBudgetGroup = useCallback(
    async (group) => {
      try {
        const { data } = await BudgetAPI.postWOP(`${budgetId}/$removegroup`, {
          name: group?.label,
          id: group?.value,
          lastUpdated: budgetLastUpdated,
        });

        const groups = formatGroupDropdownForBudgetTable(data.groups);

        dispatchAppState({
          type: SET_BUDGET_GROUPS,
          budgetGroups: groups,
          budgetLastUpdated: data?.metadata?.lastUpdated,
        });

        toastMessage("Successfully removed Group");
      } catch (e) {
        toastError(
          `Error removing Group: ${e?.response?.data?.issues[0]?.detail?.display}`
        );
      }
    },
    [budgetId, budgetLastUpdated, dispatchAppState]
  );

  const onEditSelectedSave = async (budgetLine, adjustTotal) => {
    setEditSelectedModal(false);
    const cleanObj = cleanFieldsForBulkUpdate(budgetLine, adjustTotal);

    try {
      const { data } = await BudgetAPI.postWOP(
        `${budgetId}/$bulkupdate`,
        {
          lineitemReferences: selectedRows?.map((row) => row.reference),
          updatedFields: cleanObj,
        },
        {
          params: {
            lastUpdated: budgetLastUpdated,
          },
        }
      );

      toastMessage(`Successfully updated ${data.updatedCount} lineitem(s)`);

      setBudgetLineItems((prevLineitems) => {
        return prevLineitems.map((li) => {
          if (data.updatedLineItems[li.id]) {
            const updatedLineitem = formatLineitem(
              data.updatedLineItems[li.id],
              financialsConfiguration?.financials?.divisionsObject,
              financialsConfiguration?.financials?.csiCodeMappingObject,
              budgetGroups
            );
            return updatedLineitem;
          }
          return li;
        });
      });

      appDispatch({
        type: SET_BUDGET_LAST_UPDATED,
        budgetLastUpdated: data.metadata.lastUpdated,
      });
    } catch (e) {
      toastError(
        `Error updating selected lineitems: ${e?.response?.data?.issues[0]?.detail?.display}`
      );
    }
  };

  const onAddSaveCallback = useCallback(
    async (row, budgetLine, adjustTotal) => {
      const insertLineItem = {
        ...budgetLine,
      };

      if (
        insertLineItem?.adjustment?.amount === "" ||
        insertLineItem?.adjustment?.amount === "0" ||
        !insertLineItem?.adjustment?.adjustmentUnit ||
        !insertLineItem?.adjustment?.arithmeticUnit ||
        !adjustTotal
      ) {
        insertLineItem.adjustment = {
          amount: 0,
          adjustmentUnit: undefined,
          arithmeticUnit: undefined,
        };
      }

      const budgetLineItem = { ...insertLineItem };

      let newLastUpdated;
      if (budgetLineItem?.group?.value) {
        budgetLineItem.group = budgetLineItem?.group?.value;
      }
      try {
        const { data } = await BudgetAPI.postWOP(
          `${budgetId}/$addlineitem`,
          budgetLineItem,
          {
            params: {
              position: row.index + 2 || 1,
              lastUpdated: newLastUpdated || budgetLastUpdated,
            },
          }
        );
        insertLineItem.id = data.id;

        budgetLineItems.splice(row.index + 1 || 1, 0, insertLineItem);

        appDispatch({
          type: SET_BUDGET_LINE_ITEMS,
          budgetLineItems,
        });

        queryClient.invalidateQueries(budgetLineitemKeys.budgetLineitems);
        reload();
        reloadRates(`Project/${projectId}`);
        toastMessage("Successfully added a lineitem");
      } catch (e) {
        toastError(
          `Error adding line item: ${e?.response?.data?.issues[0]?.detail?.display}`
        );
      }
    },
    [
      appDispatch,
      budgetId,
      budgetLastUpdated,
      budgetLineItems,
      projectId,
      reload,
      reloadRates,
    ]
  );

  const onSplitLineSaveCallback = useCallback(
    async (row, budgetLine, adjustTotal) => {
      const rowIndex = row.index;
      const current = budgetLineItems[rowIndex];

      let insertLineItem = {
        ...budgetLine,
        uncommittedValue: budgetLine?.splitValue,
      };

      if (
        insertLineItem?.adjustment?.amount === "" ||
        insertLineItem?.adjustment?.amount === "0" ||
        !insertLineItem?.adjustment?.adjustmentUnit ||
        !insertLineItem?.adjustment?.arithmeticUnit ||
        !adjustTotal
      ) {
        insertLineItem.adjustment = {
          amount: 0,
          adjustmentUnit: undefined,
          arithmeticUnit: undefined,
        };
      }

      if (insertLineItem?.group?.value) {
        insertLineItem.group = insertLineItem?.group?.value;
      }

      try {
        const { data } = await BudgetAPI.postWOP(
          `${budgetId}/$splitline`,
          {
            originalId: current.id,
            amount: budgetLine?.splitValue,
            newLineItem: insertLineItem,
          },
          {
            params: {
              position: row.index + 2 || 1,
              lastUpdated: budgetLastUpdated,
            },
          }
        );

        insertLineItem = formatLineitem(
          data?.newLineItem,
          financialsConfiguration?.financials?.divisionsObject,
          financialsConfiguration?.financials?.csiCodeMappingObject,
          budgetGroups
        );

        setBudgetLineItems((prevLineitems) => {
          prevLineitems.splice(row.index + 1 || 1, 0, insertLineItem);
          return prevLineitems.map((li, index) => {
            if (index === rowIndex) {
              const updatedParentLineitem = formatLineitem(
                data?.originalLineItem,
                financialsConfiguration?.financials?.divisionsObject,
                financialsConfiguration?.financials?.csiCodeMappingObject,
                budgetGroups
              );
              return updatedParentLineitem;
            }
            return li;
          });
        });

        appDispatch({
          type: SET_BUDGET_LAST_UPDATED,
          budgetLastUpdated: data.metadata.lastUpdated,
        });

        queryClient.invalidateQueries(budgetLineitemKeys.budgetLineitems);
        reload();
        reloadRates(`Project/${projectId}`);
      } catch (e) {
        toastError(
          `Error splitting line item: ${e?.response?.data?.issues[0]?.detail?.display}`
        );
      }
    },
    [
      budgetLineItems,
      budgetId,
      budgetLastUpdated,
      financialsConfiguration?.financials?.divisionsObject,
      financialsConfiguration?.financials?.csiCodeMappingObject,
      budgetGroups,
      appDispatch,
      reload,
      reloadRates,
      projectId,
    ]
  );

  const onEditSaveCallback = useCallback(
    async (row, budgetLine, adjustTotal) => {
      const rowIndex = row.index;
      const current = budgetLineItems[rowIndex];
      const budgetLineItem = { ...budgetLine };
      // if version was passed, set id to parent id
      if (budgetLineItem.isParent) {
        budgetLineItem.id = budgetLineItem.parentIdOfVersion;
        delete budgetLineItem.parentIdOfVersion;
        delete budgetLineItem.isParent;
        delete budgetLineItem.modifiedDate;
      }

      if (
        budgetLineItem?.adjustment?.amount === "" ||
        budgetLineItem?.adjustment?.amount === "0" ||
        !budgetLineItem?.adjustment?.adjustmentUnit ||
        !budgetLineItem?.adjustment?.arithmeticUnit ||
        !adjustTotal
      ) {
        budgetLineItem.adjustment = {
          amount: 0,
          adjustmentUnit: undefined,
          arithmeticUnit: undefined,
        };
      }

      budgetLineItem.paid = 0;
      budgetLineItem.billed = 0;
      delete budgetLineItem.subRows;

      setBudgetLineItems((prevBudgets) =>
        prevBudgets.map((budget, index) => {
          if (index === rowIndex) {
            return {
              ...budgetLineItem,
            };
          }
          return budget;
        })
      );

      budgetLineItem.group = budgetLineItem.group?.value;

      if (!isEqual(current, budgetLineItem)) {
        try {
          await LineitemAPI.patch(budgetLineItem.id, budgetLineItem, current);
          queryClient.invalidateQueries(budgetLineitemKeys.budgetLineitems);
          reload();
          reloadRates(`Project/${projectId}`);
          toastMessage("Successfully updated lineitem");
        } catch (e) {
          console.error(e);
          toastError(
            `Error updating lineitem ${e?.response?.data?.issues[0]?.detail?.display}`
          );
        }
      }
    },
    [budgetLineItems, projectId, reload, reloadRates]
  );

  const onCancelSaveCallback = () => {
    appDispatch({
      type: SET_BUDGET_LINE_ITEMS,
      budgetLineItems,
    });
  };

  const onFavoriteClick = async (rowId) => {
    const current = budgetLineItems?.find((li) => li.id === rowId);
    const budgetLineItem = { ...current };
    budgetLineItem.flag = !budgetLineItem.flag;

    setBudgetLineItems((prev) =>
      prev.map((li) => {
        if (li.id === rowId) {
          return budgetLineItem;
        }
        return li;
      })
    );

    try {
      await LineitemAPI.patch(budgetLineItem.id, budgetLineItem, current);
    } catch (e) {
      console.error(e);
    }

    queryClient.invalidateQueries(budgetLineitemKeys.budgetLineitems);
    await reload();
  };

  const onDeleteRowClick = useCallback(
    async (budgetIdToDelete) => {
      try {
        const { data } = await LineitemAPI.getById(budgetIdToDelete);
        if (data?.lock?.lockedBy?.reference) {
          toastError("Line item is already locked");
        } else {
          let position = 0;
          setBudgetLineItems((prevBudgets) =>
            prevBudgets.filter((b, idx) => {
              if (b.id !== budgetIdToDelete) {
                return true;
              }
              position = idx;
              return false;
            })
          );

          await BudgetAPI.delete(`${budgetId}\\$deletelineitem`, {
            params: `position=${
              // TODO: fix position when api is fixed
              position + 1
            }&lastUpdated=${new Date().toISOString()}`,
          });

          queryClient.invalidateQueries(budgetLineitemKeys.budgetLineitems);
          reload();
          reloadRates();
        }
      } catch (e) {
        toastError(
          `Error deleting line item: ${e?.response?.data?.issues[0]?.detail?.display}`
        );
      }
    },
    [budgetId, reload, reloadRates]
  );

  const onEditRowCancelClick = useCallback(
    (row) => {
      const { index: resetIndex } = row;
      setBudgetLineItems((prevBudgets) =>
        prevBudgets.map((budget, index) => {
          if (index === resetIndex) {
            return originalBudgetLineItems[index];
          }
          return budget;
        })
      );
    },
    [originalBudgetLineItems]
  );

  const groupByCallback = useCallback(
    (reduced, columnId) => {
      const manualReduced = { ...reduced };
      let list = [];

      switch (columnId) {
        case "csiCodeDivision": {
          list = divisionCodes;
          break;
        }
        // case "buildingName": {
        //   list = projectBuildings.filter(
        //     (building) => building.value !== "createNewBuilding"
        //   );
        //   break;
        // }
        default: {
          list = [];
        }
      }

      list.map((item) => {
        if (!manualReduced[item.label]) {
          if (columnId === "buildingName") {
            manualReduced[item.label] = [
              {
                id: item.label,
                isManualGrouped: true,
                original: {
                  [columnId]: item.label,
                  building: item.value,
                },
                values: {
                  [columnId]: item.label,
                  building: item.value,
                },
              },
            ];
          } else {
            manualReduced[item.label] = [
              {
                id: item.label,
                isManualGrouped: true,
                original: {
                  [columnId]: item.label,
                },
                values: {
                  [columnId]: item.label,
                },
              },
            ];
          }
        }
        return item;
      });

      return manualReduced;
    },
    [
      divisionCodes,
      // projectBuildings,
    ]
  );

  const updateBudgetTypeToCostPlus = useCallback(async () => {
    try {
      const { data } = await BudgetAPI.patch(
        budgetId,
        { budgetType: BUDGET_COST_PLUS },
        { budgetType: budgetType?.value },
        { date: budgetLastUpdated }
      );
      appDispatch({
        type: SET_BUDGET_TYPE,
        budgetType: BUDGET_COST_PLUS,
        budgetLastUpdated: data.metadata.lastUpdated,
      });

      toastMessage("Successfully converted to Cost Plus");

      queryClient.invalidateQueries(budgetLineitemKeys.budgetLineitems);
      reload();
    } catch (err) {
      toastError(
        `Error converting to Cost Plus: ${err?.response?.data?.issues[0]?.detail?.display}`
      );
    }
  }, [appDispatch, budgetId, budgetLastUpdated, budgetType?.value, reload]);

  const getBudgetCurrentStatus = useCallback(async () => {
    try {
      const { data } = await BudgetAPI.getWOP(`${budgetId}/$summary`);

      if (!moment(data.metadata.lastUpdated).isSame(budgetLastUpdated)) {
        return {
          isValid: false,
          error:
            "Budget was recently updated. Please reload the screen to view the updated Budget.",
        };
      }

      if (data.isLockGmp || data.isLockFixedFirm) {
        return {
          isValid: false,
          error:
            "Budget is locked! Please reload the screen to view the updated Budget.",
        };
      }

      if (data.lineItemsLocked !== 0) {
        return {
          isValid: false,
          error: `${data.lineItemsLocked} line items are locked.`,
        };
      }
      return { isValid: true };
    } catch (e) {
      console.log(e);
      return {
        isValid: false,
        error: "Failed to check budget status. Please reload the screen.",
      };
    }
  }, [budgetId, budgetLastUpdated]);

  const handleSendToWorkflow = useCallback(async () => {
    dispatchAppState({
      type: SET_WORKFLOW_MODAL_STATUS,
      association: `Project/${projectId}`,
      open: true,
      documents: [projectClientInovice.reference],
    });
  }, [dispatchAppState, projectClientInovice, projectId]);

  const getButtonActions = useCallback(() => {
    const options = [];

    const isCostPlusBudgetType = budgetType?.label === budgetTypeMap.costPlus;
    const isFixedFirmBudgetType = budgetType?.label === budgetTypeMap.fixedFirm;
    const isTimeAndMaterialsBudgetType =
      budgetType?.label === budgetTypeMap.timeAndMaterials;
    const isInvoiceNotSubmitted = ["draft"].includes(
      projectClientInovice?.status
    );

    if (report) return () => downloadReport();

    if (showClientInvoice) {
      if (["draft"].includes(projectClientInovice?.status)) {
        options.push({
          title: "Send to Workflow",
          onClick: async () => {
            handleSendToWorkflow();
          },
        });
      }

      return options;
    }

    if (
      !isDraftProject &&
      currentUser?.hasPermission?.("document", "can_write") &&
      isInvoiceNotSubmitted
    ) {
      options.push({
        title: "Create Financial Document",
        onClick: () =>
          history.push(GET_PROJECT_BUDGET_CREATE_DOCUMENT_PATH(projectId), {
            isFlowFromBudgetTable: true,
          }),
      });
    }
    if (
      !isDraftProject &&
      !isLockGmp &&
      !isLockFixedFirm &&
      isInvoiceNotSubmitted
    ) {
      options.push({
        title: "Publish Budget",
        onClick: async () => {
          const { isValid, error } = await getBudgetCurrentStatus();
          if (isValid) {
            setShowPublishBudgetModal(true);
          } else {
            toastError(error);
          }
        },
      });
    }

    if (isCostPlusBudgetType && !isLockGmp && isInvoiceNotSubmitted) {
      options.push({
        title: "Lock GMP",
        onClick: async () => {
          const { isValid, error } = await getBudgetCurrentStatus();
          if (isValid) {
            setShowLockGMPModal(true);
          } else {
            toastError(error);
          }
        },
      });
    }

    if (isFixedFirmBudgetType && !isLockFixedFirm && isInvoiceNotSubmitted) {
      options.push({
        title: "Lock Fixed Firm",
        onClick: async () => {
          const { isValid, error } = await getBudgetCurrentStatus();
          if (isValid) {
            setShowLockFixedFirmModal(true);
          } else {
            toastError(error);
          }
        },
      });
    }

    if (isTimeAndMaterialsBudgetType && isInvoiceNotSubmitted) {
      options.push({
        title: "Convert to Cost Plus",
        onClick: () => updateBudgetTypeToCostPlus(),
      });
    }
    if (
      selectedRows.length > 1 &&
      !isDraftProject &&
      !isLockGmp &&
      !isLockFixedFirm &&
      isInvoiceNotSubmitted
    ) {
      options.push({
        title: "Edit Selected",
        onClick: async () => {
          setEditSelectedModal(true);
        },
      });
    }

    if (!isDraftProject && !!projectClientInovice) {
      options.push({
        title: "Go to Invoice",
        onClick: async () => {
          setShowClientInvoice(true);
        },
      });
    }

    return options;
  }, [
    budgetType?.label,
    report,
    showClientInvoice,
    isDraftProject,
    currentUser,
    isLockGmp,
    isLockFixedFirm,
    selectedRows.length,
    projectClientInovice,
    downloadReport,
    handleSendToWorkflow,
    history,
    projectId,
    getBudgetCurrentStatus,
    updateBudgetTypeToCostPlus,
  ]);

  useEffect(() => {
    setButtonActions(getButtonActions());
  }, [setButtonActions, getButtonActions]);

  const handlePostComment = useCallback(
    async (value) => {
      try {
        if (value.trim().length > 0) {
          const commentObj = {
            association: selectedLineItemForComments,
            content: value,
            author: currentUser?.reference,
          };
          const { data: newComment } = await CommentAPI.post(commentObj);
          newComment.userData = currentUser;
          newComment.replies = [];
          setCommentsData((prev) => [...prev, newComment]);
        }
      } catch (error) {
        toastError(
          `Error posting Comment: ${error?.response?.data?.issues[0]?.detail?.display}`
        );
      }
    },
    [currentUser, selectedLineItemForComments]
  );

  const handlePostReply = useCallback(
    async (value, commentId) => {
      if (value.trim().length > 0) {
        const tempObj = {
          content: value,
          author: currentUser.reference,
        };
        const { data: reply } = await CommentAPI.postWOP(
          `${commentId}/$addreply`,
          {
            ...tempObj,
          }
        );

        const replyComment = {
          ...reply,
          userData: currentUser,
          replies: [],
        };

        const updatedComments = commentsData.map((comment) => {
          if (comment.id === commentId) {
            return { ...comment, replies: [...comment.replies, replyComment] };
          }
          return comment;
        });

        setCommentsData((prev) => updatedComments ?? prev);
      }
    },
    [commentsData, currentUser]
  );

  const onSaveProjectLocation = useCallback(
    async (projectLocation) => {
      const finishedResource = {
        ...project,
        buildings: [...project?.buildings, projectLocation],
      };

      try {
        const { data } = await ProjectAPI.patch(
          projectId,
          finishedResource,
          project
        );
        /**
         * TODO: this project state change is causing the issue;
         * its closing the popover if trying to add the first row in the fin code group
         */
        setProject((prev) => ({
          primaryImage: prev?.primaryImage,
          ...data,
        }));

        toastMessage("Successfully added Location");
      } catch (e) {
        toastError(e?.response?.data?.issues[0]?.location);
      }
    },
    [project, projectId]
  );

  const onSaveProjectLocationSpace = useCallback(
    async (buildingId, projectLocationSpace) => {
      const finishedResource = {
        ...project,
        buildings: project.buildings.map((building) => {
          if (building.id === buildingId) {
            return {
              ...building,
              spaces: [...building?.spaces, projectLocationSpace],
            };
          }
          return building;
        }),
      };

      try {
        const { data } = await ProjectAPI.patch(
          projectId,
          finishedResource,
          project
        );
        /**
         * TODO: this project state change is causing the issue;
         * its closing the popover if trying to add the first row in the fin code group
         */
        setProject((prev) => ({
          primaryImage: prev?.primaryImage,
          ...data,
        }));

        toastMessage("Successfully added Space");
      } catch (e) {
        toastError(e?.response?.data?.issues[0]?.location);
      }
    },
    [project, projectId]
  );

  const confirmPublishBudget = () => {
    publishBudget();
    setShowPublishBudgetModal(false);
  };

  const confirmLockGMP = useCallback(async () => {
    const savingToast = toast("Locking GMP...", {
      isLoading: true,
      position: "top-center",
    });
    setShowLockGMPModal(false);

    const { formattedLineItems, columns } = getRowsAndColumsForCsv({
      originalBudgetLineItems,
      project,
      budgetType,
      unitsOfMeasure,
    });

    json2csv(
      formattedLineItems,
      async (err, csv) => {
        if (!err) {
          const blob = new Blob([csv], {
            type: "text/csv;charset=utf-8;",
          });

          const fileRef = await uploadFile(
            blob,
            null,
            undefined,
            true,
            null,
            "PublishedBudget"
          );

          try {
            const { data } = await BudgetAPI.postWOP(`${budgetId}/$lockgmp`, {
              lastUpdated: budgetLastUpdated,
              file: fileRef,
            });

            appDispatch({
              type: SET_BUDGET_LOCK_GMP,
              isLockGmp: data.isLockGmp,
              budgetLastUpdated: data.metadata.lastUpdated,
              budgetLineItems: data.lineItems,
            });

            toast.update(savingToast, {
              isLoading: false,
              render: "Successfully Locked GMP",
              closeButton: toastCloseIcon,
              className: "bg-brandGreen text-white",
              hideProgressBar: true,
              position: "top-center",
              icon: toastIcon,
              autoClose: 3000,
            });

            addDocument(data?.document);
            queryClient.invalidateQueries(budgetLineitemKeys.budgetLineitems);
            reload();
          } catch (e) {
            toast.update(savingToast, {
              isLoading: false,
              render: `Error locking GMP: ${e}}`,
              style: {
                backgroundColor: "#BC2727",
                color: "white",
              },
              closeButton: toastCloseIcon,
              position: "top-center",
              hideProgressBar: true,
              icon: toastErrorIcon,
              autoClose: 3000,
            });
            await FileAPI.delete(fileRef.split("/")[1]);
          }
        } else {
          toast.update(savingToast, {
            isLoading: false,
            render: `Error locking GMP: ${JSON.stringify(err)}`,
            style: {
              backgroundColor: "#BC2727",
              color: "white",
            },
            closeButton: toastCloseIcon,
            position: "top-center",
            hideProgressBar: true,
            icon: toastErrorIcon,
            autoClose: 3000,
          });
        }
      },
      {
        keys: columns,
        emptyFieldValue: "",
      }
    );
  }, [
    addDocument,
    appDispatch,
    budgetId,
    budgetLastUpdated,
    budgetType,
    originalBudgetLineItems,
    project,
    reload,
    unitsOfMeasure,
  ]);

  const confirmLockFixedFirm = useCallback(async () => {
    const savingToast = toast("Locking Fixed Firm...", {
      isLoading: true,
      position: "top-center",
    });

    setShowLockFixedFirmModal(false);
    const { formattedLineItems, columns } = getRowsAndColumsForCsv({
      originalBudgetLineItems,
      project,
      budgetType,
      unitsOfMeasure,
    });

    json2csv(
      formattedLineItems,
      async (err, csv) => {
        if (!err) {
          const blob = new Blob([csv], {
            type: "text/csv;charset=utf-8;",
          });

          const fileRef = await uploadFile(
            blob,
            null,
            undefined,
            true,
            null,
            "PublishedBudget"
          );

          try {
            const { data } = await BudgetAPI.postWOP(
              `${budgetId}/$lockfixedfirm`,
              {
                lastUpdated: budgetLastUpdated,
                file: fileRef,
              }
            );

            appDispatch({
              type: SET_BUDGET_LOCK_FIXED_FIRM,
              isLockFixedFirm: data.isLockFixedFirm,
              budgetLastUpdated: data.metadata.lastUpdated,
              budgetLineItems: data.lineItems,
            });

            toast.update(savingToast, {
              isLoading: false,
              render: "Successfully Locked Fixed Firm",
              closeButton: toastCloseIcon,
              className: "bg-brandGreen text-white",
              hideProgressBar: true,
              position: "top-center",
              icon: toastIcon,
              autoClose: 3000,
            });

            addDocument(data?.document);

            queryClient.invalidateQueries(budgetLineitemKeys.budgetLineitems);
            reload();
          } catch (e) {
            toast.update(savingToast, {
              isLoading: false,
              render: `Error locking Fixed Firm: ${e}}`,
              style: {
                backgroundColor: "#BC2727",
                color: "white",
              },
              closeButton: toastCloseIcon,
              position: "top-center",
              hideProgressBar: true,
              icon: toastErrorIcon,
              autoClose: 3000,
            });
            await FileAPI.delete(fileRef.split("/")[1]);
          }
        } else {
          toast.update(savingToast, {
            isLoading: false,
            render: `Error locking Fixed Firm: ${JSON.stringify(err)}`,
            style: {
              backgroundColor: "#BC2727",
              color: "white",
            },
            closeButton: toastCloseIcon,
            position: "top-center",
            hideProgressBar: true,
            icon: toastErrorIcon,
            autoClose: 3000,
          });
        }
      },
      {
        keys: columns,
        emptyFieldValue: "",
      }
    );
  }, [
    addDocument,
    appDispatch,
    budgetId,
    budgetLastUpdated,
    budgetType,
    originalBudgetLineItems,
    project,
    reload,
    unitsOfMeasure,
  ]);

  const handleRowSelect = useCallback(
    (val) => {
      if (val) {
        if (reallocation?.reallocateFromLineitem) {
          dispatchForm({
            type: "reallocateToLineitem",
            reallocateToLineitem: val[0]?.id,
          });
        } else {
          const enabledRows = val.filter((v) => !v?.hideSelection);
          setSelectedRows(enabledRows);
          if (onRowSelect) {
            onRowSelect(enabledRows);
          }
        }
      }
    },
    [dispatchForm, onRowSelect, reallocation?.reallocateFromLineitem]
  );

  const onReloadUsers = useCallback(async () => {
    await reloadUsers();
    toastMessage("Successfully added Vendor");
  }, [reloadUsers]);

  const onEditPopoverOpenCallback = useCallback(async (id, row) => {
    try {
      await LineitemAPI.getWOP(
        `${id}/$lock?lastUpdated=${row?.metadata?.lastUpdated}`
      );
    } catch (e) {
      toastError(
        `Line item is already locked: ${e?.response?.data?.issues[0]?.detail?.display}`
      );
      throw new Error(e);
    }
  }, []);

  const onEditPopoverCloseCallback = useCallback(async (id) => {
    try {
      await LineitemAPI.getWOP(`${id}/$unlock`);
    } catch (e) {
      toastError(
        `Error unlocking line item: ${e?.response?.data?.issues[0]?.detail?.display}`
      );
      throw new Error(e);
    }
  }, []);

  const onPendingUpdatesQueueClick = () => {
    setShowPendingUpdatesQueue((prev) => !prev);
  };

  const onRejectPendingUpdates = useCallback(
    async (reason) => {
      if (openVendorInvoiceDetails) {
        // merge only document viewed
        setPendingVendorInvoicesUpdates((prev) =>
          prev?.filter(
            (item) => item.reference !== openVendorInvoiceDetails?.reference
          )
        );

        const { data } = await BudgetAPI.postByIdWOP(budgetId, "$reject", {
          lastUpdated: budgetLastUpdated,
          reference: openVendorInvoiceDetails?.reference,
          reason,
        });

        appDispatch({
          type: SET_BUDGET_LAST_UPDATED,
          budgetLastUpdated: data.metadata.lastUpdated,
        });

        setOpenVendorInvoiceDetails(null);
      }
    },
    [appDispatch, budgetId, budgetLastUpdated, openVendorInvoiceDetails]
  );

  const onMergePendingUpdates = useCallback(
    async (selectedRef) => {
      if (selectedRef) {
        // merge only passed document ref
        setPendingFinDocsUpdates((prev) =>
          prev?.filter((item) => item.reference !== selectedRef)
        );

        const { data } = await BudgetAPI.postByIdWOP(budgetId, "$merge", {
          lastUpdated: budgetLastUpdated,
          reference: selectedRef,
        });

        appDispatch({
          type: SET_BUDGET_LAST_UPDATED,
          budgetLastUpdated: data.metadata.lastUpdated,
        });

        setBudgetLineItems((prev) =>
          prev.map((li) => {
            if (data?.lineItems[li?.id]) {
              return {
                ...li,
                ...formatLineitem(
                  data?.lineItems[li?.id],
                  financialsConfiguration?.financials?.divisionsObject,
                  financialsConfiguration?.financials?.csiCodeMappingObject,
                  budgetGroups
                ),
              };
            }
            return li;
          })
        );
      } else if (openDocumentDetails) {
        // merge only document viewed
        setPendingFinDocsUpdates((prev) =>
          prev?.filter(
            (item) => item.reference !== openDocumentDetails?.reference
          )
        );

        const { data } = await BudgetAPI.postByIdWOP(budgetId, "$merge", {
          lastUpdated: budgetLastUpdated,
          reference: openDocumentDetails?.reference,
        });

        appDispatch({
          type: SET_BUDGET_LAST_UPDATED,
          budgetLastUpdated: data.metadata.lastUpdated,
        });

        setBudgetLineItems((prev) =>
          prev.map((li) => {
            if (data?.lineItems[li?.id]) {
              return {
                ...li,
                ...formatLineitem(
                  data?.lineItems[li?.id],
                  financialsConfiguration?.financials?.divisionsObject,
                  financialsConfiguration?.financials?.csiCodeMappingObject,
                  budgetGroups
                ),
              };
            }
            return li;
          })
        );

        setOpenDocumentDetails(null);
      } else if (openVendorInvoiceDetails) {
        // merge only document viewed
        setPendingVendorInvoicesUpdates((prev) =>
          prev?.filter(
            (item) => item.reference !== openVendorInvoiceDetails?.reference
          )
        );

        const { data } = await BudgetAPI.postByIdWOP(budgetId, "$merge", {
          lastUpdated: budgetLastUpdated,
          reference: openVendorInvoiceDetails?.reference,
        });

        appDispatch({
          type: SET_BUDGET_LAST_UPDATED,
          budgetLastUpdated: data.metadata.lastUpdated,
        });

        setBudgetLineItems((prev) =>
          prev.map((li) => {
            if (data?.lineItems[li?.id]) {
              return {
                ...li,
                ...formatLineitem(
                  data?.lineItems[li?.id],
                  financialsConfiguration?.financials?.divisionsObject,
                  financialsConfiguration?.financials?.csiCodeMappingObject,
                  budgetGroups
                ),
              };
            }
            return li;
          })
        );

        setOpenVendorInvoiceDetails(null);
        queryClient.invalidateQueries(vendorinvoiceKeys.vendorinvoices);
      } else if (openInvoiceDetails) {
        // merge only document viewed
        setPendingInvoicesUpdates((prev) =>
          prev?.filter(
            (item) => item.reference !== openInvoiceDetails?.reference
          )
        );

        const { data } = await BudgetAPI.postByIdWOP(budgetId, "$merge", {
          lastUpdated: budgetLastUpdated,
          reference: openInvoiceDetails?.reference,
        });

        appDispatch({
          type: SET_BUDGET_LAST_UPDATED,
          budgetLastUpdated: data.metadata.lastUpdated,
        });

        setBudgetLineItems((prev) =>
          prev.map((li) => {
            if (data?.lineItems[li?.id]) {
              return {
                ...li,
                ...formatLineitem(
                  data?.lineItems[li?.id],
                  financialsConfiguration?.financials?.divisionsObject,
                  financialsConfiguration?.financials?.csiCodeMappingObject,
                  budgetGroups
                ),
              };
            }
            return li;
          })
        );

        setOpenInvoiceDetails(null);
        reloadDocuments();
      } else if (checkedRow) {
        // merge only checkedRow (for timesheet/expense -> lineitem mapping)

        setPendingExpensesUpdates((prev) =>
          prev?.filter((item) => item.reference !== openExpense)
        );

        setPendingTimesheetsUpdates((prev) =>
          prev?.filter((item) => item.id !== openTimesheet?.timesheetEntry)
        );

        const { data } = await BudgetAPI.postByIdWOP(budgetId, "$merge", {
          lastUpdated: budgetLastUpdated,
          reference: openExpense || openTimesheet.reference,
          context: {
            timesheetEntry: openTimesheet?.timesheetEntry,
          },
          lineitem: checkedRow,
        });

        appDispatch({
          type: SET_BUDGET_LAST_UPDATED,
          budgetLastUpdated: data.metadata.lastUpdated,
        });

        setBudgetLineItems((prev) =>
          prev.map((li) => {
            if (data?.lineItems[li?.id]) {
              return {
                ...li,
                ...formatLineitem(
                  data?.lineItems[li?.id],
                  financialsConfiguration?.financials?.divisionsObject,
                  financialsConfiguration?.financials?.csiCodeMappingObject,
                  budgetGroups
                ),
              };
            }
            return li;
          })
        );
        queryClient.invalidateQueries(expenseKeys.expenses);
        setCheckedRow(null);
        setOpenExpense(null);
        setOpenTimesheet(null);
      } else {
        // merge selected rows and close window
        if (selectedDocumentsRows?.length) {
          setPendingFinDocsUpdates((prev) =>
            prev?.filter(
              (item) =>
                findIndex(selectedDocumentsRows, {
                  reference: item.reference,
                }) === -1
            )
          );

          const { data } = await BudgetAPI.postByIdWOP(budgetId, "$merge", {
            lastUpdated: budgetLastUpdated,
            reference: selectedDocumentsRows?.map((row) => row.reference),
          });

          appDispatch({
            type: SET_BUDGET_LAST_UPDATED,
            budgetLastUpdated: data.metadata.lastUpdated,
          });

          setBudgetLineItems((prev) =>
            prev.map((li) => {
              if (data?.lineItems[li?.id]) {
                return {
                  ...li,
                  ...formatLineitem(
                    data?.lineItems[li?.id],
                    financialsConfiguration?.financials?.divisionsObject,
                    financialsConfiguration?.financials?.csiCodeMappingObject,
                    budgetGroups
                  ),
                };
              }
              return li;
            })
          );
        }

        if (selectedTimesheetRows?.length) {
          // TODO: automatically merge those which have only a signle LI mapping
          // and merge those which have atleast one LI selected
        }

        // close
        onPendingUpdatesQueueClick();
      }

      queryClient.invalidateQueries(budgetLineitemKeys.budgetLineitems);
      reload();
    },
    [
      openDocumentDetails,
      openVendorInvoiceDetails,
      openInvoiceDetails,
      checkedRow,
      reload,
      budgetId,
      budgetLastUpdated,
      appDispatch,
      financialsConfiguration?.financials?.divisionsObject,
      financialsConfiguration?.financials?.csiCodeMappingObject,
      budgetGroups,
      reloadDocuments,
      openExpense,
      openTimesheet?.reference,
      openTimesheet?.timesheetEntry,
      selectedDocumentsRows,
      selectedTimesheetRows?.length,
    ]
  );

  const onUpdateVendorClose = useCallback(() => {
    dispatchVendor({
      type: "reset",
    });
    setShowUpdateVendorModal(false);
  }, [dispatchVendor]);

  const onChangeReallocationAmount = useCallback(
    (val) => {
      dispatchForm({
        type: "reallocateAmount",
        reallocateAmount: val,
      });
    },
    [dispatchForm]
  );

  const onUpdateVendorSubmit = useCallback(async () => {
    try {
      const { data } = await LineitemAPI.patch(
        vendorInfo.lineitemId,
        {
          ...vendorInfo.originalLineItem,
          vendor: vendorInfo.vendor,
        },
        vendorInfo.originalLineItem
      );

      toastMessage(`Successfully updated vendor`);

      setBudgetLineItems((prev) =>
        prev.map((li) => {
          if (li.id === vendorInfo.lineitemId) {
            return {
              ...li,
              metadata: data?.metadata,
              vendor: data?.vendor,
            };
          }
          return li;
        })
      );

      reload();
    } catch (err) {
      console.log(err);
      toastError(
        `Error updating vendor: ${err?.response?.data?.issues[0]?.detail?.display}`
      );
    }

    onUpdateVendorClose();
  }, [onUpdateVendorClose, reload, vendorInfo]);

  const onUpdateVendorChange = useCallback(
    (val) => {
      dispatchVendor({
        type: "setVendor",
        vendor: val,
      });
    },
    [dispatchVendor]
  );

  const onChangeHoldbackAmount = useCallback(
    (val) => {
      dispatchHoldback({
        type: "holdbackAmount",
        holdbackAmount: val,
      });
    },
    [dispatchHoldback]
  );

  const isReallocationDisabled = useCallback(() => {
    return (
      !parseFloat(reallocation?.reallocateAmount) ||
      parseFloat(reallocation?.reallocateAmount) >
        parseFloat(reallocation?.reallocateTotalAmount)
    );
  }, [reallocation?.reallocateAmount, reallocation?.reallocateTotalAmount]);

  const isHoldbackDisabled = useCallback(() => {
    let holdbackTotal =
      parseFloat(holdback?.workCompletedThisPeriod) -
      parseFloat(holdback?.paidToDate);

    holdbackTotal = holdbackTotal >= 0 ? holdbackTotal : 0;
    return (
      !parseFloat(holdback?.holdbackAmount) ||
      parseFloat(holdback?.holdbackAmount) > parseFloat(holdbackTotal)
    );
  }, [
    holdback?.holdbackAmount,
    holdback?.paidToDate,
    holdback?.workCompletedThisPeriod,
  ]);

  const onReallocationClose = useCallback(() => {
    dispatchForm({
      type: "resetReallocateLineitems",
    });
    toast.dismiss("reallocation-toast");
  }, [dispatchForm]);

  const onReallocationSubmit = useCallback(async () => {
    try {
      const { data } = await BudgetAPI.postByIdWOP(budgetId, "$reallocate", {
        lastUpdated: budgetLastUpdated,
        from: reallocation.reallocateFromLineitem,
        to: reallocation.reallocateToLineitem,
        amount: reallocation.reallocateAmount,
      });

      toastMessage(
        `Successfully reallocated $${formatWithCommasWithoutDecimal(
          reallocation.reallocateAmount
        )}`
      );

      setBudgetLineItems((prev) =>
        prev.map((li) => {
          if (li.id === reallocation.reallocateFromLineitem) {
            return formatLineitem(
              data.from,
              financialsConfiguration?.financials?.divisionsObject,
              financialsConfiguration?.financials?.csiCodeMappingObject,
              budgetGroups
            );
          }
          if (li.id === reallocation.reallocateToLineitem) {
            return formatLineitem(
              data.to,
              financialsConfiguration?.financials?.divisionsObject,
              financialsConfiguration?.financials?.csiCodeMappingObject,
              budgetGroups
            );
          }
          return li;
        })
      );

      appDispatch({
        type: SET_BUDGET_LAST_UPDATED,
        budgetLastUpdated: data.metadata.lastUpdated,
      });
    } catch (err) {
      toastError(
        `Error reallocating: ${err?.response?.data?.issues[0]?.detail?.display}`
      );
    }

    onReallocationClose();
  }, [
    appDispatch,
    budgetGroups,
    budgetId,
    budgetLastUpdated,
    financialsConfiguration,
    onReallocationClose,
    reallocation,
  ]);

  const onHoldbackClose = useCallback(() => {
    dispatchHoldback({
      type: "reset",
    });
    setShowHoldbackModal(false);
  }, [dispatchHoldback]);

  const onHoldbackSubmit = useCallback(async () => {
    try {
      const { data } = await LineitemAPI.patch(
        holdback.lineitemId,
        {
          ...holdback.originalLineitem,
          holdback: formatNumber(holdback.holdbackAmount),
        },
        holdback.originalLineitem
      );

      toastMessage(
        `Successfully added $${formatWithCommasWithoutDecimal(
          holdback.holdbackAmount
        )} to holdback`
      );

      setBudgetLineItems((prev) =>
        prev.map((li) => {
          if (li.id === holdback.lineitemId) {
            return {
              ...li,
              metadata: data?.metadata,
              holdback: data?.holdback,
            };
          }
          return li;
        })
      );

      reload();
    } catch (err) {
      toastError(
        `Error adding to holdback: ${err?.response?.data?.issues[0]?.detail?.display}`
      );
    }

    onHoldbackClose();
  }, [
    holdback.holdbackAmount,
    holdback.lineitemId,
    holdback.originalLineitem,
    onHoldbackClose,
    reload,
  ]);

  const onViewBudget = () => {
    // Redirect to the budget tab
    history.push("?tab=budget");
    setActivateProjectModal(false);
  };

  const onRejectCancel = () => {
    setisRejectOpen(false);
  };

  const onRejectConfirm = (rejectReason) => {
    onRejectPendingUpdates(rejectReason);
    onRejectCancel();
  };

  if (isActivateProject && !originalBudgetLineItems?.length) {
    return (
      <GetStartedCircle
        title="Project Budget"
        className="mx-auto flex justify-center pb-4"
        onGetStartedClicked={onViewBudget}
      />
    );
  }

  return (
    <>
      {!showClientInvoice && (
        <PureBudgetTable
          data={tableData}
          tableMiniMapContainerId={tableMiniMapContainerId}
          name="budgetLineItems"
          // projectBuildings={projectBuildings}
          csiCodes={csiCodes}
          groupedUnitsOfMeasure={groupedUnitsOfMeasure}
          unitsOfMeasure={unitsOfMeasure}
          vendors={vendors}
          rateSheetRates={rateSheetRates}
          onEditRowCancelClick={onEditRowCancelClick}
          onDeleteRowClick={onDeleteRowClick}
          templateSettings={templateSettings}
          updateUserTemplateSettings={updateUserTemplateSettings}
          deleteUserTemplateSettings={deleteUserTemplateSettings}
          onEditSaveCallback={onEditSaveCallback}
          onAddSaveCallback={onAddSaveCallback}
          onCancelSaveCallback={onCancelSaveCallback}
          onSplitLineSaveCallback={onSplitLineSaveCallback}
          rowAssociationMenuList={rowAssociationMenuList}
          groupByCallback={groupByCallback}
          versionCloseCallback={versionCloseCallback}
          setSelectedRows={handleRowSelect}
          initialSelectedRows={selectedRows}
          buttonActions={getButtonActions()}
          onFavoriteClick={onFavoriteClick}
          isFixedFirmBudgetType={budgetType?.label === budgetTypeMap.fixedFirm}
          isCostPlusBudgetType={budgetType?.label === budgetTypeMap.costPlus}
          isTimeAndMaterialsBudgetType={
            budgetType?.label === budgetTypeMap.timeAndMaterials
          }
          hasEditPermission={currentUser?.hasPermission?.(
            "budget",
            "can_write"
          )}
          summaryComponent={
            <BudgetSummary
              budgetSummary={budgetSummary}
              fee={project?.contractualFee}
              insurance={project?.contractualInsurance}
              budgetType={budgetType?.label}
              isLockGmp={isLockGmp}
              isLockFixedFirm={isLockFixedFirm}
            />
          }
          budgetGroups={budgetGroups}
          setSelectedLineItemForComments={setSelectedLineItemForComments}
          onSaveProjectLocation={onSaveProjectLocation}
          projectLocationSpaceConfiguration={spaceConfiguration}
          onSaveProjectLocationSpace={onSaveProjectLocationSpace}
          onSaveContactCallback={onReloadUsers}
          systemConfiguration={systemConfiguration}
          users={users}
          isDraftProject={project?.status === "draft"}
          documentModalView={documentModalView}
          setOpenDocument={setOpenDocument}
          setOpenExpense={setOpenExpense}
          onEditPopoverOpenCallback={onEditPopoverOpenCallback}
          onEditPopoverCloseCallback={onEditPopoverCloseCallback}
          isActivateProject={isActivateProject}
          toggleLowRange={toggleLowRange}
          toggleHighRange={toggleHighRange}
          toggleAllHighRange={toggleAllHighRange}
          toggleAllLowRange={toggleAllLowRange}
          isAllLowRangeChecked={isAllLowRangeChecked}
          isAllHighRangeChecked={isAllHighRangeChecked}
          onPendingUpdatesQueueClick={onPendingUpdatesQueueClick}
          onAddBudgetGroup={onAddBudgetGroup}
          onEditBudgetGroup={onEditBudgetGroup}
          onDeleteBudgetGroup={onDeleteBudgetGroup}
          hideSiteHeader={hideSiteHeader}
          isLockGmp={isLockGmp}
          isLockFixedFirm={isLockFixedFirm}
          currentUserReference={currentUser?.reference}
          disableSelectAll={
            reallocation?.reallocateFromLineitem || disableSelectAllCheckbox
          }
          currentUser={currentUser}
          pendingUpdatesTotal={
            pendingFinDocsUpdates.length +
            pendingVendorInvoicesUpdates.length +
            pendingExpensesUpdates.length +
            pendingTimesheetsUpdates.length +
            pendingInvoicesUpdates.length
          }
          showPendingUpdates={!isDraftProject}
          viaChangeOrder={viaChangeOrder}
          hideSiteHeaderTitle={hideSiteHeaderTitle}
        />
      )}
      {showClientInvoice && (
        <div>
          <BackButton
            onBackPressed={() => {
              setShowClientInvoice(false);
              reload();
            }}
          />
          <DocumentView
            docRef={projectClientInovice.reference}
            projId={projectId}
            hideSiteHeader
            hideDocumentActions
          />
        </div>
      )}
      <BudgetTableComments
        currentUser={currentUser}
        isOpen={!!selectedLineItemForComments}
        onRequestModalClose={() => setSelectedLineItemForComments(null)}
        commentsData={commentsData}
        handlePostComment={handlePostComment}
        handlePostReply={handlePostReply}
        isLoadingComments={isLoadingComments}
      />
      <BudgetTableConfirmationModal
        isOpen={
          showPublishBudgetModal ||
          showLockGMPModal ||
          showLockFixedFirmModal ||
          unlockLineitem
        }
        title={
          (showPublishBudgetModal && "Publish Budget") ||
          (showLockGMPModal && "Lock GMP") ||
          (showLockFixedFirmModal && "Lock Fixed Firm") ||
          (unlockLineitem && "Unlock Line Item")
        }
        primaryButtonTitle={
          (showPublishBudgetModal && "Publish") ||
          (showLockGMPModal && "Lock") ||
          (showLockFixedFirmModal && "Lock") ||
          (unlockLineitem && "Unlock")
        }
        primaryButtonOnClick={() => {
          if (showPublishBudgetModal) {
            confirmPublishBudget();
          } else if (showLockGMPModal) {
            confirmLockGMP();
          } else if (showLockFixedFirmModal) {
            confirmLockFixedFirm();
          } else {
            confirmLineitemUnlock();
          }
        }}
        onRequestModalClose={() => {
          if (showPublishBudgetModal) {
            setShowPublishBudgetModal(false);
          } else if (showLockGMPModal) {
            setShowLockGMPModal(false);
          } else if (showLockFixedFirmModal) {
            setShowLockFixedFirmModal(false);
          } else {
            setUnlockLineitem(null);
            setUnlockLineitemUser(null);
          }
        }}
        text={
          (showPublishBudgetModal && publishBudgetModalText) ||
          (showLockGMPModal && lockGMPModalText) ||
          (showLockFixedFirmModal && lockFixedFirmModalText) ||
          (unlockLineitem && unlockLineitemText(unlockLineitemUser))
        }
      />
      {isRejectOpen && (
        <Modal
          isOpen={isRejectOpen}
          title="Reject"
          onRequestModalClose={onRejectCancel}
          shouldCloseOnEsc
          hideFooter
          childContainerStyle={{
            height: "432px",
          }}
        >
          <BudgetTableRejectVendorInvoiceForm
            onRejectCancel={onRejectCancel}
            onRejectConfirm={onRejectConfirm}
          />
        </Modal>
      )}
      {openDocument && (
        <Modal
          isOpen={openDocument}
          onRequestModalClose={() => setOpenDocument(null)}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
          hideFooter
          inset="4rem 8rem"
          childContainerClassName="overflow-auto"
        >
          <DocumentView
            docRef={openDocument}
            projId={projectId}
            onCancel={() => setOpenDocument(null)}
            hideFloatingActionButtons
          />
        </Modal>
      )}
      {expenseData && (
        <ExpenseDetailsModal
          expenseData={expenseData}
          onCloseModal={() => setOpenExpense(null)}
          csiCodes={csiCodes}
          viewDetails
          disableEditing
          financialsConfiguration={financialsConfiguration}
        />
      )}
      {showPendingUpdatesQueue && (
        <Modal
          isOpen={showPendingUpdatesQueue}
          title="Pending Updates"
          onRequestModalClose={onPendingUpdatesQueueClick}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
          hideFooter
          inset="30% 25%"
          childContainerClassName="relative overflow-y-scroll"
        >
          <BudgetTablePendingUpdates
            finDocs={pendingFinDocsUpdates}
            vendorInvoices={pendingVendorInvoicesUpdates}
            invoices={pendingInvoicesUpdates}
            setSelectedTimesheetRows={setSelectedTimesheetRows}
            setSelectedDocumentRows={setSelectedDocumentRows}
            selectedDocumentsRows={selectedDocumentsRows}
            setSelectedExpenseRows={setSelectedExpenseRows}
            selectedExpenseRows={selectedExpenseRows}
            projectId={projectId}
            setOpenDocumentDetails={setOpenDocumentDetails}
            openDocumentDetails={openDocumentDetails}
            openVendorInvoiceDetails={openVendorInvoiceDetails}
            setOpenVendorInvoiceDetails={setOpenVendorInvoiceDetails}
            openInvoiceDetails={openInvoiceDetails}
            setOpenInvoiceDetails={setOpenInvoiceDetails}
            budgetId={budgetId}
            projectData={projectData}
            setOpenTimesheet={setOpenTimesheet}
            openTimesheet={openTimesheet}
            setOpenExpense={setOpenExpense}
            openExpense={openExpense}
            openExpenseData={expenseData}
            onMergePendingUpdates={onMergePendingUpdates}
            onPendingUpdatesQueueClick={onPendingUpdatesQueueClick}
            setCheckedRow={setCheckedRow}
            checkedRow={checkedRow}
            setisRejectOpen={setisRejectOpen}
            expenses={pendingExpensesUpdates}
            timesheets={pendingTimesheetsUpdates}
            openTimesheetData={timesheetData}
            disableMerge={!["draft"].includes(projectClientInovice?.status)}
          />
        </Modal>
      )}
      {reallocation?.reallocateToLineitem && (
        <Modal
          isOpen={reallocation?.reallocateToLineitem}
          title="REALLOCATE"
          onRequestModalClose={onReallocationClose}
          shouldCloseOnEsc
          hideFooter
          primaryButtonOnClick={onReallocationSubmit}
          primaryButtonTitle="Reallocate"
          tertiaryButtonTitle="Cancel"
          disabled={isReallocationDisabled()}
        >
          <BudgetTableReallocateForm
            onChangeReallocationAmount={onChangeReallocationAmount}
            reallocation={reallocation}
          />
        </Modal>
      )}
      {showHoldbackModal && (
        <Modal
          isOpen={showHoldbackModal}
          title="HOLDBACK"
          onRequestModalClose={onHoldbackClose}
          shouldCloseOnEsc
          hideFooter
          primaryButtonOnClick={onHoldbackSubmit}
          primaryButtonTitle="Holdback"
          tertiaryButtonTitle="Cancel"
          disabled={isHoldbackDisabled()}
        >
          <BudgetTableHoldbackForm
            onChangeHoldbackAmount={onChangeHoldbackAmount}
            holdback={holdback}
          />
        </Modal>
      )}
      {showUpdateVendorModal && (
        <Modal
          isOpen={showUpdateVendorModal}
          title="Vendor"
          onRequestModalClose={onUpdateVendorClose}
          shouldCloseOnEsc
          hideFooter
          primaryButtonOnClick={onUpdateVendorSubmit}
          primaryButtonTitle="Save"
          tertiaryButtonTitle="Cancel"
          childContainerClassName="h-96 overflow-visible"
        >
          <BudgetTableUpdateVendorForm
            onUpdateVendorChange={onUpdateVendorChange}
            vendorInfo={vendorInfo}
            vendors={vendors}
          />
        </Modal>
      )}
      {showEditSelectedModal && (
        <Modal
          isOpen={showEditSelectedModal}
          title="Edit Selected"
          onRequestModalClose={() => setEditSelectedModal(false)}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
          hideFooter
          childContainerClassName="relative"
          contentStyle={{ maxWidth: "75%" }}
        >
          <div className="static">
            <div className="flex overflow-x-auto">
              <BudgetTableInLineForm
                csiCodes={csiCodes}
                groupedUnitsOfMeasure={groupedUnitsOfMeasure}
                unitsOfMeasure={unitsOfMeasure}
                vendors={vendors}
                // projectBuildings={projectBuildings}
                budgetGroups={budgetGroups}
                onSaveProjectLocation={onSaveProjectLocation}
                projectLocationSpaceConfiguration={spaceConfiguration}
                onSaveProjectLocationSpace={onSaveProjectLocationSpace}
                onSaveContactCallback={onReloadUsers}
                systemConfiguration={systemConfiguration}
                users={users}
                rateSheetRates={rateSheetRates}
                onAddBudgetGroup={onAddBudgetGroup}
                onEditBudgetGroup={onEditBudgetGroup}
                onDeleteBudgetGroup={onDeleteBudgetGroup}
                currentUser={currentUser}
                onAddSaveCallback={onEditSelectedSave}
                hideCrossButton
                hideAddAnother
                disableRequiredFieldsValidation
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

BudgetTable.propTypes = {
  /**
   * Budget Id used to pull budget data
   */
  budgetId: PropTypes.string,
  projectId: PropTypes.string,
  projectData: PropTypes.shape({
    buildings: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        spaces: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.string,
            level: PropTypes.string,
          })
        ),
      })
    ),
    members: PropTypes.arrayOf(
      PropTypes.shape({
        user: PropTypes.string,
        position: PropTypes.string,
      })
    ),
    contractualFee: PropTypes.shape({
      feeType: PropTypes.string,
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    contractualInsurance: PropTypes.shape({
      insuranceType: PropTypes.string,
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    reference: PropTypes.string,
  }),
  onRowSelect: PropTypes.func,
  initialSelectedRows: PropTypes.arrayOf(PropTypes.string),
  documentModalView: PropTypes.bool,
  isActivateProject: PropTypes.bool,
  setDraftBudgetLineItems: PropTypes.func,
  setIsLoading: PropTypes.func,
  setButtonActions: PropTypes.func,
  setActivateProjectModal: PropTypes.func,
  hideSiteHeader: PropTypes.bool,
  pullSummary: PropTypes.bool,
  tableMiniMapContainerId: PropTypes.string,
  disableRowsWithRateAndNoUncommitedValuePending: PropTypes.bool,
  disableContingencyLineitem: PropTypes.bool,
  disableSelectAllCheckbox: PropTypes.bool,
  disbaleRowActions: PropTypes.bool,
  report: PropTypes.bool,
  viaChangeOrder: PropTypes.bool,
  hideSiteHeaderTitle: PropTypes.bool,
};

BudgetTable.defaultProps = {
  budgetId: undefined,
  projectId: undefined,
  projectData: undefined,
  setActivateProjectModal: undefined,
  setButtonActions: () => {},
  onRowSelect: undefined,
  initialSelectedRows: [],
  documentModalView: false,
  isActivateProject: false,
  setDraftBudgetLineItems: undefined,
  setIsLoading: undefined,
  hideSiteHeader: undefined,
  pullSummary: undefined,
  tableMiniMapContainerId: undefined,
  disableRowsWithRateAndNoUncommitedValuePending: false,
  disableContingencyLineitem: false,
  disableSelectAllCheckbox: false,
  disbaleRowActions: false,
  report: false,
  viaChangeOrder: false,
  hideSiteHeaderTitle: false,
};

export default BudgetTable;

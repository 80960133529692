/**
 * Checks if a user has admin or is employee.
 * @param {Object} user - The user object, which should contain a 'isAdmin' and 'isEmployee' property.
 * @returns {boolean} True if the user id amdin/employee.
 */

const isEmployeeOrAdmin = (user) => {
  return user?.isAdmin || user?.isEmployee;
};

export default isEmployeeOrAdmin;

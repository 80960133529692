import React, { useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

import uploadArrow from "../../assets/images/uploadArrow.svg";
import folderTab from "../../assets/images/folderTab.svg";
// import folderTabGreen from "../../assets/images/folderTabGreen.svg";
import folderTabGreen from "../../assets/images/folderTabGreenLight.svg";

const folderCardCN = cntl`
  flex
  flex-col
  items-center
  col-span-1
  bg-gray-100
  h-24
  w-full
  px-2
  border
  border-gray-100
  rounded-tr
  rounded-bl
  rounded-br
  hover:border-brandGreen
`;
const folderCardTitleCN = cntl`
  text-center
  mx-auto"
`;

const FolderCard = ({ id, title, itemCount, onClick, isUploadable }) => {
  const [hovering, setHovering] = useState(false);
  return (
    <button
      className={folderCardCN}
      onMouseOver={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
      onFocus={() => setHovering(true)}
      onBlur={() => setHovering(false)}
      onClick={() => onClick(id)}
      type="button"
    >
      <div
        className="relative self-start"
        style={{ top: "-17px", right: "9px" }}
      >
        <img
          className="self-start w-13 select-none"
          src={hovering ? folderTabGreen : folderTab}
          alt=""
        />
      </div>
      <div className="flex flex-col items-center w-full h-full">
        <div className="flex mb-auto">
          <p className={folderCardTitleCN}>{title}</p>
        </div>
        <div className="flex -mb-2 text-xs">{`${itemCount} items`}</div>
        <div className="flex items-center w-full pb-2">
          <img
            className={`h-5 ${isUploadable ? "visible " : "invisible"}`}
            src={uploadArrow}
            alt="upload icon"
          />
        </div>
      </div>
    </button>
  );
};

FolderCard.propTypes = {
  /**
   * value for folder id
   */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * string value for the title of the folder
   */
  title: PropTypes.string,
  /**
   * value for the document count in the folder
   */
  itemCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * click handler for routing to nested folder view
   */
  onClick: PropTypes.func,
  /**
   * folder has uploadable files
   */
  isUploadable: PropTypes.bool,
};

FolderCard.defaultProps = {
  id: undefined,
  title: undefined,
  itemCount: undefined,
  onClick: undefined,
  isUploadable: false,
};

export default FolderCard;

import { useCallback, useMemo, useState } from "react";
import { getUserFirstAndLast } from "../../../../../../helpers/Utilities";
import useCurrentUser from "../../../../../../hooks/useCurrentUser";

const useOwnedByListContactItem = ({ user, setEditing }) => {
  const { data: currentUser } = useCurrentUser();
  const [hover, setHover] = useState(false);

  const name = useMemo(() => {
    return getUserFirstAndLast(user);
  }, [user]);

  const handleHover = useCallback((action) => {
    switch (action) {
      case "leave":
        setHover(false);
        break;
      default:
        setHover(true);
    }
  }, []);

  const onEdit = (e) => {
    e.stopPropagation();
    setEditing(user);
  };

  return { hover, currentUser, name, handleHover, onEdit };
};

export default useOwnedByListContactItem;

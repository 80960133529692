import { useMemo } from "react";
import { priorityImage } from "../../../helpers/priorityDropDownHelpers";

const useDropdownWithPriorityLevelData = (priority = "") => {
  const options = useMemo(() => {
    return [
      { label: "High", value: "high", image: priorityImage("high") },
      { label: "Medium", value: "medium", image: priorityImage("medium") },
      { label: "Low", value: "low", image: priorityImage("low") },
    ];
  }, []);

  const value = useMemo(() => {
    return options.find((option) => option.value === priority);
  }, [options, priority]);

  return {
    options,
    value,
  };
};

export default useDropdownWithPriorityLevelData;

import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";

import DocumentAttachmentForm from "../DocumentForm/DocumentAttachmentForm";
/* import SelectUserInterface from "../SelectUserInterface/SelectUserInterface"; */
import ResourceDropDown from "./ResourceDropDown";

import eventIcon from "../../assets/images/eventIcon.png";
import DatePicker from "../DatePicker/DatePicker";
import Dropdown from "../Dropdown/Dropdown";
import Input from "../Input/Input";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";

/* import { workflowDistroTooltip } from "../../../constants";
/* 
per ticket 5315 commented out all things related to distro list
import { workflowDistroTooltip } from "../../../constants";
import Checkbox from "../Checkbox/Checkbox";
import InfoTooltip from "../InfoTooltip/InfoTooltip"; */

const eventIconElement = (
  <img className="w-4 h-4" src={eventIcon} alt="event icon" />
);

const WorkflowDetailsStep = ({
  isDisabled,
  workflowData,
  handleEditWorkflow,
  members,
  /*   distroMembers,
  handleChangeMember,
  handleRemoveMember, */
  document,
  dispatch,
  docResources,
  favorites,
  deleteFavorite,
  postFavorite,
  selectedDocumentsIds,
  disableAssociation,
  template,
  templateOptions,
  handleTemplateChange,
  hideAssociation,
  customOnChange,
}) => {
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [disableAddDocs, setDisableAddDocs] = useState(true);

  /*  const [distroActive, setDistroActive] = useState(); */
  const [memberCount, setMemberCount] = useState(members?.length || 0);
  const dropdownRef = useRef(null);
  useEffect(() => {
    if (workflowData?.project || workflowData?.property) {
      setDisableAddDocs(false);
    } else {
      setDisableAddDocs(true);
    }
  }, [workflowData?.project, workflowData?.property]);

  useEffect(() => {
    let timer = null;
    if (members && members.length !== memberCount) {
      setMemberCount(members.length);
      if (members.length > memberCount) {
        timer = setTimeout(
          () => dropdownRef.current.onDropdownIndicatorMouseDown(),
          100
        );
      }
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [members, memberCount, dropdownRef]);

  useEffect(() => {
    setSelectedDocuments(selectedDocumentsIds);
  }, [selectedDocumentsIds]);

  /*   const handleTimeInput = (val) => {
      if (!val) return;
      const hour = Number(val.split(":")[0]);
      const minute = Number(val.split(":")[1]);
      const { startDate } = workflowData ?? {};
      const tmpDate = moment(startDate?.date).set({ hour, minute }).toISOString();

    handleEditWorkflow("startDate", {
      ...(startDate ?? {}),
      date: tmpDate,
    });
  }; */

  return (
    <div className="mb-2">
      <div className="xl:w-1/3 lg:w-1/2">
        {!hideAssociation && (
          <div className="flex flex-row justify-between mt-5">
            <ResourceDropDown
              customOnChange={customOnChange}
              isWorkflow
              resource={
                workflowData.property ||
                workflowData.project ||
                workflowData.company
              }
              setResource={(value, field) => handleEditWorkflow(field, value)}
              validation={yup.mixed().required()}
              disableAssociation={disableAssociation}
            />
          </div>
        )}

        <Dropdown
          isDisabled={isDisabled}
          className="mt-5"
          label="Choose Template"
          placeholder="Select"
          options={templateOptions}
          onChange={handleTemplateChange}
          value={template}
        />

        <Input
          disabled={isDisabled}
          className="mt-5"
          label="Name"
          placeholder="Name"
          value={workflowData.name}
          onChange={(val) => handleEditWorkflow("name", val)}
          validation={yup.string().required()}
          // eslint-disable-next-line consistent-return
          handleEnter={(event) => {
            if (event.key === "Enter") {
              // eslint-disable-next-line no-param-reassign
              event.keyCode = 9;
              return event.keyCode;
            }
          }}
        />

        {/* per ticket 5315 commented out distro list on workflow details
        
        <div className="static flex justify-between items-center mb-1">
          <div className="flex items-center mt-5">
            <span className="ESInputLabel mr-2" style={{ marginBottom: "0px" }}>
              Distro List Recipients
            </span>
            <InfoTooltip infoData={workflowDistroTooltip} />
          </div>
          <Checkbox
            className="mt-5"
            label="Send to distro list"
            checked={distroActive}
            onChange={() => setDistroActive((prev) => !prev)}
          />
        </div> */}

        {/*         {distroActive && (
          <div className="static">
            <SelectUserInterface
              disabled={isDisabled}
              className="w-full mt-1"
              userList={members.map((mem) => {
                return { ...mem };
              })}
              userPlaceholder="Search companies..."
              userOptions={distroMembers}
              buttonText="Add Contact"
              onAddUser={handleChangeMember}
              onRemoveUser={handleRemoveMember}
              dropdownRef={dropdownRef}
              disableCreateUser
              showCurrentRole
              isMulti
            />
          </div>
        )} */}

        <div className="flex items-center justify-between mt-5">
          <DatePicker
            disabled={isDisabled}
            className="flex-1 mr-2"
            label="Start Date"
            value={workflowData.startDate?.date}
            iconLeft={eventIconElement}
            onChange={(val) =>
              handleEditWorkflow("startDate", {
                ...workflowData.startDate,
                date: val,
              })
            }
            validation={yup.date().required()}
            minDate={moment().toDate()}
          />
          {/* <Dropdown
            className="flex-1 mt-7"
            label=""
            placeholder="Select Time"
            value={TimeOptions.find((item) => {
              if (!workflowData.startDate?.date) return false;
              return (
                item.value ===
                moment(workflowData.startDate.date).format("HH:mm")
              );
            })}
            options={TimeOptions}
            onChange={({ value }) => handleTimeInput(value)}
            disableClear
            disableSort
          /> */}
        </div>
      </div>

      <div className="w-1/2">
        <SecondaryHeader className="mt-5">Attachments</SecondaryHeader>
        <DocumentAttachmentForm
          isDisabled={disableAddDocs}
          useSmallIcon
          document={document}
          dispatch={dispatch}
          favorites={favorites}
          deleteFavorite={deleteFavorite}
          postFavorite={postFavorite}
          selectedDocuments={selectedDocuments}
          setSelectedDocuments={setSelectedDocuments}
          docResources={docResources}
          showFile
        />
      </div>
    </div>
  );
};

WorkflowDetailsStep.propTypes = {
  /**
   * workflow data for overall details
   */
  workflowData: PropTypes.shape({
    name: PropTypes.string,
    startDate: PropTypes.string,
    property: PropTypes.string,
    project: PropTypes.string,
    company: PropTypes.string,
    cost: PropTypes.string,
  }),
  /**
   * documents attached
   */
  document: PropTypes.shape({
    attachments: PropTypes.arrayOf({
      id: PropTypes.string,
    }),
  }),
  /**
   * function used to display edit workflow
   */
  handleEditWorkflow: PropTypes.func,
  /**
   * array of selected members
   */
  members: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  /**
   * array of member to be shown in dropdown
   */
  /*   distroMembers: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ), */
  /**
   * function to handle members changes
   */
  // handleChangeMember: PropTypes.func,
  /**
   * function to remove members
   */
  // handleRemoveMember: PropTypes.func,
  /**
   * document form dispatcher
   */
  dispatch: PropTypes.func,
  /**
   * resources for document attachment modal
   */
  // eslint-disable-next-line react/forbid-prop-types
  docResources: PropTypes.array,
  /**
   * list of favorited documents
   */
  // eslint-disable-next-line react/forbid-prop-types
  favorites: PropTypes.array,
  //   workflowCompletionTime: PropTypes.shape({
  //     timeOfDay: PropTypes.string,
  //     timezone: PropTypes.string,
  //   }),
  selectedDocumentsIds: PropTypes.arrayOf(PropTypes.string),
  /**
   * If user came from Property -> Create Workflow
   */
  disableAssociation: PropTypes.bool,
  /**
   * value of current template selected
   */
  template: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  /**
   * passing customOnChange function to resource dropdown
   */
  customOnChange: PropTypes.func,

  /**
   * boolean for allowing disabling
   */
  isDisabled: PropTypes.bool,

  // eslint-disable-next-line react/forbid-prop-types
  templateOptions: PropTypes.array,
  handleTemplateChange: PropTypes.func,
  hideAssociation: PropTypes.bool,
  deleteFavorite: PropTypes.func,
  postFavorite: PropTypes.func,
};

WorkflowDetailsStep.defaultProps = {
  workflowData: [],
  document: {},
  handleEditWorkflow: undefined,
  members: [{}],
  /*   distroMembers: [],
  handleChangeMember: undefined,
  handleRemoveMember: undefined, */
  dispatch: undefined,
  docResources: [],
  favorites: [],
  //   workflowCompletionTime: undefined,
  selectedDocumentsIds: [],
  disableAssociation: false,
  template: undefined,
  templateOptions: [],
  handleTemplateChange: () => {},
  hideAssociation: false,
  deleteFavorite: undefined,
  postFavorite: undefined,
  customOnChange: undefined,
  isDisabled: true,
};

export default WorkflowDetailsStep;

import { SUBMITTAL_IN_PROGRESS } from "../constants";

export const wfStepCompleted = (users) => {
  return users?.some((user) => user?.status !== "waiting");
};

export const wfAllStepsCompleted = (steps, wfStatus) => {
  return steps?.every(
    (step) =>
      step?.users?.length > 0 &&
      wfStatus === SUBMITTAL_IN_PROGRESS &&
      wfStepCompleted(step?.users)
  );
};

import React from "react";
import PropTypes from "prop-types";
import PAQualitificationsSubSection from "./PAQualitificationsSubSection";
import COQualitificationsSubSection from "./COQualitificationsSubSection";
import BidsQualitificationsSubSection from "./BidsQualitificationsSubSection";
import CAQualitificationsSubSection from "./CAQualitificationsSubSection";
import POQualitificationsSubSection from "./POQualitificationsSubSection";

const QualitificationsSection = ({
  documentsSettings,
  dispatchDocumentsSettings,
}) => {
  return (
    <div className="flex flex-col">
      <PAQualitificationsSubSection
        paQualifications={
          documentsSettings?.qualsAndExceptions?.purchaseAuthorization
        }
        dispatchDocumentsSettings={dispatchDocumentsSettings}
      />
      <COQualitificationsSubSection
        coQualifications={documentsSettings?.qualsAndExceptions?.changeOrder}
        dispatchDocumentsSettings={dispatchDocumentsSettings}
      />
      <BidsQualitificationsSubSection
        bidsQualifications={documentsSettings?.qualsAndExceptions?.bid}
        dispatchDocumentsSettings={dispatchDocumentsSettings}
      />
      <CAQualitificationsSubSection
        caQualifications={
          documentsSettings?.qualsAndExceptions?.contingencyAuthorization
        }
        dispatchDocumentsSettings={dispatchDocumentsSettings}
      />
      <POQualitificationsSubSection
        poQualifications={documentsSettings?.qualsAndExceptions?.purchaseOrder}
        dispatchDocumentsSettings={dispatchDocumentsSettings}
      />
    </div>
  );
};

QualitificationsSection.propTypes = {
  documentsSettings: PropTypes.shape({
    qualsAndExceptions: PropTypes.shape({
      purchaseAuthorization: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
          isEditing: PropTypes.bool,
        })
      ),
      changeOrder: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
          isEditing: PropTypes.bool,
        })
      ),
      bid: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
          isEditing: PropTypes.bool,
        })
      ),
      contingencyAuthorization: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
          isEditing: PropTypes.bool,
        })
      ),
      purchaseOrder: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
          isEditing: PropTypes.bool,
        })
      ),
    }),
  }),

  dispatchDocumentsSettings: PropTypes.func,
};

QualitificationsSection.defaultProps = {
  dispatchDocumentsSettings: undefined,
  documentsSettings: undefined,
};

export default QualitificationsSection;

import React from "react";

const RouteSidebarLinebreak = () => {
  return (
    <div className="w-full px-4 pr-8 mt-1.5 mb-1">
      <hr className="w-full" />
    </div>
  );
};

RouteSidebarLinebreak.propTypes = {};

export default RouteSidebarLinebreak;

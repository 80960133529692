import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import Dropdown from "../Dropdown/Dropdown";
import InlineInput from "../Input/InlineInput";
import TagsContainer from "../Tags/TagsContainer";
import Widget from "../Widget/Widget";
import DescriptionField from "../DescriptionField";
import "./PropertyOverview.css";
import { getTimeZoneOptions } from "../../../helpers/Time";
import OwnedBy from "../OwnedBy";

const boldCN = (className) => cntl`
  font-bold 
  text-gray-450
  text-md
  w-1/3
  ${className}
`;

const typeCN = (className) => cntl`
  flex
  w-full
  border-b
  items-center
  pb-4
  ${className}
`;

const descriptionCN = () => cntl`
  flex
  w-full
  mt-4
  text-area-padding
`;

const textCN = cntl`
  text-gray-450 
  text-base
  font-normal
  flex
`;

const PropertyOverview = ({
  property,
  propertyTypes,
  loading,
  editing,
  onChange,
  dispatch,
}) => {
  const propertyType = useMemo(() => {
    return propertyTypes?.find((type) => type.value === property?.propertyType);
  }, [property?.propertyType, propertyTypes]);

  return (
    <Widget
      draggable={false}
      title="Property Information"
      className="mb-4"
      overflow
    >
      <div className={`relative pt-6 ${loading && "loading"}`}>
        <div className={typeCN()}>
          <h3 className={boldCN()}>Type</h3>
          <div className="w-full">
            <p className={textCN}>
              {!editing ? (
                propertyType?.label ?? "Not specified"
              ) : (
                <div className="w-56">
                  <Dropdown
                    options={propertyTypes}
                    value={propertyType}
                    placeholder="Select Type"
                    onChange={(val) => onChange("propertyType", val.value)}
                  />
                </div>
              )}
            </p>
          </div>
        </div>
        <div className={typeCN("mt-4")}>
          <h3 className={boldCN()}>Timezone</h3>
          <div className="w-full">
            <p className={textCN}>
              {!editing ? (
                property?.timezone ?? "Not specified"
              ) : (
                <div className="w-56">
                  <Dropdown
                    options={[...(getTimeZoneOptions() ?? [])]}
                    value={property?.timezone}
                    placeholder={property?.timezone ?? "Select Timezone"}
                    onChange={(val) => onChange("timezone", val.label)}
                  />
                </div>
              )}
            </p>
          </div>
        </div>
        <div className={typeCN("mt-4")}>
          <h3 className={boldCN()}>Owned By</h3>
          <div className="w-full">
            <p className={textCN}>
              <OwnedBy
                editing={editing}
                ownedBy={property?.ownedBy}
                onChange={(val) => onChange("ownedBy", val)}
                customWidth="351px"
              />
            </p>
          </div>
        </div>
        <div className={descriptionCN()}>
          <h3 className={boldCN()}>Description</h3>
          {!editing ? (
            <div className="w-full">
              <DescriptionField text={property?.description} />
            </div>
          ) : (
            <InlineInput
              className={textCN}
              value={property?.description}
              editing
              textarea
              width="w-full"
              size="sm"
              color="gray-450"
              rows={3}
              placeholder="Property Description"
              onConfirm={(val) => onChange("description", val)}
              onChangeCallback={(val) => onChange("description", val)}
              hidePencil
            />
          )}
        </div>
        <div className="flex flex-col pt-4 mt-4 border-t">
          <h3 className={boldCN(editing && "mb-4")}>Tags</h3>
          <TagsContainer
            isEditing={editing}
            tagsWrapperClassName="mt-4"
            dispatch={dispatch}
            resource={property}
          />
        </div>
      </div>
    </Widget>
  );
};

PropertyOverview.propTypes = {
  property: PropTypes.shape({
    address: PropTypes.arrayOf(
      PropTypes.shape({
        use: PropTypes.bool,
      })
    ),
    propertyType: PropTypes.string,
    mailingSameAsPhysical: PropTypes.bool,
    description: PropTypes.string,
    timezone: PropTypes.string,
    ownedBy: PropTypes.arrayOf(
      PropTypes.shape({
        reference: PropTypes.string,
        contactType: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
  propertyTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
  editing: PropTypes.bool,
  onChange: PropTypes.func,
  dispatch: PropTypes.func,
};

PropertyOverview.defaultProps = {
  property: undefined,
  propertyTypes: [],
  loading: true,
  editing: false,
  onChange: () => {},
  dispatch: undefined,
};

export default PropertyOverview;

import cntl from "cntl";
import PropTypes from "prop-types";
import React from "react";
import TextDataView from "./TextDataView";

const listItemCN = (textField) => cntl`
  flex
  justify-between
  ${textField ? "items-start" : "items-center"}
  w-full
  mr-4
  pb-5
  pt-5
  `;

const valueCN = cntl`
font-normal 
text-gray-450 
text-base
`;

const EditItemAsset = ({ input, display, isEditing }) => {
  return (
    <>
      {isEditing && <div className={listItemCN()}>{input}</div>}
      {!isEditing && (
        <>
          <div className={listItemCN(display?.field === "text-entry")}>
            <p
              className="flex flex-0 w-1/2 text-gray-450 text-base font-bold pr-2"
              style={{ wordBreak: "break-word" }}
            >
              {display?.title}
            </p>
            <div className="flex items-end w-full mt-1 text-gray-450 text-base font-normal">
              {display?.field === "check-box" &&
              display?.value?.props?.title === "undefined" ? (
                <p className={valueCN}>No</p>
              ) : (
                <TextDataView
                  display={display}
                  className={valueCN}
                  threshold={30}
                  isEditing={isEditing}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

EditItemAsset.propTypes = {
  input: PropTypes.element,
  // eslint-disable-next-line react/forbid-prop-types
  display: PropTypes.object,
  isEditing: PropTypes.bool,
};

EditItemAsset.defaultProps = {
  input: undefined,
  display: undefined,
  isEditing: undefined,
};

export default EditItemAsset;

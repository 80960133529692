import { TimesheetAPI } from "@griffingroupglobal/eslib-api";

const editTsListStatusHandler = async (props) => {
  const { items, action } = props;

  const promiseArray = items.map((item) => {
    const timesheetId = item?.reference.split("/")[1];

    return TimesheetAPI.postWOP(`${timesheetId}/${action}`);
  });

  const response = await Promise.all(promiseArray);
  return response;
};

export default editTsListStatusHandler;

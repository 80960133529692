import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import * as yup from "yup";
import Modal from "../Modal/Modal";
import { getInitials } from "../../../helpers/User";
import { useGetFile } from "../../../hooks/useGetFile";
import DatePicker from "../DatePicker/DatePicker";
import usePayrollApprovedTimesheetsForUser from "../../../hooks/timesheets/usePayrollApprovedTimesheetsForUser";
import exclamationRed from "../../assets/images/notiAlertIcon.svg";

const avatarCN = cntl`
  bg-brandGreen
  rounded-full
  w-20
  h-20
  flex
  items-center
  justify-center
  mr-6
`;

const headerCN = cntl`
  text-lg
  font-semibold
`;

const payrollApprovedMessage =
  "Employee has already been through an approved payroll period. This change will not effect their timesheet.";
const resetTimesheetsMessage =
  "By doing this you will be re-setting the timesheet, are you okay with that?";

const ChangeEmployeeHireDateModal = ({
  user,
  onCancel,
  onSave,
  onChangeUserHireDate,
  isSaving,
}) => {
  const { timeSheetsAlreadyApproved } = usePayrollApprovedTimesheetsForUser(
    user?.reference
  );
  const { data } = useGetFile(user?.avatar);

  return (
    <Modal
      title="CHANGE HIRE DATE"
      inset="10rem 25rem"
      isOpen
      onRequestModalClose={onCancel}
      hideFooter
      primaryButtonTitle="Save"
      primaryButtonOnClick={() => onSave(user?.id)}
      tertiaryButtonTitle="Cancel"
      disabled={isSaving || !user?.employeeInfo?.hireDate}
    >
      <div className="flex flex-col h-80 w-80">
        <div className="flex flex-row">
          {(data?.thumbnailUrl && (
            <div className="bg-gray-200 flex justify-center items-center w-20 h-20 rounded-full mr-6 relative">
              <img
                src={data?.thumbnailUrl}
                className="w-full h-full rounded-full"
                alt="userAvatarIcon"
              />
            </div>
          )) || (
            <div className={avatarCN}>
              <p className="font-semibold uppercase text-white text-4xl">
                {getInitials(user?.name)}
              </p>
            </div>
          )}
          <div className="px-2 pt-3">
            <h2 className={headerCN}>
              {user?.name?.firstName} {user?.name?.lastName}
            </h2>
            <h2 className="text-sm text-gray-300">{user?.company}</h2>
          </div>
        </div>
        <DatePicker
          className="pt-5"
          label="Hire Date"
          value={user?.employeeInfo?.hireDate}
          onChange={onChangeUserHireDate}
          validation={yup.date().required()}
        />
        <div className="flex items-start border rounded-md p-8 mt-5">
          <img src={exclamationRed} alt="attention" />
          <p className="text-es-medium-grey text-es-normal tracking-es-wide pl-4">
            {timeSheetsAlreadyApproved?.length
              ? payrollApprovedMessage
              : resetTimesheetsMessage}
          </p>
        </div>
      </div>
    </Modal>
  );
};

ChangeEmployeeHireDateModal.propTypes = {
  onCancel: PropTypes.func,
  user: PropTypes.shape({
    reference: PropTypes.string,
    active: PropTypes.bool,
    avatar: PropTypes.shape({
      thumbnailUrl: PropTypes.string,
    }),
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    id: PropTypes.string,
    company: PropTypes.string,
    employeeInfo: PropTypes.shape({
      hireDate: PropTypes.string,
    }),
  }),
  onSave: PropTypes.func,
  isSaving: PropTypes.bool,
  onChangeUserHireDate: PropTypes.func,
};

ChangeEmployeeHireDateModal.defaultProps = {
  onCancel: undefined,
  user: undefined,
  onSave: undefined,
  isSaving: false,
  onChangeUserHireDate: undefined,
};

export default ChangeEmployeeHireDateModal;

import { ConfigurationAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { configurationKeys } from "../config/reactQuery/queryKeyFactory";

const deleteTemplate = async ({ id, resource }) => {
  const { data } = await ConfigurationAPI.delete(`templates/${resource}/${id}`);
  return data;
};

/**
 * Mutation hook to delete given template by resource and id
 */
const useTemplatesConfigurationDelete = (mutationKey) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey,
    mutationFn: deleteTemplate,
    onSuccess: (templates) => {
      queryClient.setQueryData(configurationKeys("templates"), templates);
    },
    onError: (error) => {
      queryClient.invalidateQueries(configurationKeys("templates"));
      console.error("useTemplatesConfigurationDelete", error);
    },
  });
};

export default useTemplatesConfigurationDelete;

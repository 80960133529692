/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import HelpCardTitle from "./HelpCardTitle";
import HelpCardDescription from "./HelpCardDescription";

const cardContainer = cntl`
flex
flex-col
justify-center
items-start
p-8
border
rounded-lg
w-80
h-28
`;

const cardStyle = { width: "299px;", height: "116px;" };

const HelpCategoryCard = ({ category, helpData, setCategoryView }) => {
  const { description } = helpData;

  const showHelpPage = () => {
    setCategoryView(category);
  };

  return (
    <button type="button" onClick={showHelpPage}>
      <div style={cardStyle} className={cardContainer}>
        <HelpCardTitle title={category} />
        <HelpCardDescription description={description} />
      </div>
    </button>
  );
};

HelpCategoryCard.propType = {
  category: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  helpData: PropTypes.arrayOf(PropTypes.shape({})),
};

HelpCategoryCard.defaultProps = {
  category: {},
  helpData: [{}],
};

export default HelpCategoryCard;

import Api from "@griffingroupglobal/eslib-api";
import { HELP_REQUEST_URL } from "../../constants";
import useAuthenticatedQuery from "../useAuthenticatedQuery";
import helpKeys from "./helpKeys";

const populateHelpGridItems = (helpData) => {
  const helpItems = helpData?.faq?.reduce((acc, item) => {
    if (!acc?.[item?.category])
      return {
        ...acc,
        [item?.category]: {
          description: helpData?.sections[item?.category],
          helpData: [item],
        },
      };
    acc[item?.category].helpData.push(item);

    return acc;
  }, {});

  return helpItems;
};

// fetch help {[category]:{answer: <string>, category: <string>, display: <string>, order: <number>, platform: <string>, question: <string>}}
const fetchHelpPageData = async () => {
  const { data: helpResponseData } = await Api.get(HELP_REQUEST_URL);
  if (helpResponseData) {
    return populateHelpGridItems(helpResponseData);
  }
  return {};
};

// fetch help videos [[category]: {name, contentsUrl}, ...]
const fetchHelpVideoData = async () => {
  const { data } = await Api.get("/api/File/$help");
  return data;
};

const useGetHelpList = () => {
  const { helps, videos } = helpKeys;

  const { data: helpData } = useAuthenticatedQuery({
    queryKey: helps,
    enabled: true,
    queryFn: fetchHelpPageData,
    staleTime: 30 * 60 * 1000,
    onError: (error) => {
      console.error(error);
    },
  });

  const { data: videoData } = useAuthenticatedQuery({
    queryKey: videos,
    enabled: true,
    queryFn: fetchHelpVideoData,
    staleTime: 30 * 60 * 1000,
    onError: (error) => {
      console.error(error);
    },
  });

  return { helpData, videoData };
};

export default useGetHelpList;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { TimesheetUtil } from "@griffingroupglobal/eslib-util";
import moment from "moment";
import useResourceHistory from "../../../hooks/useResourceHistory";
import History from "./History";
import useFinancialsConfiguration from "../../../hooks/useFinancialsConfiguration";

const { TimeSheets } = TimesheetUtil;

const TimesheetHistory = ({ currentUser }) => {
  const { timesheetId, date } = useParams();
  const [period, setPeriod] = useState();
  const { data } = useFinancialsConfiguration();

  const { history, updateComment } = useResourceHistory(
    "Timesheet",
    timesheetId
  );
  useEffect(() => {
    if (!data?.financials) return;

    const { periodStart, periodEnd } = TimeSheets.getPeriodFrame(
      data?.financials?.payroll?.period,
      data?.financials?.period?.start,
      moment(date).format()
    );
    setPeriod(
      `(${moment(periodStart).format("MM/DD/YYYY")} -
          ${moment(periodEnd).format("MM/DD/YYYY")})`
    );
  }, [date, data]);
  return (
    <div>
      <History
        history={history}
        title="Timesheet"
        updateComment={updateComment}
        currentUser={currentUser}
        period={period}
      />
    </div>
  );
};

TimesheetHistory.propTypes = {
  usersDict: PropTypes.shape({}),
  currentUser: PropTypes.shape({}),
};

TimesheetHistory.defaultProps = {
  usersDict: undefined,
  currentUser: undefined,
};

export default TimesheetHistory;

import React from "react";
import PropTypes from "prop-types";
import IconButton from "../Buttons/IconButton";
import Pill from "../Pill/Pill";

/**
 * Component that render given icon in top navigation bar
 */
const TopNavBarIcon = ({
  icon,
  amount,
  className,
  iconClassName,
  hidden,
  onClick,
}) => {
  return (
    <div
      className={`flex justify-center items-center ${className ?? ""} ${
        hidden ? "hidden" : ""
      }`}
    >
      <IconButton icon={icon} imgClassName={iconClassName} onClick={onClick} />
      {amount > 0 && (
        <div className="relative w-0" style={{ top: "-10px", right: "7px" }}>
          <Pill
            className="flex justify-center items-center h-5 border border-brandGreen"
            background="bg-black"
            textSize="text-xs"
            textWeight="font-medium"
            value={amount > 99 ? "99+" : amount}
          />
        </div>
      )}
    </div>
  );
};

TopNavBarIcon.defaultProps = {
  icon: undefined,
  amount: 0,
  className: undefined,
  iconClassName: undefined,
  hidden: false,
  onClick: undefined,
};

TopNavBarIcon.propTypes = {
  icon: PropTypes.string,
  amount: PropTypes.number,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  hidden: PropTypes.bool,
  onClick: PropTypes.func,
};

export default TopNavBarIcon;

import { useReducer } from "react";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";

const defaultState = {
  title: "",
  description: "",
  propertyType: undefined,
  address: [
    {
      use: "Physical",
      street: "",
      street2: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    },
    {
      use: "Mailing",
      street: "",
      street2: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    },
  ],
  mailingSameAsPhysical: true,
  hoursOfOperation: undefined,
  additionalInfo: [],
  insurance: [],
  files: [],
  members: [],
  vendors: [],
  documents: [],
  originalResource: {},
};

const reducer = (property, action) => {
  let foundIndex;
  switch (action.type) {
    case "reset": {
      const cloned = cloneDeep(action.property);
      return cloned ? { ...cloned, originalResource: cloned } : defaultState;
    }
    case "discard":
      return defaultState;
    case "setCurrentTags": {
      return { ...property, currentTags: action.currentTags };
    }
    case "title":
      return {
        ...property,
        title: action.title,
      };
    case "lotSize":
      return {
        ...property,
        [action.type]: { value: action.lotSize },
      };
    case "edit":
      return {
        ...property,
        [action.key]: action.value,
      };
    case "edit2":
      return {
        ...property,
        [action.key1]: {
          ...property[action.key1],
          [action.key2]: action.value,
        },
      };
    case "addImage":
      return {
        ...property,
        images: [...(property.images ?? []), action.image],
      };
    case "addImages":
      return {
        ...property,
        images: action.images,
      };

    case "deleteImage":
      return {
        ...property,
        images: property.images?.filter(
          (image) => image.file !== action.imageRef
        ),
      };
    case "deleteImages": {
      const imageRefs = action.images?.map((ref) => ref?.file);
      return {
        ...property,
        images: property.images?.filter(
          (image) => imageRefs.indexOf(image?.file) < 0
        ),
      };
    }
    case "changeIsPrimary": {
      return {
        ...property,
        images: property.images?.map((image) => {
          if (image.file === action.id) {
            return {
              ...image,
              isPrimary: action.isPrimary,
            };
          }
          return {
            ...image,
            isPrimary: false,
          };
        }),
      };
    }
    case "addPrimaryImage": {
      return {
        ...property,
        images: [
          action.image,
          ...property.images?.map((image) => {
            return {
              ...image,
              isPrimary: false,
            };
          }),
        ],
      };
    }
    case "area":
      return {
        ...property,
        [action.type]: { value: action.area },
      };
    case "changeHours":
      return {
        ...property,
        [action.type]: action.changeHours,
      };
    case "hoursOfOperation":
      return {
        ...property,
        [action.type]: action.hoursOfOperation,
      };
    case "addInsurance":
      return {
        ...property,
        insurance: [...property.insurance, action.data],
      };
    case "updateInsurance":
      foundIndex = property.insurance?.findIndex(
        (item) => item.id === action.data?.id
      );
      if (foundIndex > -1) {
        return {
          ...property,
          insurance: property.insurance?.map((item) => {
            if (item.id === action.data?.id) {
              return {
                ...item,
                ...action.data,
              };
            }
            return item;
          }),
        };
      }
      return {
        ...property,
        insurance: [...property.insurance, action.data],
      };
    case "isInvestment":
      return {
        ...property,
        [action.type]: action.isInvestment,
      };
    case "purchase":
      return {
        ...property,
        purchase: action.purchase,
      };
    case "purchasePrice":
      return {
        ...property,
        purchase: { ...property.purchase, price: action.purchasePrice },
      };
    case "mortgageAmount":
      return {
        ...property,
        mortgageAmount: action.mortgageAmount,
      };
    case "fiscal":
      return {
        ...property,
        fiscal: action.fiscal,
      };
    case "realtor":
      return {
        ...property,
        realtor: action.realtor,
      };
    case "inspector":
      return {
        ...property,
        inspector: action.inspector,
      };
    case "broker":
      return {
        ...property,
        broker: action.broker,
      };
    case "agent":
      return {
        ...property,
        agent: action.agent,
      };
    case "accountingMethod":
      return {
        ...property,
        accountingMethod: action.accountingMethod,
      };
    case "payrollFrequency":
      return {
        ...property,
        payrollFrequency: action.payrollFrequency,
      };
    case "titleHolder":
      return {
        ...property,
        titleHolder: action.titleHolder,
      };
    case "removeInsurance":
      return {
        ...property,
        insurance: property.insurance?.filter((item) => item.id !== action.id),
      };
    case "idInsurance":
      return {
        ...property,
        insurance: property.insurance?.map((item, idx) => {
          if (idx === action.index) {
            return {
              ...item,
              id: uuidv4(),
            };
          }
          return item;
        }),
      };
    case "lender":
      return {
        ...property,
        lender: action.lender,
      };
    case "bedrooms":
      return {
        ...property,
        bedrooms: action.bedrooms,
      };
    case "bathrooms":
      return {
        ...property,
        bathrooms: action.bathrooms,
      };
    case "description":
      return {
        ...property,
        description: action.description,
      };
    case "propertyType":
      return {
        ...property,
        propertyType: action.propertyType,
      };
    case "mailingSameAsPhysical":
      return {
        ...property,
        mailingSameAsPhysical: action.mailingSameAsPhysical,
      };
    case "address":
      return {
        ...property,
        address: action.address,
      };
    case "interestRate":
      return {
        ...property,
        interestRate: action.interestRate,
      };
    case "addFile":
      return {
        ...property,
        files: [...property.files, action.file],
      };
    case "addFiles":
      return {
        ...property,
        files: [...property.files, ...action.files],
      };
    case "removeFile":
      return {
        ...property,
        files: property.files?.filter((ref) => ref !== action.ref),
      };
    case "ownedBy":
      return {
        ...property,
        ownedBy: [...action.ownedBy],
      };
    case "addDocument":
      return {
        ...property,
        documents: [...(property.documents ?? []), action.document],
      };
    case "addDocuments":
      return {
        ...property,
        documents: [...(property.documents ?? []), ...action.documents],
      };
    case "removeDocument":
      return {
        ...property,
        documents:
          property.documents?.filter((docRef) => docRef !== action.docRef) ??
          [],
      };
    case "addMember":
      return {
        ...property,
        members: [...property.members, action.member],
      };
    case "addMembers":
      return {
        ...property,
        members: action.members,
      };
    case "updateMember":
      foundIndex = property.members?.findIndex(
        (item) => item.user === action.data?.user
      );
      if (foundIndex > -1) {
        return {
          ...property,
          members: property.members?.map((item) => {
            if (item.user === action.data?.user) {
              return {
                ...item,
                ...action.data,
              };
            }
            return item;
          }),
        };
      }
      return {
        ...property,
        members: [...property.members, action.data],
      };
    case "removeMember":
      return {
        ...property,
        members: property.members?.filter(
          (item) => item.user !== action.member
        ),
      };
    //
    case "addVendor":
      return {
        ...property,
        vendors: [...(property.vendors || []), action.vendor],
      };
    case "addVendors":
      return {
        ...property,
        vendors: action.vendors,
      };
    case "updateVendor":
      foundIndex = property.vendors?.findIndex(
        (item) => item.user === action.data?.user
      );
      if (foundIndex > -1) {
        return {
          ...property,
          vendors: property.vendors?.map((item) => {
            if (item.user === action.data?.user) {
              return {
                ...item,
                ...action.data,
              };
            }
            return item;
          }),
        };
      }
      return {
        ...property,
        vendors: [...property.vendors, action.data],
      };
    case "removeVendor":
      return {
        ...property,
        vendors: property.vendors?.filter(
          (item) => item.user !== action.vendor
        ),
      };
    //
    case "buildingName": {
      const index = property.buildings?.findIndex(
        (item) => item.id === action.buildingId
      );
      if (index > -1) {
        return {
          ...property,
          buildings: property.buildings?.map((building, idx) => {
            if (index === idx) {
              return {
                ...building,
                name: action.name,
              };
            }
            return building;
          }),
        };
      }
      return property;
    }
    case "spaceName": {
      return {
        ...property,
        buildings: property.buildings?.map((building) => {
          if (action.buildingId === building.id) {
            return {
              ...building,
              spaces: building?.spaces?.map((space) => {
                if (space.id === action.spaceId) {
                  return {
                    ...space,
                    name: action.name,
                  };
                }
                return space;
              }),
            };
          }
          return building;
        }),
      };
    }
    case "spaceLevel": {
      return {
        ...property,
        buildings: property.buildings?.map((building) => {
          if (action.buildingId === building.id) {
            return {
              ...building,
              spaces: building?.spaces?.map((space) => {
                if (space.id === action.spaceId) {
                  return {
                    ...space,
                    level: action.level,
                  };
                }
                return space;
              }),
            };
          }
          return building;
        }),
      };
    }
    case "spaceType": {
      return {
        ...property,
        buildings: property.buildings?.map((building) => {
          if (action.buildingId === building.id) {
            return {
              ...building,
              spaces: building?.spaces?.map((space) => {
                if (space.id === action.spaceId) {
                  return {
                    ...space,
                    type: action.spaceType,
                  };
                }
                return space;
              }),
            };
          }
          return building;
        }),
      };
    }
    case "addSpaceImage": {
      return {
        ...property,
        buildings: property.buildings?.map((building) => {
          if (action.buildingId === building.id) {
            return {
              ...building,
              spaces: building?.spaces?.map((space) => {
                if (space.id === action.spaceId) {
                  return {
                    ...space,
                    images: [
                      ...space.images,
                      {
                        ...action.image,
                        file: action.image.reference,
                      },
                    ],
                  };
                }
                return space;
              }),
            };
          }
          return building;
        }),
      };
    }
    case "addSpace": {
      return {
        ...property,
        buildings: property.buildings?.map((building) => {
          if (action.buildingId === building.id) {
            return {
              ...building,
              spaces: [
                ...building?.spaces,
                {
                  id: action.spaceId,
                  images: [],
                },
              ],
            };
          }
          return building;
        }),
      };
    }
    case "addBuilding": {
      return {
        ...property,
        buildings: [
          ...property.buildings,
          {
            id: action.buildingId,
            images: [],
            spaces: [],
            name: "Not Specified",
          },
        ],
      };
    }
    case "removeSpace": {
      return {
        ...property,
        buildings: property.buildings?.map((building) => {
          if (action.buildingId === building.id) {
            return {
              ...building,
              spaces: building?.spaces?.filter(
                (space) => space.id !== action.spaceId
              ),
            };
          }
          return building;
        }),
      };
    }
    case "removeBuilding": {
      return {
        ...property,
        buildings: property.buildings?.filter(
          (building) => building.id !== action.buildingId
        ),
      };
    }
    case "buildingArea": {
      const index = property.buildings?.findIndex(
        (item) => item.id === action.buildingId
      );
      if (index > -1) {
        return {
          ...property,
          buildings: property.buildings?.map((building, idx) => {
            if (index === idx) {
              return {
                ...building,
                area: {
                  ...building.area,
                  value: action.area,
                },
              };
            }
            return building;
          }),
        };
      }
      return property;
    }
    case "addBuildingImage": {
      const index = property.buildings?.findIndex(
        (item) => item.id === action.buildingId
      );
      if (index > -1) {
        return {
          ...property,
          buildings: property.buildings?.map((building, idx) => {
            if (index === idx) {
              return {
                ...building,
                images: [
                  ...(building.images?.map((img) => ({
                    ...img,
                    isPrimary: false,
                    selected: false,
                  })) || []),
                  {
                    ...action.image,
                    isPrimary: true,
                    selected: true,
                    file: action.image.reference,
                  },
                ],
              };
            }
            return building;
          }),
        };
      }
      return property;
    }
    case "selectBuildingImage": {
      const index = property.buildings?.findIndex(
        (item) => item.id === action.buildingId
      );
      if (index > -1) {
        return {
          ...property,
          buildings: property.buildings?.map((building, idx) => {
            if (index === idx) {
              return {
                ...building,
                images: [
                  ...building.images?.map((img) => {
                    if (img.reference === action.reference) {
                      return {
                        ...img,
                        selected: true,
                      };
                    }
                    return {
                      ...img,
                      selected: false,
                    };
                  }),
                ],
              };
            }
            return building;
          }),
        };
      }
      return property;
    }
    case "changeBuildingPrimaryImage": {
      return {
        ...property,
        buildings: property.buildings?.map((building) => {
          if (building.id === action.buildingId) {
            return {
              ...building,
              images:
                building?.images?.map((img) => {
                  if (img.id === action.imageId) {
                    return {
                      ...img,
                      isPrimary: true,
                    };
                  }
                  return {
                    ...img,
                    isPrimary: false,
                  };
                }) || [],
            };
          }
          return building;
        }),
      };
    }
    case "addLinkObject":
      return {
        ...property,
        links: [...(property.links ?? []), action.link],
      };
    case "addLinkCompleteLink":
      return {
        ...property,
        links: [...(property.links ?? []), action.link],
      };
    case "addLink":
      return {
        ...property,
        links: [
          ...(property.links ?? []),
          { id: action.id, name: action.name, url: action.url },
        ],
      };
    case "changeLink": {
      return {
        ...property,
        links: property.links?.map((link) => {
          if (link.id === action.link?.id) {
            return action.link;
          }
          return link;
        }),
      };
    }
    case "deleteLink":
      return {
        ...property,
        links:
          property.links?.filter((link) => link.id !== action.linkId) ?? [],
      };
    case "filterLinks":
      return {
        ...property,
        links: property.links.filter((link) => link.name || link.url),
      };
    case "type": {
      const newInfo = property.additionalInfo?.filter(
        (m) => m.detail !== "Types"
      );
      if (action.value) {
        newInfo.push({
          detail: "Types",
          value: action.value,
          unit: action.unit,
        });
      }

      return {
        ...property,
        additionalInfo: newInfo,
        category: action.category,
        subcategory: action.subcategory,
      };
    }
    case "measurement": {
      if (action?.operation === "remove") {
        return {
          ...property,
          additionalInfo: property.additionalInfo?.filter(
            (item) => item?.id !== action.id
          ),
        };
      }
      const elementIndex = property.additionalInfo.findIndex(
        (item) => item.id === action.id
      );
      if (elementIndex > -1) {
        return {
          ...property,
          additionalInfo: property.additionalInfo?.map((item, idx) => {
            if (idx === elementIndex) {
              return {
                id: action.id,
                ...(action.value && { value: action.value }),
                ...(action.optionId && { optionId: action.optionId }),
                ...(action.unitId && { unitId: action.unitId }),
              };
            }
            return item;
          }),
        };
      }
      return {
        ...property,
        additionalInfo: [
          ...property.additionalInfo,
          {
            id: action.id,
            ...(action.value && { value: action.value }),
            ...(action.optionId && { optionId: action.optionId }),
            ...(action.unitId && { unitId: action.unitId }),
          },
        ],
      };
    }
    case "timezone":
      return { ...property, timezone: action.timezone };
    default:
      return property;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};

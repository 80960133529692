import moment from "moment";
import React, { useMemo } from "react";
import { CALENDAR_STROKE } from "../../../../constants";
import AllDayEvent from "../AllDayEvent";
import useRelativeAssociations from "../../../../hooks/useRelativeAssociations";
import { useAppState } from "../../../../state/appState";

const DayHeader = ({ value, events, handleEventClick, offset, setOffset }) => {
  const cellHeight = 755 % 35;
  const cellWidth = 280 / 7;
  const width = 280;
  const [{ calendarTimezone }] = useAppState();
  const { eventFilter: filter } = useRelativeAssociations();
  const allDayEvents = useMemo(() => {
    const list = filter(
      events?.[moment.tz(value, calendarTimezone).format()]
    )?.allDay;
    setOffset(list.length);
    return list;
  }, [events, filter, setOffset, value, calendarTimezone]);

  return (
    <svg viewBox={`-0.5 -0.125 296 ${21.375 + offset * 7}`} width="100%">
      <g transform="translate(15,0)">
        <defs>
          <clipPath id="round-corner">
            <rect
              x="-0.1"
              y="0"
              width={width + 0.2}
              height={cellHeight + 10 + offset * 7}
              rx="3"
            />
          </clipPath>
        </defs>
        <rect
          x="-0.1"
          y="0"
          width={width + 0.2}
          height={cellHeight + 5 + offset * 7}
          fill="#f9f9f9"
          rx="3"
          clipPath="url(#round-corner)"
          stroke={CALENDAR_STROKE}
          strokeWidth="0.4"
        />
        <g id="header-cells" clipPath="url(#round-corner)">
          <g
            id={`${moment.tz(value.iso, calendarTimezone).format()}-cell`}
            key={`${moment.tz(value.iso, calendarTimezone).format()}-cell`}
          >
            <rect
              x={3 * cellWidth}
              y="0"
              width={cellWidth}
              height={cellHeight + 10 + offset * 7}
              opacity="10%"
              fill="none"
            />
            <text
              x={3 * cellWidth + cellWidth / 2}
              y="7"
              fontSize="4"
              textAnchor="middle"
              fontWeight="500"
              opacity="100%"
              color="#333333"
            >
              {moment.tz(value, calendarTimezone).format("dddd")}
            </text>
            <text
              x={3 * cellWidth + cellWidth / 2}
              y="14"
              fontSize="4"
              textAnchor="middle"
              fontWeight="500"
              color="#333333"
            >
              {moment.tz(value, calendarTimezone).format("Do")}
            </text>
          </g>
        </g>
        <g id="allDayEvents">
          {allDayEvents.map((dayEvent, index) => {
            return (
              <AllDayEvent
                key={`day-long-event${dayEvent?.id}-${
                  dayEvent?.instanceStartDate || ""
                }`}
                event={dayEvent}
                subIndex={index}
                onClick={handleEventClick}
                dayView
              />
            );
          })}
        </g>
        <rect
          id="bottom-border"
          width={width + 0.2}
          y={21 + offset * 7}
          height={0.25}
          fill={CALENDAR_STROKE}
        />
      </g>
    </svg>
  );
};

export default DayHeader;

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import DetailCard from "../DetailCard/DetailCard";
import BuildingEditForm from "../LocationView/BuildingEditForm";
import BuildingsView from "../LocationView/BuildingsView";
import { resolveFileRef } from "../../../helpers/File";

const BudgetLocations = ({ project, dispatch, managementConfiguration }) => {
  const [editBuildingId, setEditBuildingId] = useState(null);
  const [spaceTypes, setSpaceTypes] = useState([]);
  const [levels, setLevels] = useState([]);

  useEffect(() => {
    if (managementConfiguration?.management) {
      setSpaceTypes(
        managementConfiguration?.management?.propertySpace.types
          .filter((type) => type.selected)
          .map((type) => ({
            label: type.display,
            value: type.id,
          }))
      );

      setLevels(
        managementConfiguration?.management?.propertyLevel.types
          .filter((type) => type.selected)
          .map((type) => ({
            label: type.display,
            value: type.id,
          }))
      );
    }
  }, [managementConfiguration]);

  const handleEdit = (key, value) => {
    dispatch({
      type: "edit",
      key,
      value,
    });
  };

  const resetProjectState = useCallback(() => {
    dispatch({
      type: "reset",
      project,
    });
  }, [project, dispatch]);

  const handleAddBuildingImage = useCallback(
    async (image, buildingId) => {
      const data = await resolveFileRef(image);
      dispatch({
        type: "addBuildingImage",
        buildingId,
        image: data,
      });
    },
    [dispatch]
  );

  const handleAddSpaceImage = useCallback(
    async (image, buildingId, spaceId) => {
      const data = await resolveFileRef(image);

      dispatch({
        type: "addSpaceImage",
        buildingId,
        spaceId,
        image: data,
      });
    },
    [dispatch]
  );

  return (
    <div className="w-full">
      {editBuildingId ? (
        <BuildingEditForm
          building={project?.buildings?.find(
            (building) => building?.id === editBuildingId
          )}
          buildings={project?.buildings}
          setEditBuildingId={setEditBuildingId}
          spaceTypes={spaceTypes}
          levels={levels}
          onChange={handleEdit}
          dispatch={dispatch}
          handleAddBuildingImage={handleAddBuildingImage}
          handleAddSpaceImage={handleAddSpaceImage}
          resetPropertyState={resetProjectState}
        />
      ) : (
        <DetailCard
          title="Locations"
          Component={
            <BuildingsView
              buildings={project?.buildings}
              dispatch={dispatch}
              setEditBuildingId={setEditBuildingId}
            />
          }
        />
      )}
    </div>
  );
};

BudgetLocations.propTypes = {
  project: PropTypes.shape({
    buildings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }),
  dispatch: PropTypes.func,
  managementConfiguration: PropTypes.shape({
    management: PropTypes.shape({
      propertySpace: PropTypes.shape({
        types: PropTypes.arrayOf(
          PropTypes.shape({
            selected: PropTypes.string,
            id: PropTypes.string,
            display: PropTypes.string,
          })
        ),
      }),
      propertyLevel: PropTypes.shape({
        types: PropTypes.arrayOf(
          PropTypes.shape({
            selected: PropTypes.string,
            id: PropTypes.string,
            display: PropTypes.string,
          })
        ),
      }),
    }),
  }),
};

BudgetLocations.defaultProps = {
  project: undefined,
  dispatch: undefined,
  managementConfiguration: undefined,
};
export default BudgetLocations;

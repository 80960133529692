import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import moment from "moment";

const headerTitleCN = cntl`
    text-gray-400
    font-medium	
    text-lg	
    mb-1
 `;
const headerSubTitleCN = cntl`
    text-gray-500
    font-medium	
 `;

const getActionAndUser = (workflowData) => {
  if (workflowData?.isDraft && workflowData?.modifiedBy)
    return `Modified by ${workflowData?.user}`;
  if (workflowData?.isDraft && !workflowData?.modifiedBy)
    return `Created by ${workflowData?.user}`;
  return `Initiated by ${workflowData?.user || ""}`;
};

const getActionDate = (workflowData) => {
  if (workflowData?.isDraft && workflowData?.modifiedBy)
    return `${moment(workflowData?.modifiedDate).format(
      "MMM D, YYYY"
    )} at ${moment(workflowData?.modifiedDate).format("h:mm A")}`;
  if (workflowData?.isDraft && !workflowData?.modifiedBy)
    return `${moment(workflowData?.dateCreated).format(
      "MMM D, YYYY"
    )} at ${moment(workflowData?.dateCreated).format("h:mm A")}`;
  return `${moment(workflowData?.initiatedDate).format(
    "MMM D, YYYY"
  )} at ${moment(workflowData?.initiatedDate).format("h:mm A")}`;
};

const DateAndName = ({ workflowData }) => {
  return (
    <div className="ml-4">
      <h3 className={headerTitleCN}>{getActionAndUser(workflowData)}</h3>
      <h3 className={headerSubTitleCN}>{getActionDate(workflowData)}</h3>
    </div>
  );
};

DateAndName.propTypes = {
  /**
   * information about the workflow latest version
   */
  workflowData: PropTypes.shape({
    user: PropTypes.string,
    modifiedBy: PropTypes.string,
    modifiedDate: PropTypes.string,
    initiatedDate: PropTypes.string,
    dateCreated: PropTypes.string,
    isDraft: PropTypes.bool,
  }),
};

DateAndName.defaultProps = {
  workflowData: undefined,
};

export default DateAndName;

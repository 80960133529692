import { AssetAPI } from "@griffingroupglobal/eslib-api";
import { toastError } from "../stories/Components/Toast/Toast";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import useCurrentUser from "./useCurrentUser";

/**
 * Fecth all assets categories
 * @returns array of assets categories
 */
const fetchAssetsCategories = async () => {
  const { data } = await AssetAPI.getWOP("categories");

  if (data?.categories) {
    return data.categories;
  }

  return [];
};

/**
 * Query hook to fetch all assets categories
 * @param {Function} select (?) custom function to transform data
 */
const useAssetsCategories = ({ select } = {}) => {
  const { data: currentUser } = useCurrentUser();

  const canReadAssetsCategories =
    currentUser?.isAdmin || currentUser?.permissions?.asset?.can_read;

  const queryKey = ["assets", "categories"];

  return useAuthenticatedQuery({
    queryKey,
    queryFn: fetchAssetsCategories,
    enabled: !!canReadAssetsCategories,
    select,
    onError: (error) => {
      toastError(
        "There was an issue getting assets categories. Please try again"
      );
      console.error("useAssetsCategories", error);
    },
  });
};

export default useAssetsCategories;

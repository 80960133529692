// APIs
import moment from "moment";

// Framework Tools
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router";

// Libraries

// Hooks
import useRequestFormReducer from "../../hooks/useRequestFormReducer";
import { useAppState } from "../../state/appState";

// Constants
import {
  SET_SUBMITTAL_MODAL_STATUS,
  SUBMITTALS_TABLE_PATH,
  checkRequestStatus,
  SUBMITTAL_STATUS_TYPES,
} from "../../constants";

// helpers
import { getFullName } from "../../helpers/Formatters";
import { onUpdateFile } from "../../helpers/File";
import { isSubmittalStartDateInPast } from "../../helpers/Submittal";
// Components
import RequestCompleteModal from "../../stories/Components/Requests/RequestModals/RequestCompleteModal";
import ModalConfirmAll from "../../stories/Components/Modal/ModalConfirmAll";
import Modal from "../../stories/Components/Modal/Modal";
import SiteHeader from "../../stories/Components/SiteHeader/SiteHeader";
import InlineInput from "../../stories/Components/Input/InlineInput";
import PrimaryButton from "../../stories/Components/Buttons/PrimaryButton";
import WidgetContainer from "../../stories/Components/Widget/WidgetContainer";
import Pill from "../../stories/Components/Pill/Pill";

import FilesTable from "../../stories/Components/FilesTable/FilesTable";
import useAssociatedFiles from "../../hooks/useAssociatedFiles";
import { toastMessage } from "../../stories/Components/Toast/Toast";
import SubmittalsDetails from "./SubmittalsDetails";
import SubmittalWorkflow from "./SubmittalWorkflow";
import SubmittalStatusPill from "./SubmittalStatusPill";
import PopoverComments from "../../stories/Components/PopoverComments";
import SubmittalHistory from "./SubmittalHistory";
import { useSubmittalComments } from "../../hooks/useComments";
import useCurrentUser from "../../hooks/useCurrentUser";
import {
  useActionOnSubmittalStep,
  useCompleteSubmittal,
  useDeleteSubmittal,
  useGetSubmittalById,
  useInitiateSubmittal,
  usePatchSubmittal,
  useSendReminderSubmittal,
  useSubmitSubmittalStep,
  useVoidSubmittal,
} from "../../hooks/useSubmittalById.new";
import { usePostSubmittal, useSubmittals } from "../../hooks/useSubmittal.new";
import { useSubmittalHistory } from "../../hooks/useHistory";
import useManagementConfiguration from "../../hooks/useManagementConfiguration";
import { getLocationState } from "../../helpers/Navigation";
import clearSearchField from "../../helpers/clearSearchField";
import { useProjectsOverview } from "../../hooks/projects";
import useQueryNotFoundNavigation from "../../hooks/navigation/useQueryNotFoundNavigation";

export default function SubmittalsDetailView() {
  const { submittalId } = useParams();
  const { state } = useLocation();
  const { isCommentNotification } = getLocationState(state);
  const history = useHistory();

  // Hooks
  const resourceReference = `Submittal/${submittalId}`;

  const params = useMemo(
    () => ({ association: resourceReference }),
    [resourceReference]
  );

  const {
    associatedFiles,
    addFiles,
    patchFile,
    removeFilesAndUpdateApi,
    cloneFile,
  } = useAssociatedFiles(params);
  const {
    data: rqSubmittal,
    isLoading,
    error,
    refetch,
  } = useGetSubmittalById(submittalId);

  // Redirect to 404 page if resource is not found
  useQueryNotFoundNavigation({ error });

  const { mutate: initiateById, isLoading: isInitiating } =
    useInitiateSubmittal();
  const { mutate: submittalActionComplete, isLoading: isCompleting } =
    useCompleteSubmittal();
  const { mutateAsync: submittalActionSubmit, isLoading: isSubmitting } =
    useSubmitSubmittalStep();
  const { mutateAsync: submittalActionReview, isLoading: isPendingStepAction } =
    useActionOnSubmittalStep();

  const { mutate: sendReminder } = useSendReminderSubmittal();
  const { data: currentUser } = useCurrentUser();
  const { data: managementConfiguration } = useManagementConfiguration();
  const { mutate: createSubmittal } = usePostSubmittal();
  const { mutateAsync: patchSubmittal, isLoading: isUpdating } =
    usePatchSubmittal();
  const { mutate: deleteSubmittal } = useDeleteSubmittal();
  const { data: rqData } = useSubmittals();
  const { mutate: voidSubmittal } = useVoidSubmittal();
  const { data: submittalHistory } = useSubmittalHistory(
    `Submittal/${submittalId}`
  );

  const [submittalForm, submittalFormDispatch] = useRequestFormReducer();
  const [submittal, setSubmittal] = useState({});

  const { data: commentMap } = useSubmittalComments(submittal?.reference);

  const project = useMemo(() => {
    return rqSubmittal?.project ?? {};
  }, [rqSubmittal?.project]);
  const distroList = useMemo(() => {
    return rqSubmittal?.distroList ?? [];
  }, [rqSubmittal?.distroList]);
  const type = useMemo(() => {
    return rqSubmittal?.type ?? {};
  }, [rqSubmittal?.type]);
  const initiated = useMemo(() => {
    return rqSubmittal?.initiated ?? {};
  }, [rqSubmittal?.initiated]);
  const ballInCourt = useMemo(() => {
    return rqSubmittal?.ballInCourt ?? [];
  }, [rqSubmittal?.ballInCourt]);
  const workflowSteps = useMemo(() => {
    return rqSubmittal?.workflowSteps ?? [];
  }, [rqSubmittal?.workflowSteps]);
  const submittalDD = useMemo(() => {
    return rqData?.submittalDD ?? {};
  }, [rqData?.submittalDD]);

  // State controls
  const [{ userDict, submittalModalStatus }, dispatch] = useAppState();

  const { projectsDD: projectDD } = useProjectsOverview();

  const [loading, setLoading] = useState(true);
  const [attachmentMap, setAttachmentMap] = useState({});
  const [editing, setEditing] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [canComplete, setCanComplete] = useState(false);
  const [canInitiate, setCanInitiate] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [submittalVoid, setSubmittalVoid] = useState(false);
  const [dropdownItems, setDropDownItems] = useState([]);
  const [subMenuOptions, setSubMenuOptions] = useState(false);
  const [projectMembers, setProjectMembers] = useState([]);
  const [editedSubmittal, setEditedSubmittal] = useState([]);
  const [submittalTypes, setSubmittalTypes] = useState([]);
  const [showInitiateModal, setShowInitiateModal] = useState(false);
  const [showCommentsPopover, setShowCommentsPopover] = useState(
    isCommentNotification
  );

  useEffect(() => {
    if (submittalModalStatus?.activeTabIndex === 0) {
      setActiveTabIndex(0);
      dispatch({
        type: SET_SUBMITTAL_MODAL_STATUS,
        activeTabIndex: undefined,
      });
    }
  }, [dispatch, submittalModalStatus?.activeTabIndex]);

  // Clone behavior before RQ migration
  useEffect(() => {
    setLoading(
      isLoading ||
        isInitiating ||
        isCompleting ||
        isSubmitting ||
        isPendingStepAction ||
        isUpdating
    );
  }, [
    isInitiating,
    isLoading,
    isCompleting,
    isSubmitting,
    isPendingStepAction,
    isUpdating,
  ]);

  // Clone behavior before RQ migration
  useEffect(() => {
    if (rqSubmittal) {
      setSubmittal(rqSubmittal?.submittal);
      setAttachmentMap(rqSubmittal?.attachmentMap);
    }
  }, [rqSubmittal]);

  useEffect(() => {
    setEditedSubmittal(
      rqData?.submittals?.find((submit) => submit?.id === submittalId)
    );
  }, [rqData?.submittals, submittalId]);

  const getSubMenuItems = useCallback(() => {
    const options = [];
    options.push({
      title: "RFI",
      onClick: () => {},
    });
    options.push({
      title: "RFP",
      onClick: () => {},
    });
    options.push({
      title: "Submittal",
      onClick: () => {},
    });
    options.push({
      title: "Contingency Authorization",
      onClick: () => {},
    });
    options.push({
      title: "Purchase Authorization",
      onClick: () => {},
    });
    options.push({
      title: "Change Order",
      onClick: () => {},
    });
    return options;
  }, []);

  const handleSendReminder = useCallback(
    (note, userArr) => {
      sendReminder({ submittalId: submittal.id, note, userArr });
    },
    [sendReminder, submittal.id]
  );

  const onCompleteSubmittal = ({ impacts, status, comment }) => {
    submittalActionComplete({
      submittalId: submittal?.id,
      submittalImpacts: impacts,
      submittalStatus: status,
      comment,
    });
  };

  const handleDeleteClick = useCallback(() => {
    setShowConfirm(true);
  }, []);

  const handleVoidClick = useCallback(() => {
    setShowConfirm(true);
    setSubmittalVoid(true);
  }, []);

  const handleInitiateSubmittal = useCallback(async () => {
    setShowInitiateModal(false);
    initiateById(submittal);
  }, [initiateById, submittal]);

  const handleCompleteSubmittal = useCallback(() => {
    dispatch({
      type: SET_SUBMITTAL_MODAL_STATUS,
      complete: true,
    });
  }, [dispatch]);

  /* set submittal types */
  useEffect(() => {
    const types = managementConfiguration?.management?.submittal?.types;
    const dropdownTypes = types?.map((stype) => {
      return { label: stype?.display, value: stype?.id };
    });
    setSubmittalTypes(dropdownTypes);
  }, [managementConfiguration?.management?.submittal?.types]);

  /* checks if submittal is in-draft or in-progress and sets isDraft for determining editable fields in edit mode */
  useEffect(() => {
    setIsDraft(submittal?.status?.includes("draft"));
  }, [submittal?.status]);

  /* dispatches current submittal to requestForm */
  useEffect(() => {
    submittalFormDispatch({
      type: "edit",
      payload: submittal,
    });
  }, [submittal, submittalFormDispatch]);

  useEffect(() => {
    const subOptions = getSubMenuItems();
    setSubMenuOptions(subOptions);
  }, [getSubMenuItems]);

  useEffect(() => {
    if (
      submittal?.status?.includes(SUBMITTAL_STATUS_TYPES?.COMPLETED_OPEN) ||
      submittal?.status?.includes(SUBMITTAL_STATUS_TYPES?.COMPLETED_RESOLVED) ||
      submittal?.status?.includes(SUBMITTAL_STATUS_TYPES?.IN_PROGRESS) ||
      submittal?.status?.includes(SUBMITTAL_STATUS_TYPES?.VOID)
    ) {
      setDisableEdit(true);
    } else {
      setDisableEdit(false);
    }
  }, [submittal?.status]);

  // This watches the submittal for changes and checks all the required info to verify if submittal can be initiated
  useEffect(() => {
    const verifyInitiate = (data) => {
      const draft = Boolean(data?.status === "draft"); // submittal must be draft to initiate
      const hasAssociation = Boolean(data?.association?.trim()); // must have an association
      const hasType = Boolean(data?.type?.trim()); // must have type
      const hasTitle = Boolean(data?.name?.trim()); // must have title
      const hasCustomName = Boolean(data?.customName?.trim()); // must have a customName
      const hasStartDate = Boolean(data?.startDate?.actual); // must have a startDate

      /* This checks every step to ensure it has a user and every user has a role */
      const stepsVerified = data?.requestWorkflow?.[0]?.steps?.every(
        (step) =>
          step?.users?.length > 0 &&
          step?.users?.every((user) => Boolean(user?.type?.trim()))
      );
      /* If all conditions are met set state value canInitiate to true */
      const submittalCanInitiate =
        draft &&
        hasAssociation &&
        hasType &&
        hasTitle &&
        hasCustomName &&
        hasStartDate &&
        stepsVerified;
      setCanInitiate(submittalCanInitiate);
    };
    // function is called everytime submittal changes
    verifyInitiate(submittal);
  }, [submittal]);

  const handleCloneSubmittal = useCallback(() => {
    const {
      association: associationClone,
      customName,
      type: typeClone,
      requestWorkflow,
      distribution,
      description,
    } = submittal;

    const newSteps = requestWorkflow?.[0]?.steps?.map((stp, index) => ({
      position: stp?.position,
      description: stp?.description,
      duration: {
        projected: stp?.duration?.projected || 0,
      },
      users: stp?.users?.map((user) => ({ ...user, status: "waiting" })),
      dueDate: {
        projected:
          index === 0
            ? moment(submittal?.startDate?.actual).add(
                stp?.duration?.projected || 0,
                "days"
              )
            : moment(
                requestWorkflow?.[0]?.steps[index - 1]?.dueDate?.projected
              ).add(stp?.duration?.projected || 0, "days"),
      },
    }));

    const clonedSubmittal = {
      description,
      customName: `${customName} (Clone)`,
      association: associationClone,
      type: typeClone,
      distribution,
      startDate: {
        actual: moment().toISOString(),
      },
      requestWorkflow: [{ steps: newSteps }],
      status: "draft",
    };

    createSubmittal(clonedSubmittal, {
      onSuccess: (response) => {
        // Always set tabIndex to 0 after creating a new submittal
        dispatch({
          type: SET_SUBMITTAL_MODAL_STATUS,
          activeTabIndex: 0,
        });
        history.push(`/submittals/${response.id}`, { activeTabIndex: 0 });
      },
    });
  }, [createSubmittal, dispatch, history, submittal]);

  // This watches submitttal, can complete, can delete, and can initiate and sets values for dropdown action buttons
  useEffect(() => {
    const submittalActions = () => {
      const options = [];

      if (
        currentUser &&
        currentUser?.permissions?.submittal?.can_update // user must be able to write to submittals to perform these actions
      ) {
        if (
          submittal?.status === SUBMITTAL_STATUS_TYPES?.COMPLETED_OPEN ||
          submittal?.status === SUBMITTAL_STATUS_TYPES?.COMPLETED_RESOLVED
        ) {
          options.push({
            title: "Clone",
            onClick: () => {
              handleCloneSubmittal();
            },
          });
        }

        if (
          canInitiate &&
          currentUser?.permissions?.submittal?.can_update &&
          submittal?.status === SUBMITTAL_STATUS_TYPES?.DRAFT
        ) {
          options.push({
            title: "Initiate",
            onClick: () => {
              // if start date is in the past
              if (isSubmittalStartDateInPast(submittal)) {
                setShowInitiateModal(true);
              } else {
                handleInitiateSubmittal();
              }
            },
          });
        }
        if (
          currentUser?.hasPermission?.("submittal", "can_update") &&
          submittal?.status === SUBMITTAL_STATUS_TYPES?.IN_PROGRESS &&
          canComplete
        ) {
          options.push({
            title: "Complete",
            onClick: () => handleCompleteSubmittal(),
          });
        }
        if (
          currentUser?.hasPermission?.("submittal", "can_update") &&
          submittal?.status === SUBMITTAL_STATUS_TYPES?.DRAFT
        ) {
          options.push({
            title: "Edit",
            onClick: () => {
              setEditing(true);
            },
          });
        }
        // as long as the submittal isn't voided or completed it can be voided
        if (
          currentUser?.hasPermission?.("submittal", "can_update") &&
          submittal?.status !== SUBMITTAL_STATUS_TYPES?.DRAFT &&
          checkRequestStatus(submittal?.status)
        ) {
          options.push({
            title: "Void",
            onClick: () => handleVoidClick(submittal),
          });
        }
      }
      // as long as the submittal isn't voided or completed it can be deleted
      if (
        currentUser?.hasPermission?.("submittal", "can_delete") &&
        submittal?.status !== SUBMITTAL_STATUS_TYPES?.COMPLETED_OPEN &&
        submittal?.status !== SUBMITTAL_STATUS_TYPES?.COMPLETED_RESOLVED &&
        checkRequestStatus(submittal?.status)
      ) {
        if (submittal?.status !== SUBMITTAL_STATUS_TYPES?.IN_PROGRESS)
          options.push({
            title: "Delete",
            onClick: () => handleDeleteClick(submittal),
          });
        options.push({
          title: "Association",
          openSubMenu: true,
        });
      }

      if (options.length === 0) {
        options.push({
          title: "No options available",
        });
      }

      return options;
    };
    const ddOptions = submittalActions();
    setDropDownItems(ddOptions);
  }, [
    canComplete,
    canInitiate,
    currentUser,
    handleCloneSubmittal,
    handleCompleteSubmittal,
    handleDeleteClick,
    handleInitiateSubmittal,
    handleVoidClick,
    submittal,
  ]);

  useEffect(() => {
    // Check if all steps are complete
    const areAllStepsComplete = rqSubmittal?.workflowSteps?.every((step) => {
      return step?.users?.some((user) => user?.status !== "waiting");
    });
    setCanComplete(areAllStepsComplete);
  }, [rqSubmittal?.workflowSteps]);

  useEffect(() => {
    if (!showConfirm) {
      setSubmittalVoid(false);
    }
  }, [showConfirm]);

  useEffect(() => {
    const memRefs = project?.members;
    const members = memRefs?.map((memRef) => userDict?.[memRef?.user]);
    const projectMemDD = members?.map((mem) => {
      const name = getFullName(mem?.name);
      const ref = mem?.reference;
      return { ...mem, label: name, value: ref };
    });

    setProjectMembers(projectMemDD);
  }, [project?.members, userDict]);

  // regular functions
  // delete submittal
  const handleDeleteSubmittal = async () => {
    deleteSubmittal(submittal?.id);
    history.push(SUBMITTALS_TABLE_PATH);
  };

  // void submittal
  const handleVoidSubmittal = async () => {
    voidSubmittal(submittal?.id);
    history.push(SUBMITTALS_TABLE_PATH);
  };
  // checks if a step is complete by look at the users in the step. If any user status !== "waiting" the step is considered complete
  // i.e. step can have multiple users - needs 1 to advance.
  const checkStepComplete = (user) => {
    return user.status !== "waiting";
  };

  /* called when check mark next to title is clicked, patches submittal customName */
  const handleChangeSubmittalTitle = async (value) => {
    submittalFormDispatch({
      type: "customName",
      payload: value,
    });
  };

  /* called when submittal is in edit mode and start date changes to update target completion */
  const handleTargetCompletion = useCallback(() => {
    const start = submittalForm?.startDate?.target;
    const target = moment(start)
      .add(submittal?.duration?.numDays, "days")
      .format("MMMM DD, YYYY");
    return target;
  }, [submittal?.duration?.numDays, submittalForm?.startDate?.target]);

  /* called when submittal is in edit mode and start date changes to update projected completion */
  const handleProjectedCompletion = useCallback(() => {
    const start = submittalForm?.startDate?.projected;
    const projected = moment(start)
      .add(submittal?.duration?.numDays, "days")
      .format("MMMM DD, YYYY");
    return projected;
  }, [submittal?.duration?.numDays, submittalForm?.startDate?.projected]);

  const handleDistroList = useCallback(() => {
    const result = submittalForm?.distribution?.map((ref) => {
      const member = userDict?.[ref];
      return member;
    });
    return result;
  }, [submittalForm?.distribution, userDict]);

  const handleArtifactsList = useCallback(() => {
    const result = submittalForm?.associatedArtifacts
      ?.filter((item) => !item?.isTemplate)
      ?.map((ref) => {
        const sub = rqData?.submittalDict?.[ref];
        return sub;
      });
    return result;
  }, [submittalForm?.associatedArtifacts, rqData?.submittalDict]);

  const handleFinishEditing = () => {
    patchSubmittal(
      {
        updatedResource: submittalForm,
        originalResource: editedSubmittal,
      },
      {
        onSuccess: () => {
          toastMessage("Submittal has been edited successfully");
        },
      }
    );
  };

  const onAddFilesCallback = useCallback(
    async (filesUploaded) => {
      // update associated files state
      addFiles(filesUploaded);

      const newFileRefs = filesUploaded.map((file) => file.reference);
      const updatedFiles = [
        ...(editedSubmittal?.attachments ?? []),
        ...filesUploaded.map((file) => ({
          ref: file.reference,
          category: file.category,
        })),
      ];

      const updatedSubmittal = {
        ...editedSubmittal,
        attachments: updatedFiles,
      };
      // patch resource
      const patchedResult = await patchSubmittal({
        updatedResource: updatedSubmittal,
        originalResource: editedSubmittal,
      });

      if (patchedResult) {
        toastMessage(
          `File changes successfully applied to ${editedSubmittal?.name}`
        );
      } else {
        // remove created files if PATCH fails
        removeFilesAndUpdateApi(newFileRefs);
      }
    },
    [addFiles, editedSubmittal, patchSubmittal, removeFilesAndUpdateApi]
  );

  const removeSubmittalFiles = useCallback(
    async (fileRefs) => {
      const updatedFiles = editedSubmittal?.attachments?.filter(
        (file) => !fileRefs.includes(file.ref)
      );
      const updatedSubmittal = {
        ...editedSubmittal,
        attachments: updatedFiles,
      };

      // patch resource
      const patchedResult = await patchSubmittal({
        updatedResource: updatedSubmittal,
        originalResource: editedSubmittal,
      });

      if (patchedResult) {
        toastMessage(
          `File changes successfully applied to ${editedSubmittal?.name}`
        );
      }
    },
    [editedSubmittal, patchSubmittal]
  );

  const handleFileClone = useCallback(
    async (fileId) => {
      cloneFile(fileId)
        .then(async (clonedFile) => {
          const updatedSubmittal = {
            ...editedSubmittal,
            attachments: [
              ...editedSubmittal?.attachments,
              { ref: clonedFile.reference, category: clonedFile.category },
            ],
          };

          const patchedResult = await patchSubmittal({
            updatedResource: updatedSubmittal,
            originalResource: editedSubmittal,
          });

          return patchedResult;
        })
        .then(() =>
          toastMessage(
            `Recent file successfully attached to ${editedSubmittal?.name}`
          )
        )
        .catch(() => {
          // remove created files if PATCH fails
          removeFilesAndUpdateApi([`File/${fileId}`]);
        });
    },
    [cloneFile, patchSubmittal, editedSubmittal, removeFilesAndUpdateApi]
  );

  const handleUpdateFile = useCallback(
    async ({ originalResource, currentTags, name }) => {
      await onUpdateFile({ originalResource, currentTags, name, patchFile });
      refetch();
    },
    [patchFile, refetch]
  );

  const handleEditClick = useCallback(() => {
    if (editing) {
      // reset original request
      submittalFormDispatch({
        type: "edit",
        payload: editedSubmittal,
      });
    }
    setEditing(!editing);
  }, [editedSubmittal, editing, submittalFormDispatch]);

  const iconsToHide = useMemo(() => {
    return {
      delete:
        submittal?.status === SUBMITTAL_STATUS_TYPES?.VOID ||
        submittal?.status === SUBMITTAL_STATUS_TYPES?.COMPLETED_OPEN ||
        submittal?.status === SUBMITTAL_STATUS_TYPES?.COMPLETED_RESOLVED,
      edit:
        submittal?.status === SUBMITTAL_STATUS_TYPES?.VOID ||
        submittal?.status === SUBMITTAL_STATUS_TYPES?.COMPLETED_OPEN ||
        submittal?.status === SUBMITTAL_STATUS_TYPES?.COMPLETED_RESOLVED,
      upload:
        submittal?.status === SUBMITTAL_STATUS_TYPES?.VOID ||
        submittal?.status === SUBMITTAL_STATUS_TYPES?.COMPLETED_OPEN ||
        submittal?.status === SUBMITTAL_STATUS_TYPES?.COMPLETED_RESOLVED,
    };
  }, [submittal?.status]);

  // Get the status of the step
  const getStepStatus = (step, activeStep) => {
    if (step?.users?.every((user) => user.status === "waiting") && activeStep) {
      return "Action Pending";
    }
    if (step?.users?.every((user) => user.status === "waiting")) {
      return "Upcoming";
    }
    return "Completed";
  };

  // Get the status of the active step
  const getIsActiveStep = useCallback(
    (step, idx) => {
      const activeStepIndex = workflowSteps.findIndex((item) =>
        item.users.every((usr) => usr.status === "waiting")
      );
      const isActiveStep = idx === activeStepIndex;
      return isActiveStep;
    },
    [workflowSteps]
  );

  const tabs = useMemo(() => {
    const permissionedTabs = {
      tabs: [
        {
          title: "Details",
          content: (
            <SubmittalsDetails
              submittal={submittal}
              loading={loading}
              project={project}
              submittalForm={submittalForm}
              submittalFormDispatch={submittalFormDispatch}
              isDraft={isDraft}
              editing={editing}
              projectDD={projectDD}
              handleDistroList={handleDistroList}
              projectMembers={projectMembers}
              ballInCourt={ballInCourt}
              type={type}
              distroList={distroList}
              submittalTypes={submittalTypes}
              initiated={initiated}
              handleArtifactsList={handleArtifactsList}
              submittalDDOptions={submittalDD}
              handleTargetCompletion={handleTargetCompletion}
              handleProjectedCompletion={handleProjectedCompletion}
              submittalHistory={submittalHistory}
              commentMap={commentMap}
            />
          ),
        },
        {
          title: "Workflow",
          content: (
            <SubmittalWorkflow
              loading={loading}
              setLoading={setLoading}
              setAttachmentMap={setAttachmentMap}
              attachmentMap={attachmentMap}
              handleSendReminder={handleSendReminder}
              submittalActionSubmit={submittalActionSubmit}
              submittalActionReview={submittalActionReview}
              patchSubmittal={patchSubmittal}
              editedSubmittal={editedSubmittal}
              commentMap={commentMap}
              editing={editing}
              workflowSteps={workflowSteps}
              checkStepComplete={checkStepComplete}
              submittalForm={submittalForm}
              submittalFormDispatch={submittalFormDispatch}
              projectMembers={projectMembers}
              userDict={userDict}
              submittalHistory={submittalHistory}
              workflow={submittalForm?.requestWorkflow?.[0]}
              requestForm={submittalForm}
              requestFormDispatch={submittalFormDispatch}
              association={submittalForm?.association}
              updateAssociatedFiles={addFiles}
              getStepStatus={getStepStatus}
              getIsActiveStep={getIsActiveStep}
            />
          ),
        },
        {
          title: "History",
          content: (
            <SubmittalHistory
              loading={loading}
              attachmentMap={attachmentMap}
              commentMap={commentMap}
              submittal={submittal}
              userDict={userDict}
            />
          ),
        },
        {
          title: "Files",
          content: (
            <FilesTable
              files={associatedFiles}
              onAddFilesCallback={onAddFilesCallback}
              removeFilesAndUpdateApi={removeFilesAndUpdateApi}
              onRemoveFilesCallback={removeSubmittalFiles}
              resourceName="Submittal"
              association={`Submittal/${submittalId}`}
              hasDeletePermission={currentUser?.hasPermission?.(
                "submittal",
                "can_delete"
              )}
              hasWritePermission={currentUser?.hasPermission?.(
                "submittal",
                "can_create"
              )}
              hasEditPermission
              handleFileClone={handleFileClone}
              handleUpdateFile={handleUpdateFile}
              hideFileActionsIcons={iconsToHide}
            />
          ),
        },
      ],
    };

    permissionedTabs.detailsIndex = 0;
    permissionedTabs.workflowIndex = 1;
    permissionedTabs.historyIndex = 2;
    permissionedTabs.submittalFilesIndex = 3;

    return permissionedTabs;
  }, [
    addFiles,
    associatedFiles,
    attachmentMap,
    ballInCourt,
    commentMap,
    currentUser,
    distroList,
    editedSubmittal,
    editing,
    iconsToHide,
    getIsActiveStep,
    handleArtifactsList,
    handleDistroList,
    handleFileClone,
    handleProjectedCompletion,
    handleSendReminder,
    handleTargetCompletion,
    handleUpdateFile,
    initiated,
    isDraft,
    loading,
    onAddFilesCallback,
    patchSubmittal,
    project,
    projectDD,
    projectMembers,
    removeFilesAndUpdateApi,
    removeSubmittalFiles,
    submittal,
    submittalActionReview,
    submittalActionSubmit,
    submittalDD,
    submittalForm,
    submittalFormDispatch,
    submittalHistory,
    submittalId,
    submittalTypes,
    type,
    userDict,
    workflowSteps,
  ]);

  // set tab index and reset search field
  const handleTabClick = useCallback(
    (index, resourceTabs, resource) => {
      setActiveTabIndex(index);
      clearSearchField(index, resourceTabs, resource);
    },
    [setActiveTabIndex]
  );

  return (
    <>
      <SiteHeader
        title={
          <div className="flex flex-col items-start">
            <InlineInput
              width="w-full"
              size="custom4xl"
              value={submittal?.customName ?? submittal?.name ?? "Loading..."}
              editing={editing}
              loading={false}
              disabled={false}
              fontWeight="bold"
              color="gray-650"
              onConfirm={(val) => handleChangeSubmittalTitle(val)}
              onChangeCallback={(val) => handleChangeSubmittalTitle(val)}
              hidePencil
              isHeaderTitle
            />
            {!!submittal?.status && (
              <div
                id="pill-icon-container"
                className="flex items-center gap-3 mt-1"
              >
                <SubmittalStatusPill
                  submittalStatus={submittal?.status}
                  canComplete={canComplete}
                />
                {submittal?.status !== SUBMITTAL_STATUS_TYPES?.DRAFT &&
                  submittal?.status !== SUBMITTAL_STATUS_TYPES?.VOID && (
                    <Pill
                      value={`Round ${submittal?.round}`}
                      textSize="text-xs"
                      border="border border-gray-450"
                      background="bg-white"
                      textColor="text-gray-450"
                    />
                  )}
              </div>
            )}
          </div>
        }
        buttons={
          submittal?.status !== SUBMITTAL_STATUS_TYPES?.VOID && (
            <PrimaryButton
              disabled={loading}
              title="Actions"
              dropdownItems={dropdownItems}
              className="dropdown-btn"
              large
              subMenuItems={subMenuOptions}
            />
          )
        }
      />
      <WidgetContainer
        disableEditing={
          disableEdit ||
          !currentUser?.hasPermission?.("administrative", "can_write_property")
        }
        loading={loading}
        className="p-4 border-gray-200 shadow-lg border rounded-md"
        style={{ minWidth: "903px" }}
        isEditing={editing}
        handleEditClick={() => handleEditClick()}
        onFinishEditing={() => handleFinishEditing()}
        onOpenComment={() => setShowCommentsPopover(true)}
        tabs={tabs.tabs}
        activeIndex={activeTabIndex}
        onTabClick={(index) => {
          handleTabClick(index, tabs, "Submittal");
        }}
        resetResourceState={() => {}}
      />
      <div className="flex flex-col w-full mt-6">
        <RequestCompleteModal
          requestData={submittal}
          isSubmittal
          onComplete={onCompleteSubmittal}
        />
        <ModalConfirmAll
          showConfirmModal={showConfirm}
          setShowConfirmModal={setShowConfirm}
          modalAction={
            submittalVoid
              ? `Void submittal ${submittal?.customName}`
              : `Delete submittal ${submittal?.customName}`
          }
          noNavigate={
            submittalVoid ? handleVoidSubmittal : handleDeleteSubmittal
          }
          primaryButtonTitle={submittalVoid ? "Void" : "Delete"}
        />
        {showInitiateModal && (
          <Modal
            title="Initiate Submittal"
            isOpen={showInitiateModal}
            primaryButtonTitle="Yes"
            primaryButtonOnClick={handleInitiateSubmittal}
            tertiaryButtonTitle="Cancel"
            onRequestModalClose={() => setShowInitiateModal(false)}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
            alert
            hideFooter
          >
            <>
              <p className="text-base mb-2">
                Would you like to set the start date to today&apos;s date?
              </p>
            </>
          </Modal>
        )}
      </div>

      {showCommentsPopover ? (
        <PopoverComments
          isOpen={showCommentsPopover}
          resourceReference={resourceReference}
          association={submittal?.association}
          onClose={() => setShowCommentsPopover(false)}
        />
      ) : null}
    </>
  );
}

import useAssignees from "../../../hooks/useAssignees";

const useContactViewData = () => {
  const { data: assignedUsersData, isLoading: isLoadingAssigness } =
    useAssignees();

  const assigned = assignedUsersData?.assigned;
  const unassigned = assignedUsersData?.unassigned;

  return {
    assigned,
    unassigned,
    isLoadingAssigness,
  };
};

export default useContactViewData;

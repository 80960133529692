import React from "react";
import PropTypes from "prop-types";
import { components } from "react-select";
import Checkbox from "../Checkbox/Checkbox";

const DropdownOptionCheckbox = ({ rtl, ...props }) => {
  const { isDisabled, isFocused, isSelected, children, innerProps, isMulti } =
    props ?? {};

  const style = {
    alignItems: "center",
    color: "inherit",
    display: "flex ",
  };

  const customInnerProps = {
    ...innerProps,
    style,
  };

  // Render checkbox in left side
  const leftCheckbox = () => (
    <div className="flex w-full gap-x-4 items-center p-2">
      {isMulti && <Checkbox onChange={() => {}} checked={isSelected} />}
      <span>{children}</span>
    </div>
  );

  // Render checkbox in all rigth side
  const rigthCheckbox = () => (
    <div className="flex w-full justify-between items-center p-2">
      <span>{children}</span>
      {isMulti && <Checkbox onChange={() => {}} checked={isSelected} />}
    </div>
  );

  return (
    <components.Option
      {...props}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      innerProps={customInnerProps}
    >
      {rtl ? rigthCheckbox() : leftCheckbox()}
    </components.Option>
  );
};

DropdownOptionCheckbox.defaultProps = {
  rtl: true,
};

DropdownOptionCheckbox.propTypes = {
  /**
   * Allow placing checkbox to left or rigth side
   */
  rtl: PropTypes.bool,
};

export default DropdownOptionCheckbox;

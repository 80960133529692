import moment from "moment/moment";

/**
 * Get the total time off (PTO) for a user within a specific timesheet period.
 *
 * @param {string} userRef - The reference identifier for the user.
 * @param {Array} pto - Array of PTO requests, each containing user reference and time off data.
 * @param {Object} pageState - The current state of the page, containing the timesheet data.
 * @param {Object} pageState.timesheet - The timesheet data for the current period.
 * @param {string} pageState.timesheet.periodStart - The start date of the timesheet period (YYYY-MM-DD format).
 * @param {string} pageState.timesheet.periodEnd - The end date of the timesheet period (YYYY-MM-DD format).
 * @returns {number} - The total hours of time off taken by the user within the timesheet period.
 */

const getTimeoffForUser = (userRef, pto, pageState) => {
  const requestsByUser = pto.filter((request) => request.userRef === userRef);
  const allDates = requestsByUser?.map((request) => request.dates).flat();

  const { periodStart, periodEnd } = pageState.timesheet;

  const startDate = moment.utc(periodStart).format("YYYY-MM-DD");
  const endDate = moment.utc(periodEnd).format("YYYY-MM-DD");

  const filteredRequests = allDates.filter((dateObj) => {
    const currentDate = moment.utc(dateObj.date);
    return currentDate.isBetween(startDate, endDate, "day", "[]");
  });

  return filteredRequests.reduce((total, request) => {
    return total + parseFloat(request?.numHours || 0); // Return updated total instead of reassigning
  }, 0);
};

export default getTimeoffForUser;

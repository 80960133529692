import { useEffect, useMemo, useState } from "react";
import { useAppState } from "../../../../../state/appState";
import { getUserFirstAndInitial } from "../../../../../helpers/Utilities";

const useUserFilterData = ({
  allColumns,
  data,
  currentCol,
  stagedFilters,
  setStagedFilters,
}) => {
  const [{ userDict }] = useAppState();
  // State to display users in key/value form
  const [userOptions, setUserOptions] = useState([]);

  const filterValue = {
    label: currentCol?.filterOptions?.label || currentCol.Header,
    value: currentCol.id,
    equals: currentCol?.filterOptions?.equals || true,
  };

  // Filter options available
  const filterOptions = useMemo(() => {
    return allColumns.reduce((acc, col) => {
      if (
        col?.filterOptions?.filterType &&
        !stagedFilters.some((filter) => filter.id === col.id)
      ) {
        acc.push({
          label: col?.filterOptions?.label || col.Header,
          value: col.id,
          equals: col?.filterOptions?.equals || true,
        });
      }
      return acc;
    }, []);
  }, [allColumns, stagedFilters]);

  // List of users selected in the dropdown
  const usersSelected = useMemo(() => {
    const currentFilter =
      stagedFilters.find((fil) => fil.id === currentCol.id) ?? {};

    return currentFilter?.value?.values?.map((val) => ({
      label: currentFilter?.value?.valueLabels?.[val],
      value: val,
    }));
  }, [stagedFilters, currentCol]);

  // This effect resolves users to be displayed
  useEffect(() => {
    if (!userDict) return;

    // Concatenate the current resource's users with the existing users
    const allTags = data.reduce((acc, resource) => {
      return acc.concat(resource.invitees);
    }, []);

    // Remove duplicate users (if needed)
    const uniqueTags = [...new Set(allTags)];

    const labels = Array.from(uniqueTags).map((usr) => ({
      label: getUserFirstAndInitial(userDict?.[usr]),
      value: usr?.reference || usr,
    }));

    setUserOptions(labels);
  }, [data, userDict]);

  const handleChangeFilterOptions = ({ value, equals }) => {
    setStagedFilters((prev) => {
      if (value) {
        return [
          ...prev.filter((filter) => filter.id !== currentCol.id),
          {
            id: value,
            value: {
              value: equals,
              type: "is",
              isTrue: true,
            },
          },
        ];
      }
      return prev.filter((filter) => filter.id !== currentCol.id);
    });
  };

  const handleSelectedUsers = (values) => {
    setStagedFilters((prev) =>
      prev.map((filter) => {
        if (filter.id === currentCol.id) {
          return {
            ...filter,
            value: {
              ...filter.value,
              type: "includes",
              values: values?.map((v) => v?.value),
              valueLabels: Object.fromEntries(
                values?.map((type) => [type.value, type.label])
              ),
            },
          };
        }
        return filter;
      })
    );
  };

  return {
    filterOptions,
    filterValue,
    userOptions,
    usersSelected,
    handleChangeFilterOptions,
    handleSelectedUsers,
  };
};

export default useUserFilterData;

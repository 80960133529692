import React from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import DropdownWithCheckbox from "../DropdownWithCheckbox";
import useDateRangeDropdownData from "./useDateRangeDropdownData";

const DateRangeDropdown = ({ dateRange, setDateRange, menuPlacement }) => {
  const {
    value,
    options,
    openDateRangePicker,
    startDate,
    endDate,
    handleSetDateRange,
    handleChangeCustomDates,
    setDateOpenDateRangePicker,
  } = useDateRangeDropdownData({
    dateRange,
    setDateRange,
  });

  return (
    <>
      <DropdownWithCheckbox
        options={options}
        value={value}
        setValue={handleSetDateRange}
        isMulti={false}
        menuPlacement={menuPlacement}
      />

      {openDateRangePicker && (
        <div className="absolute top-50 left-0 z-50 shadow-lg">
          <ReactDatePicker
            open={openDateRangePicker}
            selectsRange
            inline
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={handleChangeCustomDates}
            onClickOutside={() => setDateOpenDateRangePicker(false)}
          />
        </div>
      )}
    </>
  );
};

DateRangeDropdown.defaultProps = {
  dateRange: undefined,
  menuPlacement: "auto",
  setDateRange: () => {},
};

DateRangeDropdown.propTypes = {
  dateRange: PropTypes.shape({
    option: PropTypes.oneOf([
      "last7days",
      "last14days",
      "last30days",
      "custom",
    ]),
    custom: PropTypes.shape({
      start: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
      ]),
      end: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    }),
  }),
  menuPlacement: PropTypes.oneOf(["top", "bottom", "auto"]),
  setDateRange: PropTypes.func,
};

export default DateRangeDropdown;

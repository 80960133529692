import React from "react";
import PrimaryHeaderItems from "../../stories/Components/Primary/PrimaryHeaderItems";
import ServiceRequestsTable from "../../stories/Components/ServiceRequestsTable";
import PrimaryButton from "../../stories/Components/Buttons/PrimaryButton";
import useServiceRequestListData from "./useServiceRequestListData";

const ServiceRequestsList = () => {
  const { isLoading, serviceRequestTickets, canCreate, handleOpenModal } =
    useServiceRequestListData();

  return (
    <>
      <PrimaryHeaderItems
        headerTitle="Service Requests"
        buttonTitle="Service Requests"
        customButton={
          canCreate && (
            <PrimaryButton
              addButton
              resourceName="Request"
              onClick={handleOpenModal}
            />
          )
        }
      />

      <ServiceRequestsTable
        serviceRequestTickets={serviceRequestTickets}
        isLoading={isLoading}
      />
    </>
  );
};

export default ServiceRequestsList;

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import Input from "../Input/Input";
import PrimaryButton from "../Buttons/PrimaryButton";
import BaseButton from "../Buttons/BaseButton";
import crossIcon from "../../assets/images/crossIcon.svg";

const DivisionCodesAddCodePopover = ({
  newDivisionCode,
  setNewDivisionCode,
  onFinishAdding,
  onClose,
}) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const checkValidation = async () => {
      try {
        await yup
          .string()
          .required()
          .matches(/^[0-9]{2}$/, "Code must be of type XX")
          .validate(newDivisionCode.code);
        await yup.string().required().validate(newDivisionCode.description);

        setIsValid(true);
      } catch (err) {
        setIsValid(false);
      }
    };

    checkValidation();
  }, [newDivisionCode]);

  const onChangeCode = useCallback(
    (val) => {
      setNewDivisionCode({
        ...newDivisionCode,
        code: val,
      });
    },
    [newDivisionCode, setNewDivisionCode]
  );

  return (
    <div className="flex flex-col border-2 border-gray-100 p-4 mb-4">
      <div className="flex py-2 justify-between">
        <h3 className="uppercase font-bold">Add Division Code</h3>
        <div>
          <BaseButton
            onClick={onClose}
            iconLeft={<img src={crossIcon} alt="crossIcon" />}
          />
        </div>
      </div>
      <div className="flex flex-row py-2 w-full">
        <div className="w-1/6 pr-4">
          <Input
            placeholder="Division Code"
            className="text-gray-300"
            label="Division Code"
            value={newDivisionCode?.code}
            onChange={(val) => onChangeCode(val)}
            showValidationErrorAtBottom
            validation={yup
              .string()
              .required()
              .matches(/^[0-9]{2}$/, "Code must be of type XX")}
            disableClear
          />
        </div>
        <div className="w-full pr-4">
          <Input
            placeholder="Description"
            className="text-gray-300"
            label="Description"
            value={newDivisionCode?.description}
            onChange={(val) =>
              setNewDivisionCode({
                ...newDivisionCode,
                description: val,
              })
            }
            validation={yup.string().required()}
          />
        </div>
        <div className="w-1/6 flex pt-7">
          <PrimaryButton
            title="Add Code"
            onClick={() => onFinishAdding()}
            disabled={!isValid}
          />
        </div>
      </div>
    </div>
  );
};

DivisionCodesAddCodePopover.propTypes = {
  newDivisionCode: PropTypes.shape({
    code: PropTypes.string,
    description: PropTypes.string,
  }),
  setNewDivisionCode: PropTypes.func,
  onFinishAdding: PropTypes.func,
  onClose: PropTypes.func,
  mapping: PropTypes.shape({
    division: PropTypes.string,
  }),
};

DivisionCodesAddCodePopover.defaultProps = {
  newDivisionCode: undefined,
  setNewDivisionCode: undefined,
  onFinishAdding: undefined,
  onClose: undefined,
  mapping: undefined,
};

export default DivisionCodesAddCodePopover;

import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import moment from "moment";
import cntl from "cntl";
import { v4 as uuidv4 } from "uuid";

import useCurrentUser from "../../../hooks/useCurrentUser";
import useDocumentsConfiguration from "../../../hooks/useDocumentsConfiguration";

import DocumentUploadForm from "../DocumentUploadForm/DocumentUploadForm";
import DocumentSection from "../DocumentSection/DocumentSection";
import FileCard from "../FileCard/FileCard";
import FolderCard from "../FolderCard/FolderCard";
import {
  fileButtonPositionsMap,
  fileCardStyle,
  folderCardStyle,
  GET_DOCUMENT_FOLDER_PATH,
} from "../../../constants";

const uploadDocSectionCN = cntl`
  flex
  flex-col
  flex-wrap
  w-full
  mt-5
  mb-12
  overflow-x-auto
  overflow-y-hidden
`;

const docSectionCN = cntl`
  flex
  flex-col
  flex-wrap
  w-full
  mt-5
  mb-12
  overflow-x-auto
  overflow-y-visible
`;
const docSectionAltCN = cntl`
  flex
  flex-wrap
  w-full
  mt-5
  mb-12
`;
const folderSectionCN = cntl`
  flex
  flex-wrap
  w-full
  mt-8
  mb-12
`;
const documentRowStyle = {
  height: "264px",
};
const documentRowContainerStyle = {
  maxHeight: "545px",
};
const tabContainerStyle = {
  marginTop: "20px",
};

const DocumentMgmtFolderView = ({
  favorites,
  documentData,
  selectedDocuments,
  docTypeOptionsMap,
  addedFiles,
  onUpdateDocuments,
  onFileSelect,
  onFileClick,
  onFileEdit,
  onShowHistory,
  onSendToWorkflow,
  onUploadVersion,
  onFileAdd,
  onFileDelete,
  onFavoriteClick,
  hideButtons,
}) => {
  const history = useHistory();
  const { data: currentUser } = useCurrentUser();
  const { data: docConfig } = useDocumentsConfiguration();

  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [currentTabId, setCurrentTabId] = useState("Recents");
  const [visibleFolders, setVisibleFolders] = useState([]);
  const [recentDocuments, setRecentDocuments] = useState([]);
  const [newDocTypes, setNewDocTypes] = useState([]);
  const [showAll, setShowAll] = useState(false);

  const documents = useMemo(() => {
    return documentData?.map((doc) => doc?.original ?? doc);
  }, [documentData]);

  const docTypeCount = React.useCallback(
    (docType) => {
      if (docType === "isFavorited") {
        return documents?.reduce(
          (sum, item) =>
            favorites.map((fav) => fav.item.reference).includes(item.reference)
              ? sum + 1
              : sum,
          0
        );
      }
      if (docType === "isDraft") {
        return documents?.reduce(
          (sum, item) => (item.status === "draft" ? sum + 1 : sum),
          0
        );
      }
      return documents?.reduce(
        (sum, item) => (item.docType === docType?.value ? sum + 1 : sum),
        0
      );
    },
    [documents, favorites]
  );

  useEffect(() => {
    if (documents?.length) {
      let windowOpen;
      let windowClose;

      const now = moment();
      const today = moment().startOf("day").add(6, "hours");
      const uploadedFiles = [];
      const recents = [];

      if (now.isBefore(today)) {
        windowOpen = today.clone().subtract(1, "day").valueOf();
        windowClose = today.valueOf();
      } else {
        windowOpen = today.valueOf();
        windowClose = today.clone().add(1, "day").valueOf();
      }

      documents?.forEach((item) => {
        if (item.metadata?.createdAt) {
          const createdAt = moment(item.metadata.createdAt);
          const createdAtValue = createdAt.valueOf();
          const diff = (today.valueOf() - createdAtValue) / 86400000;

          if (windowOpen <= createdAtValue && createdAtValue <= windowClose) {
            if (item.metadata?.createdBy === `User/${currentUser?.id}`) {
              uploadedFiles.push(item);
            }
          }
          if (diff < 7) {
            recents.push(item);
          }
        }
      });

      setUploadedDocuments([...addedFiles, ...uploadedFiles]);
      setRecentDocuments(recents);
    }
  }, [documents, addedFiles, favorites, currentUser]);

  useEffect(() => {
    if (docConfig) {
      setVisibleFolders([
        {
          id: uuidv4(),
          title: "Favorites",
          docType: "isFavorited",
          itemCount: docTypeCount("isFavorited"),
        },
        {
          id: uuidv4(),
          title: "Drafts",
          docType: "isDraft",
          itemCount: docTypeCount("isDraft"),
        },
        ...[
          ...Object.entries({
            ...docTypeOptionsMap,
            ...newDocTypes.reduce((obj, val) => {
              return { ...obj, [val.value]: val };
            }, {}),
          })?.map(([key, value]) => {
            return {
              id: key,
              title: value.label,
              docType: value.value,
              itemCount: docTypeCount(value),
              isUploadable: false,
            };
          }),
        ].sort((a, b) => a.title - b.title),
      ]);
    }
  }, [docConfig, docTypeOptionsMap, newDocTypes, docTypeCount]);

  const onSelectAllHandle = React.useCallback(() => {
    const ids = documents?.map((document) => document.id);
    onFileSelect(ids);
  }, [documents, onFileSelect]);

  const getDocType = React.useCallback(
    (documentType) => {
      if (documentType) {
        const newDocObj = newDocTypes.reduce((obj, val) => {
          return { ...obj, [val.value]: val };
        }, {});
        const tmpValue =
          {
            ...docTypeOptionsMap,
            ...newDocObj,
          }[documentType]?.label ?? documentType;
        return tmpValue;
      }
      return "File";
    },
    [docTypeOptionsMap, newDocTypes]
  );

  const getDocumentRows = React.useCallback(
    (docList, uploadShow, recentsTab, openAbove) => {
      const docLength = docList?.length;
      const split = docLength > 10;
      const getFile = (doc, openOptionsAbove) => {
        return (
          <div key={doc.id} className="">
            <FileCard
              {...doc}
              docType={getDocType(doc.docType)}
              style={fileCardStyle}
              selectedDocuments={selectedDocuments}
              createdAt={
                doc.metadata?.createdAt
                  ? moment(doc.metadata.createdAt).format("MM/DD/YYYY")
                  : "--"
              }
              isFavorited={
                doc.isFavorited
                  ? doc.isFavorited
                  : favorites.some((fav) => fav.item.reference.includes(doc.id))
              }
              isDraft={doc.status === "draft"}
              onFileSelect={onFileSelect}
              onFileClick={() => onFileClick(doc)}
              onFileEdit={() => onFileEdit(doc)}
              onShowHistory={onShowHistory}
              onSendToWorkflow={() => onSendToWorkflow(doc)}
              onUploadVersion={onUploadVersion}
              onFileDelete={() => onFileDelete(doc)}
              onFavoriteClick={() => onFavoriteClick(doc.id)}
              fileButtonPosition={
                openOptionsAbove
                  ? fileButtonPositionsMap.below
                  : fileButtonPositionsMap.above
              }
              hideButtons={hideButtons}
            />
          </div>
        );
      };

      if (recentsTab && showAll) {
        return docList?.map((doc) => {
          return getFile(doc);
        });
      }

      if (split) {
        const partA = docList?.filter((item, idx) =>
          item && idx < docLength / 2 - uploadShow ? 1 : 0
        );
        const partB = docList?.filter((item, idx) =>
          item && idx >= docLength / 2 - uploadShow ? 1 : 0
        );

        return (
          <>
            <div
              className="flex w-full overflow-x-visible"
              style={documentRowStyle}
            >
              {uploadShow && (
                <DocumentUploadForm
                  onFinishUploading={onUpdateDocuments}
                  onAddFile={onFileAdd}
                  onAddPhoto={onFileAdd}
                  onAddVideo={onFileAdd}
                  showCorner
                  onNewDocType={(val) =>
                    setNewDocTypes((prev) => [val, ...prev])
                  }
                />
              )}
              {partA.map((doc) => {
                return getFile(doc, openAbove);
              })}
            </div>
            <div
              className="flex w-full overflow-x-visible"
              style={documentRowStyle}
            >
              {partB.map((doc) => {
                return getFile(doc, true);
              })}
            </div>
          </>
        );
      }

      return (
        <div
          className="flex w-full overflow-x-visible"
          style={documentRowStyle}
        >
          {uploadShow && (
            <DocumentUploadForm
              onFinishUploading={onUpdateDocuments}
              onAddFile={onFileAdd}
              onAddPhoto={onFileAdd}
              onAddVideo={onFileAdd}
              onNewDocType={(val) => setNewDocTypes((prev) => [val, ...prev])}
              showCorner
            />
          )}
          {docList?.map((doc) => {
            return getFile(doc, uploadShow ? openAbove : !split);
          })}
        </div>
      );
    },
    [
      showAll,
      onUpdateDocuments,
      onFileAdd,
      getDocType,
      selectedDocuments,
      favorites,
      onFileSelect,
      onShowHistory,
      onUploadVersion,
      hideButtons,
      onFileClick,
      onFileEdit,
      onSendToWorkflow,
      onFileDelete,
      onFavoriteClick,
    ]
  );

  const handelShowAll = React.useCallback(() => {
    setShowAll((prev) => !prev);
  }, [setShowAll]);

  const showAllElmt = React.useMemo(
    () => (
      <div className="flex justify-end">
        <button
          className="-mb-6 font-semibold text-brandGreen"
          type="button"
          onClick={handelShowAll}
        >
          {showAll ? "Show Less" : "Show All"}
        </button>
      </div>
    ),
    [handelShowAll, showAll]
  );

  const selectAllElmt = React.useMemo(
    () => (
      <div
        className="hidden"
        tabIndex="0"
        role="button"
        onClick={onSelectAllHandle}
        onKeyDown={onSelectAllHandle}
      >
        Select All
      </div>
    ),
    [onSelectAllHandle]
  );

  const getUploadSection = React.useMemo(
    () => (
      <div className={uploadDocSectionCN} style={documentRowContainerStyle}>
        {getDocumentRows(uploadedDocuments, true, false, true)}
      </div>
    ),
    [uploadedDocuments, getDocumentRows]
  );
  const getTabbedSection = React.useMemo(
    () => [
      {
        id: "Recents",
        title: showAll ? "All" : "Recents",
        pill: {
          value: showAll ? documents?.length : recentDocuments.length,
          background: ["All", "Recents"].includes(currentTabId)
            ? "bg-brandGreen"
            : "bg-gray-200",
        },
        content: (
          <>
            {onFileSelect && selectAllElmt}
            <div
              className={showAll ? docSectionAltCN : docSectionCN}
              style={
                showAll
                  ? {}
                  : { ...documentRowContainerStyle, ...tabContainerStyle }
              }
            >
              {getDocumentRows(
                showAll ? documents : recentDocuments,
                false,
                true
              )}
            </div>
            {showAllElmt}
          </>
        ),
        large: true,
      },
      {
        id: "Favorites",
        title: "Favorites",
        pill: {
          value: documents?.filter((doc) =>
            favorites.map((fav) => fav.item.reference).includes(doc.reference)
          ).length,
          background:
            currentTabId === "Favorites" ? "bg-brandGreen" : "bg-gray-200",
        },
        content: (
          <>
            {onFileSelect && selectAllElmt}
            <div
              className={docSectionCN}
              style={{ ...documentRowContainerStyle, ...tabContainerStyle }}
            >
              {getDocumentRows(
                documents?.filter((doc) =>
                  favorites
                    .map((fav) => fav.item.reference)
                    .includes(doc.reference)
                ),
                false
              )}
            </div>
          </>
        ),
        large: true,
      },
      {
        id: "Drafts",
        title: "Drafts",
        pill: {
          value: documents?.filter((doc) => doc.status === "draft").length,
          background:
            currentTabId === "Drafts" ? "bg-brandGreen" : "bg-gray-200",
        },
        content: (
          <>
            {onFileSelect && selectAllElmt}
            <div
              className={docSectionCN}
              style={{ ...documentRowContainerStyle, ...tabContainerStyle }}
            >
              {getDocumentRows(
                documents?.filter((doc) => doc.status === "draft"),
                false
              )}
            </div>
          </>
        ),
        large: true,
      },
    ],
    [
      documents,
      recentDocuments,
      favorites,
      currentTabId,
      selectAllElmt,
      showAllElmt,
      showAll,
      getDocumentRows,
      onFileSelect,
    ]
  );

  const getFolderPath = React.useCallback(
    (docType) => {
      const docTypeOptions = Object.values(docTypeOptionsMap);
      const foundValue = docTypeOptions.find((item) => item.value === docType);
      const typeIsId = docType.split("-")?.length > 1;

      if (docType === "isDraft") {
        return "drafts";
      }
      if (docType === "isFavorited") {
        return "favorites";
      }
      if (typeIsId) {
        return foundValue.label;
      }

      return docType;
    },
    [docTypeOptionsMap]
  );

  const getFolderSection = useMemo(
    () => (
      <div className={folderSectionCN}>
        {visibleFolders
          .sort((a, b) =>
            a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
          )
          .map((folder) => {
            return (
              <div
                key={folder.docType}
                className={folder.itemCount ? "" : "hidden"}
                style={folderCardStyle}
              >
                <FolderCard
                  {...folder}
                  onClick={() =>
                    history.push(
                      GET_DOCUMENT_FOLDER_PATH(getFolderPath(folder.docType)),
                      { folderName: folder.docType }
                    )
                  }
                />
              </div>
            );
          })}
      </div>
    ),
    [visibleFolders, getFolderPath, history]
  );

  const [cards, setCards] = useState([
    {
      id: "myUploads",
      title: "My Uploads",
      content: getUploadSection,
    },
    {
      id: "tabbedSection",
      title: "Tabbed Section",
      content: getTabbedSection,
      tabbedSection: true,
    },
    {
      id: "folders",
      title: "Folders",
      content: getFolderSection,
    },
  ]);
  const [indices, setIndices] = useState({
    myUploads: 0,
    tabbedSection: 1,
    folders: 2,
  });

  useEffect(() => {
    setCards(() => {
      const newList = [
        {
          id: "myUploads",
          title: "My Uploads",
          content: getUploadSection,
        },
        {
          id: "tabbedSection",
          title: "Tabbed Section",
          content: getTabbedSection,
          tabbedSection: true,
        },
        {
          id: "folders",
          title: "Folders",
          content: getFolderSection,
        },
      ];
      return newList.sort((a, b) => indices[a.id] - indices[b.id]);
    });
  }, [
    documents,
    indices,
    currentTabId,
    getUploadSection,
    getTabbedSection,
    getFolderSection,
  ]);

  const moveCard = React.useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex];
      setCards((prev) => {
        const newCards = [...prev];
        newCards.splice(dragIndex, 1);
        newCards.splice(hoverIndex, 0, dragCard);
        newCards.forEach((card, idx) => {
          setIndices((prevIndices) => {
            return { ...prevIndices, [card.id]: idx };
          });
        });
        return newCards;
      });
    },
    [cards]
  );
  const renderCard = (card, index) => {
    return (
      <div className="min-h-full" key={card.id}>
        <DocumentSection
          index={index}
          {...card}
          moveCard={moveCard}
          onTabClick={setCurrentTabId}
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col mb-12">
      {cards.map((card, i) => {
        return renderCard(card, i);
      })}
    </div>
  );
};

DocumentMgmtFolderView.propTypes = {
  /**
   * array of favortied documents
   */
  // eslint-disable-next-line react/forbid-prop-types
  favorites: PropTypes.arrayOf(PropTypes.object),
  /**
   * array of document attachments
   */
  // eslint-disable-next-line react/forbid-prop-types
  documentData: PropTypes.arrayOf(PropTypes.object),
  /**
   * array of attached documents
   */
  selectedDocuments: PropTypes.arrayOf(PropTypes.string),
  /**
   * map of all document type options
   */
  // eslint-disable-next-line react/forbid-prop-types
  docTypeOptionsMap: PropTypes.object,
  /**
   * array of added files
   */
  // eslint-disable-next-line react/forbid-prop-types
  addedFiles: PropTypes.array,
  /**
   * function called after new document is uploaded
   */
  onUpdateDocuments: PropTypes.func,
  /**
   * function for handling selection of a document
   */
  onFileSelect: PropTypes.func,
  /**
   * function for handling file click
   */
  onFileClick: PropTypes.func,
  /**
   * function for handling file edit
   */
  onFileEdit: PropTypes.func,
  /**
   * function for handling file show history
   */
  onShowHistory: PropTypes.func,
  /**
   * function for handling file send to workflow
   */
  onSendToWorkflow: PropTypes.func,
  /**
   * function for handling file upload version
   */
  onUploadVersion: PropTypes.func,
  /**
   * function for file upload
   */
  onFileAdd: PropTypes.func,
  /**
   * function for handling file delete
   */
  onFileDelete: PropTypes.func,
  /**
   * function for handling favoriting of a document
   */
  onFavoriteClick: PropTypes.func,
  /**
   * boolean for hiding document buttons
   */
  hideButtons: PropTypes.bool,
};

DocumentMgmtFolderView.defaultProps = {
  documentData: [],
  favorites: [],
  selectedDocuments: [],
  docTypeOptionsMap: {},
  addedFiles: [],
  onUpdateDocuments: undefined,
  onFileSelect: undefined,
  onFileClick: undefined,
  onFileEdit: undefined,
  onShowHistory: undefined,
  onSendToWorkflow: undefined,
  onUploadVersion: undefined,
  onFileAdd: () => {},
  onFileDelete: undefined,
  onFavoriteClick: undefined,
  hideButtons: false,
};

export default DocumentMgmtFolderView;

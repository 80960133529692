/* eslint-disable react/jsx-curly-brace-presence */
import React from "react";
import PropTypes from "prop-types";

const NumberInBrackets = ({ count, wrapperClassName }) => {
  return (
    <p className={wrapperClassName}>
      <span>{"("}</span>
      {count}
      <span>{")"}</span>
    </p>
  );
};

NumberInBrackets.propTypes = {
  count: PropTypes.number,
  wrapperClassName: PropTypes.string,
};
NumberInBrackets.defaultProps = {
  count: 0,
  wrapperClassName: "font-semibold mb-0.5",
};

export default NumberInBrackets;

import React from "react";
import PropTypes from "prop-types";
import CrossButton from "../../CrossButton/CrossButton";
import crossIconGreen from "../../../assets/images/closeDarkGreen.svg";
import chevronDown from "../../../assets/images/chevron-down-grey.svg";
import chevronUp from "../../../assets/images/chevron-up-grey.svg";
import searchIcon from "../../../assets/images/magnoliaSearchIcon.svg";
import useOwnedBySearchData from "./useOwnedBySearchData";

const OwnedBySearch = ({
  onChange,
  options,
  value,
  selectedOptions,
  onRemove,
  showPopup,
  toggleList,
  setShowPopup,
  customWidth,
}) => {
  const { displayNames, handleInput } = useOwnedBySearchData({
    selectedOptions,
    onChange,
    options,
    setShowPopup,
    showPopup,
  });

  return (
    <div
      className="flex rounded border border-es-light-grey bg-es-extra-light-grey w-full"
      style={{ width: customWidth, minHeight: "40px" }}
    >
      <div className="flex flex-row pl-3 pt-2 border-r border-es-light-grey w-full">
        <div className="flex flex-row w-full">
          <img
            src={searchIcon}
            className="self-center mb-2"
            alt="search-icon"
            style={{ width: 18, height: 18 }}
          />
          <div className="flex flex-wrap px-3 items-center w-11/12">
            {displayNames?.map((item) => (
              <div
                key={item.value}
                className="border bg-white border-es-light-grey text-es-dark-green rounded-md text-xs flex flex-row px-1 py-0.5 mr-3 mb-2"
              >
                <div className="pr-1">{item.label}</div>
                <CrossButton
                  style={{ height: 16, width: 16 }}
                  className="flex items-center"
                  icon={crossIconGreen}
                  onClick={() => onRemove(item.value)}
                />
              </div>
            ))}
            <input
              className="flex bg-es-extra-light-grey text-xs mb-2 max-w-3/4"
              value={value}
              onInput={handleInput}
              onClick={(e) => {
                e?.stopPropagation();
              }}
            />
          </div>
        </div>
      </div>
      <div
        className="flex relative"
        onClick={toggleList}
        role="button"
        onKeyDown={() => {}}
        tabIndex={0}
        style={{ width: 44 }}
      >
        <img
          style={{ height: 22, width: 22 }}
          src={showPopup ? chevronUp : chevronDown}
          alt="Close"
          className="absolute self-center left-2"
        />
      </div>
    </div>
  );
};

OwnedBySearch.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  value: PropTypes.string,
  onRemove: PropTypes.func,
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      reference: PropTypes.string,
    })
  ),
  showPopup: PropTypes.bool,
  toggleList: PropTypes.func,
  setShowPopup: PropTypes.func,
  customWidth: PropTypes.string,
};

OwnedBySearch.defaultProps = {
  onChange: () => {},
  options: [],
  value: null,
  selectedOptions: [],
  onRemove: undefined,
  showPopup: false,
  toggleList: undefined,
  setShowPopup: undefined,
  customWidth: undefined,
};

export default OwnedBySearch;

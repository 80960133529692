import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

const toggleContainer = (
  toggle,
  toggleOnMainClass,
  toggleOffMainClass,
  toggleSize
) => cntl`
${toggleSize || "w-14 h-7"} 
flex items-center 
rounded-full 
cursor-pointer 
border-2 
${toggle ? toggleOnMainClass : toggleOffMainClass}`;

const toggleCircle = (
  toggle,
  toggleOnClass,
  toggleOffClass,
  toggleCircleSize
) => cntl`
${toggleCircleSize || "w-5 h-5"} 
rounded-full 
shadow-md 
transform 
duration-300 
ease-in-out 
${toggle ? toggleOnClass : toggleOffClass}`;

const toggleOnClass = cntl`
  transform
  translate-x-7
  bg-brandGreen
`;

const toggleOffClass = cntl`
  transform
  translate-x-1
  bg-gray-200
`;

const toggleOnMainClass = cntl`
  border-brandGreen
`;

const toggleOffMainClass = cntl`
  border-gray-200
`;

const ToggleButton = ({
  isChecked,
  onToggle,
  toggleSize,
  toggleCircleSize,
}) => {
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setToggle(isChecked);
  }, [isChecked]);

  const onClick = () => {
    onToggle();
  };

  return (
    <div
      className={toggleContainer(
        toggle,
        toggleOnMainClass,
        toggleOffMainClass,
        toggleSize
      )}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
    >
      {/* Switch */}
      <div
        className={toggleCircle(
          toggle,
          toggleOnClass,
          toggleOffClass,
          toggleCircleSize
        )}
      />
    </div>
  );
};

ToggleButton.propTypes = {
  isChecked: PropTypes.bool,
  onToggle: PropTypes.func,
  toggleSize: PropTypes.string,
  toggleCircleSize: PropTypes.string,
};

ToggleButton.defaultProps = {
  isChecked: false,
  onToggle: () => {},
  toggleSize: undefined,
  toggleCircleSize: undefined,
};

export default ToggleButton;

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Dropdown from "../Dropdown/Dropdown";
import PrimaryButton from "../Buttons/PrimaryButton";
import Checkbox from "../Checkbox/Checkbox";
import BaseButton from "../Buttons/BaseButton";
import crossIcon from "../../assets/images/crossIcon.svg";

const getLength = (number) => {
  return number.toString().length;
};

const CSICodeAndMappingAddMapping = ({
  csiCode,
  expenseAccountingCodes,
  mappingIndex,
  csiCodeIndex,
  dispatchFinancialsSettings,
  setIsAdding,
  divCode,
}) => {
  const [expenseCode, setExpenseCode] = useState(null);
  const [nextSubCode, setNextSubCode] = useState(null);
  const [addAnother, setAddAnother] = useState(false);

  useEffect(() => {
    if (csiCode) {
      const getNextSubCode = () => {
        const subCode = csiCode?.code;
        const children = csiCode?.subCodes;

        let digits;
        if (children.length) {
          const { code } = children[children.length - 1];
          digits = code.split("-");
          let lastCode = parseInt(digits.pop(), 10) + 1;
          lastCode = getLength(lastCode) === 1 ? `0${lastCode}` : lastCode;
          digits.push(lastCode);
        } else {
          digits = subCode.split("-");
          let lastCode = parseInt(digits.pop(), 10) + 1;
          lastCode = getLength(lastCode) === 1 ? `0${lastCode}` : lastCode;
          digits.push(lastCode);
        }

        return digits.join("-");
      };

      const rv = getNextSubCode();

      setNextSubCode(rv);
    }
  }, [csiCode]);

  const onAddCode = useCallback(() => {
    dispatchFinancialsSettings({
      type: "addCSIExpenseCode",
      mappingIndex,
      csiCodeIndex,
      code: nextSubCode,
      expenseCode: expenseCode.value,
    });

    setIsAdding(addAnother);
  }, [
    dispatchFinancialsSettings,
    addAnother,
    csiCodeIndex,
    expenseCode,
    mappingIndex,
    nextSubCode,
    setIsAdding,
  ]);

  return (
    <div className="border-2 rounded border-gray-150 px-4 py-4">
      <div className="flex py-2 px-2 justify-between">
        <h2 className="uppercase font-bold text-lg">
          Map expense code to {csiCode?.description}
        </h2>
        <div>
          <BaseButton
            onClick={() => setIsAdding(false)}
            iconLeft={<img src={crossIcon} alt="crossIcon" />}
          />
        </div>
      </div>
      <div className="flex flex-row w-full px-2">
        <div className="flex-col w-1/5">
          <div className="flex">
            <h3 className="text-gray-200">CSI Code</h3>
          </div>
          <div className="flex py-2">
            <p className="text-gray-200">
              {" "}
              {divCode} {csiCode?.code} {nextSubCode}
            </p>
          </div>
        </div>
        <div className="flex px-4 w-1/3">
          <Dropdown
            label="Expense Accounting Code"
            placeholder="Search"
            options={expenseAccountingCodes?.map((code) => ({
              label: code.description,
              value: code.code,
            }))}
            value={expenseCode}
            onChange={setExpenseCode}
          />
        </div>
        <div className="flex align-bottom flex-col pt-6">
          <PrimaryButton title="Add Code" onClick={onAddCode} />
          <Checkbox
            label="Add Another"
            checked={addAnother}
            onChange={setAddAnother}
            className="py-2"
            labelClassName="text-gray-200 font-medium"
          />
        </div>
      </div>
    </div>
  );
};

CSICodeAndMappingAddMapping.propTypes = {
  csiCode: PropTypes.shape({
    code: PropTypes.string,
    description: PropTypes.string,
    revenueCode: PropTypes.string,
    selectedForBudgets: PropTypes.string,
    selectedForTimesheets: PropTypes.bool,
    subCodes: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        expenseCode: PropTypes.string,
        selectedForBudgets: PropTypes.string,
        selectedForTimesheets: PropTypes.bool,
      })
    ),
  }),
  expenseAccountingCodes: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      codeType: PropTypes.string,
      description: PropTypes.string,
      selectedForBudgets: PropTypes.bool,
      selectedForTimesheets: PropTypes.bool,
    })
  ),
  mappingIndex: PropTypes.number,
  csiCodeIndex: PropTypes.number,
  setIsAdding: PropTypes.func,
  dispatchFinancialsSettings: PropTypes.func,
  divCode: PropTypes.string,
};

CSICodeAndMappingAddMapping.defaultProps = {
  csiCode: undefined,
  expenseAccountingCodes: [],
  mappingIndex: undefined,
  csiCodeIndex: undefined,
  setIsAdding: undefined,
  dispatchFinancialsSettings: undefined,
  divCode: undefined,
};

export default CSICodeAndMappingAddMapping;

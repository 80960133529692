import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import DocumentReviewElement from "../DocumentReviewElement/DocumentReviewElement";
import Checkbox from "../Checkbox/Checkbox";

import {
  SIMPLE_INPUT,
  LIST_ADD_ONE,
  LIST_REMOVE_ONE,
} from "../../../constants";

const RequestForProposalReview = ({
  document,
  dispatch,
  userData,
  associatedResource,
  setAssociatedResource,
  disableAssociation,
  disableEdit,
  editMode,
}) => {
  const [originalValues, setOriginalValues] = useState({});
  const [showRecipients] = useState(false);

  useEffect(() => {
    if (!originalValues.submissionReqs && document?.submissionReqs) {
      setOriginalValues((prev) => ({
        ...prev,
        submissionReqs: document.submissionReqs,
      }));
    }
  }, [document?.submissionReqs, originalValues]);

  const handleSimpleInput = React.useCallback(
    (key, value) => {
      dispatch({
        type: SIMPLE_INPUT,
        key,
        value,
      });
    },
    [dispatch]
  );

  const handleUserListAdd = React.useCallback(
    (key, val) => {
      val.reverse().forEach(({ value }) => {
        dispatch({
          type: LIST_ADD_ONE,
          key,
          value: { id: value },
        });
      });
    },
    [dispatch]
  );

  const handleListAdd = React.useCallback(
    (key, value, append) => {
      dispatch({
        type: LIST_ADD_ONE,
        key,
        value,
        append,
      });
    },
    [dispatch]
  );

  const handleListRemove = React.useCallback(
    (key, value) => {
      dispatch({
        type: LIST_REMOVE_ONE,
        key,
        value,
      });
    },
    [dispatch]
  );

  const handleSaveUpdate = React.useCallback(
    (key, val) => {
      setOriginalValues((prev) => {
        if (Object.keys(prev).includes(key)) {
          const update = document[key];
          if (val && Array.isArray(update)) {
            update.push(val);
          }
          return { ...prev, [key]: update };
        }
        return prev;
      });
    },
    [document]
  );

  const handleCancelUpdate = React.useCallback(
    (key) => {
      const original = originalValues[key];
      if (original) {
        handleSimpleInput(key, original);
      }
    },
    [originalValues, handleSimpleInput]
  );

  const getListDisplay = React.useCallback((list, override) => {
    return (
      <div className="flex flex-col">
        {list
          ?.filter(({ checked }) => checked !== false || override)
          .map(({ text }) => (
            <div className="flex items-center mb-2" key={text}>
              {text}
            </div>
          ))}
      </div>
    );
  }, []);

  const getDueDateDisplay = React.useCallback(() => {
    if (!document.dueDate?.date) return "";
    const dateString = moment(document.dueDate.date).format("MMMM Do, YYYY");
    const timeString = document.dueDate.allDay
      ? "End Of Day"
      : moment(document.dueDate.date).format("hh:mm A");
    return `${dateString} - ${timeString}`;
  }, [document.dueDate]);

  const submissionReqs = React.useMemo(
    () => (
      <div className="grid grid-cols-12 gap-2 mt-2">
        {document.submissionReqs?.map((req) => {
          return (
            <div key={req.id} className="flex col-span-7">
              <div className="flex items-center">
                <Checkbox
                  className=""
                  label={req.text}
                  checked={req.checked}
                  onChange={(checked) =>
                    handleListAdd("submissionReqs", { ...req, checked })
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
    ),
    [document.submissionReqs, handleListAdd]
  );

  const sectionOne = React.useMemo(() => {
    return [
      {
        title: "Association",
        type: "ASSOCIATION",
        displayValue: associatedResource?.name,
        editValue: document.project ?? document.property,
        onChangeAlt: setAssociatedResource,
        associatedResource,
        disableAssociation,
        disableEdit,
      },
      {
        title: "Due Date",
        type: "DUEDATE",
        displayValue: getDueDateDisplay(),
        editValue: document.dueDate,
        onChange: (val) => handleSimpleInput("dueDate", val),
        disableEdit,
      },
      {
        title: "Title",
        type: "INPUT",
        displayValue: document.customName,
        editValue: document.customName,
        onChange: (val) => handleSimpleInput("customName", val),
        disableEdit: disableEdit || editMode,
      },
      {
        title: "Description",
        type: "TEXTAREA",
        displayValue: document.description,
        editValue: document.description,
        onChange: (val) => handleSimpleInput("description", val),
        disableEdit,
      },
      {
        title: "Blockers",
        type: "TEXTAREA",
        displayValue: document.roadblocks,
        editValue: document.roadblocks,
        onChange: (val) => handleSimpleInput("roadblocks", val),
        disableEdit,
      },
    ];
  }, [
    document,
    associatedResource,
    setAssociatedResource,
    handleSimpleInput,
    disableAssociation,
    disableEdit,
    getDueDateDisplay,
    editMode,
  ]);

  const sectionTwo = React.useMemo(() => {
    return [
      ...(showRecipients
        ? [
            {
              title: "Recipients",
              type: "USERSELECT",
              options: userData,
              selectedOptions: document.distro,
              onChange: (val) => handleUserListAdd("distro", val),
              onDelete: (val) => handleListRemove("distro", val),
              disableEdit,
            },
          ]
        : []),
      {
        title: "Submission Requirements",
        type: "CUSTOM",
        displayValue: getListDisplay(document.submissionReqs),
        editField: submissionReqs,
        onSave: () => handleSaveUpdate("submissionReqs"),
        onCancel: () => handleCancelUpdate("submissionReqs"),
        disableEdit,
      },
      {
        title: "Additional Requirements",
        type: "CRITERIA",
        displayValue: getListDisplay(document.additionalRequirements, true),
        editValue: document.additionalRequirements,
        onChange: (val) => handleListAdd("additionalRequirements", val, true),
        onSave: () => handleSaveUpdate("additionalRequirements"),
        onCancel: () => handleCancelUpdate("additionalRequirements"),
        onDelete: (val) => handleListRemove("additionalRequirements", val),
        disableEdit,
      },
    ];
  }, [
    document,
    userData,
    handleUserListAdd,
    handleListAdd,
    handleListRemove,
    handleCancelUpdate,
    handleSaveUpdate,
    submissionReqs,
    getListDisplay,
    disableEdit,
    showRecipients,
  ]);

  return (
    <>
      <div className="flex justify-between">
        <div className="flex-1 flex flex-col pr-4">
          {sectionOne.map((item) => (
            <DocumentReviewElement key={item.title} {...item} />
          ))}
        </div>
        <div className="flex-1 flex flex-col">
          {sectionTwo.map((item) => (
            <DocumentReviewElement key={item.title} {...item} />
          ))}
        </div>
      </div>
    </>
  );
};

RequestForProposalReview.propTypes = {
  /**
   * create document form state
   */
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.object,
  /**
   * create document form dispatcher
   */
  dispatch: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  userData: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  associatedResource: PropTypes.object,
  disableEdit: PropTypes.bool,
  disableAssociation: PropTypes.bool,
  setAssociatedResource: PropTypes.func,
  editMode: PropTypes.bool,
};

RequestForProposalReview.defaultProps = {
  document: {},
  dispatch: undefined,
  userData: undefined,
  associatedResource: undefined,
  disableEdit: false,
  disableAssociation: false,
  setAssociatedResource: undefined,
  editMode: false,
};

export default RequestForProposalReview;

/**
 * Identifies if there is a new item whose value doesn't match the existing pattern before the "/"
 * character in any of the other items. If such an item is found, it returns an array with that item;
 * otherwise, it returns the whole array.
 *
 * @param {Array<Object>} arr - The array of objects to check.
 * @param {string} arr[].id - The ID of the object.
 * @param {string} arr[].label - The label of the object.
 * @param {string} arr[].value - The value of the object, which contains a prefix before the "/".
 * @returns {Array<Object>} An array containing only the new item if it has a unique prefix, or the whole array otherwise.
 */

const findNewItemWithUniquePrefix = (arr) => {
  const prefixMap = {};

  if (arr.length === 0) return [];

  arr.forEach((item) => {
    const prefix = item.value.split("/")[0];
    if (!prefixMap[prefix]) {
      prefixMap[prefix] = [];
    }
    prefixMap[prefix].push(item);
  });

  const newItem = arr[arr.length - 1];
  const newItemPrefix = newItem.value.split("/")[0];

  if (prefixMap[newItemPrefix].length === 1) {
    return [newItem];
  }

  return arr;
};

export default findNewItemWithUniquePrefix;

const combineTsWithUser = (timesheetsList, userDict) => {
  if (!timesheetsList) return [];
  const timeSheetsWithFormattedUsers = timesheetsList.map((ts) => {
    const employeeWithFullName = {
      ...userDict[ts.userRef],
      fullName: `${userDict[ts.userRef]?.name.firstName} ${
        userDict[ts.userRef]?.name.lastName
      }`,
    };

    return {
      ...ts,
      employee: employeeWithFullName,
    };
  });

  return timeSheetsWithFormattedUsers;
};

export default combineTsWithUser;

import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useModalState } from "../../../state/modalState";
import { ADD_OPEN_MODAL, CONFIRM_MODAL } from "../../../constants";

export default ({ initialIndex, files, onRemove, onEdit }) => {
  const [, modalDispatch] = useModalState();
  const [fileIndex, setFileIndex] = useState(initialIndex);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState();
  const [editLoading, setEditLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  useEffect(() => {
    setFileIndex(initialIndex);
  }, [initialIndex]);

  useEffect(() => {
    setIsEditing(false);
    setEditedName(undefined);
  }, [fileIndex]);

  useEffect(() => {
    if (fileIndex > files.length - 1) {
      setFileIndex(files.length - 1);
    }
    if (fileIndex < 0 && files.length > 0) {
      setFileIndex(0);
    }
  }, [fileIndex, files.length]);

  const onEditClick = () => {
    setIsEditing(true);
    setEditedName(files[fileIndex].name);
  };

  const onThumbnailClick = (index) => {
    setFileIndex(index);
  };

  const onNextFile = () => {
    setFileIndex((prevState) => {
      const next = prevState + 1;
      if (next < files.length) {
        return next;
      }
      return 0;
    });
  };

  const onPreviousFile = () => {
    setFileIndex((prevState) => {
      const next = prevState - 1;
      if (next > -1) {
        return next;
      }
      return files.length - 1;
    });
  };

  const onCompleteEdit = async () => {
    const file = files[fileIndex];
    if (file.name !== editedName) {
      setEditLoading(true);
      await onEdit(file, editedName);
      setEditLoading(false);
    }
    setIsEditing(false);
    setEditedName(undefined);
  };

  const onRemoveClick = async (fileId) => {
    setRemoveLoading(true);
    await onRemove(fileId);
    setRemoveLoading(false);
  };
  const downloadAttachment = (attachment) => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      modalType: CONFIRM_MODAL,
      ref: { id: `${uuidv4()}-confirm-modify` },
      modalData: {
        item: {
          prompt: `Do you want to save? \n ${attachment.title}`,
          confirm: "Yes",
          cancel: "No",
          title: "Download Attachment",
          onConfirm: () => window.open(attachment?.asset_url),
        },
      },
      position: { x: 0, y: 0 },
    });
  };

  /**
   * Check if given file is video type
   * @param {Object} file - file to check
   * @returns {boolean}
   */
  const isVideoType = (file) => {
    return (
      file?.type?.includes("video") ||
      file?.originalFile?.type?.includes("video")
    );
  };

  /**
   * Gets video mime type
   * @param {Object} file - file to get the type
   * @returns {string} - video mime type
   */
  const getVideoType = (file) => {
    return file?.originalFile?.type || file?.type;
  };

  return {
    onRemoveClick,
    onCompleteEdit,
    onPreviousFile,
    onNextFile,
    onThumbnailClick,
    onEditClick,
    isEditing,
    editLoading,
    removeLoading,
    editedName,
    setEditedName,
    fileIndex,
    downloadAttachment,
    isVideoType,
    getVideoType,
  };
};

import { ENVIRONMENTS } from "../constants";

const getEnvironment = () => {
  const { API_HOST } = window.runConfig || {};

  if (API_HOST?.includes(ENVIRONMENTS.feature)) {
    return ENVIRONMENTS.feature;
  }

  if (API_HOST?.includes(ENVIRONMENTS.staging)) {
    return ENVIRONMENTS.staging;
  }

  if (API_HOST?.includes(ENVIRONMENTS.localhost)) {
    return ENVIRONMENTS.localhost;
  }

  return ENVIRONMENTS.production;
};

export default getEnvironment;

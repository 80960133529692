import React from "react";
import PropTypes from "prop-types";
import CrossButton from "../CrossButton/CrossButton";
import Input from "../Input/Input";
import PrimaryButton from "../Buttons/PrimaryButton";

const InputModal = ({
  title,
  onClose,
  inputValue,
  onInputChange,
  onSubmit,
  placeholder,
  buttonTitle,
  handleEnter,
  handleInputClick,
  animationClassName,
  style,
}) => {
  return (
    <div
      className={`absolute p-4 bg-white border-brandGreen border w-full -top-2 rounded-md z-40 ${animationClassName}`}
      role="presentation"
      onClick={handleInputClick}
      onKeyDown={(e) => e.stopPropagation()}
      style={style}
    >
      <div className="flex flex-row justify-between mb-2">
        <h3 className="font-semibold">{title}</h3>
        <CrossButton onClick={onClose} />
      </div>
      <Input
        autoFocus
        isTextarea
        placeholder={placeholder}
        className="mb-4"
        value={inputValue}
        onChange={onInputChange}
        handleEnter={handleEnter}
      />
      <PrimaryButton
        title={buttonTitle}
        className="w-full"
        onClick={onSubmit}
        noMaxWidth
      />
    </div>
  );
};
InputModal.propTypes = {
  /**
   * title of modal box
   */
  title: PropTypes.string,
  /**
   * function for closing the modal
   */
  onClose: PropTypes.func,
  /**
   * value of the textarea input
   */
  inputValue: PropTypes.string,
  /**
   * function called when the input changes
   */
  onInputChange: PropTypes.func,
  /**
   * function called primary button is clicked
   */
  onSubmit: PropTypes.func,
  /**
   * placeholder text for the input textarea
   */
  placeholder: PropTypes.string,
  /**
   * text displayed on the primary button
   */
  buttonTitle: PropTypes.string,
  handleEnter: PropTypes.func,
  handleInputClick: PropTypes.func,
  animationClassName: PropTypes.string,
  style: PropTypes.shape({}),
};

InputModal.defaultProps = {
  title: undefined,
  onClose: undefined,
  inputValue: undefined,
  onInputChange: undefined,
  onSubmit: undefined,
  placeholder: undefined,
  buttonTitle: undefined,
  handleEnter: undefined,
  handleInputClick: () => {},
  animationClassName: "",
  style: null,
};
export default InputModal;

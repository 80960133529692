/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import "./FormHierarchy.css";
import { v4 as uuidv4 } from "uuid";

const lineContainer = (hasLabel) => cntl`
  ${hasLabel && "top-4"}
  pr-6
  pl-4
  relative
`;

const horzLine = (showOnLeft) => cntl`
  hidden
  bg-gray-300
  w-5
  h-px
  ${showOnLeft ? "ml-0" : "ml-1"}
  absolute
  top-6
  ${showOnLeft ? "left-0" : "right-0"}
`;

const extendedLine = (showOnLeft) => cntl`
  hidden
  bg-gray-300
  w-8
  h-px
  ${showOnLeft ? "ml-0" : "ml-1"}
  absolute
  ${showOnLeft ? "left-0" : "right-0"}
  top-6
`;

const badge = (
  hasValue,
  hasLabel,
  showOnLeft,
  isTextArea,
  isDateRangeFilter
) => cntl`
  rounded-full
  h-4
  w-4
  flex
  bg-brandGreen
  absolute
  ${
    hasLabel
      ? isTextArea
        ? cntl`badge-bottom46`
        : cntl`badge-bottom42`
      : isDateRangeFilter
      ? cntl`badge-bottom48`
      : "top-4"
  }
  ${showOnLeft ? "left-1" : "right-1"}
`;

const topVertLine = (hasLabel, showOnLeft) => cntl`
  hidden
  block
  absolute
  bg-gray-300
  w-px
  ${showOnLeft ? "left-0" : "right-0"}
  bottom-1/2
  top-0
`;

const bottomVertLine = (showOnLeft) => cntl`
  hidden
  block
  absolute
  bg-brandGreen
  w-px
  -bottom-2
  top-6
  ${showOnLeft ? "left-0" : "right-0"}
`;

const lastVertLine = (showOnLeft) => cntl`
  hidden
  block
  absolute
  bg-gray-100
  w-px
  ${showOnLeft ? "left-0" : "right-0"}
  -bottom-8
  top-6
`;

const bottomHorzLine = (showOnLeft) => cntl`
  hidden
  bg-gray-100
  w-8
  h-px
  ml-0
  absolute
  ${showOnLeft ? "left-0" : "right-0"}
  -bottom-8
`;

const FormHierarchy = ({ children, showOnLeft, showHangingLine }) => {
  const doesChildHaveLabel = (child) => {
    if (child?.props?.label) {
      return true;
    }

    try {
      return (
        child?.props?.children?.some((grandChild) =>
          doesChildHaveLabel(grandChild)
        ) ?? false
      );
    } catch {
      return false;
    }
  };

  const isChildTextArea = (child) => {
    if (child?.props?.isTextarea) {
      return true;
    }
    try {
      return (
        child?.props?.children?.some((grandChild) =>
          doesChildHaveLabel(grandChild)
        ) ?? false
      );
    } catch {
      return false;
    }
  };

  const isChildDateRangeFilter = (child) => {
    if (child?.props?.currentCol?.filterOptions?.label === "Date Range") {
      return true;
    }
    try {
      return (
        child?.props?.children?.some((grandChild) =>
          doesChildHaveLabel(grandChild)
        ) ?? false
      );
    } catch {
      return false;
    }
  };

  return (
    <div className="flex-1 flex">
      <div className="flex-1 min-w-0">
        {children?.map((child, index) => (
          <div
            className="flex flex-1 mb-2"
            key={`formHierarchy-${child?.props?.label ?? uuidv4()}`}
          >
            {!showOnLeft && <div className="flex-1 min-w-0">{child}</div>}
            <div className={lineContainer(doesChildHaveLabel(child))}>
              {(true && (
                <>
                  {showOnLeft ? (
                    <>
                      <span className={horzLine(showOnLeft)} />
                      <span
                        className={badge(
                          child.props?.hasValue,
                          doesChildHaveLabel(child),
                          showOnLeft,
                          isChildTextArea(child),
                          isChildDateRangeFilter(child)
                        )}
                      />
                    </>
                  ) : (
                    <>
                      <span
                        className={badge(
                          child.props?.hasValue,
                          doesChildHaveLabel(child),
                          showOnLeft,
                          isChildTextArea(child),
                          isChildDateRangeFilter(child)
                        )}
                      />
                      <span className={horzLine(showOnLeft)} />
                    </>
                  )}
                </>
              )) ||
                ((child.props?.hasValue || showHangingLine) && (
                  <span className={extendedLine(showOnLeft)} />
                ))}
              {index > 0 && (
                <span
                  className={topVertLine(doesChildHaveLabel(child), showOnLeft)}
                />
              )}
              {index < children.length - 1 && (
                <span className={bottomVertLine(showOnLeft)} />
              )}
              {showHangingLine && index === children.length - 1 && (
                <>
                  <span className={lastVertLine(showOnLeft)} />
                  <span className={bottomHorzLine(showOnLeft)} />
                  <span className="absolute h-4 w-4 -bottom-10 rounded-full bg-gray-150" />
                </>
              )}
            </div>
            {showOnLeft && <div className="flex-1 min-w-0">{child}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

FormHierarchy.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  showOnLeft: PropTypes.bool,
  showHangingLine: PropTypes.bool,
};

FormHierarchy.defaultProps = {
  showOnLeft: false,
  showHangingLine: false,
};

export default FormHierarchy;

import React, { useEffect, useState } from "react";
import cntl from "cntl";
import Auth from "../../../helpers/Auth";
import { useGetTenants, useUpdateTenantName } from "../../../hooks/useTenants";
import editIcon from "../../assets/images/editpencilgreen.svg";
import checkGreenNoBackground from "../../assets/images/checkGreenNoBackground.svg";
import Input from "../Input/Input";
import useCurrentUser from "../../../hooks/useCurrentUser";

const userAvatarDropdownMenuItemCN = cntl`
  text-gray-450
  text-xs
`;

const Tenants = () => {
  const { data: tenants } = useGetTenants();
  const { data: currentUser } = useCurrentUser();
  const updateTenantName = useUpdateTenantName();
  const currentTenantId = Auth.getTenant();

  const [hover, setHover] = useState();
  const [editingTenantId, setEditingTenantId] = useState();
  const [updatedTenantName, setUpdatedTenantName] = useState();
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  useEffect(() => {
    setIsSaveDisabled(
      !updatedTenantName || updatedTenantName.trim().length < 3
    );
  }, [updatedTenantName]);

  const onStartEditing = (tenant) => {
    setEditingTenantId(tenant.value);
    setUpdatedTenantName(tenant.label);
  };

  const onChangeName = (value) => {
    setUpdatedTenantName(value);
  };

  const onSubmit = async () => {
    await updateTenantName.mutateAsync({
      id: editingTenantId,
      updatedName: updatedTenantName,
    });

    setEditingTenantId(null);
    setUpdatedTenantName(null);
  };

  return (
    <div className="flex flex-col w-64 pl-4 overflow-y-scroll hide-scrollbar max-h-36 mb-1">
      {tenants?.map((tenant) => {
        return (
          <div
            key={tenant?.value}
            className="flex w-full pb-1"
            onMouseOver={() => setHover(tenant.value)}
            onFocus={() => setHover(tenant.value)}
            onMouseOut={() => setHover(null)}
            onBlur={() => setHover(null)}
          >
            {/* show check for currenlty logged in tenant if there are multiple tenants 
            and use is not editing the current tenant */}
            {tenants.length > 1 && editingTenantId !== tenant.value && (
              <div
                className={`flex justify-end w-4 mr-2 mt-1 ${
                  currentTenantId !== tenant.value && "invisible"
                }`}
              >
                <img
                  src={checkGreenNoBackground}
                  className="w-3 h-3"
                  alt="check-icon"
                />
              </div>
            )}
            {/* tenant name display */}
            {editingTenantId !== tenant.value && (
              <div
                className={`${userAvatarDropdownMenuItemCN} pb-1 w-40 truncate`}
                key={tenant.value}
              >
                {tenant.label}
              </div>
            )}
            {/* tenant name editing field */}
            {editingTenantId === tenant.value && (
              <div
                className="w-48 pb-1"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                role="button"
                onKeyDown={() => {}}
                tabIndex="0"
                aria-label="edit input"
              >
                <Input
                  inputClassName="ESInput bg-backgroundGreen p-2 text-sm"
                  inputContainerClassName="h-6"
                  onChange={onChangeName}
                  value={updatedTenantName}
                  disableClear
                  placeholder="Name"
                />
              </div>
            )}
            {/* show edit pencil on hover to admins for only current tenant */}
            {currentUser?.isAdmin &&
              currentTenantId === tenant.value &&
              editingTenantId !== tenant.value &&
              hover === tenant.value && (
                <div className="pl-8 justify-end pt-0.5">
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onStartEditing(tenant);
                    }}
                  >
                    <img src={editIcon} alt="edit" className="w-3" />
                  </button>
                </div>
              )}
            {/* save button for currently edited tenant */}
            {editingTenantId === tenant.value && (
              <button
                type="button"
                className={`font-medium text-xs px-2 ${
                  isSaveDisabled ? "text-gray-200" : "text-darkenedGreen"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onSubmit();
                }}
                disabled={isSaveDisabled}
              >
                Save
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

Tenants.propTypes = {};

export default Tenants;

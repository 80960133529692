import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  useFilters,
  useGroupBy,
  useGlobalFilter,
  useTable,
  useAsyncDebounce,
} from "react-table";
import {
  FILTER_TYPES,
  SET_RFI_MODAL_STATUS,
  TABLE_FILTER,
} from "../../../constants";

import SiteHeader from "../SiteHeader/SiteHeader";
import SiteHeaderSearch from "../SiteHeader/SiteHeaderSearch";
import SiteHeaderFilter from "../SiteHeader/SiteHeaderFilter";
import SiteHeaderSwitchView from "../SiteHeader/SiteHeaderSwitchView";
import {
  BooleanFilter,
  DateFilter,
  IsOrNotFilter,
} from "../Table/tableFilters";
import RfiCard from "../RfiCard/RfiCard";
import PrimaryButton from "../Buttons/PrimaryButton";
import { useAppState } from "../../../state/appState";
import TabbedContainer from "../TabbedContainer/TabbedContainer";

const RfiCardGrid = ({ setIsShowingTable, data }) => {
  const [, dispatch] = useAppState();
  const [columns, setColumns] = useState([]);
  const [activeCardContent, setActiveCardContent] = useState();
  const [resolvedCardContent, setResolvedCardContent] = useState();
  const [draftCardContent, setDraftCardContent] = useState();
  const [tabIndex, setTabIndex] = useState(0);

  const rfiColumns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Association",
        accessor: "association",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
    ],
    []
  );

  useEffect(() => {
    const col = rfiColumns?.map((currentCol) => {
      switch (currentCol.filterOptions?.filterType) {
        case FILTER_TYPES.isOrNot: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.IS_OR_NOT,
            Filter: IsOrNotFilter,
          };
        }
        case FILTER_TYPES.boolean: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.BOOL,
            Filter: BooleanFilter,
          };
        }
        case FILTER_TYPES.date: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.DATE,
            Filter: DateFilter,
          };
        }
        default: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.IS_OR_NOT,
            Filter: IsOrNotFilter,
          };
        }
      }
    });
    setColumns(col);
  }, [rfiColumns]);

  const getTableRow = useCallback((rfi) => {
    return (
      <div key={rfi?.original?.id}>
        <RfiCard className="col-span-2" rfi={rfi?.original} />
      </div>
    );
  }, []);

  const {
    setAllFilters,
    setGlobalFilter,
    rows,
    allColumns,
    state: { filters, globalFilter },
  } = useTable(
    {
      columns,
      data,
      autoResetFilters: false,
      autoResetGroupBy: false,
    },
    useFilters,
    useGlobalFilter,
    useGroupBy
  );

  const [value, setValue] = useState(globalFilter);

  const onSearchChange = useAsyncDebounce((val) => {
    setGlobalFilter(val || undefined);
  }, 200);

  useEffect(() => {
    setActiveCardContent(
      <div className="flex flex-wrap mt-2">
        {rows
          ?.filter((item) => item?.original?.status === "in-progress")
          .map((item) => {
            return getTableRow(item);
          })}
      </div>
    );
    setResolvedCardContent(
      <div className="flex flex-wrap mt-2">
        {rows
          ?.filter(
            (item) =>
              item?.original?.status === "completed-resolved" ||
              item?.original?.status === "completed-open"
          )
          .map((item) => getTableRow(item))}
      </div>
    );
    setDraftCardContent(
      <div className="flex flex-wrap mt-2">
        {rows
          ?.filter((item) => item.original.status === "draft")
          .map((item) => getTableRow(item))}
      </div>
    );
  }, [columns, getTableRow, rows]);

  const cardTabs = React.useMemo(() => {
    return [
      { title: "Active", content: activeCardContent },
      { title: "Completed", content: resolvedCardContent },
      { title: "Drafts", content: draftCardContent },
    ];
  }, [activeCardContent, resolvedCardContent, draftCardContent]);

  return (
    <>
      <SiteHeader
        title="RFI"
        buttons={
          <PrimaryButton
            large
            title="+ Add RFI"
            onClick={() =>
              dispatch({
                type: SET_RFI_MODAL_STATUS,
                open: true,
              })
            }
          />
        }
        search={
          <SiteHeaderSearch
            handleSearch={(val) => {
              onSearchChange(val);
              setValue(val);
            }}
            globalFilter={value}
          />
        }
        filter={
          <SiteHeaderFilter
            allColumns={allColumns}
            tableData={data}
            filters={filters}
            setAllFilters={setAllFilters}
          />
        }
        switchView={
          <SiteHeaderSwitchView setIsShowingTable={setIsShowingTable} />
        }
      />
      <TabbedContainer
        tabs={cardTabs}
        activeIndex={tabIndex}
        onTabClick={setTabIndex}
      />
    </>
  );
};

export default RfiCardGrid;

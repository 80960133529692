import React, { useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import Modal from "../Modal/Modal";
import Input from "../Input/Input";
import IconButton from "../Buttons/IconButton";
import deleteGreenIcon from "../../assets/images/deleteGreenIcon.svg";
import TertiaryButton from "../Buttons/TertiaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";

const BudgetGroupModal = ({
  isOpen,
  handleModalClose,
  group,
  isEditingGroup,
  onDeleteGroup,
  onEditGroup,
  onAddGroup,
  dispatch,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onConfirmDelete = () => {
    onDeleteGroup(group);
    setIsDeleteModalOpen(false);
    handleModalClose();
  };

  const handleChange = (val) => {
    dispatch({
      type: "group",
      value: {
        ...group,
        label: val,
      },
      isEditingGroup,
    });
  };

  const onSave = () => {
    if (isEditingGroup) {
      onEditGroup(group);
    } else {
      onAddGroup(group);
    }
    handleModalClose();
  };

  return (
    <>
      <Modal
        title="Group"
        isOpen={isOpen}
        onRequestModalClose={handleModalClose}
        disabled={false}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <div className="flex flex-col">
          <Input
            className="mt-2"
            mainClassName="w-2/3"
            label="Name"
            placeholder="Group Name"
            value={group?.value !== "create" ? group?.label : null}
            onChange={handleChange}
            validation={yup.string().required()}
            autoFocus
          />
        </div>
        <div className="flex justify-between pt-8 pb-2 mt-auto">
          <div className="flex ">
            {isEditingGroup && (
              <IconButton
                title="Delete"
                icon={deleteGreenIcon}
                onClick={() => {
                  setIsDeleteModalOpen(true);
                }}
                className="mr-2 pt-2 text-brandGreen"
              />
            )}
          </div>
          <div className="flex justify-end">
            <TertiaryButton
              title="Cancel"
              onClick={handleModalClose}
              className="mr-2"
            />
            <PrimaryButton title="Save" onClick={onSave} />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        title="Delete?"
        hideFooter
        onRequestModalClose={() => setIsDeleteModalOpen(false)}
        primaryButtonTitle="Remove"
        primaryButtonOnClick={onConfirmDelete}
        tertiaryButtonTitle="Cancel"
      >
        <div className="flex flex-col">
          <p>Are you sure you want to delete this group?</p>
        </div>
      </Modal>
    </>
  );
};

BudgetGroupModal.propTypes = {
  onAddGroup: PropTypes.func,
  onEditGroup: PropTypes.func,
  onDeleteGroup: PropTypes.func,
  dispatch: PropTypes.func,
  handleModalClose: PropTypes.func,
  isEditingGroup: PropTypes.bool,
  isOpen: PropTypes.bool,
  group: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

BudgetGroupModal.defaultProps = {
  onAddGroup: undefined,
  onEditGroup: undefined,
  onDeleteGroup: undefined,
  dispatch: undefined,
  handleModalClose: undefined,
  isEditingGroup: false,
  isOpen: false,
  group: undefined,
};
export default BudgetGroupModal;

import React from "react";

export default () => {
  return (
    <p>
      Are you sure you want to delete this
      <br /> to-do?
    </p>
  );
};

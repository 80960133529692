import React from "react";
import PropTypes from "prop-types";
import { useAppState } from "../../../../state/appState";
import ImageCell from "../../../../stories/Components/Table/ImageCell";

const DirectMessageHeader = ({ user, expanded }) => {
  const [{ usersStreamDict }] = useAppState();
  return (
    <header
      className="py-3 border-b"
      style={{ borderColor: "#cacaca", minHeight: "64px", maxHeight: "64px" }}
    >
      <div
        className={`grid grid-cols-${
          expanded ? "8" : "6"
        } gap-3 px-4 items-center`}
      >
        <ImageCell
          className="w-10 h-10 rounded-full col-span-1"
          src={usersStreamDict?.[user?.id]?.avatar}
        />
        <span
          className={`font-semibold text-gray-500 text-lg truncate col-span-${
            expanded ? "7" : "5"
          }`}
          title={usersStreamDict?.[user?.id]?.name || user?.name}
        >
          {usersStreamDict?.[user?.id]?.name || user?.name}
          <span className="font-medium text-gray-200">
            {!usersStreamDict?.[user?.id]?.name && " (deleted)"}
          </span>
        </span>
      </div>
    </header>
  );
};

DirectMessageHeader.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  expanded: PropTypes.bool,
};

DirectMessageHeader.defaultProps = {
  user: undefined,
  expanded: false,
};

export default DirectMessageHeader;

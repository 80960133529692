/* Framework Tools */
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { useHistory } from "react-router";
import PureRequestTable from "./PureRequestTable";
import {
  useDeleteSubmittals,
  useSubmittals,
} from "../../../hooks/useSubmittal.new";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import useTemplatesConfiguration from "../../../hooks/useTemplatesConfiguration";
import { useAppState } from "../../../state/appState";
import {
  ADD_OPEN_MODAL,
  SUBMITTAL_CREATE_MODAL,
  SUBMITTAL_FORMATED_STATUS,
} from "../../../constants";
import { formatTableData } from "../../../helpers/RequestHelpers";
import Modal from "../Modal/Modal";
import PopoverComments from "../PopoverComments";
import { useModalState } from "../../../state/modalState";
import { useProjectsOverview } from "../../../hooks/projects";

const RequestTable = ({ associatedResource, setButtonActions, isTabView }) => {
  const history = useHistory();
  const submittalsParams = { project: associatedResource };
  const {
    data: { submittals = [] },
  } = useSubmittals(submittalsParams);

  const { mutate: deleteSubmittals } = useDeleteSubmittals();

  const { data: managementConfiguration } = useManagementConfiguration();
  const [templatesConfiguration, updateTemplate, , deleteTemplate] =
    useTemplatesConfiguration();

  const [{ userDict }] = useAppState();

  const { projectDict } = useProjectsOverview();

  const [templateSettings, setTemplateSettings] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allowDelete, setAllowDelete] = useState(true);
  const [commentsAssociation, setCommentsAssociation] = useState();
  const [, modalDispatch] = useModalState();

  useEffect(() => {
    // optionally stup CTA to Add Submittal
    if (isTabView) {
      setButtonActions((prev) => {
        if (!prev.find((opt) => opt.title === "Add Submittal")) {
          return [
            {
              title: "Add Submittal",
              onClick: () =>
                modalDispatch({
                  type: ADD_OPEN_MODAL,
                  ref: { id: "create-submittal-modal" },
                  modalData: {
                    id: "create-submittal-modal",
                    item: {
                      association: associatedResource,
                      disableAssociation: true,
                    },
                  },
                  modalType: SUBMITTAL_CREATE_MODAL,
                }),
              tabAction: true,
            },
            ...prev,
          ];
        }
        return prev;
      });
    }
  }, [associatedResource, isTabView, modalDispatch, setButtonActions]);

  useEffect(() => {
    const canDelete = selectedRows?.every(
      (item) =>
        item?.status !== SUBMITTAL_FORMATED_STATUS?.COMPLETE_OPEN &&
        item?.status !== SUBMITTAL_FORMATED_STATUS?.COMPLETE_RESOLVED &&
        item?.status !== SUBMITTAL_FORMATED_STATUS?.PENDING_COMPLETION
    );
    setAllowDelete(canDelete);
  }, [selectedRows, selectedRows.length]);

  useEffect(() => {
    if (templatesConfiguration) {
      setTemplateSettings(
        templatesConfiguration?.templates?.submittal?.map((template) => ({
          ...template,
          isAdmin: !template.custom,
        })) || []
      );
    }
  }, [templatesConfiguration]);

  useEffect(() => {
    const isReady =
      !!projectDict &&
      !!Object.keys(userDict)?.length &&
      managementConfiguration?.management &&
      submittals?.length;
    if (!isReady) return;

    const data = formatTableData(
      submittals.filter(
        (item) => item.isDefaultTemplate !== true && !item.isTemplate
      ),
      managementConfiguration,
      userDict,
      projectDict
    );

    setTableData(data);
  }, [managementConfiguration, projectDict, submittals, userDict]);

  const updateUserTemplateSettings = useCallback(
    async (customViews) => {
      updateTemplate({
        key: "submittal",
        updatedTemplates: [
          ...templateSettings.filter((temp) => !temp.custom),
          ...customViews.map((view) => {
            // eslint-disable-next-line no-param-reassign
            view.custom = true;
            return view;
          }),
        ],
      });
    },
    [updateTemplate, templateSettings]
  );

  const deleteUserTemplateSettings = useCallback(
    async (template) => {
      deleteTemplate({
        key: "submittal",
        id: template.id,
      });
    },
    [deleteTemplate]
  );

  const handleRowClick = useCallback(
    (val) => {
      history.push(`/submittals/${val?.id}`);
    },
    [history]
  );

  const handleRowChange = useCallback(
    (val) => {
      const ids = val.map(({ id, status }) => ({ id, status }));
      setSelectedRows(ids);
    },
    [setSelectedRows]
  );

  return (
    <>
      <PureRequestTable
        tableData={tableData}
        selectedRows={selectedRows}
        resourceName="submittals"
        templateSettings={templateSettings}
        updateUserTemplateSettings={updateUserTemplateSettings}
        deleteUserTemplateSettings={deleteUserTemplateSettings}
        setShowDeleteModal={setShowDeleteModal}
        handleRowClick={handleRowClick}
        handleRowChange={handleRowChange}
        onCommentsClick={setCommentsAssociation}
        allowDelete={allowDelete}
      />
      {showDeleteModal && (
        <Modal
          alert
          title="Delete Submittals"
          isOpen={showDeleteModal}
          primaryButtonTitle="Yes, delete"
          primaryButtonOnClick={async () => {
            deleteSubmittals(selectedRows?.map(({ id }) => id));
            setShowDeleteModal(false);
          }}
          tertiaryButtonTitle="Cancel"
          onRequestModalClose={() => setShowDeleteModal(false)}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
          hideFooter
        >
          <>
            <p className="text-base mb-2">
              Are you sure you want to delete these {selectedRows?.length}{" "}
              submittals ? Once deleted, it cannot be recovered.
            </p>
          </>
        </Modal>
      )}
      {!!commentsAssociation && (
        <PopoverComments
          isOpen={!!commentsAssociation?.resourceReference}
          resourceReference={commentsAssociation?.resourceReference}
          association={commentsAssociation?.association}
          onClose={() => setCommentsAssociation(null)}
        />
      )}
    </>
  );
};

RequestTable.propTypes = {
  associatedResource: PropTypes.string,
  setButtonActions: PropTypes.func,
  isTabView: PropTypes.bool,
};

RequestTable.defaultProps = {
  associatedResource: undefined,
  setButtonActions: undefined,
  isTabView: false,
};

export default RequestTable;

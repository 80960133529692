/* Framework Tools */
import React from "react";
import PropTypes from "prop-types";

import PureWorkflowTable from "./PureWorkflowTable";
import Modal from "../Modal/Modal";
import PopoverComments from "../PopoverComments";
import useWorkflowTableData from "./useWorkflowTableData";

const WorkflowTable = ({ associatedResource, setButtonActions, isTabView }) => {
  const {
    handleRowChange,
    handleRowClick,
    deleteUserTemplateSettings,
    updateUserTemplateSettings,
    showDeleteModal,
    setShowDeleteModal,
    tableData,
    selectedRows,
    templateSettings,
    deleteWorkflows,
    allowDelete,
    commentsAssociation,
    setCommentsAssociation,
  } = useWorkflowTableData({ associatedResource, setButtonActions, isTabView });

  return (
    <>
      <PureWorkflowTable
        tableData={tableData}
        selectedRows={selectedRows}
        resourceName="workflows"
        templateSettings={templateSettings}
        updateUserTemplateSettings={updateUserTemplateSettings}
        deleteUserTemplateSettings={deleteUserTemplateSettings}
        setShowDeleteModal={setShowDeleteModal}
        handleRowClick={handleRowClick}
        handleRowChange={handleRowChange}
        onCommentsClick={setCommentsAssociation}
        allowDelete={allowDelete}
      />
      {showDeleteModal && (
        <Modal
          alert
          title="Delete Workflows"
          isOpen={showDeleteModal}
          primaryButtonTitle="Yes, delete"
          primaryButtonOnClick={async () => {
            deleteWorkflows(selectedRows?.map(({ id }) => id));
            setShowDeleteModal(false);
          }}
          tertiaryButtonTitle="Cancel"
          onRequestModalClose={() => setShowDeleteModal(false)}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
          hideFooter
        >
          <>
            <p className="text-base mb-2">
              Are you sure you want to delete these {selectedRows?.length}{" "}
              workflows ? Once deleted, it cannot be recovered.
            </p>
          </>
        </Modal>
      )}
      {!!commentsAssociation && (
        <PopoverComments
          isOpen={!!commentsAssociation.resourceReference}
          resourceReference={commentsAssociation.resourceReference}
          association={commentsAssociation.association}
          onClose={() => setCommentsAssociation(null)}
        />
      )}
    </>
  );
};

WorkflowTable.propTypes = {
  associatedResource: PropTypes.string,
  setButtonActions: PropTypes.func,
  isTabView: PropTypes.bool,
};

WorkflowTable.defaultProps = {
  associatedResource: undefined,
  setButtonActions: undefined,
  isTabView: false,
};

export default WorkflowTable;

import React from "react";
import IconLabel from "../../IconLabel/IconLabel";
import usePastlogData from "./usePastlogData";
import AssociationLink from "../../AssociationLink";

const PastLog = ({ reportId }) => {
  const {
    report,
    canDownload,
    isRunningOrDownloadingReport,
    handleDownloadESFile,
  } = usePastlogData({
    reportId,
  });

  if (!report?.scheduler?.length) {
    return (
      <div className={isRunningOrDownloadingReport ? "loading" : ""}>
        <div className="mt-8 p-32 flex justify-center">
          <span className="text-center">
            When Reports have been successfully generated, they will appear here
            where you can download them.
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-8">
      <span className="text-gray-500 font-semibold text-2xl">
        Past Report Log
      </span>

      <div className="mt-4">
        {report?.scheduler.map((item) => (
          <div key={item.id} id="parent-row" className="w-full">
            <div className="grid grid-cols-6 gap-2">
              <>
                <div className="pr-4 py-4">
                  <p className="font-bold text-gray-450 text-md mb-2">Date</p>
                  <p className="text-sm">{item.date}</p>
                </div>

                <div className="pr-4 py-4">
                  <p className="font-bold text-gray-450 text-md mb-2">
                    Recurrence
                  </p>
                  <p className="text-sm">{report.repeat}</p>
                </div>

                <div className="pr-4 py-4">
                  <p className="font-bold text-gray-450 text-md mb-2">
                    Date Range
                  </p>
                  <p className="text-sm">{report.dateRange}</p>
                </div>

                <div className="pr-4 py-4">
                  <p className="font-bold text-gray-450 text-md mb-2">
                    Association
                  </p>
                  {report.sources.map((source) => (
                    <p key={source} className="text-sm">
                      <AssociationLink
                        associationName={source.title}
                        associationRef={source.reference}
                      />
                    </p>
                  ))}
                </div>

                <div className="pr-4 py-4">
                  <p className="font-bold text-gray-450 text-md mb-2">Type</p>
                  <p className="text-sm">{item.outputType}</p>
                </div>

                <div className="pr-4 py-4">
                  <p className="font-bold text-gray-450 text-md mb-2">
                    Template
                  </p>
                  <p className="text-sm">{report.template}</p>
                </div>
              </>
            </div>

            <div className="mb-3 w-full border-b border-gray-450 border-opacity-50">
              {item.status !== "error" && canDownload ? (
                <div className="my-2 inline-block">
                  <IconLabel
                    className="-ml-3"
                    text="Download Report >"
                    textClassName="text-darkenedGreen"
                    onClick={() => handleDownloadESFile(item.file)}
                  />
                </div>
              ) : (
                <div className="my-2 inline-block p-1 bg-red-100 rounded">
                  <span className="text-xs font-light">{item.notes}</span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PastLog;

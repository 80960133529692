import describeRruleFromString from "../helpers/Calendar/describeRruleFromString";
import { taskRecurrenceOptions } from "../helpers/Time";

const useDescribeRruleFromString = () => {
  /**
   * Transform rrule string into human readable
   * @param {string} rruleString rrule in human readable
   */
  const getRruleInHumanReadable = (rruleString = "") => {
    return rruleString.includes("DTSTART")
      ? describeRruleFromString(rruleString)
      : taskRecurrenceOptions.find(({ value }) => value.includes(rruleString))
          ?.label ?? "Does not repeat";
  };

  return {
    getRruleInHumanReadable,
  };
};

export default useDescribeRruleFromString;

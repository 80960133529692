import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import {
  CSSTransition,
  SwitchTransition,
  Transition,
} from "react-transition-group";

import "./Sidebar.css";

const containerCN = (className, useAutoHeight) => cntl`
    flex
    flex-col
    bg-white
    ${className}
    ${useAutoHeight ? "h-auto max-h-screeen" : "h-full"}
`;

const DIMENSION = {
  width: "width",
  height: "height",
};

const duration = 150;

const Sidebar = ({
  children,
  animationDimension,
  openSize: openSizeProp,
  closedSize,
  width,
  isOpen,
  className,
  header,
  isSwitchHeader,
  isStaticHeader,
  useAutoHeight,
}) => {
  const [showContent, setShowContent] = useState(isOpen);

  const openSize =
    openSizeProp ?? (animationDimension === DIMENSION.width ? "280" : "100vh");

  useEffect(() => {
    // timer to delay showing content until animation is halfway done
    let timer = null;

    if (!isOpen) {
      // menu about to close
      setShowContent(false);
    } else {
      // menu about to open
      timer = setTimeout(() => setShowContent(true), duration / 2);
    }

    return () => {
      // remove timer if component is closed before it opens
      if (timer) clearTimeout(timer);
    };
  }, [isOpen]);

  const defaultStyle = {
    transition: `${animationDimension} ${duration}ms ease-in-out`,
    [animationDimension]: isOpen ? openSize : closedSize,
  };

  // default width
  if (animationDimension === DIMENSION.height) {
    defaultStyle.width = width;
  }

  const transitionStyles = {
    entering: { [animationDimension]: openSize }, // opening
    entered: { [animationDimension]: openSize }, // opened
    exiting: { [animationDimension]: closedSize }, // closing
    exited: { [animationDimension]: closedSize }, // closed
  };

  // ref to store node for header animation
  const nodeRef = useRef(null);

  useEffect(() => {
    if (nodeRef.current) {
      // remove listener on unmount
      nodeRef.current.removeEventListener("transitionend");
    }
  }, []);

  const HeaderAnimation = () => {
    return (
      <>
        {isSwitchHeader ? (
          <SwitchTransition>
            <CSSTransition
              key={isOpen ? "closed" : "open"}
              addEndListener={(node, done) => {
                nodeRef.current = node;
                node.addEventListener("transitionend", done, false);
              }}
              classNames="fade"
            >
              <>{header}</>
            </CSSTransition>
          </SwitchTransition>
        ) : (
          <CSSTransition in={showContent} timeout={duration} classNames="fade">
            <>{header}</>
          </CSSTransition>
        )}
      </>
    );
  };

  return (
    <Transition in={isOpen} timeout={duration} unmountOnExit={closedSize === 0}>
      {(state) => (
        <div
          className={containerCN(className, useAutoHeight)}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {!isStaticHeader ? <HeaderAnimation /> : null}
          <CSSTransition
            in={showContent}
            timeout={duration}
            unmountOnExit
            classNames="fade"
          >
            {children}
          </CSSTransition>
        </div>
      )}
    </Transition>
  );
};

Sidebar.propTypes = {
  /**
   * react component to display as content
   */
  children: PropTypes.element.isRequired,
  /**
   * the size of the sidebar when it is open
   */
  openSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * the size of the sidebar when it is closed
   */
  closedSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * allows component open closed state to be controlled externally
   */
  isOpen: PropTypes.bool,
  /**
   * class names to pass to the container
   */
  className: PropTypes.string,
  /**
   * the header to display on the top of the sidebar
   */
  header: PropTypes.element,
  /**
   * determines if the header should fade out and back in (switch) or remain faded
   * turning this on allows the content to be "switched" out when the animation is active
   */
  isSwitchHeader: PropTypes.bool,
  /**
   * determines what dimension the sidebar will animation
   */
  animationDimension: PropTypes.oneOf(Object.values(DIMENSION)),
  /**
   * width used when the animationDimension is height, ignored otherwise
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * if true, doesn't apply any animation to the header
   */
  isStaticHeader: PropTypes.bool,
  /**
   * if true, does not set the fixed opensize (height) and sets height based on content size
   */
  useAutoHeight: PropTypes.bool,
};

Sidebar.defaultProps = {
  openSize: undefined,
  closedSize: 0,
  isOpen: false,
  className: null,
  header: null,
  isSwitchHeader: false,
  animationDimension: DIMENSION.width,
  width: 280,
  isStaticHeader: false,
  useAutoHeight: false,
};

export default Sidebar;

import React from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";

import Modal from "./Modal";

export default function ModalConfirmAll({
  navigateToPath,
  modalAction,
  showConfirmModal,
  setShowConfirmModal,
  noNavigate,
  closeParentModal,
  primaryButtonTitle,
  customMessage,
}) {
  const history = useHistory();

  const onConfirmCancelClicked = () => {
    setShowConfirmModal(false);
    closeParentModal();
    if (navigateToPath) {
      history.push(navigateToPath);
    } else if (noNavigate) {
      noNavigate();
    } else {
      history.goBack();
    }
  };

  return (
    <Modal
      title={`${modalAction}`}
      isOpen={showConfirmModal}
      primaryButtonTitle={primaryButtonTitle ?? "Yes"}
      primaryButtonOnClick={onConfirmCancelClicked}
      tertiaryButtonTitle="No"
      onRequestModalClose={() => setShowConfirmModal(false)}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      alert
      hideFooter
    >
      <>
        <p className="text-base mb-2">
          {customMessage ||
            ` Are you sure you want to ${modalAction}? If Yes, all data entered will
          be lost`}
        </p>
      </>
    </Modal>
  );
}

ModalConfirmAll.propTypes = {
  navigateToPath: PropTypes.string,
  showConfirmModal: PropTypes.bool,
  modalAction: PropTypes.string,
  setShowConfirmModal: PropTypes.func,
  noNavigate: PropTypes.func,
  closeParentModal: PropTypes.func,
  primaryButtonTitle: PropTypes.string,
  customMessage: PropTypes.string,
};

ModalConfirmAll.defaultProps = {
  navigateToPath: undefined,
  showConfirmModal: false,
  modalAction: "Cancel",
  setShowConfirmModal: () => {},
  noNavigate: undefined,
  closeParentModal: () => {},
  primaryButtonTitle: "Yes",
  customMessage: undefined,
};

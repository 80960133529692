import React, { useState } from "react";
import PropTypes from "prop-types";
import DetailCard from "../DetailCard/DetailCard";
import IconButton from "../Buttons/IconButton";
import trashIcon from "../../assets/images/deleteGreenIcon.svg";
import TertiaryButton from "../Buttons/TertiaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";
import EditSpaceForm from "./EditSpaceForm";
import SpaceView from "./SpaceView";
import BuildingEditDetailsForm from "./BuildingEditDetailsForm";

const BuildingEditForm = ({
  building,
  onFinishEditing,
  setEditBuildingId,
  spaceTypes,
  buildings,
  levels,
  onChange,
  dispatch,
  handleAddBuildingImage,
  handleAddSpaceImage,
  resetPropertyState,
  disableEditing,
}) => {
  const [editSpaceId, setEditSpaceId] = useState(null);
  const onRemoveBuilding = (handleEditClick) => {
    dispatch({
      type: "removeBuilding",
      buildingId: building.id,
    });

    handleEditClick();
    onFinishEditing(
      "buildings",
      buildings?.filter((build) => build.id !== building.id)
    );
  };

  return (
    <DetailCard
      title={building?.name}
      type="LOCATION"
      onChange={() => {}}
      onFinishEditing={onFinishEditing}
      Component={(handleEditClick) => (
        <div className="flex flex-col w-full py-4">
          <div className="flex w-full flex-row">
            <BuildingEditDetailsForm
              building={building}
              handleAddImage={(image) =>
                handleAddBuildingImage(image, building.id)
              }
              onChange={onChange}
              dispatch={dispatch}
              disableEditing={disableEditing}
            />
            <div className="flex flex-row w-1/3 pr-4">
              {editSpaceId && (
                <EditSpaceForm
                  isEdit
                  space={building?.spaces?.find(
                    (space) => space.id === editSpaceId
                  )}
                  building={building}
                  setEditSpaceId={setEditSpaceId}
                  levels={levels}
                  spaceTypes={spaceTypes}
                  onChange={onChange}
                  dispatch={dispatch}
                  handleAddSpaceImage={handleAddSpaceImage}
                />
              )}
              {!editSpaceId && (
                <SpaceView
                  building={building}
                  onFinishEditing={onFinishEditing}
                  setEditSpaceId={setEditSpaceId}
                  onChange={onChange}
                  dispatch={dispatch}
                  levels={levels}
                  spaceTypes={spaceTypes}
                  disableEditing={disableEditing}
                />
              )}
            </div>
          </div>
          {!editSpaceId && (
            <div className="flex justify-between w-full pt-8">
              <IconButton
                icon={trashIcon}
                title="Delete"
                className="text-gray-200 p-4"
                onClick={() => onRemoveBuilding(handleEditClick)}
                disabled={disableEditing}
              />
              <div className="flex flex-row px-4">
                <TertiaryButton
                  title="Cancel"
                  className="mx-2"
                  onClick={() => {
                    handleEditClick();
                    resetPropertyState();
                  }}
                  disabled={disableEditing}
                />
                <PrimaryButton
                  title="Save"
                  onClick={() => {
                    handleEditClick();
                    onFinishEditing();
                  }}
                  disabled={disableEditing || !building?.name}
                />
              </div>
            </div>
          )}
        </div>
      )}
      hoverClassName="border-2 border-brandGreen h-42"
      setEditBuildingId={setEditBuildingId}
      hideEditButton={disableEditing}
    />
  );
};

BuildingEditForm.propTypes = {
  onFinishEditing: PropTypes.func,
  buildings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      spaces: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          level: PropTypes.string,
          type: PropTypes.string,
          name: PropTypes.string,
          images: PropTypes.arrayOf(
            PropTypes.shape({
              thumbnailUrl: PropTypes.string,
              name: PropTypes.string,
            })
          ),
        })
      ),
    })
  ),
  building: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    spaces: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        level: PropTypes.string,
        type: PropTypes.string,
        name: PropTypes.string,
        images: PropTypes.arrayOf(
          PropTypes.shape({
            thumbnailUrl: PropTypes.string,
            name: PropTypes.string,
          })
        ),
      })
    ),
  }),
  setEditBuildingId: PropTypes.func,
  levels: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  spaceTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  dispatch: PropTypes.func,
  onChange: PropTypes.func,
  handleAddBuildingImage: PropTypes.func,
  handleAddSpaceImage: PropTypes.func,
  resetPropertyState: PropTypes.func,
  disableEditing: PropTypes.bool,
};

BuildingEditForm.defaultProps = {
  onFinishEditing: undefined,
  buildings: [],
  building: undefined,
  setEditBuildingId: undefined,
  levels: [],
  spaceTypes: [],
  dispatch: undefined,
  onChange: undefined,
  handleAddBuildingImage: undefined,
  handleAddSpaceImage: undefined,
  resetPropertyState: undefined,
  disableEditing: false,
};

export default BuildingEditForm;

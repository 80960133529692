import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import circleXIcon from "../../assets/images/circleX_Icon.svg";

const errorContainerCN = cntl`
  inline-flex
  items-center
  bg-white
  p-4
  rounded
  border
  border-brandRed
  border-l-4
  whitespace-pre-wrap
`;

const ErrorBanner = ({ text }) => {
  return (
    <div className={errorContainerCN}>
      <img className="mr-2.5" src={circleXIcon} alt="Dismiss this error" />
      <p>{text}</p>
    </div>
  );
};

ErrorBanner.propTypes = {
  /**
   * the text to display in the banner
   */
  text: PropTypes.string.isRequired,
};

ErrorBanner.defaultProps = {};

export default ErrorBanner;

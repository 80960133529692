import { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { PropertyAPI } from "@griffingroupglobal/eslib-api";
import { getFullName } from "../helpers/Formatters";
import { useAppState } from "../state/appState";
import {
  SET_PROPERTIES,
  SET_PROPERTIES_DD,
  SET_PROPERTIES_DICT,
} from "../constants";
import { tagKeys } from "../config/reactQuery/queryKeyFactory";

const defaultState = {
  address: [
    {
      use: "Physical",
      street: "",
      street2: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    },
    {
      use: "Mailing",
      street: "",
      street2: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    },
  ],
  mailingSameAsPhysical: true,
  hoursOfOperation: undefined,
  additionalInfo: [],
  insurance: [],
  files: [],
  members: [],
  vendors: [],
  documents: [],
  originalResource: {},
};

/**
 * TODO (RQ-Perf)
 * @deprecated - Instead use `usePropertyById` from `/hooks/properties`
 */
export default function usePropertyById(propertyId, leaveChildren = false) {
  const [{ userDict, properties }, dispatch] = useAppState();
  const [property, setProperty] = useState(defaultState);
  const [propertyMembers, setPropertyMembers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const reload = useCallback(
    async (leave) => {
      if (propertyId) {
        setLoading(true);

        try {
          const { data } = await PropertyAPI.getById(propertyId);
          let formatedProperty;
          const membersRefs = data?.members?.map(({ user }) => user) || [];

          setPropertyMembers(
            membersRefs?.map((ref) => {
              const user = userDict[ref];
              return {
                label: getFullName(user?.name),
                value: user?.reference,
              };
            })
          );

          if (!leave) {
            formatedProperty = await Promise.resolve({
              ...data,
              spaces: data?.spaces,
            });
            setProperty(formatedProperty);
          } else {
            setProperty(data);
          }
          setLoading(false);
        } catch (err) {
          console.error(err);
          setLoading(false);
        }
      }
    },
    [propertyId, userDict]
  );

  const patchProperty = async (finishedProperty, throwError) => {
    let rv;
    try {
      const { data } = await PropertyAPI.patch(
        property.id,
        finishedProperty,
        property
      );
      setProperty(data);
      rv = data;

      // update tags in s&c
      queryClient.invalidateQueries(tagKeys.tags);
    } catch (err) {
      console.error(err);
      if (throwError) {
        throw err;
      }
    }
    return rv;
  };

  const removeProperty = (deletedPropertyId) => {
    PropertyAPI.delete(deletedPropertyId)
      .then(() => {
        const propertiesDD = [];
        const newProperties = properties
          ?.filter(({ id }) => deletedPropertyId !== id)
          .map((e) => {
            propertiesDD.push({
              label: e?.name,
              value: e?.reference,
              id: e?.id,
            });
            return e;
          });

        dispatch({
          type: SET_PROPERTIES,
          properties: newProperties,
        });
        dispatch({
          type: SET_PROPERTIES_DD,
          propertiesDD,
        });
        dispatch({
          type: SET_PROPERTIES_DICT,
          propertiesDict: newProperties.reduce(
            (acc, v) => ({ ...acc, [v.reference]: v }),
            {}
          ),
        });
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    reload(leaveChildren);
  }, [reload, leaveChildren]);

  return {
    currentProperty: Object.keys(property).length ? property : undefined,
    property,
    propertyMembers,
    isLoading,
    reload,
    setProperty,
    patchProperty,
    removeProperty,
  };
}

import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import { blockLevelAddress, formatAddress } from "../../../helpers/Formatters";

const valueCN = cntl`
  text-gray-450 
  text-sm
  font-normal
  not-italic
  hover:underline
  cursor-pointer
`;

/**
 * @param {Object} Address
 * @returns Address Html Element with onClick function that opens address in google maps
 * @summary - Expects Object From Dispatcher Schema
 * For Displaying Interactive Address Values
 */

const Address = ({ address }) => {
  const openMap = () => {
    window.open(`http://maps.google.com/?q=${formatAddress(address)}`);
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13) {
      openMap(address);
    }
  };
  return (
    <address
      className={valueCN}
      onClick={() => openMap(address)}
      role="presentation"
      onKeyDown={(e) => onEnterPress(e)}
    >
      {blockLevelAddress(address)?.map((item) => (
        <React.Fragment key={item}>
          {item}
          <br />
        </React.Fragment>
      ))}
    </address>
  );
};

Address.propTypes = {
  address: PropTypes.shape({
    street: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
    country: PropTypes.string,
  }),
};

Address.defaultProps = {
  address: undefined,
};

export default Address;

const removeDuplicateRef = (timesheets) => {
  const duplicateCheckSet = new Set();

  return timesheets?.filter((item) => {
    const duplicate = duplicateCheckSet.has(item.reference);
    duplicateCheckSet.add(item.reference);
    return !duplicate;
  });
};

export default removeDuplicateRef;

/**
 * Calculate if current user has permissions to download the report file
 * @param {Object} currentUser Current user authenticated
 * @param {Object} report current report
 * @returns {boolean}
 */
const getDownloadReportPermissions = (currentUser, report) => {
  const { metadata, distribution = [] } = report ?? {};

  const isReportOwner = currentUser?.reference === metadata?.createdBy;
  const isCurrentUserInDistroList = distribution.includes(
    currentUser?.reference
  );

  return currentUser?.isAdmin || isReportOwner || isCurrentUserInDistroList;
};

export default getDownloadReportPermissions;

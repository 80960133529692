import React, { useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import "../ModalStyles.css";

const ButtonCN = (animated, className) => cntl`
${animated && "animated-button"}
${className ? `${className}` : ""}
`;

const BaseActionButton = ({
  className,
  onClick,
  onKeyUp,
  toggle,
  animated,
  color,
  Icon,
}) => {
  const [hover = false, setHover] = useState();
  const handleHover = (action) => {
    switch (action) {
      case "enter":
        setHover(true);
        break;
      case "leave":
        setHover(false);
        break;
      default:
        setHover(false);
    }
  };
  return (
    <div
      className={ButtonCN(animated, className)}
      role="button"
      onMouseEnter={() => handleHover("enter")}
      onMouseLeave={() => handleHover("leaver")}
      tabIndex={0}
      onClick={() => onClick()}
      onKeyUp={() => onKeyUp()}
      aria-label="button"
    >
      <Icon hover={hover} color={color} toggle={toggle} />
    </div>
  );
};

BaseActionButton.propTypes = {
  /**
   * classes to apply to the button
   */
  className: PropTypes.string,
  /**
   * function called when the button is clicked
   */
  onClick: PropTypes.func,
  /**
   * Takes React Elements
   */
  Icon: PropTypes.element,
  onKeyUp: PropTypes.func,
  toggle: PropTypes.bool,
  /**
   * Adds Animated Class (see animated-button ./ModalStyles.css)
   */
  animated: PropTypes.bool,
  /**
   * Color as string used for svg fill
   */
  color: PropTypes.string,
};

BaseActionButton.defaultProps = {
  className: undefined,
  onClick: () => {},
  onKeyUp: () => {},
  Icon: undefined,
  toggle: false,
  animated: false,
  color: undefined,
};

export default BaseActionButton;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPropertyLocationOptions } from "../../../helpers/SiteHeaderOptions";
import useCurrentUser from "../../../hooks/useCurrentUser";
import usePropertyById from "../../../hooks/usePropertyById";
import LocationAssetList from "../Asset/LocationAssetList";
import DetailViewBanner from "../../../stories/Components/DetailViewBanner/DetailViewBanner";
import { resolvePrimaryImage } from "../../../helpers/File";
import { hasWritePermission } from "../../../helpers/Permissions";
import { ASSET } from "../../../constants";

const PropertyLocationAssets = () => {
  const { propertyId, locationId } = useParams();
  const { property } = usePropertyById(propertyId);
  const { data: currentUser } = useCurrentUser();

  const [locationImage, setLocationImage] = useState();
  const [location, setLocation] = useState();

  useEffect(() => {
    const loationImages = property?.buildings?.filter((loc) => {
      return loc.id === locationId;
    })[0];
    setLocation(loationImages);
    if (loationImages?.images?.length) {
      resolvePrimaryImage(loationImages.images)
        .then((imageFile) => setLocationImage(imageFile?.contentsUrl))
        .catch((err) =>
          console.error(`Unable to resolve property primary image:${err}`)
        );
    }
  }, [locationId, property]);

  return (
    <LocationAssetList
      associatedResource={`Property/${propertyId}`}
      locationId={locationId}
      siteHeaderProps={{
        title: location?.name || "Loading",
        dropdownRoutes: getPropertyLocationOptions(
          propertyId,
          currentUser,
          locationId
        ),
      }}
      bannerComponent={
        <DetailViewBanner
          resource={property}
          resourceImage={locationImage}
          disableUpload
        />
      }
      disableCreate={!hasWritePermission(ASSET, currentUser)}
    />
  );
};

export default PropertyLocationAssets;

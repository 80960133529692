import React from "react";
import HoursOfOperation from "../../../stories/Components/HoursOfOperation/HoursOfOperation";
import FinancesInformation from "../../../stories/Components/Finances/FinancesInformation";

const PropertyFinances = ({
  editedProperty,
  editing,
  dispatch,
  onFinishEditing,
}) => {
  return (
    <>
      <FinancesInformation
        editedObject={editedProperty}
        editing={editing}
        dispatch={dispatch}
        resourceName="Property"
      />

      <div className="flex flex-col">
        <div className="relative flex gap-4 w-full">
          <div className="w-1/2 pr-2">
            <HoursOfOperation
              onFinishEditing={onFinishEditing}
              dispatch={dispatch}
              resource={editedProperty}
              isEditing={editing}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyFinances;

import moment from "moment-timezone";
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { hours } from "../../../../constants";
import {
  buildTree,
  checkRoot,
  getPreviousNodePath,
  getRootDepth,
  percentageOfDayElapsed,
} from "../../../../helpers/Calendar";
import WeekEvent from "../WeekEvent";
import useRelativeAssociations from "../../../../hooks/useRelativeAssociations";
import { useAppState } from "../../../../state/appState";

const WeekEventMap = ({ week, events, handleEventClick, currentWeek }) => {
  const { eventFilter: filter } = useRelativeAssociations();
  const [{ calendarTimezone }] = useAppState();
  const today = parseInt(
    moment.tz(new Date(), calendarTimezone).format("d"),
    10
  );
  /**
   * Builds linked list
   * event ->[...(events starting during event -> [...(and so forth)])]
   */
  const handleEventHeirarchy = useCallback((eventsOnDay) => {
    const day = eventsOnDay;

    const tree = [];

    day.forEach((event) => {
      if (!checkRoot(tree, event.id)) {
        tree.push(buildTree(event, day));
      }
    });

    return tree;
  }, []);

  /**
   * Maps multi-level tree of events
   * viewBox Values: (-strokeWidth * 2) (hides top stroke) (width) (height = # hours in a day * cell height)
   */
  return (
    <svg
      viewBox={`-0.5 -0.125 296 ${(hours.length - 1) * 15}`}
      width="100%"
      style={{ position: "absolute", top: 0 }}
    >
      {week.map((day, index) => {
        const key = moment.tz(day.iso, calendarTimezone).format();
        const mappedEvents = [];

        const orderedEvents = handleEventHeirarchy(
          filter(events?.[key])?.brief
        );

        orderedEvents?.forEach((event) => {
          const grouped = event.getGroup();
          //  Find Root Node Deepest Child
          mappedEvents.push(
            <WeekEvent
              event={event}
              index={index}
              subIndex={event?.level}
              group={getRootDepth(grouped)}
              onClick={handleEventClick}
              h={(hours.length - 1) * 15}
              calendarTimezone={calendarTimezone}
            />
          );
          if (event.group.length > 0) {
            grouped.forEach((subEvent) => {
              mappedEvents.push(
                <WeekEvent
                  event={subEvent}
                  index={index}
                  subIndex={subEvent?.level}
                  //  Find Deepest Child (Top<-(Node)->Bottom)
                  group={getRootDepth([
                    ...getPreviousNodePath(subEvent),
                    ...subEvent.getGroup(),
                  ])}
                  onClick={handleEventClick}
                  h={(hours.length - 1) * 15}
                  calendarTimezone={calendarTimezone}
                />
              );
            });
          }
        });

        return mappedEvents;
      })}
      <g id="now-indicator">
        <title>{moment.tz(new Date(), calendarTimezone).format("h:mma")}</title>
        <circle
          fill={currentWeek ? "red" : "rgba(0,0,0,0)"}
          cx={moment.tz(new Date(), calendarTimezone).format("d") * 40 + 15}
          cy={percentageOfDayElapsed() * 360}
          r={1}
          opacity={0.8}
        />
        <line
          fill={currentWeek ? "red" : "rgba(0,0,0,0)"}
          x1={today * 40 + 15}
          x2={(today + 1) * 40 + 15}
          y1={percentageOfDayElapsed() * 360}
          y2={percentageOfDayElapsed() * 360}
          stroke={currentWeek ? "red" : "rgba(0,0,0,0)"}
          strokeWidth="0.5"
          opacity={0.5}
        />
      </g>
    </svg>
  );
};

WeekEventMap.propTypes = {
  week: PropTypes.arrayOf(PropTypes.shape({})),
  events: PropTypes.shape({}),
  handleEventClick: PropTypes.func,
  currentWeek: PropTypes.bool,
};

WeekEventMap.defaultProps = {
  week: undefined,
  events: undefined,
  handleEventClick: undefined,
  currentWeek: false,
};

export default WeekEventMap;

import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";

const containerCN = cntl`
  flex
  flex-col
  m-auto
`;

const innerCN = (className) => cntl`
  flex
  ${className}
`;

const { STRIPE_TRIAL_PERIOD_DAYS } = window.runConfig;

const SignupComplete = ({ orderDetails }) => {
  return (
    <div className={containerCN} style={{ width: "1140px", marginTop: "80px" }}>
      <div className={innerCN("mb-6")}>
        <p
          className="text-es-dark-grey font-es-semibold"
          style={{
            fontSize: "38px",
            letterSpacing: "1.88px",
            lineHeight: "46.32px",
          }}
        >
          Thank You
        </p>
      </div>
      <div className={innerCN()}>
        <p className="text-es-normal text-es-dark-grey font-es-normal w-full">
          Your order has been received and we’re working on setting up your
          EstateSpace. Once your account is ready, we’ll send you an email with
          details on how to log in. This can take up to 1 business day.
        </p>
      </div>
      <div className={innerCN("my-6")}>
        <p className="text-es-normal text-es-dark-grey font-es-normal">
          Your credit card will not be charged for {STRIPE_TRIAL_PERIOD_DAYS}{" "}
          days.
        </p>
      </div>
      <div className="border p-8 rounded-lg">
        <div className={innerCN()}>
          <p
            className="text-es-lg text-es-dark-grey font-es-semibold"
            style={{ letterSpacing: "1.13px" }}
          >
            Order Confirmation
          </p>
        </div>
        <div className={innerCN("mb-8")}>
          <p className="text-es-dark-grey text-es-normal font-es-normal">
            Order Date: {moment().format("MMMM Do, YYYY")}
          </p>
        </div>
        <div
          className="flex flex-row flex-wrap gap-4"
          style={{ height: "296px", width: "1062px", marginTop: "20px" }}
        >
          {/* col 1 */}
          <div className="flex flex-col gap-3" style={{ width: "358px" }}>
            <p
              className="text-es-dark-grey font-es-semibold"
              style={{ fontSize: "20px" }}
            >
              Plan Selection
            </p>
            <p className="text-es-normal font-es-bold">
              {orderDetails.selectedPlan.planName.toUpperCase()}
            </p>
            <div>
              <p className="text-gray-400 text-base inline-block">
                {orderDetails.userCount} users |
              </p>
              <p className="text-gray-400 text-base capitalize inline-block px-1">
                {orderDetails.frequency}
              </p>
              <p className="text-gray-400 text-base inline-block">
                ($
                {orderDetails.frequency === "monthly"
                  ? orderDetails.selectedPlan.monthlyCost
                  : orderDetails.selectedPlan.annualCost}{" "}
                per user/month)
              </p>
            </div>
            <p className="text-es-normal text-es-dark-grey font-es-semibold tracking-es-wide">
              Total Due: ${orderDetails.total}
            </p>
          </div>

          {/* col 2 */}
          <div className="flex flex-col gap-3" style={{ width: "283px" }}>
            <p
              className="text-es-dark-grey font-es-semibold"
              style={{ fontSize: "20px" }}
            >
              Contact Information
            </p>
            <p className="text-es-dark-grey text-es-normal">
              {orderDetails.contactInfo.firstName}{" "}
              {orderDetails.contactInfo.lastName}
            </p>
            <p className="text-es-dark-grey text-es-normal">
              {orderDetails.contactInfo.company}
            </p>
            <p className="text-es-dark-grey text-es-normal">
              {orderDetails.contactInfo.email}
            </p>
            <p className="text-es-dark-grey text-es-normal">
              {orderDetails.contactInfo.phone}
            </p>
          </div>

          {/* col 3 */}
          <div className="flex flex-col gap-3" style={{ width: "283px" }}>
            <p
              className="text-es-dark-grey font-es-semibold"
              style={{ fontSize: "20px" }}
            >
              Billing Address
            </p>
            <p className="text-es-dark-grey text-es-normal">
              {orderDetails.billingInfo.street}{" "}
              {orderDetails.billingInfo.street2 &&
                `,  ${orderDetails.billingInfo.street2}`}
            </p>
            <p className="text-es-dark-grey text-es-normal">
              {orderDetails.billingInfo.city}{" "}
              {orderDetails.billingInfo.city && ", "}
              {orderDetails.billingInfo.state} {orderDetails.billingInfo.zip}
            </p>
            <p className="text-es-dark-grey text-es-normal">
              {orderDetails.billingInfo.country?.value}
            </p>
          </div>
          {/* col 4 */}
          <div className="flex flex-col gap-3" style={{ width: "431px" }}>
            <p
              className="text-es-dark-grey font-es-semibold"
              style={{ fontSize: "20px" }}
            >
              Credit Card Information
            </p>
            <p className="text-es-dark-grey text-es-normal">
              {orderDetails.billingInfo.cardType?.toUpperCase()}
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center justify-end w-full">
          <Link
            to="/login"
            className="p-2 rounded-lg bg-es-green text-es-white text-es-normal font-es-semibold text-center"
            style={{ height: "44px", width: "122px" }}
            title="Login"
            color="white"
          >
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

SignupComplete.propTypes = {
  orderDetails: PropTypes.shape({
    selectedPlan: PropTypes.shape({
      planName: PropTypes.string,
      userText: PropTypes.string,
      minUsers: PropTypes.number,
      maxUsers: PropTypes.number,
      monthlyCost: PropTypes.string,
      annualCost: PropTypes.string,
      discount: PropTypes.string,
      features: PropTypes.arrayOf(PropTypes.string),
      priceIdMonthly: PropTypes.string,
      priceIdAnnual: PropTypes.string,
    }),
    userCount: PropTypes.number,
    frequency: PropTypes.string,
    total: PropTypes.number,
    billingInfo: PropTypes.shape({
      name: PropTypes.string,
      city: PropTypes.string,
      state: {
        label: PropTypes.string,
        value: PropTypes.string,
      },
      cardType: PropTypes.string,
      street: PropTypes.string,
      street2: PropTypes.string,
      country: {
        label: PropTypes.string,
        value: PropTypes.string,
        countryCode: PropTypes.string,
      },
      zip: PropTypes.string,
    }),
    contactInfo: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      title: PropTypes.string,
      email: PropTypes.string,
      company: PropTypes.string,
      phone: PropTypes.string,
    }),
  }),
};

SignupComplete.defaultProps = {
  orderDetails: undefined,
};

export default SignupComplete;

import React, { useCallback, useState } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import BaseButton from "../Buttons/BaseButton";
import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";
import EditRemoveActions from "../EditRemoveActions/EditRemoveActions";
import PlusCirlceIconDarkGreen from "../../assets/images/plusCircleIconDarkGreen.svg";

const tertiaryButtonCN = cntl`
text-brandDarkGreen
py-4
text-base
`;

const textCN = (selected) => cntl`
pl-2
font-medium
truncate
w-1/3
${selected ? "" : "text-gray-200"}
`;

const ManagementContactDisciplines = ({
  managementSettings,
  dispatchManagementSettings,
}) => {
  const [addMode, setAddMode] = useState(false);
  const onAddDiscipline = useCallback(() => {
    dispatchManagementSettings({
      type: "addContactDiscipline",
    });
  }, [dispatchManagementSettings]);

  const onEditDiscipline = useCallback(
    (index, newValue) => {
      dispatchManagementSettings({
        type: "changeContactDiscipline",
        index,
        value: newValue,
      });
    },
    [dispatchManagementSettings]
  );

  const onRemoveDiscipline = useCallback(
    (index) => {
      dispatchManagementSettings({
        type: "removeContactDiscipline",
        index,
      });
    },
    [dispatchManagementSettings]
  );

  const toggleDisciplineEditing = useCallback(
    (index) => {
      dispatchManagementSettings({
        type: "changeContactDisciplineEditing",
        index,
      });
    },
    [dispatchManagementSettings]
  );

  const selectDiscipline = useCallback(
    (index) => {
      dispatchManagementSettings({
        type: "selectContactDiscipline",
        index,
      });
    },
    [dispatchManagementSettings]
  );

  const checkForEmptyValue = useCallback(
    (value, index) => {
      if (value === "") onRemoveDiscipline(index);
    },
    [onRemoveDiscipline]
  );

  const hasDuplicate = useCallback(
    (value, idx) => {
      return managementSettings.contactDisciplines.some((x, index) => {
        return x.display.toLowerCase() === value.toLowerCase() && index !== idx;
      });
    },
    [managementSettings.contactDisciplines]
  );

  const handleEnter = useCallback(
    (event, value, index) => {
      // checks if the Enter key was pressed
      if (event.keyCode === 13) {
        if (hasDuplicate(value, index)) {
          return false;
        }
        if (value === "") {
          checkForEmptyValue(value, index);
          return undefined;
        }
        if (addMode) onAddDiscipline();
        toggleDisciplineEditing(index);
        onEditDiscipline(index, value);
      }
      return undefined;
    },
    [
      hasDuplicate,
      addMode,
      onAddDiscipline,
      toggleDisciplineEditing,
      onEditDiscipline,
      checkForEmptyValue,
    ]
  );

  const handleBlur = (value, index) => {
    if (window.event.keyCode === 13) return;

    toggleDisciplineEditing(index);
    if (value === "" || hasDuplicate(value, index)) {
      onRemoveDiscipline(index);
    } else {
      onEditDiscipline(index, value);
    }

    // prevents new empty input field from being added inside handleEnter
    setAddMode(false);
  };

  return (
    <div>
      <div className="flex flex-col">
        <div className="flex flex-row border-b border-gray-150 py-2 justify-between mr-6">
          <h2 className="font-semibold py-4 uppercase">disciplines</h2>
        </div>
        <div className="flex flex-col">
          {managementSettings?.contactDisciplines?.map((discipline, index) => {
            return (
              <EditRemoveActions
                key={discipline?.id}
                index={index}
                isEditing={discipline?.isEditing}
                toggleEditing={toggleDisciplineEditing}
                handleRemove={onRemoveDiscipline}
                className="pt-2"
                setAddMode={setAddMode}
                hideRemove={discipline?.count}
              >
                <Checkbox
                  checked={discipline?.selected}
                  onChange={() => selectDiscipline(index)}
                  disabled={discipline?.count}
                />
                {(discipline?.isEditing && (
                  <div className="w-1/4 pl-2">
                    <Input
                      value={discipline.display}
                      placeholder="Discipline"
                      onChange={(val) => onEditDiscipline(index, val)}
                      onBlur={() => handleBlur(discipline.display, index)}
                      handleEnter={(event) =>
                        handleEnter(event, discipline.display, index)
                      }
                      autoFocus
                    />
                  </div>
                )) || (
                  <p className={textCN(discipline?.selected)}>
                    {discipline?.display}
                  </p>
                )}
              </EditRemoveActions>
            );
          })}
          <div className="py-4 flex flex-row items-center">
            <img
              className="h-4 w-4"
              src={PlusCirlceIconDarkGreen}
              alt="plus circle icon green"
            />
            <BaseButton
              title="Add Discipline"
              onClick={() => {
                setAddMode(true);
                onAddDiscipline();
              }}
              className={tertiaryButtonCN}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ManagementContactDisciplines.propTypes = {
  managementSettings: PropTypes.shape({
    contactDisciplines: PropTypes.arrayOf(
      PropTypes.shape({
        display: PropTypes.string,
        id: PropTypes.string,
        isOpen: PropTypes.bool,
        isEditing: PropTypes.bool,
      })
    ),
  }),
  dispatchManagementSettings: PropTypes.func,
};

ManagementContactDisciplines.defaultProps = {
  managementSettings: undefined,
  dispatchManagementSettings: undefined,
};

export default ManagementContactDisciplines;

import { compare as fjpCompare } from "fast-json-patch";
import { AttributeAPI } from "@griffingroupglobal/eslib-api";

const updateAttributes = async (current, existingAttributes) => {
  const errors = [];
  const currentAttributes = current?.map((item) => {
    if (item.fieldType === "number-entry-with-unit") {
      // eslint-disable-next-line no-param-reassign
      item.fieldType = "number-entry";
    }
    if (item?.fieldValues?.length) {
      return {
        ...item,
        fieldValues: item.fieldValues.map((value) => value.label),
      };
    }
    if (item?.unit?.fieldValues?.length) {
      return {
        ...item,
        unit: {
          ...item.unit,
          fieldValues: item.unit.fieldValues.map((value) => value.label),
        },
      };
    }
    return item;
  });

  const existingAttributesMap = existingAttributes.reduce(
    (a, v) => ({ ...a, [`${v.id}`]: v }),
    {}
  );

  const currentAttributesMap = currentAttributes.reduce(
    (a, v) => ({ ...a, [`${v.id}`]: v }),
    {}
  );

  // POST
  const postResources = currentAttributes?.filter(
    (attribute) => attribute.wasAdded
  );

  // PATCH
  const patchResourcesWithPayload = currentAttributes
    ?.filter((attribute) => attribute.wasUpdated && !attribute.wasAdded)
    ?.map((attribute) => {
      return {
        id: attribute.id,
        metadata: {
          lastUpdated: attribute.metadata.lastUpdated,
        },
        patch: fjpCompare(existingAttributesMap[attribute.id], attribute),
      };
    });

  // DELETE
  const deleteResourceIds = [];
  existingAttributes?.forEach((old) => {
    if (!currentAttributesMap[old.id]) {
      deleteResourceIds.push(old.id);
    }
  });

  if (postResources?.length) {
    const { data } = await AttributeAPI.postWOP("$bulkcreate", {
      attributes: postResources,
    });
    data.forEach((res) => {
      if (res?.issue?.detailRepl?.length) {
        errors.push(`Error creating attribute: ${res?.issue?.detailRepl[0]}`);
      }
    });
  }

  if (patchResourcesWithPayload?.length) {
    const { data } = await AttributeAPI.postWOP("$bulkupdate", {
      attributes: patchResourcesWithPayload,
    });
    data.forEach((res) => {
      if (res?.issue?.detailRepl?.length) {
        errors.push(`Error updating attribute: ${res?.issue?.detailRepl[0]}`);
      }
    });
  }

  if (deleteResourceIds?.length) {
    const { data } = await AttributeAPI.postWOP("$bulkdelete", {
      attributes: deleteResourceIds,
    });
    data.forEach((res) => {
      if (res?.issue?.detailRepl?.length) {
        errors.push(`Error deleting attribute: ${res?.issue?.detailRepl[0]}`);
      }
    });
  }

  return { errors };
};

export default updateAttributes;

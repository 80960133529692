/**
 * Return the type of association.
 * @param {string | string[]} association single association reference or array of association references
 * @returns (property | project | asset)
 */
const getAssociationType = (association) => {
  const associationType = Array.isArray(association)
    ? association[0]?.split("/")[0]
    : association?.split("/")[0];

  return associationType?.toLocaleLowerCase();
};

export default getAssociationType;

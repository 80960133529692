import { ReportAPI } from "@griffingroupglobal/eslib-api";
import { reportsKeys } from "../config/reactQuery/queryKeyFactory";
import { toastError } from "../stories/Components/Toast/Toast";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import useCurrentUser from "./useCurrentUser";

/**
 * Fecth all reports templates
 * @returns array of reports templates
 */
const getReportsTemplate = async () => {
  const { data } = await ReportAPI.getWOP("$templates");

  if (data?.entries) {
    return data.entries.map(({ resource }) => resource);
  }

  return [];
};

/**
 * Query hook to fetch all reports templates
 * @param {Function} select (?) custom function to transform data
 * @returns reports template in backend format
 */
const useReportsTemplates = (select) => {
  const { data: currentUser } = useCurrentUser();

  const canReadTemplateReports =
    currentUser?.isAdmin || currentUser?.permissions?.report?.can_read;

  const queryKey = reportsKeys.templates();

  return useAuthenticatedQuery({
    queryKey,
    queryFn: () => getReportsTemplate(),
    enabled: !!canReadTemplateReports,
    select,
    onError: (error) => {
      toastError(
        "There was an issue getting reports templates. Please try again"
      );
      console.error("useReportsTemplates", error);
    },
  });
};

export default useReportsTemplates;

import moment from "moment";

export const STATUS = {
  incomplete: "Incomplete",
  done: "Completed",
  scheduled: "Scheduled",
  "in-progress": "In Progress",
  overdue: "Overdue",
  deleted: "Deleted",
  current: "Current Task",
};

const PILL_CN = {
  done: "border-pillGray border text-white bg-pillGray",
  Completed: "border-pillGray border text-white bg-pillGray",

  scheduled: "border-navyBlue border text-white bg-navyBlue",
  Scheduled: "border-navyBlue border text-white bg-navyBlue",

  Upcoming: "border-navyBlue border text-navyBlue bg-white",
  waiting: "border-navyBlue border text-white bg-navyBlue",

  "in-progress": "border-primaryGreen border text-primaryGreen bg-white",
  "In Progress": "border-primaryGreen border text-primaryGreen bg-white",
  "Action Pending": "border-primaryGreen border text-primaryGreen bg-white",

  incomplete: "border-pillGray border text-pillGray bg-white",
  Incomplete: "border-pillGray border text-pillGray bg-white",

  void: "border-pillGray border text-pillGray bg-white",
  Void: "border-pillGray border text-pillGray bg-white",

  overdue: "border-red-500 border text-red-500 bg-transparent",
  Overdue: "border-red-500 border text-red-500 bg-transparent",

  deleted: "border-pillGray border text-pillGray bg-white",
  Deleted: "border-pillGray border text-pillGray bg-white",

  current: "border-pillGray border text-pillGray bg-white",
  "Current Task": "border-pillGray border text-pillGray bg-white",
};

/**
 * Format tasks to be rendered on table
 */
const formatTasks = (task) => {
  let formattedStatus = STATUS[task?.status] || "";
  /**
   * if scheduled, check if current or overdue
   * if not scheuled, show formatted status
   */

  if (task?.status === "scheduled") {
    const isCurrentTask = moment().isBetween(
      moment(task?.startDate).clone().startOf("day"),
      moment(task?.endDate).clone().endOf("day")
    );
    const isOverdue = moment(task?.endDate)
      .clone()
      .endOf("day")
      .isBefore(moment());
    if (isCurrentTask) {
      formattedStatus = STATUS.current;
    } else if (isOverdue) {
      formattedStatus = STATUS.overdue;
    }
  }

  return {
    ...task,
    name: task?.name,
    date: moment(task?.startDate).format("MMM DD, YYYY"),
    time: moment(task?.startDate).format("hh:mm A"),
    formattedStatus,
  };
};

export default formatTasks;

export const formattedTaskStatus = (status) => STATUS[status];
export const statusPillCN = (status) => PILL_CN[status];

const mockDocumentsData = [
  {
    reference: "Document/248884c0-e6db-4681-8ac0-a260367e1db3",
    name: "Test Test",
    type: "PA",
    resolvedDate: new Date(),
    createdBy: "Raag Mathur",
  },
  {
    reference: "Document/248884c0-e6db-4681-8ac0-a260367e1db3",
    name: "Test Test",
    type: "PA",
    resolvedDate: new Date(),
    createdBy: "Raag Mathur",
  },
  {
    reference: "Document/248884c0-e6db-4681-8ac0-a260367e1db3",
    name: "Test Test",
    type: "PA",
    resolvedDate: new Date(),
    createdBy: "Raag Mathur",
  },
  {
    reference: "Document/248884c0-e6db-4681-8ac0-a260367e1db3",
    name: "Test Test",
    type: "PA",
    resolvedDate: new Date(),
    createdBy: "Raag Mathur",
  },
  {
    reference: "Document/248884c0-e6db-4681-8ac0-a260367e1db3",
    name: "Test Test",
    type: "PA",
    resolvedDate: new Date(),
    createdBy: "Raag Mathur",
  },
  {
    reference: "Document/248884c0-e6db-4681-8ac0-a260367e1db3",
    name: "Test Test",
    type: "PA",
    resolvedDate: new Date(),
    createdBy: "Raag Mathur",
  },
];

const mockTimesheetData = [
  {
    reference: "Document/248884c0-e6db-4681-8ac0-a260367e1db3",
    employee: "Test Test",
    category: "PA",
    rate: "Test",
    timeEntryDate: new Date(),
  },
  {
    reference: "Document/248884c0-e6db-4681-8ac0-a260367e1db3",
    employee: "Test Test",
    category: "PA",
    rate: "Test",
    timeEntryDate: new Date(),
  },
  {
    reference: "Document/248884c0-e6db-4681-8ac0-a260367e1db3",
    employee: "Test Test",
    category: "PA",
    rate: "Test",
    timeEntryDate: new Date(),
  },
  {
    reference: "Document/248884c0-e6db-4681-8ac0-a260367e1db3",
    employee: "Test Test",
    category: "PA",
    rate: "Test",
    timeEntryDate: new Date(),
  },
  {
    reference: "Document/248884c0-e6db-4681-8ac0-a260367e1db3",
    employee: "Test Test",
    category: "PA",
    rate: "Test",
    timeEntryDate: new Date(),
  },
  {
    reference: "Document/248884c0-e6db-4681-8ac0-a260367e1db3",
    employee: "Test Test",
    category: "PA",
    rate: "Test",
    timeEntryDate: new Date(),
  },
];

export { mockDocumentsData, mockTimesheetData };

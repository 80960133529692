import { useRef } from "react";
import { v4 as uuidv4 } from "uuid";

const useAddAttachAndRecentButtonData = ({ onFilesAdded }) => {
  const fileInputRef = useRef();

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  /**
   * Callback that return files in the desire format to be uploaded
   */
  const handleFilesSelected = ({ target: { files: fs } }) => {
    const newFiles = Object.values(fs)?.reduce((list, item) => {
      if (item?.name) {
        list.push({
          original: item,
          id: uuidv4(),
          name: item.name,
          type: item.type,
          ...item,
        });
      }
      return list;
    }, []);

    onFilesAdded(newFiles);
  };

  return {
    fileInputRef,
    handleFileInputClick,
    handleFilesSelected,
  };
};

export default useAddAttachAndRecentButtonData;

/**
 * Validate a Role based on its props.
 * @returns {boolean} true if the role is valid, otherwise false.
 */
export default function isRoleValid({ roleName }) {
  /// You must have a role name
  if (roleName === undefined || roleName.length === 0) {
    return false;
  }

  /// The role name cannot be "admin"
  if (roleName.toLowerCase().localeCompare("admin") === 0) {
    return false;
  }

  /// The role name cannot be "superadmin"
  if (roleName.toLowerCase().localeCompare("superadmin") === 0) {
    return false;
  }

  /// Valid role!
  return true;
}

/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import cntl from "cntl";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { isEqual, cloneDeep } from "lodash";
import {
  WorkflowAPI,
  ProjectAPI,
  ConfigurationAPI,
} from "@griffingroupglobal/eslib-api";

import { useAppState } from "../../../state/appState";
import ProgressTracker from "../ProgressTracker/ProgressTracker";
import Input from "../Input/Input";
import PrimaryHeader from "../TextHeaders/PrimaryHeader";
import PrimaryButton from "../Buttons/PrimaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import WorkflowDetailsStep from "../WorkflowCreation/WorkflowDetailsStep";
import WorkflowStepForm from "../WorkflowCreation/WorkflowStepForm";
import Avatar from "../Message/Avatar";
import Checkbox from "../Checkbox/Checkbox";

import ResourceDropDown from "../WorkflowCreation/ResourceDropDown";
import DocumentAttachmentForm from "../DocumentForm/DocumentAttachmentForm";

import useUsers from "../../../hooks/useUsers";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useWorkflowsConfiguration from "../../../hooks/useWorkflowsConfiguration";
import useDocuments from "../../../hooks/useDocuments";
import useDocumentFormReducer from "../../../hooks/useDocumentFormReducer";
import useFavorites from "../../../hooks/useFavorites";
import { formatSelectUser, getFullName } from "../../../helpers/Formatters";
import PDFViewerModal from "../PDFViewer/PDFViewerModal";
import useProperties from "../../../hooks/useProperties";
import useProjects from "../../../hooks/useProjects";
import Spinner from "../Spinner/Spinner";
import {
  useWorkflows,
  buildWorkflowTemplate,
} from "../../../hooks/useWorkflows";
import { toastMessage } from "../../../helpers/Toast";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteCircleCheckIcon from "../../assets/images/circleCheckIcon.svg";
import { setHoursInDateFromConfiguration } from "../../../helpers/Time";
import ModalConfirmAll from "../Modal/ModalConfirmAll";
import {
  GET_PROPERTY_WORKFLOWS_PATH,
  SET_WORKFLOW_DOCUMENT,
  SET_WORKFLOWS,
  WORKFLOWS_PATH,
  GET_PROJECT_WORKFLOWS_PATH,
  SIMPLE_INPUT,
  UPDATE_DOCUMENT_ATTACHMENTS,
  ADD_DOCUMENT_ATTACHMENTS,
  GET_PROPERTY_DOCUMENT_PATH,
  GET_PROJECT_DOCUMENTS_PATH,
  PURCHASE_ORDER,
} from "../../../constants";
import handleWFCompDays from "../../../helpers/WorkflowHelpers";
import { getLocationState } from "../../../helpers/Navigation";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = <img src={whiteCrossIcon} alt="Close notice" />;

const headerTitleCN = cntl`
    text-gray-400
    font-medium	
    text-lg	
    mb-1
 `;
const headerSubTitleCN = cntl`
    text-gray-500
    font-medium	
 `;

const detailsSchema = yup.object().shape({
  name: yup.string().required(),
  startDate: yup.mixed().required(),
  members: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
  ),
});
const steps = ["DETAILS", "REVIEW"];
const PRIMARY_HEADER = "Create Workflow";

const CreateWorkflowForm = () => {
  const history = useHistory();
  const [{ workflows, workflowDocument }, dispatchAppState] = useAppState();
  const { state } = useLocation();
  const locationState = getLocationState(state);
  const { propertyId, projectId } = useParams();
  const { data: currentUser } = useCurrentUser();
  const { data: workflowsConfiguration } = useWorkflowsConfiguration();
  const [users, , isLoading] = useUsers();
  const [document, dispatch] = useDocumentFormReducer();
  const { documents: docResources, documentsDict } = useDocuments();
  const [favorites, { postFavorite, deleteFavorite }] =
    useFavorites("Document");
  const [properties] = useProperties();
  const { projects } = useProjects();
  const workflowParams = useMemo(() => ({ isTemplate: true }), []);
  const [workflowTemplates] = useWorkflows(workflowParams);

  const getEmptySubStep = () => ({
    name: "",
    duration: null,
    description: "",
    members: [],
    needOnlyOneAdvancer: false,
    newStep: true,
    id: uuidv4(),
  });
  const getEmptyStep = () => ({
    name: "",
    duration: null,
    dueDate: { allDay: true },
    description: "",
    members: [],
    needOnlyOneAdvancer: false,
    newStep: true,
    id: uuidv4(),
    parallelSteps: [],
    totalDurration: 0,
  });

  const [workflowCompletionTime, setWorkflowCompletionTime] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [workflowData, setWorkflowData] = useState({});
  const [workflowDataReset, setWorkflowDataReset] = useState({});
  const [resourceMembers, setResourceMembers] = useState([]);
  const [distroMembers, setDistroMembers] = useState([]);

  const [stepData, setStepData] = useState([getEmptyStep()]);
  const [saveTemplate, setSaveTemplate] = useState(false);
  const [members, setMembers] = useState([]);
  const [disableNext, setDisableNext] = useState(true);
  const [templateName, setTemplateName] = useState("");
  const [workflowDocs, setWorkflowDocs] = useState([]);
  const [template, setTemplate] = useState(null);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState(null);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [isSaving] = useState(false);
  const [selectedDocumentsIds, setSelectedDocumentsIds] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [associationResource, setAssociationResource] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [allUsers, setAllUsers] = useState([]);
  const [resetDocs, setResetDocs] = useState(false);
  const [viaDocumentSendToWorkflowPath, setViaDocumentSendToWorkflowPath] =
    useState(false);
  const [finDocVendor, setFinDocVendor] = useState();

  useEffect(() => {
    if (workflowData.project || workflowData.property || workflowData.company) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [workflowData]);

  useEffect(() => {
    dispatch({
      type: SIMPLE_INPUT,
      key: "attachments",
      value: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // NOTE: will need to handle time if not all day
  const handleUpdateDueDate = React.useCallback(
    (stpData) => {
      const getDuration = (startDate = {}, dueDate = {}) => {
        const startOn = moment(startDate?.date);
        if (startDate?.time) {
          const starthours = Number(startDate.time.split(":")?.[0]);
          const startMinutes = Number(startDate.time.split(":")?.[1]);
          startOn
            .set("hour", starthours)
            .set("minute", startMinutes)
            .set("millisecond", 0);
        }

        const dueOn = moment(dueDate.date);
        if (dueDate?.time) {
          const duehours = Number(dueDate.time.split(":")?.[0]);
          const dueMinutes = Number(dueDate.time.split(":")?.[1]);
          dueOn
            .set("hour", duehours)
            .set("minute", dueMinutes)
            .set("millisecond", 0);
        }

        const duration = dueOn.diff(startOn, "days");
        return duration;
      };

      const setMaxDates = (arr) => {
        let tempArr = arr;
        tempArr = tempArr?.map((step) => {
          let maxDate = step.dueDate;
          step.parallelSteps?.forEach((pstep) => {
            maxDate = moment(pstep.dueDate?.date).isAfter(moment(maxDate?.date))
              ? pstep.dueDate
              : maxDate;

            pstep.dueDate = {
              allDay: pstep?.dueDate?.allDay,
              date: pstep?.dueDate?.date,
              time: pstep?.dueDate?.time?.label,
            };
          });
          return { ...step, maxDate };
        });

        return tempArr;
      };

      setStepData((prev) => {
        let newSteps = stpData ?? prev;
        newSteps = newSteps.map((step, idx) => {
          if (!step.dueDate?.date) return step;
          if (idx === 0 && workflowData?.startDate?.date) {
            return {
              ...step,
              parallelSteps:
                step.parallelSteps?.map((pstep) => {
                  if (pstep.dueDate?.date) {
                    return {
                      ...pstep,
                      duration: pstep?.duration,
                    };
                  }
                  return pstep;
                }) ?? [],
              duration: step?.duration,
            };
          }

          return {
            ...step,
            parallelSteps:
              step.parallelSteps?.map((pstep) => {
                if (pstep.dueDate?.date) {
                  return {
                    ...pstep,
                    duration: getDuration(
                      prev[idx - 1]?.dueDate,
                      pstep.dueDate
                    ),
                  };
                }
                return pstep;
              }) ?? [],
            duration: step?.duration,
          };
        });

        return setMaxDates(newSteps);
      });
    },
    [workflowData?.startDate]
  );

  useEffect(() => {
    if (locationState?.workflow?.associationResource && !associationResource) {
      setAssociationResource(locationState?.workflow?.associationResource);
    }
  }, [locationState, associationResource]);

  useEffect(() => {
    if (
      !(workflowData?.project || workflowData?.property) &&
      (projectId || propertyId)
    ) {
      if (projectId) {
        let projectWf;
        setWorkflowData((prev) => {
          projectWf = {
            ...prev,
            project: `Project/${projectId}`,
          };
          return projectWf;
        });
        setWorkflowDataReset(projectWf);
      }
      if (propertyId) {
        let propertyWf;
        setWorkflowData((prev) => {
          propertyWf = {
            ...prev,
            property: `Property/${propertyId}`,
          };
          return propertyWf;
        });
        setWorkflowDataReset(propertyWf);
      }
    }
  }, [workflowData, projectId, propertyId]);

  useEffect(() => {
    if (
      associationResource &&
      !(workflowData?.property || workflowData?.project)
    ) {
      const associatedResource = associationResource.split("/")[0];
      const parent = [
        {
          reference: locationState?.workflow?.reference,
          association: "parent",
        },
      ];

      if (associatedResource === "Property") {
        setWorkflowData((prev) => ({
          ...prev,
          property: associationResource,
          associatedWorkflows: parent,
        }));
      } else if (associatedResource === "Project") {
        setWorkflowData((prev) => ({
          ...prev,
          project: associationResource,
          associatedWorkflows: parent,
        }));
      }
    }
  }, [workflowData, associationResource, locationState]);

  useEffect(() => {
    if (workflowDocs?.length && !selectedDocumentsIds?.length) {
      setSelectedDocumentsIds(
        workflowDocs?.map((reference) => reference?.split("/")[1])
      );
    }
  }, [workflowDocs, selectedDocumentsIds?.length]);

  useEffect(() => {
    if (
      Array.isArray(workflowDocument) &&
      workflowDocument?.length &&
      docResources?.length
    ) {
      const finDoc = documentsDict[`Document/${workflowDocument[0]}`];

      if (finDoc?.docType === PURCHASE_ORDER) {
        setFinDocVendor(finDoc.distro[0]);
      }
      setViaDocumentSendToWorkflowPath(true);
      const tmpDocuments = [];
      workflowDocument.forEach((doc) => {
        const tmpDocument = docResources?.find((item) => item.id === doc);
        if (tmpDocument) {
          tmpDocuments.push(tmpDocument);
        }
      });

      if (tmpDocuments?.length) {
        setSelectedDocumentsIds((prev) => [
          ...tmpDocuments.map((tmpDocument) => tmpDocument.id),
          ...prev,
        ]);
        dispatch({
          type: ADD_DOCUMENT_ATTACHMENTS,
          value: tmpDocuments,
        });
        dispatchAppState({
          type: SET_WORKFLOW_DOCUMENT,
          value: undefined,
        });
      }
    }
  }, [
    workflowDocument,
    docResources,
    dispatch,
    dispatchAppState,
    documentsDict,
  ]);

  useEffect(() => {
    if (workflowsConfiguration?.workflows) {
      setWorkflowCompletionTime({
        timeOfDay: workflowsConfiguration.workflows.completion.timeOfDay,
        timezone: workflowsConfiguration.workflows.completion.timezone,
      });

      setWorkflowData((prev) => ({
        ...prev,
        startDate: {
          date: setHoursInDateFromConfiguration(
            moment().startOf("day"),
            workflowsConfiguration.workflows.completion.timeOfDay,
            workflowsConfiguration.workflows.completion.timezone
          ),
        },
      }));
    }
  }, [workflowsConfiguration]);

  useEffect(() => {
    if (users?.length) {
      let memberDD =
        users
          ?.filter(
            (usr) =>
              usr.name?.firstName ||
              usr.name?.lastName ||
              usr.kind === "company"
          )
          ?.map((info) => ({
            label: getFullName(info.name),
            value: info.reference,
            reference: info.reference,
            ...formatSelectUser({ ...info, role: info.roleName }),
          })) ?? [];
      const distroMem = memberDD.filter((usr) => usr.kind === "company");
      setAllUsers(memberDD);

      if (workflowData?.property || workflowData?.project) {
        const reference = workflowData?.property || workflowData?.project;
        // currently only properties can be associateed. Post 3.0 add projects and companies
        const resource = reference.split("/")[0];
        switch (resource) {
          case "Property": {
            const propertyMembers =
              properties?.find((prop) => prop.reference === reference)
                ?.members ?? [];

            memberDD = memberDD.filter((usr) =>
              propertyMembers.find((mem) => {
                return mem.user === usr.reference;
              })
            );

            break;
          }
          case "Project": {
            const projectMembers =
              projects?.find((proj) => proj.reference === reference)?.members ??
              [];

            // only allow project members
            memberDD = memberDD.filter((usr) =>
              projectMembers.find((mem) => {
                return mem.user === usr.reference;
              })
            );
            // if a finDoc sent to WF has a vendor,
            // then only allow members of that vendor who are part of the project to be selected
            if (finDocVendor) {
              memberDD = memberDD.filter((usr) => usr.company === finDocVendor);
            }

            break;
          }
          default:
            memberDD = [];
        }
      }

      setResourceMembers(memberDD);
      setDistroMembers(distroMem);
    }
  }, [properties, projects, users, workflowData, finDocVendor]);

  useEffect(() => {
    const hasAssociation = () => {
      return (
        workflowData?.property || workflowData?.project || workflowData?.company
      );
    };

    const handleDisabledAdd = async () => {
      const tempObj = {
        name: workflowData?.name,
        startDate: workflowData?.startDate,
        members: members.filter((info) => info?.reference),
      };
      const isValid =
        (await detailsSchema.isValid(tempObj)) && hasAssociation();

      if (currentStep === 0) {
        const canAdvance =
          isValid &&
          stepData?.length &&
          stepData.every((step) => {
            const validParent =
              step.description && step.dueDate?.date && step.members?.length;
            const validChildren = step.parallelSteps?.reduce((valid, pStep) => {
              return (
                pStep.description &&
                pStep.dueDate?.date &&
                pStep.members?.length &&
                valid
              );
            }, true);

            return validParent && validChildren;
          });
        setDisableNext(!canAdvance);
      } else if (isValid) {
        setDisableNext(false);
      } else {
        setDisableNext(true);
      }
    };
    handleDisabledAdd();
  }, [
    members,
    currentStep,
    stepData,
    template,
    workflowData?.name,
    workflowData?.startDate,
    workflowData?.property,
    workflowData?.project,
    workflowData?.company,
  ]);

  useEffect(() => {
    if (workflowTemplates?.length && !templateOptions?.length) {
      setTemplateOptions(
        workflowTemplates
          ?.filter((wf) => wf.isTemplate)
          .map((wf) => ({
            ...wf,
            label: wf.name,
            value: wf.id,
          }))
      );
    }
  }, [workflowTemplates, templateOptions]);

  const handleMemberRefresh = (wf, resource, allMembers) => {
    const pimaryMemberList = [...(allMembers ?? resourceMembers)];
    wf.steps = wf.steps?.map((step) => {
      const tmpStep = step;
      tmpStep.members = pimaryMemberList
        .filter((mem) =>
          tmpStep.members?.find((item) => item.reference === mem.reference)
        )
        .filter((item) => {
          return resource.members?.find((rmem) => rmem.user === item.reference);
        });

      tmpStep.parallelSteps = tmpStep.parallelSteps?.map((par) => {
        const parMembers = pimaryMemberList
          .filter((mem) =>
            par.members?.find((item) => item.reference === mem.reference)
          )
          .filter((item) => {
            return resource.members?.find(
              (rmem) => rmem.user === item.reference
            );
          });
        return { ...par, id: uuidv4(), members: parMembers };
      });

      return { ...tmpStep, id: uuidv4() };
    });
  };

  const handleRemoveMember = (usr) => {
    setMembers(
      members.filter((item) => {
        return item.reference !== usr;
      })
    );
  };

  const handleChangeMember = (val) => {
    setMembers(val);
  };

  const handleDeleteStep = (parentIndex, index, isParallel) => {
    const tempArr = cloneDeep(stepData);
    if (!isParallel && tempArr[parentIndex]?.parallelSteps?.length === 0) {
      // main step with no parallel
      tempArr.splice(parentIndex, 1);
      if (!tempArr.length) {
        tempArr.push(getEmptyStep());
      }
    } else if (!isParallel) {
      // main step with parallel steps
      const nextMain = tempArr[parentIndex].parallelSteps.splice(0, 1)[0];
      nextMain.parallelSteps = tempArr[parentIndex].parallelSteps;
      tempArr[parentIndex] = nextMain;
    } else {
      // parallel step
      tempArr[parentIndex].parallelSteps.splice(index, 1);
    }
    setStepData(tempArr);
  };

  const stepClickHandler = (index) => {
    setCurrentStep(index);
  };

  const handleEditStep = (parentIndex, index, value, isParallel) => {
    const tempArr = [...stepData];
    if (isParallel) {
      tempArr[parentIndex].parallelSteps[index] = value;
    } else {
      tempArr[parentIndex] = value;
    }
    handleUpdateDueDate(tempArr);
  };

  const handleDragInStep = (
    fromParent,
    fromIndex,
    fromIsParallel,
    toParent
  ) => {
    const tempArr = cloneDeep(stepData);

    let newStep;
    if (!fromIsParallel && tempArr[fromParent].parallelSteps.length === 0) {
      // main step no parallel
      if (fromParent === toParent + 1 || fromParent === toParent) {
        return;
      }
      const newMain = tempArr.splice(fromParent, 1)[0];
      newStep = newMain;
    } else if (!fromIsParallel) {
      const newMain = tempArr[fromParent];
      const nextMain = tempArr[fromParent].parallelSteps.splice(0, 1)[0];
      nextMain.parallelSteps = tempArr[fromParent].parallelSteps;
      tempArr[fromParent] = nextMain;
      newMain.parallelSteps = [];
      newStep = newMain;
    } else {
      const newMain = tempArr[fromParent].parallelSteps.splice(fromIndex, 1)[0];
      newMain.parallelSteps = [];
      newStep = newMain;
    }
    if (toParent === -1) {
      tempArr.unshift(newStep);
    } else {
      tempArr.splice(toParent, 0, newStep);
    }
    setStepData(tempArr);
  };

  const handleAddNewStep = () => {
    const tempArr = [...stepData];
    tempArr.push(getEmptyStep());
    setStepData(tempArr);
  };

  const handleAddParallelStep = (parentIndex) => {
    const tempArr = [...stepData];
    const emptyStep = getEmptySubStep();
    tempArr[parentIndex].parallelSteps.push(emptyStep);
    setStepData(tempArr);
  };
  // TODO: need to refactor handleTemplate
  const handleTemplate = (id, association, memberList) => {
    if (id) {
      let resource;
      const wfTmp =
        cloneDeep(workflowTemplates?.find((wf) => wf.id === id)) ?? {};
      const currentDate = moment();
      const previousDate = wfTmp.startDate.date;
      const stepInfo = wfTmp.steps.map((step, i) => {
        const start = previousDate;
        const due = step.dueDate.date;
        const compDue = moment(due);
        const compStart = moment(start);
        const duration = compDue.diff(compStart, "days");
        const stepObj = {
          stepNumber: i,
          stepStart: start,
          stepDue: compDue,
          duration,
        };
        return stepObj;
      });

      if (previousDate !== currentDate) {
        wfTmp.startDate.date = currentDate;
      }

      if (wfTmp.property && properties) {
        resource = properties.find((item) => item.reference === wfTmp.property);
      } else if (wfTmp.project && projects) {
        resource = projects.find((item) => item.reference === wfTmp.project);
      }

      dispatch({
        type: SIMPLE_INPUT,
        key: "attachments",
        value: docResources.filter((doc) => {
          return wfTmp.documents?.includes(doc.reference);
        }),
      });
      setWorkflowDocs(wfTmp.documents);

      handleMemberRefresh(wfTmp, association ?? resource, memberList);

      delete wfTmp.id;
      delete wfTmp.reference;
      delete wfTmp.name;
      delete wfTmp.project;
      delete wfTmp.property;
      delete wfTmp.metadata;
      delete wfTmp.dateCreated;
      delete wfTmp.isTemplate;
      delete wfTmp.status;
      delete wfTmp.version;

      wfTmp.steps.forEach((step) => {
        const start = previousDate;
        const due = step.dueDate.date;
        // function that calculates duration, and returns a new dueDate
        const newDueDate = handleWFCompDays(start, due);
        step.dueDate.date = newDueDate;
        const { parallelSteps } = step;
        if (parallelSteps.length > 0) {
          parallelSteps.forEach((pStep) => {
            const newParalellStart = moment()
              .add(pStep.duration, "days")
              .format();
            pStep.dueDate.date = newParalellStart;
          });
        }
      });

      setStepData(wfTmp.steps);
      if (association) {
        return wfTmp;
      }

      setMembers(
        distroMembers.filter((dist) => wfTmp.members?.includes(dist.reference))
      );

      if (projectId) {
        setWorkflowData({
          ...wfTmp,
          stepInfo,
          project: `Project/${projectId}`,
        });
      }
      if (propertyId) {
        setWorkflowData({
          ...wfTmp,
          stepInfo,
          project: `ProPERTY/${propertyId}`,
        });
      }
    } else {
      setStepData([getEmptyStep()]);
      setWorkflowData(workflowDataReset);
      setTemplate();
      setMembers([]);
      setWorkflowDocs([]);
      document.attachments = [];
    }
    return null;
  };

  const handleEditWorkflow = (key, value) => {
    // Will need to be updated to include companies once added
    const checkList = ["porperty", "project", "company"];
    let resource;
    let tempObj;

    if (template && checkList.includes(key)) {
      resource = [...projects, ...properties].find(
        (item) => item.reference === value
      );
      tempObj = handleTemplate(template.id, resource, allUsers);
    } else {
      tempObj = { ...workflowData };
    }

    switch (key) {
      case "property": {
        tempObj[key] = value;
        tempObj.project = undefined;
        tempObj.company = undefined;
        break;
      }
      case "project": {
        tempObj[key] = value;
        tempObj.property = undefined;
        tempObj.company = undefined;
        break;
      }
      case "company": {
        tempObj[key] = value;
        tempObj.project = undefined;
        tempObj.property = undefined;
        break;
      }
      default: {
        tempObj[key] = value;
      }
    }

    switch (key) {
      case "property":
      case "project":
      case "company": {
        dispatch({
          type: SIMPLE_INPUT,
          key,
          value,
        });
        break;
      }
      default: {
        break;
      }
    }

    if (resource?.members && template?.members) {
      setMembers(
        allUsers
          .filter((mem) => {
            return template.members.includes(mem.reference);
          })
          .filter((mem) => {
            return mem.kind === "company";
          })
      );
    }
    setWorkflowData(tempObj);
    dispatchAppState({
      type: SET_WORKFLOW_DOCUMENT,
      value: tempObj,
    });
  };

  const handleTemplateCheck = (val) => {
    setSaveTemplate(val);
    setTemplateName("");
  };

  const handleAssociatedWF = async (reference, wfList) => {
    const tempWF = cloneDeep(locationState.workflow);
    const tempAssociation = {
      association: locationState.status,
      reference,
    };
    tempWF.associatedWorkflows = [
      ...tempWF?.associatedWorkflows,
      tempAssociation,
    ];

    const { data: newWF } = await WorkflowAPI.patch(
      locationState.workflow.id,
      tempWF,
      locationState.workflow
    );

    dispatchAppState({
      type: SET_WORKFLOWS,
      workflows: wfList.map((item) => {
        if (item.reference === newWF.reference) {
          return newWF;
        }
        return item;
      }),
    });
  };

  const createTemplate = useCallback(
    async (postPayload, existingWorkflows) => {
      try {
        const { data: workflowsConfig } = await ConfigurationAPI.getById(
          "workflows"
        );

        const { data } = await WorkflowAPI.post({
          ...postPayload,
          isTemplate: true,
          name: templateName,
          isDraft: false,
        });

        // patch config
        const patchBody = {
          workflows: {
            ...workflowsConfig.workflows,
            templates: [
              ...workflowsConfig.workflows.templates,
              { reference: data.reference },
            ],
          },
        };

        await ConfigurationAPI.patch(
          "workflows",
          patchBody,
          { workflows: workflowsConfig.workflows },
          {
            date: workflowsConfig.metadata.lastUpdated,
          }
        );

        const temp = await buildWorkflowTemplate(data, users);

        dispatchAppState({
          type: SET_WORKFLOWS,
          workflows: [...existingWorkflows, temp],
        });
      } catch (err) {
        console.error("Error updating workflows configuration");
      }
    },
    [templateName, dispatchAppState, users]
  );

  const handleCreateWorkflow = async () => {
    const formatedSteps = stepData.filter(
      (info) =>
        !info.newStep && info?.parallelSteps.filter((par) => !par.newStep)
    );

    const formattedStepsWithTime = formatedSteps?.map((step) => {
      if (step?.dueDate?.time?.value) {
        return {
          ...step,
          dueDate: {
            date: step?.dueDate?.date,
            allDay: step?.dueDate?.allDay,
            time: step?.dueDate?.time?.label,
          },
          sop: step?.sop?.[0]?.sop ?? null,
        };
      }
      return {
        ...step,
        dueDate: {
          date: step?.dueDate?.date,
          allDay: step?.dueDate?.allDay,
          time: "11:00 PM",
        },
        sop: step?.sop?.[0]?.sop ?? null,
      };
    });

    formattedStepsWithTime.forEach((step) =>
      step?.sop === null ? delete step.sop : step
    );

    const tempObj = {
      ...workflowData,
      members: members.map((info) => info?.reference),
      documents: document.attachments?.map((info) => info.reference),
      files: [],
      name: workflowData?.name,
      cost: workflowData?.cost?.replace(/,/g, ""),
      startDate: workflowData?.startDate,
      status: "inProgress",
      version: 1,
      steps: formattedStepsWithTime,
      documentTypes: document.attachments
        ?.filter((doc) => doc.docType?.split("-").length === 1)
        .map((doc) => doc.docType),
    };

    toastMessage(
      `${workflowData?.name} has been saved and initiated.`,
      toastIcon,
      toastCloseIcon
    );

    const { data: res } = await WorkflowAPI.post(tempObj);

    if (workflowData.project) {
      const project =
        projects.find((item) => item.reference === workflowData.project) ?? {};
      const newProject = {
        ...project,
        members:
          project.members?.filter(
            (item) => !members.find((mem) => mem.reference === item.reference)
          ) ?? [],
      };

      newProject.members = [
        ...newProject.members,
        ...(members.map((mem) => {
          const newMem = { user: mem.user };
          if (mem.position) newMem.position = mem.position;
          return newMem;
        }) ?? []),
      ];

      ProjectAPI.patch(workflowData.project.split("/")[1], newProject, project);
    }

    const wfList = [...(workflows ?? []), res];
    dispatchAppState({
      type: SET_WORKFLOWS,
      workflows: wfList,
    });

    if (saveTemplate && templateName?.length > 0) {
      createTemplate(tempObj, wfList);
    }

    if (locationState?.workflow) {
      await handleAssociatedWF(res?.reference, wfList);
    }

    if (propertyId) {
      if (viaDocumentSendToWorkflowPath) {
        history.push(GET_PROPERTY_DOCUMENT_PATH(propertyId));
      } else {
        history.push(GET_PROPERTY_WORKFLOWS_PATH(propertyId));
      }
    } else if (projectId) {
      if (viaDocumentSendToWorkflowPath) {
        history.push(GET_PROJECT_DOCUMENTS_PATH(projectId));
      } else {
        history.push(GET_PROJECT_WORKFLOWS_PATH(projectId));
      }
    } else {
      history.push(WORKFLOWS_PATH);
    }
  };

  const handleNext = () => {
    const selectedDocs = document.attachments?.map((info) => info?.reference);
    setWorkflowDocs(selectedDocs);
    if (currentStep < 1) {
      setCurrentStep((prev) => prev + 1);
      if (currentStep === 0) {
        const selectedMembers = members?.filter((info) => info.value);
        setMembers(selectedMembers);
      }
    } else if (currentStep === 1) {
      handleCreateWorkflow();
    }
  };

  const showSaveTemplate =
    currentStep === 1 && !isEqual(stepData, workflowTemplates?.Sample);

  const handleTemplateChange = (val) => {
    if (!val.value) {
      setWorkflowData(workflowDataReset);
      setStepData([getEmptyStep()]);
      setMembers([]);
      setTemplate();
      dispatch({
        type: SIMPLE_INPUT,
        key: "attachments",
        value: [],
      });
    } else {
      handleTemplate(val?.value);
      setTemplate(val);
    }
  };

  const onCancel = () => {
    setStepData([getEmptyStep()]);
    setSelectedDocumentsIds([]);

    dispatch({
      type: UPDATE_DOCUMENT_ATTACHMENTS,
      value: [],
    });

    setShowConfirm(true);
  };

  const onChangeCallback = () => {
    setStepData([getEmptyStep()]);
    setSelectedDocumentsIds([]);
    dispatch({
      type: UPDATE_DOCUMENT_ATTACHMENTS,
      value: [],
    });

    dispatchAppState({
      type: SET_WORKFLOW_DOCUMENT,
      value: workflowData,
    });
  };

  const getWorkflowsPath = () => {
    if (propertyId) {
      if (viaDocumentSendToWorkflowPath) {
        return GET_PROPERTY_DOCUMENT_PATH(propertyId);
      }
      return GET_PROPERTY_WORKFLOWS_PATH(propertyId);
    }
    if (projectId) {
      if (viaDocumentSendToWorkflowPath) {
        return GET_PROJECT_DOCUMENTS_PATH(projectId);
      }
      return GET_PROJECT_WORKFLOWS_PATH(projectId);
    }
    return WORKFLOWS_PATH;
  };

  const getEndDate = () => {
    const startOn = moment(workflowData.startDate?.date);
    if (workflowData.startDate?.time) {
      const starthours = Number(workflowData.startDate.time.split(":")?.[0]);
      const startMinutes = Number(workflowData.startDate.time.split(":")?.[1]);
      startOn.set("hour", starthours).set("minute", startMinutes);
    }

    const lastStep = stepData[stepData.length - 1];
    const dueOn = moment(lastStep.maxDate?.date).format("MMM D, YYYY");
    const duration = moment(lastStep.maxDate?.date).diff(startOn, "days");

    return {
      date: dueOn,
      days: duration === 1 ? `${duration} Day` : `${duration} Days`,
    };
  };

  const getDueDate = (start, duration) => {
    return moment(start).add(duration, "days").format();
  };

  useEffect(() => {
    setStepData((prev) => {
      let totalDuration = 0;
      let pSteps = [];
      const newSteps = prev.map((step) => {
        totalDuration += step?.duration;
        const startDate = workflowData?.startDate?.date;
        if (step?.parallelSteps?.length > 0) {
          pSteps = step?.parallelSteps;
          const newPSteps = pSteps?.map((pStep) => {
            const pStart = pStep?.dueDate?.date;
            const pDuration = pStep?.duration + totalDuration;
            const pDue = getDueDate(pStart, pDuration);

            return {
              ...pStep,
              dueDate: {
                allDay: pStep?.dueDate?.allDay,
                time: pStep?.dueDate?.date?.time?.label,
                timeValue: pStep?.dueDate?.date?.time?.label,
                date: pDue,
              },
            };
          });
          pSteps = newPSteps;
        }
        const dueDate = getDueDate(startDate, totalDuration);
        return {
          ...step,
          parallelSteps: pSteps,
          dueDate: {
            allDay: step?.dueDate?.allDay,
            time: step?.dueDate?.time?.label,
            timeValue: step?.dueDate?.time?.value,
            date: dueDate,
          },
        };
      });
      return newSteps;
    });
  }, [workflowData]);

  return (
    <>
      <div className="flex justify-between">
        <div className="flex flex-col flex-1">
          <PrimaryHeader className="pt-6">{PRIMARY_HEADER}</PrimaryHeader>
        </div>
        <div className="flex flex-1 justify-end pr-14">
          <ProgressTracker
            steps={steps}
            selectedIndex={currentStep}
            onStepClick={(index) => stepClickHandler(index)}
            disableNext={disableNext}
          />
        </div>
      </div>
      <hr />

      {(isLoading || isSaving) && (
        <div className="flex justify-center items-center w-full h-full">
          <Spinner notFullScreen />
        </div>
      )}

      {!isLoading && (
        <div className="">
          {currentStep === 0 && (
            <>
              <WorkflowDetailsStep
                resetDocs={resetDocs}
                setResetDocs={setResetDocs}
                customOnChange={onChangeCallback}
                isDisabled={isDisabled}
                workflowData={workflowData}
                handleEditWorkflow={handleEditWorkflow}
                members={members}
                template={template}
                templateOptions={templateOptions}
                distroMembers={distroMembers}
                handleTemplateChange={handleTemplateChange}
                handleChangeMember={handleChangeMember}
                handleRemoveMember={handleRemoveMember}
                document={document}
                dispatch={dispatch}
                docResources={docResources}
                favorites={favorites}
                postFavorite={postFavorite}
                deleteFavorite={deleteFavorite}
                workflowCompletionTime={workflowCompletionTime}
                selectedDocumentsIds={selectedDocumentsIds}
                disableAssociation={
                  propertyId || projectId || associationResource
                }
              />
              {isDisabled && (
                <div> Please select an association to start adding steps.</div>
              )}
              {!isDisabled && (
                <WorkflowStepForm
                  stepData={stepData}
                  workflowData={workflowData}
                  template={template}
                  allMembers={resourceMembers}
                  handleDragInStep={handleDragInStep}
                  handleAddNewStep={handleAddNewStep}
                  handleAddParallelStep={handleAddParallelStep}
                  handleEditStep={handleEditStep}
                  handleDeleteStep={handleDeleteStep}
                  handleTemplate={handleTemplate}
                  setStepData={setStepData}
                  noTopStep
                  canEdit
                  currentUser={currentUser}
                />
              )}
            </>
          )}

          {currentStep === 1 && (
            <>
              <div className="py-8">
                <h2 className="mb-10 text-2xl uppercase">
                  {workflowData.name}
                </h2>

                <div className="flex justify-between w-5/6">
                  <div className="flex flex-col mr-4">
                    <div className="flex items-center">
                      <Avatar
                        avatar={
                          distroMembers?.find(
                            (mem) => mem.reference === currentUser?.reference
                          )?.avatar
                        }
                        name={getFullName(currentUser?.name)}
                        isUser
                      />
                      <h3 className="text-gray-400 font-medium text-lg ml-4">
                        Created by{" "}
                        <span className="capitalize">
                          {getFullName(currentUser?.name)}
                        </span>
                      </h3>
                    </div>
                    <div className="ml-12">{`${moment().format(
                      "MMM D, YYYY"
                    )}, at ${moment().format("h:mm A")}`}</div>
                  </div>

                  <div className="flex flex-col">
                    <h3 className={headerTitleCN}>Duration</h3>
                    <div>
                      <h3 className={headerSubTitleCN}>
                        {`${moment(workflowData?.startDate?.date)?.format(
                          "MMM D, YYYY"
                        )} - ${getEndDate().date}`}
                      </h3>
                      <h3 className="text-gray-200 mt-1">
                        {getEndDate().days}
                      </h3>
                    </div>
                  </div>

                  {/*                   <div className="flex flex-col">
                    <h3 className={headerTitleCN}>Distro List</h3>
                    <div className="flex flex-row">
                      {members.map((info) => (
                        <div key={info.reference}>
                          <Avatar
                            className="mr-2 rounded-full"
                            avatar={info.avatar}
                            name={info?.label}
                            isUser
                          />
                        </div>
                      ))}
                    </div>
                  </div> */}

                  <div className="flex flex-col">
                    <h3 className={headerTitleCN}>Association</h3>
                    <ResourceDropDown
                      customOnChange={onChangeCallback}
                      isWorkflow
                      resource={
                        workflowData.property ||
                        workflowData.project ||
                        workflowData.company
                      }
                      showTextView
                    />
                  </div>
                </div>

                <div className="flex flex-col">
                  <h3 className={headerTitleCN}>Attachments</h3>
                  <DocumentAttachmentForm
                    resetDocs={resetDocs}
                    setResetDocs={setResetDocs}
                    /* isWorkflowDoc */
                    isDisabled={isDisabled}
                    document={document}
                    dispatch={dispatch}
                    favorites={favorites}
                    deleteFavorite={deleteFavorite}
                    postFavorite={postFavorite}
                    selectedDocuments={selectedDocumentsIds}
                    setSelectedDocuments={setSelectedDocumentsIds}
                    docResources={docResources}
                    showFile
                  />
                </div>
              </div>

              <WorkflowStepForm
                stepData={stepData}
                workflowData={workflowData}
                template={template}
                allMembers={resourceMembers}
                handleDragInStep={handleDragInStep}
                handleAddNewStep={handleAddNewStep}
                handleAddParallelStep={handleAddParallelStep}
                handleEditStep={handleEditStep}
                handleDeleteStep={handleDeleteStep}
                handleTemplate={handleTemplate}
                setStepData={handleUpdateDueDate}
                noTopStep
                canEdit
                currentUser={currentUser}
              />
              {selectedDocumentUrl && (
                <PDFViewerModal
                  isOpen
                  onClose={() => setSelectedDocumentUrl(null)}
                  initialUrl={selectedDocumentUrl}
                />
              )}
            </>
          )}

          <div className="flex justify-end mt-40 mb-10">
            {showSaveTemplate && (
              <div className="flex justify-end p-1 bg-white">
                <Checkbox
                  className="min-w-max"
                  label="Save as template"
                  checked={saveTemplate}
                  onChange={handleTemplateCheck}
                />
                {saveTemplate && (
                  <Input
                    value={templateName}
                    onChange={setTemplateName}
                    placeholder="Name"
                    className="w-80 ml-2 flex-none"
                  />
                )}
              </div>
            )}
          </div>

          <div className="flex justify-end items-center mt-4">
            <TertiaryButton
              onClick={onCancel}
              title="Cancel"
              className="mr-6"
            />
            <PrimaryButton
              title={currentStep === 1 ? "Initiate" : "Next"}
              onClick={handleNext}
              disabled={disableNext}
            />
          </div>
        </div>
      )}
      <ModalConfirmAll
        navigateToPath={getWorkflowsPath()}
        showConfirmModal={showConfirm}
        setShowConfirmModal={setShowConfirm}
        modalAction={`Cancel ${PRIMARY_HEADER}`}
      />
    </>
  );
};

export default CreateWorkflowForm;

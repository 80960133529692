import React from "react";
import AddAttachmentsButton from "../../AddAttachmentsButton/index";
import AttachmentsHorizontalList from "../../AttachmentsHorizontalList/index";
import useAttachmentsViewData from "./useAttachmentsViewData";

const Attachments = ({
  isEditing,
  canAddAttachments,
  noTitle,
  stepAttachments,
  filesState,
  handleFilesToAdd,
  handleFilesToRemove,
}) => {
  const { attachments, containerContainerClassName, addNewFiles, removeFile } =
    useAttachmentsViewData({
      stepAttachments,
      filesState,
      handleFilesToAdd,
      handleFilesToRemove,
      noTitle,
    });

  return (
    <div className={containerContainerClassName}>
      {!noTitle && <span className="font-semibold text-lg">Attachments</span>}

      <div className="mb-3">
        <AttachmentsHorizontalList
          isEditing={isEditing}
          files={attachments}
          onFileRemoved={removeFile}
        />
      </div>
      {canAddAttachments && <AddAttachmentsButton onFilesAdded={addNewFiles} />}
    </div>
  );
};

export default Attachments;

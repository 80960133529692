import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useHistory, useParams } from "react-router";
import { resolveFileRefByQuery } from "../../../../../helpers/File";
import smoothScrollTo from "../../../../../helpers/smoothScroll";
import formatTaskHistoryComment from "../../../../../helpers/Task/formatTaskHistoryComment";
import getTaskHistoryDuration from "../../../../../helpers/Task/getTaskHistoryDuration";
import { useTaskComments } from "../../../../../hooks/useComments";
import useSopVersionForTask from "../../../../../hooks/useSopVersionForTask";
import useTaskActivity from "../../../../../hooks/useTaskActivity";
import { useAppState } from "../../../../../state/appState";
import { getSingleResourcePathWithParams } from "../../../../../helpers/Navigation";

const useTaskHistoryData = (taskForm) => {
  const { ref, inView } = useInView();
  const [{ userDict }] = useAppState();
  const { data: commentMap } = useTaskComments(taskForm?.reference);
  const { data, hasNextPage, fetchNextPage } = useTaskActivity(taskForm?.id);
  const [originalData, setOriginalData] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [allSteps, setAllSteps] = useState([]);
  const [stepsUpdated, setStepsUpdated] = useState(false);
  const [expandedRowInfo, setExpandedRowInfo] = useState({});
  const [selectedFilter, setSelectedFilter] = useState({
    all: true,
    complete: false,
    incomplete: false,
    overdue: false,
  });
  const { usedSopDict } = useSopVersionForTask({
    currentTask: { steps: allSteps },
  });
  const [filteredData, setFilteredData] = useState([]);

  const params = useParams();
  const history = useHistory();

  const timeoutRef = useRef();

  /**
   * Determines if navigation to a task should be blocked.
   * Navigation is blocked if the task is deleted or if the currently displayed task is the same as the selected task.
   * @param {Object} selectedTask - Selected row task
   * @returns Boolean
   */
  const shouldBlockTaskNavigation = (selectedTask) => {
    return (
      selectedTask?.status === "deleted" ||
      selectedTask.instanceStartDate === taskForm.instanceStartDate
    );
  };

  const onChangeFilter = (filterType) => {
    switch (filterType) {
      case "all": {
        setSelectedFilter({
          all: true,
          complete: false,
          incomplete: false,
          overdue: false,
        });
        break;
      }
      case "complete": {
        setSelectedFilter({
          all: false,
          complete: true,
          incomplete: false,
          overdue: false,
        });
        break;
      }
      case "incomplete": {
        setSelectedFilter({
          all: false,
          complete: false,
          incomplete: true,
          overdue: false,
        });
        break;
      }
      case "overdue": {
        setSelectedFilter({
          all: false,
          complete: false,
          incomplete: false,
          overdue: true,
        });
        break;
      }
      default:
    }
  };

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  useEffect(() => {
    if (selectedFilter.all) {
      setFilteredData(originalData);
    } else if (selectedFilter.overdue) {
      setFilteredData(originalData.filter((row) => row.status === "overdue"));
    } else if (selectedFilter.complete) {
      setFilteredData(originalData.filter((row) => row.status === "done"));
    } else if (selectedFilter.incomplete) {
      setFilteredData(
        originalData.filter((row) => row.status === "incomplete")
      );
    }
  }, [selectedFilter, originalData]);

  useEffect(() => {
    const fetchFiles = async (closingFiles) => {
      const { files } = await resolveFileRefByQuery(closingFiles);

      setOriginalData((prev) =>
        prev.map((row) => {
          const { mediaFiles, documentFiles } = files.reduce(
            (acc, file) => {
              if (row.files.indexOf(file?.reference) !== -1) {
                if (file.category === "Documents") {
                  // eslint-disable-next-line no-param-reassign
                  acc = {
                    ...acc,
                    documentFiles: [
                      ...acc.documentFiles,
                      { ...file, ref: file.reference },
                    ],
                  };
                } else {
                  // eslint-disable-next-line no-param-reassign
                  acc = {
                    ...acc,
                    mediaFiles: [
                      ...acc.mediaFiles,
                      { ...file, ref: file.reference },
                    ],
                  };
                }
              }
              return acc;
            },
            { mediaFiles: [], documentFiles: [] }
          );

          return {
            ...row,
            mediaFiles,
            documentFiles,
          };
        })
      );
    };

    if (allFiles?.length) {
      fetchFiles(allFiles);
    }
  }, [allFiles]);

  useEffect(() => {
    if (Object.keys(usedSopDict).length && !stepsUpdated) {
      setOriginalData((prev) =>
        prev.map((row) => {
          return {
            ...row,
            steps: row?.steps?.map(({ stepData }) => {
              return { ...usedSopDict?.[stepData?.sop], stepData };
            }),
          };
        })
      );
      // needed to stop usedSopDict that may trigger rerenders
      setStepsUpdated(true);
    }
  }, [stepsUpdated, usedSopDict]);

  useEffect(() => {
    if (data?.pages?.length) {
      let files = [];
      let steps = [];
      let activities = [];
      data?.pages?.forEach((page, index) => {
        activities = [
          ...activities,
          ...page.data.map((entry) => {
            const commentResource = commentMap[entry?.closing?.comment];
            const entryFiles = entry?.closing?.files || [];

            files = [...files, ...entryFiles];

            steps = [...steps, ...entry?.steps];

            return {
              files: entry?.closing?.files || [],
              invitees: entry?.invitees?.map((invitee) => {
                const inviteeInfo = userDict?.[invitee];
                return inviteeInfo;
              }),

              steps: entry?.steps?.map((item) => {
                return { stepData: item };
              }),
              mediaFiles: [],
              documentFiles: [],
              duration: getTaskHistoryDuration(entry),
              formattedComments:
                commentResource && formatTaskHistoryComment(commentResource),
              index,
              instanceStartDate: entry?.instanceStartDate,
              startDate: entry?.startDate,
              endDate: entry?.endDate,
              timezone: entry?.timezone,
              recurrence: entry?.recurrence,
              status: entry?.metadata?.deletedAt ? "deleted" : entry?.status,
              isDeleted: !!entry?.metadata?.deletedAt,
              id: entry?.id,
            };
          }),
        ];
      });
      setAllFiles(files);
      setAllSteps(steps);
      setOriginalData(activities);
      setStepsUpdated(false);
    }
  }, [commentMap, data?.pages, userDict]);

  const timerRef = useRef(null);

  useEffect(() => {
    return () => {
      // Clear the timer on unmount
      if (timerRef.current) clearTimeout(timerRef.current);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const onRowClick = (index) => {
    setExpandedRowInfo((prev) => ({
      ...prev,
      [`${index}`]: !prev[index],
    }));
  };

  const onButtonClick = async (index, task) => {
    // If the past task was deleted or it is displayed in the task view, do not navigate to the page. Instead, only expand the collapsible section.
    if (shouldBlockTaskNavigation(task)) {
      onRowClick(index);
      return;
    }

    // Generates the task URL depending on whether it is accessed from the association page or the task page.
    const taskLocation = params?.taskId
      ? `/tasks/${task.id}`
      : getSingleResourcePathWithParams(
          taskForm.association,
          `tab=tasks&id=${task.id}`
        );

    // Generates the task instanceStartDate param depending on whether it is accessed from the association page or the task page.
    const taskInstancePath = `instanceStartDate=${task.instanceStartDate}`;
    const queryParams = params?.taskId
      ? `?${taskInstancePath}`
      : `&${taskInstancePath}`;

    // scroll to the top of the screen/widget
    timerRef.current = smoothScrollTo("widget-child");

    timeoutRef.current = setTimeout(() => {
      // TODO (RTD Perf: Implement `useQueryNavigation` when using RTD to ensure navigation always points to the most up-to-date task)
      // Currently, it fetches the latest data due to `staleTime: 0` in the single task query.
      history.push(`${taskLocation}${queryParams}`);
    }, 200);
  };

  return {
    filteredData,
    onButtonClick,
    onRowClick,
    expandedRowInfo,
    lastRowReferenceForInfiniteScroll: ref,
    onChangeFilter,
    selectedFilter,
    shouldBlockTaskNavigation,
  };
};

export default useTaskHistoryData;

import React from "react";
import PropTypes from "prop-types";
import ThreadReply from "./ReplyMessage";
import InlineInput from "../../../../../stories/Components/Input/InlineInput";
import useReplyThreadData from "./useReplyThreadData";
import {
  ERROR_LOADING_REPLIES,
  ERROR_SENDING_MESSAGE,
} from "../../../../../constants";
import AddMessageAttachment from "../../AddMessageAttachment";
import MessageAttachments from "../../DirectMessage/MessageAttachments";
import ReloadButton from "../../ReloadButton";
import SendMessageButton from "../../SendMessageButton";

const ReplyThread = ({ id, count, show }) => {
  const {
    sendReply,
    onChangeMessage,
    onAddFiles,
    replies,
    loadingFiles,
    loading,
    error,
    resend,
    input,
    handleRemoveFiles,
    files,
    reload,
  } = useReplyThreadData(id, count);

  return (
    <div className={`${show ? "" : "max-h-0 overflow-hidden"}`}>
      {loading ? (
        <div className="loading-circle-small" />
      ) : (
        <div>
          {error?.err === ERROR_LOADING_REPLIES ? (
            <div className="flex flex-col gap-1 text-errorRed text-xxs items-center justify-center py-1">
              <p>Failed to load replies.</p>
              <ReloadButton onClick={reload} loading={loading} />
            </div>
          ) : (
            <>
              {replies?.map((item) => (
                <ThreadReply key={item?.id} {...item} />
              ))}
              {error?.err === ERROR_SENDING_MESSAGE && (
                <ThreadReply {...error?.data} resend={resend} />
              )}
              <div className="flex flex-col gap-2 mt-4">
                {files.length > 0 && (
                  <MessageAttachments
                    files={files}
                    handleRemoveDrop={handleRemoveFiles}
                  />
                )}
                <InlineInput
                  inputStyle={{ paddingTop: "0.5rem", margin: "0" }}
                  editing
                  textarea
                  value={input ?? null}
                  onChangeCallback={onChangeMessage}
                  width="w-full"
                  size="md"
                  rows={4}
                  // disabled={newMsg}
                  placeholder="Type Message"
                  hidePencil
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      sendReply();
                      onChangeMessage("");
                    }
                  }}
                />

                <AddMessageAttachment
                  onAddFiles={onAddFiles}
                  loading={loadingFiles}
                />
                <SendMessageButton
                  send={() => {
                    sendReply();
                    onChangeMessage("");
                  }}
                  disabled={loadingFiles}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

ReplyThread.propTypes = {
  id: PropTypes.string,
  count: PropTypes.number,
  show: PropTypes.bool,
};

ReplyThread.defaultProps = {
  id: undefined,
  count: undefined,
  show: false,
};

export default ReplyThread;

import React from "react";
import PropTypes from "prop-types";
import { useDrop } from "react-dnd";
import PrimaryButton from "../Buttons/PrimaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";

const WorkflowAddStep = ({
  handleAddNewStep,
  parentIndex,
  handleDragInStep,
  disabled,
}) => {
  const ItemTypes = {
    CARD: "card",
  };
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,
    drop: (item) => {
      const dragParentIndex = item.cardPos.parentIndex;
      const dragIndex = item.cardPos.index;
      const dragIsParallel = item.cardPos.isParallel;
      handleDragInStep(dragParentIndex, dragIndex, dragIsParallel, parentIndex);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });
  return (
    <div ref={drop}>
      {isOver ? (
        <PrimaryButton
          className="w-full border-dashed border-white border-2 h-16 rounded-md mb-12 text-lg font-semibold"
          title="+ Add New Step"
          noMaxWidth
          disabled={disabled}
        />
      ) : (
        <TertiaryButton
          className="w-full border-dashed border-gray-150 border-2 h-16 rounded-md mb-12 text-lg font-semibold"
          title="+ Add New Step"
          onClick={() => handleAddNewStep(parentIndex)}
          noMaxWidth
          disabled={disabled}
        />
      )}
    </div>
  );
};

WorkflowAddStep.propTypes = {
  handleAddNewStep: PropTypes.func,
  parentIndex: PropTypes.number,
  handleDragInStep: PropTypes.func,
  disabled: PropTypes.bool,
};

WorkflowAddStep.defaultProps = {
  handleAddNewStep: undefined,
  parentIndex: undefined,
  handleDragInStep: undefined,
  disabled: true,
};
export default WorkflowAddStep;

import { useReducer } from "react";
import { cloneDeep, findIndex } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { sanitizeLabel } from "../helpers/Tag";

const defaultState = [];

const reducer = (tags, action) => {
  switch (action.type) {
    case "reset":
      return (
        cloneDeep(
          action?.tags?.map((tag) => ({
            ...tag,
            currentLabel: tag?.label,
          }))
        ) ?? defaultState
      );
    case "removeTag": {
      return tags.map((tag) => {
        if (tag.id === action.tagId) {
          return {
            ...tag,
            wasRemoved: true,
          };
        }
        return tag;
      });
    }
    case "selectTag": {
      return tags.map((tag, idx) => {
        if (idx === action.index) {
          return {
            ...tag,
            selected: !tag.selected,
            wasUpdated: true,
          };
        }
        return tag;
      });
    }
    case "changeTagLabel": {
      return tags.map((tag) => {
        if (tag.id === action.tagId) {
          return {
            ...tag,
            currentLabel: sanitizeLabel(action.value),
            wasUpdated: true,
          };
        }
        return tag;
      });
    }
    case "addTag": {
      const newTagLabel = sanitizeLabel(action.value);
      const index = findIndex(tags, (tag) => tag.label === newTagLabel);
      if (index !== -1) {
        tags.push(tags.splice(index, 1)[0]);
        return tags;
      }
      return [
        ...tags,
        {
          id: uuidv4(),
          label: sanitizeLabel(action.value),
          currentLabel: sanitizeLabel(action.value),
          count: 0,
          wasAdded: true,
        },
      ];
    }
    default:
      return tags;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};

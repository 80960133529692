/* eslint-disable import/prefer-default-export */
import cntl from "cntl";
import React from "react";

const svgCN = (className) => cntl`
cursor-pointer
${className}
`;

export const LeftChevron = ({ height = 16, onClick, className }) => {
  return (
    <svg
      height={height}
      onClick={onClick}
      className={svgCN(className)}
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.64818 15.297L0.00012207 7.64805L7.64818 0L8.93346 1.28528L2.57068 7.64805L8.93346 14.0108L7.64818 15.297Z"
        fill="#585858"
      />
    </svg>
  );
};

export const RightChevron = ({ height = 16, onClick }) => {
  return (
    <svg
      height={height}
      onClick={onClick}
      viewBox="0 0 9 16"
      fill="none"
      className="cursor-pointer"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.35207 15.297L9.00012 7.64805L1.35207 0L0.0667887 1.28528L6.42956 7.64805L0.0667887 14.0108L1.35207 15.297Z"
        fill="#585858"
      />
    </svg>
  );
};

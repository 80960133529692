import { useEffect, useState } from "react";
import { useAppState } from "../../../state/appState";
import useEsModalData from "../EsModal/useEsModalData";
import useReportPatch from "../../../hooks/useReportPatch";
import useReportDelete from "../../../hooks/useReportDelete";
import { convertAssetsCategoriesToDropdownShape } from "../../../helpers/Report/reportTemplatesFormatters";
import { capitalized } from "../../../helpers/Formatters";
import {
  sanitizeAssets,
  sanitizeIncludeInformation,
  sanitizeSubmittals,
} from "../../../helpers/Report/sanitizeReport";
import {
  CONFIRMATION_POPUP,
  TOGGLE_POSITIONED_POPUP,
} from "../../../constants";

const useReportTemplateModalData = ({ modalData }) => {
  const [, appStateDispatch] = useAppState();
  const { closeModal } = useEsModalData(null, modalData);

  const { template } = modalData;

  const modalTitle = `${template.title} - ${capitalized(template.sourceType)}`;
  const associationType = template.sourceType;

  const [templateForm, setTemplateForm] = useState(template);

  // State to hold changes in "Include Information" section
  const [includeInformationData, setIncludeInformationData] = useState();
  // State to hold changes in "Assets Categories Dropdown"
  const [assetsCategoriesData, setAssetsCategoriesData] = useState([]);

  const { mutate: patchTemplate, isLoading } = useReportPatch();
  const { mutate: deleteTemplate } = useReportDelete();

  // Disable form if required fields are not populated or if mutation indicators are `true` (when updating template)
  const disableForm = !templateForm.title.trim() || isLoading;

  const handleChangeForm = (key, value) => {
    setTemplateForm((prev) => ({ ...prev, [key]: value }));
  };

  /**
   * Apply template selected to the `Include Information` section
   */
  useEffect(() => {
    if (template) {
      const { sourceType, configuration } = template;

      setIncludeInformationData((prev) => {
        if (!sourceType) return prev;

        return { ...prev, ...configuration[sourceType] };
      });

      // Transform assets from template to dropdown shape
      setAssetsCategoriesData(() =>
        convertAssetsCategoriesToDropdownShape(
          configuration?.[sourceType]?.assets ?? { options: [] }
        )
      );
    }
  }, [template]);

  /**
   * Patch report template
   */
  const handleUpdateTemplate = () => {
    // 1. `includeInformationData` needs to be sanitized before sending it to the backend.
    // Backend requires that the `configuration` property should not contain empty `options` arrays.
    const updatedIncludeInformation = sanitizeIncludeInformation(
      includeInformationData
    );

    // 2. Start creating the full report object with the `sourceType` map.
    let finalTemplateForm = {
      ...templateForm,
      configuration: { [associationType]: { ...updatedIncludeInformation } },
    };

    // 3. Check if the user has selected `property` or `project` association,
    // and if assets is checked, it needs to include an `assets` property in the configuration object,
    // following the data structure required by the backend.
    if (
      associationType !== "asset" &&
      updatedIncludeInformation?.assets?.selected &&
      assetsCategoriesData.length
    ) {
      finalTemplateForm = sanitizeAssets(
        associationType,
        assetsCategoriesData,
        finalTemplateForm
      );
    }

    // 4. If `submittals` is selected, we need to rename the `options` array to `status`
    if (associationType === "project") {
      if (updatedIncludeInformation?.submittals?.options?.length) {
        finalTemplateForm = sanitizeSubmittals(
          associationType,
          updatedIncludeInformation,
          finalTemplateForm
        );
      }
    }

    // 5. Patch updated `templateForm`
    patchTemplate(
      {
        id: template.id,
        report: template,
        reportUpdated: finalTemplateForm,
      },
      { onSuccess: closeModal }
    );
  };

  /**
   * Delete template
   */
  const handleDeleteTemplate = () => {
    deleteTemplate([template.id]);
    closeModal();
  };

  /**
   * Show modal delete confirmation
   */
  const handleDeleteButton = () => {
    appStateDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      position: {
        centered: true,
      },
      popupData: {
        item: {
          title: "Delete Template",
          prompt:
            "Are you sure you want to delete this template, this action cannot be undone?",
          confirm: "Yes",
          cancel: "No",
          onConfirm: handleDeleteTemplate,
          onCancel: closeModal,
        },
      },
      popupType: CONFIRMATION_POPUP,
    });
  };

  return {
    modalTitle,
    template,
    templateForm,
    includeInformationData,
    assetsCategoriesData,
    associationType,
    disableForm,
    handleChangeForm,
    handleUpdateTemplate,
    handleDeleteButton,
    setIncludeInformationData,
    setAssetsCategoriesData,
  };
};

export default useReportTemplateModalData;

import { RRule } from "rrule";

const generateRruleFromSelection = (selection) => {
  if (selection.value === "daily") {
    const dailyRrule = new RRule({
      freq: RRule.DAILY,
      dtstart: new Date(),
    });

    const rruleArr = dailyRrule.toString().split("\n");
    return rruleArr[1];
  }

  if (selection.value === "weekly") {
    const weeklyRrule = new RRule({
      freq: RRule.WEEKLY,
      dtstart: new Date(),
    });

    const rruleArr = weeklyRrule.toString().split("\n");
    return rruleArr[1];
  }

  if (selection.value === "monthly") {
    const monthlyRrule = new RRule({
      freq: RRule.MONTHLY,
      dtstart: new Date(),
    });

    const rruleArr = monthlyRrule.toString().split("\n");
    return rruleArr[1];
  }

  if (selection.value === "yearly") {
    const yearlyRrule = new RRule({
      freq: RRule.YEARLY,
      dtstart: new Date(),
    });

    const rruleArr = yearlyRrule.toString().split("\n");
    return rruleArr[1];
  }

  if (selection.value === "does not repeat") {
    return "";
  }

  return selection;
};

export default generateRruleFromSelection;

import { useQueryClient } from "react-query";
import { useHistory } from "react-router";

const useQueryNavigation = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const push = ({ location, queryKey } = {}) => {
    if (!location) return;

    if (queryKey) {
      // Invalidate exact queries and active/inactive
      queryClient.invalidateQueries({
        queryKey,
        exact: true,
        refetchInactive: true,
      });
    }

    history.push(location);
  };

  return { push };
};

export default useQueryNavigation;

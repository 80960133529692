import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { downloadMedia } from "../../../helpers/File";

import attachmentIcon from "../../assets/images/attachmentIcon.svg";
import downloadIcon from "../../assets/images/downloadIcon.svg";

const AttachmentViewAndDownload = ({ filesArray, wrapperClassName }) => {
  const handleFileDownload = async (f) => {
    await downloadMedia(f);
  };
  return (
    <div className={wrapperClassName}>
      <p className="flex justify-between text-gray-300 mb-2">
        {filesArray?.length > 1 && (
          <div
            role="presentation"
            className="flex justify-between text-brandGreen cursor-pointer font-normal ml-6 mt-2"
            onClick={() =>
              handleFileDownload(filesArray.map((f) => f?.reference))
            }
          >
            Download All
            <img src={downloadIcon} className="ml-6" alt="download all" />
          </div>
        )}
      </p>
      <table className="min-w-full overflow-x-auto text-left text-sm font-light">
        <thead className="border-b text-gray-200">
          <tr>
            <th scope="col" className="font-normal px-6 py-4">
              #
            </th>
            <th scope="col" className="font-normal px-6 py-4">
              Name
            </th>
            <th scope="col" className="font-normal px-6 py-4">
              Last Modified
            </th>
            <th scope="col" className="font-normal px-6 py-4">
              Download
            </th>
          </tr>
        </thead>
        <tbody>
          {filesArray?.length > 0 &&
            filesArray
              ?.filter((f) => f?.name)
              ?.map((f, idx) => (
                <tr key={f?.id} className="border-b dark:border-neutral-500">
                  <td className="whitespace-nowrap px-6 py-4">{idx + 1}</td>
                  <td className="flex whitespace-nowrap px-6 py-4">
                    <img
                      src={attachmentIcon}
                      alt="document attachment"
                      className="inline w-3.5 h-3.5 mr-2"
                    />
                    <a
                      href={f?.contentsUrl}
                      target="_blank"
                      rel="noreferrer"
                      className="text-brandGreen hover:underline"
                    >
                      {f?.name}
                    </a>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    {moment(f?.metadata?.lastUpdated)?.format("lll")}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    <img
                      role="presentation"
                      className="ml-4 cursor-pointer"
                      src={downloadIcon}
                      alt="download file"
                      onClick={() => handleFileDownload([f?.reference])}
                    />
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
};

AttachmentViewAndDownload.propTypes = {
  filesArray: PropTypes.arrayOf(PropTypes.shape({})),
  wrapperClassName: PropTypes.string,
};

AttachmentViewAndDownload.defaultProps = {
  filesArray: [],
  wrapperClassName: "",
};

export default AttachmentViewAndDownload;

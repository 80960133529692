/**
 * Receive all tasks and SOP tasks
 * @param {Object} currentTask - Current task to validate
 * @param {Object} usedSopDict - SOP dictionary
 * @returns {Array} - Array of steps and SOP steps
 */
const stepsForTask = (currentTask, usedSopDict) => {
  if (!currentTask?.steps?.length) {
    return [];
  }

  const stepArray = [];
  currentTask.steps.forEach((item) => {
    if (item?.sop && usedSopDict?.[item.sop]) {
      return stepArray.push({
        ...usedSopDict?.[item.sop],
        stepData: item,
      });
    }
    return stepArray.push({ stepData: item });
  });
  return stepArray;
};

export default stepsForTask;

import moment from "moment";

const formatDateControl = (startDate, endDate) => {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  const formattedStartDate = startMoment.format("D");
  const formattedEndDate = endMoment.format("D MMM YYYY");
  const formattedDates = `${formattedStartDate} - ${formattedEndDate}`;

  return formattedDates;
};

export default formatDateControl;

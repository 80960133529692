import { cloneDeep } from "lodash";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useAppState } from "../../../../state/appState";

import { filePaginatedKeys } from "../../../../config/reactQuery/queryKeyFactory";
import {
  EDIT_RECURRENCE_POPUP,
  MOMENT_UTC_ES_FORMAT,
  TOGGLE_POSITIONED_POPUP,
} from "../../../../constants";
import hasRecurrenceFieldChanged from "../../../../helpers/Calendar/hasRecurrenceFieldChanged";
import checkIfUTC from "../../../../helpers/Date/checkIfUTC";
import formatIsoToUtc from "../../../../helpers/Date/formatIsoToUtc";
import isBegOfDay from "../../../../helpers/Date/isBegOfDay";
import isEndOfDay from "../../../../helpers/Date/isEndOfDay";
import { uploadFileWithData } from "../../../../helpers/File";
import formatUploadsArray from "../../../../helpers/Format/formatUploadsArray";
import { useEditCalendar } from "../../../../hooks/calendar";
import { toastError, toastMessage } from "../../Toast/Toast";

const useEditEventModalData = ({ modalData }) => {
  const queryClient = useQueryClient();
  const [, appStateDispatch] = useAppState();
  const { editInCalendar } = useEditCalendar();
  const { item, formData } = modalData;

  const defaultState = cloneDeep(item || formData);

  if (defaultState.wrappedDayBeginning) {
    defaultState.startDate = defaultState.wrappedDayBeginning;
  }

  const [formState, setFormState] = useState(defaultState);

  const initialAllDayCheck =
    formState?.allDay ||
    (isBegOfDay(formState.startDate) && isEndOfDay(formState.endDate));

  const [isAllDay, setIsAllDay] = useState(initialAllDayCheck);

  const updateFormState = (value) => {
    setFormState({ ...formState, ...value });
  };

  const toggleAllDay = () => {
    setIsAllDay(!isAllDay);
    updateFormState({
      isAllDay: !isAllDay,
      allDay: !isAllDay,
    });
  };

  const allDayData = { isAllDay, toggleAllDay };

  const formIsValid = useMemo(() => {
    const { association, name } = formState;
    return association !== "" && name !== "";
  }, [formState]);

  const onAddAttachment = async (photo, data = {}, progressCallback) => {
    try {
      const fileResource = await uploadFileWithData(
        photo,
        data,
        progressCallback,
        undefined,
        true,
        undefined,
        true
      );
      return fileResource;
    } catch (error) {
      return error;
    }
  };

  const onUpload = useCallback(async (files, progressCallback) => {
    const handleProgressCallback = (loaded, total, filename) => {
      progressCallback(loaded, total, filename);
    };

    try {
      const result = await Promise.all(
        files.map(async ({ name, docType, isFavorited, original }) => {
          const data = {
            name,
            docType,
            isFavorited,
            contentType: original.type,
            size: original.size,
          };
          const resource = await onAddAttachment(
            original,
            data,
            (loaded, total) => handleProgressCallback(loaded, total, name)
          );

          return { ...data, ...resource };
        })
      );

      return result;
    } catch (error) {
      console.error(error);
      return [];
    }
  }, []);

  const handleFilesUploaded = useCallback(async () => {
    const filteredFiles = formState?.files?.reduce(
      (list, file) => {
        if (!file?.isEditing && file?.type) {
          list.latest.push(file);
          return list;
        }
        list.previous.push(file);
        return list;
      },
      { latest: [], previous: [] }
    );

    try {
      const res = await onUpload(filteredFiles.latest, () => {});

      // update files in overview
      queryClient.invalidateQueries(filePaginatedKeys.allFiles);

      return [...filteredFiles.previous, ...res];
    } catch (error) {
      return [];
    }
  }, [formState?.files, onUpload, queryClient]);

  const handleSaveSubmit = async () => {
    // Create a deep copy of the form state to avoid modifying the original data
    const editedEventObject = cloneDeep(formState);

    const hasRecurrenceChanged = hasRecurrenceFieldChanged(
      defaultState,
      editedEventObject
    );

    if (defaultState?.recurrence) {
      appStateDispatch({
        type: TOGGLE_POSITIONED_POPUP,
        position: {
          centered: true,
        },
        popupData: {
          item,
          form: editedEventObject,
          popupWidth: 444,
          disableEditThis: hasRecurrenceChanged,
        },
        popupType: EDIT_RECURRENCE_POPUP,
      });
      return;
    }

    // Initialize variables for formatted start and end dates
    let formattedStartDate = editedEventObject.startDate;
    let formattedEndDate = editedEventObject.endDate;
    // Check if the start and end dates are in UTC format
    const isStarDateUTC = checkIfUTC(editedEventObject.startDate);
    const isEndDateUTC = checkIfUTC(editedEventObject.endDate);
    // If the start or end date is not in UTC format, convert and format it to UTC
    if (!isStarDateUTC) {
      formattedStartDate = formatIsoToUtc(editedEventObject.startDate);
    }

    if (!isEndDateUTC) {
      formattedEndDate = formatIsoToUtc(editedEventObject.endDate);
    }
    // If the event is an all-day event, adjust start and end times to the beginning and end of the day in UTC
    if (editedEventObject?.isAllDay === true) {
      editedEventObject.allDay = true;
      formattedStartDate = moment(formattedStartDate)
        .startOf("day")
        .utc()
        .format(MOMENT_UTC_ES_FORMAT);

      formattedEndDate = moment(formattedEndDate)
        .endOf("day")
        .utc()
        .format(MOMENT_UTC_ES_FORMAT);
    } else {
      editedEventObject.allDay = false;
    }

    // Update the start and end dates in the edited event object
    editedEventObject.startDate = formattedStartDate;
    editedEventObject.endDate = formattedEndDate;

    // Format tags into an array of values
    const formattedTags = formState.tags.map((tag) => tag.value);
    editedEventObject.tags = formattedTags;

    // Handle file uploads if there are files attached to the event
    if (editedEventObject.files.length > 0) {
      const uploads = await handleFilesUploaded();
      // Format and update the files array with the uploaded files' references and categories
      editedEventObject.files = formatUploadsArray(uploads);
    }
    try {
      await editInCalendar({
        args: { originalItem: item, editedItem: editedEventObject },
        operation: "$non-recurring",
      });

      toastMessage("Event edited successfully");
    } catch (error) {
      toastError("Error editing event");
      console.error("Error Editing Event -> useEditEventModalData.js", error);
    }
  };

  return {
    formState,
    formIsValid,
    allDayData,
    updateFormState,
    handleSaveSubmit,
  };
};

export default useEditEventModalData;

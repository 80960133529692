const getTaskListReqParams = () => {
  const { pathname } = window.location;

  const requestAddress = "overview";

  if (pathname === "/tasks") {
    return requestAddress;
  }

  const associatedItem = pathname?.split("/")[1];
  const associatedItemId = pathname?.split("/")[2];

  const supportingAssociation = pathname?.split("/")[3];
  const supportingAssociationId = pathname?.split("/")[4];

  if (supportingAssociation === "space") {
    return `${requestAddress}?${supportingAssociation}=${supportingAssociationId}`;
  }

  if (associatedItem === "projects") {
    return `${requestAddress}?association=Project/${associatedItemId}`;
  }

  if (associatedItem === "properties") {
    return `${requestAddress}?association=Property/${associatedItemId}`;
  }

  if (associatedItem === "assets") {
    return `${requestAddress}?asset=Asset/${associatedItemId}`;
  }

  return requestAddress;
};

export default getTaskListReqParams;

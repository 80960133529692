import { UsertenantAPI, ClientAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { tenantKeys } from "../config/reactQuery/queryKeyFactory";
import { toastError } from "../stories/Components/Toast/Toast";
import useCurrentUser from "./useCurrentUser";

const getTenants = async (email) => {
  const { data } = await UsertenantAPI.get({
    params: { email },
  });

  const tenants = data?.tenants?.map((tenant) => ({
    label: tenant.displayName,
    value: tenant["x-es-tenantid"],
  }));
  return tenants;
};
/**
 * Query hook to fetch Tags
 * @returns query hook
 */
// eslint-disable-next-line import/prefer-default-export
export const useGetTenants = () => {
  const { data: currentUser } = useCurrentUser();

  return useAuthenticatedQuery({
    enabled: !!currentUser?.email,
    queryKey: tenantKeys.tenants,
    queryFn: () => getTenants(currentUser?.email),
    onError: (error) => {
      console.error("Error fetching User tenants", error);
    },
  });
};

const patchTenantName = async ({ id, updatedName }) => {
  const { data } = await ClientAPI.postWOP("$changename", {
    tenant: id,
    name: updatedName,
  });

  return data;
};

/**
 * Mutation hook to remove single/multiple Tags
 * @returns mutation hook
 */
export const useUpdateTenantName = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchTenantName,
    onSuccess: () => {
      queryClient.invalidateQueries(tenantKeys.tenants);
    },
    onError: (error) => {
      console.error("Error updating tenant name", error);
      toastError(`Error updating tenant name. Try again later`);
    },
  });
};

import { useCallback } from "react";
import { useUsers } from "./useUsers.new";
import { getUserFirstAndLast } from "../helpers/Utilities";
import { usePropertiesOverview } from "./properties";
import { useProjectsOverview } from "./projects";
import { useAssetsOverview } from "./assets";

const useAssociationsHelper = () => {
  const { assetsDict } = useAssetsOverview();
  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  const { data: users } = useUsers();

  const userDict = users?.userDict;

  /**
   * Return full parent association object from dictionaties
   */
  const getParentResource = useCallback(
    (associationReference) => {
      return (
        propertiesDict[associationReference] ||
        projectDict[associationReference]
      );
    },
    [propertiesDict, projectDict]
  );

  /**
   * Helper function that that returns the full parent resource object for a given child resource
   * * @param {string} childAssociationReference - Child Association reference. Ex: Asset/1111-2222-3333-4444
   * @returns {Object} - Full object representing the parent association
   */
  const getParentAssociation = useCallback(
    (childAssociationReference) => {
      const resourceType = childAssociationReference?.split("/")[0];

      switch (resourceType) {
        case "Asset": {
          const asset = assetsDict[childAssociationReference];
          const parentAssociationReference = asset?.property || asset?.project;
          return getParentResource(parentAssociationReference);
        }
        default: {
          return undefined;
        }
      }
    },
    [assetsDict, getParentResource]
  );

  /**
   * Returns the association object from dictionaries based on given association reference
   * @param {string} associationReference - Association reference. Ex: Property/1111-2222-3333-5555
   * @returns {Object | undefined} - Single association object if found
   */
  const getAssociationInfo = useCallback(
    (associationReference) => {
      const resourceType = associationReference?.split("/")[0];

      switch (resourceType) {
        case "Property": {
          return propertiesDict[associationReference];
        }
        case "Project": {
          return projectDict[associationReference];
        }
        case "Asset": {
          return assetsDict[associationReference];
        }
        default: {
          return undefined;
        }
      }
    },
    [projectDict, propertiesDict, assetsDict]
  );

  /**
   * Return a list members of members for a given association reference
   * @param {string} associationReference - Association reference. Ex: Property/1111-2222-3333-5555
   * @returns {Object[]} - Array of members
   */
  const getAssociationMembers = useCallback(
    (associationReference) => {
      if (!associationReference) return [];

      let associationObject = {};

      // If `associationReference` is an Asset, we need to get the parent association (Project || Property)
      if (associationReference.startsWith("Asset")) {
        associationObject = getParentAssociation(associationReference);
      } else {
        associationObject = getAssociationInfo(associationReference);
      }

      const membersReferences = associationObject?.members || [];

      return membersReferences.reduce((acc, member) => {
        const user = userDict?.[member?.user];
        if (user) {
          const fullName = getUserFirstAndLast(user);
          acc.push({ ...user, fullName });
        }
        return acc;
      }, []);
    },
    [userDict, getParentAssociation, getAssociationInfo]
  );

  return {
    getParentAssociation,
    getAssociationInfo,
    getAssociationMembers,
  };
};

export default useAssociationsHelper;

import { useEffect, useState } from "react";

/**
 * Return only media files
 */
const getOnlyMediaFiles = (files) => {
  return files.reduce((acc, current) => {
    if (current.category !== "Documents") {
      acc.push(current);
    }

    return acc;
  }, []);
};

const useMediaViewData = ({
  requestForm,
  filesState,
  handleFilesToAdd,
  handleFilesToRemove,
}) => {
  const [mediaFiles, setMediaFiles] = useState([]);

  // This effect set only Media files on state
  useEffect(() => {
    if (!requestForm?.files) return;

    const onlyMedia = getOnlyMediaFiles(requestForm?.files) ?? [];

    // Append possible new added files to be rendered in the list
    setMediaFiles([...onlyMedia, ...filesState.mediaFilesToAdd]);
  }, [requestForm?.files, filesState.mediaFilesToAdd]);

  const addNewFiles = (files) => {
    handleFilesToAdd(files, "media");
  };

  const removeFile = (file) => {
    handleFilesToRemove(file, "media");
  };

  return {
    mediaFiles,
    addNewFiles,
    removeFile,
  };
};

export default useMediaViewData;

import React, { useState } from "react";

import ExpenseTable from "../../../stories/Components/ExpenseTable/ExpenseTable";

const PropertyExpenses = ({ property, isTabView, setButtonActions }) => {
  const [createExpenseModal, setCreateExpenseModal] = useState(false);
  return (
    <ExpenseTable
      association={{
        reference: property?.reference,
        name: property?.title,
        resource: property?.resource,
      }}
      hideSiteHeaderTitle
      setButtonActions={setButtonActions}
      isTabView={isTabView}
      createExpenseModal={createExpenseModal}
      setCreateExpenseModal={setCreateExpenseModal}
      hideSwitchView
    />
  );
};

export default PropertyExpenses;

import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import cntl from "cntl";

import PrimaryButton from "../Buttons/PrimaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import { downloadMedia } from "../../../helpers/File";
import BaseButton from "../Buttons/BaseButton";

const modalStyles = {
  content: {
    inset: 0,
    maxWidth: "100%",
    maxHeight: "100vh",
    padding: "20px 52px",
    backgroundColor: "white",
    borderColor: "#1DC29D",
    borderWidth: "8px",
    overflow: "auto",
  },
  overlay: {
    zIndex: "50",
  },
};

const AttachItemModal = ({
  content,
  contentReference,
  primaryText,
  buttonClass,
  onRequestModalClose,
  onPrimaryClick,
  isOpen,
  disableClose,
  disablePrimary,
  showBackButton,
  tableMiniMapContainerId,
}) => {
  const floatingButtonCN = cntl`
  flex
  justify-between
  items-center
  absolute
  bottom-24
  right-24
  h-16
  p-4
  border-2
  border-gray-100
  rounded-lg
  bg-white
  ${buttonClass}
`;

  const floatingBackButtonCN = cntl`
  flex
  absolute
  top-2
  left-0
`;

  const handleFileDownload = async () => {
    await downloadMedia([contentReference]);
  };

  return (
    <ReactModal
      style={modalStyles}
      isOpen={isOpen}
      onRequestClose={onRequestModalClose}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
    >
      <div className="flex flex-col w-full h-full relative z-30">
        <div
          className="flex-grow overflow-y-auto p-5"
          id={tableMiniMapContainerId}
        >
          {content}
        </div>
        {showBackButton && (
          <div className={floatingBackButtonCN}>
            <BaseButton
              className="text-brandGreen"
              onClick={onRequestModalClose}
              title="< Go Back"
            />
          </div>
        )}
        {(contentReference || onPrimaryClick || !disableClose) && (
          <div className={floatingButtonCN}>
            {!disableClose && (
              <TertiaryButton
                className={!(onPrimaryClick || contentReference) ? "" : "mr-4"}
                title="Cancel"
                onClick={onRequestModalClose}
              />
            )}
            {contentReference && (
              <PrimaryButton
                className=""
                title="Download"
                onClick={handleFileDownload}
              />
            )}
            {onPrimaryClick && (
              <PrimaryButton
                className=""
                title={primaryText ?? "Add"}
                onClick={onPrimaryClick}
                disabled={disablePrimary}
              />
            )}
          </div>
        )}
      </div>
    </ReactModal>
  );
};

AttachItemModal.propTypes = {
  /**
   * content to render in the modal
   */
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.object,
  /**
   * content reference for file download
   */
  contentReference: PropTypes.string,
  /**
   * text for primary button
   */
  primaryText: PropTypes.string,
  /**
   * className to pass to the button container
   */
  buttonClass: PropTypes.string,
  /**
   * run on cancel button click
   */
  onRequestModalClose: PropTypes.func,
  /**
   * run on add button click
   */
  onPrimaryClick: PropTypes.func,
  /**
   * modal open state
   */
  isOpen: PropTypes.bool,
  /**
   * hide close button - must handle close functionality otherwise
   */
  disableClose: PropTypes.bool,
  disablePrimary: PropTypes.bool,
  showBackButton: PropTypes.bool,
  /**
   * used by Table component for mini map to scroll
   */
  tableMiniMapContainerId: PropTypes.string,
};

AttachItemModal.defaultProps = {
  content: null,
  contentReference: null,
  primaryText: null,
  buttonClass: null,
  onRequestModalClose: undefined,
  onPrimaryClick: undefined,
  isOpen: false,
  disableClose: false,
  disablePrimary: false,
  showBackButton: false,
  tableMiniMapContainerId: undefined,
};

export default AttachItemModal;

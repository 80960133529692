/* eslint-disable no-param-reassign */
import { useCallback, useEffect, useState } from "react";
import {
  UserAPI,
  HistoryAPI,
  WorkflowAPI,
} from "@griffingroupglobal/eslib-api";
import { useAppState } from "../state/appState";

function decorateDocumentsWithUserData(documents, users) {
  for (let i = 0; i < documents.length; i += 1) {
    const doc = { ...documents[i] };
    const creator = doc.createdBy ?? doc.user;
    const modifier = doc.modifiedBy;

    for (let j = 0; j < users.length; j += 1) {
      const user = { ...users[j] };

      if (user.reference === creator) {
        doc.creator = user;
      }

      if (user.reference === modifier) {
        doc.modifier = user;
      }
    }

    // eslint-disable-next-line no-param-reassign
    documents[i] = doc;
  }

  return documents;
}

const decorateDocumentsWithWorkflowData = async (documents) => {
  const formattedDocuments = await Promise.all(
    documents.map(async (doc) => {
      const wf = doc?.context?.workflow?.reference;
      if (wf) {
        try {
          const { data } = await WorkflowAPI.getById(wf.split("/")[1]);
          doc.context.workflow.name = data.name;
          doc.context.workflow.id = data.id;
        } catch (err) {
          console.warn("WF does not exist", err);
        }
      }
      return doc;
    })
  );

  return formattedDocuments;
};

export default function useDocumentHistory(documentId) {
  const [{ users }] = useAppState();
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const reload = useCallback(async () => {
    try {
      setLoading(true);
      let userResult = users;
      let tempHistory = [];
      const queryString = `?association=Document/${documentId}`;

      const { data } = await HistoryAPI.getWOP(queryString);

      if (!userResult?.length) {
        const { data: usr } = await UserAPI.get();
        userResult = usr.entries.map((item) => item.resource);
      }

      tempHistory = decorateDocumentsWithUserData(
        data.entries.map((item) => item.resource),
        userResult
      );

      tempHistory = await decorateDocumentsWithWorkflowData(tempHistory);

      setHistory(tempHistory);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }, [documentId, users]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { history, loading, reload };
}

import moment from "moment";
import { useReducer } from "react";
import { v4 as uuidv4 } from "uuid";

const defaultState = {
  customName: "",
  description: "",
  association: "",
  startDate: {
    projected: moment().format(),
  },
  distroList: [],
  private: false,
  requestWorkflow: [{ steps: [] }],
  links: [],
  selectedWorkflow: "",
};

const reducer = (rfi, action) => {
  switch (action.type) {
    case "reset":
      return action.associated
        ? {
            ...defaultState,
            association: action.associated,
          }
        : { ...defaultState };
    case "association":
      return { ...rfi, association: action.value };
    case "resourceName":
      return { ...rfi, resourceName: action.value };
    case "customName":
      return { ...rfi, customName: action.value };
    case "description":
      return { ...rfi, description: action.value };
    case "projectedStartDate":
      return {
        ...rfi,
        startDate: {
          ...rfi?.startDate,
          projected: action.value,
        },
      };
    case "private":
      return {
        ...rfi,
        private: action.value,
      };
    case "addLink":
      return {
        ...rfi,
        links: [...(rfi?.links ?? []), { id: uuidv4(), name: "", url: "" }],
      };
    case "deleteLink":
      return {
        ...rfi,
        links: rfi?.links?.filter((link) => link.id !== action.linkId) ?? [],
      };
    case "changeLink":
      return {
        ...rfi,
        links: rfi?.links?.map((link) => {
          if (link.id === action.link?.id) {
            return action.link;
          }
          return link;
        }),
      };
    case "requestWorkflow":
      return {
        ...rfi,
        requestWorkflow: action.value,
      };
    case "addRequestWorkflow":
      return {
        ...rfi,
        requestWorkflow: [...rfi.requestWorkflow, ...action.value],
      };

    case "updateDistroList":
      return {
        ...rfi,
        distroList: action.value,
      };
    default:
      return defaultState;
  }
};

export default () => {
  return useReducer(reducer, defaultState);
};

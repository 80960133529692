// Framework Tools
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

// Libraries
import moment from "moment";
import { capitalize } from "lodash";

// Providers
import { useAppState } from "../../../../state/appState";
import { getInitials } from "../../../../helpers/Chat";
import InputModal from "../../InputModal/InputModal";
import { getFullName } from "../../../../helpers/Formatters";
import Avatar from "../../Message/Avatar";
import useSubmittalById from "../../../../hooks/useSubmittalById";

export default function RequestWorkflowStepCardDueOnCell({
  dueDate,
  users,
  handleActionButton,
  stepAdvanced,
  commentModalOpen,
  setCommentModalOpen,
  actionValues,
  setActionValues,
  submittalActionReview,
  submittalActionSubmit,
  setLoading,
  isSubmittal,
  reload,
  isOpen,
  requestData,
  sidebarOpen,
  disabled,
  activeStep,
}) {
  // Hooks
  const [{ userDict }] = useAppState();
  const { handleSendReminder } = useSubmittalById(requestData?.id);

  // State controls
  const [userObjects, setUserObjects] = useState([]);

  // State updaters
  useEffect(() => {
    const userArray = users?.map((user) => userDict?.[user?.reference]);
    setUserObjects(userArray);
  }, [userDict, users]);

  const handleReviewApproveAction = async () => {
    try {
      setLoading(true);
      await submittalActionReview(
        actionValues?.requestId,
        actionValues?.stepId,
        actionValues?.status,
        actionValues?.action,
        actionValues?.comment
      );
    } catch (err) {
      console.error(err);
    } finally {
      reload();
      setLoading(false);
    }
  };

  const handleSubmitAction = async () => {
    if (isSubmittal) {
      try {
        setLoading(true);
        await submittalActionSubmit(
          actionValues?.requestId,
          actionValues?.stepId
        );
      } catch (error) {
        console.error(error);
      } finally {
        reload();
        setLoading(false);
      }
    }
  };

  const handleAction = async (actionStatus) => {
    if (actionStatus === "submit") {
      handleSubmitAction();
    } else if (actionStatus === "sendreminder") {
      try {
        users.forEach(async (user) => {
          const userId = user.reference.split("/")[1];
          await handleSendReminder(actionValues.comment, userId);
        });
        toast.success("Reminder was sent successfully!");
      } catch (err) {
        console.error(err);
        toast.error("Failed to send reminder, please try again");
      } finally {
        setCommentModalOpen(false);
        setActionValues({});
      }
    } else {
      handleReviewApproveAction();
    }
  };

  return (
    <div
      className={
        sidebarOpen
          ? "flex flex-col"
          : "flex justify-between items-center w-full"
      }
    >
      <div className="flex flex-col items-start m-4">
        <p className="text-xs font-semibold">
          Due on: {moment(dueDate?.projected).format("MMMM DD, YYYY")}
        </p>

        {users?.map((user) => {
          const member = userObjects.find(
            (obj) => obj?.reference === user.reference
          );

          return (
            <div className="flex items-center" key={user.reference}>
              <p className="text-xs font-semibold">{capitalize(user?.type)}:</p>
              <Avatar
                className="text-xxs mx-4"
                name={getInitials(member?.name)}
                isOnline={member?.isOnline}
                id={member?.id}
                avatar={member?.avatar}
              />
              <p className="text-xs font-semibold">
                {getFullName(member?.name)} ({member?.companyName})
              </p>
            </div>
          );
        })}
        {stepAdvanced?.advanced && (
          <p className="text-xs font-semibold">
            Completed on: {moment(stepAdvanced?.date).format("MMMM DD, YYYY")}{" "}
          </p>
        )}
      </div>
      {activeStep && (
        <div className="py-4">
          <div
            className={
              sidebarOpen
                ? "relative flex flex-col ml-4"
                : "relative flex flex-col"
            }
          >
            {!isOpen && !disabled && handleActionButton()}

            {commentModalOpen && (
              <InputModal
                style={
                  sidebarOpen
                    ? {
                        width: "20rem",
                        top: "3rem",
                      }
                    : {
                        top: "3rem",
                        width: "20rem",
                        position: "absolute",
                        right: "0.15rem",
                      }
                }
                title="Add a Remark"
                buttonTitle="Submit"
                placeholder="Include a remark (optional)"
                onClose={() => setCommentModalOpen(false)}
                inputValue={actionValues.comment}
                onInputChange={(val) =>
                  setActionValues((prev) => {
                    return { ...prev, comment: val };
                  })
                }
                onSubmit={() => {
                  handleAction(actionValues?.action);
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

// prop types
RequestWorkflowStepCardDueOnCell.propTypes = {
  activeStep: PropTypes.bool,
  currentUser: PropTypes.shape({
    id: PropTypes.string,
  }),
  requestData: PropTypes.shape({
    id: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  commentModalOpen: PropTypes.bool,
  setCommentModalOpen: PropTypes.func,
  actionValues: PropTypes.shape({
    requestId: PropTypes.string,
    stepId: PropTypes.string,
    status: PropTypes.string,
    action: PropTypes.string,
    comment: PropTypes.string,
    title: PropTypes.string,
  }),
  setActionValues: PropTypes.func,
  submittalActionReview: PropTypes.func,
  submittalActionSubmit: PropTypes.func,
  setLoading: PropTypes.func,
  isSubmittal: PropTypes.bool,
  reload: PropTypes.func,
  stepAdvanced: PropTypes.shape({
    date: PropTypes.string,
    advanced: PropTypes.bool,
  }),
  handleActionButton: PropTypes.func,
  dueDate: PropTypes.string,
  users: PropTypes.arrayOf({
    user: PropTypes.shape({
      type: PropTypes.string,
      status: PropTypes.string,
    }),
  }),
  sidebarOpen: PropTypes.bool,
  disabled: PropTypes.bool,
};

// default props
RequestWorkflowStepCardDueOnCell.defaultProps = {
  activeStep: false,
  requestData: undefined,
  currentUser: undefined,
  isOpen: false,
  commentModalOpen: false,
  setCommentModalOpen: undefined,
  actionValues: undefined,
  setActionValues: undefined,
  submittalActionReview: undefined,
  submittalActionSubmit: undefined,
  setLoading: undefined,
  isSubmittal: undefined,
  reload: undefined,
  stepAdvanced: undefined,
  handleActionButton: undefined,
  dueDate: undefined,
  users: [],
  sidebarOpen: false,
  disabled: false,
};

// TODO: right now this component only render alerts with `error` severity. Add more options to ensure reusability
const useAlertSeverityData = ({ severity = "error" }) => {
  const severityClassColor = {
    error: "bg-red-100",
    success: "bg-green-100",
  };

  const backgroundClassColor = severityClassColor[severity];

  return { backgroundClassColor };
};

export default useAlertSeverityData;

import calendarKeys from "../../hooks/calendar/calendarKeys";
import createReqItems from "./createRecItems";
import getReqParams from "./getReqParms";

const addToCalendarStore = ({ queryClient, calendarData, item }) => {
  queryClient.setQueryData(
    calendarKeys.calendarList(getReqParams(calendarData)),
    (oldData) => {
      const recItems = createReqItems(item, getReqParams(calendarData).params);
      const newArr = [...recItems];

      if (oldData) newArr.push(...oldData);

      return newArr;
    }
  );
};

export default addToCalendarStore;

import React from "react";
import DragNDropFileUploader from "../../DragNDropFileUploader";
import Gallery from "../../Gallery";
import useMediaViewData from "./useMediaViewData";

const Media = ({
  isEditing,
  canAddMedia,
  stepAttachments,
  filesState,
  handleFilesToAdd,
  handleFilesToRemove,
  noTitle,
}) => {
  const { mediaFiles, addNewFiles, removeFile } = useMediaViewData({
    stepAttachments,
    filesState,
    handleFilesToAdd,
    handleFilesToRemove,
  });

  return (
    <div className={`${!noTitle && "gap-10"} grid`}>
      {!noTitle && <span className="font-semibold text-lg">Media</span>}

      <div className="flex gap-x-8">
        {canAddMedia && (
          <div>
            <DragNDropFileUploader handleFilesToAdd={addNewFiles} />
          </div>
        )}

        <Gallery
          isEditing={isEditing}
          mediaFiles={mediaFiles}
          handleFilesToRemove={removeFile}
        />
      </div>
    </div>
  );
};

export default Media;

import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import BaseButton from "../Buttons/BaseButton";
import Replies from "./Replies";
import Avatar from "../Message/Avatar";
import NewReply from "./NewReply";
import Pill from "../Pill/Pill";
import { formatNameFullFirstCap } from "../../../helpers/Formatters";

const Comments = ({
  isMaintenanceComment,
  data,
  user,
  index,
  handlePostReply,
  allowNewComment,
  canAddComment,
}) => {
  const emptyReply = {
    user,
    message: null,
  };
  const [isOpen, setIsOpen] = useState(false);
  const [showNewReply, setShowNewReply] = useState(false);
  const [replyTo, setReplyTo] = useState("");
  const handleReplyClick = (replyUser) => {
    setIsOpen(true);
    setReplyTo(replyUser);
    setShowNewReply(true);
  };
  const handleCancelReply = () => {
    setReplyTo("");
    setShowNewReply(false);
  };
  const handleSendReply = (reply) => {
    handlePostReply(reply, data.id);
    handleCancelReply();
  };
  return (
    <div className="ml-4 py-6 border-b">
      <div className="flex flex-col">
        <div className="flex flex-row mb-4">
          <Avatar
            className="mr-4 rounded-full"
            name={`${data?.userData?.name?.firstName} ${data?.userData?.name?.lastName}`}
            avatar={data?.userData?.avatar}
            id={data?.userData?.id}
            isExternalUser
          />
          <div className="flex flex-col">
            <h3 className="font-semibold text-gray-200 capitalize">
              {!isMaintenanceComment &&
                `${data?.userData?.name?.firstName} ${data?.userData?.name?.lastName}`}
              {isMaintenanceComment &&
                formatNameFullFirstCap(
                  data?.userData?.name?.lastName,
                  data?.userData?.name?.firstName
                )}
            </h3>
            <p className="text-sm text-gray-200">
              {moment(data?.metadata?.createdAt).format("lll")}
            </p>
          </div>
        </div>
        <p className="mb-4 text-gray-200">{data?.content}</p>
        {data.doc && (
          <div className="flex items-start mb-4">
            {!isMaintenanceComment && (
              <Pill
                value={data.doc}
                className="font-normal text-white truncate"
              />
            )}
            {isMaintenanceComment &&
              data.doc.map((f) => {
                return (
                  <a
                    key={f.url}
                    href={f.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {!f.contentType.includes("video/") && (
                      <div
                        className="mr-2 mb-2 w-28 h-24  border rounded-sm"
                        style={{
                          backgroundImage: `url(${f.url})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          height: "5rem",
                          display: "inline-block",
                        }}
                      />
                    )}
                    {f.contentType.includes("video/") && (
                      <div
                        className="mr-2 mb-2 max-h-24  w-28 border rounded-sm"
                        style={{
                          display: "inline-block",
                          height: "5rem",
                        }}
                      >
                        <video
                          className="max-h-24 w-28"
                          playsInline
                          autoPlay
                          muted
                          poster="Please refresh the page"
                          style={{ height: "5rem" }}
                        >
                          <source src={f.url} type={f.contentType} />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    )}
                  </a>
                );
              })}
          </div>
        )}

        <div className="ml-8">
          {isOpen && (
            <div className="mt-4 pl-4 border-l-4 border-brandGreen flex flex-col">
              {data.replies.map((info) => (
                <Replies key={info?.userData?.id} data={info} />
              ))}
              {!isMaintenanceComment && showNewReply && (
                <NewReply
                  name={emptyReply?.user?.name}
                  user={emptyReply?.user}
                  index={index}
                  replyTo={replyTo}
                  handleCancelReply={handleCancelReply}
                  handleSendReply={handleSendReply}
                />
              )}
              {isMaintenanceComment && canAddComment && showNewReply && (
                <NewReply
                  name={emptyReply?.user?.name}
                  user={emptyReply?.user}
                  index={index}
                  replyTo={replyTo}
                  handleCancelReply={handleCancelReply}
                  handleSendReply={handleSendReply}
                />
              )}
            </div>
          )}
        </div>
        <div className="flex flex-row">
          {allowNewComment && (isOpen || data?.replies?.length === 0) ? (
            <>
              {!isMaintenanceComment && (
                <BaseButton
                  title="Reply"
                  className="items-start w-22 text-base mr-2"
                  innerClassName={`${
                    isMaintenanceComment ? "mx-0 font-normal" : ""
                  }`}
                  colorCN="text-brandGreen"
                  onClick={() =>
                    handleReplyClick(
                      `${data?.userData?.name?.firstName} ${data?.userData?.name?.lastName}`
                    )
                  }
                />
              )}

              {isMaintenanceComment && canAddComment && (
                <BaseButton
                  title="Reply"
                  className="items-start w-22 text-base mr-2"
                  innerClassName={`${
                    isMaintenanceComment ? "mx-0 font-normal" : ""
                  }`}
                  colorCN="text-brandGreen"
                  onClick={() =>
                    handleReplyClick(
                      `${data?.userData?.name?.firstName} ${data?.userData?.name?.lastName}`
                    )
                  }
                />
              )}
            </>
          ) : (
            <>
              {data.replies?.length > 0 && (
                <>
                  <BaseButton
                    title={`${data.replies?.length} ${
                      data.replies?.length > 1 ? `Replies` : "Reply"
                    }`}
                    className="items-start w-22 text-base mr-2 whitespace-nowrap z-20"
                    innerClassName={`${
                      isMaintenanceComment ? "mx-0 font-normal" : ""
                    }`}
                    colorCN="text-brandGreen"
                    onClick={() => setIsOpen(true)}
                  />
                  <p className="text-gray-200 ">{`Last reply ${moment(
                    data?.replies[data?.replies.length - 1]?.metadata?.createdAt
                  ).format("lll")}`}</p>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

Comments.propTypes = {
  /**
   * data displayed in the comments
   */
  data: PropTypes.shape({
    author: PropTypes.string,
    content: PropTypes.string,
    id: PropTypes.string,
    metadata: PropTypes.shape({
      createdAt: PropTypes.string,
    }),
    doc: PropTypes.string,
    replies: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        metadata: PropTypes.shape({
          createdAt: PropTypes.string,
        }),
        userData: PropTypes.shape({
          name: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
          }),
        }),
      })
    ),
    resource: PropTypes.string,
    userData: PropTypes.shape({
      name: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      avatar: PropTypes.shape({
        thumbnailUrl: PropTypes.string,
      }),
      id: PropTypes.string,
    }),
  }),
  index: PropTypes.number,
  user: PropTypes.shape({
    name: PropTypes.string,
  }),
  handlePostReply: PropTypes.func,
  /**
   * boolean to allow users to add comments or replies
   */
  allowNewComment: PropTypes.bool,
  isMaintenanceComment: PropTypes.bool,
  canAddComment: PropTypes.bool,
};

Comments.defaultProps = {
  data: {},
  index: undefined,
  user: {},
  handlePostReply: undefined,
  allowNewComment: false,
  isMaintenanceComment: false,
  canAddComment: false,
};
export default Comments;

import React, { useCallback } from "react";
import ReportManagementTemplatesDropdown from "../ReportManagementTemplatesDropdown";

const TableHeader = ({ canWrite, search, filter, fileActionsIcons }) => {
  const searchAndFilter = useCallback(
    (className) => (
      <div className={`flex items-center ${className}`} style={{ height: 50 }}>
        <div>{search}</div>
        <div>{filter}</div>
      </div>
    ),
    [search, filter]
  );

  return (
    <div>
      <div className="flex justify-between">
        {canWrite ? <ReportManagementTemplatesDropdown /> : searchAndFilter()}
        {fileActionsIcons}
      </div>
      {canWrite ? searchAndFilter("-mt-12") : null}
    </div>
  );
};

export default TableHeader;

import React, { useState } from "react";
import PropTypes from "prop-types";
import Checkbox from "../Checkbox/Checkbox";

const PhotoView = ({ photo, onIsPrimaryChange }) => {
  const { id, src, isPrimary } = photo;
  const [isShown, setIsShown] = useState(false);

  const onMouseEnter = () => {
    setIsShown(true);
  };

  const onMouseLeave = () => {
    setIsShown(false);
  };

  return (
    <div
      className="relative"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <img className="w-24 h-24 object-contain rounded" src={src} alt="" />
      {(isShown || isPrimary) && (
        <div className="absolute top-0 bottom-0 right-0 left-0">
          <Checkbox
            className="float-right mt-2 mr-2"
            checked={isPrimary}
            onChange={(isChecked) => onIsPrimaryChange(id, isChecked)}
          />
          {isPrimary && (
            <p className="absolute bottom-1 right-0 left-0 text-center font-semibold text-sm">
              Primary
            </p>
          )}
        </div>
      )}
    </div>
  );
};

PhotoView.propTypes = {
  photo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    isPrimary: PropTypes.bool.isRequired,
  }).isRequired,
  onIsPrimaryChange: PropTypes.func,
};

PhotoView.defaultProps = {
  onIsPrimaryChange: undefined,
};

export default PhotoView;

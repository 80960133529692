/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import DropableArea from "./DropableArea";
import useDragNDropViewData from "./useDragNDropViewData";

/**
 * Component that renders a dropable files view
 */
const DragNDropFileUploader = ({ handleFilesToAdd }) => {
  const { getInputProps, getRootProps } =
    useDragNDropViewData(handleFilesToAdd);

  return (
    <div
      {...getRootProps()}
      style={{
        width: 140,
        height: 140,
      }}
    >
      <input {...getInputProps()} />
      <DropableArea />
    </div>
  );
};

DragNDropFileUploader.defaultProps = {
  handleFilesToAdd: () => {},
};

DragNDropFileUploader.propTypes = {
  handleFilesToAdd: PropTypes.func,
};

export default DragNDropFileUploader;

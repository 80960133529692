import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import moment from "moment";
import Avatar from "../Message/Avatar";
import IconButton from "../Buttons/IconButton";
import arrowRightGray from "../../assets/images/arrowRightGray.svg";
import { getFullName } from "../../../helpers/Formatters";
import fileIcon from "../../assets/images/fileIcon.svg";
import returnIcon from "../../assets/images/returnIcon.svg";
import PDFViewerModal from "../PDFViewer/PDFViewerModal";
import { getDocumentPdfUrl } from "../../../helpers/Document";
import Spinner from "../Spinner/Spinner";

const headerTitleCN = cntl`
    text-gray-400
    font-medium	
    text-lg	
    mb-1
 `;
const headerSubTitleCN = cntl`
    text-gray-500
    font-medium	
 `;
const cardWrapperCN = cntl`
    flex 
    flex-col
    p-6
    pb-10
    bg-gray-100
    rounded-md
    relative
    mb-8
 `;

const getActionAndUser = (data) => {
  if (data?.isDraft && data?.modifiedBy) return `Modified by ${data?.user}`;
  if (data?.isDraft && !data?.modifiedBy) return `Created by ${data?.user}`;
  return `Initiated by ${data?.user}`;
};

const getActionDate = (data) => {
  if (data?.isDraft && data?.modifiedBy)
    return moment(data?.modifiedDate).format("MMM D, YYYY h:mm A");
  if (data?.isDraft && !data?.modifiedBy)
    return moment(data?.dateCreated).format("MMM D, YYYY h:mm A");
  return moment(data?.initiatedDate).format("MMM D, YYYY h:mm A");
};

const WorkflowHistoryCard = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    const getEndDate = (startDate, offset) => {
      if (!startDate) return 0;
      const startOn = moment(startDate.date).startOf("day");
      if (startDate.time) {
        const starthours = Number(startDate.time.split(":")?.[0]);
        const startMinutes = Number(startDate.time.split(":")?.[1]);
        startOn.set("hour", starthours).set("minute", startMinutes);
      }

      const dueOn = startOn.clone().add(offset, "days").format("MMM D, YYYY");
      return dueOn;
    };

    if (data && data.steps && data.startDate) {
      const tempArr = data.steps;
      const histEndDate = tempArr
        ?.reduce((maxDate, step) => {
          let maxDueDate = moment(step.dueDate);
          step.parallelSteps?.forEach((stp) => {
            const localDueDate = moment(stp.dueDate);
            maxDueDate = localDueDate.isAfter(maxDueDate)
              ? localDueDate
              : maxDueDate;
          });
          return maxDueDate.isAfter(maxDate) ? maxDueDate : maxDate;
        }, moment())
        ?.format("MMM D, YYYY");

      let maxDays = 0;
      tempArr?.forEach((step) => {
        let localMax = step.duration;
        step.parallelSteps?.forEach((pstep) => {
          localMax = pstep.duration > localMax ? pstep.duration : localMax;
        });
        maxDays = localMax > maxDays ? localMax : maxDays;
      });

      setEndDate(histEndDate ?? getEndDate(data.startDate, maxDays));
    }
  }, [data]);

  const onViewFile = async (file) => {
    setIsLoading(true);
    const url = await getDocumentPdfUrl(file.id);
    setIsLoading(false);
    setSelectedDocumentUrl(url);
  };

  return (
    <>
      <div className={cardWrapperCN}>
        <div className="flex flex-row my-4 justify-between">
          <div className="flex flex-row">
            <h2 className="mr-6 text-gray-500 font-bold text-xl">{`Version ${data?.version}`}</h2>
          </div>
          <IconButton
            icon={arrowRightGray}
            onClick={() => setIsOpen((prev) => !prev)}
            className={isOpen && "transform rotate-90"}
          />
        </div>
        <div className="flex justify-between">
          <div className="flex flex-col">
            <h3 className="text-gray-400 font-medium text-lg">
              {getActionAndUser(data)}
            </h3>
            <h3 className="font-medium">{getActionDate(data)}</h3>
          </div>
          <div className="w-1/5">
            <h3 className={headerTitleCN}>Duration</h3>
            <h3 className={headerSubTitleCN}>{`${moment(
              data?.startDate?.date
            )?.format("MMM D, YYYY")} - ${endDate}`}</h3>
          </div>
          <div className="flex flex-col">
            <h3 className={headerTitleCN}>Attachments</h3>
            {data.documents?.map((info) => (
              <div
                className="flex flex-row items-center"
                key={`${info?.customName}-${info?.name}`}
              >
                <img src={fileIcon} alt="file" className="mr-2" />
                <h3 className={`${headerSubTitleCN} truncate max-w-3/4`}>
                  {info?.customName || info?.name}
                </h3>
                <IconButton
                  icon={returnIcon}
                  className="ml-2 mt-1"
                  imgClassName="w-5 h-5"
                  onClick={() => onViewFile(info)}
                />
              </div>
            ))}
          </div>

          <div className="flex flex-col">
            <h3 className={headerTitleCN}>Members</h3>
            <div className="flex flex-row">
              {data.members.map((info) => (
                <Avatar
                  name={getFullName(info?.name)}
                  key={info?.id}
                  id={info?.id}
                  avatar={info?.avatar}
                  isExternalUser
                  className="mr-2 rounded-full"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="p-6 border-2 border-grey-100 rounded-md mb-8">
          <h2 className="text-lg text-gray-400 font-medium mb-4">Activity</h2>
          <div className="border-l border-gray-150 pl-8 relative">
            {data?.history?.map((info) => (
              <div
                className="flex flex-col border-b border-gray-100"
                key={info?.title}
              >
                <div className="flex flex-row py-4">
                  <h3 className="text-lg font-semibold w-1/2">{info.title}</h3>
                  <h3 className="text-lg w-1/4 text-left capitalize">
                    {getFullName(info?.userData?.name)}
                  </h3>
                  <h3 className="text-lg w-1/4 text-right	">
                    {info.when && moment(info.when).format("MMM D, YYYY h:m A")}
                  </h3>
                  <div className="absolute w-4 h-4 bg-gray-150 -left-2 rounded-full" />
                </div>
                {info?.subData?.map((sub) => (
                  <div key={sub.action}>
                    <div className="flex flex-row py-4 border-b border-gray-150">
                      <div className="flex flex-col w-1/2 px-8">
                        <h3 className="text-lg font-semibold">
                          {sub.action}
                          {sub.secondaryTitle && (
                            <>
                              {" - "}
                              <span className="text-brandGreen font-normal">
                                {sub.secondaryTitle}
                              </span>
                            </>
                          )}
                        </h3>
                        <p className="text-gray-200 mt-2">
                          {sub.text || sub.message}
                        </p>
                      </div>
                      <h3 className="text-lg w-1/4 text-left capitalize">
                        {getFullName(sub?.userData?.name)}
                      </h3>
                      <h3 className="text-lg w-1/4 text-right	">
                        {moment(sub?.when).format("MMM D, YYYY h:m A")}
                      </h3>
                      <div className="absolute w-4 h-4 -left-2 rounded-full border border-gray-150 bg-white" />
                    </div>
                    {sub?.subData?.map((nestedData) => (
                      <div
                        className="flex flex-row py-4 border-b border-gray-150"
                        key={nestedData.action}
                      >
                        <div className="flex flex-col w-1/2 pl-20 pr-8">
                          <h3 className="text-lg font-semibold">
                            {nestedData.action}
                          </h3>
                          <p className="text-gray-200 mt-2">
                            {nestedData.text}
                          </p>
                        </div>
                        <h3 className="text-lg w-1/4 text-left capitalize">
                          {getFullName(nestedData?.userData?.name)}
                        </h3>
                        <h3 className="text-lg w-1/4 text-right">
                          {moment(nestedData.when).format("MMM D, YYYY h:m A")}
                        </h3>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
      {selectedDocumentUrl && (
        <PDFViewerModal
          isOpen
          onClose={() => setSelectedDocumentUrl(null)}
          initialUrl={selectedDocumentUrl}
        />
      )}
      {isLoading && <Spinner />}
    </>
  );
};

WorkflowHistoryCard.propTypes = {
  data: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    steps: PropTypes.object,
    cost: PropTypes.string,
    user: PropTypes.string,
    endDate: PropTypes.string,
    dateCreated: PropTypes.string,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    history: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        name: PropTypes.string,
        title: PropTypes.string,
      })
    ),
    initiatedDate: PropTypes.string,
    initiatorName: PropTypes.string,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    startDate: PropTypes.string,
    status: PropTypes.string,
    version: PropTypes.number,
    isDraft: PropTypes.bool,
    modifiedDate: PropTypes.string,
    modifiedBy: PropTypes.string,
  }),
};

WorkflowHistoryCard.defaultProps = {
  data: {},
};
export default WorkflowHistoryCard;

import { ConfigurationAPI } from "@griffingroupglobal/eslib-api";
import _, { isEmpty } from "lodash";
import { ACCRUAL_TYPES, PTO_TYPES } from "../constants";

const getFinancialLastUpdatedPatchDate = async (
  financialsConfiguration,
  financialsSettings,
  patchDate
) => {
  const allDivisionCodes = financialsSettings?.divisionCodes?.filter(
    (divCode) => divCode.code && divCode.code !== ""
  );

  const newDivisionCodes = _.differenceBy(
    allDivisionCodes,
    financialsConfiguration?.financials?.divisionCodes,
    "code"
  );

  const patchBody = {
    ...financialsConfiguration,
    financials: {
      ...financialsConfiguration.financials,
      divisionCodes: allDivisionCodes,
      rateSheet: {
        rates: financialsSettings?.rateSheet?.filter(
          (rate) => rate.category !== ""
        ),
        premiumRateDaysOfWeek: financialsSettings?.premiumRateDaysOfWeek,
      },
      taxRates: {
        ...financialsSettings?.taxRates,
        suta: financialsSettings?.taxRates.suta?.filter(
          (opt) => opt?.state && opt.state !== ""
        ),
      },
      chartOfAccounts: financialsSettings?.chartOfAccounts?.filter(
        (coa) => coa.code !== ""
      ),
      csiCodeMapping: [
        ...financialsSettings?.csiCodeMapping?.map((mapping) => {
          const divDescription =
            allDivisionCodes.find(
              (divCode) => divCode?.code === mapping?.division
            )?.description || mapping.description;
          return {
            ...mapping,
            description: divDescription,
          };
        }),
        ...newDivisionCodes.map((code) => ({
          id: code.id,
          division: code.code,
          description: code.description,
          csiCodes: [],
          custom: true,
        })),
      ],
      payroll: {
        period: financialsSettings?.payroll?.period,
        locked: financialsSettings?.payroll?.locked,
      },
      period: {
        start: financialsSettings?.period?.start,
        end: financialsSettings?.period?.end,
        locked: financialsSettings?.period?.locked,
      },
      expense: {
        ratePerMile: financialsSettings?.expense?.ratePerMile,
        categories: financialsSettings?.expenseCategories?.filter(
          (category) => category?.display !== ""
        ),
      },
    },
  };

  let date;
  try {
    const {
      data: {
        metadata: { lastUpdated },
      },
    } = await ConfigurationAPI.patch(
      "financials",
      patchBody,
      { ...financialsConfiguration, metadata: { lastUpdated: patchDate } },
      { date: patchDate }
    );
    date = lastUpdated;
  } catch (err) {
    console.error("Error updating financials configuration ", err);
  }

  return date;
};

const getSystemLastUpdatedPatchDate = async (
  systemConfiguration,
  systemSettings,
  patchDate
) => {
  const patchBody = {
    ...systemConfiguration,
    system: {
      ...systemConfiguration.system,
      worldClock: {
        cities: systemSettings?.cities,
      },
      unitsOfMeasure: {
        ...systemSettings?.unitsOfMeasure,
        length_area: [
          ...systemSettings?.lengthUnits,
          ...systemSettings?.customLengthUnits?.filter(
            (unit) => unit.display !== ""
          ),
        ],
        quantity: [
          ...systemSettings?.quantityUnits,
          ...systemSettings?.customQuantityUnits?.filter(
            (unit) => unit.display !== ""
          ),
        ],
        time: [
          ...systemSettings?.timeUnits,
          ...systemSettings?.customTimeUnits?.filter(
            (unit) => unit.display !== ""
          ),
        ],
        volume_weight: [
          ...systemSettings?.volumeUnits,
          ...systemSettings?.customVolumeUnits?.filter(
            (unit) => unit.display !== ""
          ),
        ],
      },
      sop: {
        category: systemSettings?.sopCategories?.filter(
          (cat) => cat?.display !== ""
        ),
      },
      preferences: { ...systemSettings?.preferences },
      pto: {
        general: systemSettings?.ptoGeneralTypes.reduce((accumulator, type) => {
          return { ...accumulator, [`${type.type}`]: type };
        }, {}),
        locations: systemSettings?.ptoLocations
          ?.filter((loc) => loc.location !== "")
          .map((loc) => {
            const obj = {
              location: loc.location,
              id: loc.id,
            };

            PTO_TYPES.forEach((type) => {
              obj[`${type}`] = loc.types.find(
                (ptoType) => ptoType.type === type
              );
            });

            return obj;
          }),
        category: systemSettings?.ptoCategory,
      },
      benefits: systemSettings?.benefits?.map((bf) => ({
        ...bf,
        benefit: bf.benefit.filter((opt) => opt?.plan !== ""),
      })),
      holidays: systemSettings?.holidays,

      hoursOfOperation: systemSettings?.hoursOfOperation,
    },
  };
  let date;

  try {
    const {
      data: {
        metadata: { lastUpdated },
      },
    } = await ConfigurationAPI.patch(
      "system",
      patchBody,
      { ...systemConfiguration, metadata: { lastUpdated: patchDate } },
      {
        date: patchDate,
      }
    );
    date = lastUpdated;
  } catch (err) {
    console.error("Error updating system configuration ", err);
  }

  return date;
};

const getDocumentLastUpdatedPatchDate = async (
  documentsConfiguration,
  documentsSettings,
  patchDate
) => {
  const patchBody = {
    ...documentsConfiguration,
    documents: {
      ...documentsConfiguration.documents,
      documentType: documentsSettings?.documentTypes,
      contractTermHighlights: documentsSettings?.contractTermHighlights,
      coverSheet: documentsSettings?.coverSheet,
      qualsAndExceptions: {
        ...documentsSettings?.qualsAndExceptions,
        bid: documentsSettings?.qualsAndExceptions?.bid
          ?.map((qual) => qual.value)
          ?.filter((qual) => qual !== ""),
        changeOrder: documentsSettings?.qualsAndExceptions?.changeOrder
          ?.map((qual) => qual.value)
          ?.filter((qual) => qual !== ""),
        purchaseAuthorization:
          documentsSettings?.qualsAndExceptions?.purchaseAuthorization
            ?.map((qual) => qual.value)
            ?.filter((qual) => qual !== ""),
        contingencyAuthorization:
          documentsSettings?.qualsAndExceptions?.contingencyAuthorization
            ?.map((qual) => qual.value)
            ?.filter((qual) => qual !== ""),
        purchaseOrder: documentsSettings?.qualsAndExceptions?.purchaseOrder
          ?.map((qual) => qual.value)
          ?.filter((qual) => qual !== ""),
      },
      submissionReqs: documentsSettings?.submissionReqs
        ?.map((req) => req.value)
        ?.filter((req) => req !== ""),
    },
  };

  let date;
  try {
    const {
      data: {
        metadata: { lastUpdated },
      },
    } = await ConfigurationAPI.patch(
      "documents",
      patchBody,
      { ...documentsConfiguration, metadata: { lastUpdated: patchDate } },
      { date: patchDate }
    );
    date = lastUpdated;
  } catch (err) {
    console.error("Error updating documents configuration");
  }
  return date;
};

const getWokflowLastUpdatedPatchDate = async (
  workflowsConfiguration,
  workflowsSettings,
  patchDate
) => {
  const patchBody = {
    ...workflowsConfiguration,
    workflows: {
      ...workflowsConfiguration.workflows,
      completion: {
        timeOfDay:
          workflowsSettings?.completionTime ||
          workflowsConfiguration?.workflows?.completion?.timeOfDay,
        timezone:
          workflowsSettings?.completionTimeZone ||
          workflowsConfiguration?.workflows?.completion?.timezone,
      },
      clientInvoice: {
        paymentDue: {
          value: workflowsSettings?.clientInvoicePaymentDue?.value,
          typeOfDuration:
            workflowsSettings?.clientInvoicePaymentDue?.typeOfDuration,
        },
        reviewAndApproval: workflowsSettings?.clientInvoiceApprovers?.filter(
          (approver) => approver.reference
        ),
      },
      vendorInvoice: {
        paymentDue: {
          value: workflowsSettings?.vendorInvoicePaymentProcessing?.value,
          typeOfDuration:
            workflowsSettings?.vendorInvoicePaymentProcessing?.typeOfDuration,
        },
        reviewAndApproval: workflowsSettings?.vendorInvoiceApprovers?.filter(
          (approver) => approver.reference
        ),
      },
      payrollApproval: {
        ...workflowsSettings?.payrollApproval,
        approved: workflowsSettings?.payrollApproval?.approved?.filter(
          (approver) => approver.reference
        ),
      },
      ptoApproval: workflowsSettings?.ptoApproval?.filter(
        (approver) => approver.reference
      ),
      financialAdminApproval: workflowsSettings?.financialAdminApproval?.filter(
        (approver) => approver.reference
      ),
      timesheetApproval: {
        ...workflowsSettings?.timesheetApproval,
        approved: workflowsSettings?.timesheetApproval?.approved?.filter(
          (approver) => approver.reference
        ),
      },
      employeeHandbookAcceptance: {
        ...workflowsSettings?.employeeHandbookAcceptance,
        document:
          workflowsSettings?.employeeHandbookAcceptance?.document?.reference,
      },
      policyAcknowledgement: {
        ...workflowsSettings?.policyAcknowledgement,
        policies: workflowsSettings?.policyAcknowledgement?.policies
          ?.map((policy) => policy.value)
          .filter((policy) => policy !== ""),
      },
      templates: [],
    },
  };
  let date;
  try {
    const {
      data: {
        metadata: { lastUpdated },
      },
    } = await ConfigurationAPI.patch(
      "workflows",
      patchBody,
      { ...workflowsConfiguration, metadata: { lastUpdated: patchDate } },
      { date: patchDate }
    );
    date = lastUpdated;
  } catch (err) {
    console.error("Error updating workflows configuration");
  }
  return date;
};

const getManagementLastUpdatedPatchDate = async (
  managementConfiguration,
  managementSettings,
  patchDate
) => {
  const patchBody = {
    ...managementConfiguration,
    management: {
      ...managementConfiguration.management,
      asset: {
        category: managementSettings?.assetCategories
          ?.map((cat) => ({
            ...cat,
            subcategories: cat.subcategories?.filter(
              (subcat) => subcat?.display !== ""
            ),
          }))
          ?.filter((cat) => cat?.display !== ""),
      },
      contact: {
        disciplines: managementSettings?.contactDisciplines?.filter(
          (discipline) => discipline?.display !== ""
        ),
      },
      submittal: {
        types: managementSettings?.submittalTypes?.filter(
          (submittalType) => submittalType?.display !== ""
        ),
        template: managementSettings?.submittalTemplate,
      },
      workflow: {
        template: managementSettings?.workflowTemplate,
      },
      rfi: {
        template: managementSettings?.RFITemplate,
      },
      entity: {
        types: managementSettings?.entityTypes,
      },
      entityInsurance: managementSettings?.entityInsurance
        ?.map((ins) => ({
          ...ins,
          policies: ins.policies.filter((pol) => pol?.provider !== ""),
        }))
        ?.filter((ins) => ins?.category !== ""),
      project: {
        types: managementSettings?.projectTypes,
      },
      projectInspection: {
        types: managementSettings?.projectInspectionTypes,
      },
      projectInsurance: managementSettings?.projectInsurance
        ?.map((ins) => ({
          ...ins,
          policies: ins.policies.filter((pol) => pol?.provider !== ""),
        }))
        ?.filter((ins) => ins?.category !== ""),
      property: {
        types: managementSettings?.propertyTypes,
      },
      propertySpace: {
        types: managementSettings?.propertySpaceTypes,
      },
      propertyLevel: {
        types: managementSettings?.propertyLevelTypes,
      },
      managementTasks: {
        isCompletionTimeRequired: managementSettings?.isCompletionTimeRequired,
      },
      ticket: { types: managementSettings?.ticket },
    },
  };
  let date;
  try {
    const {
      data: {
        metadata: { lastUpdated },
      },
    } = await ConfigurationAPI.patch(
      "management",
      patchBody,
      { ...managementConfiguration, metadata: { lastUpdated: patchDate } },
      { date: patchDate }
    );
    date = lastUpdated;
  } catch (err) {
    console.error("Error updating management configuration");
  }
  return date;
};

const formatDocumentConfigurationOriginalPayload = (documentsConfiguration) => {
  if (isEmpty(documentsConfiguration)) return {};
  const configPayload = {
    ...documentsConfiguration.documents,
    documentTypes: [...documentsConfiguration.documents?.documentType]?.sort(
      (a, b) => a?.display?.toUpperCase() > b?.display?.toUpperCase()
    ),
    qualsAndExceptions: {
      ...documentsConfiguration.documents?.qualsAndExceptions,
      bid: documentsConfiguration.documents?.qualsAndExceptions?.bid?.map(
        (qual) => ({
          label: qual,
          value: qual,
        })
      ),
      changeOrder:
        documentsConfiguration.documents?.qualsAndExceptions?.changeOrder?.map(
          (qual) => ({
            label: qual,
            value: qual,
          })
        ),
      purchaseAuthorization:
        documentsConfiguration.documents?.qualsAndExceptions?.purchaseAuthorization?.map(
          (qual) => ({
            label: qual,
            value: qual,
          })
        ),
      contingencyAuthorization:
        documentsConfiguration.documents?.qualsAndExceptions?.contingencyAuthorization?.map(
          (qual) => ({
            label: qual,
            value: qual,
          })
        ),
      purchaseOrder:
        documentsConfiguration.documents?.qualsAndExceptions?.purchaseOrder?.map(
          (qual) => ({
            label: qual,
            value: qual,
          })
        ),
    },
    submissionReqs: documentsConfiguration.documents?.submissionReqs?.map(
      (req) => ({
        label: req,
        value: req,
      })
    ),
  };
  return configPayload;
};

const formatFinancialConfigurationOriginalPayload = (
  financialsConfiguration
) => {
  if (isEmpty(financialsConfiguration)) return {};
  const configPayload = {
    ...financialsConfiguration.financials,
    rateSheet: financialsConfiguration.financials?.rateSheet?.rates,
    premiumRateDaysOfWeek:
      financialsConfiguration.financials?.rateSheet?.premiumRateDaysOfWeek,
    chartOfAccounts: [
      ...financialsConfiguration.financials?.chartOfAccounts,
    ].sort((a, b) => parseInt(a?.code, 10) - parseInt(b?.code, 10)),
    expenseCategories: financialsConfiguration.financials?.expense?.categories,
  };
  return configPayload;
};

const formatManagementConfigurationOriginalPayload = (
  managementConfiguration
) => {
  if (isEmpty(managementConfiguration)) return {};
  const configPayload = {
    assetCategories: managementConfiguration.management?.asset?.category || [],
    entityTypes: [...managementConfiguration.management?.entity?.types]?.sort(
      (a, b) => a?.display?.toUpperCase() > b?.display?.toUpperCase()
    ),
    entityInsurance: managementConfiguration.management?.entityInsurance,
    projectTypes: [...managementConfiguration.management?.project?.types]?.sort(
      (a, b) => a?.display?.toUpperCase() > b?.display?.toUpperCase()
    ),
    projectInspectionTypes: [
      ...managementConfiguration.management?.projectInspection?.types,
    ]?.sort((a, b) => a?.display?.toUpperCase() > b?.display?.toUpperCase()),
    projectInsurance: managementConfiguration.management?.projectInsurance,
    propertyTypes: [
      ...managementConfiguration.management?.property?.types,
    ]?.sort((a, b) => a?.display?.toUpperCase() > b?.display?.toUpperCase()),
    propertySpaceTypes: [
      ...managementConfiguration.management?.propertySpace?.types,
    ].sort((a, b) => a?.display?.toUpperCase() > b?.display?.toUpperCase()),
    propertyLevelTypes: [
      ...managementConfiguration.management?.propertyLevel?.types,
    ].sort((a, b) => a?.display?.toUpperCase() > b?.display?.toUpperCase()),
    isCompletionTimeRequired:
      managementConfiguration.management?.managementTasks
        ?.isCompletionTimeRequired,
    contactDisciplines:
      managementConfiguration.management?.contact?.disciplines,
    submittalTypes: managementConfiguration.management?.submittal?.types,
    submittalTemplate: managementConfiguration.management?.submittal?.template,
    workflowTemplate: managementConfiguration.management?.workflow?.template,
    RFITemplate: managementConfiguration.management?.rfi?.template,
    ticket: managementConfiguration.management?.ticket?.types,
  };
  return configPayload;
};

const formatSystemConfigurationOriginalPayload = (systemConfiguration) => {
  if (isEmpty(systemConfiguration)) return {};

  const configPayload = {
    ...systemConfiguration.system,
    cities: [...systemConfiguration.system?.worldClock?.cities]?.sort(
      (a, b) => a?.city?.toUpperCase() > b?.city?.toUpperCase()
    ),
    lengthUnits:
      systemConfiguration.system?.unitsOfMeasure?.length_area?.filter(
        (type) => !type.custom
      ),
    customLengthUnits:
      systemConfiguration.system?.unitsOfMeasure?.length_area?.filter(
        (type) => type.custom
      ),
    quantityUnits: systemConfiguration.system?.unitsOfMeasure?.quantity?.filter(
      (type) => !type.custom
    ),
    customQuantityUnits:
      systemConfiguration.system?.unitsOfMeasure?.quantity?.filter(
        (type) => type.custom
      ),
    timeUnits: systemConfiguration.system?.unitsOfMeasure?.time?.filter(
      (type) => !type.custom
    ),
    customTimeUnits: systemConfiguration.system?.unitsOfMeasure?.time?.filter(
      (type) => type.custom
    ),
    volumeUnits:
      systemConfiguration.system?.unitsOfMeasure?.volume_weight?.filter(
        (type) => !type.custom
      ),
    customVolumeUnits:
      systemConfiguration.system?.unitsOfMeasure?.volume_weight?.filter(
        (type) => type.custom
      ),
    sopCategories: systemConfiguration.system?.sop?.category,
    preferences: { ...systemConfiguration?.system?.preferences },
    ptoGeneralTypes: PTO_TYPES.map((type) => {
      if (type === PTO_TYPES[0]) {
        return {
          type,
          ...systemConfiguration.system?.pto?.general?.[`${type}`],
          accrualType: ACCRUAL_TYPES[1]?.value,
          maxAccrual: "100.00",
          allowNegatives: false,
        };
      }
      return {
        type,
        ...systemConfiguration.system?.pto?.general?.[`${type}`],
      };
    }),
    ptoLocations: systemConfiguration.system?.pto?.locations?.map((pto) => {
      return {
        location: pto.location,
        id: pto.id,
        types: PTO_TYPES.map((type) => ({
          type,
          ...pto[`${type}`],
        })),
      };
    }),
    ptoCategory: systemConfiguration.system?.pto?.category,
    benefits: systemConfiguration.system?.benefits,
    holidays: systemConfiguration.system?.holidays,
  };
  return configPayload;
};

const formatWorkflowConfigurationOriginalPayload = (
  workflowsConfiguration,
  employeeHandbookRef
) => {
  const config = {
    completionTime: workflowsConfiguration.workflows?.completion?.timeOfDay,
    completionTimeZone: workflowsConfiguration.workflows?.completion?.timezone,
    clientInvoiceApprovers:
      workflowsConfiguration.workflows?.clientInvoice?.reviewAndApproval,
    clientInvoicePaymentDue: {
      value: workflowsConfiguration.workflows?.clientInvoice?.paymentDue?.value,
      typeOfDuration:
        workflowsConfiguration.workflows?.clientInvoice?.paymentDue
          ?.typeOfDuration,
    },
    vendorInvoiceApprovers:
      workflowsConfiguration.workflows?.vendorInvoice?.reviewAndApproval,
    vendorInvoicePaymentProcessing: {
      value: workflowsConfiguration.workflows?.vendorInvoice?.paymentDue?.value,
      typeOfDuration:
        workflowsConfiguration.workflows?.vendorInvoice?.paymentDue
          ?.typeOfDuration,
    },
    payrollApproval: workflowsConfiguration.workflows?.payrollApproval,
    ptoApproval: workflowsConfiguration.workflows?.ptoApproval,
    financialAdminApproval:
      workflowsConfiguration.workflows?.financialAdminApproval,
    timesheetApproval: workflowsConfiguration.workflows?.timesheetApproval,
    employeeHandbookAcceptance: {
      ...workflowsConfiguration.workflows?.employeeHandbookAcceptance,
      document: {
        reference: employeeHandbookRef,
      },
    },

    policyAcknowledgement: {
      ...workflowsConfiguration.workflows?.policyAcknowledgement,
      policies:
        workflowsConfiguration.workflows?.policyAcknowledgement?.policies?.map(
          (policy) => ({
            label: policy,
            value: policy,
          })
        ),
    },
    templates: [],
  };

  return config;
};

export {
  getSystemLastUpdatedPatchDate,
  getFinancialLastUpdatedPatchDate,
  getDocumentLastUpdatedPatchDate,
  getWokflowLastUpdatedPatchDate,
  getManagementLastUpdatedPatchDate,
  formatDocumentConfigurationOriginalPayload,
  formatFinancialConfigurationOriginalPayload,
  formatManagementConfigurationOriginalPayload,
  formatSystemConfigurationOriginalPayload,
  formatWorkflowConfigurationOriginalPayload,
};

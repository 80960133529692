import { useMutation, useQueryClient } from "react-query";
import spacesKeys from "./spacesKeys";
import { toastError, toastMessage } from "../../stories/Components/Toast/Toast";
import postSpaceToProperty from "../../helpers/Spaces/postSpaceToProperty";

const useSpacesPostToProperty = (mutationKey) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey,
    mutationFn: postSpaceToProperty,
    onSuccess: () => {
      toastMessage("Space was created successfully");
    },
    onError: (error) => {
      console.error("useAddSpaceToProperty", error);
      toastError("Space could not be created. Please try again");
    },
    onSettled: () => {
      queryClient.invalidateQueries(spacesKeys.spaces);
    },
  });
};

export default useSpacesPostToProperty;

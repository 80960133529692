import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  RESOLVED_WF,
  UNRESOLVED_WF_STEP,
  RESUBMISSION_WF_STEP,
} from "../../../constants";
import RadioButton from "../Buttons/RadioButton";
import Input from "../Input/Input";
import Modal from "../Modal/Modal";

const ResolveWFModal = ({ isOpen, handleClose, handleResolveWF }) => {
  const [resolveOption, setResolveOption] = useState("");
  const [resolvedNote, setResolvedNote] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setResolveOption("");
      setResolvedNote("");
    }
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      title="Resolve WORKFLOW"
      onRequestModalClose={handleClose}
      hideFooter
      primaryButtonTitle="Submit"
      primaryButtonOnClick={handleResolveWF}
      tertiaryButtonTitle="Cancel"
    >
      <div className="flex flex-col">
        <div className="flex flex-col w-full">
          <div className="mb-3">
            <RadioButton
              label="Mark as resolved"
              isChecked={resolveOption === RESOLVED_WF}
              onChange={() => setResolveOption(RESOLVED_WF)}
            />
          </div>
          <div className="mb-3">
            <RadioButton
              label="Mark as unresolved"
              isChecked={resolveOption === UNRESOLVED_WF_STEP}
              onChange={() => setResolveOption(UNRESOLVED_WF_STEP)}
            />
          </div>
          <RadioButton
            label="Request for resubmission"
            isChecked={resolveOption === RESUBMISSION_WF_STEP}
            onChange={() => setResolveOption(RESUBMISSION_WF_STEP)}
          />
          <Input
            isTextarea
            className="mt-6"
            label="Comments"
            placeholder="Comments (optional)"
            value={resolvedNote}
            onChange={setResolvedNote}
          />
        </div>
      </div>
    </Modal>
  );
};

ResolveWFModal.propTypes = {
  /**
   * bool if modal is open
   */
  isOpen: PropTypes.bool,
  /**
   * function to handle sending model close
   */
  handleClose: PropTypes.func,
  /**
   * function to handle submission
   */
  handleResolveWF: PropTypes.func,
};

ResolveWFModal.defaultProps = {
  isOpen: undefined,
  handleClose: undefined,
  handleResolveWF: undefined,
};
export default ResolveWFModal;

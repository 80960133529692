import React from "react";
// import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { getProjectOptions } from "../../helpers/SiteHeaderOptions";
import SiteHeader from "../../stories/Components/SiteHeader/SiteHeader";

import SecondaryHeader from "../../stories/Components/TextHeaders/SecondaryHeader";

const Inventory = () => {
  const { projectId } = useParams();

  return (
    <>
      <SiteHeader
        title="Inventory"
        dropdownRoutes={projectId && getProjectOptions(projectId)}
      />
      <SecondaryHeader>Inventory: {projectId}</SecondaryHeader>
      <p>inventory goes here.</p>
    </>
  );
};

Inventory.propTypes = {};

Inventory.defaultProps = {};

export default Inventory;

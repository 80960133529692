import React from "react";
import PropTypes from "prop-types";

import Widget from "../../../stories/Components/Widget/Widget";
import WidgetEditorView from "../../../stories/Components/WidgetEditorView";
import FaqListNew from "../../../stories/Components/Help/FaqListNew";
import { CONTACT_US_PATH } from "../../../constants";
import Modal from "../../../stories/Components/Modal/Modal";
import esLogo from "../../../stories/assets/images/es-logo.png";
import videoPlayIcon from "../../../stories/assets/images/videoPlayIcon.svg";
import CarouselView from "../../../stories/Components/CarouselView/CarouselView";
import useHelpSingleCategoryData from "./useHelpSingleCategoryData";

const categoryStyle = {
  fontSize: "24px;",
  lineHeight: "32px;",
  letterSpacing: "1.125px;",
  textAlign: "left;",
};

const thumbnailsWrapperStyle = {
  height: "260px",
};

const videoWrapperStyle = {
  width: "328.5px",
  height: "231.12px",
};
const videoStyle = {
  width: "304.5px",
  height: "168.12px",
};

const HelpSingleCategoryView = ({
  category,
  helpData,
  setCategoryView,
  videoData,
}) => {
  const {
    history,
    videoDialog,
    openPlayVideo,
    closePlayVideo,
    startIndex,
    setModalTitle,
  } = useHelpSingleCategoryData({ videoData });

  return (
    <div className="border rounded-md p-2">
      <h1
        style={categoryStyle}
        className="text-xl font-semibold ml-2 mt-3 mb-5"
      >
        {category}
      </h1>
      <WidgetEditorView
        className="mb-4"
        showBackButton
        hideFooter
        hideBorder
        onBackPressed={() => {
          setCategoryView(null);
        }}
      >
        <Widget
          title="Tutorial Videos"
          draggable={null}
          className="mb-4"
          childClassName="overflow-x-scroll"
        >
          <div
            style={thumbnailsWrapperStyle}
            className="flex items-center justify-start w-fit overflow-x-scroll gap-x-4"
          >
            {videoData?.length !== 0 ? (
              videoData?.map((video) => {
                return (
                  <div key={video?.contentsUrl} style={videoWrapperStyle}>
                    <div
                      onClick={() => openPlayVideo(video)}
                      className="relative border rounded-lg flex flex-col justify-start p-3"
                      role="presentation"
                    >
                      <video
                        className="rounded-lg mb-4"
                        style={videoStyle}
                        playsInline
                        autoPlay={false}
                        muted
                        preload="metadata"
                      >
                        <source
                          src={`${video?.contentsUrl}#t=0.5`}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                      <img
                        src={esLogo}
                        alt="es logo"
                        className="absolute left-5 top-5 w-7 h-7 rounded-full"
                      />
                      <img
                        src={videoPlayIcon}
                        alt="play help video"
                        className="absolute left-6 bottom-14"
                      />
                      <p className="truncate">
                        {video?.name?.replace(".mp4", "")}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>Tutorial videos coming soon!</p>
            )}
          </div>
        </Widget>

        <Widget draggable={null} title="Frequently Asked Questions">
          <FaqListNew items={helpData} />
        </Widget>
      </WidgetEditorView>

      <div className="flex flex-col ml-3 mb-5">
        <p className="font-semibold">
          Still can’t find what your looking for?{" "}
        </p>
        <p>
          <button
            type="button"
            onClick={() => history.push(CONTACT_US_PATH)}
            className="text-brandDarkGreen font-semibold hover:underline cursor-pointer"
          >
            Contact Us
          </button>{" "}
          to speak with a real human.
        </p>
      </div>

      <Modal
        title={`${category} tutorial videos`}
        isOpen={videoDialog?.open}
        primaryButtonTitle="Close"
        primaryButtonOnClick={closePlayVideo}
        onRequestModalClose={closePlayVideo}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        alert
        hideFooter
      >
        <CarouselView
          items={videoData?.map((item) => ({
            ...item,
            category: "Videos",
            contentType: item?.name?.includes(".mp4")
              ? "video/mp4"
              : "video/quicktime",
          }))}
          startIndex={startIndex}
          updateModalTitle={setModalTitle}
          sort
        />
      </Modal>
    </div>
  );
};

HelpSingleCategoryView.defaultProps = {
  category: "",
  setCategoryView: () => {},
  helpData: [{}],
  videoData: [],
};

HelpSingleCategoryView.propTypes = {
  category: PropTypes.string,
  setCategoryView: PropTypes.func,
  helpData: PropTypes.arrayOf(PropTypes.shape({})),
  videoData: PropTypes.arrayOf(
    PropTypes.shape({ contentsUrl: PropTypes.string, name: PropTypes.string })
  ),
};

export default HelpSingleCategoryView;

import React from "react";
import PropTypes from "prop-types";

import { useAssetSwitchView } from "../../../hooks/useSwitchView";

import PureAssetDocuments from "./PureAssetDocuments";

const AssetDocuments = ({ hideHeader, setButtonActions }) => {
  // eslint-disable-next-line no-unused-vars
  const [isShowingTable, setIsShowingTable] = useAssetSwitchView(true);

  return (
    <PureAssetDocuments
      hideHeader={hideHeader}
      isShowingTable
      setIsShowingTable={setIsShowingTable}
      setButtonActions={setButtonActions}
    />
  );
};

AssetDocuments.propTypes = {
  hideHeader: PropTypes.bool,
  setButtonActions: PropTypes.func,
};

AssetDocuments.defaultProps = {
  hideHeader: false,
  setButtonActions: undefined,
};

export default AssetDocuments;

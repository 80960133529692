// Framework Tools
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

// Helpers
import { getFullName } from "../../../../helpers/Formatters";

// Images & Icons
import crossIconWhite from "../../../assets/images/crossIconWhite.svg";

// Components
import TertiaryHeader from "../../TextHeaders/TertiaryHeader";
import Avatar from "../../Message/Avatar";
import { useAppState } from "../../../../state/appState";
import MemberAvatarListWithViewAll from "../../Avatar/MemberAvatarListWithViewAll";
import { renderStatusImage } from "../../../../helpers/User";

const associatedUserCN = (avatarClassname) => cntl`
  bg-gray-150
  border-2
  border-gray-100
  mr-1
  z-40
  rounded-full
  flex
  items-center
  justify-center
  mr-1
  ${avatarClassname}
`;

const ViewAllButton = ({ distribution, avatarClassname }) => {
  return (
    <div className={associatedUserCN(avatarClassname)}>
      <p className="uppercase text-gray-400 text-sm font-semibold truncate">
        +{distribution?.length - 3}
      </p>
    </div>
  );
};
export default function RequestGridViewDistroListCell({
  distroList,
  hideTile,
  handleDeleteFromDistro,
  hideDeleteIcon,
  handleClick,
  className,
  isEditing,
  immutableUser,
  avatarClassName,
  showRsvpStatus,
  rsvpData,
}) {
  const [{ userDict }] = useAppState();
  const [distribution, setDisribution] = useState([]);

  useEffect(() => {
    setDisribution(
      distroList?.map((dist) => {
        if (typeof dist === "string") {
          return userDict?.[dist];
        }
        return dist;
      })
    );
  }, [distroList, userDict]);

  return (
    <div className="w-full h-full flex flex-col justify-start flex-wrap relative">
      {!hideTile && (
        <TertiaryHeader className="text-sm text-left">
          Distro List
        </TertiaryHeader>
      )}
      {/* This Div is a wrapper, puts view all button in line with avatars */}
      <div className={`flex ${className ?? ""}`}>
        <div className="flex flex-row">
          {distribution?.slice(0, 3).map((user) => {
            return (
              <div
                key={user?.id}
                className="relative flex"
                onClick={() => handleClick(user)}
                onKeyDown={(e) => {
                  if (e.code === "enter") handleClick(user);
                }}
                tabIndex={0}
                role="button"
              >
                <Avatar
                  className={`${avatarClassName} text-xs ${
                    hideDeleteIcon ? "mr-1" : "mr-2"
                  }`}
                  name={getFullName(user?.name)}
                  isOnline={user?.isOnline}
                  isExternalUser
                  id={user?.value}
                  avatar={user?.avatar}
                  key={user?.id}
                />
                {showRsvpStatus &&
                  typeof rsvpData?.[user?.reference] === "string" && (
                    <img
                      alt="status"
                      src={renderStatusImage(rsvpData?.[user?.reference])}
                      className="absolute h-fit bottom-5 right-0"
                      style={{ zIndex: 1 }}
                      width="15px"
                    />
                  )}
                {!hideDeleteIcon && (
                  <button
                    type="button"
                    className={`absolute top-0 right-1 ${
                      immutableUser === user?.reference ? "hidden" : ""
                    }`}
                    style={{
                      width: "16px",
                      height: "16px",
                    }}
                    onClick={() => handleDeleteFromDistro(user?.reference)}
                  >
                    <img
                      alt="delete tag"
                      className="rounded-xl p-1"
                      style={{ backgroundColor: "#027D61" }}
                      src={crossIconWhite}
                    />
                  </button>
                )}
              </div>
            );
          })}
          {distribution?.length > 3 && (
            <MemberAvatarListWithViewAll
              members={distribution}
              viewAllClassName="text-xs"
              viewAllAlways="top-8 left-8 z-10"
              viewAllButton={
                <ViewAllButton
                  distribution={distribution}
                  avatarClassname={avatarClassName}
                />
              }
              disableOnClick={isEditing}
              handleMemberClick={handleClick}
              showRsvpStatus={showRsvpStatus}
              rsvpData={rsvpData}
            />
          )}
        </div>
      </div>
    </div>
  );
}

// prop types
RequestGridViewDistroListCell.propTypes = {
  distroList: PropTypes.arrayOf(
    PropTypes.shape({
      member: PropTypes.shape({
        name: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      }),
    })
  ),
  hideTile: PropTypes.bool,
  handleDeleteFromDistro: PropTypes.func,
  hideDeleteIcon: PropTypes.bool,
  className: PropTypes.string,
  isEditing: PropTypes.bool,
  handleClick: PropTypes.func,
  // Prevent deleting immutable user (currentUser reference)
  immutableUser: PropTypes.string,
  avatarClassName: PropTypes.string,
  showRsvpStatus: PropTypes.bool,
  rsvpData: PropTypes.shape({}),
};

// default props
RequestGridViewDistroListCell.defaultProps = {
  distroList: [
    {
      member: {
        name: {
          firstName: undefined,
          lastName: undefined,
        },
      },
    },
  ],
  hideTile: false,
  handleDeleteFromDistro: undefined,
  hideDeleteIcon: false,
  className: undefined,
  isEditing: false,
  handleClick: () => {},
  immutableUser: undefined,
  avatarClassName: "w-10 h-10",
  showRsvpStatus: undefined,
  rsvpData: undefined,
};

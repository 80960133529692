import cntl from "cntl";
import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";

const buttonStyle = (disabled, className) => cntl`
border-2
rounded-lg
${!disabled && "border-borderGreen bg-white text-gray-500"}
${disabled && "bg-gray-100 border-gray-150 text-gray-150"}
font-semibold
h-10
flex
items-center
relative
${className}
`;

const AddButton = ({
  disabled,
  onClick,
  resourceName,
  className,
  buttonTitle,
  keepIcon,
}) => {
  const buttonRef = useRef({});

  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick();
    }
  }, [disabled, onClick]);

  return (
    <button
      className={buttonStyle(disabled, className)}
      onClick={handleClick}
      type="button"
      tabIndex={0}
      id={`add-${resourceName}-button`}
      aria-label={`add-${resourceName}-button`}
      ref={buttonRef}
    >
      <p className="whitespace-nowrap px-3">
        {keepIcon && buttonTitle ? (
          <>
            {buttonTitle}
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              className="inline ml-2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM11 5H9V9H5V11H9V15H11V11H15V9H11V5Z"
                fill="#333333"
              />
            </svg>
          </>
        ) : (
          buttonTitle ?? (
            <>
              {`Add ${resourceName}`}
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                className="inline ml-2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM11 5H9V9H5V11H9V15H11V11H15V9H11V5Z"
                  fill="#333333"
                />
              </svg>
            </>
          )
        )}
      </p>
    </button>
  );
};

AddButton.propTypes = {
  resourceName: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  buttonTitle: PropTypes.string,
  keepIcon: PropTypes.bool,
};

AddButton.defaultProps = {
  resourceName: "Resource",
  disabled: false,
  onClick: () => {},
  className: undefined,
  buttonTitle: undefined,
  keepIcon: undefined,
};

export default AddButton;

import { TicketAPI } from "@griffingroupglobal/eslib-api";
import { useQueryClient } from "react-query";
import { ticketsKeys } from "../config/reactQuery/queryKeyFactory";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import useCurrentUser from "./useCurrentUser";

/**
 * Fetch ticket by id
 * @param {string} id Ticket id: 1111-2222-3333-4444
 */
const getTicketById = async (id) => {
  const { data } = await TicketAPI.getByRef(`Ticket/${id}`);
  return data;
};

/**
 * Query hook to fetch single service request by id
 * @param {string} serviceRequestId ticket id
 * @param {Function} select function to select or transform data
 */
const useServiceRequestById = (serviceRequestId, select) => {
  const queryClient = useQueryClient();

  const { data: currentUser } = useCurrentUser();

  const queryKey = ticketsKeys.byId(serviceRequestId);
  const canRead = !!currentUser?.permissions?.report?.can_read;

  return useAuthenticatedQuery({
    queryKey,
    enabled: canRead && !!serviceRequestId,
    queryFn: () => getTicketById(serviceRequestId),
    select,
    // Set initial data from cache if exists
    initialData: () =>
      queryClient
        .getQueryData(ticketsKeys.tickets)
        ?.find((ticket) => ticket?.id === serviceRequestId),

    // This will refetch in the background if data is older than the provided staleTime
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(ticketsKeys.tickets)?.dataUpdatedAt,

    onError: (error) => {
      console.error("useServiceRequestById", error);
    },
  });
};

export default useServiceRequestById;

import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import cntl from "cntl";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import CreateContact from "../../../Pages/Contact/CreateContact";

import Dropdown from "../Dropdown/Dropdown";
import Input from "../Input/Input";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import TertiaryHeader from "../TextHeaders/TertiaryHeader";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";

import link from "../../assets/images/linkGreen.svg";
import deleteIcon from "../../assets/images/collapseIcon.svg";
import Avatar from "../Message/Avatar";
import ContactDetails from "../ContactForm/ContactDetails";
import {
  ADD_OPEN_MODAL,
  CREATE_CONTACT_MODAL,
  GET_CONTACT_PATH,
} from "../../../constants";
import { useAppState } from "../../../state/appState";
import BaseButton from "../Buttons/BaseButton";
import { useModalState } from "../../../state/modalState";
import { isEmployeeOrAdmin } from "../../../helpers/Permissions";
import useCurrentUser from "../../../hooks/useCurrentUser";

const modalStyles = {
  content: {
    inset: "0",
    width: "75%",
    height: "min-content",
    maxHeight: "100%",
    padding: "0",
    marginLeft: "auto",
    marginRight: "auto",
    overflowY: "auto",
    top: "0",
    left: "0",
  },
  overlay: {
    backgroundColor: "rgba(25, 25, 25, 0.8)",
    zIndex: "50",
  },
};
const deleteButtonCN = cntl`
  "mt-5"
  flex
  justify-center
  items-center
  w-6
  h-full
  ml-4
  mr-auto
`;

const SelectUserInterface = ({
  displayUsersBelowDropdown,
  avatarFontClassName,
  avatarClassName,
  avatarContainerClassName,
  overflowY,
  nameHeaderClassName,
  nameHeaderTextSize,
  nameHeaderFontWeight,
  className,
  userType,
  userList,
  userLabel,
  userPlaceholder,
  roleLabel,
  rolePlaceholder,
  buttonText,
  userOptions,
  updateSelect,
  roleOptions,
  defaultRole,
  showCurrentRole,
  onAddUser,
  onRemoveUser,
  onCancelClick,
  isSingleSelect,
  inviteNewContact,
  disableRemove,
  disableCreateUser,
  onCreateContactDialogDismissed,
  disabled,
  required,
  dropdownRef,
  hideSearchMembers,
  hideSearchPositions,
  createContactType,
  size,
  isPropertyVendor,
  isBrokerCompany,
  isFinancials,
}) => {
  const history = useHistory();
  const { data: currentUser } = useCurrentUser();
  const [{ users }] = useAppState();
  const [user, setUser] = useState();
  const [userRole, setUserRole] = useState();
  const [hovering, setHovering] = useState();
  const [addingUser, setAddingUser] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [, modalDispatch] = useModalState();

  const handleCloseModal = () => {
    setShowModal(false);
    onCreateContactDialogDismissed();
  };
  const handleAddUser = useCallback(
    (val) => {
      const usrRef = val?.value ?? user?.value;
      const tempUser = userOptions?.find((usr) => usr.value === usrRef);
      const usrRole = userRole?.value;
      const newUser = {
        ...tempUser,
        position: usrRole,
        user: usrRef,
      };
      onAddUser([...userList, newUser]);
      setUser();
      setUserRole();
      setAddingUser(false);
    },
    [onAddUser, user?.value, userList, userOptions, userRole?.value]
  );

  const handleUserSelect = useCallback(
    (val) => {
      if (roleOptions.length) {
        setAddingUser(true);
        setUser(val);
      } else {
        handleAddUser(val);
      }
    },
    [handleAddUser, roleOptions.length]
  );

  const handleAddNewUser = useCallback(
    (val) => {
      const newUser = {
        ...val,
        position: userRole?.value,
      };

      if (roleOptions.length) {
        handleUserSelect(val);
      } else {
        onAddUser([newUser, ...userList]);
        setUser();
        setUserRole();
        setAddingUser(false);
      }
    },
    [handleUserSelect, onAddUser, roleOptions.length, userList, userRole?.value]
  );

  const selectUpdate = useMemo(
    () => ({
      update: updateSelect,
      add: (val) => handleAddNewUser(val),
    }),
    [handleAddNewUser, updateSelect]
  );

  const handleShowCreateModal = useCallback(() => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: uuidv4() },
      modalData: {
        contactType: createContactType || "contact",
        updateSelect: selectUpdate,
      },
      modalType: CREATE_CONTACT_MODAL,
    });
  }, [createContactType, modalDispatch, selectUpdate]);

  const handleRoleSelect = (val) => {
    setUserRole(val);
  };
  const handleCancelClick = () => {
    onCancelClick();
    setAddingUser(false);
    setUser();
    setUserRole();
  };
  const handleRemoveMember = (ref) => {
    onRemoveUser(ref);
    setUser();
    setUserRole();
  };
  const handleDeleteHover = (mouseOver, id) => {
    if (mouseOver) {
      setHovering(id);
    } else {
      setHovering(false);
    }
  };
  const getrole = (usr) => {
    const roleId = usr.position ?? usr.role?.split("/")[1];
    if (defaultRole) {
      return defaultRole;
    }
    if (showCurrentRole && roleId) {
      return (
        roleOptions?.find((item) => item.value === roleId)?.label ?? usr.role
      );
    }
    if (showCurrentRole) {
      return usr.role;
    }
    return roleOptions?.find((item) => item.value === roleId)?.label;
  };

  // eslint-disable-next-line consistent-return
  const getAssociatedUsers = (contactUser) => {
    if (users?.length > 0 && contactUser) {
      const companyAssociatedUsers = users?.filter(
        (c) =>
          c?.company?.value &&
          c?.company?.value === contactUser?.value &&
          !c.metadata.deletedAt
      );
      return companyAssociatedUsers?.map((usr) => ({
        label: `${usr?.name?.firstName} ${usr?.name?.lastName}`,
        name: {
          firstName: usr?.name?.firstName,
          lastName: usr?.name?.lastName,
        },
        title: usr?.title,
        value: usr?.id,
        contactPoint: usr?.contactPoint,
        avatar: usr?.avatar,
        isMember: usr?.kind === "member",
        titleCN: "text-black text-xs",
        lastActive: usr?.lastActive,
        isOnline: usr?.isOnline,
      }));
    }
  };

  const navigate = useCallback(
    (usr) => {
      if (isEmployeeOrAdmin(currentUser)) {
        history.push(GET_CONTACT_PATH(usr?.id, "0"));
      }
    },
    [currentUser, history]
  );

  return (
    <div className="flex items-center w-full">
      <div className={className}>
        {!!userList?.length && (
          <div
            className={`${overflowY} ${avatarContainerClassName}
             ${displayUsersBelowDropdown && "order-last"} flex flex-col w-full`}
          >
            {userList?.map((usr) => (
              <div key={usr.value} className="w-full">
                <div
                  className="flex justify-between items-center w-full mb-2"
                  onMouseOver={() => handleDeleteHover(true, usr.value)}
                  onMouseOut={() => handleDeleteHover(false)}
                  onFocus={() => handleDeleteHover(true, usr.value)}
                  onBlur={() => handleDeleteHover(false)}
                >
                  {usr.kind === "company" && (
                    <ContactDetails
                      contactDetails={usr}
                      associatedUsers={getAssociatedUsers(usr)}
                      isPropertyVendor={isPropertyVendor}
                      isBrokerCompany={isBrokerCompany}
                    />
                  )}
                  {usr.kind !== "company" && (
                    <div
                      className={`flex flex-1 items-center ${
                        isFinancials && "py-6"
                      }`}
                    >
                      <Avatar
                        className={avatarClassName ?? "w-9 h-9 mr-2"}
                        fontClassName={avatarFontClassName ?? "text-md"}
                        isExternalUser
                        avatar={usr?.avatar}
                        id={usr?.id}
                        name={usr?.name}
                        kind={usr?.kind}
                      />
                      <div className="flex flex-col flex-1">
                        <div className="flex justify-start items-center">
                          <SecondaryHeader
                            className={
                              nameHeaderClassName ??
                              "text-xs font-medium text-ellipsis"
                            }
                            textSize={nameHeaderTextSize}
                            fontWeight={nameHeaderFontWeight}
                          >
                            {usr.firstName &&
                              usr.lastName &&
                              `${usr.firstName[0].toUpperCase()}` +
                                `${usr.firstName.substring(1)}` +
                                (` ${usr.lastName[0].toUpperCase()}` +
                                  `${usr.lastName.substring(1)}`)}
                            {!(usr.firstName && usr.lastName) && usr.label}
                          </SecondaryHeader>
                          {isFinancials && (
                            <BaseButton
                              iconRight={
                                <img
                                  src={link}
                                  alt="link"
                                  className="w-5 h-5"
                                />
                              }
                              innerClassName="whitespace-nowrap text-black font-semibold mr-1"
                              className="ml-3"
                              onClick={() => navigate(usr)}
                            />
                          )}
                        </div>
                        <TertiaryHeader className="">
                          {getrole(usr) ?? ""}
                        </TertiaryHeader>
                      </div>
                    </div>
                  )}
                  {userList?.length > 0 && (
                    <button
                      className={`${deleteButtonCN} ${
                        (hovering === usr.value && !disableRemove) ||
                        (isSingleSelect && !disableRemove)
                          ? ""
                          : "invisible"
                      }`}
                      type="button"
                      onClick={() => handleRemoveMember(usr.value)}
                    >
                      <img
                        className="w-5 cursor-pointer"
                        src={deleteIcon}
                        alt="delete user"
                      />
                    </button>
                  )}
                </div>

                <div className="col-span-2" />
              </div>
            ))}
          </div>
        )}
        {!disabled &&
          !addingUser &&
          (isSingleSelect ? !userList?.length : !!userList) && (
            <div
              className={`flex-1 flex items-center ${
                displayUsersBelowDropdown && "mb-2"
              }`}
            >
              <div className="flex-1 flex">
                <div className="flex-3">
                  {required && !hideSearchMembers && (
                    <Dropdown
                      className="w-full"
                      label={`${userLabel} `}
                      placeholder={userPlaceholder ?? "Select"}
                      value={user}
                      options={userOptions?.filter(
                        (item) =>
                          !userList?.find((usr) => usr.value === item.value)
                      )}
                      onChange={handleUserSelect}
                      forwardedRef={dropdownRef}
                      isDisabled={!userOptions?.length}
                      validation={yup.mixed().required()}
                    />
                  )}
                  {!required && !hideSearchMembers && (
                    <Dropdown
                      className="w-full"
                      label={userLabel ?? ""}
                      placeholder={userPlaceholder ?? "Select"}
                      value={user}
                      options={userOptions?.filter(
                        (item) =>
                          !userList?.find((usr) => usr.value === item.value)
                      )}
                      onChange={handleUserSelect}
                      forwardedRef={dropdownRef}
                      isDisabled={!userOptions?.length}
                      size={size}
                    />
                  )}
                </div>
                {!disableCreateUser && (
                  <div className="flex flex-col justify-between ml-4">
                    <div className="ESInputLabel invisible">Invisible</div>
                    <div className="flex self-start">
                      <SecondaryHeader className="flex-0 self-start mb-2 mt-1 mr-4">
                        or
                      </SecondaryHeader>
                      <SecondaryButton
                        className="flex-initial self-start h-10 mr-auto"
                        title={`+ ${buttonText ?? "Add Contact"}`}
                        onClick={handleShowCreateModal}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        {addingUser && roleOptions && (
          <>
            <div className="flex flex-col mt-4 ml-4">
              <div className="flex">
                <div className="relative top-12 right-8 w-0 h-0">
                  {!hideSearchPositions && (
                    <div className="w-6 h-20 border border-r-0 border-gray-200">
                      <div
                        className={`relative top-16 left-3 mt-2 rounded-full h-3 w-3 ${
                          user ? "bg-brandGreen" : "bg-gray-200"
                        }`}
                      />
                    </div>
                  )}
                </div>
                <div className="w-full">
                  <Input
                    placeholder="Label"
                    className=""
                    label={userLabel}
                    value={user?.label}
                    onChange={() => {}}
                    disabled
                  />
                </div>
              </div>
            </div>
            {!hideSearchPositions && (
              <div
                className={`flex justify-end ml-4 ${
                  roleLabel ? "mt-3" : "mt-9"
                }`}
              >
                <div className="flex-1 mt-auto">
                  <Dropdown
                    className=""
                    label={roleLabel ?? ""}
                    options={roleOptions}
                    value={userRole}
                    placeholder={rolePlaceholder ?? "Select"}
                    onChange={handleRoleSelect}
                  />
                </div>
              </div>
            )}
            <div className="flex justify-end w-full mt-6 ml-4">
              <div className="flex pr-4">
                <TertiaryButton title="Cancel" onClick={handleCancelClick} />
                <PrimaryButton
                  className="ml-4"
                  title="Add"
                  disabled={!user?.label}
                  onClick={handleAddUser}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <ReactModal
        style={modalStyles}
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      >
        <div className="flex flex-col w-full h-full border rounded">
          <div className="">
            <CreateContact
              contactType={userType}
              actionOverride={handleCloseModal}
              inviteNewContact={inviteNewContact}
              updateSelect={selectUpdate}
            />
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

SelectUserInterface.propTypes = {
  /**
   * Avatar className
   */
  avatarClassName: PropTypes.string,
  /**
   * container className
   */
  className: PropTypes.string,
  /**
   * type of user select
   */
  userType: PropTypes.string,
  /**
   * list of selected userOptions or single user
   */
  userList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      role: PropTypes.string,
      avatar: PropTypes.shape({}),
    })
  ),
  /**
   * user dropdown label text
   */
  userLabel: PropTypes.string,
  /**
   * user dropdown placeholder text
   */
  userPlaceholder: PropTypes.string,
  /**
   * role dropdown label text
   */
  roleLabel: PropTypes.string,
  /**
   * role dropdown placeholder text
   */
  rolePlaceholder: PropTypes.string,
  /**
   * default user role for when not selecting role
   */
  defaultRole: PropTypes.string,
  /**
   * show userOptions current role if it exists
   */
  showCurrentRole: PropTypes.bool,
  /**
   * add contact button text
   */
  buttonText: PropTypes.string,
  /**
   * user dropdown options
   */
  userOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      role: PropTypes.string,
      avatar: PropTypes.shape({}),
    })
  ),
  /**
   * user role dropdown options
   */
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  /**
   * function called on add user action
   */
  onAddUser: PropTypes.func,
  /**
   * function called on remove user action
   */
  onRemoveUser: PropTypes.func,
  onCancelClick: PropTypes.func,
  /**
   * allow only single selection
   */
  isSingleSelect: PropTypes.bool,

  inviteNewContact: PropTypes.bool,
  /**
   * hide the search members bar if all contacts are in the list
   */

  hideSearchMembers: PropTypes.bool,

  /**
   * hide the search position DD if dealing with vendors
   */
  hideSearchPositions: PropTypes.bool,

  /**
   * disable delete functionality
   */
  disableRemove: PropTypes.bool,

  /**
   * disable user create functionality
   */
  disableCreateUser: PropTypes.bool,
  onCreateContactDialogDismissed: PropTypes.func,
  /**
   * Update with newly created contact
   */
  updateSelect: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  dropdownRef: PropTypes.object,
  createContactType: PropTypes.string,
  /**
   * Size: md, lg
   * passed into dropdown(for Modal Dropdowns)
   */
  size: PropTypes.string,
  nameHeaderClassName: PropTypes.string,
  nameHeaderTextSize: PropTypes.string,
  avatarContainerClassName: PropTypes.string,
  overflowY: PropTypes.string,
  avatarFontClassName: PropTypes.string,
  nameHeaderFontWeight: PropTypes.string,
  displayUsersBelowDropdown: PropTypes.bool,
  isPropertyVendor: PropTypes.bool,
  isBrokerCompany: PropTypes.bool,
  isFinancials: PropTypes.bool,
};

SelectUserInterface.defaultProps = {
  avatarClassName: undefined,
  avatarContainerClassName: undefined,
  overflowY: undefined,
  hideSearchMembers: false,
  hideSearchPositions: false,
  className: undefined,
  userType: undefined,
  userList: [],
  userLabel: undefined,
  userPlaceholder: undefined,
  roleLabel: undefined,
  rolePlaceholder: undefined,
  defaultRole: undefined,
  showCurrentRole: false,
  buttonText: undefined,
  userOptions: [],
  roleOptions: [],
  onAddUser: undefined,
  onRemoveUser: undefined,
  onCancelClick: undefined,
  isSingleSelect: false,
  inviteNewContact: false,
  disableRemove: false,
  disableCreateUser: false,
  onCreateContactDialogDismissed: () => {},
  updateSelect: undefined,
  required: false,
  disabled: false,
  dropdownRef: undefined,
  createContactType: undefined,
  size: undefined,
  nameHeaderClassName: undefined,
  nameHeaderTextSize: undefined,
  avatarFontClassName: undefined,
  nameHeaderFontWeight: undefined,
  displayUsersBelowDropdown: false,
  isPropertyVendor: false,
  isBrokerCompany: false,
  isFinancials: false,
};

export default SelectUserInterface;

import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
// import { retrieveToken } from "../../../firebaseInit";
import useSystemConfiguration from "../../../hooks/useSystemConfiguration";

import ESLogo from "../../assets/images/es-logo.png";
import greenChatIcon from "../../assets/images/greenChatIcon.svg";
import greenNotificationBell from "../../assets/images/greenNotificationBell.svg";
import TodoIcon from "../../assets/images/TodoIcon.svg";
import downArrow from "../../assets/images/downArrow.svg";
import serviceBell from "../../assets/images/serviceBell.svg";

import TopNavBarIcon from "./TopNavBarIcon";
import WorldClock from "./WorldClock";
import { useAppState } from "../../../state/appState";
import { useESChatContext } from "../../../context/ChatContext/ESChatContext";
import SimpleAvatar from "../Avatar/SimpleAvatar";
import { ENVIRONMENTS, SET_REQUEST_MODAL_STATUS } from "../../../constants";
import useNotifications from "../../../hooks/useNotifications";
import useTodos from "../../../hooks/useTodos";
import getEnvironment from "../../../helpers/Environment";

const containerCN = cntl`
  flex
  top-navbar
  items-center
  justify-between
  relative
  px-3
`;

const logoParentContainerCN = cntl`
  m-3
  ml-0
  flex
  items-center
  border-r
  border-gray-200
`;

const logoContainerCN = cntl`
  flex
  items-center
  cursor-pointer
  ml-3
  mr-12
`;

const logoCN = cntl`
  rounded-full
  h-12
`;

const estatespaceTextCN = cntl`
  font-semibold
  ml-2
  text-lg
  text-white
  tracking-widest
`;

const actionIconContainerCN = cntl`
  relative
  flex
  items-center
  justify-center
`;

const menuContainerCN = cntl`
  top-nav-user-menu
  bg-white
  py-2
  border 
  border-gray-100
  shadow-md
  rounded
  absolute 
  top-full 
  right-0
  mt-2
  z-50 
`;

const getTitle = () => {
  const envName = getEnvironment();
  switch (envName) {
    case ENVIRONMENTS.production:
      return "ESTATESPACE";
    case ENVIRONMENTS.feature:
      return "ESTATESPACE (Feature)";
    case ENVIRONMENTS.staging:
      return "ESTATESPACE (Staging)";
    case ENVIRONMENTS.localhost:
      return "ESTATESPACE (localhost)";
    default:
      return "ESTATESPACE";
  }
};

const TopNavBar = ({
  onLogoClick,
  showMenu,
  onShowMenuClick,
  headerMenuItems,
  hideMessages,
  hideNotifications,
  hideTodos,
  hideServiceTicket,
  showMessages,
  setShowMessages,
  showNotifications,
  setShowNotifications,
  showTodo,
  setShowTodo,
}) => {
  // const [currentToken, setCurrentToken] = useState(null);
  const { clientChat: client, unread } = useESChatContext();
  const { data: todos } = useTodos();
  const { data: notifications = [] } = useNotifications();

  const { data: systemConfiguration } = useSystemConfiguration();
  const [, dispatch] = useAppState();

  const todosCount = useMemo(() => todos?.length ?? 0, [todos?.length]);

  // retrieveToken(currentToken, setCurrentToken);

  const notificationCount = useMemo(() => {
    return notifications.reduce((count, item) => {
      const addAmount = item.read ? 0 : 1;
      return count + addAmount;
    }, 0);
  }, [notifications]);

  const onLogoKeyDown = (event) => {
    if (event.key === "Enter") {
      onLogoClick();
    }
  };

  const handleShowSideBar = useCallback(
    (val) => {
      switch (val) {
        case "messaging":
          if (showMessages) {
            setShowMessages(false);
          } else {
            setShowNotifications(false);
            setShowTodo(false);
            setShowMessages(true);
          }
          break;
        case "todo":
          if (showTodo) {
            setShowTodo(false);
          } else {
            setShowNotifications(false);
            setShowMessages(false);
            setShowTodo(true);
          }
          break;
        case "ticket":
          setShowNotifications(false);
          setShowMessages(false);
          setShowTodo(false);

          dispatch({
            type: SET_REQUEST_MODAL_STATUS,
            open: true,
          });

          break;
        default:
          if (showNotifications) {
            setShowNotifications(false);
          } else {
            setShowMessages(false);
            setShowTodo(false);
            setShowNotifications(true);
          }
      }
    },
    [
      showMessages,
      showTodo,
      setShowNotifications,
      setShowMessages,
      setShowTodo,
      dispatch,
      showNotifications,
    ]
  );

  return (
    <div className={containerCN} style={{ zIndex: 9989 }}>
      <div className={logoParentContainerCN}>
        <div
          className={logoContainerCN}
          onClick={onLogoClick}
          onKeyDown={onLogoKeyDown}
          role="button"
          tabIndex={0}
        >
          <img className={logoCN} alt="ESLogo" src={ESLogo} />
          <p className={estatespaceTextCN}>{getTitle()}</p>
        </div>
      </div>
      <WorldClock systemConfiguration={systemConfiguration} />
      <div className={actionIconContainerCN}>
        <TopNavBarIcon
          className="mr-11"
          icon={serviceBell}
          iconClassName="w-6 h-6"
          amount={0}
          hidden={hideServiceTicket}
          onClick={() => handleShowSideBar("ticket")}
        />

        <TopNavBarIcon
          className="mr-12"
          icon={TodoIcon}
          iconClassName="w-5 h-5"
          amount={todosCount}
          hidden={hideTodos}
          onClick={() => handleShowSideBar("todo")}
        />

        <TopNavBarIcon
          className="mr-12"
          icon={greenNotificationBell}
          iconClassName="w-5 h-5"
          amount={notificationCount}
          hidden={hideNotifications}
          onClick={() => handleShowSideBar("notifications")}
        />

        <TopNavBarIcon
          icon={greenChatIcon}
          iconClassName="w-5 h-5"
          amount={unread}
          hidden={hideMessages && !client}
          onClick={() => handleShowSideBar("messaging")}
        />

        <button
          onClick={onShowMenuClick}
          type="button"
          className="ml-12 border-l border-gray-200"
        >
          <div className="flex items-center mr-3 ml-5">
            <SimpleAvatar topNav />
            <img src={downArrow} alt="chevron" className="ml-2" />
          </div>
        </button>

        {showMenu && <div className={menuContainerCN}>{headerMenuItems}</div>}
      </div>
    </div>
  );
};

TopNavBar.propTypes = {
  /**
   * function called when the estatespace logo is clicked
   */
  onLogoClick: PropTypes.func,
  /**
   * Current User Object
   */
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    reference: PropTypes.string,
  }),
  /**
   * if true, displays the header dropdown menu
   */
  showMenu: PropTypes.bool,
  /**
   * function called to toggle the display of the header dropdown menu
   */
  onShowMenuClick: PropTypes.func,
  /**
   * JSX to display inside header dropdownmenu
   */
  headerMenuItems: PropTypes.element,
  hideMessages: PropTypes.bool,
  hideNotifications: PropTypes.bool,
  hideTodos: PropTypes.bool,
  hideServiceTicket: PropTypes.bool,
  showMessages: PropTypes.bool,
  setShowMessages: PropTypes.func,
  showNotifications: PropTypes.bool,
  setShowNotifications: PropTypes.func,
  showTodo: PropTypes.bool,
  setShowTodo: PropTypes.func,
};

TopNavBar.defaultProps = {
  onLogoClick: null,
  currentUser: undefined,
  showMenu: false,
  onShowMenuClick: undefined,
  headerMenuItems: undefined,
  hideMessages: false,
  hideNotifications: false,
  hideTodos: false,
  hideServiceTicket: false,
  showMessages: false,
  setShowMessages: undefined,
  showNotifications: false,
  setShowNotifications: undefined,
  showTodo: false,
  setShowTodo: undefined,
};

export default TopNavBar;

import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import Dropdown from "../Dropdown/Dropdown";

const customStyle = {
  control: {
    borderWidth: "0px",
    fontWeight: 700,
    letterSpacing: "0.1em",
  },
  singleValue: {
    color: "#636363",
  },
  valueContainer: {
    paddingLeft: "0rem",
  },
};

const innerPageSwitcherCN = (className) => cntl`
  w-75
  z-20
  ${className}
`;

const InnerPageSwitcher = ({
  innerRoutes,
  currentPath,
  history,
  className,
  dropDownIgnoreList,
  timeOffDisableSort,
  ...props
}) => {
  const options = innerRoutes?.map((route) => ({
    label: route.name,
    value: route.path,
    subValue: route?.subPath,
    isBeta: route.isBeta,
  }));

  const formatOptionLabel = (prop) => {
    const { label, isBeta, value } = prop;
    return (
      <div className="flex flex-row justify-between">
        <div>{label}</div>
        {isBeta && (
          <div
            className={`text-xs font-semibold border rounded-3xl inline-block py-1 px-2 tracking-wider ${
              value === currentPath
                ? "text-white border-white"
                : "text-brandGreen border-brandGreen"
            }`}
            style={{ fontSize: "12px" }}
          >
            BETA
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={innerPageSwitcherCN(className)}>
      <Dropdown
        value={options.find(
          (route) =>
            route.value === currentPath || route?.subValue === currentPath
        )}
        options={options?.filter(
          (item) => !dropDownIgnoreList?.includes(item.label)
        )}
        formatOptionLabel={formatOptionLabel}
        onChange={({ value }) => history.push(value)}
        disableClear
        disableSearch
        disableSort={timeOffDisableSort}
        customStyle={customStyle}
        hideIndicator
        maxMenuHeight={400}
        {...props}
      />
    </div>
  );
};

InnerPageSwitcher.propTypes = {
  /**
   * array of child nav items
   * expected to containe name and path props
   */
  innerRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    })
  ),

  /**
   * path for pre-selecting the current match
   */
  currentPath: PropTypes.string,

  /**
   * react router history object
   * with push method for navigating on change
   */
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  className: PropTypes.string,
  /**
   * Ignore Routes In Site Header DropDown
   */
  dropDownIgnoreList: PropTypes.arrayOf(PropTypes.string),
  /**
   * Disable sorting for timeOff siteheader dropdown
   */
  timeOffDisableSort: PropTypes.bool,
};

InnerPageSwitcher.defaultProps = {
  innerRoutes: [],
  currentPath: undefined,
  history: undefined,
  className: "",
  dropDownIgnoreList: [],
  timeOffDisableSort: false,
};

export default InnerPageSwitcher;

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import { v4 as uuidv4 } from "uuid";

import Dropdown from "../Dropdown/Dropdown";
import Checkbox from "../Checkbox/Checkbox";
import Input from "../Input/Input";

import attachmentIcon from "../../assets/images/attachmentIcon.svg";
import collapseIcon from "../../assets/images/collapseIcon.svg";
import crossIcon from "../../assets/images/crossIcon.svg";
import checkGreenIcon from "../../assets/images/circleCheckGreenIcon.svg";
import { getFileTypeKey } from "../../../helpers/File";

const deleteButtonCN = () => cntl`
  ml-2
  w-5
  h-5
`;
const editButtonCN = () => cntl`
  ml-2
  w-6
  h-6
  mt-0
`;
const errorCN = cntl`
  flex
  items-center
  relative
  -top-3
  h-0
  text-brandRed
  italic
  overflow-visible
`;

const DroppedFile = ({
  file,
  defaultType,
  docTypeOptions,
  docTypeOptionsMap,
  onNameChange,
  onTypeChange,
  onFavorited,
  onRemove,
  onSaveNewType,
  disableFavorite,
  enableNewDocTypes,
}) => {
  const [hovering, setHovering] = useState(false);
  const [docName, setDocName] = useState(file.filename ?? file.name);
  const [docType, setDocType] = useState(defaultType);
  const [typeInput, setTypeInput] = useState();
  const [addingType, setAddingType] = useState();
  const [duplicate, setDuplicate] = useState();
  const [isFavorited, setIsFavorited] = useState();
  const inputRef = useRef(null);

  useEffect(() => {
    if (file && defaultType === "isFavorited" && !isFavorited) {
      setIsFavorited(true);
      onFavorited(file.id, true);
    }
  }, [file, defaultType, isFavorited, onFavorited]);

  useEffect(() => {
    if (addingType) {
      inputRef.current.focus();
    }
  }, [addingType]);

  useEffect(() => {
    if (file?.docType) {
      setDocType(file.docType);
    }
  }, [file?.docType]);

  const handleHover = (mouseOver) => {
    if (mouseOver) {
      setHovering(true);
    } else {
      setHovering(false);
    }
  };

  const handleFileNameChange = (val) => {
    setDocName(val);
    onNameChange(file.id, val, getFileTypeKey(file));
  };

  const handleFileTypeChange = (val) => {
    if (enableNewDocTypes && val.value === "addNewType") {
      setAddingType(true);
    } else {
      setDocType(val.value);
      onTypeChange(file.id, val.value, getFileTypeKey(file));
    }
  };

  const handleFavorited = () => {
    if (defaultType !== "isFavorited") {
      setIsFavorited((fav) => {
        const favorited = !fav;
        onFavorited(file.id, favorited, getFileTypeKey(file));
        return favorited;
      });
    }
  };

  const handleCancel = () => {
    setAddingType(false);
    setTypeInput();
    setDuplicate();
  };

  const handleSaveNewType = () => {
    const hasDuplicate = docTypeOptions.some(
      (type) => type.label.toLowerCase() === typeInput.trim().toLowerCase()
    );
    if (hasDuplicate) {
      setDuplicate(true);
      return;
    }

    const newType = { label: typeInput, value: uuidv4() };
    onSaveNewType(newType);
    onTypeChange(file.id, newType.value);
    setDocType(newType.value);
    handleCancel();
  };

  const handleTypeInput = (val) => {
    const tmpValue = val
      .split(" ")
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1));
    setTypeInput(tmpValue.join(" "));
    setDuplicate(false);
  };

  const handleEnter = (event) => {
    if (event.charCode === 13) {
      handleSaveNewType();
    }
  };

  const handleRemove = () => {
    onRemove(file.id, getFileTypeKey(file));
  };

  return (
    <>
      <div
        className="flex flex-col"
        onMouseOver={() => handleHover(true)}
        onMouseOut={() => handleHover(false)}
        onFocus={() => handleHover(true)}
        onBlur={() => handleHover(false)}
      >
        <div className="flex justify-between">
          <div className="flex items-center pl-1">
            <img className="mr-3" src={attachmentIcon} alt="attachment icon" />
            <p className="font-semibold text-gray-200">{docName}</p>
          </div>
          <button
            type="button"
            className={hovering && !disableFavorite ? "visible" : "invisible"}
            onClick={handleRemove}
          >
            <img src={collapseIcon} alt="remove file upload" />
          </button>
        </div>

        <div className="flex mt-4 pl-1 pb-6 border-b border-gray-300">
          <div className="flex-1 mr-4">
            <Input
              className=""
              label="Document Name"
              placeholder="Filename"
              value={docName}
              onChange={handleFileNameChange}
            />
          </div>

          <div className="flex-1 mr-4">
            {!addingType &&
              (!defaultType ||
                defaultType === "isDraft" ||
                (defaultType && !docTypeOptionsMap[docType])) && (
                <Dropdown
                  className=""
                  label="Document Type"
                  options={docTypeOptions}
                  value={docTypeOptionsMap[docType]}
                  onChange={handleFileTypeChange}
                  disableSort
                />
              )}

            {!addingType &&
              defaultType &&
              defaultType !== "isDraft" &&
              docTypeOptionsMap[docType] && (
                <Input
                  placeholder="Doc Type"
                  className=""
                  label="Document Type"
                  value={docTypeOptionsMap[docType].label}
                  disabled
                />
              )}

            {addingType && (
              <div className="flex flex-col">
                {duplicate && <p className={errorCN}>No Duplicate Types</p>}
                <Input
                  placeholder="Doc Type"
                  className=""
                  label="Document Type"
                  value={typeInput}
                  onChange={(val) => {
                    handleTypeInput(val.replace(/[^a-zA-Z0-9 ]/, ""));
                  }}
                  onKeyPress={handleEnter}
                  forwardedRef={inputRef}
                />
              </div>
            )}
          </div>

          {addingType && (
            <div className="flex items-center ml-1" style={{ width: "78px" }}>
              <div className="flex-0 mt-7">
                <button
                  className={editButtonCN()}
                  type="button"
                  onClick={handleSaveNewType}
                >
                  <img
                    className="cursor-pointer"
                    src={checkGreenIcon}
                    alt="edit data"
                  />
                </button>
              </div>
              <div className="flex-0 mt-7">
                <button
                  className={deleteButtonCN()}
                  type="button"
                  onClick={handleCancel}
                >
                  <img
                    className="cursor-pointer"
                    src={crossIcon}
                    alt="remove data"
                  />
                </button>
              </div>
            </div>
          )}

          {!addingType && !disableFavorite && (
            <Checkbox
              className="mt-auto mb-3 ml-1"
              label="Favorite"
              checked={isFavorited}
              onChange={handleFavorited}
            />
          )}
        </div>
      </div>
    </>
  );
};

DroppedFile.propTypes = {
  /**
   * file selected for upload
   */
  // eslint-disable-next-line react/forbid-prop-types
  file: PropTypes.object,
  /**
   * default document type
   */
  defaultType: PropTypes.string,
  /**
   * doc type options
   */
  // eslint-disable-next-line react/forbid-prop-types
  docTypeOptions: PropTypes.array,
  /**
   * doc type options map
   */
  // eslint-disable-next-line react/forbid-prop-types
  docTypeOptionsMap: PropTypes.object,
  /**
   * function called on file name change
   */
  onNameChange: PropTypes.func,
  /**
   * function called on file type change
   */
  onTypeChange: PropTypes.func,
  /**
   * function called on file favorited
   */
  onFavorited: PropTypes.func,
  /**
   * function called on file remove
   */
  onRemove: PropTypes.func,
  /**
   * function called to create new type
   */
  onSaveNewType: PropTypes.func,
  disableFavorite: PropTypes.bool,
  enableNewDocTypes: PropTypes.bool,
};

DroppedFile.defaultProps = {
  file: undefined,
  defaultType: undefined,
  docTypeOptions: [],
  docTypeOptionsMap: {},
  onNameChange: () => {},
  onTypeChange: () => {},
  onFavorited: () => {},
  onRemove: () => {},
  onSaveNewType: () => {},
  disableFavorite: false,
  enableNewDocTypes: false,
};

export default DroppedFile;

import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { PreferenceAPI } from "@griffingroupglobal/eslib-api";
import ContactEditForm from "../../stories/Components/ContactForm/ContactEditForm";
import useContact from "../../hooks/useContact";
import useCompanyContacts from "../../hooks/useCompanyContacts";
import useConfigurationLastUpdated from "../../hooks/useConfigurationLastUpdated";

/**
 * @deprecated: Remove this file later
 */
const EditContact = ({
  systemConfiguration,
  unassignedUsers,
  getEntityAssigned,
  managementConfiguration,
  editing,
  setEditing,
  activeIndex,
  setTabIndex,
  setEditingContact,
  setPreferencesUpdated,
  reloadAssignedUsers,
  reloadUsers,
}) => {
  const { contactId, currentTabIndex } = useParams();
  const [contactUser, setContactUser] = useState();
  const [preferences, setPreferences] = useState();
  const [preferencesOptions, setPreferenceOptions] = useState();
  const [companyContacts, setCompanyContacts] = useState();
  const [originalCompanyAssignees, setOriginalCompanyAssignees] = useState();
  const [companyAssignees, setCompanyAssignees] = useState([]);
  const [unassignedContacts, setUnassignedContacts] = useState();
  const contactParams = useMemo(() => [contactId], [contactId]);
  const [contacts] = useContact(contactParams);
  const [entityContacts] = useCompanyContacts();
  const [loadingPreferences, setLoadingPreferences] = useState(true);

  const [configurationLastUpdated] = useConfigurationLastUpdated();

  useEffect(() => {
    if (systemConfiguration) {
      setPreferenceOptions(systemConfiguration.system.preferences);
    }
  }, [systemConfiguration]);

  useEffect(() => {
    if (contacts && !contactUser) {
      setContactUser(contacts[0]);
    }
    if (entityContacts && !companyContacts) {
      setCompanyContacts(entityContacts);
    }
    if (unassignedUsers && !unassignedContacts) {
      setUnassignedContacts(unassignedUsers);
    }

    const getPreference = async () => {
      const { data } = await PreferenceAPI.get({
        params: {
          user: `User/${contactId}`,
        },
      });
      setPreferences(data);
      setLoadingPreferences(false);
    };

    if (loadingPreferences) getPreference();
  }, [
    contactId,
    contacts,
    entityContacts,
    unassignedUsers,
    companyContacts,
    contactUser,
    unassignedContacts,
    loadingPreferences,
  ]);

  useEffect(() => {
    if (
      contactUser &&
      contactUser?.kind === "company" &&
      !companyAssignees?.length &&
      !originalCompanyAssignees
    ) {
      const assignees = getEntityAssigned(contactUser?.reference);

      setCompanyAssignees(assignees);
      setOriginalCompanyAssignees(assignees);
    }
  }, [
    contactUser,
    getEntityAssigned,
    companyAssignees,
    originalCompanyAssignees,
  ]);

  return (
    <ContactEditForm
      contactUser={contactUser}
      preferences={preferences}
      preferencesOptions={preferencesOptions}
      companyContacts={companyContacts}
      currentTabIndex={activeIndex ?? currentTabIndex}
      unassignedContacts={unassignedContacts}
      setUnassignedContacts={setUnassignedContacts}
      companyAssignees={companyAssignees}
      setCompanyAssignees={setCompanyAssignees}
      originalCompanyAssignees={originalCompanyAssignees}
      managementConfiguration={managementConfiguration}
      configurationLastUpdated={configurationLastUpdated}
      editing={editing}
      setEditing={setEditing}
      setTabIndex={setTabIndex}
      setEditingContact={setEditingContact}
      setPreferencesUpdated={setPreferencesUpdated}
      reloadAssignedUsers={reloadAssignedUsers}
      reloadUsers={reloadUsers}
    />
  );
};

export default EditContact;

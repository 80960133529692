import React from "react";

import useCurrentUser from "../../../hooks/useCurrentUser";
import ModalWrapper from "../EsModal/ModalWrapper";
import ProjectForm from "./ProjectForm";
import useProjectCreateModalData from "./useProjectCreateModalData";

const ProjectCreateModal = ({ modalData }) => {
  const { data: currentUser } = useCurrentUser();

  const {
    project,
    dispatch,
    showConfirm,
    createButtonHandler,
    tmpAvatarImg,
    setTmpAvatarImg,
  } = useProjectCreateModalData({ modalData });

  return (
    <ModalWrapper
      modalData={modalData}
      primaryButtonOnClick={createButtonHandler}
      disabled={!currentUser?.reference}
      showConfirm={showConfirm}
      resourceName="Project"
      title="Create Project"
      width="48rem"
      className="px-4"
    >
      <ProjectForm
        project={project}
        dispatch={dispatch}
        property={modalData.property}
        tmpAvatarImg={tmpAvatarImg}
        setTmpAvatarImg={setTmpAvatarImg}
      />
    </ModalWrapper>
  );
};

export default ProjectCreateModal;

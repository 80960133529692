import { toast } from "react-toastify";
import { TimesheetAPI } from "@griffingroupglobal/eslib-api";
import useAuthenticatedQuery from "../useAuthenticatedQuery";
import timesheetKeys from "./timesheetKeys";
import { APPROVED, FAILED_TS_FETCH } from "../../constants";

/**
 * Custom hook to fetch and manage payroll approved timesheets mapped by userRef.
 * Example return value:
 * {
 *  approvedTsByUser: {
 *   "userRef1": [{ periodStart: "2021-01-01", periodEnd: "2021-01-15" }, ...],
 *  "userRef2": [{ periodStart: "2021-01-03", periodEnd: "2021-01-5" }, ...],
 *
 * @property {Object} approvedTsByUser - The approved timesheets mapped by user ID.
 */

const useTsUserMap = () => {
  const getApprovedTs = async () => {
    const query = {
      payrollStatus: APPROVED,
    };

    const {
      data: { entries },
    } = await TimesheetAPI.get({ params: query });

    const userEntryMap = {};

    entries
      .filter(
        (entry) =>
          entry?.resource?.periodStart &&
          entry.resource.periodEnd &&
          entry?.resource?.userRef
      )
      .forEach((entry) => {
        const periodStartAndEnd = {
          periodStart: entry.resource.periodStart,
          periodEnd: entry.resource.periodEnd,
        };

        const userRef = entry.resource;

        if (!userEntryMap[userRef]) userEntryMap[userRef] = [];

        userEntryMap[userRef].push(periodStartAndEnd);
      });

    return userEntryMap;
  };

  const { data: approvedTsByUser } = useAuthenticatedQuery({
    queryKey: timesheetKeys.tsByUserMap,
    queryFn: getApprovedTs,
    onError: () => {
      toast.error(FAILED_TS_FETCH);
    },
    staleTime: 0,
  });

  return { approvedTsByUser };
};

export default useTsUserMap;

import React, { useCallback, useRef } from "react";
import cntl from "cntl";
import * as yup from "yup";
import PropTypes from "prop-types";
import Input from "../Input/Input";
import BaseButton from "../Buttons/BaseButton";
import { phoneRegExp } from "../../../helpers/Formatters";
import InfoTooltipSignup from "../InfoTooltip/InfoTooltipSignup";

const primaryButtonCN = cntl`
  rounded-md
  bg-es-green
  hover:bg-opacity-90
`;

const innerCN = (className) => cntl`
  ${className}
`;

const ContactInformation = ({
  contactInfo,
  onChangeContactInfo,
  userCountError,
  isContactComplete,
  handleNextBtn,
}) => {
  const inputRefs = useRef({});
  const btnRef = useRef(null);

  /**
   * Automatically moves cursor to next input field on pressing Enter
   */
  const handleEnter = useCallback(
    (event) => {
      // checks if the Enter key was pressed
      if (event.keyCode === 13) {
        const inputs = Object.keys(inputRefs.current);
        const currentInput = event.target.name;
        if (currentInput === "phone") {
          // nested conditional so that if currentInput is phone, Enter does nothing when !isComplete
          if (isContactComplete) btnRef.current.focus();
        } else {
          const nextInput = inputs[inputs.indexOf(currentInput) + 1];
          inputRefs.current[`${nextInput}`].focus();
        }
      }
    },
    [isContactComplete]
  );

  return (
    <form name="contact-information">
      <div className="flex flex-col gap-4">
        <div className={innerCN("grid grid-cols-2 gap-4")}>
          <Input
            placeholder="First Name"
            label="First Name"
            labelClassName="text-es-dark-grey text-es-normal pb-2 font-es-semibold"
            mainClassName="col-span-12 text-es-normal"
            value={contactInfo?.firstName}
            onChange={(val) => onChangeContactInfo(val, "firstName")}
            validation={yup.string()}
            // eslint-disable-next-line no-return-assign
            forwardedRef={(el) => (inputRefs.current.firstName = el)}
            handleEnter={(event) => handleEnter(event)}
            name="firstName"
          />
          <Input
            placeholder="Last Name"
            label="Last Name"
            labelClassName="text-es-dark-grey text-es-normal pb-2 font-es-semibold"
            value={contactInfo?.lastName}
            mainClassName="col-span-12 text-es-normal"
            onChange={(val) => onChangeContactInfo(val, "lastName")}
            validation={yup.string()}
            // eslint-disable-next-line no-return-assign
            forwardedRef={(el) => (inputRefs.current.lastName = el)}
            handleEnter={handleEnter}
            name="lastName"
          />
        </div>
        <div className={innerCN("grid grid-cols-2 gap-4")}>
          <Input
            placeholder="Workspace Name"
            label={
              <div className="flex flex-row items-center relative">
                <p className="text-es-dark-grey text-es-normal font-es-semibold">
                  Workspace Name
                </p>
                <InfoTooltipSignup title="This name is used for push notifications on mobile and web" />
              </div>
            }
            labelClassName="text-es-dark-grey text-es-normal pb-2 font-es-semibold"
            value={contactInfo?.workspaceName}
            mainClassName="col-span-12 text-es-normal"
            onChange={(val) => onChangeContactInfo(val, "workspaceName")}
            // eslint-disable-next-line no-return-assign
            forwardedRef={(el) => (inputRefs.current.workspaceName = el)}
            handleEnter={(event) => handleEnter(event)}
            name="workspaceName"
          />
          <Input
            placeholder="Company Name"
            label="Company"
            labelClassName="text-es-dark-grey text-es-normal pb-2 font-es-semibold"
            mainClassName="col-span-12 text-es-normal"
            value={contactInfo?.company}
            onChange={(val) => onChangeContactInfo(val, "company")}
            validation={yup.string()}
            // eslint-disable-next-line no-return-assign
            forwardedRef={(el) => (inputRefs.current.company = el)}
            handleEnter={(event) => handleEnter(event)}
            name="company"
          />
        </div>
        <div className={innerCN("grid grid-cols-2 gap-4 items-start")}>
          <Input
            placeholder="Email"
            label="Email"
            labelClassName="text-es-dark-grey text-es-normal pb-2 font-es-semibold"
            value={contactInfo?.email}
            mainClassName="col-span-12 text-es-normal"
            errorMessageClassName="whitespace-nowrap"
            onChange={(val) => onChangeContactInfo(val, "email")}
            validation={yup.string().email()}
            // eslint-disable-next-line no-return-assign
            forwardedRef={(el) => (inputRefs.current.email = el)}
            handleEnter={(event) => handleEnter(event)}
            name="email"
            showValidationErrorAtBottom
          />
          <Input
            placeholder="Phone Number"
            label="Mobile Phone Number"
            labelClassName="text-es-dark-grey text-es-normal pb-2 font-es-semibold"
            mainClassName="col-span-12 text-es-normal"
            value={contactInfo?.phone}
            errorMessageClassName="whitespace-nowrap"
            onChange={(val) => onChangeContactInfo(val, "phone")}
            validation={yup
              .string()
              .matches(phoneRegExp, "Phone number is not valid")}
            // eslint-disable-next-line no-return-assign
            forwardedRef={(el) => (inputRefs.current.phone = el)}
            handleEnter={(event) => handleEnter(event)}
            name="phone"
            showValidationErrorAtBottom
            isPhoneNumber
          />
        </div>
      </div>
      <div className={innerCN("justify-end mt-6")}>
        {!userCountError && (
          <div className="flex flex-col">
            <div className="flex justify-end">
              <BaseButton
                style={{ minWidth: "156px", minHeight: "48px" }}
                title="Next"
                className={primaryButtonCN}
                ref={btnRef}
                onClick={handleNextBtn}
                disabled={!isContactComplete}
                // eslint-disable-next-line no-return-assign
                forwardedRef={(el) => (btnRef.current = el)}
              />
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

ContactInformation.propTypes = {
  contactInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    title: PropTypes.string,
    company: PropTypes.string,
    phone: PropTypes.string,
    workspaceName: PropTypes.string,
  }),
  onChangeContactInfo: PropTypes.func,
  userCountError: PropTypes.string,
  isContactComplete: PropTypes.bool,
  handleNextBtn: PropTypes.func,
};

ContactInformation.defaultProps = {
  contactInfo: undefined,
  onChangeContactInfo: undefined,
  userCountError: undefined,
  isContactComplete: false,
  handleNextBtn: undefined,
};

export default ContactInformation;

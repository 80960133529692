import React from "react";
import placeholder from "../../../assets/images/placeholderImage.png";

const ImagePreview = ({ fileUrl }) => {
  return (
    <img
      src={fileUrl || placeholder}
      className="rounded-md h-full w-full"
      alt={fileUrl}
      style={{
        objectFit: "cover",
      }}
    />
  );
};

export default ImagePreview;

import React from "react";
import PropTypes from "prop-types";
import RequestInfoCard from "../RequestInfoCard/RequestInfoCard";

export default function RequestInfoList({ rows, rowWrapperCN }) {
  return (
    <div className={rowWrapperCN}>
      {rows.map((row, index) => {
        return (
          <RequestInfoCard
            customElementCN={row.customElementCN}
            title={row.title}
            value={row.value}
            distroList={row.distroList}
            distroListOptions={row.distroListOptions}
            last={row.last}
            first={row.ignoreFirst ? false : index === 0}
            key={row.title}
            impactValue={row.impactValue}
            resultComment={row.resultComment}
            editing={row.editing}
            form={row.form}
            dispatch={row.dispatch}
            associations={row.associations}
          />
        );
      })}
    </div>
  );
}

RequestInfoList.propTypes = {
  /* Rows will be mapped over, and display a title and value, if the value is an array... will hanlde later */
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    })
  ),
  rowWrapperCN: PropTypes.string,
};

RequestInfoList.defaultProps = {
  rows: [],
  rowWrapperCN: undefined,
};

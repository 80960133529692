// Framework Tools
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { getFullName } from "../../../../helpers/Formatters";

export default function RequestHistoryActivityCell({
  history,
  commentMap,
  stepDict,
  getUserInfo,
}) {
  const [stepData, setStepData] = useState({});
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    const stepId = history?.context?.submittal?.step?.id;
    const step = stepDict[stepId];
    setStepData(step);
  }, [history?.context?.submittal?.step?.id, stepDict]);

  useEffect(() => {
    const data = getUserInfo(history?.user);
    setUserInfo(data);
  }, [getUserInfo, history?.user]);

  return (
    <div className="flex my-4 flex-col">
      <div className="p-4 flex flex-col">
        <p>
          {history?.action === "Comment Posted"
            ? "Remark Posted"
            : history?.action}{" "}
          by: {getFullName(userInfo?.name)} on:{" "}
          {moment(history?.when).format("MMMM, DD, YYYY")}
        </p>
        <div>
          {stepData &&
            stepData.comments &&
            stepData.comments.map((comment) => (
              <p key={comment}>{commentMap[comment]?.content}</p>
            ))}
        </div>
      </div>
    </div>
  );
}

// prop types
RequestHistoryActivityCell.propTypes = {
  requestData: PropTypes.shape({
    requestWorkflow: PropTypes.arrayOf(
      PropTypes.shape({ steps: PropTypes.arrayOf(PropTypes.shape({})) })
    ),
  }),
  commentMap: PropTypes.shape({}),
  history: PropTypes.shape({
    context: { submittal: {} },
    action: {},
    user: {},
    when: {},
  }),
  stepDict: PropTypes.shape({}),
  getUserInfo: PropTypes.func,
};

// default props
RequestHistoryActivityCell.defaultProps = {
  requestData: {
    requestWorkflow: { steps: [] },
  },
  getUserInfo: undefined,
  stepDict: undefined,
  commentMap: undefined,
  history: [
    {
      comment: undefined,
      user: {},
      context: { submittal: { step: undefined } },
    },
  ],
};

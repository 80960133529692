import { useAppState } from "../../../state/appState";
import { transformMentionFormat } from "../../../helpers/Comments";

const useTextWithMentionData = ({ comment }) => {
  const [{ userDict }] = useAppState();

  const { textWithMention, mentionData } = transformMentionFormat(
    comment.content,
    userDict
  );

  return {
    textWithMention,
    mentionData,
  };
};

export default useTextWithMentionData;

/**
 * Props for a row in the Roles Table in <RolesAndPermissions/>.
 * @see RolesAndPermissions
 */
export default class RolesTableRowProps {
  id;

  role;

  members;

  actionProps;

  constructor({ id, role, members, custom }) {
    this.id = id;
    this.role = role;
    this.members = members;
    this.actionProps = {
      custom,
      id,
      isAdmin: this.role.toLowerCase() === "admin",
    };
  }
}

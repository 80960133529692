import { useCallback, useMemo, useState } from "react";
import {
  usePropertiesDelete,
  usePropertiesTableFormat,
} from "../../../hooks/properties";
import useFavorites from "../../../hooks/useFavorites";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import useTemplatesConfiguration from "../../../hooks/useTemplatesConfiguration.new";
import { usePropertySwitchView } from "../../../hooks/useSwitchView";
import useTemplatesConfigurationPatch from "../../../hooks/useTemplatesConfigurationPatch";
import useTemplatesConfigurationDelete from "../../../hooks/useTemplatesConfigurationDelete";
import useCurrentUser from "../../../hooks/useCurrentUser";

const usePropertyTableData = () => {
  const { data: currentUser } = useCurrentUser();
  const [favorites, { postFavorite, deleteFavorite }] = useFavorites();

  const { data: managementConfiguration } = useManagementConfiguration();
  const { data: templatesConfiguration } = useTemplatesConfiguration();

  const { mutate: removeProperty } = usePropertiesDelete();
  const { mutate: patchTemplateSettings } = useTemplatesConfigurationPatch();
  const { mutate: deleteTemplateSettings } = useTemplatesConfigurationDelete();

  const [selectedRows, setSelectedRows] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [isShowingPropertiesTable] = usePropertySwitchView();

  const propertyTypes = useMemo(() => {
    if (!managementConfiguration?.management?.property?.types) {
      return [];
    }

    return managementConfiguration.management.property.types
      .filter((type) => type.selected)
      .map((type) => ({
        label: type.display,
        value: type.id,
      }));
  }, [managementConfiguration]);

  const { data: properties, isLoading } = usePropertiesTableFormat(
    propertyTypes,
    favorites
  );

  const templateSettings = useMemo(() => {
    if (!templatesConfiguration?.templates?.property) {
      return [];
    }

    return templatesConfiguration?.templates?.property
      ?.filter((template) => template.name !== "last_updated_column_view")
      ?.map((template) => ({
        ...template,
        isAdmin: !template.custom,
      }));
  }, [templatesConfiguration]);

  const handleRowSelect = useCallback((val) => {
    setSelectedRows(val);
  }, []);

  const updateUserTemplateSettings = useCallback(
    async (customViews) => {
      patchTemplateSettings({
        resource: "property",
        updatedTemplates: [
          ...templateSettings.filter((temp) => !temp.custom),
          ...customViews.map((view) => {
            return { ...view, custom: true };
          }),
        ],
      });
    },
    [patchTemplateSettings, templateSettings]
  );

  const deleteUserTemplateSettings = useCallback(
    async (template) => {
      deleteTemplateSettings({
        resource: "property",
        id: template.id,
      });
    },
    [deleteTemplateSettings]
  );

  const handleFavoriteClick = useCallback(
    (id) => {
      const foundItem = favorites.find((favorite) => {
        return favorite.item.reference.includes(id);
      });

      if (foundItem) {
        deleteFavorite([foundItem]);
      } else {
        postFavorite(id, "Property");
      }
    },
    [deleteFavorite, favorites, postFavorite]
  );

  return {
    properties,
    templateSettings,
    propertyTypes,
    isShowingPropertiesTable,
    selectedRows,
    showDeleteModal,
    isLoading,
    currentUser,
    removeProperty,
    handleRowSelect,
    handleFavoriteClick,
    updateUserTemplateSettings,
    deleteUserTemplateSettings,
    setShowDeleteModal,
  };
};

export default usePropertyTableData;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import * as cityTimezones from "city-timezones";
import { BRAND_GREEN_LIGHT, DROPDOWN_STYLE } from "../../../constants";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    width: "100%",
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    ...DROPDOWN_STYLE.option,
    backgroundColor: isFocused && BRAND_GREEN_LIGHT,
  }),
  singleValue: (provided) => ({
    ...provided,
    ...DROPDOWN_STYLE.singleValue,
  }),
  control: (provided) => ({
    ...provided,
    ...DROPDOWN_STYLE.control,
  }),
};

const WorldClockSelect = ({
  handleCustomCityChange,
  idx,
  city,
  handleBlur,
  handleEnter,
  autofocus,
}) => {
  const [searchTerm, setSearchTerm] = React.useState(city || "");
  const [searchResults, setSearchResults] = React.useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (value) => {
    setSelectedOption(searchTerm);
    handleCustomCityChange(value?.city, idx, value?.timezone);
  };
  const handleInputChange = (inputValue) => {
    setSearchTerm(inputValue);
  };

  useEffect(() => {
    setSearchResults(() => {
      const results = cityTimezones.findFromCityStateProvince(searchTerm);
      const options = results.slice(0, 20).map((result) => {
        const country =
          result.country === "United States of America" ? result.iso2 : null;

        let timezone;
        switch (result?.country) {
          case "Antarctica":
            // According to google New Zealand is used for Antarctica timezone
            timezone = "Pacific/Auckland";
            break;
          case "Arctic":
            // According to google CET is used for the Arctic timezone
            timezone = "Europe/Brussels";
            break;
          default:
            timezone = result?.timezone;
        }
        return {
          city: result.city,
          label: `${result.city}, ${country || result.country}`,
          timezone: timezone || "",
        };
      });
      return options;
    });
  }, [searchTerm]);

  return (
    <Select
      autoFocus={autofocus}
      className="w-full"
      styles={customStyles}
      value={selectedOption}
      defaultValue={city}
      onChange={(value) => handleChange(value)}
      inputValue={searchTerm}
      defaultInputValue={city}
      onInputChange={handleInputChange}
      isSearchable
      options={searchResults}
      onBlur={() => handleBlur(city, idx)}
      handleEnter={(event) => handleEnter(event, city, idx)}
    />
  );
};

WorldClockSelect.propTypes = {
  handleCustomCityChange: PropTypes.func,
  idx: PropTypes.number,
  city: PropTypes.string,
  handleBlur: PropTypes.func,
  handleEnter: PropTypes.func,
  autofocus: PropTypes.bool,
};

WorldClockSelect.defaultProps = {
  handleCustomCityChange: undefined,
  idx: undefined,
  city: undefined,
  handleBlur: undefined,
  handleEnter: undefined,
  autofocus: false,
};

export default WorldClockSelect;

const useContentRowData = ({ title }) => {
  const inlineStyles = {
    row: { width: "479px" },
    input: { width: "340px" },
  };

  const classStyles = {
    topicCN: `text-es-medium-grey text-es-normal font-es-bold tracking-es-wide ${
      title === "Notes" ? "mt-2" : ""
    }`,
    rowCN: `flex justify-between ${
      title === "Notes" ? "items-start" : "items-center"
    }`,
  };
  return {
    inlineStyles,
    classStyles,
  };
};

export default useContentRowData;

import { ExpenseAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { expenseKeys } from "../config/reactQuery/queryKeyFactory";
import { toastError, toastMessage } from "../stories/Components/Toast/Toast";

const patchExpense = async ({ expenseId, lastUpdated }) => {
  const { data } = await ExpenseAPI.postByIdWOP(expenseId, "$togglebillable", {
    lastUpdated,
  });

  return data;
};

/**
 * Mutation hook to remove single/multiple Expenses
 * @returns mutation hook
 */
const useUpdateMergedExpense = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchExpense,
    onSuccess: () => {
      toastMessage("Successfully updated expense");
      setTimeout(
        () => queryClient.invalidateQueries(expenseKeys.expenses),
        5000
      );
      // TODO: reload budget lineitems after Budget api RQ migration
    },
    onError: (error) => {
      console.error("Error updating Expense", error);
      toastError(
        `Error updating expense: ${error?.response?.data?.issues[0]?.location}`
      );
    },
  });
};

export default useUpdateMergedExpense;

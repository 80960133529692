import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import DetailCard from "../DetailCard/DetailCard";
import TertiaryButton from "../Buttons/TertiaryButton";
import placeholderImage from "../../assets/images/placeholderImage.png";

/**
 * @deprecated - Remove this component
 */
const SpaceView = ({
  onFinishEditing,
  building,
  setEditSpaceId,
  levels,
  spaceTypes,
  dispatch,
  disableEditing,
}) => {
  const onAddSpace = () => {
    const id = uuidv4();
    dispatch({
      type: "addSpace",
      spaceId: id,
      buildingId: building.id,
    });
    setEditSpaceId(id);
  };
  return (
    <div className="relative border-2 border-gray-150 flex flex-col pl-4 rounded w-full h-auto">
      <p className="text-gray-200 font-semibold text-lg py-4 px-3">
        Spaces at this location
      </p>
      <div className="overflow-y-scroll w-full h-84">
        {building?.spaces?.map((space) => {
          return (
            <div className="pr-4" key={space?.id}>
              <DetailCard
                title={space?.name}
                type="LOCATION"
                onChange={() => {}}
                onFinishEditing={onFinishEditing}
                Component={
                  <div className="flex flex-col w-full">
                    <div className="pt-1 pb-3">
                      <span className="text-xs text-gray-200 font-normal">
                        {!!space?.type &&
                          `${
                            spaceTypes?.find(
                              (type) => type.value === space?.type
                            )?.label || ""
                          }`}
                        {!!space?.level &&
                          `, ${
                            levels?.find(
                              (level) => level.value === space?.level
                            )?.label || ""
                          }`}
                      </span>
                    </div>
                    <div className="flex overflow-x-scroll">
                      {space?.images?.map((image) => (
                        <div
                          className="flex mr-2 min-w-max"
                          key={`${image?.thumbnailUrl}-${image?.name}`}
                        >
                          <img
                            className="h-12 object-cover w-12 rounded border border-gray-200"
                            src={image?.thumbnailUrl || placeholderImage}
                            alt={image?.name || "placeholder"}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                }
                noBorder
                hoverClassName="border-2 border-brandGreen h-42"
                titleClassName="text-black font-semibold"
                customClassName="border-2 border-white px-3 pt-3"
                editSpaceId={space?.id}
                setEditSpaceId={setEditSpaceId}
                hideEditButton={disableEditing}
              />
            </div>
          );
        })}
      </div>
      <div className="absolute bottom-0 flex py-4 z-20">
        <TertiaryButton
          title="+ Add Space"
          onClick={onAddSpace}
          disabled={disableEditing}
        />
      </div>
    </div>
  );
};

SpaceView.propTypes = {
  onFinishEditing: PropTypes.func,
  building: PropTypes.shape({
    id: PropTypes.string,
    spaces: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        level: PropTypes.string,
        type: PropTypes.string,
        name: PropTypes.string,
        images: PropTypes.arrayOf(
          PropTypes.shape({
            thumbnailUrl: PropTypes.string,
            name: PropTypes.string,
          })
        ),
      })
    ),
  }),
  setEditSpaceId: PropTypes.func,
  levels: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  spaceTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  dispatch: PropTypes.func,
  disableEditing: PropTypes.bool,
};

SpaceView.defaultProps = {
  onFinishEditing: undefined,
  building: undefined,
  setEditSpaceId: undefined,
  levels: [],
  spaceTypes: [],
  dispatch: undefined,
  disableEditing: false,
};

export default SpaceView;

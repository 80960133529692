import cntl from "cntl";
import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import placeholderImage from "../../assets/images/placeholderImage.png";
import { useGetFile } from "../../../hooks/useGetFile";

const containerCN = (className, selected, blur) => cntl`
    flex
    rounded
    relative
    ${!selected && "shadow-md"}
    ${selected && "border-4 border-brandGreen p-1 bg-white"}
    ${className}
    ${blur && !selected && "opacity-40"}
`;

const MediaWidgetImage = ({
  image,
  containerClassName,
  styles,
  onClick,
  selected,
  blur,
  index,
  compileResolved,
  isPrimary,
}) => {
  const videoRef = useRef(null);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [resolvedImage, setResolvedImage] = useState();

  const { data } = useGetFile(image?.file);

  const handleSelectImage = (event, val) => {
    if (event.code === "Enter" && !isPrimary) {
      if (!selected && resolvedImage)
        onClick({
          file: val,
          url: resolvedImage?.contentsUrl,
          name: resolvedImage?.name,
          contentType: resolvedImage?.contentType,
          index,
        });
    }
  };

  useEffect(() => {
    if (videoRef.current && !videoRef.current?.paused && !selected) {
      videoRef.current?.pause();
      setVideoPlaying(false);
    }
  }, [selected]);

  const handleClick = (event, val) => {
    let combo;
    if ((event.metaKey || event.ctrlKey) && !isPrimary) combo = "toggle";
    if (event.shiftKey) combo = "shift";
    if (event.detail === 2 && event.type === "click") combo = "dblClick";
    if (resolvedImage && !isPrimary)
      onClick(combo, {
        file: val,
        url: resolvedImage?.contentsUrl,
        name: resolvedImage?.name,
        contentType: resolvedImage?.contentType,
        index,
      });
    if (resolvedImage && isPrimary && combo === "dblClick")
      onClick(combo, {
        file: val,
        url: resolvedImage?.contentsUrl,
        name: resolvedImage?.name,
        contentType: resolvedImage?.contentType,
        index,
      });
  };

  useEffect(() => {
    if (!data) return;
    setResolvedImage(data);
    if (!isPrimary) compileResolved({ ...data, index });
  }, [image, data, compileResolved, index, isPrimary]);

  const handleVideoPlayback = useCallback(() => {
    if (videoRef.current?.paused) {
      videoRef.current?.play();
      setVideoPlaying(true);
    } else {
      videoRef.current?.pause();
      setVideoPlaying(false);
    }
  }, []);

  return (
    <div
      className={containerCN(containerClassName, selected, blur)}
      onClick={(e) => handleClick(e, image?.file)}
      onKeyDown={(e) => handleSelectImage(e, image?.file)}
      role="button"
      tabIndex={0}
      style={{ ...styles }}
    >
      {resolvedImage?.category === "Videos" ? (
        <>
          <video
            className="rounded"
            style={{
              maxWidth: isPrimary ? "426px" : "300px",
              maxHeight: isPrimary ? "426px" : "300px",
              objectFit: "contain",
            }}
            playsInline
            autoPlay={false}
            muted
            preload="metadata"
            ref={videoRef}
          >
            <source
              src={`${resolvedImage.contentsUrl}#t=0.5`}
              type={
                resolvedImage.contentType === "video/quicktime"
                  ? "video/mp4"
                  : resolvedImage.contentType
              }
            />
            Your browser does not support the video tag.
          </video>
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 100 100"
            className="absolute top-0 left-0"
            onClick={() => handleVideoPlayback()}
          >
            {!videoPlaying ? (
              <polygon
                points="40,40 60,50 40,60"
                fill="gray"
                rx="10"
                strokeLinejoin="round"
                stroke="gray"
                strokeWidth="5"
                opacity="0.4"
              />
            ) : (
              <>
                <rect
                  x="35"
                  y="35"
                  rx="1.25"
                  width="10"
                  height="30"
                  fill="gray"
                  opacity="0.4"
                />
                <rect
                  x="55"
                  y="35"
                  rx="1.25"
                  width="10"
                  height="30"
                  fill="gray"
                  opacity="0.4"
                />
              </>
            )}
          </svg>
        </>
      ) : (
        <img
          className="rounded"
          style={{
            maxWidth: isPrimary ? "426px" : "300px",
            maxHeight: isPrimary ? "426px" : "300px",
            objectFit: "contain",
          }}
          src={resolvedImage?.contentsUrl ?? placeholderImage}
          alt={`${resolvedImage?.name}`}
        />
      )}
    </div>
  );
};

MediaWidgetImage.propTypes = {
  image: PropTypes.shape({
    file: PropTypes.string,
    isPrimary: PropTypes.bool,
  }),
  containerClassName: PropTypes.string,
  styles: PropTypes.shape({}),
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  blur: PropTypes.bool,
  index: PropTypes.number,
  compileResolved: PropTypes.func,
  isPrimary: PropTypes.bool,
};

MediaWidgetImage.defaultProps = {
  image: undefined,
  containerClassName: undefined,
  styles: undefined,
  onClick: undefined,
  selected: false,
  blur: false,
  index: undefined,
  compileResolved: undefined,
  isPrimary: false,
};

export default MediaWidgetImage;

import React from "react";
import ModalWrapper from "../EsModal/ModalWrapper";
import ReportEditForm from "./ReportEditForm";
import useReportsEditModalData from "./useReportsEditModalData";

const ReportsEditModal = ({ modalData }) => {
  const {
    reportForm,
    members,
    disableForm,
    isDistroSelectOpen,
    humanReadableRecurrence,
    setHumanReadableRecurrence,
    setDistroSelectOpen,
    handleUpdateReport,
    handleChangeForm,
    handleDistroMembers,
    handleTags,
  } = useReportsEditModalData({ modalData });

  return (
    <ModalWrapper
      width="42rem"
      modalData={modalData}
      title="Edit Report"
      resourceName="Report Edition"
      primaryButtonOnClick={handleUpdateReport}
      trashCan
      showConfirm
      disabled={disableForm}
    >
      <ReportEditForm
        reportForm={reportForm}
        members={members}
        isDistroSelectOpen={isDistroSelectOpen}
        humanReadableRecurrence={humanReadableRecurrence}
        setDistroSelectOpen={setDistroSelectOpen}
        setHumanReadableRecurrence={setHumanReadableRecurrence}
        handleChangeForm={handleChangeForm}
        handleDistroMembers={handleDistroMembers}
        handleTags={handleTags}
      />
    </ModalWrapper>
  );
};

export default ReportsEditModal;

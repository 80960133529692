import React from "react";

const Divider = ({ fullLength = false }) =>
  !fullLength ? (
    <div className="flex flex-row gap-6">
      <div className="w-1/2 border-b" />
      <div className="w-1/2 border-b" />
    </div>
  ) : (
    <div className="flex flex-row w-full border-b my-2" />
  );

export default Divider;

import React from "react";
import PropTypes from "prop-types";
import RequestInfoList from "../RequestInfoList/RequestInfoList";

export default function RequestInfoContainer({ columns }) {
  return (
    <div className="flex flex-col rounded-lg w-full flex-wrap">
      <div className={`grid grid-cols-${columns.length} gap-12 mt-6`}>
        {columns.map((column) => {
          return (
            <RequestInfoList
              key={column.title}
              rows={column.rows}
              rowWrapperCN={column.rowWrapperCN}
            />
          );
        })}
      </div>
    </div>
  );
}

RequestInfoContainer.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.shape({})),
      ]),
    })
  ),
};

RequestInfoContainer.defaultProps = {
  columns: [],
};

import React from "react";
import PropTypes from "prop-types";
import AlertTotal from "../Alert/AlertTotal";

const SiteHeaderAlert = ({
  total,
  isRed,
  onClick,
  buttonState,
  title,
  className,
}) => {
  return (
    <>
      {total > 0 && (
        <AlertTotal
          className={className ?? "mr-4"}
          total={total}
          isRed={isRed}
          onClick={onClick}
          buttonState={buttonState}
          title={title}
        />
      )}
    </>
  );
};

SiteHeaderAlert.propTypes = {
  total: PropTypes.number,
  isRed: PropTypes.bool,
  onClick: PropTypes.func,
  buttonState: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
};

SiteHeaderAlert.defaultProps = {
  total: 0,
  isRed: false,
  onClick: false,
  buttonState: false,
  title: undefined,
  className: undefined,
};

export default SiteHeaderAlert;

/* eslint-disable no-param-reassign */
import { useMutation } from "react-query";
import { TodoAPI } from "@griffingroupglobal/eslib-api";
import { useCallback } from "react";
import { toastError } from "../helpers/Toast";
import queryClient from "../config/reactQuery/queryClient";
import { todosCodeKeys } from "../config/reactQuery/queryKeyFactory";
import useCurrentUser from "./useCurrentUser";

export default () => {
  const { data: currentUser } = useCurrentUser();
  const deleteTodo = useCallback(
    async (todo) => {
      if (currentUser?.reference === todo.metadata.createdBy && todo?.id)
        return TodoAPI.delete(todo?.id);

      throw new Error("Cannot delete To-do(s) you didn't create.");
    },
    [currentUser?.reference]
  );

  return useMutation({
    mutationFn: deleteTodo,
    onMutate: async (todo) => {
      await queryClient.cancelQueries(todosCodeKeys);

      const prev = queryClient.getQueryData(todosCodeKeys);

      queryClient.setQueryData(todosCodeKeys, (current) => {
        const todos = [...current];
        return todos.filter((item) => item?.id !== todo?.id);
      });

      return { prev };
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(todosCodeKeys, context?.prev);
      toastError(
        `There was an error performing this action. \n Message: ${error.message} \n Please try again`
      );
      console.error("useDeleteTodos", error);
    },
  });
};

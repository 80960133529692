import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

import Modal from "../Modal/Modal";
import TertiaryHeader from "../TextHeaders/TertiaryHeader";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";

const ResetPasswordConfirmationModalCN = cntl`
  flex
  flex-col
  items-center
  justify-around
`;

const successfulRequestCN = cntl`
  flex
  flex-col
  items-center
`;

const ResetPasswordConfirmationModal = ({
  isResetPasswordConfirmationModalOpen,
  setIsResetPasswordConfirmationModalOpen,
  successfulRequest,
  errorMessage,
}) => {
  return (
    <Modal
      title={successfulRequest ? "Create New Password" : "Forgot Password?"}
      isOpen={isResetPasswordConfirmationModalOpen}
      onRequestModalClose={() => setIsResetPasswordConfirmationModalOpen(false)}
      alert
      hideFooter
    >
      <div className={ResetPasswordConfirmationModalCN}>
        {successfulRequest && (
          <div className={successfulRequestCN}>
            <SecondaryHeader>Forgot Password Email Sent</SecondaryHeader>
            <div className="pt-5">
              <TertiaryHeader>
                Check your email for a temporary password
              </TertiaryHeader>
            </div>
          </div>
        )}
        {!successfulRequest && (
          <div className={successfulRequestCN}>
            <div className="pt-5">
              <TertiaryHeader>{errorMessage}</TertiaryHeader>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

ResetPasswordConfirmationModal.propTypes = {
  /**
   * if true, displays the modal
   */
  isResetPasswordConfirmationModalOpen: PropTypes.bool,
  /**
   * function called to trigger if the modal is open or closed
   */
  setIsResetPasswordConfirmationModalOpen: PropTypes.func,
  /**
   * boolean to determine if reset link was sent successfully
   */
  successfulRequest: PropTypes.bool,
  errorMessage: PropTypes.string,
};

ResetPasswordConfirmationModal.defaultProps = {
  isResetPasswordConfirmationModalOpen: false,
  setIsResetPasswordConfirmationModalOpen: undefined,
  successfulRequest: false,
  errorMessage: undefined,
};

export default ResetPasswordConfirmationModal;

import { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  ADD_CATEGORY_POPUP,
  ADD_OPEN_MODAL,
  ADD_PREFERENCE_MODAL,
  TOGGLE_POSITIONED_POPUP,
} from "../../../../constants";
import { useModalState } from "../../../../state/modalState";
import { useAppState } from "../../../../state/appState";

const usePreferencesLibrarySection = ({
  systemSettings,
  dispatchSystemSettings,
}) => {
  const [, modalDispatch] = useModalState();
  const [preferences, setPreferences] = useState([]);

  // Add useEffect to set Prefs, so when cancelled the updated list forces the containers to update with the updated data
  useEffect(() => {
    if (systemSettings?.preferences) {
      setPreferences(systemSettings?.preferences);
    }
  }, [systemSettings]);

  const containerTitles = useMemo(() => {
    const list =
      systemSettings?.preferences && Object.keys(preferences)?.sort();
    return list ?? [];
  }, [preferences, systemSettings]);

  const onSubmitNewPreference = (newPreferences) => {
    const updatedPrefs = { ...preferences, ...newPreferences };
    setPreferences({ ...updatedPrefs });
    dispatchSystemSettings({
      type: "updatePreferences",
      preferences: { ...updatedPrefs },
    });
  };

  const handleAddPreference = (title) => {
    const id = uuidv4();
    modalDispatch({
      type: ADD_OPEN_MODAL,
      modalType: ADD_PREFERENCE_MODAL,
      ref: { id },
      modalData: {
        item: {
          title,
          handleSubmit: onSubmitNewPreference,
          adminPreferences: preferences,
          isAdminPage: true,
        },
      },
    });
  };

  const onSubmitNewCategory = (value) => {
    const id = uuidv4();
    const newPrefs = { ...preferences };
    newPrefs[value] = {
      id,
      values: [],
    };

    setPreferences((prev) => ({
      ...newPrefs,
      ...prev,
    }));

    dispatchSystemSettings({
      type: "updatePreferences",
      preferences: { ...newPrefs },
    });
  };

  const [, appStateDispatch] = useAppState();

  const handleAddContainer = () => {
    const id = uuidv4();
    appStateDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      position: {
        x:
          window.innerWidth < 1200
            ? window.innerWidth / 5
            : window.innerWidth / 2.5,
        y: window.innerHeight / 5,
      },
      popupData: {
        id,
        onSubmit: onSubmitNewCategory,
        isAdminPage: true,
        preferences,
      },
      popupType: ADD_CATEGORY_POPUP,
    });
  };

  const handleRemovePreference = ({ pref, title }) => {
    const newPrefs = {
      ...preferences,
      [title]: {
        ...preferences?.[title],
        values: [
          ...preferences?.[title]?.values?.filter(
            (item) => pref.value !== item.value
          ),
        ],
      },
    };

    setPreferences((prev) => ({
      ...prev,
      ...newPrefs,
    }));

    dispatchSystemSettings({
      type: "updatePreferences",
      preferences: { ...newPrefs },
    });
  };

  const handleRemoveContainer = (preferenceKey) => {
    // remove container ex preferences["food"]
    const newPrefs = { ...preferences };
    newPrefs[preferenceKey] = {};
    delete newPrefs[preferenceKey];
    setPreferences({ ...newPrefs });

    dispatchSystemSettings({
      type: "updatePreferences",
      preferences: { ...newPrefs },
    });
  };

  return {
    preferences,
    containerTitles,
    handleAddPreference,
    handleRemovePreference,
    handleAddContainer,
    handleRemoveContainer,
  };
};

export default usePreferencesLibrarySection;

import moment from "moment";
import { MOMENT_UTC_ES_FORMAT } from "../../constants";

const formatIsoToUtc = (dateISOString) => {
  // Check to see if the date is in ISO format
  if (typeof dateISOString !== "string") return dateISOString;
  // Regular expression to match UTC format in ISO 8601
  const iso8601Pattern =
    /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?([-+]\d{2}:\d{2})?)$/;
  // Check if the string matches the ISO pattern
  if (iso8601Pattern.test(dateISOString)) {
    // Convert to UTC format with "Z" at the end
    return moment(dateISOString).utc().format(MOMENT_UTC_ES_FORMAT);
  }
  // If it's not in ISO format, return the original input
  return dateISOString;
};

export default formatIsoToUtc;

import React from "react";
import PropTypes from "prop-types";

import Modal from "../../../../stories/Components/Modal/Modal";
import useAssetDeleteModalData from "./useAssetDeleteModalData";

const AssetDeleteModal = ({
  asset,
  selectedAssets,
  showDeleteModal,
  setShowDeleteModal,
}) => {
  const { message, isDeleting, hasMultipleAssets, onDeleteClicked } =
    useAssetDeleteModalData({
      asset,
      selectedAssets,
      setShowDeleteModal,
    });

  return (
    <Modal
      title={`Delete ${hasMultipleAssets ? "Assets" : "Asset"}?`}
      isOpen={showDeleteModal}
      primaryButtonTitle="Yes, Delete"
      primaryButtonOnClick={onDeleteClicked}
      tertiaryButtonTitle="Cancel"
      onRequestModalClose={() => setShowDeleteModal(false)}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      hideFooter
      alert
      disabled={isDeleting}
    >
      <>
        <p className="text-base mb-2">
          Are you sure you want to delete {message}
        </p>
      </>
    </Modal>
  );
};

AssetDeleteModal.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  selectedAssets: PropTypes.arrayOf(PropTypes.string),
  showDeleteModal: PropTypes.bool,
  setShowDeleteModal: PropTypes.func,
};

AssetDeleteModal.defaultProps = {
  asset: null,
  selectedAssets: [],
  showDeleteModal: false,
  setShowDeleteModal: () => {},
};

export default AssetDeleteModal;

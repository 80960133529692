import moment from "moment";
import { useMemo } from "react";
import usePagePersistance from "../../../../../hooks/usePagePersistence";

const useTimesheetContentData = () => {
  const { pageState } = usePagePersistance();

  const entryDates = useMemo(() => {
    if (!pageState?.timesheet?.periodStart) return [];

    const startDate = pageState?.timesheet?.periodStart;
    const datesNoTimesheet = [];

    // this is the start of a timesheet view date; we want to show 7 days
    for (let i = 0; i < 7; i += 1) {
      datesNoTimesheet.push(
        moment(startDate).add(i, "days").format("YYYY-MM-DD")
      );
    }

    return datesNoTimesheet;
  }, [pageState?.timesheet?.periodStart]);

  const formattedMonth = useMemo(() => {
    return moment(entryDates?.[0]).format("MMMM");
  }, [entryDates]);

  return {
    entryDates,
    formattedMonth,
  };
};

export default useTimesheetContentData;

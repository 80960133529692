import React, { useState, useEffect } from "react";
import PropTypes, { objectOf } from "prop-types";
import moment from "moment";
import IconButton from "../Buttons/IconButton";
import arrowRightGray from "../../assets/images/arrowRightGray.svg";
import MaintenanceSection from "./MaintenanceSection";

const MaintenanceCard = ({
  data,
  defaultOpen,
  currentUser,
  onDeletedTask,
  membersOptions,
  onEditedTask,
  reloadObject,
  users,
  isTaskDurationRequired,
  association,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const [updatedItems, setUpdatedItems] = useState(data);

  useEffect(() => {
    setUpdatedItems(data);
  }, [data]);

  return (
    <div className="border-2 border-grey-100 rounded-md mb-4">
      <div className="flex justify-between items-center h-20 px-4">
        <h3 className="font-bold">{data.year}</h3>
        <IconButton
          icon={arrowRightGray}
          onClick={() => setIsOpen((prev) => !prev)}
          className={isOpen && "transform rotate-90"}
        />
      </div>
      {isOpen &&
        updatedItems?.items
          .sort((a, b) => moment(b.startDate).diff(moment(a.startDate)))
          .map((item) => (
            <MaintenanceSection
              key={item?.id}
              taskData={item}
              currentUser={currentUser}
              onDeletedTask={onDeletedTask}
              assetMembersOptions={membersOptions}
              reloadAsset={reloadObject}
              onEditedTask={onEditedTask}
              users={users}
              isTaskDurationRequired={isTaskDurationRequired}
              association={association}
            />
          ))}
    </div>
  );
};

MaintenanceCard.propTypes = {
  /**
   * maintance data
   */
  data: PropTypes.shape({
    year: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
      })
    ),
  }),
  defaultOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  currentUser: PropTypes.object,
  onDeletedTask: PropTypes.func,
  membersOptions: PropTypes.arrayOf(objectOf),
  reloadObject: PropTypes.func,
  onEditedTask: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  isTaskDurationRequired: PropTypes.bool,
  association: PropTypes.shape({}),
};

MaintenanceCard.defaultProps = {
  data: {},
  defaultOpen: undefined,
  currentUser: {},
  onDeletedTask: undefined,
  membersOptions: [],
  reloadObject: undefined,
  onEditedTask: undefined,
  users: [],
  isTaskDurationRequired: false,
  association: {},
};

export default MaintenanceCard;

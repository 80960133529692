import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";

import useDocumentsConfiguration from "../../../hooks/useDocumentsConfiguration";

import DroppedFile from "../DragDropDialog/DroppedFile";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import PrimaryButton from "../Buttons/PrimaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";

import crossIcon from "../../assets/images/crossIcon.svg";

const modalStyles = {
  content: {
    inset: "0",
    width: "50%",
    minWidth: "675px",
    height: "475px",
    maxHeight: "100vh",
    padding: "0",
    marginLeft: "auto",
    marginRight: "auto",
    overflowY: "auto",
    top: "0",
    left: "0",
  },
  overlay: {
    backgroundColor: "rgba(25, 25, 25, 0.8)",
    zIndex: "50",
  },
};

const DocumentEditModal = ({
  document,
  defaultType,
  onDocumentUpdate,
  onClose,
  show,
}) => {
  const { data: docConfig } = useDocumentsConfiguration();
  const [editedDoc, setEditedDoc] = useState(document ?? {});
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const docTypeOptions = React.useMemo(() => {
    return docConfig?.documents?.documentType
      ?.filter((doc) => doc.custom)
      ?.map((doc) => {
        return { label: doc.display, value: doc.id };
      })
      ?.sort(({ label: a }, { label: b }) => a.localeCompare(b));
  }, [docConfig]);

  const docTypeOptionsMap = React.useMemo(() => {
    return {
      ...docConfig?.documents?.documentType
        ?.filter((doc) => doc.selected && (doc.value || doc.id))
        ?.reduce((obj, item) => {
          return {
            ...obj,
            [item.value ?? item.id]: {
              label: item.display,
              value: item.value ?? item.id,
            },
          };
        }, {}),
    };
  }, [docConfig]);

  useEffect(() => {
    if (document && document.id !== editedDoc.id) {
      setEditedDoc({ ...document, filename: document.customName });
    }

    setUploadDisabled(() => {
      return !editedDoc.docType;
    });
  }, [document, editedDoc]);

  useEffect(() => {
    if (!show) {
      setIsUpdating(false);
    }
  }, [show]);

  const handleDocNameChange = ({ val }) => {
    setEditedDoc((prev) => {
      return { ...prev, customName: val, filename: val };
    });
  };
  const handleDocTypeChange = ({ val }) => {
    setEditedDoc((prev) => {
      return { ...prev, docType: val };
    });
  };
  const handleDocUpdate = () => {
    onDocumentUpdate(editedDoc);
    setIsUpdating(true);
  };
  const handleClose = () => {
    onClose();
    setIsUpdating(false);
  };

  return (
    <ReactModal
      style={modalStyles}
      isOpen={show}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
    >
      <div
        className="flex flex-col w-full h-full p-8 border rounded"
        style={{ height: "475px" }}
      >
        <div className="flex justify-between mb-8 pl-1">
          <SecondaryHeader>Edit Document</SecondaryHeader>
          <button className="-mt-6 -mr-3" type="button" onClick={handleClose}>
            <img src={crossIcon} alt="close button" />
          </button>
        </div>

        <div className="flex flex-col">
          <div className="mb-6">
            <DroppedFile
              file={editedDoc}
              defaultType={defaultType}
              docTypeOptions={docTypeOptions}
              docTypeOptionsMap={docTypeOptionsMap}
              onNameChange={(id, val) => handleDocNameChange({ id, val })}
              onTypeChange={(id, val) => handleDocTypeChange({ id, val })}
              disableFavorite
            />
          </div>
        </div>

        <div className="flex justify-end w-full mt-auto">
          <TertiaryButton
            className="mr-2"
            title="Cancel"
            onClick={handleClose}
          />
          <PrimaryButton
            title="Update"
            onClick={handleDocUpdate}
            isLoading={isUpdating}
            disabled={uploadDisabled}
          />
        </div>
      </div>
    </ReactModal>
  );
};

DocumentEditModal.propTypes = {
  /**
   * document selected for edit
   */
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.object,
  /**
   * optional accepted file type
   */
  defaultType: PropTypes.string,
  /**
   * function called when document updated
   */
  onDocumentUpdate: PropTypes.func,
  /**
   * function called on close
   */
  onClose: PropTypes.func,
  /**
   * show modal
   */
  show: PropTypes.bool,
};

DocumentEditModal.defaultProps = {
  document: {},
  defaultType: undefined,
  onDocumentUpdate: () => {},
  onClose: undefined,
  show: true,
};

export default DocumentEditModal;

import moment from "moment-timezone";
import React, { useMemo } from "react";
import _ from "lodash";
import { CALENDAR_STROKE } from "../../../../constants";
import AllDayEvent from "../AllDayEvent";
import useRelativeAssociations from "../../../../hooks/useRelativeAssociations";
import { useAppState } from "../../../../state/appState";

const WeekHeader = ({
  values,
  events,
  handleEventClick,
  offset: parentOffset,
  setOffset,
}) => {
  const [{ calendarTimezone }] = useAppState();
  const { eventFilter: filter } = useRelativeAssociations();
  const cellHeight = 755 % 35;
  const cellWidth = 280 / values.length;
  const width = 280;
  const today = moment.tz(new Date(), calendarTimezone).startOf("day").format();

  const offset = useMemo(() => {
    if (parentOffset) {
      return parentOffset + 1;
    }
    return 0;
  }, [parentOffset]);

  // Data Structure to track open spaces in event mapping
  const WeekMap = new Map();

  /**
   *
   * @param {Array} list - takes array and determines the next position to add.
   * @returns - sorted array with added position.
   */
  const updateList = (list) => {
    // If list is undefined return list [0] for first positioned event
    if (!list) {
      return { arr: [0], position: 0 };
    }

    // Sort list - Checks difference from left to right and inserts event accordingly
    const arr = _.cloneDeep(list);

    // position of added event
    let place;
    for (let v = 0; v <= arr.length - 1; v += 1) {
      const item = arr[v];
      // look behind current index
      if (arr[v - 1] + 1 !== item && item !== 0) {
        arr.splice(v - 1 < 0 ? 0 : v - 1, 0, item - 1);
        place = item - 1;
        break;
      }

      // look ahead of current index
      if (arr[v + 1] - 1 !== item) {
        arr.splice(v + 1, 0, item + 1);
        place = item + 1;
        break;
      }

      // if we reach end of the list add new event to tail
      if (v === arr.length - 1) {
        arr.push(item + 1);
        place = item + 1;
        break;
      }
    }
    return { arr, position: place };
  };

  // Hold offset until end of render then update parent
  let maxCell = 0;

  // Determine if there are any all-day events in 'values'.
  const hasAllDayEvents =
    values.find((date) => {
      const eventDay = events[date.iso];
      // Check if the 'allDay' array is not empty.
      return eventDay?.allDay.length > 0;
    }) !== undefined; // True if at least one all-day event is found, otherwise false.

  // Function to calculate the SVG viewBox size.
  const returnSvgContainerViewBox = () => {
    const modifiedOffset = offset * 7;
    const dimensionsNoAllDays = "-0.5 -0.125 296 ";

    return !hasAllDayEvents
      ? `${dimensionsNoAllDays} ${21.375 + modifiedOffset}` // No all-day events.
      : `${dimensionsNoAllDays} ${28.375 + modifiedOffset}`; // With all-day events.
  };

  // Function to calculate rectangle height in the SVG.
  const returnRectHeight = () => {
    // Base height calculation without all-day events.
    const heightNoAllDays = cellHeight + 5 + offset * 7;

    // Adjust height based on the presence of all-day events.
    return !hasAllDayEvents
      ? heightNoAllDays // No all-day events.
      : 35 + heightNoAllDays; // With all-day events.
  };

  // Function to calculate the Y-coordinate for SVG borders.
  const returnBorderY = () => {
    const baseY = offset * 7;
    // Adjust Y-coordinate based on the presence of all-day events.
    return !hasAllDayEvents ? 21 + baseY : 28 + baseY;
  };

  return (
    <svg viewBox={returnSvgContainerViewBox()} width="100%">
      <g transform="translate(15, 0)">
        <defs>
          <clipPath id="round-corner">
            <rect
              x="-0.1"
              y="0"
              width={width + 0.2}
              height={cellHeight + 10 + offset * 7}
              rx="3"
            />
          </clipPath>
        </defs>
        <rect
          x="-0.1"
          y="0"
          width={width + 0.2}
          height={returnRectHeight()}
          fill="#f9f9f9"
          rx="3"
          clipPath="url(#round-corner)"
          stroke={CALENDAR_STROKE}
          strokeWidth="0.4"
        />{" "}
        <g id="header-cells" clipPath="url(#round-corner)">
          {Array.from({ length: values.length }, (__, i) => (
            <g
              id={`${moment.tz(values[i].iso, calendarTimezone).format()}-cell`}
              key={`${moment
                .tz(values[i].iso, calendarTimezone)
                .format()}-cell`}
            >
              <rect
                x={i * cellWidth}
                y="0"
                width={cellWidth}
                height={cellHeight + 10 + offset * 7}
                opacity="10%"
                fill={today === values[i].iso ? "#1CC39D" : "none"}
              />
              <text
                x={i * cellWidth + cellWidth / 2}
                y="7"
                fontSize="4"
                textAnchor="middle"
                fontWeight="500"
                opacity={today === values[i].iso ? "50%" : "100%"}
                color={today === values[i].iso ? "#027D61" : "#333333"}
              >
                {values[i].dayName}
              </text>
              <text
                x={i * cellWidth + cellWidth / 2}
                y="14"
                fontSize="4"
                textAnchor="middle"
                fontWeight="500"
                opacity={today === values[i].iso ? "50%" : "100%"}
                color={today === values[i].iso ? "#027D61" : "#333333"}
              >
                {values[i].dayNumber}
              </text>
            </g>
          ))}
        </g>
        <g id="allDayEvents">
          {values.map((day, index) => {
            const key = moment.tz(day.iso, calendarTimezone).format();
            const allDayEvents = [];
            const filteredEvents = filter(events?.[key]);
            filteredEvents?.allDay?.forEach((dayEvent) => {
              const { daySpan } = dayEvent;
              const place = WeekMap.get(index);

              // Gets starting map and position
              const { arr, position } = updateList(place);

              for (let v = 0; daySpan > v; v += 1) {
                // if day exists in map return it else return array with position
                let scopedPlace = WeekMap.get(index + v);
                scopedPlace = scopedPlace
                  ? [...scopedPlace, position].sort((a, b) => a - b)
                  : [position];
                WeekMap.set(index + v, v === 0 ? arr : scopedPlace);
              }

              allDayEvents.push(
                <AllDayEvent
                  event={dayEvent}
                  index={index}
                  subIndex={position}
                  onClick={handleEventClick}
                  dayView={false}
                />
              );
              if (maxCell < position) {
                maxCell = position;
              }
            });
            setOffset(maxCell);
            return allDayEvents;
          })}
        </g>
        <rect
          id="bottom-border"
          width={width + 0.2}
          y={returnBorderY()}
          height={0.25}
          fill={CALENDAR_STROKE}
        />
      </g>
    </svg>
  );
};

export default WeekHeader;

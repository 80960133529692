import cntl from "cntl";
import moment from "moment-timezone";
import React, { useEffect, useMemo, useState } from "react";

const searchCN = cntl`
  pl-8
  searchContainer
  z-20
  flex
  items-center
  flex-grow
`;

const WorldClock = ({ systemConfiguration }) => {
  const [worldClock, setWorldClock] = useState(null);
  const [clockCount, setClockCount] = useState(0);

  useEffect(() => {
    if (systemConfiguration) {
      setWorldClock(systemConfiguration?.system?.worldClock);
    }
  }, [systemConfiguration, worldClock]);

  const getSelectedCities = useMemo(() => {
    return (
      systemConfiguration?.system?.worldClock?.cities?.filter(
        (city) => !!city.selected
      ) || []
    );
  }, [systemConfiguration?.system?.worldClock?.cities]);

  useEffect(() => {
    const interval = setInterval(() => {
      setClockCount(() => {
        if (clockCount === getSelectedCities.length - 1) return 0;
        return clockCount + 1;
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [clockCount, getSelectedCities, worldClock]);
  return (
    <div className={searchCN}>
      <>
        {getSelectedCities[clockCount] && (
          <div key={getSelectedCities[clockCount].id}>
            <p className="text-white w-auto block flex-shrink-0 mr-8 text-sm tracking-widest">
              <strong>{getSelectedCities[clockCount].city}</strong> -{" "}
              {moment
                .tz(
                  new Date(),
                  getSelectedCities[clockCount].cityCode || moment.tz.guess()
                )
                .format("h:mm a")}
            </p>
          </div>
        )}
      </>
      {/* {showSearchBar && (
          <div className="w-full block">
            {!hideGlobalSearch && (
              <GlobalSearchBar
                onBlur={() => {
                  if (searchQuery === "") {
                    setShowSearchBar(false);
                  }
                }}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            )}
          </div>
        )} */}
    </div>
  );
};

export default WorldClock;

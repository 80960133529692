import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";

import {
  FILTER_TYPES,
  GET_PROJECTS_PATH,
  PROJECT,
  SHOW_COLUMNS_SETTING_RIGHT,
  typeOfDurationOptions,
} from "../../../constants";
import { reformatCurrencyString } from "../../../helpers/Formatters";

import DateCell from "../Table/DateCell";

import Table from "../Table/Table";
import useTemplatesConfiguration from "../../../hooks/useTemplatesConfiguration";
import FavoriteButton from "../Buttons/FavoriteButton";
import TagList from "../Tags/TagList";
import TableActionsIconsGroup from "../Table/TableActionsIconsGroup";
import { useProjectSwitchView } from "../../../hooks/useSwitchView";
import ProjectDeleteModal from "../../../Pages/Project/DeleteModal";
import CardGrid from "../CardGrid/CardGrid";
import Avatar from "../Message/Avatar";
import { hasDeleteAdministrativePermission } from "../../../helpers/Permissions";
import useCurrentUser from "../../../hooks/useCurrentUser";

const groupOptions = {
  // ordered list of grouped column options
  hierarchicalOptions: [],
  // un-ordered list of grouped column options
  nonHierarchicalOptions: [
    {
      label: "Type",
      value: "projectType",
    },
    {
      label: "Tags",
      value: "tags",
    },
  ],
};

const openMap = (address, e) => {
  e.stopPropagation();
  window.open(`http://maps.google.com/?q=${address}`);
};

const ProjectTable = ({ data, handleFavoriteClick }) => {
  const { data: currentUser } = useCurrentUser();
  const history = useHistory();
  const [templatesConfiguration, update, , deleteTemplate] =
    useTemplatesConfiguration();
  const [isShowingProjectTable] = useProjectSwitchView();

  const [templateSettings, setTemplateSettings] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (templatesConfiguration) {
      setTemplateSettings(
        templatesConfiguration?.templates?.project
          ?.map((template) => ({
            ...template,
            isAdmin: !template.custom,
          }))
          ?.filter((template) => template.name !== "last_updated_column_view")
      );
    }
  }, [templatesConfiguration]);

  const updateUserTemplateSettings = useCallback(
    async (customViews) => {
      update({
        key: "project",
        updatedTemplates: [
          ...templateSettings.filter((temp) => !temp.custom),
          ...customViews.map((view) => {
            return { ...view, custom: true };
          }),
        ],
      });
    },
    [update, templateSettings]
  );

  const deleteUserTemplateSettings = useCallback(
    async (template) => {
      deleteTemplate({
        key: "project",
        id: template.id,
      });
    },
    [deleteTemplate]
  );

  const projectColumns = useMemo(
    () => [
      {
        id: "favorited",
        Header: "",
        editColumnModalHeader: "Favorite",
        isPrimary: true,
        accessor: "isFavorited",
        filterOptions: {
          filterType: FILTER_TYPES.boolean,
          label: "Favorites",
        },
        width: 20,
        minWidth: 90,
        maxWidth: 100,
        Cell: ({ row }) => {
          return (
            <FavoriteButton
              isSelected={row?.original?.isFavorited}
              onClick={() => {
                handleFavoriteClick(row?.original?.id);
              }}
            />
          );
        },
        Aggregated: () => null,
      },
      {
        Header: "Image",
        accessor: "primaryImage",
        Cell: ({ row }) => {
          return (
            <div className="flex flex-row items-center justify-center w-full p-2">
              <Avatar
                avatar={row?.original?.primaryImage}
                name={row?.original?.name}
                isProject
                isExternalUser
              />
            </div>
          );
        },
      },
      {
        Header: "Name",
        accessor: "name",
        minWidth: 200,
      },
      {
        Header: "Status",
        accessor: "status",
        minWidth: 200,
      },
      {
        Header: "Physical Address",
        accessor: "address",
        Cell: ({ value }) => {
          // value = project.address[]
          const physicalAddress = value?.find(
            (item) => item.use === "Physical"
          );
          // create address string to display in table
          let addressString = "";
          addressString += physicalAddress?.street
            ? `${physicalAddress?.street}, `
            : "";
          addressString += physicalAddress?.street2
            ? `${physicalAddress.street2}, `
            : "";
          addressString += physicalAddress?.city
            ? `${physicalAddress?.city}, `
            : "";
          addressString += physicalAddress?.state
            ? `${physicalAddress.state}, `
            : "";
          addressString += physicalAddress?.country
            ? `${physicalAddress.country} `
            : "";
          addressString += value?.zipCode ?? "";
          // button to open google map in new tab
          return (
            <button
              type="submit"
              alt="open map"
              className="hover:underline text-es-dark-grey"
              onClick={(e) => openMap(addressString, e)}
            >
              {addressString}
            </button>
          );
        },
        width: 350,
      },
      {
        Header: "Type",
        accessor: "projectType",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Budget",
        accessor: "contractAmount",
        Cell: ({ cell }) =>
          cell.value ? `$${reformatCurrencyString(cell.value)}` : "",
      },
      {
        Header: "Duration",
        accessor: "duration",
        Cell: ({ value }) =>
          `${value?.value || 0} ${
            typeOfDurationOptions?.find(
              (opt) => opt.value === value?.typeOfDuration
            )?.label || "days"
          }`,
        Aggregated: () => null,
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ row }) => {
          return (
            <TagList
              tags={row?.original?.currentTags}
              tagClassName="py-1 px-2"
              className="gap-2"
              showCount
            />
          );
        },
        minWidth: 200,
        filterOptions: {
          label: "Tags",
          filterType: FILTER_TYPES.tags,
        },
      },
      {
        Header: "End Date",
        accessor: "endDate",
        Cell: ({ value }) => <DateCell value={value} format="MMMM Do YYYY" />,
      },
      {
        id: "modifiedDate",
        Header: "Date Modified",
        accessor: "metadata.lastUpdated",
        Cell: ({ value }) => <DateCell value={value} />,
        filterOptions: {
          label: "Manufacture Date",
          filterType: FILTER_TYPES.date,
        },
        width: 350,
      },
    ],
    [handleFavoriteClick]
  );

  const handleRowSelect = useCallback((val) => {
    setSelectedRows(val);
  }, []);

  const onProjectRowClick = useCallback(
    (id) => {
      history.push(GET_PROJECTS_PATH(id));
    },
    [history]
  );

  return (
    <>
      <Table
        onSelectedRowChange={handleRowSelect}
        cardGrid={!isShowingProjectTable}
        cardGridComponent={(rows) => {
          return (
            <CardGrid
              resourceName="Project"
              rows={rows}
              onClick={(id) => onProjectRowClick(id)}
            />
          );
        }}
        allowSelection
        columns={projectColumns}
        data={data}
        resourceName="project"
        onRowClick={({ index }) => onProjectRowClick(data[index]?.id)}
        groupOptions={groupOptions}
        templateSettings={templateSettings}
        updateUserTemplateSettings={updateUserTemplateSettings}
        deleteUserTemplateSettings={deleteUserTemplateSettings}
        showColumnSettingsLeft={!SHOW_COLUMNS_SETTING_RIGHT}
        handleFavoriteClick={handleFavoriteClick}
        showConstantRowIndex
        fileActionsIcons={
          <TableActionsIconsGroup
            handleShowDelete={() => {
              setShowDeleteModal(true);
            }}
            canDelete={
              isShowingProjectTable &&
              hasDeleteAdministrativePermission(PROJECT, currentUser)
            }
            disabled={selectedRows?.length === 0}
            showColumnSettingsRight={
              SHOW_COLUMNS_SETTING_RIGHT && isShowingProjectTable
            }
            selectedRows={selectedRows}
            disabledColumnSettings={!isShowingProjectTable}
          />
        }
        hideSiteHeaderTitle
        showReportButton
      />

      <ProjectDeleteModal
        selectedProjects={selectedRows}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
      />
    </>
  );
};

ProjectTable.propTypes = {
  data: PropTypes.shape({}),
  handleFavoriteClick: PropTypes.func,
};

ProjectTable.defaultProps = {
  data: undefined,
  handleFavoriteClick: undefined,
};

export default ProjectTable;

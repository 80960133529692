import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useUsers from "../../../hooks/useUsers";
import SelectUserInterface from "../SelectUserInterface/SelectUserInterface";
import { getFullName } from "../../../helpers/Formatters";
import DetailCard from "../DetailCard/DetailCard";

const BudgetMembers = ({ project, dispatch, managementConfiguration }) => {
  const [users] = useUsers();

  const [memberData, setMemberData] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [currentMembers, setCurrentMembers] = useState([]);
  const [unassignedMembers, setUnassignedMembers] = useState([]);

  useEffect(() => {
    const memberDD =
      users
        ?.filter((info) => info?.name?.firstName || info?.name?.lastName)
        ?.map((info) => ({
          ...info,
          label: getFullName(info?.name),
          value: info.reference,
        })) ?? [];
    setMemberData(memberDD);
  }, [users]);

  useEffect(() => {
    let tempMembers = [];
    if (memberData?.length && project && project.members?.length) {
      tempMembers = project.members.map(({ user, position }) => {
        const data = { position, role: position };
        if (typeof user === "string") {
          data.user = user;
          data.value = user;
        } else {
          data.user = user.reference;
          data.value = user.reference;
        }
        const mem = memberData.find((item) => item.reference === data.user);
        return {
          ...mem,
          ...data,
        };
      });
    }
    setCurrentMembers(tempMembers);
  }, [memberData, project]);

  useEffect(() => {
    const currentMemberReferences = currentMembers.map((m) => m.value);

    setUnassignedMembers(
      memberData.filter(
        // Filter out members who are not current members of the property
        (member) => currentMemberReferences.indexOf(member.value) === -1
      )
    );
  }, [memberData, currentMembers]);

  useEffect(() => {
    if (managementConfiguration?.management?.project) {
      setRoleOptions(
        managementConfiguration?.management?.project?.memberPositions
          ?.filter((position) => {
            return position?.selected;
          })
          .map((position) => {
            return { label: position.display, value: position.id };
          })
      );
    }
  }, [managementConfiguration]);

  const handleAdd = (list) => {
    const newMembers = currentMembers.map((item) => {
      return { user: item.reference, position: item.position };
    });
    list.forEach((item) => {
      const newMem = { user: item.reference, position: item.position };
      newMembers.push(newMem);
      dispatch({
        type: "addMember",
        member: newMem,
      });
    });
  };

  const handleRemove = (ref) => {
    dispatch({
      type: "removeMember",
      member: ref,
    });
  };

  return (
    <div className="flex flex-col border-2 border-gray-150 p-4 my-4">
      <div className="flex flex-row w-full">
        <div className="w-1/3 pr-4 border-r-2">
          <DetailCard
            title="Project Members &amp; Positions"
            selectedUsers={currentMembers}
            displayValue=""
            users={memberData}
            options={roleOptions}
            type="USERSELECT"
            onChange={() => {}}
            onDelete={handleRemove}
            noBorder
          />
        </div>

        <div className="flex-1 ml-12 p-5">
          <SelectUserInterface
            className="w-3/4"
            userList={[]}
            userLabel="Add Member"
            userPlaceholder="Search Members"
            roleLabel="Select Position"
            rolePlaceholder="Search Positions"
            buttonText="Add Member"
            userOptions={unassignedMembers}
            roleOptions={roleOptions}
            onAddUser={(val) => handleAdd(val)}
            onRemoveUser={handleRemove}
            isSingleSelect
            disableCreateUser
          />
        </div>
      </div>
    </div>
  );
};

BudgetMembers.propTypes = {
  project: PropTypes.shape({
    members: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }),
  dispatch: PropTypes.func,
  managementConfiguration: PropTypes.shape({
    management: PropTypes.shape({
      project: PropTypes.shape({
        memberPositions: PropTypes.arrayOf(
          PropTypes.shape({
            selected: PropTypes.string,
            id: PropTypes.string,
            display: PropTypes.string,
          })
        ),
      }),
    }),
  }),
};

BudgetMembers.defaultProps = {
  project: undefined,
  dispatch: undefined,
  managementConfiguration: undefined,
};

export default BudgetMembers;

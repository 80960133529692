import { useState, useEffect, useRef, useCallback } from "react";

const useCommentsContainerData = ({
  comments,
  useReplyMode,
  onReplyPressed,
  onSend,
  selectedCommentId,
  onCloseReply,
}) => {
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const [message, setMessage] = useState("");

  useEffect(() => {
    // Scroll to the bottom of the container when new message
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }

    // Focus input when component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [comments?.length]);

  const handleSendMessage = useCallback(() => {
    setMessage((current) => {
      onSend?.(current);
      return "";
    });
  }, [onSend]);

  const handleEnter = useCallback(
    (event) => {
      // checks if the Enter key was pressed
      if (event.charCode === 13 || event.code === "Enter") {
        event.preventDefault();
        handleSendMessage();
      }
    },
    [handleSendMessage]
  );

  return {
    containerRef,
    inputRef,
    message,
    setMessage,
    handleSendMessage,
    handleEnter,
    useReplyMode,
    selectedCommentId,
    onCloseReply,
    comments,
    onReplyPressed,
  };
};

export default useCommentsContainerData;

const sortDates = (array) => {
  return array.sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);

    return dateA - dateB;
  });
};

export default sortDates;

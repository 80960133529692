import React, { useMemo } from "react";
import { PropTypes } from "prop-types";
import ModalWrapper from "../ModalWrapper";
import Dropdown from "../../Dropdown/Dropdown";
import Input from "../../Input/Input";
import useGiveTimeOffModalData from "./useGiveTimeOffModalData";
import PrimaryButton from "../../Buttons/PrimaryButton";
import ContentRow from "./ContentRow";

const GiveTimeOffModal = ({ modalData }) => {
  const {
    employeeOptions,
    formData,
    inlineStyles,
    classStyles,
    ptoTypeOptions,
    submitBtnDisabled,
    setSelectedEmployee,
    setPtoType,
    setHours,
    setNotes,
    openConfirmModal,
  } = useGiveTimeOffModalData({ modalData });

  const contentRowsArray = useMemo(() => {
    return [
      {
        title: "Employee",
        content: (
          <Dropdown
            value={formData.selectedEmployee}
            onChange={setSelectedEmployee}
            options={employeeOptions}
            placeholder="Select Employee"
          />
        ),
      },
      {
        title: "Type",
        content: (
          <Dropdown
            value={formData.type}
            onChange={setPtoType}
            options={ptoTypeOptions}
            placeholder="Select Time Off Type"
          />
        ),
      },
      {
        title: "Hours",
        content: <Input value={formData.hours} onChange={setHours} />,
      },
      {
        title: "Notes",
        content: (
          <Input value={formData.notes} onChange={setNotes} isTextarea />
        ),
      },
    ];
  }, [
    employeeOptions,
    formData.hours,
    formData.notes,
    formData.selectedEmployee,
    formData.type,
    ptoTypeOptions,
    setHours,
    setNotes,
    setPtoType,
    setSelectedEmployee,
  ]);

  return (
    <ModalWrapper
      modalData={modalData}
      width="607px"
      title="Give Time Off"
      buttons={
        <div className="pl-3">
          <PrimaryButton
            saveButtonTitle="Submit"
            onClick={openConfirmModal}
            disabled={submitBtnDisabled}
            saveButton
          />
        </div>
      }
    >
      <div style={inlineStyles.borderWrapper} className={classStyles.widgetCN}>
        {contentRowsArray.map(({ title, content }) => (
          <ContentRow key={title} title={title}>
            {content}
          </ContentRow>
        ))}
      </div>
    </ModalWrapper>
  );
};

GiveTimeOffModal.defaultProps = {
  modalData: {},
};

GiveTimeOffModal.propTypes = {
  modalData: PropTypes.shape({
    item: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default GiveTimeOffModal;

import React from "react";
import PropTypes from "prop-types";
import OwnedByList from "./OwnedByList";
import OwnedBySearch from "./OwnedBySearch";
import useOwnedByData from "./useOwnedByData";

const OwnedBy = ({ editing, ownedBy, onChange, customWidth, showLabel }) => {
  const {
    ownerNames,
    originalUserList,
    userOptions,
    searchState,
    editedUser,
    creatingUser,
    showPopup,
    wrapperRef,
    toggleList,
    onCreate,
    handleSearch,
    onEdit,
    onRemove,
    setShowPopup,
    onAdd,
    setEditing,
    setCreatingUser,
  } = useOwnedByData({ ownedBy, onChange });

  return (
    <div ref={wrapperRef} className="w-full">
      {editing && (
        <div className="relative flex flex-col">
          {showLabel && <p className="ESInputLabel mb-2">Owned by</p>}
          <div
            onClick={toggleList}
            role="button"
            onKeyDown={() => {}}
            tabIndex={0}
            aria-label="toggle"
          >
            <OwnedBySearch
              options={originalUserList}
              onChange={handleSearch}
              onRemove={onRemove}
              value={searchState}
              selectedOptions={ownedBy}
              showPopup={showPopup}
              toggleList={toggleList}
              setShowPopup={setShowPopup}
              customWidth={customWidth}
            />
          </div>
          {showPopup && (
            <div className="absolute left-0 -bottom-2 w-full">
              <OwnedByList
                members={userOptions}
                selectedMembers={ownedBy}
                onAdd={onAdd}
                onRemove={onRemove}
                onCreate={onCreate}
                onEdit={onEdit}
                setEditing={setEditing}
                editedUser={editedUser}
                creatingUser={creatingUser}
                setCreatingUser={setCreatingUser}
                customWidth={customWidth}
              />
            </div>
          )}
        </div>
      )}
      {!editing && (
        <p style={{ width: customWidth }} className="w-full">
          {ownedBy?.length ? ownerNames.map((o) => o.label).join(", ") : "N/A"}
        </p>
      )}
    </div>
  );
};

OwnedBy.propTypes = {
  ownedBy: PropTypes.arrayOf({
    reference: PropTypes.string,
  }),
  editing: PropTypes.bool,
  onChange: PropTypes.func,
  showLabel: PropTypes.bool,
  customWidth: PropTypes.string,
};

OwnedBy.defaultProps = {
  ownedBy: [],
  editing: false,
  onChange: undefined,
  showLabel: false,
  customWidth: undefined,
};
export default OwnedBy;

import { useMutation, useQueryClient } from "react-query";
import { uploadFileWithData } from "../helpers/File";
import { filePaginatedKeys } from "../config/reactQuery/queryKeyFactory";
import { toastError } from "../stories/Components/Toast/Toast";
import useProgressBar from "./useProgressBar";

const FILE_SIZE_LIMIT_MB = 150;
const BYTES_IN_MB = 1024 * 1024;

/**
 * Post files in backend
 * @param {Object []} files array of files
 * @returns array of files data
 */
const postFile = async (files = [], handleProgressBar) => {
  // Get the total sizes of files and restrict size greater than 150 mb
  const totalSizeBytes = files.reduce(
    (total, file) => total + file.original.size,
    0
  );

  const totalSizeMB = totalSizeBytes / BYTES_IN_MB;

  if (totalSizeMB > FILE_SIZE_LIMIT_MB) {
    throw new Error(
      `File size exceeds the limit of (${FILE_SIZE_LIMIT_MB} Mb).`
    );
  }

  // Map to store the progress of each file being uploaded
  const filesProgressMap = {};

  const response = await Promise.all(
    files.map(({ name, original }) => {
      const data = {
        name,
        contentType: original.type,
        size: original.size,
        type: original.type,
      };

      /**
       * Handles the upload progress of a file.
       * @param {number} loaded - The amount of data that has been loaded.
       * @param {number} total - The total size of the file being uploaded.
       */
      const handleUploadProgress = (loaded, total) => {
        filesProgressMap[name] = loaded / total;

        const overalProgress = filesProgressMap[name]
          ? Object.values(filesProgressMap).reduce(
              (sum, current) => sum + current,
              0
            )
          : 0;

        handleProgressBar(overalProgress, files.length);
      };

      return uploadFileWithData(
        original,
        data,
        handleUploadProgress,
        undefined,
        true,
        undefined,
        true
      );
    })
  );

  return response;
};

/**
 * Mutation hook to post an array of files
 * @param {string} mutationKey (?) key to track this mutation
 */
const useFilesPost = (mutationKey) => {
  const queryClient = useQueryClient();

  const { showProgress, updateProgress, dissmissProgress } = useProgressBar();

  return useMutation({
    mutationKey,
    mutationFn: (files) => postFile(files, updateProgress),
    onMutate: () => showProgress(),
    onError: (error) => {
      let errorMessage = "There was an issue adding files. Try again";

      if (error?.message?.includes("File size exceeds")) {
        errorMessage = error.message;
      }

      toastError(errorMessage);
      console.error("useFilesPost", error);
    },
    onSettled: () => {
      // Dissmises toast when files upload is completed or if error
      dissmissProgress();

      // Refetch files
      queryClient.invalidateQueries(filePaginatedKeys.allFiles);
    },
  });
};

export default useFilesPost;

import { BudgetAPI } from "@griffingroupglobal/eslib-api";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { budgetLineitemKeys } from "../config/reactQuery/queryKeyFactory";
import useFinancialsConfiguration from "./useFinancialsConfiguration";
import {
  formatGroupDropdownForBudgetTable,
  formatLineitem,
} from "../helpers/Budget";
import { budgetTypesOptions } from "../constants";

const fetchBudgetLineitemsByInvoice = async (
  budgetId,
  invoiceRef,
  financialsConfiguration
) => {
  const { data } = await BudgetAPI.getById(budgetId, {
    params: { invoice: invoiceRef },
  });

  const groups = formatGroupDropdownForBudgetTable(data.groups);

  return {
    budgetLineItems: data.lineItems.map((li) =>
      formatLineitem(
        li,
        financialsConfiguration?.financials?.divisionsObject,
        financialsConfiguration?.financials?.csiCodeMappingObject,
        groups
      )
    ),
    budgetGroups: groups,
    budgetLastUpdated: data?.metadata?.lastUpdated,
    budgetType:
      budgetTypesOptions?.find((type) => type.value === data.budgetType) ??
      data.budgetType,
    budgetSummary: data?.summary,
    budgetPendingUpdates: data?.pendingUpdates,
    isLockGmp: data?.isLockGmp,
    isLockFixedFirm: data?.isLockFixedFirm,
    budget: data,
  };
};
/**
 * Query hook to fetch budget lineitems by invoice
 * @returns query hook
 */
const useGetBudgetLineitemByInvoice = (budgetId, invoiceRef) => {
  const { data: financialsConfiguration } = useFinancialsConfiguration();
  const queryKey = budgetLineitemKeys.budgetLineitemsByInvoice(
    budgetId,
    invoiceRef
  );

  return useAuthenticatedQuery({
    queryKey,
    enabled: !!budgetId && !!invoiceRef,
    queryFn: () =>
      fetchBudgetLineitemsByInvoice(
        budgetId,
        invoiceRef,
        financialsConfiguration
      ),
    onError: (error) => {
      console.error("Error fetching Budget lineitem  by invoice", error);
    },
  });
};

export default useGetBudgetLineitemByInvoice;

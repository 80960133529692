import React from "react";
import PropTypes from "prop-types";
import InputMentions from "../InputMentions";
import ModalWrapper from "../EsModal/ModalWrapper";
import PrimaryButton from "../Buttons/PrimaryButton";
import useCreateWFCommentModalData from "./useCreateWFCommentModalData";

const CreateWFCommentModal = ({ modalData = {} }) => {
  const { mentionSuggestions, close, message, setMessage } =
    useCreateWFCommentModalData(modalData);
  return (
    <ModalWrapper
      modalData={modalData}
      title="Add a Comment"
      buttons={
        <div className="flex px-8 gap-4 w-full justify-end">
          <PrimaryButton
            saveButton
            saveButtonTitle="Save"
            onClick={() => {
              modalData?.item?.createComment(message);
              close();
            }}
            className="px-3"
            disabled={message === ""}
          />
        </div>
      }
      trashCan
      width="839px"
    >
      <div className="bg-white pb-4 mb-9 ">
        <InputMentions
          autoFocus
          placeholder="Type in comment. Type @ to mention and notify someone."
          trigger="@"
          text={message}
          onChange={(e) => setMessage(e.target.value)}
          resizable={false}
          data={mentionSuggestions}
        />
      </div>
    </ModalWrapper>
  );
};

CreateWFCommentModal.propTypes = {
  modalData: PropTypes.shape({}),
};

CreateWFCommentModal.defaultProps = {
  modalData: {},
};

export default CreateWFCommentModal;

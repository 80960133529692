import React from "react";
import TaskRow from "./TaskRow";

const TaskList = ({
  tasks = [],
  handleTasksToDelete,
  handleNavigateToTask,
  showAddAndDeleteTasks,
}) => {
  return (
    <div className="mr-1">
      {tasks.map((task, index) => (
        <TaskRow
          key={task?.id}
          task={task}
          index={index + 1}
          handleNavigateToTask={handleNavigateToTask}
          handleTasksToDelete={handleTasksToDelete}
          showAddAndDeleteTasks={showAddAndDeleteTasks}
        />
      ))}
    </div>
  );
};

export default TaskList;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line import/no-cycle
import BudgetTable from "../BudgetTable/BudgetTable";
import AttachItemModal from "../AttachItemModal/AttachItemModal";
import {
  contingencyColumns,
  budgetAdjustmentsColumns,
} from "../DocumentForm/DocumentBudgetColumns";

import Spinner from "../Spinner/Spinner";
import Table from "../Table/Table";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import TertiaryButton from "../Buttons/TertiaryButton";
import {
  ADD_LINE_ITEMS,
  CONTINGENCY_AUTHORIZATION,
  REMOVE_LINE_ITEMS,
  docLineItemAdjustmentKeys,
  BUDGET_COST_PLUS,
  PURCHASE_AUTHORIZATION,
  REMOVE_BUDGET_LINE,
  REMOVE_FORM_DATA,
  CONTINGENGY_CSI_CODE,
  CONTINGENGY_LINE_ITEM_DESCRIPTION,
} from "../../../constants";

const deleteButtonStyle = {
  marginTop: "22px",
};
const filteredColumns = ["publishedBudget", "uncommittedValue", "liveBudget"];
const rowBodyStyles = { minHeight: "270px" };

const DocumentAdjustments = ({
  document,
  dispatch,
  property,
  project,
  budget,
  budgetId,
  selectedBudgetRows,
  onBudgetRowSelect,
  originalLineItems,
  csiCodes,
  modalView,
  userList,
  disableAdd,
}) => {
  const [contingencyLineItem, setContingencyLineItem] = useState();
  const [prevRowCount, setPrevRowCount] = useState(0);
  const [adjustmentKey, setAdjustmentKey] = useState(
    docLineItemAdjustmentKeys[document?.docType]
  );
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [selectedRowsToAdd, setSelectedRowsToAdd] = useState([]);
  const [showRowsModal, setShowRowsModal] = useState(false);

  const { budgetType, isLockGmp } = budget ?? {};
  const costPlusLocked = budgetType === BUDGET_COST_PLUS && isLockGmp;

  useEffect(() => {
    if (userList?.length) {
      setUserData(userList);
    }
  }, [userList]);

  useEffect(() => {
    setAdjustmentKey(docLineItemAdjustmentKeys[document?.docType]);
  }, [document?.docType]);

  useEffect(() => {
    const adjustmentSum = Object.entries(document?.state?.lineItemData ?? {})
      .filter(([key]) => {
        return selectedBudgetRows.includes(key);
      })
      .reduce((sum, [, val]) => {
        return sum + val[adjustmentKey] || 0;
      }, 0);

    setContingencyLineItem((prev) => ({
      ...(prev ?? {}),
      adjustmentAmount: adjustmentSum,
    }));
  }, [document?.state?.lineItemData, selectedBudgetRows, adjustmentKey]);

  useEffect(() => {
    const contingencyLine = originalLineItems
      .filter((item) => {
        return CONTINGENGY_CSI_CODE.includes(item.csiCode);
      })
      .reduce(
        (obj, item) => {
          const val = {
            uncommittedValue:
              obj.uncommittedValue + (Number(item.uncommittedValue) || 0),
            adjustmentAmount:
              obj.adjustmentAmount + (Number(item.adjustmentAmount) || 0),
            uncommittedValuePending:
              obj.uncommittedValuePending +
              (Number(item.uncommittedValuePending) || 0),
            updatedGmp: obj.updatedGmp + (Number(item.updatedGmp) || 0),
            updatedGmpPending:
              obj.updatedGmpPending + (Number(item.updatedGmpPending) || 0),
            vendor: obj.vendor || item.vendor,
          };
          return val;
        },
        {
          uncommittedValue: 0,
          adjustmentAmount: 0,
          uncommittedValuePending: 0,
          updatedGmp: 0,
          updatedGmpPending: 0,
          vendor: "",
        }
      );

    const adjustmentSum = Object.entries(document?.state?.lineItemData ?? {})
      .filter(([key]) => {
        return selectedBudgetRows.includes(key);
      })
      .reduce((sum, [, val]) => {
        return sum + val[adjustmentKey] || 0;
      }, 0);

    contingencyLine.adjustmentAmount = adjustmentSum;
    contingencyLine.csiCode =
      csiCodes.find((code) => CONTINGENGY_CSI_CODE.includes(code.value))
        ?.label ?? CONTINGENGY_CSI_CODE;
    contingencyLine.description = CONTINGENGY_LINE_ITEM_DESCRIPTION;
    contingencyLine.vendorName = userData.find(
      (user) => user.value === contingencyLine.vendor
    )?.label;

    setContingencyLineItem((prev) => {
      return { ...prev, ...contingencyLine };
    });
  }, [
    document?.state?.lineItemData,
    originalLineItems,
    selectedBudgetRows,
    csiCodes,
    adjustmentKey,
    userData,
  ]);

  const handleShowRowsModal = () => {
    setShowRowsModal(true);
  };

  const handleCloseRowsModal = () => {
    setShowRowsModal(false);
  };

  const handleAddRows = () => {
    dispatch({
      type: ADD_LINE_ITEMS,
      value: selectedRowsToAdd.map((item) => {
        return { ...item, checked: false };
      }),
    });
    onBudgetRowSelect(selectedRowsToAdd.map((item) => item.id));
    setShowRowsModal(false);
  };

  const handleRemoveRows = ({ original }) => {
    dispatch({
      type: REMOVE_LINE_ITEMS,
      value: [original],
    });

    dispatch({
      type: REMOVE_BUDGET_LINE,
      key: original.id,
    });
    if (document.docType === PURCHASE_AUTHORIZATION) {
      dispatch({
        type: REMOVE_FORM_DATA,
        key: PURCHASE_AUTHORIZATION,
        value: original.id,
      });
    }
  };

  const handleLineUpdate = React.useCallback(
    (action) => {
      const { key, value } = action ?? {};
      const itemData = document.state?.lineItemData?.[key] ?? {};
      setContingencyLineItem((prev) => {
        return {
          ...prev,
          adjustmentAmount:
            (prev.adjustmentAmount || 0) +
            (value[adjustmentKey] || 0) -
            (itemData[adjustmentKey]?.amount || 0),
        };
      });

      dispatch(action);
    },
    [document.state?.lineItemData, dispatch, adjustmentKey]
  );

  const handleBudgetRowSelect = React.useCallback(
    (val) => {
      const rows = val.map((item) => item.id);
      if (rows.length > selectedBudgetRows?.length) {
        const added = rows.filter((item) => !selectedBudgetRows.includes(item));
        const addedAmount = added.reduce((sum, id) => {
          const itemData = document.state?.lineItemData?.[id] ?? {};
          return sum + itemData[adjustmentKey] || 0;
        }, 0);
        if (addedAmount) {
          setContingencyLineItem((prev) => {
            return {
              ...prev,
              adjustmentAmount: (prev?.adjustmentAmount || 0) + addedAmount,
            };
          });
        }
        onBudgetRowSelect(rows);
      } else if (
        (rows.length > 0 && rows.length < selectedBudgetRows?.length) ||
        (rows.length === 0 &&
          prevRowCount === 1 &&
          rows.length < selectedBudgetRows?.length)
      ) {
        const removed = selectedBudgetRows.filter(
          (item) => !rows.includes(item)
        );
        const subtractedAmount = removed.reduce((sum, id) => {
          const itemData = document.state?.lineItemData?.[id] ?? {};
          return sum + itemData[adjustmentKey] || 0;
        }, 0);
        if (subtractedAmount) {
          setContingencyLineItem((prev) => {
            return {
              ...prev,
              adjustmentAmount:
                (prev?.adjustmentAmount || 0) - subtractedAmount,
            };
          });
        }
        onBudgetRowSelect(rows);
      } else if (!rows.length && prevRowCount !== 0) {
        setContingencyLineItem((prev) => {
          return {
            ...prev,
            adjustmentAmount: 0,
          };
        });
        onBudgetRowSelect(rows);
      }

      setPrevRowCount(rows.length);
    },
    [
      document,
      selectedBudgetRows,
      onBudgetRowSelect,
      adjustmentKey,
      prevRowCount,
    ]
  );

  const adjustmentsColumns = React.useMemo(
    () =>
      budgetAdjustmentsColumns(
        document,
        budget,
        handleLineUpdate,
        selectedBudgetRows,
        true,
        [
          ...filteredColumns,
          ...(modalView ? [] : ["paAmount", "uncommittedValuePending"]),
        ],
        contingencyLineItem,
        null,
        project
      ),
    [
      document,
      budget,
      handleLineUpdate,
      selectedBudgetRows,
      modalView,
      contingencyLineItem,
      project,
    ]
  );

  const contingencyItemColumns = React.useMemo(
    () => contingencyColumns(document, budget),
    [document, budget]
  );

  const docBudgetLines = React.useMemo(
    () =>
      document.workingLineItems
        ?.filter((item) => {
          const lineData = document.state?.lineItemData?.[item.id] ?? {};
          return (
            modalView ||
            !costPlusLocked ||
            document.docType !== PURCHASE_AUTHORIZATION ||
            !!lineData[docLineItemAdjustmentKeys[document.docType]]
          );
        })
        .map((item) => {
          const lineData = document.state?.lineItemData?.[item.id] ?? {};
          return {
            ...item,
            csiCode:
              csiCodes.find(
                (code) => code.value === item.csiCode.split(" -")[0]
              )?.label ?? "",
            ...lineData,
          };
        }),
    [
      document.workingLineItems,
      document.docType,
      document.state,
      csiCodes,
      costPlusLocked,
      modalView,
    ]
  );

  return (
    <>
      {document.docType === CONTINGENCY_AUTHORIZATION && (
        <>
          <SecondaryHeader className="mt-6 mb-6">
            Contingency Reference
          </SecondaryHeader>
          <Table
            className=""
            resourceName="budget-items"
            data={contingencyLineItem?.csiCode ? [contingencyLineItem] : []}
            hiddenColumns={["netUncommitted"]}
            columns={contingencyItemColumns}
            cellStyling="flex items-center h-16"
            cellTextStyling="w-full pb-1"
            showEditColumns
            showHeader={false}
            hideCreateNewButton
            hideSiteHeader
            hideChangeView
            hideSaveButton
            disableHover
          />
        </>
      )}

      <SecondaryHeader className="mt-6 mb-6">Budget References</SecondaryHeader>
      <Table
        className=""
        resourceName="budget-items"
        data={docBudgetLines}
        columns={adjustmentsColumns}
        hiddenColumns={["netUncommitted"]}
        cellStyling={
          document.docType === PURCHASE_AUTHORIZATION
            ? "h-min"
            : "flex items-center h-16"
        }
        cellTextStyling={
          document.docType === PURCHASE_AUTHORIZATION
            ? "flex items-center w-full py-2"
            : "w-full pb-1"
        }
        rowBodyStyles={rowBodyStyles}
        deleteButtonStyle={deleteButtonStyle}
        initialSelectedRows={selectedBudgetRows}
        onSelectedRowChange={handleBudgetRowSelect}
        onDeleteRowClick={handleRemoveRows}
        showHeader={false}
        showEditColumns
        showFooter
        hideCreateNewButton
        hideSiteHeader
        hideChangeView
        hideSaveButton
        enableDeleting
        noDeleteConfirm
        disableHover
      />
      {!disableAdd && (
        <div className="flex">
          <TertiaryButton title="+ Add More" onClick={handleShowRowsModal} />
        </div>
      )}

      <AttachItemModal
        content={
          <>
            <div
              className={
                isLoading
                  ? "flex justify-center items-center w-full h-full"
                  : "hidden"
              }
            >
              <Spinner notFullScreen />
            </div>
            <div className={isLoading ? "hidden" : ""}>
              <BudgetTable
                budgetId={budgetId}
                projectId={project.id}
                projectData={project}
                propertyData={property}
                onRowSelect={setSelectedRowsToAdd}
                setIsLoading={setIsLoading}
                documentModalView
                hideSiteHeader
                disableContingencyLineitem={
                  document.docType === CONTINGENCY_AUTHORIZATION
                }
                disableSelectAllCheckbox={
                  document.docType === CONTINGENCY_AUTHORIZATION
                }
                disbaleRowActions
              />
            </div>
          </>
        }
        isOpen={showRowsModal}
        onRequestModalClose={handleCloseRowsModal}
        onPrimaryClick={handleAddRows}
      />
    </>
  );
};

DocumentAdjustments.propTypes = {
  /**
   * document form state
   */
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.object,
  /**
   * document form dispatcher
   */
  dispatch: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  property: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  budget: PropTypes.object,
  budgetId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  selectedBudgetRows: PropTypes.array,
  onBudgetRowSelect: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  originalLineItems: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  csiCodes: PropTypes.array,
  modalView: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  userList: PropTypes.arrayOf(PropTypes.object),
  disableAdd: PropTypes.bool,
};

DocumentAdjustments.defaultProps = {
  document: {},
  dispatch: undefined,
  property: {},
  project: {},
  budget: undefined,
  budgetId: undefined,
  selectedBudgetRows: [],
  onBudgetRowSelect: () => {},
  originalLineItems: [],
  csiCodes: [],
  modalView: undefined,
  userList: [],
  disableAdd: false,
};

export default DocumentAdjustments;

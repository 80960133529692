import { useEffect, useState } from "react";
import useIsSignedIn from "../hooks/useIsSignedIn";
import AuthInterceptor from "./AuthInterceptor";
import TenantInterceptor from "./TenantInterceptor";
import RefreshAuthInterceptor from "./RefreshAuthInterceptor";
import CompressionInterceptor from "./CompressionInterceptor";
import { SignupInterceptor } from "./SignupInterceptor";
import ApiVersionInterceptor from "./ApiVersionInterceptor";
import useIsSiteMaintenance from "../hooks/useIsSiteMaintenance";

const WithAxiosInterceptors = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [, setIsSignedIn] = useIsSignedIn();
  const [, setIsSiteMaintenance] = useIsSiteMaintenance();

  useEffect(() => {
    AuthInterceptor();
    TenantInterceptor();
    ApiVersionInterceptor();
    RefreshAuthInterceptor(setIsSignedIn, setIsSiteMaintenance);
    CompressionInterceptor();
    SignupInterceptor(setIsSignedIn);
    setLoading(false);
  }, [setIsSiteMaintenance, setIsSignedIn]);

  return loading ? null : children;
};

export default WithAxiosInterceptors;

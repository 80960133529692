import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

import Pill from "../Pill/Pill";
import "./Tab.css";

const tabCN = (className) => cntl`
  flex
  rounded-t
  select-none
  ${className}
`;

const tabTextContainerCN = (titleClassName) => cntl`
  flex
  items-center
  my-1
  ${titleClassName}
`;

const tabTextCN = (isSelected, hideUnderline, centeredTab) => cntl`
  relative
  justify-center
  items-start
  h-8
  text-xs
  font-medium
  tracking-wider
  uppercase
  whitespace-nowrap
  ${isSelected && !hideUnderline ? "border-b-2 border-brandGreen" : ""}
  ${centeredTab ? "mx-4" : "mr-8"}
`;

const tabTitleCN = (isSelected, largeFont) => cntl`
  text-base
  capitalize
  px-0.5
  ${largeFont ? "text-lg" : ""}
  ${isSelected ? "font-bold" : "font-semibold"}
  ${isSelected ? "text-gray-650" : "text-gray-300"}
  ${isSelected && "left-0"}
`;

const underlineCN = (isSelected, hideUnderline) => cntl`
  ${
    isSelected && hideUnderline
      ? "flex absolute top-9 w-full border border-brandGreen z-10"
      : ""
  }
`;

const Tab = ({
  title,
  pill,
  isSelected,
  titleClassName,
  className,
  onClick,
  largeFont,
  hideUnderline,
  centeredTab,
}) => {
  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      onClick();
    }
  };

  return (
    <>
      <div
        className={tabCN(isSelected, hideUnderline, className)}
        onClick={onClick}
        onKeyDown={onKeyDown}
        role="button"
        tabIndex={0}
      >
        <div className={tabTextContainerCN(titleClassName)}>
          <div className="relative">
            <div className={tabTextCN(isSelected, hideUnderline, centeredTab)}>
              <p className={tabTitleCN(isSelected, largeFont)}>{title}</p>
            </div>
            <div className={underlineCN(isSelected, hideUnderline)} />
          </div>
          {pill?.value > -1 ? (
            <Pill
              className="ml-3"
              value={pill?.value}
              background={pill?.background}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

Tab.propTypes = {
  /**
   * the title to show on the tab
   */
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element,
  ]).isRequired,
  /**
   * object containing pill props
   */
  // eslint-disable-next-line react/forbid-prop-types
  pill: PropTypes.object,
  /**
   * sets the selected state for the tab
   */
  isSelected: PropTypes.bool,
  /**
   * className for tab titles
   */
  titleClassName: PropTypes.string,
  /**
   * make title text larg
   */
  largeFont: PropTypes.bool,
  /**
   * Tab Class Name
   */
  className: PropTypes.string,
  /**
   * function called when clicked
   */
  onClick: PropTypes.func,
  /**
   * hides green underline
   */
  hideUnderline: PropTypes.bool,
  /**
   * centers the text in in the individual tab container
   */
  centeredTab: PropTypes.bool,
};

Tab.defaultProps = {
  pill: undefined,
  isSelected: false,
  titleClassName: null,
  largeFont: false,
  className: undefined,
  onClick: null,
  hideUnderline: false,
  centeredTab: false,
};

export default Tab;

import { useState, useEffect, useMemo } from "react";
import { calendarViewOptions } from "../constants";

function observeSiteHeaderHooks(target) {
  let listeners = []; // initial listeners can be passed an an argument as well
  let value = target;

  function get() {
    return value;
  }

  function set(newValue) {
    if (value === newValue) return;
    value = newValue;
    listeners.forEach((l) => l(value));
  }

  function unsubscribe(listenerFunc) {
    listeners = listeners.filter((l) => l !== listenerFunc);
  }

  function subscribe(listenerFunc) {
    listeners.push(listenerFunc);
    return () => unsubscribe(listenerFunc); // will be used inside React.useEffect
  }

  return {
    get,
    set,
    subscribe,
  };
}

/*
 * Switch View for Assets
 */
const switchViewAssetStore = observeSiteHeaderHooks(false);

export function useAssetSwitchView() {
  const [switchView, setSwitchView] = useState(switchViewAssetStore.get());

  useEffect(() => {
    return switchViewAssetStore.subscribe(setSwitchView);
  }, []);

  const actions = useMemo(() => {
    return (bool) => switchViewAssetStore.set(bool);
  }, []);

  return [switchView, actions];
}

/*
 * Switch View for Contacts
 */

const switchViewContactStore = observeSiteHeaderHooks(false);

export function useContactSwitchView() {
  const [switchView, setSwitchView] = useState(switchViewContactStore.get());

  useEffect(() => {
    return switchViewContactStore.subscribe(setSwitchView);
  }, []);

  const actions = useMemo(() => {
    return (bool) => switchViewContactStore.set(bool);
  }, []);

  return [switchView, actions];
}

/*
 * Switch View for Documents
 */
const switchViewDocumentStore = observeSiteHeaderHooks(false);

export function useDocumentSwitchView() {
  const [switchView, setSwitchView] = useState(switchViewDocumentStore.get());

  useEffect(() => {
    return switchViewDocumentStore.subscribe(setSwitchView);
  }, []);

  const actions = useMemo(() => {
    return (bool) => switchViewDocumentStore.set(bool);
  }, []);

  return [switchView, actions];
}

/*
 * Switch View for Projects
 */
const switchViewProjectStore = observeSiteHeaderHooks(false);

export function useProjectSwitchView() {
  const [switchView, setSwitchView] = useState(switchViewProjectStore.get());

  useEffect(() => {
    return switchViewProjectStore.subscribe(setSwitchView);
  }, []);

  const actions = useMemo(() => {
    return (bool) => switchViewProjectStore.set(bool);
  }, []);

  return [switchView, actions];
}

/*
 * Switch View for Projects
 */
const switchViewSopStore = observeSiteHeaderHooks(false);

export function useSopSwitchView() {
  const [switchView, setSwitchView] = useState(switchViewSopStore.get());

  useEffect(() => {
    return switchViewSopStore.subscribe(setSwitchView);
  }, []);

  const actions = useMemo(() => {
    return (bool) => switchViewSopStore.set(bool);
  }, []);

  return [switchView, actions];
}

/*
 * Switch View for Properties
 */
const switchViewPropertyStore = observeSiteHeaderHooks(false);

export function usePropertySwitchView() {
  const [switchView, setSwitchView] = useState(switchViewPropertyStore.get());

  useEffect(() => {
    return switchViewPropertyStore.subscribe(setSwitchView);
  }, []);

  const actions = useMemo(() => {
    return (bool) => switchViewPropertyStore.set(bool);
  }, []);

  return [switchView, actions];
}

/*
 * Switch View for Task
 */
const switchViewTaskStore = observeSiteHeaderHooks(false);

export function useTaskSwitchView() {
  const [switchView, setSwitchView] = useState(switchViewTaskStore.get());

  useEffect(() => {
    return switchViewTaskStore.subscribe(setSwitchView);
  }, []);

  const actions = useMemo(() => {
    return (bool) => switchViewTaskStore.set(bool);
  }, []);

  return [switchView, actions];
}

/*
 * Switch View for Workflows
 */
const switchViewWorkflowStore = observeSiteHeaderHooks(false);

export function useWorkflowSwitchView() {
  const [switchView, setSwitchView] = useState(switchViewWorkflowStore.get());

  useEffect(() => {
    return switchViewWorkflowStore.subscribe(setSwitchView);
  }, []);

  const actions = useMemo(() => {
    return (bool) => switchViewWorkflowStore.set(bool);
  }, []);

  return [switchView, actions];
}

/*
 * Switch View for Workflows
 */
const switchViewSubmittalStore = observeSiteHeaderHooks(false);

export function useSubmittalSwitchView() {
  const [switchView, setSwitchView] = useState(switchViewSubmittalStore.get());

  useEffect(() => {
    return switchViewSubmittalStore.subscribe(setSwitchView);
  }, []);

  const actions = useMemo(() => {
    return (bool) => switchViewSubmittalStore.set(bool);
  }, []);

  return [switchView, actions];
}

/*
 * Switch View for Projects
 */
const switchViewReportStore = observeSiteHeaderHooks(false);

export function useReportSwitchView() {
  const [switchView, setSwitchView] = useState(switchViewReportStore.get());

  useEffect(() => {
    return switchViewReportStore.subscribe(setSwitchView);
  }, []);

  const actions = useMemo(() => {
    return (bool) => switchViewReportStore.set(bool);
  }, []);

  return [switchView, actions];
}

/*
 * Switch View for Calendar
 */
const switchCalendarStore = observeSiteHeaderHooks(calendarViewOptions[0]);

export function useCalendarSwitchView() {
  const [switchView, setSwitchView] = useState(switchCalendarStore.get());

  useEffect(() => {
    return switchCalendarStore.subscribe(setSwitchView);
  }, []);

  const actions = useMemo(() => {
    return (obj) => switchCalendarStore.set(obj);
  }, []);

  return [switchView, actions];
}

/*
 * Switch View for RFI
 */
const switchViewRfiStore = observeSiteHeaderHooks(false);

export function useRfiSwitchView() {
  const [switchView, setSwitchView] = useState(switchViewRfiStore.get());

  useEffect(() => {
    return switchViewRfiStore.subscribe(setSwitchView);
  }, []);

  const actions = useMemo(() => {
    return (bool) => switchViewRfiStore.set(bool);
  }, []);

  return [switchView, actions];
}

/*
 * Switch View for Spaces
 */
const switchViewSpaceStore = observeSiteHeaderHooks(false);

export function useSpaceSwitchView() {
  const [switchView, setSwitchView] = useState(switchViewSpaceStore.get());

  useEffect(() => {
    return switchViewSpaceStore.subscribe(setSwitchView);
  }, []);

  const actions = useMemo(() => {
    return (bool) => switchViewSpaceStore.set(bool);
  }, []);

  return [switchView, actions];
}

import { useCallback } from "react";
import { getUserFirstAndLast } from "../../helpers/Utilities";
import { useGetTags } from "../useTags";
import { useUsers } from "../useUsers.new";
import useProperties from "./useProperties";

/**
 * Query selector to transform properties to table format
 * @param {Object[]} propertyTypes - List of property types
 * @param {Object[]} favorites - List of favorites properties
 */
const usePropertiesTableFormat = (propertyTypes = [], favorites = []) => {
  const { data: tagsData } = useGetTags();
  const { data: { userDict } = {} } = useUsers();
  const tagsDict = tagsData?.tagsDict;

  /**
   * Selector to transform properties to table format
   */
  const formatPropertyTableData = useCallback(
    (properties) => {
      return properties.map((property) => {
        let typeLabel;

        if (propertyTypes) {
          typeLabel = propertyTypes.filter(
            (val) => property?.propertyType === val?.value
          );
        }

        return {
          id: property.id,
          image: property.primaryImage,
          title: property.title,
          address: property.address,
          lotSize: property?.lotSize,
          description: property?.description,
          sf: property.area?.value || 0,
          bedrooms: property.bedrooms || 0,
          bathrooms: property.bathrooms || 0,
          isInvestment: property.isInvestment,
          notes: property.notes,
          propertyType: typeLabel[0]?.label,
          lastUpdated: property?.metadata?.lastUpdated,
          purchase: property?.purchase,
          interestRate: property?.interestRate,
          mortgage: property?.mortgageAmount?.price || 0,
          addAnother: property?.addAnother,
          propertyTypeId: property.propertyType,
          tags: property?.tags,
          currentTags: property?.tags?.map((tagRef) => ({
            label: tagsDict[tagRef]?.label,
            value: tagsDict[tagRef]?.reference,
          })),
          isFavorited:
            property.isFavorited ??
            favorites.some((fav) => fav.item.reference.includes(property.id)),
          ownedBy:
            (
              property?.ownedBy?.reduce((acc, item) => {
                const user = userDict?.[item?.reference];
                if (user) {
                  const fullName = getUserFirstAndLast(user);
                  acc.push(fullName);
                }
                return acc;
              }, []) || []
            ).join(", ") || "Not specified",
        };
      });
    },
    [propertyTypes, favorites, tagsDict, userDict]
  );

  return useProperties({
    select: formatPropertyTableData,
  });
};

export default usePropertiesTableFormat;

/* eslint-disable no-param-reassign */
import { AssetAPI } from "@griffingroupglobal/eslib-api";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  PUSH_USER_EVENTS_DICT,
  REMOVE_ASSOCIATED_EVENT,
  REMOVE_USER_EVENT,
  SET_ALL_EVENTS_DICT,
  SET_ALL_UNFORMATTED_EVENTS,
  SET_ASSOCIATED_EVENTS_DICT,
  SET_EVENTS,
  SET_USER_EVENTS_DICT,
  UPDATE_ASSOCIATED_EVENTS_DICT,
  UPDATE_USER_EVENTS_DICT,
} from "../constants";
import formatUserEvents from "../helpers/Calendar/formatUserEvents";
import { DateIsAfter, DateIsBefore, DateIsBetween } from "../helpers/Dates";
import { useAppState } from "../state/appState";

export default ({
  startDate: left,
  endDate: right,
  assetRef,
  user,
  association,
}) => {
  const span = useRef({ left, right });

  const [
    {
      events,
      userEvents,
      associatedEvents,
      eventsDict,
      assetsDict,
      unformattedEvents,
    },
    dispatch,
  ] = useAppState([]);
  const [loading, setLoading] = useState(true);

  const reload = useCallback(
    async (month) => {
      setLoading(true);
      let params = {
        params: {
          left,
          right,
        },
      };

      /**
       * Check if date is between current fetched timespan
       */
      const queryCheck = DateIsBetween(
        span.current?.left,
        span.current?.right,
        month
      );

      /**
       * If
       * After || Before - configure query params & update timespan
       */
      if (!queryCheck) {
        if (DateIsAfter(month, span.current?.right)) {
          params = {
            params: {
              left: span?.current?.left,
              // Query Month ahead because some days from next month may be shown on calendar
              right: moment(month).add(1, "month")?.endOf("month")?.format(),
            },
          };
          span.current = {
            ...span.current,
            right: moment(month)?.endOf("month")?.format(),
          };
        }
        if (DateIsBefore(month, span.current?.left)) {
          params = {
            params: {
              left: moment(month)?.startOf("month")?.format(),
              right: span.current?.right,
            },
          };
          span.current = {
            ...span.current,
            left: moment(month)?.startOf("month")?.format(),
          };
        }
      }

      if (!month || !queryCheck) {
        if (assetRef) {
          params.params.assets = [assetRef];
        }
        if (association) {
          params.params.association = association;
          let assetQueryString;
          if (association.toLowerCase().includes("property")) {
            assetQueryString = `?property=${association}`;
          }
          if (association.toLowerCase().includes("project")) {
            assetQueryString = `?project=${association}`;
          }
          const assets = await AssetAPI.getWOP(assetQueryString);
          const assetQuery = assets?.data?.entries?.reduce((str, item) => {
            const ref = item?.resource?.reference;
            if (ref) {
              return !str ? ref : `${str},${ref}`;
            }
            return str;
          }, "");
          params.params.association = assetQuery
            ? `${assetQuery},${association}`
            : association;
        }

        if (!user && !association && !assetRef) return;

        if (!user && !association) {
          dispatch({
            type: SET_EVENTS,
            events: formatUserEvents([]),
          });
        } else if (association) {
          dispatch({
            type: SET_ASSOCIATED_EVENTS_DICT,
            key: association,
            events: formatUserEvents([]),
          });
        } else {
          dispatch({
            type: SET_USER_EVENTS_DICT,
            events: formatUserEvents([]),
          });
          // Needed in calendar view for formatting dates with selected/current timezone
          dispatch({
            type: SET_ALL_UNFORMATTED_EVENTS,
            unformattedEvents: [],
          });
        }

        dispatch({
          type: SET_ALL_EVENTS_DICT,
          eventsDict: [],
        });
      }
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assetRef, association, dispatch, left, right, user]
  );

  /**
   * @param {Object} event - must be nested in property "resource"
   * ex. event = {resource: {...eventContents} }
   */
  const addEvent = (event) => {
    const start = moment(event?.startDate).startOf("day").format();
    let associated = event?.association || event?.resource?.association;
    if (associated?.includes("Asset")) {
      associated =
        assetsDict?.[associated]?.property || assetsDict?.[associated]?.project;
    }

    dispatch({
      type: PUSH_USER_EVENTS_DICT,
      start,
      event: formatUserEvents([event]),
      associated,
    });
  };

  const removeEvent = (event, originalKey, ref) => {
    const start = moment(event?.resource?.startDate).startOf("day").format();

    dispatch({
      type: REMOVE_USER_EVENT,
      start,
      event: formatUserEvents([event]),
      originalKey,
      ref,
    });
  };

  const updateEvent = (event, originalKey, ref) => {
    const start = moment(event?.resource?.startDate).startOf("day").format();

    dispatch({
      type: UPDATE_USER_EVENTS_DICT,
      start,
      event: formatUserEvents([event]),
      originalKey,
      ref,
    });
  };

  const removeAssociatedEvent = (
    event,
    originalKey,
    ref,
    associatedReference
  ) => {
    const start = moment(event?.resource?.startDate).startOf("day").format();

    dispatch({
      type: REMOVE_ASSOCIATED_EVENT,
      start,
      event: formatUserEvents([event]),
      originalKey,
      ref,
      association: associatedReference,
    });
  };

  const updateAssociatedEvent = (
    event,
    originalKey,
    ref,
    associatedReference
  ) => {
    const start = moment(event?.resource?.startDate).startOf("day").format();

    dispatch({
      type: UPDATE_ASSOCIATED_EVENTS_DICT,
      start,
      event: formatUserEvents([event]),
      originalKey,
      ref,
      association: associatedReference,
    });
  };

  useEffect(() => {
    reload();
  }, [reload]);

  return [
    events,
    reload,
    {
      events: userEvents,
      unformattedEvents,
      updateUserEvent: updateEvent,
      updateAssociatedEvent,
      associatedEvents,
      addEvent,
      removeEvent,
      removeAssociatedEvent,
      loading,
      eventsDict,
    },
  ];
};

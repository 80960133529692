import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import Input from "../Input/Input";
import Widget from "../Widget/Widget";

const valueCN = (value) => cntl`
${value && "mt-4"}
text-gray-200
`;

const ProjectTermHighlights = ({
  isEditing,
  project,
  documentsSettings,
  dispatch,
}) => {
  const termHighlights = project?.contractTermHighlights;

  const onChange = (value) => {
    dispatch({
      type: "edit",
      key: "contractTermHighlights",
      value,
    });
  };

  return (
    <Widget
      draggable={false}
      title="Contract Term Highlights"
      overflow
      className="mb-4"
    >
      <div>
        {isEditing && (
          <Input
            mainClassName="w-full mt-4"
            className="text-gray-300"
            value={termHighlights}
            onChange={onChange}
            placeholder={
              documentsSettings?.documents?.contractTermHighlights ??
              "Highlights"
            }
            isTextarea
            disableClear
          />
        )}
        {!isEditing && (
          <p
            className={valueCN(
              termHighlights ??
                documentsSettings?.documents?.contractTermHighlights
            )}
          >
            {termHighlights ??
              documentsSettings?.documents?.contractTermHighlights}
          </p>
        )}
      </div>
    </Widget>
  );
};

ProjectTermHighlights.propTypes = {
  isEditing: PropTypes.bool,
  project: PropTypes.shape({
    contractTermHighlights: PropTypes.string,
  }),
  documentsSettings: PropTypes.shape({
    documents: PropTypes.shape({
      contractTermHighlights: PropTypes.string,
    }),
  }),
  dispatch: PropTypes.func,
};

ProjectTermHighlights.defaultProps = {
  isEditing: false,
  project: undefined,
  documentsSettings: undefined,
  dispatch: undefined,
};

export default ProjectTermHighlights;

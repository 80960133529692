import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import { PRIMARY_DARK_GREEN, TASK_BLUE } from "../../../../constants";
import taskIcon from "../../../assets/images/taskIcon.svg";
import RequestGridViewDistroListCell from "../../Requests/RequestGridView/RequestGridViewDistroListCell";
import getDateTimeInTimezone from "../../../../helpers/Calendar/getDateTimeInTimezone";
import { useRsvp } from "../../../../hooks/rsvp";

const gridItemCN = (className) => cntl`
  flex
  items-center
  justify-start
  ${className}
  py-1
`;

const itemBgColor = {
  Task: TASK_BLUE,
  Event: PRIMARY_DARK_GREEN,
  Pto: "red",
};

const getEventStyle = (status) => {
  return { textDecoration: status === "done" ? "line-through" : "none" };
};

const ScheduleRow = ({ item, calendarTimezone }) => {
  const { data: rsvpData } = useRsvp(item?.reference, item?.instanceStartDate);
  return (
    <div className="grid grid-cols-7 gap-4 w-full text-sm">
      <div className={gridItemCN("col-span-1")}>
        <p
          style={{
            backgroundColor: itemBgColor[item?.resource],
          }}
          className="h-2.5 w-2.5 rounded-full mr-2"
        />
        <p>
          {item?.daySpan
            ? "All Day"
            : getDateTimeInTimezone({
                timezone: calendarTimezone,
                date: item.startDate,
                format: "h:mma",
              })}
        </p>
      </div>
      <div className={gridItemCN("col-span-2")}>
        <>
          {item?.resource === "Task" && (
            <img className="w-3 h-3 mr-2" src={taskIcon} alt="task-icon" />
          )}
          <p style={getEventStyle(item?.status)}>{item?.name}</p>
        </>
      </div>
      <div className={gridItemCN("col-span-2")}>
        <p>{item?.associationName}</p>
      </div>
      <div className={gridItemCN("col-span-2")}>
        <RequestGridViewDistroListCell
          avatarClassName="w-7 h-7"
          distroList={item?.invitees}
          hideTile
          hideDeleteIcon
          showRsvpStatus
          rsvpData={rsvpData}
        />
      </div>
    </div>
  );
};

ScheduleRow.propTypes = {
  item: PropTypes.shape({
    resource: PropTypes.string,
    daySpan: PropTypes.string,
    startDate: PropTypes.string,
    status: PropTypes.string,
    name: PropTypes.string,
    associationName: PropTypes.string,
    invitees: PropTypes.arrayOf(PropTypes.string),
    reference: PropTypes.string,
    instanceStartDate: PropTypes.string,
  }),
  calendarTimezone: PropTypes.string,
};

ScheduleRow.defaultProps = {
  item: undefined,
  calendarTimezone: undefined,
};

export default ScheduleRow;

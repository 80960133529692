import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import PureMediaWidget from "./PureMediaWidget";
import Modal from "../Modal/Modal";
import { downloadMedia } from "../../../helpers/File";
import CarouselView from "../CarouselView/CarouselView";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteCircleCheckIcon from "../../assets/images/circleCheckIcon.svg";
import whiteExlamationIcon from "../../assets/images/whiteExclamationIcon.svg";
import { toastMessage, toastError } from "../../../helpers/Toast";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = <img src={whiteCrossIcon} alt="Close notice" />;
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const defaultDialogState = {
  open: false,
  title: undefined,
  body: undefined,
  primaryButton: undefined,
  tertiaryButton: undefined,
  function: undefined,
};

const ImagesAndVideosWidget = ({
  hasDeletePermission,
  hasWritePermission,
  resource,
  disableEditing,
  modal,
  showHeader,
  isSop,
  isContact,
  sopClassName,
  sopStyle,
  sopMediaStyle,
  border,
  handleAddMedia,
  handleSetPrimaryMedia,
  handleRemoveMedia,
  media,
  loadingMedia,
}) => {
  const [nonPrimary, setNonPrimary] = useState();
  const [primary, setPrimary] = useState();
  const [selected, setSelected] = useState([]);
  const [imageDialog, setImageDialog] = useState(defaultDialogState);
  const [resolvedMedia, setResolvedMedia] = useState([]);
  const [actionPerforming, setActionPerforming] = useState(false);

  const handleAddResolvedMedia = useCallback((input) => {
    setResolvedMedia((prev) => {
      const included = prev?.some(
        (img) =>
          img?.index === input?.index || img?.reference === input?.reference
      );
      if (!included)
        return [...prev, input].sort((a, b) => a?.index - b?.index);
      return prev;
    });
  }, []);

  const handleDownloadSelected = async () => {
    try {
      setActionPerforming(true);
      await downloadMedia(selected?.map((val) => val?.file));
      setImageDialog(defaultDialogState);
      setSelected([]);
      setActionPerforming(false);
      toastMessage(
        `${selected?.length} media download(s) ready`,
        toastIcon,
        toastCloseIcon
      );
    } catch (err) {
      setImageDialog(defaultDialogState);
      setSelected([]);
      setActionPerforming(false);
      toastError(
        `Error downloading selected media`,
        toastErrorIcon,
        toastCloseIcon
      );
    }
  };

  const handleAddImage = () => {
    setActionPerforming(true);
  };

  const handleOnFinishUpload = async (mediaFiles) => {
    try {
      setActionPerforming(false);
      handleAddMedia(mediaFiles);
      toastMessage(
        `${mediaFiles?.length} media uploaded`,
        toastIcon,
        toastCloseIcon
      );
    } catch (err) {
      setActionPerforming(false);
      toastError(`Error uploading media`, toastErrorIcon, toastCloseIcon);
    }
  };

  const swapResolvedIndex = (next, prev, resolved) => {
    const swapped = resolved?.map((mediaObject) => {
      if (mediaObject?.reference === next?.file) {
        return { ...mediaObject, index: 0 };
      }
      if (mediaObject?.reference === prev?.file) {
        return { ...mediaObject, index: next?.index };
      }

      return mediaObject;
    });
    return swapped.sort((a, b) => a?.index - b?.index);
  };

  const handleSetPrimary = async () => {
    try {
      setActionPerforming(true);

      handleSetPrimaryMedia(selected[0]?.file);

      setImageDialog(defaultDialogState);
      setResolvedMedia((prev) => swapResolvedIndex(selected[0], primary, prev));

      setSelected([]);

      toastMessage(`Primary image set.`, toastIcon, toastCloseIcon);
      setActionPerforming(false);
    } catch (err) {
      setImageDialog(defaultDialogState);
      setSelected([]);
      setActionPerforming(false);
      toastError(
        `Error Setting Primary Image.`,
        toastErrorIcon,
        toastCloseIcon
      );
    }
  };

  const handleSelectImage = (combo, image) => {
    const included = selected?.some((img) => img?.file === image?.file);
    const indexes = selected?.map((el) => el?.index);
    const low = Math.min(indexes);
    const high = Math.max(indexes);
    let subArray;
    switch (combo) {
      case "toggle":
        if (included) {
          setSelected((prev) =>
            prev?.filter((img) => img?.file !== image?.file)
          );
        } else {
          setSelected((prev) => [...prev, image]);
        }
        break;
      case "shift":
        if (image?.index > low) {
          subArray = nonPrimary.slice(low, image?.index + 1);
          setSelected(subArray);
        } else {
          subArray = nonPrimary.slice(image?.index, high + 1);
          setSelected(subArray);
        }
        break;
      case "dblClick":
        setSelected([]);
        setImageDialog({
          open: true,
          title: "VIEW MEDIA",
          index: image.index,
          viewer: true,
          primaryButton: `Close`,
          function: () => setImageDialog(),
        });
        break;
      default:
        setSelected([image]);
    }
  };

  const handleDeselect = (image) => {
    setSelected((prev) => prev.filter((img) => img?.file !== image?.file));
  };

  const handleDeselectAll = () => {
    setSelected([]);
  };

  const handleDeleteSelected = async () => {
    const resolvedClone = _.clone(resolvedMedia);
    try {
      setActionPerforming(true);
      const selectedFiles = selected?.map((val) => val?.file);

      handleRemoveMedia(selectedFiles);
      setResolvedMedia((prev) =>
        prev.filter((val) => !selectedFiles.includes(val?.reference))
      );

      setImageDialog(defaultDialogState);
      setSelected([]);

      setActionPerforming(false);
      toastMessage(
        `${selected?.length} media deleted.`,
        toastIcon,
        toastCloseIcon
      );
    } catch (err) {
      setResolvedMedia(resolvedClone);
      setImageDialog(defaultDialogState);
      setSelected([]);
      setActionPerforming(false);
      toastError(`Error Deleting Media`, toastErrorIcon, toastCloseIcon);
    }
  };

  const handleOpenDialog = (val) => {
    switch (val) {
      case "primary":
        if (selected?.length === 1)
          setImageDialog({
            open: true,
            title: "SET PRIMARY IMAGE?",
            body: "Are you sure that you want to set the selected media as the primary image for this asset?",
            primaryButton: "Yes, set this image",
            tertiaryButton: "Cancel",
            function: handleSetPrimary,
          });
        break;
      case "delete":
        if (selected?.length > 0)
          setImageDialog({
            open: true,
            title: "DELETE MEDIA",
            body: `Are you sure that you want to delete the ${
              selected?.length
            } media ${
              selected?.length > 1 ? "items" : "item"
            }? Once deleted, this cannot be undone.`,
            primaryButton: `Delete`,
            tertiaryButton: "Cancel",
            function: handleDeleteSelected,
          });
        break;
      case "download":
        if (selected?.length > 0)
          setImageDialog({
            open: true,
            title: "DOWNLOAD MEDIA",
            body: `Are you sure that you want to download the ${selected?.length} media items?`,
            primaryButton: `Download`,
            tertiaryButton: "Cancel",
            function: handleDownloadSelected,
          });
        break;
      default:
        // eslint-disable-next-line no-console
        console.log("No value provided");
    }
  };

  useEffect(() => {
    if (resource) {
      setPrimary({ file: resource?.primaryImage });
    }
  }, [resource]);

  useEffect(() => {
    if (resource?.files?.length) {
      const reduced = resource.files
        ?.sort(
          (a, b) =>
            (b.ref === resource?.primaryImage) -
            (a.ref === resource?.primaryImage)
        )
        ?.reduce(
          (acc, file) => {
            if (file?.category === "Photos" || file?.category === "Videos") {
              return { mediaFiles: [...acc.mediaFiles, { file: file?.ref }] };
            }

            return { mediaFiles: acc.mediaFiles };
          },
          { mediaFiles: [] }
        );

      setNonPrimary(reduced.mediaFiles);
    } else if (!resource && media?.length) {
      setNonPrimary(media);
    } else {
      setNonPrimary([]);
    }
  }, [media, resource]);

  return (
    <>
      <PureMediaWidget
        hasWritePermission={hasWritePermission}
        hasDeletePermission={hasDeletePermission}
        border={border}
        images={nonPrimary}
        primary={primary}
        addImage={handleAddImage}
        resource={resource}
        selected={selected}
        selectImage={handleSelectImage}
        deselectImage={handleDeselect}
        deselectAll={handleDeselectAll}
        openImageDialog={handleOpenDialog}
        handleAddResolvedMedia={handleAddResolvedMedia}
        handleDownloadSelected={handleDownloadSelected}
        handleOnFinishUpload={handleOnFinishUpload}
        actionPerforming={actionPerforming}
        modal={modal}
        disableEditing={disableEditing}
        showHeader={showHeader}
        isSop={isSop}
        sopClassName={sopClassName}
        sopStyle={sopStyle}
        sopMediaStyle={sopMediaStyle}
        isContact={isContact}
        loadingMedia={loadingMedia}
      />
      <Modal
        title={imageDialog?.title}
        isOpen={imageDialog?.open}
        primaryButtonTitle={imageDialog?.primaryButton}
        primaryButtonOnClick={imageDialog?.function}
        primaryButtonClass={imageDialog?.primaryButtonClass}
        tertiaryButtonTitle={imageDialog?.tertiaryButton}
        onRequestModalClose={() => setImageDialog()}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        alert
        hideFooter
      >
        <>
          {imageDialog?.body && (
            <p className="text-base mb-2">{imageDialog?.body}</p>
          )}
          {imageDialog?.content && imageDialog?.content()}
          {imageDialog?.viewer && (
            <CarouselView
              items={resolvedMedia}
              sort
              startIndex={imageDialog?.index}
            />
          )}
        </>
      </Modal>
    </>
  );
};

ImagesAndVideosWidget.propTypes = {
  resource: PropTypes.shape({
    files: PropTypes.arrayOf(PropTypes.shape({})),
    primaryImage: PropTypes.string,
  }),
  media: PropTypes.arrayOf(PropTypes.string),
  disableEditing: PropTypes.bool,
  modal: PropTypes.bool,
  showHeader: PropTypes.bool,
  isSop: PropTypes.bool,
  isContact: PropTypes.bool,
  sopClassName: PropTypes.string,
  sopStyle: PropTypes.shape({}),
  sopMediaStyle: PropTypes.shape({}),
  border: PropTypes.bool,
  handleAddMedia: PropTypes.func,
  handleSetPrimaryMedia: PropTypes.func,
  handleRemoveMedia: PropTypes.func,
  loadingMedia: PropTypes.bool,
  hasWritePermission: PropTypes.bool,
  hasDeletePermission: PropTypes.bool,
};

ImagesAndVideosWidget.defaultProps = {
  resource: undefined,
  disableEditing: false,
  modal: false,
  showHeader: true,
  isSop: false,
  isContact: false,
  sopClassName: undefined,
  sopStyle: undefined,
  sopMediaStyle: undefined,
  border: true,
  handleAddMedia: undefined,
  handleSetPrimaryMedia: undefined,
  handleRemoveMedia: undefined,
  media: undefined,
  loadingMedia: false,
  hasWritePermission: true,
  hasDeletePermission: true,
};

export default ImagesAndVideosWidget;

import moment from "moment";
import { useEffect, useMemo, useState } from "react";

const useCommentRowData = ({ comment, selectedCommentId }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(comment.id === selectedCommentId);
  }, [comment, selectedCommentId]);

  return useMemo(
    () => ({
      isHovered,
      isSelected,
      commentAuthor: {
        name: comment.author.name,
        reference: comment.author.reference,
        avatar: comment.author.avatar,
      },
      commentCreatedAt: moment(comment.metadata?.createdAt).format("h:mmA"),
      setIsHovered,
    }),
    [isHovered, isSelected, setIsHovered, comment?.author, comment?.metadata]
  );
};

export default useCommentRowData;

import { WorkflowAPI } from "@griffingroupglobal/eslib-api";
import _, { cloneDeep, orderBy, partition, sortBy } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  GET_PROJECT_WORKFLOW_CREATE_PATH,
  GET_PROJECT_WORKFLOW_EDIT_PATH,
  GET_PROPERTY_WORKFLOW_CREATE_PATH,
  GET_PROPERTY_WORKFLOW_EDIT_PATH,
  RESOLVED_WF,
  RESUBMISSION_WF_STEP,
  UNRESOLVED_WF_STEP,
  WORKFLOWS_ADD_NEW_PATH,
  WORKFLOWS_EDIT_PATH,
  WORKFLOW_TAB_VIEWS,
} from "../../../constants";
import WorkflowData from "../../../helpers/Workflow";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { useWorkflowSwitchView } from "../../../hooks/useSwitchView";
import useWatchList from "../../../hooks/useWatchList";
import { useWorkflows } from "../../../hooks/useWorkflows";
import Spinner from "../../../stories/Components/Spinner/Spinner";
import WorkflowsTabbedContainer from "../../../stories/Components/TabbedContainer/WorkFlowsTabbedContainer";
import WorkflowContainer from "../../../stories/Components/WorkflowContainer/WorkflowContainer";
import AssociationWFModal from "../../../stories/Components/WorkflowModal/AssociationWFModal";
import ResolveWFModal from "../../../stories/Components/WorkflowModal/ResolveWFModal";
import WorkflowTable from "../../../stories/Components/WorkflowTable/WorkflowTable";

// TODO: For 3.0, we are assuming associated resource is always a property
const WorkflowsOverview = ({
  associatedResource,
  siteHeaderProps,
  isDraftView,
  bannerComponent,
}) => {
  const [watchList, , { addToWatchlist, removeFromWatchList }] = useWatchList();
  const history = useHistory();
  const workflowParams = useMemo(() => {
    if (!_.isEmpty(associatedResource)) {
      const resource = associatedResource.split("/")[0];
      if (resource === "Property") {
        return { property: associatedResource, isTemplate: false };
      }
      if (resource === "Project") {
        return { project: associatedResource, isTemplate: false };
      }
    }
    return {};
  }, [associatedResource]);

  const [workflows, reload, isLoading] = useWorkflows(workflowParams);
  const [isSaving, setIsSaving] = useState(false);
  const [tabIndex, setTabIndex] = useState(isDraftView && 1);
  const [activeCards, setActiveCards] = useState([]);
  const [resolvedCards, setResolvedCards] = useState([]);
  const [recentCards, setRecentCards] = useState([]);
  const [isResolveModalOpen, setIsResolveModalOpen] = useState(false);
  const [isAssociationOpen, setIsAssociationOpen] = useState(false);
  const { data: currentUser } = useCurrentUser();
  const [selectedCard, setSelectedCard] = useState("");
  const [isShowingTable] = useWorkflowSwitchView(false);
  const [searchInput, setSearchInput] = useState("");
  const [cardTypes, setCardTypes] = useState({
    active: 0,
    resolved: 0,
  });
  const [warningCount, setWarningCount] = useState(0);
  const [alertCount, setAlertCount] = useState(0);
  const [warningToggle, setWarningToggle] = useState(false);
  const [alertToggle, setAlertToggle] = useState(false);
  const [workflowsCount, setWorkflowsCount] = useState(0);

  useEffect(() => {
    const sortCards = () => {
      let initalWorkflow = workflows;
      if (associatedResource) {
        if (associatedResource.split("/")[0] === "Property") {
          initalWorkflow = initalWorkflow?.filter(
            (info) => associatedResource === info.property
          );
        } else if (associatedResource.split("/")[0] === "Project") {
          initalWorkflow = initalWorkflow?.filter(
            (info) => associatedResource === info.project
          );
        }
      }

      const total = initalWorkflow?.filter((info) => !info?.isTemplate)?.length;
      setWorkflowsCount(total);
      if (searchInput) {
        const reg = new RegExp(searchInput.toLowerCase());
        initalWorkflow = initalWorkflow?.filter((workflowItem) =>
          workflowItem?.name?.toLowerCase().match(reg)
        );
      }
      const activeWF = initalWorkflow
        ?.filter(
          (info) =>
            !info?.isDraft && !info?.isTemplate && info?.status !== RESOLVED_WF
        )
        ?.map((wf) => ({ ...wf, containerView: WORKFLOW_TAB_VIEWS.active }));

      const resolvedWF = initalWorkflow
        ?.filter(
          (info) =>
            !info?.isDraft && !info?.isTemplate && info?.status === RESOLVED_WF
        )
        ?.map((wf) => ({ ...wf, containerView: WORKFLOW_TAB_VIEWS.resolved }));

      let sortedActiveData = [];
      for (let i = 0; i < activeWF.length; i += 1) {
        let daySoFar = 0;
        const stepTotal = activeWF[i]?.steps?.reduce(
          (acc, cur, currentIndex) => {
            let totalDays = cur?.duration;
            const mainPassed = WorkflowData.checkStep(cur);
            const failedParallelStep = [];
            activeWF[i].steps[currentIndex].dueDate = moment(
              activeWF[i]?.startDate
                ? activeWF[i]?.startDate
                : activeWF[i]?.startDate?.date
            )
              .startOf("day")
              .add(daySoFar + totalDays, "day")
              .format();
            for (let j = 0; j < cur?.parallelSteps?.length; j += 1) {
              const parallelStep = cur?.parallelSteps[j];
              const parPassed = WorkflowData.checkStep(parallelStep);
              if (!parPassed) {
                failedParallelStep.push(parallelStep);
              }
              totalDays += parallelStep?.duration;
              activeWF[i].steps[currentIndex].parallelSteps[j].dueDate = moment(
                activeWF[i]?.startDate
                  ? activeWF[i]?.startDate
                  : activeWF[i]?.startDate.date
              )
                .startOf("day")
                .add(daySoFar + totalDays, "day")
                .format();
            }
            if (
              typeof activeWF[i].currentStep !== "number" &&
              (!mainPassed || failedParallelStep.length > 0)
            ) {
              activeWF[i].currentStep = currentIndex;
            }
            daySoFar += acc + totalDays;
            return acc + totalDays;
          },
          0
        );
        if (typeof activeWF[i].currentStep !== "number") {
          activeWF[i].currentStep = activeWF[i]?.steps?.length;
        }

        activeWF[i].endDate = moment(
          activeWF[i].startDate?.date
            ? activeWF[i].startDate?.date
            : activeWF[i].startDate
        )
          .startOf("day")
          .add(stepTotal, "day")
          .format();

        activeWF[i].daysOverdue = moment()
          .startOf("day")
          .diff(moment(activeWF[i].endDate).startOf("day"), "days");
      }
      const overDueSort = partition(activeWF, (info) => {
        return info.daysOverdue > 0;
      });
      const [overDue, notOverDue] = overDueSort;
      sortedActiveData = overDue;
      const actionNeededSort = partition(notOverDue, (info) => {
        const statusWarning =
          info.status === UNRESOLVED_WF_STEP ||
          info.status === RESUBMISSION_WF_STEP;
        return statusWarning;
      });
      const [actionNeeded, noActionNeeded] = actionNeededSort;
      sortedActiveData = [...sortedActiveData, ...actionNeeded];

      const dueSoonSort = partition(noActionNeeded, (info) => {
        return (
          moment(info.endDate)
            .startOf("day")
            .diff(moment().startOf("day"), "days") <= 5
        );
      });
      const [dueSoon, notDueSoon] = dueSoonSort;
      const warningCards = sortedActiveData.map((info) => ({
        ...info,
        warning: "red",
      }));
      const alertCard = dueSoon.map((info) => ({ ...info, warning: "yellow" }));
      let recentWF;
      if (alertToggle) {
        sortedActiveData = [...alertCard];
        recentWF = [...sortedActiveData]?.sort(
          (x, y) =>
            moment(y?.metadata?.lastUpdated) - moment(x?.metadata?.lastUpdated)
        );
      }
      if (warningToggle) {
        sortedActiveData = [...warningCards];
        recentWF = [...sortedActiveData]?.sort(
          (x, y) =>
            moment(y?.metadata?.lastUpdated) - moment(x?.metadata?.lastUpdated)
        );
      }
      if (!alertToggle && !warningToggle) {
        sortedActiveData = [...warningCards, ...alertCard, ...notDueSoon];
        recentWF = [...sortedActiveData, ...resolvedWF]?.sort(
          (x, y) =>
            moment(y?.metadata?.lastUpdated) - moment(x?.metadata?.lastUpdated)
        );
      }
      if (alertToggle && warningToggle) {
        sortedActiveData = [...warningCards, ...alertCard];
        recentWF = [...sortedActiveData]?.sort(
          (x, y) =>
            moment(y?.metadata?.lastUpdated) - moment(x?.metadata?.lastUpdated)
        );
      }
      setCardTypes({
        active: sortedActiveData.length,
        resolved: resolvedWF.length,
        recent: recentWF.length,
      });
      if (alertToggle || warningToggle) {
        setActiveCards(orderBy(sortedActiveData, ["daysOverdue"], ["desc"]));
      } else {
        setActiveCards(sortBy(sortedActiveData, ["name"]));
      }

      setResolvedCards(sortBy(resolvedWF, ["name"]));
      setRecentCards(recentWF);
      setAlertCount(alertCard.length);
      setWarningCount(warningCards.length);
    };

    if (workflows?.length > 0) {
      sortCards();
    }
  }, [
    associatedResource,
    workflows,
    tabIndex,
    alertToggle,
    warningToggle,
    searchInput,
  ]);

  const sortActiveCardsByOverdue = useCallback(() => {
    const sortCards = () => {
      let initalWorkflow = workflows;
      if (associatedResource) {
        if (associatedResource.split("/")[0] === "Property") {
          initalWorkflow = workflows?.filter(
            (info) => associatedResource === info.property
          );
        } else if (associatedResource.split("/")[0] === "Project") {
          initalWorkflow = workflows?.filter(
            (info) => associatedResource === info.project
          );
        }
      }
      if (searchInput) {
        const reg = new RegExp(searchInput.toLowerCase());
        initalWorkflow = initalWorkflow?.filter((workflowItem) =>
          workflowItem?.name?.toLowerCase().match(reg)
        );
      }
      const activeWF = initalWorkflow
        .filter(
          (info) =>
            !info?.isDraft && !info?.isTemplate && info?.status !== RESOLVED_WF
        )
        ?.map((wf) => ({ ...wf, containerView: WORKFLOW_TAB_VIEWS.active }));

      const resolvedWF = initalWorkflow
        .filter(
          (info) =>
            !info?.isDraft && !info?.isTemplate && info?.status === RESOLVED_WF
        )
        ?.map((wf) => ({
          ...wf,
          containerView: WORKFLOW_TAB_VIEWS.resolved,
        }));

      const draftWF = initalWorkflow
        .filter((info) => info?.isDraft && !info?.isTemplate)
        ?.map((wf) => ({ ...wf, containerView: WORKFLOW_TAB_VIEWS.draft }));

      let sortedActiveData = [];
      for (let i = 0; i < activeWF.length; i += 1) {
        let daySoFar = 0;
        const stepTotal = activeWF[i]?.steps?.reduce(
          (acc, cur, currentIndex) => {
            let totalDays = cur?.duration;
            const mainPassed = WorkflowData.checkStep(cur);
            const failedParallelStep = [];
            activeWF[i].steps[currentIndex].dueDate = moment(
              activeWF[i]?.startDate
                ? activeWF[i]?.startDate
                : activeWF[i]?.startDate.date
            )
              .startOf("day")
              .add(daySoFar + totalDays, "day")
              .format();
            for (let j = 0; j < cur?.parallelSteps?.length; j += 1) {
              const parallelStep = cur?.parallelSteps[j];
              const parPassed = WorkflowData.checkStep(parallelStep);
              if (!parPassed) {
                failedParallelStep.push(parallelStep);
              }
              totalDays += parallelStep?.duration;
              activeWF[i].steps[currentIndex].parallelSteps[j].dueDate = moment(
                activeWF[i]?.startDate
                  ? activeWF[i]?.startDate
                  : activeWF[i]?.startDate.date
              )
                .startOf("day")
                .add(daySoFar + totalDays, "day")
                .format();
            }
            if (
              typeof activeWF[i].currentStep !== "number" &&
              (!mainPassed || failedParallelStep.length > 0)
            ) {
              activeWF[i].currentStep = currentIndex;
            }
            daySoFar += acc + totalDays;
            return acc + totalDays;
          },
          0
        );
        if (typeof activeWF[i].currentStep !== "number") {
          activeWF[i].currentStep = activeWF[i]?.steps?.length;
        }

        activeWF[i].endDate = moment(activeWF[i].startDate)
          .startOf("day")
          .add(stepTotal, "day")
          .format();
        activeWF[i].daysOverdue = moment()
          .startOf("day")
          .diff(moment(activeWF[i].endDate).startOf("day"), "days");
      }
      const overDueSort = partition(activeWF, (info) => {
        return info.daysOverdue > 0;
      });
      const [overDue, notOverDue] = overDueSort;
      sortedActiveData = overDue.sort((x, y) => y.daysOverdue - x.daysOverdue);
      const actionNeededSort = partition(notOverDue, (info) => {
        const statusWarning =
          info.status === UNRESOLVED_WF_STEP ||
          info.status === RESUBMISSION_WF_STEP;
        return statusWarning;
      });
      const [actionNeeded, noActionNeeded] = actionNeededSort;
      sortedActiveData = [...sortedActiveData, ...actionNeeded];

      const dueSoonSort = partition(noActionNeeded, (info) => {
        return (
          moment(info.endDate)
            .startOf("day")
            .diff(moment().startOf("day"), "days") <= 5
        );
      });
      const [dueSoon, notDueSoon] = dueSoonSort;

      const warningCards = sortedActiveData.map((info) => ({
        ...info,
        warning: "red",
      }));
      const alertCard = dueSoon.map((info) => ({
        ...info,
        warning: "yellow",
      }));

      sortedActiveData = [...warningCards, ...alertCard, ...notDueSoon];
      const recentWF = [...sortedActiveData, ...resolvedWF, ...draftWF];

      setCardTypes({
        active: sortedActiveData.length,
        resolved: resolvedWF.length,
        recent: recentWF.length,
      });
      setActiveCards(sortedActiveData);
      setResolvedCards(sortBy(resolvedWF, ["name"]));
      setRecentCards(recentWF);
    };

    sortCards();
  }, [associatedResource, workflows, searchInput]);

  const handleEditWorkflow = () => {
    const associatedResourceParts = associatedResource?.split("/");

    if (associatedResourceParts && associatedResourceParts[0] === "Property") {
      history.push(
        GET_PROPERTY_WORKFLOW_EDIT_PATH(
          associatedResourceParts[1],
          selectedCard?.id
        )
      );
    } else if (
      associatedResourceParts &&
      associatedResourceParts[0] === "Project"
    ) {
      history.push(
        GET_PROJECT_WORKFLOW_EDIT_PATH(
          associatedResourceParts[1],
          selectedCard?.id
        )
      );
    } else {
      history.push(WORKFLOWS_EDIT_PATH(selectedCard?.id));
    }
  };

  const handleWatchWFClick = (id, isWatched, watchedWF) => {
    if (isWatched) {
      removeFromWatchList([watchedWF]);
    } else {
      addToWatchlist(id, "Workflow");
    }
  };

  const handleFlagWF = async (data) => {
    const updatedBody = {
      ...data,
      isFlagged: !data?.isFlagged,
    };
    await WorkflowAPI.patch(data.id, updatedBody, data);
    reload();
  };

  const handleResolveWF = async (option, note) => {
    const completed = {
      member: currentUser.reference,
      note: note || "",
      date: moment().format(),
    };
    const tempObj = { ...(workflows ?? []), status: option, completed };
    await WorkflowAPI.patch(selectedCard.id, tempObj, selectedCard);
    reload();
    setIsResolveModalOpen(false);
  };

  const handleReinitiateWF = async (data, isDraft) => {
    setIsSaving(true);
    // reset workflow data in tempObj
    const initalWF = data?.metadata ? data : selectedCard;
    const tempWorkflow = cloneDeep(initalWF);
    tempWorkflow.startDate = moment().startOf("day").format();
    if (tempWorkflow.isDraft) {
      tempWorkflow.isDraft = false;
    } else {
      tempWorkflow.steps = tempWorkflow?.steps?.map((info) => {
        const members = info?.members?.map((member) => {
          // eslint-disable-next-line no-underscore-dangle
          return { reference: member?.reference, _id: member._id };
        });
        const parallelSteps = info?.parallelSteps?.map((par) => {
          const parMembers = par?.members?.map((member) => {
            // eslint-disable-next-line no-underscore-dangle
            return { reference: member?.reference, _id: member._id };
          });
          return { ...par, members: parMembers };
        });
        return { ...info, members, parallelSteps };
      });
    }
    const tempObj = { ...tempWorkflow, status: "inProgress" };
    if (isDraft) {
      tempObj.isDraft = false;
      await WorkflowAPI.patch(tempWorkflow.id, tempObj, initalWF);
    } else {
      await WorkflowAPI.patch(
        `${tempWorkflow.id}/$newversion`,
        tempObj,
        initalWF
      );
    }
    setIsSaving(false);
    reload();
  };
  const handleCreateAssociatedWF = (data) => {
    const associatedResourceParts = associatedResource?.split("/");

    if (associatedResourceParts && associatedResourceParts[0] === "Property") {
      history.push(
        GET_PROPERTY_WORKFLOW_CREATE_PATH(associatedResourceParts[1]),
        data
      );
    } else if (
      associatedResourceParts &&
      associatedResourceParts[0] === "Project"
    ) {
      history.push(
        GET_PROJECT_WORKFLOW_CREATE_PATH(associatedResourceParts[1]),
        data
      );
    } else {
      history.push(WORKFLOWS_ADD_NEW_PATH, data);
    }
  };
  const handleDeleteWF = async () => {
    setIsSaving(true);
    await WorkflowAPI.delete(selectedCard.id);
    setIsSaving(false);
    reload();
  };
  const handleOpenResolve = () => {
    setIsResolveModalOpen(true);
  };
  const handleOpenAssociation = () => {
    setIsAssociationOpen(true);
  };
  const handleAddWorkflow = () => {
    const associatedResourceParts = associatedResource?.split("/");

    if (associatedResourceParts && associatedResourceParts[0] === "Property") {
      history.push(
        GET_PROPERTY_WORKFLOW_CREATE_PATH(associatedResourceParts[1])
      );
    } else if (
      associatedResourceParts &&
      associatedResourceParts[0] === "Project"
    ) {
      history.push(
        GET_PROJECT_WORKFLOW_CREATE_PATH(associatedResourceParts[1])
      );
    } else {
      history.push(WORKFLOWS_ADD_NEW_PATH);
    }
  };

  const handleSearch = useCallback((input) => {
    setSearchInput(() => input);
  }, []);

  const getWorkflowView = (data, tabs) => {
    return isShowingTable ? (
      <WorkflowTable
        data={data}
        handleAddWorkflow={handleAddWorkflow}
        sortActiveCardsByOverdue={sortActiveCardsByOverdue}
        warningCount={warningCount}
        alertCount={alertCount}
        alertToggle={alertToggle}
        warningToggle={warningToggle}
        setWarningToggle={setWarningToggle}
        setAlertToggle={setAlertToggle}
        bannerComponent={bannerComponent}
        tabs={tabs}
        associatedResource={associatedResource}
      />
    ) : (
      <WorkflowContainer
        isLoading={isLoading}
        data={data}
        setSelectedCard={setSelectedCard}
        handleOpenResolve={handleOpenResolve}
        handleOpenAssociation={handleOpenAssociation}
        handleEditWorkflow={handleEditWorkflow}
        handleReinitiateWF={handleReinitiateWF}
        handleDeleteWF={handleDeleteWF}
        handleCreateAssociatedWF={handleCreateAssociatedWF}
        handleFlagWF={handleFlagWF}
        siteHeaderProps={siteHeaderProps}
        siteHeaderTabs={tabs}
        handleAddWorkflow={handleAddWorkflow}
        sortActiveCardsByOverdue={sortActiveCardsByOverdue}
        warningCount={warningCount}
        alertCount={alertCount}
        alertToggle={alertToggle}
        warningToggle={warningToggle}
        setWarningToggle={setWarningToggle}
        setAlertToggle={setAlertToggle}
        currentUser={currentUser}
        searchInput={searchInput}
        handleSearch={handleSearch}
        bannerComponent={bannerComponent}
        associatedResource={associatedResource}
        handleWatchWF={handleWatchWFClick}
        watchList={watchList}
        workflowsCount={workflowsCount}
      />
    );
  };

  const TabViews = [
    {
      title: "ACTIVE",
      content: (tabs) => getWorkflowView(activeCards, tabs),
      pill: {
        background: tabIndex === 0 ? "bg-brandGreen" : "bg-gray-200",
        value: cardTypes?.active,
      },
    },
    {
      title: "RECENT",
      content: (tabs) => getWorkflowView(recentCards, tabs),
      pill: {
        background: tabIndex === 2 ? "bg-brandGreen" : "bg-gray-200",
        value: cardTypes?.recent,
      },
      isHidden: true,
    },
    {
      title: "COMPLETED",
      content: (tabs) => getWorkflowView(resolvedCards, tabs),
      pill: {
        background: tabIndex === 3 ? "bg-brandGreen" : "bg-gray-200",
        value: cardTypes?.resolved,
      },
    },
  ];

  useEffect(() => {
    const lastTabIndex = Number(
      window.localStorage.getItem(`${currentUser?.email}-workflows-lasttab`)
    );
    setTabIndex(lastTabIndex || 0);
  }, [currentUser]);

  const handleSetTabIndex = (index) => {
    setTabIndex(index);
    window.localStorage.setItem(
      `${currentUser.email}-workflows-lasttab`,
      index
    );
  };

  return (
    <>
      {(isLoading || isSaving) && <Spinner />}
      <WorkflowsTabbedContainer
        tabs={TabViews}
        activeIndex={tabIndex}
        onTabClick={handleSetTabIndex}
        workflows
        propertyWorkflows
      />
      <div className="flex pt-5">
        <div className="flex w-full relative justify-end">
          <ResolveWFModal
            isOpen={isResolveModalOpen}
            handleClose={() => setIsResolveModalOpen(false)}
            handleResolveWF={handleResolveWF}
          />
          <AssociationWFModal
            workflowData={selectedCard}
            isOpen={isAssociationOpen}
            onClose={() => setIsAssociationOpen(false)}
            handleCreateAssociatedWF={handleCreateAssociatedWF}
          />
        </div>
      </div>
    </>
  );
};

WorkflowsOverview.propTypes = {
  /**
   * only shows WF with this associated Resource
   */
  associatedResource: PropTypes.string,
  /**
   * alter siteHeader props
   */
  // eslint-disable-next-line react/forbid-prop-types
  siteHeaderProps: PropTypes.object,
  isDraftView: PropTypes.bool,
  bannerComponent: PropTypes.node,
};

WorkflowsOverview.defaultProps = {
  associatedResource: undefined,
  siteHeaderProps: {
    title: "Workflows",
    dropdownRoutes: undefined,
  },
  isDraftView: undefined,
  bannerComponent: undefined,
};
export default WorkflowsOverview;

// Framework Tools
import React from "react";
import PropTypes from "prop-types";

// Components
import TertiaryHeader from "../../TextHeaders/TertiaryHeader";

export default function RequestGridViewScheduleCell({ schedule }) {
  const { status } = schedule;
  return (
    <div className="w-full h-ful flex flex-col justify-start flex-wrap px-2">
      <TertiaryHeader className="text-sm text-left">
        Schedule Reference
      </TertiaryHeader>
      <p className="text-sm text-left">{status}</p>
    </div>
  );
}

// prop types
RequestGridViewScheduleCell.propTypes = {
  schedule: PropTypes.shape({
    status: PropTypes.string,
  }),
};

RequestGridViewScheduleCell.defaultProps = {
  schedule: {
    status: "",
  },
};

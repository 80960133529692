// Framework Tools
import React from "react";

// Libraries
import { cloneDeep, lastIndexOf } from "lodash";

// Constants
import { REQUEST_EMPTY_STEP } from "../../../constants";

// Components
import ManagementRequestTemplateStep from "./ManagementRequestTemplateStep";
import TertiaryButton from "../Buttons/TertiaryButton";

const ManagementSubmittalTemplateSubSection = ({
  dispatchManagementSettings,
  managementSettings,
}) => {
  const update = (data) => {
    dispatchManagementSettings({
      type: "changeSubmittalDefaultTemplate",
      payload: data,
    });
  };

  // sets role for default template steps based on step index
  const handleSetRoles = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return "submitter";
      case 1:
        return "reviewer";
      case 2:
        return "approver";
      case 3:
        return "reviewer";
      default:
        if (stepIndex % 2 === 0) {
          return "arpprover";
        }
        return "reviewer";
    }
  };

  // Adds a step at the bottom of the template
  const handleAddStep = (lastStep) => {
    const emptyStep = cloneDeep(REQUEST_EMPTY_STEP);
    const formattedEmptyStep = {
      ...emptyStep,
      name: `Step ${lastStep + 1}`,
      position: lastStep + 1,
      type: handleSetRoles(lastStep + 1),
    };

    const data = {
      ...managementSettings.submittalTemplate,
      steps: [
        ...managementSettings.submittalTemplate.steps,
        formattedEmptyStep,
      ],
    };
    update(data);
  };

  // Removes step by position
  const handleRemoveStep = (index) => {
    const data = {
      ...managementSettings.submittalTemplate,
      steps: managementSettings.submittalTemplate.steps.filter(
        (_step, idx) => idx !== index
      ),
    };

    update(data);
  };

  const onChangeDuration = (currentIndex, value) => {
    const data = {
      ...managementSettings.submittalTemplate,
      steps: managementSettings.submittalTemplate.steps.map((step, idx) => {
        if (idx === currentIndex) {
          return {
            ...step,
            duration: { projected: value },
          };
        }
        return step;
      }),
    };

    update(data);
  };

  const onChangeRole = (currentIndex, value) => {
    const data = {
      ...managementSettings.submittalTemplate,
      steps: managementSettings.submittalTemplate.steps.map((step, idx) => {
        if (idx === currentIndex) {
          return {
            ...step,
            type: value?.value,
          };
        }
        return step;
      }),
    };

    update(data);
  };

  return (
    <div className="flex flex-col w-full p-4">
      {managementSettings?.submittalTemplate?.steps?.map((step, index) => {
        return (
          <div
            className="flex items-center w-full mt-2"
            key={`${step?.name}-${step?.position}-${step?.type}-${step?.duration?.projected}`}
          >
            <ManagementRequestTemplateStep
              currentIndex={index}
              duration={step?.duration?.projected}
              role={step?.type}
              handleRemoveStep={handleRemoveStep}
              onChangeDuration={onChangeDuration}
              onChangeRole={onChangeRole}
            />
          </div>
        );
      })}
      <div className="flex w-full">
        <TertiaryButton
          className="w-full mt-4 border-dashed border-gray-150 border-2 h-16 rounded-md mb-12 text-lg font-semibold"
          title="+ Add New Step"
          onClick={() =>
            handleAddStep(
              lastIndexOf(managementSettings?.submittalTemplate?.steps)
            )
          }
          noMaxWidth
        />
      </div>
    </div>
  );
};

export default ManagementSubmittalTemplateSubSection;

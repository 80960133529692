import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import Modal from "../Modal/Modal";
import BaseButton from "../Buttons/BaseButton";

import collapseIcon from "../../assets/images/collapseIcon.svg";

const deleteIconCN = cntl`
  w-3
  h-3
`;

const DeleteButton = ({
  className,
  iconClassName,
  onConfirm,
  noConfirm,
  isOpen,
  hideDeleteButton,
  setDeleting,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setModalIsOpen(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (modalIsOpen) {
      if (setDeleting) {
        setDeleting(true);
      }
    }
  }, [modalIsOpen, setDeleting]);

  const onClick = () => {
    if (setDeleting) {
      setDeleting(true);
    }

    if (noConfirm) {
      onConfirm();
      return;
    }
    setModalIsOpen(true);
  };

  const onConfirmDelete = () => {
    onConfirm();
    setModalIsOpen(false);
    if (setDeleting) setDeleting(false);
  };

  const onDismissModal = () => {
    setModalIsOpen(false);
    if (setDeleting) setDeleting(false);
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        title="Delete?"
        hideFooter
        onRequestModalClose={onDismissModal}
        primaryButtonTitle="Remove"
        primaryButtonOnClick={onConfirmDelete}
        tertiaryButtonTitle="Cancel"
        alert
      >
        <div className="flex flex-col">
          <p>Are you sure you want to delete this?</p>
        </div>
      </Modal>

      {!hideDeleteButton && (
        <BaseButton
          className={className}
          iconLeft={
            <img
              src={collapseIcon}
              // src={crossIcon}
              className={iconClassName || deleteIconCN}
              alt="Delete"
            />
          }
          onClick={onClick}
        />
      )}
    </>
  );
};

DeleteButton.propTypes = {
  /**
   * classes applied to the button
   */
  className: PropTypes.string,
  /**
   * function called when the button is clicked
   */
  onConfirm: PropTypes.func,
  /**
   * classes applied to the icon
   */
  iconClassName: PropTypes.string,
  /**
   * bypass delete confirmation modal
   */
  noConfirm: PropTypes.bool,
  isOpen: PropTypes.bool,
  hideDeleteButton: PropTypes.bool,
  setDeleting: PropTypes.func,
};

DeleteButton.defaultProps = {
  className: undefined,
  onConfirm: undefined,
  iconClassName: undefined,
  noConfirm: false,
  isOpen: false,
  hideDeleteButton: false,
  setDeleting: undefined,
};

export default DeleteButton;

import { UserAPI } from "@griffingroupglobal/eslib-api";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { userKeys } from "../config/reactQuery/queryKeyFactory";

const fetchAssigneesUsers = async () => {
  const {
    data: { entries: contacts },
  } = await UserAPI.getWOP(`?kind=member,nonmember`);

  const allReduced = contacts?.reduce(
    (filtered, option) => {
      if (
        !option?.resource?.company?.value &&
        !option?.resource?.metadata?.deletedAt
      ) {
        filtered?.unassigned?.push({
          ...option?.resource,
          label: `${option?.resource?.name?.firstName} ${
            option?.resource?.name?.lastName ?? ""
          }`,
          name: option?.resource?.name,
          value: option?.resource?.reference,
          id: option?.resource?.id,
          avatar: option?.resource?.avatar,
          metadata: option?.resource?.metadata,
          companyName: option?.resource?.companyName,
          company: option?.resource?.company,
        });
      } else if (!option?.resource?.metadata?.deletedAt) {
        filtered?.assigned?.push({
          ...option?.resource,
          label: `${option?.resource?.name?.firstName} ${
            option?.resource?.name?.lastName ?? ""
          }`,
          name: option?.resource?.name,
          value: option?.resource?.reference,
          id: option?.resource?.id,
          avatar: option?.resource?.avatar,
          companyId: option?.resource?.company?.value,
          metadata: option?.resource?.metadata,
          companyName: option?.resource?.companyName,
          company: option?.resource?.company,
        });
      }
      return filtered;
    },

    // unassigned: Contacts that are being set on dropdown members in contact company page
    { unassigned: [], assigned: [] }
  );

  return allReduced;
};

const useAssignees = () => {
  return useAuthenticatedQuery({
    queryKey: userKeys.assigness(),
    queryFn: fetchAssigneesUsers,
    onError: (error) => {
      console.error("useAssignees", error);
    },
  });
};

export default useAssignees;

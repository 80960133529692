import { useEffect, useState } from "react";
import { useAppState } from "../state/appState";

export default () => {
  const [{ configurationLastUpdated }] = useAppState();
  const [lastUpdated, setLastUpdated] = useState();

  useEffect(() => {
    if (configurationLastUpdated) {
      setLastUpdated(configurationLastUpdated);
    }
  }, [configurationLastUpdated]);

  return [lastUpdated];
};

/* eslint-disable no-nested-ternary */
import React from "react";
import { useGetFile } from "../../../hooks/useGetFile";
import useCurrentUser from "../../../hooks/useCurrentUser";
import placeholder from "../../assets/images/userIcon.svg";
import addPhotoIcon from "../../assets/images/addPhotoIcon.svg";
import "./Avatar.css";

// const OPTIONS = {
//   xs: "w-10 h-10",
//   sm: "w-12 h-12",
//   md: "w-24 h-24",
//   lg: "w-28 h-28",
// };
const OPTIONS = {
  xs: { className: "w-10 h-10", value: 10 },
  sm: { className: "w-12 h-12", value: 10 },
  md: { className: "w-24 h-24", value: 10 },
  lg: { className: "w-28 h-28", value: 10 },
};

// TODO (Remove this todo in ES-6815) This is a dummy component
const SimpleAvatar = ({ size = "lg", isEditing, addPhoto, topNav }) => {
  const { data: user } = useCurrentUser();
  const { data, isLoading } = useGetFile(user?.avatar);

  return (
    <svg
      viewBox="0 0 10 10"
      preserveAspectRatio="xMidYMid meet"
      aria-label="avatar-image"
      style={{ minWidth: "48px" }}
      className={`${
        topNav ? "48px" : OPTIONS[size].className
      } avatar-container rounded-full`}
      role="button"
      onClick={isEditing ? () => addPhoto?.() : undefined}
      tabIndex={0}
    >
      {/* Green border circle */}
      <circle
        r="4.5"
        cx="5"
        cy="5"
        fill="none"
        stroke="#1CC39D"
        strokeWidth="0.5"
      />
      <clipPath id={`clipCircle-${user?.avatar}`}>
        <circle r="4.7" cx="5" cy="5" />
      </clipPath>
      <circle r="4.5" cx="5" cy="5" fill="#585858" strokeWidth="1" />
      {/* Avatar Image */}
      <image
        href={data?.contentsUrl ?? placeholder}
        alt="avatar-image"
        x="0"
        y="0"
        height="10"
        width="10"
        clipPath={`url(#clipCircle-${user?.avatar})`}
        preserveAspectRatio="xMaxYMax slice"
      />
      {/* Avatar Image */}

      {/* Add Photo Button */}
      {isEditing && (
        <image
          href={addPhotoIcon}
          alt="add-icon"
          className={!isEditing && "addIcon"}
          x="3.75"
          y="3.75"
          height="2.5"
          width="2.5"
        />
      )}
      {/* Add Photo Button */}

      {/* Loading Circle */}
      {isLoading ? (
        <path
          d="
    M 5.5, 5
    m 4.425, 0
    a 4.425,4.425 0 1,0 -9.85,0
    "
          fill="none"
          strokeWidth="0.15"
          stroke={`url('#myGradient-${user?.avatar}')`}
          strokeLinecap="round"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1s"
            from="0 5 5"
            to="360 5 5"
            repeatCount="indefinite"
          />
        </path>
      ) : null}
      {/* Loading Circle */}
    </svg>
  );
};

export default SimpleAvatar;

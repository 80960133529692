import moment from "moment-timezone";
import { MOMENT_CALENDAR_FORMAT } from "../../constants";

const getBeginningTimeStamp = (calendarData, calendarTimezone) => {
  return moment
    .tz(calendarData, calendarTimezone)
    .startOf("day")
    .format(MOMENT_CALENDAR_FORMAT);
};

export default getBeginningTimeStamp;

import React from "react";
import PropTypes from "prop-types";
import Widget from "../Widget/Widget";
import Header from "./Header";
import Footer from "./Footer";

/**
 * Component that displays an editable widget with a title and various
 * actions such as editing, deleting, and navigation.
 */
const WidgetEditorView = ({
  canEdit,
  canDelete,
  canDownload,
  title,
  className,
  isLoading,
  isEditing,
  showBackButton,
  backButtonTitle,
  hideFooter,
  hideBorder,
  children,
  ExtraHeaderActions,
  onBackPressed,
  onBeginEditing,
  onFinishEditing,
  onCancelEditing,
  onDeletePressed,
  onDownloadPressed,
  onOpenComments,
  overflow,
  titleClassName,
  childrenWrapperCN,
  cancelClassName,
  saveClassName,
  commentClassName,
}) => {
  return (
    <Widget
      title={null}
      draggable={false}
      loading={isLoading}
      className={className}
      childClassName={hideBorder ? "-mt-12 -ml-6 -mr-6" : "-mt-4"}
      hideBorder={hideBorder}
      overflow={overflow}
    >
      <Header
        canEdit={canEdit}
        canDownload={canDownload}
        title={title}
        titleClassName={titleClassName}
        showBackButton={showBackButton}
        backButtonTitle={backButtonTitle}
        isEditing={isEditing}
        canDelete={canDelete}
        ExtraHeaderActions={ExtraHeaderActions}
        onBackPressed={onBackPressed}
        onBeginEditing={onBeginEditing}
        onFinishEditing={onFinishEditing}
        onCancelEditing={onCancelEditing}
        onDeletePressed={onDeletePressed}
        onDownloadPressed={onDownloadPressed}
        onOpenComments={onOpenComments}
        absoluteBtnPosition={false}
        cancelClassName={cancelClassName}
        saveClassName={saveClassName}
        commentClassName={commentClassName}
      />

      <div id="widget-child" className={childrenWrapperCN}>
        {children}
      </div>

      <Footer
        canEdit={canEdit}
        hidden={hideFooter}
        isEditing={isEditing}
        onFinishEditing={onFinishEditing}
        onCancelEditing={onCancelEditing}
      />
    </Widget>
  );
};

WidgetEditorView.defaultProps = {
  canEdit: false,
  canDelete: false,
  canDownload: false,
  showBackButton: false,
  backButtonTitle: undefined,
  isLoading: false,
  isEditing: false,
  hideFooter: false,
  hideBorder: false,
  title: undefined,
  className: undefined,
  children: undefined,
  ExtraHeaderActions: undefined,
  onBackPressed: () => {},
  onBeginEditing: () => {},
  onFinishEditing: () => {},
  onCancelEditing: () => {},
  onDeletePressed: () => {},
  onDownloadPressed: () => {},
  onOpenComments: undefined,
  overflow: false,
  childrenWrapperCN: "mt-4",
  titleClassName: undefined,
  cancelClassName: undefined,
  saveClassName: undefined,
  commentClassName: undefined,
};

WidgetEditorView.propTypes = {
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  canDownload: PropTypes.bool,
  showBackButton: PropTypes.bool,
  backButtonTitle: PropTypes.string,
  isLoading: PropTypes.bool,
  isEditing: PropTypes.bool,
  hideFooter: PropTypes.bool,
  hideBorder: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  ExtraHeaderActions: PropTypes.node,
  onBackPressed: PropTypes.func,
  onBeginEditing: PropTypes.func,
  onFinishEditing: PropTypes.func,
  onCancelEditing: PropTypes.func,
  onDeletePressed: PropTypes.func,
  onDownloadPressed: PropTypes.func,
  onOpenComments: PropTypes.func,
  overflow: PropTypes.bool,
  childrenWrapperCN: PropTypes.string,
  titleClassName: PropTypes.string,
  cancelClassName: PropTypes.string,
  saveClassName: PropTypes.string,
  commentClassName: PropTypes.string,
};

export default WidgetEditorView;

import React from "react";
import Dropdown from "../Dropdown/Dropdown";
import capitalizeFirstLetter from "../../../helpers/Utilities/capitalizeFirstLetter";
import useDropdownWithPriorityLevelData from "./useDropdownWithPriorityLevelData";

const DropDownWithPriorityLevel = ({
  priority,
  placeholder = "None",
  className = "w-full",
  onChangePriority,
}) => {
  const { options, value } = useDropdownWithPriorityLevelData(priority);

  return (
    <Dropdown
      placeholder={placeholder}
      className={className}
      disableSort
      options={options}
      value={value}
      onChange={onChangePriority}
      formatOptionLabel={(option) => (
        <div className="flex flex-row justify-start items-center cursor-pointer gap-2">
          <img
            src={option.image}
            alt="priority type"
            className="shadow-light-lift w-5 h-5 rounded-full"
          />
          <p>{capitalizeFirstLetter(option.label)}</p>
        </div>
      )}
    />
  );
};

export default DropDownWithPriorityLevel;

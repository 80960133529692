import React, { useCallback, useState } from "react";
import cntl from "cntl";
import * as yup from "yup";
import PropTypes from "prop-types";
import Input from "../Input/Input";
import PrimaryButton from "../Buttons/PrimaryButton";
import CrossButton from "../CrossButton/CrossButton";
import useAssetFormReducer from "../../../hooks/useAssetFormReducer";
import Dropdown from "../Dropdown/Dropdown";

const popOverCN = cntl`
  bg-white
  flex
  flex-col
  pt-2
  px-2
  overflow-x-auto
  sticky
  left-0
  pb-24
`;

const rowCN = cntl`
  flex
`;

const rowItemCN = cntl`
  px-2
  flex-1
  min-w-max
`;

const AssetTableInLineForm = ({
  row,
  togglePopOver,
  isEdit,
  assetCategories,
  assetSubcategories,
  onAddSaveCallback,
  onEditSaveCallback,
  propertyRef,
  categoryOptions,
  prefillFieldsOnAddAnother,
}) => {
  const initialAddState = useCallback(() => {
    const rv = {};

    if (propertyRef) {
      rv.property = propertyRef;
    }

    if (row?.original?.addAnother) {
      prefillFieldsOnAddAnother.map((columnId) => {
        rv[columnId] = row.original[columnId];
        return columnId;
      });
    }

    return rv;
  }, [prefillFieldsOnAddAnother, propertyRef, row]);

  const [lineItem, dispatch] = useAssetFormReducer(
    isEdit ? row.original : initialAddState(propertyRef)
  );
  const [nameInputEntered, setNameInputEntered] = useState(isEdit);

  const [addAnother] = useState(row?.original?.addAnother);

  const onEditSave = useCallback(() => {
    const editedAsset = { ...lineItem };
    onEditSaveCallback(editedAsset);
    togglePopOver();
  }, [lineItem, onEditSaveCallback, togglePopOver]);

  const onAddSave = useCallback(() => {
    if (addAnother) {
      lineItem.addAnother = true;
    }
    const createdAsset = { ...lineItem };
    onAddSaveCallback(createdAsset);
  }, [lineItem, onAddSaveCallback, addAnother]);

  const handleChangeCategory = (val) => {
    const { label, value } = assetCategories.find(
      (item) => item.label === val.category
    );
    dispatch({
      type: "inLineFormCategoryAndSubcategory",
      category: label,
      categoryId: value,
      subcategory: val.label,
      subcategoryId: val.value,
    });
  };

  const handleChangeName = (newVal) => {
    if (!nameInputEntered) setNameInputEntered(true);
    dispatch({
      type: "name",
      name: newVal,
    });
  };

  const handleKeyPressSave = async (e) => {
    if (
      e.code === "Enter" &&
      nameInputEntered &&
      (lineItem?.name !== "" || lineItem.categoryId || lineItem.subcategoryId)
    ) {
      if (isEdit) {
        onEditSave();
      } else {
        await onAddSave();
      }
    }
  };

  // list of column content to be shown in the popover
  const columns = [
    {
      content: (
        <Input
          label="Name"
          placeholder="Name"
          value={nameInputEntered ? lineItem?.name : ""}
          onChange={(newVal) => handleChangeName(newVal)}
          validation={yup.string().required()}
          autoFocus
          onKeyPress={(e) => handleKeyPressSave(e)}
        />
      ),
    },
    {
      content: (
        <Dropdown
          key="Category (required)"
          label="Category (required)"
          options={categoryOptions}
          value={assetSubcategories[
            assetCategories?.find((opt) => opt.value === lineItem?.categoryId)
              ?.label
          ]?.find((item) => item.value === lineItem.subcategoryId)}
          onChange={(val) => handleChangeCategory(val)}
          disableClear
          validation={yup.mixed().required()}
        />
      ),
    },
    {
      content: (
        <Input
          placeholder="Description"
          label="Description"
          onChange={(value) => {
            dispatch({
              type: "description",
              description: value,
            });
          }}
          value={lineItem?.description}
          isTextarea
        />
      ),
    },
  ];

  return (
    <div className={popOverCN}>
      <div className="flex justify-end py-2">
        <CrossButton className="color" onClick={() => togglePopOver()} />
      </div>
      <div className={rowCN}>
        {columns?.map((option) => (
          <div className={rowItemCN} key={option.id}>
            {option.content}
          </div>
        ))}
        <div className="flex flex-col px-2 pt-7">
          <PrimaryButton
            title="Save"
            className="mb-2"
            onClick={async () => {
              if (isEdit) {
                onEditSave();
              } else {
                await onAddSave();
              }
            }}
            disabled={
              lineItem?.name === "" ||
              !lineItem.categoryId ||
              !lineItem.subcategoryId
            }
          />
        </div>
      </div>
    </div>
  );
};

AssetTableInLineForm.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number,
    original: {
      line: PropTypes.string,
    },
  }),
  togglePopOver: PropTypes.func,
  isEdit: PropTypes.bool,
  onAddSaveCallback: PropTypes.func,
  onEditSaveCallback: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  assetCategories: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  assetSubcategories: PropTypes.object,
  propertyRef: PropTypes.string,
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
          category: PropTypes.string,
        })
      ),
    })
  ),
  prefillFieldsOnAddAnother: PropTypes.arrayOf(PropTypes.string),
};

AssetTableInLineForm.defaultProps = {
  row: undefined,
  togglePopOver: undefined,
  isEdit: false,
  onEditSaveCallback: undefined,
  onAddSaveCallback: undefined,
  assetCategories: [],
  assetSubcategories: {},
  propertyRef: undefined,
  categoryOptions: [],
  prefillFieldsOnAddAnother: [],
};

export default AssetTableInLineForm;

// Framework Tools
import React from "react";

// Libraries
import { cloneDeep, lastIndexOf } from "lodash";

// Constants
import { WORKFLOW_EMPTY_STEP } from "../../../constants";

// Components
import ManagementRequestTemplateStep from "./ManagementRequestTemplateStep";
import TertiaryButton from "../Buttons/TertiaryButton";

const ManagementWorkflowTemplateSubSection = ({
  dispatchManagementSettings,
  managementSettings,
}) => {
  const update = (data) => {
    dispatchManagementSettings({
      type: "changeWorkflowDefaultTemplate",
      payload: data,
    });
  };

  // Adds a step at the bottom of the template
  const handleAddStep = (lastStep) => {
    const emptyStep = cloneDeep(WORKFLOW_EMPTY_STEP);
    const formattedEmptyStep = {
      ...emptyStep,
      name: `Step ${lastStep + 1}`,
      position: lastStep + 1,
      type: "approver",
    };

    const data = {
      ...managementSettings.workflowTemplate,
      steps: [...managementSettings.workflowTemplate.steps, formattedEmptyStep],
    };
    update(data);
  };

  // Removes step by position
  const handleRemoveStep = (index) => {
    const data = {
      ...managementSettings.workflowTemplate,
      steps: managementSettings.workflowTemplate.steps.filter(
        (_step, idx) => idx !== index
      ),
    };

    update(data);
  };

  const onChangeDuration = (currentIndex, value) => {
    const data = {
      ...managementSettings.workflowTemplate,
      steps: managementSettings.workflowTemplate.steps.map((step, idx) => {
        if (idx === currentIndex) {
          return {
            ...step,
            duration: { projected: value },
          };
        }
        return step;
      }),
    };

    update(data);
  };

  const onChangeRole = (currentIndex, value) => {
    const data = {
      ...managementSettings.workflowTemplate,
      steps: managementSettings.workflowTemplate.steps.map((step, idx) => {
        if (idx === currentIndex) {
          return {
            ...step,
            type: value?.value,
          };
        }
        return step;
      }),
    };

    update(data);
  };

  return (
    <div className="flex flex-col w-full p-4">
      {managementSettings?.workflowTemplate?.steps?.map((step, index) => {
        return (
          <div className="flex items-center w-full mt-2" key={step?.position}>
            <ManagementRequestTemplateStep
              currentIndex={index}
              duration={step?.duration?.projected}
              role={step?.type}
              handleRemoveStep={handleRemoveStep}
              onChangeDuration={onChangeDuration}
              onChangeRole={onChangeRole}
              isWorkflowTemplate
            />
          </div>
        );
      })}
      <div className="flex w-full">
        <TertiaryButton
          className="w-full mt-4 border-dashed border-gray-150 border-2 h-16 rounded-md mb-12 text-lg font-semibold"
          title="+ Add New Step"
          onClick={() =>
            handleAddStep(
              lastIndexOf(managementSettings?.workflowTemplate?.steps)
            )
          }
          noMaxWidth
        />
      </div>
    </div>
  );
};

export default ManagementWorkflowTemplateSubSection;

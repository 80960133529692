import React, { useState } from "react";
import ModalWrapper from "../ModalWrapper";
import Input from "../../Input/Input";
import PrimaryButton from "../../Buttons/PrimaryButton";
import useEsModalData from "../useEsModalData";
import TertiaryButton from "../../Buttons/TertiaryButton";

export default ({ modalData }) => {
  const { closeModal: close } = useEsModalData(null, modalData);
  const [feedback, setFeedBack] = useState({
    value: "",
    set: (val) => setFeedBack((prev) => ({ ...prev, value: val })),
  });

  return (
    <ModalWrapper
      modalData={modalData}
      resourceName="Time Off"
      width="607px"
      alert
      buttons={
        <div className="flex gap-4">
          <PrimaryButton
            saveButtonTitle="Deny"
            onClick={() => {
              modalData?.submit(feedback.value);
              close();
            }}
            saveButton
            onKeyUp={() => {
              modalData?.submit(feedback.value);
              close();
            }}
          />
          <TertiaryButton title="cancel" onClick={close} />
        </div>
      }
      //   disabled={}
      title={`${modalData?.resource} Feedback`}
    >
      <div>
        <p className="font-semibold text-gray-450">Record Reason</p>
        <Input
          placeholder="Reason"
          isTextarea
          onChange={feedback.set}
          value={feedback.value}
        />
      </div>
    </ModalWrapper>
  );
};

import React from "react";
import returnDates from "../../../../../../helpers/Timesheets/returnDates";

const PtoRow = ({ ptoEntryState }) => {
  const entryTotalsByDate = returnDates();

  return (
    <>
      <div className="grid col-span-4 gap-3">
        <p className="font-semibold text-gray-300">Time Off</p>
      </div>
      <div className="col-span-6 grid grid-cols-7 gap-4 ml-6">
        {entryTotalsByDate?.map((date) => {
          const dateHasPto = ptoEntryState[date];

          let cellValue = "--";

          if (dateHasPto && parseFloat(dateHasPto?.numHours) > 0) {
            cellValue = `${parseFloat(dateHasPto?.numHours)} hrs`;
          }

          return (
            <p key={date} className="pl-2 col-span-1 text-gray-450">
              {cellValue}
            </p>
          );
        })}
      </div>
    </>
  );
};

export default PtoRow;

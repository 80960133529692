import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import _ from "lodash";
import * as yup from "yup";
import { useQueryClient } from "react-query";
import SecondaryButton from "../Buttons/SecondaryButton";
import Input from "../Input/Input";
import SignUpAPI from "../../../Pages/SignUp/SignUpAPI";
import Modal from "../Modal/Modal";
import { toastError, toastMessage } from "../Toast/Toast";

const errorCN = cntl`
  text-brandRed
  italic
  text-sm
  ml-3
`;

const UserSeats = ({
  selectedPlan,
  activeUsers,
  onClose,
  dispatch,
  isOpen,
}) => {
  const queryClient = useQueryClient();
  const [seats, setSeats] = useState(0);
  const [errorMessage, setErrorMessage] = useState();
  const [isSaving, setIsSaving] = useState();

  useEffect(() => {
    const validate = async () => {
      try {
        await yup
          .number()
          .positive()
          .integer()
          .max(selectedPlan?.maxUsers)
          .min(
            Math.max(selectedPlan?.minUsers, Number.parseInt(activeUsers, 10))
          )
          .validate(seats);
        // validation passed
        setErrorMessage(undefined);
      } catch (err) {
        setErrorMessage(err.message.replace("this", "User Seats"));
      }
    };

    validate();
  }, [activeUsers, seats, selectedPlan]);

  useEffect(() => {
    setSeats(parseInt(selectedPlan?.userCount, 10));
  }, [selectedPlan]);

  const onSave = useCallback(async () => {
    try {
      setIsSaving(true);
      const subscriptionItem = await SignUpAPI.updateSubscriptionItem(
        selectedPlan?.subscriptionItem,
        {
          quantity: seats,
        },
        queryClient
      );

      dispatch({
        type: "userCount",
        value: subscriptionItem?.quantity,
      });
      dispatch({
        type: "nextPayment",
        value:
          Number.parseFloat(subscriptionItem?.plan?.amount / 100) *
          subscriptionItem?.quantity.toFixed(2),
      });
      onClose();
      toastMessage("Successfully updated user seats");
    } catch (e) {
      console.error("user seats error", e);
      toastError(
        `Error updating user seats. ${
          e?.response?.data?.issues[0]?.location || e.message || ""
        }`
      );
    } finally {
      setIsSaving(false);
    }
  }, [dispatch, onClose, seats, selectedPlan, queryClient]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestModalClose={onClose}
      title="CHANGE USER SEATS"
      hideFooter
      primaryButtonTitle="Confirm Change"
      primaryButtonOnClick={onSave}
      tertiaryButtonTitle="Cancel"
      disabled={
        seats < selectedPlan?.minUsers ||
        seats > selectedPlan?.maxUsers ||
        !!errorMessage ||
        isSaving
      }
      crossIconGreen
    >
      <div className="flex flex-col">
        <div className="flex">
          <p>
            You are currently using {activeUsers} out of{" "}
            {selectedPlan?.userCount} seats
          </p>
        </div>
        <div className="flex content-center py-4">
          <div className="flex pr-2 self-end pb-1">
            <SecondaryButton
              title="-"
              style={{ maxWidth: 15, maxHeight: 35 }}
              className="rounded-lg"
              onClick={() => setSeats((prev) => prev - 1)}
            />{" "}
          </div>
          <div className="flex">
            <Input
              placeholder="Number"
              label="No. of Seats"
              value={seats}
              onChange={(val) => {
                const value = parseInt(val, 10);
                setSeats(_.isFinite(value) ? value : 0);
              }}
              disableClear
              className="w-28"
            />
          </div>
          <div className="flex px-2 self-end pb-1">
            <SecondaryButton
              title="+"
              style={{ maxWidth: 15, maxHeight: 35 }}
              className="rounded-lg"
              onClick={() => setSeats((prev) => prev + 1)}
            />
          </div>
          <div className="flex self-center px-4">
            <p className="text-xs text-gray-400">
              Your current number of seats
            </p>
          </div>
        </div>
        <div>
          <p className={errorCN}>{errorMessage}</p>
        </div>
      </div>
    </Modal>
  );
};

UserSeats.propTypes = {
  selectedPlan: PropTypes.shape({
    minUsers: PropTypes.string,
    maxUsers: PropTypes.string,
    userCount: PropTypes.string,
    subscriptionItem: PropTypes.string,
  }),
  activeUsers: PropTypes.string,
  onClose: PropTypes.func,
  dispatch: PropTypes.func,
  isOpen: PropTypes.bool,
};

UserSeats.defaultProps = {
  selectedPlan: undefined,
  activeUsers: undefined,
  onClose: undefined,
  dispatch: undefined,
  isOpen: false,
};

export default UserSeats;

import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import Input from "../Input/Input";
import Dropdown from "../Dropdown/Dropdown";
import Avatar from "../Message/Avatar";
import IconButton from "../Buttons/IconButton";
import closeIcon from "../../assets/images/crossIcon.svg";

const ContactDetailsForm = ({
  contactDetails,
  setContactDetails,
  companyContacts,
  isEdit,
  onBoarding,
  saving,
  disbaleProfileEdit,
  inputRefs,
  handleEnter,
  onSave,
  setEditingContact,
}) => {
  const [hovering, setHovering] = useState(false);

  const toggleEdit = () => {
    onSave();
    setEditingContact((prev) => !prev);
  };

  const handleHovering = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    setHovering(value);
  };
  const handleBlur = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    setHovering(value);
  };

  const avatarInput = useRef(null);
  const onChange = useCallback(
    async (e) => {
      const reader = new FileReader();
      const avatar = e.target.files[0];
      reader.readAsDataURL(avatar);
      setContactDetails((prev) => ({
        ...prev,
        avatar,
      }));
    },
    [setContactDetails]
  );
  const addPhoto = () => {
    avatarInput.current.click();
  };

  return (
    <>
      <div
        className={`flex flex-row ${
          onBoarding ? "items-center" : "items-start"
        } mt-6 mb-6`}
        onMouseOver={(e) => handleHovering(e, true)}
        onFocus={(e) => handleHovering(e, true)}
        onMouseOut={(e) => handleHovering(e, false)}
        onBlur={(e) => handleBlur(e, false)}
        role="presentation"
      >
        <div className="flex justify-between items-center w-full mb-6">
          <div
            className={`flex flex-row ${
              onBoarding ? "items-center" : "items-start"
            } mt-6 mb-6 ${!isEdit ? "w-full" : ""}`}
          >
            <div
              className={`flex flex-col items-start justify-start h-full ${
                onBoarding ? "mr-8" : "mr-5"
              }`}
            >
              <input
                type="file"
                style={{ display: "none" }}
                ref={avatarInput}
                onChange={onChange}
              />
              <div className="relative">
                <Avatar
                  className="w-24 h-24 border-4"
                  fontClassName="text-3xl"
                  isExternalUser
                  {...contactDetails}
                  name={
                    contactDetails?.firstName &&
                    contactDetails?.lastName &&
                    `${contactDetails?.firstName} ${contactDetails?.lastName}`
                  }
                  addPhoto={!saving && !disbaleProfileEdit && addPhoto}
                />
              </div>
            </div>
            <div className="w-full">
              {!onBoarding && (
                <p className="text-xl font-medium uppercase">
                  {`${contactDetails?.firstName || ""} ${
                    contactDetails?.lastName || ""
                  }`}
                </p>
              )}

              <div className="grid grid-cols-12 gap-4">
                <Input
                  autoFocus
                  mainClassName={`col-span-${
                    contactDetails?.kind !== "company" ? "6" : "12"
                  }`}
                  label={
                    contactDetails?.kind !== "company"
                      ? "First Name"
                      : "Company Name"
                  }
                  value={contactDetails?.firstName}
                  onChange={(val) =>
                    setContactDetails({
                      ...contactDetails,
                      firstName: val,
                    })
                  }
                  placeholder={
                    contactDetails?.kind !== "company"
                      ? "First Name"
                      : "Company Name"
                  }
                  validation={yup.string().required()}
                  // eslint-disable-next-line no-return-assign, no-param-reassign
                  forwardedRef={(el) => (inputRefs.current.firstName = el)}
                  handleEnter={handleEnter}
                  name="firstName"
                />
                {contactDetails?.kind !== "company" && (
                  <Input
                    mainClassName="col-span-6"
                    label="Last Name"
                    value={contactDetails?.lastName}
                    onChange={(val) =>
                      setContactDetails({
                        ...contactDetails,
                        lastName: val,
                      })
                    }
                    placeholder="Last Name"
                    validation={yup.string().trim().required()}
                    // eslint-disable-next-line no-return-assign, no-param-reassign
                    forwardedRef={(el) => (inputRefs.current.lastName = el)}
                    handleEnter={handleEnter}
                    name="lastName"
                  />
                )}
              </div>
              <div className="grid grid-cols-12 gap-4 mt-2">
                {contactDetails?.kind !== "company" && (
                  <>
                    <Input
                      mainClassName="col-span-6"
                      label="Title"
                      value={
                        contactDetails?.title === "null"
                          ? undefined
                          : contactDetails?.title
                      }
                      onChange={(val) =>
                        setContactDetails({
                          ...contactDetails,
                          title: val,
                        })
                      }
                      placeholder="Title"
                      validation={yup.string()}
                      // eslint-disable-next-line no-return-assign, no-param-reassign
                      forwardedRef={(el) => (inputRefs.current.Title = el)}
                      handleEnter={handleEnter}
                      name="Title"
                      disabled={saving}
                    />

                    <Dropdown
                      label="Company"
                      options={companyContacts}
                      value={contactDetails?.company}
                      onChange={(val) =>
                        setContactDetails({
                          ...contactDetails,
                          company: val,
                          // eslint-disable-next-line no-underscore-dangle
                          isNewCompany: val?.__isNew__,
                        })
                      }
                      className="col-span-6"
                      placeholder="Select"
                      isCreatable
                      // eslint-disable-next-line no-return-assign, no-param-reassign
                      forwardedRef={(el) => (inputRefs.current.Company = el)}
                      onKeyUp={handleEnter}
                      name="Company"
                      // TODDO: This might change with RBAC
                      isDisabled={saving}
                    />
                  </>
                )}
              </div>
            </div>
            {isEdit && (
              <IconButton
                wrapperClassName={`${hovering ? "" : "hidden"} ${
                  contactDetails?.kind !== "company" ? "ml-4" : ""
                }`}
                imgClassName={`${isEdit ? "w-4 h-4" : "w-6 h-6"}`}
                iconClassName={`${isEdit ? "w-4 h-4" : "w-6 h-6"}`}
                onClick={toggleEdit}
                icon={closeIcon}
                disabled={
                  // eslint-disable-next-line react/prop-types
                  !(
                    (contactDetails?.firstName &&
                      contactDetails?.lastName &&
                      contactDetails?.kind !== "company") ||
                    (contactDetails?.kind === "company" &&
                      contactDetails?.firstName)
                  )
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

ContactDetailsForm.propTypes = {
  contactDetails: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    avatar: PropTypes.object,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    title: PropTypes.string,
    companyName: PropTypes.string,
    company: PropTypes.shape({}),
    kind: PropTypes.string,
  }),
  setContactDetails: PropTypes.func,
  isEdit: PropTypes.bool,
  companyContacts: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onBoarding: PropTypes.bool,
  saving: PropTypes.bool,
  disbaleProfileEdit: PropTypes.bool,
  inputRefs: PropTypes.shape({
    current: PropTypes.shape({
      Company: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      Title: PropTypes.string,
    }),
  }),
  handleEnter: PropTypes.func,
  setEditing: PropTypes.func,
  setEditingContact: PropTypes.func,
  onSave: PropTypes.func,
};

ContactDetailsForm.defaultProps = {
  contactDetails: undefined,
  setContactDetails: undefined,
  isEdit: false,
  companyContacts: undefined,
  onBoarding: false,
  saving: false,
  disbaleProfileEdit: false,
  inputRefs: { current: {} },
  handleEnter: undefined,
  setEditing: undefined,
  setEditingContact: undefined,
  onSave: undefined,
};

export default ContactDetailsForm;

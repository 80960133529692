/* eslint-disable no-param-reassign */
import { useMutation } from "react-query";
import { TodoAPI } from "@griffingroupglobal/eslib-api";
import { useCallback } from "react";
import { toastError } from "../helpers/Toast";
import queryClient from "../config/reactQuery/queryClient";
import { todosCodeKeys } from "../config/reactQuery/queryKeyFactory";
import useCurrentUser from "./useCurrentUser";

export default () => {
  const { data: currentUser } = useCurrentUser();
  const completeTodo = useCallback(async (todo) => {
    return TodoAPI.postWOP(`${todo?.id}/$checked`);
  }, []);

  return useMutation({
    mutationFn: completeTodo,
    onMutate: async (todo) => {
      await queryClient.cancelQueries({ queryKey: todosCodeKeys });
      const prev = queryClient.getQueryData(todosCodeKeys);

      queryClient.setQueryData(todosCodeKeys, (current) => {
        return current.map((item) =>
          todo?.id === item?.id
            ? {
                ...todo,
                members: todo?.members?.map((member) => {
                  if (member?.user === currentUser?.reference) {
                    return { ...member, checked: !member?.checked };
                  }
                  return member;
                }),
              }
            : item
        );
      });

      return { prev };
    },
    onSettled: () => {
      queryClient.invalidateQueries(todosCodeKeys);
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(todosCodeKeys, context?.prev);
      toastError(
        `There was an error performing this action. \n Message: ${error.message} \n Please try again`
      );
      console.error("useTodosMarkComplete", error);
    },
  });
};

import { cleanUpTitle } from "../Formatters";

const doesNameOrTitleExist = ({
  resourceTitle,
  resourceName,
  resourceType,
  resourceDict,
}) => {
  try {
    const nameExists = Object.values(resourceDict)?.some((existingResource) =>
      resourceType === "Project"
        ? cleanUpTitle(existingResource.name?.toLowerCase()) ===
          cleanUpTitle(resourceName?.toLowerCase())
        : cleanUpTitle(existingResource.title?.toLowerCase()) ===
          cleanUpTitle(resourceTitle?.toLowerCase())
    );
    return nameExists;
  } catch (error) {
    console.error("Unable to check duplicate value", error);
    return false;
  }
};

export default doesNameOrTitleExist;

import React, { useState, useEffect } from "react";
import { AssetAPI, DocumentAPI } from "@griffingroupglobal/eslib-api";
import { useAppState } from "../state/appState";

import { SET_DOCUMENTS, SET_DOCUMENTS_DICT } from "../constants";
import { decorateDocumentsWithUserData } from "../helpers/Document";

export default function useDocuments() {
  const [{ documents, documentsDict, userDict }, dispatch] = useAppState();
  const [isLoading, setIsLoading] = useState(true);

  const reload = React.useCallback(async () => {
    try {
      const { data } = await DocumentAPI.get();
      const tempDocs = decorateDocumentsWithUserData(data.entries, userDict);

      const obj = tempDocs.reduce(
        (acc, doc) => {
          acc.documents.push(doc.resource);
          acc.dictionary[doc.resource.reference] = doc.resource;
          return acc;
        },
        {
          documents: [],
          dictionary: {},
        }
      );
      dispatch({
        type: SET_DOCUMENTS,
        documents: obj.documents,
      });

      dispatch({
        type: SET_DOCUMENTS_DICT,
        documentsDict: obj.dictionary,
      });

      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, [userDict, dispatch]);

  const addDocument = React.useCallback(
    (doc) => {
      const found = documents?.find((item) => item.id === doc.id);
      let tempDocs = documents ?? [];
      if (found) {
        tempDocs = tempDocs.map((item) => {
          if (item.id === doc.id) {
            return { ...item, ...doc };
          }
          return item;
        });
      } else {
        let dcmt = [{ resource: doc }];
        dcmt = decorateDocumentsWithUserData(dcmt, userDict);
        tempDocs.unshift(dcmt[0].resource);
      }
      dispatch({
        type: SET_DOCUMENTS,
        documents: tempDocs,
      });
    },
    [userDict, documents, dispatch]
  );

  const addDocuments = React.useCallback(
    (docs) => {
      const tempDocs =
        documents?.filter((item) => {
          return !docs.find((doc) => doc.id === item.id);
        }) ?? {};
      const finalDocs = [
        ...docs.map((item) => {
          const found = documents?.find((doc) => doc.id === item.id);
          if (found) {
            return {
              ...found,
              ...item,
              docType: found.newDocType ?? found.docType,
              isFavorited: item.isFavorited,
            };
          }
          let dcmt = [{ resource: item }];
          dcmt = decorateDocumentsWithUserData(dcmt, userDict);
          return { ...dcmt[0].resource, isFavorited: item.isFavorited };
        }),
        ...tempDocs,
      ];

      dispatch({
        type: SET_DOCUMENTS,
        documents: finalDocs,
      });
    },
    [userDict, documents, dispatch]
  );

  const removeDocument = React.useCallback(
    (docId) => {
      dispatch({
        type: SET_DOCUMENTS,
        documents: documents?.filter((item) => item.id !== docId),
      });
    },
    [documents, dispatch]
  );

  const assetDocuments = React.useCallback(
    async (asset) => {
      if (!asset?.documents || !documents) return [];
      const workingDocRefs = asset.documents;
      const foundDocs = [];
      const missingDocs = [];

      workingDocRefs.forEach((ref) => {
        const found = documents.find((doc) => doc.reference === ref);
        if (found) {
          foundDocs.push(found);
        } else {
          missingDocs.push(ref);
        }
      });

      let newAsset;
      if (missingDocs.length) {
        newAsset = await AssetAPI.patch(
          asset.id,
          { ...asset, documents: foundDocs.map((item) => item.reference) },
          asset
        );
      }

      return { assetDocs: foundDocs, updatedAsset: newAsset?.data };
    },
    [documents]
  );

  useEffect(() => {
    if (Object.keys(userDict || {}).length) {
      reload();
    }
  }, [reload, userDict]);

  return {
    documents,
    isLoading,
    reload,
    addDocument,
    addDocuments,
    removeDocument,
    assetDocuments,
    documentsDict,
  };
}

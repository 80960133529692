/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import cntl from "cntl";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import DeleteButton from "../DeleteButton/DeleteButton";
import PlusArrowIcon from "../PlusArrowIcon/PlusArrowIcon";
import CrossButton from "../CrossButton/CrossButton";
import editIcon from "../../assets/images/editIcon.svg";
import checkIcon from "../../assets/images/checkmark.svg";
import "./TableRow.css";
import BaseButton from "../Buttons/BaseButton";

const menuContainerCN = cntl`
  z-50
  font-bold
  text-gray-400
  p-1
  tracking-widest
`;

const tableCellCN = (
  isClickable,
  showBorder,
  isVersionDateRow,
  isVersion,
  isPopoverOpen,
  isEditPopOverOpen,
  className
) => cntl`
${className}
  ${
    !isVersion &&
    !isVersionDateRow &&
    !isPopoverOpen &&
    !isEditPopOverOpen &&
    cntl`border-b border-gray-100`
  }
  ${(isVersion || isVersionDateRow) && cntl`version-table-cell-border`}
  pl-5
  flex
  text-xs
  h-9
  ${isClickable && cntl`cursor-pointer`}
  relative
  bg-white
  `;

const cellText = (textClassName) => cntl`
  ${textClassName}
`;

const hoverClickableRowCN = (isVersionDateRow, isVersion) => cntl`
  cursor-pointer
  ${(isVersion || isVersionDateRow) && cntl`border-b-brandGreen border-t`}
  ${!isVersion && !isVersionDateRow && cntl`border-b-brandGreen`}
  bg-gray-50
`;

const hoverMenuCN = cntl`
  flex
  self-start
`;

const overlayCN = (isVersion, enableEditOverlay, showOverlay) => cntl`
  absolute
  flex-none
  right-24
  border-b
  flex
  items-center
  w-0
  overflow-visible
  ${!isVersion && enableEditOverlay && showOverlay ? null : "invisible"}
`;

const overlayInnerContainerCN = (enableDeleteOverlay, showContainer) => cntl`
  ${
    showContainer &&
    `bg-white bg-opacity-50 flex flex-none items-center mt-1 ${
      enableDeleteOverlay ? "" : "border rounded"
    }`
  }
`;

const getStyles = (props, { id }) => {
  if (id === "selection") {
    return [
      props,
      {
        style: {
          flex: "none",
          width: "60px",
          display: "flex",
          justifyContent: "center",
          paddingLeft: "0px",
        },
      },
    ];
  }

  return [props, {}];
};

const cellProps = (props, { cell }) => getStyles(props, cell.column);

const getTitle = (item, row) => {
  if (item?.useDynamicTitleForActiveUserStatus) {
    if (row?.original?.active) {
      return "Deactivate";
    }
    return "Activate";
  }
  return item?.title;
};

const getClassName = (item, row) => {
  if (item?.useDynamicTitleForActiveUserStatus) {
    if (row?.original?.active) {
      return `${item?.className} ${
        item?.className?.includes("opacity") && "opacity-100"
      }`;
    }
    return item?.className;
  }
  return item?.className;
};

const TableRow = ({
  className,
  textClassName,
  row,
  onClick,
  enableEditOverlay,
  enableDeleteOverlay,
  onEditSaveClick,
  onEditCancelClick,
  onDeleteClick,
  isEditing,
  onEditClick: onEditClickProp,
  enableAddOverlay,
  enableClickableHoverOverlay,
  onAddRowClose,
  onEditRowClose,
  enablePopOverEditing,
  inLineForm,
  rowAssociationMenuList,
  groupBy,
  isVersion,
  rowHoverComponent,
  toggleRowExpanded,
  isVersionDateRow,
  noDeleteConfirm,
  hideEditRowButton,
  hideDeleteRowButton,
  deleteButtonStyle,
  applyTemplate,
  allowEditingOnRowClick,
  onEditPopoverOpenCallback,
  onEditPopoverCloseCallback,
  isEditingAllInlineEditForms,
  lastRowReferenceForInfiniteScroll,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isEditPopOverOpen, setIsEditPopOverOpen] = useState(false);
  const [isSplitLinePopOverOpen, setIsSplitLinePopOverOpen] = useState(false);
  const [associationMenuList, setAssociationMenuList] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (isEditingAllInlineEditForms !== undefined) {
      if (isEditingAllInlineEditForms) {
        setIsEditPopOverOpen(true);
      } else {
        setIsEditPopOverOpen(false);
      }
    }
  }, [isEditingAllInlineEditForms]);

  useEffect(() => {
    if (row.original?.addAnother) {
      setIsPopoverOpen(true);
    } else {
      setIsPopoverOpen(false);
    }
  }, [row.original?.addAnother]);

  const onMouseEnter = () => {
    setShowOverlay(true);
  };

  const onMouseLeave = () => {
    setShowOverlay(false);
  };

  const onDeleteConfirm = useCallback(() => {
    onDeleteClick(row);
  }, [onDeleteClick, row]);

  const onCancelEdit = useCallback(() => {
    onEditCancelClick();
  }, [onEditCancelClick]);

  const onSaveEdit = useCallback(() => {
    onEditSaveClick();
  }, [onEditSaveClick]);

  const togglePopOver = useCallback(() => {
    if (isPopoverOpen && onAddRowClose) {
      onAddRowClose();
    }
    setIsPopoverOpen(!isPopoverOpen);
  }, [isPopoverOpen, onAddRowClose]);

  const toggleEditPopOver = useCallback(
    async (isSplitLine) => {
      if (isEditPopOverOpen && onEditRowClose) {
        onEditRowClose();
      }
      if (!isEditPopOverOpen && onEditPopoverOpenCallback) {
        await onEditPopoverOpenCallback(row.id, row.original);
      }
      if (isEditPopOverOpen && onEditPopoverCloseCallback) {
        await onEditPopoverCloseCallback(row.id);
      }
      setIsEditPopOverOpen(!isEditPopOverOpen);
      if (isSplitLine) {
        setIsSplitLinePopOverOpen(true);
      } else {
        setIsSplitLinePopOverOpen(false);
      }
    },
    [
      isEditPopOverOpen,
      onEditRowClose,
      onEditPopoverOpenCallback,
      onEditPopoverCloseCallback,
      row,
    ]
  );

  const onEditClick = useCallback(
    (isSplitLine) => {
      if (enablePopOverEditing) {
        toggleEditPopOver(isSplitLine);
      } else {
        onEditClickProp();
      }
    },
    [enablePopOverEditing, onEditClickProp, toggleEditPopOver]
  );

  useEffect(() => {
    if (
      row.original?.sov?.length &&
      isEditingAllInlineEditForms === undefined
    ) {
      onEditClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rowAssociationMenuList.length) {
      setAssociationMenuList(
        rowAssociationMenuList
          .filter((item) => {
            if (item?.hideForCurrentUser) {
              return !row?.original?.isCurentUser;
            }
            if (item?.hideForRegisteredUser) {
              return !row?.original?.registered;
            }
            if (item?.hideForNonEmployees) {
              return row?.original?.isEmployee;
            }
            if (item?.showForLineitemsLockedByUser) {
              const reference = row?.original?.lock?.lockedBy?.reference;
              return reference && reference !== "";
            }
            return true;
          })
          .map((item) => {
            return {
              ...item,
              className: getClassName(item, row),
              onClick: (e) => {
                e?.stopPropagation();
                if (item.clearTemplateOnClick) {
                  applyTemplate(null, true);
                }

                if (item.isEditOption) {
                  onEditClick();
                }

                if (item.isDeleteOption) {
                  setIsDeleteModalOpen(true);
                }

                if (item.isSplitLineOption) {
                  onEditClick(true);
                }

                // pass line item id to the function to be used as a history param to fetch only related data
                item.onClick(
                  row?.original?.parentIdOfVersion || row.id,
                  toggleRowExpanded,
                  row?.original?.index
                );
              },
              title: getTitle(item, row),
            };
          })
      );
    }
  }, [
    applyTemplate,
    onDeleteConfirm,
    onEditClick,
    row,
    rowAssociationMenuList,
    toggleRowExpanded,
  ]);

  const getContent = useMemo(
    () => (
      <div
        className="flex flex-row w-full"
        ref={lastRowReferenceForInfiniteScroll}
      >
        {(row.isManualGrouped && (
          <div
            className="mb-4"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <div
              className={`${tableCellCN(
                true,
                false,
                isVersionDateRow,
                isVersion,
                isPopoverOpen,
                isEditPopOverOpen,
                className
              )} ${enableClickableHoverOverlay}`}
            />
            {!isPopoverOpen && enableAddOverlay && (
              <div className="absolute flex justify-start items-start left-3 -mt-2.5 h-12">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePopOver();
                  }}
                  type="button"
                  aria-label="add row"
                >
                  <PlusArrowIcon fill={showOverlay ? "#1CC39D" : "#d0d0d0"} />
                </button>
              </div>
            )}
          </div>
        )) || (
          <div
            {...{
              ...row?.getRowProps(),
              style: { ...row?.getRowProps()?.style },
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={() => allowEditingOnRowClick && onEditClick()}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
          >
            {row.cells.map((cell) => {
              if (cell.column.id === "selection") {
                if (!cell.isPlaceholder) {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <div
                      {...cell.getCellProps(cellProps)}
                      className={`${tableCellCN(
                        false,
                        true,
                        isVersionDateRow,
                        isVersion,
                        isPopoverOpen,
                        isEditPopOverOpen,
                        className
                      )} ${
                        enableClickableHoverOverlay &&
                        showOverlay &&
                        hoverClickableRowCN(isVersionDateRow, isVersion)
                      }`}
                    >
                      <div className="flex w-full justify-center">
                        {cell.render("Cell")}
                      </div>
                    </div>
                  );
                }
                return null;
              }
              return isEditing ? (
                !cell.isGrouped && !cell.isPlaceholder ? (
                  <div
                    {...cell.getCellProps(cellProps)}
                    className={`${tableCellCN(
                      false,
                      true,
                      isVersionDateRow,
                      isVersion,
                      className
                    )} ${
                      enableClickableHoverOverlay &&
                      showOverlay &&
                      hoverClickableRowCN(isVersionDateRow, isVersion)
                    }`}
                  >
                    {cell.render("Edit", { onSaveEdit })}
                  </div>
                ) : null
              ) : !cell.isPlaceholder ? (
                <div
                  onClick={() => {
                    onClick(row);
                  }}
                  role="button"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      onClick(row);
                    }
                  }}
                  tabIndex={0}
                  {...cell.getCellProps(cellProps)}
                  className={`${tableCellCN(
                    true,
                    true,
                    isVersionDateRow,
                    isVersion,
                    isPopoverOpen,
                    isEditPopOverOpen,
                    className
                  )} ${
                    enableClickableHoverOverlay &&
                    showOverlay &&
                    hoverClickableRowCN(isVersionDateRow, isVersion)
                  }`}
                >
                  {cell.isGrouped ? (
                    <div className={cellText(textClassName)}>
                      {cell.render("Cell")}
                    </div>
                  ) : cell.isAggregated ? (
                    <div className={cellText(textClassName)}>
                      {cell.render("Aggregated")}
                    </div>
                  ) : (
                    <div className={cellText(textClassName)}>
                      {cell.render("Cell")}
                    </div>
                  )}
                </div>
              ) : null;
            })}
            {!isVersion && enableAddOverlay && showOverlay && (
              <div className="absolute flex -left-5 h-12 mt-1.5 z-50">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePopOver();
                  }}
                  type="button"
                  className="mt-3"
                  aria-label="add row"
                >
                  <PlusArrowIcon />
                </button>
              </div>
            )}
            {!isVersion && showOverlay && row?.original?.showReject && (
              <div className="sticky flex-none right-12 border-b flex items-center w-0 overflow-visible">
                {rowHoverComponent(row)}
              </div>
            )}
            <div
              className={overlayCN(isVersion, enableEditOverlay, showOverlay)}
            >
              <div
                className={overlayInnerContainerCN(
                  enableDeleteOverlay,
                  !row?.original?.hideRowAssociationMenuList
                )}
              >
                {isEditing && (
                  <>
                    <button
                      className="edit-icon mr-3"
                      onClick={(e) => {
                        e.stopPropagation();
                        onSaveEdit();
                      }}
                      type="button"
                    >
                      <img
                        className="w-5 h-5"
                        src={checkIcon}
                        alt="edit icon"
                      />
                    </button>
                    <CrossButton
                      onClick={() => {
                        onCancelEdit();
                      }}
                    />
                  </>
                )}
                {!isEditing && (
                  <>
                    {!hideEditRowButton && (
                      <button
                        className="mr-1 w-4.5 h-4.5"
                        onClick={(e) => {
                          e.stopPropagation();
                          onEditClick();
                        }}
                        type="button"
                      >
                        <img src={editIcon} alt="edit icon" />
                      </button>
                    )}
                    {!hideDeleteRowButton && (
                      <DeleteButton
                        onConfirm={onDeleteConfirm}
                        noConfirm={noDeleteConfirm}
                        iconClassName="w-4.5 h-4.5"
                      />
                    )}
                    {!!associationMenuList.length &&
                      !row?.original?.hideRowAssociationMenuList && (
                        <div
                          className={hoverMenuCN}
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsEditPopOverOpen(false);
                          }}
                          tabIndex={0}
                          role="button"
                          onKeyPress={() => {}}
                          aria-label="association menu"
                        >
                          <BaseButton
                            dropdownItems={associationMenuList}
                            className={menuContainerCN}
                            title="···"
                            showMenu
                            hideDropdownChevron
                          />
                        </div>
                      )}
                  </>
                )}
              </div>
            </div>
            {enableDeleteOverlay && !enableEditOverlay && (
              <div
                className={overlayCN(
                  isVersion,
                  enableDeleteOverlay && !enableEditOverlay,
                  showOverlay
                )}
              >
                <div
                  className="flex flex-none items-center p-1 mt-2 bg-white bg-opacity-50"
                  style={deleteButtonStyle}
                >
                  <DeleteButton
                    onConfirm={onDeleteConfirm}
                    noConfirm={noDeleteConfirm}
                  />
                </div>
              </div>
            )}
            <DeleteButton
              onConfirm={onDeleteConfirm}
              noConfirm={noDeleteConfirm}
              iconClassName="w-4.5 h-4.5"
              isOpen={isDeleteModalOpen}
              hideDeleteButton
            />
          </div>
        )}
      </div>
    ),
    [
      lastRowReferenceForInfiniteScroll,
      row,
      isVersionDateRow,
      isVersion,
      isPopoverOpen,
      isEditPopOverOpen,
      className,
      enableClickableHoverOverlay,
      enableAddOverlay,
      showOverlay,
      rowHoverComponent,
      enableEditOverlay,
      enableDeleteOverlay,
      isEditing,
      hideEditRowButton,
      hideDeleteRowButton,
      onDeleteConfirm,
      noDeleteConfirm,
      associationMenuList,
      deleteButtonStyle,
      isDeleteModalOpen,
      togglePopOver,
      allowEditingOnRowClick,
      onEditClick,
      onSaveEdit,
      textClassName,
      onClick,
      onCancelEdit,
    ]
  );

  return (
    <>
      {getContent}
      {isPopoverOpen && (
        <div className="relative">
          <div className="border-b border-gray-100 static">
            {inLineForm(row, false, togglePopOver, setIsPopoverOpen, groupBy)}
          </div>
        </div>
      )}
      {isEditPopOverOpen && (
        <div className="relative">
          <div className="border-b border-gray-100 static">
            {inLineForm(
              row,
              true,
              toggleEditPopOver,
              setIsEditPopOverOpen,
              null,
              toggleRowExpanded,
              isSplitLinePopOverOpen
            )}
          </div>
        </div>
      )}
    </>
  );
};

TableRow.propTypes = {
  /**
   * custom cell styling
   */
  className: PropTypes.string,
  /**
   * custom text styling
   */
  textClassName: PropTypes.string,
  /**
   * sent by react-table, contains row info
   */
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object.isRequired,
  /**
   * function called when the table row is clicked
   */
  onClick: PropTypes.func,
  /**
   * enables the button overlay that shows up on hover
   */
  enableEditOverlay: PropTypes.bool,
  /**
   * enables the delete overlay that shows up on hover
   */
  enableDeleteOverlay: PropTypes.bool,
  /**
   * enables the add button overlay that shows up on hover
   */
  enableAddOverlay: PropTypes.bool,
  /**
   * enables the highlight contrast and underline that shows up on hover
   */
  enableClickableHoverOverlay: PropTypes.bool,
  /**
   * Function that returns React component to be displayed on add row
   */
  inLineForm: PropTypes.func,
  /**
   * Function called on add row romponent is closed
   */
  onAddRowClose: PropTypes.func,
  /**
   * function called on edit save
   */
  onEditSaveClick: PropTypes.func,
  /**
   * function called on edit cancel
   */
  onEditCancelClick: PropTypes.func,
  /**
   * function called on delete   */
  onDeleteClick: PropTypes.func,
  /**
   * function called on edit start
   */
  onStartEditing: PropTypes.func,
  /**
   * function called on edit end
   */
  onEndEditing: PropTypes.func,
  /**
   * when true, the edit view is rendered
   */
  isEditing: PropTypes.bool,
  /**
   * function called when the edit button is clicked
   */
  onEditClick: PropTypes.func,
  enablePopOverEditing: PropTypes.bool,
  onEditRowClose: PropTypes.func,
  rowAssociationMenuList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      className: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  groupBy: PropTypes.arrayOf(PropTypes.string),
  /**
   * boolean to detrmine if the row is a version/history
   */
  isVersion: PropTypes.bool,
  onRejectRow: PropTypes.func,
  toggleRowExpanded: PropTypes.func,
  isVersionDateRow: PropTypes.bool,
  rowHoverComponent: PropTypes.func,
  /**
   * bypass delete confirmation modal
   */
  noDeleteConfirm: PropTypes.bool,
  hideEditRowButton: PropTypes.bool,
  hideDeleteRowButton: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  deleteButtonStyle: PropTypes.object,
  applyTemplate: PropTypes.func,
  allowEditingOnRowClick: PropTypes.bool,
  onEditPopoverCloseCallback: PropTypes.func,
  onEditPopoverOpenCallback: PropTypes.func,
  isEditingAllInlineEditForms: PropTypes.bool,
  lastRowReferenceForInfiniteScroll: PropTypes.oneOfType([
    PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

TableRow.defaultProps = {
  className: "items-center",
  textClassName: "truncate",
  onClick: undefined,
  enableEditOverlay: false,
  enableDeleteOverlay: false,
  onEditSaveClick: undefined,
  onEditCancelClick: undefined,
  onDeleteClick: undefined,
  onStartEditing: undefined,
  onEndEditing: undefined,
  isEditing: false,
  onEditClick: undefined,
  enableAddOverlay: false,
  enableClickableHoverOverlay: true,
  inLineForm: undefined,
  onAddRowClose: undefined,
  onEditRowClose: undefined,
  enablePopOverEditing: false,
  rowAssociationMenuList: [],
  groupBy: [],
  isVersion: false,
  onRejectRow: undefined,
  toggleRowExpanded: undefined,
  isVersionDateRow: false,
  rowHoverComponent: undefined,
  noDeleteConfirm: false,
  hideEditRowButton: false,
  hideDeleteRowButton: false,
  deleteButtonStyle: undefined,
  applyTemplate: undefined,
  allowEditingOnRowClick: false,
  onEditPopoverCloseCallback: undefined,
  onEditPopoverOpenCallback: undefined,
  isEditingAllInlineEditForms: undefined,
  lastRowReferenceForInfiniteScroll: undefined,
};

export default TableRow;

/* eslint-disable no-await-in-loop */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { UserAPI } from "@griffingroupglobal/eslib-api";
import { SET_USERS_STREAM_DICT, SET_USERS_STREAM_INFO } from "../constants";
import { useAppState } from "../state/appState";
import { useESChatContext } from "../context/ChatContext/ESChatContext";

export default (refetch = true) => {
  const [{ usersStreamInfo }, dispatch] = useAppState([]);
  const { clientChat: client } = useESChatContext();
  const [loading, setLoading] = useState(true);

  const reload = useCallback(async () => {
    setLoading(true);
    const { data } = await UserAPI.getWOP("$getstreaminfo");
    const streamUserIds = [];
    let streamUserDict = {};
    let esUsers = data.map((user) => {
      streamUserIds.push(user.streamId);
      return {
        ...user,
        id: user.streamId,
      };
    });
    let streamUsersInfo = [];
    const chunks = _.chunk(streamUserIds, 30);
    for (let i = 0; i < chunks.length; i += 1) {
      try {
        const { users } = await client?.queryUsers({
          id: { $in: chunks[i] },
        });
        streamUsersInfo = streamUsersInfo.concat(users);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("Error Resolving Chat Users", e);
      }
    }

    esUsers = esUsers.map((esUser) => {
      const found = streamUsersInfo?.find((su) => su.id === esUser.streamId);
      esUser.isOnline = found?.online;
      esUser.lastActive = found?.last_active;
      esUser.avatarRef = esUser?.avatar;

      streamUserDict = {
        ...streamUserDict,
        [esUser?.streamId]: esUser,
      };
      return esUser;
    });

    dispatch({
      type: SET_USERS_STREAM_INFO,
      usersStreamInfo: esUsers,
    });

    dispatch({
      type: SET_USERS_STREAM_DICT,
      usersStreamDict: streamUserDict,
    });

    setLoading(false);
  }, [client, dispatch]);

  useEffect(() => {
    if (!refetch) return;
    if (client) {
      reload();
    }
  }, [refetch, client, dispatch, reload]);

  return [usersStreamInfo, loading];
};

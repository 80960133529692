import React from "react";
import PropTypes from "prop-types";
import ReviewProfileForm from "../ReviewProfileForm/index";
import NewPasswordForm from "../NewPasswordForm/index";
import useUserOnboardingForm from "./useUserOnboardingForm";

const UserOnboardingForm = ({ temporaryPassword, tenantDetails, email }) => {
  const {
    setIsTNCCheck,
    isTNCChecked,
    editableUser,
    setEditableUser,
    saveButtonHandler,
    showProfile,
    setShowProfile,
    isLoading,
    isFormValid,
  } = useUserOnboardingForm({
    temporaryPassword,
    email,
    tenantDetails,
  });

  return (
    <div className="flex flex-col items-center pt-8">
      {showProfile ? (
        <ReviewProfileForm
          editableUser={editableUser}
          setEditableUser={setEditableUser}
          setIsTNCCheck={setIsTNCCheck}
          isTNCChecked={isTNCChecked}
          onFinish={saveButtonHandler}
          isLoading={isLoading}
          isFormValid={isFormValid}
        />
      ) : (
        <NewPasswordForm
          email={email}
          handleNext={() => setShowProfile(true)}
          temporaryPassword={temporaryPassword}
        />
      )}
    </div>
  );
};

UserOnboardingForm.propTypes = {
  temporaryPassword: PropTypes.string,
  email: PropTypes.string,
  tenantDetails: PropTypes.shape({}),
};

UserOnboardingForm.defaultProps = {
  temporaryPassword: undefined,
  email: undefined,
  tenantDetails: undefined,
};

export default UserOnboardingForm;

// Framework & Tools
import React, { useCallback, useState, useMemo, useEffect } from "react";
import { capitalize } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { CommentAPI } from "@griffingroupglobal/eslib-api";

// Helpers

// Constants
import { SUBMITTAL_STATUS_TYPES, WF_STATUS_TYPES } from "../../../constants";

// Images & Icons
import commentIcon from "../../assets/images/comment_icon_updated.svg";

// Components
import PrimaryButton from "../Buttons/PrimaryButton";
import Input from "../Input/Input";
import Modal from "../Modal/Modal";
import IconButton from "../Buttons/IconButton";
import RequestAttachmentAndUpload from "../RequestAttachmentAndUpload/RequestAttachmentAndUpload";
import PopupMessageBox from "../PopupMessage/PopupMessageBox";
import { toastError, toastMessage } from "../Toast/Toast";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { useAppState } from "../../../state/appState";
import RoleMembersAvatarList from "../../../Pages/Admin/RoleMembersAvatarList";

export default function RequestDetailViewCard({
  step,
  index,
  activeStep,
  requestData,
  onSubmit,
  onStepAction,
  complete,
  handleSendReminder,
  attachmentMap,
  setAttachmentMap,
  allSteps,
  onPatch,
  loading,
  setLoading,
  history,
  updateAssociatedFiles,
  getStepStatus,
  // commentMap, commented out until comment designs
}) {
  // hooks
  const [{ userDict }] = useAppState();
  const { data: currentUser } = useCurrentUser();
  // State to track action values
  const [actionValues, setActionValues] = useState({
    requestId: requestData?.id,
    stepId: step?.id,
    status: "",
    action: "",
    comment: "",
  });

  const [canComplete, setCanComplete] = useState(false);
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [showViewAllComments, setShowViewAllComments] = useState(false);
  const [allComments, setAllComments] = useState([]);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const isInProgress =
    requestData?.status === SUBMITTAL_STATUS_TYPES?.IN_PROGRESS ||
    requestData?.status === WF_STATUS_TYPES?.IN_PROGRESS;
  const userCanCompleteStep = useCallback(
    (usrArray) => {
      return usrArray?.some((item) => item?.data?.id === currentUser?.id);
    },
    [currentUser?.id]
  );

  useEffect(() => {
    setCanComplete(userCanCompleteStep(step?.users));
  }, [step?.users, userCanCompleteStep]);

  // Handle opening the comment modal
  const handleOpenCommentModal = useCallback(
    (actionStatus, action) => {
      setActionValues((prev) => {
        return { ...prev, status: actionStatus, action };
      });
      setCommentModalOpen(true);
    },
    [setCommentModalOpen]
  );

  // Memoized callback for generating dropdown options
  const handleDropdownOptions = useMemo(() => {
    return [
      {
        title: "Approved",
        onClick: () => handleOpenCommentModal("approved", "respond"),
      },
      {
        title: "Approved Resubmit Req.",
        onClick: () =>
          handleOpenCommentModal(
            "approved-resubmission-required",
            "respond",
            "Approved Resubmit Req."
          ),
      },
      {
        title: "Approved No Resubmit Req.",
        onClick: () =>
          handleOpenCommentModal(
            "approved-resubmission-not-required",
            "respond"
          ),
      },
      {
        title: "Revise and Resubmit",
        onClick: () => handleOpenCommentModal("revise-and-resubmit", "respond"),
      },
      {
        title: "Reject",
        onClick: () => handleOpenCommentModal("rejected", "respond"),
      },
      {
        title: "Void",
        onClick: () => handleOpenCommentModal("void", "respond"),
      },
    ];
  }, [handleOpenCommentModal]);
  // Generate button actions based on the type
  const buttonActions = (type) => {
    switch (type) {
      case "submitter": {
        return (
          requestData?.status !== SUBMITTAL_STATUS_TYPES?.DRAFT && (
            <PrimaryButton
              saveButton
              saveButtonTitle="Submit"
              className="reviewBtn"
              onClick={() =>
                handleOpenCommentModal("submit", "submit", "Submit")
              }
            />
          )
        );
      }
      case "reviewer": {
        return (
          <div id="reviewBtns" className="flex">
            <PrimaryButton
              actionLabel="Respond"
              saveButton
              saveButtonTitle="Respond"
              id="advance"
              dropdownItems={[
                {
                  title: "Advance",
                  onClick: () => {
                    handleOpenCommentModal("advance", "advance", "Advance");
                  },
                },
                {
                  title: "Return To Submitter",
                  onClick: () => {
                    handleOpenCommentModal(
                      "return-to-submitter",
                      "returntosubmitter",
                      "Return To Submitter"
                    );
                  },
                },
              ]}
            />
          </div>
        );
      }
      case "approver": {
        return (
          <PrimaryButton
            actionLabel="Respond"
            saveButton
            saveButtonTitle="Respond"
            hideDropdownChevron
            dropdownItems={handleDropdownOptions}
            title="Respond"
            chevronColor="text-brandGreen"
          />
        );
      }
      case "reminder": {
        return (
          <PrimaryButton
            saveButton
            saveButtonTitle="Send Reminder"
            onClick={() =>
              handleOpenCommentModal(
                "remind",
                "sendreminder",
                "Send a reminder"
              )
            }
          />
        );
      }
      default: {
        return <div />;
      }
    }
  };

  // Check if the user can complete the step

  // Handle submittal actions
  const handleStepActions = useCallback(async () => {
    const id = requestData?.id;
    setDisableSaveButton(true);

    const stepId = step?.id;
    const remark = actionValues.comment;
    if (actionValues.action === "submit") {
      await onSubmit({
        id,
        stepId,
        comment: remark,
      });
      toastMessage("Submission operation was successful");
      setDisableSaveButton(false);
      setCommentModalOpen(false);
      setActionValues({});
    }
    if (actionValues.action === "advance") {
      try {
        await onStepAction({
          id,
          stepId,
          stepStatus: actionValues.status,
          stepAction: actionValues.action,
          comment: remark,
        });
        toastMessage("Advance operation was successful");
      } catch (e) {
        console.error(e.message);
        toastError("Advance operation failed. Please try again.");
      } finally {
        setCommentModalOpen(false);
      }
    }
    if (actionValues.action === "respond") {
      try {
        await onStepAction({
          id,
          stepId,
          stepStatus: actionValues.status,
          stepAction: actionValues.action,
          comment: remark,
        });
        toastMessage("Respond operation was successful.");
      } catch (e) {
        console.error(e.message);
        toastError("Respond operation failed. Please try again.");
      } finally {
        setCommentModalOpen(false);
      }
    }
    if (actionValues.action === "returntosubmitter") {
      try {
        await onStepAction({
          id,
          stepId,
          stepStatus: actionValues.status,
          stepAction: actionValues.action,
          comment: remark,
        });
        toastMessage("Return to submitter operation was successful");
      } catch (e) {
        console.error(e.message);
        toastError("Return to submitter operation failed. Please try again.");
      } finally {
        setCommentModalOpen(false);
      }
    }
    if (actionValues.action === "sendreminder") {
      const userArr = step?.users?.map((usr) => usr?.reference);
      handleSendReminder(actionValues.comment, userArr);
      setCommentModalOpen(false);
      setActionValues({});
    }
  }, [
    requestData?.id,
    step?.id,
    step?.users,
    actionValues.comment,
    actionValues.action,
    actionValues.status,
    onSubmit,
    onStepAction,
    handleSendReminder,
  ]);

  const handleShowStepComments = async (commentArr) => {
    try {
      const comments = await Promise.allSettled(
        commentArr?.map(async (commentRef) => {
          const { data } = await CommentAPI.getByRef(commentRef);
          return data;
        })
      );
      setAllComments(comments?.map((c) => c.value));
      if (!showViewAllComments) setShowViewAllComments(true);
    } catch (error) {
      console.warn("Unable to fetch comments");
    }
  };

  const getMessages = useCallback(() => {
    try {
      const historyData = history?.[requestData?.round] || [];
      const messages = allComments.map((comment) => {
        const commentAction = historyData
          ?.find((hd) => comment?.reference === hd?.context?.comment?.reference)
          .action?.replace("Comment", "");
        return (
          <div
            key={comment?.id}
            className="role-member-view-all-list-item flex flex-col items-start"
          >
            <h3 className="font-semibold text-black text-sm h-auto mb-2">{`${commentAction} with a comment`}</h3>
            <p className="ml-2 font-semibold text-gray-300 text-xs">
              {comment?.content}
            </p>
          </div>
        );
      });
      return messages ?? [];
    } catch (error) {
      console.warn(error.message);
      return [];
    }
  }, [allComments, requestData?.round, history]);

  return (
    <div className="w-full pl-6 border flex items-center rounded-lg h-40 gap-6">
      <div className="relative flex flex-col h-auto w-auto items-start gap-3">
        <div className="flex w-36 flex-col">
          <p className="text-sm font-bold text-gray-600"> Step {index + 1} -</p>
          <p className="text-sm font-bold text-gray-600">{getStepStatus?.()}</p>
        </div>
        {step?.comments?.length >= 1 && (
          <IconButton
            icon={commentIcon}
            onClick={() => handleShowStepComments(step?.comments)}
          />
        )}
        <PopupMessageBox
          wrapperClassName="pl-3 py-2 w-64 h-36"
          messages={getMessages()}
          showViewAllComments={showViewAllComments}
          setShowViewAllComments={setShowViewAllComments}
        />
      </div>
      <div className="min-h-full border-r" />
      <div className="flex gap-6" style={{ width: "24rem" }}>
        <RequestAttachmentAndUpload
          attachmentMap={attachmentMap}
          step={step}
          setAttachmentMap={setAttachmentMap}
          allSteps={allSteps}
          requestData={requestData}
          onPatch={onPatch}
          index={index}
          loading={loading}
          setLoading={setLoading}
          updateAssociatedFiles={updateAssociatedFiles}
          currentUser={currentUser}
          activeStep={activeStep}
          disableUploading={
            !(
              requestData?.status === SUBMITTAL_STATUS_TYPES?.IN_PROGRESS &&
              activeStep &&
              (currentUser?.isAdmin ||
                requestData?.initiated?.user === currentUser?.reference ||
                step?.users?.[0]?.reference === currentUser?.reference)
            )
          }
        />
      </div>
      <div className="min-h-full border-r" />
      <div className="flex self-stretch py-3" style={{ width: "18.75rem" }}>
        <div
          className="flex items-start justify-start flex-wrap self-stretch"
          style={{ width: "20.188rem" }}
        >
          <p className="w-28 text-sm font-semibold text-gray-450">
            {complete ? "Completed " : "Due On"}
          </p>
          <p className=" text-sm font-normal text-gray-450">
            {" "}
            {moment(complete ? step?.endDate : step?.dueDate?.projected).format(
              "MMMM DD, YYYY"
            )}
          </p>
          <div className="border-b w-full my-3" />
          <div className="flex flex-col items-start">
            <p className="w-28 text-sm font-semibold text-gray-450">
              {capitalize(step?.users?.[0]?.type)}
            </p>
            <p className=" text-sm font-normal text-gray-450 mt-2">
              {step?.users?.[0]?.reference && (
                <RoleMembersAvatarList
                  viewAllClassName="text-xs pl-3"
                  members={step?.users?.map(
                    (user) => userDict?.[user?.reference]
                  )}
                />
              )}
            </p>
          </div>
        </div>
      </div>
      <div
        className="w-full min-h-full flex items-center justify-center border-l"
        style={{ alignSelf: "stretch", flex: "1 0 0", background: "#F9F9F9" }}
      >
        {isInProgress &&
          activeStep &&
          canComplete &&
          buttonActions(step?.users?.[0]?.type)}
        {isInProgress &&
          step?.users?.[0]?.reference &&
          activeStep &&
          !canComplete &&
          buttonActions("reminder")}
      </div>
      {commentModalOpen && (
        <Modal
          childContainerClassName="w-192"
          title="Add a Remark"
          isOpen={commentModalOpen}
          onRequestModalClose={() => setCommentModalOpen(false)}
          primaryButtonOnClick={handleStepActions}
          primaryButtonTitle="Save"
          tertiaryButtonTitle="Cancel"
          alert
          disabled={disableSaveButton}
        >
          <Input
            placeholder="Remark"
            autoFocus
            isTextarea
            value={actionValues.comment}
            onChange={(val) =>
              setActionValues((prev) => {
                return { ...prev, comment: val };
              })
            }
          />
        </Modal>
      )}
    </div>
  );
}

RequestDetailViewCard.propTypes = {
  step: PropTypes.shape({
    id: PropTypes.string,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        reference: PropTypes.string,
        type: PropTypes.string,
        data: PropTypes.shape({
          name: PropTypes.string,
          companyName: PropTypes.string,
        }),
      })
    ),
    comments: PropTypes.arrayOf(PropTypes.shape({})),
    dueDate: PropTypes.shape({
      projected: PropTypes.string,
      actual: PropTypes.string,
    }),
    endDate: PropTypes.string,
  }),
  index: PropTypes.number,
  activeStep: PropTypes.bool,
  requestData: PropTypes.shape({
    id: PropTypes.string,
    initiated: PropTypes.shape({ user: PropTypes.string }),
    status: PropTypes.string,
    round: PropTypes.number,
  }),
  onStepAction: PropTypes.func,
  complete: PropTypes.bool,
  handleSendReminder: PropTypes.func,
  attachmentMap: PropTypes.shape({}),
  setAttachmentMap: PropTypes.shape({}),
  allSteps: PropTypes.arrayOf(PropTypes.shape({})),
  onPatch: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  history: PropTypes.arrayOf(PropTypes.shape({})),
  updateAssociatedFiles: PropTypes.func,
  onSubmit: PropTypes.func,
  getStepStatus: PropTypes.func,
};

RequestDetailViewCard.defaultProps = {
  step: undefined,
  index: undefined,
  activeStep: undefined,
  requestData: undefined,
  onStepAction: undefined,
  complete: undefined,
  handleSendReminder: undefined,
  attachmentMap: undefined,
  setAttachmentMap: undefined,
  allSteps: undefined,
  onPatch: undefined,
  loading: undefined,
  setLoading: undefined,
  history: undefined,
  updateAssociatedFiles: {},
  onSubmit: undefined,
  getStepStatus: undefined,
};

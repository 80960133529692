// Framework Tools
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { capitalize } from "lodash";

export default function RequestWorkflowStepCardActionStatusCell({
  activeStep,
  stepAdvanced,
}) {
  const [stepStatus, setStepStatus] = useState("");

  useEffect(() => {
    if (stepAdvanced.advanced && stepAdvanced?.status?.includes("-")) {
      const statusTitleArray = stepAdvanced?.status?.split("-");
      const capitalized = statusTitleArray?.map((item) => capitalize(item));
      const newTitle = capitalized.join(" ");
      setStepStatus(newTitle);
    } else {
      setStepStatus(capitalize(stepAdvanced?.status));
    }
  }, [stepAdvanced]);

  return (
    <div className="flex my-4">
      {activeStep && <p className="text-xs font-semibold">Action Pending</p>}
      {!activeStep && !stepAdvanced.advanced && (
        <p className="text-xs font-semibold">Upcoming</p>
      )}
      {!activeStep && stepAdvanced.advanced && (
        <p className="text-xs font-semibold">{stepStatus}</p>
      )}
    </div>
  );
}

// prop types
RequestWorkflowStepCardActionStatusCell.propTypes = {
  status: PropTypes.string,
  activeStep: PropTypes.shape({}),
  step: PropTypes.shape({}),
  commentMap: PropTypes.shape({}),
  stepAdvanced: PropTypes.shape({
    advanced: PropTypes.bool,
    status: PropTypes.string,
  }),
};

// default props
RequestWorkflowStepCardActionStatusCell.defaultProps = {
  status: "",
  activeStep: undefined,
  step: undefined,
  commentMap: undefined,
  stepAdvanced: undefined,
};

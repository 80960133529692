import { useMutation } from "react-query";
import { TodoAPI } from "@griffingroupglobal/eslib-api";
import { useCallback } from "react";
import { toastError } from "../helpers/Toast";
import queryClient from "../config/reactQuery/queryClient";
import { todosCodeKeys } from "../config/reactQuery/queryKeyFactory";

export default () => {
  const patch = useCallback(async (todos) => {
    const [newTodo, todo] = todos;
    return TodoAPI.patch(todo?.id, newTodo, todo);
  }, []);

  return useMutation({
    mutationFn: patch,
    onMutate: async (todos) => {
      await queryClient.cancelQueries({ queryKey: todosCodeKeys });
      const [newTodo] = todos;
      const prev = queryClient.getQueryData(todosCodeKeys);

      queryClient.setQueryData(todosCodeKeys, (current) => {
        return current.map((item) => (item.id === newTodo.id ? newTodo : item));
      });

      return { prev };
    },
    onSettled: () => {
      queryClient.invalidateQueries(todosCodeKeys);
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(todosCodeKeys, context?.prev);
      toastError(
        `There was an error performing this action. \n Message: ${error.message} \n Please try again`
      );
      console.error("useTodoPatch", error);
    },
  });
};

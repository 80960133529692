const getUpdatedTaskSopSteps = (
  taskSteps,
  taskStepId,
  sopStepInfo,
  totalSopSteps
) => {
  const updatedSteps = JSON.parse(JSON.stringify(taskSteps));
  updatedSteps.map((step) => {
    if (step?.id === taskStepId) {
      // check if sop step exists
      // if exists then set isCompleted = !isCompleted
      // if not, then add it and set isCompleted=true

      let countSopStepsMarked = 0;
      let stepExists = false;
      // eslint-disable-next-line no-param-reassign
      step.sopStepStatus = step.sopStepStatus.map((sopStep) => {
        if (sopStep.id === sopStepInfo?.id) {
          stepExists = true;

          countSopStepsMarked = !sopStep.isCompleted
            ? countSopStepsMarked + 1
            : countSopStepsMarked;

          return {
            ...sopStep,
            name: sopStepInfo.description,
            isCompleted: !sopStep.isCompleted,
          };
        }

        countSopStepsMarked = sopStep.isCompleted
          ? countSopStepsMarked + 1
          : countSopStepsMarked;
        return sopStep;
      });

      if (!stepExists) {
        step.sopStepStatus.push({
          id: sopStepInfo.id,
          name: sopStepInfo.description,
          isCompleted: true,
        });

        countSopStepsMarked += 1;
      }
      // if all sop steps are done, then mark the full sop as done
      // eslint-disable-next-line no-param-reassign
      step.isCompleted = countSopStepsMarked === totalSopSteps;
    }
    return step;
  });
  return updatedSteps;
};

export default getUpdatedTaskSopSteps;

import { WorkflowAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { useAppState } from "../state/appState";
import { taskKeys, workflowKeys } from "../config/reactQuery/queryKeyFactory";
import { SET_WORKFLOW } from "../constants";
import useEsTasks from "./useEsTasks";

const deleteWorkflows = async (ids) => {
  await Promise.all(
    ids.map(async (id) => {
      await WorkflowAPI.delete(id);
      return id;
    })
  );
};

/**
 * Mutation hook to delete workflows
 * @param {string} mutationKey key to track mutation (optional)
 */
const useDeleteWorkflows = (mutationKey) => {
  const [{ workflowsNew }, dispatch] = useAppState();
  const queryClient = useQueryClient();
  // TODO: (RQ-Performance)
  const { reloadTaskList } = useEsTasks();

  return useMutation({
    mutationKey,
    mutationFn: deleteWorkflows,
    onMutate: async (ids) => {
      const queryKey = workflowKeys.workflows;
      await queryClient.cancelQueries(queryKey);

      const currentWorkflowCache = queryClient.getQueryData(queryKey);

      const result = workflowsNew?.filter((item) => !ids.includes(item.id));

      queryClient.setQueryData((current) => ({
        ...current,
        workflows: result,
      }));

      dispatch({
        type: SET_WORKFLOW,
        workflows: result,
      });

      return { queryKey, currentWorkflowCache };
    },
    // TODO (Uncomment those lines when backend fix issue: https://estatespaceco.slack.com/archives/C01E5VBBGTH/p1694714670500119
    // onError: (error, _, { queryKey, currentWorkflowCache }) => {
    onError: (error) => {
      // Rollback data in case of any error
      // queryClient.setQueryData(queryKey, currentWorkflowCache);

      // toastError("There was an issue removing workflows");
      console.error("useDeleteWorkflow", error);
    },
    onSettled: () => {
      // If success or error, we gonna pull fresh data
      queryClient.invalidateQueries(workflowKeys.workflows);
      queryClient.invalidateQueries(taskKeys.tasks);
      // TODO: (RQ-Performance)
      reloadTaskList();
    },
  });
};

export default useDeleteWorkflows;

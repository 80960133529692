/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { set as _set } from "lodash";
import Table from "../Table/Table";
import CurrencyView from "../CurrencyView/CurrrencyView";
import SymbolPrefix from "../SymbolPrefix/SymbolPrefix";
import BooleanBullet from "../BooleanBullet/BooleanBullet";
import FinancialsTableInLineForm from "./ActualFinancialsTableInLineForm";
import { FILTER_TYPES } from "../../../constants";
import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";

const groupOptions = {
  hierarchicalOptions: [],
  nonHierarchicalOptions: [
    {
      label: "Cat #",
      value: "categoryNumber",
    },
    {
      label: "Category",
      value: "categoryName",
    },
    {
      label: "Sub #",
      value: "subcategoryNumber",
    },
    {
      label: "Subcategory",
      value: "subcategoryName",
    },
    {
      label: "Description",
      value: "description",
    },
    {
      label: "Project",
      value: "projectName",
    },
    {
      label: "Account",
      value: "accountType",
    },
  ],
};

const PureActualFinancialsTable = ({
  name,
  data,
  setData,
  onDeleteRowClick,
  onAddSaveCallback,
  onEditSaveCallback,
  onStartRowEditing,
  onEditRowCancelClick,
  onEndRowEditing,
  onSaveRowClick,
}) => {
  const actualsColumns = useMemo(() => {
    const onValueChange = (newValue, indexToEdit, accessor) => {
      setData((prevData) =>
        prevData.map((item, index) => {
          if (index === indexToEdit) {
            return { ..._set(item, accessor, newValue) };
          }
          return item;
        })
      );
    };

    const getEditInput = ({ value, row: { index }, column: { id } }) => {
      const onKeyPress = (event) => {
        if (event.key === "Enter") {
          // onSaveEdit();
          console.error("need to submit!)");
        }
      };

      return (
        <Input
          placeholder="Value"
          value={value || ""}
          onChange={(newValue) => onValueChange(newValue, index, id)}
          onKeyPress={onKeyPress}
        />
      );
    };

    const getEditBoolean = ({ value }) => {
      return <Checkbox checked={value} />;
    };

    return [
      {
        Header: "[flag icon]",
        accessor: "flag",
        isPrimary: true,
        disableSortBy: true,
        Edit: ({ value }) => value ?? "",
        filterOptions: {
          filterType: FILTER_TYPES.boolean,
        },
      },
      {
        Header: "Cat #",
        accessor: "categoryNumber",
        // TODO: docs say this should be a dropdown,
        // but need Chart of Accounts data for options
        Edit: (props) => getEditInput(props),
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Category",
        accessor: "categoryName",
        // TODO: docs say this should be a dropdown, see above
        Edit: (props) => getEditInput(props),
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Sub #",
        accessor: "subcategoryNumber",
        // TODO: docs say this should be a dropdown, see above
        Edit: (props) => getEditInput(props),
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Subcategory",
        accessor: "subcategoryName",
        // TODO: docs say this should be a dropdown, see above
        Edit: (props) => getEditInput(props),
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Description",
        accessor: "description",
        Edit: (props) => getEditInput(props),
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Project",
        accessor: "projectName",
        Edit: ({ value }) => value ?? "",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Account",
        accessor: "accountType",
        Edit: ({ value }) => value ?? "",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Forecast",
        accessor: "forecastAmount",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Edit: ({ value }) => <CurrencyView value={value} />,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Actual",
        accessor: "actualAmount",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Edit: ({ value }) => <CurrencyView value={value} />,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Variance $",
        accessor: "varianceAmount",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Edit: ({ value }) => <CurrencyView value={value} />,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Variance %",
        accessor: "variancePercentage",
        Cell: ({ value }) => <SymbolPrefix symbol="%" value={value} />,
        Edit: ({ value }) => <SymbolPrefix symbol="%" value={value} />,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Pymnt Date",
        accessor: "paymentDate",
        Edit: ({ value }) => value ?? "",
        filterOptions: {
          filterType: FILTER_TYPES.date,
        },
      },
      {
        Header: "Pyment $",
        accessor: "paymentAmount",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Edit: ({ value }) => <CurrencyView value={value} />,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Balance",
        accessor: "balance",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Edit: ({ value }) => <CurrencyView value={value} />,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Interest",
        accessor: "interest",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Edit: ({ value }) => <CurrencyView value={value} />,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Recurring",
        accessor: "recurring",
        Cell: ({ value }) => <BooleanBullet isTrue={value} />,
        Edit: (props) => getEditBoolean(props),
        filterOptions: {
          filterType: FILTER_TYPES.boolean,
        },
      },
      {
        Header: "Invc Amount",
        accessor: "invoiceAmount",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Edit: ({ value }) => <CurrencyView value={value} />,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Invc Sent",
        accessor: "invoiceSentDate",
        Edit: ({ value }) => value ?? "",
        filterOptions: {
          filterType: FILTER_TYPES.date,
        },
      },
      {
        Header: "Invc Paid",
        accessor: "invoicePaidDate",
        Edit: ({ value }) => value ?? "",
        filterOptions: {
          filterType: FILTER_TYPES.date,
        },
      },
      {
        Header: "Profit $",
        accessor: "profit",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Edit: ({ value }) => <CurrencyView value={value} />,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Gross Margin",
        accessor: "grossMargin",
        Cell: ({ value }) => <SymbolPrefix symbol="%" value={value} />,
        Edit: (props) => getEditInput(props),
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Depc $",
        accessor: "depreciationAmount",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Edit: ({ value }) => <CurrencyView value={value} />,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Depc %",
        accessor: "depreciationPercent",
        Cell: ({ value }) => <SymbolPrefix symbol="%" value={value} />,
        Edit: (props) => getEditInput(props),
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Depc Val",
        accessor: "depreciationValue",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Edit: ({ value }) => <CurrencyView value={value} />,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Equity $",
        accessor: "equityAmount",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Edit: ({ value }) => <CurrencyView value={value} />,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Equity %",
        accessor: "equityPercent",
        Cell: ({ value }) => <SymbolPrefix symbol="%" value={value} />,
        Edit: ({ value }) => <SymbolPrefix symbol="%" value={value} />,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Escalator",
        accessor: "escalatorPercent",
        Cell: ({ value }) => <SymbolPrefix symbol="%" value={value} />,
        Edit: (props) => getEditInput(props),
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Notes",
        accessor: "notes",
        Edit: (props) => getEditInput(props),
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "[kebab icon]",
        accessor: "menu",
        Edit: ({ value }) => value ?? "",
      },
    ];
  }, [setData]);

  return (
    <Table
      resourceName={name}
      columns={actualsColumns}
      data={data}
      hideChangeView
      enableAdding
      enableEditing
      onStartRowEditing={onStartRowEditing}
      onEditRowCancelClick={onEditRowCancelClick}
      onEndRowEditing={onEndRowEditing}
      onSaveRowClick={({ index }) => onSaveRowClick(index)}
      onDeleteRowClick={({ index }) => onDeleteRowClick(data[index]?.id)}
      enablePopOverEditing
      hideCreateNewButton
      groupOptions={groupOptions}
      inLineForm={(row, isEdit, togglePopOver, setIsPopoverOpen) => (
        <FinancialsTableInLineForm
          row={row}
          togglePopOver={togglePopOver}
          setIsPopoverOpen={setIsPopoverOpen}
          isEdit={isEdit}
          onEditSaveCallback={(financial) => onEditSaveCallback(row, financial)}
          onAddSaveCallback={(financial) => onAddSaveCallback(row, financial)}
        />
      )}
    />
  );
};

PureActualFinancialsTable.propTypes = {
  name: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  onDeleteRowClick: PropTypes.func,
  onAddSaveCallback: PropTypes.func,
  onEditSaveCallback: PropTypes.func,
  setData: PropTypes.func,
  onStartRowEditing: PropTypes.func,
  onEditRowCancelClick: PropTypes.func,
  onEndRowEditing: PropTypes.func,
  onSaveRowClick: PropTypes.func,
};

PureActualFinancialsTable.defaultProps = {
  name: undefined,
  data: [],
  setData: undefined,
  onDeleteRowClick: undefined,
  onAddSaveCallback: undefined,
  onEditSaveCallback: undefined,
  onStartRowEditing: undefined,
  onEditRowCancelClick: undefined,
  onEndRowEditing: undefined,
  onSaveRowClick: undefined,
};

export default PureActualFinancialsTable;

import { useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import usePto from "../../../../hooks/usePto.jsx";
import { useAppState } from "../../../../state/appState";
import { useModalState } from "../../../../state/modalState";

import {
  ADD_OPEN_MODAL,
  CONFIRM_MODAL,
  FEEDBACK_MODAL,
  GIVE_TIMEOFF_MODAL,
  TIMEOFF_REQUEST_MODAL,
} from "../../../../constants";
import useTsUserMap from "../../../../hooks/timesheets/useTsUserMap";
import parseDataForRequestModal from "../../../../helpers/Format/requestModalData";

const useTimeOffTableData = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [{ userDict }] = useAppState();
  const [, modalDispatch] = useModalState();
  const [searchOverRide, setSearchOverRide] = useState("");

  const {
    base: { isFetching, data, isLoading },
    actions: {
      patch: { mutateAsync: patch, isLoading: loadingMutation },
    },
  } = usePto();

  const { approvedTsByUser } = useTsUserMap();

  const handleBulkReview = useCallback(
    (val) => {
      if (val === "$decline") {
        modalDispatch({
          type: ADD_OPEN_MODAL,
          ref: { id: uuidv4() },
          modalData: {
            resource: "Time Off",
            submit: (feedback) =>
              patch({
                op: val,
                batch: selectedRows,
                body: { feedback },
              }),
          },
          modalType: FEEDBACK_MODAL,
        });
        return;
      }

      patch({
        op: val,
        batch: selectedRows,
      });
    },
    [modalDispatch, patch, selectedRows]
  );

  const openRequestModal = (pto, parentId, metadata) => {
    const formattedPto = JSON.parse(JSON.stringify(pto));

    if (formattedPto?.dates?.length === 1) {
      formattedPto.dateRange = moment(formattedPto?.dates[0]?.date).format("L");
    }

    if (formattedPto?.dates?.length > 1) {
      const sortedDates = formattedPto?.dates.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });

      formattedPto.dateRange = `${moment(sortedDates[0].date).format(
        "L"
      )} to ${moment(sortedDates[sortedDates.length - 1].date).format("L")}`;
    }

    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: pto ? `modify-${pto.id}` : uuidv4() },
      modalData: {
        item: formattedPto,
        parentId,
        lastUpdated: metadata?.lastUpdated,
      },
      modalType: TIMEOFF_REQUEST_MODAL,
    });
  };

  const handleTableSelect = useCallback(
    (arr) => {
      const shownRows = arr.filter((info) => !info.hideSelection);
      setSelectedRows(shownRows);
    },
    [setSelectedRows]
  );

  const openCancelRequestModal = async (row) => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      modalType: CONFIRM_MODAL,
      ref: { id: `${row?.original?.id}-confirm-cancel` },
      modalData: {
        item: {
          prompt:
            "Are you sure you want to cancel, this action cannot be undone?",
          confirm: "Yes",
          cancel: "No",
          title: "Cancel Time Off",
          onConfirm: async () => {
            const originalData = data?.timeoffDict[row?.values?.user]?.original;
            const lastUpdated = originalData?.metadata?.lastUpdated;

            const id = originalData?.id;
            const op = "$cancel";
            const body = {
              ...originalData,
              metadata: {
                lastUpdated,
              },
            };
            const originalBody = {
              metadata: {
                lastUpdated,
              },
            };
            const extras = {
              params: { requestId: row?.id },
            };

            patch({ id, op, body, originalBody, extras });
          },
        },
      },
    });
  };
  const openGiveTimeOffModal = useCallback(() => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: uuidv4() },
      modalType: GIVE_TIMEOFF_MODAL,
      title: "Give Time Off",
      modalData: {
        item: {
          id: uuidv4(),
        },
      },
    });
  }, [modalDispatch]);

  const handleRequestClick = (row, dataForModal) => {
    const { metadata, original, id, error } = parseDataForRequestModal(
      row,
      dataForModal
    );

    if (!error) {
      openRequestModal(original, id, metadata);
    }
  };

  return {
    approvedTsByUser,
    isFetching,
    data,
    isLoading,
    loadingMutation,
    userDict,
    selectedRows,
    modalDispatch,
    searchOverRide,
    handleRequestClick,
    handleTableSelect,
    setSearchOverRide,
    openCancelRequestModal,
    openRequestModal,
    patch,
    handleBulkReview,
    openGiveTimeOffModal,
  };
};

export default useTimeOffTableData;

/* Framework & Tools */
import React, { useCallback, useState } from "react";
import { cloneDeep } from "lodash";
import PropTypes from "prop-types";

/* Helpers */
import { useQueryClient } from "react-query";
import { uploadFileWithData } from "../../../helpers/File";

/* Constants */
import { REQUEST_WORKFLOW_ATTACHMENT_EXTENSIONS } from "../../../constants";

/* Components */
import SimpleFileUpLoad from "../AssetForms/SimpleFileUpLoad";
import RequestAttachmentCard from "./RequestAttachmentCard";
import { toastMessage } from "../Toast/Toast";

import { filePaginatedKeys } from "../../../config/reactQuery/queryKeyFactory";

export default function RequestAttachmentAndUpload({
  step,
  setAttachmentMap,
  attachmentMap,
  allSteps,
  requestData,
  onPatch,
  index,
  setLoading,
  updateAssociatedFiles,
  disableUploading,
}) {
  const queryClient = useQueryClient();
  const [filesToUpload, setFilesToUpload] = useState([]);

  const onAddFile = async (doc, data = {}, progressCallback) => {
    const fileResource = await uploadFileWithData(
      doc,
      data,
      progressCallback,
      undefined,
      true,
      undefined,
      true
    );
    return fileResource;
  };

  const onUpload = useCallback(async (files, progressCallback) => {
    const handleProgressCallback = (loaded, total, filename) => {
      progressCallback(loaded, total, filename);
    };

    const result = await Promise.all(
      files.map(async ({ name, docType, isFavorited, original }) => {
        const data = {
          name,
          docType,
          isFavorited,
          contentType: original?.type,
          size: original?.size,
        };
        const resource = await onAddFile(original, data, (loaded, total) =>
          handleProgressCallback(loaded, total, name)
        );

        return resource;
      })
    );
    return result;
  }, []);

  const handleFilesUploaded = useCallback(
    async (files) => {
      const res = await onUpload(files, () => {});

      // update files in overview
      queryClient.invalidateQueries(filePaginatedKeys.allFiles);
      // update associated files
      updateAssociatedFiles(res);
      setFilesToUpload([]);
      return res;
    },
    [updateAssociatedFiles, onUpload, queryClient]
  );

  const handleFilesUpdated = (updatedFiles) => {
    setFilesToUpload(updatedFiles);
  };

  const handleUploadAttachments = useCallback(
    async (files) => {
      const prevRequestData = cloneDeep(requestData);
      const prevAttachments = step?.attachments;
      const formatedFiles = files.map((file) => ({
        ref: file.reference,
        category: file.category,
      }));
      const newAttachments = [...prevAttachments, ...formatedFiles];
      const newStep = { ...step, attachments: newAttachments };
      allSteps?.splice(index, 1, newStep);
      const newRequestData = {
        ...requestData,
        requestWorkflow: [
          {
            ...requestData.requestWorkflow[0],
            id: requestData.requestWorkflow[0].id,
            steps: allSteps,
          },
        ],
      };
      if (onPatch) {
        const res = await onPatch({
          updatedResource: newRequestData,
          originalResource: prevRequestData,
        });
        if (res) {
          toastMessage(
            `Successfully Uploaded ${files?.length > 1 ? "files" : "file"}`
          );
        }

        setLoading(false);
      }
    },
    [allSteps, index, onPatch, requestData, setLoading, step]
  );

  const handleFilesAdded = React.useCallback(
    async (addedFiles) => {
      try {
        const res = await handleFilesUploaded(addedFiles);

        res.forEach((file) => {
          const fileData = file;
          const fileRef = file?.reference;
          setAttachmentMap((prev) => {
            const fileMap = { ...prev };
            fileMap[fileRef] = fileData;
            return fileMap;
          });
        });

        await handleUploadAttachments(res);
      } catch (e) {
        console.error(e);
      }
    },
    [handleFilesUploaded, handleUploadAttachments, setAttachmentMap]
  );

  return (
    <>
      {!disableUploading && (
        <SimpleFileUpLoad
          isRequestUpload
          extensions={REQUEST_WORKFLOW_ATTACHMENT_EXTENSIONS}
          files={filesToUpload}
          onFilesAdded={(file) => handleFilesAdded(file)}
          onFilesUpdated={handleFilesUpdated}
          onFilesUploaded={handleFilesUploaded}
          customUploadAreaStyle={{}}
          simple
          shared={step?.attachments?.length > 0}
        />
      )}
      <RequestAttachmentCard
        attachments={step?.attachments}
        attachmentMap={attachmentMap}
      />
    </>
  );
}

RequestAttachmentAndUpload.propTypes = {
  step: PropTypes.shape({
    attachments: PropTypes.arrayOf(PropTypes.string),
    users: PropTypes.arrayOf(
      PropTypes.shape({
        reference: PropTypes.string,
        type: PropTypes.string,
        data: PropTypes.shape({
          name: PropTypes.string,
          companyName: PropTypes.string,
        }),
      })
    ),
  }),
  setAttachmentMap: PropTypes.func,
  attachmentMap: PropTypes.shape({}),
  allSteps: PropTypes.arrayOf(PropTypes.shape({})),
  requestData: PropTypes.shape({
    initiated: PropTypes.shape({ user: PropTypes.string }),
    requestWorkflow: PropTypes.shape({
      id: PropTypes.string,
      steps: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    status: PropTypes.string,
  }),
  onPatch: PropTypes.func,
  index: PropTypes.number,
  setLoading: PropTypes.func,
  // update associated files table
  updateAssociatedFiles: PropTypes.func,
  disableUploading: PropTypes.bool,
};

RequestAttachmentAndUpload.defaultProps = {
  step: undefined,
  setAttachmentMap: undefined,
  attachmentMap: undefined,
  allSteps: undefined,
  requestData: undefined,
  onPatch: undefined,
  index: undefined,
  setLoading: undefined,
  updateAssociatedFiles: () => {},
  disableUploading: false,
};

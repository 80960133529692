import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

const bulletCN = (isTrue, className) => cntl`
  rounded-full
  flex-none
  h-2
  w-2
  ${isTrue && "bg-gray-200"}
  ${className}
`;

const BooleanBullet = ({ isTrue, className }) => {
  return (
    <span
      className={bulletCN(isTrue, className)}
      aria-label={isTrue ? "yes" : "no"}
    />
  );
};

BooleanBullet.propTypes = {
  isTrue: PropTypes.bool,
  className: PropTypes.string,
};

BooleanBullet.defaultProps = {
  isTrue: false,
  className: undefined,
};

export default BooleanBullet;

import React, { useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import * as yup from "yup";
import Input from "../Input/Input";
import TertiaryButton from "../Buttons/TertiaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";

const buttonCN = cntl`
  flex
  justify-end
  ml-6
  pt-6
`;

const teritiaryButtonCN = cntl`
  rounded-md
  capitalize
`;

const primaryButtonCN = cntl`
  rounded-md
  ml-3
`;

const BudgetTableRejectVendorInvoiceForm = ({
  onRejectCancel,
  onRejectConfirm,
}) => {
  const [rejectReason, setRejectReason] = useState();
  return (
    <div className="flex flex-col">
      <Input
        placeholder="Reason"
        onChange={setRejectReason}
        validation={yup.string()}
        value={rejectReason}
        autoFocus
        isTextarea
      />
      <div className={buttonCN}>
        <TertiaryButton
          title="Cancel"
          onClick={() => onRejectCancel()}
          className={teritiaryButtonCN}
        />
        <PrimaryButton
          className={primaryButtonCN}
          title="Reject"
          onClick={() => onRejectConfirm(rejectReason)}
        />
      </div>
    </div>
  );
};

BudgetTableRejectVendorInvoiceForm.propTypes = {
  onRejectCancel: PropTypes.func,
  onRejectConfirm: PropTypes.func,
};

BudgetTableRejectVendorInvoiceForm.defaultProps = {
  onRejectCancel: undefined,
  onRejectConfirm: undefined,
};

export default BudgetTableRejectVendorInvoiceForm;

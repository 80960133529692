import React from "react";
import PropTypes from "prop-types";
import DropdownWithSegmentedControl from "../DropdownWithSegmentedControl";
import useAssociationDropdownWithControlsData from "./useAssociationDropdownWithControlsData";

const AssociationDropdownWithControls = ({
  associations,
  setAssociations,
  associationType,
  menuPlacement,
  isDisabled,
  isMulti,
}) => {
  const {
    associationsOptions,
    associationTypesOptions,
    associationTypeSelected,
    value,
    handleSetValue,
    setAssociationTypeSelected,
  } = useAssociationDropdownWithControlsData({
    associations,
    setAssociations,
    associationType,
    isMulti,
  });

  return (
    <DropdownWithSegmentedControl
      segments={associationTypesOptions}
      options={associationsOptions}
      selectedSegment={associationTypeSelected}
      onSelectSegment={setAssociationTypeSelected}
      value={value}
      setValue={handleSetValue}
      menuPlacement={menuPlacement}
      isMulti={isMulti}
      isDisabled={isDisabled}
    />
  );
};

AssociationDropdownWithControls.defaultProps = {
  isDisabled: false,
  isMulti: false,
  associations: undefined,
  associationType: undefined,
  menuPlacement: "auto",
  setAssociations: PropTypes.func,
};

AssociationDropdownWithControls.propTypes = {
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  /**
   * Single association reference
   * or
   * Array of association references
   */
  associations: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  associationType: PropTypes.oneOf(["property", "project", "asset"]),
  menuPlacement: PropTypes.oneOf(["top", "bottom", "auto"]),
  /**
   * Callback that receive association reference
   * or
   * Array of association references
   */
  setAssociations: PropTypes.func,
};

export default AssociationDropdownWithControls;

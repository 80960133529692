import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import { DocumentAPI } from "@griffingroupglobal/eslib-api";
import { useHistory } from "react-router";

import useDocuments from "../../../hooks/useDocuments";
import useFavorites from "../../../hooks/useFavorites";
import useCurrentUser from "../../../hooks/useCurrentUser";

import CustomDocumentReview from "./CustomDocumentReview";
import PurchaseAuthorizationReview from "./PurchaseAuthorizationReview";
import RequestForInformationReview from "./RequestForInformationReview";
import RequestForProposalReview from "./RequestForProposalReview";
import ContingencyAuthorizationReview from "./ContingencyAuthorizationReview";
import PurchaseOrderReview from "./PurchaseOrderReview";
import ChangeOrderReview from "./ChangeOrderReview";
import DocumentTable from "../DocumentTable/DocumentTable";
import DocumentUploadForm from "../DocumentUploadForm/DocumentUploadForm";
import AttachItemModal from "../AttachItemModal/AttachItemModal";
import {
  contingencyColumns,
  budgetAdjustmentsColumns,
} from "../DocumentForm/DocumentBudgetColumns";
import { formatWithCommasWithoutDecimal } from "../../../helpers/Formatters";

// eslint-disable-next-line import/no-cycle
import BudgetTable from "../BudgetTable/BudgetTable";
import Spinner from "../Spinner/Spinner";
import Table from "../Table/Table";
import Summary from "../Summary/Summary";
import Modal from "../Modal/Modal";
import ModalConfirmAll from "../Modal/ModalConfirmAll";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";

import {
  RESET,
  ADD_DOCUMENT_ATTACHMENTS,
  REMOVE_DOCUMENT_ATTACHMENT,
  PURCHASE_AUTHORIZATION,
  GET_PROJECT_DOCUMENTS_PATH,
  DOCUMENTS_PATH,
  ADD_LINE_ITEMS,
  REMOVE_LINE_ITEMS,
  REQUEST_FOR_INFORMATION,
  REQUEST_FOR_PROPOSAL,
  CHANGE_ORDER,
  CONTINGENCY_AUTHORIZATION,
  CONTINGENGY_CSI_CODE,
  CONTINGENGY_LINE_ITEM_DESCRIPTION,
  PURCHASE_ORDER,
  REMOVE_BUDGET_LINE,
  REMOVE_FORM_DATA,
  docLineItemAdjustmentKeys,
  GET_PROPERTY_DOCUMENT_PATH,
  GET_PROJECT_DOCUMENTS_DOC_ADDITIONAL_DOCS_PATH,
  UPDATE_BUDGET_STATE,
  VENDOR_INVOICE,
  BUDGET_FIXED_FIRM,
  GET_PROJECTS_PATH,
} from "../../../constants";
import fileIcon from "../../assets/images/fileIcon.svg";
import ScheduleOfValues from "../ScheduleOfValues/ScheduleOfValues";
import { getDocumentUser } from "../../../helpers/Document";

const deleteButtonStyle = {
  marginTop: "22px",
};

const MINI_MAP_SCROLL_CONTAINER_ID = "mini-map-document-budget-table";

const DocumentReviewForm = ({
  document,
  existingDoc,
  dispatch,
  budget,
  userList,
  property,
  project,
  associatedResource,
  onSelectAssociatedResource: setAssociatedResource,
  docTypeOptionsMap,
  selectedBudgetRows,
  onBudgetRowSelect,
  disableAssociation,
  disableEdit,
  createMode,
  viewMode,
  editMode,
  originalLineItems,
  csiCodes,
  onFormValidate,
  onClose,
  isFlowFromBudgetTable,
  hasAdjustmentError,
}) => {
  const history = useHistory();
  const { data: currentUser } = useCurrentUser();
  const [favorites, { postFavorite, deleteFavorite }] = useFavorites();
  const {
    documents: allDocuments,
    addDocument,
    addDocuments,
    removeDocument,
  } = useDocuments();

  const [isLoading, setIsLoading] = useState();
  const [userData, setUserData] = useState([]);
  const [originalDocument, setOriginalDocument] = useState(document);
  const [isSaving, setIsSaving] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const [selectedRowsReview, setSelectedRowsReview] =
    useState(selectedBudgetRows);
  const [selectedRowsToAdd, setSelectedRowsToAdd] = useState([]);
  const [showRowsModal, setShowRowsModal] = useState(false);

  const [addedFiles, setAddedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [showDeleteDocModal, setShowDeleteDocModal] = useState(false);
  const [isDeletingDoc, setIsDeletingDoc] = useState(false);
  const [createdDocId, setCreatedDocId] = useState();
  const [deleteDoc, setDeleteDoc] = useState();
  const [contingencyLineItem, setContingencyLineItem] = useState();
  const [currentAttachment, setCurrentAttachment] = useState({});
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [prevRowCount, setPrevRowCount] = useState(0);
  const [adjustmentKey, setAdjustmentKey] = useState(
    docLineItemAdjustmentKeys[document?.docType]
  );
  const [handledRequirements, setHandledRequirements] = useState(false);

  useEffect(() => {
    if (existingDoc && existingDoc.id !== document?.id) {
      dispatch({
        type: RESET,
        value: existingDoc,
      });
    }
  }, [document, dispatch, existingDoc]);

  useEffect(() => {
    const validLineItems =
      !!document.workingLineItems?.length ||
      document.docType === REQUEST_FOR_INFORMATION;
    if (!(document.project || document.property)) {
      setHandledRequirements(false);
    } else if (!(document.dueDate?.date && document.customName)) {
      setHandledRequirements(validLineItems && false);
    } else if (document.docType === REQUEST_FOR_PROPOSAL) {
      setHandledRequirements(validLineItems && !!document.bidType);
    } else if (
      document.docType === CHANGE_ORDER ||
      document.docType === PURCHASE_ORDER ||
      document.docType === PURCHASE_AUTHORIZATION
    ) {
      setHandledRequirements(validLineItems && !!document.scheduleImpacts);
    } else if (document.docType === CONTINGENCY_AUTHORIZATION) {
      setHandledRequirements(validLineItems && !!document.scheduleImpacts);
    } else {
      setHandledRequirements(validLineItems && true);
    }
  }, [
    document.project,
    document.property,
    document.docType,
    document.dueDate,
    document.bidType,
    document.customName,
    document.scheduleImpacts,
    document.workingLineItems,
  ]);

  useEffect(() => {
    setAdjustmentKey(docLineItemAdjustmentKeys[document?.docType]);
  }, [document?.docType]);

  useEffect(() => {
    if (selectedBudgetRows?.length) {
      setSelectedRowsReview(selectedBudgetRows);
    } else if (document?.state?.checkedBudgetRows?.length) {
      setSelectedRowsReview(document.state.checkedBudgetRows);
    } else if (
      document?.state?.lineItemData &&
      Object.keys(document.state.lineItemData)?.length
    ) {
      setSelectedRowsReview(Object.keys(document.state.lineItemData));
    }
  }, [selectedBudgetRows, document]);

  useEffect(() => {
    if (!originalDocument?.id) {
      setOriginalDocument(document);
    }
  }, [originalDocument, document]);

  useEffect(() => {
    const adjustmentSum =
      Object.entries(document?.state?.lineItemData ?? {})
        .filter(([key]) => {
          return selectedRowsReview.includes(key);
        })
        .reduce((sum, [, val]) => {
          return sum + val[adjustmentKey] || 0;
        }, 0) ?? {};

    setContingencyLineItem((prev) => ({
      ...(prev ?? {}),
      adjustmentAmount: adjustmentSum,
    }));
  }, [document?.state?.lineItemData, selectedRowsReview, adjustmentKey]);

  useEffect(() => {
    const contingencyLine = originalLineItems
      .filter((item) => {
        return CONTINGENGY_CSI_CODE.includes(item.csiCode);
      })
      .reduce(
        (obj, item) => {
          const val = {
            uncommittedValue:
              obj.uncommittedValue + (Number(item.uncommittedValue) || 0),
            adjustmentAmount:
              obj.adjustmentAmount + (Number(item.adjustmentAmount) || 0),
            uncommittedValuePending:
              obj.uncommittedValuePending +
              (Number(item.uncommittedValuePending) || 0),
            updatedGmp: obj.updatedGmp + (Number(item.updatedGmp) || 0),
            updatedGmpPending:
              obj.updatedGmpPending + (Number(item.updatedGmpPending) || 0),
            vendor: obj.vendor || item.vendor,
          };
          return val;
        },
        {
          uncommittedValue: 0,
          adjustmentAmount: 0,
          uncommittedValuePending: 0,
          updatedGmp: 0,
          updatedGmpPending: 0,
          vendor: "",
        }
      );

    const adjustmentSum =
      Object.entries(document?.state?.lineItemData ?? {})
        .filter(([key]) => {
          return selectedRowsReview.includes(key);
        })
        .reduce((sum, [, val]) => {
          return sum + val[adjustmentKey] || 0;
        }, 0) ?? {};

    contingencyLine.adjustmentAmount = adjustmentSum;
    contingencyLine.csiCode =
      csiCodes.find((code) => CONTINGENGY_CSI_CODE.includes(code.value))
        ?.label ?? CONTINGENGY_CSI_CODE;
    contingencyLine.description = CONTINGENGY_LINE_ITEM_DESCRIPTION;
    contingencyLine.vendorName = userData.find(
      (user) => user.value === contingencyLine.vendor
    )?.label;
    setContingencyLineItem((prev) => {
      return { ...prev, ...contingencyLine };
    });
  }, [
    document?.state?.lineItemData,
    originalLineItems,
    selectedRowsReview,
    csiCodes,
    adjustmentKey,
    userData,
  ]);

  useEffect(() => {
    if (userList?.length) {
      setUserData(userList);
    }
  }, [userList]);

  const handleFavoriteClick = React.useCallback(
    (id) => {
      const foundItem = favorites.find((favorite) => {
        return favorite.item.reference.includes(id);
      });
      const foundDoc = allDocuments.find((doc) => doc.id === id);

      if (foundItem) {
        deleteFavorite([foundItem]);
      } else {
        postFavorite(id, "Document");
      }

      if (foundDoc) {
        delete foundDoc.isFavorited;
        dispatch({
          type: ADD_DOCUMENT_ATTACHMENTS,
          value: addedFiles.map((item) => {
            if (item.id === foundDoc.id) {
              return foundDoc;
            }
            return item;
          }),
        });
        addDocument(foundDoc);
      }
    },
    [
      allDocuments,
      favorites,
      addedFiles,
      dispatch,
      deleteFavorite,
      postFavorite,
      addDocument,
    ]
  );

  const args = {
    document,
    dispatch,
    userData,
    associatedResource,
    setAssociatedResource,
    disableAssociation,
    disableEdit: disableEdit || viewMode,
    createMode,
    viewMode,
    editMode,
  };

  const docTypes = {
    purchaseAuthorization: <PurchaseAuthorizationReview {...args} />,
    purchaseOrder: <PurchaseOrderReview {...args} />,
    changeOrder: <ChangeOrderReview {...args} />,
    requestForProposal: <RequestForProposalReview {...args} />,
    requestForInformation: <RequestForInformationReview {...args} />,
    contingencyAuthorization: <ContingencyAuthorizationReview {...args} />,
    submittal: "submittal",
    invoice: "invoice",
    meetingMinutes: "meetingMinutes",
    dailyLog: "dailyLog",
    drawings: "drawings",
  };

  const handleCancelClick = () => {
    if (onClose) {
      onClose();
    } else {
      setShowConfirm(true);
    }
  };

  const deleteButtonHandler = async () => {
    setShowDeleteModal(true);
  };

  const handleShowDelete = (doc) => {
    if (typeof doc === "string") {
      setDeleteDoc(doc);
    } else {
      const id = doc.original.reference.split("/")[1];
      setDeleteDoc(id);
    }
    setShowDeleteDocModal(true);
  };

  const handleHideDelete = () => {
    setDeleteDoc();
    setShowDeleteDocModal(false);
  };

  const handleDeleteDocument = async () => {
    setIsDeletingDoc(true);
    await DocumentAPI.delete(deleteDoc.id)
      .then(({ data: docData }) => {
        setAddedFiles((prev) => prev.filter((item) => item.id !== docData.id));
        dispatch({
          type: REMOVE_DOCUMENT_ATTACHMENT,
          value: docData,
        });
        removeDocument(docData.id);
      })
      .catch((err) => {
        console.error(err);
      });

    setIsDeletingDoc(false);
    handleHideDelete();
  };

  const handleDiscard = () => {
    if (isFlowFromBudgetTable) {
      return `${GET_PROJECTS_PATH(project.id)}?tab=1`;
    }
    if (project.id) {
      return GET_PROJECT_DOCUMENTS_PATH(project.id);
    }
    if (property.id) {
      return GET_PROPERTY_DOCUMENT_PATH(property.id);
    }
    return DOCUMENTS_PATH;
  };

  const discardButtonHandler = () => {
    if (onClose) {
      onClose();
    } else {
      setShowConfirm(true);
    }
  };

  const finishButtonHandler = (patch) => {
    if (!onFormValidate()) {
      return;
    }

    setIsSaving(true);
    const formVersion = { ...document };
    const documentData = Object.entries(document)
      .filter(([, value]) => !!value)
      .reduce((obj, entry) => {
        const [entryKey, entryValue] = entry;
        let newVal;
        if (
          entryKey === "qualificationsAndExclusions" ||
          entryKey === "submissionReqs" ||
          entryKey === "additionalRequirements" ||
          entryKey === "deviations"
        ) {
          newVal = entryValue
            ?.filter((qual) => qual.checked !== false)
            .map((qual) => qual.text);
        } else if (
          entryKey === "attachments" ||
          entryKey === "workingLineItems"
        ) {
          newVal = entryValue.map((item) => item.reference);
        } else if (entryKey === "distro") {
          newVal = entryValue.map((item) => item.id);
        }
        return { ...obj, [entryKey]: newVal ?? entryValue };
      }, {});

    delete documentData.creator;
    delete documentData.history;
    delete documentData.adjustments;

    if (!(documentData.property || documentData.project)) {
      // Associated this document to a Property or Project
      // property id is set when Create is clicked from within a property. Url Param
      if (property.id) {
        documentData.property = `Property/${property.id}`;
      }

      // project id is set when Create is clicked from within a project. Url Param
      if (project.id) {
        documentData.project = `Project/${project.id}`;
      }
    }
    // associatedResource is set from the Admin Associated Resource dropdown.
    // associatedResource is given precendence over propertyId or project id,
    // because the Admin user specifically chose it.
    if (associatedResource) {
      if (associatedResource?.value?.startsWith("Property")) {
        documentData.property = associatedResource.value;
      } else if (associatedResource?.value?.startsWith("Project")) {
        documentData.project = associatedResource.value;
      } else if (associatedResource.startsWith("Property")) {
        documentData.property = associatedResource;
      } else if (associatedResource.startsWith("Project")) {
        documentData.project = associatedResource;
      }
    }

    documentData.state = {
      ...documentData.state,
      checkedBudgetRows: selectedRowsReview,
      lineItems: [],
    };

    const lineItemData = documentData.state?.lineItemData ?? {};
    const formData = documentData.state?.formData ?? {};
    const sov = documentData.state?.sov ?? {};
    const lineItems = [];

    switch (documentData.docType) {
      case CHANGE_ORDER:
        Object.entries(lineItemData).forEach(([key, val]) => {
          lineItems.push({
            reference: `Lineitem/${key}`,
            coTotalPending: `${val?.coTotalPending ?? 0}`,
          });
        });
        break;
      case CONTINGENCY_AUTHORIZATION:
        Object.entries(lineItemData).forEach(([key, val]) => {
          lineItems.push({
            reference: `Lineitem/${key}`,
            caAdjustment: `${val?.caAdjustment ?? 0}`,
          });
        });
        break;
      case PURCHASE_AUTHORIZATION:
        Object.entries(lineItemData).forEach(([key, val]) => {
          const rowData = formData[PURCHASE_AUTHORIZATION]?.[key] ?? {};
          const result = {
            reference: `Lineitem/${key}`,
            paAmount: `${val?.paAmount ?? 0}`,
            adjustment: {
              ...(val?.paAdjustment
                ? { paAdjustment: `${val.paAdjustment}` }
                : {}),
              ...(rowData.coAssignment
                ? { co: `${rowData.coAssignment}` }
                : {}),
              ...(rowData.caAssignment
                ? { ca: `${rowData.caAssignment}` }
                : {}),
            },
          };
          lineItems.push(result);
        });
        break;
      case PURCHASE_ORDER:
        Object.entries(lineItemData).forEach(([key, val]) => {
          lineItems.push({
            reference: `Lineitem/${key}`,
            poTotalPending: `${val?.poTotalPending ?? 0}`,
          });
        });

        Object.entries(sov).forEach(([key, val]) => {
          const foundIndex = _.findIndex(lineItems, {
            reference: `Lineitem/${key}`,
          });
          if (foundIndex !== -1) {
            lineItems[foundIndex] = {
              ...lineItems[foundIndex],
              sov: val,
            };
          } else {
            lineItems.push({
              reference: `Lineitem/${key}`,
              sov: val,
            });
          }
        });
        break;
      default:
        break;
    }

    documentData.lineItemEdits = lineItems.filter((item) =>
      selectedRowsReview.includes(item.reference?.split("/")[1])
    );
    documentData.workingLineItems = documentData.workingLineItems.filter(
      (item) => selectedRowsReview.includes(item.split("/")[1])
    );
    documentData.lineItems = documentData.workingLineItems;
    delete documentData.workingLineItems;
    if (patch) {
      DocumentAPI.patch(document.id, documentData, originalDocument)
        .then(({ data }) => {
          formVersion.metadata = data.metadata;
          formVersion.modifiedBy = data.modifiedBy;
          formVersion.modifiedDate = data.modifiedDate;
          formVersion.status = data.status;
          formVersion.number = data.number;
          formVersion.version = data.version;

          dispatch({
            type: RESET,
            value: formVersion,
          });

          addDocument(data);

          // add additional Documents (if any) created on patch call
          if (data?.childrenDocuments?.length) {
            data.childrenDocuments.map((doc) => addDocument(doc));
          }
          setIsSaving(false);

          if (onClose) {
            onClose();
          }
        })
        .catch((err) => {
          setIsSaving(false);
          console.error(err);
        });
    } else {
      // TODO: API to return data for additonal created docs
      DocumentAPI.post(documentData)
        .then(({ data }) => {
          formVersion.id = data.id;
          formVersion.status = data.status;
          formVersion.createdBy = data.createdBy;

          dispatch({
            type: RESET,
            value: formVersion,
          });

          addDocument(data);

          // add additional Documents (if any) created on post call
          if (data?.childrenDocuments?.length) {
            data?.childrenDocuments?.map((doc) => addDocument(doc));
          }
          setIsSaving(false);

          const hasAdjustment =
            documentData?.state?.lineItemData &&
            Object.keys(documentData?.state?.lineItemData).some(
              (id) => documentData.state.lineItemData[id]?.paAdjustment
            );

          if (hasAdjustment) {
            setCreatedDocId(data.id);
          } else if (isFlowFromBudgetTable) {
            history.push(`${GET_PROJECTS_PATH(project.id)}?tab=1`);
          } else if (project.id) {
            history.push(GET_PROJECT_DOCUMENTS_PATH(project.id));
          } else if (property.id) {
            history.push(GET_PROPERTY_DOCUMENT_PATH(property.id));
          } else history.push(DOCUMENTS_PATH);
        })
        .catch((err) => {
          setIsSaving(false);
          console.error(err);
        });
    }
  };

  const onConfirmAdditionalDocsModal = () => {
    const docId = createdDocId;
    setCreatedDocId();
    history.push(
      GET_PROJECT_DOCUMENTS_DOC_ADDITIONAL_DOCS_PATH(project.id, docId)
    );
  };

  const onSkipAdditionalDocsModal = () => {
    setCreatedDocId();
    if (isFlowFromBudgetTable) {
      history.push(`${GET_PROJECTS_PATH(project.id)}?tab=1`);
    } else if (project.id) {
      history.push(GET_PROJECT_DOCUMENTS_PATH(project.id));
    } else if (property.id) {
      history.push(GET_PROPERTY_DOCUMENT_PATH(property.id));
    } else history.push(DOCUMENTS_PATH);
  };

  const attachmentButtonClickedHandler = () => {
    window.document.getElementsByClassName("document_upload_form")[0]?.click();
  };

  const previewDocHandler = () => {};

  const getDocType = React.useCallback(
    (documentType) => {
      if (documentType) {
        return docTypeOptionsMap[documentType]?.label ?? documentType;
      }
      return "File";
    },
    [docTypeOptionsMap]
  );

  useEffect(() => {
    const stillUploading = addedFiles.some((item) => item.isUploading);
    if (!stillUploading && isUploading && addedFiles.length) {
      addedFiles.forEach((item) => {
        if (item.isFavorited) {
          handleFavoriteClick(item.id);
        }
      });
      addDocuments(addedFiles);
      dispatch({
        type: ADD_DOCUMENT_ATTACHMENTS,
        value: addedFiles,
      });
      setIsUploading(false);
    }
  }, [addedFiles, isUploading, dispatch, addDocuments, handleFavoriteClick]);

  const handleFileAdded = React.useCallback(
    (fileRef, fileBody, showSpinner) => {
      if (showSpinner) {
        setIsUploading(true);
      }

      const currentFile = {
        isUploading: true,
        reference: fileRef,
        isFavorited: fileBody.isFavorited,
      };
      setAddedFiles((prev) => [currentFile, ...prev]);

      if (fileBody) {
        const tempDocument = {
          customName: fileBody.name,
          docType: fileBody.docType,
          contentReference: fileRef,
          lineItems: [],
          status: "open",
        };

        if (document?.project) {
          tempDocument.project = document?.project;
        } else if (document?.property) {
          tempDocument.property = document?.property;
        }

        DocumentAPI.post(tempDocument)
          .then(({ data: newDocument }) => {
            const resultDocument = {
              ...newDocument,
              creator: currentUser,
              isFavorited: fileBody.isFavorited,
              uploadedFileRef: fileRef,
            };

            setAddedFiles((prev) => {
              return [
                resultDocument,
                ...prev.filter((item) => item.reference !== fileRef),
              ];
            });
            setIsUploading(false);
          })
          .catch((err) => console.error(err));
      }
    },
    [currentUser, document?.project, document?.property]
  );

  const handleRemoveRows = ({ original }) => {
    dispatch({
      type: REMOVE_LINE_ITEMS,
      value: [original],
    });
    dispatch({
      type: REMOVE_BUDGET_LINE,
      key: original.id,
    });

    if (document.docType === PURCHASE_AUTHORIZATION) {
      dispatch({
        type: REMOVE_FORM_DATA,
        key: PURCHASE_AUTHORIZATION,
        value: original.id,
      });
    }
  };

  const handleAddRows = () => {
    dispatch({
      type: ADD_LINE_ITEMS,
      value: selectedRowsToAdd.map((item) => {
        return { ...item, checked: false };
      }),
    });
    onBudgetRowSelect(selectedRowsToAdd.map((item) => item.id));
    setShowRowsModal(false);
  };

  const handleShowRowsModal = () => {
    setShowRowsModal(true);
  };

  const handleCloseRowsModal = () => {
    setShowRowsModal(false);
  };

  const handleShowAttachment = (doc) => {
    setCurrentAttachment({
      id: doc ? doc.reference.split("/")?.[1] : "",
      contentReference: doc.contentReference,
    });
    setShowAttachmentModal(true);
  };

  const handleHideAttachment = () => {
    setCurrentAttachment({});
    setShowAttachmentModal(false);
  };

  const handleLineUpdate = React.useCallback(
    (action) => {
      const { key, value } = action ?? {};
      const itemData = document.state?.lineItemData?.[key] ?? {};
      setContingencyLineItem((prev) => {
        return {
          ...prev,
          adjustmentAmount:
            (prev.adjustmentAmount || 0) +
            (value[adjustmentKey] || 0) -
            (itemData[adjustmentKey]?.amount || 0),
        };
      });

      dispatch(action);
    },
    [document.state?.lineItemData, dispatch, adjustmentKey]
  );

  const handleBudgetRowSelect = React.useCallback(
    (val) => {
      const rows = val.map((item) => item.id);
      if (rows.length > selectedRowsReview?.length) {
        const added = rows.filter((item) => !selectedRowsReview.includes(item));
        const addedAmount = added.reduce((sum, id) => {
          const itemData = document.state?.lineItemData?.[id] ?? {};
          return sum + itemData[adjustmentKey] || 0;
        }, 0);
        if (addedAmount) {
          setContingencyLineItem((prev) => {
            return {
              ...prev,
              adjustmentAmount: (prev?.adjustmentAmount || 0) + addedAmount,
            };
          });
        }
        onBudgetRowSelect(rows);
      } else if (
        rows.length < selectedRowsReview?.length ||
        (rows.length === 0 &&
          prevRowCount === 1 &&
          rows.length < selectedRowsReview?.length)
      ) {
        const removed = selectedRowsReview.filter(
          (item) => !rows.includes(item)
        );
        const subtractedAmount = removed.reduce((sum, id) => {
          const itemData = document.state?.lineItemData?.[id] ?? {};
          return sum + itemData[adjustmentKey] || 0;
        }, 0);
        if (subtractedAmount) {
          setContingencyLineItem((prev) => {
            return {
              ...prev,
              adjustmentAmount:
                (prev?.adjustmentAmount || 0) - subtractedAmount,
            };
          });
        }
        onBudgetRowSelect(rows);
      } else if (!rows.length && prevRowCount !== 0) {
        setContingencyLineItem((prev) => {
          return {
            ...prev,
            adjustmentAmount: 0,
          };
        });
        onBudgetRowSelect(rows);
      }

      setPrevRowCount(rows.length);
    },
    [
      document,
      selectedRowsReview,
      onBudgetRowSelect,
      adjustmentKey,
      prevRowCount,
    ]
  );

  const tableEditMode = React.useMemo(() => {
    return (
      ![PURCHASE_ORDER].includes(document.docType) && !viewMode && !disableEdit
    );
  }, [document.docType, viewMode, disableEdit]);

  const adjustmentsColumns = React.useMemo(
    () =>
      budgetAdjustmentsColumns(
        document,
        budget,
        handleLineUpdate,
        selectedRowsReview,
        tableEditMode,
        ["assigned", "coAssignment", "caAssignment"],
        contingencyLineItem,
        null,
        project
      ),
    [
      document,
      budget,
      selectedRowsReview,
      handleLineUpdate,
      tableEditMode,
      contingencyLineItem,
      project,
    ]
  );

  const contingencyItemColumns = React.useMemo(
    () => contingencyColumns(document, budget),
    [document, budget]
  );

  const docBudgetLines = React.useMemo(
    () =>
      document.workingLineItems?.map((item) => {
        const lineData = document.state?.lineItemData?.[item.id];
        const formData =
          document.state?.formData?.[PURCHASE_AUTHORIZATION]?.[item.id];
        const sovData = document.state?.sov?.[item.id];
        return {
          ...item,
          csiCode:
            csiCodes.find((code) => code.value === item.csiCode?.split(" -")[0])
              ?.label ?? "",
          ...lineData,
          formData,
          sov: sovData,
        };
      }) ?? [],
    [document.state, document.workingLineItems, csiCodes]
  );

  const feeType = React.useMemo(() => {
    return project?.contractualFee?.feeType ?? "";
  }, [project?.contractualFee]);

  const feeAmount = React.useMemo(() => {
    return project?.contractualFee?.amount ?? 0;
  }, [project?.contractualFee]);

  const feeString = React.useMemo(() => {
    if (!feeType) {
      return "";
    }
    return feeType === "$" ? `$${feeAmount}` : `${feeAmount}%`;
  }, [feeType, feeAmount]);

  const insuranceType = React.useMemo(() => {
    return project?.contractualInsurance?.insuranceType ?? "";
  }, [project?.contractualInsurance]);

  const insuranceAmount = React.useMemo(() => {
    return project?.contractualInsurance?.amount ?? 0;
  }, [project?.contractualInsurance]);

  const insuranceString = React.useMemo(() => {
    if (!insuranceType) {
      return "";
    }
    return insuranceType === "$"
      ? `$${insuranceAmount}`
      : `${insuranceAmount}%`;
  }, [insuranceType, insuranceAmount]);

  const getSummaryValues = React.useCallback(
    (docType) => {
      let totalValue;
      let summaryArray = [];
      const checkedRows = docBudgetLines.filter((item) =>
        selectedRowsReview?.includes(item.id)
      );

      switch (docType) {
        case CHANGE_ORDER:
          totalValue = checkedRows?.reduce((sum, row) => {
            return sum + Number(row.coTotalPending || 0);
          }, 0);
          summaryArray = checkedRows
            ?.map((line) => {
              const csiLbl =
                csiCodes.find((code) => {
                  const lcode = line.csiCode?.split(" -")?.[0];
                  return code.value === lcode;
                })?.label ?? "";
              return { ...line, csiLbl };
            })
            .map((line) => {
              return {
                key: `${line.csiLbl}${
                  line.vendorName ? ` - ${line.vendorName}` : ""
                }`,
                value: formatWithCommasWithoutDecimal(line.coTotalPending || 0),
                unit: "$",
              };
            });
          break;
        case CONTINGENCY_AUTHORIZATION:
          totalValue = checkedRows?.reduce((sum, row) => {
            return sum + Number(row.caAdjustment || 0);
          }, 0);
          summaryArray = checkedRows
            ?.map((line) => {
              const csiLbl =
                csiCodes.find((code) => {
                  const lcode = line.csiCode?.split(" -")?.[0];
                  return code.value === lcode;
                })?.label ?? "";
              return { ...line, csiLbl };
            })
            .map((line) => {
              return {
                id: line.id,
                key: `${line.csiLbl}${
                  line.vendorName ? ` - ${line.vendorName}` : ""
                }`,
                value: formatWithCommasWithoutDecimal(line.caAdjustment || 0),
                unit: "$",
              };
            });
          summaryArray.push({
            key: "CA TOTAL",
            value: formatWithCommasWithoutDecimal(totalValue),
            isFooter: true,
            unit: "$",
          });
          break;
        case PURCHASE_ORDER:
          totalValue = checkedRows?.reduce((sum, row) => {
            if (
              budget?.budgetType === BUDGET_FIXED_FIRM &&
              budget?.isLockFixedFirm
            ) {
              return sum + Number(row.poTotalPending || 0);
            }
            return (
              sum +
              Number(row.paTotal || 0) +
              Number(row.caTotal || 0) +
              Number(row.coTotal || 0)
            );
          }, 0);
          summaryArray = checkedRows
            ?.map((line) => {
              const csiLbl =
                csiCodes.find((code) => {
                  const lcode = line.csiCode?.split(" -")?.[0];
                  return code.value === lcode;
                })?.label ?? "";
              return { ...line, csiLbl };
            })
            .map((line) => {
              const sum =
                budget?.budgetType === BUDGET_FIXED_FIRM &&
                budget?.isLockFixedFirm
                  ? Number(line.poTotalPending || 0)
                  : Number(line.paTotal || 0) +
                    Number(line.caTotal || 0) +
                    Number(line.coTotal || 0);
              return {
                key: `${line.csiLbl}${
                  line.vendorName ? ` - ${line.vendorName}` : ""
                }`,
                value: formatWithCommasWithoutDecimal(sum),
                unit: "$",
              };
            });
          summaryArray.push({
            key: "PO TOTAL",
            value: formatWithCommasWithoutDecimal(totalValue),
            isFooter: true,
            unit: "$",
          });
          break;
        case PURCHASE_AUTHORIZATION:
          totalValue = checkedRows?.reduce((sum, row) => {
            return sum + Number(row.paAmount || 0);
          }, 0);
          summaryArray = checkedRows
            ?.map((line) => {
              const csiLbl =
                csiCodes.find((code) => {
                  const lcode = line.csiCode?.split(" -")?.[0];
                  return code.value === lcode;
                })?.label ?? "";
              return { ...line, csiLbl };
            })
            .map((line) => {
              return {
                key: `${line.csiLbl}${
                  line.vendorName ? ` - ${line.vendorName}` : ""
                }`,
                value: formatWithCommasWithoutDecimal(line.paAmount || 0),
                unit: "$",
              };
            });
          summaryArray.push({
            key: "PA TOTAL",
            value: formatWithCommasWithoutDecimal(totalValue),
            isFooter: true,
            unit: "$",
          });
          break;
        default:
          totalValue = 0;
          summaryArray = [];
          break;
      }

      const calculatedFee =
        feeType === "%" ? totalValue * (feeAmount / 100) : feeAmount;
      const calculatedInsurance =
        insuranceType === "%"
          ? totalValue * (insuranceAmount / 100)
          : insuranceAmount;

      let summaryValues = [...summaryArray];
      if (
        ![PURCHASE_ORDER, PURCHASE_AUTHORIZATION, VENDOR_INVOICE].includes(
          docType
        )
      ) {
        summaryValues = [
          ...summaryValues,
          ...(docType !== CONTINGENCY_AUTHORIZATION
            ? [
                {
                  key: "Subtotal",
                  value: formatWithCommasWithoutDecimal(totalValue),
                  isFooter: docType === CHANGE_ORDER,
                  unit: "$",
                },
              ]
            : []),
          {
            key: `Fee (${feeString})`,
            value: formatWithCommasWithoutDecimal(calculatedFee),
            unit: feeType,
          },
          {
            key: `Insurance (${insuranceString})`,
            value: formatWithCommasWithoutDecimal(calculatedInsurance),
            unit: insuranceType,
          },
          {
            key: "Total",
            value: formatWithCommasWithoutDecimal(
              totalValue + calculatedFee + calculatedInsurance
            ),
            isFooter: docType === CONTINGENCY_AUTHORIZATION,
            unit: "$",
          },
        ];
      }
      return summaryValues.length < 2 ? [] : summaryValues;
    },
    [
      docBudgetLines,
      feeType,
      feeAmount,
      insuranceType,
      insuranceAmount,
      selectedRowsReview,
      csiCodes,
      budget?.budgetType,
      budget?.isLockFixedFirm,
      feeString,
      insuranceString,
    ]
  );

  const handleSaveSov = (rowId, lines) => {
    const state = document.state ?? {};
    const sov = { ...(state.sov ?? {}) };
    sov[rowId] = lines;

    dispatch({
      type: UPDATE_BUDGET_STATE,
      value: {
        ...document.state,
        sov,
      },
    });
  };

  return (
    <div className="p-8 border">
      <div className="flex flex-col justify-between items-center cursor-pointer">
        <button
          type="button"
          className="flex items-center self-end mb-4 text-brandGreen hidden"
          onClick={previewDocHandler}
        >
          <img className="w-3 mr-2" src={fileIcon} alt="preview form" />
          <div className="text-lg">Preview PDF</div>
        </button>
        <div className="w-full text-2xl">
          {document.customName || document.name}
        </div>
      </div>

      {document?.contentReference ? (
        <CustomDocumentReview {...args} />
      ) : (
        docTypes[document.docType]
      )}

      {document.docType &&
        ![REQUEST_FOR_INFORMATION, REQUEST_FOR_PROPOSAL].includes(
          document.docType
        ) && (
          <Summary
            className="mt-8 mb-12 w-1/2"
            keyClassName="w-3/4"
            title="Summary Of Cost"
            values={getSummaryValues(document.docType)}
          />
        )}

      {document.docType === CONTINGENCY_AUTHORIZATION && (
        <>
          <SecondaryHeader className="mt-6 mb-6">
            Contingency Reference
          </SecondaryHeader>
          <Table
            className=""
            resourceName="budget-items"
            data={contingencyLineItem?.csiCode ? [contingencyLineItem] : []}
            columns={contingencyItemColumns}
            cellStyling="flex items-center h-16"
            cellTextStyling="w-full pb-1"
            deleteButtonStyle={deleteButtonStyle}
            showHeader={false}
            hideCreateNewButton
            hideSiteHeader
            hideChangeView
            hideSaveButton
            disableHover
          />
        </>
      )}

      {document.docType !== REQUEST_FOR_INFORMATION && (
        <>
          <SecondaryHeader className="mt-6">Budget References</SecondaryHeader>
          <Table
            className=""
            resourceName="budget-items"
            data={docBudgetLines}
            columns={adjustmentsColumns}
            cellStyling="flex items-center h-16"
            cellTextStyling="w-full pb-1"
            deleteButtonStyle={deleteButtonStyle}
            initialSelectedRows={selectedRowsReview}
            onSelectedRowChange={handleBudgetRowSelect}
            onDeleteRowClick={handleRemoveRows}
            onEditRowCancelClick={() => {}}
            onEditSaveClick={() => {}}
            inLineForm={({ original }, isEdit, togglePopOver) => (
              <div className="flex items-center mx-4 mb-8">
                <ScheduleOfValues
                  row={original}
                  onSaveSov={handleSaveSov}
                  onTogglePopover={togglePopOver}
                  isEdit={isEdit}
                  remove
                  budget={budget}
                />
              </div>
            )}
            allowEditingOnRowClick={document?.docType === PURCHASE_ORDER}
            enablePopOverEditing
            hideEditRowButton
            enableDeleting={!viewMode}
            noDeleteConfirm
            hideCreateNewButton
            hideSiteHeader
            hideChangeView
            hideSaveButton
            disableHover
            showFooter
          />
          {(createMode || editMode) && !disableEdit && (
            <div className="flex">
              <TertiaryButton
                title="+ Add More"
                onClick={handleShowRowsModal}
              />
            </div>
          )}
        </>
      )}

      <SecondaryHeader className="mt-6">Attachments</SecondaryHeader>
      <DocumentTable
        resourceName="document-review-table"
        documents={document.attachments?.map((doc) => {
          const val = {
            ...doc,
            resource: doc.name,
            docType: getDocType(doc.docType),
            createdBy: getDocumentUser(doc.creator),
            modifiedBy: getDocumentUser(doc.modifier),
            modifiedDate: doc.metadata?.lastUpdated
              ? moment(doc.metadata.lastUpdated).format("MM/DD/YYYY")
              : "--",
          };

          delete val.id;
          return val;
        })}
        favorites={favorites}
        onRowClick={handleShowAttachment}
        onFileDelete={handleShowDelete}
        onFavoriteClick={handleFavoriteClick}
        allowSelection={false}
        enableDeleting={!viewMode}
        noDeleteConfirm
        hideSiteHeader
        hideChangeView
        hideSaveButton
        hideButtons
        disableHover
        docTypeOptionsMap={docTypeOptionsMap}
      />
      {(createMode || editMode) && !disableEdit && (
        <TertiaryButton
          className="mt-4"
          title="+ Add More"
          onClick={attachmentButtonClickedHandler}
        />
      )}

      <div className="relative right-0 bottom-0 flex justify-end mt-20">
        <div
          className="flex w-fit py-4 px-4 border-gray-100 rounded-lg bg-white"
          style={{
            borderWidth: "3px",
            bottom: "75px",
            right: "32px",
            zIndex: "20",
          }}
        >
          {createMode && (
            <div className="flex justify-end">
              <TertiaryButton
                className="mr-2"
                title="Discard"
                onClick={discardButtonHandler}
              />
              <PrimaryButton
                title="Finish"
                disabled={!handledRequirements || hasAdjustmentError}
                onClick={() => finishButtonHandler()}
                isLoading={isSaving}
              />
            </div>
          )}

          {editMode && (
            <div className="flex justify-end">
              <TertiaryButton
                className="mr-2"
                title="Cancel"
                onClick={handleCancelClick}
              />
              <SecondaryButton
                className="mr-2"
                title="Delete"
                onClick={deleteButtonHandler}
              />
              <PrimaryButton
                className={editMode ? "" : "hidden"}
                title="Update"
                onClick={() => finishButtonHandler(true)}
                isLoading={isSaving}
              />
            </div>
          )}

          {viewMode && (
            <div className="flex justify-end">
              <TertiaryButton
                className="mr-2"
                title="Cancel"
                onClick={handleCancelClick}
              />
              <SecondaryButton
                className={editMode ? "mr-6" : ""}
                title="Delete"
                onClick={deleteButtonHandler}
              />
            </div>
          )}
        </div>
      </div>

      <AttachItemModal
        content={
          <>
            <div
              className={
                isLoading
                  ? "flex justify-center items-center w-full h-full"
                  : "hidden"
              }
            >
              <Spinner notFullScreen />
            </div>
            <div className={isLoading ? "hidden" : ""}>
              <BudgetTable
                budgetId={budget.id}
                projectId={project.id}
                projectData={project}
                propertyData={property}
                onRowSelect={setSelectedRowsToAdd}
                setIsLoading={setIsLoading}
                documentModalView
                hideSiteHeader
                tableMiniMapContainerId={MINI_MAP_SCROLL_CONTAINER_ID}
                disbaleRowActions
              />
            </div>
          </>
        }
        onRequestModalClose={handleCloseRowsModal}
        onPrimaryClick={handleAddRows}
        isOpen={showRowsModal}
        tableMiniMapContainerId={MINI_MAP_SCROLL_CONTAINER_ID}
      />

      <Modal
        title={
          document
            ? `Delete ${
                document.filename || document.customName || document.name
              }?`
            : "Delete Document?"
        }
        isOpen={showDeleteModal}
        primaryButtonTitle="Yes, delete"
        primaryButtonOnClick={handleDeleteDocument}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={() => setShowDeleteModal(false)}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <>
          <p className="text-base mb-2">
            Are you sure you want to delete{" "}
            {document
              ? document.filename || document.customName || document.name
              : "this document"}
            ? Once deleted, it cannot be recovered.
          </p>
        </>
      </Modal>

      <Modal
        title={
          deleteDoc?.name ? `Delete ${deleteDoc.name}?` : "Delete Document?"
        }
        isOpen={showDeleteDocModal}
        primaryButtonTitle="Yes, delete"
        primaryButtonOnClick={handleDeleteDocument}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={handleHideDelete}
        deleting={isDeletingDoc}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <>
          <p className="text-base mb-2">
            Are you sure you want to delete {deleteDoc?.name ?? "this document"}
            ? Once deleted, it cannot be recovered.
          </p>
        </>
      </Modal>

      <Modal
        title="Complete Additional Documents"
        isOpen={createdDocId}
        primaryButtonTitle="Complete"
        primaryButtonOnClick={onConfirmAdditionalDocsModal}
        tertiaryButtonTitle="Skip"
        onRequestModalClose={onSkipAdditionalDocsModal}
        hideFooter
      >
        <>
          <p className="text-base mb-2">
            Would you you like to complete the additional documents?
          </p>
        </>
      </Modal>

      <ModalConfirmAll
        navigateToPath={handleDiscard()}
        showConfirmModal={showConfirm}
        setShowConfirmModal={setShowConfirm}
        modalAction={createMode ? "Discard Attachments" : "Cancel"}
      />

      <AttachItemModal
        content={<CustomDocumentReview document={currentAttachment} />}
        contentReference={currentAttachment.contentReference}
        onRequestModalClose={handleHideAttachment}
        isOpen={showAttachmentModal}
      />

      <div className="hidden">
        <DocumentUploadForm
          onAddFile={(fileRef, file) => handleFileAdded(fileRef, file, true)}
          onAddPhoto={(fileRef, file) => handleFileAdded(fileRef, file, true)}
          onAddVideo={(fileRef, file) => handleFileAdded(fileRef, file, true)}
          showCorner
        />
      </div>
    </div>
  );
};

DocumentReviewForm.propTypes = {
  /**
   * create document form state
   */
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.object,
  /**
   * existing document data
   */
  // eslint-disable-next-line react/forbid-prop-types
  existingDoc: PropTypes.object,
  /**
   * create document form dispatcher
   */
  dispatch: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  budget: PropTypes.object,
  /**
   * list of users
   */
  // eslint-disable-next-line react/forbid-prop-types
  userList: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  property: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.object,
  // budgetId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  associatedResource: PropTypes.object,
  onSelectAssociatedResource: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  docTypeOptionsMap: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  selectedBudgetRows: PropTypes.array,
  onBudgetRowSelect: PropTypes.func,
  //
  disableAssociation: PropTypes.bool,
  disableEdit: PropTypes.bool,
  editMode: PropTypes.bool,
  createMode: PropTypes.bool,
  viewMode: PropTypes.bool,
  //
  // eslint-disable-next-line react/forbid-prop-types
  originalLineItems: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  csiCodes: PropTypes.array,
  onFormValidate: PropTypes.func,
  /**
   * close functionality when in modal
   */
  onClose: PropTypes.func,
  isFlowFromBudgetTable: PropTypes.bool,
  hasAdjustmentError: PropTypes.bool,
};

DocumentReviewForm.defaultProps = {
  document: {},
  existingDoc: undefined,
  dispatch: undefined,
  budget: {},
  userList: [],
  property: {},
  project: {},
  associatedResource: undefined,
  onSelectAssociatedResource: undefined,
  docTypeOptionsMap: {},
  selectedBudgetRows: undefined,
  onBudgetRowSelect: () => {},
  disableAssociation: false,
  disableEdit: false,
  createMode: false,
  viewMode: false,
  editMode: false,
  csiCodes: [],
  originalLineItems: [],
  onFormValidate: () => {},
  onClose: undefined,
  isFlowFromBudgetTable: false,
  hasAdjustmentError: false,
};

export default DocumentReviewForm;

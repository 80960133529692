import React from "react";
import PropTypes from "prop-types";
import Avatar from "../Message/Avatar";
import ReplyButton from "./ReplyButton";
import TextWithMention from "../TextWithMention";
import useCommentRowData from "./useCommentRowData";

const CommentRow = ({
  comment,
  useReplyMode,
  onReplyPressed,
  selectedCommentId,
}) => {
  const {
    isHovered,
    isSelected,
    commentAuthor,
    commentCreatedAt,
    setIsHovered,
  } = useCommentRowData({
    comment,
    selectedCommentId,
  });

  return (
    <div
      className="flex items-start my-2 p-4"
      style={{ backgroundColor: isHovered || isSelected ? "#F9F9F9" : "#FFF" }}
      onMouseEnter={() => setIsHovered(!useReplyMode)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div>
        <Avatar
          className="w-10 h-10"
          isExternalUser
          id={commentAuthor?.reference}
          avatar={commentAuthor?.avatar}
        />
      </div>
      <div className="ml-2 w-full">
        <div className="flex justify-between items-center">
          <div className="w-2/5">
            <p className="font-semibold text-sm">{commentAuthor.name}</p>
          </div>
          <div className="w-1/2">
            <p className="text-xxs">{commentCreatedAt}</p>
          </div>

          <div className="w-1/3 text-right">
            <div
              className={`${
                !useReplyMode && (isHovered || isSelected) ? "" : "opacity-0"
              }`}
            >
              <ReplyButton onClick={() => onReplyPressed?.(comment.id)} />
            </div>
          </div>
        </div>

        {/* Message */}
        <div className="py-1">
          <TextWithMention comment={comment} />
        </div>

        {/* # of Replies */}
        {!useReplyMode && !!comment?.replies?.length && (
          <button
            type="button"
            className="text-xxs text-green-700"
            onClick={() => onReplyPressed?.(comment.id)}
          >{`${comment.replies.length} replies`}</button>
        )}
      </div>
    </div>
  );
};

CommentRow.defaultProps = {
  comment: {},
  useReplyMode: false,
  onReplyPressed: undefined,
  selectedCommentId: undefined,
};

CommentRow.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string,
    content: PropTypes.string,
    replies: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  useReplyMode: PropTypes.bool,
  onReplyPressed: PropTypes.func,
  selectedCommentId: PropTypes.string,
};

export default CommentRow;

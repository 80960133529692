// Framework Tools
import React from "react";
import PropTypes from "prop-types";

// Libraries
import moment from "moment";

// Components
import TertiaryHeader from "../../TextHeaders/TertiaryHeader";

export default function RequestGridViewInitiateCell({
  initiatedBy,
  initiationDate,
}) {
  return (
    <div className="w-full h-ful flex flex-col justify-start px-2">
      <TertiaryHeader className="text-sm text-left">
        Initiated By:{" "}
        <span className="text-sm text-black">
          {initiatedBy?.name?.firstName} {initiatedBy?.name?.lastName}
        </span>
      </TertiaryHeader>
      <p className="text-sm text-left">
        {moment(initiationDate).format("MMMM DD YYYY")} at{" "}
        {moment(initiationDate).format("hh:mm A")}
      </p>
    </div>
  );
}

// prop types
RequestGridViewInitiateCell.propTypes = {
  initiatedBy: PropTypes.string,
  initiationDate: PropTypes.string,
};

// default props
RequestGridViewInitiateCell.defaultProps = {
  initiatedBy: "",
  initiationDate: "",
};

import React from "react";
import PropTypes from "prop-types";
import PopoverItem from "./PopoverItem";

const PopoverList = ({ items, ...props }) => {
  return (
    <>
      {items.map((item) => {
        if (item?.type === "divider") {
          return <hr key={item?.title} className={item?.className} />;
        }

        return (
          <div
            className={`flex flex-col ${item.wrapperClassName}`}
            key={item?.title}
          >
            <PopoverItem
              title={item?.title}
              className={item?.className}
              hoverColorCN={item.hoverColorCN}
              onClick={item?.onClick}
              dropdownItems={item?.dropdownItems}
              hideDropdownChevron
              {...props}
            />
            {item?.options}
          </div>
        );
      })}
    </>
  );
};

PopoverList.propTypes = {
  /**
   * an array of the PopoverItems with data attributes
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Type of PopoverList item. Values are "button" or "divider". Defaults to "button.
       */
      type: PropTypes.string,

      /**
       * string used for the name of the popoverItem
       */
      title: PropTypes.string,
      /**
       * any CSS classes to apply to the container
       */
      className: PropTypes.string,
      /**
       * onClick function called when the item is clicked for routing
       */
      onClick: PropTypes.func,
    })
  ),
};

PopoverList.defaultProps = {
  items: [],
};

export default PopoverList;

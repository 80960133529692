import { WorkflowAPI } from "@griffingroupglobal/eslib-api";
import { workflowKeys } from "../config/reactQuery/queryKeyFactory";
import { SET_WORKFLOW, SET_WORKFLOW_DD, SET_WORKFLOW_DICT } from "../constants";
import { useAppState } from "../state/appState";
import { toastError } from "../stories/Components/Toast/Toast";
import { useProjectsOverview } from "./projects";
import { usePropertiesOverview } from "./properties";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import useCurrentUser from "./useCurrentUser";

const getWorkflows = async (params) => {
  const workflowDict = {};
  const workflowDD = [];
  const { data } = await WorkflowAPI.get({ params });

  const workflows = data?.entries?.map(({ resource }) => {
    workflowDict[resource?.reference] = {
      ...resource,
    };

    workflowDD.push({
      label: resource?.name,
      value: resource?.reference,
      id: resource?.id,
      isTemplate: resource?.isTemplate,
      isDefaultTemplate: resource?.isDefaultTemplate,
    });

    return resource;
  });

  return { workflows, workflowDD, workflowDict };
};

/**
 * Fetch all workflows or by projects or by properties
 * @association association  reference Ex: { project: Project/1234-5678-0000}
 * @tasks ["tasks/:id"] BE expects Array, current use-case is 1 at a time
 * @returns
 */
const useWorkflow = ({ association, tasks } = {}) => {
  const [{ userDict }, dispatch] = useAppState();

  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  const { data: currentUser } = useCurrentUser();
  let params;

  // Get simple or compund query key based on params
  let queryKey = workflowKeys.workflows;
  if (association) {
    queryKey = workflowKeys.workflowsByAssociation(association);
    params = {
      association,
    };
  }
  if (tasks) {
    queryKey = workflowKeys.workflowByTaskRef(tasks?.[0]);
    params = {
      tasks: tasks?.join(","),
    };
  }
  // Enabled query if there is an user signed in
  // AND If projects and users are loaded
  // ND If properties and users are loaded
  const enabled =
    !!currentUser?.permissions?.workflow?.can_read &&
    !!projectDict &&
    !!propertiesDict &&
    !!userDict;

  return useAuthenticatedQuery({
    queryKey,
    queryFn: () => getWorkflows(params),
    enabled,
    onSuccess: ({ workflowDD, workflows, workflowDict }) => {
      dispatch({
        type: SET_WORKFLOW_DD,
        workflowDD,
      });

      dispatch({
        type: SET_WORKFLOW,
        workflows,
      });
      dispatch({
        type: SET_WORKFLOW_DICT,
        workflowDict,
      });
    },
    onError: (error) => {
      toastError("There was an error while reading workflows");
      console.error("useWorkflows", error);
    },
  });
};

export default useWorkflow;

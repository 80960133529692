import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import cntl from "cntl";
import { v4 as uuidv4 } from "uuid";

import ResourceDropDown from "../WorkflowCreation/ResourceDropDown";
import SelectUserInterface from "../SelectUserInterface/SelectUserInterface";

import DatePicker from "../DatePicker/DatePicker";
import Dropdown from "../Dropdown/Dropdown";
import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";

import editIcon from "../../assets/images/editIcon.svg";
import eventIcon from "../../assets/images/eventIcon.png";
import deleteIcon from "../../assets/images/collapseIcon.svg";
import { TimeOptions } from "../../../constants";

const eventIconElement = (
  <img className="w-4 h-4" src={eventIcon} alt="event icon" />
);

const getContainerClasses = (className) => cntl`
  flex
  justify-between
  items-center
  ${className}
`;

const getEditButtonCN = (hovering) => cntl`
  ${hovering ? "" : "invisible"}
  flex
  justify-center
  items-center
  w-8
  h-8
  ml-8
  border
  border-brandGreen
  rounded-md
`;

const getButtonGroupCN = (type) => cntl`
  flex
  ${type === "ASSOCIATION" ? "mt-0" : "mt-auto"}
`;

const getEditFieldCN = (type) => cntl`
  w-full
  ${type === "CRITERIA" ? "mr-4" : "mr-4"}
`;

const deleteButtonCN = cntl`
  flex
  justify-center
  items-center
  ml-8
`;

const labelCNAlt = cntl`
  text-xs
  font-semibold
  text-gray-300
`;

const DocumentReviewField = ({
  className,
  type,
  label,
  labelAlt,
  displayValue,
  editValue,
  editField,
  options,
  optionsAlt,
  selectedOptions,
  onChange,
  onChangeAlt,
  onCancel,
  onSave,
  onDelete,
  onBlur,
  disableAssociation,
  disableEdit,
}) => {
  const [originalValue, setOriginalValue] = useState();
  const [inputValue, setInputValue] = useState();
  const [inputTemp, setInputTemp] = useState();

  const [editing, setEditing] = useState(false);
  const [hovering, setHovering] = useState();
  const [deleteHovering, setDeleteHovering] = useState();

  const [durationDays, setDurationDays] = useState();
  const [durationHours, setDurationHours] = useState();

  useEffect(() => {
    if (editValue || selectedOptions?.length) {
      let val;
      switch (type) {
        case "ASSOCIATION":
          val = { value: editValue };
          break;
        case "USERSELECT":
          val = options?.filter((opt) =>
            selectedOptions.find((item) => opt.value === item.id)
          );
          break;
        default:
          val = editValue;
          break;
      }

      setInputValue(val);
      setOriginalValue(val);
    }
  }, [type, editValue, options, selectedOptions]);

  useEffect(() => {
    if (type === "DUEDATE") {
      if (inputValue?.date) {
        const today = moment();
        const dueDate = moment(inputValue.date);
        const diffDays = dueDate.diff(today, "days");
        const diffHours = dueDate.diff(today, "hours");

        if (diffDays > 0) {
          setDurationDays(diffDays);
          setDurationHours();
        } else if (diffHours > 0) {
          setDurationDays();
          setDurationHours(diffHours);
        } else {
          setDurationDays();
          setDurationHours();
        }
      }
    }
  }, [inputValue, type]);

  const handleHover = (val) => {
    if (!disableEdit && !disableAssociation) {
      setHovering(val);
    }
  };

  const handleDeleteHover = (val) => {
    if (!disableEdit && !disableAssociation) {
      setDeleteHovering(val);
    }
  };

  const handleCriteriaKeyPress = (event) => {
    if (event.key === "Enter" && inputTemp) {
      const entry = { id: uuidv4(), text: inputTemp };
      onChange(entry);
      setInputTemp();
      setHovering();
      setDeleteHovering();
      return entry;
    }
    return undefined;
  };

  const handleSave = () => {
    let returnValue;
    if (type === "CRITERIA") {
      if (inputTemp) {
        returnValue = handleCriteriaKeyPress({ key: "Enter" });
      }
    } else if (inputValue) {
      if (onChangeAlt) {
        onChangeAlt(inputValue);
      } else {
        onChange(inputValue);
      }
    }

    onSave(returnValue);
    setEditing(false);
  };

  const handleCancel = () => {
    setInputValue(originalValue);
    setEditing(false);
    setInputTemp();
    onCancel();
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    } else {
      handleSave();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleBlur();
    }
  };

  const handleAssociation = (value, typ, name) => {
    setInputValue({ value, type: typ, name });
  };

  const handleDueDate = (key, value) => {
    setInputValue((prev) => {
      const tmp = { ...prev, [key]: value };
      return tmp;
    });
  };

  const handleTimeInput = (val) => {
    if (!val) return;
    const hour = Number(val.split(":")[0]);
    const minute = Number(val.split(":")[1]);
    const { date } = inputValue ?? {};
    const tmpDate = moment(date).set({ hour, minute }).toISOString();

    handleDueDate("date", tmpDate);
  };

  const handleRemoveItem = (val) => {
    const newVal = inputValue.filter((item) => item.value !== val);
    onDelete(val);
    setInputValue(newVal);
    setOriginalValue(newVal);
  };

  const handleEditClick = () => {
    setHovering(false);
    setEditing(true);
  };

  const getDisplayField = () => {
    switch (type) {
      case "CUSTOM":
        return displayValue;
      case "ASSOCIATION":
        return (
          <ResourceDropDown
            resource={inputValue?.value}
            setResource={handleAssociation}
            disableAssociation={disableAssociation || disableEdit}
            showTextView
            noLabel
          />
        );
      case "USERSELECT":
        return inputValue?.length ? (
          <SelectUserInterface
            className="w-full"
            userList={inputValue}
            userLabel={label}
            userPlaceholder="Search Members"
            roleLabel={labelAlt}
            rolePlaceholder="Search Roles"
            buttonText="Add Member"
            userOptions={options ?? []}
            roleOptions={optionsAlt}
            onAddUser={() => {}}
            showCurrentRole
            isSingleSelect
            disableCreateUser
            disableRemove
          />
        ) : (
          <></>
        );
      case "DUEDATE":
        return (
          <div className="flex flex-col">
            <div>{displayValue}</div>
            <div className="flex">
              {durationDays && (
                <div className="ESInputLabel mt-1 mr-2">{`${durationDays} ${
                  durationDays > 1 ? "Days" : "Day"
                }`}</div>
              )}
              {durationHours && (
                <div className="ESInputLabel mt-1 mr-2">{`${durationHours} ${
                  durationHours > 1 ? "Hours" : "Hour"
                }`}</div>
              )}
            </div>
          </div>
        );
      default:
        return <div className="flex items-center">{displayValue}</div>;
    }
  };

  const getEditField = () => {
    switch (type) {
      case "CUSTOM":
        return editField;
      case "DROPDOWN":
        return (
          <Dropdown
            label={label}
            value={options?.find((item) => item.value === inputValue)}
            options={options}
            onChange={({ value }) => setInputValue(value)}
            disableClear
          />
        );
      case "TEXTAREA":
        return (
          <Input
            placeholder="Value"
            mainClassName="w-full"
            label={label}
            value={inputValue}
            onChange={setInputValue}
            disableClear
            isTextarea
          />
        );
      case "ASSOCIATION":
        return (
          <ResourceDropDown
            resource={inputValue?.value}
            setResource={handleAssociation}
            disableAssociation={disableAssociation || disableEdit}
            noLabel
          />
        );
      case "DUEDATE":
        return (
          <div className="w-full">
            <div className="flex items-center">
              <DatePicker
                className="flex-1 mr-2"
                label={label}
                placeholder="MM-DD-YYYY"
                value={inputValue?.date}
                iconLeft={eventIconElement}
                onChange={(val) => handleDueDate("date", val)}
              />
              {!inputValue?.allDay && (
                <Dropdown
                  className={`flex-1 mr-2 ${label ? "mt-7" : ""}`}
                  label={labelAlt}
                  options={TimeOptions}
                  value={TimeOptions.find((item) => {
                    if (!inputValue?.date) return false;
                    return (
                      item.value === moment(inputValue.date).format("HH:mm")
                    );
                  })}
                  placeholder="Select"
                  onChange={({ value }) => handleTimeInput(value)}
                  disableClear
                  disableSort
                />
              )}
              <Checkbox
                className={`${label ? "mt-7" : ""}`}
                label="All Day"
                checked={inputValue?.allDay}
                onChange={() => handleDueDate("allDay", !inputValue?.allDay)}
              />
            </div>

            <div className="flex relative h-0">
              {durationDays && (
                <div className="ESInputLabel mt-1 mr-2">{`${durationDays} ${
                  durationDays > 1 ? "Days" : "Day"
                }`}</div>
              )}
              {durationHours && (
                <div className="ESInputLabel mt-1 mr-2">{`${durationHours} ${
                  durationHours > 1 ? "Hours" : "Hour"
                }`}</div>
              )}
            </div>
          </div>
        );
      case "USERSELECT":
        return (
          <SelectUserInterface
            className="w-full"
            userList={inputValue}
            userLabel={label}
            userPlaceholder="Search Members"
            roleLabel={labelAlt}
            rolePlaceholder="Search Roles"
            buttonText="Add Member"
            userOptions={options ?? []}
            roleOptions={optionsAlt}
            onAddUser={setInputValue}
            onRemoveUser={handleRemoveItem}
            showCurrentRole
            disableCreateUser
          />
        );
      case "CRITERIA":
        return (
          <>
            {inputValue?.map((item) => {
              return (
                <div
                  key={item.id}
                  className="flex items-center mb-2"
                  onMouseOver={() => handleDeleteHover(item.id)}
                  onMouseOut={() => handleDeleteHover()}
                  onFocus={() => handleDeleteHover(item.id)}
                  onBlur={() => handleDeleteHover()}
                >
                  {(item.checked || item.checked === false) && (
                    <div className="flex items-center mb-2">
                      <Checkbox
                        label={item.text}
                        checked={item.checked}
                        onChange={(checked) => onChange({ ...item, checked })}
                      />
                    </div>
                  )}

                  {!(item.checked || item.checked === false) && (
                    <div className={labelCNAlt}>{item.text}</div>
                  )}

                  {!(item.checked || item.checked === false) && (
                    <button
                      type="button"
                      className={`${deleteButtonCN} ${
                        deleteHovering !== item.id ? "invisible" : ""
                      }`}
                      style={{ minWidth: "16px", minHeight: "16px" }}
                      onClick={() => onDelete(item.id)}
                    >
                      <img
                        className="w-4 h-4 cursor-pointer"
                        src={deleteIcon}
                        alt="delete criteria"
                      />
                    </button>
                  )}
                </div>
              );
            })}

            <div className="flex items-center mt-5">
              <Input
                className="mr-3"
                mainClassName="flex-1"
                placeholder="Requirement"
                value={inputTemp}
                onChange={setInputTemp}
                onKeyPress={handleCriteriaKeyPress}
              />
              <PrimaryButton
                className=""
                title="Add"
                onClick={() => handleCriteriaKeyPress({ key: "Enter" })}
                disabled={!inputTemp?.length}
              />
            </div>
          </>
        );
      default:
        return (
          <Input
            placeholder="Value"
            mainClassName="w-full"
            label={label}
            value={inputValue}
            onChange={setInputValue}
            onKeyPress={handleKeyPress}
            disableClear
          />
        );
    }
  };

  return (
    <div className={getContainerClasses(className)}>
      {!editing && (
        <div
          className="flex items-center w-full"
          onMouseOver={() => handleHover(true)}
          onMouseOut={() => handleHover(false)}
          onFocus={() => handleHover(true)}
          onBlur={() => handleHover(false)}
        >
          <div>{getDisplayField()}</div>

          <div className="ml-10">
            <button
              className={getEditButtonCN(hovering)}
              type="button"
              onClick={handleEditClick}
            >
              <img
                className="w-8 cursor-pointer"
                src={editIcon}
                alt="edit value"
              />
            </button>
          </div>
        </div>
      )}
      {editing && (
        <div className="flex items-center w-full pr-12">
          <div className={getEditFieldCN(type)}>{getEditField()}</div>
          <div className={getButtonGroupCN(type)}>
            <SecondaryButton
              className="mr-1 max-h-10"
              title="Save"
              onClick={handleSave}
            />
            <TertiaryButton
              className="max-h-10"
              title="Cancel"
              onClick={handleCancel}
            />
          </div>
        </div>
      )}
    </div>
  );
};

DocumentReviewField.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  labelAlt: PropTypes.string,
  displayValue: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  editValue: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  editField: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ),
  optionsAlt: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  selectedOptions: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  onChangeAlt: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onBlur: PropTypes.func,
  disableAssociation: PropTypes.bool,
  disableEdit: PropTypes.bool,
};

DocumentReviewField.defaultProps = {
  className: undefined,
  type: undefined,
  label: undefined,
  labelAlt: undefined,
  displayValue: undefined,
  editValue: undefined,
  editField: undefined,
  options: undefined,
  optionsAlt: undefined,
  selectedOptions: undefined,
  onChange: () => {},
  onChangeAlt: undefined,
  onCancel: () => {},
  onSave: () => {},
  onDelete: () => {},
  onBlur: undefined,
  disableAssociation: false,
  disableEdit: false,
};

export default DocumentReviewField;

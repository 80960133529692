import React from "react";
import { FILTER_TYPES, TABLE_COLUMN_WIDTH } from "../../../constants";
import Table from "../Table/Table";
import IconButton from "../Buttons/IconButton";
import flagGreen from "../../assets/images/flagGreenIcon.svg";
import flagWhite from "../../assets/images/flagWhiteIcon.svg";
import { formatDate } from "../../../helpers/Formatters";

const PureRfiTable = ({ data }) => {
  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: "",
        accessor: "flag",
        isPrimary: true,
        Cell: ({ row }) => (
          <IconButton
            onClick={() => {
              // onFavoriteClick(row.original.id);
            }}
            icon={row.original.isFlagged ? flagGreen : flagWhite}
            disbaledBackgroundColorCN="bg-white"
          />
        ),
        width: TABLE_COLUMN_WIDTH,
        minWidth: 70,
        maxWidth: 70,
      },
      {
        Header: "Title",
        accessor: "name",
      },
      {
        Header: "Association",
        accessor: "association",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Initiated Date",
        accessor: "createdAt",
        Cell: ({ value }) => {
          return <p>{formatDate(value)}</p>;
        },
      },
      {
        Header: "Start Date",
        accessor: "startDate.projected",
        Cell: ({ value }) => {
          return <p>{formatDate(value)}</p>;
        },
      },
      {
        Header: "Private",
        accessor: "private",
        Cell: ({ value }) => {
          return <p>{value ? "Y" : "N"}</p>;
        },
      },
      {
        Header: "Duration",
        accessor: "duration.numDays",
      },
      {
        Header: "Created By",
        accessor: "createdBy",
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: ({ row }) => {
          return (
            <button
              type="button"
              className="w-32 text-brandGreen py-1 px-3 border border-brandGreen rounded hover:bg-brandGreen hover:text-white mr-2"
            >
              {(() => {
                switch (row?.original?.status) {
                  case "draft":
                    return "Initiate";
                  case "in-progress":
                    return "Send Reminder";
                  default:
                    return null;
                }
              })()}
            </button>
          );
        },
      },
    ];
  }, []);

  return (
    <Table
      resourceName="rfis"
      data={data}
      columns={tableColumns}
      showConstantRowIndex
    />
  );
};

export default PureRfiTable;

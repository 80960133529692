import { ReportAPI } from "@griffingroupglobal/eslib-api";
import { useQueryClient } from "react-query";
import { reportsKeys } from "../config/reactQuery/queryKeyFactory";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import useCurrentUser from "./useCurrentUser";

/**
 * Fetch report by id
 * @param {string} id Report id: 1111-2222-3333-4444
 */
const getReportById = async (id) => {
  const { data } = await ReportAPI.getById(id);
  return data;
};

/**
 * Query hook to fetch single report by id
 * @param {string} reportId report id
 * @param {Function} select function to select or transform data
 */
function useReportById(reportId, select) {
  const queryClient = useQueryClient();

  const { data: currentUser } = useCurrentUser();

  const queryKey = reportsKeys.byId(reportId);
  const canRead = !!currentUser?.permissions?.report?.can_read;

  return useAuthenticatedQuery({
    queryKey,
    enabled: canRead && !!reportId,
    queryFn: () => getReportById(reportId),
    select,
    // Set initial data from cache if exists
    initialData: () =>
      queryClient
        .getQueryData(reportsKeys.reports)
        ?.find((report) => report?.id === reportId),

    // This will refetch in the background if data is older than the provided staleTime
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(reportsKeys.reports)?.dataUpdatedAt,

    onError: (error) => {
      console.error("useReportById", error);
    },
  });
}

export default useReportById;

import PropTypes from "prop-types";
import React from "react";
import SpacesList from "../../stories/Components/SpacesList";

const Spaces = ({ setActions, className }) => {
  return <SpacesList setActions={setActions} className={className} />;
};

Spaces.propTypes = {
  setActions: PropTypes.func,
  className: PropTypes.string,
};

Spaces.defaultProps = {
  setActions: undefined,
  className: undefined,
};

export default Spaces;

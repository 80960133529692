import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import LocationSpace from "./LocationSpace";

const subCN = (space, editing) => cntl`
    flex 
    ${space && !editing ? "items-start" : "items-center"} 
    w-full 
    min-h-0 
    flex-auto
`;

const PureLocationWidget = ({
  space,
  spaces,
  handleAddSpace,
  levelTypes,
  spaceTypes,
  removeSpace,
  isEditing,
  setIsEditing,
  isSaving,
  isFetching,
  disableEditing,
  onClickSpaceView,
  editing,
  resourceDetails,
  parent,
}) => {
  return (
    <div
      className={`flex flex-col w-full h-full ${
        (isFetching || isSaving) && "loading"
      }`}
    >
      <div className={subCN(space, isEditing)}>
        {!isSaving && (
          <>
            <LocationSpace
              resourceDetails={resourceDetails}
              parent={parent}
              space={space}
              spaces={spaces}
              handleAddSpace={handleAddSpace}
              removeSpace={removeSpace}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              levelType={levelTypes?.[space?.level]}
              spaceType={spaceTypes?.[space?.type]}
              disableEditing={disableEditing}
              onClickSpaceView={onClickSpaceView}
              editing={editing}
            />
          </>
        )}
      </div>
    </div>
  );
};

PureLocationWidget.propTypes = {
  space: PropTypes.shape({
    level: PropTypes.string,
    type: PropTypes.string,
  }),
  parent: PropTypes.shape({}),
  removeSpace: PropTypes.func,
  handleAddSpace: PropTypes.func,
  spaces: PropTypes.arrayOf(PropTypes.shape([])),
  levelTypes: PropTypes.arrayOf(PropTypes.shape({})),
  spaceTypes: PropTypes.arrayOf(PropTypes.shape({})),
  isEditing: PropTypes.bool,
  setIsEditing: PropTypes.func,
  isFetching: PropTypes.bool,
  isSaving: PropTypes.bool,
  disableEditing: PropTypes.bool,
  editing: PropTypes.bool,
  onClickSpaceView: PropTypes.func,
  resourceDetails: PropTypes.shape({}),
};

PureLocationWidget.defaultProps = {
  space: undefined,
  parent: undefined,
  removeSpace: undefined,
  handleAddSpace: undefined,
  spaces: undefined,
  levelTypes: undefined,
  spaceTypes: undefined,
  isEditing: false,
  setIsEditing: undefined,
  isFetching: false,
  isSaving: false,
  disableEditing: false,
  editing: false,
  onClickSpaceView: () => {},
  resourceDetails: undefined,
};

export default PureLocationWidget;

import React from "react";
import ReportSingleView from "../../../stories/Components/ReportSingleView";
import useReportSinglePageData from "./useReportSinglePageData";

const ReportSinglePage = () => {
  const { isLoading, handleNavigateBack } = useReportSinglePageData({});

  return (
    <ReportSingleView
      isLoading={isLoading}
      onBackPressed={handleNavigateBack}
    />
  );
};

export default ReportSinglePage;

/**
 * Check if given user is included in the task invitees
 * @param {Object} task - Current task to validate
 * @param {Object} currentUser - Current user to verify
 * @returns {Boolean}
 */
const isCurrentUserAssigned = (task, currentUser) => {
  return task?.invitees?.includes(currentUser?.reference);
};

export default isCurrentUserAssigned;

import { useAssetsOverview } from "./assets";
import { useProjectsOverview } from "./projects";
import { usePropertiesOverview } from "./properties";

export default function useGetAssociatedResource(associationRef) {
  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();
  const { assetsDict: assetDict } = useAssetsOverview();

  const association = {};
  if (associationRef?.includes("Project")) {
    return projectDict?.[associationRef];
  }
  if (associationRef?.includes("Property")) {
    return {
      ...propertiesDict?.[associationRef],
      name: propertiesDict?.[associationRef]?.title,
    };
  }
  if (associationRef?.includes("Asset")) {
    return assetDict?.[associationRef];
  }

  return {
    value: association?.reference,
    reference: association?.reference,
    label: association?.title || association?.name,
  };
}

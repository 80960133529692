import { useState } from "react";
import useRsvp from "../../../hooks/rsvp/useRsvp";
import useRsvpPost from "../../../hooks/rsvp/useRsvpPost";
import useCurrentUser from "../../../hooks/useCurrentUser";

const useRsvpRow = ({ reference, instanceStartDate }) => {
  const {
    data: rsvp,
    isFetched,
    isLoading,
  } = useRsvp(reference, instanceStartDate);

  const { data: currentUser } = useCurrentUser();
  const { mutateAsync: postRsvp } = useRsvpPost();
  const [single, setSingle] = useState(true);
  const [all, setAll] = useState(false);
  const [status, setStatus] = useState();
  const currentStatus = !isFetched ? false : rsvp?.[currentUser?.reference];

  const onButtonPress = async (selectedStatus) => {
    setStatus(selectedStatus);

    const operation = () => {
      if (!instanceStartDate || !single) {
        return "$all";
      }
      return "$single";
    };
    try {
      await postRsvp({
        reference,
        op: operation(),
        status: selectedStatus,
        instanceStartDate,
      });
    } catch (e) {
      console.error("Error on RSVP button (Accept/Decline/Maybe) Press", e);
    }
  };

  const onDone = async (operation) => {
    try {
      await postRsvp({
        reference,
        op: operation,
        status: status ?? currentStatus,
        instanceStartDate,
      });
    } catch (e) {
      console.error("Error on RSVP button (This or All) Press", e);
    }
  };

  const onCheckboxPress = (operation) => {
    // Block patching unless user has selected a status
    if (status || currentStatus) {
      onDone(operation);
    }
  };

  return {
    setSingle,
    setAll,
    onButtonPress,
    onCheckboxPress,
    currentStatus,
    single,
    all,
    isLoading,
  };
};

export default useRsvpRow;

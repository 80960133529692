import PropTypes from "prop-types";
import React from "react";
import { CONTACTS } from "../../../../constants";
import PlusCircleButton from "../../Buttons/PlusCircleButton/PlusCircleButton";
import OwnedByListContacts from "./OwnedByListContacts";
import { hasWritePermission } from "../../../../helpers/Permissions";
import OwnedByListCreateEditContact from "./OwnedByListCreateEditContact";
import useOwnedByListData from "./useOwnedByListData";

const OwnedByList = ({
  members,
  selectedMembers,
  onAdd,
  onRemove,
  onEdit,
  onCreate,
  setEditing,
  editedUser,
  creatingUser,
  setCreatingUser,
  customWidth,
}) => {
  const {
    currentUser,
    currentMembers,
    editingState,
    setEditingState,
    onAddContact,
    onCancel,
    onChange,
  } = useOwnedByListData({
    editedUser,
    members,
    selectedMembers,
    setCreatingUser,
    onRemove,
    onAdd,
    setEditing,
  });
  return (
    <div
      style={{ width: customWidth }}
      className="absolute w-full border shadow-lg flex flex-col z-20 justify-center bg-white rounded-lg pt-4 px-4 pb-6"
    >
      {!editedUser && !creatingUser && (
        <div className="flex flex-row w-full overflow-x-auto h-72">
          <OwnedByListContacts
            handleClick={onChange}
            currentMembers={currentMembers}
            setEditing={setEditing}
          />
        </div>
      )}
      {!editedUser &&
        !creatingUser &&
        hasWritePermission(CONTACTS, currentUser) && (
          <div className="flex w-full">
            <PlusCircleButton
              className="mt-0"
              noHover
              style={{ color: "#027D61" }}
              title="Add Contact"
              onClick={onAddContact}
            />
          </div>
        )}
      {(creatingUser || editedUser) && (
        <OwnedByListCreateEditContact
          creatingUser={creatingUser}
          editingState={editingState}
          setEditingState={setEditingState}
          onCancel={onCancel}
          onCreate={onCreate}
          onEdit={onEdit}
          editedUser={editedUser}
        />
      )}
    </div>
  );
};

OwnedByList.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({})),
  selectedMembers: PropTypes.arrayOf(
    PropTypes.oneOf(PropTypes.shape({}), PropTypes.string)
  ),
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onCreate: PropTypes.func,
  onRemove: PropTypes.func,
  setEditing: PropTypes.func,
  editedUser: PropTypes.shape({
    label: PropTypes.string,
    reference: PropTypes.string,
    contactType: PropTypes.string,
  }),
  creatingUser: PropTypes.bool,
  setCreatingUser: PropTypes.func,
  customWidth: PropTypes.string,
};

OwnedByList.defaultProps = {
  members: [],
  selectedMembers: [],
  onAdd: undefined,
  onRemove: undefined,
  editedUser: undefined,
  onEdit: undefined,
  onCreate: undefined,
  setEditing: undefined,
  creatingUser: false,
  setCreatingUser: undefined,
  customWidth: undefined,
};

export default OwnedByList;

// Framework Tools
import React from "react";
import PropTypes from "prop-types";

// Components
import TertiaryHeader from "../../TextHeaders/TertiaryHeader";

export default function RequestGridViewProjectCell({ project }) {
  // gets project data by id from ProjectsAPI used in hoook

  return (
    <div className="w-full h-ful flex flex-col justify-start flex-wrap px-2">
      <TertiaryHeader className="text-sm text-left">Project</TertiaryHeader>
      <p className="text-sm text-left">{project.name}</p>
    </div>
  );
}

// prop types
RequestGridViewProjectCell.propTypes = {
  project: PropTypes.shape({
    name: "",
  }),
};

// default props
RequestGridViewProjectCell.defaultProps = {
  project: {
    name: "",
  },
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import useConfigurationLastUpdated from "../../../hooks/useConfigurationLastUpdated";
import useDocumentsConfiguration from "../../../hooks/useDocumentsConfiguration";
import useDocumentsConfigurationReducer from "../../../hooks/useDocumentsConfigurationReducer";

import UploadArea from "../UploadArea/UploadArea";
import DroppedFile from "./DroppedFile";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import PrimaryButton from "../Buttons/PrimaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import {
  formatDocumentConfigurationOriginalPayload,
  getDocumentLastUpdatedPatchDate,
} from "../../../helpers/Settings";
import crossIcon from "../../assets/images/crossIcon.svg";

const modalStyles = {
  content: {
    inset: "0",
    width: "50%",
    minWidth: "675px",
    height: "min-content",
    minHeight: "572px",
    maxHeight: "100vh",
    padding: "0",
    marginLeft: "auto",
    marginRight: "auto",
    overflowY: "auto",
    top: "0",
    left: "0",
  },
  overlay: {
    backgroundColor: "rgba(25, 25, 25, 0.8)",
    zIndex: "50",
  },
};

const DragDropDialog = ({
  files,
  extensions,
  defaultType,
  onFilesAdded,
  onFilesUpdated,
  onFilesUploaded,
  onNewDocType,
  onClose,
  show,
}) => {
  const [configurationLastUpdated] = useConfigurationLastUpdated();
  const { data: documentsConfiguration } = useDocumentsConfiguration();
  const [documentsSettings, dispatchDocumentsSettings] =
    useDocumentsConfigurationReducer();

  const [docTypeOptions, setDocTypeOptions] = useState([]);
  const [docTypeOptionsMap, setDocTypeOptionsMap] = useState({});
  const [acceptedFiles, setAcceptedFiles] = useState(files);
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [newDocTypes, setNewDocTypes] = useState([]);

  const resetDocumentsSettings = React.useCallback(() => {
    dispatchDocumentsSettings({
      type: "reset",
      configuration: formatDocumentConfigurationOriginalPayload(
        documentsConfiguration
      ),
    });
  }, [dispatchDocumentsSettings, documentsConfiguration]);

  useEffect(() => {
    if (!_.isEmpty(documentsConfiguration)) {
      resetDocumentsSettings();
    }
  }, [documentsConfiguration, resetDocumentsSettings]);

  const onUpdateDocumentsConfig = React.useCallback(
    async (patchDate, patchBody) => {
      await getDocumentLastUpdatedPatchDate(
        documentsConfiguration,
        patchBody,
        patchDate
      );
    },
    [documentsConfiguration]
  );

  const handleSaveNewType = React.useCallback(
    async (typeInput) => {
      const updatedSetting = {
        ...documentsSettings,
        customDocumentTypes: [
          ...documentsSettings.customDocumentTypes,
          {
            display: typeInput.label,
            id: typeInput.value,
            selected: true,
            custom: true,
          },
        ],
      };

      dispatchDocumentsSettings({
        type: "updateCustomDocumentType",
        value: updatedSetting,
      });
      setDocTypeOptions((prev) => {
        return [typeInput, ...prev];
      });
      setDocTypeOptionsMap((prev) => {
        return { ...prev, [typeInput.value]: typeInput };
      });

      if (onNewDocType) {
        onNewDocType(typeInput);
        setNewDocTypes((prev) => [typeInput, ...prev]);
      }

      await onUpdateDocumentsConfig(configurationLastUpdated, updatedSetting);
    },
    [
      configurationLastUpdated,
      documentsSettings,
      dispatchDocumentsSettings,
      onUpdateDocumentsConfig,
      onNewDocType,
    ]
  );

  useEffect(() => {
    setDocTypeOptions(() => {
      const tempList = [
        ...(documentsConfiguration?.documents?.documentType
          .filter((doc) => doc.custom)
          .map((doc) => {
            return { label: doc.display, value: doc.id };
          })
          .sort(({ label: a }, { label: b }) => a.localeCompare(b)) ?? []),
      ];
      if (onNewDocType) {
        tempList.push({ label: "+ Add new type", value: "addNewType" });
      }
      return tempList;
    });

    setDocTypeOptionsMap({
      ...documentsConfiguration?.documents?.documentType
        .filter((doc) => doc.selected && (doc.value || doc.id))
        .reduce((obj, item) => {
          return {
            ...obj,
            [item.value ?? item.id]: {
              label: item.display,
              value: item.value ?? item.id,
            },
          };
        }, {}),
    });
  }, [documentsConfiguration, onNewDocType]);

  useEffect(() => {
    setAcceptedFiles(files);
  }, [files]);

  useEffect(() => {
    setUploadDisabled(() => {
      if (docTypeOptionsMap && docTypeOptionsMap[defaultType]) {
        return false;
      }
      return !acceptedFiles.every((file) => !!file.docType);
    });
  }, [acceptedFiles, docTypeOptionsMap, defaultType]);

  const handleFilesAdded = React.useCallback(
    (newFiles) => {
      let fileList;
      setAcceptedFiles((prev) => {
        fileList = [
          ...prev,
          ...newFiles?.map((file) => {
            return {
              id: uuidv4(),
              original: file,
              name: file.name,
              type: file.type ?? defaultType,
              ...file,
            };
          }),
        ];
        onFilesAdded(fileList);
        return fileList;
      });
    },
    [defaultType, onFilesAdded]
  );
  const handleFileNameChange = (id, val) => {
    const updatedFiles = acceptedFiles?.map((file) => {
      if (file.id === id) {
        return { ...file, name: val };
      }
      return file;
    });
    onFilesUpdated(updatedFiles);
  };
  const handleFileTypeChange = (id, val) => {
    const updatedFiles = acceptedFiles?.map((file) => {
      if (file.id === id) {
        return { ...file, docType: val };
      }
      return file;
    });
    onFilesUpdated(updatedFiles);
  };
  const handleFileFavorited = (id, val) => {
    const updatedFiles = acceptedFiles?.map((file) => {
      if (file.id === id) {
        return { ...file, isFavorited: val };
      }
      return file;
    });
    onFilesUpdated(updatedFiles);
  };
  const handleFilesUploaded = () => {
    onFilesUploaded(acceptedFiles);
  };
  const handleFileRemoved = (id) => {
    setAcceptedFiles((prev) => prev.filter((item) => item.id !== id));
  };

  return (
    <ReactModal
      style={modalStyles}
      isOpen={show}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
    >
      <div
        className="flex flex-col w-full h-full p-8 border rounded"
        style={{ minHeight: "572px", height: "min-content" }}
      >
        <div className="flex justify-between mb-4 pl-1">
          <SecondaryHeader>New File Upload</SecondaryHeader>
          <button className="-mt-6 -mr-3" type="button" onClick={onClose}>
            <img src={crossIcon} alt="close button" />
          </button>
        </div>

        {acceptedFiles?.length > 0 && (
          <div className="flex flex-col">
            {acceptedFiles?.map((file) => (
              <div key={file.id} className="mb-6">
                <DroppedFile
                  file={file}
                  defaultType={defaultType}
                  docTypeOptions={[...newDocTypes, ...docTypeOptions]}
                  docTypeOptionsMap={{
                    ...docTypeOptionsMap,
                    ...newDocTypes.reduce((obj, val) => {
                      return { ...obj, [val.value]: val };
                    }, {}),
                  }}
                  documentsSettings={documentsSettings}
                  onNameChange={handleFileNameChange}
                  onTypeChange={handleFileTypeChange}
                  onFavorited={handleFileFavorited}
                  onRemove={handleFileRemoved}
                  onSaveNewType={handleSaveNewType}
                  enableNewDocTypes={!!onNewDocType}
                />
              </div>
            ))}
          </div>
        )}

        <div className="h-30">
          <UploadArea extensions={extensions} onFilesAdd={handleFilesAdded} />
        </div>
        <div className="flex justify-end w-full mt-8">
          <TertiaryButton className="mr-2" title="Cancel" onClick={onClose} />
          <PrimaryButton
            title="Upload"
            onClick={handleFilesUploaded}
            disabled={uploadDisabled}
          />
        </div>
      </div>
    </ReactModal>
  );
};

DragDropDialog.propTypes = {
  /**
   * files selected for upload
   */
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.array,
  /**
   * optional accepted file types
   */
  extensions: PropTypes.string,
  /**
   * optional accepted file types
   */
  defaultType: PropTypes.string,
  /**
   * function called when files added
   */
  onFilesAdded: PropTypes.func,
  /**
   * function called when files added
   */
  onFilesUpdated: PropTypes.func,
  /**
   * function called when files uploaded
   */
  onFilesUploaded: PropTypes.func,
  /**
   * function called when new doc type added
   */
  onNewDocType: PropTypes.func,
  /**
   * function called on close
   */
  onClose: PropTypes.func,
  /**
   * show modal
   */
  show: PropTypes.bool,
};

DragDropDialog.defaultProps = {
  files: [],
  extensions: "",
  defaultType: undefined,
  onFilesAdded: () => {},
  onFilesUpdated: () => {},
  onFilesUploaded: () => {},
  onNewDocType: undefined,
  onClose: undefined,
  show: true,
};

export default DragDropDialog;

import React from "react";
import PropTypes from "prop-types";
import "../todo.css";
import memberIcon from "../../../../stories/assets/images/membersIcon.svg";
import editIcon from "../../../../stories/assets/images/magnoliaEditIcon.svg";
import deleteIcon from "../../../../stories/assets/images/deleteWhiteIcon.svg";
import IconButton from "../../../../stories/Components/Buttons/IconButton";
import RoundedCheckbox from "../../../../stories/Components/Checkbox/RoundedCheckbox";
import useTodoElementData from "./useTodoElementData";

const TodoElement = ({ todo, group, complete }) => {
  const {
    handleComplete,
    checked,
    userCreated,
    completed,
    viewTodo,
    editTodo,
    groupTodo,
    handleDeleteTodo,
    disableButton,
  } = useTodoElementData({ complete, todo });

  const checkboxColumn = (
    <div
      id={`todo-actions-${todo?.id}`}
      className="bg-white col-span-2 flex flex-col justify-center items-center border rounded-l-md"
    >
      <RoundedCheckbox
        checked={checked || !todo?.id}
        onChange={handleComplete}
        inputClassName={`${checked || !todo?.id ? "checked:bg-gray-200" : ""}`}
      />
    </div>
  );

  const todoDescriptionColumn = (
    <button
      id={`todo-description-${todo?.id}`}
      onClick={() => viewTodo(todo)}
      disabled={disableButton}
      type="button"
      className={`bg-white col-span-${
        group ? "12" : "10"
      } text-sm py-3 border-t border-b pl-${group ? "5" : "3"} ${
        group ? "border-l rounded-l-lg" : ""
      }`}
    >
      {group && todo?.requiredByAll && (
        <p className="text-primaryGreen text-xxs font-medium text-left tracking-wide leading-relaxed">
          *All Required
        </p>
      )}
      <p className="w-full text-left truncate">{todo?.description}</p>
    </button>
  );

  const rightActionsColumn = (
    <div
      id={`todo-actions-${todo?.id}`}
      className="bg-white col-span-3 flex flex-col gap-2 items-center justify-center rounded-r-md border-r border-t border-b overflow-hidden"
    >
      {!group ? (
        <>
          {userCreated && (
            <div className="todo-edit-button w-full h-full flex items-center justify-center gap-2 bg-primaryGreen cursor-pointer">
              <IconButton
                onClick={editTodo}
                iconLeft={
                  <img
                    src={editIcon}
                    className="w-4 h-4"
                    alt="todo-edit-icon"
                  />
                }
                disabled={disableButton}
                disbaledBackgroundColorCN="bg-transparent"
              />
              <IconButton
                onClick={handleDeleteTodo}
                iconLeft={
                  <img
                    src={deleteIcon}
                    className="w-5 h-5"
                    alt="todo-delete-icon"
                  />
                }
                disabled={disableButton}
                disbaledBackgroundColorCN="bg-transparent"
              />
            </div>
          )}

          {groupTodo && (
            <img
              src={memberIcon}
              className={`w-4 h-4 ${userCreated ? "member-icon" : ""}`}
              alt="member-icon"
            />
          )}
        </>
      ) : (
        <p
          className={`text-xxs text-primaryGreen ${
            todo?.requiredByAll ? "-mt-5" : ""
          }`}
        >
          <span className="font-semibold">{completed?.[0]} / </span>
          {completed[1]}
        </p>
      )}
    </div>
  );

  return (
    <div
      id={`todo-${todo?.id}`}
      className="grid relative todo-elm rounded-md border border-transparent hover:border-green-200 hover:rounded-lg"
      style={{
        gridTemplateColumns: "repeat(15, minmax(0, 1fr))",
      }}
    >
      {!group && checkboxColumn}
      {todoDescriptionColumn}
      {rightActionsColumn}
    </div>
  );
};

TodoElement.propTypes = {
  complete: PropTypes.func,
  todo: PropTypes.shape({
    members: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.string,
    description: PropTypes.string,
    requiredByAll: PropTypes.bool,
    metadata: PropTypes.shape({
      createdAt: PropTypes.string,
      createdBy: PropTypes.string,
    }),
  }),
  group: PropTypes.bool,
};

TodoElement.defaultProps = {
  complete: () => {},
  todo: {},
  group: false,
};

export default TodoElement;

import moment from "moment-timezone";

import { cloneDeep } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { v4 as uuidv4 } from "uuid";
import {
  ticketsKeys,
  workflowKeys,
} from "../../../../config/reactQuery/queryKeyFactory";
import {
  ADD_OPEN_MODAL,
  CONFIRM_MODAL,
  DELETE_RECURRENCE_POPUP,
  EDIT_RECURRENCE_POPUP,
  MARK_COMPLETE_POPUP,
  MOMENT_UTC_ES_FORMAT,
  RECURRENCE_FORM_POPUP,
  recurrenceOptions,
  TOGGLE_POSITIONED_POPUP,
} from "../../../../constants";
import combineDateTime from "../../../../helpers/Calendar/combineDateTime";
import describeRruleFromString from "../../../../helpers/Calendar/describeRruleFromString";
import getAssociatedMembersForTaskEvent from "../../../../helpers/Calendar/getAssociatedMembersForTaskEvent";
import hasRecurrenceFieldChanged from "../../../../helpers/Calendar/hasRecurrenceFieldChanged";
import generateRruleFromSelection from "../../../../helpers/Date/generateRruleFromSelection";
import isBegOfDay from "../../../../helpers/Date/isBegOfDay";
import isEndOfDay from "../../../../helpers/Date/isEndOfDay";
import { getTagOptions } from "../../../../helpers/Tag";
import hasRestrictedTaskFieldsChanged from "../../../../helpers/Task/hasRestrictedTaskFieldsChanged";
import stepsForTask from "../../../../helpers/Task/stepsForTask";
import { toastError } from "../../../../helpers/Toast";
import { useAssetsOverview } from "../../../../hooks/assets";
import useEditCalendar from "../../../../hooks/api/calendar/useEditCalendar";
import useRemoveFromCalendar from "../../../../hooks/api/calendar/useRemoveFromCalendar";
import { useProjectsOverview } from "../../../../hooks/projects";
import { usePropertiesOverview } from "../../../../hooks/properties";
import {
  useEditTaskList,
  useRemoveFromTaskList,
  useTaskDelete,
  useTaskEdit,
  useTaskList,
} from "../../../../hooks/api/tasks";
import taskKeys from "../../../../hooks/api/tasks/taskKeys";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import useFilesPost from "../../../../hooks/useFilesPost";
import useGoBack from "../../../../hooks/useGoBack";
import useServiceRequests from "../../../../hooks/useServiceRequests";
import { useSop } from "../../../../hooks/useSop";
import { useGetTags } from "../../../../hooks/useTags";
import useURLQueryParameter from "../../../../hooks/useURLQueryParameter";
import useWorkflow from "../../../../hooks/useWorkflow";
import { useAppState } from "../../../../state/appState";
import { useModalState } from "../../../../state/modalState";
import { toastMessage } from "../../Toast/Toast";
import useEditingResourceState from "../../../../hooks/useEditingResourceState";

const getDefaultRecurrenceForm = (state) => {
  return state?.recurrence
    ? recurrenceOptions[recurrenceOptions.length - 1]
    : "";
};

const getDefaultRecurrenceString = (state) => {
  return state?.recurrence
    ? describeRruleFromString(state?.recurrence?.value || state?.recurrence)
    : "Not a recurring task";
};

const useSingleTaskViewData = ({
  currentTask,
  setCurrentTask,
  clearCurrentTask,
  usedSopDict,
  page,
}) => {
  const recurrenceDropDownRef = useRef(null);
  const defaultState = useMemo(() => cloneDeep(currentTask), [currentTask]);
  const [formState, setFormState] = useState(defaultState);
  const { deleteTask } = useTaskDelete();
  const { deleteFromTaskList } = useRemoveFromTaskList();
  const { editTask } = useTaskEdit();
  const { editTaskList } = useEditTaskList();
  const { editInCalendar } = useEditCalendar();
  const { removeFromCalendar } = useRemoveFromCalendar();
  const { refetchTaskList } = useTaskList();

  // focus state on last new step added
  const [focus, setFocus] = useState(false);

  const history = useHistory();

  // TODO(Parker): Replace when relatedTo API endpoint is Available
  const { data: serviceRequests } = useServiceRequests({
    tasks: [currentTask?.reference],
  });

  const { data: workflows } = useWorkflow({ tasks: [currentTask?.reference] });

  // Populate array with the related WF/SR (currently only 1, but this allows for more in future)
  const relatedToArray = useMemo(() => {
    return [...(serviceRequests || []), ...(workflows?.workflows || [])];
  }, [serviceRequests, workflows]);

  useEffect(() => {
    setFormState(defaultState);
  }, [defaultState]);

  const initialAllDayCheck =
    formState?.allDay ||
    (isBegOfDay(formState?.startDate) && isEndOfDay(formState?.endDate));
  const [isAllDay, setIsAllDay] = useState(initialAllDayCheck);

  const toggleAllDay = () => {
    setIsAllDay(!isAllDay);
  };
  const allDayData = { isAllDay, toggleAllDay };

  const INITIAL_FILES = useMemo(
    () => ({
      mediaFilesToAdd: [],
      nonMediaFilesToAdd: [],
    }),
    []
  );

  // Hook to navigate back in the history stack
  const { navigateBack } = useGoBack();

  // Hook to read query params from url
  const fromPage = useURLQueryParameter("from");

  // Hook to get access to the query cache
  const queryClient = useQueryClient();

  const [{ userDict, users }, appStateDispatch] = useAppState();
  const { data: currentUser } = useCurrentUser();

  const { assetsDict } = useAssetsOverview();
  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  const [allInviteesInfo, setAllInviteesInfo] = useState([]);
  const [createdBy, setCreatedBy] = useState();
  const [completedBy, setCompletedBy] = useState();
  const [editingMode, setEditingMode] = useState(false);
  const { data: tagsData } = useGetTags();
  const [inviteOpenPopup, setInviteOpenPopup] = useState(false);
  const { mutateAsync: postFiles, isLoading: isUploadingFiles } =
    useFilesPost();
  const [, modalStateDispatch] = useModalState();
  const { data: sopsData } = useSop();
  const [nameForm, setNameForm] = useState(currentTask?.name);

  const initialDayDif = moment(defaultState.endDate).diff(
    moment(defaultState.startDate),
    "days"
  );

  const [dateForm, setDateForm] = useState("");
  const [timeForm, setTimeForm] = useState("");
  const [endDateForm, setEndDateForm] = useState("");
  const [endTimeForm, setEndTimeForm] = useState("");
  const [durationDays, setDurationDays] = useState(initialDayDif || 0);
  const [durationMinutes, setDurationMinutes] = useState(30);
  const [recurrenceForm, setRecurrenceForm] = useState();
  const [recurrenceString, setRecurrenceString] = useState();

  // handle editing state of resource being edited
  useEditingResourceState({
    editing: editingMode,
    resource: "Task",
  });

  useEffect(() => {
    setRecurrenceString(getDefaultRecurrenceString(defaultState));
    setRecurrenceForm(getDefaultRecurrenceForm(defaultState));
  }, [defaultState]);

  const [tagsForm, setTagsForm] = useState([]);
  const [currentInvitees, setCurrentInvitees] = useState([]);
  const [filesState, setFilesState] = useState(INITIAL_FILES);
  const [taskForm, setTaskForm] = useState({ ...currentTask });
  const [isSaving, setIsSaving] = useState(false);
  const [commentsData, setCommentsData] = useState({
    isOpen: false,
    taskReference: undefined,
    association: undefined,
  });
  const [isActivityOpen, setIsActivityOpen] = useState(false);

  const isTaskComplete = currentTask?.status === "done";

  const isOverdueTask = moment(currentTask?.endDate)
    .clone()
    .endOf("day")
    .isBefore(moment());

  const isSingleTask = useMemo(() => {
    return !currentTask?.recurrence;
  }, [currentTask?.recurrence]);

  const showRSVP = useMemo(() => {
    return currentInvitees?.some(
      (item) => item?.reference === currentUser?.reference
    );
  }, [currentInvitees, currentUser?.reference]);

  const sopOptions = useMemo(() => {
    return sopsData?.reduce(
      (list, item) => {
        return {
          options: [
            ...list.options,
            { label: item.name, value: item.reference },
          ],
          dict: { ...list.dict, [item.reference]: item },
        };
      },
      { dict: {}, options: [] }
    );
  }, [sopsData]);

  const onActivityClick = () => {
    setIsActivityOpen(true);
  };

  /**
   * Handles the back button functionality on the Single Task Page.
   */
  const handleLeaveSingleView = () => {
    // Close the Activity Log if it's open
    if (isActivityOpen) {
      setIsActivityOpen(false);
    }
    // Navigate back to the previous page if the task was viewed from the Tasks page
    else if (page) {
      navigateBack();
    } else {
      // Clear the current task and navigate to the list of tasks
      // if the task was viewed from the Association Task Tab
      clearCurrentTask();
      history.push("?tab=tasks");
    }
  };

  /**
   * Open comments with ticket reference
   */

  const setTaskStatus = (status) => {
    setTaskForm({ ...taskForm, status: status.value });
  };

  const handleOpenComments = useCallback(() => {
    setCommentsData({
      taskReference: taskForm.reference,
      association: taskForm.association,
      isOpen: true,
    });
  }, [taskForm.reference, taskForm.association]);

  const handleCloseComments = useCallback(() => {
    setCommentsData({
      taskReference: undefined,
      association: undefined,
      isOpen: false,
    });
  }, []);

  const handleStepEdit = (index, key, value) => {
    // create a copy of the task form
    const taskFormCopy = { ...taskForm };
    // create a copy of the steps array
    const taskFormCopySteps = [...taskFormCopy.steps];

    // update the step at the index with the new value
    const updatedSteps = taskFormCopySteps.map((item, i) => {
      // return the updated item if it is the one we are updating
      if (i === index) return { ...item, [key]: value };
      // return the item if it is not the one we are updating
      return item;
    });

    // update the task form with the updated steps
    setTaskForm({ ...taskFormCopy, steps: updatedSteps });
  };

  const handleStepRemove = (index) => {
    // create a copy of the steps array and set task form
    const taskFormCopySteps = taskForm.steps?.filter((item, i) => i !== index);
    // update the task form with the updated steps
    setTaskForm({ ...taskForm, steps: taskFormCopySteps });
  };

  const handleFilesToAdd = (files, type = "media") => {
    setFilesState((prev) => {
      const updatedFilesState = { ...prev };

      if (type === "media") {
        // add the files to the media files to add (jpeg, png, mp4 etc)
        updatedFilesState.mediaFilesToAdd = [...prev.mediaFilesToAdd, ...files];
      } else {
        // add the files to the non media files to add (pdf, doc, cvc etc)
        updatedFilesState.nonMediaFilesToAdd = [
          ...prev.nonMediaFilesToAdd,
          ...files,
        ];
      }

      // return the updated files state
      return updatedFilesState;
    });
  };

  const handleFilesToRemove = (fileToRemove, type = "media") => {
    setFilesState((prev) => {
      const updatedFilesState = { ...prev };

      if (type === "media") {
        // remove the file from the media files to add (jpeg, png, mp4 etc)
        updatedFilesState.mediaFilesToAdd = prev.mediaFilesToAdd.filter(
          (file) => file !== fileToRemove
        );
      } else {
        // remove the file from the non media files to add (pdf, doc, cvc etc)
        updatedFilesState.nonMediaFilesToAdd = prev.nonMediaFilesToAdd.filter(
          (file) => file !== fileToRemove
        );
      }

      return updatedFilesState;
    });

    setTaskForm((prev) => {
      const updatedTaskForm = { ...prev };
      // remove the file from the task form
      updatedTaskForm.files = prev.files.filter(
        (file) => file.ref !== fileToRemove.ref
      );

      return updatedTaskForm;
    });
  };

  const toggleEditingMode = useCallback(() => {
    setNameForm(currentTask?.name);
    setDateForm(moment(currentTask?.startDate).format());
    setTimeForm(moment(currentTask?.startDate).format());

    setEndDateForm(moment(currentTask?.endDate).format());
    setEndTimeForm(moment(currentTask?.endDate).format());

    const currentInviteeArray = currentTask?.invitees?.map((userRef) => {
      return userDict?.[userRef];
    });

    setCurrentInvitees(currentInviteeArray);
    setTagsForm(getTagOptions(currentTask, tagsData?.tagsDict));
    setFilesState(INITIAL_FILES);

    setRecurrenceForm(getDefaultRecurrenceForm(currentTask));
    setRecurrenceString(getDefaultRecurrenceString(currentTask));

    // if we are entering editing mode, set the task form to the current task
    if (!editingMode) {
      setTaskForm({ ...currentTask });
    }

    setEditingMode(!editingMode);
  }, [INITIAL_FILES, currentTask, editingMode, tagsData?.tagsDict, userDict]);

  /**
   * Clean exit for recurring Tasks
   * @param {Object} - Response from backend
   */
  const handleCleanEditExit = ({ updateResponse }) => {
    setCurrentTask(updateResponse);
    toggleEditingMode();
  };

  /**
   * Updates Task history invalidating query key
   */
  const invalidateTaskHistory = () => {
    queryClient.invalidateQueries(taskKeys.history(taskForm.id));
  };

  /**
   * This function runs when a recurring task is deleted
   */
  const handleDeleteCleanExit = () => {
    // invalidate task activity
    invalidateTaskHistory();
    handleLeaveSingleView();
  };

  const handleCompleteCleanExit = async (task) => {
    setCurrentTask(task.data);
    await editTask(task, false);

    if (task?.data?.recurrence) {
      refetchTaskList();
    }
  };

  const handleCompleteClick = () => {
    appStateDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      position: {
        x:
          window.innerWidth < 1200
            ? window.innerWidth / 5
            : window.innerWidth / 2.5,
        y: window.innerHeight / 5,
      },
      popupData: {
        item: currentTask,
        handleCleanExit: handleCompleteCleanExit,
      },
      popupType: MARK_COMPLETE_POPUP,
    });
  };

  const handleRemoveSpaces = (entity) => {
    if (entity?.includes("Asset")) {
      const assets = taskForm?.assets?.filter((item) => item.ref !== entity);
      setTaskForm({ ...taskForm, assets: [...(assets ?? [])] });
      return;
    }
    const spaces = taskForm?.spaces?.filter((item) => item.id !== entity);
    setTaskForm({ ...taskForm, spaces: [...(spaces ?? [])] });
  };

  const handleSelectSpaces = (entity) => {
    if (entity?.includes("Asset")) {
      const assets = [
        ...(taskForm?.assets ?? []),
        { ref: entity, isCompleted: false },
      ];
      setTaskForm({ ...taskForm, assets: [...(assets ?? [])] });
      return;
    }
    const spaces = [
      ...(taskForm?.spaces ?? []),
      { id: entity, isChecked: false },
    ];
    setTaskForm({ ...taskForm, spaces: [...(spaces ?? [])] });
  };

  const canAddStep = useMemo(() => {
    // only if no steps exist or last step is not new empty step
    return (
      taskForm?.steps?.length === 0 ||
      !!(
        taskForm?.steps?.[taskForm?.steps?.length - 1]?.description ||
        taskForm?.steps?.[taskForm?.steps?.length - 1]?.sop
      )
    );
  }, [taskForm]);

  const handleStepAdd = () => {
    const taskFormCopySteps = [...taskForm?.steps];

    // Dispatches an action to add a new step to the state or store,
    if (canAddStep) {
      // focus on the newly added step
      setFocus(true);

      taskFormCopySteps.push({
        name: "",
        description: "",
        sop: "",
        date: moment().format(),
        id: uuidv4(),
      });

      setTaskForm({ ...taskForm, steps: taskFormCopySteps });
    }
  };

  const handleTagsChange = (obj) => {
    setTagsForm(obj.currentTags);
  };

  const handleAddInvitee = (invitee) => {
    const newInvitees = [...currentInvitees, invitee];
    setCurrentInvitees(newInvitees);
  };

  const handleRemoveInvitee = (invitee) => {
    const newInvitees = currentInvitees?.filter(
      (item) => item.reference !== invitee
    );
    setCurrentInvitees(newInvitees);
  };

  const removeAssignee = (assigneeRef) => {
    const newAssignees = currentInvitees?.filter(
      (item) => item.reference !== assigneeRef
    );

    setCurrentInvitees(newAssignees);
  };

  // Reference Files: LinksRow, useLinksRow
  // Copy taskform links array, add new link to copy, set updated TaskFormState
  const handleAddLink = ({ linkName, linkUrl }) => {
    const taskFormCopyLinks = [...taskForm.links];
    taskFormCopyLinks.push({ name: linkName, url: linkUrl, id: uuidv4() });
    setTaskForm({ ...taskForm, links: taskFormCopyLinks });
  };

  // Copy taskform array, remove deleted link, set the updated taskformstate
  const handleRemoveLink = (link) => {
    const taskFormCopyLinks = [...taskForm?.links];
    const linksArray = taskFormCopyLinks?.filter((item) => item !== link);
    setTaskForm({ ...taskForm, links: linksArray });
  };

  useEffect(() => {
    const currentInviteeArray = currentTask?.invitees?.map((userRef) => {
      return userDict?.[userRef];
    });

    setCurrentInvitees(currentInviteeArray);
  }, [currentTask?.invitees, userDict]);

  const steps = useMemo(
    () => stepsForTask(currentTask, usedSopDict),
    [currentTask, usedSopDict]
  );

  const setFormName = (name) => {
    setNameForm(name);
  };

  const handlePriorityChange = (priority) => {
    setTaskForm((prev) => ({
      ...prev,
      priority,
    }));
  };

  const handleDescriptionChange = (description) => {
    setTaskForm((prev) => ({
      ...prev,
      description,
    }));
  };

  // function to help tell the difference in days
  const calculateDaysDifference = (startDate, endDate) => {
    return moment(endDate).diff(moment(startDate), "days");
  };

  // function to help tell the difference in minutes
  const calculateMinutesDifference = (startDate, endDate) => {
    return moment(endDate).diff(moment(startDate), "minutes");
  };

  const setFormDate = (date) => {
    const newOffset = moment.tz(date, currentTask.timezone).utcOffset();
    const formattedDate = moment(date).utcOffset(newOffset, true).format();
    setDateForm(formattedDate);

    const formattedEndDate = moment(date)
      .utcOffset(newOffset, true)
      .add(durationDays, "days")
      .add(durationMinutes, "minutes")
      .format();
    // update end date based on duration
    setEndDateForm(formattedEndDate);
  };

  const setFormTime = (time) => {
    const newOffset = moment.tz(time, currentTask.timezone).utcOffset();
    const formattedTime = moment(time).utcOffset(newOffset, true).format();
    setTimeForm(formattedTime);

    const formattedEndTime = moment(time)
      .utcOffset(newOffset, true)
      .add(durationMinutes, "minutes")
      .format();
    // update end time based on duration
    setEndTimeForm(formattedEndTime);
  };

  const setFormEndDate = (date) => {
    const newOffset = moment.tz(date, currentTask.timezone).utcOffset();
    const formattedDate = moment(date).utcOffset(newOffset, true).format();
    // recalculate duration
    setDurationDays(calculateDaysDifference(dateForm, formattedDate));
    setEndDateForm(formattedDate);
  };

  const setFormEndTime = (time) => {
    const newOffset = moment.tz(time, formState.timezone).utcOffset();
    const formattedTime = moment(time).utcOffset(newOffset, true).format();
    // recalculate duration
    setDurationMinutes(calculateMinutesDifference(timeForm, formattedTime));
    setEndTimeForm(formattedTime);
  };

  const handleRecurrenceSubmit = ({ value }) => {
    setTaskForm((prev) => ({
      ...prev,
      recurrence: value.value,
    }));
  };

  const setFormRecurrence = useCallback(
    (recurrence) => {
      setRecurrenceForm(recurrence);
      if (recurrence?.label?.includes("Custom")) {
        const rect = recurrenceDropDownRef.current.getBoundingClientRect();
        const { right, top } = rect;

        const combinedDateTime = combineDateTime({
          timeFrom: timeForm,
          dateFrom: dateForm,
        });

        const convertedForPath = moment(combinedDateTime).format();

        appStateDispatch({
          type: TOGGLE_POSITIONED_POPUP,
          position: {
            x: right + 10,
            y: top - 200,
          },
          popupData: {
            dispatch: handleRecurrenceSubmit,
            noMaxHeight: true,
            setRecurrenceString,
            // set correct start date if edited
            item: { ...taskForm, startDate: convertedForPath },
          },
          popupType: RECURRENCE_FORM_POPUP,
        });
      } else {
        setRecurrenceString("");
        setTaskForm((prev) => ({
          ...prev,
          recurrence: generateRruleFromSelection(recurrence),
        }));
      }
    },
    [appStateDispatch, dateForm, taskForm, timeForm]
  );

  const cancelEditing = () => {
    setIsAllDay(initialAllDayCheck);
    toggleEditingMode();
  };

  const inviteeArray = useMemo(() => {
    if (currentTask?.association) {
      return getAssociatedMembersForTaskEvent({
        associationReference: currentTask.association,
        userDict,
        propertiesDict,
        assetsDict,
        projectDict,
        full: true,
      });
    }

    return users;
  }, [assetsDict, currentTask, projectDict, propertiesDict, userDict, users]);

  useEffect(() => {
    const invitees = currentTask?.invitees || [];
    const inviteeData = invitees?.reduce((list, invitee) => {
      // Get information for each invitee from the user dictionary
      const inviteeInfo = userDict?.[invitee];
      if (inviteeInfo) list.push(inviteeInfo);
      return list;
    }, []);

    setAllInviteesInfo(inviteeData);
    setCreatedBy(userDict?.[currentTask?.metadata?.createdBy]);
    if (currentTask?.closing?.closedBy) {
      setCompletedBy(userDict?.[currentTask?.closing?.closedBy]);
    }
  }, [userDict, currentTask]);

  useEffect(() => {
    setDateForm(moment(currentTask?.startDate).format());
    setTimeForm(moment(currentTask?.startDate).format());
  }, [currentTask]);

  useEffect(() => {
    if (!editingMode) {
      setTagsForm(getTagOptions(currentTask, tagsData?.tagsDict));
    }
  }, [currentTask, editingMode, tagsData?.tagsDict]);

  // function for handling edit click
  const handleEditClick = () => {
    setFocus(false);
    toggleEditingMode();
  };

  // function to invalidate tasks for tickets.
  // Make sure SR get the latest data update
  const invalidateServiceRequestTasks = () => {
    queryClient.invalidateQueries(ticketsKeys.tasks());
  };
  // function to invalidate tasks for WF.
  // Make sure WF get the latest data update
  const invalidateWorkflowTasks = () => {
    queryClient.invalidateQueries(
      workflowKeys.workflowByTaskRef(currentTask?.reference)
    );
  };

  const handleSaveClick = async () => {
    setIsSaving(true);
    try {
      const combinedDateTime = combineDateTime({
        timeFrom: timeForm,
        dateFrom: dateForm,
      });

      const combinedEndDateTime = combineDateTime({
        timeFrom: endTimeForm,
        dateFrom: endDateForm,
      });

      const newFiles = [
        ...filesState.mediaFilesToAdd,
        ...filesState.nonMediaFilesToAdd,
      ];

      const convertedForPath = moment
        .utc(combinedDateTime)
        .format(MOMENT_UTC_ES_FORMAT);

      const convertedEndForPath = moment
        .utc(combinedEndDateTime)
        .format(MOMENT_UTC_ES_FORMAT);

      const hasRecurrenceChanged = hasRecurrenceFieldChanged(
        currentTask,
        taskForm
      );

      // when changing recurrence, we only allow updating future recurrences from scheduled instance,
      // so DTSTART should be set to start date of this/current instance
      // if recurrence changed, but removed skip updating recurrence
      if (hasRecurrenceChanged && taskForm?.recurrence) {
        // get the recurrence string from the recurrence object
        const recString = taskForm.recurrence;
        const rruleLessDTstart =
          recString.split("\n")?.length > 1
            ? recString.split("\n")[1]
            : recString;

        // get the start date from the form state
        const dtStart = `DTSTART:${moment(taskForm.startDate)
          .utc()
          .format("YYYYMMDDTHHmmss")}Z`;
        // combine the start date and the recurrence string
        const fullRrule = `${dtStart}\n${rruleLessDTstart}`;
        // set the recurrence string to the full rrule
        taskForm.recurrence = fullRrule;
      }

      const updatesFromForm = {
        name: nameForm,
        startDate: convertedForPath,
        endDate: convertedEndForPath,
        invitees: currentInvitees?.map((item) => item?.reference),
        tags: tagsForm?.map((tagObj) => tagObj?.value),

        steps: taskForm?.steps?.length
          ? taskForm?.steps?.filter((item) => item?.description || item?.sop)
          : [],
      };

      if (isAllDay) {
        const startOfDay = moment(convertedForPath).startOf("day").format();
        const endOfDay = moment(convertedEndForPath).endOf("day").format();

        updatesFromForm.allDay = true;
        updatesFromForm.startDate = startOfDay;
        updatesFromForm.endDate = endOfDay;
      } else {
        updatesFromForm.allDay = false;
      }

      if (newFiles?.length) {
        const recentFiles = []; // actually cloned files
        const newlyAddedFiles = []; // files that are newly added from user's local system

        // separate added recent (cloned) files because they are already posted to the cloud
        newFiles?.forEach((file) => {
          if (file?.isRecent) recentFiles.push(file);
          else newlyAddedFiles.push(file);
        });

        // post newly added files from user's system
        const newlyPostedFiles = await postFiles(newlyAddedFiles);
        // combine the 2 types of posted files for the task files[]
        const allPostedFiles = [...recentFiles, ...newlyPostedFiles];

        const responseFileReferences = allPostedFiles?.map((item) => {
          return { ref: item.reference, category: item.category };
        });

        const filesForTask = [...currentTask?.files, ...responseFileReferences];

        updatesFromForm.files = filesForTask;
      }

      const taskUpdates = {
        ...taskForm,
        ...updatesFromForm,
      };

      delete taskUpdates?.originalResource;
      delete taskUpdates?.currentTags;
      delete taskUpdates?.previousTask;

      if (!currentTask?.recurrence) {
        const editTaskProps = {
          originalItem: currentTask,
          editedItem: taskUpdates,
        };

        const editParams = {
          args: editTaskProps,
          operation: "$non-recurring",
        };

        const pageViewedFrom = window.location.pathname.split("/")[1];

        if (pageViewedFrom === "tasks") {
          const response = await editTask(editParams);
          if (!response?.data) return;

          const editCalendarProps = {
            args: { ...editTaskProps, editedItem: response.data },
            operation: "$non-recurring",
          };

          editInCalendar(editCalendarProps, false);
          setCurrentTask(response.data);
        }

        if (pageViewedFrom !== "tasks") {
          const response = await editTaskList(editParams);
          const editCalendarProps = {
            args: { ...editTaskProps, editedItem: response.data },
            operation: "$non-recurring",
          };

          editInCalendar(editCalendarProps, false);
          setCurrentTask(response.data);
        }

        invalidateServiceRequestTasks();
        invalidateTaskHistory();

        toastMessage("Task updated successfully");
        toggleEditingMode();
      } else {
        const updatedTask = { ...currentTask, ...taskUpdates };

        // check if edit all should be disabled
        const hasFieldsChanged = hasRestrictedTaskFieldsChanged(
          currentTask,
          updatedTask
        );

        appStateDispatch({
          type: TOGGLE_POSITIONED_POPUP,
          position: {
            centered: true,
          },
          popupData: {
            item: currentTask,
            form: updatedTask,
            handleCleanExit: handleCleanEditExit,
            popupWidth: 444,
            disableEditAll: hasFieldsChanged,
            disableEditThis: hasRecurrenceChanged,
            disableEditFuture: isOverdueTask && hasFieldsChanged,
          },
          popupType: EDIT_RECURRENCE_POPUP,
        });
      }
    } catch (error) {
      toastError("This task could not be updated");
    } finally {
      setIsSaving(false);
    }
  };

  if (!currentTask) {
    handleLeaveSingleView();
  }

  const handleDeleteTask = async () => {
    try {
      await deleteTask(currentTask);
      // Removes task from the task list and calendar cache without making a new request.
      // Note: Hover over function for more details

      const removalParams = {
        args: currentTask,
        operation: "$non-recurring",
      };

      deleteFromTaskList(removalParams, false);
      removeFromCalendar(removalParams, false);

      // invalidate possible SR tasks
      invalidateServiceRequestTasks();
      // invalidate possible WF tasks
      invalidateWorkflowTasks();

      handleLeaveSingleView();
      toastMessage("Task deleted successfully");
    } catch (error) {
      console.error("currentTask not present : redirecting");
    }
  };

  const relatedType =
    relatedToArray[0]?.resource === "Ticket" ? "Service Request" : "Workflow";

  const deleteModalPrompt = relatedToArray?.length
    ? `This Task is related to a ${relatedType}. By deleting this Task you are deleting it from the ${relatedType}, are you sure? This action cannot be undone.`
    : "Are you sure you want to delete this task, this action cannot be undone?";

  // function for handling delete click
  const handleDeleteClick = () => {
    // if the task is not recurring, open the confirm delete modal
    if (!currentTask?.recurrence) {
      modalStateDispatch({
        type: ADD_OPEN_MODAL,
        modalType: CONFIRM_MODAL,
        ref: { id: `${currentTask.id}-confirm-delete` },
        modalData: {
          item: {
            prompt: deleteModalPrompt,
            confirm: "Yes",
            cancel: "No",
            title: "Delete Task",
            onConfirm: () => handleDeleteTask(),
          },
        },
        position: { x: 0, y: 0 },
      });
      // return to prevent the rest of the function from running
      return;
    }

    // If the task is recurring, open the delete recurrence popup
    appStateDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      position: {
        centered: true,
      },
      popupData: {
        item: currentTask,
        handleCleanExit: handleDeleteCleanExit,
        popupWidth: 444,
      },
      popupType: DELETE_RECURRENCE_POPUP,
    });
  };

  return {
    allDayData,
    nameForm,
    allInviteesInfo,
    editingMode,
    dateForm,
    timeForm,
    endDateForm,
    endTimeForm,
    tagsForm,
    steps,
    createdBy,
    completedBy,
    currentInvitees,
    inviteOpenPopup,
    inviteeArray,
    filesState,
    taskForm,
    sopOptions,
    commentsData,
    fromPage,
    isSaving: isSaving || isUploadingFiles,
    isActivityOpen,
    isTaskComplete,
    isOverdueTask,
    isSingleTask,
    recurrenceDropDownRef,
    recurrenceForm,
    recurrenceString,
    onActivityClick,
    setTaskStatus,
    handleOpenComments,
    handleCloseComments,
    handleStepAdd,
    handleStepEdit,
    handleStepRemove,
    handleDeleteTask,
    handleLeaveSingleView,
    handleFilesToAdd,
    handleFilesToRemove,
    handleTagsChange,
    handleAddInvitee,
    handleRemoveInvitee,
    setInviteOpenPopup,
    removeAssignee,
    setFormDate,
    setFormTime,
    setFormEndDate,
    setFormEndTime,
    setFormRecurrence,
    cancelEditing,
    setFormName,
    handleSaveClick,
    toggleEditingMode,
    handleCompleteClick,
    handleEditClick,
    handleDeleteClick,
    handleDescriptionChange,
    handlePriorityChange,
    handleAddLink,
    handleRemoveLink,
    handleSelectSpaces,
    handleRemoveSpaces,
    setTaskForm,
    showRSVP,
    focus,
    canAddStep,
  };
};

export default useSingleTaskViewData;

import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import EsAddressAutoComplete from "../EsAddressAutoComplete";
import Input from "../Input/Input";
import Dropdown from "../Dropdown/Dropdown";
import useBillingBaseAddressData from "./useBillingBaseAddressData";

/**
 * 
 * @param {Function} onChange 
 * @returns {Object} - { 
    "address1": {
        "label": {String},
        "value": {Object}
    },
    "street": {String},
    "city": {String},
    "country": {String},
    "state": {String},
    "zipCode": {String}
}}
 */
const BillingBaseAddressInput = ({
  address,
  onChange,
  onKeyPress,
  onForwardRef,
  test,
  hideCountry,
  stateOptions,
  addressGroupCN,
}) => {
  const { inputId, handleChange, handleAddressSelect, getValueFromKey } =
    useBillingBaseAddressData(address, onChange);

  return (
    <>
      <div className="flex flex-col w-full gap-4">
        <div className={addressGroupCN}>
          <div className="flex flex-col w-1/2 gap-2">
            <p className="ESInputLabel">Street Address</p>
            <EsAddressAutoComplete
              value={getValueFromKey("street") ?? ""}
              id={`${!test ? inputId : "test-"}address1`}
              onChange={handleAddressSelect}
              forwardedRef={(el) =>
                onForwardRef(`${!test ? inputId : "test-"}address1`, el)
              }
            />
          </div>

          <Input
            inputClassName="ESInput"
            name="street2"
            label="Apt, Suite, etc"
            labelClassName="ESInputLabel mb-2"
            mainWrapperClassName="w-1/2"
            placeholder="Apt, Suite..."
            id={`${!test ? inputId : "test-"}street2`}
            value={getValueFromKey("street2")}
            onChange={(val) => handleChange("street2", val)}
            handleEnter={onKeyPress}
            validation={yup.string()}
            forwardedRef={(el) =>
              onForwardRef(`${!test ? inputId : "test-"}street2`, el)
            }
            disableClear
          />
        </div>
        <div className={addressGroupCN}>
          <Input
            inputClassName="ESInput"
            label="City"
            labelClassName="ESInputLabel mb-2"
            mainWrapperClassName="w-1/2"
            name="city"
            placeholder="City"
            id={`${!test ? inputId : "test-"}city`}
            value={getValueFromKey("city")}
            onChange={(val) => handleChange("city", val)}
            handleEnter={onKeyPress}
            validation={yup.string()}
            forwardedRef={(el) =>
              onForwardRef(`${!test ? inputId : "test-"}city`, el)
            }
            disableClear
          />
          <div className="flex items-center w-1/2">
            {!stateOptions ? (
              <Input
                mainWrapperClassName="w-full flex"
                label="State"
                labelClassName="ESInputLabel mb-2"
                inputClassName="ESInput"
                name="state"
                placeholder="State"
                id={`${!test ? inputId : "test-"}state`}
                value={getValueFromKey("state")}
                onChange={(val) => handleChange("state", val)}
                handleEnter={onKeyPress}
                validation={yup.string()}
                forwardedRef={(el) =>
                  onForwardRef(`${!test ? inputId : "test-"}state`, el)
                }
                disableClear
              />
            ) : (
              <Dropdown
                label="State"
                labelClassName="ESInputLabel mb-2"
                placeholder="Select"
                options={stateOptions}
                value={stateOptions.find(
                  (item) => item.value === address?.state?.value
                )}
                onChange={(val) => handleChange("state", val)}
                className="ESInput"
                validation={yup.mixed().required()}
                disableSort
              />
            )}
          </div>
        </div>
        <div className={addressGroupCN}>
          <Input
            label="Postal Code"
            labelClassName="ESInputLabel mb-2"
            inputClassName="ESInput"
            mainWrapperClassName="w-1/2 flex"
            name="zipCode"
            placeholder="Postal code"
            id={`${!test ? inputId : "test-"}zipCode`}
            value={getValueFromKey("zipCode")}
            onChange={(val) => handleChange("zipCode", val)}
            handleEnter={onKeyPress}
            validation={yup.string()}
            forwardedRef={(el) =>
              onForwardRef(`${!test ? inputId : "test-"}zipCode`, el)
            }
            disableClear
          />

          {!hideCountry && (
            <Input
              label="Country"
              labelClassName="ESInputLabel mb-2"
              inputClassName="ESInput"
              mainWrapperClassName="w-1/2 flex"
              name="country"
              placeholder="Country"
              value={
                getValueFromKey("country")?.label ?? getValueFromKey("country")
              }
              onChange={(val) => handleChange("country", val)}
              handleEnter={onKeyPress}
              validation={yup.string()}
              forwardedRef={(el) =>
                onForwardRef(`${!test ? inputId : "test-"}country`, el)
              }
              disableClear
            />
          )}
        </div>
      </div>
    </>
  );
};

BillingBaseAddressInput.propTypes = {
  address: PropTypes.shape({
    state: PropTypes.shape({ value: PropTypes.string }),
  }),
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onForwardRef: PropTypes.func,
  inputRefs: PropTypes.shape({ current: PropTypes.shape({}) }),
  test: PropTypes.bool,
  hideCountry: PropTypes.bool,
  stateOptions: PropTypes.arrayOf(PropTypes.shape({})),
  addressGroupCN: PropTypes.string,
};

BillingBaseAddressInput.defaultProps = {
  address: undefined,
  onChange: () => {},
  onKeyPress: () => {},
  onForwardRef: () => {},
  inputRefs: { current: {} },
  test: false,
  hideCountry: false,
  stateOptions: undefined,
  addressGroupCN: "flex flex-row w-full gap-4 justify-evenly item-center",
};

export default BillingBaseAddressInput;

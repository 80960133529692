import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Dropdown from "../Dropdown/Dropdown";
import DatePicker from "../DatePicker/DatePicker";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import TertiaryButton from "../Buttons/TertiaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";

import eventIcon from "../../assets/images/eventIcon.png";

const eventIconElement = (
  <img className="w-4 h-4" src={eventIcon} alt="event icon" />
);

const frequencyTypeOptions = [
  { label: "Net 30", value: "net30" },
  { label: "Net 15", value: "net15" },
];

const DataImport = ({
  invoice,
  dispatch,
  project,
  budgetType,
  budgetTypeOptions,
}) => {
  const [editDateRange, setEditDateRange] = useState();
  const [editDueDate, setEditDueDate] = useState();
  const [tempFrequencyType, setTempFrequencyType] = useState();
  const [tempDueDate, setTempDueDate] = useState();

  const handleSimpleInput = (type, value) => {
    dispatch({
      type,
      value,
    });
  };

  return (
    <div className="flex flex-col w-1/2">
      <div className="flex items-center h-12">
        <span className="font-semibold mr-4">Type</span>
        <span className="">
          {
            budgetTypeOptions.find((item) => budgetType?.includes(item.value))
              ?.label
          }
        </span>
      </div>

      <div className="flex items-center h-12">
        <span className="font-semibold mr-4">Project</span>
        <span className="">{project.name}</span>
      </div>

      <div className="flex items-center h-12">
        <span className="font-semibold mr-4">Invoice Date</span>
        {!editDateRange && (
          <>
            <span className="">
              {invoice.rangeStart &&
                moment(invoice.rangeStart).format("MMM. D, YYYY")}{" "}
              -{" "}
              {invoice.rangeEnd &&
                moment(invoice.rangeEnd).format("MMM. D, YYYY")}
            </span>
            <TertiaryButton
              className="ml-2"
              title="Change"
              onClick={() => setEditDateRange((prev) => !prev)}
            />
          </>
        )}
        {editDateRange && (
          <>
            <DateRangePicker
              initialStart={invoice.rangeStart}
              initialEnd={invoice.rangeEnd}
              onChangeStartDate={(val) => handleSimpleInput("rangeStart", val)}
              onChangeEndDate={(val) => handleSimpleInput("rangeEnd", val)}
              onFinishEditing={({ startDate, endDate }) => {
                handleSimpleInput("rangeStart", startDate);
                handleSimpleInput("rangeEnd", endDate);
                setEditDateRange((prev) => !prev);
              }}
              onCancelEditing={() => setEditDateRange((prev) => !prev)}
              saveOnFinish
            />
          </>
        )}
      </div>

      <div className="flex items-center h-12">
        <span className="font-semibold mr-4">Recipient</span>
        <span className="">{invoice.recipient}</span>
      </div>

      <div className="flex items-center h-12">
        <span className="font-semibold mr-4">Due</span>
        {!editDueDate && (
          <>
            <span className="mr-4">
              {frequencyTypeOptions.find(
                (item) => item.value === invoice.frequencyType
              )?.label ?? "Net 30"}
            </span>
            <span className="">
              {invoice.dueDate &&
                moment(invoice.dueDate).format("MMM. D, YYYY")}
            </span>
            <TertiaryButton
              className="ml-2"
              title="Change"
              onClick={() => setEditDueDate((prev) => !prev)}
            />
          </>
        )}
        {editDueDate && (
          <div className="flex items-center">
            <Dropdown
              className="flex-1 mr-4"
              //   label="Frequency"
              placeholder="Select Frequency"
              options={frequencyTypeOptions}
              value={frequencyTypeOptions.find(
                (item) => item.value === tempFrequencyType
              )}
              onChange={({ value }) => setTempFrequencyType(value)}
            />
            <DatePicker
              className="flex-1 -mt-7"
              label="Due Date"
              placeholder="MM-DD-YYYY"
              value={tempDueDate}
              iconLeft={eventIconElement}
              onChange={(val) => setTempDueDate(val)}
            />
            <div className="flex flex-1 items-center ml-2">
              <TertiaryButton
                className="mr-2"
                title="Cancel"
                onClick={() => {
                  setTempFrequencyType(invoice.frequencyType);
                  setTempDueDate(invoice.dueDate);
                  setEditDueDate((prev) => !prev);
                }}
              />
              <SecondaryButton
                className="mr-2"
                title="Save"
                onClick={() => {
                  handleSimpleInput("frequencyType", tempFrequencyType);
                  handleSimpleInput("dueDate", tempDueDate);
                  setEditDueDate((prev) => !prev);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

DataImport.propTypes = {
  //   eslint-disable-next-line react/forbid-prop-types
  invoice: PropTypes.object,
  dispatch: PropTypes.func,
  //   eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.object,
  budgetType: PropTypes.string,
  //   eslint-disable-next-line react/forbid-prop-types
  budgetTypeOptions: PropTypes.array,
};

DataImport.defaultProps = {
  invoice: {},
  dispatch: undefined,
  project: {},
  budgetType: "",
  budgetTypeOptions: [],
};

export default DataImport;

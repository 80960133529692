import { useCallback, useEffect, useState } from "react";
import { useGetFile } from "./useGetFile";
import { toastError } from "../helpers/Toast";

const useDownloadFile = () => {
  const [fileReference, setFileReference] = useState(null);

  // Dependent query hook: it will be enabled if `fileReference` has a valid file reference.
  const { data: fileData, isFetching } = useGetFile(fileReference);

  // This effect will download the file, when RQ finish with data fetching OR if data is found in cache,
  useEffect(() => {
    if (!isFetching && fileData && fileData?.reference === fileReference) {
      if (fileData?.contentsUrl) {
        window.location.href = fileData.contentsUrl;
      } else {
        toastError(
          "Unfortunately this report can't be downloaded. Please contact support"
        );
      }

      // Setting `fileReference` as null to disable `useGetFile` query hook
      setFileReference(null);
    }
  }, [fileData, fileReference, isFetching]);

  /**
   * Set file reference state to fetch file data
   * @param newFileReference valid ES file reference: Ex: File/1111-2222-4444-6666
   */
  const handleDownloadESFile = useCallback((fileRef) => {
    if (fileRef) {
      setFileReference(fileRef);
    }
  }, []);

  return { handleDownloadESFile, isFetching };
};

export default useDownloadFile;

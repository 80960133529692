/* eslint-disable react/button-has-type */
import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./BaseButton.css";

const containerCN = (className) => cntl`
  flex
  flex-1
  ${className}
`;

const buttonCN = cntl`
  flex
  justify-center
  items-center
  text-sm
  capitalize
  stroke-current
  transition
  duration-300
  ease-in-out
  disabled:cursor-default
  disabled:bg-gray-100
  disabled:text-gray-300
  whitespace-nowrap	
  py-2
  rounded
  text-brandGreen
  font-semibold
  disabled:bg-opacity-70
`;

const iconCN = (className) => cntl`
  ${className}
`;

const ContactBaseButton = ({
  containerClassName,
  iconLeft,
  disabled,
  dropdownItems,
  iconClassName,
  handleShowMenu,
  onClick,
}) => {
  return (
    <div className={containerCN(containerClassName)}>
      <button
        type="button"
        className={`${buttonCN} ${dropdownItems && "justify-between"}`}
        disabled={disabled}
        onClick={dropdownItems ? () => handleShowMenu() : onClick}
      >
        <>
          {iconLeft && (
            <span className={iconCN(iconClassName)}>{iconLeft}</span>
          )}
        </>
      </button>
    </div>
  );
};

ContactBaseButton.propTypes = {
  /**
   * the handler called when the button is clicked
   */
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  /**
   * JSX to display on the left side of the button
   */
  iconLeft: PropTypes.element,
  /**
   * array of options to show on click, if passed
   * if this prop exists, a dropdown arrow will be added to the button
   */
  dropdownItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      className: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  iconClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  handleShowMenu: PropTypes.func,
};

ContactBaseButton.defaultProps = {
  iconClassName: undefined,
  disabled: false,
  iconLeft: undefined,
  dropdownItems: undefined,
  containerClassName: undefined,
  handleShowMenu: undefined,
};

export default ContactBaseButton;

/* eslint-disable import/prefer-default-export */
import { omit, omitBy } from "lodash";
import moment from "moment";
import { budgetTypeMap, PROJECT_STATUS_TYPES } from "../constants";
import { formatNumber, removeUndefinedOrEmptyFields } from "./Formatters";

/**
 * takes liveBudget and adjustment values
 * @param liveBudget
 * @param arithmeticUnit
 * @param adjustmentUnit
 * @param adjustment
 * @returns adjusted total for a line item
 */
export const computeAdjustedTotal = (
  liveBudget,
  arithmeticUnit,
  adjustmentUnit,
  adjustment
) => {
  const total = parseFloat(liveBudget || 0);
  if (arithmeticUnit && adjustmentUnit && adjustment) {
    let difference = 0;
    const adjustmentValue = parseFloat(adjustment);

    switch (adjustmentUnit) {
      case "%": {
        difference = (adjustmentValue / 100) * total;
        break;
      }
      default: {
        difference = adjustmentValue;
      }
    }

    return arithmeticUnit === "+" ? total + difference : total - difference;
  }

  return total;
};

export const parseCSICodes = (
  financials,
  setter,
  revenueAccountingCodes,
  expenseAccountingCodes
) => {
  const csiCodesList = {};

  financials?.csiCodeMapping?.forEach((div) => {
    div.csiCodes.forEach((csi) => {
      csi.subCodes.forEach((sub) => {
        let description;
        if (sub.revenueCode) {
          description = revenueAccountingCodes?.find(
            (revenueCode) => revenueCode.code === sub?.revenueCode
          )?.description;
        } else if (sub.expenseCode) {
          description = expenseAccountingCodes?.find(
            (expenseCode) => expenseCode.code === sub?.expenseCode
          )?.description;
        } else {
          description = sub?.description;
        }

        csiCodesList[`${sub.id}`] = description;
      });
    });
  });
  setter(csiCodesList);
};

export const getCSICodeDescription = (
  financialCode,
  csiCodeMapping,
  revenueAccountingCodes,
  expenseAccountingCodes
) => {
  // match div, csi and subcode
  // looking for revenue and expense
  // if found use their description, else use subcode's description
  let description = "";
  csiCodeMapping?.forEach((div) => {
    if (div.division === financialCode?.division) {
      div.csiCodes.forEach((csi) => {
        if (csi.code === financialCode?.code) {
          csi.subCodes.forEach((sub) => {
            if (sub.code === financialCode?.subcode) {
              if (sub.revenueCode) {
                description = revenueAccountingCodes?.find(
                  (revenueCode) => revenueCode.code === sub?.revenueCode
                )?.description;
              } else if (sub.expenseCode) {
                description = expenseAccountingCodes?.find(
                  (expenseCode) => expenseCode.code === sub?.expenseCode
                )?.description;
              } else {
                description = sub.description;
              }
            }
          });
        }
      });
    }
  });

  return description;
};

export const formatLineitem = (
  li,
  divisionsObject,
  csiCodeMappingObject,
  groups
) => {
  let csiCode = `${li?.financialCode?.division} ${li?.financialCode?.code} ${li?.financialCode?.subcode}`;

  if (csiCodeMappingObject) {
    csiCode = `${csiCode} - ${
      csiCodeMappingObject?.[
        `${li?.financialCode?.division} ${li?.financialCode?.code} ${li?.financialCode?.subcode}`
      ]
    }`;
  }
  return {
    ...li,
    date: li?.metadata?.lastUpdated || li.modifiedDate,
    costPerUnit: parseFloat(li.costPerUnit),
    adjustment: {
      amount: parseFloat(li?.adjustment?.amount || 0),
      arithmeticUnit: li?.adjustment?.arithmeticUnit,
      adjustmentUnit: li?.adjustment?.adjustmentUnit,
    },
    group: groups?.find((group) => group.value === li?.group),
    groupName: groups?.find((group) => group.value === li?.group)?.label,
    csiCodeDivision: divisionsObject?.[li?.financialCode?.division],
    csiCode,
    highRangeCalculated: computeAdjustedTotal(
      (li?.highRange?.costPerUnit || 0) * (li?.highRange?.quantity || 0),
      li?.highRange?.adjustment?.arithmeticUnit,
      li?.highRange?.adjustment?.adjustmentUnit,
      li?.highRange?.adjustment?.amount
    ),
  };
};

export const isSameFinancialCode = (financialCode1, financialCode2) => {
  const code1 = `${financialCode1?.division} ${financialCode1?.code} ${financialCode1?.subcode}`;
  const code2 = `${financialCode2?.division} ${financialCode2?.code} ${financialCode2?.subcode}`;
  return code1 === code2;
};

const formatLineitemForCsv = (
  li,
  unitsOfMeasure,
  isDraftProject,
  isFixedFirmBudgetType,
  isCostPlusBudgetType
) => ({
  "CSI Code": li?.csiCode,
  Description: li?.description,
  Location: li?.buildingName,
  Space: li?.spaceName,
  Group: li?.groupName,
  Notes: li?.notes,
  Vendor: li?.vendorName,
  "Unit of Measure": unitsOfMeasure?.find(
    (unit) => unit.value === li?.unitOfMeasure
  )?.label,
  QTY: li?.quantity,
  "Cost Per": li?.costPerUnit,
  Adjustment: `${li?.adjustment?.amount} ${li?.arithmeticUnit || ""}`,
  [`${isDraftProject ? "Low Range" : "Live Budget"}`]: li?.liveBudget,
  [`${isDraftProject ? "High/Low Variance" : "Budget Variance"}`]:
    li?.budgetVariance,
  [`${isDraftProject ? "High Range" : "Published Budget"}`]: isDraftProject
    ? li?.highRangeCalculated
    : li?.publishedBudget,
  ...(!isDraftProject && {
    Billable: li?.isBillable ? "Yes" : "No",
    Allowance: li?.allowance ? "Yes" : "No",
    PA: li?.purchaseAuthorization?.length
      ? li?.purchaseAuthorization?.reduce(
          (previousValue, currentValue) =>
            `${previousValue + currentValue?.name}, `,
          ""
        )
      : undefined,
    "PA Total": li?.paTotal,
    "PA Total (pending)": li?.paTotalPending,
    "Purchase Variance": li?.purchaseVariance,
    "Purchase Variance %": li?.purchaseVariancePercentage,
    "Items Not Yet Purchased": li?.itemsNotYetPurchased,
    CA: li?.contingencyAuthorization?.length
      ? li?.contingencyAuthorization?.reduce(
          (previousValue, currentValue) =>
            `${previousValue + currentValue?.name}, `,
          ""
        )
      : undefined,
    "CA Total": li?.caTotal,
    "CA Total (pending)": li?.caTotalPending,
    CO: li?.changeOrder?.length
      ? li?.changeOrder?.reduce(
          (previousValue, currentValue) =>
            `${previousValue + currentValue?.name}, `,
          ""
        )
      : undefined,
    "CO Total": li?.coTotal,
    "CO Total (pending)": li?.coTotalPending,
    [`${isFixedFirmBudgetType ? "Updated Fixed Firm" : "Updated GMP"}`]:
      li?.updatedGmpOrFixedFirm,
    ...(isCostPlusBudgetType && {
      "Updated GMP (pending)": li?.updatedGmpPending,
    }),
    "Commited Value": li?.commitedValue,
    "% Complete of Committed Value": li?.percentCompleteOfCommittedValue,
    "Uncommitted Value": li?.uncommittedValue,
    "Uncommitted Value (pending)": li?.uncommittedValuePending,
    [`${
      isCostPlusBudgetType ? "Work Invoiced to Date" : "Work Completed to Date"
    }`]: li?.workCompletedToDate,
    [`${
      isCostPlusBudgetType
        ? "Work Completed Previous Invoice"
        : "Work Completed Previous Period"
    }`]: li?.workCompletedPreviousPeriod,
    "Paid to Date": li?.paidToDate,
    "Work Completed This Period": li?.workCompletedThisPeriod,
    Retainage: li?.retainage,
    "Retainage %": li?.retainagePercentage,
    "Retainage Released this Period": li?.retainedReleasedThisPeriod,
    "Balance to Finish": li?.balanceToFinish,
    "% Complete of Budget": li?.percentCompleteOfBudget,
    [`${isFixedFirmBudgetType ? "Close-out" : "Holdback"}`]: li?.holdback,
    "Schedule %": li?.schedulePercentage,
    "Date Modified": moment(li?.date).format("dddd, MMMM Do YYYY, h:mm:ss a"),
    PO: li?.purchaseOrder?.length
      ? li?.purchaseOrder?.reduce(
          (previousValue, currentValue) =>
            `${previousValue + currentValue?.name}, `,
          ""
        )
      : undefined,
    RFP: li?.requestForProposal?.length
      ? li?.requestForProposal?.reduce(
          (previousValue, currentValue) =>
            `${previousValue + currentValue?.name}, `,
          ""
        )
      : undefined,
    RFI: li?.requestForInformation?.length
      ? li?.requestForInformation?.reduce(
          (previousValue, currentValue) =>
            `${previousValue + currentValue?.name}, `,
          ""
        )
      : undefined,
  }),
});

export const formatGroupDropdownForBudgetTable = (groups) => {
  const options = groups.map((grp) => ({
    label: grp.name,
    value: grp.id,
  }));

  // put the "Create New option first"
  options.unshift({
    label: "Create New...",
    value: "create",
  });

  return options;
};

const publishBudgetCsvFileColumns = (
  isDraftProject,
  isFixedFirmBudgetType,
  isCostPlusBudgetType
) => {
  return [
    "CSI Code",
    "Description",
    "Location",
    "Group",
    "Notes",
    "Vendor",
    "Unit of Measure",
    "QTY",
    "Cost Per",
    "Adjustment",
    isDraftProject ? "Low Range" : "Live Budget",
    isDraftProject ? "High/Low Variance" : "Budget Variance",
    isDraftProject ? "High Range" : "Published Budget",
    ...(!isDraftProject
      ? [
          "Billable",
          "Allowance",
          "PA",
          "PA Total",
          "PA Total (pending)",
          "Purchase Variance",
          "Purchase Variance %",
          "Items Not Yet Purchased",
          "CA",
          "CA Total",
          "CA Total (pending)",
          "CO",
          "CO Total",
          "CO Total (pending)",
          isFixedFirmBudgetType ? "Updated Fixed Firm" : "Updated GMP",
          ...(isCostPlusBudgetType ? ["Updated GMP (pending)"] : []),
          "Commited Value",
          "% Complete of Committed Value",
          "Uncommitted Value",
          "Uncommitted Value (pending)",
          isCostPlusBudgetType
            ? "Work Invoiced to Date "
            : "Work Completed to Date",
          isCostPlusBudgetType
            ? "Work Completed Previous Invoice"
            : "Work Completed Previous Period",
          "Paid to Date",
          isCostPlusBudgetType
            ? "Work Completed This Invoice"
            : "Work Completed This Period",
          "Retainage",
          "Retainage %",
          "Retainage Released this Period",
          "Balance to Finish",
          "% Complete of Budget",
          isFixedFirmBudgetType ? "Close-out" : "Holdback",
          "Schedule %",
          "Date Modified",
          "PO",
          "RFP",
          "RFI",
        ]
      : []),
  ];
};

export const getAggregatedTotal = (tableRow, field) => {
  if (tableRow?.subRows?.length) {
    const total = tableRow.subRows.reduce(
      (sum, n) => sum + formatNumber(n.original?.[field]),
      0
    );
    return total;
  }
  return tableRow.original[field];
};

export const getRowsAndColumsForCsv = ({
  originalBudgetLineItems,
  project,
  budgetType,
  unitsOfMeasure,
}) => {
  const isDraftProject = project?.status === PROJECT_STATUS_TYPES.draft;
  const isFixedFirmBudgetType = budgetType?.label === budgetTypeMap.fixedFirm;
  const isCostPlusBudgetType = budgetType?.label === budgetTypeMap.costPlus;

  const formattedLineItems = originalBudgetLineItems.map((li) =>
    formatLineitemForCsv(
      li,
      unitsOfMeasure,
      isDraftProject,
      isFixedFirmBudgetType,
      isCostPlusBudgetType
    )
  );

  const columns = publishBudgetCsvFileColumns(
    isDraftProject,
    isFixedFirmBudgetType,
    isCostPlusBudgetType
  );

  return { formattedLineItems, columns };
};

export const getUserData = (allUsers, reference) => {
  if (!reference) {
    return "";
  }
  const found = allUsers?.find((user) => user.reference === reference);
  return found;
};

export const publishBudgetModalText =
  "By publishing the budget external project members will be able to see changes, are you sure you want to publish?";
export const lockGMPModalText =
  "Buy locking GMP for the project budget, you will no longer be able to modify your live budget. All changes must occur via financial documents to include new line items. This action can not be undone once a financial document has been created after you lock the GMP.";
export const lockFixedFirmModalText =
  "Buy locking fixed firm for the project budget, you will no longer be able to modify your live budget. All changes must occur via financial documents to include new line items. This action can not be undone once a financial document has been created after you lock fixed firm";
export const unlockLineitemText = (name) =>
  `Are you sure you want to override the existing lock owned by ${name}?  Any edits being made by them will be lost, forcing them to refresh.`;

export const cleanFieldsForBulkUpdate = (budgetLine, adjustTotal) => {
  const lineitemEdits = { ...budgetLine };
  if (
    lineitemEdits?.adjustment?.amount === "" ||
    lineitemEdits?.adjustment?.amount === "0" ||
    !lineitemEdits?.adjustment?.adjustmentUnit ||
    !lineitemEdits?.adjustment?.arithmeticUnit ||
    !adjustTotal
  ) {
    lineitemEdits.adjustment = {
      amount: 0,
      adjustmentUnit: undefined,
      arithmeticUnit: undefined,
    };
  }

  if (lineitemEdits?.group?.value) {
    lineitemEdits.group = lineitemEdits?.group?.value;
  }

  let cleanObj = removeUndefinedOrEmptyFields(lineitemEdits);

  if (!budgetLine.isBillable) {
    cleanObj.isBillable = false;
  } else {
    cleanObj = omit(cleanObj, "isBillable");
  }

  cleanObj = omitBy(
    cleanObj,
    (value) => value === Object(value) && Object.keys(value).length === 0
  );

  return cleanObj;
};

export const isContingencyLineItem = (lineitem) =>
  lineitem?.financialCode?.division === "50" &&
  lineitem?.financialCode?.code === "10" &&
  lineitem?.financialCode?.subcode === "00";

import { ConfigurationAPI } from "@griffingroupglobal/eslib-api";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { configurationKeys } from "../config/reactQuery/queryKeyFactory";

const fetchTemplates = async () => {
  const { data } = await ConfigurationAPI.getById("templates");
  return data;
};

/**
 * Query hook to fetch templates
 * @param {Function} select (?) Custom function selector
 */
const useTemplatesConfiguration = (select) => {
  return useAuthenticatedQuery({
    queryKey: configurationKeys("templates"),
    queryFn: fetchTemplates,
    select,
    onError: (error) => console.error("useTemplatesConfiguration", error),
  });
};

export default useTemplatesConfiguration;

// APIs
import moment from "moment";
import PropTypes from "prop-types";
import { capitalize } from "lodash";

// Framework Tools
import React, { useEffect, useState } from "react";

// Components
import Widget from "../Widget/Widget";
import RequestInfoContainer from "../RequestInfoContainer/RequestInfoContainer";
import { WF_STATUS_TYPES } from "../../../constants";
import RequestGridViewDistroListCell from "../Requests/RequestGridView/RequestGridViewDistroListCell";
import { capitalized, getFullName } from "../../../helpers/Formatters";
import Avatar from "../Message/Avatar";
import AssociationLink from "../AssociationLink";

// helpers

const WorkflowDetails = ({
  workflow,
  loading,
  association,
  workflowForm,
  workflowFormDispatch,
  isDraft,
  editing,
  projectDD,
  propertyDD,
  handleDistroList,
  handleArtifactsList,
  associationMembers,
  ballInCourt,
  initiated,
  workflowDDOptions,
  handleTargetCompletion,
  handleProjectedCompletion,
  distroList,
  workflowHistory,
  commentMap,
}) => {
  const [completedComment, setCompletedComment] = useState();

  const isFreeFlow = workflowForm?.requestWorkflow?.[0]?.isFreeFlow;

  useEffect(() => {
    const resolvedHistoryComment = workflowHistory?.[workflow?.round]?.find(
      (historyItem) => !!historyItem?.context?.workflow?.resolved?.user
    )?.context?.comment?.reference;

    setCompletedComment(commentMap?.[resolvedHistoryComment]?.content);
  }, [commentMap, workflow?.round, workflowHistory]);

  let associatedResource = {};
  if (association?.resource === "Project") {
    associatedResource = {
      title: "Project",
      value: association?.name,
      associationDD: projectDD,
      ddValue: { label: association?.name, value: association?.reference },
    };
  } else {
    associatedResource = {
      title: "Property",
      value: association?.title,
      associationDD: propertyDD,
      ddValue: {
        label: association?.title,
        value: association?.reference,
      },
    };
  }

  return (
    <div className="gap-10 grid">
      {/* Many columns and rows will have a truthy value, when editing it uses the submittalForm otherwise use the loaded workflow */}
      <Widget
        title="Workflow Information"
        draggable={false}
        loading={loading}
        overflow
        className="h-min"
      >
        <RequestInfoContainer
          columns={[
            {
              rows: [
                {
                  title: associatedResource.title,
                  value: (
                    <AssociationLink
                      associationName={association?.name || association?.title}
                      associationRef={workflow?.association}
                    />
                  ),
                  form: workflowForm,
                  dispatch: workflowFormDispatch,
                  editing: {
                    isEditing: isDraft && editing,
                    isDraft,
                    value: {
                      field: "association",
                      options: associatedResource.associationDD,
                      type: "Dropdown",
                      editingValue: associatedResource.ddValue,
                    },
                    disabled: true,
                  },
                },
                {
                  form: workflowForm,
                  dispatch: workflowFormDispatch,
                  title: "Distribution",
                  value: editing ? handleDistroList() : distroList,
                  distroList: true,
                  distroListOptions: associationMembers,
                  editing: {
                    isEditing: isDraft && editing,
                    isDraft,
                    value: {
                      options: associationMembers?.filter(
                        (item) =>
                          !workflowForm?.distribution?.includes(item?.value)
                      ),
                      type: "distroDropdown",
                    },
                  },
                },
                ...(workflow?.status !== WF_STATUS_TYPES?.DRAFT &&
                workflow?.status !== WF_STATUS_TYPES?.COMPLETED_OPEN &&
                workflow?.status !== WF_STATUS_TYPES?.COMPLETED_RESOLVED
                  ? [
                      {
                        customElementCN: "relative flex flex-wrap w-2/3",
                        title: "Ball In Court",
                        value: (
                          <RequestGridViewDistroListCell
                            hideTile
                            distroList={ballInCourt}
                            hideDeleteIcon
                          />
                        ),
                      },
                    ]
                  : []),
                {
                  title: "Description",
                  value: editing
                    ? workflowForm?.description
                    : workflow?.description,
                  form: workflowForm,
                  dispatch: workflowFormDispatch,
                  editing: {
                    isEditing: editing,
                    isDraft,
                    value: {
                      field: "description",
                      fieldValue: workflowForm?.description,
                      type: "Input",
                      isTextarea: true,
                    },
                  },
                  last: true,
                },
              ],
            },
            {
              rows: [
                ...(workflow?.status !== WF_STATUS_TYPES?.DRAFT
                  ? [
                      {
                        title: "Initiated By",
                        value: (
                          <div className="flex">
                            {initiated?.name && (
                              <>
                                <Avatar
                                  className="w-10 h-10 text-xs mr-1"
                                  isExternalUser
                                  id={initiated?.id}
                                  avatar={initiated?.avatar}
                                />
                                <p className="flex items-center">
                                  {getFullName(initiated?.name)} on
                                  {` ${moment(initiated?.date).format(
                                    "MMMM, DD, YYYY"
                                  )}`}
                                </p>
                              </>
                            )}
                          </div>
                        ),
                      },
                    ]
                  : []),
                ...(workflow?.status !== WF_STATUS_TYPES?.DRAFT &&
                workflow?.status !== WF_STATUS_TYPES?.IN_PROGRESS
                  ? [
                      {
                        title: "Vendor",
                        value: capitalize(initiated?.companyName),
                      },
                    ]
                  : []),

                /* ** Hiding Budget Reference field until Budget is complete ** */

                // {
                //   title: "Budget Reference",
                //   form: workflowForm,
                //   dispatch: workflowFormDispatch,
                //   value: editing
                //     ? workflowForm?.budget?.description
                //     : workflow?.budget?.description,
                //   editing: {
                //     isEditing: editing,
                //     isDraft,
                //     value: {
                //       field: "budgetDescription",
                //       fieldValue: workflowForm?.budget?.description,
                //       type: "Input",
                //     },
                //   },
                // },

                /* ** Hiding Schedule Reference field until Budget is complete ** */

                // {
                //   title: "Schedule Reference",
                //   form: workflowForm,
                //   dispatch: workflowFormDispatch,
                //   value: editing
                //     ? workflowForm?.schedule?.description
                //     : workflow?.schedule?.description,
                //   editing: {
                //     isEditing: editing,
                //     isDraft,
                //     value: {
                //       field: "scheduleDescription",
                //       fieldValue: workflowForm?.schedule?.description,
                //       type: "Input",
                //     },
                //   },
                // },
                {
                  title: "Associations",
                  value: handleArtifactsList(),
                  associations: true,
                  form: workflowForm,
                  dispatch: workflowFormDispatch,
                  editing: {
                    isEditing: isDraft && editing,
                    isDraft,
                    value: {
                      field: "associatedArtifacts",
                      options: workflowDDOptions?.filter(
                        (option) =>
                          !option?.isTemplate &&
                          !workflowForm?.associatedArtifacts?.includes(
                            option?.value
                          )
                      ),
                      type: "Dropdown",
                    },
                  },
                  last: true,
                },
              ],
            },
          ]}
        />
      </Widget>
      <div className="w-full h-min">
        <Widget
          overflow
          title="Timing"
          loading={loading}
          draggable={false}
          className="h-min"
        >
          <RequestInfoContainer
            columns={[
              {
                rowWrapperCN: "grid grid-cols-2 gap-x-12",
                rows: [
                  {
                    title: "Start Date",
                    value: moment(workflowForm?.startDate?.actual).format(
                      "MMMM DD, YYYY"
                    ),
                    form: workflowForm,
                    dispatch: workflowFormDispatch,
                    editing: {
                      isEditing: isDraft && editing,
                      isDraft,
                      value: {
                        field: "StartDate",
                        type: "Date",
                        display: moment(workflowForm?.startDate?.actual).format(
                          "MMMM DD, YYYY"
                        ),
                        value: workflowForm?.startDate?.actual,
                        minDate: new Date(),
                      },
                    },
                    // style row not as firs row
                    ignoreFirst: true,
                  },
                  {
                    title: "Target Completion",
                    value:
                      /* if in edit mode and workflow is draft this field can change */
                      editing && isDraft
                        ? handleTargetCompletion()
                        : moment(workflow?.completion?.target).format(
                            "MMMM DD, YYYY"
                          ),
                    form: workflowForm,
                    dispatch: workflowFormDispatch,
                    editing: {
                      isEditing: false,
                      isDraft,
                      value: {
                        field: "CompletionTargetDate",
                        type: "Date",
                        display: moment(workflow?.completion?.target).format(
                          "MMMM DD, YYYY"
                        ),
                        value: workflowForm?.completion?.target,
                      },
                    },
                  },
                  {
                    title: "Projected Completion",
                    value:
                      /* if in edit mode and workflow is draft this field can change */
                      // eslint-disable-next-line no-nested-ternary
                      editing && isDraft && !isFreeFlow
                        ? handleProjectedCompletion()
                        : isFreeFlow
                        ? "N/A"
                        : moment(workflow?.completion?.projected).format(
                            "MMMM DD, YYYY"
                          ),
                    form: workflowForm,
                    dispatch: workflowFormDispatch,
                    editing: {
                      isEditing: false,
                      isDraft,
                      value: {
                        field: "CompletionProjectedDate",
                        type: "Date",
                        display: isFreeFlow
                          ? "N/A"
                          : moment(workflow?.completion?.projected).format(
                              "MMMM DD, YYYY"
                            ),
                        value: isFreeFlow
                          ? "N/A"
                          : workflowForm?.completion?.projected,
                      },
                    },
                    last: true,
                  },
                  {
                    title: "Duration",
                    value: `${workflow?.duration?.numDays} ${
                      workflow?.duration?.numDays === 1 ? "Day" : "Days"
                    }`,
                    last: true,
                  },
                ],
              },
            ]}
          />
        </Widget>
      </div>
      <div className="w-full h-min">
        {workflow?.status?.includes("completed") && (
          <Widget
            title="Impact"
            loading={loading}
            draggable={false}
            className="h-min"
          >
            <RequestInfoContainer
              columns={[
                {
                  rows: [
                    {
                      title: "Results",
                      value: capitalized(workflow?.status?.split("-")[1]),
                    },
                    {
                      title: "Schedule Impact",
                      value: workflow?.impacts?.schedule?.status,
                      impactValue: workflow?.impacts?.schedule?.value,
                    },
                    {
                      title: "Budget Impact",
                      value: workflow?.impacts?.budget?.status,
                      impactValue: workflow?.impacts?.budget?.value,
                    },
                    {
                      title: "Comments",
                      value: completedComment,
                      last: true,
                    },
                  ],
                },
              ]}
            />
            <div />
          </Widget>
        )}
      </div>
    </div>
  );
};

WorkflowDetails.propTypes = {
  workflow: PropTypes.shape({
    round: PropTypes.string,
    association: PropTypes.string,
    status: PropTypes.string,
    description: PropTypes.string,
    schedule: PropTypes.shape({
      description: PropTypes.string,
    }),
    startDate: PropTypes.shape({
      actual: PropTypes.string,
    }),
    budget: PropTypes.shape({
      description: PropTypes.string,
    }),
    completion: PropTypes.shape({
      target: PropTypes.string,
      projected: PropTypes.string,
    }),
    duration: PropTypes.shape({
      numDays: PropTypes.string,
    }),
    impacts: PropTypes.shape({
      budget: PropTypes.shape({
        status: PropTypes.string,
        value: PropTypes.string,
      }),
      schedule: PropTypes.shape({
        status: PropTypes.string,
        value: PropTypes.string,
      }),
    }),
  }),
  loading: PropTypes.bool,
  association: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    reference: PropTypes.string,
    resource: PropTypes.string,
  }),
  workflowForm: PropTypes.shape({
    distribution: PropTypes.string,
    description: PropTypes.string,
    schedule: PropTypes.shape({
      description: PropTypes.string,
    }),
    budget: PropTypes.shape({
      description: PropTypes.string,
    }),
    completion: PropTypes.shape({
      target: PropTypes.string,
      projected: PropTypes.string,
    }),
    startDate: PropTypes.shape({
      actual: PropTypes.string,
    }),
    requestWorkflow: PropTypes.arrayOf(
      PropTypes.shape({
        isFreeFlow: PropTypes.bool,
      })
    ),
    associatedArtifacts: PropTypes.arrayOf(PropTypes.string),
  }),
  workflowFormDispatch: PropTypes.func,
  isDraft: PropTypes.bool,
  editing: PropTypes.bool,
  projectDD: PropTypes.arrayOf(PropTypes.shape({})),
  propertyDD: PropTypes.arrayOf(PropTypes.shape({})),
  handleDistroList: PropTypes.func,
  handleArtifactsList: PropTypes.func,
  associationMembers: PropTypes.arrayOf(PropTypes.shape({})),
  ballInCourt: PropTypes.shape({
    name: PropTypes.string,
  }),
  initiated: PropTypes.shape({
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    date: PropTypes.string,
    companyName: PropTypes.string,
    id: PropTypes.string,
    avatar: PropTypes.string,
    kind: PropTypes.string,
  }),
  workflowDDOptions: PropTypes.arrayOf(PropTypes.shape({})),
  handleTargetCompletion: PropTypes.func,
  handleProjectedCompletion: PropTypes.func,
  distroList: PropTypes.arrayOf(PropTypes.shape({})),
  workflowHistory: PropTypes.shape({}),
  commentMap: PropTypes.shape({}),
};

WorkflowDetails.defaultProps = {
  workflow: undefined,
  loading: false,
  association: {},
  workflowForm: undefined,
  workflowFormDispatch: undefined,
  isDraft: false,
  editing: false,
  projectDD: [],
  propertyDD: [],
  handleDistroList: undefined,
  handleArtifactsList: undefined,
  associationMembers: [],
  ballInCourt: undefined,
  initiated: undefined,
  workflowDDOptions: [],
  handleTargetCompletion: undefined,
  handleProjectedCompletion: undefined,
  distroList: [],
  workflowHistory: {},
  commentMap: undefined,
};

export default WorkflowDetails;

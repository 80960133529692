import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import DetailCard from "../DetailCard/DetailCard";
import placeholderImage from "../../assets/images/placeholderImage.png";
import TertiaryButton from "../Buttons/TertiaryButton";
import GetStartedCircle from "../GetStartedCircle/GetStartedCircle";

const BuildingsView = ({
  buildings,
  onFinishEditing,
  setEditBuildingId,
  dispatch,
  isLoading,
  disableEditing,
}) => {
  const onAddBuilding = () => {
    const id = uuidv4();
    dispatch({
      type: "addBuilding",
      buildingId: id,
    });
    setEditBuildingId(id);
  };

  return (
    <>
      {!!buildings?.length && (
        <div className="flex flex-col">
          <div className="flex flex-wrap">
            {buildings?.map((building) => (
              <div className="pr-4 -ml-2.5" key={building?.id}>
                <DetailCard
                  title={building?.name}
                  type="LOCATION"
                  onChange={() => {}}
                  onFinishEditing={onFinishEditing}
                  Component={
                    <div className="flex flex-col">
                      <div className="pt-1 pb-3">
                        <p className="text-xs text-gray-200 font-semibold">
                          {`${building?.spaces?.length || 0}${
                            building?.spaces?.length === 1
                              ? " space"
                              : " spaces"
                          }`}{" "}
                          <span className="text-xs text-gray-200 font-normal">{`- ${
                            building?.area?.value || 0
                          }  sq ft`}</span>
                        </p>
                      </div>
                      <img
                        className="h-24 object-cover w-40 rounded border border-gray-200"
                        src={
                          building?.images?.find((img) => img?.isPrimary)
                            ?.thumbnailUrl || placeholderImage
                        }
                        alt={building?.primaryImage?.name || "placeholder"}
                      />
                    </div>
                  }
                  cardWidth="w-60"
                  cardHeight="h-42"
                  customClassName="border-2 border-white px-3 pt-3 pb-3"
                  noBorder
                  hoverClassName="border-2 border-brandGreen h-42"
                  titleClassName="text-black font-semibold truncate"
                  setEditBuildingId={setEditBuildingId}
                  editBuildingId={building?.id}
                  hideEditButton={isLoading || disableEditing}
                />
              </div>
            ))}
          </div>
          <TertiaryButton
            title="+ Add Location"
            onClick={onAddBuilding}
            className="mt-6 mb-4"
            disabled={disableEditing || isLoading}
          />
        </div>
      )}
      {!buildings?.length && (
        <GetStartedCircle
          title="Locations"
          className="mx-auto"
          onGetStartedClicked={onAddBuilding}
          disabled={disableEditing || isLoading}
        />
      )}
    </>
  );
};

BuildingsView.propTypes = {
  onFinishEditing: PropTypes.func,
  buildings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      area: PropTypes.shape({
        value: PropTypes.string,
      }),
      primaryImage: PropTypes.shape({
        thumbnailUrl: PropTypes.string,
        name: PropTypes.string,
      }),
      spaces: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    })
  ),
  setEditBuildingId: PropTypes.func,
  isLoading: PropTypes.bool,
  dispatch: PropTypes.func,
  disableEditing: PropTypes.bool,
};

BuildingsView.defaultProps = {
  onFinishEditing: undefined,
  buildings: [],
  setEditBuildingId: undefined,
  isLoading: false,
  dispatch: undefined,
  disableEditing: false,
};

export default BuildingsView;

const sortAndGroupByFieldType = (attributes) => {
  const groupedAttributes = {};

  // Group the attributes by fieldType
  attributes.forEach((attribute) => {
    const { fieldType } = attribute;
    if (!groupedAttributes[fieldType]) {
      groupedAttributes[fieldType] = [];
    }
    groupedAttributes[fieldType].push(attribute);
  });

  // Sort the groups alphabetically
  const sortedGroups = Object.keys(groupedAttributes).sort();

  // create an array of objects with the sorted groups
  const sortedAttributes = sortedGroups.map((group) => {
    return {
      group,
      attributes: groupedAttributes[group],
    };
  });

  const sortedAttributesFlat = sortedAttributes
    .map((attribute) => attribute.attributes)
    .flat();

  return sortedAttributesFlat;
};

export default sortAndGroupByFieldType;

import React, { useState } from "react";
import { FileAPI } from "@griffingroupglobal/eslib-api";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import BaseButton from "../Buttons/BaseButton";
import crossIcon from "../../assets/images/Close.svg";
import fileIconDarkGreen from "../../assets/images/fileIcon-dark-green.svg";
import IconButton from "../Buttons/IconButton";
import notesIconDarkGreen from "../../assets/images/notes-icon-dark-green.svg";
import { downloadMedia } from "../../../helpers/File";

const ReceiptPopup = ({ value, title, downloadable, absolutePosition }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchFiles = async () => {
    setIsLoading(true);
    const filesArray = await Promise.allSettled(
      value?.map(async (r) => {
        const fileId = r?.ref?.split("/")[1];
        const { data: fileData } = await FileAPI.getById(fileId);
        const { name, contentType, contentsUrl, category, reference } =
          fileData;
        return {
          name,
          contentType,
          contentsUrl,
          category,
          reference,
        };
      })
    );
    const tempFilesArr = filesArray
      .filter((f) => f.status === "fulfilled")
      .map((f) => f.value);
    setFiles(tempFilesArr);
    setIsLoading(false);
  };

  const handleDownload = async (file) => {
    await downloadMedia([file?.reference]);
  };

  return (
    <>
      <IconButton
        title={title}
        innerClassName="text-xs text-brandDarkGreen"
        icon={notesIconDarkGreen}
        onClick={() => {
          setShowPopup((prev) => !prev);
          if (showPopup === false) {
            fetchFiles();
          }
        }}
      />

      {showPopup && (
        <div
          className={`${
            absolutePosition || "top-2 left-11"
          } rounded border border-gray-150 z-20 bg-white absolute p-4 w-72  max-h-24 overflow-hidden overflow-y-scroll`}
        >
          {isLoading && <p>Loading...</p>}
          <div className="flex justify-between items-center">
            <p className="font-semibold text-sm">Receipts</p>
            <BaseButton
              onClick={() => {
                setShowPopup(false);
              }}
              iconLeft={
                <img
                  src={crossIcon}
                  alt="crossIcon"
                  style={{ height: 8, width: 10 }}
                />
              }
            />
          </div>

          {!isLoading &&
            files?.map((file) => {
              return (
                <div
                  key={uuidv4()}
                  className="flex flex-row items-center my-2"
                  tabIndex="0"
                  role="button"
                >
                  <img
                    src={fileIconDarkGreen}
                    alt="fileIcon"
                    style={{ height: 8, width: 10 }}
                  />
                  <div className="font-semibold text-gray-300 text-xs truncate ml-1">
                    {downloadable && (
                      <button
                        type="button"
                        className="font-semibold text-gray-300 text-xs truncate"
                        onClick={() => handleDownload(file)}
                      >
                        {file?.name}
                      </button>
                    )}
                    {!downloadable && (
                      <a
                        href={file.contentsUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {file?.name}
                      </a>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

ReceiptPopup.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  downloadable: PropTypes.bool,
  absolutePosition: PropTypes.string,
};

ReceiptPopup.defaultProps = {
  value: undefined,
  title: undefined,
  downloadable: false,
  absolutePosition: undefined,
};

export default ReceiptPopup;

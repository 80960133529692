import ReactModal from "react-modal";
import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import PDFViewer from "./PDFViewer";
import TertiaryButton from "../Buttons/TertiaryButton";

const PDFViewerModal = ({ initialUrl, isOpen, onClose }) => {
  const floatingButtonCN = cntl`
    flex
    items-center
    absolute
    bottom-24
    right-24
    w-32
    h-16
    border-2
    border-gray-100
    rounded-2xl
    bg-white
    z-20
  `;

  const modalStyles = {
    content: {
      inset: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "white",
      borderColor: "#1DC29D",
      borderWidth: "8px",
      overflowX: "hidden",
    },
    overlay: {
      zIndex: "50",
    },
  };
  return (
    <ReactModal
      style={modalStyles}
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
    >
      <div className="flex flex-col w-full h-full absolute z-30 overflow-y-unset">
        <div className="flex-grow overflow-y-auto">
          <PDFViewer showSideBar initialUrl={initialUrl} />
        </div>
        <div className={floatingButtonCN}>
          <TertiaryButton className="" title="Cancel" onClick={onClose} />
        </div>
      </div>
    </ReactModal>
  );
};

PDFViewerModal.propTypes = {
  /**
   * url of the pdf being shown
   */
  initialUrl: PropTypes.string,
  /**
   * boolean to show sidebar
   */
  isOpen: PropTypes.bool,
  /**
   * function triggered when this modal is closed
   */
  onClose: PropTypes.func,
};

PDFViewerModal.defaultProps = {
  initialUrl: undefined,
  isOpen: undefined,
  onClose: undefined,
};
export default PDFViewerModal;

/**
 * It checks if the user is the owner or an admin/superadmin and whether they have
 * the necessary permissions to update or delete the ticket.
 * @returns {Object} An object containing permission information for the user on the ticket.
 * @returns {boolean} canUpdate - Whether the user can update the ticket.
 * @returns {boolean} canDelete - Whether the user can delete the ticket.
 */
const getTicketPermissions = (currentUser, serviceRequest) => {
  const isOwnerOrAdmin =
    currentUser?.isAdmin || currentUser?.reference === serviceRequest.createdBy;

  const allowToUpdate =
    serviceRequest.status !== "closed" &&
    (!!currentUser?.isAdmin || !!currentUser?.permissions?.ticket?.can_update);

  return {
    canUpdate: isOwnerOrAdmin && allowToUpdate,
    canUpdateMarkCompleteAndPriority: allowToUpdate,
    canDelete: isOwnerOrAdmin && !!currentUser?.permissions?.ticket?.can_delete,
    canAddTasks: allowToUpdate && !!currentUser?.permissions?.task?.can_create,
  };
};

export default getTicketPermissions;

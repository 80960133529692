import Api from "@griffingroupglobal/eslib-api";
import { userKeys } from "../config/reactQuery/queryKeyFactory";
import useAuthenticatedQuery from "./useAuthenticatedQuery";

const fetchUsersWithPermissions = async (permissions = []) => {
  if (permissions.length === 0) return {};

  const { data } = await Api.get(
    `/api/User/$withpermission?permission=${permissions?.join(",")}`
  );

  return data;
};

/**
 * Query hook to fetch users references with given permissions
 * @param {string[]} permissions - array of permissions
 */
const useUsersWithPermissions = (permissions = []) => {
  return useAuthenticatedQuery({
    queryKey: userKeys.withPermissions(permissions),
    queryFn: () => fetchUsersWithPermissions(permissions),
    onError: (error) => {
      console.error("useUsersWithPermissions", error);
    },
  });
};

export default useUsersWithPermissions;

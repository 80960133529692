import { useEffect, useState } from "react";

import { useAppState } from "../state/appState";

export default function useExpenseDocuments() {
  const [{ financialsConfiguration }] = useAppState();
  const [csiCodes, setCsiCodes] = useState([]);

  useEffect(() => {
    if (financialsConfiguration?.financials) {
      const csiCodesFormatted = [];

      financialsConfiguration?.financials?.csiCodeMapping?.forEach((div) => {
        div.csiCodes.forEach((csi) => {
          csi.subCodes.forEach((sub) => {
            const fullCode = `${div.division} ${csi.code} ${sub.code}`;
            const description =
              financialsConfiguration?.financials?.csiCodeMappingObject[
                fullCode
              ];
            const csiCodeFormatted = {
              label: `${fullCode} - ${description}`,
              value: fullCode,
            };
            csiCodesFormatted.push(csiCodeFormatted);
          });
        });
      });
      setCsiCodes(csiCodesFormatted);
    }
  }, [financialsConfiguration]);

  return { csiCodes };
}

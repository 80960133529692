import PropTypes from "prop-types";
import React from "react";
import {
  ALL_EVENT_INSTANCES,
  customModalStyles,
  FUTURE_EVENT_INSTANCES,
  SINGLE_EVENT_INSTANCE,
} from "../../../constants";
import RadioButton from "../Buttons/RadioButton";
import Dropdown from "../Dropdown/Dropdown";
import Modal from "../Modal/Modal";

const { overlayStyle, contentStyle, titleStyle, headerStyle } =
  customModalStyles;

const TaskRecurrenceInstanceModal = ({
  showRecurrenceModal,
  recurrenceAtion,
  onDeleteRecurrenceModalClose,
  handleDeleteRecurrence,
  deleteEventInstance,
  setDeleteEventInstance,
  taskRecurrence,
  setTaskRecurrence,
  recurrenceOptions,
}) => {
  return (
    <Modal
      closeTimeoutMS={200}
      isOpen={showRecurrenceModal}
      title={`${recurrenceAtion} task recurrence instances`}
      onRequestModalClose={onDeleteRecurrenceModalClose}
      primaryButtonTitle="Submit"
      primaryButtonOnClick={handleDeleteRecurrence}
      tertiaryButtonTitle="Cancel"
      hideFooter
      minWidth="400px"
      buttonCnClass="pt-7 mb-1"
      disabled={
        deleteEventInstance === undefined ||
        ((deleteEventInstance === FUTURE_EVENT_INSTANCES ||
          deleteEventInstance === SINGLE_EVENT_INSTANCE) &&
          !taskRecurrence)
      }
      overlayStyle={overlayStyle}
      contentStyle={contentStyle}
      titleStyle={titleStyle}
      headerStyle={headerStyle}
      customHeight="550px"
      alert
    >
      <div className="mb-5 text-sm w-192 h-96">
        <div className="mb-1">
          <RadioButton
            label={`${recurrenceAtion} ALL task instances`}
            isChecked={deleteEventInstance === ALL_EVENT_INSTANCES}
            onChange={() => setDeleteEventInstance(ALL_EVENT_INSTANCES)}
          />
        </div>
        <div className="mb-1">
          <RadioButton
            label={`${recurrenceAtion} a SINGLE instance`}
            isChecked={deleteEventInstance === SINGLE_EVENT_INSTANCE}
            onChange={() => setDeleteEventInstance(SINGLE_EVENT_INSTANCE)}
          />
        </div>
        <div className="mb-1">
          <RadioButton
            label={`${recurrenceAtion} FUTURE instances from a date`}
            isChecked={deleteEventInstance === FUTURE_EVENT_INSTANCES}
            onChange={() => setDeleteEventInstance(FUTURE_EVENT_INSTANCES)}
          />
        </div>
        <div className="mb-1">
          {(deleteEventInstance === FUTURE_EVENT_INSTANCES ||
            deleteEventInstance === SINGLE_EVENT_INSTANCE) && (
            <Dropdown
              tabIndex={0}
              name="taskRecurrence"
              placeholder={`Select recurrence to ${recurrenceAtion}`}
              className="w-full mt-3 mb-0"
              label="Task recurrences"
              options={recurrenceOptions}
              value={taskRecurrence}
              onChange={(val) => setTaskRecurrence(val)}
              disableSort
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

TaskRecurrenceInstanceModal.propTypes = {
  showRecurrenceModal: PropTypes.bool,
  recurrenceAtion: PropTypes.string,
  onDeleteRecurrenceModalClose: PropTypes.func,
  handleDeleteRecurrence: PropTypes.func,
  deleteEventInstance: PropTypes.string,
  setDeleteEventInstance: PropTypes.func,
  taskRecurrence: PropTypes.shape({}),
  setTaskRecurrence: PropTypes.func,
  recurrenceOptions: PropTypes.shape({}),
};

TaskRecurrenceInstanceModal.defaultProps = {
  showRecurrenceModal: false,
  recurrenceAtion: undefined,
  onDeleteRecurrenceModalClose: undefined,
  handleDeleteRecurrence: undefined,
  deleteEventInstance: undefined,
  setDeleteEventInstance: undefined,
  taskRecurrence: undefined,
  setTaskRecurrence: undefined,
  recurrenceOptions: undefined,
};

export default TaskRecurrenceInstanceModal;

import { useCallback, useMemo } from "react";
import { useAppState } from "../../../../state/appState";

const usePtoSectionData = ({ dispatchSystemSettings }) => {
  const [{ financialsConfiguration }] = useAppState();

  const isLocked = useMemo(() => {
    return (
      financialsConfiguration?.financials?.payroll?.locked ||
      financialsConfiguration?.financials?.period?.locked
    );
  }, [financialsConfiguration]);

  const onChangePtoCategory = useCallback(
    (value) => {
      dispatchSystemSettings({
        type: "changePtoCategory",
        value,
      });
    },
    [dispatchSystemSettings]
  );

  return {
    isLocked,
    onChangePtoCategory,
  };
};

export default usePtoSectionData;

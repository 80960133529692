import React from "react";

const ExpandElement = ({ toggle, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      height="100%"
      preserveAspectRatio="xMidYMid meet"
    >
      {/* <g id="Hide">
        <line
          id="bottom-line"
          x1="4.83"
          y1="56.42"
          x2="55.17"
          y2="56.42"
          strokeWidth="7"
          stroke={color ?? "#fff"}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g> */}
      {toggle ? (
        <g id="Minimize">
          <g id="top-arrow">
            <line
              id="top-line"
              x1="36.11"
              y1="23.89"
              x2="57.5"
              y2="2.5"
              strokeWidth="5"
              stroke={color ?? "#fff"}
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="none"
            />
            <polyline
              id="top-head"
              points="54.44 23.89 36.11 23.89 36.11 5.56"
              fill="none"
              stroke={color ?? "#fff"}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
          </g>
          <g id="bottom-arrow">
            <polyline
              id="bottom-head"
              points="5.56 36.11 23.89 36.11 23.89 54.44"
              fill="none"
              stroke={color ?? "#fff"}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
            <line
              id="bottom-line-2"
              data-name="bottom-line"
              x1="2.5"
              y1="57.5"
              x2="23.89"
              y2="36.11"
              strokeWidth="5"
              stroke={color ?? "#fff"}
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="none"
            />
          </g>
        </g>
      ) : (
        <g id="Maximize">
          <g id="top_arrow">
            <line
              id="top_line"
              x1="57.5"
              y1="2.5"
              x2="36.11"
              y2="23.89"
              strokeWidth="5"
              stroke={color ?? "#fff"}
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="none"
            />
            <polyline
              id="top_head"
              points="39.17 2.5 57.5 2.5 57.5 20.83"
              fill="none"
              stroke={color ?? "#fff"}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
          </g>
          <g id="bottom_arrow">
            <polyline
              id="bottom_head"
              points="20.83 57.5 2.5 57.5 2.5 39.17"
              fill="none"
              stroke={color ?? "#fff"}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
            <line
              id="bottom_line"
              x1="23.89"
              y1="36.11"
              x2="2.5"
              y2="57.5"
              strokeWidth="5"
              stroke={color ?? "#fff"}
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="none"
            />
          </g>
        </g>
      )}
    </svg>
  );
};

export default ExpandElement;

import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

const CN = (className) => cntl`
  flex
  py-4
  ${className}
`;

const titleCN = (className) => cntl`
  font-bold 
  text-gray-450
  text-sm
  w-1/2
  self-center
  ${className}
`;

const DetailRow = ({ containerClassName, titleClassName, title, children }) => {
  return (
    <div className={CN(containerClassName)}>
      <h3 className={titleCN(titleClassName)}>{title}</h3>
      {children}
    </div>
  );
};

DetailRow.propTypes = {
  /**
   * className to pass to the container
   */
  containerClassName: PropTypes.string,
  /**
   * className to pass to the title
   */
  titleClassName: PropTypes.string,
  /**
   * Detail Title
   */
  title: PropTypes.string,
  /**
   * Detail Children
   */
  children: PropTypes.arrayOf(PropTypes.element),
};

DetailRow.defaultProps = {
  titleClassName: undefined,
  containerClassName: undefined,
  title: undefined,
  children: {},
};

export default DetailRow;

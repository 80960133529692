import React, { useMemo } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import BaseButton from "./BaseButton";
import ActionsButton from "./ActionsButton/ActionsButton";
import AddButton from "./AddButton";
import SaveButton from "./SaveButton";

const primaryButtonCN = (className, isDestructive, large) => cntl`
  ${large ? "px-0 h-12" : "h-10 px-4"}
  rounded
  ${isDestructive ? "bg-brandRed" : "bg-primaryGreen"}
  font-semibold
  transition-all
  duration-200
  hover:bg-primaryGreen
  border
  border-primaryGreen
  ${className}
`;

const PrimaryButton = ({
  addButton,
  saveButton,
  className,
  isDestructive,
  large,
  style,
  ...props
}) => {
  const button = useMemo(() => {
    switch (true) {
      case Array.isArray(props.dropdownItems):
        return <ActionsButton {...props} />;
      case addButton:
        return <AddButton {...props} className={className} />;
      case saveButton:
        return <SaveButton style={style} {...props} className={className} />;
      default:
        return (
          <BaseButton
            style={style || { minWidth: large ? "184px" : "120px" }}
            className={primaryButtonCN(className, isDestructive, large)}
            {...props}
          />
        );
    }
  }, [addButton, className, isDestructive, large, props, saveButton, style]);
  return <>{button}</>;
};

PrimaryButton.propTypes = {
  /**
   * any CSS classes to apply to the container
   */
  className: PropTypes.string,
  /**
   * if true, will render with a red background
   */
  isDestructive: PropTypes.bool,
  /**
   * if true, makes the button have more padding making it larger
   */
  large: PropTypes.bool,
  /**
   * Dropdown Items
   */
  dropdownItems: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * Boolean Flag for new button design
   */
  addButton: PropTypes.bool,
  /**
   * Boolean Flag for new save button
   */
  saveButton: PropTypes.bool,
  style: PropTypes.shape({}),
};

PrimaryButton.defaultProps = {
  className: null,
  isDestructive: false,
  large: false,
  dropdownItems: undefined,
  addButton: false,
  saveButton: false,
  style: undefined,
};

export default PrimaryButton;

import React from "react";
import PropTypes from "prop-types";
import dMIcon from "../../../stories/assets/images/messagingDmIcon.svg";

const SendMessageButton = ({ send, disabled }) => {
  return (
    <button
      className="self-end"
      type="button"
      onClick={send}
      disabled={disabled}
    >
      <img src={dMIcon} className="on-button-hover" alt="send-direct-message" />
    </button>
  );
};

SendMessageButton.propTypes = {
  send: PropTypes.func,
  disabled: PropTypes.bool,
};

SendMessageButton.defaultProps = {
  send: () => {},
  disabled: false,
};

export default SendMessageButton;

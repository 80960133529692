import React from "react";
import PropTypes from "prop-types";
import FileUpload from "./FileUpload";

const WorkflowFileForm = ({
  files,
  dispatch,
  handleCloseUploader,
  handleFileUpload,
  showSpinner,
}) => {
  const onAddDocument = async (fileRef) => {
    dispatch({
      type: "addFile",
      file: fileRef,
    });
  };

  const onDeleteDocument = (fileRef) => {
    dispatch({
      type: "removeFile",
      ref: fileRef,
    });
  };

  return (
    <FileUpload
      resource={files}
      onAddDocument={onAddDocument}
      onDeleteDocument={onDeleteDocument}
      handleCloseUploader={handleCloseUploader}
      handleFileUpload={handleFileUpload}
      showSpinner={showSpinner}
    />
  );
};

WorkflowFileForm.propTypes = {
  /**
   * asset currently being created or edited
   */
  files: FileUpload.propTypes.resource,
  /**
   * function called to make changes to the asset.
   * Generally will be the useAssetFormReducer
   */
  dispatch: PropTypes.func,
  /**
   * closes upload modal
   */
  handleCloseUploader: PropTypes.func,
  /**
   * function to handle file upload
   */
  handleFileUpload: PropTypes.func,
  /**
   * shows/hides loading spinner
   */
  showSpinner: PropTypes.bool,
};

WorkflowFileForm.defaultProps = {
  files: undefined,
  dispatch: undefined,
  handleCloseUploader: undefined,
  handleFileUpload: undefined,
  showSpinner: false,
};

export default WorkflowFileForm;

import React, { useEffect, useState } from "react";
import * as yup from "yup";
import cntl from "cntl";
import PropTypes from "prop-types";
import Dropdown from "../Dropdown/Dropdown";
import RadioButton from "../Buttons/RadioButton";
import BaseButton from "../Buttons/BaseButton";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import deleteGreeenIcon from "../../assets/images/collapseIcon.svg";
import Input from "../Input/Input";
import {
  MEMBER_SALARY_OPTIONS,
  EMPLOYEE_TYPES,
  PTO_CATEGORY,
} from "../../../constants";
import MemberInviteeProps from "../../../props/MemberInviteeProps";
import {
  activeCurrencyFormatter,
  phoneRegExp,
} from "../../../helpers/Formatters";
import SelectOptionsWithUpdates from "../SelectUserInterface/SelectOptionsWithUpdates";
import DatePicker from "../DatePicker/DatePicker";

const tertiaryButtonCN = (selected, className) => cntl`
  ${selected ? cntl`bg-brandGreen` : cntl`bg-white`}
  px-2
  py-1
  rounded-lg
  border
  ${className}
`;

const AddNewMemberForm = ({
  invitee,
  onChangeIsExisting,
  onRemoveInvitee,
  onChangeFirstName,
  onChangeLastName,
  onChangeCompany,
  companyContacts,
  onChangeEmail,
  onChangePhone,
  emailError,
  onChangeRole,
  onChangeEmployee,
  onChangeEmployeeType,
  onChangeAnnualVacation,
  onChangeSalaryType,
  onChangeSalary,
  roles,
  states,
  onChangeEmployeeState,
  onChangeEmployeeId,
  onChangeHireDate,
  ptoCategory,
  hideEmployeeDetails,
  directReportOptions,
  onDirectReportChange,
}) => {
  const [salaryTypes, setSalaryTypes] = useState(MEMBER_SALARY_OPTIONS);
  const [selectedDirectReport, setSelectedDirectReport] = useState([]);

  useEffect(() => {
    if (selectedDirectReport?.length > 0) {
      const newDirectReport = { user: [], role: [] };
      selectedDirectReport?.forEach((dr) => {
        if (dr?.value?.startsWith("User/"))
          newDirectReport.user.push(dr?.value);
        else if (dr?.value?.startsWith("Role/"))
          newDirectReport.role.push(dr?.value);
      });

      onDirectReportChange(newDirectReport);
    }
  }, [onDirectReportChange, selectedDirectReport]);

  useEffect(() => {
    if (invitee?.employeeType) {
      if (invitee?.employeeType?.value === "exempt") {
        const annualType = MEMBER_SALARY_OPTIONS.find(
          (opt) => opt.value === "annual"
        );
        setSalaryTypes([annualType]);
        onChangeSalaryType(annualType);
      } else {
        const hourlyType = MEMBER_SALARY_OPTIONS.find(
          (opt) => opt.value === "hourly"
        );
        setSalaryTypes([hourlyType]);
        onChangeSalaryType(hourlyType);
      }
    }
  }, [invitee?.employeeType, onChangeSalaryType]);

  return (
    <div className="border rounded-md p-4">
      <div className="flex justify-between">
        <div className="flex flex-row">
          <SecondaryHeader>Invite Contact:</SecondaryHeader>
          <div className="flex space-x-2 pl-2">
            <BaseButton
              className={tertiaryButtonCN(invitee?.isExisting)}
              title="EXISTING"
              colorCN={invitee?.isExisting ? "text-white" : "text-black"}
              onClick={() => onChangeIsExisting(true)}
            />
            <BaseButton
              className={tertiaryButtonCN(!invitee?.isExisting, "px-4")}
              title="NEW"
              colorCN={!invitee?.isExisting ? "text-white" : "text-black"}
              onClick={() => onChangeIsExisting(false)}
            />
          </div>
        </div>
        <BaseButton
          iconLeft={<img className="w-5 h-5" src={deleteGreeenIcon} alt="" />}
          className="mr-2"
          onClick={onRemoveInvitee}
        />
      </div>
      <div className="flex flex-row py-6">
        <div className="w-2/5 pr-4">
          <Input
            label="First Name"
            value={invitee?.name?.firstName}
            onChange={onChangeFirstName}
            placeholder="First Name"
            validation={yup.string().trim().required()}
          />
        </div>
        <div className="w-2/5 pr-4">
          <Input
            label="Last Name"
            value={invitee?.name?.lastName}
            onChange={onChangeLastName}
            placeholder="Last Name"
            validation={yup.string().trim().required()}
          />
        </div>
        <div className="w-3/4">
          <Dropdown
            label="Company"
            options={companyContacts}
            value={invitee?.company}
            onChange={onChangeCompany}
            placeholder="Select"
            isCreatable
          />
        </div>
      </div>
      <div className="flex flex-row pb-6">
        <div className="w-1/2 pr-4">
          <Input
            label="Email"
            value={invitee?.email?.value}
            onChange={onChangeEmail}
            placeholder="Email Address"
            subText="This will also be used as the member's username at login"
            validation={yup.string().email().required()}
          />
          <p className="text-red-500 text-sm">{emailError}</p>
        </div>
        <div className="w-1/3 pr-4">
          <Input
            label="Mobile"
            value={invitee?.phone?.value}
            placeholder="Mobile Number"
            onChange={onChangePhone}
            isPhoneNumber
            validation={yup
              .string()
              .matches(phoneRegExp, "Phone number is not valid")
              .required()}
          />
        </div>
        <div className="w-1/4">
          <Dropdown
            label="Member Role"
            options={roles}
            value={invitee?.role}
            onChange={onChangeRole}
            validation={yup.mixed().required()}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <p className="font-medium">Invite as employee?</p>
        <div className="flex flex-row">
          <RadioButton
            label="Yes"
            isChecked={invitee?.isEmployee}
            onChange={() => onChangeEmployee(true)}
          />
          <RadioButton
            label="No"
            isChecked={!invitee?.isEmployee}
            onChange={() => onChangeEmployee(false)}
          />
        </div>
        {invitee?.isEmployee && (
          <div className="grid grid-flow-row grid-cols-4 gap-3 mt-7 items-start">
            {ptoCategory === PTO_CATEGORY.location && (
              <Dropdown
                label="State of Employment"
                options={states}
                value={invitee?.stateOfEmployment}
                onChange={onChangeEmployeeState}
                validation={yup.mixed().required()}
              />
            )}
            <Input
              label="Employee ID"
              value={invitee?.employeeId}
              placeholder="Id"
              onChange={onChangeEmployeeId}
              validation={yup.string().trim()}
            />
            <SelectOptionsWithUpdates
              dropDownLabel="Select Direct Report"
              options={directReportOptions}
              setSelectedValues={setSelectedDirectReport}
              selectedValues={selectedDirectReport}
            />
            <DatePicker
              className=""
              label="Hire Date"
              value={invitee?.hireDate}
              onChange={onChangeHireDate}
              validation={yup.date().required()}
            />
          </div>
        )}
      </div>
      {invitee?.isEmployee && ptoCategory === PTO_CATEGORY.location && (
        <div className="grid grid-flow-row grid-cols-2 gap-3 mt-7">
          {!hideEmployeeDetails && (
            <>
              <Dropdown
                label="Employee Type"
                options={EMPLOYEE_TYPES}
                value={invitee?.employeeType}
                onChange={onChangeEmployeeType}
                validation={yup.mixed().required()}
              />
              <Input
                placeholder="Days"
                label="Annual Vacation Days"
                value={invitee?.annualVacation}
                onChange={onChangeAnnualVacation}
                validation={yup.number().integer().min(0)}
              />
              <Dropdown
                label="Salary or Hourly Pay"
                options={salaryTypes}
                value={invitee?.salaryType}
                onChange={onChangeSalaryType}
                className="pt-2"
                validation={yup.mixed().required()}
              />
              <Input
                placeholder="Salary"
                label={invitee?.salaryType?.label || "Annual Salary"}
                isDollarValue
                value={activeCurrencyFormatter(invitee?.salary)}
                onChange={onChangeSalary}
                className="py-2"
                validation={yup.number().min(0).required()}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

AddNewMemberForm.propTypes = {
  invitee: MemberInviteeProps.SHAPE,
  onChangeIsExisting: PropTypes.func,
  onRemoveInvitee: PropTypes.func,
  onChangeFirstName: PropTypes.func,
  onChangeLastName: PropTypes.func,
  onChangeCompany: PropTypes.func,
  companyContacts: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })
  ),
  onChangeEmail: PropTypes.func,
  onChangePhone: PropTypes.func,
  emailError: PropTypes.string,
  onChangeRole: PropTypes.func,
  onChangeEmployee: PropTypes.func,
  onChangeEmployeeType: PropTypes.func,
  onChangeAnnualVacation: PropTypes.func,
  onChangeHireDate: PropTypes.func,
  onChangeSalaryType: PropTypes.func,
  onChangeSalary: PropTypes.func,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  hideEmployeeDetails: PropTypes.bool,
  states: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  ptoCategory: PropTypes.string,
  onChangeEmployeeState: PropTypes.func,
  onChangeEmployeeId: PropTypes.func,
  directReportOptions: PropTypes.shape({}),
  onDirectReportChange: PropTypes.func,
};

AddNewMemberForm.defaultProps = {
  invitee: undefined,
  onChangeIsExisting: undefined,
  onRemoveInvitee: undefined,
  onChangeFirstName: undefined,
  onChangeLastName: undefined,
  onChangeCompany: undefined,
  companyContacts: undefined,
  onChangeEmail: undefined,
  onChangePhone: undefined,
  emailError: undefined,
  onChangeRole: undefined,
  onChangeEmployee: undefined,
  onChangeEmployeeType: undefined,
  onChangeHireDate: undefined,
  onChangeAnnualVacation: undefined,
  onChangeSalaryType: undefined,
  onChangeSalary: undefined,
  roles: [],
  hideEmployeeDetails: false,
  states: [],
  ptoCategory: undefined,
  onChangeEmployeeState: undefined,
  onChangeEmployeeId: undefined,
  directReportOptions: {},
  onDirectReportChange: undefined,
};

export default AddNewMemberForm;

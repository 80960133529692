import { useHistory } from "react-router";
import { useAssetsDelete } from "../../../../hooks/assets";
import {
  toastError,
  toastMessage,
} from "../../../../stories/Components/Toast/Toast";

const useAssetDeleteModalData = ({
  asset,
  selectedAssets = [],
  setShowDeleteModal,
}) => {
  const history = useHistory();

  const { deleteAssets, isDeleting } = useAssetsDelete();

  const hasMultipleAssets = selectedAssets.length > 1;

  const message = hasMultipleAssets
    ? "these assets? Once deleted, these assets and their data cannot be recovered."
    : "this asset? Once deleted, this asset and its data cannot be recovered.";

  /**
   * Api call to delete selected asset(s)
   * @param {string[]} assetsIds - array of asset(s) id to delete
   */
  const handleDeleteAssets = async (assetsIds) => {
    deleteAssets(assetsIds)
      .then(() => {
        const display = hasMultipleAssets
          ? "Assets were removed successfully"
          : "Asset was deleted successfully";

        toastMessage(display);
      })
      .catch(() => {
        const display = hasMultipleAssets
          ? "Assets could not be deleted. Please try again"
          : "Asset could not be deleted. Please try again";

        toastError(display);
      });

    setShowDeleteModal(false);
  };

  /**
   * Handle asset(s) deletion. Remove single or multiple assets based on props.
   */
  const onDeleteClicked = () => {
    // Remove 1:M asset(s)
    if (selectedAssets.length > 0) {
      const assetsIds = selectedAssets.map((data) => data.id);
      handleDeleteAssets(assetsIds);

      return;
    }

    // Remove single asset
    if (asset) {
      handleDeleteAssets([asset.id]);

      history.goBack();
    }
  };

  return {
    message,
    isDeleting,
    hasMultipleAssets,
    onDeleteClicked,
  };
};

export default useAssetDeleteModalData;

import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useCallback, useRef } from "react";

const buttonStyle = (className, disabled, tableIconsCancelButton) => cntl`
text-es-green text-md rounded hover:bg-darkenedGreen hover:text-white hover:border hover:border-darkenedGreen
${tableIconsCancelButton ? className : `h-10 px-4 py-2 ${className}`}
`;

const CancelButton = ({
  disabled,
  onClick,
  className,
  tableIconsCancelButton,
  style,
  cancelButtonTitle,
}) => {
  const buttonRef = useRef({});

  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick();
    }
  }, [disabled, onClick]);

  return (
    <button
      className={buttonStyle(className, disabled, tableIconsCancelButton)}
      style={style}
      onClick={handleClick}
      type="button"
      tabIndex={0}
      id="cancel-button"
      aria-label="cancel-button"
      ref={buttonRef}
    >
      <p className="whitespace-nowrap px-3 font-es-semibold">
        {cancelButtonTitle || "Cancel"}
      </p>
    </button>
  );
};

CancelButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  tableIconsCancelButton: PropTypes.bool,
  style: PropTypes.shape({}),
  cancelButtonTitle: PropTypes.string,
};

CancelButton.defaultProps = {
  disabled: false,
  onClick: () => {},
  className: undefined,
  tableIconsCancelButton: false,
  style: undefined,
  cancelButtonTitle: undefined,
};

export default CancelButton;

import moment from "moment";

const getWeekBeforeAndAfter = (dateString) => {
  const date = moment.utc(dateString);

  const weekBefore = date.clone().subtract(1, "week").format("YYYY-MM-DD");
  const weekAfter = date.clone().add(1, "week").format("YYYY-MM-DD");

  return {
    weekBefore,
    weekAfter,
  };
};

export default getWeekBeforeAndAfter;

import { isEqual } from "lodash";
import moment from "moment";

/**
 *  Restricted Task fields to disbale eidt all: SD/ED/recurrence/steps/spaces/assets
 * @param oldTask - existing task resource
 * @param  newTask - modified task resource
 * @returns Boolean - if any restricted field was modified
 */
const hasRestrictedTaskFieldsChanged = (oldTask, newTask) => {
  // compare SD
  if (!moment(oldTask.startDate).isSame(newTask.startDate)) {
    return true;
  }
  // compare ED
  if (!moment(oldTask.endDate).isSame(newTask.endDate)) {
    return true;
  }

  // compare All-day
  if (oldTask.allDay !== newTask.allDay) {
    return true;
  }
  // compare recurrence
  if (oldTask.recurrence !== newTask.recurrence) {
    return true;
  }

  const oldSpaces = oldTask.spaces.map((sp) => sp.id);
  const newSpaces = newTask.spaces.map((sp) => sp.id);
  // compare spaces
  if (!isEqual(oldSpaces, newSpaces)) {
    return true;
  }

  const oldAssets = oldTask.assets.map((as) => as.ref);
  const newAssets = newTask.assets.map((as) => as.ref);
  // compare assets
  if (!isEqual(oldAssets, newAssets)) {
    return true;
  }

  const oldSteps = oldTask.steps.map((step) => ({
    id: step.id,
    description: step.description,
    sop: step.sop,
    sopVersion: step.sopVersion,
  }));

  const newSteps = newTask.steps.map((step) => ({
    id: step.id,
    description: step.description,
    sop: step.sop,
    sopVersion: step.sopVersion,
  }));
  // compare steps
  if (!isEqual(oldSteps, newSteps)) {
    return true;
  }

  return false;
};

export default hasRestrictedTaskFieldsChanged;

import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

const tertiaryHeaderCN = (className) => cntl`
  text-base
  text-gray-300
  ${className}
`;

const TertiaryHeader = ({ className, children }) => {
  return <h3 className={tertiaryHeaderCN(className)}>{children}</h3>;
};

TertiaryHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

TertiaryHeader.defaultProps = {
  className: undefined,
};

export default TertiaryHeader;

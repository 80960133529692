import React from "react";
import PropTypes from "prop-types";
import ContactButton from "../Buttons/ContactButton";
import phoneGreen from "../../assets/images/phoneGreen.svg";
import phoneGray from "../../assets/images/phoneGray.svg";
import emailGreen from "../../assets/images/emailGreen.svg";
import emailGray from "../../assets/images/emailGray.svg";
import messageGreen from "../../assets/images/messageGreen.svg";
import messageGray from "../../assets/images/messageGray.svg";

const ContactTitleButtons = ({
  phoneOptions,
  emailOptions,
  currentUser,
  contact,
  openChat,
}) => {
  return (
    <div
      style={{ width: "142px", height: "40px" }}
      className="flex rounded-lg ml-5 justify-center bg-brandGreen bg-opacity-10"
    >
      {/* row of icons */}
      <div className="flex flex-row justify-between items-center h-full w-full px-1">
        <div className="flex flex-col w-1/3 justify-center items-center">
          <ContactButton
            iconLeft={
              <img
                alt="phoneGreenIcon"
                src={phoneOptions?.length ? phoneGreen : phoneGray}
                className="h-5 w-5"
              />
            }
            iconClassName="flex-auto flex justify-center"
            dropdownItems={phoneOptions}
            menuCN="left-10 top-24"
            containerClassName=""
          />
        </div>
        <div className="flex flex-col w-1/3 justify-center items-center">
          <ContactButton
            iconLeft={
              <img
                alt="emailGreenIcon"
                src={emailOptions?.length !== 0 ? emailGreen : emailGray}
                className="h-5 w-5"
              />
            }
            iconClassName="flex-auto flex justify-center"
            dropdownItems={emailOptions}
            menuCN="left-10 top-24"
            containerClassName=""
          />
        </div>
        <div className="flex flex-col w-1/3 justify-center items-center">
          <ContactButton
            iconLeft={
              <img
                alt="messageGreenIcon"
                src={
                  contact?.id !== currentUser?.id && contact?.kind === "member"
                    ? messageGreen
                    : messageGray
                }
                className="h-5"
              />
            }
            containerClassName=""
            onClick={() =>
              contact?.id !== currentUser?.id && contact?.kind === "member"
                ? openChat(contact)
                : null
            }
          />
        </div>
      </div>
    </div>
  );
};

ContactTitleButtons.propTypes = {
  phoneOptions: PropTypes.shape([]),
  emailOptions: PropTypes.shape([]),
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    kind: PropTypes.string,
  }),
  contact: PropTypes.shape({
    id: PropTypes.string,
    kind: PropTypes.string,
  }),
  openChat: PropTypes.func,
};

ContactTitleButtons.defaultProps = {
  phoneOptions: [],
  emailOptions: [],
  currentUser: undefined,
  contact: undefined,
  openChat: () => {},
};

export default ContactTitleButtons;

import React from "react";
import DropdownWithCheckbox from "../../DropdownWithCheckbox";
import useTemplatesDropdownData from "./useTemplatesDropdownData";

const TemplatesDropdown = ({
  associationType,
  lockAssociationType,
  handleApplyTemplate,
  isDisabled = false,
}) => {
  const {
    isLoading,
    reportTemplatesOptions,
    dropdownValue,
    handleSelectedValue,
  } = useTemplatesDropdownData({
    associationType,
    lockAssociationType,
    handleApplyTemplate,
    isDisabled,
  });

  return (
    <DropdownWithCheckbox
      options={reportTemplatesOptions}
      value={dropdownValue}
      setValue={handleSelectedValue}
      isMulti={false}
      menuPlacement="auto"
      isLoading={isLoading}
      isClearable
      isDisabled={isDisabled}
    />
  );
};

export default TemplatesDropdown;

/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { capitalize } from "lodash";

import arrowDownGray from "../../stories/assets/images/arrowDownGray.svg";
import helpIcon from "../../stories/assets/images/Help.svg";
import Checkbox from "../../stories/Components/Checkbox/Checkbox";

/**
 * Provides translation values for Resource names. This is optional, if a
 * resource is not named in this object, its own name will be used.
 */
const RoleResourceHeaderTranslation = {
  user: "contact",
  sop: "SOP",
};

/**
 * Provides tooltip content for the Resource headers.
 */
const RoleResourceHeaderTooltipContent = {
  administrative:
    "This allows members to create, update and delete invite only features. Creators will automatically be invited and can only be removed by an admin.",
  asset:
    "This controls access to entity and property assets. Members can only see assets for entities, projects and properties they are invited to.",
  budget:
    "This controls access to entity and property budgets. Members can only see budgets for entities, projects and properties they are invited to.",
  calendar:
    "This controls access to entity, project and property calendar events and tasks. Members can only see budgets for entities, projects and properties they are invited to.",
  corporate:
    "This controls access to financials. Financials can be sensitive information with exact permissions to maintain privacy across all members.",
  document:
    "This controls access to entity, project and property documents. Members can only see documents for entities, projects and properties they are invited to.",
  entity:
    "This controls access to entity financials. Entities are by invite only and work with other permissions such as assets, budgets, calendar, documents, inventory, projects, properties and staffing.",
  event:
    "This controls access and user functionality for events. Members can only see events they have access or invited to.",
  inventory:
    "This controls access to entity, project and property inventory. Members can only see inventory for entities, projects and properties they are invited to.",
  project:
    "This controls access to project budgets, financials and schedules. Projects are by invite only and work with other permissions such as assets, calendar, documents, inventory, properties and staffing.",
  property:
    "This controls access to property financials. Properties are by invite only and work with other permissions such as assets, budgets, calendar, documents, inventory, projects and staffing.",
  sop: "This controls access to SOPs. Members can only see SOPs as it relates to a given task in some cases, while SOPs may be managed by a few members with permission.",
  staffing:
    "This controls access to entity, project and property staffing. Members can only see staffing for entities, projects and properties they are invited to.",
  task: "This controls access and user functionality for tasks. Members can only see tasks they have access or assigned to.",
  timesheet:
    "This controls access to timesheets. Members can enter and submit time while others such as project managers or controllers may need the ability to approve timesheets.",
  workflow:
    "This controls access and user functionality for workflows. Members can only see workflows they have access or assigned to.",
  submittal:
    "This controls access and user functionality for submittals. Members can only see submittals they have access or assigned too.",
};

function RolePermissionsListResourceHeader({ resource, onClick }) {
  const [isTooltipShown, setTooltipShown] = useState(false);

  return (
    <button
      className="py-4 flex flex-row min-w-full items-center"
      onClick={onClick}
      type="button"
    >
      <p className="text-base font-bold text-gray-900 tracking-widest flex-1 capitalize text-left">
        {resource in RoleResourceHeaderTranslation
          ? RoleResourceHeaderTranslation[resource]
          : resource}
        {resource in RoleResourceHeaderTooltipContent && (
          <>
            <img
              src={helpIcon}
              alt="More Info"
              className="inline ml-2 -mt-0.5 w-5 h-5"
              onMouseEnter={() => setTooltipShown(true)}
              onMouseLeave={() => setTooltipShown(false)}
            />
            <div
              className={`bg-white absolute border rounded-md flex flex-col p-2 w-96 ${
                isTooltipShown ? "" : "hidden"
              }`}
            >
              <p className="text-xs font-normal tracking-wide">
                {RoleResourceHeaderTooltipContent[resource]}
              </p>
            </div>
          </>
        )}
      </p>
      <img src={arrowDownGray} alt={resource} />
    </button>
  );
}

RolePermissionsListResourceHeader.propTypes = {
  resource: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

RolePermissionsListResourceHeader.defaultProps = {
  onClick: undefined,
};

function RolePermissionsListPermissionItem({
  permissionName,
  isChecked,
  setChecked,
  disabled,
  resource,
}) {
  let permissionNameUpdated;
  const permissionNameFixed = permissionName.replaceAll("_", " ");
  if (resource === "submittal") {
    permissionNameUpdated = `${permissionNameFixed} ${capitalize(resource)}`;
  } else {
    permissionNameUpdated = permissionNameFixed;
  }

  return (
    <li className="border-b py-4">
      <Checkbox
        label={`${permissionNameUpdated}`}
        labelClassName="text-base text-gray-900 capitalize"
        checked={isChecked}
        onChange={() => setChecked(!isChecked)}
        disabled={disabled}
      />
    </li>
  );
}

RolePermissionsListPermissionItem.propTypes = {
  resource: PropTypes.string,
  permissionName: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  setChecked: PropTypes.func,
  disabled: PropTypes.bool,
};

RolePermissionsListPermissionItem.defaultProps = {
  resource: undefined,
  isChecked: false,
  setChecked: undefined,
  disabled: false,
};

function RolePermissionsList({
  allResourcePermissions,
  onResourceExpansionToggled,
  expandedResources,
  isPermissionSelected,
  onPermissionSelected,
  disableCheckboxes,
}) {
  const allResources = Object.keys(allResourcePermissions).sort();
  const permissionsForResource = (resource) =>
    Object.keys(allResourcePermissions[resource]);

  // Used to disable a permission, if the resource has a can_read permission which is unchecked
  const isCanReadPermissionUnchecked = (resource, permission) => {
    if (resource === "submittal" && permission !== "can_see") {
      return !isPermissionSelected(resource, "can_see");
    }
    if (permission === "can_read") {
      return false;
    }
    if (
      !Object.prototype.hasOwnProperty.call(
        allResourcePermissions[resource],
        "can_read"
      )
    ) {
      return false;
    }

    return !isPermissionSelected(resource, "can_read");
  };

  return (
    <>
      {allResources.map((resource) => {
        return (
          <div className="border-t" key={resource}>
            <RolePermissionsListResourceHeader
              resource={resource}
              onClick={() => onResourceExpansionToggled(resource)}
            />
            {expandedResources.indexOf(resource) >= 0 && (
              <ul>
                {permissionsForResource(resource).map((permission) => (
                  <RolePermissionsListPermissionItem
                    key={permission}
                    resource={resource}
                    permissionName={permission}
                    isChecked={isPermissionSelected(resource, permission)}
                    setChecked={() =>
                      onPermissionSelected(resource, permission)
                    }
                    disabled={
                      disableCheckboxes ||
                      isCanReadPermissionUnchecked(resource, permission)
                    }
                  />
                ))}
              </ul>
            )}
          </div>
        );
      })}
    </>
  );
}

RolePermissionsList.propTypes = {
  allResourcePermissions: PropTypes.shape({}),
  onResourceExpansionToggled: PropTypes.func,
  expandedResources: PropTypes.arrayOf(PropTypes.string),
  isPermissionSelected: PropTypes.func,
  onPermissionSelected: PropTypes.func,
  disableCheckboxes: PropTypes.bool,
};

RolePermissionsList.defaultProps = {
  allResourcePermissions: {},
  onResourceExpansionToggled: undefined,
  expandedResources: [],
  isPermissionSelected: undefined,
  onPermissionSelected: undefined,
  disableCheckboxes: false,
};

export default RolePermissionsList;

import { FileAPI } from "@griffingroupglobal/eslib-api";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import formatTaskHistoryComment from "../../../../../helpers/Task/formatTaskHistoryComment";
import getTaskHistoryDuration from "../../../../../helpers/Task/getTaskHistoryDuration";
import { useTaskComments } from "../../../../../hooks/useComments";
import { useAppState } from "../../../../../state/appState";

const useTaskClosingSectionData = (taskForm) => {
  const history = useHistory();
  const { data: commentMap } = useTaskComments(taskForm?.reference);
  const [{ userDict, sopsDict }] = useAppState();
  const [sopsForTask, setSopsForTask] = useState([]);
  const [formattedComments, setFormattedComments] = useState("");
  const [resolvedFiles, setResolvedFiles] = useState([]);
  const [allInviteesInfo, setAllInviteesInfo] = useState([]);
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    const fetchFiles = async () => {
      let resolved = [...taskForm?.closing?.files]?.map(async (ref) => {
        if (ref) {
          return FileAPI.getByRef(ref)
            .then((res) => res.data)
            .catch(() => {});
        }
        return ref;
      });
      resolved = await Promise.all(resolved);
      setResolvedFiles(resolved);
    };
    if (taskForm?.closing?.files.length > 0) fetchFiles();
  }, [taskForm?.closing?.files]);

  /**
   * Return only media files
   */
  const getOnlyMediaFiles = (files) => {
    return files.reduce((acc, current) => {
      if (current.category !== "Documents") {
        // prepare Blob data
        const binaryData = [];
        binaryData.push(current);
        acc.push({
          ...current,
          ref: current.reference,
          // pass in Blob data
          original: new Blob(binaryData),
        });
      }

      return acc;
    }, []);
  };

  /**
   * Return only media files
   */
  const getOnlyDocumentFiles = (files) => {
    return files.reduce((acc, current) => {
      if (current.category === "Documents") {
        const binaryData = [];
        binaryData.push(current);
        acc.push({
          ...current,
          ref: current.reference,
          original: new Blob(binaryData),
        });
      }

      return acc;
    }, []);
  };

  useEffect(() => {
    const invitees = taskForm?.invitees || [];
    const inviteeData = invitees?.reduce((list, invitee) => {
      // Get information for each invitee from the user dictionary
      const inviteeInfo = userDict?.[invitee];
      if (inviteeInfo) list.push(inviteeInfo);
      return list;
    }, []);

    setAllInviteesInfo(inviteeData);
  }, [userDict, taskForm]);

  useEffect(() => {
    if (taskForm?.steps) {
      // Get the SOPs for the task

      const sopsForTaskArray = taskForm?.steps?.map((item) => {
        return { ...sopsDict?.[item.sop], stepData: item };
      });

      setSopsForTask(sopsForTaskArray);
    }
  }, [sopsDict, taskForm?.steps]);

  useEffect(() => {
    if (taskForm?.closing?.comment) {
      const resource = commentMap[taskForm?.closing?.comment];
      if (resource) {
        setFormattedComments(formatTaskHistoryComment(resource));
      }
    }
  }, [commentMap, taskForm?.closing?.comment]);

  const onExpandClick = () => {
    setIsExpanded((prev) => !prev);
  };

  return {
    history,
    sopsForTask,
    allInviteesInfo,
    formattedComments,
    duration: getTaskHistoryDuration(taskForm),
    mediaFiles: getOnlyMediaFiles(resolvedFiles),
    documentFiles: getOnlyDocumentFiles(resolvedFiles),
    onExpandClick,
    isExpanded,
  };
};

export default useTaskClosingSectionData;

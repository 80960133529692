import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useMemo, useRef, useState } from "react";
import PureLocationWidget from "./PureLocationWidget";
import Widget from "../Widget/Widget";

const widgetCN = cntl`
    flex
    flex-col
    p-5
    w-full
    border-blue
`;

const LocationWidget = ({
  space,
  spaces,
  parent,
  levelTypes,
  spaceTypes,
  handleAddSpace,
  removeSpace,
  isSaving,
  disableEditing,
  isFetching,
  onClickSpaceView,
  editing,
  resourceDetails,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const wrapperRef = useRef(null);

  const formattedSpaces = useMemo(() => {
    return (
      spaces?.map((item) => ({
        ...item,
        levelType: levelTypes?.[item?.level]?.label,
      })) ?? []
    );
  }, [levelTypes, spaces]);

  return (
    <Widget
      title="Space"
      draggable={false}
      className="mb-4"
      isEditing={isEditing}
    >
      <div className={widgetCN} ref={wrapperRef}>
        <PureLocationWidget
          space={space}
          spaces={formattedSpaces}
          handleAddSpace={handleAddSpace}
          removeSpace={removeSpace}
          parent={parent}
          resourceDetails={resourceDetails}
          levelTypes={levelTypes}
          spaceTypes={spaceTypes}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          isSaving={isSaving}
          disableEditing={disableEditing}
          isFetching={isFetching}
          onClickSpaceView={onClickSpaceView}
          editing={editing}
        />
      </div>
    </Widget>
  );
};

LocationWidget.propTypes = {
  space: PropTypes.shape({}),
  spaces: PropTypes.arrayOf(PropTypes.shape({})),
  parent: PropTypes.shape({ resource: PropTypes.string }),
  levelTypes: PropTypes.arrayOf(PropTypes.shape([])),
  spaceTypes: PropTypes.arrayOf(PropTypes.shape([])),
  handleAddSpace: PropTypes.func,
  removeSpace: PropTypes.func,
  editedAsset: PropTypes.shape({
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        building: PropTypes.string,
        location: PropTypes.string,
      })
    ),
  }),
  isSaving: PropTypes.bool,
  disableEditing: PropTypes.bool,
  isFetching: PropTypes.bool,
  editing: PropTypes.bool,
  onClickSpaceView: PropTypes.func,
  resourceDetails: PropTypes.shape({}),
};

LocationWidget.defaultProps = {
  space: undefined,
  spaces: [],
  parent: undefined,
  levelTypes: undefined,
  spaceTypes: undefined,
  handleAddSpace: undefined,
  removeSpace: undefined,
  editedAsset: undefined,
  isSaving: false,
  disableEditing: false,
  isFetching: false,
  editing: false,
  onClickSpaceView: () => {},
  resourceDetails: undefined,
};

export default LocationWidget;

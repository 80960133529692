import { useMemo, useState } from "react";
import { DATE_RANGES_OPTIONS } from "../../../constants";

const useDateRangeDropdownData = ({ dateRange, setDateRange }) => {
  const [openDateRangePicker, setDateOpenDateRangePicker] = useState(false);

  // State to track dates when `custom range` option is selected
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Return the current option in the form of object for the dropdown
  const value = useMemo(() => {
    if (!dateRange) return undefined;

    // Find and return selected option
    return DATE_RANGES_OPTIONS.find(
      (option) => option.value === dateRange.option
    );
  }, [dateRange]);

  const handleSetDateRange = (optionSelected) => {
    if (optionSelected.id === "custom") {
      // If `dateRange` has custom dates, then startDate/endDate states
      // needs to be initialize to display in the date picker
      if (dateRange?.custom?.start) {
        setStartDate(new Date(dateRange.custom.start));
      }

      if (dateRange?.custom?.end) {
        setEndDate(new Date(dateRange.custom.end));
      }

      setDateOpenDateRangePicker(true);
      return;
    }

    setDateRange(optionSelected);

    // If `custom` was selected before, then start/end needs to be initialized
    setStartDate(null);
    setEndDate(null);
  };

  const handleChangeCustomDates = (selectedDates) => {
    const [start, end] = selectedDates;

    setStartDate(start);
    setEndDate(end || null);

    if (start && end) {
      setDateRange({
        id: "custom",
        value: {
          custom: { start: start.toISOString(), end: end.toISOString() },
        },
      });
    }
  };

  return {
    value,
    options: DATE_RANGES_OPTIONS,
    openDateRangePicker,
    startDate,
    endDate,
    handleChangeCustomDates,
    handleSetDateRange,
    setDateOpenDateRangePicker,
  };
};

export default useDateRangeDropdownData;

import React from "react";
import { components } from "react-select";
import Checkbox from "../Checkbox/Checkbox";
import SegmentedControl from "../SegmentedControl";

const MenuOption = ({
  segments,
  selectedSegment,
  onSelect,
  allSelected,
  handleAllSelected,
  ...props
}) => {
  const { children } = props ?? {};

  let twStyle =
    "flex w-full items-center pl-5 pt-2 pb-2 border-b border-gray-150 border-opacity-50 ";

  if (!allSelected) {
    twStyle += "hover:bg-es-light-mint";
  } else {
    twStyle += "bg-es-light-mint";
  }

  return (
    <components.Menu {...props}>
      <div className="flex w-full pt-4 pb-2 ">
        <SegmentedControl
          segments={segments}
          selectedSegment={selectedSegment}
          onSelect={onSelect}
        />
      </div>
      <div
        style={{
          // inline style required to match react-select
          height: "54px",
        }}
        className={twStyle}
      >
        <Checkbox
          className="cursor-pointer"
          onChange={handleAllSelected}
          checked={allSelected}
        />
        <p className="pl-4">All</p>
      </div>
      <div style={{ minHeight: 100 }}>{children}</div>
    </components.Menu>
  );
};

export default MenuOption;

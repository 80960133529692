import PropTypes from "prop-types";
import React from "react";
import {
  DOCUMENT_ATTACHMENT_EXTENSIONS,
  PRIMARY_DARK_GREEN,
} from "../../../constants";
import PlusCircleButton from "../Buttons/PlusCircleButton/PlusCircleButton";
import useAddAttachmentsButtonData from "./useAddAttachmentsButton";

const AddAttachmentsButton = ({ onFilesAdded }) => {
  const { fileInputRef, handleFileInputClick, handleFilesSelected } =
    useAddAttachmentsButtonData({ onFilesAdded });

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept={DOCUMENT_ATTACHMENT_EXTENSIONS}
        multiple
        onChange={handleFilesSelected}
      />
      <PlusCircleButton
        title="Add Attachments"
        className="-mt-5"
        onClick={handleFileInputClick}
        style={{ color: PRIMARY_DARK_GREEN }}
      />
    </div>
  );
};

AddAttachmentsButton.defaultProps = {
  onFilesAdded: () => {},
};

AddAttachmentsButton.propTypes = {
  onFilesAdded: PropTypes.func,
};

export default AddAttachmentsButton;

import { useCallback, useEffect, useMemo, useState } from "react";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import usePagePersistance from "../../../../hooks/usePagePersistence";
import { useAppState } from "../../../../state/appState";

const useTimesheetOverviewData = () => {
  const [findTabs, setTabs] = useState([]);
  const { data: currentUser } = useCurrentUser();
  const [{ userDict, employees: unparsedEmployees, rolesDict, payrollPeriod }] =
    useAppState();
  const { pageState, storageLoading, setPersistentPageItem } =
    usePagePersistance();

  useEffect(() => {
    const pageDataIsEmpty =
      !storageLoading &&
      pageState?.timesheet?.userId === "" &&
      pageState?.timesheet?.tab === "" &&
      pageState?.timesheet?.periodStart === "" &&
      pageState?.timesheet?.periodEnd === "";

    if (currentUser?.isEmployee && pageDataIsEmpty) {
      const startDate = payrollPeriod?.periodStart.split("T")[0];
      const endDate = payrollPeriod?.periodEnd.split("T")[0];

      setPersistentPageItem("timesheet", {
        ...pageState?.timesheet,
        periodStart: startDate,
        periodEnd: endDate,
        userId: currentUser?.id,
        tab: "my-timesheet",
      });
    }

    if (!currentUser?.isEmployee && pageDataIsEmpty) {
      setPersistentPageItem("timesheet", {
        ...pageState?.timesheet,
        userId: "",
        tab: "approve-time",
        periodStart: payrollPeriod?.periodStart.split("T")[0],
        periodEnd: payrollPeriod?.periodEnd.split("T")[0],
      });
    }
  }, [
    currentUser?.id,
    currentUser?.isEmployee,
    pageState?.timesheet,
    payrollPeriod?.periodEnd,
    payrollPeriod?.periodStart,
    setPersistentPageItem,
    storageLoading,
  ]);

  const employees = useMemo(() => {
    return unparsedEmployees?.filter(
      (item) => rolesDict?.[item?.role]?.permissions?.timesheet?.can_submit
    );
  }, [rolesDict, unparsedEmployees]);

  const handleTabClick = useCallback(
    (_, id) => {
      let pageData = {
        userId: "",
        tab: "",
        periodStart: "",
        periodEnd: "",
      };

      switch (id) {
        case "my-timesheet":
          pageData = {
            ...pageState?.timesheet,
            userId: currentUser?.id,
            tab: "my-timesheet",
          };
          break;
        case "user-timeoff":
          pageData = {
            ...pageState?.timesheet,
            userId: currentUser?.id,
            tab: "user-timeoff",
          };
          break;
        default:
          pageData = {
            ...pageState?.timesheet,
            userId: "",
            tab: id,
          };
          break;
      }

      if (!pageData?.periodStart || !pageData?.periodEnd) {
        pageData.periodStart = payrollPeriod?.periodStart.split("T")[0];
        pageData.periodEnd = payrollPeriod?.periodEnd.split("T")[0];
      }

      setPersistentPageItem("timesheet", pageData);
    },
    [
      currentUser?.id,
      pageState,
      payrollPeriod?.periodEnd,
      payrollPeriod?.periodStart,
      setPersistentPageItem,
    ]
  );

  const payrollApproveConditions = useMemo(() => {
    return currentUser?.hasPermission("corporate", "can_approve_payroll");
  }, [currentUser]);

  const timeOffApproveConditions = useMemo(() => {
    return currentUser?.hasPermission("corporate", "can_give_timeoff");
  }, [currentUser]);

  const timesheetApprovalConditions = useMemo(() => {
    return currentUser?.hasPermission("timesheet", "can_approve");
  }, [currentUser]);

  // To keep track of the active tab and show the correct tab content
  // based on the user's role and the tab they are on
  const activeTabIndex = useMemo(() => {
    const activeTab = pageState?.timesheet?.tab;
    if (activeTab && activeTab !== "" && findTabs?.length > 0)
      return findTabs?.findIndex((item) => item.id === activeTab);
    return 0;
  }, [findTabs, pageState?.timesheet?.tab]);

  return {
    activeTabIndex,
    currentUser,
    pageState,
    userDict,
    employees,
    payrollApproveConditions,
    timeOffApproveConditions,
    timesheetApprovalConditions,
    handleTabClick,
    findTabs,
    setTabs,
  };
};

export default useTimesheetOverviewData;

import { useState } from "react";
import { useHistory, useParams } from "react-router";
import useURLQueryParameter from "../../../../hooks/useURLQueryParameter";
import { useSopById, useSopByIdPrefetch } from "../../../../hooks/useSopById";
import { useSopClone } from "../../../../hooks/sop";
import { getSingleResourcePath } from "../../../../helpers/Navigation";
import {
  toastError,
  toastMessage,
} from "../../../../stories/Components/Toast/Toast";
import { useAppState } from "../../../../state/appState";
import { SET_SOP_MODAL_STATUS } from "../../../../constants";
import useQueryNotFoundNavigation from "../../../../hooks/navigation/useQueryNotFoundNavigation";

const useSopDetailsData = (currentUser) => {
  const history = useHistory();

  const { sopId } = useParams();

  // If `version` parameter available, `useSopId` will download that specific sop version
  const version = useURLQueryParameter("version");

  const { data: sop, isLoading: loading, error } = useSopById(sopId, version);

  // Redirect to 404 page if resource is not found
  useQueryNotFoundNavigation({ error });

  // Prefetch the previous SOP version to avoid loading status in history tab
  useSopByIdPrefetch(sopId, sop?.version - 1);

  const { cloneSop } = useSopClone();

  const [, dispatch] = useAppState();

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [showActivity, setShowActivity] = useState(false);

  const disableEditing =
    !!version ||
    !currentUser?.hasPermission?.("administrative", "can_write_property");

  const disableUploadMedia =
    !!version || !currentUser?.hasPermission?.("sop", "can_write");

  const showEditIcon = activeTabIndex === 0;

  const handleNavigationBack = () => {
    if (showActivity) {
      setShowActivity(false);
    } else {
      history.goBack();
    }
  };

  /**
   * Clone current SOP and redirect to single SOP page
   * @param {String} id - Given SOP id
   */
  const handleCloneSop = (id) => {
    cloneSop(id)
      .then((newSop) => {
        // Always set tabIndex to 0 after cloning a sop
        dispatch({
          type: SET_SOP_MODAL_STATUS,
          activeTabIndex: 0,
        });

        history.push(getSingleResourcePath(newSop.reference));

        toastMessage(`Successfully cloned SOP ${sop?.name}`);
      })
      .catch(() => {
        toastError(`Error cloning SOP ${sop?.name}`);
      });
  };

  return {
    loading,
    sopId,
    sop,
    version,
    showActivity,
    disableEditing,
    disableUploadMedia,
    activeTabIndex,
    showEditIcon,
    setActiveTabIndex,
    setShowActivity,
    handleNavigationBack,
    handleCloneSop,
  };
};

export default useSopDetailsData;

import React, { useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import ProjectHistoryCard from "./ProjectHistoryCard";
import Spinner from "../../stories/Components/Spinner/Spinner";

const ProjectHistory = ({ project, currentUser, users, history }) => {
  const [, setEditedProject] = useState();
  const [projectHistory, setProjectHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setEditedProject(project);
  }, [project]);
  useEffect(() => {
    if (loading && history) {
      setProjectHistory(history);
      setLoading(false);
    }
  }, [history, loading]);

  const buildHistoryByYearArray = (historyArray) => {
    const historyObj = {};
    historyArray?.forEach((item) => {
      const historyYear = moment(item?.startDate).format("YYYY");
      if (!historyObj[historyYear]) {
        historyObj[historyYear] = [item];
      } else {
        historyObj[historyYear].push(item);
      }
    });

    const historyByYearArray = Object.keys(historyObj).map((k) => ({
      year: k,
      items: historyObj[k],
    }));
    return historyByYearArray;
  };

  return (
    <>
      {loading || users?.length === 0 ? (
        <div className="pt-10">
          <Spinner notFullScreen />
        </div>
      ) : (
        <div>
          {buildHistoryByYearArray(projectHistory)
            ?.filter((info) => {
              const currentYear = moment(Date.now()).format("YYYY");
              return +info.year <= +currentYear;
            })
            .sort((a, b) => +a.year - +b.year < 0)
            .map((info, index) => (
              <ProjectHistoryCard
                key={info.year}
                data={info}
                users={users}
                defaultOpen={index === 0}
                currentUser={currentUser}
              />
            ))}
        </div>
      )}
    </>
  );
};

ProjectHistory.propTypes = {
  /**
   * maintance data
   */
  project: PropTypes.shape({
    year: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
      })
    ),
  }),
  currentUser: PropTypes.shape({
    isAdmin: PropTypes.bool,
    reference: PropTypes.string,
  }),
  history: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.shape({})),
};

ProjectHistory.defaultProps = {
  project: undefined,
  users: undefined,
  currentUser: {},
  history: false,
};

export default ProjectHistory;

import taskKeys from "../taskKeys";

const deleteTaskResponder = (queryClient) => {
  return {
    onSuccess: (returnedData, variables) => {
      queryClient.removeQueries(taskKeys.singleTask(variables.id));
    },
    onError: (err) => {
      console.error("Error in response", err);
    },
  };
};

export default deleteTaskResponder;

import React, { useCallback, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AssetAPI, EventAPI } from "@griffingroupglobal/eslib-api";

import PropertyFormMaintenance from "../../../stories/Components/AssetForms/FormMaintenance";
import useUsers from "../../../hooks/useUsers";

import { formatServerErrorMessage } from "../../../helpers/Formatters";

import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import { getEventQueryLeftRight } from "../../../helpers/getEventQueryLeftRight";
import usePropertyById from "../../../hooks/usePropertyById";
import usePropertyFormReducer from "../../../hooks/usePropertyFormReducer";
import MaintenanceScheduleModal from "../../../stories/Components/AssetForms/MaintenanceScheduleModal";

const PropertyMaintenance = ({ currentUser }) => {
  const { propertyId } = useParams();
  const { property, propertyMembers, setProperty } =
    usePropertyById(propertyId);
  const [users] = useUsers();
  const { data: managementConfiguration } = useManagementConfiguration();

  const [editedProperty, dispatch] = usePropertyFormReducer();
  const [showMaintenanceModal, setShowMaintenanceModal] = useState(false);
  const [eventsData, setEventsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showGetStarted, setShowGetStarted] = useState(false);

  const fetchEvents = useCallback(() => {
    const getEvents = async () => {
      try {
        const { left, right } = getEventQueryLeftRight(
          property?.metadata?.createdAt
        );
        const { data } = await EventAPI.get({
          params: {
            left,
            right,
            association: `Property/${property?.id}`,
          },
        });
        setShowGetStarted(data?.entries?.length === 0);
        setEventsData(data?.entries);
        if (data?.entries?.length === 0) setIsLoading(false);
      } catch (error) {
        console.warn("Failed to fetch events");
      }
    };

    getEvents();
  }, [property?.id, property?.metadata?.createdAt]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const resetAssetState = React.useCallback(() => {
    dispatch({
      type: "reset",
      property,
    });
  }, [property, dispatch]);

  useEffect(() => {
    if (property) {
      resetAssetState();
    }
  }, [property, resetAssetState]);

  const onFinishEditing = React.useCallback(
    async (key, val) => {
      const finishedProperty = {
        ...editedProperty,
        [key]: val,
      };
      try {
        const newAsset = await AssetAPI.patch(
          propertyId,
          finishedProperty,
          property
        );
        setProperty((prev) => newAsset?.data ?? prev);
      } catch (err) {
        const serverMsg = formatServerErrorMessage(err);
        console.error(serverMsg);
        setIsLoading(false);
      }
    },
    [editedProperty, propertyId, property, setProperty]
  );

  const handleOnCloseModal = useCallback(async () => {
    setShowMaintenanceModal(false);
  }, []);

  const handleUpdatedAsset = useCallback(
    (updated) => {
      setProperty(updated);
    },
    [setProperty]
  );

  return (
    <div className={`mb-10 relative ${isLoading && "loading"} min-h-200`}>
      {/* @TODO Move Add Maintenance To Page Widget Actions. 
        Then + Add Maintenance button may not be needed
      */}
      {/* {currentUser?.hasPermission?.("task", "can_create") && (
        <PrimaryButton
          title="+ Add Maintenance"
          large
          onClick={() => setShowMaintenanceModal(true)}
        />
      )} */}
      <PropertyFormMaintenance
        maintenanceTitle="Property Maintenance"
        setIsModalOpen={setShowMaintenanceModal}
        isLoading={isLoading}
        setLoading={setIsLoading}
        showGetStarted={showGetStarted}
        eventsData={eventsData}
        maintenaceObject={property}
        currentUser={currentUser}
        membersOptions={propertyMembers}
        reloadObject={fetchEvents}
        users={users}
        onFinishEditing={onFinishEditing}
        isTaskDurationRequired={
          managementConfiguration?.management?.managementTasks
            ?.isCompletionTimeRequired
        }
        associationName={editedProperty?.title}
        association={{
          reference: editedProperty?.reference,
          name: editedProperty?.title,
          property: editedProperty?.reference,
        }}
      />

      {showMaintenanceModal && (
        <MaintenanceScheduleModal
          onCloseModal={handleOnCloseModal}
          assetMembersOptions={propertyMembers}
          setUpdatedAsset={handleUpdatedAsset}
          reloadAsset={fetchEvents}
          association={{
            reference: editedProperty?.reference,
            name: editedProperty?.title,
            property: editedProperty?.reference,
          }}
        />
      )}
    </div>
  );
};

export default PropertyMaintenance;

import React from "react";
import PropTypes from "prop-types";
import ServiceRequest from "../../ServiceRequest";
import ServiceRequestsTable from "../../../stories/Components/ServiceRequestsTable";
import useServiceRequestOverview from "./useServiceRequestOverview";

/**
 * Component that renders SR lists and SR details inside P/P/A
 */
const ServiceRequestsOverview = ({ association, asset, setButtonActions }) => {
  const { isLoading, serviceRequestTickets, requestId, setRequestId } =
    useServiceRequestOverview(association, asset, setButtonActions);

  if (requestId) {
    return (
      <ServiceRequest
        requestId={requestId}
        association={association}
        hideHeader
        setButtonActions={setButtonActions}
        onBackButtonPressed={setRequestId}
      />
    );
  }

  return (
    <ServiceRequestsTable
      association={association}
      serviceRequestTickets={serviceRequestTickets}
      isLoading={isLoading}
      onRequestClicked={setRequestId}
    />
  );
};

ServiceRequestsOverview.defaultProps = {
  association: undefined,
  asset: undefined,
  setButtonActions: undefined,
};

ServiceRequestsOverview.propTypes = {
  association: PropTypes.string,
  asset: PropTypes.string,
  setButtonActions: PropTypes.func,
};

export default ServiceRequestsOverview;

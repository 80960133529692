import { getFullName } from "../Formatters";

// converts taskhistory chnages[] to string
// e.g. "steps" -> `Task steps was updated by user A`;
const getTaskActivityDescription = ({ entry, userDict }) => {
  const name = userDict[entry.user]?.name;
  let activity;
  const action = entry.action.toLowerCase();

  switch (action) {
    case "updated": {
      const allChanges = entry.changes.reduce((acc, change, index) => {
        let field;

        switch (change) {
          case "startDate":
            field = "start date";
            break;
          case "endDate":
            field = "end date";
            break;
          case "recurrence":
            field = "repeat";
            break;
          case "invitees":
            field = "assignees";
            break;
          default:
            field = change;
        }
        // if only 1 item
        if (entry.changes.length === 1) {
          return field;
        }

        // second last
        if (index === entry.changes.length - 2) {
          return `${acc} ${field}`;
        }
        // last
        if (index === entry.changes.length - 1) {
          return `${acc} and ${field}`;
        }
        // default
        return `${acc} ${field},`;
      }, "");

      activity = `Task ${allChanges} was ${action}`;
      break;
    }
    default: {
      activity = `Task was ${action}`;
    }
  }

  const date = entry.metadata.lastUpdated;
  return { date, activity, fullName: getFullName(name) };
};

export default getTaskActivityDescription;

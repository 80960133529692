import { useMutation, useQueryClient } from "react-query";
import { ProjectAPI } from "@griffingroupglobal/eslib-api";
import { useCallback } from "react";
import projectKeys from "./projectKeys";

/**
 * Mutation hook to patch a single project
 */
const useProjectsPatch = () => {
  const queryClient = useQueryClient();

  /**
   * Function to patch a project in backend
   * @param {Object} data - single object containing old project and updated project data
   * @param {Object} data.updatedProject - New project data
   * @param {Object} data.project - Old project data
   */
  const patchProject = useCallback(async ({ updatedProject, project }) => {
    const { data } = await ProjectAPI.patch(
      project.id,
      updatedProject,
      project
    );

    return data;
  }, []);

  const projectMutation = useMutation({
    mutationFn: patchProject,
    onMutate: async ({ updatedProject, project }) => {
      const queryKey = projectKeys.byId(project.id);

      // Get the current state of the project to rollback in case of any error
      const currentProject = queryClient.getQueryData(queryKey);

      // Cancel any query for properties in the meantime
      await queryClient.cancelQueries(queryKey);

      // Optimistic update project
      await queryClient.setQueryData(queryKey, (previuos) => ({
        ...previuos,
        ...updatedProject,
      }));

      // Return a context that can be accessed from callbacks below
      return { currentProject, queryKey };
    },
    onSuccess: (data, _, context) => {
      const { queryKey } = context;
      queryClient.setQueryData(queryKey, data);
    },
    onError: (error, variables) => {
      const { queryKey, currentProject } = variables;
      // Rollback project if any error
      queryClient.setQueryData(queryKey, currentProject);

      console.error("useProjectsPatch", error);
    },
    onSettled: (_, error, variables) => {
      if (error) {
        const { project } = variables;
        queryClient.invalidateQueries(projectKeys.byId(project.id));
      }

      queryClient.invalidateQueries({
        queryKey: projectKeys.all,
        exact: true,
      });

      queryClient.invalidateQueries({
        queryKey: projectKeys.overview(),
        exact: true,
      });
    },
  });

  /**
   * Patch project async
   * @param {Object} updatedProject - Updated project
   * @param {Object} project - Current project
   */
  const patchProjectAsync = (updatedProject, project) => {
    return projectMutation.mutateAsync({ updatedProject, project });
  };

  return {
    isPatching: projectMutation.isLoading,
    patchProjectAsync,
  };
};

export default useProjectsPatch;

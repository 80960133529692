const usePillsContainerData = (onClick) => {
  /**
   * Stop propagation and forward click to parent
   */
  const handlePillClick = (event, selectedPill) => {
    event.stopPropagation();
    event.preventDefault();
    onClick(selectedPill.value);
  };

  return {
    handlePillClick,
  };
};

export default usePillsContainerData;

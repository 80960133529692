import { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ADD_OPEN_MODAL, CONFIRM_MODAL } from "../../../../constants";
import convertFromCamelCase from "../../../../helpers/Format/convertFromCamelCase";
import extractParamsFrom from "../../../../helpers/Format/exrtractParamsFrom";
import useAwardTimeoff from "../../../../hooks/timeoff/useAwardTimeoff";
import usePagePersistance from "../../../../hooks/usePagePersistence";
import { useAppState } from "../../../../state/appState";
import { useModalState } from "../../../../state/modalState";
import { toastError, toastMessage } from "../../Toast/Toast";
import useEsModalData from "../useEsModalData";

const useGiveTimeOffModalData = ({ modalData }) => {
  const [{ userDict }] = useAppState();
  const [, modalDispatch] = useModalState();
  const { awardTimeoff } = useAwardTimeoff();
  const { closeModal } = useEsModalData(null, modalData); // anti patter to use a presentation hook in a dif component
  const { pageState } = usePagePersistance();

  const [formData, setFormData] = useState({
    selectedEmployee: undefined,
    type: undefined,
    hours: undefined,
    notes: undefined,
  });

  useEffect(() => {
    const userRef = `User/${pageState.timesheet.userId}`;
    const userFound = userDict[userRef];

    if (userFound) {
      setFormData((prev) => ({
        ...prev,
        selectedEmployee: {
          label: `${userFound.name.firstName} ${userFound.name.lastName}`,
          value: userFound.reference,
        },
      }));
    }
  }, [pageState, userDict]);

  const setSelectedEmployee = (employee) => {
    setFormData((prev) => ({
      ...prev,
      selectedEmployee: employee,
    }));
  };

  const employeeOptions = useMemo(() => {
    return Object.values(userDict)
      .filter((user) => user.isEmployee)
      .map((user) => {
        return {
          label: `${user.name.firstName} ${user.name.lastName}`,
          value: user.reference,
        };
      });
  }, [userDict]);

  const ptoTypeOptions =
    modalData?.item?.ptoAvailBtns &&
    Object.entries(modalData?.item.ptoAvailBtns).map(([key]) => {
      return { label: convertFromCamelCase(key), value: key };
    });

  const setPtoType = (type) => {
    setFormData((prev) => ({
      ...prev,
      type,
    }));
  };

  const setHours = (hours) => {
    if (hours === "") {
      setFormData((prev) => ({
        ...prev,
        hours: "",
      }));
      return;
    }

    const numberHours = typeof hours === "string" ? parseInt(hours, 10) : hours;

    if (Number.isNaN(numberHours)) return;

    setFormData((prev) => ({
      ...prev,
      hours: numberHours,
    }));
  };

  const setNotes = (notes) => {
    setFormData((prev) => ({
      ...prev,
      notes,
    }));
  };

  const submitBtnDisabled = useMemo(() => {
    return !formData.selectedEmployee || !formData.type || !formData.hours;
  }, [formData]);

  const handleSubmit = async (form, isDisabled) => {
    if (isDisabled) return;
    try {
      const { requestBody } = extractParamsFrom(form);

      await awardTimeoff(requestBody);

      // The rest of this code is used when this component is opened from the TimeOffUserView component
      if (!modalData.item.ptoAvailBtns || !modalData.item.setPtoAvailBtn) {
        toastMessage("Time off given successfully");
        return;
      }

      const awardType = requestBody?.award[0].type;
      const awardHours = requestBody?.award[0].amount;

      const floatAvailable = parseFloat(
        modalData.item.ptoAvailBtns[awardType].available,
        10
      );

      const floatAward = parseFloat(awardHours, 10);

      const newTotal = floatAvailable + floatAward;

      const newPtoAvailBtns = {
        ...modalData.item.ptoAvailBtns,
        [awardType]: {
          available: newTotal.toString(),
          name: awardType,
        },
      };

      modalData.item.setPtoAvailBtn(newPtoAvailBtns);

      toastMessage("Time off given successfully");
    } catch (error) {
      toastError("Failed to give time off");
    }
  };

  const inlineStyles = {
    borderWrapper: { width: "543px", height: "375px" },
  };

  const classStyles = {
    widgetCN: "flex flex-col gap-6 border rounded-md p-6 mt-2 items-center",
  };

  const openConfirmModal = () => {
    const timeAndType = `${
      formData.hours
    } ${formData.type.label.toLowerCase()}`;

    closeModal();
    modalDispatch({
      type: ADD_OPEN_MODAL,
      modalType: CONFIRM_MODAL,
      ref: { id: uuidv4() },
      modalData: {
        item: {
          width: "708px",
          title: "Confirm Time Off",
          prompt: `Are you sure you want to give this employee ${timeAndType} hours? This can not be undone.`,
          confirm: "Yes",
          cancel: "Cancel",
          onConfirm: () => handleSubmit(formData, submitBtnDisabled),
        },
      },
    });
  };

  return {
    userDict,
    employeeOptions,
    formData,
    ptoTypeOptions,
    inlineStyles,
    classStyles,
    submitBtnDisabled,
    setSelectedEmployee,
    setPtoType,
    setHours,
    setNotes,
    handleSubmit,
    openConfirmModal,
  };
};

export default useGiveTimeOffModalData;

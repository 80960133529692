import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "../../../stories/Components/Buttons/IconButton";
import arrowRightGray from "../../../stories/assets/images/arrowRightGray.svg";
import { TS_HISTORY_MESSAGE } from "../../../constants";
import HistorySection from "./HistorySection";
import WidgetContainer from "../../../stories/Components/Widget/WidgetContainer";
import useUserDict from "../../../hooks/useUserDict";

const HistoryCard = ({
  data,
  defaultOpen,
  currentUser,
  updateComment,
  period,
}) => {
  const { data: users } = useUserDict();
  const [isOpen, setIsOpen] = useState(defaultOpen);
  return (
    <WidgetContainer className="mb-3 border-gray-200 shadow-lg border rounded-md">
      <div className="flex border-b-2 justify-between items-center pr-4 h-20 px-4">
        <h3 className="font-bold">{data.year}</h3>
        <IconButton
          icon={arrowRightGray}
          onClick={() => setIsOpen((prev) => !prev)}
          className={isOpen && "transform rotate-90"}
        />
      </div>
      <div className="pl-1">
        <h3 className="pl-12 pt-5 pb-5 text-gray-500 font-semibold">
          Activity
        </h3>
      </div>
      {isOpen &&
        data.items?.map((items, i) => (
          <div className="pb-3" key={items?.id}>
            <HistorySection
              historyUser={{
                ...users?.[items?.user ?? items?.author],
                fullName: users
                  ? `${
                      users?.[items?.user ?? items?.author]?.name?.firstName
                    } ${users?.[items?.user ?? items?.author]?.name?.lastName}`
                  : "Loading...",
              }}
              history={items}
              index={i}
              currentUser={currentUser}
              users={users}
              historyActions={TS_HISTORY_MESSAGE(
                `${users?.[items?.user ?? items?.author]?.name?.firstName} ${
                  users?.[items?.user ?? items?.author]?.name?.lastName
                }`,
                period
              )}
              updateComment={updateComment}
              length={data?.items?.length - 1}
            />
          </div>
        ))}
    </WidgetContainer>
  );
};

HistoryCard.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    year: PropTypes.string,
  }),
  defaultOpen: PropTypes.bool,
  currentUser: PropTypes.shape({}),
  updateComment: PropTypes.func,
  period: PropTypes.string,
};

HistoryCard.defaultProps = {
  data: undefined,
  defaultOpen: undefined,
  currentUser: undefined,
  updateComment: undefined,
  period: "",
};

export default HistoryCard;

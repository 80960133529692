import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import "./styles.css";
import ESLogo from "../../assets/images/es-logo.png";

const spinnerCN = cntl`
  fixed
  inset-0
  m-auto
  z-50
  after-inline
  after-w-full
  after-h-full
  after-align-middle
`;

const messageCN = (messageClassName) => cntl`
relative
top-1/2
mx-auto
text-center
mt-20
${messageClassName ?? "text-4xl"}
`;

export function SpinnerInline() {
  return (
    <div className="relative top-1/2 w-14 mx-auto">
      <div className="circle spinner-gradient" />
      <img className="circle-2" src={ESLogo} alt="" />
    </div>
  );
}

const Spinner = ({
  message,
  messageClassName,
  overlay,
  notFullScreen,
  noTranslate,
}) => {
  return (
    <>
      <div
        className={notFullScreen || spinnerCN}
        style={{
          backgroundColor: overlay ? "rgb(255,255,255,0.6)" : "",
          transform: noTranslate ? "" : "translate(-4px, -28px)",
        }}
      >
        <div
          className="relative top-1/2 w-14 mx-auto"
          style={{ tranform: "translate(-50%, -50%)" }}
        >
          <div className="circle spinner-gradient" />
          <img className="circle-2" src={ESLogo} alt="" />
        </div>
        {message && (
          <p className={messageCN(messageClassName)}>
            {message} <span className="dot-1">.</span>
            <span className="dot-2">.</span>
            <span className="dot-3">.</span>
          </p>
        )}
      </div>
    </>
  );
};

export default Spinner;

Spinner.propTypes = {
  message: PropTypes.string,
  messageClassName: PropTypes.string,
  overlay: PropTypes.bool,
  notFullScreen: PropTypes.bool,
  noTranslate: PropTypes.bool,
};

Spinner.defaultProps = {
  message: undefined,
  messageClassName: "",
  overlay: false,
  notFullScreen: false,
  noTranslate: false,
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import redExclamationIcon from "../../assets/images/redExclamationIcon.svg";
import yellowExclamationIcon from "../../assets/images/yellowExclamationIcon.svg";

const WrapperCN = (className, isRed, isActive, disableActiveStyle) => cntl`
  flex 
  flex-row 
  
  border-gray-150
  ${isActive && !disableActiveStyle ? "border-2" : "border"}
  ${
    isActive &&
    !disableActiveStyle &&
    (isRed ? "border-brandRed" : "border-brandYellow")
  }
  ${isRed ? "hover:border-brandRed" : "hover:border-brandYellow"}
  rounded-md 
  p-2 
  items-center
  ${className}
`;
const pillCN = cntl`
  bg-gray-200
  inline-block
  py-0.5 
  px-2.5
  rounded-l-full
  rounded-r-full
  text-sm
  font-bold
  text-white
`;

const textCN = cntl`
  text-gray-300 
  font-semibold 
  text-sm
  pr-2
`;

const AlertTotal = ({
  className,
  total,
  isRed,
  onClick,
  buttonState,
  title,
  disableActiveStyle,
  hideIcon,
}) => {
  const [isActive, setIsActive] = useState(buttonState);

  return (
    <button
      type="button"
      className={WrapperCN(className, isRed, isActive, disableActiveStyle)}
      onClick={() => {
        setIsActive((prev) => !prev);
        onClick();
      }}
    >
      {title && <p className={textCN}>{title}</p>}
      {!hideIcon && (
        <img
          src={isRed ? redExclamationIcon : yellowExclamationIcon}
          alt="warning"
          className="mr-2 w-5 h-5"
        />
      )}
      <span className={pillCN}>{total}</span>
    </button>
  );
};

AlertTotal.propTypes = {
  className: PropTypes.string,
  total: PropTypes.number,
  isRed: PropTypes.string,
  onClick: PropTypes.func,
  buttonState: PropTypes.bool,
  disableActiveStyle: PropTypes.bool,
  hideIcon: PropTypes.bool,
  title: PropTypes.string,
};

AlertTotal.defaultProps = {
  className: undefined,
  total: undefined,
  isRed: undefined,
  onClick: false,
  buttonState: false,
  disableActiveStyle: false,
  hideIcon: false,
  title: undefined,
};

export default AlertTotal;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { DocumentAPI } from "@griffingroupglobal/eslib-api";

import useDocuments from "../../../hooks/useDocuments";
import useFavorites from "../../../hooks/useFavorites";

// eslint-disable-next-line import/no-cycle
import BudgetTable from "../BudgetTable/BudgetTable";
import DocumentUploadForm from "../DocumentUploadForm/DocumentUploadForm";
import AttachItemModal from "../AttachItemModal/AttachItemModal";
import FileCard from "../FileCard/FileCard";

import Spinner from "../Spinner/Spinner";
import Modal from "../Modal/Modal";
import Table from "../Table/Table";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import TertiaryButton from "../Buttons/TertiaryButton";
import { budgetReferencesColumns } from "../DocumentForm/DocumentBudgetColumns";
import {
  ADD_DOCUMENT_ATTACHMENTS,
  REMOVE_DOCUMENT_ATTACHMENT,
  ADD_LINE_ITEMS,
  ADD_NEW_LINE_ITEMS,
  REMOVE_LINE_ITEMS,
  fileCardStyle,
  CHANGE_ORDER,
  REMOVE_NEW_LINE_ITEMS,
  PURCHASE_AUTHORIZATION,
  REMOVE_BUDGET_LINE,
  REMOVE_FORM_DATA,
  CONTINGENCY_AUTHORIZATION,
} from "../../../constants";

const deleteButtonStyle = {
  marginTop: "22px",
};

const MINI_MAP_SCROLL_CONTAINER_ID = "mini-map-document-budget-table";

const DocumentReferences = ({
  document,
  dispatch,
  currentUser,
  property,
  project,
  budget,
  budgetId,
  docTypeOptionsMap,
  selectedBudgetRows,
  csiCodes,
  onBudgetRowSelect,
  unitsOfMeasure,
}) => {
  const { documents, addDocuments, addDocument, removeDocument } =
    useDocuments();
  const [favorites, { postFavorite, deleteFavorite }] = useFavorites();

  const [selectedRowsToAdd, setSelectedRowsToAdd] = useState([]);
  const [newRowsToAdd, setNewRowsToAdd] = useState([]);
  const [showRowsModal, setShowRowsModal] = useState(false);
  const [showAddLinesModal, setShowAddLinesModal] = useState(false);
  const [newDocTypes, setNewDocTypes] = useState([]);
  const [addedFiles, setAddedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState();
  const [parentDocument, setParentDocument] = useState();

  const handleLineUpdate = React.useCallback(
    (action) => {
      dispatch(action);
    },
    [dispatch]
  );

  const referencesColumns = React.useMemo(
    () =>
      budgetReferencesColumns(
        document,
        budget,
        handleLineUpdate,
        selectedBudgetRows,
        true,
        unitsOfMeasure,
        parentDocument?.docType,
        project
      ),
    [
      document,
      budget,
      handleLineUpdate,
      selectedBudgetRows,
      unitsOfMeasure,
      parentDocument?.docType,
      project,
    ]
  );

  useEffect(() => {
    if (documents?.length && document?.documentRelationships?.parent) {
      const parentDoc = documents.find(
        (doc) => doc.reference === document?.documentRelationships?.parent
      );
      setParentDocument(parentDoc);
    }
  }, [documents, document]);

  useEffect(() => {
    if (!selectedBudgetRows?.length && document.workingLineItems?.length) {
      onBudgetRowSelect(document.workingLineItems?.map((item) => item.id));
    }
  }, [selectedBudgetRows, document.workingLineItems, onBudgetRowSelect]);

  useEffect(() => {
    if (document?.attachments?.length) {
      setAddedFiles(document.attachments);
    } else if (
      document?.attachments &&
      !document?.attachments.length &&
      addedFiles.length &&
      !isUploading
    ) {
      setAddedFiles(document.attachments);
    }
  }, [addedFiles, document?.attachments, isUploading]);

  const handleFavoriteClick = React.useCallback(
    (id) => {
      const foundItem = favorites.find((favorite) => {
        return favorite.item.reference.includes(id);
      });
      const foundDoc = documents.find((doc) => doc.id === id);

      if (foundItem) {
        deleteFavorite([foundItem]);
      } else {
        postFavorite(id, "Document");
      }

      if (foundDoc) {
        delete foundDoc.isFavorited;
        dispatch({
          type: ADD_DOCUMENT_ATTACHMENTS,
          value: addedFiles.map((item) => {
            if (item.id === foundDoc.id) {
              return foundDoc;
            }
            return item;
          }),
        });
        addDocument(foundDoc);
      }
    },
    [
      documents,
      favorites,
      addedFiles,
      dispatch,
      deleteFavorite,
      postFavorite,
      addDocument,
    ]
  );

  useEffect(() => {
    const stillUploading = addedFiles.some((item) => item.isUploading);
    if (!stillUploading && isUploading && addedFiles.length) {
      addedFiles.forEach((item) => {
        if (item.isFavorited) {
          handleFavoriteClick(item.id);
        }
      });
      addDocuments(addedFiles);
      dispatch({
        type: ADD_DOCUMENT_ATTACHMENTS,
        value: addedFiles,
      });
      setIsUploading(false);
    }
  }, [addedFiles, isUploading, dispatch, addDocuments, handleFavoriteClick]);

  const handleShowRowsModal = () => {
    setShowRowsModal(true);
  };

  const handleCloseRowsModal = () => {
    setShowRowsModal(false);
    setShowAddLinesModal(false);
  };

  const handleAddRows = () => {
    dispatch({
      type: ADD_LINE_ITEMS,
      value: selectedRowsToAdd.map((item) => {
        return { ...item, checked: false };
      }),
    });
    setShowRowsModal(false);
  };

  const handleRemoveRows = ({ original }) => {
    if (original.isNewLineItem) {
      dispatch({
        type: REMOVE_NEW_LINE_ITEMS,
        value: [original],
      });
    } else {
      dispatch({
        type: REMOVE_LINE_ITEMS,
        value: [original],
      });
    }

    dispatch({
      type: REMOVE_BUDGET_LINE,
      key: original.id,
    });
    if (document.docType === PURCHASE_AUTHORIZATION) {
      dispatch({
        type: REMOVE_FORM_DATA,
        key: PURCHASE_AUTHORIZATION,
        value: original.id,
      });
    }
  };

  const handleShowAddRowsModal = () => {
    setShowAddLinesModal(true);
  };

  const handleAddNewRows = () => {
    dispatch({
      type: ADD_NEW_LINE_ITEMS,
      value: newRowsToAdd.map((item) => {
        return { ...item, checked: false };
      }),
    });
    setShowAddLinesModal(false);
  };

  const handleFileAdded = React.useCallback(
    (fileRef, fileBody, showSpinner) => {
      if (showSpinner) {
        setIsUploading(true);
      }

      const currentFile = {
        isUploading: true,
        reference: fileRef,
        isFavorited: fileBody.isFavorited,
      };

      setAddedFiles((prev) => [currentFile, ...prev]);

      if (fileBody) {
        const tempDocument = {
          customName: fileBody.name,
          docType: fileBody.docType,
          contentReference: fileRef,
          lineItems: [],
          status: "open",
        };

        if (document?.project) {
          tempDocument.project = document?.project;
        } else if (document?.property) {
          tempDocument.property = document?.property;
        }

        DocumentAPI.post(tempDocument)
          .then(({ data: newDocument }) => {
            const resultDocument = {
              ...newDocument,
              creator: currentUser,
              isFavorited: fileBody.isFavorited,
              uploadedFileRef: fileRef,
            };
            setAddedFiles((prev) => {
              return [
                resultDocument,
                ...prev.filter((item) => item.reference !== fileRef),
              ];
            });
          })
          .catch((err) => console.error(err));
      }
    },
    [currentUser, document?.project, document?.property]
  );

  const handleShowDelete = (doc) => {
    setDeleteDoc(doc);
    setShowDeleteModal(true);
  };

  const handleHideDelete = () => {
    setDeleteDoc();
    setShowDeleteModal(false);
  };

  const handleDeleteDocument = async () => {
    setIsDeleting(true);
    await DocumentAPI.delete(deleteDoc.id)
      .then(({ data: docData }) => {
        setAddedFiles((prev) => prev.filter((item) => item.id !== docData.id));
        dispatch({
          type: REMOVE_DOCUMENT_ATTACHMENT,
          value: docData,
        });
        removeDocument(docData.id);
      })
      .catch((err) => {
        console.error(err);
      });

    setIsDeleting(false);
    handleHideDelete();
  };

  const getDocType = React.useCallback(
    (documentType) => {
      if (documentType) {
        const newDocObj = newDocTypes.reduce((obj, val) => {
          return { ...obj, [val.value]: val };
        }, {});
        const tmpValue =
          {
            ...docTypeOptionsMap,
            ...newDocObj,
          }[documentType]?.label ?? documentType;
        return tmpValue;
      }
      return "File";
    },
    [docTypeOptionsMap, newDocTypes]
  );

  const handleBudgetRowSelect = React.useCallback(
    (val) => {
      onBudgetRowSelect(val.map((item) => item.id));
    },
    [onBudgetRowSelect]
  );

  const docBudgetLines = React.useMemo(
    () =>
      document.workingLineItems?.map((item) => {
        const lineData = document.state?.lineItemData?.[item.id] ?? {};
        return {
          ...item,
          csiCode:
            csiCodes.find((code) => code.value === item.csiCode.split(" -")[0])
              ?.label ?? "",
          ...lineData,
        };
      }),
    [document.state, document.workingLineItems, csiCodes]
  );

  return (
    <>
      <SecondaryHeader className="mt-6 mb-6">Budget References</SecondaryHeader>
      <Table
        className=""
        resourceName="budget-items"
        data={docBudgetLines}
        columns={referencesColumns}
        cellStyling="flex items-center h-16"
        cellTextStyling="w-full pb-1"
        deleteButtonStyle={deleteButtonStyle}
        initialSelectedRows={selectedBudgetRows}
        onSelectedRowChange={handleBudgetRowSelect}
        onDeleteRowClick={handleRemoveRows}
        showHeader={false}
        showEditColumns
        showFooter
        hideCreateNewButton
        hideSiteHeader
        hideChangeView
        hideSaveButton
        enableDeleting
        noDeleteConfirm
        disableHover
      />
      <div className="flex">
        <TertiaryButton
          title="+ Add More"
          onClick={handleShowRowsModal}
          disabled={!budget?.lineItems?.length}
        />
        {document.docType === CHANGE_ORDER && (
          // Removed for 3.5
          <div className="hidden">
            <TertiaryButton
              className="ml-1"
              title="+ New Line"
              onClick={handleShowAddRowsModal}
              disabled={!budget?.lineItems?.length}
            />
          </div>
        )}
      </div>

      <SecondaryHeader className="mt-6">Attachments</SecondaryHeader>
      <div className="flex flex-wrap w-full mt-2">
        <DocumentUploadForm
          onAddFile={(fileRef, file) => handleFileAdded(fileRef, file, true)}
          onAddPhoto={(fileRef, file) => handleFileAdded(fileRef, file, true)}
          onAddVideo={(fileRef, file) => handleFileAdded(fileRef, file, true)}
          showCorner
          small
          onNewDocType={(val) => setNewDocTypes((prev) => [val, ...prev])}
        />
        {addedFiles.map((doc) => {
          return (
            <div key={doc.id}>
              <FileCard
                {...doc}
                docType={getDocType(doc.docType)}
                createdAt={
                  doc.metadata?.createdAt
                    ? moment(doc.metadata.createdAt).format("MM/DD/YYYY")
                    : "--"
                }
                isFavorited={
                  doc.isFavorited
                    ? doc.isFavorited
                    : favorites.some((fav) =>
                        fav.item.reference.includes(doc.id)
                      )
                }
                style={fileCardStyle}
                onFileClick={() => {}}
                onFileEdit={() => {}}
                onShowHistory={() => {}}
                onSendToWorkflow={() => {}}
                onUploadVersion={() => {}}
                onFileDelete={() => handleShowDelete(doc)}
                onFavoriteClick={handleFavoriteClick}
              />
            </div>
          );
        })}
      </div>

      <AttachItemModal
        content={
          <>
            <div
              className={
                isLoading
                  ? "flex justify-center items-center w-full h-full"
                  : "hidden"
              }
            >
              <Spinner notFullScreen />
            </div>
            <div className={isLoading ? "hidden" : ""}>
              <BudgetTable
                budgetId={budgetId}
                projectId={project.id}
                projectData={project}
                propertyData={property}
                onRowSelect={setSelectedRowsToAdd}
                setIsLoading={setIsLoading}
                documentModalView
                hideSiteHeader
                tableMiniMapContainerId={MINI_MAP_SCROLL_CONTAINER_ID}
                disableRowsWithRateAndNoUncommitedValuePending={
                  document.docType === PURCHASE_AUTHORIZATION
                }
                disableContingencyLineitem={
                  document.docType === CONTINGENCY_AUTHORIZATION
                }
                disableSelectAllCheckbox={
                  document.docType === CONTINGENCY_AUTHORIZATION
                }
                disbaleRowActions
                viaChangeOrder={document.docType === CHANGE_ORDER}
              />
            </div>
          </>
        }
        isOpen={showRowsModal}
        onRequestModalClose={handleCloseRowsModal}
        onPrimaryClick={handleAddRows}
        tableMiniMapContainerId={MINI_MAP_SCROLL_CONTAINER_ID}
      />

      <AttachItemModal
        content={
          <>
            <div
              className={
                isLoading
                  ? "flex justify-center items-center w-full h-full"
                  : "hidden"
              }
            >
              <Spinner notFullScreen />
            </div>
            <div className={isLoading ? "hidden" : ""}>
              <BudgetTable
                budgetId={budgetId}
                projectId={project.id}
                projectData={project}
                propertyData={property}
                onRowSelect={setNewRowsToAdd}
                setIsLoading={setIsLoading}
                documentModalView
                hideSiteHeader
                pullSummary
                tableMiniMapContainerId={MINI_MAP_SCROLL_CONTAINER_ID}
                disbaleRowActions
              />
            </div>
          </>
        }
        isOpen={showAddLinesModal}
        onRequestModalClose={handleCloseRowsModal}
        onPrimaryClick={handleAddNewRows}
        tableMiniMapContainerId={MINI_MAP_SCROLL_CONTAINER_ID}
      />

      <Modal
        title={
          deleteDoc?.name ? `Delete ${deleteDoc.name}?` : "Delete Document?"
        }
        isOpen={showDeleteModal}
        primaryButtonTitle="Yes, delete"
        primaryButtonOnClick={handleDeleteDocument}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={handleHideDelete}
        deleting={isDeleting}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <>
          <p className="text-base mb-2">
            Are you sure you want to delete {deleteDoc?.name ?? "this document"}
            ? Once deleted, it cannot be recovered.
          </p>
        </>
      </Modal>
    </>
  );
};

DocumentReferences.propTypes = {
  /**
   * document form state
   */
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.object,
  /**
   * document form dispatcher
   */
  dispatch: PropTypes.func,
  /**
   * current user data
   */
  // eslint-disable-next-line react/forbid-prop-types
  currentUser: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  property: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  budget: PropTypes.object,
  budgetId: PropTypes.string,
  /**
   * map of all document type options
   */
  // eslint-disable-next-line react/forbid-prop-types
  docTypeOptionsMap: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  csiCodes: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  selectedBudgetRows: PropTypes.array,
  onBudgetRowSelect: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  unitsOfMeasure: PropTypes.array,
};

DocumentReferences.defaultProps = {
  document: {},
  dispatch: undefined,
  currentUser: {},
  property: {},
  project: {},
  budget: undefined,
  budgetId: undefined,
  docTypeOptionsMap: {},
  csiCodes: [],
  selectedBudgetRows: [],
  onBudgetRowSelect: () => {},
  unitsOfMeasure: [],
};

export default DocumentReferences;

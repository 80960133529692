import PropTypes from "prop-types";
import React, { createContext, useContext, useReducer } from "react";
import {
  ADD_OPEN_MODAL,
  CLOSE_EDIT_MODAL,
  CLOSE_RECUR,
  CLOSE_VIEW,
  CONFIRM_MODAL,
  CONTRACT_MODAL,
  EXPAND_MODAL,
  MAXIMIZE_MODAL,
  MINIMIZE_ALL_MODALS,
  MINIMIZE_MODAL,
  OPEN_RECUR,
  OPEN_VIEW,
  REMOVE_MODAL_INSTANCE,
  REMOVE_POSITIONED_MODALS,
  SET_CALENDAR_MODAL_STATUS,
  SET_EDITING_EVENTS,
  UPDATE_MODAL_INSTANCE,
} from "../constants";

const Context = createContext();
const initialState = {
  create: false,
  view: false,
  recurrence: false,
  eventEditModals: [],
  modals: [],
};

const overviewReducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return initialState;
    case SET_CALENDAR_MODAL_STATUS: {
      let update = {};
      if (Array.isArray(action.key)) {
        action.key.forEach((item, index) => {
          update[item] = action.value[index];
        });
      } else {
        update = { [action.key]: action.value };
      }
      return { ...state, ...update };
    }
    case OPEN_VIEW:
      return { ...state, view: true };
    case CLOSE_VIEW:
      return { ...state, view: false };
    case CLOSE_RECUR:
      return {
        ...state,
        recurrence: false,
        recurrenceAction: undefined,
        recurrenceEvent: undefined,
      };
    case OPEN_RECUR:
      return {
        ...state,
        recurrence: true,
        recurrenceAction: action.key,
        recurrenceEvent: action.event,
        eventEditModals: state.eventEditModals.filter(
          (item) => item.id !== action.id
        ),
      };
    case SET_EDITING_EVENTS:
      return {
        ...state,
        eventEditModals: [...state.eventEditModals, action.event],
      };
    case CLOSE_EDIT_MODAL: {
      return {
        ...state,
        eventEditModals: state.eventEditModals.filter(
          (item) => item.id !== action.id
        ),
        view: false,
      };
    }
    case "SET_VIEWING_EVENTS":
      return {
        ...state,
        viewingEvents: [...state.viewingEvents, action.event],
      };
    /**
     * Moved From App State
     */
    /**
     * Modal Actions
     */
    case ADD_OPEN_MODAL: {
      const { modals } = state;

      /**
       * Check modals for duplicate id
       * if duplicate found
       * dont reopen
       */

      const found = modals.find((item) => item.id === action.ref.id);
      if (found) return state;

      // close all positioned modals
      const nonPositionedModals = modals.filter(
        (item) => !item?.modalData?.position
      );

      return {
        ...state,
        modals: [
          {
            element: action.ref,
            id: action.ref.id,
            minimized: false,
            expanded: false,
            index: nonPositionedModals?.length,
            modalType: action.modalType,
            modalData: {
              ...action.modalData,
              position: action.position,
              id: action.ref.id,
            },
          },
          ...nonPositionedModals?.reduce((list, modal, i) => {
            if (modal.modalType === CONFIRM_MODAL) {
              return list;
            }
            /**
             * Same modal opening (id's are the same)
             * close the previous one
             */
            if (modal.id === action.ref.id) {
              return list;
            }
            /**
             * Close existing modal of type (action.modalType) - EsModal
             */
            if (modal.modalType !== action.modalType) {
              list.push({
                ...modal,
                minimized: true,
                expanded: false,
                index: i,
                modalData: { ...modal.modalData, position: undefined },
              });
            }
            /**
             * After Es Modal is minimized position property is removed
             * now behaves as modal window
             * thus should not be removed if same type is opened but minimized
             */
            if (
              modal.modalType === action.modalType &&
              !modal.modalData.position
            ) {
              list.push({
                ...modal,
                minimized: true,
                expanded: false,
                index: i,
              });
            }
            return list;
          }, []),
        ],
      };
    }

    case MINIMIZE_ALL_MODALS:
      return {
        ...state,
        modals: [
          ...state.modals?.map((modal) => ({
            ...modal,
            minimized: true,
            expanded: false,
            modalData: { ...modal?.modalData, position: undefined },
          })),
        ],
      };

    case MINIMIZE_MODAL: {
      const { modals } = state;
      // find index of the modal to minimize
      const modalIndex = modals.findIndex((item) => item.id === action.id);
      // set necessary properties to minimize
      modals[modalIndex].minimized = true;
      modals[modalIndex].expanded = false;
      // remove position property
      delete modals[modalIndex].modalData.position;
      return { ...state, modals };
    }

    case MAXIMIZE_MODAL: {
      const { modals } = state;
      const i = modals.findIndex((item) => item.id === action.id);
      const activeModal = modals[i];
      activeModal.minimized = false;
      activeModal.index = modals.length - 1;
      modals.splice(i, 1);
      const inactive = modals.map((item, index) => ({
        ...item,
        minimized: true,
        expanded: false,
        index,
      }));
      return { ...state, modals: [...inactive, activeModal] };
    }

    case EXPAND_MODAL: {
      const { modals } = state;
      const i = modals.findIndex((item) => item.id === action.id);

      if (i !== modals.length - 1) {
        const activeModal = modals[i];
        activeModal.minimized = false;
        activeModal.expanded = true;
        activeModal.index = modals.length - 1;
        modals.splice(i, 1);
        const inactive = modals.map((item, index) => ({
          ...item,
          minimized: true,
          expanded: false,
          index,
        }));
        return { ...state, modals: [...inactive, activeModal] };
      }
      modals[i].expanded = true;
      modals[i].minimized = false;
      return { ...state, modals };
    }

    case CONTRACT_MODAL: {
      const { modals } = state;
      const i = modals.findIndex((item) => item.id === action.id);
      modals[i].expanded = false;
      return { ...state, modals };
    }

    case REMOVE_MODAL_INSTANCE: {
      const { modals } = state;
      let reIndex = 0;
      const reIndexed = modals.reduce((list, item) => {
        if (item.id !== action.id) {
          list.push({ ...item, index: reIndex });
          reIndex += 1;
        }
        return list;
      }, []);
      return { ...state, modals: [...reIndexed] };
    }

    case UPDATE_MODAL_INSTANCE: {
      const { modals } = state;
      const modalUpdates = [...modals];
      // find index of the modal to update
      const indexOfItem = state.modals.findIndex(
        (item) => item.id === action.id
      );
      // update modal data with new data
      modalUpdates[indexOfItem] = action.modalData;
      // return updated state
      return { ...state, modals: [...modalUpdates] };
    }

    case REMOVE_POSITIONED_MODALS: {
      const { modals } = state;
      let reIndex = 0;
      const reIndexed = modals.reduce((list, item) => {
        if (!item?.modalData?.position) {
          list.push({
            ...item,
            index: reIndex,
            minimized: true,
            expanded: false,
          });
          reIndex += 1;
        }
        return list;
      }, []);
      return { ...state, modals: [...reIndexed] };
    }
    /**
     * Moved From App State
     */
    default:
      throw new Error();
  }
};

export const ModalWindowProvider = ({ children }) => {
  const value = useReducer(overviewReducer, { ...initialState });
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

ModalWindowProvider.propTypes = {
  /**
   * react component to pass into the context provider
   */
  children: PropTypes.element.isRequired,
};

export const useModalState = () => {
  return useContext(Context);
};

import React from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";

import Modal from "../../../stories/Components/Modal/Modal";
import { PROPERTY_TABLE_PATH } from "../../../constants";

export default function PropertyDeleteModal({
  property,
  showDeleteModal,
  setShowDeleteModal,
  removeProperty,
}) {
  const history = useHistory();

  const onDeleteClicked = () => {
    setShowDeleteModal(false);
    removeProperty(property?.id);
    history.push(PROPERTY_TABLE_PATH);
  };

  return (
    <Modal
      title="Delete Property?"
      isOpen={showDeleteModal}
      primaryButtonTitle="Yes, Delete"
      primaryButtonOnClick={onDeleteClicked}
      tertiaryButtonTitle="Cancel"
      onRequestModalClose={() => setShowDeleteModal(false)}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      hideFooter
      alert
    >
      <>
        <p className="text-base mb-2">
          Are you sure you want to delete {property?.title}? Once deleted, this
          property and its data cannot be recovered.
        </p>
      </>
    </Modal>
  );
}

PropertyDeleteModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  property: PropTypes.object,
  showDeleteModal: PropTypes.bool,
  setShowDeleteModal: PropTypes.func,
  removeProperty: PropTypes.func,
};

PropertyDeleteModal.defaultProps = {
  property: null,
  showDeleteModal: false,
  setShowDeleteModal: () => {},
  removeProperty: () => {},
};

/* eslint-disable react/jsx-props-no-spreading */
import cntl from "cntl";
import React from "react";
import PropTypes from "prop-types";

const tableCellCN = cntl`
  pl-5
  py-2
  flex
  items-center
  text-sm
  relative
`;

const cellText = (className) => cntl`
  truncate
  font-semibold
  ${className}
`;

const getStyles = (props, { id }) => {
  if (id === "selection") {
    return [
      props,
      {
        style: {
          flex: "none",
          width: "60px",
        },
      },
    ];
  }

  return [props, {}];
};

const cellProps = (props, { cell }) => getStyles(props, cell.column);

const GroupedFooterRow = ({ row, onClick, cellTextStyling }) => {
  const hasAtleastOneSubRow =
    row.subRows?.length && !row.subRows[0].isManualGrouped;
  if (hasAtleastOneSubRow) {
    return (
      <div
        className="relative border-gray-200 border-t-0 "
        {...row.getRowProps()}
      >
        <div className="absolute text-gray-200 text-sm font-semibold py-2">
          {row.groupByVal === "undefined"
            ? "Not Specified".toUpperCase()
            : row.groupByVal.toUpperCase()}{" "}
          TOTAL
        </div>
        {row.cells.map((cell) => {
          return cell.isAggregated ? (
            <div
              onClick={() => onClick(row)}
              role="button"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  onClick(row);
                }
              }}
              tabIndex={0}
              {...cell.getCellProps(cellProps)}
              className={tableCellCN}
            >
              <>
                {(cell.column.id &&
                  cell.column.id !== "selection" &&
                  cell.column.id !== "flag" && (
                    <div className={cellText(cellTextStyling)}>
                      {cell.render("Aggregated")}
                    </div>
                  )) || <div className={cellText(cellTextStyling)} />}
              </>
            </div>
          ) : null;
        })}
      </div>
    );
  }

  return <div className="border-t border-gray-150" />;
};

GroupedFooterRow.propTypes = {
  /**
   * sent by react-table, contains row info
   */
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object.isRequired,
  /**
   * function called when the table row is clicked
   */
  onClick: PropTypes.func,
  /**
   * Matching styles from custom table cell styles
   */
  cellTextStyling: PropTypes.string,
};

GroupedFooterRow.defaultProps = {
  onClick: undefined,
  cellTextStyling: undefined,
};

export default GroupedFooterRow;

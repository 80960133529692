import assetKeys from "../../hooks/assets/assetsKeys";

/**
 * Update asset in all possible queries
 * ["assets"]                                    ===> Holds an array of assets
 * ["assets", "3333-6666-4444-8888", "details"]  ===> Holds an object (single asset)
 * ["assets", "Property/1111-2222-3333-4444"]    ===> Holds an array of assets for associations
 * @param queryClient react query client object
 * @param id asset id
 * @param assetUpdated asset with new changes
 */
const mutateAssetCache = (queryClient, id, assetUpdated) => {
  queryClient.setQueriesData(assetKeys.assets, (current) => {
    if (Array.isArray(current)) {
      return current.map((asset) => {
        if (asset.id === id) {
          return assetUpdated;
        }
        return asset;
      });
    }

    if (typeof current === "object" && current.id === id) {
      return assetUpdated;
    }

    return current;
  });
};

export default mutateAssetCache;

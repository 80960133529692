/* eslint-disable consistent-return */
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import useChannels from "../../Pages/Home/Message/useChannels";
import { createChannelId } from "../../helpers/Chat";
import Auth from "../../helpers/Auth";
import useCurrentUser from "../../hooks/useCurrentUser";
import useChannelPatch from "../../Pages/Home/Message/useChannelPatch";
import { toastError } from "../../helpers/Toast";
import { ERROR_DISCONNECTED } from "../../constants";

// Chat context provider to handle a single source of connection
const ChatContext = React.createContext();

/**
 * Hook to get access to the ESChat context
 */
export const useESChatContext = () => useContext(ChatContext);

/**
 * Provides a @clientChat singleton if connection is successfully. Also shares a @selectedChannel if user selects create a channel from Contacts.
 * This allows to keep communication between child components using separate <Chat/> context by Stream Chat
 */
const ESChatContext = ({ children }) => {
  const tenantId = Auth.getTenant();
  const { data: currentUser } = useCurrentUser();
  const [unread, setUnread] = useState(0);

  const [newMsg, setNewMessage] = useState(false);
  const [newChnl, setNewChannel] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState();
  const { data, refetch } = useChannels(setSelectedChannel);

  const [viewMedia, setViewMedia] = useState(false);

  const { mutate } = useChannelPatch();

  const setNewMsg = (val) => {
    setNewMessage(val);
  };

  const getNewChannel = useCallback(
    async (id) => {
      try {
        const newChannel = await data?.client?.queryChannels({
          id: { $in: [id] },
        });
        mutate(newChannel[0]);
      } catch (e) {
        console.error("Error retrieving new message.", e);
      }
    },
    [data?.client, mutate]
  );
  const [selectedMembers, setSelectedMembers] = useState([]);

  const createDirectMessage = useCallback(async () => {
    if (selectedMembers.length === 0) return;
    const channelData = {
      members: [
        ...selectedMembers.map((item) => item.streamId),
        currentUser?.streamId,
      ],
      team: tenantId,
    };
    const channelId = createChannelId(tenantId, channelData.members);
    // create new or use existing conversation
    const conversation = data?.client.channel(
      "messaging",
      channelId,
      channelData
    );
    try {
      await conversation.watch();
      setViewMedia(false);
      setNewMsg(false);
      setSelectedMembers([]);
      setSelectedChannel(conversation);
      mutate(conversation);
      return conversation;
    } catch (e) {
      console.error("Error creating channel", e);
      setSelectedChannel(null);
    }
  }, [currentUser?.streamId, data?.client, mutate, selectedMembers, tenantId]);

  const createChannel = useCallback(
    async (n, members) => {
      const name = n.replace("#", "");
      const nameError = data?.channels?.[1].some((channel) => {
        if (channel.data.name) {
          return channel.data.name === name;
        }
        return false;
      });

      if (nameError) {
        toastError("Duplicate Channel Name");
        return;
      }

      const selectedUsersIds = [
        ...members.map((u) => u.streamId),
        currentUser?.streamId,
      ];

      if (!selectedUsersIds.length) return;

      const channelData = {
        members: selectedUsersIds,
        team: tenantId,
        name,
      };
      try {
        let conversation;
        if (channelData.name) {
          const channelId = uuidv4();
          // create unique channel
          conversation = data?.client.channel("team", channelId, channelData);
        }

        await conversation.watch();

        setSelectedChannel(conversation);
        mutate(conversation);
      } catch (e) {
        console.error("Error creating channel.", e);
      }
    },
    [currentUser?.streamId, data?.channels, data?.client, mutate, tenantId]
  );

  /**
   * Channel Management
   */

  const [channelError, setChannelError] = useState();

  const [showChannelReply, setShowChannelReply] = useState(null);

  const onChannelChange = useCallback(
    (val) => {
      if (!data?.client?.user) {
        setChannelError({ err: ERROR_DISCONNECTED });
        return;
      }
      // Close Media Viewer
      setViewMedia(false);
      // Close New Message
      setNewMsg(false);
      // Close channel replies
      setShowChannelReply(null);
      // Clear channel Errors
      setChannelError();
      // Open the selected channel
      setSelectedChannel(val);

      try {
        // Mark channel read
        if (selectedChannel)
          val?.markRead({
            id: currentUser?.streamId,
          });
      } catch (e) {
        console.error("Failed to mark channel read.");
      }
    },
    [currentUser?.streamId, data?.client?.user, selectedChannel]
  );
  /**
   * Channel Management
   */

  /**
   * Reply Attachments
   */
  const replyAttachments = useRef({});
  /**
   * Reply Attachments
   */

  const value = useMemo(
    () => ({
      clientChat: data?.client,
      directMessages: data?.channels?.[0],
      channels: data?.channels?.[1],
      selectedChannel,
      setSelectedChannel: onChannelChange,
      createDirectMessage,
      createChannelMessage: (val) => {
        setViewMedia(false);
        setNewMsg(false);
        setSelectedChannel(val);
        mutate(val);
      },
      newMsg,
      setNewMsg,
      newChnl,
      setNewChannel,
      createChannel,
      getNewChannel,
      unread,
      setUnread,
      mutate,
      refetch: () => {
        refetch();
        setChannelError();
      },
      channelError,
      setChannelError,
      showChannelReply,
      setShowChannelReply,
      selectedMembers,
      setSelectedMembers,
      // Media
      viewMedia,
      setViewMedia,
      replyAttachments,
    }),
    [
      channelError,
      createChannel,
      createDirectMessage,
      data?.channels,
      data?.client,
      getNewChannel,
      mutate,
      newChnl,
      newMsg,
      onChannelChange,
      refetch,
      selectedChannel,
      selectedMembers,
      showChannelReply,
      unread,
      viewMedia,
    ]
  );

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export default ESChatContext;

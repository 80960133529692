/* eslint-disable no-param-reassign */
import { useMutation } from "react-query";
import { TodoAPI } from "@griffingroupglobal/eslib-api";
import { useCallback } from "react";
import moment from "moment";
import { toastError } from "../helpers/Toast";
import queryClient from "../config/reactQuery/queryClient";
import { todosCodeKeys } from "../config/reactQuery/queryKeyFactory";
import useCurrentUser from "./useCurrentUser";

export default () => {
  const { data: currentUser } = useCurrentUser();
  const createTodo = useCallback(async (todo) => {
    return TodoAPI.post(todo);
  }, []);

  return useMutation({
    mutationFn: createTodo,
    onMutate: async (newTodo) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: todosCodeKeys });

      // Snapshot the previous value
      const previousTodo = queryClient.getQueryData(todosCodeKeys);

      // Optimistically update to the new value
      queryClient.setQueryData(todosCodeKeys, (current) => {
        return [
          ...current,
          {
            ...newTodo,
            metadata: {
              createdAt: moment().toISOString(),
              createdBy: currentUser?.reference,
            },
          },
        ];
      });

      // Return a context with the previous and new todo
      return { previousTodo, newTodo };
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(todosCodeKeys, (current) => {
        return [...current.filter((item) => item?.id), data];
      });
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(todosCodeKeys, context?.previousTodo);
      toastError(
        `There was an error performing this action. \n Message: ${error.message} \n Please try again`
      );
      console.error("useTodoCreate", error);
    },
  });
};

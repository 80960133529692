import { useEffect, useMemo, useRef, useState } from "react";

const useReviewProfileForm = ({ isTNCChecked, isLoading }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoaded, setIsLoaded] = useState(true);
  const [numberOfClicks, setNumberOfClicks] = useState(0);
  const timeoutRef = useRef();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // timeout to fade-in the new page component
    if (!isLoading) {
      timeoutRef.current = setTimeout(() => {
        setIsLoaded(activeIndex);
      }, 300);

      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }
  }, [activeIndex, isLoading]);

  const onTabClick = (index) => {
    // Track number of times user presses next / back
    const next = numberOfClicks + 1;
    setNumberOfClicks(next);
    // After clicking next 2 times (end of flow) don't force user to top of screen
    // 3rd click should be `Finish`
    if (numberOfClicks < 3) {
      document
        .getElementById("profileContainer")
        .scrollTo({ top: 0, behavior: "smooth" });
    }
    // set the page to render
    setActiveIndex(index);
  };

  const canFinish = useMemo(() => {
    return isTNCChecked;
  }, [isTNCChecked]);

  return { activeIndex, isLoaded, onTabClick, canFinish };
};

export default useReviewProfileForm;

/* eslint-disable consistent-return */
const getUpdatedAllTaskSpacesAssets = ({
  currentEntities,
  resourceName,
  newCompletionStatus,
  entity,
}) => {
  const updatedEntityList = currentEntities;
  const spaces = [];
  const assets = [];
  updatedEntityList.forEach((item) => {
    if (
      (entity?.ref && entity?.ref === item?.ref) ||
      (item?.ref && entity.length && resourceName === "assets")
    ) {
      return assets.push({ ...item, isCompleted: newCompletionStatus });
    }
    if (
      (entity?.id && entity?.id === item?.id) ||
      (item?.id && entity.length && resourceName === "spaces")
    ) {
      return spaces.push({ ...item, isCompleted: newCompletionStatus });
    }
    // return non complete spaces
    if (item?.id) {
      return spaces.push({ ...item });
    }

    // return non complete assets
    if (item?.ref) {
      return assets.push({ ...item });
    }
  });
  return { spaces, assets };
};

export default getUpdatedAllTaskSpacesAssets;

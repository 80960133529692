import Api, { FileAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { cloneDeep } from "lodash";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { filePaginatedKeys } from "../config/reactQuery/queryKeyFactory";
import { uploadFileWithData } from "../helpers/File";
import { toastError, toastMessage } from "../stories/Components/Toast/Toast";

// TODO (Josymar) Move this function to future post/patch Files hook in one of those invalidates
// queryClient.invalidateQueries(filePaginatedKeys.allFiles);

/**
 * Query hook that fetch file with presigned url and refetch after 45 min
 * @param {string} fileRef File reference, ex: File/2223-3334-5556
 * @returns query hook
 */
export const useGetFiles = (page) => {
  const queryClient = useQueryClient();

  const getFiles = async () => {
    let pages = 0;
    const { data } = await Api.get("/api/File", {
      paging: false,
      params: {
        limit: 50,
        page,
      },
    });

    const rv = data.entries.reduce((acc, { resource }) => {
      if (
        !resource.clonedFrom &&
        !resource?.association?.startsWith("User") &&
        !resource?.association?.startsWith("Comment")
      ) {
        acc.push(resource);
      }
      return acc;
    }, []);

    pages = data.pages;

    const queryKey = filePaginatedKeys.page(page - 1);
    const currentData = queryClient.getQueryData(queryKey);

    const dict = {
      ...(currentData?.paginatedFilesDict || {}),
      [page]: rv,
    };

    return {
      paginatedFilesDict: dict,
      hasMore: page < pages,
    };
  };

  return useAuthenticatedQuery({
    queryKey: filePaginatedKeys.page(page),
    enabled: !!page,
    queryFn: () => getFiles(page),
    staleTime: 45 * 60 * 1000,
    onError: (error) => {
      console.error("❌ useGetPaginatedFiles", error.message);
    },
  });
};

const postFiles = async (uploadedFiles) => {
  const filesUploaded = await Promise.all(
    uploadedFiles.map(async (file) => {
      const data = {
        name: file.name,
        contentType: file?.type,
        size: file?.size,
      };
      const addedFile = await uploadFileWithData(
        file,
        data,
        undefined,
        undefined,
        true,
        undefined,
        true
      );

      return addedFile;
    })
  );

  return filesUploaded;
};

export const useAddFiles = (page) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postFiles,
    onSuccess: (data) => {
      toastMessage(
        `${data?.length > 1 ? "Files" : "File"} successfully uploaded`
      );

      const queryKey = filePaginatedKeys.page(page);

      queryClient.setQueryData(queryKey, (current) => ({
        ...current,
        paginatedFilesDict: {
          ...(current?.paginatedFilesDict || {}),
          1: [...data, ...current?.paginatedFilesDict[1]],
        },
      }));
    },
    onError: (error) => {
      toastError("Something failed while uploading files, try later");
      console.error("File upload", JSON.stringify(error, null, 2));
    },
  });
};

const removeFiles = async (fileRefs) => {
  await Promise.all(fileRefs?.map((ref) => FileAPI.delete(ref?.split("/")[1])));

  return fileRefs;
};

export const useRemoveFile = (page) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: removeFiles,
    onSuccess: (fileRefs) => {
      toastMessage(
        `${fileRefs?.length > 1 ? "Files" : "File"} successfully deleted`
      );

      const queryKey = filePaginatedKeys.page(page);

      queryClient.setQueryData(queryKey, (current) => {
        const updatedDict = cloneDeep(current?.paginatedFilesDict);

        const pages = Object.keys(updatedDict);
        pages.forEach((pg) => {
          updatedDict[pg] = updatedDict[pg].filter((file) => {
            return !fileRefs.includes(file.reference);
          });
        });

        return {
          ...current,
          paginatedFilesDict: updatedDict,
        };
      });
    },
    onError: (error) => {
      toastError("Something failed while deleting files, try later");
      console.error("File delete", JSON.stringify(error, null, 2));
    },
  });
};

const updateFile = async ({ id, prevFile, updatedFile }) => {
  const { data } = await FileAPI.patch(id, updatedFile, prevFile);

  return data;
};

export const useUpdateFile = (page) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateFile,
    onSuccess: (updatedFile) => {
      toastMessage("File successfully updated");

      const queryKey = filePaginatedKeys.page(page);

      queryClient.setQueryData(queryKey, (current) => {
        const updatedDict = cloneDeep(current?.paginatedFilesDict);

        const pages = Object.keys(updatedDict);
        pages.forEach((pg) => {
          updatedDict[pg] = updatedDict[pg].map((file) => {
            if (updatedFile.reference === file.reference) {
              return { ...file, ...updatedFile };
            }
            return file;
          });
        });

        return {
          ...current,
          paginatedFilesDict: updatedDict,
        };
      });
    },
    onError: (error) => {
      toastError("Something failed while updating file, try later");
      console.error("File update", JSON.stringify(error, null, 2));
    },
  });
};

import React from "react";
import SopHistoryRow from "./SopHistoryRow";
import useSopHistoryData from "./useSopHistoryData";
import AttachmentsHorizontalList from "../../../../stories/Components/AttachmentsHorizontalList";
import Gallery from "../../../../stories/Components/Gallery";
import DescriptionField from "../../../../stories/Components/DescriptionField";

const SopHistory = ({ sop }) => {
  const {
    isLoading,
    versions,
    sopExpandedRowsMap,
    handleRowSelected,
    handleNavigateToSop,
    getDateFormatted,
    getUpdatedBy,
    getFiles,
  } = useSopHistoryData(sop);

  return (
    <div className={`${isLoading ? "loading" : ""}`}>
      {versions.map((item) => {
        const { version } = item;
        const { isExpanded, data } = sopExpandedRowsMap[version] || {};
        const { media, nonMedia } = getFiles(data) || {};

        return (
          <div
            key={version}
            className={`mb-4 p-1 relative w-full rounded-lg border-gray-150 border ${
              isExpanded ? "bg-backgroundGrey" : ""
            }`}
          >
            <div
              role="button"
              tabIndex="0"
              onClick={() => handleRowSelected(version)}
              onKeyDown={() => handleRowSelected(version)}
              aria-label={`Selected version ${version}`}
            >
              <SopHistoryRow
                sop={item}
                isExpanded={isExpanded}
                getDateFormatted={getDateFormatted}
                handleNavigateToSop={handleNavigateToSop}
              />
            </div>

            {isExpanded && (
              <div className="border-gray-150 border-t pt-2 pb-8">
                <div className="flex justify-center">
                  {/* Sop version data is fetching */}
                  {!data && <div className="loading" />}

                  <div className="w-full md:w-1/2 p-4">
                    <div className="">
                      <p className="text-es-normal font-es-semibold text-es-medium-grey mb-2">
                        Modified By
                      </p>
                      <p className="text-es-normal font-es-normal text-es-medium-grey">
                        {getUpdatedBy(data?.lastUpdatedBy)}
                      </p>
                    </div>

                    <div className="pt-6">
                      <p className="text-es-normal font-es-semibold text-es-medium-grey mb-2">
                        Steps
                      </p>

                      {data?.steps?.length > 0 &&
                        data.steps.map((step, idx) => (
                          <li key={step.id} className="flex items-start">
                            <span className="text-es-normal font-es-normal text-es-medium-grey mr-2">
                              {idx + 1}.
                            </span>
                            <span className="text-es-normal font-es-normal text-es-medium-grey">
                              {step?.description}
                            </span>
                          </li>
                        ))}
                    </div>
                  </div>

                  <div className="w-full md:w-1/2 p-4">
                    <div className="">
                      <p className="text-es-normal font-es-semibold text-es-medium-grey mb-2">
                        Description
                      </p>
                      <DescriptionField text={data?.description} />
                    </div>

                    <div className="pt-6">
                      <p className="text-es-normal font-es-semibold text-es-medium-grey mb-2">
                        Media
                      </p>
                      {media.length > 0 && <Gallery mediaFiles={media} />}
                    </div>

                    <div className="pt-6">
                      <p className="text-es-normal font-es-semibold text-es-medium-grey mb-2">
                        Attachments
                      </p>
                      {nonMedia.length > 0 && (
                        <AttachmentsHorizontalList files={nonMedia} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SopHistory;

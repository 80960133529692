import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

import "./Checkbox.css";

const containerCN = (className) => cntl`
  flex
  items-center
  ${!className?.includes("gap") && "gap-3"}
  ${className}
`;

const labelCN = (labelClassName) => cntl`
  text-xs
  flex-1
  ${labelClassName || "text-gray-300 font-semibold text-xs"}
`;

const inputCN = (
  disabled,
  isFocussed,
  onFocusClassName,
  checked,
  reject,
  isCalendarCheckbox,
  isTask,
  isMaintenance,
  size = 4,
  inputClassName
) => cntl`
  ${!reject ? "form-tick" : "form-cross"} 
  appearance-none
  border
  cursor-pointer
  ${`w-${size}`}
  ${`h-${size}`}
  ${inputClassName}
  rounded
  ${isCalendarCheckbox && cntl`${checked && isTask && "checked:bg-brandBlue"}`}
  ${
    isCalendarCheckbox &&
    cntl`${checked && isMaintenance && "checked:bg-purple-300"}`
  }
  ${
    isCalendarCheckbox &&
    cntl`${checked && !isTask && !isMaintenance && "checked:bg-brandDarkGreen"}`
  }
  ${
    disabled
      ? cntl`checked:bg-gray-300 bg-gray-150 border-gray-150`
      : cntl`${checked && !reject && "bg-brandDarkGreen"}`
  }
  ${
    disabled
      ? cntl`checked:bg-gray-300 bg-gray-150 border-gray-150`
      : cntl`${checked && reject && "bg-brandRed"}`
  }
  checked:border-transparent
  ${
    !checked &&
    !disabled &&
    !inputClassName?.includes("bg-") &&
    "bg-backgroundGreen"
  }
  ${
    !checked &&
    !disabled &&
    inputClassName?.includes("bg-") &&
    cntl`${inputClassName}`
  }
  ${isFocussed && onFocusClassName}
`;

const Checkbox = React.forwardRef(
  (
    {
      indeterminate,
      label,
      className,
      checked,
      onChange,
      disabled,
      onChangeSendEvent,
      labelClassName,
      onFocusClassName,
      stopEventPropagation,
      reject,
      isCalendarCheckbox,
      isTask,
      isMaintenance,
      onKeyUp,
      name,
      id,
      labelLeft,
      size,
      inputClassName,
    },
    ref
  ) => {
    const [isFocussed, setIsFocussed] = useState();
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      if (resolvedRef.current)
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    const handleEnter = useCallback(
      (e) => {
        if (e.code === "Enter") onChange(!checked);
      },
      [checked, onChange]
    );

    return (
      <div
        className={containerCN(className)}
        onClick={(e) => {
          if (stopEventPropagation) {
            e.stopPropagation();
          }
        }}
        tabIndex={0}
        onKeyDown={handleEnter}
        role="button"
      >
        {labelLeft && label && (
          <p className={labelCN(labelClassName)}>{label}</p>
        )}
        <input
          id={id}
          onKeyUp={onKeyUp}
          name={name}
          data-testid="CheckBoxUT"
          className={inputCN(
            disabled,
            isFocussed,
            onFocusClassName,
            checked,
            reject,
            isCalendarCheckbox,
            isTask,
            isMaintenance,
            size,
            inputClassName
          )}
          style={{ minWidth: "16px" }}
          ref={resolvedRef}
          type="checkbox"
          checked={checked}
          onChange={(event) =>
            onChange(onChangeSendEvent ? event : event?.target?.checked)
          }
          disabled={disabled}
          onFocus={() => setIsFocussed(true)}
          onBlur={() => setIsFocussed(false)}
        />
        {!labelLeft && label && (
          <p className={labelCN(labelClassName)} title={label}>
            {label}
          </p>
        )}
      </div>
    );
  }
);

Checkbox.propTypes = {
  /**
   * the text displayed in the label
   */
  label: PropTypes.string,
  /**
   * class to apply to the container
   */
  className: PropTypes.string,
  /**
   * determines if the checkbox is checked or not
   */
  checked: PropTypes.bool,
  /**
   * function called when the checkbox changes
   */
  onChange: PropTypes.func,
  /**
   * disables the button
   */
  disabled: PropTypes.bool,
  /**
   * if true, sends the full event object onChange. Mainly used by react-table checkboxes
   */
  onChangeSendEvent: PropTypes.bool,
  /**
   * prop passed from react-table to get state for the checkbox
   */
  indeterminate: PropTypes.bool,
  /**
   * class to apply to the label
   */
  labelClassName: PropTypes.string,
  onFocusClassName: PropTypes.string,
  stopEventPropagation: PropTypes.bool,
  /**
   * Cross CheckBox
   */
  reject: PropTypes.bool,
  /**
   * checks if the checkbox belongs to the calendar, in order to change background color
   * default is false, must be turned on to change background colors
   */
  isCalendarCheckbox: PropTypes.bool,
  /**
   * more calendar checkbox handling
   * checks if event isTask or Event, mx will come in the future
   * default is undefined, and will be handled by calendar checkbox component
   */
  isTask: PropTypes.bool,
  isMaintenance: PropTypes.bool,
  onKeyUp: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
  labelLeft: PropTypes.bool,
  // Size of checkbox
  size: PropTypes.string,
  inputClassName: PropTypes.string,
};

Checkbox.defaultProps = {
  label: undefined,
  className: undefined,
  checked: false,
  onChange: undefined,
  disabled: false,
  indeterminate: undefined,
  onChangeSendEvent: false,
  labelClassName: undefined,
  onFocusClassName: undefined,
  stopEventPropagation: false,
  reject: false,
  isCalendarCheckbox: false,
  isTask: false,
  isMaintenance: undefined,
  onKeyUp: undefined,
  name: undefined,
  id: undefined,
  labelLeft: false,
  size: undefined,
  inputClassName: undefined,
};

export default Checkbox;

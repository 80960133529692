import {
  INPUT_BG_DISABLED,
  INPUT_BG_ENABLED,
  INPUT_BG_GREEN,
  PRIMARY_DARK_GREEN,
} from "../../constants";

const fontSize = "0.875rem";

const dropdownBaseStyle = {
  container: (baseStyles, { isDisabled }) => ({
    ...baseStyles,
    backgroundColor: isDisabled ? INPUT_BG_DISABLED : INPUT_BG_ENABLED,
    borderColor: isDisabled ? INPUT_BG_DISABLED : INPUT_BG_ENABLED,
    borderRadius: 6,
    fontSize,
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    padding: 2,
    backgroundColor: "transparent",
    fontSize,
  }),
  option: (baseStyles, { isDisabled, isSelected }) => ({
    ...baseStyles,
    borderBottom: `0.1em inset ${INPUT_BG_DISABLED}`,
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: isSelected
      ? INPUT_BG_GREEN
      : isDisabled
      ? INPUT_BG_DISABLED
      : "white",
    ":active": {
      backgroundColor: "transparent",
    },
    ":hover": {
      backgroundColor: isDisabled ? INPUT_BG_DISABLED : INPUT_BG_GREEN,
    },
  }),
  multiValueLabel: (baseStyles) => ({
    ...baseStyles,
    color: PRIMARY_DARK_GREEN,
    fontSize: 12,
  }),
  multiValue: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: "white",
    borderWidth: 1,
    borderRadius: 20,
  }),
  multiValueRemove: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: "transparent",
    color: PRIMARY_DARK_GREEN,
    ":hover": {
      backgroundColor: "transparent",
    },
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
    fontSize,
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    fontSize,
  }),
};

export default dropdownBaseStyle;

import { DocumentAPI } from "@griffingroupglobal/eslib-api";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { vendorinvoiceKeys } from "../config/reactQuery/queryKeyFactory";

const fetchMergedVendorinvoices = async (project) => {
  const { data } = await DocumentAPI.getWOP("$getmergedvendorinvoices", {
    params: { project },
  });

  return {
    vendorinvoices: data,
  };
};
/**
 * Query hook to fetch Expenses
 * @returns query hook
 */
const useGetMergedVendorInvoices = (projectRef) => {
  const queryKey =
    vendorinvoiceKeys.mergedVendorinvoicesByAssociation(projectRef);

  return useAuthenticatedQuery({
    queryKey,
    enabled: !!projectRef,
    queryFn: () => fetchMergedVendorinvoices(projectRef),
    onError: (error) => {
      console.error("Error fetching Merged Vendorinvoices", error);
    },
  });
};

export default useGetMergedVendorInvoices;

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import MembersRow from "../Members/MembersRow";
import Widget from "../Widget/Widget";
import SimpleSearch from "../SearchBar/StatelessSearch";
import LinksView from "../Links/LinksView";
import SelectUserInterface from "../SelectUserInterface/SelectUserInterface";
import { getUserFirstAndLast } from "../../../helpers/Utilities";

const ContactAssigneesForm = ({
  currentUser,
  unassignedContacts,
  setUnassignedContacts,
  companyAssignees,
  setCompanyAssignees,
  isEditing,
  isLoading,
  handleNavigate,
  contactDispatch,
  contactResource,
}) => {
  const memberSearchRef = useRef();
  const contactSearchRef = useRef();

  const isAdmin = currentUser?.isAdmin;

  useEffect(() => {
    // update the state to display on render
    if (contactResource?.originalResource) {
      contactDispatch({
        type: "setLinks",
        links: contactResource?.originalResource?.links,
      });
    }
  }, [contactDispatch, contactResource?.originalResource]);
  // Search State for Company (ES)Member
  const [memberSearchState, setMemberSearchState] = useState({
    members: null,
    membersValue: null,
  });

  // Search State for Company Contacts
  const [contactsSearchState, setContactsSearchState] = useState({
    contacts: null,
    contactsValue: null,
  });

  // Memoized Array for Company (ES) Members
  const companyMembers = useMemo(
    () => companyAssignees?.filter((item) => item?.kind === "member"),
    [companyAssignees]
  );

  // Memoized Array for Company Contacts
  const companyContacts = useMemo(
    () => companyAssignees?.filter((item) => item?.kind !== "member"),
    [companyAssignees]
  );

  // Company (ES)Member Search Handler
  const handleMemberSearch = useCallback((arr, value) => {
    setMemberSearchState({ members: arr, value });
  }, []);

  // Company Contact Widget Search Handler
  const handleContactSearch = useCallback((arr, value) => {
    setContactsSearchState({ contacts: arr, value });
  }, []);

  // Add a Member to the company (ES member or nonMember)
  const onAdd = useCallback(
    (assignee) => {
      setCompanyAssignees((prev) => [...prev, assignee[0]]);
      setUnassignedContacts((prev) =>
        prev.filter((contact) => contact.value !== assignee[0].value)
      );
    },
    [setCompanyAssignees, setUnassignedContacts]
  );

  // Remove Member of Company (ES Member or nonMember)
  const onRemove = useCallback(
    (user) => {
      const userWithLabel = { ...user, label: getUserFirstAndLast(user) };
      setCompanyAssignees((prev) =>
        prev.filter((assignee) => {
          if (assignee === user) {
            return false;
          }
          return true;
        })
      );
      setUnassignedContacts((prev) => [...prev, userWithLabel]);
    },
    [setCompanyAssignees, setUnassignedContacts]
  );

  return (
    <div className="flex flex-row w-full gap-4">
      {/* Widget Containing contact.kind === member */}
      <Widget
        title="Members"
        draggable={false}
        width="1/3"
        childClassName="flex flex-col justify-center"
        overflow
      >
        <div
          className={`${isLoading || !companyMembers} && "loading"
        } w-full flex flex-col gap-4 flex-1`}
        >
          <SimpleSearch
            options={companyMembers}
            keys={["name.firstName", "name.lastName"]}
            onChange={handleMemberSearch}
            value={memberSearchState.membersValue}
            forwardedRef={memberSearchRef}
            placeholder="Search"
            disabled={companyMembers?.length === 0}
          />
          <div
            className="flex flex-row w-full overflow-x-auto"
            style={{ maxHeight: "240px", minHeight: "240px" }}
          >
            <MembersRow
              isEditing={isEditing && isAdmin}
              onClick={handleNavigate}
              handleRemove={onRemove}
              currentMembers={memberSearchState?.members ?? companyMembers}
              resource="Members"
            />
          </div>
          {isEditing && isAdmin && (
            <div className="border-t pt-2">
              <SelectUserInterface
                className="w-full"
                userList={[]}
                userLabel="Add Member"
                userPlaceholder="Select"
                userOptions={unassignedContacts
                  ?.filter(
                    (item) =>
                      item?.name?.firstName &&
                      item?.name?.lastName &&
                      item?.kind === "member"
                  )
                  ?.map(
                    ({ label, value, avatar, name, id, reference, kind }) => ({
                      label,
                      value,
                      name,
                      reference,
                      id,
                      avatar,
                      kind,
                    })
                  )}
                onAddUser={(val) => onAdd(val)}
                isSingleSelect
                disableCreateUser
              />
            </div>
          )}
        </div>
      </Widget>
      {/* Company Contacts (non ES Members)  */}
      <Widget
        title="Contacts"
        draggable={false}
        width="1/3"
        childClassName="flex flex-col justify-center"
        overflow
      >
        <div
          className={`${isLoading || !unassignedContacts.length}) && "loading"
        } w-full flex flex-col gap-4 flex-1`}
        >
          <SimpleSearch
            options={companyContacts}
            keys={["name.firstName", "name.lastName"]}
            onChange={handleContactSearch}
            value={contactsSearchState.contactsValue}
            forwardedRef={contactSearchRef}
            placeholder="Search"
            disabled={companyContacts?.length === 0}
          />
          <div
            className="flex flex-row w-full overflow-x-auto"
            style={{ maxHeight: "240px", minHeight: "240px" }}
          >
            <MembersRow
              isEditing={isEditing && isAdmin}
              onClick={handleNavigate}
              handleRemove={onRemove}
              currentMembers={contactsSearchState?.contacts ?? companyContacts}
              resource="Contacts"
            />
          </div>
          {isEditing && isAdmin && (
            <div className="border-t pt-2">
              <SelectUserInterface
                className="w-full"
                userList={[]}
                userLabel="Add Contact"
                userPlaceholder="Select"
                userOptions={unassignedContacts
                  ?.filter(
                    (item) =>
                      item?.name?.firstName &&
                      item?.name?.lastName &&
                      item?.kind !== "member"
                  )
                  ?.map(
                    ({ label, value, avatar, name, id, reference, kind }) => ({
                      label,
                      value,
                      name,
                      reference,
                      id,
                      avatar,
                      kind,
                    })
                  )}
                onAddUser={(val) => onAdd(val)}
                isSingleSelect
                disableCreateUser
              />
            </div>
          )}
        </div>
      </Widget>
      {/* Links Widget */}
      <LinksView
        resource={contactResource}
        disableEditing={!isEditing}
        dispatch={contactDispatch}
        editing={isEditing}
        widgetWidth="1/3"
        className="flex flex-col"
        loading={!contactResource}
      />
    </div>
  );
};

ContactAssigneesForm.propTypes = {
  currentUser: PropTypes.shape({
    isAdmin: PropTypes.bool,
  }),
  contactDispatch: PropTypes.func,
  contactResource: PropTypes.shape({
    originalResource: PropTypes.shape({
      links: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    links: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  unassignedContacts: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      avatar: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  isEditing: PropTypes.bool,
  setUnassignedContacts: PropTypes.func,
  companyAssignees: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  setCompanyAssignees: PropTypes.func,
  handleNavigate: PropTypes.func,
  isLoading: PropTypes.bool,
};

ContactAssigneesForm.defaultProps = {
  currentUser: undefined,
  contactDispatch: undefined,
  contactResource: undefined,
  unassignedContacts: [],
  setUnassignedContacts: undefined,
  handleNavigate: undefined,
  companyAssignees: [],
  setCompanyAssignees: undefined,
  isEditing: false,
  isLoading: false,
};

export default ContactAssigneesForm;

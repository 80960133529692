import { useCallback, useEffect, useRef, useState } from "react";
import { SubmittalAPI, FileAPI } from "@griffingroupglobal/eslib-api";
import { useAppState } from "../state/appState";
import { getFullName } from "../helpers/Formatters";
import useManagementConfiguration from "./useManagementConfiguration";

// Helper functions to transform data
const mapFilesData = (filesData) =>
  filesData.reduce((acc, attachment) => {
    const id = attachment.reference;
    acc[id] = attachment;
    return acc;
  }, {});

const mapDistroData = (data, userDict) => {
  const list = data?.distribution?.map((ref) => {
    const userInfo = userDict[ref];
    return userInfo;
  });
  return list;
};

const mapWorkflowSteps = (steps, userDict) =>
  steps.map((step) => {
    const fullUsers = step?.users?.reduce((acc, user) => {
      const fullUser = {
        ...user,
        data: userDict[user.reference],
      };
      return [...acc, fullUser];
    }, []);
    return { ...step, users: fullUsers };
  });

const mapProjectMembers = (project, userDict) => {
  const membersList = project?.members?.map((mem) => {
    const member = userDict[mem.user];
    const name = getFullName(member?.name);
    const ref = member?.reference;
    return { label: name, value: ref };
  });
  return membersList;
};

/**
 * // TODO (Remove this file after refactor)
 * @deprecated
 */
export default function useSubmittalById(submittalId) {
  const [{ projectDict, userDict }] = useAppState();
  const { data: managementConfiguration } = useManagementConfiguration();
  // Refs and state variables
  const userDictRef = useRef(userDict);
  const projectRef = useRef(projectDict);
  const managementRef = useRef(managementConfiguration);

  // Other state variables
  const [submittal, setSubmittal] = useState({});
  const [project, setProject] = useState({});
  const [distroList, setDistroList] = useState([]);
  const [attachmentMap, setAttachmentMap] = useState({});
  const [projectMembers, setProjectMembers] = useState([]);
  const [type, setType] = useState({});
  const [loading, setLoading] = useState(false);
  const [initiated, setInitiated] = useState({});
  const [ballInCourt, setBallInCourt] = useState({});
  const [workflowSteps, setWorkflowSteps] = useState([]);
  const [ditionariesReady, setDictionariesReady] = useState(false);

  // Keeping refs updated with latest dictionary states
  useEffect(() => {
    if (managementConfiguration && userDict && projectDict) {
      userDictRef.current = userDict;
      projectRef.current = projectDict;
      managementRef.current = managementConfiguration;
      // Won't allow to fetch submittals from this hook
      setDictionariesReady(false);
    }
  }, [managementConfiguration, projectDict, userDict]);

  // Fetching and mapping data
  const reload = useCallback(async () => {
    if (ditionariesReady) {
      setLoading(true);
      try {
        const { data } = await SubmittalAPI.getById(submittalId);
        const allFileRefs = [...data.attachments?.map((file) => file?.ref)];

        const allsteps = data.requestWorkflow[0]?.steps || [];
        allsteps.forEach((step) =>
          allFileRefs.push(...step.attachments?.map((file) => file?.ref))
        );

        data.rounds.forEach((round) =>
          round?.requestWorkflow?.[0]?.steps.forEach((step) => {
            allFileRefs.push(...step.attachments?.map((file) => file?.ref));
          })
        );

        const allFilesData = await FileAPI.get({
          params: { reference: allFileRefs.toString() },
        });
        const files = allFilesData.data.entries.map((item) => item.resource);

        // Map the data using the helper functions
        setAttachmentMap(mapFilesData(files));

        setDistroList(mapDistroData(data, userDictRef.current));
        setProjectMembers(
          mapProjectMembers(
            projectRef.current[data.association],
            userDictRef.current
          )
        );
        setWorkflowSteps(mapWorkflowSteps(allsteps, userDictRef.current));

        // Set the remaining state variables
        const initiatedUser = userDictRef.current[data?.initiated?.user];
        const ballInCourtUser =
          userDictRef.current[data.ballInCourt[0]?.user] ?? "";
        const submittalType =
          managementRef.current.management.submittal.types.find(
            (item) => item.id === data.type
          );
        setInitiated({ user: initiatedUser, date: data?.initiated?.date });
        setBallInCourt({
          user: ballInCourtUser,
          due: data?.ballInCourt[0]?.due ?? "",
        });
        setSubmittal(data);
        setProject(projectRef.current[data.association]);
        setType(submittalType);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }, [ditionariesReady, submittalId]);

  // Other operations and side effects
  const handleSendReminder = useCallback(
    async (note, userId) => {
      try {
        await SubmittalAPI.postByIdWOP(submittalId, "$sendreminder", {
          note: note || "",
          sentTo: [`User/${userId}`],
        });
        reload();
      } catch (error) {
        console.warn("Failed to send reminder");
      }
    },
    [reload, submittalId]
  );

  // The values returned by this custom hook
  return {
    submittal,
    setSubmittal,
    loading,
    setLoading,
    reload,
    attachmentMap,
    setAttachmentMap,
    handleSendReminder,
    project,
    distroList,
    type,
    initiated,
    ballInCourt,
    workflowSteps,
    projectMembers,
  };
}

import React from "react";
import PropTypes from "prop-types";
import { addDays } from "@fullcalendar/core";
import cntl from "cntl";
import moment from "moment";
import eventIcon from "../../assets/images/eventIcon.png";
import DatePicker from "../DatePicker/DatePicker";
import Dropdown from "../Dropdown/Dropdown";
import Input from "../Input/Input";
import { getDurationValue } from "../../../helpers/Project";
import { durationTypeOptions } from "../../../constants";
import Widget from "../Widget/Widget";
import useProjectDurationData from "./useProjectDurationData";

const eventIconElement = (
  <img className="w-4 h-4" src={eventIcon} alt="event icon" />
);

const cardCN = cntl`
  w-full
  px-4
`;

const circleCN = (project) => cntl`
    w-3
    h-3
    rounded-full
    ${
      project.contractDate && project.startDate
        ? "bg-brandGreen"
        : "bg-gray-200"
    }
  `;

const titleItemCN = cntl`
  font-bold 
  flex-0 
  text-gray-700 
  text-sm 
  w-60
  py-5
`;

const dateValueCN = cntl`
    text-gray-200
`;

const ProjectDuration = ({ isEditing, project, onChange, onChangeObject }) => {
  const {
    wrapperRef,
    handleKeyDown,
    startChangeHandler,
    endChangeHandler,
    dateChangeHandler,
    durationTypeChangeHandler,
    durationChangeHandler,
  } = useProjectDurationData({
    project,
    onChange,
    onChangeObject,
    durationTypeOptions,
  });

  return (
    <Widget title="Details" draggable={false} className="mb-4" overflow>
      <div className={cardCN} ref={wrapperRef}>
        <div className="mr-3 border-b w-full pb-4">
          <p className={titleItemCN}>Signed Contract Date</p>
          {isEditing ? (
            <DatePicker
              iconLeft={eventIconElement}
              value={project.contractDate}
              placeholder="MM-DD-YYYY"
              onChange={dateChangeHandler}
            />
          ) : (
            <p className={dateValueCN}>
              {project?.contractDate &&
                moment(project?.contractDate).format("LL")}
            </p>
          )}
        </div>
        <div className="flex-col w-full pb-4 border-b">
          <p className={titleItemCN}>Estimated Duration</p>
          <div className="flex items-end">
            {isEditing ? (
              <>
                <div className="w-1/2">
                  <Input
                    placeholder="Duration"
                    type="number"
                    minValue={0}
                    value={project.duration?.value}
                    onChange={durationChangeHandler}
                    disableClear
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="ml-6 w-1/2">
                  <Dropdown
                    options={durationTypeOptions}
                    value={
                      durationTypeOptions.find(
                        (item) =>
                          item.value === project?.duration?.typeOfDuration
                      ) ?? durationTypeOptions[0]
                    }
                    placeholder="Select"
                    onChange={durationTypeChangeHandler}
                    disableClear
                  />
                </div>
              </>
            ) : (
              <>
                <p className={dateValueCN}>
                  {getDurationValue(
                    project?.duration?.value ?? 0,
                    project?.duration?.typeOfDuration ??
                      durationTypeOptions[0]?.value
                  )}
                </p>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col mb-4">
          <div className="flex">
            <p className="font-bold text-gray-700 text-sm w-3/5 py-5">
              Start Date
            </p>
            <p className="font-bold text-gray-700 text-sm w-2/5 py-5">
              End Date
            </p>
          </div>
          <div className="flex items-center">
            <div className="w-2/5">
              {isEditing ? (
                <DatePicker
                  iconLeft={eventIconElement}
                  value={project.startDate}
                  maxDate={
                    project.endDate && addDays(new Date(project.endDate), -1)
                  }
                  placeholder="MM/DD/YYYY"
                  onChange={startChangeHandler}
                />
              ) : (
                <div className="flex items-center">
                  <p className={dateValueCN}>
                    {project.startDate
                      ? moment(project.startDate).format("MM/DD/YYYY")
                      : "MM/DD/YYYY"}
                  </p>
                  <div className="ml-3 flex-auto h-px bg-gray-200" />
                </div>
              )}
            </div>
            <div className="flex w-1/5 items-center">
              <div className="w-3/5 h-px bg-gray-200" />
              <div className={circleCN(project)} />{" "}
            </div>
            <div className="w-2/5">
              {isEditing ? (
                <DatePicker
                  iconLeft={eventIconElement}
                  value={project.endDate}
                  placeholder="MM/DD/YYYY"
                  onChange={endChangeHandler}
                  nonEditable
                />
              ) : (
                <p className={dateValueCN}>
                  {project.endDate
                    ? moment(project.endDate).format("MM/DD/YYYY")
                    : "MM/DD/YYYY"}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Widget>
  );
};

ProjectDuration.propTypes = {
  isEditing: PropTypes.bool,
  project: PropTypes.shape({
    duration: PropTypes.shape({
      value: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
      typeOfDuration: PropTypes.string,
    }),
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    contractDate: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onChangeObject: PropTypes.func,
  originalResource: PropTypes.shape({}),
};

ProjectDuration.defaultProps = {
  isEditing: false,
  project: undefined,
  onChange: undefined,
  onChangeObject: undefined,
  originalResource: undefined,
};

export default ProjectDuration;

/**
 * Finds employees who do not have a corresponding entry in the edited timesheet list.
 *
 * This function takes two arrays: one of employees and one of edited timesheets. It returns
 * an array of employees who do not have a matching reference in the edited timesheet list.
 * The matching is done based on the `reference` property of employees and the `userRef`
 * property of the timesheets.
 *
 * @param {Object[]} employees - An array of employee objects.
 * @param {Object[]} editedTimesheetList - An array of timesheet objects.
 * @returns {Object[]} An array of employee objects who do not have matching timesheet entries.
 */
const findEmployeesWithoutTimesheets = (employees, editedTimesheetList) => {
  const timesheetsSet = new Set(editedTimesheetList?.map((t) => t.userRef));
  return employees?.filter(
    (employee) => !timesheetsSet.has(employee.reference)
  );
};

export default findEmployeesWithoutTimesheets;

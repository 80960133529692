import React from "react";
import Attachments from "../../../../../Pages/ServiceRequest/Attachments";
import useAttachmentSectionData from "./useAttachmentSectionData";

const AttachmentsSection = ({
  editingMode,
  taskForm,
  currentTask,
  filesState,
  handleFilesToAdd,
  handleFilesToRemove,
  noTitle,
  singleTask,
}) => {
  const { hasAttachmentsToShow, hasNoAttachmentsToShow } =
    useAttachmentSectionData({
      editingMode,
      currentTask,
    });
  // if not in editing mode and there are files saved to the task, show the attachments component
  if (hasAttachmentsToShow) {
    return (
      <Attachments
        singleTask={singleTask}
        noTitle={noTitle}
        requestForm={editingMode ? taskForm : currentTask}
        isEditing={editingMode}
        filesState={filesState}
        handleFilesToAdd={handleFilesToAdd}
        handleFilesToRemove={handleFilesToRemove}
      />
    );
  }
  // if not in editing mode and there are no files saved to the task, don't show the attachments component
  if (hasNoAttachmentsToShow) {
    return (
      <div className="flex">
        {!noTitle && (
          <p style={{ width: "200px" }} className="text-lg font-semibold">
            {" "}
            Attachments
          </p>
        )}
        <p className="text-sm text-gray-450">
          There are no attachments saved to this task
        </p>
      </div>
    );
  }
  // if in editing mode the attachments component should always be shown
  return (
    <Attachments
      singleTask={singleTask}
      noTitle={noTitle}
      requestForm={editingMode ? taskForm : currentTask}
      isEditing={editingMode}
      filesState={filesState}
      handleFilesToAdd={handleFilesToAdd}
      handleFilesToRemove={handleFilesToRemove}
    />
  );
};

export default AttachmentsSection;

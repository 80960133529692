import { useCallback, useMemo } from "react";
import _ from "lodash";
import { useUsers } from "../../../../hooks/useUsers.new";
import { getUserFirstAndInitial } from "../../../../helpers/Utilities";

const keys = ["name.firstName", "name.lastName", "label", "company.value"];

const useOwnedBySearchData = ({
  selectedOptions,
  onChange,
  options,
  setShowPopup,
  showPopup,
}) => {
  const { data: users } = useUsers();
  const userDict = users?.userDict;

  const displayNames = useMemo(() => {
    return (
      selectedOptions?.reduce((acc, { reference, name }) => {
        const user = userDict?.[reference];
        if (user) {
          const fullName = getUserFirstAndInitial(user);
          acc.push({ label: fullName, value: user.reference });
        } else if (name) {
          acc.push({ label: name, value: reference });
        }
        return acc;
      }, []) || []
    );
  }, [selectedOptions, userDict]);

  const handleFilter = useCallback(
    (val) => {
      const filteredList = [];
      const exists = [];

      keys.forEach((key) => {
        options.forEach((item) => {
          if (
            _.get(item, key)?.toLowerCase()?.includes(val?.toLowerCase()) &&
            !exists.includes(item.id)
          ) {
            filteredList.push(item);
            exists.push(item.id);
          }
        });
      });

      onChange(filteredList, val);
    },
    [onChange, options]
  );

  const handleInput = useCallback(
    (e) => {
      if (!showPopup) {
        setShowPopup(true);
      }

      handleFilter(e.target.value);
    },
    [handleFilter, showPopup, setShowPopup]
  );

  return { displayNames, handleInput };
};

export default useOwnedBySearchData;

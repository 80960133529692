import { useReducer } from "react";

const defaultState = {
  currentTags: [],
  originalResource: {},
  name: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setCurrentTags": {
      return { ...state, currentTags: action.currentTags };
    }
    case "setOriginalResource": {
      return {
        ...state,
        originalResource: action.resource,
        name: action.resource?.name,
      };
    }
    case "reset": {
      return {
        ...state,
        originalResource: action.resource,
        name: action.resource?.name,
        currentTags: action.currentTags,
      };
    }
    case "setName": {
      return { ...state, name: action.name };
    }
    default:
      return defaultState;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};

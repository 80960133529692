import React from "react";
import Checkbox from "../../Checkbox/Checkbox";
import linkIcon from "../../../assets/images/shareIcon.svg";
import useUserAgreement from "./useUserAgreement";

const titleCN =
  "flex flex-row items-center gap-2 text-es-lg text-es-black tracking-es-wide font-es-semibold";
const container = "flex flex-col gap-12 pb-4";
const sectionCN = "flex flex-col gap-4";
const textRowCN = "flex flex-row gap-4";
const boldTextCN = "text-es-green font-es-bold text-es-normal w-full h-full";
const textCN = "text-es-normal font-es-normal text-es-dark-grey";

const UserAgreement = ({ setIsTNCCheck }) => {
  const { isChecked, onChange } = useUserAgreement({ setIsTNCCheck });

  return (
    <div style={{ minWidth: "1268px" }} className={container}>
      <div className={sectionCN}>
        <p className={titleCN}>
          SaaS Terms & Conditions
          <a
            href="https://estatespace.com/saas/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkIcon} alt="link icon" />
          </a>
        </p>
        <div className={textRowCN}>
          <Checkbox
            size={6}
            onChange={(val) => onChange("tnc", val)}
            checked={isChecked.tnc}
          />
          <p className={textCN}>
            I agree to EstateSpace’s{" "}
            <a
              href="https://estatespace.com/saas/"
              target="_blank"
              rel="noreferrer"
              className={boldTextCN}
            >
              SaaS Terms and Conditions
            </a>
            .
          </p>
        </div>
      </div>

      <div className={sectionCN}>
        <p className={titleCN}>
          EULA
          <a
            href="https://estatespace.com/eula/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkIcon} alt="link icon" />
          </a>
        </p>
        <div className={textRowCN}>
          <Checkbox
            size={6}
            onChange={(val) => onChange("eula", val)}
            checked={isChecked.eula}
          />
          <p className={textCN}>
            I agree to EstateSpace’s{" "}
            <a
              href="https://estatespace.com/eula/"
              target="_blank"
              rel="noreferrer"
              className={boldTextCN}
            >
              EULA
            </a>
            .
          </p>
        </div>
      </div>

      <div className={sectionCN}>
        <p className={titleCN}>
          Privacy Policy
          <a
            href="https://estatespace.com/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkIcon} alt="link icon" />
          </a>
        </p>
        <div className={textRowCN}>
          <Checkbox
            size={6}
            onChange={(val) => onChange("privacy", val)}
            checked={isChecked.privacy}
          />

          <p className={textCN}>
            I agree to EstateSpace’s{" "}
            <a
              href="https://estatespace.com/privacy/"
              target="_blank"
              rel="noreferrer"
              className={boldTextCN}
            >
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserAgreement;

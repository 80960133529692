const checkIfUTC = (dateString) => {
  if (typeof dateString !== "string") return false;
  // Regular expression to match UTC format in ISO 8601
  const utcPattern =
    /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?)([Zz])$/; //
  // returns true if string matches the UTC pattern and false otherwise
  return utcPattern.test(dateString);
};

export default checkIfUTC;

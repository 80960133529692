import React from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

/**
 * Allow to select single or both dates
 */
const DateRangeSelector = ({ startDate, endDate, value, onChange }) => {
  return (
    <ReactDatePicker
      wrapperClassName="w-full"
      selected={startDate}
      startDate={startDate}
      endDate={endDate}
      minDate={new Date(moment().subtract(5, "years"))}
      maxDate={new Date(moment().add(5, "years"))}
      value={value}
      selectsRange
      onChange={onChange}
      shouldCloseOnSelect={!(!startDate || Boolean(startDate && endDate))}
      customInput={
        <input
          type="text"
          style={{ height: "40px" }}
          className="w-full rounded border border-gray-150 text-sm text-gray-300 py-4 px-2"
        />
      }
    />
  );
};

DateRangeSelector.defaultProps = {
  startDate: new Date(),
  endDate: undefined,
  value: undefined,
  onChange: undefined,
};

DateRangeSelector.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default DateRangeSelector;

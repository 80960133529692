import { Link, Redirect, useHistory } from "react-router-dom";
import React from "react";
import useIsSignedIn from "../hooks/useIsSignedIn";
import { LOGIN_PATH, SIGNUP_PATH } from "../constants";
import estateSpaceLogo from "../stories/assets/images/estatespace-logo-new.png";
import PrimaryButton from "../stories/Components/Buttons/PrimaryButton";

/**
 * Landing Page for the Signup/Auth process.
 * @see{https://estatespace.invisionapp.com/console/Landing-ckwmrs82z053j013m8uaw25ls/ckwmrsd0y04nj01250tcj7kg9/comment}
 */
const Landing = () => {
  const [isSignedIn] = useIsSignedIn();
  const history = useHistory();

  const onSignupClicked = async () => {
    history.push(SIGNUP_PATH);
  };

  if (isSignedIn) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <div className="flex items-center justify-center mt-72">
      <div className="flex flex-col max-w-lg">
        <img alt="EstateSpace Logo" src={estateSpaceLogo} className="w-full" />

        <div className="ml-auto mr-auto max-w-md">
          <div className="w-full px-6">
            <PrimaryButton
              className="w-full mt-36 tracking-widest text-base"
              title="SIGNUP"
              onClick={onSignupClicked}
              noMaxWidth
            />
          </div>

          <div className="flex flex-row mt-10 mx-8">
            <hr className="w-full px-4 mt-2.5 border-t-gray-100" />
            <p className="px-4 text-sm font-normal">Or</p>
            <hr className="w-full px-4 mt-2.5 border-t-gray-100" />
          </div>

          <p className="mt-8 text-base text-center tracking-widest font-light mx-8">
            Already have an account?
          </p>
          <div className="text-center">
            <Link
              to={LOGIN_PATH}
              className="mt-1.5 text-base text-center font-semibold text-brandGreen hover:text-black"
            >
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

Landing.propTypes = {};
Landing.defaultProps = {};

export default Landing;

import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";

import cntl from "cntl";
import Dropdown from "../Dropdown/Dropdown";
import Input from "../Input/Input";
import {
  PROJECT_NAME,
  PROJECT_TYPE,
  PROJECT_DESCRIPTION,
  PROJECT_ADDRESS,
  PROJECT_MAILING_SAME_AS_PHYSICAL,
  PROJECT_TIMEZONE,
  PROJECT_PROPERTY,
} from "../../../constants";
import BillingAddress from "../AddressInput/BillingAddress";
import Widget from "../Widget/Widget";
import { SpinnerInline } from "../Spinner/Spinner";
import FormAvatar from "../Avatar/FormAvatar";
import { getTimeZoneOptions } from "../../../helpers/Time";
import { usePropertiesOverview } from "../../../hooks/properties";

const boldCN = (className) => cntl`
  font-bold 
  text-gray-450
  text-md
  self-center
  flex
  flex-row
  ${className}
`;

const ProjectDetails = ({
  project,
  currentUser,
  dispatch,
  configs,
  handleEnter,
  handleForwardRef,
  inputRefs,
  setSameAddress,
  property,
  isExistingProjectName,
}) => {
  const { propertiesDict, propertiesDD } = usePropertiesOverview();

  const [address, setAddress] = useState([]);
  const [mailingSameAsPhysical, setMailingSameAsPhysical] = useState(
    project.mailingSameAsPhysical
  );

  useEffect(() => {
    setSameAddress(project?.mailingSameAsPhysical);
  }, [project?.mailingSameAsPhysical, setSameAddress]);

  const projectTypeOptions = React.useMemo(() => {
    const optionList = [];
    configs?.management?.project?.types?.forEach((type) => {
      if (type.selected) {
        optionList.push({ label: type.display, value: type.id });
      }
    });
    return optionList;
  }, [configs]);

  const nameChangeHandler = (val) => {
    dispatch({
      type: PROJECT_NAME,
      value: val,
    });
  };
  const typeChangeHandler = (option) => {
    dispatch({
      type: PROJECT_TYPE,
      value: option.value,
    });
  };

  const timezoneChangeHandler = useCallback(
    (option) => {
      dispatch({
        type: PROJECT_TIMEZONE,
        value: option.label,
      });
    },
    [dispatch]
  );

  const handleAddressInput = useCallback(
    (val) => {
      setAddress(val);

      dispatch({
        type: PROJECT_ADDRESS,
        value: val,
      });
    },
    [setAddress, dispatch]
  );

  const propertyChangeHandler = useCallback(
    (option) => {
      const propert = propertiesDict?.[option.value];
      timezoneChangeHandler({ label: propert?.timezone });
      dispatch({
        type: PROJECT_PROPERTY,
        value: option.value,
      });
    },
    [dispatch, propertiesDict, timezoneChangeHandler]
  );

  const descriptionChangeHandler = (val) => {
    dispatch({
      type: PROJECT_DESCRIPTION,
      value: val,
    });
  };

  const handleMailingSameAsPhysicalInput = useCallback(
    (val) => {
      setMailingSameAsPhysical(val);

      dispatch({
        type: PROJECT_MAILING_SAME_AS_PHYSICAL,
        value: val,
      });

      if (val && !property) {
        dispatch({
          type: PROJECT_ADDRESS,
          value: [
            project?.address?.[0],
            {
              use: "Mailing",
              address1: "",
              street2: "",
              city: "",
              state: "",
              country: "",
              zip: "",
            },
          ],
        });
      }
    },
    [dispatch, property, project]
  );

  /**
   * Dispatch to state the newly avatar file uploaded
   */
  const dispatchAvatar = ({ reference, category }) => {
    dispatch({
      type: "addAvatar",
      primaryImage: reference,
      file: {
        ref: reference,
        category,
      },
    });
  };

  useEffect(() => {
    // set property DD value if already on the Projects Tab for Create Project modal
    if (property) {
      dispatch({
        type: PROJECT_PROPERTY,
        value: property?.reference,
      });
    }
  }, [dispatch, property, property?.reference]);

  useEffect(() => {
    // set property DD value if already on the Projects Tab for Create Project modal
    if (property) {
      dispatch({
        type: PROJECT_TIMEZONE,
        value: property?.timezone,
      });
    }
  }, [dispatch, property, property?.reference]);

  // set the Project address to the Property address
  useEffect(() => {
    if (property) handleAddressInput(property?.address);
  }, [property, handleAddressInput]);

  return (
    <div className="w-full">
      {!currentUser && (
        <div className="absolute w-full z-10 mr-auto ml-auto h-full mt-auto mb-auto">
          <SpinnerInline />
        </div>
      )}

      <div className="pb-6 flex flex-row align-center">
        <div className="pr-4">
          <FormAvatar
            isEditing
            editing
            resourceName="Project"
            image={project?.primaryImage}
            onChange={dispatchAvatar}
          />
        </div>
        <div className={boldCN()}>Add Image</div>
      </div>

      <Widget draggable={false} title="Project Information" overflow>
        <div className="grid grid-cols-2 gap-8">
          <div className="flex flex-row">
            <div className="w-2/5 self-center">
              <div className={boldCN()}>
                Name
                {project.name ? (
                  ""
                ) : (
                  <div className="text-brandDarkGreen">*</div>
                )}
              </div>
            </div>
            <div className="w-3/5 self-center">
              <Input
                name="projectName"
                value={project.name}
                onChange={nameChangeHandler}
                placeholder="Name"
                validation={yup.string().trim().required()}
                inputClassName="bg-green-50 pl-4 pr-8 overflow-hidden"
                inputContainerClassName="bg-green-50"
                forwardedRef={(el) => handleForwardRef("projectName", el)}
                handleEnter={handleEnter}
                autoFocus
                customErrorMessage={
                  isExistingProjectName && "Project name must be unique"
                }
              />
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-2/5 self-center">
              <div className={boldCN()}>
                Type
                {project.projectType ? (
                  ""
                ) : (
                  <div className="text-brandDarkGreen">*</div>
                )}
              </div>
            </div>
            <div className="w-3/5 self-center">
              <Dropdown
                name="projectType"
                options={projectTypeOptions}
                value={projectTypeOptions?.find(
                  (item) => item.value === project.projectType
                )}
                onChange={typeChangeHandler}
                validation={yup.object().required()}
                forwardedRef={(el) => handleForwardRef("projectType", el)}
                placeholder="Select"
                onKeyUp={handleEnter}
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-8 border-t border-gray-450 border-opacity-50 pt-4 mt-8">
          <div className="flex flex-row">
            <div className="w-2/5 self-center">
              <div className={boldCN()}>Property</div>
            </div>
            <div className="w-3/5 self-center">
              <Dropdown
                name="property"
                options={propertiesDD}
                value={propertiesDD?.find(
                  (item) => item.value === project.property
                )}
                onChange={propertyChangeHandler}
                forwardedRef={(el) => handleForwardRef("property", el)}
                placeholder="Select"
                onKeyUp={handleEnter}
                isDisabled={Boolean(property?.reference)}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-8 border-t border-gray-450 border-opacity-50 pt-4 mt-8">
          <div className="flex flex-row">
            <div className="w-2/5 self-center">
              <div className={boldCN()}>
                Timezone
                {!project.timezone && (
                  <div className="text-brandDarkGreen">*</div>
                )}
              </div>
            </div>
            <div className="w-3/5 self-center">
              <Dropdown
                options={[...(getTimeZoneOptions() ?? [])]}
                value={project?.timezone}
                placeholder={project?.timezone ?? "Select"}
                forwardedRef={(el) => handleForwardRef("timezone", el)}
                onChange={timezoneChangeHandler}
                onKeyUp={handleEnter}
                isDisabled={Boolean(property?.reference)}
              />
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-2/5 self-center">
              <div className={boldCN()}>Description</div>
            </div>
            <div className="w-3/5 self-center">
              <Input
                inputClassName="ESInput"
                inputContainerClassName="bg-green-50"
                onChange={descriptionChangeHandler}
                value={project.description}
                placeholder="Description"
              />
            </div>
          </div>
        </div>
      </Widget>
      <Widget draggable={false} title={null} className="mt-4" overflow>
        <BillingAddress
          // set Project address to Property address if Project is associated to a Property
          address={property ? property?.address : address}
          mailingSameAsPhysical={mailingSameAsPhysical}
          onChange={handleAddressInput}
          handleMailingSameAsPhysicalInput={handleMailingSameAsPhysicalInput}
          onKeyPress={handleEnter}
          onForwardRef={handleForwardRef}
          inputRefs={inputRefs}
          isDisabled={Boolean(property?.reference)}
        />
      </Widget>
    </div>
  );
};

ProjectDetails.propTypes = {
  currentUser: PropTypes.shape({}),
  /**
   * project form state
   */
  // eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.object,
  /**
   * project form dispatcher
   */
  dispatch: PropTypes.func,
  /**
   * project configs
   */
  // eslint-disable-next-line react/forbid-prop-types
  configs: PropTypes.object,
  handleEnter: PropTypes.func,
  handleForwardRef: PropTypes.func,
  inputRefs: PropTypes.shape({
    current: PropTypes.shape({
      description: PropTypes.string,
      projectName: PropTypes.arrayOf(PropTypes.string),
      projectType: PropTypes.string,
    }),
  }),
  setSameAddress: PropTypes.func,
  filesToUpload: PropTypes.shape({ length: PropTypes.number }),
  property: PropTypes.shape({
    address: PropTypes.string,
    reference: PropTypes.string,
    timezone: PropTypes.string,
  }),
  isExistingProjectName: PropTypes.bool,
};

ProjectDetails.defaultProps = {
  currentUser: undefined,
  project: {},
  dispatch: undefined,
  configs: {},
  handleEnter: undefined,
  handleForwardRef: () => {},
  inputRefs: { current: {} },
  setSameAddress: () => {},
  filesToUpload: {},
  property: undefined,
  isExistingProjectName: false,
};

export default ProjectDetails;

/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import {
  ADD_OPEN_MODAL,
  ASSET,
  CREATE_ASSET,
  GET_ASSET_VIEW_PATH,
  GET_PROPERTY_ASSET_PATH,
} from "../../../constants";
import AssetTable from "../../../stories/Components/AssetTable/AssetTable";
import { useAssets } from "../../../hooks/assets";
import { useAppState } from "../../../state/appState";
import PrimaryHeaderItems from "../../../stories/Components/Primary/PrimaryHeaderItems";
import { useProjectById } from "../../../hooks/projects";
import { useModalState } from "../../../state/modalState";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { hasWritePermission } from "../../../helpers/Permissions";

const AssetList = ({
  associatedResource,
  siteHeaderProps,
  bannerComponent,
}) => {
  const history = useHistory();
  const [{ managementConfiguration }] = useAppState();
  const [, modalDispatch] = useModalState();
  const { data: currentUser } = useCurrentUser();
  const { data: assets, isLoading } = useAssets();
  const { projectId, propertyId, assetId, locationId, spaceId } = useParams();
  const { data: currentProject } = useProjectById(projectId);
  const [catOptions, setCatOptions] = useState([]);
  const [subCatOptionsMap, setSubCatOptionsMap] = useState({});

  useEffect(() => {
    if (managementConfiguration) {
      const categories = managementConfiguration.management?.asset?.category;
      const subcatMap = {};
      setCatOptions(() => {
        const catList = categories
          ?.filter((cat) => cat.selected)
          .map((cat) => {
            subcatMap[cat.display] = cat.subcategories
              .filter((subcat) => subcat.selected)
              .map((subcat) => {
                return { label: subcat.display, value: subcat.id };
              });
            return { label: cat.display, value: cat.id };
          });
        return catList;
      });
      setSubCatOptionsMap(subcatMap);
    }
  }, [managementConfiguration]);

  const onAssetRowClick = (id) => {
    const view = propertyId && propertyId[0];
    const ref = propertyId && propertyId[1];
    if (view && view?.toLowerCase() === "property") {
      history.replace(GET_PROPERTY_ASSET_PATH(ref, id));
    } else {
      // TODO: Fix for projectAsset use case
      history.push(GET_ASSET_VIEW_PATH(id));
    }
  };

  const headerTitle =
    siteHeaderProps?.title || currentProject?.name || "Assets";

  const headerOnClick = () =>
    modalDispatch({
      type: ADD_OPEN_MODAL,
      modalData: {
        item: {
          disableAssociation: projectId || propertyId,
          propertyId,
          projectId,
          assetId,
          locationId,
          spaceId,
        },
      },
      modalType: CREATE_ASSET,
      ref: { id: uuidv4() },
    });

  return (
    <>
      <PrimaryHeaderItems
        headerTitle={headerTitle}
        buttonTitle="Asset"
        onClick={headerOnClick}
        buttonClassName={!hasWritePermission(ASSET, currentUser) && "hidden"}
      />
      <AssetTable
        originalAssets={assets}
        isLoading={isLoading}
        categoryOptionsList={catOptions}
        subCatOptionsMap={subCatOptionsMap}
        onRowClick={onAssetRowClick}
        propertyRef={associatedResource}
        bannerComponent={bannerComponent}
        hideSiteHeaderTitle
      />
    </>
  );
};

AssetList.propTypes = {
  /**
   * only shows WF with this associated Resource
   */
  associatedResource: PropTypes.string,
  /**
   * alter siteHeader props
   */
  // eslint-disable-next-line react/forbid-prop-types
  siteHeaderProps: PropTypes.object,

  bannerComponent: PropTypes.node,
};

AssetList.defaultProps = {
  associatedResource: undefined,
  siteHeaderProps: {
    title: "Assets",
    dropdownRoutes: undefined,
  },
  bannerComponent: undefined,
};

export default AssetList;

import React from "react";
import PropTypes from "prop-types";
import ActualFinancialsTable from "../../../stories/Components/ActualFinancialsTable/ActualFinancialsTable";
import useFinancialsActual from "../../../hooks/useFinancialsActual";

const ActualFinancials = ({ budgetId }) => {
  const [originalData] = useFinancialsActual(budgetId);

  return <ActualFinancialsTable originalData={originalData} />;
};

ActualFinancials.propTypes = {
  /**
   * Budget Id used to pull financial data
   */
  budgetId: PropTypes.string,
};

ActualFinancials.defaultProps = {
  budgetId: undefined,
};

export default ActualFinancials;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Summary from "../Summary/Summary";
import Dropdown from "../Dropdown/Dropdown";
import Table from "../Table/Table";

import { detailedSummaryColumnsCP } from "./InvoiceTableColumns";
import {
  BUDGET_COST_PLUS,
  BUDGET_FIXED_FIRM,
  BUDGET_TIME_AND_MATERIALS,
} from "../../../constants";

const viewOptions = [
  { label: "Executive Summary", value: "detailedSummaryExecutive" },
  { label: "Detailed Breakdown", value: "detailedSummaryBreakdown" },
];

const DetailedSummary = ({ lineItems, budgetType }) => {
  const [currentView, setCurrentView] = useState(viewOptions[0]);
  const [summaryData, setSummaryData] = useState({});

  useEffect(() => {
    const summary = lineItems.reduce((obj, item) => {
      const tmpObj = obj;
      // Control Estimate
      tmpObj.controlEstimate =
        (tmpObj.controlEstimate || 0) + (item.publishedBudget || 0);
      // Work Completed to Date
      tmpObj.workCompletedToDate =
        (tmpObj.workCompletedToDate || 0) + (item.workCompletedToDate || 0);
      // Retainage and Holdback
      tmpObj.retainageAndHoldback =
        (tmpObj.retainageAndHoldback || 0) +
        (item.retainage || 0) +
        (item.holdback || 0);
      // Work Completed To Date Less Retainage and Holdback
      tmpObj.workCompletedToDateLess =
        (tmpObj.workCompletedToDateLess || 0) +
        (item.workCompletedToDate || 0) -
        (item.retainage || 0) -
        (item.holdback || 0);
      // Payment Requested Previous Period -- missing
      tmpObj.paymentRequestedPreviousPeriod =
        (tmpObj.paymentRequestedPreviousPeriod || 0) +
        (item.paymentRequestedPreviousPeriod || 0);
      // Payment Requested This Period -- missing
      tmpObj.paymentRequestedThisPeriod =
        (tmpObj.paymentRequestedThisPeriod || 0) +
        (item.paymentRequestedThisPeriod || 0);
      // Deposit Applied This Period -- missing
      tmpObj.depositAppliedThisPeriod =
        (tmpObj.depositAppliedThisPeriod || 0) +
        (item.depositAppliedThisPeriod || 0);
      // Current Payment Due -- missing
      tmpObj.currentPaymentDue =
        (tmpObj.workCompletedThisPeriod || 0) +
        (item.depositAppliedThisPeriod || 0);
      // Balance to Finish Including Retainage and Holdback -- missing
      tmpObj.balanceToFinishRetainageHoldback =
        (tmpObj.balanceToFinishRetainageHoldback || 0) +
        (item.balanceToFinish || 0) +
        (item.retainage || 0) +
        (item.holdback || 0);
      // Balance to Finish Including Retainage and Holdback Less Contingency -- missing
      tmpObj.balanceToFinishRetainageHoldbackLessContingency =
        (tmpObj.balanceToFinishRetainageHoldbackLessContingency || 0) +
        (item.balanceToFinish || 0) +
        (item.retainage || 0) +
        (item.holdback || 0) -
        (item.caTotal || 0);
      // Remaining Deposit on Hand -- missing
      tmpObj.remainingDepositOnHand =
        (tmpObj.remainingDepositOnHand || 0) + (item.depositRemaining || 0);

      return tmpObj;
    }, {});
    setSummaryData(summary);
  }, [lineItems]);

  const budgetFieldsExecutive = {
    [BUDGET_COST_PLUS]: [
      "controlEstimate",
      "workCompletedToDate",
      "retainageAndHoldback",
      "workCompletedToDateLess",
      "paymentRequestedPreviousPeriod",
      "paymentRequestedThisPeriod",
      "depositAppliedThisPeriod",
      "currentPaymentDue",
      "balanceToFinishRetainageHoldback",
      "balanceToFinishRetainageHoldbackLessContingency",
      "remainingDepositOnHand",
    ],
    [BUDGET_FIXED_FIRM]: [
      "controlEstimate",
      "workCompletedToDate",
      "retainageAndHoldback",
      "workCompletedToDateLess",
      "paymentRequestedPreviousPeriod",
      "paymentRequestedThisPeriod",
      "depositAppliedThisPeriod",
      "currentPaymentDue",
      "balanceToFinishRetainageHoldback",
      "balanceToFinishRetainageHoldbackLessContingency",
      "remainingDepositOnHand",
    ],
    [BUDGET_TIME_AND_MATERIALS]: [
      "controlEstimate",
      "workCompletedToDate",
      "retainageAndHoldback",
      "workCompletedToDateLess",
      "paymentRequestedPreviousPeriod",
      "paymentRequestedThisPeriod",
      "depositAppliedThisPeriod",
      "currentPaymentDue",
      "balanceToFinishRetainageHoldback",
      "balanceToFinishRetainageHoldbackLessContingency",
      "remainingDepositOnHand",
    ],
    timeAndMaterial: [
      "controlEstimate",
      "workCompletedToDate",
      "retainageAndHoldback",
      "workCompletedToDateLess",
      "paymentRequestedPreviousPeriod",
      "paymentRequestedThisPeriod",
      "depositAppliedThisPeriod",
      "currentPaymentDue",
      "balanceToFinishRetainageHoldback",
      "balanceToFinishRetainageHoldbackLessContingency",
      "remainingDepositOnHand",
    ],
  };

  const budgetFieldsBreakdown = {
    [BUDGET_COST_PLUS]: [
      "controlEstimate",
      "workCompletedToDate",
      "retainageAndHoldback",
      "workCompletedToDateLess",
      "paymentRequestedPreviousPeriod",
      "paymentRequestedThisPeriod",
      "depositAppliedThisPeriod",
      "currentPaymentDue",
      "balanceToFinishRetainageHoldback",
      "balanceToFinishRetainageHoldbackLessContingency",
      "remainingDepositOnHand",
    ],
    [BUDGET_FIXED_FIRM]: [
      "controlEstimate",
      "workCompletedToDate",
      "retainageAndHoldback",
      "workCompletedToDateLess",
      "paymentRequestedPreviousPeriod",
      "paymentRequestedThisPeriod",
      "depositAppliedThisPeriod",
      "currentPaymentDue",
      "balanceToFinishRetainageHoldback",
      "balanceToFinishRetainageHoldbackLessContingency",
      "remainingDepositOnHand",
    ],
    [BUDGET_TIME_AND_MATERIALS]: [
      "controlEstimate",
      "workCompletedToDate",
      "retainageAndHoldback",
      "workCompletedToDateLess",
      "paymentRequestedPreviousPeriod",
      "paymentRequestedThisPeriod",
      "depositAppliedThisPeriod",
      "currentPaymentDue",
      "balanceToFinishRetainageHoldback",
      "balanceToFinishRetainageHoldbackLessContingency",
      "remainingDepositOnHand",
    ],
    timeAndMaterial: [
      "controlEstimate",
      "workCompletedToDate",
      "retainageAndHoldback",
      "workCompletedToDateLess",
      "paymentRequestedPreviousPeriod",
      "paymentRequestedThisPeriod",
      "depositAppliedThisPeriod",
      "currentPaymentDue",
      "balanceToFinishRetainageHoldback",
      "balanceToFinishRetainageHoldbackLessContingency",
      "remainingDepositOnHand",
    ],
  };

  const summaryValues = [
    {
      key: "Control Estimate",
      id: "controlEstimate",
      value: summaryData.controlEstimate,
      isFooter: false,
      unit: "$",
    },
    {
      key: "Work Completed to Date",
      id: "workCompletedToDate",
      value: summaryData.workCompletedToDate,
      isFooter: false,
      unit: "$",
    },
    {
      key: "Retainage and Holdback",
      id: "retainageAndHoldback",
      value: summaryData.retainageAndHoldback,
      isFooter: false,
      unit: "$",
    },
    {
      key: "Work Completed To Date Less Retainage and Holdback",
      id: "workCompletedToDateLess",
      value: summaryData.workCompletedToDateLess,
      isFooter: false,
      unit: "$",
    },
    {
      key: "Payment Requested Previous Period",
      id: "paymentRequestedPreviousPeriod",
      value: summaryData.paymentRequestedPreviousPeriod,
      isFooter: false,
      unit: "$",
    },
    {
      key: "Payment Requested This Period",
      id: "paymentRequestedThisPeriod",
      value: summaryData.paymentRequestedThisPeriod,
      isFooter: false,
      unit: "$",
    },
    {
      key: "Deposit Applied This Period",
      id: "depositAppliedThisPeriod",
      value: summaryData.depositAppliedThisPeriod,
      isFooter: false,
      unit: "$",
    },
    {
      key: "Current Payment Due",
      id: "currentPaymentDue",
      value: summaryData.currentPaymentDue,
      isFooter: false,
      unit: "$",
    },
    {
      key: "Balance to Finish Including Retainage and Holdback",
      id: "balanceToFinishRetainageHoldback",
      value: summaryData.balanceToFinishRetainageHoldback,
      isFooter: false,
      unit: "$",
    },
    {
      key: "Balance to Finish Including Retainage and Holdback Less Contingency",
      id: "balanceToFinishRetainageHoldbackLessContingency",
      value: summaryData.balanceToFinishRetainageHoldbackLessContingency,
      isFooter: false,
      unit: "$",
    },
    {
      key: "Remaining Deposit on Hand",
      id: "remainingDepositOnHand",
      value: summaryData.remainingDepositOnHand,
      isFooter: false,
      unit: "$",
    },
  ];

  const getSummaryValues = () => {
    const fields =
      (currentView.value === "detailedSummaryExecutive"
        ? budgetFieldsExecutive
        : budgetFieldsBreakdown)[budgetType] ?? [];
    return fields.map((item) => summaryValues.find((val) => val.id === item));
  };

  return (
    <div className="flex flex-col">
      <Summary
        className="mt-8 mb-12"
        keyClassName="w-full"
        title={currentView.label.toUpperCase()}
        values={getSummaryValues()}
      />

      <div className="" style={{ width: "300px" }}>
        <Dropdown
          className="mt-6"
          label="View"
          options={viewOptions}
          value={currentView}
          onChange={setCurrentView}
          disableClear
        />
      </div>

      <Table
        className="mb-6"
        data={lineItems}
        columns={detailedSummaryColumnsCP(currentView.value)}
        onSelectedRowChange={() => {}}
        hideSiteHeader
        hideCreateNewButton
        hideChangeView
        hideSaveButton
      />
    </div>
  );
};

DetailedSummary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lineItems: PropTypes.array,
  budgetType: PropTypes.string,
};

DetailedSummary.defaultProps = {
  lineItems: undefined,
  budgetType: undefined,
};

export default DetailedSummary;

import React from "react";
import TaskRow from "./TaskRow";

const TaskList = ({
  tasks = [],
  isEditing,
  handleTasksToDelete,
  handleNavigateToTask,
}) => {
  return (
    <div>
      {tasks.map((task, index) => (
        <TaskRow
          key={task?.id}
          task={task}
          index={index + 1}
          isEditing={isEditing}
          handleNavigateToTask={handleNavigateToTask}
          handleTasksToDelete={handleTasksToDelete}
        />
      ))}
    </div>
  );
};

export default TaskList;

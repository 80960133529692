import React, { useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import CrossButton from "../CrossButton/CrossButton";
import Dropdown from "../Dropdown/Dropdown";
import useCurrentUser from "../../../hooks/useCurrentUser";

const customStyle = {
  menu: {
    borderWidth: "0px",
    borderColor: "#FFFFFF",
    boxShadow: "0px",
  },
};

const formatOptionLabel = (file) => (
  <div>
    <div className="flex flex-row justify-between">
      <div className="flex flex-row">
        <div className="flex justify-center items-center w-10 h-10 mr-4 -ml-1">
          <img src={file?.thumbnailUrl} className="rounded" alt="Avatar Icon" />
        </div>
        <div className="flex flex-col">
          <div className="flex" style={{ "max-width": "270px" }}>
            <p className="font-semibold truncate text-ellipsis text-gray-300">
              {file?.name}
            </p>
          </div>
          <div className="flex flex-row">
            <p
              className="font-normal text-xs text-gray-300 uppercase"
              style={{ "max-width": "50px" }}
            >
              {`${file?.extension}  -`}
            </p>
            <p className="font-normal text-xs text-gray-300 pl-2">
              {moment(file?.metadata?.lastUpdated).format("dddd, MMMM Do YYYY")}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const RecentFiles = ({
  hide,
  handleFileClick,
  onScrollCallback,
  wrappCN,
  documentTypeFilter,
}) => {
  const wrapperRef = useRef(null);
  const { data: currentUser } = useCurrentUser();
  useOutsideAlerter(wrapperRef, () => hide());

  return (
    <div
      ref={wrapperRef}
      className={`flex flex-col absolute top-10 bg-white z-50 border border-gray-200 rounded-md p-4 ${
        wrappCN || "-right-2"
      }`}
      style={{ height: "610px", width: "377px" }}
    >
      <div className="flex flex-row justify-between pb-4">
        <div>
          <p className="font-semibold text-black text-sm pt-2">Recent files</p>
        </div>
        <div className="flex justify-end">
          <CrossButton onClick={hide} />
        </div>
      </div>
      <Dropdown
        className="overflow-hidden"
        hideIndicator
        hideDropdownIndicator
        placeholder="Search"
        defaultMenuIsOpen
        onChange={(e) => {
          if (e?.id) {
            handleFileClick(e.id);
            hide();
          }
        }}
        customStyle={customStyle}
        formatOptionLabel={formatOptionLabel}
        maxMenuHeight={500}
        menuIsAlwaysOpen
        loadOptions={(search, loadedOptions, { page }) =>
          onScrollCallback(
            search,
            loadedOptions,
            { page },
            currentUser?.reference,
            documentTypeFilter
          )
        }
        isAsyncPaginate
      />
    </div>
  );
};

RecentFiles.propTypes = {
  handleFileClick: PropTypes.func,
  hide: PropTypes.func,
  onScrollCallback: PropTypes.func,
  wrappCN: PropTypes.string,
  documentTypeFilter: PropTypes.string,
};

RecentFiles.defaultProps = {
  handleFileClick: [],
  hide: () => {},
  onScrollCallback: () => {},
  wrappCN: "-right-2",
  documentTypeFilter: undefined,
};

export default RecentFiles;

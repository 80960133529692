import addToTaskListStore from "../../../helpers/Task/addToTaskListStore";

const addTaskResponder = (queryClient) => {
  return {
    onSuccess: (returnedData) => {
      return addToTaskListStore({ queryClient, task: returnedData.data });
    },
    onError: (err) => {
      console.error("Failed to create event", err);
    },
  };
};

export default addTaskResponder;

import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";

import UserList from "../CalendarAddEventForm/UserList";
import Dropdown from "../Dropdown/Dropdown";

const SelectOptionsWithUpdates = ({
  dropDownClassName,
  dropDownLabel,
  options,
  setSelectedValues,
  selectedValues,
}) => {
  const [updatedOptions, setUpdatedOptions] = useState(options);

  useEffect(() => {
    let temptOptions = options;
    selectedValues?.forEach((val) => {
      temptOptions = temptOptions.map((item) => ({
        ...item,
        options: item?.options?.filter((it) => it?.value !== val?.value),
      }));
    });
    setUpdatedOptions(temptOptions);
  }, [options, selectedValues]);

  const handleChange = (val) => {
    setSelectedValues((prev) => [...prev, val]);
    setUpdatedOptions((prev) =>
      prev?.map((item) => ({
        ...item,
        options: item?.options?.filter((it) => it?.value !== val?.value),
      }))
    );
  };

  const handleRemoveUser = useCallback(
    (val) => {
      const removedValue = selectedValues?.find((user) => user?.value === val);
      setSelectedValues((prev) => prev?.filter((user) => user?.value !== val));

      const optionType = removedValue?.value?.split("/")[0];

      const updated = updatedOptions?.map((item) => {
        if (item?.label?.toLowerCase() === `${optionType?.toLowerCase()}s`) {
          return {
            ...item,
            options: [...item?.options, removedValue].sort((a, b) =>
              a?.label > b?.label ? 1 : -1
            ),
          };
        }
        return item;
      });

      setUpdatedOptions(updated);
    },
    [updatedOptions, selectedValues, setSelectedValues]
  );

  return (
    <div className="flex flex-col">
      <Dropdown
        className={dropDownClassName}
        label={dropDownLabel}
        onChange={(val) => handleChange(val)}
        options={updatedOptions}
      />
      <UserList
        userList={selectedValues}
        handleRemove={(val) => handleRemoveUser(val)}
      />
    </div>
  );
};

SelectOptionsWithUpdates.propTypes = {
  dropDownClassName: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  setSelectedValues: PropTypes.func,
  dropDownLabel: PropTypes.string,
  selectedValues: PropTypes.arrayOf(PropTypes.shape({})),
};

SelectOptionsWithUpdates.defaultProps = {
  dropDownClassName: undefined,
  options: [],
  setSelectedValues: () => {},
  dropDownLabel: "",
  selectedValues: [],
};

export default SelectOptionsWithUpdates;

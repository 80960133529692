import moment from "moment";

const formatTaskHistoryComment = (comment) => {
  return {
    ...comment,
    content: comment.content,
    by: comment.author,
    date: moment(comment.metadata.createdAt).format("MMMM DD, YYYY"),
    time: moment(comment.metadata.createdAt).format("hh:mm A"),
  };
};

export default formatTaskHistoryComment;

import { ConfigurationAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import useManagementConfiguration from "./useManagementConfiguration";
import { configurationKeys } from "../config/reactQuery/queryKeyFactory";

const patchManagement = async ({
  managementConfiguration,
  updatedManagement,
}) => {
  const patchBody = {
    ...managementConfiguration,
    management: {
      ...managementConfiguration.management,
      ...updatedManagement,
    },
  };

  const { data } = await ConfigurationAPI.patch(
    "management",
    patchBody,
    managementConfiguration
  );

  return data;
};

/**
 * Mutation hook to patch templates for given resource
 */
const useManagementConfigurationPatch = (mutationKey) => {
  const { data: managementConfiguration } = useManagementConfiguration();

  const queryClient = useQueryClient();
  return useMutation({
    mutationKey,
    mutationFn: ({ updatedManagement }) =>
      patchManagement({
        managementConfiguration,
        updatedManagement,
      }),
    onSuccess: (data) => {
      queryClient.setQueryData(configurationKeys("management"), data);
    },
    onError: (error) => {
      console.error("useManagementConfigurationPatch", error);
    },
    onSettled: () => {
      // TODO: (Josymar) Workaround while backend is fixed
      // Remove this code after backend fix
      setTimeout(() => queryClient.invalidateQueries("configuration"), 1000);
    },
  });
};

export default useManagementConfigurationPatch;

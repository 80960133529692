import fetchPropertiesOverview from "../../helpers/Properties/fetchPropertiesOverview";
import useAuthenticatedQuery from "../useAuthenticatedQuery";
import propertyKeys from "./propertiesKeys";

/**
 * Query hook to fetch properties overview
 */
const usePropertiesOverview = ({ enabled = true, select } = {}) => {
  const queryInfo = useAuthenticatedQuery({
    queryKey: propertyKeys.overview(),
    enabled,
    select,
    queryFn: fetchPropertiesOverview,
    onError: (error) => {
      console.error(
        "usePropertiesOverview: Error fetching properties overview",
        error
      );
    },
  });

  return {
    queryInfo,
    propertiesDict: queryInfo?.data?.propertiesDict,
    propertiesDD: queryInfo?.data?.propertiesDD,
  };
};

export default usePropertiesOverview;

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Table from "../../../Table/Table";
import useTaskActivity from "./useTaskActivity";
import SimpleSearch from "../../../SearchBar/StatelessSearch";

const TaskActivity = ({ currentTask }) => {
  const { tableData, columns, ref, searchState, handleSearch } =
    useTaskActivity({
      currentTask,
    });

  return (
    <div className="felx flex-col">
      <div className="flex justify-between items-center pb-4">
        <p className="text-gray-500 font-semibold text-xl">Activity Log</p>
      </div>
      <div className="w-1/3 py-2">
        <SimpleSearch
          options={tableData}
          keys={["activity", "fullName"]}
          onChange={handleSearch}
          value={searchState.value}
          placeholder="Search"
          className="bg-backgroundGreen"
          disabled={false}
          disableClear
        />
      </div>

      <Table
        noHeader
        resourceName="activity"
        data={useMemo(() => {
          return searchState?.value ? searchState?.filteredData : tableData;
        }, [searchState, tableData])}
        columns={columns}
        showConstantRowIndex
        hideSiteHeaderTitle
        hideSiteHeaderActionButtons
        hideSwitchView
        hideSiteHeaderDropdownContainer
        hideSiteHeader
        lastRowReferenceForInfiniteScroll={ref}
        hideLineNumber
      />
    </div>
  );
};

TaskActivity.propTypes = {
  currentTask: PropTypes.shape({
    reference: PropTypes.string,
  }),
};

TaskActivity.defaultProps = {
  currentTask: undefined,
};

export default TaskActivity;

import React from "react";
import PropTypes from "prop-types";
import urlIcon from "../../assets/images/urlIcon.svg";

const ExternalLink = ({ onClick, children }) => {
  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      onClick();
    }
  };

  return (
    <div
      className="flex items-center"
      onClick={onClick}
      onKeyDown={onKeyDown}
      role="button"
      tabIndex={0}
    >
      <p className="ESLink mr-2">{children}</p>
      <img src={urlIcon} alt="link" />
    </div>
  );
};

ExternalLink.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
};

ExternalLink.defaultProps = {
  onClick: undefined,
  children: undefined,
};

export default ExternalLink;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { EventAPI } from "@griffingroupglobal/eslib-api";
import moment from "moment";
import Modal from "../Modal/Modal";
import {
  ALL_EVENT_INSTANCES,
  customModalStyles,
  FUTURE_EVENT_INSTANCES,
  GET_ASSET_VIEW_PATH,
  GET_PROPERTY_VIEW_PATH,
  GET_SOP_DETAILS_PATH,
  SINGLE_EVENT_INSTANCE,
} from "../../../constants";
import AssetMaintenanceScheduledTaskView from "../AssetForms/AssetMaintenanceScheduledTaskView";
import { useAppState } from "../../../state/appState";
import TaskRecurrenceInstanceModal from "../AssetForms/TaskRecurrenceInstanceModal";
import { toastError, toastMessage } from "../Toast/Toast";
import { getEventQueryLeftRight } from "../../../helpers/getEventQueryLeftRight";
import { keepRecurrence } from "../../../helpers/Calendar";
import useCurrentUser from "../../../hooks/useCurrentUser";

const { overlayStyle, contentStyle, titleStyle, headerStyle } =
  customModalStyles;

/**
 * @deprecated
 */
const TaskView = ({
  data,
  reload,
  setViewTaskModal,
  viewTaskModal,
  title,
  hideSteps,
}) => {
  const history = useHistory();
  const { data: currentUser } = useCurrentUser();
  const [{ userDict }] = useAppState();
  const [updatedData, setUpdatedData] = useState(data);
  const [allStepsCompleted, setAllStepsCompleted] = useState(false);
  const [assignedTo, setAssignedTo] = useState("");
  const [associationRef, setAssociationRef] = useState();
  const [associationRoute, setAssociationRoute] = useState("");
  const [associationName, setAssociationName] = useState();
  const [invitees, setInvitees] = useState();
  const [deleteEventInstance, setDeleteEventInstance] = useState(undefined);
  const [showRecurrenceModal, setShowRecurrenceModal] = useState(false);
  const [taskRecurrence, setTaskRecurrence] = useState();
  const [recurrenceOptions, setRecurrenceOptions] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [canNavigateToAssociation, setCanNavigateToAssociation] =
    useState(false);

  const clearForm = () => {
    setDeleteEventInstance();
    setTaskRecurrence();
  };

  useEffect(() => {
    setUpdatedData(data);
  }, [data]);

  useEffect(() => {
    setAssociationRef(updatedData?.association);
    setAssociationName(updatedData?.associationName);

    if (associationRef) {
      const assciationArray = associationRef?.split("/");
      if (assciationArray[0] === "Asset") {
        setAssociationRoute(GET_ASSET_VIEW_PATH(assciationArray[1]));
        setCanNavigateToAssociation(true);
      } else if (assciationArray[0] === "Property") {
        setAssociationRoute(GET_PROPERTY_VIEW_PATH(assciationArray[1]));
        setCanNavigateToAssociation(true);
      } else if (assciationArray[0] === "Project") {
        setCanNavigateToAssociation(false);
      }
    }
  }, [updatedData?.associationName, updatedData?.association, associationRef]);

  useEffect(() => {
    const getEventRecurringOptions = async () => {
      try {
        const { left, right } = getEventQueryLeftRight(
          updatedData?.metadata?.createdAt
        );
        const { data: allEvents } = await EventAPI.get({
          params: {
            left,
            right,
            association: updatedData?.association,
          },
        });

        const rawRecurringEvents = {};

        allEvents?.entries
          ?.filter(({ resource }) => resource?.id === updatedData?.id)
          ?.forEach(({ resource }) => {
            if (resource?.recurrence) {
              if (keepRecurrence(resource?.instanceStartDate)) {
                if (!rawRecurringEvents[resource.id]) {
                  rawRecurringEvents[resource.id] = [resource];
                } else {
                  rawRecurringEvents[resource.id].push(resource);
                }
              }
            }
          });

        const recurringEvents = Object.values(rawRecurringEvents).map(
          (entry) => {
            const sortedEntry = entry.sort((d1, d2) =>
              moment(d1.instanceStartDate).diff(moment(d2.instanceStartDate))
            );
            const recurringCalendarOptions = sortedEntry.map((event) => {
              return {
                title: event.name,
                date: event.instanceStartDate,
                allDay: false,
                classNames: ["custom-event"],
              };
            });
            const recurringTasks = {
              ...sortedEntry[0],
              recurringCalendarOptions,
            };
            return recurringTasks;
          }
        );

        if (recurringEvents[0]?.recurringCalendarOptions) {
          const options = recurringEvents[0]?.recurringCalendarOptions.map(
            (option) => ({
              label: moment(option.date).format("llll"),
              value: option.date,
            })
          );
          setRecurrenceOptions(options);
        }
      } catch (error) {
        console.warn(error.message);
      }
    };

    if (updatedData) {
      getEventRecurringOptions();
    }
  }, [updatedData]);

  useEffect(() => {
    const guests = updatedData?.invitees?.reduce(
      (list, item) => {
        const { firstName, lastName } = userDict?.[item]?.name ?? {};
        const label = `${firstName ?? ""} ${lastName ?? ""}`;
        const value = item;
        if (userDict?.[item]?.name) {
          list.assigned.push(label);
          list.invitees.push({ label, value });
        }
        return list;
      },
      { assigned: [], invitees: [] }
    );
    setAssignedTo(guests?.assigned);
    setInvitees(guests?.invitees);
  }, [updatedData?.invitees, userDict]);

  const handleTaskSteps = async (sopId = undefined) => {
    setViewTaskModal(false);
    if (sopId) history.push(GET_SOP_DETAILS_PATH(sopId));
  };

  const navigateToAssociation = () => {
    setViewTaskModal(false);
    history.push(associationRoute);
  };

  const handleDeleteRecurrenceTask = async () => {
    try {
      switch (deleteEventInstance) {
        case SINGLE_EVENT_INSTANCE: {
          await EventAPI.delete(`${updatedData.id}/$${deleteEventInstance}`, {
            params: `instanceStartDate=${taskRecurrence.value}`,
          });
          break;
        }
        case ALL_EVENT_INSTANCES: {
          await EventAPI.delete(`${updatedData.id}/$all`);
          break;
        }
        case FUTURE_EVENT_INSTANCES: {
          await EventAPI.delete(`${updatedData.id}/$future`, {
            params: `instanceStartDate=${taskRecurrence.value}`,
          });
          break;
        }
        default: {
          await EventAPI.delete(updatedData.id);
        }
      }
      toastMessage("Task successfully deleted");
      reload();
      setViewTaskModal(false);
    } catch (error) {
      console.warn("Failed to delete task");
      toastError("Unable to delete task.");
    } finally {
      clearForm();
    }
  };

  const onDeleteClick = () => {
    if (updatedData?.recurrence) {
      setShowRecurrenceModal(true);
    } else {
      handleDeleteRecurrenceTask();
    }
  };

  return (
    <>
      <Modal
        closeTimeoutMS={200}
        childContainerClassName="!pr-4 !pl-4"
        title={title}
        isOpen={viewTaskModal}
        primaryButtonTitle="Close"
        primaryButtonOnClick={() => setViewTaskModal(false)}
        onRequestModalClose={() => {
          setViewTaskModal(false);
        }}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
        overlayStyle={overlayStyle}
        contentStyle={contentStyle}
        titleStyle={titleStyle}
        headerStyle={headerStyle}
        buttonCnClass="mb-1"
        onDeleteClick={() => onDeleteClick()}
        isRecurringTask={updatedData?.recurrence}
        modalAction={`task ${updatedData?.name}`}
      >
        <AssetMaintenanceScheduledTaskView
          isLoading={false}
          currentUser={currentUser}
          invitees={invitees}
          assignedTo={assignedTo}
          data={updatedData}
          setAllStepsCompleted={setAllStepsCompleted}
          allStepsCompleted={allStepsCompleted}
          navigateToSop={handleTaskSteps}
          taskStatus={updatedData?.status}
          canNavigateToAssociation={canNavigateToAssociation}
          associationRef={associationRef}
          associationName={associationName}
          navigateToAssociation={navigateToAssociation}
          isMaintenanceView
          hideSteps={hideSteps}
        />
      </Modal>
      <TaskRecurrenceInstanceModal
        showRecurrenceModal={showRecurrenceModal}
        recurrenceAtion="Delete"
        onDeleteRecurrenceModalClose={() => {
          clearForm();
          setShowRecurrenceModal(false);
        }}
        handleDeleteRecurrence={() => {
          setShowDeleteModal(true);
          setShowRecurrenceModal(false);
        }}
        deleteEventInstance={deleteEventInstance}
        setDeleteEventInstance={setDeleteEventInstance}
        taskRecurrence={taskRecurrence}
        setTaskRecurrence={setTaskRecurrence}
        recurrenceOptions={recurrenceOptions}
      />
      <Modal
        closeTimeoutMS={200}
        title={`Delete ${
          taskRecurrence?.label ? "task recurrence instance" : "task"
        }`}
        isOpen={showDeleteModal}
        primaryButtonTitle="Yes, delete"
        primaryButtonOnClick={handleDeleteRecurrenceTask}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={() => {
          clearForm();
          setShowDeleteModal(false);
        }}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
        overlayStyle={overlayStyle}
        contentStyle={contentStyle}
        titleStyle={titleStyle}
        headerStyle={headerStyle}
        alert
      >
        <p className="text-base mb-2">
          Are you sure you want to delete{" "}
          {taskRecurrence?.label ?? updatedData?.name}? Once deleted, it cannot
          be recovered.
        </p>
      </Modal>
    </>
  );
};

TaskView.propTypes = {
  data: PropTypes.shape({ steps: PropTypes.shape({}) }),
  currentUser: PropTypes.shape({}),
  setViewTaskModal: PropTypes.func,
  viewTaskModal: PropTypes.bool,
  reload: PropTypes.func,
  title: PropTypes.string,
  hideSteps: PropTypes.bool,
};
TaskView.defaultProps = {
  data: {},
  currentUser: {},
  setViewTaskModal: undefined,
  viewTaskModal: false,
  reload: () => {},
  title: "Task",
  hideSteps: false,
};

export default TaskView;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAppState } from "../../../../state/appState";
import "./MessageChannelHeader.css";
import MembersRow from "../../../../stories/Components/Members/MembersRow";
import ImageCell from "../../../../stories/Components/Table/ImageCell";

const MessageChannelHeader = ({ name, members }) => {
  const [{ usersStreamDict }] = useAppState();
  const [showMembers, setShowMembers] = useState(false);

  return (
    <header
      className="py-3 border-b border-gray-175"
      style={{ minHeight: "64px", maxHeight: "64px" }}
    >
      <div className="flex gap-3 px-4 items-center">
        <p className="font-semibold text-gray-500 truncate text-lg">{name}</p>
        {members?.slice(0, 3)?.map((member) => (
          <button
            key={member?.user_id}
            className="relative show-hoverover w-10 h-10"
            type="button"
          >
            <ImageCell
              className="w-10 h-10 rounded-full cursor-pointer object-cover"
              src={usersStreamDict?.[member.user_id]?.avatar}
              title={
                usersStreamDict?.[member.user_id]?.name ?? member?.user?.name
              }
            />
            <span
              className="absolute flex flex-1 whitespace-nowrap text-xs p-1 rounded shadow-lg bg-gray-700 text-white capitalize"
              style={{
                opacity: "1",
                transform: "translateY(-125%)",
                top: "200%",
              }}
            >
              {usersStreamDict?.[member.user_id]?.name ?? member?.user?.name}
            </span>
          </button>
        ))}

        {members?.length > 3 && (
          <button
            className="bg-gray-100 text-sm font-bold text-gray-400 rounded-full w-10 h-10 flex justify-center items-center cursor-pointer relative"
            onClick={() => setShowMembers((prev) => !prev)}
            type="button"
          >
            {`+${members?.length - 3}`}
            {showMembers && (
              <div
                className="absolute bg-white overflow-y-scroll right-0 rounded-md shadow-1 p-3"
                style={{ zIndex: "33", maxHeight: "200px", top: "125%" }}
              >
                <MembersRow
                  currentMembers={members.slice(3).map((item) => {
                    return (
                      usersStreamDict?.[item.user_id] ?? {
                        name: item?.user?.name,
                      }
                    );
                  })}
                />
              </div>
            )}
          </button>
        )}
      </div>
    </header>
  );
};

MessageChannelHeader.propTypes = {
  name: PropTypes.string,
  members: PropTypes.arrayOf(PropTypes.shape({})),
};

MessageChannelHeader.defaultProps = {
  name: "",
  members: [],
};

export default MessageChannelHeader;

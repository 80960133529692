import { useCallback, useEffect } from "react";
import { AttributeAPI } from "@griffingroupglobal/eslib-api";
import { useAppState } from "../state/appState";
import { SET_ATTRIBUTES } from "../constants";

export default () => {
  const [{ attributes, selectedAttributes }, dispatch] = useAppState();

  const reload = useCallback(async () => {
    const { data } = await AttributeAPI.get();
    const selected = [];

    dispatch({
      type: SET_ATTRIBUTES,
      attributes: data?.entries?.map((entry) => {
        if (entry.resource?.selected) {
          selected.push(entry.resource);
        }
        return entry.resource;
      }),
      selectedAttributes: selected,
    });
  }, [dispatch]);

  useEffect(() => {
    reload();
  }, [reload]);

  return [attributes, reload, selectedAttributes];
};

import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import usePagePersistance from "../usePagePersistence";
import useAuthenticatedQuery from "../useAuthenticatedQuery";
import timesheetKeys from "./timesheetKeys";
import fetchTimesheetListHandler from "./timesheetHandlers/fetchTimesheetListHandler";
import getTsListParams from "../../helpers/Timesheets/getTsListParams";

const useTimesheetList = () => {
  const { pageState } = usePagePersistance();
  const query = getTsListParams(pageState);
  const queryClient = useQueryClient();

  const authenticatedResponse = useAuthenticatedQuery({
    queryKey: timesheetKeys.timesheetList(query),
    queryFn: () => fetchTimesheetListHandler(query),
    staleTime: 0,
    onError: () => {
      toast.error("failed to get timesheets");
    },
  });

  const refectchTsList = async () => {
    await queryClient.invalidateQueries(query);
  };

  const {
    data: timesheetsList,
    isLoading: timesheetsLoading,
    isError: isTimesheetsError,
    error: timesheetsErrror,
  } = authenticatedResponse;

  return {
    refectchTsList,
    timesheetsList,
    timesheetsLoading,
    isTimesheetsError,
    timesheetsErrror,
  };
};

export default useTimesheetList;

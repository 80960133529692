import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import BaseButton from "../Buttons/BaseButton";
import crossIcon from "../../assets/images/Close.svg";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import BooleanBullet from "../BooleanBullet/BooleanBullet";

const BudgetTableExpensesCell = ({ row, columnId, setOpenExpense, title }) => {
  const [showPopup, setShowPopup] = useState(false);
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, () => setShowPopup(false));

  return (
    <div className="flex flex-row items-center" ref={wrapperRef}>
      <div
        onClick={(e) => {
          e.stopPropagation();
          if (row?.original?.[columnId]?.length) {
            setShowPopup((prev) => !prev);
          }
        }}
        tabIndex={0}
        role="button"
        onKeyDown={() => {}}
        className="text-xs text-gray-400 text-ellipsis overflow-hidden"
      >
        {row?.original?.[columnId]?.length
          ? row?.original?.[columnId]?.reduce(
              (previousValue, currentValue) =>
                `${previousValue + currentValue?.name}, `,
              ""
            )
          : ""}
      </div>
      {showPopup && (
        <div
          className="flex flex-col rounded border border-gray-150 z-20 bg-white absolute p-2 w-72 top-8"
          onClick={(e) => {
            e.stopPropagation();
          }}
          tabIndex={0}
          role="button"
          onKeyDown={() => {}}
        >
          <div className="flex justify-between">
            <div className="flex pb-2">
              <p className="font-semibold text-xs">{title}</p>
            </div>
            <BaseButton
              onClick={() => {
                setShowPopup(false);
              }}
              iconLeft={
                <img
                  src={crossIcon}
                  alt="crossIcon"
                  style={{ height: 8, width: 10 }}
                />
              }
            />
          </div>
          <div className="flex flex-col">
            {row?.original?.[columnId]?.map((option) => (
              <div
                className="flex flex-col pb-2 break-words whitespace-normal"
                key={option.reference}
              >
                <div
                  className="flex flex-row pb-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenExpense(option.reference.split("/")[1]);
                  }}
                  tabIndex={0}
                  role="button"
                  onKeyDown={() => {}}
                >
                  <BooleanBullet isTrue className="mr-1 mt-1 bg-brandGreen" />
                  <div className="font-semibold text-gray-300 text-xs">
                    {option.name}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

BudgetTableExpensesCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({}),
  }),
  setOpenExpense: PropTypes.func,
  columnId: PropTypes.string,
  title: PropTypes.string,
};

BudgetTableExpensesCell.defaultProps = {
  row: undefined,
  setOpenExpense: undefined,
  columnId: undefined,
  title: undefined,
};

export default BudgetTableExpensesCell;

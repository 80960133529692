import { ProjectAPI } from "@griffingroupglobal/eslib-api";
import sortByProperty from "../Utilities/sortByProperty";

/**
 * Fetch all projects
 * @returns {Promise<Object[]>}
 */
const fetchProjects = async () => {
  const { data } = await ProjectAPI.get();

  let projects = [];

  if (data?.entries) {
    projects = data.entries.map((entry) => {
      const resource = entry?.resource;

      return resource;
    });
  }

  // Sort projects by ES rule
  return sortByProperty(projects, "name");
};

export default fetchProjects;

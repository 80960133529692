import React, { useState } from "react";
import PropTypes from "prop-types";
import Spinner from "react-loader-spinner";
import cntl from "cntl";
import Input from "../Input/Input";
import DeleteButton from "../DeleteButton/DeleteButton";
import editIcon from "../../assets/images/editIcon.png";
import checkmarkIcon from "../../assets/images/checkmark.svg";
import CrossButton from "../CrossButton/CrossButton";

const LOADING_ICON_SIZE = 20;

const iconCN = cntl`
  w-5
  h-5
`;

const iconButtonCN = cntl`
  ml-2
`;

const EditView = ({ file, index, onEdit, onRemove }) => {
  const [fileToEdit, setFileToEdit] = useState();
  const [newFileName, setNewFileName] = useState();
  const [editLoading, setEditLoading] = useState(false);

  const onEditClick = (f) => {
    setFileToEdit(f);
    setNewFileName(f.name);
  };

  const onCompleteEdit = async () => {
    if (fileToEdit.name !== newFileName) {
      setEditLoading(true);
      await onEdit(fileToEdit, newFileName);
      setEditLoading(false);
    }
    setFileToEdit(undefined);
    setNewFileName(undefined);
  };

  const onCancelEdit = () => {
    setFileToEdit(undefined);
    setNewFileName(undefined);
  };

  return (
    <div className="flex flex-1 flex-col ml-3">
      <div className="flex justify-between items-center">
        {fileToEdit?.id === file?.id ? (
          <>
            <Input
              placeholder="File Name"
              className="flex-1"
              value={newFileName}
              onChange={(name) => setNewFileName(name)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  onCompleteEdit();
                }
              }}
            />
            {editLoading ? (
              <Spinner
                className={iconButtonCN}
                type="Puff"
                color="white"
                height={LOADING_ICON_SIZE}
                width={LOADING_ICON_SIZE}
              />
            ) : (
              <>
                <button
                  className={iconButtonCN}
                  type="button"
                  onClick={onCompleteEdit}
                >
                  <img
                    className={iconCN}
                    src={checkmarkIcon}
                    alt="accept name change"
                  />
                </button>
                <CrossButton className="ml-1" onClick={onCancelEdit} />
              </>
            )}
          </>
        ) : (
          <>
            <p>{file.name || `File ${index + 1}`}</p>
            <div className="flex">
              <button
                className={iconButtonCN}
                type="button"
                onClick={() => onEditClick(file)}
              >
                <img className={iconCN} src={editIcon} alt="edit" />
              </button>
              <DeleteButton
                className={iconButtonCN}
                onConfirm={() => onRemove(file.id)}
              />
            </div>
          </>
        )}
      </div>
      <progress className="mt-2 w-full" value={1} max={1} />
      <p className="mt-2 text-gray-300">Completed</p>
    </div>
  );
};

EditView.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};

EditView.defaultProps = {
  onEdit: undefined,
  onRemove: undefined,
};

export default EditView;

import PropTypes from "prop-types";
import React, { useState } from "react";

import HelpIcon from "../../assets/images/Help.svg";
import arrowGreen from "../../assets/images/ArrowGreen.svg";

const InfoTooltipSignup = ({ title }) => {
  const [hovering, setHovering] = useState(false);

  return (
    <>
      {hovering && (
        <div
          className="absolute z-100 flex flex-col h-fit bg-es-white"
          style={{ marginBottom: "90px", marginLeft: "39px" }}
        >
          <div
            className="flex flex-row items-center bg-es-green p-3 rounded-md"
            style={{ width: "251px", height: "54px" }}
          >
            {title && (
              <p className="text-es-sm text-white font-es-semibold">{title}</p>
            )}
          </div>
          <img
            style={{ width: "12px" }}
            className={!hovering ? "invisible" : "flex self-center"}
            src={arrowGreen}
            alt=""
          />
        </div>
      )}
      <div
        className="flex flex-col cursor-default h-fit"
        onMouseOver={() => setHovering(true)}
        onMouseOut={() => setHovering(false)}
        onFocus={() => setHovering(true)}
        onBlur={() => setHovering(false)}
      >
        <img className="h-6" src={HelpIcon} alt="" />
      </div>
    </>
  );
};

InfoTooltipSignup.propTypes = {
  title: PropTypes.string,
  toolTipStyle: PropTypes.shape({}),
  customStyle: PropTypes.shape({}),
};

InfoTooltipSignup.defaultProps = {
  title: undefined,
  toolTipStyle: undefined,
  customStyle: undefined,
};

export default InfoTooltipSignup;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import * as yup from "yup";
import { DocumentAPI } from "@griffingroupglobal/eslib-api";

import useDocuments from "../../../hooks/useDocuments";
import useFavorites from "../../../hooks/useFavorites";

import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import SelectUserInterface from "../SelectUserInterface/SelectUserInterface";
import DocumentUploadForm from "../DocumentUploadForm/DocumentUploadForm";
import FileCard from "../FileCard/FileCard";

import Modal from "../Modal/Modal";
import Input from "../Input/Input";
import Dropdown from "../Dropdown/Dropdown";
import Checkbox from "../Checkbox/Checkbox";
import DatePicker from "../DatePicker/DatePicker";

import eventIcon from "../../assets/images/eventIcon.png";
import {
  RESET,
  SIMPLE_INPUT,
  NESTED_INPUT,
  LIST_ADD_ONE,
  LIST_REMOVE_ONE,
  ADD_DOCUMENT_ATTACHMENTS,
  REMOVE_DOCUMENT_ATTACHMENT,
  TimeOptions,
  fileCardStyle,
} from "../../../constants";
import { formatSelectUser } from "../../../helpers/Formatters";

const eventIconElement = (
  <img className="w-4 h-4" src={eventIcon} alt="event icon" />
);

const csiCodes = [
  { label: "Financial Code A", value: "finCodeA" },
  { label: "Financial Code B", value: "finCodeB" },
  { label: "Financial Code C", value: "finCodeC" },
  { label: "Financial Code D", value: "finCodeD" },
];

const RequestForInformation = ({
  document,
  dispatch,
  existingDoc,
  currentUser,
  userData,
  docTypeOptionsMap,
}) => {
  const { documents, addDocuments, addDocument, removeDocument } =
    useDocuments();
  const [favorites, { postFavorite, deleteFavorite }] = useFavorites();

  const [userOptions, setUserOptions] = useState([]);
  const [durationDays, setDurationDays] = useState();
  const [durationHours, setDurationHours] = useState();

  const [addedFiles, setAddedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState();

  const docLineItem = React.useMemo(() => {}, []);

  useEffect(() => {
    if (existingDoc) {
      dispatch({
        type: RESET,
        value: existingDoc,
      });
    }
  }, [dispatch, existingDoc]);

  useEffect(() => {
    if (docLineItem?.financialCode && !document.financialCode) {
      dispatch({
        type: SIMPLE_INPUT,
        key: "financialCode",
        value: docLineItem.financialCode,
      });
    }
    if (docLineItem?.company && !document.company) {
      dispatch({
        type: SIMPLE_INPUT,
        key: "company",
        value: docLineItem.company,
      });
    }
    if (document.dueDate?.date) {
      const today = moment();
      const dueDate = moment(document.dueDate.date);
      const diffDays = dueDate.diff(today, "days");
      const diffHours = dueDate.diff(today, "hours");

      if (diffDays > 0) {
        setDurationDays(diffDays);
        setDurationHours();
      } else if (diffHours > 0) {
        setDurationDays();
        setDurationHours(diffHours);
      } else {
        setDurationDays();
        setDurationHours();
      }
    }
  }, [document, docLineItem, dispatch]);

  useEffect(() => {
    if (userData?.length && !userOptions.length) {
      setUserOptions(userData);
    }
  }, [userData, userOptions]);

  const handleFavoriteClick = React.useCallback(
    (id) => {
      const foundItem = favorites.find((favorite) => {
        return favorite.item.reference.includes(id);
      });
      const foundDoc = documents?.find((doc) => doc.id === id);

      if (foundItem) {
        deleteFavorite([foundItem]);
      } else {
        postFavorite(id, "Document");
      }

      if (foundDoc) {
        delete foundDoc.isFavorited;
        dispatch({
          type: ADD_DOCUMENT_ATTACHMENTS,
          value: document.attachments?.map((item) => {
            if (item.id === foundDoc.id) {
              return foundDoc;
            }
            return item;
          }),
        });
        addDocument(foundDoc);
      }
    },
    [
      document,
      documents,
      favorites,
      dispatch,
      deleteFavorite,
      postFavorite,
      addDocument,
    ]
  );

  useEffect(() => {
    const stillUploading = addedFiles.some((item) => item.isUploading);
    if (!stillUploading && isUploading && addedFiles.length) {
      addedFiles.forEach((item) => {
        if (item.isFavorited) {
          handleFavoriteClick(item.id);
        }
      });
      addDocuments(addedFiles);
      dispatch({
        type: ADD_DOCUMENT_ATTACHMENTS,
        value: addedFiles,
      });
      setIsUploading(false);
      setAddedFiles([]);
    }
  }, [addedFiles, isUploading, dispatch, addDocuments, handleFavoriteClick]);

  const handleFileAdded = React.useCallback(
    (fileRef, fileBody, showSpinner) => {
      if (showSpinner) {
        setIsUploading(true);
      }

      const currentFile = {
        isUploading: true,
        reference: fileRef,
        isFavorited: fileBody.isFavorited,
      };

      setAddedFiles((prev) => [currentFile, ...prev]);

      if (fileBody) {
        const tempDocument = {
          customName: fileBody.name,
          docType: fileBody.docType,
          contentReference: fileRef,
          lineItems: [],
          status: "open",
        };

        if (document?.project) {
          tempDocument.project = document?.project;
        } else if (document?.property) {
          tempDocument.property = document?.property;
        }

        DocumentAPI.post(tempDocument)
          .then(({ data: newDocument }) => {
            const resultDocument = {
              ...newDocument,
              creator: currentUser,
              isFavorited: fileBody.isFavorited,
              uploadedFileRef: fileRef,
            };
            setAddedFiles((prev) => {
              return [
                resultDocument,
                ...prev.filter((item) => item.reference !== fileRef),
              ];
            });
          })
          .catch((err) => console.error(err));
      }
    },
    [currentUser, document?.project, document?.property]
  );

  const handleShowDelete = (doc) => {
    setDeleteDoc(doc);
    setShowDeleteModal(true);
  };

  const handleHideDelete = () => {
    setDeleteDoc();
    setShowDeleteModal(false);
  };

  const handleDeleteDocument = async () => {
    setIsDeleting(true);
    await DocumentAPI.delete(deleteDoc.id)
      .then(({ data: docData }) => {
        setAddedFiles((prev) => prev.filter((item) => item.id !== docData.id));
        dispatch({
          type: REMOVE_DOCUMENT_ATTACHMENT,
          value: docData,
        });
        removeDocument(docData.id);
      })
      .catch((err) => {
        console.error(err);
      });

    setIsDeleting(false);
    handleHideDelete();
  };

  const getDocType = React.useCallback(
    (documentType) => {
      if (documentType) {
        return docTypeOptionsMap[documentType]?.label ?? documentType;
      }
      return "File";
    },
    [docTypeOptionsMap]
  );

  const handleSimpleInput = (key, value) => {
    dispatch({
      type: SIMPLE_INPUT,
      key,
      value,
    });
  };

  const handleNestedInput = (key1, key2, value) => {
    dispatch({
      type: NESTED_INPUT,
      key1,
      key2,
      value,
    });
  };

  const handleTimeInput = (val) => {
    if (!val) return;
    const hour = Number(val.split(":")[0]);
    const minute = Number(val.split(":")[1]);
    const { dueDate } = document ?? {};
    const tmpDate = moment(dueDate?.date).set({ hour, minute }).toISOString();

    dispatch({
      type: SIMPLE_INPUT,
      key: "dueDate",
      value: { ...(dueDate ?? {}), date: tmpDate },
    });
  };

  const handleListAdd = (key, val) => {
    val.forEach(({ value }) => {
      dispatch({
        type: LIST_ADD_ONE,
        key,
        value: { id: value },
      });
    });
  };

  const handleListRemove = (key, value) => {
    dispatch({
      type: LIST_REMOVE_ONE,
      key,
      value,
    });
  };

  const handleUpdateUsers = (val) => {
    setUserOptions((prev) => [
      ...prev,
      formatSelectUser({
        ...val,
        role: val.roleName,
      }),
    ]);
  };

  const getUserList = () => {
    const tmp = userOptions.filter((item) =>
      document.distro?.find((usr) => usr.id === item.value)
    );
    return tmp;
  };

  return (
    <>
      {document.financialCode && (
        <Dropdown
          className="mt-5"
          label="CSI Code"
          options={csiCodes}
          value={csiCodes.find((item) => item.value === document.financialCode)}
          placeholder="Search or Select"
          onChange={({ value }) => handleSimpleInput("financialCode", value)}
        />
      )}

      <div className="">
        <div
          className="ESInputLabel mt-5"
          style={{ justifyContent: "flex-start" }}
        >
          Add Recipients<span className="text-brandGreen text-sm">*</span>
        </div>
        <SelectUserInterface
          className="w-full"
          userList={getUserList()}
          userPlaceholder="Search Companies or members..."
          userOptions={userOptions}
          buttonText="Add Contact"
          onAddUser={(val) => handleListAdd("distro", val)}
          onRemoveUser={(val) => handleListRemove("distro", val)}
          updateSelect={handleUpdateUsers}
          showCurrentRole
        />
      </div>

      <div className="flex items-center mt-5">
        <DatePicker
          className="flex-1 mr-2"
          label="Due Date "
          placeholder="MM-DD-YYYY"
          value={document.dueDate?.date}
          iconLeft={eventIconElement}
          onChange={(val) => handleNestedInput("dueDate", "date", val)}
          validation={yup.date().required()}
        />
        {!document.dueDate?.allDay && (
          <Dropdown
            className="flex-1 mt-7 mr-2"
            label=""
            options={TimeOptions}
            value={TimeOptions.find((item) => {
              if (!document.dueDate?.date) return false;
              return (
                item.value === moment(document.dueDate.date).format("HH:mm")
              );
            })}
            placeholder="Select"
            onChange={({ value }) => handleTimeInput(value)}
            disableClear
            disableSort
          />
        )}
        <Checkbox
          className="mt-7"
          label="All Day"
          checked={document.dueDate?.allDay}
          onChange={() =>
            handleNestedInput("dueDate", "allDay", !document.dueDate?.allDay)
          }
        />
      </div>

      <div className="flex">
        {durationDays && (
          <div className="ESInputLabel mt-1 mr-2">{`${durationDays} ${
            durationDays > 1 ? "Days" : "Day"
          }`}</div>
        )}
        {durationHours && (
          <div className="ESInputLabel mt-1 mr-2">{`${durationHours} ${
            durationHours > 1 ? "Hours" : "Hour"
          }`}</div>
        )}
      </div>

      <SecondaryHeader className="mt-5">
        Request for Information
      </SecondaryHeader>
      <Input
        className=""
        label="Title"
        placeholder="Title"
        value={document.customName}
        onChange={(val) => handleSimpleInput("customName", val)}
        validation={yup.string().required()}
        disableClear
      />
      <Input
        placeholder="Description"
        className="mt-1"
        label="Description"
        value={document.description}
        onChange={(val) => handleSimpleInput("description", val)}
        disableClear
        isTextarea
      />

      <SecondaryHeader className="mt-5">Attachments</SecondaryHeader>
      <div className="flex flex-wrap w-full mt-2">
        <DocumentUploadForm
          onAddFile={(fileRef, file) => handleFileAdded(fileRef, file, true)}
          onAddPhoto={(fileRef, file) => handleFileAdded(fileRef, file, true)}
          onAddVideo={(fileRef, file) => handleFileAdded(fileRef, file, true)}
          showCorner
          small
        />
        {[...addedFiles, ...(document.attachments ?? [])]?.map((doc) => {
          return (
            <div key={doc.id}>
              <FileCard
                {...doc}
                docType={getDocType(doc.docType)}
                createdAt={
                  doc.metadata?.createdAt
                    ? moment(doc.metadata.createdAt).format("MM/DD/YYYY")
                    : "--"
                }
                isFavorited={
                  doc.isFavorited
                    ? doc.isFavorited
                    : favorites.some((fav) => {
                        return fav.item.reference.includes(doc.id);
                      })
                }
                style={fileCardStyle}
                onFileClick={() => {}}
                onFileEdit={() => {}}
                onShowHistory={() => {}}
                onSendToWorkflow={() => {}}
                onUploadVersion={() => {}}
                onFileDelete={() => handleShowDelete(doc)}
                onFavoriteClick={handleFavoriteClick}
              />
            </div>
          );
        })}
      </div>

      <Modal
        title={
          deleteDoc?.name ? `Delete ${deleteDoc.name}?` : "Delete Document?"
        }
        isOpen={showDeleteModal}
        primaryButtonTitle="Yes, delete"
        primaryButtonOnClick={handleDeleteDocument}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={handleHideDelete}
        deleting={isDeleting}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <>
          <p className="text-base mb-2">
            Are you sure you want to delete {deleteDoc?.name ?? "this document"}
            ? Once deleted, it cannot be recovered.
          </p>
        </>
      </Modal>
    </>
  );
};

RequestForInformation.propTypes = {
  /**
   * create document form state
   */
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.object,
  /**
   * create document form dispatcher
   */
  dispatch: PropTypes.func,
  /**
   * existing document data
   */
  // eslint-disable-next-line react/forbid-prop-types
  existingDoc: PropTypes.object,
  /**
   * current user data
   */
  // eslint-disable-next-line react/forbid-prop-types
  currentUser: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  userData: PropTypes.arrayOf(PropTypes.object),
  /**
   * map of all document type options
   */
  // eslint-disable-next-line react/forbid-prop-types
  docTypeOptionsMap: PropTypes.object,
};

RequestForInformation.defaultProps = {
  document: {},
  dispatch: undefined,
  existingDoc: null,
  currentUser: {},
  userData: [],
  docTypeOptionsMap: {},
};

export default RequestForInformation;

import React from "react";
import PropTypes from "prop-types";
import useSegmentedControl from "./useSegementedControlData";

const SegmentedControl = ({ segments, selectedSegment, onSelect }) => {
  const { handleSegmentClick, getClassname } = useSegmentedControl({
    segments,
    selectedSegment,
    onSelect,
  });

  return (
    <div className="flex w-full px-4 justify-center">
      {segments.map((option, index) => (
        <button
          key={option.id}
          type="button"
          onClick={(event) => handleSegmentClick(event, option)}
          className={getClassname(option, index)}
        >
          <span className="text-xs">{option.label}</span>
        </button>
      ))}
    </div>
  );
};

SegmentedControl.defaultProps = {
  segments: [],
  selectedSegment: undefined,
  onSelect: () => {},
};

SegmentedControl.propTypes = {
  segments: PropTypes.arrayOf(PropTypes.shape({})),
  selectedSegment: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  onSelect: PropTypes.func,
};

export default SegmentedControl;

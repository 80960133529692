import { useEffect, useState } from "react";
import { getFullName } from "../../../../../helpers/Formatters";
import useUserDict from "../../../../../hooks/useUserDict";
import useGetMergedTimesheets from "../../../../../hooks/useGetMergedTimesheets";
import useTimesheetsForBudget from "../../../../../hooks/useTimesheetForBudget";

const useDocumentTimesheetsData = ({ project, document, csiCodes }) => {
  const { data } = useGetMergedTimesheets(
    project?.reference,
    document?.reference
  );
  const { data: timesheetsData } = useTimesheetsForBudget({
    project: project?.reference,
  });
  const { data: userDict } = useUserDict();

  const [tableData, setTableData] = useState([]);
  const [rateSheetRates, setRateSheetRates] = useState([]);

  useEffect(() => {
    if (project) {
      setRateSheetRates(
        project?.rateSheet?.rates?.map((rate) => ({
          label: rate.category,
          value: rate.id,
          ratePerHr: rate.ratePerHr,
        }))
      );
    }
  }, [project]);

  useEffect(() => {
    if (data?.timesheets?.length) {
      setTableData(
        data.timesheets?.reduce((filteredTimesheets, { reference, entry }) => {
          const timesheet = timesheetsData?.timesheetsDict?.[reference];
          const timesheetEntry = timesheet?.[entry];

          if (timesheetEntry) {
            const user = userDict[timesheetEntry.userRef];
            const employee = getFullName(user?.name);
            const csiCodeObj = csiCodes?.find(
              (c) =>
                c.value ===
                `${timesheetEntry?.financialCode?.division} ${timesheetEntry?.financialCode?.code} ${timesheetEntry?.financialCode?.subcode}`
            )?.label;

            const rateInfo = rateSheetRates?.find(
              (rate) => rate.value === timesheetEntry.rate
            );
            filteredTimesheets.push({
              ...timesheetEntry,
              category: csiCodeObj,
              employee,
              timeEntryDate: timesheetEntry.date,
              reference,
              rateDisplay: rateInfo?.label,
              ratePerHr: rateInfo?.ratePerHr,
              totalCost:
                Number(rateInfo?.ratePerHr) *
                (Number(timesheetEntry.value) / 60),
            });
          }
          return filteredTimesheets;
        }, [])
      );
    }
  }, [
    csiCodes,
    data,
    rateSheetRates,
    timesheetsData?.timesheetsDict,
    userDict,
  ]);

  return { tableData };
};

export default useDocumentTimesheetsData;

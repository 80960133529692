/* eslint-disable no-param-reassign */
import cntl from "cntl";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import EditIcon from "../EditIcon/EditIcon";
import "./InlineInput.css";
import CrossButton from "../CrossButton/CrossButton";
import IconButton from "../Buttons/IconButton";
import PrimaryHeader from "../TextHeaders/PrimaryHeader";

const wrapperCN = (editing, loading, width) => cntl`
${editing ? "bg-backgroundGreen" : "bg-transparent"}
${loading && "loadingValue"}
relative 
${width} 
rounded
`;

const inputCN = (editing, errorMessage, background) => cntl`
border
border-gray-150
${`bg-${background ?? "transparent"}`}
rounded
flex
relative
h-max
items-center
${!editing && "container-value-mode"}
${errorMessage && "container-value-error"}
`;

const inputValueCN = (size, fontWeight, color, editing, isHeaderTitle) => cntl`
    ESInput
    h-max
    flex
    items-center
    bg-transparent
    ${editing && "ml-4"}
    ${!editing && `resize-none`}
    ${`font-${fontWeight}`}
    ${size && `text-${size}`}
    ${color && `text-${color}`}
    ${isHeaderTitle && "break-all"}
`;

const buttonCN = (xxs) => cntl`
text-gray-500
shadow-light-lift
rounded-md
bg-gray-150
col-span-1
font-semibold
h-6
w-6
${xxs ? "text-xxs" : "text-xs"}
`;

const errorMessageCN = cntl`
capitalize-first
text-brandRed italic opacity-70
absolute
text-xxs
`;

const unitCN = (editing) => cntl`
text-xs 
${editing ? "text-gray-300 bg-gray-100 px-2" : "text-gray-200"}  
flex 
items-center
`;

const InlineInput = ({
  // Temporal (it would be ideal to use `React.fordwareRef` however I didn't use it because I don't know the use case for the current `inputRef`)
  forwardRef,
  value,
  onConfirm,
  validation,
  size = "base",
  width = "w-1/2",
  fontWeight = "normal",
  color,
  inputStyle,
  containerStyle,
  placeholder = "Enter Input...",
  type = "text",
  unit,
  setOpen,
  textarea,
  rows = "6",
  cols = "20",
  editing,
  disabled,
  loading,
  hidePencil,
  background,
  extension,
  onChangeCallback,
  autoFocus,
  isHeaderTitle,
  onDrop,
  onKeyDown,
  greenbackground,
}) => {
  const [state, setState] = useState({
    value,
    prev: value,
    editing: false,
    error: undefined,
    set: (key, val) =>
      setState((prev) => {
        if (Array.isArray(key)) {
          return {
            ...prev,
            ...key.reduce((obj, item, idx) => {
              obj[item] = val?.[idx];
              return obj;
            }, {}),
          };
        }
        return { ...prev, [key]: val };
      }),
  });

  useEffect(() => {
    if (!loading) {
      setState((prev) => ({ ...prev, value, prev: value }));
    }
  }, [loading, value]);

  const inputRef = useRef();

  /**
   * Error Handling
   */

  const ErrorMessage = useMemo(() => {
    if (validation) {
      try {
        validation.validateSync(state.value);
        return null;
      } catch (err) {
        return err.message;
      }
    }
    return null;
  }, [state, validation]);

  /**
   * Error Handling
   */

  /**
   * Handlers
   */

  const handleChange = useCallback(
    (e) => {
      state.set("value", e.target.value);
      onChangeCallback(e.target.value);
    },
    [onChangeCallback, state]
  );

  const onClearClick = useCallback(() => {
    state.set(["value", "editing"], ["", true]);
    onChangeCallback("");
  }, [onChangeCallback, state]);

  const cancelEdit = useCallback(() => {
    state.set(["value", "editing"], [state.prev, false]);
    onChangeCallback(state.prev);
    setOpen(false);
  }, [onChangeCallback, setOpen, state]);

  const confirmEdit = useCallback(() => {
    state.set(["prev", "editing"], [state.value, false]);
    onChangeCallback(state.value);
    onConfirm(state.value);
    setOpen(false);
  }, [onChangeCallback, onConfirm, setOpen, state]);

  /**
   * Handlers
   */

  const handleEditing = useCallback(() => {
    if (!disabled && !loading) {
      setOpen(true);
      state.set("editing", true);
      inputRef.current?.focus();
    }
  }, [disabled, loading, setOpen, state]);

  return (
    <div
      className={wrapperCN(editing && greenbackground, loading, width)}
      style={containerStyle}
      id="inline-input"
    >
      <div
        className={inputCN(editing ?? state.editing, ErrorMessage, background)}
      >
        {!editing && isHeaderTitle ? (
          <PrimaryHeader
            className={inputValueCN(
              size,
              fontWeight,
              color,
              undefined,
              isHeaderTitle
            )}
          >
            {value?.toUpperCase()}
          </PrimaryHeader>
        ) : (
          <>
            {textarea ? (
              <textarea
                className={inputValueCN(size, "normal", color, editing)}
                style={{ paddingRight: "1.5rem", ...inputStyle }}
                rows={rows}
                cols={cols}
                ref={forwardRef || inputRef}
                value={
                  state?.value?.toString()?.trim() === "" &&
                  !state.editing &&
                  !editing
                    ? placeholder ?? "Not Specified"
                    : state.value
                }
                onChange={handleChange}
                onKeyDown={onKeyDown}
                onDrop={(e) => {
                  if (onDrop) {
                    e.preventDefault();
                    onDrop(e);
                  }
                }}
                onDragOver={(e) => {
                  if (onDrop) e.preventDefault();
                }}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                onFocus={handleEditing}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={autoFocus}
              />
            ) : (
              <input
                className={inputValueCN(size, fontWeight, color)}
                style={{
                  ...inputStyle,
                  backgroundColor: editing ? "#e8f9f5" : null,
                  // This fixes the corner issue with the gray corners being incomplete
                  borderRadius: "4px",
                }}
                ref={forwardRef || inputRef}
                value={
                  state?.value?.toString()?.trim() === "" &&
                  !state.editing &&
                  !editing
                    ? "Not Specified"
                    : state.value
                }
                onChange={handleChange}
                type={type}
                placeholder={placeholder}
                onFocus={handleEditing}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={autoFocus}
              />
            )}
          </>
        )}
        {(editing ?? state.editing) && !unit && (
          <CrossButton
            className={
              !value?.length ? "hidden" : "py-1 pr-2 m-auto bg-transparent"
            }
            onClick={onClearClick}
          />
        )}
        {/* Descriptors */}
        {unit && <span className={unitCN(state.editing)}>{unit}</span>}
        <p className={errorMessageCN} style={{ top: "-1rem" }}>
          {ErrorMessage}
        </p>
        {!state.editing && !unit && !disabled && !editing && !hidePencil && (
          <IconButton
            wrapperClassName={`${
              !editing && !loading && "icon-transition"
            } col-span-1`}
            imgClassName="w-6 h-6"
            iconClassName="w-6 h-6"
            onClick={!editing || loading ? handleEditing : () => {}}
            iconLeft={<EditIcon disabled={editing || loading} />}
          />
        )}
        {/* Descriptors */}
        {editing && extension && (
          <div className="absolute -right-14">
            <p className={`text-${size} text-gray-200`}>{extension}</p>
          </div>
        )}
      </div>
      {/* Editing Actions */}
      {state.editing && !hidePencil && (
        <div
          className="flex justify-end flex-1 pb-2 pt-1 overflow-visible absolute right-0"
          style={{ top: "100%" }}
        >
          <div className="grid grid-cols-2 gap-2">
            <button
              className={buttonCN(true)}
              style={{ zIndex: 5 }}
              type="button"
              onClick={confirmEdit}
            >
              ✔
            </button>
            <button
              className={buttonCN()}
              style={{ zIndex: 5 }}
              type="button"
              onClick={cancelEdit}
            >
              x
            </button>
          </div>
        </div>
      )}

      {/* Editing Actions */}
    </div>
  );
};

InlineInput.propTypes = {
  value: PropTypes.string,
  onConfirm: PropTypes.func,
  validation: PropTypes.shape({
    validateSync: PropTypes.func,
  }),
  size: PropTypes.string,
  /**
   * width of wrapper div
   */
  width: PropTypes.string,
  /**
   * ie. bold,normal,medium
   */
  fontWeight: PropTypes.string,
  color: PropTypes.string,
  inputStyle: PropTypes.shape({}),
  placeholder: PropTypes.string,
  type: PropTypes.string,
  unit: PropTypes.string,
  setOpen: PropTypes.func,
  /**
   * Optional text area prop
   */
  textarea: PropTypes.bool,
  /**
   * Rows in text area
   */
  rows: PropTypes.string,
  /**
   * cols in text area
   */
  cols: PropTypes.string,
  /**
   * disable edit prop
   */
  disabled: PropTypes.bool,
  /**
   * Waiting for value to populate
   */
  loading: PropTypes.bool,
  /**
   * Editing state override
   */
  editing: PropTypes.bool,
  /**
   * Container styles
   */
  containerStyle: PropTypes.shape({}),
  /**
   * Hide Pencil
   */
  hidePencil: PropTypes.bool,
  /**
   * Background default is transparent
   * tailwind color value
   */
  background: PropTypes.string,
  extension: PropTypes.string,
  onChangeCallback: PropTypes.func,
  autoFocus: PropTypes.bool,
  isHeaderTitle: PropTypes.bool,
  /**
   * Text Area file drop
   */
  onDrop: PropTypes.func,
  /**
   * onKeyDown function
   */
  onKeyDown: PropTypes.func,
  forwardRef: PropTypes.shape({}),
  greenbackground: PropTypes.bool,
};

InlineInput.defaultProps = {
  value: "Not Specified",
  onConfirm: () => {},
  validation: undefined,
  size: "base",
  fontWeight: "normal",
  color: undefined,
  inputStyle: undefined,
  placeholder: "Enter Input...",
  type: "text",
  unit: undefined,
  setOpen: () => {},
  textarea: false,
  rows: "6",
  cols: "20",
  disabled: false,
  loading: false,
  editing: undefined,
  width: "w-1/2",
  containerStyle: undefined,
  hidePencil: false,
  background: undefined,
  extension: undefined,
  onChangeCallback: () => {},
  autoFocus: false,
  isHeaderTitle: false,
  onDrop: undefined,
  onKeyDown: () => {},
  forwardRef: undefined,
  greenbackground: true,
};

export default InlineInput;

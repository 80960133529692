import React from "react";

import estateSpaceLogo from "../../stories/assets/images/estatespace-logo-new.png";

const SiteMaintenance = () => {
  return (
    <div className="flex items-center justify-center mt-72">
      <div className="flex flex-col max-w-md">
        <img alt="EstateSpace Logo" src={estateSpaceLogo} className="w-full" />

        <p className="mt-8 text-base tracking-widest font-medium mx-8 text-center">
          EstateSpace is undergoing maintenance. Check back shortly.
        </p>

        <p className="mt-8 text-base tracking-widest font-light mx-8 text-center">
          Please contact us if this issue persists.
        </p>
      </div>
    </div>
  );
};

SiteMaintenance.propTypes = {};

export default SiteMaintenance;

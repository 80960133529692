import { useRef, useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";
import { useUsers } from "../../../hooks/useUsers.new";
import {
  getFirstAndLastFromFullName,
  getUserFirstAndLast,
} from "../../../helpers/Utilities";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import useUserPatch from "../../../hooks/useUserPatch";
import capitalizeFirstLetter from "../../../helpers/Utilities/capitalizeFirstLetter";

const useOwnedByData = ({ onChange, ownedBy }) => {
  const wrapperRef = useRef(null);

  const { data: users } = useUsers();
  const userDict = users?.userDict;
  const usersList = users?.users;

  const { mutateAsync: patchUser } = useUserPatch();
  const [userOptions, setUserOptions] = useState([]);
  const [searchState, setSearchState] = useState("");
  const [editedUser, setEditedUser] = useState();
  const [creatingUser, setCreatingUser] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  // View mode: list of saved owners
  const ownerNames = useMemo(() => {
    return (
      ownedBy?.reduce((acc, { reference, contactType, name }) => {
        const user = userDict?.[reference];
        if (user) {
          const fullName = getUserFirstAndLast(user);
          acc.push({ ...user, label: fullName, value: user.reference });
        } else if (contactType === "contact" && !!name) {
          acc.push({ label: name, value: reference });
        }
        return acc;
      }, []) || []
    );
  }, [ownedBy, userDict]);

  // return existing and contacts to be created on the fly
  const getAllContacts = useCallback(() => {
    const existingUsers =
      usersList?.reduce((acc, user) => {
        const fullName = getUserFirstAndLast(user);
        acc.push({ ...user, label: fullName, value: user.reference });
        return acc;
      }, []) || [];

    const toBeCreatedContacts =
      ownedBy?.reduce((acc, { reference, contactType, name }) => {
        if (contactType === "contact" && !!name) {
          acc.push({
            contactType,
            name,
            reference,
            label: name,
            value: reference,
          });
        }
        return acc;
      }, []) || [];

    return [...existingUsers, ...toBeCreatedContacts];
  }, [ownedBy, usersList]);

  // original list of existing users in system used in search and any new contacts to be created on the fly
  const originalUserList = useMemo(() => {
    return getAllContacts();
  }, [getAllContacts]);

  // keep track of existing and contacts to be created on the fly
  useEffect(() => {
    setUserOptions(getAllContacts());
  }, [getAllContacts]);

  // to select new or existing contacts
  const onAdd = (addedUser) => {
    let list = ownedBy;
    const has = ownedBy.some((u) => u.reference === addedUser.reference);
    if (!has) {
      list = [
        ...ownedBy,
        {
          reference: addedUser.reference,
          contactType: addedUser?.contactType,
          name: addedUser?.name,
        },
      ];
    }

    onChange(list);
  };

  // when saving a newly created contact
  const onCreate = (fullName) => {
    onAdd({
      name: capitalizeFirstLetter(fullName),
      reference: `User/${uuidv4()}`,
      contactType: "contact",
    });
    setCreatingUser(false);
  };

  // to select contact from the ownedBy list
  const onRemove = (reference) => {
    onChange(ownedBy.filter((user) => user.reference !== reference));
  };

  // sets user being edited
  const setEditing = (user) => {
    setEditedUser(user);
  };

  // on editing existing or newly created contact
  const onEdit = useCallback(
    async (name, reference, contactType) => {
      const toBeCreatedContact = contactType === "contact";
      if (toBeCreatedContact) {
        onChange(
          ownedBy.map((user) => {
            if (user.reference === reference) {
              return {
                ...user,
                name,
              };
            }
            return user;
          })
        );
      } else {
        const user = userDict?.[reference];

        const payload = cloneDeep(user);
        if (user?.kind === "company") {
          payload.company.value = name;
        } else {
          const { firstName, lastName } = getFirstAndLastFromFullName(name);
          payload.name = { firstName, lastName };
        }
        await patchUser({
          oldUserInfo: user,
          newUserInfo: payload,
        });
      }
      setEditing(null);
    },
    [onChange, ownedBy, patchUser, userDict]
  );

  // updated list as user types in the search field
  const handleSearch = useCallback((arr, value) => {
    setUserOptions(arr);
    setSearchState(value);
  }, []);

  useOutsideAlerter(wrapperRef, () => {
    setEditing(null);
    setShowPopup(false);
  });

  // function to open/close the list
  const toggleList = (e) => {
    e?.stopPropagation();
    setShowPopup((prev) => !prev);
  };

  return {
    ownerNames,
    originalUserList,
    userOptions,
    searchState,
    editedUser,
    creatingUser,
    showPopup,
    toggleList,
    onCreate,
    handleSearch,
    onEdit,
    onRemove,
    wrapperRef,
    setShowPopup,
    onAdd,
    setEditing,
    setCreatingUser,
  };
};

export default useOwnedByData;

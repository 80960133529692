import { useEffect, useState } from "react";

/**
 * Return non media files
 */
const getOnlyDocuments = (files) => {
  return files.reduce((acc, current) => {
    if (current.category === "Documents") {
      acc.push(current);
    }

    return acc;
  }, []);
};

const useAttachmentsViewData = ({
  stepAttachments,
  filesState,
  handleFilesToAdd,
  handleFilesToRemove,
  noTitle,
}) => {
  const [attachments, setAttachments] = useState([]);

  // This effect set only Media files on state
  useEffect(() => {
    if (!stepAttachments) return;

    const onlyDocuments = getOnlyDocuments(stepAttachments) ?? [];

    // Append possible new added files to be rendered in the list
    setAttachments([...onlyDocuments, ...filesState.nonMediaFilesToAdd]);
  }, [stepAttachments, filesState.nonMediaFilesToAdd]);

  const addNewFiles = (files) => {
    handleFilesToAdd(files, "nonMedia");
  };

  const removeFile = (file) => {
    handleFilesToRemove(file, "nonMedia");
  };

  // Set the initial container class name
  let containerContainerClassName = "grid";

  // If there is no attachments, add a margin bottom
  if (noTitle && attachments.length !== 0) {
    containerContainerClassName += " gap-10";
  }

  // If there is no title, add a margin top
  if (!noTitle) {
    containerContainerClassName += " mt-8 gap-10";
  }

  return {
    attachments,
    containerContainerClassName,
    addNewFiles,
    removeFile,
  };
};

export default useAttachmentsViewData;

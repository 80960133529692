import { useMemo } from "react";
import { usePropertiesOverview } from "../../../hooks/properties";
import useFavorites from "../../../hooks/useFavorites";
import { useProjectsOverview } from "../../../hooks/projects";
import { getSingleResourcePath } from "../../../helpers/Navigation";

const useRouteSidebarData = () => {
  const [favorites] = useFavorites();

  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  // Return list of favorite resources (Property or Projects)
  const favoriteResources = useMemo(() => {
    const hasAnyProperties =
      propertiesDict && Object.keys(propertiesDict)?.length;

    const hasAnyProjects = projectDict && Object.keys(projectDict)?.length;

    if (!favorites?.length || !hasAnyProperties || !hasAnyProjects) {
      return [];
    }

    return favorites.reduce((acc, fav) => {
      const resourceType = fav.item.reference.split("/")[0];

      if (resourceType === "Project" || resourceType === "Property") {
        let name = "";

        if (resourceType === "Property") {
          name = propertiesDict[fav.item.reference]?.title;
        } else if (resourceType === "Project") {
          name = projectDict[fav.item.reference]?.name;
        }

        if (name) {
          acc.push({
            value: fav.item.reference,
            label: name,
            path: getSingleResourcePath(fav.item.reference),
          });
        }
      }

      return acc;
    }, []);
  }, [favorites, projectDict, propertiesDict]);

  return { favoriteResources };
};

export default useRouteSidebarData;

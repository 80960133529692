import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { getSingleResourcePath } from "../../../../../helpers/Navigation";
import { useGetFile } from "../../../../../hooks/useGetFile";
import useUserDict from "../../../../../hooks/useUserDict";

export default (member) => {
  const history = useHistory();
  const { data: userDict } = useUserDict();

  const user = userDict?.[member?.user];

  const { data: avatar } = useGetFile(user?.avatar);

  const handleNavigate = useCallback(() => {
    if (member?.user) history.push(getSingleResourcePath(member?.user));
  }, [history, member?.user]);

  return { handleNavigate, avatar, user };
};

import { useState, useCallback, useEffect } from "react";
import { BudgetAPI, ConfigurationAPI } from "@griffingroupglobal/eslib-api";
import {
  budgetTypesOptions,
  COA_CODE_TYPES,
  SET_BUDGET_DETAILS,
} from "../constants";
import { useAppState } from "../state/appState";
import {
  formatGroupDropdownForBudgetTable,
  formatLineitem,
} from "../helpers/Budget";
import useFinancialsConfiguration from "./useFinancialsConfiguration";

export default (budgetId) => {
  const { data: financialsConfiguration } = useFinancialsConfiguration();
  const [
    {
      budgetLineItems,
      budgetGroups,
      budgetLastUpdated,
      budgetType,
      budgetSummary,
      budgetPendingUpdates,
      isLockGmp,
      isLockFixedFirm,
      budget,
    },
    dispatch,
  ] = useAppState();

  const [loading, setLoading] = useState();

  const reload = useCallback(async () => {
    setLoading(true);

    if (budgetId) {
      try {
        const { data } = await BudgetAPI.getById(budgetId);

        const groups = formatGroupDropdownForBudgetTable(data.groups);

        dispatch({
          type: SET_BUDGET_DETAILS,
          budgetLineItems: data.lineItems.map((li) =>
            formatLineitem(
              li,
              financialsConfiguration?.financials?.divisionsObject,
              financialsConfiguration?.financials?.csiCodeMappingObject,
              groups
            )
          ),
          budgetGroups: groups,
          budgetLastUpdated: data?.metadata?.lastUpdated,
          budgetType:
            budgetTypesOptions?.find(
              (type) => type.value === data.budgetType
            ) ?? data.budgetType,
          budgetSummary: data?.summary,
          budgetPendingUpdates: data?.pendingUpdates,
          isLockGmp: data?.isLockGmp,
          isLockFixedFirm: data?.isLockFixedFirm,
          budget: data,
        });
      } catch (e) {
        console.error(e);
      }

      setLoading(false);
    }
  }, [budgetId, dispatch, financialsConfiguration]);

  useEffect(() => {
    reload();
  }, [reload]);

  return [
    budgetLineItems,
    budgetGroups,
    budgetLastUpdated,
    budgetType,
    budgetSummary,
    budgetPendingUpdates,
    reload,
    dispatch,
    isLockGmp,
    isLockFixedFirm,
    loading,
    budget,
  ];
};

export const getBudgetLineItems = async (budgetId, pullSummary) => {
  let budgetData;

  if (pullSummary) {
    const { data } = await BudgetAPI.getWOP(`${budgetId}/$summary`);
    budgetData = data;
  } else {
    const { data } = await BudgetAPI.getById(budgetId);
    budgetData = data;
  }

  const { data } = await ConfigurationAPI.getById("financials");
  const groups = formatGroupDropdownForBudgetTable(budgetData.groups);

  // Create kep-value pairs for fast search
  const csiCodeMappingObject = {};

  const revenueAccountingCodes = data?.financials?.chartOfAccounts?.filter(
    (code) => code.codeType === COA_CODE_TYPES[1].value
  );

  const expenseAccountingCodes = data?.financials?.chartOfAccounts?.filter(
    (code) => code.codeType === COA_CODE_TYPES[0].value
  );

  data?.financials?.csiCodeMapping.forEach((div) => {
    div?.csiCodes.forEach((csi) => {
      csi?.subCodes.forEach((sub) => {
        const finalKey = `${div.division} ${csi.code} ${sub.code}`;
        let finalDescription = sub.description;
        if (sub.revenueCode) {
          finalDescription = revenueAccountingCodes?.find(
            (revenueCode) => revenueCode.code === sub?.revenueCode
          )?.description;
        } else if (sub.expenseCode) {
          finalDescription = expenseAccountingCodes?.find(
            (expenseCode) => expenseCode.code === sub?.expenseCode
          )?.description;
        }
        csiCodeMappingObject[finalKey] = finalDescription;
      });
    });
  });

  const divisionsObject = {};
  data?.financials?.csiCodeMapping?.forEach((div) => {
    divisionsObject[div.division] = `${div.division} - ${div.description}`;
  });

  const financialsConfiguration = {
    financials: {
      ...data?.financials,
      csiCodeMappingObject,
      divisionsObject,
    },
  };

  return {
    budget: budgetData,
    lineItems: budgetData.lineItems.map((li) =>
      formatLineitem(
        li,
        financialsConfiguration?.financials?.divisionsObject,
        financialsConfiguration?.financials?.csiCodeMappingObject,
        groups
      )
    ),
  };
};

export const getBudget = async (budgetId) => {
  const { data } = await BudgetAPI.getById(budgetId);
  return data;
};

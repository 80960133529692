/* eslint-disable no-await-in-loop */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useFilters, useGlobalFilter, useGroupBy, useTable } from "react-table";
import { v4 as uuidv4 } from "uuid";
import {
  ADD_OPEN_MODAL,
  CALENDAR_PATH,
  CONTACTS,
  CREATE_CONTACT_MODAL,
  FILTER_TYPES,
  REMOVE_USER,
  TABLE_FILTER,
} from "../../../constants";
import {
  hasDeletePermission,
  isEmployeeOrAdmin,
} from "../../../helpers/Permissions";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useGoBack from "../../../hooks/useGoBack";
import { useUsers } from "../../../hooks/useUsers.new";
import useUsersStreamInfo from "../../../hooks/useUsersStreamInfo";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import { useContactSwitchView } from "../../../hooks/useSwitchView";
import useUserDelete from "../../../hooks/useUserDelete";
import {
  formatContactForExport,
  getFilteredAndReducedContactsByFirstLetter,
  getFilteredContacts,
  handleCompanyImport,
  handleUserImport,
} from "../../../helpers/User";
import { contactsViewColumns } from "../../../stories/Components/ContactView/ContactsViewColumns";
import {
  BooleanFilter,
  DateFilter,
  IncludesExcludesFilter,
  IsOrNotFilter,
} from "../../../stories/Components/Table/tableFilters";
import { sortWithSpecialCharacters } from "../../../helpers/Utilities";
import { useModalState } from "../../../state/modalState";
import handleDownloadUsers from "../../../helpers/Users/handleDownloadusers";
import sortContactsAndMembers from "../../../helpers/Utilities/sortContactsAndMembers";

const getCompanyNameFromContact = (user, users) => {
  return user?.kind === "company"
    ? user?.company?.value
    : users?.find((u) => u.reference === user?.company?.value)?.company?.value;
};

const useContactList = () => {
  const { data: currentUser } = useCurrentUser();
  const { navigateBack } = useGoBack();
  const { mutate: deleteUsers } = useUserDelete();
  const [{ modals }, modalDispatch] = useModalState();
  const [usersStreamInfo] = useUsersStreamInfo();
  const history = useHistory();
  const { data: usersData, isLoading: isFetching } = useUsers();
  const { data: managementConfiguration } = useManagementConfiguration();
  const [isShowingTable, setIsShowingTable] = useContactSwitchView();

  const users = usersData?.users;

  const [filteredContactsList, setFilteredContactsList] = useState([]);
  const [sortedContacts, setSortedContacts] = useState({});
  const [showImportModal, setShowImportModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [disciplines, setDisciplines] = useState();
  const [columns, setColumns] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const nonMembersSelected = !selectedRows.some(
    (user) => user?.kind === "member"
  );

  const allowSelection =
    currentUser?.isAdmin || hasDeletePermission(CONTACTS, currentUser);

  // Allow deletion of non-member users only by admin users or users with can_delete permission
  const canDeleteUsers = nonMembersSelected && allowSelection;

  const contacts = useMemo(() => {
    if (!users?.length) return [];
    const filteredContacts = users
      ?.filter((user) => !user.metadata.deletedAt || !user.active)
      ?.map((user) => {
        const editableUser = { ...user };
        const streamUser = usersStreamInfo?.find((su) => su.userId === user.id);
        editableUser.company = {
          ...user?.company,
          label: getCompanyNameFromContact(user, users),
        };
        editableUser.lastUpdated = user.metadata.lastUpdated;
        editableUser.lastActive = streamUser?.lastActive;
        editableUser.isOnline = streamUser?.isOnline;

        return editableUser;
      });
    return filteredContacts;
  }, [users, usersStreamInfo]);

  const companyContacts = useMemo(() => {
    return users?.filter((user) => user.kind === "company");
  }, [users]);

  if (!isEmployeeOrAdmin(currentUser)) {
    navigateBack(CALENDAR_PATH.split("/")[1]);
  }

  useEffect(() => {
    if (managementConfiguration?.management?.contact?.disciplines) {
      setDisciplines(
        managementConfiguration?.management?.contact?.disciplines
          ?.filter((discipline) => discipline.selected)
          ?.map((discipline) => {
            return { label: discipline.display, value: discipline.id };
          })
          .sort(({ label: a }, { label: b }) => a.localeCompare(b))
      );
    }
  }, [managementConfiguration]);

  const tableData = useMemo(() => {
    return getFilteredContacts(
      contacts,
      searchKeyword,
      true,
      tabIndex,
      currentUser?.id
    );
  }, [contacts, currentUser?.id, searchKeyword, tabIndex]);

  const {
    setAllFilters,
    prepareRow,
    rows,
    allColumns,
    state: { filters },
  } = useTable(
    {
      data: tableData,
      columns,
      autoResetFilters: false,
      autoResetGroupBy: false,
    },
    useFilters,
    useGlobalFilter,
    useGroupBy
  );

  useEffect(() => {
    const tableContacts = rows?.map((row) => {
      prepareRow(row);
      return row.original;
    });
    // set filtered contact for list view
    setFilteredContactsList(tableContacts);

    // sort group of contacts by first character
    const sortContacts = getFilteredAndReducedContactsByFirstLetter(
      tableContacts,
      searchKeyword,
      !isShowingTable,
      tabIndex
    );

    // sort contacts within an alphabetical group
    const contactsWithSortedCategories = {};
    Object.keys(sortContacts).forEach((key) => {
      contactsWithSortedCategories[key] = sortContactsAndMembers(
        sortContacts[key].map((item) =>
          item.kind === "company" ? { ...item, label: item.companyName } : item
        )
      );
    });

    const ordered = Object.keys(contactsWithSortedCategories)
      .sort(sortWithSpecialCharacters)
      .reduce((obj, key) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = contactsWithSortedCategories[key];
        return obj;
      }, {});
    setSortedContacts(ordered);
  }, [searchKeyword, tabIndex, isShowingTable, rows, prepareRow]);

  useEffect(() => {
    const contactColumns = contactsViewColumns(disciplines);
    const contactColumnsMapped = contactColumns?.map((currentCol) => {
      switch (currentCol.filterOptions?.filterType) {
        case FILTER_TYPES.isOrNot: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.IS_OR_NOT,
            Filter: IsOrNotFilter,
          };
        }
        case FILTER_TYPES.boolean: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.BOOL,
            Filter: BooleanFilter,
          };
        }
        case FILTER_TYPES.date: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.DATE,
            Filter: DateFilter,
          };
        }
        case FILTER_TYPES.includesExcludes: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.INCLUDES_EXCLUDES,
            Filter: IncludesExcludesFilter,
          };
        }
        default: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.IS_OR_NOT,
            Filter: IsOrNotFilter,
          };
        }
      }
    });
    setColumns(contactColumnsMapped);
  }, [disciplines, searchKeyword]);

  const onImport = useCallback(
    async (data) => {
      const allCompanyContacts = companyContacts;

      for (let i = 0; i < data.rows.length; i += 1) {
        try {
          const contact = data.rows[i];
          switch (contact?.contactType) {
            case "user": {
              await handleUserImport(contact, allCompanyContacts);
              break;
            }
            case "company": {
              await handleCompanyImport(contact, allCompanyContacts);
              break;
            }
            default: {
              // eslint-disable-next-line no-console
              console.log("Not a supporrted user type", contact?.contactType);
            }
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log("Error importing row at index", i, ". Error: ", e);
        }
      }
    },
    [companyContacts]
  );

  const downloadContacts = useCallback(() => {
    const formattedContacts = contacts.map((contact) =>
      formatContactForExport(contact)
    );
    handleDownloadUsers(formattedContacts, "ES Contacts");
  }, [contacts]);

  const isDisabledBuuton = modals?.find(
    (item) => item.modalType === CREATE_CONTACT_MODAL
  );

  const buttonActions = [
    ...(!isDisabledBuuton &&
    (currentUser?.isSuperAdmin ||
      currentUser?.isAdmin ||
      currentUser?.hasPermission?.("contacts", "can_write"))
      ? [
          {
            onClick: () => {
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalData: { contactType: "contact" },
                modalType: CREATE_CONTACT_MODAL,
              });
            },
            title: "Create Contact",
          },
        ]
      : []),
    ...(!isDisabledBuuton &&
    (currentUser?.isSuperAdmin ||
      currentUser?.isAdmin ||
      currentUser?.hasPermission?.("contacts", "can_write"))
      ? [
          {
            onClick: () => {
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalData: { contactType: "company" },
                modalType: CREATE_CONTACT_MODAL,
              });
            },
            title: "Create Company Contact",
          },
        ]
      : []),
    ...(currentUser?.isSuperAdmin ||
    currentUser?.isAdmin ||
    currentUser?.hasPermission?.("contacts", "can_write")
      ? [
          {
            onClick: () => setShowImportModal(true),
            title: "Import",
          },
        ]
      : []),
    {
      onClick: downloadContacts,
      title: "Download",
    },
  ];

  const onSearchChange = (val) => {
    setSearchKeyword(val);
  };

  const handleRowSelect = useCallback((val) => {
    setSelectedRows(val);
  }, []);

  // Remove users finalized in removeUserModal
  const onRemoveUser = useCallback(
    (usersToRemove) => {
      // deleteUsers uses the $bulk endpoint and expects an array of userRefs
      const formattedUsers = [...usersToRemove].map((item) => item.reference);
      deleteUsers([...formattedUsers]);
    },
    [deleteUsers]
  );

  // SelectedUsers is original [], onRemoveUser expects updated list of users to remove
  const handleRemoveUser = () => {
    const id = uuidv4();
    modalDispatch({
      type: ADD_OPEN_MODAL,
      position: {
        centered: true,
      },
      ref: { id },
      modalData: {
        id,
        item: {
          id,
          selectedUsers: selectedRows,
          onSave: onRemoveUser,
        },
      },
      modalType: REMOVE_USER,
    });
  };

  return {
    currentUser,
    sortedContacts,
    tabIndex,
    selectedRows,
    isShowingTable,
    setIsShowingTable,
    isFetching,
    setTabIndex,
    searchKeyword,
    allColumns,
    tableData,
    filters,
    setAllFilters,
    disciplines,
    history,
    showImportModal,
    allowSelection,
    canDeleteUsers,
    setShowImportModal,
    onImport,
    handleRowSelect,
    handleRemoveUser,
    onSearchChange,
    buttonActions,
    filteredContactsList,
  };
};

export default useContactList;

import React, { useEffect, useState } from "react";
import _ from "lodash";
import { WorkflowAPI, CommentAPI } from "@griffingroupglobal/eslib-api";

import { useAppState } from "../state/appState";
import { buildWorkflowView, buildWorkflowTemplate } from "./useWorkflows";
import { SET_WORKFLOWS, SET_COMMENTS } from "../constants";

const getWorkflowData = async (id) => {
  if (!id) {
    return "";
  }

  const { data } = await WorkflowAPI.getById(id);
  return data;
};

const getUserData = (allUsers, reference) => {
  if (!reference) {
    return "";
  }

  const found = allUsers?.find((user) => user.reference === reference);
  return found;
};
/**
 * @deprecated
 */
export default (id) => {
  const [{ workflows, users, comments }, dispatch] = useAppState();
  const [workflowList, setWorkflowList] = useState();
  const [workflowData, setWorkflowData] = useState({});
  const [userData, setUserData] = useState();
  const [commentData, setCommentData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (workflows && !workflowList) {
      setWorkflowList(workflows);
    }
  }, [workflows, workflowList]);

  useEffect(() => {
    if (workflowList && id && workflowData.id !== id) {
      setWorkflowData(workflowList.find((item) => item.id === id));
    }
  }, [workflowData, workflowList, id]);

  useEffect(() => {
    if (users?.length) {
      setUserData(users);
    }
  }, [users, userData, dispatch]);

  useEffect(() => {
    const fetchComments = async () => {
      const { data } = await CommentAPI.get();
      const commentList = data?.entries?.map((entry) => entry.resource) ?? [];
      setCommentData(commentList);
      dispatch({
        type: SET_COMMENTS,
        comments: commentList,
      });
    };

    if (comments) {
      setCommentData(comments);
    } else if (!commentData) {
      fetchComments();
    }
  }, [comments, commentData, dispatch]);

  const reloadWorkflow = React.useCallback(
    async (usrData, cmtData, currentId) => {
      setIsLoading(true);
      const usersData = usrData ?? users;
      const commentsData = cmtData ?? comments;
      const workflow = await getWorkflowData(currentId ?? id);

      let tempObj = workflow;
      if (tempObj.completed) {
        const completedWF = getUserData(usersData, tempObj?.completed?.member);
        tempObj.completed.userData = completedWF;
      }
      tempObj.metadata.userData = getUserData(
        usersData,
        tempObj?.metadata?.createdBy
      );
      const associatedWF =
        tempObj?.associatedWorkflows?.map(async (info) => {
          try {
            const associatedData = await getWorkflowData(
              info.reference?.split("/")[1]
            );
            return {
              ...info,
              workflowData: associatedData,
            };
          } catch (err) {
            return null;
          }
        }) || [];
      tempObj.associatedWorkflows = await Promise.all(associatedWF);
      tempObj.associatedWorkflows = tempObj.associatedWorkflows.filter(
        (wf) => !!wf
      );
      tempObj = await buildWorkflowView(tempObj, usersData, commentsData);
      if (tempObj.isTemplate) {
        tempObj = await buildWorkflowTemplate(tempObj, usersData);
      }

      setWorkflowData(tempObj);
      setIsLoading(false);
      return tempObj;
    },
    [id, comments, users]
  );

  const saveWorkflowData = React.useCallback(
    async (wf) => {
      setIsLoading(true);
      const saveWorkflow = async () => {
        const newWF = await reloadWorkflow();
        const found = workflowList?.find((item) => item.id === newWF.id);
        let finalWF = newWF;

        if (found) {
          finalWF = workflowList.map((item) => {
            if (item.id === found.id) {
              return newWF;
            }
            return item;
          });

          dispatch({
            type: SET_WORKFLOWS,
            workflows: finalWF,
          });
          setWorkflowList(finalWF);
        } else if (!found && workflowList) {
          dispatch({
            type: SET_WORKFLOWS,
            workflows: [newWF, ...finalWF],
          });
          setWorkflowList([newWF, ...finalWF]);
        }

        setWorkflowData(newWF);
        setIsLoading(false);
      };

      if (wf) {
        setWorkflowData(wf);
      }
      return saveWorkflow();
    },
    [workflowList, dispatch, reloadWorkflow]
  );

  useEffect(() => {
    if (!workflowList && users?.length && comments && workflowData?.id !== id) {
      saveWorkflowData();
    } else if (workflowList?.length && workflowData?.id !== id) {
      const found = workflowList.find((item) => item.id === id) ?? {};
      setWorkflowData(found);
      // eslint-disable-next-line no-underscore-dangle
      if (found._id) {
        setIsLoading(false);
      }
    } else if (workflowList?.length && workflowData?.id) {
      const found = workflowList?.find((item) => item.id === workflowData.id);
      let finalWF = workflowData;

      if (found && !_.isEqual(found, workflowData)) {
        finalWF = workflowList.map((item) => {
          if (item.id === found.id) {
            return workflowData;
          }
          return item;
        });

        dispatch({
          type: SET_WORKFLOWS,
          workflows: finalWF,
        });
        setWorkflowList(finalWF);
      }
    }
  }, [
    id,
    workflowData,
    workflowList,
    users,
    comments,
    dispatch,
    saveWorkflowData,
  ]);

  return {
    workflow: workflowData,
    reload: reloadWorkflow,
    isLoading,
    setWorkflow: setWorkflowData,
    saveWorkflow: saveWorkflowData,
  };
};

// formats POST/PATCH payload for ownedBy field in resources using the ownedBy component
const formatOwnedByForm = (resource) => {
  return (
    resource?.ownedBy?.map((user) => {
      if (user.contactType) {
        return {
          contactType: user?.contactType,
          name: user?.name,
        };
      }
      return { reference: user.reference };
    }) || []
  );
};

export default formatOwnedByForm;

import React from "react";
import { Redirect, Route } from "react-router";
import useIsSignedIn from "../hooks/useIsSignedIn";
import { SIGNUP_LANDING_PATH } from "../constants";
import useIsSiteMaintenance from "../hooks/useIsSiteMaintenance";
import SiteMaintenance from "../Pages/Error/SiteMaintenance";
import useGetCurrentTenant from "../hooks/useGetCurrentTenant";

const PrivateRoute = ({ component: Component, needAuth, ...rest }) => {
  const [isSignedIn] = useIsSignedIn();
  const [isSiteMaintenance] = useIsSiteMaintenance();
  const email = localStorage.getItem("email");
  const { data: userTenant } = useGetCurrentTenant(email);

  if (isSiteMaintenance) {
    return <Route {...rest} render={() => <SiteMaintenance />} />;
  }
  // Signed out user trying to go to: MainCointainer page will be redirect to landing sign up
  if (needAuth && !isSignedIn) {
    return <Redirect to={{ pathname: SIGNUP_LANDING_PATH }} />;
  }

  // Signed in user trying to go to: Signup/Signin pages is redirected to MainContainer
  if (
    !needAuth &&
    isSignedIn &&
    userTenant?.legalAccepted &&
    userTenant?.registered
  ) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default PrivateRoute;

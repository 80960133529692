import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  differenceBy as _differenceBy,
  intersectionBy as _intersectionBy,
} from "lodash";
import Dropdown from "../Dropdown/Dropdown";
import FormHierarchy from "../FormHierarchy/FormHierarchy";

const MAX_GROUPS_ALLOWED = 4;

const TableGroupComponent = ({
  stagedGroups,
  setStagedGroups,
  groupOptions,
}) => {
  const [dropdownOptions, setDropdownOptions] = useState([]);

  const onChangeGroup = (f, idx, newValue) => {
    const newGroups = [...stagedGroups];

    if (!newValue?.value) {
      newGroups.splice(idx, 1);
    } else {
      newGroups[idx] = newValue;
    }

    setStagedGroups(newGroups);
  };

  const onAddGroup = (newValue) => {
    setStagedGroups((prev) => [...prev, newValue]);
  };

  // Applying Rules for grouping
  // Read Documentation:  https://griffingroupglobal.atlassian.net/l/c/yeqmtgva
  useEffect(() => {
    let filteredOptions = [];
    const { nonHierarchicalOptions, hierarchicalOptions } = groupOptions;

    if (stagedGroups?.length) {
      const hasHierarchicalItems = stagedGroups.some((group) => {
        const found = hierarchicalOptions
          .flat()
          .find((ho) => ho.value === group.value);
        return !!found;
      });
      // check if no hierarchical stagedGroups are selected
      if (!hasHierarchicalItems) {
        // find the remaining non-hierarchical options
        filteredOptions = _differenceBy(
          nonHierarchicalOptions,
          stagedGroups,
          "value"
        );
        // show remaining non-hierarchical options AND all hierarchical Options
        filteredOptions = [...filteredOptions, ...hierarchicalOptions.flat()];
      } else {
        // if hierarchical options were selected

        /* toFilter Object format:
         * {
           index_Of_hirarchicalOption: index_of_lowest_Item_in_that_hierarchical_set
         }
         */
        const toFilter = {};
        // find the indexes of the lowest item in every hierarchical set that is already selected in the grouping
        // this is done so we can eleminate all the higher items in the options from each hirarchical set
        hierarchicalOptions.forEach((ho, hoIndex) => {
          ho.map((item, itemIndex) => {
            const foundIndex = stagedGroups.findIndex(
              (group) => group.value === item.value
            );
            if (foundIndex !== -1) {
              const maxIndex = Object.hasOwnProperty.call(toFilter, hoIndex)
                ? Math.max(toFilter[hoIndex], itemIndex)
                : itemIndex;
              toFilter[hoIndex] = maxIndex;
            }
            return item;
          });
        });
        // copy hierarchicalOptions so we dont change original dobject
        const options = [...hierarchicalOptions];
        // keys correspond to indexes of hirarchical sets in hierarchicalOptions array
        const keys = Object.keys(toFilter);
        // eleminate all the higher items in the options from each hirarchical set
        keys.forEach((key) => {
          options[key] = options[key].slice(toFilter[key] + 1);
        });
        // holds only valid hairarchical options
        const validHierarchicalOptions = options.flat();
        // find selected NonHierarchal Options
        const selectedNonHierarchalOptions = _intersectionBy(
          stagedGroups,
          nonHierarchicalOptions,
          "value"
        );
        // find non selected NonHierarchal Options
        const nonSelectedNonHierarchalOptions = _differenceBy(
          nonHierarchicalOptions,
          selectedNonHierarchalOptions,
          "value"
        );
        // show valid Hierarchical Options AND non selected NonHierarchal Options
        filteredOptions = [
          ...validHierarchicalOptions,
          ...nonSelectedNonHierarchalOptions,
        ];
      }
    } else {
      // if no grouping is selected, show all options
      filteredOptions = [
        ...hierarchicalOptions,
        ...nonHierarchicalOptions,
      ].flat();
    }

    setDropdownOptions(filteredOptions);
  }, [groupOptions, stagedGroups]);

  const getGroupRow = (g, idx) => {
    return (
      <div className="flex w-full" key={idx}>
        <Dropdown
          placeholder="Choose"
          options={dropdownOptions}
          onChange={(newValue) => onChangeGroup(g, idx, newValue)}
          value={g}
        />
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-col pb-2">
        <div className="flex">
          <div className="font-semibold text-black text-sm pb-2">
            <p>Group Rows by</p>
          </div>
        </div>
        <div className="flex flex-col">
          <FormHierarchy
            className="w-full"
            showOnLeft
            showHangingLine={
              stagedGroups.length < MAX_GROUPS_ALLOWED &&
              !!dropdownOptions.length
            }
          >
            {stagedGroups?.map((g, index) => getGroupRow(g, index))}
          </FormHierarchy>
          {stagedGroups.length < MAX_GROUPS_ALLOWED ? (
            <div className="flex w-full">
              {!!dropdownOptions.length && (
                <Dropdown
                  maxMenuHeight={150}
                  menuPlacement="auto"
                  placeholder="Choose"
                  options={dropdownOptions}
                  onChange={(newValue) => onAddGroup(newValue)}
                />
              )}
            </div>
          ) : (
            <p className="text-gray-300 pl-10 text-sm">
              Maximum of four stagedGroups
            </p>
          )}
        </div>
      </div>
    </>
  );
};

TableGroupComponent.propTypes = {
  groupOptions: PropTypes.shape({
    hierarchicalOptions: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        })
      )
    ),
    nonHierarchicalOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  stagedGroups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  setStagedGroups: PropTypes.func,
};

TableGroupComponent.defaultProps = {
  groupOptions: {
    hierarchicalOptions: [{ label: "", value: "" }],
    nonHierarchicalOptions: [{ label: "", value: "" }],
  },
  stagedGroups: [],
  setStagedGroups: undefined,
};

export default TableGroupComponent;

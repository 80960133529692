import cntl from "cntl";
import React, { Children } from "react";
import "../../../index.css";
import PropTypes from "prop-types";

const subContainerCN = (className, last) => cntl`
${className}
flex
flex-col
h-full
w-full
${!last ? "rightDivider" : ""}
rounded-sm
items-center
text-gray-200
px-4
pt-3
pb-1
relative
`;

const gridCN = (rowCount) => cntl`
h-full
w-full
grid 
text-gray-400
${`grid-rows-${rowCount ?? 3}`}
`;

const WidgetRowGrid = ({ className, title, children, id, last }) => {
  return (
    <div className={subContainerCN(className, last)} id={id}>
      {title && <p className="mb-2 capitalize">{title}</p>}
      <div className={gridCN(Children.count(children))}>
        {Array.isArray(children) ? (
          children?.map((Child, i) => (
            <div
              key={Child?.props?.title}
              className={
                i !== children?.length - 1
                  ? "flex h-full items-center border-b border-gray-100 py-4"
                  : "flex h-full items-center py-4"
              }
            >
              {Child}
            </div>
          ))
        ) : (
          <div>{children}</div>
        )}
      </div>
    </div>
  );
};

WidgetRowGrid.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
  id: PropTypes.string,
  last: PropTypes.bool,
};

WidgetRowGrid.defaultProps = {
  className: undefined,
  title: undefined,
  children: undefined,
  id: undefined,
  last: false,
};

export default WidgetRowGrid;

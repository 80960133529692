const getParentAssociationTimezone = (
  reference,
  propertiesDict,
  projectDict
) => {
  let parentTimezone;

  if (reference) {
    const resource = reference.split("/")[0];
    if (resource === "Property") {
      const propertyTZ = propertiesDict[reference]?.timezone;
      if (propertyTZ) {
        parentTimezone = propertyTZ;
      }
    } else if (resource === "Project") {
      const projectTZ = projectDict[reference]?.timezone;
      if (projectTZ) {
        parentTimezone = projectTZ;
      }
    }
  }
  return parentTimezone;
};

export default getParentAssociationTimezone;

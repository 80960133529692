import cntl from "cntl";
import PropTypes from "prop-types";
import React from "react";
import "./styles.css";

const buttonCN = (className) => cntl`
  w-5
  h-5
  cursor-pointer
  ${className}
`;

const RadioButton = ({
  value,
  label,
  isChecked,
  onChange,
  className,
  labelClassName,
  disabled,
}) => {
  return (
    <div className="flex items-center">
      <input
        disabled={disabled}
        className={buttonCN(className)}
        type="radio"
        value={value}
        checked={isChecked}
        onChange={(newValue) => onChange(newValue)}
      />

      {label && (
        <div>
          <p className={`ml-2 mr-6 text-gray-200 ${labelClassName}`}>{label}</p>
        </div>
      )}
    </div>
  );
};

RadioButton.propTypes = {
  /**
   * value that the button stores/returns
   */
  value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  /**
   * display value next to button
   */
  label: PropTypes.string,
  /**
   * boolean to show is button is checked or not
   */
  isChecked: PropTypes.bool,
  /**
   * funtion called button value is changed
   */
  onChange: PropTypes.func,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

RadioButton.defaultProps = {
  disabled: false,
  value: undefined,
  label: undefined,
  isChecked: false,
  onChange: undefined,
  className: undefined,
  labelClassName: undefined,
};

export default RadioButton;

import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";

const modalStyles = {
  content: {
    inset: 0,
    width: "100%",
    height: "100%",
  },
  overlay: {
    zIndex: "50",
  },
};

const WorkflowDocViewModal = ({ content, isOpen, onRequestModalClose }) => {
  return (
    <ReactModal
      style={modalStyles}
      isOpen={isOpen}
      onRequestClose={onRequestModalClose}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
    >
      {content}
    </ReactModal>
  );
};

WorkflowDocViewModal.propTypes = {
  /**
   * content to render in the modal
   */
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.object,
  /**
   * modal is open state
   */
  isOpen: PropTypes.bool,
  /**
   * run on cancel button click
   */
  onRequestModalClose: PropTypes.func,
};

WorkflowDocViewModal.defaultProps = {
  content: null,
  isOpen: false,
  onRequestModalClose: undefined,
};

export default WorkflowDocViewModal;

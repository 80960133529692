import React from "react";
import attachmentIcon from "../../../assets/images/attachment_icon_updated.svg";
import useAttachmentItemData from "./useAttachmentItemData";

const AttachmentItem = ({
  fileRef,
  fileObject,
  isEditing,
  wrapperCN,
  textCN,
}) => {
  const { handleOpenFile, fileName } = useAttachmentItemData({
    fileRef,
    fileObject,
    isEditing,
  });

  return (
    <div className={wrapperCN}>
      <button type="button" onClick={handleOpenFile}>
        <div className="flex gap-x-1">
          <img
            src={attachmentIcon}
            className="w-5 h-5 inline"
            alt="attachment-icon"
          />
          <p className={`text-left break-all text-gray-500 ${textCN ?? ""}`}>
            {fileName}
          </p>
        </div>
      </button>
    </div>
  );
};

export default AttachmentItem;

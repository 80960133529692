import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useCurrentUser from "../../../hooks/useCurrentUser";
import PureCommentWFSection from "./PureCommentWFSection";

const CommentSectionWF = ({
  attachIcon,
  onAttachIconClick,
  isMaintenanceComment,
  hideCommentsTag,
  showNewCommentSection,
  setShowNewCommentSection,
  data,
  handlePostComment,
  handlePostReply,
  allowNewComment,
  showClose,
  handleClose,
}) => {
  const { data: currentUser } = useCurrentUser();
  const [commentData, setCommentData] = useState(data);
  useEffect(() => {
    setCommentData(data);
  }, [data]);
  return (
    <PureCommentWFSection
      attachIcon={attachIcon}
      onAttachIconClick={onAttachIconClick}
      isMaintenanceComment={isMaintenanceComment}
      currentUser={currentUser}
      commentData={commentData}
      handlePostComment={handlePostComment}
      handlePostReply={handlePostReply}
      allowNewComment={allowNewComment}
      showClose={showClose}
      hideCommentsTag={hideCommentsTag}
      showNewCommentSection={showNewCommentSection}
      setShowNewCommentSection={setShowNewCommentSection}
      handleClose={handleClose}
    />
  );
};
CommentSectionWF.propTypes = {
  /**
   * data displayed in the comments
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string,
      content: PropTypes.string,
      id: PropTypes.string,
      metadata: PropTypes.shape({
        createdAt: PropTypes.string,
      }),
      replies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          metadata: PropTypes.shape({
            createdAt: PropTypes.string,
          }),
          userData: PropTypes.shape({
            name: PropTypes.shape({
              firstName: PropTypes.string,
              lastName: PropTypes.string,
            }),
          }),
        })
      ),
      resource: PropTypes.string,
      userData: PropTypes.shape({
        name: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      }),
    })
  ),
  /**
   * function called when a comment is posted
   */
  handlePostComment: PropTypes.func,
  /**
   * function called when a reply is posted
   */
  handlePostReply: PropTypes.func,
  /**
   * boolean to allow users to add comments or replies
   */
  allowNewComment: PropTypes.bool,
  showClose: PropTypes.bool,
  handleClose: PropTypes.func,
  attachIcon: PropTypes.string,
  onAttachIconClick: PropTypes.func,
  isMaintenanceComment: PropTypes.bool,
  hideCommentsTag: PropTypes.bool,
  showNewCommentSection: PropTypes.bool,
  setShowNewCommentSection: PropTypes.func,
};

CommentSectionWF.defaultProps = {
  data: [],
  handlePostComment: undefined,
  handlePostReply: undefined,
  allowNewComment: undefined,
  showClose: undefined,
  handleClose: undefined,
  attachIcon: undefined,
  onAttachIconClick: undefined,
  isMaintenanceComment: undefined,
  hideCommentsTag: undefined,
  showNewCommentSection: undefined,
  setShowNewCommentSection: undefined,
};
export default CommentSectionWF;

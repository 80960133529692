/* eslint-disable no-param-reassign */
import { MaintenanceAPI } from "@griffingroupglobal/eslib-api";
import { uniqBy } from "lodash";
import { useCallback, useEffect } from "react";
import { SET_MAINTENANCE_TASKS } from "../constants";
import { getFullName } from "../helpers/Formatters";
import { getFullRrule } from "../helpers/Time";
import { getAssociationNameFromReference } from "../helpers/Utilities";
import { useAppState } from "../state/appState";

export default () => {
  const [
    { maintenanceTasks, userDict, propertiesDict, projectDict, assetsDict },
    dispatch,
  ] = useAppState([]);

  const reload = useCallback(async () => {
    MaintenanceAPI.get()
      .then(({ data }) => {
        dispatch({
          type: SET_MAINTENANCE_TASKS,
          maintenanceTasks: uniqBy(
            data?.entries
              .filter(({ resource }) => resource?.isMaintenance)
              .map(({ resource }) => {
                const inviteesFound = resource?.invitees
                  ?.map((r) => getFullName(userDict[r]?.name))
                  ?.join(", ");

                return {
                  ...resource,
                  assignedTo: inviteesFound,
                  lastCompleted:
                    resource?.status === "done" && resource?.endDate,
                  associationName: getAssociationNameFromReference(
                    resource?.association,
                    propertiesDict,
                    projectDict,
                    assetsDict
                  ),
                  recurrenceRule: resource?.recurrence,
                  recurrence: getFullRrule(resource?.recurrence)?.label,
                  tableDueDate: resource?.recurrence
                    ? resource?.instanceStartDate
                    : resource?.startDate,
                };
              }),
            "id"
          ),
        });
      })
      .catch((err) => {
        console.warn(err);
      });
  }, [assetsDict, dispatch, projectDict, propertiesDict, userDict]);

  useEffect(() => {
    reload();
  }, [reload]);

  return [maintenanceTasks, reload];
};

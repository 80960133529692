import React, { useState } from "react";
import TertiaryButton from "../../Buttons/TertiaryButton";
import Dropdown from "../../Dropdown/Dropdown";
import RFIRequestWorkflowCreateCard from "./RFIRequestWorkflowCreateCard";

const RFIRequestWorkflow = ({
  workflowIndex,
  workflow,
  dispatch,
  rfiForm,
  memberOptions,
  templatesDict,
  templatesOptions,
  defaultAssociationTemplate,
  setIsTemplateModalOpen,
}) => {
  const [currentTemplate, setCurrentTemplate] = useState({
    label: defaultAssociationTemplate?.name,
    value: defaultAssociationTemplate?.id,
  });
  const handleGenerateEmptyStep = (num) => {
    const emptyStepObj = {
      position: num,
      description: "",
      duration: {
        projected: 0,
      },
      users: [],
      attachments: [],
    };
    return emptyStepObj;
  };

  const handleAddNewWorkflowStep = () => {
    const allRequestWorkFlows = [...rfiForm?.requestWorkflow];
    let requestWorkflow = { ...allRequestWorkFlows[workflowIndex] };
    const workflowSteps = [...requestWorkflow?.steps];
    const emptyStep = handleGenerateEmptyStep(workflowSteps?.length + 1);
    requestWorkflow = {
      ...requestWorkflow,
      steps: [...workflowSteps, emptyStep],
    };

    allRequestWorkFlows.splice(workflowIndex, 1, requestWorkflow);

    dispatch({
      type: "requestWorkflow",
      value: allRequestWorkFlows,
    });
  };

  const handleStepChange = (stepIndex, updatedStep) => {
    const allRequestWorkFlows = rfiForm?.requestWorkflow;
    let requestWorkflow = { ...allRequestWorkFlows[workflowIndex] };
    const allSteps = [...requestWorkflow?.steps];
    allSteps.splice(stepIndex, 1, updatedStep);

    requestWorkflow = { ...requestWorkflow, steps: allSteps };

    allRequestWorkFlows.splice(workflowIndex, 1, requestWorkflow);

    dispatch({
      type: "requestWorkflow",
      value: allRequestWorkFlows,
    });
  };

  const handleStepDelete = (stepIndex) => {
    const allRequestWorkFlows = rfiForm?.requestWorkflow;
    const requestWorkflow = { ...allRequestWorkFlows[workflowIndex] };
    const allSteps = [...requestWorkflow?.steps];
    allSteps.splice(stepIndex, 1);
    if (allSteps.length > 0 && allSteps[0].users?.[0]) {
      allSteps[0].users[0].role = "submitter";
    }

    allRequestWorkFlows.splice(workflowIndex, 1, {
      ...requestWorkflow,
      steps: allSteps,
    });

    dispatch({
      type: "requestWorkflow",
      value: allRequestWorkFlows,
    });
  };

  const handleTemplateChange = (val) => {
    if (val.value === "createNew") {
      setIsTemplateModalOpen(true);
      return;
    }
    const templateWorkflow = templatesDict[val.value][0];
    const allRequestWorkflow = rfiForm?.requestWorkflow;
    allRequestWorkflow.splice(workflowIndex, 1, templateWorkflow);
    dispatch({
      type: "requestWorkflow",
      value: allRequestWorkflow,
    });
    setCurrentTemplate(val);
  };
  return (
    <>
      <div className="w-full border-t mt-4">
        <div className="flex w-1/2 my-4">
          <Dropdown
            label="RFI Workflow"
            options={templatesOptions}
            onChange={(val) => handleTemplateChange(val)}
            value={templatesOptions.find(
              (t) => t.value === currentTemplate?.value
            )}
          />
        </div>
        {workflow?.steps?.map((step, stepIndex) => {
          return (
            <RFIRequestWorkflowCreateCard
              key={`${step?.position}-${step?.type}-${step?.name}`}
              index={stepIndex}
              members={memberOptions}
              dispatch={dispatch}
              step={step}
              handleStepChange={(updatedStep) =>
                handleStepChange(stepIndex, updatedStep)
              }
              handleStepDelete={() => handleStepDelete(stepIndex)}
            />
          );
        })}
      </div>
      <TertiaryButton
        className="w-full border-dashed border-gray-150 border-2 h-16 rounded-md mb-12 text-lg font-semibold"
        title="+ Add New Step"
        onClick={() => handleAddNewWorkflowStep()}
        noMaxWidth
      />
    </>
  );
};

export default RFIRequestWorkflow;

import React from "react";
import cntl from "cntl";
import moment from "moment";
import DatePicker from "../DatePicker/DatePicker";
import Input from "../Input/Input";
import { formatPhoneNumber } from "../../../helpers/Formatters";
import Dropdown from "../Dropdown/Dropdown";
import { firstNameValidation } from "../../../helpers/FormValidations";

const container = "flex flex-col items-center w-full gap-8";
const headerTextCN =
  "text-black text-es-lg font-es-semibold tracking-es-wide flex flex-row w-full";

const rowCN = (showBorder = true) => cntl`
flex 
flex-row 
items-center 
justify-between
${showBorder && " border-b border-es-light-grey pb-4"}
`;
const labelCN =
  "text-es-medium-grey font-es-bold text-es-normal tracking-es-wide";
const rowContainer = "grid grid-cols-2 gap-8";
const inputWidth = { minWidth: "400px", height: "42px" };
const inputCN = "flex flex-row items-center gap-3";
const textCN =
  "text-medium-grey font-es-normal text-es-normal tracking-es-wide text-left justify-start";

const MyProfileDetails = ({
  isEditing,
  editableUser,
  changeName,
  userPhoneNumber,
  phoneNumberValidation,
  changePhoneNumber,
  userEmail,
  changeEmail,
  changeDate,
  userDate,
  userSocial,
  changeSocial,
  setChangePasswordModalOpen,
  changePronunciation,
  changeTimezone,
  timezoneOptions,
}) => {
  return (
    <div className={container} style={{ minWidth: "1268px" }}>
      {/* profile picture drag n drop container
        <ProfileDropZone contact={editableUser} onChange={changeProfileImage} /> */}

      <div className="flex flex-col w-full p-8 border rounded-lg gap-8">
        <p className={headerTextCN}>Personal Profile</p>
        <div className={rowContainer}>
          {/* Row */}
          <div className={rowCN()}>
            <p className={labelCN}>First Name</p>
            <div style={inputWidth}>
              {isEditing ? (
                <Input
                  placeholder="First Name"
                  value={editableUser?.name?.firstName}
                  onChange={(val) => changeName(val, "firstName")}
                  validation={firstNameValidation} // string.trim.length >= 3; required
                  showValidationErrorAtBottom
                />
              ) : (
                <div className="flex h-full items-center">
                  <p className={textCN}>{editableUser?.name?.firstName}</p>
                </div>
              )}
            </div>
          </div>

          <div className={rowCN()}>
            <p className={labelCN}>Last Name</p>
            <div style={inputWidth}>
              {isEditing ? (
                <Input
                  placeholder="Last Name"
                  value={editableUser?.name?.lastName}
                  onChange={(val) => changeName(val, "lastName")}
                />
              ) : (
                <div className="flex h-full items-center">
                  <p className={textCN}>{editableUser?.name?.lastName}</p>
                </div>
              )}
            </div>
          </div>

          {isEditing && (
            <div className={rowCN()}>
              <p className={labelCN}>Pronunciation</p>
              <div style={inputWidth}>
                <Input
                  placeholder="Pronunciation"
                  onChange={(val) => changePronunciation(val)}
                  value={editableUser?.pronunciation}
                />
              </div>
            </div>
          )}

          {!!editableUser?.pronunciation && !isEditing && (
            <div className={rowCN()}>
              <p className={labelCN}>Pronunciation</p>
              <div style={inputWidth}>
                <div className="flex h-full items-center">
                  <p className={textCN}>{editableUser?.pronunciation}</p>
                </div>
              </div>
            </div>
          )}

          {/* Row */}
          {isEditing && (
            <div className={rowCN()}>
              <p className={labelCN}>Email</p>
              <div style={inputWidth}>
                <Input
                  placeholder="Email"
                  value={userEmail}
                  onChange={(val) => changeEmail(val)}
                />
              </div>
            </div>
          )}

          {!!userEmail && !isEditing && (
            <div className={rowCN()}>
              <p className={labelCN}>Email</p>
              <div style={inputWidth}>
                <div className="flex h-full items-center">
                  <p className={textCN}>{userEmail}</p>
                </div>
              </div>
            </div>
          )}

          {/* Row */}
          {isEditing && (
            <div className={rowCN(false)}>
              <p className={labelCN}>Phone Number</p>
              <div style={inputWidth}>
                <Input
                  placeholder="Mobile Phone"
                  value={formatPhoneNumber(userPhoneNumber)}
                  onChange={(val) => changePhoneNumber(val)}
                  validation={phoneNumberValidation}
                />
              </div>
            </div>
          )}

          {!!userPhoneNumber && !isEditing && (
            <div className={rowCN(false)}>
              <p className={labelCN}>Phone Number</p>
              <div style={inputWidth}>
                <div className="flex h-full items-center">
                  <p className={textCN}>{formatPhoneNumber(userPhoneNumber)}</p>
                </div>
              </div>
            </div>
          )}
          {/* TODO: BE Support */}
          {isEditing && (
            <div className={rowCN(false)}>
              <p className={labelCN}>Time Zone</p>
              <div style={inputWidth}>
                <Dropdown
                  options={timezoneOptions}
                  value={editableUser?.timezone}
                  onChange={(val) => changeTimezone(val?.label)}
                  name="user-timezone"
                  placeholder={editableUser?.timezone ?? "Select"}
                />
              </div>
            </div>
          )}
          {!!editableUser?.timezone && !isEditing && (
            <div className={rowCN(false)}>
              <p className={labelCN}>Time Zone</p>
              <div style={inputWidth}>
                <p className={textCN}>{editableUser?.timezone}</p>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col w-full p-8 border rounded-lg gap-8">
        <p className={headerTextCN}>About Me</p>

        <div className={rowContainer}>
          {/* Row */}
          {isEditing && (
            <div className={rowCN(!!userSocial)}>
              <p className={labelCN}>Start Date</p>
              <div className={inputCN} style={inputWidth}>
                <DatePicker
                  placeholder="Select Start Date"
                  onChange={(val) => changeDate(val, "Start Date")}
                  className="flex-1 h-fit"
                  value={userDate("Start Date")}
                  popperPlacement="right"
                />
              </div>
            </div>
          )}
          {userDate("Start Date") && !isEditing && (
            <div className={rowCN(!!userSocial)}>
              <p className={labelCN}>Start Date</p>
              <div className={inputCN} style={inputWidth}>
                <div className="flex h-full items-center">
                  <p className={textCN}>
                    {moment(userDate("Start Date")).format("MMMM DD, YYYY")}
                  </p>
                </div>
              </div>
            </div>
          )}
          {isEditing && (
            <div className={rowCN(!!userSocial)}>
              <p className={labelCN}>Birthday</p>
              <div className={inputCN} style={inputWidth}>
                <DatePicker
                  placeholder="Select your Birthday"
                  onChange={(val) => changeDate(val, "Birthday")}
                  value={userDate("Birthday")}
                  className="flex-1"
                  popperPlacement="right"
                />
              </div>
            </div>
          )}
          {userDate("Birthday") && !isEditing && (
            <div className={rowCN(!!userSocial)}>
              <p className={labelCN}>Birthday</p>
              <div className={inputCN} style={inputWidth}>
                <div className="flex h-full items-center">
                  <p className={textCN}>
                    {moment(userDate("Birthday")).format("MMMM DD, YYYY")}
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* Row */}
          {isEditing && (
            <div className={rowCN(false)}>
              <p className={labelCN}>LinkedIn</p>
              <div className={inputCN} style={inputWidth}>
                <Input
                  placeholder="LinkedIn URL"
                  mainWrapperClassName="flex-1"
                  value={userSocial}
                  onChange={(val) => changeSocial(val)}
                />
              </div>
            </div>
          )}

          {!!userSocial && !isEditing && (
            <div className={rowCN(false)}>
              <p className={labelCN}>LinkedIn</p>
              <div className={inputCN} style={inputWidth}>
                <div className="flex h-full items-center">
                  <p className={textCN}>{userSocial}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col w-full p-8 border rounded-lg gap-8">
        <p className={headerTextCN}>Other Tools</p>
        <div className="flex flex-row gap-3">
          <button
            onClick={() => setChangePasswordModalOpen(true)}
            className="p-2 flex flex-row items-center border-2 border-brandGreen hover:shadow-light-lift rounded-lg bg-white justify-center"
            title="Password Management"
            aria-label="Password Management"
            type="button"
            style={{ width: "254px", height: "44px" }}
          >
            <p className="text-black text-es-normal font-es-semibold">
              Password Management
            </p>
          </button>
          {/* TODO Ticket doesn't show what this button does */}
          <button
            onClick={() => {}}
            className="p-2 flex flex-row items-center border-2 border-text-es-medium-grey hover:shadow-light-lift rounded-lg bg-white justify-center"
            title="Password Management"
            aria-label="Password Management"
            type="button"
            style={{ width: "225px", height: "44px" }}
            disabled
          >
            <p className="text-es-medium-grey text-es-normal font-es-semibold">
              Status Management
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyProfileDetails;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Input from "../Input/Input";
import {
  reformatCurrencyString,
  activeCurrencyFormatter,
} from "../../../helpers/Formatters";
import {
  PURCHASE_AUTHORIZATION,
  UPDATE_BUDGET_LINE,
  UPDATE_FORM_DATA,
} from "../../../constants";

const BudgetLineInput = ({
  lineItem,
  itemKey,
  dispatch,
  maxValue,
  disableZero,
  disableNegative,
  minValue,
}) => {
  const [maxValueError, setMaxValueError] = useState(false);
  const [minValueError, setMinValueError] = useState(false);
  const [inputValue, setInputValue] = useState(
    activeCurrencyFormatter(lineItem[itemKey])
  );

  useEffect(() => {
    let hasValueError = true;
    const inputNumber = inputValue
      ? Number(`${inputValue}`.replaceAll(",", ""))
      : 0;

    if (!Number.isNaN(Number(minValue))) {
      const minNumber = Number(`${minValue}`.replaceAll(",", ""));
      if (!Number.isNaN(minNumber)) {
        hasValueError = inputNumber < minNumber;
      }
    } else {
      hasValueError = false;
    }

    setMinValueError(hasValueError);
  }, [inputValue, minValue]);

  useEffect(() => {
    let hasValueError = true;
    const inputNumber = inputValue
      ? Number(`${inputValue}`.replaceAll(",", ""))
      : 0;

    if (!Number.isNaN(Number(maxValue))) {
      const maxNumber = Number(`${maxValue}`.replaceAll(",", ""));
      if (!Number.isNaN(maxNumber)) {
        hasValueError = inputNumber > maxNumber;
        if (disableNegative) {
          hasValueError = hasValueError || inputNumber < 0;
        }
        if (disableZero) {
          hasValueError = hasValueError || inputNumber < 1;
        }
      }
    } else if (disableNegative && disableZero) {
      hasValueError = inputNumber < 1 || inputNumber < 0;
    } else if (disableNegative) {
      hasValueError = inputNumber < 0;
    } else if (disableZero) {
      hasValueError = inputNumber < 1;
    } else {
      hasValueError = false;
    }

    setMaxValueError(hasValueError);
  }, [maxValue, inputValue, disableNegative, disableZero]);

  useEffect(() => {
    setInputValue(reformatCurrencyString(lineItem[itemKey]));
  }, [lineItem, itemKey]);

  const handleChange = (val) => {
    const sign = val[0] === "-" ? "-" : "";
    // eslint-disable-next-line no-useless-escape
    const newVal = val.replace(/[^0-9\.]+/g, "");
    setInputValue(
      activeCurrencyFormatter(disableNegative ? newVal : sign + newVal)
    );
  };

  const handleDispatch = () => {
    const newLineItem = { ...lineItem };
    const originalAmount = reformatCurrencyString(inputValue);
    const newAmount = originalAmount
      ? Number(originalAmount.replaceAll(",", ""))
      : null;

    newLineItem[itemKey] = newAmount;
    setInputValue(newAmount);

    dispatch({
      type: UPDATE_BUDGET_LINE,
      key: newLineItem.id,
      value: { [itemKey]: newAmount },
    });
    dispatch({
      type: UPDATE_BUDGET_LINE,
      key: newLineItem.id,
      value: { hasInputError: minValueError || maxValueError },
    });
    dispatch({
      type: UPDATE_FORM_DATA,
      key: PURCHASE_AUTHORIZATION,
      value: {
        [newLineItem.id]: {
          splitType: undefined,
          splitAmount: 0,
          coAssignment: 0,
          caAssignment: 0,
        },
      },
    });
  };

  return (
    <div className="flex items-center" style={{ top: "16px", right: "10px" }}>
      <Input
        placeholder="Value"
        className="flex-2"
        value={inputValue ?? 0}
        onChange={handleChange}
        onBlur={handleDispatch}
        errorBorder={maxValueError || minValueError}
        isDollarValue
        disableClear
      />
    </div>
  );
};

BudgetLineInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lineItem: PropTypes.object,
  itemKey: PropTypes.string,
  dispatch: PropTypes.func,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  disableZero: PropTypes.bool,
  disableNegative: PropTypes.bool,
};

BudgetLineInput.defaultProps = {
  lineItem: {},
  itemKey: undefined,
  dispatch: () => {},
  maxValue: undefined,
  minValue: undefined,
  disableZero: false,
  disableNegative: false,
};

export default BudgetLineInput;

import React, { useEffect, useMemo, useState } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import { isEqual } from "lodash";

import Modal from "../Modal/Modal";
import { getInitials } from "../../../helpers/User";
import SelectOptionsWithUpdates from "../SelectUserInterface/SelectOptionsWithUpdates";
import { useAppState } from "../../../state/appState";
import { getFullName } from "../../../helpers/Formatters";
import { useGetFile } from "../../../hooks/useGetFile";

const avatarCN = cntl`
  bg-brandGreen
  rounded-full
  w-20
  h-20
  flex
  items-center
  justify-center
  mr-6
`;

const headerCN = cntl`
  text-lg
  font-semibold
`;

const ChangeDirectReportsModal = ({
  user,
  onCancel,
  onSave,
  isSaving,
  directReportOptions,
}) => {
  const { data } = useGetFile(user?.avatar);

  const [{ userDict, rolesDict }] = useAppState();

  // Transform current direct reports into an array of objects in the form of { value:"User/Reference", label: "User Name" }
  const originalDirectReport = useMemo(() => {
    return Object.values(user?.directReport)
      ?.flatMap((direct) => direct)
      ?.map((direct) => {
        const isUser = direct?.startsWith("User/");

        return {
          label: isUser
            ? getFullName(userDict?.[direct]?.name)
            : rolesDict?.[direct]?.name,

          value: isUser
            ? userDict?.[direct]?.reference
            : rolesDict?.[direct]?.reference,
        };
      });
  }, [userDict, rolesDict, user?.directReport]);

  // State to handle updated direct reports added/removed
  const [selectedValues, setSelectedValues] = useState(originalDirectReport);

  useEffect(() => {
    setSelectedValues(originalDirectReport);
  }, [originalDirectReport]);

  /**
   * Transform `selectedValue` into the data structure required by backend and save changes
   */
  const handleSaveDirectReports = () => {
    const newDirectReport = { user: [], role: [] };

    if (selectedValues?.length > 0) {
      selectedValues?.forEach((dr) => {
        if (dr?.value?.startsWith("User/"))
          newDirectReport.user.push(dr?.value);
        else if (dr?.value?.startsWith("Role/"))
          newDirectReport.role.push(dr?.value);
      });
    }

    onSave(user?.id, newDirectReport);
  };

  const disableSave = isSaving || isEqual(originalDirectReport, selectedValues);

  return (
    <Modal
      title="CHANGE DIRECT REPORTS"
      isOpen
      onRequestModalClose={onCancel}
      hideFooter
      primaryButtonTitle="Save"
      primaryButtonOnClick={handleSaveDirectReports}
      tertiaryButtonTitle="Cancel"
      disabled={disableSave}
    >
      <div className="flex flex-col w-192 h-80">
        <div className="flex flex-row">
          {(data?.thumbnailUrl && (
            <div className="bg-gray-200 flex justify-center items-center w-20 h-20 rounded-full mr-6 relative">
              <img
                src={data?.thumbnailUrl}
                className="w-full h-full rounded-full"
                alt="userAvatarIcon"
              />
            </div>
          )) || (
            <div className={avatarCN}>
              <p className="font-semibold uppercase text-white text-4xl">
                {getInitials(user?.name)}
              </p>
            </div>
          )}
          <div className="px-2 pt-3">
            <h2 className={headerCN}>
              {user?.name?.firstName} {user?.name?.lastName}
            </h2>
            <h2 className="text-sm text-gray-300">{user?.company}</h2>
          </div>
        </div>
        <SelectOptionsWithUpdates
          dropDownLabel="Select Direct Report"
          dropDownClassName="mt-4"
          options={directReportOptions ?? []}
          setSelectedValues={setSelectedValues}
          selectedValues={selectedValues}
        />
      </div>
    </Modal>
  );
};

ChangeDirectReportsModal.propTypes = {
  onCancel: PropTypes.func,
  user: PropTypes.shape({
    directReport: PropTypes.arrayOf(
      PropTypes.shape({
        roles: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    employeeInfo: PropTypes.shape({
      pto: PropTypes.shape({
        locationId: PropTypes.string,
      }),
    }),
    avatar: PropTypes.shape({
      thumbnailUrl: PropTypes.string,
    }),
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    id: PropTypes.string,
    company: PropTypes.string,
    isEmployee: PropTypes.bool,
  }),
  onSave: PropTypes.func,
  isSaving: PropTypes.bool,
  directReportOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

ChangeDirectReportsModal.defaultProps = {
  onCancel: undefined,
  user: undefined,
  onSave: undefined,
  isSaving: false,
  directReportOptions: undefined,
};

export default ChangeDirectReportsModal;

import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import cntl from "cntl";
import moment from "moment";
import * as yup from "yup";
import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";
import DatePicker from "../DatePicker/DatePicker";
import SecondaryButton from "../Buttons/SecondaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";
import SelectUserInterface from "../SelectUserInterface/SelectUserInterface";
import { formatSelectUser, getFullName } from "../../../helpers/Formatters";
import eventIcon from "../../assets/images/eventIcon.png";
import { TimeOptions, WFStepSchema } from "../../../constants";
import Dropdown from "../Dropdown/Dropdown";
import QuickAddToResource from "../QuickAddToResrouce/QuickAddToResource";
import { useSop } from "../../../hooks/useSop";
import MaintenanceSopSelect from "../AssetForms/MaintenanceSopSelect";
import TertiaryButton from "../Buttons/TertiaryButton";
import IconButton from "../Buttons/IconButton";
import deleteIcon from "../../assets/images/collapseIcon.svg";
import editIcon from "../../assets/images/editIcon.svg";

const editCardWrapperCN = cntl`
  flex 
  flex-col
  mb-20
  p-8
  border-2
  border-brandGreen
  rounded-md
 `;
const eventIconElement = (
  <img className="w-4 h-4" src={eventIcon} alt="event icon" />
);

const WorkflowEditStepCard = ({
  data,
  allMembers,
  toggleEdit,
  handleEditStep,
  index,
  parentIndex,
  isParallel,
  handleDeleteStep,
  hideDueDate,
  startDate,
  workflowData,
  nonRscMembers,
  setNonRscMembers,
}) => {
  const [isEditing] = useState(false);
  const [sop, setSop] = useState(data?.sop || "");
  const [name, setName] = useState(data.name ?? "");
  const [dueDate, setDueDate] = useState({
    date: data?.dueDate?.date,
    time: data?.dueDate?.time,
    allDay: data?.dueDate?.allDay,
  });
  const [duration, setDuration] = useState(data?.duration || 0);
  const [description, setDescription] = useState(data.description);
  const [members, setMembers] = useState(data.members);
  const [needOnlyOneAdvancer, setNeedOnlyOneAdvancer] = useState(
    data.needOnlyOneAdvancer
  );
  const [shownMembers, setShownMembers] = useState(allMembers);
  const [disableAdd, setDisableAdd] = useState(true);
  const [disableOneAdvancer, setDisableOneAdvancer] = useState(
    data.members?.length < 2
  );
  const [hideSearchMembers, setHideSearchMembers] = useState(false);
  const inputReference = useRef(null);
  const stepMembers = data?.members;
  const allMemberRefArray = allMembers.map((mem) => mem.value);
  const missingMembers = [];
  stepMembers.forEach((stepMem) => {
    if (!allMemberRefArray.includes(stepMem?.value)) {
      return missingMembers.push(stepMem);
    }
    return null;
  });
  const { data: sopData } = useSop({ selectOptions: true });

  useEffect(() => {
    if (shownMembers.length === members.length) {
      setHideSearchMembers(true);
    } else {
      setHideSearchMembers(false);
    }
  }, [members.length, shownMembers.length]);

  useEffect(() => {
    inputReference.current?.focus();
  }, []);

  useEffect(() => {
    setShownMembers(allMembers);
  }, [allMembers]);

  useEffect(() => {
    if (!data?.sop?.[0]?.id) {
      const stepSop = sopData?.filter((item) => item?.sop === data?.sop);
      setSop(stepSop);
    }
  }, [data?.sop, sopData]);

  const handleRemoveMember = (usr) => {
    setMembers(
      members.filter((item) => {
        return item.reference !== usr;
      })
    );
  };

  const handleChangeMember = (val) => {
    setMembers(val);
  };

  const handleAddStep = () => {
    const tempObj = {
      ...data,
      name,
      dueDate,
      duration,
      description,
      members: members.filter((info) => info?.reference),
      needOnlyOneAdvancer,
      newStep: false,
      sop,
    };

    handleEditStep(parentIndex, index, tempObj, isParallel);
    toggleEdit();
  };

  const handleCancel = () => {
    if (data?.newStep) {
      handleDeleteStep(parentIndex, index, isParallel);
    }
    setName(data.name);
    setDueDate(data.dueDate);
    setDescription(data.description);
    setMembers(data.members);
    toggleEdit();
  };

  useEffect(() => {
    const handleDisabledAdd = async () => {
      const tempObj = {
        name,
        dueDate: dueDate?.date ? dueDate : startDate?.date ?? undefined,
        description,
        members: members.filter((info) => info?.reference),
      };

      const isValid = await WFStepSchema?.isValid(tempObj);
      if (!isValid) {
        setDisableAdd(true);
      } else {
        setDisableAdd(false);
      }
    };
    handleDisabledAdd();
  }, [dueDate, members, name, description, startDate?.date]);

  useEffect(() => {
    const realMembers = members.filter((info) => info?.reference);

    if (!disableOneAdvancer && realMembers?.length < 2) {
      setDisableOneAdvancer(true);
      setNeedOnlyOneAdvancer(false);
    } else if (disableOneAdvancer && realMembers?.length > 1) {
      setDisableOneAdvancer(false);
    }
  }, [disableOneAdvancer, members]);

  // function to calculate duration
  const handleUpdateDuration = (date) => {
    const tempDuration = moment(date).diff(startDate?.date, "days");
    return setDuration(tempDuration);
  };

  // function that updates Due Date Time individually
  const handleUpdateTimeValue = (value) => {
    setDueDate((prev) => ({ ...prev, time: value }));
  };

  // function that updates Due Date individually
  const handleUpdateDateValue = (value) => {
    setDueDate((prev) => ({ ...prev, date: value }));
    handleUpdateDuration(value);
  };

  // conditional date handling
  useEffect(() => {
    // calculates Due Date based on duration, if there is a change in startDate
    if (duration) {
      // temporary due date to set state
      const tempDue = moment(startDate?.date).add(duration, "days").format();
      setDueDate((prev) => ({
        ...prev,
        date: tempDue,
        time: prev?.time,
        allDay: prev?.allDay,
      }));
    }
    // if there is no duration, set due date to startDate
    if (!duration) {
      setDueDate((prev) => ({
        ...prev,
        date: startDate?.date,
        time: prev?.time,
        allDay: prev?.allDay,
      }));
    }
  }, [startDate, duration, data]);

  const handleGetTimeValue = (timeVal) => {
    if (timeVal?.value && timeVal?.label) {
      return timeVal;
    }
    return TimeOptions.find((item) => item?.value === timeVal);
  };
  const handleAddSopStep = () => {
    setSop((prev) => [
      ...prev,
      {
        id: uuidv4(),
        date: new Date().toISOString(),
        description: "",
        value: "",
        isEditing: true,
      },
    ]);
  };

  const sopHasDuplicate = useCallback(
    (value, idx) => {
      if (value?.sop) {
        return sop?.some((s) => s.sop && s.sop === value.sop);
      }
      return sop?.some((x, sopIndex) => {
        return (
          !x.sop &&
          x?.description?.toLowerCase() === value?.description?.toLowerCase() &&
          sopIndex !== idx
        );
      });
    },
    [sop]
  );

  const toggleEditing = useCallback(
    (sopIndex) => {
      if (isEditing) {
        if (!sop[sopIndex]?.isEditing) {
          setSop((prev) =>
            prev.map((step, idx) =>
              sopIndex === idx
                ? { ...step, isEditing: true }
                : { ...step, isEditing: false }
            )
          );
        } else {
          setSop((prev) =>
            prev.map((step, idx) =>
              sopIndex === idx ? { ...step, isEditing: !step.isEditing } : step
            )
          );
        }
      } else {
        setSop((prev) =>
          prev.map((item) =>
            item.position === sopIndex + 1
              ? { ...item, isEditing: !item.isEditing }
              : item
          )
        );
      }
    },
    [isEditing, sop]
  );
  const removeStep = useCallback((val) => {
    setSop((prev) => prev.filter((step) => step.id !== val.id));
  }, []);

  const handleBlur = (value, idx) => {
    if (window.event.keyCode === 13) return;
    toggleEditing(idx);
    if (value.description === "" || sopHasDuplicate(value, idx)) {
      removeStep(value);
    }
  };

  const onSopStepChange = useCallback(
    (newValue, sopIndex) => {
      if (!sopHasDuplicate(newValue)) {
        setSop((prev) =>
          prev.map((step, idx) => {
            if (idx === sopIndex) {
              return {
                ...step,
                ...newValue,
                position: sopIndex + 1,
                isEditing: false,
                isCompleted: false,
              };
            }
            return step;
          })
        );
        handleAddSopStep();
      }
    },
    [sopHasDuplicate]
  );

  return (
    <div className={editCardWrapperCN} key={data.id}>
      <div className="flex flex-row">
        <div className="flex-1 h-full pr-8">
          <div className="flex flex-row w-full">
            {sop?.length < 1 && (
              <Input
                autoFocus
                className="w-full"
                mainClassName="w-full"
                label="Request"
                placeholder="Request"
                value={description}
                onChange={setDescription}
                validation={yup.string().required()}
                isTextarea
              />
            )}
          </div>
          <div className="flex flex-row justify-between mt-6 items-end">
            <div className="flex flex-col">
              <div className="flex items-center">
                {/* Dropdown for Due Date picker */}
                <DatePicker
                  minDate={new Date(startDate?.date)}
                  className="flex-1 mr-2"
                  label="Due Date"
                  value={dueDate?.date}
                  iconLeft={eventIconElement}
                  onChange={(val) => handleUpdateDateValue(val)}
                  validation={yup.date().required()}
                />
                {/* Dropdown for time selector when not all day */}
                {!dueDate.allDay && (
                  <Dropdown
                    className="flex-1 mt-7 mr-4"
                    label=""
                    options={TimeOptions}
                    value={handleGetTimeValue(dueDate?.time)}
                    placeholder="Select"
                    onChange={(val) => handleUpdateTimeValue(val)}
                    disableClear
                    disableSort
                  />
                )}
                <Checkbox
                  className="mt-7"
                  label="All Day"
                  checked={dueDate.allDay}
                  onChange={() =>
                    setDueDate((prev) => ({
                      ...prev,
                      allDay: !prev.allDay,
                    }))
                  }
                />
              </div>
              {!hideDueDate && (
                <>
                  <h3 className="w-1/2 ml-0.5 mt-1 mb-2 text-gray-400">
                    {duration === 1 ? `${duration} Day` : `${duration} Days`}
                  </h3>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col border-r-2 justify-center px-8">
          {sop[0]?.description === "" && (
            <div className="flex items-center !w-full mt-2">
              <MaintenanceSopSelect
                sopData={sopData}
                setSteps={setSop}
                key={uuidv4()}
                index={0}
                handleSopStepChange={onSopStepChange}
                step={sop || []}
                className="w-full mr-3"
                disableClear={!isEditing}
                handleBlur={handleBlur}
              />
              <IconButton
                className="ml-2"
                imgClassName="w-5 h-5"
                iconClassName="w-5 h-5"
                onClick={() => toggleEditing(sop[0] || 0)}
                icon={editIcon}
              />
              <IconButton
                className="ml-2"
                imgClassName="w-5 h-5"
                iconClassName="w-5 h-5"
                onClick={() => removeStep(sop[0] || 0)}
                icon={deleteIcon}
              />
            </div>
          )}
          <div className="flex items-center !w-full mt-2">
            <p>{sop?.[0]?.description || sop?.[0]?.label}</p>
            {sop?.[0]?.description && (
              <IconButton
                className="ml-2"
                imgClassName="w-5 h-5"
                iconClassName="w-5 h-5"
                onClick={() => toggleEditing(sop?.[0])}
                icon={editIcon}
              />
            )}
            <div className="flex item-center justify-center w-5 h-5">
              {sop?.[0]?.description && (
                <IconButton
                  className="ml-2"
                  imgClassName="w-5 h-5"
                  iconClassName="w-5 h-5"
                  onClick={() => removeStep(sop?.[0])}
                  icon={deleteIcon}
                />
              )}
              {sop?.[0]?.name && (
                <IconButton
                  className="ml-2"
                  imgClassName="w-5 h-5"
                  iconClassName="w-5 h-5"
                  onClick={() => removeStep(sop?.[0])}
                  icon={deleteIcon}
                />
              )}
            </div>
          </div>

          <div className="mt-3">
            {sop?.length < 1 && (
              <TertiaryButton
                onClick={() => {
                  handleAddSopStep();
                }}
                className="col-span-1 pl-0"
                title="+ Add SOP Step"
              />
            )}
          </div>
        </div>
        <div className="flex-1 pl-8">
          <div className="flex">
            <h2 className="font-semibold text-gray-300">Assignees</h2>
            <span className="text-brandGreen text-sm">*</span>
          </div>
          <h3 className="mt-2 mb-4 text-gray-200">
            Select the members who are required to review and advance this step.
          </h3>

          <SelectUserInterface
            className="w-full mb-4"
            userList={members?.map((mem) => {
              return {
                ...(mem.userData
                  ? {
                      ...mem,
                      value: mem.userData?.id,
                      label: getFullName(mem.userData?.name),
                      reference: mem.reference,
                      ...formatSelectUser({
                        ...(mem.userData ?? {}),
                        role: mem.userData?.roleName,
                      }),
                    }
                  : {
                      label: getFullName(mem?.name),
                      value: mem?.reference,
                      reference: mem?.reference,
                      firstName: mem?.firstName,
                      lastName: mem?.lastName,
                      name: {
                        firstName: mem?.firstName,
                        lastName: mem?.lastName,
                      },
                      avatar: {},
                      id: mem?.id,
                      role: mem?.roleName,
                      kind: mem?.kind,
                    }),
              };
            })}
            userPlaceholder="Search members"
            userOptions={shownMembers}
            buttonText="Add Contact"
            onAddUser={handleChangeMember}
            onRemoveUser={handleRemoveMember}
            hideSearchMembers={hideSearchMembers}
            disableCreateUser
            showCurrentRole
          />
          <div>
            <Checkbox
              label="Only one advancer required for this step"
              checked={needOnlyOneAdvancer}
              onChange={setNeedOnlyOneAdvancer}
              disabled={disableOneAdvancer}
            />
          </div>
          {nonRscMembers?.length > 0 &&
            nonRscMembers?.map((mem) => {
              return (
                <QuickAddToResource
                  key={mem}
                  addMember={mem}
                  currentMembers={data?.members}
                  setNonRscMembers={setNonRscMembers}
                  projectId={workflowData?.project?.split("/")[1] ?? false}
                  propertyId={workflowData?.property?.split("/")[1] ?? false}
                />
              );
            })}
        </div>
      </div>
      <div className="flex flex-row justify-end">
        <SecondaryButton
          title="Cancel"
          className="mr-6"
          onClick={handleCancel}
        />
        <PrimaryButton
          title={data?.newStep ? "Add Step" : "Update"}
          onClick={handleAddStep}
          disabled={disableAdd}
        />
      </div>
    </div>
  );
};
WorkflowEditStepCard.propTypes = {
  /**
   * data needed to display wrkflow steps
   */
  data: PropTypes.shape({
    sop: PropTypes.shape({}),
    duration: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    dueDate: PropTypes.object,
    needOnlyOneAdvancer: PropTypes.bool,
    position: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    documents: PropTypes.arrayOf(PropTypes.any),
    id: PropTypes.string,
    newStep: PropTypes.bool,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        reference: PropTypes.string,
        userData: PropTypes.shape({
          name: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
          }),
        }),
      })
    ),
    name: PropTypes.string,
    description: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    comments: PropTypes.arrayOf(PropTypes.any),
  }),
  /**
   * function used to toggle step editing mode
   */
  toggleEdit: PropTypes.func,
  /**
   * function used to edit a step in the workflow
   */
  handleEditStep: PropTypes.func,
  /**
   * index of current step
   */
  index: PropTypes.number,
  /**
   * index of parent group of steps
   */
  parentIndex: PropTypes.number,
  /**
   * all members selected for this workflow
   */
  allMembers: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  /**
   * boolean to determine if this steo is a parallel step
   */
  isParallel: PropTypes.bool,
  /**
   * function used to delete a step on the workflow
   */
  handleDeleteStep: PropTypes.func,
  hideDueDate: PropTypes.bool,

  startDate: PropTypes.shape({
    date: PropTypes.string,
  }),
  nonRscMembers: PropTypes.arrayOf(PropTypes.shape({})),
  setNonRscMembers: PropTypes.func,
  workflowData: PropTypes.shape({
    project: PropTypes.string,
    property: PropTypes.string,
  }),
};

WorkflowEditStepCard.defaultProps = {
  data: undefined,
  allMembers: [],
  toggleEdit: undefined,
  handleEditStep: undefined,
  index: undefined,
  parentIndex: undefined,
  isParallel: undefined,
  handleDeleteStep: undefined,
  hideDueDate: false,
  startDate: undefined,
  nonRscMembers: [{}],
  workflowData: undefined,
  setNonRscMembers: undefined,
};
export default WorkflowEditStepCard;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  PdfLoader,
  PdfHighlighter,
  Tip,
  Highlight,
  Popup,
  AreaHighlight,
} from "react-pdf-highlighter";
import "./PDFViewer.css";
import HighlightPopup from "./HighlightPopup";

const getNextId = () => String(Math.random()).slice(2);

const resetHash = () => {
  document.location.hash = "";
};

const PDFViewer = ({ initialUrl, showSideBar }) => {
  const [url, setUrl] = useState(initialUrl);
  const [highlights, setHighlights] = useState([]);

  useEffect(() => {
    setUrl(initialUrl);
  }, [initialUrl]);

  const resetHighlights = () => {
    setHighlights([]);
  };

  const addHighlight = (highlight) => {
    setHighlights((prev) => [...prev, { id: getNextId(), ...highlight }]);
  };

  const updateHighlight = (highlightId, position, content) => {
    setHighlights(
      highlights.map((h) => {
        const {
          id,
          position: originalPosition,
          content: originalContent,
          ...rest
        } = h;
        return id === highlightId
          ? {
              id,
              position: { ...originalPosition, ...position },
              content: { ...originalContent, ...content },
              ...rest,
            }
          : h;
      })
    );
  };

  return (
    <div
      className="App"
      style={{ display: "flex", height: "100%", width: "100%" }}
    >
      {showSideBar && (
        <div className="sidebar" style={{ width: "25vw" }}>
          <div className="description" style={{ padding: "1rem" }}>
            <p>
              <small>
                To create area highlight hold ⌥ Option key (Alt), then click and
                drag.
              </small>
            </p>
          </div>

          <ul className="sidebar__highlights">
            {highlights.map((highlight) => (
              <li key={highlight.id} className="sidebar__highlight">
                <div>
                  <strong>{highlight.comment?.text}</strong>
                  {highlight.content?.text ? (
                    <blockquote style={{ marginTop: "0.5rem" }}>
                      {`${highlight.content?.text.slice(0, 90).trim()}…`}
                    </blockquote>
                  ) : null}
                  {highlight.content?.image ? (
                    <div
                      className="highlight__image"
                      style={{ marginTop: "0.5rem" }}
                    >
                      <img src={highlight.content?.image} alt="Screenshot" />
                    </div>
                  ) : null}
                </div>
                <div className="highlight__location">
                  Page {highlight.position?.pageNumber}
                </div>
              </li>
            ))}
          </ul>
          {highlights.length > 0 ? (
            <div style={{ padding: "1rem" }}>
              <button type="button" onClick={resetHighlights}>
                Reset highlights
              </button>
            </div>
          ) : null}
        </div>
      )}
      <div
        style={{
          width: "100%",
          position: "relative",
        }}
      >
        <PdfLoader
          url={url}
          beforeLoad={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <div className="sk-fading-circle">
                <div className="sk-circle1 sk-circle" />
                <div className="sk-circle2 sk-circle" />
                <div className="sk-circle3 sk-circle" />
                <div className="sk-circle4 sk-circle" />
                <div className="sk-circle5 sk-circle" />
                <div className="sk-circle6 sk-circle" />
                <div className="sk-circle7 sk-circle" />
                <div className="sk-circle8 sk-circle" />
                <div className="sk-circle9 sk-circle" />
                <div className="sk-circle10 sk-circle" />
                <div className="sk-circle11 sk-circle" />
                <div className="sk-circle12 sk-circle" />
              </div>
            </div>
          }
        >
          {(pdfDocument) => (
            <PdfHighlighter
              pdfDocument={pdfDocument}
              enableAreaSelection={(event) => event.altKey}
              onScrollChange={resetHash}
              onSelectionFinished={(
                position,
                content,
                hideTipAndSelection,
                transformSelection
              ) => (
                <Tip
                  onOpen={transformSelection}
                  onConfirm={(comment) => {
                    addHighlight({ content, position, comment });
                    hideTipAndSelection();
                  }}
                />
              )}
              highlightTransform={(
                highlight,
                index,
                setTip,
                hideTip,
                viewportToScaled,
                screenshot,
                isScrolledTo
              ) => {
                const isTextHighlight = !(
                  highlight.content && highlight.content.image
                );

                const component = isTextHighlight ? (
                  <Highlight
                    isScrolledTo={isScrolledTo}
                    position={highlight.position}
                    comment={highlight.comment}
                  />
                ) : (
                  <AreaHighlight
                    isScrolledTo={isScrolledTo}
                    highlight={highlight}
                    onChange={(boundingRect) => {
                      updateHighlight(
                        highlight.id,
                        { boundingRect: viewportToScaled(boundingRect) },
                        { image: screenshot(boundingRect) }
                      );
                    }}
                  />
                );

                return (
                  <Popup
                    popupContent={<HighlightPopup {...highlight} />}
                    onMouseOver={(popupContent) =>
                      setTip(highlight, () => popupContent)
                    }
                    onMouseOut={hideTip}
                    key={index}
                    // eslint-disable-next-line react/no-children-prop
                    children={component}
                  />
                );
              }}
              highlights={highlights}
            />
          )}
        </PdfLoader>
      </div>
    </div>
  );
};

PDFViewer.propTypes = {
  /**
   * url of the pdf being shown
   */
  initialUrl: PropTypes.string,
  /**
   * boolean to show sidebar
   */
  showSideBar: PropTypes.bool,
};

PDFViewer.defaultProps = {
  initialUrl: undefined,
  showSideBar: undefined,
};

export default PDFViewer;

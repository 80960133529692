/* eslint-disable import/prefer-default-export */
import { FileAPI } from "@griffingroupglobal/eslib-api";
import { fileKeys } from "../config/reactQuery/queryKeyFactory";
import useAuthenticatedQuery from "./useAuthenticatedQuery";

const getFile = async (fileRef) => {
  if (fileRef) {
    const { data } = await FileAPI.getByRef(fileRef);
    return data;
  }
  return null;
};

/**
 * Query hook that fetch file with presigned url and refetch after 45 min
 * @param {string | object} reference File reference, ex: File/2223-3334-5556 or user object (Chat)
 * @returns query hook
 */
export const useGetFile = (ref) => {
  const fileRef = typeof ref === "object" ? ref?.reference : ref;

  return useAuthenticatedQuery({
    queryKey: fileKeys(ref),
    enabled: fileRef?.startsWith("File/"),
    queryFn: () => getFile(fileRef),
    staleTime: 45 * 60 * 1000,
    onError: (error) => {
      console.error("❌ useGetFile", error.message);
    },
  });
};

// Framework Tools
import React from "react";

// Libraries
import { cloneDeep, lastIndexOf } from "lodash";

// Constants
import { REQUEST_EMPTY_STEP } from "../../../constants";

// Components
import ManagementRequestTemplateStep from "./ManagementRequestTemplateStep";
import TertiaryButton from "../Buttons/TertiaryButton";

const ManagementRfiTemplateSection = ({
  managementSettings,
  dispatchManagementSettings,
}) => {
  // Adds a step at the bottom of the template
  const handleAddStep = (lastStep) => {
    const emptyStep = cloneDeep(REQUEST_EMPTY_STEP);
    const formattedEmptyStep = {
      ...emptyStep,
      name: `Step ${lastStep + 1}`,
      position: lastStep + 1,
    };
    dispatchManagementSettings({
      type: "addStepRFITemplate",
      step: formattedEmptyStep,
    });
  };

  // Removes step by index
  const handleRemoveStep = (index) => {
    dispatchManagementSettings({
      type: "removeStepRFITemplate",
      index,
    });
  };

  const onChangeDuration = (index, duration) => {
    dispatchManagementSettings({
      type: "changeDurationStepRFITemplate",
      index,
      duration,
    });
  };

  const onChangeRole = (index, role) => {
    dispatchManagementSettings({
      type: "changeRoleStepRFITemplate",
      index,
      role: role?.value,
    });
  };

  return (
    <div className="flex flex-col w-full p-4">
      {managementSettings?.RFITemplate?.steps?.map((step, index) => {
        return (
          <div
            className="flex items-center w-full mt-2"
            key={`${step?.name}-${step?.type}-${step?.position}-${step?.duration?.projected}`}
          >
            <ManagementRequestTemplateStep
              currentIndex={index}
              duration={step?.duration?.projected}
              role={step?.type}
              handleRemoveStep={handleRemoveStep}
              onChangeDuration={onChangeDuration}
              onChangeRole={onChangeRole}
            />
          </div>
        );
      })}
      <div className="flex w-full">
        <TertiaryButton
          className="w-full mt-4 border-dashed border-gray-150 border-2 h-16 rounded-md mb-12 text-lg font-semibold"
          title="+ Add New Step"
          onClick={() =>
            handleAddStep(lastIndexOf(managementSettings?.RFITemplate?.steps))
          }
          noMaxWidth
        />
      </div>
    </div>
  );
};

export default ManagementRfiTemplateSection;

const mentionRegex = /{{([^}}]+)}}/g;

/**
 * Extract users id based on regex from string:
 * Ex: Hello {{ User/1111-222-3333}} how are you?
 * @param {string} str string which represents message with possible mentions
 * @returns array of users ids.
 * Ex: ["User/1111-222-3333"]
 */
export const extractIdsFromMessage = (str = "") => {
  // Looks for strings matching the pattern {{ User/111/222/333 }}
  const matches = str.match(mentionRegex);
  if (!matches) {
    return [];
  }

  // Take ids out from {{ }} characters
  const ids = matches
    .map((match) => {
      const contentMatch = mentionRegex.exec(match);
      return contentMatch ? contentMatch[1] : null;
    })
    .filter(Boolean);

  return ids;
};

/**
 * Transform userId extracted with regex and replace with user information
 * @param {string} str string containing data to decode with regex
 * @param {Object} userObj user dictionary
 * @returns HTML tag with user name
 */
/**
 * Transform userId extracted with regex and replace with user information
 * @param {string} str string containing data to decode with regex
 * @param {Object} userObj user dictionary
 * @returns Object with text and mention data
 */
export const transformMentionFormat = (str = "", userObj = {}) => {
  const result = {
    textOriginal: str,
    textWithMention: "",
    mentionData: {},
  };

  result.textWithMention = str.replace(mentionRegex, (match, idFromRegex) => {
    // Extract the user id from the mention.
    const user = userObj[idFromRegex];
    // If a user is found, replace the mention with the user's first name.
    if (user) {
      const mention = `@${user.name.firstName.split(/\s+/)[0]}`;
      result.mentionData[mention] = idFromRegex;
      return mention;
    }
    // If no user is found, leave the original message
    return match;
  });

  return result;
};

/**
 * Return users who are members with administrative roles
 * @param {object[]} users array of ES users
 * @returns array with member + admin users
 */
export const getAdminUsers = (users = []) =>
  users.filter((user) => {
    return (
      user?.kind === "member" &&
      ["SuperAdmin", "Admin"].includes(user?.roleName)
    );
  });

/**
 * Transform array of users in the format required by react-mentions [{id, display}], and excludes current user
 * @param {Array} users array of users object
 * @returns array in the form of [{id: uuid, display: user name}]
 */
export const transformUsersToMentionsStructure = (
  users = [],
  currentUser = {}
) =>
  users.reduce((acc, { reference, name }) => {
    if (reference !== currentUser?.reference && name?.firstName) {
      acc.push({
        id: reference,
        display: `${name.firstName} ${name?.lastName || ""}`,
      });
    }
    return acc;
  }, []);

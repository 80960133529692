import React, { useState } from "react";
import PropTypes from "prop-types";

import BaseButton from "../Buttons/BaseButton";
import collapseIcon from "../../assets/images/collapseIcon.svg";

const RowDeleteButton = ({ onClick }) => {
  const [hovering, setHovering] = useState();

  const handleHovering = (val) => {
    setHovering(val);
  };
  return (
    <div
      className="w-8 h-8"
      onMouseOver={() => handleHovering(true)}
      onMouseOut={() => handleHovering(false)}
      onFocus={() => handleHovering(true)}
      onBlur={() => handleHovering(false)}
    >
      <div className={hovering ? "" : "invisible"}>
        <BaseButton
          className="flex items-center justify-center"
          iconRight={<img src={collapseIcon} className="w-5 h-5 mt-2" alt="" />}
          onClick={onClick}
          hideDropdownChevron
        />
      </div>
    </div>
  );
};

RowDeleteButton.propTypes = {
  onClick: PropTypes.func,
};

RowDeleteButton.defaultProps = {
  onClick: () => {},
};

export default RowDeleteButton;

import _ from "lodash";
import { toastError } from "../stories/Components/Toast/Toast";

/**
 * Get user's channels by tenant
 * @param {Object} client chat client
 * @param {string} userStreamId user's stream id
 * @param {string} tenantId current tenant id
 * @returns
 */
const getChatChannels = (client, userStreamId, tenantId) => {
  const filter = {
    team: tenantId,
    members: { $in: [userStreamId] },
  };
  const sort = [{ last_updated: -1 }];

  return client.queryChannels(filter, sort, {});
};

const getInitials = (name = "", channelName) => {
  if (channelName) {
    return channelName.charAt(0) ?? "";
  }

  if (name && typeof name === "string") {
    const firstName = name?.split(" ")[0];
    const lastName = name?.split(" ")[1];
    const firstNameLetter = firstName?.charAt(0) ?? "";
    const lastNameLetter = lastName?.charAt(0) ?? "";
    return `${firstNameLetter}${lastNameLetter}`.trim();
  }

  if (Object.keys(name).length > 0) {
    const firstNameLetter = name?.firstName?.charAt(0) ?? "";
    const lastNameLetter = name?.lastName?.charAt(0) ?? "";
    return `${firstNameLetter}${lastNameLetter}`.trim();
  }

  return "";
};

const createChannelId = (tenantId, members) => {
  const channelId = _.truncate(tenantId.replace(/[^a-zA-Z0-9]/g, ""), {
    length: 10,
    omission: "",
  });

  const userIds = members.sort();

  const uniqueId = userIds.reduce((accumulator, user) => {
    const id = _.truncate(user, {
      length: 2,
      omission: "",
    });
    return accumulator + id;
  }, channelId);

  return uniqueId;
};

const onAddFilesFunction = ({ selectedChannel, setFiles, setLoadingFiles }) => {
  return async (e) => {
    const f = e?.dataTransfer?.files || e?.target?.files;
    setLoadingFiles(true);
    try {
      const uploads = [];
      for (let i = 0; i < f.length; i += 1) {
        const blob = new Blob([f[i]], {
          type: f[i].type,
        });
        // eslint-disable-next-line no-await-in-loop
        const fileUrl = await selectedChannel.sendFile(
          blob,
          f[i].name,
          f[i].type
        );
        uploads.push({ file: f[i], url: fileUrl.file, type: f[i].type });
      }
      setFiles((prev) => [...prev, ...uploads]);
    } catch (err) {
      toastError("Error adding attachments.");
      console.error(err);
    }
    setLoadingFiles(false);
  };
};

const getTarget = (channel, currentUser) => {
  if (channel?.data?.name)
    return channel?.data?.name.toLowerCase().replace(" ", "");
  const members = Object.values(channel?.state?.members);
  return members?.find((x) => x?.user_id !== currentUser?.streamId)?.user?.name;
};

const alphaSortChannels = (channels) => {
  return [...channels].sort((a, b) => {
    const targetOne = getTarget(a);
    const targetTwo = getTarget(b);
    if (targetOne < targetTwo) {
      return -1;
    }
    if (targetOne > targetTwo) {
      return 1;
    }
    return 0;
  });
};

export {
  getChatChannels,
  createChannelId,
  getInitials,
  onAddFilesFunction,
  alphaSortChannels,
};

import { useEffect, useMemo, useState } from "react";
import useCurrentUser from "../../../../hooks/useCurrentUser";

const useOwnedByListData = ({
  editedUser,
  members,
  selectedMembers,
  setCreatingUser,
  onRemove,
  onAdd,
  setEditing,
}) => {
  const { data: currentUser } = useCurrentUser();
  const [editingState, setEditingState] = useState();

  useEffect(() => {
    setEditingState(editedUser?.label);
  }, [editedUser?.label]);

  const currentMembers = useMemo(() => {
    return (
      members.map((member) => ({
        ...member,
        isSelected: !!selectedMembers.find((selectedMember) => {
          if (selectedMember?.reference) {
            return (
              selectedMember?.reference === member.value ||
              selectedMember?.reference === member.reference
            );
          }
          return (
            selectedMember === member.value ||
            selectedMember === member?.reference
          );
        }),
      })) || []
    );
  }, [members, selectedMembers]);

  const onChange = (user) => {
    if (user.isSelected) {
      onRemove(user?.reference);
    } else {
      onAdd(user);
    }
  };

  const onAddContact = () => {
    setCreatingUser(true);
  };

  const onCancel = () => {
    setEditing(null);
    setCreatingUser(false);
  };

  return {
    currentUser,
    currentMembers,
    editingState,
    setEditingState,
    onAddContact,
    onCancel,
    onChange,
  };
};

export default useOwnedByListData;

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useAppState } from "../../../../state/appState";
import "../messagingTray.css";
import { useESChatContext } from "../../../../context/ChatContext/ESChatContext";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import { ERROR_DISCONNECTED } from "../../../../constants";
import ImageCell from "../../../../stories/Components/Table/ImageCell";

const MessagingDirectMessageList = ({ expanded }) => {
  const [{ usersStreamDict }] = useAppState();
  const {
    setSelectedChannel,
    selectedChannel,
    channels,
    directMessages,
    getNewChannel,
    error,
    clientChat,
    setUnread,
    refetch,
  } = useESChatContext();
  const { data: currentUser } = useCurrentUser();
  const [directMessagesList, setDirectMessagesList] = useState([]);

  /**
   * Reconnect
   */
  const reconnect = useRef(null);

  useEffect(() => {
    if (
      (!clientChat?.user && clientChat) ||
      error?.err === ERROR_DISCONNECTED
    ) {
      reconnect.current = setInterval(refetch, 5000);
    } else {
      reconnect.current = null;
    }

    return () => clearInterval(reconnect.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientChat, clientChat?.user]);

  useEffect(() => {
    if (directMessages?.length && Object.keys(usersStreamDict || {})?.length) {
      const data = directMessages.reduce((acc, item) => {
        const members = Object.values(item?.state?.members);
        let target = members?.find((x) => x?.user_id !== currentUser?.streamId);
        if (target) {
          const deletedTarget = {};
          if (usersStreamDict?.[target?.user_id]) {
            target = usersStreamDict?.[target?.user_id];
          } else {
            deletedTarget.name = target?.user?.name;
            target = undefined;
          }

          acc.push({
            item,
            target,
            members: Object.values(item?.state?.members),
            deletedTarget,
          });
        }
        return acc;
      }, []);
      setDirectMessagesList(data);
    }
  }, [
    currentUser?.streamId,
    directMessages,
    setDirectMessagesList,
    usersStreamDict,
  ]);

  /**
   * Reconnect
   */
  const listener = useRef();
  useEffect(() => {
    if (clientChat) {
      // Listen for new channels
      listener.current = clientChat?.on((event) => {
        if (
          event.type === "message.new" ||
          event.type === "message.read" ||
          event.type === "notification.message_new" ||
          event.type === "notification.mark_read"
        ) {
          setUnread(
            [...directMessages, ...channels].reduce((count, item) => {
              // eslint-disable-next-line no-param-reassign
              count += item?.countUnread();
              return count;
            }, 0)
          );
        }
        const eventChannel =
          directMessages?.find((item) => item.cid === event.cid) ??
          channels?.find((item) => item.cid === event.cid);
        if (event.type === "notification.added_to_channel") {
          getNewChannel(event.channel_id);
          return;
        }
        if (event.type === "notification.message_new" && !eventChannel) {
          getNewChannel(event.channel_id);
        }
      });
    }
    return () => {
      if (listener?.current) listener?.current?.unsubscribe();
    };
  }, [channels, clientChat, directMessages, getNewChannel, setUnread]);

  return (
    <div className="flex flex-col py-2 gap-4">
      {clientChat?.user && (
        <>
          {expanded && (
            <header
              className={`text-white font-medium text-sm ${
                expanded ? "px-3" : ""
              }`}
            >
              <p>Direct Messages</p>
            </header>
          )}
          <div
            className={`flex flex-col gap-4 channels-list ${
              expanded
                ? "px-3 overflow-y-scroll overflow-x-hidden"
                : "overflow-hidden"
            }`}
            style={{ maxHeight: "256px" }}
          >
            {directMessagesList
              ?.slice(0, !expanded ? 5 : undefined)
              .map((message) => {
                return (
                  <button
                    key={`button-${message?.item?.id}-${message?.item?.data?.created_at}`}
                    type="button"
                    className={`w-full p-1 relative flex gap-3 rounded-full transition-all channel-button items-center font-light justify-${
                      expanded ? "start" : "center"
                    }`}
                    onClick={() => setSelectedChannel(message?.item)}
                    title={message?.target?.name ?? "ES Member (deleted)"}
                  >
                    <span className="relative" style={{ minWidth: "1.75rem" }}>
                      {message?.target?.isOnline && (
                        <p
                          className="animate-pulse bg-borderGreen"
                          style={{
                            width: "0.5rem",
                            height: "0.5rem",
                            position: "absolute",
                            top: "0",
                            right: "0",
                            borderRadius: "99999px",
                          }}
                        />
                      )}
                      <ImageCell
                        className={`w-7 h-7 rounded-full object-cover ${
                          selectedChannel?.id === message?.item?.id
                            ? "border-white border-2"
                            : ""
                        }`}
                        src={message?.target?.avatar}
                      />
                      {message?.item.state.unreadCount > 0 && (
                        <div
                          className="bg-white w-4 flex items-center justify-center h-4 text-xxs font-semibold rounded-full absolute -right-1"
                          style={{ top: "45%" }}
                        >
                          {message?.item.state.unreadCount < 100
                            ? message?.item.state.unreadCount
                            : "+"}
                        </div>
                      )}
                    </span>
                    {expanded && (
                      <span className="flex text-left flex-col w-10/12">
                        <p className="text-white truncate text-xs">
                          {`${
                            message?.target?.name ??
                            message?.deletedTarget?.name
                          }${
                            message?.members.length > 2
                              ? ` +${message?.members.length - 2}`
                              : ""
                          }`}
                        </p>
                        {!message?.target?.name && (
                          <p className="text-gray-200 truncate text-xs">
                            (deleted)
                          </p>
                        )}
                      </span>
                    )}
                  </button>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

MessagingDirectMessageList.propTypes = {
  expanded: PropTypes.bool,
};

MessagingDirectMessageList.defaultProps = {
  expanded: false,
};

export default MessagingDirectMessageList;

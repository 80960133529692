// Framework Tools
import React from "react";
import PropTypes from "prop-types";

// Libraries
import moment from "moment";

// Components
import TertiaryHeader from "../../TextHeaders/TertiaryHeader";

export default function RequestGridViewStartDateCell({ startDate }) {
  return (
    <div className="w-full h-ful flex flex-col justify-start flex-wrap px-2">
      <TertiaryHeader className="text-sm text-left">Start Date</TertiaryHeader>
      <p className="text-sm text-left">
        {moment(startDate).format("MMMM DD, YYYY")}
      </p>
    </div>
  );
}

// prop types
RequestGridViewStartDateCell.propTypes = {
  startDate: PropTypes.string,
};

// default props
RequestGridViewStartDateCell.defaultProps = {
  startDate: "",
};

import { TimesheetAPI } from "@griffingroupglobal/eslib-api";

const fetchTimesheetListHandler = async (params) => {
  const { data } = await TimesheetAPI.get(params);
  const timesheetsArr = data?.entries.map((ts) => ts.resource);

  return timesheetsArr;
};

export default fetchTimesheetListHandler;

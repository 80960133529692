import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

const textCN = (textClassName) => cntl`
  text-gray-300 
  font-semibold 
  text-sm
  whitespace-nowrap
  ${textClassName}
`;

const IconLabel = ({
  className,
  iconStyle,
  icon,
  text,
  onClick,
  textClassName,
  direction,
  forwardedRef,
}) => {
  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      onClick();
    }
  };

  return (
    <div
      className={`flex items-center ${className}`}
      onClick={onClick}
      onKeyDown={onKeyDown}
      role="button"
      tabIndex={0}
      ref={forwardedRef}
    >
      {direction === "left" && (
        <img className="h-5 mr-3" style={iconStyle} src={icon} alt="" />
      )}
      <p className={textCN(textClassName)}>{text}</p>
      {direction === "right" && (
        <img className="h-5 ml-3" style={iconStyle} src={icon} alt="" />
      )}
    </div>
  );
};

IconLabel.propTypes = {
  /**
   * class names to apply to the container
   */
  className: PropTypes.string,
  /**
   * url pointing to an image
   */
  icon: PropTypes.string.isRequired,
  /**
   * text to display as the label
   */
  text: PropTypes.string.isRequired,
  /**
   * the handler func for clicks
   */
  onClick: PropTypes.func,
  /**
   * class names to apply to the text
   */
  textClassName: PropTypes.string,
  /**
   * direction of the icon
   */
  direction: PropTypes.string,
  forwardedRef: PropTypes.func,
  iconStyle: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
};

IconLabel.defaultProps = {
  className: null,
  onClick: null,
  textClassName: null,
  direction: "left",
  forwardedRef: undefined,
  iconStyle: undefined,
};

export default IconLabel;

import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import placeholderImage from "../../assets/images/placeholderImage.png";
import {
  downloadMedia,
  splitFileNameAndExtension,
} from "../../../helpers/File";
import TableActionsIconsGroup from "../Table/TableActionsIconsGroup";
import TagsContainer from "../Tags/TagsContainer";
import PDFViewer from "../PDFViewer/PDFViewer";
import "./fileMedia.css";
import Input from "../Input/Input";
import BackButton from "../WidgetEditorView/Header/BackButton";

const FilePreview = ({
  file,
  handleShowDelete,
  setShowFileView,
  canDelete,
  canEdit,
  handleSave,
  handleCancel,
  fileDispatch,
  fileForm,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [fileExtension, setFileExtension] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  useEffect(() => {
    setIsSaveDisabled(!splitFileNameAndExtension(fileForm?.name)?.name?.trim());
  }, [fileForm?.name]);

  useEffect(() => {
    if (file?.name) {
      setFileExtension(splitFileNameAndExtension(file?.name)?.ext);
    }
  }, [file?.name]);

  const onEditOpen = () => {
    setIsEditing(true);
  };

  const onEditSave = () => {
    handleSave();
    setIsEditing(false);
  };

  const onEditCancel = () => {
    handleCancel();
    setIsEditing(false);
  };

  const onNameChange = useCallback(
    (value) => {
      fileDispatch({
        type: "setName",
        name: value + fileExtension,
      });
    },
    [fileExtension, fileDispatch]
  );

  const handleDownload = async () => {
    await downloadMedia([file?.reference]);
  };

  return (
    <div className="flex flex-col items-start w-full h-full">
      <BackButton onBackPressed={() => setShowFileView(false)} />
      <div
        id="image_preview_container"
        className="flex flex-col justify-start items-start w-full text-2xl font-semibold my-9 px-9 mt-9 border rounded-md gap-4"
      >
        <div className="flex flex-row items-center justify-between mt-7 w-full h-9 mb-4">
          <div className="flex w-full pt-1">
            {isEditing ? (
              <Input
                mainWrapperClassName="w-3/4"
                value={
                  isEditing
                    ? splitFileNameAndExtension(fileForm?.name)?.name
                    : fileForm?.name
                }
                hidePencil
                extension={fileExtension}
                placeholder="File Name"
                onChange={(val) => onNameChange(val)}
              />
            ) : (
              <p className=" text-es-lg text-es-black font-es-semibold">
                {fileForm?.name}
              </p>
            )}
          </div>

          <TableActionsIconsGroup
            handleShowDelete={handleShowDelete}
            downloadFiles={handleDownload}
            canDelete={canDelete}
            canDownload
            canEdit={canEdit}
            handleEdit={onEditOpen}
            handleSave={onEditSave}
            handleCancel={onEditCancel}
            isEditing={isEditing}
            isSaveDisabled={isSaveDisabled}
            absoluteBtnPosition={false}
          />
        </div>

        <div
          className="flex justify-center items-center h-full w-full"
          style={{
            width: "885px",
            height: "577px",
          }}
        >
          {file?.category === "Photos" && (
            <div className="media-wrapper rounded-md">
              <img
                src={file?.contentsUrl ?? placeholderImage}
                alt="Resource"
                className="w-full mt-9"
                style={{ borderRadius: "6px" }}
              />
            </div>
          )}

          {file?.category === "Videos" && (
            <div className="media-wrapper rounded-md">
              <video
                autoPlay
                muted
                controls
                poster={placeholderImage}
                className="video-player"
              >
                <source
                  src={file?.contentsUrl ?? placeholderImage}
                  type="video/webm"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          )}

          {file?.category === "Documents" &&
            file.contentType?.includes("application/pdf") && (
              <PDFViewer initialUrl={file?.contentsUrl} />
            )}
          {file?.category === "Documents" &&
            !file?.contentType?.includes("application/pdf") && (
              <p className="flex justify-center items-center text-base font-normal h-full w-full">
                Unable to view file. &nbsp;
                <button
                  type="button"
                  className="text-brandGreen"
                  onClick={handleDownload}
                >
                  Download instead?
                </button>
              </p>
            )}
        </div>

        <div className="flex items-center mt-9 w-full">
          <div className="flex flex-col boder border-b-1 pb-7 pr-4 w-1/2">
            <div className="flex border-b">
              <p
                className="text-base font-semibold mb-4"
                style={{ width: "256px", color: "#585858" }}
              >
                Type
              </p>
              <p
                className="text-base font-normal justify-start truncate text-ellipsis mb-4"
                style={{ color: "#585858" }}
              >
                {file?.category}
              </p>
            </div>
            <div className="flex mt-4">
              <p
                className="text-base font-semibold"
                style={{ width: "256px", color: "#585858" }}
              >
                Created By
              </p>
              <p
                className="text-base font-normal justify-start"
                style={{ color: "#585858" }}
              >
                {file?.createdBy}
              </p>
            </div>
          </div>
          <div className="flex flex-col pb-7 w-1/2">
            <div className="flex border-b">
              <p
                className="text-base font-semibold mb-4"
                style={{ width: "256px", color: "#585858" }}
              >
                Date Modified
              </p>
              <p
                className="text-base font-normal justify-start mb-4"
                style={{ color: "#585858" }}
              >
                {moment(file?.metadata?.lastUpdated).format("lll")}
              </p>
            </div>
            <div className="flex mt-4">
              <p
                className="text-base font-semibold"
                style={{ width: "256px", color: "#585858" }}
              >
                Other
              </p>
              <p
                className="text-base font-normal justify-start"
                style={{ color: "#585858" }}
              >
                {file?.contentType?.split("/")[1]}
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center w-full text-base font-normal">
          <TagsContainer
            isEditing={isEditing}
            className="pb-7 w-full"
            tagsWrapperClassName="w-full mt-1"
            dispatch={fileDispatch}
            resource={fileForm}
          />
        </div>
      </div>
    </div>
  );
};

FilePreview.propTypes = {
  file: PropTypes.shape({
    metadata: PropTypes.shape({
      lastUpdated: PropTypes.string,
    }),
    contentType: PropTypes.string,
    createdBy: PropTypes.string,
    category: PropTypes.string,
    contentsUrl: PropTypes.string,
    name: PropTypes.string,
    reference: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        id: PropTypes.string,
      })
    ),
  }),
  canDelete: PropTypes.bool,
  setShowFileView: PropTypes.func,
  handleShowDelete: PropTypes.func,
  canEdit: PropTypes.bool,
  handleSave: PropTypes.func,
  fileForm: PropTypes.shape({
    resource: PropTypes.shape({
      tags: PropTypes.arrayOf(PropTypes.string),
    }),
    currentTags: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    name: PropTypes.string,
  }),
  fileDispatch: PropTypes.func,
  handleCancel: PropTypes.func,
};

FilePreview.defaultProps = {
  file: undefined,
  canDelete: false,
  setShowFileView: undefined,
  handleShowDelete: undefined,
  canEdit: false,
  handleSave: () => {},
  fileForm: undefined,
  fileDispatch: () => {},
  handleCancel: undefined,
};

export default FilePreview;

/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import Dropzone from "react-dropzone";
import uploadIcon from "../../assets/images/uploadIcon.svg";
import TertiaryButton from "../Buttons/TertiaryButton";
import ExternalLink from "../ExternalLink/ExternalLink";
import "./PureFileForm.css";
import BaseButton from "../Buttons/BaseButton";
import FileCell from "./FileCell";
import PrimaryButton from "../Buttons/PrimaryButton";
import CircularProgressBar from "../CircularProgressBar/CircularProgressBar";
import IconButton from "../Buttons/IconButton";
import Spinner from "../Spinner/Spinner";
import greyCrossIcon from "../../assets/images/greyCrossIcon.svg";

const displayContainerCN = cntl`
  my-5
`;

const titleCN = cntl`
  font-semibold
  text-lg
  text-gray-700
`;

const PureFileUpload = ({
  documents,
  onUpload,
  onEdit,
  onRemove,
  handleCloseUploader,
  handleFileUpload,
  showSpinner,
  documentTypes,
  onEditDocumentType,
}) => {
  const [filesToUpload, setFilesToUpload] = useState();
  const [progress, setProgress] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [showDropzone, setShowDropzone] = useState(true);

  useEffect(() => {
    setShowDropzone(documents.length === 0);
  }, [documents]);

  const onDrop = async (droppedFiles) => {
    setFilesToUpload(droppedFiles);
    setLoading(true);
    await onUpload(droppedFiles, (loaded, total, fileId) => {
      setProgress((prevState) => ({
        ...prevState,
        [fileId]: [loaded, total],
      }));
    });
    setLoading(false);
    setShowDropzone(false);
    setFilesToUpload(null);
  };

  const onSubmit = () => {
    handleFileUpload();
    handleCloseUploader();
  };

  return (
    <div
      className="bg-white border-2 border-brandGreen rounded-md p-6"
      style={{ transform: "translateY(1px)", minHeight: "20rem" }}
    >
      {showSpinner && (
        <Spinner overlay message="Uploading files" messageClassName="text-xl" />
      )}
      <div className="flex flex-row justify-between mb-4">
        <p className={titleCN}>File Upload</p>
        <IconButton
          onClick={handleCloseUploader}
          icon={greyCrossIcon}
          className="pr-3"
        />
      </div>
      {showDropzone && (
        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div
              className="border-dashed border cursor-pointer"
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {filesToUpload ? (
                filesToUpload.map((file) => (
                  <div key={file.name}>
                    {isLoading && (
                      <div className="flex my-6 justify-between pb-6">
                        <div className="flex items-start">
                          <div className="flex flex-row items-center ml-2">
                            <CircularProgressBar
                              value={
                                progress[file.name]
                                  ? (progress[file.name][0] /
                                      progress[file.name][1]) *
                                    100
                                  : 0
                              }
                              hideIcons
                            />
                            <p className="ml-2 font-semibold text-gray-500">
                              {file.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="flex flex-col items-center justify-center p-8 py-16">
                  <img className="mb-3 w-16" src={uploadIcon} alt="" />
                  <div className="text-semibold flex">
                    <p>Drag & Drop files or&nbsp;</p>
                    <ExternalLink>browse</ExternalLink>
                  </div>
                </div>
              )}
            </div>
          )}
        </Dropzone>
      )}
      <div>
        <div className={displayContainerCN}>
          {documents.length > 0 &&
            documents.map((doc, index) => (
              <FileCell
                doc={doc}
                index={index}
                key={doc.id}
                onEdit={onEdit}
                onRemove={onRemove}
                documentTypes={documentTypes}
                documentType={doc.documentType}
                onEditDocumentType={(val) => onEditDocumentType(val, index)}
              />
            ))}
        </div>
        <div className={displayContainerCN}>
          {documents.length > 0 && (
            <BaseButton
              title="+ Add File"
              className="items-start w-22 text-base mr-2"
              colorCN="text-brandGreen"
              onClick={() => setShowDropzone(true)}
            />
          )}
        </div>
        <div className="flex flex-row justify-end">
          <TertiaryButton
            title="Cancel"
            className="mr-4"
            onClick={handleCloseUploader}
          />
          <PrimaryButton
            title="Upload"
            className="rounded-md"
            onClick={onSubmit}
            disabled={
              documents.length === 0 ||
              !documents.every((doc) => doc?.documentType?.value)
            }
          />
        </div>
      </div>
    </div>
  );
};

PureFileUpload.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
    })
  ),
  onUpload: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  handleCloseUploader: PropTypes.func,
  /**
   * function to handle file upload
   */
  handleFileUpload: PropTypes.func,
  /**
   * shows/hides loading spinner
   */
  showSpinner: PropTypes.bool,
  documentTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onEditDocumentType: PropTypes.func,
};

PureFileUpload.defaultProps = {
  documents: [],
  handleCloseUploader: undefined,
  handleFileUpload: undefined,
  showSpinner: false,
  documentTypes: [],
  onEditDocumentType: undefined,
};

export default PureFileUpload;

import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import dropdownBaseStyle from "../../../styles/dropdowns/dropdownBaseStyle";
import DropdownOptionCheckbox from "../DropdownOptionCheckbox";
import MenuOption from "./MenuOption";
import useDropdownWithSegmentedControlData from "./useDropdownWithSegmentedControlData";

const DropdownWithSegmentedControl = ({
  segments,
  options,
  value,
  setValue,
  selectedSegment,
  onSelectSegment,
  isMulti,
  isDisabled,
  menuPlacement,
  rtl,
}) => {
  const { allSelected, handleAllSelected, handleSetValue } =
    useDropdownWithSegmentedControlData({
      selectedSegment,
      setValue,
      isMulti,
      options,
      value,
    });

  return (
    <>
      {isDisabled && Array.isArray(value) ? (
        <div className="flex flex-wrap gap-x-1 bg-es-extra-light-grey border rounded-md w-full max-h-48 pl-3 py-2 overflow-y-scroll">
          {value?.map((val) => {
            return (
              <div
                key={val?.value}
                style={{ maxWidth: "192px" }}
                className="bg-white mb-1 text-xs text-es-green px-2 py-0.5 border rounded-2xl h-fit w-fit truncate"
              >
                {val?.label}
              </div>
            );
          })}
        </div>
      ) : (
        <Select
          placeholder="Select"
          options={options}
          onChange={handleSetValue}
          value={value}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          menuPlacement={menuPlacement}
          isMulti={isMulti}
          isDisabled={isDisabled}
          components={{
            Option: (props) => <DropdownOptionCheckbox rtl={rtl} {...props} />,
            Menu: (props) => (
              <MenuOption
                segments={segments}
                selectedSegment={selectedSegment}
                onSelect={onSelectSegment}
                allSelected={allSelected}
                handleAllSelected={handleAllSelected}
                {...props}
              />
            ),
          }}
          styles={{
            ...dropdownBaseStyle,
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              maxHeight: "150px",
              overflowY: "auto",
            }),
            dropdownIndicator: (baseStyles, state) => ({
              ...baseStyles,
              display: state.isDisabled ? "none" : "",
            }),
          }}
        />
      )}
    </>
  );
};

DropdownWithSegmentedControl.defaultProps = {
  segments: [],
  options: [],
  selectedSegment: undefined,
  menuPlacement: "top",
  value: undefined,
  isMulti: false,
  isDisabled: false,
  rtl: false,
  setValue: () => {},
  onSelectSegment: () => {},
};

DropdownWithSegmentedControl.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  segments: PropTypes.arrayOf(PropTypes.shape({})),
  selectedSegment: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  value: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.string,
  ]),
  menuPlacement: PropTypes.oneOf(["top", "bottom", "auto"]),
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  rtl: PropTypes.bool,
  setValue: PropTypes.func,
  onSelectSegment: PropTypes.func,
};

export default DropdownWithSegmentedControl;

import { useCallback, useEffect } from "react";
import { ConfigurationAPI } from "@griffingroupglobal/eslib-api";
import { useQueryClient } from "react-query";
import { useAppState } from "../state/appState";
import { SET_TEMPLATES_CONFIGURATION } from "../constants";
import { configurationKeys } from "../config/reactQuery/queryKeyFactory";

/** @Deprecated use new hook */
export default (resource) => {
  const [{ templatesConfiguration, configurationLastUpdated }, dispatch] =
    useAppState();

  const queryClient = useQueryClient();

  const reload = useCallback(async () => {
    const { data } = await ConfigurationAPI.getById("templates");

    dispatch({
      type: SET_TEMPLATES_CONFIGURATION,
      templatesConfiguration: { templates: data?.templates },
      configurationLastUpdated: data.metadata.lastUpdated,
    });
  }, [dispatch]);

  useEffect(() => {
    reload();
  }, [reload]);

  const update = useCallback(
    async ({ key, updatedTemplates }) => {
      try {
        const patchBody = {
          templates: {
            ...templatesConfiguration.templates,
            [`${key}`]: updatedTemplates,
          },
        };

        const { data } = await ConfigurationAPI.patch(
          "templates",
          patchBody,
          templatesConfiguration,
          { date: configurationLastUpdated }
        );

        dispatch({
          type: SET_TEMPLATES_CONFIGURATION,
          templatesConfiguration: { templates: data.templates },
          configurationLastUpdated: data.metadata.lastUpdated,
        });
      } catch (err) {
        console.error("Error updating templates configuration");
      } finally {
        queryClient.invalidateQueries(configurationKeys("templates"));
      }

      reload();
    },
    [
      dispatch,
      reload,
      templatesConfiguration,
      configurationLastUpdated,
      queryClient,
    ]
  );

  const deleteTemplate = useCallback(
    async ({ key, id }) => {
      try {
        const { data } = await ConfigurationAPI.delete(
          `templates/${key}/${id}`
        );
        dispatch({
          type: SET_TEMPLATES_CONFIGURATION,
          templatesConfiguration: { templates: data.templates },
          configurationLastUpdated: data.metadata.lastUpdated,
        });
      } catch (err) {
        console.error("Error deleting templates configuration", err);
      } finally {
        queryClient.invalidateQueries(configurationKeys("templates"));
      }
      reload();
    },
    [dispatch, reload, queryClient]
  );

  const getLastColumnView = useCallback(
    (template) => {
      const lastColumn = template?.templates[resource]?.filter(
        (item) => item.name === "last_updated_column_view"
      )[0];
      return lastColumn;
    },
    [resource]
  );
  return [
    resource
      ? { template: templatesConfiguration?.templates[resource] }
      : templatesConfiguration,
    update,
    resource ? getLastColumnView(templatesConfiguration) : undefined,
    deleteTemplate,
  ];
};

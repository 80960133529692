import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { DEFAULT_MEDIA_EXTENSIONS } from "../../../constants";

const useDragNDropViewData = (handleAddFiles) => {
  const [hover, setHover] = useState(false);

  /**
   * Transform files dropped to the way needs to be uploaded in backend
   */
  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = acceptedFiles.map((file) => ({
        original: file,
        id: uuidv4(),
        path: file.path,
        name: file.name,
        type: file.type,
        size: file.size,
      }));

      handleAddFiles(files);
    },
    [handleAddFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: DEFAULT_MEDIA_EXTENSIONS,
  });

  return {
    onDrop,
    getRootProps,
    getInputProps,
    setHover,
    hover,
  };
};

export default useDragNDropViewData;

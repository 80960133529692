/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
import { StreamChat } from "stream-chat";
import { chatCodeKeys } from "../../../config/reactQuery/queryKeyFactory";
import useAuthenticatedQuery from "../../../hooks/useAuthenticatedQuery";
import useCurrentUser from "../../../hooks/useCurrentUser";
import Auth from "../../../helpers/Auth";
import { toastError } from "../../../stories/Components/Toast/Toast";
import queryClient from "../../../config/reactQuery/queryClient";
import { alphaSortChannels } from "../../../helpers/Chat";

const STREAM_KEY = window.runConfig?.STREAM_KEY;

export default (setSelectedChannel) => {
  const { data: currentUser } = useCurrentUser();

  const tenantId = Auth.getTenant();

  const chatConnect = async () => {
    const data = queryClient.getQueryData(chatCodeKeys);
    if (!data?.client?.user) {
      const client = new StreamChat(STREAM_KEY);

      const {
        streamToken,
        streamId,
        name: { firstName, lastName },
      } = currentUser;

      const name = `${firstName} ${lastName}`;
      const userToConnect = {
        id: streamId,
        name,
      };

      await client.connectUser(userToConnect, streamToken);

      return client;
    }
    return data?.client;
  };

  const queryChannels = async (chatUser) => {
    const batchSize = 30;

    const f = {
      team: tenantId,
      members: { $in: [currentUser?.streamId] },
      type: "messaging",
    };

    let directChannels = [];

    let teamChannels = [];

    let counter = 0;
    let lastFetchCount = batchSize;
    do {
      const direct = await chatUser.queryChannels(
        { ...f, type: "messaging" },
        { last_message_at: -1 },
        { state: true, limit: batchSize, offset: counter * batchSize }
      );
      directChannels = [...directChannels, ...direct];
      lastFetchCount = direct.length;
      counter += 1;
    } while (lastFetchCount === batchSize);

    counter = 0;
    lastFetchCount = batchSize;

    do {
      const channels = await chatUser.queryChannels(
        { ...f, type: "team" },
        { last_message_at: -1 },
        { state: true, limit: batchSize, offset: counter * batchSize }
      );
      teamChannels = [...teamChannels, ...channels];
      lastFetchCount = channels.length;
      counter += 1;
    } while (lastFetchCount === batchSize);
    setSelectedChannel(directChannels?.[0] ?? teamChannels?.[0]);
    return [alphaSortChannels(directChannels), alphaSortChannels(teamChannels)];
  };

  const fetchChat = async () => {
    const resolved = chatConnect().then(async (client) => {
      const channels = await queryChannels(client);

      return { client, channels };
    });

    return resolved;
  };
  return useAuthenticatedQuery({
    queryKey: chatCodeKeys,
    queryFn: fetchChat,
    enabled: !!currentUser?.streamToken,
    placeholderData: { client: null, channels: [[], []] },
    onError: (error) => {
      toastError("There was an error fetching chats.");
      console.error("useChannels", error);
    },
  });
};

import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router";
import { useSop } from "../../../../hooks/useSop";
import {
  ADD_CATEGORY_POPUP,
  ADD_OPEN_MODAL,
  ADD_PREFERENCE_MODAL,
  GET_SOP_DETAILS_PATH,
  TOGGLE_POSITIONED_POPUP,
} from "../../../../constants";
import { handleLinkClick } from "../../../../helpers/Utilities";
import { useModalState } from "../../../../state/modalState";
import { useAppState } from "../../../../state/appState";
import useSystemConfiguration from "../../../../hooks/useSystemConfiguration";

const useContactPreferences = ({ setPreferences, preferences }) => {
  const history = useHistory();
  const [, modalDispatch] = useModalState();
  const { data: sopData } = useSop({ selectOptions: true });
  const { data: config } = useSystemConfiguration();

  const handleOnClick = ({ link }) => {
    // legacy code stored links as link.url || link.reference
    if (link?.url) {
      return handleLinkClick(link?.url);
    }
    if (link?.reference) {
      const sopId = link?.reference?.split("/")[1];
      return history.push(GET_SOP_DETAILS_PATH(sopId));
    }
    // updated code stores link as String
    if (!link?.includes?.("Sop/")) {
      return handleLinkClick(link);
    }
    if (link?.includes?.("Sop/")) {
      const sopId = link?.split("/")[1];
      return history.push(GET_SOP_DETAILS_PATH(sopId));
    }
    return handleLinkClick(link);
  };

  const onSubmitNewPreference = (updatedPreferences) => {
    setPreferences((prev) => ({
      ...prev,
      ...updatedPreferences,
    }));
  };

  const handleAddPreference = (title, preferenceType) => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      modalData: {
        popupWidth: 400,
        item: {
          title,
          preferenceType,
          handleSubmit: onSubmitNewPreference,
          preferences,
          adminPreferences: config?.system?.preferences,
          isAdminPage: false,
        },
      },
      modalType: ADD_PREFERENCE_MODAL,
      ref: { id: uuidv4() },
    });
  };

  const handleRemoveContainer = (title, preferenceType) => {
    const currentPrefs = { ...preferences };
    delete currentPrefs[preferenceType]?.[title];

    setPreferences(currentPrefs);
  };

  const onSubmitNewCategory = (value, preferenceType) => {
    const id = uuidv4();
    let updatedPrefs = { ...preferences };
    // if preferenceType (LIKE || DISLIKE || SPECIAL) is not there
    if (!preferences?.[preferenceType]) {
      updatedPrefs = {
        ...preferences,
        [preferenceType]: {
          [value]: {
            id,
            values: [],
          },
        },
      };
      setPreferences((prev) => ({
        ...prev,
        ...updatedPrefs,
      }));
    } else {
      setPreferences((prev) => ({
        ...prev,
        [preferenceType]: {
          ...prev?.[preferenceType], // spread other subcategories ie "foods" "beverages"
          [value]: {
            id,
            values: [],
          },
        },
      }));
    }
  };

  const [, appStateDispatch] = useAppState();

  // PreferenceType = like || dislike || special
  const handleAddContainer = (preferenceType) => {
    let updatedPrefs = { ...preferences };
    if (!preferences?.[preferenceType]) {
      updatedPrefs = {
        ...updatedPrefs,
        [preferenceType]: {},
      };
    }

    appStateDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      position: {
        x:
          window.innerWidth < 1200
            ? window.innerWidth / 5
            : window.innerWidth / 2.5,
        y: window.innerHeight / 5,
      },
      popupData: {
        onSubmit: onSubmitNewCategory,
        preferenceType,
        preferences: updatedPrefs,
        isAdminPage: false,
      },
      popupType: ADD_CATEGORY_POPUP,
    });
  };

  const handleRemovePreference = ({ pref, preferenceType, title }) => {
    let updatedPrefs = { ...preferences };
    if (!preferences?.[preferenceType]?.[title]) {
      updatedPrefs = {
        ...updatedPrefs,
        [preferenceType]: {
          ...updatedPrefs?.[preferenceType],
          [title]: {
            values: [],
          },
        },
      };
    }
    const values = [
      ...updatedPrefs?.[preferenceType]?.[title]?.values?.filter(
        (item) => item.value !== pref.value
      ),
    ];
    setPreferences((prev) => ({
      ...prev,
      [preferenceType]: {
        ...prev?.[preferenceType],
        [title]: {
          ...prev?.[preferenceType]?.[title],
          values,
        },
      },
    }));
  };

  return {
    sopData,
    handleOnClick,
    handleAddContainer,
    handleRemoveContainer,
    handleRemovePreference,
    handleAddPreference,
  };
};

export default useContactPreferences;

import React from "react";
import ModalWrapper from "../ModalWrapper";
import PrimaryButton from "../../Buttons/PrimaryButton";
import useEsModalData from "../useEsModalData";
import alertIcon from "../../../assets/images/alertIcon.svg";

const Alert = ({ modalData }) => {
  /**
   * propmt - the prompt to be displayed
   */
  const { title, prompt } = modalData ?? {
    title: "Warning",
    prompt: "This is an alert!",
  };

  const { closeModal: close } = useEsModalData(null, modalData);

  return (
    <ModalWrapper
      width="454px"
      modalData={modalData}
      resourceName={modalData?.item?.title ?? "Alert"}
      title={
        <div className="flex items-center gap-2">
          <img src={alertIcon} className="w-8 h-8" alt="alert-icon" />
          {title}
        </div>
      }
      hideActions
      alert
      closeOnOverlayClick
      buttons={
        <div className="flex px-8 gap-4 w-full justify-end">
          <PrimaryButton
            saveButton
            saveButtonTitle="Ok"
            onClick={close}
            className="px-3"
          />
        </div>
      }
    >
      <div className="text-center text-gray-450">{prompt}</div>
    </ModalWrapper>
  );
};

export default Alert;

import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import CrossButton from "../CrossButton/CrossButton";
import SelectOne from "../SelectOne/SelectOne";
import Input from "../Input/Input";
import IconButton from "../Buttons/IconButton";
import RadioButton from "../Buttons/RadioButton";
import linkGreenIcon from "../../assets/images/linkGreenIcon.svg";
import linkGrayIcon from "../../assets/images/linkGrayIcon.svg";
import TertiaryButton from "../Buttons/TertiaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";
import BaseButton from "../Buttons/BaseButton";
import MaintenanceSopSelect from "../AssetForms/MaintenanceSopSelect";

import "./styles.css";
import { simpleUrl } from "../../../helpers/FormValidations";

function PreferenceItem({
  id,
  label,
  type,
  value,
  link,
  linkType,
  onChange,
  onRemove,
  options,
  isCustom,
  saving,
  sopData,
  selectPlaceHolder,
}) {
  const [openLink, setOpenLink] = useState(false);
  const [currentLink, setCurrentLink] = useState(link);
  const [step, setStep] = useState();
  const inputRef = useRef(null);
  const onSopStepChange = useCallback((val) => {
    setCurrentLink(val?.sop);
    setStep(val);
  }, []);

  useEffect(() => {
    setCurrentLink(link);
  }, [link]);

  useEffect(() => {
    const { name, label1, sop } =
      sopData?.filter((s) => s.sop === link)[0] || {};
    setStep({ description: name, label: label1, sop });
  }, [link, sopData]);

  const handleValueChange = useCallback(
    (newValue) => {
      onChange(id, label, newValue, link, linkType);
    },
    [id, label, link, linkType, onChange]
  );

  const handleLinkChange = useCallback(
    (newLink) => {
      onChange(id, label, value, newLink, linkType);
    },
    [id, label, linkType, onChange, value]
  );

  const handleSelectChange = useCallback(
    (e) => {
      onChange(id, label, value, link, e.target.value);
    },
    [id, label, link, onChange, value]
  );

  const removeValue = useCallback(() => {
    onRemove(id);
  }, [id, onRemove]);

  const handleOpenLink = useCallback(() => {
    setOpenLink((prev) => !prev);
  }, []);

  const removeLink = useCallback(() => {
    onChange(id, label, value, "", undefined);
  }, [id, label, onChange, value]);

  const onSaveAndClose = useCallback(() => {
    handleLinkChange(currentLink);
    handleOpenLink();
  }, [currentLink, handleLinkChange, handleOpenLink]);

  const onCancel = useCallback(() => {
    removeLink();
    handleOpenLink();
  }, [handleOpenLink, removeLink]);

  const onRemoveLinkAndClose = useCallback(() => {
    removeLink();
    handleOpenLink();
  }, [handleOpenLink, removeLink]);

  const handleBlur = () => {};

  return (
    <div className="flex flex-col w-full h-full mt-6">
      <div className="flex flex-row justify-between mb-4 items-center">
        {label ? (
          <p className="text-md font-bold w-1/3 flex-wrap text-gray-450 flex-start flex mb-1">
            {label}
          </p>
        ) : (
          <SelectOne
            placeholder={selectPlaceHolder}
            onChange={(val) => {
              onChange(id, val?.label, value, link, linkType, val?.id);
              inputRef.current.focus();
            }}
            options={options}
            hideText
            type={type}
            disabled={saving}
            className="text-md font-bold flex-wrap text-gray-450 mr-2 flex-start flex mb-1"
          />
        )}
        <div className="flex flex-end items-center w-2/3 justify-center">
          <Input
            onBlur={() => {}}
            className="mr-2"
            value={value}
            onChange={handleValueChange}
            placeholder={`${type === "dislike" ? "Disliked" : type} ${label}`}
            validation={yup.string()}
            disableClear
            onRemove={isCustom && removeValue}
            forwardedRef={inputRef}
            disabled={saving}
            mainClassName="w-full"
            inputContainerClassName="bg-brandGreen bg-opacity-10"
            inputClassName="bg-transparent text-gray-400 ml-4 w-full"
          />
          <IconButton
            className={`${isCustom ? "right-12" : "right-4"}`}
            icon={link !== "" ? linkGreenIcon : linkGrayIcon}
            onClick={handleOpenLink}
            disabled={saving}
          />
        </div>
      </div>
      {openLink && (
        <div className="border border-green-300 rounded-md p-6 mr-2 mt-1 mb-2">
          <div className="flex justify-between mb-3">
            <p className="font-semibold text-black text-base">Add Link</p>
            <div className="flex flex-row">
              {link && (
                <BaseButton
                  style={{ minWidth: 80 }}
                  className="text-brandGreen font-semibold text-base px-4"
                  title="Remove Link"
                  onClick={onRemoveLinkAndClose}
                />
              )}
              <CrossButton onClick={handleOpenLink} />
            </div>
          </div>
          <div>
            <div className="mb-6">
              <RadioButton
                className="mb-2"
                isChecked={linkType === "SOP"}
                label="Standard Operating Procedure (SOP)"
                value="SOP"
                onChange={handleSelectChange}
                labelClassName="text-xs mb-2"
              />
              {linkType === "SOP" && (
                <div className="ml-6 text-black">
                  <MaintenanceSopSelect
                    sopData={sopData}
                    handleSopStepChange={onSopStepChange}
                    step={step}
                    className="ml-6 mt-2"
                    handleBlur={handleBlur}
                  />
                </div>
              )}
            </div>
            <hr />
            <div className="mt-6">
              <RadioButton
                isChecked={linkType === "Website"}
                label="Website URL"
                value="Website"
                onChange={handleSelectChange}
                labelClassName="text-xs"
              />
              {linkType === "Website" && linkType !== "SOP" && (
                <Input
                  onBlur={() => {}}
                  icon="static/media/src/stories/assets/images/magnify_glass_icon..svg"
                  placeholder="Website Url"
                  className="ml-6 mt-2"
                  inputClassName="ESInput pl-4"
                  value={`${!currentLink?.includes("Sop/") ? currentLink : ""}`}
                  onChange={setCurrentLink}
                  validation={simpleUrl}
                  showValidationErrorAtBottom
                />
              )}
            </div>
          </div>
          <div className="flex flex-row justify-end pt-6">
            <div className="mr-2">
              <TertiaryButton title="Cancel" onClick={onCancel} />
            </div>
            <div>
              <PrimaryButton
                title="Save"
                style={{ minWidth: 100 }}
                onClick={onSaveAndClose}
                disabled={
                  !(currentLink?.includes("Sop/") || currentLink?.length)
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

PreferenceItem.propTypes = {
  id: PropTypes.string,
  /**
   * the Item label
   */
  label: PropTypes.string,
  /**
   * the Item type
   */
  type: PropTypes.string,
  value: PropTypes.string,
  link: PropTypes.string,
  onChange: PropTypes.func,
  linkType: PropTypes.string,
  selectPlaceHolder: PropTypes.string,
  onRemove: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      link: PropTypes.string,
      linkType: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  isCustom: PropTypes.bool,
  saving: PropTypes.bool,
  sopData: PropTypes.arrayOf(PropTypes.shape({})),
};

PreferenceItem.defaultProps = {
  id: "",
  label: "",
  type: "",
  value: "",
  link: "",
  selectPlaceHolder: undefined,
  linkType: undefined,
  onChange: undefined,
  onRemove: undefined,
  options: undefined,
  isCustom: false,
  saving: false,
  sopData: [],
};

export default PreferenceItem;

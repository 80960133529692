import { useMutation, useQueryClient } from "react-query";
import removeFromCalendarStore from "../../helpers/Calendar/removeFromCalendarStore";
import { useAppState } from "../../state/appState";
import { removeFromCalendarHandler } from "./calendarHandlers";
import removeFromCalendarResponder from "./calendarResponders/removeFromCalendarResponder";

const useRemoveFromCalendar = () => {
  const queryClient = useQueryClient();
  const [{ calendarData }] = useAppState();

  const deleteMutation = useMutation(
    removeFromCalendarHandler,
    removeFromCalendarResponder(queryClient, calendarData)
  );

  /**
   * Currently there is no use case to delete from a task list view, so no new requests need to be made. if that changes this file is set up to be modified
   *
   * @param {Props} Item can be Task or Event
   * @param {boolean} newRequest optional defaults to true
   */
  const removeFromCalendar = async (props, newRequest = true) => {
    if (!props.args) {
      return console.error(
        "args are required: populate with item you are removing"
      );
    }

    if (!props.operation) {
      return console.error(
        "operation required: populate with $non-recurring, $all, $single or $future"
      );
    }

    if (newRequest === false) {
      return removeFromCalendarStore({
        queryClient,
        calendarData,
        variables: props,
        data: props.args.item,
      });
    }

    const response = await deleteMutation.mutateAsync(props);
    return response;
  };

  const {
    isLoading: removeFromCalendarLoading,
    isError: hasRemoveFromCalendarError,
    error: removeFromCalendarError,
    isSuccess: removeFromCalendarSuccess,
  } = deleteMutation;

  return {
    removeFromCalendar,
    removeFromCalendarLoading,
    hasRemoveFromCalendarError,
    removeFromCalendarSuccess,
    removeFromCalendarError,
  };
};

export default useRemoveFromCalendar;

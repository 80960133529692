import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Api from "@griffingroupglobal/eslib-api";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Landing from "./Pages/Landing";
import Login from "./Pages/Login";
import MainContainer from "./Pages/MainContainer";
import Signup from "./Pages/SignUp/SignUp";
import ProtectedRoute from "./Routers/ProtectedRoute";
import rqConfig from "./config/reactQuery/queryClient";
import {
  HEALTH_PATH,
  LOGIN_PATH,
  SIGNUP_LANDING_PATH,
  SIGNUP_PATH,
} from "./constants";
import ErrorBoundaries from "./errors/ErrorBoundary";
import "./fonts.css";
import { setGlobalYupErrorMessages } from "./helpers/Validators";
import "./index.css";
import WithAxiosInterceptors from "./interceptors/WithAxiosInterceptors";
import { AppStateProvider } from "./state/appState";
import { ModalWindowProvider } from "./state/modalState";

setGlobalYupErrorMessages();

const { STRIPE_PUBLISHABLE_KEY } = window.runConfig || {};

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const App = () => {
  useEffect(() => {
    const { API_HOST } = window.runConfig;
    Api.config.init({
      rootUrl: API_HOST || "https://api.es-feature.io",
      storage: {},
    });
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <Elements stripe={stripePromise}>
        <AppStateProvider>
          <ModalWindowProvider>
            <WithAxiosInterceptors>
              <Switch>
                {/* Health route for DevOps to keep tabs on the container */}
                <Route path={HEALTH_PATH}>
                  <h3>The App is Healthy</h3>
                </Route>
                <ProtectedRoute
                  path={SIGNUP_LANDING_PATH}
                  component={Landing}
                />
                <ProtectedRoute path={SIGNUP_PATH} component={Signup} />
                <ProtectedRoute path={LOGIN_PATH} component={Login} />
                <ProtectedRoute path="/" component={MainContainer} needAuth />
              </Switch>
            </WithAxiosInterceptors>
          </ModalWindowProvider>
        </AppStateProvider>
      </Elements>
      <ToastContainer className="w-80 h-10" />
    </DndProvider>
  );
};

const AppRouter = () => (
  <BrowserRouter>
    <ErrorBoundaries>
      <QueryClientProvider client={rqConfig}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundaries>
  </BrowserRouter>
);

const root = createRoot(document.getElementById("app"));
root.render(<AppRouter />);

if (module.hot) {
  module.hot.accept();
}

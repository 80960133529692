import React from "react";
import PropTypes from "prop-types";

const HighlightPopup = ({ comment }) =>
  comment.text && (
    <div className="Highlight__popup">
      {comment.emoji} {comment.text}
    </div>
  );

HighlightPopup.propTypes = {
  /**
   * comment data
   */
  comment: PropTypes.shape({
    text: PropTypes.string,
    emoji: PropTypes.string,
  }),
};

HighlightPopup.defaultProps = {
  comment: undefined,
};
export default HighlightPopup;

import React from "react";
import Media from "../../../../../Pages/ServiceRequest/Media";
import useMediaSectionData from "./useMediaSectionData";

const MediaSection = ({
  editingMode,
  taskForm,
  currentTask,
  filesState,
  handleFilesToAdd,
  handleFilesToRemove,
  noTitle,
}) => {
  const { hasMediaToShow, hasNoMediaToShow } = useMediaSectionData({
    editingMode,
    currentTask,
  });
  // if not in editing mode and there are files saved to the task, show the media component
  if (hasMediaToShow) {
    return (
      <Media
        requestForm={editingMode ? taskForm : currentTask}
        isEditing={editingMode}
        filesState={filesState}
        handleFilesToAdd={handleFilesToAdd}
        handleFilesToRemove={handleFilesToRemove}
        noTitle={noTitle}
      />
    );
  }

  // if not in editing mode and there are no files saved to the task, don't show the media component
  if (hasNoMediaToShow) {
    return (
      <div className="flex">
        {!noTitle && (
          <p style={{ width: "200px" }} className="text-lg font-semibold">
            {" "}
            Media
          </p>
        )}
        <p className="text-sm text-gray-450">
          There is no media saved to this task
        </p>
      </div>
    );
  }

  // if in editing mode the media component should always be shown
  return (
    <Media
      requestForm={editingMode ? taskForm : currentTask}
      isEditing={editingMode}
      filesState={filesState}
      handleFilesToAdd={handleFilesToAdd}
      handleFilesToRemove={handleFilesToRemove}
      noTitle={noTitle}
    />
  );
};

export default MediaSection;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppState } from "../../../state/appState";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useServiceRequestTableFormat from "../../../hooks/useServiceRequestTableFormat";
import { SET_REQUEST_MODAL_STATUS } from "../../../constants";
import useAssociationsHelper from "../../../hooks/useAssociationsHelper";

const useServiceRequestOverview = (association, asset, setButtonActions) => {
  const [, dispatchAppState] = useAppState();
  const { data: currentUser } = useCurrentUser();
  const { getParentAssociation } = useAssociationsHelper();

  // Query selector to get requests by association
  const { data: serviceRequestTickets = [], isLoading } =
    useServiceRequestTableFormat({
      association,
      asset,
    });

  // State to hold service request id selected
  const [requestId, setRequestId] = useState();

  const canCreate = useMemo(
    () =>
      currentUser?.isAdmin ||
      currentUser?.hasPermission?.("ticket", "can_create"),
    [currentUser]
  );

  const handleOpenModal = useCallback(
    (open = true) => {
      let parentAssociationReference = association;

      if (asset) {
        parentAssociationReference = getParentAssociation(asset)?.reference;
      }

      dispatchAppState({
        type: SET_REQUEST_MODAL_STATUS,
        associationLock: parentAssociationReference,
        assetLock: asset,
        open,
      });
    },
    [dispatchAppState, getParentAssociation, association, asset]
  );

  // Show "Add Request" button in top header, if SR list is rendered inside association page (P/P/A)
  useEffect(() => {
    if (requestId || !setButtonActions) return;

    if (canCreate) {
      setButtonActions([
        {
          title: "Add Request",
          onClick: handleOpenModal,
          tabAction: true,
        },
      ]);
    }
  }, [requestId, canCreate, setButtonActions, handleOpenModal]);

  return {
    canCreate,
    serviceRequestTickets,
    isLoading,
    requestId,
    setRequestId,
    handleOpenModal,
  };
};

export default useServiceRequestOverview;

import { useEffect, useState } from "react";
import { useAppState } from "../state/appState";
import { SET_PAGE_STATE } from "../constants";

const usePagePersistance = () => {
  const [{ pageState }, appStateDispatch] = useAppState();
  const [storageLoading, setStorageLoading] = useState(true);

  useEffect(() => {
    const savedPageState = localStorage.getItem("pageData");
    if (savedPageState) {
      appStateDispatch({
        type: SET_PAGE_STATE,
        payload: JSON.parse(savedPageState),
      });
    }

    setStorageLoading(false);
  }, [appStateDispatch]);

  const setPersistentPageItem = (key, value) => {
    appStateDispatch({
      type: SET_PAGE_STATE,
      payload: { ...pageState, [key]: value },
    });
    localStorage.setItem(
      "pageData",
      JSON.stringify({ ...pageState, [key]: value })
    );
  };

  return { pageState, storageLoading, setPersistentPageItem };
};

export default usePagePersistance;

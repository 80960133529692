import { useQueryClient } from "react-query";
import removeFromTaskListStore from "../../helpers/Calendar/removeFromTaskListStore";

const useRemoveFromTaskList = () => {
  const queryClient = useQueryClient();

  /**
   * Currently there is no use case to delete from a task list view, so no new requests need to be made. Currently you need to pass false for newRequest
   *
   * @param {Task} task
   * @param {boolean} newRequest optional defaults to true
   */
  const deleteFromTaskList = (props, newRequest = false) => {
    if (!props) {
      console.error(
        "error deleting from tasklist: min of 1 arguments required"
      );
      return;
    }

    if (!props.args) {
      console.error(
        "error deleting from tasklist: A task.args are required in the first argument"
      );
      return;
    }

    if (newRequest === false) {
      removeFromTaskListStore({ queryClient, variables: props });
    }
  };

  return { deleteFromTaskList };
};

export default useRemoveFromTaskList;

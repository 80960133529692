import moment from "moment";
import { RRule } from "rrule";

/**
 * Get the next occurrence date after now
 * NOTE: will set the start time to be 12am so that the entire day is used.
 *
 * @param rRuleString
 * @return Date
 */
const getNextOccurrenceFromRrule = (rRuleString) => {
  const now = new Date();
  // Set the time to 12:00 AM (midnight)
  now.setUTCHours(0, 0, 0, 0);

  const rruleObj = RRule.fromString(rRuleString);

  return rruleObj.after(now);
};

/**
 * check if the instance is current:
 * NOTE: check if instanceStartDate is same as the next rrule occurrence or in future
 *
 * @param rRuleString
 * @return Date
 */
const isCurrentTask = (instanceStartDate, recurrence) => {
  const currentTaskDate = getNextOccurrenceFromRrule(recurrence);

  return moment(instanceStartDate).isSameOrAfter(currentTaskDate);
};

export default isCurrentTask;

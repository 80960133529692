import React from "react";
import { EXPENSE_CATEGORIES, settingsList } from "../../constants";
import objectHasProperties from "../../helpers/Utilities/objectHasProperties";
import PrimaryButton from "../../stories/Components/Buttons/PrimaryButton";
import TertiaryButton from "../../stories/Components/Buttons/TertiaryButton";
import QualitificationsSection from "../../stories/Components/DocumentsSettings/QuaitificationsSection";
import ChartOfAccountsSection from "../../stories/Components/FinancialsSettings/ChartOfAccountsSection";
import CSICodesAndMappingSection from "../../stories/Components/FinancialsSettings/CSICodesAndMappingSection";
import DivisionCodesSection from "../../stories/Components/FinancialsSettings/DivisionCodesSection";
import PayrollPeriodsSection from "../../stories/Components/FinancialsSettings/PayrollPeriodsSection";
import RateSheetSection from "../../stories/Components/FinancialsSettings/RateSheetSection";
import TaxesRatesSection from "../../stories/Components/FinancialsSettings/TaxesRatesSection";
import TimesheetPeriodsSection from "../../stories/Components/FinancialsSettings/TimesheetPeriodsSection";
import ManagementAssetTypes from "../../stories/Components/ManagementSettings/ManagementAssetTypes";
import ManagementAttributes from "../../stories/Components/ManagementSettings/ManagementAttributes";
import ManagementContactDisciplines from "../../stories/Components/ManagementSettings/ManagementContactDisciplines";
import ManagementRfiTemplateSection from "../../stories/Components/ManagementSettings/ManagementRfiTemplateSection";
import ManagementSubmittalTemplateSubSection from "../../stories/Components/ManagementSettings/ManagementSubmittalTemplateSubSection";
import ManagementTags from "../../stories/Components/ManagementSettings/ManagementTags";
import ManagementTaskDurationSection from "../../stories/Components/ManagementSettings/ManagementTaskDurationSection";
import ManagementWorkflowTemplateSubSection from "../../stories/Components/ManagementSettings/ManagementWorkflowTemplateSubSection";
import RatePerMile from "../../stories/Components/SettingsAndConfiguration/RatePerMile";
import SelectSection from "../../stories/Components/SettingsAndConfiguration/SelectSection";
import SettingsTable from "../../stories/Components/SettingsAndConfiguration/SettingsTable";
import BenefitsSection from "../../stories/Components/SystemSettings/BenefitsSection";
import HolidaysSection from "../../stories/Components/SystemSettings/HolidaysSection";
import HoursOfOperationSection from "../../stories/Components/SystemSettings/HoursOfOperationSection";
import PreferencesLibrarySection from "../../stories/Components/SystemSettings/PreferencesLibrarySection";
import PtoSection from "../../stories/Components/SystemSettings/PtoSection";
import UnitOfMeasurementSection from "../../stories/Components/SystemSettings/UnitOfMeasurementSection";
import BackButton from "../../stories/Components/WidgetEditorView/Header/BackButton";

const PREFERENCELIBRARY = "Preferences Library";

const SettingsAndConfigurationList = ({
  managementSettings,
  dispatchManagementSettings,
  attributeSettings,
  dispatchAttributeSettings,
  systemSettings,
  dispatchSystemSettings,
  financialsSettings,
  dispatchFinancialsSettings,
  documentsSettings,
  dispatchDocumentsSettings,
  tagsSettings,
  dispatchTagsSettings,
  navigateToHolidaysSection,
  onCancel,
  onSave,
  isSaving,
  isLoading,
  settingDetails,
  setSettingDetails,
  noChangesMade,
  isValid,
  setIsValid,
  resetSystemSettings,
}) => {
  const settingsDetailsContent = () => {
    switch (settingDetails.title) {
      case "Asset Categories":
        return (
          <ManagementAssetTypes
            managementSettings={managementSettings}
            dispatchManagementSettings={dispatchManagementSettings}
          />
        );
      case "Attributes":
        return (
          <ManagementAttributes
            attributeSettings={attributeSettings}
            dispatchAttributeSettings={dispatchAttributeSettings}
          />
        );
      case "Benefits":
        return (
          <BenefitsSection
            systemSettings={systemSettings}
            dispatchSystemSettings={dispatchSystemSettings}
          />
        );
      case "Budgets Units of Measure":
        return (
          <UnitOfMeasurementSection
            systemSettings={systemSettings}
            dispatchSystemSettings={dispatchSystemSettings}
          />
        );
      case "Chart of Accounts":
        return (
          <ChartOfAccountsSection
            financialsSettings={financialsSettings}
            dispatchFinancialsSettings={dispatchFinancialsSettings}
          />
        );
      case "Contact Disciplines":
        return (
          <ManagementContactDisciplines
            managementSettings={managementSettings}
            dispatchManagementSettings={dispatchManagementSettings}
          />
        );
      case "CSI Codes & Mapping":
        return (
          <CSICodesAndMappingSection
            financialsSettings={financialsSettings}
            dispatchFinancialsSettings={dispatchFinancialsSettings}
          />
        );
      case "Division Codes":
        return (
          <DivisionCodesSection
            financialsSettings={financialsSettings}
            dispatchFinancialsSettings={dispatchFinancialsSettings}
          />
        );
      case "Expense Categories":
        return (
          <SelectSection
            selection={{
              selectionName: "Category",
              selectionValue: EXPENSE_CATEGORIES,
              selectionItemValue: "display",
            }}
            settings={{
              settings: financialsSettings,
              dispatchSettings: dispatchFinancialsSettings,
            }}
            setIsValid={setIsValid}
          />
        );
      case "Holidays":
        return (
          <HolidaysSection
            systemSettings={systemSettings}
            dispatchSystemSettings={dispatchSystemSettings}
          />
        );
      case "Level Types":
        return (
          <SelectSection
            selection={{
              selectionName: "Level Type",
              selectionValue: "propertyLevelTypes",
              selectionItemValue: "display",
            }}
            settings={{
              settings: managementSettings,
              dispatchSettings: dispatchManagementSettings,
            }}
            setIsValid={setIsValid}
          />
        );
      case "Paid Time Off":
        return (
          <PtoSection
            systemSettings={systemSettings}
            dispatchSystemSettings={dispatchSystemSettings}
            setIsValid={setIsValid}
          />
        );
      case "Payroll Period":
        return (
          <PayrollPeriodsSection
            financialsSettings={financialsSettings}
            dispatchFinancialsSettings={dispatchFinancialsSettings}
          />
        );
      case PREFERENCELIBRARY:
        return (
          <PreferencesLibrarySection
            systemSettings={systemSettings}
            dispatchSystemSettings={dispatchSystemSettings}
          />
        );
      case "Project Types":
        return (
          <SelectSection
            selection={{
              selectionName: "Project Type",
              selectionValue: "projectTypes",
              selectionItemValue: "display",
            }}
            settings={{
              settings: managementSettings,
              dispatchSettings: dispatchManagementSettings,
            }}
            setIsValid={setIsValid}
          />
        );
      case "Project Inspection Types":
        return (
          <SelectSection
            selection={{
              selectionName: "Project Inspection Type",
              selectionValue: "projectInspectionTypes",
              selectionItemValue: "display",
            }}
            settings={{
              settings: managementSettings,
              dispatchSettings: dispatchManagementSettings,
            }}
            setIsValid={setIsValid}
          />
        );
      case "Property Types":
        return (
          <SelectSection
            selection={{
              selectionName: "Property Type",
              selectionValue: "propertyTypes",
              selectionItemValue: "display",
            }}
            settings={{
              settings: managementSettings,
              dispatchSettings: dispatchManagementSettings,
            }}
            setIsValid={setIsValid}
          />
        );
      case "Qualifications & Exclusions":
        return (
          <QualitificationsSection
            documentsSettings={documentsSettings}
            dispatchDocumentsSettings={dispatchDocumentsSettings}
          />
        );
      case "Rate Sheet":
        return (
          <RateSheetSection
            financialsSettings={financialsSettings}
            dispatchFinancialsSettings={dispatchFinancialsSettings}
            navigateToHolidaysSection={navigateToHolidaysSection}
          />
        );
      case "Space Types":
        return (
          <SelectSection
            selection={{
              selectionName: "Space Type",
              selectionValue: "propertySpaceTypes",
              selectionItemValue: "display",
            }}
            settings={{
              settings: managementSettings,
              dispatchSettings: dispatchManagementSettings,
            }}
            setIsValid={setIsValid}
          />
        );
      case "Standard Operating Procedures (SOP) Types":
        return (
          <SelectSection
            selection={{
              selectionName: "Type",
              selectionValue: "sopCategories",
              selectionItemValue: "display",
            }}
            settings={{
              settings: systemSettings,
              dispatchSettings: dispatchSystemSettings,
            }}
            setIsValid={setIsValid}
          />
        );
      case "Submittal Template":
        return (
          <ManagementSubmittalTemplateSubSection
            managementSettings={managementSettings}
            dispatchManagementSettings={dispatchManagementSettings}
          />
        );
      case "Submittal Types":
        return (
          <SelectSection
            selection={{
              selectionName: "Submittal Type",
              selectionValue: "submittalTypes",
              selectionItemValue: "display",
            }}
            settings={{
              settings: managementSettings,
              dispatchSettings: dispatchManagementSettings,
            }}
            setIsValid={setIsValid}
          />
        );
      case "Tags":
        return (
          <ManagementTags
            tagsSettings={tagsSettings}
            dispatchTagsSettings={dispatchTagsSettings}
          />
        );
      case "Tax Rates":
        return (
          <TaxesRatesSection
            financialsSettings={financialsSettings}
            dispatchFinancialsSettings={dispatchFinancialsSettings}
          />
        );
      case "RFI Template":
        return (
          <ManagementRfiTemplateSection
            managementSettings={managementSettings}
            dispatchManagementSettings={dispatchManagementSettings}
          />
        );
      case "Task Duration":
        return (
          <ManagementTaskDurationSection
            managementSettings={managementSettings}
            dispatchManagementSettings={dispatchManagementSettings}
          />
        );
      // case "RFP Template":
      //   return (
      //   );
      case "Hours of Operation":
        return (
          <HoursOfOperationSection
            systemSettings={systemSettings}
            dispatchSystemSettings={dispatchSystemSettings}
          />
        );
      // case "Submission Requirements for RFP":
      //   return (
      //   );
      case "Timesheet Period":
        return (
          <TimesheetPeriodsSection
            financialsSettings={financialsSettings}
            dispatchFinancialsSettings={dispatchFinancialsSettings}
          />
        );
      case "Workflow Template":
        return (
          <ManagementWorkflowTemplateSubSection
            managementSettings={managementSettings}
            dispatchManagementSettings={dispatchManagementSettings}
          />
        );
      case "World Clock":
        return (
          <SelectSection
            selection={{
              selectionName: "World Clock Zone",
              selectionValue: "cities",
              selectionItemValue: "city",
              selectionSecondItemValue: "cityCode",
            }}
            settings={{
              settings: systemSettings,
              dispatchSettings: dispatchSystemSettings,
            }}
            setIsValid={setIsValid}
          />
        );
      case "Rate Per Mile":
        return (
          <RatePerMile
            financialsSettings={financialsSettings}
            dispatchFinancialsSettings={dispatchFinancialsSettings}
          />
        );
      case "Service Request Types":
        return (
          <SelectSection
            selection={{
              selectionName: "Service Request Type",
              selectionValue: "ticket",
              selectionItemValue: "display",
            }}
            settings={{
              settings: managementSettings,
              dispatchSettings: dispatchManagementSettings,
            }}
            setIsValid={setIsValid}
          />
        );
      default:
        return null;
    }
  };

  const onClick = (item) => {
    setSettingDetails(item);
  };

  return objectHasProperties(settingDetails) ? (
    <>
      <div className="flex flex-row justify-between">
        <BackButton
          onBackPressed={() =>
            setSettingDetails(settingDetails.customBackNavigation ?? {})
          }
        />
        <div className="flex flex-row">
          <TertiaryButton
            title="Cancel"
            className="mx-2"
            onClick={() => {
              onCancel();
              resetSystemSettings();
            }}
            disabled={isSaving || noChangesMade}
          />
          <PrimaryButton
            title="Save Changes"
            onClick={onSave}
            disabled={isLoading || !isValid || noChangesMade}
          />
        </div>
      </div>
      <div className="pt-6">
        <p className="ESTitle">{settingDetails?.title}</p>
        {settingDetails?.title !== PREFERENCELIBRARY && ( // Preferences Design doesn't have description
          <p>{settingDetails?.description}</p>
        )}
        {settingsDetailsContent()}
      </div>
    </>
  ) : (
    <SettingsTable data={settingsList} onClick={onClick} />
  );
};

export default SettingsAndConfigurationList;

const parseDataForRequestModal = (row, reqData) => {
  const metadata = reqData?.timeoffDict[row?.values?.user]?.original?.metadata;
  const originalData = reqData?.timeoffDict[row?.values?.user]?.original;

  if (!metadata || !originalData || !row?.original) {
    return {
      original: null,
      metadata: null,
      id: "",
      error: true,
    };
  }

  return {
    original: row.original,
    metadata,
    id: originalData.id,
    error: false,
  };
};

export default parseDataForRequestModal;

/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useAppState } from "../../../../state/appState";
import { isToday } from "../../../../helpers/Calendar";
import attachmentIcon from "../../../../stories/assets/images/attachment_icon_updated.svg";
import ReloadButton from "../ReloadButton";
import useMessageBodyData from "./useMessageBodyData";

const MessageBody = ({
  text,
  created_at,
  userId,
  userName,
  attachments,
  reply,
  resend,
  id,
}) => {
  const [{ usersStreamDict }] = useAppState();
  const { handleClick, downloadAttachment } = useMessageBodyData();
  const date = moment(created_at);

  return (
    <div className="text-gray-450" id={`${id}-msg-body`}>
      <p style={{ marginTop: `${reply ? 0 : -5}px` }}>
        <span
          className={`font-semibold tracking-wider text-gray-500 text-${
            reply ? "xs" : "md"
          }`}
        >
          {usersStreamDict?.[userId]?.name ?? userName}
        </span>
        <span className="ml-2 font-medium text-xxs align-middle">
          {date.format(isToday(date) ? "h:mm a" : "M/DD h:mm a")}
        </span>
      </p>
      <div
        className="flex gap-2 items-center"
        style={{ overflowWrap: "anywhere" }}
      >
        <p className={`text-${reply ? "xs" : "sm"} font-light`}>{text}&nbsp;</p>
        {resend && <ReloadButton onClick={resend} />}
      </div>
      {attachments.map((item, i) => (
        <button
          key={`message-${id}-file-${i}`}
          type="button"
          onClick={(e) => handleClick(e, item)}
        >
          {item.mime_type?.includes("video") ||
          item.originalFile?.type?.includes("video") ? (
            <video
              className="rounded-lg mt-2 cursor-pointer"
              style={{ maxHeight: "200px", maxWidth: "70%" }}
              autoPlay={false}
              preload="metadata"
              controls
            >
              <track kind="captions" />
              <source
                src={item.asset_url}
                type={
                  item.mime_type === "video/quicktime"
                    ? "video/mp4"
                    : item.mime_type
                }
              />
              Your browser does not support the video tag.
            </video>
          ) : (
            <div className="flex w-full gap-2 cursor-pointer items-center my-2">
              <img
                className={`rounded-lg  ${
                  item.mime_type?.includes("image") ||
                  item.type?.includes("image")
                    ? "shadow-light-lift object-cover"
                    : "w-4 h-4"
                }`}
                style={{ maxHeight: "200px", maxWidth: "70%" }}
                alt={item.fallback}
                key={item.fallback}
                src={
                  item.mime_type?.includes("image") ||
                  item.type?.includes("image")
                    ? item.image_url ?? item.asset_url
                    : attachmentIcon
                }
              />
              {!item.mime_type?.includes("image") && (
                <button
                  className="truncate text-xs hover:underline"
                  type="button"
                  onClick={() => downloadAttachment(item)}
                >
                  {item?.title ?? item?.name}
                </button>
              )}
            </div>
          )}
        </button>
      ))}
    </div>
  );
};

MessageBody.propTypes = {
  text: PropTypes.string,
  created_at: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  userId: PropTypes.string,
  attachments: PropTypes.arrayOf(PropTypes.shape({})),
  reply: PropTypes.bool,
  resend: PropTypes.func,
  id: PropTypes.string,
  userName: PropTypes.string,
};

MessageBody.defaultProps = {
  text: undefined,
  created_at: undefined,
  userId: undefined,
  attachments: [],
  reply: false,
  resend: undefined,
  id: undefined,
  userName: "ES User",
};

export default MessageBody;

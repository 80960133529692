// Framework Tools
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Components
import IconButton from "../../Buttons/IconButton";
import RequestHistoryActivityCell from "./RequestHistoryActivityCell";
import RequestGridViewDurationCell from "../RequestGridView/RequestGridViewDurationCell";
import RequestGridViewInitiateCell from "../RequestGridView/RequestGridViewInitiateCell";

// Images
import arrowRightGray from "../../../assets/images/arrowRightGray.svg";
import { useAppState } from "../../../../state/appState";
import TertiaryHeader from "../../TextHeaders/TertiaryHeader";

export default function RequestHistory({
  requestData,
  requestHistory,
  commentMap,
  stepDict,
}) {
  // State control for viewing activity connected to history
  const [viewActivity, setViewActivity] = useState(false);
  const [{ userDict }] = useAppState();
  const [history, setHistory] = useState([]);

  useEffect(() => {
    setHistory(requestHistory?.[1]);
  }, [requestHistory]);

  // function that takes in an array of userRef User/123 and returns an array of objects for each user
  const getUserInfo = (userRef) => {
    const userInfo = userDict[userRef];
    return userInfo;
  };

  return (
    <div className="flex flex-col my-4">
      <div className="flex w-full border justify-between items-center p-4 h-24  bg-gray-100 rounded-md">
        <div className="flex w-2/3 justify-between">
          <div className="flex flex-col justify-center items-start">
            <p className=" text-xl">
              Round {requestHistory?.[0] || "unspecified"}
            </p>
          </div>
          <div className="flex flex-col justify-start items-start">
            <RequestGridViewInitiateCell
              initiationDate={requestData?.initiated?.date}
              initiatedBy={getUserInfo(requestData?.initiated?.user)}
            />
          </div>
          <div className="flex flex-col justify-start items-start">
            <RequestGridViewDurationCell duration={requestData?.duration} />
          </div>
        </div>
        <IconButton
          icon={arrowRightGray}
          onClick={() => setViewActivity((prev) => !prev)}
          className={viewActivity && "transform rotate-90"}
        />
      </div>
      {viewActivity && (
        <div className="border p-2 mt-2">
          <TertiaryHeader>Activity</TertiaryHeader>
          {history.map((item) => {
            return (
              <RequestHistoryActivityCell
                key={item?.id}
                getUserInfo={getUserInfo}
                history={item}
                requestData={requestData}
                commentMap={commentMap}
                stepDict={stepDict}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

// prop types
RequestHistory.propTypes = {
  commentMap: PropTypes.shape({}),
  requestData: PropTypes.shape({
    initiated: {
      user: PropTypes.string,
      date: PropTypes.string,
    },
    round: PropTypes.number,
    duration: PropTypes.shape({
      startDate: PropTypes.string,
    }),
  }),
  requestHistory: PropTypes.shape({
    context: { submittal: { round: PropTypes.number } },
  }),
  stepDict: PropTypes.shape({}),
};

// default props
RequestHistory.defaultProps = {
  commentMap: undefined,
  requestData: {
    initiated: {
      user: undefined,
      date: undefined,
    },
    round: undefined,
    duration: {
      startDate: undefined,
    },
  },
  requestHistory: undefined,
  stepDict: undefined,
};

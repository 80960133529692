import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DocumentAPI } from "@griffingroupglobal/eslib-api";

import Spinner from "../Spinner/Spinner";
import PDFViewer from "../PDFViewer/PDFViewer";

const CustomDocumentReview = ({ document }) => {
  const [loading, setLoading] = useState(true);
  const [resourceURI, setResourceURI] = useState();

  useEffect(() => {
    const getPDF = async () => {
      DocumentAPI.postWOP(`${document.id}/$createpdf`)
        .then(({ data: { pdfUrl } }) => {
          setResourceURI(pdfUrl);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setResourceURI("error");
          setLoading(false);
        });
    };
    if (document && !resourceURI) {
      getPDF();
    }
  }, [document, resourceURI]);

  return (
    <div className="relative flex flex-col bg-gray-100 rounded-md mt-5 p-2">
      {loading && (
        <div className="flex justify-center items-center w-full h-full">
          <Spinner />
        </div>
      )}
      {!loading && (
        <div className="w-full h-192">
          <PDFViewer initialUrl={resourceURI} />
        </div>
      )}
    </div>
  );
};

CustomDocumentReview.propTypes = {
  /**
   * create document form state
   */
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.object,
};

CustomDocumentReview.defaultProps = {
  document: {},
};

export default CustomDocumentReview;

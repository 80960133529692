import formatUrlResource from "../Format/formatUrlResource";

const filterTasksByResourceAndAsset = (resourceRef, tasks) => {
  const tasksForResource = tasks.filter(
    (task) => task.association === resourceRef
  );
  return tasksForResource;
};

const filterByAssociation = (taskList) => {
  const url = new URL(window.location.href);

  const resource = formatUrlResource(url.pathname.split("/")[1]);
  const resourceId = url.pathname.split("/")[2];
  const resourceRef = `${resource}/${resourceId}`;

  const supportingResource = formatUrlResource(url.pathname.split("/")[3]);
  const supportingResourceId = url.pathname.split("/")[4];
  const supportingResourceRef = `${supportingResource}/${supportingResourceId}`;

  const needsPropertyOrProjectHandling =
    resource === "Property" || resource === "Project";

  if (!supportingResource && needsPropertyOrProjectHandling) {
    return filterTasksByResourceAndAsset(resourceRef, taskList);
  }

  if (!supportingResource && resource === "Asset") {
    const tasksForResource = taskList.filter((task) =>
      task?.assets?.some((asset) => asset.ref === resourceRef)
    );
    return tasksForResource;
  }

  if (supportingResource && supportingResource === "Space") {
    const filteredByParent = filterTasksByResourceAndAsset(
      resourceRef,
      taskList
    );

    const tasksForSupportingResource = filteredByParent.filter((task) =>
      task?.spaces?.some((space) => space?.id === supportingResourceId)
    );

    return tasksForSupportingResource;
  }

  if (supportingResource && supportingResource === "Asset") {
    const tasksForSupportingResource = taskList.filter((task) =>
      task?.assets?.some((asset) => asset.ref === supportingResourceRef)
    );
    return tasksForSupportingResource;
  }

  return taskList;
};

export default filterByAssociation;

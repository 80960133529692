import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { formatCurrency } from "../../../helpers/Formatters";
import AttachmentViewAndDownload from "../FileForm/AttachmentViewAndDownload";
import Widget from "../Widget/Widget";
import TableActionsIconsGroup from "../Table/TableActionsIconsGroup";
import Modal from "../Modal/Modal";
import useAssociationsHelper from "../../../hooks/useAssociationsHelper";
import DescriptionField from "../DescriptionField";
import {
  EXPENSE_STATUS,
  MAXIMIZE_MODAL,
  REMOVE_MODAL_INSTANCE,
} from "../../../constants";
import { useModalState } from "../../../state/modalState";
import Pill from "../Pill/Pill";
import {
  hasDeletePermission,
  hasWritePermission,
} from "../../../helpers/Permissions";
import AssociationLink from "../AssociationLink";

const ExpenseDetailsView = ({
  currentUser,
  modalData,
  deleteExpenses,
  setIsEditing,
  loadingReceipts,
  expenseData = {},
  csiCodes,
  financialsConfiguration,
}) => {
  const [, modalDispatch] = useModalState();

  const { getAssociationInfo } = useAssociationsHelper();

  const [expenseInfo, setExpenseInfo] = useState({});
  const [showInvoicedInfo, setShowInvoicedInfo] = useState(false);
  const [deleteExpenseWarning, setDeleteExpenseWarning] = useState(false);

  let associationName;
  let assetName;

  if (expenseInfo?.association) {
    const associationObj = getAssociationInfo(expenseInfo.association);
    associationName = associationObj?.title || associationObj?.name;
  }

  if (expenseInfo?.asset) {
    assetName = getAssociationInfo(expenseInfo.asset)?.name;
  }

  useEffect(() => {
    const catInfo =
      financialsConfiguration?.financials?.expense?.categories?.find(
        (cat) => cat.id === expenseData?.category
      )?.display;

    setExpenseInfo({
      ...expenseData,
      category: catInfo,
    });
  }, [expenseData, financialsConfiguration]);

  const handleEdit = useCallback(() => {
    if (expenseData?.isInvoiced) {
      setShowInvoicedInfo(true);
    } else {
      setIsEditing((prev) => !prev);
    }
    setShowInvoicedInfo(false);
  }, [expenseData?.isInvoiced, setIsEditing]);

  const handleDeleteExpense = (expenseRef) => {
    deleteExpenses([expenseRef]);
    modalDispatch({
      type: REMOVE_MODAL_INSTANCE,
      id: modalData?.id,
    });
  };

  return (
    <>
      <Widget draggable={null} title="">
        <div className="flex flex-col items-start px-3 w-full show-more">
          <div className="flex justify-between items-center w-full h-5">
            <div>
              <Pill
                className="h-full mt-4"
                value={expenseData?.status}
                textSize="text-xxs font-semibold capitalize"
                border="border border-primaryGreen"
                background="bg-white"
                textColor="text-primaryGreen"
                padding="p-2"
              />
            </div>
            <div className="flex items-center">
              <TableActionsIconsGroup
                wrapperClassName="pt-0"
                style={{ height: expenseData?.isInvoiced ? "0px" : "30px" }}
                canEdit={
                  ![EXPENSE_STATUS.merged, EXPENSE_STATUS.billed].includes(
                    expenseData?.status
                  ) &&
                  !expenseData?.isInvoiced &&
                  (currentUser?.isAdmin ||
                    hasWritePermission("expense", currentUser))
                }
                canDelete={
                  ![EXPENSE_STATUS.merged, EXPENSE_STATUS.billed].includes(
                    expenseData?.status
                  ) &&
                  !expenseData?.isInvoiced &&
                  hasDeletePermission("expense", currentUser)
                }
                handleShowDelete={setDeleteExpenseWarning}
                handleEdit={handleEdit}
              />
            </div>
          </div>
          <div className="flex items-center w-full mt-5">
            <p className="flex items-start text-gray-500 font-semibold w-1/3">
              Association
            </p>
            <div className="w-2/3 pl-14">
              <AssociationLink
                associationName={associationName}
                associationRef={expenseData?.association}
              />
            </div>
          </div>

          {assetName && (
            <div className="flex items-center w-full mt-5">
              <p className="flex items-start text-gray-500 font-semibold w-1/3">
                Asset
              </p>
              <p className="text-gray-500 w-2/3 pl-14">{assetName}</p>
            </div>
          )}

          <div className="flex items-start w-full mt-5">
            <p className="flex items-center text-gray-500 font-semibold w-1/3">
              Expense Date
            </p>
            <p className="text-gray-500 w-2/3 pl-14">
              {moment(expenseInfo?.date)?.format("MM/DD/YY")}
            </p>
          </div>
          {expenseInfo?.category === "Mileage" && (
            <div className="flex items-start w-full mt-5">
              <p className="flex items-center text-gray-500 font-semibold w-1/3">
                # of Miles
              </p>
              <p className="text-gray-500 w-2/3 pl-14">
                {expenseInfo?.numMiles}
              </p>
            </div>
          )}

          {expenseInfo?.category !== "Mileage" && (
            <div className="flex items-start w-full mt-5">
              <p className="flex items-center text-gray-500 font-semibold w-1/3">
                Merchant
              </p>
              <p className="text-gray-500 w-2/3 pl-14">
                {expenseInfo?.merchant}
              </p>
            </div>
          )}

          <div className="flex items-start w-full mt-5">
            <p className="flex items-center text-gray-500 font-semibold w-1/3">
              Total Amount
            </p>
            <p className="text-gray-500 w-2/3 pl-14">
              {formatCurrency(expenseInfo?.amount)}
            </p>
          </div>
          <div className="flex items-start w-full mt-5">
            <p className="flex items-center text-gray-500 font-semibold w-1/3">
              Category
            </p>
            <p className="text-gray-500 w-2/3 pl-14">
              {expenseInfo?.category || "Not Specified"}
            </p>
          </div>

          {expenseInfo?.financialCode && (
            <div className="flex items-start w-full mt-5">
              <p className="text-gray-500 font-semibold whitespace-nowrap w-1/3">
                CSI Code
              </p>
              {expenseInfo?.financialCode?.division &&
                expenseInfo?.financialCode?.code &&
                expenseInfo?.financialCode?.subcode && (
                  <p className="text-gray-500  max-h-24 overflow-y-auto w-2/3 pl-14">
                    {
                      csiCodes?.find(
                        (c) =>
                          c.value ===
                          `${expenseInfo?.financialCode?.division} ${expenseInfo?.financialCode?.code} ${expenseInfo?.financialCode?.subcode}`
                      )?.label
                    }
                  </p>
                )}
            </div>
          )}
          <div className="flex items-start w-full mt-5">
            <p className="text-gray-500 font-semibold w-1/3">Billable</p>
            <p className="text-gray-500 w-2/3 pl-14">
              {expenseInfo?.billable ? "Yes" : "No"}
            </p>
          </div>

          <div className="flex items-start w-full  mt-5">
            <p className="flex items-center text-gray-500 font-semibold w-1/3">
              Credit Card
            </p>
            <p className="text-gray-500 w-2/3 pl-14">
              {expenseInfo?.creditCard ? "Yes" : "No"}
            </p>
          </div>

          <div className="flex items-start w-full  mt-5">
            <p className="flex items-center text-gray-500 font-semibold w-1/3">
              Description
            </p>

            <DescriptionField
              style={{ width: "700px" }}
              className="text-gray-500 text-base font-normal max-h-24 overflow-y-auto w-2/3 ml-20"
              text={expenseInfo?.description}
            />
          </div>

          {expenseInfo?.receipts?.length > 0 && (
            <div className="flex flex-col w-full">
              <p className="text-gray-500 font-semibold mt-5">
                {expenseInfo?.receipts?.length > 1 ? "Receipts" : "Receipt"}
              </p>
              <div className="flex flex-col items-start w-full overflow-x-auto mb-5">
                {!loadingReceipts && (
                  <AttachmentViewAndDownload
                    filesArray={expenseInfo?.receipts}
                    wrapperClassName="w-full"
                  />
                )}
              </div>
            </div>
          )}

          {/* Keep in for legacy expenses created with Notes */}
          {expenseInfo?.note ? (
            <div className="flex flex-col items-start w-full mb-5">
              <p className="text-gray-500 font-semibold mb-3">Notes</p>
              <p className="text-gray-500">{expenseInfo?.note}</p>
            </div>
          ) : null}
        </div>
      </Widget>
      <Modal
        title="Edit expense."
        isOpen={showInvoicedInfo}
        primaryButtonTitle="Ok"
        primaryButtonOnClick={() => setShowInvoicedInfo(false)}
        onRequestModalClose={() => setShowInvoicedInfo(false)}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <>
          <p className="text-base mb-2">
            This expence was invoiced and cannot be edited.
          </p>
        </>
      </Modal>
      <Modal
        title="Delete Expense"
        isOpen={!!deleteExpenseWarning}
        primaryButtonTitle="Yes"
        primaryButtonOnClick={() => {
          handleDeleteExpense(expenseData?.reference);
          setDeleteExpenseWarning(false);
        }}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={() => {
          setDeleteExpenseWarning(false);
          modalDispatch({
            type: MAXIMIZE_MODAL,
            id: modalData?.id,
          });
        }}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
        alert
      >
        <p className="text-base mb-2">
          {`Are you sure you want to delete expense? This action can not
              be undone.`}
        </p>
      </Modal>
    </>
  );
};

ExpenseDetailsView.propTypes = {
  currentUser: PropTypes.shape({
    isAdmin: PropTypes.bool,
    hasPermission: PropTypes.func,
  }),
  deleteExpenses: PropTypes.func,
  modalData: PropTypes.shape({ id: PropTypes.string }),
  setIsEditing: PropTypes.func,
  loadingReceipts: PropTypes.bool,
  expenseData: PropTypes.shape({}),
  csiCodes: PropTypes.arrayOf(PropTypes.shape({})),
  financialsConfiguration: PropTypes.shape({
    financials: PropTypes.shape({
      expense: PropTypes.shape({
        categories: PropTypes.arrayOf(
          PropTypes.shape({
            display: PropTypes.string,
            id: PropTypes.string,
            isOpen: PropTypes.bool,
            isEditing: PropTypes.bool,
          })
        ),
      }),
    }),
  }),
};
ExpenseDetailsView.defaultProps = {
  currentUser: undefined,
  deleteExpenses: undefined,
  modalData: undefined,
  loadingReceipts: false,
  expenseData: {},
  csiCodes: [],
  financialsConfiguration: undefined,
  setIsEditing: undefined,
};

export default ExpenseDetailsView;

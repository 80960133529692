import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useUsers from "../../hooks/useUsers";

import SelectUserInterface from "../../stories/Components/SelectUserInterface/SelectUserInterface";

import { getFullName } from "../../helpers/Formatters";
import DetailCard from "../../stories/Components/DetailCard/DetailCard";
import { useAppState } from "../../state/appState";

const ProjectMembersView = ({
  editedProject,
  dispatch,
  onFinishEditing,
  disableEditing,
}) => {
  const [users] = useUsers();

  const [memberData, setMemberData] = useState([]);
  const [addMemberOptions, setAddMemberOptions] = useState([]);
  const [currentMembers, setCurrentMembers] = useState([]);
  const [{ rolesWOP }] = useAppState();

  useEffect(() => {
    const filteredMemberData = [];

    const canReadProjectRoleTypes = rolesWOP?.map((entry) => {
      if (entry?.resource?.permissions?.project?.can_read) {
        return entry?.resource?.reference;
      }
      return "";
    });

    canReadProjectRoleTypes.forEach((role) => {
      const projectUsers = users.filter(
        (u) => u.role === role && u.kind === "member"
      );
      projectUsers.forEach((user) => {
        filteredMemberData.push(user);
      });
    });

    const cleanOptions = filteredMemberData?.map((member) => {
      return {
        ...member,
        label: getFullName(member?.name),
        value: member.reference,
      };
    });

    setAddMemberOptions(cleanOptions);

    const memberDD =
      users
        ?.filter((info) => info?.name?.firstName || info?.name?.lastName)
        ?.map((info) => ({
          ...info,
          label: getFullName(info?.name),
          value: info.reference,
        })) ?? [];

    setMemberData(memberDD);
  }, [users, rolesWOP]);

  useEffect(() => {
    const currentMemberIds = currentMembers?.map((member) => member?.id);
    setAddMemberOptions((prev) =>
      prev.filter((item) => !currentMemberIds.includes(item.id))
    );
  }, [currentMembers]);

  useEffect(() => {
    let tempMembers = [];
    if (memberData?.length && editedProject && editedProject.members?.length) {
      tempMembers = editedProject.members.map(({ user }) => {
        const data = {};
        if (typeof user === "string") {
          data.user = user;
          data.value = user;
        } else {
          data.user = user?.reference;
          data.value = user?.reference;
        }
        const mem = memberData.find((item) => item.reference === data.user);
        return {
          ...mem,
          ...data,
        };
      });
    }
    setCurrentMembers(tempMembers);
  }, [memberData, editedProject]);

  const handleAdd = (list) => {
    const newMembers = currentMembers.map((item) => {
      return { user: item.reference };
    });
    list.forEach((item) => {
      const newMem = { user: item.reference };
      newMembers.push(newMem);
      dispatch({
        type: "addMember",
        member: newMem,
      });
    });
    onFinishEditing("members", newMembers);
  };

  const handleRemove = (ref) => {
    dispatch({
      type: "removeMember",
      member: ref,
    });
    onFinishEditing(
      "members",
      currentMembers.filter(({ user }) => user !== ref)
    );
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row w-full">
        <div className="w-1/3 pr-4 border-r-2">
          <DetailCard
            title="Project Members"
            selectedUsers={currentMembers}
            displayValue=""
            users={memberData}
            type="USERSELECT"
            onChange={() => {}}
            onDelete={handleRemove}
            onFinishEditing={onFinishEditing}
            noBorder
            hideEditButton={disableEditing}
          />
        </div>

        <div className="flex-1 ml-12 p-5">
          <SelectUserInterface
            className="w-3/4"
            userList={[]}
            userLabel="Add Member"
            userPlaceholder="Search Members"
            buttonText="Add Member"
            userOptions={addMemberOptions}
            onAddUser={(val) => handleAdd(val)}
            onRemoveUser={handleRemove}
            isSingleSelect
            disabled={disableEditing}
          />
        </div>
      </div>
    </div>
  );
};

ProjectMembersView.propTypes = {
  editedProject: PropTypes.shape({
    members: PropTypes.arrayOf(
      PropTypes.shape({
        user: PropTypes.string,
      })
    ),
  }),
  dispatch: PropTypes.func,
  onFinishEditing: PropTypes.func,
  disableEditing: PropTypes.bool,
};

ProjectMembersView.defaultProps = {
  editedProject: {},
  dispatch: undefined,
  onFinishEditing: () => {},
  disableEditing: false,
};

export default ProjectMembersView;

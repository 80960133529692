import React from "react";
import PropTypes from "prop-types";
import Thumbnail from "./Thumbnail";
import CrossBubble from "../CrossBubble";

/**
 * Component that renders an inline list of media files
 * @param {thumbnailHeight} number - height of thumbnail
 * @param {thumbnailWidth} number - width of thumbnail
 */
const Gallery = ({
  mediaFiles,
  isEditing,
  handleFilesToRemove,
  thumbnailHeight,
  thumbnailWidth,
}) => {
  return (
    <div className="flex flex-wrap gap-4">
      {mediaFiles.map((file) => {
        return (
          <button
            key={file?.ref || file?.id}
            type="button"
            className="relative rounded-md shadow-light-lift"
            style={{
              width: thumbnailWidth,
              height: thumbnailHeight,
            }}
          >
            {isEditing && (
              <CrossBubble
                onClick={() => handleFilesToRemove(file)}
                width={18}
                height={18}
                className="z-10"
              />
            )}
            <Thumbnail
              file={file}
              fileObject={file?.original}
              isEditing={isEditing}
            />
          </button>
        );
      })}
    </div>
  );
};

Gallery.defaultProps = {
  mediaFiles: [],
  isEditing: false,
  handleFilesToRemove: () => {},
  thumbnailHeight: 140,
  thumbnailWidth: 140,
};

Gallery.propTypes = {
  /**
   * Files could be:
   * an array of ES files [{ref: File/1111-2222-3333, category: "Photo"}]
   * or
   * an array of native files from picker [{ original: {}, id: 123455, name: "My File.jpeg", type: "jpeg", }]
   */
  mediaFiles: PropTypes.arrayOf(
    PropTypes.oneOf([
      PropTypes.shape({ ref: PropTypes.string, category: PropTypes.string }),
      PropTypes.shape({
        original: PropTypes.shape({}),
        id: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
      }),
    ])
  ),
  isEditing: PropTypes.bool,
  handleFilesToRemove: PropTypes.func,
  thumbnailHeight: PropTypes.number,
  thumbnailWidth: PropTypes.number,
};

export default Gallery;

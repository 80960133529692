/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { isSafari } from "react-device-detect";
import cntl from "cntl";
import moment from "moment";
import PropTypes from "prop-types";
import redExclamationIcon from "../../assets/images/redExclamationIcon.svg";
import yellowExclamationIcon from "../../assets/images/yellowExclamationIcon.svg";
import ProgressBar from "../ProgressBar/ProgressBar";
import SecondaryButton from "../Buttons/SecondaryButton";
import FlagButton from "../Buttons/FlagButton";
import {
  GET_PROJECT_WORKFLOW_VIEW_PATH,
  GET_PROPERTY_WORKFLOW_VIEW_PATH,
  GET_WORKFLOW_VIEW_PATH,
  UNRESOLVED_WF_STEP,
} from "../../../constants";
import ActiveCardBody from "./ActiveCardBody";
import AssociationPopUp from "./AssociationPopUp";
import WorkflowOptions from "./WorkflowOptions";
import DeleteModal from "../DeleteModal/DeleteModal";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";

const cardWrapperCN = cntl`
  border-2
  rounded
  relative
  flex 
  flex-col
  w-75	
  mx-1.5
  mb-3
  hover:border-brandGreen
  cursor-pointer
`;
const footerContCN = (showBoarder) => cntl`
  border-gray-100 
  ${showBoarder && "border-t-2"}
  h-14 
  w-full 
  px-4
  flex
  items-center	
`;
const headerCN = cntl`
  bg-lightGreen
  w-full
  ${isSafari ? "py-4 pl-4" : "p-4"}
  h-36
  flex
  flex-col
  justify-between
`;

const WorkflowActiveCard = ({
  data,
  setSelectedCard,
  handleEditWorkflow,
  handleDeleteWF,
  handleOpenAssociation,
  handleOpenResolve,
  handleFlagWF,
  disableEditing,
  disableDelete,
  associatedResource,
  handleWatchWF,
  watchList,
}) => {
  const history = useHistory();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [watchedWF, setWatchedWF] = useState();
  const [isWatched, setIsWatched] = useState(false);

  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const optionRef = useRef(null);
  useOutsideAlerter(optionRef, () => setIsOptionOpen(false));

  useEffect(() => {
    const foundItem = watchList?.find((watchedItem) => {
      return watchedItem.item.reference.includes(data.id);
    });
    setWatchedWF(foundItem);
    if (foundItem) {
      setIsWatched(true);
    } else {
      setIsWatched(false);
    }
  }, [watchList, data.id]);

  const daysLeft = moment(data?.endDate).diff(moment().startOf("day"), "days");
  const displayTitle = () => {
    if (data.warning === undefined) {
      return null;
    }
    if (daysLeft > 0) {
      return `Due in ${daysLeft} ${daysLeft === 1 ? "day" : "days"}`;
    }
    if (daysLeft === 0) {
      return "Due today";
    }
    return `Due ${Math.abs(daysLeft)} days ago`;
  };
  const handleOpenWF = (id) => {
    if (associatedResource) {
      const associatedResourceType = associatedResource.split("/")[0];

      if (associatedResourceType === "Property") {
        history.push(
          GET_PROPERTY_WORKFLOW_VIEW_PATH(associatedResource.split("/")[1], id)
        );
      } else if (associatedResourceType === "Project") {
        history.push(
          GET_PROJECT_WORKFLOW_VIEW_PATH(associatedResource.split("/")[1], id)
        );
      }
    } else {
      history.push(GET_WORKFLOW_VIEW_PATH(id));
    }
  };

  const getCardFooter = () => {
    if (data.status === "unresolved") {
      return (
        <SecondaryButton
          title="Complete"
          className="w-full z-10"
          noMaxWidth
          onClick={handleOpenResolve}
        />
      );
    }
    return (
      <div className="flex flex-col h-full w-full">
        <div className="flex flex-col justify-center h-full">
          <div className="flex flex-row justify-between">
            <h3 className="text-sm mb-1">Steps Advanced</h3>
            <h3 className="text-sm mb-1">
              <span className="font-bold">{data.currentStep}</span> of{" "}
              <span className="font-bold">{data.steps?.length}</span>
            </h3>
          </div>
          <ProgressBar
            progress={(data.currentStep / data.steps?.length) * 100 || 0}
          />
        </div>
      </div>
    );
  };
  const workflowOptions = () => {
    const options = [];

    if (!disableEditing && data.status === UNRESOLVED_WF_STEP) {
      workflowOptions.push({
        title: "Complete",
        onClick: () => {
          setIsOptionOpen(false);
          handleOpenResolve();
        },
      });
    }

    if (!disableEditing) {
      options.push({ title: "Edit", onClick: handleEditWorkflow });
      options.push({
        title: "Create Associated Workflow",
        onClick: () => {
          setIsOptionOpen(false);
          handleOpenAssociation();
        },
      });
    }

    if (!disableDelete) {
      options.push({
        title: "Delete",
        onClick: () => {
          setIsOptionOpen(false);
          setIsDeleteModalOpen(true);
        },
      });
    }

    options.push({
      title: `${isWatched ? "Unwatch" : "Watch"}`,
      onClick: () => {
        setIsOptionOpen(false);
        handleWatchWF(data.id, isWatched, watchedWF);
      },
    });

    return options;
  };

  const handleOpenOption = () => {
    setSelectedCard(data);
    setIsOptionOpen(true);
  };
  const handleModalDelete = () => {
    handleDeleteWF();
    setIsDeleteModalOpen(false);
  };
  const handleFlagOption = () => {
    handleFlagWF(data);
  };
  const handleCardClick = () => {
    if (associatedResource) {
      const associatedResourceType = associatedResource.split("/")[0];
      if (associatedResourceType === "Property") {
        history.push(
          GET_PROPERTY_WORKFLOW_VIEW_PATH(
            associatedResource.split("/")[1],
            data.id
          )
        );
      } else if (associatedResourceType === "Project") {
        history.push(
          GET_PROJECT_WORKFLOW_VIEW_PATH(
            associatedResource.split("/")[1],
            data.id
          )
        );
      }
    } else {
      history.push(GET_WORKFLOW_VIEW_PATH(data.id));
    }
  };

  return (
    <>
      <div
        className={cardWrapperCN}
        onClick={handleCardClick}
        onKeyDown={handleCardClick}
        role="button"
        tabIndex={0}
      >
        <div className={headerCN}>
          <div className="flex flex-col">
            <div className="flex justify-between h-full">
              <div className="flex">
                {daysLeft < 0 && (
                  <img
                    src={redExclamationIcon}
                    alt="past due"
                    className="mr-3 w-4.5"
                  />
                )}
                {daysLeft <= 5 && daysLeft >= 0 && (
                  <img
                    src={yellowExclamationIcon}
                    alt="due soon"
                    className="mr-3 w-4.5"
                  />
                )}
                <h3 className="text-sm text-gray-700">{displayTitle()}</h3>
              </div>

              <div
                className="flex"
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
                tabIndex={0}
                role="button"
              >
                {!disableEditing && (
                  <FlagButton
                    className="flex relative z-10"
                    isSelected={data.isFlagged}
                    onClick={handleFlagOption}
                  />
                )}
                {(!disableEditing || !disableDelete) && (
                  <WorkflowOptions
                    onClick={handleOpenOption}
                    options={workflowOptions()}
                    isOptionOpen={isOptionOpen}
                    optionRef={optionRef}
                  />
                )}
              </div>
            </div>
            <a
              className="mt-1 text-lg font-bold cursor-pointer"
              href={handleCardClick}
            >
              {data?.name}
            </a>
          </div>
          <div
            className="flex justify-between items-end relative"
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
            tabIndex={0}
            role="button"
          >
            <h3 className="text-sm flex">{`Version ${data.version}`}</h3>
            <AssociationPopUp data={data} handleOpenWF={handleOpenWF} />
          </div>
        </div>
        <div className="h-44 w-full p-4">
          {data.metadata && <ActiveCardBody data={data} />}
        </div>
        <div className={footerContCN(data.totalStep !== data.currentStep)}>
          {data.metadata && getCardFooter()}
        </div>
      </div>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onDelete={handleModalDelete}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete Workflow?"
        text={`Are you sure that you want to delete ${data.name}? Once deleted, the workflow cannot be recovered.`}
      />
    </>
  );
};

WorkflowActiveCard.propTypes = {
  /**
   * data displayed on cards
   */
  data: PropTypes.shape({
    metadata: PropTypes.shape({
      lastUpdated: PropTypes.string,
      createdAt: PropTypes.string,
      userData: PropTypes.shape({
        name: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      }),
    }),
    relationships: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      children: PropTypes.arrayOf(PropTypes.any),
    }),
    resource: PropTypes.string,
    id: PropTypes.string,
    reference: PropTypes.string,
    version: PropTypes.number,
    isDraft: PropTypes.bool,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      })
    ),
    status: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    documents: PropTypes.arrayOf(PropTypes.any),
    dateCreated: PropTypes.string,
    startDate: PropTypes.string,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        duration: PropTypes.number,
        needOnlyOneAdvancer: PropTypes.bool,
        position: PropTypes.number,
        // eslint-disable-next-line react/forbid-prop-types
        documents: PropTypes.arrayOf(PropTypes.any),
        id: PropTypes.string,
        members: PropTypes.arrayOf(
          PropTypes.shape({
            _id: PropTypes.string,
            reference: PropTypes.string,
            status: PropTypes.string,
            date: PropTypes.string,
            userData: PropTypes.shape({
              name: PropTypes.shape({
                firstName: PropTypes.string,
                lastName: PropTypes.string,
              }),
            }),
          })
        ),
        // eslint-disable-next-line react/forbid-prop-types
        parallelSteps: PropTypes.arrayOf(PropTypes.any),
        name: PropTypes.string,
        description: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types
        comments: PropTypes.arrayOf(PropTypes.any),
        dueDate: PropTypes.string,
      })
    ),
    warning: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    associatedWorkflows: PropTypes.arrayOf(PropTypes.any),
    name: PropTypes.string,
    cost: PropTypes.string,
    endDate: PropTypes.string,
    currentStep: PropTypes.number,
    dueDate: PropTypes.string,
    isFlagged: PropTypes.bool,
    initiationDate: PropTypes.string,
    initiatorName: PropTypes.string,
    resolved: PropTypes.bool,
    totalStep: PropTypes.number,
  }),
  /**
   * sets the selected card
   */
  setSelectedCard: PropTypes.func,
  handleEditWorkflow: PropTypes.func,
  handleDeleteWF: PropTypes.func,
  handleOpenAssociation: PropTypes.func,
  handleOpenResolve: PropTypes.func,
  handleFlagWF: PropTypes.func,
  disableEditing: PropTypes.bool,
  disableDelete: PropTypes.bool,
  associatedResource: PropTypes.string,
  handleWatchWF: PropTypes.func,
  watchList: PropTypes.arrayOf(PropTypes.string),
};

WorkflowActiveCard.defaultProps = {
  data: [],
  setSelectedCard: undefined,
  handleEditWorkflow: undefined,
  handleDeleteWF: undefined,
  handleOpenAssociation: undefined,
  handleOpenResolve: undefined,
  handleFlagWF: undefined,
  disableEditing: false,
  disableDelete: false,
  associatedResource: undefined,
  handleWatchWF: undefined,
  watchList: [],
};
export default WorkflowActiveCard;

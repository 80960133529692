import useAuthenticatedQuery from "../useAuthenticatedQuery";
import { propertyKeys } from "../properties";
import { projectKeys } from "../projects";
import { assetKeys } from "../assets";
import getAssociationByReference from "../../helpers/Associations/getAssociationByReference";

/**
 * Query hook to get single association
 * @param {String} reference - The association reference
 * @returns - An object with association data, loading indicator and error
 */
const useSingleAssociationData = (reference = "") => {
  const getQueryKey = () => {
    const [resource, id] = reference.split("/");

    switch (resource) {
      case "Property":
        return propertyKeys.byId(id);
      case "Project":
        return projectKeys.byId(id);
      case "Asset":
        return assetKeys.byId(id);
      default:
        return undefined;
    }
  };

  const queryResponse = useAuthenticatedQuery({
    queryKey: getQueryKey(),
    enabled: !!reference,
    queryFn: () => getAssociationByReference(reference),
    onError: (error) => console.error("useSingleAssociationData", error),
  });

  return {
    associationData: queryResponse.data,
    associationError: queryResponse.error,
    isAssociationLoading: queryResponse.isLoading,
  };
};

export default useSingleAssociationData;

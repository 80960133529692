import { HistoryAPI } from "@griffingroupglobal/eslib-api";

/**
 * Handles the splitting of a large query string into manageable requests, fetches history data, and combines responses.
 *
 * @param {string} queryString - A large query string that needs to be split into smaller chunks to avoid request limits.
 * @returns {Promise<Object>} An object containing an array of history entries from all the batched requests.
 */

const handleHistoryReqSplit = async (queryString) => {
  // Remove the specific query prefix and split into individual parts
  const refArray = queryString.replace("?association=", "").split(",");
  const chunkSize = 20;

  // If the array is already small enough, just make the request
  if (refArray.length <= chunkSize) return HistoryAPI.getWOP(queryString);

  // requestStrObject ends up looking like { 0: '?association=ref1,ref2,ref3', 1: '?association=ref4,ref5,ref6', ... }
  const requestStrObject = {};

  // batch reference into chunks of 20 to split one large request into multiple smaller ones
  refArray.forEach((ref, index) => {
    if (!ref) return;
    const batchIndex = Math.floor(index / chunkSize);
    requestStrObject[batchIndex] =
      requestStrObject[batchIndex] || "?association=";
    requestStrObject[batchIndex] += `${ref},`;
  });

  // Remove the trailing comma from each batched request string
  Object.keys(requestStrObject).forEach((key) => {
    requestStrObject[key] = requestStrObject[key].slice(0, -1);
  });

  const promiseArr = Object.values(requestStrObject).map((requestQueryStr) =>
    HistoryAPI.getWOP(requestQueryStr)
  );

  const returnData = { data: { entries: [] } };

  try {
    const historyResponses = await Promise.all(promiseArr);
    // Combine all the history entries from the batched requests to match
    // how legacy code handles the response
    historyResponses.forEach((response) => {
      returnData.data.entries.push(...response.data.entries);
    });
    return returnData;
  } catch (err) {
    return returnData;
  }
};

export default handleHistoryReqSplit;

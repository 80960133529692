import {
  CommentAPI,
  DocumentAPI,
  EventAPI,
  FileAPI,
  UserAPI,
} from "@griffingroupglobal/eslib-api";
import { cloneDeep, isEqual } from "lodash";
import moment from "moment";
import PropTypes, { objectOf } from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  ALL_EVENT_INSTANCES,
  FUTURE_EVENT_INSTANCES,
  GET_SOP_DETAILS_PATH,
  SINGLE_EVENT_INSTANCE,
  customModalStyles,
  maintenanceStatus,
} from "../../../constants";
import { uploadFileWithData } from "../../../helpers/File";
import { toastError, toastMessage } from "../../../helpers/Toast";
import attachmentIcon from "../../assets/images/attachment.svg";
import checkmark from "../../assets/images/checkmark.svg";
import chevronRightIcon from "../../assets/images/chevronRight.svg";
import whiteCircleCheckIcon from "../../assets/images/circleCheckIcon.svg";
import deleteIcon from "../../assets/images/collapseIcon.svg";
import moreInformationIcon from "../../assets/images/moreInformation.svg";
import returnIcon from "../../assets/images/returnIcon.svg";
import uploadArrowGrayIcon from "../../assets/images/uploadArrowGrayIcon.svg";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../assets/images/whiteExclamationIcon.svg";
import IconButton from "../Buttons/IconButton";
import CommentSection from "../Comments/CommentSection";
import InputModal from "../InputModal/InputModal";
import Modal from "../Modal/Modal";
import Spinner from "../Spinner/Spinner";
import AssetMaintenanceScheduledTaskStatus from "./AssetMaintenanceScheduledTaskStatus";
import AssetMaintenanceScheduledTaskView from "./AssetMaintenanceScheduledTaskView";
import MaintenanceScheduleModal from "./MaintenanceScheduleModal";
import SimpleFileUpLoad from "./SimpleFileUpLoad";

import { isAllDay } from "../../../helpers/Calendar";
import { useAppState } from "../../../state/appState";
import TaskRecurrenceInstanceModal from "./TaskRecurrenceInstanceModal";
import "./styles.css";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = <img src={whiteCrossIcon} alt="Close notice" />;
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const { overlayStyle, contentStyle, titleStyle, headerStyle } =
  customModalStyles;
const { CANCELLED, COMPLETE, SCHEDULED } = maintenanceStatus;

const MaintenanceSection = ({
  taskData,
  currentUser,
  onDeletedTask,
  assetMembersOptions,
  onEditedTask,
  users,
  isTaskDurationRequired,
  reloadAsset,
  association,
}) => {
  const history = useHistory();
  const [{ userDict }] = useAppState();
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [moreOptions, setMoreOptions] = useState([]);
  const [isSentReminderOpen, setIsSentReminderOpen] = useState(false);
  const [remindData, setRemindData] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [assignedTo, setAssignedTo] = useState([]);
  const [updatedData, setUpdatedData] = useState(taskData);
  const [taskFilesAndDocs, setTaskFilesAndDocs] = useState([]);
  const [taskComments, setTaskComments] = useState([]);
  const [taskStatus, setTaskStatus] = useState(taskData?.status);
  const [maintenanceDuration, setMaintenanceDuration] = useState({});
  const [addCommentFiles, setAddCommentFiles] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [uploadedAttachments, setUploadedAttachments] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const [showAddComment, setShowAddComment] = useState(false);
  const [deleteEventInstance, setDeleteEventInstance] = useState(undefined);
  const [showRecurrenceModal, setShowRecurrenceModal] = useState(false);
  const [recurrenceOptions, setRecurrenceOptions] = useState([]);
  const [taskRecurrence, setTaskRecurrence] = useState();
  const [taskEdits, setTaskEdits] = useState();
  const [isProcessEditing, setIsProcessEditing] = useState(false);
  const [deletingTask, setDeletingTask] = useState(false);
  const [recurrenceAtion, setRecurrenceAction] = useState();
  const [originalSteps, setOriginalSteps] = useState();
  const [maintenanceSteps, setMaintenanceSteps] = useState(taskData?.steps);
  const [stepsNotChanged, setStepsNotChanged] = useState(true);
  const [allStepsCompleted, setAllStepsCompleted] = useState(false);
  const [buttonAction, setButtonAction] = useState("Close");
  const [invitees, setInvitees] = useState([]);
  const [closedBy, setClosedBy] = useState();
  const [canCompleteTask, setCanCompleteTask] = useState();
  const [canEditTask, setCanEditTask] = useState();
  const [canDeleteTask, setCanDeleteTask] = useState();
  const [canCommentTask, setCanCommentTask] = useState();
  const [canHideComments, setcanHideComments] = useState(
    showComments || showAddComment
  );
  const [statusHasChanged, setStatusHasChanged] = useState(false);
  const [associationRef, setAssociationRef] = useState("");
  const [canCompleteStep, setCanCompleteStep] = useState(false);
  const [isAlldayEvent, setIsAlldayEvent] = useState(false);

  useEffect(() => {
    setIsAlldayEvent(
      isAllDay(moment(updatedData?.startDate), moment(updatedData?.endDate))
    );
  }, [updatedData?.endDate, updatedData?.startDate]);

  useEffect(() => {
    setCanCompleteStep(
      invitees
        ?.map((invitee) => invitee?.value)
        ?.includes(currentUser?.reference)
    );
  }, [currentUser, invitees]);

  useEffect(() => {
    setAssociationRef(updatedData?.association);
  }, [updatedData?.associationName, updatedData?.association]);

  useEffect(() => {
    setAllStepsCompleted(
      maintenanceSteps?.length === 0 ||
        maintenanceSteps?.every(({ isCompleted }) => isCompleted)
    );
  }, [buttonAction, maintenanceSteps, maintenanceSteps?.length]);

  useEffect(() => {
    setCanCompleteTask(updatedData?.invitees.includes(currentUser?.reference));
    setCanEditTask(
      currentUser?.reference === updatedData?.owner || currentUser?.isAdmin
        ? currentUser?.permissions?.task?.can_update
        : false
    );
    setCanDeleteTask(
      currentUser?.reference === updatedData?.owner || currentUser?.isAdmin
        ? currentUser?.permissions?.task?.can_delete
        : false
    );
    setCanCommentTask(
      currentUser?.reference === updatedData?.owner ||
        currentUser?.isAdmin ||
        canCompleteTask
    );
  }, [
    updatedData?.invitees,
    updatedData?.owner,
    canCompleteTask,
    currentUser,
    currentUser?.permissions?.task?.can_delete,
    currentUser?.permissions?.task?.can_update,
  ]);

  const disableButton = useCallback(() => {
    if (
      (taskStatus === COMPLETE &&
        statusHasChanged &&
        maintenanceDuration.value &&
        maintenanceDuration.typeOfDuration) ||
      (taskStatus === CANCELLED && statusHasChanged)
    ) {
      return false;
    }
    return true;
  }, [
    statusHasChanged,
    maintenanceDuration.typeOfDuration,
    maintenanceDuration.value,
    taskStatus,
  ]);

  useEffect(() => {
    setOriginalSteps(cloneDeep(taskData?.steps));
  }, [taskData?.steps]);

  useEffect(() => {
    setTaskFilesAndDocs(taskData?.files);
  }, [taskData?.files]);

  useEffect(() => {
    setUpdatedData(taskData);
  }, [taskData]);

  useEffect(() => {
    if (updatedData?.recurringCalendarOptions) {
      const options = updatedData?.recurringCalendarOptions.map((option) => ({
        label: moment(option.date).format("llll"),
        value: option.date,
      }));
      setRecurrenceOptions(options);
    }
  }, [updatedData?.recurringCalendarOptions]);

  useEffect(() => {
    const refreshAsset = async () => {
      await reloadAsset();
    };
    refreshAsset();
  }, [reloadAsset]);

  useEffect(() => {
    const fetchClosedBy = async () => {
      if (updatedData?.closedBy) {
        const { data } = await UserAPI.getById(
          updatedData?.closedBy.split("/")[1]
        );
        setClosedBy(`${data.name.firstName} ${data.name.lastName}`);
      }
    };
    fetchClosedBy();
  }, [updatedData?.closedBy]);

  useEffect(() => {
    const guests = updatedData?.invitees?.reduce(
      (list, item) => {
        const { firstName, lastName } = userDict[item]?.name ?? {};
        const label = `${firstName ?? ""} ${lastName ?? ""}`;
        const value = item;
        if (userDict[item]?.name) {
          list.assigned.push(label);
          list.invitees.push({ label, value });
        }
        return list;
      },
      { assigned: [], invitees: [] }
    );
    setAssignedTo(guests.assigned);
    setInvitees(guests.invitees);
  }, [updatedData?.invitees, userDict]);

  useEffect(() => {
    const fetchFilesAndDocs = async () => {
      try {
        if (taskFilesAndDocs?.length !== 0) {
          let uploadedFiles = [];
          const filesIds = [];
          const docsIds = [];
          if (taskFilesAndDocs?.length > 0) {
            taskFilesAndDocs?.forEach((file) => {
              const fileParts = file?.split("/");
              if (file.includes("Document/")) {
                docsIds.push(fileParts[1]);
              } else filesIds.push(fileParts[1]);
            });
          }

          if (filesIds.length > 0) {
            const filesData = await Promise.all(
              filesIds.map(async (id) => {
                const { data: file } = await FileAPI.getById(id);
                return file;
              })
            );
            let formatedFiles;
            if (Array.isArray(filesData)) {
              formatedFiles = filesData?.map((file) => {
                const { name, reference } = file;
                return { name, reference };
              });
              uploadedFiles = [...uploadedFiles, ...formatedFiles];
            } else {
              formatedFiles = {
                name: filesData.name,
                reference: filesData.reference,
                fileUrl: filesData.contentsUrl,
              };
              uploadedFiles = [...uploadedFiles, formatedFiles];
            }
          }

          if (docsIds.length > 0) {
            const docsData = await Promise.all(
              docsIds.map(async (id) => {
                const { data: doc } = await DocumentAPI.getById(id);
                return doc;
              })
            );
            let formatedDocs;
            if (Array.isArray(docsData)) {
              formatedDocs = docsData?.map((doc) => {
                const { name, reference } = doc;
                return { name, reference };
              });
              uploadedFiles = [...uploadedFiles, ...formatedDocs];
            } else {
              formatedDocs = {
                name: docsData.name,
                reference: docsData.reference,
              };
              uploadedFiles = [...uploadedFiles, formatedDocs];
            }
          }
          setUpdatedData((prev) => ({ ...prev, files: uploadedFiles }));
        }
      } catch (error) {
        console.warn("Failed to fetch users and files", error);
      }
    };
    fetchFilesAndDocs();
  }, [taskFilesAndDocs]);

  const updateComments = useCallback(() => {
    const fetchComments = async () => {
      try {
        const { data: commentsData } = await CommentAPI.get({
          params: {
            association: updatedData?.reference,
          },
        });
        const getUserData = (allUsers, reference) => {
          if (!reference) {
            return "";
          }
          const found = allUsers?.find((user) => user.reference === reference);
          return found;
        };
        const formatedComments =
          commentsData?.map(async (info) => {
            let commentFilesUrls = [];
            if (info.files.length) {
              commentFilesUrls = await Promise.all(
                info?.files.map(async (f) => {
                  const { data: commentFile } = await FileAPI.getById(
                    f.split("/")[1]
                  );
                  return {
                    url: commentFile.contentsUrl,
                    contentType: commentFile.contentType,
                  };
                })
              );
            }
            const user = getUserData(users, info?.author);
            const formatedReplies =
              info?.replies?.map((reply) => ({
                ...reply,
                userData: getUserData(users, reply?.author),
              })) || [];
            const replies = await Promise.all(formatedReplies);
            return {
              ...info,
              userData: user,
              replies,
              doc: commentFilesUrls,
            };
          }) || [];
        const allComments = await Promise.all(formatedComments);
        setTaskComments(allComments);
      } catch (error) {
        console.warn("Failed to update comments");
      }
    };

    fetchComments();
  }, [updatedData?.reference, users]);

  useEffect(() => {
    updateComments();
  }, [updateComments]);

  const onDeleteRecurrenceModalClose = () => {
    setShowRecurrenceModal(false);
    setDeleteEventInstance(undefined);
    setTaskRecurrence(undefined);
    setIsProcessEditing(false);
    setRecurrenceAction();
  };

  const procesDeleteTask = useCallback(() => {
    if (!updatedData?.recurrence) {
      setShowDeleteModal(true);
    } else {
      setRecurrenceAction("Delete");
      setShowRecurrenceModal(true);
    }
  }, [updatedData?.recurrence]);

  useEffect(() => {
    const showHide = showComments ? "Hide Comments" : "Show Comments";
    const proceesComments = () => {
      if (taskComments.length === 0) {
        setShowAddComment((prev) => !prev);
      } else if (showHide !== "Add Comments") {
        setShowComments(showHide !== "Hide Comments");
      }
    };

    let options = [
      {
        title: "Complete",
        onClick: () => setShowCompleteModal(true),
      },
      {
        title: "Send Reminder",
        onClick: () => setIsSentReminderOpen(true),
      },
      {
        title: "Edit",
        onClick: () => setIsEditing(true),
      },
      {
        title: "Delete",
        onClick: () => procesDeleteTask(),
      },
      {
        title: `${taskComments.length > 0 ? showHide : "Add Comments"}`,
        onClick: () => proceesComments(),
      },
    ];

    if (updatedData?.status === "done") {
      options = options?.filter(
        (o) =>
          o.title === "Add Comments" ||
          o.title === "Show Comments" ||
          o.title === "Hide Comments"
      );
    }

    if (!canCompleteTask) {
      options = options.filter(
        (option) => option.title !== "Complete" || option.title !== "Complete"
      );
    }
    if (!currentUser?.isAdmin) {
      options = options.filter((option) => option.title !== "Send Reminder");
    }
    if (!canEditTask) {
      options = options.filter((option) => option.title !== "Edit");
    }
    if (!canDeleteTask) {
      options = options.filter((option) => option.title !== "Delete");
    }
    if (!canCommentTask) {
      options = options.filter((option) => option.title !== "Add Comments");
    }

    if (updatedData) {
      setMoreOptions(options);
    }
  }, [
    allStepsCompleted,
    updatedData,
    isSentReminderOpen,
    canCompleteTask,
    canEditTask,
    canDeleteTask,
    canCommentTask,
    currentUser?.isAdmin,
    currentUser.reference,
    currentUser.permissions?.task?.can_delete,
    currentUser.permissions?.task?.can_update,
    procesDeleteTask,
    taskComments.length,
    showComments,
    showAddComment,
  ]);

  const onCloseModal = useCallback(() => {
    setIsEditing(false);
  }, []);

  const processRecurrenceEditing = async () => {
    setRecurrenceAction("Edit");
    let newTask;
    try {
      if (deleteEventInstance === "all") {
        ({ data: newTask } = await EventAPI.patch(
          `${updatedData?.id}/$${deleteEventInstance}`,
          taskEdits,
          updatedData
        ));
      } else {
        ({ data: newTask } = await EventAPI.patch(
          deleteEventInstance
            ? `${updatedData?.id}/$${deleteEventInstance}?instanceStartDate=${taskRecurrence.value}`
            : updatedData.id,
          taskEdits,
          updatedData
        ));
      }
      await reloadAsset();

      onEditedTask({ ...updatedData, ...newTask });
      setUpdatedData((prev) => ({ ...prev, ...newTask }));
      setIsProcessEditing(false);
      setTaskEdits(undefined);
      setTaskRecurrence(undefined);
      setRecurrenceAction();
      setDeleteEventInstance(undefined);
      return null;
    } catch (error) {
      console.warn("Editing Task Error");
      setIsProcessEditing(false);
      setRecurrenceAction();
      setTaskEdits(undefined);
      setTaskRecurrence(undefined);
      setDeleteEventInstance(undefined);
      return null;
    }
  };

  const handleDeleteRecurrence = () => {
    setShowRecurrenceModal(false);
    if (isProcessEditing) {
      processRecurrenceEditing();
    } else {
      setShowDeleteModal(true);
    }
  };

  const onFinishedEditing = async (updatedTask) => {
    setRecurrenceAction("Edit");
    onCloseModal();
    const changedTask = {
      ...updatedData,
      ...updatedTask,
    };
    if (!updatedData?.recurrence) {
      try {
        const { data: newTask } = await EventAPI.patch(
          updatedData.id,
          changedTask,
          updatedData
        );

        await reloadAsset();
        onEditedTask(newTask);
        setUpdatedData((prev) => newTask ?? prev);
        setRecurrenceAction(undefined);
        toastMessage("Task saving success", toastIcon, toastCloseIcon);
      } catch (error) {
        setRecurrenceAction(undefined);
        console.warn("ERROR", error.message);
        toastError(
          `Task saving Failed. Please try again: ${error.message}`,
          toastErrorIcon,
          toastCloseIcon
        );
      }
    } else {
      setTaskEdits(changedTask);
      setIsProcessEditing(true);
      setShowRecurrenceModal(true);
    }
  };

  const handlePostComment = async (value) => {
    try {
      if (value.trim().length > 0 || uploadedAttachments.length > 0) {
        const commentObj = {
          association: updatedData?.reference,
          content: value,
          author: currentUser?.reference,
          files: uploadedAttachments,
        };
        const { data: newComment } = await CommentAPI.post(commentObj);
        newComment.userData = currentUser;
        newComment.notifyUsers = [currentUser.reference];
        const updatedComments = [...taskComments, newComment];
        setTaskComments((prev) => updatedComments ?? prev);
        updateComments();
      }
    } catch (error) {
      console.warn("Failed to post comment");
    }
  };
  const handlePostReply = async (value, commentId) => {
    if (value.trim().length > 0) {
      const tempObj = {
        content: value,
        author: currentUser.reference,
      };
      const { data: reply } = await CommentAPI.postWOP(
        `${commentId}/$addreply`,
        {
          ...tempObj,
        }
      );

      const replyComment = {
        ...reply,
        userData: currentUser,
        replies: [],
      };

      const updatedComments = taskComments.map((comment) => {
        if (comment.id === commentId) {
          return { ...comment, replies: [...comment.replies, replyComment] };
        }
        return comment;
      });

      setUpdatedData({ ...updatedData, comments: updatedComments });
      setTaskComments((prev) => updatedComments ?? prev);
    }
  };

  const handleRemindData = (key, value) => {
    const tempObj = { ...remindData };
    tempObj[key] = value;
    setRemindData(tempObj);
  };

  const handleAddReminder = (newReminder) => {
    const updatedTask = {
      ...updatedData,
      reminders: [...updatedData?.reminders, newReminder],
    };
    setUpdatedData((prev) => updatedTask ?? prev);
  };

  const handleSendReminder = async (note, sentTo) => {
    try {
      const Res = await EventAPI.postByIdWOP(updatedData.id, "$sendreminder", {
        note: note || "",
        sentTo,
      });

      handleAddReminder(Res.data);
    } catch (error) {
      console.warn("Failed to send reminder");
    }
  };

  const handleRemind = () => {
    handleSendReminder(remindData?.comment, [updatedData?.invitees[0]]);
    setIsSentReminderOpen(false);
  };

  const handleDeleteTask = async () => {
    try {
      setDeletingTask(true);
      setRecurrenceAction("Delete");
      switch (deleteEventInstance) {
        case SINGLE_EVENT_INSTANCE: {
          const { data: deletedEvent } = await EventAPI.delete(
            `${updatedData.id}/$${deleteEventInstance}`,
            { params: `instanceStartDate=${taskRecurrence.value}` }
          );
          onDeletedTask(deletedEvent);
          setTaskRecurrence(undefined);
          break;
        }
        case ALL_EVENT_INSTANCES: {
          const { data: deletedEvent } = await EventAPI.delete(
            `${updatedData.id}/$all`
          );
          onDeletedTask(deletedEvent);
          setTaskRecurrence(undefined);
          break;
        }
        case FUTURE_EVENT_INSTANCES: {
          const { data: deletedEvent } = await EventAPI.delete(
            `${updatedData.id}/$future`,
            { params: `instanceStartDate=${taskRecurrence.value}` }
          );
          setTaskRecurrence(undefined);
          onDeletedTask(deletedEvent);
          break;
        }
        default: {
          const { data: deletedEvent } = await EventAPI.delete(updatedData.id);
          onDeletedTask(deletedEvent);
        }
      }
      setDeleteEventInstance();
      setDeletingTask(false);
      setShowDeleteModal(false);
      reloadAsset();
    } catch (error) {
      setDeletingTask(false);
      setRecurrenceAction();
      setShowDeleteModal(false);
      console.warn("Failed to delete task");
    }
  };

  const processStatus = (rawStatus) => {
    if (rawStatus === SCHEDULED) {
      return "Scheduled";
    }
    if (rawStatus === CANCELLED) {
      return "Cancelled";
    }
    if (rawStatus === COMPLETE) {
      return "Complete";
    }
    return "";
  };

  const handleSetTaskStatus = useCallback(async () => {
    try {
      setRecurrenceAction(taskStatus === COMPLETE ? "Complete" : "Cancel");
      const updatedTask = {
        ...updatedData,
        steps:
          taskStatus === COMPLETE
            ? maintenanceSteps?.map((s) => ({ ...s, isCompleted: true }))
            : maintenanceSteps,
        endDate: new Date().toISOString(),
        duration: maintenanceDuration,
        status: taskStatus,
        closedBy: currentUser?.reference,
      };
      if (moment(updatedData?.startDate).diff(moment()) > 0) {
        updatedTask.startDate = new Date().toISOString();
      }

      if (updatedData.recurrence) {
        setShowCompleteModal(false);
        setTaskEdits(updatedTask);
        const { data: updatedTaskEvent } = await EventAPI.patchByIdWOP(
          updatedData?.id,
          "$single?",
          updatedTask,
          updatedData,
          {
            params: {
              instanceStartDate: updatedData?.instanceStartDate,
              lastUpdated: updatedData?.metadata?.lastUpdated,
            },
          }
        );
        setUpdatedData(updatedTaskEvent);
        setTaskStatus(updatedTaskEvent?.status);
        await reloadAsset();
      } else {
        const { data: updatedTaskEvent } = await EventAPI.patch(
          updatedData.id,
          updatedTask,
          updatedData
        );
        setUpdatedData(updatedTaskEvent);
        setShowCompleteModal(false);
        setRecurrenceAction();
        setTaskStatus(updatedTaskEvent?.status);
        await reloadAsset();
      }
    } catch (error) {
      setShowCompleteModal(false);
      setRecurrenceAction();
      console.warn("Unable to set task status");
    }
  }, [
    currentUser?.reference,
    maintenanceDuration,
    maintenanceSteps,
    reloadAsset,
    taskStatus,
    updatedData,
  ]);

  // eslint-disable-next-line no-unused-vars
  const removeAttachedFile = (id, file) => {
    const removedFiles = filesToUpload.filter((_file, index) => index !== id);
    setFilesToUpload(removedFiles);
  };

  const onAddPhoto = async (photo, data = {}, progressCallback) => {
    const fileRef = await uploadFileWithData(photo, data, progressCallback);
    return fileRef;
  };
  const onAddVideo = async (video, data = {}, progressCallback) => {
    const fileRef = await uploadFileWithData(video, data, progressCallback);
    return fileRef;
  };
  const onAddFile = async (doc, data = {}, progressCallback) => {
    const fileRef = await uploadFileWithData(doc, data, progressCallback);
    return fileRef;
  };

  const onUpload = useCallback(async (files, progressCallback) => {
    const handleProgressCallback = (loaded, total, filename) => {
      progressCallback(loaded, total, filename);
    };

    const result = await Promise.all(
      files.map(async ({ name, type, docType, isFavorited, original }) => {
        let res;
        const data = {
          name,
          docType,
          isFavorited,
          contentType: original.type,
          size: original.size,
        };

        if (type?.includes("image")) {
          res = await onAddPhoto(original, data, (loaded, total) =>
            handleProgressCallback(loaded, total, name)
          );
        } else if (type?.includes("video")) {
          res = await onAddVideo(original, data, (loaded, total) =>
            handleProgressCallback(loaded, total, name)
          );
        } else {
          res = await onAddFile(original, data, (loaded, total) =>
            handleProgressCallback(loaded, total, name)
          );
        }

        return { ...data, reference: res };
      })
    );
    return result;
  }, []);

  const handleFilesUploaded = useCallback(async () => {
    try {
      const filteredFiles = filesToUpload.filter((file) => !file.isEditing);
      const res = await onUpload(filteredFiles, () => {});

      setFilesToUpload([]);
      const uploadedFilesRefs = res.map((f) => f.reference);
      setUploadedAttachments((prev) => [...prev, ...uploadedFilesRefs]);

      return res;
    } catch (error) {
      setAddCommentFiles(false);
      setFilesToUpload([]);
      console.warn("Failed to upload files");
      return null;
    }
  }, [filesToUpload, onUpload]);

  const handleFilesAdded = React.useCallback(
    (addedFiles) => {
      setFilesToUpload(addedFiles);
    },
    [setFilesToUpload]
  );
  const handleFilesUpdated = (updatedFiles) => {
    setFilesToUpload(updatedFiles);
  };

  const handleAddCommentFiles = async () => {
    try {
      await handleFilesUploaded();
      setAddCommentFiles(false);
      setFilesToUpload([]);
    } catch (error) {
      setAddCommentFiles(false);
      setFilesToUpload([]);
      console.warn("handle add comment files error");
    }
  };

  useEffect(() => {
    setStepsNotChanged(isEqual(originalSteps, maintenanceSteps));
  }, [maintenanceSteps, originalSteps]);

  const updateSteps = async () => {
    const changedTask = {
      ...updatedData,
      steps: maintenanceSteps,
    };
    let newTask;
    if (updatedData.recurrence) {
      ({ data: newTask } = await EventAPI.patchByIdWOP(
        updatedData?.id,
        "$single?",
        changedTask,
        updatedData,
        {
          params: {
            instanceStartDate: updatedData?.instanceStartDate,
            lastUpdated: updatedData?.metadata?.lastUpdated,
          },
        }
      ));
    } else {
      ({ data: newTask } = await EventAPI.patch(
        updatedData.id,
        changedTask,
        updatedData
      ));
    }
    await reloadAsset();
    onEditedTask(newTask);
    setUpdatedData((prev) => newTask ?? prev);
    return newTask;
  };

  const handleTaskSteps = async (sopId = undefined) => {
    setShowTaskModal(false);

    if (!stepsNotChanged) {
      await updateSteps();
      if (allStepsCompleted) {
        if (sopId) {
          history.push(GET_SOP_DETAILS_PATH(sopId));
        } else if (canCompleteTask) setShowCompleteModal(true);
      }
    } else if (allStepsCompleted && taskStatus === SCHEDULED) {
      if (sopId) history.push(GET_SOP_DETAILS_PATH(sopId));
      else if (canCompleteTask) setShowCompleteModal(true);
    } else if (sopId) {
      history.push(GET_SOP_DETAILS_PATH(sopId));
    }
  };

  const handleViewModalBtnClick = () => {
    handleTaskSteps();
  };

  useEffect(() => {
    let timer = null;
    if (!showComments && !showAddComment) {
      timer = setTimeout(() => {
        setcanHideComments(showComments || showAddComment);
      }, 350);
    } else {
      setcanHideComments(showComments || showAddComment);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [showAddComment, showComments]);

  return (
    <div className="flex justify-between py-6 pl-8 pr-4 border-t items-start">
      <div className="flex flex-col w-1/2">
        <div className="flex flex-row items-center">
          {taskComments?.length > 0 && (
            <IconButton
              icon={chevronRightIcon}
              className={`${
                showComments
                  ? "transform rotate-90 origin-center mr-2.5 ml-0 mt-4 mb-3"
                  : "mr-2.5 ml-0 mt-4 mb-3"
              }`}
              imgClassName="w-4 h-4"
              onClick={() => setShowComments((prev) => !prev)}
            />
          )}
          <h3
            className={`${
              taskComments?.length === 0 ? "ml-7 font-bold" : "font-bold"
            }`}
          >
            {updatedData?.name}
          </h3>
          <IconButton
            icon={returnIcon}
            className="ml-3"
            imgClassName="w-5 h-5"
            onClick={() => setShowTaskModal(true)}
          />
        </div>
        <div className="overflow-auto pr-6">
          {canHideComments && (
            <CommentSection
              attachIcon={attachmentIcon}
              onAttachIconClick={() => setAddCommentFiles(true)}
              isMaintenanceComment
              data={taskComments || []}
              handlePostComment={handlePostComment}
              handlePostReply={handlePostReply}
              allowNewComment
              hideCommentsTag
              showNewCommentSection={showAddComment}
              setShowNewCommentSection={setShowAddComment}
              canAddComment={canCommentTask}
            />
          )}
        </div>
      </div>
      <div className="relative flex flex-row">
        <div className="pl-4 flex flex-col items-end">
          <div className="flex flex-row mb-2">
            <h3 className="font-bold">{processStatus(updatedData?.status)}</h3>
            {updatedData?.status.toLowerCase() === "done" && (
              <div className="ml-3 bg-brandGreen rounded-full w-6 h-6 flex items-center justify-center">
                <img src={checkmark} alt="check mark" className="w-5 h-5" />
              </div>
            )}
          </div>
          <h3 className="mb-2">
            {updatedData?.status.toLowerCase() === "scheduled"
              ? `${moment(updatedData?.startDate).format(
                  `${isAlldayEvent ? "ll" : "lll"}`
                )}${isAlldayEvent ? " (All Day)" : ""}`
              : moment(updatedData?.endDate).format("lll")}
          </h3>
          <div className="text-right">
            <h3 className="capitalize">
              {closedBy ||
                assignedTo?.map((a) => (
                  <span key={a}>
                    {a}
                    <br />
                  </span>
                ))}
            </h3>
          </div>
          {updatedData?.reminders && updatedData?.reminders?.length > 0 && (
            <h3 className="text-gray-500 font-medium mt-2">
              {`Reminder Sent ${moment(
                updatedData?.reminders[updatedData?.reminders?.length - 1]?.sent
              ).format("MMM DD, YYYY h:mm A")}`}
            </h3>
          )}
        </div>
        <div className="w-20 h-7 flex justify-end align-center">
          <IconButton
            icon={moreInformationIcon}
            dropdownItems={moreOptions}
            wrapperClassName="flex justify-end align-center"
            imgClassName="w-5 h-5"
            hideDropDownChevron={false}
          />
          {isSentReminderOpen && (
            <InputModal
              title="Send Reminder"
              buttonTitle="Submit to Send"
              placeholder="Include a personal note (optional)"
              onClose={() => setIsSentReminderOpen(false)}
              inputValue={remindData?.comment}
              onInputChange={(val) => handleRemindData("comment", val)}
              onSubmit={handleRemind}
            />
          )}
        </div>
      </div>

      <Modal
        closeTimeoutMS={200}
        title="Add attachments"
        isOpen={addCommentFiles}
        primaryButtonTitle="Add File"
        primaryButtonOnClick={handleAddCommentFiles}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={() => {
          setFilesToUpload([]);
          setAddCommentFiles(false);
        }}
        hideFooter
        disabled={filesToUpload.length === 0}
        overlayStyle={overlayStyle}
        contentStyle={contentStyle}
        titleStyle={titleStyle}
        headerStyle={headerStyle}
      >
        {filesToUpload?.map((file, index) => {
          return (
            <div
              className="flex justify-between items-center border-b py-2"
              key={file?.name}
            >
              <div className="flex flex-3">
                <img
                  className="w-5 h-5 mr-4"
                  src={uploadArrowGrayIcon}
                  alt="upload icon"
                />
                <p className="font-semibold text-gray-200">{file?.name}</p>
              </div>
              <div className="flex flex-2 justify-between">
                <p>My Uploads</p>
                <button
                  type="button"
                  onClick={() => removeAttachedFile(index, file)}
                >
                  <img className="h-5" src={deleteIcon} alt="delete icon" />
                </button>
              </div>
            </div>
          );
        })}
        <SimpleFileUpLoad
          files={filesToUpload}
          onFilesAdded={handleFilesAdded}
          onFilesUpdated={handleFilesUpdated}
          onFilesUploaded={handleFilesUploaded}
        />
      </Modal>

      <Modal
        closeTimeoutMS={200}
        childContainerClassName="!pr-4 !pl-4 overflow-y-scroll"
        title="Task"
        isOpen={showTaskModal}
        primaryButtonTitle={
          taskStatus === COMPLETE || taskStatus === CANCELLED
            ? "Close"
            : buttonAction
        }
        primaryButtonOnClick={() => {
          if (taskStatus === COMPLETE || taskStatus === CANCELLED)
            setShowTaskModal(false);
          else handleViewModalBtnClick();
        }}
        onRequestModalClose={() => {
          setShowTaskModal(false);
          if (!stepsNotChanged) updateSteps();
        }}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
        overlayStyle={overlayStyle}
        contentStyle={contentStyle}
        titleStyle={titleStyle}
        headerStyle={headerStyle}
        buttonCnClass="mb-1"
      >
        <AssetMaintenanceScheduledTaskView
          isLoading={false}
          currentUser={currentUser}
          invitees={invitees}
          assignedTo={assignedTo}
          data={updatedData}
          setUpdatedSteps={setMaintenanceSteps}
          setAllStepsCompleted={setAllStepsCompleted}
          allStepsCompleted={allStepsCompleted}
          navigateToSop={handleTaskSteps}
          setViewModalButtonAction={setButtonAction}
          taskStatus={taskStatus}
          associationRef={associationRef}
          associationName={association?.name}
          canCompleteStep={canCompleteStep}
        />
      </Modal>

      <Modal
        closeTimeoutMS={200}
        title="Complete or cancel task"
        childContainerClassName="pt-3 pr-7 h-full"
        isOpen={showCompleteModal}
        primaryButtonTitle={
          taskStatus !== COMPLETE ? "Cancel Task" : "Complete Task"
        }
        primaryButtonOnClick={handleSetTaskStatus}
        tertiaryButtonTitle={
          taskStatus === COMPLETE || taskStatus === CANCELLED
            ? "Discard"
            : "Close"
        }
        onRequestModalClose={() => {
          setTaskStatus(taskData?.status);
          setShowCompleteModal(false);
        }}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
        disabled={disableButton()}
        overlayStyle={overlayStyle}
        contentStyle={contentStyle}
        titleStyle={titleStyle}
        headerStyle={headerStyle}
        customHeight="650px"
        buttonCnClass="mb-1"
        alert
      >
        <AssetMaintenanceScheduledTaskStatus
          currentUser={currentUser}
          data={updatedData}
          allStepsCompleted={allStepsCompleted}
          setTaskStatus={setTaskStatus}
          setMaintenanceDuration={setMaintenanceDuration}
          isTaskDurationRequired={isTaskDurationRequired}
          setStatusHasChanged={setStatusHasChanged}
        />
      </Modal>
      <TaskRecurrenceInstanceModal
        showRecurrenceModal={showRecurrenceModal}
        recurrenceAtion={recurrenceAtion}
        onDeleteRecurrenceModalClose={onDeleteRecurrenceModalClose}
        handleDeleteRecurrence={handleDeleteRecurrence}
        deleteEventInstance={deleteEventInstance}
        setDeleteEventInstance={setDeleteEventInstance}
        taskRecurrence={taskRecurrence}
        setTaskRecurrence={setTaskRecurrence}
        recurrenceOptions={recurrenceOptions}
      />

      <Modal
        closeTimeoutMS={200}
        title={`Delete ${
          taskRecurrence?.label ? "task recurrence instance" : "task"
        }`}
        isOpen={showDeleteModal}
        primaryButtonTitle="Yes, delete"
        primaryButtonOnClick={handleDeleteTask}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={() => {
          setDeleteEventInstance();
          setTaskRecurrence(undefined);
          setShowDeleteModal(false);
        }}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
        overlayStyle={overlayStyle}
        contentStyle={contentStyle}
        titleStyle={titleStyle}
        headerStyle={headerStyle}
        alert
      >
        {deletingTask && <Spinner overlay notFullScreen />}
        {!deletingTask && (
          <p className="text-base mb-2">
            Are you sure you want to delete{" "}
            {updatedData?.recurrence
              ? taskRecurrence?.label
              : updatedData?.name ?? "this task"}
            ? Once deleted, it cannot be recovered.
          </p>
        )}
      </Modal>

      {isEditing && (
        <MaintenanceScheduleModal
          currentUser={currentUser}
          isEditing={isEditing}
          onCloseModal={() => setIsEditing(false)}
          assetData={updatedData}
          invitees={invitees}
          onFinishedEditing={onFinishedEditing}
          assetMembersOptions={assetMembersOptions}
          reloadAsset={reloadAsset}
          association={association}
        />
      )}
    </div>
  );
};

MaintenanceSection.propTypes = {
  /**
   * maintance data
   */
  taskData: PropTypes.shape({
    id: PropTypes.string,
    reference: PropTypes.string,
    name: PropTypes.string,
    completed: PropTypes.bool,
    recurrence: PropTypes.string,
    endDate: PropTypes.string,
    startDate: PropTypes.string,
    status: PropTypes.string,
    files: PropTypes.arrayOf("string"),
    invitees: PropTypes.arrayOf("string"),
    reminders: PropTypes.arrayOf(PropTypes.shape({})),
    comments: PropTypes.arrayOf(PropTypes.shape({})),
    handleSendReminder: PropTypes.func,
    steps: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  currentUser: PropTypes.shape({
    isAdmin: PropTypes.shape({}),
    reference: PropTypes.shape({}),
    permissions: PropTypes.shape({
      task: PropTypes.shape({
        can_delete: PropTypes.string,
        can_update: PropTypes.string,
      }),
    }),
  }),
  onDeletedTask: PropTypes.func,
  assetMembersOptions: PropTypes.arrayOf(objectOf),
  onEditedTask: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  reloadAsset: PropTypes.func,
  isTaskDurationRequired: PropTypes.bool,
  association: PropTypes.shape({ name: PropTypes.string }),
};

MaintenanceSection.defaultProps = {
  taskData: {},
  currentUser: {},
  onDeletedTask: undefined,
  assetMembersOptions: [],
  onEditedTask: undefined,
  users: [],
  reloadAsset: undefined,
  isTaskDurationRequired: false,
  association: { name: "" },
};
export default MaintenanceSection;

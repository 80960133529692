import moment from "moment-timezone";
import { guessTimeZone } from "../Time";

const getDateTimeInTimezone = ({ date, timezone, format }) => {
  const timezoneString = timezone || guessTimeZone()?.label; // default

  if (format) {
    return moment.tz(date, timezoneString).format(format);
  }
  return moment.tz(date, timezoneString);
};

export default getDateTimeInTimezone;

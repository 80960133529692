const getTicketTypesDD = (config) => {
  const optionList = [];
  config?.management?.ticket?.types?.forEach((type) => {
    if (type.selected) {
      optionList.push({ label: type.display, value: type.id });
    }
  });
  return optionList;
};

export default getTicketTypesDD;

import { DocumentAPI } from "@griffingroupglobal/eslib-api";
import moment from "moment";
import { INVOICE, VENDOR_INVOICE } from "../constants";

const getDocumentPdfUrl = async (id) => {
  try {
    const { data } = await DocumentAPI.postByIdWOP(id, "$createpdf");
    return data?.pdfUrl;
  } catch (err) {
    console.warn(err);
  }
  return "";
};

const getDocumentData = async (reference) => {
  if (!reference) {
    return "";
  }
  const newId = reference?.split("/")[1];
  try {
    const { data } = await DocumentAPI.getById(newId);
    return data;
  } catch (err) {
    return "";
  }
};

const getDocumentUser = (usr) => {
  if (!usr) {
    return "Unnamed";
  }
  if (usr.kind === "company") {
    return usr.companyName;
  }
  return `${usr.name?.firstName} ${usr.name?.lastName}` || "Unnamed";
};

export function decorateDocumentsWithUserData(docs, userDict) {
  const documents = docs;
  for (let i = 0; i < documents.length; i += 1) {
    const doc = { ...documents[i] };
    const creator = doc.resource.createdBy;
    const modifier = doc.resource.modifiedBy;
    const { vendor } = doc.resource;
    const submittedBy = doc.resource?.submittedToWorkflowBy;

    const foundCreatorUser = userDict[creator];
    if (foundCreatorUser) {
      doc.resource.creator = foundCreatorUser;
    }
    const foundModifierUser = userDict[modifier];
    if (foundModifierUser) {
      doc.resource.modifier = foundModifierUser;
    }
    const foundVendorUser = userDict[vendor];
    if (userDict[vendor]) {
      doc.resource.vendorUser = foundVendorUser;
    }
    const foundSubmittedByUser = userDict[submittedBy];
    if (foundSubmittedByUser) {
      doc.resource.submittedBy = foundSubmittedByUser;
    }
    documents[i] = doc;
  }

  return documents;
}

const getDocsDictionaryByDocType = ({ documents, getDocType, favorites }) => {
  const favoriteDict = favorites?.reduce((dict, item) => {
    // eslint-disable-next-line no-param-reassign
    dict[item?.item?.reference?.split("/")[1]] = true;

    return dict;
  }, {});

  const views = documents?.reduce(
    (dict, doc) => {
      // do not show draft vendor invoices
      const isDraftVendorInvoice =
        doc?.status === "draft" && doc?.docType === VENDOR_INVOICE;

      // do not show draft invoices
      const isNonMergedInvoice =
        doc?.docType === INVOICE && !["closed", "merged"].includes(doc?.status);

      if (!isDraftVendorInvoice && !isNonMergedInvoice) {
        if (!dict?.[doc?.docType]) {
          // eslint-disable-next-line no-param-reassign
          dict[doc?.docType] = {
            sum: 1,
            docs: [
              {
                ...doc,
                isFavorited: favoriteDict[doc?.id],
                vendorName: getDocumentUser(doc.vendorUser),
              },
            ],
          };
        } else if (dict?.[doc?.docType]) {
          // eslint-disable-next-line no-param-reassign
          dict[doc?.docType] = {
            sum: dict[doc?.docType]?.sum + 1,
            docs: [
              ...dict[doc?.docType]?.docs,
              {
                ...doc,
                isFavorited: favoriteDict[doc?.id],
                vendorName: getDocumentUser(doc.vendorUser),
              },
            ],
          };
        }

        if (favoriteDict[doc?.id]) {
          // eslint-disable-next-line no-param-reassign
          dict.isFavorited = {
            sum: dict.isFavorited.sum + 1,
            docs: [...dict.isFavorited.docs, doc],
          };
        }

        if (doc?.status === "draft") {
          // eslint-disable-next-line no-param-reassign
          dict.isDraft = {
            sum: dict.isDraft.sum + 1,
            docs: [...dict.isDraft.docs, doc],
          };
        }

        dict.allDocs.push({
          ...doc,
          resource: doc.name,
          type: getDocType(doc.docType),
          isFavorited:
            doc.isFavorited ??
            favorites.some((fav) => fav.item.reference.includes(doc.id)),
          createdBy: getDocumentUser(doc.creator),
          modifiedBy: getDocumentUser(doc.modifier),
          vendorName: getDocumentUser(doc.vendorUser),
          modifiedDate: doc.metadata?.lastUpdated
            ? moment(doc.metadata.lastUpdated).format("MM/DD/YYYY")
            : "--",
        });
      }

      return dict;
    },
    {
      isFavorited: { sum: 0, docs: [] },
      isDraft: { sum: 0, docs: [] },
      allDocs: [],
    }
  );

  return views;
};

export {
  getDocumentPdfUrl,
  getDocumentData,
  getDocumentUser,
  getDocsDictionaryByDocType,
};

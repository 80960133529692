import React, { useCallback, useState } from "react";
import cntl from "cntl";
import * as yup from "yup";
import PropTypes from "prop-types";
import BaseButton from "../Buttons/BaseButton";
import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";
import EditRemoveActions from "../EditRemoveActions/EditRemoveActions";
import Dropdown from "../Dropdown/Dropdown";
import {
  ADD_ATTRIBUTE_POPUP,
  ATTRIBUTE_TYPES,
  TOGGLE_POSITIONED_POPUP,
} from "../../../constants";
import PlusCirlceIconDarkGreen from "../../assets/images/plusCircleIconDarkGreen.svg";
import { useAppState } from "../../../state/appState";
import useAttributes from "../../../hooks/useAttributes";

const customStyle = {
  valueContainer: {
    maxHeight: "80px",
    overflow: "scroll",
  },
};

const tertiaryButtonCN = cntl`
text-brandDarkGreen
py-4
text-base
`;

const textCN = (selected) => cntl`
font-medium
truncate
w-1/3
${selected ? "" : "text-gray-200"}
`;

const ManagementAttributes = ({
  attributeSettings,
  dispatchAttributeSettings,
}) => {
  const [addMode, setAddMode] = useState(false);
  const [, appStateDispatch] = useAppState();
  const [, reloadMeasurements] = useAttributes();

  const onAddCategory = useCallback(() => {
    dispatchAttributeSettings({
      type: "addAttribute",
    });
  }, [dispatchAttributeSettings]);

  const onEditCategory = useCallback(
    (index, newValue) => {
      dispatchAttributeSettings({
        type: "changeAttributeDetail",
        index,
        value: newValue,
      });
    },
    [dispatchAttributeSettings]
  );

  const onEditFieldType = useCallback(
    (value, index) => {
      dispatchAttributeSettings({
        type: "changeFieldType",
        index,
        value,
      });
    },
    [dispatchAttributeSettings]
  );

  const onEditFieldValues = useCallback(
    (value, index) => {
      dispatchAttributeSettings({
        type: "changeFieldValues",
        index,
        value,
      });
    },
    [dispatchAttributeSettings]
  );

  const onEditUnitFieldValues = useCallback(
    (value, index) => {
      dispatchAttributeSettings({
        type: "changeUnitFieldValues",
        index,
        value,
      });
    },
    [dispatchAttributeSettings]
  );

  const onRemoveCategory = useCallback(
    (index) => {
      dispatchAttributeSettings({
        type: "removeAttribute",
        index,
      });
    },
    [dispatchAttributeSettings]
  );

  const toggleEditing = useCallback(
    (index) => {
      dispatchAttributeSettings({
        type: "changeAttributeEditing",
        index,
      });
    },
    [dispatchAttributeSettings]
  );

  const selectCategory = useCallback(
    (index) => {
      dispatchAttributeSettings({
        type: "selectAttribute",
        index,
      });
    },
    [dispatchAttributeSettings]
  );

  const checkForEmptyValue = useCallback(
    (value, pIdx) => {
      if (value === "") onRemoveCategory(pIdx);
    },
    [onRemoveCategory]
  );

  const hasDuplicate = useCallback(
    (value, idx) => {
      return attributeSettings.some((x, index) => {
        return x.detail.toLowerCase() === value.toLowerCase() && index !== idx;
      });
    },
    [attributeSettings]
  );

  const handleEnter = useCallback(
    (event, value, pIdx) => {
      // checks if the Enter key was pressed
      if (event.keyCode === 13) {
        if (hasDuplicate(value, pIdx)) {
          return false;
        }
        if (value === "") {
          checkForEmptyValue(value, pIdx);
          return undefined;
        }
        if (addMode) onAddCategory();
        toggleEditing(pIdx);
        onEditCategory(pIdx, value);
      }
      return undefined;
    },
    [
      addMode,
      checkForEmptyValue,
      hasDuplicate,
      onAddCategory,
      toggleEditing,
      onEditCategory,
    ]
  );

  const handleAddAttribute = () => {
    appStateDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      position: {
        x:
          window.innerWidth < 1200
            ? window.innerWidth / 5
            : window.innerWidth / 2.5,
        y: window.innerHeight / 5,
      },
      popupData: {
        item: {
          onAddElements: () => {},
          measurements: [],
          reloadAttributes: reloadMeasurements,
          isAdminPage: true,
        },
      },
      popupType: ADD_ATTRIBUTE_POPUP,
    });
  };

  return (
    <div>
      <div className="flex flex-col">
        <div className="flex flex-row py-2 justify-between mr-6">
          <h2 className="font-semibold py-4 uppercase">attributes</h2>
        </div>
        <div className="ml-6 justify-between flex w-3/4">
          <div className="w-1/3 text-md text-gray-200">Name</div>
          <div className="w-1/3 text-md text-gray-200">Type</div>
          <div className="w-1/3 text-md text-gray-200">Options</div>
        </div>
        <div className="border-b border-gray-150" />
        {attributeSettings?.map((attribute, index) => (
          <div className="flex" key={attribute.id}>
            <EditRemoveActions
              index={index}
              isEditing={attribute?.isEditing}
              toggleEditing={toggleEditing}
              handleRemove={onRemoveCategory}
              className="pt-2 w-full"
              setAddMode={setAddMode}
              hideRemove={attribute?.count}
              hideEdit={attribute?.count}
            >
              <Checkbox
                checked={attribute?.selected}
                onChange={() => selectCategory(index)}
                disabled={attribute?.count}
              />

              {(attribute?.isEditing && (
                <div className="pl-2 flex items-start w-3/4">
                  <div className="w-1/3">
                    <Input
                      value={attribute.detail}
                      placeholder="Name"
                      onChange={(val) => onEditCategory(index, val)}
                      handleEnter={(event) =>
                        handleEnter(event, attribute.detail, index)
                      }
                      autoFocus
                    />
                  </div>
                  <div className="w-1/3">
                    <Dropdown
                      options={ATTRIBUTE_TYPES}
                      value={ATTRIBUTE_TYPES.find(
                        (type) => type.value === attribute?.fieldType
                      )}
                      onChange={(val) => onEditFieldType(val, index)}
                      className="px-2"
                      validation={yup.mixed().required()}
                      disableClear
                    />
                  </div>
                  {attribute?.fieldType === "ddl" && (
                    <div className="w-1/3">
                      <Dropdown
                        isMulti
                        isCreatable
                        options={attribute?.fieldValues}
                        onChange={(values) => onEditFieldValues(values, index)}
                        value={attribute?.fieldValues}
                        disableClear
                        customStyle={customStyle}
                        placeholder="Select"
                        isDisabled={attribute?.saved}
                        validation={yup.mixed().required()}
                      />
                    </div>
                  )}
                  {attribute?.unit?.fieldType && (
                    <div className="w-1/3">
                      <Dropdown
                        isMulti
                        isCreatable
                        options={attribute?.unit?.fieldValues}
                        onChange={(values) =>
                          onEditUnitFieldValues(values, index)
                        }
                        value={attribute?.unit?.fieldValues}
                        disableClear
                        customStyle={customStyle}
                        placeholder="Select"
                        isDisabled={attribute?.saved}
                        validation={yup.mixed().required()}
                      />
                    </div>
                  )}
                </div>
              )) || (
                <div className="justify-between flex w-3/4 ml-2">
                  <div className={textCN(attribute?.selected)}>
                    {attribute?.detail}
                  </div>
                  <div className={textCN(attribute?.selected)}>
                    {
                      ATTRIBUTE_TYPES.find(
                        (type) => type.value === attribute?.fieldType
                      )?.label
                    }
                  </div>
                  <div className={textCN(attribute?.selected)}>
                    {attribute?.fieldValues
                      ?.map((item) => item.label)
                      .toString()}
                    {attribute?.unit?.fieldValues
                      ?.map((item) => item.label)
                      .toString()}
                  </div>
                </div>
              )}
            </EditRemoveActions>
          </div>
        ))}
        <div className="py-4 flex flex-row items-center">
          <img
            className="h-4 w-4"
            src={PlusCirlceIconDarkGreen}
            alt="plus circle icon green"
          />
          <BaseButton
            title="Add Attribute"
            onClick={() => handleAddAttribute()}
            className={tertiaryButtonCN}
          />
        </div>
      </div>
    </div>
  );
};

ManagementAttributes.propTypes = {
  attributeSettings: PropTypes.arrayOf(
    PropTypes.shape({
      detail: PropTypes.string,
      isEditing: PropTypes.string,
      isOpen: PropTypes.bool,
      count: PropTypes.number,
      selected: PropTypes.bool,
    })
  ),
  dispatchAttributeSettings: PropTypes.func,
};

ManagementAttributes.defaultProps = {
  attributeSettings: [],
  dispatchAttributeSettings: undefined,
};

export default ManagementAttributes;

import { useReducer } from "react";
import { cloneDeep } from "lodash";

const defaultState = {};

const reducer = (info, action) => {
  switch (action.type) {
    case "reset":
      return cloneDeep(action.info) ?? defaultState;
    case "initialize":
      return {
        lineitemId: action?.lineitemId,
        vendor: action?.vendor,
        originalLineItem: action?.originalLineItem,
      };
    case "setVendor":
      return {
        ...info,
        vendor: action?.vendor?.value,
      };
    default:
      return info;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};

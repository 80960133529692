import React from "react";
import PropTypes from "prop-types";

const Chevron = ({
  open,
  disabled,
  color,
  disabledColor,
  rotate,
  chevronSizes,
}) => {
  return (
    <svg
      id="subBtn-svg"
      width={chevronSizes.width}
      height={chevronSizes.height}
      viewBox="0 0 15 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid meet"
      className="transition-all"
      style={{
        transform: open
          ? "rotate(90deg)"
          : `${rotate ? `rotate(${rotate}deg)` : "rotate(0deg)"}`,
      }}
    >
      <g stroke={!disabled ? color : disabledColor}>
        <polyline
          id="Path"
          points="4.5 13 10.5 8 4.5 2"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="3"
          fill="none"
        />
      </g>
    </svg>
  );
};

Chevron.propTypes = {
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  disabledColor: PropTypes.string,
  rotate: PropTypes.string,
  chevronSizes: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
};

Chevron.defaultProps = {
  open: false,
  disabled: false,
  color: "#333333",
  disabledColor: "#d0d0d0",
  /**
   * rotate chevron as needed ie. -180,90,45
   */
  rotate: undefined,
  chevronSizes: {
    width: "10px",
    height: "10px",
  },
};

export default Chevron;

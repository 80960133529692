import { useCallback, useState } from "react";
import useNotificationsPatch from "../../../hooks/useNotificationsPatch";

export default () => {
  const { mutate: update } = useNotificationsPatch();
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState({
    isOpen: false,
    value: "",
    setSearch: (val) =>
      setSearch((prev) => {
        return {
          ...prev,
          value: val,
        };
      }),
    open: (val, e) => {
      if (e && e.target.code !== "Enter") {
        return;
      }
      setSearch((prev) => ({ ...prev, isOpen: val, value: "" }));
    },
  });
  const [multiselectOpen, setMultiselectOpen] = useState(false);

  const handleSelect = (notif) => {
    const found = selected?.find((item) => item.reference === notif.reference);
    if (!found) {
      setSelected((prev) => [...prev, notif]);
    } else {
      setSelected((prev) =>
        prev.filter((item) => item.reference !== notif.reference)
      );
    }
  };

  const handleMultiSelect = (option, notifs, e) => {
    if (e && e.target.code !== "Enter") {
      return;
    }

    setMultiselectOpen(false);
    switch (option) {
      case "All": {
        setSelected(notifs);
        break;
      }

      case "None": {
        setSelected([]);
        break;
      }

      case "Read": {
        setSelected(notifs.filter((notif) => notif?.read === true));
        break;
      }

      case "Unread": {
        setSelected(notifs.filter((notif) => notif?.read === false));
        break;
      }

      case "Flagged": {
        setSelected(notifs.filter((notif) => notif?.flagged === true));
        break;
      }

      default:
        break;
    }
  };

  /* HANDLE FLAGGING UNDER ONE BUTTON */
  /* If all items are flagged, we can unflag them otherwise flag the whole group */

  const handleFlagged = useCallback(() => {
    if (!selected || selected.length === 0) return;

    const allFlagged = selected.every((item) => item.flagged);

    if (allFlagged) {
      update([selected, "$unflag"]);
    } else {
      update([selected, "$flag"]);
    }

    setSelected([]);
  }, [selected, update]);

  const handleAction = useCallback(
    (command, e) => {
      if (e && e.target.code !== "Enter") {
        return;
      }
      if (command === "$dismissed" && selected.length === 0) return;
      update([selected, command]);
      setSelected([]);
    },
    [selected, update]
  );

  const [filters, setFilters] = useState({
    isOpen: false,
    open: (e) => {
      if (e && e.target.code !== "Enter") {
        return;
      }

      setFilters((prev) => ({
        ...prev,
        isOpen: !prev.isOpen,
        values: prev.values.map((x) => ({ ...x, value: false })),
      }));
    },
    change: (index) => {
      setFilters((prev) => {
        const v = prev.values;
        v[index].value = !v[index].value;
        return { ...prev, values: v };
      });
    },
    values: [
      { label: "read", value: false, filter: (x) => x.read },
      { label: "unread", value: false, filter: (x) => !x.read },
      { label: "flagged", value: false, filter: (x) => x.flagged },
      { label: "unflagged", value: false, filter: (x) => !x.flagged },
      { label: "threat", value: false, filter: (x) => x.type === "Threat" },
    ],
  });

  const filter = useCallback(
    (notifs) => {
      const selectedFilters = filters.values.filter((item) => item.value);
      if (search.value || selectedFilters.length > 0) {
        return notifs.filter((item) => {
          const filterValue =
            selectedFilters.length > 0
              ? selectedFilters.find((x) => x.filter(item))
              : true;
          return (
            filterValue &&
            item.title.toLowerCase().includes(search.value.toLowerCase())
          );
        });
      }
      return notifs;
    },
    [filters.values, search.value]
  );

  return {
    handleSelect,
    selected,
    handleAction,
    search: { ...search, handleSearch: search.setSearch, filter },
    multiselectOpen,
    setMultiselectOpen,
    handleMultiSelect,
    handleFlagged,
    filters,
  };
};

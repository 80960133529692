import cntl from "cntl";
import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import TrashIcon from "../../assets/images/trashDarkenedGreenIcon.svg";

const buttonStyle = (className) => cntl`
  h-10
  px-4
  py-2
  text-darkenedGreen
  text-md
  rounded
  transition-all
  duration-200
  hover:text-white
  ${className}
`;

const TrashButton = ({ disabled, onClick, className }) => {
  const buttonRef = useRef({});

  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick();
    }
  }, [disabled, onClick]);
  return (
    <button
      className={buttonStyle(className, disabled)}
      onClick={handleClick}
      type="button"
      tabIndex={0}
      id="cancel-button"
      aria-label="cancel-button"
      ref={buttonRef}
    >
      <img src={TrashIcon} alt="" />
    </button>
  );
};

TrashButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

TrashButton.defaultProps = {
  disabled: false,
  onClick: () => {},
  className: undefined,
};

export default TrashButton;

import { v4 as uuidv4 } from "uuid";
import { useMemo } from "react";
import { ADD_OPEN_MODAL, VIEW_TODO_MODAL } from "../../../../constants";
import { useModalState } from "../../../../state/modalState";

export default ({ create }) => {
  const [{ modals }, modalDispatch] = useModalState();

  const handleSubmit = (todo) => {
    create(todo);
  };

  const disableButton = useMemo(() => {
    return modals.length;
  }, [modals]);

  const openCreate = () => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: uuidv4() },
      modalData: { edit: true, onFinished: handleSubmit },
      modalType: VIEW_TODO_MODAL,
    });
  };

  return { openCreate, disableButton };
};

/* eslint-disable react/button-has-type */
import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./BaseButton.css";
import ContactBaseButton from "./ContactBaseButton";

const menuContainerCN = (menuCN) => cntl`
  border
  border-gray-200
  rounded
  bg-white
  absolute
  ${menuCN ? "" : "top-full"}
  z-50
  max-w-min
  ${menuCN}
`;

const dropdownItemCN = (className) => cntl`
  w-full
  py-3
  px-4
  text-sm
  text-left
  whitespace-nowrap
  ${className}
  hover:text-white
  hover:bg-brandGreen
`;

const ContactButton = ({
  onClick,
  disabled,
  iconLeft,
  dropdownItems,
  iconClassName,
  containerClassName,
  menuCN,
  ...props
}) => {
  const [showMenu, setShowMenu] = useState(false);

  // need to use ref to store timer so it can be cleared on unmount
  const timerRef = useRef(null);

  // closes menu
  const closeMenu = useCallback(() => {
    setShowMenu(false);
    document.removeEventListener("click", closeMenu);
  }, []);

  // toggles menu on click
  const handleShowMenu = () => {
    if (showMenu) {
      closeMenu();
    } else {
      setShowMenu(true);
      // add event listener to close menu on click outside
      timerRef.current = setTimeout(
        () => document.addEventListener("click", closeMenu),
        10
      );
    }
  };

  useEffect(() => {
    // cleanup timer
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
      document.removeEventListener("click", closeMenu);
    };
  }, [closeMenu]);

  if (dropdownItems) {
    return (
      <>
        <ContactBaseButton
          {...props}
          containerClassName={containerClassName}
          showMenu={showMenu}
          iconLeft={iconLeft}
          disabled={disabled}
          dropdownItems={dropdownItems}
          iconClassName={iconClassName}
          handleShowMenu={handleShowMenu}
          onClick={onClick}
        />
        {showMenu && !!dropdownItems?.length && (
          <div className={menuContainerCN(menuCN)}>
            {dropdownItems.map((item) => (
              <button
                key={item.title}
                type="button"
                className={dropdownItemCN(item.className)}
                onClick={item?.onClick}
              >
                {item?.anchorTag || item.title}
              </button>
            ))}
          </div>
        )}
      </>
    );
  }
  return (
    <ContactBaseButton
      containerClassName={containerClassName}
      showMenu={showMenu}
      iconLeft={iconLeft}
      disabled={disabled}
      dropdownItems={dropdownItems}
      iconClassName={iconClassName}
      handleShowMenu={handleShowMenu}
      onClick={onClick}
    />
  );
};

ContactButton.propTypes = {
  /**
   * the handler called when the button is clicked
   */
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  /**
   * JSX to display on the left side of the button
   */
  iconLeft: PropTypes.element,
  /**
   * array of options to show on click, if passed
   * if this prop exists, a dropdown arrow will be added to the button
   */
  dropdownItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      className: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  iconClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  menuCN: PropTypes.string,
};

ContactButton.defaultProps = {
  iconClassName: undefined,
  disabled: false,
  iconLeft: undefined,
  dropdownItems: undefined,
  containerClassName: undefined,
  menuCN: "",
  onClick: undefined,
};

export default ContactButton;

import React from "react";
import attachmentIcon from "../../../../stories/assets/images/attachment_icon_updated.svg";
import crossIconWhite from "../../../../stories/assets/images/crossIconWhite.svg";

const MessageAttachments = ({ handleRemoveDrop, files, loading }) => {
  return (
    <div className="relative flex w-full overflow-x-scroll gap-4 items-center p-1">
      {files?.map((item) => {
        return (
          <div
            key={item?.url}
            className="relative shadow-light-lift rounded-lg"
          >
            <button
              type="button"
              className="absolute top-0 "
              style={{
                width: "16px",
                height: "16px",
                right: "-4px",
              }}
              onClick={() => handleRemoveDrop(item.file)}
            >
              <img
                alt="delete tag"
                className="rounded-xl p-1"
                style={{ backgroundColor: "#027D61" }}
                src={crossIconWhite}
              />
            </button>
            {item?.type?.includes("video") ? (
              <video
                className="rounded-lg w-10 h-10"
                autoPlay={false}
                preload="metadata"
              >
                <track kind="captions" />
                <source
                  src={item.url}
                  type={
                    item.type === "video/quicktime" ? "video/mp4" : item.type
                  }
                />
              </video>
            ) : (
              <img
                className="rounded-lg w-10 h-10"
                alt={item.file}
                src={item?.type?.includes("image") ? item.url : attachmentIcon}
              />
            )}
          </div>
        );
      })}
      {loading && <div className="loading-circle-small" />}
    </div>
  );
};

export default MessageAttachments;

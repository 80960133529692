import moment from "moment";
import { useMemo } from "react";
import { TIMESHEET_HISTORY_PATH } from "../../../../constants";
import formatDateControl from "../../../../helpers/Timesheets/formatDateControl";
import usePagePersistance from "../../../../hooks/usePagePersistence";
import { useAppState } from "../../../../state/appState";
import wasUserHiredAfterDate from "../../../../helpers/Timesheets/wasUserHiredAfterDate";

const useTimesheetControlData = ({
  timesheet,
  handleApproveTimesheet,
  handleRejectTimesheet,
}) => {
  const { pageState, setPersistentPageItem } = usePagePersistance();
  const [{ userDict }] = useAppState();

  const viewStartDate = pageState?.timesheet?.periodStart;
  const viewEndDate = moment(viewStartDate)
    .add(6, "days")
    .format()
    .split("T")[0];

  const formattedDateText = formatDateControl(viewStartDate, viewEndDate);

  const prevBtnDisabled = useMemo(() => {
    if (
      !userDict ||
      !pageState?.timesheet?.userId ||
      !pageState?.timesheet?.periodEnd
    )
      return false;

    const userRef = `User/${pageState?.timesheet?.userId}`;

    const user = userDict[userRef];
    const periodEnd = pageState?.timesheet?.periodEnd;

    if (!user) return false;

    const disablePrevButton = wasUserHiredAfterDate(user, periodEnd);

    return disablePrevButton;
  }, [pageState?.timesheet, userDict]);

  const handlePrevWeekClick = () => {
    const newPeriodStart = moment(viewStartDate).subtract(1, "week").format();
    const newPeriodEnd = moment(viewEndDate).subtract(1, "week").format();

    setPersistentPageItem("timesheet", {
      ...pageState.timesheet,
      periodStart: newPeriodStart.split("T")[0],
      periodEnd: newPeriodEnd.split("T")[0],
    });
  };

  const handleNextWeekClick = () => {
    const newStartDate = moment(viewStartDate).add(1, "week");
    const newEndDate = moment(viewEndDate).add(1, "week");
    const currentDate = moment();

    if (newStartDate.isAfter(currentDate, "day")) {
      return;
    }

    setPersistentPageItem("timesheet", {
      ...pageState.timesheet,
      periodStart: newStartDate.format().split("T")[0],
      periodEnd: newEndDate.format().split("T")[0],
    });
  };

  const handleCurrentWeekClick = () => {
    const currentDate = moment();
    const weeksDifference = currentDate.diff(viewStartDate, "weeks");
    const newViewStartDate = moment(viewStartDate).add(
      weeksDifference,
      "weeks"
    );

    if (weeksDifference > 0) {
      setPersistentPageItem("timesheet", {
        ...pageState.timesheet,
        periodStart: newViewStartDate.format().split("T")[0],
        periodEnd: moment(newViewStartDate)
          .add(6, "days")
          .format()
          .split("T")[0],
      });
    }
  };

  const forwardBtnDisabled = moment(viewStartDate)
    .add(1, "week")
    .isAfter(moment(), "day");

  const timesheetHistoryPath = useMemo(() => {
    if (timesheet?.resource?.id === undefined) return "";
    return `${TIMESHEET_HISTORY_PATH}/${timesheet?.resource?.id}`;
  }, [timesheet?.resource?.id]);

  const dropDownActionsForSubmitted = useMemo(() => {
    return [
      {
        title: "Approve",
        onClick: handleApproveTimesheet,
      },
      {
        title: "Reject",
        onClick: handleRejectTimesheet,
      },
    ];
  }, [handleApproveTimesheet, handleRejectTimesheet]);

  return {
    timesheetHistoryPath,
    formattedDateText,
    forwardBtnDisabled,
    dropDownActionsForSubmitted,
    prevBtnDisabled,
    handlePrevWeekClick,
    handleNextWeekClick,
    handleCurrentWeekClick,
  };
};

export default useTimesheetControlData;

const updateCalendarDay = (calendarDay, event, isFullDayEvent) => {
  // Determine the type of event (all day or brief)
  const eventType = isFullDayEvent ? "allDay" : "brief";
  // Create a copy of the calendarDay to ensure immutability
  const calendarDayCopy = { ...calendarDay };
  // Check if the calendar day already has events
  if (calendarDay.brief !== undefined) {
    // If it's a full-day event, increment the count
    if (isFullDayEvent) calendarDayCopy.allDayCount += 1;
    // Add the event to the existing array of the determined type (allDay or brief)
    calendarDay[eventType].push(event);
  } else {
    // If this is the first event for the day, initialize the day's properties
    calendarDayCopy.allDay = isFullDayEvent ? [event] : [];
    calendarDayCopy.allDayCount = isFullDayEvent ? 1 : 0;
    calendarDayCopy.brief = !isFullDayEvent ? [event] : [];
  }
  // Return the updated copy of the calendar day
  return calendarDayCopy;
};

export default updateCalendarDay;

import React from "react";
import EditIcon from "../../../EditIcon/EditIcon";

const TaskEditButton = ({ handleEditClick, canShow }) => {
  // If the user is not allowed to edit the task or downs have permission,
  // don't show the button

  if (!canShow) return null;

  // Otherwise, show the button
  return (
    <button onClick={handleEditClick} type="button" aria-label="edit button">
      <EditIcon />
    </button>
  );
};

export default TaskEditButton;

const getErrorForgotPasswordApi = (error) => {
  const { issue, detail } = error?.response?.data?.issues[0] || {};
  const errorCode = issue?.code;
  const errorDetail = detail?.code;

  if (errorCode === "not_found" && errorDetail === "MSG_NO_EXIST") {
    return "This account has been deactivated. Please contact support for assistance.";
  }

  return "Something went wrong with our password recovery system. Please try again later or contact support for help.";
};

export default getErrorForgotPasswordApi;

import { useMemo } from "react";
import { useAppState } from "../../../../state/appState";
import useTimesheetList from "../../../../hooks/timesheets/useTimesheetList";
import combineTsWithUser from "../../../../helpers/Timesheets/combineTsWithUser";

const useTimesheetApprovalData = () => {
  const [{ userDict }] = useAppState();
  const { timesheetsList, timesheetsLoading } = useTimesheetList();

  /* NERD: Normally I wouldnt want to format at this scope
   *   but it was like this before, and the child components
   *   were built to handle it the way */
  const timeSheets = useMemo(
    () => combineTsWithUser(timesheetsList, userDict),
    [timesheetsList, userDict]
  );

  return { timesheetsLoading, timeSheets };
};

export default useTimesheetApprovalData;

/* NERD: Need to look at the payroll approval page
 *    and convert over to using the rq hooks
 */

import { useCallback, useEffect } from "react";
import { RoleAPI } from "@griffingroupglobal/eslib-api";
import { useAppState } from "../state/appState";
import { SET_ROLES, SET_ROLES_DICT } from "../constants";

export default () => {
  const [{ roles, rolesDict }, dispatch] = useAppState();

  const reload = useCallback(async () => {
    try {
      const { data } = await RoleAPI.get();

      const { allRoles, allRolesDict } = data.entries.reduce(
        (acc, { resource }) => {
          const { reference } = resource;
          return {
            allRoles: [...acc.allRoles, resource],
            allRolesDict: { ...acc.allRolesDict, [reference]: resource },
          };
        },
        { allRoles: [], allRolesDict: {} }
      );

      dispatch({
        type: SET_ROLES,
        roles: allRoles,
      });

      dispatch({
        type: SET_ROLES_DICT,
        rolesDict: allRolesDict,
      });
    } catch (error) {
      console.warn(error.message);
    }
  }, [dispatch]);

  useEffect(() => {
    reload();
  }, [reload]);

  return [roles, rolesDict, reload];
};

import React from "react";
import PropTypes from "prop-types";

const SiteHeaderAlphabetIndex = ({ showIndex, sortedElements }) => {
  return (
    <>
      {showIndex && (
        <div className="flex flex-row w-1/2 gap-2">
          {Object.keys(sortedElements)?.map((element) => (
            <div className="flex" key={element}>
              <a
                href={`#content-${element}`}
                className="text-md font-semiBold text-darkenedGreen"
              >
                {element}
              </a>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

SiteHeaderAlphabetIndex.propTypes = {
  showIndex: PropTypes.bool,
  sortedElements: PropTypes.arrayOf(PropTypes.shape({})),
};

SiteHeaderAlphabetIndex.defaultProps = {
  showIndex: undefined,
  sortedElements: undefined,
};

export default SiteHeaderAlphabetIndex;

/**
 * Payload for the `POST /api/Role` api call.
 */
export default class CreateRolePayload {
  name;

  custom = true;

  permissions = {};

  constructor({ name, permissions }) {
    this.name = name;
    this.permissions = permissions;
  }
}

import React from "react";
import moment from "moment";
import cntl from "cntl";
import sortDates from "../../../../../helpers/Timesheets/sortDates";
import useTimesheetContentData from "./useTimesheetContentData";

const headerRowCN = cntl`
  w-full
  text-xs
  flex
  flex-col
  col-span-1
  font-light
  text-gray-400
  leading-tight
`;
const firstRowCN = cntl`
  font-medium
`;

const TimesheetContent = ({ children }) => {
  const { entryDates } = useTimesheetContentData();

  return (
    <div className="border-gray-200 border rounded-md mt-4">
      <div className="grid grid-cols-11 rounded-t-md bg-backgroundGrey pt-6 pb-2 mb-2 px-4">
        <div className="col-start-5 col-span-6 grid grid-cols-7 ml-6 gap-3">
          {sortDates(entryDates)?.map((date) => (
            <div className={headerRowCN} key={date}>
              <h2 className={firstRowCN}>{moment(date).format("ddd")}</h2>
              <h3>{moment(date).format("D")}</h3>
            </div>
          ))}
        </div>
      </div>
      {children}
    </div>
  );
};

export default TimesheetContent;

import React from "react";
import PropTypes from "prop-types";

import backArrowBlack from "../../assets/images/backArrowBlack.svg";

const ToggleMenuView = ({ showCloseButton, onCloseClick }) => {
  return (
    <div className="flex justify-end w-full">
      {showCloseButton && (
        <button
          onClick={onCloseClick}
          type="button"
          style={{ transform: "rotate(180deg)", transformOrigin: "center" }}
        >
          <img className="w-6 h-6" src={backArrowBlack} alt="close Icon" />
        </button>
      )}
    </div>
  );
};

ToggleMenuView.propTypes = {
  showCloseButton: PropTypes.bool,
  onCloseClick: PropTypes.func,
};

ToggleMenuView.defaultProps = {
  showCloseButton: false,
  onCloseClick: undefined,
};

export default ToggleMenuView;

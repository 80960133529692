import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import usePlacesAutoComplete from "./usePlacesAutoComplete";
import CrossButton from "../CrossButton/CrossButton";

const EsAddressAutoComplete = ({
  value: inputVal,
  onChange: handleSelect = () => {},
  forwardedRef,
  id,
  isDisabled,
}) => {
  const {
    onChange,
    options,
    optionRefs,
    value,
    loading,
    clear,
    handleKeyPress,
    onSelect,
  } = usePlacesAutoComplete(inputVal, handleSelect);

  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = (e) => {
    if (
      e.target.id.includes("address1") ||
      e.target.id.includes("address-option")
    ) {
      setMenuOpen(true);
    } else {
      setMenuOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", openMenu);
    return () => {
      document.removeEventListener("click", openMenu);
    };
  }, []);

  return (
    <div
      id="address-autocomplete-wrapper"
      className="relative ESInputContainer w-full menu-open"
    >
      <div
        id="input-actions"
        className="absolute right-0 h-full flex items-center"
      >
        {loading && <div className="loading-circle-small" />}
        <div className={!value?.length ? "hidden" : "mx-2 bg-transparent"}>
          <CrossButton onClick={clear} />
        </div>
      </div>

      <input
        id={id ?? "address1"}
        className="bg-backgroundGreen text-gray-450 text-sm pl-3 w-full h-full"
        // borderRadius to fix the padding issue against the grey border
        style={{ borderRadius: 2.5 }}
        placeholder="Street Address"
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyPress}
        onInput={(e) => handleSelect({ street: e.target.value })}
        ref={forwardedRef}
        disabled={isDisabled}
      />

      {!!options.length && (
        <div
          id="menu-wrapper"
          className={`absolute top-100 flex-col w-full z-10 pt-2 ${
            !menuOpen ? "hidden" : ""
          }`}
        >
          <div
            id="menu-list"
            className="bg-white shadow-1 w-full border border-gray-200"
          >
            {options.map((option, index) => {
              return (
                <button
                  className="w-full text-left truncate hover:bg-gray-100 focus:bg-gray-100 px-3 py-1 border-b border-gray-200 text-sm address-selection"
                  onClick={() => {
                    onSelect(option, index);
                  }}
                  onKeyDown={handleKeyPress}
                  key={option?.place_id ?? option?.description}
                  type="button"
                  id={`address-option-${index}`}
                  ref={(el) => {
                    optionRefs[`address-option-${index}`] = el;
                    return optionRefs[`address-option-${index}`];
                  }}
                >
                  {option.description}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default EsAddressAutoComplete;

EsAddressAutoComplete.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  forwardedRef: PropTypes.func,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
};

EsAddressAutoComplete.defaultProps = {
  value: "",
  onChange: () => {},
  forwardedRef: undefined,
  id: undefined,
  isDisabled: false,
};

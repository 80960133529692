import cntl from "cntl";
import React from "react";

const circleCN = (activeIndex, pageIndex) => cntl`
  rounded-full
  flex
  flex-row
  items-center
  justify-center
  transition-all
  duration-200
  border-2
  border-es-green
  ${
    activeIndex >= pageIndex
      ? "bg-es-green text-white"
      : "bg-white text-es-green"
  }
  `;
const titleCN = "text-es-dark-grey font-es-semibold";
const subTitleCN =
  "text-es-dark-grey text-es-normal tracking-es-wide font-es-semibold";
const titleStyles = {
  letterSpacing: "1.88px",
  fontSize: "38px",
  lineHeight: "46.32px",
};
const circleStyle = { width: "32px", height: "32px" };
const lineCN = "border-t-2 border-t-es-green w-4 mx-0.5";
const HeaderText = ({ activeIndex }) => {
  switch (activeIndex) {
    case 0:
      return (
        <div className="flex flex-col gap-3">
          <p className={titleCN} style={titleStyles}>
            Enter Your Profile Details
          </p>
          <p className={subTitleCN}>
            Review and confirm your profile information.
          </p>
        </div>
      );
    case 1:
      return (
        <div className="flex flex-col gap-3">
          <p className={titleCN} style={titleStyles}>
            Agreement
          </p>
          <p className={subTitleCN}>
            Read and agree to EstateSpace Terms & Conditions, EULA, and Privacy
            Policy.
          </p>
        </div>
      );
    default:
      return <></>;
  }
};

const ProfileDetailsHeader = ({ activeIndex }) => {
  return (
    <div id="profileContainer" className="flex flex-col gap-8 pb-8">
      <HeaderText activeIndex={activeIndex} />
      {/* status */}
      <div className="flex flex-row items-center h-fit">
        {/* circle */}
        <div style={circleStyle} className={circleCN(activeIndex, 0)}>
          <p>1</p>
        </div>
        {/* line */}
        <div className={lineCN} />
        {/* circle */}
        <div style={circleStyle} className={circleCN(activeIndex, 1)}>
          <p>2</p>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetailsHeader;

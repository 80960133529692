import React from "react";
import CommentIcon from "../../assets/images/commentGreenIcon.svg";
import IconButton from "../Buttons/IconButton";

const CommentButton = ({
  onClick,
  style,
  commentClassName,
  taskView = false,
}) => {
  return !taskView ? (
    <button
      style={style}
      type="button"
      onClick={onClick}
      className={`flex flex-row items-center rounded-lg px-2 bg-backgroundGreen mr-4 py-2 ${commentClassName}`}
    >
      <IconButton
        imgClassName="w-4 h-5"
        iconClassName="w-4 h-5"
        icon={CommentIcon}
      />
      <p className="text-xs font-semibold text-darkenedGreen pl-1">Comment</p>
    </button>
  ) : (
    <div
      className={`flex justify-center rounded-lg bg-backgroundGreen ${commentClassName}`}
      style={style || { height: "44px", width: "142px" }}
    >
      <button
        className="flex flex-row items-center justify-center w-full h-full gap-2"
        type="button"
        onClick={onClick}
      >
        <img
          style={{ height: "24px", width: "24px" }}
          alt="comment"
          src={CommentIcon}
        />
        <p className="text-es-normal font-es-semibold text-es-green tracking-es-wide">
          Comment
        </p>
      </button>
    </div>
  );
};

export default CommentButton;

import { useHistory, useLocation } from "react-router-dom";
/**
 * Hook that allows add and remove query parameters from the URL
 */
const useURLSetQueryParameters = () => {
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const handleAddQueryParam = (query, value) => {
    searchParams.set(query, value);
    history.replace({ search: searchParams.toString() });
  };

  const handleRemoveQueryParam = (query) => {
    searchParams.delete(query);

    // Replace the current entry in the history with the updated URL.
    history.replace({ search: searchParams.toString() });
  };

  return {
    handleAddQueryParam,
    handleRemoveQueryParam,
  };
};

export default useURLSetQueryParameters;

import cntl from "cntl";
import React from "react";
import { TASK } from "../../../../../constants";
import { hasUpdatePermission } from "../../../../../helpers/Permissions";
import formatTasks, {
  statusPillCN,
} from "../../../../../helpers/ServiceRequest/formatTasks";
import PrimaryButton from "../../../Buttons/PrimaryButton";
import TertiaryButton from "../../../Buttons/TertiaryButton";
import CommentButton from "../../../CommentButton";
import Pill from "../../../Pill/Pill";
import BackButton from "../../../WidgetEditorView/Header/BackButton";
import TaskDeleteButton from "../TaskDeleteButton";
import TaskEditButton from "../TaskEditButton";
import useHeaderControlItemsData from "./useHeaderControlItemsData";

const statusCN = (status) => cntl`
border 
flex 
items-center 
rounded-full
font-medium
text-sm
py-2 
px-3
cursor-default
w-fit
${status}
`;

const HeaderControlItems = ({
  currentTask,
  editingMode,
  cancelEditing,
  handleSaveClick,
  handleDeleteClick,
  handleEditClick,
  handleCompleteClick,
  handleOpenComments,
  isSaving,
  saveClassName,
  commentClassName,
  onBackToWfPressed,
  title,
  nameForm,
}) => {
  const { currentUser, canTaskBeCompleted } = useHeaderControlItemsData({
    currentTask,
  });

  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-col gap-2 w-fit">
        {title && ( // this will just say 'Task' on task view
          <p className="text-es-lg text-es-black font-es-semibold">{title}</p>
        )}
        {!onBackToWfPressed && (
          <div className="flex">
            <Pill
              value={formatTasks(currentTask)?.formattedStatus}
              background="" // TODO: (Parker) Pill component sets bg to bg-grand if no prop is found
              className={statusCN(
                statusPillCN(formatTasks(currentTask)?.formattedStatus)
              )}
            />
          </div>
        )}
      </div>

      <div className="flex justify-between self-start">
        {onBackToWfPressed && (
          <div className="flex flex-col">
            <BackButton
              buttonStyle={{ width: "100%" }}
              title={`Task: ${currentTask?.name}`}
              onBackPressed={() => onBackToWfPressed(currentTask)}
            />
            <div style={{ maxWidth: "100px", maxHeight: "33px" }}>
              <Pill
                value={formatTasks(currentTask)?.formattedStatus}
                background="" // TODO: (Parker) Pill component sets bg to bg-grand if no prop is found
                className={statusCN(
                  statusPillCN(formatTasks(currentTask)?.formattedStatus)
                )}
              />
            </div>
          </div>
        )}
        <div
          className="flex justify-center items-center"
          style={{ gap: "14px" }} // to match figma
        >
          {!editingMode && canTaskBeCompleted && (
            <div
              className="flex flex-row items-center justify-center bg-es-green rounded-lg"
              style={{ height: "44px", width: "175px" }}
            >
              <button
                type="submit"
                title="Complete Task"
                onClick={handleCompleteClick}
                className="flex"
              >
                <p className="text-es-white font-es-semibold text-es-normal tracking-es-wide rounded-lg h-full">
                  Complete Task
                </p>
              </button>
            </div>
          )}
          {handleOpenComments && (
            <CommentButton
              onClick={handleOpenComments}
              commentClassName={commentClassName}
              taskView
            />
          )}
          {editingMode && (
            <>
              <TertiaryButton
                className="px-4 py-2"
                onClick={cancelEditing}
                title="Cancel"
                disabled={isSaving}
              />
              <PrimaryButton
                saveButton
                className={`px-4 py-2 rounded-lg ${saveClassName}`}
                onClick={handleSaveClick}
                title="save"
                disabled={isSaving || !nameForm}
              />
            </>
          )}

          <div className="flex">
            <TaskEditButton
              handleEditClick={handleEditClick}
              canShow={
                currentTask.status !== "done" &&
                currentTask.status !== "incomplete" &&
                hasUpdatePermission(TASK, currentUser) &&
                !editingMode
              }
            />

            {currentUser?.hasPermission?.("task", "can_delete") && (
              <TaskDeleteButton
                handleDeleteClick={handleDeleteClick}
                canShow={
                  currentTask.status !== "done" &&
                  currentTask.status !== "incomplete"
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderControlItems;

import React from "react";
import PropTypes from "prop-types";
import { CALENDAR_STROKE } from "../../../../constants";

const WeekBody = ({ hours }) => {
  return (
    <svg viewBox={`-0.5 0.125 296 ${(hours.length - 1) * 15 + 1}`} width="100%">
      <g id="body-cells">
        {Array.from({ length: hours.length - 1 }, (_, i) => (
          <g id={`week-${hours[i]}-row`} key={`week-${hours[i]}-row`}>
            {Array.from({ length: 7 }, (__, d) => (
              <g
                id={`week-${hours[i]}-column-${d}`}
                key={`week-${hours[i]}-column-${d}`}
              >
                <rect
                  // x = column * cell width
                  x={d * 40 + 15}
                  // y = row * cell height
                  y={i * 15}
                  // width = cell width
                  width={40}
                  // height = cell height
                  height={15}
                  fill="white"
                  stroke={CALENDAR_STROKE}
                  strokeWidth="0.2"
                />
                {d === 0 && (
                  <text
                    // x = column * cell width + (1/2 cell width)
                    x={d * 40 + 15 - 2}
                    // y = row * cell height + (20% of cell)
                    y={i * 15}
                    fontSize="3"
                    fill="#333333"
                    alignmentBaseline="middle"
                    textAnchor="end"
                  >
                    {hours[i]}
                  </text>
                )}
              </g>
            ))}
          </g>
        ))}
      </g>
    </svg>
  );
};

WeekBody.propTypes = {
  hours: PropTypes.arrayOf(PropTypes.string),
};

WeekBody.defaultProps = {
  hours: [],
};

export default WeekBody;

import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

const secondaryHeaderCN = (className, fontWeight, textSize) => cntl`
  ${textSize ?? "text-lg"}
  font-${fontWeight}
  text-gray-300
  ${className}
`;

const SecondaryHeader = ({ className, children, fontWeight, textSize }) => {
  return (
    <h2 className={secondaryHeaderCN(className, fontWeight, textSize)}>
      {children}
    </h2>
  );
};

SecondaryHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  fontWeight: PropTypes.string,
  textSize: PropTypes.string,
};

SecondaryHeader.defaultProps = {
  className: undefined,
  fontWeight: "semibold",
  textSize: "text-lg",
};

export default SecondaryHeader;

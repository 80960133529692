import { TodoAPI } from "@griffingroupglobal/eslib-api";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { todosCodeKeys } from "../config/reactQuery/queryKeyFactory";
import { toastError } from "../stories/Components/Toast/Toast";

const getTodos = async () => {
  const { data } = await TodoAPI.get();
  const todos = data?.entries?.map((item) => ({ ...item.resource }));
  return todos;
};

export default () => {
  return useAuthenticatedQuery({
    queryKey: todosCodeKeys,
    queryFn: getTodos,
    placeholderData: [],
    onError: (error) => {
      toastError("There was an error fetching todos.");
      console.error("useTodos", error);
    },
  });
};

import { useEffect, useState } from "react";

const useMouseTracker = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event) => {
    setMousePosition({
      x: event.clientX,
      y: event.clientY,
    });
  };

  useEffect(() => {
    const appElement = document.getElementById("app");

    if (appElement) {
      appElement.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      if (appElement) {
        appElement.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, []);

  return { mousePosition };
};

export default useMouseTracker;

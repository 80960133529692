const moment = require("moment");
const { MOMENT_UTC_ES_FORMAT } = require("../../constants");

const getEndDateForInstance = (event, newStartDate) => {
  let endDate = moment.utc(newStartDate);

  if (event.endDate) {
    // calculate duration
    const duration = moment
      .utc(event.endDate)
      .diff(moment.utc(event.startDate), "s");
    endDate = moment.utc(newStartDate).add(duration, "s");
  }

  return endDate.format(MOMENT_UTC_ES_FORMAT);
};

export default getEndDateForInstance;

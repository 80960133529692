/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { isSafari } from "react-device-detect";
import cntl from "cntl";
import moment from "moment";
import PropTypes from "prop-types";
import circleCheckGreenIcon from "../../assets/images/circleCheckGreenIcon.png";
import SecondaryButton from "../Buttons/SecondaryButton";
import { getFullName } from "../../../helpers/Formatters";
import {
  GET_PROJECT_WORKFLOW_VIEW_PATH,
  GET_PROPERTY_WORKFLOW_VIEW_PATH,
  GET_WORKFLOW_VIEW_PATH,
  WORKFLOWS_EDIT_PATH,
  WORKFLOW_TAB_VIEWS,
} from "../../../constants";
import WorkflowOptions from "./WorkflowOptions";
import DeleteModal from "../DeleteModal/DeleteModal";
import FlagButton from "../Buttons/FlagButton";
import AssociationPopUp from "./AssociationPopUp";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";

const cardWrapperCN = cntl`
  border-2
  rounded
  relative
  flex 
  w-75
  flex flex-col	
  mx-1.5
  mb-3
  overflow-hidden
  cursor-pointer
`;
const footerContCN = cntl`
  h-14 
  w-full 
  px-4
  flex
  items-center	
`;
const headerCN = cntl`
  bg-lightGreen
  w-full
  ${isSafari ? "py-4 pl-4" : "p-4"}
  h-36
  flex
  flex-col
  justify-between
`;

const WorkflowOtherCards = ({
  data,
  view,
  setSelectedCard,
  handleEditWorkflow,
  handleReinitiateWF,
  handleOpenAssociation,
  handleDeleteWF,
  handleCreateAssociatedWF,
  handleFlagWF,
  disableEditing,
  disableDelete,
  associatedResource,
  handleWatchWF,
  watchList,
}) => {
  const history = useHistory();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [watchedWF, setWatchedWF] = useState();
  const [isWatched, setIsWatched] = useState(false);
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const optionRef = useRef(null);
  useOutsideAlerter(optionRef, () => setIsOptionOpen(false));

  useEffect(() => {
    const foundItem = watchList?.find((watchedItem) => {
      return watchedItem.item.reference.includes(data.id);
    });
    setWatchedWF(foundItem);
    if (foundItem) {
      setIsWatched(true);
    } else {
      setIsWatched(false);
    }
  }, [watchList, data.id]);

  const handleOpenWF = (id) => {
    if (associatedResource) {
      const associatedResourceType = associatedResource.split("/")[0];

      if (associatedResourceType === "Property") {
        history.push(
          GET_PROPERTY_WORKFLOW_VIEW_PATH(associatedResource.split("/")[1], id)
        );
      } else if (associatedResourceType === "Project") {
        history.push(
          GET_PROJECT_WORKFLOW_VIEW_PATH(associatedResource.split("/")[1], id)
        );
      }
    } else {
      history.push(GET_WORKFLOW_VIEW_PATH(id));
    }
  };

  const handleEditClick = () => {
    history.push(WORKFLOWS_EDIT_PATH(data.id));
  };
  const handleInitate = () => {
    handleReinitiateWF(data, true);
  };
  const handleCreateAssociation = () => {
    const assocatedState = {
      workflow: data,
      status: "contingent",
    };
    handleCreateAssociatedWF(assocatedState);
  };

  const getDraftOptions = () => {
    const draftOptions = [];
    if (!disableEditing) {
      draftOptions.push({ title: "Edit", onClick: handleEditWorkflow });
      draftOptions.push({
        title: "Create Associated Workflow",
        onClick: () => {
          setIsOptionOpen(false);
          handleOpenAssociation();
        },
      });
    }

    if (!disableDelete) {
      draftOptions.push({
        title: "Delete",
        onClick: () => {
          setIsOptionOpen(false);
          setIsDeleteModalOpen(true);
        },
      });
    }

    if (!disableEditing && data?.steps?.length) {
      draftOptions.unshift({
        title: "Initiate",
        onClick: () => {
          setIsOptionOpen(false);
          handleReinitiateWF();
        },
      });
    }

    draftOptions.push({
      title: `${isWatched ? "Unwatch" : "Watch"}`,
      onClick: () => {
        setIsOptionOpen(false);
        handleWatchWF(data.id, isWatched, watchedWF);
      },
    });

    return draftOptions;
  };

  const resolvedOptions = () => {
    const options = [];
    if (!disableEditing) {
      options.push({
        title: "Create Associated Workflow",
        onClick: () => {
          setIsOptionOpen(false);
          handleCreateAssociation();
        },
      });
    }

    if (!disableDelete) {
      options.push({
        title: "Delete",
        onClick: () => {
          setIsOptionOpen(false);
          handleDeleteWF();
        },
      });
    }

    options.push({
      title: `${isWatched ? "Unwatch" : "Watch"}`,
      onClick: () => {
        setIsOptionOpen(false);
        handleWatchWF(data.id, isWatched, watchedWF);
      },
    });

    return options;
  };
  const dateTitle = view === WORKFLOW_TAB_VIEWS.resolved ? "Resolved" : "Draft";

  const handleOpenOption = () => {
    setSelectedCard(data);
  };

  const handleModalDelete = () => {
    handleDeleteWF();
    setIsDeleteModalOpen(false);
  };

  const handleFlagOption = () => {
    handleFlagWF(data);
  };

  const handleCardClick = () => {
    if (associatedResource) {
      const associatedResourceType = associatedResource.split("/")[0];

      if (associatedResourceType === "Property") {
        history.push(
          GET_PROPERTY_WORKFLOW_VIEW_PATH(
            associatedResource.split("/")[1],
            data.id
          )
        );
      } else if (associatedResourceType === "Project") {
        history.push(
          GET_PROJECT_WORKFLOW_VIEW_PATH(
            associatedResource.split("/")[1],
            data.id
          )
        );
      }
    } else {
      history.push(GET_WORKFLOW_VIEW_PATH(data.id));
    }
  };
  return (
    <>
      <div
        className={cardWrapperCN}
        onClick={handleCardClick}
        onKeyDown={handleCardClick}
        role="button"
        tabIndex={0}
      >
        <div className={headerCN}>
          <div className="flex flex-col">
            <div className="flex justify-between h-full">
              <div className="flex items-center">
                {view === WORKFLOW_TAB_VIEWS.resolved && (
                  <img
                    className="mr-3 w-4.5"
                    src={circleCheckGreenIcon}
                    alt="reolved"
                  />
                )}
                <h3 className="text-sm text-gray-700">{dateTitle}</h3>
              </div>
              <div
                className="flex"
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
                tabIndex={0}
                role="button"
              >
                {!disableEditing && view !== WORKFLOW_TAB_VIEWS.resolved && (
                  <FlagButton
                    className="flex relative z-10"
                    isSelected={data.isFlagged}
                    onClick={handleFlagOption}
                  />
                )}
                {(!disableEditing || !disableDelete) && (
                  <WorkflowOptions
                    options={
                      view === WORKFLOW_TAB_VIEWS.resolved
                        ? resolvedOptions()
                        : getDraftOptions()
                    }
                    onClick={handleOpenOption}
                    isOptionOpen={isOptionOpen}
                    optionRef={optionRef}
                  />
                )}
              </div>
            </div>
            <a
              className="mt-1 text-lg font-bold cursor-pointer"
              href={GET_WORKFLOW_VIEW_PATH(data.id)}
            >
              {data.name}
            </a>
          </div>
          <div className="flex justify-between items-end relative">
            <h3 className="text-sm flex">{`Version ${data.version}`}</h3>
            <AssociationPopUp data={data} handleOpenWF={handleOpenWF} />
          </div>
        </div>
        <div className="h-44 w-full p-4">
          {!data.isDraft && (
            <h2 className="mb-2">
              {view === WORKFLOW_TAB_VIEWS.resolved
                ? moment(data?.completed?.date).format("MMM D, YYYY, h:mm A")
                : "Not yet initiated"}
            </h2>
          )}
          <>
            <div className="flex flex-col mb-6">
              <h3 className="text-xs capitalize font-semibold text-gray-400">
                {view === WORKFLOW_TAB_VIEWS.resolved
                  ? `Completed by ${getFullName(
                      data?.completed?.userData?.name
                    )}`
                  : `Last modified by ${
                      data?.metadata?.userData?.name?.firstName || ""
                    } ${data?.metadata?.userData?.name?.lastName || "\u00A0"}`}
              </h3>
              <h3 className="mt-2 text-xs font-medium text-gray-200">
                {view === WORKFLOW_TAB_VIEWS.resolved
                  ? data?.completed?.note
                  : moment(data?.metadata?.lastUpdated).format(
                      "MMM D, YYYY, h:mm A"
                    )}
              </h3>
            </div>
          </>
        </div>
        <div className={footerContCN}>
          {view === WORKFLOW_TAB_VIEWS.draft && (
            <div className="flex w-full">
              {!disableEditing && (
                <>
                  <SecondaryButton
                    title="edit"
                    className="mr-4 z-10"
                    onClick={handleEditClick}
                  />
                  <SecondaryButton
                    title="Initiate"
                    className="w-full z-10"
                    noMaxWidth
                    onClick={handleInitate}
                    disabled={!data?.steps?.length}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onDelete={handleModalDelete}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete Workflow?"
        text={`Are you sure that you want to delete ${data.name}? Once deleted, the workflow cannot be recovered.`}
      />
    </>
  );
};

WorkflowOtherCards.propTypes = {
  /**
   * Current tab view
   */
  view: PropTypes.string,
  /**
   * data displayed on cards
   */
  data: PropTypes.shape({
    metadata: PropTypes.shape({
      version: PropTypes.string,
      dataUse: PropTypes.string,
      createdBy: PropTypes.string,
      location: PropTypes.string,
      tenant: PropTypes.string,
      lastUpdated: PropTypes.string,
      createdAt: PropTypes.string,
      userData: PropTypes.shape({
        name: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      }),
    }),
    relationships: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      children: PropTypes.arrayOf(PropTypes.any),
    }),
    resource: PropTypes.string,
    id: PropTypes.string,
    reference: PropTypes.string,
    completed: PropTypes.shape({
      member: PropTypes.string,
      note: PropTypes.string,
      date: PropTypes.string,
      userData: PropTypes.shape({
        name: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      }),
    }),
    version: PropTypes.number,
    isDraft: PropTypes.bool,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      })
    ),
    status: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    documents: PropTypes.arrayOf(PropTypes.any),
    dateCreated: PropTypes.string,
    startDate: PropTypes.string,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        duration: PropTypes.number,
        needOnlyOneAdvancer: PropTypes.bool,
        position: PropTypes.number,
        documents: PropTypes.arrayOf(
          PropTypes.shape({
            metadata: PropTypes.shape({
              version: PropTypes.string,
              dataUse: PropTypes.string,
              createdBy: PropTypes.string,
              location: PropTypes.string,
              tenant: PropTypes.string,
              lastUpdated: PropTypes.string,
              createdAt: PropTypes.string,
            }),
            resource: PropTypes.string,
            sensitive: PropTypes.bool,
            // eslint-disable-next-line react/forbid-prop-types
            tags: PropTypes.arrayOf(PropTypes.any),
            id: PropTypes.string,
            name: PropTypes.string,
            contentType: PropTypes.string,
            size: PropTypes.number,
            category: PropTypes.string,
            reference: PropTypes.string,
            contentsUrl: PropTypes.string,
            thumbnailUrl: PropTypes.string,
          })
        ),
        id: PropTypes.string,
        members: PropTypes.arrayOf(
          PropTypes.shape({
            _id: PropTypes.string,
            reference: PropTypes.string,
            status: PropTypes.string,
            date: PropTypes.string,
            note: PropTypes.string,
            userData: PropTypes.shape({
              name: PropTypes.shape({
                firstName: PropTypes.string,
                lastName: PropTypes.string,
              }),
            }),
          })
        ),
        parallelSteps: PropTypes.arrayOf(
          PropTypes.shape({
            duration: PropTypes.number,
            needOnlyOneAdvancer: PropTypes.bool,
            position: PropTypes.number,
            documents: PropTypes.arrayOf(PropTypes.shape({})),
            id: PropTypes.string,
            members: PropTypes.arrayOf(
              PropTypes.shape({
                _id: PropTypes.string,
                reference: PropTypes.string,
                status: PropTypes.string,
                date: PropTypes.string,
                note: PropTypes.string,
                userData: PropTypes.shape({
                  name: PropTypes.shape({
                    firstName: PropTypes.string,
                    lastName: PropTypes.string,
                  }),
                }),
              })
            ),
            name: PropTypes.string,
            description: PropTypes.string,
            // eslint-disable-next-line react/forbid-prop-types
            comments: PropTypes.arrayOf(PropTypes.any),
          })
        ),
        name: PropTypes.string,
        description: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types
        comments: PropTypes.arrayOf(PropTypes.any),
      })
    ),
    // eslint-disable-next-line react/forbid-prop-types
    associatedWorkflows: PropTypes.arrayOf(PropTypes.any),
    name: PropTypes.string,
    cost: PropTypes.string,
    isFlagged: PropTypes.bool,
  }),
  /**
   * sets the selected card
   */
  setSelectedCard: PropTypes.func,
  handleReinitiateWF: PropTypes.func,
  handleEditWorkflow: PropTypes.func,
  handleDeleteWF: PropTypes.func,
  handleOpenAssociation: PropTypes.func,
  handleCreateAssociatedWF: PropTypes.func,
  handleFlagWF: PropTypes.func,
  disableEditing: PropTypes.bool,
  disableDelete: PropTypes.bool,
  associatedResource: PropTypes.string,
  handleWatchWF: PropTypes.func,
  watchList: PropTypes.arrayOf(PropTypes.string),
};

WorkflowOtherCards.defaultProps = {
  view: undefined,
  data: [],
  setSelectedCard: undefined,
  handleReinitiateWF: undefined,
  handleEditWorkflow: undefined,
  handleDeleteWF: undefined,
  handleOpenAssociation: undefined,
  handleFlagWF: undefined,
  handleCreateAssociatedWF: undefined,
  disableEditing: false,
  disableDelete: false,
  associatedResource: undefined,
  handleWatchWF: undefined,
  watchList: [],
};
export default WorkflowOtherCards;

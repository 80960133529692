import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "../Modal/Modal";
import LocationOrSpaceSelectCard from "./LocationOrSpaceSelectCard";

const LocationSelectModal = ({
  title,
  handleModalOpen,
  isOpen,
  handleSave,
  spaces,
  space,
}) => {
  const [selectedSpace, setSelectedSpace] = useState();
  const closeModal = () => {
    setSelectedSpace();
    handleModalOpen();
  };
  return (
    <Modal
      title={title}
      isOpen={isOpen}
      primaryButtonTitle="Select"
      primaryButtonOnClick={() => {
        handleSave(selectedSpace);
        handleModalOpen();
      }}
      contentStyle={{ maxHeight: "100%", overflowY: "scroll" }}
      tertiaryButtonTitle="Cancel"
      onRequestModalClose={closeModal}
      disabled={!selectedSpace}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      hideFooter
      alert
    >
      <div className="flex items-center flex-wrap">
        {spaces?.map((item) => {
          const active = selectedSpace
            ? item?.id === selectedSpace
            : item?.id === space?.id;
          return (
            <LocationOrSpaceSelectCard
              key={item?.id}
              item={item}
              setSelectedLocationOrSpace={setSelectedSpace}
              active={active}
            />
          );
        })}
      </div>
    </Modal>
  );
};

LocationSelectModal.propTypes = {
  title: PropTypes.string,
  handleModalOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  handleSave: PropTypes.func,
  location: PropTypes.shape({
    images: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
    area: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  spaceConfiguration: PropTypes.shape({}),
  spaces: PropTypes.arrayOf(PropTypes.shape({})),
  space: PropTypes.shape({ id: PropTypes.string }),
};

LocationSelectModal.defaultProps = {
  title: "",
  handleModalOpen: undefined,
  isOpen: false,
  handleSave: undefined,
  location: undefined,
  spaceConfiguration: undefined,
  spaces: undefined,
  space: undefined,
};

export default LocationSelectModal;

import React from "react";
import PropTypes from "prop-types";
import menuIcon from "../../assets/images/menuIconBlack.svg";

/**
 * Renders the header for the calendar Sidebar when the sidebar is not open.
 */

function CalendarSidebarToggle({ toggleOpenSidebar }) {
  return (
    <div className="ml-5">
      <div className="flex justify-center">
        <button onClick={() => toggleOpenSidebar()} type="button">
          <img className="w-6 h-6" src={menuIcon} alt="Menu Icon" />
        </button>
      </div>
    </div>
  );
}

CalendarSidebarToggle.propTypes = {
  toggleOpenSidebar: PropTypes.func.isRequired,
};

export default CalendarSidebarToggle;

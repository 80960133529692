import { useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { USER_MANAGEMENT } from "../../../../constants";

const useRemoveUserModal = ({ modalData }) => {
  const selectedUsers = modalData?.item?.selectedUsers;
  const onSave = modalData?.item?.onSave;
  const [listToChange, setListToChange] = useState([...selectedUsers]);
  const data = { ...modalData, id: modalData?.id ?? uuidv4() };

  // handle adding/removing users from selectedUsers[]
  // val = true/false : checked/notChecked ;
  // user = user to remove/re-add to selectedUser array
  const handleCheckbox = (val, user) => {
    if (val) {
      setListToChange((prev) => [...prev, { ...user }]);
    }
    if (!val) {
      setListToChange((prev) => [
        ...prev?.filter((item) => item.id !== user.id),
      ]);
    }
  };

  const isChecked = useCallback(
    (user) => {
      return listToChange.some((item) => user.id === item.id);
    },
    [listToChange]
  );

  // Changes due to number of selected users to delete

  let modalTitle = "";
  let headerText = "Are you sure you want to delete";

  if (modalData?.item?.removeFrom === USER_MANAGEMENT) {
    // removing item from userManagement
    headerText = `${headerText} this member?`;
    modalTitle = "Delete Member";
  } else {
    // removing item from Contacts
    headerText = `${headerText} ${
      selectedUsers?.length === 1 ? "this Contact?" : " these Contacts?"
    }`;
    modalTitle =
      selectedUsers?.length === 1 ? "Delete Contact" : "Delete Contacts";
  }

  return {
    selectedUsers,
    data,
    onSave,
    listToChange,
    isChecked,
    handleCheckbox,
    headerText,
    modalTitle,
  };
};

export default useRemoveUserModal;

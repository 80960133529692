import { REMEMBER_ME_STATUS, USER_EMAIL } from "../../constants";

/**
 * Function to check status of rememberMe and clear localStorage during signOut
 * Clears select local storage if rememberMe true, otherwise clear all.
 */
const clearLocalStorage = () => {
  const currentUserEmail = localStorage.getItem(USER_EMAIL);
  const localStatus = localStorage.getItem(REMEMBER_ME_STATUS);
  localStorage.clear();

  if (localStatus === "true") {
    localStorage.setItem(USER_EMAIL, currentUserEmail);
    localStorage.setItem(REMEMBER_ME_STATUS, true);
  }
};

export default clearLocalStorage;

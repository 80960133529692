/* eslint-disable no-param-reassign */
import cntl from "cntl";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  APPROVED_TS,
  COA_CODE_TYPES,
  CUMULATIVE_VIEW,
  REJECT_TIMESHEET_POPUP,
  REJECTED_TS,
  TOGGLE_POSITIONED_POPUP,
} from "../../../../constants";
import { getCSICodeDescription } from "../../../../helpers/Budget";
import createNonSubmittedTimesheet from "../../../../helpers/Users/createNonSubmittedTimesheet";
import findEmployeesWithoutTimesheets from "../../../../helpers/Users/findEmployeesWithoutTimesheets";
import removeDuplicateRef from "../../../../helpers/Utilities/removeDuplicateRef";
import { useProjectsOverview } from "../../../../hooks/projects";
import useApprovalReducer from "../../../../hooks/useApprovalReducer";
import useEmployeeTimesheets from "../../../../hooks/useEmployeeTimeSheets";
import useFinancialsConfiguration from "../../../../hooks/useFinancialsConfiguration";
import useProjectCodes from "../../../../hooks/useProjectCodes";
import { useUsers } from "../../../../hooks/useUsers.new";
import { useAppState } from "../../../../state/appState";
import whiteCircleCheckIcon from "../../../assets/images/circleCheckIcon.svg";
import whiteCrossIcon from "../../../assets/images/whiteCrossIcon.svg";
import PrimaryButton from "../../Buttons/PrimaryButton";
import ApprovalTable from "../../TimeSheetApprovalTable/ApprovalTable";
import useTimesheetApprovalData from "./useTimesheetApprovalData";
import { toastError, toastMessage } from "../../../../helpers/Toast";
import useEditTimesheetsList from "../../../../hooks/timesheets/useEditTimesheetsList";
import whiteExlamationIcon from "../../../assets/images/whiteExclamationIcon.svg";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const PageCN = (financials) => cntl`
  lg:flex
  flex
  flex-initial
  flex-col
  mt-3
  mb-12
  ${!financials && "loading"}
`;
const TimeSheetApprovalView = ({ currentUser }) => {
  const [
    {
      timesheetstate: { approvalDate: date },
    },
    appStateDispatch,
  ] = useAppState();

  const { data: financialsConfiguration } = useFinancialsConfiguration();
  const { data, isLoading: isLoadingEmployees } = useUsers();
  const { timesheetHistory, pto, periodFrame, financials, reload } =
    useEmployeeTimesheets(undefined, date);

  const { editTimesheetsListStatus } = useEditTimesheetsList();
  const [editedTimesheets, dispatch] = useApprovalReducer();
  const { projectDict } = useProjectsOverview();

  const {
    base: { data: { projectCodes: rawProjectCodes } = {} },
  } = useProjectCodes();

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedEntries, setSelectedEntries] = useState({});
  const warnings = useRef([]);
  const alerts = useRef([]);
  const { timeSheets, timesheetsLoading } = useTimesheetApprovalData();

  /**
   * Default Settings & Configuration
   */

  const [revenueAccountingCodes, setRevenueAccountingCodes] = useState([]);
  const [expenseAccountingCodes, setExpenseAccountingCodes] = useState([]);
  const [csiDict, setCsiDict] = useState({});
  const [activeView, setActiveView] = useState(CUMULATIVE_VIEW);

  useEffect(() => {
    if (financialsConfiguration?.financials) {
      setRevenueAccountingCodes(
        financialsConfiguration?.financials?.chartOfAccounts?.filter(
          (code) => code.codeType === COA_CODE_TYPES[1].value
        )
      );
      setExpenseAccountingCodes(
        financialsConfiguration?.financials?.chartOfAccounts?.filter(
          (code) => code.codeType === COA_CODE_TYPES[0].value
        )
      );
    }
  }, [financialsConfiguration]);

  useEffect(() => {
    const csiCodesList = {};

    financialsConfiguration?.financials?.csiCodeMapping?.forEach((div) => {
      div.csiCodes.forEach((csi) => {
        csi.subCodes.forEach((sub) => {
          const csiCodeFormatted = {
            label: `${getCSICodeDescription(
              { division: div.division, code: csi.code, subcode: sub.code },
              financialsConfiguration?.financials?.csiCodeMapping,
              revenueAccountingCodes,
              expenseAccountingCodes
            )}`,
            value: `${div.division}-${csi.code}-${sub.code}`,
          };
          csiCodesList[`${div.division}-${csi.code}-${sub.code}`] =
            csiCodeFormatted;
        });
      });
    });

    setCsiDict(csiCodesList);
  }, [
    expenseAccountingCodes,
    financialsConfiguration?.financials?.csiCodeMapping,
    revenueAccountingCodes,
  ]);

  const defaultRatesDict = useMemo(
    () =>
      financialsConfiguration?.financials?.rateSheet?.rates?.reduce(
        (list, item) => {
          list[item?.id] = item?.category;
          return list;
        }
      ),
    [financialsConfiguration?.financials?.rateSheet?.rates]
  );

  /**
   * Default Settings & Configuration
   */

  const projectCodes = useMemo(() => {
    return rawProjectCodes
      ? Object.values(rawProjectCodes)?.reduce((list, item) => {
          item?.codes?.forEach((code) => {
            list[code.key] = code;
          });
          return list;
        }, {})
      : null;
  }, [rawProjectCodes]);

  const reset = useCallback(() => {
    dispatch({
      type: "reset",
      timeSheets,
    });
  }, [dispatch, timeSheets]);

  useEffect(() => {
    reset();
  }, [reset]);

  const submitTimeSheets = useCallback(
    async (action) => {
      let method = "";
      if (action === "approved") method = "$approve";
      if (action === "rejected") method = "$decline";

      const editTsStatusProps = {
        items: selectedRows,
        action: method,
      };

      try {
        await editTimesheetsListStatus(editTsStatusProps);

        const toastString =
          action === "approved"
            ? "Successfully approved timesheets."
            : "Successfully declined timesheets.";

        toastMessage(toastString, toastIcon, toastCloseIcon);
      } catch (err) {
        toastError("Error approving timesheet", toastErrorIcon, toastCloseIcon);
      }
    },
    [editTimesheetsListStatus, selectedRows]
  );

  const handleApproveRow = (item) => {
    (async () => {
      try {
        const method = "$approve";
        await editTimesheetsListStatus({
          items: [item],
          action: method,
        });
        toastMessage(
          "Successfully approved timesheet.",
          toastIcon,
          toastCloseIcon
        );
      } catch (err) {
        toastError("Error approving timesheet", toastErrorIcon, toastCloseIcon);
      }
    })();
  };

  const handleInitRejectRow = (item) => {
    appStateDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      position: {
        centered: true,
      },
      popupData: {
        timesheet: item,
        popupWidth: 425,
        updateTimesheet: async () => {
          try {
            toastMessage(
              "Successfully rejected timesheet.",
              toastIcon,
              toastCloseIcon
            );
            await editTimesheetsListStatus({
              items: [item],
              action: "$decline",
            });
          } catch (err) {
            toastError(
              "Error rejecting timesheet",
              toastErrorIcon,
              toastCloseIcon
            );
          }
        },
      },
      popupType: REJECT_TIMESHEET_POPUP,
    });
  };

  const handleApprovalEntries = useCallback(
    (value) => {
      dispatch({
        type: "editEntries",
        entries: selectedEntries,
        value,
      });
      submitTimeSheets(value);
      setSelectedEntries({});
    },
    [dispatch, selectedEntries, submitTimeSheets]
  );

  const statusRollup = useMemo(() => {
    const rollup = editedTimesheets?.reduce((list, item) => {
      Object.values(item?.entries)?.forEach((entry) => {
        list.push(entry?.status);
        list.push(item?.status);
      });
      return list;
    }, []);
    if (rollup.includes("rejected")) {
      return "rejected";
    }
    /**
     * Approved With Changes
     * - No Entries are open,rejected,or submitted
     */
    if (
      rollup.includes("approved-with-changes") &&
      !rollup.includes("open") &&
      !rollup.includes("rejected") &&
      !rollup.includes("submitted")
    ) {
      return "approved-with-changes";
    }
    if (rollup.includes("locked")) {
      return "payrollApproved";
    }
    // - all are approved then rollup is approved
    if (rollup.every((s) => s === "approved")) {
      return "approved";
    }

    return "open";
  }, [editedTimesheets]);

  const customSiteHeader = useCallback(
    (props) => {
      return (
        <div className="flex flex-col w-full gap-5 pt-1 pb-3">
          <div className="flex justify-between items-center">
            {props?.datePicker}
            <PrimaryButton
              title="Actions"
              large
              dropdownItems={[
                {
                  title: "Approve",
                  onClick: () => submitTimeSheets(APPROVED_TS),
                },
                {
                  title: "Reject",
                  onClick: () => submitTimeSheets(REJECTED_TS),
                },
              ]}
              disabled={selectedRows.length <= 0}
              className="whitespace-nowrap"
            />
          </div>
          <div className="flex gap-4">
            {props?.viewOptions}
            {props?.search}
          </div>
        </div>
      );
    },
    [selectedRows.length, submitTimeSheets]
  );

  const memoizedEditedTimesheets = useMemo(() => {
    if (isLoadingEmployees || timesheetsLoading) {
      return [];
    }

    const emplyeesWithoutTimesheets = findEmployeesWithoutTimesheets(
      data?.employees,
      timeSheets
    );

    const convertedToTimesheets = emplyeesWithoutTimesheets.map((employee) =>
      createNonSubmittedTimesheet(employee)
    );

    const tsWithoutDuplicates = removeDuplicateRef(timeSheets);

    const newArr = [...tsWithoutDuplicates, ...convertedToTimesheets];

    const arrToReturn = newArr?.map((sheet, index) => {
      return {
        ...sheet,
        index,
        id: index,
      };
    });

    return arrToReturn;
  }, [isLoadingEmployees, timesheetsLoading, data?.employees, timeSheets]);

  return (
    <div className={PageCN(financials)}>
      {/* Note: wait until financials are fetched to limit rerenders */}
      {financials && (
        <ApprovalTable
          timesheets={memoizedEditedTimesheets}
          ptoSheets={pto}
          historyData={timesheetHistory}
          loading={timesheetsLoading}
          dateQuery={date}
          periodFrame={periodFrame}
          financials={financials}
          projects={projectDict}
          projectCodes={projectCodes}
          defaultCsiDict={csiDict}
          defaultRates={defaultRatesDict}
          currentUser={currentUser}
          warnings={warnings}
          alerts={alerts}
          userSheetStatus={statusRollup}
          reload={reload}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          handleApproveRow={handleApproveRow}
          handleRejectRow={handleInitRejectRow}
          handleApprovalEntries={handleApprovalEntries}
          submitCumulativeRow={submitTimeSheets}
          customSiteHeader={customSiteHeader}
          activeView={activeView}
          setActiveView={setActiveView}
        />
      )}
    </div>
  );
};

export default TimeSheetApprovalView;

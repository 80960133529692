/**
 * This function is primarily a cosmetic UI fix, extracts the `Administrative` category by
 * integrating its permissions into the appropriate resource categories (property/project)
 * (https://estatespace.atlassian.net/jira/software/projects/ES/boards/26?selectedIssue=ES-8848)
 * @param {Object} allPermissions - The object containing all permissions coming from API
 * @returns {Object} The permissions object with administrative permissions merged into their corresponding resources
 */
const extractAdministrativePermissions = (allPermissions) => {
  if (!allPermissions) return {};

  const { administrative, ...rest } = { ...allPermissions };

  Object.entries(administrative).forEach(([key, value]) => {
    if (key.includes("project")) {
      rest.project = { ...rest.project, [key]: value };
    } else if (key.includes("property")) {
      rest.property = { ...rest.property, [key]: value };
    }
  });

  return rest;
};

export default extractAdministrativePermissions;

import { FileAPI } from "@griffingroupglobal/eslib-api";
import PropTypes from "prop-types";
import React, { useState } from "react";
import IconDropdownButton from "../Buttons/IconDropdownButton/IconDropdownButton";
import useAddAttachAndRecentButtonData from "./useAddAttachAndRecentButtonData";
import recentFileIcon from "../../assets/images/recentFile.svg";
import uploadFromComputerIcon from "../../assets/images/uploadFromComputer.svg";
import PlusCirlceIconDarkGreen from "../../assets/images/plusCircleIconDarkGreen.svg";
import whiteExlamationIcon from "../../assets/images/whiteExclamationIcon.svg";
import RecentFiles from "../FilesTable/RecentFiles";
import { fetchRecentFiles } from "../../../helpers/File";
import { useAppState } from "../../../state/appState";
import { toastError } from "../Toast/Toast";
import { DOCUMENT_ATTACHMENT_EXTENSIONS } from "../../../constants";

const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const AddAttachmentsAndRecentButton = ({
  onFilesAdded,
  isEditing,
  documentTypeFilter,
  className,
}) => {
  const [{ associatedFilesDict, removeFilesAndUpdateApi }] = useAppState();
  const { fileInputRef, handleFileInputClick, handleFilesSelected } =
    useAddAttachAndRecentButtonData({ onFilesAdded });

  const [showRecentFilesModal, setShowRecentFileModal] = useState();

  const uploadOptions = [
    ...[
      {
        title: "Recent files",
        onClick: () => setShowRecentFileModal((prev) => !prev),
        image: recentFileIcon,
      },
    ],
    {
      title: "Upload from computer",
      onClick: handleFileInputClick,
      image: uploadFromComputerIcon,
    },
  ];

  const cloneFile = React.useCallback(
    async (fileId) => {
      const { data } = await FileAPI.postByIdWOP(fileId, "$clone");
      const updatedDict = { ...associatedFilesDict };
      updatedDict[data.reference] = data;

      return data;
    },
    [associatedFilesDict]
  );

  const handleFileClone = React.useCallback(
    (fileId) => {
      cloneFile(fileId)
        .then((clonedFile) => {
          // filter out media files
          if (clonedFile?.category === "Documents") {
            onFilesAdded([
              { ...clonedFile, ref: clonedFile?.reference, isRecent: true },
            ]);
          }
        })
        .catch(() => {
          toastError(
            `Error attaching recent file, or file cannot be media file`,
            toastErrorIcon
          );
          // remove created files if PATCH fails
          removeFilesAndUpdateApi([`File/${fileId}`]);
        });
    },
    [cloneFile, onFilesAdded, removeFilesAndUpdateApi]
  );

  return (
    <div className="task-new-view upload_area_click flex">
      <IconDropdownButton
        dropdownItems={uploadOptions}
        className={className}
        imgClassName="w-4 h-5"
        iconClassName="w-4 h-5"
        icon={PlusCirlceIconDarkGreen}
        title="Add Attachments"
        isEditing={isEditing}
      />
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept={DOCUMENT_ATTACHMENT_EXTENSIONS}
        multiple
        onChange={handleFilesSelected}
      />

      {showRecentFilesModal && (
        <div style={{ bottom: "640px" }} className="flex flex-col relative">
          <RecentFiles
            wrappCN="left-30"
            hide={() => setShowRecentFileModal(false)}
            handleFileClick={handleFileClone}
            onScrollCallback={fetchRecentFiles}
            documentTypeFilter={documentTypeFilter}
          />
        </div>
      )}
    </div>
  );
};

AddAttachmentsAndRecentButton.defaultProps = {
  onFilesAdded: () => {},
  isEditing: PropTypes.bool,
  documentTypeFilter: "Documents",
  className: "mr-7 mb-7",
};

AddAttachmentsAndRecentButton.propTypes = {
  onFilesAdded: PropTypes.func,
  isEditing: false,
  documentTypeFilter: PropTypes.string,
  className: PropTypes.string,
};

export default AddAttachmentsAndRecentButton;

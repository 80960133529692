import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Table from "../Table/Table";
import ColumnFooter from "./ColumnFooter";
import { formatNumber } from "../../../helpers/Formatters";
import CurrencyView from "../CurrencyView/CurrrencyView";
import CircularProgressBar from "../CircularProgressBar/CircularProgressBar";
import { FILTER_TYPES, TABLE_COLUMN_WIDTH } from "../../../constants";
import BooleanBullet from "../BooleanBullet/BooleanBullet";
import BudgetTableInLineForm from "./BudgetTableInLineForm";
import flagGreen from "../../assets/images/flagGreenIcon.svg";
import flagWhite from "../../assets/images/flagWhiteIcon.svg";
import commentIcon from "../../assets/images/comment_icon.svg";
import IconButton from "../Buttons/IconButton";
import BudgetTableNotesCell from "./BudgetTableNotesCell";
import TableDescriptionCell from "../Table/TableDescriptionCell";
import BudgetTableDocumentsCell from "./BudgetTableDocumentsCell";
import Checkbox from "../Checkbox/Checkbox";
import RadioButton from "../Buttons/RadioButton";
import { getAggregatedTotal } from "../../../helpers/Budget";
import BudgetTableLockedCell from "./BudgetTableLockedCell";
import BudgetTableExpensesCell from "./BudgetTableExpensesCell";

const groupOptions = {
  // ordered list of grouped column options
  hierarchicalOptions: [
    // add more hierarchical groups
  ],
  // un-ordered list of grouped column options
  nonHierarchicalOptions: [
    {
      label: "Financial Code",
      value: "csiCodeDivision",
    },
    {
      label: "Group",
      value: "groupName",
    },
    // {
    //   label: "Location",
    //   value: "buildingName",
    // },
    {
      label: "Vendor",
      value: "vendorName",
    },
    {
      label: "Schedule %",
      value: "schedulePercentage",
    },
    // {
    //   label: "Space",
    //   value: "spaceName",
    // },
  ],
};

const PureBudgetTable = ({
  data,
  name,
  csiCodes,
  unitsOfMeasure,
  groupedUnitsOfMeasure,
  vendors,
  onEditRowCancelClick,
  onDeleteRowClick,
  templateSettings,
  updateUserTemplateSettings,
  deleteUserTemplateSettings,
  groupByCallback,
  onEditSaveCallback,
  onAddSaveCallback,
  onSplitLineSaveCallback,
  rowAssociationMenuList,
  versionCloseCallback,
  setSelectedRows,
  initialSelectedRows,
  buttonActions,
  summaryComponent,
  // projectBuildings,
  onFavoriteClick,
  budgetGroups,
  isFixedFirmBudgetType,
  isCostPlusBudgetType,
  isTimeAndMaterialsBudgetType,
  hasEditPermission,
  onCancelSaveCallback,
  setSelectedLineItemForComments,
  onSaveProjectLocation,
  projectLocationSpaceConfiguration,
  onSaveProjectLocationSpace,
  onSaveContactCallback,
  systemConfiguration,
  users,
  rateSheetRates,
  isDraftProject,
  documentModalView,
  setOpenDocument,
  setOpenExpense,
  onEditPopoverOpenCallback,
  onEditPopoverCloseCallback,
  isActivateProject,
  toggleLowRange,
  toggleHighRange,
  toggleAllHighRange,
  toggleAllLowRange,
  isAllLowRangeChecked,
  isAllHighRangeChecked,
  onPendingUpdatesQueueClick,
  pendingUpdatesTotal,
  isPendingUpdatesModal,
  handleRowCheck,
  checkedRow,
  onAddBudgetGroup,
  onEditBudgetGroup,
  onDeleteBudgetGroup,
  hideSiteHeader,
  isLockGmp,
  isLockFixedFirm,
  currentUserReference,
  disableSelectAll,
  tableMiniMapContainerId,
  currentUser,
  viaChangeOrder,
  showPendingUpdates,
  hideSiteHeaderTitle,
}) => {
  const tableColumns = React.useMemo(() => {
    return [
      ...(isPendingUpdatesModal
        ? [
            {
              Header: "",
              accessor: "radio",
              isPrimary: true,
              sticky: "left",
              Cell: ({ row }) => (
                <RadioButton
                  isChecked={row?.original?.reference === checkedRow}
                  onChange={(e) => {
                    e.stopPropagation();
                    handleRowCheck(row?.original?.reference);
                  }}
                />
              ),
            },
          ]
        : []),
      {
        Header: "",
        accessor: "lockedByReference",
        editColumnModalHeader: "Lock",
        Cell: ({ row }) => (
          <BudgetTableLockedCell
            row={row}
            currentUserReference={currentUserReference}
          />
        ),
        Aggregated: () => null,
        disableSortBy: true,
        width: TABLE_COLUMN_WIDTH,
        minWidth: 70,
        maxWidth: 70,
        isPrimary: true,
      },
      {
        Header: "",
        accessor: "flag",
        editColumnModalHeader: "Flag",
        isPrimary: true,
        Cell: ({ row }) => (
          <IconButton
            onClick={() => {
              onFavoriteClick(row.original.id);
            }}
            icon={row.original.flag ? flagGreen : flagWhite}
            disabled={isPendingUpdatesModal || isLockFixedFirm || isLockGmp}
            disbaledBackgroundColorCN="bg-white"
          />
        ),
        sortType: ({ values: { flag: a } }, { values: { flag: b } }) => {
          if (a === b) {
            return 0;
          }
          return a ? -1 : 1;
        },
        width: TABLE_COLUMN_WIDTH,
        minWidth: 70,
        maxWidth: 70,
      },
      {
        Header: "",
        accessor: "notes",
        editColumnModalHeader: "Notes",
        Cell: ({ row }) => <BudgetTableNotesCell row={row} />,
        Aggregated: () => null,
        disableSortBy: true,
        width: TABLE_COLUMN_WIDTH,
        minWidth: 70,
        maxWidth: 70,
        isPrimary: true,
      },
      {
        Header: "",
        accessor: "comments",
        editColumnModalHeader: "Comments",
        Cell: ({ row }) => (
          <div className="flex justify-center">
            <IconButton
              icon={commentIcon}
              onClick={() => {
                setSelectedLineItemForComments(row?.original?.reference);
              }}
            />
          </div>
        ),
        Aggregated: () => null,
        disableSortBy: true,
        width: TABLE_COLUMN_WIDTH,
        minWidth: 70,
        maxWidth: 70,
        isPrimary: true,
      },
      {
        Header: "CSI Code",
        accessor: "csiCode",
        isPrimary: true,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
        width: 400,
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ row }) => <TableDescriptionCell row={row} />,
        Aggregated: () => null,
      },
      // {
      //   Header: "Location",
      //   accessor: "buildingName",
      //   filterOptions: {
      //     filterType: FILTER_TYPES.isOrNot,
      //   },
      // },
      // {
      //   Header: "Space",
      //   accessor: "spaceName",
      //   hidden: true,
      // },
      {
        Header: "Group",
        accessor: "groupName",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Vendor",
        accessor: "vendorName",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Rate",
        accessor: "rate",
        width: 180,
        Cell: ({ row }) => {
          return (
            <div className="flex">
              <p>
                {
                  rateSheetRates?.find(
                    (rate) => rate.value === row?.original?.rate
                  )?.label
                }
              </p>
            </div>
          );
        },
      },
      {
        Header: "Unit of Measure",
        accessor: "unitOfMeasure",
        width: 180,
        Cell: ({ row }) => {
          return (
            <div className="flex">
              <p>
                {
                  unitsOfMeasure?.find(
                    (unit) => unit.value === row?.original?.unitOfMeasure
                  )?.label
                }
              </p>
            </div>
          );
        },
      },
      {
        Header: "Calc",
        accessor: "calculation",
        width: 180,
        Aggregated: () => null,
      },
      {
        Header: "QTY",
        accessor: "quantity",
        aggregate: "sum",
        Aggregated: () => null,
      },
      {
        Header: "Cost Per",
        accessor: "costPerUnit",
        Cell: ({ value }) => <CurrencyView value={value} />,
        Footer: (columnProps) => <ColumnFooter columnProps={columnProps} />,
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.costPerUnit);
          const bVal = formatNumber(b.values.costPerUnit);
          return aVal - bVal;
        },
        aggregate: "sum",
        Aggregated: ({ row }) => {
          const totalAdjusted = getAggregatedTotal(row, "costPerUnit");
          return <CurrencyView value={totalAdjusted} />;
        },
      },
      {
        Header: "Adjustment",
        accessor: "adjustment",
        sortType: (a, b) => {
          const aVal = formatNumber(a.values?.adjustment?.amount);
          const bVal = formatNumber(b.values?.adjustment?.amount);
          return aVal - bVal;
        },
        /* eslint-disable react/prop-types */
        Cell: ({
          row: {
            values: { adjustment },
          },
        }) => (
          <CurrencyView
            value={adjustment?.amount}
            isPercentage={adjustment?.adjustmentUnit === "%"}
          />
        ),
        /* eslint-enable react/prop-types */
      },
      {
        Header: () => {
          if (isActivateProject) {
            return (
              <Checkbox
                label="Low Range"
                checked={isAllLowRangeChecked}
                onChange={(isChecked) => toggleAllLowRange(isChecked)}
                stopEventPropagation
              />
            );
          }
          if (isDraftProject) {
            return "Low Range";
          }
          return "Live Budget";
        },
        accessor: "liveBudget",
        /* eslint-disable react/prop-types */
        Cell: ({
          row: {
            original: { liveBudget, isLowRangeSelected, id },
          },
        }) => {
          if (isActivateProject) {
            return (
              <div className="flex flex-row">
                <Checkbox
                  className="mr-2"
                  checked={isLowRangeSelected}
                  onChange={(isChecked) => toggleLowRange(id, isChecked)}
                  stopEventPropagation
                />
                <CurrencyView value={liveBudget} />
              </div>
            );
          }
          return <CurrencyView value={liveBudget} />;
        },
        Footer: (columnProps) => (
          <ColumnFooter
            columnProps={columnProps}
            className={isActivateProject && "ml-6"}
          />
        ),
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.liveBudget);
          const bVal = formatNumber(b.values.liveBudget);
          return aVal - bVal;
        },
        Aggregated: ({ row }) => {
          const totalAdjusted = getAggregatedTotal(row, "liveBudget");
          return <CurrencyView value={totalAdjusted} />;
        },
      },
      {
        Header: isDraftProject ? "High/Low Variance" : "Budget Variance",
        accessor: "budgetVariance",
        Cell: ({ value }) => {
          return <CurrencyView value={value} />;
        },
        sortType: (a, b) => {
          const aVal = formatNumber(a.values.budgetVariance);
          const bVal = formatNumber(b.values.budgetVariance);
          return aVal - bVal;
        },
        Aggregated: ({ row }) => {
          const totalAdjusted = getAggregatedTotal(row, "budgetVariance");
          return <CurrencyView value={totalAdjusted} />;
        },
        width: 200,
      },
      ...(isDraftProject
        ? [
            {
              Header: () => {
                if (isActivateProject) {
                  return (
                    <Checkbox
                      label="High Range"
                      checked={isAllHighRangeChecked}
                      onChange={(isChecked) => toggleAllHighRange(isChecked)}
                      stopEventPropagation
                    />
                  );
                }
                return "High Range";
              },
              accessor: "highRangeCalculated",
              Cell: ({
                row: {
                  original: { highRangeCalculated, isHighRangeSelected, id },
                },
              }) => {
                if (isActivateProject) {
                  return (
                    <div className="flex flex-row">
                      <Checkbox
                        className="mr-2"
                        checked={isHighRangeSelected}
                        onChange={(isChecked) => toggleHighRange(id, isChecked)}
                        stopEventPropagation
                      />
                      <CurrencyView value={highRangeCalculated} />
                    </div>
                  );
                }

                return <CurrencyView value={highRangeCalculated} />;
              },
              Footer: (columnProps) => (
                <ColumnFooter
                  columnProps={columnProps}
                  className={isActivateProject && "ml-6"}
                />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.highRangeCalculated);
                const bVal = formatNumber(b.values.highRangeCalculated);
                return aVal - bVal;
              },
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(
                  row,
                  "highRangeCalculated"
                );
                return <CurrencyView value={totalAdjusted} />;
              },
            },
          ]
        : [
            {
              Header: "Published Budget",
              accessor: "publishedBudget",
              Cell: ({ value }) => <CurrencyView value={value} />,
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.publishedBudget);
                const bVal = formatNumber(b.values.publishedBudget);
                return aVal - bVal;
              },
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(
                  row,
                  "publishedBudget"
                );
                return <CurrencyView value={totalAdjusted} />;
              },
              width: 180,
            },
            ...(isCostPlusBudgetType
              ? [
                  {
                    Header: "Published Budget (pending)",
                    accessor: "publishedBudgetPending",
                    Cell: ({ value }) => <CurrencyView value={value} />,
                    Footer: (columnProps) => (
                      <ColumnFooter columnProps={columnProps} />
                    ),
                    sortType: (a, b) => {
                      const aVal = formatNumber(
                        a.values.publishedBudgetPending
                      );
                      const bVal = formatNumber(
                        b.values.publishedBudgetPending
                      );
                      return aVal - bVal;
                    },
                    Aggregated: ({ row }) => {
                      const totalAdjusted = getAggregatedTotal(
                        row,
                        "publishedBudgetPending"
                      );
                      return <CurrencyView value={totalAdjusted} />;
                    },
                    width: 220,
                  },
                ]
              : []),
          ]),
      {
        Header: "Division Code",
        accessor: "csiCodeDivision",
        hidden: true,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
        width: 160,
      },
      ...(isDraftProject
        ? []
        : [
            {
              Header: "Billable",
              accessor: "isBillable",
              disableSortBy: true,
              Cell: ({ value }) => (
                <BooleanBullet isTrue={value} className="ml-5" />
              ),
            },
            {
              Header: "Allowance",
              accessor: "allowance",
              disableSortBy: true,
              Cell: ({ value }) => (
                <BooleanBullet isTrue={value} className="ml-5" />
              ),
            },
            ...(isCostPlusBudgetType
              ? [
                  {
                    Header: "PA",
                    accessor: "purchaseAuthorization",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                      <BudgetTableDocumentsCell
                        row={row}
                        setOpenDocument={setOpenDocument}
                        columnId="purchaseAuthorization"
                      />
                    ),
                    Aggregated: () => null,
                    width: TABLE_COLUMN_WIDTH,
                  },
                  {
                    Header: "PA Total",
                    accessor: "paTotal",
                    Cell: ({ value }) => <CurrencyView value={value} />,
                    Footer: (columnProps) => (
                      <ColumnFooter columnProps={columnProps} />
                    ),
                    sortType: (a, b) => {
                      const aVal = formatNumber(a.values.paTotal);
                      const bVal = formatNumber(b.values.paTotal);
                      return aVal - bVal;
                    },
                    Aggregated: ({ row }) => {
                      const totalAdjusted = getAggregatedTotal(row, "paTotal");
                      return <CurrencyView value={totalAdjusted} />;
                    },
                  },
                  {
                    Header: "PA (pending)",
                    accessor: "purchaseAuthorizationPending",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                      <BudgetTableDocumentsCell
                        row={row}
                        setOpenDocument={setOpenDocument}
                        columnId="purchaseAuthorizationPending"
                      />
                    ),
                    Aggregated: () => null,
                    width: TABLE_COLUMN_WIDTH,
                  },
                  {
                    Header: "PA Total (pending)",
                    accessor: "paTotalPending",
                    Cell: ({ value }) => <CurrencyView value={value} />,
                    Footer: (columnProps) => (
                      <ColumnFooter columnProps={columnProps} />
                    ),
                    sortType: (a, b) => {
                      const aVal = formatNumber(a.values.paTotalPending);
                      const bVal = formatNumber(b.values.paTotalPending);
                      return aVal - bVal;
                    },
                    Aggregated: ({ row }) => {
                      const totalAdjusted = getAggregatedTotal(
                        row,
                        "paTotalPending"
                      );
                      return <CurrencyView value={totalAdjusted} />;
                    },
                    width: 230,
                  },
                ]
              : []),
            ...(isFixedFirmBudgetType
              ? [
                  {
                    Header: "PO",
                    accessor: "purchaseOrder",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                      <BudgetTableDocumentsCell
                        row={row}
                        setOpenDocument={setOpenDocument}
                        columnId="purchaseOrder"
                      />
                    ),
                    Aggregated: () => null,
                    width: TABLE_COLUMN_WIDTH,
                  },
                  {
                    Header: "PO (pending)",
                    accessor: "purchaseOrderPending",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                      <BudgetTableDocumentsCell
                        row={row}
                        setOpenDocument={setOpenDocument}
                        columnId="purchaseOrderPending"
                      />
                    ),
                    Aggregated: () => null,
                    width: TABLE_COLUMN_WIDTH,
                  },
                  {
                    Header: "PO Total (pending)",
                    accessor: "poTotalPending",
                    Cell: ({ value }) => <CurrencyView value={value} />,
                    Footer: (columnProps) => (
                      <ColumnFooter columnProps={columnProps} />
                    ),
                    sortType: (a, b) => {
                      const aVal = formatNumber(a.values.poTotalPending);
                      const bVal = formatNumber(b.values.poTotalPending);
                      return aVal - bVal;
                    },
                    Aggregated: ({ row }) => {
                      const totalAdjusted = getAggregatedTotal(
                        row,
                        "poTotalPending"
                      );
                      return <CurrencyView value={totalAdjusted} />;
                    },
                    width: 230,
                  },
                ]
              : []),
            {
              Header: "Purchase Variance",
              accessor: "purchaseVariance",
              Cell: ({ value }) => <CurrencyView value={value} />,
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.purchaseVariance);
                const bVal = formatNumber(b.values.purchaseVariance);
                return aVal - bVal;
              },
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(
                  row,
                  "purchaseVariance"
                );
                return <CurrencyView value={totalAdjusted} />;
              },
              width: 180,
            },
            {
              Header: "Purchase Variance %",
              accessor: "purchaseVariancePercentage",
              Footer: () => null,
              Cell: ({ value }) => (
                <CircularProgressBar value={value} hideIcons />
              ),
              Aggregated: () => null,
              width: 200,
            },
            {
              Header: "Items Not Yet Purchased",
              accessor: "itemsNotYetPurchased",
              Cell: ({ value }) => <CurrencyView value={value} />,
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.itemsNotYetPurchased);
                const bVal = formatNumber(b.values.itemsNotYetPurchased);
                return aVal - bVal;
              },
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(
                  row,
                  "itemsNotYetPurchased"
                );
                return <CurrencyView value={totalAdjusted} />;
              },
              width: 220,
            },
            ...(isCostPlusBudgetType && isLockGmp
              ? [
                  {
                    Header: "CA",
                    accessor: "contingencyAuthorization",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                      <BudgetTableDocumentsCell
                        row={row}
                        setOpenDocument={setOpenDocument}
                        columnId="contingencyAuthorization"
                      />
                    ),
                    Aggregated: () => null,
                    width: TABLE_COLUMN_WIDTH,
                  },
                  {
                    Header: "CA Total",
                    accessor: "caTotal",
                    Cell: ({ value }) => <CurrencyView value={value} />,
                    Footer: (columnProps) => (
                      <ColumnFooter columnProps={columnProps} />
                    ),
                    sortType: (a, b) => {
                      const aVal = formatNumber(a.values.caTotal);
                      const bVal = formatNumber(b.values.caTotal);
                      return aVal - bVal;
                    },
                    Aggregated: ({ row }) => {
                      const totalAdjusted = getAggregatedTotal(row, "caTotal");
                      return <CurrencyView value={totalAdjusted} />;
                    },
                  },
                  {
                    Header: "CA (pending)",
                    accessor: "contingencyAuthorizationPending",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                      <BudgetTableDocumentsCell
                        row={row}
                        setOpenDocument={setOpenDocument}
                        columnId="contingencyAuthorizationPending"
                      />
                    ),
                    Aggregated: () => null,
                    width: TABLE_COLUMN_WIDTH,
                  },
                  {
                    Header: "CA Total (pending)",
                    accessor: "caTotalPending",
                    Cell: ({ value }) => <CurrencyView value={value} />,
                    Footer: (columnProps) => (
                      <ColumnFooter columnProps={columnProps} />
                    ),
                    sortType: (a, b) => {
                      const aVal = formatNumber(a.values.caTotalPending);
                      const bVal = formatNumber(b.values.caTotalPending);
                      return aVal - bVal;
                    },
                    Aggregated: ({ row }) => {
                      const totalAdjusted = getAggregatedTotal(
                        row,
                        "caTotalPending"
                      );
                      return <CurrencyView value={totalAdjusted} />;
                    },
                    width: 200,
                  },
                ]
              : []),
            ...(isLockGmp || isFixedFirmBudgetType
              ? [
                  {
                    Header: "CO",
                    accessor: "changeOrder",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                      <BudgetTableDocumentsCell
                        row={row}
                        setOpenDocument={setOpenDocument}
                        columnId="changeOrder"
                      />
                    ),
                    Aggregated: () => null,
                    width: TABLE_COLUMN_WIDTH,
                  },
                  {
                    Header: "CO Total",
                    accessor: "coTotal",
                    Cell: ({ value }) => <CurrencyView value={value} />,
                    Footer: (columnProps) => (
                      <ColumnFooter columnProps={columnProps} />
                    ),
                    sortType: (a, b) => {
                      const aVal = formatNumber(a.values.coTotal);
                      const bVal = formatNumber(b.values.coTotal);
                      return aVal - bVal;
                    },
                    Aggregated: ({ row }) => {
                      const totalAdjusted = getAggregatedTotal(row, "coTotal");
                      return <CurrencyView value={totalAdjusted} />;
                    },
                  },
                  {
                    Header: "CO (pending)",
                    accessor: "changeOrderPending",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                      <BudgetTableDocumentsCell
                        row={row}
                        setOpenDocument={setOpenDocument}
                        columnId="changeOrderPending"
                      />
                    ),
                    Aggregated: () => null,
                    width: TABLE_COLUMN_WIDTH,
                  },
                  {
                    Header: "CO Total (pending)",
                    accessor: "coTotalPending",
                    Cell: ({ value }) => <CurrencyView value={value} />,
                    Footer: (columnProps) => (
                      <ColumnFooter columnProps={columnProps} />
                    ),
                    sortType: (a, b) => {
                      const aVal = formatNumber(a.values.coTotalPending);
                      const bVal = formatNumber(b.values.coTotalPending);
                      return aVal - bVal;
                    },
                    Aggregated: ({ row }) => {
                      const totalAdjusted = getAggregatedTotal(
                        row,
                        "coTotalPending"
                      );
                      return <CurrencyView value={totalAdjusted} />;
                    },
                    width: 200,
                  },
                  {
                    Header: isFixedFirmBudgetType
                      ? "Updated Fixed Firm"
                      : "Updated GMP",
                    accessor: "updatedGmpOrFixedFirm",
                    Cell: ({ value }) => <CurrencyView value={value} />,
                    Footer: (columnProps) => (
                      <ColumnFooter columnProps={columnProps} />
                    ),
                    sortType: (a, b) => {
                      const aVal = formatNumber(a.values.updatedGmpOrFixedFirm);
                      const bVal = formatNumber(b.values.updatedGmpOrFixedFirm);
                      return aVal - bVal;
                    },
                    Aggregated: ({ row }) => {
                      const totalAdjusted = getAggregatedTotal(
                        row,
                        "updatedGmpOrFixedFirm"
                      );
                      return <CurrencyView value={totalAdjusted} />;
                    },
                    width: 250,
                  },
                ]
              : []),

            ...(isLockGmp || isFixedFirmBudgetType
              ? [
                  {
                    Header: isFixedFirmBudgetType
                      ? "Updated Fixed Firm (pending)"
                      : "Updated GMP (pending)",
                    accessor: "updatedGmpPending",
                    Cell: ({ value }) => <CurrencyView value={value} />,
                    Footer: (columnProps) => (
                      <ColumnFooter columnProps={columnProps} />
                    ),
                    sortType: (a, b) => {
                      const aVal = formatNumber(a.values.updatedGmpPending);
                      const bVal = formatNumber(b.values.updatedGmpPending);
                      return aVal - bVal;
                    },
                    Aggregated: ({ row }) => {
                      const totalAdjusted = getAggregatedTotal(
                        row,
                        "updatedGmpPending"
                      );
                      return <CurrencyView value={totalAdjusted} />;
                    },
                    width: 250,
                  },
                ]
              : []),
            {
              Header: "Committed To Date",
              accessor: "committedValue",
              Cell: ({ value }) => <CurrencyView value={value} />,
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.commitedValue);
                const bVal = formatNumber(b.values.commitedValue);
                return aVal - bVal;
              },
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(row, "commitedValue");
                return <CurrencyView value={totalAdjusted} />;
              },
              width: 200,
            },
            ...(isCostPlusBudgetType
              ? [
                  {
                    Header: "Expenses (billable)",
                    accessor: "expenseBillable",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                      <BudgetTableExpensesCell
                        row={row}
                        columnId="expenseBillable"
                        title="Expenses (non-billable)"
                        setOpenExpense={setOpenExpense}
                      />
                    ),
                    Aggregated: () => null,
                    width: 200,
                  },
                  {
                    Header: "Expense Total (billable)",
                    accessor: "expenseTotalBillable",
                    Cell: ({ value }) => <CurrencyView value={value} />,
                    Footer: (columnProps) => (
                      <ColumnFooter columnProps={columnProps} />
                    ),
                    sortType: (a, b) => {
                      const aVal = formatNumber(a.values.expenseTotalBillable);
                      const bVal = formatNumber(b.values.expenseTotalBillable);
                      return aVal - bVal;
                    },
                    Aggregated: ({ row }) => {
                      const totalAdjusted = getAggregatedTotal(
                        row,
                        "expenseTotalBillable"
                      );
                      return <CurrencyView value={totalAdjusted} />;
                    },
                    width: 250,
                  },
                  {
                    Header: "Expenses (non-billable)",
                    accessor: "expenseNonbillable",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                      <BudgetTableExpensesCell
                        row={row}
                        columnId="expenseNonbillable"
                        title="Expenses (non-billable)"
                        setOpenExpense={setOpenExpense}
                      />
                    ),
                    Aggregated: () => null,
                    width: 200,
                  },
                  {
                    Header: "Expense Total (non-billable)",
                    accessor: "expenseTotalNonbillable",
                    Cell: ({ value }) => <CurrencyView value={value} />,
                    Footer: (columnProps) => (
                      <ColumnFooter columnProps={columnProps} />
                    ),
                    sortType: (a, b) => {
                      const aVal = formatNumber(
                        a.values.expenseTotalNonbillable
                      );
                      const bVal = formatNumber(
                        b.values.expenseTotalNonbillable
                      );
                      return aVal - bVal;
                    },
                    Aggregated: ({ row }) => {
                      const totalAdjusted = getAggregatedTotal(
                        row,
                        "expenseTotalNonbillable"
                      );
                      return <CurrencyView value={totalAdjusted} />;
                    },
                    width: 250,
                  },
                ]
              : []),
            {
              Header: "% Complete of Committed Value",
              accessor: "percentCompleteOfCommittedValue",
              Footer: () => null,
              Cell: ({ value }) => (
                <CircularProgressBar value={value} hideIcons />
              ),
              Aggregated: () => null,
              width: 280,
            },
            {
              Header: "Uncommitted Value",
              accessor: "uncommittedValue",
              Cell: ({ value }) => <CurrencyView value={value} />,
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.uncommittedValue);
                const bVal = formatNumber(b.values.uncommittedValue);
                return aVal - bVal;
              },
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(
                  row,
                  "uncommittedValue"
                );
                return <CurrencyView value={totalAdjusted} />;
              },
              width: 200,
            },
            {
              Header: "Uncommitted Value (pending)",
              accessor: "uncommittedValuePending",
              Cell: ({ value }) => <CurrencyView value={value} />,
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.uncommittedValuePending);
                const bVal = formatNumber(b.values.uncommittedValuePending);
                return aVal - bVal;
              },
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(
                  row,
                  "uncommittedValuePending"
                );
                return <CurrencyView value={totalAdjusted} />;
              },
              width: 280,
            },
            {
              Header: isCostPlusBudgetType
                ? "Work Invoiced to Date"
                : "Work Completed to Date",
              accessor: "workCompletedToDate",
              Cell: ({ value }) => <CurrencyView value={value} />,
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.workCompletedToDate);
                const bVal = formatNumber(b.values.workCompletedToDate);
                return aVal - bVal;
              },
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(
                  row,
                  "workCompletedToDate"
                );
                return <CurrencyView value={totalAdjusted} />;
              },
              width: 220,
            },
            {
              Header: "Work Completed Previous Period",
              accessor: "workCompletedPreviousPeriod",
              Cell: ({ value }) => <CurrencyView value={value} />,
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.workCompletedPreviousPeriod);
                const bVal = formatNumber(b.values.workCompletedPreviousPeriod);
                return aVal - bVal;
              },
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(
                  row,
                  "workCompletedPreviousPeriod"
                );
                return <CurrencyView value={totalAdjusted} />;
              },
              width: 280,
            },
            {
              Header: "Paid to Date",
              accessor: "paidToDate",
              Cell: ({ value }) => <CurrencyView value={value} />,
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.paidToDate);
                const bVal = formatNumber(b.values.paidToDate);
                return aVal - bVal;
              },
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(row, "paidToDate");
                return <CurrencyView value={totalAdjusted} />;
              },
            },
            {
              Header: "Work Completed This Period",
              accessor: "workCompletedThisPeriod",
              Cell: ({ value }) => <CurrencyView value={value} />,
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.workCompletedThisPeriod);
                const bVal = formatNumber(b.values.workCompletedThisPeriod);
                return aVal - bVal;
              },
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(
                  row,
                  "workCompletedThisPeriod"
                );
                return <CurrencyView value={totalAdjusted} />;
              },
              width: 280,
            },
            ...(isCostPlusBudgetType || isFixedFirmBudgetType
              ? [
                  {
                    Header: "Retainage",
                    accessor: "retainage",
                    Footer: () => null,
                    sortType: (a, b) => {
                      const aVal = formatNumber(a.values.retainage);
                      const bVal = formatNumber(b.values.retainage);
                      return aVal - bVal;
                    },
                    Cell: ({ value }) =>
                      value !== "-1.00" ? (
                        <CurrencyView value={value} />
                      ) : (
                        "N/A"
                      ),
                    Aggregated: () => null,
                  },
                  {
                    Header: "Retainage %",
                    accessor: "retainagePercentage",
                    Footer: () => null,
                    Cell: ({ value }) =>
                      value !== "-1.00" ? (
                        <CircularProgressBar value={value} hideIcons />
                      ) : (
                        "N/A"
                      ),
                    aggregate: "sum",
                    Aggregated: () => null,
                  },
                  {
                    Header: "Retainage Released this Period",
                    accessor: "retainedReleasedThisPeriod",
                    Footer: (columnProps) => (
                      <ColumnFooter columnProps={columnProps} />
                    ),
                    sortType: (a, b) => {
                      const aVal = formatNumber(
                        a.values.retainedReleasedThisPeriod
                      );
                      const bVal = formatNumber(
                        b.values.retainedReleasedThisPeriod
                      );
                      return aVal - bVal;
                    },
                    Cell: ({ value }) => <CurrencyView value={value} />,
                    Aggregated: ({ row }) => {
                      const totalAdjusted = getAggregatedTotal(
                        row,
                        "retainedReleasedThisPeriod"
                      );
                      return <CurrencyView value={totalAdjusted} />;
                    },
                    width: 300,
                  },
                ]
              : []),
            ...(isCostPlusBudgetType
              ? [
                  {
                    Header: "Balance to Finish",
                    accessor: "balanceToFinish",
                    Footer: (columnProps) => (
                      <ColumnFooter columnProps={columnProps} />
                    ),
                    sortType: (a, b) => {
                      const aVal = formatNumber(a.values.balanceToFinish);
                      const bVal = formatNumber(b.values.balanceToFinish);
                      return aVal - bVal;
                    },
                    Cell: ({ value }) => <CurrencyView value={value} />,
                    Aggregated: ({ row }) => {
                      const totalAdjusted = getAggregatedTotal(
                        row,
                        "balanceToFinish"
                      );
                      return <CurrencyView value={totalAdjusted} />;
                    },
                    width: 200,
                  },
                ]
              : []),
            {
              Header: "% Complete of Budget",
              accessor: "percentCompleteOfBudget",
              Footer: () => null,
              Cell: ({ value }) => <CircularProgressBar value={value} />,
              Aggregated: () => null,
              width: 220,
            },
            {
              Header: isFixedFirmBudgetType ? "Close-out" : "Holdback",
              accessor: "holdback",
              Footer: (columnProps) => (
                <ColumnFooter columnProps={columnProps} />
              ),
              sortType: (a, b) => {
                const aVal = formatNumber(a.values.holdback);
                const bVal = formatNumber(b.values.holdback);
                return aVal - bVal;
              },
              Cell: ({ value }) => <CurrencyView value={value} />,
              Aggregated: ({ row }) => {
                const totalAdjusted = getAggregatedTotal(row, "holdback");
                return <CurrencyView value={totalAdjusted} />;
              },
            },
            {
              Header: "Schedule %",
              accessor: "schedulePercentage",
              Footer: () => null,
              Cell: ({ value }) => (
                <CircularProgressBar value={value} hideIcons />
              ),
              aggregate: "sum",
              Aggregated: () => null,
            },
            {
              Header: "Date Modified",
              accessor: "date",
              Cell: ({ value }) => (
                <div className="flex">
                  <p>{moment(value).format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
                </div>
              ),
              filterOptions: {
                label: "Date Range",
                filterType: FILTER_TYPES.date,
                duplicates: [
                  {
                    label: "Last 7 days",
                    id: "lastSevenDays",
                  },
                  {
                    label: "Last 30 days",
                    id: "lastThirtyDays",
                  },
                  {
                    label: "Last 3 months",
                    id: "lastThreeMonths",
                  },
                  {
                    label: "Last 12 months",
                    id: "lastTwelveMonths",
                  },
                ],
              },
              Aggregated: () => null,
              width: 350,
            },
            ...(isCostPlusBudgetType || isTimeAndMaterialsBudgetType
              ? [
                  {
                    Header: "PO",
                    accessor: "purchaseOrder",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                      <BudgetTableDocumentsCell
                        row={row}
                        setOpenDocument={setOpenDocument}
                        columnId="purchaseOrder"
                      />
                    ),
                    Aggregated: () => null,
                    width: TABLE_COLUMN_WIDTH,
                  },
                  {
                    Header: "PO (pending)",
                    accessor: "purchaseOrderPending",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                      <BudgetTableDocumentsCell
                        row={row}
                        setOpenDocument={setOpenDocument}
                        columnId="purchaseOrderPending"
                      />
                    ),
                    Aggregated: () => null,
                    width: TABLE_COLUMN_WIDTH,
                  },
                ]
              : []),
            // {
            //   Header: "RFP",
            //   accessor: "requestForProposal",
            //   disableSortBy: true,
            //   Cell: ({ row }) => (
            //     <BudgetTableDocumentsCell
            //       row={row}
            //       setOpenDocument={setOpenDocument}
            //       columnId="requestForProposal"
            //     />
            //   ),
            //   Aggregated: () => null,
            //   width: TABLE_COLUMN_WIDTH,
            // },
            // {
            //   Header: "RFI",
            //   accessor: "requestForInformation",
            //   disableSortBy: true,
            //   Cell: ({ row }) => (
            //     <BudgetTableDocumentsCell
            //       row={row}
            //       setOpenDocument={setOpenDocument}
            //       columnId="requestForInformation"
            //     />
            //   ),
            //   Aggregated: () => null,
            //   width: TABLE_COLUMN_WIDTH,
            // },
          ]),
    ];
  }, [
    isPendingUpdatesModal,
    isDraftProject,
    isCostPlusBudgetType,
    isFixedFirmBudgetType,
    isLockGmp,
    isTimeAndMaterialsBudgetType,
    checkedRow,
    handleRowCheck,
    currentUserReference,
    isLockFixedFirm,
    onFavoriteClick,
    setSelectedLineItemForComments,
    unitsOfMeasure,
    rateSheetRates,
    isActivateProject,
    isAllLowRangeChecked,
    toggleAllLowRange,
    toggleLowRange,
    isAllHighRangeChecked,
    toggleAllHighRange,
    toggleHighRange,
    setOpenDocument,
    setOpenExpense,
  ]);

  return (
    <Table
      allowSelection={!isPendingUpdatesModal && !isActivateProject}
      resourceName={name}
      columns={tableColumns}
      data={data}
      enableEditing={hasEditPermission}
      onSelectedRowChange={setSelectedRows}
      initialSelectedRows={initialSelectedRows}
      onEditRowCancelClick={onEditRowCancelClick}
      onDeleteRowClick={({ index }) => onDeleteRowClick(data[index]?.id)}
      enableAdding={
        hasEditPermission &&
        !isActivateProject &&
        !isPendingUpdatesModal &&
        (viaChangeOrder || (!isLockGmp && !isLockFixedFirm))
      }
      inLineForm={(
        row,
        isEdit,
        togglePopOver,
        setIsPopoverOpen,
        groupBy,
        toggleRowExpanded,
        isSplitLinePopOver
      ) => (
        <BudgetTableInLineForm
          row={row}
          togglePopOver={togglePopOver}
          csiCodes={csiCodes}
          groupedUnitsOfMeasure={groupedUnitsOfMeasure}
          unitsOfMeasure={unitsOfMeasure}
          vendors={vendors}
          isEdit={isEdit}
          setIsPopoverOpen={setIsPopoverOpen}
          onEditSaveCallback={(budgetLineItem, adjustTotal) =>
            onEditSaveCallback(
              row,
              budgetLineItem,
              adjustTotal,
              toggleRowExpanded
            )
          }
          onAddSaveCallback={(budgetLineItem, adjustTotal) =>
            onAddSaveCallback(row, budgetLineItem, adjustTotal)
          }
          onSplitLineSaveCallback={(budgetLineItem, adjustTotal) =>
            onSplitLineSaveCallback(row, budgetLineItem, adjustTotal)
          }
          onCancelSaveCallback={() => onCancelSaveCallback()}
          groupBy={groupBy}
          prefillFieldsOnAddAnother={[
            "financialCode",
            "costPerUnit",
            "quantity",
            "calculation",
            "adjustment",
            "allowance",
            "isBillable",
            "building",
            "space",
            "vendor",
            "group",
          ]}
          // projectBuildings={projectBuildings}
          budgetGroups={budgetGroups}
          onSaveProjectLocation={onSaveProjectLocation}
          projectLocationSpaceConfiguration={projectLocationSpaceConfiguration}
          onSaveProjectLocationSpace={onSaveProjectLocationSpace}
          onSaveContactCallback={onSaveContactCallback}
          systemConfiguration={systemConfiguration}
          users={users}
          rateSheetRates={rateSheetRates}
          isDraftProject={isDraftProject}
          onAddBudgetGroup={onAddBudgetGroup}
          onEditBudgetGroup={onEditBudgetGroup}
          onDeleteBudgetGroup={onDeleteBudgetGroup}
          isSplitLinePopOver={isSplitLinePopOver}
          currentUser={currentUser}
          // hideAddAnother
        />
      )}
      defaultSort={
        !isActivateProject && !isPendingUpdatesModal
          ? [{ id: "csiCodeDivision" }]
          : []
      }
      enablePopOverEditing
      groupOptions={groupOptions}
      groupByCallback={groupByCallback}
      columnsForGroupByCallback={["buildingName", "csiCodeDivision"]}
      showFooter={!isPendingUpdatesModal}
      hiddenColumns={["adjustmentUnit", "arithmeticUnit", "space", "level"]}
      templateSettings={templateSettings}
      updateUserTemplateSettings={updateUserTemplateSettings}
      deleteUserTemplateSettings={deleteUserTemplateSettings}
      rowAssociationMenuList={rowAssociationMenuList}
      showEmptyTableView={!isActivateProject && !isPendingUpdatesModal}
      versionCloseCallback={versionCloseCallback}
      buttonActions={!isActivateProject && buttonActions}
      showConstantRowIndex
      initialTemplateViewName={
        !isActivateProject && !isPendingUpdatesModal && "By Financial Code"
      }
      hideSwitchView
      summaryComponent={
        documentModalView || isActivateProject || isPendingUpdatesModal
          ? null
          : summaryComponent
      }
      hideEditRowButton
      hideDeleteRowButton
      onEditPopoverOpenCallback={onEditPopoverOpenCallback}
      onEditPopoverCloseCallback={onEditPopoverCloseCallback}
      allowEditingOnRowClick={
        !isActivateProject &&
        !isPendingUpdatesModal &&
        !isLockGmp &&
        !isLockFixedFirm
      }
      hideSiteHeader={
        hideSiteHeader || isActivateProject || isPendingUpdatesModal
      }
      onPendingUpdatesQueueClick={onPendingUpdatesQueueClick}
      showPendingUpdates={showPendingUpdates}
      pendingUpdatesTotal={pendingUpdatesTotal}
      overrideSelect
      disableSelectAll={disableSelectAll}
      miniMapContainerId={tableMiniMapContainerId}
      hideSiteHeaderTitle={hideSiteHeaderTitle}
      showScrollShadow={false}
    />
  );
};

PureBudgetTable.propTypes = {
  /**
   * data to display on the data table
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      line: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      flag: PropTypes.bool,
      purchaseAuthorization: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      csiCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
      level: PropTypes.string,
      location: PropTypes.string,
      vendor: PropTypes.string,
      liveBudget: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      actualCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      costPerUnit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      varianceInDollars: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      budgetPercentage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      projectPercentage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      isBillable: PropTypes.bool,
      schedule: PropTypes.string,
      notes: PropTypes.string,
    })
  ),
  /**
   * name of table resoure
   */
  name: PropTypes.string,
  /**
   * list of financialCodes dropdown
   */
  csiCodes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  /**
   * list of buildings dropdown
   */
  // projectBuildings: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     label: PropTypes.string,
  //     value: PropTypes.string,
  //   })
  // ),
  /**
   * list of unitsOfMeasure dropdown
   */
  unitsOfMeasure: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  /**
   * list of vendors dropdown
   */
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  /**
   * function called when edit row is cancelled
   */
  onEditRowCancelClick: PropTypes.func,
  /**
   * function called when deleting a row
   */
  onDeleteRowClick: PropTypes.func,
  updateUserTemplateSettings: PropTypes.func,
  deleteUserTemplateSettings: PropTypes.func,
  templateSettings: PropTypes.arrayOf(PropTypes.shape({})),
  onEditSaveCallback: PropTypes.func,
  onAddSaveCallback: PropTypes.func,
  onCancelSaveCallback: PropTypes.func,
  rowAssociationMenuList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      className: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  groupByCallback: PropTypes.func,
  /**
   * function called when close button is clicked on a lineitem's version history
   */
  versionCloseCallback: PropTypes.func,
  setSelectedRows: PropTypes.func,
  initialSelectedRows: PropTypes.arrayOf(PropTypes.string),
  isFixedFirmBudgetType: PropTypes.bool,
  isCostPlusBudgetType: PropTypes.bool,
  isTimeAndMaterialsBudgetType: PropTypes.bool,
  hasEditPermission: PropTypes.bool,
  buttonActions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  summaryComponent: PropTypes.element,
  onFavoriteClick: PropTypes.func,
  budgetGroups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  groupedUnitsOfMeasure: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    })
  ),
  setSelectedLineItemForComments: PropTypes.func,
  onSaveProjectLocation: PropTypes.func,
  projectLocationSpaceConfiguration: PropTypes.shape({}),
  onSaveProjectLocationSpace: PropTypes.func,
  onSaveContactCallback: PropTypes.func,
  systemConfiguration: PropTypes.shape({}),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  rateSheetRates: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      ratePerHr: PropTypes.string,
    })
  ),
  isDraftProject: PropTypes.bool,
  documentModalView: PropTypes.bool,
  setOpenDocument: PropTypes.func,
  onEditPopoverCloseCallback: PropTypes.func,
  onEditPopoverOpenCallback: PropTypes.func,
  isActivateProject: PropTypes.bool,
  toggleLowRange: PropTypes.func,
  toggleHighRange: PropTypes.func,
  toggleAllHighRange: PropTypes.func,
  toggleAllLowRange: PropTypes.func,
  isAllLowRangeChecked: PropTypes.bool,
  isAllHighRangeChecked: PropTypes.bool,
  isPendingUpdatesModal: PropTypes.bool,
  onPendingUpdatesQueueClick: PropTypes.func,
  handleRowCheck: PropTypes.func,
  checkedRow: PropTypes.string,
  onAddBudgetGroup: PropTypes.func,
  onEditBudgetGroup: PropTypes.func,
  onDeleteBudgetGroup: PropTypes.func,
  hideSiteHeader: PropTypes.bool,
  isLockFixedFirm: PropTypes.bool,
  isLockGmp: PropTypes.bool,
  currentUserReference: PropTypes.string,
  disableSelectAll: PropTypes.bool,
  onSplitLineSaveCallback: PropTypes.func,
  setOpenExpense: PropTypes.func,
  tableMiniMapContainerId: PropTypes.string,
  currentUser: PropTypes.shape({
    isAdmin: PropTypes.bool,
    isSuperAdmin: PropTypes.bool,
  }),
  pendingUpdatesTotal: PropTypes.number,
  viaChangeOrder: PropTypes.bool,
  showPendingUpdates: PropTypes.bool,
  hideSiteHeaderTitle: PropTypes.bool,
};

PureBudgetTable.defaultProps = {
  data: undefined,
  name: undefined,
  csiCodes: [],
  // projectBuildings: [],
  unitsOfMeasure: [],
  vendors: [],
  onEditRowCancelClick: undefined,
  onDeleteRowClick: undefined,
  onCancelSaveCallback: undefined,
  templateSettings: [],
  updateUserTemplateSettings: undefined,
  deleteUserTemplateSettings: undefined,
  onEditSaveCallback: undefined,
  onAddSaveCallback: undefined,
  rowAssociationMenuList: [],
  groupByCallback: undefined,
  versionCloseCallback: undefined,
  setSelectedRows: undefined,
  initialSelectedRows: [],
  isFixedFirmBudgetType: false,
  isCostPlusBudgetType: false,
  isTimeAndMaterialsBudgetType: false,
  hasEditPermission: false,
  buttonActions: [],
  summaryComponent: undefined,
  onFavoriteClick: undefined,
  budgetGroups: [],
  groupedUnitsOfMeasure: [],
  setSelectedLineItemForComments: undefined,
  onSaveProjectLocation: undefined,
  projectLocationSpaceConfiguration: undefined,
  onSaveProjectLocationSpace: undefined,
  onSaveContactCallback: undefined,
  systemConfiguration: undefined,
  users: [],
  rateSheetRates: [],
  isDraftProject: false,
  documentModalView: false,
  setOpenDocument: undefined,
  onEditPopoverCloseCallback: undefined,
  onEditPopoverOpenCallback: undefined,
  isActivateProject: false,
  toggleLowRange: undefined,
  toggleHighRange: undefined,
  toggleAllHighRange: undefined,
  toggleAllLowRange: undefined,
  isAllLowRangeChecked: false,
  isAllHighRangeChecked: false,
  isPendingUpdatesModal: false,
  onPendingUpdatesQueueClick: undefined,
  handleRowCheck: undefined,
  checkedRow: undefined,
  onAddBudgetGroup: undefined,
  onEditBudgetGroup: undefined,
  onDeleteBudgetGroup: undefined,
  hideSiteHeader: undefined,
  isLockFixedFirm: false,
  isLockGmp: false,
  currentUserReference: undefined,
  disableSelectAll: false,
  onSplitLineSaveCallback: undefined,
  setOpenExpense: undefined,
  tableMiniMapContainerId: undefined,
  currentUser: undefined,
  pendingUpdatesTotal: undefined,
  viaChangeOrder: false,
  showPendingUpdates: false,
  hideSiteHeaderTitle: false,
};

export default PureBudgetTable;

/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import Dropzone from "react-dropzone";
import filesIcon from "../../assets/images/filesIcon.svg";
import uploadIcon from "../../assets/images/uploadIcon.svg";
import { bytesToSize } from "../../../helpers/File";
import CollapsibleSection from "../CollapsibleSection/CollapsibleSection";
import TertiaryHeader from "../TextHeaders/TertiaryHeader";
import Input from "../Input/Input";
import TertiaryButton from "../Buttons/TertiaryButton";
import plusIcon from "../../assets/images/plusIcon.svg";
import DeleteButton from "../DeleteButton/DeleteButton";
import EditView from "./EditView";
import PhotoView from "./PhotoView";
import ExternalLink from "../ExternalLink/ExternalLink";

import "./PureFileForm.css";

const displayContainerCN = cntl`
  my-5
`;

const tertiaryHeaderCN = cntl`
  mb-2
`;

const IconContainerCN = cntl`
  p-6
  w-24
  h-24
  rounded
`;

const PureFileForm = ({
  photos,
  videos,
  documents,
  links,
  onUpload,
  onEdit,
  onRemove,
  onIsPrimaryChange,
  onAddLink,
  onChangeLink,
  onDeleteLink,
  isInputValid,
  handleContinue,
  hideUploadedFiles,
}) => {
  const [filesToUpload, setFilesToUpload] = useState();
  const [progress, setProgress] = useState({});
  const [isLoading, setLoading] = useState(false);
  const inputRefs = useRef({ name: {}, url: {} });

  const onDrop = async (droppedFiles) => {
    setFilesToUpload(droppedFiles);
    setLoading(true);
    await onUpload(droppedFiles, (loaded, total, fileId) => {
      setProgress((prevState) => ({
        ...prevState,
        [fileId]: [loaded, total],
      }));
    });
    setLoading(false);
    setFilesToUpload(null);
  };

  /**
   * Automatically moves cursor to next input field on pressing Enter
   */
  const handleEnter = useCallback(
    (event, idx) => {
      const currentInputCategory = event.target.name;
      // checks if the Enter key was pressed
      if (event.keyCode === 13) {
        event.preventDefault();
        if (currentInputCategory === "name") inputRefs.current.url[idx].focus();
        else if (links.length - 1 > idx)
          inputRefs.current.name[(idx + 1).toString()].focus();
        else if (isInputValid) handleContinue();
      }
    },
    [handleContinue, isInputValid, links.length]
  );

  return (
    <>
      <CollapsibleSection title="Files" defaultOpen>
        <>
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => (
              <div
                className="border-dashed border cursor-pointer"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {filesToUpload ? (
                  filesToUpload.map((file) => (
                    <div key={file.name} className="flex my-2 items-center">
                      {file.type.includes("image") && (
                        <img
                          className="max-w-32 max-h-32 object-contain"
                          src={
                            file.type.includes("image")
                              ? URL.createObjectURL(file)
                              : filesIcon
                          }
                          alt=""
                        />
                      )}
                      <div className="ml-2">
                        <p>{file.name}</p>
                        {isLoading && (
                          <>
                            <progress
                              className="mt-5 w-full"
                              value={
                                progress[file.name] && progress[file.name][0]
                              }
                              max={
                                progress[file.name] && progress[file.name][1]
                              }
                            />
                            <p>
                              {`${bytesToSize(
                                progress[file.name] && progress[file.name][0]
                              )} of ${bytesToSize(
                                progress[file.name] && progress[file.name][1]
                              )}`}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="flex flex-col items-center justify-center p-8 py-20">
                    <img className="mb-3 w-16" src={uploadIcon} alt="" />
                    <div className="text-semibold flex">
                      <p>Drop files to upload or&nbsp;</p>
                      <ExternalLink>browse</ExternalLink>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Dropzone>
          {!hideUploadedFiles && (
            <div>
              <div className={displayContainerCN}>
                <TertiaryHeader className={tertiaryHeaderCN}>
                  Photos
                </TertiaryHeader>
                {photos.length > 0 ? (
                  photos.map((photo, index) => (
                    <div key={photo.id} className="flex mb-3">
                      <PhotoView
                        photo={photo}
                        onIsPrimaryChange={onIsPrimaryChange}
                      />
                      <EditView
                        file={photo}
                        index={index}
                        onEdit={onEdit}
                        onRemove={onRemove}
                      />
                    </div>
                  ))
                ) : (
                  <p>No Photos Added</p>
                )}
              </div>
              <div className={displayContainerCN}>
                <TertiaryHeader className={tertiaryHeaderCN}>
                  Videos
                </TertiaryHeader>
                {videos.length > 0 ? (
                  videos.map((video, index) => (
                    <div key={video.id} className="flex mb-3">
                      <img className={IconContainerCN} src={filesIcon} alt="" />
                      <EditView
                        file={video}
                        index={index}
                        onEdit={onEdit}
                        onRemove={onRemove}
                      />
                    </div>
                  ))
                ) : (
                  <p>No Videos Added</p>
                )}
              </div>
              <div className={displayContainerCN}>
                <TertiaryHeader className={tertiaryHeaderCN}>
                  Documents
                </TertiaryHeader>
                {documents.length > 0 ? (
                  documents.map((doc, index) => (
                    <div key={doc.id} className="flex mb-3">
                      <img className={IconContainerCN} src={filesIcon} alt="" />
                      <EditView
                        file={doc}
                        index={index}
                        onEdit={onEdit}
                        onRemove={onRemove}
                      />
                    </div>
                  ))
                ) : (
                  <p>No Documents Added</p>
                )}
              </div>
              <div className={displayContainerCN}>
                <TertiaryHeader className={tertiaryHeaderCN}>
                  Links
                </TertiaryHeader>
                {links.length > 0 ? (
                  links.map((link, index) => (
                    <div className="mb-4" key={link.id}>
                      <div className="mb-4 flex items-center">
                        <p className="mr-2">{`Link ${index + 1}`}</p>
                        <DeleteButton onConfirm={() => onDeleteLink(link.id)} />
                      </div>
                      <Input
                        placeholder="Title"
                        className="mb-2"
                        label="Title"
                        value={link.name}
                        onChange={(name) => {
                          onChangeLink({ ...link, name }, index);
                        }}
                        name="name"
                        // eslint-disable-next-line no-return-assign
                        forwardedRef={(el) =>
                          (inputRefs.current.name[index.toString()] = el)
                        }
                        handleEnter={(event) => handleEnter(event, index)}
                      />
                      <Input
                        placeholder="Url"
                        className="mb-2"
                        label="URL"
                        value={link.url}
                        onChange={(url) => {
                          onChangeLink({ ...link, url }, index);
                        }}
                        name="url"
                        // eslint-disable-next-line no-return-assign
                        forwardedRef={(el) =>
                          (inputRefs.current.url[index.toString()] = el)
                        }
                        handleEnter={(event) => handleEnter(event, index)}
                      />
                      <hr />
                    </div>
                  ))
                ) : (
                  <p>No Links Added</p>
                )}
                <TertiaryButton
                  className="py-3 px-6"
                  iconLeft={<img src={plusIcon} alt="plus" />}
                  title="Add Link"
                  onClick={onAddLink}
                />
              </div>
            </div>
          )}
        </>
      </CollapsibleSection>
    </>
  );
};

PureFileForm.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      isPrimary: PropTypes.bool.isRequired,
    })
  ),
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
    })
  ),
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
    })
  ),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  onUpload: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onIsPrimaryChange: PropTypes.func.isRequired,
  onAddLink: PropTypes.func.isRequired,
  onChangeLink: PropTypes.func.isRequired,
  onDeleteLink: PropTypes.func.isRequired,
  isInputValid: PropTypes.bool,
  handleContinue: PropTypes.func,
  hideUploadedFiles: PropTypes.bool,
};

PureFileForm.defaultProps = {
  photos: [],
  videos: [],
  documents: [],
  links: [],
  isInputValid: false,
  handleContinue: undefined,
  hideUploadedFiles: false,
};

export default PureFileForm;

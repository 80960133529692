/* eslint-disable import/prefer-default-export */
import { ProjectAPI, PropertyAPI } from "@griffingroupglobal/eslib-api";

/**
 * takes an asset and an array of properties and resolves that assets
 * property, buildings, levels, and locations
 * @param {Asset Object} asset
 * @param {Array of Property Objects} properties
 * @returns Array of resolved locations
 */
const resolveLocationInfo = (asset, properties) => {
  const property = properties.find(
    (prop) => prop.reference === asset?.property
  );
  return {
    property,
    locations:
      asset?.locations?.map((location) => {
        const building = property?.buildings?.find(
          (bld) => bld.id === location.building
        );
        return {
          building,
          level: building?.levels?.find((lvl) => lvl.id === location.level),
          location: building?.locations?.find(
            (loc) => loc.id === location.location
          ),
        };
      }) ?? [],
  };
};

const handleFormatAttributePostPayload = (payload) => {
  const { unit, fieldType, fieldValues, detail } = payload;

  if (unit) {
    return {
      detail,
      fieldType:
        fieldType === "number-entry-with-unit" ||
        fieldType?.value === "number-entry-with-unit"
          ? "number-entry"
          : fieldType?.value || fieldType,
      fieldValues: fieldValues?.map((val) => val.label),
      unit: {
        ...unit,
        fieldType:
          unit?.fieldType === "number-entry-with-unit" ||
          unit?.fieldType?.value === "number-entry-with-unit"
            ? "number-entry"
            : unit?.fieldType?.value || unit?.fieldType,
        fieldValues: unit?.fieldValues?.map((val) => val?.label || val) ?? [],
      },
    };
  }

  return {
    detail,
    fieldType:
      fieldType === "number-entry-with-unit" ||
      fieldType?.value === "number-entry-with-unit"
        ? "number-entry"
        : fieldType?.value || fieldType,
    fieldValues: fieldValues?.map((val) => val?.label || val) ?? [],
  };
};

const getLocation = (asset, propertiesDict, projectDict) => {
  const association = asset.property
    ? propertiesDict[asset.property]
    : projectDict[asset?.project];

  if (asset?.spaces?.length) {
    return association?.spaces?.find((space) => space?.id === asset?.spaces[0])
      ?.name;
  }
  return undefined;
};

const formatPayloadForTable = (
  asset,
  additionalDetailsColumnNames,
  propertiesDict,
  projectDict,
  tagsDict,
  subCategoryDict
) => {
  const property = propertiesDict?.[asset?.property]?.title;
  const project = projectDict?.[asset?.project]?.name;
  return {
    id: asset?.id,
    name: asset?.name,
    image: asset?.primaryImage,
    primaryImage: asset?.primaryImage,
    categoryId: asset?.category,
    category: subCategoryDict?.[asset?.subcategory]?.category,
    subcategory: subCategoryDict?.[asset?.subcategory]?.subCategory,
    subcategoryId: asset?.subcategory,
    measurement: asset?.additionalInfo,
    type: asset?.additionalInfo?.find((m) => m.detail === "Types")?.value,
    link: asset?.additionalInfo?.find((m) => m.detail === "Buy It Again")
      ?.value,
    valuation: asset?.value?.length > 0 ? asset?.value[0]?.amount : undefined,
    isAppraised: !!asset?.value?.length,
    appraisalDate:
      asset?.value?.length > 0 ? asset?.value[0]?.dateOfExamination : undefined,
    brand: asset?.generalInfo?.brand,
    quantity: asset?.generalInfo?.quantity || 1,
    condition: asset?.generalInfo?.condition,
    yearMade: asset?.generalInfo?.yearMade,
    origin: asset?.generalInfo?.origin,
    numberOfOwners: asset?.generalInfo?.numberOfOwners || 0,
    purchaseDate: asset?.generalInfo?.purchaseDate,
    lastUpdated: asset?.metadata?.lastUpdated,
    parLevel: asset?.generalInfo?.parLevel,
    // TODO: ES-2799 replace this with relationships and add property/entity
    property,
    project,
    association: project ?? property,
    description: asset?.description,
    location: getLocation(asset, propertiesDict, projectDict),
    hasLinks: !!asset?.links?.length,
    hasDocuments: !!asset?.documents?.length,
    insuranceAgent: asset?.insuranceAgent,
    insuranceBroker: asset?.insuranceBroker,
    addAnother: asset?.addAnother,
    ...additionalDetailsColumnNames?.reduce((previousValue, currentValue) => {
      return {
        ...previousValue,
        [currentValue]: asset?.[currentValue],
      };
    }, {}),
    tags: asset?.tags,
    currentTags:
      asset?.tags?.map((tagRef) => ({
        label: tagsDict?.[tagRef]?.label,
        value: tagsDict?.[tagRef]?.reference,
      })) || [],
  };
};

const getDetailValue = (additionalInfo) => {
  let rv = additionalInfo.value;
  if (additionalInfo.unit) {
    rv = `${rv} ${additionalInfo.unit}`;
  }
  return rv;
};

const flatMapFilterSpaces = (properties, reference) => {
  const flatMap =
    properties
      ?.filter((property) => property?.reference === reference)
      ?.flatMap((property) =>
        property.buildings?.flatMap((building) =>
          building.spaces?.map((space) => ({
            label: space?.name,
            value: {
              location: space,
              building,
              property,
            },
          }))
        )
      ) ?? [];

  return flatMap;
};

const fetchMembers = async (getFunc, id) => {
  try {
    const { data: r } = await getFunc(id);
    return r?.members?.filter((m) => m.user !== undefined)?.map((m) => m.user);
  } catch (error) {
    return [];
  }
};

const getAssetOrPropertyMembersRef = async (res) => {
  let members;
  if (res?.resourceType === "property") {
    members = await fetchMembers(PropertyAPI.getByRef, res?.val);
  } else if (res?.project) {
    members = await fetchMembers(ProjectAPI.getByRef, res?.project);
  } else if (res?.property) {
    members = await fetchMembers(PropertyAPI.getByRef, res?.property);
  }
  return members;
};

export {
  getLocation,
  formatPayloadForTable,
  resolveLocationInfo,
  getDetailValue,
  flatMapFilterSpaces,
  handleFormatAttributePostPayload,
  getAssetOrPropertyMembersRef,
};

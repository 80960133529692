import { useMemo, useState } from "react";
import { useAppState } from "../../../../state/appState";
import { useESChatContext } from "../../../../context/ChatContext/ESChatContext";
import useCurrentUser from "../../../../hooks/useCurrentUser";

export default () => {
  const [{ users, usersStreamInfo }] = useAppState();
  const [selected, setSelected] = useState([]);
  const [channelName, setChannelName] = useState("");
  const { createChannel } = useESChatContext();
  const { data: currentUser } = useCurrentUser();

  const channelMembers = useMemo(() => {
    return users?.reduce((list, item) => {
      if (currentUser?.id === item.id) return list;
      const found = usersStreamInfo?.find((x) => x.userId === item.id);
      if (found) {
        list.push({ ...item, streamId: found.id, value: item.reference });
      }
      return list;
    }, []);
  }, [currentUser?.id, users, usersStreamInfo]);

  const onChangeMembers = (val) => {
    setSelected((prev) => [...prev, val]);
  };

  const onRemoveMember = (val) => {
    setSelected((prev) => prev.filter((item) => item.reference !== val));
  };

  const onSave = () => {
    createChannel(channelName, selected);
  };

  return {
    channelMembers,
    onChangeMembers,
    selected,
    onRemoveMember,
    channelName,
    setChannelName,
    onSave,
  };
};

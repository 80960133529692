import useWindowDimensions from "../../../hooks/useWindowDimensions";

const useTrayContainerData = ({ height }) => {
  const { height: windowHeight } = useWindowDimensions();

  // If a specific height is provided, the component uses it.
  // Otherwise, it dynamically adjusts its height to 75% of the screen size,
  // also is listening for window resizing events to ensure responsivenes.
  const dynamicHeigth = height || windowHeight - windowHeight * 0.25;

  return {
    dynamicHeigth,
  };
};

export default useTrayContainerData;

import React from "react";
import PropTypes from "prop-types";

const ContactInformationHeader = ({ contactInfo }) => {
  return (
    <div className="flex flex-col pr-8">
      <div className="flex flex-row gap-1">
        <p className="text-black text-es-normal font-es-bold">
          {contactInfo?.firstName} {contactInfo?.lastName}
        </p>
        <p>({contactInfo?.email}) </p>
      </div>
      <div className="flex flex-row justify-end">
        <p className="text-black font-es-bold">{contactInfo?.company}</p>
      </div>
    </div>
  );
};

ContactInformationHeader.propTypes = {
  contactInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    company: PropTypes.string,
  }),
};

ContactInformationHeader.defaultProps = {
  contactInfo: undefined,
};

export default ContactInformationHeader;

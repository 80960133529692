import { useCallback, useEffect, useState } from "react";
import { UserAPI } from "@griffingroupglobal/eslib-api";

export default (contactIds) => {
  const [contacts, setContacts] = useState();

  const reload = useCallback(async () => {
    const idRef = contactIds.reduce(
      (str, val) => (str ? `${str},${val}` : `${val}`),
      ""
    );
    const idQuery = `?id=${idRef}`;
    const {
      data: { entries: users },
    } = await UserAPI.getWOP(idQuery);
    const allUsers = await Promise.all(
      users?.map(async (user) => ({
        ...user?.resource,
        company: {
          ...user?.resource?.company,
          label: user?.resource?.companyName,
        },
      }))
    );
    setContacts(allUsers);
  }, [contactIds]);

  useEffect(() => {
    reload();
  }, [reload]);

  return [contacts, reload];
};

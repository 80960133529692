import { setLocale } from "yup";

// call this when the app starts to set the default error messages for the yup validator
export const setGlobalYupErrorMessages = () => {
  // sets up the default error messages for yup form validation
  setLocale({
    mixed: {
      required: "Required",
      notType: function notType(_ref) {
        switch (_ref.type) {
          case "number":
            return "Must be a number";
          case "string":
            return "Must be valid text";
          default:
            return "Must be correct type";
        }
      },
    },
  });
};

export const isValidEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

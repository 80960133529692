import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import Papa from "papaparse";
import PrimaryButton from "../Buttons/PrimaryButton";
import UploadArea from "../UploadArea/UploadArea";
import crossIcon from "../../assets/images/crossIcon.svg";
import attachmentIcon from "../../assets/images/attachmentIcon.svg";
import warningIcon from "../../assets/images/warning.svg";
import TertiaryButton from "../Buttons/TertiaryButton";
import contactTemplateFile from "../../assets/templates/contactTemplateFile.csv";
import Modal from "../Modal/Modal";
import { validateContactsImportedCsvFile } from "../../../helpers/User";

const ImportContactsModal = ({ onCSVUpload, onClose }) => {
  const [uploadedCsvFile, setUploadedCsvFile] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (uploadedCsvFile) {
      Papa.parse(uploadedCsvFile, {
        complete: (results) => {
          validateContactsImportedCsvFile(results.data, setErrors);
        },
        header: true,
      });
    }
  }, [uploadedCsvFile]);

  const close = useCallback(() => {
    onClose();
    setUploadedCsvFile(null);
    setErrors([]);
  }, [onClose]);

  const cancel = useCallback(() => {
    setUploadedCsvFile(null);
    setErrors([]);
  }, []);

  const readFile = useCallback(() => {
    setIsUploading(true);
    Papa.parse(uploadedCsvFile, {
      complete: async (results) => {
        await onCSVUpload({ rows: results.data });
        setIsUploading(false);
        onClose();
      },
      header: true,
    });
  }, [onCSVUpload, onClose, uploadedCsvFile]);

  return (
    <Modal isOpen hideFooter hideHeader>
      <div className="flex flex-col justify-center items-center py-4">
        <div className="flex flex-col">
          <div className="flex justify-between mb-4 mt-2">
            <div className="flex flex-row">
              <h2 className="text-lg font-semibold">Import Contacts</h2>
            </div>
            <button className="-mt-6 -mr-3" type="button" onClick={close}>
              <img src={crossIcon} alt="close button" />
            </button>
          </div>
          <div className="flex flex-row">
            <p>
              Before you import a CSV file, please ensure that it conforms with
              the correct format.{" "}
              <a
                href={contactTemplateFile}
                download="EstateSpace Contacts Template.csv"
                className="text-brandGreen"
              >
                Download CSV template
              </a>
            </p>
          </div>
          {(uploadedCsvFile && (
            <div className="flex flex-col">
              <div className="flex items-center pl-1 py-4">
                <img
                  className="mr-3"
                  src={attachmentIcon}
                  alt="attachment icon"
                />
                <p className="font-semibold text-gray-200">
                  {uploadedCsvFile?.name}
                </p>
              </div>
              {(!!errors.length && (
                <>
                  <p className="font-bold">Error parsing file:</p>
                  {errors.map((error) => (
                    <div className="flex items-center py-1" key={error}>
                      <img
                        className="mr-3"
                        src={warningIcon}
                        alt="warning icon"
                      />
                      <p className="font-semibold text-gray-200">{error}</p>
                    </div>
                  ))}
                </>
              )) || (
                <div className="flex justify-end w-full mt-8">
                  <TertiaryButton
                    className="mr-2"
                    title="Cancel"
                    onClick={cancel}
                  />
                  <PrimaryButton
                    title="Upload"
                    onClick={readFile}
                    disabled={isUploading}
                  />
                </div>
              )}
            </div>
          )) || (
            <UploadArea
              className="mt-4"
              onFilesAdd={(files) => {
                setUploadedCsvFile(files[0]);
              }}
              title="File Upload"
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

ImportContactsModal.propTypes = {
  onClose: PropTypes.func,
  onCSVUpload: PropTypes.func,
};

ImportContactsModal.defaultProps = {
  onClose: undefined,
  onCSVUpload: undefined,
};

export default ImportContactsModal;

import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../../Checkbox/Checkbox";
import DropdownWithCheckbox from "../../DropdownWithCheckbox";
import AssetCategoriesDropdown from "../../AssetCategoriesDropdown";
import PillsContainer from "../../DropdownWithPills/PillsContainer";
import useIncludeInformationData from "./useIncludeInformationData";
import { INPUT_BG_ENABLED } from "../../../../constants";

const IncludeInformation = ({
  associationType,
  assetsCategoriesData,
  includeInformationData,
  setIncludeInformationData,
  setAssetsCategoriesData,
}) => {
  const { checkboxesSection, dropdownsSection, getDropdownSectionClassnames } =
    useIncludeInformationData({
      associationType,
      assetsCategoriesData,
      includeInformationData,
      setIncludeInformationData,
      setAssetsCategoriesData,
    });

  return (
    <div
      className="w-full p-8 mt-4 rounded-md"
      style={{ backgroundColor: INPUT_BG_ENABLED }}
    >
      <span className="font-bold text-gray-450 text-md">
        Include Information
      </span>

      <div className="flex w-full gap-x-6 mt-4">
        {checkboxesSection.map((checkbox) => (
          <Checkbox
            key={checkbox.label}
            label={checkbox.label}
            labelClassName="text-base text-gray-300"
            checked={checkbox.isChecked}
            onChange={checkbox.onChange}
          />
        ))}
      </div>

      <div className="border-b border-gray-450 border-opacity-50 mt-4" />

      <div className="grid grid-cols-2 gap-x-10 gap-y-4 mt-6">
        {dropdownsSection.map((section) => (
          <div
            key={section.label}
            className={getDropdownSectionClassnames(section)}
          >
            <div className="col-span-1">
              <Checkbox
                label={section.label}
                disabled={section.checkboxDisabled}
                labelClassName="text-base text-gray-300"
                checked={section.isChecked}
                onChange={section.checkboxOnChange}
              />
            </div>

            <div className="col-span-2">
              {section.label === "Assets" ? (
                <AssetCategoriesDropdown
                  value={section.value}
                  setValue={section.dropdownOnChange}
                  menuPlacement={section.menuPlacement}
                  isMulti={section.isMulti}
                  isDisabled={section.dropdownDisabled}
                />
              ) : (
                <>
                  <DropdownWithCheckbox
                    options={section.dropdownOptions}
                    value={section.value}
                    setValue={section.dropdownOnChange}
                    isMulti={section.isMulti}
                    menuPlacement={section.menuPlacement}
                    isDisabled={section.dropdownDisabled}
                    disableAllOption={section.disableAllOption}
                  />

                  {section.label === "Financial Documents" &&
                    section.isChecked && (
                      <PillsContainer
                        data={section.pillOptions}
                        selectedPills={section.pillValue}
                        onClick={section.onSelectedPill}
                      />
                    )}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

IncludeInformation.defaultProps = {
  associationType: undefined,
  includeInformationData: undefined,
  assetsCategoriesData: [],
  setIncludeInformationData: () => {},
  setAssetsCategoriesData: () => {},
};

IncludeInformation.propTypes = {
  associationType: PropTypes.oneOf(["property", "project", "asset"]),
  includeInformationData: PropTypes.shape({}),
  assetsCategoriesData: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * Callback to pass data to parent component
   */
  setIncludeInformationData: PropTypes.func,
  setAssetsCategoriesData: PropTypes.func,
};

export default IncludeInformation;

import { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import {
  CredentialManagerAPI,
  UsertenantAPI,
} from "@griffingroupglobal/eslib-api";
import usePasswordValidator from "../../../../hooks/usePasswordValidator";
import Auth from "../../../../helpers/Auth";
import { tenantKeys } from "../../../../config/reactQuery/queryKeyFactory";

const useNewPasswordForm = ({ temporaryPassword, email, handleNext }) => {
  const passwordInputRef = useRef();
  const confirmPasswordRef = useRef();
  const confirmTabRef = useRef();
  const createPasswordRef = useRef();
  const queryClient = useQueryClient();
  const [passwordValidation, setPasswordValidation] = useState({});
  const [passwordShown, setPasswordShown] = useState(false);
  const [showSecondPass, setShowSecondPass] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmitNewPassword = async () => {
    setIsSaving(true);
    try {
      await CredentialManagerAPI.postWOP("$setpassword", {
        resource: "Parameters",
        parameters: [
          { name: "password", valueString: newPassword },
          { name: "email", valueString: email },
          { name: "temporaryPassword", valueString: temporaryPassword },
        ],
      });
      const userTenant = Auth.getUserTenant(email);

      await UsertenantAPI.patch(
        "$registered",
        {
          ...userTenant,
          registered: true,
        },
        userTenant,
        {
          params: { email },
          date: new Date(),
        }
      );
      queryClient.invalidateQueries(tenantKeys.tenants);
    } catch (e) {
      console.warn("There was an issue resetting your password", e);
    }
    setIsSaving(false);
    handleNext();
    Auth.signIn(email, newPassword);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoaded(true);
    }, 300);

    // cleanup function
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  /**
   * When the password is changed, run the password validation rules against it.
   */
  usePasswordValidator(
    newPassword,
    confirmNewPassword,
    setPasswordValidation,
    setIsValidPassword
  );

  return {
    confirmPasswordRef,
    passwordValidation,
    passwordInputRef,
    passwordShown,
    setPasswordShown,
    showSecondPass,
    setShowSecondPass,
    createPasswordRef,
    isLoaded,
    isValidPassword,
    setNewPassword,
    newPassword,
    setConfirmNewPassword,
    handleSubmitNewPassword,
    confirmTabRef,
    isSaving,
  };
};

export default useNewPasswordForm;

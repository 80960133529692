import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import * as yup from "yup";
import Input from "../../Input/Input";
import Dropdown from "../../Dropdown/Dropdown";
import Checkbox from "../../Checkbox/Checkbox";
import {
  TIME_OFF_TYPES,
  ACCRUAL_TYPES,
  PTO_TYPES,
  MAX_ACCRUAL_OPTIONS,
} from "../../../../constants";
import Modal from "../../Modal/Modal";
import CollapsibleSection from "../../CollapsibleSection/CollapsibleSection";
import PlusCircleButton from "../../Buttons/PlusCircleButton/PlusCircleButton";
import PrimaryButton from "../../Buttons/PrimaryButton";
import usePtoLocationsSectionData from "./usePtoLocationsSectionData";
import DatePicker from "../../DatePicker/DatePicker";
import InfoTooltip from "../../InfoTooltip/InfoTooltip";

const collapsibleContainerCN = cntl`
  text-black
  rounded-md
  border
  mb-4
  overflow-hidden
  border-gray-150
`;

const barCN = (isOpen) => cntl`
  px-4
  ${isOpen ? "lg:py-2" : "lg:py-4"}
  ${isOpen ? "" : "rounded-md"}
  bg-es-extra-light-grey
`;

const PtoLocationsSection = ({
  systemSettings,
  dispatchSystemSettings,
  setIsValid,
}) => {
  const {
    deletingLocation,
    isLocked,
    statesDDL,
    statesInUse,
    toggle,
    onSelectPtoLocationType,
    onChangePtoLocationAccrualType,
    onChangePtoLocationNumHours,
    onChangePtoLocationMaxRollOver,
    onChangePtoLocationAvailabilityDate,
    onChangePtoLocationTypeAvailabilityDays,
    onChangePtoLocationTypeMaxAccrual,
    onChangePtoLocationTypeNegativeValues,
    onChangeLocationExemptStatus,
    onRemove,
    onConfirmRemove,
    onAdd,
    onChangeLocation,
    setDeletingLocation,
  } = usePtoLocationsSectionData({
    systemSettings,
    dispatchSystemSettings,
    setIsValid,
  });

  return (
    <div>
      {systemSettings?.ptoLocations?.map((pto, ptoIndex) => {
        const ptoLocationInUse = statesInUse.has(pto?.location);
        return (
          <CollapsibleSection
            key={`${pto.location}-${pto?.isExempt}`}
            id={pto.location}
            className={collapsibleContainerCN}
            barClassName={barCN(pto?.isOpen)}
            subComponent={() =>
              pto?.isOpen && (
                <div className="flex flex-row">
                  <Checkbox
                    size={5}
                    label="Exempt"
                    checked={pto?.isExempt}
                    onChange={() => {
                      onChangeLocationExemptStatus(
                        true,
                        pto?.location,
                        ptoIndex
                      );
                    }}
                    disabled={isLocked}
                    labelClassName="text-es-normal font-es-normal text-es-medium-grey"
                  />
                  <Checkbox
                    size={5}
                    label="Non-Exempt"
                    checked={!pto?.isExempt}
                    onChange={() => {
                      onChangeLocationExemptStatus(
                        false,
                        pto?.location,
                        ptoIndex
                      );
                    }}
                    disabled={isLocked}
                    className="mr-10 ml-4"
                    labelClassName="text-es-normal font-es-normal text-es-medium-grey"
                  />
                </div>
              )
            }
            titleComponent={() => (
              <div className="flex">
                {(pto?.isOpen && (
                  <div className="pb-10">
                    <div
                      className="w-72 absolute"
                      onClick={(e) => {
                        // Stops dropdown from opening/closing collapsible section
                        e.stopPropagation();
                      }}
                      // Props below requirements for onClick
                      role="button"
                      label="button"
                      tabIndex="0"
                      onKeyDown={() => {}}
                    >
                      <Dropdown
                        placeholder="Select state (Required)"
                        customStyle={{ container: { zIndex: 1 } }}
                        options={statesDDL}
                        value={
                          pto?.location
                            ? {
                                value: pto?.location,
                                label: pto?.location,
                              }
                            : null
                        }
                        onChange={({ value }) =>
                          onChangeLocation(value, ptoIndex)
                        }
                        disableErrorMessage
                        isDisabled={isLocked}
                        validation={yup.mixed().required()}
                      />
                    </div>
                  </div>
                )) || (
                  <div className="flex flex-row items-center">
                    <h3 className="font-bold truncate text-gray-300 text-medium">
                      {pto?.location}
                    </h3>
                    <h4 className="font-semibold text-gray-300 text-xxs ml-1">
                      {pto?.isExempt ? "(Exempt)" : "(Non-Exempt)"}
                    </h4>
                  </div>
                )}
              </div>
            )}
            isOpen={!!pto?.isOpen && pto?.isOpen}
            setIsOpen={() => {
              toggle(ptoIndex);
            }}
            useChevronIcon
          >
            <div className="flex flex-col overflow-x-scroll">
              {!!pto.types.length && (
                <div className="grid grid-cols-es-pto pt-4 px-6 gap-2 border-b pb-2 mb-2">
                  <h3 className="font-semibold text-black">Time Off Type</h3>
                  <h3 className="font-semibold text-black">Accrual Type</h3>
                  <h3 className="font-semibold text-black">Hours Annually</h3>
                  <h3 className="font-semibold text-black">Max. Accrual</h3>
                  <h3 className="font-semibold text-black">
                    Max. Roll-over Annually
                  </h3>
                  <h3 className="font-semibold text-black">
                    Availability Date
                  </h3>
                  <h3 className="font-semibold text-black">Wait Period</h3>
                  <h3 className="font-semibold text-black">Negative Balance</h3>
                </div>
              )}
              {pto.types?.map((ptoType, typeIndex) => {
                if (ptoType?.type === "unlimited") return null;
                const maxAccrualValue = MAX_ACCRUAL_OPTIONS.find(
                  (opt) => opt.value === ptoType?.maxAccrual
                );
                return (
                  <div
                    className="grid grid-cols-es-pto w-full gap-2 py-3 px-6 items-center"
                    key={ptoType?.type}
                  >
                    <div className="self-center">
                      <Checkbox
                        checked={ptoType.selected}
                        size={5}
                        label={
                          TIME_OFF_TYPES.find(
                            (type) => type.value === ptoType?.type
                          )?.label
                        }
                        onChange={(val) =>
                          onSelectPtoLocationType(
                            ptoIndex,
                            typeIndex,
                            ptoType?.type,
                            val
                          )
                        }
                        labelClassName="text-base font-normal text-es-medium-grey"
                        disabled={isLocked}
                      />
                    </div>
                    <div>
                      {ptoType.selected ? (
                        <Dropdown
                          value={ACCRUAL_TYPES.find(
                            (type) => type.value === ptoType?.accrualType
                          )}
                          onChange={(val) =>
                            onChangePtoLocationAccrualType(
                              val,
                              ptoIndex,
                              typeIndex
                            )
                          }
                          options={ACCRUAL_TYPES}
                          disableClear
                          isDisabled={
                            ptoType?.type === PTO_TYPES[0] ||
                            ptoType?.type === PTO_TYPES[7] ||
                            isLocked
                          }
                        />
                      ) : (
                        <Input
                          inputContainerClassName="bg-backgroundGrey border-0"
                          inputClassName="bg-backgroundGrey p-4"
                          value="--"
                          disabled
                        />
                      )}
                    </div>
                    <div>
                      <Input
                        placeholder="Value"
                        className="text-es-medium-grey"
                        inputContainerClassName={
                          (!ptoType.selected ||
                            ptoType?.type === PTO_TYPES[0]) &&
                          "bg-backgroundGrey border-0"
                        }
                        inputClassName={
                          (!ptoType.selected ||
                            ptoType?.type === PTO_TYPES[0]) &&
                          "bg-backgroundGrey p-4"
                        }
                        value={
                          ptoType.selected && ptoType?.type !== PTO_TYPES[0]
                            ? ptoType?.numHours
                            : "--"
                        }
                        onChange={(val) =>
                          onChangePtoLocationNumHours(val, ptoIndex, typeIndex)
                        }
                        disableClear
                        disabled={
                          !ptoType.selected ||
                          ptoType?.type === PTO_TYPES[0] ||
                          isLocked
                        }
                        validation={
                          ptoType.selected &&
                          yup
                            .number()
                            .min(1, "Atleast 1")
                            .max(240, `240 maximum`)
                        }
                        showValidationErrorAtBottom
                      />
                    </div>
                    <div>
                      {ptoType.selected &&
                      ptoType?.accrualType !== "non-accrual" ? (
                        <Dropdown
                          className="text-es-medium-grey"
                          options={MAX_ACCRUAL_OPTIONS}
                          value={{
                            label: `${maxAccrualValue?.label}%`,
                            value: maxAccrualValue?.value,
                          }}
                          onChange={(val) =>
                            onChangePtoLocationTypeMaxAccrual(
                              val,
                              ptoIndex,
                              typeIndex
                            )
                          }
                          disableClear
                          isDisabled={
                            ptoType?.type === PTO_TYPES[0] ||
                            ptoType?.accrualType === "non-accrual" ||
                            isLocked
                          }
                        />
                      ) : (
                        <Input
                          inputContainerClassName="bg-backgroundGrey border-0"
                          inputClassName="bg-backgroundGrey p-4"
                          value="--"
                          disabled
                        />
                      )}
                    </div>
                    <div>
                      <Input
                        placeholder="Value"
                        className="text-es-medium-grey"
                        inputContainerClassName={
                          (!ptoType.selected ||
                            ptoType?.accrualType === "non-accrual") &&
                          "bg-backgroundGrey border-0"
                        }
                        inputClassName={
                          (!ptoType.selected ||
                            ptoType?.accrualType === "non-accrual") &&
                          "bg-backgroundGrey p-4"
                        }
                        value={
                          ptoType.selected &&
                          ptoType?.accrualType !== "non-accrual"
                            ? ptoType?.maxRollover
                            : "--"
                        }
                        onChange={(val) =>
                          onChangePtoLocationMaxRollOver(
                            val,
                            ptoIndex,
                            typeIndex
                          )
                        }
                        disableClear
                        disabled={
                          !ptoType.selected ||
                          ptoType?.type === PTO_TYPES[0] ||
                          ptoType?.accrualType === "non-accrual" ||
                          isLocked
                        }
                        validation={
                          ptoType.selected &&
                          ptoType?.accrualType === "accrual" &&
                          yup
                            .number()
                            .min(1, "Atleast 1")
                            .max(240, `240 maximum`)
                        }
                        showValidationErrorAtBottom
                      />
                    </div>
                    <div>
                      {ptoType.selected ? (
                        <DatePicker
                          value={ptoType?.availability?.date}
                          onChange={(val) =>
                            onChangePtoLocationAvailabilityDate(
                              val,
                              ptoIndex,
                              typeIndex
                            )
                          }
                          validation={yup.date()}
                          disabled={isLocked}
                        />
                      ) : (
                        <Input
                          inputContainerClassName="bg-backgroundGrey border-0"
                          inputClassName="bg-backgroundGrey p-4"
                          value="--"
                          disabled
                        />
                      )}
                    </div>
                    <div>
                      <div className="w-2/3 pr-2 inline-block">
                        <Input
                          placeholder="Value"
                          className="text-es-medium-grey"
                          inputContainerClassName={
                            !ptoType.selected && "bg-backgroundGrey border-0"
                          }
                          inputClassName={
                            !ptoType.selected && "bg-backgroundGrey p-4"
                          }
                          value={
                            ptoType.selected && ptoType?.availability?.date
                              ? ptoType?.availability?.waitPeriod
                              : "--"
                          }
                          onChange={(val) =>
                            onChangePtoLocationTypeAvailabilityDays(
                              val,
                              ptoIndex,
                              typeIndex
                            )
                          }
                          disableClear
                          disabled={
                            !ptoType.selected ||
                            !ptoType?.availability?.date ||
                            isLocked
                          }
                          validation={
                            ptoType?.availability?.waitPeriod &&
                            ptoType.selected &&
                            yup
                              .number()
                              .min(1, "Atleast 1")
                              .max(90, `90 maximum`)
                          }
                          showValidationErrorAtBottom
                        />
                      </div>
                      <div className="w-1/3 inline-block">
                        <p className="text-es-medium-grey">Days</p>
                      </div>
                    </div>
                    <div className="self-center">
                      <Checkbox
                        checked={ptoType?.allowNegatives}
                        size={5}
                        label="Allow"
                        labelClassName="text-base text-es-medium-grey"
                        onChange={(val) =>
                          onChangePtoLocationTypeNegativeValues(
                            ptoIndex,
                            typeIndex,
                            ptoType?.type,
                            val
                          )
                        }
                        disabled={
                          !ptoType.selected ||
                          ptoType?.type === PTO_TYPES[0] ||
                          isLocked
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="py-4 mt-2 flex flex-row ml-6 border-t items-center">
              <PrimaryButton
                addButton
                keepIcon={false}
                buttonTitle="Delete location"
                className="mr-2"
                onClick={() => onRemove(ptoIndex)}
                disabled={ptoLocationInUse || isLocked}
              />
              {ptoLocationInUse && !isLocked ? (
                <InfoTooltip
                  title="Disabled"
                  infoData={[
                    {
                      label: "State is in use in member management",
                      content:
                        "Currently states are locked once added as a state in member mangement (state of employment)",
                    },
                  ]}
                />
              ) : null}
            </div>
          </CollapsibleSection>
        );
      })}
      {isLocked ? null : (
        <PlusCircleButton
          title="Add Location"
          onClick={onAdd}
          className="flex w-full items-center border-t mt-6 pt-6"
          style={{ color: "#027D61", fontSize: 16 }}
          disabled={isLocked}
          noHover
        />
      )}
      {deletingLocation !== undefined && (
        <Modal
          title="DELETE?"
          isOpen
          onRequestModalClose={() => setDeletingLocation()}
          hideFooter
          primaryButtonTitle="Delete"
          primaryButtonOnClick={onConfirmRemove}
          tertiaryButtonTitle="Cancel"
          alert
        >
          <p>Are you sure you want to delete this?</p>
        </Modal>
      )}
    </div>
  );
};

PtoLocationsSection.propTypes = {
  systemSettings: PropTypes.shape({
    ptoGeneralTypes: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        accrualType: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
        availability: PropTypes.shape({
          waitPeriod: PropTypes.string,
          date: PropTypes.string,
        }),
        selected: PropTypes.bool,
        numHours: PropTypes.number,
        maxRollover: PropTypes.number,
      })
    ),
    ptoLocations: PropTypes.arrayOf(
      PropTypes.shape({
        location: PropTypes.string,
        types: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            accrualType: PropTypes.shape({
              label: PropTypes.string,
              value: PropTypes.string,
            }),
            availability: PropTypes.shape({
              waitPeriod: PropTypes.string,
              date: PropTypes.string,
            }),
            selected: PropTypes.bool,
            numHours: PropTypes.number,
            maxRollover: PropTypes.number,
          })
        ),
      })
    ),
    ptoCategory: PropTypes.string,
  }),
  dispatchSystemSettings: PropTypes.func,
  setIsValid: PropTypes.func,
};

PtoLocationsSection.defaultProps = {
  systemSettings: undefined,
  dispatchSystemSettings: undefined,
  setIsValid: undefined,
};

export default PtoLocationsSection;

import { v4 as uuidv4 } from "uuid";

/**
 * Creates a non-submitted timesheet for an employee who doesn't have one saved in the system.
 * This is the bare minimum timesheet object required to view a timesheet for such an employee.
 *
 * @param {Object} user - The user object for whom the timesheet is being created.
 * @returns {Object} The timesheet object for the given user, including user details and a unique identifier.
 */

const createNonSubmittedTimesheet = (user) => {
  // entries must be present in the timesheet object, even if it's empty.
  // keys 0-4 represent the days of the week, starting with Monday.
  const timesheetObj = {
    entries: {
      0: {
        status: "open",
        value: 0,
      },
      1: {
        status: "open",
        value: 0,
      },
      2: {
        status: "open",
        value: 0,
      },
      3: {
        status: "open",
        value: 0,
      },
      4: {
        status: "open",
        value: 0,
      },
    },
  };

  // Add the user's full name to the timesheet object.
  const modifiedUser = {
    ...user,
    fullName: `${user?.name?.firstName} ${user?.name?.lastName}`,
  };

  return {
    ...timesheetObj,
    employee: modifiedUser,
    id: uuidv4(),
    userRef: user.reference,
    simulated: true,
    status: "Not Submitted",
  };
};

export default createNonSubmittedTimesheet;

import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

const divCN = (width, height, className) => cntl`
  bg-gray-200
  rounded-full
  ${width || "w-24"}
  ${height || "h-24"}
  flex
  items-center
  justify-center
  ${className}
`;

const UserImageAvatar = ({ thumbnailUrl, width, height, className }) => {
  return (
    <div className={divCN(width, height, className)}>
      <img
        src={thumbnailUrl}
        className="w-full h-full rounded-full"
        alt="userAvatarIcon"
      />
    </div>
  );
};

UserImageAvatar.propTypes = {
  thumbnailUrl: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

UserImageAvatar.defaultProps = {
  width: undefined,
  height: undefined,
  className: undefined,
};

export default UserImageAvatar;

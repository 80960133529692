/* eslint-disable no-param-reassign */
import { useReducer } from "react";

const defaultState = {
  description: null,
  members: [],
};

const reducer = (todo, action) => {
  switch (action.type) {
    case "setRequiredByAll":
      return { ...todo, requiredByAll: action.value };
    case "description":
      return { ...todo, description: action.value };
    case "addMember":
      return { ...todo, members: [...todo.members, action.value] };
    case "removeMember":
      return {
        ...todo,
        members: todo.members.filter((item) => item.user !== action.value),
      };
    default:
      return todo;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState || defaultState);
};

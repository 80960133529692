import moment from "moment";
import { v4 as uuidv4 } from "uuid";

const organizeEntriesToRow = (timesheet) => {
  const rows = {};
  const entries = timesheet?.resource?.entries;

  const periodStart = moment(timesheet?.resource?.periodStart);
  const periodEnd = moment(timesheet?.resource?.periodEnd);

  const days = {};

  for (let i = 0; i <= periodEnd.diff(periodStart, "days"); i += 1) {
    const day = periodStart
      .clone()
      .add(i, "days")
      .utc()
      .startOf("day")
      .toISOString();

    days[day] = {
      date: day,
      value: 0,
      untracked: true,
      note: "",
      status: "open",
      isBillable: true,
    };
  }

  if (!entries) return rows;

  entries.forEach((entry) => {
    // Extract project, financialCode, and rate from the entry
    const { project, financialCode, rate } = entry;

    const key = `${project}_${financialCode.division}_${financialCode.code}_${financialCode.subcode}_${rate}`;

    if (!rows[key]) {
      rows[key] = [];
    }

    const day = moment(entry.date).utc().startOf("day").toISOString();
    if (days[day]) {
      rows[key].push(entry);
    }
  });

  const formattedRows = {};

  Object.entries(rows).forEach(([key, value]) => {
    const project = value.find((entry) => entry.project);
    const financialCode = value.find((entry) => entry.financialCode);
    const rate = value.find((entry) => entry.rate);

    const formattedDays = {};

    Object.values(days).forEach((day) => {
      formattedDays[day.date] = {
        ...day,
        project: project?.project || "",
        financialCode: financialCode?.financialCode || {},
        rate: rate?.rate || "",
        id: uuidv4(),
      };
    });

    value.forEach((entry) => {
      const day = moment(entry.date).utc().startOf("day").toISOString();

      if (formattedDays[day]) {
        formattedDays[day] = entry;
      }
    });

    formattedRows[key] = Object.values(formattedDays);
  });

  return formattedRows;
};

export default organizeEntriesToRow;

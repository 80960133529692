import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { ADD_OPEN_MODAL, CREATE_TASK_MODAL } from "../../../constants";
import getFormattedRecurrence from "../../../helpers/Date/getFormattedRecurrence";
import getUniqueTasksWithClosestStartDate from "../../../helpers/Date/getUniqueTasksWithClosestStartDate";
import { getFullName } from "../../../helpers/Formatters";
import formatTasks from "../../../helpers/ServiceRequest/formatTasks";
import filterByAssociation from "../../../helpers/Task/filterByAssociation";
import {
  getAssociationNameFromReference,
  getWorkflowNameFromReference,
} from "../../../helpers/Utilities";
import { useProjectsOverview } from "../../../hooks/projects";
import { usePropertiesOverview } from "../../../hooks/properties";
import useTaskList from "../../../hooks/tasks/useTaskList";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useSopVersionForTask from "../../../hooks/useSopVersionForTask";
import { useSingleTask } from "../../../hooks/tasks";
import useTemplatesConfiguration from "../../../hooks/useTemplatesConfiguration.new";
import useTemplatesConfigurationDelete from "../../../hooks/useTemplatesConfigurationDelete";
import useTemplatesConfigurationPatch from "../../../hooks/useTemplatesConfigurationPatch";
import useURLQueryParameter from "../../../hooks/useURLQueryParameter";
import useWorkflow from "../../../hooks/useWorkflow";
import { useAppState } from "../../../state/appState";
import { useModalState } from "../../../state/modalState";
import useQueryNotFoundNavigation from "../../../hooks/navigation/useQueryNotFoundNavigation";

// This custom hook manages the task list-related data.
const useTaskListData = () => {
  const [{ userDict }] = useAppState();

  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  const { data: workflowsData } = useWorkflow();
  const location = useLocation();
  const { data: currentUser } = useCurrentUser();
  const history = useHistory();

  const taskIdParam = useURLQueryParameter("id");
  const instanceStartDateQueryParam = useURLQueryParameter("instanceStartDate");

  // Fetch a single task if url has the `id` param
  const { singleTask, singleTaskLoading, singleTaskError } = useSingleTask(
    taskIdParam,
    instanceStartDateQueryParam
  );

  // Redirect to 404 page if resource is not found
  useQueryNotFoundNavigation({ error: singleTaskError });

  // State to manage visibility of header items based on the current page
  const [showHeaderItems, setShowHeaderItems] = useState(false);
  const [{ modals }, modalDispatch] = useModalState();

  const [currentTask, setCurrentTask] = useState(null);

  const { usedSopDict } = useSopVersionForTask({ currentTask });

  // templates for Quick View Drop Down
  const { data: templatesConfiguration } = useTemplatesConfiguration();
  const { mutate: patchTemplates } = useTemplatesConfigurationPatch();
  const { mutate: deleteTemplate } = useTemplatesConfigurationDelete();
  const [commentsData, setCommentsData] = useState({
    taskReference: null,
    association: null,
  });

  const { taskList } = useTaskList();

  const handleCloseComments = () => {
    setCommentsData({
      taskReference: null,
      association: null,
    });
  };

  const handleOpenComments = ({ reference, association }) => {
    setCommentsData({
      taskReference: reference,
      association,
    });
  };

  const templateSettings = useMemo(() => {
    if (!templatesConfiguration?.templates?.task) {
      return [];
    }

    return templatesConfiguration?.templates?.task
      ?.filter((template) => template.name !== "last_updated_column_view")
      ?.map((template) => ({
        ...template,
        isAdmin: !template.custom,
      }));
  }, [templatesConfiguration]);

  const updateUserTemplateSettings = useCallback(
    async (customViews) => {
      patchTemplates({
        resource: "task",
        updatedTemplates: [
          ...templateSettings.filter((temp) => !temp.custom),
          ...customViews.map((view) => {
            return {
              ...view,
              custom: true,
            };
          }),
        ],
      });
    },
    [patchTemplates, templateSettings]
  );
  const deleteUserTemplateSettings = useCallback(
    async (template) => {
      deleteTemplate({
        resource: "task",
        id: template.id,
      });
    },
    [deleteTemplate]
  );

  const openNewTaskDialog = useCallback(
    (lock, childLock) => {
      modalDispatch({
        type: ADD_OPEN_MODAL,
        ref: { id: uuidv4() },
        modalData: {
          associationLock: lock,
          assetLock: childLock,
          to: "task_list",
        },
        modalType: CREATE_TASK_MODAL,
      });
    },
    [modalDispatch]
  );

  // This effect runs whenever the location (route) changes
  useEffect(() => {
    // Extracting the main segment of the current path, e.g., if the path is "/tasks/123", currentPage will be "tasks"
    const currentPage = location.pathname.split("/")[1];

    // If the current page is "tasks", show the header items
    if (currentPage === "tasks") setShowHeaderItems(true);
  }, [location.pathname]);

  const formattedTasks = useMemo(() => {
    const tasks = taskList?.map((item) => item.resource) || [];
    const allTasks = filterByAssociation(tasks);

    const newTasks = allTasks?.map((item) => {
      const association = item?.association?.split("/")[0];
      return {
        ...item,
        lastCompleted: item?.metadata.lastUpdated,
        createdBy: getFullName(userDict[item?.metadata?.createdBy]?.name),
        completedBy: item?.closing?.closedBy
          ? getFullName(userDict[item?.closing?.closedBy]?.name)
          : "N/A",
        formattedStatus: formatTasks(item)?.formattedStatus,
        formattedRecurrence: getFormattedRecurrence(item.recurrence),
        associatedResource: association,
        associatedName:
          getAssociationNameFromReference(
            item.association,
            propertiesDict,
            projectDict
          ) ?? "",
        workflowName: getWorkflowNameFromReference(
          item.reference,
          workflowsData?.workflows?.filter((workflow) => !workflow.isTemplate)
        ),
      };
    });

    const tasksWithRecurrences = newTasks.filter(
      (newTask) => newTask?.recurrence
    );

    const tasksWithoutRecurrences = newTasks.filter(
      (newTask) => !newTask?.recurrence
    );

    const trimmedTasks =
      getUniqueTasksWithClosestStartDate(tasksWithRecurrences);

    return [...trimmedTasks, ...tasksWithoutRecurrences];
  }, [
    projectDict,
    propertiesDict,
    userDict,
    workflowsData?.workflows,
    taskList,
  ]);

  useEffect(() => {
    if (singleTask) {
      setCurrentTask(singleTask);
    }
  }, [singleTask]);

  /**
   * Redirect to a single task view within the association page or task resource, depending on the conditions
   */
  const onTaskClick = (rowData) => {
    const pageViewedFrom = location.pathname.split("/")[1];

    const { id, instanceStartDate } = rowData?.original || {};

    if (pageViewedFrom === "tasks") {
      const taskPath = instanceStartDate
        ? `/tasks/${id}?instanceStartDate=${instanceStartDate}`
        : `/tasks/${id}`;

      history.push(taskPath);

      return;
    }

    const queryParams = instanceStartDate
      ? `?tab=tasks&id=${id}&instanceStartDate=${instanceStartDate}`
      : `?tab=tasks&id=${id}`;

    history.push(queryParams);

    setCurrentTask(rowData?.original);
  };

  const clearCurrentTask = () => {
    setCurrentTask(null);
  };
  // Check if the current user has a modal open of create task type
  const newTaskButtonDisabled = modals?.find(
    (item) => item?.modalType === CREATE_TASK_MODAL
  );

  // Check if the current user has the correct privileges to create a new task
  const hasCorrectPrivileges =
    currentUser?.isAdmin || currentUser?.hasPermission?.("task", "can_create");

  return {
    showHeaderItems,
    modals,
    newTaskButtonDisabled,
    hasCorrectPrivileges,
    formattedTasks,
    currentTask,
    isLoading: singleTaskLoading,
    templateSettings,
    commentsData,
    openNewTaskDialog,
    onTaskClick,
    setCurrentTask,
    clearCurrentTask,
    usedSopDict,
    updateUserTemplateSettings,
    deleteUserTemplateSettings,
    handleCloseComments,
    handleOpenComments,
  };
};

export default useTaskListData;

import { useCallback, useMemo } from "react";
import useCurrentUser from "../../hooks/useCurrentUser";
import useServiceRequestTableFormat from "../../hooks/useServiceRequestTableFormat";
import { useAppState } from "../../state/appState";
import { SET_REQUEST_MODAL_STATUS } from "../../constants";

const useServiceRequestListData = () => {
  const [, dispatchAppState] = useAppState();
  const { data: currentUser } = useCurrentUser();

  // Query selector to get all service requests
  const { data: serviceRequestTickets = [], isLoading } =
    useServiceRequestTableFormat({ association: false, asset: false });

  const canCreate = useMemo(
    () =>
      currentUser?.isAdmin ||
      currentUser?.hasPermission?.("ticket", "can_create"),
    [currentUser]
  );

  const handleOpenModal = useCallback(
    (open = true) => {
      dispatchAppState({
        type: SET_REQUEST_MODAL_STATUS,
        open,
      });
    },
    [dispatchAppState]
  );

  return {
    canCreate,
    serviceRequestTickets,
    isLoading,
    handleOpenModal,
  };
};

export default useServiceRequestListData;

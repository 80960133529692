import React from "react";
import PropTypes from "prop-types";
import PureSubscriptionPlans from "./PureSubscriptionPlans";

const SubscriptionPlans = ({
  plans,
  selectedPlan,
  setIsMonthly,
  isMonthly,
  onPlanSelect,
  cardStyle,
  hideTitle,
  activeUsers,
  isSaving,
}) => {
  return (
    <PureSubscriptionPlans
      plans={plans}
      isMonthly={isMonthly}
      setIsMonthly={setIsMonthly}
      onPlanSelect={onPlanSelect}
      selectedPlan={selectedPlan}
      hideTitle={hideTitle}
      activeUsers={activeUsers}
      cardStyle={cardStyle}
      isSaving={isSaving}
    />
  );
};

SubscriptionPlans.propTypes = {
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      planName: PropTypes.string,
      userText: PropTypes.string,
      cost: PropTypes.string,
      discount: PropTypes.string,
      features: PropTypes.arrayOf(PropTypes.string),
      showDiscount: PropTypes.bool,
    })
  ),
  setIsMonthly: PropTypes.func,
  isMonthly: PropTypes.bool,
  onPlanSelect: PropTypes.func,
  hideTitle: PropTypes.bool,
  cardStyle: PropTypes.string,
  selectedPlan: PropTypes.shape({
    planName: PropTypes.string,
    billingCycle: PropTypes.string,
  }),
  activeUsers: PropTypes.number,
  isSaving: PropTypes.bool,
};

SubscriptionPlans.defaultProps = {
  plans: [],
  setIsMonthly: undefined,
  isMonthly: false,
  onPlanSelect: undefined,
  hideTitle: false,
  cardStyle: undefined,
  selectedPlan: undefined,
  activeUsers: undefined,
  isSaving: false,
};

export default SubscriptionPlans;

// APIs
import moment from "moment";
import PropTypes from "prop-types";
// Framework Tools
import React, { useEffect, useState } from "react";
// helpers
import { formatWorkflowHistoryCardData } from "../../../helpers/WorkflowHelpers";
// Components
import Widget from "../Widget/Widget";
import RequestHistoryCard from "../RequestHistoryCard/RequestHistoryCard";
import { useWorkflowHistory } from "../../../hooks/useHistory";
import { useAppState } from "../../../state/appState";

const WorkflowHistory = ({ loading, attachmentMap, commentMap, workflow }) => {
  const { data: workflowHistory, isLoading } = useWorkflowHistory(
    workflow?.reference
  );
  const [{ userDict }] = useAppState();
  const [historyCards, setHistoryCards] = useState([]);

  useEffect(() => {
    if (workflow && workflowHistory) {
      setHistoryCards(
        workflow?.rounds?.map((data, index) => {
          return formatWorkflowHistoryCardData(
            data,
            index,
            workflow,
            workflowHistory || {},
            userDict
          );
        })
      );
    }
  }, [workflow, workflowHistory, userDict]);

  return (
    <Widget
      title=""
      draggable={false}
      loading={loading || isLoading}
      hideBorder
    >
      <div className="flex flex-col items-start gap-6">
        {!loading &&
          !isLoading &&
          historyCards
            ?.map((historyCardData) => {
              return (
                <RequestHistoryCard
                  key={historyCardData?.roundNum}
                  start={moment(historyCardData?.durationStart)
                    .startOf("day")
                    .format("MMMM, DD, YYYY")}
                  end={
                    historyCardData?.durationEnd &&
                    moment(historyCardData?.durationEnd)
                      .endOf("day")
                      .format("MMMM, DD, YYYY")
                  }
                  duration={historyCardData?.durationDays}
                  commentMap={commentMap}
                  currentRound={historyCardData?.isCurrentRound}
                  round={historyCardData?.roundNum}
                  users={historyCardData?.roundUsers}
                  attachments={historyCardData?.roundAttachments}
                  actionsByStepMap={historyCardData?.actionsByStepMap}
                  attachmentMap={attachmentMap}
                  initiatedBy={historyCardData?.initiatedBy}
                />
              );
            })
            .reverse()}
      </div>
    </Widget>
  );
};

WorkflowHistory.propTypes = {
  loading: PropTypes.bool,
  attachmentMap: PropTypes.shape({}),
  commentMap: PropTypes.shape({}),
  workflow: PropTypes.shape({
    reference: PropTypes.string,
    rounds: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

WorkflowHistory.defaultProps = {
  loading: true,
  attachmentMap: undefined,
  commentMap: undefined,
  workflow: undefined,
};
export default WorkflowHistory;

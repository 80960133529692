// Framework Tools
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

// Libraries
import * as yup from "yup";

// Hooks
import { useAppState } from "../../../../state/appState";

// Constants
import {
  SET_SUBMITTAL_MODAL_STATUS,
  SET_WORKFLOW_MODAL_STATUS,
} from "../../../../constants";

// Components
import Dropdown from "../../Dropdown/Dropdown";
import Input from "../../Input/Input";
import Modal from "../../Modal/Modal";

export default function RequestCompleteModal({
  requestData,
  isSubmittal,
  isWorkflow,
  onComplete,
}) {
  // Hooks
  const [{ submittalModalStatus, workflowModalStatus }, dispatch] = useAppState(
    []
  );

  // State controls
  const [results, setResults] = useState({
    label: "Resolved",
    value: "completed-resolved",
  });
  const [budgetImpact, setBudgetImpact] = useState();
  const [scheduleImpact, setScheduleImpact] = useState();
  const [dollarAmount, setDollarAmount] = useState();
  const [days, setDays] = useState();
  const [dayOptions, setDayOptions] = useState([]);
  const [comment, setComment] = useState();

  const handleDaysOptions = useCallback(() => {
    const options = [];
    for (let i = 0; i <= 365; i += 1) {
      options.push({ label: i, value: i });
    }
    options.sort();
    return options;
  }, []);

  const resetForm = () => {
    setResults({
      label: "Resolved",
      value: "completed-resolved",
    });
    setBudgetImpact();
    setScheduleImpact();
    setDollarAmount();
    setDays();
    setComment();
  };

  useEffect(() => {
    const options = handleDaysOptions();
    setDayOptions(options);
  }, [handleDaysOptions]);

  useEffect(() => {
    if (budgetImpact?.value !== "yes") {
      setDollarAmount(0);
    }
    if (scheduleImpact?.value !== "yes") {
      setDays();
    }
  }, [budgetImpact?.value, scheduleImpact?.value]);

  // Functions
  const handleClose = () => {
    if (isSubmittal) {
      dispatch({
        type: SET_SUBMITTAL_MODAL_STATUS,
        complete: false,
      });
    } else if (isWorkflow) {
      dispatch({
        type: SET_WORKFLOW_MODAL_STATUS,
        complete: false,
      });
    }
  };

  const handleImpactOptions = () => {
    return [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
      { label: "TBD", value: "tbd" },
    ];
  };

  const handleResultOptions = () => {
    return [
      { label: "Resolved", value: "completed-resolved" },
      { label: "Open", value: "completed-open" },
    ];
  };

  const handleCompleteRequest = async () => {
    const currentStatus = results?.value;
    const currentBudget = {
      status: budgetImpact?.value,
      value: dollarAmount,
    };
    const currentSchedule = {
      status: scheduleImpact?.value,
      value: days?.value,
    };
    const currentImpacts = {
      budget: currentBudget,
      schedule: currentSchedule,
    };

    if (onComplete) {
      onComplete({
        impacts: currentImpacts,
        status: currentStatus,
        comment,
      });
    }
    handleClose();
  };

  const getTitle = () => {
    if (isSubmittal) {
      return "Complete Submittal";
    }
    if (isWorkflow) {
      return "Complete Workflow";
    }
    return "Complete";
  };

  return (
    <Modal
      modalAction={`${requestData?.customName} completion`}
      primaryButtonTitle="Complete"
      primaryButtonOnClick={handleCompleteRequest}
      isOpen={submittalModalStatus?.complete || workflowModalStatus?.complete}
      onRequestModalClose={() => {
        resetForm();
        handleClose();
      }}
      title={getTitle()}
      tertiaryButtonTitle="Cancel"
      onDeleteClick={() => {}}
      expandable
      hideFooter
      tertiaryDelete
      trashCan
      showConfirm
    >
      <div className="flex flex-col items-start gap-6 self-stretch w-full">
        <div className="flex flex-col w-88 items-start gap-3">
          <p className=" w-32 h-7 font-bold text-gray-500 text-base">Results</p>
          <Dropdown
            customStyle={{
              container: {
                background: "#F9F9F9",
              },
            }}
            disableClear
            className="w-88"
            options={handleResultOptions()}
            onChange={(val) => setResults(val)}
            value={results}
            validation={yup.object().shape({
              label: yup.string().required(),
              value: yup.string().required(),
            })}
          />
        </div>
        <div className="flex flex-col h-20 items-start self-stretch gap-3">
          <p className=" w-32 h-7 font-bold text-gray-500 text-base">
            Cost Impact
          </p>
          <div className="flex items-start gap-6 self-stretch">
            <Dropdown
              disableClear
              customStyle={{
                container: {
                  background: "#F9F9F9",
                },
              }}
              className="w-88"
              placeholder="Select"
              options={handleImpactOptions()}
              value={budgetImpact}
              onChange={(val) => setBudgetImpact(val)}
            />
            <div className="flex items-start gap-2 flex-1">
              <p className="font-bold text-gray-500 text-base">$</p>
              <Input
                inputClassName="ESInput bg-lightGreen text-gray-450 placeholderOverride"
                inputContainerClassName="bg-lightGreen text-gray-450"
                className="w-88"
                type="number"
                placeholder="Amount"
                value={dollarAmount}
                disabled={budgetImpact?.value !== "yes"}
                onChange={(val) => setDollarAmount(parseFloat(val))}
              />
            </div>
          </div>
        </div>
        <div className="flex items-start gap-10 self-stretch">
          <div className="flex w-88 flex-col items-start gap-3">
            <p className="w-56 h-7 font-bold text-gray-500 text-base">
              Schedule Impact
            </p>
            <Dropdown
              className="w-88"
              customStyle={{
                container: {
                  background: "#F9F9F9",
                },
              }}
              disableClear
              placeholder="Select"
              options={handleImpactOptions()}
              value={scheduleImpact}
              onChange={(val) => setScheduleImpact(val)}
            />
          </div>
          <div className="flex w-88 flex-col items-start gap-3">
            <p className="w-56 h-7 font-bold text-gray-500 text-base">Days</p>
            <Dropdown
              disableClear
              className="w-88"
              customStyle={{
                container: {
                  background: "#F9F9F9",
                },
              }}
              isDisabled={scheduleImpact?.value !== "yes"}
              placeholder="Select"
              disableSort
              options={dayOptions}
              value={days}
              onChange={(val) =>
                setDays({
                  label: parseInt(val.label, 10),
                  value: parseInt(val.value, 10),
                })
              }
            />
          </div>
        </div>
        <div className="flex flex-col items-start gap-3 self-stretch">
          <p className="w-56 h-7 font-bold text-gray-500 text-base">Comment</p>
          <Input
            disableClear
            isTextarea
            placeholder="Comment"
            className="w-full h-32"
            inputClassName="ESInput bg-lightGreen text-gray-450 placeholderOverride"
            inputContainerClassName="bg-lightGreen text-gray-450"
            value={comment}
            onChange={(val) => setComment(val)}
          />
        </div>
      </div>
    </Modal>
  );
}

RequestCompleteModal.propTypes = {
  requestData: PropTypes.shape({
    id: PropTypes.string,
    customName: PropTypes.string,
  }),
  isSubmittal: PropTypes.bool,
  isWorkflow: PropTypes.bool,
  onComplete: PropTypes.func,
};

RequestCompleteModal.defaultProps = {
  requestData: undefined,
  isSubmittal: false,
  isWorkflow: false,
  onComplete: undefined,
};

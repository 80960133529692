import React from "react";
import TaskList from "./TaskList";
import PlusCircleButton from "../../../stories/Components/Buttons/PlusCircleButton/PlusCircleButton";
import useTasksViewData from "./useTaskViewData";
import { PRIMARY_DARK_GREEN } from "../../../constants";

const Tasks = ({
  canAddTasks,
  serviceRequestTasks,
  serviceRequestAssociation,
  isEditing,
  handleAddTasks,
  handleRemoveTasks,
  labelClassName,
}) => {
  const { handleShowTaskModal, handleNavigateToTask } = useTasksViewData({
    canAddTasks,
    handleAddTasks,
    serviceRequestAssociation,
  });

  return (
    <div className="gap-10 grid">
      <span className={`font-semibold text-lg ${labelClassName}`}>Tasks</span>

      <TaskList
        tasks={serviceRequestTasks}
        isEditing={isEditing}
        handleNavigateToTask={handleNavigateToTask}
        handleTasksToDelete={handleRemoveTasks}
      />

      {isEditing && (
        <div>
          <PlusCircleButton
            title="Add Task"
            className="-mt-5"
            style={{ color: PRIMARY_DARK_GREEN }}
            onClick={handleShowTaskModal}
          />
        </div>
      )}
    </div>
  );
};

export default Tasks;

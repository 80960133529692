import cntl from "cntl";
import React from "react";

const buttonCN = (disabled) => cntl`
${disabled ? "bg-gray-100 text-gray-150" : "bg-primaryGreen text-white"}
px-3
py-2
rounded-md
`;

const CompleteButton = ({ disabled, onClick }) => {
  return (
    <button
      type="button"
      className={buttonCN(disabled)}
      onClick={() => {
        if (!disabled) onClick();
      }}
    >
      Mark as Complete
    </button>
  );
};

export default CompleteButton;
